<template>
	<!--协议客户-->
	<div id="customerDetail">
		<div class="title-top bg-f clearfix">{{$route.params.name}}</div>
		<div class="content bg-f pr-30 pl-30 mt-5">
			<div class="clearfix search">
				<el-form :inline='true' class="pt-30 fl" :model="Form" ref="searchForm">
					<el-form-item label='账号'>
						<el-input v-model.trim="loginName" placeholder="请输入账号" :maxlength="30"></el-input>
					</el-form-item>
					<el-form-item label='姓名'>
						<el-input v-model.trim="customerName" placeholder="请输入姓名" :maxlength="30"></el-input>
					</el-form-item>
				</el-form>
				<el-button class="fl ml-30 mt-30" type='info' @click="achieve()">搜索</el-button>
			</div>
			<div class="clearfix">
				<div class="fr">
					<el-button class='mr-10' type='info' @click="unBind">解绑销售员</el-button>
					<el-button class='mr-10' type='info' @click="showBindDialog">分配销售员</el-button>
					<el-button class='mr-10' type='info' @click="changeRow">可用额度重置</el-button>
					<el-button type="danger" class="mb-5 mr-10" @click="openCustomer('Form')">新增签单人</el-button>
				</div>
			</div>
			<div class="pb-30">
				<el-table :data="tableData" @selection-change="handleSelectionChange">
					<el-table-column type="selection"></el-table-column>
					<el-table-column label='账号' min-width="120">
						<template slot-scope="scope">
							<span>{{scope.row.loginName}}</span>
						</template>
					</el-table-column>
					<el-table-column label='姓名' min-width="90">
						<template slot-scope="scope">
							<span>{{scope.row.customerName}}</span>
						</template>
					</el-table-column>
					<el-table-column label='总额度' min-width="90">
						<template slot-scope="scope">
							<span>{{scope.row.creditLine}}</span>
						</template>
					</el-table-column>
					<el-table-column label='可用额度' min-width="90">
						<template slot-scope="scope">
							<span v-if="scope.row.creditAvailable">{{scope.row.creditAvailable}}</span>
							<span v-else>-</span>
						</template>
					</el-table-column>
                      <el-table-column label='已绑定销售员' min-width="120">
                        <template slot-scope="scope">
                            <span>{{scope.row.storeName}}</span>
                        </template>
                      </el-table-column>
                    <el-table-column label='操作' min-width="320" align="center">
                        <template slot-scope="scope">
                            <el-button type="text" @click.native="changeMoneys(scope,'form')">修改总额度</el-button>
                            <el-button type="text" @click.native="changeMoneys1(scope,'form1')">修改可用额度</el-button>
                            <el-button class="width-50" @click='isForbbident(scope)' type="text">{{scope.row.isDisable ? "启用" : "禁用"}}</el-button>
                            <el-button class="width-50" type="text" @click='isDelete(scope)'>删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
			</div>
			<!--新增协议客户-->
			<el-dialog class="minMop" title='新增签单人' :visible.sync='customer'>
				<el-form class="pr-10" :rules="rule" ref="Form" :model="Form">
					<el-form-item label="姓名:" class="ml-20" prop='customerName'>
							<el-input class="ml-10 mr-10" v-model="Form.customerName" placeholder="姓名"></el-input>
					</el-form-item>
					<el-form-item label="手机号:" class="ml-20" prop='loginName'>
							<el-input class="ml-10 mr-10" v-model="Form.loginName" placeholder="手机号" :maxlength="11"></el-input>
					</el-form-item>
					<el-form-item label="授信额度:" class="ml-20" prop='creditLine'>
							<el-input class="ml-10 mr-10" v-model="Form.creditLine" placeholder="授信额度"></el-input>
					</el-form-item>
				</el-form>
				<div slot='footer' class="dialog-footer">
					<el-button type='danger' @click="addCustomer('Form')">确定</el-button>
					<el-button class="ml-20" @click="customer = false">取消</el-button>
				</div>
			</el-dialog>
			<!--修改额度-->
			<el-dialog class="minMop" title='修改总额度' :visible.sync='changeMoney'>
				<el-form class="pr-10" :rules="rules" ref="form" :model="form">
					<el-form-item label="总额度:" class="ml-20" prop='creditLine' required>
						<el-input class="ml-10 mr-10" v-model="form.creditLine" placeholder="总额度"></el-input>
					</el-form-item>
				</el-form>
				<div slot='footer' class="dialog-footer">
					<el-button type='danger' @click="changeCreditAvailable('form')">确定</el-button>
					<el-button class="ml-20" @click="changeMoney = false">取消</el-button>
				</div>
			</el-dialog>
            <el-dialog class="minMop" title='修改可用额度' :visible.sync='changeMoney1'>
				<el-form class="pr-10" :rules="rules1" ref="form1" :model="form1">
					<el-form-item label="总额度:" class="ml-20">
						<el-input :disabled="true" class="ml-10 mr-10" v-model="form1.creditLine" placeholder="总额度"></el-input>
					</el-form-item>
					<el-form-item label="可用额度:" class="ml-20" prop='creditAvailable' required>
						<el-input class="ml-10 mr-10" v-model="form1.creditAvailable" placeholder="可用额度"></el-input>
					</el-form-item>
				</el-form>
				<div slot='footer' class="dialog-footer">
					<el-button type='danger' @click="changeCreditAvailable1('form1')">确定</el-button>
					<el-button class="ml-20" @click="changeMoney1 = false">取消</el-button>
				</div>
			</el-dialog>
            <!-- 分配销售员 -->
            <el-dialog class="bindSales" title='分配销售员' :visible.sync='setSales' width="730px">
					<div class="clearfix search">
						<el-form :inline='true' class="pt-20 fl" :model="searchForm">
							<el-form-item label='销售员名称'>
								<el-input v-model.trim="searchForm.companyName" placeholder="请输入销售员名称" :maxlength="40"></el-input>
							</el-form-item>
						</el-form>
						<el-button class="fl mt-20" type='info' @click="getSalesList()">搜索</el-button>
					</div>
					<el-table :data="unBindData">
						<el-table-column label='销售员编码' width="300">
							<template slot-scope="scope">
								<span>{{scope.row.id}}</span>
							</template>
						</el-table-column>
						<el-table-column label='销售员名称' width="300">
							<template slot-scope="scope">
								<span>{{scope.row.storeName}}</span>
							</template>
						</el-table-column>
						<el-table-column label='操作' align="center">
							<template slot-scope="scope">
								<el-button type="text" @click='bind(scope)'>绑定</el-button>
							</template>
						</el-table-column>
					</el-table>
          <!-- 分页组件 -->
					<el-pagination class="tar"
					 v-if="unBindData.length>0"
					   :current-page="currentPage"
					   :page-size='10'
					   :total='totalCount' 
					  layout="prev , pager, next, jumper" 
					  @current-change="handleChange">
					</el-pagination>
			</el-dialog>
		</div>
	</div>
</template>

<script>
import { axiosApi, basePath } from "../../../api/api";
import { MessageBox } from "element-ui";
export default {
  data() {
    let reg = /^\d{1,7}(\.\d{1,2})?$/;
    let ress = /^S$/
    var creditLine = (rule, value, callback) => {
      if (ress.test(value)) {
        callback(new Error("总额度为必填项"));
      } else if (!reg.test(value)) {
        callback(new Error("总额度为必填项,仅支持小数点前7位，小数点后2位数字"));
      }else {
        callback();
      }
    };
    var creditAvailable = (rule, value, callback) => {
      if (ress.test(value)) {
        callback(new Error("可用额度为必填项"));
      } else if (!reg.test(value)) {
        callback(new Error("可用额度为必填项，仅支持小数点前7位，小数点后2位数字"));
      } else if (
        (this.form1.creditLine || this.form1.creditLine != "") &&
        parseFloat(value) > parseFloat(this.form1.creditLine)
      ) {
        callback(new Error("可用额度不能大于总额度！"));
      } else {
        callback();
      }
    };
    return {
      customer: false, //新增协议客户弹框
      changeMoney: false, //修改额度
      changeMoney1: false, //修改额度
      customerName: null, //搜索条件
      loginName: null, //搜索条件
      Form: {
        customerName: null,
        loginName: null,
        creditLine: ""
      },
      form: {
        creditLine: "",
        id: ""
      },
      form1: {
        creditLine: "",
        creditAvailable: "",
        id: ""
      },
      cooperativeCustomerIds: [], //批量重置额度数组
      tableData: [], //根据协议公司查询所有协议客户
      rule: {
        customerName: [
          {
            required: true,
            message: "姓名为必填项"
          },
          {
            pattern: /^[\u4e00-\u9fa5a-zA-Z]{2,6}$/,
            message: "只能输入汉字或英文，长度在2-6位",
            trigger: "blur"
          }
        ],
        loginName: [
          {
            required: true,
            message: "电话为必填项"
          },
          {
            pattern: /^1\d{10}$/,
            message: "请输入正确的手机号",
            trigger: "blur"
          }
        ],
        creditLine: [
          {
            required: true,
            message: "授信额度为必填项"
          },
          {
            pattern: /^\d{1,7}(\.\d{1,2})?$/,
            message: "仅支持小数点前7位，小数点后2位数字",
            trigger: "blur"
          }
        ]
      },
      rules: {
        creditLine: [
          {
            validator: creditLine,
            trigger: "blur"
          }
        ]
      },
      rules1: {
        creditLine: [
          {
            validator: creditLine,
            trigger: "blur"
          }
        ],
        creditAvailable: [
          {
            validator: creditAvailable,
            trigger: "blur"
          }
        ]
      },
      setSales: false,//分配销售员弹窗
      searchForm: {
        companyName: null
      },
      unBindData: [],//未绑定的数据
      currentPage: 1,//
      totalCount: 0,//
      selectedAry: []
    };
  },
  watch: {},
  mounted() {
    this.$nextTick(() => {
      this.achieve();
    });
  },
  computed: {},
  methods: {
    achieve(val) {
      //根据协议公司查询所有协议客户
      axiosApi( "/superc/cooperativeCustomer/list", { 
        companyId: this.$route.params.companyId,
        customerName: this.customerName ? this.customerName : null,
        loginName: this.loginName ? this.loginName : null,
      }, res => {
          this.tableData = res.data.data ? res.data.data : [];
        }, 1,10,res => {

        }
      );
    },
    //禁用，启用
    isForbbident(val) {
      let title = val.row.isDisable ? "启用" : "禁用";
      let that = this;
      this.$confirm("您是否要" + title + "该账号？", "提示信息", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          const api = "/superc/cooperativeCustomer/update";
          axiosApi( api, {id: val.row.id,isDisable: val.row.isDisable ? false : true},res => {
              this.$message({
                type: "success",
                message: "操作成功!",
                duration: 1000
              });
              that.achieve();
            },1,10,res => {
              this.$message({
                type: "info",
                message: res.data.message,
                duration: 3000
              });
            }
          );
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作"
          });
        });
    },
    handleSelectionChange(val) {
      this.selectedAry = []
       val.map(item => {
        if (item.storeName) {
          this.selectedAry.push(item.storeName)
        }
      })
      //选中的状态
      this.cooperativeCustomerIds = val.map(item => {
        return item.id;
      });
    },
    //获取行数据
    changeMoneys(val, form) {
      if (this.$refs.form !== undefined) {
        this.$refs.form.resetFields();
      }
      this.form.creditLine = val.row.creditLine;
      this.form.id = val.row.id;
      this.changeMoney = true;
    },
    changeMoneys1(val, form1) {
      if (this.$refs.form1 !== undefined) {
        this.$refs.form1.resetFields();
      }
      this.form1.creditAvailable = val.row.creditAvailable;
      this.form1.creditLine = val.row.creditLine;
      this.form1.id = val.row.id;
      this.changeMoney1 = true;
    },
    changeCreditAvailable(form) {
      //协议客户更新（更新额度，禁用启用）
      this.$refs.form.validate(valid => {
        if (valid) {
          axiosApi( "/superc/cooperativeCustomer/updateCreditLine",{creditLine: this.form.creditLine, id: this.form.id,companyId: this.$route.params.companyId},res => {
              this.achieve();
              this.$message({
                type: "success",
                message: "修改成功",
                duration: 3000
              });
              this.changeMoney = false;
            },1, 10, res => {
              this.changeMoney = false;
              this.$message({
                type: "info",
                message: res.data.message,
                duration: 3000
              });
            }
          );
        }
      });
    },
    changeCreditAvailable1(form1) {
      //协议客户更新（更新额度，禁用启用）
      this.$refs.form1.validate(valid => {
        if (valid) {
          axiosApi( "/superc/cooperativeCustomer/updateCreditAvailable",{creditAvailable: this.form1.creditAvailable, id: this.form1.id,companyId: this.$route.params.companyId},res => {
              this.achieve();
              this.$message({
                type: "success",
                message: "修改成功",
                duration: 3000
              });
              this.changeMoney1 = false;
            },1, 10, res => {
              this.changeMoney = false;
              this.$message({
                type: "info",
                message: res.data.message,
                duration: 3000
              });
            }
          );
        }
      });
    },
    //重置额度
    changeRow() {
      if (this.cooperativeCustomerIds.length == 0) {
        this.$message({
          type: "info",
          message: "您暂未选择任何数据！",
          duration: 3000
        });
      } else {
        this.$confirm("确定重置可用额度吗？", "提示信息", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
          .then(() => {
            axiosApi(
              "/superc/availableCredit/reset",
              {
                id: this.$route.params.companyId,
                cooperativeCustomerIds: this.cooperativeCustomerIds,
                companyId: this.$route.params.companyId
              },
              res => {
                this.achieve();
                this.$message({
                  type: "success",
                  message: "重置成功",
                  duration: 3000
                });
              },
              1,
              10,
              res => {
                this.$message({
                  type: "info",
                  message: res.data.message,
                  duration: 3000
                });
              }
            );
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消重置"
            });
          });
      }
    },
    openCustomer(Form) {
      this.customer = true;
      if (this.$refs[Form] !== undefined) {
        this.$refs[Form].resetFields();
      }
    },
    addCustomer(Form) {
      //增加酒店协议公司
      this.$refs[Form].validate(valid => {
        if (valid) {
          axiosApi("/superc/customer/selectListWithStoreName",{hotelId: sessionStorage.traderId,loginName: this.Form.loginName,},
            res => {
              if(res.data.data.length>0){
                let that = this;
                this.$confirm("该账户已经是该酒店普通客户，是否升级为协议客户？", "提示信息", {
                  confirmButtonText: "确定",
                  cancelButtonText: "取消",
                  type: "warning"
                }).then(() => {
                  axiosApi("/superc/cooperativeCustomer/add",{hotelId: sessionStorage.traderId,companyId: this.$route.params.companyId,loginName: this.Form.loginName,creditLine: this.Form.creditLine,customerName: this.Form.customerName},
                    res => {
                      this.achieve();
                      this.$message({
                        type: "success",
                        message: "新增成功",
                        duration: 3000
                      });
                      this.customer = false;
                    },1, 10,res => {
                      this.customer = false;
                      this.$message({
                        type: "info",
                        message: res.data.message,
                        duration: 3000
                      });
                  });
                }).catch(() => {
                  this.customer = false;
                  this.$message({
                    type: "info",
                    message: "已取消操作"
                  });
                });
              } else {
                axiosApi("/superc/cooperativeCustomer/add",{hotelId: sessionStorage.traderId,companyId: this.$route.params.companyId,loginName: this.Form.loginName,creditLine: this.Form.creditLine,customerName: this.Form.customerName},
                  res => {
                    this.achieve();
                    this.$message({
                      type: "success",
                      message: "新增成功",
                      duration: 3000
                    });
                    this.customer = false;
                  },1, 10,res => {
                    this.customer = false;
                    this.$message({
                      type: "info",
                      message: res.data.message,
                      duration: 3000
                    });
                  });
              }
            },1, 10,res => {
              this.customer = false;
              this.$message({
                type: "info",
                message: res.data.message,
                duration: 3000
              });
            });
        }
      });
    },
    //删除
    isDelete(val) {
      let that = this;
      this.$confirm("您是否要删除该账号？", "提示信息", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
          const api = "/superc/cooperativeCustomer/delete";
          axiosApi(api,{id: val.row.id}, res => {
              this.$message({
                type: "success",
                message: "操作成功!",
                duration: 1000
              });
              that.achieve();
            },1, 10, res => {
              this.$message({
                type: "info",
                message: res.data.message,
                duration: 3000
              });
            }
          );
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作"
          });
        });
    },
    showBindDialog(){
      if(this.cooperativeCustomerIds.length>0){
        this.setSales = true;
        this.getSalesList()
      }else {
        this.$message({
						type: 'info',
						message: '请先选择需要分配的数据',
						duration: 3000
					});
      }
    },
    getSalesList(){//获取未绑定的销售员
      axiosApi("/superc/hotelStore/selectPage", {state: 2,name: this.searchForm.companyName }, (res) => {
					this.unBindData = res.data.data.elements ? res.data.data.elements : []
          this.totalCount = res.data.data.totalCount
          this.currentPage = res.data.data.pageIndex
				}, this.currentPage, 10, (res) => {
					this.$message({
						type: 'info',
						message: res.data.message,
						duration: 3000
					});
				});
    },
    handleChange(val) {
      this.currentPage = val;
      this.getSalesList()
    },
    bind(scope){
      const body = {
          bind:1,
          companyId :this.$route.params.companyId,
          supercStoreId: scope.row.id,
          cooperativeCustomerIds:this.cooperativeCustomerIds
      }
      axiosApi("/superc/cooperativeCustomer/batchBind", body, (res) => {
        this.$message({
						type: 'success',
						message: '绑定成功',
						duration: 3000
					});
          this.setSales = false
          this.achieve()
				}, 1, 10, (res) => {
					this.$message({
						type: 'error',
						message: res.data.message,
						duration: 3000
					});
				});
    },
    unBind(){
      console.log(this.selectedAry)
      if(this.cooperativeCustomerIds.length>0 && this.selectedAry.length > 0){
          const body = {
              bind:0,
              companyId :this.$route.params.companyId,
              cooperativeCustomerIds: this.cooperativeCustomerIds
          }
          axiosApi("/superc/cooperativeCustomer/batchBind", body, (res) => {
            this.$message({
                type: 'success',
                message: '解绑成功',
                duration: 1500
              });
              this.achieve()
            }, 1, 10, (res) => {
              this.$message({
                type: 'info',
                message: res.data.message,
                duration: 3000
              });
            });
        
      } else if (this.selectedAry.length === 0 && this.cooperativeCustomerIds.length>0) {
        this.$message({
          type: 'info',
          message: '选中的客户无需解绑销售员',
          duration: 1500
        });
      } else {
        this.$message({
          type: 'info',
          message: '请先选择需要解绑的数据',
          duration: 1500
        });
      }
    }
  }
}
</script>

<style lang="less">
#customerDetail {
  .content {
    .el-form--inline {
      width: 650px;
      .el-form-item__label {
        font-size: 12px;
        width: 60px;
      }
      .order-datatimerange {
        width: 240px;
      }
    }
    .el-button + .el-button {
      margin-left: 0;
    }
    .el-table .cell {
      text-align: center;
      padding: 10px 0;
      img {
        width: 60px;
        height: 60px;
        margin-left: 10px;
        margin-right: 10px;
      }
      .width-130 {
        width: 130px;
        text-align: left;
      }
      .el-button {
        height: 20px;
      }
    }
    .el-form-item__label {
      width: 100px;
    }
    .reject {
      .el-dialog {
        width: 900px;
        .el-dialog__body {
          min-height: 420px;
        }
      }
      .el-form-item__error {
        margin-left: 100px;
      }
    }
  }
  .el-dialog {
    min-height: 300px !important;
  }
  .el-form-item__error {
    margin-left: 110px !important;
  }
  .bindSales{
			.tc{
				text-align: center;
			}
			.el-dialog{
				width: 900px;
				min-height: 700px !important;
			}
			.el-dialog__body{
				padding: 20px;
				.search{
					.el-form-item__label{
						width: 80px;
					}
					.el-form--inline{
						width:350px !important;
					}
				}
			}
    }
    .width-50 {
      width: 50px !important;
    }
}
</style>