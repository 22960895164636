<template>
    <el-dialog id="addPayAccount" title="添加提现账号" :visible.sync="visible" @close="goPage" width="500px">
        <el-form label-width="158px" :model="form" ref="form1" :rules="rules">
            <el-form-item label="企业名称:" prop="companyName" required>
                <span>{{form.companyName | hide}}</span>
                <!--<el-input v-else v-model.trim="form.name" placeholder="请输入企业名称" :maxlength="32"></el-input>-->
            </el-form-item>
            <el-form-item label="营业执照注册号:" prop="companyNo" required>
                <span>{{form.companyNo | hide}}</span>
                <!--<el-input v-else v-model.trim="form.id" placeholder="请输入营业执照注册号" :maxlength="18"></el-input>-->
            </el-form-item>
            <el-form-item label="支付宝账号:" prop="payAccount">
                <el-input v-model.trim="form.payAccount" placeholder="请输入支付宝账号" :maxlength="30"></el-input>
            </el-form-item>
            <el-form-item label="预留手机号:" prop="phone">
                <el-input v-model.trim="form.phone" placeholder="请输入手机号" :maxlength="11"></el-input>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button type="danger" @click="sure">确定</el-button>
            <el-button @click="goPage">取消</el-button>
        </div>
    </el-dialog>
</template>

<script>
    import { axiosApi, basePath } from "~/api/api";
    import { mapActions, mapMutations } from "vuex";
    export default {
        props: ["dialogvisible"],
        data() {
            return {
                flag:false,
                time: 0,
                disabled: true,
                visible: false, //彈窗是否关闭
                form: {
                    companyName:'',
                    companyNo:'',
                    payAccount:'',
                    phone:'',
                },
                rules: {
                    payAccount:[
                        {
                            required: true,
                            message: "请输入支付宝账号"
                        },
                        {
                            pattern: /(^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$)|(^1\d{10}$)/,
                            message: "请输入正确的支付宝账号"
                        }
                    ],
                    phone: [
                        {
                            required: true,
                            message: "请输入预留手机号"
                        },
                        {
                            pattern: /^1\d{10}$/,
                            message: "请输入正确的手机号"
                        }
                    ],
                }
            };
        },
        filters:{
          hide(params) {
              if (params.length > 1) {
                  let str = '$1*'
                  for (let i=0;i<params.length-3;i++){
                      str+='*'
                  }
                  str+='$2'
                  return params.replace(/^(.).*(.)$/, str)
              } else {
                  return params + '*'
              }
          }
        },
        mounted() {
            this.$nextTick(() => {
                this.getStoreInfo()
            });
        },
        watch: {
            dialogvisible:{
                handler: function(val) {
                    this.visible = val
                    this.flag = false
                },
                deep: true,
                immediate:true

            },
            "form.phone": {
                handler: function(a) {
                    let reg = /^1\d{10}$/;
                    if (reg.test(a)){
                        this.disabled = false
                    }
                },
                deep: true
            }
        },
        methods: {
            getStoreInfo(){
                axiosApi("/trader/findStoreInfo/select", {traderId: sessionStorage.traderId}, (res)=>{
                    this.form.companyName = res.data.data.storeName;
                    this.form.companyNo = res.data.data.licenseNum;
                }, 1, 10,err=>{
                    this.$message.error(err.data.message)
                })
            },
            getCode(){
                this.time = 5
                let time = setInterval(()=>{
                    this.time--
                    if (this.time == 0){
                        clearInterval(time)
                    }
                },1000)
            },
            goPage(){
                this.$refs['form1'].resetFields();
                this.$emit('closedialog',this.flag)
            },

            sure() {
                this.$refs.form1.validate(valid => {
                    if (valid ) {
                        let body = {
                            userId: sessionStorage.traderId,
                            corporateName: this.form.companyName,
                            corporateLicenseNumber: this.form.companyNo,
                            accountName: this.form.payAccount,
                            mobile: this.form.phone
                        }
                        axiosApi("/cbs/pay/bankAccountAdd", body, res => {
                            this.$message.success('添加成功')
                            this.flag = true
                            this.goPage()
                        },1,10,err=>{
                            this.$message.error(err.data.message)
                        })
                    } else {
                        console.log("fail");
                    }
                });

            }
        }
    };
</script>

<style lang="less">
    #addPayAccount {
        .el-dialog{
            min-height: auto;
        }
        .wa {
            width: auto;
            padding: 0 5px;
        }
        .el-col-10 {
            width: 140px;
            .el-select .el-input--mini {
                width: 100px;
            }
        }
    }
</style>