<template>
	<div id="article_e" class="">
		<div class="title-top bg-f">
			<!-- tab -->
      <el-tabs v-model="active">
        <el-tab-pane label="企业新闻" name="1"></el-tab-pane>
        <el-tab-pane label="新闻类别" name="2"></el-tab-pane>
      </el-tabs>
		</div>

		<div class="bg-f mt-5 pb-50 pt-30 pl-30 pr-30">
			<News v-if="active=='1'"/>
			<ClassInfo v-if="active=='2'"/>
		</div>
		
	</div>

</template>

<script>
	import { axiosApi, basePath,basePath1 } from "~/api/api"
	import News from "./itemNews/news";
	import ClassInfo from "./itemNews/classInfo";
	export default {
		components:{
			News,
			ClassInfo
		},
		data() {
			return {
				active:'1',
			}
		},
		methods: {}
	}
</script>

<style lang="less">
	#article_e {
		
	}
</style>
