import {axiosApi, basePath} from "~/api/api";
import m from "~/main";

const clientType = {
    namespaced: true,
    state: {
        isDealerUser: null,
        isCityPartner:null,
        erpType:null,
        isSuperCityPartner:null,//超级城市拍档
        isChildCompany:null,//子公司
        isParentCompany:null,//总公司
        basicLoginInfo:{
            departmentName:"",
            clientIp:"",
            gmtLogin:0
        },
        optionsType: [], // 客户类型
    },

    mutations: {
        setType(state, payload) {
            console.log(state,payload,'0')
            state.isDealerUser = payload.isDealerUser;
            state.isCityPartner = payload.isCityPartner;
        }
    },
    actions:{
        resolveIsSalesDepartmentLeaderWithCache({ state },callback) {
            let isSalesDepartmentLeader = sessionStorage.getItem('isSalesDepartmentLeader')
            if (isSalesDepartmentLeader !== null) {
                callback(isSalesDepartmentLeader)
            } else {
                axiosApi("/sales/employee/app/selectOneByLoginName", {"loginName": sessionStorage.getItem('loginName')}, r => {
                    sessionStorage.setItem('isSalesDepartmentLeader', r.data.data.isDepartmentLeader)
                    callback(r.data.data.isDepartmentLeader)
                })
            }
        },
        getUserInfo({ state }, payload) {
            console.log(payload,'payload');
            axiosApi("/foundation/employee/selectOneByLoginName", payload.body, (res) => {
                console.log(res,'data');
                console.log(res.data.data.supplierType)
                sessionStorage.setItem('supplierType', res.data.data.supplierType)
                let a = res.data.data;
                if (a.loginSuccessCount == 1 && a.isAdmin == 1 && a.isHotel != 1) {
                    sessionStorage.setItem('isOne', 1);
                }
                state.basicLoginInfo = {
                    departmentName: res.data.data.departmentName,
                    clientIp: res.data.data.clientIp,
                    gmtLogin: res.data.data.gmtLogin
                }
                //console.log(a)
                //缓存登录次数，从1开始
                // if(m.$cookies.isKey('userName')){
                //     m.$cookies.remove('userName')
                // }
                m.$cookies.set('userName', a.loginName, null, '/')
                sessionStorage.setItem('loginName', a.loginName);
                sessionStorage.setItem('mobile', a.mobile);
                sessionStorage.setItem('traderId', a.traderId); //当前 店铺/供应商 ID
                sessionStorage.setItem('id', a.id); //当前用户ID
                sessionStorage.setItem('isAdmin', a.isAdmin); //是否是管理员
                sessionStorage.setItem('isDepartmentLeader', a.isDepartmentLeader); //是否未部门领导
                sessionStorage.setItem('employeeName', a.employeeName); //员工人员
                sessionStorage.setItem('departmentPath', a.departmentPath); //部门id列表
                sessionStorage.setItem('managerId', a.managerId); //员工id
                sessionStorage.setItem('isHotel', a.isHotel); //是否是酒店用户
                state.isDealerUser = a.dealerUser;
                state.isCityPartner = a.supplierType == 4;
                state.isSuperCityPartner = a.supplierType == 3;
                state.isChildCompany = a.supplierType == 2;
                state.isParentCompany = a.supplierType == 1;
                state.erpType = a.erpType;
                // sessionStorage.setItem('isDealerUser', a.dealerUser); //是否是经销商
               
                if (payload.isLogin) {
                    if (sessionStorage.getItem('lastUrl')) {
                        m.$router.push({
                            path: sessionStorage.getItem('lastUrl')
                        })
                        sessionStorage.removeItem('lastUrl')
                    } else {
                        m.$router.push({
                            path: sessionStorage.getItem('isOne') == 1 ? (basePath + '/welcome') : (basePath + "/accountCenter")
                            // path: basePath + "/shop/shopIndex"
                        })
                    }
                }else{
                    setTimeout(() => {
                        let openUrl = m.$store.state.homeSearch.mloginDialogUrl;
                        if (openUrl) {
                            m.$store.commit("setloginDialog", {
                                type: false,
                                url: ""
                            });
                            m.$router.push({
                                path: basePath + openUrl
                            });
                        }
                        location.reload(true);
                    }, 1000);
                }
            }, 0, 0, err => {
                m.$alert(err.data.message, '提示信息', {
                    confirmButtonText: '确定'
                })
            })
        },
        // 获取客户类型
        getClientType({ state }, payload) {
            axiosApi('/trader/clientType/query',{},res=>{
                let data = res.data.data;
                if(res.data.code="000000") {
                    
                    data.forEach(item=>{
                        item.value = item.id;
                        item.label = item.name;
                    })
                    state.optionsType = data
                }
            })
        },
    }
};
export default clientType
