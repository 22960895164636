/**
 * 去除首位空格
 * @param {String} 字符串
 */
function trimStr(str){
  return str.replace(/(^\s*)|(\s*$)/g,"")
}

/**
 * 抬头公司名称验证规则 
 * @param {*} rule 
 * @param {*} value 
 * @param {*} callback 
 */
export const checkcompanyname = (rule, value, callback) => {
//console.log(rule)
  let con = trimStr(value)
  const re = /^[\u4e00-\u9fa5a-zA-Z]{1,30}$/
  if(con.length == 0) {
    return callback(new Error('请输入公司名称'))
  } else if(!re.test(con)){
    return callback(new Error('公司名称为不超过30个字符的汉字和字母组合'))
  } else {
    callback()
  }
}

export const checktaitou = (rule, value, callback) => {
  let con = trimStr(value)
  const re = /^[\u4e00-\u9fa5a-zA-Z]{1,30}$/
  if(con.length == 0) {
    return callback(new Error('请输入发票抬头'))
  } else if(!re.test(con)){
    return callback(new Error('发票抬头为不超过30个字符的汉字和字母组合'))
  } else {
    callback()
  }
}

export const activityname = (rule, value, callback) => {
  if(trimStr(value).length <= 64 && trimStr(value).length > 0) {
    callback()
  } else if(trimStr(value).length == 0){
    return callback(new Error('请输入活动名称'))
  } else {
    return callback(new Error('不得输入超过64个字符'))
  }
}

/**
 * 手机号码验证规则
 * @param {*} rule 
 * @param {*} value 
 * @param {*} callback 
 */
export const checkphonenum = (rule, value, callback) => {
  let tel = trimStr(value)
  if(tel.length == 0) {
    callback(new Error('请输入注册电话'))
  } else {
    const re = /^\d{7,12}$/
    if(!re.test(tel)) {
      return callback(new Error('请输入正确的注册电话'))
    } else {
      callback()
    }
  }
}

/**
 * 纳税人识别号规则
 * @param {*} rule 
 * @param {*} value 
 * @param {*} callback 
 */
export const taxpayerIdNo = (rule, value, callback) => {
  let tel = trimStr(value)
  const re = /^[0-9A-Z]{15,20}$/
  if(tel.length == 0) {
    return callback(new Error('请输入纳税人识别号'))
  } else if(!re.test(tel)) {
    callback(new Error('请输入正确的纳税人识别号'))
  } else {
    callback()
  }
}

/**
 * 注册地址验证
 * @param {*} rule 
 * @param {*} value 
 * @param {*} callback 
 */
export const registerAddress = (rule, value, callback) => {
  let tel = trimStr(value)
  const re = /^\S{4,50}$/
  if(tel.length == 0) {
    return callback(new Error('请输入注册地址'))
  } else if (!re.test(tel)) {
    return callback(new Error('注册地址为4-50个字符'))
  } else {
    callback()
  }
}

/**
 * 开户银行
 * @param {*} rule 
 * @param {*} value 
 * @param {*} callback 
 */
export const bankDeposit = (rule, value, callback) => {
  let tel = trimStr(value)
  const re = /^[\u4e00-\u9fa5]{4,30}$/
  if(tel.length == 0) {
    return callback(new Error('请输入开户银行'))
  } else if(!re.test(tel)) {
    return callback(new Error('请输入正确的开户银行'))
  } else {
    callback()
  }
}


/**
 * 银行账号
 * @param {*} rule 
 * @param {*} value 
 * @param {*} callback 
 */
export const bankCard = (rule, value, callback) => {
  let tel = trimStr(value)
  const re = /^[0-9]{1,25}$/
  if(tel.length == 0) {
    return callback(new Error('请输入银行账号'))
  } else if(!re.test(tel)) {
    return callback(new Error('请输入正确的银行账号'))
  } else {
    callback()
  }
}


/**
 * 手机号码(移动)
 * @param {*} rule 
 * @param {*} value 
 * @param {*} callback 
 */
export const tel = (rule, value, callback) => {
  let tel = trimStr(value)
  const reg = /^1\d{10}$/;
  if(tel.length == 0) {
    return callback(new Error('请输入手机号码'))
  } else if(!reg.test(tel)) {
    return callback(new Error('请输入正确的手机号码'))
  } else {
    callback()
  }
}