<template>
    <div id="multipleImageUpload" class="media font-14  clearfix" v-loading="loading">
        <ul class="clearfix fl goodsPic">
            <li class="fl mr-10" :style="{width:width + 'px',height:height + 'px'}" v-for="(item,i) in cloneDialogImageUrlList" :key="item.url">
                <img :src="item.url"  alt="">
                <div class="iconOpt">
                    <i v-if="preview"  @click="preView(i)" class="el-icon-view"></i>
                    <i @click="delItem(i)" class="el-icon-delete"></i>
                </div>
            </li>
        </ul>
        <!-- :show-file-list="false" -->
        <el-upload class="fl" :style="{width:width + 'px',height:height+'px'}" :class="cloneDialogImageUrlList.length>=max ? 'fileList' : ''"
                   action="/sunrise-gateway/oss/ossUpload"
                   :show-file-list="false"
                   :multiple="multiple"
                   :file-list="cloneDialogImageUrlList"
                   list-type="picture-card"
                   :on-preview="handlePictureCardPreview"
                   :on-success="handleAvatarSuccess"
                   :before-upload="beforeAvatarUpload"
                   :on-remove="handleRa"
                   :on-error="errFun">
            <i class="el-icon-plus"></i>
        </el-upload>
        <el-dialog :visible.sync="dialogVisible" width="500px">
            <img width="100%" :src="dialogImageUrl" alt>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        props:{
            //图片列表
            dialogImageUrlList:{
                type:Array,
                default:[]
            },
            //是否显示预览功能
            preview:{
                type:Boolean,
                default:true
            },
            //图片大小
            limitSize:{
                type:Number,
                default:1
            },
            //最大上传数目
            max:{
                type:Number,
                default: 6
            },
            //是否多张上传
            multiple:{
                type:Boolean,
                default:false
            },
            //宽度设置
            width:{
                type:Number,
                default: 240
            },
            //高度设置
            height:{
                type:Number,
                default: 180
            }
        },
        name: "multipleImgUpload",
        data(){
            return {
                dialogVisible:false,
                loading:false,
                dialogImageUrl:'',
                cloneDialogImageUrlList:[]
            }
        },
        mounted(){
            this.$nextTick(() => {
                console.log(document.querySelector('.el-upload--picture-card'),'0');
                document.querySelector('.el-upload--picture-card').style.width = this.width + 'px';
                document.querySelector('.el-upload--picture-card').style.height = 'auto';
                document.querySelector('.el-upload--picture-card').style.lineHeight = this.height + 'px';
            })
        },
        watch:{
            dialogImageUrlList:{
                handler:function (val) {
                    console.log(val,'val');
                    this.cloneDialogImageUrlList = (val.length>0 && val[0].url) ? val : [];
                },
                deep:true,
                immediate:true
            }
        },
        methods:{
            preView(i){
                this.dialogImageUrl = this.cloneDialogImageUrlList[i].url; //门店照片的放大预览
                this.dialogVisible = true;
            },
            delItem(i){
                this.cloneDialogImageUrlList.splice(i,1);
            },
            handlePictureCardPreview(file) {
                console.log(file, "file")
                this.dialogImageUrl = file.url; //门店照片的放大预览
                this.dialogVisible = true;

            },
            handleAvatarSuccess(res,file,filelist) { //照片
                console.log(res,file,filelist, "aa");
                let arr = [];
                setTimeout(() => {
                    filelist.map((v,i) => {
                        if(i<this.max){
                            if(v.status !== 'success'){
                                this.count++;
                            }
                            arr.push({
                                url:v.response ? v.response.data[0] : v.url,
                                status:v.status,
                                uid:v.uid
                            })
                        }
                    });
                    this.cloneDialogImageUrlList = arr;
                    this.$emit('cloneDialogImageUrlList',this.cloneDialogImageUrlList);
                    this.loading = false;
                },500);
            },
            handleRa(file, fileList) { //照片删除
                this.cloneDialogImageUrlList = fileList
            },
            beforeAvatarUpload(file) {
                this.loading = true;
                const isImage = (file.type.indexOf('png') != -1 || file.type.indexOf('jpeg') != -1 || file.type.indexOf('jpg') != -1);
                const imgSize = file.size / 1024 / 1024;
                const isLimit = this.cloneDialogImageUrlList.length<this.max;
                let bool = isImage && (imgSize>0 && imgSize<1) && isLimit;
                if(!isImage){
                    this.$message.warning('请上传格式为png、jpg的图片')
                }else if(!(imgSize>0 && imgSize<this.limitSize)){
                    this.$message.warning(`请上传大小不超过${this.limitSize}M的图片`)
                }
                if(!bool) this.loading = false;
                return bool;
            },
            errFun(err) {
                console.log(err,'err');
            },
        }
    }
</script>

<style lang="less">
#multipleImageUpload{
    .goodsPic{
        li{
            position: relative;
            border: 1px solid #ccc;

            img{
                width: 100%;
                height: 100%;
            }
            .iconOpt{
                position: absolute;
                z-index: 10;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: space-around;
                i{
                    cursor: pointer;
                    display: none;
                    font-size: 24px;
                    color: #fff;
                    line-height: 15px!important;

                    /*&:first-child{
                        left: 25%;
                    }
                    &:last-child{
                        left: 65%;
                    }*/
                }
            }
            &:hover{
                background-color: #ccc;
                i{
                    display: inline-block;
                }
            }
        }
    }
}
.media{
    .fileList{
        display: none;
        .el-upload--picture-card{
            display: none;
            height: auto;
        }
    }
}
</style>
