<template>
    <div id="shopingCar" v-loading.fullscreen="loading" ref='shopingCar'>
        <div class="tar font-16">合计: <span class="color-danger mr-30">￥{{calcTotalMoney(data)}}</span>
            <el-button :disabled="this.checkedNum>0?false:true || minimunMoqDisabled" type='danger' class="mr-45" style="width: auto;padding: 0 15px;min-width: 90px"
                       @click="handleShopCarVerify">{{minimunMoqString ? minimunMoqString : data[0].checkedAreas.length > 0 ?  '结算' : minimunmoqDesc}}
            </el-button>
        </div>
        <div class="line mt-20"></div>
        <div class="plr-45 mt-20 mb-50">
            <ul class="clearfix ul1">
                <li>商品</li>
                <li>规格</li>
                <li>单价</li>
                <li style="width: 130px; margin-left: 2px" class="tac">数量</li>
                <li class="w100 ml-50 tac">小计</li>
                <li class="w100 ml-30 tac">操作</li>
            </ul>
            <div class="mt-30" v-for="(list,index1) in data" :key="index1" v-if="data.length>0">
                <el-checkbox class="pl-50 mb-10" v-model="list.checked" :disabled="list.businessStatus !== 0"
                             @change="seller(list,index1)"><span class="ml-10">{{list.sellerName}}</span></el-checkbox>
                <storeGetCoupon :couponlist="list.cartListResVoList" :sellerId="list.sellerId"
                                v-if="list.couponSize>0"></storeGetCoupon>
                <div class="discountActiveBox">
                    <div  class="mtitle" v-if="list.activitySize > 0"  >
                        <div
                                @mouseover="discountActiveMove(list,index1)"
                                @mouseout="discountActiveOut(list,index1,$event)"
                                class="activityBok">
                            <span class="activityText">
                                活动
                            </span>
                            <div class="arrowBox" :class="{'rotate-180' : discountType}"></div>
                        </div>
                    </div>
                    <!-- 浮层内容 -->
                    <transition name="fade">
                        <div
                                @mouseover="discountType = true"
                                @mouseout="discountActiveOut(list,index1,$event)"
                                v-if="list.discountActive" class="mcontent">
                            <ul class="moneListUl" v-if="list.HuoDongList">
                                <!-- 一级树 -->
                                <li :key="k" class="moneLi" v-for="(v,k) in list.HuoDongList" v-if="v.messages.length">
                                    <div>
                                        <!-- 判断是否显示 满减，满赠图标 -->
                                        <el-tag v-if="v.activityType === 2" class="mtype" type="danger">满减</el-tag>
                                        <el-tag v-else-if="v.activityType == 1" class="mtype" type="danger">满赠</el-tag>
                                        <el-tag v-else class="mtype" type="danger">换购</el-tag>
                                        <span :title="v.activityType != 2?v.pcActivityName:v.activityName"
                                              class="text oneText">{{v.activityType != 2?v.pcActivityName:v.activityName}}</span>
                                    </div>
                                    <!-- 二级树 -->
                                    <ul class="mtwoListUl">
                                        <!-- 满减 -->
                                        <template v-if="v.activityType === 2">
                                            <li :key="k1" v-for="(v1,k1) in v.messages">
                                                <span>{{v1}}</span>
                                            </li>
                                        </template>
                                        <!-- 满赠 -->
                                        <template v-else>
                                            <li class="flex" :key="k1" v-for="(v1,k1) in v.messages">
                                                <span class="mmcontent">{{v1}}</span>
                                            </li>
                                        </template>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </transition>
                </div>
                <el-checkbox-group class="bd" v-model="list.checkedAreas" @change="goods(index1)">
                    <ul class="pt-20 ul2 clearfix font-14">
                        <li v-for="(item,index) in list.cartListResVoList" :key="index" class="bbd pl-50 clearfix"
                            :class="(item.effectiveMark && list.businessStatus === 0)?'':'color-9'">
                            <el-checkbox v-if="item.effectiveMark && list.businessStatus === 0 && item.hotelSkuState !== 1"
                                         :label="item.id"></el-checkbox>
                            <i v-else class="icon-no-affect iconfont fl mr-30" style="font-size: 60px;"></i>
                            <template v-if="item.effectiveMark && list.businessStatus === 0">
                                <router-link :to="{path:'/customer/shop/goodsDetails/'+item.skuNo}">
                                    <img v-if="item.skuImage" class="icon-80 fl mr-10 " :src="item.skuImage"/>
                                    <img v-else src="../../assets/goods.jpg" class="icon-80 fl mr-10" alt=""/>
                                </router-link>
                            </template>
                            <template v-else>
                                <img v-if="item.skuImage" class="icon-80 fl mr-10 disabledImg" :src="item.skuImage"/>
                                <img v-else src="../../assets/goods.jpg" class="icon-80 fl mr-10" alt=""/>
                            </template>
                            <div class="fl width-230">
                                <p class="lh-14 text-tow-overflow">
                                    <router-link :to="{path:'/customer/shop/goodsDetails/'+item.skuNo}"
                                                 v-if="item.effectiveMark && list.businessStatus === 0">
                                        <span class="color-3">{{item.skuName}}</span>
                                    </router-link>
                                    <span v-else class="color-9">{{item.skuName}}</span>
                                </p>
                                <p class="lh-14 mt-20"><span
                                        :class="(item.effectiveMark && list.businessStatus === 0)?'':'color-9'"></span>
                                </p>
                            </div>
                            <div class="fl mwi">
                                1*{{item.specInfoNum}}
                            </div>
                            <div :style="{marginLeft:'12px', height:'10px'}" class="fl w120">
                                <!-- 判断商品是否有效 -->
                                <div>
                                    <!-- <span v-if="(item.effectiveMark && list.businessStatus === 0)">￥{{ item.showUnitPrice | fmoney }}</span>
                                    <span class="color-9" v-else>￥{{item.msrp| fmoney }}</span> -->
									<!-- 2020.4.3修改 -->
									<span v-if="item.effectiveMark">￥{{ item.showUnitPrice | fmoney }}</span>
                                    <span v-if="item.effectiveMark">/{{item.showUnitName}}</span>
                                </div>
                                <!-- <span class="color-9" :class="{'font-12':  !isHotel}"
                                      v-if="isHotel&&(item.effectiveMark && list.businessStatus === 0)">￥{{ item.showUnitPromotionPrice | fmoney }}/{{item.showUnitName}}</span> -->
                            </div>
                            <div style="margin-left: 20px;min-width: 160px;height:20px" class="fl">
                                <div v-if="(item.effectiveMark && list.businessStatus === 0)">
                                    <el-input-number size="small"
                                        v-model="item.num"
                                        type='number'
                                        @change="handleChange(item,index1,index)"
                                        :min="1"
                                        
                                        :step="1"></el-input-number>
                                        <!-- :min="!item.isIntegerMultiple ? item.minimunMoq  :  Math.ceil(item.minimunMoq / item.specInfoNum)" -->
                                    <span>{{item.showUnitName}}</span>
                                </div>
                                <!-- <p v-else class="color-9 tac" style="width: 130px;">
                                    {{item.num}}{{item.sellingUnitName}}
                                </p> -->
                            </div>
                            <!-- <div :style="{marginLeft:'36px'}" class="fl lh-20 w80 tac"
                                 v-if="(item.effectiveMark && list.businessStatus === 0)"><span
                                    :class="(item.effectiveMark && list.businessStatus === 0)?'':'color-9'">￥{{ ((isHotel ? item.discountTotalPrice : item.priorityTotalPrice) || 0)| fmoney }}</span>
                            </div>
                            <div :style="{marginLeft:'36px'}" class="fl lh-20 w80 tac" v-else><span
                                    :class="(item.effectiveMark && list.businessStatus === 0)?'':'color-9'">11￥{{item.msrpTotal||0 | fmoney }}</span>
                            </div> -->
							<div style="margin-left:36px;height:20px" class="fl lh-20 w80 tac" >
                                <span v-if="item.effectiveMark" :class="(item.effectiveMark && list.businessStatus === 0)?'':'color-9'">￥{{ ((isHotel ? item.discountTotalPrice : item.priorityTotalPrice) || 0)| fmoney }}</span>
							</div>
                            <div class="fl ml-20 w80 tac">
                                <div>
                                    <el-button type='text' :disabled="!item.effectiveMark || list.businessStatus !== 0"
                                               class="lh-14 cursor width-auto" @click='handleAddCollectGoods(item)'>
                                        <span :class="(item.effectiveMark && list.businessStatus === 0)?'':'color-9'">加入收藏夹</span>
                                    </el-button>
                                </div>
                                <el-button type='text' class="lh-14 tac mt-20 width-auto" @click="deleteCart(item.id)">
                                    <span>删除</span></el-button>
                            </div>
                        </li>
                    </ul>
                </el-checkbox-group>
            </div>
            <el-dialog :visible.sync="deleteVisible" class="deleteGoodDialog" title="提示信息">
                <div class="tac">真的要从购物车删除商品吗？</div>
                <div slot="footer" class="dialog-footer">
                    <el-button type="danger" @click="handleDeleteDialog">确 定</el-button>
                    <el-button @click="deleteVisible = false">取 消</el-button>
                </div>
            </el-dialog>
            <el-dialog :visible.sync="addVisible" class="deleteGoodDialog" title="提示信息">
                <div class="tac">是否将该商品添加至收藏夹？</div>
                <div slot="footer" class="dialog-footer">
                    <el-button type="danger" @click="handleAddDialog">确 定</el-button>
                    <el-button @click="addVisible = false">取 消</el-button>
                    <div class="mt-10">
                        <el-checkbox v-model="addChecked">不再提示</el-checkbox>
                    </div>
                </div>
            </el-dialog>
            <div v-if="data.length == 0" class="tac" v-cloak>
                <img class="icon-100 mt-120" src="../../assets/empty.png"/>
                <p class="color-danger cursor mt-30 mb-120">
                    <router-link :to="{path:'/customer/shop/shopIndex'}"><span class="color-danger">啥都没有，去首页看看></span>
                    </router-link>
                </p>
            </div>
            <affix ref="affix" :offsetBottom="0">
                <ul class="ul3 mt-20 bg-e" :class="{'fixed':isFixed}">
                    <li class="pl-50">
                        <el-button type="text" style="width: auto; height: auto; background: rgb(238, 238, 238);" :disabled="!checkedNum>0" @click="batchDelete">批量删除</el-button>
                        <el-button type='text' style="width: auto; height: auto; background: rgb(238, 238, 238);" :disabled="!checkedNum>0" class="cursor ml-50" @click="batchCollection">批量收藏</el-button>
                        <span class="ml330">已选<span class="color-danger"> {{this.checkedNum}} </span>件商品</span>
                        <span>合计:<span class="color-danger font-25 lh-20"> ￥{{calcTotalMoney(data)}}</span>（不含运费）</span>
                        <el-button type="danger" :disabled="(this.checkedNum>0?false:true) || minimunMoqDisabled" class="fr" style="width: auto;padding: 0 15px;min-width: 100px"
                                   @click="handleShopCarVerify">{{minimunMoqString ? minimunMoqString : data[0].checkedAreas.length > 0 ?  '结算' : minimunmoqDesc}}
                        </el-button>
                    </li>
                </ul>
            </affix>
        </div>
    </div>
</template>

<script>
    import {axiosApi, basePath} from '../../api/api'; // 导入API
    import storeGetCoupon from "../../components/storeGetCoupon.vue";
    import affix from './../hotel/affix.vue';

    export default {
        components: {
            storeGetCoupon,
            affix,
        },
        data() {
            return {
                checkAll: false,
                queryData: {
                    operatorType: 1, // 添加者类型 1-商户本身 2-销售顾问 3-城市经理人
                    buyerId: sessionStorage.traderId
                },
                countSkuNum: 0, //商品总件数
                countTotalNum: 0, //商品总数量
                num1: 1,
                data: [], //购物车数据
                nodata: [],
                checkedNum: 0, //选中的商品件数(初始0件)
                checkedId: [], //选中的购物车ID
                couponVisible: false, //优惠券Boolean
                couponList: [], //优惠券列表
                loading: false,
                couponStateList: [], //优惠券状态列表
                receiveCouponNum: 0, //优惠券领取个数
                isFixed: false, //固定定位
                clientHeight: null,
                scrollTop: null, //滚动条距离顶部的距离
                shopingCarDivHeight: null, //初始id=shopingcar的高度
                deleteChecked: false, //删除商品不再提示
                deleteVisible: false,
                deleteId: null, //删除商品的id
                addVisible: false, //添加至收藏夹
                addChecked: false, //添加至收藏夹不再提示
                addItem: null, //添加至收藏夹的id
                discountType: false,
                //酒店业务
                isHotel: true,  //是否是酒店用户
                isAdmin: sessionStorage.isAdmin == 1 ? true : false,
                minimunMoqDisabled: true,
                minimunMoqString: '结算',
                minimunmoqDesc:'结算'
            };
        },
        watch:{
          'data':{
              handler:function(){
                  console.log(11);
                  this.getMinimunmoq()
              },
              deep: true,
              // immediate: true
            },
        },
        mounted() {
            this.isHotel = sessionStorage.getItem("isHotel") === '1' //session判断是否是酒店用户
            this.$nextTick(() => {
                this.getCartData()
                // this.getMinimunmoq()
                this.mousewheel()
            })
        },
        computed:{
            
        },
        methods: {
            recordPrice(item){
                // isHotel?item.discountTotalPrice:item.priorityTotalPrice)||0 
                // if(this.isHotel){
                //     return item.selectedSalesUnitType == 3 ? item.discountPrice : item.discountTotalPrice;
                // }else {
                //     return item.selectedSalesUnitType == 3 ? item.priorityPrice : item.priorityTotalPrice;
                // }
            },
            // 活动移入事件
            discountActiveMove: function (row, index) {
                // 获取优惠活动数据
                let sendFn = (callback) => {
                    let allSellerId = row.sellerId;
                    let buyerId = sessionStorage.getItem('traderId');
                    let sendUrl = '/marketing/visibleActivity/page';
                    let sendObj = {
                        sellerId: allSellerId,
                        buyerId: buyerId,
                    };
                    axiosApi(sendUrl, sendObj, (res) => {
                        callback && callback();
                        let data = res.data.data.elements;
                        // 处理数据  activityType === 2 满减
                        // console.log(data);
                        /* 一级 */
                        let arr2 = data.map((v, k) => {
                            // 如果是 满赠
                            if (v.activityType === 1 || v.activityType === 4) {
                                let newLeveList = [];
                                /* 二级 */
                                v.levelList = v.levelList.map((v1, k1) => {
                                    v1.giftList = v1.giftList || [];
                                    /* 三级 */
                                    v1.giftList.map((v2, k2) => {
                                        //let name = k2 === 0 ? `${v1.activityLevelName}赠品：`
                                        // 是否显示 订购满3件 赠品：字段
                                        let type = k2 === 0 ? true : false;
                                        newLeveList.push({
                                            activityLevelName: v1.activityLevelName,
                                            activityLevelText: `${v2.name} x${v2.quantity} ${v2.giftUnit || ''}`,
                                            type: type,
                                        });
                                    });
                                    return v1;
                                });
                                v.levelList = newLeveList;
                            }
                            return v;
                        });

                        this.$set(row, 'HuoDongList', arr2);
                    });
                }
                if (!row.HuoDongList) {
                    sendFn(() => {
                        this.discountType = true;
                        this.$set(row, 'discountActive', true);
                    });
                } else {
                    this.discountType = true;
                    this.$set(row, 'discountActive', true);
                }
            },
            // 活动移除事件
            discountActiveOut: function (row, index, event) {
                this.discountType = false;
                setTimeout(() => {
                    if (this.discountType === false) {
                        this.$set(row, 'discountActive', false);
                    }
                }, 100);
            },
            mousewheel(val) {
                this.clientHeight = document.documentElement.clientHeight
            },
            batchDelete() { //批量删除
                let ids = []
                let body = []
                let temp = false
                this.data.forEach((item, index) => {
                    if (item.checkedAreas.length > 0) {
                        temp = true
                        item.checkedAreas.forEach((item2, index2) => {
                            item.cartListResVoList.forEach((item3, index3) => {
                                if (item2 == item3.id) {
                                    body.push(item3)
                                }
                            })
                        })
                    }
                })
                if (!temp) {
                    const h = this.$createElement
                    this.$msgbox({
                        title: '提示信息',
                        message: h('p', null, '请选择需要从购物车删除的商品! '),
                        showCancelButton: false,
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        beforeClose: (action, instance, done) => {
                            if (action === 'confirm') {
                                done()
                            } else {
                                done()
                            }
                            delete instance.$slots.default;
                        }
                    })
                    return false;
                }
                body.forEach((item) => {
                    ids.push(item.id)
                })
                const h = this.$createElement
                this.$msgbox({
                    title: '提示信息',
                    message: h('p', null, '真的要从购物车删除选中的' + ids.length + '个商品吗'),
                    showCancelButton: true,
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'info',
                    beforeClose: (action, instance, done) => {
                        if (action === 'confirm') {
                            this.checkedNum = 0
                            this.orderCartDelete({
                                ids: ids
                            })
                            done()
                        } else {
                            done();
                        }
                        delete instance.$slots.default;
                    }
                })

            },
            batchCollection() { //批量收藏
                let body = []
                let temp = false
                this.data.forEach((item, index) => {
                    if (item.checkedAreas.length > 0) {
                        temp = true
                        item.checkedAreas.forEach((item2, index2) => {
                            item.cartListResVoList.forEach((item3, index3) => {
                                if (item2 == item3.id) {
                                    body.push(item3)
                                }
                            })
                        })
                    }
                })
                if (!temp) {
                    const h = this.$createElement
                    this.$msgbox({
                        title: '提示信息',
                        message: h('p', {
                            style: 'text-align:center'
                        }, '请选择需要收藏的商品! '),
                        showCancelButton: false,
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        beforeClose: (action, instance, done) => {
                            if (action === 'confirm') {
                                done()
                            } else {
                                done()
                            }
                            delete instance.$slots.default;
                        }
                    })
                    return false;
                }

                let willCollections = []
                body.forEach((item) => {
                    willCollections.push({
                        buyerId: item.buyerId,
                        skuNo: item.skuNo,
                        classifyId: item.classifyId,
                        brandId: item.brandId
                    })
                })
                const h = this.$createElement
                this.$msgbox({
                    title: '提示信息',
                    message: h('p', {
                        style: 'text-align:center'
                    }, '是否将选中的' + willCollections.length + '个商品添加至收藏夹？'),
                    showCancelButton: true,
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'info',
                    beforeClose: (action, instance, done) => {
                        if (action === 'confirm') {
                            this.addCollectGoods(willCollections)
                            done();
                        } else {
                            done();
                        }
                        delete instance.$slots.default;
                    }
                })
            },
            handleShopCarVerify() {
                let traderIdList = [];
                this.data.map(item => {
                    item.checkedAreas.length > 0 && traderIdList.push(item.sellerId);
                })
                this.loading = true;
                let sendUrl = '/trader/storesBusinessStatus/select';
                let sendObj = {
                    traderIdList: traderIdList,
                };
                // 校验店铺是否关闭，打烊
                axiosApi(sendUrl, sendObj, (res) => {
                    let data = res.data;
                    let verify = data.data.leavingStore || data.data.closingStore;
                    if (!verify) {
                        this.handleShopCar();
                    } else {
                        this.loading = false;
                        let message = data.data.closingStore ?
                            `店铺已打烊！` :
                            `店铺已关闭！`;
                        this.$confirm(message, '提示', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            type: 'warning'
                        }).then(() => {
                        }).catch(() => {
                        });
                    }
                }, null, null, () => {
                    this.loading = false;
                });
            },
            handleShopCar() { //点击结算按钮操作
                axiosApi('/anon/order/cart/judge', {cartIds: this.checkedId}, (res) => {
                    this.loading = false;
                    sessionStorage.setItem('checkedShopCarIdList', JSON.stringify(this.checkedId))
                    this.$router.push(basePath + '/store/confirmOrder/1')
                }, 1, 1, (err) => {
                    this.loading = false;
                    // let h = this.$createElement;
                    // this.$msgbox({
                    //     title: '提示信息',
                    //     message: h('p', {
                    //         style: 'text-align:center'
                    //     }, err.data.message),
                    //     showCancelButton: true,
                    //     confirmButtonText: '确定',
                    //     cancelButtonText: '取消',
                    //     beforeClose: (action, instance, done) => {
                    //         done()
                    //         delete instance.$slots.default;
                    //     }
                    // })
                })
            },
            //供货商全选
            seller(obj, index1) {
                if (obj.checked) {
                    let checkedAreas = [];
                    obj.cartListResVoList.forEach((item) => {
                        console.log(item,'item')
                        if (item.effectiveMark && item.hotelSkuState !== 1) { //商品有效的时候添加
                            checkedAreas.push(item.id)
                        }
                    });
                    obj.checkedAreas = checkedAreas;
                } else {
                    obj.checkedAreas = [];
                }
                this.data.forEach((item, index) => {
                    if (index1 != index) {
                        item.checkedAreas = [];
                        item.checked = false;
                    } else {
                        this.checkedId = item.checkedAreas
                        this.checkedNum = this.getGoodsNum();
                    }
                })

                console.log(this.data,'seller')
            },
            //单选
            goods(index1) {
                this.data.forEach((item, index) => {
                    if (index1 != index) {
                        item.checkedAreas = [];
                        item.checked = false;
                    } else {
                        // this.checkedNum = item.checkedAreas.length
                        this.checkedId = item.checkedAreas
                        this.checkedNum = this.getGoodsNum();
                    }
                })
                let num = 0 //商品列表中有失效商品
                this.data[index1].cartListResVoList.forEach((item) => {
                    if (item.effectiveMark) { //把有效的商品
                        num++
                    }
                })
                this.data[index1].checked = this.data[index1].checkedAreas.length == num;
                console.log(this.data,'goods')
            },
            calcTotalMoney(data) { //计算选中商品的总钱数
                let total = 0
                if (data.length > 0) {
                    if (this.isHotel) {
                        for (let i = 0; i < data.length; i++) {
                            if (data[i].checkedAreas.length > 0) {
                                data[i].checkedAreas.forEach((areaItem, areaIndex) => {
                                    data[i].cartListResVoList.forEach((item2, index) => {
                                        if (item2.id == areaItem) {
                                            total += item2.discountTotalPrice;
                                        }
                                    })
                                })
                                break
                            }
                        }
                    } else {
                        for (let i = 0; i < data.length; i++) {
                            if (data[i].checkedAreas.length > 0) {
                                data[i].checkedAreas.forEach((areaItem, areaIndex) => {
                                    data[i].cartListResVoList.forEach((item2, index) => {
                                        if (item2.id == areaItem) {
                                            total += item2.priorityTotalPrice;
                                        }
                                    })
                                })
                                break
                            }
                        }
                    }
                    return this.fmoney(total)
                } else {
                    return 0
                }

            },
            insertAttributes() { //插入属性
                this.data.forEach((item) => {
                    if (typeof item.checked == "undefined") {
                        this.$set(item, "checked", false)
                        this.$set(item, "couponVisible", false)
                    }
                    if (typeof item.checkedAreas == "undefined") {
                        this.$set(item, "checkedAreas", [])
                    }
                    item.cartListResVoList.forEach(n => {//记录最后一次更改的商品有效数量
                        if (typeof n.goodsLastNum == "undefined") {
                            this.$set(n, "goodsLastNum", n.num)
                            // 记录 最初num的值 跟 minimunMoq 最低起订量作比较 num < minimunMoq 最小值 取 num
                            this.$set(n, "initNum", JSON.parse(JSON.stringify(n.num)))
                        }
                    })
                })
            },
            //获取购物车列表
            getCartData() {
                this.loading = true
                const api = "/anon/order/cart/listNopage"
                let body = this.queryData
                axiosApi(api, body, this.getCartDataCallback, 1, 10, (res) => {
                    this.loading = false
                })
            },
            //获取还差多少起订
            getMinimunmoq(){

                const api = "/anon/order/cart/checkMinimunmoq"
                var arr = []
                for (let i = 0; i < this.data.length; i++) {
                    if (this.data[i].checkedAreas.length > 0) {
                        this.data[i].checkedAreas.forEach((areaItem, areaIndex) => {
                            this.data[i].cartListResVoList.forEach((item2, index) => {
                                if (item2.id == areaItem) {
                                    const obj = {
                                        skuType: item2.skuType,
                                        num: item2.num,
                                        isIntegerMultiple: item2.isIntegerMultiple,
                                        specInfoNum: item2.specInfoNum,
                                        totalPrice: this.isHotel ?  item2.discountTotalPrice : item2.priorityTotalPrice,

                                    }
                                    arr.push(obj)
                                }
                            })
                        })
                        break
                    }
                }
                if (arr.length == 0){
                    this.minimunMoqDisabled = false
                    this.minimunMoqString = '结算'
                    return false
                }
                let body = {
                    buyerId: sessionStorage.traderId,
                    cartExParams: arr
                }
                axiosApi(api, body, res=>{

                    this.minimunMoqDisabled = res.data.data ? true : false
                    this.minimunMoqString = res.data.data
                }, 1, 10, () => {
                    this.loading = false
                })
            },
            getCartDataCallback(res) {
                this.loading = false
                if (res.data.data) {
                    let arr = [];
                    res.data.data.cartResBeans.map(v => {
                        v.cartListResVoList.map(item => {
                            if(item.notCheckedStock){
                                item.goodsMaxNum = 99999;
                            }else{
                                // item.goodsMaxNum = item.
                            }
                        })
                        arr.push(v)
                    })
                    this.data = arr;
                    this.minimunmoqDesc = res.data.data.minimunmoqDesc
                    this.countSkuNum = res.data.data.countSkuNum
                    this.countTotalNum = res.data.data.countTotalNum
                }

                this.insertAttributes() //加载页面插入属性
                this.$nextTick(() => {
                    this.$refs.affix.initScroll();
                });
            },
            //删除商品按钮
            deleteCart(id) {
                if (localStorage.getItem('deleteChecked_zjl')) {
                    let body = {
                        ids: []
                    }
                    body.ids.push(id)
                    this.orderCartDelete(body)
                } else {
                    this.deleteVisible = true
                    this.deleteId = id
                }
            },
            //删除商品弹框确定
            handleDeleteDialog() {
                if (this.deleteChecked) {
                    localStorage.setItem('deleteChecked_zjl', this.deleteChecked)
                }
                let body = {
                    ids: []
                }
                body.ids.push(this.deleteId)
                this.orderCartDelete(body)
                this.deleteVisible = false
                this.checkedNum = 0;
            },
            //加入收藏夹按钮
            handleAddCollectGoods(item) {
                if (localStorage.getItem('addChecked_zjl')) {
                    let body = [{
                        buyerId: item.buyerId,
                        skuNo: item.skuNo,
                        classifyId: item.classifyId,
                        brandId: item.brandId
                    }]
                    this.addCollectGoods(body)
                } else {
                    this.addItem = item
                    this.addVisible = true
                }

            },
            //弹出框添加至收藏夹
            handleAddDialog() {
                if (this.addChecked) {
                    localStorage.setItem('addChecked_zjl', this.addChecked)
                }
                let body = [{
                    buyerId: this.addItem.buyerId,
                    skuNo: this.addItem.skuNo,
                    classifyId: this.addItem.classifyId,
                    brandId: this.addItem.brandId
                }]
                this.addCollectGoods(body)
                this.addVisible = false
            },
            orderCartDelete(body) {
                const api = '/anon/order/cart/delete'
                axiosApi(api, body, (res) => {
                    this.$message({
                        type: 'success',
                        message: '操作成功!',
                        duration: 1000
                    })
                    this.getCartData();
                }, 1, 10, (res) => {
                    this.$message({
                        type: 'error',
                        message: res.data.message,
                        duration: 1000
                    })
                })
            },
            handleChange(item, index1, index2) { //计数器改变
                /*视图渲染必须在 setTimeout外面*/
                console.log(item.num , item.goodsMaxNum)
                // console.log(item,'item')
                //notCheckedStock 是否校验库存 true 不校验 false 校验
                // if(!item.notCheckedStock){
                //     this.$nextTick(() => {
                //         if(item.num > item.goodsMaxNum){
                //             flag = false;
                //             this.$message({
                //                 type:'warning',
                //                 message:'该商品剩余库存不足'
                //             })
                //             let body = {
                //                 id: item.id,
                //                 num: item.goodsMaxNum,
                //                 selectedSalesUnitType:item.selectedSalesUnitType
                //             }
                //             this.updateCart(body, index1, index2)
                //         }
                //     })
                // }
                this.$nextTick(() => {
                    let bool = false;
                    /*判断该商品是否已被选择*/
                    for (let i = 0; i < this.data[index1].checkedAreas.length; i++) {
                        if (this.data[index1].checkedAreas[i] == item.id) {
                            bool = true;
                            break;
                        }
                    }
                    if(!item.notCheckedStock){
                        if(item.num > item.goodsMaxNum){
                            this.$message({
                                type:'warning',
                                message:'该商品剩余库存不足'
                            })
                            let body = {
                                id: item.id,
                                num: item.goodsMaxNum,
                                selectedSalesUnitType:item.selectedSalesUnitType
                            }
                            this.updateCart(body, index1, index2)
                            return
                        }
                    }
                    this.$nextTick(() => {
                        if (!bool) this.data[index1].checkedAreas.push(item.id);
                        this.data[index1].cartListResVoList[index2].checked = true;
                        this.goods(index1);
                        let num = item.num;
                        let body = {
                            id: item.id,
                            num: num,
                            selectedSalesUnitType:item.selectedSalesUnitType
                        }
                        this.updateCart(body, index1, index2)
                    })
                })
            },
            updateCart(body, index1, index2) { //购物车数量加减
                const api = '/anon/order/cart/update'
                axiosApi(api, body, (res) => {
                    this.data[index1].cartListResVoList[index2].num = res.data.data.num
                    if (this.isHotel) {
                        // if(body.selectedSalesUnitType == 3){
                        //     this.data[index1].cartListResVoList[index2].discountPrice = res.data.data.totalPrice;
                        // }else{
                        //     this.data[index1].cartListResVoList[index2].discountTotalPrice = res.data.data.totalPrice;
                        // }
                        
                        this.data[index1].cartListResVoList[index2].discountTotalPrice = res.data.data.totalPrice;
                        this.data[index1].cartListResVoList[index2].goodsLastNum = res.data.data.num;//记录上次更改成功的商品数量
                        this.checkedNum = this.getGoodsNum();
                    } else {
                        // if(body.selectedSalesUnitType == 3){
                        //     this.data[index1].cartListResVoList[index2].priorityPrice = res.data.data.totalPrice;
                        // }else{
                        //     this.data[index1].cartListResVoList[index2].priorityTotalPrice = res.data.data.totalPrice;
                        // }

                        this.data[index1].cartListResVoList[index2].priorityTotalPrice = res.data.data.totalPrice;
                        this.data[index1].cartListResVoList[index2].goodsLastNum = res.data.data.num;//记录上次更改成功的商品数量
                        this.checkedNum = this.getGoodsNum();
                    }
                }, 1, 10, (res) => {
                    this.data[index1].cartListResVoList[index2].num = this.data[index1].cartListResVoList[index2].goodsLastNum;
                    this.$message({type: 'info', message: res.data.message, duration: 1000})
                })
            },
            addCollectGoods(body) { //进货商收藏商品接口
                const api = '/trader/addCollectGoods/add'
                axiosApi(api, body, (res) => {
                    this.$message({
                        type: 'success',
                        message: '操作成功',
                        duration: 1000
                    })
                }, 1, 10, (res) => {
                    this.$message({
                        type: 'info',
                        message: res.data.message,
                        duration: 1000
                    })
                })
            },

            fmoney(s, n) //s:传入的float数字 ，n:希望返回小数点几位
            {
                n = n > 0 && n <= 20 ? n : 2;
                s = parseFloat((s + "").replace(/[^\d\.-]/g, "")).toFixed(n) + "";
                var l = s.split(".")[0].split("").reverse(),
                    r = s.split(".")[1];
                let t = "";
                for (let i = 0; i < l.length; i++) {
                    t += l[i] + ((i + 1) % 3 == 0 && (i + 1) != l.length ? "," : "");
                }
                return t.split("").reverse().join("") + "." + r;
            },
            /*计算选中商品的数量*/
            getGoodsNum() {
                let num = 0;
                this.data.forEach((item, index) => {
                    if (item.checkedAreas.length > 0) {
                        item.checkedAreas.forEach((item2, index2) => {
                            item.cartListResVoList.forEach((item3, index3) => {
                                if (item2 == item3.id) {
                                    num += item3.num
                                }
                            })
                        })
                    }
                })
                return num
            }
        }
    };
</script>

<style lang="less">
    .discountActiveBox {
        display: inline-block;
        margin-left: 20px;
        position: relative;
        vertical-align: middle;

        .fade-enter-active, .fade-leave-active {
            transition: all .4s
        }

        .fade-enter, .fade-leave-to /* .fade-leave-active in below version 2.1.8 */ {
            transform: translateX(100px);
            opacity: 0;
        }

        .rotate-180 {
            transform: rotate(180deg);
        }

        .mtitle {
            cursor: pointer;
        }

        .activityBok {
            width: 70px;
            height: 30px;
            background: url('./../../assets/activityBg.png');
            background-repeat: no-repeat;
            position: relative;
            background-size: 100% 100%;
            overflow: hidden;

            .activityText {
                color: #ff4949;
                position: absolute;
                width: 100%;
                height: 100%;
                z-index: 10;
                padding-top: 5px;
                padding-left: 12px;
            }

            .arrowBox {
                width: 12px;
                height: 6px;
                background: url('./../../assets/arrowBg.png');
                background-repeat: no-repeat;
                position: absolute;
                top: 12px;
                right: 8px;
                transition: transform .4s;
            }
        }

        .mtype {
            margin-right: 10px;
        }

        .mcontent {
            width: 400px;
            max-height: 300px;
            overflow-y: auto;
            border: 1px solid #ccc;
            position: absolute;
            left: 70px;
            top: 0px;
            background-color: #fff;
            z-index: 100;
            color: #666;
            font-size: 12px;

            .mplaceholder {
                width: 130px;
                display: inline-block;
            }

            .moneListUl {
                > li {
                    margin: 10px;

                    > .text {
                        display: inline-block;
                        overflow: hidden;
                        max-width: 250px;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        vertical-align: middle;
                    }

                    .oneText {
                        font-weight: bold;
                        font-size: 14px;
                    }
                }
            }

            .mtwoListUl {
                > li {
                    padding-left: 39px;
                    margin: 10px;

                    .text {
                        display: inline-block;
                        overflow: hidden;
                        max-width: 210px;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }

                    .num {
                        float: right;
                    }
                }

                .flex {
                    display: flex;

                    .opacity-0 {
                        opacity: 0;
                    }

                    .mmtitle {
                        white-space: nowrap;
                    }

                    .mmcontent {
                        word-break: break-all;
                        word-wrap: break-word;
                        white-space: pre-wrap;
                    }
                }
            }
        }
    }

    [v-cloak] {
        display: none !important;
    }

    #shopingCar {
        .deleteGoodDialog {
            .el-dialog {
                height: 256px;
                min-height: 0px;
            }
        }

        .w80 {
            width: 80px;
        }

        .w120 {
            width: 120px;
        }

        .ul1 {
            li {
                float: left;
                width: 128px;
            }

            li:nth-child(1) {
                width: 470px;
                text-align: center;
            }

            .w100 {
                width: 80px;
            }
        }

        .ul2 {
            .mwi {
                width: 80px;
                margin-left: 10px;
            }

            li {
                height: 80px;
                padding: 20px 0;

                .el-checkbox {
                    float: left;
                    margin-right: 80px;

                    .el-checkbox__label {
                        display: none;
                    }
                }
                &:last-child {
                    border-bottom: 0 !important;
                }
            }
        }

        .ul3 {
            height: 40px;
            line-height: 40px;
            padding-left: 80px;
            z-index: 1000;

            .ml158 {
                margin-left: 88px;
            }

            .ml330 {
                margin-left: 240px;
                width: 128px;
                display: inline-block;
            }

            .el-button {
                width: 100px;
                height: 40px;
                border-radius: 0;
            }
        }

        .fixed {
            position: fixed;
            bottom: 0;
            width: 1100px;
            box-sizing: border-box;
        }

        .width-auto {
            width: auto;
            height: auto;
        }

        .el-button--text {
            color: #333;
        }

        .el-button--text.is-disabled:hover {
            background: #fff;
        }

        .disabledImg {
            background-color: rgba(0, 0, 0, .4);
        }
    }
</style>
