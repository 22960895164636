<template>
    <div id="superBManager">
        <!-- head -->
        <div class="title-top bg-f mb-5">
            <div class="clearfix black">
                <el-breadcrumb class="fl ">
                    <el-breadcrumb-item>超级B管理</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
        </div>

        <!-- body -->
        <div class="bg-f">

            <!-- class -->
            <div class='bg-f'>
                <div class='bg-f mt-10'>
                    <div class="ml-30 mr-30 second-title clearfix">
                        <div class="choose-three font-14 color-6 fl tac mr-10 cursor " :class="first == 0?'actived':''"
                             @click="getStatus(0)">
                            超级B
                        </div>
                        <div class="choose-three font-14 color-6 tac fl mr-10 cursor"
                             :class="first == 1?'actived':''" @click="getStatus(1)">
                            待审核
                            <span class="ml-5 color-danger" v-show="message">
                                ●
                            </span>
                        </div>
                        <div class="choose-three font-14 color-6 tac fl cursor"
                             :class="first == 2?'actived':''" @click="getStatus(2)">
                            不通过
                        </div>

                    </div>
                </div>
            </div>

            <!-- search -->
            <el-form ref="formInlineData" :inline="true" :model="formInlineData" label-width="90px"
                     class="clearfix pl-20 pt-30">
                <div class="fl width-70p">
                    <el-form-item label="超B账号">
                        <el-input v-model.trim="formInlineData.loginName" placeholder="请输入超B账号"></el-input>
                    </el-form-item>
                    <el-form-item label="姓名">
                        <el-input :maxlength="20" v-model.trim="formInlineData.name"
                                  placeholder="请输姓名"></el-input>
                    </el-form-item>
                    <el-form-item label='推荐人' v-if="first==0||first==1">
                        <el-select v-model="formInlineData.RecommenderId" clearable filterable
                                   :filter-method="getRecommender"
                                   placeholder="请输入客户名称">
                            <el-option
                                    v-for="item in searchRecommender"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label='审核时间' v-if="first==2">
                        <ysDaterangePicker v-model="formInlineData.orderDateRange"/>
                    </el-form-item>
                    <el-form-item label='审核人' v-if="first==2">
                        <el-select v-model="formInlineData.operatorId" clearable filterable
                                   :filter-method="getPerson"
                                   placeholder="请输入审核人名称">
                            <el-option
                                    v-for="item in searchPerson"
                                    :key="item.id"
                                    :label="item.employeeName"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="状态：" v-if="first==0">
                        <el-select v-model="formInlineData.state" clearable placeholder="请选择">
                            <el-option :label='item.classifyName' :value="item.id" v-for='item in classifyState'
                                       :key='item.id'></el-option>
                        </el-select>
                    </el-form-item>
                </div>
                <el-button type="info" class="fl ml-50" @click='achieve'>搜索</el-button>
            </el-form>

            <!-- list -->
            <div class="pl-30 pr-30 pb-30" v-show="first==0">

                <!-- export -->
                <div class='tar clearfix'>
                    <el-button type='text' class="fr" @click="exportGoods">全部导出</el-button>
                </div>

                <el-table :data="tableData" v-loading="Loading" class='mt-10 table-default' stripe>

                    <el-table-column label="超B账号" prop="loginName"></el-table-column>
                    <el-table-column label="姓名" prop="name"></el-table-column>
                    <!--  -->
                    <el-table-column label="累计佣金补贴" width="90">
                        <template slot-scope="scope">
                            <div>￥{{scope.row.totalCommission | fmoney}}</div>
                        </template>
                    </el-table-column>

                    <el-table-column label="下游数量" prop='downstreamNumber'></el-table-column>
                    <el-table-column label="推荐人">
                        <template slot-scope="scope">
                            <div>{{scope.row.recommendName}}-{{scope.row.recommendMobile}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column label="加入时间">
                        <template slot-scope="scope">
                            <span>{{scope.row.gmtModified | time}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="状态">
                        <template slot-scope="scope">
                            <span v-if="scope.row.state == 2">启用</span>
                            <span v-if="scope.row.state == 4">禁用</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="90">
                        <template slot-scope="scope">
                            <el-button class='ml-0 tac' size="small" type='text' @click="showItem(scope.row,0)">查看
                            </el-button>
                            <el-button v-if="scope.row.state==2" class='ml-0 tac' size="small" type='text'
                                       @click="able(scope,4)">禁用
                            </el-button>
                            <el-button v-if="scope.row.state==4" class='ml-0 tac' size="small" type='text'
                                       @click="able(scope,2)">启用
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>

                <!-- index -->
                <el-pagination class="tar mt-20" v-if="agencyTotal>0" :current-page="agencyProPage" :page-size='10'
                               :total='agencyTotal'
                               layout="prev , pager, next, jumper" @current-change="handleCurrentChange">
                </el-pagination>
            </div>

            <!-- list -->
            <div class="pl-30 pr-30 pb-30" v-show="first==1||first==2">

                <el-table :data="tableData" v-loading="Loading" class='mt-10 table-default' stripe>

                    <el-table-column label="超B账号" prop="loginName"></el-table-column>
                    <el-table-column label="姓名" prop="name"></el-table-column>
                    <el-table-column label="申请理由" prop='reason'></el-table-column>
                    <el-table-column :label="first==1?'申请时间':'审核时间'" width="90">
                        <template slot-scope="scope">
                            <div>{{scope.row.gmtModified | time}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column :label="first==1?'推荐人':'审核人'">
                        <template slot-scope="scope">
                            <div v-if="first==1">{{scope.row.recommendName}}-{{scope.row.recommendMobile}}</div>
                            <div v-else>{{scope.row.auditName}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="90">
                        <template slot-scope="scope">
                            <el-button v-if="1" class='ml-0 tac' size="small" type='text'
                                       @click="showItem(scope.row)">
                                <span v-if="first==1">审核</span><span v-else>查看</span>
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>

                <!-- index -->
                <el-pagination class="tar mt-20" v-if="agencyTotal>0" :current-page="agencyProPage" :page-size='10'
                               :total='agencyTotal'
                               layout="prev , pager, next, jumper" @current-change="handleCurrentChange">
                </el-pagination>
            </div>

        </div>

        <!--筛选条件导出-->
        <form ref="downClientForm2" action='/sunrise-gateway/export/superb/account/list' style="display: none">
            <input type="hidden" name="modelName" v-model="a">
            <input type="hidden" name="fileName" v-model="b">
            <input v-if="formInlineData.loginName" type="hidden" name="loginName" v-model="formInlineData.loginName">
            <input v-if="formInlineData.name" type="hidden" name="name" v-model="formInlineData.name">
            <input v-if="formInlineData.RecommenderId" type="hidden" name="RecommenderId"
                   v-model="formInlineData.RecommenderId">
            <input v-if="formInlineData.state" type="hidden" name="state" v-model="formInlineData.state">
        </form>

    </div>
</template>

<script>
    import {axiosApi, basePath} from "../../api/api";

    export default {
        name: "superBManager",
        data() {
            return {
                dialogShow: true,
                classifyState: [{classifyName: '启用', id: '2'}, {classifyName: '禁用', id: '4'}], //搜索分类
                searchRecommender: [], //推荐人分类
                searchPerson: [], //审核人分类
                Loading: false,
                formInlineData: {
                    loginName: '',//B编码
                    name: '',//名称
                    RecommenderId: '',//推荐人
                    operatorId: '',//审核人
                    state: '',//禁用状态 //1 待审核 2 启用 3不通过 4 禁用
                    orderDateRange: [],
                },
                a: 'superb.account.list',
                b: '超级B列表.xls',
                first: 0,
                agencyProPage: 1,
                agencyTotal: 0,
                tableData: [],//商品来源
                rules: {},
                message: false,
            }
        },
        methods: {
            //搜索
            achieve() {
                if(this.Loading) return 
                this.Loading = true;
                let body;
                if (this.first == 0) {
                    body = {
                        loginName: this.formInlineData.loginName,
                        name: this.formInlineData.name,
                        state: this.formInlineData.state,
                        RecommenderId: this.formInlineData.RecommenderId,
                    };
                } else if (this.first == 1) {
                    body = {
                        loginName: this.formInlineData.loginName,
                        name: this.formInlineData.name,
                        state: 1,
                        RecommenderId: this.formInlineData.RecommenderId,
                    };
                } else if (this.first == 2) {
                    body = {
                        loginName: this.formInlineData.loginName,
                        name: this.formInlineData.name,
                        state: 3,
                        operatorId: this.formInlineData.operatorId,
                        gmtStart: this.formInlineData.orderDateRange[0] ? this.formInlineData.orderDateRange[0].getTime() : null,
                        gmtEnd: this.formInlineData.orderDateRange[1] ? this.formInlineData.orderDateRange[1].getTime() : null,
                    };
                }

                let url = '/superb/account/list';
                axiosApi(url, body, res => {
                    this.Loading = false;
                    this.agencyTotal = res.data.data.totalCount;
                    this.tableData = res.data.data.elements;
                }, this.agencyProPage, 10, err => {
                    this.Loading = false;
                })
            },
            getMessage() {
                let body = {
                    state: 1,
                };
                let url = '/superb/account/list';
                axiosApi(url, body, res => {
                    this.message = res.data.data.totalCount > 0 ? true : false;
                }, this.agencyProPage, 10, err => {
                })
            },
            //分页
            handleCurrentChange(val) {
                this.agencyProPage = val;
                this.achieve();
            },
            //导出
            exportGoods() {
                if (this.agencyTotal == 0) {
                    this.$message({
                        type: 'error',
                        message: '暂无数据可导出',
                        duration: 1000
                    })
                    return false
                }
                this.$refs.downClientForm2.submit();
            },
            //tab切换new
            getStatus(e) {
                this.first = e;
                this.setStatus(e);
                this.achieve()
            },
            setStatus(e) {
                this.formInlineData.loginName = null;
                this.formInlineData.name = null;
                this.formInlineData.state = null;
                this.formInlineData.RecommenderId = null;
                this.formInlineData.operatorId = null;
                this.formInlineData.orderDateRange = [];
                this.agencyProPage = 1;
            },

            showItem(item) {
                if (this.first == 0) {
                    window.open(basePath + "/superB/superBDetails/" + item.traderId + "/" + item.id + "/" + '1', "_self");
                } else {
                    this.$router.push({path: 'superBAudit', query: {traderId: item.traderId}});
                }
            },
            //禁用启用
            able(scope, state) {
                var type = state == 4 ? "禁用" : "启用";

                this.$confirm("将" + type + "超级B, 是否继续?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                }).then(() => {
                    axiosApi('/superb/account/audit', {
                        accountId: scope.row.id,
                        state: state,
                    }, (res) => {
                        this.$message({
                            type: 'success',
                            duration: 1000,
                            message: type + "成功!",
                        });
                        this.achieve();
                    }, this.currentPage, 10, (res) => {
                        this.$message.error(res.data.message)
                    })
                }).catch(() => {
                    this.$message({
                        message: "已取消" + type,
                    });
                });
            },
            //查询客户
            getRecommender(query) {
                if (!query) {
                    return false
                }
                let url = '/superb/account/list';
                let body = {
                    name: query,
                };

                axiosApi(url, body, res => {
                    this.searchRecommender = res.data.data.elements;
                }, 1, 100)

            }
            ,
            //查询客户
            getPerson(query) {
                if (!query) {
                    return false
                }
                let url = '/foundation/employee/selectAll';
                let body = {
                    employeeName: query,
                    applicationType: 2,
                    traderId: sessionStorage.traderId,
                    isDeleted: 0,
                    state: 2
                };

                axiosApi(url, body, res => {
                    this.searchPerson = res.data.data.elements;
                }, 1, 100)

            }
        },
        mounted() {
            this.$nextTick(() => {
                this.achieve()
                this.getMessage();
            })
        }
    }
</script>

<style lang="less">
    #superBManager {
        .el-dialog {
            width: 800px;
            height: 600px;

            .img {
                width: 100px;
                height: 100px;
            }
        }

        .actived {
            border-radius: 2px;
            border: 1px solid #FF4201;
            color: #FF4201;
        }

        .second-title {
            height: 60px;
            padding: 17px 0px 17px 0px;
            box-sizing: border-box;
            border-bottom: 1px solid #EEEEEE;
        }

        .choose-three {
            width: 114px;
            height: 26px;
            line-height: 26px;
        }
    }
</style>