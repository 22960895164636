import SuperOrder from '~/views/superC/order.vue' //超级C订单
import DistributionOrder from '~/views/superC/distributionOrder.vue' //二级分销订单
import PerManagement from '~/views/superC/perManagement.vue' //超级C人员管理
import CommCash from '~/views/superC/commCash.vue' //超级C佣金提现
import CommManage from '~/views/superC/commManage.vue' //超级C商品管理
import SupercIntro from '~/views/superC/supercIntro.vue' //超级C介绍
import ApplyAcount from '~/views/superC/applyAcount.vue' //超级C开通详情
import SupercConfig from '~/views/superC/supercConfig.vue' //超级C配置
//import DistributorCheck from '~/views/superC/distributorCheck.vue' //分销商审核
import ApplySuccess from '~/views/superC/applySuccess.vue' //超c申请成功
import supercOrderDetails from '~/views/superC/supercOrderDetails.vue' //超c订单详情
import Protocol from '~/views/superC/protocol.vue' //超c协议
import ProtocolOne from '~/views/superC/protocolOne.vue' //超c协议
import ProtocolTwo from '~/views/superC/protocolTwo.vue' //超c协议
import CommissionDetails from '~/views/superC/commissionDetails.vue' //佣金明细
import creditManagement from '~/views/superC/creditManagement.vue' //超级C 授信管理
import marginAudit from '~/views/superC/marginAudit.vue' //超级C 保证金审核
import marginExtraction from '~/views/superC/marginExtraction.vue' //超级C 保证金提取
import JDepot from '~/views/superC/jDepot.vue' //超级C 久集仓库

//久集2.0
import HotelReport from '~/views/superC/hotel/hotelReport.vue' // 报表
import HotelReportGoods from '~/views/superC/hotel/hotelReportGoods.vue' // 报表销售商品列表
import SalesManage from '~/views/superC/hotel/salesManage.vue' //销售员管理
import IntegralManage from '~/views/superC/hotel/integralManage.vue' //积分管理
import DistributionGoods from '~/views/superC/hotel/distributionGoods.vue' //分销商商品管理
import ContractCustomer from '~/views/superC/hotel/contractCustomer.vue' //协议客户
import CustomerDetail from '~/views/superC/hotel/customerDetail.vue' //协议客户详情
import AuditMargin from '~/views/superC/hotel/auditMargin.vue' //保证金审核
import GetMargin from '~/views/superC/hotel/getMargin.vue' //保证金提取
import Bills from '~/views/superC/hotel/bill.vue' //发票
import AllGoods from '~/views/superC/hotel/allGoods.vue' //酒店商品
import Freight from '~/views/superC/hotel/freight.vue' //运费价格
import FreightHandle from '~/views/superC/hotel/freightHandle.vue' //运费编辑
import defaultFreightTemplate from '~/views/superC/hotel/defaultFreightTemplate.vue' //运费价格

import companyIntegral from '~/views/superC/hotel/companyIntegral.vue' // 子公司积分
import SupercHotelOrder from '~/views/superC/hotel/supercHotelOrder.vue' //订单列表
import SupercHotelSonOrder from '~/views/superC/hotel/supercHotelSonOrder.vue' //子公司订单列表
import SupercHotelOrderDetail from '~/views/superC/hotel/supercHotelOrderDetail.vue' //子公司订单详情
import HotelService from '~/views/superC/hotel/hotelServiceAgreement.vue' //酒店服务协议
import UpdateVersion from '~/views/superC/hotel/updateVersion.vue' //酒店升级页面

import HotelSetting from '~/views/superC/hotel/hotelSetting.vue' //酒店久集配置
import creditDetail from '~/views/superC/hotel/creditDetail.vue' //酒店久集授信明细
import marginManage from '~/views/superC/hotel/marginManage.vue' //酒店久集保证金管理
import hotelMsgManage from '~/views/superC/hotel/hotelMsgManage.vue' //酒店子公司，总公司消息推送

import SuperCReplaceCare from '~/views/superC/hotel/superCReplaceCare.vue' //简易版酒集代客维护列表
import SuperCCareGoods from '~/views/superC/hotel/superCCareGoods.vue' //简易版酒集维护商品


import supercStoreOrder from '~/views/superC/store/supercStoreOrder.vue' //子公司门店久集订单
import subStoreGoods from '~/views/superC/store/subStoreGoods.vue' //子公司门店久集商品
import supercStoreManage from '~/views/superC/store/supercStoreManage.vue' //中心店管理
import supercStoreManageTwo from '~/views/superC/store/supercStoreManageTwo.vue' // 中心店下级门店
import companyStoreCommission from '~/views/superC/store/companyStoreCommission.vue' //子公司门店久集门店管理

import commissio from '~/views/superC/store/commissio.vue' //门店久集佣金管理
import storeMarginManage from '~/views/superC/store/storeMarginManage.vue' //门店久集保证金管理
import classifyManage from '~/views/superC/store/classifyManage.vue' //门店久集分类管理
import storePurStockOrder from '~/views/superC/store/storePurStockOrder.vue' //门店久集采购单
import unitsManage from '~/views/superC/store/unitsManage.vue' //门店久集单位管理
import storeOrder from '~/views/superC/store/storeOrder.vue' //门店角色久集订单
import storeOrderDetail from '~/views/superC/hotel/storeOrderDetail.vue' //门店角色久集订单详情
import storeGoodsManage from '~/views/superC/store/storeGoodsManage.vue' //门店分销商品管理
import storeGoodsAdd from '~/views/superC/store/storeGoodsAdd.vue' //门店分销商品上传
import storeSetting from '~/views/superC/store/storeSetting.vue' //门店系统设置
import storeSalesManage from '~/views/superC/store/storeSalesManage.vue' //门店销售员管理
import subStoreFreSetting from '~/views/superC/store/subStoreFreSetting.vue' //门店默认运费设置

// import SpecialGoods from '~/views/superC/hotel/specialGoods.vue' //特殊商品维护
import SpecialGoodsEdit from '~/views/superC/hotel/specialGoodsEdit.vue' //特殊商品编辑
import SpecialGoodsOrder from '~/views/superC/hotel/specialGoodsOrder.vue' //特殊商品订单审核
import SpecialGoodsOrderDetail from '~/views/superC/hotel/specialGoodsOrderDetail.vue' //特殊商品订单详情
import JicaiList from '~/views/jicai/jicaiList.vue' //集采列表
import JicaiDetail from '~/views/jicai/jicaiDetail.vue' //集采详情
import JicaiDetailB from '~/views/jicai/jicaiDetailB.vue' //集采详情小B
import JicaiMy from '~/views/jicai/jicaiListMy.vue' // 我的集采小B
import JicaiListB from '~/views/jicai/jicaiListB.vue' //集采列表小B
import JicaiCreate from '~/views/jicai/jicaiCreate.vue' //集采创建
import SuperPerDetail from '~/views/superC/superPerDetail.vue' //人员管理详情
import SupercSaleDetail from '~/views/superC/supercSaleDetail.vue' //人员管理详情

import {basePath} from '../api/api'
import updateVersion from "~/views/superC/hotel/updateVersion";

export const ccFirstRouters = [
    {
        path: basePath + '/protocol', //超c协议
        component: Protocol,
        name:'protocol',
        text:'超C协议'
    },
    {
        path: basePath + '/protocolOne', //超c协议
        component: ProtocolOne,
        name:'protocolOne',
        text:'超C协议1'
    },
    {
        path: basePath + '/protocolTwo', //超c协议
        component: ProtocolTwo,
        name:'protocolTwo',
        text:'超C协议2'
    }
];
export const ccHomeRouters = [
    
];
export const ccHomeLogoRouters = [];
export const ccHomeMenuRouters = [
    {
        path: basePath + '/storeOrderDetail/:orderSn?', //门店订单列表详情
        component: storeOrderDetail,
        name:'storeOrderDetail',
        text:'门店订单列表详情'
    },
    {
        path: basePath + '/specialGoodsOrderDetail/:orderSn?', //特殊商品订单详情
        component: SpecialGoodsOrderDetail,
        name:'specialGoodsOrderDetail',
        text:'特殊商品订单详情'
    },
    {
        path: basePath + '/jicai/jicaiDetail',  //集采详情
        component: JicaiDetail,
        name:'jicaiDetail',
        text:'集采详情'
    },
    {
        path: basePath + '/jicai/jicaiDetailB',  //集采详情小B
        component: JicaiDetailB,
        name:'jicaiDetailB',
        text:'集采详情小B'
    },
    {
        path: basePath + '/jicai/jicaiCreate',  //集采创建
        component: JicaiCreate,
        name:'jicaiCreate',
        text:'集采创建'
    },
    {
        path: basePath + '/supercSaleDetail/:id?', //销售员管理详情
        component: SupercSaleDetail,
        name:'supercSaleDetail',
        text:'销售员管理详情'
    },
    {
        path: basePath + '/supercHotelOrderDetail/:orderSn?', //订单列表详情
        component: SupercHotelOrderDetail,
        name:'supercHotelOrderDetail',
        text:'订单列表详情'
    },
    {
        path: basePath + '/superPerDetail/:id?', //人员管理详情
        component: SuperPerDetail,
        name:'superPerDetail',
        text:'人员管理详情'
    },
    {
        path: basePath + '/supercOrderDetails/:orderSn?/:type?', //超C订单详情
        component: supercOrderDetails,
        name:'supercOrderDetails',
        text:'超C订单详情'
    },
    {
        path: basePath + '/superOrder/:supercStoreId?/:referrerStoreId?', //超级C订单
        component: SuperOrder,
        name:'superOrder',
        text:'超级C订单'
    },
    {
        path: basePath + '/distributionOrder/:supercStoreId?/:referrerStoreId?', //二级分销订单
        component: DistributionOrder,
        name:'distributionOrder',
        text:'二级分销订单'
    },
    {
        path: basePath + '/superPer', //超级C人员管理
        component: PerManagement,
        name:'superPer',
        text:'超级C人员管理',
        meta: {keepAlive: true}
    },
    {
        path: basePath + '/commCash', //超级C佣金提现
        component: CommCash,
        name:'commCash',
        text:'超级C佣金提现'
    },
    {
        path: basePath + '/commManage', //超级C商品管理
        component: CommManage,
        name:'commManage',
        text:'超级C商品管理'
    },
    {
        path: basePath + '/jDepot', //超级C 久集仓库
        component: JDepot,
        name:'jDepot',
        text:'超级C久集仓库'
    },
    {
        path: basePath + '/supercIntro', //超级C介绍
        component: SupercIntro,
        name:'supercIntro',
        text:'超级C介绍'
    },
    {
        path: basePath + '/applyAcount', //超级c开通详情
        component: ApplyAcount,
        name:'applyAcount',
        text:'超级c开通详情'
    },
    {
        path: basePath + '/supercConfig', //超级c配置
        component: SupercConfig,
        name:'supercConfig',
        text:'超级c配置'
    },
    {
        path: basePath + '/applySuccess', //超c申请成功
        component: ApplySuccess,
        name:'applySuccess',
        text:'超c申请成功'
    },
    {
        path: basePath + '/commissionDetails', //佣金提现
        component: CommissionDetails,
        name:'commissionDetails',
        text:'佣金提现'
    },
    {
        path: basePath + '/creditManagement', //超级C 授信管理
        component: creditManagement,
        name:'creditManagement',
        text:'超级C授信管理'
    },
    {
        path: basePath + '/marginAudit', //超级C 保证金审核
        component: marginAudit,
        name:'marginAudit',
        text:'超级C保证金审核'
    },
    {
        path: basePath + '/marginExtraction', //超级C 保证金提取
        component: marginExtraction,
        name:'marginExtraction',
        text:'超级C保证金提取'
    },
//  久集2.0
    {
        path: basePath + '/hotelReport', //酒店久集报表
        component: HotelReport,
        name:'hotelReport',
        text:'酒店久集报表'
    },
    {
        path: basePath + '/hotelReportGoods', //酒店久集报表
        component: HotelReportGoods,
        name:'hotelReportGoods',
        text:'酒店久集报表'
    },
    {
        path: basePath + '/salesManage', //销售员管理
        component: SalesManage,
        name:'salesManage',
        text:'销售员管理'
    },
    {
        path: basePath + '/integralManage', //积分提现
        component: IntegralManage,
        name:'integralManage',
        text:'积分提现'
    },
    {
        path: basePath + '/distributionGoods', //分销商商品管理
        component: DistributionGoods,
        name:'distributionGoods',
        text:'分销商商品管理'
    },
    {
        path: basePath + '/contractCustomer', //协议客户
        component: ContractCustomer,
        name:'contractCustomer',
        text:'协议客户'
    },
    {
        path: basePath + '/customerDetail/:companyId?/:name?', //协议客户详情
        component: CustomerDetail,
        name:'customerDetail',
        text:'协议客户详情'
    },
    {
        path: basePath + '/auditMargin', //保证金审核
        component: AuditMargin,
        name:'auditMargin',
        text:'保证金审核'
    },
    {
        path: basePath + '/getMargin', //保证金提取
        component: GetMargin,
        name:'getMargin',
        text:'保证金提取'
    },
    {
        path: basePath + '/bills', //发票
        component: Bills,
        name:'bills',
        text:'发票'
    },
    {
        path: basePath + '/allGoods', //酒店商品
        component: AllGoods,
        name:'allGoods',
        text:'酒店商品'
    },
    {
        path: basePath + '/freight', //运费价格表-放到销售卖货下
        component: Freight,
        name:'freight',
        text:'销售卖货运费价格表'
    },
    {
        path: basePath + '/freightHandle', //运费价格表-增删改查
        component: FreightHandle,
        name:'freightHandle',
        text:'增删改查运费价格表'
    },
    {
        path: basePath + '/defaultFreightTemplate', //默认运费设置
        component: defaultFreightTemplate,
        name:'defaultFreightTemplate',
        text:'默认运费设置'
    },
    {
      path: basePath + '/companyIntegral', // 子公司积分
      component: companyIntegral,
      name:'companyIntegral',
      text:'子公司积分'
    },
    {
        path: basePath + '/supercHotelOrder', //订单列表
        component: SupercHotelOrder,
        name:'supercHotelOrder',
        text:'订单列表'
    },
    {
        path: basePath + '/supercHotelSonOrder', //子公司订单列表
        component: SupercHotelSonOrder,
        name:'supercHotelSonOrder',
        text:'子公司订单列表'
    },
    {
        path: basePath + '/HotelService', //酒店服务协议
        component: HotelService,
        name:'HotelService',
        text:'酒店服务协议'
    },
    {
        path: basePath + '/updateVersion', //酒店服务协议
        component: UpdateVersion,
        name:'updateVersion',
        text:'更新酒店服务协议'
    },
    {
        path: basePath + '/hotelSetting', //酒店久集设置
        component: HotelSetting,
        name:'hotelSetting',
        text:'酒店久集设置'
    },
    {
        path: basePath + '/creditDetail', //授信明细
        component: creditDetail,
        name:'creditDetail',
        text:'授信明细'
    },
    {
        path: basePath + '/marginManage', //保证金管理（酒店久集）
        component: marginManage,
        name:'marginManage',
        text:'保证金管理（酒店久集）'
    },
    {
        path: basePath + '/subStoreOrder', //(子公司)门店久集订单列表
        component: supercStoreOrder,
        name:'subStoreOrder',
        text:'(子公司)门店久集订单列表'
    },
    {
        path: basePath + '/subStoreManage', //(子公司)门店久集门店管理
        component: supercStoreManage,
        name:'subStoreManage',
        text:'(子公司)门店久集门店管理'
    },
    {
        path: basePath + '/supercStoreManageTwo',
        component: supercStoreManageTwo,
        name:'supercStoreManageTwo',
        text:'中心店下级门店'
    },
    {
        path: basePath + '/companyStoreCommission', //(子公司)门店久集佣金管理
        component: companyStoreCommission,
        name:'companyStoreCommission',
        text:'(子公司)门店久集佣金管理'
    },
    
    {
        path: basePath + '/subStoreGoods', //(子公司)门店久集商品管理
        component: subStoreGoods,
        name:'subStoreGoods',
        text:'(子公司)门店久集商品管理'
    },
    {
        path: basePath + '/subStoreFreSetting', //(子公司)门店久集默认运费设置
        component: subStoreFreSetting,
        name:'subStoreFreSetting',
        text:'(子公司)门店久集默认运费设置'
    },
    {
        path: basePath + '/storePurStockOrder', //采购进货单（门店久集）
        component: storePurStockOrder,
        name:'storePurStockOrder',
        text:'采购进货单（门店久集）'
    },
    {
        path: basePath + '/subStorePurOrder', //采购进货单（子公司门店久集）
        component: storePurStockOrder,
        name:'subStorePurOrder',
        text:'采购进货单（子公司门店久集）'
    },
    {
        path: basePath + '/storeSalesManage', //销售员管理（门店久集）
        component: storeSalesManage,
        name:'storeSalesManage',
        text:'销售员管理（门店久集）'
    },
    {
        path: basePath + '/storeCommission', //佣金管理（门店久集）
        component: commissio,
        name:'storeCommission',
        text:'佣金管理（门店久集）'
    },
    {
        path: basePath + '/storeOrder', //订单（门店久集）
        component: storeOrder,
        name:'storeOrder',
        text:'订单（门店久集）'
    },
    {
        path: basePath + '/storeMarginManage', //保证金管理（门店久集）
        component: storeMarginManage,
        name:'storeMarginManage',
        text:'保证金管理（门店久集）'
    },
    {
        path: basePath + '/storeClassifyManage', //分类管理（门店久集）
        component: classifyManage,
        name:'storeClassifyManage',
        text:'分类管理（门店久集）'
    },
    {
        path: basePath + '/storeUnitsManage', //单位管理（门店久集）
        component: unitsManage,
        name:'storeUnitsManage',
        text:'单位管理（门店久集）'
    },
    {
        path: basePath + '/storeGoodsManage', //分销商品管理（门店久集）
        component: storeGoodsManage,
        name:'storeGoodsManage',
        text:'分销商品管理（门店久集）'
    },
    {
        path: basePath + '/storeGoodsAdd', //分销商品上传（门店久集）
        component: storeGoodsAdd,
        name:'storeGoodsAdd',
        text:'分销商品上传（门店久集）'
    },
    {
        path: basePath + '/storeInvoice', //开票管理（门店久集）
        component: Bills,
        name:'storeInvoice',
        text:'开票管理（门店久集）'
    },

    {
        path: basePath + '/storeSettings', //系统设置（门店久集）
        component: storeSetting,
        name:'storeSettings',
        text:'系统设置（门店久集）'
    },
    {
        path: basePath + '/hotelMsgManage', // 子，总公司消息推送
        component: hotelMsgManage,
        name:'hotelMsgManage',
        text:'子，总公司消息推送'
    },
    // { // 页面删除无路由跳转
    //     path: basePath + '/specialGoods', //特殊商品维护
    //     component: SpecialGoods,
    //     name:'specialGoods',
    //     text:'特殊商品维护'
    // },
    {
        path: basePath + '/specialGoodsEdit', //特殊商品编辑
        component: SpecialGoodsEdit,
        name:'specialGoodsEdit',
        text:'特殊商品编辑'
    },
    {
        path: basePath + '/specialGoodsOrder', //特殊商品订单审核
        component: SpecialGoodsOrder,
        name:'specialGoodsOrder',
        text:'特殊商品订单审核'
    },
    {
        path: basePath + '/jicai/jicaiList',  //集采列表
        component: JicaiList,
        name:'jicaiList',
        text:'集采列表'
    },
    {
        path: basePath + '/jicai/jicaiListB',  //集采列表小B
        component: JicaiListB,
        name:'jicaiListB',
        text:'集采列表小B'
    },
    {
        path: basePath + '/jicai/jicaiMy',  //集采列表我的
        component: JicaiMy,
        name:'jicaiMy',
        text:'集采列表我的'
    },
    {
        path: basePath + '/supercReplaceCare',  //简易版久集代客维护酒店列表
        component: SuperCReplaceCare,
        name:'supercReplaceCare',
        text:'简易版久集代客维护酒店列表'
    },
    {
        path: basePath + '/supercCareGoods',  //简易版久集代客维护
        component: SuperCCareGoods,
        name:'supercCareGoods',
        text:'简易版久集代客维护'
    },
];
export const ccCarLogoRouters = [];
