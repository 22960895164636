import { axiosApi } from '../../api/api.js'

const salesMan = {
	state: {
		saleId: "",
		people: [{

		}],
		status: null //对于弹窗里有弹窗，检测弹窗的状态是否关闭
	},
	mutations: {
		visible(state, payload) {
			state.status = payload
		},
		resetSalesman(state,payload){
			state.people = [{}];
			state.saleId = "";
		}
	},
	actions: {
		findSales({
			state
		}, payload) {
			const api = '/foundation/employee/selectAll' //更换业务员（查询所有的业务员）
			let body = {
				traderId: sessionStorage.traderId,
				employeeName: payload,
				searchManagerOnly: 1,//是否只查询业务员
				isDeleted: 0
			}
			axiosApi(api, body, res => {
				if(res.data.data) {
					state.people = res.data.data.elements;
				}
			},1,100)
		},
		sure({state}, payload) {
			state.saleId = payload;
		}

	},
	getters: {
		linknamearray(state) {
			let arr = [];
			state.people.map(v => {
				if(v.managerId == state.saleId){
					arr.push(v)
				}
			});
			return arr;
		}
	}
}

export default salesMan