<template>
    <div class="tag-nav" >
        <div v-if="tagNav.length>0" class="tag-nav-box">
            <div :class="{'tag-navs' : true , 'active' : item.id ?  $route.path == item.url : $route.fullPath == item.url}" v-for="(item,index) in tagNav" @contextmenu.prevent="showContextMenu($event,index)" :key="index" @click="goPage(item)">
                <span>{{item.text}}</span>
                <i class="iconfont icon-close font-14" @click.stop="closeTag(index)"></i>
            </div>

        </div>
        <div class="empty-box" v-else>

        </div>

        <ul class="context-menu" v-show="show" :style="{left: left ,top:top}">
            <li @click="handleClick(1)">关闭当前标签</li>
            <li @click="handleClick(2)" v-if="index!=0">关闭左侧标签</li>
            <li @click="handleClick(3)" v-if="index!=tagNav.length-1">关闭右侧标签</li>
            <li @click="handleClick(4)" v-if="tagNav.length>1">关闭所有标签</li>
        </ul>

    </div>
</template>

<script>
    import { mapState , mapMutations } from 'vuex'
    import { basePath } from '../api/api'
    export default {
        name: "tagNav",
        data(){
          return{
              index:0,
              show:false,
              left:0,
              top:0
          }
        },
        computed:{
            ...mapState('nav',['tagNav','nowNav'])
        },
        methods:{
            ...mapMutations('nav',['changeTagNav','changNowNav']),
            closeTag(i){
                if (this.tagNav[i].url == this.$route.path || this.tagNav[i].url == this.$route.fullPath){
                    if (this.tagNav[i+1]){
                        this.$router.push({
                            path: this.tagNav[i+1].url
                        })
                    } else if(this.tagNav[i-1]){
                        this.$router.push({
                            path: this.tagNav[i-1].url
                        })
                    } else{
                        this.$router.push({
                            path: basePath+ '/accountCenter'
                        })
                    }

                }
                let arr = this.$deepClone(this.tagNav)
                arr.splice(i,1)
                this.changeTagNav(arr)
            },
            showContextMenu(event,index){
                this.index = index
                this.show = true
               
                let left = event.clientX;
                if((window.innerWidth-event.clientX)<110){
                    left = window.innerWidth-110;
                }
                this.left = left + 'px'
                this.top = event.clientY + 'px'
            },
            checkPath(){
                let flag = true
                this.tagNav.forEach(item=>{
                    if (item.url == this.$route.path|| item.url == this.$route.fullPath) {
                        flag = false
                        return false
                    }
                })
                console.log(flag,'flag');
                return flag
            },
            handleClick(val){
                let index = this.index
                let arr = this.$deepClone(this.tagNav)
                if (val == 1) {
                    this.closeTag(index)
                }
                if (val == 2) {
                    arr.splice(0,index)
                    this.changeTagNav(arr)
                    this.checkPath() && this.$router.push({path: this.tagNav[0].url})
                }
                if (val == 3) {
                    arr.splice(index+1,this.tagNav.length-index-1)
                    this.changeTagNav(arr)
                    this.checkPath() && this.$router.push({path: this.tagNav[this.tagNav.length - 1].url})
                }
                if (val == 4) {
                    this.changeTagNav([])
                    this.$router.push({path: basePath+ '/accountCenter'})
                }
                this.show = false
            },
            goPage(val){
                this.changNowNav(val)
                this.$router.push({
                    path: val.url
                })
            }
        },
        mounted() {
             
            let tagNav = JSON.parse(sessionStorage.getItem('tagNav'))
            this.changeTagNav(tagNav)
            let that = this
            window.addEventListener('click',function(e){
                if(e.target.className!='tag-navs'){
                    that.show=false;
                }
            },true)
            window.addEventListener('contextmenu',function(e){
                if(e.target.className!='tag-navs'){
                    that.show=false;
                }
            },true)
        },
        destroyed() {
            let that = this
            window.removeEventListener("click", function(e){
                if(e.target.className!='tag-navs'){
                    that.show=false;
                }
            },true);
            window.removeEventListener("contextmenu", function(e){
                if(e.target.className!='tag-navs'){
                    that.show=false;
                }
            },true);
        },
        updated(){
            sessionStorage.setItem('tagNav',JSON.stringify(this.tagNav))
        },
    }
</script>

<style lang="less" scoped>
    .tag-nav{
        width: 100%;
        display: flex;
        .tag-nav-box{
            height: 46px;
            display: flex;
            align-items: center;
            width: 0;
            flex: 1;
            overflow: auto;
            .tag-navs{
                cursor: pointer;
                border-radius: 18px;
                margin-left: 10px;
                font-size: 14px;
                width: 95px;
                height: 26px;
                line-height: 26px;
                display: flex;
                justify-content: space-around;
                background-color: #ffffff;
                color: #E94A13;
                span{
                    max-width: 60px;
                    overflow:hidden;
                    font-size: 12px;
                    text-overflow:ellipsis;
                    white-space:nowrap;
                }
                a{
                    color: #E94A13;
                }
                &:first-child{
                    margin-left: 0;
                }
                &.active{
                    background-color: #E94A13;
                    color: #ffffff;
                    a{
                        color: #ffffff;
                    }
                }
            }
        }

        .empty-box{
            height: 20px;
        }
        .context-menu{
            position: fixed;
            background-color: #ffffff;
            box-shadow:0px 2px 4px 0px rgba(222,222,222,0.5);
            z-index: 3;
            border-radius: 5px;
            width: 110px;
            li{
                font-size: 12px;
                color: #666666;
                padding: 5px 0px 5px 15px;
                cursor: context-menu;
                &:hover{
                    background-color: #f6f6f6;
                }
            }
        }
    }
</style>