<template>
    <div id="chooseClient">
        <el-dialog title="选择客户" :visible.sync="clientVisible" @close="goPage" width="900px">
            <div v-loading="tableloading">
                <div class="clearfix">
                    <el-form :inline="true" label-width="80px" class="fl search">
                        <el-form-item label="客户名称:">
                            <el-input placeholder="输入客户名称" v-model.trim="storeName"></el-input>
                        </el-form-item>
                        <el-form-item label="客户类型:" v-if="clientType == 1">
                            <el-select v-model="ctype" @change="typeSelectChange" clearable class="type-select">
                                <el-option v-for="item in optionsType" :label="item.label" :value="item.value"
                                           :key="item.value"></el-option>
                            </el-select>
                            <el-cascader :options="clientChannelList" class="type-cascader" placeholder="选择终端渠道"
                                         :props="channelProp" :disabled="isDisabled" clearable change-on-select
                                         v-model="cchannel"></el-cascader>
                        </el-form-item>
                        <el-form-item label="所属分组:" :class="{'mt-10': clientType == 1}">
                            <!-- <el-select v-model="groupId" placeholder="选择分组" clearable>
                                <el-option v-for="n in groupList" :label="n.groupName" :value="n.id"
                                           :key="n.id"></el-option>
                            </el-select> -->
                            <TreeGroup v-model="groupId"/>
                        </el-form-item>
                        <el-form-item label="所属区域:" class="mt-10">
                            <el-cascader :props="districtProps" :options="districtList" clearable change-on-select
                                         placeholder="选择区域" v-model="districtIds"></el-cascader>
                        </el-form-item>
                    </el-form>
                    <el-button type="primary" class="fl ml-20" @click="queryclient(1)">查询</el-button>
                </div>
                <el-table @selection-change="handleSelectionChange" :data="clientData" tooltip-effect="dark"
                          class="mt-10 table-default">
                    <el-table-column type="selection" width="50">
                    </el-table-column>
                    <el-table-column label="客户名称" prop='storeName' width="290">
                    </el-table-column>
                    <el-table-column label="客户类型" width="120">
                        <template slot-scope="scope">
                            <span v-if="scope.row.ctype == 1">经销商</span>
                            <span v-else-if="scope.row.ctype == 2">终端</span>
                            <span v-else-if="scope.row.ctype == 6">普通酒店</span>
                            <span v-else-if="scope.row.ctype == 3">久加久*久柜</span>
                            <span v-else-if="scope.row.ctype == 5">久加久*店中店</span>
                            <span v-else-if="scope.row.ctype == 4">久加久*连锁店</span>
                            <span v-else-if="scope.row.ctype == 41">久加久*连锁店/直营</span>
                            <span v-else-if="scope.row.ctype == 42">久加久*连锁店/加盟</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="终端渠道" prop="channelName">
                    </el-table-column>
                    <el-table-column prop="groupName" label="所属分组">
                    </el-table-column>
                    <el-table-column label="所属区域" prop="districtName">
                    </el-table-column>
                </el-table>
                <div class="clearfix" v-if='total>0'>
                    <div class="fl mt-20">
                        <el-button type="primary" style="width:100px;" @click="addPageList">添加当前页</el-button>
                        <el-button type="primary" style="width:120px;" @click="addSearchList">按查询条件添加</el-button>
                    </div>
                    <!-- <el-checkbox class="fl mt-20" v-model="allclientsChecked">选中全部客户</el-checkbox> -->
                    <el-pagination class="fr" v-if='total>0'
                                   :current-page="currentPage"
                                   :page-size='10'
                                   :total='total'
                                   layout="prev , pager, next"
                                   @current-change="handlePageChange">
                    </el-pagination>
                </div>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" :loading="btnLoading" @click="addclient">确 定</el-button>
                <el-button @click="goPage">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {axiosApi, basePath} from "../api/api"
    import {mapActions, mapMutations} from 'vuex'
    import TreeGroup from '~/components/treeGroup'
    export default {
        props: ["cVisible", 'isFullGift', 'activeTab','ids'],
        components: {
            TreeGroup
        },
        data() {
            return {
                storeName: "",
                ctype: '',//客户类型
                cchannel: [],//客户渠道
                groupId: null,//所属分组
                districtIds: [],//所属区域
                // groupList: [],//客户分组列表
                // districtList:[],//客户区域列表,
                districtProps: {
                    label: 'districtName',
                    value: 'id',
                    children: 'subDistrictList'
                },
                isDisabled: false,
                currentPage: 1,
                btnLoading: false,//确定按钮
                multipleSelection: [],
                clientVisible: false,
                channelProp: {
                    label: 'classifyName',
                    value: 'id',
                    children: 'sub'
                },
                allclientsChecked: false, // 选中全部客户
            }
        },
        watch: {
            cVisible(value) {
                this.clientVisible = value
                if (value) {
                    this.storeName = "";
                    this.ctype = '';//客户类型
                    this.cchannel = [];//客户渠道
                    this.groupId = null;//所属分组
                    this.districtIds = [];//所属区域
                    this.currentPage = 1;
                }
            },
            clientVisible(val) {//监听弹窗变化改变按钮loading（打开弹窗初始化loading）
                this.btnLoading = !val;
            },
            allclientsChecked(val) {// 全选商品
                if (val) {
                    let newpostdata = Object.assign({}, this.postData, {currentPage: 1})
                    newpostdata.data.clientType = this.clientType; // B2B和酒店
                    newpostdata.data.queryPage = 0; // 0-查询全部 1-分页查询
                    this.fullgiftSearchClient(newpostdata);
                }
            }
        },
        computed: {
            postData() {
                return {
                    data: {
                        sellerId: sessionStorage.traderId,
                        storeName: this.storeName,
                        ctype: this.ctype,
                        cchannel1: this.cchannel.length > 0 ? this.cchannel[0] : null,//一级终端渠道
                        cchannel2: this.cchannel.length > 1 ? this.cchannel[1] : null,//二级终端渠道
                        groupId: this.groupId,//所属分组
                        districtId: this.districtIds[this.districtIds.length - 1],//所属区域
                        ids:this.ids
                    },
                    currentPage: this.currentPage
                }
            },
            tableloading() {
                return this.$store.state.clientlist.tableloading
            },
            total() {
                return this.$store.state.clientlist.clienttableData ?
                    this.$store.state.clientlist.clienttableData.totalCount : 0
            },
            clientData() {
                let tableData = this.$store.state.clientlist.clienttableData;
                console.log(tableData,'tableData')
                this.currentPage = tableData ? tableData.pageIndex : 1;
                return tableData ? tableData.elements : []
            },
            clientChannelList() {
                return this.$store.state.channelLevel.clientChannelList
            },
            // groupList() {
            //     return this.$store.state.channelLevel.groupList
            // },
            districtList() {
                return this.$store.state.channelLevel.districtList
            },
            clientType() {
                return (this.$route.query.activitySource ? this.$route.query.activitySource : this.$route.query.couponSource) || 1
            },
            optionsType() {
                return this.$store.state.clientType.optionsType
            }, 
        },
        mounted() {
            this.$nextTick(() => {
                // this.queryGroupList();
                this.queryChannelLevelData(0);
                this.queryDistrictList();
                this.getClientType()
            })
        },
        methods: {
            ...mapMutations({
                'clearnotid': 'clearnotid'
            }),
            ...mapActions({
                'queryclienttableData': 'queryclienttableData',
                'queryChannelLevelData': 'queryChannelLevelData',
                // 'queryGroupList': 'queryGroupList',
                'queryDistrictList': 'queryDistrictList',
                'fullgiftSearchClient': 'fullgiftSearchClient',// 新查询客户 3-7
            }),
            ...mapActions('clientType',['getClientType']),
            handleSelectionChange(val) {
                this.multipleSelection = val;
            },
            queryclient(queryPage) {
                if (this.currentPage == 1) {
                    let newpostdata = Object.assign({}, this.postData, {currentPage: 1})
                    newpostdata.data.clientType = this.clientType; // B2B和酒店
                    // let ids = [];
                    // this.tabletotalData.map(v => {
                    //     ids.push(v.buyerId)
                    // });
                    newpostdata.data.queryPage = queryPage; // 0-查询全部 1-分页查询
                    if(this.activeTab){
                        if(this.activeTab == '0'){
                            newpostdata.data.clientType = null;
                        };
						newpostdata.data.accountSource = 1;
                        this.queryclienttableData({
                            data:newpostdata,
                            activeTab: true
                        });
                        return
                    }
                   this.queryclienttableData(newpostdata)
                } else {
                    this.currentPage = 1;
                }
            },
            //调用添加赠品接口
            addclient() {
                this.btnLoading = true
                this.$store.dispatch('addclient', {
                    willadd: this.allclientsChecked ? this.$store.state.clientlist.allclienttableData : this.multipleSelection,
                    activeTab:this.activeTab
                })
                this.allclientsChecked = false
                this.$emit('closedialog')
            },
            addPageList(){
                this.$store.dispatch('addclient', {
                    willadd: this.clientData,
                    activeTab:this.activeTab
                })
                this.$emit('closedialog')
            },
            addSearchList(){
                let newpostdata = Object.assign({}, this.postData, {currentPage: 1})
                    newpostdata.data.clientType = this.clientType; // B2B和酒店
                    newpostdata.data.queryPage = 0; // 0-查询全部 1-分页查询
                this.fullgiftSearchClient(newpostdata).then(res=>{
                    if(res.data.code==='000000'){
                        var list = res.data.data
                        this.$store.dispatch('addclient', {
                            willadd: list ? list : [],
                            activeTab:this.activeTab
                        })
                    }
                });
                this.$emit('closedialog')
            },
            
            //返回上一页
            goPage() {
                this.clearnotid()
                this.$emit('closedialog')
            },
            handlePageChange(val) {
                this.currentPage = val;
                let newpostdata = Object.assign({}, this.postData, {currentPage: this.currentPage})
                newpostdata.data.clientType = this.clientType; // B2B和酒店
                // newpostdata.data.queryPage = 1; // 0-查询全部 1-分页查询
                if(this.activeTab == '0'){
                    this.postData.data.clientType = null;
                };
                if(this.activeTab){
                    this.queryclienttableData({
                        data:this.postData,
                        activeTab: true
                    });
                    return
                }
                this.queryclienttableData(this.postData)
            },
            typeSelectChange(val) {
                if (val == "1") {
                    this.isDisabled = true;
                    this.cchannel = [];
                } else {
                    this.isDisabled = false;
                }
            }
        }

    }
</script>

<style lang="less">
    #chooseClient .el-dialog {
        width: 950px;
        height: 755px;

        .el-form {
            .el-form-item {
                .el-form-item__label {
                    &::before {
                        display: none
                    }
                }
            }
        }

        .search {
            width: 680px;

            .type-select, .type-select .el-input {
                width: 100px;
            }

            .type-cascader, .type-cascader .el-input {
                width: 135px;
            }
        }
    }

    #chooseClient .table-default.el-table .cell {
        padding: 3px 15px 0;
    }

    #chooseClient .el-dialog__body {
        padding: 20px 30px;
    }

    #chooseClient .el-dialog__footer {
        bottom: 20px;
    }

    // #chooseClient .el-form .el-input{width: 120px;}
</style>
