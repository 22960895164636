<template>
    <!-- 占位高度 样式 -->
    <div :style="placeholder">
        <div :style="style">
            <slot></slot>
        </div>
    </div>
</template>

<script>
    export default {
        /* props : ['type','offsetBottom'], */
        props : {
            /* 距离底部 偏移量 */
            offsetBottom : {
                type : Number,
                default : 0,
            },
            /* 距离顶部 偏移量 */
            offsetTop : {
                type : Number,
            },
            /* 优先级 */
            zIndex : {
                type : String,
                default : '100',
            },
        },
        data() {
            return({
                style : {
                    /* position : 'fixed',
                    bottom : '0',
                    zIndex : '10', */
                },
                placeholder : '',
                top : '',
            });
        },
        methods:{
            getTop(){
                this.top = this.$el.offsetTop;
            },
            // 初始化 位置
            initScroll() {
                this.style = {};
                this.$nextTick(() => {
                    this.getTop();
                    this.scroll();
                });
            },
            scroll() {
                if(this.offsetTop !== void 0){
                    this.scrollTop();
                }else if(this.offsetBottom !== void 0){
                    this.scrollBottom();
                }
            },
            // 固定顶部 逻辑
            scrollTop() {
                // 当前窗口的 height
                let winHeight = window.innerHeight;
                // 滚动的 height
                let overflowHeight = document.documentElement.scrollTop;
                // 当前组件的 height
                let height = this.$el.clientHeight;
                if(overflowHeight  > this.top - this.offsetTop){
                    this.style = {
                        position : 'fixed',
                        top : this.offsetTop + 'px',
                        zIndex : this.zIndex,
                        width : this.$el.clientWidth + 'px',
                    };
                    this.placeholder = {
                        height : height + 'px',
                    }
                }else{
                    this.style = {};
                    this.placeholder = '';
                }
            },
            // 固定底部 逻辑
            scrollBottom() {
                // 当前窗口的 height
                let winHeight = window.innerHeight;
                // 滚动的 height
                let overflowHeight = window.pageYOffset  //用于FF
                || document.documentElement.scrollTop  
                || document.body.scrollTop ;
                // 当前组件的 height
                let height = this.$el.clientHeight;
                if(overflowHeight  > this.top + height - winHeight + this.offsetBottom){
                    this.style = {};
                    this.placeholder = '';
                }else{
                    this.style = {
                        position : 'fixed',
                        bottom : this.offsetBottom + 'px',
                        zIndex : this.zIndex,
                        width : this.$el.clientWidth + 'px',
                    };
                    this.placeholder = {
                        height : height + 'px',
                    }
                }
            },
        },
        mounted() {
            this.$nextTick(() => {
                this.getTop();
                // 不能使用 window.onscroll = () => {} 方式绑定事件 ， 会覆盖原有事件
                window.addEventListener('scroll',() => {
                    this.scroll();
                },false);
                window.addEventListener('resize',() => {
                    this.scroll();
                },false);
            });
        },
    }
</script>

<style scoped>

</style>