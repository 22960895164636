import {axiosApi} from '../../api/api.js'
import {Notification,Message } from 'element-ui'
import { resolve } from 'url';

const clientlist = {
    state: {
        //客户列表维护
        clienttableData: {
            elements: [],
        },
        allclienttableData:[],
        //添加商品列表维护，不走接口
        willadd: [],
        willaddSale: [],//停售客户
        willaddVisible: [],//可见客户
        willaddLimitation: [],//限购客户
        willdelete: [],
        tableloading: false,
        notId: [],
        clientConfig: {}
    },
    mutations: {
        deletewilladddata2(state, payload) {
            console.log(payload,'4');
            if(payload.activeTab == '7'){
                state.willaddSale.splice(payload.index, 1)
            }else if(payload.activeTab == '8'){
                state.willaddVisible.splice(payload.index, 1)
            }else if(payload.activeTab == '9'){
                state.willaddLimitation.splice(payload.index, 1)
            }else{
                state.willadd.splice(payload.index, 1)
            }
        },
        //初始化客户列表
        initclient(state, payload) {
            // state.willadd = Array.from(new Set([...state.willadd,...payload]))
             state.willadd =  payload
        },
        clearwilladd2(state) {
            state.willadd = []
        },
        willdeteleclients(state, payload) {
            console.log(payload,'pay')
            state.willdelete = payload
        },
        clearnotid(state, payload) {
            state.notId = []
        },
        //批量删除客户
        deleteclients(state) {
            var m = state.willadd.filter((each)=>{
                let g = true
                for(let i = 0;i < state.willdelete.length;i++){
                    if(each.id == state.willdelete[i].id) {
                        g = false
                    }
                }
                return g
            })
            console.log(m,'m');
            state.willdelete = []
            state.willadd = m
        },
    },
    actions: {
        //设置客户列表
        addclient({ state }, payload) {
            console.log(payload,'payloadpayload')
            let str = '';
            if(payload.activeTab == '7' ){
                str = 'willaddSale'
            }else if(payload.activeTab == '8' ){
                str = 'willaddVisible'
            }else if(payload.activeTab == '9' ){
                str = 'willaddLimitation'
            }else{
                str = 'willadd'
            };
          if(payload.addAllClients){
            state[str] = [...payload.willadd]
          }else{
              console.log(state[str]);
              // state[str] = Array.from(new Set([...state[str],...payload.willadd]))
              if(state[str] &&  state[str].length>0){
                  let arr = [];
                  payload.willadd.map(item => {
                      let flag = true;
                      state[str].map(v => {
                          if(item.buyerId == v.buyerId){
                              flag = false
                          }
                      });
                      if(flag){
                          arr.push(item);
                      }
                  });
                  console.log(arr,'arr')
                  state[str] = state[str].concat(arr);
              }else{
                  state[str] = payload.willadd;
              }
          }
        },
        // 查询客户列表 （全部客户）
        fullgiftSearchClient({state}, payload){
          state.tableloading = true
          const api = '/trader/buyers/selectList'
          let initialstate={}, newbody
          if(payload.data.ids) {
            state.notId = [...payload.data.ids, sessionStorage.getItem('traderId')]
            newbody = {
              sellerId: sessionStorage.traderId,
              unInBuyerIdList: state.notId,
              queryPage: payload.data.queryPage, // 0-查询全部 1-分页查询
            //   clientType: payload.data.clientType, // B2B和酒店
            }
          } else {
            newbody = Object.assign({}, payload.data, {unInBuyerIdList: [...new Set([...state.notId, sessionStorage.getItem('traderId')])] })
          }
          let body = Object.assign({},initialstate,newbody);
          delete body.clientType  // 移除过滤酒店用户
          return new Promise((resolve, reject) => {
            axiosApi(api,body,(res) => {
              state.tableloading = false
              if(newbody.queryPage == 0){// 不分页
                state.allclienttableData = res.data.data?res.data.data:[]
              }else{// 分页
                state.clienttableData = res.data.data
              }
              resolve(res)
            },payload.currentPage,10,err => { reject(err) })
          })
        },
        chooseAllClientsAction({rootState, state, dispatch}, payload){
          let newpostdata = Object.assign({}, payload.postData)
          newpostdata.data.clientType = payload.clientType; // B2B和酒店
          newpostdata.data.queryPage = 0; // 0-查询全部 1-分页查询
          dispatch('fullgiftSearchClient', newpostdata)
            .then(res => {
              payload.instance.confirmButtonLoading = false;
              dispatch('addclient', {
                willadd: state.allclienttableData,
                addAllClients: true
              })
              payload.done();
              Message.success('添加客户成功');
            })
            .catch(err => {
              payload.done();
              Message.error('添加客户失败');
            })
        },
        //请求客户列表
        queryclienttableData({state}, payload) {
            state.tableloading = true
            const api = '/trader/findClientAllList/select'
            var newbody;
            let currentPage;
            if(payload.activeTab){
                currentPage = payload.data.currentPage;
                if(payload.data.data.ids){
                    state.notId = [...payload.data.data.ids, sessionStorage.getItem('traderId')];
                    newbody = {
                        ...{sellerId: sessionStorage.traderId},
                        ...{unInBuyerIdList: state.notId},
                        ...payload.data.data

                    };
                    delete newbody.ids
                }
            }else{
                currentPage = payload.currentPage;
                if(payload.data.ids) {
                    state.notId = [...payload.data.ids, sessionStorage.getItem('traderId')]
                    newbody = {
                        sellerId: sessionStorage.traderId,
                        unInBuyerIdList: state.notId,
                        queryPage: payload.data.queryPage, // 0-查询全部 1-分页查询
                        // clientType: payload.data.clientType, // B2B和酒店
                    }
                } else {
                    newbody = Object.assign({}, payload.data, {unInBuyerIdList: state.notId})
                }
            }
            let body = Object.assign({},newbody);
            delete body.clientType  // 移除过滤酒店用户
            axiosApi(api,body,(res)=> {
                state.tableloading = false
                if(newbody.queryPage == 0){// 不分页
                  state.allclienttableData = res.data.data?res.data.data.elements:[]
                }else{// 分页
                  state.clienttableData = res.data.data
                }
            },currentPage,10)
        },
        getClientConfig({state}){
            axiosApi('/trader/addCusAttrConfig/select',{
                traderId : sessionStorage.getItem('traderId')
            },(res) => {
                state.clientConfig = res.data.data;
            })
        }
    },
    getters: {
        willaddclientid(state) {
            // console.log('state.willadd',state.willadd)
            return state.willadd.map((a, b)=> {
                return a.buyerId
            })
        }
    }
}

export default clientlist
