import { axiosApi } from '../../api/api.js'

const purchaseOrder = {
	state: {
		purchaseOrderList: [],
		orderDetail: '',   //储存订单详情信息
	},
	mutations: {
		setOrderDetail(state,payload) {
			state.orderDetail = payload
		}
	},
	actions: {
		/*商户订单列表查询 我的订单*/
		queryOrderList(body) {
			axiosApi('/order/list/query', body, (res) => {
//				console.log('商户订单列表查询', res)

			}, 1, 10, (res) => {
//				console.log('商户订单列表查询Error', res)
			})
		}
		
		
		
	},
	getters: {
		orderBuyerId(state) {
			return state.orderDetail.buyerId
		}
	}
}

export default purchaseOrder