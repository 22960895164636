var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"setOrderBox pb-20"},[_c('div',{staticClass:"title-top bg-f"},[_vm._v("\n        订单消息设置\n    ")]),_vm._v(" "),_c('div',{staticClass:"mContent bg-f"},[_c('ul',{staticClass:"list"},_vm._l((_vm.list),function(item,index){return _c('li',{key:index},[_c('div',{staticClass:"left"},[_c('p',[_vm._v(_vm._s(item.typeName))]),_vm._v(" "),(item.accepterVo.length > 0)?_vm._l((item.accepterVo),function(v,k){return (v.accepterValueName)?_c('div',{key:k,staticClass:"flex"},[_c('span',{staticClass:"title"},[_vm._v("\n                                "+_vm._s(v.accepterType === 1 ? '特定员工：' :
                                    v.accepterType === 2 ? '部门员工：' : '特定角色：')+"\n                            ")]),_vm._v(" "),_c('span',{staticClass:"text"},[_vm._v(_vm._s(v.accepterValueName.join('，')))])]):_vm._e()}):[_vm._m(0,true)]],2),_vm._v(" "),_c('div',{staticClass:"medit"},[_c('span',{staticClass:"el-icon-edit",on:{"click":function($event){return _vm.handleEdit(item)}}})])])}),0)]),_vm._v(" "),_c('el-dialog',{attrs:{"title":"订单消息设置","visible":_vm.dialogVisible},on:{"update:visible":function($event){_vm.dialogVisible=$event},"close":_vm.handleDialogClose}},[_c('div',{staticClass:"mDialogContent"},[_c('el-form',{ref:"form",attrs:{"model":_vm.form,"rules":_vm.ruleForm,"label-width":"80px"}},[_c('el-form-item',{attrs:{"label":"订单类型："}},[_c('span',[_vm._v(_vm._s(_vm.typeName))])]),_vm._v(" "),_c('el-form-item',{attrs:{"label":"接收对象："}},[_c('div',[_c('div',[_c('el-checkbox',{model:{value:(_vm.form.tdVal),callback:function ($$v) {_vm.$set(_vm.form, "tdVal", $$v)},expression:"form.tdVal"}},[_vm._v("特定员工")]),_vm._v(" "),(_vm.form.tdVal === true)?_c('div',[_c('el-input',{style:({width : '360px',marginTop : '10px'}),attrs:{"placeholder":"请输入手机号"},model:{value:(_vm.form.tdText),callback:function ($$v) {_vm.$set(_vm.form, "tdText", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.tdText"}}),_vm._v(" "),_c('p',{staticClass:"color-6"},[_vm._v("\n                                直接输入手机号，多个手机号用英文逗号“,”隔开\n                            ")])],1):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"mt-10"},[_c('el-checkbox',{model:{value:(_vm.form.bmVal),callback:function ($$v) {_vm.$set(_vm.form, "bmVal", $$v)},expression:"form.bmVal"}},[_vm._v("部门员工")]),_vm._v(" "),(_vm.form.bmVal === true)?_c('div',{staticClass:"treeBox"},[_c('div',{staticClass:"qyTreeBox"},[_c('el-tree',{ref:"bmTree",attrs:{"check-strictly":true,"props":{
                                        value: 'id',
                                        label: 'text',
                                        children:'sub'
                                    },"data":_vm.form.bmCustomerData,"show-checkbox":"","node-key":"id","default-expand-all":true,"expand-on-click-node":false,"render-content":_vm.renderContent2}})],1)]):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"mt-10"},[_c('el-checkbox',{model:{value:(_vm.form.jsVal),callback:function ($$v) {_vm.$set(_vm.form, "jsVal", $$v)},expression:"form.jsVal"}},[_vm._v("特定角色")]),_vm._v(" "),(_vm.form.jsVal === true)?_c('div',{staticClass:"treeBox"},[_c('div',[_c('el-tree',{ref:"jsTree",attrs:{"props":{
                                        value: 'id',
                                        label: 'text',
                                        children:'subDistrictList'
                                    },"data":_vm.form.jsCustomerData,"show-checkbox":"","node-key":"id","default-expand-all":"","expand-on-click-node":false}})],1)]):_vm._e()],1)])]),_vm._v(" "),_c('el-form-item',{attrs:{"label":"消息内容："}},[_c('el-input',{style:({width : '360px'}),attrs:{"type":"textarea","autosize":{ minRows: 4, maxRows: 4},"disabled":true},model:{value:(_vm.form.messageContent),callback:function ($$v) {_vm.$set(_vm.form, "messageContent", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.messageContent"}})],1)],1)],1),_vm._v(" "),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":_vm.handleDialogClose}},[_vm._v("取 消")]),_vm._v(" "),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.handleSubmit}},[_vm._v("确 定")])],1)])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex"},[_c('span',{staticClass:"title"},[_vm._v("接收对象：")]),_vm._v(" "),_c('span',{staticClass:"text"},[_vm._v("暂无")])])
}]

export { render, staticRenderFns }