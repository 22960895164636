<template>
	<div class='package_manage' v-loading="loading">
		<!--Title标题-->
		<div class="title-top bg-f mb-5">
				套餐管理
				<el-button class="fr ml-0 mr-30 mt-14" icon="el-icon-plus" @click="$router.push(basePath + '/changePackage')">新增套餐</el-button>
		</div>
		<!--Main主体-->
		<div class="main">
			<el-table :data="tableData">
				<el-table-column label="套餐名称" align="center">
					<template slot-scope="scope">
						<div class="good">
							<img v-if="scope.row.fileUrl" :src="scope.row.fileUrl"/>
							<img v-else class="" src="../../assets/goods.jpg"/>
							<div class="ml-30 good_name">{{scope.row.skuName}}</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column label="包含商品" align="center">
					<template slot-scope="scope">
						<div class="clearfix " style="display: flex;justify-content: center;">
							<div v-if="scope.row.skuShowPictureVOs" class="clearfix">
								<img class="fl" v-for="(v,i) in scope.row.skuShowPictureVOs" v-if="i<4" :src="v.fileUrl"/>
							</div>
							<div v-if="scope.row.skuShowPictureVOs&&scope.row.skuShowPictureVOs.length > 4" style="line-height: 60px;">
								<img style="width: 24px;height:6px;" src="../../assets/dash.png"/>
							</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column label="操作" align="center" width='120'>
					<template slot-scope="scope">
						<div>
							<el-button type="text" class="textBtn" @click="editMeal(scope.row.id)">编辑</el-button>
						 	<el-button type="text" class="textBtn" @click="deleteMeal(scope.row.id)">删除</el-button>
						</div>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination v-if='total>0' class="tar" :current-page="currentPage" :page-size='10' :total='total' layout="prev , pager, next, jumper" @current-change="handleCurrentChange">
				<!-- 分页组件 -->
			</el-pagination>
		</div>
	</div>
</template>

<script>
	import { axiosApi, basePath } from "~/api/api"
	export default{
		data(){
			return {
				tableData:[],// 套餐数据数组
				currentPage:1,// 当前页数
				total:0,// 套餐列表总条数
				loading:false,// 加载中
			}
		},
		activated(){
			this.setMeal()// 初始化分页查询套餐列表
				.then(res=>{
					this.total = res.data.data.totalCount // 总条数
					this.tableData = res.data.data.elements// 套餐数据
				})
		},
		methods:{
			handleCurrentChange(val){
				this.currentPage = val// 改变当前页
				this.setMeal().then(res=>{// 调用套餐接口
					this.total = res.data.data.totalCount // 总条数
					this.tableData = res.data.data.elements// 套餐数据
				})
			},
			setMeal(){// 分页查询套餐列表
				this.loading = true
				return new Promise((resolve, reject)=>{
					axiosApi('/goods/setMeal/page/select',{sellerId: sessionStorage.traderId},res=>{
						resolve(res)
						this.loading = false
					},this.currentPage,10,err=>{
						reject(err)
						this.loading = false
					})
				})
			},
			deleteMeal(skuId){// 删除套餐操作skuId 商品id
				const h = this.$createElement
				const that = this
				this.$msgbox({
					title: '提示信息',
					message: h('p', {
						style: 'text-align:center'
					}, '是否确认删除此套餐？'),
					showCancelButton: true,
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					beforeClose: (action, instance, done) => {
						if(action === 'confirm') {//确定
							instance.confirmButtonLoading = true
              instance.confirmButtonText = '执行中...'
              axiosApi('/goods/setMeal/info/delete',{
              	sellerId: sessionStorage.traderId,
              	id: skuId
              },res=>{// 成功回调
              	instance.confirmButtonLoading = false
              	that.$message.success('删除套餐成功！')
              	that.setMeal()
              		.then(res=>{
										this.total = res.data.data.totalCount // 总条数
										this.tableData = res.data.data.elements// 套餐数据
									})
              },1,10,res=>{// 失败回调
              	instance.confirmButtonLoading = false
              	that.$message.error(res.data.data.message)
              })
							done()
						} else {// 取消
							done()
						}
						delete instance.$slots.default;
					}
				})
			},
			editMeal(id){// 进入编辑套餐页面
				this.$router.push({path:'changePackage',query:{skuId: id}})
			}
		}
	}
</script>

<style lang="less">
	.package_manage{
		.mt-14{margin-top: 14px;}
		.main{
			min-height: 536px;
			background-color: #FFFFFF;
			padding: 30px 20px 50px 20px;
			.textBtn{
				width: auto;
				font-size: 12px;
			}
			.img_dash{
				width: 48px;height: 60px;
				background: url(../../assets/dash.png) no-repeat;
				background-position: center center;
			}
			.good{
				display: flex;
				align-items: center;
				justify-content: flex-start;
				.good_name{text-align: left;}
			}
		}
		.lh-60{line-height: 60px;}
        .el-table td, .el-table th {
            padding: 10px 0;
        }
	}
</style>