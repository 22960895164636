<template>
  <div id="address">
    <el-dialog title="地址审核" :visible.sync="visible" @close="goPage" width="780px">
      <el-form :model="addressForm" style="width:50%;margin:0 auto;" label-width="auto">
        <el-form-item label="客户名称：">{{ addressForm.storeName }}</el-form-item>
				<el-form-item label="操作内容：">{{addressForm.traderAddressInfo?"修改地址":"新增地址"}}</el-form-item>
				<el-form-item label="操作时间：">{{addressForm.timeDate}}</el-form-item>
				<el-form-item label="原收货信息：" v-if="addressForm.traderAddressInfo">
            <el-form-item label="收货人：">{{addressForm.traderAddressInfo.linkman}}</el-form-item>
            <el-form-item label="联系电话：">{{addressForm.traderAddressInfo.tel}}</el-form-item>
            <el-form-item label="收货地址：">{{addressForm.traderAddressInfo.provinceName}}{{addressForm.traderAddressInfo.cityName}}{{addressForm.traderAddressInfo.regionName}}{{addressForm.traderAddressInfo.streetName}}{{addressForm.traderAddressInfo.addr}}</el-form-item>
        </el-form-item>
				<el-form-item label="现收货信息：">
          <el-form-item label="收货人：">{{addressForm.linkman}}</el-form-item>
          <el-form-item label="联系电话：">{{addressForm.tel}}</el-form-item>
          <el-form-item label="现收货地址：">{{addressForm.provinceName}}{{addressForm.cityName}}{{addressForm.regionName}}{{addressForm.streetName}}{{addressForm.attr}}</el-form-item>
        </el-form-item>
				<el-form-item label="当前状态：">待审核</el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="sure(1)">审核通过</el-button>
        <el-button @click="sure(0)">驳回申请</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { axiosApi, basePath } from "../../api/api";
import { parseTime } from "~/utils";
export default {
  props: ["addressVisible", "sortType"],
  data() {
    return {
      fourLists: [],
      visible: false,
    };
  },
  watch: {
    addressVisible(value) {
      this.visible = value;
    },
  },
  computed: {
    addressForm() {
      var info = this.$store.state.address.item;
      info.timeDate = parseTime(info.gmtModified, '{y}-{m}-{d} {h}:{i}:{s}')
      
      return info;
    }
  },
  methods: {
    goPage() {
      this.$emit("closedialog", {
        type: 2
      });
	},
	
   
    sure(type) {
      let state = "";
      if (type == 1) {
        state = 2;
      } else if (type == 0) {
        state = 3;
      }
      // let url = "/trader/Address/audit";
      let url = "/trader/address/sellerAudit";
      axiosApi(
        url,
        {
          id: this.addressForm["id"],
          state: state
        },
        res => {
          this.$emit("closedialog", {
            type: 2,
            isLoading: true
          });
        }
      );
    }
  }
};
</script>

<style lang="less">
#address {
  .el-form-item__content {
    text-align: left;
  }
  .el-button {
    width: 90px;
    height: 32px;
  }
  .el-cascader.is-disabled .el-cascader__label {
    cursor: not-allowed;
  }
}

.v-modal {
  z-index: 10 !important;
}
</style>
