<template>
  <div class="hotel-divide-order">
    <el-dialog class="cancelOrderDialog" :title="text" :visible.sync="checkOrderVisible">
      <div class="check-order-tit">
        <div
          class="tp"
          v-if="item.parentOrSon == 3"
          v-for="(item, index) in orderData"
          :key="index"
        >
          <div class="tl" v-if="item.adjustingGoods == 1">
            <el-badge value="调货" class="my-badge" />
            <span class="ml-10">{{item.headName}}</span>
          </div>
          <div class="tl space-between">
            <span>订单号：{{item.orderSn}}</span>
            <span class="color-red fr">
              <span v-if="item.orderStatus===0">已关闭</span>
              <span v-if="item.orderStatus===1">待付款</span>
              <span v-if="item.orderStatus===2">待发货</span>
              <span v-if="item.orderStatus===3">已发货</span>
              <span v-if="item.orderStatus===4">已完成</span>
            </span>
          </div>
          <div class="content2">
            <div class="left-img">
              <img
                v-if="i.fileUrl"
                v-for="(i,index) in item.itemList"
                :key="index"
                :src="i.fileUrl"
              />
              <img v-else src="../../assets/goods.jpg" alt="久加久" />
              <div class="andSoOn" v-if="item.itemList.length >=4">
                <span v-for="i in 3" :key="i" class="dot"></span>
              </div>
            </div>
            <div class="right-info">
              <div
                class="clolr-3"
              >￥{{item.needPayMoney | fmoney}}（含运费￥{{item.freightMoney | fmoney}}）</div>
              <div class="color-9">共{{item.skuTotalNum}}件商品</div>
            </div>
          </div>
        </div>

        <div
          class="btm"
          v-if="item1.parentOrSon == 2"
          v-for="(item1, index) in orderData"
          :key="index"
        >
          <span
            class="price color-r"
          >￥{{item1.needPayMoney | fmoney}}（含运费￥{{item1.freightMoney | fmoney}}）</span>
          <span class="number color-9">共{{item1.skuTotalNum}}件商品</span>
        </div>
      </div>

      <div slot="footer" class="dialog-footer">
        <el-button type="danger" @click="handle">{{text == '取消订单' ? '确定' : text}}</el-button>
        <el-button @click="checkOrderVisible = false">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { axiosApi, basePath } from "../../api/api";
export default {
  data() {
    return {
      checkOrderVisible: false,
      orderData: []
    };
  },
  props: {
    text: {
      type: String,
      default: "立即支付"
    },
    showIt: {
      type: Boolean,
      default: false
    },
    parentOrderSn: {
      type: [String, Number],
      default: ""
    }
  },
  watch: {
    showIt(val) {
      this.checkOrderVisible = val;
      this.getOrderData();
    },
    checkOrderVisible(val) {
      this.$emit("changeOrderDialogStatus", val);
    }
  },
  methods: {
    getOrderData() {
      axiosApi(
        "/superc/order/selectByParentId",
        {
          parentOrderSn: this.parentOrderSn
        },
        res => {
          this.orderData = res.data.data ? res.data.data : [];
        },
        1,
        10,
        err => {}
      );
    },
    handle() {
      this.$emit("handleClick", this.text);
    }
  }
};
</script>

<style lang="less">
.hotel-divide-order {
  .el-dialog {
    min-height: 800px;
    width: 600px !important;
  }
}
// 拆单审核先拉出的两个订单
.check-order-tit {
  position: absolute;
  top: 85px;
  left: 0;
  right: 0;
  bottom: 90px;
  background: #f5f5f5;
  .color-red {
    color: #ff4201;
  }
  .tp {
    background: #fff;
    padding-left: 30px;
    &:nth-child(2) {
      margin-top: 10px;
    }

    .tl {
      height: 36px;
      line-height: 36px;
      padding-right: 30px;
      display: flex;
      align-items: center;
      &.space-between {
        justify-content: space-between;
      }
      .my-badge {
        padding-top: 8px;
      }
    }

    .content2 {
      border-top: 1px solid #ddd;
    //   display: flex;
      padding: 15px 30px 15px 0;

      .left-img {
        display: flex;
        // flex: 1;
        align-items: center;
        img {
          display: block;
          width: 50px;
          height: 50px;
          margin-right: 10px;
        }
        .andSoOn {
          width: 50px;
          height: 50px;
          line-height: 50px;
          .dot {
            display: inline-block;
            width: 5px;
            height: 5px;
            border-radius: 50%;
            background: #bbb;
            margin-left: 3px;
          }
        }
      }
      .right-info {
        line-height: 24px;
        text-align: right;
      }
    }
  }
  .btm {
    text-align: right;
    height: 44px;
    line-height: 44px;
    width: 100%;
    border-top: 1px solid #ddd;
    background: #fff;
    .price {
      margin-right: 20px;
    }
    .number {
      margin-right: 30px;
    }
  }
}
</style>


