<template>
  <div id="recommend" class="bg-f">
    <div class="topTabDiv">
      <el-tabs v-model="goodsType" @tab-click="search" class="activityTabs">
        <el-tab-pane v-for="(item, index) in optionsTypeList" :label="item.label" :name="item.name"  :key="item.name"></el-tab-pane>
      </el-tabs>
      <el-button icon="el-icon-plus" @click="addGoods = true">添加商品</el-button>
    </div>
    <div style="margin-top:20px">
      <el-form :inline="true" :model="form" label-width="auto">
        <el-form-item label="商品编码">
          <el-input v-model="form.goodsNo" placeholder="商品编码"></el-input>
        </el-form-item>
        <el-form-item label="商品名称">
          <el-input v-model="form.skuName" placeholder="商品名称"></el-input>
        </el-form-item>
        <el-form-item label="商品分类">
          <el-select v-model="form.classifyId" clearable>
            <el-option :label='item.classifyName' :value="item.classifyId" v-for='item in searchClassify' :key='item.classifyId'></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="search">查询</el-button>
        </el-form-item>
      </el-form>

      <el-table :data="tableData" border style="width: 100%">
        <el-table-column label="缩略图" width="300">
          <template slot-scope="{row}">
            <div class="goodsInfoDiv">
              <div class="ml-10">
                <img v-if="row.skuVO&&row.skuVO.fileUrl" :src="row.skuVO.fileUrl" />
                <img v-else src="../../../assets/goods.jpg" />
              </div>
              <div class="ml-10" style="flex:1">
                <div class="tal">商品编码:<span v-if="row.skuVO&&row.skuVO.goodsNo">{{row.skuVO.goodsNo}}</span></div>
                <div class="tal"><span v-if="row.skuVO&&row.skuVO.skuName">{{row.skuVO.skuName}}</span></div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="推送时间">
          <template slot-scope="{row}">
            <span>{{row.gmtStart | time}} 至 <span v-if="row.gmtEnd">{{row.gmtEnd | time}}</span> <span v-else>永久有效</span></span>
          </template>
        </el-table-column>
        <el-table-column label="分类" >
          <template slot-scope="{row}">
            <span v-if="row.skuVO&&row.skuVO.classifyName">{{row.skuVO.classifyName}}</span>
          </template>
        </el-table-column>
        <el-table-column label="状态" >
          <template slot-scope="{row}">
            <span>{{row.RecommendStateName}}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="200">
          <template slot-scope="{row,$index}">
            <el-link :underline="false" v-if="!($index==0 && pageIndex==1)" @click.native="handleTop(row.id)">置顶</el-link>
            <el-link :underline="false" type="primary" v-if="row.recommendState==1" @click="terminate(row.id)">终止</el-link>
            <el-link :underline="false" type="danger" @click.native="handleDelete(row.id)">删除</el-link>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        v-if="totalCount>0"
        class="tar"
        :current-page="pageIndex"
        :page-size="pageSize"
        :total="totalCount"
        layout="prev , pager, next, jumper"
        @current-change="handleCurrentChange"
      ></el-pagination>
    </div>

    <el-dialog title="添加商品" :visible.sync="addGoods" width="700px" @close="closeDialog">
      <el-form :model="addForm" label-width="130px">
        <el-form-item label="商品编码:" required>
          <el-input
            v-model.trim="goodsNo"
            auto-complete="off"
            @blur="getByGoodsNo"
            placeholder="请输入商品编码"
          ></el-input>
        </el-form-item>
        <el-form-item label="商品名称:" v-if="goodsNo&&info">
          {{info.skuName}}
        </el-form-item>
        <el-form-item label="商品分类:" v-if="goodsNo&&info">
          {{info.classifyName}}
        </el-form-item>
        <el-form-item label="推送时间:" required>
          <ysDaterangePicker :format="'yyyy-MM-dd HH:mm:ss'" :startDisabledDate='new Date()' v-model="detaArr" type="datetime"/>
        </el-form-item>
        <el-form-item label="类型:">
          <el-select v-model="addForm.goodsType" placeholder="请选择">
            <el-option
              v-for="item in optionsTypeList"
              :key="item.name"
              :label="item.label"
              :value="item.name">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
				<el-button type="primary" @click="addRecommend">确 定</el-button>
				<el-button @click="closeDialog">取 消</el-button>
			</div>
    </el-dialog>
  </div>
</template>

<script>
import { getByGoodsNo, getClassifyTopList } from "~/api/publicInfo";
import { getGoodsRecommendPage, addRecommend, overhead, deleteItem , goodsRecommendTerminate} from "~/api/axiosInfo";
export default {
  data(){
    return {
      form:{},
      goodsType: '1',
      optionsTypeList: [
        {label:'促销商品',name:"1"},
        {label:'新品推荐',name:'2'}
      ],
      tableData: [],
      addGoods: false,
      addForm: { 
        sellerId: null,
        skuNo: null,
        goodsType: '1',
        gmtStart: null,
        gmtEnd: null,
      },
      goodsNo: '',
      info: null,
      detaArr: [],
      searchClassify: [],
      pageIndex: 1,
      pageSize: 10,
      totalCount: 0
    }
  },
  mounted(){
    this.getClassifyTopList()
    this.getList()
  },
  methods:{
    handleTop(id){
      overhead({id:id}).then(res=>{
        this.$message({
          type: "success",
          message: "操作成功",
          duration: 3000
        });
        this.search()
      }).catch(err=>{

      })
    },
    handleDelete(id){
      this.$confirm('此操作将永久删除该商品, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.deleteItem(id)
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });          
      });
    },

    search(){
      this.pageIndex = 1
      this.getList()
    },
    getList(){
      var data = { 
        goodsNo:this.form.goodsNo,
        skuName: this.form.skuName,
        classifyId:this.form.classifyId,
        goodsType: this.goodsType, 
        sellerId: sessionStorage.traderId,
        pageIndex: this.pageIndex,
        pageSize: this.pageSize
      }
      getGoodsRecommendPage(data).then(res=>{
        this.tableData = res.elements;
        this.totalCount = res.totalCount;
      }).catch(err=>{

      })
    },
    deleteItem(id){
      deleteItem({ id: id }).then(res=>{
        this.$message({
          type: 'success',
          message: '删除成功!'
        });
        this.search()
      }).catch(err=>{

      })
    },
    handleCurrentChange(val){
      this.pageIndex = val
      this.getList()
    },
    terminate(id){
      this.$confirm('此操作将终止该商品推送, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.goodsRecommendTerminate(id)
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });          
      });
    },
    goodsRecommendTerminate(id){
      goodsRecommendTerminate({ id: id, sellerId:sessionStorage.traderId }).then(res=>{
        this.$message({
          type: 'success',
          message: '终止成功!'
        });
        this.getList()
      }).catch(err=>{

      })
    },
    getByGoodsNo(){
      getByGoodsNo({sellerId:sessionStorage.traderId, goodsNo:this.goodsNo}).then(res=>{
        this.info = res
        this.addForm.skuNo = res.skuNo
      }).catch(err=>{

      })
    },
    addRecommend(){
      if(this.detaArr){
       this.addForm.gmtStart = this.detaArr[0]!=null?new Date(this.detaArr[0]).getTime():null
       this.addForm.gmtEnd = this.detaArr[1]!=null?new Date(this.detaArr[1]).getTime():null
      }
      this.addForm.sellerId = sessionStorage.traderId

      if(this.info == null) {
        this.$message.error('请输入正确商品编码！')
        return
      }
      if(this.addForm.gmtStart ==null){

        this.$message.error('请输入推送时间开始时间！')
        return
      }


      addRecommend(this.addForm).then(res=>{
        this.$message({
          type: "success",
          message: "操作成功",
          duration: 3000
        });
        this.closeDialog()
        this.search()
      }).catch(err=>{

      })
    },
    getClassifyTopList(){
      getClassifyTopList().then(res=>{
        this.searchClassify = res
      }).catch(err=>{

      })
    },
    closeDialog(){
      this.addGoods = false
      this.addForm = { 
        sellerId: null,
        skuNo: null,
        goodsType: '1',
        gmtStart: null,
        gmtEnd: null,
      },
      this.goodsNo = ''
      this.info = null
      this.detaArr=[]
    },
  },
}
</script>

<style lang="less">
#recommend{
  padding: 20px;
  .topTabDiv{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .goodsInfoDiv{
    display: flex;
    margin: 10px 0;
  }
  
  .ellipsis {
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
  }
}
</style>