<template>
  <div id="address">
    <el-dialog
      title="编辑地址"
      :visible.sync="visible"
      @close="goPage"
      width="780px"
    >
      <el-form :model="addressForm" :rules="rules" ref="aForm"  style="width:50%;margin:0 auto;" label-width="auto">
        <el-form-item label="收货人:" prop="linkman">
          <el-input
            v-model.trim="addressForm.linkman"
            :maxlength="16"
          ></el-input>
        </el-form-item>
        <el-form-item label="联系电话:" prop="tel">
          <el-input v-model.trim="addressForm.tel" :maxlength="11"></el-input>
        </el-form-item>
        <el-form-item label="收货地址:" required>
          <div class="clearfix">
            <el-form-item class="fl" prop="selectedOptions">
              <selectArea v-model="addressForm.selectedOptions" @changeName="changeName" />
            </el-form-item>
          </div>
        </el-form-item>
        <el-form-item prop="attr">
            <el-input
              v-model.trim="addressForm.attr"
              :maxlength="50"
            ></el-input>
          </el-form-item>
        <el-form-item label="当前状态:">
          <span v-if="addressForm.state == 1">待审核</span>
          <span v-if="addressForm.state == 2">正常使用</span>
          <span v-if="addressForm.state == 3">未通过</span>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="sure">确定</el-button>
        <el-button @click="goPage">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { axiosApi, basePath } from "../../api/api";
export default {
  props: ["addressVisible", "sortType"],
  data() {
    var validateAddress = (rule, value, callback) => {
      var info = this.addressForm.selectedOptions
      if (info.length===0) {
        callback(new Error('请选择省市区'));
      } else {
        if(info[0]==null){
          callback(new Error('请选择省'));
        }else if(info[1]===null){
          callback(new Error('请选择市'));
        }else if(info[2]===null){
          callback(new Error('请选择区'));
        }else{
          callback();
        }
      }
    };
    return {
      fourLists: [],
      visible: false,
      form: {
        selectedOptions: []
      },
      rules: {
        linkman: [
          {
            required: true,
            pattern: /^([A-Za-z]|[\u4E00-\u9FA5]){2,16}$/,
            message: "请输入2到16位汉字或者字母",
          },
        ],
        tel: [
          {
            required: true,
            pattern: /^((0\d{2,3}-?\d{7,8})|(\d{7,8})|(1\d{10}))$/,
            message: "请输入正确的手机号码或电话号码",
          },
        ],
        selectedOptions: [
          { validator: validateAddress, trigger: 'change' },
        ],
        attr: [
          {
            required: true,
            message: "请输入详细地址",
          },
          {
            min: 4,
            max: 50,
            message: "请输入4-50个字符",
          },
        ],
      },
      areaNameList:[],
    };
  },
  watch: {
    addressVisible(value) {
      this.visible = value;
    },
  },
  computed: {
    addressForm() {
      var info = this.$store.state.address.item;
      return info;
    },
  },
  mounted(){},
  methods: {
    goPage() {
      this.$emit("closedialog", {
        type: 1,
      });
    },
    changeName(e){
      console.log(this.addressForm.selectedOptions, this.areaNameList,'name')
			this.areaNameList = e
		},
    
    sure() {
      this.$refs.aForm.validate((valid) => {
        if (valid) {
          var ids = this.addressForm.selectedOptions
          let body = {
            id: this.addressForm["id"],
            traderId: this.addressForm["traderId"],
            linkman: this.addressForm["linkman"],
            tel: this.addressForm["tel"],
            isDefault: this.addressForm["isDefault"],
            addressType: this.addressForm["addressType"],
        
            provinceId: ids[0],
            cityId: ids[1],
            regionId: ids[2],
						streetId: ids[3],

            provinceName: this.areaNameList[0],
            cityName: this.areaNameList[1],
            regionName: this.areaNameList[2],
						streetName: this.areaNameList[3],
          };
          body.addr = this.addressForm["attr"];

          axiosApi(
            '/trader/address/audit',
            body,
            (res) => {
              if(res.data.data&&res.data.data.message){
									this.$confirm(res.data.data.message, '提示', {
									confirmButtonText: '确定',
									cancelButtonText: '取消',
									type: 'warning'
								}).then(() => {
									this.editAddress(body)
								}).catch(() => {
									this.$message({
										type: 'info',
										message: '已取消'
									});          
								});
							}else{
								this.editAddress(body)
							} 
            },
            0,
            0,
            (err) => {
              this.$message(err.data.message);
            }
          );
        } else {
          return false;
        }
      });
    },
    editAddress(body){
      axiosApi('/trader/address/update', body, res => {
        this.$emit("closedialog", {
          type: 1,
          isLoading: true,
        });
      },null,null,res=>{
       
        this.$message.error(res.data.message)
      })
    },
  },
};
</script>

<style lang="less">
#address {
  .el-form-item__content {
    text-align: left;
  }
  .el-button {
    width: 90px;
    height: 32px;
  }
  // .el-form-item__error {
  //   margin-left: 185px;
  // }
  .ml-185 {
    margin-left: 185px !important;
  }
}
</style>
