<template>
	<div id="groupCommodityAudit" class="pb-50">
		<div class='c'>
			<el-badge :value="count1" :max='99' class='count2' :hidden="count1 == 0"/>
			<el-badge :value="count2" :max='99' class='count3' :hidden="count2 == 0"/>
		</div>
		<div class="title-tabs bg-f black">
			<ul class="clearfix">
				<template v-for='(tab,index) in dataTabs' class="item">
					<li :type='index' :class="type==index?'active':''" @click='handleTabs'>
						{{tab}}
						<div :class="type==index?'under-line':''"></div>
					</li>
					<div class="vertical-line"></div>
				</template>
			</ul>
		</div>
		<div class="bg-f mt-5 pb-50">
			<el-form :inline="true" :model="formInlineData" label-width="90px" class="clearfix pl-20 pt-30 search">
				<div class="fl width-70p">
					<el-form-item label="商品名称">
						<el-input v-model="formInlineData.skuName"></el-input>
					</el-form-item>
					<el-form-item label="店铺名称">
						<el-input v-model="formInlineData.storeName" :maxlength="20"></el-input>
					</el-form-item>
					<el-form-item label="商品分类">
						<el-select v-model="formInlineData.id" clearable>
							<el-option :label='item.classifyName' :value="item.id" v-for='item in searchClassify' :key='item.id'></el-option>
						</el-select>
					</el-form-item>
				</div>
				<el-button type="info" class="fl ml-50" @click='achieve(0)'>搜索</el-button>
			</el-form>
			<div class="clearfix mr-50 pr-10">
				<span class="fr color-blue ml-10 cursor" v-if='auditState == 1 && showButton("SELLER_GOODS_AUDIT",140003)' @click='getInfo(3)'>驳回审核</span>
				<span class="fr color-blue ml-10 cursor" v-if='auditState == 1 && showButton("SELLER_GOODS_AUDIT",140003)' @click='getInfo(2)'>审核通过</span>
			</div>
			<el-table :data="tableData" v-loading="Loading" class='ml-30 mt-10 table-default mr-30' @selection-change="handleSelectionChange" stripe>
				<el-table-column type="selection" width="40"></el-table-column>
				<el-table-column prop="1" label="商品名称" width="262">
					<template slot-scope="scope">
						<div class="fl ml-10">
							<img v-if='scope.row.fileUrl' :src="scope.row.fileUrl" />
							<img v-else src="../../assets/goods.jpg" />
						</div>
						<div class="fl ml-10 mt-10">
							<div class="tal w-160">商品编码:{{scope.row.skuNo}}</div>
							<div class="tal w-160">{{scope.row.skuName}}</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column label="店铺名" width="110" prop='storeName'></el-table-column>
				<el-table-column label="规格" width="60">
					<template slot-scope="scope">
						<div>1*{{scope.row.specInfoNum}}</div>
					</template>
				</el-table-column>
				<el-table-column label="商品分类" width="70" prop='classifyName'></el-table-column>
				<el-table-column label="库存" width="60" prop='stockEnableQuantity'></el-table-column>
				<el-table-column label="成本价" width="60">
					<template slot-scope="scope">
						<div>￥{{scope.row.costPrice}}</div>
					</template>
				</el-table-column>
				<el-table-column label="团购价" width="60">
					<template slot-scope="scope">
						<div>￥{{scope.row.grouponPrice}}</div>
					</template>
				</el-table-column>
				<el-table-column label="销量" width="60" prop='sales'></el-table-column>
				<el-table-column v-if='isShow1' label="久加久自营" width="70">
					<template slot-scope="scope">
						<el-checkbox v-model="scope.row.checked" :disabled='scope.row.auditState != 1' @change='bizTypeUpdate(scope.row)'></el-checkbox>
					</template>
				</el-table-column>
				<el-table-column label="操作">
					<template slot-scope="scope">
						<div v-if='scope.row.auditState == 1'>
							<router-link :to="`${basePath}/groupCommodityAuditDetails/${scope.row.skuNo}/${scope.row.goodsType}/3`">
								<el-button class='ml-0 tal' size="small" type='text'>审核</el-button>
							</router-link>
						</div>
						<div v-else>
							<router-link :to="`${basePath}/groupCommodityAuditDetails/${scope.row.skuNo}/${scope.row.goodsType}/4`">
								<el-button class='ml-0 tal' size="small" type='text'>查看</el-button>
							</router-link>
						</div>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination class="tar mr-30" v-if="total>0" :current-page="currentPage" :page-size='10' :total='total' layout="prev , pager, next, jumper" @current-change="handleCurrentChange">
				<!-- 分页组件 -->
			</el-pagination>
		</div>
		<!--驳回申请弹窗-->
		<el-dialog class='cancelOrderDialog' title='输入驳回申请理由' :visible.sync='rejectVisible'>
			<el-form class="pl-30 pr-30" :model="ruForm" ref="rejectForm" :rules="rules">
				<el-form-item label="" prop='rejectionReason'>
					<el-input type='textarea' placeholder='请输入驳回申请的理由...' :autosize="{ minRows: 3 ,maxRows: 10}" v-model='ruForm.rejectionReason'></el-input>
				</el-form-item>
			</el-form>
			<div slot='footer' class="dialog-footer">
				<el-button type='danger' @click="rejectCommit">提 交</el-button>
				<el-button @click="rejectVisible = false">取 消</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import { axiosApi, basePath } from "../../api/api";
	export default {
		data() {
			return {
				rules: {
					rejectionReason: [{
							required: true,
							message: '请输入驳回原因！'
						},
						{
							max: 30,
							message: '不能超过30个字！'
						}
					]
				},
				basePath: basePath,
				dataTabs: ["待审核", "审核通过", "审核未通过"],
				type: '0',
				Loading: true,
				auditState: '1', //审核商品状态
				count1: null, //审核角标
				count2: null, //审核角标
				tableData: [], //商品列表
				multipleSelection: [], //批量选中
				arr: [], //批量选中skuNo
				state: '', //批量审核状态
				ruForm: {
					rejectionReason: null
				}, //拒绝理由
				rejectVisible: false, //弹出框
				formInlineData: {
					storeName: null,
					skuName: null,
					id: '',
				}, //搜索条件
				searchClassify: [], //搜索分类
				currentPage: 1,
				total: 0,
				isPagination: true, //分页
				checked:false,
				isShow1:false
			}
		},
		methods: {
			handleTabs(e) {
				if(e.target.attributes.type) this.type = e.target.attributes.type.value
				this.currentPage = 1
				if(this.type == '0') {
					this.auditState = '1',
						this.achieve()
				}
				if(this.type == '1') {
					this.auditState = '2',
						this.achieve()
				}
				if(this.type == '2') {
					this.auditState = '3',
						this.achieve()
				}
			},
			//小B新增商品列表
			achieve(payload) {
				payload == 0 && (this.currentPage = 1)
				const api = "/groupon/smallGoods/list";
				const body = {
					sellerId: sessionStorage.traderId,
					auditState: this.auditState,
					skuName: this.formInlineData.skuName,
					storeName: this.formInlineData.storeName,
					classifyId: this.formInlineData.id
				}
				axiosApi(api, body, this.callback, this.currentPage, 10)
			},
			callback(res) {
				this.tableData = res.data.data.elements ? res.data.data.elements : [];
				this.tableData.forEach((item)=>{
					let _this = this;
					if(typeof item.checked == "undefined") {
						if(item.bizType == 0){
							_this.$set(item, "checked", false)
						}else{
							_this.$set(item, "checked", true)
						}
					}
				})
				this.total = res.data.data.totalCount ? res.data.data.totalCount : '';
				this.getCount()
				this.Loading = false;
			},
			//			分页
			handleCurrentChange(val) {
				this.currentPage = val
				this.achieve()
			},
			//查询分类
			classSearch() {
				const api = "/groupon/classify/selectList";
				const body = {}
				axiosApi(api, body, this.classsearch)
			},
			classsearch(res) {
				this.searchClassify = res.data.data;
			},
			//角标
			getCount() {
				const api = "/groupon/countSmallGoods/select";
				const body = {
					sellerId: sessionStorage.traderId
				}
				axiosApi(api, body, this.counts, 1, 10)
			},
			counts(res) {
				this.count1 = res.data.data.todoAuditStateCount; //待审核状态的数量
				this.count2 = res.data.data.noPassAuditStateCount; //未通过审核状态的数量
			},
			handleSelectionChange(val) { //table 选中行
				this.multipleSelection = val
			},
			getInfo(type) {
				if(this.multipleSelection.length == 0) {
					this.$message({
						title: '提示信息',
						message: '您未选中任何商品！',
						type: 'warning'
					});
				} else {
					let _this = this
					for(let i = 0; i < this.multipleSelection.length; i++) {
						_this.arr.push(this.multipleSelection[i].skuNo);
					}
					if(type == 2) {
						this.state = '2',
							this.allupperlower()
					} else {
						this.state = '3',
							this.rejectVisible = true
					}
				}
			},
			//审核
			audited() {
				const api = "/groupon/smallGoodsAudit/udpate";
				const body = {
					skuNos: this.arr,
					auditState: this.state,
					rejectionReason: this.ruForm.rejectionReason,
					sellerId: sessionStorage.traderId
				};
				axiosApi(api, body, (res) => {
					this.$message({
						type: 'success',
						message: '操作成功!',
						duration: 1000
					});
					this.arr = [],
						this.achieve()
				}, 1, 10, (res) => {
					this.$message({
						type: 'info',
						message: res.data.message,
						duration: 1000
					});
				})
			},
			//驳回申请理由提交
			rejectCommit() {
				this.$refs.rejectForm.validate((valid) => {
					if(valid) {
						this.rejectVisible = false
						this.audited();
					} else {
						return false
					}
				})
			},
			//批量审核通过
			allupperlower() {
				let that = this;
				this.$confirm('您是否要审核通过选中的商品？', '提示信息', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.audited()
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消操作'
					});
					this.arr = []
				})
			},
//			久加久自营
			bizTypeUpdate(val){
				console.log(val,'111')
				const api = "/groupon/bizType/update";
				const body = {
					skuNo:val.skuNo,
					bizType:val.bizType == '0' ? '1 ': '0'
				};
				axiosApi(api, body, (res) => {
					this.$message({
						type: 'success',
						message: '操作成功!',
						duration: 1000
					});
					this.achieve()
				}, 1, 10, (res) => {
					this.$message({
						type: 'info',
						message: res.data.message,
						duration: 1000
					});
				})
			},
				//是否显示自营选择按钮
			isShow(){
				axiosApi('/groupon/chooseBizType/display', {
						
					}, (res) => {
						this.isShow1 = res.data.data
					}, 1, 10, (res) => {
						this.$message.info(res.data.message);
					});
			}

		},
		//进入加载
		activated() {
			this.$nextTick(function() {
				this.achieve();
				this.classSearch();
				this.isShow();
			})
		}
	}
</script>

<style lang="less">
	#groupCommodityAudit {
		.table-default .el-button {
			width: auto;
		}
		.c {
			position: relative;
		}
		.el-tabs__header {
			padding-left: 0;
		}
		.count2 {
			position: absolute;
			left: 70px;
			top: 15px;
		}
		.count3 {
			position: absolute;
			left: 275px;
			top: 15px;
		}
		.red {
			color: red;
		}
		.w-400 {
			width: 400px;
		}
		.w-160 {
			width: 160px;
		}
		.el-dialog {
			min-height: 300px;
		}
		.width-70 {
			width: 70px;
		}
		.black {
			.edit-btn span {
				color: #000000 !important;
			}
		}
		.obt {
			margin-top: 80px;
			margin-right: 60px;
		}
	}
</style>