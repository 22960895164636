<template>
	<div>
		<!-- 酒店角色-积分管理-->
		<div class="title-tabs2 bg-f">
			<ul class="clearfix">
				<div v-for='(tab,index) in dataTabs' class="item" :key="index+'de'"  @click='handleTabs(index)'>
					<li :class="tab==clickedTabName?'active':''">
						{{tab}}
						<div :class="tab==clickedTabName?'under-line':''"></div>
					</li>
					<div class="vertical-line"></div>
				</div>
			</ul>
		</div>

		<!--积分提现-->
		<div id="integralWithdrawal" v-show="clickedTabName == '积分提现'">
			<div class="content bg-f pl-30 pr-30 mt-5">
				<div class="clearfix search">
					<el-form :inline='true' class="pt-30 fl" :model="form">
						<el-form-item label='申请时间'>
							<ys-daterange-picker v-model="form.addDateRange" :endDisabledDate="new Date()"/>
						</el-form-item>
						<el-form-item class="" label='销售员账号' :rules="[{pattern:/^[1]\d{10}$/,message:'请输入正确手机号',trigger:'change,blur'}]" prop="cNum">
							<el-input v-model.trim="form.cNum" placeholder="请输入销售员账号" :maxlength="11"></el-input>
						</el-form-item>
						<el-form-item label='提现状态' :rules="[{pattern:/^[1]\d{10}$/,message:'请输入正确手机号',trigger:'change,blur'}]" prop="cNum">
							<el-select v-model="form.withdrawState" placeholder="请选择">
								<el-option
										v-for="item in withdrawStateList"
										:key="item.value"
										:label="item.label"
										:value="item.value">
								</el-option>
							</el-select>
						</el-form-item>
					</el-form>
					<el-button class="fl ml-30 mt-30" type='info' @click="search">搜索</el-button>
				</div>

				<el-tabs v-model="activeTab1" @tab-click="handleTab1Click">
					<el-tab-pane label="未打款" name="1"></el-tab-pane>
					<el-tab-pane label="已打款" name="2"></el-tab-pane>
				</el-tabs>

				<div class="pb-30" v-loading="Loading">
					<el-button v-if='type == 0 && activeTab1 == "1"' type="text" class="fr" @click="handlePass({row:{}})">批量打款</el-button>
					<div v-else style="height: 32px;"></div>

					<el-table :data="elements" stripe style="width: 100%;" @selection-change="handleSelectionCheck">
						<el-table-column type="selection" v-if='type == 0 && activeTab1 == "1"'></el-table-column>
						<el-table-column label="申请单号" width="">
							<template slot-scope="scope">
								<div>{{scope.row.applyWithdrawSn}}</div>
							</template>
						</el-table-column>
						<el-table-column  label="销售员账号" width="120">
							<template slot-scope="scope">
								<span>{{scope.row.loginName}}</span>
							</template>
						</el-table-column>
						<el-table-column label="店铺名" width="90">
							<template slot-scope="scope">
								<span>{{scope.row.storeName}}</span>
							</template>
						</el-table-column>
						<el-table-column label="提现积分" width="">
							<template slot-scope="scope">
								<span>{{scope.row.withdrawMoney | fmoney}}</span>
							</template>
						</el-table-column>
						<el-table-column label="收款渠道" width="">
							<template slot-scope="scope">
								<div>{{scope.row.accountName}}</div>
								<div>({{scope.row.name}} {{scope.row.accountNumber}})</div>
							</template>
						</el-table-column>

						<el-table-column label="申请时间" width="">
							<template slot-scope="scope">
								<a>{{scope.row.gmtCreate | time}}</a>
							</template>
						</el-table-column>
						<el-table-column label="提现状态" width="90">
							<template slot-scope="scope">
								<!-- handle-处理中；fail-失败；success-成功-->
								<el-tooltip v-if="scope.row.withdrawState === 'fail'" class="item" effect="dark" :content="scope.row.failReason" placement="bottom-end">
									<el-button type="text">失败</el-button>
								</el-tooltip>
								<span v-else-if="scope.row.withdrawState === 'handle'">
									  <span v-if="scope.row.isOffline"><el-button type="text" @click="handlePass(scope)">确认打款</el-button></span>
									  <span v-else>处理中</span>
								</span>
								<span v-else>成功</span>
							</template>
						</el-table-column>
					</el-table>
					<!-- 分页组件 -->
					<el-pagination v-if="elements.length>0"
						class="tar"
						:current-page = "currentPage"
						:page-size = '10'
						:total = 'totalCount'
						layout = "prev , pager, next, jumper"
						@current-change='handleCurrentChange'>
				</el-pagination>
				</div>
			</div>
		</div>

		<!--积分明细-->
		<div id="integralSubsidiary" v-show="clickedTabName == '积分明细'">
			<div class="content bg-f mt-5">
				<div class="clearfix search">
					<el-form :inline='true' class="pt-30 fl" :model="searchForm" ref="searchForm">
						<el-form-item class="fl" label='销售员账号'>
							<el-input :maxlength="11" v-model.trim="searchForm.loginName" placeholder="请输入账号"></el-input>
						</el-form-item>
						<el-form-item class="ml-20" label='店铺名' >
							<el-input :maxlength="11" v-model.trim="searchForm.cName" placeholder="请输入店铺名"></el-input>
						</el-form-item>
					</el-form>
					<el-button class="fl ml-30 mt-30" type='info' @click="getCommList(true)">搜索</el-button>
				</div>
				<div class="pb-30" v-loading="loading">
					<el-table :data="elements2" stripe style="width: 100%;" @sort-change="changeSort" :empty-text="textShow">
							<el-table-column  label="销售员账号" width="">
								<template slot-scope="scope">
									<span>{{scope.row.loginName}}</span>
								</template>
							</el-table-column>
							<el-table-column label="店铺名" width="">
								<template slot-scope="scope">
									<span>{{scope.row.storeName}}</span>
								</template>
							</el-table-column>
							<el-table-column label="可结积分" width="" >
								<template slot-scope="scope">
									<span>{{scope.row.withdrawCommission | fmoney}}</span>
								</template>
							</el-table-column>
							<el-table-column label="累计收入" width="" >
								<template slot-scope="scope">
									<span>{{scope.row.allCommissionIncome | fmoney}}</span>
								</template>
							</el-table-column>
							<el-table-column label="待结算积分" width="">
								<template slot-scope="scope">
									<span>{{scope.row.unbalancedCommission | fmoney}}</span>
								</template>
							</el-table-column>
							<el-table-column label="已提积分" width="">
								<template slot-scope="scope">
									<span>{{scope.row.paymentCommission | fmoney}}</span>
								</template>
							</el-table-column>
							<el-table-column label="操作" width="">
									<template slot-scope="scope">
														<el-button type="text" @click="handleShow(scope.row)">查看</el-button>
												</template>
							</el-table-column>
					</el-table>
					<el-pagination v-if="elements2.length>0"
						class="tar"
            :current-page = "currentPage2"
            :page-size = '10'
            :total = 'totalCount2'
            layout = "prev , pager, next, jumper"
            @current-change='handleCurrentChange2'
            >
        </el-pagination>
				</div>
				<!-- 佣金记录弹窗 -->
				<el-dialog title="积分记录" class="show" :visible.sync="showList" width="1102px">
					<el-table v-if="commData.length>0" :data="commData" stripe style="width:90%;margin:0 5%;" :show-header='false' @expand-change="expand"  v-loading="loading1">
							<el-table-column label="" min-width="150">
								<template slot-scope="scope">
									<div>{{scope.row.operateTime | timeI}}</div>
								</template>
							</el-table-column>
							<el-table-column label="" min-width="270">
								<template slot-scope="scope">
									<div class="goods">
										<div class="goods-tit">{{scope.row.operateDesc}}</div>
										<div>{{scope.row.operateNoDesc}}</div>
									</div>
								</template>
							</el-table-column>
							<el-table-column label="" min-width="80">
								<template slot-scope="scope">
									<span>{{scope.row.operateMoney < 0 ? ' ':'+'}}{{scope.row.operateMoney}}</span>
								</template>
							</el-table-column>
							<el-table-column label="" min-width="99">
								<template slot-scope="scope">
									<span>可提积分：<br>{{scope.row.withdrawCommission}}</span>
								</template>
							</el-table-column>
							<el-table-column label="" min-width="99">
								<template slot-scope="scope">
									<span>积分余额：<br>{{scope.row.balanceCommission}}</span>
								</template>
							</el-table-column>
							<el-table-column label="" min-width="90">
								<template slot-scope="scope">
									<span>{{scope.row.typeNote}}</span>
								</template>
							</el-table-column>
							<el-table-column label="" min-width="120">
								<template slot-scope="scope">
									<span>{{scope.row.remark}}</span>
								</template>
							</el-table-column>
							<el-table-column label="" min-width="" type="expand" >
								<template slot-scope="props">
									<div class="jifen-list" v-if="props.row.type !== 0" v-loading="loading2">
										<el-table :data="props.row.applyData" stripe style="width:100%;">
                                            <el-table-column label="申请单号" width="">
                                                <template slot-scope="scope">
                                                    <div>{{scope.row.applyWithdrawSn}}</div>
                                                </template>
                                            </el-table-column>
											<el-table-column label="收款渠道" width="">
												<template slot-scope="scope">
													<div>{{scope.row.accountName}}</div>
													<div>({{scope.row.name}} {{scope.row.accountNumber}})</div>
												</template>
											</el-table-column>
											<el-table-column label="提现积分" width="">
												<template slot-scope="scope">
													<span>{{scope.row.withdrawMoney | fmoney}}</span>
												</template>
											</el-table-column>
											<el-table-column label="申请时间" width="">
												<template slot-scope="scope">
													<span>{{scope.row.gmtCreate | time}}</span>
												</template>
											</el-table-column>
											<el-table-column label="提现状态" width="" v-if="props.row.type !== 2">
												<template slot-scope="scope">
													<!-- handle-处理中；fail-失败；success-成功-->
													<el-tooltip v-if="scope.row.withdrawState === 'fail'" class="item" effect="dark" :content="scope.row.failReason" placement="bottom-end">
														<el-button type="text">失败</el-button>
													</el-tooltip>
													<span v-else>{{scope.row.withdrawState === 'handle' ? '处理中' : scope.row.withdrawState === 'success' ? '成功' : ''}}</span>
												</template>
											</el-table-column>
										</el-table>
									</div>
									<div class="order-list" v-if="props.row.type === 0" v-loading="loading2">
									<el-table>
										<el-table-column label='商品' min-width="170" align="center"></el-table-column>
										<el-table-column label='单价' min-width="85"></el-table-column>
										<el-table-column label='数量' min-width="85"></el-table-column>
										<el-table-column label='订单金额' min-width="85"></el-table-column>
										<el-table-column label='运费' min-width="85"></el-table-column>
										<el-table-column label='销售员积分' min-width="85"></el-table-column>
										<el-table-column label='买家' min-width="85"></el-table-column>
										<el-table-column label='手机号码' min-width="85"></el-table-column>
										<el-table-column label='积分到账时间' min-width="85"></el-table-column>
									</el-table>
									<div>
										<div class="table-item mt-10">
											<div class="clearfix table-item-top lh-20 bbd bg-e pl-20">
												<span class='fl'>订单编号：{{props.row.orderSn}}</span>
												<span class='fl color-6 ml-30' v-if="props.row.orderData.gmtCreate">下单时间：{{props.row.orderData.gmtCreate | time}}</span>
											</div>
											<div class="table-item-content clearfix bbd" style="display: flex;" >
												<div class=" clearfix" style="box-sizing: border-box;flex:4;" >
													<!--商品清单-->
													<div class="bb clearfix " style="padding: 20px 0 10px 0px;display:flex" v-for="(orderItem, index) in props.row.orderData.itemList" :key="index+'df'">
														<div class="fl" style="flex:2">
															<img v-if="orderItem.fileUrl" :src="orderItem.fileUrl" class="fl mr-20" />
															<img v-else src="../../../assets/goods.jpg" class="fl mr-20" />
															<p>{{orderItem.skuName}}</p>
															<p class="color-9">规格:1*{{orderItem.specInfoNum}}</p>
														</div>
														<div class="fl tac" style="flex:1">¥{{orderItem.originPrice | fmoney}}</div>
														<div class="fl tac" style="flex:1">{{orderItem.num}}</div>
													</div>
												</div>
												<div class="bl bb tac pt-20" style="flex:1;word-break: break-all">{{props.row.orderData.needPayMoney | fmoney}}</div>
												<div class="bl bb tac pt-20" style="flex:1;word-break: break-all">{{props.row.orderData.freightMoney | fmoney}}</div>
												<div class="bl bb tac pt-20" style="flex:1;word-break: break-all">{{props.row.orderData.commission | fmoney}}</div>
												<div class="bl bb tac pt-20" style="flex:1;word-break: break-all">{{props.row.orderData.addrUsername}}</div>
												<div class="bl bb tac pt-20" style="flex:1;">{{props.row.orderData.addrTel}}</div>
												<div class="bl bb tac pt-20" style="flex:1;">
													<div class="tac" v-if="props.row.orderData.commissionSettlementTime">{{props.row.orderData.commissionSettlementTime | timeI}}</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								</template>
							</el-table-column>
					</el-table>
					<!-- 分页组件 -->
					<el-pagination v-if="commData.length>0"
						class="tar mt-20"
						:current-page = "currentPage1"
						:page-size = '6'
						:total = 'totalCount1'
						layout = "prev , pager, next, jumper"
						@current-change='handleCurrentChange1'
						>
				</el-pagination>
				<div v-else class="text-none">
					该用户暂时没有积分记录
				</div>
				</el-dialog>
			</div>
		</div>

	</div>

</template>

<script>
	import { axiosApi, basePath } from "../../../api/api";
	export default {
		data() {
			return {
				clickedTabName: '',
				// 积分提现
				type:'0',
				dataTabs: [],
				activeTab1: '1',
				form: {
					addDateRange: [],
					cNum: null,
					auditState: '0',
					startTime:null,
					endTime:null,
					withdrawState: ''
				},
				elements:[],
				Loading: false,
				currentPage: 1,
				totalCount: 1,
				formC: {
					reason: null
				},
				pickerOptions: {
					disabledDate(time) {
						return time.getTime() > new Date();
					}
				},
				idList:[],//确认打款商户Id列表

				// 积分明细
				searchForm:{
					loginName: null,//账号
					cName: null,//名称
					orderColumn: null,//排序类型
					orderType: null,//升降序
				},
				loading: false,
				loading1: false,
				loading2: false,
				currentPage1: 1,
				currentPage2: 1,
				totalCount1: 0,
				totalCount2: 0,
				elements2:[],//列表数据
				commData: [],
				showList: false,//弹出框
				supercStoreId : null,
				textShow: '暂无数据',//提示语
				index: null,
				curRow: null,
				withdrawStateList: [
					{
						value: '',
						label: '全部'
					},
					{
						value: 'success',
						label: '成功'
					},
					{
						value: 'fail',
						label: '失败'
					},
					{
						value: 'handle',
						label: '处理中'
					}
				]
			}
		},
		computed: {
			showtab1() {
				if ( this.showButton('REWARD_WITHDRAW',130015) ) {
					return true
				}
			},
			showtab2() {
				if ( this.showButton('REWARD_DETAIL',130015) ) {
					return true
				}
			}
		},
		methods: {
			// 积分提现
			handleSelectionCheck(val){//选中的状态
				this.idList=[];//清空每次全选时添加的订单编号
				val.forEach(item=>{
					if(item.auditState==0){
						this.idList.push(item.id)
					}
				})
			},
      		handleCurrentChange(val){//分页
				this.currentPage = val;
				this.queryList(val)
			},
			handleTab1Click(tab, event) {
				this.form.addDateRange = [];
				this.form.cNum = '';
				this.currentPage = 1;
				if(this.activeTab1 == '1') {
					this.form.auditState = '0'
				} else if(this.activeTab1 == '2') {
					this.form.auditState = '1'
				}
				this.queryList();
			},
			handleTabs(index) {//头部Tab切换
				this.clickedTabName = this.dataTabs[index]
				//清空条件
				this.form.endTime = null
				this.form.addDateRange = []
				this.form.cNum = null
				this.form.startTime = null
				if(this.type == '0') {
					this.form.auditState = '0'
				} else if(this.type == '1') {
					this.form.auditState = '1'
				}
				this.form.withdrawState = ''

				this.searchForm.loginName = null
				this.searchForm.cName = null
				this.searchForm.orderColumn = null
				this.searchForm.orderType = null
				if (sessionStorage.isAdmin == '1' || (this.showButton('REWARD_WITHDRAW',130015) && this.showButton('REWARD_DETAIL',130015))) {
					this.clickedTabName == '积分提现' && this.queryList()//积分提现列表
					this.clickedTabName == '积分明细' && this.getCommList()//加载积分明细
				} else if(this.showButton('REWARD_WITHDRAW',130015)) {
					this.queryList()//积分提现列表
				} else if (this.showButton('REWARD_DETAIL',130015)) {
					this.getCommList()//加载积分明细
				}
			},
			queryList(x){
				this.Loading = true
				let body = {
					loginName:this.form.cNum,
					auditState:this.form.auditState ? this.form.auditState : '0',
					startTime:this.form.startTime,
					endTime:this.form.endTime,
          			supercType: 2,
					withdrawState: this.form.withdrawState
				}
				axiosApi('/superc/commissionWithdrawApply/selectPageList',body,res=>{
					this.Loading = false;
					this.elements = res.data.data.elements;
					this.totalCount = res.data.data.totalCount;
				},x?x:1,10)
			},
			search(){
        this.form.startTime = this.form.addDateRange[0] ? this.form.addDateRange[0].getTime():null
				this.form.endTime = this.form.addDateRange[1] ? this.form.addDateRange[1].getTime():null
				this.queryList()
			},
			timeFormat(value){
				var myDate = new Date(value);
				var year = myDate.getFullYear();
				var month = myDate.getMonth() + 1 < 10 ? '0' + (myDate.getMonth() + 1) : myDate.getMonth() + 1;
				var day = myDate.getDate() < 10 ? '0' + myDate.getDate() : myDate.getDate();
				return year + '-' + month + '-' + day;
			},
			handlePass(scope){//确认打款
				if(scope.row.id){//单个商品上下架数据处理
					this.idList = [];//处理每次添加的订单编号
					this.idList.push(scope.row.id);
				};
				if(this.idList.length>0){
					const h = this.$createElement
					this.$msgbox({
						title: '提示信息',
						message: h('p', {style: 'text-align:center'}, '请确认该笔积分已转账给用户？'),
						showCancelButton: true,
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						beforeClose: (action, instance, done) => {
							if(action === 'confirm') {
								axiosApi('/superc/commissionWithdrawApply/updateState',{idList:this.idList},res=>{
									this.queryList()//默认加载佣金提现列表
								},1,10)
								done()
							} else {
								done()
							}
							delete instance.$slots.default;
						}
					})
				}else{
					this.$message({type:'info',message:'请选择需要打款账户',duration:1000})
				}
			},

			// 积分明细
			getCommList(val){//久集佣金列表
				if(val&&this.currentPage2 != 1) {
					this.currentPage2 = 1;
					return
				}
				this.loading = true
				axiosApi('/superc/store/commission/select',{
					loginName: this.searchForm.loginName,
					storeName: this.searchForm.cName ,
					orderColumn: this.searchForm.orderColumn,
					orderType: this.searchForm.orderType,
					supercType: 2
				},(res)=>{
					this.loading = false
          this.totalCount2 = res.data.data.totalCount
					this.elements2 = res.data.data.elements ? res.data.data.elements : []
				},this.currentPage2,10,(res)=>{
					this.loading = false
				})
			},
      handleCurrentChange2(val){//分页
				this.currentPage2 = val;
				this.getCommList();
			},
			handleCurrentChange1(val){//弹窗分页
				this.currentPage1 = val;
				this.getCommDetaile();
			},
			changeSort(val){//排序
				let type = null;
				switch (val.column.label){
					case '总佣金':
			            type = 'allCommissionIncome'
			            break;
			        case '可提现佣金':
			            type = 'withdrawCommission'
			            break;
			        case '待结算佣金':
			            type = 'unbalancedCommission'
			            break;
			        case '已提佣金':
			            type = 'paymentCommission'
			            break;
				}
				this.searchForm.orderColumn = type;
				if(val.order=='ascending') {//升序
					this.searchForm.orderType = 1
				}else if(val.order=='descending') {//降序
					this.searchForm.orderType = 2
				}
				this.getCommList();//排序执行默认接口
			},
			getCommDetaile(val,id){//佣金明细查看
				this.loading1 = true
				axiosApi('/superc/commission/detail/select',{ supercStoreId: this.curRow.supercStoreId, financialCommissionId: this.curRow.id },(res)=>{
					this.loading1 = false
					this.totalCount1 = res.data.data.totalCount
					this.commData = res.data.data.elements ? res.data.data.elements : []
				},this.currentPage1,6,(res)=>{
					this.$message.error(res.data.message)
				})
			},
			handleShow(val){//弹出框传值
				this.showList = true;
				this.curRow = val
				this.supercStoreId = val.supercStoreId
				this.getCommDetaile(val.supercStoreId, val.id)
			},
			getArrivalDetail(val){//到账明细
				this.loading2 = true
				val.orderData = {
					itemList: [],
				}
				axiosApi('/superc/order/details/selectByOrderSn',{ orderSn: val.orderSn },(res)=>{
					this.loading2 = false
					val.orderData = res.data.data ? res.data.data : {
						itemList: []
					};
				},this.currentPage,10,(res)=>{
					this.loading2 = false
				})
			},
			getApplyDetail(val){//提现明细
				this.loading2 = true
				val.applyData = [];
				axiosApi('/finance/commission/selectCommissionWithdrawById',{ id: val.withdrawApplyId },(res)=>{
					this.loading2 = false;
					val.applyData.push(res.data.data);
				},this.currentPage,10,(res)=>{
					this.loading2 = false
				})
			},
			expand(val){//展开触发事件
				if(val.type=='0'){
					this.getArrivalDetail(val)//加载订单详情
				} else {
					this.getApplyDetail(val)//加载提现详情
				}
			}
		},
		mounted() {
			this.$nextTick(() => {
				if ( sessionStorage.isAdmin == '1' || ( this.showButton('REWARD_WITHDRAW',130015) && this.showButton('REWARD_DETAIL',130015) ) ) {
					this.dataTabs = ['积分提现', '积分明细']
					this.clickedTabName = this.dataTabs[0]
					this.queryList()//积分提现列表
				} else	if ( this.showButton('REWARD_WITHDRAW',130015) ) {
					this.dataTabs.push('积分提现')
					this.clickedTabName = this.dataTabs[0]
					this.queryList()//积分提现列表
				} else if ( this.showButton('REWARD_DETAIL',130015) ) {
					this.dataTabs.push('积分明细')
					this.clickedTabName = this.dataTabs[0]
					this.getCommList()//积分明细列表
				}
				this.clickedTabName = this.dataTabs[0]
			})
		},
	}
</script>

<style lang="less">

	#integralWithdrawal{

		.title-top{
			width: auto;
			padding-left: 30px;
		}
		.box-switch{
			box-sizing: border-box;
			width: 264px;
			height: 45px;
			line-height: 15px;
			margin-top: 15px;
			margin-right: 10px;
		}
		.content{
			.el-form--inline {
				// width: 650px;
				.el-form-item__label {
					font-size: 12px;
					width: 60px;
				}
				.order-datatimerange {
					width: 240px;
				}
			}
			.el-table .cell {
				text-align: center;
				padding: 10px 0;
				.el-button {
					height: 18px;
				}
				.el-button+.el-button {
					margin-left: 0;
				}
			}
			.el-textarea {
				.el-textarea__inner {
					border-radius: 0;
				}
			}
		}
		.color-20{
			color: #20a0ff;
		}
		.el-form-item__label{
			width: 70px !important;
		}
	}

	#integralSubsidiary{
        .order-list{
            .is-scrolling-none{
                display: none;
            }
        }
		.content{
			padding: 0px 30px 20px 20px;
			.el-table .cell{ text-align: center; }
		}
		.show{
			.el-pagination{
				padding-right: 45px ;
			}
			.el-dialog{
				width: 1020px;
				.el-dialog__body{
					padding: 30px 0 50px 0;
					max-height: 600px;
					overflow-y:auto;
					.goods{
						div{ text-align: left; }
						.goods-tit{
							width: 270px;
							/*超出部分显示...*/
							text-overflow: -o-ellipsis-lastline;
							overflow: hidden;
							text-overflow: ellipsis;
							display: -webkit-box;
              -webkit-line-clamp: 1;
              /* autoprefixer: off*/
              -webkit-box-orient: vertical; 
              /* autoprefixer: on*/
						}
					}
					.el-table__expanded-cell{ padding: 10px 5px; }
					.el-table__expand-column .cell:before{
						content: '详细';
						position: absolute;
						left: -8px !important;
						top: 13px !important;
						color: #20a0ff;
					}
					.el-table__expand-icon{
						-webkit-transform: rotate(90deg);
    					transform: rotate(90deg);
    				}
    				.el-table__expand-icon--expanded{
    					-webkit-transform: rotate(270deg);
    					transform: rotate(270deg);
    				}
					.el-table__expand-icon>.el-icon{ color: #20a0ff; }
					.el-table__empty-block{ min-height: 0; }
					.table-item {
						border: 1px solid #DDDDDD;
						font-size: 14px;
						border-bottom: none;
						.bl { border-left: 1px solid #DDD; }
						.table-item-content .pbl-20 { padding: 0px 0px 20px 20px; }
						.table-item-top { padding: 10px 0; }
						img { width: 80px; height: 80px; }
					}
				}
				.text-none {
					text-align: center;
					margin-top: 140px;
				}
			}
		}
		.el-form-item__label{
			width: 70px !important;
		}
	}
</style>
