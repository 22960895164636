import { axiosApi, basePath } from "./api";

// 获取客户最新价格
export function list2Order (data={}) {
  return new Promise((resolve, reject) => {
    axiosApi('/goods/sku/customerPrice/list2Order', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
}

// 订单删除
export function deleteOrder (data={}) {
  return new Promise((resolve, reject) => {
    axiosApi('/order/orders/delete', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
}

// 订单价格审核
export function priceAuditOrder (data={}) {
  return new Promise((resolve, reject) => {
    axiosApi('/order/modifyAuditState/edit', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
}

// 售后退货退款
export function preCreate (data={}) {
  return new Promise((resolve, reject) => {
    axiosApi('/order/serviceAfterSales/preCreate', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
}

// 仅退款
export function refundApply (data={}) {
  return new Promise((resolve, reject) => {
    axiosApi('/order/serviceAfterSales/refundApply', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
}


// 财务审核
export function confirmAfterSales (data={}) {
  return new Promise((resolve, reject) => {
    axiosApi('/order/serviceAfterSales/confirm', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
}

// 提货记录&发货记录
export function getDeliveryItem (data={}) {
  return new Promise((resolve, reject) => {
    axiosApi('/order/deliveryItem/selectList', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
}

// 取消提货
export function cancelDelivery (data={}) {
  return new Promise((resolve, reject) => {
    axiosApi('/order/deliveryItem/cancel', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
}







