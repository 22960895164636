<template>
  <el-dialog id="addbank" title="添加银行卡" :visible.sync="visible" @close="goPage" width="500px">
    <el-form label-width="158px" :model="form" ref="form1" :rules="rules">
      <el-form-item label="真实姓名:" prop="name">
        <span v-if="isname">{{form.name}}</span>
        <el-input v-else v-model.trim="form.name" placeholder="请输入姓名" :maxlength="32"></el-input>
      </el-form-item>
      <el-form-item label="身份证号:" prop="id">
        <span v-if="isname">{{form.id}}</span>
        <el-input v-else v-model.trim="form.id" placeholder="请输入身份证号" :maxlength="18"></el-input>
      </el-form-item>
    </el-form>
    <el-form label-width="158px" :model="form" ref="form" class="pb-50" :rules="rules">
      <el-form-item label="银行卡号:" prop="card">
        <el-input v-model.trim="form.card" placeholder="请输入银行卡号" :maxlength="19"></el-input>
      </el-form-item>
      <el-form-item label="有效期:" v-if="iscredit" required>
        <el-col :span="10">
          <el-form-item prop="mval">
            <el-select v-model="form.mval" placeholder="请选择" size="mini" clearable>
              <el-option
                v-for="item in form.mounth"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
            <span class="ml-5">月</span>
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item prop="yval">
            <el-select v-model="form.yval" placeholder="请选择" size="mini" clearable>
              <el-option
                v-for="item in form.year"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
            <span class="ml-5">年</span>
          </el-form-item>
        </el-col>
      </el-form-item>
      <el-form-item label="卡验证码:" v-if="iscredit" prop="cardCode">
        <el-input v-model.trim="form.cardCode" placeholder="卡背后三位"></el-input>
      </el-form-item>
      <el-form-item label="手机号:" prop="phone">
        <el-input v-model.trim="form.phone" placeholder="请输入手机号" :maxlength="11"></el-input>
      </el-form-item>
      <el-form-item label="短信验证码:" prop="code">
        <el-input v-model.trim="form.code" style="width:120px" @change="clearmsg"></el-input>
        <el-button
          class="ml-20 wa"
          @click="sendnum({type:3,mobile:form.phone})"
          :disabled="disabled || form.phone.length==0"
        >
          获取验证码
          <span v-if="timeb">{{time | seconds}}</span>
        </el-button>
        <p v-if="errormsg.length>0" class="color-danger lh-14 mt-5">{{errormsg}}</p>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="danger" @click="sure">确定</el-button>
      <el-button @click="goPage">取消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { axiosApi, basePath } from "~/api/api";
import { mapActions, mapMutations } from "vuex";
export default {
  props: ["dialogvisible", "page"],
  data() {
    return {
      visible: false, //彈窗是否关闭
      form: {
        mounth: [
          {
            label: "01",
            value: "1"
          },
          {
            label: "02",
            value: "2"
          },
          {
            label: "03",
            value: "3"
          },
          {
            label: "04",
            value: "4"
          },
          {
            label: "05",
            value: "5"
          },
          {
            label: "06",
            value: "6"
          },
          {
            label: "07",
            value: "7"
          },
          {
            label: "08",
            value: "8"
          },
          {
            label: "09",
            value: "9"
          },
          {
            label: "10",
            value: "10"
          },
          {
            label: "11",
            value: "11"
          },
          {
            label: "12",
            value: "12"
          }
        ],
        mval: null,
        yval: null,
        year: [],
        id: "",
          card:'',
        name: "",
        phone: "",
          code:''
      },
      isname: null, //是否实名
      ispage: null, //判断是否从银行卡管理页面过来
      iscredit: false, //判断是否是信用卡
      rules: {
        name: [
          {
            required: true,
            message: "请输入姓名",
              trigger: 'blur'
          },
          {
            pattern: /^([A-Za-z]|[\u4E00-\u9FA5]|[\·]){2,32}$/,
            message: "请输入您的真实姓名",
              trigger: 'blur'
          }
        ],
        id: [
          {
            required: true,
            message: "请输入身份证号",
              trigger: 'blur'
          },
          {
            pattern: /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/,
            message: "请输入正确的身份证号",
              trigger: 'blur'
          }
        ],
        card: [
          {
            required: true,
            pattern: /^\d{16,19}$/,
            message: "请输入正确的银行卡号",
              trigger: 'blur'
          }
        ],
        phone: [
          {
            required: true,
            message: "请输入银行预留手机号",
              trigger: 'blur'
          },
          {
            pattern: /^1\d{10}$/,
            message: "请输入正确的手机号",
              trigger: 'blur'
          }
        ],
        cardCode: [
          {
            required: true,
            pattern: /^\d{3}$/,
            message: "请输入正确的银行卡验证码",
              trigger: 'blur'
          }
        ],
        code: [
          {
            required: true,
            pattern: /^\d{6}$/,
            message: "请输入6位验证码",
              trigger: 'blur'
          }
        ],
        mval: [
          {
            required: true,
            message: "请选择",
              trigger: 'change'
          }
        ],
        yval: [
          {
            required: true,
            message: "请选择",
              trigger: 'change'
          }
        ]
      }
    };
  },
  mounted() {
    this.$nextTick(() => {
      //   if (sessionStorage.getItem("payform")) {
      //     this.isname = JSON.parse(sessionStorage.getItem("payform")).isVerified;
      //     this.form.id = JSON.parse(
      //       sessionStorage.getItem("payform")
      //     ).idCardNumber;
      //     this.form.name = JSON.parse(sessionStorage.getItem("payform")).realName;
      //   } else {
      //     axiosApi("/anon/cbs/account/employeeInfo", {}, res => {
      //       this.isname = res.data.data.isVerified;
      //       this.form.id = res.data.data.idCardNumber;
      //       this.form.name = res.data.data.realName;
      //     });
      //   }
      //   let a = new Date();
      //   let b = a.getFullYear();
      //   for (let i = 0; i < 10; i++) {
      //     this.form.year.push({
      //       label: b + i,
      //       value: i
      //     });
      //   }
    });
  },
  computed: {
    banklist() {
      return this.$store.state.paymodule.banklist;
    },
    errormsg() {
      return this.$store.state.paymodule.errormsg;
    },
    time() {
      return this.$store.state.paymodule.time;
    },
    timeb() {
      return this.$store.state.paymodule.timeb;
    },
    result() {
      return this.$store.state.paymodule.result;
    },
    disabled() {
      return this.$store.state.paymodule.disabled;
    }
  },
  watch: {
    dialogvisible(val) {
		if(val){
			this.getInfo()
		}
		//输入银行卡判断是否为信用卡,只有在银行卡管理页面需要判断
		this.ispage = this.page == "1";
		//this.iscredit = false //每次打开重置为银行卡表格形式
		this.clearmsg(); //将短信验证码错误信息清空，防止其他页面带过来
		this.cleartime();
		this.visible = val;
		setTimeout(() => {
			this.$refs.form.resetFields();
			if (!this.isname) this.$refs.form1.resetFields();
		}, 0);
    },
    result(val) {
      if (val) {
        this.$message.success("绑定成功");
        this.goPage();
      }
    },
    "form.phone": {
      handler: function(a) {
        let reg = /^1\d{10}$/;
        this.changedisabled(!reg.test(a));
      },
      deep: true
    }
  },
  methods: {
    ...mapActions({
      supportBanks: "supportBanks",
      bindCard: "bindCard",
      sendnum: "sendnum"
    }),
    ...mapMutations({
      clearmsg: "clearmsg",
      changedisabled: "changedisabled",
      cleartime: "cleartime"
    }),
    goPage() {
      this.$emit("closedialog");
    },
    getInfo() {
        axiosApi("/anon/cbs/account/employeeInfo", {}, res => {
        this.isname = res.data.data.isVerified;
        this.form.id = res.data.data.idCardNumber;
        this.form.name = res.data.data.realName;
        });

		let a = new Date();
		let b = a.getFullYear();
		for (let i = 0; i < 10; i++) {
			this.form.year.push({
			label: b + i,
			value: i
			});
		}
    },
    sure() {
      let a = true;
      if (!this.isname) {
        this.$refs.form1.validate(valid => (a = valid));
      }
      this.$refs.form.validate(valid => {
        if (valid && a) {
          console.log("ok");
          this.bindCard({
            realName: this.form.name,
            idCard: this.form.id,
            bankCardNo: this.form.card,
            bankCardMobile: this.form.phone,
            smsCode: this.form.code,
            err: 1
          });
        } else {
          console.log("fail");
        }
      });
    }
  }
};
</script>

<style lang="less">
#addbank {
 .el-dialog {
    width: 500px !important;
 }
  .wa {
    width: auto;
    padding: 0 5px;
  }
  .el-col-10 {
    width: 140px;
    .el-select .el-input--mini {
      width: 100px;
    }
  }
}
</style>