import {Message} from 'element-ui'

function Confirm() {
  this.status = true
}
Confirm.prototype.use = (function($message) {
  return function (rule,message) {
      switch(rule.length) {
      case 1: 
        if(rule[0] !== rule[0] || rule[0] == '' || rule[0] == undefined) {
          this.status = false
          $message({
            message: message,
            type: 'warning'
          });
        }
        return 
      case 2: 
        return 
      case 3: 
        if(rule[0] == rule[1]) {
          switch (typeof(rule[2])) {
            case 'string': 
              if(rule[2] == '') {
                  this.status = false
                  $message(message)
              }
              return 
            case 'object': 
              let c = rule[2]
              if(c == null || c.length == 0) {
                this.status = false
                $message(message)
              }
              return 
            case 'number': 
              let b = rule[2]
              if(b !== b) {
                this.status = false
                $message(message)
              }
          }
        }
        return
      case 4:
        return
      case 5: 
        if(rule[0] == rule[1]) {
          if(rule[2] == rule[3]) {
            switch (typeof(rule[4])) {
              case 'string': 
                if(rule[4] == '') {
                    this.status = false
                    $message(message)
                }
                return 
              case 'object': 
                let c = rule[4]
//              console.log(c)
                if(c.length == 0 ) {
                  this.status = false
                  $message(message)
                }
            }
          }
        }
        return 
      case 6:
        return 
      case 7:
        if(rule[0] == rule[1]) {
          if(rule[2] == rule[3]) {
            if(rule[4] == rule[5]) {
              switch (typeof(rule[6])) {
                case 'string': 
                  if(rule[6] == '') {
                      this.status = false
                      $message(message)
                  }
                  return 
                case 'object': 
                  let c = rule[6]
                  if(c.length == 0) {
                    this.status = false
                    $message(message)
                  }
              }
            }
          }
        }
      default: 
        return 
    }
  }
})(Message)

Confirm.prototype.cs = (function($message) {
  return function(type, value, Message) {
    switch (type) {
      case 'int': 
          var re = /[+-.]/;
          if(re.test(value)) {
            this.status = false
            $message({
              type: 'warning',
              message: Message + '必须为正整数，请重新输入'
            })
          }
        return 
      case 'dot': 
          var re = /[+-]/;
          if(re.test(value)) {
            this.status = false
            $message({
              type: 'warning',
              message: Message + '必须为正数，请重新输入'
            })
          }
          return 
      case 'float':
          var re = /[+-]/;
          if(re.test(value)) {
            this.status = false
            $message({
              type: 'warning',
              message: Message + '中存在+-符号，请重新输入'
            })
          }
          var ns = 0
          var arr = [...value]
          for(var i = 0; i< arr.length;i++) {
            if(arr[i] == '.') {
              ns++
            }
           }
           if(ns > 1) {
             this.status = false
             $message({
              type: 'warning',
              message: Message + '中存在多个小数点，请重新输入'
            })
           }
        return 
      default: 
        return 
    }
  }
})(Message)

export default Confirm;