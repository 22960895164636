<template>
	<div>
		<div class="title-top bg-f title-tabs">
			<span>{{detailTypeText}}库单详情</span>
		</div>

		<div id="stock-house-detail" class="bg-f" v-loading='loading'>
			<div class="order-info clearfix">
				<div class="base-info clearfix">
					<!--<div class="info1-item"><span>仓库信息</span><span>{{infoObj.warehouseName}}</span></div>-->
					<div class="info1-item" v-if="infoObj.stockType == 'stockIn'">
						<span>单据类型</span>
						<span v-if="infoObj.stockInType=='other'">其他入库</span>
						<span v-if="infoObj.stockInType=='purchase'">采购入库</span>
						<span v-if="infoObj.stockInType=='returned'">退货入库</span>
						<span v-if="infoObj.stockInType=='exchange'">退换入库</span>
					</div>
					<div class="info1-item" v-else>
						<span>单据类型</span>
						<span v-if="infoObj.stockOutType=='other'">其他出库</span>
						<span v-if="infoObj.stockOutType=='orders'">销售出库</span>
						<span v-if="infoObj.stockOutType=='ordersLock'">订单锁库</span>
						<span v-if="infoObj.stockOutType=='exchange'">换货出库</span>
					</div>
					<div class="info1-item"><span>{{detailTypeText}}库单号</span><span >{{infoObj.stockOrderNo}}</span></div>

					<div class="info1-item" v-if="!stockIn"><span>客户名称</span><span>{{infoObj.buyerName ? infoObj.buyerName : '无'}}</span></div>
					<div class="info1-item" v-if="!stockIn"><span>收货人</span><span>{{infoObj.addrUsername ? infoObj.addrUsername : '无'}}</span></div>
					<div class="info1-item" v-if="!stockIn"><span>联系电话</span><span>{{infoObj.addrTel ? infoObj.addrTel : '无'}}</span></div>
					<div class="info1-item" v-if="!stockIn"><span>收货地址</span>
						<span v-if="infoObj.addrProvince || infoObj.addr">{{infoObj.addrProvince}} {{infoObj.addrCity}} {{infoObj.addrRegion}} {{infoObj.addrStreet}} {{infoObj.addr}} </span>
						<span v-else>无</span>
					</div>

					<div class="info1-item" v-if="infoObj.orderSn && !stockIn"><span>关联销售单号</span><span>{{infoObj.orderSn}}</span></div>
					<div class="info1-item" v-if="infoObj.returnNo"><span>关联退货单号</span><span>{{infoObj.returnNo}}</span></div>

					<div class="info1-item" v-if="stockIn"><span>供应商</span><span>{{infoObj.sellerName ? infoObj.sellerName : '无'}}</span></div>
					<div class="info1-item"><span>{{detailTypeText}}库日期</span>
						<span v-if="infoObj.gmtCreate">{{infoObj.gmtCreate | time}}</span>
						<span v-else>-</span>
					</div>
					<div class="info1-item"><span>{{detailTypeText}}库备注</span><span>{{infoObj.remark ? infoObj.remark : '暂无数据'}}</span></div>
					<div class="info1-item"><span>操作人</span><span>{{infoObj.employeeName ? infoObj.employeeName : '无'}}</span></div>




				</div>
			</div>
			<div class="goods-block mt-20">
				<!-- 商品信息 -->
				<div class="clearfix" v-if="tabletotalData.length > 0">
					<!-- 商品列表 -->
					<div class="">
						<div class="clearfix item-title item-item">
							<div>
								<div class="order-item-item fl tac">
									商品名称
								</div>
								<div class="order-item-item-1 fl">
									规格
								</div>
								<div class="order-item-item-2 fl">
									分类
								</div>
								<div class="order-item-item-3 fl">
									仓库名称
								</div>
								<div class="order-item-item-4 fl">
									基本单位
								</div>
								<div class="order-item-item-5 fl">
									{{detailTypeText}}库数量
								</div>
								<div class="order-item-item-6 fl" v-if="stockIn">
									成本价
								</div>
								<div class="order-item-item-6 fl" v-if="!stockIn">
									单价
								</div>
								<div class="order-item-item-7 fl" v-if="stockIn">
									总成本
								</div>
								<div class="order-item-item-7 fl" v-if="!stockIn">
									金额
								</div>
							</div>
						</div>
						<div class="item-content">
							<!-- 商品列表 -->
							<div class="item-item" v-for="(i, index) in tabletotalData" :key="index" style="position:relative">
								<div class="clearfix ">
									<div class="clearfix order-item-item fl" style="position: relative">
										<img v-if='i.type == 2' width="80" src="../../assets/superGroup/complimentary.png" style="position: absolute;top: 0px;left: 0px;" />
										<img v-if="i.fileUrl" class="fl img mr-10" :src="i.fileUrl" />
										<img v-else src="../../assets/goods.jpg" class="fl img mr-10"/>
										<div class="fl">
											<p>商品编码：{{i.k3GoodsNo}}</p>
											<p>{{i.skuName}}</p>
										</div>
									</div>
									<div class="order-item-item-1 fl">
										1*{{i.specInfoNum}}
									</div>
									<div class="order-item-item-2 fl">
										{{i.classifyName}}
									</div>
									<div class="order-item-item-3 fl">
										{{infoObj.warehouseName}}
									</div>
									<div class="order-item-item-4 fl">
										{{i.baseUnitName}}
									</div>
									<div class="order-item-item-5 fl">
										{{i.amount}}
									</div>
									<div class="order-item-item-6 fl">
										{{i.enterShopPrice | fmoney}}
									</div>
									<div class="order-item-item-7 fl">
										<div v-if="i.type == 2 && detailTypeText == '出'">
											¥0.00
										</div>
										<div v-else>
											{{i.sellingPrice ? i.sellingPrice : i.amount * i.enterShopPrice | fmoney}}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { axiosApi, basePath } from "../../api/api"
	export default {
		data() {
			return {
				loading: false,
				tabletotalData: [],
				infoObj: {},
				getInfo: {},
				tabletotalData2: [],
				stockIn: false
			}
		},
		computed: {
			detailTypeText() {
				return this.$route.path.indexOf("/1") != -1 ? '入' : '出'
			},
			countNum() {
				let n = 0;
				this.infoObj.warehouseDeliveryVoucherItemList && this.infoObj.warehouseDeliveryVoucherItemList.forEach(item => {
					n += item.amount
				})
				return n;
			},
			countPrice() {
				let n = 0;
				this.infoObj.warehouseDeliveryVoucherItemList && this.infoObj.warehouseDeliveryVoucherItemList.forEach(item => {
					if(item.enterShopPrice && item.amount) {
						let mulNum = ((item.enterShopPrice) * (item.amount))
						n = n + mulNum;
					}
				})
				n = n.toFixed(2);
				return n;
			}
		},
		mounted() {
			this.stockIn = this.$route.path.indexOf('/1') != -1 ? true : false
			this.getInfo = this.$route.params
			axiosApi('/warehouse/deliveryVoucher/detail', {stockOrderNo: this.getInfo.no}, res => {
				this.infoObj = res.data.data
				this.tabletotalData = res.data.data.warehouseDeliveryVoucherItemList
			}, 1, 10, err => {
				this.$message.error(err.data.message)
			})
		}
	}
</script>

<style lang="less">

	#stock-house-detail {
		padding:15px 30px;
		box-sizing: border-box;
		.el-table__body-wrapper {
			display: none;
		}
		.wRight {
			width: ~"calc(100% - 94px)";
		}
		.order-info {
			margin: 0 auto;
			width: 100%;
			box-sizing: border-box;
			.title {
				height: 40px;
				line-height: 40px;
				background: #eee;
			}
			.base-info {
				.info1-item {
					display: flex;
					line-height: 44px;
					color: #333;
					& > span {
						&:nth-child(1) {
							margin-right: 20px;
              color: #666;
              min-width: 70px;
						}
					}
				}
			}
		}

		.goods-block {
			margin: 0 auto;
			// 商品，赠品
			.item-content {
				background: #F2F7FF;
				width: 100%;
				margin-bottom: 20px;

			}
			.item-title {
				background: #f6f6f6;
				padding: 20px 0;
				margin-bottom: 10px;
				text-align: center;
			}
			.item-item {
				padding: 20px 0 20px 20px;
				border-bottom: 1px dashed #DDD;
				box-sizing: border-box;
				&:nth-last-child(1) {
					border-bottom: 0;
				}
				&.item-title {
					border-bottom: 0;
				}
				& > div {
					display: flex;
					& > div {
						text-align: center;
					}
					.order-item-item {
						text-align: left;
						width: 350px;
						.img {
							width: 80px;
							height: 80px;
						}
						p {
							width: 260px;
						}
					}
					.order-item-item-1, .order-item-item-2,.order-item-item-4 {
						width: 60px;
					}
					.order-item-item-3 {
						width: 80px;
					}
					.order-item-item-5, .order-item-item-6,.order-item-item-7 {
						flex: 1;
					}
				}
			}
		}
		.remark-title {
			padding-bottom: 10px;
			line-height: 60px;
			color: #666;
			font-size: 14px;
			display: flex;
			span {
				display: inline-block;
				width: 80px;
			}
			& > div {
				flex: 1;
				line-height: 24px;
				padding: 20px 0;
			}
		}
	}
</style>
