<template>
	<div id="storeNumDetailEditor">
		<div class="title-top bg-f">
			门店编辑
		</div>
		<div class="bg-f mt-5">
			<div class="examine_reason" v-if="subsidiaryInfo.checkState === 2">驳回原因：{{subsidiaryInfo.backReason}}</div>
			<el-form :model="form" class="clearfix pt-20" label-width="200px">
				<el-form-item label="门店名称:" class="mb-30">
					<el-input v-model="subsidiaryInfo.storeName" readonly></el-input>
				</el-form-item>
				<el-form-item label="门店简称:" class="mb-30" v-if="subsidiaryInfo.storeShortName">
					<el-input v-model="subsidiaryInfo.storeShortName" readonly></el-input>
				</el-form-item>
				<el-form-item label="所在地区:">
					<el-form-item class="mb-20">
						<selectArea v-model="subsidiaryInfo.businessDistrictPath" @changeName="changeName"/>
					</el-form-item>
					<el-form-item>
						<el-input v-model="subsidiaryInfo.addr" class="w-300" :maxlength='50' :minlength='4'></el-input>
					</el-form-item>
				</el-form-item>
				<el-form-item label="地图定位:">
					<router-link :to="`${basePath}/mapLocation?key=detail&id=${id}`">
						<el-button class="go_map">{{subsidiaryInfo.signOnLongitude?'已定位，去查看>':'去获取经纬度和地址>'}}</el-button>
					</router-link>
				</el-form-item>
				<el-form-item label="营业执照编号:">
					<el-input v-model="subsidiaryInfo.licenseNum" readonly></el-input>
				</el-form-item>
				<el-form-item label="营业执照照片:">
					<el-upload class="one" action="/sunrise-gateway/oss/ossUpload" :file-list='licenseImgList' list-type="picture-card" :on-preview="handlePreview">
					</el-upload>
				</el-form-item>
				<el-form-item label="合作协议:">
					<el-upload :class="protocolImgList.length > 2 ? 'special':''" action="/sunrise-gateway/oss/ossUpload" :file-list='protocolImgList' list-type="picture-card" :on-preview="handlePreview" :on-success="handleLicensePhotoScucess" :before-upload="beforeUpload" :on-remove="handleRemoveLicense">
						<i class="el-icon-plus"></i>
					</el-upload>
				</el-form-item>
				<el-form-item label="子公司名称:">
					<el-input v-model="subsidiaryInfo.sellerName" readonly></el-input>
				</el-form-item>
                <el-form-item label="K3仓库编码:">
                    <el-input v-model="subsidiaryInfo.k3WarehouseNo"></el-input>
                </el-form-item>
				<el-form-item label="申请日期:">
					<span>{{subsidiaryInfo.applyDate | time}}</span>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" :loading="btnLoading" @click='getImgStr'>保存</el-button>
					<el-button @click='$router.push(path)'>返 回</el-button>
				</el-form-item>
			</el-form>
		</div>
		<el-dialog :visible.sync="dialogVisible" class="preview_dialog">
			<img :src="dialogImageUrl" alt="">
		</el-dialog>
	</div>
</template>
<script>
	import { axiosApi, basePath } from "../../../api/api";
	export default {
		data() {
			return {
				path: basePath + '/storeNumber',
				Loading: false,
				dialogVisible: false,
				dialogImageUrl: null,
				form: {
					id: null,
					checkResult: 1, //审核结果 0 驳回，1 审核通过
					backReason: null, //驳回原因
					sellerNo: null, //子公司编码
					storeType: null, //店铺类型 A-加久*久柜；B-久加久*连锁店；C-久加久*店中店
					storeName: null, //店铺名称
					storeShortName: null
				},
				streetName: '', //四级地址
				fourListsDelivey: [], //四级地址列表
				list: [],
				subsidiaryInfo: {
          k3WarehouseNo:'',
					businessDistrictPath: []
				},
				isCheck: 0, //0 查看 1 审核
				id: null,
				licenseImgList: [],
				protocolImgList: [],
				accreditImg: {
					url: null
				},
				btnLoading: false,
				upImg: []
			}
		},
		methods: {
			changeName(e){
				this.list = e
			},
			handlePreview(file) {
				this.dialogImageUrl = file.url;
				this.dialogVisible = true;
			},
			examineSave() {
				if(this.subsidiaryInfo.businessDistrictPath.length == 0 ) {
					this.$message({
						type: 'info',
						message: '请选择省市区街道(镇)',
						duration: 3000
					});
					return false
				}
				if((this.subsidiaryInfo && this.subsidiaryInfo.signOnLongitude == null) || !this.subsidiaryInfo) {
					this.$message({
						type: 'info',
						message: '请先上传经纬度',
						duration: 3000
					});
					return false
				}
				axiosApi("/trader/seller/updateStoreCodeProtocolImg", {
					id: this.id,
					protocolImgSrc: this.upImg.toString(),
					longitude: this.subsidiaryInfo.signOnLongitude,
					latitude: this.subsidiaryInfo.signOnLatitude,
					provinceId: this.subsidiaryInfo.businessDistrictPath[0],
					provinceName: this.list[0],
					cityId: this.subsidiaryInfo.businessDistrictPath[1],
					cityName: this.list[1],
					regionId: this.subsidiaryInfo.businessDistrictPath[2],
					regionName: this.list[2],
					addr: this.subsidiaryInfo.addr,
					streetName: this.list[3],
					streetId: this.subsidiaryInfo.businessDistrictPath[3],
          k3WarehouseNo: this.subsidiaryInfo.k3WarehouseNo
				}, res => {
					this.$message({
						type: 'success',
						message: '保存成功',
						duration: 3000
					});
					this.$router.push(this.path)
				}, res => {});
			},
			getStoreInfo() { //子公司编码维护列表
				const api = "/trader/seller/getStoreCodeCheck";
				const body = {
					id: this.id,
					isCheck: this.isCheck
				}
				axiosApi(api, body, (res) => {
					const a = res.data.data
					this.subsidiaryInfo = {...a}
					this.subsidiaryInfo.addrs = `${a.provinceName}${a.cityName}${a.regionName}`
					this.subsidiaryInfo.signOnLongitude = a.longitude
					this.subsidiaryInfo.signOnLatitude = a.latitude
					this.subsidiaryInfo.businessDistrictPath = []
					this.subsidiaryInfo.businessDistrictPath[0] = a.provinceId.toString()
					this.subsidiaryInfo.businessDistrictPath[1] = a.cityId.toString()
					this.subsidiaryInfo.businessDistrictPath[2] = a.regionId.toString()
					this.subsidiaryInfo.businessDistrictPath[3] = a.streetId.toString()
					if(sessionStorage.postdata) {
						this.subsidiaryInfo.signOnLongitude = JSON.parse(sessionStorage.postdata).signOnLongitude
						this.subsidiaryInfo.signOnLatitude = JSON.parse(sessionStorage.postdata).signOnLatitude
					} else {
						if(a.longitude) {
							let postdata = {
								signOnLongitude: a.longitude,
								signOnLatitude: a.latitude
							}
							sessionStorage.postdata = JSON.stringify(postdata)
						}
					}
					this.licenseImgList = [{
						url: a.licenseImg
					}]
					if(a.protocolImgSrc && (a.protocolImgSrc.indexOf('.jpg') > 0 || a.protocolImgSrc.indexOf('.jpeg') > 0 || a.protocolImgSrc.indexOf('.png') > 0)) {
						a.protocolImgSrc.split(',').forEach(item => {
							this.protocolImgList.push({
								url: item
							})
						})
					} else {
						this.protocolImgList = []
					}
					this.form = {
						id: a.id,
						checkResult: 1, //审核结果 0 驳回，1 审核通过
						backReason: null, //驳回原因
						sellerNo: a.sellerNo, //子公司编码
						storeType: a.storeType, //店铺类型 A-加久*久柜；B-久加久*连锁店；C-久加久*店中店
						storeName: a.storeName, //店铺名称
						storeShortName: a.storeShortName
					}
				}, 0, 10, err => {
					this.$message.warning(err.data.message)
				})
			},
			//上传照片操作
			beforeUpload(file) { //上传之前图片的判断
				const isImage = (file.type.indexOf('jpg') != -1 || file.type.indexOf('jpeg') != -1 || file.type.indexOf('png') != -1 || file.type.indexOf('bmp') != -1);
				const isLt4M = file.size / 1024 / 1024 < 4;
				const isLt0M = file.size / 1024 / 1024 > 0;
				if(!isImage) {
					this.$message.error('请上传格式为png或jpg的图片文件!');
				} else if(!isLt4M) {
					this.$message.error('上传图片大小不能超过 4MB!');
				} else if(!isLt0M) {
					this.$message.error('上传图片大小不能是 0MB!');
				}
				if(isLt4M && isLt0M && isImage) {
					this.loding = true;
				}
				return isImage && isLt4M && isLt0M;
			},
			/*上传照片*/
			handleLicensePhotoScucess(res, file, fileList) {
				this.protocolImgList.push({
					url: res.data[0]
				})
			},
			/*删除营业执照处理函数*/
			handleRemoveLicense(file, fileList) {
				this.protocolImgList = fileList
			},
			getImgStr() {

				if(this.protocolImgList.length == 0) {
					this.upImg = []
					this.examineSave()
				} else {
					this.protocolImgList.forEach((item) => {
						this.upImg.push(item.url)
					})
					this.examineSave()
				}

			},
		},
		//进入加载
		mounted() {
			this.$nextTick(function() {
				this.isCheck = this.$route.query.key === 'detail' ? 0 : 1
				this.id = this.$route.query.id
				this.getStoreInfo()
			})
		}
	}
</script>

<style lang="less">
.el-cascader-menu__wrap {
			height:225px !important;
		}
	#storeNumDetailEditor {
		
		.preview_dialog {
			.el-dialog {
				width: 80%;
			}
		}
		.el-dialog__body {
			box-sizing: border-box;
			padding: 30px 20px;
			text-align: center;
			img {
				max-width: 100%;
			}
		}
		.examine_reason {
			padding: 50px 114px 0;
			color: #D24C4C;
			font-size: 18px;
		}
		.w-440 {
			width: 440px;
			display: block;
		}
		.w-300 {
			width: 300px;
		}
		.avatar-uploader {
			display: inline;
		}
		.one {
			.el-upload--picture-card {
				display: none;
			}
			.el-upload-list__item-delete {
				display: none;
			}
		}
		.special {
			.el-upload--picture-card {
				display: none;
			}
		}
		.confirm {
			.el-dialog__body {
				padding: 30px 20px;
				text-align: center;
				font-size: 18px;
			}
			.el-dialog {
				min-height: 240px;
			}
		}
		.go_map {
			width: 170px;
			height: 32px;
			color: #20a0ff;
			font-size: 14px;
		}
	}
</style>