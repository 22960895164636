<template>
	<div id="mapLocation">
		<div class="title-top bg-f">地图定位</div>
        <div class="map_content bg-f mt-5 pl-30 pr-30 pb-50">
            <div class="clearfix pt-30" v-if="!readOnly">
                <div class="fl">
                    <span>地址搜索:</span>
                    <input type="text" ref="pickerInput" id="pickerInput" placeholder="输入地址">
                </div>
                <el-button type="primary" class="fr" @click="saveLocatione">保存位置</el-button>
            </div>
            <!-- 地图 -->
            <div class="pt-20">
                <div id="map"></div>
            </div>
        </div>
	</div>
</template>
<script>
	import { axiosApi, basePath } from "../../../api/api";
    import { setTimeout } from 'timers';
	export default {
		data() {
			return {
                map: null,
                postdata: {
					// checkaddress: '',//考勤点地点
					signOnLongitude: '',//经纬度
					signOnLatitude: '',//经纬度
                },
                readOnly: false,//只读模式
			}
		},
		//进入加载
		mounted() {
            let that = this
			this.$nextTick(function() {
                if(sessionStorage.postdata){
                    that.readOnly = JSON.parse(sessionStorage.postdata).read
                    // var map = new AMap.Map('map', {
                    //     zoom:17,//缩放级别
                    //     center: [JSON.parse(sessionStorage.postdata).signOnLongitude, JSON.parse(sessionStorage.postdata).signOnLatitude],
                    //     resizeEnable: true,
                    // });
                    that.map = new BMap.Map("map", {
                        coordsType: 3, // coordsType指定输入输出的坐标类型，3为gcj02坐标，5为bd0ll坐标，默认为5。
                    });
                    
                    that.map.centerAndZoom(new BMap.Point(JSON.parse(sessionStorage.postdata).signOnLongitude, JSON.parse(sessionStorage.postdata).signOnLatitude), 14);
                    
                    
                }else {
                    // var map = new AMap.Map('map', {
                    //     zoom:17,//缩放级别
                    //     resizeEnable: true,
                    // });
                    // 百度地图
                    that.map = new BMap.Map("map", {
                        coordsType: 3, // coordsType指定输入输出的坐标类型，3为gcj02坐标，5为bd0ll坐标，默认为5。
                    });
                    
                }
                var map = that.map
                map.enableScrollWheelZoom(true);
                map.enableDragging(); // 开启拖拽功能
                map.addEventListener('dragend', that.handleDragend); // 监听拖拽结束事件
                var ac = new BMap.Autocomplete(    //建立一个自动完成的对象
                    {"input" : "pickerInput"
                    ,"location" : map
                });
                var myValue;
                ac.addEventListener("onconfirm", function(e) {    //鼠标点击下拉列表后的事件
                    console.log(e,'eeeee')
                    var _value = e.item.value;
                    myValue = _value.province +  _value.city +  _value.district +  _value.street +  _value.business;
                    setPlace();
                });

                function setPlace(){
                    function myFun(){
                        var pp = local.getResults().getPoi(0).point;    //获取第一个智能搜索的结果
                        that.postdata = {
                            signOnLongitude: pp.lng,//经纬度
					        signOnLatitude: pp.lat,//经纬度
                        }
                        map.centerAndZoom(pp, 18);
                        that.map.clearOverlays()
                        map.addOverlay(new BMap.Marker(pp));    //添加标注
                    }
                    var local = new BMap.LocalSearch(map, { //智能搜索
                    onSearchComplete: myFun
                    });
                    local.search(myValue);
                }

			})
		},
		methods: {
            saveLocatione(){
                sessionStorage.postdata=JSON.stringify(this.postdata)
                this.$router.push({path:'storeNumDetailEditor',query:{key:'detail',id:this.$route.query.id}})
            },
            handleDragend(){
                this.map.clearOverlays()
                let center = this.map.getCenter(); // 获取新的中心点
                this.map.addOverlay(new BMap.Marker(center));    //添加标注

                this.postdata = {
                    signOnLongitude: center.lng,//经纬度
                    signOnLatitude: center.lat,//经纬度
                }
            },
		},
	}
</script>

<style lang="less">
	#mapLocation {
        .map_content{
            #pickerInput{
                z-index: 200;
                padding: 3px 10px;
                height: 24px;
                width: 240px;
                line-height: 24px;
                color: #666;
                border: 1px solid #dfdfdf;
            }
            #map{
                width: 100%;
                height: 500px;
            }
        }
	}
</style>