<template>
  <div id="invoice-dialog">
    <el-dialog
      title="发票详情"
      :visible.sync="dialogVisible"
      size=""
      @close="goback"
      >
      <div class="items">
        <div>发票类型: {{info.orderInvoiceVo && info.orderInvoiceVo.personalCompanyType == 1 ? '单位' : '个人'}}</div>
        <div v-if="info.orderInvoiceVo && info.orderInvoiceVo.personalCompanyType == 1">公司名称: {{info.orderInvoiceVo.companyHead}}</div>
        <div v-if="info.orderInvoiceVo && info.orderInvoiceVo.personalCompanyType == 1">纳税人识别号: {{info.orderInvoiceVo.taxpayerIdNo}}</div>
        <div v-if="info.orderInvoiceVo">发票金额: ¥{{info.orderInvoiceVo.needPayMoney}}</div>
        <div v-if="info.orderInvoiceVo">
          发票地址：
          {{info.orderInvoiceVo.linkman}}
          {{info.orderInvoiceVo.tel}}
          {{info.orderInvoiceVo.provinceName}}
          {{info.orderInvoiceVo.cityName}}
          {{info.orderInvoiceVo.regionName}}
          {{info.orderInvoiceVo.addr}}
        </div>
      </div>
      <!--<div slot="footer" class="dialog-footer">-->
        <!--<el-button @click="goback">取 消</el-button>-->
      <!--</div>-->
    </el-dialog>
  </div>
</template>

<script>
  export default {
    props: ["info", "visible", 'yunfei'],
    data() {
      return {
        dialogVisible: false
      }
    },
    watch: {
      visible(val) {
        this.dialogVisible = val
      }
    },
    methods: {
      goback() {
        this.$emit('close')
      }
    }
  }
</script>

<style lang="less" scoped>
  .items {
      width: 360px;
      margin: 0 70px;
      &>div {
        margin-top: 12px;
      }
    }
</style>