<template>
	<div id="groupAllGoods">
		<div class="title-tabs bg-f black mb-5">
			<ul class="clearfix">
				<template v-for='(tab,index) in dataTabs' class="item">
					<li :key="tab.type" :type='tab.type' :class="type==tab.type?'active':''" @click='handleTabs(tab.type)'>
						{{tab.name}}
						<div :class="type==tab.type?'under-line':''"></div>
					</li>
					<div :key="'line'+tab.type" class="vertical-line"></div>
				</template>
			</ul>
		</div>
		<!-- 团购商品 -->
		<groupGoods v-if="type == '1'"/>
		<!-- 平台商品 -->
		<platformGoods  v-if="type == '2'"/>
	</div>
</template>

<script>
	import { axiosApi, basePath } from "../../api/api";
	import groupGoods from './group/groupGoods.vue'
	import platformGoods from './group/platformGoods.vue'
	export default {
		components:{
			groupGoods,
			platformGoods
		},
		data() {
			
			return {
				dataTabs: [
					{name: '团购商品', type:'1'},
					{name: '平台商品', type:'2'}
				],
				type:'1',
			}
		},
		methods:{
			handleTabs(type){
				this.type = type
			},
		},
	}
</script>

<style lang="less">
	#groupAllGoods {
		.second-title {
			height: 60px;
			padding: 17px 0px 17px 0px;
			box-sizing: border-box;
			border-bottom: 1px solid #EEEEEE;
		}
		.choose-three {
			width: 114px;
			height: 26px;
			line-height: 26px;
		}
		.actived {
			border: 1px solid #FF4201;
			color: #FF4201;
		}
		/*平台商品*/
		#groupProduct {
			.el-form-item__label {
				width: 100px;
			}
			.table-default .el-button {
				width: auto;
			}
			.c {
				position: relative;
			}
			.count4 {
				position: absolute;
				left: 245px;
				top: 15px;
			}
			.el-tabs__header {
				padding-left: 0;
			}
			.count2 {
				position: absolute;
				left: 325px;
				top: 15px;
			}
			.count3 {
				position: absolute;
				left: 435px;
				top: 15px;
			}
			.red {
				color: red;
			}
			.w-400 {
				width: 400px;
			}
			.w-160 {
				width: 160px;
			}
			.el-dialog {
				min-height: 300px;
			}
			.width-70 {
				width: 70px;
			}
			.black {
				.edit-btn span {
					color: #000000 !important;
				}
			}
			.obt {
				margin-top: 80px;
				margin-right: 60px;
			}
		}
		/*end*/
		/*商家商品*/
		#groupCommodityAudit {
			.table-default .el-button {
				width: auto;
			}
			.c {
				position: relative;
			}
			.el-tabs__header {
				padding-left: 0;
			}
			.count2 {
				position: absolute;
				left: 70px;
				top: 15px;
			}
			.count3 {
				position: absolute;
				left: 275px;
				top: 15px;
			}
			.red {
				color: red;
			}
			.w-400 {
				width: 400px;
			}
			.w-160 {
				width: 160px;
			}
			.el-dialog {
				min-height: 300px;
			}
			.width-70 {
				width: 70px;
			}
			.black {
				.edit-btn span {
					color: #000000 !important;
				}
			}
			.obt {
				margin-top: 80px;
				margin-right: 60px;
			}
		}
		/*end*/
		#inventory {
			.table-default .el-button {
				width: auto;
			}
			.el-tabs__header {
				padding-left: 0;
			}
			.w-160 {
				width: 160px;
			}
			.el-dialog {
				min-height: 300px;
			}
		}
	}
</style>