<template>
  <div class="credit-detail">
    <div class="title-top bg-f">
			授信明细
		</div>
    <div class="mt-10 bg-f pt-30 pb-20 pl-20 pr-20">
      <el-table :data="tableListDialog" border tooltip-effect="dark" stripe class="mt-20 table-default">
        <el-table-column :resizable="false" width="150" prop="gmtCreate" label="创建时间">
          <template slot-scope="scope">
            {{scope.row.gmtCreate | time}}
          </template>
        </el-table-column>
        <el-table-column :resizable="false" width="200" prop="creditExplain" label="交易详情"></el-table-column>
        <el-table-column :resizable="false" prop="changeMoney" label="明细"></el-table-column>
        <el-table-column :resizable="false" prop="leftCreditMoney" label="可用额度"></el-table-column>
        <el-table-column :resizable="false" prop="creditMoney" label="总额度"></el-table-column>
        <el-table-column :resizable="false" prop="typeName" label="类型">
        </el-table-column>
        <el-table-column :resizable="false" prop="operatorName" label="操作人">
          <template slot-scope="scope">
            {{scope.row.operatorName || '--'}}
          </template>
        </el-table-column>
      </el-table>
      <div class="pageBox">
        <el-pagination class="tar" v-if='tableListDialog.length>0'
          :current-page="currentPageDialog"
          :page-size='10'
          :total='totalDialog'
          layout="prev, pager, next, jumper"
          @current-change="handlePageChangeDialog">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { axiosApi, basePath } from "../../../api/api";
export default {
  data() {
    return {
      tableListDialog : [], // 浮层表格数据
      currentPageDialog : 1, // 浮层当前页数
      totalDialog : '', // 浮层数据 总数量
    }
  },
  methods: {
    // 初始化 浮层表格 数据
    initTableListDialog() {
      axiosApi('/superc/credit/manage/logPageList',{
        id : this.$route.query.id,
      },(res) => {
        let data = res.data.data;
        if(data){
          this.tableListDialog = data.elements || [];
          this.totalDialog = data.totalCount;
        }
      },this.currentPageDialog,10);
    },
    // 浮层分页 change
    handlePageChangeDialog(val) {
      this.currentPageDialog = val;
      this.initTableListDialog();
    },
  },
  mounted() {
    this.initTableListDialog()
  }
}
</script>

<style lang="less">
.credit-detail{
  padding: 20px 20px;
}
</style>

