<template>
	<div id="groupCompanyAudit" class="pb-50">
		<div class="title-top bg-f">企业管理</div>
		<div class='bg-f mt-5'>
			<div class="ml-30 mr-30 second-title clearfix">
				<div class="choose-three font-14 color-6 fl tac mr-10 cursor " :class="first == 0?'actived':''" @click="getStatus(0)">
					全部企业({{count0}})
				</div>
				<div class="choose-three font-14 color-6 tac fl mr-10 cursor" :class="first == 4?'actived':''" @click="getStatus(4)">
					未参团({{count4}})
				</div>
				<div class="choose-three font-14 color-6 tac fl cursor" :class="first == 5?'actived':''" @click="getStatus(5)">
					已参团({{count5}})
				</div>
				<div class="choose-three font-14 color-6 tac fl cursor" :class="first == 1?'actived':''" @click="getStatus(1)">
					待审核({{count1}})
				</div>
				<div class="choose-three font-14 color-6 tac fl cursor" :class="first == 3?'actived':''" @click="getStatus(3)">
					未通过({{count2}})
				</div>
			</div>
		</div>
		<div class="bg-f pb-50 pl-30 pr-30">
			<el-form :inline="true" :model="form" label-width="90px" class="clearfix pt-30 search">
				<div class="fl">
					<el-form-item label="企业名称">
						<el-input v-model.trim="form.companyName"></el-input>
					</el-form-item>
					<el-form-item label="商家">
						<el-input v-model.trim="form.storeName" :maxlength="20"></el-input>
					</el-form-item>
				</div>
				<el-button type="info" class="fl ml-50" @click='achieve(0)' icon="el-icon-search">搜 索</el-button>
			</el-form>

			<div style="text-align:right">
				 <el-link type="primary" :underline="false" @click="()=>{this.$router.push('addEnterprise')}">新增企业</el-link>
			</div>
			<el-table :data="tableData" v-loading="Loading" class='mt-10 table-default' >
				<el-table-column label="企业名称" min-width="190">
					<template slot-scope="scope">
						<div>{{scope.row.companyName}}</div>
						<div>组织代码:{{scope.row.organizationCode ? scope.row.organizationCode : '--'}}</div>
					</template>
				</el-table-column>
				<el-table-column label="商家" min-width="250" prop='storeName'></el-table-column>
				<el-table-column label="企业人数" min-width="150" prop='num'></el-table-column>
				<el-table-column label="平均收入" min-width="150">
					<template slot-scope="scope">
						<div>￥{{scope.row.averageRevenue}}</div>
					</template>
				</el-table-column>
				<el-table-column label="操作" min-width="120">
					<template slot-scope="scope">
						<div>
							<el-button @click='isExist(scope.row.id,1)' v-if='scope.row.auditStatus==1 && showButton("COMPANY_MANAGEMENT_AUDIT",140016)' class='ml-0 tal' size="small" type='text'>审核</el-button>
							<el-button @click='isExist(scope.row.id,1)' v-else-if='scope.row.auditStatus==2 || scope.row.auditStatus==3' class='ml-0 tal' size="small" type='text'>查看</el-button>
							<el-button @click='isExist(scope.row.id,2)' v-else class='ml-0 tal' size="small" type='text'>查看</el-button>
						</div>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination class="tar mt-20" v-if="total>0" :current-page="currentPage" :page-size='10' :total='total' layout="prev , pager, next, jumper" @current-change="handleCurrentChange">
				<!-- 分页组件 -->
			</el-pagination>
		</div>
	</div>
</template>

<script>
	import { axiosApi, basePath } from "../../api/api";
	export default {
		data() {
			return {
				basePath: basePath,
				type: '0',
				Loading: true,
				activeTab: '1',
				tableData: [], //商品列表
				searchClassify: [], //搜索分类
				//				分页
				currentPage: 1,
				total: 0,
				isPagination: true,
				auditStatus: null,
				form: {
					companyName: null,
					storeName: null
				},
				count0:null,
				count1: null,
				count2: null,
				count3: null,
				count4: null,
				count5: null,
				id: '',
				sign: '1',
				first:0,
				identity:''
			}
		},
		methods: {
			handleTabs(e) {
				if(e.target.attributes.type) this.type = e.target.attributes.type.value
				this.currentPage = 1
				if(this.type == '0') {
					this.auditStatus = null
					this.achieve()
				}
				if(this.type == '1') {
					this.auditStatus = 1
					this.achieve()
				}
				if(this.type == '4') {
					this.auditStatus = 4
					this.achieve()
				}
				if(this.type == '3') {
					this.auditStatus = 3
					this.achieve()
				}
				if(this.type == '5') {
					this.auditStatus = 3
					this.achieve()
				}

			},
			getStatus(item) {
				this.first = item
				this.currentPage = 1
				if(this.first == '0') {
					sessionStorage.removeItem("groupCompanyAudit");
					this.auditStatus = null
					this.achieve()
				}
				if(this.first == '1') {
					sessionStorage.removeItem("groupCompanyAudit");
					this.auditStatus = 1
					this.achieve()
				}
				if(this.first == '4') {
					sessionStorage.removeItem("groupCompanyAudit");
					this.auditStatus = 4
					this.achieve()
				}
				if(this.first == '3') {
					sessionStorage.removeItem("groupCompanyAudit");
					this.auditStatus = 3
					this.achieve()
				}
				if(this.first == '5') {
					sessionStorage.removeItem("groupCompanyAudit");
					this.auditStatus = 5
					this.achieve()
				}
				
			},
			//加载企业列表
			achieve(payload) {
				payload == 0 && (this.currentPage = 1)
				const api = "/groupon/companyAuditPage/select";
				const body = {
					traderId: sessionStorage.traderId,
					auditStatus: sessionStorage.groupCompanyAudit ? sessionStorage.groupCompanyAudit : this.auditStatus,
					companyName: this.form.companyName,
					storeName: this.form.storeName
				}
				axiosApi(api, body, this.callback, this.currentPage, 10)
			},
			callback(res) {
				this.Loading = false;
				this.tableData = res.data.data.elements ? res.data.data.elements : [];
				this.total = res.data.data.totalCount ? res.data.data.totalCount : '';
				this.getCount()
				if(sessionStorage.groupCompanyAudit) {
					sessionStorage.removeItem("groupCompanyAudit");
				}
			},
			//			分页
			handleCurrentChange(val) {
				this.currentPage = val
				this.achieve()
			},
			//角标
			getCount() {
				const api = "/groupon/countCompanyAudit/select";
				const body = {
					traderId: sessionStorage.traderId,
					companyName: this.form.companyName,
					storeName: this.form.storeName
				}
				axiosApi(api, body, this.counts, 1, 10)
			},
			counts(res) {
				this.count0 = res.data.data.all; //全部
				this.count1 = res.data.data.pengdingNum; //待审核状态的数量
				this.count2 = res.data.data.fallureNum; //未通过审核状态的数量
				this.count4 = res.data.data.notIn; //未参团
				this.count5 = res.data.data.in; //已参团
			},
			//判断企业是否存在
			isExist(id, sign) {
				this.id = id
				this.sign = sign
				const api = "/groupon/companyDetail/select";
				const body = {
					id: id

				}
				axiosApi(api, body, this.cooCallback, this.currentPage, 10)
			},
				//登录信息
			isShow(){
				axiosApi('/groupon/sellerType/select', {
						id:sessionStorage.traderId
					}, (res) => {
						this.identity = res.data.data
					}, 1, 10, (res) => {
						this.$message.info(res.data.message);
					});
			},
			cooCallback(res) {
				if(res.data.data) {
					this.$router.push(basePath + '/groupCompanyDetails/' + this.id + '/' + this.sign)
				} else {
					this.$message({
						type: 'info',
						message: '该企业已被删除！'
					});
				}

			}
		},
		//进入加载
		activated() {
			this.$nextTick(function() {
                // this.first = 0
				this.isShow()
				this.achieve();
				if(sessionStorage.groupCompanyAudit) {
					this.type = sessionStorage.groupCompanyAudit
					this.auditStatus = sessionStorage.groupCompanyAudit
				}
			})
		}
	}
</script>

<style lang="less">
	#groupCompanyAudit {
		.table-default .el-button {
			width: auto;
		}
		.c {
			position: relative;
		}
		.count4 {
			position: absolute;
			left: 135px;
			top: 15px;
		}
		.el-tabs__header {
			padding-left: 0;
		}
		.count3 {
			position: absolute;
			left: 340px;
			top: 15px;
		}
		.red {
			color: red;
		}
		.w-400 {
			width: 400px;
		}
		.w-160 {
			width: 160px;
		}
		.el-dialog {
			min-height: 300px;
		}
		.width-70 {
			width: 70px;
		}
		.black {
			.edit-btn span {
				color: #000000 !important;
			}
		}
		.obt {
			margin-top: 80px;
			margin-right: 60px;
		}
		.second-title {
			height: 60px;
			padding: 17px 0px 17px 0px;
			box-sizing: border-box;
			border-bottom: 1px solid #EEEEEE;
		}
		.choose-three {
			width: 114px;
			height: 26px;
			line-height: 26px;
		}
		.actived {
			border: 1px solid #FF4201;
			color: #FF4201;
		}
	}
</style>