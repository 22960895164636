<template>
    <div id="chooseGift">
        <el-dialog :title="isadditional?'选择换购商品':'选择赠品'" :visible.sync="productVisible" @close="goPage">
            <div v-loading="tableloading">
                <el-form :inline="true" :model="skuObj" label-width="80px" class="clearfix" :style="{width:'850px'}">
                    <div class="fl">
                        <el-form-item label="商品编码:">
                            <el-input v-model.trim="skuObj.skuNo" @input="skuObj.skuNo=skuObj.skuNo.replace(/[\u4e00-\u9fa5]/ig,'')"></el-input>
                        </el-form-item>
                        <el-form-item label="商品名称:">
                            <el-input v-model.trim="skuObj.skuName"></el-input>
                        </el-form-item>
                        <el-form-item label="商品分类:">
                            <el-select v-model='skuObj.classifyId' clearable placeholder='请选择'>
                                <el-option v-for='item in classifylist' :key='item.id'
                                           :label='item.classifyName'
                                           :value='item.id'></el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                    <el-button type="primary" class="fl ml-50" @click="querygift">查询</el-button>
                </el-form>
                <el-table ref="goodsList" @selection-change="handleSelectionChange" :data="proData" stripe border
                          tooltip-effect="dark" class="mt-10 table-default" >
                    <el-table-column type="selection" width="50" :selectable="selectInit">
                    </el-table-column>
                    <el-table-column label="商品编码" prop='skuNo'>
                        <template slot-scope="scope">
                            <p v-if="!isCityPartner">{{ scope.row.skuNo }}</p>
                            <p v-else>{{ scope.row.goodsNo }}</p>
                        </template>
                    </el-table-column>
                    <el-table-column label="商品名称" prop='skuName' width="200">
                    </el-table-column>
                    <el-table-column label="基本单位" prop="baseUnitName" >
                    </el-table-column>
                    <el-table-column label="库存(基本)" :prop="warehouse && warehouse.no ? 'stock' : 'giftStock'">
                    </el-table-column>
                    <el-table-column label="规格单位" prop="integerUnitName" >
                    </el-table-column>
                    <el-table-column label="库存(规格)" :prop="warehouse && warehouse.no ? 'specStock' : 'giftSpecStock'">
                    </el-table-column>
                    <el-table-column label="价格" >
                        <template slot-scope="{row}">
                            ￥{{ row.giftPrice }}/{{row.baseUnitName }}
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination class="tar" v-if='total>0'
                               :current-page="currentPage"
                               :page-size='10'
                               :total='total'
                               layout="prev , pager, next, jumper"
                               @current-change="handlePageChange">
                </el-pagination>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" :loading="btnLoading" @click="addgift">确 定</el-button>
                <el-button @click="goPage">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {mapActions} from 'vuex'
    import {axiosApi, basePath} from '../api/api' //导入axiosApi
    export default {
        props: ["giftVisible", 'goodsNum', 'sn','isSubstituteOrder', 'isadditional', 'skuNoList', 'isfullgift','ischangedorder',"wareHouseId","warehouse"],
        data() {
            return {
                currentPage: 1,
                skuObj: {
                    skuNo: '',     //商品(sku)编码
                    skuName: '',     //商品名称
                    classifyId: '',   //商品分类
                    notId: []  //id过滤
                },
                radio: '',
                loading: false,     //搜索时
                currentRow: [],
                addlists: [],
                productVisible: false,
                notId: [],
                btnLoading: false,
                multipleSelection: [],
                total:0
            }
        },
        computed: {
			isCityPartner() {
				return this.$store.state.clientType.isCityPartner;
			},
            postData() {
                if (this.skuNoList) {
                    this.skuObj = Object.assign(this.skuObj, {skuNoList: this.skuNoList})
                }
                if(this.isSubstituteOrder){
                	this.skuObj.sn = this.sn;
                }
                return {
                    data: this.skuObj,
                    currentPage: this.currentPage
                }
            },
            // total() {
            //     let tableData = this.isfullgift ? this.$store.state.giftEvent.giftlistData : this.$store.state.ticket.giftlistData;
            //     return tableData ? this.$store.state.ticket.giftlistData.totalCount : 0
            // },
            classifylist() {
                return this.$store.state.classifylist.classifylistData ? this.$store.state.classifylist.classifylistData : []
            },
            proData() {
                let tableData = this.isfullgift ? this.$store.state.giftEvent.giftlistData : this.$store.state.ticket.giftlistData;
                this.currentPage = tableData ? tableData.pageIndex : 1;
                this.total = tableData ? tableData.totalCount : 0;
                if ((this.warehouse !='undefined' && this.warehouse)&& tableData){
                    tableData.elements.map(v=>{
                        v.stockEnableInfoList.map(i=>{
                            if (i.stockOrgNumber+':'+i.mcu == this.warehouse.companyNo+':'+this.warehouse.no) {
                                v.stock = i.stock
                                v.specStock = i.specStock
                            }
                        })
                        if(!v.stock || typeof(v.stock)== 'undefined' || v.stock==0){
                            v.stock = 0
                            v.specStock = 0
                        }
                    })
                }

                return tableData ? tableData.elements : []
            },
            tableloading() {
                return this.isfullgift ? this.$store.state.giftEvent.tableloading : this.$store.state.ticket.tableloading
            }
        },
        watch: {
            giftVisible(value) {
                this.productVisible = value
                if (value) {
                    this.skuObj.skuNo = '';
                    this.skuObj.skuName = '';
                    this.skuObj.classifyId = '';
                    this.currentPage = 1;
                }
            },
            productVisible(val) {//监听弹窗变化改变按钮loading（打开弹窗初始化loading）
                this.btnLoading = !val;
            }
        },
        mounted() {
            this.$nextTick(() => {
                this.classSearch()
            })
        },
        methods: {
            //查询商品列表名字
            ...mapActions({
                'addDistribute': 'querygiftlistData',
                'classSearch': 'queryclassifylistData',
                'querygiftlistDataEvent': 'querygiftlistDataEvent', //满赠活动
                'addgiftEvent': 'addgiftEvent',//满赠活动添加赠品
            }),
            selectInit(row, index) {
                // console.log(row,'row')
                if(row.giftStatus == 1) return false
                if(row.giftStatus == 0 ) return true
            },
            handleSelectionChange(val) {
                let addlist = val.map((each) => {
                    return {
                        image:each.giftPic,
                        sellerId: each.sellerId,
                        skuNo: each.skuNo,
                        skuName: each.skuName,
                        quantity: 1,
                        giftUnit: each.giftUnit,
                        limitQuantity: '',
                        giftPrice: each.giftPrice,
                        baseUnitName: each.baseUnitName,
                        unitType: each.giftType == 0 ? 1 : null, //赠品添加瓶箱选择
                        giftType: each.giftType, // 非标品1不选择0下拉选择
                        id:each.id,
						specInfoNum: each.specInfoNum, //规格
                    }
                })

                if (val.length > this.goodsNum) {//可选择赠品数量
                    let num = val.length - this.goodsNum
                    val.splice(this.goodsNum, num);
                    addlist.splice(this.goodsNum, num);//删除多余数据
                    this.currentRow = addlist;
                    this.$message.warning(`最多选中${this.goodsNum}件商品`)
                } else {
                    this.currentRow = addlist;
                }
                this.multipleSelection = val;
            },
            querygift() {
                if (this.currentPage == 1) {
                    let newpostdata = JSON.parse(JSON.stringify(this.postData));
                    console.log('newpostdata',newpostdata);
                    newpostdata.data.wareHouseId = this.wareHouseId
                    newpostdata.currentPage = 1
                    newpostdata.giftType = 0
                    if (this.isCityPartner) {
                        newpostdata.data.goodsNo = newpostdata.data.skuNo
                        delete newpostdata.data.skuNo
                    }
                    if (this.isfullgift) { //满赠活动
                        this.querygiftlistDataEvent(newpostdata)
                        return false
                    }
                    if (this.isadditional) {// 加价购
                        newpostdata.type = 'addwithadditional'
                    }
                    if(this.isfullgift){
                        newpostdata.isfullgift = true;
                    }
                    if(this.ischangedorder){ // 修改订单
                        newpostdata.ischangedorder = true;
                        newpostdata.data.isWarehouseStock = false;
                    }
                    this.addDistribute(newpostdata)
                } else {
                    this.currentPage = 1;
                    this.handlePageChange(this.currentPage)
                }
            },
            // 确认添加赠品
            addgift() {
                this.btnLoading = true;
                if (this.currentRow == null || this.currentRow == undefined) {
                    this.$message('请点击赠品当前行选择赠品')
                } else {
                    if (this.isadditional) {// 满赠商品加价换购
                        let a = JSON.parse(JSON.stringify(this.multipleSelection))
                        this.multipleSelection = a.map(item => {
							let arr = [
								{// 瓶箱下拉选择框
								    label: '箱(规格单位)',
								    value: 1
								}, {
								    label: item.giftUnit + '(基本单位)',
								    value: 2
								}
							]
                            return Object.assign(item, {
                                price: '',
                                quantity: '',
                                isrequired: false,
                                unitType: 1,
                                limitQuantity: '',
								unitOptions:arr
                            })

                        });
                        this.$store.dispatch('addgift', {
                            willadd: this.multipleSelection
                        })
                        this.$emit('closedialog')
                        return false
                    }
                    if (this.isfullgift) {// 满赠活动
                        console.log(this.currentRow,'0000');
                        this.currentRow.map(v => {
                            v.originGiftType = v.giftType;
                            v.giftType = "X105";
							// 赠品单位不写死，能转换
							let arr = [
								{// 瓶箱下拉选择框
								    label: '箱(规格单位)',
								    value: 1
								}, {
								    label: v.giftUnit + '(基本单位)',
								    value: 2
								}
							]
							v.unitOptions = arr

                        });
                        axiosApi('/marketing/giftType/list',{},res => {
                            this.currentRow.map((v,i1) => {
                                v.giftList.map((item,i2) => {
                                    item.subgiftList.map((n,i3) => {
                                        let flag = false;
                                        res.data.data.map((x,x1) => {
                                            if(x.giftType == n.giftType){
                                                flag = true;
                                            }
                                        });
                                        if(!flag){
                                            n.giftType = null;
                                            n.k3GiftType = null;
                                        }
                                    })
                                })
                            });

                        });
                        console.log(this.currentPage,'00')
                        this.$store.dispatch('addgiftEvent', {
                            willadd: this.currentRow,//选中的赠品
                        });
                        //2019.4.1
                        this.$store.dispatch('addgift', {
                            willadd: this.currentRow,//选中的赠品
                        })
                        this.$emit('closedialog')
                        return false
                    }
                    this.$store.dispatch('addgift', {
                        willadd: this.multipleSelection,//选中的赠品
                    })
                    this.$emit('closedialog')
                }
            },
            //返回上一页
            goPage() {
                this.$emit('sure')
                this.$emit('closedialog')
            },
            handlePageChange(val) {
                this.currentPage = val
                let newpostdata = Object.assign({}, this.postData)
                if (this.isfullgift) { //满赠活动
                    this.querygiftlistDataEvent(newpostdata)
                    return false
                }
                if (this.isadditional) {// 加价购
                    newpostdata.type = 'addwithadditional'
                }
                if(this.isfullgift){
                    newpostdata.isfullgift = true;
                }
				if(this.ischangedorder){
					newpostdata.ischangedorder = true;
				}
				console.log(newpostdata,'000');
                this.addDistribute(newpostdata)
            }
        }
    }
</script>

<style lang="less">
    #chooseGift .el-dialog {
        width: 950px;
        min-height: auto;
    }

    #chooseGift .table-default.el-table .cell {
        padding: 3px 15px 0;
    }

    #chooseGift .el-dialog__body {
        padding: 20px 30px;
    }

    #chooseGift .el-form .el-input {
        width: 120px;
    }

    #chooseGift .table-default .el-table__row {
        cursor: pointer;
    }

    #chooseGift .table-default .el-table__body tr.current-row > td, #chooseGift .table-default .el-table__body tr.current-row > td:hover {
        background-color: #edf7ff;
    }

    #chooseGift {
        .el-dialog__footer {
            position: static;
            transform: translateX(0);
            padding: 10px 20px 35px;
        }
    }
</style>
