<template>
    <div id="superBHot">
        <!-- head -->
        <div class="title-top bg-f mb-5">
            <div class="clearfix black">
                <el-breadcrumb class="fl ">
                    <el-breadcrumb-item>超级B排行榜</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
        </div>

        <!-- body -->
        <div class="bg-f">

            <!-- list -->
            <div class="p30">

                <el-table :data="tableData" v-loading="Loading" class='mt-10 table-default' stripe>
                    <el-table-column label="超B账号" prop="loginName"></el-table-column>
                    <el-table-column label="姓名" prop="name"></el-table-column>
                    <el-table-column label="直接佣金">
                        <template slot-scope="scope">
                            <div>￥{{scope.row.commission | fmoney}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column label="其他补贴">
                        <template slot-scope="scope">
                            <div>￥{{scope.row.subsidy | fmoney}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column label="下游超级B" prop="downstreamNumber" width="90"></el-table-column>
                    <el-table-column label="关联客户" prop="customerNumber" width="90"></el-table-column>
                    <el-table-column label="累计收益" width="90">
                        <template slot-scope="scope">
                            <div>￥{{scope.row.subsidy+ scope.row.commission| fmoney}}</div>
                        </template>
                    </el-table-column>

                    <el-table-column label="操作" width="90">
                        <template slot-scope="scope">
                            <el-button class='ml-0 tac' size="small" type='text' @click="showItem(scope.row,0)">查看
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>

                <!-- index -->
                <el-pagination class="tar" v-if="agencyTotal>0" :current-page="agencyProPage" :page-size='10'
                               :total='agencyTotal'
                               layout="prev , pager, next, jumper" @current-change="handleCurrentChange">
                </el-pagination>
            </div>

        </div>

    </div>

</template>

<script>
    import {axiosApi, basePath} from "../../api/api";

    export default {
        name: "superBHot",
        data() {
            return {
                Loading: false,
                agencyProPage: 1,
                agencyTotal: 0,
                tableData: [],//商品来源
            }
        },
        methods: {
            //搜索
            achieve() {
                this.Loading = true;
                let body = {};
                let url = '/superb/statistics/accountList';
                axiosApi(url, body, res => {
                    this.Loading = false;
                    this.agencyTotal = res.data.data.totalCount;
                    this.tableData = res.data.data.elements;
                }, this.agencyProPage, 10, err => {
                })
            },
            //分页
            handleCurrentChange(val) {
                this.agencyProPage = val;
                this.achieve();
            },
            showItem(item) {
                window.open(basePath + "/superB/superBDetails/" + item.traderId + "/" + item.id + "/" + '0', "_blank");
            }
        },
        mounted() {
            this.$nextTick(() => {
                this.achieve()
            })
        }
    }
</script>

<style lang="less">
    #superBHot {

    }
</style>