<template>
  <!--久集商品管理-->
  <div id="distributionGoods">
    <div class="title-tabs bg-f" style="position: relative;">
      <ul class="clearfix" @click="handleTabs">
        <template v-for="(tab,index) in dataTabs" class="item">
          <li :type="index" :class="type==index?'active':''" :key="index+'da'">
            {{tab}}
            <div :class="type==index?'under-line':''"></div>
          </li>
          <div class="vertical-line" :key="index+'db'"></div>
        </template>
      </ul>
      <el-badge class="count" :max="99" :value="count" :hidden="count == 0"/>
    </div>
    <div class="content bg-f pr-30 pl-30 pb-50">
      <div class="clearfix search">
        <el-form :inline="true" class="pt-30 fl" :model="Form" ref="searchForm">
          <el-form-item label="商品编码">
            <el-input v-model.trim="Form.goodsNo" placeholder="请输入商品编码" :maxlength="30"></el-input>
          </el-form-item>
          <el-form-item label="商品名称">
            <el-input v-model.trim="Form.skuName" placeholder="请输入商品名称" :maxlength="30"></el-input>
          </el-form-item>
          <el-form-item class="mr-30" label="商品分类">
            <el-select v-model="Form.classifyId" placeholder="请选择商品分类" clearable>
              <el-option
                v-for="(tab,index) in classList"
                :label="tab.classifyName"
                :value="tab.classifyId"
                :key="index"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <el-button class="fl ml-30 mt-30" type="info" @click="achieve(true)">搜索</el-button>
      </div>
      <!-- <div class="tar clearfix" v-if="type==0">
        <el-button type="text" class="fr" @click="showOneKey()">批量设置佣金</el-button>
      </div> -->
      <div class="pb-30">
        <el-table :data="tableData" @selection-change="handleSelectionChange" stripe>
          <el-table-column key="a" label="商品名称" min-width="230">
            <template slot-scope="scope">
              <div class="fl specialD">
                <img v-if="scope.row.mainFileUrl" :src="scope.row.mainFileUrl" alt />
                <img v-else src="../../../assets/goods.jpg" alt />
                <img
                  v-if="scope.row.storeType=='2'"
                  class="special"
                  src="../../../assets/special.png"
                  alt
                />
              </div>
              <div class="fl lh-16">
                <div class="width-130">
                  商品编码:
                  <span v-if="scope.row.goodsNo !=null">{{scope.row.goodsNo}}</span>
                  <span v-else>--</span>
                </div>
                <div class="width-130" show-overflow-tooltip>{{scope.row.skuName}}</div>
                <div
                  v-if="scope.row.storeType == '2' && scope.row.limitMonth && scope.row.limitNum"
                  class="width-130 color-r"
                >限购：每{{scope.row.limitMonth}}月{{scope.row.limitNum}}{{scope.row.units}}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="规格" :min-width="itemWidth" prop="specInfoNum">
            <template slot-scope="scope">
              <span>1*{{scope.row.specInfoNum}}</span>
            </template>
          </el-table-column>
          <el-table-column key="b" label="商品分类" :min-width="itemWidth">
            <template slot-scope="scope">
              <span>{{scope.row.classifyName}}</span>
            </template>
          </el-table-column>
          <!-- <el-table-column key="c" label="促销价" :min-width="itemWidth">
            <template slot-scope="scope">
              <span v-if="scope.row.promotionPrice">￥{{scope.row.promotionPrice||0 | fmoney }}</span>
              <span v-else>-</span>
            </template>
          </el-table-column>
          <el-table-column key="d" label="最低零售价" :min-width="itemWidth">
            <template slot-scope="scope">
              <span v-if="scope.row.minSellingPrice">￥{{scope.row.minSellingPrice||0 | fmoney }}</span>
              <span v-else>-</span>
            </template>
          </el-table-column>
          <el-table-column key="e" label="标准零售价" :min-width="itemWidth">
            <template slot-scope="scope">
              <span v-if="scope.row.maxSellingPrice">￥{{scope.row.maxSellingPrice||0 | fmoney }}</span>
              <span v-else>-</span>
            </template>
          </el-table-column>
          <el-table-column key="f" label="销售员佣金" :min-width="itemWidth">
            <template slot-scope="scope">
              <span
                v-if="scope.row.rewardValue == 0 || scope.row.rewardValue"
              >{{scope.row.rewardValue}}{{scope.row.rewardType == '1' ? '%' :'元'}}</span>
              <span v-else>--</span>
            </template>
          </el-table-column>
          <el-table-column key="g" label="推广员佣金" :min-width="itemWidth">
            <template slot-scope="scope">
              <span
                v-if="scope.row.salesRewardValue != null"
              >{{scope.row.salesRewardValue}}{{scope.row.salesRewardType == '1' ? '%' :'元'}}</span>
              <span v-else>--</span>
            </template>
          </el-table-column> -->
          <el-table-column label="进店价" min-width="80" align="center">
            <template slot-scope="{row}">
              <span  v-if="row.sellingPrice">{{row.sellingPrice||0 | fmoney}}</span>
              <span v-else>--</span>
            </template>
          </el-table-column>
          <el-table-column label="佣金" min-width="80" align="center">
            <template slot-scope="{row}">
              <span  v-if="row.goodsRewardValue">{{row.goodsRewardValue ||0 | fmoney}}</span>
              <span v-else>--</span>
            </template>
          </el-table-column>
          <el-table-column key="j" label="最低起订量" :min-width="itemWidth">
            <template slot-scope="scope">
              <span>{{scope.row.isIntegerMultiple ? scope.row.minimunMoq * scope.row.specInfoNum : scope.row.minimunMoq}}{{scope.row.unitName}}</span>
            </template>
          </el-table-column>
          <!-- <el-table-column key="j" label="序号" :width="itemWidth">
            <template slot-scope="scope">
              <span
              >{{scope.row.sort != null?scope.row.sort:'-'}}</span>
            </template>
          </el-table-column>-->
          <!-- <el-table-column key="l" label="操作" align="center" :min-width="isAdmin == 1?80:137">
            <template slot-scope="scope">
              <el-button type="text" @click="setMark(scope)">设置</el-button>
              <el-button type="text" v-if="type==0" @click="handleUp(scope)">上架</el-button>
              <el-button type="text" v-if="type==1" @click="handleUp(scope)">下架</el-button>
            </template>
          </el-table-column> -->
        </el-table>
        <el-pagination
          class="tar"
          v-if="tableData.length>0"
          :current-page="currentPage"
          :page-size="10"
          :total="total"
          layout="prev , pager, next, jumper"
          @current-change="handleCurrentChange"
        >
          <!-- 分页组件 -->
        </el-pagination>
      </div>
      <!--设置-->
      <el-dialog class="minMop" title="设置" :visible.sync="salesMark">
        <el-form ref="markMarks" class="pr-10" :model="markForm" :rules="rules">
          <el-form-item label="销售员佣金:" class="ml-20" required>
            <el-form-item class="ml-15" prop="rewardValue">
              <el-tooltip placement="bottom-start" effect="light" class="fl">
                <div slot="content">
                  销售员提成计算公式:
                  <br />
                  <br />（最低价-进店价）*该设置百分比或者为该设置固定佣金值 + (销售员售价-最低价)
                </div>
                <span class="el_label">
                  <img src="../../../assets/mark.png" alt />
                </span>
              </el-tooltip>
              <el-input
                size="mini"
                class="ml-10 mr-10"
                v-model="markForm.rewardValue"
                placeholder="请输入"
              ></el-input>
              <el-select v-model="markForm.rewardType" size="mini">
                <el-option
                  v-for="item in rewardTypeList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-form-item>
          <el-form-item
            label="推广员佣金:"
            class="ml-20 freightDiscounts-money"
            prop="salesRewardValue"
            required
          >
            <el-tooltip placement="bottom-start" effect="light" class="fl">
              <div slot="content">
                推广员佣金计算公式:
                <br />
                <br />（最低价-进店价）*该设置百分比或者为该设置固定佣金值
              </div>
              <span class="el_label">
                <img src="../../../assets/mark.png" alt />
              </span>
            </el-tooltip>
            <el-input
              size="mini"
              class="ml-10 mr-10"
              v-model="markForm.salesRewardValue"
              placeholder="请输入"
            ></el-input>
            <el-select v-model="markForm.salesRewardType" size="mini">
              <el-option
                v-for="item in rewardTypeList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="danger" @click="setMarks('markMarks', 1)">保存</el-button>
          <el-button v-if="type == '0'" class="ml-20" @click="setMarks('markMarks', 2)">保存并上架</el-button>
        </div>
      </el-dialog>
      <!--一键设置佣金-->
      <el-dialog class="minMop" title="批量设置佣金" :visible.sync="salesOneKeyMark">
        <el-form ref="markOneKeyMarks" class="pr-10" :model="markForm" :rules="rules">
          <el-form-item label="销售员佣金:" class="ml-20" required>
            <el-form-item class="ml-15" prop="rewardValue">
              <el-tooltip placement="bottom-start" effect="light" class="fl">
                <div slot="content">
                  销售员提成计算公式:
                  <br />
                  <br />（最低价-进店价）*该设置百分比或者为该设置固定佣金值 + (销售员售价-最低价)
                </div>
                <span class="el_label">
                  <img src="../../../assets/mark.png" alt />
                </span>
              </el-tooltip>
              <el-input
                size="mini"
                class="ml-10 mr-10"
                v-model="markForm.rewardValue"
                placeholder="请输入"
              ></el-input>
              <el-select v-model="markForm.rewardType" size="mini">
                <el-option
                  v-for="item in rewardTypeList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-form-item>
          <el-form-item label="推广员佣金:" class="ml-20" required>
            <el-form-item class="ml-15" prop="salesRewardValue">
              <el-tooltip placement="bottom-start" effect="light" class="fl">
                <div slot="content">
                  推广员佣金计算公式:
                  <br />
                  <br />（最低价-进店价）*该设置百分比或者为该设置固定佣金值
                </div>
                <span class="el_label">
                  <img src="../../../assets/mark.png" alt />
                </span>
              </el-tooltip>
              <el-input
                size="mini"
                class="ml-10 mr-10"
                v-model="markForm.salesRewardValue"
                placeholder="请输入"
              ></el-input>
              <el-select v-model="markForm.salesRewardType" size="mini">
                <el-option
                  v-for="item in rewardTypeList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="danger" @click="setOneKeyMarks('markOneKeyMarks', 1)">保存</el-button>
          <!-- <el-button v-if="type == '0'" class="ml-20" @click="setOneKeyMarks('markOneKeyMarks', 2)">保存并上架</el-button> -->
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { axiosApi, basePath } from "../../../api/api";
import { MessageBox } from "element-ui";
export default {
  // name:'distributionGoods',
  data() {
    let reg1 = /^([0-9]|([1-9]\d)|(100))(\.\d{1,2})?$/;
    let reg2 = /^(\d|[1-9]\d|100)(\.\d{1,2})?$/;
    let reg3 = /^[0-9]{1,7}([.]{1}[0-9]{0,2}){0,1}$/;

    var unitNums = (rule, value, callback) => {
      if (!value && value !== 0) {
        callback(new Error("请输入销售员佣金"));
      } else if (value && this.markForm.rewardType == 1) {
        if (!reg2.test(value)) {
          callback(new Error("请输入0-100数字"));
        } else {
          callback();
        }
      } else if (value && this.markForm.rewardType == 2) {
        if (!reg3.test(value)) {
          callback(new Error("请输入0-10000数字"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    var unitNumsSales = (rule, value, callback) => {
      if (!value && value !== 0) {
        callback(new Error("请输入推广员佣金"));
      } else if (value && this.markForm.salesRewardType == 1) {
        if (!reg2.test(value)) {
          callback(new Error("请输入0-100数字"));
        } else {
          callback();
        }
      } else if (value && this.markForm.salesRewardType == 2) {
        if (!reg3.test(value)) {
          callback(new Error("请输入0-10000数字"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    return {
      sortvisiable: false,
      id: null, //商品主键id
      sortForm: {
        sort: null
      },
      rewardTypeList: [
        { id: 1, name: "%" },
        { id: 2, name: "元" }
      ],
      sortRules: {
        sort: [
          { required: true, message: "请输入序号" },
          {
            pattern: /^[1-9]\d{0,3}$/,
            message: "请输入1-9999之间的整数"
          }
        ]
      },
      rules: {
        rewardValue: [
          {
            validator: unitNums,
            // required: true,
            // pattern: /^([1-9][\d]{0,2}|0)(\.[\d]{1,2})?$/,
            // message: '请输入正确金额',
            trigger: "blur"
          }
        ],
        salesRewardValue: [
          {
            validator: unitNumsSales,
            trigger: "blur"
          }
        ],
        rewardType: [
          {
            required: true,
            message: "请输入单位",
            trigger: "change"
          }
        ]
      },
      type: "0",
      dataTabs: ["未上架", "已上架"],
      //搜索表单
      Form: {
        goodsNo: null,
        skuName: null, //商品名称
        classifyId: null, //商品分类
        skuState: "1" //商品状态
      },
      tableData: [], //商品数据
      classList: [], //分类数据
      skuNoList: [], //上下架商品列表
      skuState: null, //1下架2上架
      stateText: "", //上下架文案
      currentPage: 1,
      total: null, //页面分页总数
      reject: false, //设价弹窗
      totalCount: null, //弹窗分页总数
      //设置佣金弹框数据
      markForm: {
        rewardType: 1,
        rewardValue: 0,
        salesRewardType: 2,
        salesRewardValue: 0
      },
      id: null, //设置佣金商品id
      salesMark: false, //销售员佣金弹框
      salesOneKeyMark: false, //一键设置销售员佣金弹框
      count: "", //角标数据
      units: null,
      isAdmin: "",
      itemWidth: 63
    };
  },
  watch: {
    salesMark(val) {
      if (!val) {
        setTimeout(() => {
          if (this.$refs.markMarks) this.$refs.markMarks.resetFields();
        }, 1000);
      }
    },
    salesOneKeyMark(val) {
      if (!val) {
        setTimeout(() => {
          if (this.$refs.markOneKeyMarks)
            this.$refs.markOneKeyMarks.resetFields();
        }, 1000);
      }
    }
  },
  created() {
    this.isAdmin = sessionStorage.isAdmin;
    if (this.isAdmin === "1") {
      this.itemWidth = 63;
    } else {
      this.itemWidth = 65;
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.achieve();
      this.getClassList();
    });
  },
  computed: {},
  methods: {
    achieve(val) {
      if (val && this.currentPage != 1) {
        this.currentPage = 1;
        return;
      }
      axiosApi(
        "/superc/tenantGoods/selectPage",
        {
          skuState: this.Form.skuState,
          goodsNo: this.Form.goodsNo,
          skuName: this.Form.skuName,
          classifyId: this.Form.classifyId,
          isSelf: 0
        },
        res => {
          this.tableData = res.data.data.elements ? res.data.data.elements : [];
          this.total = res.data.data.totalCount;
          this.getCount();
        },
        this.currentPage,
        10,
        res => {}
      );
    },
    getCount() {
      //角标数量统计
      axiosApi(
        "/superc/tenantGoods/count",
        {
          isSelf: 0
        },
        res => {
          this.count = res.data.data.onTheShelvesCount;
        },
        1,
        10,
        res => {
          this.$message({
            type: "info",
            message: res.data.message,
            duration: 3000
          });
        }
      );
    },
    //设置佣金数据处理
    setMark(val) {
      // if (this.$refs.markMarks !== undefined) {
      //   this.$refs.markMarks.resetFields();
      // }
      this.id = val.row.id;
      this.markForm.rewardType =
        val.row.rewardType == null ? 1 : val.row.rewardType;
      this.markForm.rewardValue = val.row.rewardValue;
      this.markForm.salesRewardValue = val.row.salesRewardValue;
      this.markForm.salesRewardType =
        val.row.salesRewardType == null ? 2 : val.row.salesRewardType;
      this.salesMark = true;
      this.units = val.row.units;
    },
    //设置
    setMarks(markMarks, status) {
      this.$refs[markMarks].validate(valid => {
        if (valid) {
          axiosApi(
            "/superc/web/tenantGoods/updateCommission",
            {
              id: this.id,
              rewardValue: this.markForm.rewardValue,
              rewardType: this.markForm.rewardType.toString(),
              isStandup: status == 1 ? 0 : 1, // 1：保存并上架 0：保存不上架
              salesRewardValue: this.markForm.salesRewardValue,
              salesRewardType: this.markForm.salesRewardType.toString()
            },
            res => {
              this.achieve();
              this.salesMark = false;
              this.$message({
                type: "success",
                message: "操作成功",
                duration: 1500
              });
            },
            this.currentPage,
            10,
            res => {
              this.$message({
                type: "info",
                message: res.data.message,
                duration: 1500
              });
              this.salesMark = false;
            }
          );
        }
      });
    },
    handleTabs(e) {
      //Tab切换
      if (e.target.attributes.type) this.type = e.target.attributes.type.value;
      this.currentPage = 1;
      this.Form = {
        goodsNo: null,
        skuName: null, //商品名称
        classifyId: null
      };
      if (this.type == "0") {
        //未上架
        this.Form.skuState = "1";
        this.achieve();
      } else if (this.type == "1") {
        //已上架
        this.Form.skuState = "2";
        this.achieve();
      }
    },
    handleSelectionCheck(val) {
      //选中的状态
      this.skuNoList = [];
      val.forEach(item => {
        this.skuNoList.push(item.goodsNo);
      });
    },
    handleCurrentChange(val) {
      //分页查询
      this.currentPage = val;
      this.achieve();
    },
    getClassList() {
      //获取分类列表
      axiosApi(
        "/superc/classify/selectFirstClassifyList",
        {},
        res => {
          this.classList = res.data.data;
        },
        1,
        10,
        res => {
          this.$message({
            type: "info",
            message: res.data.message,
            duration: 3000
          });
        }
      );
    },
    handleSelectionChange(val) {
      /*table全选*/
      //console.log(val, '选中值')
      this.skuNoList = []; //清空每次全选时添加的订单编号
      this.idStr = []; //清空每次全选时添加的订单Id
      val.forEach(item => {
        this.skuNoList.push(item.goodsNo);
        this.idStr.push(item.id);
      });
    },
    handleUp(scope) {
      //上下架
      if (scope.row.skuState == 1) {
        this.skuState = 2;
        this.stateText = "上架";
      } else if (scope.row.skuState == 2) {
        this.skuState = 1;
        this.stateText = "下架";
      }
      // if(scope.row.id) { //单个商品上下架数据处理
      // 	this.skuNoList = []; //处理每次添加的订单编号
      // 	this.skuNoList.push(scope.row.goodsNo);
      // };
      const h = this.$createElement;
      this.$msgbox({
        title: "提示信息",
        message: h(
          "p",
          {
            style: "text-align:center"
          },
          "确认" + this.stateText + "该商品？"
        ),
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            // if(this.skuNoList.length > 0) {
            axiosApi(
              "/superc/tenantGoods/skuState/update",
              {
                skuState: this.skuState,
                id: scope.row.id
              },
              res => {
                // this.skuNoList = []; //清空存储的商品编号
                this.currentPage = 1;
                this.achieve(); //刷新列表
                this.$message({
                  type: "success",
                  message: "操作成功",
                  duration: 3000
                });
              },
              1,
              10,
              res => {
                this.$message({
                  type: "info",
                  message: res.data.message,
                  duration: 3000
                });
              }
            );
            // } else {
            // 	this.$message({
            // 		type: 'info',
            // 		message: '请选择商品',
            // 		duration: 3000
            // 	});
            // }
            done();
          } else {
            done();
          }
          delete instance.$slots.default;
        }
      });
    },
    showOneKey() {
      this.salesOneKeyMark = true;
      this.markForm.salesRewardValue = 0;
      this.markForm.rewardValue = 0;
      this.markForm.rewardType = 1;
      this.markForm.salesRewardType = 2;
    },
    setOneKeyMarks(markOneKeyMarks, status) {
      this.$refs[markOneKeyMarks].validate(valid => {
        if (valid) {
          // if(status==1){
          axiosApi(
            "/superc/web/tenantGoods/batchUpdateCommission",
            {
              // idList: this.idList,
              rewardValue: this.markForm.rewardValue,
              rewardType: this.markForm.rewardType,
              salesRewardValue: this.markForm.salesRewardValue,
              salesRewardType: this.markForm.salesRewardType,
              isStandup: status == 1 ? 0 : 1 // 1：保存并上架 0：保存不上架
            },
            res => {
              this.achieve();
              this.salesOneKeyMark = false;
              this.$message({
                type: "success",
                message: "操作成功",
                duration: 3000
              });
            },
            this.currentPage,
            10,
            res => {
              this.$message({
                type: "info",
                message: res.data.message,
                duration: 3000
              });
              this.salesOneKeyMark = false;
            }
          );
          // }
          // else if(status==2){
          // 	axiosApi('/superc/hotelGoods/checkBatchSetReward', {}, (res) => {
          // 		console.log(res,'123456')
          // 		if(res.data.data){
          // 			const h = this.$createElement
          // 			this.$msgbox({
          // 				title: '提示信息',
          // 				message: h('p', {style: 'text-align:center'}, '有'+ res.data.data +'款商品不满足上架条件，是否将符合条件的商品先行上架'),
          // 				showCancelButton: true,
          // 				confirmButtonText: '确定',
          // 				cancelButtonText: '取消',
          // 				beforeClose: (action, instance, done) => {
          // 					if(action === 'confirm') {
          // 						axiosApi('/superc/hotelGoods/batchSetReward', {
          // 							// idList: this.idList,
          // 							rewardValue: this.markForm.rewardValue,
          // 							updateSkuState: true, // true：保存并上架 false：保存不上架
          // 						}, (res) => {
          // 							this.achieve()
          // 							this.salesOneKeyMark = false
          // 							this.$message({
          // 								type: 'success',
          // 								message: '操作成功',
          // 								duration: 3000
          // 							});
          // 						}, this.currentPage, 10, (res) => {
          // 							this.$message({
          // 								type: 'info',
          // 								message: res.data.message,
          // 								duration: 3000
          // 							});
          // 							this.salesOneKeyMark = false
          // 						})
          // 						// this.salesOneKeyMark = false
          // 						done()
          // 					} else {
          // 						this.salesOneKeyMark = false
          // 						done()
          // 					}
          // 					delete instance.$slots.default;
          // 				}
          // 			})
          // 		}else{
          // 			axiosApi('/superc/hotelGoods/batchSetReward', {
          // 				// idList: this.idList,
          // 				rewardValue: this.markForm.rewardValue,
          // 				updateSkuState: true, // true：保存并上架 false：保存不上架
          // 			}, (res) => {
          // 				this.achieve()
          // 				this.salesOneKeyMark = false
          // 				this.$message({
          // 					type: 'success',
          // 					message: '操作成功',
          // 					duration: 3000
          // 				});
          // 			}, this.currentPage, 10, (res) => {
          // 				this.$message({
          // 					type: 'info',
          // 					message: res.data.message,
          // 					duration: 3000
          // 				});
          // 				this.salesOneKeyMark = false
          // 			})
          // 		}
          // 	}, this.currentPage, 10, (res) => {
          // 		this.$message({
          // 			type: 'info',
          // 			message: res.data.message,
          // 			duration: 3000
          // 		});
          // 	})

          // }
        }
      });
    }
  }
};
</script>

<style lang="less">
#distributionGoods {
  .el-tooltip {
    width: 20px !important;
  }
  .blue {
    color: #20a0ff;
    display: inline-block;
    width: auto;
    height: auto;
    text-align: center;
    cursor: pointer;
  }
  .sort {
    .el-dialog {
      min-height: 250px !important;
    }
    .el-form-item__label {
      width: 120px !important;
    }
    .el-form-item__error {
      left: 28%;
    }
  }
  .content {
    padding-top: 0;
    .el-form--inline {
      width: 650px;
      .el-form-item__label {
        font-size: 12px;
        width: 60px;
      }
      .order-datatimerange {
        width: 240px;
      }
    }
    .el-button + .el-button {
      margin-left: 0;
    }
    .el-table__body-wrapper {
      margin-top: 1px !important;
    }
    .el-table .cell {
      text-align: center;
      padding: 10px 0;
      img {
        width: 60px;
        height: 60px;
          display: inline-block;
        margin-left: 10px;
        margin-right: 10px;
      }
      .width-130 {
        width: 140px;
        text-align: left;
      }
      .el-button {
        height: 20px;
      }
    }
    .el-form-item__label {
      width: 100px;
    }
    .reject {
      .el-dialog {
        width: 900px;
        .el-dialog__body {
          min-height: 100px;
          margin-left: 50px;
        }
      }
      .el-form-item__error {
        margin-left: 100px;
      }
    }
    .goods {
      .el-dialog__body {
        padding-top: 30px;
      }
      .el-dialog {
        width: 968px;
      }
    }
  }
  .city {
    position: absolute;
    z-index: 10;
    width: 335px;
    padding-top: 10px;
    top: 30px;
    left: 0;
    background: #ffffc3;
    .el-checkbox {
      margin: 5px;
    }
  }
  .w125 {
    width: 125px;
    position: relative;
  }
  .w100 {
    width: 100px;
  }
  .zindex {
    z-index: 20;
  }
  .w400 {
    width: 400px;
  }
  .el_label {
    width: 100px;
    padding: 0px 10px 11px 0;
    height: 32px;
    line-height: 32px;
    text-align: right;
    vertical-align: middle;
    float: left;
    font-size: 14px;
    color: #48576a;
    box-sizing: border-box;
    img {
      width: 15px;
      height: 15px;
      margin-left: 5px;
      vertical-align: sub;
    }
  }
  .minMop {
    .el-dialog {
      width: 520px;
      min-height: 300px;
      .el-form-item__error {
        left: 37px;
      }
    }
  }
  .el-form-item__error {
    left: 95px !important;
  }
  .count {
    position: absolute;
    top: 15px;
    left: 150px;
  }
  .freightDiscounts-money {
    .el-form-item__error {
      left: 110px !important;
    }
  }
  .ml50 {
    margin-left: 100px;
  }
  .el-message-box__content {
    padding: 30px 35px;
  }
  .specialD {
    position: relative;
    .special {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  .color-r {
    color: red;
  }
}
</style>
