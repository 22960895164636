<template>
  <div id="clientEdit" class="bg-f pb-20" v-loading="creditloading">
    <div class="title-menu">
      <div>
        <div class="clearfix">
          <div class="info bg-f plr-20 pt-10">
            <div class="imgWrap">
              <img :src="companyInfo.photos[0]" alt="" />
            </div>
            <div class="rightInfo">
              <div class="icon-wrap font-14 color-333">
                <div class="inline-div">{{ companyInfo.storeName }}</div>
                <div class="inline-div icon upgrade">
                  <div
                    :class="companyInfo.isCompanyVerified ? 'active-icon' : ''"
                  >
                    <span>企</span>
                  </div>

                  <!-- <div class="tip" v-if="companyInfo.isCompanyVerified">
                                        <p>企业名称：{{ companyInfo.storeName }}</p>
                                        <p>营业执照注册号：{{ companyInfo.licenseNum }}</p>
                                        <i class="iconfont icon-triangle"></i>
                                    </div> -->
                  <!-- <div class="tip tip2"  v-else>
                                        <p>你还没有认证 <span><router-link :to="basePath + '/enterpriseAuthentication?mobile=' + companyInfo.tel + '&storeName=' + companyInfo.storeName + '&licenseNum=' + companyInfo.licenseNum">立即认证</router-link></span></p>
                                        <i class="iconfont icon-triangle"></i>
                                    </div> -->
                </div>
                <div class="inline-div icon upgrade">
                  <div :class="companyInfo.isVerified ? 'active-icon' : ''">
                    <span>个</span>
                  </div>
                </div>
              </div>
              <div class="color-999">
                <div class="inline-div">
                  用户名：{{ companyInfo.loginName }}
                </div>
                <div class="inline-div">绑定手机：{{ companyInfo.mobile }}</div>
              </div>
            </div>
          </div>
          <el-button
            v-if="
              type == 0 &&
              userInfo.accountSource == 2 &&
              showButton('INFO_UPGRADE', 100056)
            "
            @click="update"
            class="fr update-btn"
            >升级为注册用户
          </el-button>
        </div>
      </div>
      <el-tabs v-model="activeTab" @tab-click="handleClick">
        <el-tab-pane label="基本资料" name="1" key="1"></el-tab-pane>
        <el-tab-pane v-if="type != 1" label="账号信息" name="2" key="2"></el-tab-pane>
        <el-tab-pane
          v-if="
            type != 1 &&
            userInfo.accountSource != 2 &&
            showButton('CUSTOMER_CONTRACT', 100056)
          "
          label="合同/协议"
          name="8"
          key="8"
        ></el-tab-pane>
        
        <el-tab-pane
          v-if="type != 1 && isCityPartner"
          label="账期设置"
          name="3"
          key="3"
        ></el-tab-pane>
        <el-tab-pane
          v-if="type != 1"
          label="收货地址管理"
          name="4"
          key="4"
        ></el-tab-pane>
        <!-- <el-tab-pane
          v-if="type != 1 && userInfo.ctype !== 6"
          label="设价管理"
          name="5"
          key="5"
        ></el-tab-pane> -->
        
       <el-tab-pane
          v-if="
            type != 1 &&
            !isCityPartner &&
            !isQuery &&
            !isDealerUser &&
            userInfo.ctype !== 1 &&
            userInfo.accountSource !== 2 &&
            userInfo.supplierType !== 2
          "
          label="开通城市拍档"
          name="6"
          key="6"
        ></el-tab-pane>
        
        <!--<el-tab-pane  label="合同管理" name="7"></el-tab-pane>-->
        <el-tab-pane
          label="仓库管理"
          name="9"
          key="9"
          v-if="
            !isCityPartner &&
            !isQuery &&
            userInfo.accountSource != 2 &&
            userInfo.state == 2
          "
        ></el-tab-pane>
        <el-tab-pane label="付款账号管理" name="10" key="10" v-if="companyInfo.ctype==2"></el-tab-pane>
      </el-tabs>
    </div>
    <!--基本资料-->
    <div class="mt-30" v-show="activeTab == 1">
      <div class="mb-20">
        <span class="labelInfo tar">客户编号:</span
        ><span class="ml-30">{{ userInfo.traderNumber }}</span>
      </div>
      <div class="mb-20">
        <span class="labelInfo tar">客户名称:</span>
        <span class="ml-30">{{ userInfo.storeName }}</span>
      </div>
      <div class="mb-20 clearfix">
        <span class="labelInfo tar fl">客户简称:</span>
        <!-- <span class="ml-30">{{userInfo.platformDescription}}</span> -->
        <div class="fl ml-30" v-if="changePlatformDescription">
          <el-input
            :maxlength="20"
            v-model="userInfo.platformDescription"
          ></el-input>
        </div>
        <div class="fl ml-30" v-else>
          {{ userInfo.platformDescription }}
          <el-button
            style="height: auto"
            v-if="!isQuery"
            type="text"
            class="color-blue"
            @click="changePlatformDescription = true"
            >修改</el-button
          >
        </div>
      </div>
      <div class="mb-20">
        <span class="labelInfo tar">所在地区:</span
        ><span class="ml-30"
          >{{ userInfo.provinceName }}{{ userInfo.cityName
          }}{{ userInfo.regionName }}{{ userInfo.streetName }}</span
        >
      </div>
      <div class="mb-20">
        <span class="labelInfo tar">详细地址:</span
        ><span class="ml-30">{{ userInfo.attr }}</span>
      </div>
      <div class="mb-20">
        <span class="labelInfo tar">营业执照编码:</span
        ><span class="ml-30">{{ userInfo.licenseNum }}</span>
      </div>
      <div
        class="mb-20"
        v-if="
          userInfo.storeCodeNo &&
          !isQuery &&
          !(
            isCityPartner &&
            (userInfo.ctype === 3 ||
              userInfo.ctype === 4 ||
              userInfo.ctype === 5)
          )
        "
      >
        <span class="labelInfo tar">门店编码:</span
        ><span class="ml-30">{{ userInfo.storeCodeNo }}</span>
      </div>
      <div class="mb-20 clearfix">
        <span class="labelInfo tar fl">营业执照照片:</span>
        <div v-if="userInfo.licenseImg" class="width-640 ml-30 fl hasP">
          <el-upload
            action="/sunrise-gateway/oss/ossUpload"
            :file-list="userInfo.licenseImgArr"
            list-type="picture-card"
            :on-preview="handlePictureCardPreview"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
          <el-dialog :visible.sync="picdialogVisible" size="tiny">
            <img width="100%" :src="dialogImageUrl" alt="show-message" />
          </el-dialog>
        </div>
      </div>
      <div class="mb-20 clearfix">
        <span class="labelInfo tar fl">门头照片:</span>
        <div class="width-640 ml-30 fl hasP">
          <el-upload
            action="/sunrise-gateway/oss/ossUpload"
            :file-list="userInfo.photosArr"
            list-type="picture-card"
            :on-preview="handlePictureCardPreview"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
        </div>
      </div>
      <div class="mb-20">
        <span class="labelInfo tar">姓名:</span
        ><span class="ml-30">{{ userInfo.linkname }}</span>
      </div>
      <div class="mb-20">
        <span class="labelInfo tar">职位:</span
        ><span class="ml-30">{{ userInfo.jobOffer }}</span>
      </div>
      <div class="mb-20">
        <span class="labelInfo tar">联系电话:</span
        ><span class="ml-30">{{ userInfo.tel }}</span>
      </div>
      <div class="mb-20">
        <span class="labelInfo tar">Email:</span
        ><span class="ml-30">{{ userInfo.email }}</span>
      </div>
      <!--<div class="mb-20" v-if="!isCityPartner"><span class="labelInfo tar">客户关系:</span>
                <el-select class="ml-30" v-model='bindingDealerTraderId' :disabled="isQuery" filterable clearable placeholder="请选择">
                    <el-option :label="item.storeName" :value="item.buyerId" v-for='item in agencyList'
                               :key='item.buyerId'></el-option>
                </el-select>
            </div>-->
      <!--<div class="mb-20" v-if="!isCityPartner && !isQuery"><span class="labelInfo tar">推荐经销商:</span>-->
      <!--<el-select class="ml-30" v-model='bindingDealerTraderId' filterable clearable placeholder="请选择经销商">-->
      <!--<el-option :label="item.storeName" :value="item.buyerId" v-for='item in agencyList'-->
      <!--:key='item.buyerId'></el-option>-->
      <!--</el-select>-->
      <!--</div>-->
      <div class="mb-20">
        <span class="labelInfo tar">关联业务员:</span>
        <span class="ml-30">
          <el-input
            v-model.trim="userInfo.oldname"
            size="small"
            disabled
          ></el-input>
          <span v-if="!isQuery">
            <el-button
              @click="saledialog = true"
              class="ml-20"
              type="text"
              v-if="userInfo.oldname"
              >「更换业务员」</el-button
            >
            <el-button
              @click="saledialog = true"
              class="ml-20"
              type="text"
              v-else
              >「添加业务员」</el-button
            >
          </span>
        </span>
      </div>
      <div class="mb-20">
        <span class="labelInfo tar"
          >{{ userInfo.accountSource == 1 ? "注册" : "录入" }}时间:</span
        ><span class="ml-30">{{ userInfo.gmtCreate | time }}</span>
      </div>
      <div v-if="!userInfo.isHotel" class="mb-20 ml-100 mr-100 dash"></div>





      <el-form
        v-if="userInfo.ctype != 3"
        :model="form"
        class="client-form"
        :rules="rules"
        ref="form"
        label-width="215px"
      >
        <!-- <el-form-item label="客户类别:" prop="cusTypeId">
          <el-cascader
            :options="cClassifyList"
            :disabled="isQuery"
            :props="{ value: 'id', label: 'name', children: 'sub' }"
            v-model="form.cusTypeId"
            @change="test"
          >
            <template slot-scope="{ node, data }">
              <span>{{ data.name }}</span>
              <span v-if="!node.isLeaf"> ({{ data.sub.length }}) </span>
            </template>
          </el-cascader>
        </el-form-item>
        <el-form-item label="客户类型:" v-if="userInfo.accountSource == 1">
          <span v-if="userInfo.storeCodeNo">
            <span v-if="userInfo.ctype == 3">久加久*久柜</span>
            <span v-if="userInfo.ctype == 5">久加久*店中店</span>
            <span v-if="userInfo.ctype == 4">久加久*连锁店</span>
          </span>
          <el-radio-group
            v-else
            v-model="form.ctype"
            :disabled="!userInfo.ctypeEnabled && isQuery"
          >
            <el-radio :disabled="isQuery" class="radio" :label="2"
              >终端</el-radio
            >
            <el-radio class="radio" v-if="!isCityPartner && !isQuery" :label="1"
              >经销商</el-radio
            >
            <el-radio :disabled="isQuery" class="radio" :label="6"
              >普通酒店</el-radio
            >
          </el-radio-group>
        </el-form-item> -->

        <el-form-item label="客户分组">
					<el-cascader v-model="form.groupId" :options="treeList" clearable :props="treeProps"></el-cascader>
				</el-form-item>
        <el-form-item
          label="客户类型:"
        >
          <el-select
            v-model="form.ctype"
            clearable
            disabled
            placeholder="请选择客户类型"
          >
            <el-option
              :label="item.name"
              :value="item.id"
              v-for="item in prepareList"
              :key="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="K3编码:" prop="k3No" v-if="needK3No">
          <!-- <el-input
            v-model.trim="form.k3No"
            placeholder="请输入K3编码"
            :disabled="isQuery"
          ></el-input> -->
          <el-select v-model="form.k3No" :disabled="isQuery" clearable placeholder="请选择">
            <el-option
              v-for="item in K3info"
              :key="item.custNumber"
              :label="item.custNumber"
              :value="item.custNumber">
              <div style="line-height:1.5">
                <div>{{item.custName}}</div>
                <div>{{item.custNumber}}</div>
              </div>
            </el-option>
          </el-select>
          <el-tooltip class="item" effect="dark" content="K3数据更新" placement="right">
						<el-button @click="getK3List" style="width:30px;height:30px;background:#409eff;padding:0;line-height:30px;color:#fff" icon="el-icon-refresh" circle></el-button>
					</el-tooltip>
        </el-form-item>
				



        <el-form-item
          label="客户渠道:"
          prop="cchannel"
          v-if="
            clientConfigData.channel !== undefined &&
            userInfo.accountSource == 1
          "
          :required="clientConfigData.channel === 1"
        >
          <el-cascader
            :disabled="isQuery"
            v-show="form.ctype == 2"
            :options="clientChannelList"
            :props="channelProp"
            change-on-select
            v-model="form.cchannel"
            @change="handleChanneChange"
          ></el-cascader>
          <span v-show="form.ctype != 2">无</span>
        </el-form-item>
        <el-form-item
          label="客户等级:"
          prop="clevel"
          v-if="clientConfigData.level !== undefined"
        >
          <el-select :disabled="isQuery" v-model="form.clevel">
            <el-option
              v-for="item in clientLevelList"
              :label="item.levelName"
              :value="item.id"
              :key="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="所属分组:">
          <el-select v-model="form.groupId" :disabled="isQuery" clearable>
            <el-option
              v-for="n in groupList"
              :label="n.groupName"
              :value="n.id"
              :key="n.id"
            ></el-option>
          </el-select>
          <router-link
            v-if="!isQuery"
            :to="`${basePath}/addAndEditGrouping?type=add&c=${$route.params.id}&d=${$route.params.buyerId}`"
          >
            <el-button class="ml-20" type="text">新增分组</el-button>
          </router-link>
        </el-form-item> -->
        <!--<el-form-item label="所属区域:" prop="districtIds" v-if="clientConfigData.district !== undefined"-->
        <!--:required="clientConfigData.district === 1">-->
        <!--<el-cascader :props="districtProps" :disabled="isQuery" :options="districtList" clearable-->
        <!--change-on-select-->
        <!--placeholder="选择区域" v-model="form.districtIds"></el-cascader>-->
        <!--<router-link  v-if="!isQuery"-->
        <!--:to="`${basePath}/addAndEditRegion?type=add&c=${$route.params.id}&d=${$route.params.buyerId}`">-->
        <!--<el-button class="ml-20" type="text">新增区域</el-button>-->
        <!--</router-link>-->
        <!--</el-form-item>-->
        <el-form-item label="客户来源:">
          <span>{{
            userInfo.csource == 1 ? "注册" : userInfo.csource == 2 ? "录入" : ""
          }}</span>
        </el-form-item>
        <el-form-item label="K3状态:">
          <span>{{
            userInfo.k3IsDisabled == null
              ? "未关联K3"
              : userInfo.k3IsDisabled
              ? "禁用"
              : "启用"
          }}</span>
        </el-form-item>
        <!-- <el-form-item label="K3编码:">
          <span>{{userInfo.k3No?userInfo.k3No:'无'}}</span>
        </el-form-item> -->
        
        <el-form-item
          label="销售品牌:"
          prop="brandTabData"
          v-if="clientConfigData.brand !== undefined"
          :required="clientConfigData.brand === 1"
        >
          <el-button class="ml-20" @click="addBrandClick">新增品牌</el-button>
          <span class="font-12 color-danger ml-10"
            >温馨提示：列表中红色背景的品牌表示已删除，您也可以删除它并保存！</span
          >
          <brandTable class="table-700"></brandTable>
        </el-form-item>
      </el-form>

      <div v-if="!userInfo.isHotel">
        <span class="labelInfo tar top mr-30">备注:</span>
        <el-input
          type="textarea"
          :disabled="isQuery"
          class="w700"
          v-model.trim="remarks"
          placeholder="输入备注..."
        ></el-input>
      </div>
      <div class="mb-20 mt-50">
        <span class="labelInfo tar"></span>
        <span class="ml-30">
          <span v-if="type == 1">
            <el-button type="danger" @click="checkSave(1)" :loading="loadingBtn"
              >审核通过</el-button
            >
            <el-button @click="failDialogVisible = true">驳回申请</el-button>
          </span>
          <el-button
            v-if="!isQuery && type != 1"
            type="danger"
            @click="handleSave"
            >保存</el-button
          >
        </span>
      </div>
      <!-- 新增品牌弹窗 -->
      <addBrand ref="addBrand"></addBrand>
      <!--审核不通过弹窗-->
      <el-dialog
        title="填写理由"
        :visible.sync="failDialogVisible"
        @close="handleReset('userRuleForm')"
      >
        <el-form
          class="ml-30 mr-30"
          :model="userRuleForm"
          label-position="top"
          :rules="userRules"
          ref="userRuleForm"
        >
          <el-form-item label="审核不通过理由:" prop="reason">
            <el-input
              type="textarea"
              :autosize="{ minRows: 4, maxRows: 10 }"
              v-model.trim="userRuleForm.reason"
            ></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="danger" @click="handleCommit('userRuleForm')"
            >确 定</el-button
          >
          <el-button @click="handleReset('userRuleForm')">取 消</el-button>
        </div>
      </el-dialog>
      <!--更换业务员-->
      <div v-if="userInfo.sellerId">
        <changeSalesman
          v-on:closedialog="saledialog = false"
          :saleVisible="saledialog"
          :title="userInfo.oldname"
        ></changeSalesman>
      </div>
      <!--选择商品-->
      <!-- <chooseGoods v-on:closedialog="dialog1Visible = false" :goodVisible="dialog1Visible" :ifnotauto="true">
            </chooseGoods> -->
    </div>
    <!--账号信息-->
    <div class="mt-30" v-if="type != 1" v-show="activeTab == 2">
      <div class="mb-50">
        <span class="labelInfo tar">账号:</span
        ><span class="ml-30">{{ userInfo.loginName }}</span>
      </div>
      <div class="mb-20"><span class="labelInfo tar">普通发票</span></div>
      <div class="mb-20">
        <span class="labelInfo tar">公司抬头:</span
        ><span class="ml-30">{{ InvoiceInfoVO1.companyHead }}</span>
      </div>
      <div class="mb-40">
        <span class="labelInfo tar">纳税人识别号:</span
        ><span class="ml-30">{{ InvoiceInfoVO1.taxpayerNumber }}</span>
      </div>
      <div class="mb-20"><span class="labelInfo tar">增值税发票</span></div>
      <div class="mb-20">
        <span class="labelInfo tar">公司名称:</span
        ><span class="ml-30">{{ InvoiceInfoVO2.companyName }}</span>
      </div>
      <div class="mb-20">
        <span class="labelInfo tar">纳税人识别号:</span
        ><span class="ml-30">{{ InvoiceInfoVO2.taxpayerNumber }}</span>
      </div>
      <div class="mb-20">
        <span class="labelInfo tar">注册地址:</span
        ><span class="ml-30">{{ InvoiceInfoVO2.address }}</span>
      </div>
      <div class="mb-20">
        <span class="labelInfo tar">注册电话:</span
        ><span class="ml-30">{{ InvoiceInfoVO2.tel }}</span>
      </div>
      <div class="mb-20">
        <span class="labelInfo tar">开户银行:</span
        ><span class="ml-30">{{ InvoiceInfoVO2.bankName }}</span>
      </div>
      <div class="mb-20">
        <span class="labelInfo tar">银行账号:</span
        ><span class="ml-30">{{ InvoiceInfoVO2.bankCard }}</span>
      </div>
    </div>
    <!-- 合同协议 -->
    <div
      class="mt-30"
      v-if="type != 1"
      v-show="activeTab == 8 && showButton('CUSTOMER_CONTRACT', 100056)"
      v-loading="loading"
    >
      <el-upload
        :disabled="isQuery"
        class="upload-demo"
        action="/sunrise-gateway/oss/uploadFile"
        :on-preview="handlePreview"
        :on-remove="handleRemove"
        :beforeUpload="beforeAvatarUpload"
        :before-remove="beforeRemove"
        :on-success="successFile"
        :on-error="errorFile"
        :file-list="fileList"
        multiple
        :limit="10"
        :on-exceed="handleExceed"
        accept=".jpg,.png,.doc,.docx,.pdf"
      >
        <el-button
          v-show="!isQuery"
          size="small"
          type="primary"
          class="chooseFiles"
          >选择文件</el-button
        >
        <div v-show="!isQuery" slot="tip" class="el-upload__tip">
          支持文件格式：.jpg .png .doc .docx .pdf
          ，单个文件不能超过10M，最多可上传10个文件
        </div>
      </el-upload>
      <el-button
        size="small"
        type="primary"
        class="saveFiles"
        @click="saveFile"
        v-show="!isQuery"
        >保存</el-button
      >
    </div>
    
    <div class="payment" v-if="activeTab == 3">
      <PayMentTable :form="formSetPayment" :isQuery="isQuery"></PayMentTable>
    </div>
    <!--收货地址管理-->
    <div class="mt-30" v-if="type != 1" v-show="activeTab == 4">
      <el-table
        :data="deliveryAddressList"
        tooltip-effect="dark"
        stripe
        class="ml-30 mr-30 mt-20 table-default"
      >
        <el-table-column prop="linkman" label="收货人" min-width="100">
        </el-table-column>
        <el-table-column prop="tel" label="联系电话" min-width="150">
        </el-table-column>
        <el-table-column label="所在地区" min-width="150">
          <template slot-scope="scope">
            {{ scope.row.provinceName }}{{ scope.row.cityName
            }}{{ scope.row.regionName }}{{ scope.row.streetName }}
          </template>
        </el-table-column>
        <el-table-column label="详细地址" min-width="200">
          <template slot-scope="scope">
            {{
              userInfo.ctype == 3 || form.ctype == 6
                ? scope.row.addr
                : scope.row.addr
            }}
          </template>
        </el-table-column>
        <el-table-column label="状态" min-width="100">
          <template slot-scope="scope">
            <div v-if="scope.row.state == 1">待审核</div>
            <div v-else>正常使用</div>
          </template>
        </el-table-column>
        <!--<el-table-column label="操作" width="100">
                    <template slot-scope="scope">
                        <el-button v-if="scope.row.state == 1" @click="checkAddress(scope.row)" type="text" size="small">
                            审核
                        </el-button>
                        <el-button v-if="scope.row.state == 2" @click="editAddress(scope.row)" type="text" size="small">
                            编辑
                        </el-button>
                    </template>
                </el-table-column>-->
      </el-table>
    </div>
    <!--添加协议商品-->
    <!-- <div v-if="type != 1 && !userInfo.isHotel" v-show="activeTab == 5">
      <el-table
        :data="goodsData"
        tooltip-effect="dark"
        border
        stripe
        class="ml-30 mr-30 mt-20 table-default"
      >
        <el-table-column label="商品名称" min-width="130">
          <template slot-scope="scope">
            <div style="display: flex">
              <div class="fl ml-10 mt-10">
                <img
                  v-if="scope.row.skuVO.fileUrl == null"
                  src="../../../assets/goods.jpg"
                />
                <img v-else :src="scope.row.skuVO.fileUrl" />
              </div>
              <div class="fl w160 ml-10 mt-10" style="flex: 1; width: 0">
                <div class="tal" v-if="scope.row.skuVO.goodsNo">
                  <span>商品编码：</span>{{ scope.row.skuVO.goodsNo }}
                </div>
                <div class="tal mt-10">
                  <span>商品名称：</span>{{ scope.row.skuVO.skuName }}
                </div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="规格" min-width="80" v-if="isOpenPriceSync">
          <template slot-scope="scope">
            1*{{ scope.row.skuVO.specInfoNum }}
          </template>
        </el-table-column>
        <el-table-column
          prop="skuVO.classifyName"
          min-width="80"
          label="商品分类"
          v-if="isOpenPriceSync"
        >
        </el-table-column>
        <el-table-column
          label="最低起订量"
          v-if="isOpenPriceSync"
          min-width="80"
        >
          <template slot-scope="scope">
            <span>
              {{ scope.row.skuVO.bottleInfo.minimunMoq
              }}{{ scope.row.unitsName }}
            </span>
            <span>
              (合{{ scope.row.skuVO.boxInfo.minimunMoq
              }}{{ scope.row.sellingUnitName }})
            </span>
          </template>
        </el-table-column>
        <el-table-column
          label="会员价(元)"
          min-width="80"
          v-if="isOpenPriceSync"
        >
          <template slot-scope="scope">
            <div>
              {{ scope.row.unitWholesalePrice }} /{{ scope.row.unitsName }}
            </div>
            <div>
              {{ scope.row.wholesalePrice }} /{{ scope.row.sellingUnitName }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="规格" min-width="70" v-if="!isOpenPriceSync">
          <template slot-scope="scope">
            1*{{ scope.row.skuVO.specInfoNum }}
          </template>
        </el-table-column>
        <el-table-column
          prop="skuVO.classifyName"
          label="商品分类"
          min-width="90"
          v-if="!isOpenPriceSync"
        >
        </el-table-column>
        <el-table-column
          prop="skuVO.msrp"
          label="建议零售价"
          min-width="80"
          v-if="!isOpenPriceSync"
        >
          <template slot-scope="scope">
            <span>
              {{ scope.row.skuVO.unitMsrp }} / {{ scope.row.unitsName }}
            </span>
            <br />
            <span>
              {{ scope.row.skuVO.integerMsrp }} /
              {{ scope.row.sellingUnitName }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          label="协议时间"
          min-width="120"
          v-if="!isOpenPriceSync"
        >
          <template slot-scope="scope">
            <span
              :class="{ 'color-9': scope.row.isExpire == '0' }"
              v-if="scope.row.startDate"
              >{{ scope.row.startDate | timenData }} --
              {{ scope.row.endDate | timenData }}</span
            >
            <span v-else>永久</span>
            <p v-if="scope.row.isExpire == '0'" class="color-9">已失效</p>
            <p v-else-if="scope.row.isExpire == '2'" class="color-9">未开始</p>
          </template>
        </el-table-column>
        <el-table-column
          prop="groupName"
          label="所属分组"
          min-width="50"
          v-if="!isOpenPriceSync"
        >
        </el-table-column>
        <el-table-column label="起订量" min-width="50" v-if="!isOpenPriceSync">
          <template slot-scope="scope">
            <span>
              {{ scope.row.skuVO.bottleInfo.minimunMoq
              }}{{ scope.row.unitsName }}
            </span>
            <span>
              (合{{ scope.row.skuVO.boxInfo.minimunMoq
              }}{{ scope.row.sellingUnitName }})
            </span>
          </template>
        </el-table-column>
        <el-table-column label="协议价" min-width="50" v-if="!isOpenPriceSync">
          <template slot-scope="scope">
            <span>
              {{ scope.row.unitWholesalePrice }} / {{ scope.row.unitsName }}
            </span>
            <br />
            <span>
              {{ scope.row.wholesalePrice }} / {{ scope.row.sellingUnitName }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="操作" min-width="80" v-if="!isOpenPriceSync">
          <template slot-scope="scope">
            <router-link
              v-if="!isQuery"
              :to="`${basePath}/editCommHome/${scope.row.skuVO.id}/${scope.row.skuVO.spuNo}/0`"
            >
              <el-button type="text">去设价</el-button>
            </router-link>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        v-if="total > 0"
        @current-change="handleCurrentpage"
        class="tar mt-20 mr-50"
        :current-page.sync="currentPage"
        :page-size="10"
        :total="total"
        layout="prev , pager, next, jumper"
      >
      </el-pagination>
      <el-dialog class="schemeDialog" title="设价方案" :visible.sync="scheme">
        <el-form :model="ruleForm" ref="ruleForm">
          <el-form-item label="客户名称:" prop="buyerId">
            <el-input :value="ruleForm.storeName" disabled></el-input>
          </el-form-item>
          <el-form-item label="所在区域:">
            <el-input
              :value="address"
              disabled
              placeholder="所在区域"
            ></el-input>
          </el-form-item>
          <el-form-item label="详细地址:">
            <el-input
              :value="ruleForm.buyerAdd"
              disabled
              placeholder="详细地址"
            ></el-input>
          </el-form-item>
          <el-form-item label="协议时间:">
            <ys-daterange-picker
              v-model="ruleForm.dateTimevalue"
              :disabled="true"
            />
          </el-form-item>
          <div class="mt-30 clearfix">
            <div class="box">
              <div class="boxchild clearfix">
                <el-form-item class="fl" label="价格:" prop="wholesalePrice">
                  <el-input
                    v-model="ruleForm.wholesalePrice"
                    placeholder="请输入价格"
                    disabled
                  ></el-input>
                </el-form-item>
                <el-form-item class="fl" label="最低起订量:" prop="minimunMoq">
                  <el-input
                    v-model="ruleForm.minimunMoq"
                    placeholder="最低起订量"
                    disabled
                  ></el-input>
                </el-form-item>
              </div>
            </div>
            <el-form-item label="备注:">
              <el-input
                v-model="ruleForm.remark"
                placeholder="请输入备注"
                disabled
              ></el-input>
            </el-form-item>
          </div>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="danger" @click="scheme = false">确 定</el-button>
          <el-button @click="scheme = false">取 消</el-button>
        </div>
      </el-dialog>
    </div> -->
    <!--开通城市拍档-->
    <div
      class="mt-30"
      v-if="type != 1 && !isCityPartner"
      v-show="activeTab == 6"
      style="min-height: 500px"
    >
      <div class="ml-30 mr-30 mt-20">
        是否开通城市拍档：
        <el-switch
          @change="getChange"
          v-model="formSet.check"
          active-text="开"
          inactive-text="关"
          active-color="#13ce66"
          inactive-color="#ccc"
        >
        </el-switch>
      </div>
      <!--<div class="ml-30 mr-30 mt-20" v-if="isParentCompany && formSet.check">-->
      <!--关联相应的子公司:-->
      <!--<el-select class="giftTypeList mt-10" clearable v-model="bindingPartnerTraderId"  placeholder="请选择子公司">-->
      <!--<el-option-->
      <!--v-for="item in childCompanyList"-->
      <!--:key="item.sellerId"-->
      <!--:label="item.storeName"-->
      <!--:value="item.sellerId">-->
      <!--</el-option>-->
      <!--</el-select>-->
      <!--<el-button type="danger"  @click="getChange(1)">保存</el-button>-->
      <!--</div>-->
    </div>
    <!--合同管理-->
    <div class="mt-30 text-center" v-if="type != 1" v-show="activeTab == 7">
      <h4>合同名称</h4>
      <div>合同等级：A 提交时间：2018-01-01 12:21:21</div>
      <!-- <div v-html=""></div> -->
    </div>
    <div class="mt-30 text-center" v-if="type != 1" v-show="activeTab == 9">
      <div class="clearfix">
        <el-form
          :inline="true"
          class="pt-30 fl"
          :model="form"
          label-width="80px"
          ref="form"
        >
          <el-form-item label="仓库模版">
            <el-select v-model="no" clearable remote placeholder="请选择仓库">
              <el-option
                v-for="item in repertoryData"
                :label="item.name"
                :value="item.no"
                :key="item.no"
              />
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <div class="clearfix">
        <el-button class="mt-50 fl ml-30" type="danger" @click="repertorySave"
          >保存</el-button
        >
      </div>
    </div>
    <div style="margin: 30px" v-if="type != 1" v-show="activeTab == 10">
      <el-button type="danger" style="width: 100px" @click="dialogVisible=true">指定付款账号</el-button>
      <div style="color: red; margin: 10px 0">
        *未使用指定帐号，默认使用通用帐号
      </div>
      <div class="clearfix">
        <el-table :data="bankList" style="width: 100%">
          <el-table-column prop="date" label="帐号类型">
						<template slot-scope="{row}">
							<span>{{ row.useType==1?"通用账号":"特殊账号" }}</span>
						</template>
					</el-table-column>
          <el-table-column prop="bankName" label="收款渠道"></el-table-column>
          <el-table-column prop="bankCard" label="银行卡号"></el-table-column>
          <el-table-column prop="bankDeposit" label="开户支行"></el-table-column>
          <el-table-column prop="bankUserName" label="开户人"></el-table-column>
          <el-table-column prop="date" label="操作">
						<template slot-scope="{row}">
							<div v-if="row.useType==2">
								<el-link type="primary" :underline="false" @click="delInfo(row)">解绑账号</el-link>
							</div>
							<div v-else>--</div>
							<!-- <span>{{ row.useType==1?"通用账号":"特殊账号" }}</span> -->
						</template>
					</el-table-column>
        </el-table>
      </div>
    </div>

		<addDialog :dialogVisible="dialogVisible" :buyerId="companyInfo.buyerId" @submitInfo="submitInfo" @closeDialog="closeDialog"/>
  </div>
</template>

<script>
import { axiosApi, basePath } from "../../../api/api";
import { mapActions, mapMutations } from "vuex";
import { MessageBox } from "element-ui";
import PayMentTable from "../../client/commen/paymentTable";
import addDialog from './components/addDialog.vue' ;
export default {
  components: {
    PayMentTable,
		addDialog
  },
  data() {
    var cchannel = (rule, value, callback) => {
      if (this.form.ctype == 2 && (value.length === 0 || value[0] == 0)) {
        callback(new Error("请选择终端渠道"));
      } else {
        callback();
      }
    };
    var clevel = (rule, value, callback) => {
      console.log(!value, "222222222");
      if (!value && this.clientConfigData.level === 1) {
        callback(new Error("请选择客户等级"));
      } else {
        callback();
      }
    };
    var districtIds = (rule, value, callback) => {
      if (value.length === 0 && this.clientConfigData.district === 1) {
        callback(new Error("请选择所属区域"));
      } else {
        callback();
      }
    };
    var brandTabData = (rule, value, callback) => {
      if (this.brandTabData.length === 0 && this.clientConfigData.brand === 1) {
        callback(new Error("请选择销售品牌"));
      } else {
        callback();
      }
    };
    return {
			dialogVisible:false, // 添加特殊银行卡弹框使用
			bankList: [],

      prepareList: [], //商家分类列表
      needK3No: false,
      K3info:[], // K3编码列表
      companyInfo: {
        photos: [],
        storeName: "",
        isCompanyVerified: "",
        isVerified: "",
        licenseNum: "",
        tel: "",
				buyerId: null
      },
      repertoryData: [], //仓库
      no: null, //被选中的仓库
      uploadFileList: [], //合同协议
      fileList: [],
      changePlatformDescription: false,
      loadingBtn: false,
      loadingFile: false,
      bindingPartnerTraderId: null, //管理子公司id
      childCompanyList: [], //子公司列表
      isQuery: false,
      //城市拍档新增
      supplierType: null,
      sellerId: null,
      formSet: {
        check: false,
      },
      isBind: false, //
      agencyList: [], //经销商
      basePath: basePath,
      userRuleForm: {
        reason: "",
        dateTimevalue: [],
      },
      userRules: {
        reason: [
          {
            required: true,
            message: "请输入理由",
            trigger: "blur",
          },
          {
            min: 1,
            max: 30,
            message: "长度在 1 到 30 个字符",
            trigger: "blur",
          },
        ],
        limit1: [
          {
            required: true,
            pattern: /^\d+(\.{0,1}\d+){0,1}$/,
            message: "授信额度不能小于0",
            min: 1,
          },
        ],
        limit2: [
          {
            required: true,
            pattern: /^\d+(\.{0,1}\d+){0,1}$/,
            message: "授信额度不能小于0",
            min: 1,
          },
        ],
      },
      failDialogVisible: false, //新客户审核不通过弹窗
      check: false, //设价 客户类型
      checked: false, //设价 客户类型
      address: "", //设价弹窗区域拼接
      ruleForm: {}, //设价弹窗信息
      scheme: false, //设价
      addressDialog2: false, //收货地址 审核
      addressDialog1: false, //收货地址 编辑
      deliveryAddressList: [], //营业地址
      InvoiceInfoVO1: {}, //普通发票
      InvoiceInfoVO2: {}, //增值发票
      saledialog: false, //更换业务员
      //dialog1Visible: false, //选择商品
      userInfo: {}, //基本资料信息
      designType: "1",
      currentPage: 1,
      total: 0,
      activeTab: "1",
      type: this.$route.params.type, // 列表也得tab, 区分老客户编辑还是新客户审核
      radio: "1",
      goodsData: [], //协议商品信息
      picdialogVisible: false, //图片放大弹框
      dialogImageUrl: "", //图片放大url
      creditInfo: {
        isDisable: 0,
      }, //进货商授信详情
      // 授信选中状态
      creditChecked: null, //授信状态
      isOpenCredit: null, //授信权限
      isChangeCredit: false, // 是否点击调整额度
      creditForm: {
        limit1: null, // 没有开启权限时候的额度
        limit2: null, //开启权限时候的额度
      },
      isDisable: null,
      creditloading: false,
      channelProp: {
        label: "classifyName",
        value: "id",
        children: "sub",
      },
      id: "",
      bindingDealerTraderId: "",
      cchannel1: null, //一级客户渠道
      cchannel2: null, //二级客户渠道
      remarks: null, //备注
      clientChannelList: [], //渠道列表
      clientLevelList: [], //等级列表
      groupList: [], //所属分组列表
      districtList: [],
      districtProps: {
        label: "districtName",
        value: "id",
        children: "subDistrictList",
      },
      form: {
        // cusTypeId: "", //客户类别
        ctype: 2, //客户类型
        k3No: null,
        cchannel: [], //客户渠道
        clevel: null, //客户等级
        groupId: null,
        districtIds: [],
      },
      formSetPayment: {
        buyerId: null,
      },
      rules: {
        cchannel: [{ validator: cchannel, trigger: "change" }],
        // clevel: [
        // 	{validator: clevel, trigger: 'change'}
        // ],
        districtIds: [{ validator: districtIds, trigger: "change" }],
        licenseNum: [
          {
            pattern: /^[0-9a-zA-Z]{10,20}$/,
            message: "请输入10-20个字符，可为字母数字",
          },
        ],
        brandTabData: [{ validator: brandTabData }],
      },
      isOpenPriceSync: false, // 商品开通同步K3
      treeList: [], // 客户分组list
      treeProps: {
        value: 'id',
        label: 'name',
        children:'sub',
        emitPath:false
      },
    };
  },
  watch: {
    linkName(item) {
      let m = {
        // name: item ? item.employeeName : this.userInfo.oldname
        oldname: item ? item.employeeName : "",
      };
      let n = this.userInfo;
      this.userInfo = Object.assign({}, n, m);
    },
    "form.ctype"(val) {
      if (val == 1 || val == 3) {
        // 经销商，普通酒店
        // this.cchannel1 = null,//一级客户渠道
        // this.cchannel2 = null,//二级客户渠道
        // this.form.cchannel = [];
      }
    },
  },
  computed: {
    isDealerUser() {
      return this.$store.state.clientType.isDealerUser;
    },
    isParentCompany() {
      return this.$store.state.clientType.isParentCompany;
    },
    isCityPartner() {
      return this.$store.state.clientType.isCityPartner;
    },
    linkName() {
      //业务员id和name
      return this.$store.getters.linknamearray.length > 0
        ? this.$store.getters.linknamearray[0]
        : {};
    },
    gooddata() {
      //添加商品信息
      return this.$store.state.ticket.willadd;
    },
    postDatagood() {
      //添加商品信息id
      return {
        data: {
          notId: this.$store.getters.willaddid,
        },
        currentPage: 1,
      };
    },
    brandIdList() {
      return this.$store.state.clientBrand.brandList.map((n) => {
        return n.brandId;
      });
    },
    clientConfigData() {
      return this.$store.state.clientlist.clientConfig;
    },
    brandTabData() {
      return this.$store.state.clientBrand.brandList;
    },
  },
  mounted() {
    this.isQuery = this.$route.query.flag === "1";
    this.formSetPayment.buyerId = this.$route.params.buyerId;
    this.id = this.$route.params.id;
    this.$nextTick(() => {
      this.changeHeight();
      this.getPrepare().then(() => {
         this.loading();
      });
     
      // this.getChildCompanyList();
      //this.queryChannelLevelData(0);//获取渠道列表信息
      //this.queryChannelLevelData(1);//获取客户等级列表信息
      this.resetBrandList();
      this.getClientConfig(); //获取录入客户配置项
      this.getFileList();
      this.getLeftTree()
    });
  },
  methods: {
    ...mapActions({
      goodsList: "querygoodtableDatas",
      makesure: "sure",
      queryChannelLevelData: "queryChannelLevelData",
      getClientConfig: "getClientConfig",
    }),
    ...mapMutations({
      deleteGoods: "deletewilladddata",
      userAddressInfo: "userInfo",
      initgood: "initgood",
      clearwilladd: "clearwilladd",
      setBrandList: "setBrandList",
      resetBrandList: "resetBrandList",
    }),
     // 获取客户分组
    getLeftTree(){
        // 查询 左侧树
        axiosApi('/trader/customer/group/listTree',{},(res) => {
            this.treeList = this.treeInit(res.data.data);
        });
        // 默认查询 全国区域 右侧表格数据
    },
    treeInit(tree){
      tree.forEach(item=>{
        if(item.sub.length>0){
          this.treeInit(item.sub)
        }else{
          delete item.sub
        }
      })
      return tree
    },
		// 付款账号管理内容
		closeDialog(val){
			this.dialogVisible = false
		},
		submitInfo(){
			this.getBindList()
		},
    getK3List(){
				axiosApi('/trader/k3CustomerInfos/getByLicenseNum',{licenseNum:this.userInfo.licenseNum},res => {
					if(res.data.code==='000000'){
						this.K3info = res.data.data
						if(res.data.data[0]&& !this.form.k3No){
							// 默认选中第一个
							this.form.k3No = res.data.data[0].custNumber || null 
						}
					}
				})
			},
		getBindList() {
			axiosApi(
        '/anon/order/find/bind/bank/info',
				{	
					buyerId: this.companyInfo.buyerId,
					traderId: sessionStorage.getItem("traderId")
				},
				(res) => {
					if(res.data.code==='000000'){
						this.bankList = res.data.data
					}
				}
			);
		},
		delInfo(row){
			 this.$confirm('此操作将解绑该特殊账号, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				axiosApi(
        '/anon/order/cancel/trader/bank/buyer/info',
					{	
						buyerId: this.companyInfo.buyerId,
						id: row.id
					},
					(res) => {
						if(res.data.code==='000000'){
							this.$message({
								type: 'success',
								message: '删除成功!'
							});
							this.getBindList()
						}
					}
				);
			}).catch(() => {
				this.$message({
					type: 'info',
					message: '已取消'
				});          
			});
		},




    //获取商家类别列表
    getPrepare() {
      return new Promise((resolve) => {
        let url = "/trader/proxyClientRegister/prepare";
        axiosApi(
          url,
          {},
          (res) => {
            if(res.data.code==='000000'){
              this.prepareList = res.data.data.cTypeVoList
              this.needK3No = res.data.data.needK3No
              resolve();
            }
            
          },
          1,
          1000
        );
      });
    },
    //获取子公司列表
    getChildCompanyList() {
      axiosApi(
        "/trader/sellerCode/selectDetailPageList",
        { sellerType: 2 },
        (res) => {
          console.log(res, "chileCompany");
          this.childCompanyList =
            (res.data.data && res.data.data.elements) || [];
        },
        1,
        1000,
        (err) => {
          this.$message.error(err.data.message);
        }
      );
    },
    //是否开通城市拍档
    getChange(val) {
      const originObj = Object.assign({}, this.formSet);
      console.log(val, "是否开通城市拍档");
      let body = {
        traderId: this.userInfo.buyerId,
        isAgent: true,
        agentSellerId: sessionStorage.getItem("traderId"),
        supplierType: val ? 4 : 0,
        settingType: val === 1 ? 2 : 1,
      };
      if (this.isParentCompany && val === 1) {
        body.bindingPartnerTraderId = this.bindingPartnerTraderId;
      }
      axiosApi(
        "/trader/traderSetting/update",
        body,
        (res) => {
          console.log(res, "res");
          this.$notify({
            title: "成功",
            message: "操作成功",
            type: "success",
          });
          this.loading();
        },
        1,
        10,
        (err) => {
          this.formSet = Object.assign({}, originObj);
          this.$message.error(err.data.message);
        }
      );
    },
    getAgencyList() {
      let url = "/trader/findClientAllList/select";
      axiosApi(
        url,
        { sellerId: sessionStorage.getItem("traderId"), ctype: "1" },
        (res) => {
          this.agencyList = res.data.data.elements;
          if (this.userInfo.bindingDealerTraderId !== 0) {
            let _this = this;
            _this.agencyList.map((v) => {
              if (v.buyerId == _this.userInfo.bindingDealerTraderId) {
                _this.bindingDealerTraderId =
                  _this.userInfo.bindingDealerTraderId;
              }
            });
          }
        }
      );
    },
    findTraderInfoBySerllerId() {
      // 查询商品是否开通同步K3
      return new Promise((resolve, reject) => {
        axiosApi(
          "/trader/findTraderInfoBySerllerId/select",
          {
            traderId: sessionStorage.getItem("traderId"),
          },
          (res) => {
            resolve(res);
          },
          1,
          10,
          (err) => {
            reject(err);
          }
        );
      });
    },
    update() {
      //升级录入客户
      this.$router.push(
        `${basePath}/clientUpdate?traderId=${this.userInfo.buyerId}&type=2&id=${this.$route.params.id}&buyerId=${this.$route.params.buyerId}`
      );
    },
    handleChanneChange(val) {
      console.log(val, "val");
      this.cchannel1 = this.cchannel2 = 0;
      if (val.length > 0) this.cchannel1 = val[0];
      if (val.length > 1) this.cchannel2 = val[1];
      if (val.length == 0) {
        this.cchannel1 = this.cchannel2 = 0;
      }
    },
    searchCredit() {
      this.creditloading = true;
      /*查看经销商权限*/
      axiosApi(
        "/trader/findSellerJurisdiction/select",
        { traderId: this.$route.params.buyerId },
        (res) => {
          this.isOpenCredit = res.data.data.isOpenCredit;
          //isOpenCredit  是否开通授信管理权限0否1是
          /*进货商查看授信详情*/
          axiosApi(
            "/trader/buyerFindCreditInfo/select",
            {
              buyerId: this.$route.params.buyerId,
              sellerId: sessionStorage.traderId,
            },
            (res2) => {
              //isDisable   0正常 1冻结
              if (res2.data.data != null) {
                this.creditInfo = res2.data.data;
                this.isDisable = this.creditInfo.isDisable;
                if (this.isOpenCredit == 0) {
                  this.creditChecked = null;
                } else {
                  if (this.creditInfo.isDisable == 1) {
                    this.creditChecked = 2;
                  } else {
                    this.creditChecked = 1;
                  }
                }
              }
              this.creditloading = false;
            }
          );
        }
      );
    },
    handlePictureCardPreview(file) {
      //图片放大
      this.dialogImageUrl = file.url;
      this.picdialogVisible = true;
    },
    handleCommit(formName) {
      // 弹出框 审核不通过 处理函数（确定按钮）
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.failDialogVisible = false;
          this.checkSave(0);
        } else {
          return false;
        }
      });
    },
    handleReset(formName) {
      //再次点击弹出框 清空 内容
      this.failDialogVisible = false;
      this.$refs[formName].resetFields();
    },
    checkSave(type) {
      //新客户审核
      let state = "";
      if (type == 0) {
        state = 3;
      } else if (type == 1) {
        state = 2;
      }
      let body = {
        id: this.userInfo.id,
        buyerId: this.userInfo.buyerId,
        sellerId: this.userInfo.sellerId,
        // bindingDealerTraderId: this.bindingDealerTraderId || 0,
        //isRetailer: this.isRetailer ? "1" : "0",
        //isDealer: this.isDealer ? "1" : "0",
        cchannel1: this.cchannel1,
        cchannel2: this.cchannel2,
        ctype: this.form.ctype,
        clevel: this.form.clevel,
        remarks: this.remarks,
        // cusTypeId: this.form.cusTypeId[this.form.cusTypeId.length - 1],
        //isDistribution: this.userInfo.isDistribution,
        //channelType: this.channelType,
        // saleId: this.linkName != undefined ? this.linkName.id : this.userInfo.saleId,
        saleId:
          this.linkName.managerId != undefined ? this.linkName.managerId : "0",
        //addTraderDistributeParam: addTraderDistributeParam,
        //deleteTraderDistributeParam: deleteTraderDistributeParam,
        state: state,
        reason: this.userRuleForm.reason,
        groupId: this.form.groupId,
        // districtId: this.form.districtIds[this.form.districtIds.length - 1],
        cbrand: this.brandIdList.join(","), //品牌id列表
      };
      this.loadingBtn = true;
      axiosApi(
        "/trader/updateClientAudit/update",
        body,
        (res) => {
          this.$router.push(basePath + "/clientList");
          this.loadingBtn = false;
        },
        1,
        10,
        (res) => {
          MessageBox.alert(res.data.message, "提示信息", {
            confirmButtonText: "确定",
          });
          this.loadingBtn = false;
        }
      );
    },
    editAddress(val) {
      //地址编辑
      this.addressDialog1 = true;
      this.userAddressInfo(val);
    },
    checkAddress(val) {
      //地址审核
      this.addressDialog2 = true;
      this.userAddressInfo(val);
    },
    loading() {
      axiosApi(
        "/trader/findClientDetails/select",
        {
          id: this.$route.params.id,
        },
        (res) => {
          if (res.data.data) {
            // this.cClassifyList.forEach((v) => {
            //   if (v.id == res.data.data.cusTypeId) {
            //     this.form.cusTypeId = [v.id]; //客户类别
            //     return false;
            //   } else {
            //     if (v.sub) {
            //       v.sub.forEach((k) => {
            //         if (k.id == res.data.data.cusTypeId) {
            //           this.form.cusTypeId = [v.id, k.id];
            //           return false;
            //         }
            //       });
            //     }
            //   }
            // });
            
            this.companyInfo = res.data.data;
           
            this.supplierType = res.data.data.supplierType === 4;
            this.formSet.check = this.supplierType;
            this.bindingPartnerTraderId = res.data.data.bindingPartnerTraderId;
            this.sellerId = res.data.data.sellerId;
            this.bindingDealerTraderId =
              res.data.data.bindingDealerTraderId || null;
            this.userInfo = res.data.data;
            this.getAgencyList();
            this.getK3List()
            this.makesure(this.userInfo.saleId);
            this.$set(this.userInfo, "oldname", this.userInfo.name);
            this.$set(this.userInfo, "licenseImgArr", [
              { url: this.userInfo.licenseImg },
            ]);
            this.$set(
              this.userInfo,
              "photosArr",
              this.userInfo.photos.map((item) => {
                return { url: item };
              })
            );
            this.cchannel1 = res.data.data.cchannel1
              ? res.data.data.cchannel1
              : null; //一级客户渠道
            this.cchannel2 = res.data.data.cchannel2
              ? res.data.data.cchannel2
              : null; //二级客户渠道
            this.form.cchannel = this.cchannel1
              ? this.cchannel2
                ? [this.cchannel1, this.cchannel2]
                : [this.cchannel1]
              : [];
            this.form.clevel = res.data.data.clevel
              ? res.data.data.clevel
              : null; //客户等级
            this.form.ctype = res.data.data.ctype ? res.data.data.ctype : 2; //客户类型
           
            this.form.k3No = res.data.data.k3No
            this.remarks = res.data.data.remarks; //备注
            this.clientChannelList = res.data.data.customerChannelVOs
              ? res.data.data.customerChannelVOs
              : []; //渠道列表
            this.clientLevelList = res.data.data.customerLevelPos
              ? res.data.data.customerLevelPos
              : []; //等级列表
            this.groupList = res.data.data.customerGroupVOs
              ? res.data.data.customerGroupVOs
              : []; //所属分组列表

            this.districtList = res.data.data.customerDistrictVO
              ? [res.data.data.customerDistrictVO]
              : []; //所属区域列表
            this.districtList[0].id = "0"; //id转化为字符串
            this.form.districtIds = res.data.data.district
              ? res.data.data.district.split(",").map((n) => {
                  return Number(n);
                })
              : [];
            // this.form.districtIds= [0];
            console.log(this.form,' console.log(this.form)');
            this.form.groupId = res.data.data.groupId
              ? res.data.data.groupId
              : null;
            if (res.data.data.traderDistributeVOs) {
              res.data.data.traderDistributeVOs.forEach((item) => {
                item.id = item.skuId;
              });
              this.clearwilladd();
              this.initgood(res.data.data.traderDistributeVOs);
            }
            if (res.data.data.customerBrandVOs) {
              let a = res.data.data.customerBrandVOs.map((n) => {
                return {
                  brandName: n.brandName,
                  brandId: n.id,
                  isDeleted: n.isDeleted,
                  classifyName: n.classifyName,
                  logoIcon: n.logoIcon,
                };
              });
              this.setBrandList(a);
            } else {
              this.setBrandList([]);
            }
          }
        },
        1,
        10,
        null,
        1
      );
    },
    handleSave() {
      // console.log(this.userInfo, 'userinfo');
      // const reg = /^(?!\d+$)[\d\u4e00-\u9fa5a-zA-Z]{0,20}$/;
      // if(!reg.test(this.userInfo.platformDescription)){
      //     this.$message({
      //         message:'请正确'
      //     })
      // }
      if (this.userInfo.ctype == 3) {
        const body = {
          id: this.userInfo.id,
          buyerId: this.userInfo.buyerId,
          sellerId: this.userInfo.sellerId,
          platformDescription: this.userInfo.platformDescription,
          bindingDealerTraderId: this.bindingDealerTraderId || 0,
          saleId:
            this.linkName.managerId != undefined
              ? this.linkName.managerId
              : "0", //关联业务员id
          ctype: this.form.ctype,
          groupId: this.form.groupId,
          // cusTypeId: this.form.cusTypeId[this.form.cusTypeId.length - 1],
          k3No: this.form.k3No
          // xToken : sessionStorage.getItem("xToken")
        };
        this.saveFn(body);
      } else {
        this.$refs.form.validate((valid) => {
          if (valid) {
            let body = {
              bindingDealerTraderId: this.bindingDealerTraderId || 0,
              id: this.userInfo.id,
              platformDescription: this.userInfo.platformDescription,
              buyerId: this.userInfo.buyerId,
              sellerId: this.userInfo.sellerId,
              // isRetailer: this.isRetailer ? "1" : "0",
              // isDealer: this.isDealer ? "1" : "0",
              ctype: this.form.ctype,
              clevel: this.form.clevel,
              // cusTypeId: this.form.cusTypeId[this.form.cusTypeId.length - 1],
              k3No: this.form.k3No,
              remarks: this.remarks,
              //isDistribution: this.userInfo.isDistribution,
              //channelType: this.channelType,
              saleId:
                this.linkName.managerId != undefined
                  ? this.linkName.managerId
                  : "0",
              groupId: this.form.groupId,
              // xToken : sessionStorage.getItem("xToken"),
              // districtId: this.form.districtIds[this.form.districtIds.length - 1],
              cbrand:
                this.brandIdList.length > 0 ? this.brandIdList.join(",") : 0, //品牌id列表
            };
            if (this.form.ctype == 2) {
              body.cchannel1 = this.cchannel1;
              body.cchannel2 = this.cchannel2;
            }
            // console.log(body)
            this.saveFn(body);
          }
        });
      }
    },
    saveFn(body) {
      axiosApi(
        "/trader/updateClientAccountInfo/update",
        body,
        (res) => {
          // sessionStorage.setItem("xToken", res.data.context.xToken); //xToken
          this.$message({
            type: "success",
            message: "操作成功",
            duration: 1000,
          });
          this.loading();
          this.resetBrandList();
          this.getClientConfig();
        },
        1,
        10,
        (res) => {
          // sessionStorage.setItem("xToken", res.data.context.xToken); //xToken
          MessageBox.alert(res.data.message, "提示信息", {
            confirmButtonText: "确定",
          });
        },
        1
      );
    },
    //			deleteRow(index, rows) {
    //				this.$msgbox({
    //					title: '提示',
    //					message: '你确定要删除？',
    //					showCancelButton: true,
    //					confirmButtonText: '确定',
    //					cancelButtonText: '取消',
    //					beforeClose: (action, instance, done) => {
    //						if(action === 'confirm') {
    //							done();
    //							rows.splice(index, 1);
    //						} else {
    //							done();
    //						}
    //					}
    //				}).then(action => {
    //					if(action == "confirm") {
    //						this.$message({
    //							type: 'info',
    //							message: 'action: 您已删除成功！'
    //						});
    //					}
    //				});
    //			},
    changeHeight() {
      var height = document.documentElement.clientHeight;
      document.getElementById("clientEdit").style.minHeight =
        height - 211 + "px";
    },
    handleClick() {
      // console.log("handleClick")
      if (this.activeTab != 1) {
        //改变高度
        this.changeHeight();
      }
      if (this.activeTab == 2) {
        //账号信息  发票
        axiosApi(
          "/trader/selectInvoiceInfoById/select",
          {
            traderId: this.userInfo.buyerId,
          },
          (res) => {
            if (res.data.data) {
              res.data.data.forEach((item) => {
                if (item.invoiceType == 1) {
                  this.InvoiceInfoVO1 = item;
                }
                if (item.invoiceType == 2) {
                  this.InvoiceInfoVO2 = item;
                }
              });
            }
          }
        );
      }
      /*授信管理操作*/
      if (this.activeTab == 3) {
        this.searchCredit();
      }
      if (this.activeTab == 4) {
        this.orderDeliveryAddress();
      }
      /* if (this.activeTab == 5) {
        if (this.currentPage != 1) {
          this.currentPage = 1;
          return false;
        }
        axiosApi(
          "/goods/protocolGoodsPage/select",
          {
            sellerId: this.userInfo.sellerId,
            buyerId: this.$route.params.buyerId,
          },
          (res) => {
            if (res.data.data) {
              this.isOpenPriceSync = res.data.data.isOpenPriceSync || false;
              this.goodsData = res.data.data.page.elements;
              this.currentPage = res.data.data.page.pageIndex;
              this.total = res.data.data.page.totalCount;
            }
          },
          this.currentPage,
          10
        );
      } */
      if (this.activeTab == 9) {
        this.getRepertoryList();
      }
			if (this.activeTab == 10) {  // 付款账号管理
				this.getBindList()
			}
    },
    /* handleCurrentpage(val) {
      // 分页查询
      this.currentPage = val;
      axiosApi(
        "/goods/protocolGoodsPage/select",
        {
          sellerId: this.userInfo.sellerId,
          buyerId: this.$route.params.buyerId,
        },
        (res) => {
          if (res.data.data) {
            this.isOpenPriceSync = res.data.data.isOpenPriceSync || false;
            this.goodsData = res.data.data.page.elements;
            // this.currentPage = res.data.data.page.pageIndex;
            this.total = res.data.data.page.totalCount;
          }
        },
        this.currentPage,
        10
      );
    }, */
    orderDeliveryAddress() {
      let ctype = this.userInfo.storeCodeNo
        ? this.userInfo.ctype
        : this.form.ctype;
      if (this.userInfo.ctype == 3 || this.form.ctype == 6) {
        axiosApi(
          "/trader/address/list",
          {
            traderId: this.userInfo.buyerId,
            addressType: 3,
          },
          (res) => {
            if (res.data.data) {
              this.deliveryAddressList = res.data.data;
            }
          }
        );
      } else {
        axiosApi(
          "/trader/orderDeliveryAddress/select",
          {
            traderId: this.userInfo.buyerId,
          },
          (res) => {
            if (res.data.data) {
              this.deliveryAddressList = res.data.data;
            }
          }
        );
      }
    },
    addressCloseDialog(type) {
      //收货地址弹窗
      if (type == 1) {
        //编辑
        this.addressDialog1 = false;
      }
      if (type == 2) {
        //审核
        this.addressDialog2 = false;
      }
      this.orderDeliveryAddress(); //重新加载收货地址列表
    },
    /* getStoreInfo(item) {
      //设价方案弹窗
      // console.log(item, "shl")
      this.scheme = true;
      const api1 = "/trader/storeClientDetails/select";
      axiosApi(
        api1,
        {
          sellerId: sessionStorage.traderId,
          buyerId: item.buyerId,
        },
        (res) => {
          this.ruleForm = Object.assign({}, this.ruleForm);
          this.ruleForm.storeName = res.data.data.storeName; //商户名称
          this.ruleForm.provinceId = res.data.data.provinceId; //省id
          this.ruleForm.provinceName = res.data.data.provinceName; //省名称
          this.ruleForm.cityId = res.data.data.cityId; //市Id
          this.ruleForm.cityName = res.data.data.cityName; //市名称
          this.ruleForm.regionId = res.data.data.regionId; //区ID
          this.ruleForm.regionName = res.data.data.regionName; //区名称
          this.address =
            this.ruleForm.provinceName +
            "/" +
            this.ruleForm.cityName +
            "/" +
            this.ruleForm.regionName; //省市区拼接
          this.ruleForm.buyerAdd = res.data.data.attr; //详细地址
          //this.ruleForm.isDistribution = res.data.data.isDistribution; //是否为总经销
          //this.ruleForm.channelType = res.data.data.channelType; //客户渠道
          //this.ruleForm.isRetailer = res.data.data.isRetailer; //客户类型/品牌商
          //this.ruleForm.isDealer = res.data.data.isDealer; //客户类型/分销商
          // if(this.ruleForm.isRetailer == 0) {
          // 	this.check = false
          // } else {
          // 	this.check = true
          // }; //品牌商
          // if(this.ruleForm.isDealer == 0) {
          // 	this.checked = false
          // } else {
          // 	this.checked = true
          // }; //分销商

          this.ruleForm.cchannel1 = res.data.data.cchannel1; //一级客户渠道
          this.ruleForm.cchannel2 = res.data.data.cchannel2; //二级客户渠道
          this.this.ruleForm.cchannel = [
            res.data.data.cchannel1,
            res.data.data.cchannel2,
          ];
          this.ruleForm.clevel = res.data.data.clevel; //客户等级
          this.ruleForm.ctype = res.data.data.ctype ? res.data.data.ctype : 2; //客户类型
        }
      );
      const api2 = "/goods/protocolClientInfo/select";
      axiosApi(
        api2,
        {
          id: item.id,
        },
        (res) => {
          //console.log(res,'设价详细信息')
          //this.ruleForm.agreementType = res.data.data.agreementType; //客户设价类型
          this.ruleForm.wholesalePrice = res.data.data.wholesalePrice; //批发价
          this.ruleForm.minimunMoq = res.data.data.minimunMoq; //最低起订量
          //this.ruleForm.returnConditionCount = res.data.data.returnConditionCount; //满返条件
          //this.ruleForm.returnCount = res.data.data.returnCount; //返多少
          this.ruleForm.startDate = res.data.data.startDate; //开始时间
          this.ruleForm.endDate = res.data.data.endDate; //结束时间
          this.ruleForm.remark = res.data.data.remark; //备注
          this.ruleForm.dateTimevalue = [
            this.ruleForm.startDate,
            this.ruleForm.endDate,
          ]; //页面显示时间
          //console.log(this.dateTimevalue)
        }
      );
    }, */
    addBrandClick() {
      this.$refs.addBrand.show();
    },
    handleRemove(file, fileList) {
      if (file && file.status === "success") {
        //移除方法
        console.log(file, fileList);
        this.fileList = fileList;
      }
    },
    handlePreview(file) {
      console.log(file);
    },
    handleExceed(files, fileList) {
      this.$message.warning(`最多可上传10个文件`);
    },
    successFile(res, file, fileList) {
      this.uploadFileList.push(res.data[0]);
      file.url = res.data[0];
      this.fileList = fileList;
    },
    errorFile() {
      this.$message.warning("上传失败");
    },
    beforeAvatarUpload(file) {
      this.loadingFile = true;
      const imgSize = file.size / 1024 / 1024;
      let bool = imgSize > 0 && imgSize < 10;
      if (!(imgSize > 0 && imgSize < 10)) {
        this.$message.warning("请上传大小不超过10M的图片");
      }
      let testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      const extension = testmsg === "jpg";
      const extension2 = testmsg === "png";
      const extension3 = testmsg === "doc";
      const extension4 = testmsg === "docx";
      const extension5 = testmsg === "pdf";
      // const isLt2M = file.size / 1024 / 1024 < 10
      if (
        !extension &&
        !extension2 &&
        !extension3 &&
        !extension4 &&
        !extension5
      ) {
        this.$message({
          message: "上传文件只能是 jpg,png,doc,docx ,pdf格式!",
          type: "warning",
        });
        return false;
      }

      return bool;
    },
    beforeRemove(file, fileList) {
      if (file && file.status === "success") {
        return this.$confirm(`确定要删除此文件吗？`, "提示");
        //移除前方法
      }
    },
    saveFile() {
      console.log(this.fileList);
      let ccontract = [];
      this.fileList.forEach((v) => {
        ccontract.push(v.url);
      });

      let api = "/trader/customerContract/update";
      let body = {
        id: this.userInfo.id,
        contractUrls: ccontract.join(","),
      };
      axiosApi(api, body, (res) => {
        this.$message.success("协议保存成功");
      });
    },
    getFileList() {
      let api = "/trader/customerContract/select";
      let body = {
        id: this.id,
      };
      axiosApi(api, body, (res) => {
        console.log(res, "res");
        console.log(res.data, "data1");
        console.log(res.data.data, "data2");
        let arr = res.data.data;
        if (arr && arr.length > 0) {
          this.uploadFileList = [].concat(arr);
        }

        let arr1 = [];
        arr.forEach((item) => {
          arr1.push(item.split("/")[item.split("/").length - 1]);
        });
        let arr2 = arr.map((item) => ({ url: item }));
        arr2.forEach((item, i) => {
          item.name = arr1[i];
        });
        this.fileList = arr2;
      });
    },
    //获取仓库数据
    getRepertoryList() {
      axiosApi(
        "/warehouse/directionTemplate/list",
        { sellerId: sessionStorage.getItem("traderId") },
        (res) => {
          this.repertoryData = res.data.data.elements
            ? res.data.data.elements
            : [];
        }
      );
      //获取客户绑定的仓库
      axiosApi(
        "/warehouse/templateCustomerRelation/info",
        { traderId: this.userInfo.buyerId },
        (res) => {
          if (res.data.data) {
            this.no = res.data.data.warehouseTemplateNo;
          } else {
            this.no = null;
          }
        }
      );
    },
    repertorySave() {
      if (this.no) {
        axiosApi(
          "/warehouse/templateCustomerRelation/insert",
          {
            traderIdList: [this.userInfo.buyerId],
            warehouseTemplateNo: this.no,
            sellerId: sessionStorage.traderId,
          },
          (res) => {
            this.$message.success("保存成功");
          }
        );
      } else {
        axiosApi(
          "/warehouse/templateCustomerRelation/delete",
          { traderIdList: [this.userInfo.buyerId] },
          (res) => {
            this.$message.success("保存成功");
          }
        );
      }
    },
  },
};
</script>

<style lang="less">
#clientEdit {
  .text-center {
    text-align: center;
  }
  .active-icon {
    background-color: #f04844;
  }
  .info {
    height: 95px;
    .imgWrap {
      float: left;
      width: 90px;
      height: 90px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .rightInfo {
      float: left;
      height: 100%;
      margin-left: 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      .inline-div {
        display: inline-block;
      }
      .icon-wrap {
        position: relative;
        .tip {
          position: absolute;
          display: none;
          color: #666;
          width: 250px;
          line-height: 24px;
          border: 1px solid #999;
          border-radius: 10px;
          background-color: #fff;
          opacity: 0.7;
          left: -114px;
          top: 36px;
          i {
            position: absolute;
            top: -21px;
            left: 44%;
            font-size: 26px;
            color: #999;
          }
        }
        .tip2 {
          left: -114px;
          i {
          }
        }
        .company-icon:hover,
        .upgrade:hover {
          .tip {
            display: block;
          }
        }
      }
      .icon {
        font-size: 12px;
        display: inline-block;
        text-align: center;
        width: 20px;
        line-height: 20px;
        border-radius: 30%;
        color: #fff;
        cursor: pointer;
        position: relative;
      }
      .company-icon {
        background-color: rgba(240, 72, 68, 1);
      }
      .upgrade {
        background-color: rgba(242, 242, 242, 1);
      }
    }
  }
  .code {
    background-color: #ff4201;
    color: #fff;
    display: inline-block;
    border-radius: 3px;
    padding: 0 10px;
    margin-left: 10px;
  }

  .update-btn {
    margin-top: 24px;
    margin-right: 20px;
    width: 120px;
  }

  .hasP {
    .el-upload-list--picture-card .el-upload-list__item {
      width: 100px;
      height: 100px;
    }
  }

  .lh-32 {
    line-height: 32px;
  }

  .w160 {
    width: 160px;
  }

  .client-form {
    .el-form-item__label {
      padding-right: 30px;
    }
  }

  .labelInfo {
    display: inline-block;
    width: 185px;
  }

  .ml-185 {
    margin-left: 185px;
  }

  .ml-215 {
    margin-left: 215px;
    border: 1px solid #ddd;
    width: 250px;
    padding-left: 20px;
  }

  .el-radio-group {
    font-size: 14px;
  }

  .el-input--mini {
    width: 77px;
  }

  .el-textarea__inner {
    resize: none;
  }

  .schemeDialog {
    .el-dialog {
      width: 700px;
      max-height: 720px;
    }

    .el-dialog__body {
      padding-bottom: 80px;

      .el-form-item__label {
        width: 215px;
        margin-right: 20px;
      }

      .el-form-item__error {
        padding-left: 145px;
      }

      .el-input {
        width: 300px;
      }

      .box {
        .el-input {
          width: 100px;
        }

        .boxchild .el-form-item {
          width: 260px;
        }

        .el-box {
          .el-form-item__error {
            padding-left: 27px;
          }
        }
      }
    }
  }

  .w700 {
    width: 700px;

    textarea {
      height: 120px;
    }
  }

  .table-700 {
    width: 700px;
  }
  .el-upload__tip {
    font-size: 14px;
    color: #999;
  }
  .upload-demo {
    padding-left: 30px;
  }
  .saveFiles {
    margin-left: 30px;
    margin-top: 60px;
    background-color: #0079fe !important;
    border: 1px solid #0079fe !important;
  }
  .chooseFiles {
    width: 120px;
    height: 40px;
    background-color: rgba(255, 255, 255, 1) !important;
    box-sizing: border-box;
    color: #999999;
    font-size: 14px;
    border: 1px solid rgba(228, 228, 228, 1) !important;
  }
  .el-upload-list {
    width: 300px;
  }
  .el-upload-list__item .el-icon-close-tip {
    display: none !important;
  }

}
.el-select-dropdown__item{
  height: auto;
  padding: 5px 20px;
}
</style>
