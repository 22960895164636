<template>
  <div>
    <div class="big-title">满减活动详情
		</div>
    <div class="item-list" v-loading="loading">
      <div class="each-item mt-20">
        <div class="item-title fl">活动名称</div>
        <div class="item-body fl" :style="{'width':'400px'}">{{activityVo.activityName}}</div>
        <!-- <span style="margin-left:50px">{{activityVo.isOvering ? '可叠加' : '不可叠加'}}</span> -->
        <span style="margin-left:50px">{{activityVo.isNewUser ? '首单' : '非首单'}}</span>
      </div>
      <div class="each-item mt-20">
        <div class="item-title fl">活动时间</div>
        <div class="item-body fl">{{activityVo.gmtStart | timenyr}} 至 {{activityVo.gmtEnd | timenyr}}</div>
      </div>
      <div class="each-item mt-20" v-if="activityVo.type == '1'">
        <div class="item-title fl">活动规则</div>
        <div class="item-body fl">订购金额</div></br>
        <div :style="{'margin-left': '180px','margin-top': '10px'}" v-for="a in cutRulePos">订购满{{a.fullMoney}}元 减{{a.minusValue}}元 </div>
      </div>
      <div class="each-item mt-20" v-if="activityVo.type == '2'">
        <div class="item-title fl">活动规则</div>
        <div class="item-body fl">订购数量</div></br>
        <div :style="{'margin-left': '180px','margin-top': '10px'}" v-for="a in cutRulePos">订购满{{a.fullAmount}}件 减{{a.minusValue}}元</div>
      </div>


      <div class="each-item mt-20">
        <div class="item-title fl">参与次数</div>
        <div class="item-body fl" v-if="activityVo.isLimitTimes == '0'">不限次数</div>
        <div class="item-body fl" v-else-if="activityVo.isLimitTimes == '1'">限制 {{ activityVo.limitTimes }} 次</div>
      </div>
      
      <div class="each-item mt-20 pl-30" v-if="activityVo.rangCustomerType =='1'">
        <div class="item-title fl">参与活动客户</div>
        <div class="item-body fl">全部客户</div>
      </div>
      <div class="each-item mt-20 pl-30" v-else-if="activityVo.rangCustomerType =='2'">
        <div class="item-title fl">参与活动客户</div>
        <div class="item-body fl ml-70">
          <div>部分客户</div>
          <tableA :size="[100,220,150,150]" :operate="true" :check="true" :noform="true"></tableA>
        </div>
      </div>
      <div class="each-item mt-20 pl-30" v-if="activityVo.rangeType =='1'">
        <div class="item-title fl">参与活动商品</div>
        <div class="item-body fl">全部商品</div>
      </div>
      <div class="each-item mt-20 pl-30" v-else>
        <div class="item-title">参与活动商品</div>
        <div class="item-body ml-70">
          <div>{{ activityVo.rangeType =='2' ? '部分商品参与' : '部分商品不参与' }}</div>
          <tableB :size="[160,420,100]" :operate="true" :check="true" :noform="true" style='width: 683px;'></tableB>
          <!-- <el-table :data="goodsSkuVos" tooltip-effect="dark" style="width: 740px" class="mt-10 table-default">
            <el-table-column label="商品编码" prop='skuNo' width="">
            </el-table-column>
            <el-table-column label="商品名称" prop='skuName' width="">
            </el-table-column>
            <el-table-column label="库存" prop='stockEnableQuantity' width="">
            </el-table-column>
          </el-table> -->
        </div>
      </div>
      <div style="text-align:center;margin:10px">
          <el-button type="primary" @click="()=>{this.$router.go(-1)}">返回</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import {mapMutations, mapActions} from 'vuex'
import { axiosApi,basePath } from "../../../api/api"
export default {
  data() {
    return {
      loading: false,
      activityGoodsRelationPoList: [],
      activityVo: {},
      cutRulePos: [],
      goodsSkuVos: [],
    }
  },
  methods: {
    ...mapMutations({
      'initclient': 'initclient',
      'initgood': 'initgood'
    }),
    getcutdetail() {
      this.loading = true
      const api = '/marketing/activityCut/Update/view'
        let body = {
            activityId: sessionStorage.getItem('cutid')
        }
        let newbody = Object.assign({},body)
        axiosApi(api, newbody, (res)=>{
          this.callback(res.data.data)
        },0, 10)
    },
    callback(res) {
      this.loading = false
      this.activityGoodsRelationPoList = res.activityGoodsRelationPoList
      this.activityVo = res.activityVo
      this.cutRulePos = res.cutRulePos
      this.goodsSkuVos = res.goodsSkuVos
      setTimeout(()=>{
        //初始化客户列表
        this.initclient(res.traderVos ? res.traderVos : [])
        //初始化商品列表
        this.initgood({willadd:res.goodsSkuVos ? res.goodsSkuVos : []})
      },0)
    }
  },
  mounted() {
    this.$nextTick(()=>{
      this.getcutdetail()
    })
  }
}
</script>
<style lang="less">
	.ml-70{
		margin-left: 70px;
	}
  .big-title {
    text-align: center;
    font-size: 24px;
  }
  .item-list {
    font-size: 15px;
    .each-item {
      overflow: hidden;
      .item-title {
        width: 160px;
        text-align: right;
        padding-right: 20px;
      }
    }
  }
</style>