<template>
	<!--查看经销协议-->
	<div id="lookLicense">
		<div class="body mt-50 clearfix">
			<div class="clearfix font-12">
				<p class="fl">分类：<span class="ml-30">{{this.classifyName}}</span></p>
				<p class="fl ml-50">品牌：<span class="ml-30">{{this.brandName}}</span></p>
			</div>
			<p class="mt-30">请选择已有凭证：</p>
			<div class="clearfix mt-20" v-if="certRelationVOs.length>0" ref="c">
				<div class="item fl tac mr-10 mb-30" v-for="(item,index) in certRelationVOs" :key="index" v-if='item.state != 3'>
					<img class="cursor icon-100" @click="getPhotos(index)" :src="item.certImg" />
					<el-checkbox v-model="item.checked" @change='handleChange()'></el-checkbox>
					<p class="font-14" v-if="item.state== 1">待审核</p>
				</div>
				<el-dialog :visible.sync="imgDialogVisible" size="tiny" @close="imgDialogVisible=false">
					<img width="100%" :src="onePhoto" alt="协议">
				</el-dialog>
			</div>
			<div v-else class="mt-10">
				您还没有上传过销售资质,请先上传！
			</div>
			<el-button @click="getId" type="danger" class="mt-50" :disabled='canAdd'>提交</el-button>
		</div>
	</div>
</template>

<script>
	import { axiosApi, basePath } from "../../api/api"
	import { MessageBox } from 'element-ui';
	export default {
		data() {
			return {
				dialogImageUrlList: [], //已有凭证
				dialogImageUrl: '',
				dialogVisible: false,
				imgDialogVisible: false,
				ids: [],
				certRelationVOs: [],
				onePhoto: '', //单个图片放大
				canAdd: true,
				classifyName:'',
				brandName:''
			}
		},
		methods: {
			//点击图片放大
			getPhotos(index) {
				this.imgDialogVisible = true
				this.onePhoto = this.certRelationVOs[index].certImg

			},
		//			查看已有销售凭证
			achieve() {
				this.classifyName = sessionStorage.classifyName,
				this.brandName = sessionStorage.brandName,
				axiosApi("/trader/findTraderCertList/select", {
					traderId: sessionStorage.traderId,
					brandId: this.$route.params.brandId,
					classifyId: this.$route.params.classifyId
				}, (res) => {
					if(res.data.data) {
						this.certRelationVOs = res.data.data.certRelationVOs;
						this.certRelationVOs.forEach((item) => {
							if(typeof item.checked == "undefined") {
								this.$set(item, "checked", false)
							}
						})
					}
				})
			},
			//判断是否有选择进行提交
			handleChange() {
				for(let i = 0; i < this.certRelationVOs.length; i++) {
					if(this.certRelationVOs[i].checked) {
						this.canAdd = false;
						break;
					} else {
						this.canAdd = true
					}
				}
			},
			//获取id
			getId() {
				let _this = this
				this.certRelationVOs.forEach(function(item, index) {
					if(item.checked) {
						_this.ids.push(item.certImg)

					}
				})
				this.upload()
			},
			//			上传
			upload() {
				let _this = this;
				this.$confirm('您确定要上传这些图片？', '提示信息', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					const api = "/goods/sellingLicense/upload";
					const body = {
						sellerId: sessionStorage.traderId,
						brandId: _this.$route.params.brandId,
						classifyId: _this.$route.params.classifyId,
						spuNo: _this.$route.params.spuNo,
						certImgUrls: _this.ids
					};
					axiosApi(api, body, (res) => {
						this.$message({
							type: 'success',
							message: '上传成功!',
							duration: 1000
						});
						history.go(-1);
					}, this.currentPage, 10, (res) => {
						this.$message({
							type: 'info',
							message: res.data.message,
							duration: 3000
						});
					});
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消操作'
					});
				})

			},
			handlePictureCardPreview(file) {
				this.dialogImageUrl = file.url;
				this.dialogVisible = true;
			},
		},
		mounted() {
			this.$nextTick(function() {
				this.achieve();

			})
		}
	}
</script>

<style lang="less">
	#lookLicense .body {
		padding-left: 100px;
	}
	
	#lookLicense .body .item {
		width: 120px;
		position: relative;
		p{
			position: absolute;
			right: 13px;
			top: 0;
			width: 55px;
			height: 20px;
			line-height: 20px;
			color: #FFFFFF;
			background: rgba(0, 0, 0, 0.5);
			text-align: center;
		}
	}
	
	#lookLicense .body .remark .el-dialog__body {
		padding: 30px;
	}
	
	#lookLicense .imgOperator {
		background-color: #000000;
		opacity: 0.5;
		width: 50px;
		height: 38px;
		line-height: 38px;
		position: absolute;
		top: 0;
		right: 0;
	}
	
	#lookLicense .wah {
		width: 120px;
		height: 90px;
	}
</style>