<template>
	<div class="bg-f" id="public">
		<div class="width-1280 p45">
			<div class="p25">
				<img src="../../../assets/alipaylogo.png" />
			</div>
			<router-view></router-view>
		</div>
	</div>
</template>

<script>
	export default {
		mounted() {
			this.$nextTick(() => {
				this.changeHeight()
			})
		},
		methods: {
			changeHeight() {
				document.getElementById("public").style.minHeight = document.documentElement.clientHeight - 187 + "px";
			}
		}
	}
</script>

<style lang="less">
	#public {
		.p45{
			padding: 0 45px;
		}
		.p25 {
			padding: 25px 0 25px 0;
			border-bottom: 2px solid #eee;
		}
	}
</style>