<template>
  <div class="OrderNotification">
    <div class="pt-30 pl-30">
				<el-checkbox v-model="checkAll" @change="handleCheckAllChange" class="font-14">全选</el-checkbox>
					<span class="ml-100 font-14 color-blue cursor" @click="read">标记已读</span>
					<span class="ml-50 font-14 color-blue cursor" @click="remove">删除</span>

					<el-checkbox-group v-model="checkedCities" @change="handleCheckedOptionChange" class="mt-20">
						<div v-for="item in messageList" :key="item.id" class="option">
							<el-checkbox :label="item.id"/>
              <div class="infoDiv cursor" @click="orderDetail(item.orderSn,item.id)">
                <div :class="item.read?'infoTitle2':'infoTitle'">通知：{{item.noticeTitle}}</div>
								<div class="font-12 color-9">时间：{{item.gmtCreate | time}}</div>
              </div>
						</div>
					</el-checkbox-group>

					<div class="countBlank mt-50 ml-40" v-if="messageList.length == 0">
						<img src="@/assets/empty.png" alt="" />
						<p class="color-9 mt-15">消息空空如也</p>
					</div>

				<el-pagination v-if='total>0' class="tar mr-30 mt-20" :current-page="form.pageIndex" :page-size='form.pageSize' :total='total' layout="prev , pager, next, jumper" @current-change="handleCurrentChange">
					<!-- 分页组件 -->
				</el-pagination>
			</div>
  </div>
</template>

<script>
import { parseTime, computeNumber } from "@/utils";
import { axiosApi, basePath } from "~/api/api";
import { mapMutations } from "vuex"
import { getOrderNoticePage, delNotice, readNotice } from "~/api/messageCenter";
export default {
  data(){
    return {
      form:{
        pageIndex:1,
        pageSize:10,
      },
      checkAll: false,
      checkedCities: [],
      messageList: [],
      msgId: [], //哪条消息需要标为已读
      total: null,
    }
  },
  mounted(){
    this.getList()
  },
  activated() {
    
  },
  methods: {
    // 获取列表
    getList(){
      getOrderNoticePage(this.form).then(res=>{
        this.messageList = res.elements ? res.elements : [];
        this.total = res.totalCount;
        this.checkAll = false;
        this.checkedCities = [];
        this.msgId = []
      }).catch(err=>{

      })
    },
    // 分页
    handleCurrentChange(val) {
      this.form.pageIndex = val;
      this.checkedCities = [];
      this.msgId = []
      this.checkAll = false;
      this.getList()
    },

    orderDetail(orderSn,id) {//去订单详情页面
      this.readNotice([id])
      sessionStorage.setItem('orderSn', orderSn)
      this.$router.push({path: basePath + '/purchaseOrderDetail',query:{orderSn:orderSn}})
    },


    ...mapMutations({
      "mesnum": "mesnum"
    }),

    handleCheckAllChange(event) {
      if(this.checkAll) {
        this.messageList.forEach(item => {
          this.checkedCities.push(item.id)
        });
        this.msgId = this.checkedCities
      } else {
        this.checkedCities = [];
        this.msgId = this.checkedCities
      }
    },
    handleCheckedOptionChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.messageList.length;
      this.msgId = this.checkedCities
    },
    read() {
      if(this.msgId.length > 0) {
        this.readNotice(this.msgId)
      }else{  
        this.$message({
          type: 'info',
          message: '请选择需要标记为已读的消息!',
          duration: 1000
        });
      }
    },
    readNotice(ids){
      const body = {
        traderNoticeRecordIds: ids,
        noticeType: 'order', // 订单order，商户currency，活动activity
      };
      readNotice(body).then(res=>{
        this.$message({
          type: 'success',
          message: '操作成功!',
          duration: 1000
        });
        this.getList()
      }).catch(err=>{

      })
    },
    remove() {
      if(this.msgId.length !== 0) {
        this.$confirm('您是否要删除此条消息？', '提示信息', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.delNotice()
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
            duration: 1000
          });
        })
      } else {
        this.$message({
          type: 'info',
          message: '请选择需要删除的消息',
          duration: 1000
        });
      }
    },
    delNotice(){
      var data = {
        traderNoticeRecordIds: this.msgId,
        noticeType: 'order', // 订单order，商户currency，活动activity
      }
      delNotice(data).then(res=>{
        this.$message({
          type: 'success',
          message: '删除成功!',
          duration: 1000
        });
        this.form.pageIndex = 1
        this.getList()
      }).catch(err=>{

      })
    }
  }
}
</script>

<style lang="less">
.OrderNotification{
    .title:hover,
		.option:hover .text-decoration{
			text-decoration: underline;
		}
		.option {
			display: flex;
			padding-top: 20px;
			.el-checkbox{
				margin-top: 10px;
				flex: 0 0 auto;
				.el-checkbox__label{
					display: none ;
				}
			}
      .infoDiv{
        flex: 1;
        font-size: 14px;
        margin-left: 20px;
        .infoTitle{
          color: #20a0ff;
        }
        .infoTitle2{
          color: #000;
        }
      }
		}
		// .message-content{
		// 	display: inline-block;
		// 	vertical-align: middle;
		// 	width: 100%;
		// }
		.text {
			border-bottom: 1px solid #ddd;
		}
		.countBlank {
			width: 900px;
			height: 300px;
			padding-top: 100px;
			text-align: center;
		}
}
</style>