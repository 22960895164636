<template>
  <div class="content">
    <div class="title-box1">
      <p>系统设置</p>
    </div>
    <div class="bg-f content1 mt-5">
      <!-- <div class="content-item">
        <div class="title-box2">
          <span class="blueLine mr-5"></span>
          <span class="title-info">久集费用设置</span>
        </div>
        <div class="content-info pb-30">
          <el-form
            ref="form"
            :model="form"
            label-width="155px"
            :rules="rules"
            class="mt-5"
          >
            <el-form-item label="服务费" prop="serviceMoney">
              <el-input
                type="text"
                v-model="form.serviceMoney"
                placeholder="请输入服务费"
                :maxlength="6"
                @keyup.native="
                  form.serviceMoney = form.serviceMoney.replace(/\s+/g, '')
                "
              ></el-input
              >元/年
              <span class="remark">注：服务费为0时用户注册隐藏服务费选项</span>
            </el-form-item>
            <el-form-item label="保证金" prop="bondMoney">
              <el-input
                type="text"
                v-model="form.bondMoney"
                placeholder="请输入保证金"
                :maxlength="6"
                @keyup.native="
                  form.bondMoney = form.bondMoney.replace(/\s+/g, '')
                "
              ></el-input
              >元&nbsp;&nbsp;&nbsp;&nbsp;
              <span class="remark">注：保证金为0时用户注册隐藏保证金选项</span>
            </el-form-item>
            <el-form-item
              label="支付宝账号"
              class="mb-40 mt-30"
              prop="alipayAccount"
            >
              <el-input
                v-model="form.alipayAccount"
                placeholder="请输入支付宝账号"
                :maxlength="30"
                @keyup.native="
                  form.alipayAccount = form.alipayAccount.replace(/\s+/g, '')
                "
              ></el-input>
              <span class="remark">用于久集缴纳保证金</span>
            </el-form-item>
            <el-form-item
              label="支付宝姓名"
              class="mb-40 mt-30"
              prop="alipayName"
            >
              <el-input
                v-model="form.alipayName"
                placeholder="请输入支付宝姓名"
                :maxlength="20"
                @keyup.native="
                  form.alipayName = form.alipayName.replace(/\s+/g, '')
                "
              ></el-input>
            </el-form-item>
          </el-form>
          <div class="btn-box">
            <el-button @click="submit" size="large" type="danger"
              >保存</el-button
            >
          </div>
        </div>
      </div>-->
      <div class="content-item">
        <div class="title-box2">
          <span class="blueLine mr-5"></span>
          <span class="title-info">配送范围设置</span>
        </div>
        <div class="content-info pb-30">
          <el-form ref="form" :model="form" label-width="155px" :rules="rules" class="mt-5">
            <el-form-item label="配送范围" prop="deliveryRange">
              <el-radio v-model="form.deliveryRangeType" label="1">
                  <sy-input type="int" v-model="form.deliveryRange" size="mini" :min="1" :max="100"></sy-input>公里内
              </el-radio>
              <el-radio style="display:inline-block;margin-top:10px" v-model="form.deliveryRangeType" label="2">不限配送范围</el-radio>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div class="content-item">
        <div class="title-box2">
          <span class="blueLine mr-5"></span>
          <span class="title-info">注册二维码（用于开通门店久集账号）</span>
        </div>
        <div class="content-info content-box">
          <div class="qart-tl"></div>
          <vue-q-art :config="config" :downloadButton="false"></vue-q-art>
          <span class="gray">(请扫一扫完成注册)</span>
        </div>
      </div>
        <div class="btn-box">
            <el-button @click="submit" size="large" type="danger">保存</el-button>
        </div>
    </div>
  </div>
</template>
<script>
import { axiosApi } from "../../../api/api";
import VueQArt from "vue-qart";
import imagePath from "../../../assets/title.png";
var checkRange = (rule, value, callback) => {
  console.log(this.form);
  // if (form.deliveryRangeType == "1") {
  //   if (!value) {
  //     return callback(new Error("距离不能为空"));
  //   } else {
  //     if (!/^[0-9]\d{0,1}$/.test(value)) {
  //       return callback(new Error("距离为1-100之间的整数"));
  //     } else {
  //       callback();
  //     }
  //   }
  // } else if (form.deliveryRangeType == "2") {
  //   callback();
  // }
};
var checkMoney = (rule, value, callback) => {
  if (value === "" || value === undefined || value === null) {
    return callback(new Error("保证金不能为空"));
  }
  if (isNaN(Number(value))) {
    callback(new Error("请输入数字值"));
  } else {
    let reg = /^([1-9]\d*|[0]{1})$/;
    if (!reg.test(value)) {
      callback(new Error("请输入正确的数字值"));
    } else {
      callback();
    }
    callback();
  }
};
var checkServiceMoney = (rule, value, callback) => {
  if (value === "" || value === undefined || value === null) {
    return callback(new Error("服务费不能为空"));
  }
  if (isNaN(Number(value))) {
    callback(new Error("请输入数字值"));
  } else {
    let reg = /^([1-9]\d*|[0]{1})$/;
    if (!reg.test(value)) {
      callback(new Error("请输入正确的数字值"));
    } else {
      callback();
    }
    callback();
  }
};
var zfb = (rule, value, callback) => {
  let reg = /^1\d{10}$/;
  let reg1 = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{1,4})+$/;
  if (reg.test(value)) {
    callback();
  } else if (reg1.test(value)) {
    callback();
  } else {
    callback(new Error("请输入正确的支付宝账号"));
  }
};
export default {
  data() {
    return {
      form: {
        deliveryRangeType: "1",
        deliveryRange: 3,
        id: null
      },
      id: null, //开通商户申请记录id
      traderId: sessionStorage.getItem("traderId"),
      rules: {},
      config: {
        value: "",
        imagePath: imagePath,
        filter: "",
        size: 165
      }
    };
  },
  methods: {
    submit() {
      if (this.form.deliveryRangeType == "1") {
        if (!this.form.deliveryRange) {
          this.$message({
            type: "error",
            message: "距离不能为空",
            duration: 2000
          });
          return false;
        } else {
          if (!/^[1-9][0-9]{0,1}|100$/.test(this.form.deliveryRange)) {
            this.$message({
              type: "error",
              message: "距离为1-100之间的整数",
              duration: 2000
            });
            return false;
          }
        }
      }
      //支付宝账号字段待改
      let body = {
        traderId: sessionStorage.getItem("traderId"),
        id: this.id,
        registUrl: this.config.value,
        deliveryRange:
          this.form.deliveryRangeType == "1"
            ? Number(this.form.deliveryRange)
            : null
      };
      console.log(this.id, 238);
      axiosApi(
        "/superc/web/tenantShopApply/setting",
        body,
        res => {
          this.$message({
            type: "success",
            message: "配置成功！",
            duration: 1000
          });
          this.getSettings();
        },
        1,
        10,
        err => {
          this.$message({
            type: "error",
            message: err.data.message,
            duration: 1000
          });
        }
      );
    },
    getSettings() {
      axiosApi(
        "/superc/traderOpenSuperC/select",
        {
          traderId: sessionStorage.getItem("traderId")
        },
        res => {
          //配置信息
          this.form.deliveryRangeType =
            res.data.data.deliveryRange != null ? "1" : "2";
          this.form.deliveryRange = res.data.data.deliveryRange != null?res.data.data.deliveryRange:3;
          this.id = res.data.data.id;
          //验证保证金
          // this.$refs.form.validateField("bondMoney"); //部分表单字段验证
          // this.$refs.form.validateField("serviceMoney");

          //商户开通久集申请记录id
        }
      );
    }
  },
  mounted() {
    this.getSettings();
  },
  created() {
    //TODO二维码接口或者二维码url
    this.config.value =
      this.PROJECT.href + "/#/storeRegister?sellerId=" + this.traderId;
  },
  components: {
    VueQArt
  }
};
</script>
<style lang="less" scoped>
.content {
  min-height: 544px;
  box-sizing: border-box;
  .title-box1 {
    width: 100%;
    height: 60px;
    padding-left: 30px;
    background: rgba(255, 255, 255, 1);
    display: flex;
    align-items: center;
    box-sizing: border-box;
    p {
      width: auto;
      height: 16px;
      font-size: 16px;
      font-family: STHeitiSC;
      font-weight: 300;
      color: rgba(51, 51, 51, 1);
      line-height: 16px;
    }
  }
  .content1 {
    padding: 30px;
    .content-item {
      .title-box2 {
        width: 100%;
        height: 16px;
        display: flex;
        align-items: center;
        .blueLine {
          width: 4px;
          height: 16px;
          background: rgba(39, 176, 252, 1);
          display: inline-block;
        }
        .title-info {
          width: auto;
          height: 14px;
          font-size: 14px;
          font-family: STHeitiSC;
          font-weight: 300;
          color: rgba(51, 51, 51, 1);
          line-height: 14px;
        }
      }
    }
    .content-info {
      padding-top: 30px;
      .el-radio {
        display: block;
      }
      .el-radio + .el-radio {
        margin-left: 0;
      }
    }
    .content-box {
      padding: 30px 30px 50px 70px;
    }
    .el-form-item__content {
      display: flex;
    }
    .gray {
      width: 195px;
      height: 14px;
      text-align: center;
      font-size: 12px;
      font-family: STHeitiSC;
      font-weight: 300;
      color: rgba(153, 153, 153, 1);
      line-height: 14px;
      margin-left: 30px;
    }
  }
  form {
    .el-form-item {
      .el-form-item__label {
        padding: 0 20px 11px 0;
      }
      .remark {
        margin-left: 25px;
      }
      .el-button--danger.is-plain {
        span {
          color: #ff4201;
        }
      }
      img {
        height: 150px;
        width: 170px;
      }
    }
  }
  .btn-box {
    width: 650px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.qart-content {
  min-height: 400px;
  padding: 50px 0 0 100px;
  .qart-tl {
    line-height: 30px;
    margin: 0 0 20px 10px;
    color: #333;
  }
}
</style>
