<template>
  <div id="storeTop">
    <div class="header">
      <div class="width-1280 flex p45">
        <div>
          <div v-if="isloginUser" class="dib top clearfix">
            <p class="fl ml-30">{{ storeName }}</p>
          </div>
          <span class="color-danger cursor" v-else @click="thisGotoLoginPage()">请登录！</span>
        </div>
        <div class="flex">
          <ul>
            <li @click="homePage('/shop/shopIndex')">
              <span class="lh12">首页</span>
            </li>
            <li
              v-if="haveJicaiMenu && !isHotel && haveSellerCode"
              @click="homePage('/jicai/jicaiList')"
            >
              <img src="../assets/jicai_icon_top_red.png" class="imgJicai on" />
              <img
                src="../assets/jicai_icon_top_gray.png"
                class="imgJicai"
              /><span class="ml-5 lh12">集采</span>
              <img class="textNew fr tac" src="../assets/jicai_new.png" />
            </li>

            <li
              v-else-if="haveJicaiMenu && !isHotel && !haveSellerCode"
              @click="homePage('/jicai/jicaiListB')"
            >
              <img src="../assets/jicai_icon_top_red.png" class="imgJicai on" />
              <img
                src="../assets/jicai_icon_top_gray.png"
                class="imgJicai"
              /><span class="ml-5 lh12">集采</span>
              <img class="textNew fr tac" src="../assets/jicai_new.png" />
            </li>
            <li @click="homePage('/shop/shopingCar')" style="display:flex;align-items:center;justify-content:center">
              <i class="iconfont icon-shopping-car color-b"></i>
              <span class="ml-5 lh12">购物车</span>
            </li>
            <li @click="homePage('/search1/messageCenter')" >
              <span
                class="cursor f4 a"
                style="display:flex;align-items:center;justify-content:center"
                @click="homePage('/search1/messageCenter')"
              >
                <i class="iconfont a icon-message color-b"></i>
                <span class="ml3 lh12">消息</span>
                <el-badge :value="messageNum" class="item" :max="99" :hidden="messageNum == 0"></el-badge>
              </span>
            </li>
            <li @click="homePage('/help')">
              <span class="lh12">帮助中心</span>
            </li>
            <li
              v-if="isloginUser"
              class="myAccount"
              @mouseover="isInAccount = true"
              @mouseout="isInAccount = false"
            >
              <i
                @click="homePage('/accountCenter')"
                style="width: 96px;display:inline-block;"
              >
                <i class="lh12 textRed">我的账户</i>
                <i
                  v-if="isloginUser"
                  class="el-icon-caret-bottom color-danger"
                  :class="
                    isInAccount ? 'el-icon-caret-top' : 'el-icon-caret-bottom'
                  "
                ></i>
              </i>
              <div
                class="logout"
                :class="{ hidden: !isInAccount }"
                v-if="isloginUser"
              >
                <div class="textRed" v-if="showButton('INDEX_PURCHASE_ORDER',100038)" @click="homePage('/store/purchaseOrder')">采购订单</div>
                <div class="textRed" v-if="showButton('INDEX_SALE_ORDER',100051)" @click="homePage('/order')">销售订单</div>
                <div class="textRed" @click="logout">退出登录</div>
              </div>
            </li>
            <li v-else @click="homePage('/accountCenter')">
              <span class="lh12">我的账户</span>
            </li>
            <!-- <li @click="homePage()"><span class="ml-10 lh12">品牌商</span></li> -->
          </ul>
          <div class=" color-danger" :class="isloginUser ? 'ml-15' : 'ml-30'">
            服务电话：<span>{{ tel }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {PROJECT} from '~/config/index.js';
import { axiosApi, gotoLoginPage, basePath, baseURI } from "~/api/api.js";
import { mapMutations, mapActions } from "vuex";
import { getUnReadCount } from "~/api/publicInfo";

import { parseTime } from "@/utils";
export default {
  data() {
    return {
      tel: "400-888-9979",
      activeIndex: "1",
      storeBig: "",
      storeName: null,
      isAdmin: "",
      traderId: "",
      cityName: "",
      messageNum: "",
      //naw: "", //设置店铺的宽度
      isloginUser: false,
      basePath: basePath,
      isHotel: false,
      isInAccount: false, // 判断是否移入我的账号
      platformDescription: "", //平台，描述
      haveSellerCode: false, //是否是公司
      haveJicaiMenu: false, //是否有集采菜单
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.isHotel =
        sessionStorage.getItem("isHotel") === "1" &&
        sessionStorage.getItem("loginName")
          ? true
          : false;
      this.getIsCompany();
      this.getInfo();

      let that = this;
      window.setTraderId = function() {
        that.traderId = sessionStorage.getItem("traderId");
        setTimeout(() => {
          that.getInfo();
        }, 0);
      };
      window.site = function() {
        that.cityName = sessionStorage.cityName;
      };
    });
  },
  computed: {
    index() {
      return this.$store.state.shl.current;
    },
    path() {
      //暂不用
      return this.$store.state.shl.path;
    },
    mnum() {
      //消息个数
      return this.$store.state.shl.mnum;
    },
  },
  watch: {
    index(a, b) {
      if (a != b) {
        this.traderId = this.index;
      }
    },
    mnum(a, b) {
      if (a != b) {
        this.messageNum = this.mnum;
      }
    },
  },
  methods: {
    ...mapMutations({
      sth: "sth",
      updataSite: "updataSite",
      resetState: "resetState",
      setType: "setType",
      setUserId: "setUserId"
    }),
    ...mapActions('nav',['getMenuList']),
    thisGotoLoginPage() {
        // this.$router.push({
        //     path: '/customer/login'
        // })
        gotoLoginPage();
    },
    getInfo() {
      this.isloginUser = !sessionStorage.getItem("loginName") ? false : true;
      if(this.isloginUser){
        this.getMenuList()
      }
      this.getMessage();
      this.isAdmin = sessionStorage.isAdmin;
      this.cityName = sessionStorage.cityName;
    },
    homePage(url) {
      if (!this.isloginUser && url != "/shop/shopIndex") {
        gotoLoginPage();
        return false;
      }
      console.log(basePath + url)
      this.$router.push(basePath + url);
    },
    logout() {
      this.$confirm("您确定要退出么？", "提示信息", {
        confirmButtonText: "确定",
        type: "warning",
      })
        .then(() => {
          axiosApi(
            "/employee/logout",
            {},
            (res) => {
              gotoLoginPage();
            }
          );
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
            duration: 1000,
          });
        });
    },
    //默认加载店铺信息
    // manyStoresHomePage() {
    //   if (!this.isloginUser) return;
    //   axiosApi(
    //     "/trader/manyStoresHomePage/select",
    //     {
    //       loginName: sessionStorage.loginName,
    //     },
    //     (res) => {
    //       if (res.data.data) {
    //         //筛选有效店铺
    //         this.info = res.data.data.list.filter((item) => {
    //           return item.state == 2;
    //         });
    //         //筛选默认店铺
    //         this.storeItem = this.info.filter((item) => {
    //           return item.isDefault == 1;
    //         })[0];
    //         this.platformDescription = this.storeItem.platformDescription;
    //         sessionStorage.platformDescription = this.platformDescription || "";

    //         this.traderId = this.storeItem.traderId;
    //       }
    //     },
    //     1,
    //     10,
    //     (res) => {
    //       this.$message.error(res.data.message);
    //     }
    //   );
    // },
    getMessage() {
      //获取消息
      if (!this.isloginUser) return;
      getUnReadCount({employeeId: sessionStorage.id}).then(res=>{
          //记录未读信息的个数
          this.messageNum = res.allUnReadCount
      }).catch(err=>{

      })
    },
    guideCount() {
      window.document.body.style.overflow = "visible";
    },
    getIsCompany() {
        if (!sessionStorage.traderId) {
            return false
        }
      axiosApi(
        "/trader/findStoreInfo/select",
        { traderId: sessionStorage.traderId },
        (res) => {
          if (res.data && res.data.data) {
            this.haveSellerCode = res.data.data.haveSellerCode;
            this.storeName = res.data.data.storeName
            if(res.data.data.isAgreementChg === 0){
              // 判断当前用户 是否显示协议弹出框
              this.$store.commit('setUserProtocolDialog',true);
            }
            localStorage.setItem('integrationInfo', res.data.data.integrationNotice)
            sessionStorage.setItem("sellerId", res.data.data.sellerId); //
            this.setUserId({sellerId:res.data.data.sellerId})
            sessionStorage.setItem(
              "haveSellerCode",
              res.data.data.haveSellerCode
            ); //是不是公司 true 是 false 否
            sessionStorage.setItem("headCompany", res.data.data.headCompany); //是不是总公司 true 是 false 否
            sessionStorage.setItem(
              "sellerSupplierType",
              res.data.data.sellerSupplierType === 4
            ); //上级是否是城市拍档
            if (this.$store.state.clientType.isDealerUser) {
              //经销商
              sessionStorage.setItem(
                "provinceId",
                res.data.data.sellerDeliveryAddressVO
                  ? res.data.data.sellerDeliveryAddressVO.provinceId
                  : ""
              ); //公司所在区域的省的Id
              sessionStorage.setItem(
                "cityId",
                res.data.data.sellerDeliveryAddressVO
                  ? res.data.data.sellerDeliveryAddressVO.cityId
                  : ""
              ); //公司所在区域的省的Id
            } else {
              sessionStorage.setItem(
                "provinceId",
                res.data.data.deliveryAddressVO.provinceId
              ); //公司所在区域的省的Id
              sessionStorage.setItem(
                "cityId",
                res.data.data.deliveryAddressVO.cityId
              ); //公司所在区域的省的Id
            }
            
          }
        },
        1,
        10
      );
      /**
       * 调菜单接口 判断集采菜单是否存在
       */
      this.haveJicaiMenu = false;
      if (sessionStorage.loginName) {
        axiosApi(
          "/foundation/resource/displaytree",
          {
            loginName: sessionStorage.loginName,
            appId: 2,
            hostPrefix: PROJECT.hostPrefix
          },
          (res) => {
            res.data.data.forEach((item, index) => {
              if (item.id == 200086) {
                this.haveJicaiMenu = true;
              }
            });
          }
        );
      }
    },
  },
};
</script>

<style lang="less">
#storeTop {
  .myAccount {
    position: relative;
    padding-left: 15px;
    padding-right: 15px;
    box-sizing: border-box;
    margin-left: 15px !important;
    width: 96px;

    .textRed {
      font-style: normal;

      &:hover {
        color: #ff4201;
      }
    }

    &:hover {
      background-color: #fff;
      border: 1px solid #ddd;
      border-bottom: none;
      border-top: none;
    }

    .hidden {
      display: none;
    }

    .logout {
      position: absolute;
      padding-left: 15px;
      padding-right: 15px;
      width: 96px;
      box-sizing: border-box;
      left: -1px;
      border: 1px solid #ddd;
      border-top: none;
      background-color: #fff;
      z-index: 5000;
    }
  }

  .store_name {
    display: inline-block;
    width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .w-modal {
    width: 633px;
    height: 271px;
    /*// margin-right: 156px;*/
    background-image: url(../assets/42x.png);
    background-size: cover;
    background-repeat: no-repeat;
    margin-left: -35px;

    .text1 {
      padding: 150px 0 0 245px;

      .p1 {
        padding-left: 32px;
      }

      .p2 {
        /*// width: 240px;*/
        padding-left: 18px;
      }
    }

    .ml-260 {
      margin: 14px 0 0 308px;
    }
  }

  border-bottom: 1px solid #ddd;

  #hi {
    position: absolute;
    top: -50px;
  }

  .header {
    background-color: #f5f5f5;
    height: 36px;
    line-height: 36px;
    color: #666;
    font-size: 12px;

    .ml3 {
      margin-left: 3px;
    }

    .f4:hover {
      color: #ff4201;
    }

    .f4.a:hover {
      .iconfont.a {
        color: #ff4201;
      }
    }

    .el-select.s:hover {
      .el-input__icon {
        color: #ff4201;
      }
    }

    .el-badge__content {
      height: 15px;
      line-height: 15px;
      padding: 0 4px;
      position: absolute;
      top: -18px;
      left: -5px;
    }

    .flex {
      display: flex;
      justify-content: space-between;

      li {
        position: relative;

        img.on {
          display: none;
        }

        cursor: pointer;

        .textNew {
          width: 24px;
          height: 12px;
          line-height: 14px;
          border-radius: 9px;
          margin-top: 5px;
          margin-left: -5px;
        }
      }

      li:hover .imgJicai {
        display: none;
      }

      li:hover img.on {
        display: inline-block;
      }

      .lh12 {
        display: inline-block;
        height: 12px;
        line-height: 12px;
      }

      ul li {
        margin-left: 30px;
        float: left;
        cursor: pointer;

        &:hover {
          span,
          .iconfont {
            color: #ff4201;
          }
        }
      }

      .el-input__inner {
        border: 0;
        background: #f5f5f5;
      }

      .el-select.mini .el-input {
        width: 135px;
      }

      .el-select.small .el-input {
        width: 190px;
      }

      .el-select.mid .el-input {
        width: 135px;
      }

      .el-select.large .el-input {
        width: 135px;
      }

      .imgJicai {
        width: 14px;
        height: 14px;
        vertical-align: middle;
        float: left;
        // display: inline-block;
        margin-top: 12px;
      }
    }
  }
}
</style>
