<template >
    <div class="tableB">
        <el-form :inline="true" :model="skuObj" label-width="80px" class="clearfix" :style="{width:'850px'}"
                 v-if="!noform">
            <div class="fl">
                <el-form-item label="商品编码:">
                    <el-input v-model.trim="skuObj.skuNo"></el-input>
                </el-form-item>
                <el-form-item label="商品名称:">
                    <el-input v-model.trim="skuObj.skuName"></el-input>
                </el-form-item>
                <el-form-item label="商品分类:" v-if="$route.query.couponSource == '3'">
                    <el-select  v-model='skuObj.classifyId' placeholder='请选择' clearable>
                        <el-option v-for='item in classifylist' :key='item.classifyId'
                                   :label='item.classifyName'
                                   :value='item.classifyId'></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="商品分类:" v-else>
                    <el-select  v-model='skuObj.classifyId' placeholder='请选择' clearable>
                        <el-option v-for='item in classifylist' :key='item.id'
                                   :label='item.classifyName'
                                   :value='item.id'></el-option>
                    </el-select>
                </el-form-item>
            </div>
            <el-button type="primary" class="fl ml-50" @click="querygood">查询</el-button>
        </el-form>
        <div class="clearfix mt-20" v-if='total>0 && !noform' style="paddingLeft: 17px;">
            <el-checkbox class="fl" v-model="allgoodsChecked" @change="checkAllgoods">选中全部商品</el-checkbox>
            <el-button class="fl ml-20" type='text' @click="delgoods">批量删除</el-button>
        </div>
        <el-table :data="tableData" tooltip-effect="dark" ref="multipleClientTable" class="mt-10 table-default"
                  @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="50" v-if="!check">
            </el-table-column>
            <el-table-column label="商品编码" :prop="$route.query.couponSource != 3?'skuNo':'goodsNo'" :width="size[0]">
            </el-table-column>
            <el-table-column label="商品名称" prop='skuName' :width="size[1]">
            </el-table-column>
            <el-table-column label="库存" :prop="$route.query.couponSource != '3'?'mainStockEnable':'stockEnableQuantity'" :width="size[2]">
                <template slot-scope="scope">
                    <span v-if="$route.query.couponSource!=3">{{scope.row.mainStockEnable!==undefined?scope.row.mainStockEnable:scope.row.stockEnableQuantity}}</span>
                    <span v-else>{{scope.row.stockEnableQuantity!==undefined?scope.row.stockEnableQuantity:scope.row.mainStockEnable}}</span>
                </template>
            </el-table-column>
            <el-table-column label="操作" :width="size[3]" v-if="!operate">
                <template slot-scope="scope">
                    <el-button type="text" @click.native.prevent="deleteRow(scope.$index, tableData)" size="small">删除
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <div class="clearfix" v-if='total>0'>
            <el-pagination
                    class="fr mt-20 mr-50"
                    :current-page="currentPage"
                    :page-size='10'
                    :total='total'
                    layout="prev , pager, next, jumper"
                    v-if="tabletotalData.length>0"
                    @current-change="handleit"
            >
            </el-pagination>
        </div>
    </div>
</template>

<script>
    import {mapMutations} from 'vuex'

    export default {
        props: ['size', 'operate', 'check', 'noform'],
        data() {
            return {
                currentPage: 1,
                tableData: [],
                skuObj: {
                    skuNo: '',     //商品(sku)编码
                    skuName: '',     //商品名称
                    classifyId: ''  //商品分类
                },
                allgoodsChecked: false, // 选中全部商品
                searchedTableData: [],// 搜索表格
                isFirst: 1,
                isDelete: false,// 是否删除
                isSearched: false,// 是否已经搜索
                allMultipleSelection: [],//全部数据选中
            }
        },
        watch: {
            currentPage() {
                if (this.searchedTableData.length == 0 && !this.isSearched) {
                    this.tableData = this.tabletotalData.slice((this.currentPage - 1) * 10, (this.currentPage - 1) * 10 + 10)
                } else {
                    this.tableData = this.searchedTableData.slice((this.currentPage - 1) * 10, (this.currentPage - 1) * 10 + 10)
                }
            },
            total() {
                if (this.searchedTableData.length == 0 && !this.isSearched) {
                    this.tableData = this.tabletotalData.slice((this.currentPage - 1) * 10, (this.currentPage - 1) * 10 + 10)
                } else {
                    this.tableData = this.searchedTableData.slice((this.currentPage - 1) * 10, (this.currentPage - 1) * 10 + 10)
                }
            },
            tabletotalData() {
                if (this.isFirst > 1 && !this.isDelete) {
                    this.skuObj = {
                        skuNo: '',     //商品(sku)编码
                        skuName: '',     //商品名称
                        classifyId: ''  //商品分类
                    }
                    this.querygood()
                }
                this.isFirst += 1
                this.isDelete = false
            },
        },
        computed: {
            total() {
                if (this.searchedTableData.length == 0 && !this.isSearched) {
                    return this.$store.state.ticket.willadd.length
                } else {
                    return this.searchedTableData.length
                }
            },
            tabletotalData() {
                console.log(this.$store.state.ticket.willadd,'this.$store.state.ticket.willadd')
                return this.$store.state.ticket.willadd
            },
            classifylist() {
                return this.$store.state.classifylist.classifylistData ?
                    this.$store.state.classifylist.classifylistData : []
            }
        },
        created() {
        },
        methods: {
            ...mapMutations({
                'deleteRowdata': 'deletewilladddata',
                'clearwilladd': 'clearwilladd',
                'clearwilldelete': 'clearwilldelete',
                'willdetelegoods': 'willdetelegoods',
                'deletegoods': 'deletegoods',
            }),
            unique(arr1){
                const res = new Map();
                return arr1.filter((a) => !res.has(a.skuNo) && res.set(a.skuNo, 1))
            },
            querygood() {// 查询 前端筛选
                this.isSearched = true
                if (this.$route.query.couponSource != "3") {
                                    this.searchedTableData = this.tabletotalData.filter(item => {
                    let a = item.skuNo.indexOf(this.skuObj.skuNo) != -1, // 商品编码
                        b = item.skuName.indexOf(this.skuObj.skuName) != -1, //商品名称
                        c = !this.skuObj.classifyId || item.classifyId == this.skuObj.classifyId // 商品分类
                    return a && b && c
                })
                }else{
                    // 列表中有可能存在空goodsNo的商品
                    this.tabletotalData.forEach((item) => {
                        item.goodsNo = item.goodsNo == null ? '':item.goodsNo;
                    }); 
                    this.searchedTableData = this.tabletotalData.filter(item => {
                    let a = item.goodsNo.indexOf(this.skuObj.skuNo) != -1, // 商品编码
                        b = item.skuName.indexOf(this.skuObj.skuName) != -1, //商品名称
                        c = !this.skuObj.classifyId || item.classifyId == this.skuObj.classifyId // 商品分类
                    return a && b && c
                })
                this.searchedTableData = this.unique(this.searchedTableData)
                }
                this.tableData = this.searchedTableData.slice((this.currentPage - 1) * 10, (this.currentPage - 1) * 10 + 10)
                console.log(this.tableData,'this.tableDatathis.tableData')
                this.currentPage = 1
            },
            checkAllgoods(e) { //选择全部商品
                console.log(this.allgoodsChecked);
                if (e) {
                        if (this.searchedTableData.length == 0 && !this.isSearched) {// 使用 tabletotalData
                            this.allMultipleSelection = [...this.tabletotalData]
                        } else { // 使用 searchedTableData
                            this.allMultipleSelection = [...this.searchedTableData]
                        }
                        this.$refs.multipleClientTable.clearSelection()
                        this.allMultipleSelection.forEach(row => {
                            this.$refs.multipleClientTable.toggleRowSelection(row)
                        })
                } else {
                        this.allMultipleSelection = []
                        this.$refs.multipleClientTable.clearSelection()
                }
            },
            handleit(val) {
                this.currentPage = val
                    if (this.allMultipleSelection.length > 0) {// 有选中的数据
                        this.allMultipleSelection.forEach(row => {
                            this.$refs.multipleClientTable.toggleRowSelection(row)
                        })
                    } else {// 不存在选中数据
                        this.$refs.multipleClientTable.clearSelection()
                    }
            },
            handleSelectionChange(val) {
                this.willdetelegoods(val)

                    if (this.allMultipleSelection.length > val.length) {
                        this.allMultipleSelection = [...val]
                    }
                    if (this.searchedTableData.length == 0 && !this.isSearched) {
                        this.allgoodsChecked = this.allMultipleSelection.length === this.tabletotalData.length
                    } else {
                        this.allgoodsChecked = this.allMultipleSelection.length === this.searchedTableData.length
                    }
                if (this.allMultipleSelection.length <= val.length) {
                    this.allMultipleSelection.push(...val)
                    this.allMultipleSelection = [...new Set(this.allMultipleSelection)]
                }
                if (this.allMultipleSelection.length > 0) {
                    if (this.searchedTableData.length == 0 && !this.isSearched) {
                        this.allgoodsChecked = this.allMultipleSelection.length === this.tabletotalData.length
                    } else {
                        this.allgoodsChecked = this.allMultipleSelection.length === this.searchedTableData.length
                    }
                }
            },
            delgoods() {//批量删除商品
                if (this.$store.state.ticket.willdelete.length != 0) {
                    this.$msgbox({
                        title: '提示信息',
                        message: '您确定要删除这些商品？',
                        showCancelButton: true,
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        beforeClose: (action, instance, done) => {
                            if (action === 'confirm') {
                                this.deletegoods()
                                this.allgoodsChecked = false
                                done();
                            } else {
                                done();
                            }
                        }
                    })
                } else if (this.$store.state.ticket.willadd.length == 0) {
                    this.$alert('无商品可删除，请先添加商品', '', {
                        confirmButtonText: '确定',
                        callback: action => {

                        }
                    });
                } else {
                    this.$alert('请先勾选需要删除的商品', '', {
                        confirmButtonText: '确定',
                        callback: action => {

                        }
                    });
                }
            },
            deleteRow(index, rows) {
                this.$msgbox({
                    title: '提示信息',
                    message: '您确定要删除此商品？',
                    showCancelButton: true,
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    beforeClose: (action, instance, done) => {
                        if (action === 'confirm') {
                            this.deleteRowdata(index)
                            done();
                        } else {
                            done();
                        }
                    }
                })
            }
        },
        mounted() {
            this.$nextTick(() => {
                this.clearwilladd()
                this.clearwilldelete()
            })
        }
    }
</script>

<style lang="less">
    .tableB {
        .el-form .el-input {
            width: 120px;
        }
        .el-table__body-wrapper {
        margin-top:1px!important;
        }
        .el-form {
            .el-form-item {
                .el-form-item__label {
                    &::before {
                        display: none
                    }
                }
            }
        }
    }
</style>
