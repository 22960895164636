<template>
	<div id="bondManager" class="pb-50">
		<!-- 头部 -->
		<div class="title-tabs bg-f">
			<ul class="clearfix">
				<template v-for='(tab,index) in dataTabs' class="item">
					<li :type='index' :key="index+'dh'" :class="type==index?'active':''" @click='handleTabs'> {{tab}}
						<div :class="type==index?'under-line':''"></div>
					</li>
					<div class="vertical-line" :key="index+'di'"></div>
				</template>
			</ul>
		</div>
		<!-- 商家保证金 -->
		<div class="bg-f mt-5 pb-50" v-show="typeName=='商家保证金'">
			<div class="content pl-30 pr-30">
				<!-- 搜索区域 -->
				<div class="clearfix search">
					<el-form :inline='true' class="pt-30 " :model="storeForm" ref="searchForm">
						<el-form-item label='商家账号' class='mr-20'>
							<el-input v-model.trim="storeForm.loginName" :maxlength="30" placeholder="请输入商家账号"></el-input>
						</el-form-item>
						<el-form-item label='商家名称' class='mr-20'>
							<el-input v-model.trim="storeForm.traderName" :maxlength="30" placeholder="请输入商家名称"></el-input>
						</el-form-item>
						<el-form-item label='客户类型' class='mr-20'>
							<el-select v-model="storeForm.cType" placeholder="请选择客户类型" clearable>
								<el-option v-for="g in typeList" :label="g.label" :value="g.id" :key="g.id"></el-option>
							</el-select>
						</el-form-item>
					</el-form>
          <div>
            <el-button type='info' @click="getStoreBondList()" icon="el-icon-search">搜 索</el-button>
          </div>
					
				</div>
				<div class="clearfix mb-10 pr-10">
					<span v-if='showButton("SELLER_BOND_ADD_SELLER",140027)' class="fr color-blue ml-10 cursor" @click='addStore()'>添加商家</span>
					<span v-if='showButton("SELLER_BOND_SET",140027)' class="fr color-blue ml-10 cursor" @click='setBond()'>批量设置保证金</span>
				</div>
				<!-- 列表区域 -->
				<div class="pb-30">
					<el-table :data="storeBondList" @selection-change="handleStoreSelectionChange" class='table-default'>
						<el-table-column type="selection" width="40"></el-table-column>
						<el-table-column label='商家名称' width="">
							<template slot-scope="scope">
								<span>{{scope.row.traderInfo.storeName}}</span>
							</template>
						</el-table-column>
						<el-table-column label='商家账号' width="">
							<template slot-scope="scope">
								<span>{{scope.row.traderInfo.loginName}}</span>
							</template>
						</el-table-column>
						<el-table-column label='客户类型' width="">
							<template slot-scope="scope">
								<span v-if="scope.row.cType==1">经销商</span>
								<span v-if="scope.row.cType==2">终端</span>
								<span v-if="scope.row.cType==6">酒店</span>
								<span v-if="scope.row.cType==3">久加久·酒柜</span>
								<span v-if="scope.row.cType==4">久加久·门店</span>
								<span v-if="scope.row.cType==5">久加久·店中店</span>
							</template>
						</el-table-column>
						<el-table-column label='保证金' width="">
							<template slot-scope="scope">
								<span v-if="scope.row.depositInfo">{{scope.row.depositInfo.deposit}}</span>
								<span v-else>0</span>
							</template>
						</el-table-column>
						<el-table-column label='操作' width="">
							<template slot-scope="scope">
								<div>
									<el-button v-if='showButton("SELLER_BOND_SET",140027)' type='text' @click="setBond(scope)">设置保证金</el-button>
								</div>
								<div>
									<el-button type='text' @click="bondDetail(scope)">保证金详情</el-button>
								</div>
							</template>
						</el-table-column>
					</el-table>
					<el-pagination class="tar mt-20" v-if="storeBondList.length>0" :current-page="storeBondPage" :page-size='10' :total='storeBondTotal' layout="prev , pager, next, jumper" @current-change="handleStoreBondChange">
						<!-- 分页组件 -->
					</el-pagination>
				</div>
			</div>
		</div>
		<!-- 保证金充值、提现 -->
		<div class="bg-f mt-5 pb-50" v-show="typeName=='保证金充值'||typeName=='保证金提现'">
			<div class="content bg-f pl-30 pr-30 pb-50">
				<!-- 状态切换区域 -->
				<div class="tab_box">
					<div class="tab_item" :class="{tab_item_checked: item.type==state}" v-for="(item,index) in tabList" :key="index" @click="checkState(item)">
						<span>{{item.label}}</span>
						<span v-if="item.bage>0">({{item.bage}})</span>
						<span v-else>(0)</span>
					</div>
				</div>
				<!-- 搜索区域 -->
				<div class="clearfix search">
					<el-form :inline='true' class="pt-30 fl width-70p" :model="bondForm" ref="searchForm">
						<el-form-item label='商家账号'>
							<el-input v-model.trim="bondForm.traderAccount" :maxlength="30" placeholder="请输入商家账号"></el-input>
						</el-form-item>
						<el-form-item label='商家名称' class='ml-20'>
							<el-input v-model.trim="bondForm.traderName" :maxlength="30" placeholder="请输入商家名称"></el-input>
						</el-form-item>
						<el-form-item label='提交时间'>
							<ys-daterange-picker v-model="bondForm.orderDateRange" type="datetime" :endDisabledDate="new Date()" />
						</el-form-item>
					</el-form>
					<el-button class="fl ml-30 mt-30" type='info' @click="handleSearch()" icon="el-icon-search">搜 索</el-button>
				</div>
				<div class="clearfix mb-10 pr-10">
					<span v-if="state=='2'" class="fr color-blue ml-10 cursor" @click='review(1)'>驳回审核</span>
					<span v-if="state=='2'" class="fr color-blue ml-10 cursor" @click='review(2)'>审核通过</span>
					<span v-if="type=='2'" class="fr color-blue ml-10 cursor" @click='handDown()'>导出提现</span>
				</div>
				<!-- 列表区域 -->
				<div class=" tab_list" v-show="typeName=='保证金充值'">
					<!-- 充值 -->
					<el-table :data="BondList" @selection-change="handleSelectionChange" class='table-default'>
						<el-table-column type="selection" min-width="40"></el-table-column>
						<el-table-column label='商家账号' min-width="100">
							<template slot-scope="scope">
								<span>{{scope.row.traderAccount}}</span>
							</template>
						</el-table-column>
						<el-table-column label='商家名称' min-width="100">
							<template slot-scope="scope">
								<span>{{scope.row.traderName}}</span>
							</template>
						</el-table-column>
						<el-table-column label='充值金额（元）' min-width="100">
							<template slot-scope="scope">
								<span>{{scope.row.amount}}</span>
							</template>
						</el-table-column>
						<el-table-column label='汇款方式' min-width="130">
							<template slot-scope="scope">
								<p>{{scope.row.accountBank}}</p>
								<p>{{scope.row.bankNo}}</p>
							</template>
						</el-table-column>
						<el-table-column label='汇款凭证' min-width="60">
							<template slot-scope="scope">
								<el-button type="text" @click="showImg(scope)">查看凭证</el-button>
							</template>
						</el-table-column>
						<el-table-column label='提交时间' min-width="80">
							<template slot-scope="scope">
								<span>{{scope.row.createTime | time}}</span>
							</template>
						</el-table-column>
						<el-table-column label='全部状态' min-width="100">
							<template slot-scope="scope">
								<span v-if="scope.row.auditState=='pending'">待审核</span>
								<div v-if="scope.row.auditState=='fail'">
									<div>审核失败</div>
									<el-tooltip class="item" effect="dark" :content="scope.row.note" placement="left-start">
										<span class="hover_t">原因</span>
									</el-tooltip>
								</div>
								<span v-if="scope.row.auditState=='pass'">审核通过</span>
							</template>
						</el-table-column>
						<el-table-column label='审核人员' min-width="100">
							<template slot-scope="scope">
								<span>{{scope.row.auditTraderName}}</span>
							</template>
						</el-table-column>
						<el-table-column label='操作' min-width="100">
							<template slot-scope="scope">
								<el-button v-if="scope.row.auditState=='pending' && showButton('BOND_RECHARGE_AUDIT',140027)" type="text" @click="deposit(scope)">审核</el-button>
								<span v-else>-</span>
							</template>
						</el-table-column>
					</el-table>
					<el-pagination class="tar mt-20" v-if="BondList.length>0" :current-page="currentPage" :page-size='10' :total='total' layout="prev , pager, next, jumper" @current-change="handleCurrentChange">
						<!-- 分页组件 -->
					</el-pagination>
				</div>
				<div class="tab_list" v-show="typeName=='保证金提现'">
					<!-- 提现 -->
					<el-table :data="BondList" @selection-change="handleSelectionChange" class='table-default'>
						<el-table-column type="selection" min-width="40"></el-table-column>
						<el-table-column label='商家账号' min-width="90">
							<template slot-scope="scope">
								<span>{{scope.row.traderAccount}}</span>
							</template>
						</el-table-column>
						<el-table-column label='商家名称' min-width="100">
							<template slot-scope="scope">
								<span>{{scope.row.traderName}}</span>
							</template>
						</el-table-column>
						<el-table-column label='提现金额（元）' min-width="100">
							<template slot-scope="scope">
								<span>{{scope.row.amount}}</span>
							</template>
						</el-table-column>
						<el-table-column label='提现方式' min-width="120">
							<template slot-scope="scope">
								<p>{{scope.row.accountBank}}</p>
								<p>{{scope.row.bankNo}}</p>
							</template>
						</el-table-column>
						<el-table-column label='提交时间' min-width="120">
							<template slot-scope="scope">
								<span>{{scope.row.createTime | time}}</span>
							</template>
						</el-table-column>
						<el-table-column label='全部状态' min-width="100">
							<template slot-scope="scope">
								<span v-if="scope.row.auditState=='pending'">待审核</span>
								<div v-if="scope.row.auditState=='fail'">
									<div>审核失败</div>
									<el-tooltip class="item" effect="dark" :content="scope.row.note" placement="left-start">
										<span class="hover_t">原因</span>
									</el-tooltip>
								</div>
								<span v-if="scope.row.auditState=='pass'">审核通过</span>
							</template>
						</el-table-column>
						<el-table-column label='审核人员' min-width="100">
							<template slot-scope="scope">
								<span>{{scope.row.auditTraderName}}</span>
							</template>
						</el-table-column>
						<el-table-column label='操作' min-width="100">
							<template slot-scope="scope">
								<el-button v-if="scope.row.auditState=='pending' && showButton('BOND_WITHDRAW_AUDIT',140027)" type="text" @click="deposit(scope)">审核</el-button>
								<span v-else>-</span>
							</template>
						</el-table-column>
					</el-table>
					<el-pagination class="tar mt-20" v-if="BondList.length>0" :current-page="currentPage" :page-size='10' :total='total' layout="prev , pager, next, jumper" @current-change="handleCurrentChange">
						<!-- 分页组件 -->
					</el-pagination>
				</div>
			</div>
		</div>
		<!-- 驳回审核弹窗 -->
		<el-dialog class='cancelDialog' title='驳回理由' :visible.sync='cancelVisible'>
			<el-form class="pl-30 pr-30" :model="form" ref="cancelForm" :rules="rules2">
				<el-form-item label="" prop='reason'>
					<el-input type='textarea' placeholder='请输入驳回的理由...' :autosize="{ minRows: 3 ,maxRows: 10}" resize="none" v-model.trim='form.reason'></el-input>
				</el-form-item>
			</el-form>
			<div slot='footer' class="dialog-footer">
				<el-button type='danger' @click="handleCommit()">确 定</el-button>
				<el-button @click="cancelVisible = false">取 消</el-button>
			</div>
		</el-dialog>
		<!-- 审核弹窗 -->
		<el-dialog class='cancelDialog' title='审核' :visible.sync='showVisible'>
			<div class="mb-20 checkRadio">
				<el-radio-group v-model="checkRadio">
					<el-radio :label="1">通过</el-radio>
					<el-radio :label="2">驳回</el-radio>
				</el-radio-group>
			</div>
			<el-form v-if="checkRadio==2" class="pl-30 pr-30" :model="form" ref="cancelForm" :rules="rules2">
				<el-form-item label="" prop='reason'>
					<el-input type='textarea' placeholder='请输入驳回的理由...' :autosize="{ minRows: 3 ,maxRows: 10}" resize="none" v-model.trim='form.reason'></el-input>
				</el-form-item>
			</el-form>
			<div slot='footer' class="dialog-footer">
				<el-button type='danger' @click="handleCommit2()">确 定</el-button>
				<el-button @click="showVisible = false">取 消</el-button>
			</div>
		</el-dialog>
		<!-- 设置保证金 -->
		<el-dialog class='bondDialog' title='商家保证金设置' :visible.sync='setBondVisible'>
			<el-form class="pl-30 pr-30" :model="form" ref="setForm" :rules="rules2">
				<el-form-item label="保证金" prop='amount'>
					<el-input placeholder='请输入保证金金额' v-model.trim='form.amount'></el-input>
				</el-form-item>
			</el-form>
			<div slot='footer' class="dialog-footer">
				<el-button type='danger' @click="handleAddBondCommit()">确 定</el-button>
				<el-button @click="setBondVisible = false">取 消</el-button>
			</div>
		</el-dialog>
		<!-- 保证金详情 -->
		<el-dialog class='bondDetailDialog' title='保证金操作详情' :visible.sync='bondDetailVisible'>
			<div class="pl-20 pr-20">
				<el-table :data="bondDetailList" class='table-default'>
					<el-table-column label='设置时间' width="">
						<template slot-scope="scope">
							<span>{{scope.row.gmtCreate| time}}</span>
						</template>
					</el-table-column>
					<el-table-column label='操作人员' width="">
						<template slot-scope="scope">
							<span>{{scope.row.sellerName}}</span>
						</template>
					</el-table-column>
					<el-table-column label='操作内容' width="">
						<template slot-scope="scope">
							<span>{{scope.row.content}}</span>
						</template>
					</el-table-column>
				</el-table>
			</div>
		</el-dialog>
		<!-- 添加商户弹窗 -->
		<el-dialog class='addStoreDialog' title='选择商家' :visible.sync='addStoreVisible'>
			<div class="content pl-30 pr-30">
				<!-- 搜索区域 -->
				<div class="clearfix search">
					<el-form :inline='true' class="fl width-70p" :model="storeForm" ref="searchForm">
						<el-form-item label='商家账号'>
							<el-input size="mini" v-model.trim="storeForm.loginName" :maxlength="30" placeholder="商家账号"></el-input>
						</el-form-item>
						<el-form-item label='商家名称' class='ml-10'>
							<el-input size="mini" v-model.trim="storeForm.traderName" :maxlength="30" placeholder="商家名称"></el-input>
						</el-form-item>
						<el-form-item label='客户类型' class='ml-10'>
							<el-select size="mini" v-model="storeForm.cType" placeholder="客户类型" clearable>
								<el-option v-for="g in typeList" :label="g.label" :value="g.id" :key="g.id"></el-option>
							</el-select>
						</el-form-item>
					</el-form>
					<el-button class="fl" type='info' @click="getStoreList()" icon="el-icon-search">搜 索</el-button>
				</div>
				<!-- 列表区域 -->
				<div class="pb-30">
					<el-table :data="storeList" @selection-change="handleStoreSChange" class='table-default'>
						<el-table-column type="selection" width="40"></el-table-column>
						<el-table-column label='商家名称' width="">
							<template slot-scope="scope">
								<span>{{scope.row.traderInfo.storeName}}</span>
							</template>
						</el-table-column>
						<el-table-column label='商家账号' width="">
							<template slot-scope="scope">
								<span>{{scope.row.traderInfo.loginName}}</span>
							</template>
						</el-table-column>
						<el-table-column label='客户类型' width="">
							<template slot-scope="scope">
								<span v-if="scope.row.cType==1">经销商</span>
								<span v-if="scope.row.cType==2">终端</span>
								<span v-if="scope.row.cType==6">酒店</span>
								<span v-if="scope.row.cType==3">久加久·酒柜</span>
								<span v-if="scope.row.cType==4">久加久·门店</span>
								<span v-if="scope.row.cType==5">久加久·店中店</span>
							</template>
						</el-table-column>
					</el-table>
					<el-pagination class="tar mt-15" v-if="storeList.length>0" :current-page="storePage" :page-size='5' :total='storeTotal' layout="prev , pager, next, jumper" @current-change="handleStoreChange">
						<!-- 分页组件 -->
					</el-pagination>
				</div>
			</div>
			<div slot='footer' class="dialog-footer">
				<el-button type='danger' @click="handleAddStoreCommit()">确 定</el-button>
				<el-button @click="addStoreVisible = false">取 消</el-button>
			</div>
		</el-dialog>
		<!-- 查看凭证 -->
		<imgview :visible="imgsvisible" :imgs="showimgs" :startindex="startindex" @close="imgsvisible=false"></imgview>

    <!-- 提现导出 -->
    <form
      ref="downClientForm"
      action="/sunrise-gateway/export/groupon/depositRecordWithdraw/select"
      style="display: none"
    >
      <input type="hidden" name="modelName" v-model="a" />
      <input type="hidden" name="fileName" v-model="b" />
      <input type="hidden" name="startRow" v-model="d" />
      <input type="hidden" name="auditTraderId" v-model="auditTraderId" />
      <input type="hidden" name="ids" v-model="ids" />
      <input type="hidden" name="startDate" v-model="startDate" />
      <input type="hidden" name="endDate" v-model="endDate" />
    </form>
  </div>
</template>

<script>
import axios from "axios";
import { axiosApi, basePath, baseURI } from "../../api/api";
import YsDaterangePicker from "~/components/ys-daterange-picker";
export default {
  components: { YsDaterangePicker },
  data() {
    return {
      /*规则*/
      rules2: {
        reason: [
          {
            required: true,
            message: "驳回理由不能为空"
          },
          {
            min: 4,
            max: 100,
            message: "驳回理由为4-100位字符"
          }
        ],
        amount: [
          {
            required: true,
            message: "保证金不能为空"
          },
          {
            pattern: /^(?!0\d|[0.]+$|0$)\d{0,6}(\.\d{0,2})?$/,
            message: "仅支持小数点前6位,小数点后2位的非0数字"
          }
        ]
      },
      dataTabs: ["商家保证金", "保证金充值", "保证金提现"],
      tabList: [
        {
          label: "全部",
          type: 0,
          bage: 0
        },
        {
          label: "已通过",
          type: 1,
          bage: 0
        },
        {
          label: "待审核",
          type: 2,
          bage: 0
        },
        {
          label: "未通过",
          type: 3,
          bage: 0
        }
      ],
      type: "0", //头部状态
      state: 0, //列表状态
      bondForm: {
        //保证金
        source: null,
        auditState: null, //pending待审核pass通过fail
        traderName: null, //商家名称
        traderAccount: null, //商家账号
        type: null, //in充值out提现
        orderDateRange: [], //startTime下单开始时间 endTime下单结束时间
        startTime: null,
        endTime: null
      },
      currentPage: 1, //保证金分页
      total: 0, //保证金分页
      BondList: [], //保证金列表
      ids: [], //审核数据
      cancelVisible: false, //驳回审核
      checkRadio: 1,
      showVisible: false, //审核弹窗
      form: {
        reason: null, //驳回理由
        amount: null //保证金
      },
      typeList: [
        {
          id: 1,
          label: "经销商"
        },
        {
          id: 2,
          label: "终端"
        },
        {
          id: 6,
          label: "酒店"
        },
        {
          id: 3,
          label: "久加久·酒柜"
        },
        {
          id: 4,
          label: "久加久·门店"
        },
        {
          id: 5,
          label: "久加久·店中店"
        }
      ], //终端类型
      storeForm: {
        traderName: null, //卖家名称
        loginName: null, //卖家账号
        cType: null //1：经销商 2：终端 3：酒店
      },
      storeBondList: [], //商家保证金列表
      storeBondPage: 1, //分页
      storeBondTotal: 0, //分页
      storeList: [], //商家列表
      storePage: 1, //分页
      storeTotal: 0, //分页
      addStoreVisible: false, //添加商户弹窗
      setBondVisible: false, //保证金设置弹窗
      bondDetailVisible: false, //保证金操作详情弹窗
      checkedStore: [], //选择的商户
      checkedStoreList: [], //选择的已有商户
      bondDetailList: [], //保证金详情
      imgsvisible: false, //身份证单个图片查看
      showimgs: [],
      startindex: 0,

      a: "empty",
      b: "提现保证金清单.xls",
      d: 0,
      auditTraderId: sessionStorage.traderId,
      startDate: "",
      endDate: "",
      typeName: "" //选中的text
    };
  },
  watch: {
    state: function(val, oldVal) {
      switch (val) {
        case 0:
          this.bondForm.auditState = null;
          break;
        case 1:
          this.bondForm.auditState = "pass";
          break;
        case 2:
          this.bondForm.auditState = "pending";
          break;
        case 3:
          this.bondForm.auditState = "fail";
          break;
      }
      this.getBondList();
    }
  },
  //进入加载
  activated() {
    this.$nextTick(function() {
      this.dataTabs = [];
      this.showButton("SELLER_BOND", 140027) &&
        this.dataTabs.push("商家保证金");
      this.showButton("BOND_RECHARGE", 140027) &&
        this.dataTabs.push("保证金充值");
      this.showButton("BOND_WITHDRAW", 140027) &&
        this.dataTabs.push("保证金提现");
      if (this.dataTabs[0] == "商家保证金") {
        this.type = 0;
        this.typeName = "商家保证金";
        this.getStoreBondList();
      } else if (this.dataTabs[0] == "保证金充值") {
        this.type = 0;
        this.typeName = "保证金充值";
        this.state = 0;
        this.bondForm.source = "recharge";
        this.bondForm.type = "in";
        this.getBondList();
        this.getBage();
      } else {
        this.type = 0;
        this.typeName = "保证金提现";
        this.state = 0;
        this.bondForm.source = "withdraw";
        this.bondForm.type = "out";
        this.getBondList();
        this.getBage();
      }
    });
  },
  methods: {
    search() {
      if (this.storeBondPage == 1) {
        this.getStoreBondList();
      } else {
        this.storeBondPage = 1;
      }
    },
    getBage() {
      //获取保证金角标
      axiosApi(
        "/groupon/depositRecord/count",
        {
          auditTraderId: sessionStorage.traderId,
          source: this.bondForm.source
        },
        res => {
          console.log(res, "角标信息");
          this.tabList.forEach(item => {
            if (item.type == 0) {
              item.bage = res.data.data.totalCount;
            } else if (item.type == 1) {
              item.bage = res.data.data.passCount;
            } else if (item.type == 2) {
              item.bage = res.data.data.pendingCount;
            } else if (item.type == 3) {
              item.bage = res.data.data.failCount;
            }
          });
          console.log(this.tabList,713)
        },
        1,
        10
      );
    },
    getBondList() {
      this.bondForm.startTime = this.bondForm.orderDateRange[0]
        ? this.bondForm.orderDateRange[0].getTime()
        : null;
      this.bondForm.endTime = this.bondForm.orderDateRange[1]
        ? this.bondForm.orderDateRange[1].getTime()
        : null;
      axiosApi(
        "/groupon/deposit/list",
        {
          type: this.bondForm.type,
          auditTraderId: sessionStorage.traderId,
          source: this.bondForm.source,
          auditState: this.bondForm.auditState,
          traderName: this.bondForm.traderName,
          traderAccount: this.bondForm.traderAccount,
          startTime: this.bondForm.startTime,
          endTime: this.bondForm.endTime
        },
        res => {
          console.log("保证金记录", res);
          this.BondList = res.data.data.elements ? res.data.data.elements : [];
          this.total = res.data.data.totalCount;
        },
        this.currentPage,
        10,
        res => {}
      );
    },
    handleCurrentChange(val) {
      //保证金分页
      this.currentPage = val;
      this.getBondList();
    },
    handleTabs(e) {
      //头部切换状态
      // if(e.target.type) this.type = e.target.type // 改变 type的值
      this.bondForm.traderName = null; //商家名称
      this.bondForm.traderAccount = null; //商家账号
      this.bondForm.orderDateRange = []; //startTime下单开始时间 endTime下单结束时间

      if (e.target.attributes.type) {
        this.type = e.target.attributes.type.value;
        this.typeName = e.target.attributes.type.ownerElement.innerText.trim();
      }
      if (this.typeName == "商家保证金") {
        this.getStoreBondList();
      } else if (this.typeName == "保证金充值") {
        this.state = 0;
        this.bondForm.source = "recharge";
        this.bondForm.type = "in";
        this.getBondList();
        this.getBage();
      } else if (this.typeName == "保证金提现") {
        this.state = 0;
        this.bondForm.source = "withdraw";
        this.bondForm.type = "out";
        this.getBondList();
        this.getBage();
      }
    },
    checkState(val) {
      //切换状态
      console.log(val, "状态数据");
      this.state = val.type;
    },
    handleSearch() {
      if (this.currentPage == 1) {
        this.getBondList();
      } else {
        this.currentPage = 1;
      }
      //搜索
      this.getBondList();
    },
    deposit(scope) {
      //审核
      this.showVisible = true;
      this.form.reason = null;
      this.ids = [];
      this.ids.push(scope.row.id);
    },
    handleCommit2() {
      //审核
      if (this.checkRadio == 2 && this.form.reason == null) {
        this.$message({
          type: "info",
          message: "请输入驳回理由",
          duration: 1000
        });
      } else {
        if (this.type == 1) {
          //充值审核
          axiosApi(
            "/groupon/deposit/rechargeAudit",
            {
              idList: this.ids,
              state: this.checkRadio == 1 ? "pass" : "fail",
              note: this.form.reason
            },
            res => {
              console.log("审核结果", res);
              this.getBondList();
              this.getBage();
              this.showVisible = false;
              this.$message({
                type: "success",
                message: "审核成功",
                duration: 1000
              });
            }
          );
        } else if (this.type == 2) {
          //提现审核
          axiosApi(
            "/groupon/deposit/withdrawAudit",
            {
              idList: this.ids,
              state: this.checkRadio == 1 ? "pass" : "fail",
              note: this.form.reason
            },
            res => {
              console.log("审核结果", res);
              this.getBondList();
              this.getBage();
              this.showVisible = false;
              this.$message({
                type: "success",
                message: "审核成功",
                duration: 1000
              });
            }
          );
        }
      }
    },
    handleSelectionChange(val) {
      //全选
      this.ids = [];
      val.forEach(item => {
        this.ids.push(item.id);
      });
    },
    review(val) {
      //批量审核
      if (val == 2) {
        //通过
        if (this.ids.length > 0) {
          if (this.type == 1) {
            //充值审核
            axiosApi(
              "/groupon/deposit/rechargeAudit",
              {
                idList: this.ids,
                state: "pass"
              },
              res => {
                console.log("审核结果", res);
                this.getBage();
                this.getBondList();
                this.$message({
                  type: "success",
                  message: "审核成功",
                  duration: 1000
                });
              }
            );
          } else if (this.type == 2) {
            //提现审核
            axiosApi(
              "/groupon/deposit/withdrawAudit",
              {
                idList: this.ids,
                state: "pass"
              },
              res => {
                console.log("审核结果", res);
                this.getBage();
                this.getBondList();
                this.$message({
                  type: "success",
                  message: "审核成功",
                  duration: 1000
                });
              }
            );
          }
        } else {
          this.$message({
            type: "info",
            message: "请先选择需要审核的人员",
            duration: 1000
          });
        }
      } else if (val == 1) {
        if (this.ids.length > 0) {
          this.cancelVisible = true;
        } else {
          this.$message({
            type: "info",
            message: "请先选择需要审核的人员",
            duration: 1000
          });
        }
      }
    },
    handleCommit() {
      //驳回审核
      this.$refs.cancelForm.validate(valid => {
        if (valid) {
          this.cancelVisible = false;
          if (this.type == 1) {
            //充值审核
            axiosApi(
              "/groupon/deposit/rechargeAudit",
              {
                idList: this.ids,
                state: "fail",
                note: this.form.reason
              },
              res => {
                console.log("审核结果", res);
                this.getBondList();
                this.getBage();
                this.$message({
                  type: "success",
                  message: "驳回审核成功",
                  duration: 1000
                });
              }
            );
          } else if (this.type == 2) {
            //提现审核
            axiosApi(
              "/groupon/deposit/withdrawAudit",
              {
                idList: this.ids,
                state: "fail",
                note: this.form.reason
              },
              res => {
                console.log("审核结果", res);
                this.getBage();
                this.getBondList();
                this.$message({
                  type: "success",
                  message: "驳回审核成功",
                  duration: 1000
                });
              }
            );
          }
        } else {
          return false;
        }
      });
    },
    getStoreBondList() {
      //商家保证金列表
      axiosApi(
        "/groupon/sellerDeposit/select",
        {
          type: "in",
          cType: this.storeForm.cType,
          traderName: this.storeForm.traderName,
          loginName: this.storeForm.loginName,
          traderId: sessionStorage.traderId
        },
        res => {
          console.log("商家保证金记录", res);
          this.storeBondList = res.data.data.elements
            ? res.data.data.elements
            : [];
          this.storeBondTotal = res.data.data.totalCount;
        },
        this.storeBondPage,
        10,
        res => {}
      );
    },
    getStoreList() {
      //商家列表
      axiosApi(
        "/groupon/sellerDeposit/select",
        {
          type: "notin",
          cType: this.storeForm.cType,
          traderName: this.storeForm.traderName,
          loginName: this.storeForm.loginName,
          traderId: sessionStorage.traderId
        },
        res => {
          console.log("商家列表", res);
          this.storeList = res.data.data.elements ? res.data.data.elements : [];
          this.storeTotal = res.data.data.totalCount;
        },
        this.storePage,
        5,
        res => {}
      );
    },
    handleStoreBondChange(val) {
      //商家分页
      this.storeBondPage = val;
      this.getStoreBondList();
    },
    handleStoreChange(val) {
      //商家列表分页
      this.storePage = val;
      this.getStoreList();
    },
    handleStoreSelectionChange(val) {
      //商家保证金全选
      this.checkedStore = val;
    },
    handleStoreSChange(val) {
      //商家全选
      this.checkedStoreList = val;
    },
    setBond(scope) {
      //设置保证金
      this.form.amount = null;
      if (scope) {
        this.checkedStore = [];
        this.checkedStore.push(scope.row);
        this.setBondVisible = true;
      } else if (this.checkedStore.length > 0) {
        this.setBondVisible = true;
      } else {
        this.$message({
          type: "info",
          message: "请先选择数据",
          duration: 1000
        });
      }
    },
    getBondDetail(traderId) {
      axiosApi(
        "/groupon/sellerDepositRecord/select",
        {
          sellerId: traderId
        },
        res => {
          console.log("保证金详情", res);
          this.bondDetailList = res.data.data ? res.data.data : [];
        }
      );
    },
    bondDetail(scope) {
      //保证金详情
      this.bondDetailVisible = true;
      this.getBondDetail(scope.row.traderId);
    },
    addStore() {
      //添加商户弹窗
      this.addStoreVisible = true;
      (this.storeForm.traderName = null), //卖家名称
        (this.storeForm.loginName = null), //卖家账号
        (this.storeForm.cType = null), //1：经销商 2：终端 3：酒店
        this.getStoreList();
    },
    handleAddStoreCommit() {
      //添加商户
      const body = {
        depositList: []
      };
      this.checkedStoreList.forEach(item => {
        body.depositList.push({
          id: item.traderInfo.id,
          amount: 0,
          state: "enable"
        });
      });
      axiosApi("/groupon/sellerDeposit/add", body, res => {
        this.addStoreVisible = false;
        console.log(res, "添加商户");
        this.getStoreBondList();
      });
    },
    handleAddBondCommit() {
      //设置保证金
      const body = {
        depositList: []
      };
      this.checkedStore.forEach(item => {
        body.depositList.push({
          id: item.traderInfo.id,
          state: "enable",
          amount: this.form.amount
        });
      });
      this.$refs.setForm.validate(valid => {
        if (valid) {
          axiosApi("/groupon/sellerDeposit/add", body, res => {
            this.setBondVisible = false;
            this.$message({
              type: "success",
              message: "保证金设置成功",
              duration: 1000
            });
            this.getStoreBondList();
          });
        }
      });
    },
    showImg(scope) {
      //查看保证金图片
      if (JSON.parse(scope.row.voucherImg).length > 0) {
        this.showimgs = JSON.parse(scope.row.voucherImg);
        this.startindex = 0;
        this.imgsvisible = true;
      } else {
        this.$message({
          type: "info",
          message: "无凭证图片",
          duration: 1000
        });
      }
    },
    handDown() {
      //导出
      this.startTime = this.bondForm.orderDateRange[0]
        ? this.bondForm.orderDateRange[0].getTime()
        : null;
      this.endTime = this.bondForm.orderDateRange[1]
        ? this.bondForm.orderDateRange[1].getTime()
        : null;
      if (this.BondList.length > 0) {
        this.$refs.downClientForm.submit();
      } else {
        this.$message({
          type: "info",
          message: "暂无数据可以导出",
          duration: 1000
        });
      }
    }
  }
};
</script>

<style lang="less">
	#bondManager {
		.tab_box {
			height: 60px;
			line-height: 60px;
			box-sizing: border-box;
			border-bottom: 1px solid #eee;
			.tab_item {
				display: inline-block;
				width: 114px;
				height: 26px;
				line-height: 26px;
				text-align: center;
				box-sizing: border-box;
				color: #666;
				font-size: 14px;
				cursor: pointer;
			}
			.tab_item:hover {
				color: #FF4201;
			}
			.tab_item_checked {
				border-radius: 2px;
				border: 1px solid #FF4201;
				color: #FF4201;
			}
		}
		.tab_list {
			.el-table {
				text-align: center;
			}
			.el-button {
				text-align: center;
				width: auto;
			}
		}
		.el-dialog {
			min-height: 300px;
		}
		.bondDetailDialog {
			.el-dialog {
				min-height: 500px;
			}
		}
		.bondDialog {
			.el-form-item__label {
				width: 110px;
			}
			.el-form-item__error {
				left: 110px;
			}
		}
		.addStoreDialog {
			.el-dialog {
				width: 800px;
				min-height: 500px;
				.content .el-form--inline {
					width: 570px;
				}
			}
		}
		.checkRadio {
			text-align: center;
		}
		.hover_t {
			color: #FF4201;
			&:hover {
				cursor: pointer;
			}
		}
	}
</style>