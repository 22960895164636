<template>
    <div class="top-nav">
        <ul>
            <li :class="{ 'active': (asideNav.id == item.id && !($route.path.indexOf('/messageCenter')!=-1 || $route.path.indexOf('/messageDetail')!=-1) )|| (asideNav.menuClassify && item.id == '999999' && !($route.path.indexOf('/messageCenter')!=-1 || $route.path.indexOf('/messageDetail')!=-1))}" v-for="(item,index) in topNav" :key="index" @click="changeNavMenu(item)">{{item.text}}</li>
        </ul>
    </div>
</template>

<script>
    import { mapState , mapMutations ,mapActions } from 'vuex'
    export default {
        name: "topNav",
        computed:{
            ...mapState('nav',['topNav','asideNav','tagNav'])
        },
        methods:{
            ...mapMutations('nav',['changeAsideNav','changeTagNav','changNowNav','addUserNav']),
            ...mapActions('nav',['getMenuList']),
            changeNavMenu(val){
                if (val.url) {
                    this.$router.push({
                        path: val.url
                    })
                    this.changNowNav(val)
                }else{
                    if (val.sub[0] && val.sub[0].url) {
                        let arr = this.$deepClone(this.tagNav)
                        arr.push(val.sub[0])
                        this.changNowNav(val.sub[0])
                        this.addUserNav(val.sub[0].id)
                        this.changeTagNav(arr)
                        this.$router.push({
                            path: val.sub[0].url
                        })
                    }else if (val.sub[0].sub.length>0 && val.sub[0].sub[0].url) {
                        let arr = this.$deepClone(this.tagNav)
                        arr.push(val.sub[0].sub[0])
                        this.changeTagNav(arr)
                        this.changNowNav(val.sub[0].sub[0])
                        this.addUserNav(val.sub[0].sub[0].id)
                        this.$router.push({
                            path: val.sub[0].sub[0].url
                        })
                    }

                }
                this.changeAsideNav(val)
            }
        },
        created() {
            this.getMenuList()
        }
    }
</script>

<style lang="less" scoped>
    .top-nav{
        flex: 1;
        width: 0;
        ul{
            display: flex;
            li{
                cursor: pointer;
                width: 81px;
                height: 36px;
                line-height: 36px;
                text-align: center;
                color: #FB6E48;
                border-radius:8px;
                &.active{
                    color: #ffffff;
                    background-color: #FB6E48;
                }
            }
        }
    }
</style>