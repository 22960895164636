import Steward from '~/views/catering/steward.vue' //服务员管理
import foodManage from '~/views/catering/foodManage.vue'  // 点餐 菜品管理
import drinksManage from '~/views/catering/drinksManage.vue'  // 点餐 菜品管理
import selfView from '~/views/catering/selfView.vue'  // 点餐 自营界面
import cabinetView from '~/views/catering/cabinetView.vue'  // 点餐 酒柜界面
import hotelManage from '~/views/catering/hotelManage.vue'  // 点餐 酒店管理
import foodTypeAdd from '~/views/catering/foodTypeAdd.vue'  // 点餐 添加菜品分类
import PsOrder from '~/views/catering/psOrder.vue'  // 订单/预约单
import PsOrderB from '~/views/catering/psOrderB.vue'  // 订单/预约单
import PsSet from '~/views/catering/psSet.vue'  // 设置
import specificationManage from '~/views/catering/specificationManage.vue'  // 规格管理
import vipManage from '~/views/catering/vipManage.vue'  // 会员管理
import foodAdd from '~/views/catering/foodAdd.vue'  // 菜品添加
import psOrderDetails from '~/views/catering/psOrderDetails.vue'  // 订单详情
import classManage from '~/views/catering/classManage.vue'  // 类别维护
import tableSet from '~/views/catering/tableSet.vue'  // 类别维护
import addSelfDrinks from '~/views/catering/addSelfDrinks.vue'  // 会员管理 admin
import cardManage from '~/views/catering/cardManage.vue'  // 会员管理 admin
import goodsDetails from '~/views/catering/goodsDetails.vue'  // 商品详情

import {basePath} from '../api/api'

export const psFirstRouters = [];
export const psHomeRouters = [ //详情

];
export const psHomeLogoRouters = [
    {
        path: basePath + '/catering/foodAdd',  //菜品添加
        component: foodAdd,
        name:'foodAdd',
        text:'菜品添加'
    },
    {
        path: basePath + '/catering/goodsDetails',  //商品详情
        component: goodsDetails,
        name:'goodsDetails',
        text:'商品详情'
    }
];
export const psHomeMenuRouters = [ //list
    {
        path: basePath + '/catering/psOrderDetails',  //订单详情
        component: psOrderDetails,
        name:'psOrderDetails',
        text:'订单详情'
    },
    {
        path: basePath + '/catering/steward', //服务员管理 
        component: Steward,
        name:'steward',
        text:'服务员管理'
    },
    {
        path: basePath + '/catering/foodManage',  //点餐 菜品管理
        component: foodManage,
        name:'foodManage',
        text:'菜品管理'
    },
    {
        path: basePath + '/catering/drinksManage',  //点餐 酒水管理
        component: drinksManage,
        name:'drinksManage',
        text:'酒水管理'
    },
    {
        path: basePath + '/catering/selfView',  //点餐 自营界面
        component: selfView,
        name:'selfView',
        text:'自营界面'
    },
    {
        path: basePath + '/catering/cabinetView',  //点餐 酒柜界面
        component: cabinetView,
        name:'cabinetView',
        text:'酒柜界面'
    },
    {
        path: basePath + '/catering/hotelManage',  //点餐 酒店管理
        component: hotelManage,
        name:'hotelManage',
        text:'酒店管理'
    },
    {
        path: basePath + '/catering/foodTypeAdd',  //点餐 菜品添加
        component: foodTypeAdd,
        name:'foodTypeAdd',
        text:'菜品添加'
    },
    {
        path: basePath + '/catering/psOrder',  //订单/预约单
        component: PsOrder,
        name:'psOrder',
        text:'预约单'
    },
    {
        path: basePath + '/catering/psOrderB',  //订单/预约单
        component: PsOrderB,
        name:'psOrderB',
        text:'预约单B'
    },
    {
        path: basePath + '/catering/psSet',  //设置
        component: PsSet,
        name:'psSet',
        text:'设置'
    },
    {
        path: basePath + '/catering/specificationManage',  //规格管理
        component: specificationManage,
        name:'specificationManage',
        text:'规格管理'
    },
    {
        path: basePath + '/catering/vipManage',  //会员管理
        component: vipManage,
        name:'vipManage',
        text:'会员管理'
    },
    {
        path: basePath + '/catering/classManage',  //类别维护
        component: classManage,
        name:'classManage',
        text:'类别维护'
    },
    {
        path: basePath + '/catering/tableSet',  //类别维护
        component: tableSet,
        name:'tableSet',
        text:'类别维护'
    },
    {
        path: basePath + '/catering/addSelfDrinks',  //添加自营酒水
        component: addSelfDrinks,
        name:'addSelfDrinks',
        text:'添加自营酒水'
    },
    {
        path: basePath + '/catering/cardManage',  //卡卷维护
        component: cardManage,
        name:'cardManage',
        text:'卡卷维护'
    }


];
export const psCarLogoRouters = [];
