<template>
  <div id="salesInCarOrderDetail" class="bg-f">
    <div class="box-con bdt">
      <div class="order-info">
        <div class="order-info-tit pl-20">订单信息</div>
        <div class="order-info-con clearfix">
          <div class="order-info-conL fl">
            <div v-if="orderInfo.adjustingGoods == 1">
              <label>
                <el-badge class="my-badge" value="调货"></el-badge>
              </label>
              <span class="ml-10">{{orderInfo.headName}}</span>
            </div>
            <div>
              <label>订单编号：</label>
              <span class="ml-10">{{orderInfo.orderSn}}</span>
            </div>
            <div>
              <label>客户名称：</label>
              <span class="ml-10">{{orderInfo.buyerName}}</span>
            </div>
            <div>
              <label>下单时间：</label>
              <span class="ml-10">{{orderInfo.gmtCreate | time}}</span>
            </div>
            <div>
              <label>结算状态：</label>
              <span class="ml-10 color-danger" v-if="orderInfo.isSettlement==0">未结</span>
              <span class="ml-10" v-if="orderInfo.isSettlement==1">已结</span>
            </div>
            <div>
              <label>业务员：</label>
              <span class="ml-10">{{orderInfo.managerName}}{{orderInfo.managerLoginName}}</span>
            </div>
          </div>
          <div class="fr mt-30 mr-20">
            <el-button
              v-if="orderInfo.orderStatus == '2' && $route.query.tp == '1' && $route.query.type != '久加久酒水单'"
              @click="openCustomer('Form')"
            >发货</el-button>
            <span
              v-if="orderInfo.orderStatus==1 && orderInfo.remainAutoCancelTime && orderInfo.remainAutoCancelTime != 0"
            >
              <span class="mr-20">
                还剩
                <span class="color-danger">{{orderInfo.remainAutoCancelTime}}</span>自动取消
              </span>
              <el-button @click="handleCancelOrder()">取消订单</el-button>
            </span>
          </div>
        </div>
      </div>

      <el-dialog class="cancelOrderDialog" title="取消订单理由" :visible.sync="cancelOrderVisible">
        <el-form class="pl-30 pr-30" :model="form" ref="cancelOrderForm" :rules="rules2">
          <el-form-item label prop="cancleReason">
            <el-input
              type="textarea"
              placeholder="请输入取消订单的理由..."
              :autosize="{ minRows: 3 ,maxRows: 10}"
              v-model.trim="form.cancleReason"
            ></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="danger" @click="handleCommit(2)">确定</el-button>
          <el-button @click="cancelOrderVisible = false">取 消</el-button>
        </div>
      </el-dialog>

      <el-tabs class="mt-20" v-model="show">
        <el-tab-pane label="订单详情" name="first"></el-tab-pane>
        <el-tab-pane label="订单备注" name="second"></el-tab-pane>
      </el-tabs>

      <div v-if="show=='first'">
        <!--购买商品基本信息-->
        <el-table>
          <el-table-column label="商品信息" min-width="200" align="center"></el-table-column>
          <el-table-column label="单价" min-width="100" align="center"></el-table-column>
          <el-table-column label="数量" min-width="100" align="center"></el-table-column>
          <el-table-column label="合计" min-width="100" align="center"></el-table-column>
          <!-- <el-table-column label="操作" align="center" width="140"></el-table-column> -->
        </el-table>
        <div class="goods mt-20">
          <div class="table-item mt-10">
            <div class="table-item-content clearfix bbd" style="">
              <div class="clearfix" style="box-sizing: border-box;">
                <!--商品清单-->
                <div
                  class="bb clearfix"
                  v-for="(orderItem, index) in orderInfo.orderItemPos"
                  :key="index"
                  style="padding: 20px 0 20px 20px;display: flex"
                >
                  <div class="specialD clearfix" style="flex: 2;display: flex">
                    <img
                      v-if="orderItem.skuImage"
                      :src="orderItem.skuImage"
                      class="skuImg fl mr-20"
                    />
                    <img v-else src="../../assets/goods.jpg" class="skuImg fl mr-20" />
                    <div class="row-item-1">
                      <p class="bw">{{orderItem.skuName}}</p>
                      <p class="color-9">规格:1*{{orderItem.changeCoefficient}}</p>
                    </div>
                  </div>
                  <div class="fl tac" style="flex: 1">¥{{orderItem.discountSpecPrice | fmoney}}/{{orderItem.unit}}</div>
                  <div
                    class="fl tac"
                    style="flex: 1"
                  >{{orderItem.specNum}}{{orderItem.unit}}</div>
                  <div
                    class="fl tac"
                    style="flex: 1"
                  >¥{{orderItem.discountSpecPrice*orderItem.specNum | fmoney}}</div>
                </div>
                  <!--赠品清单-->
                  <div
                          class="bb clearfix"
                          v-for="(orderItem, index) in orderInfo.orderGiftResVos"
                          :key="orderItem.id"
                          style="padding: 20px 0 20px 20px;display: flex"
                  >
                      <div class="fl specialD" style="display: flex;flex: 2">
                          <img v-if="orderItem.skuImage" :src="orderItem.skuImage" class="fl skuImg mr-20" />
                          <img v-else src="../../assets/goods.jpg" class="fl skuImg mr-20" />
                          <img src="../../assets/superGroup/complimentary.png" class="giftLogo" alt="">
                          <div class="row-item-1">
                              <!-- <p class="bw">{{orderItem.skuNo}}</p> -->
                              <p class="bw">{{orderItem.skuName}}</p>
                              <p class="color-9">规格:1*{{orderItem.changeCoefficient}}</p>
                          </div>
                      </div>
                      <div
                              class="fl tac"
                              style="flex: 1"
                      >¥{{orderItem.discountSpecPrice | fmoney}}/{{orderItem.sellingUnitName}}</div>
                      <div
                              class="fl tac"
                              style="flex: 1"
                      >{{orderItem.giftNum}}{{orderItem.sellingUnitName}}</div>
                      <div
                              class="fl tac"
                              style="flex: 1"
                      >¥0.00</div>
                  </div>
              </div>
              <!-- <div class="bl bb tac myBtn" :style="{paddingTop : '15px'}" style="flex-grow: 1;">
                <div :style="{width : '175px'}" class="w100">
                  <el-button type="text" class="ml-0" @click="setRemark">订单备注</el-button>
                </div>
              </div> -->
            </div>
          </div>
          <div class="goods-money clearfix">
            <div class="goods-moneyR fr">
              <div>
                <label>
                  <!-- <span class="mr-20">共{{orderInfo.skuTotalNum}}件商品</span> -->
                  商品金额：
                </label>
                <span class="ml-10">¥{{orderInfo.goodsTotalMoney||0 | fmoney}}</span>
              </div>
              <div>
                <!-- <div>
                  <label>实付金额：</label>
                  <span class="ml-10">¥{{orderInfo.needPayMoney||0 | fmoney}}</span>
                </div>-->
                <div class="font-20 color-danger">
                  <label>应付金额：</label>
                  <span class="ml-10">￥{{orderInfo.needPayMoney | fmoney}}</span>
                </div>
              </div>
            </div>
          </div>
          <!--收货信息-->
          <div class="order-info mt-20">
            <div class="order-info-tit pl-20">收货信息</div>
            <div class="order-info-con">
              <div class="order-info-conL">
                <div>
                  <label>收货人：</label>
                  <span class="ml-10">{{orderInfo.addrUsername}}</span>
                </div>
                <div>
                  <label>联系电话：</label>
                  <span class="ml-10">{{orderInfo.addrTel}}</span>
                </div>
                <div>
                  <label>收货地址：</label>
                  <span class="ml-10">{{orderInfo.addrInfo}}</span>
                </div>
              </div>
            </div>
          </div>
          <!-- 支付信息 -->
          <div class="order-info mt-20">
            <div class="order-info-tit pl-20">支付信息</div>
            <div class="order-info-con">
              <div class="order-info-conL">
                <div class="clearfix">
                  <label class="fl">支付方式：</label>
                  <div class="fl" style="width: 75%">
                    <span
                      class="ml-10"
                      v-if="orderInfo.payType !=''"
                    >{{orderInfo.payType == 'cash'?'现金':'赊销'}}</span>
                    <span class="ml-10" v-else>无</span>
                  </div>
                </div>
                <!--<div class="clearfix" v-if="orderInfo.managerCommission.length>0">-->
                  <!--<label class="fl">业务员佣金：</label>-->
                  <!--<div class="fl" style="width: 75%">-->
                    <!--<span-->
                      <!--class="ml-10"-->
                      <!--v-for="item in orderInfo.managerCommission"-->
                      <!--:key="item.id"-->
                    <!--&gt;{{item.managerName}}&nbsp;¥{{item.managerCommission | fmoney}}</span>-->
                  <!--</div>-->
                <!--</div>-->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="show=='second'">
        <div v-if="orderInfo.remark != null">
          <!--物流信息-->
          <div class="order-info mt-20">
            <div class="order-info-tit pl-20">订单备注</div>
            <div class="order-info-con">
              <div class="order-info-conL">
                <div class="clearfix">
                  <label class="fl"></label>
                  <div class="fl" style="width: 75%">
                    <span class="ml-10" v-if="orderInfo.remark != null">{{orderInfo.remark}}</span>
                    <span class="ml-10" v-else>无</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="pt-50 pb-20 font-24 tac">暂无记录</div>
      </div>
    </div>
    <el-dialog class="minMop" title="订单备注" :visible.sync="customer">
      <el-form class="pr-10" :rules="remarkrules" ref="remarkForm" :model="remarkForm">
        <el-form-item label class="pl-20 pr-20" prop="remarkForm.remark">
          <el-input
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 4}"
            v-model="remarkForm.remark"
            placeholder="请输入备注"
            :maxlength="40"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="danger" @click="addCustomer('remarkForm')">确定</el-button>
        <el-button class="ml-20" @click="customer = false">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { axiosApi, basePath } from "../../api/api";
export default {
  components: {},
  data() {
    return {
      datas: [1, 2, 3],
      traceApiDtos: [],
      /*规则*/
      rules2: {
        cancleReason: [
          {
            required: true,
            message: "取消订单理由不能为空"
          },
          {
            min: 4,
            max: 100,
            message: "取消订单理由为4-100位字符"
          }
        ]
      },
      show: "first",
      orderInfo: {}, //订单信息
      cancelOrderVisible: false,
      isDeliver: false,
      sendGoods: false,
      expressList: [], //物流列表
      form: {
        cancleReason: "", //取消订单原因
        companyName: "", //物流公司
        expressNum: "" //物流编号
      },
      customer: false, //发货弹窗
      remarkForm: {
        orderSn: this.$route.query.sn,
        remark: null
      },
      remarkrules: {
        remark: [
          {
            required: true,
            message: "备注不能为空"
          },
          {
            min: 1,
            max: 100,
            message: "长度在 1 到 100 个字符",
            trigger: "blur"
          }
        ]
      },
      voucherTableList: [], // 授信凭证 list data
      imgviewVisible: false, // 查看凭证 浮层开关
      voucherImgList: [], // 查看凭证 图片list
      bankInfo: [],
      total: 0,
      currentPage: 1,
      getSellerId: sessionStorage.getItem("traderId"),
      parentOrderSn: this.$route.query.id,
      divideOrderVisible: false
    };
  },
  mounted() {
    this.initVoucherTable();
    this.$nextTick(() => {
      this.getOrderInfo();
      if (sessionStorage.show) {
        this.show = sessionStorage.show;
      }
    });
  },
  methods: {
    setRemark() {
      this.remarkForm.remark = this.orderInfo.remark;
      this.customer = true;
    },

    addCustomer(form) {
      //备注确定
      this.$refs[form].validate(valid => {
        if (valid) {
          axiosApi(
            "/order/sic/orderRemark/update",
            this.remarkForm,
            res => {
              this.getOrderInfo();
              this.$message({
                type: "success",
                message: "备注成功",
                duration: 2000
              });
              this.customer = false;
              this.show = "second";
            },
            1,
            10,
            res => {
              this.customer = false;

              this.$message({
                type: "info",
                message: res.data.message,
                duration: 3000
              });
            }
          );
        }
      });
    },
    openCustomer(form) {
      this.isDeliver = true;
      this.customer = true;
      if (this.$refs[form] !== undefined) {
        this.$refs[form].resetFields();
      }
    },
    changeOrderDialogStatusFun(val) {
      this.divideOrderVisible = val;
    },
    handleClickFun(val) {
      this.divideOrderVisible = false;
      this.cancelOrderVisible = true;
    },
    handlePageChange(val) {
      this.currentPage = val;
      this.initVoucherTable();
    },
    // 获取凭证 列表
    initVoucherTable() {
      let body = null;
      if (this.$route.query.id) {
        body = { orderSn: this.$route.query.id };
      } else {
        body = { orderSn: this.$route.query.sn };
      }
      axiosApi(
        "/superc/credit/order/backReason",
        body,
        res => {
          let data = res.data.data;
          if (data) {
            this.tableList = data.elements;
            this.total = data.totalCount;
            if (data.elements && data.elements.length > 0) {
              this.voucherTableList = data.elements.map((v, k) => {
                v.bankInfo = {
                  bankName: v.bankName,
                  bankUserName: v.bankUserName,
                  bankCard: v.bankCard
                };
                return v;
              });
            }
          }
        },
        this.currentPage,
        10
      );
    },
    // 查看凭证
    handleVoucherSee(row) {
      row.imgs = row.imgs ? row.imgs : [];
      if (row.imgs.length === 0) {
        this.$message.warning("暂未上传凭证");
        return;
      }
      this.voucherImgList = row.imgs;
      this.bankInfo = row.bankInfo;
      this.$refs.viewredentials.show();
    },
    // 查看 驳回理由
    handleVoucherReject(row) {
      let div = (
        <div>
          <p>驳回理由：</p>
          <p
            style={{
              marginTop: "5px",
              wordWrap: "break-word",
              wordBreak: "normal",
              paddingBottom: "60px"
            }}
          >
            {row.reason}
          </p>
        </div>
      );
      this.$msgbox({
        title: "查看",
        message: div,
        showCancelButton: false,
        confirmButtonText: "确定",
        beforeClose: (action, instance, done) => {
          done();
        }
      }).then(action => {});
    },
    handleClick() {
      console.log(this.show);
      //切换内容
    },
    getOrderInfo() {
      //获取订单详细信息
      axiosApi(
        "/order/sic/web/detail",
        {
          id: this.$route.query.id
        },
        res => {
          this.orderInfo = res.data.data;
          this.traceApiDtos = res.data.data.expressOrderListenList
            ? res.data.data.expressOrderListenList
            : [];
          if (
            this.orderInfo.remainAutoCancelTime != null &&
            this.orderInfo.remainAutoCancelTime > 0
          ) {
            let time = parseInt(this.orderInfo.remainAutoCancelTime / 1000); //转化为整数
            this.orderInfo.remainAutoCancelTime = this.timeString(time); //转化为时间格式
            let clearTime = null; //定义变量
            clearTime = setInterval(() => {
              time--;
              this.orderInfo.remainAutoCancelTime = this.timeString(time);
              if (time <= 0) {
                clearTimeout(clearTime);
                //								if(this.orderInfo.orderStatus == 1) this.orderInfo.orderStatus = 0
              }
            }, 1000);
          } else {
            //						if(this.orderInfo.orderStatus == 1) this.orderInfo.orderStatus = 0
          }
        },
        1,
        10,
        res => {
          this.$message({
            type: "error",
            message: res.data.message,
            duration: 1000
          });
        }
      );
    },
    handleCancelOrder() {
      //取消订单按钮
      if (this.$route.query.id) {
        //有拆单情况
        this.divideOrderVisible = true;
      } else {
        this.cancelOrderVisible = true;
      }
    },
    handleCommit(state) {
      //确定
      this.$refs.cancelOrderForm.validate(valid => {
        if (valid) {
          this.cancelOrderVisible = false;
          axiosApi(
            "/superc/order/webCancel",
            {
              orderSn: this.$route.query.id
                ? this.$route.query.id
                : this.$route.query.sn,
              reason: this.form.cancleReason,
              cancleType: 2
            },
            res => {
              this.getOrderInfo();
              this.$message({
                type: "success",
                message: "取消成功",
                duration: 3000
              });
            },
            1,
            10,
            res => {
              this.$message({
                type: "error",
                message: res.data.message,
                duration: 1000
              });
            }
          );
        } else {
          return false;
        }
      });
    },
    orderDetail(orderSn) {
      //去订单详情页面
      let type = "jiuji";
      sessionStorage.setItem("orderSn", orderSn);
      window.open(basePath + "/ccPurchaseOrderDetail?type=" + type, "_blank");
    }
  }
};
</script>

<style lang="less">
#salesInCarOrderDetail {
  .minMop {
    .el-form-item__label {
      width: 130px;
    }
    .el-dialog__body {
      padding: 30px 0 30px !important;
    }
    .el-dialog {
      width: 500px !important;
      min-height: 300px !important;
    }
    .el-form-item__error {
      left: 150px;
    }
  }
  .bdt {
    border-top: 1px solid #eee;
  }
  .el-tabs__header {
    padding: 0;
    border-bottom: 1px solid #eee;
  }
  .el-table__empty-block {
    display: none;
  }
  .box-con {
    padding: 20px;
    .order-info {
      border: 1px solid #ddd;
      box-sizing: border-box;
      .order-info-tit {
        height: 40px;
        line-height: 40px;
        background-color: #eee;
      }
      .order-info-con {
        .order-info-conL {
          width: 700px;
          line-height: 30px;
          padding: 22.5px 0;
          label {
            width: 150px;
            display: inline-block;
            text-align: right;
          }
          &.order-info-conL2 {
            width: 90%;
          }
        }
      }
    }
    .goods {
      border: 1px solid #ddd;
      box-sizing: border-box;
      .goods-info {
        height: 120px;
        border-bottom: 1px solid #ddd;
        span {
          display: inline-block;
        }
        .goods-infoL {
          width: 650px;
          height: 100%;
          padding: 20px;
          box-sizing: border-box;
          border-right: 1px solid #ddd;
          img {
            width: 80px;
            height: 80px;
          }
          .w380 {
            width: 200px;
          }
          .w210 {
            width: 210px;
          }
        }
        .goods-infoR {
          width: 185px;
          display: inline-block;
        }
      }
      .goods-money {
        line-height: 25px;
        padding: 30px 50px;
        box-sizing: border-box;
        label {
          width: 300px;
          display: inline-block;
          text-align: right;
        }
      }
    }
    .el-dialog {
      min-height: 300px;
      .el-form-item__label {
        width: 140px;
      }
    }
    .cancel-order {
      .el-dialog {
        min-height: 500px;
      }
    }
    .sendGoods .el-form-item__error {
      margin-left: 140px;
    }
  }
  .color-red {
    color: red;
  }
  .my-badge {
    padding-top: 5px;
  }
  .myBtn {
    .el-button {
      width: 70px;
    }
  }
  .el-tabs__header {
    padding-top: 10px;
    padding-left: 0;
  }
  .el-checkbox__label {
    display: none;
  }
  .notPayCountmark {
    position: absolute;
    left: 144px;
    top: 10px;
  }
  .notDeliveryCount {
    position: absolute;
    left: 218px;
    top: 10px;
  }
  .orderDeliveriedCount {
    position: absolute;
    left: 294px;
    top: 10px;
  }
  .hide {
    display: none;
  }
  .content {
    .el-checkbox {
      width: 40px !important;
    }
    .el-date-editor--daterange.el-input {
      width: 240px;
    }
    .el-form--inline {
      width: 650px;
      .el-form-item__label {
        font-size: 12px;
        width: 60px;
      }
    }
    .el-table .el-table__body-wrapper {
      display: none;
    }
    .order-datatimerange {
      width: 240px;
    }
    .el-table {
      border: none;
      font-size: 14px;
      th > .cell {
        line-height: 12px;
        padding: 10px 0 10px 0;
        text-align: center;
      }
      th.is-leaf {
        border-bottom: 0;
      }
    }
    .checkedAll {
      position: relative;
      top: 30px;
    }
    .el-checkbox {
      width: 70px;
      margin-left: 30px;
      .el-checkbox__input {
        height: 20px;
      }
    }
    .el-checkbox__inner {
      width: 20px;
      height: 20px;
    }
    .el-checkbox__inner::after {
      top: 2px;
    }
    .table-item {
      .bl {
        border-left: 1px solid #ddd;
      }
      border: 1px solid #dddddd;
      font-size: 14px;
      border-bottom: none;
      .table-item-content .pbl-20 {
        padding: 0px 0px 20px 20px;
      }
      .table-item-top {
        padding: 10px 0;
        position: relative;
        .table-item-top-checkbox {
          margin-left: 14px;
        }
        .all_sn_ct {
          background: #eee;
          position: absolute;
          left: 70px;
          top: 30px;
          padding: 10px 5px;
          border: 1px solid #ddd;
          z-index: 1;
        }
      }
      img {
        width: 80px;
        height: 80px;
      }
    }
    .w100 {
      width: 100px;
    }
    .table-item-default {
      height: 530px;
      padding-top: 120px;
    }
    .el-dialog {
      min-height: 300px;
      .el-form-item__label {
        width: 140px;
      }
    }
    .sendGoods .el-form-item__error {
      margin-left: 140px;
    }
  }
  .ml-70 {
    margin-left: 70px;
  }
  .my-badge {
    .el-badge__content {
      border: 0;
    }
  }
  .color-blue {
    color: #20a0ff;
    cursor: pointer;
  }
  .specialD {
    position: relative;
    display: flex;
    justify-content: space-between;
      .giftLogo{
          position: absolute;
          width: 80px;
          height: 80px;
          left: 0;
          top:0;
      }
    .skuImg {
      width: 80px;
      height: 80px;
      display: inline-block;
    }
    .row-item-1 {
      flex: 1;
        width: 0;
      height: auto;
      .bw {
        word-wrap: break-word;
      }
    }
    .row-item-2 {
      width: 0;
        flex: 1;
      height: auto;
      .bw {
        word-wrap: break-word;
      }
    }
    .special {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}
</style>
