<template>
	<div>
		<div id="verifyresult">
			<p class="h90 pl-50 bg-g"><i class="iconfont font-50 c" :class="{'icon-ok':result,'icon-fail':!result}"></i><span class="ml-30 font-30 top">{{title}}</span></p>
			<el-form label-width="226px" :model="orderInfo" class="mt-60" v-if="result">
				<el-form-item label="姓名:" v-if="page==0">
					<span>{{orderInfo.realName}}</span>
				</el-form-item>
				<el-form-item :label="label1">
					<span v-if="page==0">{{orderInfo.idCardNumber}}</span>
					<span v-if="page==2">{{orderInfo.money |fmoney}}元</span>
					<span v-if="page==3" class="color-danger">{{orderInfo.needPayMoney | fmoney}}元</span>
				</el-form-item>
				<el-form-item label="手续费:" v-if="page==1||page==2">
					<span>{{orderInfo.money*orderInfo.rate||0 |fmoney}}元</span>
				</el-form-item>
				<el-form-item label="最终到账:" v-if="page==1||page==2">
					<span>{{orderInfo.money - orderInfo.money*orderInfo.rate||0  |fmoney}}元</span>
				</el-form-item>
				<el-form-item :label="label2">
					<span v-if="page==3">{{orderInfo.sn}}</span>
					<span v-if="page==2">{{orderInfo.gmtCash | time}}</span>
				</el-form-item>
				<el-form-item :label="label3">
					<span v-if="page==3">{{orderInfo.addrProvince}}{{orderInfo.addrCity}}{{orderInfo.addrRegion}}{{orderInfo.addr}} {{orderInfo.buyerName}} {{orderInfo.buyerPhone}}</span>
				</el-form-item>
			</el-form>
			<div class="mt-60 pr-50 font-20 clearfix mb-20" v-else>
				<span class="fl ml-130">失败原因:</span>
				<div class="fl ml-20 ba">{{errormsg}}</div>
			</div>
		</div>
		<div>
			<span style="margin-left: 245px;" class="font-20 mr-30">你可以:</span>
			<div class="inline-block" v-if="$route.params.page==3">
				<div class="inline-block" v-if="result"><el-button type="danger" class="mr-10" @click="goToorderDetail">查看订单</el-button><el-button @click="shopping">继续采购</el-button></div>
				<el-button type="danger" v-else>继续采购</el-button>
			</div>
			<el-button type="danger" style="width: 120px;" v-else @click="goPage">返回</el-button>
		</div>
	</div>
</template>

<script>
	import { axiosApi, basePath } from "../../../api/api"
	export default {
		data() {
			return {
				result: this.$route.params.result == "1" ? true : false, //认证结果
				page: "0", //区别是哪个页面
				label1: "",
				label2: "",
				label3:"",
				title: "",
				orderInfo:{},
			}
		},
		mounted() {
			this.$nextTick(() => {
				this.page = this.$route.params.page
				if (this.page == 3) {
					this.orderDetail()
				}else if (this.page == 0&&this.result) {
					this.loading()
				}else if (this.page == 2&&this.result) {
					this.orderInfo = sessionStorage.getItem("payform")?JSON.parse(sessionStorage.getItem("payform")):""
				}
				switch(this.$route.params.page) {
					case "0": //实名认证
						this.title = this.result?"恭喜您实名认证成功":"实名认证失败"
						this.label1 = "身份证号:"
//						this.label2 = "认证日期:"
						break;
					case "1": //充值
						this.title = this.result?"充值成功":"充值失败"
						this.label1 = "充值金额:"
						this.label2 = "充值时间:"
						break;
					case "2": //提现
						this.title = this.result?"提现申请成功":"提现申请失败"
						this.label1 = "提现金额:"
						this.label2 = "提现时间:"
						break;
					case "3": //付款
						this.title = this.result?"付款成功":"付款失败"
						this.label1 = "支付金额:"
						this.label2 = '订单号:'
						this.label3 = '收货地址:'
					default:
						break;
				}
			})
		},
		computed:{
			errormsg() {
				return this.$store.state.paymodule.errormsg
			}
		},
		methods:{
			loading(){//实名认证
				axiosApi("/anon/cbs/account/employeeInfo",{},res=>{
					this.orderInfo = res.data.data
				})
			},
      orderDetail(){ //订单详情
        let a = sessionStorage.isParentPurchaseOrderType || '',
          data = {}
        if(a==2){
          data.parentOrderSn = sessionStorage.orderSn
        }else{
          data.orderSn = sessionStorage.orderSn
        }
				axiosApi('/anon/parentOrder/detail/info',data,(res)=>{
					this.orderInfo = res.data.data
				},null,null,null,1)
			},
			goToorderDetail() { //去订单详情页面
				this.$router.push(basePath + '/purchaseOrderDetail')
			},
			shopping(){ //继续采购
				this.$router.push(basePath + '/shop/shopIndex')
			},
			goPage(){//返回
				this.$router.push(basePath + '/myaccount')
			}
		}
	}
</script>

<style lang="less">
	#verifyresult {
		width: 998px;
		min-height: 298px;
		margin: 50px auto;
		border: 1px solid #ddd;
		.h90 {
			height: 90px;
			line-height: 90px;
		}
		.c {
			color: #30C024;
		}
		.el-form-item__label,
		.el-form-item__content {
			height: 20px;
			line-height: 20px;
			font-size: 20px;
		}
		.ml-130 {
			margin-left: 130px;
		}
		.ba {
			word-break: break-all;
			width: 710px;
		}
	}
</style>