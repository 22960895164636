<template>
  <div id="chooseGoods">
    <el-dialog title="选择商品" :visible.sync="productVisible" @close="goPage2">
      <div v-loading="tableloading">
        <el-form
          :inline="true"
          :model="skuObj"
          label-width="80px"
          class="clearfix"
          :style="{ width: '900px' }"
        >
          <div class="fl">
            <el-form-item label="商品编码:" v-if="isgiftmanage||isCityPartner">
              <el-input v-model.trim="skuObj.goodsNo" @input="skuObj.goodsNo=skuObj.goodsNo.replace(/[\u4e00-\u9fa5]/ig,'')"></el-input>
            </el-form-item>
            <el-form-item label="SKU编码:" v-else>
              <el-input v-model.trim="skuObj.skuNo" @input="skuObj.skuNo=skuObj.skuNo.replace(/[\u4e00-\u9fa5]/ig,'')"></el-input>
            </el-form-item>
            <el-form-item label="商品名称:">
              <el-input v-model.trim="skuObj.skuName"></el-input>
            </el-form-item>
            <el-form-item
              label="商品分类:"
              v-if="$route.query.couponSource != '3'"
            >
              <el-select
                v-model="skuObj.classifyId"
                placeholder="请选择"
                clearable
              >
                <el-option
                  v-for="item in classifylist"
                  :key="item.id"
                  :label="item.classifyName"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="商品分类:" v-else>
              <el-select
                v-model="skuObj.classifyId"
                placeholder="请选择"
                clearable
              >
                <el-option
                  v-for="item in classifylist"
                  :key="item.classifyId"
                  :label="item.classifyName"
                  :value="item.classifyId"
                ></el-option>
              </el-select>
            </el-form-item>
          </div>
          <el-button type="primary" class="fl ml-50" @click="querygood"
            >查询</el-button
          >
        </el-form>
        <el-table
          ref="multipleTable"
          @selection-change="handleSelectionChange"
          :data="proData"
          stripe
          border
          tooltip-effect="dark"
          class="mt-10 table-default"
        >
          <el-table-column
            type="selection"
            width="50"
            :selectable="selectInit"
          ></el-table-column>
          <el-table-column label="SKU编码">
            <template slot-scope="scope">
              <p v-if="scope.row.skuNo">{{scope.row.skuNo}}</p>
              <p v-else>--</p>
            </template>
          </el-table-column>
          <el-table-column label="商品编码" v-if="isgiftmanage||isCityPartner">
            <template slot-scope="scope">
              <p v-if="scope.row.goodsNo">{{scope.row.goodsNo}}</p>
              <p v-else>--</p>
            </template>
          </el-table-column>
          <el-table-column
            label="商品名称"
            prop="skuName"
            width="300"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column label="单位" prop="units"></el-table-column>
          <el-table-column label="分类" prop="classifyName"></el-table-column>
          <el-table-column v-if="!hidePrice" label="价格">
            <template slot-scope="scope">
              <span
                v-if="
                  scope.row.enterShopPrice > 0 || scope.row.maxSellingPrice > 0
                "
                >￥{{
                  $route.query.couponSource != "3"
                    ? scope.row.enterShopPrice
                    : scope.row.maxSellingPrice
                }}</span
              >
              <span v-else>--</span>
            </template>
          </el-table-column>
          <el-table-column label="库存">
            <template slot-scope="scope">{{
              scope.row.mainStockEnable!==undefined?scope.row.mainStockEnable:scope.row.stockEnableQuantity
            }}</template>
          </el-table-column>
        </el-table>
        <div class="clearfix" v-if="total > 0">
          <el-pagination
            class="fr"
            v-if="total > 0"
            :current-page="currentPage"
            :page-size="10"
            :total="total"
            layout="prev , pager, next"
            @current-change="handlePageChange"
          ></el-pagination>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" size="mini" @click="addgift" :loading="btnLoading"
          >{{isgiftmanage ? '添加已选商品' : '确定'}}</el-button
        >
          <el-button size="mini" type="primary" v-if="isgiftmanage" @click="addAllPage">按查询结果添加</el-button>
        <el-button size="mini" @click="goPage">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
import { axiosApi, basePath } from "../api/api"; //导入axiosApi
export default {
  props: [
    "stockIn",
    "stockOut",
    "goodVisible",
    "addapi",
    "isIntegerMultiple",
    "ifnotauto",
    "ok",
    "isMeal",
    "isadditional",
    "goodsNum",
    "isgiftmanage",
    "isFullGift",
    "fromJicai",
    "activityId",
    "wareHouseId",
    "hidePrice"
  ],
  data() {
    return {
      currentPage: 1,
      skuObj: {
        goodsNo: "", // K3编码
        skuNo: "", // 商品编码
        skuName: "", //商品名称
        classifyId: "" //商品分类
      },
      btnLoading: false, //搜索时
      multipleSelection: [],
      addlists: [],
      productVisible: false,
      // notId: [],
      allgoodsChecked: false // 全部商品选中
    };
  },
  computed: {
    isCityPartner() {
      return this.$store.state.clientType.isCityPartner;
    },
    currentPath() {
      return this.$router.currentRoute.path || "";
    },
    postData() {
      if (this.isFullGift) {
        if (
          this.currentPath.indexOf("/addacaboutenoughsent") != -1 ||
          this.currentPath.indexOf("/updatesent") != -1
        )
          this.skuObj.activityType = 1; // 送赠品
        if (this.currentPath.indexOf("/addwithadditional") != -1)
          this.skuObj.activityType = 4; // 换购
        if (
          this.currentPath.indexOf("/addacaboutenoughcut") != -1 ||
          this.currentPath.indexOf("/updatecut") != -1
        )
          this.skuObj.activityType = 2; // 满减
      }
      return {
        data: this.skuObj,
        currentPage: this.currentPage,
        isIntegerMultiple: this.isIntegerMultiple
      };
    },
    tableloading() {
      return this.$store.state.ticket.tableloading;
    },
    total() {
      return this.$store.state.ticket.goodtableData
        ? this.$store.state.ticket.goodtableData.totalCount
        : 0;
    },
    classifylist() {
      return this.$store.state.classifylist.classifylistData
        ? this.$store.state.classifylist.classifylistData
        : [];
    },
    proData() {
      let tableData = this.$store.state.ticket.goodtableData;
      console.log(tableData,'biaoge')
      this.currentPage = tableData ? tableData.pageIndex : 1;
      return tableData ? tableData.elements : [];
    },
    activitySource() {
      return (
        (this.$route.query.activitySource
          ? this.$route.query.activitySource
          : this.$route.query.couponSource) || 1
      );
    }
  },
  filters: {
    participateStatus: function(value) {
      if (value != 0 && !value) return "";
      return value == 0 ? "未参加活动" : "已参加活动";
    }
  },
  watch: {
    goodVisible(value) {
      this.productVisible = value;
      if (value) {
        this.skuObj.skuNo = "";
        this.skuObj.goodsNo = "";
        this.skuObj.skuName = "";
        this.skuObj.classifyId = "";
      }
    },
    productVisible(val) {
      //监听弹窗model变化改变按钮loading（打开弹窗初始化loading）
      this.btnLoading = !val;
    },
    allgoodsChecked(val) {
      // 全选商品
      if (val) {
        let newpostdata = Object.assign({}, this.postData, {
          currentPage: 1
        });
        let buyerList = this.$store.state.clientlist.willadd || [];
        newpostdata.data.buyerIds = buyerList.map(v => v.buyerId); // 添加 入参 buyerIds 选择客户的 buyerIds
        newpostdata.data.activitySource = this.activitySource || 1; // B2B和酒店
        newpostdata.data.queryPage = 0; // 0-查询全部 1-分页查询
        if (this.isFullGift) {
          if (this.$route.query.couponSource != "3") {
            this.fullgiftSearchGoods(newpostdata);
          } else {
            // newpostdata.data.goodsNo = newpostdata.data.skuNo;
            newpostdata.data.skuNoList = this.$store.getters.willaddskuno;
            this.societyfullgiftSearchGoods(newpostdata);
          }

          return false;
        }
        if (this.isMeal) {
          // 过滤套餐商品
          this.mealgood(newpostdata);
        } else {
          // 全部商品
          this.addDistribute(newpostdata);
        }
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$route.query.couponSource != "3") {
        this.classSearch();
      } else {
        this.classSearchSociety();
      }
    });
  },
  methods: {
    ...mapMutations({
      clearnotid: "clearnotid",
      clearwilladd: "clearwilladd"
    }),
    //查询商品列表名字
    ...mapActions({
      addDistribute: "querygoodtableData",
      classSearch: "queryclassifylistData",
      classSearchSociety: "querysocietyclassifylist",
      mealgood: "queryMealtableData",
      fullgiftSearchGoods: "fullgiftSearchGoods", //赠品组合  添加客户纬度 更换 api
      societyfullgiftSearchGoods: "societyfullgiftSearchGoods",
      queryJicaiData: "queryJicaiData"
    }),
    selectInit(row, index) {
      if (
        row.participateStatus == 1 ||
        (row.stockEnableQuantity == 0 && this.stockOut)
      )
        return false;
      if (row.participateStatus == 0 || !row.participateStatus) return true;
    },
    handleSelectionChange(val) {
      let addlist = val.map(each => {
        return  each.skuNo
      });
      if (this.goodsNum) {
        if (val.length > this.goodsNum) {
          // 可选择赠品数量
          let num = val.length - this.goodsNum;
          val.splice(this.goodsNum, num);
          addlist.splice(this.goodsNum, num); //删除多余数据
          this.$message.warning(`最多选中${this.goodsNum}件商品`);
        }
      }
      this.addlists = addlist;
      this.multipleSelection = val;
    },
    querygood() {
      /* 
      使用goodsNo搜索
      isCityPartner // 城市拍档
      */
      if (this.currentPage == 1) {
        let newpostdata = JSON.parse(JSON.stringify(this.postData));
        newpostdata.data.wareHouseId = this.wareHouseId;
        newpostdata.currentPage = 1;
        newpostdata.activityId = this.activityId;
        // if (this.isCityPartner) {
        //   newpostdata.data.goodsNo = newpostdata.data.skuNo;
        //   delete newpostdata.data.skuNo;
        // }
        if (this.isgiftmanage) {
          // 赠品管理商品库
          newpostdata.data.isGiftGoodsId = true;
          newpostdata.isGift = true;
          this.mealgood(newpostdata);
          return false;
        }
        if (this.isFullGift) {
          // 赠品添加商品
          let buyerList = this.$store.state.clientlist.willadd || [];
          newpostdata.data.buyerIds = buyerList.map(v => v.buyerId); // 添加 入参 buyerIds 选择客户的 buyerIds
          newpostdata.data.activitySource = this.activitySource || 1; // B2B和酒店
          newpostdata.data.queryPage = 1; // 0-查询全部 1-分页查询
          newpostdata.data.isIntegerMultiple = this.isIntegerMultiple;
          if (this.$route.query.couponSource != "3") {
            this.fullgiftSearchGoods(newpostdata);
          } else {
            newpostdata.data.skuNoList = this.$store.getters.willaddskuno;
            this.societyfullgiftSearchGoods(newpostdata);
          }
          /* if (!this.isCityPartner) {
            if (this.$route.query.couponSource != "3") {
              this.fullgiftSearchGoods(newpostdata);
            } else {
              // newpostdata.data.goodsNo = newpostdata.data.skuNo;
              newpostdata.data.skuNoList = this.$store.getters.willaddskuno;
              this.societyfullgiftSearchGoods(newpostdata);
            }
          } else {
            if (this.$route.query.couponSource != "3") {
              // newpostdata.data.skuNo = newpostdata.data.goodsNo;
              // delete newpostdata.data.goodsNo;
              this.fullgiftSearchGoods(newpostdata);
            } else {
              newpostdata.data.skuNoList = this.$store.getters.willaddskuno;
              this.societyfullgiftSearchGoods(newpostdata);
            }
          } */

          return false;
        }
        if (this.fromJicai) {
          //集采的商品
          this.queryJicaiData(newpostdata);
        } else if (this.isMeal) {
          // 过滤套餐商品
          this.mealgood(newpostdata);
        } else if (this.stockIn || this.stockOut) {
          // 出入库商品不考虑上下架状态
          newpostdata.data.skuState = "null";
          newpostdata.data.skuType = "1";

          this.addDistribute(newpostdata);
        } else {
          // 全部商品
          this.addDistribute(newpostdata);
        }
      } else {
        this.currentPage = 1;
      }
    },
      //添加当前一页数据
      addOnePage(){
          this.$refs.multipleTable.clearSelection();
          this.proData.forEach(row => {
              this.$refs.multipleTable.toggleRowSelection(row);
          });
      },
      //添加所有页的数据
      addAllPage(){
          let newpostdata = JSON.parse(JSON.stringify(this.postData));
          // if (this.isCityPartner) {
          //     newpostdata.data.goodsNo = newpostdata.data.skuNo;
          //     delete newpostdata.data.skuNo;
          // }
          // 赠品管理商品库
          newpostdata.data.isGiftGoodsId = true;
          newpostdata.isGift = true;

          const api = '/marketing/gift/addAll'
          let newbody = newpostdata.data
          let initialstate = {
              sellerId: sessionStorage.getItem('traderId'),
              skuState: 2, //sku状态(1：待上架（下架） 2：上架中)
              auditState: 2, //审核状态（1：待审核 2：通过 3： 未通过）
              skuType: '1', // 过滤套餐商品
              // notId: this.$store.state.ticket.notId ? this.$store.state.ticket.notId : [],
          }
          let body = Object.assign({}, initialstate, newbody);
          delete body.skuState;
          axiosApi(api, body, (res) => {
              console.log(res.data.data,'data');
              this.goPage()
              // this.$emit("closedialog");
              // this.$emit("handleOk");
          }, 1, 10000,err => {
              this.$message.error(err.data.message)
          },1)
      },
    //调用添加赠品接口
    addgift() {
        if (this.addlists.length == 0){
            this.$message.error('请选择商品')
            return false
        }
      const api = this.addapi;
      let body ={
          skuNoList: this.addlists,
          sellerId: sessionStorage.traderId
      } ;
      // this.btnLoading = true;
      if (this.allgoodsChecked) {
        if (this.ifnotauto) {
          this.$store.dispatch("addgood", {
            willadd: this.$store.state.ticket.allgoodtableData
          });
          this.allgoodsChecked = false;
        }
        if (this.ok === true) {
          this.$emit("handleOk");
        } else {
          this.$emit("closedialog");
        }
        return false;
      }
      if (body.skuNoList.length > 0) {
        if (api) {
          axiosApi(api, body, this.goPage, 1, 1, err => {
            // this.$message.warning(err.data.message);
          });
          return false;
        } else {
          if (this.ifnotauto) {
            if (body.length > 1 && this.fromJicai) {
              //集采用到的逻辑
              this.$message.error("每个招标最多只允许包含1个商品！");
            } else {
              if (this.fromJicai) {
                this.clearwilladd();
              }
              this.$store.dispatch("addgood", {
                willadd: this.allgoodsChecked
                  ? this.$store.state.ticket.allgoodtableData
                  : this.multipleSelection
              });
            }
          }

          if (this.isadditional) {
            // 满赠商品加价购
            let a = JSON.parse(JSON.stringify(this.multipleSelection));
            this.multipleSelection = a.map(item => {
              return Object.assign(item, {
                price: "",
                quantity: "",
                isrequired: false,
                unitType: 1,
                limitQuantity: ""
              });
            });
            this.$store.dispatch("addgoodwidthadditional", {
              willadd: this.multipleSelection
            });
          }
        }
      }
      if (api) {
        this.goPage();
      } else {
        if (this.ok === true) {
          this.$emit("handleOk");
        } else {
          this.$emit("closedialog");
        }
      }
    },
    //返回上一页
    goPage() {
      this.clearnotid();
      this.$emit("sure");
      this.$emit("closedialog");
    },
    goPage2() {
      this.clearnotid();
      this.$emit("close");
      this.$emit("closedialog");
    },
    handlePageChange(val) {
      this.currentPage = val;
      let newpostdata = Object.assign(
        {},
        this.postData,
        {
          currentPage: val
        },
        { activityId: this.activityId }
      );
      if (this.isgiftmanage) {
        // 赠品管理商品库
        newpostdata.data.isGiftGoodsId = true;
        newpostdata.isGift = true;
        this.mealgood(newpostdata);
        return false;
      }
      if (this.isFullGift) {
        // 赠品添加商品 满赠
        let buyerList = this.$store.state.clientlist.willadd || [];
        newpostdata.data.buyerIds = buyerList.map(v => v.buyerId); // 添加 入参 buyerIds 选择客户的 buyerIds
        newpostdata.data.activitySource = this.activitySource || 1; // B2B和酒店
        newpostdata.data.queryPage = 1; // 0-查询全部 1-分页查询
        newpostdata.data.isIntegerMultiple = this.isIntegerMultiple; //
        if (this.$route.query.couponSource != "3") {
          this.fullgiftSearchGoods(newpostdata);
        } else {
          // newpostdata.data.goodsNo = newpostdata.data.skuNo;
          newpostdata.data.skuNoList = this.$store.getters.willaddskuno;
          this.societyfullgiftSearchGoods(newpostdata);
        }
        return false;
      }
      if (this.fromJicai) {
        //集采的商品
        this.queryJicaiData(newpostdata);
      } else if (this.isMeal) {
        // 过滤套餐商品
        this.mealgood(this.postData);
      } else {
        // 全部商品
        this.addDistribute(this.postData);
      }
    }
  }
};
</script>

<style lang="less">
#chooseGoods .el-dialog {
  width: 950px;
}

#chooseGoods .table-default.el-table .cell {
  padding: 3px 15px 0;
}

#chooseGoods .el-dialog__body {
  padding: 20px 30px;
}

#chooseGoods .el-dialog__footer {
  position: static;
  padding-bottom: 30px;
  transform: translateX(0);
}

#chooseGoods {
  .el-dialog {
    .el-form {
      .el-form-item {
        .el-form-item__label {
          &::before {
            display: none;
          }
        }
      }
    }
  }
}

#chooseGoods .el-form .el-input {
  width: 120px;
}
</style>
