<template>
    <div class="gift">
        <div class="clearfix">
            <div class="fr">
                <el-button @click="addGift" icon="el-icon-plus">添加赠品</el-button>
                <el-button @click="addGiftForgoodsList">商品库商品</el-button>
                <el-button @click="deletesome" icon="el-icon-delete-solid">批量删除</el-button>
            </div>
        </div>
        <div class="content">
            <div class="clearfix">
                <el-form :inline='true' class="pt-30 fl" :model="querygood">
                    <el-form-item label='商品名称'>
                        <el-input placeholder="输入商品名称" v-model.trim="querygood.skuName"></el-input>
                    </el-form-item>
                    <el-form-item label='商品编码' class='ml-20'>
                        <el-input placeholder="输入商品编码" v-model.trim="querygood.goodsNo" @input="querygood.goodsNo=querygood.goodsNo.replace(/[\u4e00-\u9fa5]/ig,'')"></el-input>
                    </el-form-item>
                    <el-form-item label='分类'>
                        <el-select v-model="querygood.classifyId" placeholder="请选择" clearable>
                            <el-option label="赠品" value="0">
                            </el-option>
                            <el-option v-for="item in classifylist" :key="item.id" :label="item.classifyName" :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
                <el-button class="fl ml-30 mt-30 color-danger" type='' @click="querygift">查 询</el-button>
            </div>
        </div>

        <el-table :data="tableData" tooltip-effect="dark" class="mt-20 clearfix table-default"
                  @selection-change="handleSelectionChange">
            <el-table-column type="selection" min-width="50">
            </el-table-column>
            <el-table-column label="SKU编码" min-width="100">
                <template slot-scope="scope">
                    <p v-if="scope.row.skuNo">{{ scope.row.skuNo }}</p>
                    <p v-else>--</p>
                </template>
            </el-table-column>
            <el-table-column label="商品编码" min-width="100">
                <template slot-scope="scope">
                    <p v-if="scope.row.k3GoodsNo">{{ scope.row.k3GoodsNo }}</p>
                    <p v-else>--</p>
                </template>
            </el-table-column>
            <el-table-column label="商品名称" min-width="150">
                <template slot-scope="scope">
                    <div>{{scope.row.skuName}}</div>
                </template>
            </el-table-column>
            <el-table-column label="商品分类" min-width="110">
                <template slot-scope="scope">
                    <div>{{scope.row.classifyName}}</div>
                </template>
            </el-table-column>
            <el-table-column label="库存" min-width="100">
                <template slot-scope="scope">
                    <div>{{scope.row.giftStock}}</div>
                </template>
            </el-table-column>
             <el-table-column label="价格" min-width="100">
                <template slot-scope="{row}">
                    <div>￥{{row.giftPrice}}/{{ row.baseUnitName}}</div>
                </template>
            </el-table-column>
            <el-table-column label="操作" min-width="150">
                <template slot-scope="scope">
                    <el-button type="text" @click.native.prevent="deleteRow(scope.$index, tableData)" size="small">删除
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
                class="tar mt-20 mr-50"
                :current-page="currentPage"
                :page-size='10'
                :total='total'
                layout="prev , pager, next, jumper"
                v-if="total>0"
                @current-change="handlePageChange"
        >
            <!-- 分页组件 -->
        </el-pagination>
        <chooseGoods v-on:sure="initcomponent"
                     :goodVisible="dialogVisible"
                     :addapi="'/marketing/gift/add'"
                     v-on:close="initcomponent2"
                     :isgiftmanage='true'>
        </chooseGoods>
        <el-dialog class='gift-dialog' title='添加新赠品' :visible.sync='giftDialog'>
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px">
                <el-form-item label='赠品名称:' prop="skuName">
                    <el-input v-model.trim="ruleForm.skuName" placeholder="输入赠品名称"></el-input>
                </el-form-item>
                <el-form-item label='赠品图片:' prop="giftPic">
                    <el-upload
                            class="avatar-uploader bd"
                            action="/sunrise-gateway/oss/ossUpload"
                            :show-file-list="false"
                            :on-success="handleAvatarSuccess"
                            :before-upload="beforeAvatarUpload">
                        <img v-if="ruleForm.giftPic" :src="ruleForm.giftPic" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                </el-form-item>
                <el-form-item label='赠品价格:' prop="giftPrice">
                    <el-input v-model.trim="ruleForm.giftPrice" placeholder="输入赠品价格"></el-input>
                </el-form-item>
                <el-form-item label='单位:' prop="giftUnit">
                    <el-input v-model.trim="ruleForm.giftUnit" placeholder="输入单位"></el-input>
                </el-form-item>
            </el-form>
            <div slot='footer' class="dialog-footer">
                <el-button type='danger' @click="handleSave">确 定</el-button>
                <el-button @click="giftDialog = false">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {mapActions} from 'vuex';
    import {axiosApi, basePath} from "../api/api";

    export default {
        data() {
            return {
                value: '白酒',
                querygood: {
                    skuName: '',
                    goodsNo: '',
                    classifyId: ''
                },
                currentPage: 1,
                multipleSelection: [],
                dialogVisible: false,
                notId: [],
                giftDialog: false,
                ruleForm: {
                    sellerId: sessionStorage.getItem('traderId'),
                    skuName: null,
                    giftPic: null,//赠品图片
                    giftPrice: null,//赠品价格
                    giftUnit: null,//赠品计量单位
                },
                rules: {
                    skuName: [{
                        required: true,
                        message: '输入赠品名称'
                    }, {
                        max: 50,
                        message: '赠品名称不能超过50个字'
                    }],
                    giftPic: [{
                        required: true,
                        message: '请上传赠品图片'
                    }],//赠品图片
                    giftPrice: [{
                        required: true,
                        message: '输入赠品价格'
                    }, {
                        pattern: /^[1-9]\d{0,9}(\.\d{1,2})?$/,
                        message: '请输入正确的数字,最多保留两位小数'
                    }],//赠品价格
                    giftUnit: [{
                        required: true,
                        message: '输入赠品单位'
                    }, {
                        pattern: /^[\u4e00-\u9fa5]{0,5}$/,
                        message: '只能输入5个以内的汉字'
                    }],//赠品计量单位
                },
            }
        },
        computed: {
			isCityPartner() {
				return this.$store.state.clientType.isCityPartner;
			},
            classifylist() {
                return this.$store.state.classifylist.classifylistData ?
                    this.$store.state.classifylist.classifylistData : []
            },
            total() {
                return this.$store.state.ticket.giftlistData ?
                    this.$store.state.ticket.giftlistData.totalCount : 0
            },
            tableData() {
                return this.$store.state.ticket.giftlistData ?
                    this.$store.state.ticket.giftlistData.elements : []
            },
            postData() {
                return {
                    data: this.querygood,
                    currentPage: this.currentPage
                }
            }
        },
        methods: {
            //查询商品列表名字
            ...mapActions({
                'addDistribute': 'queryMealtableData',
                'classSearch': 'queryclassifylistData',
                'initgoodlist': 'querygiftlistData',
            }),
            handleSave() {
                this.$refs.ruleForm.validate((valid) => {
                    if (valid) {
                        axiosApi('/marketing/selfGift/add', this.ruleForm, res => {
                            this.resetData({
                                name: this.querygood,
                                data: ['skuName', 'goodsNo', 'classifyId']
                            })
                            this.querygift();//
                            this.giftDialog = false;
                        }, 1, 1, (err) => {
                            this.$message.info(err.data.message);
                        })
                    }
                })
            },
            handleAvatarSuccess(res, file) {
                // console.log(res.data[0])
                this.ruleForm.giftPic = res.data[0]
            },
            beforeAvatarUpload(file) {
                let bool = this.isUploadImg(file);
                return bool
            },
            handlePageChange(val) {
                this.currentPage = val
                this.initgoodlist(this.postData)
            },
            initcomponent() {
                this.dialogVisible = false
                this.initgoodlist(this.postData)
            },
            initcomponent2() {
                this.dialogVisible = false
            },
            querygift() {
                if (this.currentPage == 1) {
                    let newpostdata = JSON.parse(JSON.stringify(this.postData));
                    newpostdata.currentPage = 1
                    // if (this.isCityPartner) {
                    //     newpostdata.data.goodsNo = newpostdata.data.skuNo
                    //     delete newpostdata.data.skuNo
                    // }
                    this.initgoodlist(newpostdata)
                } else {
                    this.currentPage = 1;
                }
            },
            //批量删除
            deletesome() {
                let that = this;
                this.$msgbox({
                    title: '提示信息',
                    message: '您确定要删除这些赠品嘛？',
                    showCancelButton: true,
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    beforeClose: (action, instance, done) => {
                        if (action === 'confirm') {
                            const api = "/marketing/gift/delete";
                            const body = [];
                            body.push(...this.multipleSelection.map(a => {
                                return a.skuNo
                            }));
                            if (body.length > 0) {
                                axiosApi(api, body, function (res) {
                                    that.$notify({
                                        type: 'success',
                                        message: '删除赠品成功'
                                    })
                                    that.initgoodlist(that.postData); //再次刷新商品列表
                                    done();
                                }, undefined, undefined, (pro) => {
                                    that.$notify({
                                        message: pro.data.message,
                                        type: 'warning'
                                    })
                                    done();
                                })
                            } else {
                                that.$notify({
                                    type: 'warning',
                                    message: '未选择商品'
                                })
                                done()
                            }
                        } else {
                            done();
                        }
                    }
                })
            },
            deleteRow(index, rows) {
                let that = this;
                this.$msgbox({
                    title: '提示信息',
                    message: '您确定要删除这条信息？',
                    showCancelButton: true,
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    beforeClose: (action, instance, done) => {
                        if (action === 'confirm') {
                            const api = "/marketing/gift/delete";
                            const body = [];
                            body.push(this.tableData[index].skuNo);
                            axiosApi(api, body, function (res) {
                                that.$notify({
                                    type: 'success',
                                    message: '删除赠品成功'
                                })
                                that.initgoodlist(that.postData); //再次刷新商品列表
                                done();
                            }, 0, 10, (res) => {
                                that.$notify({
                                    type: 'warning',
                                    message: '' + res.data.message
                                })
                                done()
                            })
                        } else {
                            done();
                        }
                    }
                })
            },
            addGift() {
                this.giftDialog = true;
                if (this.$refs.ruleForm) this.$refs.ruleForm.resetFields();
            },
            addGiftForgoodsList() {
                let notidlist = []
                this.tableData.forEach((value, key) => {
                    notidlist.push(value.id)
                })
                this.notId = notidlist
                this.dialogVisible = true,
                    this.addDistribute({
                        data: {isGiftGoodsId: true, notId: this.notId},
                        currentPage: 1,
                        isGift:true,
                    })
            },
            handleSelectionChange(val) {
                this.multipleSelection = val;
            }
        },
        //进入加载
        mounted() {
            this.$nextTick(function () {
                this.initgoodlist(this.postData);
                this.classSearch();
            })
        }
    }
</script>

<style lang="less">
    .gift{
        .content {
            .el-form--inline {
                width: 650px;

                .el-form-item__label {
                    font-size: 12px;
                    width: 60px;
                }
            }

        }
        .gift-dialog {
            .el-dialog__body {
                .avatar-uploader, .avatar-uploader-icon, .avatar {
                    width: 120px;
                    height: 120px;
                    line-height: 120px;
                }
                padding-bottom: 100px;
            }
        }
    }

</style>
