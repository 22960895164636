<template>
  <div class="newsPush">
    <div class="title-top bg-f mb-5 topDiv" >
      <el-tabs v-model="active">
        <el-tab-pane name="1">
          <span slot="label">商户通知</span>
        </el-tab-pane>
        <el-tab-pane name="2">
          <span slot="label">活动通知</span>
        </el-tab-pane>
        <el-tab-pane name="3">
          <span slot="label">订单通知</span>
        </el-tab-pane>
      </el-tabs>
    </div>
    <div class="bg-f p-30">
      <MerchantItem v-if="active == 1"/>
      <EventsItem v-else-if="active == 2"/>
      <OrderItem v-else/>
   </div>
  </div>
</template>

<script>
import MerchantItem from './messageItem/merchantItem';
import EventsItem from './messageItem/eventsItem';
import OrderItem from './messageItem/orderItem';

export default {
  name: 'newsPush',
  components: {
   MerchantItem,
   EventsItem,
   OrderItem
  },
  data(){
    return {
      count: 0, // 待审核角标
      active: '1',
      info:{},
    }
  },
  mounted(){
  },
  activated(){
   
  },
  computed: {

  },
  methods: {
    
  }
}
</script>

<style lang="less">
 .newsPush{
  .topDiv{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 20px;
    box-sizing: border-box;
  }
  .el-tabs__header{
    padding-left: 0;
  }
  .link{
    display: block;
    margin: 10px;
  }
  .selectDiv{
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    .inputDiv{
      box-sizing: border-box;
      width: 150px;
      text-align: right;
      padding: 0 10px 11px 0;
      height: 32px;
      line-height: 32px;
      .el-input{
        width: 110px;
      }
    }
  }
  .batchDiv{
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
 }
</style>