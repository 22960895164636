<template>
	<div id="messageInfo" class="width-1280 bg-f pb-50">
		<!--内容为空时显示-->
		<div class="countBlank mt-50 ml-40" v-if="!messageInfo || messageInfo == ''">
			<img src="../assets/empty.png" alt="" />
			<div class="color-danger mt-30">
				<router-link :to="{path:basePath + '/shop/shopIndex'}"><span class="color-danger"> 啥都没有，去首页看看 ></span></router-link>
			</div>
		</div>

		<div v-else class="w-1100">
			<div class="title tac font-20 mt-20 mb-20">{{messageInfo.newsTitle}}</div>
			<div v-html="messageInfo.newsContext" class="editimg"></div>
		</div>
	</div>
</template>

<script>
	import { axiosApi,basePath } from "../api/api";
	export	default{
		name: 'searchLR',
		data(){
			return{
				messageInfoInfoId: null,
				messageInfo: null,
				basePath: basePath,
				isloginUser: false
			}
		},
		methods:{
			getMessageInfo(){
				let api = this.isloginUser ? '/foundation/news/selectInfo' : '/unlogin/foundation/news/selectInfo';
				axiosApi(api, {id: this.messageInfoInfoId}, (res) => {
					// console.log(res)
					this.messageInfo = res.data.data;
				}, 1, 10,(res)=>{
					this.$message({
			          message: res.data.message,
			          type: 'info',
			          duration: 1000
			       });
				})
			},
			changeHeight() {
				var height = document.documentElement.clientHeight;
				document.getElementById("messageInfo").style.minHeight = height - 390 + "px";

			},
		},
		mounted(){
			this.$nextTick(()=> {
				this.isloginUser = !sessionStorage.getItem('loginName') ? false : true;
				this.messageInfoInfoId = this.$route.params.messageInfoInfoId;
				this.getMessageInfo();
				this.changeHeight();
			})
		}
	}
</script>

<style lang="less">
	#messageInfo{
		.countBlank{
			width: 1028px;
			height: 300px;
			padding-top: 100px;
			text-align: center;
		}
		.w-1100{
			width: 1100px;
		}
		.editimg{
			word-break: break-all;
			img{
				max-width: 1100px;
			}
		}
	}
</style>