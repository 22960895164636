import { axiosApi, basePath } from "./api";

// 获取商户通知
export function getNotice (data={}) {
  return new Promise((resolve, reject) => {
    axiosApi('/trader/currencyNoticeRecord/employee/page', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
}


// 获取活动通知列表
export function getActivityPage (data={}) {
  return new Promise((resolve, reject) => {
    axiosApi('/trader/activityNoticeRecord/employee/page', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
}

// 获取订单信息通知列表
export function getOrderNoticePage (data={}) {
  return new Promise((resolve, reject) => {
    axiosApi('/trader/orderNoticeRecord/employee/page', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
}


// 删除商户通知
export function delNotice (data={}) {
  return new Promise((resolve, reject) => {
    axiosApi('/trader/notice/del', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
}

// 已读商户通知
export function readNotice (data={}) {
  return new Promise((resolve, reject) => {
    axiosApi('/trader/notice/read', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
}
