import {axiosApi,basePath} from '../../api/api.js'
import {Notification} from 'element-ui'
import m from '../../main.js'

const activity = {
    state: {
       cutlist: '',
       sentlist: '',
       cutid: '',
       sentid: '',
       alert: '',
       updateloading: false,
       marketingCategory: '1',//营销分类 '1'是B2B营销  '2'酒店营销
    },
    mutations: {
        changeMarketingCategory(state, payload){// 修改 营销分类
            state.marketingCategory = payload
        },
        writecutid(state, payload) {
            state.cutid = payload
        },
        writesentid(state, payload) {
            state.sentid = payload
        },
    },
    actions: { 
       //满赠活动列表 
       queryactivitypage({state}, payload) {
           const api = '/marketing/activityPage/page'
           let newbody = Object.assign({
               sellerId: sessionStorage.getItem('traderId')
           },payload.data)
           axiosApi(api, newbody, (res)=>{
               // if(payload.data.activityType == '1') {
                  state.sentlist = res.data.data
               // } else {
               //    state.cutlist = res.data.data
               // }
                
            },payload.currentPage, 10)
       },
        //更新活动满减
        updateactivitycut({state}, payload) {
            state.updateloading = true
            const api = '/marketing/activityCut/update'
            let body = {}
            let newbody = Object.assign({},body, payload.data)
            axiosApi(api, newbody, (res)=>{
                Notification.success({
                    message: '修改活动成功,即将跳转到活动列表页面'
                })
                setTimeout(function() {
                    state.updateloading = false
                    m.$router.push(basePath + '/activity/3')
                }, 1000)
            },0 , 10,(res)=>{
                state.updateloading = false
                Notification.warning({
                    message: '修改活动失败,' + res.data.message
                })
            })
        },
        //更新活动满赠
        updateactivitysent({state}, payload) {
            state.updateloading = true
            const api = '/marketing/activityGift/update'
            let body = {
                
            }
            let newbody = Object.assign({},body, payload.data)
            axiosApi(api, newbody, (res)=>{
                Notification.success({
                    message: '修改活动成功,即将跳转到活动列表页面'
                })
                setTimeout(function() {
                    state.updateloading = false
                    m.$router.push(basePath + '/activity/2')
                }, 1000)
            }, 0 , 10, (res)=>{
                state.updateloading = false
                Notification.warning({
                    message: '修改活动失败,' + res.data.message
                })
            })
        },
    },
    getters: {
       
    }
}

export default activity