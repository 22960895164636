<template>
    <div class="messageTiTleBox"> 
        <div class="title-tabs">
            <ul class="clearfix">
                <div class="el-badge item">
                    <li :class="{active : type === 1}">
                        <span @click="open('newsPush')">消息推送</span>
                        <div :class="{'under-line' : type === 1}"></div>
                    </li>
                </div>
                <div class="vertical-line"></div>
                <div class="el-badge item">
                    <li :class="{active : type === 2}">
                        <span @click="open('smsPush')">短信推送</span>
                        <div :class="{'under-line' : type === 2}"></div>
                    </li>
                </div>
                <div class="vertical-line"></div>
                <div class="el-badge item">
                    <li :class="{active : type === 3}">
                        <span @click="open('historicalRecord')">历史记录</span>
                        <div :class="{'under-line' : type === 3}"></div>
                    </li>
                </div>
                <div class="vertical-line"></div>
                <div class="el-badge item">
                    <li :class="{active : type === 4}">
                        <span @click="open('hotelMsgManage')">活动管理</span>
                        <div :class="{'under-line' : type === 4}"></div>
                    </li>
                </div>
                <div class="vertical-line"></div>
            </ul>
            <div class="btnBox">
                <el-button id="el-button" v-if="type != 4"  @click="open('setOrder')">订单消息设置</el-button>
            </div>
        </div>
    </div>
</template>

<script>


export default {
    props : {
        type : {
            default : 1,
            type : Number,
            required : true,
        },
    },
    data: function() {
        return ({
        })
    },
    mounted: function() {

    },
    methods: {
        open : function(url){
            this.$router.push(`/customer/${url}`);
        },
    },
}
</script>

<style lang="less">

.messageTiTleBox {
    .btnBox{
        float: right;
        #el-button{
            padding: 8px;
            width: 100px;
            width: initial;
        }
    }
}

</style>

