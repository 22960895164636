<template>
	<div id="articleDetails">
		<div class="title-top bg-f">
			{{titleTop}}
		</div>
		<div class="main bg-f mt-5 pb-50 pt-30 pl-50">
			<el-form size="large" :model="form" :rules="rules" ref="form" label-width="110px">
				<el-form-item label="新闻标题:" prop="name">
					<el-input size="medium" type="textarea" style="width: 500px;" :autosize="{ minRows: 1, maxRows: 2}" v-model.trim="form.name" auto-complete="off" placeholder="请输入标题" :maxlength="50"></el-input>
				</el-form-item>
				<el-form-item label="新闻摘要:" prop="outline" >
						<el-input type="textarea" v-model.trim="form.outline" style="width: 500px;" :autosize="{ minRows: 2, maxRows: 4}" placeholder="请输入文章摘要"></el-input>
				</el-form-item>
				<el-form-item label="新闻分类:" prop="classifyId" clearable>
						<el-select @change="handlePlatForm" v-model.trim="form.classifyId" placeholder="请选择">
								<el-option
												v-for="item in classifyList"
												:key="item.id"
												:label="item.classifyName"
												:value="item.id">
								</el-option>
						</el-select>
				</el-form-item>
				<el-form-item label="发布日期:" required>
						<el-date-picker
										v-model="form.issueDate"
										type="date"
										placeholder="选择发布时间">
						</el-date-picker>
				</el-form-item>

				<el-form-item label="新闻首图:" prop="image">
					<div :class="{add:!isAdd,noAdd:isAdd}">
						<el-upload action="/sunrise-gateway/oss/ossUpload?bucket=sunrise-official" 
						list-type="picture-card" 
						:limit="1"
						:on-success="handleAvatarSuccessList" 
						:on-preview="handlePictureCardPreview"
						:on-exceed="exceedInfo"
						:file-list="form.image"
						:on-remove="handleRemove">
							<i class="el-icon-plus"></i>
						</el-upload>
						<el-dialog :visible.sync="dialogVisible" size="tiny">
								<img width="100%" :src="dialogImageUrl" alt="">
						</el-dialog>
						<p>提示：宽度700PX ,高度不限</p>
					</div>
				</el-form-item>
				<el-form-item class="ue-editor" label="新闻内容:" prop="illustrationText">
					<ueditor ref="ueeditor" v-model="form.illustrationText"></ueditor>
				</el-form-item>
				<el-form-item label=" ">
					<el-button @click="ensure" type="danger" :loading='Loading'>确 定</el-button>
				</el-form-item>
			</el-form>
		</div>
	</div>
</template>

<script>
	import { axiosApi, basePath1} from "../../api/api"
	import { getNewsDetail, getNewsClassList } from "~/api/news"

	
	export default {
		data() {
			return {
               
				form:{
					id:null,
					name:'',//文章名称
					classifyId:'',//分类id
					pictureFileUrl:'',//文章首图
					issueDate:'',//发布日期
					illustrationText:'',//文章内容
					outline:'',//文章摘要
					image:[],
        },
				classifyList:[],

				Loading: false,
				currentPage: 1,
				total: 0,
				rules: {
					name: [{
						required: true,
						message: '请输入标题'
					}, {
						max: 50,
						message: '请输入50个以内的字符'
					}],
					classifyId:[
							{ required: true, message: '请选择文章分类', trigger: 'change' }
					],
					illustrationContent:[
							{  required: true, message: '请输入文章详情', trigger: 'blur' }
					],
					outline:[
							{ required:true,message:'请输入文章摘要',trigger:'blur' }
					],

					image: [{
						required: true,
						type: "array",
						message: '请上传图片',
					}],
			

				},
				new: false, //新建or编辑
				
				isAdd: false,
				titleTop: '编辑文章',
				dialogImageUrl:null,//图片预览url
				dialogVisible: false,
			}
		},
		methods: {
			handlePlatForm(val){
						console.log(val)
				},
			
			ensure() { //新建&&更新s
				if(!this.$route.query.id) { //新建
					setTimeout(() => {
						this.form.illustrationText = this.$refs.ueeditor.getContent()
						this.$refs.form.validate((valid) => {
							if(valid) {
								let postData1 = {
									companyTraderId: sessionStorage.getItem('traderId'),
									name: this.form.name,
									outline: this.form.outline,
									classifyId: this.form.classifyId,
									pictureFileUrl: this.form.image[0].url,
									illustrationText: this.form.illustrationText,  
									issueDate:this.form.issueDate,    
								}
								axiosApi('/official/article/add', postData1, res => {
									this.Loading = true
									setTimeout(() => {
										this.$message({
										type: 'success',
										message: '保存成功！',
										duration: 3000
									});
										this.$router.push({
											path: basePath1 + '/article'
										})
									}, 400)
								})

							} else {
								return false
							}
						})
					}, 0)
				} else { //编辑更新
					setTimeout(() => {
						this.form.illustrationText = this.$refs.ueeditor.getContent()
						this.$refs.form.validate((valid) => {
							if(valid) {
								let postData2 = {
									id:this.form.id,
									companyTraderId: this.form.companyTraderId,
									name: this.form.name,
									outline: this.form.outline,
									classifyId: this.form.classifyId,
									pictureFileUrl: this.form.image[0].url,
									illustrationText: this.form.illustrationText,  
									issueDate:this.form.issueDate,    
								}

								axiosApi('/official/article/update', postData2, res => {
									this.Loading = true
									setTimeout(() => {
										this.$message({
										type: 'success',
										message: '保存成功！',
										duration: 3000
									});
										this.$router.push({
											path: basePath1 + '/article'
										})
									}, 400)
								})
							} else {
								return false
							}

						})

					}, 0)
				}
			},
			getClassList(){
				getNewsClassList({companyTraderId:sessionStorage.traderId}).then(res=>{
					this.classifyList = res
				}).catch(err=>{

				})
			},
			getInfo(id){
				getNewsDetail({id:id}).then(res=>{
					this.form = res
					this.form.image=[{
						url:res.pictureFileUrl
					}]
				}).catch(err=>{

				})
			},
			exceedInfo(err){
				this.$message({
					message: '最多上传一张！',
					duration: 3000
				});
				console.log(err,'err')
			},
			handleRemove(file, filelist) { //照片删除
				this.isAdd = false
				this.form.image = [];
				this.$refs.form.validateField("image"); //图片部分表单字段验证
			},
			handlePictureCardPreview(file) { //照片预览
				this.dialogImageUrl = file.url;
				this.dialogVisible = true;
			},
			handleAvatarSuccessList(file) { //成功上传照片
				console.log(file,'xxxxx')
				this.form.image.push({
					url: file.data[0]
				})
				if(this.form.image.length != 0) {
					this.isAdd = true;
				}
				this.$refs.form.validateField("image"); //图片部分表单字段验证
			},
		},
		mounted() {
			this.$nextTick(() => {
				this.getClassList()
				if(this.$route.query.id){
					this.titleTop = '编辑新闻'
					this.getInfo(this.$route.query.id)
				}else{
					this.titleTop = '新增新闻'
				}

				// if(this.$route.query.isNew == 0) {
				// 	this.form = JSON.parse(sessionStorage.form)
				// 	this.form.image=[{
				// 		url:this.form.pictureFileUrl
				// 	}]
				// 	console.log(this.form,'this.form');
				// 	this.isAdd = true
				// } else if(sessionStorage.form) {
				// 	sessionStorage.removeItem("form");
				// }
				
				// this.titleTop = this.$route.query.titleTop
			})
		},
		computed: {

		}
	}
</script>

<style lang="less">
	#articleDetails {
        .el-input__suffix{
            right: 30px;
        }
		.noAdd {
			.el-upload.el-upload--picture-card {
				display: none;
			}
		}
		.add {
			.el-upload.el-upload--picture-card {
				display: inline-block;
			}
		}
		.ue-editor {
			transform: translateX(0);
		}
	}
</style>