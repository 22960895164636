<template>
	<div id="tasting" class="">
		<imgview :visible="imgsvisible" :imgs="showimgs" :startindex="startindex" @close="imgsvisible=false"></imgview>
		<div class="title-top bg-f mb-5">
			<div class="clearfix">
				<el-breadcrumb class="fl">
					<el-breadcrumb-item>{{taskName}}</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
		</div>
		<div class="main bg-f pb-50">
			<el-form :inline="true" :model="form" label-width="90px" class="clearfix pl-20 pt-30">
				<div class="fl width-70p">
					<el-form-item label="报备时间">
						<ys-daterange-picker v-model="form.timerange" type="datetime" :endDisabledDate="new Date()"/>
					</el-form-item>
					<el-form-item label="部门">
						<el-cascader :props="apartmentProps" expand-trigger="hover" :options="options" v-model="form.apartment" @change="handleChange" change-on-select clearable :disabled="isDepartmentLeader == 0 && isAdmin == 0">
						</el-cascader>
					</el-form-item>
					<el-form-item label="姓名">
						<el-input v-model.trim="form.managerName" :disabled="isDepartmentLeader == 0 && isAdmin == 0"></el-input>
					</el-form-item>
					<el-form-item label='状态'>
						<el-select v-model="form.taskStatus" placeholder="请选择" clearable>
							<el-option v-for="item in statuslist" :label="item.label" :value="item.value" :key="item.value">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label='任务类型'>
						<el-select v-model="form.taskTypeId" placeholder="请选择" clearable>
							<el-option v-for="item in eventtypelist" :key="item.id" :label="item.taskTypeName" :value="item.id">
							</el-option>
						</el-select>
					</el-form-item>
				</div>
				<el-button type="info" class="fl ml-50" @click='search'>搜索</el-button>
			</el-form>
			<div class="clearfix mr-50 pr-10">
				<el-button v-if='showButton("EXPORT",200024)' class='fr ml-10' type="danger" @click="exporttable">导出</el-button>
			</div>
			<el-table :data="taskMeetingList" v-loading="Loading" class='ml-30 mt-10 table-default mr-30' @selection-change="handleSelectionChange" stripe>
				<el-table-column type="selection"></el-table-column>
				<el-table-column label="序号" min-width="80" fixed>
					<template slot-scope="scope">
						<div>{{scope.$index+10*(currentPage-1)+1}}</div>
					</template>
				</el-table-column>
				<el-table-column label="填报人" :min-width="tablewidth1">
					<template slot-scope="scope">
						<div>{{scope.row.managerName}}</div>
					</template>
				</el-table-column>
				<el-table-column label="填报时间" :min-width="tablewidth2">
					<template slot-scope="scope">
						<div>{{scope.row.taskCreateTime | timenyr}}</div>
					</template>
				</el-table-column>
				<el-table-column :label="a.na" min-width="160" v-for="a in tablehead" :key="a.num">
					<template slot-scope="scope">
						<div v-if="typeof(scope.row[a.num]) == 'object'" @click="imgview(scope.row[a.num])">
							<img v-if="a" :src="a" alt="" v-for="(a, index) in scope.row[a.num].slice(0,2)" :key="index">
							<img v-else src="../../assets/goods.jpg">
						</div>
						<div v-else>
							{{scope.row[a.num]}}
						</div>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination class="tar mt-20 mr-50" :current-page="currentPage" :page-size='10' :total='total' layout="prev , pager, next, jumper" v-if="total>0" @current-change="handlePageChange">
				<!-- 分页组件 -->
			</el-pagination>
			<form ref="form" action='/sunrise-gateway/export/sales/web/task' style="display: none">
				<input type="text" name="startRow" v-model="a">
				<input type="text" name="startCol" v-model="b">
				<!--<input type="text" name="modelName" v-model="c">-->
				<input v-if='form.managerName1 && exportType==0' type="text" name="managerName" v-model="form.managerName1">
				<input v-if='form.taskStatus1 && exportType==0' type="text" name="taskStatus" v-model="form.taskStatus1">
				<input v-if='form.taskTypeId1 && exportType==0' type="text" name="taskTypeId" v-model="form.taskTypeId1">
				<input v-if='form.apartment1 && exportType==0' type="text" name="departmentId" v-model="form.apartment1">
				<input v-if='form.startDate1 && exportType==0' type="text" name="startDate" v-model="form.startDate1">
				<input v-if='form.endDate1 && exportType==0' type="text" name="endDate" v-model="form.endDate1">
				<input type="text" name="fileName" v-model="fileName">
				<input type="text" name="modelName" v-model="modelName">
				<input v-if='exportType==1' type="text" name="taskIds" v-model="form.taskIdList">
			</form>
		</div>
	</div>
</template>
<script>
	import { axiosApi, basePath } from "../../api/api";
	// import YsDaterangePicker from "~/components/ys-daterange-picker";
	export default {
		// components: {YsDaterangePicker},
		data() {
			return {
				imgsvisible: false,
				showimgs: [],
				startindex: 0,
				statuslist: [{
						value: 1,
						label: '进行中',
					},
					{
						value: 2,
						label: '已完成',
					}
				],
				a: 0,
				b: 0,
				c: 'empty',
				options: [],
				eventtypelist: [],
				form: {
					timerange: [],
					apartment: null,
					managerName: '',
					statuslist: '',
					taskTypeId: '',
					taskStatus: '',
					apartment1: null,
					managerName1: '',
					statuslist1: '',
					taskTypeId1: '',
					taskStatus1: '',
					startDate1: '',
					endDate1: '',
					taskIdList:[],
				},
				apartmentProps: {
					value: 'id',
					label: 'text',
					children: 'sub'
				},
				currentPage: 1,
				total: '',
				Loading: false,
				taskMeetingList: [],
				selectIdNameMap: [],
				typeIdxxx: null,
				modelName: 'sales.web.task',
				fileName: '',
				multipleSelection :[],//批量选中行
				exportType:0,//是否选择导出数据
				isDepartmentLeader:null
			}
		},
		computed: {
			isAdmin(){
				return sessionStorage.getItem('isAdmin')
			},
			taskName() {
				var a = Object.assign({}, this.eventtypelist.filter((a) => {
					return a.id == this.typeIdxxx
				})[0])

				return a.taskTypeName ? a.taskTypeName : '任务列表'
			},
			tablehead() {
				let a = []
				for(let m in this.selectIdNameMap) {
					a.push({
						num: m,
						na: this.selectIdNameMap[m]
					})
				}
				return a
			},
			postData() {
				return {
					managerName: this.form.managerName ? this.form.managerName : null,
					taskTypeId: this.form.taskTypeId ? this.form.taskTypeId : null,
					taskStatus: this.form.taskStatus ? this.form.taskStatus : null,
					startDate: this.form.timerange[0] ? Date.parse(this.form.timerange[0]) : null,
					endDate: this.form.timerange[1] ? Date.parse(this.form.timerange[1]) : null,
					departmentId: this.form.apartment ? this.form.apartment.slice(-1)[0] : null,
				}
			},
			tablewidth1() {
				if(this.tablehead.length < 4) {
					return parseInt(858 - 160 * this.tablehead.length) / 2
				} else {
					return 120
				}
			},
			tablewidth2() {
				if(this.tablehead.length < 4) {
					return parseInt(858 - 160 * this.tablehead.length) / 2
				} else {
					return 160
				}
			}
		},
		methods: {
			imgview(imgs) {
				this.showimgs = imgs
				this.imgsvisible = true
			},
			exporttable() {
				this.getTaskId()//判断是否打钩选择
				
				setTimeout(() => {
					if(this.form.taskIdList.length > 0){
						this.exportType = 1
					}else{
						this.exportData()
						this.exportType = 0
					}
					setTimeout(()=>{
						if(this.taskMeetingList.length > 0) {
							//去除名字首尾空格
							if(this.form.managerName1){
								this.form.managerName1 = this.form.managerName1.trim()
							}
							setTimeout(()=>{
								this.$refs.form.submit()
								setTimeout(()=>{
									this.form.taskIdList = []
								},0)
								
							},0)
						}else{
							this.$message({
								type: 'info',
								message: '暂无数据可导出!',
								duration: 1000
							});
						}
					},0)
				}, 0)
			},
			handleChange(val) {

			},
			search() {
				if(this.form.apartment == undefined){
					this.form.apartment = []
				}
				sessionStorage.setItem('tastingConditions',JSON.stringify(this.form))
				this.typeIdxxx = this.form.taskTypeId
				this.querytabledata(1)
			},
			handlePageChange(val) {
				this.currentPage = val
				this.querytabledata()
			},
			handleSelectionChange(val) {
				this.multipleSelection = val
			},
			getTaskId() {
				let _this = this
				this.multipleSelection.forEach(function(item, index) {
					_this.form.taskIdList.push(item.taskId)
				})
			},
			watchRow(index, data) {
				this.$router.push({ path: basePath + '/tastingDetail', query: { id: data[index].id } })
			},
			querytasktype() {
				axiosApi('/sales/taskType/web/selectList', {}, (res) => {
					this.eventtypelist = res.data.data
				})
			},
			queryapartment() {
				axiosApi('/sales/clockTrack/web/departments', {
					traderId: sessionStorage.getItem('traderId')
				}, (res) => {
					this.options = res.data.data
					if(this.isDepartmentLeader == 0 && this.isAdmin == 0) {
						this.form.apartment = sessionStorage.getItem('departmentPath').split(',').map((a)=>{return Number(a)})
					} 
				})
			},
			querytabledata(x) {
				this.Loading = true
				axiosApi('/sales/task/web/selectMeetingList', this.postData, (res) => {
					this.Loading = false
					var m = res.data.data.mapList
					m.map((a, b) => {
						for(let i in a) {
							if(typeof(a[i]) == 'string' && a[i].indexOf('[') !== -1 && a[i].indexOf(']') !== -1 ) {
								a[i] = JSON.parse(a[i])
							}
						}
					})
					this.taskMeetingList = m
          this.selectIdNameMap = res.data.data.selectIdNameMap
					this.total = res.data.data.totalCount
				}, x ? x : this.currentPage, 10)
			},
			//点击导出按钮
			exportData() {
				this.form.managerName1 = this.form.managerName
				this.form.taskTypeId1 = this.form.taskTypeId
				this.form.taskStatus1 = this.form.taskStatus
				this.form.startDate1 = this.form.timerange[0] ? Date.parse(this.form.timerange[0]) : null
				this.form.endDate1 = this.form.timerange[1] ? Date.parse(this.form.timerange[1]) : null
				if(this.form.apartment) {
					this.form.apartment1 = this.form.apartment.slice(-1)[0]
				}
			},
		},
		mounted() {
            this.resolveIsSalesDepartmentLeaderWithCache(res=>{
                console.log(res)
                this.isDepartmentLeader = res
            });

			this.$nextTick(() => {
				let conditions = sessionStorage.getItem('tastingConditions')
				if(conditions){
					conditions = JSON.parse(conditions)
					this.form.timerange = conditions.timerange ? conditions.timerange : []
					this.form.apartment = conditions.apartment ? conditions.apartment : []
					this.form.managerName =  conditions.managerName ? conditions.managerName : null
					this.form.taskStatus = conditions.taskStatus ? conditions.taskStatus : null
					this.form.taskTypeId = conditions.taskTypeId ? conditions.taskTypeId : null
					this.typeIdxxx = conditions.taskTypeId ? conditions.taskTypeId : null
				}
				if(this.isDepartmentLeader == 0 && this.isAdmin == 0) {
					this.form.managerName = sessionStorage.getItem('employeeName')
				}  
				this.querytasktype()
				this.queryapartment()
				this.querytabledata(1)
				let d = new Date();
				let str = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate();
				this.fileName = '报备列表_' + str + '.xls'
			})
		}
	}
</script>
<style lang="less">
	#tasting {
		.cell {
			&>div {
				overflow: hidden;
				img {
					float: left;
				}
			}
		}
	}
</style>