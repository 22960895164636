import superBGoods from '~/views/superB/superBGoods.vue' //商品管理
import superBManager from '~/views/superB/superBManager.vue' //超级B管理
import superBDetails from '~/views/superB/superBDetails.vue' //超级B详情
import superBAudit from '~/views/superB/superBAudit.vue' //超级B审核
import superBOrder from '~/views/superB/superBOrder.vue' //超级B订单列表
import superBOrderDetails from '~/views/superB/superBOrderDetails.vue' //超级B订单列表
import superBAnalyst from '~/views/superB/superBAnalyst.vue' //超级B业务统计
import superBHotGoods from '~/views/superB/superBHotGoods.vue' //超级B 热门商品
import superBHot from '~/views/superB/superBHot.vue' //超级B 热门超级B

import {basePath} from '../api/api'

export const superBFirstRouters = [];
export const superBHomeRouters = [ //详情

];
export const superBHomeLogoRouters = [
        {
            path: basePath + '/superB/superBDetails/:traderId?/:id?/:back?',  //超级B详情
            component: superBDetails,
            name:'superBDetails',
            text:'超级B详情'
        },
        {
            path: basePath + '/superB/superBAudit',   //超级B审核
            component: superBAudit,
            name:'superBAudit',
            text:'超级B审核'
        },
        {
            path: basePath + '/superB/superBOrderDetails/:orderSn?/:type?/:back?',   //超级B订单详情  type 1 主订单 2 子订单
            component: superBOrderDetails,
            name:'superBOrderDetails',
            text:'超级B订单详情'
        },
        {
            path: basePath + '/superB/superBHotGoods',   //超级B 热门商品
            component: superBHotGoods,
            name:'superBHotGoods',
            text:'热门商品'
        },
        {
            path: basePath + '/superB/superBHot',   //超级B 热门超级B
            component: superBHot,
            name:'superBHot',
            text:'热门超级B'
        }
    ]
;
export const superBHomeMenuRouters = [ //list
    {
        path: basePath + '/superB/superBGoods',  //超级B 商品列表
        component: superBGoods,
        name:'superBGoods',
        text:'商品列表'
    },
    {
        path: basePath + '/superB/superBManager',  //超级B 管理列表
        component: superBManager,
        name:'superBManager',
        text:'管理列表'
    },
    {
        path: basePath + '/superB/superBOrder',  //超级B 订单列表
        component: superBOrder,
        name:'superBOrder',
        text:'订单列表'
    },
    {
        path: basePath + '/superB/superBAnalyst',  //超级B业务统计
        component: superBAnalyst,
        name:'superBAnalyst',
        text:'超级B业务统计'
    }


];
export const superBCarLogoRouters = [];
