<template>
	<div id="groupCompanyDetails" class="bg-f">
		<div class="box-con bdt">
			<div class="order-info">
				<div class="order-info-tit pl-20">基础信息</div>
				<div class="order-info-con clearfix">
					<div class="order-info-conL fl">
						<el-form v-if='form.auditStatus == 2 || form.auditStatus == 3' class='none' label-width='130px'>
							<el-form-item label="企业名称:" class=" mr-50">
								<span class="ml-10">{{form.companyName}}</span>
							</el-form-item>
						</el-form>
						<el-form v-if='form.auditStatus == 1' :model='form' :rules="rules" ref="form1" label-width='130px'>
							<el-form-item label="企业名称:" class=" mr-50" prop="companyName">
								<el-input v-model.trim='form.companyName' :maxlength="30"></el-input>
							</el-form-item>
						</el-form>
						<div>
							<label>企业人数:</label>
							<span class="ml-10">{{form.num}}</span>
						</div>
						<div>
							<label>平均收入:</label>
							<span class="ml-10">{{form.averageRevenue | fmoney}} 元</span>
						</div>
					</div>
				</div>
			</div>
			<div class="order-info mt-20" v-if='form.auditStatus != 3'>
				<div class="order-info-tit pl-20 clearfix"><span class="fl">登记信息</span><span class="fr color-6 mr-25">审核时需补充填写以下信息,确保企业信息真实存在</span></div>
				<div class="order-info-con">
					<div class="order-info-conL">
						<el-form v-if='form.auditStatus == 2' :model='form' :rules="rules" ref="form" label-width='130px' class='none'>
							<div class="clearfix">
								<el-form-item label="法人名称:" class=" mr-50" prop="legalName">
									<span class="ml-10">{{form.legalName}}</span>
								</el-form-item>
								<el-form-item label="注册资金:" class=" mr-50" prop="registeredFund">
									<span class="ml-10">{{form.registeredFund | fmoney}}元</span>
								</el-form-item>
								<el-form-item label="经营范围:" class="mr-50" prop="businessScope">
									<div class="range ml-10">{{form.businessScope}}</div>
								</el-form-item>

							</div>
						</el-form>
						<el-form v-if='form.auditStatus == 1' :model='form' :rules="rules" ref="form" label-width='130px'>
							<div class="clearfix">
								<el-form-item label="法人名称:" class=" mr-50" prop="legalName">
									<el-input v-model.trim='form.legalName' class='mb-10'></el-input>
								</el-form-item>
								<el-form-item label="注册资金:" class=" mr-50" prop="registeredFund">
									<el-input v-model.trim='form.registeredFund' class='mb-10 mr-10'></el-input>元
								</el-form-item>
								<el-form-item label="经营范围:" class="mr-50" prop="businessScope">
									<el-input type="textarea" v-model.trim='form.businessScope' auto-complete="off" placeholder="经营范围"></el-input>
								</el-form-item>
							</div>
						</el-form>
						<div class="mt-40 mb-40" style="margin-left: 163px;">
							<el-button v-if='form.auditStatus == 1' class='mr-60' type="info" @click="rejectVisible=true">驳回</el-button>
							<el-button v-if='form.auditStatus == 1' type="danger" @click='handleAudited'>审核通过</el-button>
							<el-button v-if='form.auditStatus == 2' @click='goPage'>返回</el-button>
						</div>
					</div>
				</div>
			</div>
			<div v-if='form.auditStatus == 3' class="false mt-20 clearfix">
				<label class="fl">未通过理由：</label>
				<div class="fl falseReson">{{form.rejectionReason}}</div>
			</div>
			<div v-if='form.auditStatus == 3' class="mt-40 mb-40">
				<el-button class='ml-60' @click='goPage'>返回</el-button>
			</div>
		</div>
		<!--驳回申请弹窗-->
		<el-dialog title='输入驳回申请理由' :visible.sync='rejectVisible'>
			<el-form class="pl-30 pr-30" :model="form" ref="rejectForm" :rules="rules">
				<el-form-item label="" prop='rejectionReason'>
					<el-input type='textarea' placeholder='请输入驳回申请的理由...' :autosize="{ minRows: 3 ,maxRows: 10}" v-model.trim='form.rejectionReason'></el-input>
				</el-form-item>
			</el-form>
			<div slot='footer'>
				<el-button type='danger' @click="rejectCommit">提 交</el-button>
				<el-button @click="rejectVisible = false">取 消</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import { axiosApi, basePath } from "../../api/api";
	export default {
		data() {
			return {
				/*规则*/
				rules: {
					rejectionReason: [
						{ required: true, message: '请输入驳回原因！' },
						{ max: 30, message: '不能超过30个字符！' }
					],
					companyName: [
						{ required: true, message: '请输入企业名称！' },
						{ max: 30, message: '不能超过30个字符！' },
						{ pattern: /^([A-Za-z]|[\u4E00-\u9FA5])+$/, message: '企业名称为汉字或字母' }
					],
					legalName: [
						{ min: 2, max: 6, message: '法人名称2-6位！' },
						{ pattern: /^([A-Za-z]|[\u4E00-\u9FA5])+$/, message: '法人名称为汉字或字母' }
					],
					registeredFund: [
						{ pattern: /^[0-9]\d{0,9}(\.\d{1,2})?$/, message: '注册资金支持小数点前10位，小数点后2位' }
					],
					businessScope: [
						{ max: 500, message: '最多可输入500个字符' }
					],
				},
				form: {
					rejectionReason: '',
					auditStatus: '',
					legalName: '',
					registeredFund: '',
					businessScope: '',
					companyName: ''

				},
				rejectVisible: false, //弹出框
				sendGoods: false,
				legalName: false,
				companyName: false,
				registeredFund: false
			}
		},
		mounted() {
			this.$nextTick(() => {
				this.achieve()
			})
		},
		methods: {
			//加载企业信息
			achieve() {
				const api = "/groupon/companyDetail/select";
				const body = {
					id: this.$route.params.id

				}
				axiosApi(api, body, this.callback, this.currentPage, 10)
			},
			callback(res) {
				this.form = res.data.data ? res.data.data : {};
				if(this.$route.params.sign == 2){
					this.form.auditStatus =2
				}

			},
			//审核
			audited() {
				const api = "/groupon/companyAuditStatus/update";
				const body = {
					id: this.$route.params.id,
					auditStatus: this.form.auditStatus,
					legalName: this.form.legalName,
					registeredFund: this.form.registeredFund,
					businessScope: this.form.businessScope,
					rejectionReason: this.form.rejectionReason,
					companyName: this.form.companyName,
				};
				axiosApi(api, body, (res) => {
					this.$message({
						type: 'success',
						message: '操作成功!',
						duration: 1000
					});
					this.$router.push(basePath + "/groupCompanyAudit");
				}, 1, 10, (res) => {
					this.$message({
						type: 'info',
						message: res.data.message,
						duration: 1000
					});
				})
			},
			//审核通过
			handleAudited() {
				const h = this.$createElement;
				this.$msgbox({
							title: '提示信息',
							message: h('p', { style: 'text-align:center' }, '是否确认审核通过？'),
							showCancelButton: true,
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							beforeClose: (action, instance, done) => {
								if(action === 'confirm') {
									done()
									this.$refs.form.validate((valid) => {
											if(valid) {
												this.$refs.form1.validate((valid) => {
														if(valid) {
															this.form.auditStatus = 2
															this.audited();
															// sessionStorage.setItem('groupCompanyAudit', 0);
														} else {
															return false
														}
													})

												}
												else {
													return false
												}
											})
									}
									else {
										done()
									}
									delete instance.$slots.default;
								}
							})
					},
					//驳回申请理由提交
					rejectCommit() {
						this.$refs.rejectForm.validate((valid) => {
							if(valid) {
								this.rejectVisible = false
								this.form.auditStatus = 3
								this.audited();
							} else {
								return false
							}
						})
					},
					//返回
					goPage() {
						this.$router.push(basePath + '/groupCompanyAudit')
					}
			}
		}
</script>

<style lang="less">
	#groupCompanyDetails {
		.mr-60 {
			margin-right: 60px;
		}
		.mr-25 {
			margin-right: 25px;
		}
		.bdt {
			border-top: 1px solid #eee;
		}
		.el-tabs__header {
			padding: 0;
			border-bottom: 1px solid #eee;
		}
		.box-con {
			padding: 20px;
			.order-info {
				border: 1px solid #ddd;
				box-sizing: border-box;
				.order-info-tit {
					height: 40px;
					line-height: 40px;
					background-color: #eee;
				}
				.order-info-con {
					.order-info-conL {
						width: 1000px;
						line-height: 30px;
						padding: 22.5px 0;
						label {
							width: 122px;
							display: inline-block;
							text-align: right;
						}
						textarea {
							width: 576px;
							min-height: 175px;
						}
						.range {
							width: 560px;
							word-wrap: break-word;
						}
					}
				}
			}
			.false {
				box-sizing: border-box;
				padding: 15px 25px 13px 30px;
				background-color: #FF4100;
				color: #FFFFFF;
				.falseReson {
					width: 890px;
					word-wrap: break-word;
				}
			}
			.el-dialog {
				min-height: 300px;
				.el-form-item__label {
					width: 140px;
				}
			}
			.sendGoods .el-form-item__error {
				margin-left: 140px;
			}
		}
		.el-form-item__error {
			padding: 0;
		}
		.none {
			.el-form-item {
				margin-bottom: 0;
			}
		}
	}
</style>