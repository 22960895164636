<template>
    <!--我的发票-->
    <div id="store-stock-invoice">
        <!--标题-->
        <div class="title-top bg-f clearfix">
            <div>我的发票</div>
        </div>

        <!--内容-->
        <div class="content bg-f mt-5">
            <div class="order-list" v-loading="loading">
                <div class="clearfix font-12 bg-e table_header color-3" style="display: flex;text-align: center">
                    <div class="fl" style="flex: 6">订单详情</div>
                    <div class="fl" style="flex: 1">发票金额</div>
                    <div class="fl" style="flex: 1">发票状态</div>
                    <div class="fl" style="flex: 2">操作</div>
                </div>
                <div class="table-item-default tac" v-if="invoicedata.length<=0">
                    <img src="../../../assets/empty.png" alt=""/>
                    <div class="color-9 mt-30"><span class="cursor"> 暂无数据 </span></div>
                </div>
                <div v-else>
                    <div class="table-item mt-20" v-for='(item,i) in invoicedata' :key="i+'an'">
                        <div class="clearfix table-item-top">
                            <span class='fl ml-20 color-9'>开票时间：{{item.gmtCreate | time}}</span>
                            <span class='fl ml-50'>订单编号： {{item.sn}}</span>
                            <span class="fr mr-20">{{item.SellerName}}</span>
                        </div>
                        <div class="table-item-content clearfix" style="display: flex;">
                            <div class=" clearfix" style="flex: 6">
                                <div class="bb clearfix" style="padding: 20px 60px 20px 20px;display: flex"
                                     v-for="(a,index) in item.orderItemVos" :key="index+'am'">
                                    <div class="fl" style="flex: 3">
                                        <img :src="a.image" class="fl mr-10"/>
                                        <p>{{a.skuName}}</p>
                                        <p class="color-9 mt-20">规格:1*{{a.specInfoNum}}</p>
                                    </div>
                                    <div class="fl pbl-20 tar " style="flex: 1">{{a.num}}</div>
                                </div>
                            </div>

                            <div class="bl bb tac pt-20" style="flex: 1">￥{{item.needPayMoney}}</div>
                            <div class="bl bb tac pt-20" style="flex: 1">
                                <p class="font-14">{{item.invoiceStatus == '0' ? '未开票' : '已开票'}}</p>
                            </div>
                            <div class="bl bb tac pt-20 pb-20" style="flex: 2;">
                                <div>
                                    <el-button v-if="item.orderInvoiceVo != null" class="ml-0"
                                               @click="handleInvoiceDetail(item.orderInvoiceVo, item.freightMoney)">发票详情
                                    </el-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <el-pagination class="tar mt-20" :current-page="currentPage" :page-size='10' :total='total'
                       layout="prev , pager, next, jumper" v-if="invoicedata.length>0" @current-change="handleit">
            <!-- 分页组件 -->
        </el-pagination>
        <!-- <addinvoice-hotel :info="invoiceinfo" :visible="dialogVisible" :yunfei="freightMoney"
                       v-on:close="dialogVisible = false">
        </addinvoice-hotel> -->
        <invoice
                :info="invoiceinfo"
                :yunfei="freightMoney"
                :visible="dialogVisible"
                v-on:close="dialogVisible = false">
        </invoice>
    </div>
</template>

<script>
    import {axiosApi, basePath} from '../../../api/api'
    import AddinvoiceHotel from "~/components/addinvoiceHotel";
    export default {
        components: {AddinvoiceHotel},
        data() {
            return {
                data: ['1'],
                dataTabs: ['全部发票', '未开票', '已开票'],
                isTitle: true, //控制 是商户的发票true ，还是供应商的发票false
                type: '0',
                total: 0,
                currentPage: 1,
                invoicedata: [],
                loading: false,
                freightMoney: '',
                dialogVisible: false,
                invoiceinfo: '',
            }
        },
        computed: {
            postData() {
                return {
                    traderType: '1',
                    buyerId: sessionStorage.getItem('traderId'),
                }
            }
        },
        methods: {
            queryinvoice() {
                this.loading = true
                const api = "/anon/order/invoice/query"

                axiosApi(api, this.postData, (res) => {
                    this.callback(res.data.data)
                }, this.currentPage, 10)
            },
            callback(res) {
                if (res) {
                    this.invoicedata = res.elements
                    this.total = res.totalCount
                } else {
                    this.invoicedata = []
                    this.total = 0
                }
                this.loading = false
            },
            handleInvoiceDetail(val, val2) { //跳转去发票详情页
                this.dialogVisible = true
                this.invoiceinfo = val ? val : {}
                this.freightMoney = val2 ? val2 : 0
                console.log(this.invoiceinfo,this.freightMoney)
            },
            handleTabs(e) {
                if (e.target.type) this.type = e.target.type // 改变 type的值
            },
            handleit(val) {
                this.currentPage = val
                this.queryinvoice()
            }
        },
        mounted() {
            this.$nextTick(() => {
                this.queryinvoice()
            })
        }
    }
</script>

<style lang="less">

    .title-tabs2 {
        margin-bottom: 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-right: 30px;

        ul {
            display: flex;
            align-items: center;
            padding: 22px 0 22px 30px;

            .item {
                display: flex;
                align-items: center;

                &:last-child {
                    .vertical-line {
                        display: none;
                    }
                }
            }

            li {
                font-size: 16px;
                color: #333;
                cursor: pointer;
                position: relative;

                &:hover {
                    color: #FF4201;
                }

                list-style-type: none;
            }

            li.active {
                color: #FF4201;
            }
        }

        .vertical-line {
            height: 16px;
            width: 2px;
            background: #BBB;
            margin: 0 15px;

        }

        .under-line {
            width: 50px;
            height: 2px;
            background: #FF4201;
            position: absolute;
            top: 28px;
            left: 50%;
            margin-left: -25px;
        }
    }

    #store-stock-invoice {
        .table_header{
            font-weight: bold;
            height: 45px;
            line-height: 45px;
            border: 1px solid #dddddd;
        }
        .title {
            padding: 30px;
            margin-bottom: 5px;
            box-sizing: border-box;
        }

        .content {
            padding: 30px 30px 66px 30px;
            box-sizing: border-box;

            .tip {
                border: 1px solid #DDD;
                height: 82px;
                padding: 20px;
                box-sizing: border-box;
                line-height: 16px;

                .tip-item {
                    margin-left: 25px;
                    margin-top: 10px;
                }
            }

            .otherTip {
                .el-form {
                    .el-form-item {
                        margin-bottom: 30px;
                        margin-right: 30px;
                    }

                    .el-form-item__label {
                        width: 58px;
                        font-size: 12px;
                    }

                    .el-form-item__inner {
                        font-size: 12px;
                    }
                }
            }

            .el-table .el-table__body-wrapper {
                display: none;
            }

            .el-table_1_column_1.is-leaf .cell {
                text-align: center;
            }

            .table-item {
                .bl {
                    border-left: 1px solid #DDDDDD;
                }

                border: 1px solid #DDDDDD;
                font-size: 14px;

                .table-item-content .pbl-20 {
                    padding: 0px 0px 20px 20px;
                }

                .table-item-top {
                    border-bottom: 1px solid #DDDDDD;
                    padding: 10px 0;

                    .table-item-top-checkbox {
                        margin-left: 14px
                    }
                }

                img {
                    width: 80px;
                    height: 80px;
                }
            }

            .table-item-default {
                height: 530px;
                padding-top: 120px;
            }
        }
    }
</style>