<template>
  <div class="ml-40 plr-45 mt-50 integral">
    <el-form :model="form" :rules="rules" ref="form" label-width="120px" class="demo-ruleForm">
      <el-form-item label="预估积分系数" prop="integration">
        <el-input v-model="form.integration"  @input="inputChange">
          <span slot="suffix">/ 倍 </span>
        </el-input>
      </el-form-item>
      <el-form-item label="是否限定时间" prop="timeLimit">
        <el-radio-group v-model="form.timeLimit" >
          <el-radio :label="true">是</el-radio>
          <el-radio :label="false">否</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="积分限定时间" v-if="form.timeLimit" prop="date">
        <el-date-picker
          v-model="form.date"
          type="daterange"
          align="right"
          size="mini"
          :picker-options="pickerOptions"
          value-format="timestamp"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          @change="setTime"
          :default-time="['00:00:00', '23:59:59']">
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="save">保存</el-button>
      </el-form-item>
    </el-form>  
  </div>
</template>

<script>
 import {axiosApi, basePath} from '~/api/api';
export default {
  name: 'integral',
  props: { skuInfo: Object },
  data(){
    return {
      form: {
        skuNo: null,
        integration: 0,
        timeLimit: false,
        integrationEffectiveStart: '',
        integrationEffectiveEnd: '',
        date: '',
      },
      rules: {
        integration: [
          {required: true, message: '请输入积分', trigger: 'blur' }
        ],
        date: [
          { type: 'array', required: true, message: '请选择积分有效时间', trigger: 'change' }
        ],
      },
      pickerOptions: {
        disabledDate(time){
          return time.getTime()<Date.now()-8.64e7
        }
      },
    }
  },
  watch:{
    'skuInfo':{
      handler:function(val,oldVal){
        if(val){
          this.initTime(val)
        }    
      },
      deep: true
    },
  },

  mounted(){
    this.$nextTick(()=>{
      this.initTime(this.skuInfo)
    })
  },
  methods: {
    initTime(val){
      this.form.skuNo = val.skuNo
      this.form.integration = val.integration
      this.form.timeLimit = val.timeLimit || false
      this.form.integrationEffectiveStart = val.integrationEffectiveStart
      this.form.integrationEffectiveEnd = val.integrationEffectiveEnd
      if(val.integrationEffectiveStart&&val.integrationEffectiveEnd){
        
        this.form.date = [new Date(val.integrationEffectiveStart).getTime(),new Date(val.integrationEffectiveEnd).getTime()]
      }else{
        this.form.date = ''
      }
    },
    inputChange() {
      var max = 100 // 积分系数最大100倍
      var val = this.form.integration.replace(/[^\d.]/g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3').replace(/^\./g, '')
      if((val-'')>max){
        val=max
      }
      this.form.integration = val
    },
    setTime(val){
      // 设置时间
      if(val){
        this.form.integrationEffectiveStart = val[0]
        this.form.integrationEffectiveEnd = val[1]
      }else{
        this.form.integrationEffectiveStart = null
        this.form.integrationEffectiveEnd = null
      }
    },
    save(){

      this.$refs['form'].validate((valid) => {
        if (valid) {
          const api = "/goods/sku/integr/set";
          const body = this.form;
          axiosApi(api, body, (res) => {
            this.$emit('changeSuccess')
            if(res.data.code==='000000'){
              this.$message({
                message: '保存成功！',
                type: 'success'
              });
            }
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
      
    },
  },
}
</script>

<style lang="less">
.el-date-range-picker__editor{
  width: 144px !important;
}
.integral{
  
  .el-input__icon{
    line-height: 24px !important;
  }
}
</style>