<template>
	<div id="groupProduct" class="pb-50">
		<div class='c'>
			<el-badge :value="count3" :max='99' class='count4' />
			<el-badge v-if='isOpenGoods == 1' :hidden="count1==0" :value="count1" :max='99' class='count2' />
			<el-badge v-if='isOpenGoods == 1' :hidden="count2==0" :value="count2" :max='99' class='count3' />
		</div>
		<div class="title-tabs bg-f black">
			<ul class="clearfix">
				<template v-for='(tab,index) in dataTabs' class="item" v-if='isOpenGoods == 1'>
					<li :type='index' :class="type==index?'active':''" @click='handleTabs'>
						{{tab}}
						<div :class="type==index?'under-line':''"></div>
					</li>
					<div class="vertical-line"></div>
				</template>
				<template v-for='(tab,index) in dataTabss' class="item" v-if='isOpenGoods == 0'>
					<li :type='index' :class="type==index?'active':''">
						{{tab}}
						<div :class="type==index?'under-line':''"></div>
					</li>
					<div class="vertical-line"></div>
				</template>
			</ul>
		</div>
		<div class="bg-f mt-5 pb-50">
			<el-form :inline="true" :model="formInlineData" label-width="90px" class="clearfix pl-20 pt-30 search" >
				<div class="fl width-70p">
					<el-form-item label="商品编码">
						<el-input v-model.trim="formInlineData.goodsNo"></el-input>
					</el-form-item>
					<el-form-item label="商品名称">
						<el-input v-model.trim="formInlineData.skuName" :maxlength="20"></el-input>
					</el-form-item>
					<el-form-item label="商品分类">
						<el-select v-model="formInlineData.id" clearable>
							<el-option :label='item.classifyName' :value="item.id" v-for='item in searchClassify' :key='item.id'></el-option>
						</el-select>
					</el-form-item>
				</div>
				<el-button type="info" class="fl ml-50" @click='achieve1(0)'>搜索</el-button>
			</el-form>
			<div class="clearfix mr-50 pr-10">
				<!--<span class="fr color-blue ml-10 cursor" @click='getInfo(2)'>批量导出</span>-->
				<span v-if='lower && showButton("DOWN",140003)' class="fr color-blue ml-10 cursor" @click='getInfo(2)'>批量下架</span>
				<span v-if='up && showButton("PUT",140003)' class="fr color-blue ml-10 cursor" @click='getInfo(1)'>批量上架</span>
			</div>
			<el-table :data="tableData" v-loading="Loading" class='ml-30 mt-10 table-default mr-30' @selection-change="handleSelectionChange" stripe>
				<el-table-column type="selection" width="40"></el-table-column>
				<el-table-column prop="1" label="商品名称" width="262">
					<template slot-scope="scope">
						<div class="fl ml-10">
							<img v-if='scope.row.fileUrl' :src="scope.row.fileUrl" />
							<img v-else src="../../assets/goods.jpg" />
						</div>
						<div class="fl ml-10 mt-10">
							<div class="tal w-160">商品编码:{{scope.row.goodsNo}}</div>
							<div class="tal w-160">{{scope.row.skuName}}</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column label="规格" width="70">
					<template slot-scope="scope">
						<div>1*{{scope.row.specInfoNum}}</div>
					</template>
				</el-table-column>
				<el-table-column label="商品分类" width="70" prop='classifyName'></el-table-column>
				<el-table-column label="库存" width="70" prop='stockEnableQuantity'></el-table-column>
				<el-table-column label="成本价" width="68">
					<template slot-scope="scope">
						<div>￥{{scope.row.costPrice}}</div>
					</template>
				</el-table-column>
				<el-table-column label="团购价" width="68">
					<template slot-scope="scope">
						<div>￥{{scope.row.grouponPrice}}</div>
					</template>
				</el-table-column>
				<el-table-column label="销量" width="70" prop='sales'></el-table-column>
				<el-table-column label="状态" width="70">
					<template slot-scope="scope">
						<span v-if="scope.row.auditState == 1">待审核</span>
						<span v-else-if="scope.row.goodsState == 1">待上架</span>
						<span v-else-if="scope.row.auditState == 2 && scope.row.goodsState == 2">出售中</span>
						<span v-else-if="scope.row.auditState == 3">审核未通过</span>

						<span v-else>-</span>
					</template>
				</el-table-column>
				<el-table-column label="操作">
					<template slot-scope="scope">
						<div v-if='scope.row.goodsType == 2'>
							<router-link :to="`${basePath}/groupDetails/${scope.row.skuNo}/${scope.row.goodsType}/0`">
								<el-button class='ml-0 tal' size="small" type='text'>添加</el-button>
							</router-link>
						</div>
						<div v-if='scope.row.goodsType == 1'>
							<div v-if='scope.row.auditState == 2 && scope.row.goodsState==2'>
								<el-button v-if='showButton("DOWN",140003)' class='ml-0 tal' size="small" type='text' @click="upperlower(scope.$index, tableData)">下架</el-button>
								<router-link :to="`${basePath}/groupDetails/${scope.row.skuNo}/${scope.row.goodsType}/2`">
									<el-button class='ml-0 tal' size="small" type='text'>查看</el-button>
								</router-link>
								<el-button v-if='type == "1"' class='ml-0 tal' size="small" type='text' @click='goodsTop(scope.row.skuNo)'>置顶</el-button>
							</div>
							<div v-else-if='scope.row.goodsState==1'>
								<router-link v-if='showButton("EDIT",140003)' :to="`${basePath}/groupDetails/${scope.row.skuNo}/${scope.row.goodsType}/0`">
									<el-button class='ml-0 tal' size="small" type='text'>编辑</el-button>
								</router-link>
								<router-link v-else :to="`${basePath}/groupDetails/${scope.row.skuNo}/${scope.row.goodsType}/2`">
									<el-button class='ml-0 tal' size="small" type='text'>查看</el-button>
								</router-link>
								<el-button v-if='showButton("PUT",140003)' class='ml-0 tal' size="small" type='text' @click="upperlower(scope.$index, tableData)">上架</el-button>
							</div>
							<div v-else-if='scope.row.auditState == 1'>
								<el-button v-if='showButton("AUDIT",140003)' class='ml-0 tal' size="small" type='text' @click="handleCheck(scope)">审核</el-button>
								<router-link v-else :to="`${basePath}/groupDetails/${scope.row.skuNo}/${scope.row.goodsType}/2`">
									<el-button class='ml-0 tal' size="small" type='text'>查看</el-button>
								</router-link>
							</div>
							<div v-else-if='scope.row.auditState == 3'>
								<router-link v-if='showButton("EDIT",140003)' :to="`${basePath}/groupDetails/${scope.row.skuNo}/${scope.row.goodsType}/0`">
									<el-button class='ml-0 tal' size="small" type='text'>编辑</el-button>
								</router-link>
								<router-link v-else :to="`${basePath}/groupDetails/${scope.row.skuNo}/${scope.row.goodsType}/2`">
									<el-button class='ml-0 tal' size="small" type='text'>查看</el-button>
								</router-link>
							</div>
						</div>

					</template>
				</el-table-column>
			</el-table>
			<el-pagination class="tar mr-30" v-if="total>0" :current-page="currentPage" :page-size='10' :total='total' layout="prev , pager, next, jumper" @current-change="handleCurrentChange">
				<!-- 分页组件 -->
			</el-pagination>
		</div>
	</div>
</template>

<script>
	import { axiosApi, basePath } from "../../api/api";
	export default {
		data() {
			return {
				basePath: basePath,
				dataTabs: ["全部商品", "出售中", "待上架", "待审核", "审核未通过"],
				dataTabss: ["全部商品", "出售中", "待上架"],
				type: '0',
				Loading: true,
				formInlineData: {
					name: '',
					skuNo: '',
					skuName: null,
					classifyName: '',
					classifyId: '',
					id: '',
					goodsState: null,
					AuditState: null,
					isAuthorizationFailure: null,
					goodsNo: ''
				},
				activeTab: '1',
				tableData: [], //商品列表
				searchClassify: [], //搜索分类
				//				分页
				currentPage: 1,
				total: 0,
				isPagination: true,
				count1: null,
				count2: null,
				count3: null,
				title1: '', //上下架
				skuState1: null,
				arr1: [],
				arr2: [],
				isAdmin: false,
				isAdd: false,
				all: false,
				multipleSelection: [],
				classifyName: null,
				brandName: null,
				classifyId: null,
				brandId: null,
				rej: 1, //判断是否进入审核页面
				isOpenGoods: 0,
				spread: false,
				dis: false,
				news: '撤回', //判断撤回/删除,
				skuNos: [], //上下架
				up: true,
				lower: true,
				isOpenGoods: 1
			}
		},
		methods: {
			handleTabs(e) {
				if(e.target.attributes.type) this.type = e.target.attributes.type.value
				this.currentPage = 1
				if(this.type == '0') {
					this.dis = false,
						this.formInlineData.goodsNo = null,
						this.formInlineData.skuName = null,
						this.formInlineData.id = null,
						this.formInlineData.isAuthorizationFailure = null,
						this.formInlineData.goodsState = null,
						this.formInlineData.AuditState = null,
						this.achieve1()
					this.up = true,
						this.lower = true
				}
				if(this.type == '1') {
					this.dis = true,
						this.formInlineData.goodsNo = null,
						this.formInlineData.skuName = null,
						this.formInlineData.id = null,
						this.formInlineData.isAuthorizationFailure = null,
						this.formInlineData.goodsState = '1',
						this.formInlineData.AuditState = '2',
						this.achieve1()
					this.up = false,
						this.lower = true
				}
				if(this.type == '2') {
					this.dis = true,
						this.formInlineData.goodsNo = null,
						this.formInlineData.skuName = null,
						this.formInlineData.id = null,
						this.formInlineData.isAuthorizationFailure = null,
						this.formInlineData.goodsState = '2',
						this.formInlineData.AuditState = null,
						this.achieve1()
					this.up = true,
						this.lower = false
				}
				if(this.type == '3') {
					this.dis = true
					this.formInlineData.goodsNo = null,
						this.formInlineData.skuName = null,
						this.formInlineData.id = null,
						this.formInlineData.isAuthorizationFailure = null,
						this.formInlineData.goodsState = null,
						this.formInlineData.AuditState = '1',
						this.achieve1()
					this.up = false,
						this.lower = false
				}
				if(this.type == '4') {
					this.dis = true
					this.formInlineData.goodsNo = null,
						this.formInlineData.skuName = null,
						this.formInlineData.id = null,
						this.formInlineData.isAuthorizationFailure = null,
						this.formInlineData.goodsState = null,
						this.formInlineData.AuditState = '3',
						this.achieve1()
					this.up = false,
						this.lower = false
				}
			},
			//加载商品列表
			achieve() {
				const api = "/groupon/bigStore/selectList";
				const body = {
//					sellerId: sessionStorage.traderId,
					sellerId: sessionStorage.traderId,
					goodsNo: this.formInlineData.goodsNo,
					skuName: this.formInlineData.skuName,
					classifyId: this.formInlineData.id,
					goodsState: this.formInlineData.goodsState,
					auditState: this.formInlineData.AuditState,
				}
				axiosApi(api, body, this.callback, this.currentPage, 10)
			},
			callback(res) {
				this.tableData = res.data.data.elements ? res.data.data.elements : [];
				this.total = res.data.data.totalCount ? res.data.data.totalCount : '';
				this.getCount()
				this.Loading = false;
				if(this.tableData.length > 0) {
					this.isOpenGoods = this.tableData[0].isOpenGoods
				}
			},
			//查询
			achieve1(payload) {
				payload == 0 && (this.currentPage = 1)
				if(this.formInlineData.goodsNo == '') {
					this.formInlineData.goodsNo = null
				}
				if(this.formInlineData.skuName == '') {
					this.formInlineData.skuName = null
				}
				const api = "/groupon/bigStore/selectList";
				const body = {
					sellerId: sessionStorage.traderId,
					goodsNo: this.formInlineData.goodsNo,
					skuName: this.formInlineData.skuName,
					classifyId: this.formInlineData.id,
					goodsState: this.formInlineData.goodsState,
					auditState: this.formInlineData.AuditState,
				}
				axiosApi(api, body, this.callback3, this.currentPage, 10)
			},
			callback3(res) {
				this.tableData = res.data.data.elements ? res.data.data.elements : [];
				this.total = res.data.data.totalCount ? res.data.data.totalCount : '';
			},
			//			分页
			handleCurrentChange(val) {
				this.currentPage = val
				this.achieve1()
			},
			//查询分类
			classSearch() {
				const api = "/groupon/classify/selectList";
				const body = {}
				axiosApi(api, body, this.classsearch)
			},
			classsearch(res) {
				this.searchClassify = res.data.data;
			},
			//角标
			getCount() {
				const api = "/groupon/storeAuditCount/select";
				const body = {
					sellerType: 1,
					sellerId: sessionStorage.traderId
				}
				axiosApi(api, body, this.counts, 1, 10)
			},
			counts(res) {
				this.count1 = res.data.data.todoAuditStateCount; //待审核状态的数量
				this.count2 = res.data.data.noPassAuditStateCount; //未通过审核状态的数量
				this.count3 = res.data.data.lowerGoodsCount; //待上架
			},
			//			单个商品上下架处理
			upperlower(index, rows) {
				if(this.tableData[index].goodsState == '1') {
					this.skuState1 = '2'
					this.title1 = '上架'
				} else {
					this.title1 = '下架'
					this.skuState1 = '1'
				}
				let that = this;
				this.$confirm('您是否要' + this.title1 + '该商品？', '提示信息', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					that.skuNos.push(that.tableData[index].skuNo)
					const api = "/groupon/bigStoreState/batchUpdate";
					const body = {
						skuNos: that.skuNos,
						goodsState: that.skuState1,
						sellerId: sessionStorage.traderId
					};
					axiosApi(api, body, (res) => {
						that.$message({
							type: 'success',
							message: '操作成功!',
							duration: 1000
						});
						this.achieve1()
						this.getCount()
					}, this.currentPage, 10, (res) => {
						this.$message({
							type: 'info',
							message: res.data.message,
							duration: 3000
						});
					});
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消上架'
					});
				})
				that.skuNos = []
			},
			handleSelectionChange(val) { //table 选中行
				this.multipleSelection = val
			},
			getInfo(type) {
				if(this.multipleSelection.length == 0) {
					this.$message({
						title: '提示信息',
						message: '您未选中任何商品!',
						type: 'warning'
					});
				} else {
					let _this = this
					for(let i = 0; i < this.multipleSelection.length; i++) {
						if(this.multipleSelection[i].auditState == 2 && (this.multipleSelection[i].goodsState == 2 || this.multipleSelection[i].goodsState == 1)) {
							this.isAdmin = true;
							_this.arr1.push(this.multipleSelection[i].goodsState);
							_this.arr2.push(this.multipleSelection[i].skuNo);
						} else {
							this.isAdmin = false;
							break;
						}
					}
					if(type == 1) {
						this.skuState1 = '1'
						this.title1 = '上架'
					} else {
						this.title1 = '下架'
						this.skuState1 = '2'
					}
					this.allupperlower()
				}
			},
			//批量上下架
			allupperlower() {
				let that = this;
				for(let i = 0; i < this.arr1.length; i++) {
					if(this.arr1[i] == this.skuState1) {
						this.isAdd = false;
						break;
					} else {
						this.isAdd = true;
					}
				}
				this.$confirm('您是否要' + this.title1 + '选中商品？', '提示信息', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					if(this.isAdmin == true && this.isAdd == true) { //不存在不可上下架商品
						const api = "/groupon/bigStoreState/batchUpdate";
						const body = {
							skuNos: that.arr2,
							goodsState: that.skuState1,
							sellerId: sessionStorage.traderId
						};
						axiosApi(api, body, (res) => {
							if(this.arr2.length > 0) {
								this.$message({
									type: 'success',
									message: '操作成功!',
									duration: 1000
								});
								this.getCount()
							} else {
								this.$message({
									type: 'warning',
									message: '您未选中任何商品!',
									duration: 1000
								});
							}
							this.arr2 = []
							this.arr1 = []
							that.achieve1();
						}, this.currentPage, 10, (res) => {
							this.$notify({
								type: 'warning',
								message: res.message.data,
								duration: 1000
							});
						});
					} else {
						this.arr1 = []
						this.arr2 = []
						this.$confirm('存在不可' + this.title1 + '商品', '提示信息', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						})
					}

				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消操作'
					});
					this.arr2 = []
					this.arr1 = []
				})
			},
			//审核
			handleCheck(scope) {
				this.rej = 1 //审核
				this.$router.push(basePath + "/groupDetails/" + scope.row.skuNo + '/' + scope.row.goodsType + '/' + this.rej);
			},
			//			大B添加超团商品
			handleAdd(item) {
				let that = this;
				this.$confirm('您是否要添加该商品？', '提示信息', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					const api = "/groupon/addBigStoreBaseInfo/insert";
					const body = {
						sellerId: sessionStorage.traderId,
						skuNo: item.skuNo,
						skuName: item.skuName,

					};
					axiosApi(api, item, (res) => {
						that.$message({
							type: 'success',
							message: '操作成功!',
							duration: 1000
						});
						this.achieve1()
						this.getCount()
					}, this.currentPage, 10, (res) => {
						this.$message({
							type: 'info',
							message: res.data.message,
							duration: 3000
						});
					});
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消添加'
					});
				})
			},
			//出售中商品置顶
			goodsTop(val) {
				axiosApi('/groupon/goods/top', {
					skuNo: val
				}, (res) => {
					this.$message({
						type: 'success',
						message: '置顶成功!',
						duration: 1000
					});
					this.achieve1();
				}, 1, 10, (res) => {
					this.$message({
						type: 'info',
						message: res.data.message,
						duration: 3000
					});
				})
			},
		},
		//进入加载
		activated() {
			this.$nextTick(function() {
				this.achieve();
				this.classSearch();
			})
		},
		mounted() {}
	}
</script>

<style lang="less">
	#groupProduct {
		.table-default .el-button {
			width: auto;
		}
		.c {
			position: relative;
		}
		.count4 {
			position: absolute;
			left: 245px;
			top: 15px;
		}
		.el-tabs__header {
			padding-left: 0;
		}
		.count2 {
			position: absolute;
			left: 325px;
			top: 15px;
		}
		.count3 {
			position: absolute;
			left: 435px;
			top: 15px;
		}
		.red {
			color: red;
		}
		.w-400 {
			width: 400px;
		}
		.w-160 {
			width: 160px;
		}
		.el-dialog {
			min-height: 300px;
		}
		.width-70 {
			width: 70px;
		}
		.black {
			.edit-btn span {
				color: #000000 !important;
			}
		}
		.obt {
			margin-top: 80px;
			margin-right: 60px;
		}
	}
</style>