<template>
    <div class="historicalRecordBox">
        <messageTitle :type="3" class="bg-f"/>
        <el-tabs @tab-click="handleClick" v-model="active" class="bg-f mt-5 pt-20">
            <el-tab-pane label="消息记录" name="1"></el-tab-pane>
            <el-tab-pane label="短信记录" name="2"></el-tab-pane>
        </el-tabs>
        <div class="mContent bg-f">
            <div class="select">
                <span>分类：</span>
                <el-select @change="handleChange" v-model="type" placeholder="请选择">
                    <el-option label="全部" value=""></el-option>
                    <el-option label="客户消息" value="2"></el-option>
                    <el-option label="员工消息" value="1"></el-option>
                </el-select>
            </div>
            <div class="pl-30 pr-30">
                <el-table :data="tableList" border tooltip-effect="dark" stripe class="mt-10 table-default">
                    <el-table-column width="300" prop="content" label="消息内容"></el-table-column>
                    <el-table-column prop="receiverDescribe" label="接收对象"></el-table-column>
                    <el-table-column label="发送时间">
                        <template slot-scope="scope">
                            <span>{{scope.row.gmtCreate | time}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作时间">
                        <template slot-scope="scope">
                            <span>{{scope.row.operationTime | time}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="operatorName" label="操作人"></el-table-column>
                    <el-table-column v-if="active === '2'" prop="count" label="短信条数"></el-table-column>
                </el-table>
            </div>
            <div class="pageBox">
                <el-pagination class="tar" v-if='total>0'
                    :current-page="currentPage"
                    :page-size='10'
                    :total='total'
                    layout="prev, pager, next, jumper"
                    @current-change="handlePageChange">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import { axiosApi, basePath } from "../../../api/api";
import messageTitle from './messageTitle.vue';


export default {
    components : {messageTitle},
    data: function() {
        return ({
            active : '1',
            type : '',
            tableList : [],
            // 最大数量
            total : 100,
            // 当前页数
            currentPage : 1,
            traderId : sessionStorage.getItem('traderId'),
        })
    },
    mounted: function() {
        this.getTableList();
    },
    methods: {
        getTableList : function(){
            let sendUrl = '/sms/merchantRecordLog/select';
            let sendObj = {
                recordType : this.active,
                receiverType : this.type,
                traderId : this.traderId,
            };
            axiosApi(sendUrl,sendObj,(res) => {
                let data = res.data.data;
                this.tableList = data.elements;
                this.total = data.totalCount;
            },this.currentPage,10);
        },
        // tabs change
        handleClick : function(){
            this.currentPage = 1;
            this.type = '';
            this.getTableList();
        },
        // 分类 change
        handleChange : function(){
            this.currentPage = 1;
            this.getTableList();
        },
        // 分页 change
        handlePageChange : function(val){
            this.currentPage = val;
            this.getTableList();
        },
    }
}
</script>

<style lang="less">

.historicalRecordBox{
    .mContent{
        padding-bottom: 30px;
        .select{
            padding: 15px 20px;
            padding-left: 35px;
        }
        .pageBox{
            padding-right: 30px;
        }
    }
}

</style>

