<template>
	<div id="groupDetail">
		<div class="title-top bg-f">团购详情</div>
		<div v-loading='loading'>
			<div class="panel_info bg-f mt-10">
				<div class="info_item">
					<span class="label">团购名称:</span>
					<span>{{groupDetail.grouponName}}</span>
				</div>
				<div class="info_item">
					<span class="label">团购时间:</span>
					<span>{{groupDetail.gmtStart | timenData}} 至 {{groupDetail.gmtEnd | timenData}}</span>
				</div>
				<div class="info_item">
					<span class="label">参团企业:</span>
					<span v-if="groupDetail.companyInfo">{{groupDetail.companyInfo.companyName}}</span>
				</div>
				<div class="info_item">
					<span class="label">团购状态:</span>
					<span v-if="groupDetail.status==1">未开始</span>
					<span v-if="groupDetail.status==2">进行中</span>
					<span v-if="groupDetail.status==3">已结束</span>
					<span v-if="groupDetail.status==4">已关闭</span>
				</div>
			</div>
			<!-- v-for="(item,index) in groupDetail.goodsList" :key="index" -->
			<div class="mt-10 pb-30">
				<div class="goods_item bg-f pb-20 mt-10" >
					<div class="item_title clearfix">
						<el-button class="fr mt-5 mr-20" style="width:110px" v-if="showButton('ADD_SPECIAL_GOODS',140018) && (groupDetail.status == 1 || groupDetail.status == 2)" @click="addGoods(groupDetail.goodsList,2)">添加特殊商品</el-button>
						<el-button class="fr mt-5 mr-20" v-if="groupDetail.status == 1 || groupDetail.status == 2" @click="addGoods(groupDetail.goodsList,1)">添加商品</el-button>
					</div>
					<div class="pl-20 pr-20 mt-20" >
						<div>
							<el-form :inline="true" :model="goodsForm" label-width="auto">
								<el-form-item label="商品编码">
									<el-input v-model.trim="goodsForm.goodsNo" @input="goodsForm.goodsNo=goodsForm.goodsNo.replace(/[\u4e00-\u9fa5]/ig,'')" clearable placeholder="请输入"></el-input>
								</el-form-item>
								<el-form-item label="商品名称">
									<el-input v-model="goodsForm.skuName" clearable placeholder="请输入"></el-input>
								</el-form-item>
								<el-form-item label="商品分类">
									<el-select v-model="goodsForm.classifyId" clearable placeholder="请选择">
										<el-option :label='item.classifyName' :value="item.id" v-for='item in searchClassify' :key="item.id"></el-option>
									</el-select>
								</el-form-item>
								<el-form-item>
									<el-button type="primary" @click="searchList(goodsForm)">搜索</el-button>
								</el-form-item>
							</el-form>
						</div>
						<!-- v-for="(item,index) in groupDetail.goodsList" :key="index" -->
						<el-table :data="groupDetail.goodsList.elements" class='table-default' >
							<el-table-column label='商品名称' width="262">
								<template slot-scope="scope">
									<div class="fl ml-10 ico_p">
										<img v-if='scope.row.fileUrl' :src="scope.row.fileUrl" />
										<img v-else src="../../assets/goods.jpg" />
										<img v-if="scope.row.storeType==2" class="icon_t" src="../../assets/special.png" />
									</div>
									<div class="fl ml-10">
										<div class="tal w-160">商品编码:{{scope.row.goodsNo}}</div>
										<div class="tal w-160">{{scope.row.skuName}}</div>
									</div>
								</template>
							</el-table-column>
							<el-table-column label='规格' width="">
								<template slot-scope="scope">
									<span>1*{{scope.row.specInfoNum}}</span>
								</template>
							</el-table-column>
							<el-table-column label='商品分类' width="">
								<template slot-scope="scope">
									<span>{{scope.row.classifyName}}</span>
								</template>
							</el-table-column>
							<el-table-column label='是否是特殊商品' width="">
								<template slot-scope="scope">
									<span v-if="scope.row.storeType ==1">否</span>
									<span v-else>是</span>
								</template>
							</el-table-column>
							<el-table-column label='商品状态' width="">
								<template slot-scope="scope">
									<span v-if="scope.row.goodsState == 1">待上架</span>
									<span v-else-if="scope.row.goodsState == 2">出售中</span>
									<span v-else>-</span>
								</template>
							</el-table-column>
							<el-table-column label='团购价' width="">
								<template slot-scope="scope">
									<span>{{scope.row.grouponPrice}}</span>
									<span>/{{scope.row.unitName}}</span>
								</template>
							</el-table-column>
							<el-table-column label='总库存' width="">
								<template slot-scope="scope">
									<span>{{scope.row.totalStock}}</span>
									<span>{{scope.row.unitName}}</span>
								</template>
							</el-table-column>
							<el-table-column label='特殊团购库存' width="">
								<template slot-scope="scope">
									<span v-if="scope.row.storeType ==2">{{scope.row.totalStock}}{{scope.row.unitName}}</span>
									
									<span v-else>--</span>
								</template>
							</el-table-column>
							<el-table-column label='序号'>
								<template slot-scope="{row}">
									<span>{{row.sort}}</span>
								</template>
							</el-table-column>
							<el-table-column label='操作' width="">
								<template slot-scope="scope">
									<div>
										<el-button type='text' @click="goodsTop(scope.row.skuNo, scope.row.grouponId,goodsForm)">置顶</el-button>
										<p>
											<el-button type='text' @click="editSpecialGoods(scope.row)">编辑</el-button>
										</p>
										<el-button type='text' @click="delGoods(scope.row)">删除</el-button>
									</div>
								</template>
							</el-table-column>
						</el-table>
						<el-pagination v-if='totalCount>0' class="tar" :current-page.sync="goodsForm.pageIndex" :page-size='goodsForm.pageSize' :total='totalCount' layout="prev , pager, next, jumper" @current-change="handleCurrentChange">
							
						</el-pagination>
					</div>
				</div>
			</div>
		</div>
		<!-- 特殊商品 -->
		<el-dialog class='goodsDialog' :title='tit' :visible='goodsVisible' @close="closeDialog">
			<div class="pl-20 pr-20" v-loading='dialogLoading'>
				<el-form :inline="true" :model="goodsForm" label-width="auto" v-if="!noCheckRadio">
					<el-form-item label="商品编码">
						<el-input v-model.trim="goodsForm.goodsNo" clearable @input="goodsForm.goodsNo=goodsForm.goodsNo.replace(/[\u4e00-\u9fa5]/ig,'')" placeholder="请输入"></el-input>
					</el-form-item>
					<el-form-item label="商品名称">
						<el-input v-model="goodsForm.skuName" clearable placeholder="请输入"></el-input>
					</el-form-item>
					<el-form-item label="商品分类">
						<el-select v-model="goodsForm.classifyId" clearable placeholder="请选择">
							<el-option :label='item.classifyName' :value="item.id" v-for='item in searchClassify' :key="item.id"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="searchSpecialGoods">搜索</el-button>
					</el-form-item>
				</el-form>
				<el-table :data="GoodsList" max-height="500" @selection-change="handleSelectionChange" class='table-default'>
					<el-table-column type="selection" width="30" v-if="!noCheckRadio" key="noCheckRadio"></el-table-column>
					<el-table-column label='商品图片' width="70">
						<template slot-scope="{row}">
							<div><img :src='row.picture'/></div>
						</template>
					</el-table-column>
					<el-table-column label='商品名称' prop="skuName" />
					<el-table-column label='规格' prop="specInfoNum" />
					<el-table-column label='总库存' prop="totalStock" />
					<el-table-column label='可分配库存' v-if="storeType==2" prop="enableSpecialStock" key="enableSpecialStock"/>
					<el-table-column label='最低起订量' prop="minimunMoq" />
					<el-table-column label='限购数量' prop="limitation" />
					<el-table-column label='单位' prop="units" />
					<el-table-column label='可用团购库存' v-if="storeType==2" key="upNum" width="100">
						<template slot-scope="scope">
							<div class="text_input">
								<el-input type="number" v-model.trim="scope.row.upNum" :maxlength="6" size="mini" :disabled="scope.row.storeType==1"></el-input>
							</div>
						</template>
					</el-table-column>
					<el-table-column label='团购价' width="140">
						<template slot-scope="scope">
							<div class="text_input">
								<el-input-number v-model="scope.row.grouponPrice" :min="0.01" :precision="2" :controls="false"></el-input-number>
								<!-- <el-input type="number" v-model.trim="scope.row.grouponPrice" :maxlength="6" size="mini"></el-input> -->
							</div>
						</template>
					</el-table-column>
				</el-table>
				<div class="dialogBtnDiv" v-if="!noCheckRadio">
					<div style="margin-top: 20px;">
							<div>
								<el-button type="primary" @click="addAll">添加当前页</el-button>
							</div>
					</div>
					<div>
						<el-pagination v-if='totalCount>0' class="tar" :current-page.sync="pageIndex" :page-sizes="[10, 50, 100, 1000]" :page-size='10' :total='totalCount' layout="sizes, prev , pager, next, jumper" @size-change="SizeChange" @current-change="pageChange">
							<!-- 分页组件 -->
						</el-pagination>
					</div>
				</div>
			</div>
			<div slot='footer' class="dialog-footer">
				<el-button type='danger' @click="handleCommit()">确 定</el-button>
				<el-button @click="goodsVisible = false">取 消</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import { axiosApi, basePath, baseURI } from "../../api/api";
	export default {
		data() {
			return {
				AgoodsForm: {
					goodsNo: '',
					skuName: '',
					classifyId: '',
					pageIndex:1,
					pageSize:10,
				}, // 弹框搜索条件
				goodsForm: {
					goodsNo: '',
					skuName: '',
					classifyId: '',
					pageIndex:1,
					pageSize:10,
				}, // 弹框搜索条件
				info: {}, // 暂存信息
				storeType: 1, // 商品类型
				totalCount: 0,
				pageIndex: 1,

				searchClassify: [], // 商品分类列表
				formInline: {},
				loading: false,
				GoodsList: [], //特殊商品列表
				goodsVisible: false,
				groupDetail: {
					companyInfo: {
						companyName: null
					},
					goodsList:{
						elements: []
					}

				}, //团购详情
				checkedGoods: [], //选中的特殊商品
				upNum: 0, //商家商品数量编辑时候用
				tit: null,
				noCheckRadio: false,
				dialogLoading: false,
				GoodList:{},
			}
		},
		//进入加载
		activated() {
			this.$nextTick(function() {
				this.getGroupDetail()
				this.classSearch()
			})
		},
		methods: {
			goodsTop(skuNo,grouponId,item) {
				axiosApi('/groupon/goods/top', {
					skuNo: skuNo,
					grouponId: grouponId
				}, (res) => {
					this.$message({
						type: 'success',
						message: '置顶成功!',
						duration: 1000
					});
					this.searchList(item)
				}, 1, 10, (res) => {
					this.$message({
						type: 'info',
						message: res.data.message,
						duration: 3000
					});
				})
			},
			//查询分类
			classSearch() {
				const api = "/unlogin/groupon/classify/selectList";
				const body = {}
				axiosApi(api, body, (res)=>{
					if(res.data.code==='000000'){
						this.searchClassify = res.data.data;
					}
				})
			},
			
			closeDialog(){
				// this.upNum = 0
				this.goodsForm = {
					goodsNo: '',
					skuName: '',
					classifyId: '',
				}, // 清空弹框搜索条件
				this.goodsVisible = false
				this.checkedGoods = []
				this.GoodsList = []
				this.pageIndex = 1
				// this.GoodsList = this.GoodsList.map(item=>{
				// 	item.upNum = 0
				// 	return item
				// })
			},
			/**
			 * 封装服务端数据用于对接组件
			 * @param list
			 * @returns {Array}
			 */
			parseGoodsList(list,type=false) {
			 return	list.map(item=>{
					return {
						id: item.id,
						picture: item.fileUrl, //商品图片
						skuName: item.skuName, //商品名称
						specInfoNum: item.skuInfo.specInfoNum, //商品规格
						minimunMoq: item.minimunMoq, //最低起订量
						units: item.skuInfo.units, //单位
						upNum: item.usedSpecialStockSelf?item.usedSpecialStockSelf:0, //上架得数量
						totalStock: item.totalStock, //总库存
						enableSpecialStock: item.enableSpecialStock, //可用库存
						limitation: item.limitation, //限购数量
						storeType: item.storeType||item.goodsInfo.storeType, // 商品类型
						grouponPrice: item.grouponPrice,
						goodsId:type?item.id:item.goodsId,
					}
				})
			},

			getGroupDetail(val) { //获取团购详情
				this.loading = true
				this.goodsForm.id = this.$route.query.id || null				
				axiosApi('/groupon/parentGroupon/selectDetail',  {
								id: this.goodsForm.id,
								pageSellerGoods:{
								goodsNo:this.goodsForm.goodsNo,
								skuName:this.goodsForm.skuName,
								classifyId:this.goodsForm.classifyId,
                             }
							}, (res) => {
					this.loading = false
					this.groupDetail = res.data.data
					this.totalCount = this.groupDetail.goodsList.totalCount					
				}, this.goodsForm.pageIndex, this.goodsForm.pageSize, (res) => {
					this.loading = false
					this.$message({
						type: 'info',
						message: res.data.message,
						duration: 1000
					})
				})
			},
			searchSpecialGoods(){
				this.pageIndex = 1 // 搜索重置页数
				this.getSpecialGoods()
			},
			pageChange(val){
				this.getSpecialGoods(val)
			},
			SizeChange(val) {
				this.getSpecialGoods(1, val)
			},
			getSpecialGoods(page=1,size=10) { //获取商品与特殊商品
				this.dialogLoading = true
				var val = this.info
				var storeType = this.storeType
				// const goodsIdList = []
				console.log(this.info,val,'info')
				// if(val.goodsList && val.goodsList.data.length > 0 && val.goodsList.data) {
				// 	val.goodsList.data.forEach(item => {
				// 		if(item.goodsInfo.storeType == storeType) {
				// 			goodsIdList.push(item.goodsInfo.id)
				// 		}
				// 	})
				// }
				// '/groupon/specialGoods/select'
				axiosApi('/groupon/specialGoods/select', {
					sellerId: this.groupDetail.sellerId,  //sellerId卖家ID
					goodsNo: this.goodsForm.goodsNo,      //商品编号
					skuName: this.goodsForm.skuName,      //SKUNAME
					classifyId: this.goodsForm.classifyId,
					storeType:storeType, // 商品类型 1、普通商品，2、特殊商品
					grouponId: this.$route.query.id,
					goodsState:2,//只显示已上架的商品
					
				},(res) => {
					if(res.data.data) {
						let Datas = res.data.data.elements
						this.totalCount = res.data.data.totalCount
						this.GoodsList = this.parseGoodsList(Datas,true)
						this.dialogLoading = false
					} else {
						this.GoodsList = []
						this.totalCount = 0
						this.dialogLoading = false
					}
				}, page, size,)
			},
			addGoods(val,type) { //打开特殊商品弹窗
				this.tit = type===1?"添加商品":"添加特殊商品"
				this.storeType = type
				this.noCheckRadio = false // 是否新增，显示多选框
				this.goodsVisible = true
				this.info = val
				this.GoodsList = []
				this.getSpecialGoods()
			},
			editSpecialGoods(row, type) { //编辑特殊商品
				var goodsInfo = row
				this.tit = goodsInfo.storeType===1?"编辑商品":"编辑特殊商品"
				this.storeType = goodsInfo.storeType  
				this.noCheckRadio = true // 不显示多选框
				this.goodsVisible = true
				// this.upNum = scope.row.specialStock
				console.log(row,"33");

				row.skuInfo = {
					specInfoNum:row.specInfoNum,
					units:row.units,
				}
				this.GoodsList = this.parseGoodsList([row])
			
				



			},
			handleSelectionChange(val) { //多选
				this.checkedGoods = val
			},
			addAll(){
				// 添加当前页。
				this.checkedGoods = this.GoodsList
				this.handleCommit()
			},
			handleCommit() { //添加商品
				let flag = false
				if(this.noCheckRadio){ // 编辑情况下
					this.checkedGoods = this.GoodsList
				}
				// return
				if(this.checkedGoods.length <= 0) {
					this.$message({
						type: 'info',
						message: '请先选择需要操作的数据',
						duration: 2000
					})
					return false
				} else {
					if(this.storeType==2){ // 商品不做验证可用团购库存数量
						this.checkedGoods.forEach(item => {
							if(item.upNum == null || item.upNum == 0) {
								this.$message({
									type: 'info',
									message: '请先输入可用团购库存数量',
									duration: 2000
								})
								flag = true
							}
							// 2021/11/23注释，前端不做验证由后端做
							// else if(item.enableSpecialStock < item.upNum) {
							// 	this.$message({
							// 		type: 'info',
							// 		message: '可用团购库存不可大于可用库存',
							// 		duration: 2000
							// 	})
							// 	flag = true
							// }
						})
					}
					const goodsList = []
					console.log(this.checkedGoods,"132");
					this.checkedGoods.forEach(item => {
						goodsList.push({
							goodsId: item.goodsId,
							totalStock: item.upNum,
							grouponPrice: item.grouponPrice
						})
					})
					if(!flag) {
						axiosApi('/groupon/specialGoods/operate', {        //保存
							grouponId: this.$route.query.id,
							goodsList: goodsList,
							storeType: this.storeType,
						}, (res) => {
							if(res.data.code==='000000'){
								this.goodsVisible = false
								this.checkedGoods = []

								this.getGroupDetail()
							}
							
						}, this.goodsForm.pageIndex, this.goodsForm.pageSize, res => {
							this.$message({
								type: 'info',
								message: res.data.message,
								duration: 1000
							})
						})
					}
				}
			},
			delGoods(row) { //删除特殊商品
				const h = this.$createElement
				this.$msgbox({
					title: '提示信息',
					message: h('p', {
						style: 'text-align:center'
					}, '是否确认删除此条商品？'),
					showCancelButton: true,
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					beforeClose: (action, instance, done) => {
						if(action === 'confirm') {
							axiosApi('/groupon/specialGoods/del', {
								id: row.id,
								storeType: row.storeType
							}, (res) => {
								this.$message({
									type: 'success',
									message: '删除成功',
									duration: 1000
								})
								this.searchList()
							},this.goodsForm.pageIndex, this.goodsForm.pageSize, (res) => {
								this.$message({
									type: 'info',
									message: res.data.message,
									duration: 1000
								})
							})
							done()
						} else {
							done()
						}
						delete instance.$slots.default;
					}
				})
			},
			handleCurrentChange(val) { //分页
				this.goodsForm.pageIndex = val
				this.getGroupDetail()
			},

			searchList(){
				console.log(this.goodsForm,'g')
				this.goodsForm.pageIndex = 1
				this.getGroupDetail()
				// this.handleCurrentChange(1)
			},
		}
	}
</script>

<style lang="less">
	#groupDetail {
		.el-table img {
				width: 30px;
				height: 30px;
				margin-right: 5px;
		}
		.el-input-number .el-input .el-input__inner {
				height: 32px!important;
				width: 118px;
				padding-right: 10px;
				padding-left: 10px;
				text-align: center;
		}
		.el-message {
			height: 70px !important;
		}
		.panel_info {
			height: 180px;
			font-size: 14px;
			padding-left: 50px;
			padding-top: 30px;
			box-sizing: border-box;
			.info_item {
				line-height: 30px;
				.label {
					font-size: 14px;
				}
			}
		}
		.w-160 {
			width: 160px;
		}
		.goods_item {
			.item_title {
				height: 44px;
				line-height: 44px;
				border-bottom: 1px solid #EEE;
			}
		}
		.ico_p {
			position: relative;
		}
		.icon_t {
			width: 60px;
			height: 60px;
			position: absolute;
			left: 0;
			top: 0
		}
		.goodsDialog {
			.el-dialog {
				min-height: 500px;
				width: 1000px;
			}
			.text_input {
				.el-input--mini {
					width: 85px;
				}
			}
			.dialogBtnDiv{
				display: flex;
				align-items: center;
				justify-content: space-between;
			}
		}
	}
</style>
