<template>
  <div>
    <div style="text-align:right">
      <el-button @click="openDialog">新增新闻类别</el-button>
    </div>
    <div>
      <el-table :data="list" stripe tooltip-effect="dark" class="mt-10 table-default" width="100px" >
				<el-table-column label="ID" prop="id"></el-table-column>
				<el-table-column label="新闻类别" prop="classifyName"></el-table-column>
				<el-table-column label="创建时间">
					<template slot-scope="{row}">
						<div>{{row.gmtCreate | timenyr}}</div>
					</template>
				</el-table-column>
				<el-table-column label="是否默认">
					<template slot-scope="{row}">
						<div>{{row.isDefault?'是':'否'}}</div>
					</template>
				</el-table-column>
				<el-table-column label="操作">
					<template slot-scope="{row}">
            <div v-if="!row.isDefault">
              <el-link type="primary" @click="openEditDialog(row)">编辑</el-link>
						  <el-link type="danger" @click="submitDelete(row)">删除</el-link>
            </div>
            <div v-else>
              不可操作
            </div>
					</template>
				</el-table-column>
			</el-table>
    </div> 

    <!-- 新增&编辑 -->
    <el-dialog :title="form.id?'编辑新闻类别':'新增新闻类别'" :visible.sync="dialogFormVisible" width="500px" @close="cancel">
      <el-form :model="form" ref="form" :rules="rules" label-width="150px">
        <el-form-item label="新闻类别" prop="classifyName">
          <el-input v-model="form.classifyName" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submit">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getNewsClassList,addNewsClass,updateNewsClass,deleteNewsClass } from "~/api/news";
export default {
  data(){
    return {
      dialogFormVisible:false,
      list: [],
      form: {
        
        id: null,
        classifyName:null,
        isDefault: null,
      },
      rules:{
        classifyName: [
          { required: true, message: '请输入分类名称', trigger: 'blur' },
          { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
      },
    }
  },
  mounted(){
    this.getList()
  },
  methods:{
    getList(){
      getNewsClassList({companyTraderId:sessionStorage.traderId}).then(res=>{
        this.list = res
      })
    },
    addClass(){
      var form = Object.assign({companyTraderId: sessionStorage.traderId},this.form)
      addNewsClass(form).then(res=>{
        this.cancel()
        this.getList()
      }).catch(err=>{})
    },
    updateClass(){
      updateNewsClass(this.form).then(res=>{
        this.cancel()
        this.getList()
      }).catch(err=>{})
    },
    deleteClass(row){
      deleteNewsClass({id:row.id}).then(res=>{
        this.$message({
          type: 'success',
          message: '删除成功!'
        });
        this.getList()
      }).catch(err=>{})
    },
    openDialog(){
      this.dialogFormVisible=true
    },
    openEditDialog(row){
      this.form = {
        id: row.id,
        classifyName:row.classifyName,
      }
      this.dialogFormVisible=true
    },
    submitDelete(row){
       this.$confirm('此操作将删除该分类, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.deleteClass(row)
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        });
    },
    submit(){
      this.$refs['form'].validate((valid) => {
        if (valid) {
          if(this.form.id){
            this.updateClass()
          }else{
            this.addClass()
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    cancel(){
      this.dialogFormVisible=false
      this.form = {
        id: null,
        classifyName:null,
      }
      this.$refs['form'].resetFields();
    },
  }
}
</script>

<style>

</style>