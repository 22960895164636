<template>
  <div>
    <el-cascader placeholder="请选择客户分组" v-model="groupId" :disabled="disabled" :collapse-tags="collapse" :options="groupList" clearable :props="treeProps"></el-cascader>
  </div> 
</template>

<script>
import { axiosApi, basePath } from "~/api/api";
import { mapMutations, mapActions } from 'vuex';
export default {
  name: 'TreeGroup',
  props: {
    value: {
    
    },
    disabled: {
      type: Boolean,
      default: false
    },
    collapse: {
     type: Boolean,
     default: false
    }
  },
  model: {
    prop: 'value',
    event: 'valChange'
  },
  computed:{
    groupId: {
      get:function () {
        return this.value
      },
      set:function (val) {
        this.$emit('valChange', val)
      }
    },
    groupList() {
      return this.$store.state.channelLevel.groupList
    },   
  },
  data(){
    return {
      treeProps: {
        value: 'id',
        label: 'name',
        children:'sub',
        emitPath:false,
        multiple: this.collapse
      },
    }
  },
  
  mounted(){
    this.queryGroupList()
  },
  methods:{
    ...mapActions({
        'queryGroupList': 'queryGroupList'
    }),
  },
}
</script>

<style>

</style>