<template>
  <div class="activities">
    <div class="bg-f center">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import storeTop from "../../components/storeTop.vue";
export default {
  data() {
    return {
      form: {
        name: ""
      }
    }
  },
  mounted() {
    this.changeHeight();
  },
  methods: {
    changeHeight() {
      var height = document.documentElement.clientHeight;
      document.getElementsByClassName("activities")[0].style.minHeight = height - 110 + "px";
    }
  }
}
</script>

<style lang="less">
.center {
  padding: 50px 0px;
}
  .avatar-uploader{
    width: 240px;
  }
</style>
