<template>
	<el-dialog :title="titles" :visible.sync="visible" @close="goPage">
		<el-form>
			<el-form-item label="选择业务员:">
				<el-select v-model="saleId" filterable clearable remote placeholder="请选择业务员" :remote-method="remotemethod">
					<!-- <el-option label="请选择" value="-1"></el-option> -->
					<el-option :label="item.employeeName" v-for="(item,index) in people" :value="item.managerId" :key="index">{{item.employeeName}}({{item.loginName}})</el-option>
				</el-select>
			</el-form-item>
		</el-form>
		<div slot="footer" class="dialog-footer">
			<el-button type="primary" @click="sure">确 定</el-button>
			<el-button @click="goPage">取 消</el-button>
		</div>
	</el-dialog>
</template>

<script>
	import { mapActions } from "vuex"
	import { axiosApi, basePath } from '../../api/api'
	export default {
		props: ["saleVisible", "title"],
		data() {
			return {
				visible: false,
				saleId: "",
				titles: "添加业务员"
			}
		},
		mounted() {
			this.$nextTick(() => {
				this.findSales(); //查询所有的业务员
			})
		},
		watch: {
			saleVisible(value) {
				if (value) {
					this.saleId = this.sale != 0 ? this.sale : ""
				}
				this.visible = value
			},
			title(val) {
				this.titles = val ? "更换业务员" : "添加业务员"
			}
		},
		computed: {
			people() {
				return this.$store.state.salesMan.people
			},
			sale() {
				return this.$store.state.salesMan.saleId
			},
		},
		methods: {
			...mapActions({
				"findSales": "findSales",
				'makesure': 'sure'
			}),
			goPage() {
				this.$emit("closedialog")
			},
			sure() {
				this.makesure(this.saleId)
				this.goPage()
			},
			remotemethod(query){
				this.findSales(query)
			}
		}
	}
</script>

<style>

</style>
