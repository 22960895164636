<template>
  <div id="bind">
    <el-steps
      :space="270"
      :active="step"
      finish-status="success"
      class="mt-30"
      :align-center="true"
    >
      <el-step title="验证身份"></el-step>
      <el-step title="设置新支付密码"></el-step>
      <el-step title="完成"></el-step>
    </el-steps>
    <div v-show="step == 1" class="content">
      <el-form :model="form" :rules="rules" ref="form" label-width="115px">
        <el-form-item label="已绑定手机号:">
          <span>{{ mobile }}</span>
        </el-form-item>
        <el-form-item label="短信验证码:" prop="code">
          <el-input
            style="width: 120px"
            v-model.trim="form.code"
            @change="clearmsg"
            :maxlength="6"
          ></el-input>
          <el-button
            class="ml-20 wa"
            @click="sendnum({ type: 2 })"
            :disabled="timeb"
            >获取验证码<span v-if="timeb">{{ time | seconds }}</span></el-button
          >
          <p v-if="errormsg.length > 0" class="color-danger lh-14 mt-5">
            {{ errormsg }}
          </p>
        </el-form-item>
        <el-form-item class="mt-50">
          <el-button type="danger" @click="sure(1)">下一步</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div v-show="step == 2" class="content">
      <el-form :model="form2" :rules="rules2" ref="form2" label-width="130px">
        <el-form-item label="新支付密码:" prop="pass">
          <el-input
            type="password"
            v-model="form2.pass"
            placeholder="请输入6位数字密码"
            :maxlength="6"
          ></el-input>
        </el-form-item>
        <el-form-item label="确认新支付密码:" prop="checkPass">
          <el-input
            type="password"
            v-model="form2.checkPass"
            :maxlength="6"
          ></el-input>
        </el-form-item>
        <el-form-item class="mt-50">
          <el-button type="danger" @click="sure(2)">确定</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div v-show="step == 3" class="content tac">
      <i class="iconfont icon-success color-danger font-150"></i>
      <p class="color-danger font-30 mt-30">恭喜您，设置新支付密码成功！</p>
      <el-button type="danger" class="mt-50" @click="goPage">返回</el-button>
    </div>
  </div>
</template>

<script>
import { axiosApi, basePath } from "~/api/api";
import { mapActions, mapMutations } from "vuex";
import md5 from "md5";
export default {
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入新支付密码"));
      } else {
        if (this.form2.checkPass !== "") {
          this.$refs.form2.validateField("checkPass");
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入确认支付密码"));
      } else if (value !== this.form2.pass) {
        callback(new Error("两次密码不一致，请重新输入"));
      } else {
        callback();
      }
    };
    return {
      form: {
        code: "",
      },
      rules: {
        code: [
          {
            required: true,
            message: "请输入验证码",
          },
          {
            min: 6,
            max: 6,
            message: "请输入6位验证码",
          },
        ],
      },
      form2: {
        pass: "",
        checkPass: "",
      },
      rules2: {
        pass: [
          {
            required: true,
            validator: validatePass,
          },
          {
            pattern: /^\d{6}$/,
            message: "请输入6位数字",
          },
        ],
        checkPass: [
          {
            required: true,
            validator: validatePass2,
          },
        ],
      },
      mobile: "",
    };
  },
  computed: {
    errormsg() {
      return this.$store.state.paymodule.errormsg;
    },
    step() {
      return this.$store.state.paymodule.step;
    },
    time() {
      return this.$store.state.paymodule.time;
    },
    timeb() {
      return this.$store.state.paymodule.timeb;
    },
  },
  mounted() {
    this.mobile = sessionStorage.getItem("mobile");
    this.activestep();
    this.clearmsg();
    this.cleartime();
  },
  methods: {
    ...mapActions({
      vcodeValid: "vcodeValid",
      resetPayPassword: "resetPayPassword",
      sendnum: "sendnum",
    }),
    ...mapMutations({
      clearmsg: "clearmsg",
      cleartime: "cleartime",
      activestep: "activestep",
    }),
    sure(type) {
      if (type == "1") {
        this.$refs.form.validate((valid) => {
          if (valid) {
            console.log("ok");
            this.vcodeValid(this.form.code);
          } else {
            console.log("fail");
          }
        });
      }
      if (type == "2") {
        this.$refs.form2.validate((valid) => {
          if (valid) {
            console.log("ok");
            this.resetPayPassword(md5(this.form2.pass));
          } else {
            console.log("fail");
          }
        });
      }
    },
    goPage() {
      this.step = 1;
      this.form.code = "";
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="less">
#bind {
  position: relative;
  height: 450px;
  padding-top: 20px;
  .el-steps {
    display: flex;
    justify-content: center;
    .el-step {
      .el-step__head.is-success {
        border-color: #ff4201;
        .el-step__icon {
          color: #ff4201;
        }
      }
      .el-step__title.is-success {
        color: #ff4201;
      }
      &:last-child {
        width: auto !important;
      }
      &:nth-child(1) {
        .el-step__title {
          margin-left: -12px;
        }
      }
      &:nth-child(2) {
        .el-step__title {
          margin-left: -27px;
        }
      }
      &:nth-child(3) {
        .el-step__title {
          margin-left: 3px;
        }
      }
    }
  }
  .content {
    position: absolute;
    left: 50%;
    top: 153px;
    transform: translateX(-50%);
    .wa {
      width: auto;
      padding: 0 5px;
    }
  }
}
</style>