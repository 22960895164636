<template>
  <div>
    <div class="big-title">优惠券详情
		</div>
    <div class="item-list" v-loading="loading">
      <div class="each-item mt-20">
        <div class="item-title fl">优惠券名称</div>
        <div class="item-body fl">{{couponName}}</div>
      </div>
      <div class="each-item mt-20">
        <div class="item-title fl">发放时间</div>
        <div class="item-body fl">{{gmtSendStart | timenyr}} 至 {{gmtSendEnd | timenyr}}</div>
      </div>
      <div class="each-item mt-20">
        <div class="item-title fl">发放数量</div>
        <div class="item-body fl">{{num}}份</div>
      </div>
      <div class="each-item mt-20">
        <div class="item-title fl">优惠券金额</div>
        <div class="item-body fl">{{minusMoney}}元</div>
      </div>
      <div class="each-item mt-20" v-if="useLimitType == '1'">
        <div class="item-title fl">使用条件</div>
        <div class="item-body fl">按订购金额&nbsp;&nbsp;消费满{{fullMoney}}元</div>
      </div>
      <div class="each-item mt-20" v-if="useLimitType == '2'">
        <div class="item-title fl">使用条件</div>
        <div class="item-body fl">按订购数量&nbsp;&nbsp;消费满{{fullNum}}{{$route.query.couponSource != '3'?'瓶':'件'}}</div>
      </div>
      <div class="each-item mt-20" v-if="rangeSkuType =='1'">
        <div class="item-title fl">参与活动商品</div>
        <div class="item-body fl">全部商品</div>
      </div>
      <div class="each-item mt-20" v-if="rangeSkuType =='2'">
        <div class="item-title fl">参与活动商品</div>
        <div class="item-body fl">
          <div>部分商品</div>
          <el-table :data="skuList" tooltip-effect="dark" style="width: 740px" class="mt-10 table-default">
            <el-table-column label="商品编码" :prop="$route.query.couponSource != 3 ?'skuNo':'goodsNo'" width="">
            </el-table-column>
            <el-table-column label="商品名称" prop='skuName' width="">
            </el-table-column>
            <el-table-column label="库存" prop='stockEnableQuantity' width="">
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="each-item mt-20" v-if="rangeTraderType =='1'">
        <div class="item-title fl">参与活动客户</div>
        <div class="item-body fl">全部客户</div>
      </div>
      <div class="each-item mt-20" v-if="rangeTraderType =='2'">
        <div class="item-title fl">参与活动客户</div>
        <div class="item-body fl">
          <div>部分客户</div>
          <div style="width: 740px">
            <tableA :size="[100,220,150,150]" :operate="true" :check="true" :noform="true"></tableA>
            <!-- <el-table :data="buyerList" tooltip-effect="dark" style="width: 100%" class="mt-10 table-default">
              <el-table-column label="客户名称" prop='storeName'>
              </el-table-column>
              <el-table-column label="所属分组" prop="groupName">
              </el-table-column>
              <el-table-column label="客户类型"> 
                <template slot-scope='scope'>
                    <span v-if="scope.row.ctype == 1">经销商</span>
                    <span v-else-if="scope.row.ctype == 2">终端</span>
                  </template>
              </el-table-column>
              <el-table-column label="终端渠道" prop="channelName">
              </el-table-column>
              <el-table-column label="所属区域" prop='districtName'>  
              </el-table-column>
            </el-table> -->
          </div>
        </div>
      </div>
      <div class="each-item mt-20" v-if="effectiveDateType =='1'">
        <div class="item-title fl">有效时间</div>
        <div class="item-body fl">按截止时间&nbsp;&nbsp;{{gmtEffectiveEnd | timenyr}}前有效</div>
      </div>
      <div class="each-item mt-20" v-if="effectiveDateType =='2'">
        <div class="item-title fl">有效时间</div>
        <div class="item-body fl">按起始时间&nbsp;&nbsp;{{gmtEffectiveStart | timenyr}} 至 {{gmtEffectiveEnd | timenyr}}</div>
      </div>
      <div class="each-item mt-20" v-if="effectiveDateType =='3'">
        <div class="item-title fl">有效时间</div>
        <div class="item-body fl">按领用时间&nbsp;&nbsp;{{effectiveDay}}天内有效（领取后开始计算）</div>
      </div>
      <div class="each-item mt-20" v-if="grantType =='1'">
        <div class="item-title fl">领取方式</div>
        <div class="item-body fl">
          <span>客户主动领取</span>
          <span v-if="receiveLimit != '-1'">限制领用次数&nbsp;&nbsp;{{receiveLimit}}次</span>
          <span v-if="receiveLimit == '-1'">不限制领用次数</span>
        </div>
      </div>
      <div class="each-item mt-20" v-if="grantType =='2'">
        <div class="item-title fl">领取方式</div>
        <div class="item-body fl">
          <span>系统自动发放</span>
          <span v-if="autograntType =='1'">&nbsp;&nbsp;新注册用户</span>
          <span v-if="autograntType =='2'">&nbsp;&nbsp;首次下单用户</span>
          <span v-if="autograntType =='3'">&nbsp;&nbsp;店铺下单用户</span>
          <span v-if="autograntType =='4'">&nbsp;&nbsp;首次访问用户</span>
          <span v-if="autograntLimitType =='1' && autograntType =='3'">&nbsp;&nbsp;按订购金额&nbsp;&nbsp;消费满{{autograntFullMoney}}元</span>
          <span v-if="autograntLimitType =='2' && autograntType =='3'">&nbsp;&nbsp;按订购数量&nbsp;&nbsp;消费满{{autograntFullNum}}瓶</span>
        </div>
      </div>
      <div class="each-item mt-20 flex">
        <div class="item-title fl">备注</div>
        <div class="item-body w920 fl">{{remark ? remark : '无'}}</div>
      </div>
      <div style="text-align:center;margin:10px">
        <el-button type="primary" @click="()=>{this.$router.go(-1)}">返回</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import {mapMutations} from 'vuex'
import { axiosApi,basePath } from "../../../api/api"
export default {
  data() {
    return {
      loading: false,
      couponName: '',
      gmtSendStart: '',
      gmtSendEnd: '',
      num: '',
      minusMoney: '',
      useLimitType: '',
      fullMoney: '',
      fullNum: '',
      rangeTraderType: '',
      rangeSkuType: '',
      effectiveDateType: '',
      gmtEffectiveEnd: '',
      gmtEffectiveStart: '',
      effectiveDay: '',
      grantType: '',
      receiveLimit: '',
      autograntType: '',
      autograntLimitType: '',
      autograntFullMoney: '',
      autograntFullNum: '',
      remark: '',
      buyerList: [],
      skuList: [],
    }
  },
  methods: {
    ...mapMutations({
      'initclient': 'initclient'
    }),
    getticketdetail() {
      this.loading = true
      const api = '/anon/marketing/coupon/detail'
        let body = {
            couponId: sessionStorage.getItem('couponid')
        }
        let newbody = Object.assign({},body)
        axiosApi(api, newbody, (res)=>{
          this.callback(res.data.data)
        },0, 10)
    },
    callback(res) {
      this.loading = false
      this.couponName = res.couponName
      this.gmtSendStart = res.gmtSendStart
      this.gmtSendEnd = res.gmtSendEnd
      this.num = res.num
      this.minusMoney = res.minusMoney
      this.useLimitType = res.useLimitType
      this.fullMoney = res.fullMoney
      this.fullNum = res.fullNum
      this.rangeTraderType = res.rangeTraderType
      this.rangeSkuType = res.rangeSkuType
      this.effectiveDateType = res.effectiveDateType
      this.gmtEffectiveEnd = res.gmtEffectiveEnd
      this.gmtEffectiveStart = res.gmtEffectiveStart
      this.effectiveDay = res.effectiveDay
      this.grantType = res.grantType
      this.receiveLimit = res.receiveLimit
      this.autograntType = res.autograntType
      this.autograntLimitType = res.autograntLimitType
      this.autograntFullMoney = res.autograntFullMoney
      this.autograntFullNum = res.autograntFullNum
      this.remark = res.remark
      this.buyerList = res.buyerList
      this.skuList = res.skuList
      console.log(res)
      setTimeout(()=>{
        //初始化客户列表
        this.initclient(res.buyerList ? res.buyerList : [])
      },0)
    }
  },
  mounted() {
    this.$nextTick(()=>{
      this.getticketdetail()
    })
  }
}
</script>
<style lang="less">
  .big-title {
    text-align: center;
    font-size: 24px;
  }
  .flex{
    display:flex;
  }
  .w920{
    width:920px;
  }
  .item-list {
    font-size: 15px;
    .each-item {
      overflow: hidden;
      .item-title {
        width: 160px;
        text-align: right;
        padding-right: 20px;
      }
    }
  }
</style>