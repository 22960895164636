<template>
	<div id="punch">
		<div class="title-top bg-f">
			打卡
		</div>
		<div class='bg-f mt-5 pb-50 pl-30 pr-30'>
			<el-form :inline="true" :model="formInlineData" label-width="90px" class="clearfix pt-30" style="display:flex">
				<div>
					<el-form-item label='时间'>
						<ys-daterange-picker v-model="formInlineData.orderDateRange" type="date" :endDisabledDate="new Date()"/>
					</el-form-item>
					<el-form-item label="姓名">
						<el-input v-model.trim="formInlineData.name" :disabled="isAdmin == 0 && isDepartmentLeader == 0"></el-input>
					</el-form-item>
				</div>
				<div style="flex:1;min-width: 200px">
					<el-button type="info" class="fl ml-50" @click='achieve(true)' icon="el-icon-search">搜 索</el-button>
				</div>
				
			</el-form>
			<div class='tar clearfix'>
				<el-button type='text' v-if="showButton('EXPORT_ALL',200017)" class="fr mr-20" @click="exportExcel">批量导出</el-button>
				<!--导出-->
				<form ref="exportList" action='/sunrise-gateway/export/sales/clock/list' style="display: none">
					<input type="text" v-if="formInlineData.startDate" name="startDate" v-model="formInlineData.startDate">
					<input type="text" v-if="formInlineData.endDate" name="endDate" v-model="formInlineData.endDate">
					<input type="text" v-if="formInlineData.name" name="managerName" v-model="formInlineData.name">
					<input type="text" name="startRow" v-model="a">
					<input type="text" name="startCol" v-model="a">
					<input type="text" name="fileName" v-model="fileName">
					<input type="text" name="modelName" v-model="modelName">
				</form>
			</div>
			<el-table :data="tableData" class='mt-10 table-default'>
				<el-table-column label="日期" min-width="115" prop='signDate'>
				</el-table-column>
				<el-table-column label="姓名" min-width="100" prop='managerName'>
				</el-table-column>
				<el-table-column label="签到时间" min-width="100">
					<template slot-scope="scope">
						<p v-if="scope.row.signOnTime" :class="{red:scope.row.isSignOnException == 2 }">{{scope.row.signOnTime | timenyrr }}</p>
						<p v-else>-</p>
					</template>
				</el-table-column>
				<el-table-column label="签到位置" min-width="85">
					<template slot-scope="scope">
						<p v-if="scope.row.signOnAddr">{{scope.row.signOnAddr}}</p>
						<p v-else>-</p>
					</template>
				</el-table-column>
				<el-table-column label="签退时间" min-width="85">
					<template slot-scope="scope">
						<p v-if='scope.row.signOutTime' :class="{red:scope.row.isSignOutException == 2 }">{{scope.row.signOutTime | timenyrr }}</p>
						<p v-else>-</p>
					</template>
				</el-table-column>
				<el-table-column label="签退位置" min-width="125">
					<template slot-scope="scope">
						<p v-if="scope.row.signOutAddr">{{scope.row.signOutAddr}}</p>
						<p v-else>-</p>
					</template>
				</el-table-column>
				<el-table-column label="异常情况" min-width="100">
					<template slot-scope="scope">
						<p v-if='scope.row.exceptionDetails == null || scope.row.exceptionDetails == ""'>无</p>
						<p v-else>{{scope.row.exceptionDetails}}</p>
					</template>
				</el-table-column>
				<el-table-column label="备注" align="left" min-width="100">
					<template slot-scope="scope">
						<!-- <p v-if='scope.row.exceptionDetails == null || scope.row.exceptionDetails == "" '></p> -->
						<div>
							<el-tooltip class="item" effect="dark" :content="scope.row.signOnRemark" placement="top">
								<p class="text-overflow">{{scope.row.signOnRemark}}</p>
							</el-tooltip>
							<el-tooltip class="item" effect="dark" :content="scope.row.signOutRemark" placement="top">
								<p class="text-overflow mt-5">{{scope.row.signOutRemark}}</p>
							</el-tooltip>
						</div>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination v-if='total>0' class="tar mt-20" :current-page="currentPage" :page-size='10' :total='total' layout="prev , pager, next, jumper" @current-change="handleCurrentChange">
			</el-pagination>
		</div>

	</div>
</template>

<script>
	import { axiosApi, basePath } from "../../api/api"
	import YsDaterangePicker from "~/components/ys-daterange-picker";
	export default {
		components: {YsDaterangePicker},
		data() {
			return {
				tableData: [], //列表
				//分页
				currentPage: 1,
				total: 0,
				isPagination: true,
				myDate: '',
				num: '',
				formInlineData: {
					orderDateRange: [],
					name: null,
					startDate: null,
					endDate: null
				},
				fileName: null,//导出名称
				a: 0,//固定值 写死
				modelName: 'sales.clock.list',
				isDepartmentLeader:null
			}
		},
		computed: {
			isAdmin() {
				return sessionStorage.getItem('isAdmin')
			},
		},
		mounted(){
            this.resolveIsSalesDepartmentLeaderWithCache(res=>{
                console.log(res)
                this.isDepartmentLeader = res
            });
		},
		methods: {
			exportExcel(){//批量导出
				this.formInlineData.startDate = (this.formInlineData.orderDateRange[0] == undefined || this.formInlineData.orderDateRange[0] == '') ? null : this.formInlineData.orderDateRange[0].getTime()
				this.formInlineData.endDate = (this.formInlineData.orderDateRange[1] == undefined || this.formInlineData.orderDateRange[1] == '') ? null : this.formInlineData.orderDateRange[1].getTime()
				this.fileName = '打卡记录.xls'
				
				setTimeout(()=>{
					if(this.tableData.length>0){
						this.$refs.exportList.submit()
					}else{
						this.$message.warning('暂无数据，无法导出');
					}
				},0)
				
			},
			//查询签到签退列表
			achieve(bool) {
				if(bool&&this.currentPage != 1) {
					this.currentPage = 1;
					return
				}
				if(this.formInlineData.name == '') {
					this.formInlineData.name = null
				}
				this.formInlineData.startDate = (this.formInlineData.orderDateRange[0] == undefined || this.formInlineData.orderDateRange[0] == '') ? null : this.formInlineData.orderDateRange[0].getTime()
				this.formInlineData.endDate = (this.formInlineData.orderDateRange[1] == undefined || this.formInlineData.orderDateRange[1] == '') ? null : this.formInlineData.orderDateRange[1].getTime()
				const api = "/sales/clock/web/selectListPage";
				const body = {
					signDate: this.signDate,
					managerName: this.formInlineData.name,
					startDate: this.formInlineData.startDate,
					endDate: this.formInlineData.endDate
				}
				axiosApi(api, body, this.callback, this.currentPage, 10)
			},
			callback(res) {
				this.tableData = res.data.data ? res.data.data.elements : []
				this.total = res.data.data ? res.data.data.totalCount : 0
			},
			//			分页
			handleCurrentChange(val) {
				this.currentPage = val
				this.achieve()
			},
		},
		//进入加载
		activated() {
			this.$nextTick(function() {
				if(this.isAdmin == 0 && this.isDepartmentLeader == 0) {
					this.formInlineData.name = sessionStorage.getItem('employeeName')
				}
				this.achieve();
			})
		}
	}
</script>

<style lang="less">
	#punch {
		.red {
			color: red;
		}
		.text-overflow{ 
			display:block;/*内联对象需加*/ 
			width:105px; 
			word-break:keep-all;/* 不换行 */ 
			white-space:nowrap;/* 不换行 */ 
			overflow:hidden;/* 内容超出宽度时隐藏超出部分的内容 */ 
			text-overflow:ellipsis;/* 当对象内文本溢出时显示省略标记(...) ；需与overflow:hidden;一起使用。*/ 
		} 
	}
</style>