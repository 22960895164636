<template>
  <div class="">
    <el-form :model="form" ref="form" label-width="128px" :rules="rules">
      <el-form-item label="活动名称:" prop="couponName">
        <el-input v-model.trim="form.couponName" palceholder="输入活动名称" class=""></el-input>
      </el-form-item>
      <el-form-item label="发放时间:" prop="value1">
        <div class="">
          <template>
            <div class="block">
              <!-- <el-date-picker
                v-model="form.value1"
                type="datetimerange"
                placeholder="选择时间范围">
              </el-date-picker> -->
              <ys-daterange-picker v-model="form.value1" type="datetime"/>
            </div>
          </template>
        </div>
      </el-form-item>
      <el-form-item label="发放数量:" required>
        <sy-input v-model="form.num" type="int" :min="1" :max="99999999"></sy-input>
        <span>份</span>
      </el-form-item>
      <el-form-item label="优惠券金额:" required>
        <sy-input v-model="form.minusMoney" type="float" :min="0.01" :max="99999999.99"></sy-input>
        <span>元</span>
      </el-form-item>
    </el-form>
    <el-form :model="form" label-width="128px" class="mt-40" :rules="rules">
      <el-form-item label="使用条件:" required>
        <div class="">
          <template>
            <el-radio-group v-model="secradio" @change="hanglesecradioChange">
              <div class="block">
              <el-radio class="radio" label="1">
                <span>按订购金额</span><span class="ml-10">消费满</span>
                <sy-input v-model="form.fullMoney" type="float" size="mini" :disabled="secradio == 2" :min="0.01" :max="99999999.99"></sy-input>
                <span>元</span>
                <span class='redalert' v-if="form.fullMoney =='' && secradio == 1">请输入订购金额</span>
              </el-radio>
            </div>
            <div class="block mt-10">
              <el-radio class="radio" label="2">
                <span>按订购数量</span><span class="ml-10">消费满</span>
                <sy-input v-model="form.fullNum" type="int" size="mini" :disabled="secradio == 1" :min="1" :max="99999999"></sy-input>
                <span>瓶</span>
                <span class='redalert' v-if="form.fullNum =='' && secradio == 2">请输入订购数量</span>
              </el-radio>
            </div>
            </el-radio-group>
          </template>
        </div>
      </el-form-item>
    </el-form>
    <el-form :model="form" label-width="128px" class="mt-40" :rules="rules">
      <el-form-item label="参与活动客户:" required>
        <div class="">
          <template>
            <el-radio class="radio" v-model="sevradio" label="1">所有客户</el-radio>
            <el-radio class="radio" v-model="sevradio" label="2">部分客户</el-radio>
          </template>
          <div class="" v-show="sevradio=='2'">
            <div class='tar'>
              <el-button type='text' @click="addclient">添加客户</el-button>
              <el-button type='text' @click="addAllClients">添加全部客户</el-button>
              <!-- <el-button type='text' @click="delclients">批量删除</el-button> -->
            </div>
            <tableA :size="[100,200,110,170]"></tableA>
            <chooseClient
                v-on:closedialog="dialog2Visible = false"
                :cVisible="dialog2Visible"
                :ifnotauto="true"
                >
            </chooseClient>
          </div>
        </div>
      </el-form-item>
    </el-form>
    <el-form :model="form" label-width="128px" class="mt-40" :rules="rules">
      <el-form-item label="有效日期:" required>
        <div class="">
          <template>
            <el-radio-group v-model="firradio" @change="handlefirradioChange">
              <div class="block">
                <el-radio class="radio" label="1">
                  <span>按截止时间  </span>
                  <span class="">
                    <template>
                      <div class="inline-block">
                        <span class="demonstration"></span>
                        <el-date-picker
                          v-model="value5"
                          type="datetime"
                          placeholder="选择日期时间"
                          :disabled="firradio != 1">
                        </el-date-picker>
                      </div>
                      <div class="inline-block">
                        前有效
                        <span class='redalert' v-if="value5 =='' && firradio == 1">请选择截止时间</span>
                      </div>
                    </template>
                  </span>
                </el-radio>
              </div>
              <div class="block mt-10">
                <el-radio class="radio" label="2">
                  <span>按起始时间  </span>
                  <span class="">
                    <template>
                      <div class="inline-block">
                        <!-- <el-date-picker
                          v-model="value4"
                          type="datetimerange"
                          placeholder="选择时间范围"
                          :disabled="firradio == 1 || firradio== 3">
                        </el-date-picker> -->
                        <ys-daterange-picker v-model="value4" :disabled="firradio == 1 || firradio== 3" type="datetime"/>
                      </div>
                      <span class='redalert' v-if="(!value4[0] || !value4[1]) && firradio == 2">请选择时间范围</span>
                    </template>
                  </span>
                </el-radio>
              </div>
              <div class="block mt-10">
                <el-radio class="radio" label="3">
                  <span>按领用时间  </span>
                  <sy-input v-model="form.effectiveDay" type="int" size="small" :disabled="firradio == 1 || firradio== 2" :min="1" :max="99999"></sy-input>
                  <span>天内有效(领取后开始计算)</span>
                  <span class='redalert' v-if="form.effectiveDay =='' && firradio == 3">请输入领用时间</span>
                </el-radio>
              </div>
            </el-radio-group>
          </template>
        </div>
      </el-form-item>
    </el-form>
    <el-form :model="form" label-width="128px" class="mt-40">
      <el-form-item label="领取方式:" required>
        <div class="">
          <template>
            <div class="block oh">
              <div class="block fl">
                <el-radio class="radio" v-model="thrradio" label="1">
                  <span>客户主动领取</span>
                </el-radio>
              </div>
              <div class="block ml-20 fl oh" v-show="thrradio == '1'">
                <template>
                  <el-radio-group v-model="fouradio" @change="handlefouradioChange">
                    <div class="block">
                    <el-radio class="radio" label="1">
                      <span>限制领用次数</span>
                      <sy-input v-model="form.receiveLimit" type="int" size="mini" :disabled="fouradio == 2" :min="1" :max="99999"></sy-input>
                      <span>次</span>
                      <span class='redalert' v-if="form.receiveLimit =='' && fouradio == 1">请输入领用次数</span>
                    </el-radio>
                  </div>
                  <div class="block mt-10">
                    <el-radio class="radio" label="2">
                      <span>不限用领用次数</span>
                    </el-radio>
                  </div>
                  </el-radio-group>
                </template>
              </div>
            </div>
            <div class="block mt-10 oh">
              <div class="block fl">
                <el-radio class="radio" v-model="thrradio" label="2">
                  <span>系统自动发放</span>
                </el-radio>
              </div>
              <div class="block ml-20 fl oh" v-show="thrradio == '2'">
                <template>
                  <div class="block">
                    <el-radio class="radio" v-model="fifradio" label="1">
                      <span>新注册用户</span>
                    </el-radio>
                  </div>
                  <div class="block mt-10">
                    <el-radio class="radio" v-model="fifradio" label="2">
                      <span>首次下单用户</span>
                    </el-radio>
                  </div>
                  <div class="block mt-10">
                    <div class="block fl">
                      <el-radio class="radio" v-model="fifradio" label="3">
                        <span>店铺下单用户</span>
                      </el-radio>
                    </div>
                    <div class="block ml-20 fl oh" v-show="fifradio == '3'">
                      <template>
                        <el-radio-group v-model="sixradio" @change="handlesixradioChange">
                          <div class="block">
                            <el-radio class="radio" label="1">
                              <span>按订购金额</span><span class="ml-10">消费满</span>
                              <sy-input v-model="form.autograntFullMoney" type="float" size="mini" :disabled="sixradio==2" :min="0.01" :max="99999999.99"></sy-input>
                              <span>元</span>
                              <span class='redalert' v-if="form.autograntFullMoney =='' && sixradio == 1">请输入订购金额</span>
                            </el-radio>
                          </div>
                          <div class="block mt-10">
                            <el-radio class="radio" label="2">
                              <span>按订购数量</span><span class="ml-10">消费满</span>
                              <sy-input v-model="form.autograntFullNum" type="int" size="mini" :disabled="sixradio==1" :min="1" :max="99999"></sy-input>
                              <span>瓶</span>
                              <span class='redalert' v-if="form.autograntFullNum =='' && sixradio == 2">请输入订购数量</span>
                            </el-radio>
                          </div>
                        </el-radio-group>
                      </template>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </template>
        </div>
      </el-form-item>
    </el-form>
    <el-form label-width="128px" class="mt-40">
       <el-form-item label="备注:">
          <div class="" style="max-width: 750px">
            <el-input
                type="textarea"
                :autosize="{ minRows: 4, maxRows: 6}"
                placeholder="请输入内容"
                v-model.trim="form.remark">
              </el-input>
          </div>
       </el-form-item>
    </el-form>
    <el-form label-width="128px">
      <div class="mt-10 ">
        <el-form-item>
          <el-button type="danger" @click="onSubmit" :loading="addloading">保存</el-button>
          <el-button @click="back">取消</el-button>
        </el-form-item>
      </div>
    </el-form>
  </div>
</template>

<script>
import {mapMutations, mapActions} from 'vuex'
import Confirm from '../../../utils/formconfirm'
import {basePath} from '../../../api/api'
// import YsDaterangePicker from "~/components/ys-daterange-picker";
function trimStr(str){
  return str.replace(/(^\s*)|(\s*$)/g,"")
}
export default {
  // components: {YsDaterangePicker},
  data() {
    var validatevalue1 = (rule, value, callback) => {
      if(!value[0] || !value[1]) {
        return callback(new Error('请选择活动时间范围'));
      }else if(value[0].getTime() == value[1].getTime()){
        return callback(new Error('开始时间和结束时间应设置不同'));
      } else {
        callback()
      }
    }
    var validatecouponname = (rule, value, callback) => {
      if(trimStr(value) == '') {
        return callback(new Error('请输入优惠券名称'));
      } else {
        callback()
      }
    }
    return {
      firradio: '1',
      secradio: '1',
      thrradio: '1',
      fouradio: '1',
      fifradio: '1',
      sixradio: '1',
      sevradio: '1',
      eigradio: '1',
      textarea: '',
      dialog1Visible: false,
      dialog2Visible: false,
      form: {
          couponName: '',
          num: '',
          minusMoney: '',
          fullMoney: '',
          fullNum: '',
          effectiveDay: '',
          autograntFullMoney: '',
          autograntFullNum: '',
          remark: '',
          receiveLimit: '',
          value1: [],
      },
       value2: '',
       value3: '',
       value4: [],
       value5: null,
       rules: {
          couponName: [
            { validator: validatecouponname, trigger: 'blur' }
          ],
          num: [
            { required: true, message: '请输入优惠券数量', trigger: 'blur' },
            { min: 1, message: '数量至少为1', trigger: 'change' }
          ],
          value1: [
            { validator: validatevalue1, required: true, trigger: 'change' }
          ],
          num: [
            { required: true, message: '请输入发放数量', trigger: 'blur' },
          ],
          minusMoney: [
            { required: true, message: '请输入消费券金额', trigger: 'blur' },
          ]
        }
     }
  },
  computed: {
    postDatagood() {
      return  {
        data: {
          notId: this.$store.getters.willaddid
        },
        currentPage: 1
      }
    },
    addloading() {
      return this.$store.state.ticket.addloading
    },

    postDataclient() {
      return  {
        data: {
          ids: this.$store.getters.willaddclientid,
          clientType: this.$route.query.couponSource|| 1, // B2B和酒店
        },
        currentPage: 1
      }
    },
    postDataaddticket() {
      return {
        data: Object.assign({}, this.form, {
          couponSource: this.$route.query.couponSource || 1, // 传值 1,2 区别酒店营销，B2B营销
          couponName: trimStr(this.form.couponName),
          gmtSendStart: Date.parse(this.form.value1[0]),
          gmtSendEnd: Date.parse(this.form.value1[1]),
          rangeTraderType: this.sevradio,
          rangeSkuType: '1',
          effectiveDateType: this.firradio,
          useLimitType: this.secradio,
          receiveLimit: this.fouradio =='2' ? '-1' : this.form.receiveLimit,
          autograntLimitType: this.sixradio,
          gmtEffectiveStart: Date.parse(this.value4[0]),
          gmtEffectiveEnd: this.value4[0] ? Date.parse(this.value4[1]) : Date.parse(this.value5),
          grantType: this.thrradio,
          autograntType: this.fifradio,
          skuList: this.$store.state.ticket.willadd.map((a)=>a.skuNo),
          buyerIdList: this.$store.state.clientlist.willadd.map((a)=>a.buyerId)
        })
      }
    },
    clientType(){
      return (this.$route.query.activitySource?this.$route.query.activitySource:this.$route.query.couponSource) || 1
    },
  },
  methods: {
    ...mapMutations({
      'deletegoods': 'deletegoods',
      'deleteclients': 'deleteclients'
    }),
    ...mapActions({
      'querygoodtableData': 'querygoodtableData',
      'queryclienttableData' : 'queryclienttableData',
      'addticket' : 'addticket',
      'chooseAllClientsAction': 'chooseAllClientsAction',
    }),
    back() {
      this.$router.push(basePath + '/activity')
    },
    onSubmit() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          let newconfirm = new Confirm();
          let confirmdata  = this.postDataaddticket.data
          // let time = new Date().getTime()
          newconfirm.use([confirmdata.couponName], '优惠券名称为空！')
          newconfirm.use([confirmdata.gmtSendStart], '发放时间为空！')
          newconfirm.use([confirmdata.num], '发放数量为空！')
          newconfirm.use([confirmdata.num], '发放数量为空！')
          newconfirm.use([confirmdata.minusMoney], '优惠券金额为空！')
          newconfirm.use([confirmdata.useLimitType, '1', confirmdata.fullMoney], '订购金额为空！')
          newconfirm.use([confirmdata.useLimitType, '2', confirmdata.fullNum], '订购数量为空！')
          newconfirm.use([confirmdata.rangeSkuType, '2', confirmdata.skuList], '未添加参与活动商品！')
          newconfirm.use([confirmdata.rangeTraderType, '2', confirmdata.buyerIdList], '未添加参与活动客户！')
          newconfirm.use([confirmdata.effectiveDateType, '1', confirmdata.gmtEffectiveEnd], '截止时间为空！')
          newconfirm.use([confirmdata.effectiveDateType, '2', confirmdata.gmtEffectiveStart], '起始时间为空！')
          newconfirm.use([confirmdata.effectiveDateType, '2', confirmdata.gmtEffectiveEnd], '起始时间为空！')
          newconfirm.use([confirmdata.effectiveDateType, '3', confirmdata.effectiveDay], '领用时间为空！')
          newconfirm.use([confirmdata.grantType, '1', confirmdata.receiveLimit], '领用次数为空！')
          newconfirm.use([confirmdata.grantType, '2', confirmdata.autograntType, '3', confirmdata.autograntLimitType, '1', confirmdata.autograntFullMoney], '订购金额为空！')
          newconfirm.use([confirmdata.grantType, '2', confirmdata.autograntType, '3', confirmdata.autograntLimitType, '2', confirmdata.autograntFullNum], '订购数量为空！')
          newconfirm.cs('int', confirmdata.num, '发放数量')
          newconfirm.cs('dot', confirmdata.minusMoney, '优惠券金额')
          if(confirmdata.useLimitType == '1') {
              newconfirm.cs('dot', confirmdata.fullMoney, '订购金额')
          } else if(confirmdata.useLimitType == '2'){
              newconfirm.cs('int', confirmdata.fullNum, '订购数量')
          }
          if(confirmdata.grantType == '1' && this.fouradio == '1') {
              newconfirm.cs('int', confirmdata.receiveLimit, '领用次数')
          }
          if(confirmdata.grantType == '2' && confirmdata.autograntType == '3' && confirmdata.autograntLimitType == '1') {
              newconfirm.cs('dot', confirmdata.autograntFullMoney, '订购金额')
          } else if(confirmdata.grantType == '2' && confirmdata.autograntType == '3' && confirmdata.autograntLimitType == '2'){
              newconfirm.cs('int', confirmdata.autograntFullNum, '订购数量')
          }
          if(confirmdata.couponName.length > 64) {
            newconfirm.status = false
            this.$message({
              type: "warning",
              message: "活动名称长度应小于64个字符"
            })
          }
          if(confirmdata.gmtSendStart == confirmdata.gmtSendEnd) {
            newconfirm.status = false
            this.$message({
              type: "warning",
              message: "发放时间开始时间和截止时间相同，请修改"
            })
          }
          if(confirmdata.remark.length > 5000) {
            newconfirm.status = false
            this.$message({
              type: "warning",
              message: "备注最大限5000字符，请修改"
            })
          }
          // if(confirmdata.gmtSendStart < time) {
          //   newconfirm.status = false
          //   this.$message({
          //     type: "warning",
          //     message: "发放时间开始时间应大于当前时间，请修改"
          //   })
          // }
          if(confirmdata.gmtEffectiveStart == confirmdata.gmtEffectiveEnd) {
            newconfirm.status = false
            this.$message({
              type: "warning",
              message: "有效日期开始时间和截止时间相同，请修改"
            })
          }
          if(confirmdata.gmtEffectiveEnd <= confirmdata.gmtSendStart) {
            newconfirm.status = false
            this.$message({
              type: "warning",
              message: "有效日期截止时间应大于发放开始时间"
            })
          }
          if (newconfirm.status) {
              this.addticket(this.postDataaddticket)
          } else {
              return false
          }
        }else{
          return false;
        }
      })
    },
    delgoods() {
      if(this.$store.state.ticket.willdelete.length != 0) {
        this.$msgbox({
          title: '提示信息',
          message: '您确定要删除这些商品？',
          showCancelButton: true,
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          beforeClose: (action, instance, done) => {
            if(action === 'confirm') {
              this.deletegoods()
              done();
            } else {
              done();
            }
          }
        })
      } else {
        this.$alert('请添加商品或者勾选需要批量删除的商品', '', {
          confirmButtonText: '确定',
          callback: action => {

          }
        });
      }
    },
    delclients() {
      if(this.$store.state.clientlist.willdelete.length != 0) {
        this.$msgbox({
          title: '提示信息',
          message: '您确定要删除这些客户？',
          showCancelButton: true,
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          beforeClose: (action, instance, done) => {
            if(action === 'confirm') {
              this.deleteclients()
              done();
            } else {
              done();
            }
          }
        })
      } else if(this.$store.state.clientlist.willadd.length == 0){
        this.$alert('无客户可删除，请先添加客户', '', {
          confirmButtonText: '确定',
          callback: action => {

          }
        });
      } else {
        this.$alert('请先勾选需要删除的客户', '', {
          confirmButtonText: '确定',
          callback: action => {

          }
        });
      }
    },
    addgood() {
      this.dialog1Visible = true
      this.querygoodtableData(this.postDatagood)
    },
    addclient() {
      this.dialog2Visible = true
      this.queryclienttableData(this.postDataclient)
    },
    addAllClients(){
      this.$msgbox({
        title: '提示',
        message: '是否选择全部客户？',
        showCancelButton: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        beforeClose: (action, instance, done) => {
          if(action === 'confirm') {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = '执行中...';
            let postData = {
              data: { sellerId: sessionStorage.traderId },
              currentPage: 1
            }
            this.chooseAllClientsAction({'instance':instance, 'done':done, 'postData': postData, 'clientType': this.clientType});
          } else {
            done();
          }
        }
      })
    },
    hanglesecradioChange(val) {
      if(val == 1) {
        this.form.fullNum = ''
      } else {
        this.form.fullMoney = ''
      }
    },
    handlefirradioChange(val) {
      switch (val) {
        case '1':
          this.value4 = []
          this.form.effectiveDay = ''
          break;
        case '2':
          this.value5 = ''
          this.form.effectiveDay = ''
          break;
        case '3':
          this.value4 = []
          this.value5 = ''
          break;
        default:
          return
      }
    },
    handlefouradioChange(val) {
      if(val == 2) {
        this.form.receiveLimit = ''
      }
    },
    handlesixradioChange(val) {
      if(val == 1) {
        this.form.autograntFullNum = ''
      } else {
        this.form.autograntFullMoney = ''
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
    })
  }
}
</script>

<style lang="less">
  .oh {
    overflow: hidden;
  }
  .redalert {
    color: #ff4949;
    font-size: 12px;
  }
</style>
