<template>
    <div class='hotelCenterStyle'>
        <div class="userInfo">
            <div class="userAllInfo">
                <img v-if="basicUserinfo.headportraitUrl" :src="basicUserinfo.headportraitUrl" alt="" class="userImage" />
                <img v-else src="../../assets/goods.jpg" class="userImage"/>
                <div class="userBasic">
                    <div class="userName">{{basicUserinfo.loginName}}</div>
                    <div class="userPhone" style="margin-top: 18px">{{tel}}</div>
                </div>
            </div>
            <div class="borderRight">
                <div style="cursor: pointer;" @click="goPage('/customer/accountInfo')">账号信息</div>
                <div style="cursor: pointer;margin-top: 17px" @click="goPage('/customer/accountSecurity')">安全设置</div>
            </div>
            <div class="clickStyle verti" @click="goPage('/customer/myaccount')">
                <div class="basicContent"><span style="font-size:16px">￥</span>{{this.balance}}</div>
                <div class="basicTitle">账户余额</div>
            </div>
            <div class="clickStyle" @click="goPage('/customer/store/myCollect')">
                <div class="basicContent">{{this.collectGood}}</div>
                <div class="basicTitle">收藏商品</div>
            </div>
            <div class="clickStyle" @click="goPageC('/customer/store/myDiscount')">
                <div class="basicContent">{{this.couponCount}}</div>
                <div class="basicTitle">优惠券</div>
            </div>
        </div>
        <div class="myOrder">
            <div class="orderTop">我的订单</div>
            <div class="orderBottom">
                <div class="clickStyle" v-for="item in orderInfo" :key="item.ind" @click="lookOrderList(item.ind)">
                    <div class="orderNum">{{item.num}}</div>
                    <div class="orderTitle">{{item.title}}</div>
                </div>
            </div>
        </div>
        <div v-loading="loading">
            <div class="table-item-default tac" v-if="ordersListResVo.length<=0">
                <img src="../../assets/empty.png" alt=""/>
                <div class="color-danger mt-30">
                    <router-link :to="{path:'/customer/shop/shopIndex'}">
                        <span class="color-danger"> 啥都没有，去首页看看 ></span>
                    </router-link>
                </div>
            </div>
            <div v-else>
                    <div class="table-item mt-20 font-12" v-for='order in ordersListResVo' :key='order.sn'>
                        <div class="clearfix table-item-top bg-e" style="paddingRight: 20px;">
                            <span class='fl ml-20'>订单编号：{{order.parentOrSon == 3?order.parentOrderSn:order.sn}}</span>
                            <span class='fl ml-20'>下单时间：{{order.gmtCreate | time}}</span>
                        </div>
                        <div v-if="order.parentOrSon == 1||(order.parentOrSon == 3&&order.orderStatus == 1)||(order.parentOrSon == 3&&order.orderStatus == 0)" style="background:white;">
                            <div class="table-item-content clearfix" style="display: flex;">
                                <div class="clearfix w-570" style="flex:6;">
                                    <!--商品清单-->
                                    <div class="bb clearfix" v-for="(orderItem, index) in order.orderItemVos" :key="index+'as'" style="display:flex">
                                        <div class="fl gift" style="flex:4">
                                            <div v-if="orderItem.changePurchaseItem"
                                                 class="triangle-topleft border-red"></div>
                                            <span v-if="orderItem.changePurchaseItem" class="init default">换购</span>
                                            <img v-if="orderItem.image" :src="orderItem.image" class="fl mr-10"/>
                                            <img v-else src="../../assets/goods.jpg" class="fl mr-10"/>
                                            <p>{{orderItem.skuName}}</p>
                                            <p class="color-9 mt-20">规格:1*{{orderItem.specInfoNum}}</p>
                                        </div>
                                        <div class="fl pbl-20" style="flex:1">¥{{orderItem.showUnitPrice |
                                            fmoney}}/{{orderItem.showUnitName}}
                                        </div>
                                        <div class="fl pbl-20 pl-40" style="flex:1">
                                            <div>{{orderItem.specNum}}{{orderItem.unit}}</div>
                                            <div class="color-danger  mt-10"
                                                 v-if="order.deliverySeparate&&order.orderStatus == 2">
                                                可提{{orderItem.remainingSpecNum}}{{orderItem.unit}}
                                            </div>
                                        </div>
                                    </div>
                                    <!--赠品清单-->
                                    <div class="bb clearfix " v-for="(orderItem, index) in order.orderGiftResVos" :key="index+'at'" style="display:flex">
                                        <div class="fl gift" style="flex:4">
                                            <div class="triangle-topleft border-red"></div>
                                            <span class="init default">赠品</span>
                                            <img v-if="orderItem.image" :src="orderItem.image" class="fl mr-10"/>
                                            <img v-else src="../../assets/goods.jpg" class="fl mr-10"/>
                                            <p>{{orderItem.skuName}}</p>
                                            <p v-if="orderItem.specInfoNum" class="color-9 mt-20">
                                                规格:1*{{orderItem.specInfoNum}}</p>
                                        </div>
                                        <div class="fl pbl-20" style="flex:1">¥0</div>
                                        <div class="fl pbl-20 pl-40" style="flex:1">
                                            <div>{{orderItem.giftNum}}{{orderItem.sellingUnitName}}</div>
                                            <div class="color-danger mt-10"
                                                 v-if="order.deliverySeparate&&order.orderStatus == 2">
                                                可提{{orderItem.remainingNum}}{{orderItem.sellingUnitName}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="bl bb tac pt-20" style="flex:1">
                                    <p>¥{{order.needPayMoney | fmoney}}</p>
                                    <p>含运费 (￥{{order.freightMoney | fmoney}})</p>
                                    <p class='mt-10 ' v-if="order.clientSource">
                                        <i v-if="order.clientSource != 1 && order.clientSource != 7 && order.clientSource!=10"
                                            class="iconfont icon-phone font-14 color-3"></i>
                                        <i v-else-if="order.clientSource==10" class="iconfont icon-store font-14 color-3"></i>
                                        <i v-else class="iconfont icon-computed font-14 color-3"></i>
                                            {{order.clientSource | clientSource}}
                                    </p>
                                </div>
                                <div class="bl bb tac pt-20" style="flex:1">
                                    <p v-if="order.editAuditState == 1 && order.orderStatus == 1" class="color-danger">
                                        待审核</p>
                                    <div v-else>
                                        <p v-if=" order.orderStatus == 1 ">待付款</p>
                                        <p class="color-danger font-12"
                                           v-if="(order.editAuditState == 3||order.editAuditState == null) && order.orderStatus == 1 && (order.lastestRemittanceAuditStatus == 2 || order.lastestRemittanceAuditStatus == null)">
                                            <!-- 剩余<span
                                                v-text="typeof(order.remainAutoCancleTime)=='string'?order.remainAutoCancleTime:null"></span> -->
                                        </p>
                                    </div>
                                    <p v-if=" order.orderStatus == 0 ">已关闭</p>
                                    <p v-if=" order.orderStatus == 2 ">{{ order.shipStatus === 4 ? '部分发货' : '待发货'}}</p>
                                    <p v-if=" order.orderStatus == 3 ">已发货</p>
                                    <p v-if=" order.orderStatus == 4 ">已完成</p>
                                </div>
                                <div class="bl bb tac pt-20 pb-10" style="flex: 2;">
                                    <div class="w-100">
                                        <div>
                                            <el-button type="danger" class="mt-10 font-12"
                                                       @click="orderDetail(order.parentOrSon == 3?order.parentOrderSn:order.sn,order.parentOrSon == 3?2: 1)">
                                                查看订单
                                            </el-button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="order.parentOrSon == 3&&(order.orderStatus != 1&&order.orderStatus!=0)" style="background:white">
                            <div v-for="(orderSon) in order.sonOrders" :key="orderSon.sn">
                                <div class="clearfix table-item-top">
                                    <span class='fl ml-20'>订单编号：{{orderSon.sn}}</span>
                                    <!-- <span class='fl ml-20'>下单时间：{{orderSon.gmtCreate | time}}</span> -->
                                    <img class="ml-20 fr separate" v-if="orderSon.deliverySeparate"
                                         style="height:50px;width:auto;" src="../../assets/separate.png"/>
                                    <img class="ml-20 fr separate" v-if="orderSon.openStraight"
                                         style="height:50px;width:auto;" src="../../assets/straight.png"/>
                                </div>
                                <div class="table-item-content clearfix" style="display: flex;">
                                    <div class="clearfix w-570" style="flex:6">
                                        <!--商品清单-->
                                        <div class="bb clearfix" v-for="(orderItem, index) in orderSon.orderItemVos" :key="index+'au'" style="display:flex">
                                            <div class="fl gift" style="flex:4">
                                                <div v-if="orderItem.changePurchaseItem"
                                                     class="triangle-topleft border-red"></div>
                                                <span v-if="orderItem.changePurchaseItem" class="init default">换购</span>
                                                <img v-if="orderItem.image" :src="orderItem.image" class="fl mr-10"/>
                                                <img v-else src="../../assets/goods.jpg" class="fl mr-10"/>
                                                <p>{{orderItem.skuName}}</p>
                                                <p class="color-9 mt-20">规格:1*{{orderItem.specInfoNum}}</p>
                                            </div>
                                            <div class="fl pbl-20" style="flex:1">¥{{orderItem.showUnitPrice |
                                                fmoney}}/{{orderItem.showUnitName}}
                                            </div>
                                            <div class="fl pbl-20 pl-40" style="flex:1">
                                                <div>{{orderItem.specNum}}{{orderItem.unit}}</div>
                                                <div class="color-danger  mt-10"
                                                     v-if="orderSon.deliverySeparate&&orderSon.orderStatus == 2">
                                                    可提{{orderItem.remainingSpecNum}}{{orderItem.unit}}
                                                </div>
                                            </div>
                                        </div>
                                        <!--赠品清单-->
                                        <div class="bb clearfix " v-for="(orderItem, index) in orderSon.orderGiftResVos" :key="index+'av'" style="display:flex">
                                            <div class="fl gift" style="flex:4">
                                                <div class="triangle-topleft border-red"></div>
                                                <span class="init default">赠品</span>
                                                <img v-if="orderItem.image" :src="orderItem.image" class="fl mr-10"/>
                                                <img v-else src="../../assets/goods.jpg" class="fl mr-10"/>
                                                <p>{{orderItem.skuName}}</p>
                                                <p v-if="orderItem.specInfoNum" class="color-9 mt-20">
                                                    规格:1*{{orderItem.specInfoNum}}</p>
                                            </div>
                                            <div class="fl pbl-20" style="flex:1">¥0</div>
                                            <div class="fl pbl-20 pl-40" style="flex:1">
                                                <div>{{orderItem.giftNum}}{{orderItem.sellingUnitName}}</div>
                                                <div class="color-danger mt-10"
                                                     v-if="orderSon.deliverySeparate&&orderSon.orderStatus == 2">
                                                    可提{{orderItem.remainingNum}}{{orderItem.sellingUnitName}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="bl bb tac pt-20" style="flex:1">
                                        <p>¥{{orderSon.needPayMoney |
                                        fmoney}}</p>
                                        <p>含运费 (￥{{order.freightMoney | fmoney}})</p>
                                        <p class='mt-10 ' v-if="order.clientSource">
                                        <i v-if="order.clientSource != 1 && order.clientSource != 7"
                                           class="iconfont icon-phone font-14 color-3"></i>
                                            <i v-else-if="order.clientSource==10" class="iconfont icon-store font-14 color-3"></i>
                                        <i v-else class="iconfont icon-computed font-14 color-3"></i>
                                        {{order.clientSource | clientSource}}</p>
                                    </div>
                                    <div class="bl bb tac pt-20" style="flex:1">
                                        <p v-if="orderSon.editAuditState == 1 && orderSon.orderStatus == 1"
                                           class="color-danger">待审核</p>
                                        <div v-else>
                                            <p v-if=" orderSon.orderStatus == 1 ">待付款</p>
                                        </div>
                                        <p v-if=" orderSon.orderStatus == 0 ">已关闭</p>
                                        <p v-if=" orderSon.orderStatus == 2 ">{{ orderSon.shipStatus === 4 ? '部分发货' :
                                            '待发货'}}</p>
                                        <p v-if=" orderSon.orderStatus == 3 ">已发货</p>
                                        <p v-if=" orderSon.orderStatus == 4 ">已完成</p>
                                    </div>
                                    <div class="bl bb tac pt-20  pb-20" style="flex:2">
                                        <div class="w-100">
                                            <div>
                                                <el-button type="danger" class="mt-10 font-12" @click="orderDetail(orderSon.sn,1)">查看订单
                                                </el-button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        <div class="myCollection">
            <div class="collectionTop orderTop">
                <div class="collectionName">我的收藏</div>
                <div style="cursor: pointer;" @click="goPage('/store/myCollect')">查看全部</div>
            </div>
            <div class="collectionBottom" v-if="collectionList.length!=0">
                <div class="collectionGood" v-for="(item,index) in collectionList" :key="index+'aw'" @click="lookGoodDetail(index)">
                    <div class="maskWhite" v-if="item.skuState==1">
                        <i class="icon-no-affect iconfont" style="font-size:55px"></i>
                    </div>
                    <img v-if="!item.fileUrl" src="../../assets/goods.jpg" alt="" class="collectionImage">
                    <img v-else :src="item.fileUrl" class="collectionImage"/>
                    <div class="collectionInfo">
                        <div class="collectionName">{{item.skuName}}</div>
                        <div class="collectionPrice"><span :style="item.skuState==1?'':'color: #E94A13;'">{{item.priorityPrice}}</span><span class="color-9 font-12" v-if="item.showUnitName">/{{item.showUnitName}}</span></div>
                    </div>
                    <div class="collectionBtn" @click.stop="deleteCollection(index)">
                        <i class="iconfont icon-delete font-30 mt-10 ml-15 fl"></i>
                    </div>
                </div>
            </div>
            <div v-else class="noData">暂无数据</div>
        </div>
    </div>
</template>


<script>
import {axiosApi,basePath} from '~/api/api';
import { mapState,mapMutations } from 'vuex';
export default {
    data () {
        return {
            tel:"",
            customMenuHidden: false,
            basicUserinfo: {
                employeeName:"",
                headportraitUrl:"",
                loginName:""
            },
            orderInfo: [{
                title: '待付款',
                num: 0,
                ind: 1,
            },{
                title: '待发货',
                num: 0,
                ind: 2,
            },{
                title: '已发货',
                num: 0,
                ind: 3,
            },{
                title: '已完成',
                num: 0,
                ind: 5
            },{
                title: '全部订单',
                num:0,
                ind:0
            }],
            ordersListResVo:[],
            collectionList:[],
            balance: 0,
            collectGood: 0,
            couponCount: 0,
            loading:false,
            isExist:false,
            createLabel:{}
        };
    },

    components: {},

    computed: {
        ...mapState('nav',['jusAllNav','tagNav'])
    },

    mounted(){
        this.getInfo()
        this.getCollectionList();
        this.getOrderList();
        this.getOrderNumber();
        
    },
    filters: {
		clientSource(val) {
			let str = '';
			switch (val) {
				case 1: {
					str = '云商PC端';
					break
				}
				case 2: {
					str = '云商IOS端';
					break
				}
				case 3: {
					str = '云商Android端';
					break
				}
				case 4: {
					str = '云销IOS端';
					break
				}
				case 5: {
					str = '云销Android端';
					break
				}
				case 6: {
					str = 'WX下单';
					break
				}
				case 7: {
					str = 'PC代下单';
					break
				}
				case 8: {
					str = '餐链下单';
					break
				}
                case 9: {
                    str = '超B下单';
                    break
                }
                case 10: {
                    str = '门店POS';
                    break
                }
				default : {
					str = '';
					break
				}
			}
			return str
		}
    },

    methods: {
        ...mapMutations('nav',['addUserNav','changeTagNav','changNowNav',]),
        orderDetail(orderSn, type) {		//去订单详情页面
            this.isExist = false;
            this.confirmJurisdiction(this.jusAllNav,'/customer/store/purchaseOrder');
            if(this.isExist){
                let a = null
                if (type == 2) a = 2
                if (type == 1) a = 1
                sessionStorage.setItem('orderSn', orderSn)
                sessionStorage.isParentPurchaseOrderType = a //因为订单详情里面 酒店版酒集根据截取url 判断是否是酒集订单所以不能用 url传值 判断是否是拆分订单
                sessionStorage.purchaseOrderType = this.type;
                window.open(window.location.origin + basePath + '/purchaseOrderDetail')
                // this.$router.push({path: basePath + '/purchaseOrderDetail'})
            }else {
                this.$message({
                    type: "info",
                    message: "您没有访问权限",
                    duration: 1000,
                })
                return;
            }
        },
        // 查询个人信息
        getInfo() {
            axiosApi(
                "/trader/manyStoresHomePage/select",
                {loginName: sessionStorage.loginName},
                (res) => {
                    console.log(res,'success')
                this.$set(this.basicUserinfo,'employeeName',res.data.data.employeeName)
                this.$set(this.basicUserinfo,'headportraitUrl',res.data.data.headportraitUrl)
                this.$set(this.basicUserinfo,'loginName',res.data.data.loginName)
            },0,100,res=>{
                console.log(res,'fail')
            })
            axiosApi("/trader/buyer/homePage/query",{traderId:sessionStorage.traderId},res=>{
                this.balance = res.data.data.balance;
                this.couponCount = res.data.data.couponCount;
                this.tel = res.data.data.tel
            })
        },
        // 获取收藏商品列表
        getCollectionList() {
			const api = "/trader/collectGoodsList/select";
			const body = {
				buyerId: sessionStorage.traderId,
				classifyId: null,
				brandId: null
			}
			axiosApi(api, body, (res)=>{
                this.collectionList = res.data.data!=null?res.data.data.elements:[];
                this.collectGood = res.data.data.totalCount;
            }, 1, 5,(res)=>{
                this.$message({
                    message: res.data.message,
                    type: 'info',
                    duration: 1000
                });
            })
        },
        
        deleteCollection(index) {
            this.isExist = false;
            this.confirmJurisdiction(this.jusAllNav,'/customer/store/myCollect');
            if(this.isExist){
                let that = this;
				this.$confirm('您是否要删除「' + this.collectionList[index].skuName + '」？', '提示信息', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					const api = "/trader/collectGoods/update";
					const body = [{
						buyerId: sessionStorage.traderId,
						skuNo: this.collectionList[index].skuNo,
						classifyId: this.collectionList[index].classifyId,
						brandId: this.collectionList[index].brandId
					}];
					axiosApi(api, body, (res) => {
						this.$message({
							type: 'success',
							message: '删除成功!',
							duration: 1000
						});
						that.getCollectionList();
					}, 0, 10, (res) => {
						this.$message({
							type: 'info',
							message: res.data.message,
							duration: 3000
						});
					});
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				})
            }else {
                this.$message({
                    type: "info",
                    message: "您没有删除权限",
                    duration: 1000,
                })
                return;
            }
			
		},
        getOrderList () {
            let body = {
                cancleReason: null,   //订单取消原因
                orderDateRange: [],   //gmtOrderStart下单开始时间 gmtOrderEnd下单结束时间
                itemName: null,		//itemName商品名称
                orderSn: null,   //订单编号
                gmtOrderStart: null,
                gmtOrderEnd: null,
                traderType: '1',
                buyerId: sessionStorage.traderId,
                orderStatus: null,    //订单状态
                shipStatus: null//订单提货
            }
            this.loading = true;
            axiosApi('/anon/order/list/query', body, (res) => {
                this.loading = false;
                this.ordersListResVo = res.data.data.elements ?res.data.data.elements:[];
                
            },1,5)
        },
        // 获取订单角标
        getOrderNumber(){
            axiosApi('/anon/order/count', {
                traderType:1,
                sellerId: sessionStorage.traderId 
            }, (res) => {
                let allCount = 0
					for (let k in  res.data.data) {
                        if(k!='showErpErrorCount'&&k!='deliverySeparateCount'){
                            allCount += res.data.data[k]
                        }
            
                    }
					this.orderInfo[4].num = allCount;

					this.orderInfo.map(v => {
						if(v.title == '待付款'){
                            v.num = res.data.data.notPayCount;
                        }else if(v.title == '待发货'){
							v.num = res.data.data.notDeliveryCount;
						}else if(v.title == '已发货'){
							v.num = res.data.data.orderDeliveriedCount;
						}else if(v.title == '已完成'){
							v.num = res.data.data.successCount;
						}
                    })
                // this.badge = Object.assign(this.badge, res.data.data);
                // let allCount = 0
                // for (let k in this.badge) {
                //     if (k != 'allCount' && k != 'deliverySeparateCount' && k != 'notAuditCount') {
                //         allCount += this.badge[k]
                //     }
                // }
                // this.badge.allCount = allCount
                // Object.keys(this.badge).forEach((i, k) => {
                //     if (this.dataTabs[k]) {
                //         this.dataTabs[k].num = this.badge[i]
                //     }
                // })
            }, 1, 10, (res) => {

            })
        },
        goPage(path) {
            this.isExist = false;
            this.createLabel = {}
            this.confirmJurisdiction(this.jusAllNav,path);
            if(this.isExist){
                if(this.createLabel && this.createLabel.url) {
                    this.addUserNav(this.createLabel.id)
                    let arr = this.$deepClone(this.tagNav)
                    arr.push(this.createLabel)
                    this.changeTagNav(arr)
                    this.changNowNav(this.createLabel)
                }
                this.$router.push(path)
            }else {
                this.$message({
                    type: "info",
                    message: "您没有访问权限",
                    duration: 1000,
                })
            }
            // 
        },
        goPageC(path) {
            this.isExist = false;
            this.createLabel = {}
            this.confirmJurisdiction(this.jusAllNav,path);
            if(this.isExist){
                if(this.createLabel && this.createLabel.url) {
                    this.addUserNav(this.createLabel.id)
                    let arr = this.$deepClone(this.tagNav)
                    arr.push(this.createLabel)
                    this.changeTagNav(arr)
                    this.changNowNav(this.createLabel)
                }
                this.$router.push({path: path,query:{type:1}})
            }else {
                this.$message({
                    type: "info",
                    message: "您没有访问权限",
                    duration: 1000,
                })
            }
            // 
        },
        lookOrderList(ind) {
            const url = '/customer/store/purchaseOrder'
            this.isExist = false;
            this.createLabel ={}
            this.confirmJurisdiction(this.jusAllNav,url);
            if(this.isExist){
                if(this.createLabel && this.createLabel.url) {
                    this.addUserNav(this.createLabel.id)
                    let arr = this.$deepClone(this.tagNav)
                    arr.push(this.createLabel)
                    this.changeTagNav(arr)
                    this.changNowNav(this.createLabel)
                }
                let type = sessionStorage.setItem('purchaseOrderType',ind)
                this.$router.push({
                    path:url,
                })
            }else {
                this.$message({
                    type: "info",
                    message: "您没有访问权限",
                    duration: 1000,
                })
            }
            
        },
        confirmJurisdiction(arr,url){
            arr.some(item=>{
                if(item.url == url){
                    this.isExist = true;
                    this.createLabel = item;
                    return true;
                }else {
                    if(item.sub.length != 0){
                        this.confirmJurisdiction(item.sub,url)
                    }
                }
            })
        },
        lookGoodDetail(index){
            if(this.collectionList[index].skuState == 1){
                return;
            }
            this.$router.push(
                basePath + '/shop/goodsDetails/' + this.collectionList[index].skuNo
            )
            
        }
    }
}

</script>
<style lang='less' scoped>
    .hotelCenterStyle {
        font-size: 12px;
        .userInfo {
            width: 100%;
            display: flex;
            padding: 20px 25px;
            box-sizing: border-box;
            background: white;
            .userAllInfo {
                display: flex;
                text-align: left;
            }
            .userImage {
                width: 50px;
                height: 50px;
                border-radius: 50%;
                margin-right: 10px;
            }
            &>div {
                width: 20%;
                text-align: center;
                .basicContent {
                    color: #E94A13;
                    margin-bottom: 16px;
                    font-size: 18px;
                }
            }
            .borderRight {
                border-right: 1px solid #E7E7E7;
            }
        } 
        .myOrder {
            margin-top: 20px;
            background: white;
            .orderBottom {
                display: flex;
                justify-content: space-around;
                align-items: center;
                padding: 25px 0;
                &>div {
                    text-align:center;    
                    .orderNum {
                        color: #E94A13;
                        margin-top: 10px;
                        font-size: 18px;
                    }
                }
            }
        }
        .myCollection {
            margin-top: 20px;
            .collectionTop {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
            .collectionBottom {
                display: flex;
                align-items: center;
            }
            .collectionBottom {
                background: white;
                padding: 0 20px 20px 20px;
                flex-wrap: wrap;
                border-left: 1px solid #DDD;
                border-bottom: 1px solid #DDD;
                border-right: 1px solid #DDD;
                .collectionGood {
                    width: 246px;
                    border: 1px solid #DDD;
                    margin-top: 20px;
                    margin-right: 20px;
                    display: inline-block;
                    position: relative;
                    .collectionImage {
                        width: 246px;
                        height: 246px;
                    }
                    .maskWhite {
                        width: 246px;
                        height:246px;
                        background: rgba(255, 255, 255, 0.6);
                        position: absolute;
                        left:0;
                        right:0;
                    }
                    .collectionInfo {
                        width: 206px;
                        line-height: 20px;
                        height: 60px;
                        border-bottom: 1px solid #DDD;
                        text-align: left;
                        padding: 20px;
                        .collectionName {
                            text-align: left;
                            height: 40px;
                            overflow:hidden;
                            text-overflow: ellipsis;
                        }
                        .collectionPrice {
                            text-align: left;
                        }
                    }
                    .collectionBtn {
                        position: absolute;
                        right: 0;
                        top: 0;
                        &:hover i {
                            color: #FF4201
                        }
                        width: 60px;
                        height: 0px;
                        background: rgba(0, 0, 0, 0.1);
                        overflow: hidden;
                        transition: all 0.5s;
                        &:hover i {
                            color: #FF4201
                        }
                    }
                }
                .collectionGood:hover {
                    .collectionBtn {
                        height: 60px;
                    }
                }
            }
        }
    }
    .orderTop {
        border: 1px solid #DDDDDD;
        padding: 10px 20px;
        background: #eee;
    }
        .bb {
            border-bottom: 1px solid #DDDDDD;
        }

        .bl {
            border-left: 1px solid #DDDDDD;
        }

        .orderDetail_btn {
            height: auto;
            margin-top: 10px;
        }

        .w-570 {
            display: flex;
            flex-wrap: wrap;

            > div {
                padding: 20px;
                padding-right: 0;
                width: 100%;
                box-sizing: border-box;
            }
        }

        .hide {
            display: none;
        }

        .table_header {
            height: 40px;
            line-height: 40px;
        }
            .el-date-editor--daterange.el-input {
                width: 240px;
            }

            .el-form--inline {
                width: 650px;

                .el-form-item__label {
                    font-size: 12px;
                    width: 60px;
                }
            }

            .el-table .el-table__body-wrapper {
                display: none;
            }

            .table-item {
                .bl {
                    border-left: 1px solid #DDD;
                }

                border: 1px solid #DDDDDD;
                font-size: 14px;
                border-bottom: none;

                .table-item-content .pbl-20 {
                    padding: 0px 0px 20px 20px;
                }

                .table-item-content {
                    .gift {
                        position: relative;
                    }

                    .triangle-topleft {
                        border-top: 60px solid #ff4201;
                        width: 0;
                        height: 0;
                        border-right: 60px solid transparent;
                        position: absolute;
                        top: 0;
                        left: 0;
                    }

                    .init {
                        transform: rotate(-45deg) translate(-4px, 10px);
                        position: absolute;
                        left: 0;
                        top: 0;
                        color: #fff;
                        letter-spacing: -0.34px;
                    }
                }

                .table-item-top {
                    border-bottom: 1px solid #DDDDDD;
                    /*// padding: 10px 0;*/
                    line-height: 40px;
                    padding-right: 65px;
                    position: relative;

                    .separate {
                        position: absolute;
                        top: 0;
                        right: 0;
                    }

                    .table-item-top-checkbox {
                        margin-left: 14px
                    }
                }

                img {
                    width: 80px;
                    height: 80px;
                }
            }

            .table-item-default {
                height: 230px;
                padding-top: 120px;
                background:white;
            }
    .clickStyle {
        cursor: pointer;
    }
    .noData {
        background:white;
        height:300px;
        width:100%;
        text-align:center;
        line-height:300px;
        font-size:18px;
    }
</style>
