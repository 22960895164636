<template>
    <el-dialog title="订单退货" :visible.sync="show" :class="isOver ? 'default-dialog' : 'dialog-w1000'"
               @close="handleClose">
        <div v-if="isOver">{{msg}}</div>
        <template v-else>
            <listInfo :goodsList="sendGoodsForm.deliveryItemList"/>
            <!-- <el-form :model="sendGoodsForm" class="mt-20" label-width="80px">
                <el-form-item label='退货备注' prop="remark">
                    <el-input :maxlength="30" v-model="sendGoodsForm.deliveryNote"></el-input>
                </el-form-item>
            </el-form> -->
        </template>

        <div slot="footer" class="dialog-footer">
            <el-button type="danger" :loading="btnLoading" @click="handleSubmit">确 定</el-button>
        </div>
    </el-dialog>
</template>

<script>
    import {axiosApi, basePath} from '~/api/api' //导入axiosApi
    import listInfo from './listInfo.vue'
    export default {
        components: {listInfo},
        props: {
            dialogVisible: Boolean,
            orderSn: String,
            sellerName: String
        },
        data() {
            return {
                show: false,
                sendGoodsForm: {
                    deliveryItemList: [],
                    gmtDelivery: new Date(),
                    deliveryNote: ''
                },
                pickerOptions0: {
                    disabledDate(time) {
                        return time.getTime() < Date.now() - 8.64e7;
                    }
                },
                btnLoading: false,
                isOver: false,
                msg: '',
                copyArr: []
            }
        },
        watch: {
            dialogVisible(val) {
                this.show = val
                if (val) {
                    this.querySeparateOrderInfo()
                }
            }
        },
        methods: {
            handleClose() {// 弹框关闭
                this.sendGoodsForm.deliveryNote = ''// 清空备注信息
                this.$emit('update:dialogVisible', false)
            },
            // 查询剩余发货商品信息
            querySeparateOrderInfo() {
                this.isOver = false
                axiosApi('/order/orderitem/list', {orderSn: this.orderSn}, res => {
                    // 格式化列表数据
                    const orderItems = res.data.data.orderItems.map(n => {
                        return {
                            rowId: n.rowId,// 订单提货传值 activityId
                            skuName: n.skuName,
                            skuNo: n.skuNo,
                            skuImage: n.skuImage,
                            specNum: n.remainingSpecNum, // 需要发货的数量
                            allSpecNum: n.specNum, // 总数量
                            remainingSpecNum: n.remainingSpecNum, // 可发量
                            specUnit: n.isIntegerMultiple ? '箱(规格单位)' : n.baseUnit + '(基本单位)', // 销售单位
                            skuGift: false, // 是否是赠品
                            k3SaleType: n.k3SaleType,
                            k3SaleTypeName: n.k3SaleTypeName,
                            isIntegerMultiple: n.isIntegerMultiple,// 是否正箱发货
                            changeCoefficient: n.changeCoefficient,// 规格数量
                            salesUnit: n.unit, // 销售单位
                            changePurchaseItem: n.changePurchaseItem,
							              baseUnit:n.baseUnit
                        }
                    })
                    const gitfItems = res.data.data.gitfItems.map(n => {
                        return {
                            rowId: n.rowId,// 订单提货传值 activityId
                            skuNo: n.skuNo,
                            skuName: n.skuName,
                            skuImage: n.skuImage || null,
                            allSpecNum: n.giftNum, // 总数量
                            remainingSpecNum: n.remainingNum, // 剩余销售数量
                            specNum: n.remainingNum, // 需要发货的数量
                            specUnit: n.isIntegerMultiple ? '箱(规格单位)' : n.baseUnitName + '(基本单位)', // 销售单位
                            skuGift: true, // 是否是赠品
                            isIntegerMultiple: n.isIntegerMultiple,// 是否正箱发货
                            changeCoefficient: n.changeCoefficient,// 规格数量
                            salesUnit: n.sellingUnitName, // 销售单位
                            giftType: n.giftType,// 赠品0标品，1非标品
                            k3GiftType: n.k3GiftType,
                            k3GiftTypeName: n.k3GiftTypeName,
                            k3SaleType: n.k3SaleType,
                            k3SaleTypeName: n.k3SaleTypeName,
                            changePurchaseItem: n.changePurchaseItem,
                            pickupType: res.data.data.pickupType, //是否支持自主提货,
                            deliverySeparate: res.data.data.deliverySeparate,
                            baseUnit:res.data.data.baseUnit ? res.data.data.baseUnit : '箱',
                            baseUnitName:n.baseUnitName
                        }
                    })
                    this.sendGoodsForm.deliveryItemList = orderItems.concat(gitfItems)
                    this.sendGoodsForm.deliveryItemList.forEach(item=>{
                      let arr = [
                        {// 瓶箱下拉选择框
                            label: '箱(规格单位)',
                            value: '箱(规格单位)'
                        }, {
                            label: item.skuGift ? item.baseUnitName + '(基本单位)' : item.baseUnit + '(基本单位)',
                            value: item.skuGift ? item.baseUnitName + '(基本单位)' : item.baseUnit + '(基本单位)'
                        }
                      ]
                      item.unitOptions = arr
                    })
                    if (this.sendGoodsForm.deliveryItemList.length === 0) {
                        this.isOver = true
                        this.msg= '无可退货商品！'
                    }
                })
            },
            handleSubmit() {
                if (this.isOver) {
                    this.show = false
                    return
                }
                this.copyArr = JSON.parse(JSON.stringify(this.sendGoodsForm.deliveryItemList))
                this.copyArr.forEach((v, i) => {
                    if (v.skuGift) {// 赠品
                        if (v.giftType == 0 && !v.isIntegerMultiple && Math.floor(v.remainingSpecNum / v.changeCoefficient) > 0) {
                            if (v.specUnit == '箱(规格单位)') {
                                this.$set(this.copyArr[i], 'specNum', v.specNum * v.changeCoefficient)
                            }
                        }
                        if (v.giftType == 0 && !v.isIntegerMultiple && Math.floor(v.remainingSpecNum / v.changeCoefficient) <= 0) {
                            this.$set(this.copyArr[i], 'specUnit', v.salesUnit)
                        }
                    } else {// 商品
                        if (!v.isIntegerMultiple && Math.floor(v.remainingSpecNum / v.changeCoefficient) > 0) {
                            if (v.specUnit == '箱(规格单位)') {
                                this.$set(this.copyArr[i], 'specNum', v.specNum * v.changeCoefficient)
                            }
                        }
                        if (!v.isIntegerMultiple && Math.floor(v.remainingSpecNum / v.changeCoefficient) <= 0) {
                            this.$set(this.copyArr[i], 'specUnit', v.salesUnit)
                        }
                    }
                })
                const body = {
                    orderSn: this.orderSn,
                    gmtDelivery: this.sendGoodsForm.gmtDelivery.getTime(),
                    deliveryNote: this.sendGoodsForm.deliveryNote,
                    deliveryItemList: this.copyArr.filter(n => (n.specNum > 0)),
                }
                if (body.deliveryItemList.length === 0) {
                    this.$message({type: 'error', message: '退货商品数量不能全部为0'})
                    return
                }
              
                this.btnLoading = true
                axiosApi('/order/deliveryItem/reduce', body, (res) => {
                    this.btnLoading = false
                    this.$message.success('您的退货申请提交成功!')
                    this.show = false
                    this.$emit('deliverySuccessCallback')
                }, null, null, (err) => {
                    this.btnLoading = false
                    // this.$message.error(err.data.message)
                }, 1)
            }
        }
    }
</script>
<style lang="less">
    .default-dialog {
        .el-dialog {
            min-height: 240px;
        }

        .el-dialog__body {
            padding: 28px 130px 90px;
        }
    }
</style>
