<template>
  <div id='com-withdraw-online' class="content bg-f pl-30 pr-30 mt-5">
    <!-- 子公司-》佣金提现-》线上提现-》社会版+门店版 -->
    <div class="clearfix search">
      <el-form :inline="true" class="pt-30" :model="form">
        <el-form-item label="申请时间">
          <ys-daterange-picker v-model="form.addDateRange" :endDisabledDate="new Date()" />
        </el-form-item>
        <el-form-item
          class="ml-20"
          label="销售员账号"
          :rules="[{pattern:/^[1]\d{10}$/,message:'请输入正确手机号',trigger:'change,blur'}]"
          prop="cNum"
        >
          <el-input v-model.trim="form.cNum" placeholder="请输入销售员账号" :maxlength="11"></el-input>
        </el-form-item>
        <el-form-item
          label="提现状态"
          :rules="[{pattern:/^[1]\d{10}$/,message:'请输入正确手机号',trigger:'change,blur'}]"
          prop="cNum"
        >
          <el-select v-model="form.withdrawState" placeholder="请选择">
            <el-option
              v-for="item in withdrawStateList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div>
        <el-button class="ml-20 mb-20" type="info" @click="search" icon="el-icon-search">搜 索</el-button>
      </div>
    </div>
    <div class="pb-30" v-loading="Loading">
      <el-table
        :data="elements"
        style="width: 100%;"
        @selection-change="handleSelectionCheck"
      >
        <el-table-column label="申请单号" width>
          <template slot-scope="scope">
            <div>{{scope.row.applyWithdrawSn}}</div>
          </template>
        </el-table-column>
        <el-table-column label="销售员账号" width="120">
          <template slot-scope="scope">
            <span>{{scope.row.loginName}}</span>
          </template>
        </el-table-column>
        <el-table-column label="销售员名称" width="90">
          <template slot-scope="scope">
            <span>{{scope.row.storeName}}</span>
          </template>
        </el-table-column>
        <el-table-column label="提现金额" width>
          <template slot-scope="scope">
            <span>￥{{scope.row.withdrawMoney | fmoney}}</span>
          </template>
        </el-table-column>
        <el-table-column label="收款渠道" width>
          <template slot-scope="scope">
            <div>{{scope.row.accountName}}</div>
            <div>({{scope.row.name}} {{scope.row.accountNumber}})</div>
          </template>
        </el-table-column>

        <el-table-column label="申请时间" width>
          <template slot-scope="scope">
            <a>{{scope.row.gmtCreate | time}}</a>
          </template>
        </el-table-column>
        <el-table-column label="提现状态" width>
          <template slot-scope="scope">
            <!--  handle-处理中；fail-失败；success-成功-->
            <el-tooltip
              v-if="scope.row.withdrawState === 'fail'"
              class="item"
              effect="dark"
              :content="scope.row.failReason"
              placement="bottom-end"
            >
              <el-button type="text">失败</el-button>
            </el-tooltip>
            <div v-else-if="scope.row.withdrawState === 'handle'">
              <span v-if="scope.row.isOffline">
                <el-button type="text" @click="handlePass(scope)">确认打款</el-button>
              </span>
              <span v-else>处理中</span>
            </div>
            <span v-else>成功</span>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        v-if="elements.length>0"
        class="tar mt-20"
        :current-page="currentPage"
        :page-size="10"
        :total="totalCount"
        layout="prev , pager, next, jumper"
        @current-change="handleCurrentChange"
      >
        <!-- 分页组件 -->
      </el-pagination>
    </div>
    <!--弹出框-->
    <el-dialog class="reject" title="驳回" :visible.sync="reject">
      <el-form class="pr-30 pl-30" :model="formC">
        <el-form-item class="ml-40">
          <el-input
            type="textarea"
            :autosize="{ minRows: 3 ,maxRows: 10}"
            :maxlength="100"
            placeholder="请输入驳回理由.."
            v-model.trim="formC.reason"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="danger" @click="reject = false">确定</el-button>
        <el-button @click="reject = false">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { axiosApi, basePath } from "../../../api/api";

export default {
  data() {
    return {
      type: "0",
      clickedTabName: "佣金提现",
      dataTabs: [],
      form: {
        addDateRange: [],
        cNum: null,
        auditState: null,
        startTime: null,
        endTime: null,
        withdrawState: ""
      },
      elements: [],
      Loading: false,
      reject: false,
      currentPage: 1,
      totalCount: 1,
      formC: {
        reason: null
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > new Date();
        }
      },
      idList: [], //确认打款商户Id列表
      withdrawStateList: [
        {
          value: "",
          label: "全部"
        },
        {
          value: "success",
          label: "成功"
        },
        {
          value: "fail",
          label: "失败"
        },
        {
          value: "handle",
          label: "处理中"
        }
      ]
    };
  },
  props: ['supercType'],
  methods: {
   
    handleSelectionCheck(val) {
      //选中的状态
      this.idList = []; //清空每次全选时添加的订单编号
      val.forEach(item => {
        if (item.auditState == 0) {
          this.idList.push(item.id);
        }
      });
    },
    handleCurrentChange(val) {
      //分页
      this.currentPage = val;
      this.queryList(val);
    },
    queryList(x) {
      this.Loading = true;
      let body = {
        loginName: this.form.cNum,
        auditState: null,
        startTime: this.form.startTime,
        endTime: this.form.endTime,
        supercType: this.supercType,
        withdrawState: this.form.withdrawState
      };
      axiosApi(
        "/superc/commissionWithdrawApply/selectPageList",
        body,
        res => {
          this.Loading = false;
          this.elements = res.data.data.elements;
          this.totalCount = res.data.data.totalCount;
        },
        x ? x : 1,
        10
      );
    },
    search() {
      this.form.startTime = this.form.addDateRange[0]
        ? this.form.addDateRange[0].getTime()
        : null;
      this.form.endTime = this.form.addDateRange[1]
        ? this.form.addDateRange[1].getTime()
        : null;
      this.queryList();
    },
    timeFormat(value) {
      var myDate = new Date(value);
      var year = myDate.getFullYear();
      var month =
        myDate.getMonth() + 1 < 10
          ? "0" + (myDate.getMonth() + 1)
          : myDate.getMonth() + 1;
      var day =
        myDate.getDate() < 10 ? "0" + myDate.getDate() : myDate.getDate();
      return year + "-" + month + "-" + day;
    },
    handlePass(scope) {
      //确认打款
      if (scope.row.id) {
        //单个商品上下架数据处理
        this.idList = []; //处理每次添加的订单编号
        this.idList.push(scope.row.id);
      }
      if (this.idList.length > 0) {
        const h = this.$createElement;
        this.$msgbox({
          title: "提示信息",
          message: h("p", { style: "text-align:center" }, "确认打款？"),
          showCancelButton: true,
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          beforeClose: (action, instance, done) => {
            if (action === "confirm") {
              axiosApi(
                "/superc/commissionWithdrawApply/updateState",
                { idList: this.idList },
                res => {
                  this.queryList(); //默认加载佣金提现列表
                },
                1,
                10
              );
              done();
            } else {
              done();
            }
            delete instance.$slots.default;
          }
        });
      } else {
        this.$message({
          type: "info",
          message: "请选择需要打款账户",
          duration: 1000
        });
      }
    },
    handleReject(scope) {
      //驳回
      this.reject = true;
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.queryList()//加载默认列表
    })
  }
};
</script>

<style lang="less">
.commission-cashout {
  .box-switch{
  	box-sizing: border-box;
  	width: 264px;
  	height: 45px;
  	line-height: 15px;
  	margin-top: 15px;
  	margin-right: 10px;
  }
  .content {
    .el-form--inline {
      .el-form-item__label {
        font-size: 12px;
        width: 75px;
      }
      .order-datatimerange {
        width: 240px;
      }
    }
    .el-table .cell {
      text-align: center;
      padding: 10px 0;
      .el-button {
        height: 18px;
      }
      .el-button + .el-button {
        margin-left: 0;
      }
    }
    .el-textarea {
      .el-textarea__inner {
        border-radius: 0;
      }
    }
    .reject {
      .el-dialog {
        min-height: 290px;
      }
    }
  }
}
</style>