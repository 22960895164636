<template>
	<div id="storeTop">
		<div class="header">
			<div class="width-1280 flex p45">
				<div>
					<span>杭州市</span>
					<span class="cursor ml-30 mr-30 f4 a">
						<i class="iconfont a icon-message color-b"></i>
						<span class="ml3 lh12">消息</span>
					</span>
					<span class="cursor f4">{{name}}</span>
					<span class="ml-10 cursor f4">退出</span>
				</div>
				<div class="flex">
					<ul>
						<li><span class="lh12">首页</span></li>
						<li><i class="iconfont icon-shopping-car color-b"></i><span class="ml-5 lh12">购物车</span></li>
						<li><i class="iconfont icon-collect color-b"></i><span class="ml-5 lh12">收藏夹</span></li>
						<li><i class="iconfont icon-order color-b"></i><span class="ml-5 lh12">我的订单</span></li>
						<li><span class="ml-10 lh12">我的账户</span></li>
					</ul>
					<div class="ml-30 color-danger">服务电话：<span>400-888-9979</span></div>
				</div>
			</div>
		</div>
		<div id="goodsSearch">
			<div id="shop" class="bg-f font-16">
				<div class="width-1280 plr-45">
					<div class="logo">
						<el-row type="flex" justify="space-between">
							<el-col :span="8">
								<img class="cursor" src="../../assets/logo-r.png" />
							</el-col>
							<el-col class="mt-30">
								<div class="clearfix">
									<el-input class="fl" placeholder="请输入关键词搜索"></el-input>
									<el-button class="fl" >搜 索</el-button>
								</div>
							</el-col>
							<el-col class="mt-30" :span="4">
								<el-button class="font-12 border-radius-no">
									<span><i class="iconfont icon-shopping-car color-danger"></i></span>
									<span class="color-danger ml-10">我的购物车</span>
									<!-- <span class="sub">0</span> -->
								</el-button>
							</el-col>
						</el-row>
					</div>
				</div>
			</div>
			<div class="table bg-f">
				<div class="width-1280 plr-45 tab clearfix">
					<ul class="nav-w">
						<li class="fl cursor">
							<span>全部商品</span>
							<i class="el-icon-caret-bottom font-14"></i>
						</li>
					</ul>
					<span class="fr font-16">店铺：{{name}}</span>
				</div>
			</div>
			<div id="shopStore" class="clearfix">
				<div class="banner" :style="bgSupplierSmallImg"></div>
				<div class="storeSearch clearfix">
					<div class="width-1280 plr-45">
						<ul>
							<li class="fl cursor"><span class="color-white">店铺首页</span></li>
							<li class="fl cursor"><span class="color-white">全部商品</span></li>
						</ul>
						<div class="clearfix fr mt-10">
							<el-input class="fl" placeholder="搜索本店商品"></el-input>
							<el-button class="fl font-16">搜索</el-button>
							<el-button class="ml-10 fr">收藏店铺</el-button>
						</div>
					</div>
				</div>
				<div class="banner bannerBig" :style="bgSupplierBigImg"></div>
			</div>
			<div class="width-1280 plr-45">
				<div class="main mt-10 mb-50">
					<div>
						<ul class="clearfix product">
							<li class="fl  mr-5 bg-f mt-5 cursor" id="pro" v-for='item in goodsList'>
								<img v-if="!item.fileUrl" src="../../assets/goods.jpg" alt="">
								<img v-else :src="item.fileUrl" />
								<div class="seckillList pt-20">
									<p class="one mb-20 font-14 dp color-6 text-tow-overflow">{{item.skuName}}</p>
									<p class="dp color-danger font-20" v-if="item.enterShopPrice < 10000">￥{{item.enterShopPrice | fmoney}}</p>
									<p class="dp color-danger font-20" v-else-if="item.enterShopPrice >= 10000">￥{{item.enterShopPrice/10000 | fmoney}}万</p>
								</div>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
		<!--尾部-->
		<footer>
			<foot></foot>
		</footer>
	</div>
</template>

<script>
	import { axiosApi, basePath } from "../../api/api"
	import Foot from "../../components/foot.vue";
	export default {
		components: {
			Foot
		},
		data() {
			return {
				name: null,
				bgSupplierSmallImg:{
					backgroundImage: "url("+ localStorage.bgSupplierSmallImg +")"
				},
				bgSupplierBigImg:{
					backgroundImage: "url("+ localStorage.bgSupplierBigImg +")"
				},
				goodsList: []
			}
		},
		computed: {

		},
		mounted() {
			this.$nextTick(function() {
				axiosApi("/goods/sku/search" ,{buyerId: sessionStorage.traderId} ,(res) => {
					//console.log(res,'商品列表')
	                this.goodsList = res.data.data.page.elements ? res.data.data.page.elements : [];
	            }, 1, 10)
				axiosApi("/trader/findStoreInfo/select", {traderId: sessionStorage.traderId}, (res)=>{
					//console.log(res,'店铺信息')
					this.name = res.data.data.storeName;
				}, 1, 10)
			})
		}
	}
</script>

<style lang="less">
	#storeTop {
		background: #F5F5F5;
		.header {
			background-color: #f5f5f5;
			height: 36px;
			line-height: 36px;
			color: #666;
			font-size: 12px;
			.ml3{
				margin-left: 3px;
			}
			.f4:hover{
				color: #ff4201;
			}
			.f4.a:hover{
				.iconfont.a{
					color: #ff4201;
				}
			}
			.el-select.s:hover{
				.el-input__icon{
					color: #ff4201;
				}
			}
			.el-badge__content {
				height: 15px;
				line-height: 15px;
				padding: 0 4px;
				position: absolute;
				top: -18px;
				left: -5px;
			}
			.flex {
				display: flex;
				justify-content: space-between;
				li {
					cursor: pointer;
				}
				.lh12 {
					display: inline-block;
					height: 12px;
					line-height: 12px;
				}
				ul li {
					margin-left: 30px;
					float: left;
					cursor: pointer;
					&:hover {
						span,
						.iconfont {
							color: #ff4201;
						}
					}
				}
			}
		}
		#goodsSearch{
			#shop {
				.font-19{
					font-size: 19px;
				}
				font-size: 16px;
				.logo {
					height: 108px;
					background: #fff;
					.border-radius-no{
						border-radius: 0;
					}
					.el-col-8{
						width: 199px;
						img {
							width: 190px;
							height: 39px;
							margin-top: 30px;
						};
						.platform {
							display: inline-block;
							color: #FF4201;
							vertical-align: top;
							font-size: 24px;
						};
					};
					.el-col-24{
						width: 600px;
						.el-input{
							width: 520px;
						};
						.el-input__inner{
							border: 2px solid #FF4201;
							border-right: 0;
							height: 36px !important;
						};
						.el-button{
							width: 80px;
							height: 36px;
							color: #FFF;
							font-size: 16px;
							background: #FF4201;
							border: 2px solid #FF4201;
							border-radius: 0;
						};
					};
					.el-col-4{
						width: 190px;
						.el-button{
							width: 190px;
							height: 36px;
						};
						span{
							vertical-align: middle;
						};
						.sub{
							min-width: 12px;
							height: 16px;
							line-height: 16px;
							padding:0 2px;
							display: inline-block;
							background: #FF4201;
							border-radius: 8px;
							color: #fff;
							margin-left: 8px;
						}
					}
				}
			}
			.table{
				border-bottom: 2px solid #FF4201;
				.tab{
					height: 50px;
					line-height: 50px;
					text-align: center;
					.nav-w>li {
						width: 132px;
						color: #000;
					};
					.nav-w>li:nth-child(1) {
						width: 190px;
						background: #FF4201;
						color: #fff;
					};
				}
			}
			#shopStore{
				margin: 0 auto;
				font-size: 16px;
				.banner{
					width: 100%;
					height: 120px;
					/*// background-size: cover;*/
				    background-repeat:no-repeat;
				    background-position:center center;
				}
				.bannerBig{
					height: 600px;
				}
				.storeSearch{
					height: 50px;
					line-height: 50px;
					background: #FF4201;
					text-align: center;
					ul li {
						width: 132px;
					}
					ul li:nth-child(1) {
						width: 190px;
						background: rgba(0,0,0,0.1);
					}
					ul li:hover {
						background: rgba(0,0,0,0.1);
					}
					.el-input{
						width: 270px;
					};
					.el-input__inner{
						border-right: 0;
						height: 30px !important;
					};
					.el-button{
						width: 80px;
						height: 30px;
						border-left: 0;
						border-radius: 0;
						background: #F5F5F5;
					};
				}

			}
			.product li {
				width: 234px;
				height: 353px;
				position: relative;
				img {
					width: 234px;
					height: 234px;
					display: block;
				}
				.seckillList {
					width: 194px;
					height: 118px;
					border-top: 1px solid #DDDDDD;
					margin: 0 auto;
					.one {
						line-height: 14px;
						width: 197px;
						height: 28px;
					}
				}
			}
			.product li:nth-child(5n) {
				margin-right: 0 !important;
			}
		}
		footer{
			background-color: #333;
			color: #fff;
		}
	}

</style>
