<template >
    <div class="">
        <el-table :data="tabletotalData" :row-class-name="tableRowClassName" tooltip-effect="dark" class="mt-10 table-default" @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="50" v-if="check">
            </el-table-column>
            <el-table-column label="品牌图片">
                <template slot-scope="scope">
                    <img class="bd" :src="scope.row.logoIcon" v-if="scope.row.logoIcon" />
                    <img class="bd" src="./../../assets/brand.png" v-else />
                </template>
            </el-table-column>
            <el-table-column label="品牌名称" prop='brandName'>
            </el-table-column>
            <el-table-column label="分类" prop="classifyName">
                <template slot-scope="scope">
                    <span>{{scope.row.classifyName || '--'}}</span>
                </template>
            </el-table-column>
            <el-table-column label="操作">
                <template slot-scope="scope">
                    <el-button type="text" @click.native.prevent="deleteRow(scope.$index, tableData)" size="small">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <!-- <el-pagination
          class="tar mt-20 mr-50"
          :current-page="currentPage"
          :page-size='10'
          :total='total'
          layout="prev , pager, next, jumper"
          v-if="tabletotalData.length>10"
          @current-change="handleit"
          >
        </el-pagination> -->
    </div>
</template>

<script>
  import {mapMutations} from 'vuex';
  export default {
    props: ['size','check'],
    data() {
      return {
        currentPage: 1,
        tableData: [],
      }
    },
    // watch: {
    //   currentPage() {
    //     this.tableData = this.tabletotalData.slice((this.currentPage-1)*10, (this.currentPage)*10)
    //   },
    //   total() {
    //     this.tableData = this.tabletotalData.slice((this.currentPage-1)*10, (this.currentPage)*10)
    //   }
    // },
    computed: {
      // total() {
      //   return this.$store.state.clientlist.willadd.length
      // },
      tabletotalData() {
        return this.$store.state.clientBrand.brandList
      }
    },
    methods: {
      ...mapMutations({
        'delBrand': 'delBrand',
        'clearwilladd': 'clearwilladd2',
        'willdeteleclients': 'willdeteleclients',
      }),
      tableRowClassName(row,index){
        if(row.isDeleted){
          return 'bg-danger'
        }else{
          return ""
        }
      },
      handleit(val) {
        this.currentPage = val
      },
      handleSelectionChange(val) {
        this.willdeteleclients(val)
      },
      deleteRow(index, rows) {
        let that = this;
        this.$msgbox({
          title: '提示信息',
          message: '您确定要删除这条信息？',
          showCancelButton: true,
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          beforeClose: (action, instance, done) => {
            if(action === 'confirm') {
              this.delBrand({index: index})
              done();
            } else {
              done();
            }
          }
        })
      }
    },
    mounted() {
      this.$nextTick(()=>{
        this.clearwilladd();
      })
    }
  }
</script>

<style lang="less">
</style>
