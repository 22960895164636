<template>
	<div>
		<div id="ball"></div>
		<div id="rightMenu" class="side font-12">
			<ul>
				<li @click="pathGo('/shop/shopingCar')">
					<div class="sidebox color-white">
						<i id="shopping-empty" class="iconfont icon-shopping-empty font-20 mr-10 fr color-white"></i>
						<span>购物车</span>
					</div>
					<div class="shop-car-num" v-if="shoppingCarNum > 0 || shoppingCarNum === '99+'">{{ shoppingCarNum }}</div>
				</li>
				<li v-for="item in rightMenu" :key="item.url" @click="pathGo(item.url)">
					<div class="sidebox color-white">
						<i :class="item.icon" class="iconfont font-20 mr-10 fr color-white"></i>
						<span >{{item.title}}</span>
					</div>
				</li>
				<li>
					<div class="sidebox color-white">
						<i class="iconfont icon-down-app font-20 mr-10 fr color-white"></i>
					</div>
					<div class="qr qrshow">
						<img src="../assets/qrimg.png" alt="" />
						<span>下载APP，有更多惊喜</span>
					</div>
				</li>

			</ul>
		</div>
	</div>
</template>
<script>
	import { basePath } from '../api/api'
	// import {bus} from "../main.js"
	export default {
		name: 'rightMenu',
		data() {
			return {
				basePath:basePath,
				rightMenu:[
					// {title: "购物车", icon:"icon-shopping-empty", url:"/shop/shopingCar"},
					{title: "收藏夹", icon:"icon-collection", url:"/store/myCollect"},
					{title: "我的订单", icon:"icon-order", url:"/store/purchaseOrder"},
					{title: "消息中心", icon:"icon-message-null", url:"/search1/messageCenter"},
					{title: "我的账户", icon:"icon-user", url:"/accountInfo"},
					{title: "客服电话：400-888-9979", icon:"icon-service"}
				],
				isloginUser: false,
				// shoppingCarNum: 0
			}
		},
		computed: {
			shoppingCarNum() {
				return this.$store.state.homeSearch.shoppingCarNum
			}
		},
		mounted() {
			this.$nextTick(() => {
				this.isloginUser = !sessionStorage.getItem('loginName') ? false : true;

			})
		},
		// created () {
		//   bus.$on('shoppingCarNum', (data) => {
		//     this.shoppingCarNum = data;
		//   })
		// },
		methods: {
			pathGo(url){
				if (!this.isloginUser&& !!url) {
					/* this.$alert('请先登录', '提示信息', {
	                    confirmButtonText: '确定'
					}) */
					this.$store.commit('setloginDialog',{type : true , url : url});
	                return false;
				}else if(url) {
					this.$router.push(basePath + url);
				}
			}
		}
	}
</script>
<style lang="less">
	#ball{
		position: fixed;
		top: 0;
		left: 0;
    	width: 10px;
    	height: 10px;
    	border-radius: 50%;
    	background-color: #ff4201;
		visibility: hidden;
		z-index: 1000;
	}
	#rightMenu{
			width: 40px;
			position: fixed;
			top: 50%;
			right: 0;
			z-index: 100;
			transform: translateY(-50%);
			ul li{
				width: 40px;
				height: 40px;
				position: relative;
				float: left;
				margin-bottom: 1px;
				cursor: pointer;
				.sidebox{
					width: 40px;
					height: 40px;
					line-height: 40px;
					text-align: center;
					position: absolute;
					top: 0;
					right: 0;
					background: #BBBBBB;
					overflow: hidden;
					transition: all 0.3s;
				}
				.shop-car-num{
					min-width: 12px;
					height: 16px;
					line-height: 16px;
					text-align: center;
					padding:0 2px;
					display: inline-block;
					background: #FF4201;
					border-radius: 8px;
					color: #fff;
					margin-left: 8px;
					position: absolute;
					top: 5px;
					right: 18px;
				}
			};
			ul li:hover{
				.sidebox{
					width: 120px;
					background: #FF4201;
				}
				.shop-car-num{
					background-color: #fff;
					color: #ff4201;
				}
			}
			ul li:nth-last-child(2):hover{
				.sidebox{
					width: 220px;
				}
			}
			ul li:last-child:hover{
				.sidebox{
					width: 40px;
				}
				.qrshow {
					display: block;
				}
			}
			.qrshow{
				display: none;
			}
			.qr{
				width: 180px;
				height: 180px;
				background: #FFFFFF;
				border: 2px solid #FF4201;
				position: absolute;
				right: 40px;
				bottom: 0;
				img{
					width: 120px;
					height: 120px;
					padding-left: 30px;
					padding-right: 30px;
					padding-top: 20px;
				}
				span{
					display: block;
					text-align: center;
					color: #F6140B;
				}
			}

		}
</style>
