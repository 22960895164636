<template>
	<div id="supercOrder" class="bg-f">
		<div class="box-con bdt">
			<!--订单基础信息-->
			<div class="order-info">
				<div class="order-info-tit pl-20">订单信息</div>
				<div class="order-info-con clearfix">
					<div class="order-info-conL fl">
						<div>
							<label>订单编号：</label>
							<span class="ml-10">{{orderInfo.orderSn}}</span>
						</div>
						<div class="clearfix">
							<div class="fl tar" style="width: 150px;">供货商名称：</div>
							<span class="ml-10 fl">{{orderInfo.sellerName}}</span>
						</div>
						<div>
							<label>订单状态：</label>
							<span class="ml-10" v-if="orderInfo.orderStatus==0">已关闭</span>
							<span class="ml-10 color-danger" v-if="orderInfo.orderStatus==1">待付款</span>
							<span class="ml-10" v-if="orderInfo.orderStatus==2">待发货</span>
							<span class="ml-10" v-if="orderInfo.orderStatus==3">已发货</span>
							<span class="ml-10" v-if="orderInfo.orderStatus==4">已完成</span>
						</div>
						<div v-if="orderInfo.orderStatus==0" class="order-cancel-flex">
							<label>关闭原因：</label>
							<span class="ml-10">{{orderInfo.cancelReason}}</span>
						</div>
					</div>
					<div class="fr mt-30 mr-20">
						<span v-if="orderInfo.orderStatus==1">
							<span class="mr-20">还剩<span class="color-danger">{{orderInfo.remainAutoCancelTime}}</span>自动取消</span>
							<el-button @click="handleCancelOrder()">取消订单</el-button>
						</span>
						<el-button v-if="orderInfo.orderStatus==2 && (orderInfo.isJdeSeller==false && orderInfo.isK3Seller==false)" @click="handleSend()">发货</el-button>
					</div>
				</div>
			</div>
			<!--取消订单弹出框-->
			<el-dialog class='cancelOrderDialog' title='取消订单理由' :visible.sync='cancelOrderVisible'>
				<el-form class="pl-30 pr-30" :model="form" ref="cancelOrderForm" :rules="rules2">
					<el-form-item label="" prop='cancleReason'>
						<el-input type='textarea' placeholder='请输入取消订单的理由...' :autosize="{ minRows: 3 ,maxRows: 10}" v-model.trim='form.cancleReason'></el-input>
					</el-form-item>
				</el-form>
				<div slot='footer' class="dialog-footer">
					<el-button type='danger' @click="handleCommit(2)">确定</el-button>
					<el-button @click="cancelOrderVisible = false">取 消</el-button>
				</div>
			</el-dialog>
			<!--选择物流公司-->
			<el-dialog class='sendGoods' title='发货信息' :visible.sync='sendGoods'>
				<el-form class="pl-30 pr-30"  :model="form" ref="sendGoods" :rules="rules3">
					<el-form-item label='选择物流' prop="expressName">
						<el-select v-model="form.expressName" placeholder="请选择物流" clearable>
							<el-option v-for="express in expressList" :key="express.companyName" :label="express.companyName" :value="express.companyName" ></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="物流编号" prop='expressNum'>
						<el-input placeholder='请输入物流编号' v-model.trim='form.expressNum'></el-input>
					</el-form-item>
				</el-form>
				<div slot='footer' class="dialog-footer">
					<el-button type='danger' @click="handleCommit(3)">确定</el-button>
					<el-button @click="sendGoods = false">取 消</el-button>
				</div>
			</el-dialog>
			<!--tab切换-->
			<el-tabs class="mt-20" v-model="show" @tab-click="handleClick">
				<el-tab-pane label="订单详情" name="first"></el-tab-pane>
				<el-tab-pane label="出库/发货" name="second"></el-tab-pane>
				<el-tab-pane label="授信凭证" v-if="orderInfo.payMethod === '2'" name="voucher"></el-tab-pane>
			</el-tabs>
			<!--切换内容--订单详情-->
			<div v-if="show=='first'">
				<!--购买商品基本信息-->
				<div class="goods mt-20">
					<div class="goods-info clearfix" v-for="g in orderInfo.itemList" :key="g.id">
						<div class="goods-infoL fl clearfix">
							<span class="fl">
								<img v-if="g.fileUrl" :src="g.fileUrl" alt="" />
								<img v-else src="../../assets/store-big.jpg" alt="" />
							</span>
							<span class="w380 ml-15 fl">
								<p>{{g.skuName}}</p>
								<span class="mt-10 color-9" v-if="g.specInfoNum">规格: 1*{{g.specInfoNum}}</span>
							</span>
							<span class="w210 fl tac">￥{{g.discountPrice||0 | fmoney}}</span>
							<span class="fl ml-15">{{g.num}}{{g.unit}}</span>
						</div>
						<span class="goods-infoR tac pt-20">￥{{g.discountPrice*g.num | fmoney}}</span>
					</div>
					<div class="goods-money clearfix">
						<div class="goods-moneyR fr">
							<div><label><span class="mr-20">共{{orderInfo.skuTotalNum}}件商品</span>商品金额：</label><span class="ml-10">¥{{orderInfo.goodsTotalMoney||0 | fmoney}}</span></div>
							<div>
								<label>运费：</label>
                <span class="ml-10" >¥{{orderInfo.freightMoney||0 | fmoney}}</span>
							</div>
							<div><label>优惠券：</label><span class="ml-10">-¥{{orderInfo.couponCutMoney||0 | fmoney}}</span></div>
							<div><label>实付金额：</label><span class="ml-10">¥{{orderInfo.needPayMoney||0 | fmoney}}</span></div>
							<div class="font-20 color-danger"><label>销售佣金：</label><span class="ml-10">¥{{orderInfo.commission||0 | fmoney}}</span></div>
							<div v-if='orderInfo.salesCommission' class="font-20 color-danger"><label>推广员佣金：</label><span class="ml-10">¥{{orderInfo.salesCommission||0 | fmoney}}</span></div>
						</div>
					</div>
				</div>
				<!--收货信息-->
				<div class="order-info mt-20">
					<div class="order-info-tit pl-20">收货信息</div>
					<div class="order-info-con">
						<div class="order-info-conL">
							<div class="flex"><label>收货人：</label><span class="ml-10">{{orderInfo.addrUsername}}</span></div>
							<div class="flex"><label>联系电话：</label><span class="ml-10">{{orderInfo.addrTel}}</span></div>
							<div class="flex"><label>收货地址：</label><span style="width: 75%" class="ml-10">{{orderInfo.addrInfo}}</span></div>
						</div>
					</div>
				</div>
				<!--备注信息-->
				<div class="order-info mt-20">
					<div class="order-info-tit pl-20">备注信息</div>
					<div class="order-info-con">
						<div class="order-info-conL">
							<div class="clearfix flex">
								<label class="fl">备注信息：</label>
								<div class="fl" style="width: 75%">
									<span class="ml-10" v-if="orderInfo.remark">{{orderInfo.remark}}</span>
									<span class="ml-10" v-else>无</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!--发票信息-->
				<div class="order-info mt-20" v-if="orderInfo.invoiceInfoVO">
					<div class="order-info-tit pl-20">发票信息</div>
					<div class="order-info-con">
						<div class="order-info-conL">
							<div>
								<label>发票信息：</label>
								<span class="ml-10" style="width: 75%" v-if="orderInfo.invoiceInfoVO.personalCompanyType==null">无</span>
								<span class="ml-10" style="width: 75%" v-if="orderInfo.invoiceInfoVO.personalCompanyType==2">个人</span>
								<span class="ml-10" style="width: 75%" v-if="orderInfo.invoiceInfoVO.personalCompanyType==1">
									<span>{{orderInfo.invoiceInfoVO.companyHead}}</span>
									<span class="ml-5">{{orderInfo.invoiceInfoVO.taxpayerIdNo}}</span>
								</span>
							</div>
							<div class="flex">
								<label>发票地址：</label>
								<span class="ml-10" v-if="orderInfo.invoiceInfoVO.personalCompanyType==null && orderInfo.invoiceInfoVO.provinceId==null">无</span>
								<span class="ml-10" style="width: 75%" v-else>
									<span>{{orderInfo.invoiceInfoVO.linkman}}</span>
									<span class="ml-5">{{orderInfo.invoiceInfoVO.tel}}</span>
									<span class="ml-5">{{orderInfo.invoiceInfoVO.provinceName}}{{orderInfo.invoiceInfoVO.cityName}}{{orderInfo.invoiceInfoVO.regionName}}</span>
									<span class="ml-5">{{orderInfo.invoiceInfoVO.addr}}</span>
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>

			<!--赠品信息-->
      <div class="order-info mt-20" v-if="orderInfo.noticeGiftAddrUsername">
        <div class="order-info-tit pl-20">赠品信息</div>
        <div class="order-info-con">
          <div class="order-info-conL order-info-conL2">
            <div class="flex">
              <label style="min-width: 150px;">赠品信息：</label>
              <span class="ml-10">{{orderInfo.noticeGiftDescription}}</span>
            </div>
            <div class="flex">
              <label>收货地址：</label>
							<div class="ml-10">
								<span>{{orderInfo.noticeGiftAddrUsername}}</span>
								<span class="ml-5">{{orderInfo.noticeGiftAddrTel}}</span>
								<span class="ml-5">{{orderInfo.noticeGiftAddrInfo}}</span>
							</div>
            </div>
          </div>
        </div>
      </div>

			<!--切换信息--出库-->
			<div v-if="show=='second'">
				<!--配送信息-->
				<div class="order-info mt-20" v-if="orderInfo.orderStatus==3 || orderInfo.orderStatus==4">
					<div class="order-info-tit pl-20">配送信息</div>
					<div class="order-info-con">
						<div class="order-info-conL">
							<div>
								<label>物流公司：</label>
								<span class="ml-10">{{orderInfo.expressName}}</span></div>
							<div>
								<label>物流单号：</label>
								<span class="ml-10">{{orderInfo.expressNum}}</span>
							</div>
							<div>
								<label>运费：</label>
								<span class="ml-10">￥{{orderInfo.freightMoney | fmoney}}</span>
							</div>
							<div>
								<label>发货时间：</label>
								<span class="ml-10">{{orderInfo.gmtShipped | time}}</span>
							</div>
						</div>
					</div>
				</div>
				<!--商品列表-->
				<div class="mt-20">
					<el-table :data="orderInfo.itemList">
						<el-table-column label='商品编号' align="center">
							<template slot-scope="scope">
								<span>{{scope.row.skuNo}}</span>
							</template>
						</el-table-column>
						<el-table-column label='商品名称' align="center">
							<template slot-scope="scope">
								<span>{{scope.row.skuName}}</span>
							</template>
						</el-table-column>
						<el-table-column label='数量' align="center">
							<template slot-scope="scope">
								<span>{{scope.row.num}}</span>
							</template>
						</el-table-column>
					</el-table>
				</div>
			</div>
			<!-- 授信凭证 start  -->
			<div v-show="show === 'voucher' && orderInfo.payMethod === '2'">
				<div class="voucher-box">
					<!-- 查看 凭证 -->
					<!-- <imgview ref="imgview" :visible="imgviewVisible" :imgs="voucherImgList" :startindex="0" @close="imgviewVisible=false"></imgview> -->
					<order-viewredentials
					:imgList="voucherImgList"
					:bankInfo="bankInfo"
					:type="1"
					:id="''"
					ref="viewredentials"></order-viewredentials>
					<el-table style="width: 100%" :data="voucherTableList" border tooltip-effect="dark" stripe class="mt-20 table-default">
						<el-table-column :resizable="false" prop="operatorName" label="操作人">
							<template slot-scope="scope">
								<span v-if="scope.row.operatorName">{{scope.row.operatorName}}</span>
								<span v-else>--</span>
							</template>
						</el-table-column>
						<el-table-column :resizable="false" prop="gmtModified" label="操作时间">
							<template slot-scope="scope">
								<span v-if="scope.row.gmtModified">{{scope.row.gmtModified | time}}</span>
								<span v-else>--</span>
							</template>
						</el-table-column>
						<el-table-column :resizable="false" prop="stateName" label="审核状态">
							<template slot-scope="scope">
								<span v-if="scope.row.stateName">{{scope.row.stateName}}</span>
								<span v-else>--</span>
							</template>
						</el-table-column>
						<el-table-column :resizable="false" label="操作">
							<template slot-scope="scope">
								<!-- 0-未上传；1-通过；2-驳回；3-审核中 -->
								<!-- 审核-通过  -->
								<template v-if="scope.row.state === 1">
									<el-button style="width:auto" @click="handleVoucherSee(scope.row)" :style="{marginRight : '10px'}" type="text">查看凭证</el-button>
								</template>
								<!-- 审核-驳回 -->
								<template v-else-if="scope.row.state === 2">
									<el-button style="width:auto" @click="handleVoucherSee(scope.row)" :style="{marginRight : '10px'}" type="text">查看凭证</el-button>
									<el-button style="width:auto" @click="handleVoucherReject(scope.row)" type="text">查看驳回理由</el-button>
								</template>
								<!-- 其他 -->
								<template v-else>
									<span>--</span>
								</template>
							</template>
						</el-table-column>
					</el-table>
					<!-- 分页 -->
                    <div class="pageBox">
                        <el-pagination class="tar" v-if='total>0'
                            :current-page="currentPage"
                            :page-size='10'
                            :total='total'
                            layout="prev, pager, next, jumper"
                            @current-change="handlePageChange">
                        </el-pagination>
                    </div>
				</div>
			</div>
			<!-- 授信凭证 end  -->
		</div>
	</div>
</template>

<script>
	import { axiosApi, basePath } from "../../api/api";
	import orderViewCredentials from './orderViewCredentialsComponent.vue';
	export default{
		components : {'order-viewredentials' : orderViewCredentials},
		data(){
			return{
				/*规则*/
				rules2:{
					cancleReason:[{
						required: true,
						message: '取消订单理由不能为空'
					},{
						min:4,
						max:100,
						message: '取消订单理由为4-100位字符'
					}]
				},
				rules3:{
					expressName:{
						required: true,
						message: '物流公司不能为空'
					},
					expressNum:[
//					{
//						required: true,
//						message: '物流编号不能为空'
//					},
					{
						pattern: /^[A-Z|0-9]{8,15}$/,
						message: '请输入正确的物流单号',
						trigger: 'blur'
					}]
				},
				show: 'first',
				orderInfo: {},//订单信息
				cancelOrderVisible: false,
				sendGoods: false,
				expressList:[],//物流列表
				form:{
					cancleReason:'',//取消订单原因
					companyName:'',//物流公司
					expressNum:'',//物流编号
				},
				voucherTableList : [], // 授信凭证 list data
				imgviewVisible : false, // 查看凭证 浮层开关
				voucherImgList : [], // 查看凭证 图片list
				bankInfo : [],
				total : 0,
				currentPage : 1,
			}
		},
		mounted() {
			this.initVoucherTable();
			this.$nextTick(() => {
				this.getOrderInfo();
				if(sessionStorage.show){
					this.show=sessionStorage.show;
				}
			})
		},
		methods:{
			handlePageChange(val) {
				this.currentPage = val;
				this.initVoucherTable();
			},
			// 获取凭证 列表
			initVoucherTable() {
				axiosApi('/superc/credit/order/backReason',{
					orderSn : this.$route.params.orderSn,
				},(res) => {
					let data = res.data.data;
					console.log(data,'凭证 列表');
					if(data){
						/* this.voucherTableList = [{
							operatorName : data.operatorName,
							gmtModified : data.gmtModified,
							stateName : data.stateName,
							state : data.state,
							reason : data.reason,
							imgs : data.imgs ? data.imgs : [],
							bankInfo : {
								bankName : data.bankName,
								bankUserName : data.bankUserName,
								bankCard : data.bankCard,
							},
						}]; */
						this.tableList = data.elements;
						this.total = data.totalCount;
						if(data.elements && data.elements.length > 0){
							this.voucherTableList = data.elements.map((v,k) => {
								v.bankInfo = {
									bankName : v.bankName,
									bankUserName : v.bankUserName,
									bankCard : v.bankCard,
								};
								return v;
							});
						}
					}
				},this.currentPage,10);
			},
			// 查看凭证
			handleVoucherSee(row) {
				/* this.voucherImgList = row.imgs;
				this.imgviewVisible = true;
				this.$refs.imgview.setActiveItem(0); */
				row.imgs = row.imgs ? row.imgs : [];
				if(row.imgs.length === 0){
					this.$message.warning('暂未上传凭证');
					return;
				}
				this.voucherImgList = row.imgs;
				this.bankInfo = row.bankInfo;
				this.$refs.viewredentials.show();
			},
			// 查看 驳回理由
			handleVoucherReject(row) {
				let div =
                <div>
                    <p>驳回理由：</p>
                    <p style={
						{
                            marginTop : '5px',
                            wordWrap: 'break-word',
							wordBreak: 'normal',
							paddingBottom: '60px',
                        }
					}>
                        {row.reason}
                    </p>
                </div>;
                this.$msgbox({
                title: '查看',
                message: div,
                showCancelButton: false,
                confirmButtonText: '确定',
                beforeClose: (action, instance, done) => {
                    console.log('确定');
                    done();
                }
                }).then(action => {
                });
			},
			handleClick(){//切换内容
				//console.log(this.show)
				//sessionStorage.setItem('show',this.show);
			},
			getOrderInfo(){//获取订单详细信息
//				//console.log('订单编号',this.$route.params.orderSn,this.$route.params.orderSn)
				axiosApi('/superc/order/selectDetailsForWeb ',{orderSn:this.$route.params.orderSn},(res)=>{
					console.log('订单详细信息',res)
					this.orderInfo = res.data.data;
					if(this.orderInfo.remainAutoCancelTime != null &&  this.orderInfo.remainAutoCancelTime > 0){
						let time = parseInt(this.orderInfo.remainAutoCancelTime / 1000)//转化为整数
						this.orderInfo.remainAutoCancelTime = this.timeString(time)//转化为时间格式
						let clearTime = null//定义变量
						clearTime = setInterval(()=>{
							time--
							this.orderInfo.remainAutoCancelTime = this.timeString(time)
							if(time<=0){
								clearTimeout(clearTime)
								if(this.orderInfo.orderStatus == 1) this.orderInfo.orderStatus = 0
							}
						},1000)
					}else{
						if(this.orderInfo.orderStatus == 1) this.orderInfo.orderStatus = 0
					}
				},1,10,(res)=>{
					this.$message({type:'error',message:res.data.message,duration:1000})
				})
			},
			getLogistics(){//物流公司列表
				axiosApi('/superc/expressCompany/selectList',{},(res)=>{
					////console.log('物流公司',res)
					this.expressList = res.data.data;
				},1,10,(res)=>{})
			},
			handleCancelOrder() {//取消订单按钮
				this.cancelOrderVisible = true;
			},
			handleSend(){//发货按钮
				this.sendGoods = true;
				this.form={
					expressName: null,//物流公司
					expressNum: null,//物流单号
				};
				this.getLogistics();//加载物流列表
			},
			handleCommit(state){//确定
				if(state==2){//弹窗 取消订单
					this.$refs.cancelOrderForm.validate((valid)=>{
						if(valid){
							this.cancelOrderVisible = false
							axiosApi('/superc/orderCancel/update', {orderSn:this.$route.params.orderSn,reason:this.form.cancleReason,cancleType:1}, (res)=>{
								////console.log('取消订单',res)
								this.getOrderInfo()
							},1,10,(res)=>{
								this.$message({type:'error',message:res.data.message,duration:1000})
							})
						}else{
							return false
						}
					})
				}else if(state==3){//弹窗 发货
					this.$refs.sendGoods.validate((valid)=>{
						if(valid){
							this.sendGoods = false
							axiosApi('/superc/sellerDeliverGoods/update', {orderSn:this.$route.params.orderSn,expressName:this.form.expressName,expressNum:this.form.expressNum}, (res)=>{
								////console.log('发货',res)
								this.getOrderInfo()
							},1,10,(res)=>{
								this.$message({type:'error',message:res.data.message,duration:1000})
							})
						}else{
							return false
						}
					})
				}
			}
		}
	}
</script>

<style lang="less">
	#supercOrder{
		.bdt{
			border-top: 1px solid #eee;
		}
		.el-tabs__header{
			padding: 0;
			border-bottom: 1px solid #eee;
		}
		.box-con{
			padding: 20px;
			.order-info{
				border: 1px solid #ddd;
				box-sizing: border-box;
				.order-info-tit{
					height: 40px;
					line-height: 40px;
					background-color: #eee;
				}
				.order-info-con{
					.order-info-conL{
						width: 700px;
						line-height: 30px;
						padding: 22.5px 0;
						label{
							min-width: 150px;
							display: inline-block;
							text-align: right;
						}
						&.order-info-conL2 {
							width: 90%;
						}
					}
				}
			}
			.goods{
				border: 1px solid #ddd;
				box-sizing: border-box;
				.goods-info{
					height: 120px;
					border-bottom: 1px solid #ddd;
					span{display: inline-block;}
					.goods-infoL{
						width: 80%;
						height: 100%;
						padding: 20px;
						box-sizing: border-box;
						border-right: 1px solid #ddd;
						img{width: 80px;height: 80px;}
						.w380{width: 380px;}
						.w210{width: 210px;}
					}
					.goods-infoR{width: 20%;display: inline-block;padding-left: 20px; box-sizing: border-box;}
				}
				.goods-money{
					min-height: 146px;
					line-height: 25px;
					padding: 30px 50px;
					box-sizing: border-box;
					label{
						width: 300px;
						display: inline-block;
						text-align: right;
					}
				}
			}
			.w550{
				width:550px;
			}
			.el-dialog{
				min-height: 300px;
				.el-form-item__label{
					width: 140px;
				}
			}
			.sendGoods .el-form-item__error{
				margin-left: 140px;
			}
		}
	}
</style>
