<template>
  <!--分类管理-->
  <div id="contractCustomer">
    <div class="title-top bg-f clearfix">分类管理</div>
    <div class="title-tabs2 bg-f mb-5">
      <ul class="clearfix">
        <div v-for="(tab,index) in dataTabs" class="item" :key="index" @click="handleTabs(index)">
          <li :class="tab==clickedTabName?'active':''">
            {{tab}}
            <div :class="tab==clickedTabName?'under-line':''"></div>
          </li>
          <div class="vertical-line"></div>
        </div>
      </ul>
    </div>
    <div class="content bg-f pr-30 pl-30 mt-5">
      <div class="clearfix">
        <div class="fr">
          <el-button type="narmal" class="mb-5 mr-10 mt-10" @click="openCustomer('form')">添加分类</el-button>
        </div>
      </div>
      <div class="pb-30">
        <el-table :data="tableData">
          <el-table-column label="分类名称">
            <template slot-scope="scope">
              <i
                class="iconfont color-blue"
                :class="scope.row.isSystem ==1 ?scope.row.icon:'icon-fenlei'"
              ></i>
              <span>{{scope.row.classifyName}}</span>
            </template>
          </el-table-column>
          <el-table-column label="分类属性">
            <template slot-scope="scope">
              <span>{{scope.row.isSystem == 1?'系统分类':'自定义分类'}}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <el-button
                type="text"
                v-if="scope.row.isSystem != 1"
                @click="openEdit('form',scope)"
              >编辑</el-button>
              <el-button type="text" v-if="scope.row.isSystem != 1" @click="isDelete(scope)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          class="tar"
          v-if="tableData.length>0"
          :current-page="currentPage"
          :page-size="10"
          :total="total"
          layout="prev , pager, next, jumper"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </div>
      <!--新增分类-->
      <el-dialog class="minMop" :title="isEdit?'编辑分类':'新增分类'" :visible.sync="customer">
        <el-form class="pr-10" :rules="rules" ref="form" :model="form">
          <el-form-item label="分类属性:" class="ml-50" prop="tenantClassify" required>
            <el-select v-model="form.tenantClassify" placeholder>
              <el-option
                v-for="item in typeList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="分类名称:" class="ml-50" prop="classifyName">
            <el-input v-model="form.classifyName" placeholder="分类名称" :maxlength="6"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button v-if="!isEdit" type="danger" @click="addCustomer('form')">确定</el-button>
          <el-button v-else type="danger" @click="Edit('form')">确定</el-button>
          <el-button class="ml-20" @click="customer = false">取消</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { axiosApi, basePath } from "../../../api/api";
import { MessageBox } from "element-ui";
function filterInput(val) {
  return val.replace(/[\ud800-\udbff][\udc00-\udfff]/g, "");
}
export default {
  data() {
    return {
      clickedTabName: "名烟名酒店",
      dataTabs: ["名烟名酒店", "夫妻老婆店", "餐饮"],
      typeList: [
        { id: 1, name: "名烟名酒店" },
        { id: 2, name: "夫妻老婆店" },
        { id: 3, name: "餐饮" }
      ],
      activeName: 0,
      customer: false, //新增分类弹框
      Form: {
        type: 1,
        classifyName: ""
      },
      form: {
        tenantClassify: 1,
        classifyName: ""
      },
      searchForm: {
        type: 1,
        classifyName: ""
      },
      id: null,
      isEdit: false,
      tableData: [], //客户数据
      currentPage: 1,
      total: 0, //页面分页总数
      Page: 1,
      totalCount: 0, //弹窗分页总数
      rules: {
        classifyName: [
          {
            required: true,
            message: "分类名称为必填项"
          },
          { min: 1, max: 6, message: "长度在 1 到 6 个字符", trigger: "blur" }
        ]
      },
      formPeriod: {
        settlementDay: "", //结算日期
        settlementPeriod: "" //周期
      },
      hotelId: "", //酒店ID
      cusId: "", //协议公司主键id
      bindData: [], //已绑定数据
      unBindData: [] //未绑定数据
    };
  },
  watch: {
    customer(val) {
      if (!val) {
        if (this.$refs.form) this.$refs.form.resetFields();
      }
    },
    "form.classifyName": {
      handler: function(val) {
        this.$nextTick(() => {
          this.form.classifyName = filterInput(val);
        });
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.achieve(1);
    });
  },
  methods: {
    handleTabs(index) {
      this.clickedTabName = this.dataTabs[index];
      this.activeName = index;
      let classifyId = index + 1;
      this.achieve(classifyId, true);
    },
    achieve(classifyId, isFirst) {
      if (isFirst && this.currentPage !== 1) {
        this.currentPage = 1;
        return
      }
      axiosApi(
        "/superc/tenantShopClassify/list",
        {
          tenantClassify: classifyId
        },
        res => {
          this.tableData = res.data.data.elements ? res.data.data.elements : [];
          this.total = res.data.data.totalCount;
        },
        this.currentPage,
        10,
        res => {}
      );
    },
    addCustomer(form) {
      //新增分类
      this.$refs[form].validate(valid => {
        if (valid) {
          axiosApi(
            "/superc/tenantShopClassify/insert",
            {
              tenantClassify: this.form.tenantClassify,
              classifyName: this.form.classifyName
            },
            res => {
              this.achieve(this.form.tenantClassify);
              this.$message({
                type: "success",
                message: "新增成功",
                duration: 3000
              });
              this.customer = false;
            },
            1,
            10,
            res => {
              this.customer = false;
              this.$message({
                type: "info",
                message: res.data.message,
                duration: 3000
              });
            }
          );
        } else {
        }
      });
    },
    Edit(form) {
      this.$refs[form].validate(valid => {
        if (valid) {
          axiosApi(
            "/superc/tenantShopClassify/update",
            {
              id: this.id,
              tenantClassify: this.form.tenantClassify,
              classifyName: this.form.classifyName
            },
            res => {
              this.achieve(this.activeName + 1);
              this.$message({
                type: "success",
                message: "修改成功",
                duration: 3000
              });
              this.customer = false;
            },
            1,
            10,
            res => {
              this.customer = false;
              this.$message({
                type: "info",
                message: res.data.message,
                duration: 3000
              });
            }
          );
        } else {
        }
      });
    },
    openCustomer(form) {
      this.customer = true;
      this.isEdit = false;
      this.form.tenantClassify = this.activeName + 1;
    },
    openEdit(form, item) {
      if (this.$refs[form] !== undefined) {
        this.$refs[form].resetFields();
      }
      this.customer = true;
      this.form.classifyName = item.row.classifyName;
      this.form.tenantClassify = item.row.tenantClassify;
      this.isEdit = true;
      this.id = item.row.id;
    },
    //删除
    isDelete(val) {
      let that = this;
      this.$confirm("您是否要删除该分类？", "提示信息", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          const api = "/superc/tenantShopClassify/update";
          axiosApi(
            api,
            {
              id: val.row.id,
              classifyName: val.row.classifyName,
              isDeleted: 1
            },
            res => {
              this.$message({
                type: "success",
                message: "删除成功!",
                duration: 1000
              });
              that.achieve(this.activeName + 1, true);
            },
            this.currentPage,
            10,
            res => {
              this.$message({
                type: "info",
                message: res.data.message,
                duration: 3000
              });
            }
          );
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除"
          });
        });
    },
    handleCurrentChange(val) {
      //分页查询
      this.currentPage = val;
      this.achieve(this.activeName + 1);
    }
  }
};
</script>

<style lang="less">
#contractCustomer {
  .content {
    .el-form--inline {
      // width: 650px;
      .el-form-item__label {
        font-size: 12px;
        width: 60px;
      }
      .order-datatimerange {
        width: 240px;
      }
    }
    .el-button + .el-button {
      margin-left: 0;
    }
    .el-table .cell {
      text-align: center;
      padding: 10px 0;
      img {
        width: 60px;
        height: 60px;
        margin-left: 10px;
        margin-right: 10px;
      }
      .el-button {
        height: 20px;
        width: 80px;
      }
    }
    .el-form-item__label {
      width: 100px;
    }
  }
  .el-dialog {
    min-height: 300px !important;
  }
  .el-form-item__error {
    margin-left: 110px !important;
  }
}
</style>