<template>
    <div id="selfView" class="">
        <div class="content bg-f pb-50">
            <!-- 状态切换区域 -->
            <div class="tab_box ml-30">
                <div class="tab_item" :class="{tab_item_checked: item.type==state}" v-for="(item,index) in tabList"
                     :key="index" @click="checkState(item)">
                    <span>{{item.label}}</span>
                    <span>({{item.bage ? item.bage : 0}})</span>
                </div>
            </div>

            <div class="clearfix search">
                <el-form :inline="true" :model="form" label-width="70px" class="clearfix pl-20 pt-30"
                         style="width: 1000px">
                    <div class="fl" style="width: 690px">
                        <el-form-item label="商品名称">
                            <el-input v-model.trim="form.skuName" placeholder="输入商品名称"
                                      @keyup.enter.native='achieve(true)'></el-input>
                        </el-form-item>
                        <el-form-item label="商品分类">
                            <el-select clearable @keyup.enter.native='achieve(true)' v-model.trim="form.foodClass"
                                       placeholder="选择商品分类">
                                <el-option
                                        v-for="item in options"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                    <el-button type="info" class="fl ml-10" @click='achieve(true)'>查询</el-button>

                </el-form>
                <div class='clearfix'>
                    <span class="fr color-blue cursor mr-50 mt-10"
                          @click="addGoods()">添加新商品</span>
                </div>
                <el-table :data="tableData" v-loading="Loading" class='ml-30 mt-20 table-default mr-30' stripe>
                    <el-table-column prop="1" label="商品名称/编码" width="350">
                        <template slot-scope="scope">
                            <div class="info flex clearfix ">
                                <div class="ml-10">
                                    <img v-if='scope.row.img' :src="scope.row.img"/>
                                    <img v-else src="../../assets/goods.jpg"/>
                                </div>
                                <div class="flex-1 ml-10 mt-10 hidden">
                                    <div class="tal ">{{scope.row.skuName}}</div>
                                    <div class="tal">商品编码:{{scope.row.skuNo}}</div>
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="分类" prop="classifyName"></el-table-column>
                    <el-table-column label="零售价">
                        <template slot-scope="scope">
                            <span>￥{{scope.row.price | fmoney}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="单位" prop="unitsName"></el-table-column>
                    <el-table-column label="操作">
                        <template slot-scope="scope">
                            <el-button v-if="scope.row.skuState==1" class='ml-0 width-90' size="small" type='text'
                                       @click.native="upDown(scope,2)">上架
                            </el-button>
                            <el-button v-else class='ml-0 width-90' size="small" type='text'
                                       @click.native="upDown(scope,1)">下架
                            </el-button>
                            <el-button size="small"  class='ml-0 width-90' type='text'
                                       @click.native="updateItem(scope)">编辑
                            </el-button>
                            <el-button v-if="!scope.row.isRecommend" class='ml-0 width-90' size="small" type='text'
                                       @click.native="setRecommend(scope,1)">设为推荐
                            </el-button>
                            <el-button v-else class='ml-0 width-90' size="small" type='text'
                                       @click.native="setRecommend(scope,0)">取消推荐
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>

                <el-pagination v-if="total>0" class="tar mr-30" :current-page="currentPage" :page-size='10'
                               :total='total'
                               layout="prev , pager, next, jumper" @current-change="handleCurrentChange">
                    <!-- 分页组件 -->
                </el-pagination>
            </div>
        </div>


        <el-dialog class="minMop" title="价格修改" :visible.sync='dialogShow'>
            <el-form class="pr-30" :model="form1" ref="addWaiter" :rules='rules'>
                <el-form-item label="价格修改" prop='price' required>
                    <el-input placeholder='请输入价格' v-model.trim='form1.price'></el-input>
                </el-form-item>
            </el-form>
            <div slot='footer' class="dialog-footer">
                <el-button type='danger' @click="save">确 定</el-button>
                <el-button @click="dialogShow = false">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {axiosApi, basePath} from "../../api/api";

    export default {
        data() {
            return {
                id: null,
                dialogShow: false,
                state: 1,
                tabList: [{
                    label: '未上架',
                    type: 1,
                    bage: 0
                }, {
                    label: '已上架',
                    type: 2,
                    bage: 0
                }],
                Loading: true,
                tableData: [], //员工列表
                currentPage: 1,
                total: 0,
                options: [{
                    value: '497',
                    label: '啤酒'
                }, {
                    value: '503',
                    label: '饮料'
                }],
                form: { //搜索条件
                    skuName: '',
                    foodClass: '',
                },
                form1: { //搜索条件
                    price: '',
                },
                rules:
                    {
                        price: [{
                            required: true,
                            pattern:/^([0-9]\d{0,9})(\s|$|\.\d{1,2}\b)/,
                            message: '请输入修改价格'
                        }]
                    },
            }
        },
        watch: {
            dialogShow(val) {
                if (!val) {
                    this.form1.price = null;
                    this.id = null;
                }
            }
        },
        methods: {
            achieve(bool) {
                if (bool && this.currentPage != 1) {
                    this.currentPage = 1;
                }
                const api = "/catering/platform/wine/selectListPage";
                const body = {
                    skuName: this.form.skuName ? this.form.skuName : null,
                    classifyId: this.form.foodClass ? this.form.foodClass : null,
                    skuType: 3,
                    skuState: this.state
                };
                axiosApi(api, body, this.callback, this.currentPage, 10)
            },
            callback(res) {
                this.tableData = res.data.data.elements
                this.total = res.data.data.totalCount
                this.Loading = false
            },
            upDown(scope, isUp) {
                var type = isUp == 1 ? "下架" : "上架";

                this.$confirm("将" + type + "该酒水, 是否继续?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                }).then(() => {
                    let url = '/catering/platform/wine/update';
                    let body = {
                        skuState: isUp,
                        id: scope.row.id,
                    };

                    axiosApi(url, body, (res) => {
                        this.$message({
                            type: 'success',
                            duration: 1000,
                            message: type + "成功!",
                        });
                        this.achieve(true);
                        this.getBage();
                    }, this.currentPage, 10, (res) => {
                        this.$message.error(res.data.message)
                    });
                }).catch(() => {
                    this.$message({
                        message: "已取消" + type,
                    });
                });
            },
            setRecommend(scope, isRecommend) {
                var type = isRecommend ? "推荐" : "取消推荐";
                this.$confirm("将" + type + "该酒水, 是否继续?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                }).then(() => {
                    let url;
                    let body;
                    url = '/catering/platform/wine/update';
                    body = {
                        id: scope.row.id,
                        isRecommend: isRecommend,
                    };

                    axiosApi(url, body, (res) => {
                        this.$message({
                            type: 'success',
                            duration: 1000,
                            message: type + "成功!",
                        });
                        this.achieve(true);
                        this.getBage();
                    }, this.currentPage, 10, (res) => {
                        this.$message.error(res.data.message)
                    });
                }).catch(() => {
                    this.$message({
                        message: "已取消" + type,
                    });
                });
            },

            addGoods() {
                this.$router.push({path: 'addSelfDrinks', query: null});
            },

            updateItem(scope) {
                this.id = scope.row.id;
                this.form1.price = scope.row.price
                this.dialogShow = true;
            },
            checkState(val) {//切换状态
                this.state = val.type;
                this.currentPage = 1;
                this.achieve()
            },
            //			分页
            handleCurrentChange(val) {
                this.currentPage = val;
                this.achieve()
            },
            save() {
                this.$refs.addWaiter.validate((v) => {
                    if (v) {
                        let body = {
                            id: this.id,
                            price: this.form1.price,
                        }
                        this.insert(body, '/catering/platform/wine/update') // 添加
                            .then(res => {
                                this.$message({
                                    type: 'success',
                                    duration: 1000,
                                    message: "修改成功!",

                                });
                                this.dialogShow = false;
                                this.achieve();
                                this.getBage();
                            })
                            .catch(res => {
                                this.$message.error(res.data.message)
                            });

                    }
                });
            },
            insert(body, url) {// 插入接口
                this.btnLoading = true
                return new Promise((resolve, reject) => {
                    axiosApi(url, body, res => {
                        resolve(res)
                        this.btnLoading = false
                    }, 1, 10, res => {
                        reject(res)
                        this.btnLoading = false
                    })
                })
            },
            getBage() {
                axiosApi("/catering/platform/wine/selectCount", {
                    skuType: 3
                }, (res) => {
                    this.tabList.forEach(item => {
                        if (item.type == 1) {
                            item.bage = res.data.data.offTheShelvesCount;
                        } else if(item.type == 2){
                            item.bage = res.data.data.onTheShelvesCount;
                        }
                    })
                }, 1, 1);
            }
        },
        //进入加载
        mounted() {
            this.$nextTick(function () {
                this.achieve();
                this.getBage();
            })
        }
    }
</script>

<style lang="less">
    #selfView {
        .el-dialog {
            height: 300px !important;
            min-height: 300px !important;
        }

        .tab_box {
            height: 60px;
            line-height: 60px;
            box-sizing: border-box;
            border-bottom: 1px solid #eee;

            .tab_item {
                display: inline-block;
                width: 114px;
                height: 26px;
                line-height: 26px;
                text-align: center;
                box-sizing: border-box;
                color: #666;
                font-size: 14px;
                cursor: pointer;
            }

            .tab_item:hover {
                color: #FF4201;
            }

            .tab_item_checked {
                border-radius: 2px;
                border: 1px solid #FF4201;
                color: #FF4201;
            }
        }

        .el-form-item__label {
            font-size: 12px;
        }

        .el-table {
            overflow: hidden;
            width: 94%;
            max-width: 100%;
            background-color: #fff;
            border: 1px solid #dfe6ec;
            font-size: 12px;
            color: #1f2d3d;
        }

        .el-button--default {
            margin-right: 0px;
        }

        .el-tabs__header {
            padding-left: 0;
        }

        .title-top {
            border: 0;
        }

        .mt-14 {
            margin-top: 14px;
        }

        .mr-60 {
            margin-right: 60px;
        }

        .black {
            .edit-btn span {
                color: #000000 !important;
            }
        }

        .el-form-item__error {
            margin-left: 190px !important;
        }
    }
</style>