<template>
    <div class="orderViewCredentials-box">
        <el-dialog ref="dialog" @close="handleClose" :title="type === 0 ? '审核凭证' : '查看凭证'" :visible.sync="dialogVisible">
            <div class="dialogBox">
                <el-carousel indicator-position="outside" :arrow="this.imgList.length > 1 ? 'always' : 'never'" :autoplay="false" ref="carousel" >
                    <el-carousel-item class="orderViewCredentials-carousel" v-for="(item,index) in imgList" :key="index">
                        <img class="orderViewCredentials-img" :src="item" />
                    </el-carousel-item>
                </el-carousel>
                <div class="bankBox" v-if="type === 0 || type === 1">
                    <div>
                        <div><div class="title">汇款银行：</div><div class="content">{{bankInfo.bankName}}</div></div>
                        <div><div class="title">持卡人：</div><div class="content">{{bankInfo.bankUserName}}</div></div>
                        <div><div class="title">银行卡号：</div><div class="content">{{bankInfo.bankCard}}</div></div>
                    </div>
                </div>
                <div class="result-box" v-if="type === 0">
                    <div>
                        <span class="content">
                            <el-radio-group v-model="result">
                                <el-radio label="1">通过</el-radio>
                                <el-radio label="2">驳回</el-radio>
                            </el-radio-group>
                            <transition name="fade">
                                <p class="textarea-item" v-if="result === '2'">
                                    <el-input :maxlength="100" placeholder="请输入驳回理由" type="textarea" v-model.trim="remarks" />
                                </p>
                            </transition>
                        </span>
                    </div>
                </div>
            </div>
            <span v-if="type === 0" slot="footer" class="dialog-footer">
                <el-button @click="handleClose">取 消</el-button>
                <el-button type="primary" @click="handleOk">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    import { axiosApi, basePath } from "../../api/api"
    export default {
        props : ['imgList','id','bankInfo','type'],
        data() {
            return {
                dialogVisible : false,
                result : '1',
                remarks : '',
            }
        },
        mounted() {
            /* 解决 el-dialog__body 一开始不显示 问题 */
            this.$refs.dialog.rendered = true;
        },
        methods : {
            activeItem(index) {
                this.$refs.carousel.setActiveItem(index);
            },
            show() {
                this.dialogVisible = true;
            },
            formReset() {
                this.result = '1';
                this.remarks = '';
            },
            handleClose() {
                this.dialogVisible = false;
                setTimeout(() => {
                    this.activeItem(0);
                    this.formReset();
                },200);
            },
            handleOk() {
                if(this.type === 1){
                    this.handleClose();
                    return;
                }
                if( this.result=== '2' && !this.remarks ){
                    this.$message.warning('请输入驳回理由');
                    return;
                }else if( this.result=== '2' && this.remarks.length < 2 ){
                    this.$message.warning('请最少输入2个文字');
                    return;
                }
                axiosApi('/superc/credit/order/imgCheck',{
                    supercStoreId : this.id.supercStoreId,
                    orderSn : this.id.orderSn,
                    buyerId : this.id.buyerId,
                    state : this.result,
                    reason : this.result == '2' ? this.remarks : '',
                },(res) => {
                    let data = res.data.data;
                    this.$message.success('操作成功');
                    this.handleClose();
                    this.$emit('close');
                },1,10,(err) => {
                    this.$message.warning(err.data);
                });
            },
        },
    }
</script>

<style lang="less" scoped>

.orderViewCredentials-box{
    
}

</style>

<style lang="less">

.orderViewCredentials-box{
    .orderViewCredentials-carousel{
        transition: none;
    }
    .fade-enter-active, .fade-leave-active {
        transition: opacity .5s
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active in below version 2.1.8 */ {
        opacity: 0
    }
    .result-box{
        >div{
            display: inline-block;
        }
        width: 300px;
        margin: 0 auto;
        padding-left: 60px;
    }
    .bankBox{
        padding: 10px 0;
        width: 300px;
        margin: 0 auto;
        padding-left: 60px;
        .title{
            display: inline-block;
            width: 70px;
        }
        .content{
            display: inline-block;
            vertical-align: top;
        }
        >div{
            display: inline-block;
        }
    }
    .textarea-item{
        width: 300px;
        margin: 0 auto;
        margin-top: 10px;
    }
    /* 轮播样式 */
    .el-carousel {
      .el-carousel__container {
        height: 400px;
        .el-carousel__item {
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                height: 100%;
                max-width: 100%;
            }
        }
      }
    }
    /* 重置浮层样式 */
    .el-dialog__wrapper{
        .el-dialog{
            min-height: initial;
        }
        .el-dialog__body{
            padding: 10px;
            color: #48576a;
            font-size: 14px;
        }
        .el-dialog__footer{
            padding: 10px 20px 15px;
            text-align: center;
            box-sizing: border-box;
            position: initial!important;
            transform: initial!important;
        }
        .el-dialog__footer .el-button--default{
            margin-left: 0;
        }
        .el-carousel__indicators--outside{
            display: none;
        }
    }
}

</style>
