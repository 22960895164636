<template>
    <div id="superBAnalyst">
        <!-- head -->
        <div class="title-top bg-f mb-5">
            <div class="clearfix black">
                <el-breadcrumb class="fl ">
                    <el-breadcrumb-item>业务统计</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
        </div>

        <div class="bg-f pt-20 pb-30">
            <div class="fl ml-30 " style="width: 5px; height: 15px; background: #00a0e9">
            </div>
            <div class="fl ml-10">
                统计
            </div>
            <div class="fr cursor mr-50">
                <el-button type='text' @click="refresh(1)">刷新</el-button>
                <el-button class="mr-10" @click="refresh(1)">
                    近7日
                </el-button>
                <el-button class="mr-10" @click="refresh(2)">
                    近30日
                </el-button>
                <el-button class="mr-10" @click="refresh(3)">
                    近90日
                </el-button>
            </div>
        </div>


        <div class="bg-f pt-30">
            <div class="order-info clearfix ml-30 mr-30">
                <div class="clearfix order">
                    <div class="flex">
                        <div class="w480 mt-40">
                            <ul>
                                <li>
                                    <span class="font-24 lh-24 dib">{{statistics.totalSuperbAccount}}</span>
                                    <p class="font-12 mt-10 lh-12">累计超级B总数</p>
                                </li>
                                <li>
                                    <span class="font-24 lh-24 dib">{{statistics.addSuperbAccount}}</span>
                                    <p class="font-12 mt-10 lh-12">新增超级B</p>
                                </li>
                                <li>
                                    <span class="font-24 lh-24 dib">￥{{statistics.totalCommission | fmoney}}</span>
                                    <p class="font-12 mt-10 lh-12">直接佣金</p>
                                </li>
                                <li>
                                    <span class="font-24 lh-24 dib">￥{{statistics.totalSubsidy | fmoney}}</span>
                                    <p class="font-12 mt-10 lh-12">其它补贴</p>
                                </li>
                                <li>
                                    <span class="font-24 lh-24 dib">{{statistics.orderNums}}</span>
                                    <p class="font-12 mt-10 lh-12">订单数</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="bg-f pt-30 pb-20">
            <div class="fl ml-30 " style="width: 5px; height: 15px; background: #00a0e9">
            </div>
            <div class="fl ml-10">
                TOP 10
            </div>

            <el-button class="fr mr-50" type='text' @click="all">查看全部</el-button>
        </div>

        <!-- body -->
        <div class="bg-f">

            <!-- class -->
            <div class='bg-f'>
                <div class='bg-f'>
                    <div class="ml-30 mr-30 second-title clearfix">
                        <div class="choose-three font-14 color-6 fl tac mr-10 cursor " :class="first == 0?'actived':''"
                             @click="getStatus(0)">
                            <div>超级B</div>
                        </div>
                        <div class="choose-three font-14 color-6 tac fl mr-10 cursor"
                             :class="first == 1?'actived':''" @click="getStatus(1)">
                            <div>热门商品</div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- list -->
            <div class="pl-30 pr-30 pb-30">

                <el-table :data="tableData0" v-loading="Loading" class='mt-10 table-default ' v-show="first==0" stripe>

                    <el-table-column label="超B账号" prop="loginName" min-width="100"></el-table-column>
                    <el-table-column label="姓名" prop="name" min-width="100"></el-table-column>
                    <el-table-column label="直接佣金" min-width="100">
                        <template slot-scope="scope">
                            <div>￥{{scope.row.commission | fmoney}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column label="其他补贴" min-width="100">
                        <template slot-scope="scope">
                            <div>￥{{scope.row.subsidy | fmoney}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column label="下游超级B" prop="downstreamNumber" min-width="90"></el-table-column>
                    <el-table-column label="关联客户" prop="customerNumber" min-width="90"></el-table-column>
                    <el-table-column label="累计收益" min-width="90">
                        <template slot-scope="scope">
                            <div>￥{{scope.row.commission+ scope.row.subsidy| fmoney}}</div>
                        </template>
                    </el-table-column>

                    <el-table-column label="操作" min-width="90">
                        <template slot-scope="scope">
                            <el-button class='ml-0 tac' size="small" type='text' @click="showItem(scope.row,0)">查看
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>

                <el-table :data="tableData" v-loading="Loading" class='mt-10 table-default' v-show="first==1" stripe>
                    <el-table-column prop="1" label="商品名称" width="310">
                        <template slot-scope="scope">
                            <div class="info flex clearfix ">
                                <div class="ml-10">
                                    <img v-if='scope.row.fileUrl' :src="scope.row.fileUrl"/>
                                    <img v-else src="../../assets/goods.jpg"/>
                                </div>
                                <div class="flex-1 ml-10 mt-10 hidden">
                                    <div class="tal">商品编码：{{scope.row.skuNo}}</div>
                                    <div class="tal">{{scope.row.skuName}}</div>
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="规格">
                        <template slot-scope="scope">
                            <div>1*{{scope.row.specInfoNum}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column label="分销佣金" width="">
                        <template slot-scope="scope">
                            <div>￥{{scope.row.minCommission | fmoney}}-￥{{scope.row.maxCommission | fmoney}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column label="一级补贴" width="">
                        <template slot-scope="scope">
                            <div>￥{{scope.row.subsidyOne | fmoney}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column label="二级补贴" width="">
                        <template slot-scope="scope">
                            <div>￥{{scope.row.subsidyTwo | fmoney}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column label="销量" prop="sales"></el-table-column>
                </el-table>
            </div>

        </div>

    </div>
</template>

<script>
    import {axiosApi, basePath} from "../../api/api";

    export default {
        name: "superBAnalyst",
        data() {
            return {
                Loading: false,
                first: 0,
                tableData0: [],//商品来源
                tableData: [],//商品来源
                statistics: {},//统计数据
                gmtStart: null,
                gmtEnd: null

            }
        },
        methods: {
            //搜索
            achieve() {
                this.Loading = true;
                if (this.first == 0) {
                    let body = {};
                    let url = '/superb/statistics/accountList'
                    axiosApi(url, body, res => {
                        this.Loading = false;
                        this.tableData0 = res.data.data.elements;
                    }, 1, 10, err => {
                    });
                } else if (this.first == 1) {
                    let body = {
                        sellerId: sessionStorage.traderId,
                    };
                    let url = '/superb/statistics/skuList';
                    axiosApi(url, body, res => {
                        this.Loading = false;
                        this.tableData = res.data.data.elements;
                    }, 1, 10, err => {
                    })
                }
            },
            getStatistics() {
                let body = {
                    gmtStart: this.gmtStart,
                    gmtEnd: this.gmtEnd,
                };
                let url = '/superb/statistics/index';
                axiosApi(url, body, res => {
                    this.statistics = res.data.data;
                }, 1, 10, err => {
                })
            },
            refresh(e) {
                let myDate = new Date();
                let end = myDate.getTime();
                switch (e) {
                    case 0://不需要参数
                        this.gmtStart = null;
                        this.gmtEnd = null;
                        break;
                    case 1://7天
                        this.gmtStart = end - 60 * 60 * 24 * 1000 * 7;
                        this.gmtEnd = end;
                        break;
                    case 2://30天
                        this.gmtStart = end - 60 * 60 * 24 * 1000 * 30;
                        this.gmtEnd = end;
                        break;
                    case 3://90天
                        this.gmtStart = end - 60 * 60 * 24 * 1000 * 90;
                        this.gmtEnd = end;
                        break;
                }
                this.achieve();
                this.getStatistics();
            },
            all() {
                if (this.first == 1) {
                    window.open(basePath + "/superB/superBHotGoods", "_blank");
                } else {
                    window.open(basePath + "/superB/superBHot", "_blank");
                }
            },
            //tab切换new
            getStatus(e) {
                this.first = e;
                this.achieve(true)
            },
            showItem(item) {
                window.open(basePath + "/superB/superBDetails/" + item.traderId + "/" + item.id + "/" + '0', "_blank");
            },
        },
        mounted() {
            this.$nextTick(() => {
                this.refresh(1);
            })
        }
    }
</script>

<style lang="less">
    #superBAnalyst {
        .el-dialog {
            width: 800px;
            height: 600px;

            .img {
                width: 100px;
                height: 100px;
            }
        }

        .button {
            border: 1px solid #EEEEEE;
            padding: 10px;
            margin-left: 20px;
        }

        .order {
            border: 1px solid #DDDDDD;
            box-sizing: border-box;

            .w480 {
                width: 967px;
                height: 94px;
            }

            ul li {
                float: left;
                width: 192px;
                border-right: 1px solid #ddd;
                text-align: center;

                &:last-child {
                    border-right: 0;
                }
            }
        }


        .actived {
            border-radius: 2px;
            border: 1px solid #FF4201;
            color: #FF4201;
        }

        .second-title {
            height: 60px;
            padding: 17px 0px 17px 0px;
            box-sizing: border-box;
            border-bottom: 1px solid #EEEEEE;
        }

        .choose-three {
            width: 114px;
            height: 26px;
            line-height: 26px;
        }
    }
</style>