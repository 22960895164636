<template>
	<div id="supercConfig">
		<div class="title-top bg-f clearfix">久集配置</div>
		<div class="content bg-f pl-30 pr-30 mt-5 pt-20">
			<el-form ref="form" :model="form" label-width="130px" :rules="rules">
				<el-form-item label="保证金" prop="cash"> 
					<el-input type="text" v-model="form.cash" placeholder="请输入保证金" :maxlength="6" @keyup.native="form.cash=form.cash.replace(/\s+/g,'')"></el-input>
					<span class="remark">注：保证金为0时用户注册隐藏保证金选项</span>
				</el-form-item>
				<el-form-item label="服务费" prop="serviceMoney"> 
					<el-input type="text" v-model="form.serviceMoney" placeholder="请输入服务费" :maxlength="6" @keyup.native="form.serviceMoney=form.serviceMoney.replace(/\s+/g,'')"></el-input>
					<span class="remark">元/年</span>
				</el-form-item>
				<el-form-item label="支付宝账号" class="mb-40 mt-30" prop="alipayAccount">
					<el-input v-model="form.alipayAccount" placeholder="请输入支付宝账号" :maxlength="30" @keyup.native="form.alipayAccount=form.alipayAccount.replace(/\s+/g,'')"></el-input>
					<span class="remark">用于久集缴纳保证金</span>
				</el-form-item>
				<el-form-item label="久集注册二维码" class="mb-20"> 
					<vue-q-art :config="config" :downloadButton="downloadButton"></vue-q-art>
				</el-form-item>
				<el-form-item label="授信额度" class="mb-20" prop="creditScale"> 
					保证金&nbsp;x&nbsp;
					<el-input v-model.trim="form.creditScale" :style="{width : '60px'}" />
					&nbsp;%
				</el-form-item>
				<div :style="{borderTop : '1px solid #aaa' , width : '650px'}"></div>
				<el-form-item label=" " class="mt-50">
					<el-button class="ml-10" @click="submit" type="danger">保存</el-button>
				</el-form-item>

			</el-form>
		</div>
	</div>
</template>
<script>
	import { axiosApi } from "../../api/api";
	import VueQArt from 'vue-qart';
	import imagePath from '../../assets/title.png'
	export default{
		data(){
			var checkMoney = (rule, value, callback) => {
		        if (value === '' || value === undefined || value === null) {
		          return callback(new Error('保证金不能为空'));
		        }
	            if (isNaN(Number(value))) {
	            	callback(new Error('请输入数字值'));
	            } else {
	            	let reg = /^([1-9]\d*|[0]{1})$/
	            	if(!reg.test(value)){
	            		callback(new Error('请输入正确的数字值'));
	            	}else{
	            		callback();
	            	}
	            	callback();
	            }
	        }
			var zfb = (rule, value, callback) => {
		        let reg = /^1\d{10}$/;
		        let reg1 = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{1,4})+$/;
		        if (reg.test(value)){
		        	callback();
 				} else if(reg1.test(value)){
 					callback();
 				} else {
				 	callback(new Error('请输入正确的支付宝账号'));
				}
	        }
			return {
				form:{
					cash:null,
					serviceMoney: null,//服务费
					alipayAccount:null,
					creditScale : '', //授信额度
					userName : '', //户名
					bankCardNo : '', //账号
					bankName : '', //开户行
					remark : '', //汇款备注
				},
				id:null,//开通商户申请记录id
				traderId:sessionStorage.getItem('traderId'),
				rules:{
					cash:[
						{ required: true, message: '保证金不能为空'},
      					{ validator: checkMoney, trigger: 'change' }
      				],
      				serviceMoney:[
      					{ required: true, message: '服务费不能为空'},
      					{ validator: checkMoney, trigger: 'change' }
      				],
      				alipayAccount:[
  						{ required: true, message: '支付宝账号不能为空'},
      					{ validator: zfb, trigger: 'blur' }
					],
					creditScale : [
						{ required: true, message: '授信额度比例不能为空'},
						// /^([1-9]{1}\d?|100)$/ 1-100 正则
						{ pattern : /^(\d{1}|[1-9]{1}\d{1}|100)$/,message: '请输入0-100的整数'},
					],
					userName : [
						{ required: true, message: '户名不能为空'},
					],
					bankCardNo : [
						{ required: true, message: '账号不能为空'},
						{pattern : /^[0-9]*$/ , message: '账号格式必须为数字'}
					],
					bankName : [
						{ required: true, message: '开户行不能为空'},
					],
					remark : [
						{ required: true, message: '汇款备注不能为空'},
					],
				},
				config: {
		            value: '',
		            imagePath: imagePath,
		            filter: ''
		        },
		        downloadButton: false
			}
		},
		components: {
			VueQArt
		},
		methods:{
			submit(){
				this.$refs.form.validate((valid) => {
					if(valid){
						//支付宝账号字段待改
						let body = {
							traderId:sessionStorage.getItem('traderId'),
							alipayNumber:this.form.alipayAccount,
							bondMoney:this.form.cash,
							serviceMoney: this.form.serviceMoney,
							id:this.id,
							registUrl:this.config.value,
							creditScale : this.form.creditScale,
							/* bankCardNo : this.form.bankCardNo,
							userName : this.form.userName,
							bankName : this.form.bankName,
							remark : this.form.remark, */
						}
						axiosApi('/superc/traderOpenSuperC/update',body,res => {
							this.$message({type:'success',message:'配置成功！',duration:1000})
						},1,10,err=>{
							this.$message({type:'error',message:err.data.message,duration:1000})
						})
					}
				})
			},
		},
		mounted(){
			//查询商户开通久集的申请结果
			axiosApi('/superc/traderOpenSuperC/select',{
				traderId:sessionStorage.getItem('traderId')
			},res=>{
				this.form.cash = res.data.data.bondMoney;
				this.form.serviceMoney = res.data.data.serviceMoney;
				this.form.alipayAccount = res.data.data.alipayNumber;

				this.form.creditScale = res.data.data.creditScale !== null ? res.data.data.creditScale * 100 : '';
				/* this.form.userName = res.data.data.userName;
				this.form.bankCardNo = res.data.data.bankCardNo;
				this.form.bankName = res.data.data.bankName;
				this.form.remark = res.data.data.remark; */

				//商户开通久集申请记录id
				this.id = res.data.data.id;
				//验证保证金
				this.$refs.from && this.$refs.from.validateField("cash"); //部分表单字段验证
			})

		},
		created(){
			this.traderId = sessionStorage.getItem('traderId')
			//推广二维码
			this.config.value = this.PROJECT.href + '/#/register?sellerId=' + this.traderId;
		}
	}
</script>
<style lang="less">
	#supercConfig{
		.content{
			padding-bottom: 50px;
			form{
				.el-form-item{
					.el-form-item__label{
						padding: 0 20px 11px 0;
					}
					.remark{
						margin-left: 25px;
					}
					.el-button--danger.is-plain{
						span{
							color: #ff4201;
						}
					}
					img{
						height: 150px;
						width: 170px;
					}
				}
				
			}
			.avatar-uploader .el-upload {
			    border: 1px dashed #d9d9d9;
			    border-radius: 6px;
			    cursor: pointer;
			    position: relative;
			    overflow: hidden;
			}
			.avatar-uploader .el-upload:hover {
			    border-color: #20a0ff;
			}
			.avatar-uploader-icon {
			    font-size: 28px;
			    color: #8c939d;
			    width: 178px;
			    height: 178px;
			    line-height: 178px;
			    text-align: center;
			}
			.avatar {
			    width: 178px;
			    height: 178px;
			    display: block;
			}

		}
	}
</style>