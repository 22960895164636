<template>
	<div id="subsidiary_examine">
		<div class="title-top bg-f">
			{{isCheck ? '门店审核' : '门店查看'}}
		</div>
		<div class="bg-f mt-5">
        <div class="examine_reason" v-if="subsidiaryInfo.checkState === 2">驳回原因：{{subsidiaryInfo.backReason}}</div>
		<el-form :model="form" class="clearfix pt-20" label-width="200px" :rules="rules" ref="form">
            <el-form-item label="门店名称:" class="mb-30">
                <el-input v-model="subsidiaryInfo.storeName" readonly></el-input>
            </el-form-item>
            <el-form-item label="门店简称:" class="mb-30" v-if="subsidiaryInfo.storeShortName">
                <el-input v-model="subsidiaryInfo.storeShortName" readonly></el-input>
            </el-form-item>
            <el-form-item label="门店类型:">
                <el-input v-model="subsidiaryInfo.storeTypeName" readonly></el-input>
            </el-form-item>
            <el-form-item label="所在地区:">
                <el-form-item class="mb-20">
                    <el-input v-model="subsidiaryInfo.addrs" readonly></el-input>
                </el-form-item>
                <el-form-item class="mb-20">
						<el-input v-model="subsidiaryInfo.streetName" readonly></el-input>
					</el-form-item>
                <el-form-item>
                    <el-input v-model="subsidiaryInfo.addr" readonly class="w-300"></el-input>
                </el-form-item>
            </el-form-item>
            <el-form-item label="地图定位:" v-if="subsidiaryInfo.signOnLongitude">
					<router-link :to="`${basePath}/mapLocation?key=detail&id=${id}`">
						<el-button class="go_map">已定位，去查看></el-button>
					</router-link>
				</el-form-item>
            <el-form-item label="营业执照编号:">
                <el-input v-model="subsidiaryInfo.licenseNum" readonly></el-input>
            </el-form-item>
            <el-form-item label="营业执照照片:">
                <el-upload
                class="avatar-uploader"
                action="/sunrise-gateway/oss/ossUpload"
                :file-list='licenseImgList'
                list-type="picture-card"
                :on-preview="handlePreview">
				</el-upload>
            </el-form-item>
            <el-form-item label="合作协议:">
                <el-upload
                v-if="protocolImgList[0]"
                class="avatar-uploader"
                action="/sunrise-gateway/oss/ossUpload"
                :file-list='protocolImgList'
                list-type="picture-card"
                :on-preview="handlePreview">
				</el-upload>
                <span v-else>无</span>
                <!-- <a v-else-if="protocolImgList[0]&&protocolImgList[0].url" :href="protocolImgList[0].url" class="color-blue"  download="合作协议">点击查看pdf</a> -->
            </el-form-item>
            <el-form-item label="子公司名称:">
                <el-input v-model="subsidiaryInfo.sellerName" readonly></el-input>
            </el-form-item>
            <el-form-item label="K3编号:">
                <span>{{subsidiaryInfo.k3No||'无'}}</span>
            </el-form-item>
            <el-form-item label="申请日期:">
                <span>{{subsidiaryInfo.applyDate | time}}</span>
            </el-form-item>
            <el-form-item v-if="isCheck" class="mb-0">
                <p style="color: #D24C4C;">该编号为系统自动生成待定编码</p>
            </el-form-item>
            <el-form-item label="门店编码:" v-if="isCheck">
                <el-input v-model="subsidiaryInfo.storeNo" readonly></el-input>
            </el-form-item>
            <el-form-item label="最终意见:" v-if="isCheck">
                <el-radio class="radio" v-model="form.checkResult" :label="1">审核通过</el-radio>
                <el-radio class="radio" v-model="form.checkResult" :label="0">驳回</el-radio>
            </el-form-item>
            <el-form-item v-show="form.checkResult === 0" v-if="isCheck" prop="backReason" class="mb-30">
                <el-input v-model.trim="form.backReason" class="w-440" type="textarea" :rows="2" placeholder="请输入驳回原因"></el-input>
            </el-form-item>
            <el-form-item v-if="isCheck">
                <el-button type="primary" :loading="btnLoading" @click='examineSave("form")'>确 定</el-button>
                <el-button @click='$router.push(path)'>返 回</el-button>
            </el-form-item>
            <el-form-item v-else>
                <el-button  @click='$router.push(path)'>返 回</el-button>
            </el-form-item>
        </el-form>
        </div>
        <el-dialog :visible.sync="dialogVisible" class="preview_dialog">
            <img :src="dialogImageUrl" alt="">
        </el-dialog>
        <el-dialog title='提示' :visible.sync='confirmDialogVisible' class="confirm">
			<span>审核通过！</span>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" @click="handlePreview(accreditImg)">查看授权书</el-button>
				<el-button @click="$router.push(path)">返回列表</el-button>
			</div>
		</el-dialog>
	</div>
</template>
<script>
	import { axiosApi, basePath } from "../../../api/api";
	export default {
		data() {
            var validateNoPass = (rule, value, callback) => {
                if (!value && this.form.checkResult === 0) {
                    callback(new Error('请输入驳回原因'));
                } else {
                    callback();
                }
            }
			return {
                path: basePath + '/storeNumber',
				Loading: false,
				dialogVisible: false,
				dialogImageUrl: null,
				form: {
					id: null,
					checkResult: 1,//审核结果 0 驳回，1 审核通过
                    backReason: null,//驳回原因
                    sellerNo: null,//子公司编码
                    storeType: null,//店铺类型 A-加久*久柜；B-久加久*连锁店；C-久加久*店中店
                    storeName: null,//店铺名称
                    storeShortName: null
				},
				rules: {
					backReason: [{ validator: validateNoPass }],
				},
                subsidiaryInfo: {},
                isCheck: 0,//0 查看 1 审核
                id: null,
                licenseImgList: [],
                protocolImgList: [],
                accreditImg: {
                    url: null
                },
                confirmDialogVisible: false,
                btnLoading: false
			}
		},
		methods: {
            handlePreview(file){
                this.dialogImageUrl = file.url;
                this.dialogVisible = true;
            },
			examineSave(formName){
				this.$refs[formName].validate((valid) => {
					if(valid) {
                        this.btnLoading = true
						const api = '/trader/seller/checkStoreCode'
						axiosApi(api, this.form, res=>{
                            this.btnLoading = false
                            if(this.form.checkResult === 1){
                                this.confirmDialogVisible = true
                                this.accreditImg.url = res.data.data.accreditImg
                            }else{
                                this.$message.success('驳回成功!')
                                this.$router.push(this.path)
                            }
						}, 0, 10, err=> {
                            this.btnLoading = false
							this.$message.warning(err.data.message)
						})
					} else {
						return false
					}
				})
			},
			getStoreInfo() {//子公司编码维护列表
				const api = "/trader/seller/getStoreCodeCheck";
				const body = {
					id: this.id,
					isCheck: this.isCheck
				}
				axiosApi(api, body, (res)=>{
                    const a = res.data.data
                    this.subsidiaryInfo = a
                    this.subsidiaryInfo.addrs = `${a.provinceName}${a.cityName}${a.regionName}`

                    this.subsidiaryInfo.signOnLongitude = a.longitude
					this.subsidiaryInfo.signOnLatitude = a.latitude

					if(sessionStorage.postdata){
						this.subsidiaryInfo.signOnLongitude = JSON.parse(sessionStorage.postdata).signOnLongitude
						this.subsidiaryInfo.signOnLatitude = JSON.parse(sessionStorage.postdata).signOnLatitude
					}else{
						if(a.longitude){
							let postdata = {signOnLongitude:a.longitude,signOnLatitude:a.latitude,read:true}
							sessionStorage.postdata=JSON.stringify(postdata)
						}
					}

                    this.licenseImgList = [{url: a.licenseImg}]
                    if (a.protocolImgSrc && (a.protocolImgSrc.indexOf('.jpg') > 0 || a.protocolImgSrc.indexOf('.jpeg') > 0) ) {
                        a.protocolImgSrc.split(',').forEach(item => {
                            this.protocolImgList.push({url: item})
                        })
                    } else {
                        this.protocolImgList = []
                    }
                    this.form = {
                        id: a.id,
                        checkResult: 1,//审核结果 0 驳回，1 审核通过
                        backReason: null,//驳回原因
                        sellerNo: a.sellerNo,//子公司编码
                        storeType: a.storeType,//店铺类型 A-加久*久柜；B-久加久*连锁店；C-久加久*店中店
                        storeName: a.storeName,//店铺名称
                        storeShortName: a.storeShortName
                    }
                } , 0, 10, err=>{
                    this.$message.warning(err.data.message)
                })
			}
		},
		//进入加载
		mounted() {
			this.$nextTick(function() {
                this.isCheck = this.$route.query.key === 'detail' ? 0 : 1
                this.id = this.$route.query.id
				this.getStoreInfo()
			})
		}
	}
</script>

<style lang="less">
	#subsidiary_examine {
		.preview_dialog{
			.el-dialog{
				width: 80%;
			}
		}
        .el-dialog__body{
            box-sizing: border-box;
            padding: 30px 20px;
            text-align: center;
            img{
            	max-width: 100%;
            }
        }
        .examine_reason{
            padding: 50px 114px 0;
            color: #D24C4C;
            font-size: 18px;
        }
		.w-440 {
            width: 440px;
            display: block;
		}
		.w-300{
			width: 300px;
        }
        .avatar-uploader{
            display: inline;
        }
        .el-upload--picture-card, .el-upload-list__item-delete{
            display: none;
        }
        .confirm{
            .el-dialog__body{
                padding: 30px 20px;
                text-align: center;
                font-size: 18px; 
            }
            .el-dialog{
                min-height: 240px;
            }
        }
        
		.go_map{
			width:170px;
			height: 32px;
			color: #20a0ff;
			font-size: 14px;
		}
	}
</style>