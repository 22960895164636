<template>
	<div id="visitAction">
		<div class="title-top bg-f mb-5">
			<div class="clearfix">
				<el-breadcrumb class="fl">
					<el-breadcrumb-item>配置详情-{{$route.query.name}}</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
		</div>
		<div class="main bg-f pb-50">
			<div class="mr-30 pt-30 mb-20 tar">
				<el-button v-if='showButton("ADD",200021)' @click="addAction('action')">新建动作</el-button>
				<el-button v-if='showButton("RESET",200021)' type="danger" @click="recoverDefault" class="ml-15">恢复默认</el-button>
			</div>
			<el-table :data="tableData" class='ml-30 table-default mr-30' highlight-current-row stripe>
				<el-table-column label="序号" prop="actionOrder" width="120">
				</el-table-column>
				<el-table-column label="动作名称" prop="actionName" width="150">
				</el-table-column>
				<el-table-column label="备注" prop="actionRemark" width="">
				</el-table-column>
				<el-table-column label="动作类型" width="150">
					<template slot-scope="scope">
						<div v-if="scope.row.actionType==1">系统动作</div>
						<div v-if="scope.row.actionType==2">自定义动作</div>
					</template>
				</el-table-column>
				<el-table-column id="isWrite" label="必填" width="150">
					<template slot-scope="scope">
						<div v-if='showButton("EDIT_WRITE",200021)'>
							<el-button v-if="scope.row.isWrite==1" @click="handleSelectChange(scope.row)" type="text" size="small">是</el-button>
							<el-button v-if="scope.row.isWrite==0" class="disabled" @click="handleSelectChange(scope.row)" type="text" size="small">否</el-button>
						</div>
						<div v-else>
							<span>{{scope.row.isWrite == 1 ? '是' : '否'}}</span>
						</div>

					</template>
				</el-table-column>
				<el-table-column label="操作" width="180">
					<template slot-scope="scope">
						<el-button @click="lookDetail(scope.$index,scope.row)" type="text" size="small">查看</el-button>
						<el-button v-if='showButton("UPD_SORT",200021)' @click="changeOrder(scope.row.actionOrder,scope.row.actionName,scope.$index)" type="text" size="small">更改排序</el-button>
						<el-button v-if='scope.row.actionStatus!=0&&scope.row.actionName!="签到与签退" && showButton("DISABLE",200021)' @click="forbidden(scope.$index)" type="text" size="small">禁用</el-button>

						<el-button v-if='scope.row.actionStatus==0 && showButton("DISABLE",200021)' class="disabled" @click="forbidden(scope.$index)" type="text" size="small">启用</el-button>
						<el-button v-if='scope.row.actionType==2 && showButton("EDIT",200021)' @click="editOne(scope.row)" type="text" size="small">编辑</el-button>
						<el-button v-if='scope.row.actionType==2 && showButton("DEL",200021)' @click="deleteOne(scope.$index)" type="text" size="small">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<!-- 更改排序 -->
		<el-dialog title="更改排序" :visible.sync="dialogChange" size="small" @close="orderOpenFirst=0;dialogChange=false">
			<el-form :model="nowAction" ref="orderForm" label-width="200px" class="sortWrap">
				<el-form-item label="动作名称">
					<span class="mt-20 ml-20">{{nowAction.nowActionName}}</span>
				</el-form-item>
				<el-form-item label="当前排序" prop="nowActionOrder" :rules="[{required: true, message: '序号不为空', trigger: 'blur'},{max:2,pattern:/^[1-9]$|^[1]\d{1}$|^[2][0]$/, message:'序号为大于0且不大于20的数字',trigger: 'change'}]">
					<el-input v-model.trim="nowAction.nowActionOrder"></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
                <el-button type="danger" @click="submitOrder('orderForm')">确 定</el-button>
            </span>
		</el-dialog>
		<!-- 新增动作 -->
		<el-dialog :title="name+'拜访动作'" :visible.sync="dialogVisible" size="" @close="closeReset" :class="{small:looksmall}" width="840px">
			<el-form :model="addData" label-width="130px" ref="actionForm">
				<el-form-item label="动作名称" prop="actionName" :rules="[{required: true, message: '请输入名称', trigger: 'blur'}]">
					<el-input placeholder="请输入名称" v-model.trim="addData.actionName" :disabled="disabled" :maxlength="20"></el-input>
				</el-form-item>
				<el-form-item label="动作序号" prop="actionOrder" :rules="[{required: true, message: '请输入序号', trigger: 'blur'}]">
					<el-input v-if="disabled" placeholder="请输入序号" v-model.trim="addData.actionOrder" disabled="disabled"></el-input>
					<sy-input v-if="!disabled" type="int" size="mini" :min="1" :max="20" v-model="addData.actionOrder"></sy-input>
				</el-form-item>
				<el-form-item label="动作备注" prop="actionRemark" :rules="[{required: true, message: '请输入备注', trigger: 'blur'}]">
					<el-input placeholder="请输入备注" size="large" v-model.trim="addData.actionRemark" :disabled="disabled" :maxlength="20"></el-input>
				</el-form-item>

				<!-- 动态内容 -->
				<el-form-item v-if="!disabled" label="动作内容">
					<el-button class="color-danger" @click="addContent()">新增动作</el-button>
				</el-form-item>
                <div v-if="addData.visitActionContents">
                    <div class="new-options-item"  v-for="(item,index) in addData.visitActionContents" :key="index">
                        <el-form-item label="类型" class="dib" required>
                            <el-select :disabled="disabled" @change="typechange(index)" v-model="item.contentType" required>
                                <el-option v-for="i in options" :key="i.value" :label="i.label" :value="i.value">
                                </el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item :label="'内容标题'+(index+1)" class="dib" :prop="'visitActionContents.' + index + '.contentName'":rules="[{required: true, message: '请输入标题', trigger: 'blur'}]">
                            <el-input placeholder="请输入标题" v-model.trim="item.contentName" :disabled="disabled" :maxlength="20"></el-input>
                        </el-form-item>

                        <el-form-item v-if="item.contentType == 1 || item.contentType == 2" label="长度/张数" class="dib" required>
                            <el-input v-model.trim="item.contentLength" v-if="disabled" disabled="disabled"></el-input>
                            <sy-input v-if="!disabled" type="int" size="mini" :min="1" :max="item.contentType == 1 ? 20 : 10" v-model="item.contentLength" :disabled="disabled"></sy-input>
                        </el-form-item>

                        <el-form-item v-if="item.contentType == 4 || item.contentType == 5" label="选项内容" class="dib" required style="width: 445px;">
                            <div class="option-child-item" v-for="(i, index2) in item.contentOptions" :key="index2">
                                <el-input :placeholder="item.contentType == 4 ? '单选项内容':'下拉项内容'" v-model="i.name" :disabled="disabled" :maxlength="20">
                                </el-input>

                                <el-button v-if="name !== '查看'" @click="removeOption(index, index2)">删除</el-button>
                            </div>
                            <el-button v-if="name !== '查看'" class="color-danger" @click="addOption(index)">新增选项</el-button>
                        </el-form-item>

                        <div v-if="!disabled" class="delete" @click.prevent="removeContentItem(item,index)">
                            <div></div>
                        </div>

                    </div>
                </div>
				

				<el-form-item label="是否为必填动作" class="mt-20 mb-50" prop="isWrite" required>
					<el-select :disabled="disabled" v-model="addData.isWrite">
						<el-option v-for="i in [{value: 1,label:'是'},{value: 0,label:'否'}]" :key="i.value" :label="i.label" :value="i.value">
						</el-option>
					</el-select>

				</el-form-item>
			</el-form>
			<span v-if="!disabled" slot="footer" class="dialog-footer">
                
                <el-button type="danger" @click="submitAdd()" :loading="submitLoading">提交</el-button>
            </span>
		</el-dialog>
	</div>
</template>

<script>
	import { axiosApi, basePath } from '../../api/api'
	import Confirm from '../../utils/formconfirm'

	export default {
		data() {
			return {
				max: 20,
				maxlength: 2,
				tableData: [],
				dialogVisible: false,
				dialogChange: false,
				value1: '',
				value2: '',
				addData: { //新增手动动作数据
					actionName: '',
					actionRemark: '',
					actionOrder: '',
					isWrite: 1,
					visitActionContents: [{
						contentName: '',
						contentType: 1,
						contentLength: ''
					}]
				},
				nowAction: {
					nowActionName: '',
					nowActionOrder: null
				},
				nowIndex: '',
				nowActionStatus: '',
				disabled: false,
				nowContentType: '',
				isEdit: false, //编辑的提交
				newAction: false,
				name: '新建', //默认为新建
				orderok: false,
				orderOpenFirst: 0,
				myactionSize: 'large',
				looksmall: false,
				submitLoading: true,
				options: [{
						label: '文本',
						value: 1,
					},
					{
						label: '图片',
						value: 2,
					},
					{
						label: '时间',
						value: 3
					},
					{
						label: '单选框',
						value: 4
					},
					{
						label: '下拉框',
						value: 5
					}
				],
				countEditFun: 0,
				momentSpecialTypeLength: 0 //点编辑第一时刻有几个类型4和类型5的
			}
		},
		watch: {
			nowAction: { //打开编辑并没有编辑文本框数字
				handler() {
					this.orderOpenFirst++
				},
				deep: true
			}
		},
		computed: {
			partActionName() { //除了总结，其他动作
				let aaa = this.tableData.filter(item => {
					return item.actionName != '总结' && item.actionStatus == 1 && item.isWrite == 1
				})

				return aaa;
			}
		},
		methods: {
			addOption(index) { // 添加选项
				if(this.addData.visitActionContents[index].contentOptions.length < 20) {
					this.addData.visitActionContents[index].contentOptions.push({
						name: ''
					})
				} else {
					this.$notify({
						message: '最多设置20条选项内容',
						type: 'warning'
					});
				}
			},
			removeOption(index, index2) { // 移除选项
				if(this.addData.visitActionContents[index].contentOptions.length <= 1) {
					this.$notify({
						message: '最少保留一项',
						type: 'warning'
					});
				} else {
					this.addData.visitActionContents[index].contentOptions.splice(index2, 1)
				}
			},
			typechange(index) {
				this.countEditFun++
					this.addData.visitActionContents[index].contentLength = this.addData.visitActionContents[index].contentType == 1 ? 20 : 10;
				if(this.countEditFun > this.momentSpecialTypeLength) {
					this.$set(this.addData.visitActionContents[index], 'contentOptions', [{
						name: ''
					}])
				}
			},
			queryVisitAction() {
				axiosApi('/sales/visitAction/web/selectAll', {
					actionClassifyId: this.$route.query.id
				}, res2 => {
					this.tableData = res2.data.data
				})
			},
			recoverDefault() { //恢复默认
				this.$confirm('您是否要恢复默认？', '提示信息', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					axiosApi('/sales/visitActionRecover/web/insert', {
						actionClassifyId: this.$route.query.id
					}, res => {
						this.queryVisitAction()
						this.$message({
						type: 'success',
						message: '操作成功',
						duration: 2000
					});
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消操作',
						duration: 2000
					});
				})
			},
			addAction() { //新建动作
				this.submitLoading = false;
				this.disabled = false;
				this.dialogVisible = true;
				this.name = '新建'
				this.countEditFun = 0
				this.momentSpecialTypeLength = 0
				//不是编辑
				this.isEdit = false
				this.newAction = true
				let order = 1;
				axiosApi('/sales/visitAction/web/selectDefaultOrder', {
					actionClassifyId: this.$route.query.id
				}, res => {
					order = res.data.data.actionOrder
					this.addData = {
						actionName: '',
						actionRemark: '',
						actionOrder: order,
						isWrite: 1,
						visitActionContents: [{
							contentName: '',
							contentType: 1,
							contentLength: ''
						}]
					}

				})
			},
			closeReset() { //新建动作框关闭时候操作
				this.looksmall = false;
				this.$refs.actionForm.resetFields()
				this.dialogVisible = false
			},
			addContent() { //新增动作内容   
				if(this.addData.visitActionContents.length >= 20) {
					this.$notify({
						message: '最多设置20项内容',
						type: 'warning'
					});
					return false;
				}
				this.addData.visitActionContents.push({
					contentName: '',
					contentType: 1,
					contentLength: '',
					contentOptions: [{
						name: ''
					}]
				})
			},
			removeContentItem(item, index) { //删除单条动作内容
				if(this.addData.visitActionContents.length == 1) {
					this.$notify({
						message: '最少保留一项',
						type: 'warning'
					});
					return false;
				} else {
					this.addData.visitActionContents.splice(index, 1)
				}
			},
			submitAdd() { //新建动作提交&&编辑内容提交
				let arr = new Array()

				//提交之前判断必填参数已经填写,,,判断是编辑还是新建
				this.startSubmit = true;
				this.addData.visitActionContents.forEach(item => {
					if(item.contentType == 4 || item.contentType == 5) {
						// delete item.contentLength
					} else if(item.contentType == 1 || item.contentType == 2) {
						delete item.contentOptions
					} else if(item.contentType == 3) {
						// delete item.contentLength
						delete item.contentOptions
					}
				})
				let postData = this.addData;
				postData.actionClassifyId = this.$route.query.id

				let newconfirm = new Confirm()
				let confirmdata = this.addData
				newconfirm.use([confirmdata.actionName], '请输入动作名称')
				newconfirm.use([confirmdata.actionOrder], '请输入动作序号')
				newconfirm.use([confirmdata.actionRemark], '请输入动作备注')
				confirmdata.visitActionContents.forEach((a, index1) => {
					arr[index1] = new Array()
					newconfirm.use([a.contentName], '请完整填写内容标题')
					if(a.contentType == 1 || a.contentType == 2) {
						newconfirm.use([a.contentLength], '请完整填写所有长度/张数')
					} else if(a.contentType == 4 || a.contentType == 5) {
						if(a.contentOptions.length > 20) {
							this.$message({
								message: '最多设置20条选项内容',
								type: 'warning'
							});
							newconfirm.status = false
						} else {
							a.contentOptions.forEach(b => {
								newconfirm.use([b.name], '请完整填写所有选项内容')
								arr[index1].push(b)
								if(b.name && /\$/.test(b.name)) {
									this.$message({
										message: '选项内容中不可包含$符号，请重新填写',
										type: 'warning'
									});
									newconfirm.status = false
								}
							})
						}
					}
				})
				if(newconfirm.status) {
					for(let i = 0; i < arr.length; i++) {
						let eachArr = []
						for(let m = 0; m < arr[i].length; m++) {
							eachArr.push(arr[i][m].name)
						}
						let laterEachArr = eachArr.unique()
						if(laterEachArr.length != eachArr.length) {
							newconfirm.status = false
							this.$message({
								message: '存在相同名称的选项内容',
								type: 'warning'
							});
							break;
						}
					}
				}

				this.submitLoading = false;
				if(newconfirm.status) {
					// 验证全部填写然后在处理数据格式
					postData.visitActionContents.forEach(item => {
						if(item.contentType == 4 || item.contentType == 5) {
							let arr = []
							item.contentOptions.forEach(item2 => {
								arr.push(item2.name)
							})
							item.contentOptions = arr.join('$')
						}
					})
					// 数据格式有问题，所以必须这里点了提交验证通过就关闭弹窗
					

					if(this.isEdit) { //编辑
						axiosApi('/sales/visitAction/web/update', postData, res => {
							this.dialogVisible = false;
							this.queryVisitAction()
						},1,10,res=>{
							this.$message({
								type: 'info',
								message: res.data.message,
								duration: 3000
							});
						})
					} else { //新建动作
						axiosApi('/sales/visitAction/web/insert', postData, res => {
							this.dialogVisible = false;
							this.queryVisitAction()
						},1,10,res=>{
							this.$message({
								type: 'info',
								message: res.data.message,
								duration: 3000
							});
						})
					}
				}
			},
			handleSelectChange(item) { //是否为必填项更改
				if(item.actionName != '签到与签退') {
					const body = {
						id: item.id,
						isWrite: item.isWrite == 1 ? 0 : 1,
						actionClassifyId: this.$route.query.id
					}
					axiosApi('/sales/visitAction/web/update', body, res => {
						this.queryVisitAction()
					})
				} else {
					this.$notify({
						message: '签到与签退不可更改',
						type: 'warning'
					})
				}
			},
			changeOrder(order, name, index) { //更改顺序弹窗
				this.dialogChange = true;
				this.nowIndex = index;
				setTimeout(() => {
					this.$refs.orderForm.resetFields() //清空验证规则   
					this.nowAction = {
						nowActionName: name,
						nowActionOrder: order
					}
				}, 0)
			},
			submitOrder(formName) {
				const body = {
					id: this.tableData[this.nowIndex].id,
					actionOrder: this.nowAction.nowActionOrder,
					actionType: this.tableData[this.nowIndex].actionType,
					actionClassifyId: this.$route.query.id
				}
				this.$refs[formName].validate((valid) => {
					if(this.orderOpenFirst == 1) { //打开编辑框，没有对数字进行编辑
						this.dialogChange = false
						axiosApi('/sales/visitAction/web/update', body, res => {
							axiosApi('/sales/visitAction/web/selectAll', {
								actionClassifyId: this.$route.query.id
							}, res => {
								this.tableData = res.data.data
							})
						})
					} else if(valid) { //编辑符合正则
						this.dialogChange = false
						axiosApi('/sales/visitAction/web/update', body, res => {
							axiosApi('/sales/visitAction/web/selectAll', {
								actionClassifyId: this.$route.query.id
							}, res => {
								this.tableData = res.data.data
							})
						})
					} else {
						return false;
					}
				});
			},
			forbiddenFn(index) {
				this.tableData[index].actionStatus == 1 ? this.tableData[index].actionStatus = 0 : this.tableData[index].actionStatus = 1;
				const body = {
					id: this.tableData[index].id,
					actionStatus: this.tableData[index].actionStatus,
					actionType: this.tableData[index].actionType,
					actionClassifyId: this.$route.query.id
				}
				axiosApi('/sales/visitAction/web/update', body, res => {
					this.queryVisitAction()
				})
			},
			forbidden(index) {
				this.tableData[index].actionStatus == 1 ? this.tableData[index].actionStatus = 0 : this.tableData[index].actionStatus = 1;
				const body = {
					id: this.tableData[index].id,
					actionStatus: this.tableData[index].actionStatus,
					actionType: this.tableData[index].actionType,
					actionClassifyId: this.$route.query.id
				}
				axiosApi('/sales/visitAction/web/update', body, res => {
					this.queryVisitAction()
				})

			},
			lookDetail(index, row) {
				this.disabled = true
				this.dialogVisible = true
				this.newAction = false
				this.name = '查看'
				if(this.tableData[index].actionType == 1) {
					this.looksmall = true;
				} else {
					this.looksmall = false;
				}

				this.countEditFun = 0 // 为了能在新增切换完之后保证正确
				//取出来需要的部分
				let obj = {
					actionName: row.actionName,
					actionRemark: row.actionRemark,
					actionOrder: row.actionOrder,
					isWrite: row.isWrite,
					id: row.id,
					actionType: 2,
					visitActionContents: []
				}

				//使用for in发现bug，2018-04-16，现在改成forEach
				let ccc = 0
				row.visitActionContentList.forEach(item => {
					if(item.contentType == 4 || item.contentType == 5) {
						ccc++
					}
					let arr = []
					if(item.contentOptions) {
						let optionarr = item.contentOptions.split('$')
						optionarr.forEach(item2 => {
							arr.push({
								name: item2
							})
						})
					}
					obj.visitActionContents.push({
						contentName: item.contentName,
						contentType: item.contentType,
						contentLength: item.contentLength,
						contentOptions: arr
					})
				})
				this.momentSpecialTypeLength = ccc

				this.$nextTick(() => {
					this.addData = obj
				})
			},
			editOne(row) {
				this.submitLoading = false
				this.disabled = false
				this.dialogVisible = true
				this.isEdit = true
				this.newAction = false
				this.name = '编辑'
				this.countEditFun = 0
				//取出来需要的部分
				let obj = {
					actionName: row.actionName,
					actionRemark: row.actionRemark,
					actionOrder: row.actionOrder,
					isWrite: row.isWrite,
					id: row.id,
					actionType: 2,
					visitActionContents: []
				}

				//使用for in发现bug，2018-04-16，现在改成forEach
				let ccc = 0
				row.visitActionContentList.forEach(item => {
					if(item.contentType == 4 || item.contentType == 5) {
						ccc++
					}
					let arr = []
					if(item.contentOptions) {
						let optionarr = item.contentOptions.split('$')
						optionarr.forEach(item2 => {
							arr.push({
								name: item2
							})
						})
					}
					obj.visitActionContents.push({
						contentName: item.contentName,
						contentType: item.contentType,
						contentLength: item.contentLength,
						contentOptions: arr
					})
				})
				this.momentSpecialTypeLength = ccc

				this.$nextTick(() => {
					this.addData = obj
				})
			},
			deleteOne(index) {
				this.$confirm('此操作将删除该动作, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					axiosApi('/sales/visitAction/web/delete', {
						ids: [this.tableData[index].id]
					}, res => {
						axiosApi('/sales/visitAction/web/selectAll', {
							actionClassifyId: this.$route.query.id
						}, res => {
							this.tableData = res.data.data
							this.$message({
								type: 'success',
								message: '删除成功!'
							});
						})
					})

				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});
			}
		},
		mounted() {
			this.$nextTick(() => {
				this.queryVisitAction()
			})
		}

	}
</script>

<style lang="less">
	#visitAction {
		.el-dialog__wrapper {
			z-index: 100!important;
			.el-dialog.el-dialog-- {
				.el-dialog__body {
					width: 840px;
				}
			}
		}
		.h1 {
			height: 1px;
			background: #dfdfdf;
			width: 100%;
		}
		.disabled {
			color: gray;
		}
		.bd {
			padding-bottom: 10px;
		}
		.inputLength {
			width: 75px;
		}
		.selectWrap {
			display: inline-block;
			.el-input {
				width: 75px;
			}
		}
		.addAction {
			width: 470px;
		}
		.sortWrap {
			position: relative;
		}
		.el-input {
			width: 115px;
			height: 32px;
		}
		.customReg:before {
			content: '*';
			color: #ff4949;
			margin-right: 0px;
		}
		.hide {
			display: none;
		}
		.errorRegWrap {
			position: relative;
		}
		.errorReg1 {
			color: #ff4949;
			font-size: 12px;
			line-height: 1;
			padding-top: 4px;
			position: absolute;
			top: 100%;
			left: 0;
			margin-left: 50px;
		}
		.errorReg2 {
			color: #ff4949;
			font-size: 12px;
			line-height: 1;
			padding-top: 4px;
			position: absolute;
			top: 100%;
			left: 0;
			margin-left: 80px;
		}
		.el-input__icon+.el-input__inner {
			font-size: 12px;
		}
		.delete {
			display: inline-block;
			vertical-align: middle;
			position: relative;
			margin-left: 10px;
			width: 16px;
			height: 16px;
			border-radius: 8px;
			background: #ff4201;
			&>div {
				position: absolute;
				height: 2px;
				width: 12px;
				background: #fff;
				left: 2px;
				top: 7px;
			}
		}
		.cell .el-button {
			width: auto !important;
		}
		.new-options-item {
			border: 1px solid #ddd;
			margin: 10px 30px;
			padding-top: 10px;
			.option-child-item {
				margin-bottom: 5px;
			}
		}
	}
</style>