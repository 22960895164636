<template>
 <div id="freightHandle" class="pb-50">
   <!-- query.type 1 查看，2新建，3编辑, 4复制 -->
  <div class="title-top bg-f">{{$route.query.type == 1 ? '查看运费模版' : $route.query.type == 2 ? '新建运费模版' : '编辑运费模版'}}</div>
  <div class="bg-f mt-5 pb-50 pt-30 pl-30 freight-name-ct">
    <el-form v-if="$route.query.type != 1" :model="searchForm" class="clearfix" label-width="auto" :rules="rules">
      <div class="fl width-70p search-ct">
      <el-form-item label="模版名称" prop='name'>
        <el-input placeholder="请输入模版名称" v-model.trim='searchForm.name'></el-input>
      </el-form-item>
      <el-form-item label="使用范围" prop='name'>
        <el-select v-model="searchForm.businessType" placeholder="请选择">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="寄件方式">
        <el-radio-group v-model="searchForm.radio">
          <el-radio label="1">按箱</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="是否默认模版">
        <el-radio-group v-model="searchForm.isDefault">
          <el-radio :label="1">是</el-radio>
          <el-radio :label="0">否</el-radio>
        </el-radio-group>
      </el-form-item>
      </div>
    </el-form>

    <div  class="main-first bg-f pl-30 pr-30">
      <!-- 查看 -->
      <div v-if="$route.query.type == 1" class="main-second bg-f">
        <el-card class="mt-20">
          <div slot="header" class="header-wrap">
            <div>{{gettedItem.name}}</div>
            <div class="color-6">最后编辑时间：{{gettedItem.gmtModified | timenyr}}</div>
          </div>
          <div class="repeat-ct-wrap">
            <div class="ct-l-ct color-3">
              <div>可配送地区</div>
              <div class="width-150">首箱(元)</div>
              <div class="width-150">续箱(元)</div>
            </div>
            <div class="repeat-ct">
              <div class="ct-l" >
                <div class="ct-l-ct" v-for="(item2, index2) in gettedItem.fareDeliveryModeList" :key="index2">
                  <div class="width-437 l-h-24">{{item2.regionText}}</div>
                  <div class="width-150">{{item2.firstPrice}}</div>
                  <div class="width-150">{{item2.cumulationPrice}}</div>
                </div>
              </div>
            </div>
          </div>
        </el-card>
      </div>

      <!-- 新建或编辑 -->
      <div v-else class="main-second mt-10">
        <el-card>
          <div slot="header" class="header-wrap">
            <div>可配送地区</div>
            <div class="width-150">首箱(元)</div>
            <div class="width-150">续箱(元)</div>
          </div>
          <div class="repeat-ct-wrap">
            <div class="ct-l-ct" v-for="(item2,index2) in gettedItem.fareDeliveryModeList" :key="index2">
              <div class="l-h-30">
                <div class="display-flex">

                  <div v-if="$route.query.type == 2 && index2 == 0" class="color-6">
                    <el-checkbox v-model="allChecked">所有地区默认配送价</el-checkbox>
                    <span v-if="$route.query.type == 2" class="color-9">
                      （未勾选，则仅下列所选区域可配送）
                    </span>
                  </div>
                  <div v-else-if="item2.region" class="color-6">{{item2.regionText}}</div>
                  <div v-else class="color-9">请编辑可配送区域</div>
                  <el-button v-if="$route.query.type != 2 || ($route.query.type == 2 && index2 != 0)" type="text" @click="editRow(index2, item2)">编辑</el-button>
                  <el-button v-if="$route.query.type != 2 || ($route.query.type == 2 && index2 != 0)" type="text" @click="deleteRow(index2,item2)">删除</el-button>
                </div>
              </div>
              <div class="width-150">
                <input style="height: 70%;outline:none;" v-model="item2.firstPrice" type="text" maxlength="6" 
                @change="item2.firstPrice = /^([1-9][\d]{0,2}|0)(\.[\d]{1,2})?$/.test(String(item2.firstPrice) )? item2.firstPrice : isNaN(Number(item2.firstPrice)) ? 0 : Number(item2.firstPrice) > 999.99 ? 999.99 : 0">
              </div>
              <div class="width-150">
                <input style="height: 70%;outline:none;" v-model="item2.cumulationPrice" type="text" maxlength="6" 
                @change="item2.cumulationPrice = /^([1-9][\d]{0,2}|0)(\.[\d]{1,2})?$/.test(String(item2.cumulationPrice) )? item2.cumulationPrice : isNaN(Number(item2.cumulationPrice)) ? 0 : Number(item2.cumulationPrice) > 999.99 ? 999.99 : 0">
              </div>
            </div>
            <div class="add-btn">
              <el-button type="info" @click="addNewRow">添加可配送区域和运费</el-button>
            </div>
          </div>
        </el-card>
        <div  class="ft bg-f">
          <el-button type="primary" @click="submit" :loading="btnLoading">保 存</el-button>
          <el-button @click="cancel">取 消</el-button>
        </div>
      </div>
    </div>
  </div>

  <el-dialog :visible.sync="areaDialog" size="middle" @close="areaDialog = false" title="编辑可配送区域" width="820px">
    <div class="areas-div clearfix">
      <div class="area-div fl" >
        <div class="ct" :class="{active: item1.id == clickedProvince}" v-for="item1 in rawCitiesData2" :key="item1.id" @click="changePro(item1.id)">
          <el-checkbox v-model="item1.checked" @change="changeAll">{{item1.name}}</el-checkbox>
          <i class="el-icon-arrow-right fr mt-8"></i>
        </div>
      </div>
      <div class="slecct-all">
        <el-checkbox v-model="allCountry" @change="changeAllCountry">全国</el-checkbox>
      </div>
      <div class="area-div fl" v-if="area2 && area2[0]">
        <div v-if="area2 && area2[0]">
          <div class="ct" :class="{active: item2.id == clickedCity}" v-for="item2 in area2[0].childs" :key="item2.id" @click="changeCity(item2.id)">
            <el-checkbox v-model="item2.checked" @change="handleCheckedCityChange" :title="item2.name" :label="item2.name" >{{item2.name}}</el-checkbox>
            <i class="el-icon-arrow-right fr mt-8"></i>
          </div>
        </div>
      </div>
      <div class="area-div fl" v-if="area3 && area3[0]">
        <div v-if="area3 && area3[0]">
          <div class="ct" v-for="item3 in area3[0].childs" :key="item3.id">
            <el-checkbox v-model="item3.checked" @change="handleCheckedAreaChange" :title="item3.name" :label="item3.name" >{{item3.name}}</el-checkbox>
          </div>
        </div>
      </div>

      <div class="area-div fr" >
        <div class="ct">已选省、市、区</div>
        <div class="ct" v-for="item1 in rawCitiesData2" :key="item1.id">
          <!-- 省 -->
          <div v-show="item1.showProvince" @click="item1.show = !item1.show">{{item1.name}}
            <i class="el-icon-caret-top" v-show="item1.show"></i>
              <i class="el-icon-caret-bottom" v-show="!item1.show"></i>
            </div>
          <div class="ml-10" v-show="item1.show" v-for="item2 in item1.childs" :key="item2.id">
            <!-- 市 -->
            <div v-show="item2.showCity" @click="item2.show = !item2.show">
              <span>{{item2.name}} </span>
              <i class="el-icon-caret-top" v-show="item2.show"></i>
              <i class="el-icon-caret-bottom" v-show="!item2.show"></i>
            </div>
            <div class="ml-10" v-show="item2.show" v-for="item3 in item2.childs" :key="item3.id">
              <!-- 区/县 -->
              <div v-show="item3.checked">{{item3.name}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="areaDialog = false">取 消</el-button>
      <el-button type="primary" @click="ensureArea">确 定</el-button>
    </div>
  </el-dialog>

  

 </div>
</template>

<script>
import { axiosApi, basePath } from "../../../api/api";
import { templateValidate } from "~/api/axiosInfo";
//字段提前声明，否则会报错
let rawCitiesData2 = JSON.parse(localStorage.getItem('areaList'))||[]
rawCitiesData2.forEach(item1 => {
  item1.show = false;
  item1.checked = false;
  item1.showProvince = false;
  item1.childs.forEach(item2 => {
    item2.show = false;
    item2.checked = false;
    item2.showCity = false;
    item2.childs.forEach(item3 => {
      item3.checked = false;
      item2.show = false;
      if (item3.checked) {
        item2.show = true;
        item1.show = true;
      }
    })
  })
})


export default {
  data() {
    return {
      allChecked: true,
      searchForm: {
        radio: '1',
        name: "",
        businessType: '',
        isDefault: 1,
      },
      allArea: {
        checked: false,
        first: '',
        next: ''
      },
      gettedItem: {
        name: "",
        fareDeliveryModeList: [
          {
            region: "",
            firstPrice: '',
            cumulationPrice: ''
          }
        ]
      },
      clickedIndex: 0,
      rawCitiesData2: rawCitiesData2,
      clickedProvince: "",
      clickedCity: "",
      areaDialog: false,
      rules: {
        name: [
          { required: true, message: '请输入模板名称', trigger: 'blur' },
          { min: 2, max: 20, message: '长度在 2 到 20字, 可输入中文、英文、数字', trigger: 'blur' },
          { pattern: /^[\u4e00-\u9fa5a-zA-Z0-9]{2,20}$/, message: '长度在 2 到 20字, 可输入中文、英文、数字'}
        ],
         businessType: [
          { required: true, message: '请输入选择使用范围', trigger: 'change' }
        ]
      },
      shopAddr: '',
      btnLoading: false,
      options:[
        {label:'久集',value:'superc'},
        {label:'超团',value:'groupon'},
        {label:'云商',value:'b2b'},
      ]
    };
  },
  computed: {
    allCountry() {
      let aa = true;
      let arr = this.rawCitiesData2;
      for(let i = 0; i < arr.length; i++) {
        if(arr[i].checked == false) {
          aa = false;
          return false;
        }
      }
      return aa;
    },
    area2() {
      if(this.clickedProvince){
        return this.rawCitiesData2.filter(item => {
          return item.id === this.clickedProvince
        })
      }else{
        return []
      } 
    },
    area3() {
      if (this.area2 && this.area2.length) {
        return this.area2[0].childs.filter(item => {
          
          return item.id === this.clickedCity
        })
      } else {
        return []
      }
    }
  },
  methods: {
    initArea() {
      this.rawCitiesData2.forEach(item1 => {
        item1.show = false;
        item1.checked = false;
        item1.showProvince = false;
        item1.childs.forEach(item2 => {
          item2.show = false;
          item2.checked = false;
          item2.showCity = false;
          item2.childs.forEach(item3 => {
            item3.checked = false;
            item2.show = false;
            if (item3.checked) {
              item2.show = true;
              item1.show = true;
            }
          })
        })
      })
    },
    achieve() {
      axiosApi('/foundation/fare/templateSelect', {
        id: this.$route.query.id
      }, res => {

        this.gettedItem = res.data.data ? res.data.data : [];
        this.gettedItem = this.resolveValueToText(this.gettedItem);

        this.searchForm.name = this.gettedItem.name;
        this.searchForm.businessType = this.gettedItem.businessType||'';
        this.searchForm.isDefault = this.gettedItem.isDefault;
      })
    },
    addNewRow() {
      if(this.gettedItem.length == 0) {
        this.gettedItem = {
          name: '',
          fareDeliveryModeList: []
        }
      } else {
        this.gettedItem.fareDeliveryModeList.push({
          region: "",
          firstPrice: '',
          cumulationPrice: ''
        })
      }
    },
    editRow(index2, item2) {
      this.initArea();
      this.areaDialog = true;
      this.clickedIndex = index2;
      if (item2.region == '000000') {
        rawCitiesData2.map(item1 => {
          item1.show = false;
          item1.checked = true;
          item1.showProvince = true;
          item1.childs.map(item2 => {
            item2.show = false;
            item2.checked = true;
            item2.showCity = true;
            item2.childs.map(item3 => {
              item3.checked = true;
            })
          })
        })
      } else {
        let arr = item2.region.split(',')

        this.rawCitiesData2.map(item1 => {
          if(arr.indexOf(item1.id) > -1){
            item1.show = false;
            item1.checked = true;
            item1.showProvince = true;
            item1.childs.map(item2 => {
              item2.show = false;
              item2.checked = true;
              item2.showCity = true;
              item2.childs.map(item3 => {
                item3.checked = true;
              })
            })
          } else {
            item1.childs.map(item2 => {
              if(arr.indexOf(item2.id) > -1){
                item1.show=false;
                item1.checked = false;
                item1.showProvince = true;
                
                item2.show = false;
                item2.checked = true;
                item2.showCity = true;
                item2.childs.map(item3 => {
                  item3.checked = true;
                })
              } else {
                item2.childs.map(item3 => {
                  if(arr.indexOf(item3.id) > -1){
                    item3.checked = true;
                    item2.showCity = true;
                    item1.showProvince = true;
                  }
                  
                })
              }
            })
          }
        })
      }
    },
    ensureArea() {
      let regionArr = [];
      if(this.allCountry) {
        regionArr.push('000000')
      } else {
        this.rawCitiesData2.map(item1 => {
          if (item1.checked) {
            regionArr.push(item1.id)
            return;
          } else {
            item1.childs.map(item2 => {
              if (item2.checked) {
                regionArr.push(item2.id)
                return false;  
              } else {
                item2.childs.map(item3 => {
                  if (item3.checked) {
                    regionArr.push(item3.id)
                  }
                })
              }
            })
          }
        })
      }
      //把数据展示到页面上
        console.log(regionArr);
        this.gettedItem.fareDeliveryModeList[this.clickedIndex].region = regionArr.join(',')
      //重新得到regionText前要重置所有的regionText
      this.gettedItem.fareDeliveryModeList.map(item => {
        item.regionText = ''
      })
      this.gettedItem = this.resolveValueToText(this.gettedItem);
      this.areaDialog = false;
    },
    deleteRow(index2, item2) {
      if ( item2.region == '' && item2.firstPrice == '' && item2.cumulationPrice == '' ) {
        this.gettedItem.fareDeliveryModeList.splice(index2, 1)
      } else {
        this.$confirm("将删除这条地区和价格配置, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
          .then(() => {
            this.gettedItem.fareDeliveryModeList.splice(index2, 1)
            this.$message({
              type: "success",
              message: "删除成功!",
              duration: 1000
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
              duration: 1000
            });
          });
      }
    },
    changePro(val) {
      this.clickedProvince = val
    },
    changeCity(val) {
      this.clickedCity = val
    },
    changeAllCountry(event) {
       if (event) {
        this.rawCitiesData2.forEach(item1 => {
          item1.show = false;
          item1.checked = true;
          item1.showProvince = true;
          item1.childs.forEach(item2 => {
            item2.show = false;
            item2.checked = true;
            item2.showCity = true;
            item2.childs.forEach(item3 => {
              item3.checked = true;
            })
          })
        })
      } else {
        this.initArea()
      }
    },
    changeAll(event) {
      // 省
      if (event) {
        this.rawCitiesData2.map(item1 => {
          if (item1.id === this.clickedProvince) {
            item1.showProvince = true; //隐藏点击省

            item1.childs.map(item2 => {
              item2.showCity = true; //隐藏点击市

              item2.checked = true;
              item2.childs.map(item3 => {
                item3.checked = true;
              })
            })
          }
        })
      } else {
        this.rawCitiesData2.map(item1 => {
          if (item1.id === this.clickedProvince) {
            item1.showProvince = false; //隐藏点击省

            item1.childs.map(item2 => {
              item2.showCity = false; //隐藏点击市

              item2.checked = false;
              item2.childs.map(item3 => {
                item3.checked = false;
              })
            })
          }
        })
      }
    },
    handleCheckedCityChange(event) {
        console.log(event);
        // 市
      if (event) {
        //市区选中的个数
        let flres = this.area2[0].childs.filter(item => {
          return item.checked == true;
        })
        if (this.area2[0].childs.length == flres.length) {
          this.area2[0].checked = true;
        }
        this.area2[0].showProvince = true; //隐藏点击省
        this.area3[0].showCity = true; //隐藏点击市

        this.area2[0].childs.map(item1 => {
          if (item1.id === this.clickedCity) {
            item1.childs.map(item2 => {
              item2.checked = true;
            })
          }
        })
      } else {
        
        this.area3[0].showCity = false; //隐藏点击市
        let flres = this.area2[0].childs.filter(item => {
          return item.checked == true;
        })
        if (flres.length == 0) {
          this.area2[0].showProvince = false; //隐藏点击省
        } else {
          this.area2[0].showProvince = true; //隐藏点击省
        }


        this.area2[0].checked = false;
        this.area2[0].childs.map(item1 => {
          if (item1.id === this.clickedCity) {
            item1.childs.map(item2 => {
              item2.checked = false;
            })
          }
        })
      }
    },
    handleCheckedAreaChange(event) {
      // 区
      if (event) {
        this.area2[0].showProvince = true; //隐藏点击省
        this.area3[0].showCity = true; //隐藏点击市
        
        //区选中的个数
        let flres2 = this.area3[0].childs.filter(item => {
          return item.checked == true;
        })
        if (this.area3[0].childs.length == flres2.length) {
          this.area3[0].checked = true;
        }
        

        //市区选中的个数
        let flres = this.area2[0].childs.filter(item => {
          return item.checked == true;
        })
        if (this.area2[0].childs.length == flres.length) {
          this.area2[0].checked = true;
        }
      } else {
        this.area2[0].checked = false;//省
        this.area3[0].checked = false;//市
        
        //区选中的个数
        let flres2 = this.area3[0].childs.filter(item => {
          return item.checked == true;
        })
        if (flres2.length == 0) {
          this.area2[0].showProvince = false; //隐藏点击省
          this.area3[0].showCity = false; //隐藏点击市
        } else {
          this.area2[0].showProvince = true; //隐藏点击省
          this.area3[0].showCity = true; //隐藏点击市
        }
      }
    },
    cancel() {
     this.$router.go(-1);
    },
    // 校验默认
    templateValidate(newsubarr){
      var data = {
        id: this.$route.query.id,
        traderId: sessionStorage.traderId,
        isInclPostage: 0,
        isConditionalInclPostage: 0,
        name: this.searchForm.name,
        businessType: this.searchForm.businessType,
        isDefault: this.searchForm.isDefault,
        shopAddr: this.shopAddr,
        valuationModel: 1,
        dispatchDelay: "24小时",
        fareDeliveryModeList: newsubarr
      }
      templateValidate(data).then(res=>{
        if(res){
          this.$confirm(res, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.toSubmit(data)
          }).catch(() => {
            this.$message({
              type: 'info',
              message: '已取消'
            });          
          });
        }else{
          this.toSubmit(data)
        }
      })
    },
    toSubmit(data) {
      this.btnLoading = true;
      axiosApi('/foundation/fare/templateUpdate', data, res => {
        this.btnLoading = false;
        let message = this.$route.query.type == 2 ||  this.$route.query.type == 4 ? "新建成功！" : this.$route.query.type == 3 ? '编辑成功！' : '操作成功！'
        this.$message({
          type: "success",
          message: message,
          duration: 1000
        });
        setTimeout(() => {
          this.$router.go(-1);
        }, 1000)
      },1,10, err => {
        this.btnLoading = false;
        if (err.data.code === 'PVDCE001') {
          this.$message.error('该模版设置的的数据太多,超过上限啦！')
        } else {
          this.$message.error(err.data.message)
        }
      })
    },
   
    submit() {
      if (this.searchForm.name == '') {
        this.$message({
          type: 'warning',
          message: '请输入模版名称'
        })
        return false;
      } else if (/^[\u4e00-\u9fa5a-zA-Z0-9]{2,20}$/.test(this.searchForm.name) == false) {
        this.$message({
          type: 'warning',
          message: '请输入正确的模版名称'
        })
        return false;
      }
      let repeatData = this.gettedItem.fareDeliveryModeList;
      let that = this;
      for(let i = 0; i < repeatData.length; i++) {
        if (that.$route.query.type == 2 && this.allChecked == false && i == 0) {
          //不处理
        } else if (that.$route.query.type == 2 && this.allChecked && i == 0) { 
          //第一个新建的不判断region
          if(repeatData[i].firstPrice === '' || repeatData[i].cumulationPrice === '') {
            this.$message({
              type: 'warning',
              message: '模版信息填写不完整！'
            })
            return false;
          }
        } else if(repeatData[i].firstPrice === '' || repeatData[i].cumulationPrice === '' || repeatData[i].region === '') {
          this.$message({
            type: 'warning',
            message: '模版信息填写不完整！'
          })
          return false;
        }
      }
      
      let regionList = [];
      let newsubarr = [];
      //新建时候allChecked
      
      //type == 3编辑 || type == 4复制
      //type == 2 新建
      if (this.$route.query.type == 2) {
        if (this.allChecked == false) {
          newsubarr = this.gettedItem.fareDeliveryModeList.slice(1)
          newsubarr = newsubarr.map((v, index) => {
            regionList = regionList.concat(v.region.split(','))
            return {
              id: null,
              firstPrice: Number(v.firstPrice),
              firstQuantity: 1,
              cumulationPrice: Number(v.cumulationPrice),
              cumulationQuantity: 1,
              deliveryWay: '',
              region: v.region,
              isDefault: 0
            }
          })
        } else {
          newsubarr = this.gettedItem.fareDeliveryModeList.map((v, index) => {
            regionList = regionList.concat(v.region.split(','))
            return {
              id: null,
              firstPrice: Number(v.firstPrice),
              firstQuantity: 1,
              cumulationPrice: Number(v.cumulationPrice),
              cumulationQuantity: 1,
              deliveryWay: '',
              region: v.region,
              isDefault: 0
            }
          })
        }
      } else if (this.$route.query.type == 3) {
        newsubarr = this.gettedItem.fareDeliveryModeList.map((v, index) => {
          regionList = regionList.concat(v.region.split(','))
          return {
            id: v.id,
            firstPrice: Number(v.firstPrice),
            firstQuantity: 1,
            cumulationPrice: Number(v.cumulationPrice),
            cumulationQuantity: 1,
            deliveryWay: '',
            region: v.region,
            isDefault: 0
          }
        })
      } else if (this.$route.query.type == 4) {
          newsubarr = this.gettedItem.fareDeliveryModeList.map((v, index) => {
          regionList = regionList.concat(v.region.split(','))
          return {
            id: null,
            firstPrice: Number(v.firstPrice),
            firstQuantity: 1,
            cumulationPrice: Number(v.cumulationPrice),
            cumulationQuantity: 1,
            deliveryWay: '',
            region: v.region,
            isDefault: 0
          }
        })
      }

      if (newsubarr.length == 0) {
        this.$message({
          type: 'warning',
          message: '没有可提交的模板数据！'
        })
        return false;
      }

      if (regionList.length > 200) {
        this.$message({
          type: 'warning',
          message: '该模版设置的的数据太多,超过上限啦！'
        })
        return false;
      }
      const api = "/trader/findStoreInfo/select";
      const body = {traderId: sessionStorage.traderId};
      if (sessionStorage.shopAddr == undefined) {
        axiosApi(api, body, (res)=>{
          let nres = res.data.data.addressVO;
          this.shopAddr = nres.provinceName + nres.cityName + nres.regionName + nres.addr
          sessionStorage.setItem('shopAddr', this.shopAddr)
          this.templateValidate(newsubarr)
        })
      } else {
        this.shopAddr = sessionStorage.shopAddr
        this.templateValidate(newsubarr)
      }
      
    }
  },
  //进入加载
  mounted() {
    this.$nextTick(() => {
      if (this.$route.query.type == '2' || this.$route.query.type == '4') { //新建or复制
        let item = sessionStorage.getItem("CurFreight");
        if (item) {
          this.gettedItem = JSON.parse(item)
          this.gettedItem.fareDeliveryModeList.map(item => {
            item.regionText = ''
          })
          this.gettedItem = this.resolveValueToText(this.gettedItem)
          this.searchForm.name = this.gettedItem.name;
          this.searchForm.businessType = this.gettedItem.businessType||'';
          this.searchForm.isDefault = this.gettedItem.isDefault;
        }
      } else { //查看or编辑
        this.achieve()
      }
      this.area2 = this.rawCitiesData2.filter(item => {
        return item.id === this.clickedProvince
      })

    });
  }
};
</script>

<style lang="less">
#freightHandle {
  .main-first {
    .main-second {
      .el-card__header {
        .header-wrap {
          display: flex;
          & > div {
            &:nth-child(1) {
              flex: 1;
              font-weight: bold;
              color: #000;
            }
          }
        }
      }
      .el-card__body {
        padding: 0;
      }
      .width-200 {
        width: 200px;
        text-align: center;
      }
      .width-150 {
        width: 150px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .width-437 {
        width: 437px;
        box-sizing: border-box;
      }
      .repeat-ct-wrap {
        line-height: 34px;
        color: #666;
        .l-h-30 {
          line-height: 30px;
          margin-top: 5px;
          .display-flex {
            display: flex;
            align-items: center;
            justify-content: center;
            div:nth-child(1) {
              flex: 1;
            }
          }
        }
        .ct-l-ct {
          display: flex;
          padding: 5px 20px;
          border-bottom: 1px solid #ddd;
          &:nth-last-child(1) {
            border: 0;
          }
          & > div {
            &:nth-child(1) {
              flex: 1;
            }
          }
          input {
            text-align: center;
          }
        }
        .add-btn {
          padding: 5px 20px;
          .el-button {
            width: 200px;
          }
        }
      }
    }
  }

  .areas-div {
    width: 800px;
    background: #fff;
    padding: 10px;
    margin-top: 10px;
    position: relative;
    .el-checkbox__label {
      display:inline !important;
    }
    .slecct-all {
      position: absolute;
      left: 10px;
      bottom: 0;
      width: 145px;
      height: 39px;
      padding: 0 10px;
      line-height: 30px;
      border: 1px solid #ddd;
      border-top: 0;
      label {
        max-width: 121px;
      }
    }
    .area-div {
      width: 165px;
      height: 350px;
      overflow-y: auto;
      margin-right: 20px;
      border: 1px solid #ddd;
      &:nth-child(1) {
        height: 320px;
      }
      &:nth-last-child(1) {
        margin-right: 0;
        width: 210px;
      }
      .ct {
        cursor: pointer;
        padding: 0 10px;
        line-height: 30px;
        color: #666;
        label {
          max-width: 101px;
          overflow-x: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        &.active {
          color: #20a0ff;
        }
        .mt-8 {
          margin-top: 8px;
        }
      }
    }
  }

  .ft {
    text-align: right;
    padding: 30px 30px 0 30px;
  }
  .el-dialog__body {
    padding: 10px 0 90px;
  }
}
</style>