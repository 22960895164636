<template>
	<div id="saleStoreGoods" class=" pb-50">
		<div class="title-top bg-f">
			经销商商品管理
		</div>
		<div class="bg-f mt-5 pb-50">
			<el-form :inline="true" :model="formInlineData" label-width="90px" class="clearfix pl-20 pt-30 ">
				<div class="fl width-70p">
					<el-form-item label="商品编码">
						<el-input v-model.trim="formInlineData.skuNo"></el-input>
					</el-form-item>
					<el-form-item label="商品名称">
						<el-input v-model.trim="formInlineData.skuName"></el-input>
					</el-form-item>
				</div>
				<el-button type="info" class="fl ml-50" @click='querylist()'>搜索</el-button>
			</el-form>
			<el-table v-loading="Loading" :data="tableData" tooltip-effect="dark" class="ml-30 mr-30 mt-10 table-default" stripe>
				<el-table-column prop="1" label="商品名称" width="290">
					<template slot-scope="scope">
						<div class="fl ml-10">
							<img v-if='scope.row.fileUrl' :src="scope.row.fileUrl" />
							<img v-else src="../../assets/goods.jpg" />
						</div>
						<div class="fl w160 ml-10 mt-10">
							<div class="tal">商品编码:{{scope.row.skuNo}}</div>
							<div class="tal">{{scope.row.skuName}}</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column label="规格" width="75">
					<template slot-scope="scope">
						<div>1*{{scope.row.specInfoNum}}</div>
					</template>
				</el-table-column>
				<el-table-column label="成本价" width="90">
					<template slot-scope="scope">
						<div>{{scope.row.enterShopPrice}}</div>
					</template>
				</el-table-column>
				<el-table-column label="建议零售价" width="80">
					<template slot-scope="scope">
						<div>{{scope.row.msrp}}</div>			
					</template>
				</el-table-column>
				<el-table-column label="团购价" width="90">
					<template slot-scope="scope">
						<div>{{scope.row.minimumGorupbuyyingPrice}}</div>
					</template>
				</el-table-column>
				<el-table-column label="库存" width="90">
					<template slot-scope="scope">
						<div>{{scope.row.stockQuantity}}</div>
					</template>
				</el-table-column>
				<el-table-column label="操作">
					<template slot-scope="scope">
						<el-button v-if='showButton("PROXY",200013)' type="text" class='font-12' @click="handleJXS(scope.$index, tableData)">同步经销商</el-button>
						<span v-else>-</span>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination class="tar mr-30" :current-page="currentPage" v-if="total>0" :page-size='10' :total='total' layout="prev , pager, next, jumper" @current-change="handleCurrentChange">
				<!-- 分页组件 -->
			</el-pagination>
		</div>

	</div>
</template>

<script>
	import { axiosApi, basePath } from "../../api/api";
	export default {
		data() {
			var validateNum = (rule, value, callback) => {
				if(parseInt(this.form2.haveNum) + parseInt(value) < 0 || parseInt(this.form2.haveNum) + parseInt(value) > 2147483647) {
					callback(new Error('您的输入超出库存正常限定'));
				} else {
					callback();
				}
			};
			return {
				formInlineData: {
          sellerId: sessionStorage.getItem('traderId'),
          skuNo: null,
          skuName: null,
        },
        searchClassify: null,
        currentPage: 1,
        total: 0,
        Loading: false,
        tableData: []
			}
		},
		methods: {
			handleJXS(index, data) {
				axiosApi('/sales/skuProxy/web/insert', {
					skuNo: data[index].skuNo,
				},(res)=>{
					this.$notify({
						type: 'success',
						message: '同步经销商成功'
					})
					this.querylist(1)
				},undefined, undefined, ()=>{
					this.$notify({
						type: 'warning',
						message: '同步经销商失败'
					})
				})
			},
      querylist(x) {
        this.Loading = true
        axiosApi('/sales/skuPage/web/selectList',this.formInlineData,(res)=>{
          this.Loading = false
          this.tableData = res.data.data.elements
					this.total = res.data.data.totalCount
        },x?x:this.currentPage,10)
      },
			handleCurrentChange(val) {
				this.currentPage = val
				this.querylist()
			},
      classSearch() {
				const api = "/goods/classifyTopList/select";
				const body = {
					classificationId: ''
				}
				axiosApi(api, body, this.classsearch)
			},
      classsearch(res) {
				let data = res.data.data;
				this.searchClassify = data;
			},
		}, //进入加载
		activated() {
			this.$nextTick(function() {
				// this.classSearch()
        this.querylist()
			})
		}
	}
</script>

<style lang="less">
	#saleStoreGoods {
		.el-form-item__label {
			font-size: 12px;
			width: 160px;
		}
		.w160 {
			width: 160px;
		}
		.el-tabs__header {
			padding-left: 0;
		}
		.edit-btn {
			margin-right: 0;
			margin-left: 0;
		}
		.el-form-item__error {
			width: 240px;
		}
		.el-dialog {
			min-height: 450px;
		}
		.hint {
			color: red;
			position: absolute;
			right: 7%;
			top: 53%;
		}
		.table-default {
			.el-button {
				width: 90px;
			}
		}
	}
</style>