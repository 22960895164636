<template>
	<div id="E_card" class="">
		<div class="title-top bg-f">
		 名片模板设置
		</div>

<div class="main bg-f mt-5 pb-50 pt-30 pl-50" style="padding-bottom: 200px;">
	       <!-- 电子名片实时渲染区域 -->
		   <div class="bankcard bd cursor mr-20 mb-20" :style="form.imgUrl!=null?'backgroundImage: url(' + form.imgUrl+')':'backgroundImage: url('+defaultImageURL + ')'">
				
                 <img class="pic" :src="SyLOGO_url"  alt="">
				 <div class="right">
				    <div  style="font-size: 16px;font-weight: 400;color: black;line-height: 23.17px;">员工姓名<img src="/src/assets/E_card/plusv.png" style="width: 13px;height: 13px;"></div>
					<div>部门XXX<span style="font-size:10px;font-weight: 700;">/岗位XXX</span>   </div>
				</div>

				<div class="pl-30 pr-30 font-12 color-3">
					<div class="left">
						<div class="companyform">
							<p class="ellipsis-2">
								{{ cardlist.company }}
							</p>
						</div>
						<div class="addr">
							<p >
							地址：{{cardlist.provinceName}}{{cardlist.cityName}}{{ cardlist.regionName}}{{cardlist.streetName}}{{cardlist.addr }}
						    </p>
						</div>
						<div>
							电话：XXXXXXXXXXX
						</div>
					</div>			
				</div>
				<img v-show="ishow" class="logo" :src="form.imgUrl2" alt="">
			</div>
			

			
		    <div class="mt-30">
						<div>
							<label>请上传名片背景图：</label>
							<span class="color-9">(宽355PX*高200PX,格式为PNG、JPG、JPEG、GIF)</span>
						</div>
						<div class="mt-20">
							<el-upload 
							action="/sunrise-gateway/oss/ossUpload?bucket=sunrise-official" 
							v-loading="loading"
							:class="form.imgUrl?'fileList2 avatar-uploader':'avatar-uploader'"
							list-type="picture-card"
							:file-list="cardlist.fileList"
							:limit="1"
							:on-preview="handlePictureCardPreview"
							:on-change="handleChange"
							:on-success="handleAvatarSuccess"
							:on-remove="handleRemove"
							:before-upload="beforeAvatarUpload1"
							>
								<i class="el-icon-plus"></i>
								
							</el-upload>
							<el-dialog :visible.sync="dialogVisible" size="tiny">
                                <img width="100%" :src="dialogImageUrl" alt="">
                            </el-dialog>

							
						</div>
			</div>


			<div class="mt-30">
						<div>
							<label>请上传公司LOGO：</label>
							<span class="color-9">(宽36PX*高90PX,格式为PNG、JPG、JPEG)</span>
						</div>
						<div class="mt-20" >
							<el-upload 
							action="/sunrise-gateway/oss/ossUpload?bucket=sunrise-official" 
							v-loading="loading2"
							:class="form.imgUrl2?'fileList2 avatar-uploader':'avatar-uploader'"
							list-type="picture-card" 
							:file-list="cardlist.fileList2"
							:limit="1"
							:on-preview="handlePictureCardPreview2"
							:on-change="handleChange"
							:on-success="handleAvatarSuccess2"
							:on-remove="handleRemove2"
							:before-upload="beforeAvatarUpload2"
							>
								<i class="el-icon-plus"></i>
							
							</el-upload>
							<el-dialog :visible.sync="dialogVisible2" size="tiny">
                                <img width="100%" :src="dialogImageUrl2" alt="">
                            </el-dialog>

						</div>
			</div>
			

			<el-button type="danger" class="ml-45 mt-50" @click="upload">提交</el-button>
</div>

	</div>


</template>

<script>
    
	import { axiosApi, basePath,basePath1 } from "../../api/api"
	export default {
		data() {
			return {

				SyLOGO_url:'https://sunrise-official.oss-cn-hangzhou.aliyuncs.com/template/ecard_sunrie_default_logo.png',
        ishow:false,
        cardlist: {
           company:"",
				   provinceName: "",
           cityName: "",
				   regionName: "",
				   streetName: "",
				   addr: "",
				   id:"",
				   fileList: [], //文件列表1   
				   fileList2: [], //文件列表2     
        },
				form: {
					imgUrl: null,
					imgurl2:null,
			  },
                
				defaultImageURL:'https://sunrise-official.oss-cn-hangzhou.aliyuncs.com/template/ecard_sunrise_default_background.png',
				dialogVisible: false,
				loading: false, //上传图片load状态
				loading2: false, //上传图片load状态
				curPage:1,
				dialogImageUrl: '', // 用于存储要预览的图片的URL
				dialogImageUrl2: '', // 用于存储要预览的图片的URL
				dialogVisible: false, // 控制预览图片的对话框显示与隐藏
				dialogVisible2: false, // 控制预览图片的对话框显示与隐藏
			}
		},
		methods: {
			//查询
			querycompany(){
				this.loading = true;
				const api = "/official/ecard/selectByTraderId";
				const body = {
					traderId: sessionStorage.traderId,
				};
			
				axiosApi(api, body,res => {
					this.cardlist.company=res.data.data.storeName;
					this.cardlist.provinceName=res.data.data.provinceName;
					this.cardlist.cityName=res.data.data.cityName;
					this.cardlist.regionName=res.data.data.regionName;
					this.cardlist.streetName=res.data.data.streetName;
					this.cardlist.addr=res.data.data.addr;
					this.cardlist.id=res.data.data.id;


					// 判断是否背景为空
					if (res.data.data.cardBackGround) {
						// this.cardlist.BACKImageUrl = res.data.data.cardBackGround; // 使用后端传递的真实 背景URL
						this.cardlist.fileList=[{
								url:res.data.data.cardBackGround
						}]
						// 给上传form赋值
						this.form.imgUrl = res.data.data.cardBackGround; // 使用后端传递的真实logo URL
					} else {
						this.cardlist.fileList =[]
					}

					// 判断LOGO是否为空
					if (res.data.data.cardLogo) {
						// this.cardlist.logoimg = res.data.data.cardLogo; // 使用后端传递的真实logo URL
						this.cardlist.fileList2=[{
							url: res.data.data.cardLogo
						}]
						// 给上传form赋值
						this.form.imgUrl2 = res.data.data.cardLogo; // 使用后端传递的真实logo URL
						this.ishow=true;
					} else {
						this.cardlist.fileList2 =[]	
						this.ishow=false; // 不显示LOGO
					}

				
					this.loading = false;

				},this.curPage,10,err => {
					this.$message.warning(err.data.message)
				})
			},
			handlePictureCardPreview2(file) {
			this.dialogImageUrl2 = file.url;
			this.dialogVisible2 = true;
			},
			beforeAvatarUpload2(file) {
			this.loading2 = true;
			const isImage =
				file.type.indexOf("jpg") != -1 ||
				file.type.indexOf("jpeg") != -1 ||
				file.type.indexOf("png") != -1 ||
				file.type.indexOf("gif") != -1;
			const isLt4M = file.size / 1024 / 1024 < 4 && file.size / 1024 / 1024 > 0;
			if (!isImage) {
				
				this.loading2 = false;
				this.$message.error("上传图片只能是jpg,jpeg,png,gif格式");
			}
			if (!isLt4M) {
			
				this.loading2 = false;
				this.$message.error("图片大小不能大于4MB");
			}
			return isImage && isLt4M;
			},
			handleAvatarSuccess2(res) {
			this.loading2 = false;
			this.form.imgUrl2 = res.data[0];
			},
			handleRemove2(res) {
			this.form.imgUrl2 = null;
			},



			handlePictureCardPreview(file) {
			this.dialogImageUrl = file.url;
			this.dialogVisible = true;
			},
			beforeAvatarUpload1(file) {
			this.loading = true;
			const isImage =
				file.type.indexOf("jpg") != -1 ||
				file.type.indexOf("jpeg") != -1 ||
				file.type.indexOf("png") != -1 
			const isLt4M = file.size / 1024 / 1024 < 4 && file.size / 1024 / 1024 > 0;
			if (!isImage) {
				this.loading = false;
				this.$message.error("上传图片只能是jpg,jpeg,png格式");
			}
			if (!isLt4M) {
				this.loading = false;
				this.$message.error("图片大小不能大于4MB");
			}
			return isImage && isLt4M;
			},
			handleAvatarSuccess(res) {
			this.loading = false;
			this.form.imgUrl = res.data[0];
			},
			handleRemove(res) {
			this.form.imgUrl = null;
			},
			handleChange() {
			
			},
			upload() {
				this.cardlist.fileList = [this.form.imgUrl].map(v => {
				return {
				url: v
				};});
			if (this.cardlist.fileList.length === 0) {
				this.$message({
					type: 'warning',
					message: '请先选择要上传的图片'
				});
				return;
			}

			this.$confirm('您确定要上传这些图片？', '提示信息', {
				confirmButtonText: '确定',
				type: 'warning'
			}).then(() => {
				const api = "/official/ecard/update";
				const body = {
					cardBackGround: this.form.imgUrl,
					cardLogo:this.form.imgUrl2,
					id:this.cardlist.id,
				};

				axiosApi(api, body, (res) => {
					this.isAdd = false;
					this.$message({
						type: 'success',
						message: '上传成功!',
						duration: 1000
					});
					this.querycompany();
					console.log(dialogImageUrl2,"dialogImageUrl2;");
				}, this.currentPage, 10, (res) => {
					this.$message({
						type: 'warning',
						message: res.data.message,
						duration: 1000
					});
				});
			}).catch(() => {
				this.$message({
					type: 'info',
					message: '已取消'
				});
			});
		}


		
		},
		activated() {
			this.$nextTick(() => {
			
			})
		},

		mounted(){
        this.$nextTick(() => {
						this.querycompany();
				})
    },
		computed: {

		}
	}
</script>

<style lang="less">


#E_card {
		.bankcard {
			border-radius: 6px;
			width: 355px;
			height: 200px;
            //background-image: url('https://sunrise-official.oss-cn-hangzhou.aliyuncs.com/template/ecard_sunrise_default_background.png');
            /* 背景图垂直、水平均居中 */
			background-position: center center;
			/* 让背景图基于容器大小伸缩 */
			background-size: cover;
			

			.left{
			width: 135px;
			position: relative;
			right: 10px;
			bottom:25px;
			font-size: 9px;
			font-weight: 400;
			letter-spacing: 0px;
			line-height: 13.03px;
			color: rgba(125, 120, 120, 1);
			text-align: left;
			vertical-align: top;
			}

            .companyform{
                
			    width: 132px;
				margin-bottom: 5px;
				font-size: 12px;
				line-height: 17.38px;
				color: rgb(0, 0, 0);

			}
			.addr{
				display: -webkit-box !important;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: normal !important;
				word-wrap: break-word;
				-webkit-flex-shrink: inherit !important;
				//-ms-flex: auto !important;
				-webkit-flex-shrink: inherit !important;
				flex-shrink: inherit !important;
				-webkit-line-clamp: 3;
				/* autoprefixer: off*/
				-webkit-box-orient: vertical; 
				/* autoprefixer: on*/

			}
		



			.right{
             position: relative;
			 left: 220px;
		
			 align-content: center;

			  font-size: 10px;
			  font-weight: 400;
		      color: rgba(110, 106, 106, 1);
		     


			}

			.pic{
				padding-left: 20px;
				padding-top: 20px;
				width: 120px;
			    height: 40px;


			}
			.logo{
				padding: 10px;
				width: 90px;
			    height: 36px;
				position: relative;
				left: 200px;
				bottom: 40px;
				object-fit: contain;
			}

			.custom-upload-item {
				width: 120px; /* 设置图片框的宽度 */
				height: 120px; /* 设置图片框的高度 */
			}
			
				.avatar-uploader {
				margin-left: 190px;
				width: 90px ;
				height: 90px ;
				border: 1px solid #cccccc;
				overflow: hidden;
				}
				.el-upload-list__item {
				width: 90px ;
				height: 90px ;
				}
				.el-upload--text {
				width: 90px ;
				height: 90px ;
				border: 1px solid #ccc;
				}
				.fileList2 .el-upload--picture-card {
				display: none;
				}
				.el-upload--picture-card {
				width: 90px ;
				height: 90px ;
				line-height: 90px ;
				border: none ;
				}
		



			.h50 {
				padding-top: 24px;
				width: 270px;
				margin-left: -1px;
				height: 27px;
				background-color: #20a0ff;
				border-radius: 6px 6px 0 0;
			}
			.unbind {
				display: none;
			}
			&:hover {
				.unbind {
					display: block;
				}
			}
		}

            .noAdd {
			.el-upload.el-upload--picture-card {
				display: none;
			}
		    }
		   .add {
			.el-upload.el-upload--picture-card {
				display: inline-block;
			}
		   }
		   


	}


	
		
	
</style>
