<template>
	<div class="bg-f" id="public">
		<div class="p45">
			<router-view></router-view>
		</div>
	</div>
</template>

<script>
	export default {
		mounted() {
			this.$nextTick(() => {
				this.changeHeight()
			})
		},
		methods: {
			changeHeight() {
				document.getElementById("public").style.minHeight = document.documentElement.clientHeight - 187 + "px";
			}
		}
	}
</script>

<style lang="less">
	#public {
		.p45{
			padding: 0 45px;
		}
		padding-bottom: 150px;
	}
</style>