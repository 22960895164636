<template>
	<div id="pay-cert-check" class="bg-f" v-loading="loading">
		<top></top>
		<div class="pay-order-content">
			<div class="mt-30">
				<div class="mb-20">
					<div class="inline-block w-255 tar">订单编号：</div><span class="inline-block w-255">{{remittanceApply.orderSn}}</span>
				</div>
				<div class="mb-20">
					<div class="inline-block w-255 tar">收款账户名称：</div><span class="inline-block w-255">{{remittanceApply.bankUserName}}</span>
				</div>
				<div class="mb-20">
					<div class="inline-block w-255 tar">付款金额：</div><span class="inline-block w-255">￥{{remittanceApply.amount | fmoney}}</span>
				</div>
				<div class="mb-20">
					<div class="inline-block w-255 tar">收款银行：</div><span class="inline-block">{{remittanceApply.incomeAccountName}}</span>
				</div>
				<div class="mb-20">
					<div class="inline-block w-255 tar">收款银行账号：</div><span class="inline-block">{{remittanceApply.incomeAccountNum}}</span>
				</div>

				<div class="mt-20 clearfix">
					<div class="inline-block w-255 tar">
						请选择汇款信息:
					</div>
					<el-form class="inline-block">
						<el-form-item>
							<el-select class="inline-block" v-model='selectBankItem' placeholder='请选择汇款银行'>
								<el-option v-for="(item, index) in bankItems" :key="index" :label='item.bankName' :value='item.id'></el-option>
							</el-select>
						</el-form-item>
					</el-form>
					<div v-if="bankItem.length!=0">
						<div class="mb-20">
							<div class="inline-block w-255 tar">持卡人：</div><span class="inline-block">{{bankItem[0].bankUserName}}</span>
						</div>
						<div class="mb-20">
							<div class="inline-block w-255 tar">收款名称：</div><span class="inline-block">{{bankItem[0].bankDeposit}}</span>
						</div>
						<div class="mb-20">
							<div class="inline-block w-255 tar">收款账号：</div><span class="inline-block">{{bankItem[0].bankCard}}</span>
						</div>
					</div>
				</div>

				<div class="clearfix mb-50">
					<div class="inline-block w-255 tar fl">付款凭证：</div>
					<el-upload class="fl" action="/sunrise-gateway/oss/ossUpload" :file-list='certList' list-type="picture-card" :on-preview="handlePictureCardPreview">
					</el-upload>
					<el-dialog :visible.sync="dialogVisible" size="tiny">
						<img width="100%" :src="dialogImageUrl" alt="certList">
					</el-dialog>
				</div>
				<div class="pl-254 pb-232">
					<el-button type="danger" @click="auditPassCheck(3)">通 过</el-button>
					<el-button @click="auditPassCheck(2)">驳 回</el-button>
				</div>
				<!--审核未通过弹出框-->
				<el-dialog class='auditFailDialog' title='审核未通过理由' :visible.sync='auditFailReasonVisible'>
					<el-form class="pl-30 pr-30" :model="form" ref="auditFailReasonForm" :rules="rules">
						<el-form-item label="" prop='auditFailReason'>
							<el-input type='textarea' placeholder='请输入审核未通过理由...' :autosize="{ minRows: 3 ,maxRows: 5}" v-model.trim='form.auditFailReason'></el-input>
						</el-form-item>
					</el-form>
					<div slot='footer' class="dialog-footer">
						<el-button type='danger' @click="doAudit(2)">提 交</el-button>
						<el-button @click="auditFailReasonVisible = false">取 消</el-button>
					</div>
				</el-dialog>

			</div>

		</div>
	</div>
</template>

<script>
	import { axiosApi, basePath } from "../../api/api";
	export default {
		data() {
			var checkbankInfo = (rule, value, callback) => {
				if(Object.keys(value).length <= 0) {
					return callback(new Error('请选择付款银行！'))
				} else {
					callback()
				}
			}
			return {
				certList: [{
					url: '/assets/1fc7f8d9fd87855eaa883fec4c00c005.png'
				}],
				dialogVisible: false,
				dialogImageUrl: null,
				orderSn: null,
				remittanceApplys: null,
				remittanceApply: {
					orderSn: null
				},
				bankItems: [],
				selectBankItem: null,
				auditState: null,
				traderBankId: null,
				form: {
					auditFailReason: null,
				},
				auditFailReasonVisible: false,
				rules: {
					auditFailReason: [{
						required: true,
						max: 30,
						message: '不能超过30个字！'
					}]
				},
				loading: false,
			}
		},
		computed: {
			bankItem () {
				return this.bankItems.filter((item)=>{
			 		return  item.id == this.selectBankItem
			 	})
			}
		},
		mounted() {
			this.orderSn = this.$route.query.sign
			this.$nextTick(() => {
				this.queryRemittanceByOrderId()
				this.queryTraderBanks()
			})
		},
		methods: {
			queryTraderBanks() {
				this.loading = true
				let body = {
					//					traderId: sessionStorage.traderId
					traderId: sessionStorage.mySellerId
				}
				//(接口已改)
				axiosApi('/trader/findSellerBankInfoList/select', body, (res) => {
					this.bankItems = res.data.data
					this.selectBankItem = res.data.data[0].id//bankname修改为id,因为id是唯一标识符
				}, 1, 10, (res) => {

				})
			},
			handlePictureCardPreview(file) {
				this.dialogImageUrl = file.url;
				this.dialogVisible = true;
			},
			queryRemittanceByOrderId() {
				this.loading = true
				let body = {
					orderSn: this.orderSn
				}
				axiosApi('/sales/orderRemittance/web/selectList', body, (res) => {
					this.loading = false
					this.remittanceApplys = res.data.data
					//拉去信息
					this.remittanceApplys.forEach((item, index) => {
						if(item.auditState == 1) {
							this.remittanceApply = item
							let urls = JSON.parse(item.urls)
							this.certList[0].url = urls[0]
							return
						} else {

						}
					})

				}, this.currentPage, 10, (res) => {
					this.loading = false
					this.$message({
						type: 'error',
						message: res.data.message,
						duration: 1000
					})
				})
			},
			auditPassCheck(flag) {
				//审核通过
				if(3 == flag) {
					if(this.selectBankItem == undefined) {
						this.$message({
							type: 'error',
							message: "请先确认并选择打款账户！",
							duration: 1000
						})
						return
					}
					const h = this.$createElement
					this.$msgbox({
						title: '重要提示',
						message: h('p', {
							style: 'text-align:center'
						}, '请确认该支付凭证为有效凭证？ '),
						showCancelButton: true,
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						beforeClose: (action, instance, done) => {
							if(action === 'confirm') {
								this.doAudit(3)
								done()
							} else {
								done()
							}
							delete instance.$slots.default;
						}
					})
				} else { //审核未通过
					this.auditFailReasonVisible = true
				}
			},
			auditFailCommit() {

			},
			doAudit(auditState) {
				let body = {
					remittanceApplyId: this.remittanceApply.id,
					auditState: auditState,
					sellerId: sessionStorage.mySellerId
				}
				//审核通过
				if(3 == auditState) {
					// body.traderBankId = this.selectBankItem.id
					// selectBankItem直接就是v-model的id值
					body.traderBankId = this.selectBankItem 
					this.loading = true
					axiosApi('/sales/orderRemittance/web/audit', body, (res) => {
						this.loading = false
						this.$message({
							type: 'success',
							message: "操作成功",
							duration: 1000
						})

						if(sessionStorage.orderType == 'dispatch') {
							this.$router.push(basePath + '/dispatch')
						} else if(sessionStorage.orderType == 'indent') {
							this.$router.push(basePath + '/indent')
						} else if (sessionStorage.orderType.indexOf('visitedDetail') >= 0 ) {
							this.$router.replace(basePath + '/' + sessionStorage.orderType)
						} 
					}, this.currentPage, 10, (res) => {
						this.loading = false
						this.$message({
							type: 'error',
							message: res.data.message,
							duration: 1000
						})
					})
				} else { //审核未通过
					body.traderBankId = null
					setTimeout(() => {
						this.$refs.auditFailReasonForm.validate((valid) => {
							if(valid) {
								body.auditReason = this.form.auditFailReason
								this.loading = true
								axiosApi('/sales/orderRemittance/web/audit', body, (res) => {
									this.loading = false
									this.$message({
										type: 'success',
										message: "操作成功",
										duration: 1000
									})
									if(sessionStorage.orderType == 'dispatch') {
										this.$router.push(basePath + '/dispatch')
									} else if(sessionStorage.orderType == 'indent') {
										this.$router.push(basePath + '/indent')
									} else if (sessionStorage.orderType.indexOf('visitedDetail') >= 0 ) {
										this.$router.replace(basePath + '/' + sessionStorage.orderType)
									}
									
								}, this.currentPage, 10, (res) => {
									this.loading = false
									this.$message({
										type: 'error',
										message: res.data.message,
										duration: 1000
									})
								})
							} else {
								return false
							}
						})
					}, 0)

				}
			}
		}
	}
</script>

<style lang="less">
	#pay-cert-check {
		.ml-95 {
			margin-left: 95px;
		}
		.mr-95 {
			margin-right: 95px;
		}
		.auditFailDialog {
			.el-dialog__body {
				padding-top: 20px;
				padding-bottom: 100px;
			}
			.el-dialog {
				min-height: 100px;
			}
			.el-textarea {
				.el-textarea__inner {
					border-radius: 0;
				}
			}
		}
		.w-255 {
			width: 255px;
		}
		.w-100 {
			width: 100px;
		}
		.pl-254 {
			padding-left: 254px;
		}
		.pb-232 {
			padding-bottom: 232px;
		}
		.pay-order-content {
			width: 1280px;
			margin: 0 auto;
			padding: 0 45px;
			box-sizing: border-box;
			.logo1 {
				height: 102px;
				color: #FF4201;
				display: flex;
				align-items: center;
				justify-content: space-between;
				border-bottom: 2px solid #EEEEEE;
				box-sizing: border-box;
				.logo2 {
					display: flex;
					align-items: center;
					img {
						height: 30px;
					}
					.br3 {
						height: 30px;
						border-left: 1px solid #333333;
						margin: 0 15px;
					}
				}
			}
			.el-upload-list--picture-card .el-upload-list__item-status-label {
				display: none;
			}
			.el-icon-delete2 {
				display: none;
			}
			.el-icon-view {
				margin-left: 20px;
			}
			.el-upload--picture-card {
				display: none;
			}
			.el-upload-list--picture-card .el-upload-list__item {
				width: 150px;
				height: 150px;
			}
		}
	}
</style>