<template>
	<div class="bill-management">
		<div class="title-top bg-f">账单管理</div>
		<div class="bg-f mt-5 pl-20 pt-30 pr-20 bill-content pb-50" :loading="loading">
			<p class="mb-50">
				<span>合计交易金额:</span><span class="ml-20 color-danger">￥{{responseData.totalAmount | fmoney}}</span>
				<span class="ml-30">待还款总金额:</span><span class="ml-20 color-danger">￥{{responseData.repaidAmount | fmoney}}</span>
			</p>

			<el-form :inline="true" label-width="60px" class="clearfix mb-30 search">
				<div class="fl width-70p">
					<el-form-item label="客户名称">
							<el-input v-model="form.fcustname"></el-input>
						</el-form-item>
						<el-form-item label="状态">
							<el-select v-model="form.fwrittenoffstatus" clearable>
								<el-option v-for="(item, index) in selectList" :label="item.label" :value="item.value" :key="index"></el-option>
							</el-select>
						</el-form-item>
					<el-form-item label="业务日期">
							<ys-daterange-picker v-model="form.timeRange"/>
						</el-form-item>
						<el-form-item label="是否到期">
							<el-select v-model="form.isExpired" clearable>
								<el-option v-for="(item, index) in selectList2" :label="item.label" :value="item.value" :key="index"></el-option>
							</el-select>
						</el-form-item>
				</div>
				<el-button type="info" class="fl ml-50" @click="search">查询</el-button>
			</el-form>

			<el-table :data="data" :row-class-name="tableRowClassName" stripe>
				<el-table-column label="账单编号" min-width="110px" prop="fbillno" align="center" fixed="left"></el-table-column>
				<el-table-column label="客户名称" prop="fcustname" min-width="110" align="center" show-overflow-tooltip>
				</el-table-column>
				<el-table-column label="业务日期" min-width="105px"  align="center">
					<template slot-scope="scope">
						{{scope.row.fdate | timenData}}
					</template>
				</el-table-column>
				<el-table-column label="到期日期" min-width="105px"  align="center">
					<template slot-scope="scope">
						{{scope.row.fenddate | timenData}}
					</template>
				</el-table-column>
				<el-table-column label="结算方式" prop="fpayconditonname" min-width="130" align="center">
				</el-table-column>
				<el-table-column label="应还款金额（含税）"  align="center" min-width="150px">
					<template slot-scope="scope">
						{{scope.row.fallamountfor | fmoney}}
					</template>
				</el-table-column>
				<el-table-column label="已还款金额"  align="center" min-width="120px">
					<template slot-scope="scope">
						{{scope.row.freceiveamount | fmoney}}
					</template>
				</el-table-column>
				<el-table-column label="状态" align="center" min-width="100px">
					<template slot-scope="scope">
						<span v-if="scope.row.fwrittenoffstatus === 'A'">未还款</span>
						<span v-if="scope.row.fwrittenoffstatus === 'C'">已还款</span>
						<span v-if="scope.row.fwrittenoffstatus === 'B'">部分还款</span>
						<!-- <span v-if="scope.row.fwrittenoffstatus === 'D'">已核销</span> -->
					</template>
				</el-table-column>
				<el-table-column label="是否到期" align="center" min-width="120px">
					<template slot-scope="scope">
						<span v-if="scope.row.isExpired === false">否</span>
						<span v-if="scope.row.isExpired === true">是</span>
					</template>
				</el-table-column>
				<el-table-column label="操作" fixed="right">
					<template slot-scope="scope">
						<el-button type="text" @click="billDetail(scope.row)" class="textBtn" v-if="scope.row.fwrittenoffstatus !== 'D'">详情</el-button>
						<el-button type="text" class="textBtn" v-else> —— </el-button>
					</template>
				</el-table-column>
			</el-table>
			<div class="tac mt-30 pb-30" v-if="data.length==0">
				<img src="../../assets/empty.png" alt="" />
				<div class="color-9 mt-30">
					<span>暂无记录</span>
				</div>
			</div>
			<!-- 分页组件 -->
			<el-pagination
				v-if="total>0"
				@current-change="handleCurrentChange"
				class="tar mt-20 mr-50"
				:current-page="currentPage"
				:page-size='10'
				:total='total' layout="prev , pager, next, jumper">
			</el-pagination>
		</div>
	</div>
</template>

<script>
	import { axiosApi, basePath } from "../../api/api"
	import YsDaterangePicker from "~/components/ys-daterange-picker";
	export default{
		components: {YsDaterangePicker},
		data(){
			return {
				selectList:[{value:"C", label:'已还款'}, {value:"B", label:'部分还款'}, {value:"A", label:'未还款'}],//状态
				selectList2:[{value:true, label:'是'}, {value:false, label:'否'} ], //是否到期
				data:[],
				form:{fcustname:null,fwrittenoffstatus:null,isExpired:null,fydatestart:null,fydateend:null,timeRange:[]},
				total:0,
				currentPage:1,
				responseData:{},//响应数据
				loading:false
			}
		},
		created(){ //初始化数据
			if (sessionStorage.getItem('bill')) {
				let bill = JSON.parse(sessionStorage.getItem('bill'))
				this.currentPage = bill.page
				delete bill.page
				this.form = Object.assign({},bill)
				setTimeout(()=>{
	    			sessionStorage.removeItem('bill')
	    		},500)
			}
		},
		mounted(){
			this.init()
		},
		beforeRouteLeave (to, form, next){
			// 导航离开该组件的对应路由时调用
    		// 可以访问组件实例 `this`
    		if (to.path === basePath+ '/billDetail') { //跳转详情
    			sessionStorage.setItem('bill', JSON.stringify(Object.assign(this.form, {page: this.currentPage})))
    		}else { //其他页面
    			if (sessionStorage.getItem('bill')) {
    				sessionStorage.removeItem('bill')
    			}
    		}
    		next()
		},
		methods:{
			search(){
				if (this.currentPage == 1) {
					this.init()
				}else{
					this.currentPage = 1
				}
			},
			init(){
				let body ={}
				this.form.fydatestart = this.form.timeRange[0]? + new Date(this.form.timeRange[0]):null
				this.form.fydateend = this.form.timeRange[1]? + new Date(this.form.timeRange[1]):null
				body = Object.assign({},this.form)
				delete body.timeRange
				body.isBuyer = 0 //供应商
				this.getBillList(body)
					.then(res => {
						this.responseData = res.data.data;
						({elements:this.data,totalCount:this.total} = this.responseData.billReceivablesPoPage)
					})
					.catch(err => {
						
					})
			},
			//1.5用户应收收款账单
			getBillList(body){
				this.loading = true
				return new Promise((resolve, reject) => {
					axiosApi('/order/billReceivables/list',body,(res)=>{
						this.loading = false
						resolve(res)
					},this.currentPage,10,(err)=>{
						this.loading = false
						reject(err)
					})
				})
			},
			tableRowClassName(row, index){ //标记背景色
	            if(row.fwrittenoffstatus === 'A' && row.isExpired === true){
	                return 'tag-row'
	            }
	            return ''
	        },
	        handleCurrentChange(v){
	            this.currentPage = v
	            this.init()        
	       	},
	       	billDetail(row){ //详情
	       		//fid和fysorderid
	       		this.$router.push({path: basePath + '/billDetail',query:{
	       			fid: row.fid, 
	       			fysorderid: row.fysorderid,
	       			fwrittenoffstatus: row.fwrittenoffstatus
	       		}})
	       	}
		}
	}
</script>

<style lang="less">
	.bill-management{
		.bill-content{
            min-height: 661px;
        }
        .el-table__empty-block{
        	display: none;
        }
        .el-table .tag-row .cell{
            color: #ff4201;
        }
        .textBtn{
        	width: 28px;
        }
	}
</style>