<template>
	<div id="storeMap" class="bg-f">
        <div class="hearder clearfix">
            <div class="search clearfix">
                <el-form :inline='true' class="" :model="searchForm" ref="searchForm">
                    <el-form-item label='门店名称' class='mr-20'>
                        <el-input v-model.trim="searchForm.storeName" placeholder="请输入门店名称" :maxlength="20"></el-input>
                    </el-form-item>
                    <el-form-item label='门店类型' class='mr-20'>
                        <el-select v-model="searchForm.storeTypes" multiple placeholder="请选择门店类型" clearable>
                            <el-option label="门店" value="B"></el-option>
                            <el-option label="久柜" value="A"></el-option>
                            <el-option label="店中店" value="C"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label='所属公司' v-if="showLabel" class='mr-20'>
                        <el-input v-model.trim="searchForm.sellerName" :maxlength="30" placeholder="请输入所属公司"></el-input>
                    </el-form-item>
                    <el-form-item label='门店编码' :class="showLabel?'mr-20':''">
                        <el-input v-model.trim="searchForm.storeNo" :maxlength="30" placeholder="请输入门店编码" @input="searchForm.storeNo=searchForm.storeNo.replace(/[\u4e00-\u9fa5]/ig,'')"></el-input>
                    </el-form-item>
                    <el-form-item label="所属区域">
                        <districtSelect v-model="searchForm.districtId" class="mr-10"/>
                    </el-form-item>
                </el-form>
                <el-button class=" " type='info' @click="search('search')" icon="el-icon-search">查 询</el-button>
            </div>
        </div>
        <!-- 地图 -->
        <div style="display:flex" class="mt-30">
            <div class="content">
                <div id="map"></div>
                <div class="search_map">
                    <input type="text" ref="pickerInput" id="pickerInput" placeholder="输入地址">
                    <img src="../../../assets/searchBtn1.png" alt="">
                </div>
                <p class="map_url">手机版地址：map.jiujiajiu.com</p>
            </div>
            <div class="width-30">
                <div style="background: #F5F7FA;">门店分布</div>
                <div><img src="../../../assets/jiujiajiu_icon3.png">门店:<span class="ml-10">{{postObj.storeCount}}</span></div>
                <div><img src="../../../assets/jiujiajiu_icon2.png">久柜:<span class="ml-10">{{postObj.gradevinCount}}</span></div>
                <div><img src="../../../assets/jiujiajiu_icon1.png">店中店:<span class="ml-10">{{postObj.inStoreCount}}</span></div>
            </div>
        </div>
	</div>
</template>
<script>
    var points = [
        {type: 1,lnglat:['119.295425','30.263841']},
        {type: 2,lnglat:['120.202916','30.263841']},
        {type: 3,lnglat:['120.205380','30.258689']},
        {type: 1,lnglat:['120.202896','30.263801']},
        {type: 3,lnglat:['119.949890','30.047695']},
        {type: 2,lnglat:['120.140900','30.335102']},
	];
    var map; //定义全局地图
    var circle;//矢量圆形
    var circleMarker;//圆点标记
    var markers = [];//点标记存储器
    var zoom
    var cityInfo = {}

	import { axiosApi, basePath } from "../../../api/api";
    import { setTimeout } from 'timers';
    import districtSelect from "@/components/districtSelect/districtSelect.vue";

    export default {
        components: {
			districtSelect,
		},
        data() {
            return {
                icon:{
                    mdUrl: require('../../../assets/md_icon.png'),
                    jgUrl: require('../../../assets/jg_icon.png'),
                    dzdUrl: require('../../../assets/dzd_icon.png'),
                    arrow: require('../../../assets/arrow.png'),
                },
                searchForm:{
                    sellerId: sessionStorage.getItem('traderId'),
                    sellerName: null,//所属公司
                    storeName: null,//门店名称
                    storeNo: null,//门店编码
                    storeTypes: [],//门店类型
                    provinceName: null,
                    cityName: null,
                    regionName: null,
                    districtId: null
                },
                postObj:{},//所有点标注信息
                storeList:[],//详细门店列表
                showLabel: false,//是否显示所属公司
            }
        },
        //进入加载
        mounted() {
            let that = this
            this.$nextTick(function(){
                if(sessionStorage&&sessionStorage.traderId==2548||sessionStorage.traderId==null){
                    that.showLabel = true
                }else{
                    that.showLabel = false
                }
                // 百度地图
                map = new BMap.Map("map",{
                    coordsType: 3 // coordsType指定输入输出的坐标类型，3为gcj02坐标，5为bd0ll坐标，默认为5。
                });
                // 鼠标缩放
                map.enableScrollWheelZoom(true);
                // 设置中心点
                map.centerAndZoom(new BMap.Point(120.169428, 30.344913), 14);
                // var geolocation = new BMap.Geolocation();
                // geolocation.getCurrentPosition(function(r){
                //     // 设置中心点
                //     map.centerAndZoom(new BMap.Point(r.longitude, r.latitude), 14);
                    

                // })
                map.addEventListener("zoomend", function(){    
                    // 缩放事件
                    that.setMarker(this.getZoom())
                });
                

                var ac = new BMap.Autocomplete(    //建立一个自动完成的对象
                    {"input" : "pickerInput"
                    ,"location" : map
                });
                var myValue;
                ac.addEventListener("onconfirm", function(e) {    //鼠标点击下拉列表后的事件
                    var _value = e.item.value;
                    myValue = _value.province +  _value.city +  _value.district +  _value.street +  _value.business;
                    setPlace();
                });

                function setPlace(){
                    function myFun(){
                        var pp = local.getResults().getPoi(0).point;    //获取第一个智能搜索的结果
                        map.centerAndZoom(pp, 18);
                        map.addOverlay(new BMap.Marker(pp));    //添加标注
                    }
                    var local = new BMap.LocalSearch(map, { //智能搜索
                    onSearchComplete: myFun
                    });
                    local.search(myValue);
                }

                that.search()
            })
        },
        methods: {
            setMarker(zoom,val){
                var that = this
                map.clearOverlays();
                if(0<zoom&&zoom<=4){//全国
                    that.postObj.countryLevel.forEach(item=>{
                        if(item.districtCenter){
                            var list =  item.districtCenter.split(',')
                            that.makePoint(list[0],list[1],{districtName:item.districtName,storeCount:item.storeCount},map)   
                        }
                    })
                }else if(4<zoom&&zoom<=8){//省
                   that.postObj.provinceLevel.forEach(item=>{
                        if(item.districtCenter){
                            var list =  item.districtCenter.split(',')
                            that.makePoint(list[0],list[1],{districtName:item.districtName,storeCount:item.storeCount},map)   
                        }
                    })
                }else if(8<zoom&&zoom<=12){//市
                    that.postObj.cityLevel.forEach(item=>{
                        if(item.districtCenter){
                            var list =  item.districtCenter.split(',')
                            that.makePoint(list[0],list[1],{districtName:item.districtName,storeCount:item.storeCount},map)   
                        }
                    })

                }else{
                    that.storeList.forEach(item=>{
                        let imgSrc = null;
                        if(item.storeType=='B'){
                            imgSrc= that.icon.mdUrl
                        }else if(item.storeType=='A'){
                            imgSrc= that.icon.jgUrl
                        }else if(item.storeType=='C'){
                            imgSrc= that.icon.dzdUrl
                        }
                        that.makePoint(item.lnglat[0],item.lnglat[1],{storeName:item.storeName},map,imgSrc,item.storeType)    
                    })
                }
            },
            handleCheck(){
                return new Promise((resolve,reject)=>{
                    axiosApi("/unlogin/trader/seller/storeCodeDistribution", this.searchForm, res => {
                        // console.log('获取所有定位:',res)
                        this.postObj=res.data.data
                        resolve()
                    })
                })
            },
            getStoreCodeList(){
                this.searchForm.provinceName=cityInfo.provinceName;
                return new Promise((resolve,reject)=>{
                    axiosApi("/unlogin/trader/seller/selectStoreCodeList", this.searchForm, res => {
                        // console.log('获取所有门店列表定位:',res)
                        let oldList = res.data.data
                        let newList = []
                        for(let i = 0; i < oldList.length; i++) {
                            if(oldList[i].longitude &&oldList[i].longitude !=null){
                                newList.push({
                                    showText: false,
                                    storeNo: oldList[i].storeNo,
                                    storeName: oldList[i].storeName,
                                    storeType: oldList[i].storeType,
                                    lnglat: [oldList[i].longitude , oldList[i].latitude]
                                })
                            }
                        }
                        this.storeList = newList
                        resolve()
                    })
                })
            },
            search(val){
                console.log(this.searchForm,'s')
                this.handleCheck().then(()=>{
                    this.getStoreCodeList().then(()=>{
                        this.setMarker()
                    })//获取点
                })

            },
            
            addMarker(imgSrc){
                var myIcon = new BMap.Icon(imgSrc, new BMap.Size(28, 40), {    
                    // 指定定位位置。   
                    // 当标注显示在地图上时，其所指向的地理位置距离图标左上    
                    // 角各偏移10像素和25像素。您可以看到在本例中该位置即是   
                    // 图标中央下端的尖角位置。    
                    anchor: new BMap.Size(14, 40),    
                 
                    imageSize: new BMap.Size(28, 40)
                }); 

                return myIcon
            },
            // 创建点
			makePoint(long, lat, info, mapObject, imgSrc, storeType = null) {
				var point = new BMap.Point(long, lat);

                if(storeType){
                    var marker = new BMap.Marker(point,{icon: this.addMarker(imgSrc)}); // 创建标注
				    mapObject.addOverlay(marker);
                    var label = new BMap.Label(this.makeBubl(info.storeName,storeType), { // 创建文本标注
					position: point, // 设置标注的地理位置
					offset: new BMap.Size(-50, -60) // 设置标注的偏移量
                    })
                    label.setStyle({ // 设置label的样式
                        border: '2px solid #fff',
                        display:"none",
                    })
                    marker.addEventListener("mouseover", function(e) {
                        label.setStyle({ display: "block" });
                    });
                    marker.addEventListener("mouseout", function(e) {
                        label.setStyle({ display: "none" });
                    });
                    mapObject.addOverlay(label)
                }else{
                    var htmlStr = '<div class="content-marker">' +
                        '<div class="content-marker-text"><p>'+ info.districtName +
                        '<p>'+ info.storeCount +'</p></div>' +
                    '</div>'
                    var label = new BMap.Label(htmlStr, { // 创建文本标注
                        position: point, // 设置标注的地理位置
                        offset: new BMap.Size(-35, -35) // 设置标注的偏移量
                    })
                    label.setStyle({ // 设置label的样式
                        border: 'none',
                        borderRadius: '35px'
                    })
                    mapObject.addOverlay(label)
                }
			},
			// 创建标注
			makeBubl(str,storeType) {
                let labelTit = null;
                if(storeType=='B'){
                    labelTit = '<span class="md">【门店】</span>'
                }else if(storeType=='A'){
                    labelTit = '<span class="jg">【久柜】</span>'
                }else if(storeType=='C'){
                    labelTit = '<span class="dzd">【店中店】</span>'
                }
				let html =
					'<div style="width:150px;text-align:center;padding:0px 10px;white-space: pre-wrap;border:5px solid #171e39; position:relative;background-color:#171e39;color:#fff">' +labelTit+
					'<span style="width:0; height:0; font-size:0; overflow:hidden; position:absolute;border-width:10px; border-style:solid dashed dashed; border-color:#171e39 transparent transparent;left:28px; bottom:-20px;"></span>' +
					str +
					'</div>'
				return html
            }
        },
	}
</script>

<style lang="less">
	#storeMap {
        width: 100%;
        box-sizing: border-box;
        padding: 30px 30px 50px 30px;
        .dzd{ color: #00BA00 }
        .jg{ color:#E43E46 }
        .md{ color:#00B0FF }
        .hearder{
            .search {
                .el-date-editor--daterange.el-input{ width: 240px; }
                .el-form--inline {
                    .el-form-item__label {
                        font-size: 12px;
                        width: 60px;
                    }
                }
            }
            .item_icon{
                font-size: 14px;
                img{
                    width:11px;
                    height:16px;
                    margin-left: 20px;
                    margin-right: 3px;
                    vertical-align: sub;
                }
            }
        }
        .content{
            width: 75%;
            position: relative;
            #map{
                width: 100%;
                height: 500px;
            }
            .search_map{
                position: absolute;
                top: 20px;
                right: 20px;
                #pickerInput{
                    z-index: 200;
                    padding: 3px 50px 3px 20px;
                    height: 24px;
                    width: 180px;
                    line-height: 24px;
                    color: #666;
                    border: 1px solid #dfdfdf;
                }
                img{
                    position: absolute;
                    width: 15px;
                    height: 15px;
                    left: 5px;
                    top: 8px;
                }
            }
        }
        .width-30 {
            width: 20%;
            border: 1px solid #ECEEF6;
            border-bottom: none;
            height: 184px;
            margin-left: 30px;
            div {
                border-bottom: 1px solid #ECEEF6;
                height: 45px;
                line-height: 45px;
                padding-left: 20px;
                img {
                    width: 10px;
                    height: 14px;
                    vertical-align: middle;
                    margin-right: 10px;
                }
                span {
                    display: inline-block;
                }
            }
        }
        // marker样式
        .content-marker {
            width: 70px;
            height: 70px;
            border-radius: 35px;
            background-color: #00AEFF;
            color: #FFF;
            font-size: 14px;
            text-align: center;
            .content-marker-text{
                padding-top: 16px;
            }
        }
        .icon_map{
            .text_box{
                width:200px;
                min-height:35px;
                /*// line-height: 35px;*/
                font-size: 12px;
                padding: 8px 10px;
                box-sizing: border-box;
                background:rgba(255,255,255,1);
                box-shadow:0px 1px 1px 0px rgba(0,0,0,0.5);
                border-radius:2px 2px 2px 0px;
                margin-bottom: 10px;
                position: relative;
                left: -75px;
                .arrow {
                    position: absolute;
                    left: 84px;
                    bottom: -12px;
                }
            }
        }
        .el-select-dropdown.is-multiple .el-select-dropdown__item.selected.hover, .el-select-dropdown__item.hover{
            color: #333 !important;
        }
        .map_url{
            text-align: right;
            margin-top: 8px;
            font-size:12px;
            font-family:STHeitiSC-Light;
            font-weight:300;
            color:rgba(153,153,153,1);
        }

	}
</style>
