<template>
	<div id="addPartment">
		<div style="background:#fff;margin-bottom:20px;padding:10px 0px">
			<el-tabs v-model="activeId">
				<el-tab-pane label="基本信息" name="0"></el-tab-pane>
				<el-tab-pane label="数据权限" name="1" v-if="!hide"></el-tab-pane>
			</el-tabs>
		</div>

		<div style="background:white;padding:20px 0">
			<el-form key="formA" :model="formDep" v-if="activeId=='0'"  label-width='140px' :rules="rules" ref="formDep">
				<el-form-item label="部门名称:" prop="departmentName">
					<el-input v-model.trim="formDep.departmentName" :value="formDep.departmentName" placeholder="请输入部门名称"></el-input>
				</el-form-item>
				<el-form-item label="上级部门:" prop="parentId">
					<el-cascader :props="config" :options="depList" clearable :change-on-select="true" v-model="parentIdOptions" @change="handleChange"></el-cascader>
				</el-form-item>
				
				<el-form-item label="部门人员:">
					<el-button @click="add = true">
						<span v-if="hide">添加</span>
						<span v-else>管理</span>
					</el-button>
				</el-form-item>
				<el-form-item>
					<div class="showBox">
						<p class="color-9" v-if="depEmplist.length<1">暂无员工</p>
						<ul>
							<li class="ml-10 fl" v-for="(emp,index) in depEmplist" :key="index+'dj'">{{emp.employeeName}} {{emp.mobile}};</li>
						</ul>
					</div>
				</el-form-item>

				<el-form-item label="部门负责人:">
					<el-select v-model="formDep.departmentLeaderIds" multiple collapse-tags clearable filterable placeholder="请选择部门负责人" >
						<el-option v-for="emp in depEmplist" :key="emp.id" :label="emp.employeeName" :value="emp.id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="">
					<el-button @click="saveDep('formDep')" type="danger">确定</el-button>
					<el-button @click="handleCancle">取消</el-button>
				</el-form-item>
			</el-form>

			<el-form key="formB" v-else label-width='140px'>
				<el-form-item label="部门客户:">
					<el-button :disabled="hide" @click="getClient">
						<span>选择客户</span>
					</el-button>
				</el-form-item>
				<el-form-item>
					<div class="showBox">
						<p class="color-9" v-if="selectListInfo.length<1">暂无客户</p>
						<ul>
							<li class="ml-10 fl" v-for="(emp,index) in selectListInfo" :key="index+'dj'">{{emp.storeName}} {{emp.tel}};</li>
						</ul>
					</div>
				</el-form-item>
			</el-form>
		</div>

		<!--弹出添加员工-->
		<el-dialog title="管理员工" :visible.sync="add" width="740px">
			<div class="box">
				<el-transfer filterable filter-placeholder="请输入员工姓名" :titles="['全部员工', '已选员工']" :buttactive-texts="['移 除', '移至已选']" :data="emp1" v-model="emps">
				</el-transfer>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button type="danger" @click="handleSave">确 定</el-button>
				<el-button @click.native="handleCancle">取 消</el-button>
			</div>
		</el-dialog>

		<!-- 添加部门客户 -->
		<el-dialog title="管理员客户" :visible.sync="client" width="740px">
			<div class="box">
				<el-transfer filterable filter-placeholder="请输入客户姓名" :props="propsData" :titles="['部门客户', '已选客户']" :buttactive-texts="['移 除', '移至已选']" :data="allList" v-model="selectList">
					<span slot-scope="{ option }"> {{ option.storeName }} {{ option.tel }}</span>
				</el-transfer>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button type="danger" @click="departmentSave">确 定</el-button>
				<el-button @click.native="departmentCancle">取 消</el-button>
			</div>
		</el-dialog>
	</div>
</template>
<script>
	import { axiosApi, basePath } from '~/api/api';
	import { getCustomerList, getOwnCustomerList,updateDepartment } from "~/api/axiosInfo";
	export default {
		data() {
			var checkParent = (rule, value, callback) => {
				if(!value) {
					return callback(new Error('上级部门不能为空'));
				}else if(value === this.formDep.id){
					return callback(new Error('不能选择自己为上级'));
				}else if(this.parentIdOptions.length === 8){
					return callback(new Error('不能选择八级部门作为上级部门'));
				}else{
					return callback();
				}
			};
			return {
				activeId: '0',
				title: "",
				hide: false,
				add: false,
				client:false, // 客户管理弹框
				depList: [],
				config: {
					label: "text",
					children: "sub",
					value: "id"
				},
				parentIdOptions: [],
				emplist: [], //全部员工列表
				depEmplist: [], //当前部门全部员工
				formDep: {
					traderId:sessionStorage.traderId,
					id: '', //部门Id
					departmentName: '', //部门名称
					parentId: '', //上级部门
					departmentLeaderIds:[], //部门负责人Id
					employees: [],
					applicationType: 2
				},
				rules: {
					departmentName: [
						{required: true,message: '请输入部门名称',trigger: 'blur'},
						{min: 3,max: 10,message: '长度在 3 到 10 个字符',trigger: 'blur'}
					],
					parentId: [{required: true,validator: checkParent,trigger: 'change'}]
				},
				emp1: [],
				emps: [],
				// 客户列表
				allList: [],
				selectList: [], // 选中客户列表
				selectListInfo: [],
				propsData:{
					key: 'buyerId',
					label: 'storeName'
				}
			}
		},
		methods: {
			// aa(){
			// 	this.add =true
			// 	this.$nextTick(()=>{
			// 		let elCheckboxGroup = document.querySelectorAll('.el-checkbox-group.el-transfer-panel__list.is-filterable')
			// 		if(elCheckboxGroup){
			// 			elCheckboxGroup[0].onscroll = ()=>{
			// 				console.log(elCheckboxGroup[0].scrollTop)
			// 			}
			// 			elCheckboxGroup[1].onscroll = ()=>{
			// 				console.log(111)
			// 			}
			// 		}
			// 	})
			// },
			//部门列表查询
			getDepList() {
				const api = "/foundation/department/displayTree";
				const body = {traderId:sessionStorage.traderId,applicationType: 2};
				axiosApi(api, body, (res)=>{ this.depList = res.data.data ? res.data.data : []; }, this.currentPage, 10)
			},
			//查询部门信息
			getDepinfo() {
				const api = "/foundation/department/selectOne";
				const body = {traderId:sessionStorage.traderId,id: this.$route.params.id == 'XX' ? '' : this.$route.params.id};
				axiosApi(api, body, this.depinfo, this.currentPage, 10)
			},
			depinfo(res) {
				//console.log('查询部门信息',res)
				this.formDep = res.data.data;
				if(this.formDep.departmentLeaderIds == undefined){
					this.$set(this.formDep, "departmentLeaderIds", [])
				}
				this.parentIdOptions = this.formDep.departmentPath.split(",").map((item) => {return parseInt(item);}); //把字符串转化为字符串数组并转化为数字数组
				this.parentIdOptions.pop()
			},
			//查询员工列表
			getEmpList() {
				axiosApi("/foundation/employee/selectAll", {applicationType: 2,traderId:sessionStorage.traderId,needPage: 0, isDeleted: 0}, (res) => {
					this.emplist = res.data.data.elements;
					this.emplist.forEach((emp, index) => {
						const employeeName = this.emplist[index].employeeName;
						const id = this.emplist[index].id;
						const mobile = this.emplist[index].mobile
						this.emp1.push({
							label: employeeName + ' ' + mobile,
							key: id
						});
					});
				}, 1, 1000);
				if(this.$route.params.id == 'XX' ? false : true) {
					axiosApi("/foundation/employee/selectAll", {applicationType: 2, traderId:sessionStorage.traderId, departmentId: this.$route.params.id, isDeleted: 0}, (res) => {
						this.depEmplist = res.data.data.elements;
						this.depEmplist.forEach((depEmp, index) => {
							this.emps.push(this.depEmplist[index].id);
						})
					});
				}
			},
			//选择上级部门处理	      
			handleChange(val) {
				this.formDep.parentId = val[val.length - 1];
				// if(this.$route.params.id == 'XX'){
				// 	this.getCustomerList()
				// }
			},
			//添加/管理员工
			handleSave() {
				this.add = false;
				this.formDep.employees = this.emps;
				this.depEmplist = [];
				this.emps.forEach((item1) => {
					this.emplist.forEach((item2) => {
						if(item1 == item2.id) {
							this.depEmplist.push(item2)
						}
					})
				})
					// 针对于部门负责人使用。对比部门人员ID，清除不在部门的负责人。
				let arr = []
				this.emps.forEach((item,index)=>{
					this.formDep.departmentLeaderIds.forEach((items,indexs)=>{
							if(item == items) {
							arr.push(items)
						}
					})
				})
				this.formDep.departmentLeaderIds = arr
			},
			//添加/编辑部门
			saveDep(formDep) {
				this.formDep.employees = this.emps;
				this.$refs[formDep].validate((valid) => {
					if(valid) {
						// console.log("验证成功");
						var body = this.formDep;
						if(this.$route.params.id == 'XX' ? false : true) {
							this.api = "/foundation/department/update";
							body.permissionType = 'customer'
						} else {
							this.api = "/foundation/department/insert";
						}
						const api = this.api;
						
						axiosApi(api, body, this.addcallback, this.currentPage, 10, (res)=>{
							this.$message({
								type: 'info',
								message: res.data.message,
								duration: 1000
							});
						})
					} else {
						// console.log('error submit!!');
						return false;
					}
				});
			},
			addcallback(res) {
				this.$message({
					title: '成功',
					message: '恭喜你，添加成功！',
					type: 'success'
				});
				if(this.$route.params.sign == 1) {
					this.$router.push(basePath + '/addPeople/' + this.$route.params.sign)
				} else if(this.$route.params.sign == 2) {
					this.$router.push(basePath + '/editPeople/' + this.$route.params.sign)
				} else {
					this.$router.push(basePath + '/department')
				}
			},
			//取消
			handleCancle() {
				if(this.$route.params.sign == 1) {
					this.$router.push(basePath + '/addPeople/' + this.$route.params.sign)
				} else if(this.$route.params.sign == 2) {
					this.$router.push(basePath + '/editPeople/' + this.$route.params.sign)
				} else {
					this.$router.push(basePath + '/department')
				}
			},
			changeHeight() {
				// var height = document.documentElement.clientHeight;
				// document.getElementById("addPartment").style.minHeight = height - 411 + "px";

			},
			getClient(){
				this.getCustomerList()
				this.getOwnCustomerList()
			},
			// 部门客户
			getCustomerList(){
				var params = {
					sellerId: sessionStorage.getItem('traderId'),
					departmentId: this.$route.params.id == 'XX' ? null : this.$route.params.id,
					isEditDepartment: true, // 区分部门编辑或者员工编辑
				}
				getCustomerList(params).then(res=>{
					this.allList = res
					this.client = true
				})
			},
			// 已选中客户
			getOwnCustomerList(){
				var params = {
					sellerId: sessionStorage.getItem('traderId'),
					departmentId: this.$route.params.id == 'XX' ? null : this.$route.params.id,
				}
				getOwnCustomerList(params).then(res=>{
					this.selectListInfo = res
					this.selectList = res.map(item=>(item.buyerId))
				})
			},
			departmentSave(){
				var data = {
					departmentId: this.$route.params.id == 'XX' ? null : this.$route.params.id,
					permissionType: 'customer',
					buyerIds: this.selectList
				}
				updateDepartment(data).then(res=>{
					this.client = false
					this.getOwnCustomerList()
				})
			},
			departmentCancle(){
				this.client = false
				this.getOwnCustomerList()
			},
		},
		mounted() {
			this.$nextTick(function() {
				this.getDepList();
				this.getEmpList();
				
				if(this.$route.params.id == 'XX' ? false : true) {
					this.hide = false;
					this.title = "编辑部门";
					this.getDepinfo();
					this.getOwnCustomerList()
				} else {
					this.hide = true;
					this.title = "新增部门"
				}
				this.changeHeight();
			})
		}
	}
</script>
<style lang="less">
	#addPartment {
        min-height: 100%;
        padding-bottom: 50px;
		.showBox {
			width: 600px;
			height: 200px;
			padding: 20px;
			overflow-y: auto;
			border: 1px solid #DDDDDD;
			p {
				text-align: center;
				line-height: 200px;
			}
		}
		.box {
			margin: 25px 75px;
			.el-transfer-panel__body{
				.el-input--small{
					width: 189px!important
				}
			}
		}
		.el-dialog {
			width: 710px;
			height: 700px;
		}
		.el-transfer-panel {
			width: 220px;
		}
		.el-transfer-panel__body {
			height: 340px;
		}
		.el-checkbox-group {
			padding-bottom:20px !important;
		}
		.el-transfer-panel__list.is-filterable {
			height: 300px;
			
		}
	}
</style>