<template>
	<div id="superGroupOrder" class="bg-f">
		<div class=" box-con bdt bg-f">
			<!--订单基础信息-->
			<div class="order-info">
				<div class="order-info-tit pl-20">订单信息</div>
				<div class="order-info-con clearfix">
					<div class="order-info-conL fl">
						<div>
							<label>订单编号：</label>
							<span class="ml-10">{{sonSns}}</span>
						</div>
						<div>
							<label>供货商名称：</label>
							<span class="ml-10">{{orderInfo.sellerName}}</span>
						</div>
						<div>
							<label>订单状态：</label>
							<span class="ml-10" v-if="orderInfo.orderStatus==0">已关闭</span>
							<span class="ml-10 color-danger" v-if="orderInfo.orderStatus==1">待付款</span>
							<span class="ml-10" v-if="orderInfo.orderStatus==2">待发货</span>
							<span class="ml-10" v-if="orderInfo.orderStatus==3">已发货</span>
							<span class="ml-10" v-if="orderInfo.orderStatus==4">已完成</span>
						</div>
						<div v-if="orderInfo.orderStatus==0" class="order-cancel-flex">
							<label>关闭原因：</label>
							<span class="ml-10">{{orderInfo.cancleReason}}</span>
						</div>
					</div>
					<div class="fr mt-30 mr-20">
						<span v-if="orderInfo.orderStatus==1">
							<span class="mr-20" v-if="!orderInfo.remainAutoCancleTime||orderInfo.remainAutoCancleTime<0">还剩<span class="color-danger">{{orderInfo.remainAutoCancleTime}}</span>自动取消</span>
							<el-button v-if="showButton('SALE_ORDER_CANCEL',140004) && type == 0 && (orderInfo.source == 5 || orderInfo.source == 6)" @click="handleCancelOrder()">取消订单</el-button>
						</span>
						<el-button v-if="orderInfo.orderStatus==2 && showButton('SALE_ORDER_SEND',140004) && type == 0 && orderInfo.headName == 'virtual' && identity == 'central'" @click="handleSend(orderInfo.sn)">发货</el-button>
					</div>
				</div>
			</div>
			<!--取消订单弹出框-->
			<el-dialog class='cancelOrderDialog' title='取消订单理由' :visible.sync='cancelOrderVisible'>
				<el-form class="pl-30 pr-30" :model="form" ref="cancelOrderForm" :rules="rules2">
					<el-form-item label="" prop='cancleReason'>
						<el-input type='textarea' placeholder='请输入取消订单的理由...' :autosize="{ minRows: 3 ,maxRows: 10}" v-model.trim='form.cancleReason'></el-input>
					</el-form-item>
				</el-form>
				<div slot='footer' class="dialog-footer">
					<el-button type='danger' @click="handleCommit(2)">确定</el-button>
					<el-button @click="cancelOrderVisible = false">取 消</el-button>
				</div>
			</el-dialog>
			<!--订单详情-->
			<!--购买商品基本信息-->
			<div class='goods-title clearfix mt-20' style="display: flex">
                <span class="tac" style="flex: 2">商品名称</span>
                <span class="tac" style="flex: 1">单价</span>
                <span class="tac" style="flex: 1">数量</span>
                <span class="tac" style="flex: 1">总金额</span>
            </div>
			<div class="goods">
				<div class="goods-info clearfix" v-for="(g,index) in orderInfo.grouponOrderItemVOs" :key="index" style="display: flex;">
					<div class="goods-infoL fl clearfix" style="flex: 4;display: flex">
                        <div style="flex: 2;" class="clearfix">
                            <span class="fl" style="position: relative;width:80px;height:80px;">
                                <img v-if="g.skuImage" :src="g.skuImage" alt="" />
                                <img v-else src="../../assets/goods.jpg" alt="" />
                            </span>
                            <span class="ml-15 fl">
								<p>
									<img v-if='g.goodsInfo && g.goodsInfo.bizType == 1' src="../../assets/superGroup/jiujiajiu.png" style="width: 45px;height: 15px;vertical-align:middle"/>
									<span style="position: relative;top: 2px;">{{g.skuName}}</span>
								</p>
								<span class="mt-10 color-9" v-if="g.changeCoefficient">规格: 1*{{g.changeCoefficient}}</span>
						    </span>
                        </div>

						<span class="tac" style="flex: 1;">￥{{g.originPrice||0 | fmoney}}</span>
						<span class="tac" style="flex: 1">{{g.num}} <span>{{g.unit}}</span> </span>
					</div>
					<span class="goods-infoR tac pt-20 fl">￥{{g.discountTotalPrice | fmoney}}</span>
				</div>
				<!--赠品-->
				<div class="goods-info clearfix" v-for="(g,index) in orderInfo.orderActivityGiftRelationVOs" :key="index" style="display: flex;">
					<div class="goods-infoL fl clearfix" style="display: flex;flex: 4">
                        <div style="flex: 2">
                            <span class="fl" style="position: relative;width:80px;heigth:80px">
								<img v-if="g.skuImage" :src="g.skuImage" alt=""/>
								<img v-else src="../../assets/goods.jpg" alt="" />
                                <!--赠品-->
								<img src="../../assets/superGroup/complimentary.png" alt="" style="position: absolute;top:0;left:0;"/>
							</span>
                            <span class=" ml-15 fl">
								<p>
									<img v-if='g.goodsInfo && g.goodsInfo.bizType == 1' src="../../assets/superGroup/jiujiajiu.png" style="width: 45px;height: 15px;vertical-align:middle"/>
									<span style="position: relative;top: 2px;">{{g.skuName}}</span>
								</p>
								<span class="mt-10 color-9" v-if="g.changeCoefficient">规格: 1*{{g.changeCoefficient}}</span>
						</span>
                        </div>

						<span class="tac" style="flex: 1">￥{{0 | fmoney}}</span>
						<span class="tac" style="flex: 1">{{g.giftNum}} <span>{{g.sellingUnitName}}</span> </span>
					</div>
					<span class="goods-infoR tac pt-20 fl">￥{{0 | fmoney}}</span>
				</div>
				<div class="goods-money clearfix">
					<div class="goods-moneyR fr">
						<div><label><span class="mr-20">共{{orderInfo.skuTotalNum}}件商品</span>订单金额：</label><span class="ml-10">¥{{orderInfo.goodsTotalMoney||0 | fmoney}}</span></div>

						<div><label>满减优惠：</label><span class="ml-10">-¥{{orderInfo.activityCutMoney||0 | fmoney}}</span></div>
						<div><label>运费：</label><span class="ml-10">+¥{{orderInfo.freightMoney | fmoney}}</span></div>
						<div><label>应付金额：</label><span class="ml-10">¥{{orderInfo.needPayMoney | fmoney}}</span></div>
						<div v-if='orderInfo.voucherCutMonney != 0'><label>代金券减：</label><span class="ml-10">-¥{{orderInfo.voucherCutMonney||0 | fmoney}}</span></div>
						<div v-if='orderInfo.payedMoney != 0'><label>实付金额：</label><span class="ml-10">¥{{(orderInfo.payedMoney) | fmoney}}</span></div>
						<div v-if='type == 0' class="font-20 color-danger"><label>本单分销分成：</label><span class="ml-10">¥{{orderInfo.commission||0 | fmoney}}</span></div>
					</div>
				</div>
			</div>
			<!--收货信息-->
			<div class="order-info mt-20">
				<div class="order-info-tit pl-20">收货信息</div>
				<div class="order-info-con">
					<div class="order-info-conL">
						<div><label>收货人：</label><span class="ml-10">{{orderInfo.addrUsername}}</span></div>
						<div><label>联系电话：</label><span class="ml-10">{{orderInfo.addrMobile}}</span></div>
						<div><label>收货地址：</label><span class="ml-10">{{orderInfo.addrProvince}}{{orderInfo.addrCity}}{{orderInfo.addrRegion}}{{orderInfo.addr}}</span></div>
					</div>
				</div>
			</div>
			<!--备注信息-->
			<div class="order-info mt-20">
				<div class="order-info-tit pl-20">备注信息</div>
				<div class="order-info-con">
					<div class="order-info-conL">
						<div>
							<label>备注信息：</label>
							<span class="ml-10" v-if="orderInfo.remark">{{orderInfo.remark}}</span>
							<span class="ml-10" v-else>无</span>
						</div>
					</div>
				</div>
			</div>
			<!--发票信息-->
			<div class="order-info mt-20" v-if="orderInfo.orderInvoicePo">
				<div class="order-info-tit pl-20">发票信息</div>
				<div class="order-info-con">
					<div class="order-info-conL">
						<div>
							<label>发票信息：</label>
							<span class="ml-10" v-if="orderInfo.orderInvoicePo.personalCompanyType==null">无</span>
							<span class="ml-10" v-if="orderInfo.orderInvoicePo.personalCompanyType==2">个人</span>
							<span class="ml-10" v-if="orderInfo.orderInvoicePo.personalCompanyType==1">
									<span>{{orderInfo.orderInvoicePo.companyHead}}</span>
							<span class="ml-5">{{orderInfo.orderInvoicePo.taxpayerIdNo}}</span>
							</span>
						</div>
					</div>
				</div>
			</div>
			<!--配送信息-->
			<div class="order-info mt-20" v-if="(orderInfo.orderStatus==3 || orderInfo.orderStatus==4 )&& (orderInfo.shipperType == 3 || orderInfo.shipperType == 1)">
				<div class="order-info-tit pl-20">物流信息</div>
				<div class="order-info-con pt-30 pb-30 pl-75">
					<div style="width: 800px;" class="order-info-conL pt-0 pb-0" :class="{'mt-15':index != 0}" v-for='(item,index) in orderInfo.deliveryVos' :key="index">
						<div class="clearfix">
							<div class="fl">物流公司：</div>
							<div style="width: 100px;" class="ml-10 fl">{{item.shipperName}}</div>
							<div style="width: 80px;" class="ml-10 tal fl">物流单号：</div>
							<div style="width: 150px;" class="ml-10 fl">{{item.deliveryNo}}</div>
							<div class="ml-30 color-blue cursor fl" @click="searchLogistics(item)">查看物流</div>
						</div>
					</div>
				</div>
			</div>
			<!--物流信息-->
			<!--<div class="order-info mt-20" v-if="(orderInfo.orderStatus==3 || orderInfo.orderStatus==4) && orderInfo.shipperType == 3 && formInfo.traceApiDtos !=null">
				<div class="order-info-tit pl-20">物流信息</div>
				<div class="order-info-con pl-20">
					<div class="order-info-conS ml-50">
						<div class="clearfix" v-for='(item,index) in formInfo.traceApiDtos' :key='index' :class='index == 0 ? "color-red":""'>
							<span class="fl">{{item.opeTime | time}}</span>
							<div class="fl ml-10" style="width: 80%;">
								<span>{{item.opeRemark}}</span>
								<span class="ml-10">{{item.opeTitle}}</span>
							</div>
						</div>
					</div>
				</div>
			</div>-->
			<!--快递100物流信息-->
			<!--<div class="order-info mt-20" v-if="(orderInfo.orderStatus==3 || orderInfo.orderStatus==4) && orderInfo.shipperType == 1 && listInfo.length > 0">
				<div class="order-info-tit pl-20">物流信息</div>
				<div class="order-info-con pl-20">
					<div class="order-info-conS ml-50">
						<div class="clearfix" v-for='(item,index) in listInfo' :key='index' :class='index == 0 ? "color-red":""'>
							<span class="fl">{{item.time | time}}</span>
							<div class="fl ml-10" style="width: 80%;">
								<span>{{item.context}}</span>
							</div>
						</div>
					</div>
				</div>
			</div>-->
			<!--<el-button v-if="(orderInfo.orderStatus==3 || orderInfo.orderStatus==4) && formInfo.shipperType == 1" class='mt-30' type="danger" @click="checkLogistics">查看物流状态</el-button>-->
			<!--快递100-->
			<!--<div class="order-info mt-20" v-if="(orderInfo.orderStatus==3 || orderInfo.orderStatus==4) && formInfo.shipperType == 1">
				<div class="order-info-tit pl-20">物流单号</div>
				<div class="order-info-con">
					<div class="order-info-conL">
						<div v-for="(i,index) in formInfo.deliveryVos" :key="index">
							<label>{{i.shipperName}}：</label>
							<span @click="checkLogistics(i)" class="ml-10 color-blue cursor">{{i.deliveryNo}}</span>
						</div>
					</div>
				</div>
			</div>-->
		</div>
		<!-- 查看物流弹窗 -->
		<el-dialog class='bondDialog' title='查看物流' :visible.sync='logisticsVisible'>
			<div class="content">
				<div class="content-top clearfix">
					<img class="fl car-image mt-5" src="../../assets/superGroup/big-car.png"/>
					<div class="fl ml-20 color-6 font-14">
						<p class="mb-5">物流公司：{{formV.shipperName}}</p>
						<p class="mb-5">物流单号：{{formV.deliveryNo}}</p>
						<p>发货时间：{{formV.gmtShipped | time}}</p>
					</div>
				</div>
				<div class="content-bottom pt-30 pb-20 pl-30" ref='wl'>
					<div class="clearfix" v-for="(item,index) in listInfo" :key='index'>
						<img v-if='index == 0' style="width: 15px;height: 15px;" class="" src="../../assets/superGroup/short01.png"/>
						<img v-if='index != 0' style="width: 15px;height: 15px;" class="" src="../../assets/superGroup/short02.png"/>
						<!--<img v-if='index == 0' class="fl trace-image" src="../../assets/superGroup/trace01.png"/>
						<img v-else-if='index != 0 && index != listInfo.length-1' class="fl trace-image" src="../../assets/superGroup/trace02.png"/>
						<img style="width: 15px;height: 15px;" v-else class="fl" src="../../assets/superGroup/short02.png"/>-->
						<div class="ml-8 border-blue" :class="{'border-333':index != 0,'border-000':index == listInfo.length-1}" style="width: 360px;margin-top: -4px;">
							<div class="mt-020">
								<p class="color-6" :class="{'color-9':index != 0}">{{item.time}}</p>
								<p class="color-3" :class="{'color-9':index != 0}">{{item.context}}</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div slot='footer' class="dialog-footer">
				<el-button type='danger' @click="logisticsVisible = false">确 定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import { axiosApi, basePath } from "../../api/api";
	export default {
		data() {
			return {
				orderSn:'',
				sonSns:'',
				// mainOrderSn:sessionStorage.mainOrderSn,
				type:sessionStorage.GroupOrderType ? sessionStorage.GroupOrderType  : 0,
				/*规则*/
				rules2: {
					cancleReason: [{
						required: true,
						message: '取消订单理由不能为空'
					}, {
						min: 4,
						max: 100,
						message: '取消订单理由为4-100位字符'
					}]
				},
				orderInfo: {}, //订单信息
				formInfo: {}, //订单出库发货记录
				cancelOrderVisible: false,
				sendGoods: false,
				host: '',
				form: {
					cancleReason: '', //取消订单原因
					companyName: '', //物流公司
					expressNum: '', //物流编号
				},
				sn: '',
				identity:'',
				listInfo:{},//专门的物流信息
				companyCode:'',
				deliveryNo:'',
				logisticsVisible:false,//查看物流弹窗
				formV:{},//弹框信息
				
			}
		},
		watch:{
			logisticsVisible(val){
				if(this.$refs.wl){
					this.$refs.wl.scrollTop= 0
				}
			}
		},
		mounted() {
			this.$nextTick(() => {
				this.sonSns = sessionStorage.sn
				this.host = encodeURIComponent(window.location.href);
				this.getOrderInfo();
//				this.shipmentRechord();
				this.isShow()
			})
		},
		methods: {
			getOrderInfo() { //获取订单详细信息
				axiosApi('/groupon/grouponOrderDetail/select', {
					orderSn: sessionStorage.sn ? sessionStorage.sn : '',
					// mainOrderSn:sessionStorage.mainOrderSn ? sessionStorage.mainOrderSn : '',
					sourceType:this.type == 0 ? '5' : ''
				}, (res) => {
					this.orderInfo = res.data.data;
					this.sn = this.orderInfo.sn
//					if(res.data.data.deliveryVos && res.data.data.deliveryVos.length > 0){
//						this.companyCode = res.data.data.deliveryVos[0].companyCode
//						this.deliveryNo = res.data.data.deliveryVos[0].deliveryNo
//						this.checkLogisticsNew()
//					}
					
					if(this.orderInfo.remainAutoCancleTime != null && this.orderInfo.remainAutoCancleTime > 0) {
						let time = parseInt(this.orderInfo.remainAutoCancleTime / 1000) //转化为整数
						this.orderInfo.remainAutoCancleTime = this.timeString(time) //转化为时间格式
						let clearTime = null //定义变量
						clearTime = setInterval(() => {
							time--
							this.orderInfo.remainAutoCancleTime = this.timeString(time)
							if(time <= 0) {
								clearTimeout(clearTime)
								// if(this.orderInfo.orderStatus == 1) this.orderInfo.orderStatus = 0
							}
						}, 1000)
					} else {
						// if(this.orderInfo.orderStatus == 1 && this.orderInfo.remainAutoCancleTime < 0) this.orderInfo.orderStatus = 0
					}
				}, 1, 10, (res) => {
					this.$message({
						type: 'error',
						message: res.data.message,
						duration: 1000
					})
				})
			},
			//订单出库发货记录查询
			shipmentRechord() {
				axiosApi('/groupon/shipmentRechord/query', {
					orderSn: sessionStorage.sn
				}, (res) => {
					this.formInfo = res.data.data;
					if(this.formInfo.traceApiDtos){
						this.formInfo.traceApiDtos = this.formInfo.traceApiDtos.reverse()
					}
				}, 1, 10, (res) => {})
			},
			searchLogistics(item){
				
				this.formV.shipperName = item.shipperName
				this.formV.deliveryNo = item.deliveryNo
				this.formV.gmtShipped = item.gmtShipped
				this.companyCode = item.companyCode
				this.deliveryNo = item.deliveryNo
				this.orderSn = item.orderSn
				this.checkLogisticsNew()
				this.logisticsVisible = true
				console.log(item,'物流单号')
			},
			//专门查物流的接口
			checkLogisticsNew() {
				if(this.orderSn){
					axiosApi('/foundation/kuaidi100/fetchExpressRouteInfo', {
					 companyCode: this.companyCode,
     				 expressNO: this.deliveryNo,
					orderSn:this.orderSn
				  }, (res) => {
					console.log('data',JSON.parse(res.data.data[0].expressData))
					this.listInfo =res.data.data ?  JSON.parse(res.data.data[0].expressData) : []
				  }, 1, 10, (res) => {})
				}
				
			},
			checkLogistics(val) { //查看物流
				axiosApi('/groupon/expressInfo/select', {
					type: val.shipperName,
					postid: val.deliveryNo,
				}, (res) => {
					window.open(res.data.data.url)
				}, 1, 10, (res) => {})
			},
			handleCancelOrder() { //取消订单按钮
				this.cancelOrderVisible = true;
			},
			handleSend(sn) { //发货按钮
				this.$router.push(basePath + "/groupConsignment/" + sn);
			},
			handleCommit(state) { //确定
				if(state == 2) { //弹窗 取消订单
					this.$refs.cancelOrderForm.validate((valid) => {
						if(valid) {
							this.cancelOrderVisible = false
							axiosApi('/groupon/grouponOrderCancel/update', {
								mainOrderNo: this.orderInfo.parentOrderSn,
								cancleReason: this.form.cancleReason,
								cancleType: 2
							}, (res) => {
								//console.log('取消订单',res)
								this.$message({type:'success',message:'取消成功',duration:1000})
								this.getOrderInfo()
							}, 1, 10, (res) => {
								this.$message({
									type: 'error',
									message: res.data.message,
									duration: 1000
								})
							})
						} else {
							return false
						}
					})
				}
			},
				//登录信息
			isShow(){
				axiosApi('/groupon/sellerType/select', {
						id:sessionStorage.traderId
					}, (res) => {
						this.identity = res.data.data
					}, 1, 10, (res) => {
						this.$message.info(res.data.message);
					});
			},
		}
	}
</script>

<style lang="less">
	#superGroupOrder {
		.pl-75{
			padding-left: 75px;
		}
		.ml-8{
			margin-left: 6px;
		}
		.mt-020{
			margin-top: -25px;
		}
		.border-blue{
			border-left: 2px solid #00aaee;
			padding: 10px 0 20px 20px;
		}
		.border-000{
			border-left: none !important;
			padding: 10px 0 20px 20px;
		}
		.border-333{
			border-left: 2px solid #dddddd;
			padding: 10px 0 20px 20px;
		}
		 .pt-0{
		 	padding-top: 0 !important;
		 } 
		 .pb-0{
		 	padding-bottom: 0 !important;
		 }
		.color-red {
			color: red;
		}
		.bdt {
			border-top: 1px solid #eee;
		}
		.el-tabs__header {
			padding: 0;
			border-bottom: 1px solid #eee;
		}
		.box-con {
            padding: 20px;
			.order-info {
				border: 1px solid #ddd;
				box-sizing: border-box;
				.order-info-tit {
					height: 40px;
					line-height: 40px;
					background-color: #eee;
				}
				.order-info-con {
					.order-info-conL {
						width: 600px;
						line-height: 30px;
						padding: 22.5px 0;
						label {
							width: 150px;
							display: inline-block;
							text-align: right;
						}
					}
					.order-info-conS {
						line-height: 30px;
						padding: 22.5px 0;
						label {
							width: 150px;
							display: inline-block;
							text-align: right;
						}
					}
				}
			}
			.goods {
				border: 1px solid #ddd;
				box-sizing: border-box;
				.goods-info {
					height: 120px;
					border-bottom: 1px solid #ddd;
					span {
						display: inline-block;
					}
					.goods-infoL {
                        width: 80%;
						height: 100%;
						padding: 20px;
						box-sizing: border-box;
						border-right: 1px solid #ddd;
						img {
							width: 80px;
							height: 80px;
						}
						.w380 {
							width: 380px;
						}
						.w210 {
							width: 210px;
						}
					}
					.goods-infoR {
						width: 20%;
                        padding-left: 20px;
                        box-sizing: border-box;
						display: inline-block;
					}
				}
				.goods-money{
					height: 150px;
					line-height: 25px;
					padding: 30px 50px;
					/*box-sizing: border-box;*/
					label {
						width: 300px;
						display: inline-block;
						text-align: right;
					}
				}
			}
			.el-dialog {
				min-height: 300px;
				.el-form-item__label {
					width: 140px;
				}
			}
			.sendGoods .el-form-item__error {
				margin-left: 140px;
			}
		}
		.goods-title {
			width: 100%;
			height: 40px;
			line-height: 40px;
			border: 1px solid #ddd;
			border-bottom: none;
			box-sizing: border-box;
			background: #eee;
		}
		.width-239 {
			width: 239px;
		}
		.bondDialog{
			.el-dialog{
				width: 700px !important;
				height: 700px !important;
				.el-dialog__body{
					padding-left: 100px;
				}
				.content{
					width: 500px;
					height: 507px;
					border: 1px solid #EEEEEE;
					.content-top{
						width: 500px;
						height: 106px;
						background: #F5F5F5;
						font-size: 14px;
						padding:20px 0 20px 30px;
						box-sizing:border-box;
						.car-image{
							/*width: 50px;
							height: 35px;*/
						}
					}
					.content-bottom{
						width: 500px;
						height: 401px;
						box-sizing: border-box;
						overflow-y: scroll;
						.trace-image{
							width: 14px;
							height: 98px;
						}
					}
				}
			}
		}
	}
</style>