<template>
    <div id="cardManage" class="pb-50">
        <div class="title-top bg-f">卡券维护</div>
        <div class="bg-f mt-5 pb-50">
            <div class="content bg-f pl-30 pr-30">
                <!-- 搜索区域 -->
                <div class="clearfix search">
                    <el-form :inline='true' label-width="50px" class="pt-30" :model="form" ref="searchForm"
                             :rules="rules2">

                        <el-form-item label='序列号:'>
                            <el-input v-model.trim="form.number" :maxlength="30" placeholder="请输序列号"></el-input>
                        </el-form-item>
                        <el-form-item class="ml-10" label="状态:">
                            <el-select clearable v-model="form.classifyId" class="fl" placeholder="">
                                <el-option
                                        v-for="item in options"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>


                        <el-form-item label='额度:'>
                            <el-form-item prop="money1" style="margin: 0;">
                                <el-input size="mini" v-model.trim="form.money1" placeholder=""></el-input>
                            </el-form-item>
                            <span>&nbsp;至&nbsp;</span>
                            <el-form-item prop="money2">
                                <el-input size="mini" v-model.trim="form.money2" placeholder=""></el-input>
                            </el-form-item>
                        </el-form-item>
                        <el-form-item class="ml-20" label='有效期:'>
                            <ysDaterangePicker v-model="form.orderDateRange"/>
                        </el-form-item>
                    </el-form>
                    <div>
                        <el-button class="mb-20" type='info' @click="getData(true)" icon="el-icon-search">查 询</el-button>
                    </div>
                </div>

                <!-- 按钮 -->
                <div class='clearfix'>
                    <el-upload class="dib fr mt-5 "
                               action="/sunrise-gateway/import/catering/platform/card/import?startRow=1&startCol=0"
                               :show-file-list="false" :on-success="handUpSuccess" :before-upload="handUpBefore"
                               :on-error="handUpError" accept="application/vnd.ms-excel">
                        <span type='text' class="color-blue cursor">导入</span>
                    </el-upload>
                    <span class="fr color-blue cursor mt-5 mr-30"
                          @click="downloadTemplate">模板下载</span>
                    <span class="fr color-blue cursor mr-30 mt-5"
                          @click="dialogShow=true">添加</span>
                </div>

                <!-- 列表区域 -->
                <div class="mt-10">
                    <el-table :data="GroupList" class='table-default'>
                        <el-table-column label="序列号" prop="no"></el-table-column>
                        <el-table-column label="密码" prop="password"></el-table-column>
                        <el-table-column label='有效期' width="160">
                            <template slot-scope="scope">
                                <span v-if="scope.row.gmtStart">{{scope.row.gmtStart | timenData}}至{{scope.row.gmtEnd | timenData}}</span>
                                <span v-else>永久有效</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="额度">
                            <template slot-scope="scope">
                                <span>￥{{scope.row.quotaMoney | fmoney}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label='卡种' width="">
                            <template slot-scope="scope">
                                <span>{{scope.row.type==1?'实体卡':'电子卡'}}</span>
                            </template>
                        </el-table-column>

                        <el-table-column label='状态' width="">
                            <template slot-scope="scope">
                                <span v-if="scope.row.status==1">{{"可用"}}</span>
                                <span v-if="scope.row.status==2">{{"禁用"}}</span>
                                <span v-if="scope.row.status==3">{{"已用"}}</span>
                            </template>
                        </el-table-column>

                        <el-table-column label="操作" width="100">
                            <template slot-scope="scope">
                                <el-button v-if="scope.row.status==1" class='ml-0 width-90' size="small" type='text'
                                           @click="upDown(scope,2)">禁用
                                </el-button>
                                <el-button v-if="scope.row.status==2" class='ml-0 width-90' size="small" type='text'
                                           @click="upDown(scope,1)">启用
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-pagination class="tar mt-20" v-if="GroupList.length>0" :current-page="currentPage" :page-size='10'
                                   :total='total' layout="prev , pager, next, jumper"
                                   @current-change="handleCurrentChange">
                        <!-- 分页组件 -->
                    </el-pagination>
                </div>
            </div>
        </div>

        <!--添加服务员弹窗-->
        <el-dialog class="minMop aa" title="添加卡券" :visible.sync='dialogShow'>
            <el-form class="pr-30" :model="form1" ref="ruleForm" :rules='rules'>
                <el-form-item label="序列号" prop='number'>
                    <el-input placeholder='请输序列号' v-model.trim='form1.number'></el-input>
                </el-form-item>
                <el-form-item label="密码" prop='password'>
                    <el-input placeholder='请输入密码' v-model.trim='form1.password'></el-input>
                </el-form-item>

                <el-form-item label="类型" prop='typeId'>
                    <el-select clearable v-model="form1.typeId" class="fl" placeholder="">
                        <el-option
                                v-for="item in optionsType"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label='有效期'>
                    <ysDaterangePicker v-model="form1.orderDateRange"/>
                </el-form-item>

                <el-form-item label="额度" prop='money'>
                    <el-input placeholder='请输入额度' v-model.trim='form1.money'></el-input>
                </el-form-item>
            </el-form>
            <div slot='footer' class="dialog-footer">
                <el-button type='danger' @click="save">确 定</el-button>
                <el-button @click="dialogShow = false">取 消</el-button>
            </div>
        </el-dialog>

        <!-- 下载导入失败文件信息 -->
        <form ref="downImportErrForm" :action=errorInfoFileUrl style="display: none">

        </form>

        <!-- 下载导入失败文件信息 -->
        <form ref="downTemplate" :action="templateUrl" style="display: none">

        </form>
    </div>
</template>

<script>
    import {axiosApi, gotoLoginPage, basePath, baseURI} from "~/api/api.js";

    export default {
        data() {
            return {
                /*规则*/
                rules: {
                    number: [{required: true, message: '序列号为2-20个字母或数字', pattern: /^[a-zA-Z0-9_-]{2,20}$/}],
                    password: [{required: true, message: '密码为2-10个字母或数字', pattern: /^[a-zA-Z0-9_-]{2,10}$/}],
                    typeId: [{required: true, message: '请选择类型'}],
                    money: [{required: true, message: '请输入额度', pattern: /^[0-9]{1,9}([.]{1}[0-9]{0,2}){0,1}$/}],
                },
                rules2: {
                    money1: [{
                        message: '请输入正确金额',
                        pattern: /^[0-9]{1,9}([.]{1}[0-9]{0,2}){0,1}$/
                    }],
                    money2: [{
                        message: '请输入正确金额',
                        pattern: /^[0-9]{1,9}([.]{1}[0-9]{0,2}){0,1}$/
                    }],
                },
                dialogShow: false,
                options: [
                    {value: '', label: '全部'},
                    {value: '1', label: '可用'},
                    {value: '2', label: '禁用'},
                    {value: '3', label: '已用'},
                ],
                optionsType: [
                    {value: '1', label: '实物卡'},
                ],
                currentPage: 1,
                total: 0,
                GroupList: [],
                form: {
                    number: '',
                    money1: '',
                    money2: '',
                    classifyId: null,
                    orderDateRange: []
                },
                form1: {
                    number: '',
                    password: '',
                    typeId: '',
                    money: '',
                    orderDateRange: [],
                },
                templateUrl: "https://sunrise-cbs.oss-cn-hangzhou.aliyuncs.com/template/jiujiajiu/catering/%E5%8D%A1%E5%88%B8%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xls",//模版地址
                errorInfoFileUrl: null,//错误文件下载
            }
        },
        watch: {
            dialogShow(val) {
                if (!val) {
                    this.form1.number = null;
                    this.form1.password = null;
                    this.form1.typeId = null;
                    this.form1.money = null;
                    this.form1.orderDateRange = [];
                    this.$refs.ruleForm.resetFields()
                }
            }
        },
        //进入加载
        mounted() {
            this.$nextTick(function () {
                this.getData()
            })
        },
        methods: {
            getData(type) {
                this.$refs.searchForm.validate((valid) => {
                    if (valid) {
                        if (type) {
                            this.currentPage = 1
                        }
                        axiosApi('/catering/platform/card/selectPage', {
                            no: this.form.number,
                            minQuotaMoney: this.form.money1,
                            maxQuotaMoney: this.form.money2,
                            status: this.form.classifyId,
                            gmtStart: !this.form.orderDateRange[0] ? null : this.form.orderDateRange[0].getTime(),
                            gmtEnd: !this.form.orderDateRange[1] ? null : this.form.orderDateRange[1].getTime(),
                        }, (res) => {
                            this.GroupList = res.data.data.elements ? res.data.data.elements : []
                            this.total = res.data.data.totalCount
                        }, this.currentPage, 10, (res) => {
                        });
                    } else {
                        return false
                    }
                })


            },
            downloadTemplate() {
                this.$refs.downTemplate.submit();
            },
            handleCurrentChange(val) { //分页
                this.currentPage = val
                this.getData()
            },

            save() {
                this.$refs.ruleForm.validate((v) => {
                    if (v) {
                        let body = {
                            no: this.form1.number,
                            password: this.form1.password,
                            type: this.form1.typeId,
                            quotaMoney: this.form1.money,
                            gmtStart: !this.form1.orderDateRange[0] ? null : this.form1.orderDateRange[0].getTime(),
                            gmtEnd: !this.form1.orderDateRange[1] ? null : this.form1.orderDateRange[1].getTime()
                        }
                        this.insert(body) // 添加
                            .then(res => {
                                this.$message({
                                    type: 'success',
                                    duration: 1000,
                                    message: "添加卡券成功!",

                                });
                                this.dialogShow = false;
                                this.getData();
                            })
                            .catch(res => {
                                this.$message.error(res.data.message)
                            })
                    }
                })
            },
            insert(body) {// 插入接口
                this.btnLoading = true
                return new Promise((resolve, reject) => {
                    axiosApi('/catering/platform/card/add', body, res => {
                        resolve(res);
                        this.btnLoading = false
                    }, 1, 10, res => {
                        reject(res);
                        this.btnLoading = false
                    })
                })
            },

            //批量导入
            handUpBefore(file) { //导入前
                this.$loading({
                    fullscreen: true,
                    lock: true,
                    text: '正在导入卡券...'
                })
                return true
            },
            handUpError(err, file, fileList) { //导入失败
                this.$loading().close();
                this.$message.error('导入失败！');
            },
            upDown(scope, status) {
                let type = status == 1 ? "启用" : "禁用";

                this.$confirm("将" + type + "该卡券, 是否继续?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                }).then(() => {
                    axiosApi('/catering/platform/card/update', {
                        id: scope.row.id,
                        status: status,
                    }, (res) => {
                        this.$message({
                            type: 'success',
                            duration: 1000,
                            message: type + "成功!",
                        });
                        this.getData();
                    }, this.currentPage, 10, (res) => {
                        this.$message.error(res.data.message)
                    })
                }).catch(() => {
                    this.$message({
                        message: "已取消" + type,
                    });
                });
            },
            handUpSuccess(res, file, fileList) { //导入成功
                console.log(res, '导入信息回调')
                this.$loading().close();
                switch (res.code) {
                    case "000000": //成功
                        let h = this.$createElement;
                        if (res.data.failedRows == 0) { //失败条数为0
                            this.$msgbox({
                                title: '提示信息',
                                message: h('p', {
                                    style: 'text-align:center'
                                }, [h('p', null, '导入已完成！'), h('p', null, `共导入信息${res.data.succeededRows}条，全部成功！`)]),
                                showCancelButton: false,
                                confirmButtonText: '确定',
                                beforeClose: (action, instance, done) => {
                                    if (action === 'confirm') {
                                        done();
                                    } else {
                                        done();
                                    }
                                    delete instance.$slots.default;
                                }
                            })
                        } else {
                            this.errorInfoFileUrl = res.data.errorInfoFileUrl;
                            this.$msgbox({
                                title: '提示信息',
                                message: h('p', {
                                    style: 'text-align:center'
                                }, [h('p', null, '导入已完成！'), h('p', null, `共导入信息${res.data.succeededRows}条，失败${res.data.failedRows}条，点击下载查看失败原因！`)]),
                                showCancelButton: true,
                                confirmButtonText: '下载失败文件',
                                cancelButtonText: '我不想知道',
                                beforeClose: (action, instance, done) => {
                                    if (action === 'confirm') {
                                        this.$refs.downImportErrForm.submit(); //下载失败原因
                                        done();
                                    } else {
                                        done();
                                    }
                                    delete instance.$slots.default;
                                }
                            })
                        }
                        this.getData(); //导入完成后刷新列表
                        break;
                    case "999999": //系统异常
                        this.$alert(res.message, '提示信息', {
                            confirmButtonText: '确定'
                        });
                        break;
                    case "AUTH_001": //用户未登陆
                        gotoLoginPage();
                        break;
                    case "AUTH_002": //用户无权限
                        this.$alert(res.message, '提示信息', {
                            confirmButtonText: '确定'
                        });
                        break;
                    case "LOGIN_005": //用户已禁用
                        this.$alert(res.message, '提示信息', {
                            confirmButtonText: '确定'
                        });
                        break;
                    case "LOGIN_006": //用户session失效
                        gotoLoginPage();
                        break;
                    default:
                        this.$alert(res.message, '提示信息', {
                            confirmButtonText: '确定'
                        });
                        break;
                }

            },
        },

    }
</script>

<style lang="less">
    #cardManage {
        .aa {
            .el-form-item__error {
                margin-left: 190px !important;
            }
        }

        .el-button {
            width: 90px;
            height: 28px;
            border-radius: 2px;
            padding: 0;
        }
        .el-form--inline .el-form-item {
            display: inline-block;
             margin-right: 0px;
            vertical-align: top;
        }

    }
</style>