<template>
	<div id="homepage" v-loading.fullscreen.lock="fullscreenLoading">
		<!--<div v-if="isOne&&!isNext" class="v-modal" style="z-index:1000 !important"></div>
		<div v-if="isOne&&!isNext" class="el-dialog__wrapper" style="z-index:1003 !important">
			<div class="width-1280">
				<div class="guide fr">
					<div class="text">点击此进入我的账户</div>
					<el-button @click="guideCount" class="mt-30 ml-100" type="danger">下一步</el-button>
				</div>
			</div>
		</div>-->
		<div class="top clearfix mb-20 width-1280 plr-45">
			<!--轮播图-->
			<div class="banner fl mt-10">
				<el-carousel v-if="advertisementList.length !=0 " :indicator-position="advertisementList.length > 1 ? '' : 'none'" arrow="hover">
					<el-carousel-item v-for='item in advertisementList' :key="item.imgUrl">
						<img @click="clickAdvertisement(item)" class="cursor" :src="item.imgUrl" :alt="item.advertisementName" />
					</el-carousel-item>
				</el-carousel>
				<img v-else style="height: 410px;width: 790px;" class="cursor" src="https://sunrise-app.oss-cn-shanghai.aliyuncs.com/template/ys/advertisement/default.png" alt="">
			</div>
			<div class="right fr mt-10">
				<div class="right-top bg-f">
					<div class="news">
						<span>公告</span>
					</div>
					<div class="ml-10 mt-10">
						<p class="text-hidden cursor mb-10 font-12 color-6" v-for='item in newsList' @click="newsInfo(item.id)" :key="item.newsTitle">{{item.newsTitle}}</p>
					</div>
				</div>
				<div class="right-bottom mt-10">
					<img class="cursor"  v-for='item in specialadvertisementList' :key="item.imgUrl" :src="item.imgUrl" :alt="item.advertisementName" @click="clickAdvertisement(item)"/>
				</div>
			</div>
		</div>
		<homeGoods class="mb-20 width-1280 plr-45"/>

		<!-- <div class="new bg-f mb-20" v-for="(n) in goodsElementsList" :key="n.title" >
			<div v-if="n.sub.length>0">
				<div class="listTitle font-30 clearfix bg-f">
					<span class="line fl"></span>
					<h3 class="fl">{{ n.title }}</h3>
					<span class="line fl"></span>
				</div>
				<div class="clearfix plr-20">
					<goodsItem v-for='item in n.sub' :key='item.skuNo' :itemData="item.skuVO" @parabolaFn="parabolaFn" @storeClick="goStore" :isIndex="true" />
				</div>
			</div>
		</div>
		<div class="show-load" v-if="isNoMore">
			<span>没有更多了</span>
		</div> -->
		<!-- <div class="adv mt-30 mb-50 width-1280">
			<img v-for='item in bottomAdvertisementList' :key="item.targetUrl" @click="clickAdvertisement(item.targetUrl)" class="cursor" :src="item.imgUrl" :alt="item.advertisementName" />
		</div> -->
		<!--修改地址弹出框-->
		<!-- <el-dialog class='cancelOrderDialog' title='请选择' :visible.sync='addressVisible' :close-on-click-modal="false" :close-on-press-escape='false'>
			<p class="tac mb-50">为了给您提供更优质的服务，请完善营业地区</p>
			<el-form v-model='addressForm' class="pl-120 pr-30" ref="cancelOrderForm">
				<el-form-item label="">
					<el-select v-model="addressForm.id" clearable ref='address' @change='getvalue' placeholder='请选择街道(镇)'>
						<el-option v-for='item in fourLists' :label='item.districtName' :value="item.id"  :key='item.districtName'></el-option>
					</el-select>
				</el-form-item>
			</el-form>
			<div slot='footer' class="dialog-footer">
				<el-button type='danger' @click='setStreetAddress'>确 定</el-button>
			</div>
		</el-dialog> -->
	</div>
</template>

<script>
	import axios from 'axios';
	import { axiosApi, basePath } from "../../api/api";
	import { mapMutations, mapState } from 'vuex'
	import goodsItem from "../../components/goodsItem.vue";
	import homeGoods from "~/components/home/homeGoods.vue";
	let clientHeight, scrollTop, offsetHeight;
	var parabola, element, target;
	export default {
		components: {
			goodsItem,
			homeGoods
		},
		data() {
			return {
				basePath: basePath,
				addressVisible: false, //修改地址弹出框
				isOne: false, //是否是首次登陆
				isNext: false, //是否点击下一步
				nava: false,
				classifyList: [],
				classifyName: '白酒',
				brandList: [],
				advertisementList: [], //轮播广告
				specialadvertisementList: [], //专题广告
				bottomAdvertisementList: [], //底部广告
				newsId: '1',
				newsList: [],
				classifyId: null,
				goodsElementsList: [{
						title: '促销商品',
						sub: []
					},
					{
						title: '新品推荐',
						sub: []
					}
				],
				fullscreenLoading: false,
				isloginUser: !sessionStorage.getItem('loginName') ? false : true,
				// isHotel: true,
				isLoad: false,
				currentPage: 1,
				isNoMore: false,
				fourLists: [], //四级地址列表
				addressForm: {
					id: '',
					name: ''
				}
			}
		},
		computed: {
			zeroShow(){
				return this.$store.state.homeSearch.zeroShow
			},
			shoppingCarNum() {
				return this.$store.state.homeSearch.shoppingCarNum
			},
			sellerId(){
				return this.$store.state.homeSearch.sellerId
			},
		},
		watch:{
			'zeroShow': function(val, oldVal) {
				this.goodsList()
			},
			sellerId(){
				this.news()
			},
		},
		methods: {
			...mapMutations({
				'mouseShow': 'mouseShow',
				'collectStoreHide': 'collectStoreHide'
			}),
			guideCount() {
				this.isOne = false;
				window.document.body.style.overflow = 'visible';
				this.$router.push(basePath + '/accountCenter');
			},
			advertisement() { //广告
				this.fullscreenLoading = true;

					let api = "/unlogin/foundation/advertisement/selectAll";
					let body = {
						isEffective: true, // 进行中的广告
					};
					axiosApi(api, body, (res) => {
						this.advertisementList = res.data.data.banner ? res.data.data.banner : []; //轮播图核心广告
						this.specialadvertisementList = res.data.data.specialSubject ? res.data.data.specialSubject.slice(0, 1) : [{imgUrl:'https://sunrise-app.oss-cn-shanghai.aliyuncs.com/template/ys/advertisement/right_default.png',advertisementName:'默认图片'}]; //右侧专题广告
						this.fullscreenLoading = false;
					}, 1, 10)
				// } 
				// else { //酒店banner
				// 	axiosApi('/trader/hotelSellerBanner/list', {
				// 		buyerId: sessionStorage.getItem('traderId')
				// 	}, (res) => {
				// 		this.advertisementList = res.data.data;
				// 		if(this.advertisementList.length > 0) this.advertisementList.map(item => {
				// 			item.targetUrl = item.url;
				// 			item.imgUrl = item.img;
				// 		})
				// 		this.fullscreenLoading = false;
				// 	}, 1, 10)
				// }
			},
			clickAdvertisement(item) {
				// general
				// goods
				// goodsSearch
				switch (item.advertisementType) {
					case 'goods':
						// 商品跳转
						var info = JSON.parse(item.advertisementKeywords)
						this.$router.push(`${basePath}/shop/goodsDetails/${info.skuNo}`)
						break;
					case 'goodsSearch':
						// 关键字搜索跳转
						// advertisementKeywords
						this.$router.push(basePath+'/goodsSearch/classify/brand/' + item.advertisementKeywords );
						// this.$router.push(`${basePath}/shop/goodsDetails/${info.skuNo}'`)
						break;
					default:
						// 普通图不处理
						break;
				}

				// if(!url) return;
				// let httpUrl = (url.indexOf('http://') == 0 || url.indexOf('https://') == 0) ? url : window.location.protocol + '//' + url;
				// window.open(httpUrl)
			},
			news() { //公告
				this.newsList = [];
				let api = "/unlogin/foundation/news/selectAll";
				let body = {
					newsTypeId: this.newsId,
					isEffective: true,
					sellerId: this.sellerId
				}
				axiosApi(api, body, (res) => {
					this.newsList = res.data.data.elements ? res.data.data.elements.splice(0, 5) : [];
				}, 1, 10)
			},
			newsInfo(id) { //进入公告详情
				this.$router.push(basePath + '/news/message/' + id)
			},
			//加载商品列表
			goodsList() {
				return
				this.fullscreenLoading = true;
				let api = this.isloginUser ? "/goods/goodsRecommendIndex/select" : "/unlogin/goods/goodsRecommendIndex/select";
				let body = {
					buyerId: sessionStorage.getItem('traderId'),
					shouldHasStore: this.zeroShow
				}
				// console.log(body)
				axiosApi(api, body, (res) => {
					if(res.data.data.promotionVo.length > 0) this.goodsElementsList[0].sub = res.data.data.promotionVo.splice(0, 10);
					if(res.data.data.recommentVo.length > 0) this.goodsElementsList[1].sub = res.data.data.recommentVo.splice(0, 10);
					this.goodsElementsList.forEach(item => {
						item.sub.length > 0 && item.sub.forEach(n => {
							n.skuVO.storeName = n.storeName;
							n.skuVO.sellerId = n.sellerId;
						})
					})
					this.fullscreenLoading = false;
				}, 1, 10, () => {
					this.fullscreenLoading = false;
				})
			},
			goStore(sellerId) {
				this.$router.push(`${basePath}/goodsSearch/classify/brand/goodsName/${sellerId}`)
			},
			parabolaFn() { //抛物线小球
				parabola.init();
			},
			getHotelGoodsList() {
				this.fullscreenLoading = true;
				const api = "/goods/hotelSkuList/list";
				const body = {
					buyerId: sessionStorage.getItem('traderId')
				}
				axiosApi(api, body, (res) => {
					if(res.data.data.length > 0) {
						res.data.data.forEach(n => {
							n.priorityPrice = n.hotelAgreementGoodsVO.discountPrice; //商品折后价
							n.price = n.hotelAgreementGoodsVO.promotionPrice; //商品会员价
						})
						setTimeout(() => {
							this.goodsElementsList[0].sub = [...this.goodsElementsList[0].sub, ...res.data.data];
						}, 0)
					} else {
						this.isNoMore = true;
					}
					this.fullscreenLoading = false;
					this.isLoad = true;
				}, this.currentPage, 20, () => {
					this.fullscreenLoading = false;
					this.isLoad = false;
				})
			},
			onScroll() {
				clientHeight = document.documentElement.clientHeight;
				scrollTop = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop; //用于FF
				offsetHeight = document.body.offsetHeight;
				// console.log(document.documentElement.clientHeight+'-----------'+window.innerHeight); // 可视区域高度
				// console.log(document.documentElement.scrollTop); // 滚动高度
				// console.log(document.body.offsetHeight); // 文档高度
				// 判断是否滚动到底部 并且开关打开则加载数据
				if(this.isLoad && ((clientHeight + scrollTop) >= (offsetHeight - (150 + 40))) && !this.isNoMore) {
					this.currentPage++;
					// console.log(this.currentPage)
					// 将开关关闭
					this.isLoad = false;
					this.getHotelGoodsList();
				}
			},
			// needStreetAddress() { //判断商户营业地址是否需要输入四级地址
			// 	axiosApi("/trader/district/needStreetAddress", {
			// 		traderId: sessionStorage.getItem('traderId')
			// 	}, (res) => {
			// 		this.fourLists = res.data.data
			// 		if(this.fourLists.length > 0) {
			// 			this.addressVisible = true
			// 			 document.body.parentNode.style.overflow = "hidden"; //禁止页面滚动
			// 		}
			// 	}, 1, 10000, () => {

			// 	})
			// },
			// getvalue(val) {
			// 	let obj = {};
			// 	this.fourLists.map(v => {
			// 		if(v.id === val) obj = v
			// 	});
			// 	// obj = this.fourLists.find((item) => {
			// 	// 	return item.id === val;
			// 	// });
			// 	this.addressForm.name = obj.districtName
			// },
			// setStreetAddress() { //设置四级地址
			// 	if(this.addressForm.id.length == 0 || !this.addressForm.id) {
			// 		this.$message({
			// 			type: 'error',
			// 			message: '请先选择街道(镇)'
			// 		})
			// 		return false;
			// 	} else {
			// 		axiosApi("/trader/address/setStreetAddress", {
			// 			traderId: sessionStorage.getItem('traderId'),
			// 			streetId: this.addressForm.id,
			// 			streetName: this.addressForm.name
			// 		}, (res) => {
			// 			this.addressVisible = false
			// 			document.body.parentNode.style.overflow = "auto";  //恢复页面滚动
			// 			this.$message({
			// 				type: 'success',
			// 				message: '设置成功!'
			// 			})
			// 		}, 1, 10000, () => {

			// 		})
			// 	}

			// },
		},
		mounted() {
			// this.isOne = sessionStorage.getItem('isOne') === '1' && this.isloginUser && (sessionStorage.getItem('userType') === '1' || sessionStorage.getItem('userType') === '2') ? true : false;
			if(document.documentElement) {
				document.documentElement.scrollTop = 0;
			} else {
				document.body.scrollTop = 0;
			}
			// this.isHotel = sessionStorage.getItem('isHotel') === '1' && this.isloginUser ? true : false;
			this.$nextTick(() => {
				// sessionStorage.getItem('traderId') && this.needStreetAddress() //获取traderId 后，去判断商户营业地址是否需要输入四级地址
				// this.getClassifyList();
				// if(this.isHotel) { //酒店用户
				// 	this.goodsElementsList = [{
				// 		title: '全部商品',
				// 		sub: []
				// 	}];
				// 	this.getHotelGoodsList();
				// } else {
					
				// }
				this.goodsElementsList = [{
						title: '促销商品',
						sub: []
					},
					{
						title: '新品推荐',
						sub: []
					}
				];
				// this.goodsList();
				
				this.news();
				this.advertisement();
				this.mouseShow();
				this.collectStoreHide();
				target = document.getElementById("shopping-empty");
				element = document.getElementById("ball");
				parabola = this.parabola(element, target, {
					speed: 700, // 每帧移动的像素大小
					curvature: 0.0006, // 实际指焦点到准线的距离
					complete: function() { //动画执行完回调
						element.style.visibility = "hidden";
					}
				}).mark();
				// setTimeout(() => {
				// 	if(this.isHotel) {
				// 		window.addEventListener('scroll', this.onScroll, false)
				// 	}
				// })
			})
		},
		// beforeDestroy() {
		// 	if(this.isHotel) {
		// 		window.removeEventListener('scroll', this.onScroll, false);
		// 	}
		// },
	}
</script>

<style lang='less'>
	/*// body{*/
	/*//   overflow: auto!important;*/
	/*// }*/
	#homepage {
		.guide {
			width: 555px;
			height: 295px;
			margin-right: 156px;
			background-image: url(../../assets/32x.png);
			background-size: cover;
			background-repeat: no-repeat;
			.text {
				margin: 180px 0 0 70px;
			}
			.ml-100 {
				margin-left: 100px;
			}
		}
		.dp {
			display: block;
		}
		/*轮播图*/
		.banner {
			margin-left: 200px;
		}
		.el-carousel__container,
		.el-carousel__container img {
			height: 410px;
			width: 790px;
		}
		.el-carousel__indicators li .el-carousel__button {
			width: 12px;
			height: 12px;
			border-radius: 6px;
			background: #FFFFFF;
			opacity: 1;
		}
		.el-carousel__indicator.is-active button {
			background: #FF4201 !important;
		}
		/*右侧公告*/
		.news{
			height: 50px;
			line-height: 50px;
			text-align: center;
			border-bottom:1px solid #eee ;
		}
		.top .right {
			.right-top {
				width: 190px;
				height: 194px;
				.el-tabs__nav {
					width: 190px !important;
				}
				.el-tabs__item {
					width: 80px !important;
					text-align: center;
				}
				.el-tabs__header {
					padding-left: 10px;
				}
				.el-tabs__item.is-active {
					color: #FF4201;
				}
				.el-tabs__active-bar {
					background: #FF4201;
					width: 80px !important;
					height: 2px;
				}
				.text-hidden {
					width: 168px;
					text-overflow: ellipsis;
					white-space: nowrap;
					overflow: hidden;
				}
				.text-hidden:hover {
					color: #FF4201;
				}
			}
			.right-bottom img {
				width: 190px;
				height: 206px;
			}
		}
		/*新品上市*/
		.new {
			width: 1190px;
			margin: 0 auto;
			.line {
				margin-top: 45px;
				display: inherit-block;
				width: 200px;
				height: 2px;
				background: #ff4201;
				border: 0
			}
			.listTitle {
				vertical-align: middle;
				width: 580px;
				margin: 0 auto;
				line-height: 90px;
				h3 {
					width: 180px;
					text-align: center;
					display: inline-block;
				}
			}
		}
		/*广告位*/
		.adv {
			img {
				width: 1190px;
				height: 240px;
			}
		}
		.show-load {
			text-align: center;
			padding-bottom: 20px;
		}
		.pl-120 {
			padding-left: 120px;
		}
		.el-dialog__headerbtn{
			display: none !important;
		}
	}
</style>
