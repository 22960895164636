<template>
  <div id="homeGoods" v-if="info.length>0">
    <div class="bg-f" style="padding:20px">
      <div class="topDivTab">
        <div class="tabDiv">
          <div v-for="(item, index) in info" :key="item.goodsTypeInt" :class="type===item.goodsType?'tabItem activateItem':'tabItem'" @click="setTab(item.goodsType,item.goodsTypeDesc)">{{item.goodsTypeDesc}}</div>
        </div>
        <div class="rightDiv">
          <span @click="()=>{$router.push(basePath+'/goodsSearch/classify/brand/goodsName?goodsType='+type+'&name='+goodsTypeDesc)}">查看更多<i class="el-icon-arrow-right"></i></span>
        </div>
      </div>
      <div class="infoDiv">
        <div v-for="item in listInfo" :key="type+item.id" class="infoItem goodsInfoItem" @click="goodsClick(item)">
          <div class="imgDiv">
            <!-- <img alt=""> -->
            <el-image class="imgItem" :src="item.fileUrl" >
              <div slot="error">
                <img class="imgItem" src="~/assets/goods.jpg" alt="">
              </div>
            </el-image>
          </div>
          <div class="bottomInfo">
            <div class="itemName ellipsis-2">
              <span class="tagClass">久加久</span>
              <span class="titleClass">{{item.skuName}}</span>
            </div>
            <!-- <div class="itemText">商品净含量500ml</div> -->
            <div class="itemPrice">
              <span v-if="item.price < 10000 ">
                  ￥{{item.price | fmoney}}
                  <span class="font-12" >/{{ item.salesUnitName}}</span>
              </span>
              <span v-else-if="item.price >= 10000">
                  ￥{{item.price/10000 | fmoney}}万
                  <span class="font-12" >/{{ item.salesUnitName}}</span>
              </span>
            </div>
            <div class="font-14 color-9" v-if="item.totalSales!=null">月售：{{item.totalSales}}</div>
          </div>

          <div class="two cursor">
              <div class="all fl pt-10" @click.stop="handCollection(item)">
                  <div :class="{'shake': CollectionActive}"><i class="iconfont icon-collection color-white" :class="{'color-danger': item.goodsCollected}"></i></div>
                  <div class="font-12 color-white" :class="{'color-danger': item.goodsCollected}">收藏</div>
              </div>
              <div class="all fl pt-10" @click.stop="isOrderGoods(item)">
                  <div :class="{'shake': carActive}"><i class="iconfont icon-shopping-empty color-white"></i></div>
                  <div class="font-12 color-white">购物车</div>
              </div>
          </div>
        </div>
        <!-- flex补位 -->
        <div v-for="item in ((listInfo.length%5>0)?5-listInfo.length%5:0)" :key="'x'+item" class="infoItem"></div>
      </div>
    </div>
    <el-dialog
            title="加入购物车"
            :visible.sync="centerDialogVisible"
            width="950px"
            class="goodsItemModelWrap"
            center>
            <div class="goodsItemModel flex">
                <div class="goodsInfoBox">
                    <div class="goodsImage">
                        <img v-if="!itemData.fileUrl" src="~/assets/goods.jpg" alt="">
                        <img v-else :src="itemData.fileUrl" />
                    </div>
                </div>
                <div class="infoWrap flex-1">
                    <div class="priceInfo">
                        <p class="text-tow-overflow color-666">{{ itemData.skuName }}</p>
                        <p class="color-999 mt-10">
                            销售价
                            <span style="color:rgb(222,71,37)" v-if="goodsSkuInfo.price < 10000 ">
                                ￥{{goodsSkuInfo.price | fmoney}}
                                <span class="font-12" >/{{ goodsSkuInfo.salesUnitName}}</span>
                            </span>
                            <span style="color:rgb(222,71,37)" v-else-if="goodsSkuInfo.price >= 10000">
                                ￥{{goodsSkuInfo.price/10000 | fmoney}}万
                                <span class="font-12" >/{{ goodsSkuInfo.salesUnitName}}</span>
                            </span>
                        </p>
                    </div>
                    <div class="choose-type clearfix">
                        <label class="fl mr-20">选择</label>
                        <div class="fl" >
                            <div v-if="itemData.skuType !== 2">
                                <el-button  @click="handlerType(2)"  class="mr-20 type_btn" :class="{ pro_type:(salesUnitType == 1 || salesUnitType == 2) }">{{ boxInfo.showTextContext }}</el-button>
                                <el-button  @click="handlerType(3)" class="type_btn" :class="{ pro_type:salesUnitType == 3 }">{{ bottleInfo.showTextContext }}</el-button>
                            </div>
                            <el-button v-else class="type_btn pro_type">{{ mealInfo.showTextContext }}</el-button>
                        </div>
                    </div>
                    <div v-if="goodsSkuInfo.disableSalesDesc" class="mt-10 color-danger cursor font-14" style="margin-left: 62px;">
                        {{ goodsSkuInfo.disableSalesDesc }}
                    </div>
                    <div class="clearfix choose-num">
                        <label class="fl mr-20">数量</label>
                        <div class="fl clearfix">
                            <div class="fl mr-20">
                                <el-input-number size="small" :disabled="!goodsSkuInfo.enableSales" :min="goodsMinNum" :max='goodsSkuInfo.goodsMaxNum' v-model.number="goodsNum" :step="1" class="fl"  ></el-input-number>
                            </div>
                            <div class="fl">
                                <span class="color-9">{{ goodsSkuInfo.minimunMoq }}{{ goodsSkuInfo.salesUnitName }}起购 | 库存{{ goodsSkuInfo.stockEnableQuantity > 99 ? '99+' : goodsSkuInfo.stockEnableQuantity }}{{ goodsSkuInfo.salesUnitName }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="footer clearfix">
                        <label class="fl mr-20"></label>
                        <el-button :disabled="(!goodsSkuInfo.enableSales||goodsNum <= 0)" class="fl ml-" type="primary" @click="handAddCar">加入购物车</el-button>
                    </div>
                </div>
            </div>    
        </el-dialog>
  </div>
</template>

<script>
import { axiosApi,basePath } from "~/api/api";
import {mapMutations, mapActions} from 'vuex'
import { goodsRecommendIndex } from "~/api/axiosInfo";
export default {
  data(){
    return {
      CollectionActive: false,
      carActive:false,
      type: 'RECENT',
      list: [
        {name:'常用商品',type: 1 },
        {name:'推荐商品',type: 2 },
        {name:'新品推荐',type: 3 },
        {name:'活动商品',type: 4 },
        {name:'必进商品',type: 5 },
      ],
      info:[],
      listData: {},
      listInfo: [],
      itemData: {},
      buyerId: sessionStorage.traderId,
      centerDialogVisible:false,
      goodsNum:0,//购买商品数量
      salesUnitType:null,//销售类型
      goodsSkuInfo:{},//商品sku详情
      boxInfo :{},//箱
      bottleInfo :{},//瓶
      mealInfo :{},//套餐
      goodsTypeDesc: '',
    }
  },
  computed: {
    zeroShow(){
      return this.$store.state.homeSearch.zeroShow
    },
    goodsMinNum(){
        let num = this.goodsSkuInfo.stockEnableQuantity < this.goodsSkuInfo.minimunMoq ? this.goodsSkuInfo.stockEnableQuantity : this.goodsSkuInfo.minimunMoq;
        return num;
    }
  },
  watch:{
    'zeroShow': function(val, oldVal) {
      this.getList()
    },
    centerDialogVisible(val){
        if(!val){
            this.salesUnitType = this.itemData.skuType !== 2 ? (this.itemData.salesUnitType == 3 ? 3 : 2) : null;
            this.goodsSkuInfo = this.salesUnitType ? ( this.salesUnitType == 2 ? this.boxInfo : this.bottleInfo ) : this.mealInfo;
            if(val.notCheckedStock){//true 不检查 false 检查
                this.goodsSkuInfo.goodsMaxNum = 99999;
            }else{
                this.goodsSkuInfo.goodsMaxNum = this.goodsSkuInfo.stockEnableQuantity
            }
            this.goodsNum = this.goodsSkuInfo.stockEnableQuantity < this.goodsSkuInfo.minimunMoq ? this.goodsSkuInfo.stockEnableQuantity : this.goodsSkuInfo.minimunMoq;
        }
    }
  },
  mounted(){
    this.getList()
  },
  methods:{
    ...mapActions({
        'setShoppingCarNum': 'setShoppingCarNum'
    }),
    setTab(type,goodsTypeDesc){
      this.type = type
      this.goodsTypeDesc = goodsTypeDesc
      this.listInfo = this.listData[type]
    },
    goodsClick(info){
      this.$router.push(`${basePath}/shop/goodsDetails/${info.skuNo}`)
      console.log(info,'商品信息')
    },
    getList(){
      /* 
      shouldHasStore
      returnCollected
      limit
      */
      var data = {
        returnCollected: true,
        shouldHasStore: this.zeroShow
      } 
      goodsRecommendIndex(data).then(res=>{
        console.log(res,'res')
        this.initList(res)
        console.log(this.info,'info')
      }).catch(err=>{

      })
    },
    initList(list){
      var info = []
      var listData = {}
      list.forEach(item=>{
        info.push({goodsType:item.goodsType,goodsTypeDesc:item.goodsTypeDesc,goodsTypeInt:item.goodsTypeInt})
        listData[item.goodsType] = item.skuList
      })
      
      this.info = info
      this.listData = listData
      // 默认第一个
      this.type = info[0].goodsType
      this.goodsTypeDesc = info[0].goodsTypeDesc
      this.listInfo = listData[this.type]
    },
    handlerType(salesUnitType){
        if(this.salesUnitType && this.salesUnitType != salesUnitType){
            this.salesUnitType = salesUnitType;
            this.goodsSkuInfo = salesUnitType == 2 ? this.boxInfo : this.bottleInfo;
            this.goodsNum = (+this.goodsSkuInfo.stockEnableQuantity < +this.goodsSkuInfo.minimunMoq )? this.goodsSkuInfo.stockEnableQuantity : this.goodsSkuInfo.minimunMoq;
              if(this.itemData.notCheckedStock){//true 不检查 false 检查
                this.goodsSkuInfo.goodsMaxNum = 99999;
            }else{
                this.goodsSkuInfo.goodsMaxNum = this.goodsSkuInfo.stockEnableQuantity
            }
        }
    },
    handAddCar(){//加入购物车
        this.carActive = true;
        let body = {
            skuNo: this.itemData.skuNo,//商品skuNo
            num: this.goodsNum,	//添加购物车数量
            buyerId: sessionStorage.traderId,//用户id
            operatorType: 1,//添加者类型：商户
            selectedSalesUnitType:this.salesUnitType//选择商品类型
        }
        axiosApi("/anon/order/cart/insert", body, (res) => {
            this.carActive = false;
            this.centerDialogVisible = false;
            this.$message({
                message: "加入购物车成功",
                type: 'info',
                duration: 1000
            });
            //抛物线
            // let element = document.getElementById("ball");
            // element.style.left = event.clientX + 'px';
            // element.style.top = event.clientY + 'px';
            // element.style.visibility = 'visible';
            // this.$emit('parabolaFn');
            let newA = res.data.data
            newA = Object.assign({},res.data.data);
            this.setShoppingCarNum(newA.countNum)
        }, 1, 10,(res)=>{
            this.carActive = false;
            this.centerDialogVisible = false;
            this.$message({
                message: res.data.message,
                type: 'info',
                duration: 1000
            });
        })
    },
    isOrderGoods(item){//检查该商品是否支持购买
        this.boxInfo =  item.boxInfo;//箱
        this.bottleInfo =  item.bottleInfo;//瓶
        this.mealInfo =  item.mealInfo;//套餐
        this.salesUnitType = item.skuType !== 2 ? (item.salesUnitType == 3 ? 3 : 2) : null;
        this.goodsSkuInfo = this.salesUnitType ? ( this.salesUnitType == 2 ? this.boxInfo : this.bottleInfo ) : this.mealInfo;

        if(item.notCheckedStock){//true 不检查 false 检查
            this.goodsSkuInfo.goodsMaxNum = 99999;
        }else{
            this.goodsSkuInfo.goodsMaxNum = this.goodsSkuInfo.stockEnableQuantity
        }
        this.goodsNum = this.goodsSkuInfo.stockEnableQuantity < this.goodsSkuInfo.minimunMoq ? this.goodsSkuInfo.stockEnableQuantity : this.goodsSkuInfo.minimunMoq;
        this.itemData = item
        let api,body;
        if(this.buyerId == this.itemData.sellerId) {
            this.$message("不能购买自己的商品");
            return
        }
        
        this.centerDialogVisible = true;
    },
    handCollection(item){//收藏商品
      this.itemData = item
      if(this.buyerId == this.itemData.sellerId) {
          this.$message({
              message: "不能收藏自己的商品",
              type: 'info',
              duration: 1000
          });
          return
      }
      this.CollectionActive = true;
      let body = [{ 
          skuNo: this.itemData.skuNo,
          buyerId: sessionStorage.traderId,
          classifyId: this.itemData.classifyId,
          brandId: this.itemData.brandId
      }];
      let api = "/trader/collectGoods/update";
      this.CollectApi(api, body);
    },
    CollectApi(api, body){
      axiosApi(api, body, (res) => {
          this.CollectionActive = false;
          this.itemData.goodsCollected = !this.itemData.goodsCollected;
          this.$message({
          message: this.itemData.goodsCollected ? "收藏成功！" : "已取消收藏！",
          type: 'success',
          duration: 1000
          });
      }, 1, 10,(res)=>{
          this.CollectionActive = false;
          this.$message({
              message: res.data.message,
              type: 'info',
              duration: 1000
          });
      })
    },
  }
}
</script>

<style lang="less">
#homeGoods{
  .topDivTab{
    display: flex;
    align-items: center;
    justify-content: space-between;
    .rightDiv{
      color: rgba(255, 66, 1, 1);
      cursor: pointer;
    }
  }
  .tabDiv{
    display: flex;
    align-items: center;
   
    .tabItem{
      font-size: 20px;
      font-weight: 500;
      color: rgba(166, 166, 166, 1);
      margin-right: 30px;
      cursor: pointer;
    }
    .activateItem{
      font-size: 24px;
      font-weight: 500;
      height: 40px;
      line-height: 40px;
      border-radius: 21.5px;
      color: #fff;
      background: rgba(255, 66, 1, 1);
      padding: 0 10px;
    }
  }
  .infoDiv{
    width: 1150px;
    display: flex;
    flex-wrap: wrap;
    justify-content:space-between;
    padding: 20px 0;
    
    .infoItem{
      width: 224px;
      margin-bottom: 10px;
    }
    .goodsInfoItem{
      box-shadow: 0 0 5px #eee;
      position: relative;
      .two {
          height: 0px;
          width: 100%;
          background: rgba(0, 0, 0, .5);
          position: absolute;
          overflow: hidden;
          top: 0;
          left: 0;
          transition: all .3s;
          .all {
              width: 50%;
              height: 50px;
              text-align: center;
          }
      }
      /*图标晃动*/
    @-webkit-keyframes shake {
        0% {
            -webkit-transform: translate(2px, 2px);
        }
        25% {
            -webkit-transform: translate(-2px, -2px);
        }
        50% {
            -webkit-transform: translate(0px, 0px);
        }
        75% {
            -webkit-transform: translate(2px, -2px);
        }
        100% {
            -webkit-transform: translate(-2px, 2px);
        }
    }
      @keyframes shake {
          0% {
              transform: translate(2px, 2px);
          }
          25% {
              transform: translate(-2px, -2px);
          }
          50% {
              transform: translate(0px, 0px);
          }
          75% {
              transform: translate(2px, -2px);
          }
          100% {
              transform: translate(-2px, 2px);
          }
      }
      .shake{
          -webkit-animation: shake 0.2s linear;
          animation: shake 0.2s linear;
      }
    }
    .goodsInfoItem:hover{
        .two{
            height: 60px;
            span span:nth-child(1){
                width: 16px;
                display: inline-block;
            }
            .all:hover i,.all:hover div{
                color: #ff4201;
            }
        }
    }
    .imgDiv{
      display: block;
      width: 224px;
      height: 224px;
      border-radius: 25px;
      margin-bottom: 10px;
      .imgItem{
        display: block;
        width: 224px;
        height: 224px;
      }
    }
    .bottomInfo{
      padding: 5px;
      box-sizing: border-box;
    }
    .itemName{
      // height: 60px;
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0px;
      line-height: 30px;
      color: rgba(56, 56, 56, 1);
      text-align: left;
      .tagClass{
        display: inline-block;
        vertical-align: middle;
        width: 36px;
        height: 17px;
        text-align: center;
        line-height: 17px;
        border-radius: 3px;
        background: rgba(255, 66, 1, 1);
        color: #fff;
        font-size: 9px;
        margin-right: 5px;

        
      }
      .titleClass{
        vertical-align: middle;
       
        // flex: 1;
        // overflow: hidden;
        // white-space: nowrap;
        // text-overflow: ellipsis;
      }
    }
    .itemText{
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0px;
      line-height: 20.27px;
      color: rgba(166, 166, 166, 1);
      text-align: left;
      vertical-align: top;
    }
    .itemPrice{
      font-size: 22px;
      font-weight: 500;
      letter-spacing: 0px;
      line-height: 31.86px;
      color: rgba(255, 66, 1, 1);
      text-align: left;
      vertical-align: top;
    }
  }
  .goodsItemModelWrap{
        min-height: auto!important;
        .el-dialog__body{
            padding-bottom: 0;
           /*  padding-left: 20px;
            padding-right: 20px; */
            box-sizing: border-box;
            .goodsItemModel{
                padding-right: 20px;
                .goodsInfoBox{
                    padding-left: 20px;
                    padding-right: 20px;
                    padding-bottom: 30px;
                    box-sizing: border-box;
                    .goodsImage img{
                        width: 300px;
                        height: 300px;
                        border-radius: 10px;
                        border:1px solid #ccc;
                        margin-right: 20px
                    }
                }
                 .infoWrap{
                     position: relative;
                     .priceInfo{
                        padding-bottom: 20px;
                        border-bottom:1px solid #ccc;
                    }
                    .choose-type,.choose-num{
                        line-height: 32px;
                        padding: 20px 0;
                        box-sizing: border-box;
                        .pro_type{
                            color:#FF4217;
                            border-color: #FF4217; 
                        }
                    }
                    .footer{
                        position: absolute;
                        bottom: 30px;
                        box-sizing: border-box;
                        /* padding-right: 40px; */
                        p{
                            &:last-child{
                                background-color: rgb(253,152,56);
                                color: #fff;
                                cursor: pointer;
                                font-size: 18px;
                                text-align: center;
                                line-height: 38px;
                            };
                            &:first-child{
                                padding: 10px;
                                text-align: right
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>