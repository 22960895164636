<template>
	<div class="change-package" v-loading="loading">
		<!--Title标题-->
		<div class="title-top bg-f mb-5">
				{{title}}
		</div>
		<!--main主体-->
		<div class="main">
			<el-form :model='form' :rules="rules" ref="ruleForm" label-width='130px'>

				<el-form-item label="套餐名称:" prop='skuName'>
					<el-input class='good_name' :maxlength='50' v-model.trim="form.skuName" placeholder="请输入套餐名称"></el-input>
				</el-form-item>

				<el-form-item label='套餐商品:' class='tar'>
					<el-button type='text' @click="addgood">添加商品</el-button>
				</el-form-item>

				<el-form-item label='' class='good_table'>
					<el-table :data='form.goodsList'>
						<el-table-column label='商品编码' align='center' prop='goodsNo'></el-table-column>
						<el-table-column label='商品名称' align='center' prop='skuName'></el-table-column>
						<el-table-column label='数量' align='center'>
							<template slot-scope="scope">
								<el-form-item :key='scope.row.id'
                                              required
									    :rules="[{ required: true, message: '请输入商品数量'},
                                        { pattern: /^[1-9]\d{0,2}$/, message: '最大支持3位整数'}]"
                                      >
									<el-input class='wp100' v-model.trim='scope.row.num'></el-input>
								</el-form-item>
							</template>
						</el-table-column>
						<el-table-column label='单位' align='center' prop='units'></el-table-column>
						<el-table-column label='规格' align='center' >
							<template slot-scope="scope">
								1*{{scope.row.specInfoNum}}
							</template>
						</el-table-column>
						<el-table-column label="操作" align="center">
							<template slot-scope="scope">
								<el-button type='text' @click="deleteGood(scope.row)">删除</el-button>
							</template>
						</el-table-column>
					</el-table>
				</el-form-item>

				<el-form-item label='套餐图片:' prop='fileList'>
					<input type="hidden" v-model="form.fileList" />
					<el-upload  :class="{'fileList': form.fileList.length >= 6}"
						action="/sunrise-gateway/oss/ossUpload" :file-list='form.fileList' list-type="picture-card"
						:on-preview="handlePictureCardPreview"
						:on-success="handleAvatarPhotoScucess"
						:before-upload="beforeAvatarUpload"
						:on-remove="handleRemove">
						<i class="el-icon-plus"></i>
					</el-upload>
					<el-dialog :visible.sync="dialogVisible" size="tiny">
						<img width="100%" :src="dialogImageUrl" alt="">
					</el-dialog>
				</el-form-item>

				<div class="mt-50 ml-55 pl-10">
					<span class="ml-20 pl-50 color-9">温馨提示：请上传890px宽的图片</span>
					<div class="clearfix">
						<label class="mr-10 fl">图文说明:</label>
						<!--<el-input type="textarea" v-model="ruForm.illustrationText" auto-complete="off" placeholder="这里是富文本"></el-input>-->
						<ueditor ref="ueeditor" v-model="form.illustrationText"></ueditor>
					</div>
				</div>

				<el-form-item label='' class='mt-30'>
					<el-button type='danger' @click='save' :loading='btnloading'>保存</el-button>
					<el-button @click="$router.push({path:'packageManage'})">取消</el-button>
				</el-form-item>
			</el-form>
		</div>

		<!-- 添加商品组件 -->
    <chooseGoods
	      v-on:closedialog="dialog1Visible = false"
	      :goodVisible="dialog1Visible"
	      :ifnotauto="true"
	      >
	  </chooseGoods>
	</div>
</template>

<script>
	import { axiosApi, basePath } from "~/api/api"
	import { mapMutations, mapActions } from 'vuex';
	import chooseGoods from './component/chooseGoods.vue'
	export default{
		components: {
      chooseGoods
    },
		data(){
			return{
				loading: false,// 加载
				title:'添加套餐',// 标题
				form:{
					fileList:[],//商品图片列表
					goodsList:[],//套餐商品列表
					illustrationText:'',// 富文本信息
				}, // 套餐form表单
				rules:{
					skuName:[{required:true,message:'请输入套餐名称'},{min:1,max:50,message:'套餐名称最多50个字符'}],
					fileList: [{required: true,message: '请上传套餐图片',type: 'array'},
					{min: 1,max: 6,type: "array",message: "请上传最多6张商品照片"}],
				}, // 表单验证规则
				dialogVisible:false, //图片预览弹窗
				dialogImageUrl:'', //图片预览URL
				dialog1Visible: false, // 添加商品浮层
				btnloading: false
			}
		},
		created(){
			this.clearwilladd()
			if (this.$route.query.skuId) {// 编辑
				this.title = '编辑套餐'
				this.mealInfo()
					.then(res=>{
						this.$set(this.form,'skuName',res.skuName);
						this.$set(this.form,'illustrationText',res.illustrationText)
						res.skuShowPictureVOs.map(i=>{
							this.form.fileList.push(Object.assign(i,{url:i.fileUrl}))
						});
						let arr = [];
                        res.skuSetInfoVOList.map(i=>{
                            arr.push(Object.assign(i,{id: i.skuId, units: i.unitName}))
                        });
                        this.initgood({willadd:arr})
                        // this.initgood(res.skuSetInfoVOList.map(i=>{
                        //     return Object.assign(i,{id: i.skuId, units: i.unitName})
                        // })),// 初始化商品列表
                        this.form.goodsList = this.goodsList// 然后和vuex里面的商品列表关联
					})
					.catch(res=>{

					})
			}else{
				this.form.goodsList = this.goodsList// 和vuex里面的商品列表关联
			}
		},
    computed:{
    	postDatagood() {//已选择商品id
	      return  {
	        data: {
	          notId: this.$store.getters.willaddid
	        },
	        currentPage: 1
	      }
	    },
	    goodsList () {// 已添加的商品列表
	    	return this.$store.state.ticket.willadd
	    }
    },
        methods:{
            ...mapMutations({
                'deleteMealGood':'deleteMealGood',
                'initgood':'initgood',
                'clearwilladd':'clearwilladd'
            }),
            ...mapActions({
          'querygoodtableData': 'queryMealData'
        }),
        mealInfo(){// 套餐详情接口
            this.loading = true
            return new Promise((resolve, reject)=>{
                axiosApi('/goods/setMeal/info/select',{
                    sellerId: sessionStorage.traderId,
                    id: this.$route.query.skuId
                },res=>{
                    this.loading = false
                    resolve(res.data.data)
                },1,10,res=>{
                    this.loading = false
                    reject(res)
                })
            })
        },
        deleteGood(row){// 删除商品
            this.$confirm('是否删除该条？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.deleteMealGood(row)// vuex 删除商品
                this.form.goodsList = this.goodsList// 和vuex里面的商品列表关联
                this.$message.success('删除成功！')
        }).catch(() => {
        });

        },
      addgood() {// 添加商品
        this.dialog1Visible = true;
        this.querygoodtableData(this.postDatagood)
      },
      mealInsert(body){// 新建套餐接口
        this.btnloading = true
        return new Promise((resolve, reject)=>{
            axiosApi('/goods/setMeal/insert',body,res=>{
                resolve(res)
                this.btnloading = false
            },1,10,res=>{
                reject(res)
                this.btnloading = false
            })
        })
      },
              mealUpdate(body){// 编辑套餐商品
                this.btnloading = true
                return new Promise((resolve, reject)=>{
                    axiosApi('/goods/setMeal/info/update',body,res=>{
                        resolve(res)
                        this.btnloading = false
                    },1,10,res=>{
                        reject(res)
                        this.btnloading = false
                    })
                })
              },
              save(){// 保存套餐商品
                this.$refs.ruleForm.validate((v)=>{
                    if (v) {
                        if (this.form.goodsList.length>0) {// 已有添加套餐商品
                            this.form.illustrationText = this.$refs.ueeditor.getContent();
                            let body = {
                                sellerId: sessionStorage.traderId,// 卖家Id
                                skuName: this.form.skuName,//套餐名称
                                skuType: 2,
                                illustrationText: this.form.illustrationText//图文说明的内容富文本
                            }
                            body.skuSetInfoParamList = this.form.goodsList.map(i=>{// 套餐包含商品列表
                                return {
                                    skuId: i.id,// 商品id
                                    skuName: i.skuName,
                                    k3GoodsNo: i.k3GoodsNo,
                                    specInfoNum: i.specInfoNum,
                                    unitName: i.units,
                                    skuNo: i.skuNo,
                                    num: i.num// 套餐单个商品数量
                                }
                            })
                            body.skuShowPictureParam = this.form.fileList.map(i=>{// 套餐商品图片
                                return {
                                    fileUrl: i.fileUrl,
                                    isDefault: 1,
                                    sort: 2
                                }
                            })
                            if (this.$route.query.skuId) { // 编辑
                                body.id = this.$route.query.skuId
                                this.mealUpdate(body)
                                    .then(res=>{
                                        this.$message.success('编辑套餐成功！')
                                      this.$router.push({path:'packageManage'})
                                    })
                                    .catch(res=>{
                                        this.$message.error(res.data.message)
                                    })
                                return false
                            }
                            this.mealInsert(body) // 添加
                                .then(res=>{
                                    this.$message.success('添加套餐成功！')
                                    this.$router.push({path:'packageManage'})
                                })
                                .catch(res=>{
                                    this.$message.error(res.data.message)
                                })
                        }else{
                            this.$message.warning('请添加套餐商品！')
                            return false
                        }
                    } else{
                        return false
                    }
                })
              },
            handlePictureCardPreview(file){// 预览图片
                this.dialogImageUrl = file.url;
                this.dialogVisible = true;
            },
            handleAvatarPhotoScucess(res,file,fileList){// 上传图片成功
                file.url=res.data[0];
                file.fileUrl=res.data[0];
                this.form.fileList  = fileList
                this.$refs.ruleForm.validateField("fileList"); //部分表单字段验证
            },
            beforeAvatarUpload(file){// 上传图片之前的验证
                return this.isUploadImg(file)
            },
            handleRemove(file, fileList){// 移除上传图片
                this.form.fileList  = fileList
            },
        }
	}
</script>

<style lang="less">
	.change-package{
		.main{
			background-color: #FFFFFF;
			padding: 30px 50px 50px 20px;
			.textBtn{
				width: auto;
			}
			.good_name{
				width: 600px;
			}
			.good_table{
				.el-form-item__content{margin-left: 65px!important;}
			}
			.edui-editor.edui-default{
				z-index: 0!important;
			}
			.el-upload--picture-card{
				width: 90px;height: 90px;
				line-height: 90px;
			}
			.cell{
	    	.el-form-item{
	    		margin-top: 10px;
	    		margin-bottom: 10px;
	    	}
				.el-form-item__content{
					margin-left: 0!important;
					.el-input{
						width: 120px;
					}
				}
			}
			.fileList {
				.el-upload.el-upload--picture-card{
					display: none;
				}
			}
			.el-upload--picture-card{
				width: 90px;
				line-height: 90px;
				height: 90px;
			}
			.el-icon-plus{
				line-height: 90px;
			}
			.el-upload-list--picture-card .el-upload-list__item{
				width: 90px;
				height: 90px;
			}
		}
	}
</style>