<template>
  <div class="hotel-home-box" v-loading="loading">
    <div class="title-top bg-f">全部客户</div>
    <div class="bg-f pb-50 mt-5 pl-30 pr-30 m-content">
      <div class="search-form-box">
        <el-form
          :inline="true"
          class="pt-30 mb-20"
          :model="form"
          ref="form"
					label-width="auto"
        >
          <el-form-item label="客户编号:">
            <el-input v-model.trim="form.traderNumber"></el-input>
          </el-form-item>
          <el-form-item label="客户名称:">
            <el-input v-model.trim="form.storeName"></el-input>
          </el-form-item>
          <el-form-item label="客户账号:">
            <el-input v-model.trim="form.loginName"></el-input>
          </el-form-item>
					<el-form-item label="K3编码:">
            <el-input v-model.trim="form.k3No" @input="form.k3No=form.k3No.replace(/[^\w\.\/]/ig,'')" clearable></el-input>
          </el-form-item>
          <el-form-item label="所属区域">
            <districtSelect v-model="form.districtId" class="mr-10"/>
          </el-form-item>
					<el-form-item>
						<el-button
							@click="handleSearch"
							icon="el-icon-search"
							class="el-button--info"
							>查询</el-button
						>
          </el-form-item>
        </el-form>
      
      </div>
      <div class="m-table-box">
        <el-table
          :data="tableData"
          tooltip-effect="dark"
          stripe
          class="table-default"
        >
          <el-table-column label="客户名称/简称" min-width="100">
            <template slot-scope="scope">
              <div style="text-align: left">
                <p class="tal">名称：{{ scope.row.storeName }}</p>
                <p class="tal">
                  简称：{{ scope.row.platformDescription || "--" }}
                </p>
                <el-tag
                  size="mini"
                  v-if="scope.row.isDisable == 1"
                  type="danger"
                  >已禁用</el-tag
                >
              </div>
            </template>
          </el-table-column>
          <el-table-column label="K3编码/状态" width="90">
            <template slot-scope="{ row }">
              <div v-if="row.k3No">{{ row.k3No }}</div>
              <span v-if="row.k3IsDisabled === null">未关联K3</span>
              <div v-else>
                <span v-if="row.k3IsDisabled">禁用</span>
                <div v-else>
                  <div>启用</div>
                </div>
              </div>
            </template>
          </el-table-column>

          <el-table-column label="客户账号/编号" min-width="100">
            <template slot-scope="scope">
              <p class="tal">{{ scope.row.loginName }}</p>
              <p class="tal">{{ scope.row.traderNumber || "--" }}</p>
            </template>
          </el-table-column>
          <el-table-column prop="groupName" label="所属分组" min-width="80">
          </el-table-column>
          <el-table-column prop="districtName" label="所属区域" min-width="90">
          </el-table-column>
          <el-table-column label="联系人/电话" min-width="80">
            <template slot-scope="scope">
              <p>{{ scope.row.linkname }}</p>
              <p>{{ scope.row.tel }}</p>
            </template>
          </el-table-column>
  
          <el-table-column label="客户类型" min-width="75">
            <template slot-scope="scope">
              <span v-if="scope.row.ctype == 1">经销商</span>
              <span v-else-if="scope.row.ctype == 2">终端</span>
              <span v-else-if="scope.row.ctype == 6">普通酒店</span>
              <span v-else-if="scope.row.ctype == 3">久加久*久柜</span>
              <span v-else-if="scope.row.ctype == 5">久加久*店中店</span>
              <span v-else-if="scope.row.ctype == 4">久加久*连锁店</span>
              <span v-else-if="scope.row.ctype == 41">久加久*连锁店/直营</span>
              <span v-else-if="scope.row.ctype == 42">久加久*连锁店/加盟</span>
            </template>
          </el-table-column>

          <el-table-column label="操作">
            <template slot-scope="scope">
              <div>
                <router-link
                  :to="`${basePath}/hotelK3Edit?id=${scope.row.id}&buyerId=${scope.row.buyerId}&type=2`"
                  class="blur mr-10"
                  >查看商品
                </router-link>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <div class="pageBox">
          <el-pagination
            class="tar"
            v-if="total > 0"
            :current-page="currentPage"
            :page-size="10"
            :total="total"
            layout="prev, pager, next, jumper"
            @current-change="handlePageChange"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* 朱 */
import { axiosApi, basePath } from "../../api/api";

import { mapMutations, mapActions } from "vuex";
import districtSelect from "@/components/districtSelect/districtSelect.vue";

export default {
  components: {
    districtSelect
  },
  data() {
    return {
     
   
      searchClassify: [], //搜索分类
      loading: false,
     

      form: {
				sellerId: sessionStorage.getItem("traderId"),
				traderNumber: '',
				storeName: '',
				loginName: '',
				k3No: '',
      },
      tableData: [],
      currentPage: 1,
      total: 0,
    };
  },
  mounted: function () {
    this.classSearch();
    this.getList();
  },
  methods: {
    //查询分类
    classSearch() {
      const api = "/goods/classifyTopList/select";
      const body = {
        classificationId: "",
      };
      axiosApi(api, body, this.classsearch);
    },
    classsearch(res) {
      this.searchClassify = res.data.data;
    },
    // 初始化 table
    getList() {
      this.loading = true;
      axiosApi(
        "/trader/findClientAllList/select",
        this.form,
        (res) => {
          let data = res.data.data;
          this.loading = false;
          if (data) {
            this.tableData = data.elements;
            this.total = data.totalCount;
          }
        },
        this.currentPage,
        10,
        (err) => {
          this.loading = false;
          this.$message.warning(err.data.message);
        }
      );
    },

    // 点击查询
    handleSearch() {
      this.currentPage = 1;
      this.getList();
    },
    // 分页change
    handlePageChange(val) {
      this.currentPage = val;
      this.getList();
    },
  },
};
</script>

<style lang="less">
    .hotel-home-box {
        .blur {
            color: #20a0ff;
            cursor: pointer;
        }

        .el-table {
            width: 100%;
        }

        .order_status {
            height: 60px;

            li {
                box-sizing: border-box;
                height: 26px;
                line-height: 26px;
                padding-left: 10px;
                padding-right: 10px;
                list-style-type: none;
                margin-top: 17px;
                margin-right: 10px;
                cursor: pointer;
                user-select: none;
                border-radius: 2px;

                &:hover {
                    color: #ff4201;
                }
            }

            .active {
                border: 1px solid #ff4201;
                color: #ff4201;
            }
        }

        .m-content {
            min-height: 100vh;

            .search-form-box {
                overflow: hidden;
                position: relative;

                .m-btn {
                    position: absolute;
                    top: 31px;
                    left: 700px;
                }
            }

            .setMoq {
                width: 105px;
            }
        }

      
    }
</style>
