<template>
	<div id="detaInfo">
		<div class="mt-50 font-14 ml-50">
			<div class="main">
				<div class="clearfix">
					<ul>
						<li class="fl mr-50">
							<div>
								<label class="mr-10">条形码：</label>
								<span class="ml-10">{{ruForm.spuVo.citm}}</span>
							</div>
						</li>
						<li class="fl mr-50">
							<label class="mr-10">分类：</label>
							<span class="ml-10">{{ruForm.classifyName}}</span>
						</li>
						<li class="fl mr-50">
							<label class="mr-10">品牌：</label>
							<span class="ml-10">{{ruForm.brandName}}</span>
						</li>
						<li class="fl mr-50">
							<label class="mr-10">计量单位：</label>
							<span class="ml-10">{{ruForm.units}}</span>
						</li>
						<li class="fl mr-50">
							<label class="mr-10">包装：</label>
							<span class="ml-10">{{ruForm.specInfo}}</span>
						</li>
						<li class="fl mr-50">
							<label class="mr-10">包装规格：</label>
							<span class="ml-10">1*{{ruForm.spuVo.specInfoNum}}</span>
						</li>
						<li class="fl mr-50" v-for="k in ruForm.parameterList" :key="k.spuParameterName">
							<div>
								<label class="mr-10">{{k.spuParameterName}}：</label>
								<span class="ml-10">{{k.spuParameterValue}}</span>
							</div>
							<!--<div class="mt-30 mainL" v-if="k.spuParameterId==13">
								<label class="mr-10">{{k.spuParameterName}}</label>
								<span class="ml-10">{{k.spuParameterValue}}</span>
							</div>-->
						</li>
					</ul>
				</div>
			</div>
			<div class="mt-50 ml-55 pl-10">
				<span class="ml-20 pl-50 color-9">温馨提示：请上传890px宽的图片</span>
				<div class="clearfix">
					<label class="mr-10 fl">图文说明:</label>
					<!--<el-input type="textarea" v-model="ruForm.illustrationText" auto-complete="off" placeholder="这里是富文本"></el-input>-->
					<ueditor ref="ueeditor" v-model="ruForm.illustrationText"></ueditor>
				</div>
			</div>
			<div v-if='isEdit' class="ml-100">
				<el-button class="ml-55 mt-50" type="danger" @click="handleSave">保存</el-button>
			</div>
		</div>
	</div>
</template>

<script>
	import { axiosApi, basePath } from '../../api/api';
	export default{
		props: {isEdit: Boolean,skuInfo: Object},
		data(){
			return{
				ruForm:{
					skuNo: null,
					classifyName: null,
					brandName: null,
					units: null,
					specInfo: null,
					spuVo: {},
					parameterList: [],
					illustrationText: '',
				}
			}
		},
		watch:{
			skuInfo(val){//商品详细信息
				this.ruForm = this.skuInfo;
			}
		},
		methods: {
			// getcommInfo(){
			// 	this.ruForm = this.skuInfo;
			// },
			handleSave(){
				//console.log(this.ruForm.illustrationText)
				setTimeout(() => {
                    this.ruForm.illustrationText = this.$refs.ueeditor.getContent();
                    // let reg =  /[^\u0020-\u007E\u00A0-\u00BE\u2E80-\uA4CF\uF900-\uFAFF\uFE30-\uFE4F\uFF00-\uFFEF\u0080-\u009F\u2000-\u201f\u2026\u2022\u20ac\r\n]/g;
                    // console.log(reg.test(this.ruForm.illustrationText))
                    // if(reg.test(this.ruForm.illustrationText)){
                    //     this.$message({
					// 		type: 'warning',
					// 		message: '请去除emoji表情'
                    //     });
                    //     return
                    // }
					const api = "/goods/skuIllustration/update";
					const body = { id:this.ruForm.illId, illustrationText: this.ruForm.illustrationText, sellerId:sessionStorage.traderId };
					axiosApi(api, body, (res)=>{
						//console.log(res,'基本信息编辑')
						this.$message({
							type: 'success',
							message: '修改成功'
						});
					}, 1, 10)
				}, 0)
			}
		},
		// mounted() {
		// 	this.$nextTick(function(){
		// 		this.getcommInfo();
		// 	})
		// }
	}
</script>

<style lang="less">
	#detaInfo{
		.pl-55{
			padding-left: 55px;
		}
		.pl-10{
			padding-left: 10px;
		}
		.ml-55{
			margin-left: 55px;
		}
		.main{
			label{
				width: 130px;
			 	height: 30px;
			 	line-height: 30px;
			 	box-sizing: border-box;
			 	text-align: right;
				display: inline-block;
			}
			span{
				width: 240px;
				display: inline-block;
			}
			.mainL span{
				width: 500px;
				vertical-align: text-top;
			}
			.el-textarea__inner{
				width: 400px;
				display: inline-block;
			}
		}
	}
</style>