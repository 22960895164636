<template>
    <div id="jicaiDetailB" class="bg-f">
        <div class="width-1280 box-con bdt">
            <div class="clearfix bg-f pt-30">
                <div class="ml-20 fl mt-20 ml-30">
                    <p class="font-16 mt8 lh-16">{{Data.name}}</p>
                    <p class="lh-16 mt-15 color-9">招标时间：{{Data.bidDateFrom | timenData}}</p>
                </div>

                <el-form v-if="Data.bidderState==-1||(Data.bidderState==2&&Data.state==1&&$route.query.ListB)"
                         class='selector fl'
                         :model='form'
                         label-width='130px'>
                    <el-select v-model.trim="form.priceId" placeholder="请选择">
                        <el-option
                                v-for="item in options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                    </el-select>
                    <el-button type='danger' @click='apply'>立即报名</el-button>
                </el-form>
                <el-form v-else-if="Data.bidderState==3" class='selector fl' :model='form'
                         label-width='130px'>
                    <el-select v-model.trim="form.priceId" placeholder="请选择">
                        <el-option
                                v-for="item in options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                    </el-select>
                    <div class="ml-20 text2 tac fr">
                        未开始
                    </div>
                </el-form>
                <div v-else-if="Data.bidderState==2" class='selector fl' label-width='130px'>

                </div>

                <div v-else-if="Data.bidderState==1||Data.bidderState==0" class='selector fl' label-width='130px'>
                    <div class="text1 pl-10 fl">满{{Data.bidderQty}}箱、每瓶{{Data.bidderPrice}}元、返利{{Data.bidderRebate}}%
                    </div>
                    <div class="ml-20 text tac fr">
                        已报名
                    </div>
                </div>

                <div class="fr mr-30 ">
                    <img class="icon" :src="src"/>
                </div>
            </div>
            <!-- 页签-->
            <div>
                <span class="text_title ml-30">招标信息</span>
                <hr class="line mt-10 ml-30"/>
            </div>
            <!-- 招标信息 -->
            <div class="bg-f mt-5">
                <div class="ml-100 pl-20 pt-50 pb-40 bg">
                    <el-form label-width='130px'>
                        <el-form-item label="招标商品:">
                            <div>
                                <img v-if="!Data.skuImage" class="fl icon-60 bd" src="../../assets/goods.jpg"/>
                                <img v-else class="fl icon-60 bd" :src="Data.skuImage"/>
                                <div class="ml-20 fl">
                                    <p class="lh-16 mt-15 color-9">{{Data.skuName}}</p>
                                    <p class="lh-16 mt-15 color-9">商品编码：{{Data.skuNo}}</p>
                                </div>
                            </div>
                        </el-form-item>
                        <el-form-item label="集采价:">
                            <span v-if="Data.bidderState==2&&!$route.query.ListB">-</span>
                            <span v-html="priceString" v-else class="fl"></span>
                        </el-form-item>
                        <el-form-item label="最低零售价:">
                            <span v-if="priceRed" class="fl">{{priceRed}}元</span>
                            <span v-else class="fl">-</span>
                        </el-form-item>
                        <el-form-item label="招标地区:">
                            <span class="fl">{{Data.allDistrictName}}</span>
                        </el-form-item>
                        <el-form-item label="招标名额:">
                            <span class="fl">{{Data.limitBitNum}}</span>
                        </el-form-item>
                        <el-form-item label="合约时间:">
                            <span class="fl">{{Data.validPeriod}}年</span>
                        </el-form-item>
                        <el-form-item v-if="Data.reason" label="驳回理由:">
                            <span class="fl">{{Data.reason}}</span>
                        </el-form-item>
                        <el-form-item label="招标说明:">
                            <div v-html="Data.illustrationContent" class="fl"/>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
            <div class="bottom-btn-apply">
                <el-form v-if="Data.bidderState==-1||(Data.bidderState==2&&Data.state==1&&$route.query.ListB)"
                         class='selectors fr'
                         :model='form' label-width='130px'>
                    <el-select v-model.trim="form.priceId" placeholder="请选择">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                    <el-button class="ml-50" type='danger' style="height: 50px" @click='apply'>立即报名</el-button>
                </el-form>
                <el-form v-else-if="Data.bidderState==3" class='selectors fr' :model='form' label-width='130px'>
                    <el-select v-model.trim="form.priceId" class="mt-10" placeholder="请选择">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                    <div class="ml-50 text2 tac fr pt-10" style="height: 38px">
                        未开始
                    </div>
                </el-form>
                <div v-else-if="Data.bidderState==2" class='selectors fr' label-width='130px'>

                </div>

                <div v-else-if="Data.bidderState!=3" class='selectors fr' label-width='130px'>
                    <div class="text1 pl-10 fl mt-10">
                        满{{Data.bidderQty}}箱、每瓶{{Data.bidderPrice}}元、返利{{Data.bidderRebate}}%
                    </div>
                    <div class="ml-50 text tac fr pt-10" style="height: 38px">
                        已报名
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {axiosApi, basePath} from "../../api/api";

    export default {
        data() {
            return {
                icon1: require("../../assets/jicai_icon_1.png"),
                icon2: require("../../assets/jicai_icon_2.png"),
                icon3: require("../../assets/jicai_icon_3.png"),
                icon4: require("../../assets/jicai_icon_4.png"),
                icon5: require("../../assets/jicai_icon_5.png"),
                type: 0,
                Data: {},
                priceString: "",
                goodsNo: null,
                priceRed: null,
                form: {
                    priceId: null,
                }
                ,
                options: [],
            }
                ;
        },
        mounted() {
            this.$nextTick(function () {
                this.getDetail();
            })
        },
        watch: {
            goodsNo: function (val) {
                this.getPriceRed()
            },
        },
        computed: {
            src() {
                let icon = "";
                switch (this.Data.state) {
                    case -1:
                        icon = this.icon2;
                        break;
                    case 2:
                        icon = this.icon1;
                        break;
                    case 0:
                        icon = this.icon3;
                        break;
                    case 1:
                        if (this.Data.bidderState == 2 && !this.$route.query.ListB) {
                            icon = this.icon5;
                        } else {
                            icon = this.icon4;
                        }
                        break;
                    default:
                        icon = null;
                }
                return icon;
            },
        },
        methods: {
            getDetail() {
                axiosApi('/trader/bid/detail/buyer', {
                    id: this.$route.query.id,
                    bidderId: this.$route.query.bidderId,
                    needAreaInfo: true,
                    bidByManager: false
                }, (res) => {
                    this.Data = res.data.data ? res.data.data : {};
                    this.goodsNo = res.data.data.skuNo
                    this.areaChange();
                }, this.currentPage, 10, (res) => {
                    this.$message.error(res.data.message)
                });

            },
            areaChange() {
                if (this.Data.bidPriceList && this.Data.bidPriceList.length != 0) {
                    this.form.priceId = this.Data.bidPriceList[0].id;
                    this.priceString = "";
                    this.Data.bidPriceList.forEach(item => {
                        this.priceString += "满" + item.qty + "箱、每瓶" + item.price + "元、返利" + item.rebate + "%<BR/>"
                        this.options.push({
                            label: "满" + item.qty + "箱、每瓶" + item.price + "元、返利" + item.rebate + "%",
                            value: item.id
                        })
                    });
                }
            },
            getPriceRed() {
                axiosApi('/superc/traderGoods/selectBySkuNo', {
                    skuNo: this.goodsNo
                }, (res) => {
                    this.priceRed = res.data.data.minSellingPrice;
                }, this.currentPage, 10, (res) => {
                    this.$message.error(res.data.message)
                });
            },
            apply() {
                let rebate;
                let bidPrice;
                let bidQty;
                if (this.form.priceId) {
                    this.Data.bidPriceList.forEach(item => {
                        if (item.id == this.form.priceId) {
                            bidPrice = item.price;
                            rebate = item.rebate;
                            bidQty = item.qty;
                        }
                    });
                } else {
                    this.$message.error("请选择价格")
                    return false
                }
                this.$confirm("您选择了" + this.Data.validPeriod + "年内进货" + bidQty + "箱以上，享受集采价为" + bidPrice + "元/瓶，返利" + rebate + "%，确定要报名吗", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                }).then(() => {
                    axiosApi('/trader/bid/submit', {
                        bidId: this.$route.query.id,
                        bidPriceId: this.form.priceId,
                        bidPrice: bidPrice,
                        bidQty: bidQty,
                        bidRebate: rebate,
                        bidByManager: false
                    }, (res) => {
                        this.$message.success('报名成功')
                        this.getDetail();
                    }, this.currentPage, 10, (res) => {
                        this.$message.error(res.data.message)
                    });
                }).catch(() => {
                    this.$message({
                        message: "已取消报名",
                    });
                });
            }
        }
    };
</script>

<style lang="less">
    #jicaiDetailB {
        .bdt {
            border-top: 1px solid #eee;
        }

        .box-con {
            padding: 30px 125px 50px 125px;
        }

        .selector {
            margin-left: 200px;
            margin-top: 20px;
        }

        .icon {
            margin-top: 20px;
            margin-right: 90px;
            width: 94px;
            height: 73px;
        }

        .text {
            width: 90px;
            height: 30px;
            line-height: 30px;
            border: 1px solid #ff4201;
            color: #ff4201;
        }

        .text1 {
            width: 240px;
            height: 30px;
            line-height: 30px;
            border: 1px solid #DDDDDD;
            color: #333333;
        }

        .text2 {
            width: 90px;
            height: 30px;
            line-height: 30px;
            border: 1px solid #DDDDDD;
            color: #333333;
        }

        .line {
            color: #EEEEEE
        }

        .text_title {
            padding: 5px 15px 5px 15px;
            border: 1px solid #DDDDDD;
        }

        .bottom-btn-apply {
            background: #FFFFFF;
            padding-right: 418px;
            box-sizing: border-box;
            box-shadow: 0px 0px 10px #000;
            margin: 0px 0px -150px 0px;
            width: 100%;
            height: 50px;
            position: fixed;
            left: 0;
            bottom: 150px;
            padding-bottom: 10px;
        }
    }
</style>