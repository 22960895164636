/**
 * Created by PPi on 2017/9/26.
 * Changed by wangmin 2020/09/14
 */

let HOST = window.location.host,
	PROTOCOL = window.location.protocol;


let hostPrefix = null
let idx = HOST.indexOf('www')
if (idx >= 0) {
	hostPrefix = HOST.substring(0, idx)
} else {
	hostPrefix = 'pre'
}


let callbackHost = null
if (hostPrefix.indexOf('d') === 0) {
	callbackHost = PROTOCOL + '//superc.test.ejiujiajiu.com'
} else {
	callbackHost = PROTOCOL + '//' + hostPrefix + 'superc.jiujiajiu.com'
}
const PROJECT =	{
	open: '/#',
	hostPrefix: PROTOCOL + '//' + hostPrefix + 'www',
	href: callbackHost,
	mcbs: PROTOCOL + '//' + hostPrefix + 'mcbs.jiujiajiu.com',
	login:PROTOCOL + '//' + hostPrefix + 'wwwlogin.jiujiajiu.com',
	main:PROTOCOL + '//' + hostPrefix + 'www.jiujiajiu.com',
	jmall:PROTOCOL + '//' + hostPrefix + 'wwwjmall.jiujiajiu.com',

	local:PROTOCOL + '//' + hostPrefix + 'www.jiujiajiu.com'	// 当前域名
}

export { PROJECT }
