<template>
<div>
    <el-tabs v-model="activeTab" @tab-click="handleTabClick">
        <el-tab-pane label="全部" name="1"></el-tab-pane>
        <el-tab-pane label="未开始" name="2"></el-tab-pane>
        <el-tab-pane label="进行中" name="3"></el-tab-pane>
        <el-tab-pane label="已结束" name="4"></el-tab-pane>
        <el-tab-pane name="5">
            <span slot="label">待审核<span v-if="activityCount" class="color-danger">({{activityCount}})</span></span>
        </el-tab-pane>
    </el-tabs>

    <el-table :data="tableData" tooltip-effect="dark" class="mt-20 table-default">
        <el-table-column label="活动名称" min-width="225">
            <template slot-scope="scope">
                <div @click="tosentdetail(scope.$index, tableData)" :style="{color: '#20a0ff',cursor: 'pointer'}">{{scope.row.activityName}}</div>
            </template>
        </el-table-column>
        <el-table-column label="有效时间" min-width="295">
            <template slot-scope="scope">
                <div>{{scope.row.gmtStart | timenyr}} 至 {{scope.row.gmtEnd | timenyr}}</div>
            </template>
        </el-table-column>
        <el-table-column label="参与商品" >
            <template slot-scope="scope">
                <div>{{scope.row.rangeType==1?"全部商品":"部分商品"}}</div>
            </template>
        </el-table-column>
        <el-table-column label="参与客户">
            <template slot-scope="scope">
                <div>{{scope.row.rangCustomerType==1?"全部客户":"部分客户"}}</div>
            </template>
        </el-table-column>
        <el-table-column label="活动状态">
            <template slot-scope="scope">
                <div>{{scope.row.status | status2}}</div>
            </template>
        </el-table-column>
        <el-table-column label="审核状态" min-width="70">
            <template slot-scope="{row}">
                <el-tooltip placement="top" v-if="row.auditState==2">
                <div slot="content">审核原因:<br/>{{row.auditReason}}</div>
                <div>{{row.auditStateName}}</div>
                </el-tooltip>
                <div v-else>{{row.auditStateName}}</div>
            </template>
        </el-table-column>
        <el-table-column label="活动类型"  min-width="100" align="center">
            <template slot-scope="scope">
              <div v-if="scope.row.activityType==4" style="width: 100%;">
                <div v-if=" scope.row.type == '1'">满额加价购</div>
                <div v-if=" scope.row.type == '2'">满件加价购</div>
                <div v-if=" scope.row.type == '3'">每满额加价购</div>
                <div v-if=" scope.row.type == '4'">每满件加价购</div>
              </div>
              <div v-else-if="scope.row.activityType==2" style="width: 100%;">
                <div v-if="scope.row.activityCutType == 1">满金额</div>
                <div v-if="scope.row.activityCutType == 2">满数量</div>
              </div>
              <div v-else style="width: 100%;">
                <div v-if=" scope.row.type == '1'">订购金额</div>
                <div v-if=" scope.row.type == '2'">订购数量</div>
                <div v-if=" scope.row.type == '3'">每满额送</div>
                <div v-if=" scope.row.type == '4'">每满件送</div>
              </div>
            </template>
        </el-table-column>
        <el-table-column label="操作" width="90">
            <template slot-scope="scope">
                <el-link type="primary" :underline="false" style="display:block;margin:5px" v-if="scope.row.auditState==1 && showButton('AUDIT',100095)" @click="audit(scope.row.id)">审核</el-link>
                <div>
                   <el-button type="text" @click="updatesent(scope.$index, tableData)" v-if="scope.row.status == '0'">编辑</el-button>
                </div>
                <div>
                   <el-button type="text" @click="deletesent(scope.$index, tableData)" v-if="scope.row.status == '0'">删除</el-button>
                </div>
                <div>
                    <el-button type="text" @click="endesent(scope.$index, tableData)" v-if="scope.row.status == '1'">终止</el-button>
                </div>
                <el-link type="primary" :underline="false" style="margin:5px" @click="copyAdd(scope.row)">复制</el-link>
            </template>
        </el-table-column>
    </el-table>

    <el-pagination class="tar" v-if='total>0'
    :current-page="currentPage"
    :page-size='10'
    :total='total'
    layout="prev , pager, next, jumper"
    @current-change="handlePageChange">
    </el-pagination>

     <!-- 审核弹框 -->
    <el-dialog title="审核" :visible.sync="dialogVisible" @close="dialogClose">
      <el-form :model="parmas" ref="parmas" :rules="rules" label-width="100px" style="margin: 0 20px">
        <el-form-item label="审核状态" prop="auditState">
          <el-select v-model="parmas.auditState" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="审核理由" prop="auditReason" :rules="{ required: parmas.auditState==2?true:false, message: '请输入审核理由', trigger: 'blur'}">
          <el-input
            type="textarea"
            :rows="2"
            placeholder="请输入审核理由"
            v-model="parmas.auditReason">
          </el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitAudit">确 定</el-button>
        <el-button @click="dialogClose">取 消</el-button>
      </div>
    </el-dialog>
</div>
</template>

<script>
import {mapActions} from 'vuex'
import { axiosApi,basePath } from '../api/api' //导入axiosApi
import { auditActivity, auditActivityCount } from "~/api/axiosInfo";

export default {
    props: ['activityType','findByBuyerId', 'skuId', 'activityName'],
    data() {
        return {
            activeTab: '1',
            currentPage: 1,
            auditState: null,
            activityCount: null,
            // 审核
            dialogVisible: false,
            parmas: {
                id: null,
                auditState: 3,
                auditReason: '',
            },
            options:[
                {value:3,label:'审核通过'},
                {value:2,label:'审核驳回'},
            ],
            rules: {
                auditState: [
                    { required: true, message: '请选择审核状态', trigger: 'change' }
                ]
            },
        }
    },
    watch: {
        activityType(val){
            this.activeTab = '1'
        }
    },
    computed: {
        tableData() {
            return this.$store.state.activity.sentlist ? this.$store.state.activity.sentlist.elements : [] 
        },
        total() {
            return this.$store.state.activity.sentlist ? this.$store.state.activity.sentlist.totalCount : 0 
        },
        postData() {
            if(this.activeTab == '2'){
                var status = '0'
            }else if(this.activeTab == '3'){
                var status = '1'
            }else if(this.activeTab == '4'){
                var status = '2'
            }else{
                var status = null
            }
            return {
                data: {
                    status: status,
                    activityType: this.activityType == 1?0:this.activityType,
                    activitySource: this.$store.state.activity.marketingCategory, //'B2B营销', '酒店营销'
                    activityName: this.activityName||'',
                    skuId: this.skuId||'',
                    findByBuyerId: this.findByBuyerId||'',
                    auditState: this.auditState
                },
                currentPage: this.currentPage
            }
        },
    },
    methods: {
        ...mapActions({
            'querysentpage': 'queryactivitypage',
        }),
        deletesent(index, rows) {
            let that = this;
            this.$msgbox({
                title: '提示信息',
                message: '您确定要删除此活动？',
                showCancelButton: true,
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                beforeClose: (action, instance, done) => {
                    if(action === 'confirm') {
                        const api = "/marketing/activity/delete";
                        const body = {
                            activityId: rows[index].activityId,
                            sellerId: sessionStorage.getItem('traderId')
                        };
                        axiosApi(api, body, function(res) {
                            that.$notify({
                                type: 'success',
                                message: '该满增活动已经成功终止！'
                            })
                            that.querysentpage(that.postData)
                            done()
                        }, 0, 10, (res)=> {
                            that.$notify({
                                type: 'warning',
                                message: '该满增活动已经终止失败！'
                            })
                            done()
                        })
                    } else {
                        done();
                    }
                }
            })
        },
        updatesent(index, rows) {
            if(this.activityType == 1){// 满赠活动
                sessionStorage.setItem('activitysentid',rows[index].activityId)
                if(rows[index].activityType == 4){// 加价换购
                  this.$router.push({path: basePath + '/activities/addwithadditional',query:{activityId: rows[index].activityId,activitySource: this.$store.state.activity.marketingCategory}})
                }else{
                  this.$router.push({path: basePath+'/activities/updatesent',query:{activitySource: this.$store.state.activity.marketingCategory}})
                }
            }else if(this.activityType == 2){
                sessionStorage.setItem('activitycutid',rows[index].activityId)
                this.$router.push({path: basePath+'/activities/updatecut', query:{activitySource: this.$store.state.activity.marketingCategory}})
            }
        },
        copyAdd(row){
            // 复制并新增
            const {id, activityType}  = row
            if(activityType==4){// 加价换购促销
                this.$router.push({path: basePath + '/activities/addwithadditional', query:{activitySource: this.$store.state.activity.marketingCategory || 1,activityId:id,type:'copy'}})
            }else if(activityType==1){// 满赠促销
                this.$router.push({path: basePath + '/activities/addacaboutenoughsent', query:{activitySource: this.$store.state.activity.marketingCategory || 1,id:id}})
            }else if(activityType==2){ // 满减
                this.$router.push({path: basePath + '/activities/addacaboutenoughcut', query:{activitySource: this.$store.state.activity.marketingCategory||1, id:id}})
            }
        },
        endesent(index, rows) {
            let that = this;
            this.$msgbox({
                title: '提示信息',
                message: '您确定要终止此活动？',
                showCancelButton: true,
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                beforeClose: (action, instance, done) => {
                    if(action === 'confirm') {
                        const api = "/marketing/activity/stop";
                        const body = {
                            activityId: rows[index].activityId,
                            sellerId: sessionStorage.getItem('traderId')
                        };
                        axiosApi(api, body, function(res) {
                            that.$notify({
                                type: 'success',
                                message: '终止活动成功'
                            })
                            that.querysentpage(that.postData)
                            done()
                        }, 0, 10, (res)=> {
                            that.$notify({
                                type: 'warning',
                                message: '终止活动失败,' + res.data.message
                            })
                            done()
                        })
                    } else {
                        done();
                    }
                }
            })
        },
        handleTabClick() {
            if(this.activeTab==5){
               // 待审核
               this.auditState = 1
            }else{
               // 其他tab
               this.auditState = null
            }
            this.currentPage = 1
            this.querysentpage(this.postData)
            this.$emit('changStatusType', this.activeTab)
        },
        handlePageChange(val) {
            this.currentPage = val
            this.querysentpage(this.postData)
            console.log('分页执行')
        },
        tosentdetail(index, row) {
            var id = row[index].id
            if(this.activityType == 1){
                sessionStorage.setItem('sentid',id)
                this.$router.push(basePath + '/activities/sentdetail')
            }else if(this.activityType == 2){
                sessionStorage.setItem('cutid',id)
                this.$router.push(basePath + '/activities/cutdetail')
            }
            
        },
        auditActivityCount(){
            var data = {
                sellerId: sessionStorage.traderId,
                sortType: this.$store.state.activity.marketingCategory,
                activityType: this.activityType == 1?0:this.activityType,
            }
            auditActivityCount(data).then(res=>{
                this.activityCount = res
            }).catch(err=>{

            })
        },
        audit(id){
            this.dialogVisible = true
            this.parmas.id = id
        }, 
        dialogClose(){
            this.dialogVisible = false
            this.parmas = {}
            this.$refs['parmas'].resetFields();
        },
        submitAudit(){
            this.$refs['parmas'].validate((valid) => {
                if (valid) {
                    auditActivity(this.parmas).then(res=>{
                        this.auditActivityCount()
                        this.querysentpage(this.postData)
                        this.dialogClose()
                    }).catch(err=>{

                    })
                } else {
                console.log('error submit!!');
                return false;
                }
            });  
        },
    },
    mounted() {
        this.$nextTick(()=>{
            this.auditActivityCount()
            // this.querysentpage(this.postData)
        })
    }
}
    
</script>