<template>
	<div id="supplier-address">
        <div v-if="!isJiuDian" class="content bg-f mt-5">
            <div class="mb-30">
                <el-button class="addAddressBtn color-danger" v-if="info.length <=50" @click="add">添加收货地址</el-button>
                <span class="font-12 color-9 ml-10">可以设置多个收货地址，最多50个；</span>
            </div>
            <addressAdd v-on:closedialog="addressCloseDialog" :addressVisible="addressDialog" :title="editStatus ? 'edit' : 'add'"></addressAdd>
            <div>
                <div class="content2" v-for="(item,index) in info" :key="'key'+index">
                    <div v-if="item.isDefault==1&&item.state == 2" class="triangle-topleft border-blue"></div>
                    <div v-if="item.state == 1" class="triangle-topleft border-red"></div>
                    <div v-if="item.state == 3" class="triangle-topleft border-gray"></div>
                    <span v-if="item.isDefault==1&&item.state == 2" class="init default">默认</span>
                    <span v-if="item.state == 1" class="init statusChecking">审核中</span>
                    <span v-if="item.state == 3" class="init statusChecking">不通过</span>
                    <el-form class='fl'>
                        <el-form-item label="收货人:">
                            {{item.linkman}}
                        </el-form-item>
                        <el-form-item label="手机/电话:">
                            {{item.tel}}
                        </el-form-item>
                        <el-form-item label='所在地区:'>
                            {{item.provinceName}}<span class="ml-10"></span>{{item.cityName}}<span class="ml-10"></span>{{item.regionName}}<span class="ml-10"></span>{{ item.streetName }}
                        </el-form-item>
                        <el-form-item label='详细地址:'>
                            {{item.addr}}
						</el-form-item>
                    </el-form>
                    <div class="fr">
                        <!--<div class="clearfix">-->
                            <!--<p v-if="item.addressType == 1">营业地址</p>-->
                            <!--<p v-if="item.addressType == 2">仓库地址</p>-->
                        <!--</div>-->
                        <div class="mt-40" v-if="item.isDefault==1">
                            <el-button type='text' v-if="(item.state == 2||item.state == 3) && showButton('EDIT',100101)" @click="edit(item)">编辑</el-button>
                            <el-button type='text' v-if="(item.state == 2||item.state == 3)"  @click="deleteAddress(item)">删除</el-button>
                        </div>
                        <div class="mt-40" v-else>
                            <el-button type='text' v-if="showButton('DEFAULT',100101)&&item.state == 2" @click="defaultSet(item)">设为默认</el-button>
                            <el-button type='text' v-if="showButton('EDIT',100101)&&(item.state == 2||item.state == 3)" @click="edit(item)">编辑</el-button>
							<el-button type='text' v-if="(item.state == 2||item.state == 3)"  @click="deleteAddress(item)">删除</el-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <jd-address v-else class="content bg-f mt-5"></jd-address>
	</div>
</template>

<script>
	import { axiosApi } from "../../api/api.js"
	import { mapMutations } from "vuex"
	import jdAddress from './jd-address.vue';
	export default {
		components : {jdAddress},
		data() {
			return {
				editStatus:false,
				addStatus:false,
				addressDialog: false,
				info: [],
				sortType:1,
				// 当前是否是 酒店用户
				isJiuDian: sessionStorage.getItem('isHotel') === '1' ? true : false,
			}
		},
		mounted() {
			this.$nextTick(() => {
				this.loading()
				this.changeHeight()
			})
		},
		methods: {
			...mapMutations({
				'userAddressInfo': 'userInfo',
				"clearItem":"clearItem"
			}),
			handleSortTabs(e){// B2B营销、酒店营销切换
				console.log(e.target.attributes.type,'00')
				if(e.target.attributes.type){
					// 改变 type的值
					this.sortType = e.target.attributes.type.value;
				}
			},
			add() {//添加收货地址
				this.clearItem()
				this.addStatus = true;
				this.editStatus = false;
				this.addressDialog = true;
			},
			addressCloseDialog() {
				this.addressDialog = false;
				this.loading();
			},
			edit(item){//编辑
				this.editStatus = true;
				this.addStatus = false;
				this.userAddressInfo(item);
				this.addressDialog = true;
			},
            deleteAddress(item){//删除
                this.$msgbox({
                    title: '提示信息',
                    message: '您确定要删除这条地址？',
                    showCancelButton: true,
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    beforeClose: (action, instance, done) => {
                        if (action === 'confirm') {
                            axiosApi('/trader/address/delete',{id:item.id },res => {
                                this.loading()
                            },1,10,err => {

                            })
                            done();
                        } else {
                            done();
                        }
                    }
                })
            },
			loading() { //默认加载数据
				axiosApi("/trader/orderDeliveryAddress/select", {
					traderId: sessionStorage.traderId
				}, res => {
					if(res.data.data) {
						this.info = res.data.data;
					}
				})
			},
			defaultSet(item) { //设为默认
				// console.log(item)
				axiosApi("/trader/address/setDefault", {
					traderId: sessionStorage.traderId,
					isDefault: 1,
					id: item.id
				}, res => {
					this.loading()
				})
			},
			changeHeight() {
				var height = document.documentElement.clientHeight;
				document.getElementsByClassName("content")[0].style.minHeight = height - 382 + "px";

			}
		}
	}
</script>

<style lang="less">
	#supplier-address {
		box-sizing: border-box;
		.title {
			padding: 30px;
			margin-bottom: 5px;
		}
		.m-content{
			min-height: 500px;
			padding-bottom: 30px;
		}
		.content {
			padding: 30px;
			/*min-height: 829px;*/
			.addAddressBtn {
				width: auto;
				padding: 10px 20px;
				height: auto;
			}
			.content2 {
				position: relative;
				.triangle-topleft {
				    width: 0;
				    height: 0;
				    border-right: 60px solid transparent;
				    position: absolute;
				    top: -1px;left: 0;
				}
				.border-blue{
					border-top: 60px solid #20a0ff;
				}
				.border-red{
					border-top: 60px solid #FF4201;
				}
				.border-gray{
					border-top: 60px solid #BBBBBB;
				}
				.init{
					position: absolute;left: 0;top: 0;
					color: #fff;letter-spacing: -0.34px;
				}
				/*审核中*/
				.statusChecking{
					transform: rotate(-45deg) translate(-6px,4px);
				}
				/*默认*/
				.default{
					transform: rotate(-45deg) translate(-0px,9px);
				}
				position: relative;
				margin-bottom: 20px;
				border: 1px solid #DDD;
				height: 160px;
				padding: 30px 30px 30px 0;
				box-sizing: border-box;
				.el-form-item {
					margin-bottom: 15px;
				}
				.el-form-item__label {
					padding: 0;
					line-height: 14px;
					height: 14px;
					padding-right: 10px;
					width: 102px;
				}
				.el-form-item__content {
					line-height: 14px;
				}
				.el-form {
					width: 750px;
				}
				p {
					width: 90px;
					height: 30px;
					line-height: 30px;
					color: #fff;
					text-align: center;
					border-radius: 2px;
					background-color: #D24C4C;
					float: right;
				}
				.el-button--text {
					width: auto;
				}
			}
		}
		.title-tabs {
			padding-bottom: 5px;
			margin-bottom: 0;
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding-right: 30px;

			ul {
				display: flex;
				align-items: center;
				padding: 22px 0 22px 30px;

				.item {
					display: flex;
					align-items: center;
				}

				li {
					font-size: 16px;
					color: #333;
					cursor: pointer;
					position: relative;

					&:hover {
						color: #FF4201;
					}

					list-style-type: none;
				}

				li.active {
					color: #FF4201;
				}
			}

			.vertical-line {
				height: 16px;
				width: 2px;
				background: #BBB;
				margin: 0 15px;

				&:last-child {
					display: none;
				}
			}

			.under-line {
				width: 50px;
				height: 2px;
				background: #FF4201;
				position: absolute;
				top: 28px;
				left: 50%;
				margin-left: -25px;
			}
		}
	}
</style>
