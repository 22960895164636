<template>
	<div id="aptitude">
		<div class="ml-50 mt-50" v-if="fromData.classifyName">
			<div class="ml-45">
				<div class=" font-12" v-model="fromData">
					<label>分类：</label><span>{{fromData.classifyName}}</span>
					<label class="ml-50">品牌：</label><span>{{fromData.brandName}}</span>
					<span v-if="fromData.authorizationStartDay!=null && fromData.authorizationEndDay!=null"><label class="ml-50">有效时间：</label><span>{{fromData.authorizationStartDay | timenData}} 至  {{fromData.authorizationEndDay | timenData}}</span></span>
				</div>
				<div class="mt-20 clearfix showImg">
					<span class="hasP">
						<el-upload action="/sunrise-gateway/oss/ossUpload" :file-list='certImg' list-type="picture-card" :on-preview="showImg" >
						</el-upload>
						<el-dialog :visible.sync="dialogVis" size="tiny">
							<img width="100%" :src="dialogImageUrl" alt="">
						</el-dialog>
					</span>
					<!--<ul>
						<li class="fl mr-10 mb-10" v-for="(item,index) in fromData.certRelationVOs" v-if="item.state==2" :key="index">
							<span class="dib" style="width: 120px;height: 90px;" @click="showImg(item)"><img :src="item.certImg" alt="" /></span>
							<el-dialog v-model="dialog" size="tiny">
								<img width="100%" :src="certImg" alt="">
							</el-dialog>
						</li>
					</ul>-->
				</div>
			</div>
		</div>
		
		<!--内容为空时显示-->
		<div class="countBlank mt-50 ml-40" v-if="fromData.classifyName==''">
			<img src="../../assets/empty.png" alt="" />
			<p class="color-9 mt-15">销售资质空空如也</p>
		</div>
	</div>
</template>

<script>
	import { axiosApi, basePath } from '../../api/api';
	export default{
		data (){
			return{
				dialogVis: false,//预览
				certImg: [],//预览地址库
				dialogImageUrl:'',//预览
				fromData: {
					classifyName: '',
					brandName: '',
					authorizationStartDay: null,
					authorizationEndDay: null,
					certRelationVOs:[],
				}
			}
		},
		methods: {
			showImg(file){
				this.dialogVis = true;
				this.dialogImageUrl = file.url;
//				this.certImg = item.certImg;
			},
			getAuthorInfo(){
				const api = "/goods/spuAuthorizationInfoBySpuNo/select";
				const body = { sellerId:sessionStorage.traderId,spuNo:this.$route.params.spuNo};
				axiosApi(api, body, (res)=>{
//					console.log(res,'经销协议')
					if(res.data.data) {
						this.fromData = res.data.data;
						this.fromData.certRelationVOs.forEach((item)=>{
							if(item.state=2){
								this.certImg.push({url:item.certImg})
							}
						})
					}
				}, 1, 10)
			}
		},
		mounted() {
			this.$nextTick(function(){
				this.getAuthorInfo();
			})
		}
	}
</script>

<style lang="less">
	#aptitude{
		.showImg li{
			width: 120px;
			height: 90px;
			img{
				width: 100%;
				height: 100%;
			}
		}
		.countBlank{
			width: 1028px;
			height: 300px;
			padding-top: 100px;
			text-align: center;
		}
	}
</style>