import { axiosApi, basePath } from "./api";

// 是否同步商品进入赠品库
export function updateIsOpenSyncGift (data={traderId: sessionStorage.getItem("traderId")}) {
  return new Promise((resolve, reject) => {
    axiosApi('/trader/sellerIsOpenSyncGift/update', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
}

// 是否对客户显示对账单数据
export function updateIsOpenBuyerFreeView (data={traderId: sessionStorage.getItem("traderId")}) {
  return new Promise((resolve, reject) => {
    axiosApi('/trader/sellerIsOpenBuyerFreeView/update', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
}