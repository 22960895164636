<template>
	<div class="step3" :class="{ 'width-1280': !hlepregister, }">
		<div v-if="hlepregister" class="title-top bg-f mb-5">
			代客注册
		</div>
		<div class="bg-f" :class="{ 'replace': hlepregister,'step': !hlepregister }">
			<div class="mb-50" v-if="!newShop">
				<i class="iconfont icon-inspector color-b font-18 mr-10"></i>
				<span class="color-6 font-16">3/3注册完成</span>
			</div>
			<div class="w420 tac">
				<i class="iconfont icon-success color-danger font-150"></i>
				<div v-if="!newShop">
					<p class="color-danger mt-30 font-30 lh-30">{{hlepregister?"代客注册成功！":"您的注册申请已提交！"}}</p>
					<p class="color-9 mt-10 font-20 lh-20">{{hlepregister?"该客户已经自动添加到您的客户列表您可以对其进一步编辑和管理":"审核将需要1-3个工作日，请耐心等待，谢谢！！"}}</p>
					<el-button v-if="hlepregister" class="mt-50" @click="goPage(0)">去编辑</el-button>
					<el-button v-if="hlepregister" class="mt-50 ml-50" @click="goPage(1)">返回列表</el-button>
				</div>
				<div v-else>
					<p class="color-danger mt-30 font-30 lh-30">您的申请信息已提交！</p>
					<p class="color-9 mt-10 font-20 lh-20">审核将需要1-3个工作日，请耐心等待，谢谢！</p>
					<el-button class="mt-50 w115" @click="goPage(2)">返回店铺管理</el-button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { basePath } from "../../api/api"
	export default {
		data() {
			return {
				newShop: this.$route.params.buyerId ? false : true,
				hlepregister: this.$route.path.indexOf('/replace') > 0 ? true : false
			}
		},
		methods: {
			goPage(type) {
				if (type == 0) {
					this.$router.push(`${basePath}/clientEdit/${this.$route.params.traderId}/0/${this.$route.params.buyerId}`)
				}
				if(type == 1) {
					this.$router.push(`${basePath}/clientList`)
				}
				if(type == 2) {
					this.$router.push(`${basePath}/storeManage`)
				}
			}
		}
	}
</script>

<style lang="less">
	.step3 {
		.step{
			padding: 50px 200px;
		}
		.w420 {
			width: 420px;
			margin: 0 auto;
		}
		.w115 {
			width: 115px;
		}
		.replace{
			padding: 30px 30px 50px;
		}
	}
</style>
