<template>
    <!--超团订单-->
    <div id="superBOrder">
        <div class="title-top bg-f mb-5">
            <div class="clearfix black">
                <el-breadcrumb class="fl ">
                    <el-breadcrumb-item>订单查询</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
        </div>
        <div class='bg-f'>
            <div class="ml-30 mr-30 second-title clearfix">
                <div class="choose-three font-14 color-6 fl tac mr-10 cursor " :class="first == 0?'actived':''"
                     @click="getStatus(0)">
                    全部订单
                </div>
                <div class="choose-three font-14 color-6 tac fl mr-10 cursor"
                     :class="first == 1?'actived':''" @click="getStatus(1)">
                    进行中
                </div>
                <div class="choose-three font-14 color-6 tac fl cursor"
                     :class="first == 2?'actived':''" @click="getStatus(2)">
                    已完成
                </div>
            </div>
        </div>
        <div class="content bg-f pl-30 pr-30 pb-50">
            <div class="clearfix search">
                <el-form :inline='true' class="pt-30 fl" :model="form">
                    <el-form-item label=' 订单编号'>
                        <el-input v-model.trim="form.orderSn" :maxlength="30" placeholder="请输入订单编号" @input="form.orderSn=form.orderSn.replace(/[\u4e00-\u9fa5]/ig,'')"></el-input>
                    </el-form-item>
                    <el-form-item label='商品名称'>
                        <el-input v-model.trim="form.itemName" :maxlength="30" placeholder="请输入商品名称"></el-input>
                    </el-form-item>
                    <el-form-item label='买家名称'>
                        <el-input v-model.trim="form.storeName" :maxlength="30" placeholder="请输入买家名称"></el-input>
                    </el-form-item>
                    <el-form-item label='订单状态'>
                        <el-select v-model="form.orderStatus" placeholder="请选订单状态" clearable>
                            <el-option
                                    v-for="item in options"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label='下单时间'>
                        <ysDaterangePicker v-model="form.orderDateRange"/>
                    </el-form-item>
                    <el-form-item label='关联超B'>
                        <el-select v-model="form.superbAccountId" clearable filterable
                                   :filter-method="getRecommender"
                                   placeholder="请输入关联超级B">
                            <el-option
                                    v-for="item in searchRecommender"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
                <el-button class="fl ml-30 mt-30" type='info' @click="queryOrderList">查询</el-button>
            </div>

            <div>
                <el-table :data="groupList" stripe class='table-default font-12'>
                    <el-table-column label='订单编号' prop="parentOrderSn" width="150"></el-table-column>
                    <el-table-column label='买家' prop="buyerName"></el-table-column>
                    <el-table-column label="订单金额">
                        <template slot-scope="scope">
                            <span>￥{{scope.row.needPayMoney | fmoney}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label='下单时间' width="150">
                        <template slot-scope="scope">
                            {{ scope.row.gmtCreate | time}}
                        </template>
                    </el-table-column>
                    <el-table-column label='佣金'>
                        <template slot-scope="scope">
                            <span>￥{{scope.row.commission | fmoney}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="一级补贴">
                        <template slot-scope="scope">
                            <span>￥{{scope.row.subsidyOne | fmoney}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label='二级补贴'>
                        <template slot-scope="scope">
                            <span>￥{{scope.row.subsidyTwo | fmoney}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label='状态'>
                        <template slot-scope="scope">
                            <span v-if="scope.row.orderStatus == 1">待付款</span>
                            <span v-if="scope.row.orderStatus == 2">待发货</span>
                            <span v-if="scope.row.orderStatus == 3">待收货</span>
                            <span v-if="scope.row.orderStatus == 4">已完成</span>
                            <span v-if="scope.row.orderStatus == 0">已关闭</span>
                        </template>
                    </el-table-column>
                    <el-table-column label='操作' width="80">
                        <template slot-scope="scope">
                            <el-button type='text' @click="toDetails(scope.row)">订单详情</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination class="tar mt-20" v-if="groupList.length>0" :current-page="currentPage" :page-size='10'
                               :total='total' layout="prev , pager, next, jumper" @current-change="handleCurrentChange">
                    <!-- 分页组件 -->
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
    import {axiosApi, basePath} from "../../api/api"

    export default {
        data() {
            return {
                form: {
                    superbOrderStatus: null,
                    orderSn: null,
                    itemName: null,
                    storeName: null,
                    orderStatus: null,
                    superbAccountId: null,
                    orderDateRange: [],
                },
                searchRecommender: [], //搜索分类
                options: [{
                    value: '1',
                    label: '待付款'
                }, {
                    value: '2',
                    label: '待发货'
                }, {
                    value: '3',
                    label: '待收货'
                }, {
                    value: '4',
                    label: '已完成'
                }, {
                    value: '0',
                    label: '已关闭'
                }],
                loading: false,
                currentPage: 1,
                total: null,
                first: 0,
                groupList: []
            }
        },
        mounted() {
            this.$nextTick(() => {
                this.queryOrderList();
            })
        },
        methods: {
            handleCurrentChange(val) { //分页
                this.currentPage = val;
                this.queryOrderList();
            }
            ,
            //查询客户
            getRecommender(query) {
                if (!query) {
                    return false
                }
                let url = '/superb/account/list';
                let body = {
                    name: query,
                };

                axiosApi(url, body, res => {
                    this.searchRecommender = res.data.data.elements;
                }, 1, 100)

            },
            queryOrderList(bool) {
                if (bool && this.currentPage != 1) {
                    this.currentPage = 1;
                    return
                }
                this.loading = true;
                let url = '/superb/order/sellerSelectPage';
                let body = {
                    sellerId: sessionStorage.traderId,
                    traderType: 7,
                    superbOrderStatus: this.form.superbOrderStatus,
                    orderSn: this.form.orderSn ? this.form.orderSn : null,
                    itemName: this.form.itemName ? this.form.itemName : null,
                    storeName: this.form.storeName ? this.form.storeName : null,
                    orderStatus: this.form.orderStatus ? this.form.orderStatus : null,
                    superbAccountId: this.form.superbAccountId ? this.form.superbAccountId : null,
                    gmtOrderStart: this.form.orderDateRange[0] ? this.form.orderDateRange[0].getTime() : null,
                    gmtOrderEnd: this.form.orderDateRange[1] ? this.form.orderDateRange[1].getTime() : null,
                }

                axiosApi(url, body,
                    (res) => {
                        this.loading = false;
                        this.groupList = res.data.data.elements;
                        this.total = res.data.data.totalCount;
                    }, this.currentPage, 10, (res) => {
                        this.loading = false;
                        this.$message({
                            type: 'error',
                            message: res.data.message,
                            duration: 1000
                        })
                    }
                )
            },
            toDetails(item) {
                window.open(basePath + "/superB/superBOrderDetails/" + item.parentOrderSn + "/"+ 1 + "/" + '1', "_self");
            },
            //tab切换new
            getStatus(e) {
                this.first = e;
                this.setStatus(e);
                this.queryOrderList(true)
            }
            ,
            setStatus(e) {

                if (e == 0) {
                    this.options = [{
                        value: '1',
                        label: '待付款'
                    }, {
                        value: '2',
                        label: '待发货'
                    }, {
                        value: '3',
                        label: '待收货'
                    }, {
                        value: '4',
                        label: '已完成'
                    }, {
                        value: '0',
                        label: '已关闭'
                    }]
                } else if (e == 1) {
                    this.options = [{
                        value: '1',
                        label: '待付款'
                    }, {
                        value: '2',
                        label: '待发货'
                    }, {
                        value: '3',
                        label: '待收货'
                    }]
                } else if (e == 2) {
                    this.options = [{
                        value: '4',
                        label: '已完成'
                    }]
                }
                this.form.orderStatus = null;

                if (e == '0') { //全部订单
                    this.form.superbOrderStatus = null;
                } else {
                    switch (e) {
                        case 1:
                            this.form.superbOrderStatus = 1;
                            break;
                        case 2:
                            this.form.superbOrderStatus = 2;
                            break;
                    }
                }
            }
        }
    }
</script>

<style lang="less">
    #superBOrder {

        .table-default .el-button {
            width: auto;
        }

        .content {
            .el-date-editor--daterange.el-input {
                width: 240px;
            }

            .el-form--inline {
                width: 650px;

                .el-form-item__label {
                    font-size: 12px;
                    width: 60px;
                }
            }

            .order-datatimerange {
                width: 240px;
            }

            .checkedAll {
                position: relative;
                top: 30px;
            }

            .el-checkbox {
                width: 70px;
                margin-left: 30px;

                .el-checkbox__input {
                    height: 20px;
                }
            }

            .el-checkbox__inner {
                width: 20px;
                height: 20px;
            }

            .el-checkbox__inner::after {
                top: 2px;
            }

            .table-item {
                .bl {
                    border-left: 1px solid #ddd;
                }

                border: 1px solid #dddddd;
                font-size: 14px;
                border-bottom: none;

                .table-item-content .pbl-20 {
                    padding: 0px 0px 20px 20px;
                }

                .table-item-top {
                    padding: 10px 0;

                    .table-item-top-checkbox {
                        margin-left: 14px;
                    }
                }

                img {
                    width: 80px;
                    height: 80px;
                }
            }

            .w100 {
                width: 100px;
            }

            .table-item-default {
                height: 530px;
                padding-top: 120px;
            }

            .el-dialog {
                min-height: 300px;

                .el-form-item__label {
                    width: 140px;
                }
            }

            .sendGoods .el-form-item__error {
                margin-left: 140px;
            }
        }

        .actived {
            border-radius: 2px;
            border: 1px solid #FF4201;
            color: #FF4201;
        }

        .second-title {
            height: 60px;
            padding: 17px 0px 17px 0px;
            box-sizing: border-box;
            border-bottom: 1px solid #EEEEEE;
        }

        .choose-three {
            width: 114px;
            height: 26px;
            line-height: 26px;
        }
    }
</style>