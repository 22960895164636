<template>
    <div class="setOrderBox pb-20"> 
        <div class="title-top bg-f">
            订单消息设置
        </div>
        <div class="mContent bg-f">
            <ul class="list">
                <li v-for="(item,index) in list" :key="index">
                    <div class="left">
                        <p>{{item.typeName}}</p>
                        <template v-if="item.accepterVo.length > 0">
                            <div v-if="v.accepterValueName" class="flex" v-for="(v,k) in item.accepterVo" :key="k">
                                <span class="title">
                                    {{
                                        v.accepterType === 1 ? '特定员工：' :
                                        v.accepterType === 2 ? '部门员工：' : '特定角色：'
                                    }}
                                </span>
                                <span class="text">{{v.accepterValueName.join('，')}}</span>
                            </div>
                        </template>
                        <template v-else>
                            <div class="flex">
                                <span class="title">接收对象：</span>
                                <span class="text">暂无</span>
                            </div>
                        </template>
                    </div>
                    <div class="medit">
                        <span class="el-icon-edit" @click="handleEdit(item)"></span>
                    </div>
                </li>
            </ul>
        </div>
        <!-- 编辑 浮层 -->
        <el-dialog
        title="订单消息设置"
        :visible.sync="dialogVisible"
        @close="handleDialogClose">
        <div class="mDialogContent">
            <el-form :model="form" :rules="ruleForm" ref="form" label-width="80px">
                <el-form-item label="订单类型：">
                    <span>{{typeName}}</span>
                </el-form-item>
                <!-- 接收对象 -->
                <el-form-item label="接收对象：">
                    <div>
                        <!-- 特定员工 -->
                        <div>
                            <el-checkbox v-model="form.tdVal">特定员工</el-checkbox>
                            <div v-if="form.tdVal === true">
                                <el-input 
                                    placeholder="请输入手机号" 
                                    :style="{width : '360px',marginTop : '10px'}" 
                                    v-model.trim="form.tdText" 
                                />
                                <p class="color-6">
                                    直接输入手机号，多个手机号用英文逗号“,”隔开
                                </p>
                            </div>
                        </div>
                        <!-- 部门员工 -->
                        <div class="mt-10">
                            <el-checkbox v-model="form.bmVal">部门员工</el-checkbox>
                            <div v-if="form.bmVal === true" class="treeBox">
                                <div class="qyTreeBox">
                                    <!-- 部门树 -->
                                    <el-tree
                                        ref="bmTree"
                                        :check-strictly="true"
                                        :props="{
                                            value: 'id',
                                            label: 'text',
                                            children:'sub'
                                        }"
                                        :data="form.bmCustomerData"
                                        show-checkbox
                                        node-key="id"
                                        :default-expand-all="true"
                                        :expand-on-click-node="false"
                                        :render-content="renderContent2"
                                    >
                                    </el-tree>
                                </div>
                            </div>
                        </div>
                        <!-- 特定角色 -->
                        <div class="mt-10">
                            <el-checkbox v-model="form.jsVal">特定角色</el-checkbox>
                            <div v-if="form.jsVal === true" class="treeBox">
                                <div>
                                    <!-- 角色树 -->
                                    <el-tree
                                        ref="jsTree"
                                        :props="{
                                            value: 'id',
                                            label: 'text',
                                            children:'subDistrictList'
                                        }"
                                        :data="form.jsCustomerData"
                                        show-checkbox
                                        node-key="id"
                                        default-expand-all
                                        :expand-on-click-node="false"
                                    >
                                    </el-tree>
                                </div>
                            </div>
                        </div>
                    </div>
                </el-form-item>
                <el-form-item label="消息内容：">
                    <el-input
                     :style="{width : '360px'}" type="textarea"
                     :autosize="{ minRows: 4, maxRows: 4}" 
                     :disabled="true"
                     v-model.trim="form.messageContent"></el-input>
                </el-form-item>
            </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="handleDialogClose">取 消</el-button>
            <el-button type="primary" @click="handleSubmit">确 定</el-button>
        </span>
        </el-dialog>
    </div>
</template>

<script>
import { axiosApi, basePath } from "../../../api/api";

import { getBm, getJs } from '~/api/messageAjaxApi';

export default {
    data: function() {
        return ({
            dialogVisible : false,
            form : {
                // 特定 多选框
                tdVal : false,
                // 特定 文本输入
                tdText : '',
                // 部门 多选框
                bmVal : false,
                // 部门 数据
                bmCustomerData : [],
                // 角色 数据
                jsCustomerData : [],
                // 角色 多选框
                jsVal : false,
                // 消息内容
                messageContent : '',
            },
            // 编辑 title
            typeName : '',
            ruleForm : {},
            list : [],
        })
    },
    mounted: function() {
        // 获取 部门
        getBm((data) => {
            this.form.bmCustomerData = data;
        });
        // 获取 角色
        getJs((data) => {
            this.form.jsCustomerData = data;
        });
        this.getList();
    },
    methods: {
        // 获取列表数据
        getList : function(){
            let sendUrl = '/foundation/messageSetSelectList/select';
            let sendObj = {
                traderId: sessionStorage.traderId
            };
            axiosApi(sendUrl,sendObj, (res) => {
                let data = res.data;
                /* var arr = [{data : '[{"a":20}]'}];
                arr = arr.map(function(v,k){
                    v.data = JSON.parse(v.data);
                    return v;
                }); */
                this.list = data.data;
            });
        },
        handleEdit : function(item){
            this.dialogVisible = true;
            this.initEidt(item);
        },
        // 编辑 回显数据
        initEidt : function(item){
            this.form.messageContent = item.messageContent;
            // 记录当前编辑的 id
            this.id = item.id;
            this.typeName = item.typeName;
            let data = item.accepterVo;
            data.map((v,k) => {
                // 特定员工
                if(v.accepterType === 1){
                    this.form.tdVal = true;
                    this.form.tdText = v.accepterValueName.join(',');
                // 部门员工
                }else if(v.accepterType === 2){
                    this.form.bmVal = true;
                    this.$nextTick(() => {
                        this.$refs.bmTree.setCheckedKeys(v.accepterValue);
                    });
                // 特定角色
                }else if(v.accepterType === 3){
                    this.form.jsVal = true;
                    this.$nextTick(() => {
                        this.$refs.jsTree.setCheckedKeys(v.accepterValue);
                    });
                }
            });
        },
        // 关闭 浮层
        handleDialogClose : function(){
            this.dialogVisible = false;
            this.tableReset();
        },
        // 浮层 点击确定
        handleSubmit : function(){
            this.$refs.form.validate((valid) => {
                if (valid) {
                    // 通过 校验
                    let verifyData = this.verifyFn();
                    if(verifyData){
                        let sendUrl = '/foundation/messageSet/update';
                        let sendObj = {
                            id : this.id,
                            accepterCode : verifyData,
                        };
                        axiosApi(sendUrl,sendObj,(res) => {
                            let data = res.data;
                            this.$message.success('修改成功');
                            this.getList();
                            this.handleDialogClose();
                        },1,10,(err) => {
                            this.$message.warning(err.data.message);
                        });
                    }
                }
            });
        },
        // 校验表单数据
        verifyFn : function(){
            let form = {...this.form};
            // 数据集合
            let accepterCode = [];

            if(this.form.tdVal === true){
                if(!form.tdText){
                    this.$message.warning('请输入手机号');
                    return;
                        // !/^1[3578][0-9]{9}(,1[3578][0-9]{9})*$/
                }else if(!/^1[0-9][0-9]{9}(,1[0-9][0-9]{9})*$/.test(form.tdText)){
                    this.$message.warning('手机号格式有误');
                    return;
                }else{
                    accepterCode.push({
                        accepterType : 1,
                        accepterValue : form.tdText.split(','),
                        accepterValueName : form.tdText.split(','),
                    });
                }
            }
            if(this.form.bmVal === true){
                let v = this.$refs.bmTree.getCheckedNodes();
                if(v.length === 0){
                    this.$message.warning('请选择部门');
                    return;
                }else{
                    let ids = []; let names = [];
                    v.map((v,k) => {
                        ids.push(v.id);
                        names.push(v.text);
                    });
                    accepterCode.push({
                        accepterType : 2,
                        accepterValue : ids,
                        accepterValueName : names,
                    });
                }
            }
            if(this.form.jsVal === true){
                let v = this.$refs.jsTree.getCheckedNodes();
                if(v.length === 0){
                    this.$message.warning('请选择角色');
                    return;
                }else{
                    let ids = []; let names = [];
                    v.map((v,k) => {
                        ids.push(v.id);
                        names.push(v.text);
                    });
                    accepterCode.push({
                        accepterType : 3,
                        accepterValue : ids,
                        accepterValueName : names,
                    });
                }
            }
            return accepterCode;
            
        },
        // 重复 浮层表单数据
        tableReset : function(){
            this.form.tdVal = false;
            this.form.bmVal = false;
            this.form.jsVal = false;
            this.form.tdText = '';
        },
        renderContent2 : function(h, { node, data, store }){
            return (
                <span class="mlist">
                    <span title={data.text} class="mtext">
                        {data.text}
                    </span>
                </span>
            )
        },
    },
}
</script>

<style lang="less">

.setOrderBox {
    .mContent{
        margin-top: 5px;
        .list{
            overflow: hidden;
            padding: 30px;
            li{
                position: relative;
                margin-bottom: 15px;
                padding: 15px;
                border: 1px solid #ccc;
                overflow: hidden;
                .left{
                    float: left;
                    width: ~"calc(100% - 18px - 50px)";
                    p{
                        padding-bottom: 10px;
                    }
                    /* 经典 布局 */
                    .flex{
                        display: flex;
                        .title{
                            display: inline-block;
                            text-overflow:ellipsis;
                            white-space:nowrap
                        }
                        .text{
                            display: inline-block;
                            white-space: pre-wrap;
                            word-wrap: break-word;
                            word-break: break-all;
                        }
                    }
                }
                .medit{
                    position: absolute;
                    top: ~"calc(50% - 10px)";
                    left: ~"calc(100% - 36px)";
                    span{
                        cursor: pointer;
                        font-size: 18px;
                    }
                }
            }
        }
    }
    /* 浮层 样式 */
    .mDialogContent{
        .treeBox{
            width: 360px;
            max-height: 240px;
            padding: 5px 0;
            overflow-y: auto;
            border: 1px solid #d1dbe5;
            margin-top: 10px;
            .el-tree{
                border: none;
            }
        }
        /* 区域树 样式 部门树 样式 */
        .qyTreeBox{
            overflow: hidden;
            /* 暂无数据 样式 */
            .el-tree__empty-text{
                position: initial;
            }
            .mlist{
                display: inline-block;
                    .mtext{
                        display: inline-block;
                        max-width: 100px;
                        overflow:hidden;
                        text-overflow:ellipsis;
                        white-space:nowrap
                    }
                }
                /* 重写 tree样式 */
                .el-tree{
                    float: left;
                }
                .el-tree-node__content{
                    /* min-width: 230px; */
                }
                .el-tree-node__content>.el-checkbox{
                    vertical-align: middle;
                    display: inline-block;
                }
                .el-tree-node__content:hover{
                    background : initial;
                }
                .el-tree-node__expand-icon{
                    display: inline-block;
                }
                .el-tree-node__content .handle{
                    opacity: 0;
                    display: inline-block;
                    margin-bottom: 28px;
                    vertical-align: middle;
                }
                .el-tree-node__content:hover .handle{
                    opacity: 1;
                }
            }
        }
    .el-dialog__body{
        padding: 30px 20px;
        padding-bottom: 86px;
    }
}

</style>

