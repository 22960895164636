<template>
	<div id="storeDistribution">
		<div class="title-top bg-f">门店分布</div>
        <div class="map_content bg-f mt-5">
            <!-- 注册门店分布画布 -->
            <store-map></store-map>
        </div>
	</div>
</template>
<script>
    import storeMap from './storeMap.vue';
	import { axiosApi, basePath } from "../../../api/api";
    import { setTimeout } from 'timers';
	export default {
		data() {
			return {
                
			}
		},
		//进入加载
		mounted() {
			this.$nextTick(function(){

            })
		},
		methods: {

        },
        components: {
            storeMap
        }
	}
</script>

<style lang="less">
	#storeDistribution {
        .map_content{

        }
	}
</style>