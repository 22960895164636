<template>
	<div id="groupConsignment" class="pb-50">
		<div class="bg-f black font-16 color-danger">
			发货
		</div>
		<div class="main bg-f mt-5 pb-50">
			<p class="color-danger font-16">第一步：确认收货信息</p>
			<!--商品列表-->
			<div class="mt-20 mr-35">
				<el-table :data="goodsInfo.grouponOrderItemVOs" stripe>
					<el-table-column label='序号' align="center" type="index" width="100">
					</el-table-column>
					<el-table-column label='图片' align="center">
						<template slot-scope="scope" style="position: relative;">
							<img v-if='scope.row.skuImage' :src="scope.row.skuImage" />
							<img v-if='scope.row.giftNum' src="../../assets/superGroup/complimentary.png" style="position: absolute;top: 10px;left: 42px;" />
						</template>
					</el-table-column>
					<el-table-column label='商品编号' align="center">
						<template slot-scope="scope">
							<span>{{scope.row.goodsNo ? scope.row.goodsNo : scope.row.k3GoodsNo}}</span>
						</template>
					</el-table-column>
					<el-table-column label='商品名称' align="center">
						<template slot-scope="scope">
							<span>{{scope.row.skuName}}</span>
						</template>
					</el-table-column>
					<el-table-column label='单价' align="center">
						<template slot-scope="scope">
							<span>￥{{scope.row.originPrice||0 | fmoney}}</span>
						</template>
					</el-table-column>
					<el-table-column label='数量' align="center">
						<template slot-scope="scope">
							<span>{{scope.row.num ? scope.row.num : scope.row.giftNum}}</span>
						</template>
					</el-table-column>
					<el-table-column label='金额小计' align="center">
						<template slot-scope="scope">
							<span>{{scope.row.discountTotalPrice ? scope.row.discountTotalPrice : 0}}</span>
						</template>
					</el-table-column>
				</el-table>
				<div class="price clearfix">
					<div class="fl">
						共<span>{{goodsInfo.skuTotalNum}}</span>件商品
					</div>
					<div class="fl ml-20">
						<p class="mb-15">
							<span>订单金额：</span>
							<span>&yen;{{goodsInfo.goodsTotalMoney}}</span>
						</p>
						<p>
							<span>实付金额：</span>
							<span>&yen;{{goodsInfo.needPayMoney}}</span>
						</p>
					</div>
				</div>
			</div>
			<p class="address"><span class="font-12">买家收货地址</span>：<strong>{{goodsInfo.addrProvince}}{{goodsInfo.addrCity}}{{goodsInfo.addrRegion}}{{goodsInfo.addr}}</strong><strong class="ml-30">{{goodsInfo.addrUsername}}</strong><strong class="ml-30">{{goodsInfo.addrMobile}}</strong></p>
			<p class="color-danger font-16">第二步：确认发货信息</p>
			<div class="address mb-20 clearfix">
				<p class="fl">
					<span>我的发货地址</span>：
					<span v-if='ee'>
						<span>{{addressDefalut.provinceName}}{{addressDefalut.cityName}}{{addressDefalut.regionName}}{{addressDefalut.attr}}</span>
					<span class="ml-30">{{addressDefalut.linkman}}</span>
					<span class="ml-30">{{addressDefalut.tel}}</span>
					</span>
					<span v-else>
						请新增发货地址
					</span>
				</p>
				<p class="fr mr-100 cursor" @click='changeAddress(1,"add")'>
					<span class='color-blue'>新增发货地址</span>
				</p>
				<p :class='addressLists.length == 1?"cc":"bb"' class="fr mr-30 cursor" @click='changeAddress(0,"edit")'>
					<span class='color-blue'>编辑</span>
				</p>
			</div>
			<div class="address-list bd" v-show='up'>
				<template>
					<p class="mb-10 clearfix aa" v-for='(i,index) in addressLists' :key='index'>
						<el-radio-group class="radio fl" v-model='addressId'>
							<el-radio :label='i.id' @change.native='changeAddressList()'>
								<span>{{i.provinceName}}{{i.cityName}}{{i.regionName}}{{i.attr}}</span>
								<span class="ml-30">{{i.linkman}}</span><span class="ml-30 mr-30">{{i.tel}}</span>
							</el-radio>
						</el-radio-group>
						<span class="bb fl">
							<span class='color-blue mr-20 cursor' v-if='i.isDefault == 0' @click='setDefault(index)'>设为默认</span><span @click='changeAddress(index,"edit")' class='color-blue mr-20 cursor'>编辑</span><span class='color-blue cursor' @click='deleteAddress(index)'>删除</span>
						</span>
					</p>
				</template>
			</div>
			<div class='clearfix ml-43 mt-15 cursor bb' :class='dd==true?"zz":""' @click='up = !up'>
				<p class='fl font-12'>更多地址</p>
				<img v-if='!up' class='fl' style="margin-top: 3px;margin-left: 8px;" src="../../assets/spread.png" />
				<img v-else class='fl' style="margin-top: 3px;margin-left: 8px;" src="../../assets/up.png" />
			</div>
			<p class="color-danger font-16 mt-40">第三步：选择物流信息</p>
			<div class="mt-43 ml-43 choose">
				<template>
					<el-radio-group v-model="radio" @change='change()'>
						<el-radio :label="1" v-if="entry">快递配送</el-radio>
						<el-radio :label="2">自己联系物流</el-radio>
						<el-radio :label="3">商家配送</el-radio>
					</el-radio-group>
				</template>
			</div>
			<div class="logistics bd ml-43 mt-15 pt-10" :class="radio==3?'disabledLogistics':''" style="position: relative;">
				<div class="one">
					<img :class="flag?'':'left'" src="../../assets/polygon.png" />
				</div>
				<div class="ml-15 mt-30" v-show='radio == 1&&entry'>
					<div class='wh clearfix'>
						<p class="fl mt-5">包裹数量</p>
						<el-input class='fl ml-30' v-model='packNum' placeholder="请输入包裹数，单位：个" :maxlength="5"></el-input>
					</div>
					<p class="color-red font-12 ml-140 bb" :class='bool1==true ? "zz":""'>请输入正确的包裹数量</p>
					<div class='mt-10 clearfix mr-15'>
						<template>
							<el-table class="mb-30" :data="tableDatas" height="150" style="width: 100%">
								<el-table-column prop="shipperName" label="快递公司名称" width="240">
								</el-table-column>
								<el-table-column prop="name" label="计费规则" width="240">
									<!--<template slot-scope="scope">
										首重&yen;{{scope.row.firstWeight}}/1kg，续重&yen;{{scope.row.addWeight}}/1kg
									</template>-->
								</el-table-column>
								<el-table-column label="操作">
									<template slot-scope="scope">
										<el-radio-group v-model="radio1">
											<el-radio class="radio" :label='scope.row.value'>{{ }}</el-radio>
										</el-radio-group>
									</template>
								</el-table-column>
							</el-table>
						</template>
					</div>
				</div>
				<div class="ml-15 mt-30" v-show='radio == 2'>
					<div class='wh clearfix'>
						<img class='mt-5 fl' src="../../assets/car.png" />
						<el-select class='ml-50 fl' v-model="value" placeholder="请选择快递公司">
							<el-option v-for="item in simpleLists" :key="item.id" :label="item.companyName" :value="item.id">
							</el-option>
						</el-select>
					</div>
					<div class='wh mt-10 clearfix'>
						<img class='mt-5 fl' src="../../assets/num.png" />
						<el-input class='fl ml-50' v-model='serialNumber' placeholder="请输入物流编号" :maxlength="19"></el-input>
					</div>
					<p class="color-red font-12 ml-140 bb" :class='bool2 == true ? "zz":""'>请输入正确的物流编号</p>
				</div>
			</div>
			<div class="btn ml-43">
				<el-button class='mr-50' onclick='javascript:history.go(-1);'>取 消</el-button>
				<el-button type='danger' @click='goPage()'>确认发货</el-button>
			</div>
		</div>
		<!--新增/编辑地址-->
		<el-dialog :title="title" :visible.sync="address">
			<el-form :inline="true" :model="formInfo" :rules="rules" ref="formInfo" class="clearfix">
				<el-form-item label="发货人" prop='linkman'>
					<el-input v-model="formInfo.linkman"></el-input>
				</el-form-item>
				<el-form-item label="手机" prop='tel'>
					<el-input v-model="formInfo.tel"></el-input>
				</el-form-item>
				<el-form-item label="所在地区" prop="area">
					<cityCascaderBox v-model='formInfo.area' :num="3" :showAllLevels="true" :checkStrictly="false" :emitPath="true" @changeName="changeName"/>
				</el-form-item>
				<el-form-item label="详细地址" prop='attr'>
					<el-input v-model="formInfo.attr"></el-input>
				</el-form-item>
				<!--<el-form-item label="是否为默认地址">
					<template>
						<el-radio class="radio" v-model="isNeed" label="1">是</el-radio>
						<el-radio class="radio" v-model="isNeed" label="0">否</el-radio>
					</template>
				</el-form-item>-->
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" @click='addAddress'>确 定</el-button>
				<el-button @click="address=false">取 消</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import { axiosApi, basePath } from "../../api/api";
	export default {
		data() {
			return {
				//add shl
				entry: false, //暂时不展示京东物流
				basePath: basePath,
				config: {
					label: "text",
					children: "sub",
					value: "id"
				},
				rules: {
					linkman: [{
						required: true,
						min: 2,
						max: 20,
						message: "收货人姓名至少2个字符"
					}],
					tel: [{
						required: true,
						pattern: /^(13\d|14[57]|15\d|17\d|18\d)\d{8}$/,
						message: '请输入正确的手机号码',
					}],
					area: [{
						required: true,
						type: "array",
						message: '请选择省市区',
						trigger: 'change'
					}],
					attr: [{
						required: true,
						message: '请填写详细地址',
					}, {
						min: 4,
						max: 60,
						message: '请输入4-60个字符',
					}],
				},
				addressDefalut: {}, //默认地址
				addressLists: [], //地址列表
				addressId: '', //切换地址绑定
				detailsId: '', //详情地址
				api: '', //编辑新增地址
				simpleLists: [], //快递公司列表
				simpleListss: [], //快递公司列表,匹配价格
				packNum: '', //包裹数量
				serialNumber: '', //物流编号
				value: '', //快递公司id
				bool1: false, //正则判断
				bool2: false, //正则判断
				radio: 2, //三个发货地址radio
				radio1: '', //京东物流radio
				flag: true, //3个快递切换样式
				up: false, //点击控制 更多地址
				address: false,
				dd: false, //地址列表1条
				ee: false, //地址列表0条
				fromCityId: "", //卖家地址id
				fromProvinceId: '',
				toCityId: "", //买家市地址id
				toProvinceId: '', //买家省地址id
				title: '新增地址', //编辑/新增地址title
				tableData: [], //京东快递
				tableDatas: [], //匹配过京东快递
				goodsInfo: {}, //进入加载商品信息
				formInfo: { //弹框w-model
					id: '',
					linkman: '',
					tel: '',
					address: '',
					provinceName: '',
					cityName: '',
					regionName: '',
					area: [],
				},
				expressInfo: {}, //发货信息
				isNeed: '0', //是否为默认地址
				areaNameList: []
			}
		},
		methods: {
			changeName(e){
				this.areaNameList = e
			},
			addressList() { //查询发货地址列表
				axiosApi('/groupon/address/selectList', {
					traderId: sessionStorage.traderId,
					isReceive: 1
				}, (res) => {
					this.addressLists = res.data.data ? res.data.data : []
					if(this.addressLists.length > 0) {
						this.ee = true
					} else {
						this.ee = false
					}
					if(this.addressLists.length > 1) {
						this.dd = true
					} else {
						this.dd = false
						this.up = false
					}
					this.addressLists.forEach((item) => {
						if(item.isDefault == 1) {
							this.addressId = item.id
							this.detailsId = item.id
							this.detailsAddress()
						}
					})
				}, 1, 10, (res) => {

				})
			},
			againList() { //查询发货地址列表2
				axiosApi('/groupon/address/selectList', {
					traderId: sessionStorage.traderId,
					isReceive: 1
				}, (res) => {
					this.addressId = this.detailsId = res.data.data[res.data.data.length - 1].id //新增地址更新我的发货地址，并更新选中状态
					this.addressLists = res ? res.data.data : []
					if(this.addressLists.length > 1) {
						this.dd = true
					} else {
						this.dd = false
					}
					if(this.addressLists.length > 0) {
						this.ee = true
					} else {
						this.ee = false
					}
					setTimeout(() => {
						this.detailsAddress()
					}, 100)
				}, 1, 10, (res) => {
					if(this.detailsId = this.formInfo.id) {
						this.detailsAddress()
					}
				})
			},
			moreList() { //查询发货地址列表3
				axiosApi('/groupon/address/selectList', {
					traderId: sessionStorage.traderId,
					isReceive: 1
				}, (res) => {
					this.addressLists = res ? res.data.data : []

				}, 1, 10, (res) => {

				})
			},
			defaultAddress() { //查询默认地址
				axiosApi('/groupon/defaultAddress/selectInfo', {
					traderId: sessionStorage.traderId,
					isReceive: 1
				}, (res) => {
					this.addressDefalut = res ? res.data.data : {}
				}, 1, 10, (res) => {

				})
			},
			detailsAddress() { //查询地址详情
				axiosApi('/groupon/address/selectInfo', {
					id: this.detailsId
				}, (res) => {
					this.addressDefalut = res ? res.data.data : {}
					this.fromProvinceId = this.addressDefalut.provinceId
					this.fromCityId = this.addressDefalut.cityId
					this.shipperPrice()
				}, 1, 10, (res) => {

				})
			},
			setDefault(index) { //设置默认地址
				axiosApi('/groupon/address/setDefault', {
					id: this.addressLists[index].id
				}, (res) => {
					this.againList()
				}, 1, 10, (res) => {

				})
			},
			change() {
				if(this.radio == 2) {
					this.flag = false
				} else {
					this.flag = true
				}
			},
			//点击切换地址
			changeAddressList(val) {
				this.detailsId = this.addressId
				this.detailsAddress()
				//				
			},
			changeAddress(index, val) {
				this.address = true
				this.formInfo.attr = ''
				if(this.$refs.formInfo !== undefined) {
					this.$refs.formInfo.resetFields();
				}
				if(val == 'add') {
					this.formInfo = {}
					this.formInfo.id = null
					this.isNeed = 0
					this.title = '新增地址',
						this.api = '/groupon/address/insert'
				} else {
					this.title = '编辑地址'
					this.formInfo.id = this.addressLists[index].id
					this.formInfo.linkman = this.addressLists[index].linkman
					this.formInfo.tel = this.addressLists[index].tel
					this.formInfo.area = []
					this.formInfo.area[0] = this.addressLists[index].provinceId.toString()
					this.formInfo.area[1] = this.addressLists[index].cityId.toString()
					this.formInfo.area[2] = this.addressLists[index].regionId.toString()
					this.formInfo.attr = this.addressLists[index].attr
					this.isNeed = this.addressLists[index].isDefault.toString()
					this.api = '/groupon/address/update'
				}
			},
			//新增发货地址
			addAddress() {
					this.$refs.formInfo.validate(valid => {
						if(valid) {
							axiosApi(this.api, {
								id: this.formInfo.id,

								provinceName: this.areaNameList[0],
								cityName: this.areaNameList[1],
								regionName: this.areaNameList[2],

								provinceId:  this.formInfo.area[0],
								cityId:this.formInfo.area[1],
								regionId: this.formInfo.area[2],

								attr: this.formInfo.attr,
								linkman: this.formInfo.linkman,
								tel: this.formInfo.tel,
								isReceive: 1,
								isDefault: this.isNeed,
							}, (res) => {
								this.address = false
								if(this.ee == false) {
									this.addressList()
								}
								this.againList()
							}, 1, 10, (res) => {

							})

						} else {
							return false;
						}
					})
			},
			//删除发货地址
			deleteAddress(index) {
				this.$confirm('确认删除该发货地址？', '提示信息', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					axiosApi('/groupon/address/delete', {
						id: this.addressLists[index].id
					}, (res) => {
						if(this.ee == false) {
							this.addressList()
						}
						this.againList()
						if(this.addressLists[index].id = this.detailsId) {
							this.addressList()
						}
					}, 1, 10, (res) => {

					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				})
			},
			//快递公司列表查询
			simpleList() {
				axiosApi('/foundation/expressCompany/simpleList', {
					type: 0
				}, (res) => {
					this.simpleLists = res.data.data.expressCompanyList ? res.data.data.expressCompanyList : []
					this.simpleLists = this.simpleLists.filter(item => item.companyName !== "自行配送")
				}, 1, 10, (res) => {

				})
			},
			//快递公司列表查询,匹配快递公司运费价格
			simpleListd() {
				axiosApi('/foundation/expressCompany/simpleList', {
					type: 1
				}, (res) => {
					this.simpleListss = res.data.data.expressCompanyList ? res.data.data.expressCompanyList : []
					this.simpleListss = this.simpleListss.filter(item => item.companyName !== "自行配送")
					this.tableDatas = []
					if(this.tableData.length > 0) {
						this.simpleListss.forEach(item => {
							this.tableData.forEach(item1 => {
								if(item1.shipperName == item.companyName) {
									let a = {}
									a.shipperName = item.companyName
									a.name = " 首重￥" + item1.firstWeight + "/1kg," + "续重￥" + item1.addWeight + "/1kg"
									a.value = item.id.toString()
									this.tableDatas.push(a)
								} else {
									let a = {}
									a.shipperName = item.companyName
									a.name = " 暂无参考"
									a.value = item.id.toString()
									this.tableDatas.push(a)
								}
							})
						})
					} else {
						this.simpleListss.forEach(item => {
							let a = {}
							a.shipperName = item.companyName
							a.name = " 暂无参考"
							a.value = item.id.toString()
							this.tableDatas.push(a)
						})
					}

					if(this.tableDatas.length > 0) {
						this.radio1 = this.tableDatas[0].value
					}
				}, 1, 10, (res) => {

				})
			},
			//进入加载信息
			orderInfo() {
				axiosApi('/groupon/shipmentOrder/info', {
					orderSn: this.$route.params.sn,
					sellerId: sessionStorage.traderId
				}, (res) => {
					this.goodsInfo = res.data.data ? res.data.data : {}
					this.goodsInfo.grouponOrderItemVOs = this.goodsInfo.grouponOrderItemVOs.concat(this.goodsInfo.orderActivityGiftRelationVOs)
					this.toProvinceId = this.goodsInfo.addrProvinceId
					this.toCityId = this.goodsInfo.addrCityId
					this.addressList() //地址列表
				}, 1, 10, (res) => {

				})
			},
			//匹配快递公司运费价格
			shipperPrice() {
				axiosApi('/foundation/shipperPrice/match', {
					fromCityId: this.fromCityId,
					fromProvinceId: this.fromProvinceId,
					toProvinceId: this.toProvinceId,
					toCityId: this.toCityId
				}, (res) => {
					this.tableData = res.data.data ? res.data.data : []
					this.simpleListd() //匹配价格运费

				}, 1, 10, (res) => {

				})
			},
			goPage() {
				this.expressInfo = {}
				this.expressInfo.orderSn = this.goodsInfo.sn
				if(this.radio == 1) {
					this.expressInfo.shipperType = 3
					this.expressInfo.addressId = this.addressId
					this.expressInfo.quantity = this.packNum
					this.expressInfo.shipperId = this.radio1
				} else if(this.radio == 2) {
					this.expressInfo.shipperType = 1
					this.expressInfo.shipperId = this.value
					this.expressInfo.deliveryNo = this.serialNumber
				} else {
					this.expressInfo.shipperType = 2
				}
				let reg = /^[1-9]\d*$/
				let reg1 = /^[A-Za-z0-9]{1,19}$/
				if((this.radio == 1 && reg.test(this.packNum)) || (this.radio == 2 && reg1.test(this.serialNumber) && this.value != '') || this.radio == 3) {
					this.bool1 = false
					this.bool2 = false
					axiosApi('/groupon/orderShipment/update', this.expressInfo, (res) => {
						this.$message({
							type: 'success',
							message: '发货成功！',
							duration: 3000
						});
						this.$router.push(basePath + "/superGroup/groupOrder");
					}, 1, 10, (res) => {
						this.$message({
							type: 'warning',
							message: res.data.message,
							duration: 1000
						});
					})
				} else {
					if(this.radio == 1) {
						this.bool1 = true
					} else {
						this.bool2 = true
					}
				}
			},
			useSupportExpress() {
				axiosApi("/groupon/useSupportExpress/select", {}, res => {
					this.entry = res.data.data
				}, 1, 10, res => {
					this.entry = res.data.data
					this.radio = 2
				})
			}
		},
		//进入加载
		mounted() {
			this.$nextTick(function() {
				this.simpleList() //快递公司列表
				this.orderInfo()
//				this.useSupportExpress() //是否有京东物流
			})
		}
	}
</script>

<style lang="less">
	#groupConsignment {
		.mt-43 {
			margin-top: 43px;
		}
		.ml-43 {
			margin-left: 43px;
		}
		.mr-15 {
			margin-right: 15px;
		}
		.black {
			height: 70px;
			line-height: 70px;
			padding-left: 39px;
		}
		.ml-140 {
			margin-left: 140px;
		}
		.color-red {
			color: red
		}
		.main {
			padding: 23px 0 0 26px;
			.mr-35 {
				margin-right: 35px;
			}
			.price {
				padding: 43px 0 25px 635px;
				border: 1px solid #DDDDDD;
				border-top: none;
				.mb-15 {
					margin-bottom: 15px;
				}
			}
			.address {
				margin-top: 40px;
				margin-left: 44px;
				margin-bottom: 62px;
			}
			.address-list {
				width: 734px;
				height: 81px;
				padding: 14px 0 0 27px;
				margin-left: 44px;
				overflow-y: scroll;
			}
			.choose {
				.el-radio+.el-radio {
					margin-left: 135px;
				}
			}
			.logistics {
				width: 613px;
				min-height: 163px;
				border-radius: 8px;
				.one {
					img {
						position: absolute;
						top: -11px;
						left: 40px;
					}
					.left {
						position: absolute;
						top: -11px;
						left: 250px;
					}
				}
				.el-button {
					/*width: 0;*/
				}
			}
			.disabledLogistics {
				display: none;
			}
			.wh {
				width: 528px;
				height: 35px;
				padding-left: 55px;
				padding-top: 5px;
				background: #F8F8F8;
				.el-input__inner {
					border: none;
					height: 27px !important;
				}
			}
			.btn {
				margin-top: 71px;
			}
		}
		.aa:hover {
			background: #F2F2F2;
		}
		.bb {
			display: none;
		}
		.cc {
			display: block;
		}
		.aa:hover .bb {
			display: inline-block;
			background: #F2F2F2;
		}
		.zz {
			display: block;
		}
	}
</style>