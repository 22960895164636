<template>
    <div>
      <el-dialog
        title="指定特殊账号"
        :visible.sync="show"
        width="50%"
        @close="closeDialog"
      >
        <div style="margin:0 10px">
          <div style="color: red; margin: 10px 0">*若使用指定帐号，则取消所有通用帐号</div>
          <el-table
            ref="tableList"
            :data="list"
            tooltip-effect="dark"
            style="width: 100%"
            @selection-change="SelectionChange">
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column prop="bankName" label="付款渠道"></el-table-column>
            <el-table-column prop="bankCard" label="银行卡号"></el-table-column>
            <el-table-column prop="bankDeposit" label="开户支行"></el-table-column>
            <el-table-column prop="bankUserName" label="开户人"></el-table-column>
          </el-table>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="addInfo">确 定</el-button>
          <el-button @click="closeDialog">取 消</el-button>
        </span>
      </el-dialog>
    </div>
</template>

<script>
import { axiosApi, basePath } from "../../../../api/api";
export default {
  props: ['dialogVisible', 'buyerId'],
  data(){
    return {
      show:false,
      list: [],
      traderBankIds: [],
    }
  },
  mounted(){},
  activated(){},
  watch: {
    dialogVisible(newName, oldName){
      if(newName){
        this.getList()
      }
      this.show = newName
    }
  },
  methods: {
    SelectionChange(list){
      this.traderBankIds = list.map(item=>{
        return item.id
      })
    },
    getList(){
      axiosApi(
        '/anon/order/find/trader/bank/buyer/info',
        { traderId: sessionStorage.getItem("traderId"),
          buyerId: this.buyerId,
        },
        (res) => {
          if(res.data.code==='000000'){
            this.list = res.data.data
          }
        }
      );
    },
    addInfo(){
      if(this.traderBankIds.length==0){
        this.$message({
          type: "info",
          message: "请选择要绑定的特殊账号",
        });
        return
      }
      axiosApi(
        '/anon/order/bind/trader/bank/buyer/info',
        { 
          buyerId: this.buyerId,
          traderBankIds: this.traderBankIds
        },
        (res) => {
          if(res.data.code==='000000'){
            this.$emit('submitInfo') // 确认
            this.closeDialog()
          }
        }
      );
    },
    closeDialog(){
      this.$refs.tableList.clearSelection();
      this.$emit('closeDialog') // 关闭弹框的操作
    },
    handleSelectionChange(){},
  },
}
</script>

<style>

</style>