import {axiosApi,basePath} from '../../api/api.js'
// import { Message } from 'element-ui';
import m from '../../main.js'

const channelLevel = {
    state: {
        clientChannelList: [],
        clientLevelList: [],
        add: true,
        channelData: {
            classifyName: "",
            parentId: 0,
            traderId: null
        },
        levelData: {
            traderId: "",
            levelName:''
        },
        groupList: [],
        districtList: []
    },
    mutations: {
        insert(state){
            state.add = true;
        },
        update(state){
            state.add = false;
        },
        resetChannelLevelData(state){
            state.channelData = {
                classifyName: "",
                parentId: "",
                traderId: null
            }
            state.levelData = {
                traderId: null,
                levelName:''
            }
        },
        setData(state, payload){
            if(payload.type == 0){
                state.channelData = Object.assign({},{
                    classifyName: payload.data.classifyName,
                    parentId: payload.data.parentId,
                    id: payload.data.id
                })
            }else{
                state.levelData = Object.assign({},{
                    levelName: payload.data.levelName,
                    id: payload.data.id
                })
            }
        },
        setDataList(state, payload){
            state.groupList = payload.data ? payload.data : [];
            // if(payload.type == 0){
            //     state.clientChannelList = payload.data;
            // }else{
            //     state.clientLevelList = payload.data;
            // }
        }
    },
    actions: {
        //请求渠道/等级列表
        queryChannelLevelData({state}, payload) {
            let api = payload == 0 ? '/trader/customerChannel/List' : '/trader/customerLevel/List'
            axiosApi(api, {traderId: sessionStorage.getItem('traderId')}, (res)=>{
                if(payload == 0){
                    state.clientChannelList = res.data.data ? res.data.data : [];
                }else if(payload == 1){
                    state.clientLevelList = res.data.data ? res.data.data : [];
                }
            }, 0, 10,(err)=>{
                /*m.$message({
                    type: 'info',
                    message: err.data.message,
                    duration: 1000
                })*/
            })
        },
        //编辑查询单个渠道或等级信息
        selectChannelLevelData({state, commit}, payload){
            let api= payload.type == 0 ? '/trader/customerChannel/select' : '/trader/customerLevel/select'
            axiosApi(api, { id: payload.id }, (res)=>{
                commit('setData',{
                    type: payload.type,
                    data: res.data.data
                })
            }, 0, 10)
        },
        //新增、编辑渠道或等级
        addChannelLevelData({state, commit, dispatch}, payload) {
            let api
            if(state.add){//添加
                api = payload.type == 0 ? '/trader/customerChannel/insert' : '/trader/customerLevel/insert'
            }else{//编辑
                api = payload.type == 0 ? '/trader/customerChannel/update' : '/trader/customerLevel/update'
            }
            axiosApi(api, payload.body, (res)=>{
                m.$message({
                    type: 'success',
                    message: '操作成功！',
                    duration: 1000
                })
                commit('resetChannelLevelData')
                //调用查询列表方法
                dispatch('queryChannelLevelData', payload.type)
            }, 0, 10,(err)=>{
                m.$message({
                    type: 'info',
                    message: err.data.message,
                    duration: 1000
                })
            })
        },
        //删除渠道或等级
        delChannelLevelData({state, dispatch}, payload){
            let h = m.$createElement;
                m.$msgbox({
                    title: '提示信息',
                    message: h('p', {
                        style: 'text-align:center'
                    }, '是否确定删除?'),
                    showCancelButton: true,
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    beforeClose: (action, instance, done) => {
                        if(action === 'confirm') {
                            let api = payload.type == 0 ? '/trader/customerChannel/delete' : '/trader/customerLevel/delete'
                            axiosApi(api, { id: payload.id }, (res)=>{
                                m.$message({
                                    type: 'success',
                                    message: '操作成功！',
                                    duration: 1000
                                })
                                dispatch('queryChannelLevelData', payload.type)
                                m.loading();
                            },0,0,(err)=>{
                                // m.$message({
                                //     type: 'info',
                                //     message: '操作失败！',
                                //     duration: 1000
                                // })
                            })

                            done()
                        } else {
                            done()
                        }
                        delete instance.$slots.default;
                    }
                })
        },
        queryGroupList({state}){
            axiosApi('/trader/customer/group/listTree',{},(res)=>{
                console.log(res, 'group')
                let treeInit = (tree)=>{
                    tree.forEach(item=>{
                        if(item.sub.length>0){
                         treeInit(item.sub)
                        }else{
                        delete item.sub
                        }
                    })
                    return tree
                }
                state.groupList = treeInit(res.data.data);
            })
        },
        queryDistrictList({state}){
            //区域
            axiosApi('/trader/customerDistrict/query',{traderId: sessionStorage.getItem('traderId')},(res)=>{
                state.districtList = [res.data.data];
                state.districtList[0].id = '0';
            },1,10,err => {
                console.log(err,'err0000')
            })
        }
    },
    getters: {

    }
}

export default channelLevel
