<template>
    <!--支付订单页面-->
    <div id="pay-cash" class="bg-f" v-loading="loading">
        <top v-if="!isChild"></top>
        <div class="pt-30 cashier-content" :class="{'cashier': !isChild}">
            <div v-if="!isChild" class="clearfix">
                <div class="fl ">
                    <span class="font-20 color-3 lh-20 ">订单提交成功，请尽快付款！订单号：{{orderSn}}</span>
                </div>
                <div class="fr lh-30">
                    支付金额 <span class="color-danger font-30">￥<span>{{payData.payAmount || 0 | fmoney}}</span></span>
                </div>
            </div>
            <div v-if="!isChild" class="clearfix mb-50 ">
                <div class="color-6 fl lh-14">请您在<span class="color-danger">{{payData.remainAutoCancleTime}}</span>内完成支付，否则订单会被自动取消
                </div>
                <el-button type="text" class="fr payorder-text-btn" @click="orderDetail()">查看订单详情</el-button>
            </div>
            <div class="pay-way">
                <!-- <div class="clearfix">
                    <el-checkbox v-model="blChecked" :disabled="balance==0" class="pay-checkbox fl">
						<span class="fw font-16" :class="{'color-9':balance<=0,'color-3':blChecked}">
							余额支付<span :class="{'color-3F':blChecked}">（可用余额：<span :class="{'color-danger':balance>0}">¥{{balance | fmoney}}</span>）</span>
						</span>
                    </el-checkbox>
                    <span v-if="blChecked" class="fr font-20 mr-20">支付<span class="color-danger">¥{{balancePay||0  | fmoney}}</span></span>
                </div> -->
                <el-radio-group v-model="activeIndex" class="mb-50" @change="radioChange">
                    <div v-for="(item, index) in payData.payMethodList" :key="index" class="bank"
                         :class="{'bb':activeIndex==index, 'bank1': !isChild}">
                        <el-radio :label="index">
                            <span class="font-16 fl" v-if="item.payType=='OLP'">{{item.bankName}}</span>
                            <span v-else class="fl"><span class="font-16">{{item.label}}</span><span class="ml-50">{{item.type}}</span></span>
                            <span class="mr-20 fr" v-if="activeIndex==index"><span>支付</span><span
                                    class="color-danger">¥{{bankPay||0 | fmoney}}</span></span>
                        </el-radio>
                    </div>
                </el-radio-group>
                <div class="mb-50 mt-30">
                    <el-button class="other-pay-btn" @click="showPayway"
                               v-if="payData.payMethodList.length>0">其他支付方式
                    </el-button>
                    <el-button  class="add-bank-btn" @click="addBank">添加银行卡</el-button>
                </div>
                <!--是否有支付密码-->
                <!-- <div v-if="blChecked&&hasPayPassword" class="mb-50">
                    <el-form label-position="top" :model="form" :rules="rules" ref="form">
                        <el-form-item label="支付密码:" prop="pwd">
                            <el-input type="password" :class="{'pwd-input':errormsg.length>0}" @change="clearmsg"
                                      :maxlength='6' v-model="form.pwd" placeholder="请输入支付密码"></el-input>
                            <el-button type='text' @click="fsecret">忘记密码？</el-button>
                            <p class="color-danger font-12" v-if="errormsg.length>0">{{errormsg}}</p>
                        </el-form-item>
                    </el-form>
                </div> -->

                <el-button type="danger" class="mb-50" @click="confirmPayment">确认付款</el-button>
            </div>
        </div>
        <!--添加银行卡弹窗-->
        <addbank v-on:closedialog="close" :dialogvisible="dialogvisible"></addbank>
        <el-dialog title="" :visible.sync="visible" class="payDialog" @close="closeDialog">
            <div class="font-16 color-6"><i class="el-icon-information color-2b mr-10 sub"></i>请于<span
                    class="color-danger">{{payData.remainAutoCancleTime}}</span>内在新开的页面完成支付
            </div>
            <div class="font-16 color-6 mt-30"><i class="el-icon-information color-2b mr-10 vertical"></i>如果已经支付完成，请点击
                <el-button class="ml-20" type="danger" @click="payover">完成支付</el-button>
            </div>
            <div class="font-16 color-6 mt-30"><i class="el-icon-information color-2b mr-10 vertical"></i>如果付款遇到问题，请点击
                <el-button class="ml-20" @click="payagain">重新支付</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>

    import {axiosApi, basePath} from "../../api/api"
    import md5 from 'md5'
    export default {
        name: 'cashier',
        props: ['isChild','buyerId','calcIntegration','calcCoin'],
        data() {
            return {
                dialogvisible: false, //添加銀行卡彈窗是否关闭
                activeIndex: 0,
                basePath: basePath,
                loading: false,
                visible: false,
                payData: {
                    payMethodList: []
                }, // 支付收银台返回信息
                orderSn: null, //订单号
                balance: 0, //余额钱数
                balanceObj: {}, //余额方式
                // blChecked: false, //选中余额
                hasPayPassword: false, //是否设置有支付密码
                alipayment: {}, // 支付宝数据
                balancePay: 0, //余额显示支付金额
                bankPay: 0, //其他支付方式支付金额
                form: {
                    pwd: null, // 支付密码
                },
                paymethod: [], //支付方式（参数）
                pwdValidate: true, // 支付密码验证
                ispass: false,
                // rules: {
                //     pwd: [{
                //         required: true,
                //         pattern: /^\d{6}$/,
                //         message: '请输入6位数字'
                //     }]
                // },
                errormsg: '', //支付密码错误
            }
        },
        mounted() {
            this.$nextTick(() => {
                if (sessionStorage.orderSn) {
                    this.orderSn = sessionStorage.orderSn
                }
                if (!this.isChild) document.getElementById("pay-cash").style.minHeight = document.documentElement.clientHeight - 187 + "px";
                this.paymentDesk({
                    'orderNo': this.orderSn,
                    'terminalId': 'PC',
                    'tradeNo':'YS_' + this.orderSn
                })
            })
        },
        computed: {
            result() {
                return this.$store.state.paymodule.result
            }
        },
        watch: {
            //监听余额是否选中
            // blChecked(val) {
            //     //有其他支付方式并且展开
            //     if (this.payData.payMethodList.length > 0) {
            //         if (val) {
            //             if (this.payData.payAmount > this.balance) {
            //                 for (let i = 0; i < this.payData.payMethodList.length; i++) {
            //                     let item = this.payData.payMethodList[i]
            //                     //支付宝
            //                     if (item.bankCode == "alipay") {
            //                         this.activeIndex = i
            //                     }
            //                 }
            //                 this.bankPay = this.payData.payAmount
            //             } else {
            //                 this.activeIndex = null
            //             }
            //         } else {
            //             for (let i = 0; i < this.payData.payMethodList.length; i++) {
            //                 let item = this.payData.payMethodList[i]
            //                 //支付宝
            //                 if (item.bankCode == "alipay") {
            //                     this.activeIndex = i
            //                 }
            //             }
            //             this.bankPay = this.payData.payAmount
            //         }
            //     }
            // },
        },
        methods: {
            //点击弹窗关闭按钮
            closeDialog() {
                this.payagain()
            },
            close() {
                this.dialogvisible = false
                if (this.result) {
                    this.paymentDesk({
                        'orderNo': this.orderSn,
                        'terminalId': 'PC',
                         'tradeNo':'YS_' + this.orderSn
                    })
                }
            },
            clearmsg() {
                this.errormsg = ''
            },
            //付款
            payment(body) {
                let newwin
                for (let i = 0; i < this.payData.payMethodList.length; i++) {
                    let item = this.payData.payMethodList[i]
                    for (let j = 0; j < body.payMethod.length; j++) {
                        if (body.payMethod[j].payMethodId === item.methodId) {
                            newwin = window.open('about:blank', '_blank')
                            break
                        }
                    }
                }
                axiosApi('/anon/cbs/pay/payment', body, (res) => {
                    // console.log(res.data.data.bankData,res,'11111')
                    let Obj = JSON.parse(res.data.data.bankData)
                    let key = ''
                    let data = ''
                    for (let k in Obj) {
                        key = k
                        data = Obj[k]
                    }
                    // console.log(data)
                    localStorage.alipayHtml = data
                    // sessionStorage.alipayHtml = res.data.data.bankData
                    if (this.activeIndex == null) {
                        this.$router.push(basePath + '/pay/verifyresult/3/1')
                    }
                    if (this.activeIndex != null) { //其他支付方式中的一种
                        newwin.location = window.location.protocol + '//' + window.location.host + this.basePath + '/alipay'
                        this.visible = true
                    }
                }, 1, 10, (res) => {
                    if (res.data.code == 'CBS1011') {
                        this.errormsg = res.data.message
                    } else if (res.data.code == '100006') {
                        this.errormsg = "输入错误次数过多，请稍后再试"
                    } else {
                        this.$message({
                            type: 'error',
                            message: res.data.message,
                            duration: 3000
                        })
                        newwin.close()
                    }
                })
            },
            //1.1查询用户账户基本信息
            employeeInfo() {
                axiosApi('/anon/cbs/account/employeeInfo', null, (res) => {
                    let data = res.data.data
                    //余额
                    this.hasPayPassword = res.data.data.hasPayPassword
                    this.balance = data.withdrawalBalance //余额值
                    // this.blChecked = this.balance != 0
                    //选中余额后页面余额显示付多少
                    this.balancePay = this.payData.payAmount;
                    //有其他支付方式并且展开
                    if (this.payData.payMethodList.length > 0) {
                        // if (this.blChecked) {
                        //     // if (this.payData.payAmount > this.balance) {
                        //         for (let i = 0; i < this.payData.payMethodList.length; i++) {
                        //             let item = this.payData.payMethodList[i]
                        //             //支付宝
                        //             if (item.bankCode == "alipay") {
                        //                 this.activeIndex = i
                        //             }
                        //         }
                        //         this.bankPay = this.payData.payAmount 
                        //     // } else {
                        //     //     this.activeIndex = null
                        //     // }
                        // } else {
                            for (let i = 0; i < this.payData.payMethodList.length; i++) {
                                let item = this.payData.payMethodList[i]
                                //支付宝
                                if (item.bankCode == "alipay") {
                                    this.activeIndex = i
                                }
                            }
                            this.bankPay = this.payData.payAmount 
                        // }
                    }
                    let payform = {}
                    if (!sessionStorage.getItem('payform')) {
                        payform = {
                            loginName: data.loginName,
                            idCardNumber: data.idCardNumber,
                            isVerified: data.isVerified,
                            hasPayPassword: data.hasPayPassword,
                            realName: data.realName,
                            step: 1,
                            mobile: data.mobile
                        }
                    } else {
                        payform = Object.assign(JSON.parse(sessionStorage.getItem("payform")), {
                            step: 1
                        })
                    }
                    sessionStorage.setItem("payform", JSON.stringify(payform))

                }, 1, 10, (res) => {
                })
            },
            //1.17支付收银台
            paymentDesk(body) {
                axiosApi('/anon/cbs/pay/paymentDesk', body, (res) => {
                    this.payData = res.data.data
                    //定时器
                    let time = 10000 / 1000
                    if (time > 0) {
                        this.payData.remainAutoCancleTime = this.timeString(time)
                        let clearTime = setInterval(() => {
                            time--
                            this.payData.remainAutoCancleTime = this.timeString(time)
                            if (time <= 0) {
                                clearTimeout(clearTime)
                            }
                        }, 1000)
                    }
                    //支付方式
                    //余额
                    for (let i = 0; i < this.payData.payMethodList.length; i++) {
                        let item = this.payData.payMethodList[i]
                        //余额
                        if (item.bankCode == "sy") {
                            this.balanceObj = item
                        }
                    }
                    //去除余额方式
                    this.payData.payMethodList = this.payData.payMethodList.filter(item => item.payType != "OBP")
                    this.employeeInfo()
                }, 1, 10, (res) => {
                }, 1)
            },
            //完成支付
            payover() {
                this.visible = false;
                //是否是买家付款 true 买家付款 false 代支付
                const isSelf = this.buyerId == sessionStorage.getItem('traderId')
                // this.getStatus();
                const url = isSelf ? '/purchaseOrderDetail' :  `/supplierOrderDetail?sn=${this.orderSn}&type=1`
                this.$router.push(basePath + url)
            },
            //查看订单状态
            getStatus(){
                let array = JSON.parse(sessionStorage.getItem('orderInfo')).map(v =>v.orderSn);
                axiosApi('/order/payStatus/query',{ orderSns:array },res => {
                    console.log(res,'status');
                    // if(res.data.data.isNeedToPay){
                    //     this.$router.push(basePath + '/prePay')
                    // }else {
                    //     this.$router.push(basePath + '/purchaseOrderDetail')
                    // }
                },err => {
                    this.$message.warning(err.data.message)
                })
            }, 
            //重新支付
            payagain() {
                let a = sessionStorage.isParentPurchaseOrderType || '',
                    data = {}
                if (a == 2) {
                    data.parentOrderSn = sessionStorage.orderSn
                } else {
                    data.orderSn = sessionStorage.orderSn
                }
                axiosApi('/anon/parentOrder/detail/info', data, (res) => {
                    if (res.data.data.payStatus != 3) {
                        window.location.reload()//页面刷新
                    } else {
                        this.visible = false
                        //是否是买家付款 true 买家付款 false 代支付
                        const isSelf = this.buyerId == sessionStorage.getItem('traderId')
                        // this.getStatus();
                        const url = isSelf ? '/purchaseOrderDetail' :  `/supplierOrderDetail?sn=${this.orderSn}&type=1`
                        this.$router.push(basePath + url)
                    }
                })
            },
            /*确认付款*/
            confirmPayment() {
                //选中余额支付
                // if (this.blChecked) {
                    // if (this.hasPayPassword) {
                        // this.$refs.form.validate((valid) => {
                        //     if (valid) {
                                // this.balanceToPay()
                        //     } else {
                        //         return false
                        //     }
                        // })
                    // } else {
                    //     let h = this.$createElement
                    //     this.$msgbox({
                    //         title: "提示信息",
                    //         message: h('p', {
                    //             style: 'font-size:14px;'
                    //         }, '为了您的账户安全，支付前请先设置您的支付密码'),
                    //         showCancelButton: true,
                    //         confirmButtonText: '立即设置',
                    //         cancelButtonText: '取消',
                    //         beforeClose: (action, instance, done) => {
                    //             if (action === 'confirm') {
                    //                 //设置支付密码
                    //                 this.$router.push(basePath + '/pay/bind')
                    //                 done()
                    //             } else {
                    //                 done()
                    //             }
                    //             delete instance.$slots.default;
                    //         }
                    //     })
                    // }
                // } else {
                    this.balanceToPay()
                // }
            },
            //余额支付
            balanceToPay() {
                let body = {
                    orderNo: this.orderSn,
                    payMethod: [],
                    tradeNo: this.payData.tradeNo,
                    calcIntegration:this.calcIntegration,
                    calcCoin: this.calcCoin,
                    payPassword: null
                }
                // if (this.blChecked) { //余额支付
                //     // body.payPassword = md5(this.form.pwd)
                //     let obj = {
                //         terminalId: 'PC',
                //         amount: this.balancePay,
                //         payMethodId: this.balanceObj.methodId
                //     }
                //     body.payMethod.push(obj)
                // }
                for (let i = 0; i < this.payData.payMethodList.length; i++) { //其他支付方式
                    if (i == this.activeIndex) {
                        let obj = {
                            terminalId: 'PC',
                            amount: this.bankPay,
                            payMethodId: this.payData.payMethodList[i].methodId
                        }
                        body.payMethod.push(obj)
                    }
                }
                this.payMethod = body.payMethod
                this.payment(body)
            },
            addBank() {
                this.dialogvisible = true
            },
            /*显示其他支付方式*/
            showPayway() {
                // this.blChecked = this.payData.payAmount > this.balance
                this.bankPay = this.payData.payAmount 
            },
            //单选框组 change事件
            radioChange() {
                // if (this.activeIndex != null) {
                //     if (this.payData.payAmount < this.balance) {
                //         this.blChecked = false
                //     }
                // }
            },
            orderDetail() { //去订单详情页面
                sessionStorage.setItem('orderSn', this.orderSn)
                this.$router.push(basePath + '/purchaseOrderDetail')
            },
            fsecret() { //忘记支付密码
                this.$router.push(basePath + '/pay/bind')
            },
        }
    }
</script>

<style lang="less">
    .el-message {
        z-index: 100000;
    }

    #pay-cash {
        .vertical {
            visibility: hidden;
        }

        .el-dialog__header {
            background-color: #F5F5F5;
            border-bottom: none;
        }

        .color-2b {
            color: #2BB8AB;
            font-size: 30px;
        }

        .aliForm {
            height: 50px;
            background: #f00;

            input {
                display: block !important;
            }
        }

        .payDialog {
            .el-dialog {
                min-height: 0;

                .el-dialog__title {
                    margin-left: 66px;
                }

                .el-dialog__body {
                    padding: 50px;
                    padding-left: 90px !important;
                }
            }
        }

        .pwd-input {
            .el-input__inner {
                border: 1px solid red;
            }
        }

        .payorder-text-btn {
            width: auto;
            height: auto;
        }

        canvas {
            width: 165px !important;
            height: 165px !important;
        }

        .ml-95 {
            margin-left: 95px;
        }

        .mr-95 {
            margin-right: 95px;
        }

        .cashier {
            margin: 0 auto;
            padding: 0 45px;
            width: 1000px;
            margin: 0 95px;
            box-sizing: border-box;
        }

        .cashier-content {
            .pay-way {
                .pay-checkbox {
                    margin-bottom: 30px;

                    .el-checkbox__inner {
                        width: 20px;
                        height: 20px;
                        margin-right: 20px;
                    }

                    .el-checkbox__inner::after {
                        height: 9px;
                        left: 6px;
                    }
                }

                .other-pay-btn,
                .add-bank-btn {
                    width: 150px;
                    height: 40px;
                    color: #20a0ff;
                    margin-right: 20px;
                }

                .add-bank-btn {
                    border: 1px dashed #DDDDDD;
                    margin-left: 0;
                }

                .bank {
                    width: 871px;
                    height: 80px;
                    /*line-height: 80px;*/
                    border-top: 1px solid #ddd;

                    &:last-child {
                        border-bottom: 1px solid #ddd;
                    }

                    .el-radio__inner {
                        width: 20px;
                        height: 20px;
                        margin-right: 15px;
                    }

                    .el-radio:after {
                        content: ".";
                        display: block;
                        height: 0;
                        font-size: 0;
                        clear: both;
                        visibility: hidden;
                    }

                    .el-radio {
                        box-sizing: border-box;
                        width: 100%;
                        padding-left: 30px;

                        .el-radio__input {
                            float: left;
                            height: 90px;
                            line-height: 90px;
                        }

                        .el-radio__label {
                            display: inline-block;
                            width: 800px !important;
                            /*float: right;*/
                            height: 80px;
                            line-height: 80px;
                        }

                        .el-radio__label:after {
                            content: ".";
                            display: block;
                            height: 0;
                            font-size: 0;
                            clear: both;
                            visibility: hidden;
                        }
                    }

                    &:hover {
                        border: 2px solid #20a0ff;
                    }
                }

                .bank1 {
                    width: 1000px;
                }

                .bb {
                    border: 2px solid #20a0ff !important;
                }
            }

            .address-info,
            .pay-bank,
            .credit-pay,
            .payment-voucher-info {
                border: 1px solid #DDDDDD;
                box-sizing: border-box;
            }

            .address-info {
                padding: 30px 0;

                li {
                    span {
                        display: inline-block;
                        width: 102px;
                        text-align: right;
                        margin-right: 10px;
                    }
                }
            }

            .credit-pay {
                margin-top: 20px;
                font-size: 20px;

                .w115 {
                    width: 135px;
                }
            }

            .el-radio__label {
                font-size: 20px;
                /*line-height: 32px;*/
                clear: both;
            }

            .el-radio__label:after {
                content: '';
                clear: both;
                display: inline-block;
            }

            .pay-bank,
            .credit-pay {
                padding: 30px;
            }

            .payment-voucher-info {
                padding: 30px;
                min-height: 240px;

                .showpaymentImage {
                    .el-upload--picture-card {
                        display: none;
                    }

                    .el-icon-delete2 {
                        display: none;
                    }

                    .el-icon-view {
                        padding-left: 20px;
                    }
                }

                .el-dialog {
                    .avatar-uploader-icon {
                        width: 240px;
                        height: 180px;
                        line-height: 180px;
                        border: 1px solid #BBBBBB;
                    }

                    .el-icon-plus:before {
                        font-size: 30px;
                    }

                    .el-upload__tip {
                        width: 240px;
                        position: relative;
                        line-height: 12px;
                        /*left: 185px;*/
                        min-height: 30px;
                    }

                    .el-form-item__error {
                        top: 181px;
                        left: 185px;
                    }
                }
            }
        }
    }
</style>