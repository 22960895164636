<template>
  <!--门店-->
  <div id="contractCustomer">
    <div class="title-top bg-f clearfix">
      门店管理
      <el-button class="fr mt-14 mr-30" @click="openCustomer('form')">新增中心店</el-button>
    </div>
    <div class="content bg-f pr-30 pl-30 mt-5">
      <div class="clearfix search">
        <el-form :inline="true" class="pt-30 fl" label-width="auto" :model="Form" ref="searchForm">
          <el-form-item label="中心店名称">
            <el-input v-model.trim="Form.storeName" placeholder="请输入中心店名称" :maxlength="40"></el-input>
          </el-form-item>
          <el-form-item label="中心店账号">
            <el-input v-model.trim="Form.loginName" placeholder="请输入中心店账号" :maxlength="40"></el-input>
          </el-form-item>
        </el-form>
        <el-button class="fl ml-30 mt-30" type="info" @click="achieve(true)" icon="el-icon-search">搜 索</el-button>
      </div>
      <div class="pb-30">
        <el-table :data="tableData">
          <el-table-column label="中心店名称" min-width="100">
            <template slot-scope="scope">
              <span>{{scope.row.storeName}}</span>
            </template>
          </el-table-column>
          <el-table-column label="中心店账号" min-width="100">
            <template slot-scope="scope">
              <span>{{scope.row.loginName}}</span>
            </template>
          </el-table-column>
          <el-table-column label="联系人/电话" min-width="120">
            <template slot-scope="scope">
              <span>{{scope.row.linkname}}/{{scope.row.tel}}</span>
            </template>
          </el-table-column>
          <el-table-column label="地区" min-width="70">
            <template slot-scope="scope">
              <span v-if="scope.row.regionName">{{scope.row.regionName}}</span>
              <span v-else>-</span>
            </template>
          </el-table-column>
          <el-table-column label="提现佣金">
             <template scope="{row}">
              <div>{{row.withdrawCommission||0|fmoney}}</div>
            </template>  
          </el-table-column>
          <el-table-column label="抵扣佣金">
            <template scope="{row}">
              <div>{{row.deductionCommission||0|fmoney}}</div>
            </template>  
          </el-table-column>
          <el-table-column label="下属门店数量" prop="customerNum"></el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <el-link :type="scope.row.state == 4 ?'primary':'danger'" :underline="false" @click="isForbbident(scope)">{{scope.row.state == 4 ? "启用" : "禁用"}}</el-link>
              <el-link type="primary" :underline="false" @click="toDetails(scope.row.traderId,scope.row.storeName)">详情</el-link>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          class="tar mt-20"
          v-if="tableData.length>0"
          :current-page="currentPage"
          :page-size="10"
          :total="total"
          layout="prev , pager, next, jumper"
          @current-change="handleCurrentChange"
        >
          <!-- 分页组件 -->
        </el-pagination>
      </div>
      <!--新增门店-->
      <el-dialog class="minMop" title="新增中心店" :visible.sync="customer">
        <el-form class="pr-10" :rules="rules" ref="form" :model="form">
          <el-form-item label="手机号:" class="ml-20" prop="loginName">
            <el-input
              class="ml-10 mr-10"
              v-model="form.loginName"
              placeholder="手机号"
              :maxlength="11"
            ></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="danger" @click="addCustomer('form')">确定</el-button>
          <el-button class="ml-20" @click="customer = false">取消</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { axiosApi, basePath } from "../../../api/api";
import { MessageBox } from "element-ui";
export default {
  data() {
    return {
      customer: false, //新增门店弹框
      formDown:{
          sellerId:'',
          supercType:'',
      },
      Form: {
        storeName: "",
        loginName: ""
      },
      form: {
        loginName: ""
      },
      searchForm: {
        companyName: "",
        loginName: ""
      },
      tableData: [], //客户数据
      currentPage: 1,
      total: 0, //页面分页总数
      Page: 1,
      rules: {
        loginName: [
          {
            required: true,
            message: "手机号为必填项"
          }
        ]
      }
    };
  },
  watch: {},
  mounted() {
    this.$nextTick(() => {
        this.formDown = {
            companyId: sessionStorage.traderId,
            supercType: 3,
        }
      this.achieve()
    });
  },
  computed: {},
  methods: {
    toDetails(id,storeName){
      this.$router.push({path:'/customer/supercStoreManageTwo',query:{id:id,storeName:storeName}})
    },
    achieve(val) {
      //分页查询酒店协议公司
      if (val && this.currentPage != 1) {
        this.currentPage = 1;
        return;
      }
      axiosApi(
        "/superc/tenantShopApply/page",
        {
          sellerId: sessionStorage.traderId,
          storeName: this.Form.storeName,
          loginName:this.Form.loginName
        },
        res => {
          this.tableData = res.data.data ? res.data.data.elements : [];
          this.total = res.data.data.totalCount;
        },
        this.currentPage,
        10,
        res => {}
      );
    },
    addCustomer(form) {
      //增加酒店协议公司
      this.$refs[form].validate(valid => {
        if (valid) {
          axiosApi(
            "/superc/tenantShopApply/insert",
            {
              loginName: this.form.loginName
            },
            res => {
              this.achieve();
              this.$message({
                type: "success",
                message: "新增成功",
                duration: 3000
              });
              this.customer = false;
            },
            1,
            10,
            res => {
              // this.customer = false;
              this.$message({
                type: "info",
                message: res.data.message,
                duration: 3000
              });
            }
          );
        } else {
        }
      });
    },
    openCustomer(form) {
      this.customer = true;
      if (this.$refs[form] !== undefined) {
        this.$refs[form].resetFields();
      }
    },
    //禁用，启用
    isForbbident(val) {
      let title = val.row.state == 4 ? "启用" : "禁用";
      let that = this;
      this.$confirm("您是否要" + title + "该客户？", "提示信息", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          const api = "/superc/tenantShopApplyState/update";
          axiosApi(
            api,
            {
              traderId: val.row.traderId,
              state: val.row.state == 4 ? 2 : 4
            },
            res => {
              this.$message({
                type: "success",
                message: "操作成功!",
                duration: 1000
              });
              that.achieve();
            },
            this.currentPage,
            10,
            res => {
              this.$message({
                type: "info",
                message: res.data.message,
                duration: 3000
              });
            }
          );
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作"
          });
        });
    },
    handleCurrentChange(val) {
      //分页查询
      this.currentPage = val;
      this.achieve();
    },
    handleChange(val) {
      //弹窗分页查询
      this.Page = val;
      this.getSalesList();
    }
  }
};
</script>

<style lang="less">
#contractCustomer {
  .content {
    .el-form--inline {
      // width: 650px;
      .el-form-item__label {
        font-size: 12px;
        width: 60px;
      }
      .order-datatimerange {
        width: 240px;
      }
    }
    .el-button + .el-button {
      margin-left: 0;
    }
    .el-table .cell {
      text-align: center;
      padding: 10px 0;
      img {
        width: 60px;
        height: 60px;
        margin-left: 10px;
        margin-right: 10px;
      }
      .width-130 {
        width: 130px;
        text-align: left;
      }
      .el-button {
        height: 20px;
        width: 80px;
      }
    }
    .el-form-item__label {
      width: 100px;
    }
    .reject {
      .el-dialog {
        width: 900px;
        .el-dialog__body {
          min-height: 420px;
        }
      }
      .el-form-item__error {
        margin-left: 100px;
      }
    }
  }
  .el-dialog {
    min-height: 300px !important;
  }
  .el-form-item__error {
    margin-left: 110px !important;
  }
  .bindSales {
    .tc {
      text-align: center;
    }
    .el-dialog {
      width: 900px;
      min-height: 700px !important;
    }
    .el-dialog__body {
      padding: 20px;
      .search {
        .el-form-item__label {
          width: 80px;
        }
        .el-form--inline {
          width: 350px !important;
        }
      }
    }
  }
}
</style>
