<template>
	<div class="step1 width-1280">
		<div class="mb-50 mt-50">
			<!-- <img class="sub mr-10" src="../../assets/register.png" /> -->
			<i class="iconfont icon-inspector color-b font-18 mr-10"></i>
			<span class="color-6 font-16">2/3修改登录密码</span>
		</div>
		<el-form :model="form" :rules="rules" ref="form" label-width='340px'>
			<el-form-item label="设置密码:" prop="pass">
				<el-input :type="showPass?'text':'password'" @keyup.native="isCaps($event)" @blur="capB1 = false" @focus="isCaps($event)" v-model="form.pass" :maxlength="20" auto-complete="off">
					<i slot="suffix" class="el-icon-view" @click="showPass=!showPass"></i>
				</el-input>
				<img class="cap" v-if="capB1" src="../../../assets/caps.png" />
				<div v-if="form.pass.length>=6" id="tips"><span></span><span></span><span></span><span></span></div>
			</el-form-item>
			<el-form-item label="确认密码:" prop="checkPass">
				<el-input :type="showCheckPass?'text':'password'" @keyup.native="isCaps2($event)" @blur="capB2 = false" @focus="isCaps2($event)" v-model="form.checkPass" :maxlength="20" >
					<i slot="suffix" class="el-icon-view" @click="showCheckPass=!showCheckPass"></i>
				</el-input>
				<img class="cap" v-if="capB2" src="../../../assets/caps.png" />
			</el-form-item>
			<el-form-item>
				<div style="width: 240px;font-size: 12px;line-height: 1.2;">
					密码必须是6-20位，且数字、大写字母、小写字母、标点符号至少包含两种。
				</div>
			</el-form-item>
			<el-form-item>
			</el-form-item>
			<el-form-item class="mt-50" label="">
				<el-button type="danger" @click="goPage('form')">下一步</el-button>
			</el-form-item>
		</el-form>
	</div>
</template>

<script>
	import { axiosApi, basePath } from "../../../api/api"
	import { passcheck, capsisopen ,passfocus} from "../../../utils/passcheck.js"
	var md5 = require("md5");
	function validatePassword(password) {
		const types = {
			digit: /\d/,
			upper: /[A-Z]/,
			lower: /[a-z]/,
			symbol: /[\W_]/ // 标点符号和其他符号
		};
		const requiredTypes = Object.values(types);
		const isValidLength = password.length >= 6 && password.length <= 20;
		const hasRequiredTypes = requiredTypes.some(regex => regex.test(password));
		const hasTwoTypes = requiredTypes.filter(regex => regex.test(password)).length >= 2;
	
		return isValidLength && hasRequiredTypes && hasTwoTypes;
	}
	export default {
		data() {
			var validatePass = (rule, value, callback) => {
				if(value === '') {
					callback(new Error('请输入密码'));
				} else {

					const regex = /^(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*]).{6,20}$/;

					
					if(validatePassword(value)){
						if(this.form.checkPass !== '') {
							this.$refs.form.validateField('checkPass');
						}
						callback();
					}else{
						callback(new Error('密码必须是6-20位，且数字、大写字母、小写字母、标点符号至少包含两种。'));
					}
					
				}
			};
			var validatePass2 = (rule, value, callback) => {
				if(value === '') {
					callback(new Error('请再次输入密码'));
				} else if(value !== this.form.pass) {
					callback(new Error('两次输入密码不一致!'));
				} else {
					callback();
				}
			};
			return {
				form: {
					pass: "",
					checkPass: ""
				},
				capB1: false, //判断大小写键是否打开
				capB2: false, //判断大小写键是否打开
				showPass: false,
				showCheckPass: false,
				rules: {
					pass: [{
						required: true,
						validator: validatePass,
						trigger: 'blur'
					}],
					checkPass: [{
						required: true,
						validator: validatePass2,
						trigger: 'blur'
					}],
				}
			}
		},
		methods: {
			isCaps(e) { //检测大小写键是否打开
				this.capB1 = passfocus()
				if(this.form.pass.length >= 6) {
					passcheck(this.form.pass) //检测密码强度
				}
				this.capB1 = capsisopen(e, this.form.pass) //检测大小写键是否打开
			},
			isCaps2(e) {
				this.capB2 = passfocus()
				this.capB2 = capsisopen(e, this.form.checkPass) //检测大小写键是否打开
			},
			goPage(form) {
				this.$refs[form].validate((valid) => {
					if(valid) {
						const api = "/unlogin/employee/resetPwd"
						const body = {
							loginName: sessionStorage.mobile,
							mobile: sessionStorage.mobile,
							newLoginPwd: md5(this.form.pass),
							appId: 2
						}
						axiosApi(api, body, (res) => {
							if(res.data.data == null) {
								this.$router.push(basePath + "/register/changePassThird");
							}
						}, 1, 10, (res) => {
							if(res.data.code == "TR0058") {
								this.isCode = true;
							}
						})
					} else {
						// console.log('error submit!!');
						return false;
					}
				});
			},

		}
	}
</script>