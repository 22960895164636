<template>
   <div>
    <div class="title-top">
			出库单
      <el-button class="fr mr-30 mt-17" @click="addNewHouseSn" v-if="showButton('ADD', 200131)">添加出库单</el-button>
		</div>
    <div class="bg-f pb-50 pt-30">
			<stock-house-ware-com operateType="0"></stock-house-ware-com>
    </div>
  </div>
</template>

<script>
import { axiosApi, basePath } from "../../api/api";
import stockHouseWareCom from './stockHouseWareCom.vue';
export default {
  data() {
    return {

    }
  },
  components: {
    stockHouseWareCom: stockHouseWareCom
  },
  methods: {
    addNewHouseSn() {
      this.$router.push({path: basePath + '/stockHouseOrderAdd/0'})
    }
  }
}
</script>

<style>

</style>

