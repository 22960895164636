<template>
	<div id="inventory" class="pb-50">
		<div class="title-top bg-f">
			库存管理
		</div>
		<div class="bg-f mt-5 pb-50">
			<el-form :inline="true" :model="formInlineData" label-width="90px" class="clearfix pl-20 pt-30 search">
				<div class="fl width-70p">
					<el-form-item label="商品编码">
						<el-input v-model.trim="formInlineData.goodsNo"></el-input>
					</el-form-item>
					<el-form-item label="商品名称">
						<el-input v-model.trim="formInlineData.skuName"></el-input>
					</el-form-item>
					<el-form-item label="商品分类">
						<el-select v-model="formInlineData.classifyName" clearable>
							<el-option :label='item.classifyName' :value="item.classifyName" v-for='item in searchClassify' :key="item.classifyName"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="商品状态">
						<el-select v-model="formInlineData.goodsState" placeholder="选择状态" clearable :disabled='dis'>
							<el-option label="待上架" value='2'></el-option>
							<el-option label="出售中" value='1'></el-option>
						</el-select>
					</el-form-item>
					<el-form-item v-if='isShow1' label="K3仓库">
						<el-select v-model="formInlineData.storeType" placeholder="选择仓库" clearable>
							<el-option label="统一仓" value='1'></el-option>
							<el-option label="超团特殊仓" value='2'></el-option>
						</el-select>
					</el-form-item>
				</div>
				<el-button type="info" class="fl ml-50" @click='achieve1(true)'>搜索</el-button>
			</el-form>
			<div class="clearfix tar mr-50 pr-10">
				<el-button type='text' @click="handDown">批量导出</el-button>
				<el-button class='ml-0' type='text' @click='allSynchronization'>同步</el-button>
			</div>
			<el-table :data="tableData" v-loading="Loading" class='ml-30 mt-10 table-default mr-30' @selection-change="handleSelectionChange" stripe>
				<el-table-column type="selection" width="40"></el-table-column>
				<el-table-column prop="1" label="商品名称" width="262">
					<template slot-scope="scope">
						<div class="fl ml-10">
							<img v-if='scope.row.imageUrl' :src="scope.row.imageUrl" />
							<img v-else src="../../assets/goods.jpg" />
						</div>
						<div class="fl ml-10 mt-10">
							<div class="tal w-160">商品编码:{{scope.row.goodsNo}}</div>
							<div class="tal w-160">{{scope.row.skuName}}</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column label="规格" width="70">
					<template slot-scope="scope">
						<div>1*{{scope.row.specInfoNum}}</div>
					</template>
				</el-table-column>
				<el-table-column label="商品分类" width="70" prop='classifyName'></el-table-column>
				<el-table-column label="商品品牌" width="70" prop='brandName'></el-table-column>
				<el-table-column v-if='isShow1' label="K3仓库" width="70">
					<template slot-scope="scope">
						<span v-if="scope.row.erpType != 'K3'">--</span>
						<span v-if="scope.row.storeType == '1' && scope.row.erpType == 'K3'">统一仓</span>
						<span v-if="scope.row.storeType  == '2' && scope.row.erpType == 'K3'">超团特殊仓</span>
					</template>
				</el-table-column>
				<el-table-column label="状态" width="70">
					<template slot-scope="scope">
						<span v-if="scope.row.goodsState == '2'">待上架</span>
						<span v-if="scope.row.goodsState == '1'">出售中</span>
					</template>
				</el-table-column>
				<el-table-column label="可用库存" width="80" prop='stockEnableQuantity'></el-table-column>
				<el-table-column label="库存" width="80" prop='stockQuantity'></el-table-column>
				<el-table-column label="操作">
					<template slot-scope="scope">
						<el-button type="text" class='font-12 width-90' @click="synchronization(scope.row.skuNo)">同步</el-button>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination class="tar mr-30" v-if="total>0" :current-page="currentPage" :page-size='10' :total='total' layout="prev , pager, next, jumper" @current-change="handleCurrentChange">
				<!-- 分页组件 -->
			</el-pagination>
		</div>
		<!-- 导出 -->
		<form ref="downClientForm2" action='/sunrise-gateway/export/groupon/goodsStock/list' style="display: none">
			<input type="hidden" name="modelName" v-model="a">
			<input type="hidden" name="fileName" v-model="b">
			<input type="hidden" name="startRow" v-model="c">
			<input type="hidden" name="goodsNo" v-model="formInlineData.goodsNo">
			<input type="hidden" name="skuNoList" v-model="lists">
			<input type="hidden" name="skuName" v-model="formInlineData.skuName">
			<input type="hidden" name="classifyName" v-model="formInlineData.classifyName">
			<input type="hidden" name="goodsState" v-model="formInlineData.goodsState">
			<input type="hidden" name="storeNo" v-model="formInlineData.storeNo">
			<input type="hidden" name="storeType" v-model="formInlineData.storeType">
		</form>

	</div>
</template>

<script>
	import axios from 'axios';
	import { axiosApi, basePath, baseURI } from "../../api/api";
	export default {
		data() {
			return {
				a: 'empty',
				b: '库存管理.xls',
				c:0,
				basePath: basePath,
				Loading: true,
				formInlineData: { 
					id:'',
					goodsNo:'',
					skuName:'',
					classifyName:'',
					goodsState:'',
					storeNo:'',
					storeType:''
				},
				tableData: [], //商品列表
				searchClassify: [], //搜索分类
				//				分页
				currentPage: 1,
				total: 0,
				isPagination: true,
				multipleSelection: [],
				classifyName: null,
				brandName: null,
				classifyId: null,
				brandId: null,
				dis: false,
				multipleSelectionIdList: [],
				erpType:1,
				lists:'',
				isShow1:false
			}
		},
		methods: {
			handDown(){
				if(this.tableData.length > 0){
					this.$refs.downClientForm2.submit();
					this.multipleSelectionIdList = []
					this.achieve()
				}else{
					this.$message.info('暂无数据导出!');
				}
			},
			//加载商品列表
			achieve() {
				const api = "/groupon/erpStock/selectPage";
				const body = {
					goodsNo:this.formInlineData.goodsNo,
					skuName:this.formInlineData.skuName,
					classifyName:this.formInlineData.classifyName,
					goodsState:this.formInlineData.goodsState,
					storeType:this.formInlineData.storeType,
					storeNo:this.formInlineData.storeNo,
				}
				axiosApi(api, body, this.callback, this.currentPage, 10)
			},
			callback(res) {
				this.tableData = res.data.data.elements ? res.data.data.elements : [];
				this.total = res.data.data.totalCount ? res.data.data.totalCount : '';
				this.Loading = false;
			},
			//查询
			achieve1(bool) {
				if(bool&&this.currentPage != 1) {
					this.currentPage = 1;
					return
				}
				const api = "/groupon/erpStock/selectPage";
				const body = {
					goodsNo:this.formInlineData.goodsNo,
					skuName:this.formInlineData.skuName,
					classifyName:this.formInlineData.classifyName,
					goodsState:this.formInlineData.goodsState,
					storeType:this.formInlineData.storeType,
					storeNo:this.formInlineData.storeNo,
				}
				axiosApi(api, body, this.callback3, this.currentPage, 10)
			},
			callback3(res) {
				this.tableData = res.data.data.elements ? res.data.data.elements : [];
				this.total = res.data.data.totalCount ? res.data.data.totalCount : '';
				this.currentPage = res.data.data.pageIndex;
			},
			//			分页
			handleCurrentChange(val) {
				this.currentPage = val
				this.achieve1()
			},
			//查询分类
			classSearch() {
				const api = "/goods/classifyTopList/select";
				const body = {
					classificationId: ''
				}
				axiosApi(api, body, this.classsearch)
			},
			classsearch(res) {
				this.searchClassify = res.data.data;
			},
			handleSelectionChange(val) { //table 选中行
				this.multipleSelectionIdList = val.map((item)=>{
						return item.skuNo
				})
				this.multipleSelection = this.multipleSelectionIdList
				this.lists = this.multipleSelectionIdList.join(',')
			},
//			单个同步库存
			synchronization(val){
					axiosApi('/groupon/erpStock/update', {
						skuNo:val
					}, (res) => {
						this.$message.success('同步成功!');
						this.achieve()
					}, this.currentPage, 10, (res) => {
						this.$message.info(res.data.message);
					});
			},
			//批量同步
			allSynchronization(){
				this.Loading = true
				axiosApi('/groupon/erpStock/batchUpdate', {
						skuNoList:this.multipleSelection
					}, (res) => {
						this.$message.success('同步成功!');
						this.achieve()
					}, 1, 10, (res) => {
						this.$message.info(res.data.message);
					});
			},
				//是否显示仓库
			isShow(){
				axiosApi('/groupon/chooseStore/display', {
						
					}, (res) => {
						this.isShow1 = res.data.data
					}, 1, 10, (res) => {
						this.$message.info(res.data.message);
					});
			},

		},
		//进入加载
		activated() {
			this.$nextTick(function() {
				this.achieve();
				this.classSearch();
				this.isShow()
			})
		}
	}
</script>

<style lang="less">
	#inventory {
		.table-default .el-button {
			width: auto;
		}
		.el-tabs__header {
			padding-left: 0;
		}
		.w-160 {
			width: 160px;
		}
		.el-dialog {
			min-height: 300px;
		}
	}
</style>