<template>
  <!--久集商品管理-->
  <div id="distributionGoods">
    <div class="title-top bg-f mb-5">{{$route.query.storeName}}酒店商品列表</div>
    <div class="title-tabs bg-f" style="position: relative;">
      <ul class="clearfix">
        <template v-for='(tab,index) in dataTabs' class="item">
          <li :type='index' :class="type==index?'active':''" @click='handleTabs'>
            {{tab}}
            <div :class="type==index?'under-line':''"></div>
          </li>
          <div class="vertical-line"></div>
        </template>
      </ul>
      <el-badge class="count" :max="99" :value="count" :hidden="count == 0"/>
    </div>
    <div class="content bg-f pr-30 pl-30">
      <div class="clearfix search">
        <el-form :inline='true' class="pt-30 fl" :model="Form" ref="searchForm">
          <el-form-item label='商品名称'>
            <el-input v-model.trim="Form.skuName" placeholder="请输入商品名称" :maxlength="30"></el-input>
          </el-form-item>
          <el-form-item class="mr-30" label='商品分类'>
            <el-select v-model="Form.classifyId" placeholder="请选择商品分类" clearable>
              <el-option v-for='(tab,index) in classList' :label="tab.classifyName" :value="tab.classifyId" :key='index'></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <el-button class="fl ml-30 mt-30" type='info' @click="achieve(true)">搜索</el-button>
      </div>
      <div class='tar clearfix'>
        <el-button type='text' class="fr" @click="showOneKey()">批量设置积分</el-button>
      </div>
      <div class="pb-30">
        <el-table :data="tableData" @selection-change="handleSelectionChange" stripe>
          <el-table-column key="a" label='商品名称' min-width="230">
            <template slot-scope="scope">
              <div class="fl specialD">
                <img v-if="scope.row.fileUrl" :src="scope.row.fileUrl" alt=""/>
                <img v-else src="../../../assets/goods.jpg" alt=""/>
                <img v-if="scope.row.storeType=='2'" class="special" src="../../../assets/special.png" alt=""/>
              </div>
              <div class="fl lh-16">
                <div class="width-130">
                  商品编码:<span v-if="scope.row.goodsNo">{{scope.row.goodsNo}}</span>
                  <span v-else>--</span>
                </div>
                <div class="width-130" show-overflow-tooltip>{{scope.row.skuName}}</div>
                <div class="color-9 width-130">规格:{{scope.row.specification}}</div>
                <div v-if='scope.row.storeType == "2" && scope.row.limitMonth && scope.row.limitNum'
                     class="width-130 color-r">
                  限购：每{{scope.row.limitMonth}}月{{scope.row.limitNum}}{{scope.row.units}}
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column key="b" label='商品分类' :min-width="itemWidth">
            <template slot-scope="scope">
              <span>{{scope.row.classifyName}}</span>
            </template>
          </el-table-column>
          <el-table-column key="c" label='促销价' :min-width="itemWidth">
            <template slot-scope="scope">
              <span v-if="scope.row.promotionPrice">￥{{scope.row.promotionPrice||0 | fmoney }}</span>
              <span v-else>-</span>
            </template>
          </el-table-column>
          <el-table-column key="d" label='最低零售价' :min-width="itemWidth">
            <template slot-scope="scope">
              <span v-if="scope.row.minSellingPrice">￥{{scope.row.minSellingPrice||0 | fmoney }}</span>
              <span v-else>-</span>
            </template>
          </el-table-column>
          <el-table-column key="e" label='标准零售价' :min-width="itemWidth">
            <template slot-scope="scope">
              <span v-if="scope.row.maxSellingPrice">￥{{scope.row.maxSellingPrice||0 | fmoney }}</span>
              <span v-else>-</span>
            </template>
          </el-table-column>
          <el-table-column key="f" label='销售员提成' :min-width="itemWidth">
            <template slot-scope="scope">
              <span v-if="scope.row.rewardValue == 0 || scope.row.rewardValue">{{scope.row.rewardValue}}{{scope.row.rewardType == '1' ? '%' :'分'}}</span>
              <span v-else>--</span>
            </template>
          </el-table-column>
          <el-table-column key="g" v-if="isAdmin === '1'" label='酒店利润' :min-width="itemWidth">
            <template slot-scope="scope">
              <span>￥{{scope.row.hotelCommission | fmoney}}</span>
            </template>
          </el-table-column>
          <el-table-column key="h" v-if="isAdmin === '1'" label='销售员利润' :min-width="itemWidth">
            <template slot-scope="scope">
              <span>￥{{scope.row.rewardAfterCalculate | fmoney}}</span>
            </template>
          </el-table-column>
          <el-table-column key="i" v-if="isAdmin === '1'" label='进店价' :min-width="itemWidth">
            <template slot-scope="scope">
              <span>￥{{scope.row.unitDiscountPrice | fmoney}}</span>
            </template>
          </el-table-column>
          <el-table-column key="j" label='最低起订量' :min-width="itemWidth">
            <template slot-scope="scope">
              <span>{{scope.row.isIntegerMultiple ? scope.row.minimunMoq * scope.row.specInfoNum : scope.row.minimunMoq}}{{scope.row.units}}</span>
            </template>
          </el-table-column>
          <el-table-column key="k" label='促销费' :min-width="itemWidth">
            <template slot-scope="scope">
              ￥{{scope.row.unitPromotionDiscountsPrice||0 | fmoney}}
            </template>
          </el-table-column>
          <el-table-column key="l" label='操作' :min-width="itemWidth" align="center">
            <template slot-scope="scope">
              <el-button type="text" @click="setMark(scope)">设置</el-button>
              <el-button type="text" v-if="type==0" @click="handleUp(scope)">上架</el-button>
              <el-button type="text" v-if="type==1" @click="handleUp(scope)">下架</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination class="tar" v-if="tableData.length>0" :current-page="currentPage" :page-size='10' :total='total'
                       layout="prev , pager, next, jumper" @current-change="handleCurrentChange">
          <!-- 分页组件 -->
        </el-pagination>
      </div>
      <!--设置-->
      <el-dialog class="minMop" title='设置' :visible.sync='salesMark'>
        <el-form ref="markMarks" class="pr-10" :model="markForm" :rules="rules">
          <el-form-item label="销售员积分:" class="ml-20" required>
            <el-form-item class="ml-15" prop="rewardValue">
              <el-input size="mini" class="ml-10 mr-10" v-model="markForm.rewardValue" placeholder="请输入"></el-input>
              <el-select v-model="markForm.rewardType" size="mini">
                <el-option label="%" value='1'></el-option>
                <el-option label="分" value='2'></el-option>
              </el-select>
            </el-form-item>
          </el-form-item>
          <el-form-item label="运费补贴:" class="ml-20 freightDiscounts-money" prop="freightDiscountsMoney">
            <el-input size="mini" class="ml-10 mr-10" v-model="markForm.freightDiscountsMoney"
                      placeholder="请输入金额"></el-input>
            <span class="color-6">元/{{units}}</span>
            <div class="color-6 ml-100">（用于补贴消费者的运费，由酒店承担）</div>
          </el-form-item>
        </el-form>
        <div slot='footer' class="dialog-footer">
          <el-button type='danger' @click="setMarks('markMarks', 1)">保存</el-button>
          <el-button v-if="type == '0'" class="ml-20" @click="setMarks('markMarks', 2)">保存并上架</el-button>
        </div>
      </el-dialog>
      <!--一键设置积分-->
      <el-dialog class="minMop" title='批量设置积分' :visible.sync='salesOneKeyMark'>
        <el-form ref="markOneKeyMarks" class="pr-30 ml50" :model="markForm" :rules="rules">
          <el-form-item label="销售员积分:" class="ml-20" required>
            <el-form-item class="ml-15" prop="rewardValue">
              <el-input size="mini" class="ml-10 mr-10" v-model="markForm.rewardValue" placeholder="请输入"></el-input>&nbsp;%
            </el-form-item>
            <p class="ml-10 mt-10">（一键设置积分后会适用全部商品）</p>
          </el-form-item>
        </el-form>
        <div slot='footer' class="dialog-footer">
          <el-button type='danger' @click="setOneKeyMarks('markOneKeyMarks', 1)">保存</el-button>
          <el-button v-if="type == '0'" class="ml-20" @click="setOneKeyMarks('markOneKeyMarks', 2)">保存并上架
          </el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
  import {axiosApi, basePath} from "../../../api/api";
  import {MessageBox} from 'element-ui'

  export default {
    data() {
      let reg1 = /^([0-9]|([1-9]\d)|(100))(\.\d{1,2})?$/
      let reg2 = /^(\d|[1-9]\d|100)(\.\d{1,2})?$/
      let reg3 = /^([0]|[1-9]\d{0,3}|10000)$/

      var unitNums = (rule, value, callback) => {
        if ((!value && value !== 0)) {
          callback(new Error('请输入销售员积分'));
        } else if (value && this.markForm.rewardType == 1) {
          if (!reg2.test(value)) {
            callback(new Error('请输入0-100数字'));
          } else {
            callback();
          }
        } else if (value && this.markForm.rewardType == 2) {
          if (!reg3.test(value)) {
            callback(new Error('请输入0-10000数字'));
          } else {
            callback();
          }
        } else {
          callback();
        }
      }
      return {
        rules: {
          rewardValue: [{
            validator: unitNums,
            trigger: 'blur'
          }],
          freightDiscountsMoney: [{
            pattern: /^([1-9][\d]{0,2}|0)(\.[\d]{1,2})?$/,
            message: '请输入正确金额，最大999.99元',
            trigger: 'blur'
          }]
        },
        type: '0',
        dataTabs: ['未上架', '已上架'],
        //搜索表单
        Form: {
          skuName: null, //商品名称
          classifyId: null, //商品分类
          skuState: '1' //商品状态
        },
        tableData: [], //商品数据
        classList: [], //分类数据
        skuNoList: [], //上下架商品列表
        skuState: null, //1下架2上架
        stateText: '',//上下架文案
        currentPage: 1,
        total: null, //页面分页总数
        reject: false, //设价弹窗
        totalCount: null, //弹窗分页总数
        //设置积分弹框数据
        markForm: {
          rewardType: '1',
          rewardValue: '',
          freightDiscountsMoney: 0
        },
        idList: [], //设置积分商品id
        salesMark: false, //销售员积分弹框
        salesOneKeyMark: false, //一键设置销售员积分弹框
        count: '',//角标数据
        units: null,
        isAdmin: '1',
        itemWidth: 65
      }
    },
    watch: {},
    created() {
      this.isAdmin = sessionStorage.isAdmin
      if (this.isAdmin === '1') {
        this.itemWidth = 65
      } else {
        this.itemWidth = 70
      }
    },
    mounted() {
      this.$nextTick(() => {
        this.achieve()
        this.getClassList()
      })
    },
    computed: {},
    methods: {
      achieve(val) {
        if (val && this.currentPage != 1) {
          this.currentPage = 1;
          return
        }
        axiosApi('/superc/hotelGoods/selectPageList', {
          skuState: this.Form.skuState,
          goodsNo: this.Form.goodsNo,
          skuName: this.Form.skuName,
          classifyId: this.Form.classifyId,
          hotelId: this.$route.query.traderId,
        }, (res) => {
          this.tableData = res.data.data.elements ? res.data.data.elements : []
          this.total = res.data.data.totalCount
          this.getCount()
        }, this.currentPage, 10, (res) => {

        })
      },
      getCount() { //角标数量统计
        axiosApi("/superc/hotelGoods/count", {
          skuState: '2',
          hotelId: this.$route.query.traderId,
        }, (res) => {
          this.count = res.data.data.count
        }, 1, 10, (res) => {
          this.$message({
            type: 'info',
            message: res.data.message,
            duration: 3000
          });
        });
      },
      //设置积分数据处理
      setMark(val) {
        if (this.$refs.markMarks !== undefined) {
          this.$refs.markMarks.resetFields();
        }
        this.idList = []
        this.idList.push(val.row.id)
        this.markForm.rewardType = val.row.rewardType == null ? '1' : val.row.rewardType.toString()
        this.markForm.rewardValue = val.row.rewardValue
        this.markForm.freightDiscountsMoney = val.row.freightDiscountsMoney
        this.salesMark = true
        this.units = val.row.units
      },
      //设置
      setMarks(markMarks, status) {
        this.$refs[markMarks].validate((valid) => {
          if (valid) {
            axiosApi('/superc/hotelGoods/setReward', {
              hotelId: this.$route.query.traderId,
              idList: this.idList,
              rewardValue: this.markForm.rewardValue,
              rewardType: this.markForm.rewardType,
              updateSkuState: status == 1 ? false : true, // true：保存并上架 false：保存不上架
              freightDiscountsMoney: this.markForm.freightDiscountsMoney
            }, (res) => {
              this.achieve()
              this.salesMark = false
              this.$message({
                type: 'success',
                message: '操作成功',
                duration: 3000
              });
            }, this.currentPage, 10, (res) => {
              this.$message({
                type: 'info',
                message: res.data.message,
                duration: 3000
              });
              this.salesMark = false
            })
          }
        })
      },
      handleTabs(e) { //Tab切换
        if (e.target.attributes.type) this.type = e.target.attributes.type.value
        this.Form = {
          skuName: null, //商品名称
          classifyId: null
        }
        if (this.type == '0') { //未上架
          this.Form.skuState = '1'
          this.achieve(1)
        } else if (this.type == '1') { //已上架
          this.Form.skuState = '2'
          this.achieve(1)
        }
      },
      handleSelectionCheck(val) { //选中的状态
        this.skuNoList = [];
        val.forEach(item => {
          this.skuNoList.push(item.skuNo)
        })
      },
      handleCurrentChange(val) { //分页查询
        this.currentPage = val;
        this.achieve()
      },
      getClassList() { //获取分类列表
        axiosApi("/superc/classify/selectFirstClassifyList", {}, (res) => {
          this.classList = res.data.data;
        }, 1, 100, (res) => {
          this.$message({
            type: 'info',
            message: res.data.message,
            duration: 3000
          });
        });
      },
      handleSelectionChange(val) { /*table全选*/
        //console.log(val, '选中值')
        this.skuNoList = []; //清空每次全选时添加的订单编号
        this.idStr = []; //清空每次全选时添加的订单Id
        val.forEach(item => {
          this.skuNoList.push(item.skuNo)
          this.idStr.push(item.id)
        })
      },
      handleUp(scope) { //上下架
        if (scope.row.skuState == 1) {
          this.skuState = 2
          this.stateText = "上架"
        } else if (scope.row.skuState == 2) {
          this.skuState = 1
          this.stateText = "下架"
        }
        ;
        if (scope.row.id) { //单个商品上下架数据处理
          this.skuNoList = []; //处理每次添加的订单编号
          this.skuNoList.push(scope.row.skuNo);
        }
        ;
        const h = this.$createElement
        this.$msgbox({
          title: '提示信息',
          message: h('p', {
            style: 'text-align:center'
          }, '确认' + this.stateText + '该商品？'),
          showCancelButton: true,
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          beforeClose: (action, instance, done) => {
            if (action === 'confirm') {
              if (this.skuNoList.length > 0) {
                axiosApi("/superc/hotelGoods/updateState", {
                  hotelId: this.$route.query.traderId,
                  skuState: this.skuState,
                  skuNoList: this.skuNoList
                }, (res) => {
                  this.skuNoList = []; //清空存储的商品编号
                  this.achieve(); //刷新列表
                  this.$message({
                    type: 'success',
                    message: '操作成功',
                    duration: 3000
                  });
                }, 1, 10, (res) => {
                  this.$message({
                    type: 'info',
                    message: res.data.message,
                    duration: 3000
                  });
                });
              } else {
                this.$message({
                  type: 'info',
                  message: '请选择商品',
                  duration: 3000
                });
              }
              done()
            } else {
              done()
            }
            delete instance.$slots.default;
          }
        })
      },
      showOneKey() {
        this.salesOneKeyMark = true
        this.markForm = {
          rewardValue: "",
          rewardType: "1",
          freightDiscountsMoney: 0
        }
      },
      setOneKeyMarks(markOneKeyMarks, status) {
        this.$refs[markOneKeyMarks].validate((valid) => {
          if (valid) {
            if (status == 1) {
              axiosApi('/superc/hotelGoods/batchSetReward', {
                // idList: this.idList,
                skuState: this.type == '0' ? 1 : 2,
                hotelId: this.$route.query.traderId,
                rewardValue: this.markForm.rewardValue,
                updateSkuState: status == 1 ? false : true, // true：保存并上架 false：保存不上架
              }, (res) => {
                this.achieve()
                this.salesOneKeyMark = false
                this.$message({
                  type: 'success',
                  message: '操作成功',
                  duration: 3000
                });
              }, this.currentPage, 10, (res) => {
                this.$message({
                  type: 'info',
                  message: res.data.message,
                  duration: 3000
                });
                this.salesOneKeyMark = false
              })
            } else if (status == 2) {
              axiosApi('/superc/hotelGoods/checkBatchSetReward', {
                hotelId: this.$route.query.traderId,
              }, (res) => {
                if (res.data.data) {
                  const h = this.$createElement
                  this.$msgbox({
                    title: '提示信息',
                    message: h('p', {style: 'text-align:center'}, '有' + res.data.data + '款商品不满足上架条件，是否将符合条件的商品先行上架'),
                    showCancelButton: true,
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    beforeClose: (action, instance, done) => {
                      if (action === 'confirm') {
                        axiosApi('/superc/hotelGoods/batchSetReward', {
                          // idList: this.idList,
                          skuState: this.type == '0' ? 1 : 2,
                          hotelId: this.$route.query.traderId,
                          rewardValue: this.markForm.rewardValue,
                          updateSkuState: true, // true：保存并上架 false：保存不上架
                        }, (res) => {
                          this.achieve()
                          this.salesOneKeyMark = false
                          this.$message({
                            type: 'success',
                            message: '操作成功',
                            duration: 3000
                          });
                        }, this.currentPage, 10, (res) => {
                          this.$message({
                            type: 'info',
                            message: res.data.message,
                            duration: 3000
                          });
                          this.salesOneKeyMark = false
                        })
                        // this.salesOneKeyMark = false
                        done()
                      } else {
                        this.salesOneKeyMark = false
                        done()
                      }
                      delete instance.$slots.default;
                    }
                  })
                } else {
                  axiosApi('/superc/hotelGoods/batchSetReward', {
                    // idList: this.idList,
                    skuState: this.type == '0' ? 1 : 2,
                    hotelId: this.$route.query.traderId,
                    rewardValue: this.markForm.rewardValue,
                    updateSkuState: true, // true：保存并上架 false：保存不上架
                  }, (res) => {
                    this.achieve()
                    this.salesOneKeyMark = false
                    this.$message({
                      type: 'success',
                      message: '操作成功',
                      duration: 3000
                    });
                  }, this.currentPage, 10, (res) => {
                    this.$message({
                      type: 'info',
                      message: res.data.message,
                      duration: 3000
                    });
                    this.salesOneKeyMark = false
                  })
                }
              }, this.currentPage, 10, (res) => {
                this.$message({
                  type: 'info',
                  message: res.data.message,
                  duration: 3000
                });
              })

            }
          }
        })
      },
    }
  }
</script>

<style lang="less">
  #distributionGoods {
    .content {
      .el-form--inline {
        width: 650px;

        .el-form-item__label {
          font-size: 12px;
          width: 60px;
        }

        .order-datatimerange {
          width: 240px;
        }
      }

      .el-button + .el-button {
        margin-left: 0;
      }

      .el-table .cell {
        text-align: center;
        padding: 10px 0;

        img {
            display: inline-block;
          width: 60px;
          height: 60px;
          margin-left: 10px;
          margin-right: 10px;
        }

        .width-130 {
          width: 140px;
          text-align: left;
        }

        .el-button {
          height: 20px;
        }
      }

      .el-form-item__label {
        width: 100px;
      }

      .reject {
        .el-dialog{
          width: 900px;

          .el-dialog__body {
            min-height: 100px;
            margin-left: 50px;
          }
        }

        .el-form-item__error {
          margin-left: 100px;
        }
      }

      .goods {
        .el-dialog__body {
          padding-top: 30px;
        }

        .el-dialog {
          width: 968px;
        }
      }
    }

    .city {
      position: absolute;
      z-index: 10;
      width: 335px;
      padding-top: 10px;
      top: 30px;
      left: 0;
      background: #ffffc3;

      .el-checkbox {
        margin: 5px;
      }
    }

    .w125 {
      width: 125px;
      position: relative;
    }

    .w100 {
      width: 100px;
    }

    .zindex {
      z-index: 20;
    }

    .w400 {
      width: 400px;
    }

    .el_label {
      width: 100px;
      padding: 0px 10px 11px 0;
      height: 32px;
      line-height: 32px;
      text-align: right;
      vertical-align: middle;
      float: left;
      font-size: 14px;
      color: #48576a;
      box-sizing: border-box;

      img {
        width: 15px;
        height: 15px;
        margin-left: 5px;
        vertical-align: sub;
      }
    }

    .minMop {
      .el-dialog {
        width: 520px;
        min-height: 300px;

        .el-form-item__error {
          left: 37px;
        }
      }
    }

    .el-form-item__error {
      left: 95px !important;
    }

    .count {
      position: absolute;
      top: 15px;
      left: 150px;
    }

    .freightDiscounts-money {
      .el-form-item__error {
        left: 110px !important;
      }
    }

    .ml50 {
      margin-left: 100px;
    }

    .el-message-box__content {
      padding: 30px 35px;
    }

    .specialD {
      position: relative;

      .special {
        position: absolute;
        top: 0;
        left: 0;
      }
    }

    .color-r {
      color: red;
    }
  }
</style>
