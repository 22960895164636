<template>
  <div class="default-freight pb-50">
    <div class="title-top bg-f">默认运费设置</div>

    <div class="pl-20 pt-30 pb-50 bg-f mt-5">
      <el-form ref="form" :model="temForm" :rules="rules">
        <el-form-item label="默认运费模版">
          <el-select v-model="temForm.templateId" placeholder="请选择默认运费模版">
            <el-option v-for="i in options" :label="i.name" :value="i.id" :key="i.id"></el-option>
          </el-select>
          <el-button type="text" @click="lookTemplate">查看</el-button>
        </el-form-item>
        <span class="color-9">（改变默认运费模版会覆盖所有门店久集久加久商品中的运费，请谨慎使用）</span>

        <el-form-item class="mt-20">
          <el-button class="mt-50 ml-100" type="primary" @click="submit" :loading="btnLoading">确定</el-button>
        </el-form-item>
      </el-form>
    </div>

  </div>
</template>

<script>
  import { axiosApi, basePath } from "../../../api/api";
  export default {
    data() {
      return {
        temForm: {
          templateId: ''//模版id
        },
        rules: {
          region: [
            { required: true, message: '请选择模板', trigger: 'change' }
          ]
        },
        options: [],
        btnLoading: false
      }
    },
    methods: {
      achieve() {
        axiosApi('/foundation/fare/templateSelectList', {
          traderId: sessionStorage.traderId,
          noDetails: true,
          businessType:'superc'
        }, res => {
          if (res.data.data.elements) {
            this.options = res.data.data.elements.map(v => {
              return {
                id: v.id,
                name: v.name,
                isDefault: v.isDefault
              }
            })
            for(let i = 0; i < this.options.length; i++) {
              if (this.options[i].isDefault) {
                this.temForm.templateId = this.options[i].id
                return false;
              }
            }
          }
        }, 1,100, err => {
          this.$message.error(err.data.message)
        })
      },
      lookTemplate() {
        if (!this.temForm.templateId) {
          return false;
        }
        window.open(basePath + '/freightHandle?type=1&id=' + this.temForm.templateId, '_blank')
      },
      submit() {
        if (!this.temForm.templateId) {
          return false;
        }
        this.$confirm('该模板会覆盖久集中所有商品，是否确定?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.btnLoading = true;
          axiosApi('/foundation/fare/setTraderDefault', {
            fareId: this.temForm.templateId,
            businessType:'superc'
          }, res => {
            this.btnLoading = false;
            this.$message({
              message: '默认模板设置成功！',
              type: 'success',
              duration: 2000
            })
          }, 1, 10, err=> {
            this.btnLoading = false;
            this.$message.error(err.data.message)
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          });          
        });

      }
    },
    mounted() {
      this.achieve()
    }
  }
</script>

<style lang="less">
  .default-freight {
    .el-form-item__label {
      width: 100px;
    }
  }
</style>
