<template>
	<div id="editPeople">
		<div style="background:#fff;margin-bottom:20px;padding:10px 0px">
			<el-tabs v-model="activeId">
				<el-tab-pane label="基本信息" name="0"></el-tab-pane>
				<el-tab-pane label="权限" name="1"></el-tab-pane>
			</el-tabs>
		</div>
		<div class="main bg-f" style="padding-top:20px" v-if="activeId==='0'">
			<el-form :model='form' label-width='130px' :rules="rules" ref="form" class='ml-100'>
				<el-form-item label="登录账号:" prop="loginName">
					<el-input v-model.trim='form.loginName' :maxlength="20" disabled></el-input> 
          <!-- <el-button type="text" v-if="!isAdmin" @click="changeLoginName">修改</el-button> -->
				</el-form-item>
				<el-form-item label="绑定手机:" prop="mobile">
					<el-input placeholder="请输入手机号码" v-model.trim='form.mobile' disabled :maxlength="11"></el-input>
				</el-form-item>
				<el-form-item label="姓名:" prop="employeeName">
					<el-input placeholder="" :minlength="1" :maxlength="16" v-model.trim="form.employeeName" @blur="getSalerInfo"></el-input>
				</el-form-item>
				<el-form-item label="当前头像:">
					<span class="fl">
						<el-upload class="avatar-uploader" action="/sunrise-gateway/oss/ossUpload" :show-file-list="false" :on-success="handleAvatarScucess" :before-upload="beforeAvatarUpload">
							<img v-if='form.headportraitUrl' class='icon-90' :src="form.headportraitUrl" >
							<i v-else class="el-icon-plus avatar-uploader-icon icon-90"></i>
						</el-upload>
					</span>
				</el-form-item>
				<el-form-item label="业务联系电话:" >
					<el-input v-model.trim="form.businessMobile"></el-input>
				</el-form-item>
				<el-form-item label="业务联系邮箱:">
					<el-input v-model.trim="form.email"></el-input>
				</el-form-item>
				<el-form-item label="E名片自定义字段:">
					<div class="listInfoDiv" v-for="(item,index) in form.profession" :key="index">
						<el-input v-model.trim="form.profession[index]" maxlength="20"></el-input>
						<i class="el-icon-circle-plus infoBtn plusBtn" @click="plusClick" v-if="(form.profession.length-1)==index&&form.profession.length!=2"/>
						<i class="el-icon-remove infoBtn removeBtn" @click="removeClick(index)" v-if="form.profession.length!=1"/>
					</div>
				</el-form-item>

				<el-form-item label="联系地址:">
					<selectArea v-model="form.businessDistrictPath"/>
				</el-form-item>
				
				<el-form-item label="">
					<el-input  v-model.trim="form.businessAddress"></el-input>
				</el-form-item>








				
				<!-- <el-form-item label="状态:">
					<el-radio class="radio" v-model="form.radio2" :label="0">正常</el-radio>
					<el-radio class="radio" v-model="form.radio2" :label="1">禁用</el-radio>
				</el-form-item> -->
				<el-form-item label="职位:" prop="position">
					<el-input placeholder="请输入职位" v-model.trim='form.position'></el-input>
				</el-form-item>
				
				<el-form-item label="所属区域:">
					<el-checkbox v-model="checked">不限</el-checkbox>
				</el-form-item>
				<el-form-item>
					<el-cascader clearable :options="searchDepartmentList" :props="config" show-all-levels :disabled='checked' placeholder='请选择地区' v-model='form.areas' change-on-select></el-cascader>
				</el-form-item>
				<el-form-item label="设为部门负责人:">
					<el-radio class="radio" v-model="form.radio1" :label="1">是&nbsp;&nbsp;&nbsp; </el-radio>
					<el-radio class="radio" v-model="form.radio1" :label="0">否</el-radio>
				</el-form-item>
				<el-form-item label="所属默认部门:" prop="dep">
					<el-cascader clearable :options="data3" :props="config" show-all-levels v-model='form.dep' placeholder='请选择部门' change-on-select></el-cascader>
					<span class="ml-20 color-blue cursor" @click='goPage("addPartment")'>添加部门</span>
				</el-form-item>
				<el-form-item label="角色:" v-if="!isAdmin">
					<el-cascader clearable :options="roleLists" :props="config" show-all-levels v-model='form.roleId' placeholder='请选择角色' @change='getRole' change-on-select></el-cascader>
					<span class="ml-20 color-blue cursor" @click='goPage("addRole")'>添加角色</span>
				</el-form-item>
				<!--新增员工编码 部门编码-->
				<el-form-item label="部门编码:" v-if="!isCityPartner">
					<!-- <el-input placeholder="请输入K3的部门编码" v-model="form.k3Dept" :maxlength="20"></el-input> -->
					<el-select v-model="form.k3Dept" clearable placeholder="K3的部门编码">
						<el-option
							v-for="item in salerInfoList"
							:key="item.k3Dept"
							:label="item.k3Dept"
							:value="item.k3Dept">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="员工编码:"  v-if="!isCityPartner">
					<!-- <el-input placeholder="请输入K3的员工编码" v-model="form.k3Saler" :maxlength="20"></el-input> -->
					<el-select v-model="form.k3Saler" clearable placeholder="K3的员工编码">
						<el-option
							v-for="item in salerInfoList"
							:key="item.k3Saler"
							:label="item.k3Saler"
							:value="item.k3Saler">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item v-if="FormData.managerId" label="云销定位:" prop="isNeedPosition">
					<el-radio class="radio" v-model="form.radio3" :label="1">开启定位&nbsp;&nbsp;&nbsp; </el-radio>
					<el-radio class="radio" v-model="form.radio3" :label="0">无需定位</el-radio>
				</el-form-item>

				<el-form-item class='mt-50'>
					<el-button @click.native="EditPeople('form')" type="danger" class='mr-30'>保存</el-button>
					<el-button @click='goPage("cancel")'>取消</el-button>
				</el-form-item>
			</el-form>
		</div>
		<div class="divBg" v-else>
			<el-form class="mb-50 clearfix width-640 " v-for="(item,index1) in list" :key="index1">
				<fieldset class="bd">
					<legend class="ml-10 pl-20 pr-20">
						<el-checkbox class='mr-40 mb-10' v-model="item.checkAll" disabled>{{item.text}}</el-checkbox>
					</legend>
					<div v-for='(item,index) in item.sub' :key="index">
						<el-checkbox class="color-3 mr-30 mb-10 mt-10 fl ml-15 w-600" v-model="item.checked" :label="item.id" disabled>{{item.text}}</el-checkbox>
						<span class="fl ml-30">
							<el-checkbox-group v-model="item.checkedAreas"> 
								<el-checkbox class='width-110 mb-10 hides'  v-if="!isCityPartner||item.id!='200158'" :title="item.text" v-for='(item, index3) in item.sub' :key="index3" :label="item.id" disabled>{{item.text}}</el-checkbox>
							</el-checkbox-group>
						</span>
					</div>
				</fieldset>
			</el-form>
		</div>
    <!-- 修改登录账号 -->
    <!-- <el-dialog 
      class="change_account"
      title="修改登录账号"
      size="tiny"
      :visible.sync="dialogVisible"
      width="600px"
      :before-close="handleClose">
      <div class="content">
        <el-form label-width='130px' :model="accountForm" :rules="rules" ref="accountForm">
          <el-form-item label="登录账号" prop="loginName">
            <el-input class="loginName_input" placeholder="账号仅支持中英文、数字，且不能为纯数字，长度为2-20字符"  v-model.trim="accountForm.loginName" :maxlength="20"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handleConfirm">确 定</el-button>
        <el-button @click="handleCancle">取 消</el-button>
      </span>
    </el-dialog> -->
	</div>
</template>

<script>
	import { MessageBox } from 'element-ui';
	import {PROJECT} from '~/config/index.js';
	import { axiosApi, basePath } from "../../../api/api";
	export default {
		data() {
			//正则判断登录账号
			var loginName = (rule, value, callback) => {
				let reg = /^[A-Za-z0-9\u4e00-\u9fa5]{2,20}$/,
					notreg = /^[\d]{1,20}$/
				if(!value) {
					return callback(new Error('登录账号不可为空'));
				}
				setTimeout(() => {
					if(value === 'admin'||value === '管理员') {
						return callback(new Error('登录账号包含被系统屏蔽的字符'));
					}
					if(!reg.test(value)) {
						return callback(new Error('登录账号长度为2-20个字符,不能含有特殊字符及“管理员”等保留字'));
					} else {
						// if(notreg.test(value)){
						// 	return callback(new Error('登录账号不能为纯数字'))
						// }
						callback();
					}
				}, 500);
			};
      		//正则判断手机号码
			var mobile = (rule, value, callback) => {
				let reg = /^1\d{10}$/
				if(!value) return callback(new Error('手机号码不可为空'));
				setTimeout(() => {
				if(!reg.test(value)){
					return callback(new Error('手机号码不正确'));
				}else{
					return callback()
				}
				})
			}
			return {
				loading: false,
				selectLoading: false, // 姓名搜索选择框
				salerInfoList: [],
				fourLists: [],
				activeId: '0',
				// dialogVisible: false,// 修改登录账号visible
				// accountForm:{
				// 	loginName: ''// 登录账户
				// },
				idd: 'XX',
				sign: 2,
				areas: [],
				bool: false,
				disabled: true,
				list: [], //权限列表
				data3: [], //部门列表
				roleLists: [], //所有角色
				id: '',
				roleName: '', //角色名
				roleId: '', //角色Id
				departmentId: '', //部门id
				checked: true,
				options: [],
				dep: '', //接收字符串部门id
				area: '', //接收字符串地区id
				areaa: [],
				info: {},
				form: { //修改信息双向数据绑定
					id: '',
					loginName: '',
					mobile: '',
					employeeName: '',
					headportraitUrl: '',
					businessMobile: '',
					businessAddress: '',
					email: '',
					position: '',
					areas: [],
					role: [],
					radio1: null, //设为部门负责人
					// radio2: null, //状态
					radio3: null, //状态
					dep: [], //部门双向绑定
					roleId: [],
					isAdmin: 1,
					k3Dept:'',
					k3Saler:'',
					businessDistrictPath: [],
					profession:[''], // 名片自定义数组
				}, //修改信息双向数据绑定
				FormData: {}, //单个员工信息初始
				id: '', //获取员工ID
				loginName: '',
				searchDepartmentList: [], //搜索部门
				config: {
					label: "text",
					children: "sub",
					value: "id"
				},
				rules: {
					loginName: [{
						required: true,
						validator: loginName,
						trigger: 'blur'
					}],
					mobile: [{
						required: true,
						validator: mobile,
						trigger: 'blur'
					}],
					employeeName: [{
						required: true,
						message: '不能为空',
						trigger: 'blur'
					}],
					position: [{
						required: true,
						message: '不能为空',
						trigger: 'blur'
					}],
					dep: [{
						required: true,
						type: "array",
						message: '不能为空',
						trigger: 'blur'
					}],
					roleId: [{
						required: true,
						type: "array",
						message: '不能为空',
						trigger: 'blur'
					}]
				},
				path: { path: basePath + "/people" },
				isAdmin: false
			}
		},
        computed:{
            isCityPartner() {
                return this.$store.state.clientType.isCityPartner;
            }
        },
		methods: {
			plusClick(){
				if(this.form.profession.length<2){
					this.form.profession.push('')
				}
			},
			removeClick(index){
				if(this.form.profession.length>1){
					this.form.profession.splice(index,1)
				}
			},
			//上传头像
			beforeAvatarUpload(file) {
				this.loading = true;
				const isImage = (file.type.indexOf('jpg') != -1 || file.type.indexOf('jpeg') != -1 || file.type.indexOf('png') != -1 || file.type.indexOf('bmp') != -1);
				const isLt4M = file.size / 1024 / 1024 < 4 && file.size / 1024 / 1024 > 0;
				if(!isImage) {
					this.loading = false;
					this.$message.error('上传图片只能是jpg,jpeg,png,bmp格式');
				}
				if(!isLt4M) {
					this.loading = false;
					this.$message.error('上传图片大小不能超过 4MB并大于0MB');
				}
				return isImage && isLt4M;
			},
			handleAvatarScucess(res) {
				this.loading = false;
				this.form.headportraitUrl = res.data[0];
			},
			// 修改登录账号
			// changeLoginName(){
			// 	this.dialogVisible = true
			// 	this.accountForm.loginName = this.form.loginName + ''
			// },
			// 修改登录账户
			// handleClose(done){
			// 	this.dialogVisible = false
			// 	done()
			// },
			// 修改登录账户确定
			// handleConfirm(){
			// 	this.$refs.accountForm.validate(valid => {
			// 	if(!valid) return false
			// 	this.form.loginName = this.accountForm.loginName + ''
			// 	this.dialogVisible = false
			// 	})
			// },
			// 修改登录账户取消
			// handleCancle(){
			// 	this.dialogVisible = false
			// 	this.accountForm.loginName = ''
			// },
			//页面跳转
			goPage: function(path) {
				if(path == 'addRole') {
					sessionStorage.setItem('info', JSON.stringify(this.form));
					this.$router.push(basePath + '/addRole/' + this.sign);
				}
				if(path == 'addPartment') {
					sessionStorage.setItem('info', JSON.stringify(this.form));
					this.$router.push(basePath + '/dep/addPartment/' + this.idd + '/' + this.sign);
				}
				if(path == 'cancel') {
					history.go(-1)
				}
			},
			//得到已缓存的信息
			getInfo() {
				if(this.$route.params.sign) {
					this.info = sessionStorage.getItem("info");
					this.form = JSON.parse(this.info);
					console.log(this.form,'xx')
					this.getRole()
					this.isAdmin = this.form.isAdmin == 1 ? true : false; //是否是管理员
					if(this.isAdmin) {
						this.form.roleId = [0];
					}
					setTimeout(() => {
						this.achieveRole()
					}, 0)
					sessionStorage.removeItem("info");
				}
			},
			//			获取loginName
			gethash() {
				var test = window.location.href;
        		this.loginName = decodeURI(test.split('=')[1]);
			},
			//进入页面判断不同入口
			achieveAll() {
				if(this.$route.params.sign) {
					this.getInfo()
				} else {
					this.achieve()
				}
			},
			//			获取单个员工信息
			achieve(index, rows) {
				const api = "/foundation/employee/selectOneByLoginName";
				axiosApi(api, { loginName: this.loginName, appId: 2 }, this.callback1, this.currentPage, 10)
			},
			callback1(res) {				
				this.FormData = res.data.data;
				this.achieveOne()
			},
			achieveOne() {
				let _this = this
				this.form.id = this.FormData.id;
				this.form.loginName = this.FormData.loginName;
				this.form.mobile = this.FormData.mobile;
				this.form.employeeName = this.FormData.employeeName;
				this.form.position = this.FormData.position;
				this.form.radio1 = this.FormData.isDepartmentLeader;
				this.form.isAdmin = this.FormData.isAdmin;
				// this.form.radio2 = this.FormData.isDisable;
				this.form.radio3 = this.FormData.isNeedPosition
				this.form.k3Saler = this.FormData.k3Saler;
				this.form.k3Dept = this.FormData.k3Dept
				this.dep = this.FormData.departmentPath;
				this.form.headportraitUrl = this.FormData.headportraitUrl
				this.form.businessAddress = this.FormData.businessAddress
				this.form.businessMobile = this.FormData.businessMobile
				this.form.email = this.FormData.email
				if(this.FormData.profession.length!=0){
					// 防止空数组
					this.form.profession = this.FormData.profession
				}
				this.form.businessDistrictPath = this.FormData.businessDistrictPath
				// if (this.FormData.businessDistrictPath != null&&this.FormData.businessDistrictPath!='') {
        //   var idList = this.FormData.businessDistrictPath.split(",");
				// 	this.form.businessDistrictPath= idList
        // }else{
        //   this.form.businessDistrictPath = []
        // }

				this.area = this.FormData.districtPath ? this.FormData.districtPath : null;
				this.FormData.roles.forEach(function(item, index) {
					_this.roleId = item.id,
						_this.form.roleId.push(_this.roleId)
				})
				this.isAdmin = this.form.isAdmin == 1 ? true : false; //是否是管理员
				if(this.isAdmin) {
					this.form.roleId = [0];
				}
				if(this.area) {
					this.checked = false
				} else {
					this.checked = true
				}
				setTimeout(() => {
					this.changeArr()
					this.achieveList()
					this.achieveRole(); //获取所有角色
					this.getSalerInfo()
				}, 0)
			},
			//数据格式转化
			changeArr() {
				if(this.dep) {
					if(this.dep.indexOf(",") != -1) {
						this.form.dep = this.dep.split(",").map((item) => { return parseInt(item); }); //把字符串转化为字符串数组并转化为数字数组
					} else {
						let arr = []
						arr.push(parseInt(this.dep))
						this.form.dep = arr
					}
				}
				if(!this.checked) {
					this.form.areas = this.area.split(",").map((item) => { return parseInt(item); }); //把字符串转化为字符串数组并转化为数字数组
				} else {
					this.form.areas = [0] //这个需要数组 
				}
			},
			//查询地区
			searchAreas() {
				const api = "/foundation/district/displayTree";
				axiosApi(api, {}, this.searchAreas1, this.currentPage, 10)
			},
			searchAreas1(res) {
				this.searchDepartmentList = res.data.data
				this.searchDepartmentList = this.cascaderClear(this.searchDepartmentList)
			},
			//编辑员工
			EditPeople(form) {
				this.$refs[form].validate((valid) => {
					if(valid) {
						let _this = this
						if(this.checked == false) {
							this.areaa = this.form.areas[this.form.areas.length - 1];

						} else {
							this.areaa = 0
						}
						let depp = this.form.dep[this.form.dep.length - 1];
						const api = "/foundation/employee/update";
						const body = {
							id: this.form.id,
							loginName: this.form.loginName,
							mobile: this.form.mobile,
							employeeName: this.form.employeeName,
							position: this.form.position,
							isDepartmentLeader: this.form.radio1,
							// isDisable: this.form.radio2.toString(),
							roles: this.isAdmin ? [] : this.form.roleId,
							departmentId: depp,
							districtId: this.areaa,
							k3Saler:this.form.k3Saler,
							k3Dept:this.form.k3Dept,
							appId: 2,
							isNeedPosition: this.form.radio3,
							headportraitUrl:this.form.headportraitUrl,
							businessAddress:this.form.businessAddress,
							businessMobile:this.form.businessMobile,
							email: this.form.email,
							businessDistrictPath: this.form.businessDistrictPath,
						}
						// 去除空字符串
						body.profession = this.form.profession.filter(item=>{
							return item!=''
						})

						// if(this.form.businessDistrictPath&&this.form.businessDistrictPath.length>0){
							
						// 	body.businessDistrictPath = this.form.businessDistrictPath.join(',')
						// }else{
						// 	body.businessDistrictPath = null
						// }

						



						axiosApi(api, body, this.callback6, this.currentPage, 10, function(res) {
							MessageBox.alert(res.data.message, '提示信息', {
								confirmButtonText: '确定'
							});
						})
					} else {
						this.$confirm('请正确填写信息！', '提示信息', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							showCancelButton: false,
							type: 'warning'
						})
						return false;
					}
				});
			},
			callback6(res) {
				this.$message({
					type: 'success',
					message: '保存成功!',
					duration: 1000
				});
				this.$router.push(basePath + '/people')
			},
			//			获取角色列表
			achieveRole() {
				const api = "/foundation/role/selectAll";
				axiosApi(api, { isDisplay: 1, applicationType: 2, traderId: sessionStorage.traderId }, this.achieveRole1, this.currentPage, 10)
			},
			achieveRole1(res) {
				this.roleLists = res.data.data ? res.data.data : [];
				// if(this.isAdmin) this.roleLists.push({ id: 0, text: '系统管理员' })
				this.roleLists.forEach(item=>{
					if(item.sub.length == 0){
						item.sub = null
					}
				})
			},
			getRole() {
                this.roleId = this.form.roleId[0]
				this.achieveList()
			},
			//			获取所有权限
			achieveList() {
				const api = "/foundation/resource/displaytree";
				axiosApi(api, { loginName: sessionStorage.loginName, appId: 2, hostPrefix: PROJECT.hostPrefix }, (res) => {
					this.list = res.data.data ?  [].concat(res.data.data.normalMenuTree,res.data.data.applicationMenuTreeList[0].applicationMenuTree,res.data.data.applicationMenuTreeList[1].applicationMenuTree) : [];
					this.checked1()
					this.achieveOneList();
				}, this.currentPage, 10)
			},
			//			单个角色权限
			achieveOneList() {
				if(this.isAdmin) {
					this.roleId = 0
				}
        const api = "/foundation/roleresourcerelation/selectList";
				axiosApi(api, { roleId: this.roleId }, (res) => {
					this.onelist = res.data.data ? res.data.data : [];
					this.add();
				}, this.currentPage, 10)
			},
			checked1() {
				let _this = this;
				this.list.forEach(function(item, index) {
					if(typeof item.checkAll == "undefined") {
						_this.$set(item, "checkAll", false)
					}
					if(typeof item.allAreas == "undefined") {
						_this.$set(item, "allAreas", [])
					}
					if(item.sub) {
						for(let i = 0; i < item.sub.length; i++) {
							if(typeof item.sub[i].checked == "undefined") {
								_this.$set(item.sub[i], "checked", false)
							}
							if(typeof item.sub[i].checkedAreas == "undefined") {
								_this.$set(item.sub[i], "checkedAreas", [])
							}
						}
					}
				})
			},
			//权限数据渲染（打钩）
			add() {
				if(this.isAdmin) {
					let _this = this
					_this.list.forEach(function(item) {
						item.checkAll = true
						if(item.sub) {
							for(let i = 0; i < item.sub.length; i++) {
								item.sub[i].checked = true
								if(item.sub[i].sub) {
									for(let j = 0; j < item.sub[i].sub.length; j++) {
										let checkedAreas = [];
										checkedAreas.push(item.sub[i].sub[j].id)
										item.sub[i].checkedAreas = item.sub[i].checkedAreas.concat(checkedAreas);
									}
								}
							}
						}
					})
				} else {
					let _this = this
					this.onelist.forEach(function(item, index) {
						_this.list.forEach(function(item1, index1) {
							if(item.resourceId == item1.id) {
								item1.checkAll = true
							}
							if(item1.sub) {
								for(let i = 0; i < item1.sub.length; i++) {
									if(item.resourceId == item1.sub[i].id) {
										item1.sub[i].checked = true
									}
									if(item1.sub[i].sub) {
										for(let j = 0; j < item1.sub[i].sub.length; j++) {
											if(item.resourceId == item1.sub[i].sub[j].id) {
												let checkedAreas = [];
												checkedAreas.push(item.resourceId)
												item1.sub[i].checkedAreas = item1.sub[i].checkedAreas.concat(checkedAreas);
											}
										}
									} else {
										if(item.resourceId == item1.sub[i].id) {
											item1.sub[i].checked = true
										}

									}
								}
							}
						})
					})
				}
			},
			//获取所有部门
			getDepList() {
				const api = "/foundation/department/displayTree";
				axiosApi(api, { applicationType: 2, traderId: sessionStorage.traderId }, (res) => {
					this.data3 = res.data.data ? res.data.data : [];
					this.data3 = this.cascaderClear(this.data3)
				}, this.currentPage, 10)
			},
			//获取部门编码&员工编码
			getSalerInfo() {
				const api = "/foundation/employee/salerInfo/list";
				axiosApi(api, {employeeName:this.form.employeeName}, (res) => {
					if(res.data.code==='000000'){
						this.salerInfoList = res.data.data
					}else{
						this.salerInfoList = []
					}
				}, null,null,err=>{
				})
			}
		},
		//进入加载
		mounted() {
			this.$nextTick(() => {
				this.gethash();
				this.achieveAll(); //进入页面加载判断
				this.searchAreas(); //获取所有地区
				//				this.achieveRole(); //获取所有角色
				this.achieveList(); //获取所有权限
				this.getDepList(); //获取所有部门
			})
		}
	};
</script>

<style lang="less">
	#editPeople {
		.listInfoDiv{
			display: flex;
			align-items: center;
			margin: 5px 0;
			.infoBtn{
				font-size: 30px;
				cursor: pointer;
			}
			.plusBtn{
				color: #409eff;
			}
			.removeBtn{
				color: red;
			}
		}
    .change_account{
      .loginName_input{
        width: 350px;
        .el-input__inner{
          font-size: 12px;
        }
      }
      .el-dialog{
        .el-dialog__header{
          position: relative;
          .el-dialog__title{
            margin-left: 0;
          }
          .el-dialog__headerbtn{
            position: absolute;
            top:16px;
          }
        }
        .el-dialog__body{
          padding: 50px 40px 30px;
          font-size: 12px;
        }
        .el-dialog__footer{
          position: static;
          transform: translateX(0);
          padding: 10px 20px 40px;
        }
      }
			
		}
		.el-checkbox__label {
			display:inline !important;
		}
		.el-checkbox__input.is-disabled+.el-checkbox__label {
			color: #333;
		}
		.el-checkbox__input.is-disabled.is-checked .el-checkbox__inner {
			background-color: #20a0ff;
			border-color: #20a0ff;
		}
		.el-tabs__item {
			width: 100px;
			text-align: center;
			/*background: gray;*/
		}
		.w-600 {
			width: 600px;
			display: block;
		}
		.el-cascader-menu {
			min-width: 240px !important;
		}
		.el-checkbox {
			margin-left: 0;
		}
		.hides {
			overflow: hidden;
			/*自动隐藏文字*/
			text-overflow: ellipsis;
			/*文字隐藏后添加省略号*/
			white-space: nowrap;
		}
		.width-110 {
			width: 110px;
		}
		legend label {
			margin-right: 0px !important;
			line-height: initial;
			margin-bottom: 0px !important;
		}
		.divBg{
			background: #fff;
			padding: 10px;
			padding-top:20px ;
		}
		.avatar-uploader-icon {
			border: 1px solid #ddd;
			width: 90px;
			height: 90px;
			line-height: 90px;
			font-size: 30px;
		}
		.el-upload--picture-card {
			width: 90px;
			height: 90px;
		}
		.el-upload-list--picture-card .el-upload-list__item {
			width: 90px;
			height: 90px;
		}
	}
</style>