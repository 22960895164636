<template>
  <div id="accountInfo">
    <div class="main pl-100 pl-20 pt-50 pb-50 mt-5 bg-f">
      <el-form
        class="ml-100"
        :model="form"
        label-width="130px"
        :rules="rules"
        ref="form"
      >
        <el-form-item label="用户名:">
          <el-input
            class="fl"
            v-model.trim="form.loginName"
            :disabled="true"
          ></el-input>
        </el-form-item>
        <el-form-item label="当前头像:">
          <span class="fl">
            <el-upload
              disabled
              class="avatar-uploader"
              action="/sunrise-gateway/oss/ossUpload"
              :show-file-list="false"
              :on-success="handleAvatarScucess"
              :before-upload="beforeAvatarUpload"
            >
              <img
                v-if="form.headportraitUrl"
                class="icon-90"
                :src="form.headportraitUrl"
              />
              <i v-else class="el-icon-plus avatar-uploader-icon icon-90"></i>
            </el-upload>
          </span>
        </el-form-item>
        <el-form-item label="姓名:" prop="employeeName">
          <el-input
            class="fl"
            disabled
            v-model.trim="form.employeeName"
          ></el-input>
        </el-form-item>
        <el-form-item label="业务联系电话:" prop="businessMobile">
          <el-input disabled v-model.trim="form.businessMobile"></el-input>
        </el-form-item>
        <el-form-item label="业务联系邮箱:" prop="email">
          <el-input disabled v-model.trim="form.email"></el-input>
        </el-form-item>
        <div class="clearfix">
          <el-form-item label="联系地址:" class="fl">
            <selectArea v-model="form.businessDistrictPath" :disabled="true" />
          </el-form-item>
        </div>
        <el-form-item label="" prop="businessAddress">
          <el-input disabled v-model.trim="form.businessAddress"></el-input>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { axiosApi, basePath } from "../../../api/api";
export default {
  props: ["parentForm"],
  watch: {
    parentForm: {
      handler: function (val) {
        let arrayNew = [];
        this.form = this.$deepClone(val);
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      fourListsDelivey: [], //四级地址列表
      form: {},
      streetId: "",
      subForm: {},
      rules: {
        employeeName: [
          {
            pattern: /^[\u4e00-\u9fa5a-zA-Z]{2,6}$/,
            message: "只能输入汉字或英文，长度在2-6位",
            trigger: "blur",
          },
        ],
        businessMobile: [
          {
            pattern: /^((0\d{2,3}-?\d{7,8})|(\d{7,8})|(1\d{10}))$/,
            message: "请输入正确的号码",
            trigger: "blur",
          },
        ],
        email: [
          //						{ pattern: /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/, message: '请输入正确的邮箱格式', trigger: 'blur' },
          {
            pattern:
              /^(?=\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$).{0,20}$/,
            message: "请输入正确的邮箱格式,且长度不能大于20",
            trigger: "blur",
          },
        ],
        businessAddress: [
          {
            min: 4,
            max: 50,
            message: "详细地址长度在4-50",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    //重置
    handReset() {
      this.$confirm("确定要重置?", "提示信息", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$emit("reset");
          // this.$message({
          // 	type: 'success',
          // 	message: '重置成功！'
          // });
          // this.achieve();
        })
        .catch(() => {});
    },
    //上传头像
    beforeAvatarUpload(file) {
      this.loading = true;
      const isImage =
        file.type.indexOf("jpg") != -1 ||
        file.type.indexOf("jpeg") != -1 ||
        file.type.indexOf("png") != -1 ||
        file.type.indexOf("bmp") != -1;
      const isLt4M = file.size / 1024 / 1024 < 4 && file.size / 1024 / 1024 > 0;
      if (!isImage) {
        this.loading = false;
        this.$message.error("上传图片只能是jpg,jpeg,png,bmp格式");
      }
      if (!isLt4M) {
        this.loading = false;
        this.$message.error("上传图片大小不能超过 4MB并大于0MB");
      }
      return isImage && isLt4M;
    },
    handleAvatarScucess(res) {
      this.loading = false;
      this.form.headportraitUrl = res.data[0];
    },
  },
  mounted() {
    // this.$nextTick(function() {
    // 	this.achieve()
    // })
  },
};
</script>

<style lang='less'>
#accountInfo {
  .avatar-uploader-icon {
    border: 1px solid #ddd;
    width: 90px;
    height: 90px;
    line-height: 90px;
    font-size: 30px;
  }
  .el-upload--picture-card {
    width: 90px;
    height: 90px;
  }
  .el-upload-list--picture-card .el-upload-list__item {
    width: 90px;
    height: 90px;
  }
  .own {
    width: 200px !important;
    .el-form-item__content {
      margin-left: 15px !important;
    }
  }
}
</style>