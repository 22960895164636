<template>
    <div class="user-protocol-dialog-container">
        <el-dialog
            :visible.sync="dialogVisible"
            class="mdialog"
            :before-close="handleDialogClose"
            :close-on-press-escape="false"
            :close-on-click-modal="false"
            :show-close="false"
        >
            <div :style="{
                height : height,
            }" class="content">
                <p class="container-1">欢迎登录久加久平台，请阅读并确认变更后的协议</p>
                <p class="container-2">
                    <router-link target="_blank" :to='basePath + "/aggrement?isForce=true"'>《久加久平台服务协议》</router-link>
                </p>
            </div>
            <div class="footer">
                <el-button @click="handleOk" type="primary">同意</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import cont from './aggrement.vue';
    import { axiosApi,gotoLoginPage,basePath,baseURI } from '~/api/api.js'

    export default {
        components : {cont},
        data() {
            return {
                /* dialogVisible : true, */
                height : '80px',
            }
        },
        computed : {
            dialogVisible : function(){
                return this.$store.state.homeSearch.mUserProtocolDialogState;
            },
        },
        mounted() {
            // 初始化容器height
            /* let height = window.innerHeight;
            let a = height - (height * 0.1) - 50 - 50 - 40 - 52 - 30 + 'px';
            this.height = a; */
        },
        methods : {
            // 关闭浮层 执行退出账户逻辑
            handleDialogClose() {
                axiosApi("/employee/logout", {}, res => {
                    gotoLoginPage();
                });
            },
            handleOk() {
                axiosApi('/trader/agreement/change',{
                    
                },(res) => {
                    let data = res.data.data;
                    this.$store.commit('setUserProtocolDialog',false);
                    /* window.mUserProtocolDialogNotify.close(); */
                },1,1,(err) => {
                    this.$message.warning(err.data.message);
                });
            },
        },
    }
</script>

<style lang="less">
    .user-protocol-dialog-container{
        .mdialog{
            /* z-index: 999999999999!important; */
        }
        .content{
            /* border: 1px solid #ddd; */
            padding: 10px;
            color: #666666;
            overflow-y: auto;
            /* overflow-y: auto;
            max-height: 350px; */
            .width-1280{
                width: auto;
            }
            .container-1{
                text-align: center;
            }
            .container-2{
                text-align: center;
                margin-top: 20px;
                a{
                    color : #20a0ff;
                }
            }
        }
        .footer{
            padding-top: 20px;
            text-align: center;
        }
        /* 修改弹出框样式 */
        .mdialog{
            .el-dialog__header{
                /* display: none; */
                border-bottom: none;
            }
            .el-dialog{
                top: 15%!important;
                min-height: auto!important;
                width: 400px!important;
            }
            .el-dialog__body{
                padding: 20px
            }
            .el-tabs__header{
                padding-left: 79px!important;
            }
            .el-dialog__footer{
                width: 100%!important;
                bottom: 68px!important;
            }
            .el-dialog__title{
                margin-left: 0px;
            }
        }
    }
</style>