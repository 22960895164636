import {axiosApi,basePath} from '../../api/api.js'
import {Notification} from 'element-ui'
import m from '../../main.js'

const clientBrand = {
    state: {
        brandList: [],
        notId: []
    },
    mutations: {
        //设置品牌列表
        setBrandList(state, payload) {
            state.brandList.push(...payload);
        },
        delBrand(state, payload) {
            // for (var i = 0;i < state.brandList.length;i++) {
            //     if(state.brandList[i].id == payload.id){
            //         state.brandList.splice(i, 1)
            //     }
            // }

            state.brandList.splice(payload.index, 1)
        },
        resetBrandList(state, payload) {
            state.brandList = [];
        },
    },
    actions: { 
        //设置商品列表
        addgood({state,commit}, payload) {
            
        }
    },
    getters: {
        notId(state) {
            return state.brandList.map((n)=> {
                return n.brandId
            })
        }
    }
}

export default clientBrand