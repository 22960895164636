<template>
  <div>
    <el-table :data="listInfo">
      <el-table-column label="流水号" prop="relationSn" ></el-table-column>
      <el-table-column label="操作时间" prop="showTime" width="140"></el-table-column>
      <el-table-column label="积分变化" prop="modifiedIntegration" ></el-table-column>
      <!-- <el-table-column label="本期预估积分" prop="afterTotalIntegration"></el-table-column> -->
      <el-table-column label="业务操作" prop="typeDescr"></el-table-column>
      <el-table-column label="说明" show-overflow-tooltip>
        <template slot-scope="{row}">
          <span>{{ row.explain }} <span v-if="row.relationSn">单号：{{ row.relationSn }}</span> </span>
        </template>
      </el-table-column>
    </el-table>

    <div class="tar mt-20">
      <router-link :to="`${basePath}/IntegralRecordInfo`">
        <el-button type="text" class="width-auto">
          查看所有交易记录
          <i class="el-icon-arrow-right color-b"></i>
        </el-button>
      </router-link>
    </div>
  </div>
</template>

<script>
import { axiosApi, basePath } from "~/api/api";
import { parseTime } from "@/utils";
export default {
  name: 'IntegralRecord',
  props: ['id','list'],
  data(){
    return {
      
      basePath: basePath
    }
  },
  mounted(){
    
  },
  computed:{
    listInfo(){
      var list = this.list.map(item=>{
        item.showTime = parseTime(item.gmtCreate)
        return item
      })
     return list
    },
  },
  methods:{
    
  }
}
</script>

<style lang="less" scoped>

</style>