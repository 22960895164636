<template>
	<div id="sellerManager" class="pb-50">
		<div class="title-top bg-f">商家管理</div>
		<div class="bg-f mt-5 pb-20">
			<div class="content bg-f pl-30 pr-30">
                <!-- 搜索区域 -->
                <div class="clearfix search">
                    <el-form :inline='true' class="pt-30 fl" :model="Form" ref="searchForm">
                        <el-form-item label='商家名称'>
                            <el-input v-model.trim="Form.storeName" :maxlength="30" placeholder="请输入商家名称"></el-input>
                        </el-form-item>
                        <el-form-item label='商家账号' class='ml-20'>
                            <el-input v-model.trim="Form.loginName" :maxlength="30" placeholder="请输入商家账号"></el-input>
                        </el-form-item>
                    </el-form>
                    <el-button class="fl ml-30 mt-30" type='info' @click="getSellerList()" icon="el-icon-search">搜 索</el-button>
                </div>
                <!-- 列表区域 -->
                <div class="pb-30">
                    <el-table :data="storeList">
                        <el-table-column label='商家名称' min-width="120">
                            <template slot-scope="scope">
                                <span>{{scope.row.storeName}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label='商家账号' min-width="120">
                            <template slot-scope="scope">
                                <span>{{scope.row.loginName}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label='营收收益' min-width="100">
                            <template slot-scope="scope">
                                <span v-if="scope.row.income">￥{{scope.row.income||0 | fmoney }}</span>
                                <span v-else>0</span>
                            </template>
                        </el-table-column>
                        <el-table-column label='可用保证金' min-width="130">
                            <template slot-scope="scope">
                                <span v-if="scope.row.deposit">￥{{scope.row.deposit||0 | fmoney }}</span>
                                <span v-else>0</span>
                            </template>
                        </el-table-column>
                        <el-table-column label='不可用保证金' min-width="130">
                            <template slot-scope="scope">
                                <span v-if="scope.row.disabledDeposit">￥{{scope.row.disabledDeposit||0 | fmoney }}</span>
                                <span v-else>0</span>
                            </template>
                        </el-table-column>
                        <el-table-column label='订单数量' min-width="90">
                            <template slot-scope="scope">
                                <span>{{scope.row.orderAmount}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label='发团数量' min-width="90">
                            <template slot-scope="scope">
                                <span>{{scope.row.grouponAmount}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label='商品数量' min-width="90">
                            <template slot-scope="scope">
                                <span>{{scope.row.goodsAmount}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label='操作'>
                            <template slot-scope="scope"> <span>-</span></template>
                        </el-table-column>
                    </el-table>
                    <el-pagination class="tar mt-20"
                        v-if="storeList.length>0" 
                        :current-page="currentPage" 
                        :page-size='10' 
                        :total='total' 
                        layout="prev , pager, next, jumper"
                         @current-change="handleCurrentChange">
                        <!-- 分页组件 -->
                    </el-pagination>
                </div>
            </div>
        </div>
  </div>
</template>

<script>
import axios from "axios";
import { axiosApi, basePath, baseURI } from "../../api/api";
export default {
  data() {
    return {
      Form: {
        storeName: null,
        loginName: null
      },
      storeList: [],
      total: 0,
      currentPage: 1,
      isClick: true
    };
  },
  mounted() {
    //进入加载
    this.$nextTick(function() {
      this.getSellerList();
    });
  },
  methods: {
    //搜索
    search() {
      if (this.currentPage == 1) {
        this.getSellerList();
      } else {
        this.currentPage = 1;
      }
    },
    // 获取列表
    getSellerList() {
      this.isClick = false;
      axiosApi(
        "/groupon/statistics/selectList",
        {
          loginName: this.Form.loginName,
          storeName: this.Form.storeName
        },
        res => {
          this.storeList = res.data.data.elements ? res.data.data.elements : [];
          this.total = res.data.data.totalCount;
          this.isClick = true;
        },
        this.currentPage,
        10,
        res => {}
      );
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getSellerList();
    }
  }
};
</script>

<style lang="less">
#sellerManager {
}
</style>