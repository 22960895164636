<template>
	<div id="editComm">
		<div class="bg-f font-16 pb-50">
			<div class="width-1280 plr-45">
				<div class="logo">
					 <router-link to="/customer/shop/shopIndex" class="mid"> 
						<img class="mid cursor" src="../assets/logo-r.png" />
					 </router-link>
				<!--内容区-->
				</div>
				<div>
					<router-view></router-view>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { basePath } from "../api/api";
	export default {
		data() {
			return {
				basePath: basePath
			}
		}
	}
</script>

<style lang="less">
	#editComm{
		.logo {
			height: 80px;
			background: #fff;
			border-bottom: 2px solid #EEEEEE;
			box-sizing:border-box;
			img{
				width: 190px;
    			height: 39px;
			    margin-top: 20px;
			}
		}
	}
		
</style>