<template>
    <div id="foodTypeAdd" class="">
        <div class="title-top bg-f mb-5">
            <div class="clearfix black">
                <el-breadcrumb class="fl ">
                    <el-breadcrumb-item>菜品分类管理</el-breadcrumb-item>
                </el-breadcrumb>

                <div class="tab_box">
                    <el-button class="fr mt-15 mr-30" @click="dialogShow1" style="width: 100px">添加菜品分类
                    </el-button>
                </div>
            </div>
        </div>
        <div class="content bg-f pb-50 pt-30">

            <div class="clearfix search">
                <el-table :data="tableData" v-loading="Loading" class='ml-30 table-default mr-30' stripe>
                    <el-table-column label="序号" prop="sort"></el-table-column>
                    <el-table-column label="分类名称" prop="classifyName"></el-table-column>
                    <el-table-column label="状态">
                        <template slot-scope="scope">
                            <span>{{ scope.row.isDisabled? '禁用' : '启用' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="菜品数量" prop="skuCount"></el-table-column>
                    <el-table-column label="操作" width="200">
                        <template slot-scope="scope">
                            <el-button size="small" type='text'
                                       @click="updateItem(scope)">编辑
                            </el-button>
                            <el-button v-if="scope.row.isDisabled" class='ml-0 width-50' size="small" type='text'
                                       @click="disableItem(scope,0)">启用
                            </el-button>
                            <el-button v-else class='ml-0 width-50' size="small" type='text'
                                       @click="disableItem(scope,1)">禁用
                            </el-button>
                            <el-button class='ml-0 width-50' size="small" type='text'
                                       @click="deleteItem(scope)">删除
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>

                <el-pagination v-if="total>0" class="tar mr-30" :current-page="currentPage" :page-size='10'
                               :total='total'
                               layout="prev , pager, next, jumper" @current-change="handleCurrentChange">
                    <!-- 分页组件 -->
                </el-pagination>
            </div>
        </div>

        <el-dialog title='添加菜品分类' :visible.sync='dialogShow'>
            <el-form :model="form" ref="ruleForm" :rules="rules">
                <el-form-item label="分类名称" prop="name">
                    <el-input v-model.trim="form.name" placeholder="输入分类名称"></el-input>
                </el-form-item>
                <el-form-item label="序号" prop="number">
                    <el-input v-model.trim="form.number" placeholder="输入序号"></el-input>
                </el-form-item>
            </el-form>
            <div slot='footer'>
                <el-button type='danger' @click="save">提 交</el-button>
                <el-button @click="dialogShow = false">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {axiosApi, basePath} from "../../api/api";

    export default {
        data() {
            return {
                dialogShow: false,
                form:
                    {
                        id: null,
                        name: null,
                        number: null,
                    },
                Loading: true,
                tableData: [], //员工列表
                currentPage: 1,
                total: 0,
                rules: {
                    name: [
                        {required: true, message: '请输入分类名称',},
                        {min: 1, max: 10, message: '长度在 1 到 10 个字符'}
                    ],
                    number: [
                        {required: true, pattern: /^[0-9]{1,5}$/, message: '请输入正确序号'}
                    ],
                }
            }
        },
        watch: {
            dialogShow(val) {
                if (!val) {
                    this.form.id = null;
                    this.form.name = null;
                    this.form.number = null;
                    this.$refs.ruleForm.resetFields()
                }
            }
        },
        methods: {
            dialogShow1() {
                this.dialogShow = true;
                setTimeout(() => {
                    this.$refs.ruleForm.resetFields()
                    this.form.number = 1;
                    this.form.number = this.tableData[this.tableData.length - 1].sort + 1;//获取列表里最后一个序号 再加一
                }, 0)
            },
            achieve(bool) {
                if (bool && this.currentPage != 1) {
                    this.currentPage = 1;
                    return
                }
                const api = "/catering/platform/foodClassify/selectList";
                const body = {}
                axiosApi(api, body, this.callback, this.currentPage, 10)
            },
            callback(res) {
                this.tableData = res.data.data
                this.total = res.data.data.totalCount
                this.Loading = false
            },
            //分页
            handleCurrentChange(val) {
                this.currentPage = val
                this.achieve()
            },
            save() {
                this.$refs.ruleForm.validate((v) => {
                    if (v) {
                        let body = {
                            classifyName: this.form.name,
                            id: this.form.id,
                            sort: this.form.number,
                        };
                        if (this.form.id) {
                            this.insert(body, '/catering/platform/foodClassify/update') // 编辑
                                .then(res => {
                                    this.dialogShow = false;
                                    this.achieve(true);
                                    this.$message({
                                        type: 'success',
                                        duration: 1000,
                                        message: "修改菜品成功!",

                                    });
                                })
                                .catch(res => {
                                    this.$message.error(res.data.message)
                                });
                        } else {
                            this.insert(body, '/catering/platform/foodClassify/insert') // 添加
                                .then(res => {
                                    this.dialogShow = false;
                                    this.achieve(true);
                                    this.$message({
                                        type: 'success',
                                        duration: 1000,
                                        message: "添加菜品成功!",

                                    });
                                })
                                .catch(res => {
                                    this.$message.error(res.data.message)
                                });
                        }

                    }
                })
            },
            insert(body, url) {// 新建套餐接口
                return new Promise((resolve, reject) => {
                    axiosApi(url, body, res => {
                        resolve(res)
                    }, 1, 10, res => {
                        reject(res)
                    })
                })
            },
            updateItem(scope) {
                this.dialogShow = true;
                setTimeout(() => {
                    this.$refs.ruleForm.resetFields()
                    this.form.id = scope.row.id;
                    this.form.name = scope.row.classifyName;
                    this.form.number = scope.row.sort;
                }, 0)
            },
            disableItem(scope, isDisabled) {
                var type = isDisabled ? "禁用" : "启用";

                this.$confirm("将" + type + "菜品分类, 是否继续?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                }).then(() => {
                    axiosApi('/catering/platform/foodClassify/update', {
                        id: scope.row.id,
                        isDisabled: isDisabled,
                    }, (res) => {
                        this.$message({
                            type: 'success',
                            duration: 1000,
                            message: type + "成功!",
                        });
                        this.achieve(true);
                    }, this.currentPage, 10, (res) => {
                        this.$message.error(res.data.message)
                    })
                }).catch(() => {
                    this.$message({
                        message: "已取消" + type,
                    });
                });
            },
            deleteItem(scope) {
                this.$confirm("将删除菜品分类, 是否继续?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                }).then(() => {
                    axiosApi('/catering/platform/foodClassify/update', {
                        id: scope.row.id,
                        isDeleted: 1,
                    }, (res) => {
                        this.$message({
                            type: 'success',
                            duration: 1000,
                            message: "删除成功!",
                        });
                        this.achieve(true);
                    }, this.currentPage, 10, (res) => {
                        this.$message.error(res.data.message)
                    })
                }).catch(() => {
                    this.$message({
                        message: "已取消删除",
                    });
                });
            }
        },
        //进入加载
        mounted() {
            this.$nextTick(function () {
                this.achieve();
            })
        }
    }
</script>

<style lang="less">
    #foodTypeAdd {
        .avatar-uploader-icon {
            border: 1px solid #ddd;
            width: 90px;
            height: 90px;
            line-height: 90px;
            font-size: 30px;
        }

        .tab_box {
            height: 60px;
        }

        .search .el-form-item__label {
            width: 90px;
        }

        .el-dialog {
            height: 300px !important;
            min-height: 300px !important;
        }

        .el-form-item__error {
            margin-left: 185px !important;
        }

        .el-form-item__label {
            font-size: 12px;
        }

        .el-button--default {
            margin-right: 0px;
        }

        .el-tabs__header {
            padding-left: 0;
        }

        .title-top {
            border: 0;
        }

        .mt-14 {
            margin-top: 14px;
        }

        .mr-60 {
            margin-right: 60px;
        }

        .black {
            .edit-btn span {
                color: #000000 !important;
            }
        }

        .el-table {
            overflow: hidden;
            width: 94%;
            max-width: 100%;
            background-color: #fff;
            border: 1px solid #dfe6ec;
            font-size: 12px;
            color: #1f2d3d;
        }
    }
</style>