<template>
    <div id="supplier-change-order" class="bg-f pb-50" v-loading="loading">
        <div class="store-confirm-order">
            <div class="order-detail pt-20">
                <div class="order-content">
                    <div class="item mb-20">
                        <p class="tar">
                            <el-button type='text' @click="addGifts" v-if="!isDealerUser">添加赠品</el-button>
                            <el-button type='text' @click="addGoods">添加商品</el-button>
                        </p>
                        <el-table class='common' :class="{'mb-10': !orderInfoObj.remark}">
                            <el-table-column label='商品' width='330'></el-table-column>
                            <el-table-column label='单价' width='120' align="center"></el-table-column>
                            <el-table-column label='数量' align="center"></el-table-column>
                            <el-table-column label='合计' align="center"></el-table-column>
                            <el-table-column label='操作' width='107' align="center"></el-table-column>
                        </el-table>
                        <div v-if="orderInfoObj.remark" class="remark clearfix mb-10"
                             style="paddingLeft: 20px;line-height:40px;display: flex">
                            <span class="fl" style="width: 60px">订单备注: </span>
                            <div class="fl ml-20" style="line-height:40px;flex: 1;width: 0">
                                <span style="vertical-align:top">{{orderInfoObj.remark}}</span>
                                <div v-if="orderInfoObj.remarkPics">
                                    <img style="width: 80px;height: 80px" class="mr-10" :src="item" v-for="(item,index) in orderInfoObj.remarkPics" alt="" :key="index" @click="lookImageDetail(orderInfoObj.remarkPics,index)">
                                </div>
                            </div>
                        </div>
                        <div class="item-content">
                            <!--商品-->
                            <div v-for="(i, index) in multipleSelectionConfirm" :key="i.id">
                                <div class="item-item clearfix">
                                    <div>
                                        <div v-if="i.existDelivery" class="font-12 color-danger pl-20 pt-20">
                                            此商品已设置首次发货，不可进行修改
                                        </div>
                                        <div class="clearfix order-item-item pt-20 pl-20 pb-20">
                                            <div class="clearfix">
                                                <div class="gift fl">
                                                    <div v-if="i.changePurchaseItem"
                                                         class="triangle-topleft border-red"></div>
                                                    <span v-if="i.changePurchaseItem" class="init default">换购</span>
                                                    <img v-if="i.image" class="fl img mr-10" :src="i.image"/>
                                                    <img v-else src="../../assets/goods.jpg" class="fl img mr-10"/>
                                                </div>
                                                <div class="fl" style="width: 220px;">
                                                    <p>商品编码：{{ isCityPartner ? i.goodsNo : i.skuNo}}</p>
                                                    <p class="text-tow-overflow">{{i.skuName}}</p>
                                                    <p class="color-9">规格: 1*{{i.specInfoNum}}</p>
                                                </div>
                                            </div>
                                            <div class="mt-10">
                                                商品备注：<el-input v-model="i.remark" @blur="checkRemark(i.remark,index,'goods')" :maxlength="20" placeholder="请输入商品备注"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="order-item-item-2 pt-20 tac " :class="{'pt56':i.existDelivery}">
                                        <el-input type="number" min="1" :disabled="i.existDelivery||i.skuType == 2" v-model='i.goodsSkuInfo.showPrice'
                                                  @change="changePrice(index)"
                                                  @keyup.native="checkPrice(index)"></el-input>
                                        <span>/{{i.goodsSkuInfo.showName}}</span>
                                    </div>
                                    <div class="order-item-item-3 pt-20 tac clearfix" :class="{'pt56':i.existDelivery}">
                                        <el-input-number size="small" :disabled="i.existDelivery"
                                                         v-model="i.calcNum"
                                                         @change="changeStep(index)"
                                                         :step="1"
                                                         :min="1"
                                                         class="fl"
                                                         :debounce="500">
                                        </el-input-number>
                                        <!-- <span>{{i.packageUnitName}}</span> -->
                                        <span v-if="i.skuType == 2" class=" lh-30">{{i.goodsSkuInfo.showName}}</span>
                                        <div v-else class="fl">
                                            <el-select @change="changeMultiple(i,index)" class="unitList" size="mini"  v-model="i.isIntegerMultiple" placeholder="请选择">
                                                <el-option :label="i.baseUnit" :value="false"></el-option>
                                                <el-option :label="i.integerUnitName" :value="true"></el-option>
                                            </el-select>
                                        </div>
                                    </div>
                                    <div class="order-item-item-4 pt-20" :class="{'pt56':i.existDelivery}">
                                        <!--@blur="changeTotalPrice(index)" @keyup.native="checkTotalPrice(index)" ="i.existDelivery||i.skuType == 2"-->
                                        <el-input disabled :maxlength="18" v-model="i.totalPrice"></el-input>
                                    </div>
                                    <div class="pt-20 order-item-item-5" :class="{'pt56':i.existDelivery}">
                                        <!--新增 商品类型-->
                                        <el-select v-model="i.k3SaleType" class="unit_select2 mb-10"
                                                   placeholder="请选择">
                                            <el-option v-for="item in SaleTypeList" :key="item.saleType"
                                                       :label="item.saleName" :value="item.saleType"></el-option>
                                        </el-select>
                                        <div class="mt-10"
                                             v-if="isDealerUser && !i.existDelivery&&i.skuType!=2&&orderInfoObj.supplierTraderId==orderInfoObj.sellerId">
                                            <el-button type='text' style="width: auto;height: auto;"
                                                       @click="setAsGift(i)">设为赠品
                                            </el-button>
                                        </div>
                                        <div class="mt-10" v-if="!i.existDelivery">
                                            <el-button style="width: auto;height: auto;" type='text'
                                                       @click="removeChooseGood(i,'good',index)">删除商品
                                            </el-button>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <!--赠品-->
                            <div class="item-item clearfix" v-for="(item, index) in giftExPoList" :key="index">
                                <div>
                                    <div v-if="item.existDelivery" class="font-12 color-danger pl-20 pt-20">
                                        此商品已设置首次发货，不可进行修改
                                    </div>
                                    <div class="clearfix  order-item-item pt-20 pl-20 pb-20">
                                        <div class="clearfix">
                                            <div class="gift fl">
                                                <div class="triangle-topleft border-red"></div>
                                                <span class="init default">赠品</span>
                                                <img v-if="item.image" class="img mr-10" :src="item.image"/>
                                                <img v-else src="../../assets/goods.jpg" class="img mr-10"/>
                                            </div>
                                            <div class="fl" style="width: 220px;">
                                                <p>商品编码：{{ isCityPartner ? item.goodsNo : item.k3GoodsNo }}</p>
                                                <p class="text-tow-overflow"> {{item.skuName}}</p>
                                                <p class="color-9" v-if="item.specInfoNum">规格:
                                                    1*{{item.specInfoNum}}</p>
                                            </div>
                                        </div>
                                        <div  class="mt-10">
                                                商品备注：<el-input v-model="item.remark" @blur="checkRemark(item.remark,index,'gift')" :maxlength="20" placeholder="请输入商品备注"/>
                                            </div>
                                    </div>
                                </div>
                                <div class="order-item-item-2 pt-20 " :class="{'pt56':item.existDelivery}">
                                    <el-input value='0.00' disabled></el-input>
                                </div>
                                <div class="order-item-item-3 pt-20" :class="{'pt56':item.existDelivery}">
                                    <el-input-number :disabled="item.existDelivery || item.giftType==1" size="small" v-model="item.giftNum" :min="1"></el-input-number>
                                    <el-select v-if="item.giftType!=1" class="unit_spec"
                                               :disabled="item.existDelivery || isDealerUser"
                                               v-model="item.isIntegerMultiple" placeholder="请选择">
                                         <el-option :label="item.baseUnitName" :value="false"></el-option>
                                         <el-option :label="item.integerUnitName " :value="true"></el-option>
                                    </el-select>
                                    <span v-if="item.giftType==1">{{item.packageUnitName}}</span>
                                </div>
                                <div class="order-item-item-4 pt-20" :class="{'pt56':item.existDelivery}">
                                    <el-input value='0' disabled></el-input>
                                </div>
                                <div class="pt-20 order-item-item-5" :class="{'pt56':item.existDelivery}" >
                                    <!--新增 商品类型-->
                                    <el-select  v-model="item.k3SaleType" class="unit_select2 mb-10"
                                               placeholder="请选择">
                                        <el-option v-for="i in SaleTypeList" :key="i.saleType"
                                                   :label="i.saleName" :value="i.saleType"></el-option>
                                    </el-select>
                                    <!--新增 赠品类型-->
                                    <el-select v-if="!isDealerUser && !isCityPartner" v-model="item.k3GiftType" class="unit_select2"
                                               placeholder="请选择">
                                        <el-option v-for="item in giftTypeData" :key="item.giftType"
                                                   :label="item.giftTypeName" :value="item.giftType"></el-option>
                                    </el-select>
                                    <div class="delBtn" v-if="!item.existDelivery">
                                        <el-button type='text' @click="removeChooseGood(item,'gift',index)">
                                            删除商品
                                        </el-button>
                                    </div>
                                </div>
                            </div>
                            <!--添加备注-->
                            <el-dialog class="goodRemarkDialog" title="添加备注" :close-on-click-modal='false'
                                       :visible.sync="goodRemarkVisible">
                                <el-form v-show="goodRemarkVisible" :model="goodRemarkForm" ref="goodRemarkForm"
                                         :rules="rules">
                                    <el-form-item label='' prop="goodRemark">
                                        <el-input type='textarea' :rows='4'
                                                  v-model.trim='goodRemarkForm.goodRemark'></el-input>
                                    </el-form-item>
                                </el-form>
                                <div slot="footer" class="dialog-footer">
                                    <el-button type="danger" @click="handleSaveGoodRemark">确 定</el-button>
                                    <el-button @click="CancelGoodRemark">取 消</el-button>
                                </div>
                            </el-dialog>
                        </div>

                        <div class="total clearfix">
                            <ul class="fr mr-50">
                                <li class="mb-10 clearfix">
                                    <span class="fl tar">{{activityList.count?activityList.count:0}}件商品总额：</span>
                                    <span class='ml-30 fr'>￥ {{activityList.srcTotalPrice|fmoney}}</span>
                                </li>
                                <!--<li class="tar mb-10 clearfix">
                                    <span class="fl tar">改价金额：</span>
                                    <span class='ml-30 fr'>{{orderInfoObj.changedPrice>=0?'+':'-'}}￥ {{orderInfoObj.changedPrice!=null?Math.abs(orderInfoObj.changedPrice):'0.00' | fmoney}}</span>
                                </li>-->
                                <li class="mb-10 clearfix">
                                    <span class="fl tar">满减金额：</span>
                                    <span class='ml-30 fr'>- ￥ {{orderInfoObj.activityCutMoney||0 | fmoney}}</span>
                                </li>
                                <li class="mb-10 clearfix">
                                    <span class="fl tar ">优惠券金额：</span>
                                    <span class='ml-30 fr'>- ￥ {{orderInfoObj.couponCutMoney||0 | fmoney}}</span>
                                </li>
                                <!--<li class="mb-10 clearfix">
                                    <span class="fl tar" > 订单优惠：</span>
                                    <span class='ml-30 fr'>- ￥ {{orderInfoObj.couponCutMoney+orderInfoObj.activityCutMoney | fmoney}}</span>
                                </li>-->
                                <li class="mb-10 clearfix">
                                    <span class="fl tar">运费：</span>
                                    <span class='ml-30 fr'>+￥ {{orderInfoObj.freightMoney?orderInfoObj.freightMoney:0 | fmoney}}</span>
                                </li>
                                <li class="font-20 payment clearfix">
                                    <span class="fl tar">支付金额：</span>
                                    <span class='ml-30 fr'>￥ {{activityList.srcTotalPrice + orderInfoObj.freightMoney + (orderInfoObj.changedPrice?orderInfoObj.changedPrice:0) - (orderInfoObj.couponCutMoney+orderInfoObj.activityCutMoney)  | fmoney}}</span>
                                </li>
                            </ul>
                        </div>
                        <div class="tal mt-20" v-if="!orderInfoObj.deliverySeparate && !orderInfoObj.openStraight && isOpenOrderTypeOpt">
                            <span class="tar">单据类型：</span>
                            <el-select class="giftTypeList"  v-model="k3SalesOrderType"   placeholder="请选择单据类型">
                                <el-option
                                        v-for="item in orderTypeList"
                                        :key="item.type"
                                        :label="item.label"
                                        :value="item.type">
                                </el-option>
                            </el-select>
                        </div>
                        <div class='tar mt-20 mb-50'>
                            <el-button type='danger' @click="orderUpdateSave">保 存</el-button>
                            <el-button @click="orderCancel">取 消</el-button>
                        </div>
                    </div>
                </div>
            </div>

            <!--商品弹框-->
            <el-dialog title="选择商品" :visible.sync="dialogTableVisible" class="chooseGoods">
                <div class="clearfix">
                    <el-form :inline="true" class="fl goodsForm" :model="chooseGoodsForm" ref="chooseGoodsForm">
                        <el-form-item label="商品编码" >
                            <el-input v-model.trim="chooseGoodsForm.skuNo"></el-input>
                        </el-form-item>
                        <el-form-item label="商品名称" >
                            <el-input v-model.trim="chooseGoodsForm.skuName"></el-input>
                        </el-form-item>
                        <el-form-item label="商品分类" >
                            <el-select v-model="chooseGoodsForm.classifyId" clearable>
                                <el-option v-for="(i,index) in classifyList" :key='index' :value="i.id"
                                           :label="i.classifyName"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-form>
                    <el-button type="info" class="fl" @click="searchGoods">查 询</el-button>
                    <el-table @selection-change="handleSelectionChange" class="fl table-default" stripe border
                              :data="goodsList" v-loading="goodsLoading">
                        <el-table-column :selectable="isOrderGoodsEffect" type="selection" >
                            <!-- <template slot-scope="scope">
                                {{ scope.row.goodsInvalidMessage }}
                            </template> -->
                        </el-table-column>
                        <el-table-column property="skuNo" label="商品编码" width="120">
                            <template slot-scope="scope">
                                <p v-if="isDealerUser">{{ scope.row.skuNo }}</p>
                                <p v-else-if="isCityPartner">{{ scope.row.goodsNo }}</p>
                                <p v-else>{{ scope.row.k3GoodsNo }}</p>
                            </template>
                        </el-table-column>
                        <el-table-column property="skuName" label="商品名称" width="200">
                            <template slot-scope="scope">
                                <p v-if="isDealerUser" class="text-tow-overflow">{{ scope.row.skuName }}</p>
                                <p class="text-tow-overflow" v-else>
                                    <span style="background-color: #F59A23;display: inline-block;color: #fff;padding: 3px 5px;" v-if="scope.row.dealerGoods">经销商</span>
                                    <span v-if="!scope.row.isOrderGoodsEffect">【非协议】</span>
                                    {{ scope.row.skuName }}</p>
                            </template>
                        </el-table-column>
                        <el-table-column property="classifyName" label="分类"></el-table-column>
                        <el-table-column label="基本单位" prop="unitsName">
                           
                        </el-table-column>
                        <el-table-column label="单价(基本)" prop="singlePriorityPrice">
                            <template slot-scope="scope">
                                <span :class="scope.row.singlePriorityPrice ==0 ? 'color-danger' : ''">{{ scope.row.singlePriorityPrice ? scope.row.singlePriorityPrice : '0.00' }} / {{ scope.row.unitsName }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="库存(基本)">
                            <template slot-scope="scope">
                                <span v-if="!isCityPartner">{{scope.row.stock}}</span>
                                <span v-else>{{ scope.row.stockEnableQuantity }}</span>
                            </template>
                        </el-table-column>
                        <!-- <el-table-column label="规格单位" prop="packageUnitName">
                           
                        </el-table-column> -->
                        <el-table-column label="单价(规格)" prop="boxPriorityPrice">
                            <template slot-scope="scope">
                                <span :class="scope.row.boxPriorityPrice ==0 ? 'color-danger' : ''">{{  scope.row.boxPriorityPrice ? scope.row.boxPriorityPrice : '0.00'  }} / {{ scope.row.packageUnitName }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="库存(规格)" prop="specStock">
                            <template slot-scope="scope">
                                <span v-if="!isCityPartner">{{scope.row.specStock}}</span>
                                <span v-else>
                                    <span v-if="scope.row.skuType === 2">{{ scope.row.stockEnableQuantity }}</span>
                                    <span v-else>{{ Math.floor(scope.row.stockEnableQuantity / scope.row.specInfoNum) }}</span>
                                </span>

                            </template>
                        </el-table-column>
                    </el-table>
                    <el-pagination v-if='total>0' class="tar fr" :current-page="currentPage" :page-size='10'
                                   :total='total' layout="prev , pager, next, jumper"
                                   @current-change="handleCurrentChange">
                        <!-- 分页组件 -->
                    </el-pagination>
                </div>
                <div slot="footer" class="dialog-footer">
                    <el-button type="danger" @click="goodsListSave">确 定</el-button>
                    <el-button @click="goodsListCancel">取 消</el-button>
                </div>
            </el-dialog>

            <!--赠品弹框-->
            <choose-gift @closedialog="closedialog" :warehouse="this.k3WarehouseNo" :ischangedorder="true" :isSubstituteOrder="orderInfoObj.isSubstituteOrder"  :skuNoList="skuNoList" :sn="orderSn" :giftVisible="giftVisible"></choose-gift>
            <imgview :visible="imgsvisible" :imgs="showimgs" :startindex="startindex" @close="imgsvisible=false"></imgview>
        </div>
    </div>
</template>


<script>
    import {axiosApi, basePath} from "../../api/api"
    import { mapActions , mapMutations } from 'vuex';
    export default {
        data() {
            return {
                k3WarehouseNo:null,// k3组织号仓库信息
                priceUnit:1,//是否开启按瓶设价
				isOpenOrderTypeOpt:false,
				k3SalesOrderType:1,//默认为1
				orderTypeList:[
                    { label:'标准销售订单',type:1 } ,
					{ label:'现销订单',type:2 }
				],//订单单据类型
                skuNoList:[],//已选赠品
                giftVisible:false,
                giftTypeData:[],
                basePath: basePath,
                /*商品弹框变量*/
                dialogTableVisible: false, //选择商品 弹框
                chooseGoodsForm: {
                    skuName: null,
                    classifyId: null,
                    skuNo: null //商品编码
                },
                isHotel: 0,//默认非酒店用户
                goodsLoading: false,
                goodsList: [],
                classifyList: [], //分类列表
                multipleSelection: [], //选中的商品
                multipleSelectionConfirm: [], //显示到页面的商品
                giftExPoList: [], //赠品列表
                goodRemarkVisible: false, // 商品添加备注
                goodRemarkForm: {
                    goodRemark: null
                }, //备注
                rules: {
                    goodRemark: [{
                        max: 30,
                        message: '请输入1-30个字符'
                    }]
                },
                orderSn: null,
                loading: false,
                changeOrderObj: {}, //session存储的orderSn
                orderInfoObj: {}, // 订单详情
                activityList: { //活动对象
                    couponExPos: [], //必须定义数组名不然 会报错
                    count: 0,//商品数量
                    srcTotalPrice: 0,// 商品总价
                },
                currentPage: 1, //商品页数
                total: 0,
                remarkIndex: null, //给那个商品添加备注的标识
                couponItem: {}, //选中 的优惠券
                unitOptions: [{
                    label: '箱',
                    value: '箱'
                }, {
                    label: '瓶',
                    value: '瓶'
                }],
                SaleTypeList: [], //商品类型列表
                imgsvisible: false,
                startindex: 0,
                showimgs: [],
            }
        },
        watch: {
            giftExPoList:{
                handler:function (val) {
                    this.skuNoList = [];
                    if(val.length>0){
                        val.map(v => {
                            this.skuNoList.push(v.skuNo);
                        })
                    }
                },
                deep:true
            },
            dialogTableVisible(val) {
                if (val) {
                    /**
                     * skuName: null,
                     classifyId: null,
                     skuNo: null //商品编码
                     * */
                    this.chooseGoodsForm.skuName = null;
                    this.chooseGoodsForm.classifyId = null;
                    this.chooseGoodsForm.skuNo = null;
                }
            },
            giftExtraList:{
                handler:function (val) {
                    if(val){
                        let arr = JSON.parse(JSON.stringify(val));
                        arr.map(v => {
                            v.giftList.map(item => {
                                let flag = true;
                                if(this.giftExPoList.length>0){
                                    this.giftExPoList.map(n => {
                                        if(n.skuNo == item.skuNo){
											console.log(item,'0')
                                            flag = false;
                                        }else{
                                        	console.log(item,'1')
                                            item.image = item.giftPic ? item.giftPic : item.image
											// item.specUnit = '箱(规格单位)';
                                            item.k3GiftType = 'X105';
                                            item.integerUnitName = item.integerUnitName;
                                            item.isIntegerMultiple = true;
                                            item.baseUnitName = item.giftUnit;
											item.changeCoefficient = item.specInfoNum;
                                        }
                                    });

                                }else {
									item.image = item.giftPic ? item.giftPic : item.image
                                    // item.specUnit = '箱(规格单位)';
                                    item.isIntegerMultiple = true;
                                    item.integerUnitName = item.integerUnitName;
                                    item.baseUnitName = item.giftUnit;
                                    item.k3GiftType = 'X105';
                                }
                                if(flag){
                                    let data = {
                                        ...item,
                                        giftNum: 1,
                                        k3SaleType: '001'
                                    }
                                    this.giftExPoList.push(data);

                                }else{

                                }
                            })
                            this.giftExPoList.map(n => {
                                let flag2 = true

                                v.giftList.map(item=>{
                                    if (n.skuNo == item.skuNo){
                                        flag2 = false
                                    }
                                })
                                if (flag2 && n.goodsNo === undefined){
                                    this.giftExPoList = this.giftExPoList.filter(v => {
                                        return v.skuNo != n.skuNo
                                    })
                                }

                            });
                        })

                    }
					console.log(this.giftExPoList,'this.giftExPoList')
                },
                deep:true
            }
        },
        mounted() {
            this.$nextTick(() => {
                this.classifyTopListSelect()
                this.anonOrderDetailInfo()
                this.giftTypeList()
                this.getSaleTypeList()
            })
        },
        methods: {
            ...mapMutations({
                'deleteOnewillGift': 'deleteOnewillGift',
            }),
            ...mapActions({
                'querygiftlistData': 'querygiftlistData',
            }),
            lookImageDetail(list,index){
                this.imgsvisible = true;
                this.startindex = index;
                this.showimgs = list;
            },
            //修改商品单位
            changeMultiple(item,index){
                let obj = JSON.parse(JSON.stringify(this.multipleSelectionConfirm[index]));
                obj.cloneIsIntergerMuliple = !obj.cloneIsIntergerMuliple;
                this.$set(this.multipleSelectionConfirm[index],'cloneIsIntergerMuliple',obj.cloneIsIntergerMuliple)
                if(obj.cloneIsIntergerMuliple && this.priceUnit !== 1){
                    obj.goodsSkuInfo = {
                        showPrice: obj.specShowUnitPrice.toFixed(2),
                        showName: obj.specShowUnitName
                    }
                }else {
                    obj.goodsSkuInfo = {
                        showPrice:obj.baseShowUnitPrice.toFixed(2),
                        showName:obj.baseShowUnitName
                    };
                }
                this.$set(this.multipleSelectionConfirm,index,obj);
                this.changeStep(index);
            },
			//验证商品备注
			checkRemark(remark,index,type){
            	let originData = type === 'gift' ? this.giftExPoList[index] : this.multipleSelectionConfirm[index];

				if(remark){
					let obj = JSON.parse(JSON.stringify(originData));
					let reg = /[^\a-\z\A-\Z0-9\u4E00-\u9FA5]/g;
					remark = remark.replace(reg,'');
					obj.remark = remark;
					console.log(remark,'---------remark')
					this.$set((type === 'gift' ? this.giftExPoList : this.multipleSelectionConfirm),index,obj);
				}
			},
            // 获取商品类型列表
            getSaleTypeList() {
                axiosApi('/order/saleType/list',{},res=>{
                    this.SaleTypeList = res.data.data
                },1,1000,err=>{
                    this.$message.error('获取商品类型列表失败')
                })
            },
            //添加赠品
            addGifts(){
                this.giftVisible = true;
                let dataObj = {skuNoList: this.skuNoList,giftType:0, isWarehouseStock: false};
                if(this.orderInfoObj.isSubstituteOrder){
                  dataObj.sn = this.orderSn
                }
                this.querygiftlistData(Object.assign({data: {}}, { data:dataObj }))
            },
            closedialog(val){
                this.giftVisible = false;
            },
            giftTypeFilter(val){
                let str = '';
                this.giftTypeData.map(v => {
                    if(v.giftType == val){
                        str = v.giftTypeName
                    }
                })
                return str;
            },
            giftTypeList(){
                axiosApi('/marketing/giftType/list',{},res => {
                    this.giftTypeData = res.data.data;
                })
            },
            //商品不能勾选处理函数
            isOrderGoodsEffect(row, index) {
                // return true;
                if (this.isDealerUser) {
                    return true
                }
                return this.goodsList[index].isOrderGoodsEffect && !this.goodsList[index].isSelect
            },
            // 取消
            orderCancel() {
            	window.close()
            },
            //保存修改的订单
            orderUpdateSave() {
                let total = (this.activityList.srcTotalPrice + this.orderInfoObj.freightMoney + (this.orderInfoObj.changedPrice ? this.orderInfoObj.changedPrice : 0) - (this.orderInfoObj.couponCutMoney + this.orderInfoObj.activityCutMoney)) * 1
                if (this.multipleSelectionConfirm.length === 0 && this.giftExPoList.length === 0) {
                    this.$message.warning('请添加商品')
                    return false
                }
                if (total < 0) {
                    this.$message.warning('订单金额小于0')
                    return false
                }

                if(this.isOpenOrderTypeOpt && !this.k3SalesOrderType){
					this.$message.warning('请选择订单单据类型')
					return false
                }
                let flag = false

                for (let i = 0 ;i<this.multipleSelectionConfirm.length;i++){
                    if(this.multipleSelectionConfirm[i].goodsSkuInfo.showPrice == 0||this.multipleSelectionConfirm[i].goodsSkuInfo.showPrice == '0.00' || this.multipleSelectionConfirm[i].goodsSkuInfo.showPrice == null){
                        this.$message.warning('【'+this.multipleSelectionConfirm[i].skuName +'】单价为空，暂时无法提交订单')
                        flag = true
                        break
                    }
                }
                if (flag){
                    return false
                }
                let body = {
                    sellerId: sessionStorage.traderId,
                    buyerId: this.orderInfoObj.buyerId,
                    orderId: this.orderInfoObj.id,
                    orderSn: this.orderSn,
					k3SalesOrderType:this.k3SalesOrderType
                }
                //商品明细
                let orderItemExs = []
                
                this.multipleSelectionConfirm.forEach((i) => {
                	let goodsItem = this.SaleTypeList.filter(v=>{
						return v.saleType == i.k3SaleType
					})[0];
                	if(!goodsItem.saleName){
                		this.$message.warning('请选择商品类型')
                        return
                    }
                    let name = goodsItem.saleName
                    if (i.changePurchaseItem) {// 换购商品
                        orderItemExs.push({
                            k3SaleType: i.k3SaleType,
                            k3SaleTypeName: name,
                            activityId: i.activityId,
                            activityName: i.activityName,
                            unitType: i.unitType,
                            k3GoodsNo: i.k3GoodsNo,
                            skuNo: i.skuNo,
                            skuName: i.skuName,
                            price: i.goodsSkuInfo.showPrice,
                            quantity: i.calcNum,
                            remark: i.remark || "",
                            isIntegerMultiple: i.cloneIsIntergerMuliple,
                            sellingUnitName: i.cloneIsIntergerMuliple ? i.integerUnitName : i.baseShowUnitName,  //销售单位 订单里面是 unit 添加商品 商品返回 sellingUnitName
                            id: !i.isAdd ? i.id : null,
                            skuType: i.skuType,
                            setPriceUnit: i.setPriceUnit
                        })
                    } else {
                        orderItemExs.push({
                            k3SaleType: i.k3SaleType,
                            k3SaleTypeName: name,
                            k3GoodsNo: i.k3GoodsNo,
                            skuNo: i.skuNo,
                            skuName: i.skuName,
                            price: i.goodsSkuInfo.showPrice,
                            quantity: i.calcNum,
                            remark: i.remark || "",
                            isIntegerMultiple: i.cloneIsIntergerMuliple ,
                            sellingUnitName: i.cloneIsIntergerMuliple ? i.integerUnitName : i.baseShowUnitName,  //销售单位 订单里面是 unit 添加商品 商品返回 sellingUnitName
                            id: !i.isAdd ? i.id : null,
                            skuType: i.skuType,
                            setPriceUnit: i.setPriceUnit
                        })
                    }

                });

                body.orderItemExs = orderItemExs;
                let orderGiftResVos = []
                this.giftExPoList.forEach(v => {
					let goodsItem = this.SaleTypeList.filter(i=>{
						return v.saleType == i.k3SaleType
					})[0];
					if(!goodsItem.saleName){
						this.$message.warning('请选择赠品类型')
						return
					}
					let name = goodsItem.saleName
                    let giftObj = {
						'remark':v.remark || "",
                        'giftNum': v.giftNum,
                        'changeCoefficient': v.changeCoefficient,
                        'sellingUnitName': v.packageUnitName,
                        'skuNo': v.skuNo,
                        'skuName': v.skuName,
                        'skuImage': v.image,
                        'k3GiftType': v.k3GiftType,
                        'k3GiftTypeName': this.giftTypeFilter(v.k3GiftType),
                        'k3SaleType': v.k3SaleType,
                        'k3SaleTypeName': name,
                        'giftType': v.giftType,
                        'k3GoodsNo': v.k3GoodsNo,
                        'isIntegerMultiple': v.giftType == 1 ? 0 : v.isIntegerMultiple,
                        'id': v.id || null,
                        'skuType': v.skuType,

                    };
                    if(v.isNewGift){
                        giftObj.giftId = v.id;
                        giftObj.id = null;
                    }
                    orderGiftResVos.push(giftObj);
                });
                body.orderGiftResVos = orderGiftResVos;
                this.orderOrderItemChange(body)
            },
            //订单换货加货减货
            orderOrderItemChange(body) {
                console.log(body,)
                return;
                this.loading = true
                axiosApi('/order/orderItem/change', body, (res) => {
                    this.loading = false
                    this.$message({
                        type: 'success',
                        message: '操作成功！',
                        duration: 1000
                    })
                    // setTimeout(() => {
                    //     window.close()
                    // }, 100)
                }, 1, 10, (res) => {
                    this.loading = false
                    this.$message({
                        type: 'error',
                        message: res.data.message,
                        duration: 1000
                    })
                })
            },

            //获取orderSn
            getOrderSn() {
                let body = {}
                this.changeOrderObj = JSON.parse(sessionStorage.getItem('changeOrderObj'))
                if (this.changeOrderObj.type == 'supplierChangeOrder') {
                    this.orderSn = this.changeOrderObj.orderSn
                    body = {
                        orderSn: this.changeOrderObj.orderSn
                    }
                }
                body.freshenGoods = true //套餐修改订单
                return body
            },
            updateNumAndPrice() {// 更新商品个数和总金额
                this.activityList.count = 0
                this.activityList.srcTotalPrice = 0
                this.multipleSelectionConfirm.forEach(v => {
                    this.activityList.count += v.calcNum * 1// 计算商品数量
                    this.activityList.srcTotalPrice += v.totalPrice * 1// 计算商品总价
                })
            },
            //订单详情接口
            anonOrderDetailInfo() { 
                this.loading = true
                axiosApi('/anon/parentOrder/detail/info', this.getOrderSn(), (res) => {
                    this.k3WarehouseNo = {
                        companyNo: res.data.data.supplierK3SalesCompanyNo,
                        no: res.data.data.k3WarehouseNo
                    }
                    this.isHotel = res.data.data.isHotel;
                    this.priceUnit = res.data.data.priceUnit;
                    this.k3SalesOrderType = res.data.data.k3SalesOrderType;
                    this.isOpenOrderTypeOpt = res.data.data.isOpenOrderTypeOpt;
					this.loading = false
                    this.orderInfoObj = res.data.data;
                    this.orderInfoObj.remarkPics = JSON.parse(this.orderInfoObj.remarkPics)
                    this.multipleSelectionConfirm = JSON.parse(JSON.stringify(this.orderInfoObj.orderItemVos))
                    this.multipleSelectionConfirm.forEach((i) => {
                        i.goodsSkuInfo = {
                            showPrice:i.showUnitPrice.toFixed(2),
                            showName:i.showUnitName
                        };
                        i.calcNum = i.specNum;
                        i.cloneIsIntergerMuliple = i.isIntegerMultiple;
                        i.totalPrice = i.discountTotalPrice ? i.discountTotalPrice.toFixed(2) : i.discountPrice.toFixed(2);
                        i.packageUnitName = i.unit
                    })
                    this.giftExPoList = JSON.parse(JSON.stringify(this.orderInfoObj.orderGiftResVos))// 赠品列表
                    this.giftExPoList.forEach(v => {
                        this.$set(v,'isOld',true); //之前的赠品
                    });
                    this.updateNumAndPrice()// 更新 商品个数和 总价格
                }, 1, 10, (res) => {
                    this.loading = false
                })
            },

            /*商品减价加价操作*/
            //验证总价小数点和整数
            checkTotalPrice(index) {
                let item = this.multipleSelectionConfirm[index]
                item = Object.assign({}, item)
                let reg = /^[\+]?\d*?\.?\d*?$/
                if (!reg.test(item.totalPrice)) {
                    item.totalPrice = 0
                } else {
                    if (item.totalPrice !== 0 && item.totalPrice.indexOf('.') > 0) {
                        item.totalPrice = item.totalPrice.substr(0, (item.totalPrice).indexOf('.') + 3)
                    }
                }
                this.$set(this.multipleSelectionConfirm, index, item)
            },
            //验证单价小数点和整数
            checkPrice(index) {
                let item = this.multipleSelectionConfirm[index]
                item = Object.assign({}, item)
                this.$set(this.multipleSelectionConfirm, index, item)
            },
            //改变单价计算总计
            changePrice(index) {
                let obj = Object.assign({},this.multipleSelectionConfirm[index]);
                obj.changedPrice = true;
                this.$set(this.multipleSelectionConfirm,index,obj);
                let item = this.multipleSelectionConfirm[index]
                item = Object.assign({}, item)
                // item.price = (item.price * 1).toFixed(2)
                if (item.goodsSkuInfo.showPrice < 0.01) {
                    item.goodsSkuInfo.showPrice = 0.01
                }
                if (item.goodsSkuInfo.showPrice > 9999999999.99) {
                    item.goodsSkuInfo.showPrice = 9999999999.99
                }
                if ((this.priceUnit == 1 && item.isIntegerMultiple == 1) || (item.setPriceUnit == 1 && item.isIntegerMultiple == 1)) {// 设价单位与销售单位不统一 需要乘以规格
                    item.totalPrice = (item.goodsSkuInfo.showPrice * item.calcNum * item.specInfoNum).toFixed(2)// 价格 X 数量 X 规格
                } else {
                    item.totalPrice = (item.goodsSkuInfo.showPrice * item.calcNum).toFixed(2)
                }
                setTimeout(()=>{
                    this.$set(this.multipleSelectionConfirm, index, item)
                    this.updateNumAndPrice()// 更新 商品个数和 总价格
                },50)
            },
            //改变总价计算单价
            changeTotalPrice(index) {
                let item = this.multipleSelectionConfirm[index]
                item = Object.assign({}, item)
                item.totalPrice = (item.totalPrice * 1).toFixed(2)
                if ((this.priceUnit == 1 && item.isIntegerMultiple == 1) || (item.setPriceUnit == 1 && item.isIntegerMultiple == 1)) {// 设价单位与销售单位不统一 需要乘以规格
                    if (item.totalPrice == 0.00 || item.totalPrice < (0.01 * item.calcNum * item.specInfoNum)) {// 判断总价是否 小于 最小单价计算的总价格
                        item.price = 0.01
                        item.totalPrice = (item.price * item.calcNum * item.specInfoNum).toFixed(2)
                    }
                    item.price = (item.totalPrice / (item.calcNum * item.specInfoNum)).toFixed(2)
                } else {
                    if (item.totalPrice == 0.00 || item.totalPrice < (0.01 * item.calcNum)) {
                        item.price = 0.01
                    }
                    item.totalPrice = (item.price * item.calcNum).toFixed(2)
                }
                this.$set(this.multipleSelectionConfirm, index, item)
                this.updateNumAndPrice()// 更新 商品个数和 总价格
            },
            //改变步数计算总价 (调用代下单是否有优惠)
            changeStep(index) {
                let that = this;
                setTimeout(() => {
                    let item = JSON.parse(JSON.stringify(this.multipleSelectionConfirm[index]));
                    if ((this.priceUnit == 1 && item.isIntegerMultiple == 1) || (item.setPriceUnit == 1 && item.isIntegerMultiple == 1)) {// 设价单位与销售单位不统一 需要乘以规格
                        item.totalPrice = (item.goodsSkuInfo.showPrice * item.calcNum * item.specInfoNum).toFixed(2)// 价格 X 数量 X 规格
                    } else {
                        item.totalPrice = (item.goodsSkuInfo.showPrice * item.calcNum).toFixed(2)// 价格 X 数量
                    }
                    this.$set(this.multipleSelectionConfirm, index, item)
                    this.updateNumAndPrice()// 更新 商品个数和 总价格
                }, 0)
            },
            setAsGift(item) {// 设为赠品
                let h = this.$createElement;
                this.$msgbox({
                    title: '提示信息',
                    message: h('p', {
                        style: 'text-align:center'
                    }, '是否确定设为赠品?'),
                    showCancelButton: true,
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    beforeClose: (action, instance, done) => {
                        if (action === 'confirm') {
                            let giftObj = Object.assign({}, item, {
                                'specUnit': this.isDealerUser ? item.packageUnitName : (item.isIntegerMultiple ? '箱' : '瓶'),
                                'giftNum': item.calcNum,
                                'changeCoefficient': item.specInfoNum,
                                'giftType': 0 ,// 平台赠品0 ，自定义赠品1
                                'k3GiftType':'X105'
                            })
                            let temp = false
                            this.giftExPoList.forEach(v => {
                                if (v.skuNo == item.skuNo) { // 有相同的赠品
                                    temp = true
                                }
                            })
                            if (!temp) {
                                this.giftExPoList.unshift(giftObj)// 添加到赠品
                                this.multipleSelectionConfirm = this.multipleSelectionConfirm.filter(v => {
                                    return v != item
                                }) // 从商品中删除
                                this.updateNumAndPrice()//  删除后更新 商品个数和 总价格
                            }
                            if (temp) {
                                this.$message.error('存在相同的赠品，不能设置为赠品, 当前商品不需要的话，可以主动删除')
                                done()
                                return false
                            }
                            setTimeout(() => {
                                this.$message({
                                    type: 'success',
                                    message: '操作成功！',
                                    duration: 1000
                                })
                            }, 300)
                            done()
                        } else {
                            done()
                        }
                        delete instance.$slots.default;
                    }
                })
            },
            // 供货商查询店铺下商户可以购买的商品
            goodsSkuCustomSearch(body, bool) {
                if (bool && this.currentPage != 1) {
                    this.currentPage = 1;
                    return
                }
                let data;
                if (this.isHotel == 1) {
                    data = {...body, ...{disabledSet: false, onlyReturnOnShelf: false}};
                } else {
                    data = {...body, ...{disabledSet: false}};
                }
                this.goodsLoading = true;
                if (this.isDealerUser) {
                    let obj = {
                        buyerId: data.buyerId,
                        appGoodsState: 1,
                        notInSkuList: data.skuNoList,
                        skuName: data.skuName,
                        skuClassifyId: data.classifyId,
                        skuNo: data.skuNo,
                    };
                    axiosApi('/goods/dealer/goodsList', obj, res => {
                        this.goodsLoading = false;
                        if (res.data.data != null) {
                            this.goodsList = res.data.data.resultPage.elements;
                            this.goodsList.map(v=>{
                                v.isSelect = false
                                this.multipleSelectionConfirm.map(i=>{
                                    if(v.skuNo == i.skuNo){
                                        v.isSelect = true
                                    }
                                })
                                !this.isCityPartner&&v.stockEnableInfoList.map(i=>{
                                    if (i.stockOrgNumber+':'+i.mcu == this.k3WarehouseNo.companyNo+':'+this.k3WarehouseNo.no) {
                                        v.stock = i.stock
                                        v.specStock = i.specStock
                                    }
                                })
                                if(!v.stock || typeof(v.stock)== 'undefined' || v.stock==0){
                                    v.stock = 0
                                    v.specStock = 0
                                }
                            })
                            res.data.data.resultPage.elements.map(v => {
                                v.goodsNo = v.skuNo;
                                v.classifyName = v.skuClassifyName;
                                v.priorityPrice = v.dealerSalesPrice;
                                v.showUnitName = v.packageUnitName;
                                v.calcNum = 1;
                                v.specInfoNum = v.specInfo;
                                v.image = v.skuImage;
                            })
                            this.total = res.data.data.resultPage.totalCount
                        } else {
                            this.dialogTableVisible = false
                            let h = this.$createElement;
                            this.$msgbox({
                                title: '提示信息',
                                message: h('p', {
                                    style: 'text-align:center'
                                }, '没有可以添加的商品！'),
                                showCancelButton: false,
                                confirmButtonText: '确定',
                                cancelButtonText: '取消',
                                beforeClose: (action, instance, done) => {
                                    if (action === 'confirm') {
                                        done()
                                    } else {
                                        done()
                                    }
                                    delete instance.$slots.default;
                                }
                            })
                        }
                    }, this.currentPage, 10, err => {
                        this.goodsLoading = false;
                        this.$message.warning(err.data.message)
                    })
                } else {
                    data.goodsNo = data.skuNo;
                    delete data.skuNo;
                    data.changeOrder= true
                    axiosApi('/goods/skuCustom/search', data, (res) => {
                        this.goodsLoading = false
                        if (res.data.data != null) {
                            this.goodsList = res.data.data.elements;
                            this.goodsList.map(v=>{
                                v.isSelect = false
                                this.multipleSelectionConfirm.map(i=>{
                                    if(v.skuNo == i.skuNo){
                                        v.isSelect = true
                                    }
                                })
                                !this.isCityPartner&&v.stockEnableInfoList.map(i=>{
                                    if (i.stockOrgNumber+':'+i.mcu == this.k3WarehouseNo.companyNo+':'+this.k3WarehouseNo.no) {
                                        v.stock = i.stock
                                        v.specStock = i.specStock
                                    }
                                })
                                if(!v.stock || typeof(v.stock)== 'undefined' || v.stock==0){
                                    v.stock = 0
                                    v.specStock = 0
                                }
                            })
                            this.total = res.data.data.totalCount
                        } else {
                            this.dialogTableVisible = false
                            let h = this.$createElement;
                            this.$msgbox({
                                title: '提示信息',
                                message: h('p', {
                                    style: 'text-align:center'
                                }, '没有可以添加的商品！'),
                                showCancelButton: false,
                                confirmButtonText: '确定',
                                cancelButtonText: '取消',
                                beforeClose: (action, instance, done) => {
                                    if (action === 'confirm') {
                                        done()
                                    } else {
                                        done()
                                    }
                                    delete instance.$slots.default;
                                }
                            })
                        }
                    }, this.currentPage, 10, (res) => {
                        this.goodsLoading = false
                    })
                }
            },
            //搜索formGoods查询
            searchGoods() {
                let body = {
                    ...this.chooseGoodsForm,
                    ...{
                        buyerId: this.orderInfoObj.buyerId,
                            skuNoList: this.skuNotNoList, //选中商品ID列表
                            storeSellerId: sessionStorage.traderId,
                        // skuType:1 //过滤套餐商品
                    }
                };

                // if (this.orderInfoObj.supplierTraderId != this.orderInfoObj.sellerId) {// 子订单
                //     body.skuType = 1
                // }
                if(this.orderInfoObj.isSubstituteOrder){
                    body.isSubstituteOrder = true;
                }
                this.goodsSkuCustomSearch(body, true)
            },
            //分类查询接口
            classifyTopListSelect() {
                axiosApi('/goods/classifyTopList/select', {}, (res) => {

                    this.classifyList = res.data.data
                }, 1, 10, (res) => {

                })
            },
            //添加备注
            addGoodRemark(index) {
                this.goodRemarkVisible = true
                this.remarkIndex = index
                if (this.multipleSelectionConfirm[index].remark != null) {
                    this.goodRemarkForm.goodRemark = this.multipleSelectionConfirm[index].remark
                } else {
                    this.goodRemarkForm.goodRemark = null
                }
            },
            //选中商品
            handleSelectionChange(val) {
                this.multipleSelection = val
            },
            //选择商品弹框
            addGoods() {
                if (this.orderInfoObj.buyerId != null && this.orderInfoObj.buyerId != '') { //已选择客户
                    this.dialogTableVisible = true
                    this.skuNotNoList = []
                    if (this.multipleSelectionConfirm.length > 0) {
                        this.multipleSelectionConfirm.forEach((i, index) => {
                            if (!i.changePurchaseItem) {//换购不过滤
                                this.skuNotNoList.push(i.skuNo)
                            }
                        })
                    } else {
                        this.skuNotNoList = []
                    }
                    let body = {
                        buyerId: this.orderInfoObj.buyerId,
                        skuNoList: this.skuNotNoList, //选中商品ID列表
                        storeSellerId: sessionStorage.traderId,
                    }
                    // if (this.orderInfoObj.supplierTraderId != this.orderInfoObj.sellerId) {// 子订单
                    //     body.skuType = 1
                    // }
                    if(this.orderInfoObj.isSubstituteOrder){
                    	body.isSubstituteOrder = true;
                    }
                    this.goodsSkuCustomSearch(body)
                }

            },
            removeChooseGood(item, type,index) { //移除选中的商品
                let _this = this;
                let h = this.$createElement;
                this.$msgbox({
                    title: '提示信息',
                    message: h('p', {
                        style: 'text-align:center'
                    }, '是否确定删除?'),
                    showCancelButton: true,
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    beforeClose: (action, instance, done) => {
                        if (action === 'confirm') {
                            if (type === 'good') {// 删除商品
                                // this.multipleSelectionConfirm = this.multipleSelectionConfirm.filter((i, index) => {
                                //     return i != item
                                // })
                                console.log(_this.multipleSelectionConfirm)
                                // debugger
                                _this.multipleSelectionConfirm.splice(index,1);
                                if (this.multipleSelectionConfirm.length == 0) {
                                    this.activityList = {
                                        couponExPos: []
                                    }
                                    this.couponItem = {
                                        minusMoney: 0
                                    } //选中的优惠券
                                    this.remark = null //备注
                                } else {
                                    this.updateNumAndPrice()// 更新 商品个数和 总价格
                                }
                                // this.updateNumAndPrice()// 更新 商品个数和 总价格
                            } else if (type === 'gift') {// 删除赠品
                                let k=0
                                this.giftExPoList.map(i=>{
                                    if (!i.isOld){
                                        k+=1
                                    }
                                })
                                if ((index>this.giftExPoList.length - k) || (index == this.giftExPoList.length - k)){
                                    this.deleteOnewillGift({
                                        index1: this.$store.state.ticket.willaddgiftDataid,
                                        index2: index-(this.giftExPoList.length-k)
                                    })
                                }
                                //云销新增同个赠品多次下单 导致订单列表有多个商品skuNo相同 修改过滤方法
                                this.giftExPoList = this.giftExPoList.filter(v => {
                                    return v.rowId != item.rowId
                                })
                            }
                            setTimeout(() => {
                                this.$message({
                                    type: 'success',
                                    message: '操作成功！',
                                    duration: 1000
                                })
                            }, 300)
                            done()
                        } else {
                            done()
                        }
                        delete instance.$slots.default;
                    }
                })
            },
            //选择商品保存
            goodsListSave() {
                let that = this;
                let multipleSelection = JSON.parse(JSON.stringify(this.multipleSelection))
                multipleSelection.forEach(i => {
                    i.isAdd = true;
                    i.k3SaleType = '001';
                    i.isIntegerMultiple = true;
                    i.cloneIsIntergerMuliple = true;
                    if (i.fileUrl) {
                        i.image = i.fileUrl
                    };
                    i.calcNum = 1;
                    i.goodsSkuInfo = {
                        showPrice:this.priceUnit === 1 ? i.singlePriorityPrice.toFixed(2) : i.boxPriorityPrice.toFixed(2) ,
                        showName:this.priceUnit === 1 ? i.unitsName  : i.packageUnitName
                    };
                    i.integerUnitName = i.packageUnitName;
                    i.baseUnit = i.unitsName;
                    i.baseShowUnitPrice = i.singlePriorityPrice;
                    i.baseShowUnitName = i.unitsName;
                    i.specShowUnitPrice = i.boxPriorityPrice;
                    i.specShowUnitName = i.packageUnitName;
                    i.price = i.goodsSkuInfo.showPrice;
                    if(i.isIntegerMultiple){//按规格单位 且 没开启按瓶设价
                        if(this.priceUnit === 1){
                            i.totalPrice = (i.price * i.calcNum * i.specInfoNum).toFixed(2);
                        }else{
                            i.totalPrice = (i.price * i.calcNum).toFixed(2)
                        }
                    }else{
                        i.totalPrice = (i.price * i.calcNum).toFixed(2)
                    }
                    
                })
                this.multipleSelectionConfirm.push(...multipleSelection) //数组合并选中商品

                this.dialogTableVisible = false
                this.updateNumAndPrice()
            },
            goodsListCancel() { //选择商品弹框取消
                this.dialogTableVisible = false
            },
            //保存商品备注
            handleSaveGoodRemark() {
                this.$refs.goodRemarkForm.validate((valid) => {
                    if (valid) {
                        if (this.goodRemarkForm.goodRemark !== null) {
                            this.multipleSelectionConfirm[this.remarkIndex].remark = this.goodRemarkForm.goodRemark.trim()
                        } else {
                            this.multipleSelectionConfirm[this.remarkIndex].remark = null
                        }
                        setTimeout(() => {
                            this.$message({
                                type: 'success',
                                message: '操作成功！',
                                duration: 1000
                            })
                        }, 500)
                        this.goodRemarkVisible = false
                    } else {
                        return false
                    }
                })
            },
            //取消商品备注
            CancelGoodRemark(i) {
                this.$refs.goodRemarkForm.resetFields()
                this.goodRemarkVisible = false
            },
            //商品分页查询
            handleCurrentChange(val) {
                this.currentPage = val
                let body = Object.assign(this.chooseGoodsForm, {
                    buyerId: this.orderInfoObj.buyerId,
                    skuNoList: this.skuNotNoList, //选中商品ID列表
                    storeSellerId: sessionStorage.traderId,
                    // skuType:1 //过滤套餐商品
                })
                // if (this.orderInfoObj.supplierTraderId != this.orderInfoObj.sellerId) {// 子订单
                //     body.skuType = 1
                // }
				if(this.orderInfoObj.isSubstituteOrder){
					body.isSubstituteOrder = true;
				}
                this.goodsSkuCustomSearch(body)
            },
        },
        computed:{
            isDealerUser(){
                return this.$store.state.clientType.isDealerUser;
            },
            isCityPartner() {
                return this.$store.state.clientType.isCityPartner;
            },
            giftExtraList(){
                this.$store.state.ticket.willaddgiftData.map(v => {
                   v.giftList.map(item => {
                       item.isNewGift = true;
                   })
                });
                return this.$store.state.ticket.willaddgiftData;
            }
        }

    }
</script>

<style lang="less">
    #supplier-change-order {
        .remark {
            border: 1px solid #ddd;
            border-top: none;
        }
        .el-table-column--selection{
            .cell{
                width: 50px;
            }
        }
        .unit_select2{
            height: 32px;
            .el-input{
                width: 120px;
            }
        }
        .resize-triggers, .resize-triggers > div, .contract-trigger:before{
            height: 32px;
        }

        .lh-32 {
            line-height: 32px;
        }

        .goodRemarkDialog {
            .el-dialog {
                height: 300px;
            }

            .el-dialog__body {
                padding: 50px 30px;
            }
        }

        .chooseGoods {
            .el-dialog {
                width: 1000px;

                .el-table {
                    width: 100%;
                }

                .el-dialog__body {
                    margin-bottom: 80px;
                    padding: 30px 30px;
                    .goodsForm{
                        width: 850px;
                        .el-input{
                            width: 195px;
                        }
                    }
                    .el-form {
                        .el-form-item__label {
                            width: 70px;
                            font-size: 12px;
                        }

                        .el-input__inner {
                            font-size: 12px;
                        }

                        .el-form-item {
                            margin-right: 10px;
                        }
                    }
                }
            }
        }

        .el-input-number.el-input-number--small {
            .el-input {
                width: 131px !important;
            }
        }

        .store-confirm-order {
            margin: 0 auto;
            padding: 1px 45px;
            box-sizing: border-box;
        }

        .order-detail {
            width: 940px;
            margin: 0 auto;

            .el-table__empty-block {
                display: none;
            }

            .order-content {
                .order-item-item-3 {
                    width: 180px;
                    box-sizing: border-box;
                    border-right: 1px solid #DDDDDD;
                    .el-input__suffix{
                        right: 0;
                    }
                    .unit_spec {
                        width: 50px;
                        height: 30px;
                        margin-top: -1px;

                        .el-input {
                            width: 50px;
                            height: 30px;
                        }

                        .el-input__inner {
                            width: 50px;
                            height: 30px !important;
                        }
                    }
                    .el-input-number .el-input .el-input__inner{
                        height: 32px!important;
                    }
                    .unitList .el-input--mini{
                        width:50px;
                    }
                }

                .pt56 {
                    padding-top: 56px !important;
                }

                .order-item-item-4 {
                    border-right: 1px solid #DDDDDD;
                    padding: 0 20px;
                    text-align: center;
                    width: 100px;
                }

                .order-item-item-5 {
                    width: 138px;
                    text-align: center;
                    &>div{
                        text-align: center;
                    }
                    /*display: flex;*/
                    /*flex-direction: column;*/
                    /*align-items: center;*/
                    /*justify-content: center;*/
                }

                .message {
                    .el-input {
                        width: 500px;
                    }
                }

                img {
                    width: 80px;
                    height: 80px;
                }

                .item-content {
                    border-top: 1px solid #DDD;
                    width: 940px;
                }

                .item-item {
                    border: 1px solid #DDD;
                    border-top: none;
                    box-sizing: border-box;
                    display: flex;

                    .order-item-item-2 .el-input, .order-item-item-4 .el-input {
                        width: 90px;
                    }

                    .gift {
                        position: relative;
                    }

                    .triangle-topleft {
                        border-top: 60px solid #ff4201;
                        width: 0;
                        height: 0;
                        border-right: 60px solid transparent;
                        position: absolute;
                        top: 0;
                        left: 0;
                    }

                    .init {
                        transform: rotate(-45deg) translate(-4px, 10px);
                        position: absolute;
                        left: 0;
                        top: 0;
                        color: #fff;
                        letter-spacing: -0.34px;
                    }
                }

                .order-item-item-2 {
                    padding-left: 10px;
                    padding-right: 20px;
                    width: 120px;
                }

                .order-item-item {
                    width: 310px;

                }
            }

            .total {
                width: 940px;
                padding: 30px 0 30px 0;
                border: 1px solid #DDDDDD;
                border-top: none;
                box-sizing: border-box;

                .payment {
                    color: #D24C4C;
                }

                span {
                    display: inline-block;
                    /*width: 150px;*/
                    text-align: left;
                    display: inline-block;
                }

                ul li {
                    margin-bottom: 5px;

                }

                .coupon-select {
                    width: 198px !important;

                    .el-input--small {
                        width: 198px !important;
                    }

                    .el-input__inner {
                        padding-right: 35px !important;
                    }
                }
            }
        }
    }
</style>
