<template>
    <div id="store-purchase-order">
        <div class="title-top bg-f clearfix">
            <div>我的订单</div>
        </div>
        <!-- 内容 -->
        <div class="content bg-f pb-50 pl-30 pr-30 mt-5">
            <ul class="clearfix order_status bb">
                <template v-for='(tab,index) in dataTabs'  class="item">
                    <div :key="index+'bg'">
                        <li :type='index' class="fl" :class="type==index?'active':''"  @click='handleTabs'>
                            {{tab.title}}<span :type='index' v-if="tab.num>0" class="color-danger">({{tab.num}})</span>
                        </li>
                        <div class="vertical-line" ></div>
                    </div>
                </template>
            </ul>
            <div class="clearfix search">
                <el-form :inline='true' class="pt-30 fl" :model="form">
                    <el-form-item label='订单商品'>
                        <el-input v-model.trim="form.itemName"></el-input>
                    </el-form-item>
                    <el-form-item label='订单编号'>
                        <el-input v-model.trim="form.orderSn" @input="form.orderSn=form.orderSn.replace(/[\u4e00-\u9fa5]/ig,'')"></el-input>
                    </el-form-item>
                    <el-form-item label='下单时间'>
                        <ys-daterange-picker v-model="form.orderDateRange"/>
                    </el-form-item>
                    <el-form-item label="支付方式" class="mr-20">
                        <el-select
                        v-model="form.payTypeList"
                        clearable
                        multiple
                        collapse-tags
                        placeholder="请选择"
                        >
                        <el-option
                            v-for="item in payTypeInfo"
                            :key=" item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
            </div>
            <div class="pb-30">
                <el-button icon="el-icon-search" type='info' @click="handleCheck">查 询</el-button>
            </div>
            <div class='tar'>
                <!--<el-button type='text'>批量导出</el-button>-->
            </div>
            <div class="order-list" v-loading="loading">
                <div class="clearfix font-12 bg-e table_header color-3" style="display: flex;text-align: center">
                    <div class="" style="flex: 4">商品信息</div>
                    <div class="" style="flex: 1">单价</div>
                    <div class="" style="flex: 1">数量</div>
                    <div class="" style="flex: 1">订单总额</div>
                    <div class="" style="flex: 1">交易状态</div>
                    <div class="" style="flex: 1">预估积分</div>
                    <div class="" style="flex: 1">支付方式</div>
                    <div class="" style="flex: 2">操作</div>
                </div>
                <div class="table-item-default tac" v-if="ordersListResVo.length<=0">
                    <img src="../../../assets/empty.png" alt=""/>
                    <div class="color-danger mt-30">
                        <router-link :to="{path:'/customer/shop/shopIndex'}"><span
                                class="color-danger"> 啥都没有，去首页看看 ></span></router-link>
                    </div>
                </div>
                <div v-else>
                    <div class="table-item mt-20 font-12" v-for='order in ordersListResVo' :key='order.sn'>
                        <div class="clearfix table-item-top bg-e" style="paddingRight: 20px;">
                            <span class='fl ml-20'>订单编号：{{order.parentOrSon == 3?order.parentOrderSn:order.sn}}</span>
                            <span class='fl ml-20'>下单时间：{{order.gmtCreate | time}}</span>
                            <span class="fl ml-20" v-if="order.gmtComplete">交易完成时间：{{ order.gmtComplete | time }}</span>
                            <span class='fl ml-20' v-if="order.parentOrSon == 3">订单状态：已拆分</span>
                            <img class="ml-20 fr separate" v-if="order.deliverySeparate" style="height:50px;width:auto;"
                                 src="../../../assets/separate.png"/>
                            <img class="ml-20 fr separate" v-if="order.openStraight" style="height:50px;width:auto;"
                                 src="../../../assets/straight.png"/>
                            <span class="fl ml-20">{{order.sellerType == 4 ? order.sellerName : order.platformName}}</span>
                        </div>
                        <div class="clearfix table-item-top" v-if="order.remark">
                            <span class='fl ml-20 color-9'>备注：{{order.remark}}</span>
                            <div v-if="order.remarkPics" style="display:inline-block">
                                <img v-for="(item,index) in order.remarkPics" :key="index" style="width:30px;height:30px;margin-top: 10px" :src="item" alt="" @click="lookImage(order.remarkPics,index)">
                            </div>
                        </div>
                        <div>
                            <!-- <div class="clearfix table-item-top">
                              <span class='fl ml-20'>订单编号：{{order.sn}}</span>
                              <span class='fl ml-20'>下单时间：{{order.gmtCreate | time}}</span>
                              <img class="ml-20 fr separate" v-if="order.deliverySeparate" style="height:50px;width:auto;" src="../../../assets/separate.png"/>
                            </div> -->
                            <div class="table-item-content clearfix" style="display: flex;">
                                <div class="clearfix bb" style="flex: 6">
                                    <!--商品清单-->
                                    <div class="clearfix" :class="{'bb': index !=order.orderItemVos.length-1 || (index ==order.orderItemVos.length-1 && order.orderGiftResVos.length > 0)}"  style="display: flex;padding: 20px 0 20px 20px" v-for="(orderItem, index) in order.orderItemVos" :key="index+'bc'">
                                        <div class="gift" style="flex: 4">
                                            <div v-if="orderItem.changePurchaseItem"
                                                 class="triangle-topleft border-red"></div>
                                            <span v-if="orderItem.changePurchaseItem" class="init default">换购</span>
                                            <img v-if="orderItem.image" :src="orderItem.image" class="fl mr-10"/>
                                            <img v-else src="../../../assets/goods.jpg" class="fl mr-10"/>
                                            <p>{{orderItem.skuName}}</p>
                                            <p class="color-9 mt-20" v-if="orderItem.goodsNo">商品编码:{{orderItem.goodsNo}}</p>
                                            <p class="color-9 mt-20">规格:1*{{orderItem.specInfoNum}}</p>
                                        </div>
                                        <div class="fl" style="flex: 1;text-align: center">¥{{orderItem.showUnitPrice |
                                            fmoney}}/{{orderItem.showUnitName}}
                                        </div>
                                        <div class="fl" style="flex: 1;text-align: center">
                                            <div>{{orderItem.specNum}}{{orderItem.unit}}</div>
                                            <div class="color-danger  mt-10"
                                                 v-if="order.deliverySeparate&&order.orderStatus == 2">
                                                可提{{orderItem.remainingSpecNum}}{{orderItem.unit}}
                                            </div>
                                        </div>
                                    </div>
                                    <!--赠品清单-->
                                    <div class="clearfix " :class="{'bb': index != order.orderGiftResVos.length-1}"  style="display: flex;padding: 20px 0 20px 20px" v-for="(orderItem, index) in order.orderGiftResVos" :key="index+'bd'">
                                        <div class="fl gift" style="flex: 4">
                                            <div class="triangle-topleft border-red"></div>
                                            <span class="init default">赠品</span>
                                            <img v-if="orderItem.image" :src="orderItem.image" class="fl mr-10"/>
                                            <img v-else src="../../../assets/goods.jpg" class="fl mr-10"/>
                                            <p>{{orderItem.skuName}}</p>
                                            <p class="color-9 mt-20" v-if="orderItem.goodsNo">商品编码:{{orderItem.goodsNo}}</p>
                                            <p v-if="orderItem.specInfoNum" class="color-9 mt-20">
                                                规格:1*{{orderItem.specInfoNum}}</p>
                                        </div>
                                        <div class="fl giftPriceClass" style="flex: 1;text-align: center">¥{{ orderItem.salesPrice }}/{{ orderItem.sellingUnitName }}</div>
                                        <div class="fl" style="flex: 1;text-align: center">
                                            <div>{{orderItem.giftNum}}{{orderItem.sellingUnitName}}</div>
                                            <div class="color-danger mt-10"
                                                 v-if="order.deliverySeparate&&order.orderStatus == 2">
                                                可提{{orderItem.remainingNum}}{{orderItem.sellingUnitName}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="bl bb tac pt-20" style="flex: 1">¥{{order.totalMoney | fmoney}}
                                </div>
                                <div class="bl bb tac pt-20" style="flex: 1">
                                    <p v-if="order.editAuditState == 1 && order.orderStatus == 1" class="color-danger">
                                        待审核</p>
                                    <div v-else>
                                        <p v-if=" order.orderStatus == 1 ">待付款</p>
                                        <p class="color-danger font-12"
                                           v-if="order.remainAutoCancleTime&&(order.editAuditState == 3||order.editAuditState == null) && order.orderStatus == 1 && (order.lastestRemittanceAuditStatus == 2 || order.lastestRemittanceAuditStatus == null)">
                                            剩余<span
                                                v-text="typeof(order.remainAutoCancleTime)=='string'?order.remainAutoCancleTime:null"></span>
                                        </p>
                                    </div>
                                    <p v-if=" order.orderStatus == 0 ">已关闭</p>
                                    <p v-if=" order.orderStatus == 2 ">{{ order.shipStatus === 4 ? '部分发货' : '待发货'}}</p>
                                    <p v-if=" order.orderStatus == 3 ">已发货</p>
                                    <p v-if=" order.orderStatus == 4 ">已完成</p>
                                    <p v-if=" order.orderStatus == 5 ">已退款</p>
                                    <!-- <div>
                                        <el-button type='text' class="font-12 orderDetail_btn"  >售后记录</el-button>
                                    </div> -->
                                </div>
                                <div class="bl bb tac pt-20" style="flex: 1">
                                    {{ order.predictiveIntegral }}
                                </div>
                                <div class="bl bb tac pt-20" style="flex: 1">
                                    {{ order.payTypeName }}
                                </div>
                                <div class="bl bb tac pt-20 pb-20" style="flex: 2;">
                                    <div class="w-100">
                                        <div v-if="order.payStatus == 1">
                                            <div v-if="order.lastestRemittanceAuditStatus == 2 " class="color-danger">
                                                支付凭证未通过审核
                                            </div>
                                            <div v-if="order.lastestRemittanceAuditStatus == 1 " class="color-danger">
                                                支付凭证正在审核中
                                            </div>
                                        </div>
                                        <div v-if="order.editAuditState == 2 && order.orderStatus == 1"
                                             class="color-danger">商家正在审核您的订单
                                        </div>
                                        <!-- <div v-if="(order.editAuditState == 1 || order.editAuditState == 2) && order.orderStatus == 1"></div> -->
                                        <div v-if="showButton('PAY',100040)&&order.enablePay">
                                            <!-- v-if="!order.paymentOnBehalf" -->
                                            <!--v-if="showButton('PAY',100040)&&order.orderStatus!=0 && order.payStatus !=3 && (order.lastestRemittanceAuditStatus == 2 || !order.lastestRemittanceAuditStatus) && order.payType != 6 && order.payType != 2"-->
                                            <div>
                                                <el-button
                                                        type='danger'
                                                        class="font-12"
                                                        @click="orderPay(order.parentOrSon == 3?order.parentOrderSn:order.sn,order.parentOrSon == 3?2: 1)"
                                                >去付款
                                                </el-button>
                                            </div>
                                        </div>
                                        <div v-if="order.orderStatus == 3">
                                            <el-button class="ml-0 mt-10 font-12"
                                                       @click="confirmReceipt(order.parentOrSon == 3?order.parentOrderSn:order.sn)">
                                                确认收货
                                            </el-button>
                                        </div>
                                        <!-- 订单状态为待发货并且卖家开通了支持订单提货 -->
                                        <div v-else-if="order.orderStatus == 2 && order.deliverySeparate">
                                            <el-button
                                                    @click="notifySeparate(order.parentOrSon == 3?order.parentOrderSn:order.sn,order.platformName)"
                                                    class="ml-0 mt-10 font-12" type='danger'>订单提货
                                            </el-button>
                                        </div>
                                        <div >
                                            <el-button class="ml-0 mt-10 font-12" type='danger'  v-if="order.orderItemVos.length > 0  && (order.orderStatus == 2 || order.orderStatus == 3 || order.orderStatus == 4 ||order.orderStatus == 5 || order.orderStatus == 0)"  @click="tryAgainOrder(order)">
                                                再来一单
                                            </el-button>
                                        </div>
                                        <div >
                                            <el-button class="ml-0 mt-10 font-12"   v-if="order.enableAfterSale"  @click="applyForAfterService(order)">
                                                申请售后
                                            </el-button>
                                        </div>
                                         <div >
                                            <el-button class="ml-0 mt-10 font-12"   v-if="order.enableRefund"  @click="confirmRefundApply(order)">
                                                仅退款
                                            </el-button>
                                        </div>
                                        <div v-if="order.orderStatus == 1 && order.payStatus == 1 && (order.lastestRemittanceAuditStatus != 1 )">
                                            <el-button class="ml-0 mt-10 font-12" v-if="showButton('CANCEL',100040)"
                                                       @click='handleCancelOrder(order.parentOrSon == 3?order.parentOrderSn:order.sn)'>
                                                取消订单
                                            </el-button>
                                        </div>
                                        <div>
                                            <el-button class="mt-10 font-12"
                                                       @click="orderDetail(order.parentOrSon == 3?order.parentOrderSn:order.sn,order.parentOrSon == 3?2: 1)">
                                                查看订单
                                            </el-button>
                                        </div>
                                        <div v-if="order.orderStatus == 0">
                                            <!-- 已关闭才能删 -->
                                            <el-button class="mt-10 font-12" @click="submitDelete(order.parentOrSon == 3?order.parentOrderSn:order.sn)">删除订单</el-button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <div v-if="order.parentOrSon == 3&&(order.orderStatus != 1&&order.orderStatus!=0)">
                            <div v-for="(orderSon) in order.sonOrders" :key="orderSon.sn">
                                <div class="clearfix table-item-top">
                                    <span class='fl ml-20'>订单编号：{{orderSon.sn}}</span>
                                    <img class="ml-20 fr separate" v-if="orderSon.deliverySeparate"
                                         style="height:50px;width:auto;" src="../../../assets/separate.png"/>
                                    <img class="ml-20 fr separate" v-if="orderSon.openStraight"
                                         style="height:50px;width:auto;" src="../../../assets/straight.png"/>
                                </div>
                                <div class="table-item-content clearfix" style="display: flex;">
                                    <div class="clearfix bb" style="flex: 6;">
                                        <div class="clearfix" :class="{'bb': index !=orderSon.orderItemVos.length-1 || (index ==orderSon.orderItemVos.length-1 && orderSon.orderGiftResVos.length > 0)}" style="display: flex;padding: 20px 0 20px 20px"  v-for="(orderItem, index) in orderSon.orderItemVos" :key="index+'be'">
                                            <div class="fl gift" style="flex: 4">
                                                <div v-if="orderItem.changePurchaseItem"
                                                     class="triangle-topleft border-red"></div>
                                                <span v-if="orderItem.changePurchaseItem" class="init default">换购</span>
                                                <img v-if="orderItem.image" :src="orderItem.image" class="fl mr-10"/>
                                                <img v-else src="../../../assets/goods.jpg" class="fl mr-10"/>
                                                <p>{{orderItem.skuName}}</p>
                                                <p class="color-9 mt-20">规格:1*{{orderItem.specInfoNum}}</p>
                                            </div>
                                            <div class="fl " style="flex: 1;text-align: center">¥{{orderItem.showUnitPrice |
                                                fmoney}}/{{orderItem.showUnitName}}
                                            </div>
                                            <div class="fl" style="flex: 1;text-align: center">
                                                <div>{{orderItem.specNum}}{{orderItem.unit}}</div>
                                                <div class="color-danger  mt-10"
                                                     v-if="orderSon.deliverySeparate&&orderSon.orderStatus == 2">
                                                    可提{{orderItem.remainingSpecNum}}{{orderItem.unit}}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="clearfix" :class="{'bb': index != orderSon.orderGiftResVos.length-1}" style="display: flex;padding: 20px 0 20px 20px" v-for="(orderItem, index) in orderSon.orderGiftResVos" :key="index+'bf'">
                                            <div class="fl gift" style="flex: 4">
                                                <div class="triangle-topleft border-red"></div>
                                                <span class="init default">赠品</span>
                                                <img v-if="orderItem.image" :src="orderItem.image" class="fl mr-10"/>
                                                <img v-else src="../../../assets/goods.jpg" class="fl mr-10"/>
                                                <p>{{orderItem.skuName}}</p>
                                                <p v-if="orderItem.specInfoNum" class="color-9 mt-20">
                                                    规格:1*{{orderItem.specInfoNum}}</p>
                                            </div>
                                            <div class="fl" style="flex: 1;text-align: center">¥0</div>
                                            <div class="fl " style="flex: 1;text-align: center">
                                                <div>{{orderItem.giftNum}}{{orderItem.sellingUnitName}}</div>
                                                <div class="color-danger mt-10"
                                                     v-if="orderSon.deliverySeparate&&orderSon.orderStatus == 2">
                                                    可提{{orderItem.remainingNum}}{{orderItem.sellingUnitName}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="bl bb tac pt-20" style="flex: 1">¥{{orderSon.needPayMoney |
                                        fmoney}}
                                    </div>
                                    <div class="bl bb tac pt-20" style="flex: 1">
                                        <p v-if="orderSon.editAuditState == 1 && orderSon.orderStatus == 1"
                                           class="color-danger">待审核</p>
                                        <div v-else>
                                            <p v-if=" orderSon.orderStatus == 1 ">待付款</p>
                                        </div>
                                        <p v-if=" orderSon.orderStatus == 0 ">已关闭</p>
                                        <p v-if=" orderSon.orderStatus == 2 ">{{ orderSon.shipStatus === 4 ? '部分发货' :
                                            '待发货'}}</p>
                                        <p v-if=" orderSon.orderStatus == 3 ">已发货</p>
                                        <p v-if=" orderSon.orderStatus == 4 ">已完成</p>
                                    
                                    </div>
                                    <div class="bl bb tac pt-20  pb-20" style="flex: 2;">
                                        <div class="w-100">
                                            <div v-if="orderSon.payStatus == 1">
                                                <div v-if="orderSon.lastestRemittanceAuditStatus == 2 "
                                                     class="color-danger">
                                                    支付凭证未通过审核
                                                </div>
                                                <div v-if="orderSon.lastestRemittanceAuditStatus == 1 "
                                                     class="color-danger">
                                                    支付凭证正在审核中
                                                </div>
                                            </div>
                                            <div v-if="orderSon.editAuditState == 2 && orderSon.orderStatus == 1"
                                                 class="color-danger">商家正在审核您的订单
                                            </div>
                                            <div v-if="(orderSon.editAuditState == 1 || orderSon.editAuditState == 2) && orderSon.orderStatus == 1"></div>
                                            <div v-else>
                                                <div>
                                                    <el-button
                                                            type='danger'
                                                            class="font-12"
                                                            v-if="showButton('PAY',100040)&&orderSon.orderStatus!=0 && orderSon.payStatus !=3 && (orderSon.lastestRemittanceAuditStatus == 2 || !orderSon.lastestRemittanceAuditStatus) && orderSon.payType != 6 && orderSon.payType != 2"
                                                            @click="orderPay(orderSon.sn, 1)">去付款
                                                    </el-button>
                                                </div>
                                            </div>
                                            <div >
                                                <el-button class="ml-0 mt-10 font-12" type='danger' v-if="orderSon.orderItemVos.length > 0  && (orderSon.orderStatus == 2 || orderSon.orderStatus == 3 || orderSon.orderStatus == 4 || orderSon.orderStatus == 0)" @click="tryAgainOrder(orderSon)">
                                                    再来一单
                                                </el-button>
                                            </div>
                                            <div v-if="orderSon.orderStatus == 3">
                                                <el-button class="ml-0 mt-10" @click="confirmReceipt(orderSon.sn)">
                                                    确认收货
                                                </el-button>
                                            </div>
                                            <div v-else-if="orderSon.orderStatus == 2 && orderSon.deliverySeparate">
                                                <el-button  @click="notifySeparate(orderSon.sn,orderSon.platformName)"
                                                           class="ml-0 mt-10 font-12" type='danger'>订单提货
                                                </el-button>
                                            </div>
                                           
                                            <div v-if="orderSon.orderStatus == 1 && orderSon.payStatus == 1 && (orderSon.lastestRemittanceAuditStatus != 1 )">
                                                <el-button class="ml-0 mt-10 font-12" v-if="showButton('CANCEL',100040)"
                                                           @click='handleCancelOrder(orderSon.sn)'>取消订单
                                                </el-button>
                                            </div>
                                            <div>
                                                <el-button class="mt-10 font-12" @click="orderDetail(orderSon.sn,1)">查看订单
                                                </el-button>
                                            </div>
                                            <div v-if="orderSon.orderStatus == 0">
                                                <el-button class="mt-10 font-12" @click="submitDelete(orderSon.sn)">删除订单</el-button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                    </div>
                    <!--取消订单弹出框-->
                    <el-dialog class='cancelOrderDialog' title='取消订单理由' :visible.sync='cancelOrderVisible' @close="closeOrderDialog">
                        <el-form class="pl-30 pr-30" :model="form" ref="cancelOrderForm" :rules="rules">
                            <el-form-item label="" prop='cancleReason'>
                                <el-input type='textarea' placeholder='请输入取消订单的理由...'
                                          :autosize="{ minRows: 3 ,maxRows: 10}"
                                          v-model.trim='form.cancleReason'></el-input>
                            </el-form-item>
                        </el-form>
                        <div slot='footer' class="dialog-footer">
                            <el-button type='danger' :loading="btnLoading" @click="cancelOrderCommit">提 交</el-button>
                            <el-button @click="cancelOrderVisible = false">取 消</el-button>
                        </div>
                    </el-dialog>
                </div>
                <el-pagination v-if='total>0' class="tar mt-20" :current-page="currentPage" :page-size='10' :total='total'
                               layout="prev , pager, next, jumper" @current-change="handleCurrentChange">
                    <!-- 分页组件 -->
                </el-pagination>
            </div>
        </div>
        <!-- 订单提货弹窗 -->
        <separateOrderDialog :dialogVisible.sync="separateDialog" :orderSn="orderSn" :sellerName="sellerName"
                             @deliverySuccessCallback="handleCheck"/>
        <imgview :visible="imgsvisible" :imgs="showimgs" :startindex="startindex" @close="imgsvisible=false"></imgview>
    </div>
</template>

<script>
    //	import {mapMutations, mapActions} from 'vuex'
    import {axiosApi, basePath} from "~/api/api"
    import separateOrderDialog from './separateOrderDialog.vue'
    // import YsDaterangePicker from "~/components/ys-daterange-picker";
    import { deleteOrder , refundApply} from "~/api/order";
    export default {
        components: { separateOrderDialog},
        data() {
            return {
                type: '0',
                dataTabs: [{title: '所有订单', num: 0}, {title: '待付款', num: 0}, {title: '待发货', num: 0}, {
                    title: '已发货',
                    num: 0
                }, {title: '分批待提货', num: 0}, {title: '已完成', num: 0}, {title: '已关闭', num: 0}],
                data: ['1'],
                cancelOrderVisible: false, reason: null, ordersListResVo: [],
                orderSn: null,//订单编号
                form: {
                    cancleReason: null,   //订单取消原因
                    orderDateRange: [],   //gmtOrderStart下单开始时间 gmtOrderEnd下单结束时间
                    itemName: null,		//itemName商品名称
                    orderSn: null,   //订单编号
                    gmtOrderStart: null,
                    gmtOrderEnd: null,
                    traderType: '1',
                    orderStatus: null,    //订单状态
                    shipStatus: null, // 订单提货
                    payTypeList: [],
                },
                payTypeInfo:[
                    {value:'0',label:'组合支付'},
                    {value:'1',label:'线下支付'},
                    {value:'3',label:'在线支付'},
                    {value:'4',label:'余额支付'},
                    {value:'6',label:'账期支付'},
                    {value:'10',label:'直营店支付'},
                    {value:'11',label:'久币支付'},
                ],
                rules: {
                    cancleReason: [{max: 30, message: '不能超过30个字！'},{ required:true,message:'请输入取消理由' }]
                },
                clearTime: 1,  //定时器
                loading: false,
                currentPage: 1, total: null,
                badge: {		//标记
                    allCount: 0,//全部订单
                    notPayCount: 0,  //未付款
                    notDeliveryCount: 0, //已付款-待发货
                    orderDeliveriedCount: 0, //已发货-待收货
                    deliverySeparateCount: 0,// 订单提货
                    successCount: 0, //已完成
                    cancleCount: 0, //已取消
                    notAuditCount: 0, //未审核
                },
                btnLoading: false,
                sendGoodsDialog: false,
                sendGoodsForm: {
                    deliveryItemList: [],
                    gmtDelivery: null
                },
                separateDialog: false, // 订单提货弹窗控制
                sellerName: '', //供应商名称传值订单提货组件
                imgsvisible:false,
                showimgs:[],
                startindex:0
            }
        },
        watch:{

        },
        computed: {
			isCityPartner() {
				return this.$store.state.clientType.isCityPartner;
			},
        },
        mounted() {
            this.$nextTick(() => {
                let type = sessionStorage.getItem('purchaseOrderType')
                if (type) {
                    this.type = type
                    if (this.type == '0') {	//全部订单

                    } else if (this.type == '1') {  //待付款
                        this.form.orderStatus = 1
                    } else if (this.type == '2') { //待发货
                        this.form.orderStatus = 2
                    } else if (this.type == '3') {		//待收货
                        this.form.orderStatus = 3
                    } else if (this.type == '4') {// 订单提货
                        this.form.orderStatus = null

                        this.form.orderStatusList = [2,3]
                        this.form.shipStatusList = [1,4]
                        this.form.deliverySeparate = 1
                    } else if (this.type == '5') {// 已完成
                        this.form.orderStatus = 4
                    } else if (this.type == '6') {// 已关闭
                        this.form.orderStatus = 0
                    }
                } else {
                    this.type = '0'
                }
                this.form.buyerId = sessionStorage.traderId
                this.queryOrderList(this.form)
                this.anonOrderCount()
                sessionStorage.removeItem('purchaseOrderType')
            })
        },
        methods: {
            submitDelete(orderSn){
                this.$confirm('此操作将永久删除该订单, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    
                    this.deleteOrder(orderSn)
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });          
                });
            },
            deleteOrder(orderSn){
                deleteOrder({orderSn:orderSn}).then(res=>{
                    this.$message({
                        type: 'success',
                        message: '删除成功!'
                    });
                    this.handleCheck()
                }).catch(err=>{

                })
            },
            lookImage(list,index){
                this.imgsvisible = true;
                this.showimgs = list;
                this.startindex = index;
            },
            //申请售后
            applyForAfterService(order){
                console.log(order,'or')
                let orderSn = order.parentOrSon == 3 ? order.parentOrderSn : order.sn;
                this.$router.push({path:basePath + '/addService',query:{
                    orderSn,
                }})
            },
              // 仅退款确认

            confirmRefundApply(order){
                this.$confirm('是否确认对订单进行全额退款?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
                }).then(() => {
                    this.refundApplyBtn(order)
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消'
                    });          
                });
            },
            // 仅退款
            refundApplyBtn(order){
                refundApply({orderSn:order.sn}).then(res=>{
                    //查看
                    this.$router.push(basePath + "/serviceDetail/" + res.serviceNo);
                }).catch(err=>{

                })
            },

            //再来一单
            tryAgainOrder(order){
                // console.log(order,'order');
                let body = {
                    buyerId:order.buyerId,
                    // sellerId:order.sellerId,
                    skuList:order.orderItemVos.filter(v => {
                        return !v.activityId
                    }).map(v => {
                        return {
                            skuNo:v.skuNo,
                            num:v.specNum,
                            operatorType:1,
                            selectedSalesUnitType:v.isIntegerMultiple ? 2 : 3
                        }
                    })
                }
                axiosApi('/order/cart/batchInsert',body,res => {
                    // window.open(window.location.origin + basePath + '/shop/shopingCar')
                    this.$router.push(basePath + '/shop/shopingCar')
                },1,1,err => {
                    this.$message({
						message: err.data.message,
						type: 'warning'
					});
                })
            },
            notifySeparate(data, name) {
                // console.log(data)
                this.separateDialog = true
                this.orderSn = data
                this.sellerName = name
            },
            //订单统计(角标)
            anonOrderCount() {
                axiosApi('/order/buyerPurchaseOrder/count', {
                    traderType: 1,
                    buyerId: sessionStorage.traderId
                }, (res) => {
                    // console.log('标记数量',res)
					let allCount = 0
					for (let k in this.badge) {
					    if (k != 'allCount') {
					        allCount += this.badge[k]
					    }
					};

					/**
                     * {title: '所有订单', num: 0}, {title: '待付款', num: 0}, {title: '待发货', num: 0}, {
                    title: '已发货',
                    num: 0
                }, {title: '订单提货', num: 0}, {title: '已完成', num: 0}, {title: '已关闭', num: 0}
                     * */
					this.dataTabs[0].num = allCount;
					this.dataTabs.map((v,index) => {
                        if (index == "1") {
                            //待付款
                            v.num = res.data.data.notPayCount;
                        } else if (index == "2") {
                            //待发货
                            v.num = res.data.data.notDeliveryCount;
                        } else if (index == "3") {
                            //待收货
                            v.num = res.data.data.orderDeliveriedCount;
                        } else if (index == "4") {
                            // 分批发货
                            v.num = res.data.data.deliverySeparateCount;
                        } else if (index == "5") {
                            //已完成
                            v.num = res.data.data.successCount;
                        } else if (index == "6") {
                            //已关闭
                            v.num = res.data.data.cancleCount;
                        }           
                    })
                }, 1, 10, (res) => {

                })
            },
            handleCurrentChange(val) {
                this.currentPage = val
                this.queryOrderList(this.form)
                this.anonOrderCount()
            },
            handleTabs(e) {
                if (e.target.attributes.type) this.type = e.target.attributes.type.value
                this.form = {
                    cancleReason: null,   //订单取消原因
                    orderDateRange: [],   //gmtOrderStart下单开始时间 gmtOrderEnd下单结束时间
                    itemName: null,		//itemName商品名称
                    orderSn: null,   //订单编号
                    gmtOrderStart: null,
                    gmtOrderEnd: null,
                    traderType: '1',
                    buyerId: sessionStorage.traderId,
                    orderStatus: null,    //订单状态
                    shipStatus: null//订单提货
                }
                if (this.type == '0') {// 全部订单
                    this.form.orderStatus = null
                } else if (this.type == '1') {// 待付款
                    this.form.orderStatus = 1
                } else if (this.type == '2') {// 待发货
                    this.form.orderStatus = 2
                } else if (this.type == '3') {// 待收货
                    this.form.orderStatus = 3
                } else if (this.type == '4') {// 订单提货
                	this.form.orderStatus = null
                    this.form.orderStatusList = [2,3]
                    this.form.shipStatusList = [1,4]
                    this.form.deliverySeparate = 1
                } else if (this.type == '5') {// 已完成
                	this.form.orderStatus = 4
                } else if (this.type == '6') {// 已关闭
                    this.form.orderStatus = 0
                }
                this.currentPage = 1
                this.queryOrderList(this.form)
                this.anonOrderCount()
            },
            handleCheck() {   ///订单条件查询
                this.form.gmtOrderStart = !this.form.orderDateRange[0] ? null : this.form.orderDateRange[0].getTime()
                this.form.gmtOrderEnd = !this.form.orderDateRange[1] ? null : this.form.orderDateRange[1].getTime()
                this.currentPage = 1;
                this.queryOrderList(this.form)
                this.anonOrderCount()
            },
            /*商户订单列表查询 我的订单*/
            queryOrderList(body) {
                this.loading = true
                axiosApi('/order/buyerPurchaseOrder/list', body, (res) => {
                    this.loading = false
                    if (res.data.data) {
                        this.total = res.data.data.totalCount
                        this.ordersListResVo = res.data.data.elements
                        // console.log('商户订单列表查询', res,this.ordersListResVo)
                        /*定时器显示*/
                        this.ordersListResVo.forEach((item, index) => {
                           item.remarkPics = JSON.parse(item.remarkPics)
                            if (item.remainAutoCancleTime != null && item.remainAutoCancleTime > 0) {
                                let time = parseInt(item.remainAutoCancleTime / 1000)
                                item.remainAutoCancleTime = this.timeString(time)
                                item.clearTime = null
                                item.clearTime = setInterval(() => {
                                    time--
                                    item.remainAutoCancleTime = this.timeString(time)
                                    if (time <= 0) {
                                        clearTimeout(item.clearTime)
                                        item.remainAutoCancleTime = null
                                        //									this.cancelOrder({orderSn:item.sn,cancleType:3})  //系统取消
                                        // if (item.orderStatus == 1 && item.payStatus == 1 && (item.editAuditState == null || item.editAuditState == 3) && (item.lastestRemittanceAuditStatus == null || item.lastestRemittanceAuditStatus != 1)) item.orderStatus = 0
                                        this.$set(this.ordersListResVo, index, item)
                                    }
                                }, 1000)
                            } else {
                                // if (item.orderStatus == 1 && item.payStatus == 1 && (item.editAuditState == null || item.editAuditState == 3) && (item.lastestRemittanceAuditStatus == null || item.lastestRemittanceAuditStatus != 1)) {
                                //     item.orderStatus = 0;
                                //     if (item.parentOrSon == 3) {
                                //         item.sonOrders.forEach(v => {
                                //             v.orderStatus = 0
                                //         })
                                //     }
                                // }
                                item.remainAutoCancleTime = null
                                this.$set(this.ordersListResVo, index, item)
                            }
                        })
                    } else {
                        this.total = 0
                        this.ordersListResVo = []
                    }

                }, this.currentPage, 10, (res) => {
                    this.loading = false
                    // console.log('商户订单列表查询Error', res)
                    this.$message({type: 'error', message: res.data.message, duration: 1000})
                })
            },
            cancelOrder(body) {	//取消订单接口
                axiosApi('/anon/order/cancel', body, (res) => {
                    // console.log('取消订单',res)
//					this.$message({type:'success',message:'操作成功！',duration:1000})
                    this.queryOrderList(this.form)
                    this.anonOrderCount()
                }, 1, 10, (res) => {
                    // console.log('取消订单Error',res)
                    this.$message({type: 'error', message: res.data.message, duration: 1000})
                })
            },
            handleCancelOrder(orderSn) { //取消订单按钮
                this.cancelOrderVisible = true;
                this.orderSn = orderSn;
                this.btnLoading = false;
            },
            //关闭弹框
            closeOrderDialog() {
                this.$refs.cancelOrderForm.resetFields();
                this.cancelOrderVisible = false;
            },
            cancelOrderCommit() {  //弹窗 取消订单
                this.$refs.cancelOrderForm.validate((valid) => {
                    if (valid) {
                        this.btnLoading = true;
                        this.cancelOrderVisible = false
                        this.cancelOrder({
                            orderSn: this.orderSn,
                            cancleReason: this.form.cancleReason,
                            cancleType: 1,
                            buyerId: sessionStorage.traderId
                        })

                    } else {
                        return false
                    }
                })
            },
            confirmReceipt(orderSn) {		//确认收货
                const h = this.$createElement
                this.$msgbox({
                    title: '提示信息',
                    message: h('p', {
                        style: 'text-align:center'
                    }, '是否确认收货？'),
                    showCancelButton: true,
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    beforeClose: (action, instance, done) => {
                        if (action === 'confirm') {
                            this.confirmShipOrder({orderSn: orderSn})
                            done()
                        } else {
                            done()
                        }
                        delete instance.$slots.default;
                    }
                })
            },
            confirmShipOrder(body) {
                axiosApi('/anon/order/ship/confirm', body, (res) => {
                    this.$message({type: 'success', message: '操作成功!', duration: 1000})
                    this.queryOrderList(this.form)
                    this.anonOrderCount()
                }, 1, 10, (res) => {
                    this.$message({type: 'error', message: res.data.message, duration: 1000})
                })
            },
            orderDetail(orderSn, type) {		//去订单详情页面
                let a = null
                if (type == 2) a = 2
                if (type == 1) a = 1
                sessionStorage.setItem('orderSn', orderSn)
                sessionStorage.isParentPurchaseOrderType = a //因为订单详情里面 酒店版酒集根据截取url 判断是否是 酒集订单所以不能用 url传值 判断是否是拆分订单
                sessionStorage.purchaseOrderType = this.type;
                // window.open(window.location.origin + basePath + '/purchaseOrderDetail')
                this.$router.push({path: basePath + '/purchaseOrderDetail',query:{orderSn:orderSn}})
            },
            orderPay(orderSn, type) {
                let a = null
                if (type == 2) a = 2
                if (type == 1) a = 1
                sessionStorage.isParentPurchaseOrderType = a
                sessionStorage.purchaseOrderType = this.type
                sessionStorage.setItem('orderSn', orderSn)
                window.open(window.location.origin + basePath + '/payOrder?topay=1')
                // this.$router.push(basePath + '/payOrder')
            }
        }
    }
</script>

<style lang="less">
    #store-purchase-order {
        .bb {
            border-bottom: 1px solid #ECEEF6;
        }

        .bl {
            border-left: 1px solid #ECEEF6;
        }

        .orderDetail_btn {
            height: auto;
            margin-top: 10px;
        }

        .order_status {
            height: 60px;

            li {
                box-sizing: border-box;
                height: 26px;
                line-height: 26px;
                padding-left: 10px;
                padding-right: 10px;
                list-style-type: none;
                margin-top: 17px;
                margin-right: 10px;
                cursor: pointer;
                user-select: none;
                border-radius: 2px;

                &:hover {
                    color: #ff4201;
                }
            }

            .active {
                border: 1px solid #ff4201;
                color: #ff4201;
            }
        }

        position: relative;
        box-sizing: border-box;

        .w-570 {
            width: 579px !important;
            display: flex;
            flex-wrap: wrap;

            > div {
                padding: 20px;
                padding-right: 0;
                width: 100%;
                box-sizing: border-box;
            }
        }

        .hide {
            display: none;
        }

        .table_header {
            height: 40px;
            line-height: 40px;
        }

        .content {
            .el-date-editor--daterange.el-input {
                width: 240px;
            }

            .el-form--inline {
                .el-form-item__label {
                    font-size: 12px;
                    width: 60px;
                }
            }

            .el-table .el-table__body-wrapper {
                display: none;
            }

            .table-item {
                .bl {
                    border-left: 1px solid #ECEEF6;
                }

                border: 1px solid #ECEEF6;
                font-size: 14px;
                border-bottom: none;

                .table-item-content .pbl-20 {
                    padding: 0px 0px 20px 20px;
                }

                .table-item-content {
                    .gift {
                        position: relative;
                    }

                    .triangle-topleft {
                        border-top: 60px solid #ff4201;
                        width: 0;
                        height: 0;
                        border-right: 60px solid transparent;
                        position: absolute;
                        top: 0;
                        left: 0;
                    }

                    .init {
                        transform: rotate(-45deg) translate(-4px, 10px);
                        position: absolute;
                        left: 0;
                        top: 0;
                        color: #fff;
                        letter-spacing: -0.34px;
                    }
                }

                .table-item-top {
                    border-bottom: 1px solid #ECEEF6;
                    /*// padding: 10px 0;*/
                    line-height: 40px;
                    padding-right: 65px;
                    position: relative;

                    .separate {
                        position: absolute;
                        top: 0;
                        right: 0;
                    }

                    .table-item-top-checkbox {
                        margin-left: 14px
                    }
                }

                img {
                    width: 80px;
                    height: 80px;
                }
            }

            .table-item-default {
                height: 530px;
                padding-top: 120px;
            }

            .w-100 {
                width: 100px;
                margin: 0 auto;
            }
        }
        .giftPriceClass{
            text-decoration:line-through;
        }
    }
</style>
