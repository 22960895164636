<template>
	<div id="shop">
		<!--商城首页top-->
		<div class="bg-f font-16">
			<div class="width-1280 plr-45">
				<div class="logo">
					<el-row type="flex" justify="space-between">
						<el-col :span="8">
							<router-link :to="basePath + '/shop/shopIndex'">
								<img class="cursor" src="../assets/logo-r.png" />
							</router-link>
						</el-col>
						<el-col class="mt-30">
							<div class="clearfix">
								<el-input class="fl" v-model.trim="goodsName" @keydown.enter.native='goodsSearch' placeholder="请输入关键词搜索"></el-input>
								<el-button class="fl" @click="goodsSearch">搜 索</el-button>
							</div>
						</el-col>
						<el-col class="mt-30" :span="4">
							<!-- <router-link :to=""> -->
								<el-button @click.native="goShoppingCar" class="font-12 border-radius-no">
									<span><i class="iconfont icon-shopping-car color-danger"></i></span>
									<span class="color-danger ml-10">我的购物车</span>
									<span class="sub" v-if="shoppingCarNum > 0 || shoppingCarNum === '99+'">{{shoppingCarNum}}</span>
								</el-button>
								
							<!-- </router-link> -->
						</el-col>
					</el-row>
					
					<div style="text-align:center" v-if="integrationInfo&&isOpen">
						<div class="titleDiv">
							<img src="../assets/attention.png" alt="" style="vertical-align: middle;margin-right:10px">
							{{integrationInfo}}
						</div>
					</div>
				</div>
			</div>
		</div>
		<rightMenu></rightMenu>
		<div>
			<router-view></router-view>
		</div>
	</div>
</template>
<script>
	import RightMenu from "./rightMenu.vue";
	import { axiosApi,basePath } from "../api/api";
	import {mapActions} from 'vuex'
	// import {bus} from "../main.js"
	export default {
		components: {
			RightMenu
		},
		data() {
			return {
				goodsName: "",
				basePath: basePath,
				// shoppingCarNum: 0,
				isloginUser: false,
				totalTime: 60,
				isOpen: sessionStorage.getItem("isOpen")!=1?false:true
			}
		},
		computed: {
			shoppingCarNum() {
				return this.$store.state.homeSearch.shoppingCarNum
			},
			integrationInfo(){
				var info = localStorage.getItem('integrationInfo')
				return info=='null'?null:info
			}, 
		},
		methods: {
			...mapActions({
				'setShoppingCarNum': 'setShoppingCarNum'
			}),
			getCountdown() {
					// 60s 关闭积分提示
					let clock =  window.setInterval(() => {
							this.totalTime --;
							if(this.totalTime < 0){
									this.isOpen = false
									sessionStorage.setItem('isOpen',0)
									// this.resetSetItem('isOpen',0)
									window.clearInterval(clock);
							}
					}, 1000);
			},
			goodsSearch(){//搜索商品
				this.goodsName = this.goodsName.trim();//去除首位空格
				let goodsName = (this.goodsName === null || this.goodsName === "") ? 'goodsName' : this.goodsName;
				this.$router.push(basePath+'/goodsSearch/classify/brand/' + goodsName );
			},
			getShoppingCarNum(){//购物车数量
				if(!this.isloginUser) return false;
				// axiosApi("/anon/order/cart/count", {operatorType:1,buyerId:sessionStorage.traderId}, (res) => {
				// 	this.shoppingCarNum = res.data.data < 100 ? res.data.data : '99+';
				// 	bus.$emit('shoppingCarNum', this.shoppingCarNum)
				// }, 1, 10)
				this.setShoppingCarNum();
			},
			goShoppingCar(){
				if (!this.isloginUser) {
					/* this.$alert('请先登录', '提示信息', {
	                    confirmButtonText: '确定'
					}) */
					this.$store.commit('setloginDialog',{type : true , url : '/shop/shopingCar'});
	                return false;
				}
				this.$router.push(basePath + '/shop/shopingCar')
			}
		},
		// created () {
		//   bus.$on('shoppingCarNum', (data) => {
		//     this.shoppingCarNum = data;
		//   })
		// },
		mounted() {
			this.$nextTick(()=> {
				if(this.isOpen){
					this.getCountdown()
				}
				this.isloginUser = !sessionStorage.getItem('loginName') ? false : true;
				this.getShoppingCarNum();
			})
		}
	}
</script>
<style lang="less">
	#shop {
		.font-19{
			font-size: 19px;
		}
		font-size: 16px;
		.logo {
			height: 108px;
			background: #fff;
			.border-radius-no{
					border-radius: 0;
				}
			.el-col-8{
				width: 199px;
				img {
					width: 190px;
					height: 39px;
					margin-top: 30px;
				};
				.platform {
					display: inline-block;
					color: #FF4201;
					vertical-align: top;
					font-size: 24px;
				};
			};
			.el-col-24{
				width: 600px;
				.el-input{
					width: 520px;
				};
				.el-input__inner{
					border: 2px solid #FF4201;
					border-right: 0;
					height: 36px !important;
				};
				.el-button{
					width: 80px;
					height: 36px;
					color: #FFF;
					font-size: 16px;
					background: #FF4201;
					border: 2px solid #FF4201;
					border-radius: 0;
				};
			};
			.el-col-4{
				width: 190px;
				.el-button{
					width: 190px;
					height: 36px;
				};
				span{
					vertical-align: middle;
				};
				.sub{
					min-width: 12px;
					height: 16px;
					line-height: 16px;
					padding:0 2px;
					display: inline-block;
					background: #FF4201;
					border-radius: 8px;
					color: #fff;
					margin-left: 8px;
				}
			}
		}
		.titleDiv{
			display: inline-block;
			background-color: rgb(253, 246, 236);
			color: #ec720d;
			font-size: 14px;
			padding: 5px 10px;
		}
	}
</style>