<template>
    <div class="goods-item fl" :class="{ 'goods-item-index': isIndex}">
        <!-- , 'goods-item-hotel': isHotel  -->
        <router-link :to="`${basePath}/shop/goodsDetails/${itemData.skuNo}`" target=_blank>
            <img v-if="!itemData.fileUrl" src="../assets/goods.jpg" alt="">
            <img v-else :src="itemData.fileUrl" />
            <div class="seckillList">
                <div v-if="isloginUser" class="color-danger lh-20 font-20 clearfix" :class="isIndex ? 'pt-10' : 'pt-20'">
                    <!-- ||isHotel -->
                    <!-- B2B和酒店管理员 -->
                    <div class="clearfix">
                        <span>
                            <span v-if="goodsSkuInfo.price < 10000">
                                ￥{{itemData.showUnitPrice | fmoney}}/{{itemData.showUnitName }}
                            </span>
                            <span v-else>
                                ￥{{(itemData.showUnitPrice / 10000) | fmoney}}万/{{itemData.showUnitName }}
                            </span>
                        </span>
                        
                        <!-- <span v-if="isIndex && isHotel" class="fr font-12 color-9">已售:{{itemData.sales}}</span> -->
                    </div>
                </div>
                <p v-else class="color-danger font-14 pt-20">查看批发价请登录</p>
                <p class="one mt-10 font-14 dp color-6 text-tow-overflow good_skuName">{{itemData.skuName}}</p>
                <div class="font-14 color-9" v-if="itemData.totalSales!=null">月售：{{itemData.totalSales }}</div>
            </div>
        </router-link>
        <div class="two cursor">
            <div class="all fl pt-10" @click.stop="handCollection()">
                <div :class="{'shake': CollectionActive}"><i class="iconfont icon-collection color-white" :class="{'color-danger': itemData.goodsCollected}"></i></div>
                <div class="font-12 color-white" :class="{'color-danger': itemData.goodsCollected}">收藏</div>
            </div>
            <div class="all fl pt-10" @click.stop="isOrderGoods">
                <div :class="{'shake': carActive}"><i class="iconfont icon-shopping-empty color-white"></i></div>
                <div class="font-12 color-white">购物车</div>
            </div>
        </div>
        <el-dialog
            title="加入购物车"
            :visible.sync="centerDialogVisible"
            width="950px"
            class="goodsItemModelWrap"
            center>
            <div class="goodsItemModel flex">
                <div class="goodsInfoBox">
                    <div class="goodsImage">
                        <img v-if="!itemData.fileUrl" src="../assets/goods.jpg" alt="">
                        <img v-else :src="itemData.fileUrl" />
                    </div>
                </div>
                <div class="infoWrap flex-1">
                    <div class="priceInfo">
                        <p class="text-tow-overflow color-666">{{ itemData.skuName }}</p>
                        <p class="color-999 mt-10">
                            销售价
                            <!-- <span style="color:rgb(222,71,37)">{{ itemData.price }}</span> -->
                            <span style="color:rgb(222,71,37)" v-if="goodsSkuInfo.price < 10000 ">
                                ￥{{goodsSkuInfo.price | fmoney}}
                                <span class="font-12" >/{{ goodsSkuInfo.salesUnitName}}</span>
                            </span>
                            <span style="color:rgb(222,71,37)" v-else-if="goodsSkuInfo.price >= 10000">
                                ￥{{goodsSkuInfo.price/10000 | fmoney}}万
                                <span class="font-12" >/{{ goodsSkuInfo.salesUnitName}}</span>
                            </span>
                        </p>
                    </div>
                    <div class="choose-type clearfix">
                        <label class="fl mr-20">选择</label>
                        <div class="fl" >
                            <div v-if="itemData.skuType !== 2">
                                <el-button  @click="handlerType(2)"  class="mr-20 type_btn" :class="{ pro_type:(salesUnitType == 1 || salesUnitType == 2) }">{{ boxInfo.showTextContext }}</el-button>
                                <el-button  @click="handlerType(3)" class="type_btn" :class="{ pro_type:salesUnitType == 3 }">{{ bottleInfo.showTextContext }}</el-button>
                            </div>
                            <el-button v-else class="type_btn pro_type">{{ mealInfo.showTextContext }}</el-button>
                        </div>
                    </div>
                    <div v-if="goodsSkuInfo.disableSalesDesc" class="mt-10 color-danger cursor font-14" style="margin-left: 62px;">
							{{ goodsSkuInfo.disableSalesDesc }}
					</div>
                    <div class="clearfix choose-num">
                        <label class="fl mr-20">数量</label>
                        <div class="fl clearfix">
                            <div class="fl mr-20">
                                <el-input-number size="small" :disabled="!goodsSkuInfo.enableSales" :min="goodsMinNum" :max='goodsSkuInfo.goodsMaxNum' v-model.number="goodsNum" :step="1" class="fl"  ></el-input-number>
                            </div>
                            <div class="fl">
                                <span class="color-9">{{ goodsSkuInfo.minimunMoq }}{{ goodsSkuInfo.salesUnitName }}起购 | 库存{{ goodsSkuInfo.stockEnableQuantity > 99 ? '99+' : goodsSkuInfo.stockEnableQuantity }}{{ goodsSkuInfo.salesUnitName }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="footer clearfix">
                        <label class="fl mr-20"></label>
                        <el-button :disabled="(!goodsSkuInfo.enableSales||goodsNum <= 0)" class="fl ml-" type="primary" @click="handAddCar">加入购物车</el-button>
                    </div>
                </div>
            </div>    
        </el-dialog>
    </div>
</template>

<script>
	import { axiosApi,basePath } from "../api/api";
	import {mapMutations, mapActions} from 'vuex'
    import imagePath from '~/assets/title.png';
        export default {
            props: ['itemData', 'isIndex', 'isHotel'],
            data(){
                return {
                    carActive: false,
                    CollectionActive: false,
                    isloginUser: !sessionStorage.getItem('loginName') ? false : true,
                    buyerId: sessionStorage.traderId,
                    isAdmin: sessionStorage.isAdmin == 1 ? true : false, // 是否是管理员
                    nonAgreementGoodsIconfont:'',
                    centerDialogVisible:false,
                    isCurrent:true,//默认选择整箱装
                    goodsNum:0,//购买商品数量
                    salesUnitType:null,//销售类型
                    goodsSkuInfo:{},//商品sku详情
                    boxInfo :{},//箱
                    bottleInfo :{},//瓶
                    mealInfo :{}//套餐
                }
            },
            watch:{
                centerDialogVisible(val){
                    if(!val){
                        this.salesUnitType = this.itemData.skuType !== 2 ? (this.itemData.salesUnitType == 3 ? 3 : 2) : null;
                        this.goodsSkuInfo = this.salesUnitType ? ( this.salesUnitType == 2 ? this.boxInfo : this.bottleInfo ) : this.mealInfo;
                        if(val.notCheckedStock){//true 不检查 false 检查
                            this.goodsSkuInfo.goodsMaxNum = 99999;
                        }else{
                            this.goodsSkuInfo.goodsMaxNum = this.goodsSkuInfo.stockEnableQuantity
                        }
                        this.goodsNum = this.goodsSkuInfo.stockEnableQuantity < this.goodsSkuInfo.minimunMoq ? this.goodsSkuInfo.stockEnableQuantity : this.goodsSkuInfo.minimunMoq;
                    }
                },
                itemData:{
                    handler:function(val){
                        this.boxInfo =  val.boxInfo;//箱
                        this.bottleInfo =  val.bottleInfo;//瓶
                        this.mealInfo =  val.mealInfo;//套餐
                        this.salesUnitType = val.skuType !== 2 ? (val.salesUnitType == 3 ? 3 : 2) : null;
                        this.goodsSkuInfo = this.salesUnitType ? ( this.salesUnitType == 2 ? this.boxInfo : this.bottleInfo ) : this.mealInfo;
                        // if(val.priceUnit === 1){
                        //     this.goodsSkuInfo.priceUnitName = val.unitsName;
                        // }
                        if(val.notCheckedStock){//true 不检查 false 检查
                            this.goodsSkuInfo.goodsMaxNum = 99999;
                        }else{
                            this.goodsSkuInfo.goodsMaxNum = this.goodsSkuInfo.stockEnableQuantity
                        }
                        this.goodsNum = this.goodsSkuInfo.stockEnableQuantity < this.goodsSkuInfo.minimunMoq ? this.goodsSkuInfo.stockEnableQuantity : this.goodsSkuInfo.minimunMoq;
                    },
                    deep:true,
                    immediate:true
                }
            },
            computed:{
                goodsMinNum(){
                    let num = this.goodsSkuInfo.stockEnableQuantity < this.goodsSkuInfo.minimunMoq ? this.goodsSkuInfo.stockEnableQuantity : this.goodsSkuInfo.minimunMoq;
                    return num;
                }
            },
            methods: {
                ...mapActions({
                    'setShoppingCarNum': 'setShoppingCarNum'
                }),
                //切换瓶箱
                handlerType(salesUnitType){
                    if(this.salesUnitType && this.salesUnitType != salesUnitType){
                        this.salesUnitType = salesUnitType;
                        this.goodsSkuInfo = salesUnitType == 2 ? this.boxInfo : this.bottleInfo;
                        this.goodsNum = (+this.goodsSkuInfo.stockEnableQuantity < +this.goodsSkuInfo.minimunMoq )? this.goodsSkuInfo.stockEnableQuantity : this.goodsSkuInfo.minimunMoq;
                         if(this.itemData.notCheckedStock){//true 不检查 false 检查
                            this.goodsSkuInfo.goodsMaxNum = 99999;
                        }else{
                            this.goodsSkuInfo.goodsMaxNum = this.goodsSkuInfo.stockEnableQuantity
                        }
                    }
                },
                findTraderInfoBySerllerId() { // 查询商品是否开通同步K3
                    return new Promise((resolve, reject) => {
                        axiosApi('/trader/findTraderInfoBySerllerId/select', {
                            'traderId': sessionStorage.getItem('sellerId')
                        }, (res) => {
                            resolve(res)
                        }, 1, 10, err => {
                            reject(err)
                        })
                    })
                },
                goStore(sellerId){
                    if (!this.isloginUser) {
                        this.$store.commit('setloginDialog',true);
                        return false;
                    }
                    this.$emit('storeClick', sellerId)
                },
                isOrderGoods(fun, event){//检查该商品是否支持购买
                    this.salesUnitType = this.itemData.skuType !== 2 ? (this.itemData.salesUnitType == 3 ? 3 : 2) : null;
                    let api,body;
                    if (!this.isloginUser) {
                        this.$store.commit('setloginDialog',true);
                        return false;
                    }else if(this.buyerId == this.itemData.sellerId) {
                        this.$message("不能购买自己的商品");
                        return
                    }
                    this.centerDialogVisible = true;
                },
                handAddCar(){//加入购物车
                    this.carActive = true;
                    // const goodsNum = this.itemData.isIntegerMultiple ? Math.ceil(this.itemData.minimunMoq/this.itemData.specInfoNum) : Number(this.itemData.minimunMoq)
                    let body = {
                        skuNo: this.itemData.skuNo,//商品skuNo
                        num: this.goodsNum,	//添加购物车数量
                        buyerId: sessionStorage.traderId,//用户id
                        operatorType: 1,//添加者类型：商户
                        selectedSalesUnitType:this.salesUnitType//选择商品类型
                    }
                    axiosApi("/anon/order/cart/insert", body, (res) => {
                        this.carActive = false;
                        this.centerDialogVisible = false;
                        this.$message({
                            message: "加入购物车成功",
                            type: 'info',
                            duration: 1000
                        });
                        //抛物线
                        // let element = document.getElementById("ball");
                        // element.style.left = event.clientX + 'px';
                        // element.style.top = event.clientY + 'px';
                        // element.style.visibility = 'visible';
                        // this.$emit('parabolaFn');
                        let newA = res.data.data
                        newA = Object.assign({},res.data.data);
                        this.setShoppingCarNum(newA.countNum)
                    }, 1, 10,(res)=>{
                        this.carActive = false;
                        this.centerDialogVisible = false;
                        this.$message({
                            message: res.data.message,
                            type: 'info',
                            duration: 1000
                        });
                    })
                },
                handCollection(){//收藏商品
                if (!this.isloginUser) {
                    this.$store.commit('setloginDialog',true);
                            return false;
                }else if(this.buyerId == this.itemData.sellerId) {
                    this.$message({
                        message: "不能收藏自己的商品",
                        type: 'info',
                        duration: 1000
                    });
                    return
                }
                this.CollectionActive = true;
                let body = [{ 
                    skuNo: this.itemData.skuNo,
                    buyerId: sessionStorage.traderId,
                    classifyId: this.itemData.classifyId,
                    brandId: this.itemData.brandId
                }];
                let api = "/trader/collectGoods/update";
                this.CollectApi(api, body);
                },
                CollectApi(api, body){
                axiosApi(api, body, (res) => {
                    this.CollectionActive = false;
                    this.itemData.goodsCollected = !this.itemData.goodsCollected;
                    this.$message({
                    message: this.itemData.goodsCollected ? "收藏成功！" : "已取消收藏！",
                    type: 'success',
                    duration: 1000
                    });
                }, 1, 10,(res)=>{
                    this.CollectionActive = false;
                    this.$message({
                        message: res.data.message,
                        type: 'info',
                        duration: 1000
                    });
                })
            },
            // beforeRouteLeave(to,from,next){
            //     console.log(to)
            // }
        }
    }
</script>

<style lang="less" >
    .goods-item{
        width: 234px;
        height: 353px;
        margin: 5px 5px 0 0;
        position: relative;
        background: #fff;
        img {
            width: 234px;
            height: 234px;
            display: block;
        }
        .seckillList {
            width: 214px;
            height: 80px;
            border-top: 1px solid #DDDDDD;
            margin: 0 auto;
            .one {
                line-height: 16px;
                width: 100%;
                height: 32px;
            }
            .good_skuName{
              word-break: break-all;
            }
        }
        .store-name{
            padding: 10px;
            span:hover{
                color: #ff4201;
            }
        }
        .two {
            height: 0px;
            width: 100%;
            background: rgba(0, 0, 0, .5);
            position: absolute;
            overflow: hidden;
            top: 0;
            left: 0;
            transition: all .3s;
            .all {
                width: 50%;
                height: 50px;
                text-align: center;
            }
        }
    }
    .goods-item-index{
        width: 210px;
        height: 290px;
        background: #F6F6F6;
        margin: 0 25px 20px 0;
        img{
            width: 208px;
            height: 209px;
            border: 1px solid #f6f6f6;
            border-bottom: 0;
        }
        .seckillList{
            width: 190px;
            border-top: 0;
        }
        .store-name{
            padding: 5px 10px;
        }
    }
    .goods-item-hotel{
        height: 372px;
        .seckillList {
            height: 90px;
        }
    }
    .goods-item-hotel.goods-item-index{
        height: 342px;
    }
    .goods-item:nth-child(5n) {
        margin-right: 0 !important;
    }
    .goods-item:hover{
        .two{
            height: 60px;
            span span:nth-child(1){
                width: 16px;
                display: inline-block;
            }
            .all:hover i,.all:hover div{
                color: #ff4201;
            }
        }
    }
    /*图标晃动*/
    @-webkit-keyframes shake {
        0% {
            -webkit-transform: translate(2px, 2px);
        }
        25% {
            -webkit-transform: translate(-2px, -2px);
        }
        50% {
            -webkit-transform: translate(0px, 0px);
        }
        75% {
            -webkit-transform: translate(2px, -2px);
        }
        100% {
            -webkit-transform: translate(-2px, 2px);
        }
    }
    @keyframes shake {
        0% {
            transform: translate(2px, 2px);
        }
        25% {
            transform: translate(-2px, -2px);
        }
        50% {
            transform: translate(0px, 0px);
        }
        75% {
            transform: translate(2px, -2px);
        }
        100% {
            transform: translate(-2px, 2px);
        }
    }
    .shake{
        -webkit-animation: shake 0.2s linear;
        animation: shake 0.2s linear;
    }
    /*加入购物车弹框*/
    .goodsItemModelWrap{
        min-height: auto!important;
        .el-dialog__body{
            padding-bottom: 0;
           /*  padding-left: 20px;
            padding-right: 20px; */
            box-sizing: border-box;
            .goodsItemModel{
                padding-right: 20px;
                .goodsInfoBox{
                    padding-left: 20px;
                    padding-right: 20px;
                    padding-bottom: 30px;
                    box-sizing: border-box;
                    .goodsImage img{
                        width: 300px;
                        height: 300px;
                        border-radius: 10px;
                        border:1px solid #ccc;
                        margin-right: 20px
                    }
                }
                 .infoWrap{
                     position: relative;
                     .priceInfo{
                        padding-bottom: 20px;
                        border-bottom:1px solid #ccc;
                    }
                    .choose-type,.choose-num{
                        line-height: 32px;
                        padding: 20px 0;
                        box-sizing: border-box;
                        .pro_type{
                            color:#FF4217;
                            border-color: #FF4217; 
                        }
                    }
                    .footer{
                        position: absolute;
                        bottom: 30px;
                        box-sizing: border-box;
                        /* padding-right: 40px; */
                        p{
                            &:last-child{
                                background-color: rgb(253,152,56);
                                color: #fff;
                                cursor: pointer;
                                font-size: 18px;
                                text-align: center;
                                line-height: 38px;
                            };
                            &:first-child{
                                padding: 10px;
                                text-align: right
                            }
                        }
                    }
                }
            }
        }
    }
</style>