<template>
<div id="advertisementList">
   <div>
      <div class="topDiv">
        <el-tabs v-model="activeTab" @tab-click="clickTab">
          <el-tab-pane v-for="(tab,index) in dataItemTabs" :key="index" :label="tab.name" :name="tab.type"></el-tab-pane>
        </el-tabs>
        <el-button class="mr-0" icon="el-icon-plus" @click="openAdd">新增广告</el-button>
      </div>
      
      <el-table :data="list">
          <el-table-column label="广告名称" align="center" min-width="200px">
            <template slot-scope="scope">{{scope.row.advertisementName}}</template>
          </el-table-column>
          <el-table-column label="推送时间" align="center" min-width="260px;">
            <template slot-scope="{row}">
              <span>{{row.gmtAdvertisementStart | time}} 至 <span v-if="row.gmtAdvertisementEnd">{{row.gmtAdvertisementEnd | time}}</span> <span v-else>永久有效</span></span>
            </template>
          </el-table-column>
          <el-table-column label="轮播图" align="center" min-width="100px;">
            <template slot-scope="scope">
              <el-button type="text" @click="lookImg(scope.row.imgUrl)">查看</el-button>
            </template>
          </el-table-column>
          <el-table-column label="状态" prop="advertisementState" align="center" min-width="90px;"></el-table-column>
          <el-table-column label="广告位置" align="center" min-width="90px;">
            <template slot-scope="{row}">
              {{row.location?'首页专题广告':'首页核心广告'}}
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" min-width="150px">
            <template slot-scope="{row,$index}">
              <el-link v-if="($index!=0&&pageIndex==1)" :underline="false" @click="handleMoveToTop(row)">置顶</el-link>
              <el-link type="primary" :underline="false" @click="openEdit(row)">编辑</el-link>
              <el-link type="danger" :underline="false" @click="confirmDelete(row)">删除</el-link>
            </template>
          </el-table-column>
        </el-table>

        <el-pagination
          class="tar mr-30"
          v-if="total>0"
          :current-page="pageIndex"
          :page-size="pageSize"
          :total="total"
          layout="prev , pager, next, jumper"
          @current-change="handleCurrentChange"
        >
          <!-- 分页组件 -->
        </el-pagination>
    </div>
    <!--新增广告-->
      <el-dialog class="minMop" title="新增广告" :visible.sync="dialogAdd" width="770px" @close="closeDialog">
        <el-form :model="form" label-width="130px">
            <el-form-item label="广告标题:" required>
              <el-input
                v-model.trim="form.advertisementName"
                auto-complete="off"
                placeholder="请输入广告标题"
              ></el-input>
            </el-form-item>
              <el-form-item label="推送时间:" required>
              <ysDaterangePicker :format="'yyyy-MM-dd HH:mm:ss'" :startDisabledDate='new Date()' v-model="dateArr" type="datetime"/>
            </el-form-item>
            <el-form-item label="跳转规则:">
              <el-radio-group v-model="form.advertisementType" @change="changeType">
                <el-radio label="general">普通轮播图</el-radio>
                <el-radio label="goods">跳转商品详情页</el-radio>
                <el-radio label="goodsSearch">跳转商品列表页</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item :required="form.advertisementType!=='general'?true:false" :label="form.advertisementType==='goods'?'广告商品编码:':'广告商品关键字:'" v-if="form.advertisementType!='general'">
              <el-input
                v-model.trim="form.advertisementKeywords"
                auto-complete="off"
                @blur="getSkuInfo"
                :placeholder="form.advertisementType==='goods'?'请输入编码':'请输入关键字'"
              ></el-input>
              <div>{{skuName}}</div>
            </el-form-item>
            <el-form-item label="广告图片:">
              <el-upload
                class="avatar-uploader"
                action="/sunrise-gateway/oss/ossUpload"
                v-loading="loading"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload"
                :on-error="errFun"
              >
                <img v-if="form.imgUrl" :src="form.imgUrl" class="avatar" />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
          </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="danger" @click="saveAd()">确定</el-button>
          <el-button class="ml-20" @click="closeDialog()">取消</el-button>
        </div>
    </el-dialog>
    <!-- 查看图片 -->
    <el-dialog :visible.sync="dialogVisible" size="tiny">
        <img width="100%" :src="dialogImageUrl" alt />
    </el-dialog>


</div>
</template>

<script>
import { getAdvertisement, deleteSelectInfo, setTop, advertisementInsert, getSelectInfo, updateSelectInfo, sarechGoods } from "~/api/axiosInfo";

export default {
  data(){
    return {
      // 云商子tab
      dataItemTabs:[
        {type:'0',name:'首页核心广告'},
        {type:'1',name:'首页专题广告'},
      ],
      activeTab:"0",
      form:{
        advertisementName: null,
        imgUrl: null,
        gmtAdvertisementStart: null,
        gmtAdvertisementEnd: null,
        location: 0, // 初始默认轮播图
        advertisementType: 'general', // 初始默认普通轮播图
        advertisementKeywords: null,
      },
      list:[],

      skuName:'',
      dateArr: [],
      dialogAdd: false,
      loading: false,
      dialogImageUrl: null,
      dialogVisible: false,
      pageIndex:1,
      pageSize:10,
      total:0
    }
  },
  methods: {
    openAdd(){
      this.dialogAdd = true
    },
    closeDialog(){
      this.form={
        advertisementName: null,
        imgUrl: null,
        gmtAdvertisementStart: null,
        gmtAdvertisementEnd: null,
        location: 0, // 初始默认轮播图
        advertisementType: 'general', // 初始默认普通轮播图
        advertisementKeywords: null,
      }
      this.skuName = ''
      this.dateArr = []
      this.dialogAdd = false
    },
    openEdit(row){
      this.form = row
      if (row.advertisementType==='goods') {
        var info = JSON.parse(row.advertisementKeywords)
        this.form.advertisementKeywords= info.goodsNo
        this.skuName = info.skuName
      }
      this.dateArr = [row.gmtAdvertisementStart,row.gmtAdvertisementEnd]
      this.dialogAdd = true
    },
    changeType(){
      this.form.advertisementKeywords = null
      this.skuName = null
    },
    handleMoveToTop(row) {
      setTop({id:row.id,traderId:row.traderId}).then(res=>{
        this.getList()
      }).catch(err=>{

      })
    },
    confirmDelete(row) {
      this.$confirm("此操作将删除广告, 是否继续?", "提示信息", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.deleteAd(row.id);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除"
          });
        });
    },

    deleteAd(id) {
      deleteSelectInfo({id:id}).then(res=>{
        this.$message({
          type: "success",
          message: "删除成功",
          duration: 1000
        });
        this.pageIndex = 1
        this.getList()
      }).catch(err=>{

      })
    },
    getInfo() {
      getSelectInfo( { id: this.adId }).then(res=>{
        this.form = res
        if (res.advertisementType==='goods') {
          var info = JSON.parse(res.advertisementKeywords)
          this.form.advertisementKeywords= info.goodsNo
          this.skuName = info.skuName
        }
        this.dateArr = [res.gmtAdvertisementStart,res.gmtAdvertisementEnd]
      }).catch(err=>{

      })
    },

    getSkuInfo(){
      if(this.form.advertisementType==='goods'){
        sarechGoods({goodsNo:this.form.advertisementKeywords,sellerId:sessionStorage.traderId}).then(res=>{
          if(res){
            this.skuName = res.skuName
          }else{
            this.skuName = null
          }
        }).catch(err=>{

        })
      }else{
        return
      }
    },


    handleAdd() {
      advertisementInsert(this.form).then(res=>{
        this.$message({
          type: "info",
          message: "添加成功"
        });
        this.getList()
        this.closeDialog()
      }).catch(err=>{

      })
    },
    handleEdit() {
      updateSelectInfo(this.form).then(res=>{
        this.$message({
          type: "info",
          message: "编辑成功"
        });
        this.getList()
        this.closeDialog()
      }).catch(err=>{

      })
    },

    saveAd() {
      if(this.dateArr){
       this.form.gmtAdvertisementStart = this.dateArr[0]!=null?new Date(this.dateArr[0]).getTime():null
       this.form.gmtAdvertisementEnd = this.dateArr[1]!=null?new Date(this.dateArr[1]).getTime():null
      }
      if (
        null == this.form.advertisementName ||
        0 == this.form.advertisementName.trim().length
      ) {
        this.$message.error("请输入标题");
        return;
      }
      if (15 < this.form.advertisementName.trim().length) {
        this.$message.error("标题在15字以内");
        return;
      }
      if (this.form.gmtAdvertisementStart == null) {
        this.$message.error("请选择推送开始时间");
        return;
      }
      if(this.form.advertisementType!=='general'){
        if(this.form.advertisementKeywords == null||this.form.advertisementKeywords == ''){
          this.form.advertisementType =='goods'?
          this.$message.error("请输入广告商品编码"):
          this.$message.error("请输入广告商品关键字");
          return;
        }
      }
      if (null == this.form.imgUrl || 0 == this.form.imgUrl.length) {
        this.$message.error("请上传图片");
        return;
      }
      if (null == this.form.imgUrl || 0 == this.form.imgUrl.length) {
        this.$message.error("请上传图片");
        return;
      }
      
      this.form.traderId = sessionStorage.traderId
      if (null != this.form.id) {
        this.handleEdit();
      } else {
        this.handleAdd();
      }
    },

    //上传图片
    beforeAvatarUpload(file) {
      this.loading = true;
      const isImage =
        file.type.indexOf("jpg") != -1 ||
        file.type.indexOf("jpeg") != -1 ||
        file.type.indexOf("png") != -1 ||
        file.type.indexOf("bmp") != -1;
      const isLt4M = file.size / 1024 / 1024 < 4;
      if (!isImage) {
        this.loading = false;
        this.$message.error("上传图片只能是jpg、jpeg、png、bmp格式");
      }
      if (!isLt4M) {
        this.loading = false;
        this.$message.error("上传图片大小不能超过 4MB");
      }
      return isImage && isLt4M;
    },
    handleAvatarSuccess(res) {
      this.loading = false;
      this.form.imgUrl = res.data[0];
    },
    errFun(err) {
      //				console.log(err);
    },

    clickTab({name,label},event){
      this.form.location = name
      this.pageIndex = 1
      this.getList()
    },
    getList(){
      var form = {
        traderId: sessionStorage.traderId,
        pageIndex:this.pageIndex,
        pageSize:this.pageSize,
        location:this.form.location
      }
      getAdvertisement(form).then(res=>{
        if(res){
          this.list = res.elements
          this.total = res.totalCount
        }
      }).catch(err=>{
        this.list = []
        this.total = 0
      })
    },
    handleCurrentChange(val){
      this.pageIndex = val
      this.getList()
    },
    //预览
    lookImg(url) {
      this.dialogImageUrl = url;
      this.dialogVisible = true;
    },
  },
  mounted(){
    this.getList()
  },
}
</script>

<style lang="less">
#advertisementList {
  .topDiv {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .avatar {
    width: 500px;
    height: 300px;
    line-height: 300px;
  }
  .avatar-uploader-icon {
    border: 1px solid #ddd;
    width: 500px;
    height: 300px;
    line-height: 300px;
    font-size: 30px;
  }
  .el-loading-spinner {
    left: -18%;
  }
}
  
</style>