<template>
    <div id="visitSet" >
        <div class="title-top bg-f mb-5">
            拜访动作配置
            <el-button class="fr mr-30 mt-14" v-if='showButton("ADD_CLASSIFY",200092)' @click="addAction()">新建类别</el-button>
        </div>
        <div class="bg-f pb-50 pt-30 pl-30 pr-30">
            <el-table :data="tableData"  class='table-default' highlight-current-row stripe>
                <el-table-column label="序号" prop="actionClassifyOrder" width="120">   
                </el-table-column>
                <el-table-column label="类别名称" prop="actionClassifyName" width="250">   
                </el-table-column>
                 <el-table-column label="状态" width="120">
                    <template slot-scope="scope">
                        <div v-if="scope.row.actionStatus==0">禁用</div>
                        <div v-if="scope.row.actionStatus==1">正常</div> 
                    </template>
                </el-table-column>
                <el-table-column label="动作数量" prop="count" width="150">   
                </el-table-column>
                <el-table-column label="操作" width="">  
                    <template slot-scope="scope">
                        <el-button @click="lookDetail(scope.$index,scope.row)" type="text" size="small">查看详情</el-button>
                        <el-button v-if='scope.row.actionStatus!=0&& showButton("DISABLE_CLASSIFY",200093)' @click="forbidden(scope.$index)" type="text" size="small">禁用</el-button>
                        <el-button v-if='scope.row.actionStatus==0 && showButton("DISABLE_CLASSIFY",200093)' class="disabled" @click="forbidden(scope.$index)" type="text" size="small">启用</el-button>
                        <el-button v-if='showButton("UPDATE_CLASSIFY",200094)'  class="" @click="addAction(scope.row)" type="text" size="small">编辑</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <!-- 新增类别 -->
        <el-dialog :title="name + '类别'" :visible.sync="dialogVisible" size=""  @close="closeReset" :class="{small:looksmall}">
            <el-form :model="addData" label-width="130px" ref="actionForm" >
                <el-form-item label="类别名称" prop="actionClassifyName" required>
                    <el-input placeholder="请输入名称" v-model.trim="addData.actionClassifyName" :disabled="disabled" :maxlength="8"></el-input>
                </el-form-item>
                <el-form-item label="类别序号" prop="actionClassifyOrder" required>
                    <el-input v-if="disabled" placeholder="请输入序号" v-model.trim="addData.actionClassifyOrder" disabled="disabled"></el-input >
                    <sy-input v-if="!disabled" type="int" size="mini" :min="1" :max="20" v-model="addData.actionClassifyOrder" :placeholders="placeholder"></sy-input>
                </el-form-item>
            </el-form>  
            <span v-if="!disabled" slot="footer" class="dialog-footer">
                
                <el-button type="danger" @click="submitAdd()" :loading="submitLoading">提交</el-button>
            </span>
        </el-dialog>
    </div>
</template>


<script>
    import {axiosApi ,basePath} from '../../api/api'
    import Confirm from '../../utils/formconfirm'

    export default {
        data() {
            return {
            	placeholder:'请输入序号',
                max:20,
                maxlength:2,
                tableData: [],
                dialogVisible:false,
                dialogChange:false,
                value1:'',
                value2:'',
                addData:{//新增手动动作数据
                	id:'',
                	actionStatus:"",
                    actionClassifyName:'',
                    actionClassifyOrder:'',
                },
                nowAction:{
                    nowActionName:'',
                    nowActionOrder:null
                },
                nowIndex:'',
                nowActionStatus:'',
                disabled:false,
                nowContentType:'',
                isEdit:false,//编辑的提交
                newAction:false,
                name:'新建',//默认为新建
                orderok:false ,
                orderOpenFirst:0,
                myactionSize:'large',
                looksmall:false,
                submitLoading: true,
                countEditFun: 0,
                momentSpecialTypeLength: 0 //点编辑第一时刻有几个类型4和类型5的
            }
        },
        watch:{
            nowAction:{//打开编辑并没有编辑文本框数字
                handler(){
                    this.orderOpenFirst++
                },
                deep:true
            }
        },
        computed:{
            
        },
        methods: {
            queryVisitAction(){
                axiosApi('/sales/visitActionClassify/web/selectList',{
                	sellerId:sessionStorage.traderId
                },res => {
                    this.tableData = res.data.data
                })
            },
             //修改排序和名称
            addAction(item){//新建动作
            	this.submitLoading = false;
                this.disabled = false;
                this.dialogVisible = true;
                this.name = item ? '编辑' : '新建'
                this.isEdit = item ? true : false
                this.addData.actionClassifyName = item ? item.actionClassifyName : ''
                this.addData.actionClassifyOrder = item ? item.actionClassifyOrder : ''
                this.addData.id = item ? item.id : ''
                this.addData.actionStatus = item ? item.actionStatus : ''
            },
            closeReset(){//新建动作框关闭时候操作
                this.looksmall = false;
                this.$refs.actionForm.resetFields()
                this.startSubmit = false
            },
            submitAdd(){ //新建动作提交&&编辑内容提交
                let arr = new Array()
                let newconfirm = new Confirm()
                let confirmdata = this.addData
                newconfirm.use([confirmdata.actionClassifyOrder], '请输入类别序号')
                newconfirm.use([confirmdata.actionClassifyName], '请输入类别名称')
                this.submitLoading = false;
                if(newconfirm.status){
                   
                    // 数据格式有问题，所以必须这里点了提交验证通过就关闭弹窗
                    if(this.isEdit){ //编辑
                    	  axiosApi('/sales/visitActionClassify/web/update',{
                        	id:this.addData.id,
                        	actionStatus:this.addData.actionStatus,
                        	actionClassifyName:this.addData.actionClassifyName,
                        	actionClassifyOrder:this.addData.actionClassifyOrder
                        },res=>{
                        	   this.dialogVisible = false;//新建动作
                            this.queryVisitAction()
                              this.$message({
						type: 'success',
						message: '保存成功',
						duration: 3000
					});
                        },1,100,res=>{
                        	this.$message({
								type: 'info',
								message: res.data.message,
								duration: 3000
							});
                        })
                    }else{ //新增
                    	  axiosApi('/sales/visitActionClassify/web/insert',{
                        	sellerId:sessionStorage.traderId,
                        	actionClassifyName:this.addData.actionClassifyName,
                        	actionClassifyOrder:this.addData.actionClassifyOrder
                        },res=>{
                            this.dialogVisible = false;
                            this.queryVisitAction()
                            this.$message({
						type: 'success',
						message: '保存成功',
						duration: 3000
					});
                        },1,100,res=>{
                        	this.$message({
								type: 'info',
								message: res.data.message,
								duration: 3000
							});
                        })
                    }
                      
                }
            },
            forbidden(index){ 
                const body={
                    id:this.tableData[index].id,
                    actionStatus: this.tableData[index].actionStatus == 1 ? 0 : 1
                }
                axiosApi('/sales/visitActionClassify/web/updateStatus',body,res=>{
                   this.queryVisitAction()
                },1,10,res=>{
                	this.$message({
								type: 'info',
								message: res.data.message,
								duration: 3000
							});
                })
                   
            },
            lookDetail(index, row){
               this.$router.push({
               	path:basePath + '/visitaction',
               	query:{
               		id:row.id,
               		name:row.actionClassifyName
               	}
               })
            },
        },
        mounted() {
            this.$nextTick(() => {
                this.queryVisitAction()
            })
        }
        
    }
</script>


<style lang="less">
    #visitSet{
        .el-dialog__wrapper{
            z-index: 100!important;
            .el-dialog.el-dialog--{
                .el-dialog__body {
                    width: 840px;
                }
            }
        }
        .h1{
            height: 1px;
            background: #dfdfdf;
            width: 100%;
        }
        .disabled{
            color: gray;
        }
        .bd{
            padding-bottom: 10px;
        }
        .inputLength{
            width: 75px;
        }
        .selectWrap{
            display: inline-block;
            .el-input{
                width: 75px;
            }
        }
        .addAction{
            width: 470px;
        }
        .el-input {
            width: 115px;
            height: 32px;
        }
        .customReg:before{
            content: '*';
            color: #ff4949;
            margin-right: 0px;
        }
        .hide{
            display: none;
        }
        .errorRegWrap{
            position: relative;
        }
        .errorReg1{
            color: #ff4949;
            font-size: 12px;
            line-height: 1;
            padding-top: 4px;
            position: absolute;
            top: 100%;
            left: 0;
            margin-left: 50px;
        }
        .errorReg2{
            color: #ff4949;
            font-size: 12px;
            line-height: 1;
            padding-top: 4px;
            position: absolute;
            top: 100%;
            left: 0;
            margin-left: 80px;
        }

        .el-input__icon+.el-input__inner {
            font-size: 12px;
        }
        .delete {
            display: inline-block;
            vertical-align: middle;
            position: relative;
            margin-left: 10px;
            width: 16px;
            height: 16px;
            border-radius: 8px;
            background: #ff4201;
            &>div {
                position: absolute;
                height: 2px;
                width: 12px;
                background: #fff;
                left: 2px;
                top: 7px;
            }
        }
        .cell .el-button {
            width: auto !important;
        }
        .new-options-item {
            border: 1px solid #ddd;
            margin: 10px 30px;
            padding-top: 10px;
            .option-child-item {
                margin-bottom: 5px;
            }
        }
    }
</style>