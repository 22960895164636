<template>
    <div id="steward" class="pb-50">
        <div class="title-top bg-f">服务员管理</div>
        <div class="bg-f mt-5 pb-50">
            <div class="content bg-f pl-30 pr-30 pb-50">
                <!-- 搜索区域 -->
                <div class="clearfix search">
                    <el-form :inline='true' class="pt-30 fl" :model="Form" ref="searchForm" label-width="70px">
                        <el-form-item label='服务员姓名'>
                            <el-input v-model.trim="Form.name" :maxlength="30" placeholder="请输入服务员姓名"></el-input>
                        </el-form-item>
                        <el-form-item label='手机号'>
                            <el-input v-model.trim="Form.mobile" :maxlength="11" placeholder="请输入手机号"></el-input>
                        </el-form-item>
                    </el-form>
                    <el-button class="fl ml-30 mt-30" type='info' @click="getGroupList()">查询</el-button>
                </div>
                <div class="clearfix mr-20 pr-10">
                    <span class="fr color-blue ml-10 cursor mb-20" @click='addWaiter'>添加服务员</span>
                </div>
                <!-- 列表区域 -->
                <div class="pb-30">
                    <el-table :data="GroupList" class='table-default'>
                        <el-table-column label="手机号" prop="mobile"></el-table-column>
                        <el-table-column label="姓名" prop="name"></el-table-column>
                        <el-table-column label='状态' width="">
                            <template slot-scope="scope">
                                <span>{{scope.row.status==1?'正常':'禁用'}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label='操作' width="">
                            <template slot-scope="scope">
                                <el-button v-if="scope.row.status==1" type='text' @click="disable(scope,0)">禁用
                                </el-button>
                                <el-button v-else type='text' @click="disable(scope,1)">启用</el-button>
                                <el-button type='text' @click="deleteItem(scope)">删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-pagination class="tar" v-if="GroupList.length>0" :current-page="currentPage" :page-size='10'
                                   :total='total' layout="prev , pager, next, jumper"
                                   @current-change="handleCurrentChange">
                        <!-- 分页组件 -->
                    </el-pagination>
                </div>
            </div>
        </div>
        <!--添加服务员弹窗-->
        <el-dialog class="minMop" title="添加服务员" :visible.sync='dialogShow'>
            <el-form class="pr-30" :model="form" ref="addWaiter" :rules='rules'>
                <el-form-item label="服务员姓名" prop='name' required>
                    <el-input placeholder='请输入服务员姓名' v-model.trim='form.name'></el-input>
                </el-form-item>
                <el-form-item label="手机号" prop='mobile' required>
                    <el-input placeholder='请输入服务员手机号' v-model.trim='form.mobile'></el-input>
                </el-form-item>
            </el-form>
            <div slot='footer' class="dialog-footer">
                <el-button type='danger' @click="save">确 定</el-button>
                <el-button @click="dialogShow = false">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import axios from 'axios';
    import {axiosApi, basePath, baseURI} from "../../api/api";

    export default {
        data() {
            return {
                /*规则*/
                rules: {
                    name: [{
                        required: true,
                        message: '请输入服务员姓名'
                    }],
                    mobile: [{
                        required: true,
                        message: '请输入服务员手机号'
                    }],
                },
                currentPage: 1,
                total: 0,
                GroupList: [],
                Form: {
                    name: null, //卖家名称
                    mobile: null, //账号
                },
                dialogShow: false, //添加服务员弹框
                form: { //增加服务员弹框数据
                    name: '',
                    mobile: ''
                }
            }
        },
        watch: {
            dialogShow(val) {
                if (!val) {
                    this.form.name = null;
                    this.form.mobile = null;
                    this.$refs.addWaiter.resetFields()
                }
            }
        },
        //进入加载
        mounted() {
            this.$nextTick(function () {
                this.getGroupList()
            })
        },
        methods: {
            getGroupList() {
                axiosApi('/catering/platform/waiter/selectPage', {
                    name: this.Form.name ? this.Form.name : null,
                    mobile: this.Form.mobile ? this.Form.mobile : null,
                }, (res) => {
                    this.GroupList = res.data.data.elements ? res.data.data.elements : [];
                    this.total = res.data.data.totalCount
                }, this.currentPage, 10, (res) => {

                })
            },
            handleCurrentChange(val) { //分页
                this.currentPage = val
                this.getGroupList()
            },
            //添加服务员弹框
            addWaiter() {
                this.dialogShow = true
            },

            save() {
                this.$refs.addWaiter.validate((v) => {
                    if (v) {
                        let body = {
                            name: this.form.name,
                            mobile: this.form.mobile,
                        }
                        this.insert(body, '/catering/platform/waiter/insert') // 添加
                            .then(res => {
                                this.$message({
                                    type: 'success',
                                    duration: 1000,
                                    message: "添加成功!",
                                });
                                this.dialogShow = false;
                                this.getGroupList(true);
                            })
                            .catch(res => {
                                this.$message.error(res.data.message)
                            });

                    }
                })
            },

            insert(body, url) {// 插入接口
                this.btnLoading = true
                return new Promise((resolve, reject) => {
                    axiosApi(url, body, res => {
                        resolve(res)
                        this.btnLoading = false
                    }, 1, 10, res => {
                        reject(res)
                        this.btnLoading = false
                    })
                })
            },

            deleteItem(scope) {
                this.$confirm("将删除服务员, 是否继续?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                }).then(() => {
                    axiosApi('/catering/platform/waiter/delete', {
                        id: scope.row.id,
                    }, (res) => {
                        this.$message({
                            type: 'success',
                            duration: 1000,
                            message: "删除成功!",
                        });
                        if (this.GroupList.length == 1 & this.currentPage != 1) {
                            this.currentPage = this.currentPage - 1;
                        }
                        this.getGroupList(true);
                    }, this.currentPage, 10, (res) => {
                        this.$message.error(res.data.message)
                    })
                }).catch(() => {
                    this.$message({
                        message: "已取消删除",
                    });
                });
            },
            disable(scope, status) {
                var type = status == 0 ? "禁用" : "启用";

                this.$confirm("将" + type + "服务员, 是否继续?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                }).then(() => {
                    axiosApi('/catering/platform/waiter/update', {
                        id: scope.row.id,
                        status: status,
                        mobile: scope.row.mobile,
                    }, (res) => {
                        this.$message({
                            type: 'success',
                            duration: 1000,
                            message: type + "成功!",
                        });
                        this.getGroupList(true);
                    }, this.currentPage, 10, (res) => {
                        this.$message.error(res.data.message)
                    })
                }).catch(() => {
                    this.$message({
                        message: "已取消" + type,
                    });
                });
            }


        },
    }
</script>

<style lang="less">
    #steward {
        .search .el-form-item__label {
            width: 90px;
        }

        .el-dialog {
            height: 300px !important;
            min-height: 300px !important;
        }

        .el-form-item__error {
            margin-left: 185px !important;
        }
    }
</style>