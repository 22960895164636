<template>
  <!--久集酒店订单-->
  <div id="supercHotelOrder">
    <div class="title-tabs2 bg-f mb-5">
      <ul class="clearfix">
        <div v-for="(tab,index) in dataTabs" class="item" :key="index" @click="handleTabs(index)">
          <li :class="tab==clickedTabName?'active':''">
            {{tab}}
            <div :class="tab==clickedTabName?'under-line':''"></div>
          </li>
          <div class="vertical-line"></div>
        </div>
      </ul>
    </div>

    <div class="content bg-f pl-30 pr-30 pb-40" v-if="clickedTabName === '销售订单'">
      <div style="position: relative;">
        <el-tabs v-model="type" @tab-click="handleTabs2">
          <el-tab-pane
            :label="item"
            :name="index + ''"
            v-for="(item, index) in orderTabs"
            :key="index"
          ></el-tab-pane>
        </el-tabs>
        <!--未付款标记-->
        <el-badge class="notPayCountmark" :max="99" :value="badge.notPayCount" :hidden="badge.notPayCount==0"/>
        <!--待发货标记-->
        <el-badge class="notDeliveryCount" :max="99" :value="badge.notDeliveryCount" :hidden="badge.notDeliveryCount==0"/>
        <!--待收货标记-->
        <el-badge class="orderDeliveriedCount" :max="99" :value="badge.orderDeliveriedCount" :hidden="badge.orderDeliveriedCount==0" />
      </div>

      <div class="clearfix search">
        <el-form :inline="true" class="pt-30 fl" :model="form" ref="searchForm">
          <el-form-item
            label="订单编号"
            prop="orderSn"
            :rules="[{pattern:/^\d{16,20}$/,message:'请输入正确订单编号',trigger:'blur'}]"
          >
            <el-input v-model.trim="form.orderSn" placeholder="请输入订单编号" :maxlength="20"></el-input>
          </el-form-item>
          <el-form-item label="订单商品" class="ml-20">
            <el-input v-model.trim="form.skuName" :maxlength="30" placeholder="请输入订单商品"></el-input>
          </el-form-item>
          <el-form-item label="销售员">
            <el-select v-model="form.supercStoreId" placeholder="请选择销售员" clearable>
              <el-option v-for="g in superCList" :label="g.storeName" :value="g.id" :key="g.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="支付方式" class="ml-20">
            <el-select v-model="form.payMethod" placeholder="请选择支付方式" clearable>
              <el-option label="在线支付" value="3"></el-option>
              <el-option label="余额支付" value="4"></el-option>
              <el-option label="账期支付" value="6"></el-option>
              <el-option label="授信支付" value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="下单时间">
            <ys-daterange-picker v-model="form.orderDateRange" :endDisabledDate="new Date()" />
          </el-form-item>
        </el-form>
        <el-button class="fl ml-30 mt-30" type="info" @click="handleCheck">查询</el-button>
      </div>
      <div class="tar clearfix">
        <el-button type="text" @click="exportExcel">批量导出</el-button>
        <el-button style="width: 100px;" type="text" @click="checkTable">查看已生成报表</el-button>
        <!--				<el-button type='text' class="fr" @click="exportExcel">批量导出</el-button>-->
      </div>
      <div class="order-list" v-loading="loading">
        <el-checkbox v-model="checkAll" @change="handleCheckAllChange" class="checkedAll"></el-checkbox>
        <el-table>
          <el-table-column label min-width="50"></el-table-column>
          <el-table-column label="商品" min-width="120" align="center"></el-table-column>
          <el-table-column label="单价" min-width="85"></el-table-column>
          <el-table-column label="数量" min-width="85"></el-table-column>
          <el-table-column label="销售员" min-width="85"></el-table-column>
          <el-table-column label="积分" min-width="85"></el-table-column>
          <el-table-column label="客户" min-width="85"></el-table-column>
          <el-table-column label="订单状态" min-width="85"></el-table-column>
          <el-table-column label="支付方式" min-width="85"></el-table-column>
          <el-table-column label="订单金额" min-width="85"></el-table-column>
          <el-table-column label="操作" align="center" min-width="85"></el-table-column>
        </el-table>
        <div class="table-item-default tac" v-if="ordersListResVo.length<=0">
          <img src="../../../assets/empty.png" alt />
          <div class="color-danger mt-30">
            <router-link :to="{path:'/customer/shop/shopIndex'}">
              <span class="color-danger">啥都没有，去首页看看 ></span>
            </router-link>
          </div>
        </div>
        <div v-else>
          <el-checkbox-group v-model="checkOrder" @change="handleCheckChange">
            <div class="table-item mt-10" v-for="(order, index) in ordersListResVo" :key="index">
              <div class="clearfix table-item-top lh-20 bbd bg-e" v-if="order.adjustingGoods == 1">
                <el-badge value="调货" class="fl ml-70 my-badge"></el-badge>
                <span class="fl ml-10">{{order.headName}}</span>
              </div>
              <div class="clearfix table-item-top lh-20 bbd bg-e">
                <el-checkbox class="fl" :label="order"></el-checkbox>
                <div
                  class="fl"
                  @mouseover="showAllsn = true; showSnIndex = index"
                  @mouseleave="showAllsn = false; showSnIndex = null"
                >
                  c端订单:{{order.orderSn}}
                  <div class="fr" v-if="order.parentOrSon === 3">
                    <i v-show="!showAllsn || showSnIndex !== index" class="el-icon-caret-bottom"></i>
                    <i v-show="showAllsn && showSnIndex === index" class="el-icon-caret-top"></i>
                  </div>
                  <div
                    v-show="order.parentOrSon === 3 && showAllsn && showSnIndex === index"
                    class="all_sn_ct"
                  >
                    <div>
                      主订单:{{order.parentOrderSn}}
                      <br />
                      子订单:{{order.orderSn}}
                    </div>
                  </div>
                </div>

                <!-- 可访问的采购单 -->
                <span class="fl ml-20">
                  采购单:
                  <span
                    @click="purchaseOrderDetail(order.parentSn)"
                    class="color-blue"
                  >{{order.parentSn}}</span>
                </span>
                <span class="fl color-9 ml-20">下单时间:{{order.gmtCreate | time}}</span>
                <span
                  class="fr color-6 mr-20"
                  v-if="order.orderStatus==1 && order.remainAutoCancelTime && order.remainAutoCancelTime > 0 && clickedTabName === '销售订单'"
                >
                  还剩
                  <span class="color-danger">{{order.remainAutoCancelTime}}</span>自动取消
                </span>
              </div>
              <div class="table-item-content clearfix bbd" style="display: flex;">
                <div class="clearfix" style="width:405px;box-sizing: border-box;flex:4">
                  <!--商品清单-->
                  <div
                    class="bb clearfix"
                    v-for="(orderItem, index) in order.itemList"
                    :key="index"
                    style="padding: 20px 0 20px 20px;box-sizing:border-box;display:flex"
                  >
                    <div class="fl specialD" style="flex:2">
                      <img v-if="orderItem.fileUrl" :src="orderItem.fileUrl" class="fl mr-20" />
                      <img v-else src="../../../assets/goods.jpg" class="fl mr-20" />
                      <img
                        v-if="order.storeType=='2'"
                        class="special"
                        src="../../../assets/special.png"
                        alt
                      />
                      <div class="row-item-1">
                        <p class="bw">{{orderItem.skuName}}</p>
                        <p class="color-9">规格:1*{{orderItem.specInfoNum}}</p>
                      </div>
                    </div>
                    <div class="fl tac" style="flex:1">¥{{orderItem.discountPrice | fmoney}}</div>
                    <div class="fl tac" style="flex:1">{{orderItem.num}}{{orderItem.unit}}</div>
                  </div>
                </div>
                <div
                  class="bl bb tac pt-20"
                  style="flex:1;word-break: break-all"
                >{{order.storeName}}</div>
                <div
                  class="bl bb tac pt-20"
                  style="flex:1;word-break: break-all"
                >{{order.commission}}</div>
                <div
                  class="bl bb tac pt-20"
                  style="flex:1;word-break: break-all"
                >{{order.customerName ? order.customerName : '--'}}</div>
                <div class="bl bb tac pt-20" style="flex:1;">
                  <span v-if="order.orderStatus==0">已关闭</span>
                  <span v-if="order.orderStatus==1">待付款</span>
                  <span v-if="order.orderStatus==2">待发货</span>
                  <span v-if="order.orderStatus==3">已发货</span>
                  <span v-if="order.orderStatus==4">已完成</span>
                </div>
                <!-- 支付方式 -->
                <div class="bl bb tac pt-20" style="flex:1;">
                  <span v-if="order.payMethodExp">{{order.payMethodExp}}</span>
                  <span v-else>--</span>
                </div>
                <div class="bl bb tac pt-20" style="flex:1;">
                  <div class="tac">¥{{order.needPayMoney | fmoney}}</div>
                  <div class="tac">（含运费：¥{{order.freightMoney | fmoney}}）</div>
                </div>
                <div class="bl bb tac" :style="{paddingTop : '15px'}" style="flex:1;">
                  <div :style="{width : '90px'}" class="w100">
                    <el-button
                      type="text"
                      v-if="order.orderStatus==1"
                      @click="handleCancelOrder(order.orderSn, order)"
                    >取消订单</el-button>
                    <el-button type="text" class="ml-0" @click="orderDetail(order)">订单详情</el-button>
                    <!-- 已发货 有审核凭证 -->
                    <template>
                      <el-button
                        :style="{marginLeft : '0'}"
                        v-if="order.creditState === 3"
                        @click="handleExamine(order)"
                        type="text"
                      >审核凭证</el-button>
                      <el-tooltip v-if="order.creditState === 0" content="待用户上传凭证" placement="top">
                        <span :style="{color: '#999'}">审核凭证</span>
                      </el-tooltip>
                    </template>
                    <!-- 审核通过 有查看凭证 -->
                    <template v-if="order.creditState === 1">
                      <el-button
                        :style="{marginLeft : '0'}"
                        @click="handleWitchExamine(order)"
                        type="text"
                      >查看凭证</el-button>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </el-checkbox-group>
          <!--取消订单弹出框-->
          <el-dialog class="cancelOrderDialog" title="取消订单理由" :visible.sync="cancelOrderVisible">
            <el-form class="pl-30 pr-30" :model="form" ref="cancelOrderForm" :rules="rules2">
              <el-form-item label prop="cancleReason">
                <el-input
                  type="textarea"
                  placeholder="请输入取消订单的理由..."
                  :autosize="{ minRows: 3 ,maxRows: 10}"
                  resize="none"
                  v-model.trim="form.cancleReason"
                ></el-input>
              </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
              <el-button type="danger" @click="handleCommit(2)">确 定</el-button>
              <el-button @click="cancelOrderVisible = false">取 消</el-button>
            </div>
          </el-dialog>
        </div>
        <el-pagination
          v-if="total>0"
          class="tar"
          :current-page.sync="currentPage"
          :page-size="10"
          :total="total"
          layout="prev , pager, next, jumper"
          @current-change="handleCurrentChange"
        >
          <!-- 分页组件 -->
        </el-pagination>
      </div>
    </div>

    <div class="content bg-f pl-30 pr-30 pb-40" v-if="clickedTabName === '采购订单'">
      <supercPurchaseOrder></supercPurchaseOrder>
    </div>
    <!--导出-->
    <!--<form ref="exportList" action='/sunrise-gateway/export/superc/hotelSuperOrder' style="display: none">
			<input type="text" name="startRow" v-model="a">
			<input type="text" name="startCol" v-model="a">
			<input type="text" v-if="formExport.orderSn" name="orderSn" v-model="formExport.orderSn">
			<input type="text" v-if="formExport.skuName" name="skuName" v-model="formExport.skuName">
			<input type="text" v-if="formExport.orderStatus" name="orderStatus" v-model="formExport.orderStatus">
			<input type="text" v-if="formExport.supercStoreId" name="supercStoreId" v-model="formExport.supercStoreId">
			<input type="text" v-if="formExport.startTime" name="startTime" v-model="formExport.startTime">
			<input type="text" v-if="formExport.endTime" name="endTime" v-model="formExport.endTime">
			<input type="text" v-if="formExport.payMethod" name="payMethod" v-model="form.payMethod" />
			<input type="text" v-if="form.sellerId" name="sellerId" v-model="form.sellerId">
			<input type="text" v-if="idStr.length" name="idStr" v-model="idStr">
			<input type="text" name="fileName" v-model="fileName">
			<input type="text" name="modelName" v-model="modelName">
    </form>-->

    <!-- 审核凭证 -->
    <order-viewredentials
      :imgList="voucherImgList"
      :bankInfo="bankInfo"
      :id="voucherId"
      :type="viewredentialsType"
      @close="handleVoucherClose"
      ref="viewredentials"
    ></order-viewredentials>
    <!-- 查看 凭证 -->
    <imgview
      ref="imgview"
      :visible="imgviewVisible"
      :imgs="voucherImgList"
      :startindex="0"
      @close="imgviewVisible=false"
    ></imgview>
    <!-- 拆单的审核凭证弹窗/取消订单弹窗 -->
    <hotel-divide-order
      :parentOrderSn="parentOrderSn"
      :text="cancelOrCheck == 0 ? '取消订单' : '审核凭证' "
      :showIt="divideOrderVisible"
      @changeOrderDialogStatus="changeOrderDialogStatusFun"
      @handleClick="handleClickFun"
    ></hotel-divide-order>
  </div>
</template>

<script>
import { axiosApi, basePath } from "../../../api/api";
import orderViewCredentials from "./orderViewCredentialsComponent.vue";
import hotelDivideOrder from "../../../components/jiuji/hotelDivideOrder.vue";
import supercPurchaseOrder from "./supercPurchaseOrder.vue";
import { mapMutations } from "vuex";
import _ from "underscore";
// import YsDaterangePicker from "~/components/ys-daterange-picker";

export default {
  components: {
    // YsDaterangePicker,
    "order-viewredentials": orderViewCredentials,
    hotelDivideOrder: hotelDivideOrder,
    supercPurchaseOrder: supercPurchaseOrder
  },
  data() {
    return {
      clickedTabName: "销售订单",
      /*规则*/
      rules2: {
        cancleReason: [
          {
            required: true,
            message: "取消订单理由不能为空"
          },
          {
            min: 4,
            max: 100,
            message: "取消订单理由为4-100位字符"
          }
        ]
      },
      type: "0",
      orderTabs: ["所有订单", "待付款", "待发货", "待收货", "已完成", "已关闭"],
      dataTabs: ["销售订单", "采购订单"],
      data: ["1"],
      cancelOrderVisible: false, //取消订单弹框
      reason: null,
      ordersListResVo: [], //订单数据
      orderSn: null, //订单编号
      superCList: [], //久集账号列表
      idStr: [], //批量导出选中列表
      fileName: null, //导出名称
      a: 0, //固定值 写死
      modelName: "superc.hotelSuperOrder",
      flag: false,
      cloneForm: {},
      form: {
        sellerId: sessionStorage.traderId,
        orderSn: null, //订单编号
        skuName: null, //商品名称
        orderStatus: null, //订单状态
        supercStoreId: null, //久集主键Id
        startTime: null,
        endTime: null,
        orderDateRange: [], //gmtOrderStart下单开始时间 gmtOrderEnd下单结束时间
        traderType: "1",
        cancleReason: null, //订单取消原因
        expressName: null, //物流公司
        expressNum: null, //物流单号
        payMethod: "" // 支付方式
      },
      formExport: {
        orderSn: null, //订单编号
        skuName: null, //商品名称
        orderStatus: null, //订单状态
        supercStoreId: null, //销售员id
        startTime: null,
        endTime: null,
        payMethod: "" // 支付方式
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > new Date();
        }
      }, //禁选未来时间
      rules: {
        cancleReason: [
          {
            max: 30,
            message: "不能超过30个字！"
          }
        ]
      },
      clearTime: 1, //定时器
      loading: false,
      currentPage: 1,
      total: null,
      badge: {
        //标记
        notPayCount: 0, //未付款
        notDeliveryCount: 0, //已付款-待发货
        orderDeliveriedCount: 0, //已发货-待收货
        successCount: 0, //已完成
        cancleCount: 0, //已取消
        notAuditCount: 0 //未审核
      },
      checkAll: false, //选中状态
      checkOrder: [], //选中的每行订单数据
      sendGoods: false, //发货弹框
      expressList: [], //物流列表
      voucherImgList: [], // 凭证图片 list
      bankInfo: {}, // 凭证 银行信息
      voucherId: "", // 审核 凭证 id
      viewredentialsType: "", // 审核浮层类型 0 - 审核 1 - 详情
      imgviewVisible: false, // 查看凭证 dialog 开关
      divideOrderVisible: false, //拆单dialog
      cancelOrCheck: 0, //默认取消订单为0， 审核凭证为1
      parentOrderSn: null, //主订单parentOrderSn
      rowSupercStoreId: null, //点击的item的supercsotreid,
      showAllsn: false,
      showSnIndex: null
    };
  },
  watch: {
    clickedTabName(val, old) {
      if (old == "采购订单" && val == "销售订单") {
        this.type = "0";
        this.form = {
          orderSn: null, //订单编号
          skuName: null, //商品名称
          orderStatus: null, //订单状态
          supercStoreId: null, //久集主键Id
          startTime: null,
          endTime: null,
          orderDateRange: [], //gmtOrderStart下单开始时间 gmtOrderEnd下单结束时间
          traderType: "1",
          cancleReason: null, //订单取消原因
          expressName: null, //物流公司
          expressNum: null, //物流单号
          payMethod: ""
        };
        this.form.sellerId = sessionStorage.traderId;
        this.currentPage = 1;
        this.queryOrderList(this.form);
      }
    },
    form: {
      handler: function() {
        this.flag = false;
      },
      deep: true
    }
  },
  mounted() {
    this.getsuperC(); //默认加载久集列表
    this.$nextTick(() => {
      setTimeout(() => {
        if (this.$route.query.supercStoreId) {
          this.form.supercStoreId = parseInt(this.$route.query.supercStoreId);
          this.type = this.$route.query.type + "";
          this.form.orderStatus = null;
        }
        this.queryOrderList(this.form); //默认加载订单
      }, 100);
    });
  },
  methods: {
    ...mapMutations({
      setTask: "setTask"
    }),
    //查看已生成报表
    checkTable() {
      let taskType = "superc_hotel_order";
      this.setTask({
        url: "/datacenter/exportTask/listExportTask",
        businessType: "superc",
        taskType: taskType
      });
      this.$router.push(basePath + "/statement");
    },
    exportExcel() {
      //批量导出
      if (this.cloneForm) {
        if (this.cloneForm.orderDateRange) {
          if (
            !this.cloneForm.orderDateRange[0] ||
            !this.cloneForm.orderDateRange[1]
          ) {
            this.$message.warning("请选择下单时间并查询，再进行批量导出");
            return;
          }
        } else {
          this.$message.warning("请选择下单时间并查询，再进行批量导出");
          return;
        }
      } else {
        this.$message.warning("请选择下单时间并查询，再进行批量导出");
        return;
      }

      if (this.form.payMethod == 2) {
        this.cloneForm.payMethodName = "授信支付";
      } else if (this.form.payMethod == 3) {
        this.cloneForm.payMethodName = "在线支付";
      } else if (this.form.payMethod == 4) {
        this.cloneForm.payMethodName = "余额支付";
      }

      this.superCList.map(v => {
        if (v.id == this.form.supercStoreId) {
          this.cloneForm.storeName = v.storeName;
        }
      });

      let body = _.pick(
        this.cloneForm,
        "orderSn",
        "skuName",
        "orderStatus",
        "supercStoreId",
        "startTime",
        "endTime",
        "payMethod",
        "payMethodName",
        "storeName",
        "sellerId"
      );

      axiosApi(
        "/datacenter.exportTask/addHotelSupercOrder",
        body,
        res => {
          this.$notify({
            title: "成功",
            message:
              "导出的文件正在生成，请不要重复点击，文件生成后，可以在“查看已生成报表”中查看!",
            type: "success"
          });
        },
        1,
        10,
        err => {
          this.$message.error(err.data.message);
        }
      );
    },
    // 获取 凭证图片 银行信息
    getVoucherImg(orderSn) {
      return new Promise((resove, reject) => {
        axiosApi(
          "/superc/credit/order/imgUploadPre",
          {
            orderSn: orderSn
          },
          res => {
            let data = res.data.data;

            if (data) {
              let bankInfo = {
                bankName: data.bankName,
                bankUserName: data.bankUserName,
                bankCard: data.bankCard
              };
              resove({
                img: data.imgs ? data.imgs : [],
                bankInfo: bankInfo
              });
            }
          }
        );
      });
    },
    handleExamine2(row) {
      this.viewredentialsType = 0;
      this.getVoucherImg(row.orderSn).then(data => {
        this.voucherImgList = data.img;
        this.voucherId = {
          supercStoreId: row.supercStoreId,
          orderSn: row.orderSn,
          buyerId: sessionStorage.traderId
        };
        this.bankInfo = data.bankInfo;
        this.$refs.viewredentials.show();
      });
    },
    // 审核 凭证
    handleExamine(row) {
      this.parentOrderSn = row.parentOrderSn;
      this.rowSupercStoreId = row.supercStoreId;
      if (row.parentOrSon == 3) {
        //子订单
        this.cancelOrCheck = 1;
        this.divideOrderVisible = true;
      } else {
        this.handleExamine2(row);
      }
    },
    // 审核 凭证 提交后
    handleVoucherClose() {
      // 刷新列表
      this.queryOrderList(this.form);
    },
    // 查看 凭证
    handleWitchExamine(row) {
      let sn = "";
      if (row.parentOrSon == 3) {
        sn = row.parentOrderSn;
      } else {
        sn = row.orderSn;
      }
      this.viewredentialsType = 1;
      this.getVoucherImg(sn).then(data => {
        this.voucherImgList = data.img;
        this.voucherId = {
          supercStoreId: row.supercStoreId,
          orderSn: row.orderSn,
          buyerId: sessionStorage.traderId
        };
        this.bankInfo = data.bankInfo;
        this.$refs.viewredentials.show();
      });
    },

    getsuperC() {
      /*久集账号*/
      axiosApi(
        "/superc/queryHotelStoreForSeller/select",
        {},
        res => {
          if (this.$route.query.nm) {
            this.superCList = [
              ...[
                {
                  storeName: this.$route.query.nm,
                  id: this.$route.query.supercStoreId
                }
              ],
              ...res.data.data
            ];
          } else {
            this.superCList = res.data.data;
          }
        },
        1,
        10,
        res => {}
      );
    },
    anonOrderCount() {
      //订单统计(角标)
      axiosApi(
        "/superc/hotelOrder/count",
        {
          sellerId: sessionStorage.traderId
        },
        res => {
          this.badge = res.data.data;
        },
        1,
        10,
        res => {}
      );
    },
    handleCurrentChange(val) {
      //分页
      this.currentPage = val;
      this.queryOrderList(this.form);
    },
    handleTabs2() {
      this.form = {
        orderSn: null, //订单编号
        skuName: null, //商品名称
        orderStatus: null, //订单状态
        supercStoreId: null, //久集主键Id
        startTime: null,
        endTime: null,
        orderDateRange: [], //gmtOrderStart下单开始时间 gmtOrderEnd下单结束时间
        traderType: "1",
        cancleReason: null, //订单取消原因
        expressName: null, //物流公司
        expressNum: null, //物流单号
        payMethod: ""
      };
      this.currentPage = 1;
      if (this.type == "0") {
        //全部订单
      } else if (this.type == "1") {
        //待付款
        this.form.orderStatus = 1;
      } else if (this.type == "2") {
        //待发货
        this.form.orderStatus = 2;
      } else if (this.type == "3") {
        //待收货
        this.form.orderStatus = 3;
      } else if (this.type == "4") {
        //已完成
        this.form.orderStatus = 4;
      } else if (this.type == "5") {
        //已关闭
        this.form.orderStatus = 0;
      }
      this.form.sellerId = sessionStorage.traderId;
      this.currentPage = 1;
      this.queryOrderList(this.form);
    },
    handleTabs(index) {
      this.clickedTabName = this.dataTabs[index];
    },
    handleCheck() {
      ///订单条件查询
      this.form.startTime =
        this.form.orderDateRange && this.form.orderDateRange[0]
          ? this.form.orderDateRange[0].getTime()
          : null;
      this.form.endTime =
        this.form.orderDateRange && this.form.orderDateRange[1]
          ? this.form.orderDateRange[1].getTime()
          : null;
      this.cloneForm = JSON.parse(JSON.stringify(this.form));
      setTimeout(() => {
        this.flag = true;
      }, 0);
      //订单编号表单验证
      this.$refs.searchForm.validate(valid => {
        if (valid) {
          if (this.currentPage != 1) {
            this.currentPage = 1;
            return;
          }
          this.queryOrderList(this.form);
        }
      });
    },
    queryOrderList(body) {
      /*久集订单列表*/
      this.loading = true;
      axiosApi(
        "/superc/order/queryHotelOrderList",
        body,
        res => {
          this.loading = false;
          this.total = res.data.data.totalCount;
          this.ordersListResVo = res.data.data.elements;
          /*定时器显示*/
          this.ordersListResVo.forEach((item, index) => {
            if (
              item.remainAutoCancelTime != null &&
              item.remainAutoCancelTime > 0
            ) {
              let time = parseInt(item.remainAutoCancelTime / 1000); //转化为整数
              item.remainAutoCancelTime = this.timeString(time); //转化为时间格式
              item.clearTime = null; //定义变量
              item.clearTime = setInterval(() => {
                time--;
                item.remainAutoCancelTime = this.timeString(time);
                if (time <= 0) {
                  clearTimeout(item.clearTime);
                  //this.cancelOrder({orderSn:item.sn,cancleType:3})  //系统取消
                  //									if(item.orderStatus == 1) item.orderStatus = 0
                  this.$set(this.ordersListResVo, index, item);
                }
              }, 1000);
            } else {
              //							if(item.orderStatus == 1) item.orderStatus = 0
              this.$set(this.ordersListResVo, index, item);
            }
          });
          //导出参数处理
          this.formExport.orderSn = this.form.orderSn;
          this.formExport.skuName = this.form.skuName;
          this.formExport.orderStatus = this.form.orderStatus;
          this.formExport.supercStoreId = this.form.supercStoreId;
          this.formExport.startTime = this.form.startTime;
          this.formExport.endTime = this.form.endTime;
          this.formExport.payMethod = this.form.payMethod;

          this.anonOrderCount();
        },
        this.currentPage,
        10,
        res => {
          this.loading = false;
          this.$message({
            type: "error",
            message: res.data.message,
            duration: 1000
          });
        }
      );
    },
    handleCancelOrder(orderSn, order) {
      //取消订单
      if (order.parentOrSon == 3) {
        //子订单
        this.divideOrderVisible = true;
        this.cancelOrCheck = 0;
        this.parentOrderSn = order.parentOrderSn;
      } else {
        this.cancelOrderVisible = true;
        this.orderSn = orderSn; //订单编号
      }
    },
    handleCommit(state) {
      //弹窗确定按钮
      this.$refs.cancelOrderForm.validate(valid => {
        if (valid) {
          this.cancelOrderVisible = false;
          axiosApi(
            "/superc/order/webCancel",
            {
              orderSn: this.orderSn,
              reason: this.form.cancleReason,
              cancleType: 2
            },
            res => {
              this.queryOrderList(this.form);
              this.$message({
                type: "success",
                message: "取消成功",
                duration: 3000
              });
            },
            1,
            10,
            res => {
              this.$message({
                type: "error",
                message: res.data.message,
                duration: 1000
              });
            }
          );
        } else {
          return false;
        }
      });
    },
    orderDetail(order) {
      //去订单详情页面
      sessionStorage.setItem("orderSn", order.orderSn);
      if (order.parentOrSon == 3) {
        window.open(
          basePath +
            "/supercHotelOrderDetail?sn=" +
            order.orderSn +
            "&id=" +
            order.parentOrderSn,
          "_blank"
        );
      } else {
        window.open(
          basePath + "/supercHotelOrderDetail?sn=" + order.orderSn,
          "_blank"
        );
      }
    },
    handleCheckAllChange(val) {
      /*table全选*/
      if (this.checkAll) {
        this.ordersListResVo.forEach(item => {
          this.checkOrder.push(item);
          this.idStr.push(item.id);
        });
      } else {
        this.checkOrder = [];
        this.idStr = [];
      }
    },

    handleCheckChange(val) {
      //单选
      let checkedCount = val.length;
      this.checkAll = checkedCount === this.ordersListResVo.length;
      this.idStr = [];
      val.forEach(item => {
        this.idStr.push(item.id);
      });
    },
    changeOrderDialogStatusFun(val) {
      this.divideOrderVisible = val;
    },
    handleClickFun(val) {
      if (val == "取消订单") {
        this.divideOrderVisible = false;
        this.cancelOrderVisible = true;
        this.orderSn = this.parentOrderSn; //订单编号
      } else if (val == "审核凭证") {
        this.divideOrderVisible = false;
        this.handleExamine2({
          supercStoreId: this.rowSupercStoreId,
          orderSn: this.parentOrderSn
        });
      }
    },
    purchaseOrderDetail(orderSn) {
      //去采购单详情页面
      let type = "jiuji";
      sessionStorage.setItem("orderSn", orderSn);
      window.open(basePath + "/ccPurchaseOrderDetail?type=" + type, "_blank");
    }
  }
};
</script>

<style lang="less">
#supercHotelOrder {
  position: relative;
  box-sizing: border-box;
  .el-tabs__header {
    padding-top: 10px;
    padding-left: 0;
  }
  .el-checkbox__label {
    display: none;
  }
  .notPayCountmark {
    position: absolute;
    left: 144px;
    top: 10px;
  }
  .notDeliveryCount {
    position: absolute;
    left: 218px;
    top: 10px;
  }
  .orderDeliveriedCount {
    position: absolute;
    left: 294px;
    top: 10px;
  }
  .hide {
    display: none;
  }
  .content {
    .el-checkbox {
      width: 40px !important;
    }
    .el-date-editor--daterange.el-input {
      width: 240px;
    }
    .el-form--inline {
      width: 650px;
      .el-form-item__label {
        font-size: 12px;
        width: 60px;
      }
    }
    .el-table .el-table__body-wrapper {
      display: none;
    }
    .order-datatimerange {
      width: 240px;
    }
    .el-table {
      border: none;
      font-size: 14px;
      th > .cell {
        line-height: 12px;
        padding: 10px 0 10px 0;
        text-align: center;
      }
      th.is-leaf {
        border-bottom: 0;
      }
    }
    .checkedAll {
      position: relative;
      top: 30px;
    }
    .el-checkbox {
      width: 70px;
      margin-left: 30px;
      .el-checkbox__input {
        height: 20px;
      }
    }
    .el-checkbox__inner {
      width: 20px;
      height: 20px;
    }
    .el-checkbox__inner::after {
      top: 2px;
    }
    .table-item {
      .bl {
        border-left: 1px solid #ddd;
      }
      border: 1px solid #dddddd;
      font-size: 14px;
      border-bottom: none;
      .table-item-content .pbl-20 {
        padding: 0px 0px 20px 20px;
      }
      .table-item-top {
        padding: 10px 0;
        position: relative;
        .table-item-top-checkbox {
          margin-left: 14px;
        }
        .all_sn_ct {
          background: #eee;
          position: absolute;
          left: 70px;
          top: 30px;
          padding: 10px 5px;
          border: 1px solid #ddd;
          z-index: 1;
        }
      }
      img {
        width: 80px;
        height: 80px;
      }
    }
    .w100 {
      width: 100px;
    }
    .table-item-default {
      height: 530px;
      padding-top: 120px;
    }
    .el-dialog {
      min-height: 300px;
      .el-form-item__label {
        width: 140px;
      }
    }
    .sendGoods .el-form-item__error {
      margin-left: 140px;
    }
  }
  .ml-70 {
    margin-left: 70px;
  }
  .my-badge {
    .el-badge__content {
      border: 0;
    }
  }
  .color-blue {
    color: #20a0ff;
    cursor: pointer;
  }
  .specialD {
    position: relative;
    display: flex;
    justify-content: space-between;
    .row-item-1 {
      width: 120px;
      height: auto;
      .bw {
        word-wrap: break-word;
      }
    }
    .row-item-2 {
      width: 0;
        flex: 1;
      height: auto;
      .bw {
        word-wrap: break-word;
      }
    }
    .special {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}
</style>
