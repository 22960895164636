<template>
  <div style="margin-bottom: 30px">
     <el-table class='common mb-50' :data='info'>
      <el-table-column label='操作时间' prop='gmtCreate'>
          <template slot-scope="scope">
              <span>{{scope.row.gmtCreate | time}}</span>
          </template>
      </el-table-column>
      <el-table-column label='操作人' prop='operatorName'></el-table-column>
      <el-table-column label='操作' prop='message'></el-table-column>
      <el-table-column label='区域名称' prop='regionName'>
          <template slot-scope="scope">
              <span v-text="scope.row.supplierName"></span>
          </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  props: ["info"],
  data() {
    return {};
  },
};
</script>

<style>
</style>