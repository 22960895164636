<template>
  <div class="advert-tops" v-if="message" @click="toUrl">
    <div class="ico-horn">
      <img src="../../assets/attention.png" alt="">
    </div>
    <!-- 滚动文字区域 -->
    <div class="marquee-wrap">
      <div class="marquee-box" id="marquee-box">
        <div v-for="i in 3" :key="'i'+i" class="marquee-list"  :id="i == 1 ? 'marquee' : ''" >
          <!-- <span v-for="item in message" :key="item">{{item}}</span> -->
          <span>{{message}}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script type="text/javascript">
import { axiosApi, basePath } from "~/api/api";
export default {
  // props: {
  //   message: {
  //     type: Array,
  //     required: true,
  //   },
  // },
  data() {
    return {
      message: '',
      basePath
    };
  },
  mounted: function () {
    // 获取滚动数据,登录时请求
    var id = sessionStorage.getItem('id')
    if(id){
      this.getMessage()
    }
   
    // 延时滚动
    setTimeout(() => {
      this.runMarquee();
    }, 1000);
  },
  methods: {
    toUrl() {
      this.$router.push(basePath+'/stock/skuStockAlert')
    },
    getMessage(){
      const api = "/warehouse/stock/alert/getSkuStockAlertMessage";
      axiosApi(api, {}, (res)=>{
        if(res.data.code ==='000000'){
          this.message = res.data.data
          // 判断通知栏是否显示
          this.$emit('isNotice', res.data.data?true:false)
        }
      }, null, null)
    },
    runMarquee() {
      // 获取文字 计算后宽度
      var width = document
          .getElementById("marquee")
          .getBoundingClientRect().width,
        marquee = document.getElementById("marquee-box"),
        disx = 0; // 位移距离
      //设置位移
      setInterval(() => {
        disx--; // disx-=1; 滚动步长
        if (-disx >= width) {
          disx = 10; // 如果位移超过文字宽度，则回到起点  marquee-list的margin值
        }
        // marquee.style.transform = 'translateX(' + disx + 'px)'
        marquee.style.left = disx + "px";
      }, 30); //滚动速度
    },
  },
};
</script>
<style>

.advert-tops {
  cursor: pointer;
  position: relative;
  display: flex;
  width: 300px;
  height: 36px;
  padding: 0 10px;
  background-color: #fffbe8;
  font-size: 14px;
  align-items: center;
}
.ico-horn {
  display: flex;
  margin-right: 10px;
  width: 14px;
  height: 14px;
  justify-content: center;
  align-items: center;
}
.ico-horn > img {
  width: 20px;
  height: 20px;
}
/* 以下代码与滚动相关 */
.marquee-wrap {
  position: relative;
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 100%;
  margin-left: 20px;
}
.marquee-box {
  position: absolute;
  top: 50%;
  display: flex;
  white-space: nowrap;
  transform: translateY(-50%);
}
.marquee-list {
  min-width: 300px;
  margin-right: 10px;
}
.marquee-list span {
  padding: 0 0.04rem;
  color: rgb(236, 114, 13);
  /* font-weight: 700; */
}
</style>
