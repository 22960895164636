<template>
	<div class="couponInlineDiv cursor" @mouseover="handlecouponShow($event)" @mouseout="getCouponList.couponVisible = false">
		<div class="couponImg"></div>
		<div :class="{ 'content': true }" @mouseover="getCouponList.couponVisible = true" @mouseout="getCouponList.couponVisible = false" v-show="getCouponList.couponVisible" ><!--  -->
			<div class="title"></div>
			<div class="list" v-loading="loading">
				<div class="list-title">
					<div class="lh-16">
						<i class="icon-notice iconfont"></i><span class="font-16 lh-16 color-6">已领取{{receiveCouponNum}}张优惠券<span :class="{'hide':!show}">，有优惠券可领取</span></span>
					</div>
					<!-- <i class="el-icon-close color-b cursor" @click="handleCouponClose($event)"></i> -->
				</div>
				<div class="list-line"></div>
				<div class="list-content" v-for="i in couponStateList" >
					<div class="list-content2">
						<div class="pb-10"><i class="icon-coupon iconfont color-danger font-30 mr-10" ></i></div>
						<div style="line-height: 16px;">
							<div v-show="i.fullMoney"><span class="color-6 font-16" style="line-height: 16px;">满￥{{i.fullMoney}}元减{{i.minusMoney}}</span></div>
							<div v-show="i.fullNum"><span class="color-6 font-16" style="line-height: 16px;">满{{i.fullNum}}数量减{{i.minusMoney}}</span></div>
							<span class="color-9 font-12">{{i.gmtSendStart | time}}—{{i.gmtSendEnd | time}}</span>
						</div>
					</div>
					<el-button type="info" class="mr-20" @click="handleReceive(i)" :disabled="i.remainNum<=0">领取</el-button>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import { basePath,axiosApi } from '../api/api'
	export default {
		name: 'storeGetCoupon',
		props:[ "couponlist" ,"sellerId"],
		data() {
			return {
				//couponVisible: false, //优惠券Boolean
				couponList: [], //优惠券列表
				loading:false,
				couponStateList:[], //优惠券状态列表
				receiveCouponNum:0, //优惠券领取个数
				getCouponList:{
					couponVisible:false
				},
				show:false,  //
				skuNos:[]
			}
		},
		computed:{
		},
		mounted() {
			this.$nextTick(() => {
				// console.log('28347657234',this.couponlist)
				// this.getCouponList = this.couponlist;
				// this.skuNo = !this.getCouponList.skuNo ? null : this.getCouponList.skuNo;
			})
		},
		watch: {
			// couponlist(value) {
			// 	// console.log('44444',value)
			// 	// this.getCouponList = value;
			// 	// this.skuNo = !this.getCouponList.skuNo ? null : this.getCouponList.skuNo;
			// }
		},
		methods: {
			/*优惠券操作*/
			//领取优惠券
			handleReceive(item){
				// console.log(item)
				let body = {
					buyerId: sessionStorage.traderId,
					cpnsId: item.cpnsId,
					source: '购物车领取'
				}
				this.findCouponListInsert(body, item)
			},
			//领取优惠券接口
			findCouponListInsert(body, item){
				this.loading = true
				axiosApi('/trader/findCouponList/insert', body, (res)=>{
					this.loading = false
					// console.log('领取优惠券接口', res)
					this.$message({type:'success', message:'领取成功', duration:1000})
					this.receiveCouponNum++
					item.remainNum = res.data.data.remainNum
					let index = this.couponList.indexOf(item)
					this.$set(this.couponList, index, item)
					this.show = false
					this.couponStateList.forEach((i)=>{
						if(i.remainNum>0){
							this.show = true
						}
					})
				},1,10,(res)=>{
					this.loading = false
					this.$message({type:'error', message:res.data.message, duration:1000})
				})
			},
			//领取优惠券状态查询
			traderFindCouponState(body){
				axiosApi('/trader/findCouponState/select', body, (res)=>{
					// console.log('领取优惠券状态查询', res)
					this.couponStateList = res.data.data
					if(this.couponStateList){
						this.receiveCouponNum=0
						this.show = false
						this.couponStateList.forEach((i)=>{
							this.receiveCouponNum = this.receiveCouponNum+i.receiveNum
							if(i.remainNum>0){
								this.show = true
							}
						})
					}
				},1,10,(res)=>{
					
				})
			},
			//优惠券
			handlecouponShow(event) { //优惠券显示，隐藏
				//console.log(event.target.className)
				this.getCouponList = Object.assign({},this.getCouponList)
				this.getCouponList.couponVisible = true;
				if(this.couponStateList && this.couponStateList.length>0) {
					return;
				}
				this.skuNos = this.couponlist.map(v => {
					console.log(v.skuNo,'no')
					return v.skuNo
				});
				console.log(this.skuNos,'nos')
				let body = {
					buyerId: sessionStorage.traderId, //进货商ID 需要 从 sessionStorage里面获取
					sellerId: this.sellerId, //供应商ID
					skuNos: this.skuNos
				};
				this.traderFindCouponState(body)
			},
			handleCouponClose(event) { //点击优惠券的 关闭按钮
				// console.log(Object.getOwnPropertyDescriptor(this.getCouponList, 'couponVisible'))
				this.getCouponList = Object.assign({},this.getCouponList)
				this.getCouponList.couponVisible = !this.getCouponList.couponVisible;
				// console.log(Object.getOwnPropertyDescriptor(this.getCouponList, 'couponVisible'))
			}
		}
	}
</script>
<style lang="less">
	.hide {
		display: none;
	}
	.couponInlineDiv {
		display: inline-block;
		position: relative;
		vertical-align: middle;
		.couponImg{
			width: 106px;
			height: 34px;
			user-select: none;
			background-image: url(../assets/Group 12.png);
			background-position: center;
			background-repeat: no-repeat;
			z-index: 10000;
		}
		.content {
			width: 440px;
			z-index: 1000;
			padding-top: 10px;
			border-radius: 2px 0 0 0 2px 2px 2px;
			background: #FFFFFF;
			position: absolute;
			top: 32px;
			padding-bottom: 0px;
			.title {
				height: 22px;
				width: 100%;
				background-image: url(../assets/Artboard.png);
				background-repeat: no-repeat;
			}
			.list {
				box-sizing: border-box;
				padding: 0 20px 20px 20px;
				border: 2px solid #FF4201;
				border-top: none;
				.list-title {
					padding: 14px 0;
					display: flex;
					justify-content: space-between;
					align-items: center;
					.el-icon-close {
						&:hover {
							color: #333333;
						}
					}
				}
				.list-line {
					height: 1px;
					background: #DDDDDD;
				}
				.list-content {
					display: flex;
					align-items: center;
					justify-content: space-between;
					padding-top: 20px;
					.el-button--danger {
						width: 50px;
						height: 30px;
					}
					.list-content2 {
						display: flex;
						align-items: center;
						justify-content: space-between;
					}
				}
			}
		}
	}
</style>