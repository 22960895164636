import classify from '../views/field/classify.vue' //分类管理
import article from '../views/field/article.vue' //文章管理
import articleDetails from '../views/field/articleDetails.vue' //文章新增、编辑
import dispatch from '../views/field/dispatch.vue' //订单配送
import businessList from '../views/field/businessList.vue' //商户列表
import punch from '../views/field/punch.vue' //打卡
import visitRecord from '../views/field/visitRecord.vue' //拜访记录
import visitedDetail from '../views/field/visitedDetail.vue' //拜访记录详情
import visitAction from '../views/field/visitAction.vue' //拜访动作配置
import reportType from '../views/field/reportType.vue' //报备类型设置
import area from '../views/field/area.vue' //地区管理
import checkWork from '../views/field/checkWork.vue' //考勤管理
import checkDetail from '../views/field/checkDetail.vue' //考勤详情
import history from '../views/field/history.vue' //历史轨迹
import tasting from '../views/field/tasting.vue' //品鉴会任务
import tastingDetail from '../views/field/tastingDetail.vue' //品鉴会任务详情
import eventreport from '../views/field/eventreport.vue' //事件报备
import sogospread from '../views/field/sogospread.vue' //商户分布
import commoditymanage from '../views/field/commoditymanage.vue' //经销商商品管理
import actualpath from '../views/field/actualpath.vue' //实时轨迹
import pathdetail from '../views/field/pathdetail.vue' //轨迹详情
import Indent from '../views/field/indent.vue' //已采购订单

import CloudOrderDetails from '../views/shop/cloudOrderDetails.vue' //云销订单详情
import CloudPayOrder from '../views/shop/cloudPayOrder.vue' //采购进货付款页面
import CloudPayCertCheck from '../views/shop/cloudPayCertCheck.vue' //采购进货付款页面

import VisitSet from '../views/field/visitSet.vue' //拜访设置
import Settings from '../views/field/settings.vue' //拜访设置 考勤设置 报备类型设置

import { basePath } from '../api/api'

export const yxFirstRouters = [];
export const yxHomeRouters = [
	{
		path: basePath + '/cloudPayOrder', //采购进货 付款页面（云销）
		component: CloudPayOrder,
        name:'cloudPayOrder',
        text:'付款页面（云销）',
	},
	{
		path: basePath + '/cloudPayCertCheck/:sign?', //支付凭证审核(云销)
		component: CloudPayCertCheck,
        name:'cloudPayCertCheck',
        text:'支付凭证审核(云销)',
	},
	{
		path: basePath + "/cloudOrderDetails/:orderSn?", //云销 订单详情
		component: CloudOrderDetails,
        name:'cloudOrderDetails',
        text:'订单详情',
	}
];
export const yxHomeLogoRouters = [];
export const yxHomeMenuRouters = [
	{
		path: basePath + '/classify', //葵花宝典/分类管理
		component: classify,
        name:'classify',
        text:'分类管理',
	},
	{
		path: basePath + '/article', //葵花宝典/文章管理
		component: article,
		meta: { keepAlive: true },
        name:'article',
        text:'文章管理',
	},
	{
		path: basePath + '/articleDetails', //葵花宝典/文章编辑，管理
		component: articleDetails,
        name:'articleDetails',
        text:'文章编辑管理',
	},
	{
		path: basePath + '/dispatch', //商户管理/订单配送(云销)
		component: dispatch,
		meta: { keepAlive: true },
        name:'dispatch',
        text:'订单配送(云销)',
	},
	{
		path: basePath + '/indent', //商户管理/已采购订单(云销)
		component: Indent,
		meta: { keepAlive: true },
        name:'indent',
        text:'已采购订单(云销)',
	},
	{
		path: basePath + '/businessList', //系统设置/商户列表
		component: businessList,
		meta: { keepAlive: true },
        name:'businessList',
        text:'商户列表',
	},
	{
		path: basePath + '/punch', //外勤管理/打卡
		component: punch,
		meta: { keepAlive: true },
        name:'punch',
        text:'打卡',
	},
	{
		path: basePath + '/visitRecord', //外勤管理/拜访记录
		component: visitRecord,
		meta: { keepAlive: true },
        name:'visitRecord',
        text:'拜访记录',
	},
	{
		path: basePath + '/visitedDetail', //外勤管理/拜访详情
		component: visitedDetail,
        name:'visitedDetail',
        text:'拜访详情',
	},
	{
		path: basePath + '/visitAction', //外勤管理/拜访动作配置
		component: visitAction,
        name:'visitAction',
        text:'拜访动作配置',
	},
	{
		path: basePath + '/reportType', //销售顾问/报备类型设置
		component: reportType,
		meta: { keepAlive: true },
        name:'reportType',
        text:'报备类型设置',
	},
	{
		path: basePath + '/area', //广告管理/地区管理
		component: area,
        name:'area',
        text:'地区管理',
	},
	{
		path: basePath + '/checkWork', //考勤管理
		component: checkWork,
        name:'checkWork',
        text:'考勤管理',
	},
	{
		path: basePath + '/checkDetail/:id?', //考勤详情
		component: checkDetail,
        name:'checkDetail',
        text:'考勤详情',
	},
	{
		path: basePath + '/history', //历史轨迹
		component: history,
		meta: { keepAlive: true },
        name:'history',
        text:'历史轨迹',
	},
	{
		path: basePath + '/tasting', //品鉴会任务
		component: tasting,
        name:'tasting',
        text:'品鉴会任务',
	},
	{
		path: basePath + '/tastingDetail', //品鉴会详情
		component: tastingDetail,
        name:'tastingDetail',
        text:'品鉴会详情',
	},
	{
		path: basePath + '/eventreport', //事件报备
		component: eventreport,
		meta: { keepAlive: true },
        name:'eventreport',
        text:'事件报备',
	},
	{
		path: basePath + '/commoditymanage', //经销商商品管理
		component: commoditymanage,
		meta: { keepAlive: true },
        name:'commoditymanage',
        text:'经销商商品管理',
	},
	{
		path: basePath + '/actualpath', //实时轨迹
		component: actualpath,
        name:'actualpath',
        text:'实时轨迹',
	},
	{
		path: basePath + '/pathdetail', //历史轨迹详情
		component: pathdetail,
        name:'pathdetail',
        text:'历史轨迹详情',
	},
	{
		path: basePath + '/sogospread', //商户分布
		component: sogospread,
        name:'sogospread',
        text:'商户分布',
	},
	{
		path: basePath + '/visitSet', //拜访设置
		component: VisitSet,
        name:'visitSet',
        text:'拜访设置',
	}
	,
	{
		path: basePath + '/settings', //总设置
		component: Settings,
        name:'settings',
        text:'总设置',
	}
];
export const yxCarLogoRouters = [];
