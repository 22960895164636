<template>
	<div id="qualManage">
		<div class="title-top bg-f">
			销售资质
		</div>
		<div class="main pl-100 pl-20 pt-50 pb-50 mt-5 bg-f">
			<p class="ml-30">销售资质</p>
			<div class="ml-100 mt-50" v-for='list1 in list' >
				<div class="clearfix mb-20">
					<p class="fl ml-30">品牌：<span class="ml-30">{{list1.brandName}}</span></p>
					<p class="fl ml-50">分类：<span class="ml-30">{{list1.classificationName}}</span></p>
				</div>
				<div class="clearfix border-bottom mr-30">
					<p class="fl mr-30">销售资质：</p>
					<div class="mb-50  hasP fl w750">
						<el-upload action="/sunrise-gateway/oss/ossUpload" :file-list="list1.imgarr" list-type="picture-card" :on-preview="handlePictureCardPreview">
							<i class="el-icon-plus"></i>
						</el-upload>
					</div>
				</div>
			</div>
		</div>
		<el-dialog :visible.sync="dialogVisible" size="tiny">
			<img width="100%" :src="dialogImageUrl" alt="show-message">
		</el-dialog>
	</div>
</template>

<script>
	import { axiosApi, basePath } from "../../../api/api";
	export default {
		data() {
			return {
				list: null,
				dialogVisible: false,
				dialogImageUrl: ""
			}
		},
		methods: {
			handlePictureCardPreview(file) {
				this.dialogImageUrl = file.url;
				this.dialogVisible = true;
			},
			//加载信息
			achieve() {
				const api = "/trader/findTraderCertRecord/select";
				const body = {
					traderId: sessionStorage.traderId,
					state: 2
				}
				axiosApi(api, body, res => {
					this.list = res.data.data ? res.data.data : []
					this.list.forEach(item1 => {
						this.$set(item1, "imgarr", [])
						item1.certRelationVOs.forEach(item2 => {
							item1.imgarr.push({
								url: item2.certImg
							})
						})
					})
				}, 1, 10)
			},
		},
		//进入加载
		mounted() {
			this.$nextTick(function() {
				this.achieve()
			})
		}
	};
</script>

<style lang="less">
	#qualManage {
		.border-bottom {
			border-bottom: 1px dashed #DDDDDD;
		}
		.w750{
			width: 750px;
		}
	}
</style>