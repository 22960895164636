import { axiosApi } from '../../api/api.js'

const shl = {
	state: {
		current: "",
		path:"",
		mnum:""
	},
	mutations: {
		resetState(state){
			state.current = "";
			state.path = "";
			state.mnum = "";
		},
		sth(state,payload){//店铺切换
			state.current = payload.traderId;
			state.path = payload.path;
		},
		mesnum(state,payload){
			state.mnum = payload;
		}
	}
}

export default shl