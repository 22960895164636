<template>
  <el-cascader
    :props="districtProps"
    :options="districtList"
    clearable
    @change="Change"
    placeholder="选择区域"
    v-model="districtId"
  ></el-cascader>
</template>

<script>
import { getDistrict } from "~/api/publicInfo";
import { mapActions, mapMutations } from 'vuex' 

export default {
  props: {
    value:{}, // 
  },
  data() {
    return {
      data: [],
      districtProps: {
        label: "districtName",
        value: "id",
        children: "subDistrictList",
        checkStrictly: true,
        emitPath: false,
      },
      districtId: null,
    };
  },
  watch: {
    value(newVal,oldVal){
      this.districtId = newVal
    }
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  computed: {
    districtList() {
        return this.cascaderClear2(this.$store.state.channelLevel.districtList); 
    },
  },
  mounted() {
    this.districtId = this.value

    this.queryDistrictList();
  },
  methods: {
    ...mapActions({
      'queryDistrictList': 'queryDistrictList',
    }),
    Change: function (val) {
      this.$emit("change", val);
    },
    // getList(){
    //   getDistrict().then(res=>{
    //     this.districtList = this.cascaderClear2([res]);
    //   }).catch(err=>{

    //   })
    // }
  },
};
</script>

<style lang="less" scoped>

</style>
