<template>
    <div id="repertoryManage">
        <div class="title-top bg-f title-tabs">
            <span>仓库管理</span>
            <div class="fr">
                <el-button class="fr" @click="addRepertory" v-if="showButton('ADD', 200128)">添加新仓库</el-button>
            </div>
        </div>
        <div class="bg-f mt-5 pb-50 pt-30">
            <el-table v-loading="Loading" :data="tableData" tooltip-effect="dark" class="ml-30 mr-30 mt-20 table-default" stripe>
                <el-table-column prop="name" label="仓库名称" min-width="200">
                </el-table-column>
                <el-table-column label="仓库所在地" min-width="200">
                    <template slot-scope="scope">
                        {{scope.row.provinceName}}
                        {{scope.row.cityName}}
                        {{scope.row.regionName}}
                        {{scope.row.streetName}}
                        {{scope.row.addr}}
                    </template>
                </el-table-column>
                <el-table-column prop="linkman" label="负责人" min-width="190">
                </el-table-column>
                <el-table-column label="操作" min-width="190">
                    <template slot-scope="scope">
                        <el-button type="text" @click="editRepertory(scope.row.id)" v-if="showButton('EDIT', 200128)">编辑</el-button>
                        <el-button type="text" @click="deleteRepertory(scope.row.id)" v-if="showButton('DEL', 200128)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <!-- 添加新仓库弹窗-->
        <el-dialog :close-on-click-modal="false"  :title="stockTitle + '仓库'" :visible.sync="addRepertoryDialog" :before-close="closeDialog" width="730px">
            <div>
                <el-form :model="addRepertoryForm"  :rules="rules" ref="addRepertoryFormRef" label-width='185px' class="pb-20">
                    <el-form-item label="仓库名称:" prop="name">
                        <el-input v-model.trim ="addRepertoryForm.name" :maxlength="30" placeholder="请输入仓库名称"></el-input>
                        <el-checkbox v-model="addRepertoryForm.isDefault" class="ml-20">设为主仓（优先发货）</el-checkbox>
                    </el-form-item>
                    <el-form-item label="所在地区:" prop="districtIds">
                        <selectArea v-model="addRepertoryForm.districtIds" @changeName="changeName" />
                    </el-form-item>
                    <el-form-item label="详细地址:" prop="addr" class="clearfix">
                        <el-input v-model.trim="addRepertoryForm.addr" :maxlength="60" placeholder="请填写详细地址" ></el-input>
                    </el-form-item>
                    <el-form-item label="负责人:">
                        <el-input v-model.trim="addRepertoryForm.linkman" :maxlength="10" placeholder="请输入负责人"></el-input>
                    </el-form-item>
                    <el-form-item label="联系电话:" prop="tel">
                        <el-input v-model.trim="addRepertoryForm.tel" :maxlength="12" placeholder="请输入联系电话"></el-input>
                    </el-form-item>
                </el-form>
            </div>

            <div slot="footer" class="dialog-footer">
                <el-button :loading="btnLoading" type="primary" @click="addRepertoryClick">确定</el-button>
                <el-button  @click="addRepertoryDialog = false, resetForm()">取消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import { axiosApi, basePath } from "../../api/api"

    export default {
        name: "repertoryManage",
        data(){
            var validateAddress = (rule, value, callback) => {
                var info = this.addRepertoryForm.districtIds
                if (info.length===0) {
                    callback(new Error('请选择省市区'));
                } else {
                    if(info[0]==null){
                    callback(new Error('请选择省'));
                    }else if(info[1]===null){
                    callback(new Error('请选择市'));
                    }else if(info[2]===null){
                    callback(new Error('请选择区'));
                    }else{
                    callback();
                    }
                }
            };
            return{
                stockTitle: '',
                tableData:[],
                Loading: false,
                btnLoading: false,
                addRepertoryDialog: false,
                firstRequest: true,
                fourListsDelivey:[],
                addRepertoryForm:{
                    name: '',
                    districtIds: [],
                    addr: '',
                    // distributionType: 'all',
                    isDefault:false,
                    dispatchingId:[[]],
                    // distributionJson: [],
                    k3WarehouseCompanyNo:'',
                    linkman:'',
                    tel:'',
                },
                KThreeRepertoryList: [],
                rules:{
                    name: [
                        { required: true, message: '请正确填写，仓库名称2-30个字符', trigger: 'blur' },
                        { min: 2, max: 30, message: '请正确填写，仓库名称2-30个字符', trigger: 'blur' }
                    ],
                    districtIds: [
                        { validator: validateAddress, trigger: 'change' },
                    ],
                    addr: [
                        { required: true, message: '请正确填写地址，2-60个字符', trigger: 'blur' },
                        { min: 2, max: 60, message: '请正确填写地址，2-60个字符', trigger: 'blur' }
                    ],
                    dispatchingId: [
                        {type: 'array', required: true, message: '请选择地区', trigger: 'blur'}
                    ],
                    k3WarehouseCompanyNo: [
                        { required: true, message: '请选择k3仓库', trigger: 'blur'}
                    ],
                    linkman: [
                        { min: 2, max: 20, message: '请正确填写，2-20个字符', trigger: 'blur'}
                    ],
                    tel: [
                        { pattern: /^[0-9]{7,12}$/, message: '请正确填写电话号码7-12位数字', trigger: 'blur'}
                    ]
                },
                areaNameList: []
            }
        },
        methods:{
            changeName(e){
			    this.areaNameList = e
		    },
            resetForm() { //清空验证方法
                this.$refs['addRepertoryFormRef'].resetFields();
            },
            closeDialog(done) {
                this.resetForm()
                done()
            },
            addRepertory() {
                if (this.tableData.length<2){
                    this.stockTitle = '新增'
                    this.addRepertoryDialog = true
                    this.addRepertoryForm = {
                        streetId:'',
                        streetName: '',
                        dispatchingId: [[]],
                        districtIds: [],
                        name: '',
                        provinceId: '',
                        cityId: '',
                        regionId: '',
                        provinceName: '',
                        cityName: '',
                        regionName: '',
                        addr: '',
                        isDefault: this.tableData.length == 0 ? true : false,
                        linkman: '',
                        tel: '',
                        // distributionType: 'all',
                        k3WarehouseCompanyNo: '',
                        // distributionJson: [],
                        id: null
                    }
                    
                }else{
                    this.$message.warning('目前仅支持创建两个仓库')
                }
            },
          
            addRepertoryClick() {
                this.$refs.addRepertoryFormRef.validate(valid => {
                    if(valid) {
                        this.btnLoading = true
                        let param = {
                            name: this.addRepertoryForm.name,
                            provinceId: this.addRepertoryForm.districtIds[0],
                            cityId: this.addRepertoryForm.districtIds[1],
                            regionId: this.addRepertoryForm.districtIds[2],
                            streetId: this.addRepertoryForm.districtIds[3],
                            provinceName: this.areaNameList[0],
                            cityName: this.areaNameList[1],
                            regionName: this.areaNameList[2],
                            streetName: this.areaNameList[3],
                            addr: this.addRepertoryForm.addr,
                            isDefault: this.addRepertoryForm.isDefault,
                            linkman: this.addRepertoryForm.linkman,
                            tel: this.addRepertoryForm.tel,
                            distributionType: 'all',
                            k3WarehouseCompanyNo: this.addRepertoryForm.k3WarehouseCompanyNo,
                        }
                        if(this.addRepertoryForm.k3WarehouseCompanyNo) {
                            this.KThreeRepertoryList.forEach(item => {
                                if(item.stockNum === this.addRepertoryForm.k3WarehouseCompanyNo) {
                                    param.k3WarehouseType = item.k3WarehouseType
                                }
                            })
                        }
                       
                        let api = this.stockTitle === '新增' ? '/warehouse/info/insert' : '/warehouse/info/update'
                        this.stockTitle === '编辑' && (param.id = this.addRepertoryForm.id)
                        axiosApi(api, param, (res)=>{
                            this.addRepertoryDialog = false;
                            setTimeout(() => {
                                this.btnLoading = false
                            }, 300)
                            this.$message.success(this.stockTitle + '成功！')
                            this.getRepertoryList()
                        }, 1, 10, err => {
                            this.btnLoading = false
                            this.$message.error(err.data.message)
                        })
                    }
                })
            },
            editRepertory(val) {
                axiosApi('/warehouse/detail/select', {id:val}, (res)=>{
                    const data = res.data.data
                    const districtIds = [].concat(data.provinceId.toString(),data.cityId.toString(),data.regionId.toString(),data.streetId.toString())
                   
                    this.addRepertoryForm = {
                        ...this.addRepertoryForm,
                        districtIds: districtIds,
                        name: data.name,
                        addr: data.addr,
                        isDefault: data.isDefault == 1 ? true : false,
                        linkman: data.linkman,
                        tel: data.tel,
                        // distributionType: data.distributionType,
                        k3WarehouseCompanyNo: data.k3WarehouseCompanyNo,
                        // distributionJson: data.distributionJson,
                        id: data.id
                    }
                    this.addRepertoryDialog = true;
                    this.stockTitle = '编辑';
                }, 1, 10, err => {
                    this.$message.error(err.data.message)
                })
            },
            deleteRepertory(val){
                this.$confirm('删除仓库会导致此仓库的商品库存清除，请谨慎', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    axiosApi('/warehouse/info/delete',{
                        id:val
                    },(res)=>{
                        this.$message.success('删除成功')
                        this.getRepertoryList()
                    },1,10,err=>{
                        this.$message.error(err.data.message)
                    })
                }).catch(() => {

                });
            },
            
            
            getRepertoryList() {
                axiosApi('/warehouse/info/list',{},(res)=>{
                    this.tableData = res.data.data ? res.data.data : []
                })
            }
        },
        created(){
            this.getRepertoryList()
        },

    }
</script>

<style lang="less">
#repertoryManage{
    .fr{
        float: right;
    }
    .el-dialog{
        width: 720px;
    }
}
</style>
