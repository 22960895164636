import { axiosApi } from '../../api/api.js'

const address = {
	state: {
		item: {
			selectedOptions: [],
			streetId: ""
		}
	},
	mutations: {
		userInfo(state, payload) {
			let newitem = Object.assign({}, payload)
			state.item = newitem
			state.item.selectedOptions = [];
			if (newitem["provinceId"]) {
				state.item.selectedOptions.push(newitem["provinceId"].toString());
				if (newitem["cityId"]) {
					state.item.selectedOptions.push(newitem["cityId"].toString());
					if (newitem["regionId"]) {
						state.item.selectedOptions.push(newitem["regionId"].toString());
						if (newitem["streetId"]) {
							state.item.selectedOptions.push(newitem["streetId"].toString());
							state.item.streetId = newitem["streetId"];
						}
					}
					
				}
			}
		},
		clearItem(state, payload) {
			state.item = {
				selectedOptions: [],
				streetId: ""
			}
		}
	}
}

export default address