<template>
    <div id="jicaiList" class="pb-50">
        <div class="title_top bg-f clearfix">
            <div class="fl">招标信息</div>
            <div class="button button_top fr tac mr-30 mt-15 color-3 font-14 cursor" @click="toCreate">发布招标信息</div>
        </div>
        <div class="bg-f mt-5">
            <div class="content bg-f pl-30 pr-30 ">
                <!-- 状态切换区域 -->
                <div class="tab_box">
                    <div class="tab_item" :class="{tab_item_checked: item.type==state}" v-for="(item,index) in tabList"
                         :key="index" @click="checkState(item)">
                        <span>{{item.label}}</span>
                        <span>({{item.bage ? item.bage : 0}})</span>
                    </div>
                </div>
                <!-- 搜索区域 -->
                <div class="clearfix search">
                    <el-form :inline='true' class="pt-30 fl main" label-width="90px" :model="Form" ref="searchForm">
                        <div>
                            <el-form-item label='中标商户' class="fl">
                                <el-input v-model.trim="Form.traderName" :maxlength="30" placeholder="输入中标商户名称"></el-input>
                            </el-form-item>
                            <el-form-item label='商户账号' class="fl">
                                <el-input v-model.trim="Form.traderAccount" :maxlength="30"
                                        placeholder="输入中标商户账号"></el-input>
                            </el-form-item>
                            <el-form-item label='商品名称' class="fl">
                                <el-input v-model.trim="Form.goodsName" :maxlength="30" placeholder="输入商品名称"></el-input>
                            </el-form-item>
                            <el-form-item label='商品编码' class="fl">
                                <el-input v-model.trim="Form.goodsNo" :maxlength="30" placeholder="输入商品编码"></el-input>
                            </el-form-item>
                                <el-form-item label='招标时间'>
                                    <ys-daterange-picker v-model="Form.orderDateRange"/>
                                </el-form-item>
                                <el-form-item label='招标地区'>
                                    <!-- <el-cascader clearable :options="areas" show-all-levels placeholder='请选择地区'
                                                v-model='area' change-on-select></el-cascader>
                                    <div>
                                        <el-select v-if="options.length!=0&&Form.searchRegion"
                                                v-model.trim="Form.searchStreet"
                                                placeholder="请选择街道">
                                            <el-option
                                                    v-for="item in options"
                                                    :key="item.id"
                                                    :label="item.districtName"
                                                    :value="item.id">
                                            </el-option>
                                        </el-select>
                                    </div> -->
                                    <cityCascaderBox v-model='area' :num="4" :showAllLevels="true" :checkStrictly="false" :emitPath="true"/>
                                </el-form-item>
                        </div>
                            <div>
                                <el-button class="ml-30 mb-20" type='info' @click="getList()" icon="el-icon-search">查 询</el-button>
                            </div>
                    </el-form>
                </div>
                <!-- 列表区域 -->
                <div class="pb-30">
                    <el-table :data="List" class='table-default'>
                        <el-table-column label='招标名称' min-width="100" align="center"  class-name="plName">
                            <template slot-scope="scope">
                                <span :title="scope.row.name">{{scope.row.buyerId}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label='商品名称' min-width="150" align="center">
                            <template slot-scope="scope">
                                <span :title="scope.row.skuName">{{scope.row.buyerName}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label='招标时间' min-width="80" align="center">
                            <template slot-scope="scope">
                                <span>{{scope.row.bidDateFrom | timenData}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label='招标地区' min-width="140" align="center">
                            <template slot-scope="scope">
                                <span :title="scope.row.allDistrictName">{{scope.row.allArea}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label='招标名额' min-width="70" align="center">
                            <template slot-scope="scope">
                                <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{scope.row.limitBitNum}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label='竞选数量' min-width="70"align="center">
                            <template slot-scope="scope">
                                <span v-if="!scope.row.bidderCount">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0</span>
                                <span v-else>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{scope.row.bidderCount}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label='中标商户' min-width="80"align="center">
                            <template slot-scope="scope">
                                <span v-if="!scope.row.contractTraderName">-</span>
                                <span v-else>{{scope.row.contractTraderName}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label='状态' min-width="60" align="center">
                            <template slot-scope="scope">
                                <span v-if="scope.row.state==0">未开始</span>
                                <span v-if="scope.row.state==1">进行中</span>
                                <span v-if="scope.row.state==2">已完成</span>
                                <span v-if="scope.row.state==-1">已删除</span>
                            </template>
                        </el-table-column>
                        <el-table-column label='操作' min-width="100" align="center">
                            <template slot-scope="scope">
                                <span class="button_text cursor" type='text' @click="toDetail(scope)">查看</span>
                                <span class="button_text cursor" v-if="scope.row.state==0||scope.row.state==1"
                                      type='text'
                                      @click="upDate(scope)">编辑
                                </span>
                                <span class="button_text cursor" v-if="scope.row.state==0||scope.row.state==1"
                                      type='text'
                                      @click="deleteJicai(scope)">删除
                                </span>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-pagination class="tar mt-20"
                                   v-if="List.length>0"
                                   :current-page="currentPage"
                                   :page-size='10'
                                   :total='total'
                                   layout="prev , pager, next, jumper"
                                   @current-change="handleCurrentChange">
                        <!-- 分页组件 -->
                    </el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {axiosApi, basePath, baseURI} from "../../api/api";
    // import YsDaterangePicker from "~/components/ys-daterange-picker";

    export default {
        // components: {YsDaterangePicker},
        data() {
            return {
                areas: [], //全国各省
                tabList: [{
                    label: '全部',
                    type: 1,
                    bage: 0
                }, {
                    label: '未开始',
                    type: 2,
                    bage: 0
                }, {
                    label: '进行中',
                    type: 3,
                    bage: 0
                }, {
                    label: '已完成',
                    type: 4,
                    bage: 0
                }, {
                    label: '已删除',
                    type: 5,
                    bage: 0
                }],
                isSonCompany: false,
                state: 1,
                currentPage: 1,
                total: 0,
                List: [],
                options: [],
                area: [],
                Form: {
                    traderName: null,//中标商户
                    traderAccount: null,//中标商户账号
                    goodsName: null,//商品名称
                    searchRegion: null,//招标地区
                    goodsNo: null,//商品编码
                    searchProvince: null,
                    searchCity: null,
                    searchStreet: null,
                    orderDateRange: [],   //gmtOrderStart下单开始时间 gmtOrderEnd下单结束时间
                },

            }
        },
        //进入加载
        mounted() {
            this.$nextTick(function () {
                if (sessionStorage.getItem('jicaiListType')) {
                    this.state = Number(sessionStorage.getItem('jicaiListType'));
                    sessionStorage.removeItem("jicaiListType");
                }
                // this.setAreas()
                this.getList()
                this.getBage()
            })
        },
        watch: {
            area: function (val) {
                this.Form.searchProvince = this.area[0];
                this.Form.searchCity = this.area[1];
                this.Form.searchRegion = this.area[2];
                this.Form.searchStreet = this.area[3];
            },
        },
        methods: {
            getBage() {//获取角标
                axiosApi('/trader/bid/count/seller', {}, (res) => {
                    this.tabList.forEach(item => {
                        if (item.type == 1) {
                            item.bage = res.data.data.notStart + res.data.data.started + res.data.data.complete + res.data.data.delete
                        } else if (item.type == 2) {
                            item.bage = res.data.data.notStart
                        } else if (item.type == 3) {
                            item.bage = res.data.data.started
                        } else if (item.type == 4) {
                            item.bage = res.data.data.complete
                        } else if (item.type == 5) {
                            item.bage = res.data.data.delete
                        }
                    })
                }, 1, 10)
            }
            ,
            getList() {
                let state_ = null;
                switch (this.state) {
                    case 1:
                        state_ = null;
                        break;
                    case 2:
                        state_ = 0;
                        break;
                    case 3:
                        state_ = 1;
                        break;
                    case 4:
                        state_ = 2;
                        break;
                    case 5:
                        state_ = -1;
                        break;
                }
                let bidDateSearchFrom = !this.Form.orderDateRange[0] ? null : this.Form.orderDateRange[0].getTime()
                let bidDateSearchTo = !this.Form.orderDateRange[1] ? null : this.Form.orderDateRange[1].getTime()
                axiosApi('/trader/bid/list/seller', {
                    state: state_,//状态(-1:已删除，0:未开始，1:进行中，2:已完成)
                    successBidderAccount: this.Form.traderAccount ? this.Form.traderAccount : null,//中标用户名
                    successBidderName: this.Form.traderName ? this.Form.traderName : null,//中标商户名
                    searchProvince: this.Form.searchProvince ? this.Form.searchProvince : null,//招标省
                    searchCity: this.Form.searchCity ? this.Form.searchCity : null,//招标市
                    searchRegion: this.Form.searchRegion ? this.Form.searchRegion : null,//招标区
                    searchStreet: this.Form.searchStreet ? this.Form.searchStreet : null,//招标街道
                    bidDateSearchFrom: bidDateSearchFrom ? bidDateSearchFrom : null,//招标时间开始
                    bidDateSearchTo: bidDateSearchTo ? bidDateSearchTo : null,//招标时间结束
                    skuName: this.Form.goodsName ? this.Form.goodsName : null,//商品名称
                    skuNo: this.Form.goodsNo ? this.Form.goodsNo : null,//商品编码
                }, (res) => {
                    this.List = res.data.data.elements ? res.data.data.elements : []
                    this.List.forEach(item => {
                        if (item.name.length> 16) {
                            item.buyerId = item.name.substring(0, 14) + "...";
                        } else {
                            item.buyerId = item.name;
                        }
                        if (item.skuName.length > 20) {
                            item.buyerName = item.skuName.substring(0, 18) + "...";
                        } else {
                            item.buyerName = item.skuName;
                        }
                        item.allDistrictName = this.changeArea(item.allArea);
                        if (item.allDistrictName.length > 9) {
                            item.allArea = item.allDistrictName.substring(0, 7) + "...";
                        } else {
                            item.allArea = item.allDistrictName;
                        }
                    })
                    this.total = res.data.data.totalCount
                }, this.currentPage, 10, (res) => {
                })
            }
            ,
            checkState(val) {//切换状态
                this.state = val.type
                this.currentPage = 1
                this.getList()
            }
            ,
            handleCurrentChange(val) {//分页
                this.currentPage = val
                this.getList()
            }
            ,
            toDetail(scope) {//跳转团购详情
                this.$router.push({path: 'jicaiDetail', query: {id: scope.row.id}})
                sessionStorage.setItem('jicaiListType', this.state);
            }
            ,
            toCreate() {
                this.$router.push({path: 'jicaiCreate'})
                sessionStorage.setItem('jicaiListType', this.state);
            }
            ,
            deleteJicai(scope) {
                this.$confirm("将删除这条招标, 是否继续?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                }).then(() => {
                    axiosApi('/trader/bid/delete', {
                        id: scope.row.id,//状态(-1:已删除，0:未开始，1:进行中，2:已完成)
                    }, (res) => {
                        this.$message({
                            message: "删除成功!",
                        });
                        this.getList();
                        this.getBage();
                    }, this.currentPage, 10, (res) => {

                    })
                }).catch(() => {
                    this.$message({
                        message: "已取消删除",
                    });
                });
            },
            changeArea(val) {
                let areaString = this.resolveValueToText_4level(val)
                return areaString;
            },
            upDate(scope) {
                this.$router.push({path: 'jicaiCreate', query: {id: scope.row.id}})
                sessionStorage.setItem('jicaiListType', this.state);
            },
        }
    }
</script>

<style lang="less">
    #jicaiList {
        .table-default .el-button {
            margin-left: 10px;
        }

        .tab_box {
            height: 60px;
            line-height: 60px;
            box-sizing: border-box;
            border-bottom: 1px solid #eee;

            .tab_item {
                display: inline-block;
                width: 114px;
                height: 26px;
                line-height: 26px;
                text-align: center;
                box-sizing: border-box;
                color: #666;
                font-size: 14px;
                cursor: pointer;
            }

            .tab_item:hover {
                color: #FF4201;
            }

            .tab_item_checked {
                border-radius: 2px;
                border: 1px solid #FF4201;
                color: #FF4201;
            }
        }

        .title_top {
            width: 100%;
            height: 60px;
            line-height: 60px;
            box-sizing: border-box;
            padding-left: 20px;
            font-size: 16px;

            .button_top {
                width: 118px;
                height: 30px;
                line-height: 30px;
                border: 1px solid #DDDDDD;
            }
        }

        .input_short {
            width: 100px;
        }

        .button_text {
            width: 28px;
            height: 14px;
            font-size: 14px;
            font-family: STHeitiSC-Light;
            font-weight: 300;
            color: #20a0ff;
            line-height: 14px;
        }

        .table-default.el-table .cell {
            /*text-align: left !important;*/
        }
        .plName .cell {
            margin-left: 30px!important;
        }
    }
</style>
