<template>
    <div class="addBrandBox"> 
        <el-dialog title="新增品牌" :visible.sync="dialogVisible" @close="handleClose" width="920px">
            <el-form :inline="true" :model="searchBrand" label-width="80px" class="clearfix" :style="{width:'850px'}">
                <div class="fl">
                    <el-form-item v-if="isSetBrand" label="分类:">
                        <el-select v-model='searchBrand.classChened' placeholder='请选择' clearable >
                            <el-option :key="item.id" v-for="(item) in classList" 
                                :label="item.classifyName" :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="品牌名称:">
                        <el-input placeholder="请输入品牌名称" v-model="searchBrand.brandName" @keyup.enter.native="handleSearch"></el-input>
                    </el-form-item>
                </div>
                <el-button type="primary" class="fl ml-50" @click="handleSearch">查询</el-button>
            </el-form>
            <div class="addBrandDialogBox">
                <div class="tableListBox">
                    <el-table @selection-change="handleSelectionChange" :data="brandList" border tooltip-effect="dark" stripe class="mt-20 table-default">
                        <el-table-column
                        type="selection"
                        width="55">
                        </el-table-column>
                        <el-table-column label="品牌图片">
                            <template slot-scope="scope">
                                <img class="bd" :src="scope.row.logoIcon" v-if="scope.row.logoIcon" />
                                <img class="bd" src="./../../assets/brand.png" v-else />
                            </template>
                        </el-table-column>
                        <el-table-column prop="brandName" label="品牌名称"></el-table-column>
                        <el-table-column prop="relatedClassify" label="分类">
                            <template slot-scope="scope">
                                <span v-if="isSetBrand">{{scope.row.relatedClassify || '--'}}</span>
                                <span v-else>{{scope.row.classifyName || '--'}}</span>
                            </template>
                        </el-table-column>
                    </el-table>
                    <div class="pageBox">
                        <el-pagination class="tar" v-if='total>10'
                            :current-page="currentPage"
                            :page-size='10'
                            :total='total'
                            layout="prev, pager, next, jumper"
                            @current-change="handlePageChange">
                        </el-pagination>
                    </div>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="handleClose">取 消</el-button>
                <el-button type="primary" @click="handleSubmit">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { axiosApi, basePath } from "../../api/api";
import {mapMutations, mapActions} from 'vuex';

export default {
    props:[ 'brandDialog','isSetBrand' ],
    data: function() {
        return ({
            dialogVisible : false,
            // 分类 列表
            classList : [],
            // 品牌列表
            brandList : [],
            currentPage : 1,
            total : 0,
            // 保存当前 选中的数据
            multipleSelection : [],
            searchBrand: {
                classChened: null,
                brandName: null
            }
        })
    },
    computed: {
        notId(){
            return this.$store.getters.notId
        }
    },
    watch:{
        dialogVisible(val){
            this.dialogVisible = val;
            if(val){
                this.searchBrand = {
                        classChened: null,
                        brandName: null
                    }
                if(this.currentPage == 1){
                    this.getBrands();
                }else{
                   this.currentPage = 1; 
                } 
            } 
        }
    },
    mounted: function() {
        if(this.isSetBrand) this.init();
    },
    methods: {
        ...mapMutations({
          'setBrandList': 'setBrandList',
        }),
        show : function(){
            this.dialogVisible = true;
        },
        init : function(){
            // 获取所有分类
            axiosApi("/goods/classifyTopList/select",{},(res) => {
                let data = res.data.data;
                this.classList = data;
            });	
        },
        // 根据分类， 品牌名称 查询 对应品牌
        getBrands : function(){
            const api = this.isSetBrand ? '/goods/classifyBrandListQuery/select' : '/trader/traderBrand/select';
            axiosApi(api ,{
                traderId: this.isSetBrand ? null : sessionStorage.getItem('traderId'),
                classifyId: this.searchBrand.classChened,
                brandName: this.searchBrand.brandName,
                selectedBrandIdList: this.isSetBrand ? [] : this.notId,
                isPage: 1
            },(res) => {
                let data = res.data.data;
                this.brandList = data.elements;
                this.total = data.totalCount;
            },this.currentPage,10);	
        },
        // 点击 搜索
        handleSearch : function(){
            if(this.currentPage == 1){
                this.getBrands();
            }else{
               this.currentPage = 1; 
            }
        },
        // 分页change
        handlePageChange : function(val){
            this.currentPage = val;
            this.getBrands();
        },
        // 表格多选 change
        handleSelectionChange : function(val){
            this.multipleSelection = val;
        },
        // 浮层点击确定
        handleSubmit : function(){
            // 获取 品牌ids
            let brandList = [];
            this.multipleSelection.map(v => {brandList.push({
                brandId: this.isSetBrand ? v.id : v.brandId,//品牌维护需要的是id，客户编辑需要brandId
                brandName: v.brandName,
                classifyName: v.classifyName,
                logoIcon: v.logoIcon
            })});
            if(this.isSetBrand){//如果是品牌维护页面
                if(brandList.length === 0 ){
                    this.dialogVisible = false;
                    return;
                }
                axiosApi('/trader/traderBrand/insert',{
                    traderId: sessionStorage.traderId,
                    brandList: brandList,
                },(res) => {
                    let data = res.data.data;
                    this.$message.success('添加成功');
                    this.$emit('close');
                    this.dialogVisible = false;
                },1,10,(err) => {
                    this.$message.warning(err.data.message);
                });
            }else{
                this.setBrandList(brandList);
                this.dialogVisible = false;
            }
        },
        // 浮层点击取消
        handleClose : function(){
            this.dialogVisible = false;
        },
    },
}
</script>

<style lang="less">

.addBrandBox {
    //* 重写默认GR 的浮层样式 */
    .el-dialog__wrapper{
        .el-dialog{
            min-height: initial!important;
            width: 950px;
        }
        .el-dialog__body{
            padding: 20px 30px;
            padding-bottom: 100px;
        }
        .el-button--default{
            margin-left : 0;
        }
    }
}

</style>

