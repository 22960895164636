<template>
  <div class="stock-house-ware bg-f pb-50">
    <div class="clearfix pt-30 pl-30">
        <el-form
          :inline="true"
          :model="formInlineData"
          label-width="90px"
          class="fl width-80p"
        >
          <el-form-item :label="text+'单号'">
            <el-input v-model.trim="formInlineData.stockOrderNo"></el-input>
          </el-form-item>
          <el-form-item :label="text+'类型'">
            <el-select v-model="formInlineData.type" clearable>
              <el-option
                :label="item.label"
                :value="item.value"
                v-for="(item, index) in whichTypes"
                :key="index"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="text+'仓库'">
            <el-select v-model="formInlineData.warehouseId" clearable>
              <el-option
                :label="item.name"
                :value="item.id"
                v-for="(item, index) in warehouse"
                :key="index"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="供应商名称" v-if="operateType == 1">
            <el-input v-model.trim="formInlineData.sellerName"></el-input>
          </el-form-item>
          <el-form-item label="客户名称" v-if="operateType == 0">
            <el-input v-model.trim="formInlineData.buyerName"></el-input>
          </el-form-item>
          <el-form-item v-if="operateType == 0" label="关联订单号" >
            <el-input v-model.trim="formInlineData.orderSn"></el-input>
          </el-form-item>
        </el-form>
        <el-button type="info" class="fl ml-30" @click="search">搜索</el-button>
    </div>

    <div>
      <el-table
        :data="warehouseData"
        tooltip-effect="dark"
        class="ml-30 mr-30 mt-20 table-default"
        stripe
        v-loading="loading"
      >
        <el-table-column label="单据类型">
          <template slot-scope="scope">
              <div v-if="operateType == 0">
                    <span v-if="scope.row.stockOutType=='other'">其他出库</span>
                    <span v-if="scope.row.stockOutType=='orders'">销售出库</span>
                    <span v-if="scope.row.stockOutType=='ordersLock'">订单锁库</span>
                    <span v-if="scope.row.stockOutType=='exchange'">换货出库</span>
              </div>
              <div v-else>
                    <span v-if="scope.row.stockInType=='other'">其他入库</span>
                    <span v-if="scope.row.stockInType=='purchase'">采购入库</span>
                    <span v-if="scope.row.stockInType=='returned'">退货入库</span>
                    <span v-if="scope.row.stockInType=='exchange'">退换入库</span>
              </div>
          </template>
        </el-table-column>
        <el-table-column :label="text+'单号'" min-width="180" prop="stockOrderNo"></el-table-column>
        <el-table-column :label="operateType == 1 ? '供应商名称' : '客户名称'" min-width="150">
          <template slot-scope="scope">
            <span v-if="operateType == 0">{{scope.row.buyerName}}</span>
            <span v-else>{{scope.row.sellerName}}</span>
          </template>
        </el-table-column>
        <el-table-column :label="text+'仓库'" min-width="120" prop="warehouseName"></el-table-column>
        <el-table-column :label="text+'日期'" min-width="150">
          <template slot-scope="scope">
            <span v-if="scope.row.gmtCreate">{{scope.row.gmtCreate | time}}</span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column label="操作人" min-width="100" prop="employeeName"></el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="toDetail(scope.row)">详情</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        class="tar mr-30"
        :current-page="currentPage"
        v-if="total>0"
        :page-size="10"
        :total="total"
        layout="prev , pager, next, jumper"
        @current-change="handleCurrentChange"
      ></el-pagination>
    </div>
  </div>
</template>
<script>
	import { axiosApi, basePath } from "../../api/api";

export default {
  name: 'stockhousewarecom',
  data() {
    return {
      warehouse: [],
      loading: false,
      currentPage: 1,
      total: 0,
      formInlineData: {
        type: "",
        stockOrderNo: "",
        warehouseId: '',
        orderSn: '',
        buyerName: '',
        sellerName: ''
      },
      warehouseData: [],
      inType: [
        { value: 'purchase', label: "采购入库" },
        { value: 'returned', label: "退货入库" },
        { value: 'other', label: "其他入库" }
      ],
      outType: [
        { value: 'orders', label: "销售出库" },
        { value: 'other', label: "其他出库" },
      ]
    };
  },
  computed: {
    text() {
      let text = this.operateType == 1 ? "入库" : "出库";
      return text;
    },
    whichTypes() {
      let text = this.operateType == 1 ? this.inType : this.outType
      return text;
    },
    paramObj() {
      let obj = {
        stockType: this.operateType === '1' ? 'stockIn' : 'stockOut',
        stockOrderNo: this.formInlineData.stockOrderNo,
        warehouseId: this.formInlineData.warehouseId,
      }
      if(this.operateType === '1') {
        obj.stockInType = this.formInlineData.type
        obj.sellerName = this.formInlineData.sellerName
      } else {
        obj.stockOutType = this.formInlineData.type
        obj.buyerName = this.formInlineData.buyerName
        obj.orderSn = this.formInlineData.orderSn
      }
      return obj;
    }
  },
  //type 1 入库 0 出库
  props: {
    operateType: {
      type: String,
      default: ''
    }
  },
  methods: {
    getWareHouseList() {
      axiosApi('/warehouse/info/list',{},(res)=>{
        this.warehouse =  res.data.data ? res.data.data : []
      })
    },
    search() {
      if(this.currentPage != 1) {
        this.currentPage = 1;
      } else {
        this.getStockList()
      }
    },
    getStockList() {
      this.loading = true;
      // stockType 出入库类型 入库stockIn 出库stockOut
      axiosApi('/warehouse/deliveryVoucher/page', this.paramObj, res => {
        this.loading = false;
        this.warehouseData = res.data.data ? res.data.data.elements : [];
        this.total = res.data.data ? res.data.data.totalCount : 0;
        this.currentPage = res.data.data ? res.data.data.pageIndex : 1;
      },this.currentPage,10,err => {
        this.loading = false;
        this.$message.error(err.data.message)
      })
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getStockList()
    },
    toDetail(row) {
      let myurl = basePath + '/stockHouseOrderDetail' + '/' + this.operateType + '/' + row.stockOrderNo
      this.$router.push({ path: myurl });
    }
  },
  mounted() {
    this.getWareHouseList()
    if(this.operateType != '') {
      this.formInlineData.value = '';
      this.formInlineData.stockOrderNo = '';
      this.formInlineData.orderSn = '';
      if(this.currentPage == 1) {
        this.getStockList();
      } else {
        this.currentPage = 1;
      }
    }
  }
};
</script>
