        
<template>
  <!--久集采购订单-->
  <div id="storePurStockOrder" class="content">
    <div class="title-top bg-f clearfix">
      <div>采购进货单</div>
    </div>
    <div class="content mt-5 bg-f pl-30 pr-30 pb-40">
      <div style="position: relative;">
        <el-tabs v-model="typec" @tab-click="handleTabs">
          <el-tab-pane
            :label="item"
            :name="index + ''"
            v-for="(item, index) in dataTabs"
            :key="index"
          ></el-tab-pane>
        </el-tabs>
        <!--未付款标记-->
        <el-badge class="notPayCountmark" :max="99" :value="badge.notPayCount" v-if="badge.notPayCount!=0"/>
        <!--待发货标记-->
        <el-badge class="notDeliveryCount" :max="99" :value="badge.notDeliveryCount" v-if="badge.notDeliveryCount!=0"/>
        <!--待收货标记-->
        <el-badge class="orderDeliveriedCount" :max="99" :value="badge.orderDeliveriedCount"  v-if="badge.orderDeliveriedCount!=0"/>
      </div>

      <div class="clearfix search">
        <el-form :inline="true" class="pt-30" :model="form" ref="searchForm">
          <el-form-item
            label="订单编号"
            prop="orderSn"
            :rules="[{pattern:/^\d{16,20}$/,message:'请输入正确订单编号',trigger:'blur'}]" class="ml-20"
          >
            <el-input v-model.trim="form.orderSn" placeholder="请输入订单编号" :maxlength="20"></el-input>
          </el-form-item>
          <el-form-item label="商品名称" class="ml-20">
            <el-input v-model.trim="form.itemName" :maxlength="30" placeholder="请输入商品名称"></el-input>
          </el-form-item>
          <el-form-item label="下单时间" class="ml-20">
            <ys-daterange-picker v-model="form.orderDateRange" :endDisabledDate="new Date()" />
          </el-form-item>
        </el-form>
        <div>
          <el-button class="ml-20" type="info" @click="handleCheck" icon="el-icon-search">查 询</el-button>
        </div>
      </div>
      <div class="tar clearfix">
        <!--<el-button type='text' class="fr" @click="exportExcel">批量导出</el-button>-->
      </div>
      <div class="order-list" v-loading="loading">
        <el-checkbox v-model="checkAll" @change="handleCheckAllChange" class="checkedAll"></el-checkbox>
        <el-table>
          <el-table-column label min-width="50"></el-table-column>
          <el-table-column label="商品" min-width="190" align="center"></el-table-column>
          <el-table-column label="单价" min-width="120" align="center"></el-table-column>
          <el-table-column label="数量" min-width="120" align="center"></el-table-column>
          <el-table-column label="支付金额" min-width="120" align="center"></el-table-column>
          <el-table-column label="交易状态" min-width="120" align="center"></el-table-column>
          <el-table-column label="操作" align="center" min-width="120"></el-table-column>
        </el-table>
        <div class="table-item-default tac" v-if="ordersListResVo.length<=0">
          <img src="../../../assets/empty.png" alt />
          <div class="color-danger mt-30">
            <router-link :to="{path:'/customer/shop/shopIndex'}">
              <span class="color-danger">啥都没有，去首页看看 ></span>
            </router-link>
          </div>
        </div>
        <div v-else>
          <el-checkbox-group v-model="checkOrder" @change="handleCheckChange">
            <div class="table-item mt-10" v-for="(order, index) in ordersListResVo" :key="index">
              <div class="clearfix table-item-top lh-20 bbd bg-e" v-if="order.adjustingGoods == 1">
                <el-badge value="调货" class="fl ml-70 my-badge"></el-badge>
                <span class="fl ml-10">{{order.headName}}</span>
              </div>
              <div class="clearfix table-item-top lh-20 bbd bg-e">
                <el-checkbox class="fl" :label="order"></el-checkbox>
                <span class="fl">订单编号:{{order.sn}}</span>
                <span class="fl color-9 ml-20">下单时间:{{order.gmtCreate | time}}</span>
                <!-- <span class='fr color-6 mr-20' v-if="order.orderStatus==1">还剩<span class="color-danger">{{order.remainAutoCancelTime}}</span>自动取消</span> -->
                <span class="fr mr-40">{{order.sellerName}}</span>
                <span class="fr mr-20">卖家手机：{{order.sellerPhone}}</span>
              </div>
              <div class="table-item-content clearfix bbd" style="display: flex;">
                <div class="clearfix" style="box-sizing: border-box;flex:4">
                  <!--商品清单-->
                  <div
                    class="bb clearfix"
                    v-for="orderItem in order.orderItemVos"
                    :key="orderItem.id"
                    style="padding: 20px 0 20px 20px;display:flex;box-sizing:border-box"
                  >
                    <div class="fl specialD" style="flex:2">
                      <img v-if="orderItem.image" :src="orderItem.image" class="fl mr-20" />
                      <img v-else src="../../../assets/goods.jpg" class="fl mr-20" />
                      <div class="row-item-1">
                        <p class="bw">{{orderItem.skuName}}</p>
                        <p class="color-9">规格:1*{{orderItem.specInfoNum}}</p>
                      </div>
                    </div>
                    <div class="fl tac" style="flex:1">¥{{orderItem.discountPrice | fmoney}}</div>
                    <div class="fl tac" style="flex:1">{{orderItem.num}}{{orderItem.unit}}</div>
                  </div>
                </div>
                <div class="bl bb tac pt-20" style="flex:1">
                  <div class="tac">¥{{order.needPayMoney | fmoney}}</div>
                  <div class="tac">（含运费：¥{{order.freightMoney | fmoney}}）</div>
                </div>
                <div class="bl bb tac pt-20" style="flex:1">
                  <span v-if="order.orderStatus==0">已关闭</span>
                  <span v-if="order.orderStatus==1">待付款</span>
                  <span v-if="order.orderStatus==2">待发货</span>
                  <span v-if="order.orderStatus==3">已发货</span>
                  <span v-if="order.orderStatus==4">已完成</span>
                </div>
                <div class="bl bb tac pt-20" style="flex:1">
                  <el-button type="text" class="ml-0" @click="purchaseOrderDetail(order.sn)">订单详情</el-button>
                </div>
              </div>
            </div>
          </el-checkbox-group>
        </div>
        <!-- 分页组件 -->
        <el-pagination
          v-if="total>0"
          class="tar mt-20"
          :current-page.sync="currentPage"
          :page-size="10"
          :total="total"
          layout="prev , pager, next, jumper"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </div>
    </div>
    <!--导出-->
    <form ref="exportList" action="/sunrise-gateway/export/superc/order" style="display: none">
      <input type="text" name="startRow" v-model="a" />
      <input type="text" name="startCol" v-model="a" />
      <input type="text" v-if="form.sellerId" name="sellerId" v-model="form.sellerId" />
      <input type="text" v-if="form.orderSn" name="orderSn" v-model="form.orderSn" />
      <input type="text" v-if="form.itemName" name="itemName" v-model="form.itemName" />
      <input type="text" v-if="form.orderStatus" name="orderStatus" v-model="form.orderStatus" />
      <input
        type="text"
        v-if="form.supercStoreId"
        name="supercStoreId"
        v-model="form.supercStoreId"
      />
      <input
        type="text"
        v-if="form.gmtOrderStart"
        name="gmtOrderStart"
        v-model="form.gmtOrderStart"
      />
      <input type="text" v-if="form.gmtOrderEnd" name="gmtOrderEnd" v-model="form.gmtOrderEnd" />
      <input type="text" v-if="idStr" name="idStr" v-model="idStr" />
      <input type="text" name="fileName" v-model="fileName" />
      <input type="text" name="modelName" v-model="modelName" />
      <input type="text" name="payMethod" v-model="form.payMethod" />
    </form>
  </div>
</template>

<script>
import { axiosApi, basePath } from "../../../api/api";
// import YsDaterangePicker from "~/components/ys-daterange-picker";

export default {
  // components: {YsDaterangePicker},
  data() {
    return {
      typec: "0",
      dataTabs: ["所有订单", "待付款", "待发货", "待收货", "已完成", "已关闭"],
      data: ["1"],
      cancelOrderVisible: false, //取消订单弹框
      reason: null,
      ordersListResVo: [], //订单数据
      orderSn: null, //订单编号
      idStr: [], //批量导出选中列表
      fileName: null, //导出名称
      a: 0, //固定值 写死
      modelName: "superc.order",
      form: {
        sellerId: sessionStorage.traderId,
        orderSn: null, //订单编号
        itemName: null, //商品名称
        orderStatus: null, //订单状态
        supercStoreId: null, //久集主键Id
        // startTime: null,
        // endTime: null,
        orderDateRange: [], //gmtOrderStart下单开始时间 gmtOrderEnd下单结束时间
        gmtOrderStart: null,
        gmtOrderEnd: null,
        traderType: "1"
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > new Date();
        }
      }, //禁选未来时间
      rules: {
        cancleReason: [
          {
            max: 30,
            message: "不能超过30个字！"
          }
        ]
      },
      clearTime: 1, //定时器
      loading: false,
      currentPage: 1,
      total: null,
      badge: {
        //标记
        notPayCount: 0, //未付款
        notDeliveryCount: 0, //已付款-待发货
        orderDeliveriedCount: 0, //已发货-待收货
        successCount: 0, //已完成
        cancleCount: 0, //已取消
        notAuditCount: 0 //未审核
      },
      checkAll: false, //选中状态
      checkOrder: [], //选中的每行订单数据
      sendGoods: false, //发货弹框
      expressList: [], //物流列表
      voucherImgList: [], // 凭证图片 list
      bankInfo: {}, // 凭证 银行信息
      voucherId: "", // 审核 凭证 id
      viewredentialsType: "", // 审核浮层类型 0 - 审核 1 - 详情
      imgviewVisible: false, // 查看凭证 dialog 开关
      divideOrderVisible: false, //拆单dialog
      cancelOrCheck: 0, //默认取消订单为0， 审核凭证为1
      parentOrderSn: null, //主订单parentOrderSn
      rowSupercStoreId: null //点击的item的supercsotreid
    };
  },
  mounted() {
    this.typec = "0";
    this.$nextTick(() => {
      if (this.$route.query.supercStoreId) {
        this.form.supercStoreId = this.$route.query.supercStoreId;
        this.typec = this.$route.query.type;
        this.form.orderStatus = this.$route.query.type;
      }
      this.queryOrderList(this.form); //默认加载订单
    });
  },
  methods: {
    anonOrderCount() {
      //订单统计(角标)
      axiosApi(
        "/superc/hotelOrder/count",
        {
          sellerId: sessionStorage.traderId
        },
        res => {
          this.badge = res.data.data;
        },
        1,
        10,
        res => {}
      );
    },
    handleCurrentChange(val) {
      //分页
      this.currentPage = val;
      this.queryOrderList(this.form);
    },
    handleTabs(e) {
      this.typec = e.name;
      this.form = {
        orderSn: null, //订单编号
        itemName: null, //商品名称
        orderStatus: null, //订单状态
        supercStoreId: null, //久集主键Id
        startTime: null,
        endTime: null,
        orderDateRange: [], //gmtOrderStart下单开始时间 gmtOrderEnd下单结束时间
        gmtOrderStart: null,
        gmtOrderEnd: null,
        traderType: "1"
      };
      this.currentPage = 1;
      if (this.typec == "0") {
        //全部订单
      } else if (this.typec == "1") {
        //待付款
        this.form.orderStatus = 1;
      } else if (this.typec == "2") {
        //待发货
        this.form.orderStatus = 2;
      } else if (this.typec == "3") {
        //待收货
        this.form.orderStatus = 3;
      } else if (this.typec == "4") {
        //已完成
        this.form.orderStatus = 4;
      } else if (this.typec == "5") {
        //已关闭
        this.form.orderStatus = 0;
      }
      this.form.sellerId = sessionStorage.traderId;
      this.currentPage = 1;
      this.queryOrderList(this.form);
    },

    handleCheck() {
      ///订单条件查询

      this.form.gmtOrderStart = this.form.orderDateRange[0]
        ? this.form.orderDateRange[0].getTime()
        : null;
      this.form.gmtOrderEnd = this.form.orderDateRange[1]
        ? this.form.orderDateRange[1].getTime()
        : null;
      //订单编号表单验证
      this.$refs.searchForm.validate(valid => {
        if (valid) {
          if (this.currentPage != 1) {
            this.currentPage = 1;
            return;
          }
          this.queryOrderList(this.form);
        }
      });
    },
    queryOrderList(body) {
      /*久集订单列表*/
      this.loading = true;
      axiosApi(
        "/superc/order/web/tenantshopPurchaseOrder",
        body,
        res => {
          this.loading = false;
          this.total = res.data.data.totalCount;
          this.ordersListResVo = res.data.data.elements;
          /*定时器显示*/
          // this.ordersListResVo.forEach((item, index) => {
          // 	if(item.remainAutoCancelTime != null && item.remainAutoCancelTime > 0) {
          // 		let time = parseInt(item.remainAutoCancelTime / 1000) //转化为整数
          // 		item.remainAutoCancelTime = this.timeString(time) //转化为时间格式
          // 		item.clearTime = null //定义变量
          // 		item.clearTime = setInterval(() => {
          // 			time--
          // 			item.remainAutoCancelTime = this.timeString(time)
          // 			if(time <= 0) {
          // 				clearTimeout(item.clearTime)
          // 				//this.cancelOrder({orderSn:item.sn,cancleType:3})  //系统取消
          // 				if(item.orderStatus == 1) item.orderStatus = 0
          // 				this.$set(this.ordersListResVo, index, item)
          // 			}
          // 		}, 1000)
          // 	} else {
          // 		if(item.orderStatus == 1) item.orderStatus = 0
          // 		this.$set(this.ordersListResVo, index, item)
          // 	}
          // })
        },
        this.currentPage,
        10,
        res => {
          this.loading = false;
          this.$message({
            type: "error",
            message: res.data.message,
            duration: 1000
          });
        }
      );
    },

    handleCheckAllChange(val) {
      /*table全选*/
      if (this.checkAll) {
        this.ordersListResVo.forEach(item => {
          this.checkOrder.push(item);
          this.idStr.push(item.id);
        });
      } else {
        this.checkOrder = [];
        this.idStr = [];
      }
    },

    handleCheckChange(val) {
      //单选
      let checkedCount = val.length;
      this.checkAll = checkedCount === this.ordersListResVo.length;
      this.idStr = [];
      val.forEach(item => {
        this.idStr.push(item.id);
      });
    },
    exportExcel() {
      //批量导出
      this.form.gmtOrderStart = this.form.orderDateRange[0]
        ? this.form.orderDateRange[0].getTime()
        : null;
      this.form.gmtOrderEnd = this.form.orderDateRange[1]
        ? this.form.orderDateRange[1].getTime()
        : null;
      let str =
        new Date().getFullYear() +
        "-" +
        (new Date().getMonth() + 1) +
        "-" +
        new Date().getDate();
      this.fileName = "订单列表_" + str + ".xls";

      setTimeout(() => {
        if (this.ordersListResVo.length > 0) {
          this.$refs.exportList.submit();
        } else {
          this.$message.warning("暂无数据，无法导出");
        }
      }, 0);
    },
    purchaseOrderDetail(orderSn) {
      //去采购单详情页面
      let type = "jiuji";
      sessionStorage.setItem("orderSn", orderSn);
      window.open(basePath + "/ccPurchaseOrderDetail?type=" + type + '&showSalesCommission=1', "_blank");
    }
  }
};
</script>

<style lang="less">
#storePurStockOrder {
  position: relative;
  box-sizing: border-box;
  .color-blue {
    cursor: pointer;
    color: #20a0ff;
  }
  .specialD {
    display: flex;
    justify-content: space-between;
    .row-item-1 {
      width: 240px;
      height: auto;
    }
  }
  .bw {
    word-wrap: break-word;
  }
  .el-tabs__active-bar {
    min-width: 74px !important;
  }
  .el-tabs__header {
    padding-top: 10px;
    padding-left: 0;
  }
  .el-checkbox__label {
    display: none;
  }
  .notPayCountmark {
    position: absolute;
    left: 137px;
    top: 10px;
    z-index: 10000;
  }
  .notDeliveryCount {
    position: absolute;
    left: 218px;
    top: 10px;
    z-index: 10000;
  }
  .orderDeliveriedCount {
    position: absolute;
    left: 300px;
    top: 10px;
    z-index: 10000
  }
  .hide {
    display: none;
  }
  .content {
    .el-checkbox {
      width: 40px !important;
    }
    .el-date-editor--daterange.el-input {
      width: 240px;
    }
    .el-form--inline {
      .el-form-item__label {
        font-size: 12px;
        width: 60px;
      }
    }
    .el-table .el-table__body-wrapper {
      display: none;
    }
    .order-datatimerange {
      width: 240px;
    }
    .el-table {
      border: none;
      font-size: 14px;
      th > .cell {
        line-height: 12px;
        padding: 10px 0 10px 0;
        text-align: center;
      }
      th.is-leaf {
        border-bottom: 0;
      }
    }
    .checkedAll {
      position: relative;
      top: 30px;
    }
    .el-checkbox {
      width: 70px;
      margin-left: 30px;
      .el-checkbox__input {
        height: 20px;
      }
    }
    .el-checkbox__inner {
      width: 20px;
      height: 20px;
    }
    .el-checkbox__inner::after {
      top: 2px;
    }
    .table-item {
      .bl {
        border-left: 1px solid #ECEEF6;
      }
      border: 1px solid #ECEEF6;
      font-size: 14px;
      border-bottom: none;
      .table-item-content .pbl-20 {
        padding: 0px 0px 20px 20px;
      }
      .table-item-top {
        padding: 10px 0;
        .table-item-top-checkbox {
          margin-left: 14px;
        }
      }
      img {
        width: 80px;
        height: 80px;
      }
    }
    .w100 {
      width: 90px;
    }
    .table-item-default {
      height: 530px;
      padding-top: 120px;
    }
    .el-dialog {
      min-height: 300px;
      .el-form-item__label {
        width: 140px;
      }
    }
    .sendGoods .el-form-item__error {
      margin-left: 140px;
    }
  }
  .ml-70 {
    margin-left: 70px;
  }
  .my-badge {
    .el-badge__content {
      border: 0;
    }
  }

  .freight-dialog {
    .el-form-item__label {
      width: 110px;
    }
    .el-select {
      & > .el-input {
        width: 100px;
      }
    }
  }
  .specialD {
    position: relative;
    .special {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}
</style>