import { axiosApi, basePath } from "./api";

// 删除新闻
export function deleteNews (data={}) {
  return new Promise((resolve, reject) => {
    axiosApi('/official/article/delete', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
}

// 获取新闻详情
export function getNewsDetail (data={}) {
  return new Promise((resolve, reject) => {
    axiosApi('/unlogin/official/article/detail', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
}


// 获取新闻分类列表
export function getNewsClassList (data={}) {
  return new Promise((resolve, reject) => {
    axiosApi('/official/article/classify/list', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
}

// 新增新闻分类
export function addNewsClass (data={}) {
  return new Promise((resolve, reject) => {
    axiosApi('/official/article/classify/add', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
}

// 编辑新闻分类
export function updateNewsClass (data={}) {
  return new Promise((resolve, reject) => {
    axiosApi('/official/article/classify/update', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
}

// 删除新闻分类
export function deleteNewsClass (data={}) {
  return new Promise((resolve, reject) => {
    axiosApi('/official/article/classify/delete', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
}

// 获取官网招聘
export function getRecruitment (data={}) {
  return new Promise((resolve, reject) => {
    axiosApi('/unlogin/official/recruitment/page', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
}

// 添加官网招聘
export function addRecruitment (data={}) {
  return new Promise((resolve, reject) => {
    axiosApi('/official/recruitment/add', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
}

// 修改官网招聘
export function updateRecruitment (data={}) {
  return new Promise((resolve, reject) => {
    axiosApi('/official/recruitment/update', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
}

// 删除官网招聘
export function deleteRecruitment (data={}) {
  return new Promise((resolve, reject) => {
    axiosApi('/official/recruitment/delete', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
}

// 获取轮播图
export function getAdvertisement (data={}) {
  return new Promise((resolve, reject) => {
    axiosApi('/official/advertisement/page', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
}

// 添加轮播图
export function addAdvertisement (data={}) {
  return new Promise((resolve, reject) => {
    axiosApi('/official/advertisement/add', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
}

// 编辑轮播图
export function updateAdvertisement (data={}) {
  return new Promise((resolve, reject) => {
    axiosApi('/official/advertisement/update', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
}

// 删除轮播图
export function deleteAdvertisement (data={}) {
  return new Promise((resolve, reject) => {
    axiosApi('/official/advertisement/delete', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
}


// 详情轮播图
export function infoAdvertisement (data={}) {
  return new Promise((resolve, reject) => {
    axiosApi('/official/advertisement/info', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
}

// 置顶轮播图
export function setTopAdvertisement (data={}) {
  return new Promise((resolve, reject) => {
    axiosApi('/official/advertisement/setTop', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
}
