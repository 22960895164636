<template>
	<div class="store-number">
		<div class="title-tabs bg-f">
			<ul class="clearfix">
				<template v-for='(tab,index) in dataTabs' class="item">
					<li :key="tab.name" :class="type==tab.type?'active':''" @click='handleTabs(tab)'>
						{{tab.name}}
						<div :class="type==tab.type?'under-line':''"></div>
					</li>
					<div class="vertical-line" :key="index+'al'"></div>
				</template>
			</ul>
			<el-badge class="mark" :max="99" :value="notCheck" v-if="notCheck!=0"/>
		</div>
		<div class="bg-f pb-50 pl-30 pr-30">
			<!-- 门店搜索条件 -->
			<el-form :inline="true" label-width="65px" class="clearfix pt-30 mt-5 search">
                <el-form-item label="门店名称:">
                    <el-input v-model.trim="form.storeName" placeholder="请输入门店名称"></el-input>
                </el-form-item>
                <el-form-item label="门店类型:">
										<el-select v-model="form.storeType" clearable placeholder="请选择">
											<el-option
												v-for="item in store"
												:key="item.type"
												:label="item.desc"
												:value="item.type">
											</el-option>
										</el-select>
                    <!-- <el-cascader clearable change-on-select :options="store" v-model="form.storeType" :props="{'label':'text', 'value':'code', 'children': 'sub'}">
                    </el-cascader> -->
                </el-form-item>
                <el-form-item label="所属公司:" v-if="showLabel">
                    <el-input v-model.trim="form.sellerName" placeholder="请输入所属公司"></el-input>
                </el-form-item>
                <el-form-item label="门店编码:">
                    <el-input v-model.trim="form.storeNo" placeholder="请输入门店编码" @input="form.storeNo=form.storeNo.replace(/[\u4e00-\u9fa5]/ig,'')"></el-input>
                </el-form-item>
								<el-form-item label="所属区域">
									<districtSelect v-model="form.districtId" class="mr-10"/>
								</el-form-item>
                <el-form-item label="申请日期:">
                    <ys-daterange-picker v-model="searchTimes"/>
                </el-form-item>
			</el-form>
            <div class="pt-5">
                <el-button type="info" icon="el-icon-search" @click="search">查 询</el-button>
            </div>
			<!--门店列表-->
            <div class="tar">
                <el-button type="text" @click="exportData">导出</el-button>
            </div>
			<el-table :data="tableData" tooltip-effect="dark" stripe class="mt-10 table-default">
				<el-table-column label="门店编码">
					<template slot-scope="scope">
						<p>{{ scope.row.storeNo||"- -" }}</p>
					</template>
				</el-table-column>
				<el-table-column label="K3编码">
					<template slot-scope="scope">
						<p>{{ scope.row.k3No||"- -" }}</p>
					</template>
				</el-table-column>
				<el-table-column prop="storeName" label="门店名称"></el-table-column>
				<el-table-column prop="storeTypeName" label="门店类型"></el-table-column>
				<el-table-column prop="allAddr" label="门店地址"></el-table-column>
				<el-table-column label="所属公司" prop="sellerName"></el-table-column>
				<el-table-column prop="districtName" label="申请日期">
					<template slot-scope="scope">
						<p>{{scope.row.applyDate | timenData}}</p>
					</template>
				</el-table-column>
				<el-table-column prop="regionName" label="状态">
					<template slot-scope="scope">
						<p v-if="scope.row.checkState === 0">待审核</p>
						<p v-if="scope.row.checkState === 1">审核通过</p>
						<p v-if="scope.row.checkState === 2">审核驳回</p>
					</template>
				</el-table-column>
				<el-table-column v-if="type == '0' || type == '2'" prop="storeStatusName" label="门店状态">
					<template slot-scope="scope">
						{{scope.row.storeStatusName ? scope.row.storeStatusName : '-'}}
					</template>
				</el-table-column>
				<el-table-column label="操作">
					<template slot-scope="scope">
						<div>
				      <p class="tac font-12">
								<router-link v-if="scope.row.checkState === 1 && scope.row.sellerId == form.sellerId" :to="`${basePath}/storeNumDetailEditor?key=detail&id=${scope.row.id}`">
									<el-button class='ml-0 tal font-12 width-auto' type='text'>编辑</el-button>
								</router-link>
							</p>
				      <p class="tac font-12">
								<router-link v-if="scope.row.checkState === 2 || scope.row.checkState === 1" :to="`${basePath}/storeNumDetail?key=detail&id=${scope.row.id}`">
									<el-button class='ml-0 tal font-12 width-auto' type='text'>查看</el-button>
								</router-link>
							</p>
							<p class="tac font-12" v-if="scope.row.checkState === 2">
								<el-button type="text" class="font-12" @click="operate(scope.row, 'delete')">删除</el-button>
							</p>
							<p class="tac font-12" v-if="scope.row.checkState === 1">
								<el-button type="text" class="font-12" @click="operate(scope.row, 'look')">授权书</el-button>
							</p>
							<p class="tac font-12">
								<router-link v-if="scope.row.checkState === 0" :to="`${basePath}/storeNumDetail?id=${scope.row.id}`">
									<el-button class='ml-0 tal font-12 width-auto' type="text">审核</el-button>
								</router-link>
							</p>
							<p class="tac font-12" v-if="scope.row.checkState === 1">
								<el-button type="text" class="font-12" @click="operate(scope.row, 'modifyStatus')">修改状态</el-button>								
							</p>
							<!--<p class=/el-button>"scope.row.checkState === 1">
								<el-button type="text">
									<a :href="scope.row.accreditImg" download="授权书.jpg" class="download">下载授权书</a>
								</el-button>
							</p>-->
						</div>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination v-show="tableData && tableData.length" @current-change="handleCurrentChange" class="tar mt-20 mr-50" :current-page.sync="currentPage" :page-size='10' :total='total' layout="prev , pager, next, jumper">
				<!-- 分页组件 -->
			</el-pagination>
		</div>
		<el-dialog :visible.sync="dialogVisible" size="tiny" width="800px">
			<img width="100%" height="100%" :src="dialogImageUrl" alt="">
		</el-dialog>
		<el-dialog  title="请选择需要修改的门店状态" :visible.sync="dialogStatus" class="dialog-status" width="500px" >
            <div style="min-height: 300px">
                <el-select v-model="statusValue" placeholder="请选择">
                    <el-option
                            v-for="item in statusOptions"
                            :key="item.status"
                            :label="item.desc"
                            :value="item.status">
                    </el-option>
                </el-select>
            </div>

			<span slot="footer" class="dialog-footer">
					<el-button @click="modifyStatus" type="danger">确定</el-button>
			</span>
		</el-dialog>

		<!-- 导出 -->
		<form method='get' ref="exportDataList" action='/sunrise-gateway/export/trader/seller/storeCodeCheckList' style="display: none">
			<input type="text" name="modelName" value="storecode.list" />
			<input type="text" name="fileName" value="门店编码列表.xls">
			<input v-if="form.sellerId" type="text" name="sellerId" v-model="form.sellerId">
			<input v-if="form.sellerName" type="text" name="sellerName" v-model="form.sellerName">
			<input v-if="form2.storeName" type="text" name="storeName" v-model="form2.storeName">
			<input v-if="form2.storeNo" type="text" name="storeNo" v-model="form2.storeNo">
			<input v-if="form2.storeType" type="text" name="storeType" v-model="form2.storeType">
			<!-- <input v-if="form2.storeType[1]" type="text" name="subStoreType" v-model="form2.storeType[1]"> -->
			<input type="text" name="checkState" v-model="form2.checkState">
			<input v-if="form2.allCompanies" type="text" name="allCompanies" v-model="form2.allCompanies">
			<input v-if="form2.applyDateStart" type="text" name="applyDateStart" v-model="form2.applyDateStart">
			<input v-if="form2.applyDateEnd" type="text" name="applyDateEnd" v-model="form2.applyDateEnd">
			<input type="text" name="startRow" value="0">
		</form>
	</div>
</template>

<script>
	import { axiosApi, basePath } from "../../../api/api"
	import { MessageBox } from 'element-ui';
	import districtSelect from "@/components/districtSelect/districtSelect.vue";

	// import YsDaterangePicker from "~/components/ys-daterange-picker";
	export default {
		components: {
			districtSelect,
			// YsDaterangePicker,
		},
		data() {
			return {
				store: [], //门店类型
				dataTabs: [
					{name: '全部企业', type: null},
					{name: '待审核', type: 0},
					{name: '审核通过', type: 1},
					{name: '驳回', type: 2},
				],
				type: 0, //tab对应编号
				tableData: [], //门店数据
				total: 10, //门店查询总数
				currentPage: 1, //当前查询页
				form: {
					sellerId: sessionStorage.getItem('traderId'),
					storeName: null, //门店名称
					storeNo: null, //门店编码
					storeType: null, //门店类型
					checkState: 0, //审核状态
					sellerName: null, //所属公司
					allCompanies: 1
				},
				form2: {
					sellerId: sessionStorage.getItem('traderId'),
					storeName: null, //门店名称
					storeNo: null, //门店编码
					storeType: null, //门店类型
					checkState: 0, //审核状态
					sellerName: null, //所属公司
					allCompanies: 1
				},
				dialogImageUrl: null,
				dialogVisible: false,
				notCheck: '',
				searchTimes: [],
				dialogStatus: false, //门店状态
				statusOptions: [],
				statusValue: '',
				checkedId: null,
                showLabel: false,//是否显示所属公司
			};
		},
		//created(){ //数据初始化
		//		if(sessionStorage.getItem('storeNumber')){
		//      	let storeNumber = JSON.parse(sessionStorage.getItem('storeNumber'))
		//	        this.type = storeNumber.type //tab页面
		//	        delete storeNumber.type
		//					this.currentPage = storeNumber.page //当前页数
		//	        delete storeNumber.page
		//	        this.form = Object.assign({},storeNumber)
		//	        setTimeout(()=>{
		//	    			sessionStorage.removeItem('storeNumber')
		//	    		},500)
		//    }
		//},
		activated() {
			sessionStorage.removeItem('postdata')
			this.getStoreCode()
			this.getStoreStatus()
			let body = Object.assign({}, this.form)
			body.storeType = this.form.storeType? this.form.storeType : null //门店类型
			// body.subStoreType = this.form.storeType[1] ? this.form.storeType[1] : null //子门店类型
			this.getStoreList(body)
			if(sessionStorage&&sessionStorage.traderId==2548){
				this.showLabel = true
			}else{
				this.showLabel = false
			}
		},
		
		methods: {
			// 导出
			exportData() {
				if(this.tableData && this.tableData.length === 0) {
					this.$message.warning('无数据可导出');
				} else {
					this.$refs.exportDataList.submit()
				}
			},
			/*获取门店类型*/
			getStoreCode() {
				axiosApi('/trader/storecode/listStoreType', {}, res => {
					this.store = res.data.data;
					// this.deleteSub(this.store)
				}, 1, 10)
			},
			deleteSub(data){
				data.forEach(item=>{
					console.log(item.sub.length)
					if(item.sub.length==0){
						delete item.sub
					}else {
						this.deleteSub(item.sub)
					}
				})
			},
			// 获取门店状态
			getStoreStatus() {
				axiosApi('/trader/storecode/listStoreStatus', {}, res => {
					this.statusOptions = res.data.data ? res.data.data : []
				}, 1, 10)
			},
			getStoreList() {
				/*新增日期选择*/
				this.form.applyDateStart = +this.searchTimes[0] ||null;
				this.form.applyDateEnd = +this.searchTimes[1] ||null;
				
				let body = Object.assign({}, this.form)
				body.storeType = this.form.storeType ? this.form.storeType: null //门店类型
				// body.subStoreType = this.form.storeType[1] ? this.form.storeType[1] : null //子门店类型
				axiosApi('/trader/seller/getStoreCodeCheckList', body, (res) => {
					({
						elements: this.tableData,
						totalCount: this.total
					} = res.data.data.datalist);
					this.notCheck = res.data.data.notCheck || 0;
					//给导出数据赋值
					this.form2.sellerName = this.form.sellerName
					this.form2.storeName = this.form.storeName
					this.form2.storeNo = this.form.storeNo
					this.form2.storeType = this.form.storeType
					this.form2.checkState = this.form.checkState
					this.form2.allCompanies = this.form.allCompanies
					this.form2.applyDateStart = this.form.applyDateStart
					this.form2.applyDateEnd = this.form.applyDateEnd

				}, this.currentPage, 10)
			},
			search() { //搜索查询
				if(this.currentPage === 1) {
					this.getStoreList()
				} else {
					this.currentPage = 1
				}
			},
			handleTabs(e) { //切换标签页
			
				if(e) { 
					this.type = e.type
					console.log(this.type)
				}
				//清空数据
				this.form = {
					storeName: null,
					storeNo: null,
					storeType: null,
					sellerId: sessionStorage.getItem('traderId'),
					applyDateStart: null,
					applyDateEnd: null,
					sellerName: null
				}
				this.searchTimes = []
				this.form.checkState = e.type
				if(e.type==null){
					this.form.allCompanies = 1
				}
				// if(this.form.checkState < 0) {
				// 	this.form.checkState = null
				// 	this.form.allCompanies = 1
				// }
				this.search()
			},
			handleCurrentChange() { //改变当前页
				this.getStoreList()
			},
			cancelApply(body) { //删除门店编码申请
				return new Promise((resolve, reject) => {
					axiosApi('/trader/storecode/cancelapply', body, (res) => {
						resolve(res)
					}, null, null, (err) => {
						reject(err)
					})
				})
			},
			operate(row, type) { //操作
				if(type === 'delete') { //删除
					const h = this.$createElement;
					this.$msgbox({
						title: '提示',
						message: h('p', null, [
							h('p', {
								style: 'textAlign: center'
							}, '是否确认从列表中删除？'),
						]),
						showCancelButton: true,
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						beforeClose: (action, instance, done) => {
							if(action === 'confirm') {
								instance.confirmButtonLoading = true
								instance.confirmButtonText = '执行中...'
								this.cancelApply({
										id: row.id
									})
									.then(() => {
										done()
										setTimeout(() => {
											instance.confirmButtonLoading = false
										}, 300)
										this.$message.success('删除成功！')
										this.getStoreList(this.form)
									})
									.catch((err) => {
										done()
										setTimeout(() => {
											instance.confirmButtonLoading = false
										}, 300)
										this.$message.success(err.data.message)
									})
							} else {
								done()
							}
						}
					})
				} else if(type === 'look') {
					this.dialogImageUrl = row.accreditImg
					this.dialogVisible = true
				} else if(type == 'modifyStatus') {
					this.dialogStatus = true
					this.statusValue = String(row.storeStatus)
					this.checkedId = row.id
				}
			},
			modifyStatus(id) {
				axiosApi('/trader/seller/updateStoreCode', {
					id: this.checkedId,
					storeStatus: this.statusValue
				}, (res) => {
					this.dialogStatus = false
					this.getStoreList()
				}, null, null, (err) => {
					this.$message.error(err.data.message);
					this.dialogStatus = false
				})
			}
		}
	};
</script>

<style lang="less">
	.store-number {
		.el-dialog {
			min-height: 420px;
		}
		.el-dialog--tiny {
			width: 80%;
		}
		.el-dialog__body {
			box-sizing: border-box;
			padding: 30px 20px;
		}
		.download {
			color: #20a0ff;
		}
		.title-tabs {
			position:relative;
			
		}
		.mark{
			position: absolute;
			.el-badge__content{
				position: absolute;
				top: -22px;
				left: 170px;
			}
		}
		.dialog-status {
			.el-select {
				margin-left: 105px;
			}
		}
	}
</style>