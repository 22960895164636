<template>
    <!--超团订单-->
    <div id="psOrderB">
        <div class="title-tabs bg-f black mb-5">
            <ul class="clearfix">
                <template v-for='(tab,index) in tabs' class="item">
                    <li :type='index' :class="type==index?'active':''" @click='handleTabs'>
                        {{tab}}
                        <div :class="type==index?'under-line':''"></div>
                    </li>
                    <div class="vertical-line"></div>
                </template>
            </ul>
        </div>
        <div class='bg-f'>
            <div class="ml-30 mr-30 second-title clearfix">
                <div class="choose-three font-14 color-6 fl tac mr-10 cursor " :class="first == 0?'actived':''"
                     @click="getStatus(0)">
                    全部订单({{badge.count0}})
                </div>
                <div class="choose-three font-14 color-6 tac fl mr-10 cursor"
                     :class="first == 1?'actived':''" @click="getStatus(1)">
                    待付款({{badge.count1}})
                </div>
                <div class="choose-three font-14 color-6 tac fl cursor"
                     :class="first == 2?'actived':''" @click="getStatus(2)">
                    部分付款({{badge.count2}})
                </div>

                <div class="choose-three font-14 color-6 tac fl cursor" :class="first == 3?'actived':''"
                     @click="getStatus(3)">
                    已完成({{badge.count3}})
                </div>
                <div class="choose-three font-14 color-6 tac fl cursor" :class="first == 4?'actived':''"
                     @click="getStatus(4)">
                    已取消({{badge.count4}})
                </div>
            </div>
        </div>
        <div class="content bg-f pl-30 pr-30 pb-50">
            <div class="clearfix search">
                <el-form :inline='true' class="pt-30" :model="form">
                    <el-form-item label='商品名称' class='mr-20'>
                        <el-input v-model.trim="form.skuName" :maxlength="30" placeholder="请输入商品名称"></el-input>
                    </el-form-item>
                    <el-form-item label='客户手机' class='mr-20'>
                        <el-input v-model.trim="form.customerMobile" :maxlength="30"
                                  placeholder="请输入客户手机"></el-input>
                    </el-form-item>
                    <el-form-item label='订单编号' class='mr-20'>
                        <el-input v-model.trim="form.sn" :maxlength="30" placeholder="请输入订单编号"></el-input>
                    </el-form-item>
                    <el-form-item label='下单时间' class='mr-20'>
                        <ysDaterangePicker v-model="form.orderDateRange"/>
                    </el-form-item>
                    <el-form-item label='支付方式' class='mr-20'>
                        <el-select v-model="form.payMethod" placeholder="请选择支付方式" clearable>
                            <el-option
                                    v-for="item in options"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label='酒店名称' class='mr-20'>
                        <el-input v-model.trim="form.hotelName" :maxlength="30" placeholder="请输入酒店名称"
                        ></el-input>
                    </el-form-item>
                </el-form>
                <div>
                    <el-button class="mb-20" type='info' @click="queryOrderList" icon="el-icon-search">查 询</el-button>
                </div>
            </div>
            <div class='tar clearfix'>
                <el-button type='text' class="fr" @click="exportOrder">导出</el-button>
            </div>
            <div>
                <el-table :data="groupList" stripe class='table-default font-12'>
                    <el-table-column label='酒店名称' prop="hotelName"></el-table-column>
                    <el-table-column label='订单编号' prop="sn" width="150"></el-table-column>
                    <el-table-column label="下单时间" width="150">
                        <template slot-scope="scope">
                            {{ scope.row.gmtCreate | time}}
                        </template>
                    </el-table-column>
                    <el-table-column label='商品数量' prop="totalGoodsCount" width="60">
                    </el-table-column>
                    <el-table-column label='客户信息'>
                        <template slot-scope="scope">
                            <div v-if="scope.row.customerNickName">
                                <p>{{scope.row.customerNickName}}</p>
                                <p>{{ scope.row.customerMobile }}</p>
                            </div>
                            <div v-else>
                                <p>（服务员代下单）</p>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="订单状态" width="60">
                        <template slot-scope="scope">
                            <span v-if="scope.row.orderStatus == 1">待付款</span>
                            <span v-if="scope.row.orderStatus == 2">部分支付</span>
                            <span v-if="scope.row.orderStatus == 3">已支付</span>
                            <span v-if="scope.row.orderStatus == 4">已完成</span>
                            <span v-if="scope.row.orderStatus == 5">已取消</span>
                        </template>
                    </el-table-column>
                    <el-table-column label='酒水单金额'>
                        <template slot-scope="scope">
                            <span>￥{{scope.row.wineAmount | fmoney}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label='订单金额'>
                        <template slot-scope="scope">
                            <span>￥{{scope.row.totalAmount | fmoney}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label='操作' width="80">
                        <template slot-scope="scope">
                            <el-button type='text' @click="toDetails(scope)">订单详情</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination class="tar mt-20" v-if="groupList.length>0" :current-page="currentPage" :page-size='10'
                               :total='total' layout="prev , pager, next, jumper" @current-change="handleCurrentChange">
                    <!-- 分页组件 -->
                </el-pagination>
            </div>
        </div>

        <!--筛选条件导出订单-->
        <form ref="downClientForm2" action='/sunrise-gateway/export/catering/platform/subCompanyOrder'
              style="display: none">
            <input type="hidden" name="modelName" v-model="a">
            <input type="hidden" name="fileName" v-model="b">
            <!--订单编号-->
            <input v-if="form2.sn" type="hidden" name="sn" v-model="form2.sn">
            <!--订单状态-->
            <input v-if="form2.orderStatus" type="hidden" name="orderStatus" v-model="form2.orderStatus">
            <!--顾客名称-->
            <input v-if="form2.customerNickName" type="hidden" name="customerNickName" v-model="form2.customerNickName">
            <!--顾客手机-->
            <input v-if="form2.customerMobile" type="hidden" name="customerMobile" v-model="form2.customerMobile">
            <!--商品名称-->
            <input v-if="form2.skuName" type="hidden" name="skuName" v-model="form2.skuName">
            <!--支付类型-->
            <input v-if="form2.payMethod" type="hidden" name="payMethod" v-model="form2.payMethod">
            <input type="hidden" name="isWineList" v-model="type">
            <!--支付类型-->
            <input v-if="form2.hotelName" type="hidden" name="hotelName" v-model="form2.hotelName">
            <!--开始时间-->
            <input v-if="form2.gmtCreateStart" type="hidden" name="gmtCreateStart" v-model="form2.gmtCreateStart">
            <!--结束时间-->
            <input v-if="form2.gmtCreateEnd" type="hidden" name="gmtCreateEnd" v-model="form2.gmtCreateEnd">
        </form>
    </div>
</template>

<script>
    import {axiosApi, basePath} from "../../api/api"

    export default {
        data() {
            return {
                type: '0',
                tabs: ['订单', '酒水单'],
                cancelOrderVisible: false, //取消订单弹框
                form: {
                    sn: null,
                    customerNickName: null, //订单编号
                    customerMobile: null, //客户手机
                    skuName: null, //商品名称
                    orderDateRange: [], //
                    orderStatus: null,
                    payMethod: null,
                    hotelName: null,
                },
                form2: {
                    sn: null,
                    customerNickName: null, //订单编号
                    customerMobile: null, //客户手机
                    skuName: null, //商品名称
                    orderStatus: null,
                    payMethod: null,
                    gmtCreateStart: null,
                    gmtCreateEnd: null,
                    hotelName: null,
                },
                a: 'catering.platform.subCompanyOrder',
                b: '订单列表.xls',
                form1: {
                    price: null,
                    cancelReason: null,
                    sn: null
                },
                options: [{
                    value: '3',
                    label: '组合支付'
                }, {
                    value: '2',
                    label: '久加久余额支付'
                }, {
                    value: '1',
                    label: '线下支付'
                }],
                loading: false,
                currentPage: 1,
                total: null,
                badge: {
                    count0: 0,
                    count1: 0,
                    count2: 0,
                    count3: 0,
                    count4: 0,
                },
                first: 0,
                groupList: []
            }
        },
        mounted() {
            this.$nextTick(() => {
                this.type = this.$route.query.type1 ? this.$route.query.type1 : 0;
                this.first = this.$route.query.first ? this.$route.query.first : 0;
                this.setStatus(this.first);
                this.queryOrderList();
                this.anonOrderCount();
            })
        },

        methods: {
            anonOrderCount() { //订单统计(角标)
                let url = '/catering/platform/subCompanyOrder/count';
                axiosApi(url, {
                    isWineList: this.type
                }, (res) => {
                    if (this.type == 1) {
                        this.badge.count0 = res.data.data.allWineOrder;
                        this.badge.count1 = res.data.data.unPayWineOrderCount;
                        this.badge.count2 = res.data.data.partPayWineOrderCount;
                        this.badge.count3 = res.data.data.completedWineOrderCount;
                        this.badge.count4 = res.data.data.cancelWineOrderCount;
                    } else {
                        this.badge.count0 = res.data.data.allOrder;
                        this.badge.count1 = res.data.data.unPayOrderCount;
                        this.badge.count2 = res.data.data.partPayOrderCount;
                        this.badge.count3 = res.data.data.completedOrderCount;
                        this.badge.count4 = res.data.data.cancelOrderCount;
                    }
                }, 1, 10, (res) => {

                })
            }
            ,
            exportOrder() {
                if (this.total == 0) {
                    this.$message({
                        type: 'error',
                        message: '暂无数据可导出',
                        duration: 1000
                    })
                    return false
                }
                this.$refs.downClientForm2.submit();
            }
            ,
            handleCurrentChange(val) { //分页
                this.currentPage = val;
                this.queryOrderList();
                this.anonOrderCount()
            }
            ,
            handleTabs(e) {
                this.first = 0
                if (e.target.attributes.type) {
                    this.type = e.target.attributes.type.value
                    this.typeName = e.target.attributes.type.ownerElement.innerText.trim()
                }
                this.setStatus(this.first);
                this.anonOrderCount();
                this.queryOrderList(true);
            }
            ,
            queryOrderList(bool) {
                this.form2 = this.form;
                this.form2.gmtCreateStart = this.form.orderDateRange[0] ? this.form.orderDateRange[0].getTime() : null;
                this.form2.gmtCreateEnd = this.form.orderDateRange[1] ? this.form.orderDateRange[1].getTime() : null;
                if (bool && this.currentPage != 1) {
                    this.currentPage = 1;
                    return
                }
                this.loading = true;
                let url = '/catering/platform/subCompanyOrder/selectPage';
                let body = {
                    orderStatus: this.form.orderStatus ? this.form.orderStatus : null,
                    sn: this.form.sn ? this.form.sn : null,
                    customerNickName: this.form.customerNickName ? this.form.customerNickName : null,
                    customerMobile: this.form.customerMobile ? this.form.customerMobile : null,
                    skuName: this.form.skuName ? this.form.skuName : null,
                    payMethod: this.form.payMethod ? this.form.payMethod : null,
                    hotelName: this.form.hotelName ? this.form.hotelName : null,
                    gmtCreateStart: this.form.orderDateRange[0] ? this.form.orderDateRange[0].getTime() : null,
                    gmtCreateEnd: this.form.orderDateRange[1] ? this.form.orderDateRange[1].getTime() : null,
                    isWineList: this.type
                }

                axiosApi(url, body,
                    (res) => {
                        this.loading = false;
                        console.log(res, 'res')
                        this.groupList = res.data.data.elements;
                        this.total = res.data.data.totalCount;
                    }, this.currentPage, 10, (res) => {
                        this.loading = false;
                        this.$message({
                            type: 'error',
                            message: res.data.message,
                            duration: 1000
                        })
                    }
                )
            }
            ,
            dialogShow(scope) {
                this.cancelOrderVisible = true;
                this.form1.sn = scope.row.sn;
            }
            ,
            toDetails(scope) {
                this.$router.push({
                    path: 'psOrderDetails',
                    query: {
                        first: this.first,
                        type1: this.type,
                        type: 0,
                        id: scope.row.id,
                        sn: scope.row.sn,
                        isSubCompany: 1
                    }
                });
            }
            ,
            //tab切换new
            getStatus(e) {
                this.first = e;
                this.anonOrderCount()
                this.setStatus(e);
                this.queryOrderList(true)
            }
            ,
            setStatus(e) {
                if (e == '0') { //全部订单
                    this.form.orderStatus = null;
                } else {
                    switch (e) {
                        case 1:
                            this.form.orderStatus = 1;
                            break;
                        case 2:
                            this.form.orderStatus = 2;
                            break;
                        case 3:
                            this.form.orderStatus = 4;
                            break;
                        case 4:
                            this.form.orderStatus = 5;
                            break;
                    }
                }
            }
        }
    }
</script>

<style lang="less">
    #psOrderB {

        .table-default .el-button {
            width: auto;
        }

        .content {
            .el-date-editor--daterange.el-input {
                width: 240px;
            }

            .el-form--inline {

                .el-form-item__label {
                    font-size: 12px;
                    width: 60px;
                }
            }

            .order-datatimerange {
                width: 240px;
            }

            .checkedAll {
                position: relative;
                top: 30px;
            }

            .el-checkbox {
                width: 70px;
                margin-left: 30px;

                .el-checkbox__input {
                    height: 20px;
                }
            }

            .el-checkbox__inner {
                width: 20px;
                height: 20px;
            }

            .el-checkbox__inner::after {
                top: 2px;
            }

            .table-item {
                .bl {
                    border-left: 1px solid #ddd;
                }

                border: 1px solid #dddddd;
                font-size: 14px;
                border-bottom: none;

                .table-item-content .pbl-20 {
                    padding: 0px 0px 20px 20px;
                }

                .table-item-top {
                    padding: 10px 0;

                    .table-item-top-checkbox {
                        margin-left: 14px;
                    }
                }

                img {
                    width: 80px;
                    height: 80px;
                }
            }

            .w100 {
                width: 100px;
            }

            .table-item-default {
                height: 530px;
                padding-top: 120px;
            }

            .el-dialog {
                min-height: 300px;

                .el-form-item__label {
                    width: 140px;
                }
            }

            .sendGoods .el-form-item__error {
                margin-left: 140px;
            }
        }

        .actived {
            border-radius: 2px;
            border: 1px solid #FF4201;
            color: #FF4201;
        }

        .second-title {
            height: 60px;
            padding: 17px 0px 17px 0px;
            box-sizing: border-box;
            border-bottom: 1px solid #EEEEEE;
        }

        .choose-three {
            width: 114px;
            height: 26px;
            line-height: 26px;
        }
    }
</style>