<template>
  <div id="store-record-manage">
    <div class="title-top bg-f">发票管理</div>
    <div class="content  mt-10 bg-f clearfix">
      <el-form class='form' :model="form" ref="form">
        <div class="font-16 mb-20" style="border-bottom: 1px solid #dddddd;padding-bottom: 20px">普通发票</div>
        <el-form-item label='公司抬头' prop="companyLetter" :rules="[{ required:true, pattern: /^([A-Za-z]|[\u4E00-\u9FA5]){1,30}$/, message: '请输入1~30个汉字或字母', trigger: 'blur'}]">
          <el-input v-model.trim="form.companyLetter" value="form.companyLetter" :maxlength="31" @change="form.flag=1"></el-input>
        </el-form-item>
        <el-form-item label='纳税人识别号' prop="taxpayerNumber" :rules="[{ required:true, pattern: /^[A-Za-z0-9]{15,20}$/, min: 15 , max: 20, message: '输入15-20位纳税人识别号', trigger: 'blur'}]">
          <el-input v-model.trim="form.taxpayerNumber" value="form.taxpayerNumber" :maxlength="21" placeholder="输入15-20位纳税人识别号" @change="form.flag=1"></el-input>
        </el-form-item>
        <el-form-item class='ml-150 mt-50'>
          <!--<el-button v-if="form.companyLetter===''" type="primary" :disabled="true">保 存</el-button>
          <el-button v-else type='danger' @click="save()">保 存</el-button>-->
          <el-button v-if="form.flag===0||form.companyLetter===''" type="primary" :disabled="true" >保 存</el-button>
          <el-button v-else type='danger' @click="save()">保 存</el-button>
        </el-form-item>
      </el-form>

      <el-form :model="formVAT" ref="formVAT">
        <div class="font-16 mb-20" style="border-bottom: 1px solid #dddddd;padding-bottom: 20px">增值税发票</div>
        <el-form-item label='公司名称' prop="companyName" :rules="[
      { required:true, pattern: /^([A-Za-z]|[\u4E00-\u9FA5]){1,30}$/, message: '请输入1-30个汉字、字母',trigger: 'blur' }
    ]">
          <el-input v-model.trim="formVAT.companyName" value="formVAT.companyName" :maxlength="31" @change="formVAT.flag=1"></el-input>
        </el-form-item>
        <el-form-item label='纳税人识别号' prop="taxpayerNumber" :rules="[
      { required:true, pattern: /^[A-Za-z0-9]{15,20}$/, message: '请输入15-20位纳税人识别号', trigger: 'blur'}
    ]">
          <el-input v-model.trim="formVAT.taxpayerNumber" value="formVAT.taxpayerNumber" :maxlength="21" placeholder="输入15-20位纳税人识别号" @change="formVAT.flag=1"> </el-input>
        </el-form-item>
        <el-form-item label='注册地址' prop="address" :rules="[
      { required:true, pattern:  /^.{4,50}$/, message: '请输入4-50个字符', trigger: 'blur' }
    ]">
          <el-input v-model.trim="formVAT.address" value="formVAT.address" :maxlength="31" @change="formVAT.flag=1"></el-input>
        </el-form-item>
        <el-form-item label='注册电话' prop="tel" :rules="[
      { required:true, pattern: /^(\d{7,12})$/, message: '注册电话格式有误', trigger: 'blur' }
    ]">
          <el-input v-model.trim="formVAT.tel" value="formVAT.tel" :maxlength="13" @change="formVAT.flag=1"></el-input>
        </el-form-item>
        <el-form-item label='开户银行' prop="bankName" :rules="[
      { required:true, pattern: /^[\u4e00-\u9fa5]{4,30}$/, message: '请输入4-30个汉字', trigger: 'blur' }
    ]">
          <el-input v-model.trim="formVAT.bankName" value="formVAT.bankName" :maxlength="31" @change="formVAT.flag=1"></el-input>
        </el-form-item>
        <el-form-item label='银行账号' prop="bankCard" :rules="[
      { required:true, pattern: /^\d{0,25}$/, message: '请输入1-25位数字', trigger: 'blur' }
    ]">
          <el-input v-model.trim="formVAT.bankCard" value="formVAT.bankCard" :maxlength="26" @change="formVAT.flag=1"></el-input>
        </el-form-item>
        <el-form-item class='ml-150 mt-50'>
          <el-button v-if="formVAT.companyName===''|formVAT.taxpayerNumber==''|formVAT.address==''|formVAT.tel==''|formVAT.bankName==''|formVAT.bankCard==''|formVAT.flag===0" type="primary" :disabled="true">保 存</el-button>
          <el-button v-else type='danger' @click="saveVAT()">保 存</el-button>
        </el-form-item>
      </el-form>
      <!-- 发票寄送信息 start -->
      <el-form class='mb-20 form mdeliveryInfoBox' :rules="sendFormRules" :model="sendForm" ref="sendForm" style="padding-top:0">
        <div class="font-16 mb-20"  style="border-bottom: 1px solid #dddddd;padding-bottom: 20px">发票寄送信息</div>
        <el-form-item label='收票人' prop="ticketCollector">
          <el-input v-model.trim="sendForm.ticketCollector" :maxlength="32" ></el-input>
        </el-form-item>
        <el-form-item label='手机号' prop="iPhone" >
          <el-input v-model.trim="sendForm.iPhone"></el-input>
        </el-form-item>
        <el-form-item label='收票地址' prop="collectorAddress" >
          <cityCascaderBox v-model='sendForm.collectorAddress' :num="3" :showAllLevels="true" :checkStrictly="false" :emitPath="true" @changeName="changeName"/>
        </el-form-item>
        <el-form-item label=' ' class="detailedBox" prop="detailed" >
          <el-input placeholder="请输入详细地址" :style="{width : '240px'}" type="textarea" v-model.trim="sendForm.detailed" :maxlength="50" ></el-input>
        </el-form-item>
        <div>
          <p :style="{paddingLeft : '128px' , lineHeight: '25px'}" class="color-6">
            温馨提示：发票寄送地址与收货地址不同
          </p>
          <p :style="{paddingLeft : '128px' , lineHeight: '25px'}" class="color-6">
            可能会产生新的物流费用，请确认后使用
          </p>
        </div>
        <el-form-item class='mt-50 ml-150'>
          <!--<el-button v-if="form.companyLetter===''" type="primary" :disabled="true">保 存</el-button>
          <el-button v-else type='danger' @click="save()">保 存</el-button>-->
          <el-button type='danger' @click="handleSendSave">保 存</el-button>
        </el-form-item>
      </el-form>
      <!-- 发票寄送信息 end -->
    </div>
  </div>
</template>

<script>
  import { axiosApi } from "../../../api/api"
  export default{
    data(){
      return{
        traderId:sessionStorage.getItem('traderId'),
        data:null,
        dataVAT:'',
          form:{
          companyLetter:'',
          taxpayerNumber:'',
          flag:0
        },
        formVAT:{
          companyName:'',
          taxpayerNumber:'',
          address:'',
          tel:'',
          bankName:'',
          bankCard:'',
          invoiceType:2,
          flag:0
          },
          /* 发票寄送信息 start */
          sendForm : {
            ticketCollector : '', // 收票人
            iPhone : '', // 手机号
            collectorAddress : [], // 收票地址
            detailed : '', // 收票地址 textarea
          },
          sendFormRules : {
            ticketCollector : [
              {required: true , pattern: /^[a-zA-Z\u4e00-\u9fa5]{2,32}$/ , message : '请输入2-32字汉字、字母'}
            ],
            iPhone : [
              { required: true, message: '联系电话不能为空', trigger: 'blur' },
						  { pattern: /^((0\d{2,3}-?\d{7,8})|(\d{7,8})|(1\d{10}))$/, trigger : 'blur' , message : '电话号或手机号格式有误' }
            ],
            collectorAddress : [
              {required: true , message : '请选择收票地址'}
            ],
            detailed : [
              { min: 4, max: 50,  message: '请输入4-50个字符' },
              { required: true , message : '请输入详细地址' }
            ],
          },
          /* 发票寄送信息 end */
          areaNameList: []
      }
    },
    mounted() {
      this.$nextTick(function() {
        this.loading(1)
        this.loading(2)
        this.initSendSave();
      })
    },
    methods:{
      changeName(e){
        this.areaNameList = e
      },
      /* 发票寄送信息 start */
      // 初始化数据
      initSendSave :function(d){
        console.log(d);
        axiosApi('/trader/invoiceAddress/getInfo',{
          traderId : sessionStorage.getItem('traderId'),
        },(res) => {
          let data = res.data.data;
          if (data) {
            this.sendForm.collectorAddress = data.provinceId ? [
              data.provinceId + '',
              data.cityId + '',
              data.regionId + ''
            ] : [];
            this.sendForm.ticketCollector = data.linkman || '';
            this.sendForm.iPhone = data.tel || '';
            this.sendForm.detailed = data.addr || '';
          }
        });
      },
      // 保存
      handleSendSave : function(){
        this.$refs.sendForm.validate((valid) => {
          if (valid) {
            let sendUrl = '/trader/invoiceAddress/addOrUpdate';
            let sendObj = {
              sendProvinceId : this.sendForm.collectorAddress[0],
              sendCityId : this.sendForm.collectorAddress[1],
              sendRegionId : this.sendForm.collectorAddress[2],

              sendProvinceName : this.areaNameList[0],
              sendCityName : this.areaNameList[1],
              sendRegionName : this.areaNameList[2],
              linkMan : this.sendForm.ticketCollector,
              sendTel : this.sendForm.iPhone,
              sendAddress : this.sendForm.detailed,
              traderId : sessionStorage.getItem('traderId'),
            };
            axiosApi(sendUrl , sendObj , (res) => {
              let data = res.data.data;
              this.$message.success('恭喜你，设置成功！');
            } , 1 , 10 , (err) => {
              let data = err.data;
              this.$message.warning(data);
            });
          }
        });
      },
      /* 发票寄送信息 end */
      loading(type){
          // console.log(this.form.flag)
        const api='/trader/selectInvoiceInfo/select';
        const body={
          invoiceType: type,
          traderId: this.traderId
        };
        axiosApi(api, body, (res) =>{
          if(type == 1){
            this.data=res.data.data;
            if(this.data!==null){
              this.form.companyLetter=res.data.data.companyHead
              this.form.taxpayerNumber=res.data.data.taxpayerNumber
            }
          }else if(type == 2){
            this.dataVAT=res.data.data;
            if(this.dataVAT!==null){
              this.formVAT.companyName=res.data.data.companyName;
              this.formVAT.taxpayerNumber=res.data.data.taxpayerNumber;
              this.formVAT.address=res.data.data.address;
              this.formVAT.tel=res.data.data.tel;
              this.formVAT.bankName=res.data.data.bankName;
              this.formVAT.bankCard=res.data.data.bankCard;
            }
          }
        },1,10,(err)=>{
          this.$alert(err.data.message, '提示信息', {
              confirmButtonText: '确定'
          });
        });
      },
      save(){
        this.$refs.form.validate((valid)=>{
          if(valid){
            const api = !this.data ? "/trader/insertInvoiceInfo/insert" : "/trader/invoiceInfo/update";
            const body = {
                invoiceType: 1,
                companyHead:this.form.companyLetter,
                taxpayerNumber:this.form.taxpayerNumber,
                traderId:this.traderId,
                 id: this.data ? this.data.id : null
              };
              // console.log(body)
              this.callBack(api, body, 1)
          }else {
            this.$message({
              title: '失败',
              message: '信息输入不正确',
              type: 'info'
            });
          }
        })
       },
       callBack(api, body, type){
          axiosApi(api, body, (res) =>{
            this.$message({
              message: '恭喜你，设置成功！',
                type: 'success'
              });
              if(type ==1)this.form.flag=0;
              if(type ==2)this.formVAT.flag=0;
              this.loading(type);
          },1,10,(res)=>{
            this.$message({
              message: res.data.message,
              type: 'info'
            });
          })
       },
      saveVAT(){
         this.$refs.formVAT.validate((valid)=>{
           if(valid){
              const api = !this.dataVAT ? "/trader/insertAppreciationInvoice/insert" : "/trader/invoiceInfo/update";
              const body = {
                 traderId:this.traderId,
                 companyName:this.formVAT.companyName,
                 taxpayerNumber:this.formVAT.taxpayerNumber,
                 address:this.formVAT.address,
                 tel:this.formVAT.tel,
                 bankName:this.formVAT.bankName,
                 bankCard:this.formVAT.bankCard,
                 invoiceType:2,
                 id: this.dataVAT ? this.dataVAT.id : null
               };
              this.callBack(api, body, 2)
           }else {
             this.$message({
               title: '失败',
               message: '信息输入不正确',
               type: 'info'
             });
           }
         })
       },
    }
  }
</script>

<style lang='less'>
  .mdeliveryInfoBox{
    .detailedBox{
      .el-form-item__label{
        visibility: hidden;
      }
    }
  }
  #store-record-manage {
      .content{
          padding: 20px 0;
      }
    .el-form{
        padding: 20px 50px;
        .el-form-item__label{width: 105px;}
    }
    .ml-148{margin-left: 134px;  }
    .ml-150{margin-left: 150px;}
    .mt-100{margin-top: 100px;}
    .mb-100{margin-bottom: 100px;}
    .el-form .el-form-item__label{margin-right: 20px;}
    .el-form-item__error{margin-left: 125px}
  }
</style>