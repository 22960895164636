<template>
  <!--久集商品管理-->
  <div id="goodsodityManage">
    <div class="title-top bg-f clearfix">分销商品管理</div>
    <div class="content bg-f pr-30 pl-30 mt-5">
      <div class="clearfix search">
        <el-form :inline="true" class="pt-30 fl" :model="Form" ref="searchForm">
          <el-form-item
            class="mr-30"
            label="商品编码"
            :rules="[{pattern:/^[a-zA-Z0-9\.]{0,20}$/,message:'请输入正确商品编码',trigger:'blur'}]"
            prop="goodsNo"
          >
            <el-input v-model="Form.goodsNo" placeholder="请输入商品编码" :maxlength="20"></el-input>
          </el-form-item>
          <el-form-item
            label="sku编码" class="mr-30"
            :rules="[{pattern:/^[a-zA-Z0-9]{0,20}$/,message:'请输入正确sku编码',trigger:'blur'}]"
            prop="skuNo"
          >
            <el-input v-model="Form.skuNo" placeholder="请输入sku编码" :maxlength="20"></el-input>
          </el-form-item>
          <el-form-item class="mr-30" label="商品名称">
            <el-input v-model.trim="Form.skuName" placeholder="请输入商品名称" :maxlength="30"></el-input>
          </el-form-item>
          <el-form-item class="mr-30" label="商品分类">
            <el-select v-model="Form.classifyId" placeholder="请选择商品分类" clearable>
              <el-option
                v-for="tab in classList"
                :key="tab.classifyId"
                :label="tab.classifyName"
                :value="tab.classifyId"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="mr-30" label="商品状态">
            <el-select v-model="Form.skuState" placeholder="请选择商品状态" clearable>
              <el-option label="全部状态" value="null"></el-option>
              <el-option label="未上架" value="1"></el-option>
              <el-option label="已上架" value="2"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div>
          <el-button class="" type="info" @click="achive(true)" icon="el-icon-search">搜 索</el-button>
        </div>
      </div>
      <div class="clearfix">
        <div class="fr">
          <el-button type="text" @click="handleUp({row:{skuState:1}})">批量上架</el-button>
          <el-button type="text" @click="handleUp({row:{skuState:2}})">批量下架</el-button>
          <el-button type="text" @click="exportGoodsExcel">下载模板</el-button>
          <el-upload
            class="dib"
            action="/sunrise-gateway/import/superc/goods?startRow=1&startCol=0"
            :show-file-list="false"
            :on-success="handUpSuccess"
            :before-upload="handUpBefore"
            :on-error="handUpError"
            accept="application/vnd.ms-excel"
          >
            <el-button type="text">批量导入</el-button>
          </el-upload>
          <el-button type="text" @click="exportExcel">批量导出</el-button>
          <el-button type="danger" class="mb-5" @click="hanleAddGoods">添加商品</el-button>
        </div>
      </div>
      <div class="pb-30">
        <el-table :data="tableData" @selection-change="handleSelectionChange">
          <el-table-column type="selection"></el-table-column>
          <el-table-column label="商品名称" min-width="280">
            <template slot-scope="scope">
                <div style="display: flex">
                    <div class="fl specialD">
                        <img v-if="scope.row.fileUrl" :src="scope.row.fileUrl" alt />
                        <img v-else src="../../assets/goods.jpg" alt />
                    </div>
                    <div class="fl lh-16" style="flex: 1;width: 0">
                        <div class="width-130">
                            商品编码:
                            <span v-if="scope.row.goodsNo">{{scope.row.goodsNo}}</span>
                            <span v-else>无</span>
                        </div>
                        <div class="width-130">
                            sku编码:
                            <span v-if="scope.row.skuNo">{{scope.row.skuNo}}</span>
                            <span v-else>无</span>
                        </div>
                        <div class="width-130" show-overflow-tooltip>{{scope.row.skuName}}</div>
                    </div>
                </div>

            </template>
          </el-table-column>
          <el-table-column label="规格" min-width="45">
            <template slot-scope="scope">
              <span>{{scope.row.specification}}</span>
            </template>
          </el-table-column>
          <el-table-column label="商品分类" min-width="55">
            <template slot-scope="scope">
              <span>{{scope.row.classifyName}}</span>
            </template>
          </el-table-column>
          <el-table-column label="开票成本价" min-width="60">
            <template slot-scope="scope">
              <span v-if="scope.row.costPrice">￥{{scope.row.costPrice||0 | fmoney }}</span>
              <span v-else>-</span>
            </template>
          </el-table-column>
          <el-table-column label="最低售价" min-width="60">
            <template slot-scope="scope">
              <span v-if="scope.row.minSellingPrice">￥{{scope.row.minSellingPrice||0 | fmoney }}</span>
              <span v-else>-</span>
            </template>
          </el-table-column>
          <el-table-column label="最高售价" min-width="60">
            <template slot-scope="scope">
              <span v-if="scope.row.maxSellingPrice">￥{{scope.row.maxSellingPrice||0 | fmoney }}</span>
              <span v-else>-</span>
            </template>
          </el-table-column>
          <el-table-column label="售前比例" min-width="55">
            <template slot-scope="scope">
              <span v-if="scope.row.preSaleScale!=null">{{scope.row.preSaleScale}}%</span>
              <span v-else>-</span>
            </template>
          </el-table-column>
          <el-table-column label="售后比例" min-width="60">
            <template slot-scope="scope">
              <span v-if="scope.row.afterSaleScale!=null">{{scope.row.afterSaleScale}}%</span>
              <span v-else>-</span>
            </template>
          </el-table-column>
          <el-table-column label="销售佣金" min-width="55">
            <template slot-scope="scope">
              <span
                v-if="scope.row.commission ||scope.row.commission==0"
              >￥{{scope.row.commission||0 | fmoney }}</span>
              <span v-else>-</span>
            </template>
          </el-table-column>
          <el-table-column label="推广员佣金" min-width="60">
            <template slot-scope="scope">
              <span
                v-if="scope.row.salesCommission ||scope.row.salesCommission==0"
              >￥{{scope.row.salesCommission||0 | fmoney }}</span>
              <span v-else>-</span>
            </template>
          </el-table-column>
          <el-table-column label="序号" min-width="45">
            <template slot-scope="scope">
              <span>{{scope.row.sort != null?scope.row.sort:'-'}}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" min-width="200">
            <template slot-scope="scope">
              <el-button type="text" @click="openSort(scope.row)">排序</el-button>
              <el-button type="text" @click="handleSetPrice(scope)">设价</el-button>
              <el-button type="text" v-if="scope.row.skuState==1" @click="handleUp(scope)">上架</el-button>
              <el-button type="text" v-if="scope.row.skuState==2" @click="handleUp(scope)">下架</el-button>
              <el-button type="text" @click="handleMinimunMoq(scope)">最低起订量</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          class="tar mt-20"
          v-if="tableData.length>0"
          :current-page="currentPage"
          :page-size="10"
          :total="total"
          layout="prev , pager, next, jumper"
          @current-change="handleCurrentChange"
        >
          <!-- 分页组件 -->
        </el-pagination>
      </div>
      <!--弹出框 设价-->
      <el-dialog class="reject" title="设价" :visible.sync="reject">
        <el-form class="pr-30" :model="formPrice" ref="rules9" :rules="rules9">
          <div class="clearfix">
            <div class="w400 fl">
              <el-form-item label="开票成本价" prop="costPrice">
                <el-input
                  placeholder="请输入开票成本价"
                  v-model.trim="formPrice.costPrice"
                  @keyup.native="formPrice.costPrice=formPrice.costPrice.replace(/\s+/g,'')"
                ></el-input>
                <span class="ml-5">元</span>
              </el-form-item>
            </div>
            <div class="w400 fl">
              <el-form-item label="最低售价" prop="minSellingPrice">
                <el-input
                  placeholder="请输入最低售价"
                  v-model.trim="formPrice.minSellingPrice"
                  @keyup.native="formPrice.minSellingPrice=formPrice.minSellingPrice.replace(/\s+/g,'')"
                ></el-input>
                <span class="ml-5">元</span>
              </el-form-item>
            </div>
            <div class="clearfix">
              <div class="w400 fl">
                <el-form-item label="最高售价" prop="maxSellingPrice">
                  <el-input
                    placeholder="请输入最高售价"
                    v-model.trim="formPrice.maxSellingPrice"
                    @keyup.native="formPrice.maxSellingPrice=formPrice.maxSellingPrice.replace(/\s+/g,'')"
                  ></el-input>
                  <span class="ml-5">元</span>
                </el-form-item>
              </div>
              <div class="w400 fl">
                <el-form-item label="售前比例" prop="preSaleScale">
                  <el-input
                    placeholder="请输入0-100以内的数字"
                    v-model.trim="formPrice.preSaleScale"
                    @keyup.native="formPrice.preSaleScale=formPrice.preSaleScale.replace(/\s+/g,'')"
                  ></el-input>
                  <span class="ml-5">%</span>
                </el-form-item>
              </div>
            </div>
            <div class="clearfix">
              <div class="w400 fl">
                <el-form-item label="售后比例" prop="afterSaleScale">
                  <el-input
                    placeholder="请输入0-100以内的数字"
                    v-model.trim="formPrice.afterSaleScale"
                    @keyup.native="formPrice.afterSaleScale=formPrice.afterSaleScale.replace(/\s+/g,'')"
                  ></el-input>
                  <span class="ml-5">%</span>
                </el-form-item>
              </div>
              <div class="w400 fl">
                <el-tooltip placement="bottom-start" effect="light">
                  <div slot="content">
                    销售佣金计算方式:
                    <br />
                    <br />（最低售价-开票成本价）*售前比例+（成交价-最低售价）*售后比例
                  </div>
                  <span class="el_label">
                    销售佣金
                    <img src="../../assets/mark.png" alt />
                  </span>
                </el-tooltip>
                <el-input placeholder="销售佣金" v-model="commission" readonly disabled></el-input>
                <span class="ml-5">元</span>
              </div>
            </div>
            <div class="clearfix">
              <div class="w400 fl">
                <el-form-item label="售前推广员佣金比例" prop="salesPreSaleScale">
                  <el-input
                    placeholder="请输入售前推广员佣金比例"
                    v-model.trim="formPrice.salesPreSaleScale"
                    @keyup.native="formPrice.salesPreSaleScale=formPrice.salesPreSaleScale.replace(/\s+/g,'')"
                  ></el-input>
                  <span class="ml-5">%</span>
                </el-form-item>
              </div>
              <div class="w400 fl">
                <el-form-item label="售后推广员佣金比例" prop="salesAfterSaleScale">
                  <el-input
                    placeholder="请输入售后推广员佣金比例"
                    v-model.trim="formPrice.salesAfterSaleScale"
                    @keyup.native="formPrice.salesAfterSaleScale=formPrice.salesAfterSaleScale.replace(/\s+/g,'')"
                  ></el-input>
                  <span class="ml-5">%</span>
                </el-form-item>
              </div>
            </div>
            <div class="clearfix">
              <div class="w400 fl">
                <el-tooltip placement="bottom-start" effect="light">
                  <div slot="content">
                    推广员佣金计算方式:
                    <br />
                    <br />（最低售价-开票成本价）*售前推广员佣金比例+（成交价-最低售价）*售后推广员佣金比例
                  </div>
                  <span class="el_label">
                    推广员佣金
                    <img src="../../assets/mark.png" alt />
                  </span>
                </el-tooltip>
                <el-input placeholder="售前推广员佣金" v-model="salesSaleCommission" readonly disabled></el-input>
                <span class="ml-5">元</span>
              </div>
              <div class="w400 fl">
                <el-form-item label="仓库号" prop="storeType">
                  <el-radio-group v-model="formPrice.storeType">
                    <el-radio label="1">统一仓</el-radio>
                    <el-radio label="2">久集特殊仓</el-radio>
                  </el-radio-group>
                </el-form-item>
              </div>
            </div>
          </div>
          <el-form-item label="非配送区域">
            <el-checkbox
              v-model="formPrice.isCountrywide"
              :indeterminate="formPrice.isIndeterminate"
              @change="isCountry"
            >全国</el-checkbox>
            <div class="ml-100">
              <span class="dib w125" v-for="(item,index) in formPrice.areas" :key="index">
                
                <div class="flexCenter">
                  <el-checkbox
                    v-model="item.checkAll"
                    :indeterminate="item.isIndeterminate"
                    @change="proChange(item)"
                    class="flexCenter"
                  >
                    <div class="itemNameClass">{{item.name}}</div>
                    <span
                      class="color-danger font-12"
                      v-if="item.citys.length>0"
                    >({{item.citys.length}})</span>
                  </el-checkbox>
                  <i
                    class="cursor color-danger"
                    :class="item.display?'el-icon-caret-top':'el-icon-caret-bottom'"
                    @click="proClick(item)"
                  ></i>
                </div>
                <el-checkbox-group
                  v-model="item.citys"
                  class="city"
                  v-if="item.display"
                  :class="{zindex:item.zindex}"
                  @change="cityChange(item)"
                  style="display: flex;flex-wrap: wrap;"
                >
                  <el-checkbox
                    v-for="i in item.childs"
                    :label="i.id"
                    :key="i.id"
                    :title="i.name"
                    class="flexCenter"
                  >
                  <div class="text-overflow w100">{{i.name}}</div>
                  </el-checkbox>
                </el-checkbox-group>
              </span>
            </div>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="danger" class="mr-20" @click="handleSetSave">确定</el-button>
          <el-button class="ml-20" @click="reject = false">取消</el-button>
        </div>
      </el-dialog>
      <!--商品弹窗-->
      <el-dialog class="goods" title="添加商品" :visible.sync="goods">
        <el-form class="pr-30" :model="formPrice">
          <el-form-item label="关键词搜索" class="ml-10">
            <el-input placeholder="请输入商品名称" v-model.trim="formPrice.skuName"></el-input>
            <el-button class="ml-30" type="info" @click="getGoodsList(true)">搜索</el-button>
          </el-form-item>
        </el-form>
        <div class="ml-30 mr-30 mb-50">
          <el-table :data="goodsList" ref="multipleTable" @selection-change="handleSelectionCheck">
            <el-table-column type="selection" :selectable="disabled"></el-table-column>
            <el-table-column label="商品名称" width="300">
              <template slot-scope="scope">
                <div class="fl">
                  <img v-if="scope.row.fileUrl" :src="scope.row.fileUrl" alt />
                  <img v-else src="../../assets/goods.jpg" alt />
                </div>
                <div class="fl lh-16">
                  <div class="width-180">商品编码:{{scope.row.goodsNo}}</div>
                  <div class="width-180" show-overflow-tooltip>{{scope.row.skuName}}</div>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="规格" width>
              <template slot-scope="scope">
                <span>{{scope.row.specInfo}}</span>
              </template>
            </el-table-column>
            <el-table-column label="商品分类" width>
              <template slot-scope="scope">
                <span>{{scope.row.classifyName}}</span>
              </template>
            </el-table-column>
            <!--<el-table-column label='建议零售价' width="">
							<template slot-scope="scope">
								<span>￥{{scope.row.msrp}}</span>
							</template>
						</el-table-column>
						<el-table-column label='团购价' width="">
							<template slot-scope="scope">
								<span>￥{{scope.row.minimumGorupbuyyingPrice}}</span>
							</template>
						</el-table-column>
						<el-table-column label='久集价' width="">
							<template slot-scope="scope">
								<span v-if="scope.row.supercPrice">￥{{scope.row.supercPrice}}</span>
								<span v-else>-</span>
							</template>
            </el-table-column>-->
          </el-table>
          <el-pagination
            class="tar"
            v-if="goodsList.length>0"
            :current-page="Page"
            :page-size="10"
            :total="totalCount"
            layout="prev , pager, next, jumper"
            @current-change="handleAddCurrentChange"
          >
            <!-- 分页组件 -->
          </el-pagination>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button type="danger" class="mr-20" @click="handleAddGoods">确定</el-button>
          <el-button class="ml-20" @click="goods=false">取消</el-button>
        </div>
      </el-dialog>
      <!--最低起订量-->
      <el-dialog class="minMop" title="最低起订量" :visible.sync="minMop">
        <el-form ref="minMopForm" class="pr-10" :model="minMopForm" :rules="rules5">
          <el-form-item label="最低起订量:" class="ml-20" required>
            <el-radio-group v-model="radio">
              <el-form-item class="ml-15" prop="unitNum">
                <el-radio style="margin-right: 0" :label="1">{{ }}</el-radio>
                <el-input
                  size="mini"
                  class="ml-10 mr-10"
                  v-model="minMopForm.unitNum"
                  placeholder="最低起订量"
                ></el-input>
                <span class="font-14">{{ minMopForm.units }}</span>
              </el-form-item>
              <el-form-item class="ml-15 mt-10" prop="sellingNum">
                <el-radio style="margin-right: 0" class="mt-20" :label="2">{{ }}</el-radio>
                <el-input
                  size="mini"
                  class="ml-10 mr-10"
                  v-model="minMopForm.sellingNum"
                  placeholder="最低起订量"
                ></el-input>
                <el-select v-model="minMopForm.sellingUnit" size="mini">
                  <el-option
                    v-for="(item, index) in goodsSellings"
                    :label="item.dictValue"
                    :value="item.dictKey"
                    :key="index"
                  ></el-option>
                </el-select>
                <span
                  class="font-14"
                >（合{{ minMopForm.sellingNum }}*{{ minMopForm.specInfoNum }}{{ minMopForm.units }}）</span>
              </el-form-item>
            </el-radio-group>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="danger" @click="handleMinSave">确定</el-button>
          <el-button class="ml-20" @click="minMop = false">取消</el-button>
        </div>
      </el-dialog>
      <!-- 排序 -->
      <el-dialog class="sort" title="序号" :visible.sync="sortvisiable">
        <el-form ref="sortForm" class="pr-10" :model="sortForm" :rules="sortRules">
          <el-form-item label="序号:" class="ml-20" prop="sort">
            <el-input type="text" v-model="sortForm.sort" placeholder="请输入序号" required></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="danger" @click="handleSortSave">确定</el-button>
          <el-button class="ml-20" @click="clearSort">重置</el-button>
        </div>
      </el-dialog>
    </div>
    <!--导出-->
    <form
      ref="exportList"
      action="/sunrise-gateway/export/superc/trader/goods"
      style="display: none"
    >
      <input type="text" name="startRow" v-model="a" />
      <input type="text" name="startCol" v-model="a" />
      <input type="text" v-if="Form.skuNo" name="skuNo" v-model="Form.skuNo" />
      <input type="text" v-if="Form.skuName" name="skuName" v-model="Form.skuName" />
      <input type="text" v-if="Form.classifyId" name="classifyId" v-model="Form.classifyId" />
      <input type="text" v-if="Form.skuState" name="skuState" v-model="Form.skuState" />
      <input type="text" v-if="Form.goodsNo" name="goodsNo" v-model="Form.goodsNo" />
      <input type="text" v-if="idStr" name="idStr" v-model="idStr" />
      <input type="text" name="fileName" v-model="fileName" />
      <input type="text" name="modelName" v-model="modelName" />
    </form>
    <!--导出商品列表-->
    <form
      ref="exportGoodsList"
      action="/sunrise-gateway/export/superc/skugoods"
      style="display: none"
    >
      <input type="text" name="startRow" v-model="a" />
      <input type="text" name="startCol" v-model="a" />
      <input type="text" name="modelName" v-model="h" />
      <input type="text" name="fileName" v-model="j" />
    </form>
    <!-- 下载导入失败文件信息 -->
    <form
      ref="downImportErrForm"
      action="/sunrise-gateway/export/superc/importgoods/failedrecord"
      style="display: none"
    >
      <input type="text" name="startRow" v-model="a" />
      <input type="text" name="startCol" v-model="a" />
      <input type="hidden" name="modelName" v-model="e" />
      <input type="hidden" name="fileName" v-model="f" />
      <input type="hidden" name="key" v-model="g" />
    </form>
  </div>
</template>

<script>
import { axiosApi, gotoLoginPage, basePath } from "~/api/api.js";
let areaList = JSON.parse(localStorage.getItem('areaList'))
export default {
  data() {
    let reg = /^\d{1,6}(\.\d{1,2})?$/;
    //			let reg1 = /^\d+(\.\d{1,2})?$/
    let reg1 = /^([0-9]|([1-9]\d)|(100))(\.\d{1,2})?$/;
    var costPrice = (rule, value, callback) => {
      if (!reg.test(value)) {
        callback(new Error("请输入正确的开票成本价"));
      } else if (
        this.formPrice.minSellingPrice != null &&
        this.formPrice.maxSellingPrice != null
      ) {
        if (
          value * 1 > this.formPrice.minSellingPrice * 1 ||
          value * 1 > this.formPrice.maxSellingPrice * 1
        ) {
          callback(new Error("开票成本价<=最低售价<=最高售价"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    var minSellingPrice = (rule, value, callback) => {
      if (!reg.test(value)) {
        callback(new Error("请输入正确的最低售价"));
      } else if (
        this.formPrice.costPrice != null &&
        this.formPrice.maxSellingPrice != null
      ) {
        if (
          value * 1 < this.formPrice.costPrice * 1 ||
          value * 1 > this.formPrice.maxSellingPrice * 1
        ) {
          callback(new Error("开票成本价<=最低售价<=最高售价"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    var maxSellingPrice = (rule, value, callback) => {
      if (!reg.test(value)) {
        callback(new Error("请输入正确的最高售价"));
      } else if (
        this.formPrice.costPrice != null &&
        this.formPrice.minSellingPrice != null
      ) {
        if (
          value * 1 < this.formPrice.costPrice * 1 ||
          value * 1 < this.formPrice.minSellingPrice * 1
        ) {
          callback(new Error("开票成本价<=最低售价<=最高售价"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    var per = (rule, value, callback) => {
      if (!reg1.test(value)) {
        callback(new Error("只支持小数点后1到2位"));
      } else if (value > 100) {
        callback(new Error("只支持小数点后1到2位"));
      } else {
        callback();
      }
    };
    var validateUnitNum = (rule, value, callback) => {
      if ((value == "" || !value) && this.radio == 1) {
        callback(new Error("请输入最低起订量"));
      } else {
        callback();
      }
    };
    var validateSellingNum = (rule, value, callback) => {
      if ((value == "" || !value) && this.radio == 2) {
        callback(new Error("请输入最低起订量"));
      } else {
        callback();
      }
    };
    var salesPreSaleScaleNum = (rule, value, callback) => {
      //分销佣金
      // if(!this.formPrice.minSellingPrice || this.formPrice.minSellingPrice == ''){
      // 	callback(new Error('请先输入最低售价'));
      // } else if(parseFloat(value) >= parseFloat(this.formPrice.minSellingPrice) && this.formPrice.minSellingPrice) {
      // 	callback(new Error('分销佣金必须小于最低售价'));
      // } else {
      // 	callback();
      // }
    };
    var salesAfterSaleScaleNum = (rule, value, callback) => {
      //分销佣金
      // if(!this.formPrice.minSellingPrice || this.formPrice.minSellingPrice == ''){
      // 	callback(new Error('请先输入最低售价'));
      // } else if(parseFloat(value) >= parseFloat(this.formPrice.minSellingPrice) && this.formPrice.minSellingPrice) {
      // 	callback(new Error('分销佣金必须小于最低售价'));
      // } else {
      // 	callback();
      // }
    };
    return {
      sortvisiable: false,
      id: null, //商品主键id
      sortForm: {
        sort: null
      },
      sortRules: {
        sort: [
          { required: true, message: "请输入序号" },
          {
            pattern: /^[1-9]\d{0,3}$/,
            message: "请输入1-9999之间的整数"
          }
        ]
      },
      e: "superc.importgoods.failedrecord",
      f: "导入失败商品列表信息.xlsx",
      g: null,
      h: "superc.skugoods",
      j: "未添加商品列表.xlsx",
      rules5: {
        unitNum: [
          {
            validator: validateUnitNum
          },
          {
            pattern: /^[1-9]\d{0,2}$/,
            message: "最大支持3位整数"
          }
        ],
        sellingNum: [
          {
            validator: validateSellingNum
          },
          {
            pattern: /^[1-9]\d{0,2}$/,
            message: "最大支持3位整数"
          }
        ]
      },
      rules9: {
        costPrice: [
          {
            required: true,
            message: "开票成本价不能为空",
            trigger: "blur"
          },
          {
            validator: costPrice,
            trigger: "blur"
          }
        ],
        minSellingPrice: [
          {
            required: true,
            message: "最低售价不能为空",
            trigger: "blur"
          },
          {
            validator: minSellingPrice,
            trigger: "blur"
          }
        ],
        maxSellingPrice: [
          {
            required: true,
            message: "最高售价不能为空",
            trigger: "blur"
          },
          {
            validator: maxSellingPrice,
            trigger: "blur"
          }
        ],
        preSaleScale: [
          {
            required: true,
            message: "售前比例不能为空",
            trigger: "blur"
          },
          {
            validator: per,
            trigger: "blur"
          }
        ],
        afterSaleScale: [
          {
            required: true,
            message: "售后比例不能为空",
            trigger: "blur"
          },
          {
            validator: per,
            trigger: "blur"
          }
        ],
        storeType: [
          {
            required: true,
            message: "仓库号不能为空",
            trigger: "blur"
          }
        ],
        salesPreSaleScale: [
          // {
          // 	validator: salesPreSaleScaleNum
          // },
          {
            pattern: /^(\d|[1-9]\d|100)(\.\d{1,2})?$/,
            message: "请输入正确的比例"
          }
        ],
        salesAfterSaleScale: [
          // {
          // 	validator: salesAfterSaleScaleNum
          // },
          {
            pattern: /^(\d|[1-9]\d|100)(\.\d{1,2})?$/,
            message: "请输入正确的比例"
          }
        ]
      },
      type: "0",
      dataTabs: ["未上架", "已上架"],
      Form: {
        skuName: null, //商品名称
        skuNo: null, // sku编码
        goodsNo: null, //商品编码
        classifyId: null, //商品分类
        skuState: null //商品状态
      },
      tableData: [], //商品数据
      classList: [], //分类数据
      skuNoList: [], //上下架商品列表
      skuState: null, //1下架2上架
      stateText: "",
      currentPage: 1,
      total: null, //页面分页总数
      reject: false, //设价弹窗
      formPrice: {
        id: "", //设价Id
        costPrice: null, //开票成本价
        minSellingPrice: null, //最低售价
        maxSellingPrice: null, //最高售价
        preSaleScale: null, //售前比例
        afterSaleScale: null, //售后比例
        skuName: null, //商品名称
        storeType: "1", //是否K3仓库 1：否；2：是
        salesArea: [], //销售区域
        isCountrywide: false, //是否全国
        isIndeterminate: false, //全国是否部分选中
        salesCommission: null, //分销佣金
        salesPreSaleScale: null, // 售前推广员比例
        salesAfterSaleScale: null, // 售后推广员比列
        areas: (() => {
          //添加属性
          areaList.forEach((item, index) => {
            this.$set(item, "checkAll", false); //省 是否 选中
            this.$set(item, "isIndeterminate", false); //省 是否  部分选中
            this.$set(item, "display", false); //市 是否显示
            this.$set(item, "zindex", false); //控制最后显示的区  在最上层
            this.$set(item, "checked", false); //市 是否 选中
            this.$set(item, "citys", []); //存储 选中市 对应的id
          });
          return areaList;
        })() //全国各省
      },
      goods: false, //商品弹窗
      goodsList: [], //可添加商品列表
      Page: 1,
      totalCount: null, //弹窗分页总数
      skuNoList: [], //添加商品列表
      idStr: [], //全部导出选中列表
      fileName: null, //导出文件名称
      a: 0, //写死
      modelName: "superc.trader.goods",
      minMop: false,
      radio: 1,
      // goodsSellings: ['箱','瓶'],//包装分类
      goodsSellings: [], //包装分类
      minMopForm: {
        id: null,
        unitNum: 1, //数量
        sellingNum: 1, //数量
        sellingUnit: null, //规格单位
        specInfoNum: null, //规格
        units: null, //计量单位
        isIntegerMultiple: false //是否包装规格整数倍
      },
      isK3Seller: false, //是否k3
      isSetStoreType: false //是否统一仓
    };
  },
  watch: {
    radio(val) {
      if (val == 1) {
        this.minMopForm.isIntegerMultiple = 0;
      } else {
        this.minMopForm.isIntegerMultiple = 1;
      }
    },
    sortvisiable(val) {
      if (!val) {
        let _this = this;
        setTimeout(() => {
          if (_this.$refs.sortForm) _this.$refs.sortForm.resetFields();
        }, 500);
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      axiosApi(
        "/superc/seller/isK3Seller",
        {},
        res => {
          this.isK3Seller = res.data.data.isK3Seller;
        },
        this.currentPage,
        10,
        res => {
          this.$message({
            type: "info",
            message: res.data.message,
            duration: 3000
          });
        }
      );
      this.achive(); //默认加载商品列表
      this.getClassList(); //加载分类列表
    });
  },
  computed: {
    commission: function() {
      //计算佣金  公式：（最低售价-开票成本价）*售前比例+（最高售价-最低售价）*售后比例
      return (
        (this.formPrice.minSellingPrice * 1 - this.formPrice.costPrice * 1) *
          ((this.formPrice.preSaleScale * 1) / 100) +
        (this.formPrice.maxSellingPrice * 1 -
          this.formPrice.minSellingPrice * 1) *
          ((this.formPrice.afterSaleScale * 1) / 100)
      ).toFixed(2);
    },
    salesSaleCommission: function() {
      return (
        (this.formPrice.minSellingPrice * 1 - this.formPrice.costPrice * 1) *
          ((this.formPrice.salesPreSaleScale * 1) / 100) +
        (this.formPrice.maxSellingPrice * 1 -
          this.formPrice.minSellingPrice * 1) *
          ((this.formPrice.salesAfterSaleScale * 1) / 100)
      ).toFixed(2);
    }
  },
  methods: {
    clearSort() {
      axiosApi(
        "/superc/goodsSort/update",
        {
          supercType: 1,
          id: this.id,
          sort: null
        },
        res => {
          this.$message({
            type: "success",
            message: "重置成功",
            duration: 1500
          });
          this.achive(); //默认加载商品列表
          this.sortvisiable = false;
        },
        this.Page,
        10,
        res => {
          this.$message({
            type: "error",
            message: res.data.message,
            duration: 1500
          });
        }
      );
    },
    handleSortSave() {
      this.$refs.sortForm.validate(valid => {
        if (valid) {
          axiosApi(
            "/superc/goodsSort/update",
            {
              supercType: 1,
              id: this.id,
              sort: parseInt(this.sortForm.sort)
            },
            res => {
              this.$message({
                type: "success",
                message: "设置成功",
                duration: 1500
              });
              this.achive(); //默认加载商品列表
              this.sortvisiable = false;
            },
            this.Page,
            10,
            res => {
              this.$message({
                type: "error",
                message: res.data.message,
                duration: 1500
              });
            }
          );
        }
      });
    },
    openSort(item) {
      this.id = item.id;
      this.sortvisiable = true;
      if (item.sort == null) {
        this.getSort();
      } else {
        this.sortForm.sort = parseInt(item.sort);
      }
    },
    getSort() {
      axiosApi(
        "/superc/goodsSortMax/select",
        {
          supercType: 1
        },
        res => {
          this.sortForm.sort = res.data.data;
        },
        this.Page,
        10,
        res => {}
      );
    },
    disabled(row, index) {
      //弹框过滤可以选中的值
      return row.isAdd != 1; //当isAdd==1为已添加商品,不能选择
    },
    handleTabs(e) {
      //Tab切换
      if (e.target.attributes.type) this.type = e.target.attributes.type.value;
      if (this.type == "0") {
        //未上架
      } else if (this.type == "1") {
        //已上架
      }
    },
    hanleAddGoods() {
      //添加商品
      this.goods = true;
      this.getGoodsList(); //加载可添加商品列表
    },
    handleSelectionCheck(val) {
      //选中的状态
      this.skuNoList = [];
      val.forEach(item => {
        this.skuNoList.push(item.skuNo);
      });
    },
    getGoodsList(val) {
      //可添加商品列表
      if (val) {
        this.Page = 1;
      }
      axiosApi(
        "/superc/seller/selectSellerAllGoodsPageList",
        {
          skuName: this.formPrice.skuName
        },
        res => {
          this.goodsList = res.data.data.elements;
          this.totalCount = res.data.data.totalCount;
          setTimeout(() => {
            //解决异步，使数据同步加载
            if (this.goodsList.length > 0) {
              this.goodsList.forEach(item => {
                if (item.isAdd == 1) {
                  this.$refs.multipleTable.toggleRowSelection(item);
                }
              });
            }
          }, 0);
        },
        this.Page,
        10,
        res => {
          this.$message({
            type: "info",
            message: res.data.message,
            duration: 3000
          });
        }
      );
    },
    handleAddGoods() {
      //添加商品
      if (this.skuNoList && this.skuNoList.length > 0) {
        axiosApi(
          "/superc/traderGoods/batchInsert",
          {
            skuNoList: this.skuNoList
          },
          res => {
            this.goods = false;
            this.achive(); //默认加载商品列表
          },
          this.currentPage,
          10,
          res => {
            this.$message({
              type: "info",
              message: res.data.message,
              duration: 3000
            });
          }
        );
      } else {
        this.goods = false;
        this.$message({
          type: "info",
          message: "没有可添加的商品",
          duration: 3000
        });
      }
    },
    achive(val) {
      //获取已添加商品列表
      if (val) {
        //重置为1
        this.currentPage = 1;
      }
      const api = "/superc/traderGoods/selectPageList";
      const body = {
        skuNo: this.Form.skuNo,
        skuName: this.Form.skuName,
        classifyId: this.Form.classifyId,
        skuState: this.Form.skuState,
        goodsNo: this.Form.goodsNo
      };
      this.$refs.searchForm.validate(valid => {
        if (valid) {
          axiosApi(
            api,
            body,
            res => {
              this.tableData = res.data.data.elements;
              this.total = res.data.data.totalCount;
            },
            this.currentPage,
            10,
            res => {
              this.$message({
                type: "info",
                message: res.data.message,
                duration: 3000
              });
            }
          );
        }
      });
    },
    handleCurrentChange(val) {
      //分页查询
      this.currentPage = val;
      this.achive();
    },
    handleAddCurrentChange(val) {
      //弹窗分页查询
      this.Page = val;
      this.getGoodsList();
    },
    getClassList() {
      //获取分类列表
      axiosApi(
        "/superc/classify/selectFirstClassifyList",
        {},
        res => {
          this.classList = res.data.data;
        },
        this.currentPage,
        10,
        res => {
          this.$message({
            type: "info",
            message: res.data.message,
            duration: 3000
          });
        }
      );
    },
    handleMinimunMoq(scope) {
      //最低起订量数据处理
      this.minMop = true;
      if (scope.row.isIntegerMultiple == 1) {
        this.radio = 2;
      } else {
        this.radio = 1;
      }
      this.minMopForm.id = scope.row.id;
      this.minMopForm.units = scope.row.units; //瓶
      if (scope.row.isIntegerMultiple) {
        this.minMopForm.sellingNum = scope.row.minimunMoq; //销售单位数量
        this.minMopForm.unitNum = null;
      } else {
        this.minMopForm.unitNum = scope.row.minimunMoq; //按瓶的最低起订量
        this.minMopForm.sellingNum = null;
      }

      this.minMopForm.sellingUnit = scope.row.sellingUnit
        ? scope.row.sellingUnit
        : scope.row.goodsSellings[0].dictKey; //规格单位
      this.goodsSellings = scope.row.goodsSellings; //规格分类
      this.minMopForm.specInfoNum = scope.row.specInfoNum; //规格数量
    },
    handleMinSave() {
      //最低起订量
      this.$refs.minMopForm.validate(valid => {
        if (valid) {
          axiosApi(
            "/superc/traderGoods/updateMinNumandMutiple",
            {
              id: this.minMopForm.id,
              isIntegerMultiple: this.minMopForm.isIntegerMultiple,
              unitNum: this.minMopForm.unitNum,
              sellingNum: this.minMopForm.sellingNum,
              sellingUnit: this.minMopForm.sellingUnit
            },
            res => {
              this.minMop = false;
              this.achive(); //刷新列表
              this.$message({
                type: "success",
                message: "设置成功",
                duration: 3000
              });
            },
            this.currentPage,
            10,
            res => {
              this.minMop = false;
              this.achive(); //刷新列表
              this.$message({
                type: "info",
                message: res.data.message,
                duration: 3000
              });
            }
          );
        }
      });
    },
    handleSetPrice(scope) {
      //设价弹窗打开及数据处理
      if (this.$refs.rules9) this.$refs.rules9.resetFields();
      this.reject = true;
      this.formPrice.id = scope.row.id;
      this.formPrice.costPrice = scope.row.costPrice
        ? String(scope.row.costPrice)
        : null; //开票成本价
      this.formPrice.minSellingPrice = scope.row.minSellingPrice
        ? String(scope.row.minSellingPrice)
        : null; //最低售价
      this.formPrice.maxSellingPrice = scope.row.maxSellingPrice
        ? String(scope.row.maxSellingPrice)
        : null; //最高售价
      this.formPrice.preSaleScale =
        scope.row.preSaleScale != null ? String(scope.row.preSaleScale) : null; //售前比例
      this.formPrice.afterSaleScale =
        scope.row.afterSaleScale != null
          ? String(scope.row.afterSaleScale)
          : null; //售后比例
      this.formPrice.salesPreSaleScale =
        scope.row.salesPreSaleScale != null
          ? String(scope.row.salesPreSaleScale)
          : null; //分销佣金
      this.formPrice.salesAfterSaleScale =
        scope.row.salesAfterSaleScale != null
          ? String(scope.row.salesAfterSaleScale)
          : null; //分销佣金
      this.formPrice.storeType =
        scope.row.storeType != null ? String(scope.row.storeType) : null; //K3特殊仓
      this.isSetStoreType = scope.row.isSetStoreType
        ? scope.row.isSetStoreType
        : false;
      this.formPrice.isCountrywide = false; //全国选中
      this.formPrice.isIndeterminate = false; //全国部分选中
      this.formPrice.areas.forEach(item => {
        item.display = false; //默认不打开市
        item.citys = []; //清空未保存所选择的市
        item.checkAll = false; //省全选重置
        item.isIndeterminate = false; //省部分选择重置
      });
      if (scope.row.salesArea) {
        let arr = JSON.parse(scope.row.salesArea);
        arr.forEach(item1 => {
          //返回数据
          this.formPrice.areas.forEach(item2 => {
            //总数据
            if (item1.id == item2.id) {
              //省相等
              item2.checkAll = item1.sub.length == item2.childs.length;
              item2.isIndeterminate =
                item1.sub.length > 0 &&
                item1.sub.length < item2.childs.length;
              item2.citys = item1.sub.map(item => item.id.toString());
              this.formPrice.isCountrywide =
                arr.length == this.formPrice.areas.length;
              this.formPrice.isIndeterminate =
                arr.length > 0 && arr.length < this.formPrice.areas.length;
            }
          });
        });
      }
    },
    isCountry() {
      //全国
      if (this.formPrice.isCountrywide) {
        this.formPrice.areas.forEach(item => {
          item.checkAll = true;
          this.proChange(item);
        });
      } else {
        this.formPrice.areas.forEach(item => {
          item.checkAll = false;
          this.proChange(item);
        });
      }
    },
    proClick(item) {
      //市是否展开
      item.display = !item.display;
      item.zindex = !item.zindex;
      this.formPrice.areas.forEach(item1 => {
        if (item1 != item) {
          item1.zindex = false;
        }
      });
    },
    proChange(item) {
      //省
      item.citys = item.checkAll ? item.childs.map(item => item.id) : [];
      item.isIndeterminate =
        item.citys.length > 0 && item.citys.length < item.childs.length;
      let a = this.formPrice.areas.filter(item => item.checkAll); //过滤被选中的省
      let b = this.formPrice.areas.filter(item => item.isIndeterminate); //过滤部分被选中的省
      this.formPrice.isCountrywide = a.length == this.formPrice.areas.length;
      this.formPrice.isIndeterminate =
        (a.length > 0 || b.length > 0) &&
        a.length < this.formPrice.areas.length;
    },
    cityChange(item) {
      //市
      item.checkAll = item.childs.length == item.citys.length;
      item.isIndeterminate =
        item.citys.length > 0 && item.citys.length < item.childs.length;
      let a = this.formPrice.areas.filter(item => item.checkAll); //过滤被选中的省
      let b = this.formPrice.areas.filter(item => item.isIndeterminate); //过滤部分被选中的省
      this.formPrice.isCountrywide = a.length == this.formPrice.areas.length;
      this.formPrice.isIndeterminate =
        (a.length > 0 || b.length > 0) &&
        a.length < this.formPrice.areas.length;
    },
    handleSetSave() {
      //设价
      this.formPrice.salesArea = []; //重置再选择
      this.formPrice.areas.forEach(item => {
        //省
        if (item.isIndeterminate || item.checkAll) {
          let obj = {};
          obj.id = item.id;
          obj.sub = item.citys.map(item => {
            return {
              id: item
            };
          });
          this.formPrice.salesArea.push(obj);
        }
      });
      this.$refs.rules9.validate(valid => {
        if (valid) {
          //if(this.formPrice.salesArea.length>0){
          axiosApi(
            "/superc/traderGoods/setSupercPriceAndPlace",
            {
              id: this.formPrice.id,
              costPrice: this.formPrice.costPrice,
              minSellingPrice: this.formPrice.minSellingPrice,
              maxSellingPrice: this.formPrice.maxSellingPrice,
              preSaleScale: this.formPrice.preSaleScale,
              afterSaleScale: this.formPrice.afterSaleScale,
              storeType: this.formPrice.storeType, //1：否；2：是
              salesArea: this.formPrice.salesArea,
              salesPreSaleScale: this.formPrice.salesPreSaleScale,
              salesAfterSaleScale: this.formPrice.salesAfterSaleScale
            },
            res => {
              this.reject = false; //关闭弹窗
              this.achive(); //刷新列表
            },
            this.currentPage,
            10,
            res => {
              this.$message({
                type: "info",
                message: res.data.message,
                duration: 3000
              });
            }
          );
        }
      });
    },
    handleSelectionChange(val) {
      /*table全选*/
      this.skuNoList = []; //清空每次全选时添加的订单编号
      this.idStr = []; //清空每次全选时添加的订单Id
      val.forEach(item => {
        this.skuNoList.push(item.skuNo);
        this.idStr.push(item.id);
      });
    },
    handleUp(scope) {
      //上下架
      if (scope.row.skuState == 1) {
        this.skuState = 2;
        this.stateText = "上架";
      } else if (scope.row.skuState == 2) {
        this.skuState = 1;
        this.stateText = "下架";
      }
      if (scope.row.id) {
        //单个商品上下架数据处理
        this.skuNoList = []; //处理每次添加的订单编号
        this.skuNoList.push(scope.row.skuNo);
      }
      const h = this.$createElement;
      this.$msgbox({
        title: "提示信息",
        message: h(
          "p",
          {
            style: "text-align:center"
          },
          "确认" + this.stateText + "该商品？"
        ),
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            if (this.skuNoList.length > 0) {
              axiosApi(
                "/superc/traderGoods/updateState",
                {
                  skuState: this.skuState,
                  skuNoList: this.skuNoList
                },
                res => {
                  this.skuNoList = []; //清空存储的商品编号
                  this.achive(); //刷新列表
                  this.$message({
                    type: "success",
                    message: `${this.stateText}成功`,
                    duration: 3000
                  });
                },
                this.currentPage,
                10,
                res => {
                  this.$message({
                    type: "info",
                    message: res.data.message,
                    duration: 3000
                  });
                }
              );
            } else {
              this.$message({
                type: "info",
                message: "请选择商品",
                duration: 3000
              });
            }
            done();
          } else {
            done();
          }
          delete instance.$slots.default;
        }
      });
    },
    exportExcel() {
      //批量导出
      let str =
        new Date().getFullYear() +
        "-" +
        (new Date().getMonth() + 1) +
        "-" +
        new Date().getDate();
      this.fileName = "商品列表_" + str + ".xlsx";

      setTimeout(() => {
        if (this.tableData.length > 0) {
          this.$refs.exportList.submit();
        } else {
          this.$message.info("请选择导出商品");
        }
      }, 0);
    },
    exportGoodsExcel() {
      //商品下载
      setTimeout(() => {
        this.$refs.exportGoodsList.submit();
      }, 0);
    },
    //批量导入
    handUpBefore(file) {
      //导入前
      this.$loading({
        fullscreen: true,
        lock: true,
        text: "正在导入商品..."
      });
      return true;
    },
    handUpError(err, file, fileList) {
      //导入失败
      this.$loading().close();
      this.$message.error("导入失败！");
    },
    handUpSuccess(res, file, fileList) {
      //导入成功
      this.$loading().close();
      switch (res.code) {
        case "000000": //成功
          let h = this.$createElement;
          if (res.data.failedRows == 0) {
            //失败条数为0
            this.$msgbox({
              title: "提示信息",
              message: h(
                "p",
                {
                  style: "text-align:center"
                },
                [
                  h("p", null, "导入已完成！"),
                  h(
                    "p",
                    null,
                    `共导入信息${res.data.successedRows}条，全部成功！`
                  )
                ]
              ),
              showCancelButton: false,
              confirmButtonText: "确定",
              beforeClose: (action, instance, done) => {
                if (action === "confirm") {
                  done();
                } else {
                  done();
                }
                delete instance.$slots.default;
              }
            });
          } else {
            this.g = res.data.failedRowsKey;
            this.$msgbox({
              title: "提示信息",
              message: h(
                "p",
                {
                  style: "text-align:center"
                },
                [
                  h("p", null, "导入已完成！"),
                  h(
                    "p",
                    null,
                    `共导入信息${res.data.successedRows}条，失败${res.data.failedRows}条，点击下载查看失败原因！`
                  )
                ]
              ),
              showCancelButton: true,
              confirmButtonText: "下载失败文件",
              cancelButtonText: "我不想知道",
              beforeClose: (action, instance, done) => {
                if (action === "confirm") {
                  this.$refs.downImportErrForm.submit(); //下载失败原因
                  done();
                } else {
                  done();
                }
                delete instance.$slots.default;
              }
            });
          }
          this.achive(); //导入完成后刷新列表
          break;
        case "999999": //系统异常
          this.$alert(res.message, "提示信息", {
            confirmButtonText: "确定"
          });
          break;
        case "AUTH_001": //用户未登陆
          gotoLoginPage();
          break;
        case "AUTH_002": //用户无权限
          this.$alert(res.message, "提示信息", {
            confirmButtonText: "确定"
          });
          break;
        case "LOGIN_005": //用户已禁用
          this.$alert(res.message, "提示信息", {
            confirmButtonText: "确定"
          });
          break;
        case "LOGIN_006": //用户session失效
          gotoLoginPage();
          break;
        default:
          this.$alert(res.message, "提示信息", {
            confirmButtonText: "确定"
          });
          break;
      }
    }
  }
};
</script>

<style lang="less">
#goodsodityManage {
  .blue {
    color: #20a0ff;
    display: inline-block;
    width: 30px;
    height: auto;
    text-align: center;
    cursor: pointer;
  }
  .content {
    .el-form--inline {
      .el-form-item__label {
        font-size: 12px;
        width: 60px;
      }
      .order-datatimerange {
        width: 240px;
      }
    }
    .el-button + .el-button {
      margin-left: 0;
    }
    .width-180 {
      width: 180px;
      line-height: 18px;
      text-align: left;
    }
    .el-table .cell {
      text-align: center;
      padding: 10px 0;
      img {
        width: 60px;
        height: 60px;
        margin-left: 10px;
        margin-right: 10px;
      }
      .width-130 {
        width: 100%;
        line-height: 18px;
        text-align: left;
      }
      .el-button {
        height: 20px;
      }
    }
    .el-table__body-wrapper {
      margin-top: 1px !important;
    }

    .reject {
      .el-form-item__label {
        width: 160px;
      }
      .el-input {
        width: 230px !important;
        height: 32px;
      }
      .el-dialog {
        width: 940px;
        .el-dialog__body {
          min-height: 420px;
        }
      }
      .el-form-item__error {
        margin-left: 100px;
      }
    }
    .goods {
      .el-dialog__body {
        padding-top: 30px;
      }
      .el-dialog {
        width: 968px;
      }
      .el-form-item__label {
        width: 90px;
      }
    }
  }
  .city {
    position: absolute;
    z-index: 10;
    width: 335px;
    padding-top: 10px;
    top: 30px;
    left: 0;
    background: #ffffc3;
    .el-checkbox {
      margin: 5px;
    }
  }
  .specialD {
    img {
      width: 60px;
      height: 60px;
      display: inline-block;
      margin-right: 5px;
    }
  }
  .w125 {
    width: 125px;
    position: relative;
  }
  .w100 {
    width: 100px;
  }
  .zindex {
    z-index: 20;
  }
  .w400 {
    width: 420px;
  }
  .el_label {
    width: 160px;
    padding: 0px 10px 11px 0;
    height: 32px;
    line-height: 32px;
    text-align: right;
    vertical-align: middle;
    float: left;
    font-size: 14px;
    color: #48576a;
    box-sizing: border-box;
    img {
      width: 15px;
      height: 15px;
      margin-left: 5px;
      vertical-align: sub;
    }
  }
  .sort {
    .el-dialog {
      min-height: 250px !important;
    }
    .el-form-item__label {
      width: 120px !important;
    }
    .el-form-item__error {
      left: 28%;
    }
  }
  .minMop {
    .el-dialog {
      width: 620px;
      min-height: 300px;
      .el-form-item__error {
        left: 37px;
      }
    }
  }
  .flexCenter{
    display: flex;
    align-items: center;
    .el-checkbox__label{
      display: flex;
      align-items: center;
    }
    .itemNameClass{
      width: 50px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
</style>
