<template>
    <div id="store-supplier-management">
        <!--头部-->
        <div class="title-top mb-5 bg-f">
            我的供应商
            <el-button  class="fr mr-30 mt-17" @click="openDialog" v-if="showButton('ADD', 200132)">添加供应商</el-button>
        </div>

        <div class="pl-30 pr-30 pt-30 pb-50 content bg-f">
            <!--表格 table-->
            <el-table :data="sellerList" stripe v-loading='loading' >
                <el-table-column label='供应商名称' align="center">
                    <template slot-scope="scope">
                        <span>{{scope.row.supplierName}} <span class="color-9 font-12" v-if="scope.row.isFirst==1">(第一供应商)</span> </span>
                    </template>
                </el-table-column>
                <el-table-column label='联系人' align="center" prop="linkName"></el-table-column>
                <el-table-column label='联系电话' align="center" prop="tel"></el-table-column>
                <el-table-column label='联系地址' align="center" width="180">
                    <template slot-scope="scope">
                        <span>{{scope.row.provinceName}}{{scope.row.cityName}}{{scope.row.regionName}}{{scope.row.streetName}}{{scope.row.attr}}</span>
                    </template>
                </el-table-column>
                <el-table-column label='操作' align="center">
                    <template slot-scope="scope">
                        <div v-if="!scope.row.isOnLineSupplier">
                            <el-button type="text" class="ml-10 wh-auto font-12" @click.native='getInfoSupplier(scope.row)' v-if="showButton('EDIT', 200132)">编辑</el-button>
                            <el-button type="text" class="ml-10 wh-auto font-12" @click.native='handleDelete(scope.row)' v-if="showButton('DEL', 200132)">删除</el-button>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination v-if="total>0" class="tar mt-20"  :current-page="currentPage" :page-size='10' :total='total' layout="prev , pager, next, jumper" @current-change="handleCurrentChange">
                <!-- 分页组件 -->
            </el-pagination>
        </div>
        <!--添加供应商弹窗-->
        <el-dialog :close-on-click-modal="false" :title="title" :visible.sync="dialogTableVisible" class="supplierForm" :before-close="resetForm">
            <div class="clearfix">
                <el-form class="" :model="supplierForm" ref="supplierForm" :rules="supplierRules">
                    <el-form-item label-width="150px" label="供应商名称" prop='supplierName'>
                        <el-input v-model.trim="supplierForm.supplierName" :maxlength="20"></el-input>
                    </el-form-item>
                    <el-form-item label-width="150px" label="联系人" prop='linkName'>
                        <el-input v-model.trim="supplierForm.linkName" :maxlength="6"></el-input>
                    </el-form-item>
                    <el-form-item label-width="150px" label="联系电话" prop='tel'>
                        <el-input v-model="supplierForm.tel" :maxlength="11"></el-input>
                    </el-form-item>
                    <div class="clearfix">
                        <el-form-item label-width="150px" label="所在地区" prop="area" class="fl">
                            <selectArea v-model="supplierForm.area" @changeName="changeName"/>
                        </el-form-item>
                    </div>
                    <el-form-item label-width="150px" label="详细地址" prop='attr'>
                        <el-input v-model.trim="supplierForm.attr" :maxlength="40"></el-input>
                    </el-form-item>
                </el-form>
                <div class="text-center">
                    <el-button :loading="btnLoading" type="danger" @click="addSupplier">确定</el-button>
                    <el-button @click="resetForm">取消</el-button>
                </div>
            </div>
        </el-dialog>

    </div>
</template>

<script>
    import { axiosApi } from '../../../api/api' //api
    import { MessageBox } from 'element-ui'
    export default {
        data() {
            var validateAddress = (rule, value, callback) => {
            var info = this.supplierForm.area
            if (info.length===0) {
                callback(new Error('请选择省市区'));
            } else {
                if(info[0]==null){
                callback(new Error('请选择省'));
                }else if(info[1]===null){
                callback(new Error('请选择市'));
                }else if(info[2]===null){
                callback(new Error('请选择区'));
                }else{
                callback();
                }
            }
            };
            return {
                fourListsDelivey: [], //四级地址列表
                title:'添加供应商',
                editId: '',
                editSupplier: false,
                dialogTableVisible:false,
                sellerList: [], //我的供应商列表
                supplierForm: {
                    supplierName: '',
                    area: [],
                    attr: '',
                    linkName: '',
                    tel: ''
                },
                areaNameList: [],
                supplierRules: {
                    supplierName: [
                        {required: true, message: '请输入供货商名称', trigger: 'blur'},
                        { pattern: /^[\u4e00-\u9fa5a-zA-Z\d]{2,40}$/, message: '请输入中、英文、数字，长度在2-40位', trigger: 'blur' }

                    ],
                    linkName: [
                        {required: true, message: '请输入联系人', trigger: 'blur'},
                        { pattern: /^[\u4e00-\u9fa5a-zA-Z\d]{2,20}$/, message: '请输入中、英文、数字，长度在2-20位', trigger: 'blur' }
                    ],
                    tel: [
                        {required: true, message: '请输入联系电话', trigger: 'blur'},
                        { pattern: /^\d{11}$/, message: '请输入正确的电话', trigger: 'blur'},
                    ],
                    area: [
                        { validator: validateAddress, trigger: 'change' },
                        // {type:'array', required: true, message: '请选择地区', trigger: 'blur'},
                        // {type:'array', required: true, message: '请选择地区', trigger: 'change'},
                    ],
                    streetId: [
                        {type: 'number', required: true, message: '请选择街道（镇）', trigger: 'blur'},
                        {type: 'number', required: true, message: '请选择街道（镇）', trigger: 'change'}
                    ],
                    attr: [
                        {required: true, message: '请输入详细地址', trigger: 'blur'},
                        {pattern: /[\S\s]{2,40}/, message: '详细地址长度在2-40位', trigger: 'blur'}
                    ],
                },
                currentPage: 1,
                total: 1,
                traderId: '', //交易对象id 商户
                supplierName: '', //搜索-供应商名称
                loading:false,
                btnLoading: false
            }
        },
        watch: {
            "supplierForm.area": {
                handler:function(val){
                    this.supplierForm.streetId = ""
                    if (!val || val.length === 0) {
                        this.fourListsDelivey = []
                    }
                    if(val[2]){
                        this.selectStreetInfoLists(val[2])
                    }
                },
                deep:true,
                immediate:true
            }
        },
        methods: {
            changeName(e){
                this.areaNameList = e
            },
            openDialog() {
                this.dialogTableVisible = true;
                setTimeout(()=>{
                    this.$refs['supplierForm'] && this.$refs['supplierForm'].resetFields();
                    this.supplierForm =
                        {
                            supplierName: '',
                            area: [],
                            attr: '',
                            linkName: '',
                            tel: '',
                        }
                },50)

                this.title = '添加供应商';
                this.editSupplier = false
            },
            //搜索处理函数
            handleClick(){
                this.buyerFindSellerList(true)
            },
            buyerFindSellerList(bool) { // 供应商列表展示
                if(bool&&this.currentPage != 1) {
                    this.currentPage = 1;
                }
                this.loading = true
                let api = "/warehouse/supplier/selectPage"
                axiosApi(api, {}, this.buyerFindSellerListCallback, this.currentPage, 10,(res)=>{
                    this.loading = false
                    this.$message({type:'error',message:res.data.message,duration:1000})
                })
            },
            buyerFindSellerListCallback(res) { // 供应商列表展示 回调函数
                this.loading = false
                this.sellerList = res.data.data.elements //获取的供应商列表数据
                this.total = res.data.data.totalCount //总条数
                this.currentPage = res.data.data.pageIndex
            },
            handleCurrentChange(val) { //分页处理函数
                this.currentPage = val
                this.buyerFindSellerList()
            },
            deleteRelevanceSellerList(body){   //删除商户的供应商
                const api = "/warehouse/supplier/delete"
                this.loading = true
                axiosApi(api,body,this.deleteRelevanceSellerListCallback,1,10,(res)=>{
                    this.loading = false
                    MessageBox.alert(res.data.message, '提示', {
                        confirmButtonText: '确定'
                    })
                    this.buyerFindSellerList()
                })
            },
            deleteRelevanceSellerListCallback(res){   //删除商户的供应商接口回调函数
                this.$message({
                    type:'success',
                    message:'删除成功'
                })
                this.buyerFindSellerList()
            },
            handleDelete(row) { //删除供应商
                let that = this
                this.$msgbox({
                    title: '提示信息',
                    message: "该数据删除后不可恢复，确认要删除？",
                    showCancelButton: true,
                    confirmButtonText: '删 除',
                    cancelButtonText: '取 消',
                    closeOnClickModal: false,
                    beforeClose: (action, instance, done) => {
                        if(action === 'confirm') {
                            instance.confirmButtonLoading = true;
                            instance.confirmButtonText = '执行中...';
                            setTimeout(() => {
                                instance.confirmButtonLoading = false;
                            }, 300)
                            let body = {
                                id:row.id
                            }
                            that.deleteRelevanceSellerList(body)
                            done()
                        } else {
                            done()
                        }
                    }
                })
            },
            addSupplier() {
                this.$refs.supplierForm.validate((valid) => {
                    if (valid) {
                        this.btnLoading = true
                        let api = ""
                        let {supplierName, linkName, tel, attr } = this.supplierForm
                        let body = {
                            supplierName, linkName, tel, attr,


                            provinceId: this.supplierForm.area[0],
                            cityId: this.supplierForm.area[1],
                            regionId: this.supplierForm.area[2],
                            streetId: this.supplierForm.area[3],

                            provinceName: this.areaNameList[0],
                            cityName: this.areaNameList[1],
                            regionName: this.areaNameList[2],
                            streetName: this.areaNameList[3]
                        }
                        if (this.editSupplier){
                            api = "/warehouse/supplier/update"
                            body.id = this.editId
                        } else{
                            api = "/warehouse/supplier/insert"
                        }
                        axiosApi(api,body,this.addSupplierCallback,1,10,(res)=>{
                            this.btnLoading = false
                            this.$message({
                                type: 'error',
                                message: res.data.message,
                                duration: 2500
                            })
                        })
                    }
                });

            },
            addSupplierCallback(){
                this.$message({
                    type: 'success',
                    message: '操作成功',
                    duration: 1500
                })
                this.btnLoading = false
                this.resetForm()
                this.buyerFindSellerList()
            },
            resetForm() {
                this.$refs['supplierForm'] && this.$refs['supplierForm'].resetFields();
                this.supplierForm =
                    {
                        supplierName: '',
                        area: [],
                        attr: '',
                        linkName: '',
                        tel: ''
                    }
                this.dialogTableVisible = false
            },
            getInfoSupplier(row) {
                this.title = '编辑供应商'
                this.editId = row.id
                axiosApi('/warehouse/supplier/detail/select', {
                    id: row.id
                },res => this.getInfoSupplierCallback(res),1, 10 )

            },
            getInfoSupplierCallback(res){
                let data = res.data.data
             
                this.supplierForm = {
                    ...data,
                    area: [].concat(data.provinceId.toString(),data.cityId.toString(),data.regionId.toString(),data.streetId.toString()),
                    provinceId: data.provinceId,
                    cityId: data.cityId,
                    regionId: data.regionId,
                    provinceName: data.provinceName,
                    cityName: data.cityName,
                    regionName: data.regionName,
                    tel: data.tel ? data.tel.toString() : '',
                    linkName: data.linkName
                }
                this.dialogTableVisible = true
                this.editSupplier = true
                this.$refs['supplierForm'] && this.$refs['supplierForm'].resetFields();
            },
            editSupplierCallback(res) {

            },
            selectStreetInfoLists(val) { //通过区县查询四级街道地址信息
                axiosApi("/unlogin/trader/district/selectStreetInfoList", {
                    parentId: val
                }, (res) => {
                    this.fourListsDelivey = res.data.data
                }, 1, 10000, () => {})
            },
            getStreetName(val){
                const ary = this.fourListsDelivey;
                for (let i = 0; i < ary.length; i++) {
                    if (val === ary[i].id) {
                        this.supplierForm.streetName = ary[i].districtName;
                        break;
                    }
                }
            },
        },
        mounted() {
            this.$nextTick(() => {
                this.traderId = sessionStorage.traderId
                this.buyerFindSellerList();
            })
        }
    }
</script>

<style lang="less">
    .my_title_tabs {
        height: 60px;
        background: #fff;
        width: auto;
        margin: 0 30px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #eee;
        & > div {
            cursor: pointer;
            padding: 0 10px;
            margin-right: 15px;
            height: 24px;
            line-height: 24px;
            border: 1px solid #fff;
            border-radius: 2px;
            &.active {
                border: 1px solid #ff4201;
                color: #ff4201;
            }
        }
    }
    #store-supplier-management .supplierForm {
        .el-dialog {
            width: 700px;
        }
    }
    #store-supplier-management .el-tabs__header{
        padding-left: 0;
    }
    .text-center{
        text-align: center;
    }
    #store-supplier-management .content {
        position: relative;
    }

    #store-supplier-management .exportBtn {
        position: absolute;
        top: 0px;
        right: 30px;
    }

    #store-supplier-management {
        .wh-auto{width: auto;height: auto;}
        .mb-5 {
            margin-bottom: 5px;
        }
        .el-table th>.cell {
            padding-left: 30px;
        }
        .el-table .cell {
            padding-left: 30px;
            color: #666666;
        }
    }
</style>
