<template>
  <div class="hotel-report">
    <div class="title-top bg-f clearfix">
      <div>销售报表-商品详情</div>
    </div>

    <div class="content bg-f pt-50 pb-50 pl-30 pr-30 mt-5">
      <el-table :data="tableData" stripe>
        <el-table-column label='商品图片'>
          <template slot-scope="scope">
            <img v-if="scope.row.skuImage" :src="scope.row.skuImage"/>
            <img v-else src="../../../assets/goods.jpg"/>
          </template>
        </el-table-column>
        <el-table-column label='商品名称' width="200">
          <template slot-scope="scope">
            <span>{{scope.row.skuName}}</span>
          </template>
        </el-table-column>
        <el-table-column label='商品分类'>
          <template slot-scope="scope">
            <span>{{scope.row.classifyName}}</span>
          </template>
        </el-table-column>
        <el-table-column label='单位'>
          <template slot-scope="scope">
            <span>{{scope.row.unit}}</span>
          </template>
        </el-table-column>
        <el-table-column label='销售金额'>
          <template slot-scope="scope">
            <span>{{scope.row.goodsMoney}}</span>
          </template>
        </el-table-column>
        <el-table-column label='销售数量'>
          <template slot-scope="scope">
            <span>{{scope.row.num}}</span>
          </template>
        </el-table-column>
        <el-table-column label='酒店利润'>
          <template slot-scope="scope">
            <span>{{scope.row.sellerCommission}}</span>
          </template>
        </el-table-column>
        <el-table-column label='销售员积分'>
          <template slot-scope="scope">
            <span>{{scope.row.commission}}</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import {axiosApi} from "~/api/api";

export default {
  data() {
    return {
      tableData: [],
      hotelReportData: {}
    }
  },
  methods: {
    achieve() {
      axiosApi('/superc/superStatistics/selectOrderItemSalesReport', {
        supercStoreId: this.hotelReportData.id,
        startTime: this.hotelReportData.startTime,
        endTime: this.hotelReportData.endTime
      }, res => {
        if (res.data.data) {
          this.tableData = res.data.data
        }
      }, 1, 10, err => {
        this.$message({
          type: 'error',
          message: err.data.message,
          duration: 2000
        })
      })
    }
  },
  created() {
    this.hotelReportData = JSON.parse(sessionStorage.getItem('hotelReportData'))
    this.achieve()
  }
}
</script>

<style lang="less">
  .hotel-report {
    .content {
      .el-table .cell {
				text-align: center;
        padding: 10px 0;
      }
    }
  }
</style>
