<template>
    <div class="custorerConfigBox">
        <div class="title-top bg-f mb-5">
            客户录入配置
        </div>
        <div class="mContentBox pt-10 pb-10 bg-f pl-30 pr-30">
            <el-table @select="handleSelect" @select-all="handleSelectAll" @selection-change="handleChange" ref="table" :data="tableList" border tooltip-effect="dark" stripe class="mt-20 table-default">
                <el-table-column
                type="selection"
                width="55">
                </el-table-column>
                <el-table-column prop="name" label="信息名称"></el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-radio-group @change="handleRadioChange(scope.row)" v-model="scope.row.handle">
                            <el-radio :label="1">设为必填项</el-radio>
                            <el-radio :label="0">设为选填项</el-radio>
                        </el-radio-group>
                    </template>
                </el-table-column>
            </el-table>
            <div class="minfo color-6 ml-30 mr-30 mt-20">
                温馨提示：请勾选您需要的信息名称，便于被勾选的信息内容在云销客户信息录入的时候使用！
            </div>
            <div class="mHnadleBox">
                <el-button type="danger" @click="handleSubmit">保存配置</el-button>
            </div>
        </div>
    </div>
</template>

<script>
import { axiosApi, basePath } from "../../../api/api";




export default {
    data: function() {
        return ({
            tableList : [
                {id : 'shopsign' , name : '门店照片' , handle : null},
                {id : 'channel' , name : '渠道类型' , handle : null},
                {id : 'level' , name : '客户等级' , handle : null},
                {id : 'district' , name : '客户区域' , handle : null},
                {id : 'brand' , name : '销售品牌' , handle : null},
            ],
        })
    },
    mounted: function() {
        this.defaultTableList = this.Copy(this.tableList);
        this.init();
    },
    methods: {
        Copy : function(arr){
            let newArr = [];
            newArr = arr.map((v,k) => {
                let type = Object.prototype.toString.call(v) === '[object Array]' ?
                            [] : Object.prototype.toString.call(v) === '[object Object]' ? 
                            {} : null;
                if(type){
                    v = Object.assign(type,v);
                }
                return v;
            });
            return newArr;
        },
        init : function(){
            this.$nextTick(() => {
                this.tableList = this.Copy(this.defaultTableList);
                axiosApi('/trader/addCusAttrConfig/select',{
                    traderId : sessionStorage.getItem('traderId')
                },(res) => {
                    let data = res.data.data;
                    // 初始化 是否必填
                    for(let key in data){
                        this.defaultTableList.map((v,k) => {
                            if(data[key] !== null && v.id === key){
                                this.tableList[k].handle = data[key];
                            }
                        });
                    }
                    this.$refs.table.clearSelection();
                    // 初始化 是否选中
                    this.$nextTick(() => {
                        for(let key in data){
                            this.defaultTableList.map((v,k) => {
                                if(data[key] !== null && v.id === key){
                                    this.$refs.table.toggleRowSelection(this.tableList[k],true);
                                }
                            });
                        }
                    });
                });
            });
        },
        // 表格 check 单个
        handleSelect : function(val,row){
            let index = this.tableList.indexOf(row);
            let handle = null;
            // 当前 选中
            if(val.indexOf(row) !== -1){
                handle = 0;
            }
            this.tableList[index].handle = handle;
        },
        // 表格 check 全部
        handleSelectAll : function(val){
            let handle = null;
            // 当前 全选
            if(val.length !== 0){
                handle = 0;
            }
            this.tableList.map((v,k) => {
                this.tableList[k].handle = handle;
            });
        },
        // 表格中 row 单选框 change
        handleRadioChange : function(row){
            if(row.handle !== null){
                this.$refs.table.toggleRowSelection(row,true);
            }
        },
        handleChange : function(val){
            this.multipleSelection = val;
        },
        handleSubmit : function(){
            let sendUrl = '/trader/addCusAttrConfig/insert';
            let sendObj = {};
            this.multipleSelection.map((v,k) => {
                sendObj[v.id] = v.handle;
            });
            sendObj.traderId = sessionStorage.getItem('traderId');
            axiosApi(sendUrl,sendObj, (res) => {
                let data = res.data.data;
                this.$message.success('保存成功');
                // this.init();
            },1,10,(err) => {
                this.$message.warning(err.data.message);
            });
        },
    },
}
</script>

<style lang="less">

.custorerConfigBox{
    .mContentBox{
        .mHnadleBox{
            text-align: center;
            padding-top: 50px;
            padding-bottom: 30px;
        }
        .el-radio-group{
        	.el-radio__label{
        		font-size: 12px;
        	}
        }
    }
}

</style>

