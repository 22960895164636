<template >
    <div class="">
        <el-form :model="form" ref="form" label-width="98px" :rules="rules">
            <el-form-item label="活动名称:" prop="activityName">
                <div class="inline-block">
                    <el-input v-model.trim="form.activityName" palceholder="输入活动名称" class=""></el-input>
                </div>
                <div class="inline-block ml-40">
                    <template>
                        <!-- <el-checkbox v-model="isOvering">可叠加</el-checkbox> -->
                        <el-checkbox v-model="isNewUser">是否首单</el-checkbox>
                    </template>
                </div>
            </el-form-item>
            <el-form-item label="活动时间:" prop="value1">
                <div class="">
                    <template>
                        <div class="block">
                            <!-- <el-date-picker
                              v-model="form.value1"
                              type="datetimerange"
                              placeholder="选择时间范围">
                            </el-date-picker> -->
                            <ys-daterange-picker v-model="form.value1" type="datetime"/>
                        </div>
                    </template>
                </div>
            </el-form-item>
        </el-form>
        <el-form :model="form" label-width="98px" class="mt-40">
            <el-form-item label="活动规则:" required>
                <div class="">
                    <template>
                        <div class="block">
                            <el-radio class="radio" v-model="secradio" label="1">
                                <span>订购金额</span>
                                <div class="block mt-20 ml-10" v-if="secradio == '1'">
                                    <div class="mt-10" v-for="(x,y) in moneylist" :key="y">
                                        <span class='redalert ml-10'>*</span>
                                        <span class="">订购满</span>
                                        <sy-input v-model="x.fullMoney" type="float" size="mini" :min="0.01"
                                                  :max="99999999.99"></sy-input>
                                        <span>元</span>
                                        <div class="inline-block ml-40">
                                            <span class='redalert ml-10'>*</span>
                                            <span>减</span>
                                            <sy-input v-model="x.minusValue" type="float" size="mini" :min="0.01"
                                                      :max="99999999.99"></sy-input>
                                            <span>元</span>
                                            <span @click="deletemoneylist(y)" class="reddelete">删除</span>
                                        </div>
                                    </div>
                                    <div class="mt-10 ml-10">
                                        <el-button type="text" class="btn" @click="addmoneylist">继续添加</el-button>
                                    </div>
                                </div>
                            </el-radio>
                        </div>
                        <div class="block">
                            <el-radio class="radio" v-model="secradio" label="2">
                                <span>订购数量</span>
                                <div class="block mt-20 ml-10" v-if="secradio == '2'">
                                    <div class="mt-10" v-for="(x,y) in numlist" :key="y">
                                        <span class='redalert ml-10'>*</span>
                                        <span class="">订购满</span>
                                        <sy-input v-model="x.fullAmount" type="int" size="mini" :min="1"
                                                  :max="99999999"></sy-input>
                                        <span>件</span>
                                        <div class="inline-block ml-40">
                                            <span class='redalert ml-10'>*</span>
                                            <span>减</span>
                                            <sy-input v-model="x.minusValue" type="float" size="mini" :min="0.01"
                                                      :max="99999999.99"></sy-input>
                                            <span>元</span>
                                            <span @click="deletenumlist(y)" class="reddelete">删除</span>
                                        </div>
                                    </div>
                                    <div class="mt-10 ml-10">
                                        <el-button type="text" class="btn" @click="addnumlist">继续添加</el-button>
                                    </div>
                                </div>
                            </el-radio>
                        </div>
                    </template>
                </div>
            </el-form-item>
        </el-form>

        <!-- 限定次数 -->
        <el-form :model="form" label-width="98px" class="mt-20">
            <el-form-item label="参与次数:" required>
                <div class="">
                    <template>
                        <div class="">
                            <el-radio class="radio" v-model="isLimitTimes" label="0">不限次数</el-radio>
                            <el-radio class="radio" v-model="isLimitTimes" label="1">限制次数</el-radio>
                        </div>
                        <div class="block mt-20 ml-90" v-if="isLimitTimes == '1'">
                            <span class='redalert ml-10'>*</span>
                            <sy-input v-model="limitTimes" type="int" size="mini" :min="1" :max="99999999"></sy-input>
                            <span>次</span>
                        </div>
                    </template>
                </div>
            </el-form-item>
        </el-form>

        <!-- 选择客户 -->
        <el-form :model="form" label-width="98px" class="mt-20" :rules="rules">
            <el-form-item label="活动对象:" required>
                <div class="">
                    <template>
                        <el-radio class="radio" v-model="sevradio" label="1">所有客户</el-radio>
                        <el-radio class="radio" v-model="sevradio" label="2">部分客户</el-radio>
                    </template>
                    <div class="" v-show="sevradio=='2'" style="max-width: 902px;">
                        <div class='tar pb-20'>
                            <el-button type='text' @click="addclient">添加客户</el-button>
                            <el-button type='text' @click="addAllClients">添加全部客户</el-button>
                            <!-- <el-button type='text' @click="delclients">批量删除</el-button> -->
                        </div>
                        <tableA :size="[100,200,110,170]"></tableA>
                        <chooseClient
                                v-on:closedialog="dialog2Visible = false"
                                :cVisible="dialog2Visible"
                                :ifnotauto="true"
                        >
                        </chooseClient>
                    </div>
                </div>
            </el-form-item>
        </el-form>

        <!-- 选择商品-->
        <el-form :model="form" label-width="98px" class="mt-20">
            <el-form-item label="活动商品:" required>
                <div class="">
                    <template>
                        <el-radio class="radio" v-model="thiradio" label="1">所有商品</el-radio>
                        <el-radio class="radio" v-model="thiradio" label="2">部分商品</el-radio>
                        <!-- <el-radio class="radio" v-model="thiradio" label="3">部分商品不参与</el-radio> -->
                    </template>
                    <div class="" v-show="thiradio!='1'" style="max-width: 902px">
                        <div class='tar pb-20'>
                            <el-button type='text' @click="addGood">添加商品</el-button>
                            <el-button type='text' @click="addAllGoods">添加全部商品</el-button>
                        </div>
                        <tableB :size="[360,,205]" :noclassify="true"></tableB>
                        <chooseGoods
                                v-on:closedialog="dialog1Visible = false"
                                :goodVisible="dialog1Visible"
                                :ifnotauto="true"
                                :isMeal="true"
                                :is-full-gift='true'
                        >
                        </chooseGoods>
                    </div>
                </div>
            </el-form-item>
        </el-form>
        <el-form label-width="98px">
            <div class="mt-10">
                <el-form-item>
                    <el-button type="danger" @click="onSubmit" :loading="loading">保存</el-button>
                    <el-button @click="back">取消</el-button>
                </el-form-item>
            </div>
        </el-form>
    </div>
</template>

<script>
    import {mapMutations, mapActions} from 'vuex'
    import Confirm from '@/utils/formconfirm'
    import {activityname} from '@/utils/formverify'
    import {axiosApi, basePath} from '@/api/api'
    import YsDaterangePicker from "~/components/ys-daterange-picker";

    export default {
        components: {YsDaterangePicker},
        data() {
            var validatevalue1 = (rule, value, callback) => {
                if (!value[0] || !value[1]) {
                    return callback(new Error('请选择活动时间范围'));
                } else if (value[0].getTime() == value[1].getTime()) {
                    return callback(new Error('开始时间和结束时间应设置不同'));
                } else {
                    callback()
                }
            }
            return {
                form: {
                    activityName: '',
                    value1: [],
                },
                moneylist: [
                    {
                        type: '1',
                        fullMoney: '',
                        minusValue: '',
                    }
                ],
                dialog1Visible: false,
                dialog2Visible: false,
                numlist: [
                    {
                        type: '2',
                        fullAmount: '',
                        minusValue: '',
                    }
                ],
                loading: false,
                isOvering: false,
                isNewUser: false,
                checkList: [],
                value2: '',
                thiradio: '1',
                secradio: '1',
                sevradio: '1',
                isLimitTimes: '0',
                limitTimes: "1",
                rules: {
                    activityName: [
                        {validator: activityname, trigger: 'blur'}
                    ],
                    value1: [
                        {validator: validatevalue1, trigger: 'change'}
                    ],
                }
            }
        },
        computed: {
            postDataclient() {//已选择客户id
                return {
                    data: {
                        ids: this.$store.getters.willaddclientid,
                        queryPage: 1, // 0-查询全部 1-分页查询
                        clientType: this.$route.query.activitySource || 1, // B2B和酒店
                    },
                    currentPage: 1
                }
            },
            postDatagood() {//已选择商品id
                let buyerList = this.$store.state.clientlist.willadd || [];
                return {
                    data: {
                        notId: this.$store.getters.willaddid,
                        buyerIds: buyerList.map(v => v.buyerId), // 添加 入参 buyerIds 选择客户的 buyerIds
                        activitySource: this.$route.query.activitySource || 1, // B2B和酒店
                        queryPage: 1, // 0-查询全部 1-分页查询
                        activityType: 2,// 活动类型 满赠送赠品 1  2-满减 3.限时限购 4.满赠(加价购)
                    },
                    currentPage: 1
                }
            },
            postData() {
                return {
                    data: {
                        cutRuleLi: this.secradio == '1' ? this.moneylist : this.numlist,
                        goodsRelationLi: this.$store.state.ticket.willadd.map((a) => {
                            return {skuNo: a.skuNo}
                        }),
                        customerRelation: this.$store.state.clientlist.willadd.map((a) => {
                            return {buyerId: a.buyerId}
                        }),
                        activity: {
                            activitySource: this.$route.query.activitySource || 1, // 传值 1,2 区别酒店营销，B2B营销
                            activityName: this.form.activityName,
                            gmtStart: Date.parse(this.form.value1[0]),
                            gmtEnd: Date.parse(this.form.value1[1]),
                            isLimitTimes: this.isLimitTimes,//是否限定次数
                            limitTimes: this.limitTimes,//限定次数
                            rangeType: this.thiradio,
                            rangCustomerType: this.sevradio,
                            activityType: '2',
                            sellerId: sessionStorage.getItem('traderId'),
                            isOvering: this.isOvering ? '1' : '0',
                            isNewUser: this.isNewUser ? '1' : '0',
                        }
                    }
                }
            },
            activitySource() {
                return (this.$route.query.activitySource ? this.$route.query.activitySource : this.$route.query.couponSource) || 1
            }
        },
        methods: {
            ...mapMutations({
                'deletegoods': 'deletegoods',
                'deleteclients': 'deleteclients',
                'initgood': 'initgood',
				'initclient': 'initclient',
            }),
            ...mapActions({
                'queryclienttableData': 'queryclienttableData',
                'fullgiftSearchGoods': 'fullgiftSearchGoods',
                'chooseAllGoodsAction': 'chooseAllGoodsAction',
                'chooseAllClientsAction': 'chooseAllClientsAction',
            }),
            getsentdetail() {//获取满减活动详情
				return new Promise((resolve, reject) => {
					axiosApi('/marketing/activityCut/Update/view', {activityId: this.$route.query.id}, (res) => {
						resolve(res.data.data)
					}, 0, 10)
				})
			},
            init(sentinfo) {// 初始化编辑页面
				this.form = {
					activityName: sentinfo.activityVo.activityName,
					value1: [new Date(sentinfo.activityVo.gmtStart), new Date(sentinfo.activityVo.gmtEnd)]
				}
				// this.isOvering = sentinfo.activityVo.isOvering ? true : false
				this.isNewUser = sentinfo.activityVo.isNewUser ? true : false
				this.thiradio = sentinfo.activityVo.rangeType + ''//1全部商品参与，2部分商品参与，3部分商品不参与
				this.secradio = sentinfo.activityVo.type + '';//1按订购金额，2按订购数量
                if(sentinfo.activityVo.type==1){
                    this.moneylist = sentinfo.cutRulePos
                }else{
                    this.numlist = sentinfo.cutRulePos
                }
				//限制次数
				this.isLimitTimes = sentinfo.activityVo.isLimitTimes + ''
				this.limitTimes = sentinfo.activityVo.limitTimes + ''
				this.sevradio = sentinfo.activityVo.rangCustomerType + ''//1全部客户参与，2部分客户参与
				//初始化客户列表
				this.initclient(sentinfo.traderVos ? this.initList(sentinfo.traderVos) : [])
				//初始化商品列表
				this.initgood({willadd: sentinfo.goodsSkuVos ? sentinfo.goodsSkuVos : []})
				setTimeout(() => {
					this.isinited = true //已经初始化
				}, 0)
			},
            initList(list){
			// 剔除已删除客户
			var arr = list.filter(item=>{
				return item.buyerId
			})
            return arr
            },
            addactivitycut(data) {
                var that = this
                that.loading = true
                const api = '/marketing/activityCut/add'
                let body = {}
                let newbody = Object.assign({}, body, data.data)
                axiosApi(api, newbody, (res) => {
                    that.$notify({
                        type: 'success',
                        message: '添加满减活动成功,即将跳转到活动列表页面'
                    })
                    setTimeout(function () {
                        that.loading = false
                        that.$router.push(basePath + '/activity/3')
                    }, 1000)
                }, 0, 10, (res) => {
                    that.loading = false
                    that.$notify({
                        type: 'warning',
                        message: '添加满减活动失败,' + res.data.message
                    })
                })
            },
            delgoods() {//批量删除活动商品
                if (this.$store.state.ticket.willdelete.length != 0) {
                    this.$msgbox({
                        title: '提示信息',
                        message: '您确定要删除这些商品？',
                        showCancelButton: true,
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        beforeClose: (action, instance, done) => {
                            if (action === 'confirm') {
                                this.deletegoods()
                                done();
                            } else {
                                done();
                            }
                        }
                    })
                } else if (this.$store.state.ticket.willadd.length == 0) {
                    this.$alert('无商品可删除，请先添加商品', '', {
                        confirmButtonText: '确定',
                        callback: action => {

                        }
                    });
                } else {
                    this.$alert('请先勾选需要删除的商品', '', {
                        confirmButtonText: '确定',
                        callback: action => {

                        }
                    });
                }
            },
            deletemoneylist(y) {
                this.moneylist.splice(y, 1)
            },
            deletenumlist(y) {
                this.numlist.splice(y, 1)
            },
            delclients() {//批量删除客户
                if (this.$store.state.clientlist.willdelete.length != 0) {
                    this.$msgbox({
                        title: '提示信息',
                        message: '您确定要删除这些客户？',
                        showCancelButton: true,
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        beforeClose: (action, instance, done) => {
                            if (action === 'confirm') {
                                this.deleteclients()
                                done();
                            } else {
                                done();
                            }
                        }
                    })
                } else if (this.$store.state.clientlist.willadd.length == 0) {
                    this.$alert('无客户可删除，请先添加客户', '', {
                        confirmButtonText: '确定',
                        callback: action => {

                        }
                    });
                } else {
                    this.$alert('请先勾选需要删除的客户', '', {
                        confirmButtonText: '确定',
                        callback: action => {

                        }
                    });
                }
            },
            addmoneylist() {
                if (this.moneylist.length < 5) {
                    this.moneylist.push({
                        type: '1',
                        fullMoney: '',
                        minusValue: '',
                    })
                } else {
                    this.$message.warning('最多添加5条活动规则')
                }
            },
            addGood() {//添加活动商品
                this.dialog1Visible = true
                this.fullgiftSearchGoods(this.postDatagood)
            },
            addAllGoods() { // 添加全部商品
                this.$msgbox({
                    title: '提示',
                    message: '是否选择全部商品？',
                    showCancelButton: true,
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    beforeClose: (action, instance, done) => {
                        if (action === 'confirm') {
                            instance.confirmButtonLoading = true;
                            instance.confirmButtonText = '执行中...';
                            let currentPath = this.$router.currentRoute.path || '';
                            this.chooseAllGoodsAction({
                                'instance': instance,
                                'done': done,
                                'currentPath': currentPath,
                                'activitySource': this.activitySource
                            });
                        } else {
                            done();
                        }
                    }
                })
            },
            addclient() {//添加客户
                this.dialog2Visible = true
                this.queryclienttableData(this.postDataclient)
            },
            addAllClients() {
                this.$msgbox({
                    title: '提示',
                    message: '是否选择全部客户？',
                    showCancelButton: true,
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    beforeClose: (action, instance, done) => {
                        if (action === 'confirm') {
                            instance.confirmButtonLoading = true;
                            instance.confirmButtonText = '执行中...';
                            let postData = {
                                data: {sellerId: sessionStorage.traderId},
                                currentPage: 1
                            }
                            this.chooseAllClientsAction({
                                'instance': instance,
                                'done': done,
                                'postData': postData,
                                'clientType': this.activitySource
                            });
                        } else {
                            done();
                        }
                    }
                })
            },
            addnumlist() {//添加赠品
                if (this.numlist.length < 5) {
                    this.numlist.push({
                        type: '2',
                        fullAmount: '',
                        minusValue: '',
                    })
                } else {
                    this.$message.warning('最多添加5条活动规则')
                }
            },
            back() {
                this.$router.push(basePath + '/activity/3')
            },
            onSubmit() {
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        let newconfirm = new Confirm();
                        let confirmdata = this.postData.data
                        newconfirm.use([confirmdata.activity.activityName], '活动名称为空！')
                        newconfirm.use([confirmdata.activity.gmtStart], '活动时间为空！')
                        newconfirm.use([confirmdata.activity.gmtEnd], '活动时间为空！')
                        newconfirm.use([confirmdata.activity.isLimitTimes, '1', confirmdata.activity.limitTimes], '限制次数为空！')
                        newconfirm.use([confirmdata.activity.rangCustomerType, '2', confirmdata.customerRelation], '未添加参与活动客户！')
                        newconfirm.use([confirmdata.activity.rangeType, '2', confirmdata.goodsRelationLi], '未添加活动商品！')
                        if (confirmdata.activity.activityName.length > 64) {
                            newconfirm.status = false
                            this.$message({
                                type: 'warning',
                                message: '活动名称最大字符64位，请修改！'
                            })
                        }
                        //    if(confirmdata.activity.gmtStart < new Date().getTime()) {
                        //      newconfirm.status = false
                        //      this.$message({
                        //        type: 'warning',
                        //        message: '活动开始时间应在当前时间之后，请修改！'
                        //      })
                        //    }

                        if (confirmdata.cutRuleLi.length == 0) {
                            newconfirm.status = false
                            this.$message({
                                type: 'warning',
                                message: '未添加活动规则！'
                            })
                        }
                        if (this.secradio == '1') {
                            let temp = false;
                            confirmdata.cutRuleLi.forEach((a, b) => {
                                newconfirm.use([a.minusValue], '减免金额为空！')
                                newconfirm.use([a.fullMoney], '订购金额为空！')
                                newconfirm.cs('dot', a.fullMoney, '订购金额')
                                newconfirm.cs('dot', a.minusValue, '减免金额')
                                if (Number(a.minusValue) > Number(a.fullMoney)) {
                                    temp = true
                                }
                            })
                            if (temp) {
                                newconfirm.status = false
                                this.$message.warning('订购金额不能小于满减金额')
                                return false
                            }

                            var full = confirmdata.cutRuleLi.map((a, b) => {
                                return a.fullMoney
                            })
                            var len1 = full.length
                            // var fullset = new Set(full)
                            // var len2 = fullset.size
                            var fullset = full.unique();//数组去重
                            var len2 = fullset.length;
                            if (len1 != len2) {
                                newconfirm.status = false
                                this.$message({
                                    type: 'warning',
                                    message: '存在相同订购金额的活动规则！'
                                })
                            }
							 this.moneylist.map((v,i) => {
							    if(i < this.moneylist.length-1){
							       
							        if(Number(v.fullMoney) >= Number(this.moneylist[i+1].fullMoney)){
							            newconfirm.status = false
							            this.$message({
							                type: 'warning',
							                message: '下一级满额必须大于上一级满额！'
							            })
							        };
							        if(Number(v.minusValue) >= Number(this.moneylist[i+1].minusValue)){
							            newconfirm.status = false
							            this.$message({
							                type: 'warning',
							                message: '下一级满减金额必须大于上一级满减金额！'
							            })
							        }
							    }
							});
                        } else {
                            confirmdata.cutRuleLi.forEach((a, b) => {
                                newconfirm.use([a.minusValue], '减免金额为空！')
                                newconfirm.use([a.fullAmount], '订购数量为空！')
                                newconfirm.cs('dot', a.fullAmount, '订购金额')
                                newconfirm.cs('dot', a.minusValue, '减免金额')
                            })

                            var full = confirmdata.cutRuleLi.map((a, b) => {
                                return a.fullAmount
                            })
                            var len1 = full.length
                            // var fullset = new Set(full)
                            // var len2 = fullset.size
                            var fullset = full.unique();//数组去重
                            var len2 = fullset.length;
                            if (len1 != len2) {
                                newconfirm.status = false
                                this.$message({
                                    type: 'warning',
                                    message: '存在相同订购数量的活动规则！'
                                })
                            }
                        }

                       
                        if (newconfirm.status) {
                            this.addactivitycut(this.postData)
                        } else {
                            return false
                        }
                    } else {
                        return false;
                    }
                })

            }
        },
        mounted(){
            this.$nextTick(() => {
                if(this.$route.query.id){
                    this.getsentdetail().then(res=>{
                        this.init(res)
                    })
                }
            })
        }
    }
</script>

<style lang="less" scoped>
    .btn {
        text-align: left
    }

    .el-dialog {
        width: 950px!important;
        padding: 0 30px;
        /*height: 725px;*/
    }

    .reddelete {
        margin-left: 20px;
        color: #20a0ff;
    }

    .ml-90 {
        margin-left: 90px;
    }
</style>
