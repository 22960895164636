import Vue from 'vue';

//vue时间过滤 (1293072805)
Vue.filter('time', function(value=0) {
  if(!value) return null
	var myDate = new Date(value);
	var year = myDate.getFullYear();
	var month = myDate.getMonth() + 1;
	var day = myDate.getDate();
	var hours = myDate.getHours();
	var minutes = myDate.getMinutes();
	var seconds = myDate.getSeconds();
	return year + '-' + (month >= 10 ? month : '0' + month) + '-' + (day >= 10 ? day : '0' + day) + ' ' + (hours >= 10 ? hours : '0' + hours) + ':' + (minutes >= 10 ? minutes : '0' + minutes) + ':' + (seconds >= 10 ? seconds : '0' + seconds);
});
Vue.filter('timeI', function(value=0) {
	var myDate = new Date(value);
	var year = myDate.getFullYear();
	var month = myDate.getMonth() + 1;
	var day = myDate.getDate();
	var hours = myDate.getHours();
	var minutes = myDate.getMinutes();
	return year + '-' + (month >= 10 ? month : '0' + month) + '-' + (day >= 10 ? day : '0' + day) + ' ' + (hours >= 10 ? hours : '0' + hours) + ':' + (minutes >= 10 ? minutes : '0' + minutes);
});
//vue时间过滤 (1293072805)
Vue.filter('timenyr', function(value=0) {
	var myDate = new Date(value);
	var year = myDate.getFullYear();
	var month = myDate.getMonth() + 1 < 10 ? '0' + (myDate.getMonth() + 1) : myDate.getMonth() + 1;
	var day = myDate.getDate() < 10 ? '0' + myDate.getDate() : myDate.getDate();
	var hours = myDate.getHours() < 10 ? '0' + myDate.getHours() : myDate.getHours();
	var minutes = myDate.getMinutes() < 10 ? '0' + myDate.getMinutes() : myDate.getMinutes();
	var seconds = myDate.getSeconds() < 10 ? '0' + myDate.getSeconds() : myDate.getSeconds();
	return year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds;
});
//vue时间过滤（不带时分秒） (1293072805)
Vue.filter('timenData', function(value=0) {
	var myDate = new Date(value);
	var year = myDate.getFullYear();
	var month = myDate.getMonth() + 1 < 10 ? '0' + (myDate.getMonth() + 1) : myDate.getMonth() + 1;
	var day = myDate.getDate() < 10 ? '0' + myDate.getDate() : myDate.getDate();
	return year + '-' + month + '-' + day;
});
//vue时间过滤（不带时分秒） (1293072805)
Vue.filter('timenData2', function(value=0) {
	var myDate = new Date(value);
	var year = myDate.getFullYear();
	var month = myDate.getMonth() + 1 < 10 ? '0' + (myDate.getMonth() + 1) : myDate.getMonth() + 1;
	var day = myDate.getDate() < 10 ? '0' + myDate.getDate() : myDate.getDate();
	return year + '年' + month + '月' + day + '日';
});
// 新增
Vue.filter('timeown', function(value=0) {
	var hours = parseInt((value) / (60 * 60));
	var minutes = parseInt((value - hours * 60 * 60) / (60));
	var seconds = value - hours * 60 * 60 - minutes * 60;
//	return days + " 天 " + hours + " 小时 " + minutes + " 分钟 " + seconds + " 秒 ";
	return hours + " 小时 " + minutes + " 分钟 " + seconds + ' 秒 ';
});
Vue.filter('timenyrr', function(value=0) {
	var myDate = new Date(value);
	var year = myDate.getFullYear();
	var month = myDate.getMonth() + 1 < 10 ? '0' + (myDate.getMonth() + 1) : myDate.getMonth() + 1;
	var day = myDate.getDate() < 10 ? '0' + myDate.getDate() : myDate.getDate();
	var hours = myDate.getHours() < 10 ? '0' + myDate.getHours() : myDate.getHours();
	var minutes = myDate.getMinutes() < 10 ? '0' + myDate.getMinutes() : myDate.getMinutes();
	var seconds = myDate.getSeconds() < 10 ? '0' + myDate.getSeconds() : myDate.getSeconds();
	return hours + ':' + minutes + ':' + seconds;
});
Vue.filter('getHM', function(value=0) {
	var myDate = new Date(value);
	var hours = myDate.getHours() < 10 ? '0' + myDate.getHours() : myDate.getHours();
	var minutes = myDate.getMinutes() < 10 ? '0' + myDate.getMinutes() : myDate.getMinutes();
	if(value) {
		return hours + ':' + minutes;
	}else {
		return ''
	}
});

//vue状态过滤
Vue.filter('status', function(value) {
	switch(value) {
		case 1:
			return '全部';
		case 2:
			return '未开始';
		case 3:
			return '进行中';
		case 4:
			return '已结束';
		case 5:
			return '已终止'
		default:
			break;
	}
})
//vue状态过滤
Vue.filter('status2', function(value) {
	switch(value) {
		case '0':
			return '未开始';
		case '1':
			return '进行中';
		case '2':
			return '已结束';
		case '3':
			return '全部';
		default:
			break;
	}
})
//vue数字转字符串
Vue.filter('toString', function(value) {
	return value.toString();
});
//倒计时过滤器 秒
Vue.filter("seconds",function (value) {
	return "(" + value + "s)";
})

//vue 钱过滤器
//Vue.filter('formatCurrency', function(num) {
//	num = num.toString().replace(/\$|\,/g, '');
//	if(isNaN(num))
//		num = "0";
//	let sign = (num == (num = Math.abs(num)));
//	num = Math.floor(num * 100 + 0.50000000001);
//	let cents = num % 100;
//	num = Math.floor(num / 100).toString();
//	if(cents < 10)
//		cents = "0" + cents;
//	for(var i = 0; i < Math.floor((num.length - (1 + i)) / 3); i++)
//		num = num.substring(0, num.length - (4 * i + 3)) + ',' +
//		num.substring(num.length - (4 * i + 3));
//	return '￥' + (((sign) ? '' : '-') + num + '.' + cents);
//})
Vue.filter('fmoney', function(s=0,n=2) { //s:传入的float数字 ，n:希望返回小数点几位
  let minus = s < 0 ? true : false
	let m = s < 0 ? -s : s || 0
	m = parseFloat((m + "").replace(/[^\d\.-]/g, ""))
	m = (Math.round(m * 100) / 100).toFixed(n)+""
	var l = m.split(".")[0].split("").reverse(),
		r = m.split(".")[1];
	let t = "";
	for(let i = 0; i < l.length; i++) {
		t += l[i] + ((i + 1) % 3 == 0 && (i + 1) != l.length ? "," : "");
  }
  let res = t.split("").reverse().join("") + "." + r;
  if (minus) {
    return '-' + res
  } else {
    return res
  }
})

//标准时间转化为时间戳
export function timeStamp(str) {
	str = str.replace(/-/g, "/");
	var date = new Date(str);
	return date.getTime();
}
//手机号或身份证隐藏其中一些字段以***代替(传参首位和末位)
Vue.filter("safehide", function(str, a=3, b=4) {
	//return str.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2');
	return str.replace(str.substr(a, str.length-a-b), "*".repeat(str.length-a-b))
})
//银行卡号取后四位
Vue.filter("end", function(str, a=4) {
	return str.substr(str.length - a);
})

//2017-11-30 13:35 转换为 eg: 22017/11/30 周四 12:00
Vue.filter('timeWeek', function(val) { //入参格式 2017-11-30 13:35
  val = val.replace(/-/g,'/') //safria不支持2017-11-30格式，须改成2017/11/30格式
  let date = new Date(val)
  var year = date.getFullYear();
  var month = (date.getMonth()+1) >= 10 ? (date.getMonth()+1) : '0' + (date.getMonth()+1)
  var day = date.getDate() >= 10 ? date.getDate() : '0' + date.getDate()
  var hour = date.getHours() >= 10 ? date.getHours() : '0' + date.getHours()
  var minute = date.getMinutes() >= 10 ? date.getMinutes() : '0' + date.getMinutes()
  let week = ''
  switch (date.getDay()) {
    case 0:week="周日";break
    case 1:week="周一";break
    case 2:week="周二";break
    case 3:week="周三";break
    case 4:week="周四";break
    case 5:week="周五";break
    case 6:week="周六";break
  }
  return year + "/" + month + "/" + day + " " + week + " " + hour + ":" + minute
})
// 1000m 转化 1Km 
Vue.filter('meters', function(val){
  if(val==0) return 0+'m'
  if(!val) return null;
  if(val<1000){
    return val+'m'
  }else{
    return val/1000 + 'km'
  }
})

//信息显示*号

Vue.filter('hideInformation',function(val){
	if(val){
		let len = val.length - 1;
		return val.substr(0, 1) + '*********' + val.substr(len, 1)
	}
    
    
})

Vue.filter('formatNumber',function(num){
	let reg=/\d{1,3}(?=(\d{3})+$)/g;
	return (num + '').replace(reg, '$&,');
})