<template>
	<!--超团订单-->
	<div id="groupOrder">
		<div class="title-tabs bg-f black mb-5">
			<ul class="clearfix">
				<template v-for='(tab,index) in tabs' class="item">
					<li :type='index' :class="type==index?'active':''" :key="index+'ag'" @click='handleTabs'>
						{{tab}}
						<div :class="type==index?'under-line':''"></div>
					</li>
					<div class="vertical-line" :key="index+'ah'"></div>
				</template>
			</ul>
		</div>
		<div class='bg-f'>
			<div class="ml-30 mr-30 second-title clearfix">
				<div class="choose-three font-14 color-6 fl tac mr-10 cursor " :class="first == 0?'actived':''" @click="getStatus(0)">
					全部订单({{badge.allCount}})
				</div>
				<div class="choose-three font-14 color-6 tac fl mr-10 cursor" :class="first == 1?'actived':''" @click="getStatus(1)">
					待付款({{badge.notPayCount}})
				</div>
				<div class="choose-three font-14 color-6 tac fl cursor" :class="first == 2?'actived':''" @click="getStatus(2)">
					待发货({{badge.notDeliveryCount}})
				</div>
				<div class="choose-three font-14 color-6 tac fl cursor" :class="first == 3?'actived':''" @click="getStatus(3)">
					已发货({{badge.orderDeliveriedCount}})
				</div>
				<div class="choose-three font-14 color-6 tac fl cursor" :class="first == 4?'actived':''" @click="getStatus(4)">
					已完成({{badge.successCount}})
				</div>
				<div class="choose-three font-14 color-6 tac fl cursor" :class="first == 5?'actived':''" @click="getStatus(5)">
					已关闭({{badge.cancleCount}})
				</div>
			</div>
		</div>
		<div class="content bg-f pl-30 pr-30 pb-50">
			<div class="clearfix search">
				<el-form :inline='true' class="pt-30" :model="form" ref="searchForm">
					<el-form-item label='订单编号' class='mr-20' prop="sn">
						<el-input v-model.trim="form.orderSn" placeholder="请输入订单编号" :maxlength="25" @input="form.orderSn=form.orderSn.replace(/[\u4e00-\u9fa5]/ig,'')"></el-input>
					</el-form-item>
					<el-form-item label='商品名称' class='mr-20'>
						<el-input v-model.trim="form.itemName" :maxlength="30" placeholder="请输入商品名称"></el-input>
					</el-form-item>
					<el-form-item label='超团卖家' class='mr-20'>
						<el-input v-model.trim="form.storeName" :maxlength="30" placeholder="请输入商家名称"></el-input>
					</el-form-item>
					<el-form-item label='收货人' class='mr-20'>
						<el-input v-model.trim="form.addrUsername" :maxlength="30" placeholder="请输入收货人名称"></el-input>
					</el-form-item>
					<el-form-item label='下单时间' class='mr-20'>
						<ys-daterange-picker v-model="form.orderDateRange" :endDisabledDate="new Date()" />
					</el-form-item>
					<el-form-item label="手机号" v-if="type==0">
						<el-input :maxlength="11" v-model.trim="form.buyerPhone" placeholder="请输入手机号" :onkeyup="this.form.buyerPhone=this.form.buyerPhone?this.form.buyerPhone.replace(/\D/g, ''):''"></el-input>
					</el-form-item>
				</el-form>
				<div>
					<el-button class="mb-20" type='info' @click="handleCheck(0)" icon="el-icon-search">搜 索</el-button>
				</div>
			</div>
			<div class='tar clearfix mb-10'>
				<el-button type='text' class="fr" style="width: 100px;" @click="checkTable">查看已生成报表</el-button>
				<el-button type='text' class="fr" @click="exportOrder">批量导出</el-button>
			</div>
			<div class="order-list" v-loading="loading">
				<el-table>
					<el-table-column label='商品' min-width="170" align="center"></el-table-column>
					<el-table-column align="center" label='单价' min-width="85"></el-table-column>
					<el-table-column align="center" label='数量' min-width="85"></el-table-column>
					<el-table-column align="center" label='类型' min-width="85"></el-table-column>
					<el-table-column align="center" label='超团商家' min-width="85"></el-table-column>
					<el-table-column align="center" label='顾客' min-width="85"></el-table-column>
					<el-table-column align="center" label='订单状态' min-width="85"></el-table-column>
					<el-table-column align="center" label='金额' min-width="85"></el-table-column>
					<el-table-column align="center" label='操作' min-width="85"></el-table-column>
				</el-table>
				<div class="table-item-default tac" v-if="ordersListResVo.length<=0">
					<img src="../../assets/empty.png" alt="" />
					<div class="color-danger mt-30">
						<router-link :to="{path:'/customer/shop/shopIndex'}">
							<span class="color-danger"> 啥都没有，去首页看看 ></span>
						</router-link>
					</div>
				</div>
				<div v-else>
					<div class="table-item mt-10" v-for='(orders, index) in ordersListResVo' :key='index'>
						<div class="clearfix table-item-top lh-20 bbd bg-e">
							
							<span v-if='orders.isSplit' class='fl ml-30'>
							    订单编号：{{orders.mainOrderSn}}
							</span>
							<span v-else class='fl ml-30'>
								<span v-if='orders.orderStatus==1 || orders.orderStatus==0'>订单编号:{{orders.mainOrderSn}}</span>
								<span v-else>
								订单编号：{{orders.smallGrouponOrderListVOList[0].sn}} 
								    <span v-if='orders.smallGrouponOrderListVOList[0].k3Sn'>ERP销售单号:{{orders.smallGrouponOrderListVOList[0].k3Sn}}</span>
									<span v-if='orders.smallGrouponOrderListVOList[0].k3OutStockNos'>ERP出库单号：{{orders.smallGrouponOrderListVOList[0].k3OutStockNos}}</span>
								</span>
							</span>
							<span  class='fr color-6 ' style="color: #409eff ;cursor: pointer; margin-right: 80px;" type="text"  v-if="orders.orderStatus==1 && showButton('SALE_ORDER_CANCEL',140004) && type == 0 && orders.source != 7" @click="handleCancelOrder(orders.mainOrderSn)">取消订单</span>
							<span class='fl color-6 ml-30'>下单时间：{{orders.gmtCreate | time}}</span>
							<span v-if='orders.isSplit' class='fl color-6 ml-30 '>订单状态:已拆分</span>
							<span class='fr color-6 mr-30' v-if="orders.orderStatus==1">还剩
								<span class="color-danger">{{orders.remainAutoCancleTime}}</span>
								自动取消
							</span>
								
						</div>
						<div v-for='(order,i) in orders.smallGrouponOrderListVOList' :key="i+'ai'">
							<div v-if='orders.isSplit' class="clearfix table-item-top lh-20 bbd">
								<span class='fl ml-30'>
								订单编号：{{order.sn}}
							    <span v-if='order.k3Sn'>ERP销售单号：{{order.k3Sn}}</span>
								<span v-if='order.k3OutStockNos'>ERP出库单号：{{order.k3OutStockNos}}</span>
							    </span>
							</div>
							<div class="table-item-content clearfix bbd" style="display: flex;">
								<div class=" clearfix" style="flex:4;box-sizing: border-box;">
									<!--商品清单-->
									<div class="clearfix " :class='index != order.orderItemVos.length-1 ? "bb":""' v-for="(orderItem, index) in order.orderItemVos" :key="index+'ac'" style="padding: 20px 0 20px 20px;display:flex">
										<div class="fl" style="flex:2;display:flex">
											<div style="position: relative;">
												<img v-if="orderItem.skuImage" :src="orderItem.skuImage" class="fl mr-20" />
												<img v-else src="../../assets/goods.jpg" class="fl mr-20" />
											</div>
											<div class="fl">
												<p class="clearfix">
													<span class="fl">商品编码:</span>
													<span class="fl" style="word-wrap:break-word">{{orderItem.k3GoodsNo}}</span>
												</p>
												<p>
													<img v-if='orderItem.goodsInfo && orderItem.goodsInfo.bizType == 1'  src="../../assets/superGroup/jiujiajiu.png" style="width: 45px;height: 15px;vertical-align:middle"/>
													<span style="position: relative;top: 2px;">{{orderItem.skuName}}</span>
												</p>
											</div>
										</div>
										<div class="fl tac " style="flex:1">¥{{orderItem.originPrice | fmoney}}</div>
										<div class="fl tac" style="flex:1">{{orderItem.num}} <span>{{orderItem.unit}}</span> </div>
									</div>
									<!--赠品清单-->
									<div class="clearfix" :class='{bb:index != order.orderActivityGiftRelationVOs.length-1,btop:index == 0}' v-for="(orderItem, index) in order.orderActivityGiftRelationVOs" :key="index+'ad'" style="padding: 20px 0 20px 20px;display:flex">
										<div class="fl" style="flex:2;display:flex">
											<div style="position: relative;">
												<img v-if="orderItem.skuImage" :src="orderItem.skuImage" class="fl mr-20" />
												<img v-else src="../../assets/goods.jpg" class="fl mr-20" />
												<!--赠品标识-->
												<img src="../../assets/superGroup/complimentary.png" style="position: absolute;top: 0;left: 0;" />
											</div>
											<div class="fl">
												<p class="clearfix" >
													<span class="fl">商品编码:</span>
													<span class="fl" style="word-wrap:break-word">{{orderItem.k3GoodsNo}}</span>
												</p>
												<p>
													<img v-if='orderItem.goodsInfo && orderItem.goodsInfo.bizType == 1'  src="../../assets/superGroup/jiujiajiu.png" style="width: 45px;height: 15px;vertical-align:middle"/>
													<span style="position: relative;top: 2px;">{{orderItem.skuName}}</span>
												</p>
											</div>
										</div>
										<div class="fl tac " style="flex:1">¥{{0 | fmoney}}</div>
										<div class="fl tac" style="flex:1">{{orderItem.giftNum}} <span>{{orderItem.sellingUnitName}}</span> </div>
									</div>
								</div>
								<div class="bl tac pt-20" style="flex:1">
									{{(order.source == 5 || order.source == 6) ? '用户订单':order.source == 7 ? '商家订单':'--'}}
								</div>
								<div class="bl tac pt-20" style="flex:1">{{order.sellerName}}</div>
								<div class="bl pt-20" style="flex:1;word-wrap:break-word;text-align:left;">
									<div style="margin:0 5px">
										<div v-if="type==0">
											<div>姓名：{{order.buyerName}}</div>
											<div>账号：{{order.buyerPhone}}</div>
										</div>
										<div>收货人：{{order.addrUsername}}</div>
									</div>
									
								</div>
								<div class="bl tac pt-20" style="flex:1">
									<span v-if="order.orderStatus==0">已关闭</span>
									<span v-if="order.orderStatus==1">待付款</span>
									<span v-if="order.orderStatus==2">待发货</span>
									<span v-if="order.orderStatus==3">已发货</span>
									<span v-if="order.orderStatus==4">已完成</span>
								</div>
								<div class="bl tac pt-20" style="flex:1">
									<div class="tac">¥{{order.needPayMoney}}</div>
									<div class="tac">（含运费：¥{{order.freightMoney |fmoney}}）</div>
								</div>
								<div class="bl tac pt-20" style="flex:1">
									<div class="w100">
										<!-- <el-button type="text" v-if="order.orderStatus==1 && showButton('SALE_ORDER_CANCEL',140004) && type == 0 && order.source != 7" @click="handleCancelOrder(orders.mainOrderSn)">取消订单</el-button> -->
										<el-button type="text" v-if="order.orderStatus==2 && showButton('SALE_ORDER_SEND',140004) && type == 0 && order.headName == 'virtual' && identity == 'central'" @click="handleSend(order.sn)">发货</el-button>
										
										<el-button type="text" class="ml-0" @click="orderDetail(order.sn)">订单详情</el-button>
										<!-- <el-button v-if='orders.isSplit && (orders.orderStatus!=1 && orders.orderStatus!=0)' type="text" class="ml-0" @click="orderDetail(orders.smallGrouponOrderListVOList[0].sn,'son')">订单详情</el-button>
										<el-button v-if='!orders.isSplit' type="text" class="ml-0" @click="orderDetail(order.sn,'son')">订单详情</el-button> -->
									</div>
								</div>
							</div>
						</div>
					</div>
					<!--</el-checkbox-group>-->
					<!--取消订单弹出框-->
					<el-dialog class='cancelOrderDialog' title='取消订单理由' :visible.sync='cancelOrderVisible'>
						<el-form class="pl-30 pr-30" :model="form" ref="cancelOrderForm" :rules="rules2">
							<el-form-item label="" prop='cancleReason'>
								<el-input type='textarea' placeholder='请输入取消订单的理由...' :autosize="{ minRows: 3 ,maxRows: 10}" v-model.trim='form.cancleReason'></el-input>
							</el-form-item>
						</el-form>
						<div slot='footer' class="dialog-footer">
							<el-button type='danger' @click="handleCommit(2)">确 定</el-button>
							<el-button @click="cancelOrderVisible = false">取 消</el-button>
						</div>
					</el-dialog>
				</div>
				<el-pagination v-if='total>0' class="tar mt-20" :current-page.sync="currentPage" :page-size='10' :total='total' layout="prev , pager, next, jumper" @current-change="handleCurrentChange">
					<!-- 分页组件 -->
				</el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
	import { axiosApi, basePath } from "../../api/api"
	import YsDaterangePicker from "~/components/ys-daterange-picker";
	import { mapMutations } from 'vuex';
	export default {
		components: {YsDaterangePicker},
		data() {
			return {
				aaa:true,
				datas: [1, 1],
				identity: '',
				searchType: 2,

				a: 'empty',
				b: '订单列表.xls',
				c: 0,
				/*规则*/
				rules2: {
					cancleReason: [{
						required: true,
						message: '取消订单理由不能为空'
					}, {
						min: 4,
						max: 100,
						message: '取消订单理由为4-100位字符'
					}]
				},
				sellerId: sessionStorage.traderId,
				buyerId: sessionStorage.traderId,
				type: '0',
				typeName: '销售订单',
				tabs: ['销售订单', '采购订单'],
				data: ['1'],
				cancelOrderVisible: false, //取消订单弹框
				reason: null,
				ordersListResVo: [], //订单数据
				sn: null, //订单编号
				flag:false,
				cloneForm:{},
				form: {
					orderSn: null, //订单编号
					itemName: null, //商品名称
					orderStatus: null, //订单状态
					addrUsername: null, //买家名称
					storeName: null, //卖家名称
					gmtOrderStart: null,
					gmtOrderEnd: null,
					orderDateRange: [], //gmtOrderStart下单开始时间 gmtOrderEnd下单结束时间
					sourceType: '5',
					buyerPhone: ''

				},
				rules: {
					cancleReason: [{
						max: 30,
						message: '不能超过30个字！'
					}]
				},
				clearTime: 1, //定时器
				loading: false,
				currentPage: 1,
				total: null,
				badge: { //标记
					notPayCount: 0, //未付款
					notDeliveryCount: 0, //已付款-待发货
					orderDeliveriedCount: 0, //已发货-待收货
					successCount: 0, //已完成
					cancleCount: 0, //已取消
					notAuditCount: 0, //未审核
					allCount: 0, //全部
					orderDeliveriedCount: 0,
					cancleCount: 0, //已关闭
				},
				checkAll: false, //选中状态
				checkOrder: [], //选中的每行订单数据
				checkOrderSn: [], //选中的每行订单数据
				first: 0,
			}
		},
		activated() {
			this.$nextTick(() => {
				sessionStorage.GroupOrderType = 0
				//导出的选中数据  清空
				this.checkOrderSn = ""
				this.form.sellerId = sessionStorage.traderId
				this.form.buyerId = sessionStorage.traderId
				this.isShow()
				//				sessionStorage.removeItem('type')
			})
		},
		watch:{
			form:{
				handler:function () {
					this.flag = false
				},
				deep:true
			}
		},
		methods: {
			...mapMutations({
				'setTask':'setTask'
			}),
			//查看已生成报表
			checkTable(){
				let taskType = this.type==1 ? 'groupon_purchase_order' : 'groupon_order';
				this.setTask({
					url:'/datacenter/exportTask/listExportTask',
					businessType:'groupon',
					taskType : taskType
				});
				this.$router.push(basePath + '/statement')
			},
			//批量导出
			exportOrder() {
				if(this.cloneForm){
					if(this.cloneForm.orderDateRange){
						if(!this.cloneForm.orderDateRange[0] || !this.cloneForm.orderDateRange[1]){
							this.$message.warning("请选择下单时间并查询，再进行批量导出");
							return
						}
					}else {
						this.$message.warning("请选择下单时间并查询，再进行批量导出");
						return
					}

				}else{
					this.$message.warning("请选择下单时间并查询，再进行批量导出");
					return
				}
				this.cloneForm.searchType = this.searchType;
				// delete this.cloneForm.cancleReason;



				let url = this.type ? '/datacenter/exportTask/addGrouponOrder' : '/datacenter/exportTask/addGrouponOrderExportTaskServiceImpl';

				axiosApi(url,this.cloneForm,res => {
					this.$notify({
						title: '成功',
						message: '导出的文件正在生成，请不要重复点击，文件生成后，可以在“查看已生成报表”中查看!',
						type: 'success'
					});
				},1,10,err => {
					this.$message.error(err.data.message);
				})

			},
			handleCheckAllChange(val) { /*table全选*/
				if(this.checkAll) {
					this.ordersListResVo.forEach(item => {
						this.checkOrder.push(item)
					});
					this.checkOrderSn = this.ordersListResVo.map(i => i.sn).join()
				} else {
					this.checkOrder = [];
					this.checkOrderSn = ""
				}
			},
			handleCheckChange(val) { //单选
				let checkedCount = val.length;
				this.checkAll = checkedCount === this.ordersListResVo.length;
				this.checkOrderSn = val.map(i => i.sn).join()
			},
			anonOrderCount() { //订单统计(角标)
				axiosApi('/groupon/orderStatusCount/select', {
					sellerId: sessionStorage.traderId,
					sourceType: this.form.sourceType
				}, (res) => {
					this.badge = res.data.data
				}, 1, 10, (res) => {

				})
			},
			handleCurrentChange(val) { //分页
				this.checkAll = false
				this.currentPage = val;
				this.queryOrderList(this.form);
				this.anonOrderCount()
			},
			handleTabs(e) {
				this.checkAll = false
				this.first = 0
				//				if(e.target.type) this.type = e.target.type // 改变 type的值
				if(e.target.attributes.type) {
					this.type = e.target.attributes.type.value
					this.typeName = e.target.attributes.type.ownerElement.innerText.trim()
				}
				sessionStorage.GroupOrderType = this.type
				this.form = {
					orderSn: null, //订单编号
					itemName: null, //商品名称
					orderStatus: null, //订单状态
					gmtOrderStart: null,
					gmtOrderEnd: null,
					orderDateRange: [], //gmtOrderStart下单开始时间 gmtOrderEnd下单结束时间
					cancleReason: null, //订单取消原因
					//					sourceType:'platform'
					sourceType: '5',
					buyerPhone: ''
				}
				console.log(this.typeName,'this.typeName')
				if(this.typeName == '销售订单') { //全部订单
					//					this.form.sourceType = 'platform'
					this.form.sourceType = '5'
					this.anonOrderCount()
				} else if(this.type == '1') { //待付款
					//					this.form.sourceType = 'merchant'
					this.form.sourceType = 6
					this.anonOrderCount()
				}
				this.form.sellerId = sessionStorage.traderId;
				this.form.buyerId = sessionStorage.traderId;
				if(this.currentPage != 1) {
					this.currentPage = 1
                    this.queryOrderList(this.form)
				} else {
					this.queryOrderList(this.form)
				}
			},
			handleCheck(payload) { ///订单条件查询
				payload == 0 && (this.currentPage = 1);
				this.form.gmtOrderStart = this.form.orderDateRange[0] ? this.form.orderDateRange[0].getTime() : null;
				this.form.gmtOrderEnd = this.form.orderDateRange[1] ? this.form.orderDateRange[1].getTime() : null;
				this.cloneForm = JSON.parse(JSON.stringify(this.form));
				if(this.currentPage != 1) {
					this.currentPage = 1
				} else {
					this.queryOrderList(this.form)
				};
				setTimeout(() => {
					this.flag = true;
				},0)
			},
			queryOrderList(body) { /*超团订单列表*/
				this.loading = true
				axiosApi('/groupon/bigTraderOrder/selectList', body, (res) => {
					this.loading = false
					this.total = res.data.data.totalCount
					this.ordersListResVo = res.data.data.elements
					/*定时器显示*/
					res.data.data.elements.forEach((item, index) => {
						if(item.remainAutoCancleTime != null && item.remainAutoCancleTime > 0) {
							let time = parseInt(item.remainAutoCancleTime / 1000) //转化为整数
							item.remainAutoCancleTime = this.timeString(time) //转化为时间格式
							let clearTime = null //定义变量
							clearTime = setInterval(() => {
								time--
								item.remainAutoCancleTime = this.timeString(time)
								if(time <= 0) {
									clearTimeout(clearTime)
									if(item.orderStatus === 1) item.orderStatus = 0
								}
							}, 1000)
						} else {
							if(item.orderStatus === 1) item.orderStatus = 0
						}

					})

				}, this.currentPage, 10, (res) => {
					// console.log('商户订单列表查询Error', res)
					this.loading = false
					this.$message({
						type: 'error',
						message: res.data.message,
						duration: 1000
					})
				})
			},
			handleCancelOrder(sn) { //取消订单按钮
				this.cancelOrderVisible = true;
				this.sn = sn; //订单编号
			},
			handleSend(sn) { //发货按钮
				this.sn = sn
				this.$router.push(basePath + "/groupConsignment/" + this.sn);
			},
			handleCommit(state) { //弹窗确定按钮
				if(state == 2) { //弹窗 取消订单
					this.$refs.cancelOrderForm.validate((valid) => {
						if(valid) {
							this.cancelOrderVisible = false
							axiosApi('/groupon/grouponOrderCancel/update', {
								mainOrderNo: this.sn,
								cancleReason: this.form.cancleReason,
								cancleType: 2
							}, (res) => {
								//console.log('取消订单',res)
								this.$message({
									type: 'success',
									message: '取消成功',
									duration: 1000
								})
								this.queryOrderList(this.form)
								this.anonOrderCount()
							}, 1, 10, (res) => {
								this.$message({
									type: 'error',
									message: res.data.message,
									duration: 1000
								})
							})
						} else {
							return false
						}
					})
				}
			},
			orderDetail(sn,type) { //去订单详情页面
				console.log(sn,'去订单详情的订单编号')


				sessionStorage.setItem('sn', sn)
				sessionStorage.removeItem('mainOrderSn')
				// sessionStorage.setItem('isSplit', false)
				this.$router.push(basePath + '/groupOrderDetails')

				return
				if(type == 'mainOrderSn'){
					sessionStorage.setItem('mainOrderSn', sn)
					sessionStorage.removeItem('sn')
					sessionStorage.setItem('isSplit', true)
				}else{
					sessionStorage.setItem('sn', sn)
					sessionStorage.removeItem('mainOrderSn')
					sessionStorage.setItem('isSplit', false)
				}

				this.$router.push(basePath + '/groupOrderDetails')
			},
			confirmReceipt(sn) { //确认收货
				const h = this.$createElement
				this.$msgbox({
					title: '提示信息',
					message: h('p', {
						style: 'text-align:center'
					}, '是否确认收货？'),
					showCancelButton: true,
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					beforeClose: (action, instance, done) => {
						if(action === 'confirm') {

							done()
						} else {
							done()
						}
						delete instance.$slots.default;
					}
				})
			},
			//tab切换new
			getStatus(e) {
				this.checkAll = false
				this.first = e
				let sourceType = this.form.sourceType
				this.form = {
					orderSn: null, //订单编号
					itemName: null, //商品名称
					orderStatus: null, //订单状态
					gmtOrderStart: null,
					gmtOrderEnd: null,
					orderDateRange: [], //gmtOrderStart下单开始时间 gmtOrderEnd下单结束时间
					cancleReason: null, //订单取消原因
					//					sourceType:sourceType
					sourceType: this.form.sourceType,
					buyerPhone: ''
				}
				this.anonOrderCount()
				if(e == '0') { //全部订单

				} else if(e == '1') { //待付款
					this.form.orderStatus = 1
				} else if(e == '2') { //待发货
					this.form.orderStatus = 2
				} else if(e == '3') { //待收货
					this.form.orderStatus = 3
				} else if(e == '4') { //已完成
					this.form.orderStatus = 4
				} else if(e == '5') { //已关闭
					this.form.orderStatus = 0
				}
				this.form.sellerId = sessionStorage.traderId;
				this.form.buyerId = sessionStorage.traderId;
				if(this.currentPage != 1) {
					this.currentPage = 1
				} else {
					this.queryOrderList(this.form)
				}
			},
			//登录信息
			isShow() {
				axiosApi('/groupon/sellerType/select', {
					id: sessionStorage.traderId
				}, (res) => {
					this.identity = res.data.data
					this.tabs = []
					if(this.identity == 'central') { //没有采购订单
						this.showButton("SALE_ORDER", 140004) && this.tabs.push('销售订单')
						this.queryOrderList(this.form) //默认加载订单
						this.anonOrderCount()
					} else {
						this.showButton("SALE_ORDER", 140004) && this.tabs.push('销售订单')
						this.showButton("PURCHASE_ORDER", 140004) && this.tabs.push('采购订单')
						//							this.form.sourceType = this.tabs[0] == '销售订单' ? 'platform' : 'merchant'
						this.form.sourceType = this.tabs[0] == '销售订单' ? '5' : ''
						console.log(this.tabs[0],'this.tabs[0]',this.form)
						this.queryOrderList(this.form) //默认加载订单
						this.anonOrderCount()
					}
				}, 1, 10, (res) => {
					this.$message.info(res.data.message);
				});
			},
		}
	}
</script>

<style lang="less">
	#groupOrder {
		position: relative;
		box-sizing: border-box;
		.el-checkbox__label {
			display: none;
		}
		.notPayCountmark {
			position: absolute;
			left: 169px;
			top: 10px;
		}
		.notDeliveryCount {
			position: absolute;
			left: 248px;
			top: 10px;
		}
		.orderDeliveriedCount {
			position: absolute;
			left: 330px;
			top: 10px;
		}
		.hide {
			display: none;
		}
		.content {
			.el-date-editor--daterange.el-input {
				width: 240px;
			}
			.el-form--inline {
				.el-form-item__label {
					font-size: 12px;
					width: 60px;
				}
			}
			.el-table .el-table__body-wrapper {
				display: none;
			}
			.order-datatimerange {
				width: 240px;
			}
			.el-table {
				border: none;
				font-size: 14px;
				th>.cell {
					line-height: 12px;
					padding: 10px 0 10px 0;
				}
				th.is-leaf {
					border-bottom: 0;
				}
			}
			.checkedAll {
				position: relative;
				top: 30px;
			}
			.el-checkbox {
				width: 70px;
				margin-left: 30px;
				.el-checkbox__input {
					height: 20px;
				}
			}
			.el-checkbox__inner {
				width: 20px;
				height: 20px;
			}
			.el-checkbox__inner::after {
				top: 2px;
			}
			.table-item {
				.bl {
					border-left: 1px solid #ddd;
				}
				border: 1px solid #dddddd;
				font-size: 14px;
				border-bottom: none;
				.table-item-content .pbl-20 {
					padding: 0px 0px 20px 20px;
				}
				.table-item-top {
					padding: 10px 0;
					.table-item-top-checkbox {
						margin-left: 14px;
					}
				}
				img {
					width: 80px;
					height: 80px;
				}
			}
			.w100 {
				width: 100px;
			}
			.table-item-default {
				height: 530px;
				padding-top: 120px;
			}
			.el-dialog {
				min-height: 300px;
				.el-form-item__label {
					width: 140px;
				}
			}
			.sendGoods .el-form-item__error {
				margin-left: 140px;
			}
		}
		.bb {
			border-bottom: 1px solid #DDDDDD;
		}
		.btop{
			border-top: 1px solid #DDDDDD;
		}
		.actived {
			border: 1px solid #FF4201;
			color: #FF4201;
		}
		.second-title {
			height: 60px;
			padding: 17px 0px 17px 0px;
			box-sizing: border-box;
			border-bottom: 1px solid #EEEEEE;
		}
		.choose-three {
			width: 114px;
			height: 26px;
			line-height: 26px;
		}
	}
</style>
