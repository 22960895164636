<template>
    <el-form  :model="ruleForm" label-width="120px" class="clearfix pl-20 pt-30">
        <el-form-item label="选择账期模板:">
            <el-select v-model="zaTemplate" :disabled="isQuery" placeholder="请选择账期模板" @change="zqChange">
                <el-option
                        v-for="item in zqList"
                        :label="item.templateName"
                        :value="item.id"
                        :key="item.id">
                </el-option>
            </el-select>
        </el-form-item>
        <el-form-item label=" ">
            <el-table v-loading="loading" :data="zqData" tooltip-effect="dark" stripe class="mt-20 table-default">
                <el-table-column prop="templateName" label="模板名称" min-width="280">
                </el-table-column>
                <el-table-column label="结算方式" min-width="80">
                    <template slot-scope="scope">
                        <span v-if="scope.row.creditType == 1">月结</span>
                    </template>
                </el-table-column>
                <el-table-column prop="creditDate" label="结算日" min-width="90">
                </el-table-column>
                <el-table-column label="结算月数">
                    <template slot-scope="scope">
                        <span v-if="scope.row.creditType == 1">{{scope.row.creditPeriodNum}}月/次</span>
                    </template>
                </el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-button class='ml-0 tal' size="small" type='text' v-if="!isQuery" @click="deleteTemplate()">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-form-item>
        <el-form-item label=" ">
            <el-button type="danger" v-if="!isQuery"  @click="saveZq(false)">保存</el-button>
        </el-form-item>
    </el-form>
</template>

<script>
    import { axiosApi,basePath } from "~/api/api";

    export default {
        name: "paymentTable",
        props:['form','isQuery'],
        data(){
            return {
                loading:false,
                ruleForm:{},
                zqData:[],//账期数据
                zqList: [],//账期列表
                zaTemplate:null,//账期列表
                paymentDaysTemplate: {}, //账期模版
            }
        },
        methods:{
            //	获取账期模板列表
            getZqList() {
                let api = '/finance/templateinfolistall/select';
                let body = {
                    traderId: sessionStorage.getItem('traderId')
                };
                axiosApi(api, body, res => {
                    this.zqList = res.data.data
                }, 1, 10, err => {
                    this.$message.error(err.data.message)
                })
            },
            //删除
            deleteTemplate() {
                this.$confirm('确定要删除该账期模板吗？', '提示信息', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                    closeOnClickModal: false,
                }).then(() => {
                    this.saveZq(true);
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                })
            },
            //删除 or  保存
            saveZq(isDelete) {//是否删除模板
                let api = "/finance/templatesellerrelation/update";
                let body = {
                    buyerId: this.form.buyerId,
                    sellerId: sessionStorage.getItem('traderId'),
                    creditTemplateId: isDelete ? 0 : this.zaTemplate,
                    creditTemplateState: 1
                };
                let str = isDelete ? '删除成功' : '保存成功';
                axiosApi(api, body, res => {
                    this.$message.success(str);
                    this.getPaymentDaysTemplate()
                }, 1, 10, err => {
                    this.$message.error(err.data.message)
                })
            },
            //改变账期模板
            zqChange() {
                if(this.zqList.length>0){
                    this.zqData = [];
                    this.zqList.map(v => {
                        if(v.id == this.zaTemplate){
                            this.zqData.push(v)
                        }
                    })
                }
            },
            //获取账期模板
            getPaymentDaysTemplate() {
                this.loading = true;
                console.log(this.form,'fds')
                axiosApi("/finance/financialtempbysellerbuyer/select", {
                    sellerId: sessionStorage.getItem('traderId'),
                    buyerId: this.form.buyerId
                }, res => {
                    console.log(res,'res')
                    this.loading = false;
                    if (res.data.data) {
                        console.log(res,'tem')
                        this.paymentDaysTemplate = res.data.data;
                        this.zqData = [];
                        this.$nextTick(() => {
                            this.zaTemplate = this.paymentDaysTemplate.id;
                        })
                        this.zqData.push(this.paymentDaysTemplate);
                    } else {
                        this.zaTemplate = "";
                        this.zqData = [];
                    }
                },1,10,err => {
					this.loading = false;
                })
            },
        },
        mounted(){
           this.$nextTick(() => {
               this.getZqList();
               this.getPaymentDaysTemplate()
           })
        }
    }
</script>

<style scoped>

</style>
