<template>
	<!--支付订单页面-->
	<div id="alipay" class="bg-f"  v-html="alipay"></div>
</template>

<script>
	export default {
		data() {
			return {
				alipay:"",
			}
		},
		mounted() {
			this.$nextTick(() => {
				if (localStorage.alipayHtml) {
					this.alipay = localStorage.alipayHtml
					setTimeout(()=>{
						document.forms[0].submit();
					},0)
					localStorage.removeItem('alipayHtml')
				}
				
			})
		},
		methods: {
		}
	}
</script>

<style lang="less">
</style>