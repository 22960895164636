<template>
	<div class="bg-f6">
		<header>
			<storeTop></storeTop>
		</header>
		<main id="body" class="bg-f6">
			<router-view></router-view>
		</main>
		<!--尾部-->
		<footer>
			<foot></foot>
		</footer>
		<!-- 登录框 -->
		<loginDialog v-if="dialogVisible"></loginDialog>
		<!-- 协议 -->
		<userProtocolDialog></userProtocolDialog>
	</div>
</template>

<script>
	import loginDialog from './../components/loginDialog.vue';
	import userProtocolDialog from './../components/userProtocolDialog.vue';
	export default {
		components : {loginDialog,userProtocolDialog},
		mounted() {
			var height = document.documentElement.clientHeight;
			document.getElementById("body").style.minHeight = height - 120 + "px";
		},
		computed : {
            dialogVisible : function(){
                return this.$store.state.homeSearch.mloginDialogState;
            },
        },
	}
</script>

<style>
	footer{
		background-color: #333;
		color: #fff;
	}

</style>
