<template>
	<div id="goodsDetails" v-loading.fullscreen.lock="fullscreenLoading">
		<!--<div class="width-1280 plr-45">-->
		<div class="mt-10 bg-f clearfix">
			<div class="fl w-400">
				<div class="booth">
					<img class="bd" v-if="!skuInfo.skuShowPictureVOs||(skuInfo.skuShowPictureVOs&&skuInfo.skuShowPictureVOs.length === 0)" src="../../assets/goods.jpg" alt="">
					<img class="bd" v-else-if="skuInfo.skuShowPictureVOs&&skuInfo.skuShowPictureVOs.length>0" :src="skuInfo.skuShowPictureVOs[activeImg].fileUrl" alt="" />
				</div>
				<ul class="clearfix mt-10 thumb">
					<li v-for="(item,index) in skuInfo.skuShowPictureVOs" class="fl cursor" :class="{'active-bd': activeImg == index, 'bd': activeImg != index}" @mouseover="smallImgclick(index)" :key="index+'bn'"><img :src="item.fileUrl" alt="" /></li>
				</ul>
				<div class="mt-30 mb-40 pushBtn clearfix">
					<span class="fr color-9 font-12 cursor" :class="{'color-danger':skuInfo.goodsCollected}" @click="handCollectionGoods"><i class="iconfont icon-collection mr-10"
                                                          :class="{'color-danger':skuInfo.goodsCollected}"></i>{{!skuInfo.goodsCollected ? '加入收藏夹' : '取消收藏'}}</span>
					<!--<span class="fr color-danger font-12"><i class="iconfont icon-collect mr-10"></i>加入收藏夹</span>-->
				</div>
			</div>
			<div class="fl pl-30 pr-50 color-6 font-16 pt-30 pb-30 info">
				<div class="tit font-20 color-3" v-if="skuInfo.skuType!==2">{{skuInfo.skuName}}</div>
				<div v-if="skuInfo.skuType===2" class="flex meal_title">
					<img src="../../assets/meal.png" />
					<div>{{skuInfo.skuName}}</div>
				</div>
				<storeGetCoupon class="mt-10" v-if="activityList && activityList.couponCount > 0 && traderId != skuInfo.sellerId && skuInfo.skuType !== 2" :couponlist="[{skuNo:couponInfo.skuNo}]" :sellerId="couponInfo.sellerId"></storeGetCoupon>
				<template>
					<!-- 限时限购 -->
					<template v-if="activityList">
						<div class="mt-15 pl-20 resteriction"  v-for="(item, i) in activityList.activityRestrictionVos" :key="i+'bw'">
							<label class="font-14 mr-30">限时购</label>
							<span class="font-12">截止到{{ item.gmtEnd | timenData2 }} 该商品限购{{ item.limitTimes }}{{ skuInfo.units }}</span>
						</div>
					</template>
				</template>

				<div class="bg-f6 pl-20 price pt-20 pb-20" :class="{ 'mt-15': !haveRestriction }">
					<div class="h30 dib" style="width: 100%;">
						<!-- 不是酒店用户协议商品 -->
					
						<!-- v-if="!isHotel || !skuInfo.hotelAgreementGoodsVO" -->
						<template>
							<div>
								<!-- <label class="font-14" :style="{display:!skuInfo.hotelAgreementGoodsVO ? 'none' : 'inline-block'}">
										<span v-if="skuInfo.dealerGoods">经销商价</span>
										<span v-else-if="isHotel && skuInfo.hotelAgreementGoodsVO">{{skuInfo.skuType===2?'套餐价':'销售价'}}</span>
								</label> -->

								<!-- <span v-if="isHotel" class="color-danger" ></span> -->
								<!-- <span v-if="isHotel" class="color-danger">该商品{{nonAgreementGoodsIconfont}}</span> -->
								<span v-if="isloginUser" class="color-danger font-30">
										￥{{ goodsSkuInfo.showPrice | fmoney}}
										<span class="font-12 color-9" v-if="goodsSkuInfo.showUnitName">/{{ goodsSkuInfo.showUnitName }}</span>
								</span>
								<span @click="goLogin" v-else class="color-danger font-14 cursor">查看<span v-if="skuInfo.dealerGoods">销售价</span><span v-else>{{skuInfo.skuType===2?'套餐价':'销售价'}}</span>，请登录</span>
							</div>
							<div style="display:flex;">
								<div class="mt-5" v-if="!skuInfo.dealerGoods">
									<label class="font-14">建议零售价</label>
									<span>￥{{goodsSkuInfo.msrp | fmoney}}</span>
									<span class="font-12 color-9" v-if="goodsSkuInfo.showUnitName">/{{ goodsSkuInfo.showUnitName }}</span>
								</div>
								<div class="mt-5 ml-20" v-if="goodsSkuInfo.goodsIntegration!=null">
									<div> 
										<span class="font-14">商品积分：</span> 
										<span class="font-12 color-9">{{ goodsSkuInfo.goodsIntegration }}</span>
									</div>
									<div v-if="skuInfo.integrationEffectiveStart&&skuInfo.integrationEffectiveEnd"> <span class="font-14">积分有效期：</span> <span class="font-12 color-9">{{ skuInfo.integrationEffectiveStart }}至{{ skuInfo.integrationEffectiveEnd }}</span></div>
								</div>
							</div>
						</template>
					
						<!-- <template v-else>
							<div style="display:flex;">
								<div class="mt-5">
									<label class="font-14">{{ skuInfo.dealerGoods ? '经销商价':'销售价' }}</label>
									<span>￥{{goodsSkuInfo.price | fmoney}}</span>
									<span class="font-12 color-9" v-if="goodsSkuInfo.salesUnitName">/{{ goodsSkuInfo.salesUnitName }}</span>
								</div>
							  <div class="mt-5 ml-20" v-if="goodsSkuInfo.goodsIntegration!=null">
									<div> 
										<span class="font-14">商品积分：</span> 
										<span class="font-12 color-9">{{ goodsSkuInfo.goodsIntegration }}</span>
									</div>
									<div v-if="skuInfo.integrationEffectiveStart&&skuInfo.integrationEffectiveEnd"> <span class="font-14">积分有效期：</span> <span class="font-12 color-9">{{ skuInfo.integrationEffectiveStart }}至{{ skuInfo.integrationEffectiveEnd }}</span></div>
								</div>
							</div>
						</template> -->
						<!-- 满减活动 -->
						<div>
							<!-- v-if="(isHotel && skuInfo.hotelAgreementGoodsVO) || !isHotel" -->
							<div v-show="hasActivity" class="clearfix font-14 mt-10 lh-23" :class="{'hidden': !isopenPromotion,'activity_promotion':toShowMore}">
								<div class="fl mr-20">促销</div>
								<div class="fl" id="activity_body" style="width: 525px" ref="activity_body">
									<div v-show="(activityList.activityCutVos&&activityList.activityCutVos.length>0)" class="clearfix font-14  lh-23">
										<!--<img class="fl activity_logo" src="../../assets/full_reduce.png" alt="">-->
										<span class="fl activity_logo tac lh-16" style="display: inline-block;border-radius: 10px;border:1px solid #5ACE37;color: #5ACE37;font-size: 12px">满减</span>
										<div class="fl activity_content">
											<div style="width: 454px" class="color-danger" v-for="(item, i) in activityList.activityCutVos" :key="i+'bo'">
													<div v-for="(a,b) in item.messages" :key="b+'bp'">{{a}}</div>
											</div>
										</div>
									</div>
									<div v-show="activityList.activityGiftVos&&activityList.activityGiftVos.length>0" class="clearfix font-14 lh-23">
										<!--<img class="fl activity_logo" src="../../assets/full_present.png" alt="">-->
										<span class="fl activity_logo  tac lh-16" style="display: inline-block;border-radius: 10px;border:1px solid #5ACE37;color: #5ACE37;font-size: 12px">满赠</span>
										<div class="fl activity_content">
											<div style="width: 454px" class="color-cyan" v-for="(item, i) in activityList.activityGiftVos" :key="i+'bq'">
												<div v-if="salesUnitType == 3 && item.messages">
													<div v-for="(a,b) in item.messages" :key="b+'br'">{{a}}</div>
												</div>
												<div v-if="salesUnitType == 2 && item.specMessages">
													<div v-for="(a,b) in item.specMessages" :key="b+'bs'">{{a}}</div>
												</div>
											</div>
										</div>
									</div>
									<!-- 新的换购 -->
									<div v-show="activityList.activityChangePurchases&&activityList.activityChangePurchases.length>0" class="clearfix font-14 lh-23">
										<div v-for="item in activityList.activityChangePurchases" :key="item.activityId">
											<!--<img class="fl activity_logo" src="../../assets/full_exchange.png"
													alt="">-->
											<span class="fl activity_logo  tac lh-16" style="display: inline-block;border-radius: 10px;border:1px solid #5ACE37;color: #5ACE37;font-size: 12px">换购</span>
											<div class="fl activity_content">
												<!-- <div style="width: 454px" class="color-z">
														<div v-for="(a,b) in item.messages" :key="b">{{a}}</div>
												</div> -->
                                                <div v-if="salesUnitType == 3 && item.messages">
													<div v-for="(a,b) in item.messages" :key="b+'bt'">{{a}}</div>
												</div>
												<div v-if="salesUnitType == 2 && item.specMessages">
													<div v-for="(a,b) in item.specMessages" :key="b+'bu'">{{a}}</div>
												</div>
											</div>
										</div>
									</div>
									<!-- 经销商 -->
									<div v-show="activityList.dealerGiftVos&&activityList.dealerGiftVos.length>0" class="clearfix font-14 lh-23">
										<div v-for="item in activityList.dealerGiftVos" :key="item.activityId">
											<!--<img class="fl activity_logo" src="../../assets/full_exchange.png"
													alt="">-->
											<span class="fl activity_logo  tac lh-16" style="display: inline-block;border-radius: 10px;border:1px solid #ff4201;color: #ff4201;font-size: 12px">协议</span>
											<div class="fl activity_content">
												<div style="width: 454px;color:#ff4201">
													<div v-for="(a,b) in item.messages" :key="b+'bs'">{{a}}</div>
												</div>
											</div>
										</div>
									</div>
									<!--<div v-if="activityList.dealerGiftDesc">
										<span class="fl activity_logo  tac lh-16" style="display: inline-block;border-radius: 10px;border:1px solid #5ACE37;color: #5ACE37;font-size: 12px">协议</span>
										<div class="fl activity_content">
											<div style="width: 454px" class="color-cyan">
												<div  >{{activityList.dealerGiftDesc}}</div>
											</div>
										</div>
									</div>-->
								</div>
								<!-- <div class="fr mr-20 color-danger cursor" @click="showMore">
									<span>{{toShowMore ? '收起促销' : '展开促销'}}</span>
									<i class="el-icon-arrow-down" :class="{beforeAnimation: !toShowMore, animation: toShowMore}"></i>
								</div> -->
								<div v-show="toShowMore" class="fr mr-20 color-danger cursor" style="user-select:none;" @click="isopenPromotion = !isopenPromotion">
									{{isopenPromotion?'收起促销':'展开促销'}} <i class="el-icon-arrow-down"></i></div>
							</div>
						</div>
					</div>
				</div>
				<div class="mt-15 ml-20 font-14 goods-info">
					<label>规格</label><span class="mr-10">1*{{skuInfo.specInfoNum}}</span>
					<label class="ml-50" v-if="skuInfo.totalSales!=null">月售</label><span>{{skuInfo.totalSales}}</span>
					<div v-if="isloginUser" class="mt-15">
						<label>配送至</label><span class="dib">{{ skuInfo.distributionAdd }}</span>
					</div>
					<div v-if="isloginUser" class="mt-15">
						<!--  && ((isHotel && skuInfo.hotelAgreementGoodsVO) || !isHotel) -->
						<label>选择</label>
						<div class="dib" >
							<div v-if="skuInfo.skuType !== 2">
								<el-button  @click="handlerType(2)"  class="mr-20 type_btn" :class="{ pro_type:(salesUnitType == 1 || salesUnitType == 2) }">{{ boxInfo.showTextContext }}</el-button>
								<el-button  @click="handlerType(3)" class="type_btn" :class="{ pro_type:salesUnitType == 3 }">{{ bottleInfo.showTextContext }}</el-button>
							</div>
							<el-button v-else class="type_btn pro_type">{{ mealInfo.showTextContext }}</el-button>
						</div>
					</div>
					<div v-if="goodsSkuInfo.disableSalesDesc" class="mt-10 color-danger cursor font-14" style="margin-left: 62px;">
							{{ goodsSkuInfo.disableSalesDesc }}
					</div>
				</div>
				<div class="mt-40 ml-20">
					<div class="ped clearfix font-14">
						<!-- v-if="(isHotel && skuInfo.hotelAgreementGoodsVO) || !isHotel" -->
						<el-input-number size="small" :disabled="!goodsSkuInfo.enableSales" :min="goodsSkuInfo.minimunMoq" :max='goodsSkuInfo.goodsMaxNum' v-model.number="goodsNum" :step="goodsStep" class="fl" ></el-input-number>
						<span class="ml-5 fl" >{{ goodsSkuInfo.showUnitName }}</span>
						<span class="fl ml-30">{{ goodsSkuInfo.minimunMoq }}{{ goodsSkuInfo.showUnitName }}起购／库存{{ goodsSkuInfo.stockEnableQuantity }}{{goodsSkuInfo.showUnitName}}</span>
					</div>
					<div class="shopBtn mt-20" >
						<!-- <div v-if="skuInfo.hotelSkuState==1 || skuInfo.isStopSellingMark">
							<el-button disabled>暂停出售
							</el-button>
						</div> -->
						<!-- v-else -->
						<div>
							<!-- <router-link :to="basePath + '/store/confirmOrder/2/' + skuInfo.skuNo + '/' + goodsNum"> -->
							<el-button @click="isOrderGoods(buyClick)" :disabled="(!goodsSkuInfo.enableSales||((traderId == skuInfo.sellerId)))" type="danger">立即购买
							</el-button>
							<!--  || (isHotel && !skuInfo.hotelAgreementGoodsVO) -->
							<!-- </router-link> -->
							<el-button @click="isOrderGoods(handAddCar, $event)" :disabled="(!goodsSkuInfo.enableSales||((traderId == skuInfo.sellerId) || (goodsNum <= 0)))" class="ml-20 color-danger bg-fed">加入购物车
							</el-button>
							<!-- || (isHotel && !skuInfo.hotelAgreementGoodsVO) -->
						</div>
					</div>
					<div class="explain_w mt-30 font-14 color-6">
						<p>
							<span>服务说明：100%正品 | PICC承保</span>
							<i class="iconfont icon-inspector cursor font-20 ml-20 mid" @mousemove="explain = true" @mouseout="explain = false"></i>
						</p>
						<div v-show="explain" class="explain" @mousemove="explain = true" @mouseout="explain = false">
							<img src="../../assets/polygon.png" />
							<label class="color-3">100%正品</label>
							<p>久加久云商平台所售商品均100%保真、保质。</p>
							<label class="color-3">PICC承保</label>
							<p>久加久云商平台所售商品均由中国人民财产保险有限公司（PICC）承保。</p>
						</div>
						<p class="mt-10">温馨提示：不支持7天无理由退款</p>
					</div>
				</div>
			</div>
		</div>
		<div class="main mt-10 mb-50 font-16 clearfix">
			<!-- <div class="storeInfo fl"> -->
			<!--店铺信息-->
			<!-- <div class="h-340 bg-f">
                <div class="pl-20 pt-20">
                    <label class="font-14">店铺信息：</label>
                    <div class="mt-20 clearfix avar">
                        <img v-if="!skuInfo.storeHead" src="../../assets/store-small.jpg" alt="">
                        <img v-else :src="skuInfo.storeHead" alt="" />
                        <span class="ml-10">{{ skuInfo.storeName }}</span>
                    </div>
                    <div class="mt-30 lh-16 address clearfix">
                        <label class="fl">地址：</label><span class="fl">{{ skuInfo.storeAdd }}</span>
                    </div>
                    <div class="mt-50">
                        <el-button type="danger" @click="goStore" class="mt-30 ml-20 mr-10">进店逛逛</el-button>
                        <el-button v-if="!isHotel" class="mt-30" :disabled="traderId == skuInfo.sellerId" @click="handCollection">{{!skuInfo.sellerCollected ? '收藏店铺' : '取消收藏'}}</el-button>
                    </div>
                </div>
            </div> -->
			<!--店铺推荐-->
			<!-- <div class="bg-f store-classify mt-10 pb-10 font-16">
                <p class="pl-30">店内分类:</p>
                <ul>
                    <li @click="supperClassify(isHotel ? item.id :item.dictKey)" v-for="item in storClassifyList" class="cursor hover-danger pl-30" :key="item.id">
                        <span>{{ isHotel ? item.classifyName : item.dictValue }}</span>
                        <i class="el-icon-arrow-right font-12 color-9 fr mr-30"></i>
                    </li>
                </ul>
            </div> -->
			<!-- </div> -->
			<div class="fl hot_goods">
				<div v-if="goodsList.length>0" class="hot_goods_title color-danger">推荐商品</div>
				<template v-if="goodsList.length>0">
					<goodsItem  class="hot_good_item" v-for='item in goodsList' :key='"sku"+item.skuNo' :itemData="item" @parabolaFn="parabolaFn" @storeClick="goStore"/>
					<!-- <goodsItem class="hot_good_item" v-for='item in n.sub' :key='item.skuNo' :itemData="isHotel ? item : item.skuVO" @parabolaFn="parabolaFn" @storeClick="goStore" :isIndex="true" :isHotel="isHotel"/> -->
				</template>
			</div>
			<div class="commInfo fl">
				<!--套餐商品清单-->
				<div class="bg-f mb-10 clearfix pl-30 pt-30 pr-30 pb-30 bd mr-30" v-if="skuInfo.skuType === 2">
					<label class="font-16">商品介绍：</label>
					<div class="clearfix mt-50">
						<div class="fl font-14">
							套餐商品：
						</div>
						<div class="fl ml-15">
							<a v-for="i in skuInfo.skuSetInfoVOList" :key="i.skuName" class="mb-20 block " :href="`${basePath}/shop/goodsDetails/${i.skuNo}`" target="_blank">{{i.skuName}}( {{i.num}}{{i.unitName}} )</a>
						</div>
					</div>
				</div>
				<!--商品介绍-->
				<div class="h-340 font-14 bg-f bd mr-30" v-if="skuInfo.skuType !== 2">
					<div class="pl-30 pt-30">
						<label class="font-16">商品介绍：</label>
						<div class="mt-30 clearfix">
							<ul class="commInfo-list">
								<li class="fl text-overflow-ellipsis">
									<label class="mr-5">分类：</label>
									<span>{{skuInfo.classifyName}}</span>
								</li>
								<li class="fl text-overflow-ellipsis">
									<label class="mr-5">品牌：</label>
									<span>{{skuInfo.brandName}}</span>
								</li>
								<li class="fl text-overflow-ellipsis">
									<label class="mr-5">包装规格：</label>
									<span>1*{{skuInfo.specInfoNum}}</span>
								</li>
								<li class="fl text-overflow-ellipsis">
									<label class="mr-5">计量单位：</label>
									<span>{{skuInfo.units}}</span>
								</li>
								<li class="fl text-overflow-ellipsis">
									<label class="mr-5">条形码：</label>
									<span>{{ skuInfo.spuVo ? skuInfo.spuVo.citm : null }}</span>
								</li>
								<li class="fl text-overflow-ellipsis" v-for="(item,index) in parameterList" :key="index+'bv'" v-if="item.spuParameterValue">
									<label class="mr-5">{{item.spuParameterName}}：</label>
									<span>{{item.spuParameterValue}}</span>
								</li>
							</ul>
						</div>
					</div>
				</div>
				<div class="mt-10 bg-f h-341 clearfix mr-30" v-html="skuInfo.illustrationText"></div>
			</div>
		</div>
		<!--</div>-->
	</div>
</template>

<script>
	import { axiosApi, basePath } from "../../api/api";
	import { mapMutations, mapActions } from 'vuex'
	import storeGetCoupon from "../../components/storeGetCoupon.vue";
	import goodsItem from "../../components/goodsItem.vue";
	// import {bus} from "../../js/extend.js";
	import { parseTime } from "@/utils";
	var parabola, element, target;
	export default {
		components: {
			storeGetCoupon,
			goodsItem
		},
		data() {
			return {
				basePath: basePath,
				activeImg: 0,
				skuNo: null,
				sellerId: null,
				goodsNum: 1,
				goodsStep: 1,
				skuInfo: {
					skuShowPictureVOs: []
				},
				activityList: {
					activityCutVos: [],
					activityGiftVos: [],
					activityRestrictionVos: []
				},
				givenPriceList: [],
				storClassifyList: [],
				parameterList: [],
				couponInfo: {
					buyerId: null,
					sellerId: null,
					couponVisible: false, //是否显示
					skuNo: null
				},
				traderId: null,
				fullscreenLoading: false,
				isloginUser: false,
				haveRestriction: false,
				maxNum: null,
				isHotel: false,
				explain: false,
				isPackageGood: false, // 是否是套餐商品
				isAdmin: sessionStorage.getItem('isAdmin') == '1' ? true : false,
				isopenPromotion: false,
				toShowMore: null, //默认折叠
				hasActivity: false,
				goodsList: [], // 商品列表
				nonAgreementGoodsIconfont: '', //提示文案
				isCurrent:true,//默认选择整箱装
				salesUnitType:null,
				goodsSkuInfo:{},//商品sku详情
				boxInfo :{},//箱
				bottleInfo :{},//瓶
				mealInfo :{}//套餐
			}
		},
		computed: {
			zeroShow(){
				return this.$store.state.homeSearch.zeroShow
			},
			stockEnableQuantity() { // 计算 库存
				return this.skuInfo.isIntegerMultiple ? this.skuInfo.stockEnableSpecQuantity : this.skuInfo.stockEnableQuantity
			}
		},
		watch: {
			zeroShow: function (val) {
				this.goodsSearch()
				// this.getStorClassify(this.skuInfo.sellerId);
			}
		},
		// computed:{
		//   hasActivity(){// 是否存在活动
		//     if(Object.keys(this.activityList).length>0){
		//       return  (this.activityList.activityCutVos&&this.activityList.activityCutVos.length>0)||(this.activityList.activityGiftVos&&this.activityList.activityGiftVos.length>0)||(this.activityList.activityChangePurchases&&this.activityList.activityChangePurchases.length>0)
		//     }else{
		//       return false
		//     }
		//   }
		// },
		methods: {
			...mapMutations({
				'mouseHide': 'mouseHide',
				'collectStoreShow': 'collectStoreShow'
			}),
			...mapActions({
				'getUserId': 'getUserId',
				'setShoppingCarNum': 'setShoppingCarNum'
			}),
			//切换瓶箱
			handlerType(salesUnitType){
				if(this.salesUnitType && this.salesUnitType != salesUnitType){
					this.salesUnitType = salesUnitType;
					// goodsSkuInfo.enableSales // 是否可购买 true 可购买 false 不可购买
					this.goodsSkuInfo = salesUnitType == 2 ? this.boxInfo : this.bottleInfo;
					this.goodsNum = this.goodsSkuInfo.stockEnableQuantity < this.goodsSkuInfo.minimunMoq ? this.goodsSkuInfo.stockEnableQuantity : this.goodsSkuInfo.minimunMoq;
						if(this.skuInfo.notCheckedStock){
							this.goodsSkuInfo.goodsMaxNum = 99999;
					}else{
							this.goodsSkuInfo.goodsMaxNum = this.goodsSkuInfo.stockEnableQuantity
					}
				}
			},
			goodsSearch() { // 商品搜索
				this.fullscreenLoading = true;
				let api = '/unlogin/goods/recommendGoods/list'
				let body = {
					limit: 5,
					returnCollected:true,
					goodsViewType: 'promotion',
					shouldHasStore: this.zeroShow // 库存为了是否显示 false 显示，true不显示
				}
				axiosApi(api, body, (res) => {
					this.goodsList = res.data.data
					this.fullscreenLoading = false;
				}, 1, 10, () => {
					this.fullscreenLoading = false;
				})
			},
			parabolaFn() { //抛物线小球
				parabola.init();
			},
			chieve() {
				this.fullscreenLoading = true;
				//后台🙆‍♂统一接口 收回逻辑判断
				let api = !this.isloginUser ? "/unlogin/goods/skuInfo/select" : "/goods/skuInfo/select";
				// let api = !this.isloginUser ? "/unlogin/goods/skuInfo/select" : this.isHotel ? '/goods/hotelSkuDetail/select' : "/goods/skuInfo/select";
				axiosApi(api, {
					skuNo: this.skuNo,
					buyerId: this.traderId,
					isCheckInvisible: true
				}, (res) => { //加载商品详情
					this.fullscreenLoading = false;
					
					var data = res.data.data
					this.skuInfo = data;




					this.skuInfo.skuShowPictureVOs = this.skuInfo.skuShowPictureVOs.slice(0, 5);
					this.couponInfo.buyerId = this.traderId;
					this.couponInfo.skuNo = this.skuNo;
					this.couponInfo.sellerId = this.skuInfo.sellerId;
					this.salesUnitType = this.skuInfo.skuType !== 2 ? (this.skuInfo.salesUnitType == 3 ? 3 : 2) : null;
					
					this.boxInfo =  this.skuInfo.boxInfo;//箱
					this.bottleInfo =  this.skuInfo.bottleInfo;//瓶
					this.mealInfo =  this.skuInfo.mealInfo;//套餐
					this.goodsSkuInfo = this.salesUnitType ? ( this.salesUnitType == 2 ? this.boxInfo : this.bottleInfo ) : this.mealInfo;
					// if(this.skuInfo.priceUnit === 1 && this.salesUnitType === 3){
					//     this.goodsSkuInfo.priceUnitName = this.skuInfo.unitsName;
					// }
					if(this.skuInfo.notCheckedStock){
							this.goodsSkuInfo.goodsMaxNum = 99999;
					}else{
							this.goodsSkuInfo.goodsMaxNum = this.goodsSkuInfo.stockEnableQuantity
					}
					this.goodsNum = this.goodsSkuInfo.stockEnableQuantity < this.goodsSkuInfo.minimunMoq ? this.goodsSkuInfo.stockEnableQuantity : this.goodsSkuInfo.minimunMoq
					this.parameterList = (this.skuInfo.spuVo ? this.skuInfo.spuVo.parameterList : []);
					// this.isHotel ? (this.skuInfo.spuVo.parameterList) : 
					// if(!this.isHotel) {
					// 	let collectionData = {
					// 		sellerId: this.skuInfo.sellerId,
					// 		isCollection: this.skuInfo.isCollection
					// 	}
					// 	this.getUserId(collectionData)
					// }
					//加载商品活动
					// if(!this.isHotel)this.goodsActivityList();
					this.goodsActivityList();
					//加载该店铺商品分类列表
					// this.getStorClassify(this.skuInfo.sellerId);
					// if(this.isHotel && !this.skuInfo.hotelAgreementGoodsVO){// 没有酒店商品协议
					//   this.$alert('该商品未签约','提示',{
					//     confirmButtonText: '确定',
					//     callback: action=>{
					//       this.$router.push({path: basePath + '/shop/shopIndex',query:{'isForce': true}})
					//     }
					//   })
					// }
				}, null, null, res => {
					this.fullscreenLoading = false;
					if(res.data.code == 'GS0118') {
						this.$alert(res.data.message, '提示', {
							confirmButtonText: '确定',
							callback: action => {
								this.$router.push({
									path: basePath + '/shop/shopIndex',
									query: {
										'isForce': true
									}
								})
							}
						})
					} else if(res.data.code == 'OS1096') {
						this.$alert(res.data.message, '提示', {
							confirmButtonText: '确定',
							callback: action => {
								this.$router.push({
									path: basePath + '/shop/shopIndex'
								})
							}
						})
					}
				})
			},
			goodsActivityList() { //加载商品活动
				if(!this.isloginUser) return;
				axiosApi("/marketing/activity/queryBySkuNo", {
					skuType: this.skuInfo.skuType,
					skuNo: this.skuNo,
					sellerId: this.skuInfo.sellerId,
					buyerId: sessionStorage.traderId
				}, (res) => {
					if(!res.data.data) {
						return
					}
					this.activityList = res.data.data;
					if(Object.keys(this.activityList).length > 0) {
						this.hasActivity = (this.activityList.dealerGiftVos && this.activityList.dealerGiftVos.length > 0) || (this.activityList.activityCutVos && this.activityList.activityCutVos.length > 0) || (this.activityList.activityGiftVos && this.activityList.activityGiftVos.length > 0) || (this.activityList.activityChangePurchases && this.activityList.activityChangePurchases.length > 0)
					} else {
						this.hasActivity = false
					}
					let t = 20
					if(this.hasActivity) {
						let a = setInterval(() => {
							// console.log(t)
							if(document.getElementById('activity_body')) {
								this.toShowMore = document.getElementById('activity_body').clientHeight > 96
							}
							t--
							if(t == 0) {
								window.clearInterval(a)
							}
						}, 50)
					}
					//如果有限购活动
					this.haveRestriction = this.activityList.activityRestrictionVos && this.activityList.activityRestrictionVos.length > 0;

				})
			},
			smallImgclick(index) {
				this.activeImg = index;
				// this.skuInfo.fileUrl = this.skuInfo.skuShowPictureVOs[index].fileUrl;
			},
			isOrderGoods(fun, event) {
				let api, body;
				if(!this.isloginUser) {
					this.$store.commit('setloginDialog', true);
					return false;
				} 
				// else if(this.isHotel && !this.skuInfo.hotelAgreementGoodsVO) {
				// 	this.$message(`该商品${this.nonAgreementGoodsIconfont},不能${fun === this.buyClick ? '购买' : '加入购物车'}`);
				// 	return false
				// } 
				fun(event);
			},
			handAddCar(event) { //加入购物车
				let body = {
					skuNo: this.skuInfo.skuNo,
					num: this.goodsNum,
					buyerId: this.traderId, //用户id
                    operatorType: 1, //添加者类型：商户
                    selectedSalesUnitType:this.salesUnitType
				}
				// console.log(body)
				axiosApi("/anon/order/cart/insert", body, (res) => {
					//抛物线
					element.style.left = event.clientX + 'px';
					element.style.top = event.clientY + 'px';
					element.style.visibility = 'visible';
					parabola.init();

					let newA = res.data.data
					newA = Object.assign({}, res.data.data)
					this.setShoppingCarNum(newA.countNum)
					// bus.$emit('shoppingCarNum',newA.countNum)
				}, 1, 10, (res) => {
					// console.log(res.data.message)
					this.$message({
						message: res.data.message,
						type: 'info',
						duration: 1000
					});
				})
			},
			buyClick() { //立即购买
				this.$router.push(basePath + '/store/confirmOrder/2/' + this.skuInfo.skuNo + '/' + this.goodsNum + '/' + (this.salesUnitType?this.salesUnitType:'3'))
			},
			handCollection() { //收藏店铺
				if(!this.isloginUser) {
					/* this.$alert('请先登录', '提示信息', {
					    confirmButtonText: '确定'
					}) */
					this.$store.commit('setloginDialog', true);
					return false;
				}
				let body = [{
					'buyerId': this.traderId,
					'sellerId': this.skuInfo.sellerId
				}];
				let api = "/trader/collectSeller/update";
				this.CollectApi(api, body, 'sellerCollected');
			},
			handCollectionGoods() { //收藏商品
				if(!this.isloginUser) {
					/* this.$alert('请先登录', '提示信息', {
					    confirmButtonText: '确定'
					}) */
					this.$store.commit('setloginDialog', true);
					return false;
				} else if(this.traderId == this.skuInfo.sellerId) {
					this.$message({
						message: "不能收藏自己的商品",
						type: 'info',
						duration: 1000
					});
					return
				}
				let body = [{
					skuNo: this.skuInfo.skuNo,
					buyerId: this.traderId,
					classifyId: this.skuInfo.classifyId,
					brandId: this.skuInfo.brandId
				}];
				let api = "/trader/collectGoods/update";
				this.CollectApi(api, body, 'goodsCollected');
			},
			CollectApi(api, body, type) {
				axiosApi(api, body, (res) => {
					let collectedType = type == 'sellerCollected' ? res.data.data[0].sellerCollected : res.data.data[0].goodsCollected;
					this.$message({
						message: collectedType ? "收藏成功！" : "已取消收藏！",
						type: 'success',
						duration: 1000
					});
					if(type == 'sellerCollected') this.skuInfo.sellerCollected = !this.skuInfo.sellerCollected;
					if(type == 'goodsCollected') this.skuInfo.goodsCollected = !this.skuInfo.goodsCollected;
				}, 1, 10, (res) => {
					this.$message({
						message: res.data.message,
						type: 'info',
						duration: 1000
					});
				})
			},
			getStorClassify(sellerId) { //获取当前用户商品分类
				// this.categoryLoading = true;
				// let api = this.isHotel ? "/goods/hotelClassifyBrand/select" : this.isloginUser ? "/goods/sku/search" : "/unlogin/goods/sku/search";
				let api = this.isloginUser ? "/goods/sku/search" : "/unlogin/goods/sku/search";
				axiosApi(api, {
					buyerId: this.traderId,
					storeSellerId: sellerId,
					shouldHasStore: this.zeroShow // 库存为了是否显示 false 显示，true不显示
				}, (res) => {
					this.storClassifyList = res.data.data.classifyConditionSet;
					// this.storClassifyList = this.isHotel ? res.data.data : res.data.data.classifyConditionSet;
					this.categoryLoading = false;
				}, 1, 0)
			},
			goStore() {
				if(!this.isloginUser) {
					this.$store.commit('setloginDialog', true);
					return false;
				} 
				// else if(this.isHotel && !this.skuInfo.hotelAgreementGoodsVO) {
				// 	this.$message('该商品' + this.nonAgreementGoodsIconfont + ',不能操作')
				// 	return
				// }
				this.$router.push(`${basePath}/goodsSearch/classify/brand/goodsName/${this.skuInfo.sellerId}`)
			},
			supperClassify(classifyId) {
				if(!this.isloginUser) {
					this.$store.commit('setloginDialog', true);
					return false;
				} 
				// else if(this.isHotel && !this.skuInfo.hotelAgreementGoodsVO) {
				// 	this.$message('该商品' + this.nonAgreementGoodsIconfont + ',不能操作')
				// 	return
				// }
				this.$router.push(`${basePath}/goodsSearch/${classifyId}/brand/goodsName/${this.skuInfo.sellerId}`)
			},
			goLogin() {
				if(!this.isloginUser) {
					this.$store.commit('setloginDialog', true);
					return false;
				}
			},
			findTraderInfoBySerllerId() { // 查询商品是否开通同步K3
				return new Promise((resolve, reject) => {
					axiosApi('/trader/findTraderInfoBySerllerId/select', {
						'traderId': sessionStorage.getItem('sellerId')
					}, (res) => {
						resolve(res)
					}, 1, 10, err => {
						reject(err)
					})
				})
			},
		},
		created() {
			this.isloginUser = !sessionStorage.getItem('loginName') ? false : true;
			// this.isHotel = sessionStorage.getItem('isHotel') === '1' && this.isloginUser ? true : false;
			this.traderId = this.isloginUser ? sessionStorage.traderId : null;
			// this.sellerId = this.$route.params.seller;
			this.skuNo = this.$route.params.skuNo;
			this.chieve();
			this.mouseHide();
			this.collectStoreShow();
			this.goodsSearch();
		},
		mounted() {
			this.$nextTick(() => {
				this.findTraderInfoBySerllerId().then(res => {
					this.nonAgreementGoodsIconfont = res.data.data.nonAgreementGoodsIconfont || '非协议'
				})
				target = document.getElementById("shopping-empty");
				element = document.getElementById("ball");
				parabola = this.parabola(element, target, {
					speed: 700, // 每帧移动的像素大小
					curvature: 0.0006, // 实际指焦点到准线的距离
					complete: function() { //动画执行完回调
						element.style.visibility = "hidden";
					}
				}).mark();
			})
		}
	}
</script>

<style lang="less">
	#goodsDetails {
		.bd {
			border: 1px solid #dddddd;
		}
		background-color: #fff;
		.h0 {
			height: 0px;
		}
		.ha {
			height: auto;
		}
		.activity_promotion {
			height: 96px;
			/*// overflow: hidden;*/
		}
		.hidden {
			overflow: hidden;
			/*// max-height: 0!important;*/
		}
		.color-z {
			color: #FA9A00;
		}
		.activity_logo {
			margin-right: 10px;
			line-height: 21px;
			margin-top: 7px;
			width: 38px;
			height: 16px;
			.activity_content {
				width: 450px;
			}
		}
		.lh-23 {
			line-height: 30px;
		}
		width: 1190px;
		margin: 0 auto;
		.block {
			display: block;
		}
		.activity {
			margin-top: 20px;
			.iconfont {
				vertical-align: sub;
			}
		}
		.w-400 {
			width: 400px;
			.booth {
				width: 400px;
				height: 400px;
				img {
					width: 398px;
					height: 398px;
				}
				;
			}
			;
			.thumb {
				width: 400px;
				li {
					width: 70px;
					height: 70px;
					margin-right: 10px;
					img {
						width: 70px;
						height: 70px;
					}
					;
				}
				;
				.active-bd {
					width: 68px;
					height: 68px;
					border: 2px solid #333;
					img {
						width: 68px;
						height: 68px;
					}
					;
				}
				li:nth-last-child(1) {
					margin-right: 0;
				}
			}
			.el-input-number--small .el-input__inner {
				padding-right: 135px;
			}
		}
		/*右侧价格区域*/
		.info {
			box-sizing: border-box;
			width: 790px;
			.tit {
				padding-top: 7px;
				width: 467px;
			}
			.w-623 {
				width: 623px;
				vertical-align: top;
			}
			.resteriction {
				box-sizing: border-box;
				width: 730px;
				height: 32px;
				line-height: 32px;
				color: #fff;
				background: linear-gradient(to right, #E43A3D, #E9843B);
				label {
					color: #FFE035;
				}
			}
			.price {
				box-sizing: border-box;
				width: 730px;
				vertical-align: middle;
				position: relative;
				.show-more {
					max-height: 1500px;
					transition: max-height .5s linear;
				}
				.hide-more {
					max-height: 76px;
					transition: max-height .5s linear;
				}
				.beforeAnimation {
					transform: rotate(0);
					transition: transform .5s linear;
				}
				.animation {
					transform: rotate(180deg);
					transition: transform .5s linear;
				}
				.h30 {
					label {
						display: inline-block;
						width: 100px;
					}
					span {
						vertical-align: middle;
					}
				}
				.given-price {
					vertical-align: top;
					position: relative;
					top: 10px;
					div {
						display: inline-block;
						vertical-align: top;
					}
				}
			}
			.goods-info {
				label {
					display: inline-block;
					width: 64px;
				}
			}
			.ped {
				line-height: 32px;
			}
			.shopBtn {
				.el-button {
					width: 120px;
					height: 36px;
					font-size: 16px;
				}
				.bg-fed {
					background-color: #ffeded;
					border: 1px solid #ff4201;
				}
				.bg-fed:hover {
					color: #ff4201;
					background-color: #e5d4d4;
				}
				.bg-fed.is-disabled {
					color: #ff9777;
					background-color: #ffeded;
				}
				.el-button--danger.is-disabled:hover {
					background-color: #ff4201;
					border-color: #ff4201;
					color: #ffa080;
				}
			}
			.explain_w {
				position: relative;
				.explain {
					padding: 20px;
					box-sizing: border-box;
					position: absolute;
					top: 37px;
					left: 185px;
					width: 440px;
					height: 150px;
					background-color: #fff;
					box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.50);
					border-radius: 2px;
					p {
						margin-bottom: 15px;
					}
					img {
						position: absolute;
						top: -11px;
						left: 49px;
					}
				}
			}
		}
		/*内容区域*/
		.main {
			.h-340 {
				height: 340px;
			}
			.hot_goods {
				width: 236px;
				margin-left: 30px;
				margin-right: 30px;
				margin-bottom: 40px;
				.hot_goods_title {
					border: 1px solid #dddddd;
					border-bottom: none;
					height: 40px;
					line-height: 40px;
					padding-left: 20px;
				}
				.hot_good_item {
					border: 1px solid #dddddd;
					margin: 0;
					margin-bottom: 10px;
				}
			}
			/*店铺信息*/
			.storeInfo {
				width: 293px;
				margin-right: 6px;
				.h-340 .avar {
					img {
						width: 60px;
						height: 60px;
						border-radius: 50%;
						vertical-align: middle;
					}
					span {
						width: 176px;
						height: 32px;
					}
				}
				.h-340 .address {
					width: 240px;
					label {
						height: 32px;
					}
					span {
						width: 192px;
						height: 32px;
					}
					;
				}
				.h-412 {
					height: 412px;
					img {
						width: 200px;
						height: 174px;
						margin: 47px 47px 72px 46px;
					}
					.bhr {
						width: 196px;
						height: 1px;
						background: #DDDDDD;
						display: block;
					}
					div span {
						display: block;
						width: 235px;
						height: 14px;
						word-break: break-all;
					}
				}
				.store-classify {
					p {
						line-height: 56px;
					}
					ul li {
						line-height: 40px;
						background-color: #fff;
						.el-icon-arrow-right {
							margin-top: 15px;
						}
					}
					ul li:hover {
						background-color: #f6f6f6;
					}
				}
			}
			/*商品信息*/
			.commInfo {
				width: 891px;
				.commInfo-list li {
					width: 249px;
					line-height: 34px;
					padding-right: 20px;
				}
				.text-overflow-ellipsis {
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}
				.h-341 {
					width: 891px;
					overflow: hidden;
				}
			}
		}
		.meal_title {
			display: flex;
			img {
				margin-right: 14px;
				height: 20px;
				margin-top: 3px;
			}
			div {
				font-family: STHeitiSC-Light;
				font-size: 20px;
				color: #333333;
				letter-spacing: -0.48px;
				line-height: 24px;
			}
		}
		a {
			font-family: STHeitiSC-Light;
			font-size: 14px;
			color: #20a0ff;
			letter-spacing: -0.34px;
		}
		.type_btn{
			font-size: 14px;
		}
		.pro_type{
			color:#FF4217;
			border-color: #FF4217; 
		}
	}
</style>
