<template>
  <div id="supercStoreGoods">
    <div class="title-top bg-f mb-5">门店久集商品管理</div>
    <div class="content bg-f manage pt-10">
      <div style="position: relative;">
        <el-tabs v-model="type1" @tab-click="handleTab1Click">
          <el-tab-pane label="未上架" name="1"></el-tab-pane>
          <el-tab-pane label="已上架" name="2"></el-tab-pane>
        </el-tabs>
        <el-badge class="count" :max="99" :value="count" :hidden="count == 0"/>
      </div>
      <div class="clearfix search">
        <el-form :inline="true" class="pt-30 ml-30" :model="formInlineData" label-width="70px">
          <el-form-item label="商品编码" class="mr-20">
            <el-input v-model.trim="formInlineData.skuNo" :maxlength="30" placeholder="请输入商品编码" @input="formInlineData.skuNo=formInlineData.skuNo.replace(/[\u4e00-\u9fa5]/ig,'')"></el-input>
          </el-form-item>
          <el-form-item label="商品名称" class="mr-20">
            <el-input v-model.trim="formInlineData.skuName" :maxlength="30" placeholder="请输入商品名称"></el-input>
          </el-form-item>
          <el-form-item label="商品分类" class="mr-20">
            <el-select v-model="formInlineData.classifyId" clearable>
              <el-option
                :label="item.classifyName"
                :value="item.classifyId"
                v-for="(item, i) in searchClassify"
                :key="i"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="商品类型" class="mr-20" v-show="type == 1">
            <el-select v-model="formInlineData.storeType" clearable>
              <el-option label="特殊商品" value="2"></el-option>
              <el-option label="普通商品" value="1"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div>
          <el-button class="ml-30" type="info" @click="achieve(0)" icon="el-icon-search">查 询</el-button>
        </div>
      </div>
      <div style="text-align:right;margin:5px 30px">
        <el-upload
          style="display: inline-block; margin-right: 10px"
          action="/sunrise-gateway/import/trade/goods/reward?startRow=1&startCol=0"
          :show-file-list="false"
          :on-success="UpSuccess"
          :before-upload="UpBefore"
          :on-error="UpError"
        >
          <el-button>批量导入</el-button>
        </el-upload>
        <a
          style="color: #20a0ff;"
          href="https://sunrise-app.oss-cn-shanghai.aliyuncs.com/template/%E5%95%86%E5%93%81%E4%BD%A3%E9%87%91%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx"
        >
          <el-button>下载模板</el-button>
        </a>
      </div>
      <div class="p-30" v-show="type == 0">
        <el-table
          :data="tableData"
          style="width: 100%"
          @selection-change="handleSelectionChange"
        >
          <el-table-column label="商品名称" min-width="250">
            <template slot-scope="scope" style="display:flex;align-items:center">
              <div class="fl specialD">
                <img v-if="scope.row.fileUrl" :src="scope.row.fileUrl" alt />
                <img v-else src="../../../assets/goods.jpg" alt />
                <img
                  v-if="scope.row.storeType=='2'"
                  class="special"
                  src="../../../assets/special.png"
                  alt
                />
              </div>
              <div class="fl lh-16" style="width:calc(100% - 80px)">
                <div style="width:100%;">
                  商品编码:
                  <span>{{scope.row.goodsNo ? scope.row.goodsNo :'--'}}</span>
                </div>
                <div style="width:100%;margin-top:10px" show-overflow-tooltip>{{scope.row.skuName}}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="规格" min-width="65" align="center">
            <template slot-scope="scope">
              <span>1*{{scope.row.specInfoNum}}</span>
            </template>
          </el-table-column>
          <el-table-column label="商品分类" min-width="80" align="center">
            <template slot-scope="scope">
              <span>{{scope.row.classifyName}}</span>
            </template>
          </el-table-column>
          <el-table-column label="进店价" min-width="80" align="center">
            <template slot-scope="{row}">
              <span v-if="row.sellingPrice!=null">{{row.sellingPrice||0 | fmoney}}</span>
              <span v-else>--</span>
            </template>
          </el-table-column>
          <el-table-column label="佣金" min-width="80" align="center">
            <template slot-scope="{row}">
              <span  v-if="row.goodsRewardValue!=null">{{row.goodsRewardValue ||0 | fmoney}}</span>
              <span v-else>--</span>
            </template>
          </el-table-column>
          <!-- 中心店需求中去除 -->
          <!-- <el-table-column label="标准零售价" min-width="90" align="center">
            <template slot-scope="scope">
              <span
                v-if="scope.row.unitMaxSellingPrice"
              >￥{{scope.row.unitMaxSellingPrice||0 | fmoney }}</span>
              <span v-else>--</span>
            </template>
          </el-table-column>
          <el-table-column label="促销价" min-width="80" align="center">
            <template slot-scope="scope">
              <span
                v-if="scope.row.unitPromotionPrice"
              >￥{{scope.row.unitPromotionPrice||0 | fmoney }}</span>
              <span v-else>--</span>
            </template>
          </el-table-column>
          <el-table-column label="最低零售价" min-width="100" align="center">
            <template slot-scope="scope">
              <span
                v-if="scope.row.unitMinSellingPrice"
              >￥{{scope.row.unitMinSellingPrice||0 | fmoney }}</span>
              <span v-else>--</span>
            </template>
          </el-table-column> -->
          <el-table-column label="最低起订量" min-width="100" align="center">
            <template slot-scope="scope">
              <span
                v-if="scope.row.minimunMoq"
              >{{scope.row.isIntegerMultiple ? scope.row.minimunMoq * scope.row.specInfoNum : scope.row.minimunMoq}} {{scope.row.unitName}}</span>
              <span v-else>--</span>
            </template>
          </el-table-column>
          <!-- 中心店需求中去除 -->
          <!-- <el-table-column label="折扣/返利" min-width="100" align="center">
            <template slot-scope="scope">
              <span>{{scope.row.percentOrReturn ? scope.row.percentOrReturn : '--'}}</span>
            </template>
          </el-table-column>
          <el-table-column label="序号" align="center" min-width="100">
            <template slot-scope="scope">
              <span>{{scope.row.sort != null?scope.row.sort:'-'}}</span>
            </template>
          </el-table-column> -->
          <el-table-column label="操作" align="center" min-width="120">
            <template slot-scope="scope">
              <!-- 中心店需求中去除 -->
              <!-- <el-button class="myBtn" type="text" @click="openSort(scope.row)">排序</el-button> -->
              <el-button class="myBtn" type="text" @click="handleMinimunMoq(scope)">编辑</el-button>
              <el-button
                class="myBtn"
                type="text"
                @click="handleUp(scope)"
              >{{scope.row.skuState == "1" ? "上架":"下架"}}</el-button>
            </template>
          </el-table-column>
        </el-table>
          <!-- 公用的分页组件 -->
          <el-pagination
                  class="tar mt-20"
                  v-if="tableData.length>0"
                  :current-page="currentPage"
                  :page-size="10"
                  :total="total"
                  layout="prev , pager, next, jumper"
                  @current-change="handleCurrentChange"
          ></el-pagination>
      </div>


      <!--佣金和最低起订量弹窗-->
      <el-dialog class="minMop" :title="score ? '编辑' : '编辑'" :visible.sync="minMop">
        <!-- 设置最低起订量 -->
        <el-form ref="minMopForm" class="pr-10" :model="minMopForm" :rules="rules5">
          <el-form-item label="最低起订量:" class="ml-20" required>
            <el-radio-group v-model="radio">
              <el-form-item class="ml-15" prop="unitNum">
                <el-radio :label="1" style="margin-right: 0">{{ }}</el-radio>
                <el-input
                  size="mini"
                  class="ml-10 mr-10"
                  v-model="minMopForm.unitNum"
                  placeholder="最低起订量"
                  :maxlength="3"
                ></el-input>
                <span class="font-14">{{ minMopForm.unitName }}</span>
              </el-form-item>
              <el-form-item class="ml-15" prop="sellingNum">
                <el-radio class="mt-20" style="margin-right: 0;line-height: 32px" :label="2">{{ }}</el-radio>
                <el-input
                  size="mini"
                  class="ml-10 mr-10"
                  v-model="minMopForm.sellingNum"
                  placeholder="最低起订量"
                  :maxlength="3"
                ></el-input>
                <el-select v-model="minMopForm.units" size="mini">
                  <el-option
                    v-for="(item, index) in goodsSellings"
                    :label="item.dictValue"
                    :value="item.dictKey"
                    :key="index"
                  ></el-option>
                </el-select>
                <span class="font-14">（合1*{{ minMopForm.specInfoNum }}{{ minMopForm.unitName }}）</span>
              </el-form-item>
            </el-radio-group>
          </el-form-item>

          <el-form-item class="ml-20" label="佣金" prop="rewardValue">
              <!-- <el-input
                placeholder="请输入"
                @blur="blur4(minMopForm.rewardValue)"
                v-model.trim="minMopForm.rewardValue"
                :max="minMopForm.sellingPrice"
              ></el-input> -->
              <el-input-number :controls="false" v-model="minMopForm.rewardValue" :precision="2" :min="0" :max="minMopForm.rewardType==1?100:minMopForm.sellingPrice" @blur="blur4(minMopForm.rewardValue)" ></el-input-number>
              <el-select v-model="minMopForm.rewardType" size="mini">
                <el-option
                  v-for="(item, index) in arr"
                  :label="item.label"
                  :value="item.id"
                  :key="index"
                ></el-option>
              </el-select>
              <span class="font-14">{{ minMopForm.unitName }}</span>
          </el-form-item>
          
          <el-form-item label="运费设置:" class="ml-20 fare-item"  prop="freTemplateId">
            <el-select v-model="minMopForm.freTemplateId" size="medium" placeholder="默认模板">
              <el-option
                v-for="(item, index) in fareIdList"
                :label="item.name"
                :value="item.id"
                :key="index"
              ></el-option>
            </el-select>
            <el-button type="text" @click="lookTemplate">查看</el-button>
          </el-form-item>
        </el-form>
      
        <div slot="footer" class="dialog-footer">
          <el-button type="danger" @click="handleMinSave(1)">保存</el-button>
          <el-button v-if="type1 == '1'" class="ml-20" @click="handleMinSave(2)">保存并上架</el-button>
        </div>
      </el-dialog>
      <!-- 排序 -->
      <el-dialog class="sort" title="序号" :visible.sync="sortvisiable">
        <el-form ref="sortForm" class="pr-10" :model="sortForm" :rules="sortRules">
          <el-form-item label="序号:" class="ml-20" prop="sort">
            <el-input type="text" v-model="sortForm.sort" placeholder="请输入序号"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="danger" @click="handleSortSave">确定</el-button>
          <el-button class="ml-20" @click="clearSort">重置</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { axiosApi, basePath } from "../../../api/api"; //导入axiosApi

export default {
  data() {
    let reg2 = /^(\d|[1-9]\d|100)(\.\d{1,2})?$/;
    let reg3 = /^[0-9]{1,5}([.]{1}[0-9]{0,2}){0,1}$/;
    var unitNums = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入佣金"));
      } else if (value && this.minMopForm.rewardType == 1) {
        if (!reg2.test(value)) {
          callback(new Error("请输入0-100数字"));
        } else {
          callback();
        }
      } else if (value && this.minMopForm.rewardType == 2) {
        if (!reg3.test(value)) {
          callback(new Error("佣金为5位以内,请输入0-99999.99数字"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    var validateUnitNum = (rule, value, callback) => {
      if ((value == "" || !value) && this.radio == 1) {
        callback(new Error("请输入最低起订量"));
      } else {
        callback();
      }
    };
    var validateSellingNum = (rule, value, callback) => {
      if ((value == "" || !value) && this.radio == 2) {
        callback(new Error("请输入最低起订量"));
      } else {
        callback();
      }
    };
    var freTemplateId = (rule, value, callback) => {
      if (value == "") {
        // callback(new Error('请选择运费模板'));
        callback();
      } else {
        callback();
      }
    };
    return {
      sortvisiable: false,
      id: null, //商品主键id
      sortForm: {
        sort: null
      },
      sortRules: {
        sort: [
          { required: true, message: "请输入序号" },
          {
            pattern: /^[1-9]\d{0,3}$/,
            message: "请输入1-9999之间的整数"
          }
        ]
      },
      type: "0",
      type1: "1",
      radio: 1,
      currentPage: 1,
      total: 0,
      dataTabs: ["酒店协议商品", "全部商品"],
      formInlineData: {
        skuName: null, //商品名称
        skuNo: null, //商品编码
        classifyId: "", //商品分类
        skuState: "1", //商品状态
        storeType: "" //
      },
      searchClassify: [],
      tableData: [],
      score: false, //非协议商品设价弹框
      minMop: false,
      minMopForm: {
        id: null,
        unitNum: 1, //数量
        unitName: "",
        sellingNum: 1, //数量
        sellingUnit: null, //规格单位
        specInfoNum: null, //规格
        units: null, //计量单位
        isIntegerMultiple: false, //是否包装规格整数倍
        freTemplateId: "", //运费模版id
        rewardType: 1,
        rewardValue: 0
      },
      // goodsSellings: ['箱', '瓶'], //包装分类
      goodsSellings: [], //包装分类
      arr: [
        {
          id: 1,
          label: "%"
        },
        {
          id: 2,
          label: "元"
        }
      ], //分数
      rules5: {
        unitNum: [
          {
            validator: validateUnitNum
          },
          {
            pattern: /^[1-9]\d{0,2}$/,
            message: "不可以为0，最大支持3位整数"
          }
        ],
        sellingNum: [
          {
            validator: validateSellingNum
          },
          {
            pattern: /^[1-9]\d{0,2}$/,
            message: "不可以为0，最大支持3位整数"
          }
        ],
        freTemplateId: [{ required:true,message:'请选择运费模板', trigger: "change" }],
        rewardValue: [
          {
            validator: unitNums,
            trigger: "blur",
            required: true
          }
        ]
      },
     
      formPrice: {
        //设置佣金
        id: "", //设价Id
        skuName: null, //商品名称
        priceUnit: null,
        rewardType: 1,
        rewardValue: 0
      },
      count: "",
      fareIdList: []
    };
  },
  watch: {
    radio(val) {
      if (val == 1) {
        this.minMopForm.isIntegerMultiple = false;
        if (this.minMopForm.sellingNum == 0) {
          this.minMopForm.sellingNum = null;
        }
      } else {
        this.minMopForm.isIntegerMultiple = true;
        if (this.minMopForm.unitNum == 0) {
          this.minMopForm.unitNum = null;
        }
      }
    },
    sortvisiable(val) {
      if (!val) {
        let _this = this;
        setTimeout(() => {
          if (_this.$refs.sortForm) _this.$refs.sortForm.resetFields();
        }, 500);
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.achieve();
      this.getClassList();
      this.getFareIdList();
    });
  },
  methods: {
    UpSuccess(res) {
      if (res.code === "000000") {
        this.$message({
          message: "导入成功!",
          type: "success",
        });
      } else {
        this.$confirm(res.message, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {})
          .catch(() => {});
      }
      this.$loading().close();
    },
     UpBefore(file) {
      this.$loading({
        fullscreen: true,
        lock: true,
        text: "正在导入...",
      });
      return true;
    },
    UpError(err, file, fileList) {
      this.$loading().close();
      this.$message.error("导入失败！");
    },
    clearSort() {
      axiosApi(
        "/superc/goodsSort/update",
        {
          supercType: 3,
          id: this.id,
          sort: null,
          isSelf: 0
        },
        res => {
          this.$message({
            type: "success",
            message: "重置成功",
            duration: 1500
          });
          this.achieve(); //默认加载商品列表
          this.sortvisiable = false;
        },
        this.Page,
        10,
        res => {
          this.$message({
            type: "error",
            message: res.data.message,
            duration: 1500
          });
        }
      );
    },
    handleSortSave() {
      this.$refs.sortForm.validate(valid => {
        if (valid) {
          axiosApi(
            "/superc/goodsSort/update",
            {
              supercType: 3,
              id: this.id,
              sort: parseInt(this.sortForm.sort),
              isSelf: 0
            },
            res => {
              this.$message({
                type: "success",
                message: "设置成功",
                duration: 1500
              });
              this.achieve(); //默认加载商品列表
              this.sortvisiable = false;
            },
            this.Page,
            10,
            res => {
              this.$message({
                type: "error",
                message: res.data.message,
                duration: 1500
              });
            }
          );
        }
      });
    },
    openSort(item) {
      this.id = item.id;
      this.sortvisiable = true;
      if (item.sort == null) {
        this.getSort();
      } else {
        this.sortForm.sort = parseInt(item.sort);
      }
    },
    getSort() {
      axiosApi(
        "/superc/goodsSortMax/select",
        {
          supercType: 3,
          isSelf: 0
        },
        res => {
          this.sortForm.sort = res.data.data;
        },
        this.Page,
        10,
        res => {}
      );
    },
    blur4(item) {
      if (
        /^[0-9]{1,7}([.]{1}[0-9]{0,2}){0,1}$/.test(item) &&
        this.minMopForm.rewardType == 2
      ) {
        this.minMopForm.rewardValue = parseFloat(item).toFixed(2);
      }
    },
    getFareIdList() {
      //运费模版列表
      axiosApi(
        "/foundation/fare/templateSelectList",
        {
          traderId: sessionStorage.traderId,
          noDetails: true,
          businessType: "superc"
        },
        res => {
          for (let i = 0; i < res.data.data.elements.length; i++) {
            if (res.data.data.elements[i].isDefault == "1") {
              this.minMopForm.freTemplateId = parseInt(
                res.data.data.elements[i].id
              );
            }
          }
          this.fareIdList = res.data.data.elements.map(item => {
            return {
              id: item.id,
              name: item.isDefault == 1 ? item.name + " (默认模板)" : item.name,
              isDefault: item.isDefault,
              copyId: item.id
            };
          });
        },
        1,
        100
      );
    },
    achieve(val) {
      if (val == 0 && this.currentPage != 1) {
        this.currentPage = 1;
      }
      axiosApi(
        "/superc/web/tenantShopTraderGoods/selectListPage",
        {
          // isAgreementGoods: this.type == "0" ? "1" : "2",
          skuState: parseInt(this.formInlineData.skuState),
          goodsNo: this.formInlineData.skuNo,
          skuNameLike: this.formInlineData.skuName,
          classifyId: this.formInlineData.classifyId
          // storeType: this.formInlineData.storeType,
        },
        res => {
          this.getCount(); //角标数量
          this.tableData = res.data.data.elements ? res.data.data.elements : [];
          this.total = res.data.data.totalCount;
        },
        this.currentPage,
        10,
        res => {}
      );
    },
    getClassList() {
      //获取分类列表
      axiosApi(
        "/superc/classify/selectFirstClassifyList",
        {},
        res => {
          this.searchClassify = res.data.data;
        },
        1,
        100,
        res => {
          this.$message({
            type: "info",
            message: res.data.message,
            duration: 3000
          });
        }
      );
    },
    getCount() {
      //角标数量统计
      axiosApi(
        "/superc/web/tenantTraderGoods/selectCount",
        {
          isSelf: 0,
          skuState: "2"
        },
        res => {
          this.count = res.data.data.standUpCount;
        },
        1,
        10,
        res => {
          this.$message({
            type: "info",
            message: res.data.message,
            duration: 3000
          });
        }
      );
    },
    //协议非协议切换
    handleTabs(e) {
      this.type1 = "1";
      this.formInlineData = {
        skuName: null, //商品名称
        skuNo: null, //商品编码
        classifyId: "",
        skuState: "1", //商品状态
        storeType: ""
      };
      if (e.target.attributes.type) this.type = e.target.attributes.type.value;
      this.achieve(0);
    },
    //上架未上架tab切换
    handleTab1Click(e) {
      this.formInlineData = {
        skuName: null, //商品名称
        skuNo: null, //商品编码
        classifyId: "",
        storeType: ""
      };
      this.type1 = e.name;
      this.formInlineData.skuState = e.name;
      this.achieve(0);
    },
    //new
    handleSelectionChange(val) {
      /*table全选*/
    },
    //分页
    handleCurrentChange(val) {
      this.currentPage = val;
      this.achieve();
    },
    handleUp(scope) {
      //上下架
      if (scope.row.skuState == 1) {
        this.skuState = 2;
        this.stateText = "上架";
      } else if (scope.row.skuState == 2) {
        this.skuState = 1;
        this.stateText = "下架";
      }
      if (scope.row.id) {
        //单个商品上下架数据处理
        this.skuNoList = []; //处理每次添加的订单编号
        this.skuNoList.push(scope.row.skuNo);
      }
      const h = this.$createElement;
      this.$msgbox({
        title: "提示信息",
        message: h(
          "p",
          {
            style: "text-align:center"
          },
          "确认" + this.stateText + "该商品？"
        ),
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            // if(this.skuNoList.length > 0) {
            axiosApi(
              "/superc/web/tenantShopTraderGoods/updateStatus",
              {
                skuState: this.skuState,
                // skuNoList: this.skuNoList,
                // isAgreement: this.type == "0" ? "1" : "2",
                id: scope.row.id
              },
              res => {
                this.$message({
                  type: "success",
                  message: "操作成功",
                  duration: 3000
                });
                this.skuNoList = []; //清空存储的商品编号
                this.currentPage = 1;
                this.achieve(); //刷新列表
              },
              1,
              10,
              res => {
                this.$message({
                  type: "info",
                  message: res.data.message,
                  duration: 3000
                });
              }
            );
            done();
          } else {
            done();
          }
          delete instance.$slots.default;
        }
      });
    },
    handleMinimunMoq(scope) {
      //最低起订量数据处理
      if (this.$refs.minMopForm !== undefined) {
        this.$refs.minMopForm.resetFields();
      }
      if (scope.row.isIntegerMultiple == true) {
        this.radio = 2; //箱
      } else {
        this.radio = 1; //瓶
      }
      this.minMopForm.id = scope.row.id;
      this.minMopForm.units = scope.row.sellingUnit
        ? scope.row.sellingUnit
        : scope.row.goodsSellings[0].dictKey; //箱

      if (scope.row.isIntegerMultiple) {
        this.minMopForm.unitNum = null; //按瓶的最低起订量
        this.minMopForm.sellingNum = scope.row.minimunMoq; //销售单位数量
      } else {
        this.minMopForm.unitNum = scope.row.minimunMoq; //按瓶的最低起订量
        this.minMopForm.sellingNum = null; //销售单位数量
      }
      this.minMopForm.rewardType = scope.row.rewardType; // 佣金类型
      this.minMopForm.rewardValue = scope.row.rewardValue; // 佣金
      this.minMopForm.sellingPrice = scope.row.sellingPrice
      this.minMopForm.sellingUnit = scope.row.unitKey; //规格单位
      this.minMopForm.specInfoNum = scope.row.specInfoNum; //规格数量
      this.minMopForm.unitName = scope.row.unitName; // 瓶or基本单位
      this.minMopForm.freTemplateId = scope.row.freTemplateId
        ? scope.row.freTemplateId
        : this.minMopForm.freTemplateId; //运费模版
      this.minMop = true;
      this.goodsSellings = scope.row.goodsSellings; //规格分类
    },
    submitScoreAndMinnumber(type) {
      //1保存 2保存并上架
      let paramObj = {
        id: this.minMopForm.id, //必需
        isIntegerMultiple: this.minMopForm.isIntegerMultiple, //必需 单位（1：箱；0：瓶）
        sellingUnit:
          this.radio == 1 ? this.minMopForm.sellingUnit : this.minMopForm.units, //必需 单位（1：箱；0：瓶）
        minimunMoq:
          this.radio == 1
            ? this.minMopForm.unitNum
            : this.minMopForm.sellingNum, //按箱的数量
        isStandup: type == "1" ? 0 : 1, //必需 1：保存并上架 0：保存不上架
        freTemplateId: this.minMopForm.freTemplateId
          ? this.minMopForm.freTemplateId
          : null, //运费模版id
        rewardValue: this.minMopForm.rewardValue,
        rewardType: this.minMopForm.rewardType
      };
      // this.score &&  ( paramObj = Object.assign({}, paramObj, {
      // 	rewardValue: this.formPrice.rewardValue, //佣金值(非协议时必输)
      // 	rewardType: this.formPrice.rewardType //佣金单位(1：百分比；2：元) （非协议时必输）
      // }) )
      axiosApi(
        "/superc/web/tenantShopTraderGoods/update",
        paramObj,
        res => {
          this.minMop = false;
          this.achieve(); //刷新列表
          this.$message({
            type: "success",
            message: "设置成功",
            duration: 3000
          });
        },
        this.currentPage,
        10,
        res => {
          this.minMop = false;
          this.achieve(); //刷新列表
          this.$message({
            type: "info",
            message: res.data.message,
            duration: 3000
          });
        }
      );
    },
    handleMinSave(type) {
      //type 1保存 2保存并上架 1确定 2取消
      if (this.type1 === "2" && type == "2") {
        this.minMop = false;
        return false;
      }
      let flag = false; //是否判断执行起订量判断
      let flag1 = false;
      let flag2 = false;
      if (this.score) {
        this.$refs.rules9.validate(valid => {
          if (valid) {
            flag1 = true;
          }
        });
        this.$refs.minMopForm.validate(valid => {
          if (valid) {
            flag2 = true;
          }
        });
        setTimeout(() => {
          if (flag1 && flag2) {
            this.submitScoreAndMinnumber(type);
          }
        }, 0);
      } else {
        this.$refs.minMopForm.validate(valid => {
          if (valid) {
            this.submitScoreAndMinnumber(type);
          }
        });
      }
    },

    renderHeader(h, { column, $index }) {
      let span = <span class="is-required">{column.label}</span>;
      return span;
    },
    lookTemplate() {
      //因为默认都是经过改造的，不存在ID
      if (this.fareIdList && this.fareIdList.length === 0) {
        return false;
      }
      let defaultList = this.fareIdList.filter(item => {
        return item.id == "" && item.isDefault == 1;
      });
      let id = null;
      if (this.minMopForm.freTemplateId == "") {
        id = defaultList[0].copyId;
      } else {
        id = this.minMopForm.freTemplateId;
      }
      window.open(basePath + "/freightHandle?type=1&id=" + id, "_blank");
    },
    handleSetSpecial(scope) {
      if (scope.row.storeType == 1) {
        const h = this.$createElement;
        this.$msgbox({
          title: "提示信息",
          message: h(
            "p",
            {
              style: "text-align:center"
            },
            "确认将该商品设为特殊商品？"
          ),
          showCancelButton: true,
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          beforeClose: (action, instance, done) => {
            if (action === "confirm") {
              axiosApi(
                "/superc/specialGoods/companySet",
                {
                  id: scope.row.id
                },
                res => {
                  this.$message({
                    type: "success",
                    message: "操作成功",
                    duration: 3000
                  });
                  this.achieve(); //刷新列表
                },
                1,
                10,
                res => {
                  this.$message({
                    type: "info",
                    message: res.data.message,
                    duration: 3000
                  });
                }
              );
              done();
            } else {
              done();
            }
            delete instance.$slots.default;
          }
        });
      } else if (scope.row.storeType == 2) {
        const h = this.$createElement;
        this.$msgbox({
          title: "提示信息",
          message: h(
            "p",
            {
              style: "text-align:center"
            },
            "确认取消该特殊商品？"
          ),
          showCancelButton: true,
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          beforeClose: (action, instance, done) => {
            if (action === "confirm") {
              axiosApi(
                "/superc/specialGoods/companyCancel ",
                {
                  id: scope.row.id
                },
                res => {
                  this.$message({
                    type: "success",
                    message: "操作成功",
                    duration: 3000
                  });
                  this.achieve(); //刷新列表
                },
                1,
                10,
                res => {
                  this.$message({
                    type: "info",
                    message: res.data.message,
                    duration: 3000
                  });
                }
              );
              done();
            } else {
              done();
            }
            delete instance.$slots.default;
          }
        });
      }
    }
  }
};
</script>

<style lang="less">
#supercStoreGoods {
  .blue {
    color: #20a0ff;
    display: inline-block;
    width: auto;
    height: auto;
    text-align: center;
    cursor: pointer;
  }
  .sort {
    .el-dialog {
      min-height: 250px !important;
    }
    .el-form-item__label {
      width: 120px !important;
    }
    .el-form-item__error {
      left: 28%;
    }
  }
  .myBtn {
    width: auto;
  }
  .title-box {
    width: 100%;
    height: auto;
    padding: 20px 30px;
    box-sizing: border-box;
    background-color: #ffffff;
  }
  .manage {
    .el-form--inline {
      .el-form-item__label {
        font-size: 12px;
        width: 60px;
      }
    }
  }
  .el-button + .el-button {
    margin-left: 0 !important;
  }
  .el-form-item__label {
    width: 100px;
  }
  .minMop {
    .el-dialog {
      width: 520px;
      min-height: 300px;
        .el-input--mini{
            width: 102px !important;
        }
      .el-form-item__error {
        left: 37px;
      }
    }
  }
  .width-130 {
    min-width: 150px;
  }
  .one {
    .el-input {
      width: 120px !important;
    }
    .el-form-item__error {
      margin-left: 61px;
    }
  }
  .count {
    position: absolute;
    top: 0;
    left: 157px;
  }
  .width-63 {
    width: 63px !important;
  }
  .el-table__body-wrapper {
    margin-top: 1px !important;
  }
  .fare-item {
    .el-form-item__error {
      left: 100px !important;
    }
  }
  .allGoods {
    .el-table .cell {
      padding: 10px;
      /*// text-align: center;*/
    }
  }
  .specialD {
    position: relative;
    img {
      width: 60px;
      height: 60px;
      display: inline-block;
      margin-right: 5px;
    }
    .special {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  .el-input-number .el-input .el-input__inner {
    padding: 0 15px;
  }
}
</style>
