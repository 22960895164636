<template>
  <!--酒店-->
  <div id="contractCustomer">
    <div class="title-top bg-f clearfix">
      酒店管理
      <el-button class="fr mt-14 mr-30" @click="openCustomer('form')">新增酒店</el-button>
    </div>
    <div class="content bg-f pr-30 pl-30 mt-5">
      <div class="clearfix search">
        <el-form :inline="true" class="pt-30" :model="Form" ref="searchForm">
          <el-form-item label="账号" class="mr-20">
            <el-input v-model.trim="Form.loginName" placeholder="请输入账号" :maxlength="40"></el-input>
          </el-form-item>
          <el-form-item label="姓名" class="mr-20">
            <el-input v-model.trim="Form.linkname" placeholder="请输入联系人姓名" :maxlength="40"></el-input>

          </el-form-item>
          <el-form-item label="酒店名称" class="mr-20">
            <el-input v-model.trim="Form.storeName" placeholder="请输入酒店名称" :maxlength="40"></el-input>
          </el-form-item>
        </el-form>
        <div>
          <el-button class="ml-20 mb-20" type="info" @click="achieve(true)" icon="el-icon-search">搜 索</el-button>
        </div>
      </div>
      <div class="pb-30">
        <el-table :data="tableData">
          <el-table-column label="酒店名称" min-width="163">

            <template slot-scope="scope">
              <span>{{scope.row.storeName}}</span>
            </template>
          </el-table-column>
          <el-table-column label="酒店账号" min-width="123">

            <template slot-scope="scope">
              <span>{{scope.row.loginName}}</span>
            </template>
          </el-table-column>
          <el-table-column label="联系人" min-width="133">
            <template slot-scope="scope">
              <span>{{scope.row.linkname}}</span>
            </template>
          </el-table-column>
          <el-table-column label="所属地区" min-width="150">
            <template slot-scope="scope">
              <span
                v-if="scope.row.regionName"
              >{{scope.row.provinceName}}{{scope.row.cityName}}{{scope.row.regionName}}</span>
              <span v-else>-</span>
            </template>
          </el-table-column>
          <el-table-column label="久集类型" min-width="150">
            <template slot-scope="scope">
              <span>{{scope.row.isOpenHotelSuperc == 1?'标准版':scope.row.isOpenHotelSuperc == 3?'简易版':''}}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" min-width="150" align="center" width="">
            <template slot-scope="scope">
              <el-button type="text" @click="goGoodsCare(scope.row)">维护商品</el-button>
              <el-button type="text" @click="openSaleCode(scope.row)">销售员注册码</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          class="tar mt-20"
          v-if="tableData.length>0"
          :current-page="currentPage"
          :page-size="10"
          :total="total"
          layout="prev , pager, next, jumper"
          @current-change="handleCurrentChange"
        >
          <!-- 分页组件 -->
        </el-pagination>
      </div>
      <!--新增酒店-->
      <el-dialog class="minMop" title="新增酒店" :visible.sync="customer">
        <el-form class="pr-10" :rules="rules" ref="form" :model="form">
          <el-form-item label="账号:" class="ml-20" prop="loginName">
            <el-input
              class="ml-10 mr-10"
              v-model="form.loginName"
              placeholder="请输入酒店主账号"
              :maxlength="40"
            ></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="danger" @click="addCustomer('form')">确定</el-button>
          <el-button class="ml-20" @click="customer = false">取消</el-button>
        </div>
      </el-dialog>
      <!-- 销售员注册码 -->
      <el-dialog class="minMop" title="销售员注册码" :visible.sync="saleCodeVisable">
        <div class="img-box">
          <vue-q-art :config="config" :downloadButton="false"></vue-q-art>
        </div>
        <div slot="footer" class="dialog-footer">通过该注册码注册的销售员将免审核</div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { axiosApi, basePath } from "../../../api/api";
import { MessageBox } from "element-ui";
import VueQArt from "vue-qart";
import imagePath from "../../../assets/title.png";
export default {
  components: {
    VueQArt
  },
  data() {
    return {
      customer: false, //新增酒店弹框
      saleCodeVisable: false, // 销售员注册码弹窗
      config: {
        value: "",
        imagePath: imagePath,
        filter: "",
        size: 165
      },
      downloadButton: false,
      Form: {
        storeName: "",
        loginName: "",
        linkname: ""
      },
      form: {
        loginName: ""
      },
      searchForm: {
        companyName: "",
        loginName: ""
      },
      tableData: [], //客户数据
      currentPage: 1,
      total: 0, //页面分页总数
      Page: 1,
      rules: {
        loginName: [
          {
            required: true,
            message: "酒店账号为必填项"
          }
        ]
      },
      isVisable:true,
    };
  },
  watch: {},
  mounted() {
    this.$nextTick(() => {
      this.achieve();
    });
  },
  computed: {},
  methods: {
    achieve(val) {
      //分页查询酒店协议公司
      if (val && this.currentPage != 1) {
        this.currentPage = 1;
        return;
      }
      axiosApi(
        "/superc/web/hotelApply/page",
        {
          storeName: this.Form.storeName,
          loginName: this.Form.loginName,
          linkname: this.Form.linkname
        },
        res => {
          this.tableData = res.data.data ? res.data.data.elements : [];
          this.total = res.data.data.totalCount;
        },
        this.currentPage,
        10,
        res => {}
      );
    },
    openSaleCode(item) {
      this.saleCodeVisable = true;
      //TODO二维码接口或者二维码url
      this.config.value =
        this.PROJECT.href +
        "/#/hotelRegister?sellerId=" +
        item.traderId +
        "&simple=1";
    },
    addCustomer(form) {
      //增加酒店协议公司
      if(this.isVisable){
        this.$refs[form].validate(valid => {
        if (valid) {
          this.isVisable = false
          axiosApi(
            "/superc/hotelSeller/openSimpleSuperc",
            {
              loginName: this.form.loginName
            },
            res => {
              this.achieve();
              this.$message({
                type: "success",
                message: "新增成功",
                duration: 3000
              });
              this.customer = false;
            },
            1,
            10,
            res => {
              this.customer = false;
              this.$message({
                type: "info",
                message: res.data.message,
                duration: 3000
              });
            }
          );
        } else {
        }
      });
      }
      
    },
    openCustomer(form) {
      this.customer = true;
      this.isVisable = true
      if (this.$refs[form] !== undefined) {
        this.$refs[form].resetFields();
      }
    },
    //禁用，启用
    goGoodsCare(val) {
      this.$router.push({path:`${basePath}/supercCareGoods`,query:{
        traderId:val.traderId,
        storeName:val.storeName
      }});
    },
    handleCurrentChange(val) {
      //分页查询
      this.currentPage = val;
      this.achieve();
    },
    handleChange(val) {
      //弹窗分页查询
      this.Page = val;
      this.getSalesList();
    }
  }
};
</script>

<style lang="less">
#contractCustomer {
  .content {
    .img-box {
      width: 100%;
      height: 200px;
      padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      img {
        width: 180px;
        height: 180px;
        display: inline-block;
      }
    }
    .el-form--inline {
      .el-form-item__label {
        font-size: 12px;
        width: 60px;
      }
      .order-datatimerange {
        width: 240px;
      }
    }
    .el-button + .el-button {
      margin-left: 0;
    }
    .el-table .cell {
      text-align: center;
      padding: 10px 0;
      img {
        width: 60px;
        height: 60px;
        margin-left: 10px;
        margin-right: 10px;
      }
      .width-130 {
        width: 130px;
        text-align: left;
      }
      .el-button {
        height: 20px;
        width: 80px;
      }
    }
    .el-table__body-wrapper {
      margin-top: 1px !important;
    }
    .el-table__header {
      width: 969px !important;
    }
    .el-form-item__label {
      width: 100px;
    }
    .reject {
      .el-dialog {
        width: 900px;
        .el-dialog__body {
          min-height: 420px;
        }
      }
      .el-form-item__error {
        margin-left: 100px;
      }
    }
  }
  .el-dialog {
    min-height: 300px !important;
  }
  .el-form-item__error {
    margin-left: 110px !important;
  }
  .bindSales {
    .tc {
      text-align: center;
    }
    .el-dialog {
      width: 900px;
      min-height: 700px !important;
    }
    .el-dialog__body {
      padding: 20px;
      .search {
        .el-form-item__label {
          width: 80px;
        }
        .el-form--inline {
          width: 350px !important;
        }
      }
    }
  }
}
</style>
