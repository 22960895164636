<template>
    <div id="superBAudit">
        <!-- head -->
        <div class="title-top bg-f mb-5">
            <div class="clearfix black">
                <el-breadcrumb class="fl ">
                    <el-breadcrumb-item v-if="!isAudit&&Data.state==1">待审核资料</el-breadcrumb-item>
                    <el-breadcrumb-item v-else>申请资料</el-breadcrumb-item>
                </el-breadcrumb>
                <el-button class="fr mt-15" type='text' @click="dialogShow1 = true">申请记录</el-button>
            </div>
        </div>
        <!-- body -->
        <div class="bg-f mt-5">
            <div class="ml-100 pl-20 pt-50 pb-40 bg">
                <el-form label-width='130px'>
                    <el-form-item label="注册手机号:">
                        <span class="fl">{{Data.loginName}}</span>
                    </el-form-item>
                    <el-form-item label="申请人姓名:">
                        <span class="fl">{{Data.name}}</span>
                    </el-form-item>
                    <el-form-item label="身份证号码:">
                        <span class="fl">{{Data.idNo}}</span>
                    </el-form-item>
                    <el-form-item label="身份证正面:">
                        <div>
                            <img v-if="!Data.idFrontImg" class="fl icon-60 bd" src="../../assets/goods.jpg"/>
                            <img v-else class="fl icon-60 bd" :src="Data.idFrontImg"/>
                            <div class="ml-20 fl">
                                <el-button type='text' @click="showImg(Data.idFrontImg)">查看</el-button>
                            </div>
                        </div>
                    </el-form-item>
                    <el-form-item label="身份证反面:">
                        <div>
                            <img v-if="!Data.idBackImg" class="fl icon-60 bd" src="../../assets/goods.jpg"/>
                            <img v-else class="fl icon-60 bd" :src="Data.idBackImg"/>
                            <div class="ml-20 fl">
                                <el-button type='text' @click="showImg(Data.idBackImg)">查看</el-button>
                            </div>
                        </div>
                    </el-form-item>
                    <el-form-item label="推荐人:">
                        <span class="fl">{{Data.recommendName}}</span>
                    </el-form-item>
                    <el-form-item label="申请时间:">
                        <span class="fl">{{Data.gmtModified | time}}</span>
                    </el-form-item>
                    <el-form-item label="申请理由:">
                        <span class="fl">{{Data.reason}}</span>
                    </el-form-item>
                    <el-form-item label="审核结果:" v-if="Data.state==3">
                        <span>审核不通过</span>
                    </el-form-item>
                    <el-form-item label="备注:" v-if="Data.state==3">
                        <span class="fl">{{Data.auditLogVOS[0].remark}}</span>
                    </el-form-item>
                    <el-form-item label="审核时间:" v-if="Data.state==3">
                        <span class="fl">{{Data.gmtCreate | time}}</span>
                    </el-form-item>
                </el-form>
            </div>


            <div style="margin-left: 100px" v-if="isAudit">
                <el-radio-group v-model="radio">
                    <el-radio :label="3">审核不通过</el-radio>
                    <el-radio :label="2">审核通过</el-radio>
                </el-radio-group>
                <el-form class="pr-30 mt-30" :model="form" ref="rejectForm" v-if="radio==3">
                    <el-form-item label="备注（选填）" prop='rejectionReason'>
                        <el-input type='textarea' placeholder='备注内容最多200字' :maxlength="200"
                                  :autosize="{ minRows: 3 ,maxRows: 10}"
                                  v-model.trim="form.reason"></el-input>
                    </el-form-item>
                </el-form>
                <div slot='footer' class="dialog-footer mt-30">
                    <el-button type='danger' @click="save()">提 交</el-button>
                    <el-button @click="isAudit = false">取 消</el-button>
                </div>
            </div>


            <div style="margin-left: 150px" class="clearfix" v-if="!isAudit&&Data.state==1">
                <el-button type='danger' class="fl" @click="isAudit=true">去审核</el-button>
                <el-button class="fl ml-50" @click="goBack()">关闭页面</el-button>
            </div>

            <div style="margin-left: 150px" class="clearfix" v-if="Data.state==3">
                <el-button class="fl ml-50" @click="goBack()">关闭页面</el-button>
            </div>
        </div>

        <!-- dialog -->
        <el-dialog title="身份证照片" :visible.sync='dialogShow'>
           <div class='bg-img' :style="{backgroundImage: 'url('+ img +')'}"></div>
        </el-dialog>

        <!-- dialog -->
        <el-dialog title="申请记录" :visible.sync='dialogShow1'>
            <el-table :data="Data.auditLogVOS" stripe class='table-default font-12 ml-30 mr-30 mt-50'>
                <el-table-column label='申请时间'>
                    <template slot-scope="scope">
                        <span>{{scope.row.gmtCreate | time}}</span>
                    </template>
                </el-table-column>
                <el-table-column label='审核结果'>
                    <template slot-scope="scope">
                        <span v-if="scope.row.state == 1">待审核</span>
                        <span v-if="scope.row.state == 2">待发货</span>
                        <span v-if="scope.row.state == 3">不通过</span>
                    </template>
                </el-table-column>
                <el-table-column label='备注' prop="remark" ></el-table-column>
            </el-table>
        </el-dialog>

    </div>
</template>

<script>
    import {axiosApi} from "../../api/api";

    export default {
        name: "superBAudit",
        data() {
            return {
                isAudit: false,
                dialogShow: false,
                dialogShow1: false,
                radio: 3,
                img: null,
                form: {
                    reason: null,
                },
                Data: {},//商品来源
                remark: null
            }
        },
        methods: {
            achieve() {
                this.Loading = true;
                let body = {
                    traderId: this.$route.query.traderId
                };
                let url = '/superb/account/detail';
                axiosApi(url, body, res => {
                    this.Loading = false;
                    this.Data = res.data.data;
                }, this.agencyProPage, 10, err => {
                })
            },
            showImg(img) {
                this.dialogShow = true;
                this.img = img;
            },
            save() {
                axiosApi('/superb/account/audit', {
                    accountId: this.Data.id,
                    remark: this.form.reason,
                    state: this.radio,
                }, (res) => {
                    this.$message({
                        type: 'success',
                        duration: 1000,
                        message: "审核完成!",
                    });
                    this.goBack();
                }, this.currentPage, 10, (res) => {
                    this.$message.error(res.data.message)
                });
            },
            goBack() {
                this.$router.push({path: 'superBManager'});
            }

        },
        mounted() {
            this.$nextTick(() => {
                this.achieve()
            })
        }
    }
</script>

<style lang="less">
    #superBAudit {
		.bg-img{
			width: 800px;
			height: 550px;
			margin-top: -30px;
			overflow: hidden;
			background-origin:content; 
			background-position:50% 50%; 
			background-size:contain; 
			background-repeat:no-repeat; 
			
		}
        .el-dialog {
            width: 800px;
            height: 600px;
			.el-dialog__body{
				width: 800px;
				height: 600px;
			}
            img {
                max-width: 800px;
                max-height: 540px;
            }
        }

        .el-form-item__label {
            width: 100px;
        }

        .el-textarea {
            width: 50%;
        }
    }
</style>