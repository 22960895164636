var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"smsPushBox"},[_c('messageTitle',{staticClass:"bg-f",attrs:{"type":2}}),_vm._v(" "),_c('div',{staticClass:"formBox mt-5 bg-f pt-20"},[_c('el-form',{ref:"form",attrs:{"model":_vm.form,"rules":_vm.ruleForm,"label-width":"120px"}},[_c('el-form-item',{attrs:{"label":"接收对象："}},[_c('div',{staticClass:"mb-10"},[_c('el-radio-group',{on:{"change":_vm.handleReceiveObj},model:{value:(_vm.form.receiveObj),callback:function ($$v) {_vm.$set(_vm.form, "receiveObj", $$v)},expression:"form.receiveObj"}},[_c('el-radio',{attrs:{"label":2}},[_vm._v("客户")]),_vm._v(" "),_c('el-radio',{attrs:{"label":1}},[_vm._v("员工")])],1)],1),_vm._v(" "),(_vm.form.receiveObj === 2)?_c('div',[_c('el-radio-group',{on:{"change":_vm.handleCustomer},model:{value:(_vm.form.customerType),callback:function ($$v) {_vm.$set(_vm.form, "customerType", $$v)},expression:"form.customerType"}},[_c('el-radio',{attrs:{"label":1}},[_vm._v("全部客户")]),_vm._v(" "),_c('el-radio',{attrs:{"label":2}},[_vm._v("分组客户")]),_vm._v(" "),_c('el-radio',{attrs:{"label":3}},[_vm._v("区域客户")]),_vm._v(" "),_c('el-radio',{attrs:{"label":4}},[_vm._v("特定客户")])],1),_vm._v(" "),(_vm.form.customerType === 2 || _vm.form.customerType === 3)?_c('div',{staticClass:"treeBox"},[(_vm.form.customerType === 2)?_c('el-tree',{ref:"fzTree",attrs:{"props":{
                                value: 'id',
                                label: 'name',
                                children:'sub'
                            },"data":_vm.form.fzCustomerData,"show-checkbox":"","node-key":"id","default-expand-all":"","expand-on-click-node":false}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"qyTreeBox"},[(_vm.form.customerType === 3)?_c('el-tree',{ref:"qyTree",attrs:{"check-strictly":true,"props":{
                                    value: 'id',
                                    label: 'districtName',
                                    children:'subDistrictList'
                                },"data":_vm.form.qyCustomerData,"show-checkbox":"","node-key":"id","default-expand-all":true,"expand-on-click-node":false,"render-content":_vm.renderContent}}):_vm._e()],1)],1):_vm._e()],1):_vm._e(),_vm._v(" "),(_vm.form.receiveObj === 1)?_c('div',[_c('el-radio-group',{on:{"change":_vm.handleStaff},model:{value:(_vm.form.staffType),callback:function ($$v) {_vm.$set(_vm.form, "staffType", $$v)},expression:"form.staffType"}},[_c('el-radio',{attrs:{"label":1}},[_vm._v("全部员工")]),_vm._v(" "),_c('el-radio',{attrs:{"label":2}},[_vm._v("部门员工")]),_vm._v(" "),_c('el-radio',{attrs:{"label":3}},[_vm._v("特定角色")]),_vm._v(" "),_c('el-radio',{attrs:{"label":4}},[_vm._v("特定员工")])],1),_vm._v(" "),(_vm.form.staffType === 2 || _vm.form.staffType === 3)?_c('div',{staticClass:"treeBox"},[_c('div',{staticClass:"qyTreeBox"},[(_vm.form.staffType === 2)?_c('el-tree',{ref:"bmTree",attrs:{"check-strictly":true,"props":{
                                    value: 'id',
                                    label: 'text',
                                    children:'sub'
                                },"data":_vm.form.bmCustomerData,"show-checkbox":"","node-key":"id","default-expand-all":true,"expand-on-click-node":false,"render-content":_vm.renderContent2}}):_vm._e()],1),_vm._v(" "),(_vm.form.staffType === 3)?_c('el-tree',{ref:"jsTree",attrs:{"props":{
                                value: 'id',
                                label: 'text',
                                children:'subDistrictList'
                            },"data":_vm.form.jsCustomerData,"show-checkbox":"","node-key":"id","default-expand-all":"","expand-on-click-node":false}}):_vm._e()],1):_vm._e()],1):_vm._e(),_vm._v(" "),(_vm.form.customerType === 4 || _vm.form.staffType === 4)?_c('div',[_c('el-input',{style:({width : '360px',marginTop : '10px'}),attrs:{"placeholder":"请输入手机号"},model:{value:(_vm.form.userNameOrPhone),callback:function ($$v) {_vm.$set(_vm.form, "userNameOrPhone", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.userNameOrPhone"}}),_vm._v(" "),_c('span',{staticClass:"color-6"},[_vm._v("\n                        直接输入手机号，多个手机号用英文逗号“,”隔开\n                    ")])],1):_vm._e()]),_vm._v(" "),_c('el-form-item',{attrs:{"label":"消息内容：","prop":"messageContent"}},[_c('el-input',{style:({width : '360px'}),attrs:{"type":"textarea","maxlength":256,"autosize":{ minRows: 4, maxRows: 4}},model:{value:(_vm.form.messageContent),callback:function ($$v) {_vm.$set(_vm.form, "messageContent", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.messageContent"}})],1),_vm._v(" "),_c('el-form-item',{attrs:{"label":"发送时间："}},[_c('el-radio-group',{on:{"change":_vm.handleSend},model:{value:(_vm.form.sendType),callback:function ($$v) {_vm.$set(_vm.form, "sendType", $$v)},expression:"form.sendType"}},[_c('el-radio',{attrs:{"label":1}},[_vm._v("立即")]),_vm._v(" "),_c('el-radio',{attrs:{"label":2}},[_vm._v("定时")])],1),_vm._v(" "),(_vm.form.sendType === 2)?_c('el-date-picker',{attrs:{"clearable":false,"type":"datetime","placeholder":"选择日期时间"},model:{value:(_vm.form.timer),callback:function ($$v) {_vm.$set(_vm.form, "timer", $$v)},expression:"form.timer"}}):_vm._e()],1),_vm._v(" "),_c('el-form-item',{staticClass:"pt-30 pb-30"},[_c('el-button',{attrs:{"loading":_vm.loading,"type":"primary"},on:{"click":_vm.handleSubmit}},[_vm._v("确定")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }