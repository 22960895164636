<template>
	<div id="history">
		<div class="title-top bg-f">
			历史轨迹
		</div>
		<div class='bg-f mt-5 pb-50 pl-30 pr-30'>
			<el-form :inline="true" :model="formInlineData" label-width="90px" class="clearfix pt-30" style="display:flex">
				<div>
					<el-form-item label='时间'>
						<ys-daterange-picker v-model="formInlineData.orderDateRange" type="date" :endDisabledDate="new Date()"/>
					</el-form-item>
					<el-form-item label="姓名">
						<el-input v-model.trim="formInlineData.name" @keyup.enter.native='achieve(true)' :disabled="isAdmin == 0 && isDepartmentLeader == 0"></el-input>
					</el-form-item>
				</div>
				<div style="flex:1;min-width: 200px">
					<el-button type="info" class="fl ml-50" @click='achieve(true)' icon="el-icon-search">搜 索</el-button>
				</div>
			</el-form>
			<div class="mr-30 pr-10 clearfix">
				<el-button v-if='showButton("EXPORT",200019)' class='fr ml-10' type="text" @click="exporttable">导出</el-button>
			</div>
			<el-table :data="historyList" class='mt-10 table-default' v-loading="Loading">
				<el-table-column label="时间" min-width="100" prop='trackDate'>
				</el-table-column>
				<el-table-column label="部门" min-width="100" prop='departmentName'>
				</el-table-column>
				<el-table-column label="姓名" min-width="100" prop='managerName'>
				</el-table-column>
				<el-table-column label="状态" min-width="100">
					<template slot-scope="scope">
						<p v-if='scope.row.trackStatus == 0'>轨迹不全</p>
						<p v-if='scope.row.trackStatus == 1'>轨迹正常</p>
					</template>
				</el-table-column>
				<el-table-column label="在线时长" min-width="100">
					<template slot-scope="scope">
						<p>{{scope.row.onlineTime | timeown }}</p>
					</template>
				</el-table-column>
                <el-table-column label="里程" min-width="100">
                  <template slot-scope="scope">
                                <p>{{scope.row.totalMeters | meters }}</p>
                            </template>
                </el-table-column>
				<el-table-column label="拜访商户次数" min-width='90' prop='visitTime'>
				</el-table-column>
				<el-table-column label="失联时长" min-width="100">
					<template slot-scope="scope">
						<p>{{scope.row.outlineTime | timeown }}</p>
					</template>
				</el-table-column>
				<el-table-column label="操作" min-width="100">
					<template slot-scope="scope">
						<p class='color-blue cursor' @click="topathdetail(scope.$index, historyList)">查看详情</p>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination v-if='total>0' class="tar mt-20" :current-page="currentPage" :page-size='10' :total='total' layout="prev , pager, next, jumper" @current-change="handleCurrentChange">
			</el-pagination>
			<form ref="form" action='/sunrise-gateway/export/sales/web/clockTrackTotal' style="display: none">
				<input type="text" name="startRow" v-model="a">
				<input type="text" name="startCol" v-model="b">
				<input v-if='formInlineData.name1' type="text" name="managerName" v-model="formInlineData.name1">
				<input v-if='formInlineData.startDate1' type="text" name="startDate" v-model="formInlineData.startDate1">
				<input v-if='formInlineData.endDate1' type="text" name="endDate" v-model="formInlineData.endDate1">
				<input type="text" name="fileName" v-model="fileName">
				<!--<input type="text" name="modelName" v-model="c">-->
				<input type="text" name="modelName" v-model="modelName">
			</form>
		</div>

	</div>
</template>

<script>
	import { axiosApi, basePath } from "../../api/api"
	// import YsDaterangePicker from "~/components/ys-daterange-picker";
	export default {
		// components: {YsDaterangePicker},
		data() {
			return {
				historyList: [],
				formInlineData: {
					orderDateRange: [],
					name: null,
					startDate: null,
					endDate: null,
					name1: null,
					startDate1: null,
					endDate1: null,
				},
				a: 0,
				b: 0,
				c: 'empty',
				//分页
				currentPage: 1,
				total: 0,
				Loading: false,
				isPagination: true,
				modelName: 'sales.web.clockTrackTotal',
				fileName: '',
				isDepartmentLeader:null
			};
		},
		computed: {
			isAdmin() {
				return sessionStorage.getItem('isAdmin')
			},
		},
		methods: {
			getEndDate(start) {
				var d = new Date(start);
				d.setMonth((d.getMonth()) + 1);
				var yy1 = d.getFullYear();
				var mm1 = d.getMonth()+1;
				var dd1 = d.getDate();
				mm1 = mm1 < 10 ? '0' + mm1 : mm1
				dd1 = dd1 < 10 ? '0' + dd1 : dd1
				let newVal = yy1 + '-' + mm1 + '-' + dd1
				return newVal;
			},
			getStartDate(newend) { //仅获取当天的前一个月
				var d = new Date(newend);
				d.setMonth((d.getMonth()) - 1);
				var yy1 = d.getFullYear();
				var mm1 = d.getMonth()+1;
				var dd1 = d.getDate();
				mm1 = mm1 < 10 ? '0' + mm1 : mm1
				dd1 = dd1 < 10 ? '0' + dd1 : dd1
				let newVal = yy1 + '-' + mm1 + '-' + dd1
				return newVal;
			},
			getToday() {
				let d = new Date();
				var yy1 = d.getFullYear();
				var mm1 = d.getMonth()+1;
				var dd1 = d.getDate();
				mm1 = mm1 < 10 ? '0' + mm1 : mm1
				dd1 = dd1 < 10 ? '0' + dd1 : dd1
				let newVal = yy1 + '-' + mm1 + '-' + dd1
				return newVal;
			},
			limitTime(start, end, num) {
				let getNewEnd = this.getEndDate(start)
				let nowEnd = (new Date(end)).toLocaleDateString()
				let getNewEnd1 = Date.parse(new Date(getNewEnd))
				let nowEnd1 = Date.parse(new Date(nowEnd))
				if (nowEnd1 > getNewEnd1) {
					this.$message({
						type: 'warning',
						message: '请选择一个月以内的数据',
						duration: 2000
					});
					return false
				} else {
					this.formInlineData.name1 = this.formInlineData.name
					axiosApi('/sales/clockTrackTotal/web/selectListPage', {
						managerName: this.formInlineData.name,
						startDate: start,
						endDate: end
					}, res => {
						if(!res.data.data.elements || res.data.data.elements && res.data.data.elements.length == 0) {
							let message = num == 1 ? '您所选择的时间之后的' : num == 2 ? '您所选择的时间之前的' : num == 3 ? '默认最近的' : ''
							this.$message({
								type: 'warning',
								message: message +'一个月范围内无数据可导出',
								duration: 2000
							});
							return false
						} else {
							this.formInlineData.startDate1 = start
							this.formInlineData.endDate1 = end
							setTimeout(() => {
								this.$refs.form.submit()
							}, 0)
						}
					}, this.currentPage, 10)
				}
			},
			exporttable() {
				let range = this.formInlineData.orderDateRange
				let start = this.formInlineData.startDate1
				let end = this.formInlineData.endDate1
				if(this.historyList.length > 0) {
					if (start && end && Date.parse(range[0]) == start && range[1] && range[1].getTime() == end) {
						this.limitTime(start, end, 0)
					} else if (start && Date.parse(range[0]) == start && (!end || range[1] && range[1].getTime() != end)) {
						// 仅有开始日期，结束日期为今天或者开始日期一个月以后的某一天
						let d1 = this.getEndDate(start)
						let d2 = this.getToday()
						if (Date.parse(d1) < Date.parse(d2)) {
						  end = d1
						} else {
							end = d2
						}
						this.limitTime(start, end, 1)
					} else if ((!start || Date.parse(range[0]) != start) && end && range[1] && range[1].getTime() == end) {
						// 仅有结束日期，开始日期为默认一个月之前某一天
						start = Date.parse(this.getStartDate(end))
						this.limitTime(start, end, 2)
					} else {
						// 默认今天之前一个月的
						start = Date.parse(this.getStartDate(this.getToday()))
						end = Date.parse(this.getToday())
						this.limitTime(start, end, 3)
					}
				} else {
					this.$message({
						type: 'warning',
						message: '暂无数据可导出!',
						duration: 2000
					});
				}
			},

			topathdetail(index, data) {
				var managerId = data[index].managerId
				var trackDate = data[index].gmtCreate
				var departmentId = data[index].departmentId
				this.$router.push({
					path: basePath + '/pathdetail'
				})
				sessionStorage.setItem('pathSearchParams',JSON.stringify(
					{
						managerId: managerId,
						trackDate: trackDate,
						departmentId: departmentId
					}
				))
			},
			achieve(bool) {
				if(bool&&this.currentPage != 1) {
					this.currentPage = 1;
					return
				}
				this.Loading = true
				if(this.formInlineData.name == '') {
					this.formInlineData.name = null
				}
				this.formInlineData.startDate = this.formInlineData.orderDateRange[0] ? this.formInlineData.orderDateRange[0].getTime() : null
				this.formInlineData.endDate = this.formInlineData.orderDateRange[1] ? this.formInlineData.orderDateRange[1].getTime() : null
				//导出数据
				this.formInlineData.endDate1 = this.formInlineData.endDate
				this.formInlineData.startDate1 = this.formInlineData.startDate
				this.formInlineData.name1 = this.formInlineData.name
				const api = "/sales/clockTrackTotal/web/selectListPage";
				const body = {
					managerName: this.formInlineData.name,
					startDate: this.formInlineData.startDate,
					endDate: this.formInlineData.endDate
				}
				axiosApi(api, body, this.callback, this.currentPage, 10)
			},
			callback(res) {
				this.historyList = res.data.data.elements ? res.data.data.elements : []
				this.total = res.data.data.totalCount
				this.Loading = false
			},
			//			分页
			handleCurrentChange(val) {
				this.currentPage = val
				this.achieve()
			},
		},
		//进入加载
		activated() {
			this.$nextTick(function() {
				if(this.isAdmin == 0 && this.isDepartmentLeader == 0) {
					this.formInlineData.name = sessionStorage.getItem('employeeName')
				}
				this.achieve();
				let d = new Date();
				let str = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate();
				this.fileName = '云销轨迹报表_' + str + '.xls'
			})
		},
		mounted(){
            this.resolveIsSalesDepartmentLeaderWithCache(res=>{
                console.log(res)
                this.isDepartmentLeader = res
            });
		}
	}
</script>

<style lang="less">
	#history {
		.export {
			margin: 10px 30px;
		}
	}
</style>