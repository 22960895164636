<template>
  <div class="addacticket">
    <div class="title-tabs bg-f">
      <ul>
        <li>添加活动券</li>
      </ul>
		</div>

    <div class="content bg-f pl-30 pr-30 pt-30">
			<div class="clearfix">
        <div class="aclist">
          <div class="title">
            优惠券
          </div>
          <div class="body">
            <ul>
              <li v-for="(aclist,index) in aclists">
                <div class="">
                  <i class='iconfont'></i>
                </div>
                <div class="">
                  <div class="sub-tit" v-text="aclist.subtit">
                  </div>
                  <div class="sub-body" v-text="aclist.subbody">
                  </div>
                </div>
                <div class="">
                  <el-button @click='valueticket(index)'>去配置</el-button>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { axiosApi,basePath } from '../../../api/api' //导入axiosApi
export default {
  data() {
    return {
      aclists: [
        {
          subtit: '店铺优惠券',
          subbody: '优惠券的一种，全店铺通用，只受订单消费金额或数量限制',
        },
        {
          subtit: '商品优惠券',
          subbody: '优惠券的一种，除受订单金额或数量限制外，也受商品限制，只能购买指定商品。提交订单使用优惠券时，订单中的商品必须在优惠券制定商品范围内且金额或数量达到要求',
        },
        {
          subtit: '客户优惠券',
          subbody: '优惠券的一种，除受使用限制或数量限制外，也受用户限制，只有指定用户才能领取。提交订单使用优惠券时，消费金额或数量达到限额要求',
        },
        {
          subtit: '优惠券',
          subbody: '商户可以从商品、用户、订单金额多维度来设置优惠券的使用条件，满足不同使用场景优惠券的发放要求',
        }
      ]
    }
  },
  methods: {
    valueticket(val) {
      switch(val) {
        case 0: 
          this.$router.push({path: basePath + '/activities/addshopticket', query:{couponSource: this.$route.query.couponSource || 1}})
          return 
        case 1: 
          this.$router.push({path: basePath + '/activities/addgoodticket', query:{couponSource: this.$route.query.couponSource || 1}})
          return 
        case 2: 
          this.$router.push({path: basePath + '/activities/addclientticket', query:{couponSource: this.$route.query.couponSource || 1}})
          return 
        case 3: 
          this.$router.push({path: basePath + '/activities/addticket', query:{couponSource: this.$route.query.couponSource || 1}})
          return 
        default: 
          return
      }
    }
  }
}
</script>

<style lang="less">
.addacticket {
  overflow: hidden;
}
.aclist {
  .title {
    font-size: 14px;
    color: #333;
    padding-left: 8px;
    position: relative;
    &::before {
      content: '';
      display: block;
      width: 4px;
      height: 16px;
      background: #27B0FC;
      position: absolute;
      left: 0;
      top: 3px;
    }
  }
  .body {
    overflow: hidden;
    margin-top: 18px;
    ul {
      overflow: hidden;
      li {
        width: 460px;
        float: left;
        height: 140px;
        box-sizing: border-box;
        padding: 20px;
        border: 1px solid #ddd;
        margin-bottom: 20px;
        &:nth-child(odd) {
          margin-right: 20px;
        }
        &>div {
          float: left;
          &:nth-child(1) {
            &::after {
              content: '';
              display: block;
              height: 1px;
            }
            width: 0px;
            margin-right: 10px;
          }
          &:nth-child(2) {
            width: 300px;
            margin-right: 15px;
            .sub-tit {
              font-size: 18px;
              line-height: 18px;
              color: #333;
            }
            .sub-body {
              font-size: 14px;
              color: #666;
              margin-top: 10px;
            }
          }
          &:nth-child(3) {
            button {
              margin-top:  18px;
            }
          }
        }
      }
    }
  }
}
</style>
