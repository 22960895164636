<template >
    <div class="add-gift-sent">
        <el-form :model="form" ref='form' label-width="98px" :rules="rules">
            <el-form-item label="活动名称:" prop="activityName">
                <div class="inline-block">
                    <el-input v-model.trim="form.activityName" palceholder="输入活动名称" class=""></el-input>
                </div>
                <div class="inline-block ml-40">
                    <template>
                        <!-- <el-checkbox v-model="isOvering">可叠加</el-checkbox> -->
                        <el-checkbox v-model="isNewUser">是否首单</el-checkbox>
                    </template>
                </div>
            </el-form-item>
            <el-form-item label="活动时间:" prop="value1">
                <div class="">
                    <template>
                        <div>
                             <el-date-picker
                                v-model="form.value1"
                                type="datetimerange"
                                range-separator="至"
                                value-format="timestamp"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期">
                            </el-date-picker>
                            <!-- <ys-daterange-picker v-model="form.value1" type="datetime"/> -->
                        </div>
                    </template>
                </div>
            </el-form-item>
        </el-form>
        <el-form :model="form" label-width="98px" class="">
            <el-form-item label="活动规则:">
                <template>
                    <div>
                        <el-radio class="radio" v-model="secradio" label="1">订购金额</el-radio>
                        <el-radio class="radio " v-model="secradio" label="2">订购数量</el-radio>
                        <el-radio class="radio" v-model="secradio" label="3">每满额送</el-radio>
                        <el-radio class="radio mr-30" v-model="secradio" label="4">每满件送</el-radio>
                        <!-- <span>订购金额</span> -->
                        <div class="mt-20 ml-10">
                             <div v-if="secradio==2||secradio==4" class="mb-20">
                                <span class='redalert ml-10'>*</span>
                                <span class="">商品类型:</span>
                                <el-radio class="radio" v-model="unitType" label="1">按箱设置</el-radio>
                                <el-radio class="radio mr-30" v-model="unitType" label="2">按瓶设置</el-radio>
                            </div>
                            <div class="mt-10" v-for="(x,y) in activityRulesList" :key="y"
                                 :style="{overflow: 'hidden'}">
                                <div class="clearfix">
                                    <div class="fl">
                                        <span class='redalert ml-10'>*</span>
                                        <span class="" v-if="secradio==1||secradio==2">满</span>
                                        <span class="" v-if="secradio==3||secradio==4">每满</span>
                                        <span>
                                            <sy-input v-show="secradio == '1'" v-model="x.fullMoney" type="float" size="mini" :min="0.01"
                                                    :max="99999999.99"></sy-input>
                                            <sy-input v-show="secradio == '2'" v-model="x.fullAmount" type="int" size="mini" :min="1"
                                                    :max="99999999"></sy-input>
                                            <sy-input v-show="secradio == '3'" v-model="x.fullMoney" type="float" size="mini" :min="0.01"
                                                    :max="99999999.99"></sy-input>
                                            <sy-input v-show="secradio == '4'" v-model="x.fullAmount" type="int" size="mini" :min="1"
                                                    :max="99999999"></sy-input>
                                            <span v-if="secradio == '3'||secradio == '1'">元</span>
                                            <span v-if="(secradio == '2'||secradio == '4')&&unitType == 1">箱</span>
                                            <span v-if="(secradio == '2'||secradio == '4')&&unitType == 2">瓶</span>
                                        </span>
                                    </div>
                                    <div class="fl ml-20 mb-10">
                                        <el-button type="text" class="ml-20" style="width:auto;"
                                                   @click="addgiftcombination(y,x)">添加赠品组合
                                        </el-button>
                                        <i v-if="secradio=='1'||secradio=='2'" @click="deletemoneylist(y)"
                                           class="el-icon-circle-close font-16 cursor ml-20"></i>
                                    </div>
                                </div>
                                <div class="ml-20 clearfix" v-for="(item,i) in x.giftList" :key="i">
                                    <div class="mb-5">
                                        <span class='redalert ml-10'>*</span>
                                        <span>{{'赠品组合'+numTochinese(i+1)+': '}}</span>
                                        <el-button type="text" class="ml-20" style="width:auto;"
                                                   @click="addgift(y,x,i,item)">添加赠品
                                        </el-button>
                                        <i @click="deletecombination(y, i)"
                                           class="el-icon-circle-close font-16 cursor ml-20"></i>
                                    </div>
                                    <div class="ml-50">
                                        <div v-for="(n, key) in item.subgiftList" class="mb-10">
                                            <el-input v-model.trim="n.skuName" palceholder=""
                                                      :disabled="true"></el-input>
                                            <span class='redalert ml-10'>*</span>
                                            <span>数量</span>
                                            <sy-input v-model="n.quantity" type="int" size="mini" :min="1"
                                                      :max="99999999"></sy-input>
                                            <!--<span>{{n.giftUnit}}</span>-->
                                            <span v-if="n.originGiftType == 1">{{n.giftUnit}}</span>
                                            <el-select v-else class="unit_select" v-model="n.unitType"
                                                       placeholder="请选择">
                                                <el-option v-for="item in n.unitOptions" :key="item.value"
                                                           :label="item.label" :value="item.value"></el-option>
                                            </el-select>
                                            <span class="ml-20" v-if="secradio==3||secradio==4">上限</span>
                                            <sy-input v-if="secradio==3||secradio==4" v-model="n.limitQuantity"
                                                      :sent="true" type="int" size="mini" :min="n.quantity*1"
                                                      :max="99999999"></sy-input>
                                            <span v-if="secradio==3||secradio==4">
                                                <span v-if="n.originGiftType == 1">{{n.giftUnit}}</span>
                                                <span v-else>
                                                  {{n.unitType==1?'箱':'瓶'}}
                                                </span>
                                            </span>
                                            <!--<span v-else>{{ n.giftUnit }}</span-->
                                            <!--新增 赠品类型-->
                                            <el-select v-model="n.giftType" v-if="!isCityPartner && n.originGiftType !== 1" class="unit_select2"
                                                       placeholder="请选择">
                                                <el-option v-for="a in giftTypeData" :key="a.giftType"
                                                           :label="a.giftTypeName" :value="a.giftType"></el-option>
                                            </el-select>
                                            <span>
                                                ￥{{ n.giftPrice }}/{{n.baseUnitName}}
                                            </span>
                                            <i @click="delOnegift(y,i,key)"
                                               class="el-icon-circle-close font-16 cursor ml-20"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-10 ml-10">
                                <el-button type="text" class="btn" @click="addmoneylist"
                                           v-if="secradio == '1'||secradio == '2'">继续添加
                                </el-button>
                            </div>
                        </div>
                    </div>
                </template>
            </el-form-item>
        </el-form>

        <!-- 限定次数 -->
        <el-form :model="form" label-width="98px" class="mt-20">
            <el-form-item label="参与次数:" required>
                <div class="">
                    <template>
                        <div class="">
                            <el-radio class="radio" v-model="isLimitTimes" label="0">不限次数</el-radio>
                            <el-radio class="radio" v-model="isLimitTimes" label="1">限制次数</el-radio>
                        </div>
                        <div class="mt-20 ml-90" v-if="isLimitTimes == '1'">
                            <span class='redalert ml-10'>*</span>
                            <sy-input v-model="limitTimes" type="int" size="mini" :min="1" :max="99999999"></sy-input>
                            <span>次</span>
                        </div>
                    </template>
                </div>
            </el-form-item>
        </el-form>

        <!-- 选择客户 -->
        <el-form :model="form" label-width="98px" class="mt-20">
            <el-form-item label="活动对象:" required>
                <div class="">
                    <template>
                        <el-radio class="radio" v-model="sevradio" label="1">所有客户</el-radio>
                        <el-radio class="radio" v-model="sevradio" label="2">部分客户</el-radio>
                    </template>
                    <div v-show="sevradio=='2'">
                        <div class='tar'>
                            <el-button type='text' @click="addclient">添加客户</el-button>
                            <el-button type='text' @click="addAllClients">添加全部客户</el-button>
                            <!-- <el-button type='text' @click="delclients">批量删除</el-button> -->
                        </div>
                        <tableA :size="[100,200,110,170]"></tableA>
                        <chooseClient
                                v-on:closedialog="dialog2Visible = false"
                                :cVisible="dialog2Visible"
                                :ifnotauto="true"
                                :is-full-gift='true'
                        >
                        </chooseClient>
                    </div>
                </div>
            </el-form-item>
            <!-- 选择商品-->
            <el-form-item label="活动商品:" required>
                <div class="">
                    <template>
                        <el-radio class="radio" v-model="thiradio" label="1">所有商品</el-radio>
                        <el-radio class="radio" v-model="thiradio" label="2">部分商品</el-radio>
                        <!-- <el-radio class="radio" v-model="thiradio" label="3">部分商品不参与</el-radio> -->
                    </template>
                    <div v-show="thiradio!='1'">
                        <div class='tar'>
                            <el-button type='text' @click="addGood">添加商品</el-button>
                            <el-button type='text' @click="addAllGoods">添加全部商品</el-button>
                        </div>
                        <tableB :size="[360,,205]" :noclassify="true"></tableB>
                        <chooseGoods
                                v-on:closedialog="dialog1Visible = false"
                                :goodVisible="dialog1Visible"
                                :ifnotauto="true"
                                :isMeal="true"
                                :is-full-gift='true'
                                :isIntegerMultiple="isIntegerMultiple"
                        >
                        </chooseGoods>
                    </div>
                </div>
            </el-form-item>
        </el-form>
        <el-form label-width="98px">
            <div class="mt-10">
                <el-form-item>
                    <el-button type="danger" @click="goodsSumit" :loading="loading">保存</el-button>
                    <el-button @click="back">取消</el-button>
                </el-form-item>
            </div>
        </el-form>
        <chooseGift
                v-on:closedialog="dialogVisible = false"
                :giftVisible="dialogVisible"
                :goodsNum="goodsNum"
                :isfullgift="true"
                :skuNoList="skuNoList"
        >
        </chooseGift>
    </div>
</template>

<script>
    import {mapMutations, mapActions} from 'vuex'
    import Confirm from '@/utils/formconfirm'
    import {activityname} from '@/utils/formverify'
    import {axiosApi, basePath} from '@/api/api'
    import YsDaterangePicker from "~/components/ys-daterange-picker";

    export default {
        components: {YsDaterangePicker},
        data() {
            var validatevalue1 = (rule, value, callback) => {
                if (value==null||!value[0] || !value[1]) {
                    return callback(new Error('请选择活动时间范围'));
                } else if (value[0] == value[1]) {
                    return callback(new Error('开始时间和结束时间应设置不同'));
                } else {
                    callback()
                }
            }
            return {
                cloneActivityRulesList:[],
                form: {
                    activityName: '',
                    value1: [],
                },
                giftTypeData:[],
                dialogVisible: false,
                dialog1Visible: false,
                dialog2Visible: false,
                limitTimes: "1",
                loading: false,
                isOvering: false,
                isNewUser: false,
                checkList: [],
                thiradio: '1',//1全部商品参与，2部分商品参与，3部分商品不参与
                secradio: '1',//1按订购金额，2按订购数量
                unitType: null,// 按箱商品，按瓶商品
                isIntegerMultiple: null,// 是否整箱购买
                sevradio: '1',//1全部客户参与，2部分客户参与
                isLimitTimes: '0',
                rules: {
                    activityName: [
                        {validator: activityname, trigger: 'blur'}
                    ],
                    value1: [
                        {validator: validatevalue1, trigger: 'change'}
                    ],
                },
                goodsNum: 5,//可选择赠品数量，默认为最大值5
                unitOptions: [{// 瓶箱下拉选择框
                    label: '箱',
                    value: 1
                }, {
                    label: '瓶',
                    value: 2
                }],
                skuNoList: [],//赠品sku列表
            }
        },
        watch: {
            //secradio(val) {
                // this.clearwilladd()// 清空商品
                // this.clearwilladdgiftEvent()// 清空赠品
                // this.unitType = null
                // if (val == 4 || val == 2) this.unitType = '1' //
                // if (this.$route.query.activitySource == 2) {
                //     this.isIntegerMultiple = null
                // }
            //},
            // sevradio(val) { // 切换全部，部分客户
            //     console.log('执行2')
            //     this.clearwilladd()// 清空商品
            // },
            // unitType(val) {
            //     console.log('执行3')
            //     this.clearwilladd()
            // },
            // activityClientlist(val) {
            //     console.log('执行4')
            //     this.clearwilladd()// 清空商品
            // }
        },
        computed: {
			isCityPartner() {
				return this.$store.state.clientType.isCityPartner;
			},
            postDataclient() {//已选择客户id
                return {
                    data: {
                        ids: this.$store.getters.willaddclientid,
                        // queryPage: 1, // 0-查询全部 1-分页查询
                        clientType: this.$route.query.activitySource || 1, // B2B和酒店
                    },
                    currentPage: 1
                }
            },
            postDatagood() {//已选择商品id
                if (this.secradio == 4 || this.secradio == 2) {//
                    if (this.$route.query.activitySource == 2) {
                        this.isIntegerMultiple = null;
                    } else {
                        this.isIntegerMultiple = this.unitType == '1'
                    }
                } else {
                    this.isIntegerMultiple = null
                }
                let buyerList = this.$store.state.clientlist.willadd || [];
                return {
                    data: {
                        notId: this.$store.getters.willaddid,
                        isIntegerMultiple: this.isIntegerMultiple,
                        buyerIds: buyerList.map(v => v.buyerId), // 添加 入参 buyerIds 选择客户的 buyerIds
                        activitySource: this.$route.query.activitySource || 1, // B2B和酒店
                        queryPage: 1, // 0-查询全部 1-分页查询
                        activityType: 1,// 活动类型 满赠送赠品 1  2-满减 3.限时限购 4.满赠(加价购)
                    },
                    currentPage: 1
                }
            },
            postData() {
                return {
                    data: {
                        goodsRelation: this.$store.state.ticket.willadd.map((a) => {
                            return {skuNo: a.skuNo}
                        }),
                        customerRelation: this.$store.state.clientlist.willadd.map((a) => {
                            return {buyerId: a.buyerId}
                        }),
                        activity: {
                            activitySource: this.$route.query.activitySource || 1, // 传值 1,2 区别酒店营销，B2B营销
                            activityName: this.form.activityName,
                            gmtStart: this.form.value1[0],
                            gmtEnd: this.form.value1[1],
                            isLimitTimes: this.isLimitTimes,//是否限定次数
                            limitTimes: this.limitTimes,//限定次数
                            rangeType: this.thiradio,
                            rangCustomerType: this.sevradio,
                            activityType: '1',//1满赠，2满减
                            sellerId: sessionStorage.getItem('traderId'),
                            // isOvering: this.isOvering ? '1' : '0',
                            isNewUser: this.isNewUser ? '1' : '0'
                        },
                        activityGiftRuleExLi: this.cloneActivityRulesList.map((a, b) => {/* 赠品list */
                            return {
                                fullMoney: this.secradio == 1 || this.secradio == 3 ? a.fullMoney : null,
                                fullAmount: this.secradio == 2 || this.secradio == 4 ? a.fullAmount : null,
                                type: this.secradio,
                                unitType: this.unitType,
                                activityGiftRelationLi: this.arrayTogiftList(a.giftList),
                            }
                        })
                    }
                }
            },
            activityRulesList() {// state 中获取 list
                console.log(this.$store.getters.getfullgiftList,'this.$store.getters.getfullgiftList')
                return this.$store.getters.getfullgiftList
            },
            activityClientlist() {// 客户列表
                return this.$store.state.clientlist.willadd
            },
            activitySource() {
                return (this.$route.query.activitySource ? this.$route.query.activitySource : this.$route.query.couponSource) || 1
            }
        },
        methods: {
            ...mapMutations({
                // 'clearwilladdgift': 'clearwilladdgift',
                'clearwilladdgiftEvent': 'clearwilladdgiftEvent',//清空赠品
                'deletegoods': 'deletegoods',
                // 'casewilladdgiftData': 'casewilladdgiftData',
                'casewilladdgiftDataEvent': 'casewilladdgiftDataEvent',////删除一种满赠规则
                'casewilladdgiftnum': 'casewilladdgiftnum',
                'deleteclients': 'deleteclients',
                'clearwilladd': 'clearwilladd',
                // 'addwilladdgift': 'addwilladdgift',
                'addwilladdgiftEvent': 'addwilladdgiftEvent', //添加活动规则
                // 'deleteOnewillGift': 'deleteOnewillGift'
                'deleteOnewillGiftEvent': 'deleteOnewillGiftEvent',// 删除单个赠品
                'deletecombinationEvent': 'deletecombinationEvent', //删除商品组合
                'initclient': 'initclient',
                'initgood': 'initgood',
                'initgiftmoneyEvent': 'initgiftmoneyEvent'
            }),
            ...mapActions({
                'querygiftlistData': 'querygiftlistData',
                'queryclienttableData': 'queryclienttableData',
                'querygiftlistDataEvent': 'querygiftlistDataEvent',
                'fullgiftSearchGoods': 'fullgiftSearchGoods',// 赠品组合添加商品
                'fullgiftSearchClient': 'fullgiftSearchClient',// 添加客户 ，查询客户
                'chooseAllGoodsAction': 'chooseAllGoodsAction',
                'chooseAllClientsAction': 'chooseAllClientsAction',
            }),
            getsentdetail() {
                const api = '/marketing/activityGift/Update/view'
                let body = {
                    activityId: this.$route.query.id
                }
                let newbody = Object.assign({}, body)
                axiosApi(api, newbody, (res) => {
                    const {
                        activityCustomerRelationPoList,
                        activityGoodsRelationPoList,
                        activityVo,
                        giftRuleExPos,
                        goodsSkuVos,
                        traderVos
                    } = res.data.data
                    var form = {
                        activityName: activityVo.activityName, // 活动名称
                        value1: [activityVo.gmtStart,activityVo.gmtEnd]
                    }
                    this.isNewUser = activityVo.isNewUser // 是否首单
                    
                    this.unitType = giftRuleExPos[0].unitType+''
                    var list = giftRuleExPos.map(item=>{
                        item.activityGiftRelationLi.forEach(items=>{
                            let arr = [
								{// 瓶箱下拉选择框
								    label: '箱(规格单位)',
								    value: 1
								}, {
								    label: items.giftUnit + '(基本单位)',
								    value: 2
								}
							]
                            items.giftType = items.k3GiftType
                            items.unitOptions = arr
                        })
                        item.fullMoney = item.fullMoney!=null?item.fullMoney:""
                        item.fullAmount = item.fullAmount!=null?item.fullAmount:""
                        item.giftList = this.giftToview(item.activityGiftRelationLi)
                        return item
                    })

                    this.initgiftmoneyEvent(list)
                    this.secradio = activityVo.type+'' // 活动规则  
                    this.isLimitTimes = activityVo.isLimitTimes+'' // 是否限定次数
                    this.limitTimes = activityVo.limitTimes // 限定次数
                    this.sevradio = activityVo.rangCustomerType+'' // 活动对象
                    this.thiradio = activityVo.rangeType+'' // 活动商品
                    this.form = form
                    //初始化客户列表
                    this.initclient(traderVos ? this.initList(traderVos) : [])
                    //初始化商品列表
                    this.initgood({willadd: goodsSkuVos ? goodsSkuVos : []})
                    // this.$store.dispatch('addclient', {willadd: traderVos})
                    // this.$store.dispatch("initgood", { willadd: goodsSkuVos })
                   
                }, 0, 10,err => {
                    this.$message.error(err.data.message)
                })
            },
            initList(list){
            // 剔除已删除客户
            var arr = list.filter(item=>{
                return item.buyerId
            })
            return arr
            },
            giftToview(arr = []) {// 后端数据 转化 前端显示结构
                let newArr = [...arr], viewArr = []
                newArr.forEach((a, b) => {
                    if (viewArr[a.groupSort - 1]) {
                        if (viewArr[a.groupSort - 1].subgiftList) {
                            viewArr[a.groupSort - 1].subgiftList.push(a)
                        } else {
                            viewArr[a.groupSort - 1].subgiftList = []
                            viewArr[a.groupSort - 1].subgiftList.push(a)
                        }
                    } else {
                        viewArr[a.groupSort - 1] = {}
                        viewArr[a.groupSort - 1].subgiftList = []
                        viewArr[a.groupSort - 1].subgiftList.push(a)
                    }
                })
                return viewArr
            },

            giftTypeList(){
                axiosApi('/marketing/giftType/list',{},res => {
                    this.giftTypeData = res.data.data;
                })
            },
            arrayTogiftList(arr = []) {//转化postData里数组结构用于传参
                arr.map((c, d) => {
                    c.subgiftList.forEach((e, k) => {
                        e.groupSort = d + 1
                    })
                    return c.subgiftList
                })
                let newArr = []
                arr.forEach((a, b) => {
                    newArr.push.apply(newArr, a.subgiftList)
                })
                return newArr
            },
            numTochinese(val) {// 数字转汉字
                if (!val && val != 0) return null;
                let arr = ["零", "一", "二", "三", "四", "五", "六", "七", "八", "九"];
                let a = '';
                arr.forEach((item, index) => {
                    if (val == index) {
                        a = item
                    }
                })
                return a
            },
            addactivitysent(data) {
                var that = this
                that.loading = true
                const api = '/marketing/activityGift/add'
                let body = {}
                let newbody = Object.assign({}, body, data.data);
                axiosApi(api, newbody, (res) => {
                    that.$notify({
                        type: 'success',
                        message: '添加满赠活动成功,即将跳转到活动列表页面'
                    })
                    setTimeout(function () {
                        that.loading = false
                        that.$router.push(basePath + '/activity/2')
                    }, 1000)
                }, 0, 10, (res) => {
                    that.loading = false
                    that.$notify({
                        type: 'warning',
                        message: '添加满赠活动失败,' + res.data.message
                    })
                })
            },
            delgoods() {//批量删除商品
                if (this.$store.state.ticket.willdelete.length != 0) {
                    this.$msgbox({
                        title: '提示信息',
                        message: '您确定要删除这些商品？',
                        showCancelButton: true,
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        beforeClose: (action, instance, done) => {
                            if (action === 'confirm') {
                                this.deletegoods()
                                done();
                            } else {
                                done();
                            }
                        }
                    })
                } else if (this.$store.state.ticket.willadd.length == 0) {
                    this.$alert('无商品可删除，请先添加商品', '', {
                        confirmButtonText: '确定',
                        callback: action => {

                        }
                    });
                } else {
                    this.$alert('请先勾选需要删除的商品', '', {
                        confirmButtonText: '确定',
                        callback: action => {

                        }
                    });
                }
            },
            deletemoneylist(y) {
                let h = this.$createElement;
                this.$msgbox({
                    title: '提示信息',
                    message: h('p', {
                        style: 'text-align:center'
                    }, '是否确定删除?'),
                    showCancelButton: true,
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    beforeClose: (action, instance, done) => {
                        if (action === 'confirm') {
                            this.casewilladdgiftDataEvent(y)
                            done()
                        } else {
                            done()
                        }
                        delete instance.$slots.default;
                    }
                })
            },
            deletecombination(index1, index2) { //删除一种赠品组合 index1 ->规则索引 index2->组合索引
                let h = this.$createElement;
                this.$msgbox({
                    title: '提示信息',
                    message: h('p', {
                        style: 'text-align:center'
                    }, '是否确定删除?'),
                    showCancelButton: true,
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    beforeClose: (action, instance, done) => {
                        if (action === 'confirm') {
                            setTimeout(() => {
                                this.deletecombinationEvent({
                                    index1: index1,
                                    index2: index2
                                })
                            }, 0)
                            done()
                        } else {
                            done()
                        }
                        delete instance.$slots.default;
                    }
                })
            },
            delOnegift(index1, index2, index3) {
                // this.dialogVisible = true;
                setTimeout(() => {
                    this.dialogVisible = true; //更新 state
                    this.dialogVisible = false;
                }, 0)
                this.deleteOnewillGiftEvent({
                    index1: index1,
                    index2: index2,
                    index3: index3
                })
            },
            deletenumlist(y) {
                this.casewilladdgiftnum(y)
                this.numlist.splice(y, 1)
            },
            delclients() {//批量删除客户
                if (this.$store.state.clientlist.willdelete.length != 0) {
                    this.$msgbox({
                        title: '提示信息',
                        message: '您确定要删除这些客户？',
                        showCancelButton: true,
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        beforeClose: (action, instance, done) => {
                            if (action === 'confirm') {
                                this.deleteclients()
                                done();
                            } else {
                                done();
                            }
                        }
                    })
                } else if (this.$store.state.clientlist.willadd.length == 0) {
                    this.$alert('无客户可删除，请先添加客户', '', {
                        confirmButtonText: '确定',
                        callback: action => {

                        }
                    });
                } else {
                    this.$alert('请先勾选需要删除的客户', '', {
                        confirmButtonText: '确定',
                        callback: action => {

                        }
                    });
                }
            },
            addmoneylist() {
                this.addwilladdgiftEvent()
            },
            addGood() {//添加活动商品
                this.dialog1Visible = true
                this.fullgiftSearchGoods(this.postDatagood)
            },
            addAllGoods() { // 添加全部商品
                this.$msgbox({
                    title: '提示信息',
                    message: '是否选择全部商品？',
                    showCancelButton: true,
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    beforeClose: (action, instance, done) => {
                        if (action === 'confirm') {
                            instance.confirmButtonLoading = true;
                            instance.confirmButtonText = '执行中...';
                            let currentPath = this.$router.currentRoute.path || '';
                            this.chooseAllGoodsAction({
                                'isIntegerMultiple': this.isIntegerMultiple,
                                'instance': instance,
                                'done': done,
                                'currentPath': currentPath,
                                'activitySource': this.activitySource
                            });
                        } else {
                            done();
                        }
                    }
                })
            },
            addclient() {//添加客户 用老接口
                this.dialog2Visible = true
                this.queryclienttableData(this.postDataclient)
                // this.fullgiftSearchClient(this.postDataclient)
            },
            addAllClients() {
                this.$msgbox({
                    title: '提示',
                    message: '是否选择全部客户？',
                    showCancelButton: true,
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    beforeClose: (action, instance, done) => {
                        if (action === 'confirm') {
                            instance.confirmButtonLoading = true;
                            instance.confirmButtonText = '执行中...';
                            let postData = {
                                data: {sellerId: sessionStorage.traderId},
                                currentPage: 1
                            }
                            this.chooseAllClientsAction({
                                'instance': instance,
                                'done': done,
                                'postData': postData,
                                'clientType': this.activitySource
                            });
                        } else {
                            done();
                        }
                    }
                })
            },
            addgiftcombination(index, item) { // 添加赠品组合     index 规则索引 -- item 规则对象
                let num = 0
                if (!item.giftList) num = 0;
                if (item.giftList) num = item.giftList.length;
                if (num >= 5) {
                    this.$message.info('每条规则最多有5种赠品组合');
                    return false
                } else {
                    this.dialogVisible = true;  //chooseGift 赠品弹框 open
                    this.goodsNum = 5;
                    let skuNoList = [], giftList = this.activityRulesList[index].giftList, subIndex = 0;
                    if (item.giftList) {// 存在
                        subIndex = item.giftList.length
                    }
                    this.skuNoList = skuNoList // props 传值 chooseGift
                    this.querygiftlistDataEvent(Object.assign({data: {}}, {
                        'chooseIndex': index,
                        'subIndex': subIndex,
                        data: {skuNoList: []}
                    }))
                }
            },
            addgift(index, item, index2, item2) {// 添加赠品 index 规则索引 -- item 规则对象 index2-> 组合内的赠品索引
                let num = 0
                if (!item2.subgiftList) num = 0;
                if (item2.subgiftList) num = item2.subgiftList.length;
                if (num >= 5) {
                    this.$message.info('每种组合最多包含5种赠品');
                    return false
                } else {
                    this.dialogVisible = true;  //chooseGift 赠品弹框 open
                    this.goodsNum = 5 - num;
                    let skuNoList = [], subIndex = 0;
                    if (item.giftList) {// 存在
                        subIndex = index2;
                        skuNoList = item2.subgiftList.map(n => { // 获取赠品列表的 skuno
                            return n.skuNo
                        })
                        this.skuNoList = skuNoList // props 传值 chooseGift
                    }
                    this.querygiftlistDataEvent(Object.assign({data: {}}, {
                        'chooseIndex': index,
                        'subIndex': subIndex,
                        data: {skuNoList: skuNoList}
                    }))
                }
            },
            back() {
                this.$router.push(basePath + '/activity/2')
            },
            filterTypeData(type){
                let str = ''
                this.giftTypeData.map(v => {
                    if(v.giftType == type){
                        str = v.giftTypeName
                    }
                })
                return str
            },
            listVerify(val){
                var list = JSON.parse(JSON.stringify(val));
                list.forEach((item,index)=>{
                    item.giftList.forEach(itemA=>{
                        var itemAtotalPrice = 0
                        itemA.subgiftList.forEach(itemB=>{
                            if(itemB.unitType==1){
                              itemB.itemBtotalPrice = itemB.specInfoNum*Number(itemB.quantity)*itemB.giftPrice
                            }else{
                              itemB.itemBtotalPrice = Number(itemB.quantity)*itemB.giftPrice
                            }
                            itemAtotalPrice += itemB.itemBtotalPrice
                        })
                        itemA.itemAtotalPrice = itemAtotalPrice
                    })
                    // 单个组合是否超过起送金额
                    item.isExceed = (item.giftList.some((itemSoem => (item.fullMoney < itemSoem.itemAtotalPrice))))
                })
                return list.some(item=>item.isExceed)
            },
            goodsSumit(){
                if(this.secradio == 1 || this.secradio == 3){
                    var cloneActivityRulesList = JSON.parse(JSON.stringify(this.activityRulesList));
                    if(this.listVerify(cloneActivityRulesList)){
                        this.$confirm('存在赠品价值超过起送金额的组合!, 是否继续?', '提示', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            type: 'warning'
                        }).then(() => {
                            this.onSubmit()
                        }).catch(() => {
                            this.$message({
                                type: 'info',
                                message: '已取消'
                            });          
                        });
                    }else{
                        this.onSubmit()
                    }
                }else{
                    this.onSubmit()
                } 
            },
            onSubmit() {
                let that = this;
                this.cloneActivityRulesList = JSON.parse(JSON.stringify(this.activityRulesList));
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        let newconfirm = new Confirm();
                        let confirmdata = {
                            goodsRelation: that.$store.state.ticket.willadd.map((a) => {
                                return {skuNo: a.skuNo}
                            }),
                            customerRelation: that.$store.state.clientlist.willadd.map((a) => {
                                return {buyerId: a.buyerId}
                            }),
                            activity: {
                                activitySource: that.$route.query.activitySource || 1, // 传值 1,2 区别酒店营销，B2B营销
                                activityName: that.form.activityName,
                                gmtStart: that.form.value1[0],
                                gmtEnd: that.form.value1[1],
                                isLimitTimes: that.isLimitTimes,//是否限定次数
                                limitTimes: that.limitTimes,//限定次数
                                rangeType: that.thiradio,
                                rangCustomerType: that.sevradio,
                                activityType: '1',//1满赠，2满减
                                sellerId: sessionStorage.getItem('traderId'),
                                // isOvering: this.isOvering ? '1' : '0',
                                isNewUser: that.isNewUser ? '1' : '0'
                            },

                            activityGiftRuleExLi: that.cloneActivityRulesList.map((a, b) => {/* 赠品list */
                                a.giftList.map(v => {
                                    v.subgiftList.map(n => {
                                        n.k3GiftType = n.giftType;
                                        n.k3GiftTypeName = that.filterTypeData(n.giftType);
                                        n.giftType = n.originGiftType;
                                        delete n.originGiftType;
                                    })
                                });
                                return {
                                    fullMoney: that.secradio == 1 || that.secradio == 3 ? a.fullMoney : null,
                                    fullAmount: that.secradio == 2 || that.secradio == 4 ? a.fullAmount : null,
                                    type: that.secradio,
                                    unitType: that.unitType,
                                    activityGiftRelationLi: that.arrayTogiftList(a.giftList),
                                }
                            })
                        };
                        // let confirmdata  = Object.assign({}, this.postData.data)
                        newconfirm.use([confirmdata.activity.activityName], '活动名称为空！')
                        newconfirm.use([confirmdata.activity.gmtStart], '活动时间为空！')
                        newconfirm.use([confirmdata.activity.gmtEnd], '活动时间为空！')
                        newconfirm.use([confirmdata.activity.isLimitTimes, '1', confirmdata.activity.limitTimes], '限制次数为空！')
                        newconfirm.use([confirmdata.activity.rangCustomerType, '2', confirmdata.customerRelation], '未添加参与活动客户！')
                        newconfirm.use([confirmdata.activity.rangeType, '2', confirmdata.goodsRelation], '未添加活动商品！')
                        // newconfirm.cs('dot', confirmdata.fullMoney, '订购金额')
                        // newconfirm.cs('int', confirmdata.quantity, '赠品数量')
                        if (confirmdata.activity.activityName.length > 64) {
                            newconfirm.status = false
                            this.$message({
                                type: 'warning',
                                message: '活动名称最大字符64位，请修改！'
                            })
                        }
                        //    if(confirmdata.activity.gmtStart < new Date().getTime()) {
                        //      newconfirm.status = false
                        //      this.$message({
                        //        type: 'warning',
                        //        message: '活动开始时间应在当前时间之后，请修改！'
                        //      })
                        //    }
                        let flag = true;
                        that.cloneActivityRulesList.map(v => {
                            v.giftList.map((x, y) => {
                                if (x.subgiftList.length == 0) {
                                    let n;
                                    switch (y) {
                                        case 0 : {
                                            n = '一';
                                            break
                                        }
                                        case 1 : {
                                            n = '二';
                                            break
                                        }
                                        case 2 : {
                                            n = '三';
                                            break
                                        }
                                        case 3 : {
                                            n = '四';
                                            break
                                        }
                                        case 4 : {
                                            n = '五';
                                            break
                                        }
                                    }
                                    flag = false;
                                    this.$message({
                                        type: 'warning',
                                        message: `赠品组合${n}未添加赠品`
                                    })
                                }
                            })
                        });
                        if (!flag) {
                            return
                        }

                        confirmdata.activityGiftRuleExLi.forEach((a, b) => {
                            /*{
                                  fullAmount: a.fullNum,
                                  type: 2,
                                  activityGiftRelationLi:
                                }*/
                            if (this.secradio == '1') {// 订购金额
                                newconfirm.use([a.fullMoney], '订购金额为空！')
                                newconfirm.cs('dot', a.fullMoney, '订购金额')
                            } else if (this.secradio == '2') {// 订购数量
                                newconfirm.use([a.fullAmount], '订购数量为空！')
                                newconfirm.cs('int', a.fullAmount, '订购数量')
                            } else if (this.secradio == '3') {// 每满额送
                                newconfirm.use([a.fullMoney], '满额金额为空！')
                                newconfirm.cs('dot', a.fullMoney, '满额金额')
                            } else if (this.secradio == '4') {// 每满件送
                                newconfirm.use([a.fullAmount], '满件数量为空！')
                                newconfirm.cs('int', a.fullAmount, '满件数量')
                            }
                            // a.activityGiftRelationLi.forEach
                            let m = a.activityGiftRelationLi;//
                            newconfirm.use([m[0]], '赠品组合为空！')
                            for (let i = 0; i < m.length; i++) {
                                // console.log('67890987656789876789',m)
                                newconfirm.use([m[i].quantity], '赠品数量为空！')
                                newconfirm.cs('int', m[i].quantity, '赠品数量')
                                if (!newconfirm.status) return
                            }
                        })

                        var full = confirmdata.activityGiftRuleExLi.map((a, b) => {
                            return (this.secradio == '1' || this.secradio == '3') ? a.fullMoney : a.fullAmount
                        })
                        // console.log(full)
                        var len1 = full.length
                        var fullset = full.unique();//数组去重
                        var len2 = fullset.length;
                        // var fullset = new Set(full)//数组去重
                        // var len2 = fullset.size
                        if (len1 != len2) {
                            newconfirm.status = false
                            this.$message({
                                type: 'warning',
                                message: '存在相同订购金额的活动规则！'
                            })
                        }
                        if (newconfirm.status) {
                            this.addactivitysent(this.postData)
                        } else {
                            return false
                        }
                    } else {
                        return false;
                    }
                });

            }
        },
        mounted() {
            this.$nextTick(() => {
                this.clearwilladdgiftEvent();
                this.giftTypeList()
                if(this.$route.query.id){
                    this.getsentdetail()
                }
            })
        }
    }
</script>

<style lang="less">
    .btn {
        text-align: left
    }

    .add-gift-sent {
        .el-date-editor .el-range-separator {
        line-height: 24px!important;
        }
        .el-date-editor--datetime .el-icon-time, .el-input__icon {
            line-height: 24px!important;
        }   
    .el-dialog {
            width: 950px!important;
            padding: 0 30px;
            /*height: 725px;*/
        }
        width: 1150px;

        .cursor {
            color: #999;

            &:hover {
                color: #ff4201;
            }
        }


        .unit_select {
            width: 120px;

            .el-input {
                width: 120px;
            }
        }
        .unit_select2 {
            width: 120px;

            .el-input {
                width: 120px;
            }
        }
    }
</style>
