<template>
	<div class="step1">
		<div class="mb-50">
			<!-- <img class="sub mr-10" src="../../assets/register.png" /> -->
			<i class="iconfont icon-inspector color-b font-18 mr-10"></i>
			<span class="color-6 font-16">1/3验证身份</span>
		</div>
		<el-form :model="form" :rules="rules" ref="form" label-width='140px'>
			<el-form-item label='手机号:' prop="phone">
				<el-input :disabled="true" v-model="form.phone" :maxlength="11" @change="checkMobileNewAvailable"></el-input>
				<p v-if="exist" class="color-danger lh-12 mt-5">系统不存在的用户</p>
			</el-form-item>
			<el-form-item label="短信验证码:" prop="code">
				<el-input class="w105 mr-20" :maxlength="6" v-model.trim="form.code" @change="isCode=false"></el-input>
				<el-button class="w115" @click="sending" :disabled="codeDisabled">
					<span v-if="codeBool">获取验证码<span v-show="timeBool">{{time | seconds}}</span></span>
					<span v-else>获取验证码</span>
				</el-button>
				<p v-if="isCode" class="color-danger lh-12 mt-5">{{cmessage}}</p>
			</el-form-item>
			<el-form-item class="mt-50" label="">
				<el-button type="danger" @click="goPage('form')">下一步</el-button>
			</el-form-item>
		</el-form>
	</div>
</template>

<script>
    import { MessageBox } from 'element-ui'
	import { axiosApi, basePath } from "../../../api/api"
	import md5 from "md5"
	export default {
		data() {
			let phone = sessionStorage.getItem('mobile');
			return {
				exist: false, //手机号错误信息是否显示
				cmessage: "", //验证码错误信息
				isCode: false, //验证码错误信息时候显示
				codeDisabled: false, //发送验证码按钮是否禁用
				form: {
					phone: phone.substr(0,3)+"****"+phone.substr(7),
					code: "",
				},
				defaultPhone: phone,
				rules: {
					phone: [{
						required: true,
						message: "请输入正确的手机号",
					}],
					code: [{
						required: true,
						message: '请输入验证码',
						trigger: 'blur'
					}, {
						min: 6,
						max: 6,
						message: '请输入6位验证码',
						trigger: 'blur'
					}]
				},
				time: "59",
				timeBool: false,
				codeBool: true,
				clearTime: -1
			}
		},
		filters: {
			seconds(value) {
				return "(" + value + "s)";
			}
		},
		methods: {
			checkMobileNewAvailable() { //判断该手机号是否是绑定的手机号
				this.exist = false;////清除手机错误信息
				this.codeDisabled = true;
				if(this.form.phone.length == 11) {
					axiosApi("/unlogin/employee/checkMobileNewAvailable", {
						mobile: this.defaultPhone,
						loginName: this.defaultPhone,
						appId: 2
					}, (res) => {
						if(res.data.data) {
							this.exist = false;
							this.codeDisabled = false;
						} else {
							this.exist = true;
						}
					})
				}
			},
			sending() { //发送验证码
				if(true) {
					let _this = this;
					this.codeDisabled = true;
					if(!this.codeBool) {
						clearTimeout(this.clearTime);
						this.codeBool = true;
						this.time = "59"
					}
					this.timeBool = true;
					this.clearTime = setInterval(function() {
						_this.time--;
						if(_this.time == 0) {
							_this.codeBool = false;
							_this.codeDisabled = false;
						}
					}, 1000)
					const api2 = "/sms/sendSingle"
					const body2 = {
						mobile: this.defaultPhone,
						smsTemplate: "3",
						smsType: "sms0003",
						appId: 2,
						smsPurposeType:1
					}
					axiosApi(api2, body2, () => {}, 1, 10);
				}
			},
			goPage(form) {
				this.$refs[form].validate((valid) => {
					if(valid && !this.exist) {
						const api = "/unlogin/sms/varify"
						const body = {
							mobile: this.defaultPhone,
							messageCode: this.form.code,
							smsType: "sms0003",
							appId: 2
						}
						axiosApi(api, body, (res) => {
							if(res.data.data) {
								sessionStorage.mobile = this.defaultPhone;
								this.$router.push(basePath + "/register/changePassSecond");
							}
						}, 1, 10, (res) => {
							this.isCode = true;
							this.cmessage = res.data.message;
						})
					} else {
						// console.log('error submit!!');
						return false;
					}
				});
			},

        },
	}
</script>
<style lang="less">
.step1 {
	background:white;
	padding:20px 0 1px 20px;
	box-sizing: border-box;
}
</style>