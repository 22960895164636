<template>
	<div class="" v-loading="pictureLoading">
		<div class="add-store  bg-f pb-50">
			<el-form :model="form" :rules="rules" ref="form" label-width='270px'>
				<p class="form-label">基本信息</p>
				<!-- <div v-if="isAdd"> -->
				<el-form-item v-if="!isAdd" label="客户编号:">
					<span>{{ form.traderNumber }}</span>
				</el-form-item>
				<el-form-item label="联系方式:" prop="mobile">
					<el-input v-model.trim="form.mobile" :maxlength="11" placeholder="输入手机号" disabled></el-input>
				</el-form-item>
				<el-form-item label="联系人姓名:" prop="linkname">
					<el-input v-model.trim="form.linkname" placeholder="输入姓名" :disabled="!isAdd"></el-input>
				</el-form-item>
				<el-form-item label="职位:" prop="jobOffer">
					<el-input v-model.trim="form.jobOffer" placeholder="输入职位" :disabled="!isAdd"></el-input>
				</el-form-item>
				<el-form-item label="邮箱:" prop="email">
					<el-input v-model.trim="form.email" placeholder="输入邮箱" :disabled="!isAdd"></el-input>
				</el-form-item>
				<!-- </div> -->
				<el-form-item label="客户来源:" prop="email">
					<span>{{form.csource != 1 ? '录入' : '注册'}}</span>
				</el-form-item>
				<el-form-item label="客户名称:" prop="storeName">
					<el-input v-model.trim="form.storeName" placeholder="请输入内容" :disabled="!isAdd"></el-input>
					<span class="color-9 font-12 ml-5">与营业执照保持一致</span>
				</el-form-item>
				<!--<el-form-item label="客户类型:" prop="ctype">
            		<el-radio class="radio" v-model="form.ctype" :label="2" :disabled="ctypeDisabled">终端</el-radio>
					<el-radio class="radio" v-model="form.ctype" :label="1" :disabled="ctypeDisabled">经销商</el-radio>
					<el-radio class="radio" v-model="form.ctype" :label="3" :disabled="ctypeDisabled">酒店</el-radio>
				</el-form-item>
				<el-form-item label='终端渠道:' prop="cchannel" v-if="clientConfigData.channel !== undefined" :class="{ 'is-required': clientConfigData.channel === 1 }" :required="clientConfigData.channel === 1">
					<div v-if="form.ctype == 2">
						<el-cascader :options="clientChannelList" :props="channelProp" clearable change-on-select v-model="form.cchannel"></el-cascader>
						<span class="color-blue cursor ml-5 font-12" @click="addChannel = true;title = '新增客户渠道'">新增渠道</span>
					</div>
					<span v-else>无</span>
				</el-form-item>-->
				<el-form-item label='客户等级:' prop="clevel" v-if="clientConfigData.level !== undefined" :class="{ 'is-required': clientConfigData.level === 1 }" :required="clientConfigData.level === 1">
					<el-select v-model="form.clevel" clearable>
						<el-option v-for="item in clientLevelList" :key="item.id" placeholder="选择客户等级" :label="item.levelName" :value="item.id" ></el-option>
					</el-select>
					<span class="color-blue cursor ml-5 font-12" @click="addLevel = true;title = '新增客户等级'">新增客户等级</span>
				</el-form-item>
				<el-form-item label='所属分组:' prop="groupId">
					<TreeGroup v-model="form.groupId"/>
					<!-- <router-link :to="`${basePath}/addAndEditGrouping?type=add&e=${ form.mobile }`">
						<span class="color-blue cursor ml-5 font-12">新增分组</span>
					</router-link> -->
				</el-form-item>
				<el-form-item label='所属区域:' prop="districtIds" v-if="clientConfigData.district !== undefined" :class="{ 'is-required': clientConfigData.district === 1 }" :required="clientConfigData.district === 1">
					<el-cascader :props="districtProps" :options="districtList" clearable change-on-select placeholder="选择区域" v-model="form.districtIds"></el-cascader>
					<router-link :to="`${basePath}/addAndEditRegion?type=add&e=${ form.mobile }`">
						<span class="color-blue cursor ml-5 font-12">新增区域</span>
					</router-link>
				</el-form-item>
				<!--<el-form-item v-if="!isCityPartner" label="推荐经销商:"  class="mb-30">-->
					<!--<el-select v-model='form.bindingDealerTraderId'  filterable clearable  placeholder="请选择经销商">-->
						<!--<el-option :label="item.storeName" :value="item.buyerId" v-for='item in agencyList' :key='item.buyerId'></el-option>-->
					<!--</el-select>-->
				<!--</el-form-item>-->
				<el-form-item label="关联业务员:" prop="salesman">
					<el-input v-model.trim="form.salesman" placeholder="选择关联业务员" disabled></el-input>
					<span class="color-blue cursor ml-5 font-12" @click="saledialog = true">添加业务员</span>
				</el-form-item>

				<p class="parting-line"></p>

				<el-form-item label="营业执照编号:" prop="licenseNum">
					<el-input v-model.trim="form.licenseNum" placeholder="输入营业执照编码" :disabled="!isAdd"></el-input>
				</el-form-item>
				<el-form-item label="营业执照照片:" prop="dialogImageUrl">
					<p class="color-9 lh-14 p10">图片仅支持jpg,jpeg,png,bmp格式,文件小于4M</p>
					<div v-if="isAdd" :class="{noAdd:!isUpload,add:isUpload}">
						<el-upload action="/sunrise-gateway/oss/ossUpload" accept=".jpg,.jpeg,.png,.bmp" :file-list="licenseImgList" list-type="picture-card" :on-preview="handlePictureCardPreview" :on-success="handleAvatarScucess" :before-upload="beforeAvatarUpload" :on-remove="handleRa" :on-error="errFun">
							<i class="el-icon-plus"></i>
						</el-upload>
						<el-dialog :visible.sync="dialogVisible" size="tiny">
							<img width="100%" :src="dialogImageUrl" alt="show-message">
						</el-dialog>
					</div>
					<div v-else class="noDel" :class="{noAdd:!isUpload,add:isUpload}">
						<el-upload action="/sunrise-gateway/oss/ossUpload" accept=".jpg,.jpeg,.png,.bmp" :file-list="licenseImgList" list-type="picture-card" :on-preview="handlePictureCardPreview">
							<i class="el-icon-plus"></i>
						</el-upload>
						<el-dialog :visible.sync="dialogVisible" size="tiny">
							<img width="100%" :src="dialogImageUrl" alt="show-message">
						</el-dialog>
					</div>
				</el-form-item>
				<el-form-item label="门店照片:" v-if="clientConfigData.shopsign !== undefined">
					<span v-if="clientConfigData.shopsign === 1" class="color-danger photo-url">*</span>
					<p class="color-9 lh-14  p10">图片仅支持jpg,jpeg,png,bmp格式,文件小于4M</p>
					<el-form-item prop="photoUrlList" :class="{ 'is-required': clientConfigData.shopsign === 1 }" :required="clientConfigData.shopsign === 1">
						<div v-if="isAdd || (clientConfigData.shopsign !== undefined && form.photoUrl.length < 1)" :class="{noAdd: !isUpload1}">
							<el-upload action="/sunrise-gateway/oss/ossUpload" accept=".jpg,.jpeg,.png,.bmp" :file-list="form.photoUrlList" list-type="picture-card" :on-preview="handlePictureCardPreview" :on-success="handleAvatarSuccessList" :on-remove="handleRemove" :before-upload="beforeAvatarUpload" :on-error="errFun">
								<i class="el-icon-plus"></i>
							</el-upload>
						</div>
						<div v-else class="noDel" :class="{noAdd:!isUpload1,add:isUpload1}">
							<el-upload action="/sunrise-gateway/oss/ossUpload" accept=".jpg,.jpeg,.png,.bmp" :file-list="form.photoUrlList" list-type="picture-card" :on-preview="handlePictureCardPreview">
								<i class="el-icon-plus"></i>
							</el-upload>
						</div>
					</el-form-item>
				</el-form-item>
                <el-form-item class="operating-area" label="店铺面积:" prop="operatingArea" v-if="clientConfigData.shopsign !== undefined">
                    <el-input v-model.trim="form.operatingArea" size="small" placeholder="输入店铺面积" :disabled="!isAdd"></el-input>
                    <span>平方米</span>
                </el-form-item>
				<el-form-item label='商户地址:' required>
					<div class="clearfix">
						<cityCascaderBox v-model='form.businessAddress' :num="3" :disabled="!isAdd" :showAllLevels="true" :checkStrictly="false" :emitPath="true" @changeName="changeName"/>
					</div>
					<el-form-item prop="addr" class="mb-10">
						<el-input v-model.trim="form.addr" class="db mt-10" placeholder="输入详细地址" :disabled="!isAdd"></el-input>
					</el-form-item>
				</el-form-item>
				<el-form-item label='销售品牌:' prop="brandTabData" v-if="clientConfigData.brand !== undefined" :class="{ 'is-required': clientConfigData.brand === 1 }" :required="clientConfigData.brand === 1">
					<el-button class="ml-20" @click="addBrandClick">新增品牌</el-button>
					<span class="font-12 color-danger ml-10">温馨提示：列表中红色背景的品牌表示已删除，您也可以删除它并保存！</span>
					<brandTable class="table-700" :rules="true"></brandTable>
				</el-form-item>
				<el-form-item label="备注:">
					<el-input type="textarea" v-model.trim="form.remarks" placeholder="输入备注..."></el-input>
				</el-form-item>
				<el-form-item class='mt-50 mb-0'>
					<el-button type='danger' @click="submit">提交</el-button>
				</el-form-item>
			</el-form>
			<!--新增客户渠道弹窗-->
			<clientChannel v-model="addChannel" :title="title" v-on:closedialog="addChannel = false"></clientChannel>
			<!--新增客户等级弹窗-->
			<clientLevel v-model="addLevel" :title="title" v-on:closedialog="addLevel = false"></clientLevel>
			<!-- 添加更换业务员 -->
			<changeSalesman v-on:closedialog="saledialog = false" :saleVisible="saledialog" :title="'添加业务员'"></changeSalesman>
			<!-- 新增品牌弹窗 -->
			<addBrand ref="addBrand"></addBrand>
		</div>
	</div>
</template>

<script>
	import { axiosApi, basePath } from "../../api/api"
	import { mapActions, mapMutations } from 'vuex'
	import TreeGroup from '~/components/treeGroup'
	export default {
		components:{
			TreeGroup
		},
		data() {
			var cchannel = (rule, value, callback) => {
				if(this.form.ctype == 2 && value.length === 0) {
					callback(new Error('请选择终端渠道'));
				} else {
					callback();
				}
			}
			var clevel = (rule, value, callback) => {
				if(this.clientConfigData.level === 1 && (!value || value == "")) {
					callback(new Error('请选择客户等级'));
				} else {
					callback();
				}
			}
			var districtIds = (rule, value, callback) => {
				if(value.length === 0 && this.clientConfigData.district === 1) {
					callback(new Error('请选择所属区域'));
				} else {
					callback();
				}
			}
			var photoUrlList = (rule, value, callback) => {
				if(value.length === 0 && this.clientConfigData.shopsign === 1) {
					callback(new Error('请上传门头照片'));
				} else {
					callback();
				}
			}
			var brandTabData = (rule, value, callback) => {
				if(this.brandTabData.length === 0 && this.clientConfigData.brand === 1) {
					callback(new Error('请选择销售品牌'));
				} else {
					callback();
				}
			}
			var streetId = (rule, value, callback) => {
				if(this.form.businessAddress.length > 0 && this.fourLists.length > 0 && (!value || value.length == 0)) {
					callback(new Error('请选择商户地址的四级地址'));
				} else {
					callback();
				}
			}
			var deliveryStreetId = (rule, value, callback) => {
				if(this.form.deliveryAddress.length > 0 && this.fourListsDelivey.length > 0 && (!value || value.length == 0)) {
					callback(new Error('请选择仓库地址的四级地址'));
				} else {
					callback();
				}
			}
			return {
				agencyList:[],//经销商
				basePath: basePath,
				title: null,
				isUpload: true,
				isUpload1: true,
				addChannel: false, //新增渠道弹窗
				addLevel: false, //新增等级弹窗
				saledialog: false, //添加业务员弹窗
				isAdd: false, //当前用户id
				pictureLoading: false, //上传图片loading，或者点击下一步页面加载跳转
				traderPhotoRelationParams: [], //审核门店照片信息
				isAdd: false, //门店添加的图片是否要有，此版本门店照片1到2张
				isAdd1: false, //营业执照添加的照片是否要有，一张
				params: false,
				checked: false,
				//cchannel: [],//客户渠道
				form: {
					bindingDealerTraderId:'',//经销商
					deliveryStreetId:'',
					streetId: '',
					deliveryStreetName:'',
					streetName:"",
					id: null,
					traderNumber: null,
					sellerId: sessionStorage.getItem('traderId'),
					mobile: null, //手机号
					linkname: null, //联系人
					email: null,
					jobOffer: null, //职位
					csource: 2, //客户来源  1：注册 2：录入
					storeName: null, //商户名称
					ctype: 2, //客户类型 1：经销商 2：终端
					cchannel1: null, //一级客户渠道
					cchannel2: null, //二级客户渠道
					clevel: null, //客户等级
					groupId: null, //所属分组id
					saleId: null, //关联业务员id
					licenseNum: null, //营业执照编号
					licenseImg: null, //营业执照照片
					photoUrl: [], //门头照片
					photoUrlList: [], //门头照照片
					operatingArea: null, //店铺面积
					provinceId: null, //商户地址省id
					cityId: null, //市id
					regionId: null, //区id
					provinceName: null, //商户地址省名称
					cityName: null, //市名称
					regionName: null, //区名称
					addr: null, //详细地址
					deliveryProvinceId: null, //收货地址省id
					deliveryCityId: null, //
					deliveryRegionId: null, //
					deliveryProvinceName: null, //
					deliveryCityName: null, //
					deliveryRegionName: null, //
					deliveryAttr: null, //
					remarks: null, //备注
					buyerId: null, //
					salesman: null, //业务员名称
					businessAddress: [], //营业地址
					deliveryAddress: [], //收货地址
					cchannel: [], //客户渠道
					districtId: null, //所属区域ID
					districtIds: []
				},
				rules: {
					mobile: [{
						required: true,
						pattern: /^1\d{10}$/,
						message: '请输入正确的手机号'
					}],
					linkname: [{
						required: true,
						pattern: /^([A-Za-z]|[\u4E00-\u9FA5]){2,6}$/,
						message: '请输入2-6个字符，可为汉字或字母'
					}],
					jobOffer: [{
						pattern: /^([A-Za-z0-9]|[\u4E00-\u9FA5]){2,10}$/,
						min: 2,
						max: 10,
						message: '请输入2-10个字符,可以为汉字字母和数字',
					}],
					email: [{
						pattern: /^(?=\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$).{0,20}$/,
						message: '请输入正确的邮箱格式,且长度不能大于20',
					}],
					storeName: [{
						required: true,
						message: '请输入商户名称',
					}, {
						min: 2,
						max: 40,
						message: '请输入2-40个字符',
					}],
					cchannel: [{
						validator: cchannel,
						trigger: 'change'
					}],
					clevel: [{
						validator: clevel,
						trigger: 'change'
					}],
					districtIds: [{
						validator: districtIds,
						trigger: 'change'
					}],
					licenseNum: [{
						pattern: /^[0-9a-zA-Z]{10,20}$/,
						message: '请输入10-20个字符，可为字母数字'
					}],
					photoUrlList: [{
						validator: photoUrlList
					}],
					operatingArea: [{
						pattern: /^\d{0,4}|(\d{1,4}\.\d{0,2})$/,
						message: '输入数字，可以是4位以内的整数，小数点后最多两位'
					}],
					businessAddress: [{
						required: true,
						type: "array",
						message: '请选择省市区',
					}],
					addr: [{
						required: true,
						message: '请输入详细地址',
					}, {
						min: 4,
						max: 50,
						message: '请输入4-50个字符',
					}],
					deliveryAddress: [{
						type: "array",
						message: '请选择省市区',
					}],
					deliveryAttr: [{
						min: 4,
						max: 50,
						message: '请输入4-50个字符',
					}],
					operatingArea: [{
						pattern: /^\d{0,6}$/,
						message: '请输入0~6为的正整数'
					}],
					brandTabData: [{
						validator: brandTabData
					}],
					streetId: [{
						validator: streetId
					}],
					deliveryStreetId: [{
						validator: deliveryStreetId
					}]
				},
				dialogVisible: false, //查看上传照片
				dialogImageUrl: null, //只做门店照片的查看用

				licenseImgList: [], //营业执照照片
				//salesman: null,//业务员
				channelProp: {
					label: 'classifyName',
					value: 'id',
					children: 'sub'
				},
				districtList: [],
				districtProps: {
					label: 'districtName',
					value: 'id',
					children: 'subDistrictList'
				},
				// photos: [],//门头照片
				ctypeDisabled: false,
				fourLists: [], //四级地址列表
				fourListsDelivey:[],//仓库地址四级地址
				areaNameList: []
			}
		},
		watch: {
			linkName(item) {
				//console.log(item)
				this.form.salesman = item ? item.employeeName : null;
				this.form.saleId = item ? item.managerId : null;
			},
			checked(val) {
				if(val) {
					this.form.deliveryAttr = this.form.attr; //
					this.form.deliveryAddress = this.form.businessAddress
					this.fourListsDelivey = this.fourLists
					this.form.deliveryStreetId = this.form.streetId
				}
			},
			ctype(val) {
				if(val == 1) {
					this.form.cchannel1 = null, //一级客户渠道
						this.form.cchannel2 = null, //二级客户渠道
						this.form.cchannel = [];
				}
			},
		},
		computed: {
			isCityPartner() {
				return this.$store.state.clientType.isCityPartner;
			},
			linkName() { //业务员id和name
				return this.$store.getters.linknamearray.length > 0 ? this.$store.getters.linknamearray[0] : {};
			},
			clientChannelList() {
				return this.$store.state.channelLevel.clientChannelList
			},
			clientLevelList() {
				return this.$store.state.channelLevel.clientLevelList
			},
			people() { //业务员列表
				return this.$store.state.salesMan.people
			},
			ctype() {
				return this.form.ctype;
			},
			brandIdList() {
				return this.$store.state.clientBrand.brandList.map(n => {
					return n.brandId
				})
			},
			clientConfigData() {
				return this.$store.state.clientlist.clientConfig
			},
			brandTabData() {
				return this.$store.state.clientBrand.brandList
			}
		},
		mounted() {
			this.$nextTick(() => {
				this.getAgencyList();
				//区域
				axiosApi('/trader/customerDistrict/query', {
					traderId: sessionStorage.getItem('traderId')
				}, (res) => {
					this.districtList = [res.data.data];
					this.districtList[0].id = '0';
				})
				this.getClientConfig(); //获取录入客户配置项
				this.makesure(0); //清除vuex中缓存的业务员数据
				this.queryChannelLevelData(0);
				this.queryChannelLevelData(1);
				this.form.mobile = this.$route.params.mobile;
				//console.log(888)
				this.form.saleId = null; //关联业务员id
				this.form.salesman = ""
				let body = {
					sellerId: sessionStorage.getItem('traderId'),
					mobile: this.form.mobile
				}
				axiosApi('/trader/clientInsert/get', body, (res) => {
					let a = res.data.data
					if(a) {
						this.isUpload = this.isUpload1 = this.isAdd = false;
						this.form.traderNumber = a.traderNumber;
						this.form.linkname = a.linkname;
						this.form.jobOffer = a.jobOffer;
						this.form.email = a.email;
						this.form.csource = a.csource ? a.csource : 2;
						this.form.storeName = a.storeName;
						this.form.ctype = a.ctype ? a.ctype : 2;
						if(a.ctype == 3) {
							this.ctypeDisabled = true;
						}
						this.form.cchannel = [a.cchannel1, a.cchannel2]; //客户渠道
						this.form.cchannel1 = a.cchannel1;
						this.form.cchannel2 = a.cchannel2;
						this.form.id = a.id;
						this.form.clevel = a.clevel; //客户等级
						this.form.groupId = a.groupId; //所属分组
						this.form.saleId = a.saleId; //关联业务员id
						this.form.licenseNum = a.licenseNum; //营业执照编号
						this.form.licenseImg = a.licenseImg; //营业执照照片
						if(a.licenseImg) this.licenseImgList.push({
							url: a.licenseImg
						})
						//门头照片
						if(a.photos.length > 0) this.form.photoUrlList.push({
							url: a.photos[0]
						})
						if(a.photos.length > 1) this.form.photoUrlList.push({
							url: a.photos[1]
						})
						this.form.photoUrl = a.photos;
						// if(a.photos.length > 0)this.photos = a.photos;
						this.form.operatingArea = a.operatingArea; //店铺面积

						this.form.provinceId = a.provinceId; //商户地址省id
						this.form.cityId = a.cityId; //市id
						this.form.regionId = a.regionId; //区id
						this.form.provinceName = a.provinceName; //商户地址省名称
						this.form.cityName = a.cityName; //市名称
						this.form.regionName = a.regionName; //区名称
						
						this.form.addr = a.attr; //详细地址
						this.form.deliveryProvinceId = a.deliveryProvinceId; //收货地址省id
						this.form.deliveryCityId = a.deliveryCityId; //
						this.form.deliveryRegionId = a.deliveryRegionId; //
						this.form.deliveryProvinceName = a.deliveryProvinceName; //
						this.form.deliveryCityName = a.deliveryCityName; //
						this.form.deliveryRegionName = a.deliveryRegionName; //
						this.form.deliveryAttr = a.deliveryAttr; //
						this.form.remarks = a.remarks; //备注

						// this.form.salesman = a.salesman;//业务员名称
						this.form.salesman = a.name; //业务员名称
						this.form.buyerId = a.buyerId

						this.form.businessAddress = [a.provinceId.toString(), a.cityId.toString(), a.regionId.toString()]; //营业地址
						this.form.deliveryAddress = a.deliveryProvinceId ? [a.deliveryProvinceId.toString(), a.deliveryCityId.toString(), a.deliveryRegionId.toString()] : []; //收货地址

						this.form.districtIds = a.district ? a.district.split(",").map(n => {
							return Number(n)
						}) : []; //所属区域

						this.form.districtIds[0] = '0';
						// for(let item of this.people){
						// 	console.log(item)
						// 	if(item.managerId == this.form.saleId){
						// 		this.form.salesman = item.employeeName;//业务员名称

						// 	}
						// }
						if(res.data.data.customerBrandVOs) {
							let a = res.data.data.customerBrandVOs.map(n => {
								return {
									brandName: n.brandName,
									brandId: n.id,
									isDeleted: n.isDeleted,
									classifyName: n.classifyName,
									logoIcon: n.logoIcon
								}
							})
							this.setBrandList(a)
						} else {
							this.setBrandList([])
						}
					} else {
						this.isAdd = true
					}
				}, 0, 0, err => {
					this.$message(err.data.message);
				}, 1)
				this.resetBrandList()
			})
		},
		methods: {
			...mapActions({
				'queryChannelLevelData': 'queryChannelLevelData',
				'makesure': 'sure',
				'getClientConfig': 'getClientConfig'
			}),
			...mapMutations({
				'setBrandList': 'setBrandList',
				'resetBrandList': 'resetBrandList'
			}),
			changeName(e){ // 省市区街道地址名
				this.areaNameList = e
			},
			getAgencyList(){
				let url = '/trader/findClientAllList/select';
				axiosApi( url,{ "sellerId": sessionStorage.getItem('traderId'),"ctype":"1" },res => {
					this.agencyList = res.data.data.elements;
				})
			},
			businessAddressChange(){//营业地址更改
				this.form.streetId = ""
				this.selectStreetInfoList(this.form.businessAddress[2])
			},
			deliveryAddressChange(){//仓库地址更改
				this.form.deliveryStreetId = ""
				this.selectStreetInfoLists(this.form.deliveryAddress[2])
			},
			beforeAvatarUpload(file) {
				let bool = this.isUploadImg(file)
				if(bool) {
					this.pictureLoading = true
				}
				return bool
			},
			handleRemove(file, fileList) {
				if(fileList.length > 1) {
					this.isUpload1 = false;
				} else {
					this.isUpload1 = true;
				}
				this.form.photoUrlList = fileList.map(item => {
					return {
						url: item.url
					}
				})
				this.form.photoUrl = fileList.map(item => {
					return item.url
				})
			},
			errFun(err, file, fileList) {
				this.pictureLoading = false
				this.$message({
					message: err.data.message,
					type: 'info',
					duration: 1000
				});
			},
			handleAvatarScucess(res, file, fileList) {
				this.isUpload = false;
				this.pictureLoading = false;
				this.form.licenseImg = res.data[0];
				this.licenseImgList = fileList.map(item => {
					return {
						url: item.response.data[0]
					}
				})
			},
			handlePictureCardPreview(file) {
				this.dialogImageUrl = file.url; //门店照片的放大预览
				this.dialogVisible = true;
			},
			handleRa(file, fileList) {
				this.isUpload = true;
				this.form.licenseImg = null;
				this.form.licenseImgList = fileList.map(item => {
					return {
						url: item.url
					}
				})
			},
			handleAvatarSuccessList(res, file, fileList) {
				if(fileList.length > 1) {
					this.isUpload1 = false;
				} else {
					this.isUpload1 = true;
				}
				this.pictureLoading = false;
				this.form.photoUrlList = fileList
				this.form.photoUrl = fileList.map(item => {
					return item.response.data[0]
				})
			},
			submit() {
				// console.log(this.form,1)
				// delete this.form.cityId
				// setTimeout(()=>{
				// 	console.log(this.form,2)
				// },0)
				//客户渠道
				if(this.form.cchannel.length > 0) this.form.cchannel1 = this.form.cchannel[0]
				if(this.form.cchannel.length > 1) this.form.cchannel2 = this.form.cchannel[1]
				//商户地址
				this.form.provinceId = this.form.businessAddress[0]; //商户地址省id
				this.form.cityId = this.form.businessAddress[1]; //
				this.form.regionId = this.form.businessAddress[2]; //
				this.form.provinceName = this.areaNameList[0]; //商户地址省名称
				this.form.cityName = this.areaNameList[1];
				this.form.regionName = this.areaNameList[2];
				// let arr2 = this.$refs.deliveryAddress.inputValue ? this.$refs.deliveryAddress.inputValue.split("/") : [];
				// this.form.deliveryProvinceId = this.form.deliveryAddress[0] ? this.form.deliveryAddress[0] : ''; //收货地址省id
				// this.form.deliveryCityId = this.form.deliveryAddress[1] ? this.form.deliveryAddress[1] : ''; //
				// this.form.deliveryRegionId = this.form.deliveryAddress[2] ? this.form.deliveryAddress[1] : ''; //
				// this.form.deliveryProvinceName = arr2[0] ? arr2[0] : ''; //
				// this.form.deliveryCityName = arr2[1] ? arr2[1] : ''; //
				// this.form.deliveryRegionName = arr2[2] ? arr2[2] :''; //
				this.form.districtId = this.form.districtIds[this.form.districtIds.length - 1];
				this.form.cbrand = this.brandIdList.join(',') //品牌id列表
				if(this.isAdd) { // 新增客户删除 ctype,cchannel1,cchannel2
					delete this.form.ctype
					delete this.form.cchannel1
					delete this.form.cchannel2
				}
				console.log(this.form,'form')
				this.$refs.form.validate((valid) => {
					if(valid) {
						let api = this.isAdd ? '/trader/clientInsert/insert' : '/trader/clientInsert/update';
						axiosApi(api, this.form, (res) => {
							this.$message({
								message: '录入成功！',
								type: 'success',
								duration: 1000
							});
							setTimeout(() => {
								this.$router.push(`${basePath}/clientList`);
							}, 1000)
						}, 1, 10, (err) => {
							this.$message({
								message: err.data.message,
								type: 'info',
								duration: 1000
							});
						}, 1)
					}
				})

			},
			addBrandClick() {
				this.$refs.addBrand.show();
			},
			selectStreetInfoList(val) { //通过区县查询四级街道地址信息
				axiosApi("/unlogin/trader/district/selectStreetInfoList", {
					parentId: val
				}, (res) => {
					this.fourLists = res.data.data
					console.log(res, 'address111')
				}, 1, 10000, () => {

				})
			},
			selectStreetInfoLists(val) { //通过区县查询四级街道地址信息
				axiosApi("/unlogin/trader/district/selectStreetInfoList", {
					parentId: val
				}, (res) => {
					this.fourListsDelivey = res.data.data
					console.log(res, 'address111')
				}, 1, 10000, () => {

				})
			},
			addressName(val) {
				let obj = {};
//				obj = this.fourLists.find((item) => {
//					return item.id === val;
//				});
				this.fourLists.map(v => {
						if(v.id === val) obj = v
					});
				this.form.streetName = obj ? obj.districtName : ""
			},
			deliveryName(val) {
				let obj = {};
//				obj = this.fourListsDelivey.find((item) => {
//					return item.id === val;
//				});
				this.fourListsDelivey.map(v => {
						if(v.id === val) obj = v
					});
				this.form.deliveryStreetName = obj ? obj.districtName : ""
			},
		}
	}
</script>

<style lang="less">
	.add-store {
		padding: 20px;
		.form-label {
			border-left: 3px solid #20a0ff;
			margin-top: 20px;
			padding-left: 10px;
		}
		.parting-line {
			height: 2px;
			margin: 50px 0 50px 40px;
			background: url(../../assets/bg-dashed.png);
		}
		.el-form-item__label {
			padding-right: 30px;
		}
		.p10 {
			padding: 9px 0 10px;
		}
		.ml-200 {
			margin-left: -200px;
		}
		.el-input.is-disabled .el-input__inner {
			background-color: #fff;
			color: #333;
		}
		.el-cascader.is-disabled .el-cascader__label {
			color: #333;
			cursor: not-allowed;
		}
		.noAdd {
			.el-upload.el-upload--picture-card {
				display: none;
			}
		}
		.add {
			.el-upload.el-upload--picture-card {
				display: inline-block;
			}
		}
		.noDel {
			.el-upload-list__item-actions {
				.el-upload-list__item-delete {
					display: none;
				}
			}
		}
		.w100 {
			width: 100px;
		}
		.w130 {
			width: 130px;
		}
		.w700 {
			width: 700px;
			textarea {
				height: 120px;
			}
		}
		.w-300 .el-input {
			width: 350px;
		}
		.avatar {
			width: 238px;
			height: 178px;
			display: block;
		}
		.photo-url {
			position: absolute;
			left: -98px;
			top: 0;
		}
		.operating-area {
			.el-form-item__error {
				left: auto;
				right: 234px;
			}
		}
		.table-700 {
			width: 700px;
		}
	}
</style>
