<template>
    <div id="addSelfDrinks" class=" pb-50">
        <div class="title-top bg-f">
            商品库
        </div>
        <div class="bg-f mt-5 pb-50">
            <el-form :inline="true" :model="formInlineData" label-width="90px" class="clearfix pl-20 pt-30">
                <div class="fl width-70p">
                    <el-form-item label="商品编码">
                        <el-input v-model.trim="form.spuNo"></el-input>
                    </el-form-item>
                    <el-form-item label="商品名称">
                        <el-input v-model.trim="form.spuName"></el-input>
                    </el-form-item>
                </div>
                <el-button type="info" class="fl ml-50" @click='achieve(true)'>查询</el-button>
            </el-form>
            <div class='clearfix ml-30 mr-30 mt-20'>
                <div class="bd mb-10 ha pr">
                    <div class="fl w85 bg-e tac">分类</div>
                    <ul class="clearfix  ml-80" :class="{reduce:spread1}">
                        <li class="fl width-90 cursor tac ch" v-for='(list,index) in searchClassify' :key="index"
                            @click="getClassindex(index)" :class="{active:index==activeIndexClassfy}">
                            {{list.classifyName}}
                        </li>
                    </ul>
                    <P v-if="this.searchClassify.length > 8" class="fr font-12 cursor color-3 dw mr-10"
                       @click="spread1=!spread1">更多
                        <i class="color-bb" :class='{"el-icon-caret-bottom":spread1,"el-icon-caret-top":!spread1}'></i>
                    </P>
                </div>
                <div class="bd more ha pr">
                    <div class="fl w85 bg-e tac">品牌</div>
                    <div v-if='!spread2' class="mt-10 ">
                        <el-input class='ml-30' placeholder="请输入品牌" v-model.trim='brandName1'
                                  @keyup.enter.native="achieveBrand()"></el-input>
                        <el-button type="danger" class=" ml-30" @click='achieveBrand()'>查询</el-button>
                    </div>
                    <ul class="clearfix  ml-80"
                        :class="{reduce:spread2,hha:searchBrandName.length > 8 && !spread2}">
                        <li class="fl width-90 cursor tac hides ch" v-for='(item,index) in searchBrandName' :key="index"
                            :class="{active:index==activeIndexBrand}" @click='getbrandindex(index)'>{{item.brandName}}
                        </li>
                    </ul>
                    <div v-if="this.searchBrandName.length > 8" class="fr font-12 cursor color-3 dw mr-10"
                         @click="brandMore">更多
                        <i class="color-bb" :class='{"el-icon-caret-bottom":spread2,"el-icon-caret-top":!spread2}'></i>
                    </div>
                </div>
            </div>
            <!--<div class="bbd clearfix ml-30 mr-30">
                <el-tabs class="fl mt-30 supplier" v-model="activeTab">
                    <el-tab-pane label="全部商品" name="1"></el-tab-pane>
                </el-tabs>
            </div>-->
            <el-table :data="productTableData" v-loading="Loading" tooltip-effect="dark"
                      class="ml-30 table-default mr-30 mt-20" stripe>
                <el-table-column prop="1" label="商品名称"  min-width="300">
                    <template slot-scope="scope">
                        <div class="fl ml-10 mt-10">
                            <img v-if='scope.row.defaultPicture' :src="scope.row.defaultPicture"/>
                            <img v-else src="../../assets/goods.jpg"/>
                        </div>
                        <div class="fl mt-10 w210 mt-10">
                            <div class="lh-12 tal">商品编码:{{scope.row.spuNo}}</div>
                            <div class="tal">{{scope.row.spuName}}</div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="规格" min-width="80">
                    <template slot-scope="scope">
                        <div>1*{{scope.row.specInfoNum}}</div>
                    </template>
                </el-table-column>
                <el-table-column prop="classifyName" label="商品分类" min-width="110">
                </el-table-column>
                <el-table-column prop="brandName" label="品牌" min-width="110">
                </el-table-column>
                <el-table-column label="市场参考价" min-width="110">
                    <template slot-scope="scope">
                        <div>￥{{scope.row.msrp}}</div>
                    </template>
                </el-table-column>
                <el-table-column prop="7" label="状态" min-width="100">
                    <template slot-scope="scope">
                        <span v-if="scope.row.isAdd == '0'">未添加</span>
                        <span v-if="scope.row.isAdd == '1'">已添加</span>
                    </template>
                </el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-button type="text" class='font-12' @click.native="addSku(scope.$index,productTableData)">
                            {{scope.row.isAdd==1?'查看':'添加'}}
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination class="tar mr-30" v-if="total>0" :current-page="currentPage" :page-size='10' :total='total'
                           layout="prev , pager, next, jumper" @current-change="handleCurrentChange">
                <!-- 分页组件 -->
            </el-pagination>
        </div>

        <!--弹窗选择区域-->
        <el-dialog title="请提交销售资质！" :visible.sync="dialogAreaVisible">
            <div class="boom ml-50">
                <p class="w-400">由于您没有该商品的销售资质，该商品暂时只能被您现有的客户可见。若您想全网销售，请<span class="red">上传销售资质</span>或<span
                        class="red">选择已有销售资质</span></p>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="haveUpload">去上传</el-button>
                <el-button @click="hadUpload">选择已有的</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {axiosApi, basePath} from "../../api/api";

    export default {
        data() {
            return {
                spread1: false, //判断是否展开
                spread2: false, //判断是否展开
                productTableData: [], //商品列表
                searchClassify: [{classifyName: '全部', id: null}], //搜索分类
                searchBrandName: [{brandName: '全部', id: null}], //搜索品牌
                Loading: true,
                //				分页
                currentPage: 1,
                total: 0,
                isPagination: true,
                formInlineData: {name: ''},
                form: { //搜索条件
                    id: '',
                    name: '',
                    spuNo: null,
                    spuName: null,
                    classifyId: null,
                    brandId: null,
                    isAdd: null
                },
                classifyName: null,
                brandName: null,
                brandName1: '',
                classifyId: null,
                brandId: null,
                spuNo: null,
                activeTab: '1',
                activeIndexClassfy: 0,
                activeIndexBrand: 0,
                dialogAreaVisible: false,
                rej: 2 //跳转查看传参
                //				isAuthorizationFailure:false
            }
        },
        methods: {
            //查询分类/品牌
            searchAll() {
                axiosApi("/catering/platform/wine/selfWineBrandList", {}, (res) => {
                    this.searchBrandName = this.searchBrandName.concat(res.data.data); //品牌
                });

                this.searchClassify = this.searchClassify.concat([{classifyName: '饮料', id: 503}, {
                    classifyName: '啤酒',
                    id: 497
                }]); //分类
                setTimeout(() => {
                    this.changeHeight()
                }, 200)
            },
            brandMore() { //品牌更多
                this.spread2 = !this.spread2
                document.getElementsByClassName("w750")[1].scrollTop = 0
                this.activeIndexBrand = 0
            },
            //搜索品牌
            achieveBrand() {
                const api = "/catering/platform/wine/selfWineBrandList";
                const body = {
                    classifyId: this.form.classifyId,
                    brandName: this.brandName1
                }
                axiosApi(api, body, this.achieveBrand1, 1, 1000)
            },
            achieveBrand1(res) {
                this.activeIndexBrand = null
                if (this.brandName1 == '') {
                    this.searchBrandName = [{brandName: '全部', id: null}]
                    this.searchBrandName = this.searchBrandName.concat(res.data.data);
                } else {
                    this.searchBrandName = res.data.data
                }

            },
            //更多改变高度
            changeHeight() {
                if (this.searchClassify.length > 8) {
                    this.spread1 = true;
                }
                if (this.searchBrandName.length > 8) {
                    this.spread2 = true;
                }
            },
            //搜索选中状态
            getClassindex(index) {
                this.activeIndexClassfy = index;
                this.form.classifyId = this.searchClassify[index].id
                this.achieve(true);
                this.achieveBrand()
            },
            getbrandindex(index) {
                this.activeIndexBrand = index;
                this.form.brandId = this.searchBrandName[index].id
                this.achieve(true);
            },
            //加载商品列表
            achieve(bool) {
                if (bool && this.currentPage != 1) {
                    this.currentPage = 1;
                }
                const api = "/catering/platform/wine/selfWineSelectListPage";
                const body = {
                    goodsNo: this.form.spuNo,
                    skuName: this.form.spuName,
                    classifyId: this.form.classifyId,
                    brandId: this.form.brandId,
                    skuType: 3
                }
                axiosApi(api, body, this.callback, this.currentPage, 10)
            },
            callback(res) {
                let data = res.data.data.elements ? res.data.data.elements : [];
                this.total = res.data.data.totalCount ? res.data.data.totalCount : '';
                this.productTableData = data;
                this.Loading = false
            },
            //分页
            handleCurrentChange(val) {
                this.currentPage = val
                this.achieve()
            },
            //商品添加,编辑前判断
            addSku(index, rows) {
                this.classifyName = this.productTableData[index].classifyName;
                this.brandName = this.productTableData[index].brandName;
                this.classifyId = this.productTableData[index].classifyId;
                this.brandId = this.productTableData[index].brandId;
                this.spuNo = this.productTableData[index].spuNo;
//				this.isAuthorizationFailure = this.productTableData[index].isAuthorizationFailure;
                if (this.productTableData[index].isAdd == 0) {
                    this.addSkuOne()
                } else {
                    if (this.productTableData[index].edit) {
                        this.$alert('此商品正在编辑中', '提示信息', {
                            confirmButtonText: '确定',
                            callback: action => {
                                this.$message({
                                    type: 'info',
                                    message: '操作成功'
                                });
                            }
                        });
                    } else {
                        this.$router.push({path: 'goodsDetails', query: {spuNo: this.productTableData[index].spuNo}});
                    }
                }
            },
            //商品添加
            addSkuOne() {
                let that = this;
                this.$confirm('您是否要添加该商品？', '提示信息', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    const api = "/catering/platform/wine/insert";
                    const body = {
                        b2bSpuNo: this.spuNo,
                        skuType: 3
                    };
                    axiosApi(api, body, (res) => {
                        that.achieve();
                        that.$message({
                            type: 'success',
                            message: '添加成功!',
                            duration: 1000
                        });
                    }, this.currentPage, 10, (res) => {
                        this.$message({
                            type: 'info',
                            message: res.data.message,
                            duration: 3000
                        });
                    });
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消操作'
                    });
                })
            },
            //
            haveUpload() {
                this.dialogAreaVisible = false;
                sessionStorage.setItem('classifyName', this.classifyName);
                sessionStorage.setItem('brandName', this.brandName);
                this.$router.push(basePath + '/uploadLicense/' + this.classifyId + '/' + this.brandId + '/' + this.spuNo)
            },
            hadUpload() {
                this.dialogAreaVisible = false;
                sessionStorage.setItem('classifyName', this.classifyName);
                sessionStorage.setItem('brandName', this.brandName);
                this.$router.push(basePath + '/looklicense/' + this.classifyId + '/' + this.brandId + '/' + this.spuNo)
            },
        },
        mounted() {
            this.$nextTick(function () {
                this.achieve(); //获取商品列表
                this.searchAll(); //获取品牌/分类
                this.changeHeight(); //更多改变高度
            })
        }
    }
</script>

<style lang="less">
    #addSelfDrinks {
        .el-form-item__label {
            font-size: 12px;
        }

        .w160 {
            width: 160px;
        }

        .w210 {
            width: 210px;
        }

        .w80 {
            width: 80px;
        }

        .w85 {
            width: 80px;
        }

        .w750 {
            width: 750px;
        }

        .bd {
            border: 1px solid #DDDDDD;
            height: 50px;
            line-height: 50px;
        }

        .ha {
            height: auto;
        }

        .reduce {
            height: 50px;
            overflow: hidden;
        }

        .w-900 {
            width: 900px;
        }

        .dw {
            position: absolute;
            right: 0;
            top: 0;
        }

        .pr {
            position: relative;
        }

        .red {
            color: red;
        }

        .w-400 {
            width: 400px;
        }

        .hides {
            overflow: hidden;
            /*自动隐藏文字*/
            text-overflow: ellipsis;
            /*文字隐藏后添加省略号*/
            white-space: nowrap;
            /*强制不换行*/
            width: 20em;
            /*不允许出现半汉字截断*/
        }

        .hha {
            max-height: 250px;
            overflow-y: scroll;
        }

        .ml-80 {
            margin-left: 80px;
        }

        .el-dialog {
            min-height: 300px;
        }

        .ch:hover,
        .ch.active {
            background: #666666;
            color: #FFFFFF;
        }
    }
</style>