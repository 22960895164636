<template>
	<div class="chooseGoods2">
		<el-dialog title="选择商品" :visible.sync="productVisible" @close="goPage">
			<div v-loading="tableloading">
				<el-form :inline="true" :model="skuObj" label-width="80px" class="clearfix" :style="{width:'850px'}">
					<div class="fl">
						<el-form-item label="商品编码:">
							<el-input  v-model.trim="skuObj.goodsNo" @input="skuObj.goodsNo=skuObj.goodsNo.replace(/[\u4e00-\u9fa5]/ig,'')"></el-input>
						</el-form-item>
						<el-form-item label="商品名称:">
							<el-input  v-model.trim="skuObj.skuName"></el-input>
						</el-form-item>
						<el-form-item label="商品分类:">
							<el-select v-model='skuObj.classifyId' placeholder='请选择' clearable >
								<el-option v-for='item in classifylist'  :key='item.id'
									:label='item.classifyName'
									:value='item.id'></el-option>
							</el-select>
						</el-form-item>
					</div>
					<el-button type="primary" class="fl ml-50" @click="querygood">查询</el-button>
				</el-form>
				<el-table @selection-change="handleSelectionChange" :data="proData" stripe border tooltip-effect="dark" class="mt-10 table-default">
					<el-table-column type="selection" width="50">
					</el-table-column>
					<el-table-column label="商品图片">
						<template slot-scope="scope">
							<img v-if="scope.row.fileUrl" :src="scope.row.fileUrl"/>
							<img v-else src="../../../assets/goods.jpg" />
						</template>
					</el-table-column>
					<el-table-column label="商品编码" prop='goodsNo' >
					</el-table-column>
					<el-table-column label="商品名称" prop='skuName' width="350" show-overflow-tooltip>
					</el-table-column>
					<el-table-column label="分类" prop='classifyName' >
					</el-table-column>
				</el-table>
				<el-pagination class="tar" v-if='total>0'
					:current-page="currentPage"
					:page-size='10'
					:total='total'
					layout="prev , pager, next, jumper"
					@current-change="handlePageChange">
				</el-pagination>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" @click="addgift" :loading="btnLoading">确 定</el-button>
				<el-button @click="goPage">取 消</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {mapActions,mapMutations} from 'vuex'
	import { axiosApi,basePath } from '~/api/api' //导入axiosApi
	export default {
		props: ["goodVisible", "addapi", "ifnotauto",'ok'],
		data() {
			return {
				currentPage: 1,
				skuObj:{
					goodsNo:'',     //K3的商品编码
					skuName:'',     //商品名称
					classifyId:''  //商品分类
				},
				btnLoading:false,     //搜索时
				multipleSelection: [],
				addlists: [],
				productVisible: false,
			}
		},
		computed: {
			postData() {
				return {
					data: this.skuObj,
					currentPage: this.currentPage
				}
			},
			tableloading() {
				return this.$store.state.ticket.tableloading
			},
			total() {
				return this.$store.state.ticket.goodtableData ?
					this.$store.state.ticket.goodtableData.totalCount : 0
			},
			classifylist() {
				return this.$store.state.classifylist.classifylistData ?
					this.$store.state.classifylist.classifylistData : []
			},
			proData() {
				let tableData = this.$store.state.ticket.goodtableData;
				this.currentPage = tableData ? tableData.pageIndex : 1;
				return tableData ? tableData.elements : []
			},
			goodsList(){// 套餐页面已添加的商品
				return this.$store.state.ticket.willadd
			}
		},
		watch: {
			goodVisible(value) {
				this.productVisible = value
				if(value) {
					this.skuObj.goodsNo = ''
					this.skuObj.skuName = ''
					this.skuObj.classifyId = ''
				}
			},
			productVisible(val){//监听弹窗model变化改变按钮loading（打开弹窗初始化loading）
				this.btnLoading = !val;
			}
			// notid(value) {
			// 	this.postData.data.notId = this.skuObj.notId = value
			// 	// console.log(this.postData)
			// }
		},
		mounted(){
			this.$nextTick(()=>{
				this.classSearch()
			})
		},
		methods: {
			...mapMutations({
					'clearnotid': 'clearnotid'
			}),
			//查询商品列表名字
			...mapActions({
					'addDistribute' : 'queryMealData',
					'classSearch' : 'queryclassifylistData'
			}),
			handleSelectionChange(val) {
                this.multipleSelection = val.map((i)=>{ // 选中商品 默认套餐商品数量 为 1
                    return Object.assign(i,{num:1})
                })
				this.multipleSelection = val
			},
			querygood() {
				if(this.currentPage == 1){
					let newPostData = Object.assign({}, this.postData, {currentPage: 1})
	     			this.addDistribute(newPostData)
     			}else{
     				this.currentPage = 1
     			}
			},
			// 向页面添加商品
			addgift() {
				let body = this.multipleSelection
				this.btnLoading = true
				if(body.length > 0){// 已有选中商品
					if ((this.goodsList.length + this.multipleSelection.length) > 10) {// 套餐页面商品数 + 已有选中商品 >10 不做操作
						this.$message.error('每个套餐最多只允许包含10个商品！')
					}else{
						if(this.ifnotauto) {
							this.$store.dispatch('addgood', {
								willadd: this.multipleSelection
							})
						}
					}
				}
				this.$emit('closedialog')
			},
			//返回上一页
			goPage() {
				this.clearnotid()
				this.$emit('sure')
				this.$emit('closedialog')
			},
			handlePageChange(val){
				this.currentPage = val
				this.addDistribute(this.postData)
			}
		}
	}
</script>

<style  lang="less">
	.chooseGoods2 .el-dialog {
		width: 950px;
		min-height: 725px;
	}
	.chooseGoods2 .table-default.el-table .cell{
		padding: 3px 15px 0;
	}
	.chooseGoods2 .el-dialog__body {
		padding: 20px 30px;
	}
	.chooseGoods2 {
		.el-dialog {
			.el-form {
				.el-form-item {
					.el-form-item__label {
						&::before {
							display: none
						}
					}
				}
			}
		}
	}
	.chooseGoods2 .el-form .el-input{width: 120px;}
	.chooseGoods2 .el-dialog{
		.el-dialog__footer{
			padding: 10px 20px 30px;
			position: static;
			transform: translateX(0);
		}
	}
</style>
