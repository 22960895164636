<template>
	<el-dialog id="unbindbank" title="解绑银行卡" :visible.sync="visible" @close="goPage">
		<el-form label-width="158px" :model="form" ref="form" :rules="rules" class="pb-50">
			<el-form-item label="已绑定手机号:">
				<span>{{phone}}</span>
			</el-form-item>
			<el-form-item label="短信验证码:" prop="code">
				<el-input v-model.trim="form.code" style="width:120px" @change="clearmsg"></el-input>
				<el-button class="ml-20 wa" @click="sendnum({type:4})" :disabled="timeb">获取验证码<span v-if="timeb">{{time | seconds}}</span></el-button>
				<p v-if="errormsg.length>0" class="color-danger lh-14 mt-5">{{errormsg}}</p>
			</el-form-item>
		</el-form>
		<div slot="footer" class="dialog-footer">
			<el-button type="danger" @click="sure">确定</el-button>
			<el-button @click="goPage">取消</el-button>
		</div>
	</el-dialog>
</template>

<script>
	import { axiosApi, basePath } from "~/api/api"
	import { mapActions, mapMutations } from "vuex"
	export default {
		props: ["dialogvisible","bankCardId"],
		data() {
			return {
				form: {
                    code:''
                },
				visible: false, //彈窗是否关闭
				phone: "",
				rules: {
					code: [{
						required: true,
						pattern: /^\d{6}$/,
						message: '请输入6位验证码',
					}]
				}
			}
		},
		computed: {
			errormsg() {
				return this.$store.state.paymodule.errormsg
			},
			time() {
				return this.$store.state.paymodule.time
			},
			timeb() {
				return this.$store.state.paymodule.timeb
			},
			result(){
				return this.$store.state.paymodule.unresult
			}
		},
		watch: {
			dialogvisible(val) {
				this.phone = JSON.parse(sessionStorage.payform).mobile
				this.cleartime()
				this.clearmsg()
				this.visible = val
				setTimeout(() => {
					this.$refs.form.resetFields()
				}, 0)
			},
			result(val){
				if (val) {
					this.$message.success("解绑成功")
					this.goPage()
				}
			}
		},
		methods: {
			...mapActions({
				"sendnum": "sendnum",
				"unbindCard":"unbindCard"
			}),
			...mapMutations({
				"clearmsg": "clearmsg",
				"cleartime": "cleartime"
			}),
			goPage() {
				this.$emit("closedialog")
			},
			sure() {
				this.$refs.form.validate(valid=>{
					if (valid) {
						console.log("ok")
						this.unbindCard({
							bankcode:this.bankCardId,
							vcode:this.form.code
						})
					}else{
						console.log("fail")
					}
				})
				
			}
		}
	}
</script>

<style lang="less">
	#unbindbank {
		.el-dialog {
			min-height: 310px;
			.wa {
				width: auto;
				padding: 0 5px;
			}
		}
	}
</style>