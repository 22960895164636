import { axiosApi, basePath } from "./api";

// 获取全部分组 ajax
let getFz = (callback) => {
    let sendUrl = '/trader/customer/group/listTree';
    axiosApi(sendUrl, {}, (res) => {
        let data = treeInit(res.data.data);
        callback(data);
    }, 1, 100000);
}

let treeInit = (tree) => {
  tree.forEach(item=>{
    if(item.sub.length>0){
      treeInit(item.sub)
    }else{
      delete item.sub
    }
  })
  return tree
}



// 获取全部 区域 ajax
let getQy = (callback) => {
    axiosApi('/trader/customerDistrict/query', {
        traderId: sessionStorage.getItem('traderId'),
    }, (res) => {
        callback([res.data.data]);
    });
}

// 获取全部 部门 ajax
let getBm = (callback) => {
    const api = "/foundation/department/displayTree";
    const body = {
        applicationType: 2,
        traderId: sessionStorage.traderId
    };
    axiosApi(api, body, (res) => {
        callback(res.data.data || []);
    }, 1, 100000);
}

// 获取全部 角色 ajax
let getJs = (callback) => {
    const api = "/foundation/role/selectAll";
    const body = {
        applicationType: 2,
        traderId: sessionStorage.traderId
    }
    axiosApi(api, body, (res) => {
        callback(res.data.data || []);
    }, 1, 100000)
}

export { getFz, getQy, getBm, getJs };