<template>
	<!--支付订单页面-->
	<div id="cloud-pay-order" class="bg-f">
		<top></top>
		<div class="pay-order-content">
			<div class="content  pt-30" v-loading="loading">
				<div class="clearfix pb-30">
					<div class="fl ">
						<span class="font-20 color-3 lh-20 ">订单提交成功，请尽快付款！订单号：{{orderPrePayVo.sn}}</span>
						<div class="color-6 lh-14  mt-10">请您在<span class="color-danger">{{orderPrePayVo.remainAutoCancleTime}}</span>内完成支付，否则订单会被自动取消</div>
					</div>
					<div class="fr">
						支付金额 <span class="color-danger font-30">￥<span >{{orderPrePayVo.needPayMoney | fmoney}}</span></span>
					</div>
				</div>

				<ul class="address-info">
					<li class="mb-10"><span class="color-9">收货人:</span> {{orderPrePayVo.addrUsername}}</li>
					<li class="mb-10"><span class="color-9">手机／电话:</span> {{orderPrePayVo.addrMobile}}</li>
					<li class="mb-10"><span class="color-9">所在地区:</span> {{orderPrePayVo.addrProvince}} {{orderPrePayVo.addrCity}} {{orderPrePayVo.addrRegion}}</li>
					<li><span class="color-9">详细地址:</span> {{orderPrePayVo.addr}}</li>
				</ul>
				<div class="pay-bank mt-20 clearfix">
					<div class="font-20 lh-20 color-3 fl mr-50">
						请选择汇款信息:
					</div>
					<div class="fl">
						<el-form :model="form" ref="form" :rules="rules">
							<el-form-item prop="bankItem">
								<el-select v-model='form.id' placeholder='请选择汇款银行'>
									<el-option v-for="(item, index) in orderPrePayVo.findSellerBankInfoVOs" :key="index" :label='item.bankName' :value='item.id'></el-option>
								</el-select>
							</el-form-item>
							<div v-if="bankItem.length!=0">
								<p class="font-14 lh-14 mt-20">收款名称：<span>{{bankItem[0].bankUserName}}</span></p>
								<p class="font-14 lh-14 mt-20">收款银行：<span>{{bankItem[0].bankName}}</span></p>
								<p class="font-14 lh-14 mt-20">开户支行：<span>{{bankItem[0].bankDeposit}}</span></p>
								<p class="font-14 lh-14 mt-20 mb-30">收款账号：<span>{{bankItem[0].bankCard}}</span></p>
							</div>
						</el-form>
					</div>
				</div>

				<div class="payment-voucher-info mt-20">
					<div class="font-20 lh-20 color-3 fl mr-50">
						付款凭证信息:
					</div>
					<div class=" fl clearfix">
						<el-button class='' type="danger" v-if="fileList.length < 1" @click="paymentVoucherVisible=true">添 加</el-button>
						<el-upload class="fl showpaymentImage" action="/sunrise-gateway/oss/ossUpload" :file-list='fileList' list-type="picture-card" :on-preview="handlePictureCardPreview">
						</el-upload>
						<el-dialog :visible.sync="dialogVisible" size="tiny">
							<img width="100%" :src="dialogImageUrl" alt="">
						</el-dialog>
					</div>
					<el-button class="fl" type='danger' v-if="fileList.length==1" @click="paymentVoucherVisible=true">编 辑</el-button>
					<div class="fr">
						<!-- <img class="db" :src="`http://qrcode.shuogesha.com/qrcode?pixel=165_165&content=${ PROJECT.mcbs }/payOrder?traderId=${ traderId }%26sn=${ orderPrePayVo.sn }%26sum=${ orderPrePayVo.needPayMoney }`" width="165px" height="165px" />  -->
						<vue-q-art :config="config" :downloadButton="false"></vue-q-art>
						<p class="tac">您也可以扫码上传付款凭证</p>
					</div>
					<el-dialog title="付款凭证信息" :visible.sync='paymentVoucherVisible' v-loading.body="imgLoading">
						<el-form :model="form" :rules="rules" ref="formPayment">
							<el-form-item label='汇款凭证:' prop="paymentImg">
								<input type="hidden" v-model="form.paymentImg" />
								<el-upload class="avatar-uploader fl" action="/sunrise-gateway/oss/ossUpload" :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
									<img v-if="form.paymentImg" :src="form.paymentImg" class="avatar">
									<i v-else class="el-icon-plus avatar-uploader-icon"></i>
									<div class="el-upload__tip" slot="tip">请上传有效清晰地凭证， 4M以内，支持jpg、png 格式。</div>
								</el-upload>
							</el-form-item>
						</el-form>
						<div slot="footer" class="dialog-footer">
							<el-button type="danger" @click="paymentDialogSave">确 定</el-button>
							<el-button @click="paymentDialogCancel">取 消</el-button>
						</div>
					</el-dialog>
				</div>
				<el-button type='danger' class="mt-50 mb-50" @click="handleCommit">确认支付</el-button>
				
				<el-button class="mt-50 mb-50 ml-20" @click="nextPay">稍后支付</el-button>
			</div>

		</div>

	</div>
</template>

<script>
	import { axiosApi, basePath } from "../../api/api";
	import VueQArt from 'vue-qart';
	import imagePath from '~/assets/title.png';
	export default {
		components: {
			VueQArt
		},
		data() {
			return {
				value: null,
     			size: 178,
				basePath: basePath,
				paymentVoucherVisible: false, //添加付款凭证
				problemVisible: false, //问题反馈
				issue: null, //问题string
				orderPrePayVo: {}, //订单预支付页面
				loading: false,
				fileList: [], //显示的图片
				form: {
					id:null,//v-model的id
					paymentImg: null, //付款凭证 img
				},
				dialogVisible: false,
				dialogImageUrl: '',
				rules: {
					paymentImg: [{
						required: true,
						message: '请上传付款凭证！',
						trigger: 'change'
					}]
				},
				imgLoading: false,
				traderId: null, //供应商ID
				config: {
					value: "",
					imagePath: imagePath,
			        filter: ''
				}
			}
		},
		computed:{
			bankItem(){
				let that = this;
				if(this.orderPrePayVo.findSellerBankInfoVOs){
					return  this.orderPrePayVo.findSellerBankInfoVOs.filter((a)=>{
						return a.id == that.form.id
					})
				} else {
					return []
				}
				
			}
		},
		mounted() {
			this.$nextTick(() => {
				this.prepayOrderInfo()
			})
		},
		methods: {

			// 稍后支付
			nextPay() {
//				this.$router.push(basePath + '/dispatch')
				if(sessionStorage.orderType == 'dispatch'){
						this.$router.push(basePath + '/dispatch')
					} else if(sessionStorage.orderType == 'indent'){
						this.$router.push(basePath + '/indent')
					}
			},
			/*确认支付*/
			handleCommit() {
				this.$refs.form.validate((valid) => {
					if(valid) {
						if(this.fileList.length == 1) {
							let body = {
								orderSn: this.orderPrePayVo.sn,//订单号
								amount: this.orderPrePayVo.needPayMoney,//支付金额
								traderBankId: this.bankItem[0].id,//银行卡ID
								urls: [this.fileList[0].url],//凭证图片
								bankUserName: this.bankItem[0].bankUserName,//持卡人姓名
								incomeAccountNum: this.bankItem[0].bankCard,//银行卡号
								incomeAccountName: this.bankItem[0].bankDeposit//银行支行
							}
							this.addApplyRemittance(body)
						}
						if(this.fileList.length == 0) {
							const h = this.$createElement
							this.$msgbox({
								title: '提示信息',
								message: h('p', {
									style: 'text-align:center'
								}, '您填写的信息不完整! '),
								showCancelButton: false,
								confirmButtonText: '确定',
								cancelButtonText: '取消',
								beforeClose: (action, instance, done) => {
									if(action === 'confirm') {
										done()
									} else {
										done()
									}
									delete instance.$slots.default;
								}
							})
						}
					} else {
						return false
					}
				})
			},
			/*remittance.apply.add 插入汇款凭证*/
			addApplyRemittance(body) {
				axiosApi('/sales/orderRemittance/app/insert', body, (res) => {
					this.$message({type:'success',message:'我们已经收到您的付款凭证信息，审核通过后会尽快为您安排发货 ',duration:2000})
					if(sessionStorage.orderType == 'dispatch'){
						this.$router.push(basePath + '/dispatch')
					} else if(sessionStorage.orderType == 'indent'){
						this.$router.push(basePath + '/indent')
					}
				}, 1, 10, (res) => {
					this.$message({
						type: 'error',
						message: res.data.message,
						duration: 1000
					})
				})
			},
			/*支付页面信息接口*/
			prepayOrderInfo() {
				this.loading = true
				axiosApi('/sales/orderPrePay/web/selectInfo', {
					orderSn: sessionStorage.orderSn
				}, (res) => {
					this.loading = false
					this.orderPrePayVo = res.data.data
					let time = parseInt(this.orderPrePayVo.remainAutoCancleTime / 1000)
					this.orderPrePayVo.remainAutoCancleTime = this.timeString(time)
					this.$set(this.orderPrePayVo, 'remainAutoCancleTime', this.timeString(time))
					this.orderPrePayVo.clearTime = null
					this.orderPrePayVo.clearTime = setInterval(() => {
						time--
						this.orderPrePayVo.remainAutoCancleTime = this.timeString(time)
						if(time <= 0) {
							clearTimeout(this.orderPrePayVo.clearTime)
							this.cancelOrder({
								orderSn: this.orderPrePayVo.sn,
								cancleType: 3
							}) //系统取消
						}
					}, 1000)
					this.form.id = res.data.data.findSellerBankInfoVOs && res.data.data.findSellerBankInfoVOs[0] ? res.data.data.findSellerBankInfoVOs[0].id : null
					this.traderId = this.orderPrePayVo.orderItemPos && this.orderPrePayVo.orderItemPos[0] ? this.orderPrePayVo.orderItemPos[0].sellerId : null
					this.config.value = `${ this.PROJECT.mcbs }/payOrder?traderId=${ this.traderId }&sn=${ this.orderPrePayVo.sn }&sum=${ this.orderPrePayVo.needPayMoney }`;
				}, 1, 10, (res) => {
					this.loading = false
					this.$message({
						type: 'error',
						message: res.data.message,
						duration: 1000
					})
				})
			},
			cancelOrder(body) {
				this.loading = true
				axiosApi('/sales/order/web/cancle', body, (res) => {
					this.loading = false
					// console.log('取消订单', res)
					this.$message({
						type: 'success',
						message: '操作成功！',
						duration: 1000
					})
					this.queryOrderList(this.form)
					this.findSellerJurisdiction()
					this.anonOrderCount()
				}, 1, 10, (res) => {
					this.loading = false
					// console.log('取消订单Error', res)
					this.$message({
						type: 'error',
						message: res.data.message,
						duration: 1000
					})
				})
			},
			beforeAvatarUpload(file) { //上传之前图片的判断
				//				this.imgLoading = true
				const isImage = (file.type.indexOf('png') != -1 || file.type.indexOf('jpeg') != -1);
				const isLt4M = file.size / 1024 / 1024 < 4;
				const isLt0M = file.size / 1024 / 1024 > 0;
				if(!isImage) {
					this.$message.error('请上传格式为png或jpg的图片文件!');
				} else if(!isLt4M) {
					this.$message.error('上传图片大小不能超过 4MB!');
				} else if(!isLt0M) {
					this.$message.error('上传图片大小不能等于 0MB!');
				}
				if(isImage && isLt4M && isLt0M) {
					this.imgLoading = true
				}
				return isImage && isLt4M && isLt0M;
			},
			handlePictureCardPreview(file) { //图片放大
				this.dialogImageUrl = file.url;
				this.dialogVisible = true
			},
			handleAvatarSuccess(res, file) { //长传付款凭证  成功
				this.imgLoading = false
				// console.log(res, file)
				this.form.paymentImg = res.data[0]
				this.$refs.formPayment.validateField('paymentImg');
			},
			paymentDialogSave() { //上传凭证确定保存
				this.$refs.formPayment.validate((valid) => {
					if(valid) {
						this.paymentVoucherVisible = false;
						if(this.fileList.length < 1) {
							this.fileList.push({
								url: this.form.paymentImg
							})
						} else {
							this.fileList[0].url = this.form.paymentImg
						}
					} else {
						return false;
					}
				})
			},
			paymentDialogCancel() {
				this.paymentVoucherVisible = false;
				if(this.fileList.length < 1) {
					this.$refs.formPayment.resetFields(); //重置表单
				}
			}
		}
	}
</script>

<style lang="less">
	.el-message{z-index: 100000;}
	#cloud-pay-order {
		canvas{
			width: 165px !important;
			height: 165px !important;
		}
		.pay-order-content {
			width: 1280px;
			margin: 0 auto;
			padding: 0 45px;
			box-sizing: border-box;
			
			.issue {
				color: #333333;
				&:hover {
					color: #FF4201;
				}
			}
			.content {
				width: 1000px;
				margin: 0 95px;
				.address-info,
				.pay-bank,
				.payment-voucher-info {
					border: 1px solid #DDDDDD;
					box-sizing: border-box;
				}
				.address-info {
					padding: 30px 0;
					li {
						span {
							display: inline-block;
							width: 102px;
							text-align: right;
							margin-right: 10px;
						}
					}
				}
				.pay-bank {
					padding: 30px;
				}
				.payment-voucher-info {
					padding: 30px;
					min-height: 240px;
					.showpaymentImage {
						.el-upload--picture-card {
							display: none;
						}
						.el-icon-delete2 {
							display: none;
						}
						.el-icon-view {
							padding-left: 20px;
						}
					}
					.el-dialog {
						.avatar-uploader-icon {
							width: 240px;
							height: 180px;
							line-height: 180px;
							border: 1px solid #BBBBBB;
						}
						.el-icon-plus:before {
							font-size: 30px;
						}
						.el-upload__tip {
							width: 240px;
							position: relative;
							line-height: 12px;
							/*left: 185px;*/
							min-height: 30px;
						}
						.el-form-item__error {
							top: 181px;
							left: 185px;
						}
					}
				}
			}
		}
	}
</style>