<template>
	<div id="articleDetails">
		<div class="title-top bg-f">
			{{titleTop}}
		</div>
		<div class="main bg-f mt-5 pb-50 pt-30 pl-50">
			<el-form size="large" :model="formArticle" :rules="rules" ref="formArticle" label-width="110px">
				<el-form-item label="文章标题:" prop="title">
					<el-input size="small" class="inputTitle" v-model.trim="formArticle.title" auto-complete="off" placeholder="请输入名称" :maxlength="21"></el-input>
				</el-form-item>
				<el-form-item label="文章图片:" prop="image">
					<div :class="{add:!isAdd,noAdd:isAdd}">
						<el-upload action="/sunrise-gateway/oss/ossUpload" list-type="picture-card" :on-success="handleAvatarSuccessList" :on-preview="handlePictureCardPreview" :file-list="formArticle.image" :on-remove="handleRemove">
							<i class="el-icon-plus"></i>
						</el-upload>
					</div>
				</el-form-item>
				<el-form-item label="文章排序:" prop="sort">
					<el-input size="small" class="inputSort" v-model.trim="formArticle.sort" auto-complete="off" placeholder="请输入排序号" :maxlength="4"></el-input>
				</el-form-item>
				<el-form-item label="所属分类:" prop="sunflowerClassifyId" required>
					<el-select v-model="formArticle.sunflowerClassifyId" placeholder="输入分类" clearable>
						<el-option :label='item.classifyName' :value="item.id" v-for='item in searchClassify' :key='item.id'></el-option>
					</el-select>
				</el-form-item>
				<el-form-item class="ue-editor" label="文章内容" prop="content">
					<ueditor ref="ueeditor" v-model="formArticle.content"></ueditor>
				</el-form-item>
				<el-form-item label=" ">
					<el-button @click="ensure" type="danger" :loading='Loading'>确 定</el-button>
				</el-form-item>
			</el-form>
		</div>
	</div>
</template>

<script>
	import { axiosApi, basePath } from "../../api/api"
	export default {
		data() {
			return {
				addArticle: false, //新建or编辑模态框是否出现
				formInlineData: {
					title: null,
					sunflowerClassifyId: null,
				},
				searchClassify: [],
				Loading: false,
				tableData: [],
				formArticle: {
					content: '',
					image: [],
					sunflowerClassifyId: null
				},
				currentPage: 1,
				total: 0,
				rules: {
					title: [{
						required: true,
						message: '请输入标题'
					}, {
						max: 20,
						message: '请输入20个以内的字符'
					}],
					image: [{
						required: true,
						type: "array",
						message: '请上传图片',
					}],
					sort: [{
							required: true,
							message: '请输入排序号'
						},
						{
							pattern: /^[0-9]\d{0,3}$/,
							message: '排序号必须为数字值,且不能超过4位！'
						}
					],
					sunflowerClassifyId: [{
						required: true,
						message: '请选择分类'
					}],
					content: [{
						required: true,
						message: '请输入文章内容'
					}]
				},
				new: false, //新建or编辑
				image: '',
				dialogVisible: false,
				isAdd: false,
				initsort: null,
				titleTop: '编辑文章',
				Loading:false
			}
		},
		methods: {
			queryclasses(x) {
				axiosApi('/sales/sunflowerclassify/web/selectList', {}, (res) => {
					this.searchClassify = res.data.data.length > 0 ? res.data.data : []  //当没有分类，后台返回的数据没有空数组，也不是null，竟然是个空字符串
					let arr = [] 
					this.searchClassify.forEach(item=>{
						arr.push(item.id)
					})
					if(this.searchClassify.length == 0){
						this.formArticle.sunflowerClassifyId = ''
					}else{
						this.formArticle.sunflowerClassifyId = arr.includes(this.formArticle.sunflowerClassifyId) ? this.formArticle.sunflowerClassifyId : ''
					}
					

				}, x ? x : this.currentPage, 10)
			},
			ensure() { //新建&&更新s
				if(this.$route.query.isNew == 1) { //新建
					setTimeout(() => {
						this.formArticle.content = this.$refs.ueeditor.getContent()
						this.$refs.formArticle.validate((valid) => {
							if(valid) {
								let postData1 = {
									sellerId: sessionStorage.getItem('traderId'),
									sunflowerClassifyId: this.formArticle.sunflowerClassifyId,
									title: this.formArticle.title,
									image: this.formArticle.image[0].url,
									sort: this.formArticle.sort,
									content: this.$refs.ueeditor.getContent()
								}
								axiosApi('/sales/sunflowerarticle/app/insert', postData1, res => {
									this.Loading = true
									setTimeout(() => {
										this.$message({
										type: 'success',
										message: '保存成功！',
										duration: 3000
									});
										this.$router.push({
											path: basePath + '/article'
										})
									}, 400)
								})

							} else {
								return false
							}
						})
					}, 0)
				} else { //编辑更新
					setTimeout(() => {
						this.formArticle.content = this.$refs.ueeditor.getContent()
						this.$refs.formArticle.validate((valid) => {
							if(valid) {
								let postData2 = {
									id: this.formArticle.id,
									sunflowerClassifyId: this.formArticle.sunflowerClassifyId,
									title: this.formArticle.title,
									image: this.formArticle.image[0].url,
									sort: this.formArticle.sort,
									content: this.formArticle.content
								}

								axiosApi('/sales/sunflowerarticle/app/update', postData2, res => {
									this.Loading = true
									setTimeout(() => {
										this.$message({
										type: 'success',
										message: '保存成功！',
										duration: 3000
									});
										this.$router.push({
											path: basePath + '/article'
										})
									}, 400)
								})
							} else {
								return false
							}

						})

					}, 0)
					sessionStorage.removeItem("formArticle");
				}
			},
			handleRemove(file, filelist) { //照片删除
				this.isAdd = false
				this.formArticle.image = [];
				this.$refs.formArticle.validateField("image"); //图片部分表单字段验证
			},
			handlePictureCardPreview(file) { //照片预览
				this.image = file.url;
				this.dialogVisible = true;
			},
			handleAvatarSuccessList(file) { //成功上传照片
				this.formArticle.image.push({
					url: file.data[0]
				})
				if(this.formArticle.image.length != 0) {
					this.isAdd = true;
				}
				// console.log(this.formArticle.image,"this.formArticle.image")
				this.$refs.formArticle.validateField("image"); //图片部分表单字段验证

			},
		},
		mounted() {
			this.$nextTick(() => {
				if(this.$route.query.isNew == 0) {
					this.formArticle = JSON.parse(sessionStorage.formArticle)
					this.isAdd = true
				} else if(sessionStorage.formArticle) {
					sessionStorage.removeItem("formArticle");
				}
				this.queryclasses(1)
				this.titleTop = this.$route.query.titleTop
			})
		},
		computed: {

		}
	}
</script>

<style lang="less">
	#articleDetails {
        .el-input__suffix{
            right: 30px;
        }
		.noAdd {
			.el-upload.el-upload--picture-card {
				display: none;
			}
		}
		.add {
			.el-upload.el-upload--picture-card {
				display: inline-block;
			}
		}
		.ue-editor {
			transform: translateX(0);
		}
	}
</style>