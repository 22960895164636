import {axiosApi} from '../../api/api.js'

const sendGoodsManager = {
    state : {
        goodsList:[],//商品列表
        total:0,
        tableloading:false
    },

    actions : {
        getGoodsList({state},payload){
            let body = {
                auditState: payload.auditState,
                checkGift: payload.checkGift,
                classifyId: payload.classifyId,
                goodsNo: payload.goodsNo,
                goodsState: payload.goodsState,
                sellerId: payload.sellerId,
                skuName:payload.skuName,
            };
            state.tableloading = true;
            axiosApi('/groupon/bigStore/selectList',body,res => {
                state.tableloading = false;
                state.goodsList = res.data.data.elements;
                state.total = res.data.data.totalCount;
            },payload.currentPage ,10,err => {
                state.tableloading = false;
            })
        }
    },

    mutations : {

    },

    getters :{

    }
}

export default sendGoodsManager;