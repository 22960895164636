<template>
  <div class="bg-f">
    <div id="store-confirm-order" class="clearfix">
      <div class="logo1 bbd mb-30">
        <router-link :to="basePath + '/shop/shopIndex'">
          <img class="cursor fl" src="../../assets/logo-r.png" />
        </router-link>
        <div class="mt-30 fr">
          <div class="order-state"></div>
          <div class="mt-10 font-16 order-state-info">
            <span>拍下商品</span><span class="ml-107 color-9">下单成功</span
            ><span class="ml-107 color-9">付款成功</span>
          </div>
        </div>
      </div>

      <div v-loading="loading" class="clearfix">
        <div class="address mb-20">
          <div class="title clearfix">
            <div class="fl font-16">收货地址</div>
            <div class="ml-30 fl lh-21">
              <span v-text="receivingAddress.linkman"></span>
              <span class="ml-30" v-text="receivingAddress.tel"></span>
              <!--<span class="background-3 color-white ml-30"-->
              <!--v-if='receivingAddress.addressType==1&&!isHotel'>营业地址</span>-->
              <!--<span class="background-3 color-white ml-30"-->
              <!--v-if='receivingAddress.addressType==2&&!isHotel'>收货地址</span>-->
              <!--<span class="background-3 color-white ml-30"-->
              <!--v-if='receivingAddress.addressType==3&&!isHotel'>联系地址</span>-->
              <span class="ml-30">
                <span v-text="receivingAddress.provinceName"></span>
                <span v-text="receivingAddress.cityName"></span>
                <span v-text="receivingAddress.regionName"></span>
                <span v-text="receivingAddress.streetName"></span>
                <span v-if="isHotel">{{ receivingAddress.addr }}</span>
                <span v-else>{{ receivingAddress.addr }}</span>
              </span>
              <div class="mt-20 mb-20">
                <el-button
                  type="text"
                  class="textBtn"
                  @click="changeAddressVisible = true"
                  v-if="addressPosList.length > 0"
                  >更多地址
                </el-button>
              </div>
            </div>
            <div class="fr addressClass">
              <span
                @click="addressDialog = true"
                class="address-btn cursor color-danger"
                v-if="(addressPosList.length < 30 && isHotel) || !isHotel"
                >添加收货地址</span
              >
              <el-dialog
                title="添加收货地址"
                :close-on-click-modal="false"
                :visible.sync="addressDialog"
                class="addressDialog"
                @close="resetForm"
              >
                <div class="dialog-content">
                  <el-form
                    :model="addressForm"
                    ref="addressForm"
                    :rules="rules"
                    label-width="150px"
                  >
                    <el-form-item label="收货人:" prop="linkman">
                      <el-input
                        placeholder="请输入收货人姓名"
                        v-model.trim="addressForm.linkman"
                      ></el-input>
                    </el-form-item>
                    <el-form-item label="联系电话:" prop="tel">
                      <el-input
                        placeholder="请输入联系电话"
                        :maxlength="11"
                        v-model.trim="addressForm.tel"
                      ></el-input>
                    </el-form-item>
                    <el-form-item
                      label="地址:"
                      class="clearfix"
                      prop="selectedOptions"
                      required
                    >
                      <selectArea
                        style="width:300px"
                        v-model="addressForm.selectedOptions"
                        @changeName="changeName"
                      />
                    </el-form-item>
                    <el-form-item label="详细地址" prop="addr">
                      <el-input
                        placeholder="请输入详细地址"
                        v-model.trim="addressForm.addr"
                        class="fl attr"
                      ></el-input>
                    </el-form-item>
                    <el-form-item label="是否设为默认:">
                      <el-checkbox v-model="isDefault">设为默认</el-checkbox>
                    </el-form-item>
                  </el-form>
                </div>
                <div slot="footer" class="dialog-footer">
                  <el-button type="danger" @click="handleAddressSave"
                    >确 定</el-button
                  >
                </div>
              </el-dialog>
            </div>
          </div>

          <!--更换地址弹出框-->
          <el-dialog
            title="更换地址"
            class="changeAddress"
            :visible.sync="changeAddressVisible"
            size="tiny"
          >
            <div class="ml-50">
              <div
                class="ml-100 mb-20"
                v-for="(item, index) in addressPosList"
                :key="index"
              >
                <el-radio
                  :disabled="item.state != 2 && item.state != null"
                  v-model="addressRadio"
                  :label="item"
                  class="ml-0"
                >
                  <span v-text="item.linkman"></span>
                  <span
                    v-text="item.tel"
                    :class="{
                      'color-9': item.state != 2 && item.state != null,
                    }"
                  ></span>
                  <!--<span class="background-3 color-white ml-30" v-if='item.addressType==4&&!isHotel'>营业地址</span>-->
                  <!--<span class="background-3 color-white ml-30" v-if='item.addressType==3&&!isHotel'>收货地址</span>-->
                  <div
                    class="ml-27"
                    :class="{
                      'color-9': item.state != 2 && item.state != null,
                    }"
                  >
                    <span v-text="item.provinceName"></span>
                    <span v-text="item.cityName"></span>
                    <span v-text="item.regionName"></span>
                    <span v-text="item.streetName"></span>
                    <span v-if="isHotel">{{ item.addr }}</span>
                    <span v-else>{{ item.addr }}</span>
                  </div>
                  <span
                    v-if="item.state != 2 && item.state != null"
                    class="color-danger"
                    >审核中，暂不支持更换</span
                  >
                  <el-button
                    :disabled="item.state != 2 && item.state != null"
                    @click="handleUpdateAddress(item.id)"
                    type="text"
                    class="ml-27 mb-20"
                    v-if="
                      item.isDefault == 0 &&
                      (item.state == 2 || item.state == null)
                    "
                    >设为默认
                  </el-button>
                </el-radio>
              </div>
            </div>
            <div slot="footer" class="dialog-footer">
              <el-button type="danger" @click="handleChangeAddress"
                >确 定</el-button
              >
              <el-button @click="changeAddressVisible = false">取 消</el-button>
            </div>
          </el-dialog>
        </div>
        <div class="order-info clearfix">
          <div class="title fl mr-30">
            <div class="font-16">订单详情</div>
          </div>
          <div class="order-content fl ml-0">
            <div
              class="item mb-50"
              v-for="(cartItem, index) in cartLiExPo"
              :key="index"
            >
              <!-- <span class="font-16 color-6 mb-10 inline-block">商家：{{cartItem.sellerName}}</span> -->
              <span class="font-16 color-6 mb-10 inline-block"
                >区域名称: {{ cartLiExPo[0].sellerName }}</span
              >
              <ul class="table-title clearfix">
                <li class="t1">货品信息</li>
                <li class="t2 tac">单价</li>
                <!-- <li class="t3 tac">折扣/固定返利</li> -->
                <li class="t4 tac">数量</li>
                <li class="t5">小计</li>
                <li class="t5 tac">预估积分</li>
              </ul>
              <div class="item-content mb-20">
                <div
                  class="clearfix lh-32 pl-20 will_buy_activity"
                  v-if="haswillbuylist.length > 0"
                >
                  <div class="fl change_buy mr-20">换购</div>
                  <div class="fl">满足换购活动，可享受换购优惠</div>
                  <el-button
                    @click="gotobuy"
                    type="text"
                    class="fl color-danger change_buy_btn"
                    >去换购 <i class="el-icon-arrow-right"></i
                  ></el-button>
                </div>
                <div
                  class="clearfix lh-32 pl-20 will_buy_activity"
                  v-if="hasGiftList.length > 1 && !isFull"
                >
                  <div class="fl change_buy mr-20">满赠</div>
                  <div class="fl">满足满赠活动，可享受满赠优惠</div>
                  <el-button
                    @click="togift"
                    type="text"
                    class="fl color-danger change_buy_btn"
                    v-if="isShowGift"
                  >
                    选赠品 <i class="el-icon-arrow-right"></i
                  ></el-button>
                </div>
                <!--商品-->
                <div
                  class="item-item flex"
                  v-for="(skuItem, skuIndex) in cartItem.cartExPos"
                  :key="skuIndex+'--'+skuItem.skuNo"
                >
                  <div class="order-item-item flex gift pl-20 pt-20 pb-20">
                    <div
                      v-if="skuItem.isNeedPay != undefined"
                      class="triangle-topleft border-red"
                    ></div>
                    <span
                      v-if="skuItem.isNeedPay != undefined"
                      class="init default"
                      >换购</span
                    >
                    <div>
                      <img
                        v-if="skuItem.fileUrl"
                        class="fl img mr-10"
                        :src="skuItem.fileUrl"
                      />
                      <img
                        v-else
                        src="../../assets/goods.jpg"
                        class="img fl mr-10"
                        alt=""
                      />
                    </div>
                    <div class="flex-1">
                      <p class="ellipsis-2" v-text="skuItem.skuName"></p>
                      <p class="color-9">规格: 1*{{ skuItem.specInfoNum }}</p>
                    </div>
                  </div>
                  <div class="order-item-item-2 pt-20 tac">
                    <!-- 如果是酒店用户展示会员价 -->
                    <!-- <p v-if="isHotel&&skuItem.isNeedPay==undefined" v-cloak>
                                            ￥{{skuItem.showUnitPromotionPrice}}/{{skuItem.showUnitName}}</p>
                                        <p v-else v-cloak>￥{{skuItem.showUnitPrice}}/{{skuItem.showUnitName}}</p> -->
                    <p v-cloak>
                      ￥{{ skuItem.price }}/{{ skuItem.salesUnitName }}
                    </p>
                  </div>
                  <!--折扣返利-->
                  <!-- <div v-if="isHotel" class="order-item-item-3 fl pt-20 tac">
                                        <p v-if="skuItem.isNeedPay==undefined" v-cloak>-￥{{skuItem.discount}}/{{skuItem.salesUnitName}}</p>
                    <p v-else v-cloak>-￥0/{{skuItem.salesUnitName}}</p>
                                    </div> -->
                  <div class="order-item-item-3 pt-20 tac">
                    <p>
                      <span>{{ skuItem.num }}</span
                      ><span>{{ skuItem.salesUnitName }}</span>
                      <!-- <span>{{skuItem.num}}</span><span>{{skuItem.salesUnitName}}</span> -->
                      <!-- skuItem.isNeedPay是换购必购的 不显示 -->
                      <span
                        v-if="
                          skuItem.singleNum !== skuItem.num &&
                          skuItem.isNeedPay == undefined
                        "
                        >({{ skuItem.singleNum
                        }}{{ skuItem.baseUnitName }})</span
                      >
                    </p>
                  </div>
                  <div class="pt-20 tac" style="width: 150px">
                    <p class="color-F656" v-cloak>
                      ￥{{ (skuItem.price * skuItem.num) | fmoney }}
                    </p>
                  </div>
                  <div class="pt-20 tac">
                    <p class="color-F656" v-cloak>
                      {{ skuItem.goodsIntegration || 0 }}
                    </p>
                  </div>
          
                </div>
                <!--赠品-->
                <div
                  class="item-item clearfix"
                  v-for="(item, index) in giftExs"
                  :key="index+'--'+item.skuNo"
                >
                  <div
                    class="clearfix order-item-item fl gift pl-20 pt-20 pb-20"
                  >
                    <div class="triangle-topleft border-red"></div>
                    <span class="init default">赠品</span>
                    <img v-if="item.url" class="fl img mr-10" :src="item.url" />
                    <img
                      v-else
                      src="../../assets/goods.jpg"
                      class="img fl mr-10"
                      alt=""
                    />
                    <div
                      class="fl"
                      :class="{
                        'color-9':
                          item.shortStock && !deliverySeparate && !openStraight,
                      }"
                    >
                      <p>
                        {{ item.giftSkuName }}
                      </p>
                      <p class="color-9" v-if="item.specInfoNum">
                        规格: 1*{{ item.specInfoNum }}
                      </p>
                    </div>
                  </div>
                  <div
                    class="order-item-item-2 fl pt-20 tac"
                    :class="{
                      'color-9':
                        item.shortStock && !deliverySeparate && !openStraight,
                    }"
                  >
                    <p v-cloak class="giftPriceClass">￥{{ item.giftPrice | fmoney }}</p>
                  </div>
                  <!-- <div v-if="isHotel" class="order-item-item-3 fl pt-20 tac">
                                        <p >-￥0/{{item.salesUnitName}}</p>
                                    </div> -->
                  <div class="order-item-item-3 fl pt-20 tac">
                    <p
                      v-if="
                        item.shortStock && !deliverySeparate && !openStraight
                      "
                      class="color-9"
                    >
                      库存不足无法赠送
                    </p>
                    <p v-else>{{ item.quantity }}{{ item.salesUnitName }}</p>
                  </div>
                  <div
                    class="fl pt-20 tac"
                    style="width: 150px"
                    :class="{
                      'color-9':
                        item.shortStock && !deliverySeparate && !openStraight,
                    }"
                  >
                    <p
                      :class="
                        item.shortStock && !deliverySeparate && !openStraight
                          ? 'color-9'
                          : 'color-F656'
                      "
                      v-cloak
                    >
                      ￥0
                    </p>
                  </div>
                  <div class="fl pt-20 tac">
                    <p class="color-F656" v-cloak>0</p>
                  </div>
                </div>
                <div
                  v-if="haswillbuylist.length > 0"
                  class="color-9 font-12 mt-10"
                >
                  提示：换购商品的金额不计入满减优惠
                </div>
              </div>

              <div class="message mr-0">
                <div class="font-16 color-6 mb-10">给卖家留言</div>
                <div class="clearfix">
                  <div class="fl">
                    <el-input
                      class="input1"
                      :maxlength="50"
                      placeholder="请输入备注内容"
                      v-model.trim="remark"
                      style="margin-bottom: 10px"
                    ></el-input>
                    <multipleImgUpload
                      :width="90"
                      :height="90"
                      :dialogImageUrlList="fileList"
                      :max="3"
                      @cloneDialogImageUrlList="cloneDialogImageUrlList"
                      :preview="true"
                    >
                    </multipleImgUpload>
                  </div>
                  <ul class="fr">
                    <li
                      class="tal mb-10 clearfix"
                      v-if="
                        cartItem.couponExPos && cartItem.couponExPos.length > 0
                      "
                    >
                      店铺优惠:
                      <el-select
                        size="small"
                        v-model="couponItem"
                        clearable
                        @change="handleCouponChange"
                        value-key="id"
                      >
                        <el-option
                          v-for="(
                            couponItem, couponIndex
                          ) in cartItem.couponExPos"
                          :key="couponIndex"
                          :value="couponItem"
                          :label="couponItem.couponName"
                        ></el-option>
                      </el-select>
                      <span class="fr ml-30 lh-32" v-cloak
                        >-￥{{ couponItem.minusMoney || 0 }}</span
                      >
                    </li>
                    <li class="tar mb-10 clearfix">
                      <span class="fl tal"> 运费：</span
                      ><span class="ml-30 fr"
                        >+￥{{
                          cartItem.freight ? cartItem.freight : 0 | fmoney
                        }}</span
                      >
                    </li>
                    <li class="tar mb-10 clearfix">
                      <span class="fl tal">满减金额</span
                      ><span class="ml-30 fr"
                        >-￥{{ cartItem.cutPrice | fmoney }}</span
                      >
                    </li>
                    <li class="tar mb-10 clearfix">
                      <span class="fl tal">优惠券金额</span
                      ><span class="ml-30 fr"
                        >-￥{{ couponItem.minusMoney | fmoney }}</span
                      >
                    </li>
                    <li class="tar mb-10 clearfix">
                      <span class="fl tal">折扣返利：</span
                      ><span class="ml-30 fr"
                        >-￥{{ cartItem.discountCutMonney | fmoney }}</span
                      >
                    </li>
                    <li class="payment tar clearfix">
                      <span class="fl tal">店铺合计：</span>
                      <span class="ml-30 fr"
                        >￥
                        {{
                          totalPrice - couponItem.minusMoney + freight ||
                          0 | fmoney
                        }}</span
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--发票信息-->
        <div class="delivery clearfix">
          <div class="delivery-title fl">
            <div class="font-16">发票信息</div>
          </div>
          <div class="delivery-content fl">
            <div class="clearfix">
              <el-checkbox
                class="mb-20 fl"
                v-model="invoiceChecked"
                @change="handleCheckInvo"
                label="需要发票"
              ></el-checkbox>
              <el-button
                type="text"
                class="ml-10 fl"
                v-if="
                  invoiceChecked &&
                  Object.keys(invoiceInfoVOs1).length == 0 &&
                  Object.keys(invoiceInfoVOs2).length == 0
                "
                @click="handleChangeInvoice"
                >添加
              </el-button>
              <el-button
                type="text"
                class="ml-10 fl"
                v-if="
                  invoiceChecked &&
                  (Object.keys(invoiceInfoVOs1).length > 0 ||
                    Object.keys(invoiceInfoVOs2).length > 0)
                "
                @click="handleChangeInvoice"
                >修 改
              </el-button>
            </div>
            <!--  -->
            <div class="" v-if="invoiceChecked">
              <!--  -->
              <div
                class="color-9 invoice mb-30"
                v-if="
                  Object.keys(invoiceInfoVOs1).length > 0 &&
                  invoiceSave1 == true
                "
              >
                <div class="mb-10">普通发票</div>
                <div class="mb-10">
                  <span>发票抬头:</span>
                  <span v-text="invoiceInfoVOs1.companyHead"></span>
                </div>
                <div class="mb-10">
                  <span>纳税人识别号:</span>
                  <span v-text="invoiceInfoVOs1.taxpayerNumber"></span>
                </div>
                <div class="mb-10">
                  <span>收票人:</span>
                  <span v-text="invoiceInfoVOs1.linkman"></span>
                </div>
                <div class="mb-10">
                  <span>手机号:</span>
                  <span v-text="invoiceInfoVOs1.sendTel"></span>
                </div>
                <div class="mb-10">
                  <span>收票地址:</span>
                  <span>{{
                    invoiceInfoVOs1.provinceName +
                    " " +
                    invoiceInfoVOs1.cityName +
                    " " +
                    invoiceInfoVOs1.regionName +
                    " " +
                    invoiceInfoVOs1.addr
                  }}</span>
                </div>
              </div>
              <!--  -->
              <div
                class="color-9 invoice mb-30"
                v-if="
                  Object.keys(invoiceInfoVOs2).length > 0 &&
                  invoiceSave2 == true
                "
              >
                <div class="mb-10">
                  <span>增值税发票</span>
                </div>
                <div class="mb-10">
                  <span>公司名称:</span>
                  <span v-text="invoiceInfoVOs2.companyName"></span>
                </div>
                <div class="mb-10">
                  <span>纳税人识别号:</span>
                  <span v-text="invoiceInfoVOs2.taxpayerNumber"></span>
                </div>
                <div class="mb-10">
                  <span>注册地址:</span>
                  <span v-text="invoiceInfoVOs2.address"></span>
                </div>
                <div class="mb-10">
                  <span>注册电话:</span>
                  <span v-text="invoiceInfoVOs2.tel"></span>
                </div>
                <div class="mb-10">
                  <span>开户银行:</span>
                  <span v-text="invoiceInfoVOs2.bankName"></span>
                </div>
                <div class="mb-10">
                  <span>银行账号:</span>
                  <span v-text="invoiceInfoVOs2.bankCard"></span>
                </div>
                <div class="mb-10">
                  <span>收票人:</span>
                  <span v-text="invoiceInfoVOs2.linkman"></span>
                </div>
                <div class="mb-10">
                  <span>手机号:</span>
                  <span v-text="invoiceInfoVOs2.sendTel"></span>
                </div>
                <div class="mb-10">
                  <span>收票地址:</span>
                  <span>{{
                    invoiceInfoVOs2.provinceName +
                    " " +
                    invoiceInfoVOs2.cityName +
                    " " +
                    invoiceInfoVOs2.regionName +
                    " " +
                    invoiceInfoVOs2.addr
                  }}</span>
                </div>
              </div>
            </div>
            <el-dialog
              title="发票信息"
              :visible.sync="dialogVisible"
              :close-on-click-modal="false"
              width="700px"
              class="billDialog"
              @close="handleCloseDialog"
            >
              <div class="bill-type">
                <el-radio-group v-model="radio">
                  <el-radio label="1">普通发票</el-radio>
                  <el-radio label="2">增值税发票</el-radio>
                </el-radio-group>
              </div>
              <!--
                                作者：317219686@qq.com
                                时间：2017-10-31
                                描述：普通发票
                            -->
              <el-form
                v-show="radio == '1'"
                ref="invoiceInfoVOs1Form"
                :model="invoiceInfoVOs1Form"
                :rules="rules"
                label-width="245px"
              >
                <el-form-item label="发票抬头" prop="item">
                  <el-input
                    v-model.trim="invoiceInfoVOs1Form.item"
                    placeholder="输入发票抬头"
                  ></el-input>
                </el-form-item>
                <el-form-item
                  label="纳税人识别号"
                  required
                  prop="taxpayerNumber1"
                >
                  <el-input
                    v-model.trim="invoiceInfoVOs1Form.taxpayerNumber1"
                    placeholder="输入15-20位纳税人识别号"
                  ></el-input>
                </el-form-item>
              </el-form>
              <!--
                                作者：317219686@qq.com
                                时间：2017-10-31
                                描述：增值税发票
                            -->
              <el-form
                v-show="radio == '2'"
                :model="invoiceInfoVOs2Form"
                ref="invoiceInfoVOs2Form"
                :rules="rules"
                label-width="245px"
              >
                <el-form-item label="公司名称" required prop="companyName1">
                  <el-input
                    v-model.trim="invoiceInfoVOs2Form.companyName1"
                    placeholder="输入公司名称"
                  ></el-input>
                </el-form-item>
                <el-form-item
                  label="纳税人识别号"
                  required
                  prop="taxpayerNumber1"
                >
                  <el-input
                    v-model.trim="invoiceInfoVOs2Form.taxpayerNumber1"
                    placeholder="输入15-20位纳税人识别号"
                  ></el-input>
                </el-form-item>
                <el-form-item label="注册地址" required prop="address1">
                  <el-input
                    v-model.trim="invoiceInfoVOs2Form.address1"
                    placeholder="输入注册地址"
                  ></el-input>
                </el-form-item>
                <el-form-item label="注册电话" required prop="tel1">
                  <el-input
                    v-model.trim="invoiceInfoVOs2Form.tel1"
                    placeholder="输入注册电话"
                  ></el-input>
                </el-form-item>
                <el-form-item label="开户银行" required prop="bankName1">
                  <el-input
                    v-model.trim="invoiceInfoVOs2Form.bankName1"
                    placeholder="输入开户银行"
                  ></el-input>
                </el-form-item>
                <el-form-item label="银行账号" required prop="bankCard1">
                  <el-input
                    v-model.trim="invoiceInfoVOs2Form.bankCard1"
                    placeholder="输入银行账号"
                  ></el-input>
                </el-form-item>
              </el-form>
              <!--
                                作者：317219686@qq.com
                                时间：2017-11-01
                                描述：发票寄送地址
                            -->
              <div class="send-info">收票信息</div>
              <el-form
                :model="sendInvoForm"
                ref="sendInvoForm"
                :rules="rules"
                label-width="245px"
              >
                <el-form-item label="收票人" prop="linkman">
                  <el-input
                    v-model.trim="sendInvoForm.linkman"
                    :maxlength="32"
                    placeholder="请输入收票人"
                  ></el-input>
                </el-form-item>
                <el-form-item label="联系电话" prop="sendTel">
                  <el-input
                    v-model.trim="sendInvoForm.sendTel"
                    placeholder="请输入联系电话"
                  ></el-input>
                </el-form-item>
                <el-form-item label="收票地址" class="mb-10" required>
                  <el-checkbox v-model="attrChecked" @change="handleCheckAddr"
                    >同收货地址</el-checkbox
                  >
                </el-form-item>
                <el-form-item label=" " class="mb-10" v-if="!attrChecked">
                  <div class="w240 fl lh-14">
                    温馨提示：发票寄送地址与收货地址不同可能会产生新的物流费用，请确认后使用
                  </div>
                </el-form-item>
                <el-form-item
                  label=" "
                  class="mb-30 noLabelSign"
                  v-show="!attrChecked"
                  prop="selectedOptions"
                  :rules='[{required: true,type: "array",message: "请选择地址",trigger: "change",}]'
                >
                 <cityCascaderBox v-model='sendInvoForm.selectedOptions' :num="3" :showAllLevels="true" :checkStrictly="false" :emitPath="true" @changeName="setName"/>
                </el-form-item>
                <el-form-item
                  label=" "
                  v-if="!attrChecked"
                  class="pb-20 noLabelSign"
                  prop="addr"
                >
                  <el-input
                    v-model.trim="sendInvoForm.addr"
                    placeholder="请输入详细地址"
                  ></el-input>
                </el-form-item>
              </el-form>
              <div slot="footer" class="dialog-footer">
                <el-button type="danger" @click="handleInvoiceSave"
                  >确 定</el-button
                >
              </div>
            </el-dialog>
          </div>
        </div>

        <!--分批提货-->
        <div v-if="preOrderInfo.openStraight" class="delivery clearfix">
          <div class="delivery-title fl">
            <div class="font-16">商品直发</div>
          </div>
          <div class="delivery-content fl pb-20">
            <el-checkbox
              v-model="openStraight"
              label="需要商品直发"
            ></el-checkbox>
          </div>
        </div>

        <!--分批提货-->
        <div v-if="preOrderInfo.deliverySeparate" class="delivery clearfix">
          <div class="delivery-title fl">
            <div class="font-16">分批提货</div>
          </div>
          <div class="delivery-content fl pb-20">
            <div>
              <el-checkbox
                v-model="deliverySeparate"
                @change="deliverySeparateChange"
                label="需要分批提货"
              ></el-checkbox>
            </div>
            <div class="mt-10 ml-5">
              <span class="font-12" style="color: red"
                >注：设置分批提货后，买家可以在订单中分批提货</span
              >
            </div>
            <!--<div v-if="deliverySeparate" class="mt-10">
                            <span class="mr-10">设置首次提货</span>
                            <el-radio v-model="oneDeliverySeparate" label="1">是</el-radio>
                            <el-radio v-model="oneDeliverySeparate" label="2">否</el-radio>
                        </div>-->
            <el-dialog
              title="订单提货"
              :visible.sync="goodsListDialogFalse"
              :show-close="false"
              :close-on-press-escape="false"
              :close-on-click-modal="false"
              class="dialog-w1000"
            >
              <consignmentGoods :goodsList="sendGoodsForm.deliveryItemList" />
              <!-- <el-form :model="sendGoodsForm" class="mt-20" label-width="80px">
                                <el-form-item label='提货时间' prop="gmtDelivery" :rules="{ required: true, message: '请选择提货时间' }">
                                    <el-date-picker
                                        v-model="sendGoodsForm.gmtDelivery"
                                        type="date"
                                        placeholder="选择日期"
                                        :picker-options="pickerOptions0"
                                        :clearable="false">
                                    </el-date-picker>
                                </el-form-item>
                            </el-form> -->
              <div slot="footer" class="dialog-footer">
                <el-button @click="goodsListDialogClose" class="ml-50"
                  >取 消</el-button
                >
                <el-button type="danger" @click="goodsListDialogSave"
                  >确 定</el-button
                >
              </div>
            </el-dialog>
          </div>
        </div>

        <!--佣金抵扣-->
        <div class="delivery clearfix">
          <div class="delivery-title fl">
            <div class="font-16">佣金抵扣</div>
          </div>
          <div class="delivery-content fl pb-20">
            <div>
              <el-checkbox
                v-model="isUseDeduction"
                :disabled="preOrderInfo.deductionCommission === 0"
                >可抵扣佣金 （￥{{
                  preOrderInfo.deductionCommission
                }}）</el-checkbox
              >
            </div>
          </div>
        </div>

        <!-- 订单信息 -->
        <div class="total clearfix">
          <ul class="fr">
            <li class="tar mb-10 clearfix">
              <span class="fl tal">
                <span class="color-danger">{{ count + needPay }}</span>
                件商品总额：
              </span>
              <span class="ml-30 fr">￥ {{ srcTotalPrice || 0 | fmoney }}</span>
            </li>
            <li class="tar mb-10 clearfix">
              <span class="fl tal">预估积分</span
              ><span class="ml-30 fr"
                >{{ predictiveIntegral || 0 | fmoney }}积分</span
              >
            </li>
            <li class="tar mb-10 clearfix">
              <span class="fl tal">满减金额</span
              ><span class="ml-30 fr">-￥{{ cutPrice | fmoney }}</span>
            </li>
            <li class="tar mb-10 clearfix">
              <span class="fl tal">优惠券金额</span
              ><span class="ml-30 fr"
                >-￥{{ couponItem.minusMoney | fmoney }}</span
              >
            </li>
            <li class="tar mb-10 clearfix">
              <span class="fl tal">折扣返利：</span>
              <span class="ml-30 fr"
                >-￥ {{ discountCutMonney || 0 | fmoney }}</span
              >
            </li>
            <li class="tar mb-10 clearfix">
              <span class="fl tal">运费：</span>
              <span class="ml-30 fr"
                >+￥ {{ freight ? freight : 0 | fmoney }}</span
              >
            </li>
            <li class="tar mb-10 clearfix" v-if="isUseDeduction">
              <span class="fl tal">抵货佣金：</span>
              <span class="ml-30 fr"
                >-￥ {{ preOrderInfo.usedDeductionCommission.toFixed(2) }}</span
              >
            </li>
            <li class="font-20 mb-10 payment tar clearfix">
              <span class="fl tal">支付金额：</span>
              <span class='ml-30 fr'> 
                  <span v-if="isUseDeduction">￥ {{totalPrice - couponItem.minusMoney - preOrderInfo.usedDeductionCommission + freight||0 | fmoney}}</span>
                  <span v-else>￥ {{totalPrice - couponItem.minusMoney + freight||0 | fmoney}}</span>
                  <br/>
                  <span v-if="giftExs.length>0" class="font-12" style="color: red;">（赠品不参与计算）</span>
              </span> 
            </li>
          </ul>
        </div>
        <el-button
          class="orderCommit fr"
          type="danger"
          :disabled="minimunMoqDisabled"
          @click="isChangeInvoice"
          >{{ minimunMoqString ? minimunMoqString : "提交订单" }}</el-button
        >
      </div>
    </div>
    <!-- 选择赠品组合 -->
    <el-dialog
      title="选择赠品组合"
      :visible.sync="dialogGiftVisible"
      class="gift_team_dialog will_buy_dialog"
      @close="handleCloseGift"
    >
      <div class="content">
        <el-tabs v-model="giftActiveName" @tab-click="handleGiftTabClick">
          <el-tab-pane
            v-for="(item, index) in fullGiftList"
            :key="index"
            :label="item.activityName"
            :name="index + ''"
          ></el-tab-pane>
        </el-tabs>
        <div class="lh-32 color-danger pl-20">
          {{ giftRuleDescriptionText }}
        </div>
        <!-- 自定义商品table title -->
        <div class="customer_table_title clearfix">
          <el-checkbox class="fl ml-20" style="opacity: 0"></el-checkbox>
          <div class="product fl cell">商品</div>
          <div class="money fl cell">金额</div>
          <div class="num fl cell">数量</div>
        </div>
        <div class="customer_table_body">
          <el-radio-group v-model="radioCheck" @change="handleGiftCheckChange">
            <div
              class="clearfix cell"
              style="position: relative"
              v-for="(n, i) in willGiftTableData"
              :key="i"
            >
              <div style="position: absolute; top: 10px; left: 20px">
                <el-radio
                  class="checked"
                  :label="i"
                  :disabled="n.shortStock && !deliverySeparate && !openStraight"
                ></el-radio>
              </div>
              <div class="clearfix fl">
                <div v-for="(a, b) in n.giftExs" :key="b" class="clearfix">
                  <div class="clearfix fl item_1 cell_cell ml-38">
                    <img
                      v-if="a.image"
                      class="fl w-60 mr-10"
                      :src="a.image"
                      alt=""
                    />
                    <img
                      v-else
                      class="fl w-60 mr-10"
                      src="../../assets/goods.jpg"
                      alt=""
                    />
                    <div
                      class="fl"
                      :class="{
                        'color-9':
                          n.shortStock && !deliverySeparate && !openStraight,
                      }"
                    >
                      <p>{{ a.giftSkuName }}</p>
                      <p class="color-9">规格: 1*{{ a.specInfoNum }}</p>
                    </div>
                  </div>
                  <div
                    class="fl item_2 cell_cell"
                    :class="{
                      'color-9':
                        n.shortStock && !deliverySeparate && !openStraight,
                    }"
                  >
                    ¥{{ a.unitAmount | fmoney }}
                  </div>
                  <div
                    class="fl item_3 cell_cell"
                    :class="{
                      'color-9':
                        n.shortStock && !deliverySeparate && !openStraight,
                    }"
                  >
                    {{ a.quantity }}{{ a.salesUnitName }}
                  </div>
                </div>
              </div>
            </div>
          </el-radio-group>
        </div>

        <div class="mt-20">
          已选<span class="color-danger">{{ giftCheckNum }}</span
          >件
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="confirmGift">确 定</el-button>
        <el-button
          @click="
            dialogGiftVisible = false;
            checkGiftGood = [];
          "
          >取 消</el-button
        >
      </span>
    </el-dialog>

    <!-- 选择换购商品 -->
    <el-dialog
      title="选择换购商品"
      :visible.sync="dialogwillBuyVisible"
      class="will_buy_dialog"
      @close="handleClosewillBuy"
    >
      <div class="content">
        <el-tabs v-model="activeName" @tab-click="handlewillbuytabClick">
          <el-tab-pane
            v-for="(item, index) in fullExchangeList"
            v-if="item.changePurchaseGoodsVoList.length > 0"
            :key="index"
            :label="item.activityName"
            :name="index + ''"
          ></el-tab-pane>
        </el-tabs>
        <div class="lh-32 color-danger pl-20">
          {{ ruleDescriptionText }}
        </div>
        <!-- 自定义商品table title -->
        <div class="customer_table_title clearfix">
          <el-checkbox
            class="fl ml-20"
            :indeterminate="isIndeterminate"
            v-model="checkAll"
            @change="handleCheckAllChange"
          ></el-checkbox>
          <div class="product fl cell">商品</div>
          <div class="money fl cell">金额</div>
          <div class="num fl cell">数量</div>
        </div>
        <div class="customer_table_body">
          <el-checkbox-group v-model="checkgood" @change="handleCheckChange">
            <div
              class="clearfix cell font-14"
              v-for="(n, i) in willbuytableData"
              :key="i"
            >
              <div class="fl ml-20 pt-10">
                <el-checkbox class="checked" :label="n"></el-checkbox>
              </div>
              <div class="clearfix fl item_1 cell_cell">
                <img
                  v-if="n.image"
                  class="fl w-60 mr-10"
                  :src="n.image"
                  alt=""
                />
                <img
                  v-else
                  class="fl w-60 mr-10"
                  src="../../assets/goods.jpg"
                  alt=""
                />
                <div class="fl">
                  <p>{{ n.skuName }}</p>
                  <p class="color-9">规格: 1*{{ n.specInfoNum }}</p>
                </div>
              </div>
              <div class="fl item_2 cell_cell">
                ¥{{ n.unitAmount | fmoney }}
              </div>
              <div class="fl item_3 cell_cell">
                {{ n.num }}{{ n.salesUnitName }}
              </div>
            </div>
          </el-checkbox-group>
        </div>

        <div class="mt-20">
          已选<span class="color-danger">{{ willbuycheckNum }}</span
          >件， 最多选择<span class="color-danger">{{
            maxwillbuycheckNum
          }}</span
          >件 共计<span class="color-danger">¥{{ willbuymoney }}</span>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="confirmWillBuy">确 定</el-button>
        <el-button
          @click="
            dialogwillBuyVisible = false;
            checkgood = [];
          "
          >取 消</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { axiosApi, basePath } from "../../api/api"; // 导入API
import consignmentGoods from "~/components/consignmentGoods.vue";
import { getCalculate } from "~/api/axiosInfo";
export default {
  components: { consignmentGoods },
  data() {
    var checkAddress = (rule, value, callback) => {
      // console.log(value)
      if (value.length <= 0) {
        return callback(new Error("地址不能为空"));
      } else {
        callback();
      }
    };
    var validateAddress = (rule, value, callback) => {
      var info = this.addressForm.selectedOptions
      if (info.length===0) {
        callback(new Error('请选择省市区'));
      } else {
        if(info[0]==null){
          callback(new Error('请选择省'));
        }else if(info[1]===null){
          callback(new Error('请选择市'));
        }else if(info[2]===null){
          callback(new Error('请选择区'));
        }else{
          callback();
        }
      }
    };
    return {
      checkAddressDialog: false, //地址验证弹框
      fourLists: [],
      isHotel: sessionStorage.getItem("isHotel") === "1",
      giftTypeData: [],
      isFull: true,
      xToken: null,
      giftList: [], //4.10 赠品列表
      radioCheck: 0, //赠品默认选中
      goodsListDialogFalse: false, //设置无首次提货
      isAdmin: sessionStorage.getItem("isAdmin") == "1" ? true : false, // 酒店 用户是否是管理员
      attrChecked: true, //寄送地址选择框值
      giftInfo: "", //赠品信息
      basePath: basePath,

      areaNameList: [],
      addr: "", //详细地址
      addressForm: {
        linkman: "",
        tel: "",
        addr: "",
        address: "",
        selectedOptions: [], //省市区选择,
        streetId: "",
        streetName: "",
      }, //添加地址弹窗
      clickModel: false,
      dialogVisible: false,
      options: [],
      billValue: "",
      radio: "1",
      deliveryDialog: false,
      deliverTime: "2017-04-18",
      pickerOptions0: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        },
      },
      showAddress: true,
      pretype: 1, //判断从购物车或商品详情页进入
      skuNum: null, //商品id（从立即购买跳转过来）
      goodsNum: null, //商品数量（从立即购买跳转过来）
      checkedShopCarIdList: [], //购物车ID数组
      preOrderInfo: {}, //订单中转页展示返回数据
      cartLiExPo: [], //包括商品信息（活动信息列表包括赠品）（商品列表） （店铺优惠券）
      addressPosList: [], //收货地址列表
      invoiceInfoVOs1: {}, //发票信息列表(普发)
      invoiceInfoVOs2: {}, //发票信息列表(增值)
      arrNameList: [],
      minusMoneyList: [],
      invoiceChecked: false, //是否需要发票
      //				invoiceContent: '1', //发票内容
      invoiceInfoVOs1Form: {
        //避免数据双向绑定
        item: "", //发票抬头
        invoiceContent: "1",
        taxpayerNumber1: "",
      },
      invoiceInfoVOs2Form: {
        companyName1: "",
        taxpayerNumber1: "",
        address1: "",
        tel1: "",
        bankName1: "",
        bankCard1: "",
        invoiceContent: "1",
      },
      sendInvoForm: {
        //寄送地址
        linkman: "", //发票寄送人姓名
        sendTel: "", //发票寄送电话
        addr: "", //寄送地址-详细地址
        selectedOptions: [], //级联省市区
      },
      addressDialog: false, //地址弹窗设置
      count: null, //商品总数
      totalPrice: null, //源价格
      freight: null,
      // orderType: null,
      srcTotalPrice: null,
      cutPrice: null,
      isDefault: null, //是否默认
      changeAddressVisible: false, //更换地址
      addressRadio: null,
      receivingAddress: {}, //显示的收货地址
      loading: false, //进入订单中转页loading
      remark: null, //备注
      couponItem: {}, //选择的优惠券
      rules: {
        item: [
          {
            required: true,
            message: "请输入1-30位汉字、字母",
            pattern: /^[a-zA-Z\u4e00-\u9fa5]{1,30}$/,
          },
        ],
        companyName1: [
          {
            required: true,
            message: "请输入1-30位汉字、字母",
            pattern: /^[a-zA-Z\u4e00-\u9fa5]{1,30}$/,
          },
        ],
        taxpayerNumber1: [
          {
            required: true,
            message: "输入15-20位纳税人识别号",
            min: 15,
            max: 20,
          },
        ],
        address1: [
          {
            required: true,
            max: 50,
            message: "请输入4-50位字符",
          },
        ],
        tel1: [
          { required: true, message: "注册电话不能为空", trigger: "blur" },
          {
            pattern: /^1[0-9]{10}$/,
            trigger: "change",
            message: "注册电话格式有误",
          },
        ],
        bankName1: [
          {
            pattern: /^[\u4e00-\u9fa5]{4,30}$/,
            required: true,
            message: "请输入4-30个汉字",
          },
        ],
        bankCard1: [
          {
            required: true,
            pattern: /^[0-9]{1,25}$/,
            message: "请输入1-25位数字",
          },
        ],
        linkman: [
          {
            required: true,
            pattern: /^[a-zA-Z\u4e00-\u9fa5]{2,32}$/,
            message: "请输入2—32字汉字、字母",
          },
        ],
        tel: [
          { required: true, message: "联系电话不能为空", trigger: "change" },
          {
            pattern: /^((0\d{2,3}-?\d{7,8})|(\d{7,8})|(1\d{10}))$/,
            trigger: "change",
            message: "电话号或手机号格式有误",
          },
        ],
        sendTel: [
          { required: true, message: "联系电话不能为空", trigger: "change" },
          {
            pattern: /^((0\d{2,3}-?\d{7,8})|(\d{7,8})|(1\d{10}))$/,
            trigger: "change",
            message: "电话号或手机号格式有误",
          },
        ],
        addr: [
          {
            required: true,
            max: 50,
            message: "请输入4-50位字符",
          },
        ],
        selectedAddress: [
          {
            required: true,
            validator: checkAddress,
            type: "array",
            trigger: "change",
          },
        ],
        selectedOptions: [
          { validator: validateAddress, trigger: 'change' },
        ],
      },
      invoiceInfoVO: {},
      invoiceSave1: false, //点击发票弹框确定 改变 内容
      invoiceSave2: false, //点击发票弹框确定 改变 内容
      giftExs: [], //赠品信息
      /*发票优化  提交订单保存发票*/
      comInvo: {}, //普通发票
      increInvo: {}, //增值发票
      isInvoSave: false, //点击确定
      // isHotel: true, //是否是酒店用户
      deliverySeparate: false, //是否分批发货
      openStraight: false, //是否商品直发
      goodsListDialog: false,
      sendGoodsForm: {
        deliveryItemList: [],
        gmtDelivery: new Date(),
      },
      oneDeliverySeparate: "1",
      paymentOnBehalf: false, // 判断酒店是否能 账期支付
      dialogwillBuyVisible: false, // 选择换购商品dialog
      activeName: "0", // 换购活动tab
      willbuytableData: [], // 换购table
      fullExchangeList: [], // 满赠 换购活动
      isIndeterminate: false, // 部分选
      checkAll: false, // 全选
      checkgood: [], // 选中数组
      checkgoodBackup: [], // 备份选中数组
      haswillbuylist: [], // 有换购的商品
      // 满赠活动
      hasGiftList: [], // 存在满赠组合列表
      fullGiftList: [], // 满赠 组合活动
      dialogGiftVisible: false, // 选择满赠组合商品dialog
      giftActiveName: "0", // 满赠活动Tab
      willGiftTableData: [], // 满赠table
      isGiftIndeterminate: false, // 部分选赠品
      giftCheckAll: false, // 全选赠品
      checkGiftGood: [], // 选中数组赠品
      checkGiftGoodBackup: [], // 备份选中数组赠品
      allgiftDisabled: false, // 赠品组合 全选框
      discountCutMonney: 0,
      needPay: 0,
      groupActivityGifts: [],
      isShowGift: false,
      salesUnitType: null, //销售类型
      fileList: [],
      minimunMoqDisabled: false,
      minimunMoqString: "",
      isUseDeduction: false, // 是否使用佣金抵扣
      predictiveIntegral: 0, // 总积分
    };
  },
  watch: {
    fullGiftList(val) {
      this.fullGiftList.map((v) => {
        if (v.groupActivityGifts.length > 1) {
          this.isShowGift = true;
        } else {
          /*v.groupActivityGifts.map(item => {
                            if(item.giftExs.length>1){
                                this.isShowGift = true
                            }
                        })*/
        }
      });
    },
    deliverySeparate(val) {
      if (this.openStraight) {
        if (val) {
          this.$message({
            type: "info",
            message: "分批发货和商品直发不能同时开启，请先关闭商品直发",
          });
          this.deliverySeparate = false;
        }
      }
    },
    openStraight(val) {
      if (val) {
        if (this.deliverySeparate) {
          this.$message({
            type: "info",
            message: "商品直发和分批发货不能同时开启，请先关闭分批发货",
          });
          this.openStraight = false;
        }
      }
    },
    oneDeliverySeparate(val) {
      if (val === "1" && this.deliverySeparate) {
        this.goodsListDialog = true;
      }
    },
    giftList: {
      handler: function (val) {
        val.map((v) => {
          v.map((n) => {
            this.giftExs.push(n);
          });
        });
      },
      deep: true,
    },
    cartLiExPo: {
      handler: function (val) {
        this.getMinimunmoq();
      },
      deep: true,
    },
  },
  computed: {
    isReceivingAddress() {
      return Object.keys(this.receivingAddress).length < 3;
    },
    giftCheckNum() {
      // 满赠选中件数
      let num = 0;
      this.checkGiftGood.forEach((i) => {
        if (i.index == this.giftActiveName) {
          num += i.giftExs.length;
        }
      });
      return num;
    },
    willbuycheckNum() {
      // 换购选中件数
      let num = 0;
      this.checkgood.forEach((i) => {
        if (i.index == this.activeName) num++;
      });
      return num;
    },
    maxwillbuycheckNum() {
      // 最大换购数量
      if (this.fullExchangeList.length > 0) {
        return this.willbuytableData.length <
          this.fullExchangeList[this.activeName * 1].optionalNum
          ? this.willbuytableData.length
          : this.fullExchangeList[this.activeName * 1].optionalNum;
      }
    },
    willbuymoney() {
      // 已选金额
      let money = 0;
      this.checkgood.forEach((i) => {
        if (i.index == this.activeName) money += i.amount;
      });
      return money;
    },
    ruleDescriptionText() {
      // 活动文案
      if (this.fullExchangeList.length > 0) {
        return this.fullExchangeList[this.activeName * 1].ruleDescriptionText;
      }
    },
    giftRuleDescriptionText() {
      // 赠品活动文案
      if (this.fullGiftList.length > 0) {
        return this.fullGiftList[this.giftActiveName * 1].ruleDescriptionText;
      }
    },
  },
  mounted() {
    // this.isHotel = sessionStorage.getItem("isHotel") === '1';
    // this.xToken = sessionStorage.getItem("xToken");
    this.$nextTick(() => {
      if (sessionStorage.getItem("checkedShopCarIdList")) {
        this.checkedShopCarIdList = JSON.parse(
          sessionStorage.getItem("checkedShopCarIdList")
        );
      }
      this.pretype = this.$route.params.type;
      this.skuNum = this.$route.params.skuNo ? this.$route.params.skuNo : null;
      this.goodsNum = this.$route.params.num ? this.$route.params.num : null;
      this.salesUnitType = this.$route.params.salesUnitType
        ? this.$route.params.salesUnitType
        : null;
      this.preOrderQuery().then(() => {
        this.getMinimunmoq();
      });
    });
  },
  methods: {
    //获取还差多少起订
    getMinimunmoq() {
      const api = "/anon/order/cart/checkMinimunmoq";
      var arr = [];
      for (let i = 0; i < this.cartLiExPo.length; i++) {
        this.cartLiExPo[i].cartExPos.forEach((item2, index) => {
          // console.log(this.isHotel ? (item2.isNeedPay ? item2.price * item2.num : item2.promotionPrice * item2.num) : (item2.isNeedPay ? item2.price * item2.num : item2.amount));
          // console.log(item2.isNeedPay ? item2.amount : item2.price * item2.num);
          const obj = {
            skuType: item2.skuType,
            num: item2.num,
            isIntegerMultiple: item2.isIntegerMultiple,
            specInfoNum: item2.specInfoNum,
            totalPrice: item2.price * item2.num,
            // totalPrice: this.isHotel ? ( item2.isNeedPay!=undefined ? item2.price * item2.num  : item2.promotionPrice * item2.num ) :( item2.isNeedPay!=undefined ? item2.amount  : item2.price * item2.num),
          };
          arr.push(obj);
        });
      }
      if (arr.length == 0) {
        this.minimunMoqDisabled = false;
        this.minimunMoqString = "";
        return false;
      }
      let body = {
        buyerId: sessionStorage.traderId,
        cartExParams: arr,
      };
      axiosApi(
        api,
        body,
        (res) => {
          this.minimunMoqDisabled = res.data.data ? true : false;
          this.minimunMoqString = res.data.data;
        },
        1,
        10,
        () => {
          this.loading = false;
        }
      );
    },
    cloneDialogImageUrlList(val) {
      this.fileList = val;
    },
    handleCouponChange(val) {
      //改变优惠券
      if (val == "") {
        this.couponItem = {};
        this.couponItem.minusMoney = 0;
      }
    },
    handleClosewillBuy() {
      // console.log('close')
      this.checkgood = [];
    },
    handleCloseGift() {
      // 关闭满赠组合弹框
      this.checkGiftGood = [];
    },
    confirmGift() {
      //确认赠品
      this.checkGiftGoodBackup = this.checkGiftGood.map((n) => n); // 备份选中数据
      // this.giftList[this.giftActiveName] = this.checkGiftGood.giftExs;
      // if(this.giftExs)
      // this.giftExs = this.giftExs.filter(i => !i.giftItem)// 过滤 giftItem = true 选中的赠品组合
      this.giftList[this.giftActiveName] = [];

      this.checkGiftGood.forEach((item, index) => {
        this.$set(this.giftList, index, item.giftExs);
        //.push(...item.giftExs)
      });
      this.giftExs = [];
      // this.giftList.map(v => {
      //     v.map(n => {
      //         this.$set
      //         this.giftExs.push(n)
      //     })
      // });

      this.giftExs = [...new Set(this.giftExs)];
      this.sendGoodsForm.deliveryItemList =
        this.sendGoodsForm.deliveryItemList.filter((i) => i.giftType !== 0); //清空赠品
      this.giftExs.forEach((item) => {
        // 发货赠品 过滤掉非标赠品
        if (item.giftType === 0) {
          this.sendGoodsForm.deliveryItemList.push({
            activityId: item.activityId, // 首次发货传递 activityId （赠品才传activityId ）
            skuName: item.giftSkuName,
            skuNo: item.giftSkuNo,
            skuImage: item.image,
            specNum: 1, // 需要发货的数量
            allSpecNum: item.quantity,
            remainingSpecNum: item.quantity,
            specUnit: "箱",
            skuGift: true,
            isIntegerMultiple: item.isIntegerMultiple, // 是否正箱发货
            changeCoefficient: item.specInfoNum, // 规格数量
            salesUnit: item.salesUnitName, // 销售单位
            giftType: item.giftType, // 赠品0标品，1非标品
          });
        }
      });
      this.getCalculate()
      this.dialogGiftVisible = false;
    },
    confirmWillBuy() {
      // 确认换购商品
      this.preOrderInfo.cartLiExPo[0].cartExPos =
        this.preOrderInfo.cartLiExPo[0].cartExPos.filter(
          (i) => i.isNeedPay !== false
        );
      let a = this.checkgood.map((k) => {
        let obj = {
          fileUrl: k.image,
          price: k.unitAmount, // 单价
          salesUnitName: k.salesUnitName, // 单位
          changePurchaseItem: true,
        };
        return Object.assign(k, obj);
      });
      this.preOrderInfo.cartLiExPo[0].cartExPos.push(...a);
      this.checkgoodBackup = this.checkgood.map((n) => n); // 备份选中数据
      // 更新分批发货的换购商品数据
      this.sendGoodsForm.deliveryItemList = [];
      // 发货商品信息
      this.preOrderInfo.cartLiExPo[0].cartExPos.forEach((item) => {
        if (!item.changePurchaseItem) {
          this.sendGoodsForm.deliveryItemList.push({
            skuName: item.skuName,
            skuNo: item.skuNo,
            skuImage: item.fileUrl,
            specNum: 1, // 需要发货的数量
            allSpecNum: item.num, // 总数量
            remainingSpecNum: item.num, // 可发量
            specUnit: "箱", // 销售单位
            skuGift: false, // 是否是赠品
            isIntegerMultiple: item.isIntegerMultiple, // 是否正箱发货
            changeCoefficient: item.specInfoNum, // 规格数量
            salesUnit: item.salesUnitName, // 销售单位
          });
        } else {
          this.sendGoodsForm.deliveryItemList.push({
            activityId: item.activityId, // 换购商品传 activityId
            changePurchaseItem: item.changePurchaseItem,
            skuName: item.skuName,
            skuNo: item.skuNo,
            skuImage: item.image,
            specNum: 1, // 需要发货的数量
            allSpecNum: item.num, // 总数量
            remainingSpecNum: item.num, // 可发量
            specUnit: "箱", // 销售单位
            skuGift: false, // 是否是赠品
            isIntegerMultiple: item.isIntegerMultiple, // 是否正箱发货
            changeCoefficient: item.specInfoNum, // 规格数量
            salesUnit: item.salesUnitName, // 销售单位
          });
        }
      });
      this.giftExs.forEach((item) => {
        // 发货赠品 过滤掉非标赠品
        if (item.giftType === 0) {
          this.sendGoodsForm.deliveryItemList.push({
            activityId: item.activityId, // 首次发货传递 activityId （赠品才传activityId ）
            skuName: item.giftSkuName,
            skuNo: item.giftSkuNo,
            skuImage: item.image,
            specNum: 1, // 需要发货的数量
            allSpecNum: item.quantity,
            remainingSpecNum: item.quantity,
            specUnit: "箱",
            skuGift: true,
            isIntegerMultiple: item.isIntegerMultiple, // 是否正箱发货
            changeCoefficient: item.specInfoNum, // 规格数量
            salesUnit: item.salesUnitName, // 销售单位
            giftType: item.giftType, // 赠品0标品，1非标品
          });
        }
      });
      // 运费
      this.getCalculate()
      // 计算更新金额
      this.totalPrice = this.cartLiExPo[0].totalPrice; //优惠后总价（没有计算优惠券）
      this.srcTotalPrice = this.cartLiExPo[0].srcTotalPrice; //源价格加上换购金额
      this.preOrderInfo.cartLiExPo[0].cartExPos.forEach((i) => {
        if (i.changePurchaseItem) {
          this.totalPrice += i.amount;
          this.srcTotalPrice += i.amount;
        }
      }); // 加上换购金额
      let sum = this.preOrderInfo.cartLiExPo[0].count;
      this.checkgood.map((v) => {
        sum += v.num;
      });
      this.count = sum; // 换购商品件数
      this.dialogwillBuyVisible = false;
    },
    gotobuy(item) {
      // 去换购
      this.dialogwillBuyVisible = true;
      this.willbuytableData =
        this.fullExchangeList[this.activeName * 1].changePurchaseGoodsVoList; // 换购商品
      if (this.checkgoodBackup.length > 0) {
        this.checkgood = this.checkgoodBackup;
      }
      // 更新全选
      let checkedCount = 0;
      this.checkgood.forEach((item) => {
        if (item.index == this.activeName) checkedCount++;
      });
      this.checkAll = checkedCount === this.maxwillbuycheckNum;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.maxwillbuycheckNum;
    },
    togift() {
      // 选赠品 按钮
      this.dialogGiftVisible = true;
      this.willGiftTableData =
        this.fullGiftList[this.giftActiveName * 1].groupActivityGifts; // 换购商品
      if (this.checkGiftGoodBackup.length > 0) {
        this.checkGiftGood = this.checkGiftGoodBackup;
      }
      if (!this.deliverySeparate && !this.openStraight) {
        //不分批发货
        this.checkGiftGood = this.checkGiftGood.filter((v) => !v.shortStock);
      }
      // 更新全选
      let checkedCount = 0;
      this.checkGiftGood.forEach((item) => {
        if (item.index == this.giftActiveName) checkedCount++;
      });
      this.giftCheckAll = checkedCount === 1;
    },
    handleCheckAllChange(event) {
      // 全选
      if (event) {
        let checkedCount = 0;
        this.checkgood.forEach((item) => {
          if (item.index == this.activeName) checkedCount++;
        });
        if (checkedCount < this.maxwillbuycheckNum) {
          // 如果已选中的数据 小于 最多可选中个数
          this.checkgood = this.checkgood.filter(
            (n) => n.index != this.activeName
          ); // 清空当前已选中的数据
          for (let a = 0; a < this.maxwillbuycheckNum; a++) {
            const element = this.willbuytableData[a];
            this.checkgood.push(element);
          }
        } else {
          // 大于等于
          //不做操作
        }
      } else {
        this.checkgood = this.checkgood.filter(
          (n) => n.index != this.activeName
        ); // 过滤当前tab 选中的 数据
      }
      this.isIndeterminate = false;
    },
    handleGiftCheckAllChange(event) {
      // 全选
      if (event) {
        let checkedCount = 0;
        this.checkGiftGood.forEach((item) => {
          if (item.index == this.giftActiveName) checkedCount++;
        });
        if (checkedCount == 0) {
          this.checkGiftGood = this.checkGiftGood.filter(
            (n) => n.index != this.giftActiveName
          ); // 清空当前已选中的数据
          if (!this.deliverySeparate && !this.openStraight) {
            //不分批发货
            this.checkGiftGood = this.checkGiftGood.filter(
              (v) => !v.shortStock
            );
            for (let i = 0; i < this.willGiftTableData.length; i++) {
              if (!this.willGiftTableData[i].shortStock) {
                this.checkGiftGood.push(this.willGiftTableData[i]);
                return false;
              }
            }
          } else {
            // 分批发货
            this.checkGiftGood.push(this.willGiftTableData[0]);
          }
        }
      } else {
        this.checkGiftGood = this.checkGiftGood.filter(
          (n) => n.index != this.giftActiveName
        ); // 过滤当前tab 选中的 数据
      }
    },
    handleCheckChange(val) {
      // 单选 this.maxwillbuycheckNum最多选中件数
      let checkedCount = 0;
      val.forEach((item) => {
        if (item.index == this.activeName) checkedCount++;
      });
      if (checkedCount <= this.maxwillbuycheckNum) {
        this.checkAll = checkedCount === this.maxwillbuycheckNum;
        this.isIndeterminate =
          checkedCount > 0 && checkedCount < this.maxwillbuycheckNum;
      } else {
        this.checkgood.pop();
        this.$message.warning(`最多选中${this.maxwillbuycheckNum}件`);
      }
    },
    handleGiftCheckChange(val) {
      // 单选
      // console.log(val)
      this.$set(
        this.checkGiftGood,
        this.giftActiveName,
        this.willGiftTableData[val]
      );
      // this.checkGiftGood = [this.willGiftTableData[val]]
      /*let checkedCount = 0;
                val.forEach(item => {
                    if (item.index == this.giftActiveName) checkedCount++
                })
                if (checkedCount <= 1) {
                    this.giftCheckAll = checkedCount === 1
                } else {
                    this.checkGiftGood.pop()
                    this.$message.warning(`最多选中1组赠品`)
                }*/
    },
    handlewillbuytabClick() {
      // 切换换购活动
      this.willbuytableData =
        this.fullExchangeList[this.activeName * 1].changePurchaseGoodsVoList; // 换购商品
      let checkedCount = 0;
      this.checkgood.forEach((item) => {
        if (item.index == this.activeName) checkedCount++;
      });
      this.checkAll = checkedCount === this.maxwillbuycheckNum;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.maxwillbuycheckNum;
    },
    handleGiftTabClick() {
      // 切换满赠活动
      this.willGiftTableData =
        this.fullGiftList[this.giftActiveName * 1].groupActivityGifts; // 满赠组合商品

      this.radioCheck = this.willGiftTableData.indexOf(
        this.checkGiftGood[this.giftActiveName]
      );
      // this.willGiftTableData
      let checkedCount = 0;
      this.checkGiftGood.forEach((item) => {
        if (item.index == this.giftActiveName) checkedCount++;
      });
      this.giftCheckAll = checkedCount === 1;
    },
    goodsListDialogClose() {
      this.oneDeliverySeparate = "2";
      this.goodsListDialog = false;
    },
    goodsListDialogSave() {
      const goodsList = this.sendGoodsForm.deliveryItemList.filter(
        (n) => n.specNum > 0
      );
      if (goodsList.length === 0) {
        this.$message.error("如果需要首次发货，发货商品数量不能全部为0");
        return;
      }
      this.goodsListDialog = false;
    },
    deliverySeparateChange() {
      if (this.deliverySeparate && this.oneDeliverySeparate === "1") {
        this.goodsListDialog = true;
      }
    },
    handleCheckInvo() {
      //需要发票选择change
      if (this.isReceivingAddress) {
        //如果没有收货地址
        this.invoiceChecked = false;
        this.$message.error("请先添加收货地址");
      } else if (this.invoiceChecked) {
        //需要发票
        if (!!this.sendInvoForm.sendTel && !!this.sendInvoForm.linkman) {
          //都有收票信息
          if (Object.keys(this.invoiceInfoVOs1).length > 0) {
            //如果有普通发票信息
            this.invoiceSave1 = true;
            this.invoiceSave2 = false;
          } else {
            //如果有增值税发票信息
            if (Object.keys(this.invoiceInfoVOs2).length > 0) {
              this.invoiceSave1 = false;
              this.invoiceSave2 = true;
            }
          }
        } else {
          //没有收票信息
          this.dialogVisible = true;
          if (Object.keys(this.invoiceInfoVOs1).length > 0) {
            this.radio = "1";
          } else {
            if (Object.keys(this.invoiceInfoVOs2).length > 0) {
              this.radio = "2";
            }
          }
        }
        this.handleCheckAddr();
      }
    },
    handleCheckAddr() {
      //是否勾选默认收票地址
      this.sendInvoForm.selectedOptions = [];

      if (this.attrChecked) {
        //默认收票地址
        this.sendInvoForm.selectedOptions[0] =
          this.receivingAddress.provinceId.toString();
        this.sendInvoForm.selectedOptions[1] =
          this.receivingAddress.cityId.toString();
        this.sendInvoForm.selectedOptions[2] =
          this.receivingAddress.regionId.toString();
        this.arrNameList = [
          this.receivingAddress.provinceName,
          this.receivingAddress.cityName,
          this.receivingAddress.regionName,
        ];
        this.sendInvoForm.addr = this.receivingAddress.addr;
      } else {
        if (Object.keys(this.invoiceInfoVOs1).length > 0) {
          //如果已经选择普通发票信息或已经选择增值税发票信息
          this.sendInvoForm.addr = this.comInvo.addr; //寄送地址-详细地址
          this.sendInvoForm.selectedOptions[0] =
            this.invoiceInfoVOs1.provinceId.toString();
          this.sendInvoForm.selectedOptions[1] =
            this.invoiceInfoVOs1.cityId.toString();
          this.sendInvoForm.selectedOptions[2] =
            this.invoiceInfoVOs1.regionId.toString();
          this.sendInvoForm.addr = this.invoiceInfoVOs1.addr;
        } else if (Object.keys(this.invoiceInfoVOs2).length > 0) {
          this.sendInvoForm.addr = this.comInvo.addr; //寄送地址-详细地址
          this.sendInvoForm.selectedOptions[0] =
            this.invoiceInfoVOs2.provinceId.toString();
          this.sendInvoForm.selectedOptions[1] =
            this.invoiceInfoVOs2.cityId.toString();
          this.sendInvoForm.selectedOptions[2] =
            this.invoiceInfoVOs2.regionId.toString();
          this.sendInvoForm.addr = this.invoiceInfoVOs2.addr;
        } else if (!!this.comInvo.provinceId) {
          //数据库存在普通发票信息
          this.sendInvoForm.addr = this.comInvo.addr; //寄送地址-详细地址
          this.sendInvoForm.selectedOptions[0] =
            this.comInvo.provinceId.toString();
          this.sendInvoForm.selectedOptions[1] = this.comInvo.cityId.toString();
          this.sendInvoForm.selectedOptions[2] =
            this.comInvo.regionId.toString();
        } else if (!!this.increInvo.provinceId) {
          //数据库存在增值税发票信息
          this.sendInvoForm.addr = this.increInvo.addr; //寄送地址-详细地址
          this.sendInvoForm.selectedOptions[0] =
            this.increInvo.provinceId.toString();
          this.sendInvoForm.selectedOptions[1] =
            this.increInvo.cityId.toString();
          this.sendInvoForm.selectedOptions[2] =
            this.increInvo.regionId.toString();
        } else if (this.receivingAddress.provinceId) {
          //使用收货地址
          this.sendInvoForm.selectedOptions[0] =
            this.receivingAddress.provinceId.toString();
          this.sendInvoForm.selectedOptions[1] =
            this.receivingAddress.cityId.toString();
          this.sendInvoForm.selectedOptions[2] =
            this.receivingAddress.regionId.toString();
          this.arrNameList = [
            this.receivingAddress.provinceName,
            this.receivingAddress.cityName,
            this.receivingAddress.regionName,
          ];
          this.sendInvoForm.addr = this.receivingAddress.addr;
        }
      }
    },
    changeImage() {
      let imageList = [];
      if (this.fileList.length != 0) {
        this.fileList.forEach((item) => {
          imageList.push(item.url);
        });
      }
      return imageList;
    },
    handleChangeInvoice() {
      //添加，修改 发票信息
      this.dialogVisible = true;
      if (Object.keys(this.invoiceInfoVOs1).length > 0) {
        this.radio = "1";
      } else {
        if (Object.keys(this.invoiceInfoVOs2).length > 0) {
          this.radio = "2";
        }
      }
    },
    handleCloseDialog() {
      //判断发票是否存在
      if (
        this.preOrderInfo.invoiceInfoVOs &&
        this.preOrderInfo.invoiceInfoVOs.length > 0
      ) {
        for (
          var index = 0;
          index < this.preOrderInfo.invoiceInfoVOs.length;
          index++
        ) {
          var invoiceInfoVOs = this.preOrderInfo.invoiceInfoVOs[index];
          if (invoiceInfoVOs.invoiceType == 1) {
            //普通发票
            this.invoiceInfoVOs1 = invoiceInfoVOs;
            this.invoiceInfoVOs1Form.item = this.invoiceInfoVOs1.companyHead;
            this.invoiceInfoVOs1Form.taxpayerNumber1 =
              this.invoiceInfoVOs1.taxpayerNumber;
            if (!!this.sendInvoForm.sendTel && !!this.sendInvoForm.linkman) {
            } else {
              this.sendInvoForm.linkman = this.invoiceInfoVOs1.linkman; //发票寄送人姓名
              this.sendInvoForm.sendTel = this.invoiceInfoVOs1.sendTel; //发票寄送电话
            }
            if (
              !!this.invoiceInfoVOs1.linkman &&
              !!this.invoiceInfoVOs1.sendTel
            ) {
              this.sendInvoForm.linkman = this.invoiceInfoVOs1.linkman; //发票寄送人姓名
              this.sendInvoForm.sendTel = this.invoiceInfoVOs1.sendTel; //发票寄送电话
            }
            if (!!this.invoiceInfoVOs1.provinceId) {
              //存在
              this.sendInvoForm.selectedOptions = [
                this.invoiceInfoVOs1.provinceId.toString(),
                this.invoiceInfoVOs1.cityId.toString(),
                this.invoiceInfoVOs1.regionId.toString(),
              ];
              this.sendInvoForm.addr = this.invoiceInfoVOs1.addr; //寄送地址-详细地址
            } else {
              this.sendInvoForm.selectedOptions = [
                this.receivingAddress.provinceId.toString(),
                this.receivingAddress.cityId.toString(),
                this.receivingAddress.regionId.toString(),
              ];
              this.sendInvoForm.addr = this.receivingAddress.addr;
            }
          } else {
            //增值税发票
            this.invoiceInfoVOs2 = invoiceInfoVOs;
            this.invoiceInfoVOs2Form.companyName1 =
              this.invoiceInfoVOs2.companyName;
            this.invoiceInfoVOs2Form.taxpayerNumber1 =
              this.invoiceInfoVOs2.taxpayerNumber;
            this.invoiceInfoVOs2Form.address1 = this.invoiceInfoVOs2.address;
            this.invoiceInfoVOs2Form.tel1 = this.invoiceInfoVOs2.tel;
            this.invoiceInfoVOs2Form.bankName1 = this.invoiceInfoVOs2.bankName;
            this.invoiceInfoVOs2Form.bankCard1 = this.invoiceInfoVOs2.bankCard;
            if (!!this.sendInvoForm.sendTel && !!this.sendInvoForm.linkman) {
            } else {
              this.sendInvoForm.linkman = this.invoiceInfoVOs2.linkman; //发票寄送人姓名
              this.sendInvoForm.sendTel = this.invoiceInfoVOs2.sendTel; //发票寄送电话
            }
            if (
              !!this.invoiceInfoVOs2.linkman &&
              !!this.invoiceInfoVOs2.sendTel
            ) {
              this.sendInvoForm.linkman = this.invoiceInfoVOs2.linkman; //发票寄送人姓名
              this.sendInvoForm.sendTel = this.invoiceInfoVOs2.sendTel; //发票寄送电话
            }
            if (!!this.invoiceInfoVOs2.provinceId) {
              //存在
              this.sendInvoForm.selectedOptions = [
                this.invoiceInfoVOs2.provinceId.toString(),
                this.invoiceInfoVOs2.cityId.toString(),
                this.invoiceInfoVOs2.regionId.toString(),
              ];
              this.sendInvoForm.addr = this.invoiceInfoVOs2.addr; //寄送地址-详细地址
            } else {
              this.sendInvoForm.selectedOptions = [
                this.receivingAddress.provinceId.toString(),
                this.receivingAddress.cityId.toString(),
                this.receivingAddress.regionId.toString(),
              ];
              this.sendInvoForm.addr = this.receivingAddress.addr;
            }
          }
        }
      }

      // if (!(!!this.sendInvoForm.sendTel&&!!this.sendInvoForm.linkman)) {
      // 	this.invoiceChecked = false
      // }
      this.handleCheckAddr();
      setTimeout(() => {
        if (Object.keys(this.invoiceInfoVOs1).length <= 0) {
          this.$refs.invoiceInfoVOs1Form.resetFields();
        }
        if (Object.keys(this.invoiceInfoVOs2).length <= 0) {
          this.$refs.invoiceInfoVOs2Form.resetFields();
        }
        if (
          Object.keys(this.invoiceInfoVOs1).length <= 0 &&
          Object.keys(this.invoiceInfoVOs2).length <= 0
        ) {
          this.$refs.sendInvoForm.resetFields();
        }
      }, 0);
    },
    preOrderQuery() {
      //订单中转页展示
      this.loading = true;
      const api = "/anon/order/preOrder/query";
      let body = {
        cartIds: this.checkedShopCarIdList,
        type: this.pretype,
        buyerId: sessionStorage.traderId,
        skuNo: this.skuNum,
        num: this.goodsNum,
        selectedSalesUnitType: this.salesUnitType,
      };
      return new Promise((resolve, reject) => {
        axiosApi(
          api,
          body,
          (res) => {
            this.loading = false;
            this.preOrderInfo = res.data.data;
            // this.orderType = this.preOrderInfo.orderType
            this.addressPosList = this.preOrderInfo.addressPosList; //收货地址列表
            this.cartLiExPo = this.preOrderInfo.cartLiExPo; //商品信息（活动信息列表包括赠品
            this.paymentOnBehalf = this.preOrderInfo.paymentOnBehalf; // 酒店判断 是否能账期支付
            // this.paymentOnBehalf = true
            this.preOrderInfo.cartLiExPo[0].actPos.forEach((it) => {
              if (it.changePurchaseNeedGoodsVoList) {
                // 存在必购商品
                if (it.changePurchaseNeedGoodsVoList.length > 0) {
                  let changePurchaseNeedGoodsVoList =
                    it.changePurchaseNeedGoodsVoList;
                  it.changePurchaseNeedGoodsVoList =
                    changePurchaseNeedGoodsVoList.map((k) => {
                      this.needPay += k.num;
                      let obj = {
                        fileUrl: k.image,
                        price: k.unitAmount, // 单价
                        salesUnitName: k.salesUnitName, // 单位
                        changePurchaseItem: true,
                      };
                      return Object.assign(k, obj);
                    });
                  // 通过字段isNeedPay标识是必购商品
                  this.preOrderInfo.cartLiExPo[0].cartExPos.push(
                    ...it.changePurchaseNeedGoodsVoList
                  );
                }
              }
            });
            sessionStorage.setItem("xToken", res.data.context.xToken);

            // 获取换购活动列表 --> activityType == 4
            this.fullExchangeList =
              this.preOrderInfo.cartLiExPo[0].actPos.filter(
                (i) => i.activityType === 4
              );
            // 过滤有换购活动 但是没有换购商品列表
            this.fullExchangeList = this.fullExchangeList.filter(
              (i) => i.changePurchaseGoodsVoList.length !== 0
            );
            this.fullExchangeList = this.fullExchangeList.map((item, index) => {
              item.changePurchaseGoodsVoList.forEach((k) => {
                k.index = index;
                k.changePurchaseItem = true; // 标识换购商品
              });
              return item;
            }); // 向每个换购商品添加对应活动的索引
            //有换购商品

            this.fullExchangeList.forEach((n) => {
              this.haswillbuylist.push(...n.changePurchaseGoodsVoList);
            });

            //协议商品

            //协议商品

            //满赠 开始
            // 获取满赠组合活动列表 --> activityType == 1

            this.preOrderInfo.cartLiExPo[0].actPos.map((v) => {
              if (v.activityType === 1) {
                if (v.groupActivityGifts.length > 1) {
                  this.isFull = false;
                }
              }
            });
            this.fullGiftList = this.preOrderInfo.cartLiExPo[0].actPos.filter(
              (i) => i.activityType === 1 || i.activityType === 0
            );
            // 过滤有满赠组合活动 但是没有满赠组合商品列表
            this.fullGiftList = this.fullGiftList.filter(
              (i) => i.groupActivityGifts.length !== 0
            );
            this.fullGiftList = this.fullGiftList.map((item, index) => {
              item.groupActivityGifts.forEach((k) => {
                k.index = index;
                k.giftItem = true; // 标识满赠组合商品
                k.giftExs.forEach((g) => {
                  g.giftItem = true; // 标识满赠组合商品
                  g.groupSort = k.groupSort;
                });
              });
              return item;
            }); // 向每个换购商品添加对应活动的索引
            this.fullGiftList.forEach((n) => {
              this.hasGiftList.push(...n.groupActivityGifts);
            });
            if (this.fullGiftList.length > 0) {
              //默认第一个赠品组合
              this.fullGiftList.forEach((a, b) => {
                this.giftList[b] = [];
                a.groupActivityGifts.forEach((c, d) => {
                  if (d == 0) {
                    this.checkGiftGoodBackup.push(c);
                    this.$set(this.giftList, b, c.giftExs);
                    // c.giftExs.forEach(v => {
                    //     this.giftList[b].push(v);
                    //     // this.$set(this.giftList,b,[v])
                    //     // this.giftExs.push(v)
                    // })
                  }
                });
              });
            }
            // End  满赠
            // 发货商品信息
            this.preOrderInfo.cartLiExPo[0].cartExPos.forEach((item) => {
              if (!item.changePurchaseItem) {
                this.sendGoodsForm.deliveryItemList.push({
                  skuName: item.skuName,
                  skuNo: item.skuNo,
                  skuImage: item.fileUrl,
                  specNum: 1, // 需要发货的数量
                  allSpecNum: item.num, // 总数量
                  remainingSpecNum: item.num, // 可发量
                  specUnit: "箱", // 销售单位
                  skuGift: false, // 是否是赠品
                  isIntegerMultiple: item.isIntegerMultiple, // 是否正箱发货
                  changeCoefficient: item.specInfoNum, // 规格数量
                  salesUnit: item.salesUnitName, // 销售单位
                });
              } else {
                this.sendGoodsForm.deliveryItemList.push({
                  activityId: item.activityId, // 换购商品传 activityId
                  changePurchaseItem: item.changePurchaseItem,
                  skuName: item.skuName,
                  skuNo: item.skuNo,
                  skuImage: item.image,
                  specNum: 1, // 需要发货的数量
                  allSpecNum: item.num, // 总数量
                  remainingSpecNum: item.num, // 可发量
                  specUnit: "箱", // 销售单位
                  skuGift: false, // 是否是赠品
                  isIntegerMultiple: item.isIntegerMultiple, // 是否正箱发货
                  changeCoefficient: item.specInfoNum, // 规格数量
                  salesUnit: item.salesUnitName, // 销售单位
                });
              }
            });

            //活动里面的赠品信息
            // this.giftExs = []
            // if (this.preOrderInfo.cartLiExPo[0].actPos && this.preOrderInfo.cartLiExPo[0].actPos.length) {
            // 	this.preOrderInfo.cartLiExPo[0].actPos.forEach((item) => {
            // 		if(item.activityType == 1) { // 活动类型 1-满赠 2-满减
            // 			// 赠品列表
            // 			// this.giftExs.push(...item.giftExs)
            // 		}
            // 	})
            // }

            this.giftExs.forEach((item) => {
              // 发货赠品 过滤掉非标赠品
              if (item.giftType === 0) {
                this.sendGoodsForm.deliveryItemList.push({
                  activityId: item.activityId, // 首次发货传递 activityId （赠品才传activityId ）
                  skuName: item.giftSkuName,
                  skuNo: item.giftSkuNo,
                  skuImage: item.image,
                  specNum: 1, // 需要发货的数量
                  allSpecNum: item.quantity,
                  remainingSpecNum: item.quantity,
                  specUnit: "箱",
                  skuGift: true,
                  isIntegerMultiple: item.isIntegerMultiple, // 是否正箱发货
                  changeCoefficient: item.specInfoNum, // 规格数量
                  salesUnit: item.salesUnitName, // 销售单位
                  giftType: item.giftType, // 赠品0标品，1非标品
                });
              }
            });
            this.cartLiExPo.forEach((item, index) => {
              // 获取 每家供应商最大的优惠券
              if (item.couponExPos !== null) {
                if (item.couponExPos.length != 0) {
                  this.minusMoneyList.push(item.couponExPos[0]);
                }
              }
            });
            if (this.minusMoneyList.length != 0) {
              this.couponItem = this.minusMoneyList[0]; //一个供应商的优惠金额
            } else {
              this.couponItem.minusMoney = 0;
            }

            // 计算商品总数
            this.predictiveIntegral =
              this.cartLiExPo[0].predictiveIntegral || 0; // 总积分, null 为 0
            this.count = this.preOrderInfo.cartLiExPo[0].count; // 换购商品件数
            this.totalPrice = this.cartLiExPo[0].totalPrice; //优惠后总价（没有计算优惠券）
            this.freight = this.cartLiExPo[0].freight; //运费
            this.srcTotalPrice = this.cartLiExPo[0].srcTotalPrice; //源价格加上换购金额
            this.preOrderInfo.cartLiExPo[0].cartExPos.forEach((i) => {
              if (i.changePurchaseItem) {
                this.totalPrice += i.amount;
                this.srcTotalPrice += i.amount;
              }
            }); // 加上换购金额
            this.cutPrice = this.cartLiExPo[0].cutPrice; //活动满减金额
            this.discountCutMonney = this.cartLiExPo[0].discountCutMonney;
            // if (this.isHotel) {  // 酒店
            //     this.discountCutMonney = this.cartLiExPo[0].discountCutMonney
            // }
            //处理显示的收货地址
            let item;
            if (this.isHotel) {
              //酒店用户
              for (let i = 0; i < this.addressPosList.length; i++) {
                item = this.addressPosList[i];
                if (item.isDefault == 1 && item.state == 2) {
                  this.receivingAddress = item;
                  this.addressRadio = item;
                  this.loading = false;
                  break;
                }
                // this.receivingAddress = item
                // this.addressRadio = item
                // this.loading = false
                // break;
              }
            } else {
              for (let i = 0; i < this.addressPosList.length; i++) {
                item = this.addressPosList[i];
                if (item.isDefault == 1 && item.state == 2) {
                  this.receivingAddress = item;
                  this.addressRadio = item;
                  this.loading = false;
                  break;
                  // this.receivingAddress = item
                  // this.addressRadio = item
                  // this.loading = false
                  // break;
                }
              }
            }
            //判断发票是否存在
            if (
              this.preOrderInfo.invoiceInfoVOs &&
              this.preOrderInfo.invoiceInfoVOs.length > 0
            ) {
              for (
                var index = 0;
                index < this.preOrderInfo.invoiceInfoVOs.length;
                index++
              ) {
                var invoiceInfoVOs = this.preOrderInfo.invoiceInfoVOs[index];
                if (invoiceInfoVOs.invoiceType == 1) {
                  //普通发票
                  this.comInvo = Object.assign({}, invoiceInfoVOs); //普通发票赋值
                  this.invoiceInfoVOs1 = invoiceInfoVOs;
                  this.invoiceInfoVOs1Form.item =
                    this.invoiceInfoVOs1.companyHead;
                  this.invoiceInfoVOs1Form.taxpayerNumber1 =
                    this.invoiceInfoVOs1.taxpayerNumber;
                  this.sendInvoForm.linkman = this.invoiceInfoVOs1.linkman; //发票寄送人姓名
                  this.sendInvoForm.sendTel = this.invoiceInfoVOs1.sendTel; //发票寄送电话
                  //默认收货地址
                  this.sendInvoForm.selectedOptions.push(
                    this.receivingAddress.provinceId.toString(),
                    this.receivingAddress.cityId.toString(),
                    this.receivingAddress.regionId.toString()
                  );
                  this.sendInvoForm.addr = this.receivingAddress.addr;
                  /*初始化显示*/
                  this.invoiceInfoVOs1.provinceId =
                    this.receivingAddress.provinceId;
                  this.invoiceInfoVOs1.cityId = this.receivingAddress.cityId;
                  this.invoiceInfoVOs1.regionId =
                    this.receivingAddress.regionId;
                  this.invoiceInfoVOs1.provinceName =
                    this.receivingAddress.provinceName;
                  this.invoiceInfoVOs1.cityName =
                    this.receivingAddress.cityName;
                  this.invoiceInfoVOs1.regionName =
                    this.receivingAddress.regionName;
                  this.invoiceInfoVOs1.addr = this.receivingAddress.addr;
                } else {
                  //增值税发票
                  this.increInvo = Object.assign({}, invoiceInfoVOs); // 赋值增值发票
                  this.invoiceInfoVOs2 = invoiceInfoVOs;
                  this.invoiceInfoVOs2Form.companyName1 =
                    this.invoiceInfoVOs2.companyName;
                  this.invoiceInfoVOs2Form.taxpayerNumber1 =
                    this.invoiceInfoVOs2.taxpayerNumber;
                  this.invoiceInfoVOs2Form.address1 =
                    this.invoiceInfoVOs2.address;
                  this.invoiceInfoVOs2Form.tel1 = this.invoiceInfoVOs2.tel;
                  this.invoiceInfoVOs2Form.bankName1 =
                    this.invoiceInfoVOs2.bankName;
                  this.invoiceInfoVOs2Form.bankCard1 =
                    this.invoiceInfoVOs2.bankCard;
                  this.sendInvoForm.linkman = this.invoiceInfoVOs2.linkman; //发票寄送人姓名
                  this.sendInvoForm.sendTel = this.invoiceInfoVOs2.sendTel; //发票寄送电话
                  //默认收货地址
                  this.sendInvoForm.selectedOptions.push(
                    this.receivingAddress.provinceId.toString(),
                    this.receivingAddress.cityId.toString(),
                    this.receivingAddress.regionId.toString()
                  );
                  this.sendInvoForm.addr = this.receivingAddress.addr;
                  /*初始化显示*/
                  this.invoiceInfoVOs2.provinceId =
                    this.receivingAddress.provinceId;
                  this.invoiceInfoVOs2.cityId = this.receivingAddress.cityId;
                  this.invoiceInfoVOs2.regionId =
                    this.receivingAddress.regionId;
                  this.invoiceInfoVOs2.provinceName =
                    this.receivingAddress.provinceName;
                  this.invoiceInfoVOs2.cityName =
                    this.receivingAddress.cityName;
                  this.invoiceInfoVOs2.regionName =
                    this.receivingAddress.regionName;
                  this.invoiceInfoVOs2.addr = this.receivingAddress.addr;
                }
              }
            }

            this.loading = false;
            resolve();
          },
          1,
          10,
          (res) => {
            if (res.data.code == "OS1096" || res.data.code == "OS1098") {
              this.$alert(res.data.message, "提示信息", {
                confirmButtonText: "确定",
                callback: (action) => {
                  this.$router.push({ path: basePath + "/shop/shopIndex" });
                },
              });
            } else {
              // this.$message({
              //     type: 'warning',
              //     message: res.data.message,
              //     duration: 1000
              // })
            }
            this.loading = false;
            reject();
          }
        );
      });
    },
    setName(arr) {
      this.arrNameList = arr;
    },
    handleInvoiceSave() {
      if (this.radio == "1") {
        //普通发票
        this.$refs.invoiceInfoVOs1Form.validate((valid) => {
          this.$refs.sendInvoForm.validate((valid2) => {
            if (valid2 && valid) {
              this.invoiceSave1 = true;
              this.invoiceSave2 = false;
              this.invoiceInfoVOs1.companyHead = this.invoiceInfoVOs1Form.item;
              this.invoiceInfoVOs1.taxpayerNumber =
                this.invoiceInfoVOs1Form.taxpayerNumber1;
              this.invoiceInfoVOs1.content = 2;
              this.invoiceInfoVOs1.addr = this.sendInvoForm.addr;
              this.invoiceInfoVOs1.sendTel = this.sendInvoForm.sendTel;
              this.invoiceInfoVOs1.linkman = this.sendInvoForm.linkman;
              this.invoiceInfoVOs1.provinceId =
                this.sendInvoForm.selectedOptions[0];
              this.invoiceInfoVOs1.cityId =
                this.sendInvoForm.selectedOptions[1];
              this.invoiceInfoVOs1.regionId =
                this.sendInvoForm.selectedOptions[2];
              this.invoiceInfoVOs1.provinceName = this.arrNameList[0];
              this.invoiceInfoVOs1.cityName = this.arrNameList[1];
              this.invoiceInfoVOs1.regionName = this.arrNameList[2];
              this.dialogVisible = false;
            } else {
              return false;
            }
          });
        });
      } else {
        //增值发票
        this.$refs.invoiceInfoVOs2Form.validate((valid) => {
          this.$refs.sendInvoForm.validate((valid2) => {
            if (valid2 && valid) {
              this.invoiceSave2 = true;
              this.invoiceSave1 = false;
              this.invoiceInfoVOs2.companyName =
                this.invoiceInfoVOs2Form.companyName1;
              this.invoiceInfoVOs2.taxpayerNumber =
                this.invoiceInfoVOs2Form.taxpayerNumber1;
              this.invoiceInfoVOs2.address = this.invoiceInfoVOs2Form.address1;
              this.invoiceInfoVOs2.tel = this.invoiceInfoVOs2Form.tel1;
              this.invoiceInfoVOs2.bankName =
                this.invoiceInfoVOs2Form.bankName1;
              this.invoiceInfoVOs2.bankCard =
                this.invoiceInfoVOs2Form.bankCard1;
              this.invoiceInfoVOs2.content = 2;
              this.invoiceInfoVOs2.addr = this.sendInvoForm.addr;
              this.invoiceInfoVOs2.sendTel = this.sendInvoForm.sendTel;
              this.invoiceInfoVOs2.linkman = this.sendInvoForm.linkman;
              this.invoiceInfoVOs2.provinceId =
                this.sendInvoForm.selectedOptions[0];
              this.invoiceInfoVOs2.cityId =
                this.sendInvoForm.selectedOptions[1];
              this.invoiceInfoVOs2.regionId =
                this.sendInvoForm.selectedOptions[2];
              this.invoiceInfoVOs2.provinceName = this.arrNameList[0];
              this.invoiceInfoVOs2.cityName = this.arrNameList[1];
              this.invoiceInfoVOs2.regionName = this.arrNameList[2];
              this.dialogVisible = false;
            } else {
              return false;
            }
          });
        });
      }
    },
    auditAddress(body) {
      axiosApi(
        "/trader/address/audit",
        body,
        (res) => {
          if (res.data.data && res.data.data.message) {
            this.$confirm(res.data.data.message, "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            })
              .then(() => {
                this.addBuyerDefaultAddress(body);
                this.addressDialog = false;
              })
              .catch(() => {
                this.$message({
                  type: "info",
                  message: "已取消",
                });
              });
          }else{
            this.addBuyerDefaultAddress(body);
            this.addressDialog = false;
          }
        },
        null,
        null,
        (err) => {
          this.$message.error(res.data.message);
        }
      );
    },

    addBuyerDefaultAddress(body) {
      //添加收货地址
      const api = "/trader/address/add";
      body.addressType = 3;
      axiosApi(
        api,
        body,
        (res) => {
          this.$message({
            type: "success",
            message: "操作成功",
            duration: 1000,
          });
          let listBody = {
            traderId: this.preOrderInfo.buyerId,
          };

          listBody.addressType = 3;

          axiosApi(
            this.isHotel
              ? "/trader/address/list"
              : "/trader/orderDeliveryAddress/select",
            listBody,
            (res) => {
              // console.log(res, 'addressList')
              this.addressPosList = res.data.data; //返回 的 全部地址 数组
              if (res.data.data[0].state == 2) {
                this.receivingAddress = res.data.data[0];
              }
            },
            1,
            10,
            (res) => {
              this.$message({
                type: "error",
                message: res.data.message,
                duration: 1000,
              });
            }
          );
        },
        1,
        10,
        (res) => {
          this.$message.error(res.data.message);
        }
      );
    },
    changeName(e) {
      this.areaNameList = e;
    },
    handleAddressSave() {
      //保存添加的地址
      this.$refs.addressForm.validate((valid) => {
        if (valid) {
          let body = Object.assign(this.addressForm, {
            traderId: this.preOrderInfo.buyerId,
            provinceId: this.addressForm.selectedOptions[0],
            cityId: this.addressForm.selectedOptions[1],
            regionId: this.addressForm.selectedOptions[2],
            streetId: this.addressForm.selectedOptions[3],
            provinceName: this.areaNameList[0],
            cityName: this.areaNameList[1],
            regionName: this.areaNameList[2],
            streetName: this.areaNameList[3],

            isDefault: this.isDefault == true ? 1 : 0, //是否设为默认
            addressType: 3,
          });
          if (!this.isHotel) {
            // 非酒店详细地址 传值 attr ，酒店 详细地址 传值 addr
            body.addr = body.addr;
          }
          this.auditAddress(body);
        } else {
          return false;
        }
      });
    },
    resetForm() {
      this.$refs.addressForm.resetFields();
      this.fourLists = [];
      this.addressForm.streetId = "";
      this.addressForm.streetName = "";
      this.selectedOptions = [];
    },
    orderDeliveryAddress() {
      //查询全部地址
      let body = { traderId: this.preOrderInfo.buyerId };
      if (this.isHotel) {
        body.addressType = 3;
      }
      axiosApi(
        this.isHotel
          ? "/trader/address/list"
          : "/trader/orderDeliveryAddress/select",
        body,
        (res) => {
          // console.log(res, 'addressList')
          this.addressPosList = res.data.data; //返回 的 全部地址 数组
          this.addressRadio = res.data.data[0]; //默认选中第一个
          this.$message({
            type: "success",
            message: "操作成功",
            duration: 1000,
          });
        },
        1,
        10,
        (res) => {
          this.$message({
            type: "error",
            message: res.data.message,
            duration: 1000,
          });
        }
      );
    },
    updateBuyerDefaultAddress(body) {
      //进货商的地址设为默认（收货）
      const api = "/trader/address/setDefault";
      axiosApi(
        api,
        body,
        (res) => {
          this.orderDeliveryAddress();
        },
        1,
        10,
        (res) => {
          // console.log(res)
        }
      );
    },
    handleUpdateAddress(id) {
      //地址设为默认按钮操作
      let body = {
        id: id, //id地址id
        traderId: this.preOrderInfo.buyerId, //商户id,买家id
        isDefault: 1, //设为默认
      };
      this.updateBuyerDefaultAddress(body);
    },
    // 更换地址
    handleChangeAddress() {
      this.receivingAddress = this.addressRadio;
      this.getCalculate()
      this.changeAddressVisible = false;
      this.checkAddressDialog = false;
    },
    getCalculate(){
        let goodsList = []
        let list1 = this.preOrderInfo.cartLiExPo[0].cartExPos;
        if(list1.length){
          goodsList = list1.map(item=>{
              return {
                  isIntegerMultiple:item.isIntegerMultiple,
                  num:item.num,
                  specInfoNum:item.specInfoNum,
                  skuName:item.skuName,
                  skuNo:item.skuNo,
              }
          })
        }
        var list = [...goodsList]
        var data = {
            buyerId: this.preOrderInfo.buyerId,
            addrRegionId: this.receivingAddress.regionId,
            goodsInfoParamList: list
        } 
        getCalculate(data).then(res=>{
            this.cartLiExPo[0].freight = res
            this.freight = res
        })
    },
    addOrder(body, instance = {}) {
      //生成订单
      if (!this.checkAddressDialog) {
        axiosApi(
          "/order/add/checkAddr",
          {
            addressId: body.addressId,
            buyerId: body.buyerId,
            sellerId: this.cartLiExPo[0].sellerId,
          },
          (res) => {
            this.confirmOrder(body, instance);
          },
          1,
          1,
          (err) => {
            this.$confirm(err.data.message, "提示", {
              confirmButtonText: "确定",
              showCancelButton: false,
              type: "warning",
            })
              .then(() => {
                this.checkAddressDialog = true;
                instance.confirmButtonLoading = false;
              })
              .catch(() => {});
          }
        );
      } else {
        this.confirmOrder(body, instance);
      }
    },
    confirmOrder(body, instance) {
      this.loading = true;
      const api = "/anon/order/add";
      axiosApi(
        api,
        body,
        (res) => {
          sessionStorage.setItem("xToken", res.data.context.xToken); //xToken
          instance.confirmButtonLoading = false;
          this.loading = false;
          /*sessionStorage.setItem('orderSn', res.data.data);
					if (this.paymentOnBehalf) {
						this.$router.push({path: basePath + '/payOrder', query: {paymentOnBehalf: 1}});
						return false
					}
					this.$router.push(basePath + '/payOrder')*/
          if (res.data.data.length == 1) {
            sessionStorage.setItem("orderSn", res.data.data[0].parentOrderSn);
            if (res.data.data[0].paymentOnBehalf) {
              //开启账单支付
              this.$router.push({
                path: basePath + "/payOrder",
                query: { paymentOnBehalf: 1 },
              });
            } else {
              this.$router.push(basePath + "/payOrder");
            }
          } else {
            let array = [];
            res.data.data.map((v) => {
              let obj = {
                orderSn: v.parentOrderSn,
                // needPayMoney:v.needPayMoney,
                // orderPayStatus:null
              };
              array.push(obj);
            });
            sessionStorage.setItem("orderInfo", JSON.stringify(array));
            this.$router.push(basePath + "/prePay");
          }
        },
        1,
        10,
        (res) => {
          instance.confirmButtonLoading = false;
          this.loading = false;
          sessionStorage.setItem("xToken", res.data.context.xToken); //xToken
          if (res.data.code == "0S1077") {
            //1075  赠品不足
            let response = res.data.data.insufficientGifts || [];
            response.forEach((item) => {
              this.fullGiftList.forEach((v) => {
                v.groupActivityGifts.forEach((v1) => {
                  if (
                    item.activityId == v1.activityId &&
                    item.groupSort == v1.groupSort
                  ) {
                    v1.shortStock = true;
                    v1.giftExs.forEach((v2) => {
                      v2.shortStock = true;
                    });
                  }
                });
              });
            });
            response.forEach((item) => {
              this.giftExs.forEach((v) => {
                if (
                  item.activityId == v.activityId &&
                  item.groupSort == v.groupSort
                )
                  this.$set(v, "shortStock", true);
              });
            });
            this.giftExs = this.giftExs.map((v) => v);
            this.$alert(
              "赠品库存不足，无法赠送请确认订单或重新选择赠品",
              "提示",
              {
                confirmButtonText: "确定",
                callback: (action) => {},
              }
            );
          } else if (res.data.code == "OS1093" || res.data.code == "OS1094") {
            //k3价格变动
            this.$alert(res.data.message, "提示", {
              confirmButtonText: "确定",
              callback: (action) => {
                window.location.reload();
              },
            });
            // this.$confirm(res.data.message, '提示', {
            //     confirmButtonText: '确定',
            //     cancelButtonText: '取消',
            //     type: 'warning'
            // }).then(() => {
            //     window.location.reload();
            // }).catch(() => {
            //
            // });
          } else if (res.data.code == "OS1099") {
            this.$confirm(res.data.message, "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            })
              .then(() => {
                this.$router.push(this.basePath + "/shop/shopingCar");
              })
              .catch(() => {});
          } else {
            this.$message({
              type: "error",
              message: res.data.message,
              duration: 1000,
            });
          }
        }
      );
    },
    cmMsgbox(content, showCancel, body, invoFunc, orderFunc) {
      //自定义 msgbox
      const h = this.$createElement;
      this.$msgbox({
        title: "提示信息",
        message: h(
          "p",
          {
            style: "text-align:center",
          },
          content
        ),
        showCancelButton: showCancel,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = "执行中...";
            invoFunc(body, instance); //调用保存发票接口
            // orderFunc(instance)  //提交订单
            done();
          } else {
            orderFunc(); //提交订单按钮操作
            done();
          }
          delete instance.$slots.default;
        },
      });
      return false;
    },
    invoInsert(body, instance) {
      //调用保存发票接口
      //console.log('调用保存发票接口')
      axiosApi(
        "/trader/traderInvoice/addOrUpdate",
        body,
        (res) => {
          if (instance) instance.confirmButtonLoading = false;
          this.handleAddOrder();
        },
        1,
        1,
        (err) => {
          if (instance) instance.confirmButtonLoading = false;
          this.$message.error(
            err.data.data ? err.data.data.message : err.data.message
          );
        }
      );
    },
    objEquals(x, y) {
      var in1 = x instanceof Object;
      var in2 = y instanceof Object;
      if (!in1 || !in2) {
        return x === y;
      }
      if (Object.keys(x).length !== Object.keys(y).length) {
        return false;
      }
      for (var p in x) {
        var a = x[p] instanceof Object;
        var b = y[p] instanceof Object;
        if (a && b) {
          return equals(x[p], y[p]);
        } else if (x[p] !== y[p]) {
          return false;
        }
      }
      return true;
    },
    isChangeInvoice() {
      //判断用户是否修改过发票信息
      let com = Object.keys(this.comInvo).length > 0; //是否有普通发票
      let incre = Object.keys(this.increInvo).length > 0; //是否有增值发票
      if (this.invoiceChecked) {
        //选中发票
        if (
          Object.keys(this.invoiceInfoVOs1).length <= 0 &&
          Object.keys(this.invoiceInfoVOs2).length <= 0
        ) {
          const h = this.$createElement;
          this.$msgbox({
            title: "提示信息",
            message: h(
              "p",
              {
                style: "text-align:center",
              },
              "您选择了需要发票，但是没有填写发票信息！"
            ),
            showCancelButton: false,
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            beforeClose: (action, instance, done) => {
              if (action === "confirm") {
                done();
              } else {
                done();
              }
              delete instance.$slots.default;
            },
          });
          return false;
        }
        if (this.invoiceSave1) {
          //普通发票
          let body = {
            invoiceType: 1, //发票类型
            companyHead: this.invoiceInfoVOs1.companyHead, //抬头
            content: 2, //内容
            taxpayerNumber: this.invoiceInfoVOs1.taxpayerNumber, //纳税人识别码
            traderId: this.preOrderInfo.buyerId,
            sendTel: this.sendInvoForm.sendTel, //收票手机号
            linkMan: this.sendInvoForm.linkman, //收票人
          };
          body.sendProvinceId = this.invoiceInfoVOs1.provinceId;
          body.sendCityId = this.invoiceInfoVOs1.cityId;
          body.sendRegionId = this.invoiceInfoVOs1.regionId;
          body.sendProvinceName = this.invoiceInfoVOs1.provinceName;
          body.sendCityName = this.invoiceInfoVOs1.cityName;
          body.sendRegionName = this.invoiceInfoVOs1.regionName;
          body.sendAddress = this.invoiceInfoVOs1.addr;
          if (!com) {
            //没有存储普通发票信息
            this.cmMsgbox(
              "发票信息是否需要保存？",
              true,
              body,
              this.invoInsert,
              this.handleAddOrder
            );
          } else {
            //有普通发票信息
            /*省市区ID转化为number,名称去除空格*/
            this.invoiceInfoVOs1.provinceId =
              this.invoiceInfoVOs1.provinceId * 1;
            this.invoiceInfoVOs1.cityId = this.invoiceInfoVOs1.cityId * 1;
            this.invoiceInfoVOs1.regionId = this.invoiceInfoVOs1.regionId * 1;
            this.invoiceInfoVOs1.provinceName =
              this.invoiceInfoVOs1.provinceName.trim();
            this.invoiceInfoVOs1.cityName =
              this.invoiceInfoVOs1.cityName.trim();
            this.invoiceInfoVOs1.regionName =
              this.invoiceInfoVOs1.regionName.trim();
            let isChange = this.objEquals(this.invoiceInfoVOs1, this.comInvo); //比较 原数据是否改变
            if (!isChange) {
              //需要更新
              this.cmMsgbox(
                "发票信息是否需要更新？",
                true,
                body,
                this.invoInsert,
                this.handleAddOrder
              );
            } else {
              //不需要更新
              this.handleAddOrder();
            }
          }
        } else if (this.invoiceSave2) {
          //增值发票
          let body = {
            invoiceType: 2, //发票类型
            companyName: this.invoiceInfoVOs2.companyName,
            taxpayerNumber: this.invoiceInfoVOs2.taxpayerNumber, //纳税人识别码
            address: this.invoiceInfoVOs2.address,
            tel: this.invoiceInfoVOs2.tel,
            bankName: this.invoiceInfoVOs2.bankName,
            bankCard: this.invoiceInfoVOs2.bankCard,
            traderId: this.preOrderInfo.buyerId,
            content: 2,
            sendTel: this.sendInvoForm.sendTel, //收票手机号
            linkMan: this.sendInvoForm.linkman, //收票人
          };
          body.sendProvinceId = this.invoiceInfoVOs2.provinceId;
          body.sendCityId = this.invoiceInfoVOs2.cityId;
          body.sendRegionId = this.invoiceInfoVOs2.regionId;
          body.sendProvinceName = this.invoiceInfoVOs2.provinceName;
          body.sendCityName = this.invoiceInfoVOs2.cityName;
          body.sendRegionName = this.invoiceInfoVOs2.regionName;
          body.sendAddress = this.invoiceInfoVOs2.addr;
          if (!incre) {
            //没有存储普通发票信息
            this.cmMsgbox(
              "发票信息是否需要保存？",
              true,
              body,
              this.invoInsert,
              this.handleAddOrder
            );
          } else {
            this.increInvo.content = 2;
            /*省市区ID转化为number,名称去除空格*/
            this.invoiceInfoVOs2.provinceId =
              this.invoiceInfoVOs2.provinceId * 1;
            this.invoiceInfoVOs2.cityId = this.invoiceInfoVOs2.cityId * 1;
            this.invoiceInfoVOs2.regionId = this.invoiceInfoVOs2.regionId * 1;
            this.invoiceInfoVOs2.provinceName =
              this.invoiceInfoVOs2.provinceName.trim();
            this.invoiceInfoVOs2.cityName =
              this.invoiceInfoVOs2.cityName.trim();
            this.invoiceInfoVOs2.regionName =
              this.invoiceInfoVOs2.regionName.trim();
            let temp = this.objEquals(this.invoiceInfoVOs2, this.increInvo);
            if (!temp) {
              //需要更新
              this.cmMsgbox(
                "发票信息是否需要更新？",
                true,
                body,
                this.invoInsert,
                this.handleAddOrder
              );
            } else {
              //不需要更新
              this.handleAddOrder();
            }
          }
        }
      } else {
        this.handleAddOrder();
      }
    },
    handleAddOrder(instance = {}) {
      //提交订单按钮操作
      if (!this.receivingAddress.id) {
        this.$message({ type: "error", message: "请选择收货地址" });
        return false;
      }
      // if (this.deliverySeparate && !this.sendGoodsForm.gmtDelivery) {
      // 	this.$message({type:'error',message:'请选择首次发货时间'})
      // 	return false
      // }
      // 分批发货并且需要首次发货

      let arr = JSON.parse(JSON.stringify(this.sendGoodsForm.deliveryItemList));
      arr.forEach((v, i) => {
        if (v.skuGift) {
          // 赠品
          if (
            v.giftType == 0 &&
            !v.isIntegerMultiple &&
            Math.floor(v.remainingSpecNum / v.changeCoefficient) > 0
          ) {
            if (v.specUnit == "箱") {
              this.$set(arr[i], "specNum", v.specNum * v.changeCoefficient);
            }
          }
          if (
            v.giftType == 0 &&
            !v.isIntegerMultiple &&
            Math.floor(v.remainingSpecNum / v.changeCoefficient) <= 0
          ) {
            this.$set(arr[i], "specUnit", v.salesUnit);
          }
        } else {
          // 商品
          if (
            !v.isIntegerMultiple &&
            Math.floor(v.remainingSpecNum / v.changeCoefficient) > 0
          ) {
            if (v.specUnit == "箱") {
              this.$set(arr[i], "specNum", v.specNum * v.changeCoefficient);
            }
          }
          if (
            !v.isIntegerMultiple &&
            Math.floor(v.remainingSpecNum / v.changeCoefficient) <= 0
          ) {
            this.$set(arr[i], "specUnit", v.salesUnit);
          }
        }
      });
      const goodsList =
          this.deliverySeparate && this.oneDeliverySeparate === "1"
            ? arr.filter((n) => n.specNum > 0)
            : [],
        time =
          this.deliverySeparate && this.oneDeliverySeparate === "1"
            ? new Date(this.sendGoodsForm.gmtDelivery).getTime()
            : null;
      let body = {
        addressId: this.receivingAddress.id,
        buyerId: this.preOrderInfo.buyerId,
        orderInsertPos: [],
        source: 1,
        type: this.pretype,
        // orderType: this.orderType,
        // 分批发货
        deliverySeparate: this.deliverySeparate, // 是否分批发货
        openStraight: this.openStraight, // 是否商品直发
        // gmtDelivery: time, // 首次发货时间
        //					deliveryItemList: goodsList  设置首次提货
        deliveryItemList: [], //首次提货为否
      };

      /*酒店用户source赋值为8 */
      if (this.isHotel) {
        body.source = 8;
      }
      let orderItemInsertPos = [];
      if (this.cartLiExPo[0] && this.cartLiExPo[0].cartExPos)
        this.cartLiExPo[0].cartExPos.forEach((item) => {
          if (item.changePurchaseItem) {
            // 换购，必购商品
            orderItemInsertPos.push({
              activityId: item.activityId,
              activityName: item.activityName,
              // unitType: item.unitType,
              skuNo: item.skuNo,
              skuName: item.skuName,
              num: item.num,
              price: item.price,
              isIntegerMultiple: item.isIntegerMultiple,
            });
          } else {
            orderItemInsertPos.push({
              skuNo: item.skuNo,
              skuName: item.skuName,
              num: item.num,
              isIntegerMultiple: item.isIntegerMultiple,
            });
          }
        });
      //				let invoiceInfoVO = {}
      if (this.invoiceChecked) {
        //选中发票
        this.invoiceInfoVO.content = 2;
        if (this.invoiceSave1) {
          //普通发票
          this.invoiceInfoVO.companyHead = this.invoiceInfoVOs1.companyHead;
          this.invoiceInfoVO.taxpayerNumber =
            this.invoiceInfoVOs1.taxpayerNumber;
          this.invoiceInfoVO.id = this.invoiceInfoVOs1.id;
          this.invoiceInfoVO.invoiceType = 1;
          /*收票地址*/
          this.invoiceInfoVO.sendTel = this.invoiceInfoVOs1.sendTel;
          this.invoiceInfoVO.linkman = this.invoiceInfoVOs1.linkman;
          this.invoiceInfoVO.provinceId = this.invoiceInfoVOs1.provinceId;
          this.invoiceInfoVO.cityId = this.invoiceInfoVOs1.cityId;
          this.invoiceInfoVO.regionId = this.invoiceInfoVOs1.regionId;
          this.invoiceInfoVO.provinceName = this.invoiceInfoVOs1.provinceName;
          this.invoiceInfoVO.cityName = this.invoiceInfoVOs1.cityName;
          this.invoiceInfoVO.regionName = this.invoiceInfoVOs1.regionName;
          this.invoiceInfoVO.addr = this.invoiceInfoVOs1.addr;
        } else {
          this.invoiceInfoVO.id = this.invoiceInfoVOs2.id;
          this.invoiceInfoVO.invoiceType = 2;
          this.invoiceInfoVO.companyHead = this.invoiceInfoVOs2.companyName;
          this.invoiceInfoVO.taxpayerNumber =
            this.invoiceInfoVOs2.taxpayerNumber;
          this.invoiceInfoVO.address = this.invoiceInfoVOs2.address;
          this.invoiceInfoVO.tel = this.invoiceInfoVOs2.tel;
          this.invoiceInfoVO.bankName = this.invoiceInfoVOs2.bankName;
          this.invoiceInfoVO.bankCard = this.invoiceInfoVOs2.bankCard;
          /*收票地址*/
          this.invoiceInfoVO.provinceId = this.invoiceInfoVOs2.provinceId;
          this.invoiceInfoVO.cityId = this.invoiceInfoVOs2.cityId;
          this.invoiceInfoVO.regionId = this.invoiceInfoVOs2.regionId;
          this.invoiceInfoVO.provinceName = this.invoiceInfoVOs2.provinceName;
          this.invoiceInfoVO.cityName = this.invoiceInfoVOs2.cityName;
          this.invoiceInfoVO.regionName = this.invoiceInfoVOs2.regionName;
          this.invoiceInfoVO.sendTel = this.invoiceInfoVOs2.sendTel;
          this.invoiceInfoVO.linkman = this.invoiceInfoVOs2.linkman;
          this.invoiceInfoVO.addr = this.invoiceInfoVOs2.addr;
        }
      }
      let orderInsertPoObj = {
        sellerId: this.cartLiExPo[0].sellerId, //卖家ID
        remark: this.remark,
        remarkPics: this.changeImage(),
        couponId: this.couponItem.id,
        buyerCouponId: this.couponItem.buyerCouponId,
        invoiceParam: this.invoiceInfoVO,
        orderItemInsertPos: orderItemInsertPos,
      };
      body.orderInsertPos.push(orderInsertPoObj);
      //  传值 选中的赠品组合  groupActivityGifts
      let groupActivityGifts = [];
      this.checkGiftGoodBackup.forEach((v) => {
        if (!(v.shortStock && !this.deliverySeparate && !this.openStraight)) {
          //非分批，直发且库存不足 不需要传值
          groupActivityGifts.push({
            activityId: v.activityId,
            groupSort: v.groupSort,
            dealerId: v.dealerId,
            giftExs: v.giftExs.map((obj) => {
              return { giftSkuNo: obj.giftSkuNo, quantity: obj.quantity };
            }),
          });
        }
      });
      body.groupActivityGifts = groupActivityGifts;
      body.xToken = sessionStorage.getItem("xToken");
      body.isUseDeduction = this.isUseDeduction;
      // end 选中的赠品组合
      this.addOrder(body, instance);
    },
  },
};
</script>

<style lang="less">
    .gift_team_dialog {
        .ml-38 {
            margin-left: 38px;
        }
    }

    .el-radio-group {
        font-size: 12px;
    }

    .will_buy_dialog .el-radio .el-radio__label {
        display: none
    }

    .will_buy_dialog {
        .el-dialog {
            width: 995px;
            min-height: auto;

            .el-dialog__body {
                padding-left: 30px;
                padding-right: 30px;
            }
        }

        .el-dialog__footer {
            position: static;
            transform: translateX(0);
            padding-bottom: 30px;
        }

        .el-dialog__headerbtn {
            position: absolute;
            top: 16px;
        }

        .el-tabs__header {
            padding-left: 0px;
            border-bottom: 1px solid #dddddd;

            .el-tabs__nav-prev, .el-tabs__nav-next {
                line-height: 38px;
            }
        }

        .el-tabs__nav {
            white-space: nowrap;
        }

        .customer_table_title {
            background-color: #f5f5f5;
            height: 40px;
            line-height: 40px;
            font-weight: 600;
            border: 1px solid #ddd;
            border-right: none;

            .cell {
                padding-left: 20px;
                padding-right: 20px;
                box-sizing: border-box;
                border-right: 1px solid #ddd;
            }

            .product {
                width: 500px;
            }

            .money {
                width: 200px;
            }

            .num {
                width: 200px;
            }
        }

        .customer_table_body {

            .cell {
                border: 1px solid #ddd;
                border-right: none;
                border-top: none;

                .w-60 {
                    width: 60px;
                    height: 60px;
                }

                .cell_cell {
                    box-sizing: border-box;
                    padding: 10px 20px;
                    border-right: 1px solid #ddd;
                    min-height: 80px;
                }

                .item_1 {
                    width: 500px;
                }

                .item_2 {
                    width: 200px;
                }

                .item_3 {
                    width: 200px;
                }

                .el-checkbox__label {
                    display: none;
                }
            }
        }
    }

    .change_buy {
        width: 50px;
        text-align: center;
        background-color: #ff4201;
        height: 20px;
        line-height: 20px;
        margin-top: 6px;
        border-radius: 10px;
        color: #fff;
    }

    .change_buy_btn {
        &:hover {
            color: #ff4201;
        }

        &:focus {
            color: #ff4201;
        }

        &:active {
            color: #ff4201;
        }
    }

    .dialog-w1000 {
        .el-dialog {
            width: 1000px;
        }

        .el-dialog__body {
            padding-left: 20px;
            padding-right: 20px;
        }
    }

    .billDialog {
        .el-dialog__title {
            margin-left: 65px;
        }

        .el-dialog__body {
            padding-bottom: 80px;
        }
    }

    [v-cloak] {
        display: none;
    }

    .list-enter-active,
    .list-leave-active {
        transition: all 1s;
    }

    .list-enter,
    .list-leave-active {
        opacity: 0;
        transform: translateY(-80px);
    }

    .color-F656 {
        color: #F6561F;
    }

    .lh-21 {
        line-height: 21px;
    }

    #store-confirm-order {
        .background-3 {
            background: #333333;
        }


        width: 1280px;
        margin: 0 auto;
        padding: 0 45px;
        box-sizing: border-box;

        .orderCommit {
            width: 200px;
            height: 50px;
            font-size: 20px;
            margin-right: 95px;
            margin-bottom: 85px;
            border-radius: 0;
        }

        .hide {
            display: none;
            transition: all .5s;
        }
    }

    #store-confirm-order .logo1 {
        height: 114px;

        img {
            width: 190px;
            height: 39px;
            padding-top: 38px;
        }

        .order-state {
            width: 370px;
            height: 30px;
            margin-right: 115px;
            margin-left: 42px;
            background-image: url(../../assets/order-state.png);
        }

        .order-state-info {
            line-height: 16px;
            margin-left: 25px;

            .ml-107 {
                margin-left: 107px;
            }
        }
    }

    #store-confirm-order {
        .giftPriceClass{
            text-decoration:line-through;
        }
        .w240 {
            width: 240px;
        }

        .order-content {
            .order-table {
                border: none;

                .el-table__body-wrapper {
                    display: none;
                }
            }

            .table-title {
                width: 1000px;
                height: 40px;
                line-height: 40px;
                background-color: #EEEEEE;

                li {
                    float: left;
                    box-sizing: border-box;
                    font-weight: bold;
                    font-size: 12px;
                }

                .t1 {
                    width: 480px;
                    padding-left: 40px;
                }

                .t2 {
                    width: 136px;
                    padding-left: 20px;
                }

                .t3 {
                    width: 100px;
                }

                .t4 {
                    width: 100px;
                }

                .t5 {
                    margin-left: 65px;
                }
            }

            .order-item-item-3 {
                width: 100px;
            }

            .message {
                margin-right: 95px;

                .input1 {
                    width: 400px;
                }

                .el-form {
                    .el-form-item {
                        width: 400px;
                        line-height: 14px;
                        margin-bottom: 10px;
                    }

                    .el-form-item__content {
                        line-height: 14px;
                    }

                    .el-form-item__label {
                        width: 70px;
                        line-height: 14px;
                        height: 14px;
                    }
                }
            }
        }

        /*交货日期CSS*/

        .delivery {
            .delivery-content {
                margin-left: 33px;

                .el-button--text {
                    width: auto;
                    height: auto;
                    line-height: 21px;
                }

                .invoice {
                    margin-left: 22px;
                }

                .billDialog {
                    .bill-type {
                        text-align: center;
                        margin-bottom: 52px;

                        .el-radio + .el-radio {
                            margin-left: 35px;
                        }
                    }

                    .send-info {
                        text-align: center;
                        margin-bottom: 20px;
                        font-size: 16px;
                    }

                    .el-form {
                        .el-form-item {
                            margin-bottom: 30px;
                        }

                        .el-form-item__label {
                            padding-right: 30px;
                        }
                    }
                }
            }
        }

        /*去除星号必填*/

        .noLabelSign {
            .el-form-item__label:before {
                content: "";
            }
        }

        /*订单总计*/

        .total {
            width: 1000px;
            margin-left: 95px;
            padding: 30px 60px 20px 0;
            border: 1px solid #DDDDDD;
            box-sizing: border-box;

            li {
                /*line-height: 14px;*/
                margin-bottom: 10px;
            }

            .payment {
                color: #D24C4C;
                line-height: 20px;
            }

            span {
                width: 150px;
            }
        }

        .total li > span {
            display: inline-block;
        }
    }

    #store-confirm-order .title {
        margin-right: 95px;
    }

    #store-confirm-order .address-btn {
        padding: 10px 20px;
        width: auto;
        height: auto;
    }

    #store-confirm-order .content {
        position: relative;
        margin-bottom: 10px;
        width: 1000px;
        border: 1px solid #DDD;
        height: 160px;
        margin-left: 95px;
        padding: 30px 30px 30px 0;
        box-sizing: border-box;
    }

    #store-confirm-order .content .el-form-item {
        margin-bottom: 15px;
    }

    #store-confirm-order .content .el-form-item__label {
        padding: 0;
        line-height: 14px;
        height: 14px;
        padding-right: 10px;
        width: 102px;
    }

    #store-confirm-order .content .el-form-item__content {
        line-height: 14px;
    }

    #store-confirm-order .content .el-form {
        width: 850px;
    }

    .text-btn {
        position: absolute;
        bottom: 30px;
        right: 30px;
        padding: 0;
        width: auto;
        height: auto;
    }

    #store-confirm-order .more-btn {
        margin-left: 95px;
    }

    #store-confirm-order .order-content {
        margin-left: 95px;
    }

    #store-confirm-order .item-content {
        border-top: 1px solid #DDD;
        width: 1000px;

        .will_buy_activity {
            border: 1px solid #dddddd;
            border-top: none;
        }

        .gift {
            position: relative;
        }

        .triangle-topleft {
            border-top: 60px solid #ff4201;
            width: 0;
            height: 0;
            border-right: 60px solid transparent;
            position: absolute;
            top: 20px;
            left: 20px;
        }

        .init {
            transform: rotate(-45deg) translate(-4px, 10px);
            position: absolute;
            left: 20px;
            top: 20px;
            color: #fff;
            letter-spacing: -0.34px;
        }
    }

    #store-confirm-order .item-item {
        /*// padding: 0px 50px 20px 20px;*/
        border: 1px solid #DDD;
        border-top: none;
        box-sizing: border-box;
    }

    #store-confirm-order .item-item .img {
        width: 90px;
        height: 90px;
    }

    #store-confirm-order .item-item .order-item-item {
        width: 460px;
    }

    #store-confirm-order .item-item .order-item-item-2 {
        width: 116px;
        padding-left: 20px;
    }

    .order-item-item-4 {
        width: 140px;
    }

    #store-confirm-order {
        .textBtn {
            width: auto;
            height: auto;
        }

        .addressClass {
            .el-dialog {
                width: 700px;

                .ml-104 {
                    margin-left: 104px;
                }

                .el-form-item__label {
                    width: 150px;
                    // margin-right: 20px;
                }

                .attr {
                    width: 300px;
                }

                .name,
                .phone {
                    padding-left: 20px;
                }
            }
        }

        .changeAddress {
            .el-radio {
                white-space: inherit;
            }

            .el-dialog {
                width: 700px;

                .el-dialog__body {
                    margin-bottom: 50px;

                    .el-button--text {
                        width: auto;
                        height: auto;
                    }
                }

                .el-button.is-disabled.el-button--text {
                    background: #bbb;
                }
            }
        }

        .hide {
            display: none;
        }
    }
</style>
