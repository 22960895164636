<template>
  <div>
    <!-- 搜索 -->
    <div>
      <el-form :inline="true" :model="form" class="demo-form-inline" label-width="auto">
        <el-form-item label="活动名称">
          <el-input v-model="form.title" placeholder="活动名称" clearable></el-input>
        </el-form-item>
        <el-form-item label="推送时间">
          <ys-daterange-picker v-model="form.value" type="date"/>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="search">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!-- 列表 -->
    <div style="margin-top:20px">
      <el-table :data="list" border style="width: 100%">
        <el-table-column  prop="title"  label="活动名称" > </el-table-column>
        <el-table-column  prop="Time"  label="推送时间" > </el-table-column>
        <el-table-column  prop=""  label="操作" align="center"> 
          <template slot-scope="{row}">
            <el-link type="primary" @click="tosentdetail(row.activityId,row.activityType)">查看详情</el-link>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- 分页 -->
    <div style="text-align:right">
      <el-pagination
        v-if="total>0"
        @current-change="CurrentChange"
        :current-page="form.pageIndex"
        :page-size="form.pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { axiosApi, basePath } from "~/api/api";
import { parseTime, computeNumber } from "@/utils";
import { getActivityNotice } from "~/api/newsPush";
export default {
  data(){
    return {
      form:{
        pageIndex: 1,
        pageSize:10,
        value:[],
        queryBeginDate: '',
        queryEndDate: '',
      },
      list:[{}],
      total:0,
    }
  },
  computed:{
    searchData(){
      // 处理搜索参数
      var data = Object.assign({},this.form)
      data.queryBeginDate = 
        this.form.value && this.form.value[0]
          ? this.form.value[0].getTime()
          : null;
      data.queryEndDate =
        this.form.value && this.form.value[1]
          ? this.form.value[1].getTime()
          : null;
      delete data.value
      return data
    }
  },
  mounted(){
    this.getList()
  },
  methods:{
    search(){
      this.form.pageIndex = 1
      this.getList()
    },
    CurrentChange(val){
      this.form.pageIndex = val
      this.getList()
    },
    getList(){
      getActivityNotice(this.searchData).then(res=>{
        var list = res.elements.map(item=>{
          item.Time = parseTime(item.pushTime,'{y}-{m}-{d} {h}:{i}')
          return item
        })
        this.list = list
        this.total = res.totalCount
      }).catch(err=>{

      })
    },
    tosentdetail(id,activityType) {
        
        if(activityType == 1||activityType == 4){
            sessionStorage.setItem('sentid',id)
            this.$router.push(basePath + '/activities/sentdetail')
        }else if(activityType == 2){
            sessionStorage.setItem('cutid',id)
            this.$router.push(basePath + '/activities/cutdetail')
        }
        
    },
  },
}
</script>

<style>

</style>