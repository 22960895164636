<template>
	<div id="dispatch" class="pr-40">
		<div class="title-tabs bg-f pl-20">
			<ul class="clearfix">
				<template v-for='(tab,index) in dataTabs' class="item">
					<li :type='index' :class="type==index?'active':''" @click='handleTabs'>
						{{tab}}
						<div :class="type==index?'under-line':''"></div>
					</li>
					<div class="vertical-line"></div>
				</template>
			</ul>
		</div>
		<div class='bg-f'>
			<el-form :inline="true" :model="formInlineData" label-width="90px" class="pl-20 clearfix pt-30">
				<div class="fl" style="width: 80%;">
					<el-form-item label="订单编号">
						<el-input v-model.trim="formInlineData.orderNo"></el-input>
					</el-form-item>
					<el-form-item label="订单商品">
						<el-input v-model.trim="formInlineData.goods"></el-input>
					</el-form-item>
					<el-form-item label="订单状态:" clearable>
						<el-select v-model="formInlineData.orderStatus" placeholder="请选择状态" clearable :disabled='dis'>
							<el-option label="全部订单" value=''></el-option>
							<el-option label="待发货" value='2'></el-option>
							<el-option label="已完成" value='4'></el-option>
							<el-option label="已关闭" value='0'></el-option>
							<el-option label="待付款" value='1'></el-option>
							<el-option label="已发货" value='3'></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="业代">
						<el-input v-model.trim="formInlineData.proxy" :disabled="isAdmin == 0 && isDepartmentLeader == 0"></el-input>
					</el-form-item>
					<el-form-item label='下单时间' prop="orderDateRange">
						<ys-daterange-picker v-model="formInlineData.orderDateRange" type="date" :endDisabledDate="new Date()"/>
					</el-form-item>
				</div>
				<el-button type="info" class="fl" @click='achieve(true)'>搜索</el-button>
				<!-- 导出 start -->
				<el-button type="text" 
				:style="{
					marginLeft: '0',
					marginTop: '73px',
					marginRight: '10px',
				}"
				@click="handleFileDownLoad" >
					导出订单
				</el-button>
				<form method='get' ref="fileDownLoadForm" action='/sunrise-gateway/export/order/factory' style="display: none">
					<input type="hidden" name="modelName" value="order.factory" />
					<input type="hidden" name="fileName" value="厂商订单.xls" />
					<input type="hidden" name="startRow" value="1" />
				</form>
				<!-- 导出 end -->
			</el-form>
			<!--<div class="clearfix">
				<span class="color-blue cursor fr mr-60">批量导出</span >
			</div>-->
		</div>
		<div class="bg-f pl-30 pr-30 pb-50" v-loading='Loading'>
			<el-checkbox v-model="checkAll" @change="handleCheckAllChange" class="checkedAll"></el-checkbox>
			<el-table>
				<el-table-column label='商品' width="280"></el-table-column>
				<el-table-column label='单价' width='80'></el-table-column>
				<el-table-column label='数量' width='60'></el-table-column>
				<el-table-column label='业代' width="90"></el-table-column>
				<el-table-column label='渠道商' width="90"></el-table-column>
				<el-table-column label='订单状态'></el-table-column>
				<el-table-column label='订单金额'></el-table-column>
				<el-table-column label='操作'></el-table-column>
			</el-table>
			<div class="table-item-default tac mt-50" v-if="messageList.length<=0">
				<img src='../../assets/empty.png' alt="" />
				<div class="color-danger mt-30">
					<router-link :to="{path:'/customer/shop/shopIndex'}"><span class="color-danger"> 啥都没有，去首页看看 ></span></router-link>
			</div>
		</div>
		<div v-else>
			<el-checkbox-group v-model="checkedShoping" @change="handleCheckedOptionChange">
				<div class='details mt-10' v-for="(item,index) in messageList" :key='index'>
					<div class="topTit">
						<el-checkbox class="checked mt-15" :label="item.id" :key="item.id">
							<span class="font-12 ml-50">订单编号：{{item.sn}}</span>
							<span class="font-12 ml-40">下单时间：{{item.gmtCreate | timenyr }}</span>
							<span v-if='item.orderStatus == 1 &&　item.remainAutoCancleTime != 0 && (item.lastestRemittanceAuditStatus == null || item.lastestRemittanceAuditStatus == 2)' class="font-12 time">还剩{{item.remainAutoCancleTime }}自动取消</span>
						</el-checkbox>
					</div>
					<div class="bd" style="overflow: hidden; border-top: none; position: relative;">
						<div class='shopingInfoClass clearfix'>
							<div class='shopingInfo clearfix' v-for='item1 in item.orderItemVos'>
								<img v-if='item1.image' class="shopingPhoto fl" :src="item1.image" alt="">
								<img v-else class="shopingPhoto fl" src='../../assets/goods.jpg' />
								<div class="shopingTit fl mt-20">
									<p>商品编码：{{item1.skuNo}}</p>
									<p>{{item1.skuName}}</p>
								</div>
								<span class="shopingPrice fl">{{item1.discountPrice}}</span>
								<span class="shopingCount fl ">{{item1.num}}</span>
							</div>
						</div>
						<div class='other'>
							<div class="subemployeename tac pt-50">{{item.managerName}}</div>
							<div class="canal tac pt-50">
								{{item.sellerName}}
							</div>
							<div class="status">
								<p class='tac mt-50' v-if='item.orderStatus == 0'>已关闭</p>
								<p class='tac mt-50' v-if='item.orderStatus == 1'>待付款</p>
								<p class='tac mt-50' v-if='item.orderStatus == 2'>待发货</p>
								<p class='tac mt-50' v-if='item.orderStatus == 3'>已发货</p>
								<p class='tac mt-50' v-if='item.orderStatus == 4'>已完成</p>
							</div>
							<div class="price tac">
								<p class="mt-40">￥{{item.needPayMoney}}</p>
								<p>(含运费:￥{{item.freightMoney}}元)</p>
							</div>
							<div class="handle tac font-12 pt-10 cursor">
								<div class='btn' v-if='item.orderStatus == 1 && item.lastestRemittanceAuditStatus != 1 && item.payStatus == 1 && showButton("PAY",200026)' @click="orderPay(item.sn)">付款</div>
								<div v-if='item.orderStatus == 1'>
									<div class='btn' v-if='item.payStatus == 1 && (item.lastestRemittanceAuditStatus == null || item.lastestRemittanceAuditStatus == 2) && showButton("CANCEL",200026)' @click="cancelBoom(index)">取消订单</div>
								</div>
								<div class='btn' 
								v-if='item.orderStatus == 2 && item.jdeSn == null && showButton("DELIVERY",200026)'
								@click='deliverGoods(index)'>发货</div>
								<div class='btn' v-if='item.orderStatus == 3 && showButton("CONFIRM",200026)' @click='confirmGoods(index)'>确认收货</div>
								<div v-if="item.lastestRemittanceAuditStatus == 1 && item.orderStatus !== 0" class="mt-10">
									<div class='btn' v-if='showButton("CHECK",200026)' @click='auditRemittance(item.sn,item.sellerId)'>审核付款凭证</div>
								</div>
								<div class='btn' @click='goToOrderDetails(item.sn)'>订单详情</div>
							</div>
						</div>
					</div>
				</div>
			</el-checkbox-group>
		</div>
		<el-pagination v-if='total>0' class="tar" :current-page="currentPage" :page-size='10' :total='total' layout="prev , pager, next, jumper" @current-change="handleCurrentChange">
		</el-pagination>
	</div>
	<!--取消订单弹出框-->
	<el-dialog class='cancelOrderDialog' :close-on-click-modal='false' title='取消订单理由' :visible.sync='cancelOrderVisible'>
		<el-form class="pl-30 pr-30" :model="formInlineData" ref="cancelOrderForm">
			<el-form-item label="" prop='cancleReason'>
				<el-input type='textarea' placeholder='请输入取消订单的理由...' :autosize="{ minRows: 3 ,maxRows: 5}" v-model.trim='formInlineData.cancleReason'></el-input>
			</el-form-item>
		</el-form>
		<div slot='footer' class="dialog-footer">
			<el-button type='danger' @click='cancleOrder'>提 交</el-button>
			<el-button @click="cancelOrderVisible = false">取 消</el-button>
		</div>
	</el-dialog>
	</div>

</template>

<script>
	import { axiosApi, basePath } from "../../api/api"
	// import YsDaterangePicker from "~/components/ys-daterange-picker";
	export default {
		// components: {YsDaterangePicker},
		data() {
			return {
				dis:false,
				messageList: [], //订单列表
				//分页
				currentPage: 1,
				total: 0,
				isPagination: true,
				over: false,
				type: '0',
				dataTabs: ['全部订单', '待付款', '待发货', '已发货', '已完成', '已关闭'],
				formInlineData: {
					goods: null,
					status: null,
					orderNo: null,
					proxy: null,
					orderStatus: '',
					gmtOrderStart: null,
					gmtOrderEnd: null,
					orderDateRange: [], //搜索时间数组
					sn: '', //取消订单入参:订单编号
					cancleReason: '', //取消订单理由
				},
				cancelOrderVisible: false, //控制取消订单弹出框dialogVisible
				activeName2: 'first',
				checkAll: false,
				checkedShoping: [],
				sellerId: null, //取消订单需要的sellerId
				Loading: true,
				isDepartmentLeader:null
			}
		},
		computed: {
			isAdmin() {
				return sessionStorage.getItem('isAdmin')
			},
		},
		methods: {
			handleFileDownLoad() {
				if(this.totalPages === 0){
					this.$message.warning('订单为空 无法导出');
					return;
				}
				this.$refs.fileDownLoadForm.submit();			
			},
			//tab切换
			handleTabs(e) {
				if(e.target.attributes.type) this.type = e.target.attributes.type.value
				if(this.type == 0) {
					this.formInlineData.orderStatus = ''
					this.dis = false
				}
				if(this.type == 1) {
					this.formInlineData.orderStatus = '1'
					this.dis = true
				}
				if(this.type == 2) {
					this.formInlineData.orderStatus = '2'
					this.dis = true
				}
				if(this.type == 3) {
					this.formInlineData.orderStatus = '3'
					this.dis = true
				}
				if(this.type == 4) {
					this.formInlineData.orderStatus = '4'
					this.dis = true
				}
				if(this.type == 5) {
					this.formInlineData.orderStatus = '0'
					this.dis = true
				}
				this.currentPage =1//切换标签页码重置为1
				this.achieve()
			},
			//订单列表
			achieve(bool) {
				if(bool&&this.currentPage != 1) {
					this.currentPage = 1;
					return
				}
				this.Loading = true
				this.formInlineData.gmtOrderStart = (this.formInlineData.orderDateRange[0] == undefined || this.formInlineData.orderDateRange[0] == '') ? null : this.formInlineData.orderDateRange[0].getTime()
				this.formInlineData.gmtOrderEnd = (this.formInlineData.orderDateRange[1] == undefined || this.formInlineData.orderDateRange[1] == '') ? null : this.formInlineData.orderDateRange[1].getTime()
				const api = "/sales/order/web/selectListPage";
				let subEmployeeName = null;
				if(this.isAdmin == 0 && this.isDepartmentLeader == 0){
					subEmployeeName = sessionStorage.getItem('loginName')
				} else {
					subEmployeeName = this.formInlineData.proxy
				}
				const body = {
					orderStatus: this.formInlineData.orderStatus,
					orderSn: this.formInlineData.orderNo,
					itemName: this.formInlineData.goods,
					subEmployeeName: subEmployeeName,
					gmtOrderStart: this.formInlineData.gmtOrderStart,
					gmtOrderEnd: this.formInlineData.gmtOrderEnd
				}
				axiosApi(api, body, this.callback, this.currentPage, 10)
			},
			callback(res) {
				this.messageList = res.data.data.elements
				this.total = res.data.data.totalCount
				this.totalPages = res.data.data.totalPages;
				//订单计时器
				this.messageList.forEach((item, index) => {
					if(item.remainAutoCancleTime != null && item.remainAutoCancleTime > 0) {
						let time = parseInt(item.remainAutoCancleTime / 1000)
						item.remainAutoCancleTime = this.timeString(time)
						item.clearTime = null
						item.clearTime = setInterval(() => {
							time--
							item.remainAutoCancleTime = this.timeString(time)
							if(time <= 0) {
								clearTimeout(item.clearTime)
								this.over = true
							}
						}, 1000)
					} else {
						item.remainAutoCancleTime = 0
					}
				})
				setTimeout(()=>{
					this.Loading = false
				},1000)
			},
			//			分页
			handleCurrentChange(val) {
				this.currentPage = val
				this.achieve()
			},
			//订单详情
			goToOrderDetails(orderSn) {
				let supplierOrderDetail = {
					type: 'supplierOrderDetail',
					orderSn: orderSn
				}
				sessionStorage.setItem('supplierOrderDetail', JSON.stringify(supplierOrderDetail))
				this.$router.push(basePath + '/cloudOrderDetails')
			},
			//取消订单弹出
			cancelBoom(index) {
				this.cancelOrderVisible = true,
					this.formInlineData.sn = this.messageList[index].sn,
					this.sellerId = this.messageList[index].sellerId
			},
			//取消订单
			cancleOrder() {
				let that = this;
				const api = "/sales/order/web/cancle";
				const body = {
					cancleType: 2,
					orderSn: this.formInlineData.sn,
					cancleReason: this.formInlineData.cancleReason,
					sellerId: this.sellerId
				};
				axiosApi(api, body, (res) => {
					this.$message({
						type: 'success',
						message: '取消成功!',
						duration: 1000
					});
					that.cancelOrderVisible = false,
						that.achieve();
				}, this.currentPage, 10, (res) => {
					this.$message({
						type: 'info',
						message: res.data.message,
						duration: 3000
					});
				})
			},
			//			发货
			deliverGoods(index) {
				let that = this;
				this.$confirm('你是否要进行发货？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					const api = "/sales/orderDelivery/web/confirm";
					const body = {
						orderSn: this.messageList[index].sn,
						sellerId: this.messageList[index].sellerId
					};
					axiosApi(api, body, (res) => {
						this.$message({
							type: 'success',
							message: '发货成功!',
							duration: 1000
						});
						that.achieve();
					}, this.currentPage, 10, (res) => {
						this.$message({
							type: 'info',
							message: res.data.message,
							duration: 3000
						});
					});
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消发货'
					});
				})
			},
			//			确认收货
			confirmGoods(index) {
				let that = this;
				this.$confirm('你是否确认收货？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					const api = "/sales/orderShip/app/confirm";
					const body = { orderSn: this.messageList[index].sn };
					axiosApi(api, body, (res) => {
						this.$message({
							type: 'success',
							message: '收货成功!',
							duration: 1000
						});
						that.achieve();
					}, this.currentPage, 10, (res) => {
						this.$message({
							type: 'info',
							message: res.data.message,
							duration: 3000
						});
					});
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消收货'
					});
				})
			},
			//去付款
			orderPay(orderSn) {
				sessionStorage.setItem('orderSn', orderSn)
				this.$router.push(basePath + '/cloudPayOrder')
			},
			//审核支付凭证Btn
			auditRemittance(orderSn, sellerId) {
				sessionStorage.setItem('mySellerId', sellerId),
					this.$router.push({
						path: basePath + '/cloudPayCertCheck',
						query: {
							sign: orderSn
						}
					})
			},
			handleCheckAllChange(val) {
				if(this.checkAll) {
					this.messageList.forEach(item => {
						this.checkedShoping.push(item.id)
					});
				} else {
					this.checkedShoping = [];
				}
			},
			handleCheckedOptionChange(value) {
				let checkedCount = value.length;
				this.checkAll = checkedCount === this.messageList.length;
			},
		},
		mounted() {
            this.resolveIsSalesDepartmentLeaderWithCache(res=>{
                console.log(res)
                this.isDepartmentLeader = res
            });
		},
		//进入加载
		activated() {
			this.$nextTick(function() {
				if(this.isAdmin == '0' && this.isDepartmentLeader == '0') {
					this.formInlineData.proxy = sessionStorage.getItem('employeeName')
				}
				this.achieve();
				sessionStorage.setItem('orderType', 'dispatch')
			})
		}
	}
</script>

<style lang="less">
	#dispatch {
		.btn {
			width: 75px;
			height: 25px;
			line-height: 25px;
			margin-left: 4px;
			text-align: center;
			border: 1px solid #DDDDDD;
			margin-bottom: 10px;
		}
		.checkedAll {
			position: relative;
			top: 30px;
			left: 30px;
		}
		.checked {
			position: relative;
			top: -20px;
			left: 30px;
		}
		.cell {
			text-align: center;
		}
		.details {
			margin-top: 15px;
			min-height: 165px;
			.topTit {
				width: 100%;
				height: 13px;
				padding: 15px 0;
				background-color: #eeeeee;
				.time {
					margin-left: 230px;
				}
			}
			.shopingInfoClass {
				width: 45%;
				.shopingInfo {
					min-height: 120px;
					border-right: 1px solid #EEEEEE;
					border-bottom: 1px solid #EEEEEE;
					.shopingPhoto {
						width: 81px;
						height: 81px;
						margin-top: 20px;
						margin-left: 30px;
					}
					.shopingTit {
						width: 130px;
						margin-left: 20px;
						word-wrap: break-word
					}
					.shopingPrice {
						margin-left: 45px;
						width: 45px;
						margin-top: 50px;
					}
					.shopingCount {
						margin-left: 5px;
						text-align: center;
						width: 45px;
						margin-top: 50px;
					}
					.shopingProxy {
						margin-left: 5px;
						margin-top: 50px;
					}
				}
			}
			.other {
				position: absolute;
				left: 45%;
				top: 0px;
				bottom: 0px;
				right: 0;
				height: 100%;
				.subemployeename,
				.canal,
				.status,
				.price,
				.handle {
					float: left;
					color: #666666;
					width: 16%;
					height: 100%;
					border-right: 1px solid #DDDDDD;
				}
				.handle {
					border-right: none;
				}
				.price {
					width: 27%;
				}
				.subemployeename{
					width: 20%;
				}
			}
		}
		.el-table__empty-block {
			display: none;
		}
		/*弹框高度*/
		.el-dialog {
			min-height: 300px;
		}
		.mr-60{
			margin-right: 60px;
		}
	}
</style>