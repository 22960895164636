<template>
  <div id="purchase-order-detail-cc" class="bg-f">
    <div class="content-width" v-loading="loading">
      <div class="mb-50" v-if="orderDetail.orderStatus != 0">
        <div class="order-state" :style="orderState"></div>
        <div class="mt-10 font-16 order-state-info">
          <div>
            <span class :class="orderDetail.orderStatus >=1?'color-danger':''">下单提交</span>
            <p
              class="font-12 color-9 mt-10"
              v-if="orderDetail.orderStatus >=1"
            >{{orderDetail.gmtCreate | time}}</p>
          </div>
          <div>
            <span class="color-9" :class="orderDetail.orderStatus >=2?'color-danger':''">支付货款</span>
            <p
              class="font-12 color-9 mt-10"
              v-if="orderDetail.orderStatus>=2"
            >{{orderDetail.gmtPay | time}}</p>
          </div>
          <div>
            <span class="color-9" :class="orderDetail.orderStatus >=3?'color-danger':''">卖家发货</span>
            <p
              class="font-12 color-9 mt-10"
              v-if="orderDetail.orderStatus>=3"
            >{{orderDetail.gmtShipped | time}}</p>
          </div>
          <div>
            <span class="color-9" :class="orderDetail.orderStatus >=4?'color-danger':''">确认收货</span>
            <p
              class="font-12 color-9 mt-10"
              v-if="orderDetail.orderStatus>=4"
            >{{orderDetail.gmtComplete | time}}</p>
          </div>
        </div>
      </div>
      <!--收货信息-->
      <div class="order-info clearfix mb-20">
        <div class="title">
          <p class="fl">收货信息</p>
        </div>
        <el-form class="clearfix fl" label-width="130px">
          <el-form-item label="收货人:">{{orderDetail.addrUsername}}</el-form-item>
          <el-form-item label="电话:">{{orderDetail.addrMobile}}</el-form-item>
          <el-form-item
            label="地址:"
          >{{orderDetail.addrProvince}}{{orderDetail.addrCity}}{{orderDetail.addrRegion}}{{orderDetail.addr}}</el-form-item>
          <el-form-item label="备注:">
            <div>
              <p>{{orderDetail.remark?orderDetail.remark:'无'}}</p>
            </div>
          </el-form-item>
        </el-form>
      </div>

      <!--发票信息-->
      <div v-if="orderDetail.orderInvoicePo!=null">
        <div class="order-info clearfix mb-20" v-if="orderDetail.orderInvoicePo.type==1">
          <div class="title">
            <p class="fl">发票信息</p>
          </div>
          <el-form class="fl mb-10" label-width="130px">
            <el-form-item label="发票类型:">{{orderDetail.orderInvoicePo.type==1?'普通发票':'增值税发票'}}</el-form-item>
            <el-form-item label="发票抬头:">{{orderDetail.orderInvoicePo.companyHead}}</el-form-item>
            <el-form-item label="纳税人识别号:">{{orderDetail.orderInvoicePo.taxpayerIdNo}}</el-form-item>
            <el-form-item label="收票人:">{{orderDetail.orderInvoicePo.linkman}}</el-form-item>
            <el-form-item label="手机号:">{{orderDetail.orderInvoicePo.tel}}</el-form-item>
            <el-form-item
              label="收票地址:"
            >{{orderDetail.orderInvoicePo.provinceName+orderDetail.orderInvoicePo.cityName+orderDetail.orderInvoicePo.regionName+orderDetail.orderInvoicePo.streetName+orderDetail.orderInvoicePo.addr}}</el-form-item>
          </el-form>
        </div>

        <div class="order-info clearfix mb-20" v-if="orderDetail.orderInvoicePo.type==2">
          <div class="title">
            <p class="fl">发票信息</p>
          </div>
          <el-form class="fl mb-10">
            <el-form-item label="发票类型:">{{orderDetail.orderInvoicePo.type==1?'普通发票':'增值税发票'}}</el-form-item>
            <el-form-item label="公司名称:">{{orderDetail.orderInvoicePo.companyHead}}</el-form-item>
            <el-form-item label="纳税人识别号:">{{orderDetail.orderInvoicePo.taxpayerIdNo}}</el-form-item>
            <el-form-item label="注册地址:">{{orderDetail.orderInvoicePo.registerAddress}}</el-form-item>
            <el-form-item label="注册电话:">{{orderDetail.orderInvoicePo.registerPhone}}</el-form-item>
            <el-form-item label="开户银行:">{{orderDetail.orderInvoicePo.bankDeposit}}</el-form-item>
            <el-form-item label="银行账号:">{{orderDetail.orderInvoicePo.bankCard}}</el-form-item>
            <el-form-item label="收票人:">{{orderDetail.orderInvoicePo.linkman}}</el-form-item>
            <el-form-item label="手机号:">{{orderDetail.orderInvoicePo.tel}}</el-form-item>
            <el-form-item
              label="收票地址:"
            >{{orderDetail.orderInvoicePo.provinceName+orderDetail.orderInvoicePo.cityName+orderDetail.orderInvoicePo.regionName+orderDetail.orderInvoicePo.addr}}</el-form-item>
          </el-form>
        </div>
      </div>
      <!--赠品信息-->
      <div class="order-info clearfix mb-20" v-if="!!orderDetail.orderGiftContent">
        <div class="title">
          <p class="fl">赠品信息</p>
        </div>
        <el-form class="fl clearfix gift">
          <el-form-item label="赠品信息:">{{orderDetail.orderGiftContent}}</el-form-item>
        </el-form>
      </div>

      <div class="table-item mt-20">
        <div class="clearfix table-item-top">
          <span
            class="fl ml-20 color-9"
            v-if="orderDetail.orderStatus == 0 && $route.query.type !='jiuji'"
          >取消订单时间： {{orderDetail.gmtCancle | time}}</span>
          <span class="fl ml-20 color-9" v-else>下单时间： {{orderDetail.gmtCreate | time}}</span>
          <span class="fl ml-50">订单编号： {{orderDetail.sn}}</span>
          <span class="fr mr-20">{{orderDetail.sellerName}}</span>
          <span class="fr mr-20">卖家手机：{{orderDetail.sellerPhone}}</span>
        </div>
        <div class="table-item-content bb clearfix" style="display: flex;">
          <!--商品-->
          <div class="clearfix">
            <div
              class="clearfix last-no-border"
              v-for="(item, index) in orderDetail.orderItemVos"
              :key="index"
            >
              <div class="fl gift" style="width: 350px;">
                <div v-if="item.changePurchaseItem" class="triangle-topleft border-red"></div>
                <span v-if="item.changePurchaseItem" class="init default">换购</span>
                <img v-if="item.image" :src="item.image" class="fl mr-10" />
                <img v-else src="../../../assets/goods.jpg" class="fl mr-10" />
                <p>{{item.skuName}}</p>
                <p class="color-9 mt-20">规格:1*{{item.specInfoNum}}</p>
              </div>
              <div
                class="fl pbl-20 tac"
                style="width:100px;"
              >¥{{item.originSpecPrice | fmoney}}/{{item.showUnitName}}</div>
              <div class="fl pbl-20 tac" style="width:50px;">{{item.specNum}}{{item.unit}}</div>
            </div>
            <!--赠品清单-->
            <div
              class="clearfix last-no-border"
              v-for="(orderItem, index) in orderDetail.orderGiftResVos"
              :key="index"
            >
              <div class="fl gift" style="width: 350px;">
                <div class="triangle-topleft border-red"></div>
                <span class="init default">赠品</span>
                <img v-if="orderItem.image" :src="orderItem.image" class="fl mr-10" />
                <img v-else src="../../../assets/goods.jpg" class="fl mr-10" />
                <p>{{orderItem.skuName}}</p>
                <p class="color-9 mt-20" v-if="orderItem.specInfoNum">规格:1*{{orderItem.specInfoNum}}</p>
              </div>
              <div class="fl pbl-20 tac" style="width:100px;">¥0</div>
              <div
                class="fl pbl-20 tac"
                style="width:50px;"
              >{{orderItem.giftNum}}/{{orderItem.sellingUnitName}}</div>
            </div>
          </div>
          <div class="bl tac pt-20" style="width: 180px;">
            <p
              v-if="orderDetail.editAuditState == 1 && orderDetail.orderStatus == 1"
              class="color-danger"
            >待审核</p>
            <div v-else>
              <p
                v-if="(orderDetail.editAuditState == 3||orderDetail.editAuditState == null) && orderDetail.orderStatus == 1 && (orderDetail.lastestRemittanceAuditStatus==2||orderDetail.lastestRemittanceAuditStatus==null) && this.$route.query.type !='jiuji'"
              >
                <span class="color-danger">剩余</span>
                <span
                  class="color-danger"
                  v-text="typeof(orderDetail.remainAutoCancleTime)=='string'?orderDetail.remainAutoCancleTime:null"
                ></span>
              </p>
              <p v-if=" orderDetail.orderStatus == 1 " class="color-danger">待付款</p>
              ￥{{orderDetail.goodsTotalMoney || 0 | fmoney}}
            </div>
            <p v-if=" orderDetail.orderStatus == 0 " class="color-danger">已关闭</p>
            <p
              v-if=" orderDetail.orderStatus == 2 "
              class="color-danger"
            >{{ orderDetail.shipStatus === 4 ? '部分发货' : '待发货'}}</p>
            <p v-if=" orderDetail.orderStatus == 3 " class="color-danger">已发货</p>
            <p v-if=" orderDetail.orderStatus == 4 " class="color-danger">已完成</p>
          </div>
          <div class="bl tac pb-10 plr-10 pt-20" style="flex-grow: 1;">
            <div class="mt-10" v-if="orderDetail.orderStatus == 3 && orderType">
              <el-button @click="confirmReceipt(orderDetail.sn)">确认收货</el-button>
            </div>
            <div
              v-if="orderDetail.editAuditState == 2 && orderDetail.orderStatus == 1"
              class="color-danger"
            >商家正在审核您的订单</div>
            <div
              v-else-if="!((orderDetail.editAuditState == 1 || orderDetail.editAuditState == 2) && orderDetail.orderStatus == 1)"
            >
              <div
                class="mt-10"
                v-if="orderDetail.payStatus != 3 && (orderDetail.lastestRemittanceAuditStatus == 2 || !orderDetail.lastestRemittanceAuditStatus) && orderDetail.orderStatus != 0 && orderDetail.payType != 6 && orderDetail.payType != 2"
              >
                <el-button
                  type="danger"
                  v-if="showButton('PAY',100040) && orderType && !orderDetail.paymentOnBehalf"
                  @click="orderPay(orderDetail.sn)"
                >去付款</el-button>
              </div>
              <div
                v-if="orderDetail.orderStatus == 1 && orderDetail.payStatus == 1 && (orderDetail.lastestRemittanceAuditStatus == null || orderDetail.lastestRemittanceAuditStatus == 2 )"
              >
                <el-button
                  class="ml-0 mt-10"
                  v-if="showButton('CANCEL',100040) && orderType"
                  @click="handleCancelOrder(orderDetail.sn)"
                >取消订单</el-button>
              </div>
              <div
                v-else-if="orderDetail.orderStatus == 2 && orderDetail.deliverySeparate && orderType"
              >
                <el-button class="ml-0 mt-10" @click="separateDialog = true" type="danger">订单提货</el-button>
              </div>
            </div>
            <div class="mt-10" v-if="orderDetail.orderStatus == 0 && orderDetail.cancleReason">
              <el-button
                style="width: 110px;"
                @click="handleCancleReason(orderDetail.cancleReason)"
              >取消订单原因</el-button>
            </div>
            <div
              v-if="orderDetail.lastestRemittanceAuditStatus == 1 && orderDetail.orderStatus != 0"
              class="color-danger"
            >您上传的支付凭证正在审核中</div>
            <div
              class="color-danger"
              v-if="orderDetail.lastestRemittanceAuditStatus == 2 "
            >您上传的支付凭证审核未通过,未通过原因:{{orderDetail.remittanceApplyAuditFailReason}}</div>
          </div>
          <!--取消订单弹出框-->
          <el-dialog class="cancelOrderDialog" title="取消订单理由" :visible.sync="cancelOrderVisible">
            <el-form class="pl-30 pr-30" :model="form" ref="cancelOrderForm" :rules="rules">
              <el-form-item label prop="cancleReason">
                <el-input
                  type="textarea"
                  placeholder="请输入取消订单的理由..."
                  :autosize="{ minRows: 3 ,maxRows: 10}"
                  v-model.trim="form.cancleReason"
                ></el-input>
              </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
              <el-button type="danger" @click="cancelOrderCommit">提 交</el-button>
              <el-button @click="cancelOrderVisible = false">取 消</el-button>
            </div>
          </el-dialog>
        </div>
            <div class="total bb clearfix">
          <ul class="fr">
            <li class="tar clearfix mb-10" v-if="ishotel">
              <span class="fl tal w112">
                <i class="color-danger" style="font-style: normal;" v-text="orderDetail.count||0"></i>
                件商品总额：
              </span>
              <span class="ml-30 fr tar">￥ {{ orderDetail.goodsTotalMoney ||0 | fmoney}}</span>
            </li>
            <!-- <li class="tar clearfix mb-10" v-if="!ishotel">
							<span class="fl tal w112">改价金额：</span>
            <span class='ml-30 fr tar'>{{orderDetail.changedPrice>0?'+':'-'}}￥ {{orderDetail.changedPrice!=null?Math.abs(orderDetail.changedPrice):0 | fmoney}}</span></li>-->
            <li class="tar clearfix mb-10" v-if="!ishotel">
              <span class="fl w112">活动减免金额：</span>
              <span class="ml-30 fr tar">- ￥ {{orderDetail.activityCutMoney||0 | fmoney}}</span>
            </li>
            <li class="tar clearfix mb-10" v-if="!ishotel">
              <span class="fl tal w112">优惠券减免金额：</span>
              <span class="ml-30 fr tar">- ￥ {{orderDetail.couponCutMoney||0 | fmoney}}</span>
            </li>
            <!-- 折扣返利 v-if="ishotel"删除条件 -->
            <li class="tar clearfix mb-10">
              <span class="fl tal w112">折扣返利：</span>
              <span class="ml-30 fr tar">- ￥ {{orderDetail.discountCutMonney||0 | fmoney}}</span>
            </li>
            <li class="tar clearfix mb-10">
              <span class="fl tal w112">促销费用：</span>
              <span class="ml-30 fr tar">- ￥ {{orderDetail.promotionDiscountsMoney ||0 | fmoney}}</span>
            </li>
            <li class="tar clearfix mb-10">
              <span class="fl tal w112">运费：</span>
              <span class="ml-30 fr tar">+ ￥ {{orderDetail.freightMoney||0 | fmoney}}</span>
            </li>
            <li class="tar clearfix mb-10" v-if="showSalesCommission">
              <span class="fl tal w112">销售员佣金：</span>
              <span class="ml-30 fr tar"> ￥ {{orderDetail.commission||0 | fmoney}}</span>
            </li>
            <li class="tar clearfix mb-10" v-if="showSalesCommission">
              <span class="fl tal w112">推广员佣金：</span>
              <span class="ml-30 fr tar"> ￥ {{orderDetail.salesCommission||0 | fmoney}}</span>
            </li>
            <li class="font-20 payment tar clearfix">
              <span class="fl tal w112">应付金额：</span>
              <span class="ml-30 fr tar">￥ {{orderDetail.needPayMoney||0 | fmoney}}</span>
            </li>
          </ul>
        </div>
      </div>
      <!-- 发货信息 -->
      <template v-if="detailList && detailList.length > 0">
        <div class="mt-30 mb-10">发货信息</div>
        <el-table :data="detailList" border tooltip-effect="dark" class="detailList">
          <el-table-column label="序号" width="60" align="center">
            <template slot-scope="scope">{{scope.$index + 1}}</template>
          </el-table-column>
          <el-table-column label="货品信息">
            <template slot-scope="scope">
              <div class="gift">
                <div v-if="scope.row.changePurchaseItem" class="triangle-topleft border-red"></div>
                <span v-if="scope.row.changePurchaseItem" class="init default">换购</span>
                <div v-if="scope.row.skuGift" class="triangle-topleft border-red"></div>
                <span v-if="scope.row.skuGift" class="init default">赠品</span>
                <img v-if="scope.row.skuImage" class="fl img mr-10" :src="scope.row.skuImage" />
                <img v-else src="../../../assets/goods.jpg" class="img fl mr-10" alt />
                <div class="fl" style="width: 240px;">{{scope.row.skuName}}</div>
              </div>
            </template>
          </el-table-column>
          <!--<el-table-column label="商品编码" prop='skuNo' align="center" width="120"></el-table-column>-->
          <el-table-column label="总数量" prop="specNumTotal" align="center" width="80"></el-table-column>
          <el-table-column label="单位" prop="specUnitName" align="center" width="80"></el-table-column>
          <!--<el-table-column label="K3出库单号" prop='deliveryId' align="center" width="120"></el-table-column>-->
          <el-table-column label="提货数量" prop="specNum" align="center" width="100"></el-table-column>
          <el-table-column label="提货时间" prop="gmtCreate" align="center" width="160">
            <template slot-scope="scope">{{scope.row.gmtCreate | time}}</template>
          </el-table-column>
          <el-table-column label="状态" align="center" width="100">
            <template slot-scope="scope">
              <div class="font-12">{{scope.row.statusName}}</div>
              <el-tooltip
                v-if="scope.row.status === 4"
                effect="dark"
                :content="scope.row.rejectReason"
                placement="left-start"
              >
                <el-button type="text" class="text_btn">原因</el-button>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
      </template>

      <!--付款信息-->
      <div class="clearfix">
        <div
          class="order-info order-info-dispatch clearfix mb-20 mr-0 fl"
          v-if="orderDetail.payStatus >= 2"
          v-for="(item, index) in orderDetail.paymentLogsVOs"
          :key="index"
        >
          <div class="title">付款信息</div>
          <el-form class="fl mb-10">
            <el-form-item label="付款时间:">{{item.payTime | time}}</el-form-item>
            <el-form-item label="付款方式:">
              <span v-if="item.payMethod==0">组合支付</span>
              <span v-if="item.payMethod==1">线下付款</span>
              <span v-if="item.payMethod==3">在线支付</span>
              <span v-if="item.payMethod==4">余额支付</span>
              <span v-if="item.payMethod==5">货到付款</span>
              <span v-if="item.payMethod==6">先货后款</span>
              <span
                v-if="item.payMethod!=0&&item.payMethod!=1&&item.payMethod!=3&&item.payMethod!=4&&item.payMethod!=6"
              >其他</span>
            </el-form-item>
            <!-- <el-form-item label="支付流水号:" v-if="!ishotel">{{item.paySn}}</el-form-item> -->
            <el-form-item label="支付金额:" v-if="!ishotel">￥{{item.payMoney | fmoney}}</el-form-item>
            <!--支付方式 == 6 为先货后款 显示应付金额-->
            <el-form-item
              label="应付金额:"
              v-if="item.payMethod==6"
            >￥{{orderDetail.needPayMoney | fmoney}}</el-form-item>
            <!--<el-form-item label='收款账号:'>
							{{item.traderBankAccount}}
            </el-form-item>-->
            <el-form-item label="付款凭证:" v-if="item.isHasVoucher">
              <el-button
                type="text"
                style="width:auto;height: auto;"
                v-if="showButton('VOUCHER',100040)"
                @click="handleLookremittance(item.id)"
              >查看</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <el-dialog title="付款凭证信息" class="lookpaymentDialog" :visible.sync="lookpaymentVisible">
        <el-form :model="orderRemittanceApplyVo" class="mb-50">
          <div>
            <el-form-item label="持卡人姓名:">
              <el-input
                placeholder="持卡人姓名"
                class="fl"
                readonly
                v-model="orderRemittanceApplyVo.bankUserName"
              ></el-input>
            </el-form-item>
            <el-form-item label="收款银行名称:">
              <el-input class="fl" readonly v-model="orderRemittanceApplyVo.incomeAccountName"></el-input>
            </el-form-item>
            <el-form-item label="收款银行账号:">
              <el-input class="fl" readonly v-model="orderRemittanceApplyVo.incomeAccountNum"></el-input>
            </el-form-item>
          </div>
          <el-form-item label="付款金额:">
            <el-input
              placeholder="请输入付款金额"
              class="fl"
              readonly
              v-model="orderRemittanceApplyVo.amount"
            ></el-input>
          </el-form-item>
          <el-form-item label="汇款凭证:">
            <el-upload
                    :disabled="true"
              class="avatar-uploader fl"
              action="/sunrise-gateway/oss/ossUpload"
              list-type="picture-card"
              :on-preview="handlePictureCardPreview"
              :file-list="orderRemittanceApplyVo.fileList"
            ></el-upload>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="danger" @click="lookpaymentVisible=false">确 定</el-button>
        </div>
      </el-dialog>
      <el-dialog :visible.sync="dialogVisible" size="tiny" class="showImgDialog">
        <img width="100%" :src="dialogImageUrl" alt />
      </el-dialog>
    </div>
    <!-- 通知发货弹窗 -->
    <separateOrderDialog
      :dialogVisible.sync="separateDialog"
      :orderSn="orderDetail.sn"
      :sellerName="orderDetail.sellerName"
      @deliverySuccessCallback="orderDetailInfo({ orderSn: sessionStorage.orderSn })"
    />
  </div>
</template>

<script>
import { axiosApi, basePath } from "../../../api/api";
import separateOrderDialog from "./separateOrderDialog.vue";
export default {
  components: { separateOrderDialog },
  data() {
    return {
      orderState: {
        backgroundPosition: "0 0"
      },
      dialogVisible: false,
      dialogImageUrl: "",
      changePriceVisible: false,
      cancelOrderVisible: false,
      reason: null,
      orderDetail: {}, //订单详情信息
      loading: false,
      form: {
        cancleReason: null
      },
      rules: {
        cancleReason: [{ max: 30, message: "不能超过30个字！" }]
      },
      lookpaymentVisible: false, //查看付款凭证dialog
      orderRemittanceApplyVo: {}, //付款凭证详情
      ishotel: sessionStorage.getItem("isHotel") === "1", //酒店用户
      detailList: [], // 发货信息
      separateOrder: [], // 剩余发货商品信息
      separateDialog: false,
      orderType: true, //判断是否为酒店久集订单,false为酒店久集订单
      showSalesCommission: 0
    };
  },
  mounted() {
    this.showSalesCommission = this.$route.query.showSalesCommission
    this.$nextTick(() => {
      let afterUrl = window.location.search.substring(1);
      if (afterUrl) {
        this.orderType = false;
      } else {
        this.orderType = true;
      }
      this.orderDetailInfo({
        orderSn: sessionStorage.orderSn
      });
    });
  },
  methods: {
    filterSeparateOrderInfo(data) {
      const orderItems = data.orderItemVos.map(n => {
        if (n.remainingSpecNum > 0) {
          return n;
        }
      });
      const gitfItems = data.orderGiftResVos
        ? data.orderGiftResVos.map(n => {
            if (n.remainingNum > 0) {
              return {
                skuNo: n.skuNo,
                skuName: n.skuName,
                skuImage: n.skuImage,
                specNum: n.giftNum,
                remainingSpecNum: n.remainingNum,
                num: 0
              };
            }
          })
        : [];
      this.separateOrder = orderItems.concat(gitfItems);
    },
    queryDeliveryInfo() {
      const body = {
        orderSn: sessionStorage.orderSn,
        status: 2
      };
      axiosApi(
        "/order/deliveryItem/selectList",
        body,
        res => {
          this.detailList = res.data.data || [];
        },
        1,
        10
      );
    },
    handlePictureCardPreview(file) {
      //图片放大
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleLookremittance(id) {
      //查看付款凭证
      this.remittanceApply({
        orderSn: sessionStorage.orderSn,
      });
      this.lookpaymentVisible = true;
    },
    //根据付款记录id获取汇款凭证
    remittanceApply(body) {
      axiosApi(
        "/anon/order/remittanceApply/get",
        body,
        res => {
          // console.log(res, '根据付款记录id获取汇款凭证')
          this.orderRemittanceApplyVo = res.data.data;
          this.orderRemittanceApplyVo.fileList = [
            {
              url: JSON.parse(this.orderRemittanceApplyVo.urls)[0]
            }
          ];
        },
        1,
        10
      );
    },
    orderPay(orderSn) {
      //去付款
      sessionStorage.setItem("orderSn", orderSn);
      axiosApi(
        "/anon/order/detail/info",
        { orderSn: sessionStorage.orderSn },
        res => {
          if (res.data.data.payStatus != 3) {
            this.$router.push(basePath + "/payOrder");
          } else {
            this.orderDetailInfo({ orderSn: sessionStorage.orderSn });
            this.$message({
              type: "info",
              message: "您已付款成功",
              duration: 1000
            });
          }
        },
        null,
        null,
        res => {}
      );
    },
    handleCancelOrder() {
      //取消订单按钮
      this.cancelOrderVisible = true;
    },
    /*订单详情*/
    orderDetailInfo(body) {
      this.loading = true;
      let api = this.showSalesCommission ? '/superc/order/Detail/info' : "/anon/order/detail/info"
      axiosApi(
        api,
        body,
        res => {
          this.loading = false;
          this.orderDetail = res.data.data;
          this.orderState.backgroundPosition =
            "0 " + (this.orderDetail.orderStatus - 1) * -40 + "px";
          if (
            this.orderDetail.remainAutoCancleTime &&
            this.orderDetail.remainAutoCancleTime > 0
          ) {
            let time = parseInt(this.orderDetail.remainAutoCancleTime / 1000);
            this.orderDetail.remainAutoCancleTime = this.timeString(time);
            this.orderDetail.clearTime = null;
            this.orderDetail.clearTime = setInterval(() => {
              time--;
              this.orderDetail.remainAutoCancleTime = this.timeString(time);
              if (time <= 0) {
                clearTimeout(this.orderDetail.clearTime);
                if (
                  this.orderDetail.orderStatus == 1 &&
                  this.orderDetail.payStatus == 1 &&
                  (!this.orderDetail.editAuditState ||
                    this.orderDetail.editAuditState == 3) &&
                  (!this.orderDetail.lastestRemittanceAuditStatus ||
                    this.orderDetail.lastestRemittanceAuditStatus != 1)
                ) {
                  this.orderDetail.orderStatus = 0;
                  this.$set(this.ordersListResVo, index, this.orderDetail);
                }
              }
            }, 1000);
          } else if (
            this.orderDetail.orderStatus == 1 &&
            this.orderDetail.payStatus == 1 &&
            (!this.orderDetail.editAuditState ||
              this.orderDetail.editAuditState == 3) &&
            (!this.orderDetail.lastestRemittanceAuditStatus ||
              this.orderDetail.lastestRemittanceAuditStatus != 1) &&
            this.$route.query.type != "jiuji"
          ) {
            this.orderDetail.orderStatus = 0;
            this.$set(this.ordersListResVo, index, this.orderDetail);
          }
          this.queryDeliveryInfo();
          if (
            this.orderDetail.orderStatus === 2 &&
            this.orderDetail.deliverySeparate
          ) {
            this.filterSeparateOrderInfo(res.data.data);
          }
        },
        1,
        10,
        res => {
          this.loading = false;
          this.$message({
            type: "error",
            message: res.data.message,
            duration: 1000
          });
        }
      );
    },
    //订单取消的原因
    handleCancleReason(cancleReason) {
      const h = this.$createElement;
      this.$msgbox({
        title: "订单取消原因",
        message: h("p", null, [
          h(
            "p",
            {
              style: "text-align:center"
            },
            cancleReason
          )
        ]),
        showCancelButton: false,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            done();
          } else {
            done();
          }
          delete instance.$slots.default;
        }
      });
    },
    //弹窗 取消订单
    cancelOrderCommit() {
      // console.log(this.$refs)
      this.$refs.cancelOrderForm.validate(valid => {
        if (valid) {
          this.cancelOrderVisible = false;
          this.cancelOrder({
            orderSn: this.orderDetail.sn,
            cancleReason: this.form.cancleReason,
            cancleType: 1,
            buyerId: sessionStorage.traderId
          });
        } else {
          return false;
        }
      });
    },
    //取消订单接口
    cancelOrder(body) {
      axiosApi(
        "/anon/order/cancel",
        body,
        res => {
          // console.log('取消订单',res)
          this.$message({
            type: "success",
            message: "操作成功！",
            duration: 1000
          });
          this.orderDetailInfo({
            orderSn: sessionStorage.orderSn
          });
        },
        1,
        10,
        res => {
          // console.log('取消订单Error',res)
          this.$message({
            type: "error",
            message: res.data.message,
            duration: 1000
          });
        }
      );
    },
    confirmReceipt(orderSn) {
      //确认收货
      const h = this.$createElement;
      this.$msgbox({
        title: "提示信息",
        message: h(
          "p",
          {
            style: "text-align:center"
          },
          "是否确认收货？"
        ),
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            this.confirmShipOrder({ orderSn: orderSn });
            done();
          } else {
            done();
          }
          delete instance.$slots.default;
        }
      });
    },
    confirmShipOrder(body) {
      axiosApi(
        "/anon/order/ship/confirm",
        body,
        res => {
          const h = this.$createElement;
          this.$msgbox({
            message: h(
              "p",
              {
                style: "text-align:center"
              },
              "确认收货成功"
            ),
            showCancelButton: false,
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            beforeClose: (action, instance, done) => {
              if (action === "confirm") {
                // this.queryOrderList(this.form)
                this.orderDetail.orderStatus = 4;
                this.orderState.backgroundPosition =
                  "0 " + (this.orderDetail.orderStatus - 1) * -40 + "px";
                done();
              } else {
                done();
              }
              delete instance.$slots.default;
            }
          });
        },
        1,
        10,
        res => {
          this.$message({
            type: "error",
            message: res.data.message,
            duration: 1000
          });
          // this.queryOrderList(this.form)
        }
      );
    }
  }
};
</script>

<style lang="less">
#purchase-order-detail-cc {
    padding:20px;
  .detailList {
    .gift {
      position: relative;
    }
    .triangle-topleft {
      border-top: 40px solid #ff4201;
      width: 0;
      height: 0;
      border-right: 40px solid transparent;
      position: absolute;
      left: 0;
      top: 0;
    }
    .init {
      font-size: 12px;
      transform: rotate(-45deg) translate(-4px, 5px);
      position: absolute;
      left: 0;
      top: 0;
      color: #fff;
      letter-spacing: -0.34px;
    }
    .text_btn {
      width: auto;
      font-size: 12px;
    }
  }
  .last-no-border {
    width: 550px;
    padding: 20px;
    border-bottom: 1px solid #ddd;
  }
  .last-no-border:last-child {
    border-bottom: 0;
  }
  .bb {
    border-bottom: 1px solid #dddddd;
  }
  .bl {
    border-left: 1px solid #dddddd;
  }
  .content-width {
    margin: 0 auto;
    box-sizing: border-box;
    .order-state {
      width: 540px;
      height: 30px;
      background-image: url(../../../assets/order-state.png);
      margin: 0 auto;
    }
    .order-state2 {
      width: 540px;
      height: 30px;
      background: url(../../../assets/order-state.png) 0 -40px;
      margin: 0 auto;
    }
    .order-state3 {
      width: 540px;
      height: 30px;
      background: url(../../../assets/order-state.png) 0 -80px;
      margin: 0 auto;
    }
    .order-state4 {
      width: 540px;
      height: 30px;
      background: url(../../../assets/order-state.png) 0 -120px;
      margin: 0 auto;
    }
    .order-state-info {
      line-height: 16px;
      width: 664px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      div {
        width: 150px;
        text-align: center;
      }
    }
    .order-state-time {
      padding-left: 160px;
      .second {
        padding-left: 70px;
      }
      .third {
        padding-left: 65px;
      }
      .fourth {
        padding-left: 64px;
      }
    }
    .order-info {
      margin: 0 auto;
      margin-top: 30px;
      border: 1px solid #dddddd;
      box-sizing: border-box;
      .title {
        background-color: #eeeeee;
        height: 40px;
        line-height: 40px;
        padding-left: 20px;
      }
      .el-form-item__label {
        padding: 0;
        line-height: 14px;
        height: 14px;
        padding-right: 20px;
        width: 145px;
      }
      .el-form-item__content {
        line-height: 14px;
      }
      .el-form {
        min-width: 600px;
        margin-top: 30px;
      }
      .gift {
        .el-form-item {
          .el-form-item__label {
            float: left;
            line-height: 20px;
          }
          .el-form-item__content {
            float: left;
            width: 700px;
            line-height: 20px;
          }
        }
      }
    }
    /*.order-info-dispatch {
				width: 465px;height: 304px;
				margin: 0;
				margin-top: 20px;
				margin-right: 10px;
			}*/
    .lookpaymentDialog {
      .el-form-item__label {
        line-height: 32px;
        height: 32px;
      }
      .el-dialog {
        .el-dialog__body {
          padding-top: 20px;
          padding-left: 10px;
        }
        .avatar-uploader {
          .el-upload--picture-card {
            display: none;
          }
        }
        .el-icon-view {
          margin-left: 20px;
        }
        .el-icon-delete2 {
          display: none;
        }
        .el-upload-list--picture-card .el-upload-list__item-status-label {
          display: none;
        }
      }
      .showImgDialog {
        .el-dialog {
          z-index: 10000;
        }
        .el-dialog__body {
          width: auto;
          height: auto;
          img {
            width: auto;
            height: auto;
          }
        }
      }
    }
    .table-item {
      .bl {
        border-left: 1px solid #dddddd;
      }
      border: 1px solid #dddddd;
      font-size: 14px;
      border-bottom: none;
      .table-item-content .pbl-20 {
        padding: 0px 0px 20px 20px;
      }
      .table-item-content {
        .gift {
          position: relative;
        }
        .triangle-topleft {
          border-top: 60px solid #ff4201;
          width: 0;
          height: 0;
          border-right: 60px solid transparent;
          position: absolute;
          top: 0;
          left: 0;
        }
        .init {
          transform: rotate(-45deg) translate(-4px, 10px);
          position: absolute;
          left: 0;
          top: 0;
          color: #fff;
          letter-spacing: -0.34px;
        }
      }
      .table-item-top {
        border-bottom: 1px solid #dddddd;
        padding: 10px 0;
        .table-item-top-checkbox {
          margin-left: 14px;
        }
      }
      img {
        width: 80px;
        height: 80px;
      }
      .total {
        padding: 30px 60px 30px 0;
        /*// border: 1px solid #DDDDDD;*/
        border-top: none;
        box-sizing: border-box;
        .payment {
          color: #d24c4c;
        }
        span {
          display: inline-block;
          width: 190px;
          text-align: left;
        }
        .w112 {
          width: 115px;
        }
        ul li {
          margin-bottom: 5px;
        }
      }
    }
    .table-item-default {
      height: 530px;
      padding-top: 120px;
    }
  }
}
</style>
