<template>
  <!--门店-->
  <div class="contractCustomerTwo">
    <div class="title-top bg-f clearfix">
      {{ storeName }}管理
      <el-button class="fr mt-14 mr-30" @click="customer = true"
        >新增门店</el-button
      >
    </div>
    <div class="content bg-f pr-30 pl-30 mt-5">
      <div class="clearfix search">
        <el-form
          :inline="true"
          class="pt-30 fl"
          label-width="auto"
          :model="params"
        >
          <el-form-item label="门店名称">
            <el-input
              v-model.trim="params.customerName"
              placeholder="请输入门店名称"
              :maxlength="40"
            ></el-input>
          </el-form-item>
          <el-form-item label="门店账号">
            <el-input
              v-model.trim="params.loginName"
              placeholder="请输入门店账号"
              :maxlength="40"
            ></el-input>
          </el-form-item>
        </el-form>
        <el-button
          class="fl ml-30 mt-30"
          type="info"
          @click="search"
          icon="el-icon-search"
          >搜 索</el-button
        >
      </div>
      <div class="pb-30">
        <el-table :data="tableData">
          <el-table-column
            label="门店名称"
            prop="customerName"
            min-width="100"
          ></el-table-column>
          <el-table-column
            label="门店账号"
            prop="loginName"
            min-width="100"
          ></el-table-column>
          <el-table-column label="联系人/电话" min-width="120">
            <template slot-scope="scope">
              <div>
                {{ scope.row.linkName }}
              </div>
              <div>
                {{ scope.row.loginName }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="地区" min-width="70">
            <template slot-scope="scope">
              <span v-if="scope.row.regionName">{{
                scope.row.regionName
              }}</span>
              <span v-else>-</span>
            </template>
          </el-table-column>
          <el-table-column
            label="额度"
            prop="creditAvailable"
          ></el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="{ row }">
              <el-link
                type="primary"
                :underline="false"
                @click="editSClick(row)"
                >充值</el-link
              >
              <el-link :underline="false" @click="openDetail(row.id)"
                >充值明细</el-link
              >
              <el-link type="danger" :underline="false" @click="detInfo(row.id)"
                >删除</el-link
              >
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          class="tar mt-20"
          v-if="tableData.length > 0"
          :current-page="currentPage"
          :page-size="10"
          :total="total"
          layout="prev , pager, next, jumper"
          @current-change="handleCurrentChange"
        >
          <!-- 分页组件 -->
        </el-pagination>
      </div>
      <!--新增门店-->
      <el-dialog class="minMop" title="新增门店" :visible.sync="customer" @close='closeAdd'>
        <el-form
          class="pr-10"
          :rules="rules"
          ref="form"
          :model="form"
          label-width="auto"
        >
          <el-form-item label="手机号:" class="ml-20" prop="loginName">
            <el-input
              v-model="form.loginName"
              placeholder="手机号"
              :maxlength="11"
            ></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="danger" @click="addCustomer">确定</el-button>
          <el-button class="ml-20" @click="closeAdd">取消</el-button>
        </div>
      </el-dialog>

      <!--编辑-->
      <el-dialog class="minMop" title="充值" :visible.sync="editShow" @close="editClose">
        <div style="margin: 0 10px">
          <el-form
            class="pr-10"
            ref="editform"
            :rules="editRules"
            :model="editform"
            label-width="auto"
          >
            <el-form-item label="额度:" class="ml-20">
              <el-input
                disabled
                v-model="editform.creditAvailable"
                placeholder="额度"
                :maxlength="40"
              ></el-input>
            </el-form-item>
            <el-form-item label="充值额度:" class="ml-20" prop="changeMoney">
              <el-input-number
                v-model="editform.changeMoney"
                :controls="false"
                placeholder="充值额度"
                :maxlength="40"
              ></el-input-number>
            </el-form-item>
            <el-form-item label="备注:" class="ml-20" prop="creditExplain">
              <el-input
                type="textarea"
                :rows="2"
                :maxlength="50"
                placeholder="请输入内容"
                v-model="editform.creditExplain"
              >
              </el-input>
            </el-form-item>
          </el-form>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button type="danger" @click="submitEdit">确定</el-button>
          <el-button class="ml-20" @click="editClose">取消</el-button>
        </div>
      </el-dialog>

      <!--明细-->
      <el-dialog
        class="minMop"
        title="明细"
        :visible.sync="detailShow"
        width="70%"
      >
        <div style="margin: 0 10px">
          <el-table :data="detailList">
            <el-table-column label="操作人" prop="operatorName"></el-table-column>
            <el-table-column label="充值额度" prop="changeMoney"></el-table-column>
            <el-table-column label="可用额度" prop="creditAvailable">
              <template slot-scope="{ row }">
                <div>
                  {{ (row.creditAvailable + row.changeMoney).toFixed(2) }}
                </div>
              </template>
            </el-table-column>
            <el-table-column label="类型" prop="typeName"> </el-table-column>
            <el-table-column label="备注" prop="creditExplain"></el-table-column>
            <el-table-column label="操作时间" prop="dateTime"> </el-table-column>
          </el-table>
          <el-pagination
            class="tar mt-20"
            :current-page="detailPage"
            :page-size="10"
            :total="detailTotal"
            layout="prev , pager, next, jumper"
            @current-change="detailPageChange"
          >
            <!-- 分页组件 -->
          </el-pagination>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button type="danger" @click="detailShow = false">确定</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { parseTime } from "../../../utils/index";
import { axiosApi, basePath } from "../../../api/api";
import { MessageBox } from "element-ui";
export default {
  data() {
    return {
      storeName: this.$route.query.storeName || "门店",
      customer: false, //新增门店弹框
      editShow: false, // 编辑弹框
      detailShow: false, // 明细弹框
      detailList: [], // 明细List
      editform: {
        id: "",
        creditAvailable: 0,
        changeMoney: "",
        creditExplain: "",
      }, // 编辑Form
      form: {
        hotelId: this.$route.query.id,
        loginName: "",
      },
      params: {
        hotelId: this.$route.query.id,
      }, // 查询参数
      tableData: [], //客户数据
      currentPage: 1,
      total: 0,
      // 明细分页
      detailPage: 1,
      detailTotal: 0,
      logID: "",
      rules: {
        loginName: [
          {
            required: true,
            message: "请输入手机号",
            trigger: "blur",
          },
        ],
      },
      editRules: {
        changeMoney: [
          {
            required: true,
            message: "请输入充值金额",
            trigger: "blur",
          },
        ],
        creditExplain: [
          {
            required: true,
            message: "请填写备注",
            trigger: "blur",
          },
        ],
      },
    };
  },
  watch: {},
  mounted() {
    console.log(parseTime, "formatTime");
    this.getList();
  },
  computed: {},
  methods: {
    // 列表部分
    search() {
      this.currentPage = 1;
      this.getList();
    },
    getList() {
      axiosApi(
        "/superc/tenantsCustomer/page",
        this.params,
        (res) => {
          this.tableData = res.data.data ? res.data.data.elements : [];
          this.total = res.data.data.totalCount;
        },
        this.currentPage,
        10,
        (res) => {}
      );
    },
    handleCurrentChange(val) {
      //分页查询
      this.currentPage = val;
      this.getList();
    },
    // 新增部分
    addCustomer() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          axiosApi(
            "/superc/tenantsCustomer/add",
            this.form,
            (res) => {
              this.search();
              this.closeAdd()
            },
            null,
            null,
            (res) => {}
          );
        } else {
          return false;
        }
      });
    },
    closeAdd() {
      this.form = {
        hotelId: this.$route.query.id,
        loginName: "",
      };
      this.customer = false;
    },
    // 编辑部分
    editSClick(row) {
      this.editform.creditAvailable = row.creditAvailable;
      this.editform.id = row.id;
      this.editShow = true;
    },
    editClose() {
      this.editform = {
        id: "",
        creditAvailable: 0,
        changeMoney: "",
        creditExplain: "",
      };
      this.editShow = false;
    },
    submitEdit() {
      this.$refs["editform"].validate((valid) => {
        if (valid) {
          axiosApi(
            "/superc/tenantsCustomer/addCreditAvailable",
            this.editform,
            (res) => {
              this.search();
              this.editClose();
            },
            null,
            null,
            (res) => {}
          );
        }
      });
    },
    // 充值明细
    openDetail(id) {
      this.getDetailList(id);
      this.logID = id;
      this.detailShow = true;
    },
    getDetailList(id) {
      console.log(id, "xx");
      axiosApi(
        "/superc/tenantsCustomer/pageCreditAvailableLog",
        { cooperativeCustomerId: id },
        (res) => {
          if (res.data.code === "000000") {
            var info = res.data.data;
            this.detailList = info.elements.map((item) => {
              item.dateTime = parseTime(item.gmtCreate);
              return item;
            });
            this.detailTotal = info.totalCount;
          }
        },
        this.detailPage,
        10,
        (res) => {}
      );
    },
    detailPageChange(val) {
      this.detailPage = val;
      this.getDetailList(this.logID);
    },
    // 删除
    detInfo(id) {
      this.$confirm("此操作将永久删除该门店, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.delItem(id);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    delItem(id) {
      axiosApi(
        "/superc/tenantsCustomer/delete",
        { id: id },
        (res) => {
          if (res.data.code === "000000") {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.search();
          }
        },
        null,
        null,
        (res) => {}
      );
    },
  },
};
</script>
<style lang="less">
.contractCustomerTwo {
  .el-form-item__error {
    margin-left: 0px !important;
  }
}
</style>
<style lang="less">
.contractCustomerTwo {
  .content {
    .el-input__inner {
      padding: 0 15px;
      text-align: left;
    }
    .el-form--inline {
      // width: 650px;
      .el-form-item__label {
        font-size: 12px;
        width: 60px;
      }
      .order-datatimerange {
        width: 240px;
      }
    }
    .el-button + .el-button {
      margin-left: 0;
    }
    .el-table .cell {
      text-align: center;
      padding: 10px 0;
      img {
        width: 60px;
        height: 60px;
        margin-left: 10px;
        margin-right: 10px;
      }
      .width-130 {
        width: 130px;
        text-align: left;
      }
      .el-button {
        height: 20px;
        width: 80px;
      }
    }
    .el-form-item__label {
      width: 100px;
    }
    .reject {
      .el-dialog {
        width: 900px;
        .el-dialog__body {
          min-height: 420px;
        }
      }
    }
  }
  .el-dialog {
    min-height: 300px !important;
  }
}
</style>
