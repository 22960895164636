<template>
  <div style="margin-bottom: 30px">
    <el-table class="common mb-50" :data="info" :default-sort='{prop:"time",order:"descending"}' border>
      <el-table-column label="时间" sortable prop="time" width="200px">
        <template slot-scope="{ row }">
          <span>{{ row.time }}</span>
        </template>
      </el-table-column>
      <el-table-column label="描述" prop="message"></el-table-column>
      <el-table-column label="备注" prop="notes"></el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  props: ["info"],
  data() {
    return {};
  },
};
</script>

<style>
</style>