<template>
	<div id="cloud-order-detail" class="bg-f">
		<top></top>
		<div class="store-confirm-order">
			<div v-loading='loading' class="pb-50">
				<div class="order-info clearfix mb-50">
					<div class="title">
						订单信息
					</div>
					<el-form class='fl'>
						<el-form-item label="订单编号:">
							{{orderInfoObj.sn}}
						</el-form-item>
						<el-form-item label="客户名称:">
							{{orderInfoObj.buyerName}}
						</el-form-item>
						<el-form-item label='下单时间:'>
							{{orderInfoObj.gmtCreate | time}}
						</el-form-item>
						<el-form-item label='订单状态:'>
							<p class="color-danger" v-if=" orderInfoObj.editAuditState == 1 && orderInfoObj.orderStatus == 1">待审核</p>
							<div v-else>
								<p v-if=" orderInfoObj.orderStatus == 1 " class="color-danger">待付款
									<span class="color-danger font-14 ml-20" v-if='(orderInfoObj.editAuditState == 3||orderInfoObj.editAuditState == null) && orderInfoObj.orderStatus == 1 && (orderInfoObj.lastestRemittanceAuditStatus == 2 || orderInfoObj.lastestRemittanceAuditStatus == null)'>
										剩余
										<span v-text="typeof(orderInfoObj.remainAutoCancleTime)=='string'?orderInfoObj.remainAutoCancleTime:null"></span>
									</span>
								</p>
							</div>
							<p v-if=" orderInfoObj.orderStatus == 0 " class="color-danger">已关闭</p>
							<p v-if=" orderInfoObj.orderStatus == 2 " class="color-danger">{{ orderInfoObj.shipStatus === 4 ? '部分发货' : '待发货'}}</p>
							<p v-if=" orderInfoObj.orderStatus == 3 " class="color-danger">已发货</p>
							<p v-if=" orderInfoObj.orderStatus == 4 " class="color-danger">已完成</p>

						</el-form-item>
						<el-form-item label='订单金额:'>
							￥{{orderInfoObj.needPayMoney | fmoney}}
						</el-form-item>
					</el-form>
					<div class="fr mt-30">
						<div v-if="orderInfoObj.editAuditState == 1 && orderInfoObj.orderStatus==1">
							<el-button @click="auditPass(orderInfoObj)">审核通过</el-button>
							<el-button class="mr-45" @click="auditReject(orderInfoObj)">驳回申请</el-button>
						</div>
						<div v-else>
							<div>
								<div class='mt-10' v-if="orderInfoObj.orderStatus == 3">
									<el-button class="mr-45" @click='confirmGoods(orderInfoObj.sn)'>确认收货</el-button>
								</div>
								<div v-if="orderInfoObj.editAuditState == 1 && orderInfoObj.orderStatus == 1"></div>
								<div v-else>
									<div v-if="orderInfoObj.orderStatus == 1 && orderInfoObj.payStatus == 1 && (orderInfoObj.lastestRemittanceAuditStatus == null || orderInfoObj.lastestRemittanceAuditStatus == 2 )">
										<el-button class="ml-0 mt-10 mr-45" @click="handleCancelOrder">取消订单</el-button>
									</div>
								</div>
							</div>
							<el-button type="danger" class="mr-45" v-if="orderInfoObj.lastestRemittanceAuditStatus == 1 " @click='auditRemittance(orderInfoObj.sn)'>审核付款凭证</el-button>
						</div>
					</div>
					<!--审核驳回弹出框-->
					<el-dialog :visible.sync="auditRejectVisible" title="驳回申请理由" class="editAuditFail">
						<el-form :model="auditForm" :rules="rules" ref="editAuditFail">
							<el-form-item prop="editAuditFailReason">
								<el-input type="textarea" :autosize="{ minRows: 3 ,maxRows: 5}" v-model.trim="auditForm.editAuditFailReason"></el-input>
							</el-form-item>
						</el-form>
						<div slot='footer' class="dialog-footer">
							<el-button type='danger' @click="handleCommitEditAuditFail">确 定</el-button>
							<el-button @click="auditRejectVisible = false">取 消</el-button>
						</div>
					</el-dialog>

                    <!-- TODO 先注释后期后端给判断条件放开 -->
					<!-- <div class="fr mt-30" v-if="orderInfoObj.orderStatus == 2"> -->
						<!--<el-button class=' mr-45'>通知发货</el-button>-->
						<!-- <el-button class=' mr-45' v-if="orderInfoObj.jdeSn==null && orderInfoObj.k3Sn==null" @click="shipConfirmDialog(orderInfoObj.sn)">发货</el-button> -->
					<!-- </div> -->
				</div>

				<el-tabs class='tabs' v-model="activeName" @tab-click="handleClick">
					<el-tab-pane label="订单详情" name="1"></el-tab-pane>
					<el-tab-pane label="出库／发货记录" name="2"></el-tab-pane>
					<el-tab-pane label="收款记录" name="3"></el-tab-pane>
					<el-tab-pane label="订单操作日志" name="4"></el-tab-pane>
					<el-tab-pane label="赠品" name="6"></el-tab-pane>
					<el-tab-pane label="订单备注" name="5"></el-tab-pane>
				</el-tabs>
				<div v-if="activeName == '1'">
					<div class="order-detail ">
						<div class="order-content">
							<div class="item mb-20">
								<div class="item-content">
									<div class="item-item clearfix" v-for="(item, index) in orderInfoObj.orderItemVos" :key="index">
										<div class="clearfix order-item-item pt-20 pl-20 pb-20">
											<img v-if='item.image' class="fl img mr-10" :src="item.image" alt="没有图片" />
											<img v-else class="fl img mr-10"  src='../../assets/goods.jpg'/>
											<div class="fl" style="min-width:240px;">
												<p class="mb-20" style="width: 240px;" v-text="item.skuName"> </p>
												<p class="color-9">规格: 1*{{item.specInfoNum}}</p>
											</div>
										</div>
										<div class="order-item-item-2 pt-20 ">
											<p>¥{{item.showUnitPrice | fmoney}}/{{item.showUnitName}}</p>
										</div>
										<div class="order-item-item-3 pt-20">
											<p>{{item.specNum}}{{item.unit}}</p>
										</div>
										<div class="order-item-item-4 pt-20">
											<p class="color-F656 ">￥{{item.discountTotalPrice}}</p>
										</div>
										<div class="pt-20 order-item-item-5">
											<el-button v-if="item.remark" @click="modifyRemark(item)">修改备注</el-button>
											<el-button v-else @click="modifyRemark(item)">添加备注</el-button>
										</div>
									</div>

									<div class="item-item clearfix" v-for="(item, index) in orderInfoObj.orderGiftResVos" :key="index">
										<div class="clearfix order-item-item pt-20 pl-20 pb-20">
                                            <img v-if="item.image" class="fl img mr-10" :src="item.image" alt="spuImage" />
											<img v-else class="fl img mr-10"  src='../../assets/goods.jpg'/>
											<div class="fl" style="min-width:240px;">
												<p class="mb-20" v-text="item.skuName"> </p>
												<p class="color-9" v-if="item.specInfoNum">规格: 1*{{item.specInfoNum}}</p>
											</div>
										</div>
										<div class="order-item-item-2 pt-20 ">
											<p>￥0</p>
										</div>
										<div class="order-item-item-3 pt-20">
											<p>{{item.giftNum}}{{item.sellingUnitName}}</p>
										</div>
										<div class="order-item-item-4 pt-20">
											<p class="color-F656 ">￥0</p>
										</div>
										<div class="pt-20 order-item-item-5">

										</div>
									</div>
								</div>
								<div class="total clearfix">
									<ul class="fr">
										<li class="tar">活动减免金额：<span class='ml-30'>-￥ {{(orderInfoObj.activityCutMoney || 0) | fmoney}}</span></li>
										<li class="tar">优惠券减免金额：<span class='ml-30'>-￥{{(orderInfoObj.couponCutMoney || 0) | fmoney}} </span></li>
										<li class="tar">运费：<span class='ml-30'>+￥ {{(orderInfoObj.freightMoney || 0) | fmoney}}</span></li>
										<li class="font-20 payment tar">应付金额：<span class='ml-30'>￥ {{(orderInfoObj.needPayMoney || 0)  | fmoney}}</span></li>
									</ul>
								</div>
							</div>
						</div>
					</div>
					<!--添加修改备注弹框-->
					<el-dialog @close="handleCancelRemark()" class='remarkDialog' :close-on-click-modal='false' title='备注信息' :visible.sync='remarkDialog'>
						<el-form class="pl-30 pr-30" :model="form" ref="remarkDialog" :rules="rules">
							<el-form-item prop="remark">
								<el-input type='textarea' placeholder='请输入备注信息...' :autosize="{ minRows: 3 ,maxRows: 5}" v-model.trim='form.remark'></el-input>
							</el-form-item>
						</el-form>
						<div slot='footer' class="dialog-footer">
							<el-button type='danger' @click="handleSaveRemark()">确 定</el-button>
							<el-button @click="handleCancelRemark()">取 消</el-button>
						</div>
					</el-dialog>

					<div class="order-info clearfix mb-20">
						<div class="title">
							<p class="fl">收货信息</p>
							<p class="fr mr-30">
								<el-button type='text' v-if="orderDeliveryAddressList.length>1" @click="changeAddressVisible=true">更换地址</el-button>
							</p>
						</div>
						<el-form class='fl'>
							<el-form-item label="收货人:">
								{{orderInfoObj.addrUsername}}
							</el-form-item>
							<el-form-item label="电话:">
								{{orderInfoObj.addrMobile}}
							</el-form-item>
							<el-form-item label='地址:'>
								{{orderInfoObj.addrProvince}} {{orderInfoObj.addrCity}} {{orderInfoObj.addrRegion}} {{orderInfoObj.addr}}
							</el-form-item>
							<!--<el-form-item label='交货日期:'>
							无交货日期
							<el-button class='ml-30' type='text' style='height: auto;'>修 改</el-button>
						</el-form-item>-->
						</el-form>
					</div>
					<!--更换地址dialog-->
					<el-dialog title="更换地址" class="changeAddress" :visible.sync="changeAddressVisible" size="tiny">
						<div class="ml-50 mb-50 ml-50">
							<div class="" v-for="(item, index) in orderDeliveryAddressList" :key="index">
								<el-radio v-model="address" :label="item">
									{{item.linkman}} {{item.tel}}
									<!--<span class="background-3 color-white " v-text="item.addressType == 4?'营业地址':'收货地址'"></span>-->
									<div class="ml-27">{{item.provinceName}} {{item.cityName}} {{item.regionName}} {{item.attr}}</div>
								</el-radio>
							</div>
						</div>
						<div slot="footer" class="dialog-footer">
							<el-button type="primary" @click="handleChangeAddress">确 定</el-button>
							<el-button @click="changeAddressVisible=false">取 消</el-button>
						</div>
					</el-dialog>
					<!--发票信息-->
					<div v-if="orderInfoObj.orderInvoicePo">
						<div class="order-info clearfix mb-20" v-if="orderInfoObj.orderInvoicePo.type==2">
							<div class="title">
								<p class="fl">发票信息</p>
								<p class="fr mr-30">
									<el-button type='text' @click="updateInvoice(2)" v-if="orderInfoObj.payStatus == 1 && orderInfoObj.orderStatus==1 && orderInfoObj.lastestRemittanceAuditStatus != 1">修改发票</el-button>
								</p>
							</div>
							<el-form class='fl mb-10'>
								<el-form-item label="发票类型:">
									{{orderInfoObj.orderInvoicePo.type==1?'普通发票':'增值税发票'}}
								</el-form-item>
								<!-- <el-form-item label="发票内容:">
									{{orderInfoObj.orderInvoicePo.content==1?'酒水':'明细'}}
								</el-form-item> -->
								<el-form-item label="公司名称:">
									{{orderInfoObj.orderInvoicePo.companyHead}}
								</el-form-item>
								<el-form-item label="纳税识别码:">
									{{orderInfoObj.orderInvoicePo.taxpayerIdNo}}
								</el-form-item>
								<el-form-item label='注册地址:'>
									{{orderInfoObj.orderInvoicePo.registerAddress}}
								</el-form-item>
								<el-form-item label='注册电话:'>
									{{orderInfoObj.orderInvoicePo.registerPhone}}
								</el-form-item>
								<el-form-item label='开户银行:'>
									{{orderInfoObj.orderInvoicePo.bankDeposit}}
								</el-form-item>
								<el-form-item label='银行账号:'>
									{{orderInfoObj.orderInvoicePo.bankCard}}
								</el-form-item>
							</el-form>
						</div>

						<div class="order-info clearfix mb-20" v-if="orderInfoObj.orderInvoicePo.type==1">
							<div class="title">
								<p class="fl">发票信息</p>
								<p class="fr mr-30">
									<el-button type='text' @click="updateInvoice(1)" v-if="orderInfoObj.payStatus == 1 && orderInfoObj.orderStatus==1 && orderInfoObj.lastestRemittanceAuditStatus != 1">修改发票</el-button>
								</p>
							</div>
							<el-form class='fl mb-10'>
								<el-form-item label="发票类型:">
									{{orderInfoObj.orderInvoicePo.type==1?'普通发票':'增值税发票'}}
								</el-form-item>
								<el-form-item label="发票抬头:">
									{{orderInfoObj.orderInvoicePo.companyHead}}
								</el-form-item>
								<!-- <el-form-item label="发票内容:">
									{{orderInfoObj.orderInvoicePo.content==1?'酒水':'明细'}}
								</el-form-item> -->
							</el-form>
						</div>

					</div>
					<!--发票弹框-->
					<el-dialog title="发票信息":visible.sync="invoiceDialogVisible" :close-on-click-modal='false' class='billDialog' @close='handleCloseDialog'>
						<div class="bill-type">
							<el-radio-group v-model="radio" @change="changeRadio">
								<el-radio label="1">普通发票</el-radio>
								<el-radio label="2">增值发票</el-radio>
							</el-radio-group>
						</div>
						<el-form v-show="radio=='1'" ref="invoiceInfoVOs1Form" :model='invoiceInfoVOs1Form' :rules="rules">
							<el-form-item label='发票抬头' prop="item">
								<el-input v-model.trim='invoiceInfoVOs1Form.item'></el-input>
							</el-form-item>
							<!-- <el-form-item label='发票内容' prop="invoiceContent">
								<el-radio class="radio" v-model="invoiceInfoVOs1Form.invoiceContent" label="1">酒水</el-radio>
								<el-radio class="radio" v-model="invoiceInfoVOs1Form.invoiceContent" label="2">明细</el-radio>
							</el-form-item> -->
						</el-form>
						<el-form v-show="radio=='2'" :model='invoiceInfoVOs2Form' ref="invoiceInfoVOs2Form" class="pb-20" :rules="rules">
							<!-- <el-form-item label='发票内容' prop="invoiceContent">
								<el-radio class="radio" v-model="invoiceInfoVOs2Form.invoiceContent" label="1">酒水</el-radio>
								<el-radio class="radio" v-model="invoiceInfoVOs2Form.invoiceContent" label="2">明细</el-radio>
							</el-form-item> -->
							<el-form-item label='公司名称' required prop="companyName1">
								<el-input v-model.trim='invoiceInfoVOs2Form.companyName1'></el-input>
							</el-form-item>
							<el-form-item label='纳税人识别码' required prop="taxpayerNumber1">
								<el-input v-model.trim='invoiceInfoVOs2Form.taxpayerNumber1' placeholder="输入15-20位纳税人识别号"></el-input>
							</el-form-item>
							<el-form-item label='注册地址' required prop="address1">
								<el-input v-model.trim='invoiceInfoVOs2Form.address1'></el-input>
							</el-form-item>
							<el-form-item label='注册电话' required prop="tel1">
								<el-input v-model.trim='invoiceInfoVOs2Form.tel1'></el-input>
							</el-form-item>
							<el-form-item label='开户银行' required prop="bankName1">
								<el-input v-model.trim='invoiceInfoVOs2Form.bankName1'></el-input>
							</el-form-item>
							<el-form-item label='银行账户' required prop="bankCard1">
								<el-input v-model.trim='invoiceInfoVOs2Form.bankCard1'></el-input>
							</el-form-item>
						</el-form>
						<div slot="footer" class="dialog-footer">
							<el-button type="danger" @click="handleInvoiceSave">确 定</el-button>
						</div>
					</el-dialog>

				</div>
				<!--出库记录-->
				<div v-if="activeName == '2'" class="noContent">
                    <el-table v-if="detailList && detailList.length > 0" :data="detailList" border tooltip-effect="dark">
                        <el-table-column label="序号" width="50">
                            <template slot-scope="scope">
								{{scope.$index + 1}}
							</template>
						</el-table-column>
						<el-table-column label="货品信息">
							<template slot-scope="scope">
								<img v-if="scope.row.skuImage" class="fl img mr-10" :src="scope.row.skuImage" />
								<img v-else src="../../assets/goods.jpg" class="img fl mr-10" alt="" />
								<div class="fl">{{scope.row.skuName}}</div>
							</template>
						</el-table-column>
						<el-table-column label="商品编码" prop='skuNo' align="center" width="120"></el-table-column>
						<el-table-column label="总数量" prop='specNumTotal' align="center" width="85"></el-table-column>
						<el-table-column label="单位" prop='specUnitName' align="center" width="80"></el-table-column>
						<el-table-column label="K3出库单号" prop='deliveryId' align="center" width="120"></el-table-column>
						<el-table-column label="K3出库数量" prop='specNum' align="center" width="100"></el-table-column>
						<el-table-column label="出库时间" prop='gmtDelivery' align="center" width="120">
							<template slot-scope="scope">
								{{scope.row.gmtDelivery | time}}
							</template>
						</el-table-column>
					</el-table>
					<div v-else class="font-20 color-9 tac">暂无记录</div>
                </div>
				<!--收款记录-->
				<div class="" v-if="activeName == '3'" key='payment'>
					<div style="width: 940px;margin: 0 auto;" class="tar mb-20">
						<el-button @click="paymentVoucherVisible = true;form.payMoney = orderInfoObj.needPayMoney;" v-show="orderInfoObj.payStatus == 1&&orderInfoObj.orderStatus != 0">添加付款凭证</el-button>

						<!--付款凭证dialog-->
						<el-dialog title="添加付款凭证信息" class="paymentVoucherDialog" :visible.sync='paymentVoucherVisible'>
							<el-form :model="form" :rules="rules" ref="formPayment">
								<el-form-item label='收款银行:' prop="bankInfo">
									<el-select v-model='form.id' class="fl" placeholder='选择你的收款银行'>
										<el-option v-for="(item, index) in sellerBankInfoList" :key="index" :label='item.bankName' :value='item.id'></el-option>
									</el-select>
								</el-form-item>
								<div v-for="(item, index) in bankInfo" :key="index" v-show="bankInfo.length != 0">
									<el-form-item label='持卡人姓名:'>
										<el-input placeholder='持卡人姓名' class="fl" disabled v-model.trim='item.bankUserName'></el-input>
									</el-form-item>
									<el-form-item label='开户银行:'>
										<el-input placeholder='请输入你的开户银行' class="fl" disabled v-model.trim="item.bankDeposit"></el-input>
									</el-form-item>
									<el-form-item label='银行卡号:'>
										<el-input placeholder='请输入你的银行卡号' class="fl" disabled v-model.trim="item.bankCard"></el-input>
									</el-form-item>
								</div>
								<el-form-item label='付款金额:' prop="payMoney">
									<el-input placeholder='请输入付款金额' class="fl" disabled v-model.trim="form.payMoney"></el-input>
								</el-form-item>
								<el-form-item label='汇款凭证:' prop="paymentImg">
									<input type="hidden" v-model="form.paymentImg" />
									<el-upload class="avatar-uploader fl" :loading="imgLoading" action="/sunrise-gateway/oss/ossUpload" :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
										<img v-if="form.paymentImg" :src="form.paymentImg" class="avatar">
										<i v-else class="el-icon-plus avatar-uploader-icon"></i>
										<div class="el-upload__tip" slot="tip">请上传有效清晰地凭证,4M以内,支持jpg,png格式。</div>
									</el-upload>
								</el-form-item>
							</el-form>
							<div slot="footer" class="dialog-footer">
								<el-button type="danger" @click="paymentDialogSave">确 定</el-button>
								<el-button @click="paymentDialogCancel">取 消</el-button>
							</div>
						</el-dialog>
					</div>
					<el-table class='common mb-50' :data='orderInfoObj.paymentLogsVOs'>
						<el-table-column label='支付流水号' prop='paySn' show-overflow-tooltip></el-table-column>
						<el-table-column label='收款时间' show-overflow-tooltip>
							<template slot-scope="scope">
								<span v-if="scope.row.payTime">{{scope.row.payTime | time}}</span>
							</template>
						</el-table-column>
						<el-table-column label='收款账号' prop='traderBankAccount' show-overflow-tooltip></el-table-column>
						<el-table-column label='收款金额' prop='payMoney'></el-table-column>
						<el-table-column label='支付方式'>
							<template slot-scope="scope">
								<span>{{scope.row.payMethod == 1 ?'线下打款' : '其他' }}</span>
							</template>
						</el-table-column>
						<el-table-column label='状态'>
							<template>
								<span>成功</span>
							</template>
						</el-table-column>
						<el-table-column label='收款凭证'>
							<template slot-scope="scope">
								<el-button type='text' v-if="scope.row.isHasVoucher?true:false" @click="handleLookremittance(scope.row.id)">查 看</el-button>
							</template>
						</el-table-column>
					</el-table>
					<el-dialog :visible.sync="dialogVisible" size="tiny" class="showImgDialog">
						<img width="100%" :src="dialogImageUrl" alt="">
					</el-dialog>
				</div>
				<el-dialog title="付款凭证信息" class="lookpaymentDialog" :visible.sync='lookpaymentVisible'>
					<el-form :model="orderRemittanceApplyVo">
						<div>
							<el-form-item label='持卡人姓名:'>
								<el-input placeholder='持卡人姓名' class="fl" readonly v-model.trim='orderRemittanceApplyVo.bankUserName'></el-input>
							</el-form-item>
							<el-form-item label='收款银行名称:'>
								<el-input class="fl" readonly v-model.trim="orderRemittanceApplyVo.incomeAccountName"></el-input>
							</el-form-item>
							<el-form-item label='收款银行账号:'>
								<el-input class="fl" readonly v-model.trim="orderRemittanceApplyVo.incomeAccountNum"></el-input>
							</el-form-item>
						</div>
						<el-form-item label='付款金额:'>
							<el-input placeholder='请输入付款金额' class="fl" readonly v-model.trim="orderRemittanceApplyVo.amount"></el-input>
						</el-form-item>
						<el-form-item label='汇款凭证:'>
							<el-upload :disabled="true" class="avatar-uploader fl" action="/sunrise-gateway/oss/ossUpload" list-type="picture-card" :on-preview="handlePictureCardPreview" :file-list="orderRemittanceApplyVo.fileList"></el-upload>
						</el-form-item>
					</el-form>
					<div slot="footer" class="dialog-footer">
						<el-button type="danger" @click="lookpaymentVisible=false">确 定</el-button>
					</div>
				</el-dialog>
				<!--操作日志-->

				<div class="" v-if="activeName == '4'" key='orderLog'>
					<el-table class='common mb-50' :data='orderInfoObj.orderLogVos'>
						<el-table-column label='操作时间' prop='gmtCreate'>
							<template slot-scope="scope">
								<span>{{scope.row.gmtCreate | time}}</span>
							</template>
						</el-table-column>
						<el-table-column label='操作人' prop='operatorName'></el-table-column>
						<el-table-column label='操作' prop='message'></el-table-column>
						<el-table-column label='状态' prop='result'>
							<template slot-scope="scope">
								<span v-text="scope.row.result==1?'成功':'失败'"></span>
							</template>
						</el-table-column>
						<el-table-column label='备注' prop='remark'>
							<template slot-scope="scope">
								<span v-text="scope.row.remark?'scope.row.remark':'无'"></span>
							</template>
						</el-table-column>
					</el-table>
				</div>

				<!--订单备注-->
				<div class="" v-if="activeName == '5' " key="orderRemark">
					<el-table class='common mb-50' :data='orderInfoObj.orderRemarkVos'>
						<el-table-column label='操作人' prop="operatorName"></el-table-column>
						<el-table-column label='类型'>
							<template slot-scope="scope">
								{{scope.row.traderType=='1'?'买方':'卖方'}}
							</template>
						</el-table-column>
						<el-table-column label='备注' prop='remark' show-overflow-tooltip></el-table-column>
						<el-table-column label='创建时间'>
							<template slot-scope="scope">
								{{scope.row.gmtCreate | time}}
							</template>
						</el-table-column>
					</el-table>
					<el-input type='textarea' placeholder="请输入订单备注..." v-model.trim="newRemark" class='textrea' :autosize='{minRows: 4, maxRows: 8}'></el-input>
					<el-button type='danger' :disabled="newRemark==null||newRemark.trim()==''" class='remark-btn mt-20' @click="addNewRemark">添加备注</el-button>
				</div>

				<!-- 赠品 -->
				<div v-if="activeName === '6'" class="mGiftM common mb-50">
					<el-table class='common mb-50' :data='giftTableList'>
						<el-table-column label='操作人' prop='subEmployeeName'>
							<!-- <template slot-scope="scope">
								<span>{{scope.row.gmtCreate | time}}</span>
							</template> -->
						</el-table-column>
						<el-table-column label='备注' prop='orderGiftContent'>
							<template slot-scope="scope">
								{{scope.row.orderGiftContent || '--'}}
							</template>
						</el-table-column>
					</el-table>
				</div>
			</div>
		</div>
			<!--取消订单弹出框-->
					<el-dialog class='cancelOrderDialog' :close-on-click-modal='false' @close="closeOrderDialog" title='取消订单理由' :visible.sync='cancelOrderVisible'>
						<el-form class="pl-30 pr-30" :model="form" ref="cancelOrderForm">
							<el-form-item label="" prop='cancleReason'>
								<el-input type='textarea' placeholder='请输入取消订单的理由...' :autosize="{ minRows: 3 ,maxRows: 5}" v-model.trim='form.cancleReason'></el-input>
							</el-form-item>
						</el-form>
						<div slot='footer' class="dialog-footer">
							<el-button type='danger' @click="cancelOrderCommit">提 交</el-button>
							<el-button @click="cancelOrderVisible = false">取 消</el-button>
						</div>
					</el-dialog>
	</div>
</template>

<script>

	import { axiosApi, basePath } from "../../api/api"
	export default {
		data() {
			var checkbankInfo = (rule, value, callback) => {
				if(Object.keys(value).length <= 0) {
					return callback(new Error('请选择付款银行！'))
				} else {
					callback()
				}
			}
			return {
				basePath: basePath,
				activeName: '1',
				goodTable: [],
				supplierOrderDetail: null, //sessionStorage:存储orderSn
				orderInfoObj: {}, //订单详情信息
				remarkDialog: false, //添加备注
				loading: true, //loading
				changeAddressVisible: false, //更换地址boolean
				orderDeliveryAddressList: [], //客户地址
				address: {}, //打开地址弹框 默认选中的值,
				paymentLogsVOs: [], //付款记录
				paymentVoucherVisible: false, //付款凭证visible
				imgLoading: false, // 添加凭证图片loading
				sellerBankInfoList: [], // 供应商银行信息列表
				form: {
					id:'',
					payMoney: null, //支付金额
					paymentImg: null, //支付凭证
					remark: null, //每条商品的备注信息
					cancleReason: null, //取消订单的理由
					id:null, //id

				},
				rules: {
					payMoney: [{
						required: true,
						pattern: /^[1-9]{1}\d{0,7}(\.\d{1,2})?$/,
						message: '请输入正确支付金额'
					}],
					paymentImg: [{
						required: true,
						message: '请上传付款凭证！',
						trigger: 'change'
					}],
					remark: [{
						max: 30,
						message: '请输入1-30位字符'
					}],
					cancleReason: [{
						max: 30,
						message: '请输入1-30位字符'
					}],
					item: [{
						required: true,
						message: '请输入1-30位汉字、字母',
						pattern: /^[a-zA-Z\u4e00-\u9fa5]{1,30}$/
					}],
					companyName1: [{
						required: true,
						message: '请输入1-30位汉字、字母',
						pattern: /^[a-zA-Z\u4e00-\u9fa5]{1,30}$/
					}],
					taxpayerNumber1: [{
						required: true,
						message: "输入15-20位纳税人识别号",
						min: 15,
						max:20
					}],
					address1: [{
						required: true,
						max: 50,
						message: '请输入4-50位字符'
					}],
					tel1: [{
						pattern: /^[0-9]{7,12}$/,
						required: true,
						message: '请输入7-12位数字'
					}],
					bankName1: [{
						pattern: /^[\u4e00-\u9fa5]{4,30}$/,
						required: true,
						message: '请输入4-30个汉字'
					}],
					bankCard1: [{
						required: true,
						pattern: /^[0-9]{1,25}$/,
						message: '请输入1-25位数字'
					}],
					goodRemark: [{
						max: 30,
						message: '请输入1-30个字符'
					}],
					editAuditFailReason: [{
						required: true,
						max: 30,
						message: '请输入1-30个字符'
					}]
				},
				auditForm: {
					editAuditFailReason: null, //申请驳回理由
				},
				orderSn: null, //订单编号
				orderRemittanceApplyVo: {}, //根据付款凭证ID获取付款信息（查看）
				lookpaymentVisible: false, // 查看付款凭证 显示隐藏
				dialogVisible: false,
				dialogImageUrl: null,
				newRemark: null, //新添加的备注信息
				cancelOrderVisible: false, //取消订单 dialogVisible
				changePriceVisible: false, //改价dialog
				order: {}, //改价
				order2: {},
				passRemark: false, //备注通过
				invoiceDialogVisible: false,
				radio: '1',
				invoiceInfoVOs1Form: { //普通发票弹框
					item: '', //发票抬头
					invoiceContent: '1'
				},
				invoiceInfoVOs2Form: { //增值发票弹框
					companyName1: "",
					taxpayerNumber1: "",
					address1: "",
					tel1: "",
					bankName1: "",
					bankCard1: "",
					invoiceContent: '1'
				},
				invoiceInfoVOs1: {},
				invoiceInfoVOs2: {},
				invoiceSave1: false,
				invoiceSave2: false,
				auditRejectVisible: false, //审核驳回dialog

				/* 赠品 相关 start */

				giftTableList : [], // table 数据

				/* 赠品 相关 end */
                detailList: [] // 发货出库记录
			}
		},
		created() {
			this.$nextTick(() => {
				this.anonOrderDetailInfo() //订单详情
			})
		},
		computed: {
			bankInfo(){
				let that = this
			 	return this.sellerBankInfoList.filter((item)=>{
			 		return  item.id == that.form.id
			 	})
			}
		},
        mounted() {
			this.$nextTick(() => {
				this.anonOrderDetailInfo() //订单详情
				this.queryDeliveryInfo() // 发货出库记录
			})
		},
		methods: {
			/*审核未通过*/
			auditReject(obj) {
				this.auditRejectVisible = true
			},
			/*审核未通过*/
			handleCommitEditAuditFail() {
				this.$refs.editAuditFail.validate((valid) => {
					if(valid) {
						this.auditRejectVisible = false
						let body = {
							orderSn: this.orderInfoObj.sn,
							editAuditState: 2, //审核不通过
							editAuditFailReason: this.auditForm.editAuditFailReason
						}
						this.auditOrder(body)
					} else {
						return false
					}
				})
			},
			/*审核通过*/
			auditPass(obj) {
				let h = this.$createElement;
				this.$msgbox({
					title: '提示信息',
					message: h('p', {
						style: 'text-align:center'
					}, '确认审核通过？'),
					showCancelButton: false,
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					beforeClose: (action, instance, done) => {
						if(action === 'confirm') {
							let body = {
								orderSn: obj.sn,
								editAuditState: 3 //审核通过
							}
							clearTimeout(this.orderInfoObj.clearTime)
							this.auditOrder(body)
							done()
						} else {
							done()
						}
						delete instance.$slots.default;
					}
				})
			},
			/*审核通过、驳回接口*/
			auditOrder(body) {
				axiosApi('/order/audit', body, (res) => {
					this.$message({
						type: 'success',
						message: '操作成功！',
						duration: 1000
					})
					clearTimeout(this.orderInfoObj.clearTime)
					this.anonOrderDetailInfo()
				}, 1, 10, (res) => {

				})
			},
			/*修改发票*/
			updateInvoice(item) {
				if(item == 1) { //普通发票
					this.invoiceInfoVOs1Form.item = this.orderInfoObj.orderInvoicePo.companyHead
					this.invoiceInfoVOs1Form.invoiceContent = this.orderInfoObj.orderInvoicePo.content
				} else { //增值发票
					this.invoiceInfoVOs2Form.companyName1 = this.orderInfoObj.orderInvoicePo.companyHead
					this.invoiceInfoVOs2Form.taxpayerNumber1 = this.orderInfoObj.orderInvoicePo.taxpayerIdNo
					this.invoiceInfoVOs2Form.address1 = this.orderInfoObj.orderInvoicePo.registerAddress
					this.invoiceInfoVOs2Form.tel1 = this.orderInfoObj.orderInvoicePo.registerPhone
					this.invoiceInfoVOs2Form.bankName1 = this.orderInfoObj.orderInvoicePo.bankDeposit
					this.invoiceInfoVOs2Form.bankCard1 = this.orderInfoObj.orderInvoicePo.bankCard
					if(this.orderInfoObj.orderInvoicePo.content == null || this.orderInfoObj.orderInvoicePo.content == undefined) {
						this.invoiceInfoVOs2Form.invoiceContent = '1'
					} else {
						this.invoiceInfoVOs2Form.invoiceContent = this.orderInfoObj.orderInvoicePo.content + ''
					}
				}
				this.invoiceDialogVisible = true
			},
			handleCloseDialog() { //发票关闭清空没有数据的form表单
				this.invoiceDialogVisible = false
			},
			changeRadio() { //单选框
				// console.log(this.radio)
			},
			//保存发票
			handleInvoiceSave() {
				if(this.radio == '1') { //普通发票
					this.$refs.invoiceInfoVOs1Form.validate((valid) => {
						if(valid) {
							this.invoiceSave1 = true
							this.invoiceSave2 = false
							this.invoiceInfoVOs1.companyHead = this.invoiceInfoVOs1Form.item
							this.invoiceInfoVOs1.content = this.invoiceInfoVOs1Form.invoiceContent
							this.invoiceDialogVisible = false
							let body = {
								id: this.orderInfoObj.orderInvoicePo.id,
								orderId: this.orderInfoObj.id,
								orderSn: this.orderInfoObj.sn,
								buyeId: this.orderInfoObj.buyerId,
								type: 1,
								companyHead: this.invoiceInfoVOs1.companyHead,
								content: this.invoiceInfoVOs1.content
							}
							this.orderInvoiceUpdate(body)
						} else {
							return false
						}
					})
				} else { //增值发票
					this.$refs.invoiceInfoVOs2Form.validate((valid) => {
						if(valid) {
							this.invoiceSave2 = true
							this.invoiceSave1 = false
							this.invoiceInfoVOs2.companyName = this.invoiceInfoVOs2Form.companyName1
							this.invoiceInfoVOs2.taxpayerNumber = this.invoiceInfoVOs2Form.taxpayerNumber1
							this.invoiceInfoVOs2.address = this.invoiceInfoVOs2Form.address1
							this.invoiceInfoVOs2.tel = this.invoiceInfoVOs2Form.tel1
							this.invoiceInfoVOs2.bankName = this.invoiceInfoVOs2Form.bankName1
							this.invoiceInfoVOs2.bankCard = this.invoiceInfoVOs2Form.bankCard1
							this.invoiceInfoVOs2.content = this.invoiceInfoVOs2Form.invoiceContent
							this.invoiceDialogVisible = false
							let body = {
								id: this.orderInfoObj.orderInvoicePo.id,
								orderId: this.orderInfoObj.id,
								orderSn: this.orderInfoObj.sn,
								buyeId: this.orderInfoObj.buyerId,
								type: 2,
								companyHead: this.invoiceInfoVOs2.companyName,
								taxpayerIdNo: this.invoiceInfoVOs2.taxpayerNumber,
								registerAddress: this.invoiceInfoVOs2.address,
								registerPhone: this.invoiceInfoVOs2.tel,
								bankDeposit: this.invoiceInfoVOs2.bankName,
								bankCard: this.invoiceInfoVOs2.bankCard,
								content: this.invoiceInfoVOs2.content
							}
							this.orderInvoiceUpdate(body)
						} else {
							return false
						}
					})
				}
			},
			//订单发票信息修改
			orderInvoiceUpdate(body) {
				axiosApi('/sales/orderInvoice/web/update', body, (res) => {
					// console.log('订单发票信息修改', res)
					this.$message({
						type: 'success',
						message: '操作成功',
						duration: 1000
					})
					clearTimeout(this.orderInfoObj.clearTime)
					this.anonOrderDetailInfo()
				}, 1, 10, (res) => {
					//					this.$message({type: 'error',message:res.data.message,duration: 1000})
				})
			},

			//审核支付凭证Btn
			auditRemittance(orderSn) {
				sessionStorage.setItem('mySellerId', this.orderInfoObj.sellerId),
					this.$router.push({
						path: basePath + '/cloudPayCertCheck',
						query: {
							sign: orderSn
						}
					})
			},
			//			确认收货
			confirmGoods(orderSn) {
				let that = this;
				this.$confirm('你是否确认收货？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					const api = "/sales/orderShip/app/confirm";
					const body = { orderSn: orderSn };
					axiosApi(api, body, (res) => {
						this.$message({
							type: 'success',
							message: '收货成功!',
							duration: 1000
						});
						that.orderInfoObj.orderStatus = 4
					}, this.currentPage, 10, (res) => {
						this.$message({
							type: 'info',
							message: res.data.message,
							duration: 3000
						});
					});
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消收货'
					});
				})
			},
			//备注验证
			checkRemark(order) {
				if(order.remark.length > 30) {
					this.passRemark = true
				} else {
					this.passRemark = false
				}
			},
			//匹配整数 小数点后两位
			testFreight(order) {
				let reg = /^[\+]?\d*?\.?\d*?$/
				if(!reg.test(order.freightMoney)) {
					order.freightMoney = 0
				} else {
					if(order.freightMoney !== 0 && order.freightMoney.indexOf('.') > 0) {
						order.freightMoney = order.freightMoney.substr(0, order.freightMoney.indexOf('.') + 3)
					}
				}
			},
			//折扣匹配 正负数 小数点后两位
			num(order) {
				let reg = /^[\+\-]?\d*?\.?\d*?$/
				if(!reg.test(order.changedPrice)) {
					order.changedPrice = 0
				} else {
					if(order.changedPrice !== 0 && order.changedPrice.indexOf('.') > 0) {
						order.changedPrice = order.changedPrice.substr(0, order.changedPrice.indexOf('.') + 3)
					}
				}
			},
			handleCancelOrder() { //取消订单按钮
				console.log(this.cancelOrderVisible)
				this.cancelOrderVisible = true
				console.log(this.cancelOrderVisible,'3')
			},
			closeOrderDialog() {
				this.$refs.cancelOrderForm.resetFields()
				this.cancelOrderVisible = false
			},
			//弹窗 取消订单
			cancelOrderCommit() {
				this.cancelOrderVisible = false
				this.cancelOrder({
					orderSn: this.orderSn,
					cancleReason: this.form.cancleReason,
					cancleType: 2,
					sellerId:this.orderInfoObj.sellerId
				})
			},
			//取消订单接口
			cancelOrder(body) {
				this.loading = true
				axiosApi('/sales/order/web/cancle', body, (res) => {
					this.loading = false
					// console.log('取消订单', res)
					this.$message({
						type: 'success',
						message: '操作成功！',
						duration: 1000
					})
					clearTimeout(this.orderInfoObj.clearTime)
					this.anonOrderDetailInfo()
				}, 1, 10, (res) => {
					this.loading = false
					// console.log('取消订单Error', res)
					this.$message({
						type: 'error',
						message: res.data.message,
						duration: 1000
					})
				})
			},
			//商品添加备注
			/*备注弹框取消重置表单*/
			handleCancelRemark() {
				this.$refs.remarkDialog.resetFields()
				this.remarkDialog = false
			},
			/*修改明细备注*/
			modifyRemark(item) {
				if(item.remark){
					this.form.remark = item.remark
				}
				this.form.id = item.id
				this.remarkDialog = true
			},
			/*添加备注信息*/
			handleSaveRemark() {
				// console.log(item, '添加备注信息', this.$refs)
				this.$refs.remarkDialog.validate((valid) => {
					if(valid) {
						this.orderItemPriceChange({
							itemId: this.form.id,
							remark: this.form.remark,
							sellerId: sessionStorage.traderId
						})
						this.remarkDialog = false
					} else {
						return false
					}
				})
			},
			/*订单明细改价*/
			orderItemPriceChange(body) {
				axiosApi('/sales/orderItem/web/priceChange', body, (res) => {
					this.$message({
						type: 'success',
						message: '操作成功！',
						duration: 1000
					})
					clearTimeout(this.orderInfoObj.clearTime)
					this.anonOrderDetailInfo()
				}, 1, 10, (res) => {

				})
			},

			addNewRemark() {
				this.anonOrderRemarkAdd({
					orderSn: this.orderSn,
					remark: this.newRemark,
					traderType: 2
				})
			},
			// 订单备注添加接口
			anonOrderRemarkAdd(body) {
				axiosApi('/sales/orderRemark/web/insert', body, (res) => {
					// console.log('订单备注添加', res)
					this.$message({
						type: 'success',
						message: '操作成功！',
						duration: 1000
					})
					clearTimeout(this.orderInfoObj.clearTime)
					this.anonOrderDetailInfo()
					this.newRemark = null
				}, 1, 10, (res) => {

				})
			},
			beforeAvatarUpload(file) { //上传之前图片的判断
				let bool = this.isUploadImg(file)
				if(bool) {
					this.imgLoading = true
				}
				return bool
			},
			handleAvatarSuccess(res, file) { //上传付款凭证  成功
				this.imgLoading = false
				this.form.paymentImg = res.data[0]
				this.$refs.formPayment.validateField('paymentImg');
			},
			//点击修改订单按钮
			changeorderdetail() {
				let changeOrderObj = {
					type: 'supplierChangeOrder',
					orderSn: this.orderSn
				}
				sessionStorage.setItem('changeOrderObj', JSON.stringify(changeOrderObj))
				this.$router.push(basePath + "/cloudChangeOrder")
			},
			paymentDialogCancel() {
				this.paymentVoucherVisible = false;
				this.$refs.formPayment.resetFields(); //重置表单
			},
			paymentDialogSave() { //上传凭证确定保存
				this.$refs.formPayment.validate((valid) => {
					if(valid) {
						this.paymentVoucherVisible = false;
						/*插入付款凭证*/
						this.anonRemittanceAppplyAdd({
							orderSn: this.orderSn,
							amount: this.form.payMoney,
							bankUserName: this.bankInfo[0].bankUserName,
							incomeAccountNum: this.bankInfo[0].bankCard,
							incomeAccountName: this.bankInfo[0].bankDeposit,
							urls: [this.form.paymentImg],
							traderBankId: this.bankInfo[0].id
						})
					} else {
						return false;
					}
				})
			},
			//插入汇款凭证
			anonRemittanceAppplyAdd(body) {
				axiosApi('/sales/orderRemittance/app/insert', body, (res) => {
					// console.log('插入汇款凭证', res)
					this.$message({
						type: 'info',
						message: '操作成功！',
						duration: 1000
					})
					clearTimeout(this.orderInfoObj.clearTime)
					this.anonOrderDetailInfo()
				}, 1, 10, (res) => {
					this.$message({
						type: 'error',
						message: res.data.message,
						duration: 1000
					})
				})
			},

			handlePictureCardPreview(file) { //图片放大
				this.dialogImageUrl = file.url;
				this.dialogVisible = true
			},
			findSellerBankInfoList(body) { //线下支付的账号信息列表(接口已改)
				axiosApi('/trader/findSellerBankInfoList/select', body, (res) => {
					this.sellerBankInfoList = res.data.data
					// this.form.bankName = res.data.data[0].bankName
					this.form.id = res.data.data[0].id
				}, 1, 10, (res) => {
					this.$message({
						type: 'error',
						message: res.data.message,
						duration: 2000
					})
				})
			},
			handleLookremittance(id) { //查看付款凭证
//				console.log(this.orderSn)
				this.remittanceApply({
					id: id,
					orderSn:this.orderSn
				})
				this.lookpaymentVisible = true
			},
			remittanceApply(body) { //根据付款记录id获取汇款凭证
				axiosApi('/sales/orderRemittance/web/selectByPaymentId', body, (res) => {
					// console.log(res, '根据付款记录id获取汇款凭证')
					this.orderRemittanceApplyVo = res.data.data
					this.orderRemittanceApplyVo.fileList = [{
						url: JSON.parse(this.orderRemittanceApplyVo.urls)[0]
					}]
				}, 1, 10, (res) => {
					this.$message({
						type: 'error',
						message: res.data.message,
						duration: 2000
					})
				})
			},
			handleClick(tab) { //标签页切换tabs
				if(this.activeName == 3) {}
			},
			anonOrderDetailInfo() { //订单详情接口
				this.loading = true
				axiosApi('/sales/order/web/selectInfo', this.getOrderSn(), (res) => {
					this.loading = false
					this.orderInfoObj = res.data.data;
					this.giftTableList = [{
						orderGiftContent : res.data.data.orderGiftContent,
						subEmployeeName : res.data.data.subEmployeeName,
					}];
					this.customChange({
						traderId: this.orderInfoObj.buyerId
					}) //调用客户地址列表
					this.findSellerBankInfoList({
						traderId: this.orderInfoObj.sellerId
					})

					if(this.orderInfoObj.remainAutoCancleTime != null && this.orderInfoObj.remainAutoCancleTime > 0) {
						let time = parseInt(this.orderInfoObj.remainAutoCancleTime / 1000)
						this.orderInfoObj.remainAutoCancleTime = this.timeString(time)
						this.orderInfoObj.clearTime = null
						this.orderInfoObj.clearTime = setInterval(() => {
							time--
							this.orderInfoObj.remainAutoCancleTime = this.timeString(time)
							if(time <= 0) {
								clearTimeout(this.orderInfoObj.clearTime)
								if(this.orderInfoObj.orderStatus == 1 && this.orderInfoObj.payStatus == 1 && (this.orderInfoObj.editAuditState == null || this.orderInfoObj.editAuditState == 3) && (this.orderInfoObj.lastestRemittanceAuditStatus == null || this.orderInfoObj.lastestRemittanceAuditStatus != 1)) this.orderInfoObj.orderStatus = 0
								this.$set(this.ordersListResVo, index, this.orderInfoObj)
							}
						}, 1000)
					} else {
						if(this.orderInfoObj.orderStatus == 1 && this.orderInfoObj.payStatus == 1 && (this.orderInfoObj.editAuditState == null || this.orderInfoObj.editAuditState == 3) && (this.orderInfoObj.lastestRemittanceAuditStatus == null || this.orderInfoObj.lastestRemittanceAuditStatus != 1)) this.orderInfoObj.orderStatus = 0
						this.$set(this.ordersListResVo, index, this.orderInfoObj)
					}

				}, 1, 10, (res) => {
					this.loading = false
				})
			},
			customChange(body) { //客户地址列表(接口已改)
				axiosApi('/trader/orderDeliveryAddress/select', body, (res) => {
					this.orderDeliveryAddressList = res.data.data
					this.orderDeliveryAddressList.forEach((item, index) => {
						if(this.orderInfoObj.addrId == item.id) { //营业地址
							this.address = item
						}

					})
				}, 1, 10, (res) => {
					this.$message({
						type: 'error',
						message: res.data.message,
						duration: 2000
					})
				})
			},
			handleChangeAddress() { //点击 dialog 确定更新地址
				this.changeAddressVisible = false
				this.orderChange({
					id: this.orderInfoObj.id,
					addrId: this.address.id,
					addrMobile: this.address.tel,
					addrUsername: this.address.linkman,
					addrProvinceId: this.address.provinceId,
					addrCityId: this.address.cityId,
					addr: this.address.attr,
					sellerId:this.orderInfoObj.sellerId
				})
			},

			orderChange(body) { //订单信息修改
				axiosApi('/sales/order/web/update', body, (res) => {
					clearTimeout(this.orderInfoObj.clearTime)
					this.anonOrderDetailInfo()
				}, 1, 10, (res) => {
					this.$message({
						type: 'error',
						message: res.data.message,
						duration: 2000
					})
				})
			},
			getOrderSn() { //获取orderSn
				let body = {}
				this.supplierOrderDetail = JSON.parse(sessionStorage.getItem('supplierOrderDetail'))
				if(this.supplierOrderDetail.type == 'supplierOrderDetail') {
					this.orderSn = this.supplierOrderDetail.orderSn
					body = {
						orderSn: this.supplierOrderDetail.orderSn
					}
				}
				return body
			},
			// shipConfirmDialog(orderSn) {
			// 	const h = this.$createElement
			// 	this.$msgbox({
			// 		title: '提示信息',
			// 		message: h('p', {
			// 			style: 'text-align:center'
			// 		}, '确认该订单已经发货？'),
			// 		showCancelButton: true,
			// 		confirmButtonText: '确定',
			// 		cancelButtonText: '取消',
			// 		beforeClose: (action, instance, done) => {
			// 			if(action === 'confirm') {
			// 				this.doShip({
			// 					orderSn: orderSn,
			// 					sellerId: this.orderInfoObj.sellerId
			// 				})
			// 				done()
			// 			} else {
			// 				done()
			// 			}
			// 			delete instance.$slots.default;
			// 		}
			// 	})
			// },
			doShip(body) {
				this.loading = true
				axiosApi("/sales/orderDelivery/web/confirm", body, (res) => {
					this.loading = false
					this.$message({
						type: 'success',
						message: "发货成功",
						duration: 2000
					})
					this.orderInfoObj.orderStatus = 3
				}, 1, 10, (res) => {
					this.loading = false
					this.$message({
						type: 'error',
						message: res.data.message,
						duration: 2000
					})
				})
            },
			queryDeliveryInfo() {
				const orderSn = JSON.parse(sessionStorage.getItem('supplierOrderDetail')).orderSn
				const body = {
					orderSn: orderSn,
					status: 2
				}
				axiosApi('/order/deliveryItem/selectList', body, (res) => {
					this.detailList = res.data.data || []
				}, 1, 10)
			}
		}
	}
</script>

<style lang="less">
	.color-3F {
		color: #3F3F3F;
	}

	.background-3 {
		background: #333333;
	}

	.billDialog {
		.el-dialog {
			width: 700px;
		}
		.bill-type {
			text-align: center;
			margin-bottom: 52px;
			.el-radio+.el-radio {
				margin-left: 35px;
			}
		}
		.el-form {
			.el-form-item {
				margin-bottom: 30px;
			}
			.el-form-item__label {
				width: 245px;
				padding-right: 30px;
			}
		}
	}

	#cloud-order-detail {
		.editAuditFail {
			.el-dialog {
				min-height: 0;
				height: 300px;
				.el-dialog__body {
					padding-top: 20px;
					.el-form {
						min-width: 0px;
						width: 500px;
						padding: 0 20px;
						box-sizing: border-box;
					}
				}
			}
		}
		.bb {
			border-bottom: 1px solid #DDDDDD;
		}
		.bl {
			border-left: 1px solid #DDDDDD;
		}
		.changePriceDialog {
			.el-dialog {
				width: 1000px;
				.el-table {
					.cell {
						padding: 10px;
					}
					.el-table__empty-block {
						display: none;
					}
				}
			}
			.el-dialog__body {
				.el-form-item__label {
					font-size: 12px;
					width: 215px;
					margin-right: 20px;
				}
				.el-input__inner {
					font-size: 12px;
				}
				.ferght {
					flex-grow: 1;
				}
				.changePriceInfo {
					padding-bottom: 36px;
					border: 1px solid #DDDDDD;
					border-top: none;
					margin-bottom: 80px;
				}
			}
		}
		.lookpaymentDialog {
			.el-dialog {
				.avatar-uploader {
					.el-upload--picture-card {
						display: none;
					}
				}
				.el-icon-view {
					margin-left: 20px;
				}
				.el-icon-delete2 {
					display: none;
				}
				.el-upload-list--picture-card .el-upload-list__item-status-label {
					display: none;
				}
			}
			.showImgDialog {
				.el-dialog {
					z-index: 10000;
				}
				.el-dialog__body {
					width: auto;
					height: auto;
					img {
						width: auto;
						height: auto;
					}
				}
			}
		}
		.paymentVoucherDialog {
			.el-dialog {
				.el-dialog__body {
					margin-bottom: 50px;
				}
				.el-form-item__label {
					width: 160px;
				}
				.el-form-item__error {
					left: 160px;
				}
				.avatar-uploader {
					width: 100px;
					height: 100px;
					.el-upload {
						width: 110px;
						height: 110px;
						.el-icon-plus {
							width: 100px;
							height: 100px;
							line-height: 110px;
							border: 1px solid #DDDDDD;
						}
						img {
							width: 100px;
							height: 100px;
						}
					}
					.el-upload__tip {
						position: relative;
						bottom: 130px;
						line-height: 16px;
						left: 110px;
						width: 140px;
					}
				}
			}
		}
		.noContent {
			padding-bottom: 257px;
			width: 940px;
			margin: 0 auto;
			p {
				text-align: center;
				margin-top: 50px;
			}
		}
		.changeAddress {
			.el-radio {
				white-space: inherit;
			}
			.el-dialog {
				width: 500px;
			}
		}
		.remarkDialog {
			.el-dialog {
				min-height: 300px;
			}
		}
		.el-button--text {
			width: auto;
		}
		.common {
			width: 940px;
			margin: 0 auto;
		}
		.textrea {
			width: 940px;
			display: block;
			margin-left: 125px;
			.el-textarea__inner {
				border-radius: 0;
			}
		}
		.remark-btn {
			margin-left: 125px;
			margin-bottom: 120px;
		}
		.store-confirm-order {
			margin: 0 auto;
			padding: 0 45px;
			box-sizing: border-box;
		}
		.order-info {
			margin: 0 auto;
			margin-top: 30px;
			width: 940px;
			border: 1px solid #DDDDDD;
			box-sizing: border-box;
			.title {
				background-color: #EEEEEE;
				height: 40px;
				line-height: 40px;
				padding-left: 20px;
			}
			.el-form-item__label {
				padding: 0;
				line-height: 14px;
				height: 14px;
				padding-right: 20px;
				width: 145px;
			}
			.el-form-item__content {
				line-height: 14px;
			}
			.el-form {
				min-width: 600px;
				margin-top: 30px;
			}
		}
		.tabs {
			margin: 0 auto;
			width: 940px;
			margin-bottom: 30px;
			.el-tabs__header {
				padding-left: 0;
				border-bottom: 1px solid #DDDDDD;
			}
		}
		.order-detail {
			width: 940px;
			margin: 0 auto;
			.order-content {
				.order-item-item-3 {
					border-right: 1px solid #DDDDDD;
					padding-right: 30px;
					width: 50px;
				}
				.order-item-item-4 {
					border-right: 1px solid #DDDDDD;
					width: 150px;
					text-align: center;
				}
				.order-item-item-5 {
					text-align: center;
					flex-grow: 1;
				}
				.message {
					.el-input {
						width: 500px;
					}
				}
				img {
					width: 80px;
					height: 80px;
				}
				.item-content {
					border-top: 1px solid #DDD;
					width: 940px;
				}
				.item-item {
					border: 1px solid #DDD;
					border-top: none;
					box-sizing: border-box;
					display: flex;
					.el-input {
						width: 90px;
					}
				}
				.order-item-item-2 {
					margin-right: 60px;
					width: 100px;
				}
			}
			.total {
				width: 940px;
				padding: 30px 60px 30px 0;
				border: 1px solid #DDDDDD;
				border-top: none;
				box-sizing: border-box;
				.payment {
					color: #D24C4C;
				}
				span {
					display: inline-block;
					width: 150px;
					text-align: left;
				}
				ul li {
					margin-bottom: 5px;
				}
			}
		}
		.el-table {
			.cell {
				padding: 10px 30px;
			}
		}
	}

	.mr-45 {
		margin-right: 45px;
	}
</style>
