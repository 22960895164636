<template>
    <div class='applicationInfo'>
        <div v-for="(item,index) in applicationMenuTreeList" :key="index" v-if="item.applicationMenuTree.length > 0">
            <div class="title">{{item.menuClassify == 1 ? '关联业务' : '其他业务'}}</div>
            <div class="content">
                <div class="contentItem" v-for="(nav,i) in item.applicationMenuTree" :key="i" @click="changeNavMenu(nav)">
                    <div class="imgStyle">
                        <i class="fr iconfont" :class="nav.icon"></i>
                    </div>
                    <div class="contentName">
                        <div class="nameTop">{{nav.text}}</div>
                        <div class="nameBottom">{{nav.desc}}</div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>


<script>
    import { mapState , mapMutations} from 'vuex'
    import { basePath } from '../../api/api'
    export default {
        data () {
            return {
                relatedBusiness: [{
                    title: '集采管理',
                    info: '这里写产品介绍说明',
                    img: require('../../assets/application/jicai.png')
                },{
                    title: '城市拍档管理',
                    info: '这里写产品地方大师傅公司豆腐干山豆根山豆根山豆根介绍说明',
                    img: require('../../assets/application/city.png')
                },{
                    title: '超级B管理',
                    info: '这里写产品介绍说明',
                    img: require('../../assets/application/superB.png')
                },{
                    title: '车销管理',
                    info: '这里写产品介绍说明',
                    img: require('../../assets/application/car.png')
                },{
                    title: '云销管理',
                    info: '这里写产品介绍说明',
                    img: require('../../assets/application/cloud.png')
                }],
                otherBusiness:[{
                    title: '超团管理',
                    info: '这里写产品介绍说明',
                    img: require('../../assets/application/buy.png')
                },{
                    title: '社会久集管理',
                    info: '这里写产品介绍说明',
                    img: require('../../assets/application/social.png')
                },{
                    title: '门店久集管理',
                    info: '这里写产品介绍说明',
                    img: require('../../assets/application/store.png')
                },{
                    title: '酒店久集管理',
                    info: '这里写产品介绍说明',
                    img: require('../../assets/application/hotel.png')
                },{
                    title: '点餐系统管理',
                    info: '这里写产品介绍说明',
                    img: require('../../assets/application/food.png')
                },]
            };
        },

        computed:{
            ...mapState('nav',['asideNav','applicationMenuTreeList'])
        },

        mounted(){

        },

        methods: {
            ...mapMutations('nav',['changeAsideNav']),
            changeNavMenu(val){
                console.log(val,'84')
                if (val.url) {
                    this.$router.push({
                        path: val.url
                    })
                }
                this.changeAsideNav(val)
            }
        }
    }

</script>
<style lang='less'>
    .applicationInfo {
        width: 100%;
        .title {
            padding: 10px;
            font-size: 16px;
            font-weight: bold;
            margin-bottom: 20px;
        }
        .content {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            padding: 10px;
            .contentItem {
                width: 250px;
                display: flex;
                cursor: pointer;
                align-items: center;
                background: white;
                border-radius: 5px;
                padding: 25px;
                margin: 0 10px 20px;
                .imgStyle {
                    color: #FA5D47;
                    width: 50px;
                    height: 50px;
                    line-height: 50px;
                    text-align: center;
                    background: #FFF3E7;
                    i {
                        width: 50px;
                        font-size: 32px;
                    }
                }
                .contentName {
                    margin-left: 10px;
                    .nameTop {
                        font-size: 14px;
                        font-weight: bold;
                    }
                    .nameBottom {
                        font-size: 12px;
                        margin-top: 10px;
                        color: #9e9285;
                        width: 183px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
            }
        }
        .content::after {
            content: "";
            width: 250px;
            margin: 10px;
        }
    }
</style>