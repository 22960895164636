<template>
  <!--久集仓库-->
  <div id="inventoryDetail">
    <div class="title-top bg-f clearfix">库存明细</div>
    <div class="content bg-f pr-20 pl-20 mt-5 pt-20">
      <div class="pb-20">
        <el-table :data="tableData">
          <el-table-column label="单据类型" width="70">
            <template slot-scope="scope">
              <div v-if="scope.row.stockType == 'stockOut'">
                  <span v-if="scope.row.stockOutType=='other'">其他出库</span>
                  <span v-if="scope.row.stockOutType=='orders'">销售出库</span>
                  <span v-if="scope.row.stockOutType=='ordersLock'">订单锁库</span>
                  <span v-if="scope.row.stockOutType=='exchange'">换货出库</span>
              </div>
             <div v-else>
                <span v-if="scope.row.stockInType=='other'">其他入库</span>
                <span v-if="scope.row.stockInType=='purchase'">采购入库</span>
                <span v-if="scope.row.stockInType=='returned'">退货入库</span>
                <span v-if="scope.row.stockInType=='exchange'">退换入库</span>
             </div>
              <!-- <span v-if="scope.row.stockType == 'stockOut'">{{scope.row.stockOutType == 'orders' ? '销售出库' : '其他出库'}}</span>
              <span v-else>{{scope.row.stockInType == 'purchase' ? '采购入库' : '其他入库'}}</span> -->
            </template>
          </el-table-column>
          <el-table-column label="单据编号">
            <template slot-scope="scope">
              <span>{{scope.row.stockOrderNo}}</span>
            </template>
          </el-table-column>
          <el-table-column label="仓库名称" width="80">
            <template slot-scope="scope">
              <span>{{scope.row.warehouseName}}</span>
            </template>
          </el-table-column>
          <el-table-column label="基本单位" width="60">
            <template slot-scope="scope">
              <span>{{scope.row.baseUnitName}}</span>
            </template>
          </el-table-column>
          <el-table-column label="数量" width="60">
            <template slot-scope="scope">
              <span>{{scope.row.stockType == 'stockIn' ? '+' : '-'}}{{scope.row.amount}}</span>
            </template>
          </el-table-column>
          <el-table-column label="库存余量" width="80">
            <template slot-scope="scope">
              <span>{{scope.row.availableQuantity}}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作人" width="70">
            <template slot-scope="scope">
              <span>{{scope.row.employeeName?scope.row.employeeName:'--'}}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作时间" width="130">
            <template slot-scope="scope">
              <span>{{scope.row.gmtCreate | time}}</span>
            </template>
          </el-table-column>
          <el-table-column label="备注" align="left" show-overflow-tooltip>
            <template slot-scope="scope">
                <p>{{scope.row.remark}}</p>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          class="tar"
          v-if="tableData.length>0"
          :current-page="currentPage"
          :page-size="10"
          :total="total"
          layout="prev , pager, next, jumper"
          @current-change="handleCurrentChange"
        >
          <!-- 分页组件 -->
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { axiosApi, basePath } from "../../api/api";
export default {
  data() {
    return {
      tableData: [], //商品数据
      classList: [], //分类数据
      currentPage: 1,
      total: null //页面分页总数
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.achive(); //默认加载商品列表
    });
  },
  methods: {
    achive(val) {
      //获取已添加商品列表
      if (val) {
        //重置为1
        this.currentPage = 1;
      }
      const api = "/warehouse/skuinfo/deliveryVoucher/list";
      const body = {
        skuNo: this.$route.query.skuNo,
        warehouseId: this.$route.query.warehouseId
      };
      axiosApi(
        api,
        body,
        res => {
          this.tableData = res.data.data.elements;
          this.total = res.data.data.totalCount;
        },
        this.currentPage,
        10,
        res => {
          this.$message({
            type: "error",
            message: res.data.message,
            duration: 3000
          });
        }
      );
    },
    handleCurrentChange(val) {
      //分页查询
      this.currentPage = val;
      this.achive();
    }
  }
};
</script>

<style lang="less">
  .el-tooltip__popper{max-width:50%}
#inventoryDetail {
  .content {
    .mtI-50 {
      margin-top: 82px !important;
    }
    .el-form--inline {
      width: 650px;
      .el-form-item__label {
        font-size: 12px;
        width: 60px;
      }
      .order-datatimerange {
        width: 240px;
      }
    }
    .el-table__body-wrapper {
      margin-top: 1px !important;
    }
    .el-button + .el-button {
      margin-left: 0;
    }
    .el-table .cell {
      text-align: center;
      padding: 10px 0;
      img {
        width: 60px;
        height: 60px;
        margin-left: 10px;
        margin-right: 10px;
      }
      .width-130 {
        width: 130px;
        text-align: left;
      }
      .el-button {
        height: 20px;
      }
    }
    .el-form-item__label {
      width: 100px;
    }
  }
}
</style>
