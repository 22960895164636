<template>
    <div id="classify" class="">
        <div class="title-top bg-f pl-20">
			      分类管理
            <el-button v-if='showButton("ADD",200028)' class="fr mr-30 mt-14" @click="addClassify">新增分类</el-button>
        </div>
        <div class='bg-f mt-5 pb-50 pt-30 pl-30 pr-30'>
            <el-table :data="tableData" stripe tooltip-effect="dark" class="table-default" v-loading="Loading">
                <el-table-column label="序号" >
                    <template slot-scope="scope">
						<div>{{scope.$index * currentPage + 1}}</div>
					</template>
                </el-table-column>
                <el-table-column label="分类名" >
                    <template slot-scope="scope">
						<div>{{scope.row.classifyName}}</div>
					</template>
                </el-table-column>
                <el-table-column label="操作" >
                    <template slot-scope="scope">
                        <div v-if='showButton("EDIT",200028)||showButton("DEL",200028)'>
                            <el-button v-if='showButton("EDIT",200028)' @click.native.prevent="edit(scope.$index, tableData)" type="text" size="small" >
                                编辑
                            </el-button>
                            <el-button v-if='showButton("DEL",200028)' @click.native.prevent="deleteRow(scope.$index, tableData)" type="text" size="small">
                                删除
                            </el-button>
                        </div>
                        <div v-else>-</div>
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>

<script>
import { axiosApi, basePath } from "../../api/api"
import Confirm from '../../utils/formconfirm'

export default {
    data() {
        return {
            tableData: [],
            currentPage: 1,
            Loading: false,
            nowclass:null
        }
    },
    methods: {
        addClassify(){
            if(this.tableData.length < 5) {
                this.newclass()
            } else {
                this.$notify({
                    type: 'warning',
                    message: '最多配置5个文章分类'
                })
            }
        },
        newclass(vvv) {//新建&&编辑
            var that = this
            if(vvv){
                this.nowclass = vvv.classifyName;
            }
            let newconfirm = new Confirm()
            if(vvv){//编辑
                 this.$prompt('请输入分类名', '编辑分类', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    inputPattern: /^[\u4e00-\u9fa5]{1,8}$/,
                    inputErrorMessage: '分类名应为长度不超过8的中文字符串',
                    inputValue:vvv ? vvv.classifyName : ''
                }).then((val)=>{
                    let confirmdata = val.value;
                    if(val.value==''){
                        newconfirm.use([confirmdata], '分类名不得为空')
                    }
                    this.tableData.forEach((a)=>{
                        if(a.classifyName == val.value && that.nowclass !== val.value) {
                            newconfirm.status = false
                            this.$notify({
                                type: 'warning',
                                message: '不可编辑重复分类名'
                            })
                        }
                    })
                    if(newconfirm.status){
                        axiosApi('/sales/sunflowerclassify/app/update',{
                            id:vvv.id,
                            sellerId:sessionStorage.getItem('traderId'),
                            classifyName:val.value,
                            isDisable:0
                        },res=>{
                            that.querydata(1)
                        })
                    }
                    
                }).catch(()=>{})
            } else {
                 this.$prompt('请输入分类名', '新增分类', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    inputPattern: /^[\u4e00-\u9fa5]{1,8}$/,
                    inputErrorMessage: '分类名应为长度不超过8的中文字符串',
                    inputValue:vvv ? vvv.classifyName : ''
                }).then((val)=>{
                    let confirmdata = val.value;

                    if(val.value==''){
                        newconfirm.use([confirmdata], '分类名不得为空')
                    }
                    this.tableData.forEach((a)=>{
                        if(a.classifyName == val.value) {
                            newconfirm.status = false
                            this.$notify({
                                type: 'warning',
                                message: '不可新增重复分类名'
                            })
                        }
                    })
                    if(newconfirm.status){
                        axiosApi('/sales/sunflowerclassify/app/insert', {
                            sellerId: sessionStorage.getItem('traderId'),
                            classifyName:val.value
                        },(res)=>{
                            that.$notify({
                                type: 'success',
                                message: '新增文章分类成功'
                            })
                            that.querydata(1)
                        },undefined,undefined,(res)=>{
                            that.$notify({
                                type: 'warning',
                                message: res.data.message
                            })
                        })
                    }
                }).catch(()=>{})
            }
        },
        querydata(x) {
            this.Loading = true
            axiosApi('/sales/sunflowerclassify/web/selectList', {}, (res)=>{
                this.tableData = res.data.data ? res.data.data : []
                this.Loading = false
            },x?x:this.currentPage,10)
        },
        edit(aa,bb){
            this.newclass(bb[aa])
        },
        deleteRow(aa,bb){
            this.$confirm('确定删除该分类？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
               axiosApi('/sales/sunflowerclassify/app/update',{
                    id:bb[aa].id,
                    sellerId:sessionStorage.getItem('traderId'),
                    classifyName:bb[aa].classifyName,
                    isDisable:1
                },res=>{
                    this.querydata(1)
                    this.$notify({
                        type: 'success',
                        message: '删除成功!'
                    });
                })
                
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });          
            });
            
        }
    },
    mounted() {
        this.$nextTick(()=>{
            this.querydata(1)
        })
    }
}
</script>

<style lang="less" >
.el-message-box__input {
    .el-input {
        width: 100% !important;
        input {
            width: 100%;
        }
    }
}
#classify{
    padding-left: 15px;
    padding-bottom: 50px;
    .border{
        border: 1px solid #EEEEEE;
        background-color:  #EEEEEE;
    }
    .el-input {
        width: 100% !important;
    }
}
</style>