<template>
	<div id="salesManage" class="pb-50">
		<!-- 图片预览 -->
		<imgview :visible="imgsvisible" :imgs="showimgs" :startindex="startindex" @close="imgsvisible=false"></imgview>
		<div class="title-tabs bg-f black">
			<ul class="clearfix">
				<template v-for='(tab,index) in dataTabs' class="item" >
					<li :type='index' :class="type==index?'active':''" @click='handleTabs' :key="index+'ccb'">
						{{tab}}
						<div :class="type==index?'under-line':''"></div>
					</li>
					<div class="vertical-line" :key="index+'cca'"></div>
				</template>
			</ul>
		</div>
		<div class="bg-f mt-5 pb-50 pl-30 pr-30">
			<el-form :inline="true" :model="form" label-width="90px" class="clearfix pt-30 search">
				<div class="fl width-80p">
					<el-form-item label='加入时间'>
						<ys-daterange-picker v-model="Form.orderDateRange" :endDisabledDate="new Date()" />
					</el-form-item>
					<el-form-item label="销售员账号">
						<el-input v-model.trim="Form.loginName"></el-input>
					</el-form-item>
					<el-form-item label="销售员名">
						<el-input v-model.trim="Form.storeName" :maxlength="20"></el-input>
					</el-form-item>
				</div>
				<el-button type="info" class="fl ml-50" @click='achieve(0)'>搜索</el-button>
			</el-form>
			<div class="clearfix mr-30">
				<div class="fr">
					<el-button type="text" v-if="showButton('EXPORT', 130002)" @click="exportOrder">批量导出</el-button>
					<el-button v-if="showButton('ADD', 130002)" type="danger" class="mb-5" @click="openNew">新增销售员</el-button>
				</div>
			</div>
			<el-table :data="tableData" v-loading="Loading" class='mt-10 table-default' stripe>
				<el-table-column label="销售员账号" width="90">
					<template slot-scope="scope">
						<div>{{scope.row.loginName}}</div>
					</template>
				</el-table-column>
				<el-table-column label="销售员名" width="90">
					<template slot-scope="scope">
						<div>{{scope.row.storeName}}</div>
					</template>
				</el-table-column>
				<el-table-column label="身份证" width="135">
					<template slot-scope="scope">
						<div>{{scope.row.name}}</div>
						<div>{{scope.row.idCard}}</div>
					</template>
				</el-table-column>
				<el-table-column label="身份证照片" width="75">
					<template slot-scope="scope">
						<div v-if="scope.row.idCardImg && scope.row.idCardImg.length" class="color-blue cursor" @click="lookIdCardImg(scope)">查看</div>
					</template>
				</el-table-column>
				<el-table-column label="交易额(元)" width="90">
					<template slot-scope="scope">
						<div>￥{{scope.row.orderAllMoney||0 | fmoney }}</div>
					</template>

				</el-table-column>
				<el-table-column label="订单(笔)" width="60">
					<template slot-scope="scope">
						<!--<div :class="{'color-blue cursor' : 'scope.row.consumptionTimes != 0'}" @click="goOrder(scope)">{{scope.row.consumptionTimes}}</div>-->
						<div v-if='scope.row.consumptionTimes > 0' class='cursor color-blue' @click="goOrder(scope)">{{scope.row.consumptionTimes}}</div>
						<div v-else>{{scope.row.consumptionTimes}}</div>
					</template>
				</el-table-column>
				<el-table-column label="销售积分" width="75">
					<template slot-scope="scope">
						<div>{{scope.row.commission}}</div>
					</template>
				</el-table-column>
				<el-table-column label="加入时间" width="130">
					<template slot-scope="scope">
						<div>{{scope.row.switchTime | time}}</div>
					</template>
				</el-table-column>
				<el-table-column label="状态" width="60">
					<template slot-scope="scope">
						<div v-if='scope.row.state == "-1"' :style="{color : '#ff4201'}">已删除</div>
						<div v-if='scope.row.state == "1"'>待复审</div>
						<div v-if='scope.row.state == "2"'>正常</div>
						<div v-if='scope.row.state == "3"'>审核失败</div>
						<div v-if='scope.row.state == "4"'>已禁用</div>
						<div v-if='scope.row.state == "5"'>待初审</div>
					</template>
				</el-table-column>
				<el-table-column label="保证金" width="" v-if="hotelType == 1">
					<template slot-scope="scope" >
						<div>￥{{scope.row.bondMoney | fmoney}}</div>
					</template>
				</el-table-column>
				<el-table-column label="操作">
					<template slot-scope="scope">
						<el-button @click='Review(scope,1)' class='ml-0 tal' size="small" type='text'>
							<span v-if="showButton('AUDIT_ONE', 130002) && scope.row.state == '5'">初审</span>
						</el-button>
						<el-button @click='Review(scope,2)' class='ml-0 tal' size="small" type='text'>
							<span v-if="showButton('AUDIT_TWO', 130002) && scope.row.state == '1'">复审</span>
						</el-button>
						<el-button @click='isForbbident(scope)' class='ml-05 tal' size="small" type='text'>
							<span v-if="scope.row.state == '2' && showButton('DISABLE', 130002)">禁用</span>
							<span v-if="scope.row.state == '4' && showButton('ENABLE', 130002)">启用</span>
						</el-button>
						<span v-if="scope.row.state == -1">
							<el-button @click="handleOperator(scope.row)" class='ml-05 tal' size="small" type="text">查看</el-button>
							   <el-button @click="handleWithdraw(scope.row)" class='ml-0 tal' size="small" type="text">撤回</el-button>
						   </span>
						   <span v-if="scope.row.state != -1">
							   <el-button @click="handleDelete(scope.row)" class='ml-0 tal' size="small" type="text">删除</el-button>
						   </span>
						<el-button v-if="showButton('CREDIT', 130002) && scope.row.isOpenHotelSuperc == 1" @click='showAccreditBtn(scope.row)' class='ml-0 tal' size="small" type='text'>授信管理</el-button>
					</template>
				</el-table-column>
			</el-table>
			<!-- 导出 -->
			<form ref="downClientForm" action='/sunrise-gateway/export/superc/hotelStore' style="display: none">
				<input type="hidden" name="modelName" v-model="a">
				<input type="hidden" name="fileName" v-model="b">
				<input type="hidden" name="startRow" v-model="c">
				<input type="hidden" name="state" v-model="Form.state">
				<input type="hidden" name="loginName" v-model="Form.loginName">
				<input type="hidden" name="storeName" v-model="Form.storeName">
			</form>
			<!-- 新增销售员 -->
			<el-dialog title="新增销售员" :visible.sync="addManage" :close-on-click-modal="false">
				<el-form size="large" class='ml-30' :model="form" label-width="110px" :rules="rules" ref="form">
					<el-form-item label="账号:" prop="account">
						<el-input class="inputTitle" v-model.trim="form.account" auto-complete="off" placeholder="请输入手机号" :maxlength="21"></el-input>
					</el-form-item>
					<el-form-item label="姓名:" prop="storeName">
						<el-input class="inputTitle" v-model.trim="form.storeName" auto-complete="off" placeholder="请输入姓名" :maxlength="6"></el-input>
					</el-form-item>
					<el-form-item label="身份证号:" prop="IDcard">
						<el-input class="inputTitle" v-model.trim="form.IDcard" auto-complete="off" placeholder="请输入身份证号码" :maxlength="18"></el-input>
					</el-form-item>
					<el-form-item label="身份证照片:" prop="image">
						<div class="img" style="height: 90px;">
							<el-upload :class="isAd?'dis':''" action="/sunrise-gateway/oss/ossUpload" list-type="picture-card" :before-upload="beforeAvatarUpload" :on-success="handleAvatarSuccessList" :on-preview="handlePictureCardPreview" :file-list="form.image" :on-remove="handleRemove">
								<i class="el-icon-plus"></i>
							</el-upload>
						</div>
					<span>(请上传身份证正面和反面)</span>
					</el-form-item>
				</el-form>
				<div slot="footer" class="dialog-footer footer mt-30">
					<el-button type="danger" @click="addShop('form')">确 定</el-button>
				</div>
			</el-dialog>
			<!--上传图片-->
			<el-dialog :visible.sync="dialogVisible" size="tiny" @click="dialogVisible=false">
				<img width="100%" :src="image" alt="">
			</el-dialog>
			<!-- 身份证照片 -->

			<el-dialog title="身份证照片" class="imgContainer" :visible.sync="idCardImg" @close="idCardImg=false">
				<div>
					<div class="imgWrap" v-if="CardImg.length!=0">
						<img v-for="(item,index) in CardImg" :src="item" :key="index+'ccc'" @click="imgview(CardImg,index)">
					</div>
					<div class="nocon" v-else>暂无数据</div>
				</div>
			</el-dialog>
			<!-- 授信管理弹窗 -->
			<el-dialog title="授信管理" class="accredit-dialog" :visible="showAccredit" @close="showAccredit=false">
				<div>
					<el-form :model="accreditForm" :rules="accreditRules" ref="accredit">
						<el-form-item label="授信额度:" prop="creditMoney">
							<el-input v-model="accreditForm.creditMoney"></el-input>
						</el-form-item >
					</el-form>
				</div>
				<div class="accredit-center">
					<div>已用额度：{{useCreditMoney}}</div>
					<div>可用额度：
						<span v-if="Number(accreditForm.creditMoney) || Number(accreditForm.creditMoney) == 0">
							<span v-if="String( Number(accreditForm.creditMoney) - Number(useCreditMoney) ).indexOf('.') > -1">
								{{ ( Number(accreditForm.creditMoney) - Number(useCreditMoney) ).toFixed(2)}}
							</span>
							<span v-else>{{ ( Number(accreditForm.creditMoney) - Number(useCreditMoney) )}}</span>
						</span>
					</div>
				</div>
				<div slot='footer' class="dialog-footer">
					<el-button @click="lookAccreditDetail">查看授信明细</el-button>
					<el-button type='danger' @click="confirmAccredit">修改额度</el-button>
				</div>
			</el-dialog>
			<!-- 分页组件 -->
			<el-pagination class="tar mr-30" v-if="total>0" :current-page="currentPage" :page-size='10' :total='total' layout="prev , pager, next, jumper" @current-change="handleCurrentChange">
			</el-pagination>
			<!-- 审核 -->
			<el-dialog :title="reviewTit" class="review-dialog" :visible.sync="showReview" @close="showReview=false">
				<div class="content">
					<el-radio-group v-model="checkRadio">
						<el-radio :label="1">通过</el-radio>
						<el-radio :label="2">驳回</el-radio>
					</el-radio-group>
					<el-input v-if="checkRadio==2" class="mt-20" placeholder="请输入驳回原因" type="textarea" :maxlength="100" :autosize="{ minRows: 2, maxRows: 4}" v-model.trim="reason"></el-input>
				</div>
				<div slot='footer' class="dialog-footer">
					<el-button type='danger' @click="saveReview">确定</el-button>
					<el-button @click="showReview=false">取消</el-button>
				</div>
			</el-dialog>
		</div>
		<!-- 删除 查看操作人 -->
		<div class="operator-box">
			<el-dialog title="查看" :visible.sync="dialogStateOperator" @close="dialogStateOperator=false">
				<el-table :data="listOperator">
					<el-table-column property="deleteEmployeeName" label="操作人"></el-table-column>
					<el-table-column property="deleteTime" label="操作时间">
						<template slot-scope="scope">
							{{scope.row.deleteTime | time}}
						</template>
					</el-table-column>
				</el-table>
			</el-dialog>
		</div>
	</div>
</template>

<script>
	import { axiosApi, basePath } from "../../../api/api";
	import md5 from "md5"
	// import YsDaterangePicker from "~/components/ys-daterange-picker";
	export default {
		// components: {YsDaterangePicker},
		data() {
			var imgDouble = (rule, value, callback) => {
				if((this.form.image.length == 1)) {
					callback(new Error('请上传正反2张照片'));
				} else {
					callback();
				}
			}
			var validateCreditMoney = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入授信额度!'));
        } else if (Number(this.accreditForm.creditMoney) < Number(this.useCreditMoney) ) {
          callback(new Error('可用额度不能小于0!'));
        } else if(/^([1-9][\d]{0,7}|0)(\.[\d]{1,2})?$/.test(value) == false){
          callback(new Error('请输入整数部分最大为8位的正整数或者两位小数!'));
        } else {
					callback()
				}
      };
			return {
				hotelType:null,//酒店类型
				showReview: false ,//审核弹窗
				reviewTit: null,//弹窗标题
				checkRadio: 1,//通过为1 驳回为2
				reason: '',//驳回原因
				salerId: null,//id
				stateReview: null,//状态
				a: 'empty',
				b: '销售员管理.xls',
				c:'0',

				basePath: basePath,
				dataTabs: ["全部", "正常", "禁用","已删除"],
				type: '0',
				Loading: false,
				activeTab: '1',
				tableData: [], //商品列表
				searchClassify: [], //搜索分类
				//分页
				currentPage: 1,
				total: 0,
				isPagination: true,
				auditStatus: null,
				form: {
					companyName: null,
					storeName: null,
					account: null,
					IDcard: null,
					storeName: null,
					image: [],
				},
				Form: {
					orderDateRange: [],
					loginName: null,
					storeName: null,
					state: null,
					startDate:null,
					endDate:null
				},
				addManage: false, //新增销售员弹框，
				isAd: false,
				image: "",
				dialogVisible: false, //上传图片预览照片按钮
				idCardImg: false, //查看按钮
				imgsvisible: false, //身份证单个图片查看
				showimgs: [],
				startindex: 0,
				CardImg: [

				],
				rules: {
					account: [{
						required: true,
						pattern: /^1\d{10}$/,
						message: '请输入正确的11位手机号'
					}, {
						max: 50,
						message: '请输入50个以内的字符'
					}],
					image: [{
						// required: true,
						type: "array",
						message: '请上传图片',
					},{
						validator: imgDouble
					}],
					storeName: [{
						required: true,
						pattern: /^[a-zA-Z\u4e00-\u9fa5]{2,6}$/,
						message: '请输入2—6字（汉字，字母）'
					}],
					IDcard: [{
						// required: true,
						pattern: /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/,
						message: '请输入正确的身份证号'
					}],
				},

				// 授信管理
				clickedItem: null,
				showAccredit: false,
				accreditForm: {
					creditMoney: 0,
				},
				useCreditMoney: 0,
				accreditRules: {
					creditMoney: [
						{ validator: validateCreditMoney, trigger: 'blur' }
					]
				},
				dialogStateOperator : false,
				listOperator : [],
			}
		},
		methods: {
			//获取酒店类型
			getHotelType(){
									axiosApi('/trader/seller/selectOne', {
						traderId: sessionStorage.traderId,
					}, (res) => {
						this.hotelType = res.data.data.isOpenHotelSuperc

					}, this.currentPage, 10, (res) => {
						this.$message({
							type: 'info',
							message: res.data.message,
							duration: 3000
						});
					});
			},
			//批量导出
			exportOrder() {
				if(this.tableData.length > 0) {
					this.$refs.downClientForm.submit();
				} else {

					this.$message({
						type: 'info',
						message: '暂无数据导出',
						duration: 3000
					});
				}

			},
			handleTabs(e) {
				if(e.target.attributes.type) this.type = e.target.attributes.type.value
				this.Form = {
					orderDateRange: [],
					loginName: null,
					storeName: null,
					state: null,
					startDate:null,
					endDate:null
				},
				this.currentPage = 1
				if(this.type == '0') {
					this.Form.state = ''
					this.achieve()
				}
				if(this.type == '1') {
					this.Form.state = '2'
					this.achieve()
				}
				if(this.type == '2') {
					this.Form.state = '4'
					this.achieve()
				}
				if(this.type == '3') {//已删除
					this.Form.state = '-1'
					this.achieve()
				}

			},
			//酒店店铺分页查询
			achieve(payload) {
				if(payload == 0&&this.currentPage != 1) {
					this.currentPage = 1;
					return
				}
				this.Form.startDate = this.Form.orderDateRange[0] ?this.Form.orderDateRange[0].getTime() : null;
				this.Form.endDate = this.Form.orderDateRange[1] ? this.Form.orderDateRange[1].getTime() : null;
				this.Loading = true
				axiosApi('/superc/hotelStore/selectPage', {
					state: this.Form.state,
					loginName: this.Form.loginName,
					storeName: this.Form.storeName,
					startDate:this.Form.startDate,
					endDate:this.Form.endDate,
				}, (res) => {
					this.tableData = res.data.data.elements ? res.data.data.elements : []
					this.total = res.data.data.totalCount
					this.Loading = false
				}, this.currentPage, 10, (res) => {
					this.Loading = false
				})
			},
			goOrder(scope){
				if (sessionStorage.getItem('isAdmin') == 1 || this.showButton('CHECK_ORDERS',130002)) {
					if(scope.row.consumptionTimes > 0){
            if (scope.row.state == "2") {
              this.$router.push({
                path: basePath + "/supercHotelOrder",
                query:{
                  supercStoreId: scope.row.id,
                  type: 0
                }
              })
            } else {
              this.$router.push({
                path: basePath + "/supercHotelOrder",
                query:{
                  supercStoreId: scope.row.id,
                  nm: scope.row.storeName,
                  type: 0
                }
              })
            }
					}
				}
			},
			openNew(){
				this.isAd = false
				this.form = {
					companyName: null,
					storeName: null,
					account: null,
					IDcard: null,
					storeName: null,
					image: [],
				}
				if(this.$refs.form !== undefined) {
					this.$refs.form.resetFields();
				}
				this.addManage = true
			},
			lookIdCardImg(scope) {
				if (scope.row.idCardImg && scope.row.idCardImg.length) {
					this.CardImg = scope.row.idCardImg.map(item => {
						if (item.indexOf('url') > -1 ) {
							return JSON.parse(item).url
						} else {
							return item
						}
					})
					setTimeout(()=>{
						this.idCardImg = true;
					},0)
				}
			},
			imgview(imgs, index) {
				this.showimgs = imgs
				this.startindex = index
				this.imgsvisible = true
			},
			handleRemove(file, filelist) { //照片删除
				this.form.image = []
				this.form.image = filelist;
				this.loading = false
				if(this.form.image.length > 1) {
					this.isAd = true
				} else {
					this.isAd = false
				}
			},
			handlePictureCardPreview(file) { //照片预览
				this.image = file.url;
				this.dialogVisible = true;
			},
			beforeAvatarUpload(file) { //上传之前图片的判断
				const isImage = (file.type.indexOf('jpg') != -1 || file.type.indexOf('jpeg') != -1 || file.type.indexOf('png') != -1 || file.type.indexOf('bmp') != -1);
				const isLt4M = file.size / 1024 / 1024 < 4 && file.size / 1024 / 1024 > 0;
				if(!isImage) {
					this.$message.error('上传图片只能是jpg,jpeg,png,bmp格式');
				}
				if(!isLt4M) {
					this.$message.error('上传图片大小不能大于 4MB或小于 0MB!');
				}
				return isImage && isLt4M;
			},
			handleAvatarSuccessList(file) { //成功上传照片
				this.form.image.push({
					url: file.data[0]
				})
				if(this.form.image.length > 1) {
					this.isAd = true
				} else {
					this.isAd = false
				}

			},
			addShop(form) { //增加销售员
				this.$refs[form].validate((valid) => {
					if(valid) {
						let imgList = []
						this.form.image.map(item => {
							imgList.push(item.url)
						})
						axiosApi('/superc/hotelStore/insert', {
							loginName: this.form.account,
							storeName: this.form.storeName,
							idCard: this.form.IDcard,
							idCardImg: imgList
						}, (res) => {
							this.achieve()
							this.$message({
								type: 'success',
								message: '新增成功',
								duration: 3000
							});
							this.addManage = false
						}, 1, 10, (res) => {
							this.$message({
								type: 'info',
								message: res.data.message,
								duration: 3000
							});
						})
					} else {

					}
				});
			},
			Review(scope, time){//审核按钮
			    this.reason = ''
				this.stateReview = scope.row.state
				this.salerId = scope.row.id
				if(scope.row.state==5){
					this.reviewTit = '初审'
				}else if(scope.row.state==1){
					this.reviewTit = '复审'
				}
				this.showReview = true
			},
			saveReview(){//保存审核状态
				const api ='/superc/supercStore/updateState'
				let state = null
				if(this.checkRadio==2){ //驳回
					state=3
				} else {
					if(this.stateReview==5){//初审
						state = 1
					}else if(this.stateReview==1){//复审
						state=2
					}
				}
				axiosApi(api, {
					id: this.salerId,
					reason: this.reason,
					state: state
				}, (res) => {
					this.achieve()
					this.showReview = false
					let msg = ''
					state == 3 && (msg = '驳回成功！')
					state == 1 && (msg = '初审成功！')
					state == 2 && (msg = '复审成功！')
					this.$message({
						type: 'success',
						message: msg,
						duration: 1000
					});
					that.achieve();
				}, 1, 10, (res) => {
					this.showReview = false
					this.$message({
						type: 'info',
						message: res.data.message,
						duration: 3000
					});
				});
			},
			//禁用，启用
			isForbbident(val) {
				let title = val.row.state == '2' ? '禁用' : '启用'
				let that = this;
				this.$confirm('您是否要' + title + '该销售员？', '提示信息', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					const api = "/superc/supercStore/updateState";
					axiosApi(api, {
						id: val.row.id,
						state: val.row.state == '2' ? '4' : '2'
					}, (res) => {
						this.$message({
							type: 'success',
							message: '操作成功!',
							duration: 1000
						});
						that.achieve();
					}, this.currentPage, 10, (res) => {
						this.$message({
							type: 'info',
							message: res.data.message,
							duration: 3000
						});
					});
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消操作'
					});
				})
			},
			showAccreditBtn(row) {
				if (this.$refs['accredit'] != undefined) {
					this.$refs['accredit'].resetFields();
				}
				this.clickedItem = row
				this.showAccredit = true
				this.accreditForm.creditMoney = row.creditMoney
				this.useCreditMoney = row.useCreditMoney
			},
			confirmAccredit() {
				this.$refs['accredit'].validate((valid) => {
					if (valid) {
						axiosApi('/superc/app/updateCreditMoney', {
							id: this.clickedItem.id,
							creditMoney: this.accreditForm.creditMoney
						}, res => {
							this.showAccredit = false
							this.$message({
								type: 'success',
								message: '修改额度成功!',
								duration: 1000
							});
							this.achieve()
						}, 1, 10, err => {
							this.$message.error(err.data.message);
						})
					}
				})
			},
			lookAccreditDetail() {
				this.$router.push({path: basePath + '/creditDetail', query: {id: this.clickedItem.id}})
			},
			//			分页
			handleCurrentChange(val) {
				this.currentPage = val
				this.achieve()
			},
			// 删除
			handleDelete(row) {
				console.log(row);
				this.$confirm('确认删除该账号？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.deleteFn(row.id);
                }).catch(() => {
                });
			},
			// 删除逻辑
			deleteFn(id) {
				axiosApi('/superc/supercStore/web/delete',{
					id : id,
				},(res) => {
					this.$message.success('操作成功');
					this.currentPage = 1;
					this.achieve();
				},1,1,(err) => {
					this.$message.warning(err.data.message);
				})
			},
			// 删除 - 查看
			handleOperator(row) {
				this.dialogStateOperator = true;
				axiosApi('/superc/delete/supercStore/select',{
					id : row.id,
				},(res) => {
					let data = res.data.data;
					this.listOperator = [{
						deleteEmployeeName : data.deleteEmployeeName,
						deleteTime : data.deleteTime,
					}];
				});
			},
				// 删除 - 撤回
			handleWithdraw(row) {
				this.$confirm('确认恢复该账号？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    axiosApi('/superc/supercStore/web/resume',{
						id : row.id,
					},(res) => {
						this.$message.success('操作成功');
						// 重置表格数据
						this.Form.state = null
						this.currentPage = 1;
						this.type = '0';
						this.achieve();
					},1,1,(err) => {
						this.$message.warning(err.data.message);
					});
                }).catch(() => {
                });
			},
		},
		//进入加载
		mounted() {
			this.$nextTick(() => {
				this.achieve()
				this.getHotelType()
			})
		},
	}
</script>

<style lang="less">
	#salesManage {
		.table-default.el-table .cell{
			padding: 10px 0px;
		}
		.table-default .el-button {
			width: auto;
		}
		.el-tabs__header {
			padding-left: 0;
		}
		.el-dialog {
			min-height: 300px;
		}
		.black {
			.edit-btn span {
				color: #000000 !important;
			}
		}
		.el-upload-list__item {
			width: 90px;
			height: 90px;
		}
		.el-upload--picture-card {
			width: 90px;
			height: 90px;
			line-height: 90px;
			background-size: 50px 50px;
		}
		.dis .el-upload--picture-card {
			display: none;
		}
		.imgContainer {
			.el-dialog__body {
				padding: 10px 30px;
				.imgWrap {
					text-align: center;
					overflow-x: auto;
					display: flex;
					img {
						height: 250px;
						width: auto;
						margin-right: 10px;
						&:nth-last-child(1) {
							margin-right: 0;
						}
					}
				}
			}
		}
		.nocon {
			text-align: center;
			margin-top: 25%;
		}

		.accredit-dialog {
			.accredit-center {
				padding-left: 115px;
				line-height: 32px;
				& > div {
					color: #333;
					&:nth-child(1) {
						margin-bottom: 10px;
						margin-right: 10px;
					}
				}
			}
			.el-form-item__error {
				left: 185px;
			}
		}
		.review-dialog {
			.content { text-align: center;padding: 0 50px; }
			.el-dialog {
				min-height: 320px;
			}
		}
		.ml-05{
			margin-left: -5px;
		}
		/* 浮层样式 修改 */
		.operator-box{
			.el-dialog{
				min-height: auto!important;
			}
			.el-dialog__body{
				padding: 20px 30px!important;
			}
		}

	}
</style>
