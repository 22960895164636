<template>
	<div id="recharge">
		<p><i class="iconfont font-16 icon-inspector color-b"></i><span class="font-16 ml-10 color-9 lh-16">温馨提示:</span></p>
		<p class="mt-10 ml26 color-9 font-16 w828">1.充值成功后，余额可能存在延迟现象，一般1-5分钟内到账，如有问题，请咨询客服。<br />2.充值金额必须大于0。<br />3.充值需扣除相应手续费。<br />4.您只能用储蓄卡进行充值，不支持信用卡充值。<br /> 5.充值完成后，您可以进入我的账户页面查看账户余额。</p>
		<el-form label-width="186px" :model="form" :rules="rules" ref="form" class="mt-50">
			<el-form-item label="账户余额:">
				<span class="color-danger">{{allmoney | fmoney}}</span>元
			</el-form-item>
			<el-form-item label="充值金额:" prop="money">
				<el-input v-model.trim="form.money" placeholder="请输入充值金额" @change="form.money == '0'?form.money = '1':''" :maxlength="12"></el-input><span class="ml-10">元</span>
			</el-form-item>
			<el-form-item class="mt-50">
				<el-button type="danger" @click="submit">下一步</el-button>
			</el-form-item>
		</el-form>
	</div>
</template>

<script>
	import { axiosApi, basePath } from "~/api/api"
	export default {
		data() {
			let check = (rule, val, callback) => {
				let reg = /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/
				if(!reg.test(val)) {
					callback(new Error("充值金额必须为整数或小数"))
				} else {
					if(val * 1 > 99999999.99) {
						callback(new Error("充值金额不能大于99,999,999.99元"))
					}
					callback()
				}
			}
			return {
				allmoney:"",//账户余额
				form: {
					money: ""
				},
				rules: {
					money: [{
						required: true,
						validator: check,
					}]
				}
			}
		},
		mounted(){
			this.allmoney = this.$route.params.money	
		},
		methods: {
			submit() {
				this.$refs.form.validate(val => {
					if(val) {
						this.$router.push(`${basePath}/pay/rechargemid2/1/${this.form.money}`)
					} else {
						console.log("fail")
					}
				})
			}
		}
	}
</script>

<style lang="less">
	#recharge {
		padding: 30px 155px;
		.ml26 {
			margin-left: 26px;
		}
		.ml-6 {
			margin-left: -6px;
		}
		.el-form-item__label {
			padding-right: 30px;
		}
		.wa {
			width: auto;
			padding: 0 5px;
		}
		.w828{
			width: 828px;
		}
	}
</style>