<template>
	<div id="groupInfo">
		<div class="mt-50 font-14 ml-30">
			<el-form :model='ruleForm' :rules="rules" ref="ruleForm" label-width='130px'>
				<el-form-item label="商品图片:">
					<el-upload v-if='this.$route.params.rej==0' :class="isDisable?'reduce1':''" action="/sunrise-gateway/oss/ossUpload" :file-list='fileList' list-type="picture-card" :on-preview="handlePictureCardPreview" :on-success="handleAvatarPhotoScucess" :before-upload="beforeAvatarUpload" :on-remove="handleRemove">
						<i class="el-icon-plus"></i>
					</el-upload>
					<el-upload v-else class="reduce" action="/sunrise-gateway/oss/ossUpload" :file-list='fileList' list-type="picture-card" :on-preview="handlePictureCardPreview">
					</el-upload>
					<el-dialog :visible.sync="dialogVisible" size="tiny">
						<img width="100%" :src="dialogImageUrl" alt="">
					</el-dialog>
				</el-form-item>
				<el-form-item label="商品名称:" prop="skuName">
					<el-input class="ml-20 width-500" v-model.trim="ruleForm.skuName" placeholder="请输入商品名称" disabled></el-input>
				</el-form-item>
				<el-form-item label="副标题:" prop="skuSubtitle">
					<el-input type="textarea" autosize :rows='2' class="ml-20" v-model.trim="ruleForm.skuSubtitle" placeholder="请输入副标题" :disabled='dis'></el-input>
				</el-form-item>
				<div class="main clearfix">
					<el-form-item label="商品编码:" prop="goodsNo" class="fl mr-50">
						<el-input class="ml-20" v-model.trim="ruleForm.goodsNo" placeholder="请输入商品编码" disabled></el-input>
					</el-form-item>
					<div class="fl mr-50 mb-20">
						<label class="mr-17">条形码:</label>
						<span>{{ruleForm.spuVo.citm}}</span>
					</div>
					<div class="fl mr-50 mb-20">
						<label class="mr-17">分类:</label>
						<span>{{ruleForm.spuVo.classifyName}}</span>
					</div>
					<div class="fl mr-50 mb-20">
						<label class="mr-17">品牌:</label>
						<span>{{ruleForm.spuVo.brandName}}</span>
					</div>
					<div class="fl mr-50 mb-20">
						<label class="mr-17">计量单位:</label>
						<span>{{ruleForm.units}}</span>
					</div>
					<div class="fl mr-50 mb-20">
						<label class="mr-17">包装规格:</label>
						<span>1*{{ruleForm.spuVo.specInfoNum}}</span>
					</div>
					<el-form-item label="商品说明:" prop="description" class="fl mr-50">
						<el-input type="textarea" autosize :rows='2' class="ml-20" v-model.trim="ruleForm.description" placeholder="请输入商品说明" :disabled='dis' :maxlength='100'></el-input>
					</el-form-item>
				</div>
				<el-form-item label="最低起订量:" class="mt-30" prop="minimunMoq">
					<el-input size="mini" class="ml-20" v-model.trim="ruleForm.minimunMoq" placeholder="最低起订量" @change="checkChang" :disabled='dis' :maxlength='3'></el-input>
					<span class="ml-10">{{ruleForm.unitName}}起售</span>
					<el-checkbox v-model="ruleForm.isIntegerMultiple" class="ml-30" @change="checkedChange" :disabled='dis'>订购数必须为包装规格整数倍</el-checkbox>
				</el-form-item>
				<el-form-item label="限购:" class="mt-30" prop="limitation">
					<el-input size="mini" class="ml-20" v-model.trim="ruleForm.limitation" placeholder="限购数量" :disabled='dis' :maxlength='3'></el-input>
					<span class="ml-10">（单位同最低起订量）</span>
				</el-form-item>
				<div class="clearfix mt-50">
					<el-form-item label="成本价:" class="fl mr-50" prop="costPrice">
						<el-input class="ml-20" v-model.trim="ruleForm.costPrice" placeholder="成本价" :disabled='dis'></el-input>
						<span class="ml-10">元/{{ruleForm.unitName}}</span>
					</el-form-item>
					<el-form-item v-if='identity == "central"' label="进店价:" class="fl mr-50">
						<el-input class="ml-20" v-model.trim="ruleForm.enterShopPrice" placeholder="进店价" disabled></el-input>
						<span class="ml-10">元/{{ruleForm.unitName}}</span>
					</el-form-item>
					<el-form-item v-if='identity == "big"' label="进店价:" class="fl mr-50">
						<el-input class="ml-20" placeholder="进店价" disabled></el-input>
						<span class="ml-10">元/{{ruleForm.unitName}}</span>
					</el-form-item>
					<el-form-item label="建议零售价:" class="fl mr-50" prop="msrp">
						<el-input class="ml-20" v-model.trim="ruleForm.msrp" placeholder="建议零售价" :disabled='dis'></el-input>
						<span class="ml-10">元/{{ruleForm.unitName}}</span>
					</el-form-item>
					<el-form-item label="团购价:" class="fl mr-50" prop="minimumGorupbuyyingPrice">
						<el-input class="ml-20" v-model.trim="ruleForm.minimumGorupbuyyingPrice" placeholder="团购价" :disabled='dis'></el-input>
						<span class="ml-10">元/{{ruleForm.unitName}}</span>
						<!--<span v-if='minSellingPrice &&  maxSellingPrice'>团购价范围区间：{{minSellingPrice || 0}} - {{maxSellingPrice || 0}}</span>-->
						<!--<p class="ml-20 color-red" v-if='(!minSellingPrice || !maxSellingPrice) && !dis'>当前商品未维护最低零售价与标准零售价</p>-->
						<p class="ml-20 color-red" v-if='identity == "central"&&ruleForm.minimumGorupbuyyingPrice<ruleForm.enterShopPrice'>*当前团购价已低于进店价</p>
					</el-form-item>
					<el-form-item label="业务员提成:" class="fl mr-50" prop="salesIncome">
						<el-input class="ml-20" v-model.trim="ruleForm.salesIncome" placeholder="业务员提成" :disabled='dis'></el-input>
						<span class="ml-10">元/{{ruleForm.unitName}}</span>
					</el-form-item>
				</div>
				<div>
					<!-- <el-form-item label="久加久认证:">
						<el-checkbox class='ml-10' v-model="checked" :disabled='dis'>{{ }}</el-checkbox>
					</el-form-item> -->
					<el-form-item v-if='groupType == 0' label="特殊商品:">
						<el-checkbox class='ml-10' v-model="sonChecked" :disabled='dis'>{{ }}</el-checkbox>
						<span class="color-red font-28">【特殊商品团购活动限购且不能参加营销活动】</span>
					</el-form-item>
					<el-form-item v-if='identity == "central" && groupType == 0' label="虚拟仓库存:" prop="virtualStock" :maxlength='6'>
						<el-input class="ml-20" v-model.trim="ruleForm.virtualStock" placeholder="虚拟库存" :disabled='dis'></el-input>
						<span class="ml-10">{{ruleForm.unitName}}</span>
					</el-form-item>
				</div>
				<el-form-item label="销售资质:" v-if='groupType == 1'>
					<el-upload v-if='saleCert[0] && saleCert[0].url.length > 0' class="reduce" :file-list='saleCert' action="/sunrise-gateway/oss/ossUpload" list-type="picture-card" :on-preview="handlePictureCardPreview">
						<i class="el-icon-plus"></i>
					</el-upload>
					<p v-else>未上传</p>
					<el-dialog :visible.sync="dialogVisible" size="tiny">
						<img width="100%" :src="dialogImageUrl" alt="">
					</el-dialog>
				</el-form-item>
				
				<el-form-item label="销售区域:" prop="area" style='width: 999px;'>
					<el-checkbox v-model="isCountrywide" :indeterminate="isIndeterminate" @change="isCountry" :disabled='dis'>全国</el-checkbox>
					<div class="">
						<span class="dib w125" v-for="item in areas" :key="item.id">
							<el-checkbox v-model="item.checkAll" :indeterminate="item.isIndeterminate" @change="proChange(item)" :disabled='dis'>
								<span>{{item.name}}</span>
						  <span class="color-danger font-12" v-if="item.citys.length>0">({{item.citys.length}})</span>
              </el-checkbox>
              <i class="cursor color-danger" :class="item.display?'el-icon-caret-top':'el-icon-caret-bottom'" @click="proClick(item)"></i>
              <el-checkbox-group v-model="item.citys" class="city" v-if="item.display" :class="{zindex:item.zindex}" @change="cityChange(item)">
                <el-checkbox v-for="i in item.childs" :key="i.id" :label="i.id" :title="i.name" :disabled='dis'>
									<span class="text-overflow w100" style="display: inline-block;">{{i.name}}</span>
								</el-checkbox>
              </el-checkbox-group>
						</span>
					</div>
				</el-form-item>
				<div class="mt-50 ml-55 pl-10">
					<div class="clearfix">
						<label class="mr-10 fl">图文说明:</label>
						<ueditor class='fl' id='own' ref="ueeditor" v-model="ruleForm.illustrationText"></ueditor>
					</div>
				</div>
				<el-form-item class="mt-30 change" v-if='this.$route.params.rej==0'>
					<el-button class="ml-20" type="danger" @click="handleSave('ruleForm',1)">仅保存</el-button>
					<el-button v-if='showButton("PLATFORM_GOODS_PUT",140003)' class="ml-20" type="danger" @click="handleSave('ruleForm',2)">保存并上架</el-button>
				</el-form-item>

			</el-form>
		</div>
	</div>
</template>

<script>
	import { axiosApi, basePath } from '../../api/api';
	export default {
		props: {
		      ruForms: {
		      type: Object,
		      }
		  },
		
		data() {
			var checkCostPrice = (rule, value, callback) => {
				if(parseFloat(this.ruleForm.costPrice) > parseFloat(this.ruleForm.enterShopPrice) && this.identity != "big") { //总公司
					this.a = true
					callback(new Error('成本价不能大于进店价'));
				} else {
					this.a = false
					if(this.c) {
						this.$refs.ruleForm.validateField('minimumGorupbuyyingPrice'); //验证团购价
					}
					callback();
				}
			};
			var checkEnterShopPrice = (rule, value, callback) => {
				if(value === '') {
					callback(new Error('进店价为必填项'));
					this.b = true
				} else if(parseFloat(this.ruleForm.minimumGorupbuyyingPrice) - parseFloat(this.ruleForm.enterShopPrice) < parseFloat(this.ruleForm.minimumGorupbuyyingPrice) * 0.006) {
					this.b = true
					callback(new Error('下游商户获取佣金不足以支付千分之六手续费'));
				} else if(parseFloat(this.ruleForm.enterShopPrice) < parseFloat(this.ruleForm.costPrice) || parseFloat(this.ruleForm.enterShopPrice) > parseFloat(this.ruleForm.minimumGorupbuyyingPrice)) {
					this.b = true
					callback(new Error('进店价需要处于成本价到团购价之间'));
				} else {
					this.b = false
					if(this.a) {
						this.$refs.ruleForm.validateField('costPrice');
					}
					if(this.d) {
						this.$refs.ruleForm.validateField('minimumGorupbuyyingPrice');
					}
					callback();
				}

			};
			var checkMsrp = (rule, value, callback) => {
				if(value === '') {
					callback(new Error('建议零售价为必填项'));
					this.c = true
				} else if(parseFloat(this.ruleForm.msrp) < parseFloat(this.ruleForm.minimumGorupbuyyingPrice)) {
					this.c = true
					callback(new Error('建议零售价不能小于团购价'));
				} else {
					this.c = false
					if(this.d) {
						this.$refs.ruleForm.validateField('minimumGorupbuyyingPrice');
					}
					callback();
				}
			};
			var checkMinimumGorupbuyyingPrice = (rule, value, callback) => {
				if(value === '') {
					callback(new Error('团购价为必填项'));
					this.d = true
				} else {
					this.d = false
					if(this.c) {
						this.$refs.ruleForm.validateField('msrp');
					}
					callback();
				}

			};
			var checkLimit = (rule, value, callback) => {
				if(parseFloat(value) < parseFloat(this.ruleForm.minimunMoq)) {
					callback(new Error('限购数量不能低于最低起订量！'));
				} else if(this.ruleForm.isIntegerMultiple && value % this.ruleForm.minimunMoq != 0 && this.ruleForm.minimunMoq != 0) {
					callback(new Error('限购数量必须为包装规格整数倍！'));
				}
				callback();

			};
			var checkSalesIncome = (rule, value, callback) => {
				if(parseFloat(value) >= parseFloat(this.ruleForm.minimumGorupbuyyingPrice) - parseFloat(this.ruleForm.enterShopPrice)) {
					callback(new Error('提成必须小于' + (parseFloat(this.ruleForm.minimumGorupbuyyingPrice - this.ruleForm.enterShopPrice).toFixed(2)) + '元（团购价-进店价）'));
				}
				callback();

			};
			return {
				areas: (() => { //添加属性
					var areaList = JSON.parse(localStorage.getItem('areaList'))
					areaList.forEach((item, index) => {
						this.$set(item, "checkAll", false) //省 是否 选中
						this.$set(item, "isIndeterminate", false) //省 是否  部分选中
						this.$set(item, "display", false) //市 是否显示
						this.$set(item, "zindex", false) //控制最后显示的区  在最上层
						this.$set(item, "checked", false) //市 是否 选中
						this.$set(item, "citys", []) //存储 选中市 对应的id
					})
					return areaList
				})(), //全国各省
				isCountrywide: false, //全国是否全选
				isIndeterminate: false, //全国是否部分选中
				authorizationRegion: [],
				adInfo: {},
				salesMan: '', //业务员提成
				radio1: 1, //仓库号
				checked: true, //久加久自营
				checked1: true, //久加久自营
				sonChecked: false,
				groupType: sessionStorage.groupType ? sessionStorage.groupType : 0,
				rules: {
					skuName: [{
							required: true,
							message: '商品名称为必填项'
						},
						{
							max: 32,
							message: '商品名称最多只能支持32个字符'
						}
					],
					skuSubtitle: [{
						max: 64,
						message: '副标题最多只能支持64个字符'
					}],
					goodsNo: [{
						max: 20,
						message: '商品编码最多只能支持20个字符'
					}],
					minimunMoq: [{
							required: true,
							message: '最低起订量为必填项'
						},
						{
							pattern: /^[1-9]\d{0,2}$/,
							message: '最大支持3位整数'
						}
					],
					costPrice: [{
							validator: checkCostPrice
						},
						{
							pattern: /^\d{1,10}(\.\d{1,2})?$/,
							message: '仅支持小数点前10位,小数点后2位的数字'
						}
					],
					salesIncome: [{
						pattern: /^\d{1,10}(\.\d{1,2})?$/,
						message: '仅支持小数点前10位,小数点后2位的数字'
					}],
					enterShopPrice: [{
							required: true,
							message: '进店价为必填项'
						},
						{
							validator: checkEnterShopPrice
						},
						{
							pattern: /^(?!0\d|[0.]+$|0$)\d{0,10}(\.\d{0,2})?$/,
							message: '仅支持小数点前10位,小数点后2位的非0数字'
						}
					],
					msrp: [{
							required: true,
							message: '建议零售价为必填项'
						},
						{
							validator: checkMsrp
						},
						{
							pattern: /^(?!0\d|[0.]+$|0$)\d{0,10}(\.\d{0,2})?$/,
							message: '仅支持小数点前10位,小数点后2位的非0数字'
						}
					],
					minimumGorupbuyyingPrice: [{
							required: true,
							message: '团购价为必填项'
						},
						{
							validator: checkMinimumGorupbuyyingPrice
						},
						{
							pattern: /^(?!0\d|[0.]+$|0$)\d{0,10}(\.\d{0,2})?$/,
							message: '仅支持小数点前10位,小数点后2位的非0数字'
						}
					],
					area: [{
						required: true,
						message: ''
					}],
					limitation: [{
						pattern: /^[1-9]\d{0,2}$/,
						message: '最大支持3位非0的正整数'
					}, {
						validator: checkLimit
					}],
					virtualStock: [{
						pattern: /^[0-9]\d{0,5}$/,
						message: '最大支持6位非0的正整数'
					}]
				},
				specInfo: '', //包装规格
				loading: false,
				ruleForm: {
					picturePos: [], //图片信息
					skuName: '', //商品名称
					skuNo: '', //商品编码
					classifyName: '', //分类
					brandName: '', //品牌
					units: '', //库存+计量单位
					unitName:'',//普通单位
					spuVo: {}, //基础信息
					id: '', //sku_id
					skuSubtitle: '', //副标题
					goodsNo: '', //商品编码
					minimunMoq: 1, //最低起订量
					costPrice: '', //成本价
					enterShopPrice: '', //进店价
					msrp: '', //建议零售价
					minimumGorupbuyyingPrice: '', //团购价
					isIntegerMultiple: false, //是否包装规格整数倍 (子公司/总公司的商品默认按箱售卖（即勾选订购数必须为包装规格整数倍，最低起订量显示包装规格数量)
					isPreferential: false, //是否启用客户设价
					sellerId: sessionStorage.traderId,
					goodsSubtitle: '', //副标题
					illustrationText: '', //富文本
					classifyId: '', //分类id
					salesArea: null, //销售区域
					description: '', //商品说明
					area: 'area', //模拟必输项星号
					limitation: '', //限购
					salesIncome: '', //业务员提成
					storeType: "1", //库存类型
					bizType: '0', //营业类型
					virtualStock: '', //虚拟库存
					// fareId: '', //选中模板id
				},
				isDisable: false, //控制加号显示隐藏
				dialogVisible: false, //预览
				dialogImageUrl: null, //图片显示
				fileList: [],
				saleCert: [], //销售资质
				dis: true, //控制审核，查看，区分
				api: '',
				value: '',
				a: false,
				b: false,
				c: false,
				d: false,
				ruForm: {},
				isShow1: false,
				isShow2: false,
				identity: '',
				minSellingPrice: '', //最低零售价
				maxSellingPrice: '', //会员价
				// fareIdList: [], //模板数据
				isDefaultId: '', //默认id
			}
		},
		props: ['ruForms'],
		computed: {

		},
		watch:{
			ruForms(val){
				this.ruForm = val
				this.getcommInfos()
				
			}
			
		},
		methods: {

			//商品详细信息
			getcommInfos() {
				
				this.ruleForm.picturePos = this.ruForm.skuShowPictureVOs;
				this.ruleForm.skuName = this.ruForm.skuName; //商品名称
				this.ruleForm.skuNo = this.ruForm.skuNo; //商品编码
				this.ruleForm.classifyName = this.ruForm.classifyName; //分类
				this.ruleForm.classifyId = this.ruForm.classifyId; //分类id
				this.ruleForm.brandName = this.ruForm.brandName; //品牌
				this.ruleForm.units = this.ruForm.baseUnitName; //库存和计量单位
				this.ruleForm.unitName = this.ruForm.unitName; //普通单位
				this.ruleForm.spuVo = this.ruForm.spuVo; //基础信息
				this.ruleForm.id = this.ruForm.id; //sku_id
				this.ruleForm.skuSubtitle = this.ruForm.skuSubtitle; //副标题
				this.ruleForm.goodsNo = this.ruForm.goodsNo; //商品编码
				this.ruleForm.minimunMoq = this.ruForm.minimunMoq; //最低起订量
				this.ruleForm.costPrice = this.ruForm.costPrice; //成本价
				this.ruleForm.enterShopPrice = this.ruForm.enterShopPrice //进店价
				this.ruleForm.msrp = this.ruForm.msrp; //建议零售价
				this.ruleForm.illustrationText = this.ruForm.illustrationText; //富文本
				this.ruleForm.minimumGorupbuyyingPrice = this.ruForm.grouponPrice; //团购价
				this.ruleForm.isIntegerMultiple = this.$route.params.addType == 'add' ? false : this.ruForm.isIntegerMultiple; //是否包装规格整数倍(第一次添加商品整数倍设置为true)
				this.ruleForm.isPreferential = this.ruForm.isPreferential; //是否启用客户设价
				this.ruleForm.salesArea = this.ruForm.salesArea; //销售区域
				this.ruleForm.description = this.ruForm.description; //图文说明
				this.ruleForm.specInfo = this.ruForm.specInfo; //最低起订量单位
				this.ruleForm.salesIncome = this.ruForm.salesIncome; //
				this.ruleForm.virtualStock = this.ruForm.virtualStock; //虚拟库存
				//						this.ruleForm.storeType = this.ruForm.storeType == 2 ? 1 : this.ruForm.storeType; //库存类型
				this.sonChecked = this.ruForm.storeType == 2 ? true : false; //库存类型
				this.ruleForm.bizType = this.ruForm.bizType; //营业类型
				this.checked = this.ruForm.bizType == 1 ? true : false; //营业类型
				this.ruleForm.limitation = this.ruForm.limitation == 0 ? null : this.ruForm.limitation; //限购
				this.saleCert = [{
					url: this.ruForm.saleCert
				}]
				if(this.identity != 'small' && this.ruForm.bizType == null) {
					this.checked = true
				}
				if(this.$route.params.addType == 'add') { //第一次添加商品，包装规格整数倍为true，设置最低起订量为包装规格整数倍
					if(this.ruleForm.minimunMoq % this.ruleForm.spuVo.specInfoNum != 0) {
						this.ruleForm.minimunMoq = this.ruleForm.spuVo.specInfoNum;
						this.ruleForm.minimunMoq=1;
					}
				
				}
				if(this.ruForm.skuShowPictureVOs.length > 0) {
					this.ruForm.skuShowPictureVOs.forEach((item) => {
						item.url = item.fileUrl;
					}); //图片处理
					this.fileList = this.ruForm.skuShowPictureVOs; //图片展示
					if(this.fileList.length >= 6) {
						this.isDisable = true
					} else {
						this.isDisable = false
					}
				} else {
					this.fileList = []
				}
				if(this.ruleForm.enterShopPrice == 0) {
					this.ruleForm.enterShopPrice = ''
				}
				if(this.ruleForm.minimumGorupbuyyingPrice == 0) {
					this.ruleForm.minimumGorupbuyyingPrice = ''
				}
				if(this.ruleForm.msrp == 0) {
					this.ruleForm.msrp = ''
				}
				this.specInfo = this.ruForm.spuVo.specInfo; //规格
				this.getArea() //销售区域初始数据处理
//				this.goodsPriceRange() //价格区间 8.20去掉这个功能
			},
			//客户设价数据处理
			checkChang() {
				if(this.ruleForm.minimunMoq != 0) {
					if(this.ruleForm.minimunMoq % this.ruleForm.spuVo.specInfoNum != 0) {
						this.ruleForm.isIntegerMultiple = false;
					} else {
						this.ruleForm.isIntegerMultiple = this.ruleForm.isIntegerMultiple;
						this.ruleForm.minimunMoq = this.ruleForm.spuVo.specInfoNum;
						
					}
					this.$refs.ruleForm.validateField('limitation'); //是否为包装规格整数倍更改 => 限购验证
				}
			},
			checkedChange() {
				
				// this.ruleForm.minimunMoq = this.ruleForm.spuVo.specInfoNum;
				if(this.ruleForm.minimunMoq % this.ruleForm.spuVo.specInfoNum != 0) {
					this.ruleForm.minimunMoq = this.ruleForm.spuVo.specInfoNum;
				}
				this.$refs.ruleForm.validateField('limitation'); //是否为包装规格整数倍更改 => 限购验证
			},
			//商品信息保存
			handleSave(ruleForm, val) {
//				if(!this.minSellingPrice || !this.maxSellingPrice) {
//					return false;
//				}
				this.areaSave()
				if(this.ruleForm.minimunMoq == 0) {
					this.$message.info('最低起订量不能为0');
					return;
				} else if(this.ruleForm.salesArea.length == 0) {
					this.$message.info('请选择销售区域！');
					return;
				} else {
					setTimeout(() => {
						this.ruleForm.illustrationText = this.$refs.ueeditor.getContent();
						this.$refs[ruleForm].validate((valid) => {
							if(valid) {
								if(this.$route.params.goodsType == 2) {
									this.api = "/groupon/addBigStoreBaseInfo/insert";
									this.ruleForm.picturePos.forEach(item => {
										if(item.id) {
											delete item.id
										}
									})
								} else {
									this.api = "/groupon/bigStoreBaseInfo/update";
								}
								this.ruleForm.bizType = this.checked ? 1 : 0; //营业类型
								this.ruleForm.storeType = this.sonChecked ? '2' : '1'
								this.ruleForm.grouponPrice = this.ruleForm.minimumGorupbuyyingPrice //团购价
								this.ruleForm.goodsSubtitle = this.ruleForm.skuSubtitle
								this.ruleForm.limitation = this.ruleForm.limitation == '' ? null : this.ruleForm.limitation
								this.ruleForm.enterShopPrice = this.ruleForm.enterShopPrice == '' ? null : this.ruleForm.enterShopPrice; //进店价
								axiosApi(this.api, this.ruleForm, (res) => {
									// this.handleSaves(val)

									if(val == '1'){ //保存
										this.$message({
										type: 'success',
										message: '操作成功!',
										duration: 1000
									});
									this.$router.push(basePath + "/superGroup/groupAllGoods");
									}else{//保存并上架
										this.upperlower()
									}
									this.$route.params.goodsType = 1
								}, 1, 10, (res) => {
									this.$message({
										type: 'warning',
										message: res.data.message,
										duration: 1000
									});
								})
							} else {
								return false;
							}
						});
					}, 0)
				}

			},
			//	区域上传处理
			areaSave() {
				this.ruleForm.salesArea = [] //重置再选择
				this.areas.forEach(item => { //省
					if(item.isIndeterminate || item.checkAll) {
						let obj = {};
						obj.id = item.id;
						obj.sub = item.citys.map(item => {
							return {
								id: item
							}
						});
						this.ruleForm.salesArea.push(obj)
					}
				})
			},

			//上传图片
			handlePictureCardPreview(file) { //预览
				this.dialogImageUrl = file.url;
				this.dialogVisible = true;
			},
			beforeAvatarUpload(file) { //上传之前的验证
				const isImage = (file.type.indexOf('jpg') != -1 || file.type.indexOf('jpeg') != -1 || file.type.indexOf('png') != -1 || file.type.indexOf('bmp') != -1);
				const isLt4M = file.size / 1024 / 1024 < 4 && file.size / 1024 / 1024 > 0;
				if(!isImage) {
					this.$message.error('请上传图片格式文件!');
					this.loading = false
				}
				if(!isLt4M) {
					this.$message.error('上传图片大小不能超过 4MB!');
					this.loading = false
				}
				return isImage && isLt4M;
			},
			handleAvatarPhotoScucess(res, file, fileList) { //上传成功的状态
				file.url = res.data[0];
				file.fileUrl = res.data[0];
				file.fileType = 1;
				this.ruleForm.picturePos = fileList;
				//成功的回调
				if(fileList.length >= 6) {
					this.isDisable = true
				} else {
					this.isDisable = false
				}
			},
			handleRemove(file, fileList) { //删除
				this.ruleForm.picturePos = fileList
				if(fileList.length >= 6) {
					this.isDisable = true
				} else {
					this.isDisable = false
				}
			},
			getArea() {
				if(this.ruleForm.salesArea) {
					let arr = JSON.parse(this.ruleForm.salesArea);
					arr.forEach(item1 => { //返回数据
						this.areas.forEach(item2 => { //总数据
							if(item1.id == item2.id) { //省相等
								item2.checkAll = item1.sub.length == item2.childs.length;
								item2.isIndeterminate = item1.sub.length > 0 && item1.sub.length < item2.childs.length;
								item2.citys = item1.sub.map(item => item.id.toString());
								this.isCountrywide = arr.length == this.areas.length;
								this.isIndeterminate = arr.length > 0 && arr.length < this.areas.length;
							}
						})
					});
				}
			},

			//start
			proClick(item) { //市是否展开
				item.display = !item.display
				item.zindex = !item.zindex
				
				this.areas.forEach(item1 => {
					console.log(item1,item , item1==item, 'item')
					if(item1 != item) {
						item1.zindex = false
						item1.display = false
					}
				})
			},
			proChange(item) { //省
				item.citys = item.checkAll ? item.childs.map(item => item.id) : []
				console.log(item.citys,item.childs,'item.citys')
				item.isIndeterminate = item.citys.length > 0 && item.citys.length < item.childs.length
				let a = this.areas.filter(item => item.checkAll) //过滤被选中的省
				let b = this.areas.filter(item => item.isIndeterminate) //过滤部分被选中的省
				this.isCountrywide = a.length == this.areas.length
				this.isIndeterminate = (a.length > 0 || b.length > 0) && a.length < this.areas.length
			},
			cityChange(item) { //市
				item.checkAll = item.childs.length == item.citys.length
				item.isIndeterminate = item.citys.length > 0 && item.citys.length < item.childs.length
				let a = this.areas.filter(item => item.checkAll) //过滤被选中的省
				let b = this.areas.filter(item => item.isIndeterminate) //过滤部分被选中的省
				this.isCountrywide = a.length == this.areas.length
				this.isIndeterminate = (a.length > 0 || b.length > 0) && a.length < this.areas.length
			},
			isCountry() { //全国
				if(this.isCountrywide) {
					this.areas.forEach(item => {
						item.checkAll = true
						this.proChange(item)
					})
				} else {
					this.areas.forEach(item => {
						item.checkAll = false
						this.proChange(item)
					})
				}
			},
			//登录信息
			isShow() {
				axiosApi('/groupon/sellerType/select', {
					id: sessionStorage.traderId
				}, (res) => {
					this.identity = res.data.data
				}, 1, 10, (res) => {
					this.$message.info(res.data.message);
				});
			},
			//是否显示自营选择按钮
			isShowType() {
				axiosApi('/groupon/chooseBizType/display', {

				}, (res) => {
					this.isShow2 = res.data.data
				}, 1, 10, (res) => {
					this.$message.info(res.data.message);
				});
			},
			//价格区间
			goodsPriceRange() {
				axiosApi('/groupon/goodsPriceRange/select', {
					skuNo: this.$route.params.skuNo
				}, (res) => {
					this.minSellingPrice = res.data.data ? res.data.data.minSellingPrice : null
					this.maxSellingPrice = res.data.data ? res.data.data.maxSellingPrice : null

				}, 1, 10, (res) => {
					this.$message({
						type: 'error',
						message: res.data.message,
						duration: 1000
					})
				})
			},
			
			//商品上架
			upperlower() {
				axiosApi('/groupon/bigStoreState/batchUpdate', {
					skuNos: [this.$route.params.skuNo],
					goodsState: '2',
					sellerId: sessionStorage.traderId
				}, (res) => {
					this.$message({
						type: 'success',
						message: '操作成功!',
						duration: 1000
					});
					this.$router.push(basePath + "/superGroup/groupAllGoods");
				}, 1, 10, (res) => {
					this.$message.info(res.data.message);
				});
			},

		},
		mounted() {
			this.ruleForm.minimunMoq=1
			this.$nextTick(function() {
				// this.getFareIdList()
				this.isShow()
				
				if(this.$route.params.rej == 0) {
					this.dis = false
				} else {
					this.dis = true
				}
				this.$refs.ruleForm.resetFields()
				// this.ruleForm.minimunMoq=1
				

				this.isShowType()
				
			})
		}
	}
</script>

<style lang="less">
	#groupInfo {
		.el-radio__label {
			color: #333333;
		}
		.width-500 {
			width: 500px;
		}
		.width-850 {
			width: 850px;
		}
		.el-textarea {
			width: 500px;
			.el-textarea__inner {
				resize: none;
			}
		}
		.change {
			width:auto;
		}
		.el-checkbox__input.is-disabled+.el-checkbox__label {
			color: #333;
		}
		.el-checkbox__input.is-disabled.is-checked .el-checkbox__inner {
			background-color: #20a0ff;
			border-color: #20a0ff;
		}
		.tit {
			.el-input {
				width: 400px;
			}
		}
		.el-form-item__error {
			margin-left: 20px;
		}
		.mr-17 {
			margin-right: 17px;
		}
		.main {
			label {
				width: 130px;
				height: 32px;
				line-height: 32px;
				padding-right: 10px;
				box-sizing: border-box;
				text-align: right;
				display: inline-block;
			}
			span {
				width: 240px;
				display: inline-block;
			}
		}
		.search {
			.el-input__inner {
				height: 36px !important;
				border: 2px solid #ff4201;
			}
			.el-input {
				width: 320px;
			}
			.el-button {
				width: 80px;
				height: 36px;
				font-size: 16px;
				background-color: #ff4201;
				border: 0;
				border-radius: 0;
			}
		}
		.el-upload--picture-card {
			width: 90px;
			line-height: 90px;
			height: 90px;
		}
		.el-icon-plus {
			line-height: 90px;
		}
		.el-upload-list--picture-card .el-upload-list__item {
			width: 90px;
			height: 90px;
		}
		.fileList .el-upload--picture-card {
			display: none;
		}
		.mainList {
			.el-input {
				width: 100px;
				.el-input__inner {
					text-align: center;
					border: 0;
					box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 8px;
				}
			}
		}
		.reduce .el-upload--picture-card {
			display: none;
		}
		.reduce1 .el-upload--picture-card {
			display: none;
		}
		.reduce .el-upload-list__item-delete {
			display: none;
		}
		.reduce1 .el-upload-list__item-delete {
			/*display: none;*/
		}
		.el-form-item__error {
			width: 500px;
		}
		.city {
			position: absolute;
			z-index: 1000;
			width: 335px;
			padding-top: 10px;
			top: 30px;
			left: 0;
			background: #ffffc3;
			.el-checkbox {
				margin: 5px;
				display: inline-block;
			}
		}
		.w125 {
			width: 195px;
			position: relative;
		}
		.w100 {
			width: 100px;
		}
		.zindex {
			z-index: 9999;
		}
		.w400 {
			width: 400px;
		}
		#own {
			width: 800px !important;
		}
		.color-red {
			color: red;
		}
	}
</style>