import { axiosApi } from '~/api/api.js'
import { Message,MessageBox } from 'element-ui'
import m from '~/main.js'
import { resolve } from 'path';
const basePath = "/customer";
const hotelEdit = {
    // namespaced: true,
    state: {
      syncPackageGoodVisible: false,// 套餐商品同步
    },
    mutations: {
      openSyncPackageGoodDialog(state, payload){// 打开关闭套餐商品同步弹框
        state.syncPackageGoodVisible = payload
      },
    },
    actions: {
      
      //统一设置起订量
      setUpQuantity({state, commit}, payload){
          return new Promise((resolve,reject)=>{
              axiosApi('/goods/hotelagreementgoods/setting/up/quantity',payload,(res)=>{resolve(res)},1,10,(err)=>{reject(err)})
          })
      },
      // //折扣商品列表(单个客户)
      // hotelselleragreement({state,commit}, payload){
      //     return new Promise((resolve,reject)=>{
      //         axiosApi('/goods/hotelagreementgoods/selectList',
      //         payload.body,
      //         res=>{resolve(res)},
      //         payload.currentPage,'10',
      //         (err)=>{reject(err)})
      //     })
      // },
      mealGoodUpdate({state,commit}, payload){// 编辑套餐后保存
          return new Promise((resolve,reject)=>{
              axiosApi('/goods/sku/customerPrice/update',payload,res=>{resolve(res)},null,null,res=>{reject(res)})
          })
      }
        
    },
    getters: {
        
    }
}
export default hotelEdit
