<template>
  <div id="voucherDetail" class="bg-f">
    <div class="box-con bdt">
      <div class="bg-f">
        <el-form
          class=""
          :model="form"
          label-width="130px"
          :rules="rules"
          ref="ruleForm"
        >
          <el-form-item label="代金券名称:" prop="title">
            <el-input
              :disabled="!isAdd"
              placeholder="输入代金券名称"
              class="fl ml-20"
              v-model.trim="form.title"
            ></el-input>
          </el-form-item>
          <el-form-item label="代金券描述:" prop="remark">
            <el-input
              maxlength="50"
              :rows="4"
              type="textarea"
              show-word-limit
              :disabled="!isAdd"
              placeholder="输入代金券描述"
              class="fl ml-20"
              v-model="form.remark"
              style="width: 240px"
            ></el-input>
          </el-form-item>
          <el-form-item label="发放数量:" prop="num">
            <el-input
              :disabled="!isAdd"
              placeholder="输入发放数量"
              class="input_short_1 ml-20"
              v-model.trim="form.num"
            ></el-input>
            张
          </el-form-item>
          <el-form-item label="面额:" prop="amountMoney">
            <el-input
              :disabled="!isAdd"
              placeholder="输入面额"
              class="input_short_1 ml-20"
              v-model.trim="form.amountMoney"
            ></el-input>
            元
          </el-form-item>
          <el-form-item label="团购活动:" required>
            <el-select
              v-if="isAdd"
              class="group-name ml-20"
              v-model="form.grouponId"
              placeholder="请选择"
              :disabled="!isAdd"
            >
              <el-option
                v-for="(item, i) in activityList"
                :key="i + 'id'"
                :label="item.grouponName"
                :value="item.id"
              ></el-option>
            </el-select>
            <el-input
              v-if="!isAdd"
              disabled
              placeholder="全部团购活动"
              class="input_short_1 ml-20"
              v-model.trim="form.grouponName"
            ></el-input>
          </el-form-item>
          <el-form-item label="有效期至:" prop="startTime">
            <el-date-picker
              class="ml-20"
              :disabled="!isAdd"
              v-model="form.startTime"
              type="date"
              placeholder="选择日期"
              :picker-options="pickerOptions"
            />
          </el-form-item>
          <el-form-item label="发放方式:">
            <p class="ml-20">系统自动发放</p>
          </el-form-item>
          <el-form-item label="添加绑定商品" prop="gifts" v-if="isAdd">
            <el-button
              type="primary"
              @click="goodVisible = true"
              :disabled="!isAdd"
              style="margin-left: 20px"
              >添加商品</el-button
            >
            <span style="margin-left: 10px; color: orange; font-size: 12px"
              >注:最多添加5个</span
            >
          </el-form-item>

          <!-- 已添加商品展示 -->
          <el-form-item v-if="willSelectList.length > 0">
         <el-table
          :data="willSelectList"
          v-loading="goodLoading"
          class="table-default"
          stripe
          style="height:450px;width: 900px;"
        >
          <!-- <el-table-column type="selection" width="50" :selectable="selectInit"> -->
          <el-table-column label="商品名称" width="300">
            <template slot-scope="scope">
              <div class="goods-wrap">
                <div class="ml-10 l-googs" style="position: relative">
                  <img v-if="scope.row.fileUrl" :src="scope.row.fileUrl" />
                  <img v-else src="../../assets/goods.jpg" />
                </div>
                <div class="ml-10 r-goods">
                  <div class="tal w-160 lh-30">
                   商品编码 {{ scope.row.goodsNo}}
                  </div>
                  <div class="tal w-160 lh-30">{{ scope.row.skuName }}</div>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="规格" prop="specInfoNum" width="80">
            <template slot-scope="scope">
              <span>1*{{ scope.row.specInfoNum }}</span>
            </template>
          </el-table-column>
          <el-table-column label="单位" prop="unitName" width="80"></el-table-column>
          <el-table-column label="团购价"  width="100">
            <template slot-scope="scope">
              <span>{{ scope.row.grouponPrice }}元/{{ scope.row.unitName }}</span>
            </template>
          </el-table-column>
          <el-table-column label='绑定数量' >
						<template slot-scope="scope">
							<div class="text_input" style="display: flex; align-items: center;justify-content: center;flex-direction: row;">
								<el-input-number v-model="scope.row.num" :min="1" :max="1000" 
                size="small"
                :step="1" 
                 step-strictly
                 :controls="false" >
                  <!-- <template slot="prefix">
                    <span style="color: #999;">请输入1~1000的数字</span>
                  </template> -->
                </el-input-number>
                <span>   {{ scope.row.unitName }}</span>
								<!-- <el-input type="number" v-model.trim="scope.row.grouponPrice" :maxlength="6" size="mini"></el-input> -->
							</div>
						</template>
					</el-table-column>
        </el-table>
          </el-form-item>


   
          <el-form-item v-if="!isAdd&&goodsInfo.length > 0" label="绑定商品:">
         <el-table
          :data="goodsInfo"
          v-loading="goodLoading"
          class="table-default"
          style="height:470px;width: 900px;"
        >
          <el-table-column label="商品名称" width="400">
            <template slot-scope="scope">
              <div class="goods-wrap">
                <div class="ml-10 l-googs" style="position: relative">
                  <img v-if="scope.row.fileUrl" :src="scope.row.fileUrl" />
                  <img v-else src="../../assets/goods.jpg" />
                </div>
                <div class="ml-10 r-goods">
                  <div class="tal w-160 lh-30">
                   商品编码 {{ scope.row.goodsNo}}
                  </div>
                  <div class="tal w-160 lh-30">{{ scope.row.skuName }}</div>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="规格" prop="specInfoNum" >
            <template slot-scope="scope">
              <span>1*{{ scope.row.specInfoNum }}</span>
            </template>
          </el-table-column>
          <el-table-column label="单位" prop="unitName" ></el-table-column>
          
          <el-table-column label='绑定数量' >
						<template slot-scope="scope">
								<span>{{ scope.row.num }}  {{ scope.row.unitName }}</span>
						</template>
					</el-table-column>
        </el-table>
          </el-form-item>


          <el-form-item v-if="!isAdd" class="mt-50" label="发放客户:">
            <span class="color-3 font-12 ml-20">手机号码</span>
            <el-input
              placeholder="输入手机号"
              class="input_short_1 ml-10"
              v-model.trim="mobile"
            ></el-input>
            <el-button
              class="ml-20 color-danger"
              style="z-index: 0"
              @click="search(0)"
              >查询</el-button
            >
          </el-form-item>
          <div class="ml-100 mt-50 pl-20" v-if="!isAdd">
            <el-table
              :data="tableData"
              v-loading=""
              class="ml-30 mt-10 table-default mr-30"
              stripe
            >
              <el-table-column
                label="客户"
                width="300"
                prop="name"
              ></el-table-column>
              <el-table-column
                label="手机号"
                width=""
                prop="mobile"
              ></el-table-column>
              <el-table-column
                label="备注"
                width=""
                prop="remark"
              ></el-table-column>
            </el-table>
            <el-pagination
              class="tar mr-30"
              v-if="total > 0"
              :current-page="currentPage"
              :page-size="10"
              :total="total"
             
              layout="prev , pager, next, jumper"
              @current-change="handleCurrent"
            >
              <!-- 分页组件 -->
            </el-pagination>
          </div>
          <el-form-item v-if="isAdd" label="" class="mt-50 pl-20">
            <el-button style="z-index: 0" type="danger" @click="insert"
              >保存</el-button>
            <el-button style="z-index: 0" @click="goBack">取消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <!--添加商品-->
    <el-dialog
      class="goods-dialog"
      title="选择商品"
      :visible.sync="goodVisible"
      @close="closeGoodVisible"
      width="100%"
    >
      <div v-loading="tableloading">
        <el-form
          :inline="true"
          :model="skuObj"
          label-width="80px"
          class="clearfix"
          :style="{ width: '670px' }"
        >
          <div class="fl">
            <el-form-item label="商品名称:">
              <el-input v-model.trim="skuObj.skuName"></el-input>
            </el-form-item>
            <el-form-item label="商品编码:">
              <el-input v-model.trim="skuObj.goodsNo"></el-input>
            </el-form-item>
          </div>
          <el-button
            type="primary"
            class="fl ml-50"
            @click="handleCurrentChangePlatform(1)"
            >查询</el-button
          >
        </el-form>
        <el-table
          :data="giftData"
          v-loading="giftLoading"
          class="table-default"
          @selection-change="handleSelectionChange"
          stripe
          style="height:500px"
        >
          <!-- <el-table-column type="selection" width="50" :selectable="selectInit"> -->
          <el-table-column type="selection" width="50" >
          </el-table-column>
          <el-table-column label="商品名称" width="280">
            <template slot-scope="scope">
              <div class="goods-wrap">
                <div class="ml-10 l-googs" style="position: relative">
                  <img v-if="scope.row.fileUrl" :src="scope.row.fileUrl" />
                  <img v-else src="../../assets/goods.jpg" />
                </div>
                <div class="ml-10 r-goods">
                  <div class="tal w-160 lh-30">
                   商品编码 {{ scope.row.goodsNo}}
                  </div>
                  <div class="tal w-160 lh-30">{{ scope.row.skuName }}</div>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="商品分类"
            prop="classifyName"
          ></el-table-column>
          <el-table-column label="规格" prop="specInfoNum">
            <template slot-scope="scope">
              <span>1*{{ scope.row.specInfoNum }}</span>
            </template>
          </el-table-column>
          <el-table-column label="单位" prop="unitName"></el-table-column>
          <el-table-column label="团购价" prop="grouponPrice">
            <template slot-scope="scope">
              <span>{{ scope.row.grouponPrice }}元/{{ scope.row.unitName }}</span>
            </template>
          </el-table-column>



        </el-table>
        <!-- 分页组件 -->
        <el-pagination
          style="margin-bottom:20px"
          class="tar mt-20"
          v-if="totalPlatform > 0"
          :current-page="currentPagePlatform"
          :page-size="pageSize"
          :total="totalPlatform"
          :page-sizes="[10, 20, 50, 100, 1000]"
          layout="prev, pager, next, sizes , jumper"
          @current-change="handleCurrentChangePlatform"
          @size-change="handleSizeChange"
        >
        </el-pagination>
        <div slot="footer" class="dialog-footer" style="text-align: center">
          <el-button type="primary" @click="addgift" :loading="btnLoading"
            >确 定</el-button
          >
          <el-button @click="closeGoodVisible">取 消</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapMutations, mapActions } from "vuex";
import { axiosApi, basePath } from "~/api/api";

export default {
  data() {
    var checkPersonCount = (rule, value, callback) => {
      if (parseFloat(value) > 1000000) {
        return callback(new Error("面额不能大于1000000"));
      } else {
        callback();
      }
    };
    return {
      giftIdList: [],
      currentPagePlatform: 1,
      pageSize: 10,
      totalPlatform: 1,
      giftLoading: false,
      goodLoading: false,
      giftData: [],
      type: "",
      skuObj: {
        skuName: null, //商品名称
        classifyId: null, //商品分类
        goodsNo:null,//商品编码
      }, //赠品搜索条件
      giftsId: "",
      willAddSend: [], //添加赠品列表
      willSelectList: [],//商品列表
      goodsInfo: [],//查看列表
      tableloading: false,
      goodVisible: false,
      noChange: false,
      activityList: [], //团购列表
      voucherId: "",
      mobile: "",
      total: 0,
      currentPage: 1,
      tableData: [],
      isAdd: false, //是否新增
      pickerOptions: {
        disabledDate(time) {
          let curDate = new Date().getTime();
          let ten = 10 * 31536000 * 1000 + 24 * 3600 * 1000 * 3;
          let TenYear = curDate + ten;
          return (
            time.getTime() + 24 * 3600 * 1000 < Date.now() ||
            time.getTime() > TenYear
          );
        },
      },
      dialog1Visible: false, // 添加商品浮层
      dialogIsShow: false, //地址弹窗
      btnLoading: false,
      regionText: null,
      isUpdate: false, //是不是编辑
      personCountUpdate: 0, //编辑的时候 数量不能小于原来的数据
      form: {
        title: null,
        num: null,
        amountMoney: null,
        startTime: null,
        grouponId: "",
        grouponName: "",
      },
      goods: {
        goodsName: null,
        goodsUrl: null,
        goodsNo: null,
      },
      value: "",
      rules: {
        title: [
          {
            required: true,
            message: "请输入代金券名称",
          },
          {
            min: 1,
            max: 15,
            message: "代金券名称最多15个字",
          },
          {
            pattern: /^[\u4e00-\u9fa5a-zA-Z0-9]+$/,
            message: "仅支持支持中英文及数字",
          },
        ],
        num: [
          {
            required: true,
            message: "请输入发放数量",
          },
          {
            pattern: /^([1-9]\d{0,3}|10000)$/,
            message: "仅支持支持1-10000间的正整数",
          },
        ],
        amountMoney: [
          {
            required: true,
            message: "请输入面额",
          },
          {
            pattern: /^(?!0\d|[0.]+$|0$)\d{0,7}(\.\d{0,2})?$/,
            message: "仅支持小数点前7位,小数点后2位的非0数字",
          },
          {
            validator: checkPersonCount,
          },
        ],
        startTime: [
          {
            required: true,
            message: "请选择有效期",
          },
        ],
      },
    };
  },
  computed: {
    goodsList() {
      return this.$store.state.sendGoodsManager.goodsList;
    },
    classifylist() {
      return this.$store.state.classifylist.classifylistData;
    },
  },
  watch: {},
  methods: {
    ...mapActions({
      queryclassifylistData: "queryclassifylistData",
      getGoodsList: "getGoodsList",
    }),
    selectInit(row, index) {
      // 禁用已添加的绑定商品
      var arr =  this.willSelectList.map(item=>{
        return item.id
      })
      if (row.goodsState == 2||arr.includes(row.id)) {
        return false;
      } else {
        return true;
      }
    },
    //改变当前页
    handleCurrentChangePlatform(val) {
      this.currentPagePlatform = val;
      this.querygood();
    },
    handleSizeChange(val){
      this.currentPagePlatform = 1;
      this.pageSize = val
      this.querygood();
    },
    deleteItem(index){
      this.willSelectList.splice(index,1)
    },
    //添加赠品
    addgift() {
      if (this.willAddSend.length > (5-this.willSelectList.length)) {
        this.$message.warning("捆绑商品最多为5件！");
        return;
      }
      
      var list = this.willAddSend.map((v) => {

        var data = {

          goodsId:v.id,
          kuNo:v.skuNo,
          skuName:v.skuName,
          fileUrl:v.fileUrl,
          unitName:v. unitName,
          oodsNo:v.goodsNo,
          specInfoNum:v.specInfoNum,
           num : v.num?v.num:1,
           fileUrl:v.fileUrl,
           grouponPrice:v.grouponPrice

        }
        return data;
      });
      this.willSelectList =  this.willSelectList.concat(list);
      this.btnLoading = false;
      this.goodVisible = false;
      console.log(this.willSelectList,"willSelectList");
      
    },
    //搜索商品
    querygood() {
      let body = {
        skuName:this.skuObj.skuName,
        goodsNo:this.skuObj.goodsNo,
        sellerId: sessionStorage.getItem("traderId"),
        goodsState: 2,
        AuditState: 2,
        currentPage: this.currentPagePlatform,
      };
      this.achieveSearch(body);
    },
		// 关闭弹框清除条件，重置分页
		closeGoodVisible(){
			this.goodVisible = false
			this.currentPagePlatform = 1
      this.pageSize = 10
			this.skuObj.skuName = null
			this.querygood()
		},
    //初始化
    init() {
      this.achieveSearch();
    },
    //搜索
    achieveSearch(payload) {
      let func = this.$debouncing(() => {
        let url;
        let body;
        if (payload) {
          body = payload || { skuName: null };
        }
        url = "/groupon/grouponGoods/list";
        axiosApi(
          url,
          body,
          (res) => {
            this.giftData = res.data.data.elements;
            this.totalPlatform = res.data.data.totalCount;
          },
          this.currentPagePlatform,
          this.pageSize
        );
      });
      func();
    },
    getGiftList() {
      let body = { 
        skuName:this.skuObj.skuName,
        sellerId: sessionStorage.getItem("traderId"),
        goodsState: 2,
        AuditState: 2,
        currentPage: this.currentPagePlatform
      };
      let url = "/groupon/grouponGoods/list";
      axiosApi(url, body, (res) => {
        this.giftData = res.data.data.elements;
        this.totalPlatform = res.data.data.totalCount;
      },1,10);
    },
    //选择商品
    handleSelectionChange(val) {

      
      this.willAddSend = val;
      // this.willAddSend.forEach((item) => {
      //     if (val == item.id) {
      //       this.NEWLIST.goodsId =item.goodsId;
      //       this.NEWLIST.skuNo =item.skuNo;
      //       this.NEWLIST.skuName =item.skuName;
      //       this.NEWLIST.fileUrl =item.fileUrl;
      //       this.NEWLIST.unitName =item.unitName;
      //       this.NEWLIST.goodsNo =item.goodsNo;
      //       this.NEWLIST.specInfoNum =item.specInfoNum;
      //       this.NEWLIST.num =1;
      //       this.NEWLIST.fileUrl =item.fileUrl;

      //     }
      //   });
       // console.log(this.NEWLIST,"888");

    },
    insert() {
      // 新建代金券
      this.$refs.ruleForm.validate((v) => {
        if (v) {
          axiosApi(
            "/groupon/voucher/add",
            {
              title: this.form.title,
              amountMoney: this.form.amountMoney,
              num: this.form.num,
              remark: this.form.remark,
              gmtValidity: this.form.startTime.getTime(),
              grouponId: this.form.grouponId,
              goodsInfo:this.willSelectList
            },
            (res) => {
              this.$router.push(basePath + "/superGroup/groupSalesManager")
              .then(() => {
                    this.$router.go(0); // 刷新当前页面
                    });;
              this.$message({
                type: "success",
                message: "保存成功",
                duration: 3000,
              });
            },
            1,
            10,
            (res) => {
              this.$message({
                type: "info",
                message: res.data.message,
                duration: 3000,
              });
            }
          );
        }
      });
    },
    getDetail() {
      // 代金券详情
      axiosApi(
        "/groupon/voucher/selectDetail",
        {
          id: this.$route.query.id,
        },
        (res) => {
          this.form.title = res.data.data.title;
          this.form.remark = res.data.data.remark;
          this.form.amountMoney = res.data.data.amountMoney;
          this.form.num = res.data.data.num;
          this.form.startTime = res.data.data.gmtValidity;
          this.form.grouponName = res.data.data.grouponName;
          this.voucherId = res.data.data.id;
          this.goodsInfo=  res.data.data.goodsInfo;
          this.voucherCustomer();
          if (
            res.data.data.giftNames &&
            Array.isArray(res.data.data.giftNames) &&
            res.data.data.giftNames.length > 0
          ) {
            this.willSelectList = res.data.data.giftNames.map((item) => {
              return { skuName: item };
            });
            this.willAddSend = this.willSelectList;
          }
        },
        1,
        10,
        (res) => {
          this.$message({
            type: "info",
            message: res.data.message,
            duration: 3000,
          });
        }
      );
      
    },
    voucherCustomer(val) {
      // 代金券客户列表
      if (val) {
        this.currentPage = 1;
        return false;
      }
      axiosApi(
        "/groupon/voucherCustomer/selectList",
        {
          voucherId: this.voucherId,
          mobile: this.mobile,
        },
        (res) => {
          this.tableData = res.data.data.elements;
          this.total = res.data.data.totalCount;
        },
        this.currentPage,
        10,
        (res) => {
          this.$message({
            type: "info",
            message: res.data.message,
            duration: 3000,
          });
        }
      );
    },
    //获取团购活动列表
    getGroupList() {
      axiosApi(
        "/groupon/groupon/list",
        {
          statusList: [1, 2],
        },
        (res) => {
          this.activityList = res.data.data;
          this.activityList.unshift({
            id: "",
            grouponName: "全部团购活动",
          });
        }
      );
    },
    handleCurrent(val) {
      this.currentPage = val || 1;
      this.voucherCustomer();
    },
    search() {
      this.voucherCustomer(0);
    },
    dataChange(val) {
      if (!val) {
        this.noChange = false;
        this.form.startTime = null;
      } else {
        this.noChange = true;
        this.activityList.forEach((item) => {
          if (val == item.id) {
            this.form.startTime = new Date(item.gmtEnd);
          }
        });
      }
    },
    goBack() {
      this.$router.push(basePath + "/superGroup/groupSalesManager");
    },
  },
  mounted() {
    this.$nextTick(function () {
      this.getGroupList();
      this.getGiftList();
      if (this.$route.query.id) {
        this.isAdd = false;
        this.getDetail();
      } else {
        this.isAdd = true;
      }

      
    });
  },
};
</script>

<style lang="less">
#voucherDetail {
  .goods-wrap {
    overflow: hidden;
    display: flex;
    .r-goods {
      flex: 1;
      overflow: hidden;
      div {
        font-size: 12px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
  .el-table:before {
    display: none;
  }
  .el-table {
    height: 500px;
    overflow-y: scroll;
  }
  .goods-dialog,
  .order-dialog {
    .el-dialog {
      width: 830px !important;
      max-height: 1500px;
    }
    .table-default .el-button {
      width: auto;
    }
    .table-default.el-table .cell {
      padding: 3px 15px 0;
    }
    .el-dialog__body {
      width: 770px;
      // height: 628px;
      padding: 20px 30px;
    }
    .el-dialog {
      .el-form {
        .el-form-item {
          .el-form-item__label {
            &::before {
              display: none;
            }
          }
        }
      }
    }
  }
  .goods-dialog {
    .el-form .el-input {
      width: 120px;
    }
  }
  .el-form-item__error {
    left: 20px !important;
  }
  .bdt {
    border-top: 1px solid #eee;
  }
  .box-con {
    padding: 20px;
  }
  .button_top {
    width: 238px;
    height: 30px;
    line-height: 30px;
    border: 1px solid #dddddd;
    color: #20a0ff;
  }
  .color_blue {
    color: #20a0ff;
  }
  .input_short_2 {
    width: 150px;
  }
  .input_short_1 {
    width: 120px;
  }
  .icon-24 {
    width: 24px;
    height: 24px;
  }
  .el-table
    .table-default
    .el-table--fit
    .el-table--striped
    .el-table--enable-row-hover
    .el-table--enable-row-transition {
    height: 500px;
    overflow-y: scroll;
  }
  .el-textarea__inner {
    resize: none;
  }
  .el-pagination__jump{
    margin-left: 0px;
  }
}
</style>