<template>
    <div class="clientGrouping pb-20">
        <div class="title-top bg-f">
            分组管理
            <!-- <el-button @click="add" icon="el-icon-plus" class="fr mr-30 mt-14">新增区域</el-button> -->
        </div>
        <div class="bg-f mt-5 content-padding">
            <div class="content">
                <div class="left">
                    <el-tree
                        :data="treeList"
                        @node-click="treeClick"
                        :props="treeProps"
                        node-key="id"
                        :default-expand-all="true"
                        :expand-on-click-node="false"
                    >
                       
                    </el-tree>
                </div>
                <div class="right">
                    <div class="selectTextBox">
											<span>[ {{ selectedText }} ]</span>
                      <div>
												<el-button size="small" @click="dialogTransfer = true">客户迁移</el-button>
												<el-button size="small" @click="delSelect">批量删除</el-button>
												<el-button size="small" @click="addInfo">添加客户</el-button>
											</div>
                    </div>
                    <div class="tableBox pl-20 pr-20">
                        <el-table :data="tableList" border tooltip-effect="dark" stripe class="mt-20 table-default" @selection-change="SelectionChange">
                            <el-table-column type="selection" width="55"></el-table-column>
														<el-table-column prop="storeName" label="客户名称"></el-table-column>
                            <el-table-column prop="loginName" label="客户账号"></el-table-column>
                            <el-table-column prop="groupName" label="状态">
                                <template slot-scope="scope">
                                    {{scope.row.isDisable==1?"禁用":"正常"}}
                                </template>
                            </el-table-column>
														 <el-table-column label="操作">
                                <template slot-scope="{row}">
                                    <el-button size="small" @click="delItem(row.buyerId)">删除</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <div class="pageBox">
                        <el-pagination class="tar" v-if='total>0'
                            :current-page="currentPage"
                            :page-size='10'
                            :total='total'
                            layout="prev, pager, next, jumper"
                            @current-change="handlePageChange">
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>

				<el-dialog title="迁移客户" :visible.sync="dialogTransfer">
					<div style="padding:10px">
						<el-form :model="form" label-width="auto">
								<el-form-item label="原有分组">
									{{selectedText}}
								</el-form-item>
								<el-form-item label="新分组">
									<el-cascader v-model="newId" :options="treeList" :props="treeProps"></el-cascader>
								</el-form-item>
							</el-form>
						</div>
						<span slot="footer" class="dialog-footer">
							<el-button type="primary" @click="submitTransfer">确 定 迁 移</el-button>
							<el-button @click="dialogTransfer = false">取 消</el-button>
						</span>
				</el-dialog>

				<el-dialog title="添加客户" :visible.sync="dialogShow" width="60%">
					<div style="padding:10px">
						<el-form :model="form" :inline="true" label-width="auto">
								<el-form-item label="客户名称">
									<el-input v-model="form.storeName" autocomplete="off"></el-input>
								</el-form-item>
								<el-form-item label="客户账号">
									<el-input v-model="form.loginName" autocomplete="off"></el-input>
								</el-form-item>
								<el-form-item>
									<el-button type="primary" @click="search">查询</el-button>
								</el-form-item>
							</el-form>
							<el-table :data="dialogList" @selection-change="dialogSelect">
								<el-table-column type="selection" width="55"></el-table-column>
								<el-table-column prop="storeName" label="客户名称"></el-table-column>
								<el-table-column prop="loginName" label="客户账号"></el-table-column>
								<el-table-column prop="groupName" label="状态">
										<template slot-scope="scope">
												{{scope.row.isDisable==1?"禁用":"正常"}}
										</template>
								</el-table-column>
							</el-table>
							<el-pagination class="tar" v-if='dialogTotal>0'
									:current-page="dialogPage"
									:page-size='10'
									:total='dialogTotal'
									layout="prev, pager, next, jumper"
									@current-change="dialogPageChange">
							</el-pagination>
						
						</div>
						<span slot="footer" class="dialog-footer">
							<el-button type="primary" @click="submit">确 定</el-button>
							<el-button @click="dialogShow = false">取 消</el-button>
						</span>
				</el-dialog>


    </div>
</template>

<script>
import { axiosApi, basePath } from "../../../api/api"


export default {
    data: function() {
        return ({
						form: {
							storeName: '',
							loginName: '',
							groupId: null,
							unGroupedCustomer: false, // false已分组的客户，true未分组的客户
							sellerId : sessionStorage.getItem('traderId'),
						},
            // 左侧树
            treeList : [],
            // table数据
            tableList : [],
            // 总数量
            total : 0,
            // 当前页数
            currentPage : 1,
            // 当前选中的 文案
            selectedText : '',
            treeProps: {
                value: 'id',
                label: 'name',
                children:'sub',
								emitPath:false
            },
						ids: [], // 选中id
						dialogShow: false,
						dialogList: [],
						dialogTotal: 0,
						dialogPage:1,
						buyerIdList: [],
						dialogTransfer: false,
						newId: '',
        })
    },
    activated() {
        this.getLeftTree();
    },
    methods: {
        // 获取左侧 树
        getLeftTree(){
            // 查询 左侧树
            axiosApi('/trader/customer/group/listTree',{},(res) => {
                this.treeList = this.treeInit(res.data.data);
								this.form.groupId = res.data.data[0].id
								 this.selectedText = res.data.data[0].name;
								this.getTableList();
            });
            // 默认查询 全国区域 右侧表格数据
        },
				treeInit(tree){
					tree.forEach(item=>{
						if(item.sub.length>0){
							this.treeInit(item.sub)
						}else{
							delete item.sub
						}
					})
					return tree
				},
				submitTransfer(){
					axiosApi('/trader/customer/group/transfer',{
							oldId:this.form.groupId,
							newId: this.newId,
							sellerId: this.form.sellerId
						},(res) => {
                if(res.data.code==='000000'){
									
									this.$message({
										type: 'success',
										message: '迁移成功!'
									});
									this.dialogTransfer = false
									this.newId = ''
									this.currentPage = 1;
            			this.getTableList();
								}
            });
				},
        // 获取已分组数据
        getTableList(){
            axiosApi('/trader/customer/listByGroup',{
							groupId:this.form.groupId,
							unGroupedCustomer:false,
							sellerId: this.form.sellerId
						},(res) => {
                if(res.data.data){
                    let data = res.data.data;
                    this.tableList = data.elements;
                    this.total = data.totalCount;
                }else{
                    this.tableList = [];
                    this.total = 0; 
                }
            },this.currentPage,10);
        },
				 // 获取未分组数据
        getDialogList(){
            axiosApi('/trader/customer/listByGroup',{
							storeName: this.form.storeName,
							loginName: this.form.loginName,
							unGroupedCustomer:true,
							sellerId: this.form.sellerId
						},(res) => {
                if(res.data.code==='000000'){
                    let data = res.data.data;
                    this.dialogList = data.elements;
                    this.dialogTotal = data.totalCount;
                }else{
                    this.dialogList = [];
                    this.dialogTotal = 0; 
                }
            },this.dialogPage,10);
        },
				search(){
					this.dialogPage = 1
					this.getDialogList()
				},
				addInfo(){
					this.getDialogList()
					this.dialogShow = true
				},
				// 全选
				SelectionChange(list){
					var ids = list.map(item=>(item.buyerId))
					this.ids = ids
				},
				// 批量删除
				delSelect(){
					this.$confirm('此操作将永久删除所选中客户, 是否继续?', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						this.dleList(this.ids)
					}).catch(() => {
						this.$message({
							type: 'info',
							message: '已取消删除'
						});          
					});
				},
				// 单个客户删除
				delItem(id){
					this.$confirm('此操作将永久删除该客户, 是否继续?', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						this.dleList([id])
					}).catch(() => {
						this.$message({
							type: 'info',
							message: '已取消删除'
						});          
					});
				},
				dleList(ids){
					axiosApi('/trader/customer/group/unBind',{buyerIdList:ids},(res) => {
						if(res.data.code === '000000'){
							this.$message({
								type: 'success',
								message: '删除成功!'
							});
							this.getTableList()
						}
					},this.currentPage,10)
				},
        // 点击左侧树 
        treeClick(data){
						console.log(data)
            this.form.groupId = data.id;
            this.currentPage = 1;
            this.selectedText = data.name;
            this.getTableList();
        },  
        // 点击分页
        handlePageChange(val){
            this.currentPage = val;
            this.getTableList();
        },
				dialogPageChange(val){
					this.dialogPage = val
					this.getDialogList()
				},
				dialogSelect(list){
					var ids = list.map(item=>(item.buyerId))
					this.buyerIdList = ids
				},
				submit(){
					axiosApi('/trader/customer/group/bind',{
						groupId:this.form.groupId,
						buyerIdList:this.buyerIdList
						},(res) => {
						if(res.data.code === '000000'){
							this.$message({
								type: 'success',
								message: '添加成功!'
							});
							this.dialogShow = false
							this.buyerIdList = []
							this.getTableList()
						}
					})
				},

    },
}
</script>

<style lang="less">

.clientGrouping{
    .content-padding{
        padding: 30px 30px 50px;
    }

    .pageBox{
        padding-right: 20px;
        padding-bottom: 20px;
    }
    .content{
        border: 1px solid #ccc;
        height: auto;
        display: flex;
        align-items: stretch;
        min-height: 480px;
        .left{
            border-right: 1px solid #ccc;
            padding: 20px 0;
            width: 230px;
            overflow-x: auto;
            max-height: 628px;
            overflow-y: auto;
        }
        .right{
            width: ~"calc(100% - 231px)";
            .selectTextBox{
                padding: 20px 20px 0 20px;
								display: flex;
								align-items: center;
								justify-content: space-between;
            }
        }
    }
    .el-tree-node__content .handle{
        color: #00a0e9;
        padding-left: 20px;
        opacity: 0;
    }
    .el-tree-node__content:hover .handle{
        opacity: 1;
    }
}

</style>

