<template>
	<div id="groupSet">
		<!-- 头部 -->
		<div class="title-tabs bg-f">
			<ul class="clearfix">
				<template v-for='(tab,index) in dataTabs' class="item">
					<li :type='index' :class="type==index?'active':''" @click='handleTabs'> {{tab}}
						<span :class="type==index?'under-line':''"></span>
					</li>
					<span class="vertical-line"></span>
				</template>
			</ul>
		</div>
		<!-- 基础设置 -->
		<div class="base_box bg-f mt-5 pb-50" v-show="typeName=='基础设置'">
			<div class="content clearfix pt-20 pl-30 pr-30">
				<div class="item">
					<label class="fl">商家商品占比</label>
					<div class="item_c">
						<p>
							<el-input :maxlength="6" placeholder="请输入" v-model="form.goodsRatio"></el-input>&nbsp;%</p>
						<span>商家发布团购活动时，商家商品的数量=团购活动商品的总数量*商家商品占比（特殊商品的数量不计算在团购活动中）</span>
					</div>
				</div>
				<div class="item">
					<label class="fl">保证金解冻天数</label>
					<div class="item_c">
						<p>
							<el-input :maxlength="2" placeholder="请输入" v-model="form.settleDays"></el-input>&nbsp;天</p>
						<span>团购活动结束且商家的最后一笔订单结束+N天，系统返还冻结的商家保证金到商家的可用保证金账户</span>
					</div>
				</div>
				<div class="item">
					<label class="fl">客服联系电话</label>
					<div class="item_c">
						<p>
							<el-input :maxlength="15" placeholder="请输入客服联系电话" v-model="form.contactMobile"></el-input>
						</p>
						<span>设置客服联系电话，商家有问题可以随时联系</span>
					</div>
				</div>
				<el-button class="fl ml-50 mt-30" type='danger' @click="handleSet">确认提交</el-button>
			</div>
		</div>
		<!-- 运费设置 -->
		<div class="freight bg-f mt-5" v-show="typeName=='运费设置'">
			<div id="freight-price">
				<div class="pl-30 pt-30 pb-50 bg-f mt-5">
					<el-form ref="form" :model="temForm" :rules="rules">
						<el-form-item label="默认运费模版">
							<el-select v-model="temForm.templateId" placeholder="请选择默认运费模版">
								<el-option v-for="i in options" :label="i.name" :value="i.id" :key="i.id"></el-option>
							</el-select>
							<el-button type="text" @click="lookTemplate">查看</el-button>
						</el-form-item>
						<span class="color-9">（改变默认运费模版会覆盖所有超团未设置模版的商品运费，请谨慎使用）</span>

						<el-form-item class="mt-20">
							<el-button class="mt-50 ml-50" type="primary" @click="submit" :loading="btnLoading">确定</el-button>
						</el-form-item>
					</el-form>
				</div>

			</div>
		</div>
		<!-- 保证金说明设置 -->
		<div class="freight bg-f mt-5 pb-50" v-show="typeName=='保证金说明设置'">
			<div class="content pt-30 pl-30 pr-30">
				<ueditor id='own' ref="ueeditor" v-model="form.depositDesc"></ueditor>
				<el-button class="mt-30" type='danger' @click="handleSet">确认提交</el-button>
			</div>
		</div>

		<!-- 导出 -->
		<form ref="downClientForm" action='/sunrise-gateway/export/foundation/freightSettle/list' style="display: none">
			<input type="hidden" name="modelName" v-model="a">
			<input type="hidden" name="fileName" v-model="b">
			<input type="hidden" name="startRow" v-model="d">
			<input type="hidden" name="startDate" v-model="startDate">
			<input type="hidden" name="endDate" v-model="endDate">
		</form>
		<!-- 下载导入失败文件信息 -->
		<form ref="downClientErrForm" action='/sunrise-gateway/export/foundation/batchImport/failed' style="display: none">
			<input type="hidden" name="modelName" v-model="e">
			<input type="hidden" name="fileName" v-model="f">
			<input type="hidden" name="key" v-model="g">
		</form>
	</div>
</template>

<script>
	import axios from 'axios';
	import { axiosApi, gotoLoginPage, basePath, baseURI } from "~/api/api";
	export default {
		data() {
			return {
				dataTabs: ['基础设置', '运费设置', '保证金说明设置'],
				type: '0', //头部状态
				typeName:'',
				form: {
					goodsRatio: null, //商家商品占比
					settleDays: 1, //商品订单结束天数
					depositDesc: null, //商家保证金说明
					depositSwitch: false, //商家保证金开关
					contactMobile: null, //子公司联系电话
				},

				// 以下为运费粘贴代码
				a: 'foundation.freightSettle.list',
				b: '运费月结账单.xls',
				d: 1,
				e: 'importCustomer.failedRecord',
				f: '导入失败账单列表信息.xls',
				g: null,
				pickerOptions: {
					disabledDate(time) {
						return true;
					}
				}, //禁选未来时间
				formInlineData: {
					startTime: "",
					endTime: "",
					maxTime: "",
					minTime: ""
				},
				startDate: "",
				endDate: "",
				total: 0,
				currentPage: 1,
				arrInfo: [], //所有年月
				formInfo: {}, //单月信息
				//				运费设置数据
				temForm: {
					templateId: '' //模版id
				},
				rules: {
					region: [{
						required: true,
						message: '请选择模板',
						trigger: 'change'
					}]
				},
				options: [],
				btnLoading: false
			}
		},
		filters: {
			time(value) { //运费使用方法
				let myDate = new Date(value);
				let year = myDate.getFullYear();
				let month = myDate.getMonth() + 1;
				return year + '年' + (month >= 10 ? month : '0' + month) + '月';
			}
		},
		watch: {
			formInlineData: { //运费使用方法
				handler: function(item) {
					this.pickerOptions.disabledDate = function(time) {
						return time.getTime() < item.minTime || time.getTime() > item.maxTime
					}
					//开始时间
					if(item.startTime) {
						this.startDate = item.startTime.getTime()
					} else {
						this.startDate = ""
					}
					if(item.endTime) {
						this.endDate = item.endTime.getTime()
					} else {
						this.endDate = ""
					}
				},
				deep: true
			}
		},
		//进入加载
		activated() {
			this.$nextTick(function() {
//				if(this.type == 0) {
//					this.getSetList(0)
//				}
						this.dataTabs = []
						this.showButton("BASE_SET",140024) && this.dataTabs.push('基础设置')
						this.showButton("FREIGHT_SET",140024) && this.dataTabs.push('运费设置')
						this.showButton("BOND_EXPLAIN_SET",140024) && this.dataTabs.push('保证金说明设置')
						if(this.dataTabs[0] == '基础设置'){
							this.type = 0
							this.typeName ='基础设置'
							this.getSetList(0)
						}else if(this.dataTabs[0] == '运费设置'){
							this.type = 0
							this.typeName ='运费设置'
							this.achieve()
						}else{
							this.type = 0
							this.typeName ='保证金说明设置'
							this.getSetList(2)
						}
			})
		},
		methods: {
			handleTabs(e) { //头部切换状态
				// if(e.target.type) this.type = e.target.type // 改变 type的值
//				dataTabs: ['基础设置', '运费设置', '保证金说明设置'],
				if(e.target.attributes.type){
					this.type = e.target.attributes.type.value
					this.typeName = e.target.attributes.type.ownerElement.innerText.trim()
				} 
				if(this.typeName == '基础设置') {
					this.getSetList(0)
				} else if(this.typeName == '运费设置') {
					this.achieve()
				} else if(this.typeName == '保证金说明设置') {
					this.getSetList(2)
				}
			},
			getSetList(val) { //查询基础设置
				const body = {}
				if(val == 0) {
					body.typeList = ['goodsRatio', 'settleDays', 'depositSwitch', 'contactMobile']
				} else if(val == 2) {
					body.typeList = ['depositDesc']
				}
				axiosApi('/groupon/baseSettings/list', body, (res) => {
					if(res.data.data) {
						res.data.data.forEach(item => {
							if(item.dataType == 'goodsRatio') {
								this.form.goodsRatio = item.dataValue
							} else if(item.dataType == 'settleDays') {
								this.form.settleDays = item.dataValue
							} else if(item.dataType == 'depositDesc') {
								this.form.depositDesc = item.dataValue ? item.dataValue : ''
							} else if(item.dataType == 'depositSwitch') {
								this.form.depositSwitch = item.dataValue == 'enable' ? true : false
							} else if(item.dataType == 'contactMobile') {
								this.form.contactMobile = item.dataValue
							}
						})
					}
				})
			},
			handleSet() {
				if(this.type == 0) {
					let reg = /^100$|^(\d|[1-9]\d)$/ //0-100,正整数
					let reg1 = /^(((\d|[1-9]\d)(\.\d{1,2})?)|100|100.0|100.00)$/ //0-100,允许有两位小鼠
					if(this.form.goodsRatio != null && !reg1.test(this.form.goodsRatio)) {
						this.$message({
							type: 'info',
							message: '商家商品占比请输入0-100的数字',
							duration: 2000
						})
						return false
					} else if(this.form.settleDays == null) {
						this.$message({
							type: 'info',
							message: '保证金解冻天数不能为空',
							duration: 2000
						})
						return false
					} else if(this.form.settleDays != null && !/^(([12]?[1-9](\.[1-9]{1,2})?)|30)$/.test(this.form.settleDays)) {
						this.$message({
							type: 'info',
							message: '保证金解冻天数请输入1-30的正整数',
							duration: 2000
						})
						return false
					}
				}

				this.form.depositDesc = this.$refs.ueeditor.getContent();
				const body = {
					goodsRatio: this.form.goodsRatio, //商家商品占比
					settleDays: this.form.settleDays, //商品订单结束天数
					depositDesc: this.form.depositDesc, //商家保证金说明
					depositSwitch: this.form.depositSwitch ? 'enable' : 'disable', //商家保证金开关
					contactMobile: this.form.contactMobile, //子公司联系电话
				}
				axiosApi('/groupon/baseSettings/iu', body, (res) => {
					this.$message({
						type: 'success',
						message: '设置成功',
						duration: 1000
					})
					if(this.type == 0) {
						this.getSetList(0)
					} else if(this.type == 2) {
						this.getSetList(2)
					}
				})
			},

			//以下为运费的方法 （之前的版本，不用了，暂时保留）
			handUpSuccess(res, file, fileList) {
				this.$loading().close()
				switch(res.code) {
					case "000000": //系统交易成功
						let h = this.$createElement;
						if(res.data.failedRows == 0) { //失败条数为0
							this.$msgbox({
								title: '提示信息',
								message: h('p', {
									style: 'text-align:center'
								}, [
									h('p', null, '导入已完成！'),
									h('p', null, `共导入信息${res.data.successedRows}条，全部成功！`)
								]),
								showCancelButton: false,
								confirmButtonText: '确定',
								beforeClose: (action, instance, done) => {
									if(action === 'confirm') {
										done()
										this.freightSettleMonthAll()
									} else {
										done()
									}
									delete instance.$slots.default;
								}
							})
						} else {
							this.g = res.data.failedRowsKey;
							this.$msgbox({
								title: '提示信息',
								message: h('p', {
									style: 'text-align:center'
								}, [
									h('p', null, '导入已完成！'),
									h('p', null, `共导入信息${res.data.successedRows}条，失败${res.data.failedRows}条，点击下载查看失败原因！`)
								]),
								showCancelButton: true,
								confirmButtonText: '下载失败文件',
								cancelButtonText: '我不想知道',
								beforeClose: (action, instance, done) => {
									if(action === 'confirm') {
										this.$refs.downClientErrForm.submit();
										done()
									} else {
										done()
									}
									delete instance.$slots.default;
								}
							})
						}
						break;
					case "999999": //系统异常
						this.$alert(res.message, '提示信息', {
							confirmButtonText: '确定'
						});
						break;
					case "AUTH_001": //用户未登陆
						gotoLoginPage();
						break;
					case "AUTH_002": //用户无权限
						this.$alert(res.message, '提示信息', {
							confirmButtonText: '确定'
						});
						break;
					case "LOGIN_005": //用户已禁用
						this.$alert(res.message, '提示信息', {
							confirmButtonText: '确定'
						});
						break;
					case "LOGIN_006": //用户session失效
						gotoLoginPage();
						break;
					default:
						this.$alert(res.message, '提示信息', {
							confirmButtonText: '确定'
						});
						break;
				}

			},
			handUpError(err, file, fileList) {
				this.$loading().close()
				this.$message.error('导入失败！');
			},
			handUpBefore(file) {
				this.$loading({
					fullscreen: true,
					lock: true,
					text: '正在导入客户...'
				})
				return true
			},
			handDown() {
				this.$refs.downClientForm.submit();
			},
			handlerSeach() {
				this.currentPage = 1
				this.freightSettleMonthAll()
			},
			//所有月份运费结算列表						
			freightSettleMonthAll() {
				let a = this.formInlineData.startTime
				let b = this.formInlineData.endTime
				a && (a = a.getTime())
				b && (b = b.getTime())
				if(a && b && a > b) {
					MessageBox.alert("开始时间不得大于结束时间", '提示信息', {
						confirmButtonText: '确定'
					});
					return false;
				}
				axiosApi("/foundation/freightSettleMonth/all", {
					traderId: sessionStorage.traderId,
					startTime: a,
					endTime: b,
				}, res => {
					this.arrInfo = res ? res.data.data.elements : []
					this.total = res.data.data.totalCount
					this.arrInfo.length > 0 && this.arrInfo.forEach((item, index) => {
						if(index == 0) {
							this.$set(item, "checked", true)
						} else {
							this.$set(item, "checked", false)
						}
					})
					this.arrInfo.length > 0 && this.freightSettleMonthGet(this.arrInfo[0].gmtPaid)
				}, 1, 10)
			},
			//单月份运费结算列表						
			freightSettleMonthGet(payload) {
				axiosApi("/foundation/freightSettleMonth/get", {
					traderId: sessionStorage.traderId,
					time: payload
				}, res => {
					this.formInfo = res.data.data
				})
			},
			spreadClick(item) {
				this.arrInfo.forEach(i => {
					if(i == item) {
						i.checked = !i.checked
					} else {
						i.checked = false
					}
				})
				this.freightSettleMonthGet(item.gmtPaid)
			},
			//运费设置方法（新版本）
			//获取原始数据
			achieve() {
				axiosApi('/foundation/fare/templateSelectList', {
					traderId: sessionStorage.traderId,
					noDetails: true,
					businessType:"groupon"
				}, res => {
					if(res.data.data.elements) {
						this.options = res.data.data.elements.map(v => {
							return {
								id: v.id,
								name: v.name,
								isDefault: v.isDefault
							}
						})
						for(let i = 0; i < this.options.length; i++) {
							if(this.options[i].isDefault) {
								this.temForm.templateId = this.options[i].id
								return false;
							}
						}
					}
				}, 1, 100, err => {
					this.$message.error(err.data.message)
				})
			},
			lookTemplate() {
				if(!this.temForm.templateId) {
					return false;
				}
				window.open(basePath + '/freightHandle?type=1&id=' + this.temForm.templateId, '_blank')
			},
			submit() {
				if(!this.temForm.templateId) {
					return false;
				}
				this.$confirm('该模板会覆盖超团中所有商品，是否确定?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.btnLoading = true;
					axiosApi('/foundation/fare/setTraderDefault', {
						fareId: this.temForm.templateId,
						businessType:"groupon"
					}, res => {
						this.btnLoading = false;
						this.$message({
							message: '默认模板设置成功！',
							type: 'success',
							duration: 2000
						})
					}, 1, 10, err => {
						this.btnLoading = false;
						this.$message.error(err.data.message)
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消'
					});
				});

			}
		}
	}
</script>

<style lang="less">
	#groupSet {
		.content {
			.item {
				margin-top: 30px;
				label {
					text-align: right;
					margin-top: 5px;
					width: 140px;
					display: inline-block;
					font-size: 16px;
				}
				.item_c {
					width: 760px;
					margin-left: 25px;
					display: inline-block;
					p {
						margin-bottom: 7px;
						label {
							text-align: left;
						}
					}
					span {
						color: #999;
						font-size: 12px;
					}
				}
			}
		}
		#own {
			width: 800px !important;
		}
		// 运费模块样式
		#freight-price {
			background-color: #fff;
			.el-form-item__label {
				width: 100px;
			}
		}
	}
</style>