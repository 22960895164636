<template>
  <div class="update_version">
    <div class="title-top bg-f clearfix">版本升级</div>

    <div class="content p-30 bg-f mt-5">
      <div class="color-danger">尊敬的用户，为了提高用户体验，您现在使用的是简易版久集。如需得到更完善的功能，请升级版本，升级版功能包括：</div>
      <div class="agree_item_content">
        <div class="mr-100">
          <h4>1.保证金管理</h4>
          <p>开通后销售员可缴纳保证金至酒店账户，
            同时会获得保证金*酒店设置的比例得到授信额度，
            销售员代下单时既可用授信支付。</p>
        </div>
        <img src="../../../assets/superc/update-bond.png" alt="">
      </div>

      <div class="agree_item_content">
        <img src="../../../assets/superc/update-special.png" alt="">
        <div class="ml-100">
          <h4>2.特殊商品审核</h4>
          <p>茅台、五粮液等畅销品会限量供应，
            该功能应用后销售员购买特殊商品时需要酒店管理员审核后才可销售。</p>
        </div>

      </div>

      <div class="agree_item_content">
        <div class="mr-100">
          <h4>3.协议客户</h4>
          <p>目前版本只支持线上支付（微信或者支付宝)，
            升级后可对客户设置账期，该客户下单后即可使用账期支付。</p>
        </div>
        <img src="../../../assets/superc/update-agreement.png" alt="">
      </div>

      <el-button type="danger" class="open_btn" @click="updateCC">立即升级</el-button>
    </div>


  </div>
</template>

<script>
  import {axiosApi, basePath} from "~/api/api";

  export default {
    data () {
      return {
        btnLoading: false
      }
    },
    methods: {
      updateCC () {
        const h = this.$createElement;
        let _this = this;
        this.$msgbox({
          title: '温馨提示',
          message: h('p',{ style: "text-align:center"} , '您确定要升级吗？'),
          showCancelButton: true,
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          beforeClose: (action, instance, done) => {
            if (action === 'confirm') {
              instance.confirmButtonLoading = true;
              instance.confirmButtonText = '执行中...';
              axiosApi('/superc/web/simpleHotelUpgrade/update', {
                traderId: sessionStorage.traderId
              }, res => {
                done();
                _this.$message({
                  type: 'success',
                  message: '恭喜您，升级成功，立即体验吧！',
                  duration: 1500
                })
                setTimeout(() => {
                  instance.confirmButtonLoading = false;
                  _this.$router.push({path: basePath + '/accountCenter'})
                }, 1500)
              },1,10,err => {
                done();
                setTimeout(() => {
                  instance.confirmButtonLoading = false;
                  _this.$message.error(err.data.message)
                }, 300)
              })
            } else {
              done();
            }
          }
        }).then(action => {
        });
      }
    },
    created () {

    }
  }
</script>

<style lang="less">
  .update_version {

    .agree_item_content {
      padding: 50px 200px 0px 150px;
      display: flex;
      h4 {
        line-height: 46px;
        font-size: 16px;
        color: #333;
      }
      & > div {
        width: 350px;
        p {
          color: #666;
          line-height: 17px;
        }
      }
    }
    .open_btn {
      margin: 50px 0px 50px 150px;
    }
  }
</style>
