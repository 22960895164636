<template>
    <div id="wrap">
        <header>
            <div class="logo-con"></div>
            <div class="nav-menu">
                <el-popover
                        placement="right"
                        width="550"
                        offset="100"
                        v-model="showAllNav"
                        trigger="click">
                    <div class="nav-menu-list"  >
                        <div class="nav-menu-list-title">
                            <strong class="color-333">导航管理</strong>
                        </div>
                        <ul class="nav-menu-list-box">
                            <li>
                                <dl v-for="nav in allNav" :key="nav.id">
                                    <dt> {{nav.text}} <i class="iconfont icon-right font-12 ml-5 "></i></dt>
                                    <dd>
                                        <span v-for="(item,i) in nav.sub" :key="'aa'+i" @click="gotoUrl(item)">
                                            {{item.text}}
                                        </span>
                                    </dd>
                                </dl>
                            </li>
                        </ul>
                    </div>
                    <div slot="reference" class="more-menu"></div>
                </el-popover>
                <top-nav></top-nav>
            </div>
            <div class="operate">
                <span class="home" @click="goNew"></span>
                <span class="message" v-popover:popoverOne>
                    <el-badge :value="messageNum" :max="99" style="position:absolute;left:80px;top:-15px" :hidden="messageNum==0">
                        <!-- <el-badge is-dot style="position:absolute;left:88px;top:-5px" :hidden="messageNum==0"> -->
                    
                </el-badge>
                </span>
                
                
                <span class="mine" v-popover:popoverTwo></span>
            </div>
        </header>
        <div class="con">
            <aside>
                <aside-nav></aside-nav>
            </aside>
            <div class="right-con">
                <tag-nav></tag-nav>
                <div class="main" ref="main">
                    <div class="main-item">
                        <all-keep-alive>
                            <router-view v-if="$route.meta.keepAlive" :key="$route.fullPath"></router-view>
                        </all-keep-alive>
                        <all-keep-alive :include="keepAliveList">
                            <router-view v-if="!$route.meta.keepAlive" :key="$route.fullPath"></router-view>
                        </all-keep-alive>

                    </div>

                </div>

            </div>
        </div>
        <el-popover ref="popoverOne" placement="bottom" width="400" trigger="hover">
            <div class="messageInfo">
                <div class="title">
                    <div :style="clicked===0?'color: #E94A13': ''" @click="lookMessage(0)">商户通知</div>
                    <div :style="clicked===1?'color: #E94A13': ''" @click="lookMessage(1)">活动通知</div>
                    <div :style="clicked===2?'color: #E94A13;border:none': 'border:none'" @click="lookMessage(2)">订单信息</div>
                </div>
                <div v-if="messageList.length!=0">
                    <div class="messageContent cursor" v-for="(item,index) in messageList" :key="'e'+index"  @click="lookMore('/search1/messageCenter')">
                        <div class="messageTrue">{{item.noticeTitle}}</div>
                        <div class="messageUnread">未读</div>
                    </div>
                </div>
                <div style="text-align:center;height:200px;line-height: 200px" v-else>暂无数据</div>
                <div class="messageFooter" @click="lookMore('/search1/messageCenter')" v-if="messageList.length!=0">
                    查看更多
                </div>
            </div>
        </el-popover>
        <el-popover ref="popoverTwo" placement="bottom" width="350" trigger="hover">
            <div class="accountInfo">
                <div class="accountTop">
                    <div class="accountTitle">账户信息</div>
                    <div class="quit">
                        <span @click="lookAccount('/customer/accountInfo')">账户管理</span><span class="quitBtn" @click="quit">退出</span>
                    </div>
                </div>
                <div class="accountItem" style="background: #F7F7F7;margin-top: 10px">
                    <div class="contentTitle">所在部门</div>
                    <div class="contentInfo">{{basicLoginInfo.departmentName}}</div>
                </div>
                <div class="accountItem">
                    <div class="contentTitle">本次登录</div>
                    <div class="contentInfo">{{basicLoginInfo.gmtLogin | time}}</div>
                </div>
                <div class="accountItem" style="background: #F7F7F7">
                    <div class="contentTitle">登录地区</div>
                    <div class="contentInfo">{{basicLoginInfo.clientIp}}</div>
                </div>
            </div>
        </el-popover>

         <div class="notice" v-show="showNotice">
          <!-- 通知栏组件 -->
          <textScroll @isNotice="isNotice" />  
          <i style="font-size:20px" class="el-icon-close" @click="showNotice=false"></i>
        </div>
    </div>
</template>

<script>
    import { allKeepAlive } from 'all-keep-alive'
    import asideNav from './asideNav'
    import topNav from './topNav'
    import tagNav from './tagNav'
    import { mapState , mapMutations , mapActions} from 'vuex';
    import {axiosApi,pushOrGotoPageByWholeUrl,gotoLoginPage,basePath,baseURI,checkGotoSameHost} from '~/api/api';
    import { getNotice, getActivityPage, getOrderNoticePage } from "~/api/messageCenter";
    import { getUnReadCount } from "~/api/publicInfo";
    import textScroll from './textScroll'
    export default {
        name: "admin",
        components:{
            asideNav,
            topNav,
            tagNav,
            allKeepAlive,
            textScroll
        },
        data() {
            return {
                showNotice: true,
                showAllNav:false,
                clicked: 0,
                messageList: [],
                isExist:false,
                messageNum:0,
                basicLoginInfo:{},
                keepAliveList:[]
            }
        },
        computed:{
            ...mapState('nav',['topNav','allNav','tagNav','jusAllNav']),
        },
        created(){
            this.getUserInfo()
        },
        mounted(){
            this.getInfo()
            this.getMessageList()
            this.getUnReadCount()
        },
        watch:{
            $route: {
                handler: function(val){
                    let main = this.$refs.main
                    main.scrollTo(0,0)
                },
                deep: true
            },
            '$store.state.nav.tagNav':{
                handler: function(val){
                    let arr= []
                    val.forEach(item=>{
                        arr.push(item.url)
                    })
                    this.keepAliveList = arr
                },
                deep: true
            }
        },
        methods: {
            ...mapMutations('nav',['changeTagNav','changNowNav','addUserNav']),
            getUnReadCount(){
                getUnReadCount({employeeId: sessionStorage.id}).then(res=>{
                    this.messageNum = res.allUnReadCount //记录未读信息的个数
                }).catch(err=>{

                })
            },
            isNotice(value){
               // 判断通知栏是否显示
               this.showNotice = value
            },
            getInfo() {
                axiosApi(
                    "/trader/manyStoresHomePage/select",
                    {loginName: sessionStorage.loginName},
                    (res) => {
                        res.data.data.list.forEach((item, index) => {
                            if (item.traderId == sessionStorage.traderId) {
                                sessionStorage.setItem('storeInfo',JSON.stringify(item));
                            }
                        })
                    })
            },
            getUserInfo() {
                const body = {
                    "loginName": sessionStorage.loginName,
                    appId: 2
                }
                axiosApi("/foundation/employee/selectOneByLoginName", body, (res) => {
                    let a = res.data.data;
                    this.basicLoginInfo= {
                        departmentName: a.departmentName,
                        clientIp: a.clientIp,
                        gmtLogin: a.gmtLogin
                    }
                })
            },
            lookMessage(num) {
                this.clicked = num;
                this.getMessageList()
            },
            goNew(){
                this.$router.push({
                    path: '/customer/shop/shopIndex'
                })
            },
            quit(){
                this.$confirm("您确定要退出么？", "提示信息", {
                    confirmButtonText: "确定",
                    type: "warning",
                })
                    .then(() => {
                    axiosApi(
                        "/employee/logout",
                        {},
                        (res) => {
                            gotoLoginPage();
                        }
                    );
                    })
                    .catch((res) => {
                        console.log(res)
                    this.$message({
                        type: "info",
                        message: "已取消",
                        duration: 1000,
                    });
                    });
            },
            gotoUrl(val){
                if(checkGotoSameHost(val.url, val.wholeUrl)){
                    let arr = this.$deepClone(this.tagNav)
                    this.addUserNav(val.id)
                    arr.push(val)
                    this.changeTagNav(arr)
                    this.changNowNav(val)
                    this.showAllNav = false
                }
                pushOrGotoPageByWholeUrl(val.url, val.wholeUrl);
            },
            lookMore(url){
                this.$router.push({
                    path:basePath+url,
                    query: {
                        type: this.clicked
                    }
                })
            },
            lookAccount(url){
                this.isExist = false;
                this.confirmJurisdiction(this.jusAllNav,url);
                if(this.isExist){
                    this.$router.push({
                        path:url,
                    })
                }else {
                    this.$message({
                        type: "info",
                        message: "您没有访问权限",
                        duration: 1000,
                    })
                }
                
            },
            goAccountInfo(){

            },
            confirmJurisdiction(arr,url){
                arr.some(item=>{
                    if(item.url == url){
                        this.isExist = true;
                        return true;
                    }else {
                        if(item.sub.length != 0){
                            this.confirmJurisdiction(item.sub,url)
                        }
                    }
                })
            },
            getNotice(data){
                getNotice(data).then(res=>{
                    this.messageList = res.elements ? res.elements : [];
                }).catch(err=>{

                })
            },
            getActivityPage(data){
                getActivityPage(data).then(res=>{
                    this.messageList = res.elements ? res.elements : [];
                }).catch(err=>{

                })
            },
            getOrderNoticePage(data){
                getOrderNoticePage(data).then(res=>{
                    this.messageList = res.elements ? res.elements : [];
                }).catch(err=>{

                })
            },
            getMessageList(){
                let body = {
                    read: 0,
                    pageIndex:1,
                    pageSize:5,
				};
                if(this.clicked==1){
                    // 活动通知
                    this.getActivityPage(body)
                }else if(this.clicked == 2){
                    // 订单通知
                    this.getOrderNoticePage(body)
                }else{
                    // 商户通知
                    this.getNotice(body)
                }
            }
        }
    }
</script>

<style lang="less">
    #wrap{
        position: relative;
        .notice{
          position:absolute;
          top:65px;
          right: 24px;
          display: flex;
          align-items: center;
          background: #fffbe8;
        }
        header{
            padding-right: 45px;
            background-color: #ffffff;
            height: 60px;
            display: flex;
            align-items: center;
            box-shadow:0px 2px 4px 0px rgba(222,222,222,0.5);
            .logo-con{
                width: 295px;
                height: 100%;
                background: url("../assets/nav/logo.png") center center no-repeat;
                background-size: 190px 39px;
            }
            .nav-menu{
                flex: 1;
                width: 0;
                display: flex;
                align-items: center;
                .more-menu{
                    cursor: pointer;
                    margin-right: 28px;
                    width: 18px;
                    height: 17px;
                    background: url("../assets/nav/more_menu.png") center center no-repeat;
                    background-size: contain;
                }
            }
            .operate{
                width: 160px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                position:relative;
                span{
                    cursor: pointer;
                    width: 21px;
                    height: 21px;
                    background-size: contain;
                    background-position: center;
                    background-repeat: no-repeat;
                    &.home{
                        background-image: url("../assets/nav/index.png");
                    }
                    &.message{
                        background-image: url("../assets/nav/message.png");
                    }
                    &.mine{
                        background-image: url("../assets/nav/mine.png");
                    }
                }
            }
        }
        .con{
            display: flex;
            overflow: auto;
            aside{
                width: 200px;
                min-width: 200px;
                position: relative;
                background-color: #2B2C4B;
            }
            .right-con{
                padding: 0 24px;
                background-color: #EEEEEE;
                flex: 1;
                width: 0;
                display: flex;
                flex-direction: column;
                height: calc(100vh - 60px);
                >.main{
                    overflow: auto;
                    flex: 1;
                    display: flex;
                    .main-item{
                        width: 0;
                        min-width: 1000px;
                        flex: 1;
                    }
                    /*-ms-overflow-style: none;*/
                    /*overflow: -moz-scrollbars-none;*/
                    /*&::-webkit-scrollbar {*/
                        /*width: 0 !important*/
                    /*}*/
                }
            }
        }
    }
    .nav-menu-list{
        padding: 6px 8px;
        .nav-menu-list-title{
            border-bottom: 1px solid #EFEFEF;
            padding-bottom: 15px;
            height: 25px;
            display: flex;
            align-items: center;
        }
        .nav-menu-list-box{
            padding: 10px 0;
            max-height: 500px;
            li{
                line-height: 40px;
                dl{
                    display: flex;
                    font-size: 12px;
                    margin-bottom: 15px;
                    dt{
                        width: 73px;
                        height: 25px;
                        text-align: center;
                        line-height: 25px;
                        border-radius: 12.5px;
                        margin-right: 15px;
                        background-color: #2B2C4B;
                        color: #ffffff;
                    }
                    dd{
                        flex: 1;
                        display: flex;
                        flex-wrap: wrap;
                        padding: 1px 0;
                        span{
                            flex-shrink: 0;
                            margin: 0 10px 10px 0;
                            padding: 0 7px;
                            height: 23px;
                            border-radius: 11.5px;
                            text-align: center;
                            line-height: 23px;
                            color: #6F6F6F;
                            &:hover{
                                background-color: #FB6E48;
                                color: #ffffff;
                            }
                        }
                    }
                }
            }
        }
    }
    .messageInfo {
        width: 100%;
        .title  {
            display: flex;
            border-bottom: 1px solid #e4e4e4;
            padding-bottom: 10px;
            div {
                width: 132px;
                border-right: 1px solid #e4e4e4;
                text-align: center;
                cursor: pointer;
            }
        }
        .messageContent {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px 10px 0 10px;
            .messageTrue {
                width: 90%;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
            .messageUnread {
                color: #E94A13;
            }
        }
        .messageFooter {
            margin-top: 10px;
            border-top: 1px solid #e4e4e4;
            line-height: 30px;
            text-align: center;
            width: 100%;
            height: 30px;
            padding-top: 10px;
            cursor: pointer;
        }
    }
    .accountInfo {
        .accountTop {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .accountTitle {
                font-size: 16px;
                font-weight: bold;
            }
            .quit {
                span {
                    color: #E94A13;
                    padding: 0 10px;
                    cursor: pointer;
                    &.quitBtn {
                        border-left: 1px solid #e4e4e4;
                    }
                }
            }
        }
        
        .accountItem {
            display: flex;
            height: 36px;
            line-height: 36px;
            .contentTitle {
                width: 70px;
            }
            .contentInfo {
                width: 320px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }
    }
</style>