<template>
	<!--特殊商品订单订单-->
	<div id="specialGoodsOrder">
        <div class="title-top bg-f">特殊商品订单审核</div>
		<div class="content bg-f pl-30 pr-30 mt-5 pb-40">
			<div style="position: relative;">
				<el-tabs v-model="type" @tab-click="handleTabs">
					<el-tab-pane :label="item" :name="index + ''" v-for="(item, index) in orderTabs" :key="index"></el-tab-pane>
				</el-tabs>
				<!--待审核标记-->
				<el-badge class="notPayCountmark" :max="99" :value="bageCount" :hidden="bageCount == 0"/>
			</div>
			<div class="order-list" >
				<el-checkbox v-model="checkAll" @change="handleCheckAllChange" class="checkedAll"></el-checkbox>
				<el-table>
					<el-table-column label='' width="50"align="center"></el-table-column>
					<el-table-column label='商品' min-width="72" align="center"></el-table-column>
					<el-table-column label='单价' min-width="30"align="center"></el-table-column>
					<el-table-column label='数量' min-width="30"align="center"></el-table-column>
					<el-table-column label='销售员' min-width="30"align="center"></el-table-column>
					<el-table-column label='积分' min-width="30"align="center"></el-table-column>
					<el-table-column label='客户' min-width="30"align="center"></el-table-column>
					<el-table-column label='订单状态' min-width="30"align="center"></el-table-column>
					<el-table-column label='审核状态' min-width="30"align="center"></el-table-column>
					<el-table-column label='订单金额' min-width="30"align="center"></el-table-column>
					<el-table-column label='操作' align="center" min-width="30"></el-table-column>
				</el-table>
				<div class="table-item-default tac" v-if="ordersListResVo.length<=0">
					<img src="../../../assets/empty.png" alt="" />
					<div class="color-danger mt-30">
						<router-link :to="{path:'/customer/shop/shopIndex'}"><span class="color-danger"> 啥都没有，去首页看看 ></span></router-link>
					</div>
				</div>
				<div v-else>
					<el-checkbox-group v-model="checkOrder" @change="handleCheckChange">
						<div class="table-item mt-10" v-for='(order, index) in ordersListResVo' :key='index'>
							<div class="clearfix table-item-top lh-20 bbd bg-e" v-if="order.adjustingGoods == 1">
								<el-badge value="调货" class='fl ml-70 my-badge'></el-badge>
								<span class='fl ml-10'>{{order.headName}}</span>
							</div>
							<div class="clearfix table-item-top lh-20 bbd bg-e">
								<el-checkbox class="fl" :label="order"></el-checkbox>
								<span class='fl'>c端订单:{{order.orderSn}}</span>
								<span v-if="order.parentSn && type == 1" class='fl ml-20' @click="purchaseOrderDetail(order.parentSn)">采购单:<span class="color-blue">{{order.parentSn}}</span></span>
								<span class='fl color-6 ml-30'>下单时间:{{order.gmtCreate | time}}</span>
								<!-- <span class='fr color-6 mr-30' v-if="order.orderStatus==1">还剩<span class="color-danger">{{order.remainAutoCancelTime}}</span>自动取消</span> -->
							</div>
							<div class="table-item-content clearfix bbd" style="display: flex;">
								<div class=" clearfix" style="flex: 5;box-sizing: border-box;">
									<!--商品清单-->
									<div class="bb clearfix " v-for="orderItem in order.itemList" :key="orderItem.id" style="display:flex;padding: 20px 0 20px 20px;">
										<div class="fl" style="flex: 3;">
											<img v-if="orderItem.fileUrl" :src="orderItem.fileUrl" class="fl mr-20" />
											<img v-else src="../../../assets/goods.jpg" class="fl mr-20" />
											<p>{{orderItem.skuName}}</p>
											<p class="color-9">规格:1*{{orderItem.specInfoNum}}</p>
										</div>
										<div class="fl tac " style="flex: 1;">¥{{orderItem.discountPrice | fmoney}}</div>
										<div class="fl tac " style="flex: 1;">{{orderItem.num}}{{orderItem.unit}}</div>
									</div>
								</div>
								<div class="bl bb tac pt-20" style="flex: 1;word-break: break-all">{{order.storeName}}</div>
								<div class="bl bb tac pt-20" style="flex: 1;word-break: break-all">{{order.commission}}</div>
								<div class="bl bb tac pt-20" style="flex: 1;word-break: break-all">{{order.customerName ? order.customerName : '--'}}</div>
								<div class="bl bb tac pt-20" style="flex: 1;">
									<span v-if="order.orderStatus==0">已关闭</span>
									<span v-if="order.orderStatus==1">待付款</span>
									<span v-if="order.orderStatus==2">待发货</span>
									<span v-if="order.orderStatus==3">已发货</span>
									<span v-if="order.orderStatus==4">已完成</span>
								</div>
								<div class="bl bb tac pt-20" style="flex: 1;">
									<span v-if="order.auditStatus==0">待审核</span>
									<span v-if="order.auditStatus==1">已通过</span>
									<span v-if="order.auditStatus==2">已驳回</span>
									<span v-if="order.auditStatus==3">已失效</span>
								</div>
								<div class="bl bb tac pt-20" style="flex: 1;">
									<div class="tac">¥{{order.needPayMoney | fmoney}}</div>
									<div class="tac">（含运费：¥{{order.freightMoney | fmoney}}）</div>
								</div>
								<div class="bl bb tac" :style="{paddingTop : '15px'}" style="flex: 1">
									<div class="w100">
										<el-button type="text" class="ml-0" @click="orderDetail(order)">订单详情</el-button>
										<el-button type="text" v-if="order.auditStatus==0" @click="handlePass(order)">通过</el-button>
										<el-button type="text" v-if="order.auditStatus==0" @click="handleFailed(order)">驳回</el-button>
									</div>
								</div>

							</div>
						</div>
					</el-checkbox-group>
				</div>
				<el-pagination v-if='total>0' class="tar mt-20"
					:current-page.sync="currentPage" 
					:page-size='10' :total='total' 
					layout="prev , pager, next, jumper" 
					@current-change="handleCurrentChange">
					<!-- 分页组件 -->
				</el-pagination>
			</div>
		</div>
		
		<!-- 驳回订单弹出框-->
		<el-dialog class='cancelOrderDialog' title='驳回订单理由' :visible.sync='cancelOrderVisible'>
			<el-form class="pl-30 pr-30" :model="form" ref="cancelOrderForm" :rules="rules2">
				<el-form-item label="" prop='cancleReason'>
					<el-input type='textarea' placeholder='请输入驳回订单的理由...' :autosize="{ minRows: 3 ,maxRows: 10}" resize="none" v-model.trim='form.cancleReason'></el-input>
				</el-form-item>
			</el-form>
			<div slot='footer' class="dialog-footer">
				<el-button type='danger' @click="handleCommit()">确 定</el-button>
				<el-button @click="cancelOrderVisible = false">取 消</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import { axiosApi, basePath } from "../../../api/api"
	import hotelDivideOrder from '../../../components/jiuji/hotelDivideOrder.vue';
	import supercPurchaseOrder from './supercPurchaseOrder.vue';

	export default {
		data() {
			return {
				/*规则*/
				rules2: {
					cancleReason: [{
						required: true,
						message: '驳回订单理由不能为空'
					}, {
						min: 4,
						max: 100,
						message: '驳回订单理由为4-100位字符'
					}]
				},
				type: '0',
				orderTabs: ['待审核', '已通过', '已驳回','已失效'],
				ordersListResVo: [], //订单数据
				orderSn: null, //订单编号
				form: {
					auditStatus: 0, //订单状态
					cancleReason: null, //订单取消原因
				},
				currentPage: 1,
				total: null,
				checkAll: false, //选中状态
				checkOrder: [], //选中的每行订单数据
				idStr:[],
				getSellerId: sessionStorage.getItem('traderId'),
				cancelOrderVisible:false,
				bageCount:0
			}
		},
		mounted() {
			this.$nextTick(() => {
				let type = sessionStorage.getItem('type')
				if(type) {
					this.type = type
					if(this.type == '0') { //未审核
						this.form.auditStatus = 0
					} else if(this.type == '1') { //已通过
						this.form.orderStatus = 1
					} else if(this.type == '2') { //已驳回
						this.form.orderStatus = 2
					} else if(this.type == '3') { //已驳回
						this.form.orderStatus = 3
					}
				} else {
					this.type = '0'
				}
				sessionStorage.removeItem('type')
				this.queryOrderList(this.form)
			})
		},
		methods: {
			handleCheckAllChange(val) { /*table全选*/
				if(this.checkAll) {
					this.ordersListResVo.forEach(item => {
						this.checkOrder.push(item)
						this.idStr.push(item.id)
					});
				} else {
					this.checkOrder = [];
					this.idStr = []
				}
			},
			handleCheckChange(val) { //单选
				let checkedCount = val.length;
				this.checkAll = checkedCount === this.ordersListResVo.length;
				this.idStr = [];
				val.forEach(item => {
					this.idStr.push(item.id)
				});
			},

			handleCurrentChange(val) { //分页
				this.currentPage = val;
				this.queryOrderList(this.form);
			},
			handleTabs(){
				this.currentPage = 1;
				if(this.type == '0') { //未审核
					this.form.auditStatus = 0
				} else if(this.type == '1') { //已通过
					this.form.auditStatus = 1
				} else if(this.type == '2') { //已驳回
					this.form.auditStatus = 2
				}  else if(this.type == '3') { //已驳回
					this.form.auditStatus = 3
				} 
				this.queryOrderList(this.form)
			},
			queryOrderList(body) { /*久集订单列表*/
				axiosApi("/superc/specialGoods/pageOrderList", this.form, (res) => {
					console.log(res,'订单列表')
					this.ordersListResVo = res.data.data.specialGoodsOrderPage.elements
					this.total = res.data.data.specialGoodsOrderPage.totalCount
					this.bageCount = res.data.data.notAuditCount
					console.log(this.bageCount)
				}, this.currentPage, 10, (res) => {
					this.$message({
						type: 'info',
						message: res.data.message,
						duration: 3000
					});
				});
			},
			handlePass(order){//审核通过
				const h = this.$createElement
				this.$msgbox({
					title: '提示信息',
					message: h('p', {
						style: 'text-align:center'
					}, '确认审核通过该订单？'),
					showCancelButton: true,
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					beforeClose: (action, instance, done) => {
						if(action === 'confirm') {
							axiosApi("/superc/specialGoods/AuditOrder", {
								orderSn: order.orderSn,
								auditStatus: 1
							}, (res) => {
								this.$message({
									type: 'success',
									message: '操作成功',
									duration: 2000
								});
								this.queryOrderList(this.form)
							}, 1, 10, (res) => {
								this.$message({
									type: 'info',
									message: res.data.message,
									duration: 3000
								});
							});
							done()
						} else {
							done()
						}
						delete instance.$slots.default;
					}
				})
			},
			handleFailed(order){//审核驳回
				this.form.cancleReason = ''
				this.cancelOrderVisible = true;
				this.$refs.cancelOrderForm && this.$refs.cancelOrderForm.resetFields() //打开时候清空验证规则
				this.orderSn = order.orderSn
			},
			handleCommit() { //弹窗确定按钮
				this.$refs.cancelOrderForm.validate((valid) => {
					if(valid) {
						this.cancelOrderVisible = false
						axiosApi('/superc/specialGoods/AuditOrder', {
							orderSn: this.orderSn,
							backReason: this.form.cancleReason,
							auditStatus: 2
						}, (res) => {
							this.queryOrderList(this.form)
							this.$message({
								type: 'success',
								message: '驳回成功',
								duration: 3000
							});
						}, 1, 10, (res) => {
							this.$message({
								type: 'error',
								message: res.data.message,
								duration: 1000
							})
						})
					} else {
						return false
					}
				})
			},

			orderDetail(order) { //去订单详情页面
				sessionStorage.setItem('orderSn', order.orderSn)
				if (order.parentOrSon == 3 ) {
					window.open(basePath + '/specialGoodsOrderDetail?sn='+order.orderSn+'&'+'id='+order.parentOrderSn, '_blank')
				} else {
					window.open(basePath + '/specialGoodsOrderDetail?sn='+order.orderSn, '_blank')
				}
			},
			purchaseOrderDetail(orderSn){//去采购单详情页面
				let type = 'jiuji'
				sessionStorage.setItem('orderSn',orderSn)
				window.open(basePath + '/ccPurchaseOrderDetail?type=' + type, '_blank')
			}
		}
	}
</script>

<style lang="less">
	#specialGoodsOrder {
		position: relative;
		box-sizing: border-box;
		.color-blue {
			cursor: pointer;
			color: #20a0ff;
		}
		.el-tabs__header {
			padding-top: 10px;
			padding-left: 0;
		}
		.el-checkbox__label {
			display: none;
		}
		.notPayCountmark {
			position: absolute;
			left: 57px;
			top: 10px;
		}
		.notDeliveryCount {
			position: absolute;
			left: 218px;
			top: 10px;
		}
		.orderDeliveriedCount {
			position: absolute;
			left: 294px;
			top: 10px;
		}
		.hide {
			display: none;
		}
		.content {
			.el-checkbox {
				width: 40px !important;
			}
			.el-date-editor--daterange.el-input {
				width: 240px;
			}
			.el-form--inline {
				width: 650px;
				.el-form-item__label {
					font-size: 12px;
					width: 60px;
				}
			}
			.el-table .el-table__body-wrapper {
				display: none;
			}
			.order-datatimerange {
				width: 240px;
			}
			.el-table {
				border: none;
				font-size: 14px;
				th>.cell {
					line-height: 12px;
					padding: 10px 0 10px 0;
					text-align: center;
				}
				th.is-leaf {
					border-bottom: 0;
				}
			}
			.checkedAll {
				position: relative;
				top: 30px;
			}
			.el-checkbox {
				width: 70px;
				margin-left: 30px;
				.el-checkbox__input {
					height: 20px;
				}
			}
			.el-checkbox__inner {
				width: 20px;
				height: 20px;
			}
			.el-checkbox__inner::after {
				top: 2px;
			}
			.table-item {
				.bl {
					border-left: 1px solid #DDD;
				}
				border: 1px solid #DDDDDD;
				font-size: 14px;
				border-bottom: none;
				.table-item-content .pbl-20 {
					padding: 0px 0px 20px 20px;
				}
				.table-item-top {
					padding: 10px 0;
					.table-item-top-checkbox {
						margin-left: 14px
					}
				}
				img {
					width: 80px;
					height: 80px;
				}
			}
			.w100 {
				width: 120px;
			}
			.table-item-default {
				height: 530px;
				padding-top: 120px;
			}
			.el-dialog {
				min-height: 300px;
				.el-form-item__label {
					width: 140px;
				}
			}
			.sendGoods .el-form-item__error {
				margin-left: 140px;
			}
		}
		.ml-70 {
			margin-left: 70px;
		}
		.my-badge {
			.el-badge__content {
				border: 0;
			}
		}
		
		.freight-dialog {
			.el-form-item__label {
				width: 110px;
			}
			.el-select {
				& > .el-input {
					width: 100px;
				}
			}
		}
		.el-button+.el-button{
			margin-left: 0;
		}
		.cancelOrderDialog{
			.el-dialog{
				min-height: 200px;
			}
		}
	}
</style>