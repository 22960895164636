<template>
  <div id="client" class="pb-20">
    <div class="title-tabs bg-f">
      <ul class="clearfix">
        <template v-for="(tab, index) in dataTabs" class="item">
          <li
            :key="index"
            :type="index"
            :class="type == index ? 'active' : ''"
            @click="handleTabs"
          >
            {{ tab }}
            <div :class="type == index ? 'under-line' : ''"></div>
          </li>
          
          <div :key="tab" class="vertical-line"></div>
        </template>
      </ul>
      
     <div class="tar">
        <!--暂时隐藏新增客户入口-->
        <!-- <el-button v-if="showButton('CUSTOMER_ADD',100056)" @click="addStoreDialog = true">新增客户</el-button> -->
      </div>
    </div>
    <!-- 角标 -->
    <!-- 新客户审核角标隐藏，以后是否使用未知，暂时注释 -->
    <!-- <el-badge class="a" :value="count" :max="99" v-if="count != 0"></el-badge> -->
    <!-- 搜索条件 -->
    <div class="bg-f pb-50 pl-30 pr-30">
      <el-form
        :inline="true"
        label-width="70px"
        class="clearfix pt-30 mt-5 search"
      >
        <div>
          <el-form-item label="客户编号:">
            <el-input v-model.trim="traderNumber"></el-input>
          </el-form-item>
          <el-form-item label="客户名称:">
            <el-input v-model.trim="storeName"></el-input>
          </el-form-item>
          <el-form-item label="客户账号:">
            <el-input v-model.trim="loginName"></el-input>
          </el-form-item>
          <el-form-item label="客户类型:">
            <!-- <el-select v-model="ctype">
                            <el-option label="全部" value=""></el-option>
                            <el-option label="经销商" v-if="!isCityPartner" value="1"></el-option>
                            <el-option label="终端" value="2"></el-option>
                            <el-option label="普通酒店" value="6"></el-option>
                            <el-option label="久加久*久柜" value="3"></el-option>
                            <el-option label="久加久*连锁店" value="4"></el-option>
                            <el-option label="久加久*店中店" value="5"></el-option>
                        </el-select> -->
            <el-cascader
              v-model="ctype"
              clearable
              :options="optionsType"
              :props="{ checkStrictly: true }"
            ></el-cascader>
          </el-form-item>
          <el-form-item label="终端渠道:" v-show="type == 0">
            <el-cascader
              :options="clientChannelList"
              :props="channelProp"
              clearable
              change-on-select
              v-model="cchannel"
              @change="handleChanneChange"
            ></el-cascader>
          </el-form-item>
          <el-form-item label="状态:">
            <el-select v-model="isDisable">
              <el-option label="全部" value=""></el-option>
              <el-option label="正常" value="0"></el-option>
              <el-option label="禁用" value="1"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="客户升级:">
            <el-select v-model="accountSource">
              <el-option label="全部" value=""></el-option>
              <el-option label="已升级" value="1"></el-option>
              <el-option label="待升级" value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-show="type == 0" label="所属分组:">
            <!-- <el-select v-model="groupId" clearable>
              <el-option
                v-for="n in groupList"
                :key="n.id"
                :label="n.groupName"
                :value="n.id"
              ></el-option>
            </el-select> -->
            <el-cascader v-model="groupId" :options="treeList" clearable :props="treeProps"></el-cascader>
          </el-form-item>
          <el-form-item v-show="type == 0" label="所属区域:">
            <el-cascader
              :props="districtProps"
              :options="districtList"
              clearable
              change-on-select
              placeholder="选择区域"
              v-model="districtIds"
            ></el-cascader>
          </el-form-item>
          <el-form-item label="业务员:">
            <el-input v-model.trim="managerName"></el-input>
          </el-form-item>
          <el-form-item label="K3编码:">
            <el-input v-model.trim="k3No" @input="k3No=k3No.replace(/[^\w\.\/]/ig,'')" clearable></el-input>
          </el-form-item>
        </div>
      </el-form>
      <div class="pt-5">
        <el-button type="info" icon="el-icon-search" @click="search"
          >查询</el-button
        >
      </div>
      <div v-if="type == 0" class="clearfix tar pr-10">
        <!--<el-button v-if="!isCityPartner" type="text" @click="setupWarehouse">仓库设置</el-button>-->
        <!--<el-button v-if="!isCityPartner && !isDealerUser && !isParentCompany" @click="showClientDia" type="text">客户关系变更</el-button>-->
        <a
          v-if="showButton('IMPORT_MULT', 100056)"
          style="color: #20a0ff"
          href="https://sunrise-cbs.oss-cn-hangzhou.aliyuncs.com/template/jiujiajiu/%E6%89%B9%E9%87%8F%E5%AF%BC%E5%85%A5%E5%AE%A2%E6%88%B7%E6%A8%A1%E6%9D%BF.xls"
        >
          <el-button type="text">下载模板</el-button>
        </a>
        <el-upload
          v-if="showButton('IMPORT_MULT', 100056)"
          class="dib"
          action="/sunrise-gateway/import/trader/customerlist?startRow=1&startCol=0"
          :show-file-list="false"
          :on-success="handUpSuccess"
          :before-upload="handUpBefore"
          :on-error="handUpError"
          accept="application/vnd.ms-excel"
        >
          <el-button type="text">批量导入</el-button>
        </el-upload>
        <el-button
          v-if="showButton('EXPORT_ALL', 100056)"
          type="text"
          @click="exportClient"
          >批量导出</el-button
        >
        <el-button
          type="text"
          v-if="showButton('DEL', 100056)"
          @click="deleteMer"
          >批量删除</el-button
        >
        <el-button type="text" v-if="showButton('DEL', 100056)" @click="showLog"
          >删除日志</el-button
        >
      </div>

      <!--全部客户-->
      <el-table
        v-show="type == 0"
        :data="userData"
        tooltip-effect="dark"
        stripe
        class="mt-15 table-default"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column label="客户名称/简称" min-width="100">
          <template slot-scope="scope">
            <div style="text-align:left;">
              <p class="tal">名称：{{ scope.row.storeName }}</p>
              <p class="tal">简称：{{ scope.row.platformDescription || '--' }}</p>
              <el-tag size="mini" v-if="scope.row.isDisable==1" type="danger">已禁用</el-tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="K3编码/状态" width="90">
          <template slot-scope="{ row }">
            <div v-if="row.k3No">{{row.k3No}}</div>
            <span v-if="row.k3IsDisabled === null">未关联K3</span>
            <div v-else>
              <span v-if="row.k3IsDisabled">禁用</span>
              <div v-else>
                <div>启用</div>
              </div>
            </div>
          </template>
        </el-table-column>

        <!-- <el-table-column prop="loginName" label="客户账号" min-width="100"></el-table-column> -->
        <el-table-column label="客户账号/编号" min-width="100">
          <template slot-scope="scope">
            <p class="tal">{{ scope.row.loginName }}</p>
            <p class="tal">{{ scope.row.traderNumber || '--' }}</p>
          </template>
        </el-table-column>
        <el-table-column prop="groupName" label="所属分组" min-width="80">
        </el-table-column>
        <el-table-column prop="districtName" label="所属区域" min-width="90">
        </el-table-column>
        <el-table-column label="联系人/电话" min-width="80">
          <template slot-scope="scope">
            <p>{{ scope.row.linkname }}</p>
            <p>{{ scope.row.tel }}</p>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="regionName" label="地区" width="70">
                </el-table-column> -->
        <el-table-column label="客户类型" min-width="75">
          <template slot-scope="scope">
            <span v-if="scope.row.ctype == 1">经销商</span>
            <span v-else-if="scope.row.ctype == 2">终端</span>
            <span v-else-if="scope.row.ctype == 6">普通酒店</span>
            <span v-else-if="scope.row.ctype == 3">久加久*久柜</span>
            <span v-else-if="scope.row.ctype == 5">久加久*店中店</span>
            <span v-else-if="scope.row.ctype == 4">久加久*连锁店</span>
            <span v-else-if="scope.row.ctype == 41">久加久*连锁店/直营</span>
            <span v-else-if="scope.row.ctype == 42">久加久*连锁店/加盟</span>
          </template>
        </el-table-column>
        <el-table-column label="终端渠道" min-width="70">
          <template slot-scope="scope">
            {{ scope.row.classifyName1 }}
            <span v-if="scope.row.classifyName1 && scope.row.classifyName2"
              >/</span
            >
            {{ scope.row.classifyName2 }}
          </template>
        </el-table-column>
        <el-table-column
          prop="name"
          label="关联业务员"
          min-width="75"
        ></el-table-column>
        <!-- <el-table-column prop="isDisable" label="状态" width="50">
                    <template slot-scope="scope">
                        {{scope.row.isDisable==1?"禁用":"正常"}}
                    </template>
                </el-table-column> -->
        <el-table-column label="操作" min-width="90">
          <template slot-scope="scope">
            <div>
              <!--<p class="tac" v-if="scope.row.isDisable==1">
                                <el-button type="text" >启用
                                </el-button>
                            </p>
                            <p class="tac" v-else>
                                <el-button type="text" >禁用
                                </el-button>
                            </p>-->
              <p class="tac">
                <el-button
                  type="text"
                  v-if="showButton('EDIT', 100056)"
                  @click="goPage(scope.row)"
                  >编辑
                </el-button>
              </p>
              <p
                class="tac"
                v-if="
                  scope.row.accountSource == 2 && showButton('UPGRADE', 100056)
                "
              >
                <el-button
                  type="text"
                  title="录入客户升级为注册用户"
                  @click="update(scope.row)"
                  >升级</el-button
                >
              </p>
              <!-- <p class="tac">
                                <el-button type="text" v-if="showButton('DEL',100056)" @click="deleteRow(scope.row)">删除</el-button>
                            </p> -->
              <p class="tac">
                <el-button
                  v-if="showButton('DEL', 100056)"
                  @click="deleteMer(scope.$index, scope.row)"
                  type="text"
                >
                  删除
                </el-button>
              </p>
              <p class="tac">
                <el-button
                  v-if="scope.row.longitude && scope.row.latitude"
                  style="width: 74px"
                  @click="deleteLocation(scope.$index, scope.row)"
                  type="text"
                >
                  清空经纬度
                </el-button>
              </p>
              <!-- <p
                class="tac"
                v-if="scope.row.accountSource == 1 && isOpenPriceSync"
              >
                <el-button type="text" @click="resetPrice(scope.row.buyerId)">同步价格</el-button>
              </p> -->
              <p class="tac" v-if="scope.row.ctype == 2">
                  <el-button type="text" style="width:90px" @click="setInfo(scope.row)">指定付款账号</el-button>
              </p>
              <p class="tac" v-if="showButton('CUSTOMER_FINANCE_ANALYES', 100056)">
                  <el-button type="text" style="width:90px" @click="()=>{$router.push({path:basePath+'/creditRecordInfo',query:{buyerId:scope.row.buyerId}})}">对账单</el-button>
              </p>
            </div>
          </template>
        </el-table-column>
      </el-table>

      <!--新客户审核-->
      <el-table
        v-show="type == 1"
        :data="userData"
        tooltip-effect="dark"
        stripe
        class="mt-20 table-default"
      >
        <el-table-column prop="traderNumber" label="客户编号" min-width="100">
        </el-table-column>
        <el-table-column prop="storeName" min-width="100" label="客户名称">
        </el-table-column>
        <el-table-column
          prop="platformDescription"
          label="客户简称"
          min-width="50"
        >
        </el-table-column>
        <el-table-column prop="loginName" label="客户账号" min-width="100">
        </el-table-column>
        <el-table-column prop="regionName" label="所在地区" min-width="100">
        </el-table-column>
        <el-table-column label="客户类型" min-width="70">
          <template slot-scope="scope">
            <span v-if="scope.row.ctype == 1">经销商</span>
            <span v-else-if="scope.row.ctype == 2">终端</span>
            <span v-else-if="scope.row.ctype == 6">普通酒店</span>
            <span v-else-if="scope.row.ctype == 3">久加久*久柜</span>
            <span v-else-if="scope.row.ctype == 5">久加久*店中店</span>
            <span v-else-if="scope.row.ctype == 4">久加久*连锁店</span>
            <span v-else-if="scope.row.ctype == 41">久加久*连锁店/直营</span>
            <span v-else-if="scope.row.ctype == 42">久加久*连锁店/加盟</span>
          </template>
        </el-table-column>
        <el-table-column label="注册时间" min-width="80">
          <template slot-scope="scope">
            {{ scope.row.gmtCreate | time }}
          </template>
        </el-table-column>
        <el-table-column prop="name" label="关联业务员" min-width="50">
        </el-table-column>
        <el-table-column prop="isDisable" label="状态" min-width="60">
          <template slot-scope="scope">
            {{ scope.row.isDisable == 1 ? "禁用" : "正常" }}
          </template>
        </el-table-column>
        <el-table-column label="操作" min-width="100">
          <template slot-scope="scope">
            <div>
              <p class="tac">
                <el-button type="text" @click="goPage(scope.row)"
                  >审核</el-button
                >
              </p>
            </div>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        v-if="total > 0"
        @current-change="handleCurrentChange"
        class="tar mt-20 mr-50"
        :current-page.sync="currentPage"
        @size-change="sizeChange"
        :page-sizes="[10,20,50,100]"
        :page-size="pageSize"
        :total="total"
        layout="sizes, prev , pager, next, jumper"
      >
        <!-- 分页组件 -->
      </el-pagination>
    </div>

    <el-dialog title="新增客户" :visible.sync="addStoreDialog" width="500px">
      <el-form
        :model="addStoreForm"
        :rules="rules"
        ref="addStoreForm"
        label-width="160px"
      >
        <el-form-item label="联系方式:" prop="tel">
          <el-input
            v-model.trim="addStoreForm.tel"
            :maxlength="11"
            placeholder="输入手机号"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addStoreDialog = false">取 消</el-button>
        <el-button type="primary" @click="addStoreClick" :loading="loadingBtn"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <!-- 导出 -->
    <form
      ref="downClientForm"
      action="/sunrise-gateway/export/trader/customerlist"
      style="display: none"
    >
      <input type="hidden" name="modelName" v-model="a" />
      <input type="hidden" name="fileName" v-model="b" />
      <input type="hidden" name="sellerId" v-model="c" />
      <input type="hidden" name="startRow" v-model="d" />
    </form>
    <!-- 下载导入失败文件信息 -->
    <form
      ref="downClientErrForm"
      action="/sunrise-gateway/export/importCustomer/failedRecord"
      style="display: none"
    >
      <input type="hidden" name="modelName" v-model="e" />
      <input type="hidden" name="fileName" v-model="f" />
      <input type="hidden" name="key" v-model="g" />
    </form>
    <!-- 导出 -->
    <!--选中状态导出订单-->
    <form
      ref="downClientForm1"
      action="/sunrise-gateway/export/trader/customerlist"
      style="display: none"
    >
      <input type="hidden" name="modelName" v-model="a" />
      <input type="hidden" name="fileName" v-model="b" />
      <input type="hidden" name="sellerId" v-model="c" />
      <input type="hidden" name="startRow" v-model="d" />
      <input type="hidden" name="idsStr" v-model="traderIds" />
    </form>
    <!--筛选条件导出订单-->
    <form
      ref="downClientForm2"
      action="/sunrise-gateway/export/trader/customerlist"
      style="display: none"
    >
      <input type="hidden" name="modelName" v-model="a" />
      <input type="hidden" name="fileName" v-model="b" />
      <input type="hidden" name="sellerId" v-model="c" />
      <input type="hidden" name="startRow" v-model="d" />
      <!--客户编号-->
      <input type="hidden" name="traderNumber" v-model="traderNumber" />
      <!--客户名称-->
      <input type="hidden" name="storeName" v-model="storeName" />
      <!--客户账号-->
      <input type="hidden" name="loginName" v-model="loginName" />
      <!--客户类型-->
      <input type="hidden" name="ctype" v-model="ctype" />
      <!--终端渠道-->
      <input type="hidden" name="cchannel1" v-model="cchannel1" />
      <input type="hidden" name="cchannel2" v-model="cchannel2" />
      <!--状态-->
      <input type="hidden" name="isDisable" v-model="isDisable" />
      <!--所属分组-->
      <input type="hidden" name="groupId" v-model="groupId" />
      <!--所属区域-->
      <input type="hidden" name="districtId" v-model="districtId" />
    </form>

    <form
      ref="downClientForm3"
      action="/sunrise-gateway/export/trader/coustomerRelationChange"
      style="display: none"
    >
      <input type="hidden" name="modelName" v-model="a2" />
      <input type="hidden" name="fileName" v-model="b2" />
      <input type="hidden" name="sellerId" v-model="c" />
      <input type="hidden" name="startRow" v-model="d2" />
      <!--所属区域-->
      <input type="hidden" name="operateBatchNo" v-model="operateBatchNo" />
    </form>
    <el-dialog
      title="提示"
      :visible.sync="deleteDialog"
      class="del-dialog"
      width="500px"
    >
      <div class="lh-20 color-danger">
        已删除{{ deleteInfo.successNum }}条数据！
      </div>
      <div v-if="deleteInfo.errorList.length > 0" class="lh-20 mb-10">
        报错列表
      </div>
      <el-table
        v-if="deleteInfo.errorList.length > 0"
        :data="deleteInfo.errorList"
        border
        tooltip-effect="dark"
        stripe
        class="no-title"
      >
        <el-table-column prop="storeName"></el-table-column>
        <el-table-column prop="loginName"></el-table-column>
        <el-table-column prop="message"></el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="deleteDialog = false"
          >确 定</el-button
        >
      </div>
    </el-dialog>

    <!--客户关系变更-->
    <el-dialog
      title="客户关系变更"
      :visible.sync="addClientDialog"
      width="500px"
    >
      <el-form :model="clientForm">
        <!-- <el-form-item label="当前在：" label-width="120px">
                    <span>{{ siteName }}</span>
                </el-form-item>-->
        <el-form-item label="变更到：" label-width="120px">
          <el-select
            v-model="clientForm.citycooperateId"
            filterable
            remote
            :remote-method="getCityPartnerList"
            placeholder="请输入变更到的对象"
          >
            <el-option
              v-for="item in cityCooperateList"
              :key="item.id"
              :label="item.storeName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addClientDialog = false">取 消</el-button>
        <el-button type="primary" @click="changeClient">确 定</el-button>
      </div>
    </el-dialog>

    <addDialog :dialogVisible="dialogVisible" :buyerId="setAddId" @closeDialog="closeDialog"/>
  </div>
</template>

<script>
import addDialog from './components/addDialog.vue' ;
import { axiosApi, basePath } from "../../../api/api";
import { mapMutations, mapActions } from "vuex";
import { MessageBox } from "element-ui";

export default {
  components: {
    addDialog
  },
  data() {
    return {
      dialogVisible: false, // 添加特殊银行卡弹框使用
      setAddId: null,
      operateBatchNo: null,
      clientForm: {
        citycooperateId: "",
      },
      siteName: sessionStorage.getItem("employeeName"),
      addClientDialog: false,
      cityCooperateList: [],
      accountSource: "",
      b2: "客户关系变更表单.xls",
      districtId: null,
      a2: "empty",
      a: "trader.customerlist",
      b: "客户列表.xls",
      c: sessionStorage.getItem("traderId"),
      d: 2,
      d2: 0,
      e: "importCustomer.failedRecord",
      f: "导入失败客户列表信息.xls",
      g: null,
      basePath: basePath,
      clearItem: false,
      count: 0, //新客户审核角标
      //count1: 0, //收货地址角标
      addressState: "", //收货地址 状态
      addressDialog2: false, //收货地址 审核
      addressDialog1: false, //收货地址 编辑
      addressDialog0: false, //新增客户 弹窗
      type: 0, //tab
      // 新客户审核tab隐藏，以后是否使用未知，暂时注释 "新客户审核"
      dataTabs: ["全部客户"], 
      userData: [],
      //customerSource: '',//客户来源
      multipleSelection: [],
      // customer: [{
      //     label: "全部",
      //     value: ""
      // }, {
      //     label: "经销商",
      //     value: "1"
      // }, {
      //     label: "终端",
      //     value: "2"
      // }, {
      //     label: "普通酒店",
      //     value: "6"
      // }, {
      //     label: "久加久*久柜",
      //     value: "3"
      // }, {
      //     label: "久加久*连锁店",
      //     value: "4"
      // }, {
      //     label: "久加久*店中店",
      //     value: "5"
      // }],
      ctype: [],
      optionsType: [
        { value: null, label: "全部类型" },
        { value: 1, label: "经销商" },
        { value: 2, label: "终端" },
        { value: 6, label: "普通酒店" },
        { value: 3, label: "久加久*久柜" },
        {
          value: 4,
          label: "久加久*连锁店",
          children: [
            { value: 41, label: "直营" },
            { value: 42, label: "加盟" },
          ],
        },
        { value: 5, label: "久加久*店中店" },
      ],
      // isRetailer: "",
      // isDealer: sessionStorage.getItem('isDealer') == "true",
      traderNumber: "",
      storeName: "",
      loginName: "",
      // channelType: [],
      managerName: "", //业务员
      k3No: '', // k3编码
      cchannel: [],
      cchannel1: null,
      cchannel2: null,
      channelProp: {
        label: "classifyName",
        value: "id",
        children: "sub",
      },
      isDisable: "",
      currentPage: 1,
      pageSize:10,
      total: 0,
      addStoreDialog: false,
      addStoreForm: {
        tel: null,
      },
      rules: {
        tel: [
          {
            required: true,
            pattern: /^1\d{10}$/,
            message: "请输入正确的手机号",
          },
        ],
      },
      treeList: [],
      groupId: null,
      treeProps: {
        value: 'id',
        label: 'name',
        children:'sub',
        emitPath:false
      },
      districtList: [],
      districtIds: [],
      districtProps: {
        label: "districtName",
        value: "id",
        children: "subDistrictList",
      },
      hotelDialog: false,
      typeTransformaForm: {
        storeName: "",
        sellerId: sessionStorage.getItem("traderId"),
        buyerId: null,
        toTraderType: 3, //客户类型 1：经销商 2：终端  3：酒店
      },
      loadingBtn: false,
      traderIds: [], //批量导出
      ids: [], //删除客户id
      buyerIdList: [], //删除客户的进货商id
      deleteInfo: {
        successNum: 0,
        errorList: [],
      },
      deleteDialog: false,
      isOpenPriceSync: false,
    };
  },
  activated() {
    this.loading();
    this.queryChannelLevelData(0);
    this.findTraderInfoBySerllerId().then((res) => {
      this.isOpenPriceSync = res.data.data.isOpenPriceSync || false;
      this.siteName = res.data.data.storeName;
    });
    this.getTree()
    //区域
    axiosApi(
      "/trader/customerDistrict/query",
      { traderId: sessionStorage.getItem("traderId") },
      (res) => {
        this.districtList = this.cascaderClear2([res.data.data]);
        this.districtList[0].id = "0";
      }
    );
  },
  computed: {
    clientChannelList() {
      return this.$store.state.channelLevel.clientChannelList;
    },
    isCityPartner() {
      return this.$store.state.clientType.isCityPartner;
    },
    isDealerUser() {
      return this.$store.state.clientType.isDealerUser;
    },
    isParentCompany() {
      return this.$store.state.clientType.isParentCompany;
    },
  },
  mounted() {
    if (this.isCityPartner) {
      this.optionsType = [
        { value: null, label: "全部类型" },
        { value: 2, label: "终端" },
        { value: 6, label: "普通酒店" },
        { value: 3, label: "久加久*久柜" },
        {
          value: 4,
          label: "久加久*连锁店",
          children: [
            { value: 41, label: "直营" },
            { value: 42, label: "加盟" },
          ],
        },
        { value: 5, label: "久加久*店中店" },
      ];
    }
  },
  methods: {
    ...mapMutations({
      deleteGoods: "deletewilladddata",
      isvisible: "visible",
    }),
    ...mapActions({
      queryChannelLevelData: "queryChannelLevelData",
    }),
     // 获取客户分组
    getTree(){
        // 查询 左侧树
        axiosApi('/trader/customer/group/listTree',{},(res) => {
            this.treeList = this.treeInit(res.data.data);
        });
        // 默认查询 全国区域 右侧表格数据
    },
    treeInit(tree){
      tree.forEach(item=>{
        if(item.sub.length>0){
          this.treeInit(item.sub)
        }else{
          delete item.sub
        }
      })
      return tree
    },
    // 跳转仓库设置页面
    // setupWarehouse() {
    //   this.$router.push({
    //     path: basePath + "/warehouseSetup",
    //   });
    // },
    showClientDia() {
      if (this.traderIds.length === 0) {
        this.$message.warning("请选择客户");
        return false;
      } else {
        this.addClientDialog = true;
      }
    },
    //客户关系变更
    changeClient() {
      let item = this.cityCooperateList.filter(
        (v) => v.id === this.clientForm.citycooperateId
      );
      console.log(item);
      axiosApi(
        "/trader/coustomerRelationChange/update",
        {
          ids: this.traderIds.split(","),
          sellerId: this.clientForm.citycooperateId,
          sellerName: item[0].storeName,
        },
        (res) => {
          console.log(res, "change");
          this.operateBatchNo = res.data.data.operateBatchNo;
          console.log(this.operateBatchNo);
          this.$notify({
            title: "成功",
            message: "操作成功",
            type: "success",
          });
          this.addClientDialog = false;
          this.clientForm = {
            citycooperateId: null,
          };
          this.loading();
          this.getCityPartnerList();
          let that = this;
          setTimeout(() => {
            this.$confirm(
              `共变更${
                res.data.data.changeFailCount + res.data.data.changeSuccessCount
              }个客户，失败${
                res.data.data.changeFailCount
              }个，是否导出客户变更结果?`,
              "提示",
              {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
              }
            )
              .then(() => {
                that.$refs.downClientForm3.submit();
              })
              .catch(() => {
                this.$message({
                  type: "info",
                  message: "已取消",
                });
              });
          }, 500);
        },
        1,
        10,
        (err) => {
          this.$message.error(err.data.message);
        }
      );
    },
    //获取城市拍档列表
    getCityPartnerList(val) {
      axiosApi(
        "/trader/partner/customer/change",
        {
          sellerId: sessionStorage.getItem("traderId"),
          storeName: val,
          userType: 1,
        },
        (res) => {
          this.cityCooperateList = res.data.data;
        },
        1,
        10000,
        (err) => {}
      );
    },
    /* resetPrice(buyerId) {
      //同步
      this.$confirm("是否确认同步k3?", "提示信息", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        axiosApi(
          "/goods/buyer/resetPrice",
          { buyerId: buyerId },
          (res) => {
            this.$message.success("价格同步成功");
          },
          1,
          10,
          (res) => {
            this.$message.success("价格同步失败");
          }
        );
      });
    }, */
    findTraderInfoBySerllerId() {
      // 查询商品是否开通同步K3
      return new Promise((resolve, reject) => {
        axiosApi(
          "/trader/findTraderInfoBySerllerId/select",
          {
            traderId: sessionStorage.getItem("traderId"),
          },
          (res) => {
            resolve(res);
          },
          1,
          10,
          (err) => {
            reject(err);
          }
        );
      });
    },
    handUpBefore(file) {
      this.$loading({
        fullscreen: true,
        lock: true,
        text: "正在导入客户...",
      });
      return true;
    },
    handUpSuccess(res, file, fileList) {
      this.$loading().close();
      switch (res.code) {
        case "000000": //系统交易成功
          let h = this.$createElement;
          if (res.data.failedRows == 0 && res.data.upGradeFailedRows == 0) {
            //失败条数为0
            this.$msgbox({
              title: "提示信息",
              message: h("p", { style: "text-align:center" }, [
                h("p", null, "导入已完成！"),
                h("p", null, `共导入信息${res.data.totalRows}条，全部成功！`),
              ]),
              showCancelButton: false,
              confirmButtonText: "确定",
              beforeClose: (action, instance, done) => {
                if (action === "confirm") {
                  done();
                } else {
                  done();
                }
                delete instance.$slots.default;
              },
            });
          } else {
            this.g = res.data.failedRowsKey;
            this.$msgbox({
              title: "提示信息",
              message: h("p", { style: "text-align:center" }, [
                h("p", null, "导入已完成！"),
                h(
                  "p",
                  null,
                  `共导入信息${res.data.totalRows}条，其中成功${res.data.successedRows}条（升级失败${res.data.upGradeFailedRows}条），导入失败${res.data.failedRows}条，点击下载查看失败原因！`
                ),
              ]),
              showCancelButton: true,
              confirmButtonText: "下载失败文件",
              cancelButtonText: "我不想知道",
              beforeClose: (action, instance, done) => {
                if (action === "confirm") {
                  this.$refs.downClientErrForm.submit();
                  done();
                } else {
                  done();
                }
                delete instance.$slots.default;
              },
            });
          }
          this.loading();
          break;
        case "999999": //系统异常
          this.$alert(res.message, "提示信息", {
            confirmButtonText: "确定",
          });
          break;
        case "AUTH_001": //用户未登陆
          gotoLoginPage();
          break;
        case "AUTH_002": //用户无权限
          this.$alert(res.message, "提示信息", {
            confirmButtonText: "确定",
          });
          break;
        case "LOGIN_005": //用户已禁用
          this.$alert(res.message, "提示信息", {
            confirmButtonText: "确定",
          });
          break;
        case "LOGIN_006": //用户session失效
          gotoLoginPage();
          break;
        default:
          this.$alert(res.message, "提示信息", {
            confirmButtonText: "确定",
          });
          break;
      }
    },
    handUpError(err, file, fileList) {
      this.$loading().close();
      this.$message.error("导入失败！");
    },
    exportClient() {
      //批量导出
      this.districtId = this.districtIds[this.districtIds.length - 1];
      if (this.multipleSelection.length > 0) {
        //选中状态提交表单
        this.$refs.downClientForm1.submit();
      } else {
        if (this.userData.length > 0) {
          this.$refs.downClientForm2.submit();
        } else {
          this.$message({
            type: "info",
            message: "暂无数据可导出!",
            duration: 1000,
          });
        }
      }
    },
    handleTabs(e) {
      if (e.target.attributes.type) this.type = e.target.attributes.type.value;
      //清空数据
      this.traderNumber = "";
      this.storeName = "";
      this.loginName = "";
      this.ctype = [];
      this.isDisable = "";
      this.currentPage = 1;
      this.cchannel = [];
      this.cchannel1 = this.cchannel2 = null;
      this.groupId = null;
      this.districtIds = [];
      this.accountSource = "";
      this.k3No = '';
      this.loading();
    },
    handleChanneChange(val) {
      if (val.length > 0) this.cchannel1 = val[0];
      if (val.length > 1) this.cchannel2 = val[1];
      if (val.length == 0) {
        this.cchannel1 = this.cchannel2 = null;
      }
    },
    deleteRow(item) {
      //删除
      this.$confirm("是否确认从客户中删除？", "提示信息", {
        confirmButtonText: "确定",
        type: "warning",
      })
        .then(() => {
          axiosApi(
            "/trader/sellerDeleteBuyer/update",
            {
              ids: [item.id],
            },
            (res) => {
              this.loading();
              this.$message({
                type: "success",
                message: "操作成功",
                duration: 1000,
              });
            },
            1,
            10,
            (res) => {
              MessageBox.alert(res.data.message, "提示", {
                confirmButtonText: "确定",
              });
            }
          );
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
            duration: 1000,
          });
        });
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      this.traderIds = this.multipleSelection.map((i) => i.id).join(); /// [123,2312,123,123]
    },
    getIdsStr() {
      let _this = this;
      this.multipleSelection.forEach(function (item, index) {
        _this.ids.push(item.id); //批量删除客户的id
        _this.buyerIdList.push(item.buyerId); //批量删除客户的进货商id
      });
    },
    countBuyerSellerRelation() {
      //新客户审核角标
      axiosApi(
        "/trader/countBuyerSellerRelation/select",
        {
          sellerId: sessionStorage.traderId,
        },
        (res) => {
          this.count = res.data.data;
        }
      );
    },
    search() {
      this.currentPage = 1
      this.loading();
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.loading();
    },
    sizeChange(val){
      this.currentPage = 1
      this.pageSize = val
      this.loading()
    },
    loading() {
      this.$loading();
      this.countBuyerSellerRelation();
      let api = "";
      if (this.type == 0) {
        //全部客户
        api = "/trader/findClientAllList/select";
      } else if (this.type == 1) {
        //新客户审核
        api = "/trader/findStateClientAllList/select";
      }
      let ctype;
      if (this.ctype.length == 1) {
        ctype = this.ctype[0];
      }
      if (this.ctype.length == 2) {
        ctype = this.ctype[1];
      }
      const body = {
        accountSource: this.accountSource,
        sellerId: sessionStorage.traderId,
        traderNumber: this.traderNumber,
        storeName: this.storeName,
        loginName: this.loginName,
        ctype: ctype,
        managerName: this.managerName,
        // isRetailer: this.isRetailer,
        // isDealer: this.isDealer,
        // channelType: this.channelType,
        isDisable: this.isDisable,
        state: this.addressState,
        cchannel1: this.cchannel1, //一级终端渠道
        cchannel2: this.cchannel2, //二级终端渠道
        groupId: this.groupId, //所属分组
        k3No: this.k3No, // k3编码
        districtId: this.districtIds[this.districtIds.length - 1], //所属区域
      };
      axiosApi(
        api,
        body,
        (res) => {
          this.$loading().close();
          this.userData = res.data.data ? res.data.data.elements : [];
          this.total = res.data.data ? res.data.data.totalCount : 0;
          if (this.type == 0) {
            for (let item1 in this.userData) {
              //如果没有客户等级则跳过本次循环
              if (!this.userData[item1].cchannel1) continue;
              for (let item2 in this.clientChannelList) {
                if (
                  this.userData[item1].cchannel1 ==
                  this.clientChannelList[item2].id
                ) {
                  this.$set(
                    this.userData[item1],
                    "classifyName1",
                    this.clientChannelList[item2].classifyName
                  );
                  if (!this.userData[item1].cchannel2) break;
                  for (let item3 in this.clientChannelList[item2].sub) {
                    if (
                      this.userData[item1].cchannel2 ==
                      this.clientChannelList[item2].sub[item3].id
                    ) {
                      this.$set(
                        this.userData[item1],
                        "classifyName2",
                        this.clientChannelList[item2].sub[item3].classifyName
                      );
                      break;
                    }
                  }
                  break;
                }
              }
            }
            
          }
        },
        this.currentPage,
        this.pageSize,
        1
      );
    },
    goPage(item) {
      this.$router.push(
        `${basePath}/clientEdit/${item.id}/${this.type}/${item.buyerId}`
      );
    },
    addStoreClick() {
      this.$refs.addStoreForm.validate((valid) => {
        if (valid) {
          this.loadingBtn = true;
          let body = {
            sellerId: sessionStorage.getItem("traderId"),
            mobile: this.addStoreForm.tel,
          };
          axiosApi(
            "/trader/clientInsert/get",
            body,
            (res) => {
              this.loadingBtn = false;
              this.addStoreDialog = false;
              this.$router.push(
                `${basePath}/addStore/${this.addStoreForm.tel}`
              );
            },
            0,
            0,
            (err) => {
              this.$message(err.data.message);
              this.loadingBtn = false;
            }
          );
        }
      });
    },
    update(row) {
      //录入用户升级注册用户
      // console.log(row)
      //传值 traderId, type(区分进入入口)
      this.$router.push(
        `${basePath}/clientUpdate?traderId=${row.buyerId}&type=1`
      );
    },
    //删除日志
    showLog() {
      this.$router.push(basePath + "/showLog");
    },
    //删除客户
    deleteMer(index, row) {
      this.ids = [];
      this.buyerIdList = [];
      if (row) {
        this.ids.push(row.id);
        this.buyerIdList.push(row.buyerId);
      } else {
        this.getIdsStr();
      }
      // console.log(this.ids,this.buyerIdList,'ididi')
      if (this.ids.length > 0 || this.buyerIdList.length > 0) {
        this.deleteEnt();
      } else {
        this.$message({
          type: "info",
          message: "您未选择任何商户，请先选择！",
          duration: 3000,
        });
      }
    },
    deleteEnt() {
      let that = this;
      this.$confirm("确定要删除该商户吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          const api = "/sales/managerBuyer/web/batchDelete";
          const body = { ids: this.ids, buyerIdList: this.buyerIdList };
          axiosApi(
            api,
            body,
            (res) => {
              this.deleteDialog = true;
              this.deleteInfo = res.data.data;
              that.loading();
            },
            1,
            10,
            (res) => {
              this.$message({
                type: "info",
                message: res.data.message,
                duration: 3000,
              });
            }
          );
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    deleteLocation(index, row) {
      // 清空经纬度
      let that = this;
      this.$confirm("您是否要清空该客户经纬度？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          const api = "/sales/traderLngAndLatInfo/delete";
          const body = { traderId: row.buyerId };
          axiosApi(
            api,
            body,
            (res) => {
              this.$message({
                type: "success",
                message: "清空成功!",
                duration: 1000,
              });
              this.loading();
            },
            1,
            10,
            (res) => {
              this.$message({
                type: "info",
                message: res.data.message,
                duration: 3000,
              });
            }
          );
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消清空",
          });
        });
    },
    setInfo(row){
      this.setAddId = row.buyerId
      this.dialogVisible = true
    },
    closeDialog(val){
      this.dialogVisible = false
    }
  },
};
</script>
<style lang="less">
#client {
  position: relative;
  .buttonDiv{
    width: auto !important;
    padding: 0 10px ;
  }
  .el-form-item__label {
    font-size: 12px;
  }
  .el-form--inline .el-form-item {
    margin-right: 0;
  }

  .el-badge.a {
    position: absolute;
    left: 200px;
    top: 11px;
  }

  .el-badge.b {
    position: absolute;
    left: 329px;
    top: 11px;
  }

  .no-title .el-table__header-wrapper {
    display: none;
  }

  .del-dialog {
    .el-dialog {
      min-height: auto;
    }

    .el-dialog__body {
      padding: 20px 20px 90px;
    }
  }
}
</style>
