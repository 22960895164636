<template>
    <!--我的发票-->
    <div id="store-stock-invoice">
        <div class="title-tabs bg-f mb-5">
            <ul class="clearfix">
                <template v-for='(tab,index) in dataTabs' class="item">
                    <li   :type='index' :class="type==index?'active':''" @click='handleTabs' :key="'c'+index">
                        {{tab}}
                        <div :class="type==index?'under-line':''"></div>
                    </li>
                    <div   class="vertical-line" :key="'b'+index"></div>
                </template>
            </ul>
            <el-button v-if="showButton('SUPPLE',100053)" @click="addinvoice">补开发票</el-button>
        </div>
        <el-badge id="" :max="99" :value="notyet" :hidden="notyet == 0"/>
        <!--内容-->
        <div class="content bg-f">
            <div class="otherTip search">
                <el-form :inline="true" label-width="78px" style="width:100%;">
                    <el-form-item label='申请商家：'>
                        <el-input placeholder='请输入申请商家' v-model.trim="buyerName"></el-input>
                    </el-form-item>
                    <el-form-item label='订单编号：'>
                        <el-input placeholder='请输入订单编号' v-model.trim="orderSn" @input="orderSn=orderSn.replace(/[\u4e00-\u9fa5]/ig,'')"></el-input>
                    </el-form-item>
                    <el-button class='color-danger' @click="queryinvoice(true)">查 询</el-button>
                </el-form>
            </div>
            <div class="order-list" v-loading="loading">
                <el-table style="display: flex">
                    <el-table-column label='订单详情' min-width="100"></el-table-column>
                    <el-table-column label='发票金额' align="center" min-width="50"></el-table-column>
                    <el-table-column label='买家' align="center"  min-width="50"></el-table-column>
                    <el-table-column label='发票状态' align="center"  min-width="50"></el-table-column>
                    <el-table-column label='操作' align="center"  min-width="50"></el-table-column>
                </el-table>
                <div class="table-item-default tac" v-if="invoicedata.length<=0">
                    <img src="../../../assets/empty.png" alt=""/>
                    <div class="color-danger mt-30"><span class="cursor"> 暂无数据 </span></div>
                </div>
                <div v-if="invoicedata.length == 0" class="noquery">
                    暂无数据
                </div>
                <div v-else>
                    <div class="table-item mt-20" v-for='item in invoicedata' :key='item.sn'>
                        <div class="clearfix table-item-top">
                            <span class='fl ml-20 color-9' v-if="item.orderInvoiceVo">申请时间：{{item.orderInvoiceVo.gmtCreate | time}}</span>
                            <span class='fl ml-50'>订单编号： {{item.sn}}</span>
                        </div>
                        <div class="table-item-content clearfix" style="display: flex;">
                            <div class=" clearfix" style="flex: 2">
                                <div class="bb clearfix " style="display: flex; padding: 20px 40px 20px 20px;"
                                     v-for='(a,index) in item.orderItemVos' :key='index'>
                                    <div class="fl gift" style="flex: 4">
                                        <div v-if="a.changePurchaseItem" class="triangle-topleft border-red"></div>
                                        <span v-if="a.changePurchaseItem" class="init default">换购</span>
                                        <img v-if="a.image" :src="a.image" class="fl mr-10"/>
                                        <img v-else src="../../../assets/goods.jpg" class="fl mr-10"/>
                                        <p>{{a.skuName}}</p>
                                        <p v-if="a.specInfoNum" class="color-9 mt-20">规格:1*{{a.specInfoNum}}</p>
                                    </div>
                                    <div class="fl pbl-20 tar " style="flex: 1">{{a.num}}{{a.baseUnit}}</div>
                                </div>
                            </div>
                            <div class="bl bb tac pt-20" style="flex: 1">
                                ￥{{(item.needPayMoney-item.freightMoney).toFixed(2)}}
                            </div>
                            <div class="bl bb tac pt-20" style="flex: 1">
                                <div>{{item.buyerName}}</div>
                                <div>{{item.buyerPhone}}</div>
                            </div>
                            <div class="bl bb tac pt-20" style="flex: 1">
                                <p class="font-14">{{item.invoiceStatus == '0' ? '未开票' : '已开票'}}</p>
                            </div>
                            <div class="bl bb tac pt-20" style="flex: 1">
                                <div>
                                    <el-button v-if="item.orderInvoiceVo != null && item.invoiceStatus == '1'"
                                               class="ml-0"
                                               @click="handleInvoiceDetail(item.orderInvoiceVo,item.freightMoney)">发票详情
                                    </el-button>
                                    <el-button
                                            v-if="showButton('INVOICE',100053)&& item.invoiceStatus == '0' && item.orderInvoiceVo != null"
                                            class="ml-0"
                                            @click="writeInvoice(item.orderInvoiceVo,item.sn,item.freightMoney)">开具发票
                                    </el-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <el-pagination
                    class="tar mt-20"
                    :current-page="currentPage"
                    :page-size='10'
                    :total='total'
                    layout="prev , pager, next, jumper"
                    v-if="invoicedata.length>0"
                    @current-change="handleit"
            >
                <!-- 分页组件 -->
            </el-pagination>
        </div>

        <invoice
                :info="invoiceinfo"
                :yunfei="freightMoney"
                :visible="dialogVisible"
                v-on:close="dialogVisible = false">
        </invoice>
        <writeinvoice
                :info="invoiceinfo"
                :sn="sn"
                :yunfei="freightMoney"
                :visible="dialogVisible2"
                v-on:reload="reload"
                v-on:close="dialogVisible2 = false">
        </writeinvoice>
        <!--加入参数isHotelJJ 区分酒店久集和普通B端发票-->
        <addinvoice-hotel
                :visible.sync="dialogVisible1"
                :isHotelJJ="false"
                v-on:close="dialogVisible1 = false"
                v-on:reload="reload">
        </addinvoice-hotel>
    </div>
</template>

<script>
    import {axiosApi, basePath} from '../../../api/api' //导入axiosApi
    export default {
        data() {
            return {
                data: ['1'],
                dataTabs: ['全部发票', '未开票', '已开票'],
                isTitle: false, //控制 是商户的发票true ，还是供应商的发票false
                type: '0',
                total: 0,
                money: '',
                currentPage: 1,
                invoicedata: '', //订单列表
                loading: false,
                dialogVisible: false,
                dialogVisible1: false,
                dialogVisible2: false,
                invoiceinfo: '',
                freightMoney: '',
                buyerName: '',
                orderSn: '',
                sn: '',
                notyet: 0,
            }
        },
        computed: {
            postData() {
                return {
                    traderType: '2',
                    sellerId: sessionStorage.getItem('traderId'),
                    invoiceStatus: this.type == '0' ? '' : this.type == '1' ? '0' : '1',
                    buyerName: this.buyerName,
                    orderSn: this.orderSn
                }
            }
        },
        methods: {
            handleInvoiceDetail(val, val2) { //跳转去发票详情页
                this.dialogVisible = true
                this.invoiceinfo = val ? val : {}
                this.freightMoney = val2 ? val2 : 0
            },
            queryinvoice(bool) {
                if (bool && this.currentPage != 1) {
                    this.currentPage = 1;
                    return
                }
                this.loading = true
                const api = "/anon/order/invoice/query"
                axiosApi(api, this.postData, (res) => {
                    this.callback(res.data.data)
                }, this.currentPage, 10, err => {
                    this.$message.error(err.data.message);
                    this.invoicedata = [];
                    this.loading = false;
                })
            },
            queryinvoicecount() {
                const api = "/order/invoice/makeout/count"

                axiosApi(api, {
                    traderType: '2',
                    sellerId: sessionStorage.getItem('traderId'),
                }, (res) => {
                    this.callback2(res.data.data)
                }, this.currentPage, 10)
            },
            callback(res) {
                if (res) { // 判断数据格式
                    this.invoicedata = res.elements
                    this.total = res.totalCount
                } else {
                    this.invoicedata = []
                    this.total = 0
                }
                this.loading = false
            },
            callback2(res) {
                this.notyet = res.notMakeCount
            },
            handleTabs(e) {
//				if(e.target.type) this.type = e.target.type // 改变 type的值
                if (e.target.attributes.type) this.type = e.target.attributes.type.value
                this.buyerName = ''
                this.orderSn = ''
                this.queryinvoice(1)
            },
            handleit(val) {
                this.currentPage = val
                this.queryinvoice()
            },
            addinvoice() {
                this.dialogVisible1 = true
            },
            writeInvoice(val1, val2, val3) {
            	console.log(val1, val2, val3);
                this.dialogVisible2 = true
                this.invoiceinfo = val1 ? val1 : {}
                this.sn = val2 ? val2 : ''
                this.freightMoney = val3 ? val3 : ''
            },
            reload() {
                this.queryinvoice(1)
                this.queryinvoicecount()
            }
        },
        activated() {
            this.$nextTick(() => {
                this.queryinvoice(1)
                this.queryinvoicecount()
            })
        }
    }
</script>

<style lang="less">
    /*MyTabs 自定义*/
    .noquery {
        height: 50px;
        text-align: center;
        line-height: 50px;
        border-left: 1px solid #ddd;
        border-right: 1px solid #ddd;
        border-bottom: 1px solid #ddd;
    }

    #store-stock-invoice {
        position: relative;

        .el-badge {
            position: absolute;
            left: 167px;
            top: 14px;
        }

        .title {
            width: 1000px;
            padding: 30px;
            margin-bottom: 5px;
            box-sizing: border-box;
        }

        .content {
            padding: 30px 30px 10px;
            box-sizing: border-box;

            .tip {
                border: 1px solid #DDD;
                height: 82px;
                padding: 20px;
                box-sizing: border-box;
                line-height: 16px;

                .tip-item {
                    margin-left: 25px;
                    margin-top: 10px;
                }
            }

            .otherTip {
                .el-form {
                    .el-form-item {
                        margin-bottom: 30px;
                        margin-right: 30px;
                    }

                    .el-form-item__label {
                        width: 58px;
                        font-size: 12px;
                    }

                    .el-form-item__inner {
                        font-size: 12px;
                    }
                }
            }

            .el-table .el-table__body-wrapper {
                display: none;
            }

            .el-table_1_column_1.is-leaf .cell {
                text-align: center;
            }

            .table-item {
                .bl {
                    border-left: 1px solid #DDDDDD;
                }

                border: 1px solid #DDDDDD;
                font-size: 14px;

                .table-item-content .pbl-20 {
                    padding: 0px 0px 20px 20px;
                }

                .table-item-content {
                    .gift {
                        position: relative;
                    }

                    .triangle-topleft {
                        border-top: 60px solid #ff4201;
                        width: 0;
                        height: 0;
                        border-right: 60px solid transparent;
                        position: absolute;
                        top: 0;
                        left: 0;
                    }

                    .init {
                        transform: rotate(-45deg) translate(-4px, 10px);
                        position: absolute;
                        left: 0;
                        top: 0;
                        color: #fff;
                        letter-spacing: -0.34px;
                    }
                }

                .table-item-top {
                    border-bottom: 1px solid #DDDDDD;
                    padding: 10px 0;

                    .table-item-top-checkbox {
                        margin-left: 14px
                    }
                }

                img {
                    width: 80px;
                    height: 80px;
                }
            }

            .table-item-default {
                height: 530px;
                padding-top: 120px;
            }
        }
    }
</style>
