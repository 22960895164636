<template>
	<div class="supplier-sale-area">
		<div class="title-top bg-f">
			地区管理
		</div>
		<div class="bg-f mt-5 pl-30 pr-30 pt-20 pb-50">
			<div class="mb-30 clearfix mt-30">
				<div class="fl height-60 clearfix">
					<span class="ml-20 mr-20 mt-20 fl">标题：</span><span class="fl mt-20">{{adInfo.advertisementName}}</span>
				</div>
				<div class="fl height-60 clearfix">
					<span class="ml-100 fl mt-20">图片：</span>
					<img v-if="adInfo.imgUrl" class="icon-60 fl ml-30" :src="adInfo.imgUrl" />
					<img v-else class="icon-60 fl ml-30" :src="adInfo.imgUrl" />
				</div>

			</div>
			<div class="ml-30 fl bd mb-50">
				<el-checkbox class="ml-20 mt-20 p-5" v-model="isCountrywide" :indeterminate="isIndeterminate" @change="iscountry">全国</el-checkbox>
				<div class="mt-20  clearfix" v-for="(item1,index1) in areas" :key="index1">
					<div class="fl w100 pl-20">
						<el-checkbox class="p-5 checkbox" v-model="item1.checkAll" :indeterminate="item1.isIndeterminate" @change="provincechange(item1)">{{item1.name}}</el-checkbox>
					</div>
					<div class="fl w670">
						<div class="dib w165 mb-10 clearfix" v-for="(item2,index2) in item1.childs" :key="index2">
							<el-checkbox v-model="item2.checked" :indeterminate="item2.isIndeterminate" @change="citychange(item2,item1)" :class="{bgc:item2.display}" class="p-5">
								<span :title="item2.name" class="text-overflow top dib" style="width:75px">{{item2.name}}</span>
								<!--<span class="color-danger font-12" v-if="item2.checkedAreas.length>0">({{item2.checkedAreas.length}})</span>-->
							</el-checkbox>
							<!--<i class="cursor color-danger" :class="{'el-icon-caret-bottom':!item2.display,'el-icon-caret-top':item2.display}" @click="cityclick(item2,$event)"></i>
							<el-checkbox-group class="region" v-model="item2.checkedAreas" @change="regionchange(item2,item1)" v-if="item2.display" :class="{zindex:item2.zindex}">
								<el-checkbox v-for="item3 in item2.childs" :label="item3.id" ><span :title="item3.name" class="text-overflow dib w110 top">{{item3.name}}</span></el-checkbox>
							</el-checkbox-group>-->
						</div>
					</div>
				</div>
			</div>
			<div class="ml-30">
				<el-button type="primary" @click="handleSave">保存</el-button>
			</div>
		</div>
	</div>
</template>

<script>
	import { axiosApi, basePath } from '../../api/api'; // 导入API
	import { timeStamp } from '../../main.js' //导入时间戳转化函数
	import { MessageBox } from 'element-ui';
	let areaList = JSON.parse(localStorage.getItem('areaList'))
	export default {
		data() {
			return {
				areas: (() => { //添加属性
					areaList.forEach((item1, index1) => {
						this.$set(item1, "checkAll", false) //省 是否 选中
						this.$set(item1, "isIndeterminate", false) //省 是否  部分选中
						item1.childs.forEach((item, index) => {
							this.$set(item, "display", false) //区 是否显示
							this.$set(item, "display", false) //区 是否显示
							this.$set(item, "zindex", false) //控制最后显示的区  在最上层
							this.$set(item, "checked", false) //市 是否 选中
							this.$set(item, "checkedAreas", []) //存储 选中区 对应的id
							this.$set(item, "isIndeterminate", false) //市  是否 部分选中
							delete item.childs
						})
					})
					return areaList
				})(), //全国各省
				isCountrywide: false, //全国是否全选
				isIndeterminate: false, //全国是否部分选中
				authorizationRegion: [],
				adInfo: {}
			}
		},
		mounted() {
			this.$nextTick(function() {
				this.adInfo = JSON.parse(sessionStorage.adInfo)
				this.achieve()
			})
		},
		methods: {
			achieve(){
				if(this.adInfo.area){
					JSON.parse(this.adInfo.area).forEach(item1 => { //返回数据的省
							this.areas.forEach(item2 => { //总数据的省
								if(item1.id == item2.id) { //省相等
									item1.sub.forEach(item3 => { //返回数据的市
										item2.childs.forEach(item4 => { //总数据的市
											if(item3.id == item4.id) { //市相等
//												console.log(item4, "4")
//												item4.checkedAreas = item3.sub.map(item5 => {
//													return item5.id.toString()
//												})
												item4.checked = true
//												item4.checked = item4.checkedAreas.length == item4.childs.length
//												item4.isIndeterminate = item4.checkedAreas.length > 0 && item4.checkedAreas.length < item4.childs.length
												let arr = item2.childs.filter(item6 => {
													return item6.checked
												})
												item2.checkAll = arr.length == item2.childs.length
												let b = item2.childs.filter(item6 => {
													return item6.isIndeterminate
												})
												item2.isIndeterminate = (arr.length > 0 || b.length > 0) && arr.length < item2.childs.length
												let c = this.areas.filter(item6 => {
													return item6.isIndeterminate
												})
												let d = this.areas.filter(item6 => {
													return item6.checkAll
												})
												if(JSON.parse(this.adInfo.area).length == this.areas.length){
													this.isCountrywide = true
												}else{
													this.isIndeterminate = (c.length > 0 || d.length > 0) && d.length < this.areas.length
												}

											}
										})
									})
								}
							})
						})
				}
			},
			cityclick(item, e) {
				item.display = !item.display
				item.zindex = !item.zindex
				this.areas.forEach(item1 => {
					item1.childs.forEach(item2 => {
						if(item != item2) {
							item2.zindex = false
						}
					})
				})
			},
			//区选择
			regionchange(item, item1) {
				item.checked = item.checkedAreas.length == item.childs.length
				this.isCheckAll(item1, item)
			},
			//市选择
			citychange(item, item1) {
//				if(item.checked) {
//					item.checkedAreas = item.childs.map(item2 => {
//						return item2.id
//					})
//				} else {
//					item.checkedAreas = []
//				}
				this.isCheckAll(item1, item)
			},
			//省选择
			provincechange(item) {
				if(item.checkAll) {
					item.childs.forEach(item1 => {
						item1.checked = true
						this.citychange(item1, item)
					})
				} else {
					item.childs.forEach(item1 => {
						item1.checked = false
						this.citychange(item1, item)
					})
				}
				this.iscountryall(item)
			},
			//判断全省是否全选
			isCheckAll(item, item1) {
				let arr = item.childs.filter(item1 => {
					return item1.checked
				})
				item.checkAll = arr.length == item.childs.length;
				this.iscountryall(item, item1)
			},
			//全国选择
			iscountry() {
				if(this.isCountrywide) {
					this.areas.forEach(item1 => {
						item1.checkAll = true
						this.provincechange(item1)
					})
				} else {
					this.areas.forEach(item1 => {
						item1.checkAll = false
						this.provincechange(item1)
					})
				}
			},
			//判断全国是否全选
			iscountryall(item, item1) {
				let arr = this.areas.filter(item2 => {
					return item2.checkAll
				})
				this.isCountrywide = this.areas.length == arr.length
				//市 是否 部分选中
				if(item1) { //对于省的操作需要判断一下
					item1.isIndeterminate = item1.checkedAreas.length > 0 && item1.checkedAreas.length < item1.childs.length
				}
				let a = item.childs.filter(item3 => {
					return item3.checked
				})
				let c = item.childs.filter(item3 => {
					return item3.isIndeterminate
				})
				//省 是否 部分选中
				item.isIndeterminate = (a.length > 0 || c.length > 0) && a.length < item.childs.length
				//全国是否部分选中
				let b = this.areas.filter(item3 => {
					return item3.isIndeterminate
				})
				this.isIndeterminate = (b.length > 0 || arr.length > 0) && arr.length < this.areas.length
			},
			//			保存
			handleSave() {
				this.authorizationRegion = []
				this.areas.forEach(item => { //省
					if(item.isIndeterminate || item.checkAll) {
						let arr = []
						item.childs.forEach(item1 => { //市
							if(item1.checked) {
								arr.push({
									"id": item1.id
								})
							}
						})
						this.authorizationRegion.push({
							"id": item.id,
							"sub": arr
						})
					}
				})
				this.adInfo.area = this.authorizationRegion
				axiosApi("/sales/advertisement/web/update", this.adInfo, (res) => {
					this.$router.push({
					path: basePath + '/advertisement'
				})
					this.$message({
						type: 'success',
						message: '保存成功!',
						duration: 1000
					});
				});
			},
		}
	}
</script>

<style lang="less">
	.supplier-sale-area {
		width: 970px;
		margin: 30px auto;
		.region {
			position: absolute;
			z-index: 10;
			width: 160px;
			padding-top: 10px;
			top: 30px;
			left: 0;
			background: #ffffc3;
			.el-checkbox {
				margin: 5px;
			}
		}
		.zindex {
			z-index: 20;
		}
		.bgc {
			background-color: #ffffc3;
		}
		.w85 {
			max-width: 85px;
		}
		.w100 {
			width: 100px;
		}
		.w110 {
			max-width: 110px;
		}
		.w165 {
			width: 165px;
			position: relative;
		}
		.w670 {
			width: 670px;
		}
		.ml-118 {
			margin-left: 118px;
		}
		.height-60 {
			height: 60px;
		}
		.el-checkbox{
			display: flex;
			align-items: center;
			.el-checkbox__label{
				width: 70px;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}
	}
</style>