<template>
    <div id="foodManage" class="">

        <div class="title-top bg-f mb-5">
            <div class="clearfix black">
                <el-breadcrumb class="fl ">
                    <el-breadcrumb-item>菜品管理</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
        </div>

        <div class="content bg-f pl-30 pr-30 pb-50">
            <div class="tab_box">
                <div class="tab_item" :class="{tab_item_checked: item.type==state}" v-for="(item,index) in tabList"
                     :key="index" @click="checkState(item)">
                    <span>{{item.label}}</span>
                    <span>({{item.bage ? item.bage : 0}})</span>
                </div>
            </div>

            <div class="clearfix search">
                <!-- 搜索区域 -->
                <el-form :inline="true" :model="form" label-width="60px" class="clearfix mt-30">
                    <div class="fl">
                        <el-form-item label="菜品名称" prop="foodName">
                            <el-input v-model.trim="form.foodName" placeholder="输入菜品名称"
                                      @keyup.enter.native='achieve(true)'></el-input>
                        </el-form-item>
                        <el-form-item label="菜品分类" class="ml-20">
                            <el-select clearable @keyup.enter.native='achieve(true)'
                                       v-model.trim="form.foodClass"
                                       placeholder="选择菜品分类">
                                <el-option
                                        v-for="item in options"
                                        :key="item.id"
                                        :label="item.classifyName"
                                        :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                    <el-button type="info" class="fl ml-30" @click='achieve(true)'>查询</el-button>
                </el-form>

                <!-- 按钮 -->
                <div class='clearfix mt-10'>
                    <span class="fr color-blue cursor mr-20"
                          @click="toFoodAdd()">添加菜品</span>
                    <span class="fr color-blue cursor mr-20"
                          @click="toFoodTypeAdd()">菜品分类管理</span>
                    <span class="fr color-blue cursor mr-20"
                          @click="toSpecificationManage()">规格管理</span>
                </div>

                <!-- 列表 -->
                <el-table :data="tableData" v-loading="Loading" class='mt-20 table-default' stripe>
                    <el-table-column prop="1" label="菜品名称" width="350">
                        <template slot-scope="scope">
                            <div class="info flex clearfix ">
                                <div class="ml-10">
                                    <img v-if='scope.row.mainFileUrl' :src="scope.row.mainFileUrl"/>
                                    <img v-else src="../../assets/goods.jpg"/>
                                </div>
                                <div class="flex-1 ml-10 mt-10 hidden">
                                    <div class="tal ">{{scope.row.skuName}}</div>
                                    <div class="tal">菜品编码:{{scope.row.skuNo}}</div>
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="菜品分类" prop="classifyName"></el-table-column>
                    <el-table-column label="单位" prop="unitKey"></el-table-column>
                    <el-table-column label="零售价">
                        <template slot-scope="scope">
                            <span v-if="scope.row.price">￥{{scope.row.price | fmoney}}</span>
                            <span v-else>时价</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="规格" prop="">
                        <template slot-scope="scope">
                            <span>{{scope.row.specList}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="100">
                        <template slot-scope="scope">
                            <el-button size="small" type='text'
                                       @click="toFoodAdd(scope)">编辑
                            </el-button>
                            <el-button v-if="scope.row.skuState==1" class='ml-0 width-90' size="small" type='text'
                                       @click="upDown(scope,2)">上架
                            </el-button>
                            <el-button v-else class='ml-0 width-90' size="small" type='text'
                                       @click="upDown(scope,1)">下架
                            </el-button>
                            <el-button v-if="scope.row.isRecommend==1&&scope.row.skuState==2" class='ml-0 width-90'
                                       size="small" type='text'
                                       @click="setRecommend(scope,0)">取消推荐
                            </el-button>
                            <el-button v-else-if="scope.row.isRecommend==0&&scope.row.skuState==2" class='ml-0 width-90'
                                       size="small" type='text'
                                       @click="setRecommend(scope,1)">设为推荐
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>

                <!-- 分页组件 -->
                <el-pagination v-if="total>0" class="tar mr-30" :current-page="currentPage" :page-size='10'
                               :total='total'
                               layout="prev , pager, next, jumper" @current-change="handleCurrentChange">
                </el-pagination>
            </div>
        </div>

    </div>
</template>

<script>
    import {axiosApi, basePath} from "../../api/api";

    export default {
        data() {
            return {
                tabList: [{
                    label: '未上架',
                    type: 1,
                    bage: 0
                }, {
                    label: '已上架',
                    type: 2,
                    bage: 0
                }],
                options: [],
                state: 1,
                Loading: true,
                form: { //搜索条件
                    foodName: null,
                    foodClass: null,
                },
                tableData: [], //员工列表
                currentPage: 1,
                total: 0,
                rules:
                    {
                        foodName: [{required: true, message: '请输入名称'}, {min: 1, max: 30, message: '名称最多30个字符'}],
                    },
            }
        },
        methods: {
            toFoodAdd(scope) {
                if (scope) {
                    this.$router.push({path: 'foodAdd', query: {skuNo: scope.row.skuNo, type: this.state}});
                } else {
                    this.$router.push({path: 'foodAdd', query: null});
                }
            },
            toFoodTypeAdd() {
                this.$router.push({path: 'foodTypeAdd', query: null})
            },
            toSpecificationManage() {
                this.$router.push({path: 'specificationManage', query: null})
            },
            //加载员工列表
            achieve(bool) {
                this.Loading = true;
                if (bool && this.currentPage != 1) {
                    this.currentPage = 1;
                }
                const api = "/catering/platform/foodSku/selectPage";
                const body = {
                    skuName: this.form.foodName ? this.form.foodName : null,
                    classifyId: this.form.foodClass,
                    skuState: this.state
                }
                axiosApi(api, body, this.callback, this.currentPage, 10)
            },
            callback(res) {
                this.tableData = res.data.data.elements
                this.tableData.forEach(item => {
                    let type = '';
                    if (item.specList.length != 0) {
                        item.specList.forEach(item1 => {
                            type = type + item1.name + '/';
                        })
                        type = type.substr(0, type.length - 1);
                    }
                    item.specList = type;
                })
                this.total = res.data.data.totalCount
                this.Loading = false
            },
            //			分页
            handleCurrentChange(val) {
                this.currentPage = val
                this.achieve()
            },
            checkState(val) {//切换状态
                this.state = val.type
                this.currentPage = 1
                this.achieve()
            },
            getClass() {
                axiosApi('/catering/platform/foodClassify/selectList', {}, (res) => {
                    this.options = res.data.data.filter(item => {
                        return item.isDisabled == false && item.isDeleted == false;
                    })
                }, this.currentPage, 10)
            },
            getBage() {//获取角标
                axiosApi('/catering/platform/foodSkuStateCount/select', {}, (res) => {
                    this.tabList.forEach(item => {
                        if (item.type == 1) {
                            item.bage = res.data.data.offTheShelfCount;
                        } else if (item.type == 2) {
                            item.bage = res.data.data.onTheShelfCount;
                        }
                    })
                }, 1, 10)
            },
            upDown(scope, skuState) {
                var type = skuState == 1 ? "下架" : "上架";

                this.$confirm("将" + type + "菜品, 是否继续?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                }).then(() => {
                    axiosApi('/catering/platform/foodSku/update', {
                        skuNo: scope.row.skuNo,
                        price: scope.row.price,
                        skuState: skuState,
                    }, (res) => {
                        this.$message({
                            type: 'success',
                            duration: 1000,
                            message: type + "成功!",
                        });
                        this.getBage();
                        this.achieve(true);
                    }, this.currentPage, 10, (res) => {
                        this.$message.error(res.data.message)
                    })
                }).catch(() => {
                    this.$message({
                        message: "已取消" + type,
                    });
                });
            },
            setRecommend(scope, isRecommend) {
                var type = isRecommend ? "推荐" : "取消推荐";

                this.$confirm("将" + type + "菜品, 是否继续?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                }).then(() => {
                    axiosApi('/catering/platform/foodSku/update', {
                        skuNo: scope.row.skuNo,
                        price: scope.row.price,
                        isRecommend: isRecommend,
                    }, (res) => {
                        this.$message({
                            type: 'success',
                            duration: 1000,
                            message: type + "成功!",
                        });
                        this.achieve(true);
                    }, this.currentPage, 10, (res) => {
                        this.$message.error(res.data.message)
                    })
                }).catch(() => {
                    this.$message({
                        message: "已取消" + type,
                    });
                });
            },
        },
        //进入加载
        mounted() {
            this.$nextTick(function () {
                this.state = this.$route.query.type ? this.$route.query.type : 1;
                this.achieve();
                this.getBage();
                this.getClass();
            })
        }
    }
</script>

<style lang="less">
    #foodManage {
        .tab_box {
            height: 60px;
            line-height: 60px;
            box-sizing: border-box;
            border-bottom: 1px solid #eee;

            .tab_item {
                display: inline-block;
                width: 114px;
                height: 26px;
                line-height: 26px;
                text-align: center;
                box-sizing: border-box;
                color: #666;
                font-size: 14px;
                cursor: pointer;
            }

            .tab_item:hover {
                color: #FF4201;
            }

            .tab_item_checked {
                border-radius: 2px;
                border: 1px solid #FF4201;
                color: #FF4201;
            }
        }

        .title-top {
            border: 0;
        }

        .black {
            .edit-btn span {
                color: #000000 !important;
            }
        }
    }
</style>