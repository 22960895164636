<template>
    <div id="groupStatementDetail">
        <div class="title-top bg-f">详细数据</div>
        <div class="bg-f mt-5 pb-50" v-loading='loading'>
            <div class="clearfix search">
                <el-form :inline='true' class="pt-30 fl" :model="form">
                    <div class='fl ml-30' style="font-size: 14px;margin-top: 7px;"  >团购时间：{{$route.query.gmtStart-'' | timenData }}  到 {{$route.query.gmtEnd-'' | timenData }}  </div>
                    <el-form-item label='数据日期' class='ml-20'>
                        <ys-daterange-picker v-model="form.orderDateRange" :endDisabledDate="endDisabledDate"
                                             :missdistance="31"/>
                    </el-form-item>
                </el-form>
                <el-button class="fl ml-30 mt-30"  icon="el-icon-search" type='info' @click="achieveOne">查询</el-button>
            </div>
            <div class="pl-30 mt-50">
                <div class="title clearfix">
                    <p class="title-name fl"></p>
                    <p class="fl pl-5 title-word">交易统计</p>
                </div>
                <div class="clearfix mt-30">
                    <div class="fl width-490 border-d">
                        <ul class="bg-e" v-for="item in dataA" >
                            <li class="fl li-word tac">{{item}}</li>
                        </ul>
                        <ul class="bg-e">
                            <li class="fl li-word tac bg-f">{{allData.pageView}}</li>
                            <li class="fl li-word tac bg-f">{{allData.uniqueVisitor}}</li>
                            <li class="fl li-word tac bg-f">{{allData.orderPersonNum}}</li>
                            <li class="fl li-word tac bg-f">{{allData.orderNum}}</li>
                            <li class="fl li-word tac bg-f">&yen;{{parseFloat(allData.orderTotalMoney).toFixed(2)}}</li>
                        </ul>
                        <ul class="bg-e" v-for="item in dataB" >
                            <li class="fl li-word tac">{{item}}</li>
                        </ul>
                        <ul class="bg-e">
                            <li class="fl li-word tac bg-f">{{allData.paidPersonNum}}</li>
                            <li class="fl li-word tac bg-f">{{allData.paidNum}}</li>
                            <li class="fl li-word tac bg-f">&yen;{{parseFloat(allData.paidTotalMoney).toFixed(2)}}</li>
                            <li class="fl li-word tac bg-f">&yen;{{parseFloat(allData.perTransaction).toFixed(2)}}</li>
                            <li class="fl li-word tac bg-f">&yen;{{parseFloat(allData.commission).toFixed(2)}}</li>
                        </ul>
                    </div>
                    <div class="fl" style="margin-left: 72px;position: relative;width: 400px;">
                        <img src="../../assets/triangle.png"/>
                        <div class="triangle-word" style="position: absolute;left: 205px;top: 50px;">
                            <span class="color-6 font-12">下单转化率</span>
                            <br/>
                            <span class="color-6 font-12">{{a}}%</span>
                        </div>
                        <div class="triangle-word color-6 font-12" style="position: absolute;left: 269px;top: 90px;">
                            <span class="color-6 font-12">成交转化率</span>
                            <br/>
                            <span class="color-6 font-12">{{c}}%</span>
                        </div>
                        <div class="triangle-word color-6 font-12" style="position: absolute;left: 168px;top: 120px;">
                            <span class="color-6 font-12">付款转化率</span>
                            <br/>
                            <span class="color-6 font-12">{{b}}%</span>
                        </div>
                    </div>
                </div>
            </div>
            <div id="echarts" class="mt-30"></div>
            <div class="pl-30 mt-50">
                <div class="title clearfix">
                    <p class="title-name fl"></p>
                    <p class="fl pl-5 title-word">流量统计</p>
                </div>
                <div id="echarts-second"></div>
                <div class="mt-50">
                    <el-table :data="tableDataOne" v-loading="Loading" class='ml-30 mt-10 table-default mr-30' stripe>
                        <el-table-column label="日期" width="300">
                            <template slot-scope="scope">
                                <div>{{ scope.row.gmtStatistics}}</div>
                                <!-- <div>{{scope.row.gmtCreate | timenData}}</div> -->
                            </template>
                        </el-table-column>
                        <el-table-column label="浏览次数" width="300" prop='pageView'></el-table-column>
                        <el-table-column label="浏览人数" prop='uniqueVisitor'></el-table-column>
                    </el-table>
                    <el-pagination class="tar mt-20" v-if="totalOne>0" :current-page.sync="currentPageOne"
                                   :page-size='10' :total='totalOne' layout="prev , pager, next, jumper"
                                   @current-change="handleCurrentChangeOne">
                        <!-- 分页组件 -->
                    </el-pagination>

                </div>
            </div>

            <div class="pl-30 mt-50">
                <div class="title clearfix">
                    <p class="title-name fl"></p>
                    <p class="fl pl-5 title-word">销量统计</p>
                </div>
                <div class="mt-50">
                    <el-table :data="tableDatatwo" v-loading="Loading" class='ml-30 mt-10 table-default mr-30' stripe>
                        <el-table-column label="商品名称" width="300" prop='skuName'></el-table-column>
                        <el-table-column label="付款人数" width="200" prop='goodsPersonNum'></el-table-column>
                        <el-table-column label="销售数量" width="200" prop='goodsNum'></el-table-column>
                        <el-table-column label="销售金额(元)">
                            <template slot-scope="scope">
                                <div>&yen;{{parseFloat(scope.row.goodsTotalMoney).toFixed(2)}}</div>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-pagination class="tar mt-20" v-if="totalTwo>0" :current-page.sync="currentPageTwo"
                                   :page-size='10' :total='totalTwo' layout="prev , pager, next, jumper"
                                   @current-change="handleCurrentChangeTwo">
                        <!-- 分页组件 -->
                    </el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    // 引入 ECharts 主模块
    var echarts = require('echarts/lib/echarts');
    // 引入折线图
    require('echarts/lib/chart/line');
    // 引入提示框和标题组件
    require('echarts/lib/component/tooltip');
    require('echarts/lib/component/legend');
    import axios from 'axios';
    import {axiosApi, basePath, baseURI} from "../../api/api";

    export default {
        data() {
            return {
                Loading: false,
                loading: false,
                form: {
                    dataSources: '',
                    orderDateRange: [],
                    startDate: '',
                    endDate: ''
                },
                allData: {},//所有数据
                dataA: [
                    '浏览次数',
                    '浏览人数',
                    '下单人数',
                    '下单数',
                    '应付金额'
                ],
                dataAA: [],
                dataB: [
                    '付款人数',
                    '付款订单数',
                    '实付金额',
                    '客单价',
                    '佣金营收'
                ],
                dataBA: [],
                a: '',//下单转化率
                b: '',//付款转换率
                c: '',//成交转化率
                tableDataOne: [],
                tableDatatwo: [],
                totalOne: 0,
                totalTwo: 0,
                currentPageTwo: 1,
                currentPageOne: 1,
                endDisabledDate:null
            }
        },
        created() {
            console.log(this.$route.query);
            let info =this.$route.query;
            if (info.type=="2")                            //进行中只能查看当前日期
             {  let aa = this.getDay(0);
                let bb = this.getDay(-6);
                this.form.orderDateRange[0] = new Date(bb);
                this.form.orderDateRange[1] = new Date(aa); 
                this.endDisabledDate = new Date()
             }
             else {
                let endDate = new Date(info.gmtEnd-'');
                console.log(endDate);
                this.form.orderDateRange[1] = endDate
                let startDate = new Date( info.gmtStart-'');
                // startDate.setDate(endDate.getDate() - 6);
                this.form.orderDateRange[0] = startDate;
                this.endDisabledDate = endDate
             }

           
        },
        //进入加载
        mounted() {
            this.$nextTick(() => {
                this.achieveOne()
            })
        },
        methods: {
            setEchart() {
                var myChart = echarts.init(document.getElementById('echarts'));
                var myCharts = echarts.init(document.getElementById('echarts-second'));
                let dataArr = []//日期数组
                let arr = [] //数据数组
                //取出日期放进数组
                for (let item in this.allData.grouponStatisticsMap) {
                    console.log(item, '日期')
                    dataArr.unshift(item)

                }
                dataArr.sort()
                dataArr.forEach(item => {
                    arr.push(this.allData.grouponStatisticsMap[item])
                })
                let orderPersonNum = [] //下单人数
                let orderNum = [] //下单数
                let orderTotalMoney = [] //应付金额
                let paidPersonNum = [] //付款人数
                let paidNum = [] //付款订单数
                let paidTotalMoney = [] //实付金额
                let pageView = [] //浏览次数
                let uniqueVisitor = [] //浏览人数
                arr.forEach(item => {
                    orderPersonNum.push(item.orderPersonNum)
                    orderNum.push(item.orderNum)
                    orderTotalMoney.push(item.orderTotalMoney)
                    paidPersonNum.push(item.paidPersonNum)
                    paidNum.push(item.paidNum)
                    paidTotalMoney.push(item.paidTotalMoney)
                    pageView.push(item.pageView)
                    uniqueVisitor.push(item.uniqueVisitor)
                })
                // 绘制图表
                myChart.setOption({
                    title: {
                        text: '折线图堆叠'
                    },
                    tooltip: {
                        trigger: 'axis'
                    },
                    legend: {
                        data: ['下单人数', '下单数', '应付金额', '付款人数', '付款订单数', '实付金额']
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },
                    toolbox: {
                        feature: {

                        }
                    },
                    xAxis: {
                        type: 'category',
                        boundaryGap: false,
                        data: dataArr
                    },
                    yAxis: {
                        type: 'value'
                    },
                    series: [{
                        name: '下单人数',
                        type: 'line',
                        stack: '总量',
                        data: orderPersonNum
                    },
                        {
                            name: '下单数',
                            type: 'line',
                            stack: '总量',
                            data: orderNum
                        },
                        {
                            name: '应付金额',
                            type: 'line',
                            stack: '总量',
                            data: orderTotalMoney
                        },
                        {
                            name: '付款人数',
                            type: 'line',
                            stack: '总量',
                            data: paidPersonNum
                        },
                        {
                            name: '付款订单数',
                            type: 'line',
                            stack: '总量',
                            data: paidNum
                        },
                        {
                            name: '实付金额',
                            type: 'line',
                            stack: '总量',
                            data: paidTotalMoney
                        }
                    ]
                });
                // 绘制图表
                myCharts.setOption({
                    title: {
                        text: '折线图堆叠'
                    },
                    tooltip: {
                        trigger: 'axis'
                    },
                    legend: {

//						y: 'bottom', // 'center' | 'bottom' | {number}
                        data: ['浏览次数', '浏览人数']
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },
                    toolbox: {
                        feature: {

                        }
                    },
                    xAxis: {
                        type: 'category',
                        boundaryGap: false,
                        data: dataArr
                    },
                    yAxis: {
                        type: 'value'
                    },
                    series: [{
                        name: '浏览次数',
                        type: 'line',
                        stack: '总量',
                        data: pageView
                    },
                        {
                            name: '浏览人数',
                            type: 'line',
                            stack: '总量',
                            data: uniqueVisitor
                        }
                    ]
                });

            },
            //获取最近3天
            getDay(day) {
                var today = new Date();
                var targetday_milliseconds = today.getTime() + 1000 * 60 * 60 * 24 * day;
                today.setTime(targetday_milliseconds);
                var tYear = today.getFullYear();
                var tMonth = today.getMonth();
                var tDate = today.getDate();
                tMonth = (tMonth + 1).toString().padStart(2 , "0");
                tDate = tDate.toString().padStart(2,"0");
                return tYear + "-" + tMonth + "-" + tDate + ( day >= 0 ? " 23:59:59" : " 00:00:00" );
            },
            /*//获取最近3天
            getDays(day) {
                var today = new Date();
                var targetday_milliseconds = today.getTime() + 1000 * 60 * 60 * 24 * day;
                today.setTime(targetday_milliseconds);
                var tYear = today.getFullYear();
                var tMonth = today.getMonth();
                var tDate = today.getDate();
                tMonth = this.doHandleMonth(tMonth + 1);
                tDate = this.doHandleMonth(tDate);
                let aa = tYear + "-" + tMonth + "-" + tDate;
                return tYear + "-" + tMonth + "-" + tDate + " 23:59:59";
            },*/

            /*doHandleMonth(month) {
                var m = month;
                if (month.toString().length == 1) {
                    m = "0" + month;
                }
                return m;
            },*/
            //获取eacharts数据
            achieveOne(payload) {
                console.log(this.form.orderDateRange, 'this.form.orderDateRange')
                this.form.startDate = this.form.orderDateRange[0] ? this.form.orderDateRange[0].getTime() : null;
                this.form.endDate = this.form.orderDateRange[1] ? this.form.orderDateRange[1].getTime() : null;
                if (!this.form.startDate || !this.form.endDate) {
                    this.$message({
                        type: 'warning',
                        message: '请选择完整的时间',
                        duration: 1000
                    })
                } else {
                    axiosApi('/groupon/statisticsGroupon/query', {
                        startDate: this.form.startDate,
                        endDate: this.form.endDate,
                        grouponId: this.$route.params.id,
                        source: this.form.dataSources
                    }, (res) => {
                        this.currentPageTwo = 1
                        this.currentPageOne = 1
                        this.allData = res.data.data
                        this.a = this.allData.uniqueVisitor != 0 ? parseInt(this.allData.orderPersonNum * 100 / this.allData.uniqueVisitor).toFixed(2) : 0.00 //下单
                        this.b = this.allData.orderPersonNum != 0 ? parseInt(this.allData.paidPersonNum * 100 / this.allData.orderPersonNum).toFixed(2) : 0.00 //付款
                        this.c = this.allData.uniqueVisitor != 0 ? parseInt(this.allData.paidPersonNum * 100 / this.allData.uniqueVisitor).toFixed(2) : 0.00 //成交
                        this.setEchart()
                        this.achievetwo()
                        this.achievethree()
                    }, this.currentPageSalesman, 10, (res) => {
                        this.$message({
                            type: 'error',
                            message: res.data.message,
                            duration: 1000
                        })
                    })
                }

            },
            //获取第一个表单
            achievetwo(payload) {
                axiosApi('/groupon/statisticsGroupon/view/query', {
                    startDate: this.form.startDate,
                    endDate: this.form.endDate,
                    grouponId: this.$route.params.id,
                    source: this.form.dataSources
                }, (res) => {
                    this.tableDataOne = res.data.data.elements ? res.data.data.elements : []
                    this.totalOne = res.data.data.totalCount
                    console.log(res.data.data, 'res1')

                }, this.currentPageOne, 10, (res) => {
                    this.$message({
                        type: 'error',
                        message: res.data.message,
                        duration: 1000
                    })
                })
            },
            //获取第二个表单
            achievethree(payload) {
                axiosApi('/groupon/statisticsGroupon/goods/query', {
                    startDate: this.form.startDate,
                    endDate: this.form.endDate,
                    grouponId: this.$route.params.id,
                    source: this.form.dataSources
                }, (res) => {
                    this.tableDatatwo = res.data.data.elements ? res.data.data.elements : []
                    this.totalTwo = res.data.data.totalCount
                }, this.currentPageTwo, 10, (res) => {
                    this.$message({
                        type: 'error',
                        message: res.data.message,
                        duration: 1000
                    })
                })
            },
            //获取团购
            Getachieve(body) {
				this.loading1 = true
				axiosApi('/groupon/statisticsGroupon/list', body, (res) => {
					this.tableData = res.data.data.elements ? res.data.data.elements : []
				}, (res) => {
					this.$message({
						type: 'error',
						message: res.data.message,
						duration: 1000
					})
				})
			},

            //第一个表格分页
            handleCurrentChangeOne(val) {
                this.currentPageOne = val
                this.achievetwo()
            },
            handleCurrentChangeTwo(val) {
                this.currentPageTwo = val
                this.achievethree()
            }
        }
    }
</script>

<style lang="less">
    #groupStatementDetail {
        .pl-5 {
            padding-left: 5px;
        }

        .width-490 {
            width: 490px;
        }

        .height-50 {
            height: 50px;
        }

        .li-word {
            width: 98px;
            height: 50px;
            line-height: 50px;
            background: #EEEEEE;
        }

        .border-d {
            border: 1px solid #DDDDDD;
        }

        .bg-e {
            background: #EEEEEE;
        }

        .title {
            width: 100%;

            .title-name {
                width: 4px;
                height: 16px;
                border-radius: 2px;
                background: #27B0FC;
            }

            .title-word {
                font-size: 14px;
                height: 16px;
                line-height: 16px;
            }
        }

        .triangle-word {
        }

        #echarts {
            width: 100%;
            height: 500px;
        }

        #echarts-second {
            width: 100%;
            height: 500px;
        }

        .el-form-item__label {
            width: 88px;
        }
    }
</style>