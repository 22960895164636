<template>
  <div id="salesStatistics" v-loading="loading">
    <div class="title-top bg-f clearfix mb-5">
      <span>销售汇总</span>
    </div>
    <div class="bg-f pb-50 pl-30 pr-30">
      <div class="clearfix">
        <el-form
          :inline="true"
          class="pt-30 fl search"
          :model="form"
          label-width="80px"
          ref="form"
        >
        <div>
          <el-form-item label="下单时间">
            <ys-daterange-picker v-model="dateRange" />
          </el-form-item>
          <el-form-item label="业务员帐号">
            <el-input v-model.trim="form.managerLoginName"></el-input>
          </el-form-item>
          <el-form-item label="业务员姓名">
            <el-input v-model.trim="form.managerName"></el-input>
          </el-form-item>
          </div>
          <div><el-button class="fl ml-20 mb-20" type="info" @click="handleSearch" icon="el-icon-search">查 询</el-button></div>
        </el-form>
        
      </div>
      <el-table :data="salesReportList" style="width: 100%;" empty-text="暂时还没有添加业务员哦">
        <el-table-column label="业务员" prop="managerName" align="center"></el-table-column>
        <el-table-column label="帐号" prop="managerLoginName" align="center" width="120"></el-table-column>
        <el-table-column label="订单数" prop="orderNum" align="center">
          <template slot-scope="scope">
            <span
              class="orderNum"
              v-if="scope.row.orderNum > 0"
              @click="goDetail(scope.row)"
            >{{scope.row.orderNum}}</span>
            <span v-else>{{scope.row.orderNum}}</span>
          </template>
        </el-table-column>
        <el-table-column label="总金额" prop="totalMoney" align="center">
          <template slot-scope="scope">
            <span>¥{{scope.row.totalMoney | fmoney}}</span>
          </template>
        </el-table-column>
        <el-table-column label="未上交金额" align="center">
          <template slot-scope="scope">
            <span>¥{{scope.row.accountMoney | fmoney}}</span>
          </template>
        </el-table-column>
        <!--<el-table-column label="下单佣金" align="center">-->
          <!--<template slot-scope="scope">-->
            <!--<span>¥{{scope.row.totalTeamCommission | fmoney}}</span>-->
          <!--</template>-->
        <!--</el-table-column>-->
        <!--<el-table-column label="总佣金" align="center">-->
          <!--<template slot-scope="scope">-->
            <!--<span>¥{{scope.row.totalManagerCommission | fmoney}}</span>-->
          <!--</template>-->
        <!--</el-table-column>-->
        <el-table-column align="center" width="260" label="操作">
          <template slot-scope="scope">
            <div>
              <el-button
                size="small"
                type="text"
                v-if="scope.row.sicTeamId != null"
                @click="goInventory(scope.row)"
              >查看库存</el-button>
              <el-button size="small" type="text" @click="goDetail(scope.row)">订单明细</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        v-if="total>10"
        class="tar mt-20"
        :current-page="currentPage"
        :page-size="10"
        :total="total"
        layout="prev , pager, next, jumper"
        @current-change="handleCurrentChange"
      >
        <!-- 分页组件 -->
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { axiosApi, basePath } from "~/api/api";
import { mapActions } from "vuex";
import YsDaterangePicker from "~/components/ys-daterange-picker";
export default {
  components: { YsDaterangePicker },
  data() {
    return {
      isExport: 1,
      form: {
        sellerId: sessionStorage.getItem("traderId"),
        queryBeginDate: null, //下单开始时间
         queryEndDate: null, //下单结束时间
        managerLoginName: null,
        managerName: null
      },
      dateRange: [],
      salesReportList: [], //报表数据列表
      currentPage: 1,
      total: 0,
      loading: false,
      reportList: null
    };
  },
  watch: {
    "form.managerLoginName": {
      handler: function() {
        if (this.form.managerLoginName != null) {
          this.form.managerLoginName = this.form.managerLoginName.replace(
            /[\ud800-\udbff][\udc00-\udfff]/g,
            ""
          );
        }
      }
    },
    "form.managerName": {
      handler: function() {
        if (this.form.managerName != null) {
          this.form.managerName = this.form.managerName.replace(
            /[\ud800-\udbff][\udc00-\udfff]/g,
            ""
          );
        }
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.querySalesReport();
      this.getManagerList();
    });
  },
  computed: {
    managerList() {
      return this.$store.state.salesMan.people;
    }
  },
  methods: {
    ...mapActions({
      findSales: "findSales"
    }),
    // 查看库存
    goInventory(item) {
      this.$router.push({
        path: basePath + "/salesInCarInventory",
        query: {
          id: item.sicTeamId,
          teamName: item.teamName,
          teamCarNo: item.teamCarNo
        }
      });
    },
    handleSearch() {
      //查询
      this.form.queryBeginDate = !this.dateRange[0]
        ? null
        : +new Date(this.dateRange[0]);
      this.form. queryEndDate = !this.dateRange[1]
        ? null
        : +new Date(this.dateRange[1]);
      this.formDown = Object.assign({}, this.form);
      if (this.currentPage === 1) {
        this.querySalesReport();
      } else {
        this.currentPage = 1; //赋值自动调用一次接口
      }
    },
    goDetail(item) {
      this.$router.push({
        path: basePath + "/salesInCarOrderList",
        query: {
          managerLoginName: item.managerLoginName,
          queryBeginDate: this.form.queryBeginDate,
           queryEndDate: this.form. queryEndDate
        }
      });
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.querySalesReport();
    },
    /* 获取销售报表信息 */
    querySalesReport() {
      this.loading = true;
      axiosApi(
        "/order/sic/orderSummary",
        this.form,
        res => {
          this.loading = false;
          this.salesReportList = res.data.data.elements;
          this.total = res.data.data.totalCount;
        },
        this.currentPage,
        10,
        res => {
          this.salesReportList = [];
          this.loading = false;
          this.$message({
            type: "error",
            message: res.data.message
          });
        }
      );
    },
    getManagerList(query) {
      this.findSales(query);
    }
  }
};
</script>

<style lang="less">
#salesStatistics {
  .el-table__body-wrapper {
    margin-top: 1px !important;
  }
  .orderNum {
    color: #20a0ff;
    padding: 0 10px;
    cursor: pointer;
  }
}
</style>