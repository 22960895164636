<template>
  <!--久集订单-->
  <div id="superc-order">
    <div class="title-tabs bg-f mb-5">
      <ul class="clearfix">
        <template v-for="(tab,index) in dataTabs" class="item">
          <li :key="tab" :type="index" :class="type==index?'active':''" @click="handleTabs">
            {{tab}}
            <div :class="type==index?'under-line':''"></div>
          </li>
          <div :key="index" class="vertical-line"></div>
        </template>
      </ul>
    </div>
    
    <!--未付款标记-->
    <el-badge class="notPayCountmark" :max="99" :value="badge.notPayCount" :hidden="badge.notPayCount == 0"/>
    <!--待发货标记-->
    <el-badge class="notDeliveryCount" :max="99" :value="badge.notDeliveryCount" :hidden="badge.notDeliveryCount == 0"/>
    <!--待收货标记-->
    <el-badge class="orderDeliveriedCount" :max="99" :value="badge.orderDeliveriedCount" :hidden="badge.orderDeliveriedCount== 0"/>
    <div class="content bg-f pl-30 pr-30 pb-40">
      <div class="clearfix search">
        <el-form :inline="true" class="pt-30 " :model="form" ref="searchForm">
          <el-form-item
            label="订单编号"
            prop="orderSn" class="ml-20"
            :rules="[{pattern:/^\d{16,20}$/,message:'请输入正确订单编号',trigger:'blur'}]"
          >
            <el-input v-model.trim="form.orderSn" placeholder="请输入订单编号" :maxlength="20"></el-input>
          </el-form-item>
          <el-form-item label="商品名称" class="ml-20">
            <el-input v-model.trim="form.skuName" :maxlength="30" placeholder="请输入订单商品"></el-input>
          </el-form-item>
          <el-form-item label="久集账号" class="ml-20">
            <el-select v-model="form.supercStoreId" placeholder="请选择久集账号" clearable>
              <el-option v-for="g in superCList" :key="g.id" :label="g.storeName" :value="g.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="支付方式" class="ml-20">
            <el-select v-model="form.payMethod" placeholder="请选择支付方式" clearable>
              <!--<el-option label="组合支付" value="0"></el-option>
              <el-option label="线下支付" value="1"></el-option>-->
              <el-option label="授信支付" value="2"></el-option>
              <el-option label="在线支付" value="3"></el-option>
              <el-option label="余额支付" value="4"></el-option>
              <!--<el-option label="自行支付" value="5"></el-option>-->
            </el-select>
          </el-form-item>
          <el-form-item label="下单时间" class="ml-20">
            <!--<el-date-picker v-model="form.orderDateRange" class="order-datatimerange" type="daterange" placeholder="选择时间范围" :picker-options="pickerOptions">
            </el-date-picker>-->
            <ys-daterange-picker v-model="form.orderDateRange" :endDisabledDate="new Date()" />
          </el-form-item>
        </el-form>
        <div>
          <el-button class="fl ml-20" type="info" @click="handleCheck" icon="el-icon-search">查 询</el-button>
        </div>
      </div>
      <div class="tar clearfix plr-20">
        <el-button type="text" class="fr" style="width: 100px;" @click="checkTable">查看已生成报表</el-button>
        <el-button type="text" class="fr" @click="exportExcel">批量导出</el-button>
      </div>
      <div class="order-list" v-loading="loading">
        <el-checkbox v-model="checkAll" @change="handleCheckAllChange" class="checkedAll"></el-checkbox>
        <el-table>
          <el-table-column label width="50"></el-table-column>
          <el-table-column label="商品" min-width="160"></el-table-column>
          <el-table-column label="单价" ></el-table-column>
          <el-table-column label="数量" ></el-table-column>
          <el-table-column label="久集" ></el-table-column>
          <el-table-column label="销售佣金" ></el-table-column>
          <el-table-column label="买家" ></el-table-column>
          <el-table-column label="交易状态" ></el-table-column>
          <el-table-column label="支付方式" ></el-table-column>
          <el-table-column label="支付金额" ></el-table-column>
          <el-table-column label="操作" align="center"></el-table-column>
        </el-table>
        <div class="table-item-default tac" v-if="ordersListResVo.length<=0">
          <img src="../../assets/empty.png" alt />
          <div class="color-danger mt-30">
            <router-link :to="{path:'/customer/shop/shopIndex'}">
              <span class="color-danger">啥都没有，去首页看看 ></span>
            </router-link>
          </div>
        </div>
        <div v-else>
          <el-checkbox-group v-model="checkOrder" @change="handleCheckChange">
            <div class="table-item mt-10" v-for="(order, index) in ordersListResVo" :key="index">
              <div class="clearfix table-item-top lh-20 bbd bg-e">
                <el-checkbox class="fl" :label="order"></el-checkbox>
                <span class="fl">订单编号：{{order.orderSn}}</span>
                <span class="fl color-6 ml-30">下单时间：{{order.gmtCreate | time}}</span>
                <span class="fr color-6 mr-30" v-if="order.orderStatus==1">
                  还剩
                  <span class="color-danger">{{order.remainAutoCancelTime}}</span>自动取消
                </span>
              </div>
              <div class="table-item-content clearfix bbd" style="display: flex;padding-left: 50px">
                <div class="clearfix" style="flex: 4;width: 0;box-sizing: border-box;">
                  <!--商品清单-->
                  <div
                    class="bb clearfix"
                    v-for="(orderItem, index) in order.itemList"
                    :key="index"
                    style="display: flex; padding: 20px 0 20px 20px;"
                  >
                    <div class="fl specialD" style="flex: 2;width: 0;margin-left: -50px">
                      <img v-if="orderItem.fileUrl" :src="orderItem.fileUrl" class="fl mr-20" />
                      <img v-else src="../../assets/goods.jpg" class="fl mr-20" />
                      <div class="row-item-1">
                        <p class="bw">{{orderItem.skuName}}</p>
                        <p class="color-9">规格:1*{{orderItem.specInfoNum}}</p>
                      </div>
                    </div>
                    <div class="fl tac" style="flex: 1;width: 0">¥{{orderItem.discountPrice | fmoney}}</div>
                    <div class="fl tac" style="flex: 1;width: 0">{{orderItem.num}}{{orderItem.unit}}</div>
                  </div>
                </div>
                <div
                  class="bl bb tac pt-20"
                  style="flex: 1;word-break: break-all;width: 0"
                >{{order.storeName}}</div>
                <div
                  class="bl bb tac pt-20"
                  style="flex: 1;width: 0; word-break: break-all"
                >￥{{order.commission | fmoney}}</div>
                <div
                  class="bl bb tac pt-20"
                  style="flex: 1;width: 0;word-break: break-all"
                >{{order.addrUsername}}</div>
                <div class="bl bb tac pt-20" style="flex: 1;width: 0;">
                  <span v-if="order.orderStatus==0">已关闭</span>
                  <span v-if="order.orderStatus==1">待付款</span>
                  <span v-if="order.orderStatus==2">待发货</span>
                  <span v-if="order.orderStatus==3">已发货</span>
                  <span v-if="order.orderStatus==4">已完成</span>
                </div>
                <!-- 支付方式 -->
                <div class="bl bb tac pt-20" style="flex: 1;width: 0;">
                  <span v-if="order.payMethodExp">{{order.payMethodExp}}</span>
                  <span v-else>--</span>
                </div>
                <div class="bl bb tac pt-20" style="flex: 1;width: 0">
                  <div class="tac">¥{{order.needPayMoney | fmoney}}</div>
                  <div class="tac" v-if="order.freightMoney != null && order.freightMoney > 0">（含运费：¥{{order.freightMoney | fmoney}}）</div>
                  <div class="tac" v-if="order.couponCutMoney != null && order.couponCutMoney > 0">（优惠券：<br />-¥{{order.couponCutMoney | fmoney}}）</div>
                </div>
                <div class="bl bb tac" :style="{paddingTop : '15px'}" style="flex: 1;width: 0;">
                  <div>
                    <el-button
                      type="text"
                      v-if="order.orderStatus==1"
                      @click="handleCancelOrder(order.orderSn)"
                    >取消订单</el-button>
                    <el-button
                      type="text"
                      v-if="order.orderStatus==2 && (order.isJdeSeller==false && order.isK3Seller==false)"
                      @click="handleSend(order.orderSn)"
                    >发货</el-button>
                    <!-- 已发货 有审核凭证 -->
                    <template>
                      <el-button
                        :style="{marginLeft : '0'}"
                        v-if="order.creditState === 3"
                        @click="handleExamine(order)"
                        type="text"
                      >审核凭证</el-button>
                      <el-tooltip v-if="order.creditState === 0" content="待用户上传凭证" placement="top">
                        <span>审核凭证</span>
                      </el-tooltip>
                    </template>
                    <!-- 审核通过 有查看凭证 -->
                    <template v-if="order.creditState === 1">
                      <el-button
                        :style="{marginLeft : '0'}"
                        @click="handleWitchExamine(order)"
                        type="text"
                      >查看凭证</el-button>
                    </template>
                    <el-button type="text" class="ml-0" @click="orderDetail(order.orderSn)">
                      订单详情
                      <!--<router-link tag="button" :to="`${basePath}/supercOrderDetails`" target = _blank>
											<span >订单详情</span>
                      </router-link>-->
                    </el-button>
                  </div>
                </div>
              </div>
            </div>
          </el-checkbox-group>
          <!--取消订单弹出框-->
          <el-dialog class="cancelOrderDialog" title="取消订单理由" :visible.sync="cancelOrderVisible">
            <el-form class="pl-30 pr-30" :model="form" ref="cancelOrderForm" :rules="rules2">
              <el-form-item label prop="cancleReason">
                <el-input
                  type="textarea"
                  placeholder="请输入取消订单的理由..."
                  :autosize="{ minRows: 3 ,maxRows: 10}"
                  resize="none"
                  v-model.trim="form.cancleReason"
                ></el-input>
              </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
              <el-button type="danger" @click="handleCommit(2)">确 定</el-button>
              <el-button @click="cancelOrderVisible = false">取 消</el-button>
            </div>
          </el-dialog>
          <!--选择物流公司-->
          <el-dialog class="sendGoods" title="发货信息" :visible.sync="sendGoods">
            <el-form class="pl-30 pr-30" :model="form" ref="sendGoods" :rules="rules3">
              <el-form-item label="选择物流" prop="expressName">
                <el-select v-model="form.expressName" placeholder="请选择物流" clearable>
                  <el-option
                    v-for="(express,index) in expressList"
                    :label="express.companyName"
                    :value="express.companyName"
                    :key="index"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="物流编号" prop="expressNum">
                <el-input placeholder="请输入物流编号" v-model.trim="form.expressNum"></el-input>
              </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
              <el-button type="danger" @click="handleCommit(3)">确定</el-button>
              <el-button @click="sendGoods = false">取 消</el-button>
            </div>
          </el-dialog>
        </div>
        <el-pagination
          v-if="total>0"
          class="tar mt-20"
          :current-page.sync="currentPage"
          :page-size="10"
          :total="total"
          layout="prev , pager, next, jumper"
          @current-change="handleCurrentChange"
        >
          <!-- 分页组件 -->
        </el-pagination>
      </div>
    </div>
    <!--导出-->
    <!--<form ref="exportList" action='/sunrise-gateway/export/superc/order' style="display: none">
			<input type="text" name="startRow" v-model="a">
			<input type="text" name="startCol" v-model="a">
			<input type="text" v-if="form.sellerId" name="sellerId" v-model="form.sellerId">
			<input type="text" v-if="form.orderSn" name="orderSn" v-model="form.orderSn">
			<input type="text" v-if="form.skuName" name="skuName" v-model="form.skuName">
			<input type="text" v-if="form.orderStatus" name="orderStatus" v-model="form.orderStatus">
			<input type="text" v-if="form.supercStoreId" name="supercStoreId" v-model="form.supercStoreId">
			<input type="text" v-if="form.startTime" name="startTime" v-model="form.startTime">
			<input type="text" v-if="form.endTime" name="endTime" v-model="form.endTime">
			<input type="text" v-if="idStr" name="idStr" v-model="idStr">
			<input type="text" name="fileName" v-model="fileName">
			<input type="text" name="modelName" v-model="modelName">
			<input type="text" name="payMethod" v-model="form.payMethod" />
    </form>-->
    <!-- 审核凭证 -->
    <order-viewredentials
      :imgList="voucherImgList"
      :bankInfo="bankInfo"
      :id="voucherId"
      :type="viewredentialsType"
      @close="handleVoucherClose"
      ref="viewredentials"
    ></order-viewredentials>
    <!-- 查看 凭证 -->
    <imgview
      ref="imgview"
      :visible="imgviewVisible"
      :imgs="voucherImgList"
      :startindex="0"
      @close="imgviewVisible=false"
    ></imgview>
  </div>
</template>

<script>
import { axiosApi, basePath } from "../../api/api";
import orderViewCredentials from "./orderViewCredentialsComponent.vue";
import { mapMutations } from "vuex";
import _ from "underscore";
// import YsDaterangePicker from "~/components/ys-daterange-picker";
export default {
  components: { "order-viewredentials": orderViewCredentials },
  data() {
    var expressRules = (rule, value, callback) => {
      let reg1 = /^[0-9]{12}$/; //顺丰
      let reg2 = /^((618|680|828|571|518)[0-9]{9})$|^(2008[0-9]{8})$|^((00|10)[0-9]{10})$/; //中通
      let reg3 = /^[0-9]{10}$/; //汇通
      let reg4 = /^(0|1|2|3|5|6|7|8|E|D|F|G|V|W|e|d|f|g|v|w)[0-9]{9}$/; //圆通
      let reg5 = /^[0-9]{13}$/; //韵达
      let reg6 = /^(268|888|588|688|368|468|568|668|768|868|968)[0-9]{9}$|^(268|888|588|688|368|468|568|668|768|868|968)[0-9]{10}$|^(STO)[0-9]{10}$/; //申通
      let reg7 = /^[0-9]{14}$/; //天天
      let reg8 = /^[A-Z]{2}[0-9]{9}[A-Z]{2}$/; //EMS
      if (this.form.expressName == "顺丰速运" && !reg1.test(value)) {
        callback(new Error("请输入正确的物流单号"));
      } else if (this.form.expressName == "中通快递" && !reg2.test(value)) {
        callback(new Error("请输入正确的物流单号"));
      } else if (this.form.expressName == "百世快递" && !reg3.test(value)) {
        callback(new Error("请输入正确的物流单号"));
      } else if (this.form.expressName == "圆通速递" && !reg4.test(value)) {
        callback(new Error("请输入正确的物流单号"));
      } else if (this.form.expressName == "韵达物流" && !reg5.test(value)) {
        callback(new Error("请输入正确的物流单号"));
      } else if (this.form.expressName == "申通快递" && !reg6.test(value)) {
        callback(new Error("请输入正确的物流单号"));
      } else if (this.form.expressName == "天天快递" && !reg7.test(value)) {
        callback(new Error("请输入正确的物流单号"));
      } else if (this.form.expressName == "EMS" && !reg8.test(value)) {
        callback(new Error("请输入正确的物流单号"));
      } else {
        callback();
      }
    };
    return {
      /*规则*/
      rules2: {
        cancleReason: [
          {
            required: true,
            message: "取消订单理由不能为空"
          },
          {
            min: 4,
            max: 100,
            message: "取消订单理由为4-100位字符"
          }
        ]
      },
      rules3: {
        expressName: {
          required: true,
          message: "物流公司不能为空"
        },
        expressNum: [
          //					{
          //						required: true,
          //						message: '物流编号不能为空'
          //					},
          {
            pattern: /^[A-Z|0-9]{8,15}$/,
            message: "请输入正确的物流单号",
            trigger: "blur"
          }
        ]
        //					expressNum:[{
        //						required: true,
        //						message: '物流编号不能为空'
        //					},{
        //						validator: expressRules,
        //						trigger: 'blur'
        //					}]
      },
      type: "0",
      dataTabs: ["所有订单", "待付款", "待发货", "已发货", "已完成", "已关闭"],
      data: ["1"],
      cancelOrderVisible: false, //取消订单弹框
      reason: null,
      ordersListResVo: [], //订单数据
      orderSn: null, //订单编号
      superCList: [], //久集账号列表
      idStr: [], //批量导出选中列表
      fileName: null, //导出名称
      a: 0, //固定值 写死
      modelName: "superc.order",
      flag: false,
      cloneForm: {},
      form: {
        sellerId: sessionStorage.traderId,
        orderSn: null, //订单编号
        skuName: null, //商品名称
        orderStatus: null, //订单状态
        supercStoreId: null, //久集主键Id
        startTime: null,
        endTime: null,
        orderDateRange: [], //gmtOrderStart下单开始时间 gmtOrderEnd下单结束时间
        traderType: "1",
        cancleReason: null, //订单取消原因
        expressName: null, //物流公司
        expressNum: null, //物流单号
        payMethod: "", // 支付方式
        supercStoreId: "",
        referrerStoreId: ""
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > new Date();
        }
      }, //禁选未来时间
      rules: {
        cancleReason: [{ max: 30, message: "不能超过30个字！" }]
      },
      clearTime: 1, //定时器
      loading: false,
      currentPage: 1,
      total: null,
      badge: {
        //标记
        notPayCount: 0, //未付款
        notDeliveryCount: 0, //已付款-待发货
        orderDeliveriedCount: 0, //已发货-待收货
        successCount: 0, //已完成
        cancleCount: 0, //已取消
        notAuditCount: 0 //未审核
      },
      checkAll: false, //选中状态
      checkOrder: [], //选中的每行订单数据
      sendGoods: false, //发货弹框
      expressList: [], //物流列表
      voucherImgList: [], // 凭证图片 list
      bankInfo: {}, // 凭证 银行信息
      voucherId: "", // 审核 凭证 id
      viewredentialsType: "", // 审核浮层类型 0 - 审核 1 - 详情
      imgviewVisible: false // 查看凭证 dialog 开关
    };
  },
  watch: {
    form: {
      handler: function() {
        this.flag = false;
      },
      deep: true
    }
  },
  mounted() {
    this.$nextTick(() => {
      let type = sessionStorage.getItem("type");
      if (type) {
        this.type = type;
        if (this.type == "0") {
          //全部订单
        } else if (this.type == "1") {
          //待付款
          this.form.orderStatus = 1;
        } else if (this.type == "2") {
          //待发货
          this.form.orderStatus = 2;
        } else if (this.type == "3") {
          //待收货
          this.form.orderStatus = 3;
        } else if (this.type == "4") {
          //已完成
          this.form.orderStatus = 4;
        } else if (this.type == "5") {
          //已关闭
          this.form.orderStatus = 0;
        }
      } else {
        this.type = "0";
      }
      this.form.sellerId = sessionStorage.traderId;
      this.queryOrderList(this.form); //默认加载订单
      this.getsuperC(); //默认加载久集列表
      //				this.anonOrderCount()
      //				sessionStorage.removeItem('type')
    });
  },
  methods: {
    ...mapMutations({
      setTask: "setTask"
    }),
    //查看已生成报表
    checkTable() {
      let taskType = "superc_society_order";
      this.setTask({
        url: "/datacenter/exportTask/listExportTask",
        businessType: "superc",
        taskType: taskType
      });
      this.$router.push(basePath + "/statement");
    },
    exportExcel() {
      //批量导出
      if (this.cloneForm) {
        if (this.cloneForm.orderDateRange) {
          if (
            !this.cloneForm.orderDateRange[0] ||
            !this.cloneForm.orderDateRange[1]
          ) {
            this.$message.warning("请选择下单时间并查询，再进行批量导出");
            return;
          }
        } else {
          this.$message.warning("请选择下单时间并查询，再进行批量导出");
          return;
        }
      } else {
        this.$message.warning("请选择下单时间并查询，再进行批量导出");
        return;
      }

      this.superCList.map(v => {
        if (this.form.supercStoreId == v.id) {
          this.cloneForm.storeName = v.storeName;
        }
      });

      if (this.form.payMethod == 2) {
        this.cloneForm.payMethodName = "授信支付";
      } else if (this.form.payMethod == 3) {
        this.cloneForm.payMethodName = "在线支付";
      } else if (this.form.payMethod == 4) {
        this.cloneForm.payMethodName = "余额支付";
      }

      let body = _.pick(
        this.cloneForm,
        "sellerId",
        "orderSn",
        "skuName",
        "orderStatus",
        "supercStoreId",
        "startTime",
        "endTime",
        "storeName",
        "payMethod",
        "payMethodName"
      );

      axiosApi(
        "/datacenter/exportTask/addSocietySupercOrder",
        body,
        res => {
          this.$notify({
            title: "成功",
            message:
              "导出的文件正在生成，请不要重复点击，文件生成后，可以在“查看已生成报表”中查看!",
            type: "success"
          });
        },
        1,
        10,
        err => {
          this.$message.error(err.data.message);
        }
      );
    },
    // 获取 凭证图片 银行信息
    getVoucherImg(orderSn) {
      return new Promise((resove, reject) => {
        axiosApi(
          "/superc/credit/order/imgUploadPre",
          {
            orderSn: orderSn
          },
          res => {
            let data = res.data.data;

            if (data) {
              let bankInfo = {
                bankName: data.bankName,
                bankUserName: data.bankUserName,
                bankCard: data.bankCard
              };
              resove({
                img: data.imgs ? data.imgs : [],
                bankInfo: bankInfo
              });
            }
          }
        );
      });
    },
    // 审核 凭证
    handleExamine(row) {
      /* this.voucherImgList = [
					'http://imgsrc.baidu.com/image/c0%3Dshijue1%2C0%2C0%2C294%2C40/sign=1682a2cca76eddc432eabcb851b2dc88/50da81cb39dbb6fd4a10690f0324ab18972b374b.jpg',
					'http://imgsrc.baidu.com/image/c0%3Dshijue1%2C0%2C0%2C294%2C40/sign=cfb53f93c3177f3e0439f44e18a651b2/6609c93d70cf3bc7814060c9db00baa1cd112a56.jpg'
				];
				this.voucherId = 10086;
				this.$refs.viewredentials.show(); */
      this.viewredentialsType = 0;
      this.getVoucherImg(row.orderSn).then(data => {
        this.voucherImgList = data.img;
        this.voucherId = {
          supercStoreId: row.supercStoreId,
          orderSn: row.orderSn,
          buyerId: sessionStorage.traderId
        };
        this.bankInfo = data.bankInfo;
        this.$refs.viewredentials.show();
      });
    },
    // 审核 凭证 提交后
    handleVoucherClose() {
      // 刷新列表
      this.queryOrderList(this.form);
    },
    // 查看 凭证
    handleWitchExamine(row) {
      /* this.imgviewVisible = true;
				this.voucherImgList = [
					'http://pic.58pic.com/58pic/13/23/37/01958PICjAH_1024.jpg',
					'http://pic31.nipic.com/20130802/7487939_094504476000_2.jpg'
				];
				this.$refs.imgview.setActiveItem(0); */
      this.viewredentialsType = 1;
      this.getVoucherImg(row.orderSn).then(data => {
        this.voucherImgList = data.img;
        this.voucherId = {
          supercStoreId: row.supercStoreId,
          orderSn: row.orderSn,
          buyerId: sessionStorage.traderId
        };
        this.bankInfo = data.bankInfo;
        this.$refs.viewredentials.show();
      });
    },
    getsuperC() {
      /*久集账号*/
      axiosApi(
        "/superc/querySupercForSeller/select",
        {
          sellerId: sessionStorage.traderId,
          supercType: "1"
        },
        res => {
          this.superCList = res.data.data;
        },
        1,
        10,
        res => {}
      );
    },
    getLogistics() {
      //物流公司列表
      axiosApi(
        "/superc/expressCompany/selectList",
        {},
        res => {
          this.expressList = res.data.data;
        },
        1,
        10,
        res => {}
      );
    },
    anonOrderCount() {
      //订单统计(角标)
      axiosApi(
        "/anon/order/count",
        {
          traderType: 1,
          buyerId: sessionStorage.traderId
        },
        res => {
          this.badge = res.data.data;
        },
        1,
        10,
        res => {}
      );
    },
    handleCurrentChange(val) {
      //分页
      this.currentPage = val;
      this.queryOrderList(this.form);
      //				this.anonOrderCount()
    },
    handleTabs(e) {
      //				if(e.target.type) this.type = e.target.type // 改变 type的值
      if (e.target.attributes.type) this.type = e.target.attributes.type.value;
      this.form = {
        orderSn: null, //订单编号
        skuName: null, //商品名称
        orderStatus: null, //订单状态
        supercStoreId: null, //久集主键Id
        startTime: null,
        endTime: null,
        orderDateRange: [], //gmtOrderStart下单开始时间 gmtOrderEnd下单结束时间
        traderType: "1",
        cancleReason: null, //订单取消原因
        expressName: null, //物流公司
        expressNum: null, //物流单号
        payMethod: ""
      };
      this.currentPage = 1;
      if (this.type == "0") {
        //全部订单
      } else if (this.type == "1") {
        //待付款
        this.form.orderStatus = 1;
      } else if (this.type == "2") {
        //待发货
        this.form.orderStatus = 2;
      } else if (this.type == "3") {
        //待收货
        this.form.orderStatus = 3;
      } else if (this.type == "4") {
        //已完成
        this.form.orderStatus = 4;
      } else if (this.type == "5") {
        //已关闭
        this.form.orderStatus = 0;
      }
      this.form.sellerId = sessionStorage.traderId;
      this.currentPage = 1;
      this.queryOrderList(this.form);
    },
    timeFormat(value) {
      var myDate = new Date(value);
      var year = myDate.getFullYear();
      var month =
        myDate.getMonth() + 1 < 10
          ? "0" + (myDate.getMonth() + 1)
          : myDate.getMonth() + 1;
      var day =
        myDate.getDate() < 10 ? "0" + myDate.getDate() : myDate.getDate();
      return year + "-" + month + "-" + day;
    },
    handleCheck() {
      ///订单条件查询
      this.form.startTime = this.form.orderDateRange[0]
        ? this.form.orderDateRange[0].getTime()
        : null;
      this.form.endTime = this.form.orderDateRange[1]
        ? this.form.orderDateRange[1].getTime()
        : null;
      this.cloneForm = JSON.parse(JSON.stringify(this.form));
      setTimeout(() => {
        this.flag = true;
      }, 0);
      //				this.form.startTime = this.form.startTime == null ? null : this.timeFormat(this.form.startTime);
      //				this.form.endTime = this.form.endTime == null ? null : this.timeFormat(this.form.endTime);
      //订单编号表单验证
      this.$refs.searchForm.validate(valid => {
        if (valid) {
          this.currentPage = 1;
          this.queryOrderList(this.form);
        }
      });
    },
    queryOrderList(body) {
      /*久集订单列表*/
      if (this.$route.query.supercStoreId) {
        body.supercStoreId = this.$route.query.supercStoreId;
      }
      if (this.$route.query.referrerStoreId) {
        body.referrerStoreId = this.$route.query.referrerStoreId;
      }
      this.loading = true;
      axiosApi(
        "/superc/supercOrderListQuery/select",
        body,
        res => {
          this.loading = false;
          this.total = res.data.data.totalCount;
          this.ordersListResVo = res.data.data.elements;
          /*定时器显示*/
          this.ordersListResVo.forEach((item, index) => {
            if (
              item.remainAutoCancelTime != null &&
              item.remainAutoCancelTime > 0
            ) {
              let time = parseInt(item.remainAutoCancelTime / 1000); //转化为整数
              item.remainAutoCancelTime = this.timeString(time); //转化为时间格式
              item.clearTime = null; //定义变量
              item.clearTime = setInterval(() => {
                time--;
                item.remainAutoCancelTime = this.timeString(time);
                if (time <= 0) {
                  clearTimeout(item.clearTime);
                  //this.cancelOrder({orderSn:item.sn,cancleType:3})  //系统取消
                  if (item.orderStatus == 1) item.orderStatus = 0;
                  this.$set(this.ordersListResVo, index, item);
                }
              }, 1000);
            } else {
              if (item.orderStatus == 1) item.orderStatus = 0;
              this.$set(this.ordersListResVo, index, item);
            }
          });
        },
        this.currentPage,
        10,
        res => {
          this.loading = false;
          this.$message({
            type: "error",
            message: res.data.message,
            duration: 1000
          });
        }
      );
    },
    handleCancelOrder(orderSn) {
      //取消订单按钮
      this.cancelOrderVisible = true;
      this.orderSn = orderSn; //订单编号
    },
    handleSend(orderSn) {
      //发货按钮
      this.sendGoods = true;
      this.form.expressName = null; //物流公司
      this.form.expressNum = null; //物流单号
      this.orderSn = orderSn; //订单编号
      this.getLogistics(); //加载物流列表
    },
    handleCommit(state) {
      //弹窗确定按钮
      if (state == 2) {
        //弹窗 取消订单
        this.$refs.cancelOrderForm.validate(valid => {
          if (valid) {
            this.cancelOrderVisible = false;
            axiosApi(
              "/superc/orderCancel/update",
              {
                orderSn: this.orderSn,
                reason: this.form.cancleReason,
                cancleType: 1
              },
              res => {
                this.queryOrderList(this.form);
                //this.anonOrderCount()
              },
              1,
              10,
              res => {
                this.$message({
                  type: "error",
                  message: res.data.message,
                  duration: 1000
                });
              }
            );
          } else {
            return false;
          }
        });
      } else if (state == 3) {
        //弹窗 发货
        let expressCompanyCode = null;
        this.expressList.forEach(item => {
          if (item.companyName == this.form.expressName) {
            expressCompanyCode = item.companyCode;
          }
        })
        this.$refs.sendGoods.validate(valid => {
          if (valid) {
            this.sendGoods = false;
            axiosApi(
              "/superc/sellerDeliverGoods/update",
              {
                orderSn: this.orderSn,
                expressName: this.form.expressName,
                expressNum: this.form.expressNum,
                expressCompanyCode: expressCompanyCode
              },
              res => {
                this.type = "2";
                this.form.orderStatus = 2;
                this.queryOrderList(this.form);
              },
              1,
              10,
              res => {
                this.$message({
                  type: "error",
                  message: res.data.message,
                  duration: 1000
                });
              }
            );
          } else {
            return false;
          }
        });
      }
    },
    orderDetail(orderSn) {
      //去订单详情页面
      sessionStorage.setItem("orderSn", orderSn)
      window.open(basePath + "/supercOrderDetails/" + orderSn, "_blank");
    },
    confirmReceipt(orderSn) {
      //确认收货
      const h = this.$createElement;
      this.$msgbox({
        title: "提示信息",
        message: h(
          "p",
          {
            style: "text-align:center"
          },
          "是否确认收货？"
        ),
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            done();
          } else {
            done();
          }
          delete instance.$slots.default;
        }
      });
    },
    handleCheckAllChange(val) {
      /*table全选*/
      if (this.checkAll) {
        this.ordersListResVo.forEach(item => {
          this.checkOrder.push(item);
          this.idStr.push(item.id);
        });
      } else {
        this.checkOrder = [];
        this.idStr = [];
      }
    },

    handleCheckChange(val) {
      //单选
      let checkedCount = val.length;
      this.checkAll = checkedCount === this.ordersListResVo.length;
      this.idStr = [];
      val.forEach(item => {
        this.idStr.push(item.id);
      });
    }
  }
};
</script>

<style lang="less">
#superc-order {
  position: relative;
  box-sizing: border-box;
  .el-checkbox__label {
    display: none;
  }
  .notPayCountmark {
    position: absolute;
    left: 169px;
    top: 10px;
  }
  .specialD {
    position: relative;
    display: flex;
    justify-content: space-between;
    .row-item-1 {
      width: 134px;
      height: auto;
      .bw {
        word-wrap: break-word;
      }
    }
  }
  .notDeliveryCount {
    position: absolute;
    left: 248px;
    top: 10px;
  }
  .orderDeliveriedCount {
    position: absolute;
    left: 330px;
    top: 10px;
  }
  .hide {
    display: none;
  }
  .content {
    .el-date-editor--daterange.el-input {
      width: 240px;
    }
    .el-form--inline {
      .el-form-item__label {
        font-size: 12px;
        width: 60px;
      }
    }
    .el-table .el-table__body-wrapper {
      display: none;
    }
    .order-datatimerange {
      width: 240px;
    }
    .el-table {
      border: none;
      font-size: 14px;
      th > .cell {
        line-height: 12px;
        padding: 10px 0 10px 0;
        text-align: center;
      }
      th.is-leaf {
        border-bottom: 0;
      }
    }
    .checkedAll {
      position: relative;
      top: 30px;
    }
    .el-checkbox {
      width: 70px;
      margin-left: 30px;
      .el-checkbox__input {

      }
    }
    .el-checkbox__inner {

    }
    .el-checkbox__inner::after {
      top: 2px;
    }
    .table-item {
      .bl {
        border-left: 1px solid #ECEEF6;
      }
      border: 1px solid #ECEEF6;
      font-size: 14px;
      border-bottom: none;
      .table-item-content .pbl-20 {
        padding: 0px 0px 20px 20px;
      }
      .table-item-top {
        padding: 10px 0;
        .table-item-top-checkbox {
          margin-left: 14px;
        }
      }
      img {
        width: 80px;
        height: 80px;
      }
    }
    .w100 {
      margin: 0 auto;

    }
    .table-item-default {
      height: 530px;
      padding-top: 120px;
    }
    .el-dialog {
      min-height: 300px;
      .el-form-item__label {
        width: 140px;
      }
    }
    .sendGoods .el-form-item__error {
      margin-left: 140px;
    }
  }
}
</style>
