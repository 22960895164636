<template>
  <div id="salesInCarTeam" v-loading="loading">
    <div class="title-top bg-f clearfix mb-5">
      <span>小组管理</span>
    </div>
    <div class="bg-f pb-50 pl-30 pr-30">
      <div class="tar clearfix pt-30 pb-20">
        <el-button style="width: 100px;" type="text" @click="handleAddTeam">新增小组</el-button>
      </div>
      <el-table :data="salesReportList" style="width: 100%;" empty-text="暂时还没有添加小组哦">
        <el-table-column label="小组名" prop="teamName" align="center"></el-table-column>
        <el-table-column label="车牌号" prop="teamCarNo" align="center"></el-table-column>
        <el-table-column label="关联业务员" align="center">
          <el-table-column label="姓名" align="center">
            <template slot-scope="scope">
              <div
                class="scopeList"
                v-for="(item,index) in scope.row.sicTeamManagerVOList"
                :key="index"
              >{{item.distManagerName}}</div>
            </template>
          </el-table-column>
          <el-table-column label="帐号" align="center">
            <template slot-scope="scope">
              <div
                class="scopeList"
                v-for="(item,index) in scope.row.sicTeamManagerVOList"
                :key="index"
              >{{item.distManagerMobile}}</div>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column label="操作" align="center" width="350">
          <template slot-scope="scope">
            <div class="btn-box">
              <el-button  size="small" type="text" @click="goInventory(scope.row)">查看库存</el-button>
              <el-button size="small" type="text" @click="exhibitGoods(scope.row)">上货</el-button>
              <el-button size="small" type="text" @click="withdrawalGoods(scope.row)">退仓</el-button>
              <el-button size="small" type="text" @click="handleEditTeam(scope.row)">编辑</el-button>
              <el-button size="small" type="text" @click="deleteTeam(scope.row)">删除</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        v-if="total>10"
        class="tar"
        :current-page="currentPage"
        :page-size="10"
        :total="total"
        layout="prev , pager, next, jumper"
        @current-change="handleCurrentChange"
      >
        <!-- 分页组件 -->
      </el-pagination>
    </div>
    <!--新增小组弹窗-->
    <el-dialog :title="dialogTitle" :visible.sync="dialogShow" class="addDia" width="640px">
        <div>
            <el-form class="pl-100" :model="form1" ref="ruleForm" :rules="rules">
                <el-form-item label="小组名称" prop="teamName">
                    <el-input placeholder="请输小组名称" :maxlength="15" v-model.trim="form1.teamName"></el-input>
                </el-form-item>
                <el-form-item label="车牌号" prop="teamCarNo">
                    <el-input placeholder="请输车牌号" :maxlength="7" v-model.trim="form1.teamCarNo "></el-input>
                </el-form-item>
                <div class="itemList" v-for="(item,index) in form1.sicTeamManagerList" :key="index">
                    <el-form-item
                            label="业务员帐号"
                            ref="itemOne"
                            :prop="'sicTeamManagerList.'+index+'.distManagerId'"
                            :rules="rules.sicTeamManagerList.distManagerId"
                            style="width:400px"
                    >
                        <el-select
                                v-model.trim="form1.sicTeamManagerList[index].distManagerId"
                                filterable
                                popper-class="mySelect"
                                placeholder="请输入业务员帐号"
                                @change="changeSales"
                                no-match-text=" "
                                @visible-change="focusSelect(form1.sicTeamManagerList[index].distManagerId)"
                        >
                            <el-option
                                    v-for="item in salesList"
                                    :key="item.distManagerId"
                                    :label="item.distManagerName"
                                    :value="item.distManagerId"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item
                            style="width:100px;"
                            label=""
                    >
                        <el-button v-if="index>0" type="normal" @click="deleteTeamItem(index)">删 除</el-button>
                    </el-form-item>
                </div>
            </el-form>
            <div class="mt-20 tac">
                <el-button @click="addMember">新增成员</el-button>
                <el-button v-if="type == 'add'" @click="AddTeam('ruleForm')">新增小组</el-button>
                <el-button v-else @click="EditTeam('ruleForm')">确定编辑</el-button>
            </div>
        </div>

      <div slot="footer" class="dialog-footer">

      </div>
    </el-dialog>
      <!--上货/退仓商品表格-->
      <el-dialog title="选择商品" :visible.sync="dialogTableVisible" class="chooseGoods" :before-close="closeDialog" width="800px">
          <div class="clearfix pl-20 pr-20">
              <el-table @selection-change="handleSelectionChange" ref="goodsList" class="table-default" stripe border
                        :data="goodsList" v-loading="goodsLoading" :row-key="rowKey">
                  <el-table-column  type="selection" :reserve-selection="true"></el-table-column>
                  <el-table-column property="skuName" label="商品" width="200">
                      <template slot-scope="scope">
                          <div class="goods-img-info">
                              <div class="goods-img">
                                  <img :src="scope.row.fileUrl" alt="">
                              </div>
                              <div class="goods-info">
                                  {{ scope.row.skuName }}
                              </div>
                          </div>
                      </template>
                  </el-table-column>
                  <el-table-column label="规格" prop="boxPriorityPrice">
                      <template slot-scope="scope">
                          <span >1*{{scope.row.specInfoNum}}</span>
                      </template>
                  </el-table-column>
                  <el-table-column label="批发价" prop="boxPriorityPrice">
                      <template slot-scope="scope">
                          <span >￥{{scope.row.unitEnterShopPrice}}/{{scope.row.units}}</span>
                      </template>
                  </el-table-column>
                  <el-table-column label="库存" prop="boxPriorityPrice" v-if="!exhibit" key='1' >
                      <template slot-scope="scope">
                          <span >{{ scope.row.stock}}</span>
                          <span >{{scope.row.units}}</span>
                      </template>
                  </el-table-column>
                  <el-table-column :label="exhibit ? '上货' : '退仓' +'数量'" prop="boxPriorityPrice" width="200">
                      <template slot-scope="scope">
                          <div class="goods-num">
                              <div class="goods-num-change">
                                  <span class="reduce-btn" @click="reduceNum(scope.row)">-</span>
                                  <sy-input type="int" v-model="scope.row.num" size="mini" :min="1" :max="exhibit ? 9999 : scope.row.stock"></sy-input>
                                  <span class="add-btn" @click="addNum(scope.row)">+</span>
                              </div>
                              <div v-if="exhibit" class="selectDiv">
                                  <el-select v-if="scope.row.salesUnitType===1" v-model="scope.row.isTrue" placeholder="请选择" style="width:40px" size="mini">
                                    <el-option v-for="(item,index) in scope.row.selectList" :key="index" :label="item.name" :value="item.isTrue"></el-option>
                                  </el-select>
                                  <span v-else-if="scope.row.salesUnitType===2">{{scope.row.packageUnitName}}</span>
                                  <span v-else>{{scope.row.units}}</span>
                              </div>
                              <span v-else>{{scope.row.units}}</span>
                          </div>

                      </template>
                  </el-table-column>
                  <el-table-column label="小计" prop="boxPriorityPrice" v-if="exhibit" key='2' >
                      <template slot-scope="scope">
                          <span v-if="scope.row.salesUnitType===1">
                            <span  v-if="scope.row.isTrue">￥{{(scope.row.enterShopPrice * scope.row.num) | fmoney }}</span>
                            <span  v-else>￥{{(scope.row.unitEnterShopPrice * scope.row.num) | fmoney }}</span>
                          </span>
                          <span v-else-if="scope.row.salesUnitType===2">￥{{(scope.row.enterShopPrice * scope.row.num) | fmoney }}</span>
                          <span  v-else>￥{{(scope.row.unitEnterShopPrice * scope.row.num) | fmoney }}</span>

                          
                      </template>
                  </el-table-column>
              </el-table>
              <el-pagination v-if='goodsTotal>0' class="tar" :current-page="goodsPage" :page-size='10'
                             :total='goodsTotal' layout="prev , pager, next, jumper"
                             @current-change="goodsCurrentChange">
                  <!-- 分页组件 -->
              </el-pagination>
              <div class="operate-con">
                  <span>已选{{totalGoods}}件商品</span>
                  <span class="ml-30">合计 <span class="font-20 color-danger">￥{{totalMoney | fmoney}}</span></span>
                  <el-button size="small" :loading="btnLoading" class="ml-30"  type="danger" @click="save()">{{exhibit ? '上货':'退仓'}}</el-button>
              </div>
          </div>
      </el-dialog>
  </div>
</template>

<script>
import { axiosApi, basePath } from "~/api/api";
import { mapActions } from "vuex";
import YsDaterangePicker from "~/components/ys-daterange-picker";
export default {
  components: { YsDaterangePicker },
  data() {
    var validateCommission = (rule, value, callback) => {
      let obj = JSON.parse(JSON.stringify(this.form1.sicTeamManagerList));
      var allCommission = 0;
      for (let i = 0; i < obj.length; i++) {
        allCommission += parseFloat(obj[i].commissionScale);
      }
      if (value === "") {
        callback(new Error("请输入佣金比例"));
      } else {
        if (!/^(\d|[1-9]\d|100)(\.\d{1,2})?$/.test(value)) {
          if (value.toString().split(".")[1] && value.toString().split(".")[1].length > 2) {
            callback(new Error("佣金比例最多两位小数"));
          } else {
            callback(new Error("请输入0-100%之间的佣金比例"));
          }
        } else if (allCommission > 100) {
          callback(new Error("佣金比例之和不能大于100%"));
        }
        callback();
      }
    };
    var validateAccount = (rule, value, callback) => {
      var arrAccount = [];
      let obj = JSON.parse(JSON.stringify(this.form1.sicTeamManagerList));
      for (let i = 0; i < obj.length; i++) {
        arrAccount.push(obj[i].distManagerId);
      }
      var arr = Array.from(new Set(arrAccount));
      if (value === "" || value == null) {
        callback(new Error("请输入业务员帐号"));
      } else {
        if (arrAccount.length != arr.length) {
          callback(new Error("业务员帐号不可重复"));
        }
        callback();
      }
    };
    return {
        dialogTableVisible:false,//是否开启上货退仓弹窗
        multipleSelection:[],//选中的商品
        exhibit:true,//true上货false退仓
        goodsList:[],//上货退仓商品列表
        goodsPage:1,
        goodsTotal: 0,
        goodsLoading:false,
        sicTeamId: '',//选中的小组id
        totalMoney: 0,//选中商品的总价钱
        totalGoods: 0,//选中商品的总数
        btnLoading: false,
      isExport: 1,
      type: "add",
      dialogTitle: "",
      salesList: [],
      normalsalesList: [],
      id: null, //小组id
      // form: {
      //   sellerId: sessionStorage.getItem("traderId"),
      //   buyerName: null, //买家名称
      //   buyerMobile: null, //买家手机
      //   managerId: null, //业务员id
      //   gmtOrderStart: null, //下单开始时间
      //   gmtOrderEnd: null, //下单结束时间
      //   isExport: 0
      // },
      dateRange: [],
      salesReportList: [], //报表数据列表
      currentPage: 1,
      total: 0,
      loading: false,
      reportList: null,
      dialogShow: false,
      form1: {
        teamName: null,
        teamCarNo: null,
        sicTeamManagerList: [
          {
            commissionScale: 0,
            distManagerId: null
          }
        ]
      },
      rules: {
        teamName: [
          { required: true, message: "请输入小组名称", trigger: "blur" },
          {
            pattern: /^[\u4e00-\u9fa50-9A-Za-z]{2,15}$/,
            message: "请输入正确的小组名称",
            trigger: "blur"
          }
        ],
        teamCarNo: [
          { required: true, message: "请输入车牌号", trigger: "blur" },
          {
            pattern: /^[\u4e00-\u9fa50-9A-Za-z]{7}$/,
            message: "请输入正确的车牌号",
            trigger: "blur"
          }
        ],
        sicTeamManagerList: {
          distManagerId: [
            { validator: validateAccount, required: true, trigger: "change" }
          ],
          commissionScale: [
            { validator: validateCommission, required: true, trigger: "blur" }
          ]
        }
      },
      managerList: []
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.querySalesReport();
      this.getManagerList();
    });
  },
  computed: {
      getHasNumList() {
          const list = this.multipleSelection ? this.multipleSelection : [];
          const arr = list
              .filter(i => i.num)
              .map(i => {
                  console.log(i,'iiiiiiiiiii')
                  return {
                      skuNo: i.skuNo,
                      isIntegerMultiple: this.exhibit?i.salesUnitType===1?i.isTrue:i.isIntegerMultiple:false,
                      num: i.num
                  };
              });
          return arr;
      },
  },
  watch: {
    dialogShow(val) {
      if (!val) {
        if (this.$refs.ruleForm) this.$refs.ruleForm.resetFields();
      }
    },
    salesList() {
      this.$forceUpdate();
    },
    multipleSelection:{
          handler(val) {
              this.totalGoods = 0
              this.totalMoney = 0
              val.forEach(v=>{
                  this.totalGoods += 1
                  this.totalMoney += this.exhibit ? v.isTrue?v.num*v.enterShopPrice:v.num*v.unitEnterShopPrice : v.num*v.unitEnterShopPrice
              })
          },
          deep: true,

      },
    dialogTableVisible(){
        this.totalMoney = 0
        this.totalGoods = 0
    }
  },
  methods: {
    ...mapActions({
      findSales: "findSales"
    }),
      closeDialog(done){
          this.$refs.goodsList.clearSelection()
          done();
      },
      rowKey (row) {
          return row.skuNo
      },
      addNum(row){
          let n = parseInt(row.num)  + 1
          if (!this.exhibit && n>row.stock){
              return false
          }
          row.num = n
      },
      reduceNum(row){
          let n = parseInt(row.num) - 1
          if (n == 0) {
              return false
          }
          row.num = n
      },
      save(){

        if(this.multipleSelection.length == 0){
            this.$message({
                type: "error",
                message: `请选择${this.exhibit ? '上货':'退仓'}商品`
            });
            return false
        }
        let url = ''
          if (this.exhibit){
              url = '/goods/sic/goodsStock/up'
          } else{
              url = '/goods/sic/goodsStock/return'
          }
          let data = {
              detailsList: this.getHasNumList,
              sicTeamId: this.sicTeamId
          }
          this.btnLoading = true
          axiosApi(
              url,
              data,
              res => {
                  this.$message({
                      type: "success",
                      message: this.exhibit ? '上货申请成功':'退仓申请成功'
                  });
                  this.$refs.goodsList.clearSelection()
                  this.dialogTableVisible = false
                  this.goodsList = []
                  this.btnLoading = false
              },this.goodsPage,10,
              res => {
                  this.$message({
                      type: "error",
                      message: res.data.message
                  });
                  this.$refs.goodsList.clearSelection()
                  this.dialogTableVisible = false
                  this.btnLoading = false
              }
          );
      },
      goodsCurrentChange(val){
          // this.multipleSelection.forEach(row => {
          //     this.$refs.goodsList.toggleRowSelection(row)
          // })
        this.goodsPage = val
          let type = this.exhibit ? 'up' : 'refunds'
          this.getGoods(type)
      },
    getGoods(type){
        this.goodsLoading = true
        axiosApi(
            "/goods/sic/sicGoodsPage",
            { pageType: type, sicTeamId:this.sicTeamId ,sellerId: sessionStorage.traderId},
            res => {
                this.goodsList = res.data.data.page.elements.map(v=>{
                    return {
                        ...v,
                        selectList: [{isTrue:true,name:v.packageUnitName},{isTrue:false,name:v.units}],
                        isTrue: v.salesUnitType===2?false:true, // 用于判断包装单位还是基础单位
                        num:1
                    }
                })
                console.log(this.goodsList,'this.goodsList')
                this.goodsTotal = res.data.data.page.totalCount
                this.goodsLoading = false
            },this.goodsPage,10,
            res => {
                this.goodsLoading = false
                this.$message({
                    type: "error",
                    message: res.data.message
                });
            }
        );
    },
    focusSelect(id) {
      this.$nextTick(() => {
        let arrId = [];
        for (let i = 0; i < this.form1.sicTeamManagerList.length; i++) {
          arrId.push(this.form1.sicTeamManagerList[i].distManagerId);
        }
        arrId = arrId.filter(item => item != id && item != null);
        this.salesList = this.normalsalesList.filter(
          item => !arrId.includes(item.distManagerId)
        );
        this.$forceUpdate();
      });
    },
    changeSales() {
      this.$nextTick(() => {
        let arrId = [];
        for (let i = 0; i < this.form1.sicTeamManagerList.length; i++) {
          arrId.push(this.form1.sicTeamManagerList[i].distManagerId);
        }
        if (arrId.includes(null)) {
          this.getSalesList("", "");
        } else {
          this.salesList = this.normalsalesList.filter(
            item => !arrId.includes(item.distManagerId)
          );
        }
        this.$forceUpdate();
      });
    },
    // 查看库存
    goInventory(item) {
      this.$router.push({
        path: basePath + "/salesInCarInventory",
        query: {
          id: item.id,
          teamName: item.teamName,
          teamCarNo: item.teamCarNo
        }
      });
    },
      //选中商品
      handleSelectionChange(val) {
          this.multipleSelection = val
          console.log(this.multipleSelection,'multipleSelection');
      },
      //上货
      exhibitGoods(item){
          this.exhibit = true
          this.sicTeamId = item.id
          this.dialogTableVisible = true
          this.goodsPage = 1
          this.getGoods('up')
      },
      //退仓
      withdrawalGoods(item){
          this.exhibit = false
          this.sicTeamId = item.id
          this.dialogTableVisible = true
          this.goodsPage = 1
          this.getGoods('refunds')
      },
    handleAddTeam() {
      this.form1 = {
        teamName: null,
        teamCarNo: null,
        sicTeamManagerList: [
          {
            commissionScale: 0,
            distManagerId: null
          }
        ]
      };
      this.dialogShow = true;
      this.getSalesList("", "");
      this.dialogTitle = "新增小组";
      this.type = "add";
      var obj = {
        teamName: "",
        teamCarNo: "",
        sicTeamManagerList: []
      };
      obj.teamName = null;
      obj.teamCarNo = null;
      obj.sicTeamManagerList = [
        {
          commissionScale: 0,
          distManagerId: null
        }
      ];
      Object.assign(this.form1, obj);
      // setTimeout(() => {
      //   if (this.$refs.ruleForm) this.$refs.ruleForm.resetFields();
      // }, 0);
    },
    // 获取小组的数据
    getSalesList(mobile, sicTeamId) {
      axiosApi(
        "/order/sic/team/manager/select",
        { distManagerMobile: mobile, sicTeamId: sicTeamId },
        res => {
          this.salesList = res.data.data != null ? res.data.data : [];
          this.normalsalesList = res.data.data != null ? res.data.data : [];
        },
        res => {
          this.$message({
            type: "error",
            message: res.data.message
          });
        }
      );
    },
    getTeamInfo(id) {
      axiosApi(
        "/order/sic/team/select",
        { id: id },
        res => {
          this.form1 = {
            teamName: null,
            teamCarNo: null,
            sicTeamManagerList: [
              {
                commissionScale: 0,
                distManagerId: null
              }
            ]
          };
          let obj = res.data.data;
          obj.sicTeamManagerList = JSON.parse(
            JSON.stringify(res.data.data.sicTeamManagerVOList)
          );
          delete obj.sicTeamManagerVOList;
          Object.assign(this.form1, obj);
          // this.changeSales()
        },
        res => {
          this.$message({
            type: "error",
            message: res.data.message
          });
        }
      );
    },
    handleEditTeam(item) {
      this.getTeamInfo(item.id);
      this.getSalesList("", item.id);
      this.id = item.id;
      this.dialogShow = true;
      this.dialogTitle = "编辑小组";
      this.type = "edit";
      setTimeout(() => {
        this.changeSales();
      }, 1000);
    },
    AddTeam(form) {
      // 新增小组
      this.$refs[form].validate(valid => {
        if (valid) {
          this.$msgbox({
            title: "提示信息",
            message: "确定增加小组吗？",
            showCancelButton: true,
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            beforeClose: (action, instance, done) => {
              if (action === "confirm") {
                this.form1.sicTeamManagerList.forEach(item => {
                  item.commissionScale = Number(item.commissionScale);
                });
                axiosApi(
                  "/order/sic/team/add",
                  this.form1,
                  res => {
                    this.$message({
                      type: "success",
                      message: "新增成功"
                    });
                    this.querySalesReport();
                    this.dialogShow = false;
                  },
                  1,
                  10,
                  res => {
                    this.$message({
                      type: "error",
                      message: res.data.message
                    });
                  }
                );
                done();
              } else {
                done();
              }
            }
          });
        }
      });
    },
    EditTeam(form) {
      this.$refs[form].validate(valid => {
        if (valid) {
          this.$msgbox({
            title: "提示信息",
            message: "确定编辑小组吗？",
            showCancelButton: true,
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            beforeClose: (action, instance, done) => {
              if (action === "confirm") {
                this.form1.sicTeamManagerList.forEach(item => {
                  item.commissionScale = Number(item.commissionScale);
                  delete item.distManagerMobile;
                  delete item.distManagerName;
                  delete item.id;
                  delete item.sicTeamId;
                });
                this.form1.id = this.id;
                axiosApi(
                  "/order/sic/team/update",
                  this.form1,
                  res => {
                    this.$message({
                      type: "success",
                      message: "编辑成功"
                    });
                    this.querySalesReport();
                    this.dialogShow = false;
                  },
                  1,
                  10,
                  res => {
                    this.$message({
                      type: "error",
                      message: res.data.message
                    });
                  }
                );
                done();
              } else {
                done();
              }
            }
          });
        }
      });
    },
    // 确定编辑
    deleteTeamItem(index) {
      //删除增加的业务员
      this.form1.sicTeamManagerList.splice(index, 1);
    },
    deleteTeam(item) {
      // 删除小组
      this.$msgbox({
        title: "提示信息",
        message: "确定删除该小组吗？",
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            let body = {
              id: item.id
            };
            axiosApi(
              "/order/sic/team/delete",
              body,
              res => {
                this.$message({
                  type: "success",
                  message: "删除成功"
                });
                this.querySalesReport();
              },
              1,
              10,
              res => {
                this.$message({
                  type: "error",
                  message: res.data.message
                });
              }
            );
            done();
          } else {
            done();
          }
        }
      });
    },
    addMember() {
      if (this.form1.sicTeamManagerList.length < 5) {
        this.form1.sicTeamManagerList.push({
          commissionScale: 0,
          distManagerId: null
        });
      } else {
        this.$message({
          type: "error",
          message: "每个小组最多5人"
        });
      }
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.querySalesReport();
    },
    /* 获取销售报表信息 */
    querySalesReport() {
      this.loading = true;
      axiosApi(
        "/order/sic/team/page",
        {},
        res => {
          this.loading = false;
          this.salesReportList = res.data.data.elements;
          this.total = res.data.data.totalCount;
        },
        this.currentPage,
        10,
        res => {
          this.salesReportList = [];
          this.loading = false;
          this.$message({
            type: "error",
            message: res.data.message
          });
        }
      );
    },
    getManagerList(query) {
      this.findSales(query);
    }
  }
};
</script>

<style lang="less">
.mySelect {
  .el-select-dropdown__empty {
    display: none !important;
  }
}

#salesInCarTeam {
    .btn-box{
        text-align: left;
        .el-button{
            margin-left: 0;
        }
    }
    .goods-num{
        display: flex;
        align-items: center;
        // justify-content: space-around;
        .goods-num-change{
            display: flex;
            align-items: center;
            .el-input--mini{
                width: 50px;
                .el-input__inner{
                    padding: 3px 0px;
                    text-align: center;
                }
            }
            .add-btn{
                font-size: 22px;
                line-height: 32px;
                width: 30px;
                height: 30px;
                border: 1px solid #ddd;
                border-left: none;
                cursor: context-menu;
            }
            .reduce-btn{
                font-size: 22px;
                line-height: 27px;
                width: 30px;
                height: 30px;
                border: 1px solid #ddd;
                border-right: none;
                cursor: context-menu;
            }
        }
    }
    .goods-img-info{
        display: flex;
        .goods-img{
            width: 40px;
            height: 40px;
            img{
                width: 100%;
                height: 100%;
            }
        }
        .goods-info{
            text-align: left;
            flex: 1;
            margin-left: 10px;
        }
    }
    .operate-con{
        text-align: right;
        background-color: #eeeeee;
        margin-top: 15px;
    }
  .el-table__body-wrapper {
    margin-top: 1px !important;
  }
  .commissionInput {
    width: 70px !important;
  }
    .chooseGoods{
        .el-dialog {
            width: 820px !important;
            .el-dialog__body{
                padding-bottom: 30px;
            }
        }
    }

  .button-box {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .el-form-item__error {
    margin-left: 22% !important;
  }
  .addDia {
    .el-form-item__label {
      width: 100px;
    }
    .pl-100 {
      padding-left: 100px;
    }
  }
  .itemList {
    display: flex;
    align-items: center;
    .el-form-item__error {
      margin-left: 30% !important;
    }
  }
  .scopeList {
    padding: 10px 0;
  }
  .selectDiv{
    .el-select .el-input--mini{
      width: 50px!important;
    }
    .el-input__inner{
      padding: 0 5px;
    }
  }
}
</style>