import {axiosApi,basePath} from '../../api/api.js'
import m from '../../main.js'
import {PROJECT} from '~/config/index.js';
import {bus} from "~/js/extend";
import { deepClone } from '~/utils/deepClone';

const nav = {
    namespaced: true,
    state: {
        nowNav: null,
        asideNav:{},
        tagNav:[],
        topNav:[],
        customizeNav:[],//自定义功能
        usuallyNav:[],//常用功能
        allNav:[],//所有的三级导航转变成二级导航,并且去掉为空的元素
        applicationMenuTreeList:[],//应用单独的分列
        jusAllNav:[]//全部标签
    },
    mutations: {
        changeAsideNav(state,payload){
            state.asideNav = payload
        },
        changeTagNav(state, payload) {
            let arr = deepClone(payload);
            for(let i=0; i<arr.length; i++){
                for(let j=i+1; j<arr.length; j++){
                    if(arr[i].url==arr[j].url){         //第一个等同于第二个，splice方法删除第二个
                        if (arr[i].customizeNav === arr[j].customizeNav) {
                            arr.splice(j,1);
                            j--;
                        }else{
                            arr.splice(i,1);
                            j--;
                        }

                    }
                }
            }
            if (arr.length > 15){
                m.$message.error('最多可添加15个标签导航')
            }else{
                state.tagNav = arr

            }


        },
        closeTagNav(state, payload) { 
            var tagNav =  deepClone(state.tagNav)
            for(var i=0;i<tagNav.length;i++){
                if (tagNav[i].url === payload) {
                    tagNav.splice(i,1)
                    break;
                }
            }
            state.tagNav = tagNav
            // console.log(tagNav,payload,'tagNav')
        },
        changNowNav(state,payload){
            state.nowNav = payload
        },
        // 改变常用菜单
        changeResourceList(state,payload) {
            state.usuallyNav = payload
        },
        // 改变自定义菜单
        changeCustomizeNav(state,payload) {
            state.customizeNav = payload;
        },
        // 新增常用菜单
        addUserNav(state,id){
            const body = {resourceId:id}
            const api = '/foundation/employeeRecentResource/update'
            axiosApi(api,body,(res)=>{
            })
        },
        // 遍历所有标签
        // getAllNav(state,url){
        //     const arr = state.jusAllNav
        //     arr.forEach(item=>{
        //         if(item.url == url){
        //             return true;
        //         }else {
        //             if(item.sub != false) {
        //                 item.sub.forEach(list =>{
        //                     if(list.url == url)
        //                 })
        //             }
        //         }
        //     })
        // }
    },
    actions: {
        getMenuList({state, dispatch}, payload) {
            const api = "/foundation/resource/displaytree";
            const body = {
                loginName: sessionStorage.loginName,
                appId: 2,
                hostPrefix: PROJECT.hostPrefix
            };
            axiosApi(api, body, res => {
                let allArray = [];
                let buttonList = []
                let arr = res.data.data.normalMenuTree;
                let arr2 = res.data.data.applicationMenuTreeList;//应用单独的分列
                if (arr2[0]) {
                    TreeEach2(arr2[0].applicationMenuTree)
                }
                if (arr2[1]){
                    TreeEach2(arr2[1].applicationMenuTree)
                }
                function TreeEach2(data) {
                    for (let i = 0; i < data.length; i++) {
                        if (data[i].type == 2 || data[i].id == 210163) {
                            buttonList.push(data[i]);
                            if (!!data[i].sub) buttonList = buttonList.concat(data[i].sub); //合并数组
                            data.splice(i, 1);
                            i--;
                        } else if (data[i].sub) {
                            TreeEach2(data[i].sub);
                        }
                    }
                }
                state.applicationMenuTreeList = arr2
                //酒店后台-酒店久集新增数据分组处理
                arr.forEach((item, index) => {
                    item['sub1'] = []
                    item['sub2'] = []
                    item['sub3'] = []
                    item['sub4'] = []
                    if (item.code === 'IS_OPEN_HOTEL_SUPER_C' && item.sub[0].groupId != null) {
                        item.sub1 = item.sub.filter((i => {
                            return i.groupId === 1
                        }))
                        item.sub2 = item.sub.filter((i => {
                            return i.groupId === 2
                        }))
                    }
                    if (item.code === 'IS_OPEN_TENANTSHOP_SUPERC' && item.sub[0].groupId != null) {
                        item.sub3 = item.sub.filter((i => {
                            return i.groupId === 1
                        }))
                        item.sub4 = item.sub.filter((i => {
                            return i.groupId === 2
                        }))
                    }
                    allArray.push(item)
                })
                arr2.forEach(item=>{
                    item.applicationMenuTree.forEach(list=>{
                        allArray.push(list)
                    })
                })

                state.jusAllNav = allArray;

                function TreeEach(data) {
                    for (let i = 0; i < data.length; i++) {
                        if (data[i].type == 2 || data[i].id == 210163) {
                            buttonList.push(data[i]);
                            if (!!data[i].sub) buttonList = buttonList.concat(data[i].sub); //合并数组
                            data.splice(i, 1);
                            i--;
                        } else if (!!data[i].sub) {
                            TreeEach(data[i].sub);
                        }
                        // debugger
                    }
                }

                TreeEach(arr)
                let arr3 = deepClone(arr)

                let applicationManageMenu = {
                    text:'应用',
                    sub:[],
                    id: '999999',//自定义的代表应用
                    url: '/customer/applicationManage',
                    wholeUrl: PROJECT.main+'/customer/applicationManage'
                }
                applicationManageMenu.sub = [].concat(arr2[0] ? arr2[0].applicationMenuTree : [],arr2[1] ? arr2[1].applicationMenuTree : [])
                if (applicationManageMenu.sub.length>0){
                    arr3.push(applicationManageMenu)
                }

                let accountCenterMenu = {
                    text:'首页',
                    sub:[],
                    id: '000000',//自定义的首页应用
                    url: '/customer/accountCenter',
                    wholeUrl: PROJECT.main+'/customer/accountCenter'
                }
                arr3.unshift(accountCenterMenu)

                arr3.forEach((v,i)=>{
                    if (v.id == '200000' || v.id == '210163') {
                        arr3.splice(i,1)
                    }
                })
                state.topNav = arr3;
                let newArr = deepClone(arr);
                newArr.forEach((v,i)=>{
                    if (v.sub.length == 0 || v.id == '200000' || v.id == '210163' ){
                        newArr.splice(i,1)
                        return false
                    }
                    let minArr = []
                    v.sub.forEach(data=>{
                        if (data.url){
                            // data.customize = false
                            minArr = minArr.concat(data)
                        }
                        if (data.sub&&data.sub.length>0){
                            data.sub.forEach(i=>{
                                // i.customize = false
                                 minArr = minArr.concat(i)
                             })
                        }
                    })
                    v.sub = minArr
                })
                state.allNav = newArr
                bus.$emit('buttonList', buttonList);
                // console.log(buttonList);

                sessionStorage.setItem("buttonList", JSON.stringify(buttonList))
            });
        },
    }
}

export default nav