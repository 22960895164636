<template>
    <div id="superBDetails">
        <!-- head -->
        <div class="order-info clearfix mb-30 ml-30 mr-30">
            <div class="title">
                超B信息
            </div>
            <div class="flex">
                <el-form class='flex-1'>
                    <el-form-item label="超B姓名:">
                        {{Data.name}}
                    </el-form-item>
                    <el-form-item label="注册手机号:">
                        {{Data.loginName}}
                    </el-form-item>
                    <el-form-item label='身份证号:'>
                        {{Data.idNo}}
                        <span @click="dialogShow = true" class="ml-20 cursor color-blue">
                            查看
                        </span>
                    </el-form-item>
                    <el-form-item label='推荐人:'>
                        {{Data.recommendName}}
                    </el-form-item>
                    <el-form-item label='申请时间:'>
                        {{Data.gmtInsert | time}}
                    </el-form-item>
                    <el-form-item label='状态:'>
                        <span v-if="Data.state == 1">待审核</span>
                        <span v-if="Data.state == 2">启用</span>
                        <span v-if="Data.state == 3">3不通过</span>
                        <span v-if="Data.state == 4">禁用</span>
                    </el-form-item>
                </el-form>
                <div class="ml-10 mt-30">
                    <div>
                        <el-button class="mr-30" @click="goBack()" v-if="this.$route.params.back==1">
                            关闭页面
                        </el-button>
                    </div>
                </div>
            </div>
        </div>
        .


        <!-- body -->
        <div class="bg-f">

            <!-- class -->
            <div class='bg-f'>
                <div class='bg-f mt-10'>
                    <div class="ml-30 mr-30 second-title clearfix">
                        <div class="choose-three font-14 color-6 fl tac mr-10 cursor " :class="first == 0?'actived':''"
                             @click="getStatus(0)">
                            <div>￥{{Data.totalCommission | fmoney}}</div>
                            <div>累计佣金</div>
                        </div>
                        <div class="choose-three font-14 color-6 tac fl mr-10 cursor"
                             :class="first == 1?'actived':''" @click="getStatus(1)">
                            <div>￥{{Data.subsidyOne | fmoney}}</div>
                            <div>一级补贴</div>
                        </div>
                        <div class="choose-three font-14 color-6 tac fl cursor"
                             :class="first == 2?'actived':''" @click="getStatus(2)">
                            <div>￥{{Data.subsidyTwo | fmoney}}</div>
                            <div>二级补贴</div>
                        </div>
                        <div class="choose-three font-14 color-6 tac fl cursor"
                             :class="first == 3?'actived':''" @click="getStatus(3)">
                            <div>{{Data.downstreamNumber}}</div>
                            <div>下游</div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- list 1 -->
            <div class="pl-30 pr-30 pb-30" v-show="first!=3">

                <el-table :data="tableData" v-loading="Loading" class='mt-10 table-default' stripe>

                    <el-table-column label="创建时间">
                        <template slot-scope="scope">
                            <span>{{scope.row.gmtCreate | time}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="订单编号" prop="orderSn"></el-table-column>
                    <el-table-column label="买家" prop="buyerName"></el-table-column>
                    <el-table-column label="卖家" prop="sellerName"></el-table-column>
                    <el-table-column label="订单金额" width="90">
                        <template slot-scope="scope">
                            <div>￥{{scope.row.discountTotalPrice | fmoney}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column label="佣金" width="90" v-if="first==0">
                        <template slot-scope="scope">
                            <div>￥{{scope.row.commission | fmoney}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column label="补贴" width="90" v-if="first==1">
                        <template slot-scope="scope">
                            <div>￥{{scope.row.subsidyOne | fmoney}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column label="补贴" width="90" v-if="first==2">
                        <template slot-scope="scope">
                            <div>￥{{scope.row.subsidyTwo | fmoney}}</div>
                        </template>
                    </el-table-column>

                    <el-table-column label="操作" width="90">
                        <template slot-scope="scope">
                            <el-button class='ml-0 tac' size="small" type='text' @click="showItem(scope.row)">查看
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>

                <!-- index -->
                <el-pagination class="tar mt-15" v-if="agencyTotal>0" :current-page="agencyProPage" :page-size='10'
                               :total='agencyTotal'
                               layout="prev , pager, next, jumper" @current-change="handleCurrentChange">
                </el-pagination>
            </div>

            <!-- list 2 -->
            <div class="pl-30 pr-30 pb-30" v-show="first==3">

                <el-table :data="tableData2" v-loading="Loading" class='mt-10 table-default' stripe>

                    <el-table-column label="下游注册手机" prop="loginName"></el-table-column>
                    <el-table-column label="下游姓名" prop="name"></el-table-column>
                    <el-table-column label="累计佣金">
                        <template slot-scope="scope">
                            <div>￥{{scope.row.commission | fmoney}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column label="最近单笔佣金">
                        <template slot-scope="scope">
                            <div>￥{{scope.row.lastCommission | fmoney}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column label="最近交易时间">
                        <template slot-scope="scope">
                            <div>{{scope.row.lastOrderTime | time}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="90">
                        <template slot-scope="scope">
                            <el-button class='ml-0 tac' size="small" type='text' @click="showItem(scope.row)">查看
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>

                <!-- index -->
                <el-pagination class="tar mr-30" v-if="agencyTotal2>0" :current-page="agencyProPage2" :page-size='10'
                               :total='agencyTotal2'
                               layout="prev , pager, next, jumper" @current-change="handleCurrentChange2">
                </el-pagination>
            </div>


        </div>

        <!-- dialog -->
        <el-dialog title="身份证照片" :visible.sync='dialogShow'>
            <div>
                <img class="" style="max-width: 100%" :src=Data.idFrontImg>
                <img class="" style="max-width: 100%" :src=Data.idBackImg>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {axiosApi, basePath} from "../../api/api";

    export default {
        name: "superBDetails",
        data() {
            return {
                dialogShow: false,
                orderInfoObj: {},
                Loading: false,
                first: 0,
                agencyProPage: 1,
                agencyTotal: 0,
                tableData: [],
                Data: {},
                type: 'commission',
                agencyProPage2: 1,
                agencyTotal2: 0,
                tableData2: [],
            }
        },
        methods: {
            //搜索 累计佣金 一级补贴 二级补贴
            achieve() {
                this.Loading = true;
                let body = {
                    accountId: this.$route.params.id,
                    type: this.type
                };
                let url = '/superb/account/detail/commissionList';
                axiosApi(url, body, res => {
                    this.Loading = false;
                    if (res.data.data == null) {
                        this.agencyTotal = 0;
                        this.tableData = [];
                    } else {
                        this.agencyTotal = res.data.data.totalCount;
                        this.tableData = res.data.data.elements;
                    }
                }, this.agencyProPage, 10, err => {
                })
            },
            //搜索 下游
            achieve2() {
                this.Loading = true;
                let body = {
                    accountId: this.$route.params.id,
                };
                let url = '/superb/account/detail/downstreamList';
                axiosApi(url, body, res => {
                    this.Loading = false;
                    this.agencyTotal2 = res.data.data.totalCount;
                    this.tableData2 = res.data.data.elements;
                }, this.agencyProPage2, 10, err => {
                })
            },
            getDetail() {
                let body = {
                    traderId: this.$route.params.traderId
                };
                let url = '/superb/account/detail';
                axiosApi(url, body, res => {
                    this.Data = res.data.data;
                }, this.agencyProPage, 10, err => {
                })
            },
            //分页
            handleCurrentChange(val) {
                this.agencyProPage = val;
                this.achieve();
            },
            //分页2
            handleCurrentChange2(val) {
                this.agencyProPage2 = val;
                this.achieve2();
            },
            //tab切换new
            getStatus(e) {
                this.first = e;
                this.setStatus(e);
            },
            setStatus(e) {
                switch (e) {
                    case 0:
                        this.type = 'commission';
                        this.agencyProPage = 1;
                        this.achieve();
                        break;
                    case 1:
                        this.type = 'subsidy_one';
                        this.agencyProPage = 1;
                        this.achieve();
                        break;
                    case 2:
                        this.type = 'subsidy_two';
                        this.agencyProPage = 1;
                        this.achieve();
                        break;
                    case 3:
                        this.agencyProPage2 = 1;
                        this.achieve2();
                        break;
                }
            },

            showItem(item) {
                if (this.first == 3) {
                    window.open(basePath + "/superB/superBDetails/" + item.traderId + "/" + item.id + "/" + '0', "_blank");
                } else {
                    window.open(basePath + "/superB/superBOrderDetails/" + item.orderSn+ "/"+ 2+ "/" + '0', "_blank");
                }
            },
            goBack() {
                // this.$router.push({path: 'superBManager'});
                history.go(-1)
            }
        }
        ,
        mounted() {
            this.$nextTick(() => {
                this.achieve();
                this.getDetail();
            })
        }
    }
</script>

<style lang="less">
    #superBDetails {
        padding-top: 1px;
        background-color: #ffffff;
        .el-dialog {
            width: 810px;
            height: 600px;

            img {
                width: 400px;
                height: 500px;
            }
        }

        .actived {
            border-radius: 2px;
            border: 1px solid #FF4201;
            color: #FF4201;
        }


        .second-title {
            height: 85px;
            padding: 17px 0px 17px 0px;
            box-sizing: border-box;
            border-bottom: 1px solid #EEEEEE;
        }

        .choose-three {
            width: 114px;
            height: 50px;
            line-height: 26px;
        }

        .order-info {
            margin-top: 30px;
            border: 1px solid #DDDDDD;
            box-sizing: border-box;

            .title {
                background-color: #EEEEEE;
                height: 40px;
                line-height: 40px;
                padding-left: 20px;
            }

            .el-form-item__label {
                padding: 0;
                line-height: 14px;
                height: 14px;
                padding-right: 20px;
                width: 145px;
            }

            .el-form-item__content {
                line-height: 14px;
            }

            .el-form {
                margin-top: 30px;
            }

            .cancelOrderDialog {
                .el-form {
                    width: 440px;
                    min-width: 440px;
                }
            }

            .gift {
                .el-form-item {
                    .el-form-item__label {
                        float: left;
                        line-height: 20px;
                    }

                    .el-form-item__content {
                        float: left;
                        width: 700px;
                        line-height: 20px;
                    }
                }
            }

        }
    }
</style>