<template>
    <div id="selectGift">
        <el-dialog title="选择赠品" :visible.sync="giftVisible" @close="goPage">
            <div v-loading="tableloading">
                <el-form :inline="true" :model="skuObj" label-width="80px" class="clearfix" :style="{width:'850px'}">
                    <div class="fl">
                        <el-form-item label="商品分类:">
                            <el-select v-model='skuObj.classifyName' placeholder='请选择' clearable>
                                <el-option v-for='item in classifylist' :key='item.id'
                                           :label='item.classifyName'
                                           :value='item.classifyName'></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="商品名称:">
                            <el-input  v-model.trim="skuObj.skuName"></el-input>
                        </el-form-item>
                    </div>
                    <el-button type="primary" class="fl ml-50" @click="querygiftList">查询</el-button>
                </el-form>
                <el-table ref="goodsList" @selection-change="handleSelectionChange" :data="giftData2" stripe border tooltip-effect="dark" class="mt-10 table-default">
                    <el-table-column type="selection" width="50"  :selectable='selectInit'>
                    </el-table-column>
                    <el-table-column label="赠品名称" prop='skuName' width="300">
                        <template slot-scope="scope">
                            <div class="goods-wrap">
                                <div class="ml-10 l-googs" style="position: relative;">
                                    <img v-if='scope.row.giftPic' :src="scope.row.giftPic" />
                                    <img v-else src="~/assets/goods.jpg" />
                                </div>
                                <div class="ml-10 r-goods">
                                    <div class="tal w-160 lh-20" :title="scope.row.skuName">{{scope.row.skuName}}</div>
                                    <div class="tal w-160 lh-20" v-if="scope.row.specInfoNum">规格1*{{scope.row.specInfoNum}}</div>
                                    <div class="tal w-160 lh-20">
                                        <span style="color:#FF4201;">￥{{scope.row.giftPrice | fmoney}}</span>
                                        <span style="color:#999999;text-decoration-line: line-through;margin-left: 10px;" v-if="scope.row.msrp">￥{{scope.row.msrp | fmoney}}</span>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="赠品分类" prop='classifyName'>
                    </el-table-column>
                    <el-table-column label="赠品库存" prop='giftStock'>
                    </el-table-column>
                    <el-table-column label="销售单位" prop='giftUnit'>
                    </el-table-column>
                    <el-table-column label="赠送数量" >
                        <template slot-scope="scope">
                            <div class="w-88">
                                <sy-input :disabled="scope.row.giftStatus==0" type="int" v-model="scope.row.quantity" :min="1" :max="Number(scope.row.giftStock)"></sy-input>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination class="tar" v-if='total>0 || total2>0'
                               :current-page="currentPage"
                               :page-size='10'
                               :total='isSelected ? total2 : total'
                               layout="prev , pager, next, jumper"
                               @current-change="handlePageChange">
                </el-pagination>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" :loading="btnLoading" @click="addgift">确 定</el-button>
                <el-button @click="goPage">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import { mapMutations } from 'vuex'
    export default {
        name: "selectGift",
        props:["productVisible","proData","max","currentGiftIndex"],
        watch:{
            productVisible(val) {
                this.giftVisible = val;
            },
            currentPage(val) {
                this.giftData2 = this.giftData2.slice((this.currentPage-1)*10, (this.currentPage-1)*10+10);
            },
            giftData(val){
                this.giftData2 = [...val];
            }
        },
        computed:{
            classifylist(){
                return this.$store.state.classifylist.classifylistData
            },
            giftData(){
                this.proData.map((v,i) => {
                    this.numList[i] = 1;
                })
                let arr=this.proData.slice((this.currentPage-1)*10, (this.currentPage-1)*10+10);
                arr.map((v,i) => {
                    this.$set(arr[i],'quantity',1)
                });
                return arr
            },
            total(){
                return this.proData.length
            },
            total2(){
                return this.giftData2.length
            }
        },
        data(){
            return {
                numList:[],
                giftData2:[],//备份数据源
                giftVisible:false,
                currentPage:1,
                btnLoading:false,
                tableloading:false,
                skuObj: {
                    skuName: null,     //商品名称
                    classifyName: null,   //商品分类
                },
                isSelected:false,//搜索判断
                willAdd:[],//即将添加的赠品
            }
        },
        methods:{
            ...mapMutations({
                'queryGift':'queryGift'
            }),
            change(scope){
            },
            goPage(){
                let arr=this.proData.slice(0, 10);
                arr.map((v,i) => {
                    this.$set(arr[i],'quantity',1)
                });
                this.giftData2 = arr;
                this.isSelected = false;
                this.skuObj.classifyName = null;
                this.skuObj.skuName = null;
                this.$emit('closedialog',null);
            },
            //添加赠品
            addgift(){
                if(this.willAdd.length==0){
                    this.$message.warning('请添加赠品');
                    return
                }

                    this.$emit('closedialog',null)
                    this.$store.commit('addGift',{
                        willAdd:this.willAdd,
                        currentGiftIndex:this.currentGiftIndex
                    });
            },
            selectInit(row,index){
                if(row.giftStatus==0){
                    return false
                }
                return true
            },
            //选中行
            handleSelectionChange(val){
                this.willAdd = val;
                let addList = val.map(v => {
                    return v;
                });
                let len = this.max - this.$store.state.marketActivity.willAddActivitySendList[this.currentGiftIndex].willAddGift.length;
                if(val.length>len){
                    let num = val.length - len;
                    val.splice(len, num);
                    addList.splice(len, num);//删除多余数据
                    this.willAdd = addList;
                    this.$message.warning(`最多选中${this.max}件商品`)
                }else{
                    this.willAdd = addList;
                }

            },
            //当前页change
            handlePageChange(val){
                this.currentPage = val;
                setTimeout(() => {
                    if(this.willAdd.length>0){// 有选中的数据
                        this.willAdd.forEach(row => {
                            this.$refs.goodsList.toggleRowSelection(row)
                        })
                    }else{// 不存在选中数据
                        this.$refs.goodsList.clearSelection()
                    }
                }, 0)
            },
            //查询
            querygiftList(){
                if(this.skuObj.classifyName || this.skuObj.skuName){
                    this.isSelected = true;
                    let arr = [];//接收过滤后的数据
                    this.proData.map(v => {
                        if (this.skuObj.classifyName && this.skuObj.skuName) {
                            if (v.classifyName == this.skuObj.classifyName && v.skuName.indexOf(this.skuObj.skuName) != -1) {
                                v.quantity = 1;
                                arr.push(v)
                                // return v;
                            }
                        } else if (this.skuObj.classifyName && !this.skuObj.skuName) {
                            if(v.classifyName == this.skuObj.classifyName){
                                v.quantity = 1;
                                arr.push(v)
                                // return v;
                            }
                        } else if (!this.skuObj.classifyName && this.skuObj.skuName) {
                            if(v.skuName.indexOf(this.skuObj.skuName) != -1){
                                v.quantity = 1;
                                arr.push(v)
                            }
                        }
                    });
                    this.giftData2 =[...arr];
                }else {
                    this.isSelected = false;
                    this.giftData2 = this.giftData;
                }
            }
        }
    }
</script>

<style lang="less">
    #selectGift{
        .el-table__header,.el-table__body,.el-table__empty-block{
            width: 100%!important;
        }
    }
    #selectGift .el-dialog {
        width: 950px;
        min-height: auto;
    }
    #selectGift .table-default.el-table .cell{
        padding: 3px 15px 0;
    }
    #selectGift .el-dialog__body {
        padding: 20px 30px;
    }
    #selectGift .el-form .el-input{width: 120px;}
    #selectGift .table-default .el-table__row{
        cursor: pointer;
    }
    #selectGift .table-default .el-table__body tr.current-row>td,#selectGift .table-default .el-table__body tr.current-row>td:hover{
        background-color: #edf7ff;
    }
    #selectGift {
        .el-dialog__footer{
            position: static;
            transform: translateX(0);
            padding: 10px 20px 35px;
        }
    }
    .el-table__header{
        width: 100%;
    }
    .w-88 .el-input{
        width: 88px!important;
    }
</style>
