<template>
    <div id="rechargemid">
        <div class="w1000">
            <p class="mt-30"><i class="iconfont font-16 icon-inspector color-b"></i><span class="font-16 ml-10 color-9 lh-16">温馨提示:</span></p>
            <p v-if="page" class="mt-10 ml26 color-9 font-16">1.提现金额必须大于0且不能大于账户可提现金额。<br />2.提现需扣除相应手续费。<br />3.提现申请成功后，一般会在2-3个工作日到账，如有问题，请咨询客服。<br />4.您只能用储蓄卡进行提现，不支持信用卡提现。<br /> 5.提现到银行卡会产生一定的手续费。</p>
            <p v-else class="mt-10 ml26 color-9 font-16">1.充值成功后，余额可能存在延迟现象，一般1-5分钟内到账，如有问题，请咨询客服。<br />2.充值金额必须大于0。<br />3.充值需扣除相应手续费。<br />4.您只能用储蓄卡进行充值，不支持信用卡充值。<br /> 5.充值完成后，您可以进入我的账户页面查看账户余额。</p>
        </div>
        <p class="mt-50"><span>{{page?"可提现余额:":"充值金额:"}}</span><span class="color-danger ml-30 font-20">{{balance | fmoney}}</span>元</p>
        <p v-if="!page">
            <span>充值手续费:</span><span class="color-danger ml-30 font-20">{{balance*rate | fmoney}}</span>元
            <span class="ml-50">最终到账:</span><span class="color-danger ml-30 font-20">{{balance - fmoney(balance*rate) | fmoney}}</span>元
        </p>
        <div v-if="banklist.length>0" class="mt-20">

            <div>
                <el-radio-group v-model="activeIndex" @change="changePayMethod()">
                    <div v-for="(item,index) in banklist" class="bank" :key="index" :class="{bb:activeIndex==index}" @change="activeIndex=index">
                        <el-radio :label="index"><span class="font-16">{{item.bankName}}</span>
                            <span v-if="item.bankCardNo">({{item.bankCardNo | end}})</span>
                            <span class="ml-50" v-if="item.bankType == 1">储蓄卡</span>
                            <span class="ml-50" v-if="item.bankType == 2">信用卡</span>
                        </el-radio>
                    </div>
                </el-radio-group>
            </div>
            <el-button type="text" class="dash mt-20 color-blue w150 mb-50" @click="dialogvisible=true">添加银行卡</el-button>
            <el-form label-position="top" :model="form" :rules="rules" ref="form">
                <el-form-item label="提现金额:" v-if="page" prop="money">
                    <el-input placeholder="请输入提现金额" v-model.trim="form.money"></el-input><span class="ml-10">元</span>
                </el-form-item>
                <div v-if="page">
                    手续费：{{commisssion | fmoney}}元（提现需要扣除 {{rate*100||0}}% 手续费）
                </div>
                <div class="mt-20" v-if="page">
                    最终到账：<span class="font-16 color-danger">{{realMoney | fmoney}}</span>元
                </div>
                <el-form-item class="mt-50">
                    <el-button type="danger" @click="submit">确定</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div v-else class="addbank bd cursor pl-30 mt-20" @click="dialogvisible=true"><i class="iconfont icon-plus-sign font-20 color-b"></i><span class="color-blue font-20 ml-10">添加银行卡</span></div>
        <!--添加银行卡弹窗-->
        <addbank v-on:closedialog="close" :dialogvisible="dialogvisible" :page="0"></addbank>
    </div>
</template>

<script>
    import { axiosApi, basePath } from "~/api/api"
    export default {
        data() {
            let check = (rule, val, callback) => {
                let reg = /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/
                if(!reg.test(val)) {
                    callback(new Error("提现金额必须为整数或小数"))
                } else {
                    if(val == 0) {
                        callback(new Error("输入金额需大于0"))
                    }
                    if(val * 1 > this.balance * 1) {
                        callback(new Error("输入金额超过最大可提现金额"))
                    }
                    callback()
                }
            }
            return {
                balance: 888, //余额
                form: {
                    money:null
                },
                rules: {
                    money: [{
                        required: true,
                        validator: check,
                    }],
                    pass: [{
                        required: true,
                        message: "请输入支付密码"
                    }, {
                        pattern: /^\d{6}$/,
                        message: "请输入6位数字的支付密码"
                    }]
                },
                page: "", //区别是 0提现  还是  1充值
                dialogvisible: false, //添加銀行卡彈窗是否关闭
                activeIndex: 0, //当前选中的银行卡
                banklist: [], //获取绑定银行卡信息
                ispass: null, //是否设置支付密码
                rate:0, //手续费率
            }
        },
        computed: {
            commisssion(){ //手续费
                if(this.form.money !== null && this.form.money !== ""){
                    return (this.form.money*1*this.rate).toFixed(2) || 0
                }else{
                    return 0
                }

            },
            realMoney(){ //最终到账
                return (this.form.money*1 - this.form.money*1*this.rate).toFixed(2)||0
            }
        },
        mounted() {
            this.$nextTick(() => {
                this.page = this.$route.params.page == 0
                this.balance = this.$route.params.money
                this.getbank()
            })
        },
        methods: {
            changePayMethod(){  // 支付方式选中change
                this.rate = this.banklist[this.activeIndex].rate / 10000
            },
            getbank() { //充值/提现余额获取支付方式列表
                let api = this.page ? "/anon/cbs/pay/cardList" : "/anon/cbs/pay/chargeCashBanks"
                let body = this.page ? {} : {
                    terminalId: "PC"
                }
                axiosApi(api, body, res => {
                    if(this.page) { //提现
                        this.banklist = res.data.data.filter(item => item.bankType == 1)
                        if(this.banklist.length>0){
                            // this.rate = (this.banklist[this.activeIndex].rate / 10000).toFixed(2)
                            // console.log(this.banklist[this.activeIndex],this.rate,'11')
                            /* 目前银行卡费率为 0  */
                            this.rate = 0
                        }
                    } else { //充值
                        this.banklist = res.data.data.payMethodList
                        if(this.banklist.length>0){
                            this.rate = this.banklist[this.activeIndex].rate / 10000
                        }
                        this.ispass = res.data.data.hasPayPassword
                    }
                }, null, null, null, 1)
            },
            close() {
                this.getbank()
                this.dialogvisible = false
            },
            submit() {
                this.$refs.form.validate(valid => {
                    if(valid) {
                        //console.log("ok")
                        let bank = this.banklist.filter((item, index) => index == this.activeIndex)
                        //console.log(bank)
                        if(this.page) { //提现
                            let payform = {}
                            if(sessionStorage.getItem("payform")) {
                                payform = Object.assign(JSON.parse(sessionStorage.getItem("payform")), {
                                    money: this.form.money,
                                    bankname: bank[0].bankName,
                                    card: bank[0].bankCardNo,
                                    bankCardId:bank[0].bankCardId,
                                    rate: this.rate
                                })
                            } else {
                                payform = {
                                    money: this.form.money,
                                    bankname: bank[0].bankName,
                                    card: bank[0].bankCardNo,
                                    bankCardId:bank[0].bankCardId,
                                    rate: this.rate
                                }
                            }
                            sessionStorage.setItem("payform", JSON.stringify(payform))
                            this.$router.push(`${basePath}/pay/withdrawal`)
                        } else { //充值
                            let newwin = window.open('about:blank', '_blank')
                            axiosApi("/anon/cbs/pay/chargeCash", {
                                amount: this.balance,
                                payMethodId: bank[0].methodId,
                                bankCardId: "",
                                payPassword: this.form.pass
                            }, res => {
                                console.log(res.data.data.bankData)
                                let bankData = JSON.parse(res.data.data.bankData)
                                let key = ''
                                let value = ''
                                for(let k in bankData){
                                    key = k
                                    value = bankData[k]
                                }
                                localStorage.alipayHtml = value
                                newwin.location = window.location.protocol + '//' + window.location.host + basePath + '/alipay'
                            },null,null,(err)=>{
                                newwin.close()
                            })
                        }
                    } else {
                        console.log("fail")
                    }
                })
            }
        }
    }
</script>

<style lang="less">
    #rechargemid {
        padding: 1px 0 150px 95px;
        .bank {
            width: 1000px;
            height: 80px;
            line-height: 80px;
            border-top: 1px solid #ddd;
            &:last-child {
                border-bottom: 1px solid #ddd;
            }
            .el-radio__inner {
                width: 20px;
                height: 20px;
                margin-right: 15px;
            }
            .el-radio {
                width: 100%;
                padding-left: 30px;
                box-sizing: border-box;
            }
            &:hover {
                border: 2px solid #0ae;
            }
            &.bb {
                border: 2px solid #0ae;
            }
        }
        .w150 {
            width: 150px;
            height: 40px;
            line-height: 40px;
        }
        .addbank {
            width: 1000px;
            height: 80px;
            line-height: 80px;
        }
        .w1000 {
            width: 1000px;
        }
    }
</style>