<template >
  <div class="width-1280 pt-20 pb-20">
    <p style="margin-top: 0;margin-bottom: 0;text-align: center;line-height: 32px;background: white">
    <strong><span style="font-size:19px;font-family:宋体;color:#3D3F3E">“久加久云商”用户（买家）注册协议</span></strong>
</p>
<p style="margin-top: 0;margin-bottom: 0;text-indent: 28px;background: white">
    <span style="font-size:14px;font-family:宋体;color:#333333">&nbsp;</span>
</p>
<p style="margin-top: 0;margin-bottom: 0;text-indent: 28px;line-height: 24px;background: white">
    <span style="font-size: 14px;font-family: 宋体">本协议是用户您与“久加久云商”平台（简称“本平台”，网址为</span><span style="font-size: 14px;font-family: 宋体;color: black">https://www.jiujiajiu.com</span><span style="font-size: 14px;font-family: 宋体">）所有者浙江久加久科技股份有限公司（以下简称“久加久”）之间就本平台服务等相关事宜所订立的契约，请用户您仔细阅读本注册协议，用户您点击“同意并继续”按钮后，本协议即构成对双方有约束力的法律文件。</span>
</p>
<p style="margin-top: 0;margin-bottom: 0;line-height: 24px;background: white">
    <span style="font-size: 14px;font-family: 宋体">&nbsp;</span>
</p>
<p style="text-align:left;line-height:24px;background:white">
    <strong><span style="font-size: 14px;font-family: 宋体">第1条 本平台服务条款的确认和接纳</span></strong>
</p>
<p style="text-align:left;text-indent:28px;line-height:24px;background:white">
    <span style="font-size: 14px;font-family: 宋体">1.1 </span><span style="font-size: 14px;font-family: 宋体">本平台的各项电子服务的所有权和运作权归久加久所有。用户同意所有注册协议条款并完成注册程序，才能成为本平台的正式用户。用户确认：本协议条款是处理双方权利义务的契约，始终有效，法律另有强制性规定或双方另有特别约定的，依其规定。</span>
</p>
<p style="text-align:left;text-indent:28px;line-height:24px;background:white">
    <span style="font-size: 14px;font-family: 宋体">1.2 </span><span style="font-size: 14px;font-family: 宋体">用户点击同意本协议的，</span><span style="font-size: 14px;font-family: 宋体">即视为用户确认自己具有相应的营业资质（包括但不限于《营业执照》和《食品流通许可证》等），能够独立承担法律责任，并且用户将对其在订单中提供的所有信息的真实性负责。如因用户不具有相应营业资质或提供信息不真实给久加久造成损失的，用户应承担相应赔偿责任。</span>
</p>
<p style="text-align:left;text-indent:28px;line-height:24px;background:white">
    <span style="font-size: 14px;font-family: 宋体">1.3 </span><span style="font-size: 14px;font-family: 宋体">久加久保留在中华人民共和国大陆地区法施行之法律允许的范围内独自决定拒绝服务、关闭用户账户、清除或编辑内容或取消订单的权利。</span>
</p>
<p style="text-align:left;line-height:24px;background:white">
    <span style="font-size: 14px;font-family: 宋体">&nbsp;</span>
</p>
<p style="text-align:left;line-height:24px;background:white">
    <strong><span style="font-size: 14px;font-family: 宋体">第2条 本平台服务</span></strong>
</p>
<p style="text-align:left;text-indent:28px;line-height:24px;background:white">
    <span style="font-size: 14px;font-family: 宋体">2.1 </span><span style="font-size: 14px;font-family: 宋体">久加久通过互联网依法为用户提供互联网信息等服务，用户在完全同意本协议及本平台规定的情况下，方有权使用本平台的相关服务。</span>
</p>
<p style="text-align:left;text-indent:28px;line-height:24px;background:white">
    <span style="font-size: 14px;font-family: 宋体">2.2 </span><span style="font-size: 14px;font-family: 宋体">用户必须自行准备如下设备和承担如下开支：</span>
</p>
<p style="text-align: left;text-indent: 0;line-height: 24px;background: white">
    <span style="font-size: 14px;font-family: 宋体">（1）</span><span style="font-size: 14px;font-family: 宋体">上网设备，包括并不限于电脑或者其他上网终端、调制解调器及其他必备的上网装置；</span>
</p>
<p style="text-align:left;line-height:24px;background:white">
    <span style="font-size: 14px;font-family: 宋体">（2）上网开支，包括并不限于网络接入费、上网设备租用费、手机流量费等。</span>
</p>
<p style="margin-top: 0;margin-bottom: 0;line-height: 24px;background: white">
    <span style="font-size: 14px;font-family: 宋体">（3）所有发给用户的通告都通过重要页面公告或电子邮件或公函传送。用户协议条款的修改、服务变更、或其它重要事件的通告都会以此形式进行。所有通告、修改或变更一经页面公告或电子邮件/公函传送，即视为已送达用户并对用户生效。</span>
</p>
<p style="margin-top: 0;margin-bottom: 0;line-height: 24px;background: white">
    <span style="font-size: 14px;font-family: 宋体">&nbsp;</span>
</p>
<p style="margin-top: 0;margin-bottom: 0;line-height: 24px;background: white">
    <strong><span style="font-size: 14px;font-family: 宋体">第3条 用户信息</span></strong>
</p>
<p style="margin-top: 0;margin-bottom: 0;text-indent: 28px;line-height: 24px;background: white">
    <span style="font-size: 14px;font-family: 宋体">3.1 </span><span style="font-size: 14px;font-family: 宋体">用户应自行诚信向本平台提供注册资料，用户保证其提供的注册资料真实、准确、完整、合法有效，用户注册资料如有变动的，应及时更新其注册资料。如果用户提供的注册资料不合法、不真实、不准确、不详尽的，用户需承担因此引起的相应责任及后果。&nbsp;&nbsp; &nbsp;</span>
</p>
<p style="margin-top: 0;margin-bottom: 0;text-indent: 28px;line-height: 24px;background: white">
    <span style="font-size: 14px;font-family: 宋体">3.2 </span><span style="font-size: 14px;font-family: 宋体">用户在本平台进行浏览、下单购物等活动时，涉及用户真实姓名/名称、通信地址、联系电话、电子邮箱等隐私信息的，本平台将予以严格保密，除非得到用户的授权或法律另有规定，本平台不会向外界披露用户隐私信息。</span>
</p>
<p style="margin-top: 0;margin-bottom: 0;text-indent: 28px;line-height: 24px;background: white">
    <span style="font-size: 14px;font-family: 宋体">3.3 </span><span style="font-size: 14px;font-family: 宋体">用户注册成功后，即成为本平台的合法用户，将得到一个账号和密码。用户有义务合理的保存、使用其账号和密码，对该账号和密码安全负全部责任；用户可以根据注册时填写的邮箱和电话找回密码。用户必须对以此组账号及密码登入系统后所发生的活动和事件负全责。用户若发现任何非法使用用户账号或存在安全漏洞的情况，请立即通知本平台。</span>
</p>
<p style="margin-top: 0;margin-bottom: 0;text-indent: 28px;line-height: 24px;background: white">
    <span style="font-size: 14px;font-family: 宋体">3.4 </span><span style="font-size: 14px;font-family: 宋体">用户同意，本平台拥有通过邮件、短信电话等形式，向在本平台用户、收货人发送订单信息、促销活动等告知信息的权利。</span>
</p>
<p style="margin-top: 0;margin-bottom: 0;text-indent: 28px;line-height: 24px;background: white">
    <span style="font-size: 14px;font-family: 宋体">3.5 </span><span style="font-size: 14px;font-family: 宋体">用户不得将在本平台注册获得的账户借给他人使用，否则用户应承担由此产生的全部责任，并与实际使用人承担连带责任。</span>
</p>
<p style="margin-top: 0;margin-bottom: 0;text-indent: 28px;line-height: 24px;background: white">
    <span style="font-size: 14px;font-family: 宋体">3.6</span><span style="font-size: 14px;font-family: 宋体">本平台尊重并保护用户的个人隐私权。本平台将以高度的勤勉、审慎义务对待用户的资料信息，除在如下情况下，不会将这些信息对外披露或向第三方提供：</span>
</p>
<p style="margin-top: 0;margin-bottom: 0;text-indent: 28px;line-height: 24px;background: white">
    <span style="font-size: 14px;font-family: 宋体">（1）根据法律的有关规定，或者行政司法机构的要求，向第三方或者行政、司法机构披露；</span>
</p>
<p style="margin-top: 0;margin-bottom: 0;text-indent: 28px;line-height: 24px;background: white">
    <span style="font-size: 14px;font-family: 宋体">（2）为提供用户所要求的产品和服务，而必须向第三方分享用户的个人信息。</span>
</p>
<p style="margin-top: 0;margin-bottom: 0;text-indent: 28px;line-height: 24px;background: white">
    <span style="font-size: 14px;font-family: 宋体">3.7 </span><span style="font-size: 14px;font-family: 宋体">用户需对本平台所显示的信息保密，不得向第三方透漏。</span>
</p>
<p style="margin-top: 0;margin-bottom: 0;text-indent: 28px;line-height: 24px;background: white">
    <span style="font-size: 14px;font-family: 宋体">&nbsp;</span>
</p>
<p style="margin-top: 0;margin-bottom: 0;line-height: 24px;background: white">
    <strong><span style="font-size: 14px;font-family: 宋体">第4条 商品信息</span></strong>
</p>
<p style="margin-top: 0;margin-bottom: 0;text-indent: 28px;line-height: 24px;background: white">
    <span style="font-size: 14px;font-family: 宋体">本平台上的商品价格、数量、是否有货等商品信息随时都有可能发生变动，本平台不作特别通知。由于网站上商品信息的数量极其庞大，虽然本平台会尽最大努力保证用户所浏览商品信息的准确性，但由于众所周知的互联网技术因素等客观原因存在，本平台网页显示的信息可能会有一定的滞后性或差错，对此情形用户知悉并理解。</span>
</p>
<p style="margin-top: 0;margin-bottom: 0;text-indent: 28px;line-height: 24px;background: white">
    <span style="font-size: 14px;font-family: 宋体">为表述便利，商品和服务简称为“商品”或“货物”。</span>
</p>
<p style="margin-top: 0;margin-bottom: 0;line-height: 24px;background: white">
    <span style="font-size: 14px;font-family: 宋体">&nbsp;</span>
</p>
<p style="margin-top: 0;margin-bottom: 0;line-height: 24px;background: white">
    <strong><span style="font-size: 14px;font-family: 宋体">第5条 订单和货款结算</span></strong>
</p>
<p style="text-align:left;text-indent:28px;line-height:24px;background:white">
    <span style="font-size: 14px;font-family: 宋体">5.1</span><span style="font-size: 14px;font-family: 宋体">在用户下订单时，请用户仔细确认所购商品的名称、价格、数量、型号、规格、尺寸、联系地址、电话、收货人等信息。收货人与用户本人不一致的，收货人的行为和意思表示视为用户的行为和意思表示，用户应对收货人的行为及意思表示的法律后果承担连带责任。</span>
</p>
<p style="margin-top: 0;margin-bottom: 0;text-indent: 28px;line-height: 24px;background: white">
    <span style="font-size: 14px;font-family: 宋体">5.2 </span><span style="font-size: 14px;font-family: 宋体">用户下单完成后根据所显示的金额进行支付，支付时可选择本平台现有各类支付方式之一进行支付。</span>
</p>
<p style="margin-top: 0;margin-bottom: 0;text-indent: 28px;line-height: 24px;background: white">
    <span style="font-size: 14px;font-family: 宋体">5.3 </span><span style="font-size: 14px;font-family: 宋体">用户在平台下单的订单数据将保存于系统，用户可在本平台进行相关查询，最终货款金额以用户与本平台实际结算为准。若用户发现系统提供数据有误，请及时联系本平台。</span>
</p>
<p style="margin-top: 0;margin-bottom: 0;line-height: 24px;background: white">
    <span style="font-size: 14px;font-family: 宋体">&nbsp;</span>
</p>
<p style="margin-top: 0;margin-bottom: 0;line-height: 24px;background: white">
    <strong><span style="font-size: 14px;font-family: 宋体">第6条 配送</span></strong>
</p>
<p style="text-align:left;text-indent:28px;line-height:24px;background:white">
    <span style="font-size: 14px;font-family: 宋体">6.1</span><span style="font-size: 14px;font-family: 宋体">销售方将会把商品（货物）送到用户所指定的收货地址，所有在本平台上列出的送货时间为参考时间，参考时间的计算是根据库存状况、正常的处理过程和送货时间、送货地点的基础上估计得出的。</span>
</p>
<p style="text-align:left;text-indent:28px;line-height:24px;background:white">
    <span style="font-size: 14px;font-family: 宋体">6.2 </span><span style="font-size: 14px;font-family: 宋体">因如下情况造成订单延迟或无法配送等，销售方不承担延迟配送的责任：</span>
</p>
<p style="text-align:left;line-height:24px;background:white">
    <span style="font-size: 14px;font-family: 宋体">（1）用户提供的信息错误、地址不详细等原因导致的；</span>
</p>
<p style="text-align:left;line-height:24px;background:white">
    <span style="font-size: 14px;font-family: 宋体">（2）货物送达后无人签收，导致无法配送或延迟配送的；</span>
</p>
<p style="text-align:left;line-height:24px;background:white">
    <span style="font-size: 14px;font-family: 宋体">（3）情势变更因素导致的；</span>
</p>
<p style="text-align:left;line-height:24px;background:white">
    <span style="font-size: 14px;font-family: 宋体">（4）不可抗力因素导致的，例如：自然灾害、交通戒严、突发战争等。</span>
</p>
<p style="margin-top: 0;margin-bottom: 0;line-height: 24px;background: white">
    <span style="font-size: 14px;font-family: 宋体">&nbsp;</span>
</p>
<p style="margin-top: 0;margin-bottom: 0;line-height: 24px;background: white">
    <strong><span style="font-size: 14px;font-family: 宋体">第7条 所有权及知识产权条款</span></strong>
</p>
<p style="margin-top: 0;margin-bottom: 0;text-indent: 28px;background: white">
    <span style="font-size: 14px;font-family: 宋体">7.1</span><span style="font-size: 14px;font-family: 宋体">久加久是本平台的所有者，拥有此网站内容及资源的版权等合法权利，受法律保护，有权不时地对本协议及本平台的内容进行修改和公示，一经公示即视为已通知用户并生效。在法律允许的最大限度范围内，久加久对本协议及本平台内容拥有解释权。</span>
</p>
<p style="margin-top: 0;margin-bottom: 0;text-indent: 28px;background: white">
    <span style="font-size: 14px;font-family: 宋体">7.2 </span><span style="font-size: 14px;font-family: 宋体">除法律另有强制性规定外，未经久加久的特别书面许可，任何单位或个人不得以任何方式全部或部分复制、转载、引用、链接、抓取或以其他方式使用本平台的信息内容。否则，久加久有权追究其法律责任。</span>
</p>
<p style="margin-top: 0;margin-bottom: 0;text-indent: 28px;background: white">
    <span style="font-size: 14px;font-family: 宋体">7.3 </span><span style="font-size: 14px;font-family: 宋体">本平台所刊登的资料信息（诸如文字、图表、标识、按钮图标、图像、声音文件片段、数字下载、数据编辑和软件），均是久加久或其内容提供者的财产，受中国和国际版权法的保护。本平台上所有内容的汇编是久加久的排他财产，受中国和国际版权法的保护。本平台上所有软件都是久加久或其关联公司或其软件供应商的财产，受中国和国际版权法的保护。</span>
</p>
<p style="margin-top: 0;margin-bottom: 0;line-height: 24px;background: white">
    <span style="font-size: 14px;font-family: 宋体">&nbsp;</span>
</p>
<p style="text-align:left;line-height:24px;background:white">
    <strong><span style="font-size: 14px;font-family: 宋体">第8条 责任限制</span></strong><strong><span style="font-size: 14px;font-family: 宋体">及不承诺担保</span></strong>
</p>
<p style="text-align:left;text-indent:28px;line-height:24px;background:white">
    <span style="font-size: 14px;font-family: 宋体">8.1</span><span style="font-size: 14px;font-family: 宋体">除非另有明确的书面说明，本平台及其所包含的或以其它方式通过本平台提供给用户的全部信息、内容、材料、产品（包括软件）和服务，均是在按现状和按现有的基础上提供的。</span>
</p>
<p style="text-align:left;line-height:24px;background:white">
    <span style="font-size: 14px;font-family: 宋体">除非另有明确的书面说明,久加久不对本平台的运营及其包含在本网站上的信息、内容、材料、产品（包括软件）或服务作任何形式的、明示或默示的声明或担保（根据中华人民共和国法律另有规定的以外）。</span>
</p>
<p style="text-align:left;text-indent:28px;line-height:24px;background:white">
    <span style="font-size: 14px;font-family: 宋体">8.2 </span><span style="font-size: 14px;font-family: 宋体">久加久不担保本平台所包含的或以其它方式通过本平台提供给用户的全部信息、内容、材料、产品（包括软件）和服务、其服务器或从本平台发出的电子信件、信息没有病毒或其他有害成分。</span>
</p>
<p style="text-align:left;text-indent:28px;line-height:24px;background:white">
    <span style="font-size: 14px;font-family: 宋体">8.3 </span><span style="font-size: 14px;font-family: 宋体">如因不可抗力或其它本平台无法控制的原因使本平台销售系统崩溃或无法正常使用导致网上交易无法完成或丢失有关的信息、记录等，久加久会合理地尽力协助处理善后事宜。</span>
</p>
<p style="text-align:left;line-height:24px;background:white">
    <span style="font-size: 14px;font-family: 宋体">&nbsp;</span>
</p>
<p style="text-align:left;line-height:24px;background:white">
    <strong><span style="font-size: 14px;font-family: 宋体">第9条 协议更新及用户关注义务</span></strong>
</p>
<p style="text-align:left;text-indent:28px;line-height:24px">
    <span style="font-size: 14px;font-family: 宋体;background: white">根据国家法律法规变化及网站运营需要，久加久有权对本协议条款不时地进行修改，修改后的协议一旦被张贴在本平台上即生效，并代替原来的协议。用户可随时登录查阅最新协议；</span><span style="font-size: 14px;font-family: 宋体">&nbsp;</span><span style="font-size: 14px;font-family: 宋体">用户有义务不时关注并阅读最新版的协议及网站公告。如用户不同意更新后的协议，可以且应立即停止接受久加久网站依据本协议提供的服务；如用户继续使用本网站提供的服务的，即视为同意更新后的协议。久加久建议用户在使用本平台之前阅读本协议及本平台的公告。&nbsp;<span style="background:white">如果本协议中任何一条被视为废止、无效或因任何理由不可执行，该条应视为可分的且并不影响任何其余条款的有效性和可执行性。</span></span>
</p>
<p style="text-align:left;line-height:24px;background:white">
    <span style="font-size: 14px;font-family: 宋体">&nbsp;</span>
</p>
<p style="text-align:left;line-height:24px;background:white">
    <strong><span style="font-size: 14px;font-family: 宋体">第10条 法律管辖和适用</span></strong>
</p>
<p style="text-align:left;text-indent:28px;line-height:24px">
    <span style="font-size: 14px;font-family: 宋体;background: white">本协议的订立、执行和解释及争议的解决均应适用在中华人民共和国大陆地区适用之有效法律（但不包括其冲突法规则）。 如发生本协议与适用之法律相抵触时，则这些条款将完全按法律规定重新解释，而其它有效条款继续有效。 如缔约方就本协议内容或其执行发生任何争议，双方应尽力友好协商解决；协商不成时，任何一方均可向久加久所在地区法院提起诉讼。</span>
</p>
<p>
    <br/>
</p>
  </div>
</template>

<script>
export default {
}
</script>

<style lang="less">
</style>
