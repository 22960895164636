/**
 * 
 * @param {function} func 目标函数
 * @param {number} wait 时间设置
 */
export function debouncing (func,wait = 1000){
    return function () {
        let args = arguments;
        let context = this;
        console.log('again')
        if (window.timer) {
            clearTimeout(window.timer);
        }
        let callnew  = !window.timer;
        window.timer = setTimeout(() => {
            window.timer = null;
            console.log('kaka')
        }, wait);
        if (callnew) func.apply(context, [...args])
    }
}
