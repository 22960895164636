<template>
    <div id="psOrderDetails" class="bg-f">
        <div class="psOrderDetails">
            <div v-loading='loading' class="pb-50 pt-30">
                <div class="order-info clearfix mb-50 ml-30 mr-30">
                    <div class="title">
                        订单信息
                    </div>
                    <div class="flex">
                        <el-form class='flex-1'>
                            <el-form-item label="订单编号:">
                                {{orderInfoObj.sn}}
                            </el-form-item>
                            <el-form-item label="客户名称:">
                                <span v-if="orderInfoObj.customerNickName">
                                {{orderInfoObj.customerNickName}} {{orderInfoObj.customerMobile}}</span>
                                <span v-else>（服务员代下单）</span>
                            </el-form-item>
                            <el-form-item label='下单时间:'>
                                {{orderInfoObj.gmtCreate | time}}
                            </el-form-item>
                            <el-form-item label='订单状态:'>
                                <div v-if="this.$route.query.type == 1">
                                    <span v-if="orderInfoObj.orderStatus == 1">待确认</span>
                                    <span v-if="orderInfoObj.orderStatus == 2">已预定</span>
                                    <span v-if="orderInfoObj.orderStatus == 3">就餐中</span>
                                    <span v-if="orderInfoObj.orderStatus == 4">已取消</span>
                                    <span v-if="orderInfoObj.orderStatus == 5">已完成</span>
                                </div>
                                <div v-else>
                                    <span v-if="orderInfoObj.orderStatus == 1">待付款</span>
                                    <span v-if="orderInfoObj.orderStatus == 2">部分支付</span>
                                    <span v-if="orderInfoObj.orderStatus == 3">已支付</span>
                                    <span v-if="orderInfoObj.orderStatus == 4">已完成</span>
                                    <span v-if="orderInfoObj.orderStatus == 5">已取消</span>
                                </div>
                            </el-form-item>
                            <el-form-item label="订单金额:">
                                ￥{{orderInfoObj.totalAmount | fmoney}}
                            </el-form-item>
                            <el-form-item label='备注信息:'>
                                <div style="padding-left: 146px">
                                    {{orderInfoObj.remark}}
                                </div>
                            </el-form-item>
                            <el-form-item label='就餐人数:'>
                                {{orderInfoObj.peopleCount}}
                            </el-form-item>
                            <el-form-item
                                    v-if="(this.$route.query.type == 0 &&orderInfoObj.orderStatus == 5)||(this.$route.query.type == 1 &&orderInfoObj.orderStatus == 4)"
                                    label='取消理由:'>
                                <div style="padding-left: 146px">
                                    {{orderInfoObj.cancelReason}}
                                </div>
                            </el-form-item>
                        </el-form>
                        <div class="ml-10 mt-30">
                            <div>
                                <el-button class="mr-10" type @click="goBack()">
                                    返回
                                </el-button>
                                <el-button
                                        v-if=" this.$route.query.type==0&&this.$route.query.isDisable&&this.$route.query.isSubCompany==0&&orderInfoObj.orderStatus == 1"
                                        class="mr-10"
                                        @click="dialogShow()">订单改价
                                </el-button>
                                <el-button
                                        v-if=" this.$route.query.type==0&&this.$route.query.isDisable&&this.$route.query.isSubCompany==0&&orderInfoObj.orderStatus == 1"
                                        class="mr-10"
                                        @click="cancelOrderVisible = true">取消订单
                                </el-button>
                                <el-button
                                        v-if=" this.$route.query.type==0&&this.$route.query.isDisable&&this.$route.query.isSubCompany==0&&orderInfoObj.orderStatus == 1"
                                        class="mr-10"
                                        @click="updatePersonCount">修改就餐人数
                                </el-button>
                                <el-button
                                        v-if="this.$route.query.type==0&&this.$route.query.isDisable&&this.$route.query.isSubCompany==0&&orderInfoObj.tableNo&& (orderInfoObj.orderStatus ==3||orderInfoObj.orderStatus ==2||orderInfoObj.orderStatus ==1)"
                                        class="mr-45"
                                        @click="affirmOrder0">确认收款
                                </el-button>
                                <el-button
                                        v-if=" this.$route.query.type==1&&this.$route.query.isDisable&&(orderInfoObj.orderStatus == 1)"
                                        class="mr-10"
                                        @click="cancelOrderVisible = true">取消预约
                                </el-button>
                                <el-button
                                        v-if=" this.$route.query.type==1&&this.$route.query.isDisable&&orderInfoObj.orderStatus == 1"
                                        class="mr-10"
                                        @click="affirmOrder1()">确认预约
                                </el-button>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="this.$route.query.type==1" class="order-info clearfix mb-50 ml-30 mr-30">
                    <div class="title">
                        预约信息
                    </div>
                    <div class="flex">
                        <el-form class='flex-1'>
                            <el-form-item label='预约到店时间:'>
                                {{orderInfoObj.appointmentTime | time}}
                            </el-form-item>
                            <el-form-item label="预约醒酒:">
                                <p v-if="orderInfoObj.breathingStartTime ">{{orderInfoObj.breathingStartTime |
                                    time}}</p>
                                <p v-else>不需要醒酒</p>
                            </el-form-item>
                            <el-form-item label='人数:'>
                                {{orderInfoObj.peopleCount}}
                            </el-form-item>
                            <el-form-item label='预约要求:'>
                                <p v-if=" orderInfoObj.tableCategoryType == 0 ">大厅包厢均可</p>
                                <p v-if=" orderInfoObj.tableCategoryType == 1 ">大厅</p>
                                <p v-if=" orderInfoObj.tableCategoryType == 2 ">包厢</p>
                            </el-form-item>
                            <el-form-item label="预约服务员:">
                                {{orderInfoObj.agentWaiterName}}
                            </el-form-item>
                        </el-form>
                    </div>
                </div>
                <!--收款记录-->
                <div class="ml-30 color-333 mt-30 mb-20">酒水单信息<span class="color-999">（共{{wineTableData.length}}件商品，合计:￥{{wineTotalAmount | fmoney}}）</span>
                </div>
                <div class="" key='payment'>
                    <el-table :data="wineTableData"
                              class='ml-30 mt-10 table-default mr-30' stripe>
                        <el-table-column prop="1" label="酒水名称/编码" width="350">
                            <template slot-scope="scope">
                                <div class="info flex clearfix ">
                                    <div class="ml-10">
                                        <img v-if='scope.row.skuImage' :src="scope.row.skuImage"/>
                                        <img v-else src="../../assets/goods.jpg"/>
                                    </div>
                                    <div class="flex-1 ml-10 mt-10 hidden">
                                        <div class="tal">{{scope.row.skuName}}</div>
                                        <div class="tal">酒水编码:{{scope.row.skuNo}}</div>
                                    </div>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="单价">
                            <template slot-scope="scope">
                                <span v-if="scope.row.unitPrice!=null">￥{{scope.row.unitPrice | fmoney}}</span>
                                <span v-else>时价</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="数量" prop="goodsCount"></el-table-column>
                        <el-table-column label="金额小计">
                            <template slot-scope="scope">
                                <span>￥{{scope.row.totalAmount | fmoney}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="操作" width="100"
                                         v-if=" this.$route.query.type==0&&this.$route.query.isDisable&&this.$route.query.isSubCompany==0">
                            <template slot-scope="scope">
                                <el-button v-if=" orderInfoObj.orderStatus == 1&&scope.row.skuType==3" size="small"
                                           type='text'
                                           @click.native="dialogShow(scope)">改价
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <div class="ml-30 color-333  mt-30 mb-20">菜单信息<span class="color-999">（共{{foodTableData.length}}件商品，合计:￥{{foodTotalAmount | fmoney}}）</span>
                </div>
                <!--收款记录-->
                <div class="" key='payment'>
                    <el-table :data="foodTableData" class='ml-30 mt-10 table-default mr-30' stripe>
                        <el-table-column prop="1" label="菜品名称/编码" width="350">
                            <template slot-scope="scope">
                                <div class="info flex clearfix ">
                                    <div class="ml-10">
                                        <img v-if='scope.row.skuImage' :src="scope.row.skuImage"/>
                                        <img v-else src="../../assets/goods.jpg"/>
                                    </div>
                                    <div class="flex-1 ml-10 mt-10 hidden">
                                        <div class="tal">{{scope.row.skuName}}</div>
                                        <div class="tal">菜品编码:{{scope.row.skuNo}}</div>
                                        <div class="tal color-9">{{scope.row.foodSpecName}}</div>
                                    </div>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="单价">
                            <template slot-scope="scope">
                                <span v-if="scope.row.unitPrice!=null">￥{{scope.row.unitPrice | fmoney}}</span>
                                <span v-else>时价</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="数量" prop="goodsCount"></el-table-column>
                        <el-table-column label="金额小计">
                            <template slot-scope="scope">
                                <span>￥{{scope.row.totalAmount | fmoney}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="操作"
                                         v-if="this.$route.query.type==0&&this.$route.query.isDisable&&this.$route.query.isSubCompany==0">
                            <template slot-scope="scope">
                                <el-button v-if="orderInfoObj.orderStatus == 1 "
                                           size="small" type='text'
                                           @click.native="dialogShow(scope)">改价
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>

                <!--其它-->
                <div v-if="this.$route.query.type==0&&orderInfoObj.tissueAmount+orderInfoObj.dinnerwareAmount!=0&&orderInfoObj.tableId!=0"
                     class="ml-30  mt-30 mb-20">
                    其它
                    <span class="color-999">（共{{orderInfoObj.peopleCount*2}}件商品，合计:￥{{orderInfoObj.tissueAmount+orderInfoObj.dinnerwareAmount
                    |
                    fmoney}}）</span>
                </div>
                <div v-if="this.$route.query.type==0&&orderInfoObj.tissueAmount+orderInfoObj.dinnerwareAmount!=0&&orderInfoObj.tableId!=0" class="" key='orderLog'>
                    <el-table :data="tablewareTableData"
                              class='ml-30 mt-10 table-default mr-30' stripe>
                        <el-table-column label="商品" prop="name"></el-table-column>
                        <el-table-column label="数量" prop="count"></el-table-column>
                        <el-table-column label="总价">
                            <template slot-scope="scope">
                                <span>￥{{scope.row.price | fmoney}}</span>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>

                <!--历史改价记录-->
                <div v-if="this.$route.query.type==0" class="ml-30  mt-30 mb-20">历史操作记录</div>
                <div v-if="this.$route.query.type==0" class="" key='orderLog'>
                    <el-table :data="orderInfoObj.orderChangePriceList"
                              class='ml-30 mt-10 table-default mr-30' stripe>
                        <el-table-column label="操作人" prop="operatorName"></el-table-column>
                        <el-table-column label="操作时间">
                            <template slot-scope="scope"> {{scope.row.gmtCreate | time}}</template>
                        </el-table-column>
                        <el-table-column label="操作类型">
                            <template slot-scope="scope">{{scope.row.operationType==1?'订单改价':scope.row.skuName+'改价'}}
                            </template>
                        </el-table-column>
                        <el-table-column label="改价金额">
                            <template slot-scope="scope">
                                <span v-if="scope.row.changePriceAmount>0">+￥{{scope.row.changePriceAmount | fmoney}}</span>
                                <span v-else>-￥{{scope.row.changePriceAmount*-1 | fmoney}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="备注" prop="remark"></el-table-column>
                    </el-table>
                </div>

                <!--付款记录-->
                <div v-if="this.$route.query.type==0" class="ml-30 mt-30 mb-20">付款记录</div>

                <div v-if="this.$route.query.type==0" class="aa">
                    <span>
                        共{{foodTableData.length+wineTableData.length}}件商品，订单金额:  ￥{{orderInfoObj.totalAmount | fmoney}}
                        <span v-if="this.$route.query.type==0&&(orderInfoObj.orderStatus == 1||orderInfoObj.orderStatus == 2)"
                              style="color: #FF4201">     还需支付: ￥{{priceSpread | fmoney}}</span>
                    </span>
                </div>

                <div v-if="this.$route.query.type==0" class="" key='orderLog'>
                    <el-table :data="orderInfoObj.payRecordVOList"
                              class='ml-30 table-default mr-30' stripe>
                        <el-table-column label="付款时间">
                            <template slot-scope="scope"> {{scope.row.gmtCreate | time}}</template>
                        </el-table-column>
                        <el-table-column label="付款方式">
                            <template slot-scope="scope">
                                <span v-if="scope.row.payMethod == 1">线下支付</span>
                                <span v-if="scope.row.payMethod == 2">会员卡支付</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="金额">
                            <template slot-scope="scope">
                                <span>￥{{scope.row.payAmount | fmoney}}</span>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>

            </div>
        </div>

        <!--订单改价-->
        <el-dialog class='cancelOrderDialog' title='订单改价' :visible.sync='updateOrderVisible'>
            <el-form class="pl-30 pr-30" :model="form1" ref="cancelOrderForm" :rules="rules2">
                <el-form-item label='改价金额' prop='price'>
                    <el-input v-model.trim="form1.price" placeholder="请输入改价金额" :maxlength="25"></el-input>
                </el-form-item>
                <el-form-item class="mt-10" label="" prop='remark'>
                    <el-input type='textarea' :maxlength="100" placeholder='请输入改价理由...'
                              :autosize="{ minRows: 3 ,maxRows: 10}"
                              v-model.trim='form1.remark'></el-input>
                </el-form-item>
            </el-form>
            <div slot='footer' class="dialog-footer">
                <el-button type='danger' @click="handleCommit()">确 定</el-button>
                <el-button @click="updateOrderVisible = false">取 消</el-button>
            </div>
        </el-dialog>
        <el-dialog class="minMop" title="修改就餐人数" :visible.sync='dialog'>
            <el-form class="pr-30" :model="form2" ref="ruleForm1" :rules="rules1">
                <el-form-item label="就餐人数:" prop='count'>
                    <el-input placeholder='输入就餐人数' v-model.trim='form2.count'></el-input>
                </el-form-item>
            </el-form>
            <div slot='footer' class="dialog-footer">
                <el-button type='danger' @click="save">确 定</el-button>
                <el-button @click="dialog = false">取 消</el-button>
            </div>
        </el-dialog>

        <!--取消订单弹出框-->
        <el-dialog class='cancelOrderDialog' :close-on-click-modal='false' @close="closeOrderDialog"
                   :title='this.$route.query.type==1?"取消预约理由":"取消订单理由"' :visible.sync='cancelOrderVisible'>
            <el-form class="pl-30 pr-30" :model="form3" ref="cancelOrderForm3" :rules="rules">
                <el-form-item label="" prop='cancelReason'>
                    <el-input type='textarea' :maxlength="100"
                              :placeholder='this.$route.query.type==1?"请输入取消预约理由...":"请输入取消订单的理由..."'
                              :autosize="{ minRows: 3 ,maxRows: 5}"
                              v-model.trim='form3.cancelReason'></el-input>
                </el-form-item>
            </el-form>
            <div slot='footer' class="dialog-footer">
                <el-button type='danger' @click="cancelOrderCommit()">提
                    交
                </el-button>
                <el-button @click="cancelOrderVisible = false">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {axiosApi, basePath} from "../../api/api"

    export default {
        data() {
            const personCount = (rule, value, callback) => {
                if (value == 0) {
                    callback('人数不能为0');
                } else {
                    callback();
                }
            }

            return {
                rules1: {
                    count: [{
                        required: true,
                        pattern: /^[0-9]{1,2}$/,
                        message: '请输入正确修改人数'
                    }, {
                        validator: personCount,
                    }]
                },
                rules2: {
                    price: [{
                        required: true,
                        message: '请输入改价金额',
                        pattern: /^(0|[1-9]\d*)(\s|$|\.\d{1,2}\b)/
                    }],
                    remark: [{
                        required: true,
                        message: '改价理由不能为空'
                    }, {
                        min: 4,
                        max: 100,
                        message: '改价理由为4-100位字符'
                    }]
                },
                priceSpread: 0,//差价
                isAffirm: true,//有时价不能确认收款
                dialog: false,
                orderInfoObj: {},
                tableData: [], //员工列表
                wineTableData: [],
                foodTableData: [],
                wineTotalAmount: 0,
                foodTotalAmount: 0,
                tablewareTableData: [],
                loading: false, //loading
                form1: {
                    type: 0,//改价类型 0 订单改价 1 商品改价
                    price: null,
                    remark: null,
                    id: null,
                    preTotalAmount: null,
                },
                form2: {
                    count: null,//改价类型 0 订单改价 1 商品改价
                },
                form3: {
                    cancelReason: null,
                },
                rules: {
                    cancelReason: [{
                        required: true,
                        message: '不能为空',
                    }, {
                        max: 100,
                        message: '不能超过100个字！'
                    }]
                },
                updateOrderVisible: false, //改价 dialogVisible
                cancelOrderVisible: false, //取消订单弹框
            }
        },
        watch: {
            updateOrderVisible(val) {
                if (!val) {
                    this.form1.price = null;
                    this.form1.remark = null;
                    this.form1.id = null;
                    this.form1.preTotalAmount = null;
                    this.$refs.cancelOrderForm.resetFields()
                }
            }
            , dialog(val) {
                if (!val) {
                    this.form2.count = null;
                    this.$refs.ruleForm1.resetFields()
                }
            }
        },
        mounted() {
            this.$nextTick(() => {
                this.anonOrderDetailInfo() //订单详情
            })
        },
        methods: {
            updatePersonCount() {
                this.dialog = true
            },

            goBack() {
                if (this.$route.query.isSubCompany == 0) {
                    this.$router.push({
                        path: 'PsOrder',
                        query: {type: this.$route.query.type, first: this.$route.query.first}
                    });
                } else {
                    this.$router.push({
                        path: 'PsOrderB',
                        query: {type1: this.$route.query.type1, first: this.$route.query.first}
                    })
                }
            },
            closeOrderDialog() {
                this.form3.cancelReason = null;
            },
            //保存取消订单理由
            cancelOrderCommit() {
                this.$refs.cancelOrderForm3.validate((valid) => {
                    if (valid) {
                        this.cancelOrderVisible = false;
                        let url;
                        let body;
                        if (this.$route.query.type == 0) {
                            url = '/catering/platform/order/cancel';
                            body = {
                                id: this.$route.query.id,
                                cancelReason: this.form3.cancelReason,
                            }
                        } else {
                            url = '/catering/platform/orderAppointmentStatus/update';
                            body = {
                                sn: this.$route.query.sn,
                                orderStatus: 4,
                                cancelReason: this.form3.cancelReason,
                            }
                        }


                        axiosApi(url, body, (res) => {
                            this.$message({
                                type: 'success',
                                duration: 1000,
                                message: "取消成功!",
                            });
                            this.anonOrderDetailInfo();
                        }, this.currentPage, 10, (res) => {
                            this.$message.error(res.data.message)
                        })
                    } else {
                        return false
                    }
                })
            },

            dialogShow(scope) {
                if (scope) {
                    this.form1.type = 1;
                    this.form1.id = scope.row.id;
                    this.form1.preTotalAmount = this.orderInfoObj.totalAmount;
                } else {
                    this.form1.type = 0;
                    this.form1.preTotalAmount = this.orderInfoObj.totalAmount;
                }
                this.updateOrderVisible = true;
            },

            handleCommit() {
                let body = null;
                if (this.form1.type) {
                    body = {
                        orderItems: [{
                            unitPrice: this.form1.price,
                            remark: this.form1.remark,
                            id: this.form1.id,

                        }],
                        orderSn: this.orderInfoObj.sn
                    };
                } else {
                    body = {
                        totalAmount: this.form1.price,
                        preTotalAmount: this.form1.preTotalAmount,
                        remark: this.form1.remark,
                        sn: this.orderInfoObj.sn
                    };
                }

                this.$refs.cancelOrderForm.validate((valid) => {
                    if (valid) {
                        this.updateOrderVisible = false
                        axiosApi(this.form1.type ? '/catering/platform/orderItem/changePrice' : '/catering/platform/order/changePrice', body, (res) => {
                            this.$message({
                                type: 'success',
                                message: '改价成功',
                                duration: 1000
                            })
                            this.anonOrderDetailInfo();
                        }, 1, 10, (res) => {
                            this.$message({
                                type: 'error',
                                message: res.data.message,
                                duration: 1000
                            })
                        })
                    } else {
                        return false
                    }
                })
            },
            save() {
                this.$refs.ruleForm1.validate((v) => {
                    if (v) {
                        axiosApi('/catering/platform/peopleCount/update', {
                            peopleCount: this.form2.count,
                            tableId: this.orderInfoObj.tableId,
                        }, (res) => {
                            this.$message({
                                type: 'success',
                                message: "修改成功!",
                                duration: 1000
                            });
                            this.anonOrderDetailInfo();
                            this.dialog = false;
                        }, this.currentPage, 10, (res) => {
                            this.$message.error(res.data.message)
                        })
                    }
                })
            },
            affirmOrder0() {
                if (this.isAffirm) {
                    this.$confirm("确定收款吗?是否继续", "提示", {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                    }).then(() => {
                        axiosApi('/catering/platform/order/confirm', {
                            id: this.$route.query.id,
                        }, (res) => {
                            this.$message({
                                type: 'success',
                                message: "确认成功!",
                                duration: 1000
                            });
                            this.anonOrderDetailInfo();
                        }, this.currentPage, 10, (res) => {
                            this.$message.error(res.data.message)
                        })
                    }).catch(() => {
                        this.$message({
                            message: "已取消确认",
                        });
                    });
                } else {
                    this.$confirm("请修改时价金额后再确认收款", "提示", {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                    }).then(() => {

                    }).catch(() => {

                    });
                }
            },
            affirmOrder1() {
                this.$confirm("将确认该预约订单, 是否继续?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                }).then(() => {
                    axiosApi('/catering/platform/orderAppointmentStatus/update', {
                        sn: this.$route.query.sn,
                        orderStatus: 2,
                    }, (res) => {
                        this.$message({
                            type: 'success',
                            message: "确认成功!",
                            duration: 1000
                        });
                        this.anonOrderDetailInfo();
                    }, this.currentPage, 10, (res) => {
                        this.$message.error(res.data.message)
                    })
                }).catch(() => {
                    this.$message({
                        message: "已取消确认",
                    });
                });
            },
            anonOrderDetailInfo() { //订单详情接口
                this.loading = true
                axiosApi(this.$route.query.type == 1 ? '/catering/platform/orderAppointment/selectDetails' : '/catering/platform/subCompanyOrderDetails/select', {
                    id: this.$route.query.id,
                    sn: this.$route.query.sn
                }, (res) => {
                    this.orderInfoObj = res.data.data;
                    if (this.$route.query.type == 1) {
                        this.wineTableData = this.orderInfoObj.orderAppointmentItemList.filter(item => {
                            return item.skuType == 2 || item.skuType == 3;
                        });
                        this.foodTableData = this.orderInfoObj.orderAppointmentItemList.filter(item => {
                            return item.skuType == 1;
                        });
                    } else {
                        this.wineTableData = this.orderInfoObj.orderItems.filter(item => {
                            return item.skuType == 2 || item.skuType == 3;
                        });
                        this.foodTableData = this.orderInfoObj.orderItems.filter(item => {
                            return item.skuType == 1;
                        });
                    }

                    if (this.orderInfoObj.payRecordVOList != null) {
                        let price = 0;
                        this.orderInfoObj.payRecordVOList.forEach(item => {
                            price = item.payAmount + price;
                        });
                        this.priceSpread = this.orderInfoObj.totalAmount - price;
                    } else {
                        this.priceSpread = this.orderInfoObj.totalAmount;
                    }


                    this.isAffirm = true;
                    this.foodTableData.map(item => {
                        if (item.unitPrice == null) {
                            this.isAffirm = false;
                            return;
                        }
                    });
                    this.foodTotalAmount = 0;
                    this.wineTotalAmount = 0;
                    this.foodTableData.map(item => {
                        this.foodTotalAmount = this.foodTotalAmount + item.totalAmount;
                    });
                    this.wineTableData.map(item => {
                        this.wineTotalAmount = this.wineTotalAmount + item.totalAmount;
                    });
                    this.tablewareTableData = [];
                    this.tablewareTableData.push({
                        price: this.orderInfoObj.tissueAmount,
                        name: '纸巾',
                        count: this.orderInfoObj.peopleCount,
                    });
                    this.tablewareTableData.push({
                        price: this.orderInfoObj.dinnerwareAmount,
                        name: '餐具',
                        count: this.orderInfoObj.peopleCount,
                    })
                    this.loading = false
                }, 1, 10, (res) => {
                    this.loading = false
                })
            },
        }
    }
</script>

<style lang="less">
    #psOrderDetails {
        .flex {
            display: flex;
        }

        .aa {
            padding-left: 30px;
            margin-left: 30px;
            margin-right: 30px;
            padding-bottom: 15px;
            padding-top: 15px;
            border: 1px solid #DDDDDD;
        }

        .el-dialog {
            height: 300px !important;
            min-height: 300px !important;
        }


        .bl {
            border-left: 1px solid #DDDDDD;
        }

        .order-info {
            margin-top: 30px;
            border: 1px solid #DDDDDD;
            box-sizing: border-box;

            .title {
                background-color: #EEEEEE;
                height: 40px;
                line-height: 40px;
                padding-left: 20px;
            }

            .el-form-item__label {
                padding: 0;
                line-height: 14px;
                height: 14px;
                padding-right: 20px;
                width: 145px;
            }

            .el-form-item__content {
                line-height: 14px;
            }

            .el-form {
                margin-top: 30px;
            }

            .cancelOrderDialog {
                .el-form {
                    width: 440px;
                    min-width: 440px;
                }
            }

            .gift {
                .el-form-item {
                    .el-form-item__label {
                        float: left;
                        line-height: 20px;
                    }

                    .el-form-item__content {
                        float: left;
                        width: 700px;
                        line-height: 20px;
                    }
                }
            }

        }

        .el-table {
            overflow: hidden;
            width: 95%;
            max-width: 100%;
            background-color: #fff;
            border: 1px solid #dfe6ec;
            font-size: 12px;
            color: #1f2d3d;

            .cell {
                padding: 10px 30px;
            }
        }

        .el-form-item__error {
            margin-left: 190px !important;
        }
    }
</style>
