<template>
	<div class="forget width-1280">
		<div class="mb-50">
			<!-- <img class="sub mr-10" src="../../assets/register.png" /> -->
			<i class="iconfont icon-inspector color-b font-18 mr-10"></i>
			<span class="color-6 font-16">
				<span v-if="bool">2/2</span>
				<span v-else>3/3</span>
				修改密码成功
			</span>
		</div >
		<div class="w330 tac">
			<img src="../../assets/register-success.png"/>
			<p class="color-danger mt-30 font-30 lh-30">修改密码成功</p>
			<el-button v-if="bool" class="mt-50" @click="goPage('/accountSafe')">返回</el-button>
			<el-button v-else class="mt-50" @click="goPage('/login')">立即登录</el-button>
			
		</div>
	</div>
</template>
<script>
    import { axiosApi, gotoLoginPage, basePath } from "~/api/api.js";

	export default{
		data(){
			return{
				bool:false//判断是忘记密码还是修改密码
			}
		},
		mounted(){
			this.$nextTick(()=>{
				this.bool = this.$route.params.path == "cpf";
			})
		},
		methods:{
			goPage(path){
				if (path=='/login') {
					gotoLoginPage();
				}
				this.$router.push("/customer"+path)
			}
		}
	}
	
</script>