var render = function render(){var _vm=this,_c=_vm._self._c;return _vm._m(72)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('br')])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"text-align":"center","line-height":"32px"}},[_c('strong',[_c('span',{staticStyle:{"font-size":"19px","font-family":"宋体","color":"#3D3F3E","font-weight":"bolder"}},[_vm._v("共享经济合作伙伴协议")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"text-indent":"28px","line-height":"29px"}},[_c('span',{staticStyle:{"font-family":"宋体"}},[_vm._v(" ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"text-indent":"28px","line-height":"29px"}},[_c('span',{staticStyle:{"font-size":"14px","font-family":"'MS Mincho', serif"}},[_vm._v("\n\t\t\t\t感谢您选择云账户（天津）共享经济信息咨询有限公司（以下简称“本公司”）为您提供共享经济综合服务。您在使用服务前阅读《共享经济合作伙伴协议》（下称“本协议”）。本公司将依托综合实力竭诚为您提供最规范、专业的服务。\n\t\t\t")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"text-indent":"28px","line-height":"29px"}},[_c('span',{staticStyle:{"font-size":"14px","font-family":"'MS Mincho', serif"}},[_vm._v("\n\t\t\t\t在您接受本协议之前，请您务必审慎阅读、充分理解本协议各条款内容，特别是免除或者限制责任的条款、管辖与法律适用条款，以及开通或使用某项服务的单独协议。限制、免责条款可能以黑体加粗的形式提示您重点注意。 \n\t\t\t")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"text-indent":"28px","line-height":"29px","margin-top":"10px"}},[_c('span',{staticStyle:{"font-size":"14px","font-family":"'MS Mincho', serif","font-weight":"bolder","text-decoration":"underline","font-style":"italic"}},[_vm._v("\n\t\t\t\t特别提示：\n\t\t\t")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"text-indent":"28px","line-height":"29px","margin-top":"10px"}},[_c('span',{staticStyle:{"font-size":"14px","font-family":"'MS Mincho', serif","font-weight":"bolder","text-decoration":"underline","font-style":"italic"}},[_vm._v("\n\t\t\t\t针对获得生产经营所得的自由职业者在使用本协议项下本公司提供的共享经济综合服务时，本公司承诺依法纳税、确保自然人纳税人取得税后的合法收入。\n\t\t\t")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"text-indent":"28px","line-height":"29px","margin-top":"10px"}},[_c('span',{staticStyle:{"font-size":"14px","font-family":"'MS Mincho', serif","font-weight":"bolder","text-decoration":"underline","font-style":"italic"}},[_vm._v("\n\t\t\t\t军人、公职人员等国家法律法规和纪律规定禁止从事兼职或经商的人员，严禁使用本协议项下本公司提供的共享经济综合服务。\n\t\t\t")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"text-indent":"28px","line-height":"29px","margin-top":"10px"}},[_c('span',{staticStyle:{"font-size":"14px","font-family":"'MS Mincho', serif","font-weight":"bolder","text-decoration":"underline","font-style":"italic"}},[_vm._v("\n\t\t\t\t公司雇员等其他与公司具有劳动/劳务合同关系、或其他类似的劳动人事法律关系并从与其有前述关系的公司取得工资薪金所得的人员，严禁使用本协议项下本公司提供的共享经济综合服务；该类人员因从事生产经营而从非与其有前述关系的企、事业单位所取得的生产经营所得，本公司可为其提供本协议项下的共享经济综合服务。\n\t\t\t")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"text-indent":"28px","line-height":"29px","margin-top":"10px"}},[_c('span',{staticStyle:{"font-size":"14px","font-family":"'MS Mincho', serif","font-weight":"bolder","text-decoration":"underline","font-style":"italic"}},[_vm._v("\n\t\t\t\t公司法定代表人、股东、董事、监事等其他从所属公司取得收入的人员，一律严禁使用本协议项下本公司提供的共享经济综合服务；该类人员因从事生产经营而从非与其有前述关系的企、事业单位所取得的生产经营所得，本公司可为其提供本协议项下的共享经济综合服务。\n\t\t\t")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"text-indent":"28px","line-height":"29px","margin-top":"10px"}},[_c('span',{staticStyle:{"font-size":"14px","font-family":"'MS Mincho', serif","font-weight":"bolder","text-decoration":"underline","font-style":"italic"}},[_vm._v("\n\t\t\t\t一经发现有上述任一行为的，本公司有权上报税务机关及其他相关国家机关。税务机关及其他相关国家机关根据《中华人民共和国税收征收管理法》及其他相关法律法规的规定追责任主体的法律责任。\n\t\t\t")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"text-indent":"28px","line-height":"29px","margin-top":"10px"}},[_c('span',{staticStyle:{"font-size":"14px","font-family":"'MS Mincho', serif","font-weight":"bolder","text-decoration":"underline"}},[_vm._v("\n\t\t\t\t鉴于：\n\t\t\t")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-left":"35px","line-height":"29px","margin-top":"10px"}},[_c('span',{staticStyle:{"font-family":"宋体"}},[_vm._v("本公司是在中国境内注册成立的拥有相关资质的有限责任公司，具备共享经济资源平台及智能系统，为诸多互联网企业平台客户（简称“企业客户”）提供共享经济综合服务，接受其委托为其筛选适合的自由职业者并可提供综合共享经济服务；您系本公司按照与企业客户签署的《共享经济智能综合服务协议》的规定，接受企业客户的委托为其筛选的具有专业素养、能力及相应许可的自由职业者，能满足本公司企业客户的商业需求；双方根据相关法律法规进行友好协商并签署本协议。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-left":"35px","line-height":"29px","margin-top":"30px"}},[_c('strong',[_c('span',{staticStyle:{"font-family":"宋体"}},[_c('span',{staticStyle:{"font-variant-numeric":"normal","font-variant-east-asian":"normal","font-weight":"normal","font-stretch":"normal","font-size":"9px","line-height":"normal","font-family":"'Times New Roman'"}},[_vm._v("  ")])])]),_c('strong',[_c('span',{staticStyle:{"font-family":"宋体","font-weight":"bolder"}},[_vm._v("第一条 合作内容")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-left":"35px","line-height":"29px","margin-top":"10px"}},[_c('span',{staticStyle:{"font-family":"宋体"}},[_vm._v("1、本公司为拥有相关资质的有限责任公司，具备共享经济资源平台及智能系统，本公司将与您开展\n业务合作，向您宣贯企业客户业务规则（包括申请流程、自由职业者应履行的活动条件、活动内容、活\n动注意事项、最终对应到您的绩效费计算及支付方式等）。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-left":"35px","line-height":"29px","margin-top":"10px"}},[_c('span',{staticStyle:{"font-family":"宋体"}},[_vm._v("2、您系根据本公司按照与企业客户签署的《共享经济智能综合服务协议》的规定，接受企业客户的\n委托为其筛选的具有专业素养、能力及相应许可的自由职业者，经本公司筛选及推荐，您可向企业客户\n提出申请并提供相应生产经营活动，申请通过后您可按照本公司企业客户业务规则查询活动需求、接受\n活动需求、履行生产经营活动过程中的相关义务并享有相关权利、查询活动订单、收取最终对应到您的\n绩效费等。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-left":"35px","line-height":"29px","margin-top":"10px"}},[_c('span',{staticStyle:{"font-family":"宋体"}},[_vm._v("3、您应自行负担就其取得的生产经营绩效费需缴纳的各项税款及行政收费（如有），本公司按照有\n关税收政策规定及本公司主管税务机关的要求，并接受您委托向其代征前述税费；本公司接受您委托向\n企业客户收取绩效费并将完税之后的金额支付给您。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-left":"35px","line-height":"29px","margin-top":"10px"}},[_c('span',{staticStyle:{"font-family":"宋体"}},[_vm._v("4、依照代征权限，本公司可以进行代征的范围仅限于从事生产经营的个人，即本协议项下的自由职业者。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-left":"35px","line-height":"29px","margin-top":"30px"}},[_c('strong',[_c('span',{staticStyle:{"font-family":"宋体"}},[_c('span',{staticStyle:{"font-variant-numeric":"normal","font-variant-east-asian":"normal","font-weight":"normal","font-stretch":"normal","font-size":"9px","line-height":"normal","font-family":"'Times New Roman'"}},[_vm._v("  ")])])]),_c('strong',[_c('span',{staticStyle:{"font-family":"宋体","font-weight":"bolder"}},[_vm._v("第二条 您绩效费的结算及支付方式")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-left":"35px","line-height":"29px","margin-top":"10px"}},[_c('span',{staticStyle:{"font-family":"宋体"}},[_vm._v("1、根据本公司与其企业客户之间签署的《共享经济智能综合服务协议》的规定，本公司应就其向企\n业客户提供的共享经济综合服务收取服务费，其中，服务费金额中涵盖最终对应到您基于其自由职业者\n活动而取得的费用金额（简称“绩效费”），本公司将完税后金额支付予您。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-left":"35px","line-height":"29px","margin-top":"10px"}},[_c('span',{staticStyle:{"font-family":"宋体"}},[_vm._v("2、关于绩效费具体结算及支付方式，参见附件一。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-left":"35px","line-height":"29px","margin-top":"10px"}},[_c('span',{staticStyle:{"font-family":"宋体"}},[_vm._v("3、您应自行负担就其取得的绩效费需缴纳的各项税款及行政收费（如有）。本公司在向您支付绩效费时，有权按照有关税收政策规定及本公司主管税务机关的要求，向其代征个人所得税等税款及行政收费（如有），该等款项将从本公司企业客户所支付的绩效费中予以扣减。应本公司要求，您应提供与税款申报缴纳相关的协助及信息。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-left":"35px","line-height":"29px","margin-top":"30px"}},[_c('strong',[_c('span',{staticStyle:{"font-family":"宋体"}},[_c('span',{staticStyle:{"font-variant-numeric":"normal","font-variant-east-asian":"normal","font-weight":"normal","font-stretch":"normal","font-size":"9px","line-height":"normal","font-family":"'Times New Roman'"}},[_vm._v("  ")])])]),_c('strong',[_c('span',{staticStyle:{"font-family":"宋体","font-weight":"bolder"}},[_vm._v("第三条 本公司的权利与义务")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-left":"35px","line-height":"29px","margin-top":"10px"}},[_c('span',{staticStyle:{"font-family":"宋体"}},[_vm._v("1、本公司有权就您提供的生产经营活动制定相应活动标准，您应当遵守前述活动标准，本公司应敦\n促您不得违背相关法律法规，且本公司对您违反法律法规的行为不承担任何法律责任。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-left":"35px","line-height":"29px","margin-top":"10px"}},[_c('span',{staticStyle:{"font-family":"宋体"}},[_vm._v("2、本公司在向您支付其绩效费时，应当按照有关税收政策规定及本公司主管税务机关的要求，向您\n代征个人所得税等各项税款及行政收费（如有）。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-left":"35px","line-height":"29px","margin-top":"10px"}},[_c('span',{staticStyle:{"font-family":"宋体"}},[_vm._v("3、本公司为使您满足企业客户业务需求而向您提供服务，该等服务并不当然导致甲乙双方构成任何\n劳动/劳务合同关系或其他类似劳动法律关系，本公司对您因从事生产经营活动与任一方或第三人所产生\n的争议不承担任何法律责任。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-left":"35px","line-height":"29px","margin-top":"10px"}},[_c('span',{staticStyle:{"font-family":"宋体"}},[_vm._v("4、本公司发现您违反本协议第四条第 4 款之规定的，本公司有权中止本协议，并将您的违法行为自\n发现之时 24 小时之内向本公司主管税务机关报告。您应自行承担由此产生的税务相关的处罚责任，包括\n但不限于根据税务机关的要求补缴相应的税款、滞纳金等。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-left":"35px","line-height":"29px","margin-top":"10px"}},[_c('span',{staticStyle:{"font-family":"宋体"}},[_vm._v("5、本公司承诺对您所披露的个人隐私信息进行保密。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-left":"35px","line-height":"29px","margin-top":"10px"}},[_c('span',{staticStyle:{"font-family":"宋体"}},[_vm._v("6、本公司可以留存为实现本次合作内容从您处获取的您个人信息。但是，未经您授权，本公司不得\n将从您个人信息披露给任何第三方。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-left":"35px","line-height":"29px","margin-top":"10px"}},[_c('span',{staticStyle:{"font-family":"宋体"}},[_vm._v("7、本公司承诺将按照相关法律法规的要求，对从您处获得的您个人信息履行安全保护义务，保障网\n络免受干扰、破坏或未经授权的访问，防止网络数据泄露或被窃取、篡改。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-left":"35px","line-height":"29px","margin-top":"30px"}},[_c('strong',[_c('span',{staticStyle:{"font-family":"宋体"}},[_c('span',{staticStyle:{"font-variant-numeric":"normal","font-variant-east-asian":"normal","font-weight":"normal","font-stretch":"normal","font-size":"9px","line-height":"normal","font-family":"'Times New Roman'"}},[_vm._v("  ")])])]),_c('strong',[_c('span',{staticStyle:{"font-family":"宋体","font-weight":"bolder"}},[_vm._v("第四条 您的权利与义务")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-left":"35px","line-height":"29px","margin-top":"10px"}},[_c('span',{staticStyle:{"font-family":"宋体"}},[_vm._v("1、您有权且应当拒绝违反法律法规的活动。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-left":"35px","line-height":"29px","margin-top":"10px"}},[_c('span',{staticStyle:{"font-family":"宋体"}},[_vm._v("2、您保证向本公司提供的信息均是真实的、有效的、合法的，如信息变更（例如，不再是自由职业者身份时）应当及时通知本公司，与此有关的全部法律责任由您负责。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-left":"35px","line-height":"29px","margin-top":"10px"}},[_c('span',{staticStyle:{"font-family":"宋体"}},[_vm._v("3、您不得从事违反法律及行政法规等行为，如洗钱、偷税漏税及其他本公司认为不得使用您服务的行为等。\n")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-left":"35px","line-height":"29px","margin-top":"10px"}},[_c('span',{staticStyle:{"font-family":"宋体"}},[_vm._v("4、您承诺与企业客户不具有下列关系：")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-left":"35px","line-height":"29px","margin-top":"10px"}},[_c('span',{staticStyle:{"font-family":"宋体"}},[_vm._v("4.1 与企业客户具有劳动/劳务合同关系、或其他类似的劳动人事法律关系；")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-left":"35px","line-height":"29px","margin-top":"10px"}},[_c('span',{staticStyle:{"font-family":"宋体"}},[_vm._v("4.2 与企业客户的关联企业具有劳动/劳务合同关系、或其他类似的劳动人事法律关系；")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-left":"35px","line-height":"29px","margin-top":"10px"}},[_c('span',{staticStyle:{"font-family":"宋体"}},[_vm._v("4.3 为企业客户及其关联企业的法定代表人、董事、监事、股东；")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-left":"35px","line-height":"29px","margin-top":"10px"}},[_c('span',{staticStyle:{"font-family":"宋体"}},[_vm._v("4.4 其他不适用于本协议第一条第 4 款本公司代征范围之规定的人员。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-left":"35px","line-height":"29px","margin-top":"10px"}},[_c('span',{staticStyle:{"font-family":"宋体"}},[_vm._v("如本条规定的人员以自由职业者身份从事生产经营行为的，经其提供相关证明文件后其生产经营所\n得可由本公司按照本协议向其代征个人所得税。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-left":"35px","line-height":"29px","margin-top":"10px"}},[_c('span',{staticStyle:{"font-family":"宋体"}},[_vm._v("5、为实现本协议合作之目的，您同意本公司及其企业客户收集您的个人信息，包括但不限于您姓名、身份证号、收款账户信息、接单数量及费用等。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-left":"35px","line-height":"29px","margin-top":"10px"}},[_c('span',{staticStyle:{"font-family":"宋体"}},[_vm._v("6、您发现本公司违反法律、行政法规的规定或者双方的约定收集、使用其个人信息的，有权要求其\n删除其个人信息；发现本公司收集、存储的其个人信息有错误的，有权要求其予以更正。本公司应当采\n取措施予以删除或者更正。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-left":"35px","line-height":"29px","margin-top":"10px"}},[_c('span',{staticStyle:{"font-family":"宋体"}},[_vm._v("7、您保证活动过程中遵守国家法律法规，因您个人原因造成其个人或任何第三方损害的责任，由您\n承担全部法律责任。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-left":"35px","line-height":"29px","margin-top":"10px"}},[_c('span',{staticStyle:{"font-family":"宋体"}},[_vm._v("8、您确认，其具有完全民事权利能力及完全民事行为能力，并满足所有履行本协议所需的法定条件\n或约定条件。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-left":"35px","line-height":"29px","margin-top":"10px"}},[_c('span',{staticStyle:{"font-family":"宋体"}},[_vm._v("9、您确认，其知晓最终支付予您的绩效费结算方式，并有权通过本公司向企业客户要求支付绩效\n费。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-left":"35px","line-height":"29px","margin-top":"10px"}},[_c('span',{staticStyle:{"font-family":"宋体"}},[_vm._v("10、您可自行在企业客户处查询绩效费的计算方式、支付途径等信息，如有异议，可直接与企业客\n户沟通，本公司视情况需要可提供相关协助。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-left":"35px","line-height":"29px","margin-top":"10px"}},[_c('span',{staticStyle:{"font-family":"宋体"}},[_vm._v("11、您应当本着本公司利益最大化的原则，勤勉履行本协议，维护本公司形象，不得损害本公司的\n合法权益。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-left":"35px","line-height":"29px","margin-top":"10px"}},[_c('span',{staticStyle:{"font-family":"宋体"}},[_vm._v("12、本公司没有为您购买任何保险的法律义务，您自行购买商业保险的，其投保、出险及理赔事宜\n均与本公司无关。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-left":"35px","line-height":"29px","margin-top":"10px"}},[_c('span',{staticStyle:{"font-family":"宋体"}},[_vm._v("13、您应排他性地使用于企业客户处获得的唯一身份标识，因您泄露或提供给他人使用该等身份标\n识而造成的一切后果，由您自行承担，与本公司无关。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-left":"35px","line-height":"29px","margin-top":"10px"}},[_c('span',{staticStyle:{"font-family":"宋体"}},[_vm._v("14、您应自备提供生产经营活动所需要的设备设施、自行承担提供生产经营活动的必要费用。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-left":"35px","line-height":"29px","margin-top":"30px"}},[_c('strong',[_c('span',{staticStyle:{"font-family":"宋体"}},[_c('span',{staticStyle:{"font-variant-numeric":"normal","font-variant-east-asian":"normal","font-weight":"normal","font-stretch":"normal","font-size":"9px","line-height":"normal","font-family":"'Times New Roman'"}},[_vm._v("  ")])])]),_c('strong',[_c('span',{staticStyle:{"font-family":"宋体","font-weight":"bolder"}},[_vm._v("第五条 违约责任")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-left":"35px","line-height":"29px","margin-top":"10px"}},[_c('span',{staticStyle:{"font-family":"宋体"}},[_vm._v("14、您应自备提供生产经营活动所需要的设备设施、自行承担提供生产经营活动的必要费用。任何一方违反本协议约定，即构成该方的违约；除本协议另有规定外，违约方应向守约方承担违约\n责任，补偿守约方承受或遭致的所有损失、责任、赔偿金或费用（包括但不限于合理的律师费、诉讼\n费、公证费等）。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-left":"35px","line-height":"29px","margin-top":"30px"}},[_c('strong',[_c('span',{staticStyle:{"font-family":"宋体"}},[_c('span',{staticStyle:{"font-variant-numeric":"normal","font-variant-east-asian":"normal","font-weight":"normal","font-stretch":"normal","font-size":"9px","line-height":"normal","font-family":"'Times New Roman'"}},[_vm._v("  ")])])]),_c('strong',[_c('span',{staticStyle:{"font-family":"宋体","font-weight":"bolder"}},[_vm._v("第六条 声明、承诺与保证")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-left":"35px","line-height":"29px","margin-top":"10px"}},[_c('span',{staticStyle:{"font-family":"宋体"}},[_vm._v("本协议项下各方声明、承诺和保证：本协议任何一方已披露签署和履行本协议所应当向另一方披露\n的全部信息，且披露内容真实、准确、无遗漏。协议各方同时声明和承诺：本协议的签署和履行不与本\n协议任何一方已经签署的协议或需承担的任何义务相冲突，且也不会对本协议任何一方以外的第三方形\n成任何法律和商业上的冲突。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-left":"35px","line-height":"29px","margin-top":"30px"}},[_c('strong',[_c('span',{staticStyle:{"font-family":"宋体"}},[_c('span',{staticStyle:{"font-variant-numeric":"normal","font-variant-east-asian":"normal","font-weight":"normal","font-stretch":"normal","font-size":"9px","line-height":"normal","font-family":"'Times New Roman'"}},[_vm._v("  ")])])]),_c('strong',[_c('span',{staticStyle:{"font-family":"宋体","font-weight":"bolder"}},[_vm._v("第七条 保密责任")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-left":"35px","line-height":"29px","margin-top":"10px"}},[_c('span',{staticStyle:{"font-family":"宋体"}},[_vm._v("1、本协议任何一方应对本协议所涉及的所有内容以及协议各方在执行本协议过程中相关的一切法\n律、商业、合作业务的所有资讯进行保密。未经对方允许，均不得向对方以外的任意第三方披露。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-left":"35px","line-height":"29px","margin-top":"10px"}},[_c('span',{staticStyle:{"font-family":"宋体"}},[_vm._v("2、保密期应为：协议的有效期内及协议终止之后的十年。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-left":"35px","line-height":"29px","margin-top":"30px"}},[_c('strong',[_c('span',{staticStyle:{"font-family":"宋体"}},[_c('span',{staticStyle:{"font-variant-numeric":"normal","font-variant-east-asian":"normal","font-weight":"normal","font-stretch":"normal","font-size":"9px","line-height":"normal","font-family":"'Times New Roman'"}},[_vm._v("  ")])])]),_c('strong',[_c('span',{staticStyle:{"font-family":"宋体","font-weight":"bolder"}},[_vm._v("第八条 适用法律、争议及纠纷解决和司法管辖")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-left":"35px","line-height":"29px","margin-top":"10px"}},[_c('span',{staticStyle:{"font-family":"宋体"}},[_vm._v("本协议的订立、执行和解释及争议的解决均应适用中国法律。凡因本协议引起的或与本协议有关的\n任何争议，协议各方应友好协商解决。如不能协商解决，双方一致同意提交至本公司所在地有管辖权的\n人民法院通过诉讼解决。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-left":"35px","line-height":"29px","margin-top":"30px"}},[_c('strong',[_c('span',{staticStyle:{"font-family":"宋体"}},[_c('span',{staticStyle:{"font-variant-numeric":"normal","font-variant-east-asian":"normal","font-weight":"normal","font-stretch":"normal","font-size":"9px","line-height":"normal","font-family":"'Times New Roman'"}},[_vm._v("  ")])])]),_c('strong',[_c('span',{staticStyle:{"font-family":"宋体","font-weight":"bolder"}},[_vm._v("第九条 补充协议及附件")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-left":"35px","line-height":"29px","margin-top":"10px"}},[_c('span',{staticStyle:{"font-family":"宋体"}},[_vm._v("双方同意，在本协议签订后，如另有补充协议，该等补充协议及附件构成本协议不可分割的组成部分，具有同等法律效力。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-left":"35px","line-height":"29px","margin-top":"30px"}},[_c('strong',[_c('span',{staticStyle:{"font-family":"宋体"}},[_c('span',{staticStyle:{"font-variant-numeric":"normal","font-variant-east-asian":"normal","font-weight":"normal","font-stretch":"normal","font-size":"9px","line-height":"normal","font-family":"'Times New Roman'"}},[_vm._v("  ")])])]),_c('strong',[_c('span',{staticStyle:{"font-family":"宋体","font-weight":"bolder"}},[_vm._v("第十条 权利的行使")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-left":"35px","line-height":"29px","margin-top":"10px"}},[_c('span',{staticStyle:{"font-family":"宋体"}},[_vm._v("在本协议的任意一方或双方未能及时行使本协议项下的权利不应被视为放弃该权利，也不影响该方\n在将来行使该权利。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-left":"35px","line-height":"29px","margin-top":"30px"}},[_c('strong',[_c('span',{staticStyle:{"font-family":"宋体"}},[_c('span',{staticStyle:{"font-variant-numeric":"normal","font-variant-east-asian":"normal","font-weight":"normal","font-stretch":"normal","font-size":"9px","line-height":"normal","font-family":"'Times New Roman'"}},[_vm._v("  ")])])]),_c('strong',[_c('span',{staticStyle:{"font-family":"宋体","font-weight":"bolder"}},[_vm._v("第十一条 通知")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-left":"35px","line-height":"29px","margin-top":"10px"}},[_c('span',{staticStyle:{"font-family":"宋体"}},[_vm._v("因本协议的订立和履行，本公司需要向您发出的任何通知、指示、催告、文件扫描件或电子文档或\n者其他任何形式的意思表示，均可通过包括但不限于电子邮件、站内信、系统通知或页面公告等形式中\n的一种或几种发送或接收。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-left":"35px","line-height":"29px","margin-top":"30px"}},[_c('strong',[_c('span',{staticStyle:{"font-family":"宋体"}},[_c('span',{staticStyle:{"font-variant-numeric":"normal","font-variant-east-asian":"normal","font-weight":"normal","font-stretch":"normal","font-size":"9px","line-height":"normal","font-family":"'Times New Roman'"}},[_vm._v("  ")])])]),_c('strong',[_c('span',{staticStyle:{"font-family":"宋体","font-weight":"bolder"}},[_vm._v("第十二条 协议生效")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-left":"35px","line-height":"29px","margin-top":"10px"}},[_c('span',{staticStyle:{"font-family":"宋体"}},[_vm._v("您通过页面勾选或点击确认或以其他方式选择接受本协议，即表示您与本公司达成协议并同意接受\n本协议的全部约定内容以及本协议有关的各项规则，所包含的其他与本协议项下各项规则有关的条款和\n规定。如果您不同意本协议的任意内容，或者无法准确理解本公司对条款的解释，请您停止使用本服\n务。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-left":"35px","line-height":"29px","margin-top":"30px","text-align":"center"}},[_c('strong',[_c('span',{staticStyle:{"font-family":"宋体"}},[_c('span',{staticStyle:{"font-variant-numeric":"normal","font-variant-east-asian":"normal","font-weight":"normal","font-stretch":"normal","font-size":"9px","line-height":"normal","font-family":"'Times New Roman'"}},[_vm._v("  ")])])]),_c('strong',[_c('span',{staticStyle:{"font-family":"宋体","font-weight":"bolder"}},[_vm._v("附件一 绩效费的结算及支付规则")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-left":"35px","line-height":"29px","margin-top":"10px"}},[_c('span',{staticStyle:{"font-family":"宋体"}},[_vm._v("(a) 根据本公司与其企业客户签署的《共享经济智能综合服务协议》的规定，对于您向本公司企业\n客户提供的生产经营活动，本公司企业客户在向本公司结算服务费（简称“服务费”）时，将\n按照业务结算规则计算最终对应到您的绩效费。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-left":"35px","line-height":"29px","margin-top":"10px"}},[_c('span',{staticStyle:{"font-family":"宋体"}},[_vm._v("(b) 本公司在收取服务费后将向您支付绩效费，具体应支付至本公司企业客户提供的提现申请单上\n列示的您收款账户。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-left":"35px","line-height":"29px","margin-top":"10px"}},[_c('span',{staticStyle:{"font-family":"宋体"}},[_vm._v("(c) 本协议对绩效费的结算周期不作明确约定，视具体活动内容及需求，双方可自行协商。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-left":"35px","line-height":"29px","margin-top":"10px"}},[_c('br')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"statement-page"},[_vm._m(0),_vm._v(" "),_vm._m(1),_vm._v(" "),_vm._m(2),_vm._v(" "),_vm._m(3),_vm._v(" "),_vm._m(4),_vm._v(" "),_vm._m(5),_vm._v(" "),_vm._m(6),_vm._v(" "),_vm._m(7),_vm._v(" "),_vm._m(8),_vm._v(" "),_vm._m(9),_vm._v(" "),_vm._m(10),_vm._v(" "),_vm._m(11),_vm._v(" "),_vm._m(12),_vm._v(" "),_vm._m(13),_vm._v(" "),_vm._m(14),_vm._v(" "),_vm._m(15),_vm._v(" "),_vm._m(16),_vm._v(" "),_vm._m(17),_vm._v(" "),_vm._m(18),_vm._v(" "),_vm._m(19),_vm._v(" "),_vm._m(20),_vm._v(" "),_vm._m(21),_vm._v(" "),_vm._m(22),_vm._v(" "),_vm._m(23),_vm._v(" "),_vm._m(24),_vm._v(" "),_vm._m(25),_vm._v(" "),_vm._m(26),_vm._v(" "),_vm._m(27),_vm._v(" "),_vm._m(28),_vm._v(" "),_vm._m(29),_vm._v(" "),_vm._m(30),_vm._v(" "),_vm._m(31),_vm._v(" "),_vm._m(32),_vm._v(" "),_vm._m(33),_vm._v(" "),_vm._m(34),_vm._v(" "),_vm._m(35),_vm._v(" "),_vm._m(36),_vm._v(" "),_vm._m(37),_vm._v(" "),_vm._m(38),_vm._v(" "),_vm._m(39),_vm._v(" "),_vm._m(40),_vm._v(" "),_vm._m(41),_vm._v(" "),_vm._m(42),_vm._v(" "),_vm._m(43),_vm._v(" "),_vm._m(44),_vm._v(" "),_vm._m(45),_vm._v(" "),_vm._m(46),_vm._v(" "),_vm._m(47),_vm._v(" "),_vm._m(48),_vm._v(" "),_vm._m(49),_vm._v(" "),_vm._m(50),_vm._v(" "),_vm._m(51),_vm._v(" "),_vm._m(52),_vm._v(" "),_vm._m(53),_vm._v(" "),_vm._m(54),_vm._v(" "),_vm._m(55),_vm._v(" "),_vm._m(56),_vm._v(" "),_vm._m(57),_vm._v(" "),_vm._m(58),_vm._v(" "),_vm._m(59),_vm._v(" "),_vm._m(60),_vm._v(" "),_vm._m(61),_vm._v(" "),_vm._m(62),_vm._v(" "),_vm._m(63),_vm._v(" "),_vm._m(64),_vm._v(" "),_vm._m(65),_vm._v(" "),_vm._m(66),_vm._v(" "),_vm._m(67),_vm._v(" "),_vm._m(68),_vm._v(" "),_vm._m(69),_vm._v(" "),_vm._m(70),_vm._v(" "),_vm._m(71)])
}]

export { render, staticRenderFns }