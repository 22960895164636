<template>
    <div id="setPayAccount">
        <div class="title-top bg-f">
           提现账号
        </div>
        <div class="mt-5 bg-f p30 cheight clearfix">
            <div class="bankcard bd cursor fl mr-20 mb-20" v-for="item in accountList">
                <p class="card-head">
                    <span class="color-white">{{item.accountName }}</span>
                    <span class="delete-btn">
                        <i class="iconfont icon-delete color-white" @click="deleteAccount(item.id)"></i>
                    </span>
                </p>
                <div class="card-con">
                    <p>持有人:{{item.corporateName }}</p>
                    <p>预留手机号:{{item.mobile }}</p>
                </div>
            </div>
            <div class="bankcard bg-f bd tac cursor fl" @click="dialogvisible=true">
                <i class="el-icon-plus color-b font-50 mt-40 dib"></i>
                <p class="color-9 font-12">提现账号</p>
            </div>
        </div>
        <!--添加银行卡弹窗-->
        <addPayAccount @closedialog="closedialog" :dialogvisible="dialogvisible"></addPayAccount>
    </div>
</template>

<script>
    import { axiosApi, basrPath } from "~/api/api"
    export default {
        data() {
            return {
                dialogvisible: false,
                accountList: [],
            }
        },
        mounted() {
            this.$nextTick(() => {
                document.getElementsByClassName("cheight")[0].style.minHeight = document.documentElement.clientHeight - 406 + "px";

                this.getAccount()
            })
        },
        // filters:{
        //     passFilter (val) {
        //         let str = ''
        //         if (/^[1]([3-9])[0-9]{9}$/.test(val)){
        //             str = val.toString().replaceAll("(\\d{3})\\d{4}(\\d{4})", "$1****$2")
        //         }else{
        //             str = val.toString().replaceAll("(\\w?)(\\w )(\\w)(@\\w \\.[a-z] (\\.[a-z] )?)", "$1****$3$4")
        //             console.log(str);
        //         }
        //         return str;
        //     },
        //     nameFilter(val){
        //         let reg = /^(.).+(.)$/g;
        //         return val.replace(reg, "$1*$2")
        //     }
        // },
        methods: {
            deleteAccount(val){
                this.$confirm('是否确认删除该账户?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    let body = {
                        cashBankId:val
                    }
                    axiosApi("/cbs/pay/bankAccountDelete",body,res=>{
                        this.$message.success('删除成功')
                        this.getAccount()
                    },1,10,err=>{
                        this.$message.error(err.data.message)
                    })
                }).catch()

            },
            getAccount() {
                let body = {
                    userId: sessionStorage.traderId
                }
                axiosApi("/cbs/pay/bankAccountList", body, res => {
                    this.accountList = res.data.data
                },1,10,err=>{
                    this.$message.error(err.data.message)
                })
            },
            closedialog() {
                this.dialogvisible = false
                this.getAccount()
            }
        }
    }
</script>

<style lang="less">
    #setPayAccount {
        .bankcard {
            color: #FFF;
            background-color: #108ee9;
            border-radius: 6px;
            width: 268px;
            height: 148px;
            .card-head{
                position: relative;
                font-size: 16px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                background: url("/src/assets/pay.png") 15px center no-repeat;
                background-size: 35px;
                height: 60px;
                padding:0px 15px 0px 60px;
                border-radius: 6px 6px 0 0;
                &:after{
                    position: absolute;
                    content: "";
                    bottom: 0px;
                    left: 15px;
                    display: inline-block;
                    width: 240px;
                    border-bottom: 1px solid #FFF;
                }
            }
            .card-con{
                padding: 10px 15px;
                p{
                    font-size: 14px;
                    line-height: 36px;
                }
            }
            .unbind {
                display: none;
            }
            &:hover {
                .unbind {
                    display: block;
                }
            }
        }
    }
</style>