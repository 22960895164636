<template>
  <div id="sogospread">
    <div class="title-top bg-f mb-5">
      <div class="clearfix">
        <el-breadcrumb class="fl">
          <el-breadcrumb-item>商户分布</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <div class="main bg-f pb-50">
      <el-form :inline="true" label-width="80px" class="clearfix pl-20 pt-30">
        <div class="fl">
          <el-form-item label="客户地区:">
            <el-cascader
              class="mr-10 fl"
              ref="address"
              :options="areaOptions"
              v-model="selectedOptions"
              :props="areaProps"
              clearable
            ></el-cascader>
          </el-form-item>
          <el-form-item label="商户名称:">
            <el-input
              v-model.trim="storeName"
              placeholder="输入商户名称"
            ></el-input>
          </el-form-item>
        </div>
        <el-button
          type="info"
          class="fl ml-50"
          @click="search"
          icon="el-icon-search"
          >搜 索</el-button
        >
      </el-form>
      <div style="display: flex">
        <div class="mapbox" v-loading="loading">
          <div id="map" :class="{ clicked: isSelected }"></div>
          <div id="mapinfo" :class="{ clicked: isSelected }">
            <div class="top">
              <div class="fir">
                <div class="imgwrap">
                  <img
                    :src="selectdone.avater ? selectdone.avater : defaultImg"
                    alt=""
                  />
                </div>
                <div class="imgstore">{{ selectdone.storeName }}</div>
              </div>
              <div class="sec">公司地址：{{ companyaddress }}</div>
            </div>
            <div class="infobody">
              <div class="stat">
                <div class="fir">
                  <div class="fl">业务统计</div>
                  <div class="tabs fr">
                    <div
                      class="tab"
                      :class="{ active: isActive == index }"
                      v-for="(item, index) in ['本月', '上月', '全部']"
                      :key="item"
                      @click="isActive = index"
                    >
                      {{ item }}
                    </div>
                  </div>
                </div>
                <div class="sec">
                  <div>
                    <div>下单次数</div>
                    <div>{{ business.orderNumCount }}</div>
                  </div>
                  <div>
                    <div>订单完成</div>
                    <div>{{ business.orderCompleteNumCount }}</div>
                  </div>
                  <div>
                    <div>订单总金额</div>
                    <div>{{ business.orderMoneyCount }}</div>
                  </div>
                  <div>
                    <div>拜访次数</div>
                    <div>{{ business.visitNumCount }}</div>
                  </div>
                </div>
              </div>
              <div class="detail">
                <div class="tit">公司详情</div>
                <div class="body">
                  <div class="item">
                    <div>联系姓名：</div>
                    <div>{{ selectdone.linkname }}</div>
                  </div>
                  <div class="item">
                    <div>手机号：</div>
                    <div>{{ selectdone.tel }}</div>
                  </div>
                  <div class="item">
                    <div>门店地址：</div>
                    <div>
                      {{ selectdone.provinceName }}{{ selectdone.cityName
                      }}{{ selectdone.regionName }}{{ selectdone.attr }}
                    </div>
                  </div>
                  <div class="item">
                    <div>营业执照编号：</div>
                    <div>{{ selectdone.licenseNum }}</div>
                  </div>
                  <div class="item">
                    <div>营业执照照片：</div>
                    <div>
                      <img v-if="item" :src="item" :key="'imgA'+index" v-for="(item,index) in selectdone.licenseImg ? selectdone.licenseImg.split(',')
                          : []"
                      />
                      <img v-else src="../../assets/goods.jpg" />
                    </div>
                  </div>
                  <div class="item">
                    <div>门店照片：</div>
                    <div>
                      <img
                        v-if="item"
                        :src="item"
                        :key="'imgB'+index"
                        v-for="(item,index) in selectdone.photos"
                      />
                      <img v-else src="../../assets/goods.jpg" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="width-20 mt-20">
          <div style="background: #f5f7fa">商户分布</div>
          <div>
            <img src="../../assets/jiujiajiu_icon3.png" />商户：{{ num }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { axiosApi, basePath } from "../../api/api";
var map;
var geocoder;
export default {
  data() {
    return {
      mdUrl: require('~/assets/md_icon.png'),
      defaultImg: require("../../assets/goods.jpg"),
      areaOptions: [], //添加地址 城市
      selectedOptions: [], //省市区选择
      storeName: "",
      storelist: [],
      companyaddress: null,
      isActive: 0,
      isSelected: false,
      selectdone: {},
      loading: true,
      areaProps: {
        value: "id",
        label: "text",
        children: "sub",
      },
      num: 0,
    };
  },
  watch: {
    haslng(val) {
      map.clearOverlays();
      var that = this;
      if(val[0]){
        map.centerAndZoom(new BMap.Point(val[0].pos.lng,val[0].pos.lat),12);
      }
      val.forEach(item=>{
         that.makePoint(item.pos.lng,item.pos.lat,{storeName:item.storeName,id:item.id,sellerId:item.sellerId},map,that.mdUrl)
      })
    },
  },
  computed: {
    business() {
      switch (this.isActive) {
        case 0:
          return this.selectdone.thisMonth
            ? JSON.parse(this.selectdone.thisMonth)
            : {};
        case 1:
          return JSON.parse(this.selectdone.lastMonth);
        case 2:
          return JSON.parse(this.selectdone.allMonth);
        default:
          return {};
      }
    },
    haslng() {
      return this.storelist.filter((a) => {
        return a.pos.O > 0;
      });
    },
    postdata() {
      return {
        storeName: this.storeName,
        name: null,
        type: 0,
        sellerId: sessionStorage.getItem("traderId"),
        provinceId:
          this.selectedOptions.length > 0 ? this.selectedOptions[0] : null,
        cityId:
          this.selectedOptions.length > 0 ? this.selectedOptions[1] : null,
        regionId:
          this.selectedOptions.length > 0 ? this.selectedOptions[2] : null,
        isMap: 1,
      };
    },
  },
  methods: {
    querylist(aa) {
      axiosApi("/sales/traderSpread/web/selectList", this.postdata, (res) => {
        if (res.data.data.salesTraderList) {
          this.storelist = res.data.data.salesTraderList.map((a) => {
            return {
              id: a.id,
              buyerGrade: a.buyerGrade,
              storeName: a.storeName,
              sellerId: a.sellerId,
              pos: {
                O: a.latitude,
                M: a.longitude,
                lng: a.longitude,
                lat: a.latitude,
              },
            };
          });
          this.loading = false;
        } else {
          this.storelist = [];
          this.loading = false;
        }
        if (!aa) {
          //级联结构，只需要初始化加载一次
          this.areaOptions = res.data.data.traderAreaList
            ? res.data.data.traderAreaList
            : [];
          this.num = res.data.data.salesTraderList
            ? res.data.data.salesTraderList.length
            : 0;
          this.areaOptions = this.cascaderClear(this.areaOptions);
        }
      });
    },
    search() {
      this.loading = true;
      this.isSelected = false;
      this.querylist("serach");
    },
    makePoint(long, lat, info, mapObject, imgSrc) {
      var that = this
      var point = new BMap.Point(long, lat);
      var marker = new BMap.Marker(point, { icon: this.addMarker(imgSrc) }); // 创建标注
      mapObject.addOverlay(marker);
      var label = new BMap.Label(this.makeBubl(info.storeName), {
        // 创建文本标注
        position: point, // 设置标注的地理位置
        offset: new BMap.Size(-48, -70), // 设置标注的偏移量
      });
      label.setStyle({
        // 设置label的样式
        border: "2px solid #fff",
      });
      marker.addEventListener("click", function (e) {
            var id = info.id;
            var sellerId = info.sellerId;
            that.loading = true;
            axiosApi(
              "/sales/trader/web/selectInfo",
              {
                id: id,
                sellerId: sellerId,
              },
              (res) => {
                // console.log(res.data)
                that.loading = false;
                that.selectdone = res.data.data;
                that.isSelected = true;
                var geoc = new BMap.Geocoder({extensions_town: true}); 
                 geoc.getLocation(new BMap.Point(res.data.data.longitude, res.data.data.latitude), function(rs){
                    var addComp = rs.addressComponents;
                    that.companyaddress = addComp.province + addComp.city +  addComp.district + addComp.town+ addComp.street +  addComp.streetNumber
                }); 
              }
            );
      });
   
      mapObject.addOverlay(label);
      
    },
    addMarker(imgSrc){
        var myIcon = new BMap.Icon(imgSrc, new BMap.Size(28, 40), {    
            // 指定定位位置。   
            // 当标注显示在地图上时，其所指向的地理位置距离图标左上    
            // 角各偏移10像素和25像素。您可以看到在本例中该位置即是   
            // 图标中央下端的尖角位置。    
            anchor: new BMap.Size(14, 40),    
          
            imageSize: new BMap.Size(28, 40)
        }); 

        return myIcon
    },
    // 创建标注
    makeBubl(str) {
      let html =
        '<div style="width:150px;text-align:center;padding:0px 10px;white-space: pre-wrap;border:5px solid #171e39; position:relative;background-color:#171e39;color:#fff">'+
        '<span style="width:0; height:0; font-size:0; overflow:hidden; position:absolute;border-width:10px; border-style:solid dashed dashed; border-color:#171e39 transparent transparent;left:28px; bottom:-20px;"></span>' +
        str +
        "</div>";
      return html;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.querylist();

      // 百度地图
      map = new BMap.Map("map", {
        coordsType: 3, // coordsType指定输入输出的坐标类型，3为gcj02坐标，5为bd0ll坐标，默认为5。
      });

      var geolocation = new BMap.Geolocation();
      geolocation.getCurrentPosition(function (r) {
        // 设置中心点
        map.centerAndZoom(new BMap.Point(r.longitude, r.latitude), 14);
        // 鼠标缩放
        map.enableScrollWheelZoom(true);
      });
      map.addEventListener("zoomend", function () {
        // 缩放事件
        // that.setMarker(this.getZoom())
      });
    });
  },
};
</script>
<style lang="less">
#sogospread {
  .width-20 {
    width: 18%;
    border: 1px solid #eceef6;
    border-bottom: none;
    height: 92px;
    div {
      img {
        width: 10px;
        height: 14px;
        vertical-align: middle;
        margin-right: 5px;
      }
      border-bottom: 1px solid #eceef6;
      height: 45px;
      line-height: 45px;
      padding-left: 20px;
    }
  }
  .mapbox {
    width: 77%;
    height: 500px;
    margin: 0 30px;
    margin-top: 20px;
    box-sizing: border-box;
    position: relative;
    #map {
      position: absolute !important;
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      &.clicked {
        right: 278px;
      }
    }
    #mapinfo {
      overflow-y: scroll;
      position: absolute;
      box-shadow: 0px 0px 5px #ccc;
      top: 0px;
      right: 0px;
      bottom: 0px;
      display: none;
      &.clicked {
        display: block;
        width: 278px;
      }
      .top {
        background: #03a6e8;
        color: #fff;
        box-sizing: border-box;
        padding: 10px;
        .fir {
          width: 200px;
          display: flex;
          i {
            float: left;
            width: 30px;
            height: 30px;
            border: 1px solid #fff;
            border-radius: 15px;
            position: relative;
            margin-right: 10px;
            &:before {
              position: absolute;
              left: 6px;
              top: 4px;
            }
          }
          .imgwrap {
            width: 45px;
            height: 45px;
            margin-right: 10px;
            background-color: #fff;
            img {
              width: 45px;
              height: 45px;
            }
          }
          .imgstore {
            height: 45px;
            flex: 1;
            display: flex;
            align-items: center;
          }
        }
        .sec {
          margin-top: 10px;
          font-size: 12px;
          span {
            &:first-child {
              color: #ff4201;
              margin-right: 10px;
            }
            &:last-child {
            }
          }
        }
      }
      .infobody {
        .stat {
          .fir {
            padding: 20px 10px 5px 10px;
            overflow: hidden;
            color: #666;
            border-bottom: 1px solid #dfdfdf;
            .tabs {
              .tab {
                width: 44px;
                border: 1px solid transparent;
                text-align: center;
                float: left;
                border-radius: 3px;
                &.active {
                  border-color: #03a6e8;
                }
              }
            }
          }
          .sec {
            padding: 10px;
            overflow: hidden;
            & > div {
              width: 23%;
              text-align: center;
              float: left;
              font-size: 12px;
              color: #aaa;
              &:nth-child(3) {
                width: 31%;
              }
            }
          }
        }
        .detail {
          .tit {
            margin-top: 10px;
            padding: 0 10px 5px 10px;
            border-bottom: 1px solid #dfdfdf;
            color: #666;
          }
          .body {
            padding: 10px;
            .item {
              font-size: 13px;
              overflow: hidden;
              color: #aaa;
              margin-top: 4px;
              & > div {
                float: left;
                &:first-child {
                  width: 95px;
                  text-align: right;
                }
                &:last-child {
                  width: 142px;
                  img {
                    width: 60px;
                    height: 60px;
                    margin-right: 10px;
                    &:last-child {
                      margin-right: 0;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>