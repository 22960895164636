<template>
    <div id="foodAdd" class="bg-f">
        <div class=" box-con bdt">
            <!-- title -->
            <div class="clearfix bg-f pt-30">
                <el-form :model='form' label-width='130px' :rules="rules" ref="ruleForm">
                    <div class="fl">
                        <el-form-item label="菜品主图:" label-width='80px' prop='mainFileUrl'>
                            <el-upload v-loading="loading" class="avatar-uploader"
                                       action="/sunrise-gateway/oss/ossUpload"
                                       :show-file-list="false" :on-success="handleAvatarSuccess"
                                       :before-upload="beforeAvatarUpload1">
                                <img v-if='form.mainFileUrl' class='icon-90' :src="form.mainFileUrl">
                                <i v-else class="el-icon-plus avatar-uploader-icon icon-90"></i>
                            </el-upload>
                        </el-form-item>
                    </div>
                    <div class="fl">
                        <el-form-item label="菜品名称:" prop='skuName'>
                            <el-input v-model.trim='form.skuName' style="width: 365px"></el-input>
                        </el-form-item>
                        <div class="clearfix">
                            <div class="fl">
                                <el-form-item label="零售价:" prop='price'>
                                    <el-input size='mini' v-model.trim='form.price'></el-input>
                                    元 （不填写显示时价）
                                </el-form-item>
                            </div>
                        </div>
                        <div>
                            <el-form-item label="单位:" prop="unitKey">
                                <el-select clearable size='mini' v-model="form.unitKey" placeholder="请选择">
                                    <el-option
                                            v-for="item in options"
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </div>
                    </div>
                </el-form>
            </div>

            <!-- tab -->
            <div class="mt-10">
                <div v-if="type==1">
                    <span class="text_title1 cursor" @click="type=0">基本信息</span>
                    <span class="text_title cursor" style="margin-left: -4px" @click="type=1">商品详情</span>
                </div>
                <div v-else>
                    <span class="text_title cursor" @click="type=0">基本信息</span>
                    <span class="text_title1 cursor" style="margin-left: -4px" @click="type=1">商品详情</span>
                </div>
                <hr class="line mt-5"/>
            </div>

            <!-- 基本信息 -->
            <div class="pb-30 mt-50" v-show="type==0">
                <el-form :model='form' label-width='130px' :rules="rules" ref="ruleForm1">
                    <el-form-item label="商品图片:" prop="photos">
						<span class="fl" style="height: 111px">
							<input type="hidden" v-model="form.fileUrls"/>
							<el-upload :class="isDisable?'fileList2':''" action="/sunrise-gateway/oss/ossUpload"
                                       :file-list='fileList' list-type="picture-card"
                                       :on-preview="handlePictureCardPreview" :on-success="handleAvatarPhotoScucess"
                                       :before-upload="beforeAvatarUpload" :on-remove="handleRemove">
								<i class="el-icon-plus"></i>
							</el-upload>
							<el-dialog :visible.sync="dialogVisible" size="tiny">
								<img width="100%" :src="dialogImageUrl" alt="">
							</el-dialog>
						</span>
                    </el-form-item>
                    <el-form-item label="菜品分类:" prop="classifyId">
                        <el-select clearable v-model="form.classifyId" class="fl" placeholder="选择菜品分类">
                            <el-option
                                    v-for="item in optionsClass"
                                    :key="item.id"
                                    :label="item.classifyName"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="规格(多选):" prop="specification" class="mt-40">
                        <div v-if="datas.length!=0" class="clearfix" style='width: 800px;'>
                            <div @click="chooseOne(index)" class="one tac color-6 font-12 fl cursor hover mb-10"
                                 :class='{actived:item.select}' v-for='(item,index) in datas' :key='index'>
                                {{item.specName}}
                            </div>
                        </div>
                        <el-button v-else size="small" type='text'
                                   @click="toSpecificationManage()">设置规格
                        </el-button>
                    </el-form-item>
                </el-form>
            </div>

            <!-- 商品详情 -->
            <div class="bg-f mt-50 mb-30" v-show="type==1">
                <el-form :model='form'>
                    <div class="clearfix">
                        <label class="mr-10 font-14 fl">菜品说明:</label>
                        <ueditor ref="ueeditor" v-model="form.illustrationText"></ueditor>
                    </div>
                </el-form>
            </div>

            <!-- button -->
            <div class=" ml-100 pl-30">
                <el-button type="danger" @click="save(1)">保存</el-button>
                <el-button class="ml-20" @click="save(2)">保存并上架</el-button>
            </div>
        </div>
    </div>
</template>

<script>
    import {axiosApi, basePath} from "../../api/api";

    export default {
        data() {
            return {
                datas: [],
                options: [
                    {value: '份', label: '份'},
                    {value: '只', label: '只'},
                    {value: '碗', label: '碗'},
                    {value: '盒', label: '盒'},
                    {value: '例', label: '例'}
                ],
                optionsClass: [],
                fileList: [],
                form: {
                    mainFileUrl: null,
                    skuName: null,
                    price: null,
                    unitKey: null,
                    fileUrls: [],
                    classifyId: null,
                    specIds: [],
                    specList: [],
                    illustrationText: null,
                },
                loading: false,
                dialogVisible: false,//预览
                dialogImageUrl: null,//图片显示
                isDisable: false,//控制加号显示隐藏
                type: 0,
                rules:
                    {
                        skuName: [{required: true, message: '请输入菜品名称'}, {min: 1, max: 30, message: '菜品名称最多30个字符'}],
                        classifyId: [{required: true, message: '请选择分类'}],
                        unitKey: [{required: true, message: '请选择单位'}],
                        mainFileUrl: [{required: true, message: '请上传菜品主图'}],
                        price: [{message: '请输入正确金额', pattern: /^[0-9]{1,7}([.]{1}[0-9]{0,2}){0,1}$/}],
                    }
                ,
            }
                ;

        },
        computed: {},
        watch: {},
        methods: {
            chooseOne(index) {
                this.datas[index].select = !this.datas[index].select;
            },
            beforeAvatarUpload1(file) {
                this.loading = true;
                const isImage = (file.type.indexOf('jpg') != -1 || file.type.indexOf('jpeg') != -1 || file.type.indexOf('png') != -1 || file.type.indexOf('bmp') != -1);
                const isLt4M = file.size / 1024 / 1024 < 4 && file.size / 1024 / 1024 > 0;
                if (!isImage) {
                    this.loading = false;
                    this.$message.error('上传图片只能是jpg,jpeg,png,bmp格式');
                }
                if (!isLt4M) {
                    this.loading = false;
                    this.$message.error('上传图片大小不能超过 4MB并大于0MB');
                }
                return isImage && isLt4M;
            },
            beforeAvatarUpload(file) {
                const isImage = (file.type.indexOf('jpg') != -1 || file.type.indexOf('jpeg') != -1 || file.type.indexOf('png') != -1 || file.type.indexOf('bmp') != -1);
                const isLt4M = file.size / 1024 / 1024 < 4 && file.size / 1024 / 1024 > 0;
                if (!isImage) {
                    this.$message.error('上传图片只能是jpg,jpeg,png,bmp格式');
                }
                if (!isLt4M) {
                    this.$message.error('上传图片大小不能超过 4MB并大于0MB');
                }
                return isImage && isLt4M;
            },
            handleAvatarSuccess(res) {
                this.loading = false;
                this.form.mainFileUrl = res.data[0];
            },
            handleAvatarPhotoScucess(res, file, fileList) {
                this.form.fileUrls.push(res.data[0])
                //成功的回调
                if (fileList.length >= 6) {
                    this.isDisable = true
                } else {
                    this.isDisable = false
                }
            },
            //预览
            handlePictureCardPreview(file) {
                this.dialogImageUrl = file.url;
                this.dialogVisible = true;
            },
            handleRemove(file, fileList) {//删除
                this.form.fileUrls = [];
                fileList.forEach((item) => {
                    this.form.fileUrls.push(item.url);
                });
                this.$refs.ruleForm1.validateField("fileUrls"); //部分表单字段验证
                if (fileList.length >= 6) {
                    this.isDisable = true
                } else {
                    this.isDisable = false
                }
            },
            getClass() {
                axiosApi('/catering/platform/foodClassify/selectList', {}, (res) => {
                    this.optionsClass = res.data.data.filter(item => {
                        return item.isDisabled == false && item.isDeleted == false;
                    })
                }, this.currentPage, 10)
            },
            toSpecificationManage() {
                this.$router.push({path: 'specificationManage', query: null})
            },
            getSpec() {
                axiosApi('/catering/platform/foodSpec/selectList', {}, (res) => {
                    this.datas = res.data.data.filter(item => {
                        return item.isDisabled == false && item.isDeleted == false;
                    });
                    let _this = this;
                    this.datas.forEach(item => {
                        if (typeof item.select == "undefined") {
                            _this.$set(item, "select", false)
                        }
                    });
                }, this.currentPage, 10)
            },
            getSpecIds() {
                this.form.specIds = [];
                this.datas.forEach(item1 => {
                    if (item1.select) {
                        this.form.specIds.push(item1.id)
                    }
                });
            },
            setSpecIds() {
                this.datas.forEach(item1 => {
                    this.form.specList.forEach(item2 => {
                        if (item1.id == item2.id) {
                            item1.select = true;
                        }
                    });
                });
            },
            save(isUp) {
                this.getSpecIds();
                this.$refs.ruleForm.validate((v) => {
                    if (v) {
                        this.$refs.ruleForm1.validate((v1) => {
                            if (v1) {
                                this.form.illustrationText = this.$refs.ueeditor.getContent();
                                let body = {
                                    skuState: isUp,
                                    skuNo: this.$route.query.skuNo,
                                    skuName: this.form.skuName,
                                    unitKey: this.form.unitKey,
                                    price: this.form.price,
                                    mainFileUrl: this.form.mainFileUrl,
                                    fileUrls: this.form.fileUrls,
                                    illustrationText: this.form.illustrationText,
                                    classifyId: this.form.classifyId,
                                    specIds: this.form.specIds,
                                }
                                this.insert(body) // 添加
                                    .then(res => {
                                        this.$message({
                                            type: 'success',
                                            duration: 1000,
                                            message: "添加菜品成功!",

                                        });
                                        this.$router.push({
                                            path: 'foodManage',
                                            query: {type: this.$route.query.type}
                                        })
                                    })
                                    .catch(res => {
                                        this.$message.error(res.data.message)
                                    })
                            }
                        })
                    }
                })


            },
            insert(body) {// 插入接口
                return new Promise((resolve, reject) => {
                    axiosApi(this.$route.query.skuNo ? '/catering/platform/food/update' : '/catering/platform/food/insert', body, res => {
                        resolve(res)
                    }, 1, 10, res => {
                        reject(res)
                    })
                })
            },
            getFoodDetail() {
                if (this.$route.query.skuNo) {
                    axiosApi('/catering/platform/sku/selectDetails', {
                        skuNo: this.$route.query.skuNo,
                        skuType: 1,
                    }, (res) => {
                        this.form = res.data.data;
                        this.$refs.ueeditor.setContent(this.form.illustrationContent);
                        this.fileList = [];

                        this.form.fileUrls.forEach((item) => {
                            this.fileList.push({url: item});
                        });

                        if (this.fileList.length >= 6) {
                            this.isDisable = true
                        } else {
                            this.isDisable = false
                        }
                        this.setSpecIds();
                    }, this.currentPage, 10)
                }
            },
        }
        ,
        //进入加载
        mounted() {
            this.$nextTick(function () {
                this.getClass();
                this.getSpec();
                this.getFoodDetail();
            })
        }
    };
</script>

<style lang="less">
    #foodAdd {
        .avatar-uploader-icon {
            border: 1px solid #ddd;
            width: 90px;
            height: 90px;
            line-height: 90px;
            font-size: 30px;
        }

        .el-upload-list--picture-card .el-upload-list__item {
            width: 90px;
            height: 90px;
        }

        .el-upload--picture-card {
            width: 90px;
            height: 90px;
            line-height: 100px;
        }

        .bdt {
            border-top: 1px solid #eee;
        }

        .box-con {
            padding: 30px 125px 50px 125px;
        }

        .line {
            color: #EEEEEE
        }

        .text_title1 {
            padding: 5px 15px 5px 15px;
            border: 1px solid #DDDDDD;
        }

        .text_title {
            padding: 5px 15px 5px 15px;
            border: 1px solid #FF4201;
            color: #FF4201;
        }

        .one {
            width: 107px;
            height: 34px;
            line-height: 34px;
            background: #F5F5F5;
            margin-right: 15px;
        }

        .hover:hover {
            width: 105px;
            height: 32px;
            background: rgba(255, 255, 255, 1);
            border-radius: 2px;
            border: 1px solid rgba(255, 66, 1, 1);
            color: #FF4201;
        }

        .actived {
            width: 105px;
            height: 32px;
            background: rgba(255, 255, 255, 1);
            border-radius: 2px;
            border: 1px solid rgba(255, 66, 1, 1);
            color: #FF4201;
        }

        .fileList2 .el-upload--picture-card {
            display: none;
        }
    }
</style>