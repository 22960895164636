<template>
 <div id="freight" class="pb-50">
  <div class="title-top bg-f">运费模板</div>
  <div class="bg-f mt-5 p-30">
   <el-form :model="searchForm" class="clearfix">
    <div class="fl search-ct" style="width: 340px">
     <el-form-item label="模版名称" prop='name'>
      <el-input placeholder="请输入模版名称" v-model.trim='searchForm.name' :maxlength="20"></el-input>
     </el-form-item>
    </div>
    <el-button type="info"  icon="el-icon-search" class="fl ml-50" @click="search">搜 索</el-button>
   </el-form>
   
   <div class="main-first bg-f pb-50">
    <div class="main-first-tl">
     <el-button type="text" @click="addRow">新建运费模版</el-button>
     <span class="color-9">(最多可创建100个)</span>
    </div>
    <!-- 模版模块 -->
    <div class="main-second mt-10">
      <div v-if="fData && fData.length == 0" style="margin-left: 400px;color: #666;margin-top: 50px;">暂无模版数据</div>
      <el-card v-for="(item1,index1) in fData" :key="item1.id" class="mt-20">
        <div slot="header" class="header-wrap">
          <div> <el-tag size="small" type="danger" v-if="item1.isDefault==1" effect="dark">默认</el-tag> {{item1.name}}</div>
          <div class="color-6">最后编辑时间：{{item1.gmtModified | timenyr}}</div>
        </div>
        <div class="repeat-ct-wrap">
        <div class="repeat-ct-tl" style="display: flex;">
          <div style="flex: 1;padding-left: 20px">可配送地区</div>
          <div style="text-align: center;width:140px">首箱(元)</div>
          <div style="text-align: center;width:140px">续箱(元)</div>
          <div style="text-align: center;width:140px">使用范围</div>
          <div style="text-align: center;width:200px">操作</div>
        </div>
        <div class="repeat-ct" style="display: flex;">
          <div class="ct-l" style="flex: 1">
              <div class="ct-l-ct" v-for="(item2, index2) in item1.fareDeliveryModeList" :key="index2" style="display: flex">
                <div class=" l-h-24" style="flex: 1;padding-left: 20px">{{item2.regionText}}</div>
                <div style="text-align: center;width:140px">{{item2.firstPrice}}</div>
                <div style="text-align: center;width:140px">{{item2.cumulationPrice}}</div>
              </div>
          </div>
          <div class="ct-r " style="text-align: center;width:140px">
              {{ item1.businessTypeValue }}
          </div>
          <div class="ct-r " style="text-align: center;width:200px">
              <el-button type="text" @click="editRow(item1)">编辑</el-button>
              <el-button type="text" @click="copyRow(item1)">复制</el-button>
              <el-button type="text" @click="deleteRow(item1,index1)">删除</el-button>
          </div>
        </div>
        </div>
      </el-card>
      <!-- 分页 -->
      <el-pagination class="tar" v-if="fData.length>0" :current-page="currentPage" :page-size='10' :total='total' layout="prev , pager, next, jumper" @current-change="handleCurrentChange">
			</el-pagination>
    </div>

   </div>
  </div>
 </div>
</template>

<script>
import { axiosApi, basePath } from "../../../api/api";
export default {
  data() {
    return {
      searchForm: {
        name: ""
      },
      fData: [],
      currentPage: 1,
      total: 0,
      
    };
  },
  methods: {
    achieveList() {
      axiosApi('/foundation/fare/templateSelectList', {
        traderId: sessionStorage.traderId,
        name: this.searchForm.name
      }, res => {
        if (res.data.data.elements) {
          this.fData = this.resolveValueToText(res.data.data.elements);

          this.currentPage = res.data.data.pageIndex;
          this.total = res.data.data.totalCount;
          //处理删除时候最后一个无数据再去前一页
          if (this.currentPage > 1 && this.fData.length === 0) {
            this.currentPage = this.currentPage - 1;
            this.achieveList();
          }
        } else {
          this.fData = [];
          this.currentPage = 1;
          this.total = 0;
        }
      }, this.currentPage, 10, err => {
        this.fData = [];
        this.currentPage = 1;
        this.total = 0;
      })
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.achieveList();
    },
    search() {
      this.currentPage = 1;
      this.achieveList();
    },
    addRow() {
      if (this.fData.length < 100) {
        let obj = {
          name: "",
          fareDeliveryModeList: [
            {
              region: "000000",
              firstPrice: 0,
              cumulationPrice: 0
            }
          ],
          isDefault: 1
        }
        sessionStorage.setItem('CurFreight', JSON.stringify(obj))
        this.$router.push(basePath + "/freightHandle?type=2");
      } else {
        this.$message({
          type: "info",
          message: "最多可创建100个运费模版"
        });
      }
    },
    editRow(item) {
      sessionStorage.setItem("CurFreight", JSON.stringify(item));
      this.$router.push(basePath + "/freightHandle?type=3&id=" + item.id);
    },
    copyRow(item) {
      // 新建并带这个信息
      sessionStorage.setItem("CurFreight", JSON.stringify(item));
      this.$router.push(basePath + "/freightHandle?type=4");
    },
    deleteRow(item1, index1) {
      if (item1.isDefault == 1) {
        this.$message({
          type: "warning",
          message: "默认模板不可以删除!"
        });
        return false;
      }
      this.$confirm("将删除这条模板, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          axiosApi('/foundation/fare/templateDelete', {
            id: item1.id
          }, res => {
            this.$message({
              type: "success",
              message: "删除成功!"
            });
            this.achieveList()
          }, 1, 10, err => {
            this.$message.error(err.data.message)
          })
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除"
          });
        });
    },
    
  },
  //进入加载
  mounted() {
    this.$nextTick(() => {
      this.currentPage = 1;
      this.achieveList()
    });
  },
  // 缓存请求
  activated(){
    this.$nextTick(() => {
      this.currentPage = 1;
      this.achieveList()
    });
  },
};
</script>

<style lang="less">
#freight {
  .el-form-item__label {
    width: 90px;
  }
  .main-first {
    .main-first-tl {
      text-align: right;
      .el-button {
        width: 90px !important;
      }
    }
    .main-second {
      .el-card__header {
        .header-wrap {
          display: flex;
          & > div {
            padding: 0 17px;
            &:nth-child(1) {
              flex: 1;
              font-weight: bold;
              color: #333;
              padding: 0;
            }
          }
        }
      }
      .el-card__body {
        padding: 0;
      }
      .width-200 {
        width: 200px;
        text-align: center;
      }
      .width-150 {
        width: 150px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .width-437 {
        width: 437px;
        padding-left: 20px;
        box-sizing: border-box;
      }
      .repeat-ct-wrap {
        line-height: 34px;
        color: #666;
        .repeat-ct-tl {
          color: #333;
          display: flex;
          border-bottom: 1px solid #ddd;
        }
        .repeat-ct {
          display: flex;
          .ct-l {
            padding: 5px 0;
            flex: 1;
            border-right: 1px solid #ddd;
            .l-h-24 {
              line-height: 24px;
              margin-top: 5px;
            }
            .ct-l-ct {
              display: flex;
              border-bottom: 1px solid #ddd;
              &:nth-last-child(1) {
                border: 0;
              }
            }
          }
          .ct-r {
            padding: 5px 0 5px 10px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
              justify-content: center;
            .el-button {
              width: 50px;
            }
          }
        }
      }
    }
  }
}
</style>