<template>
	<div id="messageDetail">
		<div class="content" v-loading="loading2" element-loading-text="拼命加载中">
			<p class="msg font-16">商户通知</p>
			<el-form ref="form" :model="info" label-width="auto">
				<el-form-item label="名称：">{{info.title}}</el-form-item>
				<el-form-item label="时间：">{{info.Time}}</el-form-item>
				<el-form-item label="内容：">
					<div v-html="info.content"></div>
				</el-form-item>
				<el-form-item>
					<el-button @click="$router.go(-1)">返回</el-button>
				</el-form-item>
			</el-form>
		</div>
	</div>
</template>

<script>
	import { axiosApi } from "~/api/api"
	import {currencyNoticeDetail} from "~/api/newsPush";
	import { parseTime, computeNumber } from "@/utils";
	import { mapMutations } from "vuex"
	export default {
		data() {
			return {
				info: {},
				loading2: true
			};
		},

		mounted() {
			this.$nextTick(function() {
				this.getInfo();
			})
		},
		methods: {
			getInfo(){
				currencyNoticeDetail({id:this.$route.params.id}).then(res=>{
						res.Time = parseTime(res.pushTime,'{y}-{m}-{d} {h}:{i}')
						this.info = res
						this.loading2 = false;
				}).catch(err=>{

				})
			},
		}
	}
</script>

<style lang="less">
	#messageDetail{
		 .content {
				min-height: 726px;
				border: solid 1px #DDDDDD;
				background-color: #ffffff;
				padding: 30px;
				word-break: break-all;
			}
			.msg {
				font-size: 20px;
				color: #20a0ff;
			}
		}
</style>