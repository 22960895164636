<template>
    <div id="cabinetView" class="">
        <div class="content bg-f pb-50">
            <div class="tab_box ml-30">
                <div class="tab_item" :class="{tab_item_checked: item.type==state}" v-for="(item,index) in tabList"
                     :key="index" @click="checkState(item)">
                    <span>{{item.label}}</span>
                    <span>({{item.bage ? item.bage : 0}})</span>
                </div>
            </div>
            <div class="clearfix search">
                <el-form :inline="true" :model="form" label-width="70px" class="clearfix pl-20 pt-30"
                         style="width: 1000px">
                    <div class="fl width-70p">
                        <el-form-item label="商品名称">
                            <el-input v-model.trim="form.skuName" @keyup.enter.native='achieve(true)'></el-input>
                        </el-form-item>
                        <el-form-item label="商品分类">
                            <el-select clearable @keyup.enter.native='achieve(true)' v-model.trim="form.classifyId"
                                       placeholder="选择商品分类">
                                <el-option
                                        v-for="item in options"
                                        :key="item.classifyId"
                                        :label="item.classifyName"
                                        :value="item.classifyId">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                    <el-button type="info" class="fl" @click='achieve(true)'>查询</el-button>

                </el-form>
                <el-table :data="tableData" v-loading="Loading" class='ml-30 mt-10 table-default mr-30' stripe>
                    <el-table-column prop="1" label="商品名称/编码" width="320">
                        <template slot-scope="scope">
                            <div class="info flex clearfix ">
                                <div class="ml-10">
                                    <img v-if='scope.row.img' :src="scope.row.img"/>
                                    <img v-else src="../../assets/goods.jpg"/>
                                </div>
                                <div class="flex-1 ml-10 mt-10 hidden">
                                    <div class="tal ">{{scope.row.skuName}}</div>
                                    <div class="tal">商品编码:{{scope.row.skuNo}}</div>
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="分类" prop="classifyName"></el-table-column>
                    <el-table-column label="零售价">
                        <template slot-scope="scope">
                            <span v-if="scope.row.unitMsrpPrice==0">-</span>
                            <span v-else>￥{{scope.row.unitMsrpPrice | fmoney}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="促销价">
                        <template slot-scope="scope">
                            <span v-if="scope.row.unitPromotionPrice==0">-</span>
                            <span v-else>￥{{scope.row.unitPromotionPrice | fmoney}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="最低零售价">
                        <template slot-scope="scope">
                            <span v-if="scope.row.unitMinSellingPrice==0">-</span>
                            <span v-else>￥{{scope.row.unitMinSellingPrice | fmoney}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="进店价">
                        <template slot-scope="scope">
                            <span v-if="scope.row.unitDiscountPrice==0">-</span>
                            <span v-else>￥{{scope.row.unitDiscountPrice | fmoney}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="单位" prop="unitsName"></el-table-column>
                    <el-table-column label="操作">
                        <template slot-scope="scope">
                            <el-button v-if="!scope.row.isRecommend" size="small" type='text' style="width: 95px;"
                                       @click.native="setRecommend(scope,1)">设为推荐
                            </el-button>
                            <el-button v-else size="small" type='text' style="width: 95px;"
                                       @click.native="setRecommend(scope,0)">取消推荐
                            </el-button>
                            <el-button v-if="scope.row.skuState==1" size="small" type='text'
                                       @click.native="setUpDown(scope,2)">上架
                            </el-button>
                            <el-button v-else size="small" type='text'
                                       @click.native="setUpDown(scope,1)">下架
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>

                <el-pagination v-if="total>0" class="tar mr-30" :current-page="currentPage" :page-size='10'
                               :total='total'
                               layout="prev , pager, next, jumper" @current-change="handleCurrentChange">
                    <!-- 分页组件 -->
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
    import {axiosApi, basePath} from "../../api/api";

    export default {
        data() {
            return {
                state: 1,
                tabList: [{
                    label: '未上架',
                    type: 1,
                    bage: 0
                }, {
                    label: '已上架',
                    type: 2,
                    bage: 0
                }],
                Loading: true,
                form: { //搜索条件
                    skuName: '',
                    classifyId: '',
                },
                options: [],
                tableData: [], //员工列表
                currentPage: 1,
                total: 0,
            }
        },
        methods: {
            checkState(val) {//切换状态
                this.state = val.type
                this.currentPage = 1
                this.achieve()
            },
            //			分页
            handleCurrentChange(val) {
                this.currentPage = val
                this.achieve()
            },

            getClass() {
                axiosApi('/catering/platform/wine/classify', {}, (res) => {
                    this.options = res.data.data
                }, this.currentPage, 10)
            },

            achieve(bool) {
                this.Loading = true;
                if (bool && this.currentPage != 1) {
                    this.currentPage = 1;
                }
                const api = "/catering/platform/wine/selectListPage";
                const body = {
                    skuName: this.form.skuName ? this.form.skuName : null,
                    classifyId: this.form.classifyId ? this.form.classifyId : null,
                    skuState: this.state,
                    skuType: 2,
                }
                axiosApi(api, body, this.callback, this.currentPage, 10)
            },
            callback(res) {
                this.tableData = res.data.data.elements
                this.total = res.data.data.totalCount
                this.Loading = false
            },
            setRecommend(scope, isRecommend) {
                var type = isRecommend ? "推荐" : "取消推荐";

                this.$confirm("将" + type + "该酒水, 是否继续?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                }).then(() => {
                    let url;
                    let body;

                    if (scope.row.id) {
                        url = '/catering/platform/wine/update';
                        body = {
                            id: scope.row.id,
                            isRecommend: isRecommend,
                        };
                    } else {
                        url = '/catering/platform/wine/insert';
                        body = {
                            skuType: scope.row.skuType,
                            b2bSkuNo: scope.row.skuNo,
                            skuState: scope.row.skuState,
                            isRecommend: isRecommend,
                        };
                    }
                    axiosApi(url, body, (res) => {
                        this.$message({
                            type: 'success',
                            duration: 1000,
                            message: type + "成功!",
                        });
                        this.achieve(true);
                        this.getBage();
                    }, this.currentPage, 10, (res) => {
                        this.$message.error(res.data.message)
                    });
                }).catch(() => {
                    this.$message({
                        message: "已取消" + type,
                    });
                });
            },
            setUpDown(scope, skuState) {
                var type = skuState == 1 ? "下架" : "上架";

                this.$confirm("将" + type + "该酒水, 是否继续?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                }).then(() => {
                    let url;
                    let body;

                    if (scope.row.id) {
                        url = '/catering/platform/wine/update';
                        body = {
                            id: scope.row.id,
                            skuState: skuState,
                        };
                    } else {
                        url = '/catering/platform/wine/insert';
                        body = {
                            skuType: scope.row.skuType,
                            b2bSkuNo: scope.row.skuNo,
                            isRecommend: scope.row.isRecommend,
                            skuState: skuState,
                        };
                    }
                    axiosApi(url, body, (res) => {
                        this.$message({
                            type: 'success',
                            duration: 1000,
                            message: type + "成功!",
                        });
                        this.achieve(true);
                        this.getBage();
                    }, this.currentPage, 10, (res) => {
                        this.$message.error(res.data.message)
                    });
                }).catch(() => {
                    this.$message({
                        message: "已取消" + type,
                    });
                });
            },
            getBage() {
                axiosApi("/catering/platform/wine/selectCount", {
                    skuType: 2
                }, (res) => {
                    this.tabList.forEach(item => {
                        if (item.type == 1) {
                            item.bage = res.data.data.offTheShelvesCount;
                        } else if (item.type == 2) {
                            item.bage = res.data.data.onTheShelvesCount;
                        }
                    })
                }, 1, 1);

            }
        },
        //进入加载
        mounted() {
            this.$nextTick(function () {
                this.achieve();
                this.getClass();
                this.getBage();
            })
        }
    }
</script>

<style lang="less">
    #cabinetView {
        .tab_box {
            height: 60px;
            line-height: 60px;
            box-sizing: border-box;
            border-bottom: 1px solid #eee;

            .tab_item {
                display: inline-block;
                width: 114px;
                height: 26px;
                line-height: 26px;
                text-align: center;
                box-sizing: border-box;
                color: #666;
                font-size: 14px;
                cursor: pointer;
            }

            .tab_item:hover {
                color: #FF4201;
            }

            .tab_item_checked {
                border-radius: 2px;
                border: 1px solid #FF4201;
                color: #FF4201;
            }
        }

        .el-form-item__label {
            font-size: 12px;
        }

        .el-table {
            overflow: hidden;
            width: 94%;
            max-width: 100%;
            background-color: #fff;
            border: 1px solid #dfe6ec;
            font-size: 12px;
            color: #1f2d3d;
        }

        .el-button--default {
            margin-right: 0px;
        }

        .el-tabs__header {
            padding-left: 0;
        }

        .title-top {
            border: 0;
        }

        .mt-14 {
            margin-top: 14px;
        }

        .mr-60 {
            margin-right: 60px;
        }

        .black {
            .edit-btn span {
                color: #000000 !important;
            }
        }

        .el-dialog {
            width: 700px;
            top: 10% !important;

            .el-dialog__body {
                padding: 0 0 40px 0px;
                height: 500px;

                .el-input-group__append {
                    background-color: #fff;
                    color: #ff4201;
                    cursor: pointer;
                }
            }

            .main-area {
                display: flex;
                padding: 0 50px;

                .left {
                    width: 240px;
                    height: 400px;
                    padding: 10px;
                    line-height: 36px;

                    & > span {
                        margin: 10px;
                        font-size: 14px;
                        color: #333;
                    }

                    .left-content {
                        padding-top: 20px;
                        border: 1px solid #eee;
                        height: 380px;

                        .el-form {
                            padding: 0 20px;
                        }

                        .el-form-item {
                            margin-bottom: 8px;
                        }

                        .el-checkbox-group {
                            height: 300px;
                            overflow-y: auto;
                            padding: 0 20px;

                            .el-checkbox {
                                display: block;

                                .el-checkbox__label {
                                    max-width: 198px;
                                    overflow: hidden;
                                    white-space: nowrap;
                                    text-overflow: ellipsis;
                                }
                            }

                            .el-checkbox + .el-checkbox {
                                margin-left: 0;
                            }
                        }

                        .no-customer {
                            padding: 0 20px;
                            color: #666;
                        }

                        .select-all-wrap {
                            border-top: 1px solid #eee;
                            padding: 0 20px;
                        }

                        .el-radio-group {
                            width: 198px;
                            padding: 0 20px;
                            height: 336px;
                            overflow-y: auto;

                            .el-radio {
                                display: block;
                                height: 32px;

                                .el-radio__label {
                                    max-width: 198px;
                                    overflow: hidden;
                                    white-space: nowrap;
                                    text-overflow: ellipsis;
                                }
                            }

                            .el-radio + .el-radio {
                                margin-left: 0;
                            }
                        }
                    }
                }

                .center {
                    padding: 0;
                    height: 362px;
                    display: flex;
                    align-items: center;
                    margin-top: 40px;

                    p {
                        border: 1px solid #eee;
                        border-radius: 5px;
                        width: 90px;
                        height: 32px;
                        text-align: center;
                        line-height: 32px;
                    }

                }
            }
        }
    }
</style>