<template>
	<div class="pay-success">
		<top></top>
		<div class="content">
			<p class="content-p">
				<img class="fl mr-10" src="../../assets/register-success.png" alt="支付成功" />
				<div class="fl">
					<div v-if="$route.query.m" class="font-20">恭喜您下单成功，本次账期支付金额    <span class="font-24 color-danger">{{amount | fmoney}}</span>   元</div>
					<div v-else class="font-20">恭喜您下单成功</div>
					<div class="font-16">即将自动跳转到商城首页   {{remainTime}}s...</div>
					<el-button class="mt-50 cust-btn" @click="backTo(1)">返回首页</el-button>
					<el-button class="mt-50 ml-30 cust-btn" @click="backTo(2)">查看订单详情</el-button>
				</div>
			</p>
		</div>
	</div>
</template>

<script>
	import { basePath } from "../../api/api"
	export default{
		data(){
			return {
				remainTime:5,  //倒计时时间
				i:"0", //setInterval的值
				amount:'0', //交易金额
			}
		},
		mounted(){
			this.amount = this.$route.query.m
			this.$nextTick(function(){
				this.autoJump();
			})
		},
		methods:{
			autoJump(){//自动跳转
				this.i = setInterval(()=>{
					this.remainTime--;
					(this.remainTime<=0)&&(this.$router.push(basePath+ '/shop/shopIndex'));//跳转首页
					(this.remainTime<=0)&&(clearTimeout(this.i));//清除setInterval
					if (this.$route.path !== "/customer/paySuccess") { //URl路径改变需要 清除 定时器
						clearTimeout(this.i)
					}
				},1000)
			},
			backTo(v){//点击按钮返回
				clearTimeout(this.i);
				if(v===1){ //返回首页
					this.$router.push(basePath+ '/shop/shopIndex');
				}else if(v===2){ //返回订单详情
					if (this.$route.query.sn) {
						this.$router.push(basePath + '/newValetOrder?orderSn='+this.$route.query.sn+'&type=1&whichPage=2')
					} else {
						this.$router.push(basePath + '/purchaseOrderDetail')
					}

				}
			}
		}
	}
</script>

<style lang="less">
	.pay-success{
		background-color: #fff;
		.content{
			width: 1280px;
			margin: 0 auto;
			padding-bottom: 313px;
			box-sizing: border-box;
			margin-top: 100px;
			img{width: 30px; height: 30px;}
			.content-p{
				width: 500px;
				margin: 0 auto;
				.mt-3{margin-top: 3px;}
			}
		}
		.cust-btn{
			width: 120px;height: 36px;
		}
	}
</style>
