<template>
	<div id="accountSafe">
		<div class="title-top bg-f">
			账户安全
		</div>
		<div class="safe mt-10 bg-f pt-50 pb-20">
			<div class="flex ml-50 mr-50 bd pt-20 pb-20">
				<p class="lh32 ml-20">密码管理</p>
				<el-button type="text" @click="goPage">修改</el-button>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		mounted() {
			this.changeHeight();
		},
		methods: {
			changeHeight() {
				var height = document.documentElement.clientHeight;
				document.getElementsByClassName("safe")[0].style.minHeight = height - 381 + "px";
			},
			goPage(){
				this.$router.push("/customer/register/changePassFirst");
			}
		}
	}
</script>

<style lang="less">
	#accountSafe {
		.flex {
			display: flex;
			justify-content: space-between;
		}
		.lh32 {
			line-height: 32px;
		}
	}
</style>