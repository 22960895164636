<template>
    <div class="">
        <div class="title-tabs bg-f">
            <ul>
                <li>优惠券领取明细</li>
            </ul>
        </div>

        <div class="content bg-f pl-30 pr-30 pt-30">
            <div class="clearfix">
                <el-tabs class="" v-model="activeTab" @tab-click='handletabchange'>
                    <el-tab-pane label="全部" name="0"></el-tab-pane>
                    <el-tab-pane label="未使用" name="1"></el-tab-pane>
                    <el-tab-pane label="已过期" name="2"></el-tab-pane>
                    <el-tab-pane label="已使用" name="3"></el-tab-pane>
                </el-tabs>

                <el-table :data="ticketlist" tooltip-effect="dark" class="ml-30 mt-20 table-default mr-30">
                    <el-table-column label="客户名称" width="300">
                        <template slot-scope="scope">
                            <div>{{$route.query.couponSource != '3'?scope.row.storeName:scope.row.name}}{{scope.row.tel?`/${scope.row.tel}`:''}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column label="领用时间" width="300">
                        <template slot-scope="scope">
                            <div>{{scope.row.gmtCreate | time}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column label="状态">
                        <template slot-scope="scope">
                            <div>{{scope.row.couponState == '1' ? '未使用' : scope.row.couponState == '2' ? '已过期' :scope.row.couponState == '3'?
                                '已使用':''}}
                            </div>
                        </template>
                    </el-table-column>
                </el-table>

                <el-pagination class="tar" v-if='type0total>0'
                               :current-page="currentPage"
                               :page-size='10'
                               :total='type0total'
                               layout="prev , pager, next, jumper"
                               @current-change="handlePageChange">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
    import {axiosApi, basePath} from "../../../api/api"

    export default {
        data() {
            return {
                activeTab: '0',
                currentPage: 1,
                type0total: 0,
                ticketlist: [],
                like: '333'
            }
        },
        computed: {
            cpnsId() {
                return sessionStorage.getItem('couponid')
            },
            postData() {
                return {
                    sellerId: sessionStorage.getItem('traderId'),
                    couponState: this.activeTab == '0'?'':this.activeTab,
                    cpnsId: this.cpnsId
                }
            }
        },
        methods: {
            //优惠券领取明细
            queryticketgetdetail(postdata) {
                const api = '/trader/findSellerCoupon/select'
                let body = Object.assign({}, postdata)
                axiosApi(api, body, (res) => {
                    this.ticketlist = res.data.data ? res.data.data.elements : []
                    this.type0total = res.data.data ? res.data.data.totalCount : 0
                }, this.currentPage, 10)
            },
            handletabchange() {
                // console.log(11)
                if (this.currentPage != 1) {
                    this.currentPage = 1
                } else {
                    this.queryticketgetdetail(this.postData)
                }
            },
            handlePageChange(val) {
                this.currentPage = val
                this.queryticketgetdetail(this.postData)
            }
        },
        mounted() {
            this.$nextTick(() => {
                this.queryticketgetdetail(this.postData)
            })
        }
    }
</script>

<style lang="css">
</style>
