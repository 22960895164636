<template>
    <el-dialog id="addressPicker" :visible.sync="dialogIsShow_" size="middle" @close="handleClose" :title="title" width="1020px">
        <div class="areas-div clearfix">
            <div v-if="nationwideIsShow" class="area-div fl">
                <div class="ct" :class="{active: item1.id == clickedProvince}" v-for="item1 in rawCitiesData2"
                     :key="item1.id" @click="changePro(item1.id)">
                    <el-checkbox :indeterminate="item1.isIndeterminate&&!item1.checked" v-model="item1.checked"
                                 @change="changeAll">
                        {{item1.name}}
                    </el-checkbox>
                    <i class="el-icon-arrow-right fr mt-8"></i>
                </div>
            </div>
            <div class="area-div fl" v-if="area2 && area2[0]&&nationwideIsShow">
                <div v-if="area2 && area2[0]">
                    <div class="ct" :class="{active: item2.id == clickedCity}" v-for="item2 in area2[0].childs"
                         :key="item2.id" @click="changeCity(item2.id)">
                        <el-checkbox :indeterminate="item2.isIndeterminate&&!item2.checked" v-model="item2.checked"
                                     @change="handleCheckedCityChange" :title="item2.name"
                                     :label="item2.name">{{item2.name}}
                        </el-checkbox>
                        <i class="el-icon-arrow-right fr mt-8"></i>
                    </div>
                </div>
            </div>
            <div class="area-div fl" v-if="area3 && area3[0]">
                <div v-if="area3 && area3[0]">
                    <div class="ct" :class="{active: item3.id == clickedArea}" v-for="item3 in area3[0].childs"
                         :key="item3.id" @click="changeArea(item3.id)">
                        <el-checkbox :indeterminate="item3.isIndeterminate&&!item3.checked" v-model="item3.checked"
                                     @change="handleCheckedAreaChange" :title="item3.name"
                                     :label="item3.name">{{item3.name}}
                        </el-checkbox>
                        <i class="el-icon-arrow-right fr mt-8"></i>
                    </div>
                </div>
            </div>
            <div class="slecct-all">
                <el-checkbox v-if="nationwideIsShow" v-model="allCountry" @change="changeAllCountry">全国</el-checkbox>
                <el-checkbox v-else v-model="allCity" @change="changeAllCity">全市</el-checkbox>
            </div>
            <div class="area-div fl" v-if="area4 && area4[0] && !isAllProvince">
                <div v-if="area4 && area4[0]">
                    <div class="ct" v-for="item4 in area4[0].childs" :key="item4.id">
                        <el-checkbox v-model="item4.checked" @change="handleCheckedTownChange" :title="item4.name"
                                     :label="item4.name">{{item4.name}}
                        </el-checkbox>
                    </div>
                </div>
            </div>

            <div class="area-div fr">
                <div class="ct">已选省、市、区</div>
                <div class="ct" v-for="item1 in rawCitiesData2" :key="item1.id">
                    <!-- 省 -->
                    <div v-show="item1.showProvince" @click="item1.show = !item1.show">{{item1.name}}
                        <i class="el-icon-caret-top" v-show="item1.show"></i>
                        <i class="el-icon-caret-bottom" v-show="!item1.show"></i>
                    </div>
                    <div class="ml-10" v-show="item1.show" v-for="item2 in item1.childs" :key="item2.id">
                        <!-- 市 -->
                        <div v-show="item2.showCity" @click="item2.show = !item2.show">
                            <span>{{item2.name}} </span>
                            <i class="el-icon-caret-top" v-show="item2.show"></i>
                            <i class="el-icon-caret-bottom" v-show="!item2.show"></i>
                        </div>
                        <div class="ml-10" v-show="item2.show" v-for="item3 in item2.childs" :key="item3.id">
                            <!-- 区/县 -->
                            <div v-show="item3.showArea" @click="item3.show = !item3.show">
                                <span>{{item3.name}} </span>
                                <i class="el-icon-caret-top" v-show="item3.show"></i>
                                <i class="el-icon-caret-bottom" v-show="!item3.show"></i>
                            </div>
                            <div class="ml-10" v-show="item3.show" v-for="item4 in item3.childs" :key="item4.id">
                                <!-- 街道 镇-->
                                <div v-show="item4.checked">{{item4.name}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div slot="footer" class="dialog-footer">
            <el-button @click="handleClose">取 消</el-button>
            <el-button type="primary" @click="ensureArea">确 定</el-button>
        </div>
    </el-dialog>
</template>

<script>
    import {axiosApi, basePath} from "~/api/api"

    let rawCitiesData2 = JSON.parse(localStorage.getItem('areaList'))||[]
    rawCitiesData2.forEach(item1 => {
        item1.show = false;
        item1.checked = false;
        item1.showProvince = false;
        item1.isIndeterminate = false;
        item1.childs.forEach(item2 => {
            item2.show = false;
            item2.checked = false;
            item2.showCity = false;
            item2.isIndeterminate = false;
            item2.childs.forEach(item3 => {
                item3.checked = false;
                item3.childs = item3.childs || [];
                item3.show = false;
                item3.showArea = false;
                item3.isIndeterminate = false;
                item3.childs.forEach(item4 => {
                    item4.checked = false;
                })
            })
        })
    })

    export default {
        data() {
            return {
                dialogIsShow_: false,
                rawCitiesData2: [],
                clickedProvince: "",
                clickedCity: "",
                clickedArea: "",
                nationwideIsShow: true,
                allCountry: false,
            }
        },
        props: {
            dialogIsShow:{
               type:Boolean
            },
            provinceIdsMap:{
                default:() => {
                    return []
                },
                type:Array
            },
            cityIdsMap:{
                default:() => {
                    return []
                },
                type:Array
            },
            regionIdsMap:{
                default:() => {
                    return []
                },
                type:Array
            },
            title:{
                default:'招标地区',
                type:String
            },
            isAllProvince:{
                default:false,
                type:Boolean
            },

        },
        watch: {
            dialogIsShow(val) {
                this.dialogIsShow_ = val
            }
        },
        computed: {
            allCity() {
                let aa = true;
                this.rawCitiesData2.forEach(item1 => {
                    item1.childs.forEach(item2 => {
                        if (item2.id == this.clickedCity) {
                            item2.childs.forEach(item3 => {
                                if (!item3.checked) {
                                    aa = false
                                    return false
                                }
                            })
                        }
                    })
                })
                return aa;
            },
            //省
            area2() {
               return this.rawCitiesData2.filter(item => {
                    return item.id === this.clickedProvince
                })
            },
            //市
            area3() {
                if (this.area2 && this.area2.length) {
                    return this.area2[0].childs.filter(item => {
                        return item.id === this.clickedCity
                    })
                } else {
                    return []
                }
            },
            
            //区
            area4() {
                if (this.area3 && this.area3.length) {
                    return this.area3[0].childs.filter(item => {
                        return item.id === this.clickedArea
                    })
                } else {
                    return []
                }
            }
        },
        methods: {
            initArea() {
                console.log(this.provinceIdsMap);
                let _this = this;
                setTimeout(() => {
                    _this.rawCitiesData2.forEach(item1 => {
                        item1.show = false;//右边数据预览
                        item1.checked = false;//左侧是否勾选
                        item1.showProvince = false;//右边省
                        item1.isIndeterminate = false;//右边省
                        _this.provinceIdsMap.map(v1 => {
                            if(v1.key == item1.id && v1.id){
                                item1.checked = true;
                            }
                        })
                        item1.childs.forEach(item2 => {
                            item2.show = false;
                            item2.checked = item1.checked ? true : false;
                            if(!item1.checked && item2.checked) {
                                item1.isIndeterminate = true;
                            }
                            item2.showCity = false;
                            item2.isIndeterminate = false;
                            _this.cityIdsMap.map(v2 => {
                                if(v2.key == item2.id && v2.id){
                                    item2.checked = true;
                                }
                            })
                            item2.childs.forEach(item3 => {
                                item3.checked = item2.checked ? true : false;
                                if(!item2.checked && item3.checked) {
                                    item2.isIndeterminate = true;
                                }
                                item3.show = false;
                                item3.showArea = false;
                                item3.isIndeterminate = false;
                                _this.regionIdsMap.map(v3 => {
                                    if(v3.key == item3.id && v3.id){
                                        item3.checked = true;
                                    }
                                })
                                item3.childs.forEach(item4 => {
                                    item4.checked = false
                                })
                            })
                        })
                    })
                },1000)
            },
            changeAllCountry(event) {
                if (event) {
                    this.rawCitiesData2.forEach(item1 => {
                        item1.show = false;
                        item1.checked = true;
                        item1.showProvince = true;
                        item1.isIndeterminate = true;
                        item1.childs.forEach(item2 => {
                            item2.show = false;
                            item2.checked = true;
                            item2.showCity = true;
                            item2.isIndeterminate = true;
                            item2.childs.forEach(item3 => {
                                item3.show = false;
                                item3.checked = true;
                                item3.showArea = true;
                                item3.isIndeterminate = true;
                                item3.childs.map(item4 => {
                                    item4.checked = true
                                })
                            })
                        })
                    })
                } else {
                    this.initArea()
                }
            },
            changeAllCity(event) {
                if (event) {
                    this.rawCitiesData2.forEach(item1 => {
                        item1.show = false;
                        item1.checked = false;
                        item1.showProvince = true;
                        item1.isIndeterminate = true;
                        item1.childs.forEach(item2 => {
                            if (item2.id == this.clickedCity) {
                                item2.show = false;
                                item2.checked = true;
                                item2.showCity = true;
                                item2.isIndeterminate = true;
                                item2.childs.forEach(item3 => {
                                    item3.show = false;
                                    item3.checked = true;
                                    item3.showArea = true;
                                    item3.isIndeterminate = true;
                                    item3.childs.map(item4 => {
                                        item4.checked = true
                                    })
                                })
                            }
                        })
                    })
                } else {
                    this.initArea()
                }
            },
            changeAll(event) {
                // 省
                if (event) {
                    this.rawCitiesData2.map(item1 => {
                        if (item1.id === this.clickedProvince) {
                            item1.showProvince = true;
                            item1.checked = true;
                            item1.isIndeterminate = true;
                            item1.childs.map(item2 => {
                                item2.showCity = true;
                                item2.checked = true;
                                item2.isIndeterminate = true;
                                item2.childs.map(item3 => {
                                    item3.checked = true;
                                    item3.showArea = true;
                                    item3.isIndeterminate = true;
                                    item3.childs.map(item4 => {
                                        item4.checked = true
                                    })
                                })
                            })
                        }
                    })
                } else {
                    this.rawCitiesData2.map(item1 => {
                        if (item1.id === this.clickedProvince) {
                            item1.showProvince = false; //隐藏点击省
                            item1.checked = false;
                            item1.isIndeterminate = false;
                            item1.childs.map(item2 => {
                                item2.showCity = false; //隐藏点击市
                                item2.checked = false;
                                item2.isIndeterminate = false;
                                item2.childs.map(item3 => {
                                    item3.showArea = false;
                                    item3.checked = false;
                                    item3.isIndeterminate = false;
                                    item3.childs.map(item4 => {
                                        item4.checked = false;
                                    })
                                })
                            })
                        }
                    })
                }
            },
            handleCheckedCityChange(event) {
                // 市
                if (event) {
                    //市区选中的个数
                    this.area2[0].showProvince = true;
                    this.area2[0].isIndeterminate = true;
                    this.area3[0].showCity = true;
                    this.area3[0].isIndeterminate = true;

                    //市区选中的个数
                    let flres = this.area2[0].childs.filter(item => {
                        return item.checked == true;
                    })
                    if (this.area2[0].childs.length == flres.length) {
                        this.area2[0].checked = true;
                    }

                    this.area2[0].childs.map(item1 => {
                        if (item1.id === this.clickedCity) {
                            item1.checked = true;
                            item1.isIndeterminate = true;
                            item1.showCity = true;
                            item1.childs.map(item2 => {
                                item2.checked = true;
                                item2.isIndeterminate = true;
                                item2.showArea = true;
                                item2.childs.map(item3 => {
                                    item3.checked = true;
                                    item3.isIndeterminate = true;
                                })
                            })
                        }
                    })
                } else {
                    this.area2[0].checked = false;//省
                    this.area3[0].checked = false;//市
                    this.area3[0].showCity = false;
                    this.area3[0].isIndeterminate = false;

                    let flres = this.area2[0].childs.filter(item => {
                        return item.isIndeterminate == true;
                    })
                    if (flres.length == 0) {
                        this.area2[0].showProvince = false; //隐藏点击省
                        this.area2[0].isIndeterminate = false; //隐藏点击省
                    } else {
                        this.area2[0].showProvince = true; //隐藏点击省
                    }

                    this.area2[0].childs.map(item1 => {
                        if (item1.id === this.clickedCity) {
                            item1.checked = false;
                            item1.isIndeterminate = false;
                            item1.showCity = false;
                            item1.childs.map(item2 => {
                                item2.checked = false;
                                item2.isIndeterminate = false;
                                item2.showArea = false;
                                item2.childs.map(item3 => {
                                    item3.checked = false;
                                })
                            })
                        }
                    })
                }
            },
            handleCheckedAreaChange(event) {
                // 区
                if (event) {
                    this.area2[0].showProvince = true;
                    this.area2[0].isIndeterminate = true;
                    this.area3[0].showCity = true;
                    this.area3[0].isIndeterminate = true;
                    this.area4[0].showArea = true;
                    this.area4[0].isIndeterminate = true;

                    //区选中的个数
                    let flres2 = this.area3[0].childs.filter(item => {
                        return item.checked == true;
                    })
                    if (this.area3[0].childs.length == flres2.length) {
                        this.area3[0].checked = true;
                    }


                    //市区选中的个数
                    let flres = this.area2[0].childs.filter(item => {
                        return item.checked == true;
                    })
                    if (this.area2[0].childs.length == flres.length) {
                        this.area2[0].checked = true;
                    }

                    this.area3[0].childs.map(item1 => {
                        if (item1.id === this.clickedArea) {
                            item1.checked = true;
                            item1.isIndeterminate = true;
                            item1.showCity = true;
                            item1.childs.map(item2 => {
                                item2.checked = true;
                            })
                        }
                    })
                } else {
                    this.area2[0].checked = false;//省
                    this.area3[0].checked = false;//市
                    this.area4[0].checked = false;//区
                    this.area4[0].isIndeterminate = false;//区
                    this.area4[0].showArea = false;//区

                    //区选中的个数
                    let flres2 = this.area3[0].childs.filter(item => {
                        return item.isIndeterminate == true;
                    })
                    if (flres2.length == 0) {
                        this.area3[0].showCity = false; //隐藏点击市
                        this.area3[0].isIndeterminate = false;
                    } else {
                        this.area3[0].showCity = true; //隐藏点击市
                    }

                    let flres = this.area2[0].childs.filter(item => {
                        return item.isIndeterminate == true;
                    })
                    if (flres.length == 0) {
                        this.area2[0].showProvince = false; //隐藏点击省
                        this.area2[0].isIndeterminate = false; //隐藏点击省
                    } else {
                        this.area2[0].showProvince = true; //隐藏点击省
                    }

                    this.area3[0].childs.map(item1 => {
                        if (item1.id === this.clickedArea) {
                            item1.childs.map(item2 => {
                                item2.checked = false;
                            })
                        }
                    })
                }
            },
            handleCheckedTownChange(event) {
                // 街道
                if (event) {
                    this.area2[0].showProvince = true; //隐藏点击省
                    this.area2[0].isIndeterminate = true;
                    this.area3[0].showCity = true; //隐藏点击市
                    this.area3[0].isIndeterminate = true;
                    this.area4[0].showArea = true; //隐藏点击市
                    this.area4[0].isIndeterminate = true;


                    //街道选中的个数
                    let flres3 = this.area4[0].childs.filter(item => {
                        return item.checked == true;
                    })
                    if (this.area4[0].childs.length == flres3.length) {
                        this.area4[0].checked = true;
                    }

                    //区选中的个数
                    let flres2 = this.area3[0].childs.filter(item => {
                        return item.checked == true;
                    })
                    if (this.area3[0].childs.length == flres2.length) {
                        this.area3[0].checked = true;
                    }


                    //市区选中的个数
                    let flres = this.area2[0].childs.filter(item => {
                        return item.checked == true;
                    })
                    if (this.area2[0].childs.length == flres.length) {
                        this.area2[0].checked = true;
                    }
                } else {
                    this.area2[0].checked = false;//省
                    this.area3[0].checked = false;//市
                    this.area4[0].checked = false;//市

                    //预览 区 是否需要显示
                    let flres3 = this.area4[0].childs.filter(item => {
                        return item.checked == true;
                    })
                    if (flres3.length == 0) {
                        this.area4[0].showArea = false;
                        this.area4[0].isIndeterminate = false;
                    } else {
                        this.area4[0].isIndeterminate = true;
                        this.area4[0].showArea = true;
                    }

                    //预览 市 是否需要显示
                    let flres2 = this.area3[0].childs.filter(item => {
                        return item.isIndeterminate == true;
                    });
                    if (flres2.length == 0) {
                        this.area3[0].showCity = false; //隐藏点击市
                        this.area3[0].isIndeterminate = false; //隐藏点击市
                    } else {
                        this.area3[0].showCity = true; //隐藏点击市
                    }


                    //预览 省 是否需要显示
                    let flres = this.area2[0].childs.filter(item => {
                        return item.isIndeterminate == true;
                    });
                    if (flres.length == 0) {
                        this.area2[0].showProvince = false; //隐藏点击省
                        this.area2[0].isIndeterminate = false; //隐藏点击省
                    } else {
                        this.area2[0].showProvince = true; //隐藏点击省
                    }
                }
            },
            changePro(val) {
                this.clickedProvince = val;
                // console.log(val,'Province');
                
            },
            changeCity(val) {
                this.clickedCity = val
                //  console.log(val,'City');
            },
            changeArea(val) {
                if (window.event.target.nodeName == "INPUT") {
                    return false;
                }
                this.clickedArea = val
                this.pushTown()
            },
            pushTown() {
                if (this.area4[0].childs.length == 0) {
                    let body = {
                        parentId: this.clickedArea
                    }
                    this.getTown(body) // 添加
                        .then(res => {
                            res.data.data.forEach(item => {
                                this.area4[0].childs.push({
                                    "label": item.districtName,
                                    "value": item.id,
                                    "checked": this.area4[0].checked
                                })
                            })
                        })
                        .catch(res => {
                            this.$message.error(res.data.message)
                        })
                }
            },
            handleClose() {
                this.$emit("closeDialog")
            },
            ensureArea() {
                let regionArr = [];
                if (this.allCountry) {
                    regionArr.push(0)
                } else {
                    this.rawCitiesData2.map(item1 => {
                        if (item1.checked) {
                            regionArr.push(parseInt(item1.id))
                            return;
                        } else {
                            item1.childs.map(item2 => {
                                if (item2.checked) {
                                    regionArr.push(parseInt(item2.id))
                                    return false;
                                } else {
                                    item2.childs.map(item3 => {
                                        if (item3.checked) {
                                            regionArr.push(parseInt(item3.id))
                                        } else {
                                            item3.childs.map(item4 => {
                                                if (item4.checked) {
                                                    regionArr.push(item4.id);
                                                }
                                            })
                                        }
                                    })
                                }
                            })
                        }
                    })
                }
                this.$emit("closeDialog", regionArr);
                this.$emit("selectedCity",this.rawCitiesData2)
            },
            getTown(body) {// 新建套餐接口
                return new Promise((resolve, reject) => {
                    axiosApi('/unlogin/trader/district/selectStreetInfoList', body, res => {
                        resolve(res)
                    }, 1, 10, res => {
                        reject(res)
                    })
                })
            },
            setAreas() {
                if (sessionStorage.getItem('headCompany') == "false" ) {//子公司要过滤其他省

                    let provinceId = sessionStorage.getItem('provinceId');
                    let cityId = sessionStorage.getItem('cityId');
                    console.log(provinceId,cityId);
                    let area;
                    if(this.isAllProvince){
                        area = rawCitiesData2
                    }else{
                        area  = rawCitiesData2.filter(item => {
                            return item.id == provinceId
                        });
                    }
                    this.rawCitiesData2 = area;
                    let aa = true;
                    let arr = this.rawCitiesData2;
                    if (arr.length == 1) { //只有一个省的时候,就不是全国
                        return false;
                    }
                    for (let i = 0; i < arr.length; i++) {
                        if (arr[i].checked == false) {
                            aa = false;
                            return false;
                        }
                    }
                    this.allCountry = aa
                    if(this.isAllProvince){
                        this.clickedProvince = this.rawCitiesData2[0].id;
                        this.clickedCity = this.rawCitiesData2[0].childs[0].id;
                        area[0].childs = area[0].childs.filter(item => {
                            return item.id == this.clickedCity
                        });
                    }else{
                        this.clickedCity = cityId;
                        this.clickedProvince = provinceId;
                        area[0].childs = area[0].childs.filter(item => {
                            return item.id == cityId
                        });
                    }
                    this.nationwideIsShow = this.isAllProvince;
                    
                } else {
                    this.nationwideIsShow = true;
                    this.rawCitiesData2 = rawCitiesData2;
                    let aa = true;
                    let arr = this.rawCitiesData2;
                    if (arr.length == 1) { //只有一个省的时候,就不是全国
                        return false;
                    }
                    for (let i = 0; i < arr.length; i++) {
                        if (arr[i].checked == false) {
                            aa = false;
                            return false;
                        }
                    }
                    this.allCountry = aa
                }
            }
        },
        //进入加载
        mounted() {
            this.$nextTick(() => {
                this.setAreas();
                this.initArea();
            })
        }
    }
</script>

<style lang="less">
    #addressPicker {
        .el-checkbox__label{
            display: inline-block;
            width: 80px;
        }
        .areas-div {
            width: 1000px;
            background: #fff;
            padding: 10px;
            margin-top: 10px;
            position: relative;

            .slecct-all {
                position: absolute;
                left: 10px;
                bottom: 0;
                width: 145px;
                height: 39px;
                padding: 0 10px;
                line-height: 30px;
                border: 1px solid #ddd;
                border-top: 0;

                label {
                    max-width: 121px;
                }
            }

            .area-div {
                width: 165px;
                height: 350px;
                overflow-y: auto;
                margin-right: 20px;
                border: 1px solid #ddd;

                &:nth-child(1) {
                    height: 320px;
                }

                &:nth-last-child(1) {
                    margin-right: 0;
                    width: 210px;
                }

                .ct {
                    cursor: pointer;
                    padding: 0 10px;
                    line-height: 30px;
                    color: #666;

                    label {
                        max-width: 121px;
                        overflow-x: hidden;
                        /*text-overflow: ellipsis;
                        white-space: nowrap;*/
                    }

                    &.active {
                        color: #20a0ff;
                    }

                    .mt-8 {
                        margin-top: 8px;
                    }
                }
            }
        }
    }
</style>