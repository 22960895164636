import { axiosApi, basePath } from "./api";

// 新增消息
export function currencyNoticeAdd (data={}) {
  return new Promise((resolve, reject) => {
    axiosApi('/trader/currencyNotice/add', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
}

// 删除消息
export function currencyNoticeDel (data={}) {
  return new Promise((resolve, reject) => {
    axiosApi('/trader/currencyNotice/del', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
}

// 消息列表
export function currencyNoticePage (data={}) {
  return new Promise((resolve, reject) => {
    axiosApi('/trader/currencyNotice/page', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
}

// 消息详情
export function currencyNoticeDetail (data={}) {
  return new Promise((resolve, reject) => {
    axiosApi('/trader/currencyNotice/detail', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
}


// 活动通知
export function getActivityNotice (data={}) {
  return new Promise((resolve, reject) => {
    axiosApi('/trader/activityNotice/page ', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
}



// 订单通知
export function getOrderNotice (data={}) {
  return new Promise((resolve, reject) => {
    axiosApi('/trader/orderNotice/page', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
}


