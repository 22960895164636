<template>
    <div id="psSet" class="pb-50">
        <div class="title-top bg-f">设置</div>
        <div class="bg-f mt-5 pb-50">
            <div class="content bg-f pl-30 pr-30 pb-50 pt-50">
                <div class="top">
                    <div class="top-top color-3 font-14 pl-20">
                        基础设置
                    </div>
                    <div class="top-bottom pt-30 pl-50">
                        <div>
                            <span class="color-3 font-12">营业时间:</span>
                            <span class="ml-30">
								<template>
								<el-time-picker ref="ruleForm" format="HH:mm" is-range v-model="form.time1"
                                                placeholder="请选择营业时间">
								</el-time-picker>
							</template>
							</span>
                        </div>
                        <div class="mt-30 clearfix">
                            <span class="color-3 font-12 fl">酒店logo:</span>
                            <span class="fl ml-30">
								<el-upload v-loading="loading" class="avatar-uploader" action="/sunrise-gateway/oss/ossUpload"
                                           :show-file-list="false" :on-success="handleAvatarScucess"
                                           :before-upload="beforeAvatarUpload">
									<img v-if='form.headportraitUrl' class='icon-90' :src="form.headportraitUrl">
									<i v-else class="el-icon-plus avatar-uploader-icon icon-90"></i>
								</el-upload>
							</span>
                        </div>
                    </div>
                </div>
                <div class="top_1 mt-30">
                    <div class="top-top color-3 font-14 pl-20">
                        其它设置
                    </div>
                    <div class="top-bottom pt-30 pl-50">
                        <div>
                            <span class="color-3 font-12">酒桌设置:</span>
                            <span class="color-blue font-12 ml-20 cursor" @click="toTableSet">编辑</span>
                            <span class="color-blue font-12 ml-20 cursor" @click="downloadQR">批量下载二维码</span>
                        </div>
                        <div class="mt-30 clearfix">
                            <span class="color-3 font-12 fl">预约到店时间:</span>
                            <div class="fl">
                                <div class="mb-10 width-640" v-for="(item,index) in form.timeList" :key='index'>
                                    <span class="ml-30">
                                        <template>
                                            <el-time-picker format="HH:mm" is-range v-model="item.time"
                                                            placeholder="请选择预约时间">
                                            </el-time-picker>
                                             <span class="color-blue font-12 ml-30 cursor" @click="addTime"
                                                   v-if="index == form.timeList.length-1 & form.timeList.length<3">添加</span>
                                             <img v-else class="ml-30 icon-24 myIcon" src="../../assets/jicai_icon_del.png"
                                                  @click="delTime(index)"/>
                                        </template>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="mt-15 clearfix">
                            <span class="fl font-14">
                            纸巾:
                            </span>
                            <el-form class="fl ml-30" :model="form" ref="ruleForm" :rules='rules'>
                                <el-form-item v-if="form.button1" prop='price1' label-width="0">
                                    <el-input size="mini" placeholder='请输入价格' v-model.trim='form.price1'></el-input>
                                </el-form-item>
                            </el-form>
                            <span v-if="form.button1" class="fl font-14 ml-10">
                            元/人
                            </span>
                            <el-switch
                                    class="ml-30 fl"
                                    v-model="form.button1"
                                    active-color="#13ce66"
                                    inactive-color="#ff4949">
                            </el-switch>
                        </div>

                        <div class="mt-15 clearfix">
                            <span class="fl font-14">
                            餐具:
                            </span>
                            <el-form class="fl ml-30" :model="form" ref="ruleForm1" :rules='rules'>
                                <el-form-item v-if="form.button2" prop='price2' label-width="0">
                                    <el-input size="mini" placeholder='请输入价格' v-model.trim='form.price2'></el-input>
                                </el-form-item>
                            </el-form>
                            <span v-if="form.button2" class="fl font-14 ml-10">
                            元/人
                            </span>
                            <el-switch
                                    class="ml-30 fl"
                                    v-model="form.button2"
                                    active-color="#13ce66"
                                    inactive-color="#ff4949">
                            </el-switch>
                        </div>
                        <div class="mt-15  clearfix">
                            <span class="fl font-14">
                            醒酒服务:
                            </span>
                            <el-switch
                                    class="ml-25 fl"
                                    v-model="form.button3"
                                    active-color="#13ce66"
                                    inactive-color="#ff4949">
                            </el-switch>
                        </div>
                    </div>
                </div>
                <el-button class="mt-50" type='danger' @click="commit">保存</el-button>
            </div>
        </div>
    </div>
</template>

<script>
    import {axiosApi, basePath, baseURI} from "../../api/api";

    export default {
        data() {
            return {
                loading: false,
                form: {
                    id: null,
                    headportraitUrl: null,
                    time1: [],
                    timeList: [{
                        time: []
                    }],
                    price1: null,
                    price2: null,
                    button1: false,
                    button2: false,
                    button3: false,
                },
                rules:
                    {
                        price1: [{message: '请输入正确价格', pattern: /^[0-9]{1,7}([.]{1}[0-9]{0,2}){0,1}$/}],
                        price2: [{message: '请输入正确价格', pattern: /^[0-9]{1,7}([.]{1}[0-9]{0,2}){0,1}$/}],
                    },
            }
        },
        //进入加载
        mounted() {
            this.$nextTick(function () {
                this.getInfo()
            })
        },
        methods: {
            beforeAvatarUpload(file) {
                this.loading = true;
                const isImage = (file.type.indexOf('jpg') != -1 || file.type.indexOf('jpeg') != -1 || file.type.indexOf('png') != -1 || file.type.indexOf('bmp') != -1);
                const isLt4M = file.size / 1024 / 1024 < 4 && file.size / 1024 / 1024 > 0;
                if (!isImage) {
                    this.loading = false;
                    this.$message.error('上传图片只能是jpg,jpeg,png,bmp格式');
                }
                if (!isLt4M) {
                    this.loading = false;
                    this.$message.error('上传图片大小不能超过 4MB并大于0MB');
                }
                return isImage && isLt4M;
            },

            handleAvatarScucess(res) {
                this.loading = false;
                this.form.headportraitUrl = res.data[0];
            },

            toTableSet() {
                this.$router.push({path: 'tableSet', query: null})
            },

            downloadQR() {
                this.$router.push({path: 'tableSet', query: null})
            },

            addTime() {
                this.form.timeList.push({
                    time: null,
                });
            },

            delTime(index) {
                this.form.timeList.splice(index, 1);
            },

            commit() {
                this.$refs.ruleForm.validate((v) => {
                    if (v) {
                        this.$refs.ruleForm1.validate((v1) => {
                            if (v1) {

                                if (this.verifyTime(this.form.timeList)) {//校验时间间隔小于15分中
                                    return false;
                                }

                                let body = {
                                    id: this.form.id,//id
                                    startBusinessHours: this.form.time1 && this.form.time1[0] ? this.formatDate(this.form.time1[0]) : null,//开始营业时间
                                    endBusinessHours: this.form.time1 && this.form.time1[1] ? this.formatDate(this.form.time1[1]) : null,//结束营业时间
                                    icon: this.form.headportraitUrl,//图片
                                    appointmentTime: this.getAppointmentTime(this.form.timeList),//预约时间
                                    tissuePrice: this.form.button1 ? this.form.price1 : 0,//纸巾
                                    dinnerwarePrice: this.form.button2 ? this.form.price2 : 0,//餐具
                                    isOpenBreathing: this.form.button3 ? 1 : 0,//是否醒酒
                                };
                                this.insert(body) // 添加
                                    .then(res => {
                                        this.$message({
                                            type: 'success',
                                            duration: 1000,
                                            message: "设置成功!",

                                        });
                                    })
                                    .catch(res => {
                                        this.$message.error(res.data.message)
                                    })
                            }
                        })
                    }
                })
            },

            formatDate(myDate) {
                let hour = myDate.getHours();
                let minutes = myDate.getMinutes();
                if (hour < 10) {
                    hour = '0' + hour;
                }
                if (minutes < 10) {
                    minutes = '0' + minutes;
                }
                return hour + ':' + minutes;
            },

            getHours(myDate) {
                let type = myDate.split(":");
                if (type.indexOf(0) == 0) {
                    return type[0].substring(1, 2);
                } else {
                    return type[0]
                }
            },

            getMinutes(myDate) {
                let type = myDate.split(":");
                if (type[1].indexOf(0) == 0) {
                    return type[1].substring(1, 2);
                } else {
                    return type[1]
                }
            },

            verifyTime(timeList) {
                let isError = false;
                timeList.forEach(item => {
                    if (item.time != null && item.time[0] != null) {
                        console.log(item.time[1].getTime(), "item.time[1] ")
                        console.log(item.time[0].getTime(), "item.time[0] ")

                        if (item.time[1].getTime() - item.time[0].getTime() <= 900000) {
                            this.$message({
                                type: 'error',
                                duration: 1500,
                                message: "时间间隔必须大于15分钟",

                            });
                            isError = true;
                            return
                        }
                    }
                });
                if (this.form.time1 != null && this.form.time1[0] != null) {
                    if (this.form.time1[1].getTime() - this.form.time1[0].getTime() <= 900000) {
                        this.$message({
                            type: 'error',
                            duration: 1500,
                            message: "时间间隔必须大于15分钟",

                        });
                        isError = true;
                    }
                }

                return isError
            },


            getAppointmentTime(timeList) {
                let type = '';
                timeList.forEach(item => {
                    if (item.time != null && item.time[0] != null) {
                        type = type + this.formatDate(item.time[0]) + "-" + this.formatDate(item.time[1]) + ",";
                    }
                });

                return type.length == 0 ? null : type.substring(0, type.length - 1);
            },

            setTimeList(appointmentTime) {
                let tiem = appointmentTime.split(",");
                this.form.timeList = [];
                tiem.forEach(item => {
                    let type = item.split("-");
                    let start = new Date(2016, 9, 9, this.getHours(type[0]), this.getMinutes(type[0]));
                    let end = new Date(2016, 9, 9, this.getHours(type[1]), this.getMinutes(type[1]));
                    this.form.timeList.push({time: [start, end]})
                });
            },

            getInfo() {
                axiosApi('/catering/platform/hotel/select', {}, (res) => {
                    this.form.id = res.data.data.id;
                    this.form.price1 = res.data.data.tissuePrice;
                    this.form.price2 = res.data.data.dinnerwarePrice;
                    if (this.form.price1 > 0) {
                        this.form.button1 = true;
                    } else {
                        this.form.button1 = false;
                    }
                    if (this.form.price2 > 0) {
                        this.form.button2 = true;
                    } else {
                        this.form.button2 = false;
                    }
                    this.form.button3 = res.data.data.isOpenBreathing;
                    this.form.headportraitUrl = res.data.data.icon;

                    if (res.data.data.startBusinessHours) {
                        this.form.time1 = [new Date(2016, 9, 9, this.getHours(res.data.data.startBusinessHours), this.getMinutes(res.data.data.startBusinessHours))
                            , new Date(2016, 9, 9, this.getHours(res.data.data.endBusinessHours), this.getMinutes(res.data.data.endBusinessHours))];
                    }
                    if (res.data.data.appointmentTime) {
                        this.setTimeList(res.data.data.appointmentTime);
                    }



                }, this.currentPage, 10)
            },

            insert(body) {// 插入接口
                this.btnLoading = true
                return new Promise((resolve, reject) => {
                    axiosApi('/catering/platform/hotel/update', body, res => {
                        resolve(res)
                        this.btnLoading = false
                    }, 1, 10, res => {
                        reject(res)
                        this.btnLoading = false
                    })
                })
            },
        },
    }
</script>

<style lang="less">
    #psSet {
        .myIcon{
            position: relative;;
            top: 7px;
        }
        .top {
            height: 273px;
            border: 1px solid #DDDDDD;
        }

        .top_1 {
            padding-bottom: 30px;
            border: 1px solid #DDDDDD;
        }

        .top-top {
            width: 100%;
            height: 50px;
            line-height: 50px;
        }

        .top-bottom {
            border-top: 1px solid #DDDDDD;
        }

        .avatar-uploader-icon {
            border: 1px solid #ddd;
            width: 90px;
            height: 90px;
            line-height: 90px;
            font-size: 30px;
        }

        .el-upload--picture-card {
            width: 90px;
            height: 90px;
        }

        .el-upload-list--picture-card .el-upload-list__item {
            width: 90px;
            height: 90px;
        }

        .color_blue {
            color: #20a0ff;
            padding: 5px 25px 5px 25px;
            Border: 1px dashed #DDDDDD;
        }

        .ml-25 {
            margin-left: 25px;
        }
    }
</style>