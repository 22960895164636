<template>
	<div class="hotel-juji-config">
		<div class="title-tabs2 bg-f">
			<ul class="clearfix">
				<div v-for='(tab,index) in dataTabs' class="item" :key="index"  @click='handleTabs(index)'>
					<li :class="tab==clickedTabName?'active':''">
						{{tab}}
						<div :class="tab==clickedTabName?'under-line':''"></div>
					</li>
					<div class="vertical-line"></div>
				</div>
			</ul>
		</div>

		<div id="supercConfig" v-show="clickedTabName === '保证金账户设置'">
			<div class="content bg-f pl-30 pr-30 pt-20">
				<el-form ref="form" :model="form" label-width="160px" :rules="rules">
					<el-form-item label="保证金最低起充额度" prop="cash">
						<el-input type="text" v-model="form.cash" placeholder="请输入保证金" :maxlength="6" @keyup.native="form.cash=form.cash.replace(/\s+/g,'')"></el-input>
						<span class="remark">注：保证金为0时用户注册隐藏保证金选项</span>
					</el-form-item>
					<el-form-item label="支付宝账号" class="mb-40 mt-30" prop="alipayAccount">
						<el-input v-model="form.alipayAccount" placeholder="请输入支付宝账号" :maxlength="30" @keyup.native="form.alipayAccount=form.alipayAccount.replace(/\s+/g,'')"></el-input>
						<span class="remark">用于久集缴纳保证金</span>
					</el-form-item>
					<el-form-item label="支付宝姓名" class="mb-40 mt-30" prop="alipayName">
						<el-input v-model="form.alipayName" placeholder="请输入支付宝姓名" :maxlength="20" @keyup.native="form.alipayName=form.alipayName.replace(/\s+/g,'')"></el-input>
					</el-form-item>
					<div :style="{borderTop : '1px solid #aaa' , width : '650px'}"></div>
					<el-form-item label=" " class="mt-50">
						<el-button class="ml-10" @click="submit" type="danger">保存</el-button>
					</el-form-item>

				</el-form>
			</div>
		</div>

		<div id="setwidthdraw" v-show="clickedTabName === '提现日期设置'">
			<div class="content bg-f">
				<div class="content-box">
					<div class="box-tit">
						<span>当前可提现日期</span>
						<el-button type="text" class="ml-30" @click="setData=true">修改</el-button>
					</div>
					<div class="box-con">
						<span v-if="showData.length==0">暂无设置提现日期</span>
						<span v-if="showData.length>0 && showData.length!=31">每月<span class="num" v-for="(k,index) in showData" :key="index">{{k}}<span>、</span></span>号</span>
						<span v-if="showData.length>0 && showData.length==31">全月</span>
					</div>
				</div>
			</div>
			<!-- 选择提现日期 -->
			<el-dialog class="setData" title="选择提现日期" :visible.sync="setData" :close-on-click-modal="false">
				<div class="set-box">
					<el-checkbox v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
					<div class="mt-20 check">
						<span :class="{'checked':k.checked}" v-for="(k,index) in Datas" :key="index" @click="checkDatas(k)">{{k.value}}</span>
					</div>
				</div>
				<div slot="footer" class="dialog-footer footer mt-30">
					<el-button type="danger" @click='save'>确 定</el-button>
					<el-button  @click='setData=false'>取 消</el-button>
				</div>
			</el-dialog>
		</div>

		<div v-show="clickedTabName === '注册二维码'" class="qart-content bg-f">
			<div class="qart-tl">注册二维码（用于开通酒店久集账号）</div>
			<vue-q-art :config="config" :downloadButton="false"></vue-q-art>
		</div>
		<div v-show="clickedTabName === '团队奖励设置'" class="reward-content bg-f">
			<div>
				<label>团队奖励开关</label>
				<el-switch class="ml100" v-model="awardSwitch" active-text="" inactive-text="" active-color="#13ce66"></el-switch>
			</div>
			<div class="content_reward" v-if="awardSwitch">
				<p>可将久集订单中利润的百分比分配给指定账号</p>
				<div class="reward_input" v-for="(item,index) in dataValue" :key="index">
					<span>角色</span>
					<el-input :disabled="item.readOnly" v-model="item.roleName" :maxlength="10" placeholder="请输入角色或职位"></el-input>
					<span class="ml-10">账号</span>
					<el-input v-if="item.readOnly" :disabled="item.readOnly" v-model="item.loginName"></el-input>
					<el-select v-if="!item.readOnly" v-model="item.supercStoreId" placeholder="请选择久集" clearable>
						<el-option v-for="(g,index) in superCList" :label="g.storeName + g.loginName" :value="g.id" :key="index"></el-option>
					</el-select>
					<span class="ml-10">奖励比例</span>
					<el-input size="mini" type="number" @blur="blurText($event)" :disabled="item.readOnly" v-model="item.awardPercentage" placeholder=""></el-input>&nbsp;%
					<span class="reward_del" @click="removeItem(index)"><i class="el-icon-circle-cross"></i></span>
				</div>
				<div class="reward_input_add">
					<el-button @click="addReward">+ 新增角色</el-button>
				</div>
			</div>
			<div class="footer_save">
				<el-button @click="submitReward" type="danger">保存</el-button>
			</div>
		</div>

		<div v-show="clickedTabName === '协议客户延长收款'" class="reward-content bg-f">
			<div>
				<el-form ref="form2" :model="extendForm" label-width="155px" :rules="extendRules">
					<el-form-item label="协议客户延长收款" prop="extendDays">
						<el-input type="text" v-model="extendForm.extendDays" placeholder="请输入延长日期" :maxlength="3" :max="99"></el-input>
						<span class="remark">天</span>
					</el-form-item>
					<span class="ml-30">销售员所对应的协议客户，将会根据客户的账期天数+延长收款天数变成可结算积分。</span>
				</el-form>
			</div>
			<div class="footer_save">
				<el-button @click="submitExtendDate" type="danger" :loading="btnLoading">保存</el-button>
			</div>
		</div>
	</div>

</template>
<script>
	import { axiosApi } from "../../../api/api";
	import VueQArt from 'vue-qart';
	import imagePath from '../../../assets/title.png'
	var checkMoney = (rule, value, callback) => {
		if (value === '' || value === undefined || value === null) {
			return callback(new Error('保证金不能为空'));
		}
		if (isNaN(Number(value))) {
			callback(new Error('请输入数字值'));
		} else {
			let reg = /^([1-9]\d*|[0]{1})$/
			if(!reg.test(value)){
				callback(new Error('请输入正确的数字值'));
			}else{
				callback();
			}
			callback();
		}
	}
	var zfb = (rule, value, callback) => {
		let reg = /^1\d{10}$/;
		let reg1 = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{1,4})+$/;
		if (reg.test(value)){
			callback();
		} else if(reg1.test(value)){
			callback();
		} else {
			callback(new Error('请输入正确的支付宝账号'));
		}
	}
	export default{
		data(){
			return {
				btnLoading: false,
				dataTabs: [],
				clickedTabName: '保证金账户设置',
				form:{
					cash:null,
					alipayAccount:null,
					// creditScale : '', //授信额度
					userName : '', //户名
					bankCardNo : '', //账号
					alipayName: null,//支付宝姓名
				},
				id:null,//开通商户申请记录id
				traderId:sessionStorage.getItem('traderId'),
				rules:{
					cash:[
						{ required: true, message: '保证金不能为空'},
								{ validator: checkMoney, trigger: 'change' }
							],
							alipayAccount:[
							{ required: true, message: '支付宝账号不能为空'},
								{ validator: zfb, trigger: 'blur' }
					],
					alipayName: [
						{ required: true, message: '支付宝姓名不能为空'},
								{ pattern :  /^[\u4E00-\u9FA5\uf900-\ufa2d·s]{2,20}$/,message: '请输入正确的姓名' }
					]
				},
				config: {
					value: "",
					imagePath: imagePath,
					filter: '',
					size: 165
				},
				downloadButton: false,

				// 提现日期设置
				Datas:[{checked:false,value:'1'},
					{checked:false,value:'2'},
					{checked:false,value:'3'},
					{checked:false,value:'4'},
					{checked:false,value:'5'},
					{checked:false,value:'6'},
					{checked:false,value:'7'},
					{checked:false,value:'8'},
					{checked:false,value:'9'},
					{checked:false,value:'10'},
					{checked:false,value:'11'},
					{checked:false,value:'12'},
					{checked:false,value:'13'},
					{checked:false,value:'14'},
					{checked:false,value:'15'},
					{checked:false,value:'16'},
					{checked:false,value:'17'},
					{checked:false,value:'18'},
					{checked:false,value:'19'},
					{checked:false,value:'20'},
					{checked:false,value:'21'},
					{checked:false,value:'22'},
					{checked:false,value:'23'},
					{checked:false,value:'24'},
					{checked:false,value:'25'},
					{checked:false,value:'26'},
					{checked:false,value:'27'},
					{checked:false,value:'28'},
					{checked:false,value:'29'},
					{checked:false,value:'30'},
					{checked:false,value:'31'}],//日期源
				setData: false,//修改日期弹窗
				checkAll: false,//全选
				checkData:[],//选中的日期
				showData: [],//展示的时间
				awardSwitch: false,//团队奖励开关
				superCList:[],
				dataValue:[],//奖励人员的数组
				extendForm: {
					extendDays: 0
				},
				extendRules: {
					extendDays: [
						{ required: true, message: '延长日期不能为空'},
						{ pattern :  /^(0|[1-9][0-9]?)$/,message: '请输入大于等于0小于100的整数' }
					]
				}

			}
		},
		methods : {
			handleTabs(index) {
				this.clickedTabName = this.dataTabs[index]
			},
			submit(){
				this.$refs.form.validate((valid) => {
					if(valid){
						//支付宝账号字段待改
						let body = {
							traderId:sessionStorage.getItem('traderId'),
							alipayNumber:this.form.alipayAccount,
							bondMoney:this.form.cash,
							id:this.id,
							registUrl:this.config.value,
							// creditScale : this.form.creditScale,
							alipayName: this.form.alipayName
						}
						axiosApi('/superc/hotelSeller/setBond',body,res => {
							this.$message({type:'success',message:'配置成功！',duration:1000})
						},1,10,err=>{
							this.$message({type:'error',message:err.data.message,duration:1000})
						})
					}
				})
			},

			//提现日期设置
			contains(arr, obj) {
				var i = arr.length;
				while (i--) {
					if (arr[i] === obj) {
						return true;
					}
				}
				return false;
			},
			checkDatas(v){
				if(this.contains(this.checkData, v.value)){
					this.checkData.remove(v.value)
				}else{
					this.checkData.push(v.value)
				}
				this.Datas.forEach(items => {
					items.checked = false
					this.checkData.forEach(item=>{
						if(items.value==item){
							items.checked = true
						}
					})
				});
				if(this.Datas.length==this.checkData.length){
					this.checkAll = true;
				}else{
					this.checkAll = false;
				}
			},
			handleCheckAllChange(e){//全选
				this.checkData = []
				if(e){
					this.Datas.forEach(items=>{
						this.checkData.push(items.value)
					})
				}else{
					this.checkData = []
				}
				this.Datas.forEach(items => {
					items.checked = false
					this.checkData.forEach(item=>{
						if(items.value==item){
							items.checked = true
						}
					})
				});
			},
			getSetData(){
				//查询设置的提现日期
				axiosApi('/superc/hotelSeller/selectSetInfo',{
					traderId:sessionStorage.getItem('traderId')
				},res=>{
					res.data.data.withdrawDateNo && ( this.showData = res.data.data.withdrawDateNo.split(",") )
					res.data.data.withdrawDateNo && ( this.checkData = res.data.data.withdrawDateNo.split(",") )
					this.Datas.forEach(items => {
						items.checked = false
						this.checkData.forEach(item=>{
							if(items.value==item){
								items.checked = true
							}
						})
					});
					if(this.Datas.length==this.checkData.length){
						this.checkAll = true;
					}else{
						this.checkAll = false;
					}


					//验证保证金
					this.$refs.form.validateField("cash"); //部分表单字段验证
					//配置信息
					this.form.cash = res.data.data.bondMoney;
					this.form.alipayAccount = res.data.data.alipayNumber;
					this.form.alipayName = res.data.data.alipayName;
					// this.form.creditScale = res.data.data.creditScale !== null ? res.data.data.creditScale * 100 : '';
					//商户开通久集申请记录id
					this.id = res.data.data.id;

					this.extendForm.extendDays = res.data.data.paymentExtendDays
				})
			},
			save(){
				if(!this.checkData.length>0){
					this.$message({
						type: 'info',
						message: '请先选择提现日期',
						duration: 1000
					})
					return false
				}else{
					this.setData = false;
					axiosApi('/superc/hotelSeller/setHotelWithdraw',{
						traderId:sessionStorage.getItem('traderId'),
						withdrawDateList: this.checkData
					},res=>{
						this.getSetData()
					})
				}
			},
			getSupercList(){
				axiosApi('/superc/queryHotelStoreForSeller/select',{},(res)=>{
					this.superCList = res.data.data;
				},1,10,(res)=>{})
			},
			getRewardList(){
				axiosApi('/superc/supercAward/selectList',{},(res)=>{
					this.dataValue = []
					this.awardSwitch = res.data.data.awardSwitch
					if(res.data.data.supercAwardList&&res.data.data.supercAwardList.length>0){
						res.data.data.supercAwardList.forEach(item=>{
							this.dataValue.push({
								id:item.id,
								roleName:item.roleName,
								supercStoreId:item.supercStoreId,
								awardPercentage:item.awardPercentage,
								loginName: item.storeName + item.loginName,
								readOnly:true
							})
						})
					}
				},1,10,(res)=>{})
			},
			addReward(){//新增数据
				if(this.dataValue.length>=10){
                    this.$message({
                      message: '角色过多，请合理分配，控制在10位以内',
					  type: 'warning',
					  duration:2000
                    });
                    return false;
                }
                this.dataValue.push({roleName:'',supercStoreId:'',awardPercentage:'',readOnly:false})
			},
			blurText(e){
				let reg = /^(((\d|[1-9]\d)(\.\d{1,2})?)|100|100.0|100.00)$/
				let boolean = reg.test(e.target.value)
				if(!boolean){
					this.$message.warning('奖励比例请输入0-100内的数字')
					e.target.value=''
				}
			},
			removeItem(index){
				const h = this.$createElement
				this.$msgbox({
					title: '提示信息',
					message: h('p', {style: 'text-align:center'}, '确认删除该条奖励设置？保存后删除操作生效'),
					showCancelButton: true,
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					beforeClose: (action, instance, done) => {
						if(action === 'confirm') {
							this.dataValue.splice(index, 1)
							done()
						} else {
							done()
						}
						delete instance.$slots.default;
					}
				})
			},
			submitReward(){
				var s = 0;//计算奖励比例总和
				var falg = false;//判断是否设置重复手机号
				for (var i=this.dataValue.length-1; i>=0; i--) {
					if(this.dataValue[i].awardPercentage*1<0){
						// this.$message({type:'info',message:'奖励比例不能小于0',duration:2000})
						return false
					}
					s += this.dataValue[i].awardPercentage*1;
				}
				var arr = []
				this.dataValue.forEach(item=>{
					arr.push(item.supercStoreId)
				})
				var nary = arr.sort();
				for(var i=0;i<arr.length;i++){
					if (nary[i]==nary[i+1]){
						this.$message({type:'info',message:'不能设置重复账号',duration:2000})
						return false
					}
				}
				if(s>=100){
					this.$message({type:'info',message:'奖励比例总和不能大于或等于100',duration:2000})
					return false
				}
				const body={
					awardSwitch:this.awardSwitch,
					supercAwardList:this.dataValue
				}
				axiosApi('/superc/supercAward/batchSet',body,(res)=>{
					this.$message({type:'success',message:'配置成功！',duration:2000})
					this.getRewardList()
				},1,10,(res)=>{
					this.$message({type:'info',message:res.data.message,duration:2000})
				})
			},
			submitExtendDate() {
				this.$refs.form2.validate(val => {
					if (val) {
						this.btnLoading = true
						axiosApi('/superc/web/hotelApplyExtendDays/update', {
							traderId: sessionStorage.getItem('traderId'),
							paymentExtendDays: this.extendForm.extendDays
						}, res => {
							this.$message({type:'success',message:'延长收款配置成功！',duration:2000})
							setTimeout(() => {
								this.btnLoading = false
							}, 300)
						})
					}
				})
			}

		},
		created() {
			// 二维码url
			this.config.value = this.PROJECT.href + '/#/hotelRegister?sellerId=' + this.traderId;
		},
		mounted(){
			// '保证金账户设置', '提现日期设置', '注册二维码'
			if (sessionStorage.isAdmin == '1') {
				 this.dataTabs = ['保证金账户设置', '提现日期设置', '注册二维码','团队奖励设置','协议客户延长收款']
			} else {
				if (this.showButton('MARGIN_CONFIG', 130014)) {
					this.dataTabs.push('保证金账户设置')
				}
				if (this.showButton('WITHDRAW_DATE_CONFIG', 130014)){
					this.dataTabs.push('提现日期设置')
				}
				if (this.showButton('QR_CODE', 130014)) {
					this.dataTabs.push('注册二维码')
				}
				// todo 后端加这两项的权限配置
				// if (this.showButton('QR_CODE', 130014)) {
					this.dataTabs.push('团队奖励设置')
				// }
				if (this.showButton('PAYMENT_EXTEND', 130014)) {
					this.dataTabs.push('协议客户延长收款')
				}
			}
			this.clickedTabName = this.dataTabs[0]
			this.getSetData()
			this.getSupercList()
			this.getRewardList()
		},
		components: {
			VueQArt
		}
	}
</script>
<style lang="less">
	#supercConfig{
		.content{
			height: 544px;
			padding-bottom: 50px;
			form{
				.el-form-item{
					.el-form-item__label{
						padding: 0 20px 11px 0;
					}
					.remark{
						margin-left: 25px;
					}
					.el-button--danger.is-plain{
						span{
							color: #ff4201;
						}
					}
					img{
						height: 150px;
						width: 170px;
					}
				}

			}
			.avatar-uploader .el-upload {
			    border: 1px dashed #d9d9d9;
			    border-radius: 6px;
			    cursor: pointer;
			    position: relative;
			    overflow: hidden;
			}
			.avatar-uploader .el-upload:hover {
			    border-color: #20a0ff;
			}
			.avatar-uploader-icon {
			    font-size: 28px;
			    color: #8c939d;
			    width: 178px;
			    height: 178px;
			    line-height: 178px;
			    text-align: center;
			}
			.avatar {
			    width: 178px;
			    height: 178px;
			    display: block;
			}

		}
	}
	#setwidthdraw {
		.content {
			height: 544px;
			padding: 50px 30px 50px 20px;
			.content-box{
				width:100%;
				height: 150px;
				box-sizing: border-box;
				border: 1px solid #DDDDDD;
				.box-tit{
					height: 50px;
					line-height: 50px;
					border-bottom: 1px solid #DDDDDD;
					padding-left: 20px;
				}
				.box-con{
					padding-top: 30px;
          padding-left: 20px;
					color: #666666;
					font-size: 14px;
					.num:last-child{
						span{display:none}
					}
				}
      }
		}
		.setData{
			.el-dialog{
				min-height: 380px;
			}
			.set-box{
				padding-left: 30px;
				padding-right: 50px;
			}
			.check{
				span{
					width: 30px;
					height: 30px;
					line-height: 30px;
					text-align: center;
					display: inline-block;
					border: 1px solid #20a0ff;
					margin: 0px 5px 5px 0;
					&:hover {
						background: #B8EBFF;
					}
					&.checked {
						background: #20a0ff;
						color: #FFFFFF;
					}
				}
			}
		}
	}

	.qart-content {
		min-height: 400px;
		padding: 50px 0 0 100px;
		.qart-tl {
			line-height: 30px;
			margin: 0 0 20px 10px;
			color: #333;
		}
	}
	.reward-content{
		min-height: 300px;
		padding: 30px 50px;
		.ml100{
			margin-left: 150px;
		}
		.content_reward{
			margin-top: 30px;
			margin-left: 30px;
			p{color: #999}
			.reward_input{
				margin-top: 20px;
				.el-input{
					width: 200px;
				}
				.el-input--mini{
					width: 60px;
					vertical-align: middle;
				}
				.reward_del{
					margin-left: 15px;
					i{
						font-size: 16px;
						color: #ff4201
					}
					i:hover{
						cursor: pointer;
					}
				}
			}
			.reward_input_add{
				margin-top: 20px;
				margin-left: 30px;
				.el-button{
					width: 140px;
    				height: 32px;
				}
			}
		}
		.footer_save{
			margin-left: 59px;
			margin-top: 50px;
		}
	}
</style>
