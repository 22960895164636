import {axiosApi, basePath} from '../../api/api.js'
import m from '../../main.js';
import {PROJECT} from '~/config/index.js';
import {bus} from "../../js/extend.js";

const homeSearch = {
    state: {
        classfilShow: null,
        collectStoreBtn: false,
        currentPage: 1,
        showRaceCondition: false,
        sellerId: null,
        isCollection: false,
        shoppingCarNum: 0,
        mloginDialogState: false,
        mloginDialogUrl: '',
        menuList: [],
        menuList1: [],
        mUserProtocolDialogState: false,
        zeroShow:  localStorage.getItem('showZero')==='true'?true:false  // 首页开关是否显示库存为0的商品
    },
    mutations: {
        setloginDialog(state, data) {
            state.mloginDialogState = typeof data === 'object' ? data.type : data;
            if (data.url !== undefined) {
                state.mloginDialogUrl = data.url;
            }
        },
        mouseShow(state) {
            state.classfilShow = true;
        },
        mouseHide(state) {
            state.classfilShow = false;
        },
        collectStoreShow(state) {
            state.collectStoreBtn = true;
        },
        collectStoreHide(state) {
            state.collectStoreBtn = false;
        },
        setUserProtocolDialog(state, data) {
            state.mUserProtocolDialogState = data;
        },
        setZeroShow(state, type) { // 设置库存为0显示状态
            localStorage.setItem('showZero', type); // 为0是否显示
            state.zeroShow = type;
        },
        setUserId(state, payload) {
            state.sellerId = payload.sellerId;
        },
    },
    actions: {
        getUserId({state}, payload) {
            state.sellerId = payload.sellerId;
            state.isCollection = payload.isCollection;
        },
        setShoppingCarNum({state}, payload) {
            if (!payload) {
                axiosApi("/anon/order/cart/count", {operatorType: 1, buyerId: sessionStorage.traderId}, (res) => {
                    state.shoppingCarNum = res.data.data < 100 ? res.data.data : '99+';
                }, 1, 10)
            } else {
                state.shoppingCarNum = payload < 100 ? payload : '99+';
            }
        },
        getMenuList({state, dispatch}, payload) {
            let _this = this
            const api = "/foundation/resource/displaytree";
            const body = {
                loginName: sessionStorage.loginName,
                appId: 2,
                hostPrefix: PROJECT.hostPrefix
            };
            axiosApi(api, body, res => {
                state.menuList1 = res.data.data;
                // console.log(res.data.data,'menulist');
                
                //酒店后台-酒店久集新增数据分组处理
                state.menuList1.forEach((item, index) => {
                    item['sub1'] = []
                    item['sub2'] = []
                    item['sub3'] = []
                    item['sub4'] = []
                    if (item.code === 'IS_OPEN_HOTEL_SUPER_C' && item.sub[0].groupId != null) {
                        item.sub1 = item.sub.filter((i => {
                            return i.groupId === 1
                        }))
                        item.sub2 = item.sub.filter((i => {
                            return i.groupId === 2
                        }))
                    }
                    if (item.code === 'IS_OPEN_TENANTSHOP_SUPERC' && item.sub[0].groupId != null) {
                        item.sub3 = item.sub.filter((i => {
                            return i.groupId === 1
                        }))
                        item.sub4 = item.sub.filter((i => {
                            return i.groupId === 2
                        }))
                    }
                })

                var buttonList = []

                function TreeEach(data) {
                    for (var i = 0; i < data.length; i++) {
                        if (data[i].type == 2 || data[i].id == 210163) {
                            buttonList.push(data[i]);
                            if (!!data[i].sub) buttonList = buttonList.concat(data[i].sub); //合并数组
                            data.splice(i, 1);
                            i--;
                        } else if (!!data[i].sub) {
                            TreeEach(data[i].sub);
                        }
                        // debugger
                    }
                }

                TreeEach(state.menuList1)
                bus.$emit('buttonList', buttonList);
                // console.log(buttonList);
                
                sessionStorage.setItem("buttonList", JSON.stringify(buttonList))
                state.menuList = state.menuList1;
            });
        },
        // getUserInfo({state}, payload) {
        //     axiosApi("/foundation/employee/selectOneByLoginName", payload.body, (res) => {
        //         let a = res.data.data;
        //         if (a.loginSuccessCount == 1 && a.isAdmin == 1 && a.isHotel != 1) {
        //             sessionStorage.setItem('isOne', 1);
        //         }
        //         //console.log(a)
        //         //缓存登录次数，从1开始
        //         // if(m.$cookies.isKey('userName')){
        //         //     m.$cookies.remove('userName')
        //         // }
        //         m.$cookies.set('userName', a.loginName, null, '/')
        //         sessionStorage.setItem('loginName', a.loginName);
        //         sessionStorage.setItem('mobile', a.mobile);
        //         sessionStorage.setItem('traderId', a.traderId); //当前 店铺/供应商 ID
        //         sessionStorage.setItem('id', a.id); //当前用户ID
        //         sessionStorage.setItem('isAdmin', a.isAdmin); //是否是管理员
        //         sessionStorage.setItem('isDepartmentLeader', a.isDepartmentLeader); //是否未部门领导
        //         sessionStorage.setItem('employeeName', a.employeeName); //员工人员
        //         sessionStorage.setItem('departmentPath', a.departmentPath); //部门id列表
        //         sessionStorage.setItem('managerId', a.managerId); //员工id
        //         sessionStorage.setItem('isHotel', a.isHotel); //是否是酒店用户
        //         sessionStorage.setItem('isDealerUser', a.dealerUser); //是否是经销商

        //         if (payload.isLogin) {
        //             if (sessionStorage.getItem('lastUrl')) {
        //                 m.$router.push({
        //                     path: sessionStorage.getItem('lastUrl')
        //                 })
        //                 sessionStorage.removeItem('lastUrl')
        //             } else {
        //                 m.$router.push({
        //                     path: sessionStorage.getItem('isOne') == 1 ? (basePath + '/welcome') : (basePath + "/shop/shopIndex")
        //                     // path: basePath + "/shop/shopIndex"
        //                 })
        //             }
        //         }

        //     }, 0, 0, err => {
        //         m.$alert(err.data.message, '提示信息', {
        //             confirmButtonText: '确定'
        //         })
        //     })
        // },
        // 判断当前用户 是否显示协议弹出框
        // getUserProtocolInfo({state}, data) {
        //     let loginName = window.sessionStorage.getItem('loginName');
        //     if (!loginName) {
        //         // 防止报错
        //         return new Promise(() => {
        //         });
        //     }
        //     return (
        //         new Promise((resove, reject) => {
        //             axiosApi('/trader/isAgreement/select', {}, (res) => {
        //                 let data = res.data.data;
        //                 if (data.isAgreementChg === 0) {
        //                     state.mUserProtocolDialogState = true;
        //                     resove(data);
        //                     /* setTimeout(() => {
        //                         window.mUserProtocolDialogNotify = m.$notify.info({
        //                             title: '提示',
        //                             message: '用户协议已更新，请查看',
        //                             duration: 0,
        //                         });
        //                     }, 100); */
        //                 }
        //             }, 1, 1, (err) => {
        //                 reject(err);
        //             });
        //         })
        //     )
        // },
    },
    getters: {}
}

export default homeSearch
