import {axiosApi} from '../../api/api.js'

const classifylist = {
    state: {
        classifylistData: [

        ],
    },
    mutations: {
    },
    actions: {
        //请求商品分类列表
        queryclassifylistData({state}) {
            const api = '/goods/classifyTopList/select';

            const body = {
                classificationId: ''
            };
           axiosApi(api,body,(res)=> {
                state.classifylistData = res.data.data.length > 0 ? res.data.data : []
            })
        },
        // 社会久集商品分类列表
        querysocietyclassifylist({state}) {
            const api = '/superc/classify/selectFirstClassifyList';
            const body = {
            };
           axiosApi(api,body,(res)=> {
                state.classifylistData = res.data.data.length > 0 ? res.data.data : []
            })
        },
        //加载赠品列表
    },
    getters: {}
}

export default classifylist
