<template>
    <div id="jicaiDetail" class="bg-f">
        <div class="width-1280 box-con bdt">
            <div class="clearfix bg-f pt-30">
                <div class="ml-20 fl mt-20">
                    <p class="font-16 mt8 lh-16">{{Data.name}}</p>
                    <p class="lh-16 mt-15 color-9">招标时间：{{Data.bidDateFrom | timenData}}</p>
                </div>
                <div v-show="src" class="fr mr-30 ">
                    <img class="icon" :src="src"/>
                </div>
            </div>
            <div class="mt-10">
                <div v-if="type==1">
                    <span class="text_title1 cursor" @click="type=0">招标商户</span>
                    <span class="text_title cursor" style="margin-left: -3px" @click="type=1">招标信息</span>
                </div>
                <div v-else>
                    <span class="text_title cursor" @click="type=0">招标商户</span>
                    <span class="text_title1 cursor" style="margin-left: -3px" @click="type=1">招标信息</span>
                </div>
                <hr class="line mt-5"/>
            </div>
            <!-- 招标商户 -->
            <div class="pb-30 mt-50" v-if="type==0">
                <el-table :data="List" class='table-default'>
                    <el-table-column label='商户' width=""  class-name="plName">
                        <template slot-scope="scope">
                            <span>{{scope.row.buyerName}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label='账号' width="120">
                        <template slot-scope="scope">
                            <span>{{scope.row.buyerLoginName}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label='招标信息' width="">
                        <template slot-scope="scope">
                            <span> 满{{scope.row.bidQty}}箱、每瓶{{scope.row.bidPrice}}元、返利{{scope.row.bidRebate}}%</span>
                        </template>
                    </el-table-column>
                    <el-table-column label='驳回理由' width="">
                        <template slot-scope="scope">
                            <span v-if="scope.row.reason">{{scope.row.reason}}</span>
                            <span v-else>-</span>
                        </template>
                    </el-table-column>
                    <el-table-column label='状态' width="80">
                        <template slot-scope="scope">
                            <span v-if="scope.row.state==0">竞标中</span>
                            <span v-if="scope.row.state==1">成功</span>
                            <span v-if="scope.row.state==2">失败</span>
                        </template>
                    </el-table-column>
                    <el-table-column label='操作' width="160">
                        <template slot-scope="scope">
                            <el-button v-if="scope.row.state==0" type='text'
                                       @click="pass(scope.row.id,scope.row.bidId)">通过
                            </el-button>
                            <el-button v-if="scope.row.state==0" type='text'
                                       @click="rejectVisible=true,id =scope.row.id">
                                驳回
                            </el-button>
                            <span v-else>-</span>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination class="tar"
                               v-if="List.length>0"
                               :current-page="currentPage"
                               :page-size='10'
                               :total='total'
                               layout="prev , pager, next, jumper"
                               @current-change="handleCurrentChange">
                    <!-- 分页组件 -->
                </el-pagination>
            </div>
            <!-- 招标信息 -->
            <div class="bg-f mt-5" v-if="type==1">
                <div class="ml-100 pl-20 pt-50 pb-40 bg">
                    <el-form label-width='130px'>
                        <el-form-item label="招标商品:">
                            <div>
                                <img v-if="!Data.skuImage" class="fl icon-60 bd" src="../../assets/goods.jpg"/>
                                <img v-else class="fl icon-60 bd" :src="Data.skuImage"/>
                                <div class="ml-20 fl">
                                    <p class="lh-16 mt-15 color-9">{{Data.skuName}}</p>
                                    <p class="lh-16 mt-15 color-9">商品编码：{{Data.skuNo}}</p>
                                </div>
                            </div>
                        </el-form-item>
                        <el-form-item label="集采价:">
                            <span v-html="priceString" class="fl"></span>
                        </el-form-item>
                        <el-form-item label="最低零售价:">
                            <span v-if="priceRed" class="fl">{{priceRed}}元</span>
                            <span v-else class="fl">-</span>
                        </el-form-item>
                        <el-form-item label="招标地区:">
                            <span class="fl">{{Data.allDistrictName}}</span>
                        </el-form-item>
                        <el-form-item label="招标名额:">
                            <span class="fl">{{Data.limitBitNum}}</span>
                        </el-form-item>
                        <el-form-item label="合约时间:">
                            <span class="fl">{{Data.validPeriod}}年</span>
                        </el-form-item>
                        <el-form-item label="竞选数量:" >
                            <span v-if="!Data.bidderCount">0人</span>
                            <span v-else class="fl">{{Data.bidderCount}}人</span>
                        </el-form-item>
                        <el-form-item v-if="Data.state==2" label="合约期限:">
                            <span v-if="Data.contractDateFrom" class="fl">{{Data.contractDateFrom | timenData}}至{{Data.contractDateTo | timenData}}</span>
                            <el-button v-else class="button_top tac mr-30 font-14" @click="updateData">编辑</el-button>
                        </el-form-item>
                        <el-form-item label="招标说明:">
                            <div v-html="Data.illustrationContent" class="fl"/>
                        </el-form-item>
                    </el-form>
                </div>
            </div>

        </div>

        <!--驳回申请弹窗-->
        <el-dialog title='输入驳回理由' :visible.sync='rejectVisible'>
            <el-form class="pl-30 pr-30" :model="Form" ref="ruleForm" :rules="rules">
                <el-form-item label="" prop='rejectionReason'>
                    <el-input type='textarea' placeholder='请输入驳回的理由...' :autosize="{ minRows: 3 ,maxRows: 10}"
                              v-model.trim='Form.rejectionReason'></el-input>
                </el-form-item>
            </el-form>
            <div slot='footer'>
                <el-button type='danger' @click="rejectCommit">提 交</el-button>
                <el-button @click="rejectVisible = false">取 消</el-button>
            </div>
        </el-dialog>

        <el-dialog title='合同期限' :visible.sync='dateVisible'>
            <el-form :inline='true' class="pt-30 fl" :model="Form">
                <el-form-item label="合同期限">
                    <ys-daterange-picker v-model="Form.orderDateRange"/>
                </el-form-item>
            </el-form>
            <div slot='footer'>
                <el-button type='danger' @click="dateCommit">提 交</el-button>
                <el-button @click="dateVisible = false">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {axiosApi, basePath} from "../../api/api";
    // import YsDaterangePicker from "~/components/ys-daterange-picker";

    export default {
        // components: {YsDaterangePicker},
        data() {
            return {
                icon1: require("../../assets/jicai_icon_1.png"),
                icon2: require("../../assets/jicai_icon_2.png"),
                icon3: require("../../assets/jicai_icon_3.png"),
                icon4: require("../../assets/jicai_icon_4.png"),
                List: [],
                type: 0,
                priceString: "",
                goodsNo: null,
                priceRed: null,
                rejectVisible: false, //弹出框
                dateVisible: false, //弹出框
                Form:
                    {
                        orderDateRange: [],
                        rejectionReason: '',
                    },
                id: null,//驳回哪个人的Id
                Data: {},
                currentPage: 1,
                rules:
                    {
                        rejectionReason: [
                            {required: true, message: '请输入驳回原因！'},
                            {max: 30, message: '不能超过30个字符！'}
                        ],
                    }
                ,
            }
                ;

        },
        mounted() {
            this.$nextTick(function () {
                this.getList();
                this.getDetail();
            })
        },
        computed: {
            src() {
                let icon = "";
                switch (this.Data.state) {
                    case -1:
                        icon = this.icon2
                        break;
                    case 2:
                        icon = this.icon1
                        break;
                    case 0:
                        icon = this.icon3
                        break;
                    case 1:
                        icon = this.icon4
                        break;
                    default:
                        icon = null;
                }
                return icon;
            },
        },
        watch: {
            goodsNo: function (val) {
                this.getPriceRed()
            },
        },
        methods: {
            getList() {
                axiosApi('/trader/bidder/list', {
                    id: this.$route.query.id,
                }, (res) => {
                    this.List = res.data.data.elements ? res.data.data.elements : []
                    this.total = res.data.data.totalCount
                }, this.currentPage, 10, (res) => {
                })
            },
            getDetail() {
                axiosApi('/trader/bid/detail/seller', {
                    id: this.$route.query.id,
                    needAreaInfo: true,
                    bidByManager: false
                }, (res) => {
                    this.Data = res.data.data ? res.data.data : {};
                    this.goodsNo = res.data.data.skuNo
                    this.areaChange();
                }, this.currentPage, 10, (res) => {
                    this.$message.error(res.data.message)
                })
            },
            audited() {
                const api = "/trader/bid/reject";
                const body = {
                    bidId: this.$route.query.id,
                    id: this.id,
                    reason: this.Form.rejectionReason,
                };
                axiosApi(api, body, (res) => {
                    this.$message({
                        message: '操作成功!',
                    });
                    this.getList();
                    this.getDetail();
                }, 1, 10, (res) => {
                    this.$message({
                        message: res.data.message,
                    });
                })
            },
            handleTabs(e) {
                if (e.target.attributes.type) this.type = e.target.attributes.type.value;
            },
            handleCurrentChange(val) {//分页
                this.currentPage = val
                this.getList()
            },
            //驳回申请理由提交
            rejectCommit() {
                this.$refs.ruleForm.validate((valid) => {
                    if (valid) {
                        this.rejectVisible = false
                        this.audited();
                    } else {
                        return false
                    }
                })
            },
            pass(id, bidId) {
                const api = "/trader/bid/approve";
                const body = {
                    bidId: bidId,
                    id: id,
                };
                axiosApi(api, body, (res) => {
                    this.$message({
                        message: '操作成功!',
                    });
                    this.getList();
                    this.getDetail();
                }, 1, 10, (res) => {
                    this.$message({
                        message: res.data.message,
                    });
                })
            },
            getPriceRed() {
                axiosApi('/superc/traderGoods/selectBySkuNo', {
                    skuNo: this.goodsNo
                }, (res) => {
                    this.priceRed = res.data.data.minSellingPrice;
                }, this.currentPage, 10, (res) => {
                    this.$message.error(res.data.message)
                });
            },
            areaChange() {
                if (this.Data.bidPriceList && this.Data.bidPriceList.length != 0) {
                    this.priceString = "";
                    this.Data.bidPriceList.forEach(item => {
                        this.priceString += "满" + item.qty + "箱、每瓶" + item.price + "元、返利" + item.rebate + "%<BR/>"
                    });
                }
            },
            updateData() {
                this.dateVisible = true;
            },
            dateCommit() {
                let contractDateFrom = this.Form.orderDateRange[0] ? this.Form.orderDateRange[0].getTime() : null;
                let contractDateTo = this.Form.orderDateRange[1] ? this.Form.orderDateRange[1].getTime() : null;
                if (contractDateFrom && contractDateTo) {
                    axiosApi('/trader/bid/update', {
                        id: this.$route.query.id,
                        contractDateFrom: contractDateFrom,
                        contractDateTo: contractDateTo
                    }, (res) => {
                        this.dateVisible = false;
                        this.getDetail();
                    }, this.currentPage, 10, (res) => {
                        this.$message.error(res.data.message)
                    });
                } else {
                    this.$message.error("请选择日期")
                }
            }
        }
    };
</script>

<style lang="less">
    #jicaiDetail {
        .button_top {
            width: 90px;
            height: 30px;
            line-height: 30px;
            border: 1px solid #DDDDDD;
            color: #20a0ff
        }

        .bdt {
            border-top: 1px solid #eee;
        }

        .box-con {
            padding: 30px 125px 50px 125px;
        }

        .icon {
            margin-top: 20px;
            margin-right: 90px;
            width: 94px;
            height: 73px;
        }

        .table-default.el-table .cell {
            text-align: left !important;
        }

        .line {
            color: #EEEEEE
        }

        .text_title1 {
            padding: 5px 15px 5px 15px;
            border: 1px solid #DDDDDD;
        }
        .text_title {
            padding: 5px 15px 5px 15px;
            border: 1px solid #FF4201;
            color: #FF4201;
        }
        .plName .cell {
            margin-left: 30px!important;
        }
    }
</style>