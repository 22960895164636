<template>
	<!--二级分销订单-->
	<div id="distributionOrder">
		<div class="title-tabs bg-f">
			<ul class="clearfix">
				<template v-for='(tab,index) in dataTabs' class="item">
					<li :type='index' :class="type==index?'active':''" @click='handleTabs'>
						{{tab}}
						<div :class="type==index?'under-line':''"></div>
					</li>
					<div class="vertical-line"></div>
				</template>
			</ul>
		</div>
		<!--未付款标记-->
		<el-badge class="notPayCountmark" :max="99" :value="badge.notPayCount" :hidden="badge.notPayCount==0"/>
		<!--待发货标记-->
		<el-badge class="notDeliveryCount" :max="99" :value="badge.notDeliveryCount" :hidden="badge.notDeliveryCount==0"/>
		<!--待收货标记-->
		<el-badge class="orderDeliveriedCount" :max="99" :value="badge.orderDeliveriedCount" :hidden="badge.orderDeliveriedCount==0"/>
		<div class="content bg-f pl-30 pr-30 pb-40">
			<div class="clearfix search">
				<el-form :inline='true' class="pt-30 fl" :model="form" ref="searchForm">
					<el-form-item label='订单编号' prop="orderSn" :rules="[{pattern:/^\d{16,20}$/,message:'请输入正确订单编号',trigger:'blur'}]">
						<el-input v-model.trim="form.orderSn" placeholder="请输入订单编号"
						 :maxlength="20"></el-input>
					</el-form-item>
					<el-form-item label='订单商品' class='ml-20'>
						<el-input v-model.trim="form.skuName" :maxlength="30" placeholder="请输入订单商品"></el-input>
					</el-form-item>
					<!--<el-form-item label='久集'>
						<el-select v-model="form.supercStoreId" placeholder="请选择久集账号" clearable>
							<el-option v-for="g in superCList" :label="g.storeName" :value="g.id" ></el-option>
						</el-select>
					</el-form-item>-->

					<el-form-item label='支付方式'>
						<el-select v-model="form.payMethod" placeholder="请选择支付方式" clearable>
							<!--<el-option label="组合支付" value="0"></el-option>
							<el-option label="线下支付" value="1"></el-option>-->
							<el-option label="授信支付" value="2"></el-option>
							<el-option label="在线支付" value="3"></el-option>
							<el-option label="余额支付" value="4"></el-option>
							<!--<el-option label="自行支付" value="5"></el-option>-->
						</el-select>
					</el-form-item>
					<el-form-item label='下单时间' class='ml-20'>
						<!--<el-date-picker v-model="form.orderDateRange" class="order-datatimerange" type="daterange" placeholder="选择时间范围" :picker-options="pickerOptions">
						</el-date-picker>-->
						<ys-daterange-picker v-model="form.orderDateRange" :endDisabledDate="new Date()"/>
					</el-form-item>
				</el-form>
				<el-button class="fl ml-30 mt-30" type='info' @click="handleCheck">查询</el-button>
			</div>
			<div class='tar clearfix'>
				<!--<el-button type='text' class="fr" @click="exportExcel">批量导出</el-button>-->
			</div>
			<div class="order-list" v-loading="loading" style="position: relative">
				<el-checkbox v-model="checkAll" @change="handleCheckAllChange" class="checkedAll" style="position: absolute;top: 10px;left: 0px"></el-checkbox>
				<el-table>
					<el-table-column label='商品' align="center" min-width="100"></el-table-column>
					<el-table-column label='单价' align="center" min-width="50"></el-table-column>
					<el-table-column label='数量' align="center" min-width="50"></el-table-column>
					<el-table-column label='久集' align="center" min-width="50"></el-table-column>
					<el-table-column label='销售佣金' align="center" min-width="50"></el-table-column>
					<el-table-column label='买家' align="center" min-width="50"></el-table-column>
					<el-table-column label='交易状态' align="center" min-width="50"></el-table-column>
					<el-table-column label='支付方式' align="center" min-width="50"></el-table-column>
					<el-table-column label='支付金额' align="center" min-width="50"></el-table-column>
					<el-table-column label='操作' min-width="50" align="center"></el-table-column>
				</el-table>
				<div class="table-item-default tac" v-if="ordersListResVo.length<=0">
					<img src="../../assets/empty.png" alt="" />
					<div class="color-danger mt-30">
						<router-link :to="{path:'/customer/shop/shopIndex'}"><span class="color-danger"> 啥都没有，去首页看看 ></span></router-link>
					</div>
				</div>
				<div v-else>
					<el-checkbox-group v-model="checkOrder"  @change="handleCheckChange">
						<div class="table-item mt-10" v-for='(order, index) in ordersListResVo' :key='index'>
						<div class="clearfix table-item-top lh-20 bbd bg-e">
							<el-checkbox class="fl" :label="order"></el-checkbox>
							<span class='fl'>订单编号：{{order.orderSn}}</span>
							<span class='fl color-6 ml-30'>下单时间：{{order.gmtCreate | time}}</span>
						</div>
						<div class="table-item-content clearfix bbd" style="display: flex;" >
							<div class=" clearfix" style="flex: 4;box-sizing: border-box;" >
								<!--商品清单-->
								<div class="bb clearfix "  v-for="(orderItem, index) in order.itemList"
									style="padding: 20px 0 20px 20px;display: flex">
									<div class="fl" style="flex: 2">
										<img v-if="orderItem.fileUrl" :src="orderItem.fileUrl" class="fl mr-20" />
										<img v-else src="../../assets/goods.jpg" class="fl mr-20" />
										<p>{{orderItem.skuName}}</p>
										<p class="color-9">规格:1*{{orderItem.specInfoNum}}</p>
									</div>
									<div class="fl tac " style="flex: 1">¥{{orderItem.discountPrice | fmoney}}</div>
									<div class="fl tac " style="flex: 1">{{orderItem.num}}{{orderItem.unit}}</div>
								</div>
							</div>
							<div class="bl bb tac pt-20" style="flex: 1;word-break: break-all">{{order.storeName}}</div>
							<div class="bl bb tac pt-20" style="flex: 1;word-break: break-all">￥{{order.commission | fmoney}}</div>
							<div class="bl bb tac pt-20" style="flex: 1;word-break: break-all">{{order.addrUsername}}</div>
							<div class="bl bb tac pt-20" style="flex: 1;">
								<span v-if="order.orderStatus==0">已关闭</span>
								<span v-if="order.orderStatus==1">待付款</span>
								<span v-if="order.orderStatus==2">待发货</span>
								<span v-if="order.orderStatus==3">已发货</span>
								<span v-if="order.orderStatus==4">已完成</span>
							</div>
							<!-- 支付方式 -->
							<div class="bl bb tac pt-20" style="flex: 1;">
								<span v-if="order.payMethodExp">{{order.payMethodExp}}</span>
								<span v-else>--</span>
							</div>
							<div class="bl bb tac pt-20" style="flex: 1">
								<div class="tac">¥{{order.needPayMoney | fmoney}}</div>
								<div class="tac" v-if="order.freightMoney != null && order.freightMoney > 0">（含运费：¥{{order.freightMoney | fmoney}}）</div>
								<div class="tac" v-if="order.couponCutMoney != null && order.couponCutMoney > 0">（优惠券：<br/>-¥{{order.couponCutMoney | fmoney}}）</div>
							</div>
							<div class="bl bb tac" :style="{paddingTop : '15px'}" style="flex: 1;" >
                                <el-button type="text" class="ml-0" @click="orderDetail(order.orderSn)">
                                    订单详情
                                </el-button>
							</div>

						</div>
					</div>
					</el-checkbox-group>
				</div>
				<el-pagination v-if='total>0' class="tar mt-20" :current-page.sync="currentPage" :page-size='10' :total='total' layout="prev , pager, next, jumper" @current-change="handleCurrentChange">
					<!-- 分页组件 -->
				</el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
	import { axiosApi, basePath } from "../../api/api"
	// import YsDaterangePicker from "~/components/ys-daterange-picker";
	export default {
		// components: {YsDaterangePicker},
		data() {
			return {
				type: '0',
				dataTabs: ['所有订单', '待发货', '待收货', '已完成'],
				data: ['1'],
				cancelOrderVisible:false,//取消订单弹框
				reason:null,
				ordersListResVo:[],//订单数据
				orderSn: null,//订单编号
				superCList:[],//久集账号列表
				idStr:[],//批量导出选中列表
				fileName: null,//导出名称
				form:{
					sellerId: sessionStorage.traderId,
					orderSn: null,   //订单编号
					skuName: null,		//商品名称
					orderStatus:null,    //订单状态
					supercStoreId:null,//久集主键Id
					startTime:null,
					endTime:null,
					orderDateRange: [],   //gmtOrderStart下单开始时间 gmtOrderEnd下单结束时间
					traderType:'1',
					cancleReason:null,   //订单取消原因
					expressName: null,//物流公司
					expressNum: null,//物流单号
					payMethod : '', // 支付方式
					referrerStoreId:'',
					orderStatusList:[2,3,4]
				},
				pickerOptions: {
					disabledDate(time) {
						return time.getTime() > new Date();
					}
				},//禁选未来时间
				rules:{
					cancleReason:[{max:30, message:'不能超过30个字！'}]
				},
				clearTime:1,  //定时器
				loading:false,
				currentPage:1,total:null,
				badge:{		//标记
					notPayCount:0,  //未付款
					notDeliveryCount:0, //已付款-待发货
					orderDeliveriedCount:0, //已发货-待收货
					successCount: 0, //已完成
					cancleCount:0, //已取消
					notAuditCount:0, //未审核
				},
				checkAll:false,//选中状态
				checkOrder:[], //选中的每行订单数据
				sendGoods:false,//发货弹框
				expressList:[],//物流列表
				voucherImgList : [], // 凭证图片 list
				bankInfo : {}, // 凭证 银行信息
				voucherId : '', // 审核 凭证 id
				viewredentialsType : '', // 审核浮层类型 0 - 审核 1 - 详情
				imgviewVisible : false, // 查看凭证 dialog 开关
			}
		},
		mounted() {
			this.$nextTick(() => {
				let type = sessionStorage.getItem('type')
				if (type) {
					this.type = type
					if(this.type == '0'){	//全部订单
						this.form.orderStatusList = [2,3,4]
					}else if(this.type == '1'){  //待发货
						this.form.orderStatusList = [2]
					}else if(this.type == '2'){ //待收货
						this.form.orderStatusList = [3]
					}else if(this.type == '3'){		//已完成
						this.form.orderStatusList = [4]
					}
				}else{
					this.type = '0'
				}
				this.form.sellerId = sessionStorage.traderId
				this.queryOrderList(this.form)//默认加载订单
				this.getsuperC();//默认加载久集列表
//				sessionStorage.removeItem('type')
			})
		},
		methods: {
			getsuperC(){/*久集账号*/
				axiosApi('/superc/querySupercForSeller/select',{
					sellerId:sessionStorage.traderId,
					supercType:'1'
				},(res)=>{
					console.log('久集账号',res)
					this.superCList = res.data.data;
				},1,10,(res)=>{})
			},
			anonOrderCount(){//订单统计(角标)
				axiosApi('/anon/order/count',{
					traderType: 1,
					buyerId: sessionStorage.traderId
				},(res)=>{
					// //console.log('标记数量',res)
					this.badge = res.data.data
				},1,10,(res)=>{

				})
			},
			handleCurrentChange(val){//分页
				this.currentPage = val;
				this.queryOrderList(this.form);
//				this.anonOrderCount()
			},
			handleTabs(e) {
//				if(e.target.type) this.type = e.target.type // 改变 type的值
				if(e.target.attributes.type) this.type = e.target.attributes.type.value
				this.form =	{
					orderSn: null,   //订单编号
					skuName: null,		//商品名称
					orderStatus:null,    //订单状态
					supercStoreId:null,//久集主键Id
					startTime:null,
					endTime:null,
					orderDateRange: [],   //gmtOrderStart下单开始时间 gmtOrderEnd下单结束时间
					traderType:'1',
					cancleReason:null,   //订单取消原因
					expressName: null,//物流公司
					expressNum: null,//物流单号
					payMethod : '',
				}
				this.currentPage = 1;
				if(this.type == '0'){	//全部订单
					this.form.orderStatusList = [2,3,4]
				}else if(this.type == '1'){  //待发货
					this.form.orderStatusList = [2]
				}else if(this.type == '2'){ //已发货
					this.form.orderStatusList = [3]
				}else if(this.type == '3'){		//已完成
					this.form.orderStatusList = [4]
				}
				this.form.sellerId = sessionStorage.traderId;
				this.currentPage = 1
				this.queryOrderList(this.form)
			},
			timeFormat(value){
                var myDate = new Date(value);
                var year = myDate.getFullYear();
                var month = myDate.getMonth() + 1 < 10 ? '0' + (myDate.getMonth() + 1) : myDate.getMonth() + 1;
                var day = myDate.getDate() < 10 ? '0' + myDate.getDate() : myDate.getDate();
                return year + '-' + month + '-' + day;
            },
			handleCheck(){   ///订单条件查询
				this.form.startTime =  this.form.orderDateRange[0] ? this.form.orderDateRange[0].getTime() : null;
				this.form.endTime = this.form.orderDateRange[1] ? this.form.orderDateRange[1].getTime() : null;
//				this.form.startTime = this.form.startTime == null ? null : this.timeFormat(this.form.startTime);
//				this.form.endTime = this.form.endTime == null ? null : this.timeFormat(this.form.endTime);
				//订单编号表单验证
				this.$refs.searchForm.validate((valid)=>{
					if(valid){
						this.currentPage = 1
						this.queryOrderList(this.form)
					}
				})
			},
			queryOrderList(body) {/*久集订单列表*/
				if(this.$route.query.supercStoreId){
					body.supercStoreId = this.$route.query.supercStoreId
				}
				if(this.$route.query.referrerStoreId){
					body.referrerStoreId = this.$route.query.referrerStoreId
				}
				console.log(body,'body')
				this.loading = true
				axiosApi('/superc/supercOrderListQuery/select', body, (res) => {
					//console.log('订单列表查询', res,this.ordersListResVo)
					this.loading = false
					this.total = res.data.data.totalCount
					this.ordersListResVo = res.data.data.elements

				}, this.currentPage, 10, (res) => {
					this.loading = false
					this.$message({type:'error',message:res.data.message,duration:1000})
				})
			},
			orderDetail(orderSn){//去订单详情页面
				sessionStorage.setItem('orderSn',orderSn)
//				console.log(this.PROJECT,'测试')
				window.open(basePath + '/supercOrderDetails/' + orderSn + "/1", '_blank')
			},
			handleCheckAllChange(val){/*table全选*/
				if(this.checkAll) {
					this.ordersListResVo.forEach(item => {
						this.checkOrder.push(item)
						this.idStr.push(item.id)
					});
				} else {
					this.checkOrder = [];
					this.idStr = []
				}
			},

			handleCheckChange(val){//单选
				let checkedCount = val.length;
				this.checkAll = checkedCount === this.ordersListResVo.length;
				this.idStr = [];
				val.forEach(item => {
					this.idStr.push(item.id)
				});
			},
		}
	}
</script>

<style lang="less">
	#distributionOrder {
		position: relative;
		box-sizing: border-box;
		.el-checkbox__label{display: none;}
		.notPayCountmark{
			position: absolute;
			left: 169px;top: 10px;
		}
		.notDeliveryCount{
			position: absolute;
			left: 248px;top: 10px;
		}
		.orderDeliveriedCount{
			position: absolute;
			left: 330px;top: 10px;
		}
		.hide {
			display: none;
		}
		.content {
			.el-date-editor--daterange.el-input{
				width: 240px;
			}
			.el-form--inline {
				width: 650px;
				.el-form-item__label {
					font-size: 12px;
					width: 60px;
				}
			}
			.el-table .el-table__body-wrapper {
				display: none;
			}
			.order-datatimerange {
				width: 240px;
			}
			.el-table{
				border: none;font-size: 14px;
				th>.cell{line-height: 12px; padding: 10px 0 10px 0;text-align: center;}
				th.is-leaf{
					border-bottom: 0;
				}
			}
			.checkedAll {
				position: relative;
				top: 30px;
			}
			.el-checkbox{
				width: 70px;
				margin-left: 30px;
				.el-checkbox__input{height: 20px;}
			}
			.el-checkbox__inner{width: 20px;height: 20px;}
			.el-checkbox__inner::after {top: 2px;}
			.table-item {
				.bl {border-left: 1px solid #DDD;}
				border: 1px solid #DDDDDD;
				font-size: 14px;
				border-bottom: none;
				.table-item-content .pbl-20 {
					padding: 0px 0px 20px 20px;
				}
				.table-item-top {
					padding: 10px 0;
					.table-item-top-checkbox {
						margin-left: 14px
					}
				}
				img {
					width: 80px;
					height: 80px;
				}
			}
			.w100{
				width: 100px;
			}
			.table-item-default {
				height: 530px;
				padding-top: 120px;
			}
			.el-dialog{
				min-height: 300px;
				.el-form-item__label{
					width: 140px;
				}
			}
			.sendGoods .el-form-item__error{
				margin-left: 140px;
			}
		}
	}
</style>
