<template>
	<div id="applyAccount">
		<div class="title-top bg-f clearfix">开通详情</div>
		<div class="content bg-f pl-30 pr-30 mt-5 pt-20">
			<el-form ref="form" :model="form" label-width="100px" :rules="rules">
				<el-form-item label="申请说明" prop="applyExplain"> 
					<el-input type="textarea" v-model.trim="form.applyExplain" :maxlength="200"></el-input>
				</el-form-item>
				<el-form-item label="合同编辑" prop="contract"> 
					<ueditor ref="ueeditor" v-model="form.contract"></ueditor>
				</el-form-item>
				<el-form-item prop="checked"> 
					<el-checkbox v-model="form.checked">
						<span>我已阅读并同意</span>
						<router-link :to="`${basePath}/protocolOne`" target = _blank>
							<span class="color-3 hover-danger ml-6 color-blue cursor">《“久加久云商”用户（买家）注册协议》</span>
						</router-link>
						<router-link :to="`${basePath}/protocolTwo`" target = _blank>
							<span class="color-3 hover-danger ml-6 color-blue cursor">《共享经济合作伙伴协议》</span>
						</router-link>
					</el-checkbox>
				</el-form-item>
			</el-form>
			<el-button class="submitBtn" :disabled="!form.checked" @click="submit" type="danger">提交</el-button>
		</div>
	</div>
</template>
<script>
	import { axiosApi, basePath } from "../../api/api";
	export default {
		data(){
			return {
				basePath: basePath,
				form:{
					applyExplain:'',
					contract:'',
					checked:true
				},
				employeeName:null,
				rules:{
					applyExplain:[{
	                    required: true,
	                    message: '请填写申请说明'
	                }, {
	                    min: 2,
	                    message: '至少2个字符'
	                }],
	                contract:[{
	                	required: true,
	                	message: '请填写合同'
	                }]
				}
				
			}
		},
		methods:{
			submit(){
				this.form.contract = this.$refs.ueeditor.getContent();
				setTimeout(()=>{
					this.$refs.form.validate((valid) => {
						if(valid&&this.form.checked){
							const body = {
								traderId: sessionStorage.getItem('traderId'),
								applyExplain: this.form.applyExplain,
								contract: this.$refs.ueeditor.getContent(),
								employeeName: this.employeeName
							}
							axiosApi('/superc.traderOpenSuperC.apply',body,res=>{
								this.$router.replace({path:'applySuccess'})
							},1,10,err=>{
								this.$message({type:'error',message:err.data.message,duration:1000})
							})
							
						}
					})
					
				},0)
			}
		},
		mounted (){
			//获取一个需要提交的参数-->申请人姓名employeeName
			axiosApi("/trader/manyStoresHomePage/select", {
				loginName: sessionStorage.getItem('loginName')
			}, res => {
				this.employeeName = res.data.data.employeeName;
			})
			//查询商户开通久集的申请结果
			axiosApi('/superc.traderOpenSuperC.select',{
				traderId:sessionStorage.getItem('traderId')
			},res=>{
				this.form = Object.assign({},{checked:true},res.data.data)
			})
		}
	}
</script>
<style lang="less">
	#applyAccount{
		.content{
			padding-bottom: 130px;
			position: relative;
			textarea{
				width: 600px;
				height: 100px;
				overflow-y: hidden;
			}
			.submitBtn{
				position: absolute;
				left: 50%;
				margin-left: -45px;
				bottom: 50px;
			}
			.success{
				.successMsg{
					padding: 0px 30px;
					text-align: center;
				}
			}
			.el-dialog{
				min-height: 280px;
			}
	        .el-form-item__label{
	        	padding-right: 20px;
	        }
	        .el-form-item__content{
	        	margin-left: 100px;
	        }
			
		}
	}
</style>