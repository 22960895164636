<template>
    <div id="component-goods-box">
        <el-dialog title="选择商品" :visible.sync="productVisible" @close="goPage">
            <div v-loading="tableloading">
                <el-form :inline="true" :model="skuObj" label-width="80px" class="clearfix" :style="{width:'850px'}">
                    <div class="fl">
                        <el-form-item label="商品编码:">
                            <el-input v-model.trim="skuObj.skuNo" @input="skuObj.skuNo=skuObj.skuNo.replace(/[\u4e00-\u9fa5]/ig,'')"></el-input>
                        </el-form-item>
                        <el-form-item label="商品名称:">
                            <el-input v-model.trim="skuObj.skuName"></el-input>
                        </el-form-item>
                        <el-form-item label="商品分类:">
                            <el-select v-model='skuObj.classifyId' placeholder='请选择' clearable>
                                <el-option v-for='(item,i) in classifylist' :key='i'
                                           :label='item.classifyName'
                                           :value='item.id'></el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                    <el-button type="primary" class="fl ml-50" @click="querygood">查询</el-button>
                </el-form>
                <el-table @selection-change="handleSelectionChange" :data="proData" stripe border tooltip-effect="dark"
                          class="mt-10 table-default m-table">
                    <el-table-column type="selection" width="50">
                    </el-table-column>
                    <el-table-column label="商品图片">
                        <template slot-scope="scope">
                            <img v-if="scope.row.fileUrl" :src="scope.row.fileUrl"/>
                            <img v-else src="../../assets/goods.jpg"/>
                        </template>
                    </el-table-column>
                    <el-table-column label="商品编码" prop='skuNo'>
                        <template slot-scope="scope">
                            <span v-if="isUnK3">{{ scope.row.goodsNo }}</span>
                            <span v-else>{{ scope.row.skuNo }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="商品名称" prop='skuName' width="350" show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column label="单位" prop='packageUnitName'>
                    </el-table-column>
                    <el-table-column label="分类" prop='classifyName'>
                    </el-table-column>
                </el-table>
                <el-pagination class="tar" v-if='total>0'
                               :current-page="currentPage"
                               :page-size='10'
                               :total='total'
                               layout="prev , pager, next, jumper"
                               @current-change="handlePageChange">
                </el-pagination>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="addgift" :loading="btnLoading">确 定</el-button>
                <el-button @click="goPage">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {mapActions, mapMutations} from 'vuex'
    import {axiosApi, basePath} from "../../api/api"; //导入axiosApi
    export default {
        props: ["goodVisible", "addapi", "ifnotauto", 'ok', 'isOpenPricesync','hotelAgreementId','isUnK3'],
        data() {
            return {
                currentPage: 1,
                skuObj: {
                    skuNo: '',     //商品(sku)编码
                    skuName: '',     //商品名称
                    classifyId: ''  //商品分类
                },
                btnLoading: false,     //搜索时
                multipleSelection: [],
                addlists: [],
                productVisible: false,
                // notId: [],
            }
        },
        computed: {
            postData() {
                return {
                    data: this.skuObj,
                    currentPage: this.currentPage
                }
            },
            tableloading() {
                return this.$store.state.ticket.tableloading
            },
            total() {
                return this.$store.state.ticket.goodtableData ?
                    this.$store.state.ticket.goodtableData.totalCount : 0
            },
            classifylist() {
                return this.$store.state.classifylist.classifylistData ?
                    this.$store.state.classifylist.classifylistData : []
            },
            proData() {
                let tableData = this.$store.state.ticket.goodtableData;
                this.currentPage = tableData ? tableData.pageIndex : 1;
                return tableData ? tableData.elements : []
            },
			isCityPartner() {
				return this.$store.state.clientType.isCityPartner;
			},
        },
        watch: {
            goodVisible(value) {

                this.productVisible = value
                if (value) {
                    this.skuObj.skuNo = ''
                    this.skuObj.skuName = ''
                    this.skuObj.classifyId = ''
                }
            },
            productVisible(val) {//监听弹窗model变化改变按钮loading（打开弹窗初始化loading）
                this.btnLoading = !val;
            }
            // notid(value) {
            // 	this.postData.data.notId = this.skuObj.notId = value
            // 	// console.log(this.postData)
            // }
        },
        mounted() {
            this.$nextTick(() => {
                this.classSearch()
            })
        },
        methods: {
            ...mapMutations({
                'clearnotid': 'clearnotid'
            }),
            //查询商品列表名字
            ...mapActions({
                'addDistribute': 'querygoodtableDataMeal',// 只查询套餐商品
                'classSearch': 'queryclassifylistData',
                'allGoodsQuery': 'allGoodsQuery' // 查询全部商品
            }),
            handleSelectionChange(val) {
                let addlist = val.map((each) => {
                    return {
                        sellerId: each.sellerId,
                        skuNo: each.skuNo,
                        skuName: each.skuName
                    }
                })
                this.addlists = addlist;
                this.multipleSelection = val.map(item => {
                    this.$set(item, 'add', true)// 添加add 属性区分新增商品
                    return item
                })
            },
            querygood() {
                if (this.currentPage == 1) {
                    var newpostdata = { ...this.postData, ...{currentPage: 1},...{hotelAgreementId:this.hotelAgreementId} };

                    let obj = JSON.parse(JSON.stringify(newpostdata));

                    if(this.isCityPartner){
						obj.data.goodsNo = obj.data.skuNo;
                    	delete obj.data.skuNo
                    }
                    console.log(obj,'000')
                    this.isOpenPricesync ? this.addDistribute(obj) : this.allGoodsQuery(obj)

                } else {
                    this.currentPage = 1;
                }
            },
            //调用添加赠品接口
            addgift() {
                const api = this.addapi;
                let body = this.addlists;
                this.btnLoading = true;
                if (body.length > 0) {
                    if (api) {
                        axiosApi(api, body, this.goPage)
                    } else {
                        if (this.ifnotauto) {
                            this.$store.dispatch('addgood', {
                                willadd: this.multipleSelection
                            })
                        }
                    }
                }
                if (api) {
                    this.goPage()
                } else {
                    if (this.ok === true) {
                        this.$emit('handleOk');
                    } else {
                        this.$emit('closedialog')
                    }
                }

            },
            //返回上一页
            goPage() {
                this.clearnotid()
                this.$emit('sure')
                this.$emit('closedialog')
            },
            handlePageChange(val) {
                this.currentPage = val;
				var newpostdata = { ...this.postData,...{hotelAgreementId:this.hotelAgreementId} };
                this.isOpenPricesync ? this.addDistribute(newpostdata) : this.allGoodsQuery(newpostdata)
            }
        }
    }
</script>

<style lang="less">
    #component-goods-box .el-dialog {
        width: 950px;
        /*height: 975px;*/
    }

    #component-goods-box .table-default.el-table .cell {
        padding: 3px 15px 0;
    }

    #component-goods-box .el-dialog__body {
        padding: 20px 30px;
    }

    #component-goods-box {
        .el-dialog__footer {
            position: static;
            transform: translateX(0);
            padding-bottom: 40px;
        }

        .el-dialog {
            .el-form {
                .el-form-item {
                    .el-form-item__label {
                        &::before {
                            display: none
                        }
                    }
                }
            }
        }

        .m-table {
            /* height: 470px;
            overflow-y: auto; */
        }
    }

    #component-goods-box .el-form .el-input {
        width: 120px;
    }
</style>
