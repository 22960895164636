<template>
    <div id="superBOrderDetails" class="bg-f">
        <div class="superBOrderDetails">
            <div v-loading='loading' class="pb-50">
                <div class="order-info clearfix mb-50 ml-30 mr-30">
                    <div class="title">
                        订单信息
                    </div>
                    <div class="flex">
                        <el-form class='flex-1'>
                            <el-form-item label='订单状态:'>
                                <div>
                                    <span v-if="orderInfoObj.orderStatus == 1">待付款</span>
                                    <span v-if="orderInfoObj.orderStatus == 2">待发货</span>
                                    <span v-if="orderInfoObj.orderStatus == 3">待收货</span>
                                    <span v-if="orderInfoObj.orderStatus == 4">已完成</span>
                                    <span v-if="orderInfoObj.orderStatus == 0">已关闭</span>
                                </div>
                            </el-form-item>
                            <el-form-item label="订单编号:">
                                {{orderInfoObj.sn}}
                            </el-form-item>
                            <el-form-item label="买家名称:">
                                {{orderInfoObj.buyerName}} ({{orderInfoObj.buyerPhone}})
                            </el-form-item>
                            <el-form-item label='订单金额:'>
                                ￥{{orderInfoObj.needPayMoney | fmoney}}
                            </el-form-item>
                            <el-form-item label='支付方式:'>
                                <div>
                                    <span v-if="orderInfoObj.payType == 0">组合支付</span>
                                    <span v-if="orderInfoObj.payType == 1">线下支付</span>
                                    <span v-if="orderInfoObj.payType == 2">授信支付</span>
                                    <span v-if="orderInfoObj.payType == 3">在线支付</span>
                                    <span v-if="orderInfoObj.payType == 4">余额支付</span>
                                </div>
                            </el-form-item>
                            <el-form-item label="支付时间:">
                                {{orderInfoObj.gmtPay | time}}
                            </el-form-item>
                            <el-form-item label='下单时间:'>
                                {{orderInfoObj.gmtCreate | time}}
                            </el-form-item>
                            <el-form-item label='下单方式:'>
                                <div class="fl">
                                    <span v-if="orderInfoObj.source == 1">商户下单</span>
                                    <span v-if="orderInfoObj.source == 2">供货商代下单</span>
                                    <span v-if="orderInfoObj.source == 3">城市经理代下单</span>
                                    <span v-if="orderInfoObj.source == 4">久集销售单</span>
                                    <span v-if="orderInfoObj.source == 5">超团toC商品销售单(自己发货)</span>
                                    <span v-if="orderInfoObj.source == 6">超团小代买上级商品toC销售单(上级发货订单)</span>
                                    <span v-if="orderInfoObj.source == 7">超团采购单</span>
                                    <span v-if="orderInfoObj.source == 8">酒店</span>
                                    <span v-if="orderInfoObj.source == 9">酒店久集采购单</span>
                                    <span v-if="orderInfoObj.source == 10">餐链</span>
                                    <span v-if="orderInfoObj.source == 11">门店久集采购</span>
                                    <span v-if="orderInfoObj.source == 12">超级B代下单</span>
                                </div>
                                <span class="fl ml-10">{{orderInfoObj.sourceDesc}}</span>
                            </el-form-item>
                            <el-form-item label='收货人:'>
                                {{orderInfoObj.addrUsername}}（{{orderInfoObj.addrTel}}）
                            </el-form-item>
                            <el-form-item
                                    label='收货地址:'>
                                {{orderInfoObj.addrProvince}} {{orderInfoObj.addrCity}}
                                {{orderInfoObj.addrRegion}}{{orderInfoObj.addrStreet}} {{orderInfoObj.addr}}
                            </el-form-item>
                        </el-form>
                        <div class="ml-10 mt-30">
                            <div>
                                <el-button class="mr-10" type @click="goBack()" v-if="this.$route.params.back==1">
                                    关闭页面
                                </el-button>
                            </div>
                        </div>
                    </div>
                </div>
                <!--收款记录-->
                <div class="ml-30 color-333 mt-30 mb-20  mr-30" style="text-align: right" v-if="sonOrders.length==0">
                    合计佣金:￥{{orderInfoObj.totalCommission | fmoney}} ，
                    合计一级补贴:￥{{orderInfoObj.totalSubsidyOne | fmoney}} ，
                    合计二级补贴:￥{{orderInfoObj.totalSubsidyTwo | fmoney}}
                </div>
                <div class="" key='payment' v-if="sonOrders.length==0">
                    <el-table :data="tableData"
                              class='ml-30 mt-10 table-default mr-30' stripe>
                        <el-table-column prop="1" label="商品" width="250">
                            <template slot-scope="scope">

                                <div class="fl specialD" style="width: 220px;">
                                    <img v-if="scope.row.image" :src="scope.row.image" class="fl mr-20"/>
                                    <img v-else src="../../assets/goods.jpg" class="fl mr-20"/>
                                    <img v-if="scope.row.activityId!=null"
                                         class="special"
                                         src="../../assets/trade.png"
                                         alt/>

                                    <img v-if="scope.row.isComplimentary"
                                         class="special"
                                         src="../../assets/superGroup/complimentary.png"
                                         alt/>
                                    <div class="flex-1 ml-10 mt-10 hidden">
                                        <div class="tal">{{scope.row.skuName}}</div>
                                        <div class="tal">规格:1*{{scope.row.specInfoNum}}</div>
                                    </div>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="单价">
                            <template slot-scope="scope">
                                ￥{{scope.row.originSpecPrice | fmoney}}/{{scope.row.showUnitName ? scope.row.showUnitName : scope.row.unit}}
                            </template>
                        </el-table-column>
                        <el-table-column label="佣金">
                            <template slot-scope="scope">
                                ￥{{scope.row.originUnitCommission | fmoney}}
                            </template>
                        </el-table-column>
                        <el-table-column label="一级补贴">
                            <template slot-scope="scope">
                                ￥{{scope.row.unitSubsidyOne | fmoney}}
                            </template>
                        </el-table-column>
                        <el-table-column label="二级补贴">
                            <template slot-scope="scope">
                                ￥{{scope.row.unitSubsidyTwo | fmoney}}
                            </template>
                        </el-table-column>
                        <el-table-column label="数量">
                            <template slot-scope="scope">
                                <div v-if="scope.row.isComplimentary">
                                    {{scope.row.specInfoNum}}{{scope.row.unit}}
                                </div>
                                <div v-else>
                                    {{scope.row.specNum}}{{scope.row.unit}}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="超级B" width="135">
                            <template slot-scope="scope">
                                <span>{{scope.row.accountName}}</span><br/>
                                <span>{{scope.row.accountTel}}</span>
                            </template>

                        </el-table-column>
                        <el-table-column label="小计佣金">
                            <template slot-scope="scope">
                                <span>￥{{scope.row.originUnitCommission*scope.row.specNum | fmoney}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="小计一级补贴">
                            <template slot-scope="scope">
                                <span>￥{{scope.row.subsidyOne | fmoney}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="小计二级补贴">
                            <template slot-scope="scope">
                                <span>￥{{scope.row.subsidyTwo | fmoney}}</span>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>

                <ul>
                    <li class="mb-10" v-for="(item,index) in sonOrders" :key="index">
                        <div class="ml-30 clearfix color-333 mt-30 mr-30">
                            <span class="fl mr-30 mb-20">订单编号:{{item.sn}}</span>
                            <span class="fl mb-20" v-if="item.orderStatus == 1">待付款</span>
                            <span class="fl mb-20" v-if="item.orderStatus == 2">待发货</span>
                            <span class="fl mb-20" v-if="item.orderStatus == 3">待收货</span>
                            <span class="fl mb-20" v-if="item.orderStatus == 4">已完成</span>
                            <span class="fl mb-20" v-if="item.orderStatus == 0">已关闭</span>
                            <span class="fr mb-20">
                            合计佣金:￥{{item.totalCommission | fmoney}} ，
                            合计一级补贴:￥{{item.totalSubsidyOne | fmoney}} ，
                            合计二级补贴:￥{{item.totalSubsidyTwo | fmoney}}</span>
                        </div>
                        <div class="" key='payment'>
                            <el-table :data="sonOrders[index].orderItemVos"
                                      class='ml-30 mt-10 table-default mr-30' stripe>
                                <el-table-column prop="1" label="商品" width="250">
                                    <template slot-scope="scope">

                                        <div class="fl specialD" style="width: 220px;">
                                            <img v-if="scope.row.image" :src="scope.row.image" class="fl mr-20"/>
                                            <img v-else src="../../assets/goods.jpg" class="fl mr-20"/>
                                            <img v-if="scope.row.activityId!=null"
                                                 class="special"
                                                 src="../../assets/trade.png"
                                                 alt/>

                                            <img v-if="scope.row.isComplimentary"
                                                 class="special"
                                                 src="../../assets/superGroup/complimentary.png"
                                                 alt/>
                                            <div class="flex-1 ml-10 mt-10 hidden">
                                                <div class="tal">{{scope.row.skuName}}</div>
                                                <div class="tal">规格:1*{{scope.row.specInfoNum}}</div>
                                            </div>
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column label="单价">
                                    <template slot-scope="scope">
                                        ￥{{scope.row.originSpecPrice | fmoney}}/{{scope.row.showUnitName ? scope.row.showUnitName : scope.row.unit}}
                                    </template>
                                </el-table-column>
                                <el-table-column label="佣金">
                                    <template slot-scope="scope">
                                        ￥{{scope.row.originUnitCommission | fmoney}}
                                    </template>
                                </el-table-column>
                                <el-table-column label="一级补贴">
                                    <template slot-scope="scope">
                                        ￥{{scope.row.unitSubsidyOne | fmoney}}
                                    </template>
                                </el-table-column>
                                <el-table-column label="二级补贴">
                                    <template slot-scope="scope">
                                        ￥{{scope.row.unitSubsidyTwo | fmoney}}
                                    </template>
                                </el-table-column>
                                <el-table-column label="数量">
                                    <template slot-scope="scope">
                                        <div v-if="scope.row.isComplimentary">
                                            {{scope.row.giftNum}}{{scope.row.unit}}
                                        </div>
                                        <div v-else>
                                            {{scope.row.specNum}}{{scope.row.unit}}
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column label="超级B" width="135">
                                    <template slot-scope="scope">
                                        <span>{{scope.row.accountName}}</span><br/>
                                        <span>{{scope.row.accountTel}}</span>
                                    </template>

                                </el-table-column>
                                <el-table-column label="小计佣金">
                                    <template slot-scope="scope">
                                        <span>￥{{scope.row.originUnitCommission*scope.row.specNum | fmoney}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="小计一级补贴">
                                    <template slot-scope="scope">
                                        <span>￥{{scope.row.subsidyOne | fmoney}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="小计二级补贴">
                                    <template slot-scope="scope">
                                        <span>￥{{scope.row.subsidyTwo | fmoney}}</span>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                    </li>
                </ul>


                <div class="total clearfix summary ml-30 mr-30">
                    <ul class="fr">
                        <li class="clearfix">
                            <span class="fl tal summary_title">商品金额：</span>
                            <span class='ml-30 fr tar summary_money'>+￥{{orderInfoObj.goodsTotalMoney | fmoney}}</span>
                        </li>
                        <li class="clearfix">
                            <span class="fl tal summary_title">满减金额：</span>
                            <span class='ml-30 fr tar summary_money'>- ￥ {{orderInfoObj.activityCutMoney||0 | fmoney}}</span>
                        </li>
                        <li class="clearfix">
                            <span class="fl tal summary_title">优惠券金额：</span>
                            <span class='ml-30 fr tar summary_money'>- ￥ {{orderInfoObj.couponCutMoney||0 | fmoney}}</span>
                        </li>
                        <li class="clearfix">
                            <span class="fl tal summary_title">佣金折扣：</span>
                            <span class='ml-30 fr tar summary_money'>- ￥ {{orderInfoObj.originTotalCommission-orderInfoObj.totalCommission||0 | fmoney}}</span>
                        </li>
                        <li class="clearfix" v-if="orderInfoObj.isHotel">
                            <span class="fl tal summary_title">折扣返利：</span>
                            <span class='ml-30 fr tar summary_money'>- ￥ {{orderInfoObj.discountCutMonney||0 | fmoney}}</span>
                        </li>
                        <li class="clearfix">
                            <span class="fl tal summary_title">运费：</span>
                            <span class='ml-30 fr tar summary_money'>+￥ {{orderInfoObj.freightMoney||0 | fmoney}}</span>
                        </li>
                        <li class="font-20 payment">
                            <span class="fl tal summary_title">应付金额：</span>
                            <span class='ml-30 fr tar summary_money'>￥ {{orderInfoObj.needPayMoney||0  | fmoney}}</span>
                        </li>
                        <li class="font-20 payment">
                            <span class="fl tal summary_title">应得佣金：</span>
                            <span class='ml-30 fr tar summary_money'>￥ {{orderInfoObj.totalCommission||0  | fmoney}}</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {axiosApi, basePath} from "../../api/api"

    export default {
        data() {
            return {
                orderInfoObj: {},
                tableData: [],
                sonOrders: [],
                loading: false,
            }
        },
        mounted() {
            this.$nextTick(() => {
                this.getDetails() //订单详情
            })
        },
        methods: {
            goBack() {
                history.go(-1);
            },
            getDetails() {

               let body = {};
                if (this.$route.params.type==1) {
                    body = {parentOrderSn: this.$route.params.orderSn};
                } else {
                    body = {orderSn: this.$route.params.orderSn};
                }

                axiosApi('/superb/order/sellerDetail', body, (res) => {
                    this.orderInfoObj = res.data.data;
                    res.data.data.orderGiftResVos.forEach(item => {
                        item.isComplimentary = true;//给赠品对象赋值标记
                    });
                    this.tableData = res.data.data.orderItemVos.concat(res.data.data.orderGiftResVos);

                    this.sonOrders = res.data.data.sonOrders;
                    console.log("数组长度,", this.sonOrders.length);
                    this.sonOrders.forEach(item => {
                        this.setTotal(item);
                        item.orderGiftResVos.forEach(item => {
                            item.isComplimentary = true;//给赠品对象赋值标记
                        });
                        item.orderItemVos = item.orderItemVos.concat(item.orderGiftResVos);
                    });

                    this.setTotal(this.orderInfoObj);
                }, 0, 0, (res) => {

                }, this.loading);
            },

            setTotal(tableData) {
                tableData.totalSubsidyOne = 0;
                tableData.totalSubsidyTwo = 0;
                tableData.orderItemVos.forEach(item => {
                    tableData.totalSubsidyOne = tableData.totalSubsidyOne + item.subsidyOne;
                    tableData.totalSubsidyTwo = tableData.totalSubsidyTwo + item.subsidyTwo;
                })
            }
        }
    }
</script>

<style lang="less">
    #superBOrderDetails {
        padding-top: 1px;
        .flex {
            display: flex;
        }

        .total {
            padding: 30px 60px 30px 0;
            border: 1px solid #DDDDDD;
            border-top: none;
            box-sizing: border-box;

            .payment {
                color: #D24C4C;
            }

            span {
                display: inline-block;
                /*width: 150px;*/
                text-align: left;
            }

            ul li {
                margin-bottom: 5px;
            }
        }

        .aa {
            padding-left: 30px;
            margin-left: 30px;
            margin-right: 30px;
            padding-bottom: 15px;
            padding-top: 15px;
            border: 1px solid #DDDDDD;
        }

        .el-dialog {
            height: 300px !important;
            min-height: 300px !important;
        }


        .bl {
            border-left: 1px solid #DDDDDD;
        }

        .order-info {
            margin-top: 30px;
            border: 1px solid #DDDDDD;
            box-sizing: border-box;

            .title {
                background-color: #EEEEEE;
                height: 40px;
                line-height: 40px;
                padding-left: 20px;
            }

            .el-form-item__label {
                padding: 0 20px 0 0;
                line-height: 14px;
                height: 14px;
                width: 145px;
            }

            .el-form-item__content {
                line-height: 14px;
            }

            .el-form {
                margin-top: 30px;
            }

            .cancelOrderDialog {
                .el-form {
                    width: 440px;
                    min-width: 440px;
                }
            }

            .gift {
                .el-form-item {
                    .el-form-item__label {
                        float: left;
                        line-height: 20px;
                    }

                    .el-form-item__content {
                        float: left;
                        width: 700px;
                        line-height: 20px;
                    }
                }
            }

        }

        .el-table {
            overflow: hidden;
            max-width: 100%;
            background-color: #fff;
            border: 1px solid #dfe6ec;
            font-size: 12px;
            color: #1f2d3d;
            td{
                .cell {
                    padding: 10px 30px;
                }
            }

        }

        .el-form-item__error {
            margin-left: 190px !important;
        }

        .specialD {
            position: relative;
            display: flex;
            justify-content: space-between;

            .row-item-1 {
                width: 120px;
                height: auto;

                .bw {
                    word-wrap: break-word;
                }
            }
        }

        .special {
            position: absolute;
            top: 0;
            left: 0;
        }

        .summary {
            .summary_title {
                width: 115px !important;
            }

            .summary_money {
                min-width: 200px;
            }
        }
    }
</style>