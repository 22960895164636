<template>
    <div id="goodsDetails" class="bg-f">
        <div class="psOrderDetails">
            <div v-loading='loading' class="pb-50">
                <div class="order-info clearfix mb-50 ml-30 mr-30">
                    <div class="flex">
                        <el-form class='flex-1'>
                            <el-form-item label="商品图片:">
                                <div>
                                    <img style="width: 100px; height: 100px" v-if='orderInfoObj.defaultPicture'
                                         :src="orderInfoObj.defaultPicture"/>
                                    <img style="width: 100px; height: 100px" v-else src="../../assets/goods.jpg"/>
                                </div>
                            </el-form-item>
                            <el-form-item label="商品名称:">
                                {{orderInfoObj.spuName}}
                            </el-form-item>
                            <el-form-item label="K3商品编码:">
                                {{orderInfoObj.k3GoodsNo}}
                            </el-form-item>
                            <div class="clear">
                                <div class="fl" style="width: 500px;">
                                    <el-form-item label="分类:">
                                        {{orderInfoObj.classifyName}}
                                    </el-form-item>

                                    <el-form-item label="包装规格:">
                                        {{orderInfoObj.specInfoNum}}
                                    </el-form-item>

                                    <el-form-item label="计量单位:">
                                        {{orderInfoObj.units}}
                                    </el-form-item>

                                    <el-form-item label="产地:">
                                        {{orderInfoObj.productAreaName}}
                                    </el-form-item>

                                    <el-form-item label="建议零售价:">
                                        ￥{{orderInfoObj.msrp | fmoney}}
                                    </el-form-item>

                                    <el-form-item class="fl" label="图文详情:">
                                        <div v-html="orderInfoObj.illustrationContent" class="ml">

                                        </div>
                                    </el-form-item>
                                </div>
                                <div class="fl" style="width: 500px;">
                                    <el-form-item label="条形码:">
                                        {{orderInfoObj.citm}}
                                    </el-form-item>
                                    <el-form-item label="商品编码:">
                                        {{orderInfoObj.spuNo}}
                                    </el-form-item>
                                    <el-form-item label="品牌:">
                                        {{orderInfoObj.brandName}}
                                    </el-form-item>
                                    <el-form-item label="包装:">
                                        {{orderInfoObj.specInfo}}
                                    </el-form-item>
                                    <el-form-item>
                                    </el-form-item>
                                </div>

                            </div>

                            <!--<el-form-item v-for="(item,index) in form.parameterList" :key="item.key"-->
                            <!--v-if="item.inputType == 1" class='fl' :label="item.spuParameterName +　':'"-->
                            <!--:rules="spuProp(item.characterType)"-->
                            <!--:prop="'parameterList.' + index + '.spuParameterValue'">-->
                            <!--{{item.spuParameterValue}}-->
                            <!--</el-form-item>-->
                            <!--<el-form-item v-else-if="item.inputType==3" class='fl' :label="item.spuParameterName +　':'">-->
                            <!--<el-select size="small" placeholder="请选择参数" clearable-->
                            <!--v-model="item.spuParameterOptionId">-->
                            <!--<el-option v-for="(v,index) in item.optionList" :label="v.optionName" :value="v.id"-->
                            <!--:key='index'></el-option>-->
                            <!--</el-select>-->
                            <!--</el-form-item>-->
                        </el-form>
                    </div>


                </div>

            </div>
        </div>
    </div>
</template>

<script>
    import {axiosApi, basePath} from "../../api/api"

    export default {
        data() {
            return {
                orderInfoObj: {},
                loading: false,
            }
        },
        mounted() {
            this.$nextTick(() => {
                this.anonOrderDetailInfo() //订单详情
            })
        },
        methods: {
            anonOrderDetailInfo() { //订单详情接口
                this.loading = true;
                axiosApi('/goods/spuInfo/select', {
                    spuNo: this.$route.query.spuNo
                }, (res) => {
                    this.orderInfoObj = res.data.data;
                    this.loading = false
                }, 1, 10, (res) => {
                    this.loading = false
                })
            },
        }
    }
</script>

<style lang="less">
    #goodsDetails {
        .flex {
            display: flex;
        }

        .ml {
            margin-left: 146px;
        }

        .bl {
            border-left: 1px solid #DDDDDD;
        }

        .order-info {
            margin-top: 30px;
            border: 1px solid #DDDDDD;
            box-sizing: border-box;

            .title {
                background-color: #EEEEEE;
                height: 40px;
                line-height: 40px;
                padding-left: 20px;
            }

            .el-form-item__label {
                padding: 0;
                line-height: 14px;
                height: 14px;
                padding-right: 20px;
                width: 145px;
            }

            .el-form-item__content {
                line-height: 14px;
            }

            .el-form {
                margin-top: 30px;
            }

            .cancelOrderDialog {
                .el-form {
                    width: 440px;
                    min-width: 440px;
                }
            }

            .gift {
                .el-form-item {
                    .el-form-item__label {
                        float: left;
                        line-height: 20px;
                    }

                    .el-form-item__content {
                        float: left;
                        width: 700px;
                        line-height: 20px;
                    }
                }
            }

        }

    }
</style>
