<template>
	<div id="editRole">
		<div class="mt-50 ml-100">
			<div class='mb-30 ml-50 clearfix'>
				<label class="color-3 fl mt-5">角色名称：</label>
				<div class="fl c">
					<el-input v-model.trim='roleName' @change="change()"></el-input>
					<p class='color-danger font-12 cc d width-230' :class={dd:disabled}>请输入1-16位角色名称！</p>
				</div>
				<el-button class='ml-20 radius e-100 fl' @click='getRoleSelectAll' :disabled="disabled">修改角色名称</el-button>
			</div>
				<el-form class="mb-50 ml-50 clearfix width-640" v-for="(item,index1) in list" :key="index1">
					<fieldset class="bd">
						<legend class="ml-10 pl-20 pr-20">
							<el-checkbox class='mr-40 mb-10' v-model="item.checkAll" @change="all(item)">{{item.text}}</el-checkbox>
						</legend>
						<div v-for='(items,index1) in item.sub' :key="index1">
							<el-checkbox class="color-3 mr-30 fl mb-10 mt-10 ml-15 w-600" v-model="items.checked" :label="items.id" @change="handleCheckAllChange(item,items)">{{items.text}}</el-checkbox>
							<span class="fl ml-30">
															<el-checkbox-group v-model="items.checkedAreas">
																	<div v-for='(itemss,index) in items.sub' :key="index+'s'">
																			<el-checkbox class='w-600 mb-10 hides' v-model="itemss.checked"  v-if="!isCityPartner||itemss.id!='200158'" :title="itemss.text" :key="itemss.id" :label="itemss.id" @change="handleCheckedCitiesChange($event,item,items,itemss)">{{itemss.text}}</el-checkbox>
																			<span class="fl ml-30">
																					<el-checkbox-group v-model="itemss.checkedAreas2">
																							<el-checkbox class='width-110 mb-10 hides' v-model="itemsss.checked" v-if="!isCityPartner||itemsss.id!='200158'" :title="itemsss.text" v-for='itemsss in itemss.sub' :key="itemsss.id" :label="itemsss.id" @change="handleCheckedCitiesChange2($event,item,items,itemss,itemsss,index,index1)">{{itemsss.text}}</el-checkbox>
																					</el-checkbox-group>
																			</span>
																	</div>
								</el-checkbox-group>
													</span>
						</div>

					</fieldset>
				</el-form>
			<div class="ml-50 btnDivClass">
				<div class="btnDivClassFixed">
					<el-button type="primary" @click.native='addInfo()'>修改权限</el-button>
					<el-button @click.native='goPage()'>取消</el-button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { MessageBox } from 'element-ui';
	import { axiosApi, basePath } from "~/api/api";
	import { getRoleSelectAll } from "~/api/axiosInfo";
	export default {
		data() {
			return {
				text: '禁用此角色',
				txt: '',
				checkedAreas: [],
				id: "",
				list: [],
				dialogAreaVisible: false,
				disabled: true,
				disab: false,
				onelist: [],
				roleName: '',
				resources: [], //资源绑定
				isdisplay: 1,
				bool: false,
				flag: 0,
				flag1: 0,
				arr:[],
				path: { path: basePath + "/roleLimits" },
				roleList: [],
			}
		},
        computed:{
            isCityPartner() {
                return this.$store.state.clientType.isCityPartner;
            }
        },
		methods: {
			//页面跳转
			goPage() {
				//				this.$router.push(basePath + '/limits');
				history.go(-1)
			},
			//判断按钮是否禁用
			change() {
				if(this.roleName.length > 0 && this.roleName.length < 17) {
					this.bool = true;
				} else {
					this.bool = false;
				}
				if(this.bool) {
					this.disabled = false;
				} else {
					this.disabled = true;
				}
			},
			//			获取所有权限
			achieveList() {
				this.roleName = this.$route.params.text //获取初始角色名
				const api = "/foundation/resource/alldisplaytree";
				const body = { loginName: sessionStorage.loginName, appId: 2 }
				axiosApi(api, body, res=>{
					this.list = res.data.data;
					this.checked();
					this.achieveOneList();
				}, this.currentPage, 10)
			},
			getRoleSelectAll(){
				// 获取全部，验证角色名是否重复
				var data = {
					applicationType: 2,
					traderId: sessionStorage.traderId
				}
				getRoleSelectAll(data).then(res=>{
					this.roleList = res
					this.updateRole()
				}).catch(err=>{

				})
			},
			//修改角色名称
			updateRole() {
				var isRepeat = this.roleList.some(item=>{
					return (item.id != this.$route.params.id && item.text === this.roleName)
				})
				if (isRepeat) {
					this.$message({
						type: 'warning',
						message: '角色名重复!',
						duration: 1000
					});
					return
				}
				const api = "/foundation/role/update";
				const body = {
					id: this.$route.params.id,
					roleName: this.roleName,
					applicationType: 2
				}
				axiosApi(api, body, this.updateRole1, this.currentPage, 10, (res)=>{
					this.$message({
						type: 'warning',
						message: res.data.message,
						duration: 1000
					});
				})
			},
			updateRole1(res) {
				this.$message({
					title: '成功',
					message: '恭喜你，修改成功！',
					type: 'success'
				});
			},
			firstPush() {
				if(this.$route.params.isDisplay == 0) {
					this.flag = 0
					this.flag1 = 0
					this.text = '启用此角色'
					this.txt = '启用'
				} else {
					this.flag = 1
					this.flag1 = 1
					this.text = '禁用此角色'
					this.txt = '禁用'
				}
			},
			secondPush() {
				if(this.flag == 0) {
					this.text = '禁用此角色'
					this.txt = '禁用'
					this.flag++
				} else {
					this.text = '启用此角色'
					this.txt = '启用'
					this.flag = 0
				}
			},
			//			判断isDisplay值
			first() {
				if(this.flag1 == 0) {
					this.isdisplay = 1
					this.flag1++
				} else {
					this.isdisplay = 0
					this.flag1 = 0
				}
				this.Isdisplay()

			},
			Isdisplay() {
				this.$confirm('是否' + this.txt + '角色「' + this.$route.params.text + '」？', '提示信息', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					const api = "/foundation/role/update";
					const body = {
						id: this.$route.params.id,
						isDisplay: this.isdisplay
					};
					axiosApi(api, body, (res) => {
						this.$message({
							type: 'success',
							message: '修改成功!',
							duration: 1000
						});
						this.secondPush()
					}, this.currentPage, 10, (res) => {
						this.$notify({
							title: '警告',
							message: res.data.message,
							type: 'warning'
						});
					});
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消修改'
					});
				})
			},
			//			单个角色权限
			achieveOneList() {
				const api = "/foundation/roleresourcerelation/selectList";
				const body = {
					roleId: this.$route.params.id
				}
				axiosApi(api, body, res=>{
					this.onelist = res.data.data;
				this.add();
				}, this.currentPage, 10)
			},
			checked() { //属性赋值
                let _this = this;
                this.list.forEach(function(item, index) {
                    if(typeof item.checkAll == "undefined" || item.checkAll == null) {
                        _this.$set(item, "checkAll", false)
                    }
                    if(typeof item.allAreas == "undefined") {
                        _this.$set(item, "allAreas", [])
                    }
                    if(item.sub) {
                        for(let i = 0; i < item.sub.length; i++) {
                            if(typeof item.sub[i].checked == "undefined" || item.sub[i].checked == null) {
                                _this.$set(item.sub[i], "checked", false)
                            }
                            if(typeof item.sub[i].checkedAreas == "undefined") {
                                _this.$set(item.sub[i], "checkedAreas", [])
                            }
                            if (item.sub[i].sub.length>0){
                                for (let j = 0; j < item.sub[i].sub.length; j++) {
                                    if(typeof item.sub[i].sub[j].checked == "undefined" || item.sub[i].sub[j].checked == null) {
                                        _this.$set(item.sub[i].sub[j],"checked", false)
                                    }
                                    if(typeof item.sub[i].sub[j].checkedAreas2 == "undefined") {
                                        _this.$set(item.sub[i].sub[j], "checkedAreas2", [])
                                    }
                                    if (item.sub[i].sub[j].sub.length > 0){
                                        for (let k = 0; k < item.sub[i].sub[j].sub.length; k++) {
                                            if(typeof item.sub[i].sub[j].sub[k].checked == "undefined" || item.sub[i].sub[j].sub[k].checked == null) {
                                                _this.$set(item.sub[i].sub[j].sub[k],"checked", false)
                                                console.log(123,item.sub[i].sub[j].sub[k], item.sub[i].sub[j].sub[k].checked);
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                })
                console.log(this.list);
			},
			add() { //之前选择的渲染
				let _this = this
				this.onelist.forEach(function(item, index) {
					_this.list.forEach(function(item1, index1) {	
						if(item.resourceId == item1.id){
							item1.checkAll = true
						}
						if(item1.sub) {						
							for(let i = 0; i < item1.sub.length; i++) {								
								if(item.resourceId == item1.sub[i].id) {
									item1.sub[i].checked = true
								}
								if(item1.sub[i].sub) {
									for(let j = 0; j < item1.sub[i].sub.length; j++) {
										if(item.resourceId == item1.sub[i].sub[j].id) {
                                            item1.sub[i].sub[j].checked = true
											let checkedAreas = [];
											checkedAreas.push(item.resourceId)
											item1.sub[i].checkedAreas = item1.sub[i].checkedAreas.concat(checkedAreas);
										}
										if (item1.sub[i].sub[j].sub){
                                            console.log(item1.sub[i].sub[j].sub,270);
                                            for(let k = 0; k < item1.sub[i].sub[j].sub.length; k++) {
                                                if(item.resourceId == item1.sub[i].sub[j].sub[k].id) {
                                                    item1.sub[i].sub[j].sub[k].checked =  true
                                                    console.log(item1.sub[i].sub[j].sub[k],270);
                                                    let checkedAreas = [];
                                                    checkedAreas.push(item.resourceId)
                                                    item1.sub[i].sub[j].checkedAreas2 = item1.sub[i].sub[j].checkedAreas2.concat(checkedAreas);
                                                }
                                            }
                                        }
									}
								} else {
									if(item.resourceId == item1.sub[i].id) {
										_this.resources.push(item.resourceId)
										item1.sub[i].checked = true
									}
								}
							}
						}
					})							
				})
			},
			//一级菜单全选
			all(item) {
				if(item.checkAll && item.sub) {
                    let allAreas = [];
                    item.sub.forEach((item1) => {
                        item1.checked = true;
                        allAreas.push(item1.id)
                        if(item1.sub) {
                            let checkedAreas = [];
                            item1.sub.forEach((item2) => {
                                checkedAreas.push(item2.id)
                                if (item2.sub){
                                    item2.sub.forEach(item3=>{
                                        item3.checked = true
                                        item2.checkedAreas2.push(item3.id)
                                        // if (item3.sub){
                                        //     item3.sub.forEach(item4=>{
                                        //         item3.checkedAreas2.push(item4.id)
                                        //     })
                                        // }
                                    })
                                }
                            })
                            item1.checkedAreas = checkedAreas;
                        }
                    })
                    item.allAreas = allAreas;
				} else {
					item.allAreas = [];
					if(item.sub) {
						item.sub.forEach((item1) => {
							item1.checked = false;
							if(item1.sub) {
								item1.checkedAreas = [];
                                item1.sub.forEach(item2=>{
                                    item2.checked = false
                                    item2.checkedAreas2=[]
                                    if (item2.sub) {
                                        item2.sub.forEach(item3=>{
                                            item3.checked = false
                                        })
                                    }
                                })
							}
						})
					}
				}
			},
			//二级菜单全选
			handleCheckAllChange(item, items) {
				if(items.checked) {
					item.checkAll = true //二级菜单有一个勾选，它对应的一级菜单一定勾选，取消某个二级菜单的勾选，不会影响上级菜单
					if(items.sub) { //二级菜单勾选，三级都选中
						items.sub.forEach(item => {
							items.checkedAreas.push(item.id)
                            if (item.sub){
                                item.checkedAreas2 = []
                                item.sub.forEach(itemss=>{
                                    itemss.checked = true
                                    item.checkedAreas2.push(itemss.id)
                                })
                            }
                        })
					} 
				}else { //二级菜单未勾选，三级都不选中
                    items.checkedAreas = []
                    if (items.sub) {
                        items.sub.forEach(i=>{
                            i.checkedAreas2= []
                        })
                    }
					}
			},
			//			三级菜单全选
            handleCheckedCitiesChange(val,item, items, itemss) {
                itemss.checked = val
                console.log(itemss.checked);
                if (itemss.checked){
                    item.checkAll = true
                    items.checked = true
                    if (itemss.sub) {
                        itemss.checkedAreas2 = []
                        itemss.sub.forEach(i=>{
                            i.checked = true
                            itemss.checkedAreas2.push(i.id)
                        })
                    }
                } else{
                    itemss.checkedAreas2 = []
                }
            },
            //			四级菜单全选
            handleCheckedCitiesChange2(val,item, items, itemss,itemsss,index,index1) {
                itemsss.checked = val
                if (itemsss.checked) {
                    this.$set(items,'checked',true)
                    items.checkedAreas.push(itemss.id)
                    // itemss.checkedAreas2.push(itemsss.id)
                    this.$set(items.sub,index,itemss)
                    this.$set(item.sub,index1,items)
                    console.log(13);
                    items.checked = true
                    itemss.checked = true
                    item.checkAll = true
                    console.log(item.checkAll, items.checked, itemss.checked, itemsss.checked);
                }else{
                    // itemss.checkedAreas2.splice(itemss.checkedAreas2.findIndex(data => data === itemsss.id), 1)
                }
                this.$forceUpdate()
            },
			//点击确定提交数据
			addInfo(){
				let arr = []
				this.list.forEach(item=>{
					if(item.checkAll){
						arr.push(item.id)
						if(item.sub){
							item.sub.forEach(item1=>{
								if(item1.checked){
									arr.push(item1.id)
									item1.checkedAreas.forEach(item2=>{
										arr.push(item2)
									})
                                    if (item1.sub){
                                        console.log('item1',item1);
                                        item1.sub.forEach(item2=>{
                                            if (item2.sub){
                                                item2.sub.forEach(item3=>{
                                                    if (item3.checked){
                                                        arr.push(item3.id)
                                                    }
                                                })
                                            }
                                        })
                                    }
								}
							})
						}
					}
				})
				this.arr = arr
				this.resourceBind()
				
			},
			//			资源绑定
			resourceBind() {
				const api = "/foundation/roleresourcerelation/bind";
				const body = {
					roleId: this.$route.params.id,
					resources: this.arr,
					tradeId: 0
				}
				axiosApi(api, body, res=>{
					this.$message({
					title: '成功',
					message: '恭喜你，保存成功！',
					type: 'success'
				});
				this.$router.push(basePath + '/roleLimits')
				}, this.currentPage, 10)
			},

		},
		mounted() {
			this.$nextTick(function() {
				this.achieveList();
				this.change();
				this.firstPush()
			})
		}
	}
</script>

<style lang="less">
	#editRole {
        background-color: #ffffff;
        padding-top: 1px;
		.el-checkbox {
			margin-left: 0;
		}
		.el-checkbox__label {
			display:inline !important;
		}
		.w-600 {
			width: 600px;
			display: block;
		}
		.radius {
			border-radius: 2px;
			width: 80px;
		}
		.e-100 {
			width: 100px;
		}
		.hides {
			overflow: hidden;
			/*自动隐藏文字*/
			text-overflow: ellipsis;
			/*文字隐藏后添加省略号*/
			white-space: nowrap;
		}
		.width-110{
			width: 110px;
		}
		legend label{
			margin-right: 0px !important;
			line-height: initial;
			margin-bottom: 0px !important;
		}
		.c{
			position: relative;
		}
		.cc{
			position: absolute;
			left: 0;
			top:110%
		}
		.d{
			display: none;
		}
		.dd{
			display: inline-block;
		}
		.btnDivClass{
			background:#fff;
			height:90px;
			.btnDivClassFixed{
				height:90px;
				width: 70%;
				background:#fff;
				position: fixed;
				bottom: 0;
				z-index: 2;
				padding:20px 0;
				box-sizing: border-box;
			}
		}
	}
</style>