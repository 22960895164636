<template>
    <div id="repertoryManage">
        <div class="title-top bg-f title-tabs">
            <span>仓库管理</span>
            <div class="fr">
                <el-button class="fr" @click="addRepertory">添加新仓库</el-button>
            </div>
        </div>
        <div class="bg-f mt-5 pb-50 pt-30">
            <el-table v-loading="Loading" :data="tableData" tooltip-effect="dark" class="ml-30 mr-30 mt-20 table-default" stripe>
                <el-table-column prop="companyNo" label="销售组织号" min-width="100">
                </el-table-column>
                <el-table-column prop="no" label="仓库编码" min-width="60">
                </el-table-column>
                <el-table-column prop="name" label="仓库名称" min-width="200">
                    <template slot-scope="scope">
                        <span v-if="scope.row.isMain" class="main-logo">主</span>{{scope.row.name}}
                    </template>
                </el-table-column>
                <el-table-column prop="linkman" label="负责人" min-width="100">
                </el-table-column>
                <el-table-column prop="useLogistics" label="是否经过物流" min-width="100">
                    <template slot-scope="scope">
                        <span>{{scope.row.useLogistics ? '是' : '否'}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="仓库地址" min-width="200">
                    <template slot-scope="scope">
                        {{scope.row.provinceName}}
                        {{scope.row.cityName}}
                        {{scope.row.regionName}}
                        {{scope.row.streetName}}
                        {{scope.row.attr}}
                    </template>
                </el-table-column>

                <el-table-column label="操作" min-width="190">
                    <template slot-scope="scope">
                        <el-button type="text" @click="editRepertory(scope.row)" >编辑</el-button>
                        <el-button type="text" @click="deleteRepertory(scope.row)" v-if="!(scope.row.isMainCompany && scope.row.isMain)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <!-- 添加新仓库弹窗-->
        <el-dialog :close-on-click-modal="false"  :title="stockTitle + '仓库'" :visible.sync="addRepertoryDialog" :before-close="closeDialog" width="730px">
            <div>
                <el-form :model="addRepertoryForm"  :rules="rules" ref="addRepertoryFormRef" label-width='185px' class="pb-20">
                    <el-form-item label="销售组织号:" prop="companyNo">
                        <!-- :disabled="forbidEdit" -->
                        <el-input type="number" v-model.trim ="addRepertoryForm.companyNo"  :max="9999999999" disabled placeholder="请输入销售组织号"></el-input>
                    </el-form-item>
                    <el-form-item label="仓库名称:" prop="name">
                        <el-input v-model.trim ="addRepertoryForm.name" :maxlength="10"  placeholder="请输入仓库名称"></el-input>
                        <el-checkbox v-model="addRepertoryForm.isMain" :disabled="forbidEdit">主仓库</el-checkbox>
                    </el-form-item>
                    <el-form-item label="仓库编码:" prop="no">
                        <el-input v-model.trim ="addRepertoryForm.no" :maxlength="10" :disabled="forbidEdit && noForbidEdit" placeholder="请输入仓库编码"></el-input>

                    </el-form-item>
                    <el-form-item label="是否经过物流" required>
                        <el-radio-group v-model="useLogistics">
                            <el-radio :label="1">是</el-radio>
                            <el-radio :label="0">否</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="仓库地址:" prop="districtIds">
                        <selectArea v-model="addRepertoryForm.districtIds" @changeName="changeName" />
                    </el-form-item>
                    <el-form-item label="详细地址:" prop="attr" class="clearfix">
                        <el-input v-model.trim="addRepertoryForm.attr" :maxlength="40" placeholder="请填写详细地址" ></el-input>
                    </el-form-item>

                    <!--<el-form-item label="K 3仓库:" v-if="isK3" prop="k3WarehouseCompanyNo">
                        <el-select v-model="addRepertoryForm.k3WarehouseCompanyNo" placeholder="请选择K3仓库" :disabled="this.stockTitle === '新增' ? false : true" >
                            <el-option
                                    v-for="item in KThreeRepertoryList"
                                    :key="item.stockNum"
                                    :label="item.stockName"
                                    :value="item.stockNum">
                            </el-option>
                        </el-select>
                    </el-form-item>-->
                    <el-form-item label="负责人:">
                        <el-input v-model.trim="addRepertoryForm.linkman" :maxlength="10" placeholder="请输入负责人"></el-input>
                    </el-form-item>
                    <el-form-item label="联系电话:" prop="tel">
                        <el-input v-model.trim="addRepertoryForm.tel" :maxlength="12" placeholder="请输入联系电话"></el-input>
                    </el-form-item>
                </el-form>
            </div>

            <div slot="footer" class="dialog-footer">
                <el-button :loading="btnLoading" type="primary" @click="addRepertoryClick">确定</el-button>
                <el-button  @click="addRepertoryDialog = false, resetForm()">取消</el-button>
            </div>
        </el-dialog>
        
        <!-- 3.0.0需求-暂时隐藏 -->
        <!-- <el-dialog :visible.sync="departmentShow" title="选择部门">
            <el-table
                ref="departmentList"
                :data="[]"
                tooltip-effect="dark"
                style="width: 100%"
                @selection-change="handleSelectionChange">
                <el-table-column type="selection" width="55"></el-table-column>
                <el-table-column prop="date" label="部门名称"></el-table-column>
                <el-table-column prop="date" label="上级部门"></el-table-column>
            </el-table>
            
        </el-dialog> -->
    </div>
</template>

<script>
    import { axiosApi, basePath } from "../../api/api"

    export default {
        name: "repertoryManage",
        data(){
            var validateAddress = (rule, value, callback) => {
                var info = this.addRepertoryForm.districtIds
                if (info.length===0) {
                    callback(new Error('请选择省市区'));
                } else {
                    if(info[0]==null){
                    callback(new Error('请选择省'));
                    }else if(info[1]===null){
                    callback(new Error('请选择市'));
                    }else if(info[2]===null){
                    callback(new Error('请选择区'));
                    }else{
                    callback();
                    }
                }
            };
            return{
                departmentShow: false,
                useLogistics:0,
                forbidEdit: false,//部分仓库的组织号仓库名等禁止编辑
                noForbidEdit: false,//有可能出现主组织主仓库但是没有仓库编码，所以另加的判断条件
                stockTitle: '',
                tableData:[],
                tableDataNum:0,
                Loading: false,
                btnLoading: false,
                addRepertoryDialog: false,
                isK3:true,
               
                fourListsDelivey:[],
                addRepertoryForm:{
                    name: '',
                    isMain:false,
                    districtIds: [],
                    attr: '',
                    isDefault:false,
                    dispatchingId:[[]],
                    k3WarehouseCompanyNo:'',
                    linkman:'',
                    tel:'',
                    useLogistics :1,
                    companyNo:0,
                },
                KThreeRepertoryList: [],
                rules:{
                    companyNo: [
                        { required: true, message: '请填写销售组织号', trigger: 'blur' },
                        {min: 1, max: 10, message: '请正确填写销售组织号,1到10位数字', trigger: 'blur'}
                    ],
                    name: [
                        { required: true, message: '请输入仓库名称', trigger: 'blur' },
                        { min: 1, max: 10, message: '请正确填写，仓库名称1-10个字符', trigger: 'blur' }
                    ],
                    no: [
                        { required: true, message: '请填写仓库编号', trigger: 'blur' },
                        {min: 1, max: 10, message: '请正确填写销售组织号', trigger: 'blur'},
                        {pattern: /^[a-zA-Z0-9]{1,10}$/, message: '请正确填写销售组织号', trigger: 'blur'},
                    ],
                    districtIds: [
                        { validator: validateAddress, trigger: 'change' },
                    ],
                    // attr: [
                    //     { required: true, message: '请正确填写地址，2-60个字符', trigger: 'blur' },
                    //     { min: 2, max: 60, message: '请正确填写地址，2-60个字符', trigger: 'blur' }
                    // ],
                    // streetId: [{
                    //     validator: streetId
                    // }],
                    dispatchingId: [
                        {type: 'array', required: true, message: '请选择地区', trigger: 'blur'}
                    ],
                    k3WarehouseCompanyNo: [
                        { required: true, message: '请选择k3仓库', trigger: 'blur'}
                    ],
                    linkman: [
                        { min: 2, max: 20, message: '请正确填写，2-20个字符', trigger: 'blur'}
                    ],
                    tel: [
                        { pattern: /^[0-9]{7,12}$/, message: '请正确填写电话号码7-12位数字', trigger: 'blur'}
                    ]
                },
                areaNameList: [], // 地址名称
            }
        },
        methods:{
            changeName(e){
			    this.areaNameList = e
		    },
            resetForm() { //清空验证方法
                this.$refs['addRepertoryFormRef'].resetFields();
            },
            closeDialog(done) {
                this.resetForm()
                done()
            },
            addRepertory() {
                if(this.tableDataNum>15){
                    this.$message({
                        message:'指定仓库上限15个',
                        type:'info'
                    })
                    return;
                }
                this.stockTitle = '新增'
                this.forbidEdit = false
                this.addRepertoryDialog = true
                axiosApi('/trader/findTraderInfoBySerllerId/select',{traderId:sessionStorage.traderId},res=>{
                    this.addRepertoryForm = {
                        isMain: false,
                        streetId:'',
                        streetName: '',
                        dispatchingId: [[]],
                        districtIds: [],
                        name: '',
                        provinceId: '',
                        cityId: '',
                        regionId: '',
                        provinceName: '',
                        cityName: '',
                        regionName: '',
                        attr: '',
                        isDefault: this.tableData.length == 0 ? true : false,
                        linkman: '',
                        tel: '',
                        k3WarehouseCompanyNo: '',
                        id: null,
                        companyNo:res.data.data.k3WarehouseCompanyNo
                    }
                })
                
            },
            addRepertoryClick() {
                this.$refs.addRepertoryFormRef.validate(valid => {
                    if(valid) {
                        this.btnLoading = true
                        let param = {
                            isMain: this.addRepertoryForm.isMain,
                            main: this.addRepertoryForm.isMain,
                            companyNo: this.addRepertoryForm.companyNo,
                            no:this.addRepertoryForm.no,
                            name: this.addRepertoryForm.name,
                            provinceId: this.addRepertoryForm.districtIds[0],
                            cityId: this.addRepertoryForm.districtIds[1],
                            regionId: this.addRepertoryForm.districtIds[2],
                            streetId: this.addRepertoryForm.districtIds[3],
                            provinceName: this.areaNameList[0],
                            cityName: this.areaNameList[1],
                            regionName: this.areaNameList[2],
                            streetName: this.areaNameList[3],
                            attr: this.addRepertoryForm.attr,
                            linkman: this.addRepertoryForm.linkman,
                            tel: this.addRepertoryForm.tel,
                            useLogistics: this.useLogistics
                        }
                        let api = this.stockTitle === '新增' ? '/trader/sellerK3SuperHouseNo/update' : '/trader/sellerK3SuperHouseNo/update'
                        axiosApi('/trader/findSellerJurisdiction/select', {traderId: sessionStorage.traderId}, (res)=>{
                            if (this.addRepertoryForm.companyNo == res.data.data.k3SalesCompanyNo) {
                                param.isMainCompany = true
                                param.mainCompany = true
                            }else{
                                param.isMainCompany = false
                                param.mainCompany = false
                            }
                            if(this.stockTitle === '新增'){
                                param.actionType = 'add'
                            }else{
                                param.k3WarehouseCompanyNo = this.addRepertoryForm.k3SalesCompanyNo
                                param.k3WarehouseNo = this.addRepertoryForm.k3WarehouseNo
                                param.actionType = 'update'
                                param.originalWarehouseNo = this.addRepertoryForm.originalWarehouseNo
                                param.originalWarehouseName = this.addRepertoryForm.originalWarehouseName
                            }
                            axiosApi(api, param, (res)=>{
                                this.addRepertoryDialog = false;
                                setTimeout(() => {
                                    this.btnLoading = false
                                }, 300)
                                this.$message.success(this.stockTitle + '成功！')
                                this.getRepertoryList()
                            }, 1, 10, err => {
                                this.btnLoading = false
                                this.$message.error(err.data.message)
                            })
                        }, 1, 10, err => {
                            this.$message.error(err.data.message)
                        })

                    }
                })
            },
            editRepertory(val) {
                    if (val.isMainCompany && val.isMain){
                        this.forbidEdit = true
                    }else{
                        this.forbidEdit = false
                    }
                    const data = val
                    const districtIds = [].concat(data.provinceId ? data.provinceId.toString() : null ,data.cityId ? data.cityId.toString() : null ,data.regionId ? data.regionId.toString() : null,data.streetId ? data.streetId.toString() : null)
                    this.addRepertoryForm = {
                        ...this.addRepertoryForm,
                        originalWarehouseNo:data.no,
                        originalWarehouseName:data.name,
                        isMain:data.isMain,
                        k3SalesCompanyNo:data.companyNo,
                        k3WarehouseNo:data.no,
                        districtIds: districtIds,
                        name: data.name,
                        companyNo:data.companyNo,
                        no:data.no,
                        attr: data.attr,
                        isDefault: data.isDefault == 1 ? true : false,
                        linkman: data.linkman,
                        tel: data.tel,
                        k3WarehouseCompanyNo: data.k3WarehouseCompanyNo,
                        id: data.id,
                       
                    }
                    console.log(data)
                    this.useLogistics=data.useLogistics?1:0
                    if (this.addRepertoryForm.no){
                        this.noForbidEdit = true
                    }else{
                        this.noForbidEdit = false
                    }
                 
                    this.addRepertoryDialog = true;
                    this.stockTitle = '编辑';
            },
            deleteRepertory(val){
                this.$confirm('确定要删除该仓库？', '删除仓库', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    axiosApi('/trader/sellerK3SuperHouseNo/delete',{
                        k3SalesCompanyNo: val.companyNo,
                        k3WarehouseNo: val.no,
                    },(res)=>{
                        this.$message.success('删除成功')
                        this.getRepertoryList()
                    },1,10,err=>{
                        this.$message.error(err.data.message)
                    })
                }).catch(() => {

                });
            },
           
            getRepertoryList() {
                axiosApi('/trader/k3WarehouseInfo/select',{traderId: sessionStorage.getItem("traderId")},(res)=>{
                    this.tableData = res.data.data ? res.data.data : []
                    this.tableDataNum = this.tableData.length
                })
            }
        },
        created(){
            this.getRepertoryList()
        },

    }
</script>

<style lang="less">
#repertoryManage{
    .fr{
        float: right;
    }
    .el-dialog{
        width: 720px;
    }
    .main-logo{
        background-color: red;
        border-radius: 3px;
        padding:2px 3px;
        margin-right: 3px;
        color:#FFF;
    }
}
</style>
