<template>
  <div id="addressAdd">
    <el-dialog :title="titles" :visible.sync="visible" @close="goPage" width="800px">
      <el-form :model="addressForm" :rules="rules" ref="aForm" style="width:50%;margin:0 auto;" label-width="auto">
        <el-form-item label="收货人:" prop="linkman">
          <el-input v-model.trim="addressForm.linkman"></el-input>
        </el-form-item>
        <el-form-item label="联系电话:" prop="tel">
          <el-input :maxlength="11" v-model.trim="addressForm.tel"></el-input>
        </el-form-item>
        <el-form-item label="收货地址:" prop="selectedOptions" required>
          <selectArea v-model="addressForm.selectedOptions" @changeName="changeName" />
        </el-form-item>
        <el-form-item prop="addr">
          <el-input v-model.trim="addressForm.addr" placeholder="详细地址"></el-input>
        </el-form-item>
        <el-form-item v-if="addressForm.isDefault != 1">
          <el-checkbox v-model="isDefault"></el-checkbox><span class="ml-10">设为默认地址</span>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="sure" :loading="btnloading"
          >确定</el-button
        >
        <el-button @click="goPage">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { axiosApi, basePath } from "../../api/api";
export default {
  props: ["addressVisible", "title", "jdAddress"],
  data() {
    var validateAddress = (rule, value, callback) => {
      var info = this.addressForm.selectedOptions
      if (info.length===0) {
        callback(new Error('请选择省市区'));
      } else {
        if(info[0]==null){
          callback(new Error('请选择省'));
        }else if(info[1]===null){
          callback(new Error('请选择市'));
        }else if(info[2]===null){
          callback(new Error('请选择区'));
        }else{
          callback();
        }
      }
    };
    return {
      fourLists: [], //四级地址
      btnloading: false, //确认按钮点击状态
      titles: "",
      isDefault: false,
      visible: false,
			areaNameList: [],
			addressForm:{
				selectedOptions: []
			},
      rules: {
        linkman: [
          {
            required: true,
            pattern: /^([A-Za-z]|[\u4E00-\u9FA5]){2,16}$/,
            message: "请输入2到16位汉字或者字母",
          },
        ],
        tel: [
          { required: true, message: "联系电话不能为空", trigger: "change" },
          {
            pattern: /^((0\d{2,3}-?\d{7,8})|(\d{7,8})|(1\d{10}))$/,
            trigger: "change",
            message: "电话号或手机号格式有误",
          },
        ],
        selectedOptions: [
          { validator: validateAddress, trigger: 'change' },
        ],
        // streetId:[{
        // 	required: true,
        // 	message: '请选择街道',
        // }],
        attr: [
          {
            required: true,
            message: "请输入详细地址",
          },
          {
            min: 4,
            max: 50,
            message: "请输入4-50个字符",
          },
        ],
        addr: [
          {
            required: true,
            message: "请输入详细地址",
          },
          {
            min: 4,
            max: 50,
            message: "请输入4-50个字符",
          },
        ],
      },
    };
  },
  watch: {
    addressVisible(value) {
      this.visible = value;
      if (value) {
        this.btnloading = false;
      }
    },
    title: {
      handler: function (val) {
        if (val == "edit") {
          this.titles = "编辑收货地址";
        } else if (val == "add") {
          this.titles = "添加收货地址";
        }
      },
      immediate: true,
    },
    "addressInfo": {
      handler: function (val) {
				this.addressForm = val
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    addressInfo() {
      this.isDefault = this.$store.state.address.item.isDefault == 1;
      return this.$store.state.address.item;
    },
  },
  methods: {
		changeName(e){
			this.areaNameList = e
		},
    goPage() {
      this.$emit("closedialog");
    },
    sure() {	
      this.$refs.aForm.validate((valid) => {
        this.btnloading = true;
        if (valid) {
          var ids = this.addressForm.selectedOptions
          let body = {
            id: this.addressForm["id"] ? this.addressForm["id"] : "",
            traderId: sessionStorage.traderId,
            linkman: this.addressForm["linkman"],
            tel: this.addressForm["tel"],
            isDefault: this.isDefault ? 1 : 0,
            addressType: 3,

						provinceId: ids[0],
            cityId: ids[1],
            regionId: ids[2],
						streetId: ids[3],

            provinceName: this.areaNameList[0],
            cityName: this.areaNameList[1],
            regionName: this.areaNameList[2],
						streetName: this.areaNameList[3],

            addr:this.addressForm.addr
					};
          
          let api = "";
					if(this.jdAddress){
						if (this.addressForm["id"]) {
							api =  '/trader/address/update'
						}else{
							api = '/trader/address/add'
						}
					}else{
						if (this.addressForm["id"]) {
							api = "/trader/address/update"
						}else{
							api = "/trader/address/add"
						}
					}
						axiosApi('/trader/address/audit', body, res => {
							if(res.data.data&&res.data.data.message){
									this.$confirm(res.data.data.message, '提示', {
									confirmButtonText: '确定',
									cancelButtonText: '取消',
									type: 'warning'
								}).then(() => {
									this.editAddress(api,body)
								}).catch(() => {
									this.btnloading = false;
									this.$message({
										type: 'info',
										message: '已取消'
									});          
								});
							}else{
								this.editAddress(api,body)
							}
						},null,null,err=>{
							this.goPage();
							this.$message.error(res.data.message)
						})
					} else {
						this.btnloading = false;

						return false;
					}
				})
			},
			editAddress(api, body){
				axiosApi(api, body, res => {
					this.goPage();
				},null,null,res=>{
					this.goPage();
					this.$message.error(res.data.message)
				})
			},
		}
	}
</script>

<style lang="less">
#addressAdd {

 
  .el-button {
    width: 90px;
    height: 32px;
  }
}
</style>
