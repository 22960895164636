<template>
	<div id="accountCenter">
		<!-- 卖家 -->
		<storeCenter v-if="isHotel"/>
		<!-- 买家 -->
		<hotelCenter v-else/>
	</div>
</template>

<script>
import hotelCenter from './hotelCenter.vue'//买家页面
// import storeCenter from './homePage.vue'
import storeCenter from './newCenter.vue'//卖家页面
// import hotelCenter from '../hotel/hotelCenter.vue'
import {axiosApi} from '~/api/api.js';
	export default {
		components: { storeCenter, hotelCenter },
		data() {
			return {
				isHotel: sessionStorage.getItem('supplierType') != 0
			}
		},
		mounted(){
			 axiosApi(
        "/trader/findStoreInfo/select",
        { traderId: sessionStorage.traderId },
        (res) => {
          if (res.data && res.data.data) {
            sessionStorage.setItem("sellerId", res.data.data.sellerId); //
            sessionStorage.setItem(
              "haveSellerCode",
              res.data.data.haveSellerCode
            ); //是不是公司 true 是 false 否
            sessionStorage.setItem("headCompany", res.data.data.headCompany); //是不是总公司 true 是 false 否
            sessionStorage.setItem(
              "sellerSupplierType",
              res.data.data.sellerSupplierType === 4
            ); //上级是否是城市拍档
            if (this.$store.state.clientType.isDealerUser) {
              //经销商
              sessionStorage.setItem(
                "provinceId",
                res.data.data.sellerDeliveryAddressVO
                  ? res.data.data.sellerDeliveryAddressVO.provinceId
                  : ""
              ); //公司所在区域的省的Id
              sessionStorage.setItem(
                "cityId",
                res.data.data.sellerDeliveryAddressVO
                  ? res.data.data.sellerDeliveryAddressVO.cityId
                  : ""
              ); //公司所在区域的省的Id
            } else {
              sessionStorage.setItem(
                "provinceId",
                res.data.data.deliveryAddressVO.provinceId
              ); //公司所在区域的省的Id
              sessionStorage.setItem(
                "cityId",
                res.data.data.deliveryAddressVO.cityId
              ); //公司所在区域的省的Id
            }
            this.haveSellerCode = res.data.data.haveSellerCode;
          }
        },
        1,
        10
      );
		},
		computed:{
			isCityPartner() {
				return this.$store.state.clientType.isCityPartner;
			}
		}
	}
</script>
