<template>
	<div id="settings">
		<div class="title-tabs bg-f">
			<ul class="clearfix">
				<template v-for='(tab,index) in dataTabs' class="item">
					<li :type='index' :class="type==index?'active':''" @click='handleTabs' :key="index">
						{{tab}}
						<div :class="type==index?'under-line':''"></div>
					</li>
					<div class="vertical-line" :key="'a'+index"></div>
				</template>
			</ul>
			<el-button class="fr mr-30" v-if='typeName =="拜访动作配置"' @click="addAction()">新建类别</el-button>
			<el-button v-if='typeName =="考勤设置"' class="fr mr-30" @click="goPage('add')">新增考勤</el-button>
			<el-button v-if='typeName =="报备类型设置"' class="fr mt-14 mr-30" @click="dialogType=true">新增</el-button>
		</div>
		<div class="content bg-f pl-30 pr-30 mt-5 pb-50 pt-30" v-loading='Loading'>
			<div class="class-one" v-show='typeName =="拜访动作配置"'>
				<el-table :data="tableData" class='table-default' stripe >
					<el-table-column label="序号" prop="actionClassifyOrder" min-width="120">
					</el-table-column>
					<el-table-column label="类别名称" prop="actionClassifyName" min-width="200">
					</el-table-column>
					<el-table-column label="状态" min-width="80">
						<template slot-scope="scope">
							<div v-if="scope.row.actionStatus==0">禁用</div>
							<div v-if="scope.row.actionStatus==1">正常</div>
						</template>
					</el-table-column>
					<el-table-column label="动作数量" prop="count" min-width="100">
					</el-table-column>
					<el-table-column label="操作" min-width="300">
						<template slot-scope="scope">
							<el-button @click="lookDetail(scope.$index,scope.row)" type="text" size="small">查看详情</el-button>
							<el-button v-if='scope.row.actionStatus!=0' @click="forbidden(scope.$index)" type="text" size="small">禁用</el-button>
							<el-button v-if='scope.row.actionStatus==0' class="disabled" @click="forbidden(scope.$index)" type="text" size="small">启用</el-button>
							<el-button class="" @click="addAction(scope.row)" type="text" size="small">编辑</el-button>
						</template>
					</el-table-column>
				</el-table>
				<!-- 新增类别 -->
				<el-dialog :title="name + '类别'" :visible.sync="dialogVisible" size="" @close="closeReset" :class="{small:looksmall}">
					<el-form :model="addData" label-width="130px" ref="actionForm">
						<el-form-item label="类别名称" prop="actionClassifyName" :rules="[
                                              { required: true, message: '请输入名称', trigger: 'blur' },
                                            ]">
							<el-input placeholder="请输入名称" v-model.trim="addData.actionClassifyName" :disabled="disabled" :maxlength="8"></el-input>
						</el-form-item>
						<el-form-item label="类别序号" prop="actionClassifyOrder"  :rules="[
                                              { required: true, message: '请输入序号', trigger: 'blur' },
                                            ]">
							<el-input v-if="disabled" placeholder="请输入序号" v-model.trim="addData.actionClassifyOrder" disabled="disabled"></el-input>
							<sy-input v-if="!disabled" type="int" size="mini" :min="1" :max="20" v-model="addData.actionClassifyOrder" :placeholders="placeholder"></sy-input>
						</el-form-item>
					</el-form>
					<span v-if="!disabled" slot="footer" class="dialog-footer">
                
                <el-button type="danger" @click="submitAdd()" :loading="submitLoading">提交</el-button>
            </span>
				</el-dialog>
			</div>
			<div class="class-two" v-if='typeName =="考勤设置"'>
				<el-table :data="info" border tooltip-effect="dark" stripe >
					<el-table-column label="标题" align="center" prop="configTitle">
					</el-table-column>
					<el-table-column label="考勤起始时间" align="center" prop="signOnTime">
					</el-table-column>
					<el-table-column label="考勤结束时间" align="center" prop="signOutTime">
					</el-table-column>
					<el-table-column label="考勤周期" align="center" show-overflow-tooltip>
						<template slot-scope="scope">
							<span v-for="(item, index) in scope.row.clockWeekList" :key="index">{{item}}</span>
						</template>
					</el-table-column>
					<el-table-column label="考勤人员数" align="center">
						<template slot-scope="scope">
							<el-button type="text" @click="lookClockPeople(scope.row.managerNames)">{{scope.row.managerNum}}</el-button>
						</template>
					</el-table-column>
					<el-table-column label="操作" align="center" width="300">
						<template slot-scope="scope">
							<div>
								<el-button type="text" @click="goPage('edit',scope.row)">编辑</el-button>
								<el-button type="text" class="ml-0" @click="deleteRows(scope.row)">删除</el-button>
							</div>

						</template>
					</el-table-column>
				</el-table>
				<el-pagination v-if="totalCheck>0" @current-change="handleCurrentChangeCheck" class="tar mt-20 mr-50" :current-page="currentPageCheck" :page-size='10' :total='totalCheck' layout="prev , pager, next, jumper">
					<!-- 分页组件 -->
				</el-pagination>
				<el-dialog title="考勤人员" :visible.sync="peopleDialog" @close="peopleDialog = false">
					<span v-for="(p,index) in people" :key="index">{{p}}<span v-if="index < people.length - 1">, </span></span>
				</el-dialog>
			</div>
			<div class="class-three" v-show='typeName =="报备类型设置"'>
				<el-table :data="tableData" stripe tooltip-effect="dark" class="table-default" >
					<el-table-column prop="taskTypeName" label="类型名称" width="110">
					</el-table-column>
					<el-table-column prop="taskTypeDescription" label="类型描述">
					</el-table-column>
					<el-table-column prop="selectNames" label="配置项" width="480">
					</el-table-column>
					<el-table-column label="操作" width="200">
						<template slot-scope="scope">
							<div>
								<el-button @click="editRow(scope.$index, tableData)" type="text" size="small">
									编辑
								</el-button>
								<el-button @click.native.prevent="deleteRow(scope.$index, tableData)" type="text" size="small">
									删除
								</el-button>
							</div>
						</template>
					</el-table-column>
				</el-table>
				<el-pagination class="tar mt-20 mr-50" :current-page="currentPage" :page-size='10' :total='total' layout="prev , pager, next, jumper" v-if="total>0" @current-change="handlePageChange">
					<!-- 分页组件 -->
				</el-pagination>
				<div class="add">
					<el-dialog title="新增报备类型" :visible.sync="dialogType" size="small">
						<div class="box">
							<el-form :model="postData1" label-width="90px" class="clearfix pl-20">
								<el-form-item label="类型名称" required>
									<el-input placeholder="请输入名称" class="w240" v-model.trim="postData1.taskTypeName" :maxlength="15"></el-input>
								</el-form-item>
								<el-form-item label="类型描述"  required>
									<el-input placeholder="请输入描述" class="w240" v-model.trim="postData1.taskTypeDescription" :maxlength="20"></el-input>
								</el-form-item>
								<el-form-item label="设置选项">
									<el-button class="color-danger" @click="addadd(1)">新增</el-button>
								</el-form-item>
								<div class="optionlist">
									<div class="" v-for="(item,index) in postData1.taskTypeSelectList1" :key="index">
										<span class="redrequired ml-10">*</span><span>类型</span>
										<el-select v-model="item.selectType" placeholder="请选择" size="mini">
											<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
											</el-option>
										</el-select>
										<span class="redrequired">*</span><span>选项名</span>
										<el-input v-model.trim="item.selectName" placeholder="请输入选项名" size="small" :maxlength="10"></el-input>
										<span class="ml-10">描述</span>
										<el-input v-model.trim="item.selectDescribe" placeholder="请输入描述" size="small" :maxlength="20"></el-input>
										<span v-if="item.selectType != 3" class="redrequired ml-10">*</span><span v-if="item.selectType != 3">长度/张数</span>
										<sy-input v-if="item.selectType != 3" type="int" size="mini" :min="1" :max="99" v-model="item.selectLength"></sy-input>
										<div class="delete" @click="adddelete(1, index)">
											<div></div>
										</div>
									</div>
								</div>
								<el-form-item label="执行选项">
									<el-button class="color-danger" @click="addadd(2)">新增</el-button>
								</el-form-item>
								<div class="optionlist">
									<div class="" v-for="(item, index) in postData1.taskTypeSelectList2" :key="index">
										<span class="redrequired ml-10">*</span><span>类型</span>
										<el-select v-model="item.selectType" placeholder="请选择" size="mini">
											<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
											</el-option>
										</el-select>
										<span class="redrequired">*</span><span>选项名</span>
										<el-input v-model.trim="item.selectName" placeholder="请输入选项名" size="small" :maxlength="10"></el-input>
										<span class="ml-10">描述</span>
										<el-input v-model.trim="item.selectDescribe" placeholder="请输入描述" size="small" :maxlength="20"></el-input>
										<span v-if="item.selectType != 3" class="redrequired ml-10">*</span><span v-if="item.selectType != 3">长度/张数</span>
										<sy-input v-if="item.selectType != 3" type="int" size="mini" :min="1" :max="99" v-model="item.selectLength"></sy-input>
										<div class="delete" @click="adddelete(2, index)">
											<div></div>
										</div>
									</div>
								</div>
							</el-form>
						</div>
						<span slot="footer" class="dialog-footer">
                        <el-button class="ml-20 mt-50" type="danger" @click="addeventtype" :loading="loading1">确 定</el-button>
                        <el-button class="ml-20 mt-50" @click="dialogType = false">取 消</el-button>
                    </span>
					</el-dialog>
				</div>
				<div class="edit">
					<el-dialog title="编辑报备类型" :visible.sync="dialogEdit" size="small">
						<div class="box">
							<el-form :model="postData2" label-width="90px" class="clearfix pl-20">
								<el-form-item label="类型名称" required>
									<el-input placeholder="请输入名称" class="w240" v-model.trim="postData2.taskTypeName" :maxlength="15"></el-input>
								</el-form-item>
								<el-form-item label="类型描述" required>
									<el-input placeholder="请输入描述" class="w240" v-model.trim="postData2.taskTypeDescription" :maxlength="20"></el-input>
								</el-form-item>
								<el-form-item label="设置选项">
									<el-button class="color-danger" @click="editadd(1)">新增</el-button>
								</el-form-item>
								<div class="optionlist">
									<div v-for="(item,index) in postData2.taskTypeSelectList1" :key="index">
										<span class="redrequired ml-10">*</span><span>类型</span>
										<el-select v-model="item.selectType" placeholder="请选择" size="mini" :disabled="item.disabled">
											<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
											</el-option>
										</el-select>
										<span class="redrequired">*</span><span>选项名</span>
										<el-input v-model.trim="item.selectName" placeholder="请输入选项名" size="small" :maxlength="10"></el-input>
										<span class="ml-10">描述</span>
										<el-input v-model.trim="item.selectDescribe" placeholder="请输入描述" size="small" :maxlength="20"></el-input>
										<span v-if="item.selectType != 3" class="redrequired ml-10">*</span><span v-if="item.selectType != 3">长度/张数</span>
										<sy-input v-if="item.selectType != 3" type="int" size="mini" :min="1" :max="99" v-model="item.selectLength"></sy-input>
										<div class="delete" @click="editdelete(1, index)">
											<div></div>
										</div>
									</div>
								</div>
								<el-form-item label="执行选项">
									<el-button class="color-danger" @click="editadd(2)">新增</el-button>
								</el-form-item>
								<div class="optionlist">
									<div v-for="(item,index) in postData2.taskTypeSelectList2" :key="index">
										<span class="redrequired ml-10">*</span><span>类型</span>
										<el-select v-model="item.selectType" placeholder="请选择" size="mini" :disabled="item.disabled">
											<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
											</el-option>
										</el-select>
										<span class="redrequired">*</span><span>选项名</span>
										<el-input v-model.trim="item.selectName" placeholder="请输入选项名" size="small" :maxlength="10"></el-input>
										<span class="ml-10">描述</span>
										<el-input v-model.trim="item.selectDescribe" placeholder="请输入描述" size="small" :maxlength="20"></el-input>
										<span v-if="item.selectType != 3" class="redrequired ml-10">*</span><span v-if="item.selectType != 3">长度/张数</span>
										<sy-input v-if="item.selectType != 3" type="int" size="mini" :min="1" :max="99" v-model="item.selectLength"></sy-input>
										<div class="delete" @click="editdelete(2, index)">
											<div></div>
										</div>
									</div>
								</div>
							</el-form>
						</div>
						<span slot="footer" class="dialog-footer">
                        <el-button class="ml-20 mt-50" type="danger" @click="editeventtype" :loading="loading1">确 定</el-button>
                        <el-button class="ml-20 mt-50 color-danger" @click="dialogEdit = false">取 消</el-button>
                    </span>
					</el-dialog>
				</div>
			</div>
			<div v-show='typeName =="云销代下单设置"'>
				<el-form ref="form" :model="form" label-width="140px">
					<el-form-item label="是否显示选择赠品:">
						<el-switch
							@change="updateGift"
							active-color="#13ce66"
							v-model="form.openSalesGift"
							active-text="开"
							inactive-text="关">
						</el-switch>
					</el-form-item>
				</el-form>
			</div>
		</div>
	</div>
</template>

<script>
	import { axiosApi, basePath } from "../../api/api";
	import Confirm from '../../utils/formconfirm';
	import { updateGift, getGiftInfo } from "~/api/axiosInfo";
	export default {
		data() {
			return {
				Loading: false,
				type: '0',
				dataTabs: ['拜访动作配置','考勤设置','报备类型设置','云销代下单设置'],
				//拜访动作配置
				placeholder: '请输入序号',
				max: 20,
				maxlength: 2,
				tableData: [],
				dialogVisible: false,
				dialogChange: false,
				value1: '',
				value2: '',
				addData: { //新增手动动作数据
					id: '',
					actionStatus: "",
					actionClassifyName: '',
					actionClassifyOrder: '',
				},
				nowAction: {
					nowActionName: '',
					nowActionOrder: null
				},
				nowIndex: '',
				nowActionStatus: '',
				disabled: false,
				nowContentType: '',
				isEdit: false, //编辑的提交
				newAction: false,
				name: '新建', //默认为新建
				orderok: false,
				orderOpenFirst: 0,
				myactionSize: 'large',
				looksmall: false,
				submitLoading: true,
				countEditFun: 0,
				momentSpecialTypeLength: 0, //点编辑第一时刻有几个类型4和类型5的

				//考勤设置
				info: [],
				currentPageCheck: 1,
				totalCheck: 0,
				pictureLoading: false,
				people: [],
				peopleDialog: false,

				//报备类型设置
				loadingtable: false,
				currentPage: 1,
				total: '',
				tableData: [],
				dialogType: false,
				dialogEdit: false,
				loading1: false,
				editid: null,
				options: [{
						label: '文本',
						value: 1,
					},
					{
						label: '图片',
						value: 2,
					},
					{
						label: '时间',
						value: 3
					}
				],
				postData1: {
					taskTypeName: '',
					taskTypeDescription: '',
					taskTypeSelectList1: [{
						selectName: null,
						selectLength: '',
						selectType: 1,
						excuteType: 0,
						selectDescribe: null
					}],
					taskTypeSelectList2: [{
						selectName: null,
						selectLength: '',
						selectType: 1,
						excuteType: 1,
						selectDescribe: null
					}]
				},
				postData2: {
					taskTypeName: '',
					taskTypeDescription: '',
					taskTypeSelectList1: [{
						selectName: null,
						selectLength: '',
						selectType: 1,
						excuteType: 0,
						selectDescribe: null
					}],
					taskTypeSelectList2: [{
						selectName: null,
						selectLength: '',
						selectType: 1,
						excuteType: 1,
						selectDescribe: null
					}]
				},
				deletelist: [],
				typeName: '',
				form: {
					openSalesGift: false
				}
			}
		},
		watch: {
			nowAction: { //打开编辑并没有编辑文本框数字
				handler() {
					this.orderOpenFirst++
				},
				deep: true
			},
			dialogType(val) {
				if(val) {
					this.postData1 = {
						taskTypeName: '',
						taskTypeDescription: '',
						taskTypeSelectList1: [{
							selectName: null,
							selectLength: '',
							selectType: 1,
							excuteType: 0,
							selectDescribe: null
						}],
						taskTypeSelectList2: [{
							selectName: null,
							selectLength: '',
							selectType: 1,
							excuteType: 1,
							selectDescribe: null
						}]
					}
				}
			}
		},
		methods: {
			handleTabs(e) { //Tab切换
				if(e.target.attributes.type) this.type = e.target.attributes.type.value;
				this.typeName = e.target.attributes.type.ownerElement.innerText.trim()
				sessionStorage.setType = this.type
				sessionStorage.setTypeName = this.typeName
				this.getInfo()
			},
			//新增拜访动作配置
			queryVisitAction() {
				this.Loading = true
				axiosApi('/sales/visitActionClassify/web/selectList', {
					sellerId: sessionStorage.traderId
				}, res => {
					this.tableData = res.data.data
					this.Loading = false
				})
			},
			//修改排序和名称
			addAction(item) { //新建动作
				this.submitLoading = false;
				this.disabled = false;
				this.dialogVisible = true;
				this.name = item ? '编辑' : '新建'
				this.isEdit = item ? true : false
				this.addData.actionClassifyName = item ? item.actionClassifyName : ''
				this.addData.actionClassifyOrder = item ? item.actionClassifyOrder : ''
				this.addData.id = item ? item.id : ''
				this.addData.actionStatus = item ? item.actionStatus : ''
			},
			closeReset() { //新建动作框关闭时候操作
				this.looksmall = false;
				this.$refs.actionForm.resetFields()
				this.startSubmit = false
			},
			submitAdd() { //新建动作提交&&编辑内容提交
				let arr = new Array()
				let newconfirm = new Confirm()
				let confirmdata = this.addData
				newconfirm.use([confirmdata.actionClassifyOrder], '请输入类别序号')
				newconfirm.use([confirmdata.actionClassifyName], '请输入类别名称')
				this.submitLoading = false;
				if(newconfirm.status) {

					// 数据格式有问题，所以必须这里点了提交验证通过就关闭弹窗
					if(this.isEdit) { //编辑
						axiosApi('/sales/visitActionClassify/web/update', {
							id: this.addData.id,
							actionStatus: this.addData.actionStatus,
							actionClassifyName: this.addData.actionClassifyName,
							actionClassifyOrder: this.addData.actionClassifyOrder
						}, res => {
							this.dialogVisible = false; //新建动作
							this.queryVisitAction()
							this.$message({
								type: 'success',
								message: '保存成功',
								duration: 3000
							});
						}, 1, 100, res => {
							this.$message({
								type: 'info',
								message: res.data.message,
								duration: 3000
							});
						})
					} else { //新增
						axiosApi('/sales/visitActionClassify/web/insert', {
							sellerId: sessionStorage.traderId,
							actionClassifyName: this.addData.actionClassifyName,
							actionClassifyOrder: this.addData.actionClassifyOrder
						}, res => {
							this.dialogVisible = false;
							this.queryVisitAction()
							this.$message({
								type: 'success',
								message: '保存成功',
								duration: 3000
							});
						}, 1, 100, res => {
							this.$message({
								type: 'info',
								message: res.data.message,
								duration: 3000
							});
						})
					}

				}
			},
			forbidden(index) {
				const body = {
					id: this.tableData[index].id,
					actionStatus: this.tableData[index].actionStatus == 1 ? 0 : 1
				}
				axiosApi('/sales/visitActionClassify/web/updateStatus', body, res => {
					this.queryVisitAction()
				}, 1, 10, res => {
					this.$message({
						type: 'info',
						message: res.data.message,
						duration: 3000
					});
				})

			},
			lookDetail(index, row) {
				this.$router.push({
					path: basePath + '/visitaction',
					query: {
						id: row.id,
						name: row.actionClassifyName
					}
				})
			},

			//考勤设置方法
			lookClockPeople(val) {
				this.people = val
				this.peopleDialog = true
			},
			goPage(type, item) {
				if(type == 'add') {
					this.$router.push(basePath + "/checkDetail")
				} else if(type == 'edit') {
					this.$router.push(basePath + "/checkDetail/" + item.id)
				}
			},
			handleCurrentChange(val) {
				this.currentPage = val;
				this.querytable();
			},
			handleCurrentChangeCheck(val) {
				this.currentPageCheck = val;
				this.loading();
			},
			loading(bool) {
				if(bool && this.currentPageCheck != 1) {
					this.currentPageCheck = 1;
					return
				}
				this.Loading = true
				this.pictureLoading = true;
				this.info = []
				axiosApi("/sales/clockConfig/selectPageList", {}, res => {
					this.pictureLoading = false;
					this.Loading = false
					if(res.data.data.elements.length > 0) {
						this.info = res.data.data.elements
						this.totalCheck = res.data.data.totalCount
					}
				}, this.currentPageCheck, 10, err => {
					this.Loading = false
					this.pictureLoading = false;
				})
			},
			deleteRows(item) {
				this.$confirm('确定执行该操作？', '提示', {
					confirmButtonText: '确定',
					type: 'warning'
				}).then(() => {
					axiosApi("/sales/clockConfig/delete", {
						id: item.id
					}, res => {
						this.loading(true);
						this.$message({
							type: "success",
							message: "操作成功",
							duration: 1000
						})
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消',
						duration: 1000
					})
				})
			},

			//报备类型设置
			adddelete(x, index) {
				this.$msgbox({
					title: '提示信息',
					message: '确认删除此选项？',
					showCancelButton: true,
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					beforeClose: (action, instance, done) => {
						if(action === 'confirm') {
							if(x == 1) {
								this.postData1.taskTypeSelectList1.length > 1 ? this.postData1.taskTypeSelectList1.splice(index, 1) : this.$notify({
									message: '至少存在一条设置选项',
									type: 'warning'
								})
							} else {
								this.postData1.taskTypeSelectList2.length > 1 ? this.postData1.taskTypeSelectList2.splice(index, 1) : this.$notify({
									message: '至少存在一条执行选项',
									type: 'warning'
								})
							}
							done()
						} else {
							done()
						}
					}
				})
			},
			addadd(x) {
				if(x == 1) {
					if(this.postData1.taskTypeSelectList1.length < 20) {
						this.postData1.taskTypeSelectList1.push({
							selectName: null,
							selectLength: '',
							selectType: 1,
							excuteType: 0,
							selectDescribe: null
						})
					} else {
						this.$notify({
							type: 'warning',
							message: '最多配置20个设置选项'
						})
					}
				} else {
					if(this.postData1.taskTypeSelectList2.length < 20) {
						this.postData1.taskTypeSelectList2.push({
							selectName: null,
							selectLength: '',
							selectType: 1,
							excuteType: 1,
							selectDescribe: null
						})
					} else {
						this.$notify({
							type: 'warning',
							message: '最多配置20个执行选项'
						})
					}
				}
			},
			editadd(x) {
				if(x == 1) {
					if(this.postData2.taskTypeSelectList1.length < 20) {
						this.postData2.taskTypeSelectList1.push({
							selectName: null,
							selectLength: '',
							selectType: 1,
							excuteType: 0,
							selectDescribe: null
						})
					} else {
						this.$notify({
							type: 'warning',
							message: '最多配置20个设置选项'
						})
					}
				} else {
					if(this.postData2.taskTypeSelectList2.length < 20) {
						this.postData2.taskTypeSelectList2.push({
							selectName: null,
							selectLength: '',
							selectType: 1,
							excuteType: 1,
							selectDescribe: null
						})
					} else {
						this.$notify({
							type: 'warning',
							message: '最多配置20个执行选项'
						})
					}
				}
			},
			editdelete(x, index) {
				this.$msgbox({
					title: '提示信息',
					message: '确认删除此选项？',
					showCancelButton: true,
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					beforeClose: (action, instance, done) => {
						if(action === 'confirm') {
							if(x == 1) {
								if(this.postData2.taskTypeSelectList1.length > 1) {
									this.deletelist.push(this.postData2.taskTypeSelectList1[index].id)
									this.postData2.taskTypeSelectList1.splice(index, 1)
								} else {
									this.$notify({
										message: '至少存在一条设置选项',
										type: 'warning'
									})
								}
							} else {
								if(this.postData2.taskTypeSelectList2.length > 1) {
									this.deletelist.push(this.postData2.taskTypeSelectList2[index].id)
									this.postData2.taskTypeSelectList2.splice(index, 1)
								} else {
									this.$notify({
										message: '至少存在一条执行选项',
										type: 'warning'
									})
								}
							}
							done()
						} else {
							done()
						}
					}
				})
			},
			querytable(x) {
				this.Loading = true
				this.loadingtable = true
				if(x && this.currentPage != 1) {
					this.currentPage = 1;
					return
				}
				axiosApi('/sales/taskType/web/selectListPage', {}, (res) => {
					this.tableData = res.data.data.elements
					this.total = res.data.data.totalCount
					this.loadingtable = false
					this.Loading = false
				}, x ? x : this.currentPage, 10)
			},
			//弹窗一，新增报备类型
			addeventtype() {
				this.loading1 = true
				let newconfirm = new Confirm()
				let confirmdata = this.postdataadd
				newconfirm.use([confirmdata.taskTypeName], '请输入报备类型名称')
				newconfirm.use([confirmdata.taskTypeDescription], '请输入报备类型描述')
				confirmdata.taskTypeSelectList.forEach((a) => {
					newconfirm.use([a.selectName], '请完整填写选项必填项')
					if(a.selectType != 3) {
						newconfirm.use([a.selectLength], '请完整填写选项必填项')
					}
				})
				let timeTypeList = confirmdata.taskTypeSelectList.filter((a) => {
					return a.selectType == 3
				})
				if(timeTypeList.length > 5) {
					newconfirm.status = false
					this.$message('日期类型超过上限')
				}
				var namelist = confirmdata.taskTypeSelectList.map((a) => {
					return a.selectName
				})
				var abc = namelist.unique(); //数组去重
				if(namelist.length !== abc.length) {
					newconfirm.status = false
					this.$message('存在相同的选项名称')
				}
				if(newconfirm.status) {
					axiosApi('/sales/taskType/web/insert', this.postdataadd, (res) => {
						this.querytable(1)
						this.$notify({
							type: 'success',
							message: '新增报备类型成功'
						})
						this.loading1 = false
					}, undefined, undefined, (res) => {
						this.loading1 = false
						this.$notify({
							type: 'warning',
							message: '新增报备类型失败，' + res.data.message
						})
					})
					this.dialogType = false
				} else {
					this.loading1 = false
				}
			},
			editeventtype() {
				this.loading1 = true
				let newconfirm = new Confirm()
				let confirmdata = this.postdataedit
				newconfirm.use([confirmdata.taskTypeName], '请输入报备类型名称')
				newconfirm.use([confirmdata.taskTypeDescription], '请输入报备类型描述')
				confirmdata.taskTypeSelectList.forEach((a) => {
					newconfirm.use([a.selectName], '请完整填写选项必填项')
					if(a.selectType != 3) {
						newconfirm.use([a.selectLength], '请完整填写选项必填项')
					}
				})
				let timeTypeList = confirmdata.taskTypeSelectList.filter((a) => {
					return a.selectType == 3
				})
				if(timeTypeList.length > 5) {
					newconfirm.status = false
					this.$message('日期类型超过上限')
				}
				var namelist = confirmdata.taskTypeSelectList.map((a) => {
					return a.selectName
				})
				var abc = namelist.unique(); //数组去重
				if(namelist.length !== abc.length) {
					newconfirm.status = false
					this.$message('存在相同的选项名称')
				}
				if(newconfirm.status) {
					axiosApi('/sales/taskType/web/update', this.postdataedit, (res) => {
						this.querytable(1)
						this.$notify({
							type: 'success',
							message: '编辑报备类型成功'
						})
						this.loading1 = false
					}, undefined, undefined, (res) => {
						this.loading1 = false
						this.$notify({
							type: 'warning',
							message: '编辑报备类型失败，' + res.data.message
						})
					})
					this.dialogEdit = false
				} else {
					this.loading1 = false
				}
			},
			handlePageChange(val) {
				this.currentPage = val
				this.querytable()
			},
			deleteRow(index, data) {
				var that = this
				this.$msgbox({
					title: '提示信息',
					message: '您确定要删除此报备类型？',
					showCancelButton: true,
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					beforeClose: (action, instance, done) => {
						if(action === 'confirm') {
							axiosApi('/sales/taskType/web/delete', {
								id: data[index].id
							}, () => {
								this.$notify({
									type: 'success',
									message: '删除报备类型成功'
								})
								that.querytable(1)
							}, undefined, undefined, (res) => {
								this.$notify({
									type: 'warning',
									message: '删除报备类型失败' + res.data.message
								})
							})
							done();
						} else {
							done();
						}
					}
				})
			},
			editRow(index, data) {
				this.editid = data[index].id
				axiosApi('/sales/taskType/web/selectInfo', {
					id: data[index].id
				}, (res) => {
					let rest = res.data.data
					this.postData2.taskTypeName = rest.taskTypeName
					this.postData2.taskTypeDescription = rest.taskTypeDescription
					this.postData2.taskTypeSelectList1 = rest.taskTypeSelectList.filter((a) => {
						return a.excuteType == 0
					}).map((a) => {
						return Object.assign({}, a, {
							disabled: true
						})
					})
					this.postData2.taskTypeSelectList2 = rest.taskTypeSelectList.filter((a) => {
						return a.excuteType == 1
					}).map((a) => {
						return Object.assign({}, a, {
							disabled: true
						})
					})
					this.dialogEdit = true
				})
			},
			getMenuListCloud() {
//				let _this = this
//				const api = "/foundation/resource/displaytree";
//				const body = {
//					loginName: sessionStorage.loginName,
//					appId: 2
//				};
//				axiosApi(api, body, res => {
//					console.log(res, '数据')
//					this.dataTabs = []
//					res.data.data.forEach(item => {
//						console.log(item)
//						if(item.text == '云销管理') {
//							item.sub.forEach(item1 => {
//								if(item1.text == '设置') {
//									item1.sub.forEach(item2 => {
//										_this.dataTabs.push(item2.text)
//									})
//								}
//							})
//						}
//					})
//				});
				this.dataTabs = []
				this.showButton("TASK_CONFIG",200108) && this.dataTabs.push('报备类型设置')
				this.showButton("CLOCK_CONFIG",200108) && this.dataTabs.push('考勤设置')
				this.showButton("VISIT_ACTION_CONFIG",200108) && this.dataTabs.push('拜访动作配置')
				this.dataTabs.push('云销代下单设置')
				setTimeout(() =>{
					this.getInfo()
				}, 0)

			},
			getInfo(){
					this.type = sessionStorage.setType ? sessionStorage.setType : 0
					this.typeName = sessionStorage.setTypeName ? sessionStorage.setTypeName : this.dataTabs[0]
					
					if(this.typeName == "拜访动作配置") {
						this.queryVisitAction()
					} else if(this.typeName == "考勤设置") {
						this.loading()
					} else if(this.typeName == "拜访动作配置"){
						this.querytable()
					}else{
						this.getGiftInfo()
					}
			},
			updateGift(e){
				updateGift(this.form).then(res=>{
					this.$message({
						type: "success",
						message: "操作成功",
						duration: 1000
					})
					this.getGiftInfo()
				}).catch(err=>{
					this.getGiftInfo()
				})
			},
			getGiftInfo(){
				getGiftInfo({traderId:sessionStorage.traderId}).then(res=>{
					this.form.openSalesGift = res.openSalesGift
				}).catch(err=>{

				})
			},

		},
		mounted() {
			this.$nextTick(() => {
				this.getMenuListCloud()

				//				if(this.type == 0) {
				//					this.queryVisitAction()
				//				} else if(this.type == 1) {
				//					this.loading()
				//				} else {
				//					 this.querytable()
				//				}
			})
		},
		computed: {
			postdataadd() {
				return {
					taskTypeName: this.postData1.taskTypeName,
					taskTypeDescription: this.postData1.taskTypeDescription,
					taskTypeSelectList: [...this.postData1.taskTypeSelectList1, ...this.postData1.taskTypeSelectList2]
				}
			},
			postdataedit() {
				return {
					id: this.editid,
					taskTypeName: this.postData2.taskTypeName,
					selectDelIdList: this.deletelist,
					taskTypeDescription: this.postData2.taskTypeDescription,
					taskTypeSelectList: [...this.postData2.taskTypeSelectList1, ...this.postData2.taskTypeSelectList2].map((a) => {
						return {
							selectName: a.selectName,
							selectLength: a.selectLength,
							selectType: a.selectType,
							excuteType: a.excuteType,
							selectDescribe: a.selectDescribe,
							id: a.id ? a.id : null
						}
					})
				}
			}
		},
	}
</script>

<style lang="less">
	#settings {
		.el-table__body-wrapper {
  			margin-top:1px!important;
		}
		.class-one {
			.el-dialog__wrapper {
				z-index: 100!important;
				.el-dialog.el-dialog-- {
					.el-dialog__body {
						width: 840px;
					}
				}
			}
			.h1 {
				height: 1px;
				background: #dfdfdf;
				width: 100%;
			}
			.disabled {
				color: gray;
			}
			.bd {
				padding-bottom: 10px;
			}
			.inputLength {
				width: 75px;
			}
			.selectWrap {
				display: inline-block;
				.el-input {
					width: 75px;
				}
			}
			.addAction {
				width: 470px;
			}
			.el-input {
				width: 115px;
				height: 32px;
			}
			.customReg:before {
				content: '*';
				color: #ff4949;
				margin-right: 0px;
			}
			.hide {
				display: none;
			}
			.errorRegWrap {
				position: relative;
			}
			.errorReg1 {
				color: #ff4949;
				font-size: 12px;
				line-height: 1;
				padding-top: 4px;
				position: absolute;
				top: 100%;
				left: 0;
				margin-left: 50px;
			}
			.errorReg2 {
				color: #ff4949;
				font-size: 12px;
				line-height: 1;
				padding-top: 4px;
				position: absolute;
				top: 100%;
				left: 0;
				margin-left: 80px;
			}
			.el-input__icon+.el-input__inner {
				font-size: 12px;
			}
			.delete {
				display: inline-block;
				vertical-align: middle;
				position: relative;
				margin-left: 10px;
				width: 16px;
				height: 16px;
				border-radius: 8px;
				background: #ff4201;
				&>div {
					position: absolute;
					height: 2px;
					width: 12px;
					background: #fff;
					left: 2px;
					top: 7px;
				}
			}
			.cell .el-button {
				width: auto !important;
			}
			.new-options-item {
				border: 1px solid #ddd;
				margin: 10px 30px;
				padding-top: 10px;
				.option-child-item {
					margin-bottom: 5px;
				}
			}
		}
		.class-two {
			.mt14 {
				margin-top: 14px;
			}
			.el-table th>.cell {
				text-align: center;
			}
			.el-table .cell {
				padding: 0 10px;
			}
			.el-dialog__body {
				padding: 30px;
			}
		}
		.class-three {
			padding-left: 15px;
			padding-bottom: 50px;
			.redrequired {
				color: red;
				margin-right: 0px !important;
			}
			.border {
				border: 1px solid #EEEEEE;
				background-color: #EEEEEE;
			}
			.w240 {
				width: 240px;
			}
			.w130 {
				width: 130px;
			}
			.w75 {
				width: 75px;
			}
			.w335 {
				width: 335px;
			}
			.selectWrap {
				display: inline-block;
				.el-input {
					width: 75px;
				}
			}
			.add,
			.edit {
				.el-dialog {
					width: 870px;
					max-height: 790px;
					.box {
						overflow: hidden;
						margin-bottom: 45px;
						.optionlist {
							max-height: 190px;
							width: 790px;
							margin-bottom: 20px;
							margin-left: 43px;
							overflow-y: scroll;
							&>div {
								margin-bottom: 10px;
								&:last-child {
									margin-bottom: 0px;
								}
								span {
									font-size: 12px;
									margin-right: 5px;
								}
								.el-input--small {
									width: 138px!important;
								}
								.delete {
									display: inline-block;
									vertical-align: middle;
									position: relative;
									margin-left: 10px;
									width: 16px;
									height: 16px;
									border-radius: 8px;
									background: #ff4201;
									&>div {
										position: absolute;
										height: 2px;
										width: 12px;
										background: #fff;
										left: 2px;
										top: 7px;
									}
								}
							}
						}
					}
				}
			}
		}
	}
</style>