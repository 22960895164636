import { render, staticRenderFns } from "./foodManage.vue?vue&type=template&id=43a46cba&"
import script from "./foodManage.vue?vue&type=script&lang=js&"
export * from "./foodManage.vue?vue&type=script&lang=js&"
import style0 from "./foodManage.vue?vue&type=style&index=0&id=43a46cba&prod&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports