<template>
    <div class="content bg-f">
        <div class="mb-30">
            <el-button class="addAddressBtn color-danger" @click="add">添加收货地址</el-button>
            <span class="font-12 color-9 ml-10">可以设置多个收货地址，最多50个；</span>
        </div>
        <div>
            
            <div class="content2" v-for="item in list">
                <div v-if="item.isDefault==1&&item.state == 2" class="triangle-topleft border-blue"></div>
                <div v-if="item.state == 1" class="triangle-topleft border-red"></div>
                <div v-if="item.state == 3" class="triangle-topleft border-gray"></div>

                <span v-if="item.isDefault==1&&item.state == 2" class="init default">默认</span>
                <span v-if="item.state == 1" class="init statusChecking">审核中</span>
                <span v-if="item.state == 3" class="init statusChecking">不通过</span>
                <el-form class='fl'>
                    <el-form-item label="收货人:">
                        {{item.linkman}}
                    </el-form-item>
                    <el-form-item label="手机/电话:">
                        {{item.tel}}
                    </el-form-item>
                    <el-form-item label='所在地区:'>
                        {{item.provinceName}}<span class="ml-10"></span>{{item.cityName}}<span class="ml-10"></span>{{item.regionName}}<span class="ml-10"></span>{{ item.streetName }}
                    </el-form-item>
                    <el-form-item label='详细地址:'>
                        {{item.addr}}
                    </el-form-item>
                </el-form>
                <div class="fr">
                    <!--<div class="clearfix">
                        <p v-if="item.addressType == 1">营业地址</p>
                        <p v-if="item.addressType == 2">仓库地址</p>
                    </div>-->
                    <div class="mt-40" v-if="item.isDefault==1">
                        <el-button type='text' v-if="item.state == 2||item.state == 3"  @click="handleEditDefaultAddress(item)">编辑</el-button>
                        <el-button type='text' v-if="item.state == 2||item.state == 3"  @click="handleDelete(item)" class="red mr-10">删除</el-button>
                    </div>
                    <div class="mt-40" v-else>
                        <el-button type='text' v-if="item.state == 2||item.state == 3"   @click="handleSetDefaultAddress(item)">设为默认</el-button>
                        <el-button type='text' v-if="item.state == 2||item.state == 3"   @click="handleEditDefaultAddress(item)">编辑</el-button>
                        <el-button type='text' v-if="item.state == 2||item.state == 3"  @click="handleDelete(item)" class="red mr-10">删除</el-button>
                    </div>
                </div>
            </div>
        </div>
    
        <!-- 编辑浮层 -->
        <addressAdd v-on:closedialog="handleClose" :jdAddress="true" :addressVisible="dialogTableVisible" :title="list.length >= 2?'edit':'add'"></addressAdd>
        
    </div>
</template>

<script>
    import {axiosApi} from "../../api/api";
    import { mapMutations } from "vuex"
    export default {
        data() {
            return {
                addressDialog:false,
                list : [],
                dialogTableVisible : false,
                type : '',
                ruleForm : {
                    consignee : '', // 收货人
                    mobile : '', //手机
                    region : [], //所在地区
                    address : '', // 详细地区
                    isDefault : false, // 是否默认地址
                    isDefault0 : false,
                },
                rules : {
                    consignee : [
                        { required: true, message: '收货人不能为空', trigger: 'change' },
                        { pattern: /^([A-Za-z]|[\u4E00-\u9FA5]){2,16}$/, trigger : 'change' , message : '请输入2到16位汉字或者字母' },
                    ],
                    mobile : [
                        { required: true, message: '联系电话不能为空', trigger: 'change' },
                        { pattern: /^((0\d{2,3}-?\d{7,8})|(\d{7,8})|(1\d{10}))$/, trigger : 'change' , message : '电话号或手机号格式有误' },
                    ],
                    region : [
                        { required: true, message: '所在地区不能为空'}
                    ],
                    address : [
                        { required: true, message: '详细地区不能为空', trigger: 'change' },
                        { min: 4, max: 50, message: '请输入4-50个字符', trigger: 'change' },
                    ],
                    isDefault : [],
                },
                editForm : {},
            }
        },
        mounted() {
            // this.$refs.dialog.rendered = true;
            this.initList();
        },
        methods : {
            ...mapMutations({
                'userAddressInfo': 'userInfo',
                "clearItem":"clearItem"
            }),
            add() {//添加收货地址
                // this.type = 'add';
                this.clearItem();
                this.dialogTableVisible = true;
            },
            addressCloseDialog() {
                this.addressDialog = false;
                this.loading();
            },
            // 初始化 list
            initList() {
                axiosApi("/trader/address/list", {
					traderId: sessionStorage.traderId,
                    addressType : '3',
				}, res => {
					if(res.data.data) {
                        this.list = res.data.data;
					}
				})
            },
            // 设置默认地址
            handleSetDefaultAddress(item) {
                axiosApi("/trader/address/setDefault", {
                    traderId: sessionStorage.traderId,
                    isDefault: 1,
                    id: item.id
				}, res => {
					this.initList()
				})
            },
            // 编辑默认地址
            handleEditDefaultAddress(item) {
                this.userAddressInfo(item);
                // this.type = 'edit';
                // this.editForm = item;
                // this.ruleForm = {
                //     consignee : item.linkman,
                //     mobile : item.tel,
                //     region : [item.provinceId.toString(),item.cityId.toString(),item.regionId.toString()],
                //     address : item.addr,
                //     isDefault0 : item.isDefault === 1 ? true : false,
                //     isDefault : item.isDefault === 1 ? true : false,
                // };
                this.dialogTableVisible = true;
            },
            // 新增默认地址
            handleAddDefaultAddress(item) {
                if(this.list.length === 31 ){
                    this.$message.warning('最多添加30个收获地址');
                    return;
                }
                this.type = 'add';
                this.dialogTableVisible = true;
            },
            // 浮层取消
            handleClose() {
                this.dialogTableVisible = false;
                this.initList()
                // this.$refs.ruleForm.resetFields();
                this.editForm = {}
            },
    
            // 点击删除
            handleDelete(item) {
                this.$confirm('是否删除该条？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    axiosApi('/trader/address/delete',{
                        id : item.id,
                    },(res) => {
                        let data = res.data.data;
                        this.$message.success('删除成功');
                        this.initList();
                    });
                }).catch(() => {
                });
            },
        },
    }
</script>

<style lang="less" scoped>

.jd-address-container{
    .blue{
        color: #20a0ff;
        cursor: pointer;
    }
    .red{
        color: red;
        cursor: pointer;
    }
    .list{
        overflow: hidden;
        >li{
            float: left;
            width: 458px;
            margin-right: 50px;
            height: 200px;
            border: 1px solid #ccc;
            margin-bottom: 30px;
            position: relative;
            .m-title{
                position: absolute;
                right: 0;
                top: 0;
                width: 100px;
                height: 40px;
                line-height: 40px;
                border: 1px solid #ccc;
                border-top: none;
                border-right: none;
                background-color: #eee;
                text-align: center;
            }
            .m-content{
                padding-left: 30px;
                padding-right: 20px;
                margin-top: 55px;
                >div{
                    margin-bottom: 20px;
                }
            }
            .m-icon{
                color: #bbb;
                font-size: 30px;
                position: absolute;
                top: 77.5px;
                left: 206.5px;
                cursor: pointer;
            }
        }
        >li:nth-child(2n){
            margin-right: 0;
        }
    }
}

</style>

<style lang="less">
    .jd-address-container{
        /* 重写浮层样式 */
        .el-dialog__body{
            padding: 30px 20px 100px 20px;
            min-width: auto;
        }
        .el-dialog{
            width: 480px;
            min-height: 410px;
        }
    }
</style>
