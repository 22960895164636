<template>
  <div id="superPerDetail" class="bg-f">
    <!-- 图片预览 -->
    <imgview
      :visible="imgsvisible"
      :imgs="showimgs"
      :startindex="startindex"
      @close="imgsvisible = false"
    ></imgview>
    <logoTop></logoTop>
    <div class="plr-45">
      <div>
        <div class="commCont">
          <div class="ml-40 mr-30 plr-45 mt-30">
            <div class="bbd clearfix">
              <el-tabs class="fl supplier" v-model="activeTab" @tab-click="handleClick">
                <el-tab-pane label="基础信息" name="1"></el-tab-pane>
                <el-tab-pane label="平台数据" name="2"></el-tab-pane>
              </el-tabs>
            </div>
          </div>
        </div>
        <div class="pb-50 ml-50">
          <div class="pl-30" v-show="activeTab == 1">
            <ul class="ml-30 ul">
              <li>
                <div>
                  <label class="mr-10">账号：</label>
                  <span class="ml-10">{{ objDetails.loginName }}</span>
                </div>
              </li>
              <li>
                <div>
                  <label class="mr-10">销售员名：</label>
                  <span class="ml-10">{{ objDetails.storeName }}</span>
                </div>
              </li>
              <li>
                <div>
                  <label class="mr-10">身份证：</label>
                  <span class="ml-10">{{ objDetails.name }}</span>
                  <span class="ml-10">{{ objDetails.idCard }}</span>
                  <!-- <span class="color-blue cursor" @click="lookIdCardImg">查看</span> -->
                </div>
              </li>
              <li>
                <div>
                  <label class="mr-10">加入时间：</label>
                  <span class="ml-10">
                    {{
                    timeFormat(objDetails.gmtCreate)
                    }}
                  </span>
                </div>
              </li>
              <li>
                <div class="remark">
                  <label>备注：</label>
                  <span class="ml-20">
                    {{
                    objDetails.remark || "--"
                    }}
                  </span>
                </div>
              </li>
              <!-- <li>
                <div>
                  <label class="mr-10">已缴纳保证金：</label>
                  <span class="ml-10">&yen;{{objDetails.bondMoney }}</span>
                </div>
              </li>
              <li>
                <div>
                  <label class="mr-10">服务费(元/年)：</label>
                  <span class="ml-10">&yen;{{objDetails.serviceMoney || 0 }}</span>
                </div>
              </li>
              <li>
                <div>
                  <label class="mr-10">支付凭证：</label>
                  <span class="ml-10 color-blue cursor" @click="lookBondCertify()">查看</span>
                </div>
              </li>-->
              <!-- <li>
                <div>
                  <label class="mr-10">推荐人：</label>
                  <span class="ml-10">
                    {{
                    objDetails.referrerName || "--"
                    }}
                  </span>
                </div>
              </li> -->
            </ul>
          </div>
          <!--平台数据-->
          <div class="pl-30" v-show="activeTab == 2">
            <ul class="ml-30 ul">
              <li>
                <div>
                  <label class="mr-10">交易额：</label>
                  <span class="ml-10">&yen;{{ objDetails.orderAllMoney }}</span>
                </div>
              </li>
              <li>
                <div>
                  <label class="mr-10">自营单订单数：</label>
                  <span
                    v-if="objDetails.selfOrderNum > 0"
                    class="ml-10 color-blue cursor"
                    @click="goOrder(1)"
                  >{{ objDetails.selfOrderNum }}笔</span>
                  <span v-else class="ml-10">0笔</span>
                </div>
              </li>
              <li>
                <div>
                  <label class="mr-10">久加久订单数：</label>
                  <span
                    v-if="objDetails.orderCount > objDetails.selfOrderNum"
                    class="ml-10 color-blue cursor"
                    @click="goOrder(2)"
                  >
                    {{
                    objDetails.orderCount - objDetails.selfOrderNum
                    }}笔
                  </span>
                  <span v-else class="ml-10">0笔</span>
                </div>
              </li>
            </ul>
            <!--下级用户-->
            <!-- <p class="ml-30 mt-50">下级用户</p>
            <el-table
              :data="tableData"
              v-loading="Loading"
              class="ml-30 mt-10 table-default mr-30"
              stripe
            >
              <el-table-column label="账号" >
                <template slot-scope="scope">
                  <div>{{ scope.row.loginName }}</div>
                </template>
              </el-table-column>
              <el-table-column label="姓名" >
                <template slot-scope="scope">
                  <div>{{ scope.row.name }}</div>
                </template>
              </el-table-column>
              <el-table-column label="交易额" >
                <template slot-scope="scope">
                  <div>&yen;{{ scope.row.orderAllMoney }}</div>
                </template>
              </el-table-column>
              <el-table-column label="订单笔数" width="210">
                <template slot-scope="scope">
                  <div
                    v-if="scope.row.selfOrderNum > 0"
                    class="cursor color-blue"
                    @click="goToOrder(scope, 1)"
                  >自营单{{ scope.row.orderCount }}笔</div>
                  <div v-else>自营单0笔</div>
                  <div
                    v-if="scope.row.orderCount > scope.row.selfOrderNum"
                    class="cursor color-blue"
                    @click="goToOrder(scope, 2)"
                  >
                    久加久订单{{
                    scope.row.orderCount - scope.row.selfOrderNum
                    }}笔
                  </div>
                  <div v-else>久加久订单0笔</div>
                </template>
              </el-table-column>
              <el-table-column label="已获佣金">
                <template slot-scope="scope">
                  <div>&yen;{{ scope.row.commission }}</div>
                </template>
              </el-table-column>
            </el-table>
            <el-pagination
              class="tar mr-30"
              v-if="total > 0"
              :current-page="currentPage"
              :page-size="10"
              :total="total"
              layout="prev , pager, next, jumper"
              @current-change="handleCurrentChange"
            >
            </el-pagination> -->
          </div>
          <!--按钮-->
          <div class="clearfix">
            <el-button class="ml-55 mt-50 fl" onclick="javascript:history.go(-1);">返回</el-button>
            <!-- <el-button
              v-if="objDetails.state == 2"
              class="ml-55 mt-50 fl"
              type="danger"
              @click="handleEditBond()"
            >修改保证金</el-button>-->
            <!-- <el-button class="ml-55 mt-50 fl" @click="handleRemark()">添加备注</el-button> -->
            <!-- <el-button
              v-if="objDetails.state != -1"
              class="ml-55 mt-50 fl"
              type="danger"
              @click="handleDelete()"
            >删除</el-button> -->
          </div>
        </div>
      </div>
    </div>
    <!-- 身份证照片 -->
    <el-dialog title="身份证照片" class="imgContainer" :visible.sync="idCardImg">
      <div>
        <div class="imgWrap" v-if="CardImg.length != 0">
          <img
            v-for="(item, index) in CardImg"
            :src="item"
            :key="index"
            @click="imgview(CardImg, index)"
          />
        </div>
        <div class="nocon tac" v-else>暂无数据</div>
      </div>
    </el-dialog>
    <!-- 缴纳保证金凭证弹窗 -->
    <el-dialog title="保证金缴纳凭证" class="imgContainer" :visible.sync="certify">
      <div class="p20">
        <div class="imgWrap tac" v-if="bondImg.length != 0">
          <img
            v-for="(item, index) in bondImg"
            :src="item"
            :key="index"
            @click="imgview(bondImg, index)"
          />
        </div>
        <div class="nocon tac" v-else>暂无数据</div>
      </div>
    </el-dialog>
    <!--修改保证金弹出框-->
    <el-dialog class="bond" title="修改保证金" :visible.sync="editBond" @close="closeBond">
      <el-form ref="bondForm" class="pr-30" :model="bondForm" :rules="rules1" label-width="100px">
        <el-form-item label="保证金" prop="money">
          <el-input placeholder="请输入保证金" v-model.trim="bondForm.money" :maxlength="6"></el-input>
        </el-form-item>
        <el-form-item class="mb-5" label="修改理由" prop="reason">
          <el-input
            type="textarea"
            :autosize="{ minRows: 3, maxRows: 10 }"
            :maxlength="100"
            placeholder="请输入修改理由.."
            v-model.trim="bondForm.reason"
          ></el-input>
        </el-form-item>
        <br />
        <span class="color-9 font-12 gaze">修改理由会以消息的方式通知久集用户，请认真填写</span>
        <br />
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="danger" @click="ensureBondMoney">确定</el-button>
        <el-button @click="editBond = false">取消</el-button>
      </div>
    </el-dialog>
    <!-- 备注 -->
    <el-dialog class="bond" title="添加备注" :visible.sync="remarkVisable" @close="remarkVisable = false">
      <el-form
        ref="remarkForm"
        class="pr-30"
        :model="remarkForm"
        :rules="rulesRemark"
        label-width="100px"
      >
        <el-form-item class="mb-5" label="备注" prop="remark">
          <el-input
            type="textarea"
            :autosize="{ minRows: 3, maxRows: 10 }"
            :maxlength="100"
            placeholder="请输入备注.."
            v-model.trim="remarkForm.remark"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="danger" @click="commitRemark('remarkForm')">确定</el-button>
        <el-button @click="remarkVisable = false">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { axiosApi, basePath } from "../../api/api";
import { mapActions, mapMutations } from "vuex";
export default {
  data() {
    var checkMoney = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("保证金不能为空"));
      }
      if (isNaN(Number(value))) {
        callback(new Error("请输入数字值"));
      } else {
        let reg = /^([1-9]\d*|[0]{1})$/;
        if (!reg.test(value)) {
          callback(new Error("请输入正确的数字值"));
        } else {
          callback();
        }
        callback();
      }
    };
    var checkRemark = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("备注不能为空"));
      }
      if (value) {
        let reg = /^[A-Za-z0-9-(),.!！，。？_@''""‘’?，；;:：、\\/\s\-“”*&^%$#`~;\u4e00-\u9fa5]{2,100}$/;
        if (!reg.test(value)) {
          callback(new Error("禁止输入特殊字符"));
        } else {
          callback();
        }
        callback();
      }
    };
    return {
      activeTab: "1",
      certify: false, //保证金凭证弹窗
      bondImg: [], //保证金凭证图片
      idCardImg: false, //身份证照片弹框
      CardImg: [], //身份证照片数据
      editBond: false, //修改保证金
      remarkVisable: false,
      remarkForm: {
        remark: null
      },
      rulesRemark: {
        remark: [
          { required: true, message: "备注不能为空" },
          {
            min: 2,
            max: 100,
            message: "备注为2-100位字符"
          },
          { validator: checkRemark, trigger: "blur" }
        ]
      },
      bondForm: {
        money: null,
        reason: null
      },
      rules1: {
        money: [
          { required: true, message: "保证金不能为空" },
          { validator: checkMoney, trigger: "change" }
        ],
        reason: [
          {
            required: true,
            message: "修改理由不能为空"
          },
          {
            min: 4,
            max: 100,
            message: "修改理由为4-100位字符"
          }
        ]
      },
      skuInfo: {},
      logo: "",
      rejectVisible: false, //弹出框
      ruForm: {
        id: this.$route.params.skuId,
        auditState: 2, //默认值是审核通过
        sellerId: sessionStorage.traderId,
        rejectionReason: null
      },
      objDetails: {},
      tableData: [],
      Loading: false,
      total: 0,
      currentPage: 1,
      imgsvisible: false,
      showimgs: [],
      startindex: 0
    };
  },
  methods: {
    imgview(imgs, index) {
      this.showimgs = imgs;
      this.startindex = index;
      this.imgsvisible = true;
    },
    //获取详情
    achieve() {
      axiosApi(
        "/superc/societySuperc/selectDetails",
        {
          id: this.$route.params.id
        },
        res => {
          this.objDetails = res.data.data;
          this.achieveSon();
        },
        1,
        10
      );
    },
    //获取下级详情
    achieveSon() {
      axiosApi(
        "/superc/societySuperc/selectByReferrer",
        {
          referrerStoreId: this.$route.params.id
        },
        res => {
          this.tableData = res.data.data.elements ? res.data.data.elements : [];
          this.total = res.data.data.totalCount;
          console.log(res.data.data.elements, "获取下级详情");
        },
        this.currentPage,
        10
      );
    },
    timeFormat(value) {
      var myDate = new Date(value);
      var year = myDate.getFullYear();
      var month =
        myDate.getMonth() + 1 < 10
          ? "0" + (myDate.getMonth() + 1)
          : myDate.getMonth() + 1;
      var day =
        myDate.getDate() < 10 ? "0" + myDate.getDate() : myDate.getDate();
      return year + "-" + month + "-" + day;
    },
    //查看身份证
    lookIdCardImg() {
      this.idCardImg = true;
      this.CardImg = this.objDetails.idCardImg ? this.objDetails.idCardImg : [];
    },
    //查看凭证
    lookBondCertify() {
      this.certify = true;
      this.bondImg = this.objDetails.bondImg
        ? this.objDetails.bondImg.split(",")
        : [];
    },
    // 修改备注
    handleRemark() {
      this.remarkVisable = true;
      this.remarkForm.remark = this.objDetails.remark;
    },
    //备注
    commitRemark(form) {
     this.$refs[form].validate(valid => {
        if (valid) {
          const body = {
            id: this.$route.params.id,
            remark: this.remarkForm.remark
          };
          axiosApi(
            "/superc/store/remark/update",
            body,
            res => {
              this.achieve();
              this.remarkVisable = false;
            },
            1,
            10
          );
        }
      });
    },
    handleEditBond() {
      //修改保证金
      this.editBond = true;
    },
    ensureBondMoney() {
      //保证金修改确定按钮
      setTimeout(() => {
        this.$refs.bondForm.validate(valid => {
          ////console.log(valid)
          if (valid) {
            const body = {
              id: this.$route.params.id,
              bondMoney: this.bondForm.money,
              updateAmountReason: this.bondForm.reason
            };
            axiosApi(
              "/superc/supercStore/updateBond",
              body,
              res => {
                this.achieve();
                this.editBond = false;
              },
              1,
              10
            );
          }
        });
      }, 0);
    },
    closeBond() {
      //保证金弹窗关闭清空数据,清空验证规则
      this.bondForm = {
        money: null,
        reason: null
      };
      this.$refs.bondForm.resetFields();
    },
    // 删除
    handleDelete(row) {
      console.log(row);
      this.$confirm("确认删除该账号？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.deleteFn();
        })
        .catch(() => {});
    },
    // 删除逻辑
    deleteFn(id) {
      axiosApi(
        "/superc/supercStore/web/delete",
        {
          id: this.$route.params.id
        },
        res => {
          this.$message.success("操作成功");
          this.$router.push(basePath + "/superPer");
        },
        1,
        1,
        err => {
          this.$message.warning(err.data.message);
        }
      );
    },
    //去订单列表
    goOrder(orderType) {
      window.open(
        basePath +
          "/storeOrder?supercStoreId=" +
          this.$route.params.id +
          "&type=0&orderType=" +
          orderType,
        "_blank"
      );
    },
    goToOrder(val, orderType) {
      window.open(
        basePath +
          "/storeOrder?supercStoreId=" +
          val.row.id +
          "&type=0&orderType=" +
          orderType,
        "_blank"
      );
    },
    handleClick(tab, event) {},
    //			分页
    handleCurrentChange(val) {
      this.currentPage = val;
      this.achieveSon();
    }
  },
  mounted() {
    this.$nextTick(function() {
      this.achieve();
    });
  }
};
</script>

<style lang="less">
#superPerDetail {
  .remark {
    display: flex;
    align-items: top;
    span {
      width: 800px;
      text-align: left;
    }
  }
  #imgview {
    z-index: 9999;
  }
  .imgContainer {
    .el-dialog__body {
      padding: 10px 30px;
      .imgWrap {
        text-align: center;
          justify-content: space-around;
        overflow-x: auto;
        display: flex;
        img {
          height: 250px;
          width: auto;
          margin-right: 10px;
          &:nth-last-child(1) {
            margin-right: 0;
          }
        }
      }
    }
  }
  .el-tabs__header {
    padding-left: 0;
  }
  .el-dialog {
    min-height: 300px;
  }
  .bbd {
    position: relative;
  }
  label {
    width: 120px;
    display: inline-block;
    text-align: right;
  }
  .ul {
    li {
      margin-top: 15px;
    }
  }
  .bond {
    .el-textarea {
      width: 300px;
    }
  }
  .el-table__body-wrapper {
    margin-top: 1px !important;
  }
  .el-dialog {
    min-height: 350px;
    .el-dialog__body {
      padding: 40px 0 80px 0;
    }
    .el-form-item__label {
      width: 160px;
    }
    .el-textarea {
      .el-textarea__inner {
        border-radius: 0;
      }
    }
    .gaze {
      margin-left: 100px;
    }
  }
}
</style>
