import Vue from 'vue'

require('es6-promise').polyfill()
import axios from 'axios'
//axios.defaults.withCredentials = true;
import {MessageBox} from 'element-ui'
import m from '../main.js'
import { PROJECT } from '../config/index.js'

// 只会刷新页面后执行一次：清空本地存储的版本
localStorage.removeItem('frontendVersion')
// 初始化全部变量赋值
window.frontendVersion = null

export const baseURI = '/sunrise-gateway'
export const httpHeaderConfig = {
    headers: {'Content-Type': 'application/json;charset=UTF-8;'}
}
export const basePath = '/customer'
export const basePath1 = '/official'

axios.defaults.timeout = 500000

// 请求队列
const queue = []
// axios内置的中断ajax的方法
const cancelToken = axios.CancelToken
// 同样的url、方法、参数可以视为相同的请求
const token = config => {
    return `${config.url}_${config.method}_${config.data}`
}
// 中断重复的请求，并从队列中移除
const removeQueue = config => {
    for (let i = 0, size = queue.length; i < size; i++) {
        const task = queue[i]
        if (task.token === token(config) && (token(config).indexOf('order/warehouseInfo/query') == -1) || (task.token.indexOf('trader/findClientAllList/select') != -1 && token(config).indexOf('trader/findClientAllList/select') != -1)) {
            task.cancel()
            queue.splice(i, 1)
            break
        }
    }
}

// 请求拦截(请求携带token)
axios.interceptors.request.use(
    config => {
        removeQueue(config) // 中断之前的同名请求---这是axios的防抖处理  防止用户多次点击的情况下产生的多次请求的问题
        // 添加cancelToken
        config.cancelToken = new cancelToken(c => {
            queue.push({token: token(config), cancel: c})
        })

        return config
    },
    err => {
        return Promise.reject(err)
    }
)

// 响应拦截,对返回的状态码判断和进行一些操作
axios.interceptors.response.use(
    response => {
        // 在请求完成后，自动移出队列--防抖处理的一部分
        removeQueue(response.config)
        return response
    },
    err => {
        return Promise.reject(err)
    }
)

export const axiosApi = (
    api,
    body,
    callack,
    pageIndex,
    pageSize,
    otherCallback,
    isloading
) => {
    let loading = isloading == '1' || false

    let xToken = null
    if (body) {
        xToken = body.xToken
        delete body.xToken
    }
    if (loading) var fullscreenloading = m.$loading()
    for (let item in body) {
        //搜索条件去掉空格
        if (typeof body[item] == 'string') {
            body[item] = body[item].trim()
            if (body[item] == '') {
                body[item] = null
            }
        }
        //判断 是否有districtId并且在首页 默认值 12
        if (
            item == 'districtId' &&
            window.location.href.indexOf('/customer/shop/shopIndex') != -1
        ) {
            if (body[item] == null) {
                body[item] = 12
            }
        }
    }
    body = JSON.stringify(body)
    let data = {
        body: body,
        ctrlData: {
            pageIndex: pageIndex == undefined ? 1 : pageIndex,
            pageSize: pageSize == undefined ? 1000 : pageSize,
            frontendVersion: window.frontendVersion,
            xToken: xToken,
        }
    }
    data = JSON.stringify(data)
    return axios
        .post(`${baseURI}` + api, data, httpHeaderConfig)
        .then(res => {
            if (api=='/trader/manyStoresHomePage/select'){
                console.log(res)
                console.log(loading)
            }
            
            // 登录失败不会有版本
            if (res.data.context.frontendVersion) {
                if (!window.frontendVersion) { // 第一次刷新必然进入这里，第一次存储
                    localStorage.setItem('frontendVersion', res.data.context.frontendVersion)
                    window.frontendVersion = localStorage.getItem('frontendVersion')
                } else {
                    if (res.data.context.frontendVersion !== window.frontendVersion) {
                        window.location.reload()
                    }
                }
            }

            switch (res.data.code) {
                case '000000': //系统交易成功

                    if (loading) fullscreenloading.close()
                    if (api == '/trader/manyStoresHomePage/select') {
                    console.log('???')}
                    callack(res)
                    break
                case '999999': //系统异常
                    if (loading) fullscreenloading.close()
                    if (typeof otherCallback == 'function') otherCallback(res)
                    MessageBox.alert(res.data.message, '提示信息', {
                        center: true,
                        confirmButtonText: '确定'
                    })
                    break
                case 'AUTH_001': //用户未登陆
                    gotoLoginPage();//线上登录
                    // m.$router.push({
                    //     path: '/customer/login'
                    // })
                    break
                case 'AUTH_002': //用户无权限
                    if (loading) fullscreenloading.close()
                    if (typeof otherCallback == 'function') otherCallback(res)
                    MessageBox.alert(res.data.message, '提示信息', {
                        confirmButtonText: '确定'
                    })
                    break
                case 'LOGIN_005': //用户已禁用
                    if (loading) fullscreenloading.close()
                    if (typeof otherCallback == 'function') ;
                    MessageBox.alert(res.data.message, '提示信息', {
                        confirmButtonText: '确定'
                    })
                    break
                case 'LOGIN_006': //用户session失效
                    gotoLoginPage();
                    break
                case 'FRONTEND_VERSION_UPDATED':
                    if (loading) fullscreenloading.close()
                    break
                case '0S1077':
                    if (typeof otherCallback == 'function') otherCallback(res)
                    break
                case '0S1075':
                    if (typeof otherCallback == 'function') otherCallback(res)
                    break
                case 'OS1099':
                    if (typeof otherCallback == 'function') otherCallback(res)
                    break
                case 'OS1093':
                    if (typeof otherCallback == 'function') otherCallback(res)
                    break
                case 'OS1094':
                    if (typeof otherCallback == 'function') otherCallback(res)
                    break
                case 'SMS0008':
                    MessageBox.alert('滑动验证码已过期，请刷新页面后重试', '提示信息', {
                        confirmButtonText: '确定'
                    })
                    break
                default:
                    if (loading) fullscreenloading.close()
                    if (typeof otherCallback == 'function') otherCallback(res)
                    MessageBox.alert(res.data.message, '提示信息', {
                        confirmButtonText: '确定'
                    })
                    break
            }
        })
        .catch(err => {
            // console.log(err, 'err')
            //      MessageBox.alert(err, '提示', {
            //          confirmButtonText: '确定'
            //      });
        })
}

export const gotoLoginPage = () => {
    axiosApi(
        "/getSessionId",
        {},
        res => {
            localStorage.clear();
            sessionStorage.clear();
            localStorage.clear();
            window.location.href = m.PROJECT.login + "?url=" + m.PROJECT.local + m.$route.fullPath + "&JSESSIONID=" + res.data.data
            // m.$router.push({
            //     path: '/customer/login'
            // })
        }
    );
};
export const gotoPageByWholeUrl = (wholeUrl) => {
    if (wholeUrl.indexOf('JSESSIONID=') >= 0) {
        window.location.href = wholeUrl;
        return;
    }

    axiosApi(
        "/getSessionId",
        {},
        res => {
            if (wholeUrl.indexOf('?') >= 0) {
                window.location.href = wholeUrl + "&JSESSIONID="+res.data.data
            } else {
                window.location.href = wholeUrl + "?JSESSIONID="+res.data.data
            }
        }
    );
};
export const pushOrGotoPageByWholeUrl = (url, wholeUrl) => {
    if(m.PROJECT.local.split('/')[2] == wholeUrl.split('/')[2]) {
        m.$router.push({path:url})
    } else {
        gotoPageByWholeUrl(wholeUrl)
    }
};
export const checkGotoSameHost = (url, wholeUrl) => {
    if(m.PROJECT.local.split('/')[2] == wholeUrl.split('/')[2]) {
        return true
    } else {
        return false
    }
};

export const getLoginInfoBySessionId = (sessionId, routerPath, callbackFunc) => {
    let url;
    if(sessionId){
        url = "/employee/getLoginInfo?JSESSIONID="+sessionId
    }else{
        url = "/employee/getLoginInfo"
    }
    axiosApi(url,
        {},
        (res) => {
            sessionStorage.setItem('traderId', res.data.data.traderId); //当前 店铺/供应商 IDK
            sessionStorage.setItem('loginName', res.data.data.loginName);
            sessionStorage.setItem('mobile', res.data.data.mobile);
            sessionStorage.setItem('id', res.data.data.id); //当前用户ID
            sessionStorage.setItem('isAdmin', res.data.data.isAdmin); //是否是管理员
            sessionStorage.setItem('isDepartmentLeader', res.data.data.isDepartmentLeader) //是否未部门领导
            sessionStorage.setItem('employeeName', res.data.data.employeeName) //员工人员
            sessionStorage.setItem('departmentPath', res.data.data.departmentPath) //部门id列表
            sessionStorage.setItem('managerId', res.data.data.managerId) //员工id
            sessionStorage.setItem('isHotel', res.data.data.isHotel); //是否是酒店用户
            if (localStorage.getItem('showZero') === null) { // 为0是否显示
                m.$store.commit('setZeroShow',true);
                // localStorage.setItem('showZero', true); 
            }
            axiosApi("/foundation/employee/selectOneByLoginName", {
                "loginName": res.data.data.loginName,
                appId: 2
            },  (resquest) => {
                    // console.log(res, 'data');
                    // console.log(res.data.data.supplierType)
                    let a = resquest.data.data;
                    console.log(a)
                    sessionStorage.setItem('supplierType', a.supplierType)
                    
                    if (a.loginSuccessCount == 1 && a.isAdmin == 1 && a.isHotel != 1) {
                        sessionStorage.setItem('isOne', 1);
                    }
                    m.$store.state.clientType.basicLoginInfo = {
                        departmentName: a.departmentName,
                        clientIp: a.clientIp,
                        gmtLogin: a.gmtLogin
                    }
                    //console.log(a)
                    //缓存登录次数，从1开始
                    // if(m.$cookies.isKey('userName')){
                    //     m.$cookies.remove('userName')
                    // }
                    m.$cookies.set('userName', a.loginName, null, '/')
                    sessionStorage.setItem('loginName', a.loginName);
                    sessionStorage.setItem('mobile', a.mobile);
                    sessionStorage.setItem('traderId', a.traderId); //当前 店铺/供应商 ID
                    sessionStorage.setItem('id', a.id); //当前用户ID
                    sessionStorage.setItem('isAdmin', a.isAdmin); //是否是管理员
                    sessionStorage.setItem('isDepartmentLeader', a.isDepartmentLeader); //是否未部门领导
                    sessionStorage.setItem('employeeName', a.employeeName); //员工人员
                    sessionStorage.setItem('departmentPath', a.departmentPath); //部门id列表
                    sessionStorage.setItem('managerId', a.managerId); //员工id
                    sessionStorage.setItem('isHotel', a.isHotel); //是否是酒店用户
                    if (!sessionStorage.getItem('isOpen')) { 
                        // 首页积分提示条是否开关。 0为关，1为开，首次进入开启，60s后关闭
                        sessionStorage.setItem('isOpen', 1); 
                    }

                    m.$store.state.clientType.isDealerUser = a.dealerUser;
                    m.$store.state.clientType.isCityPartner = a.supplierType == 4;
                    m.$store.state.clientType.isSuperCityPartner = a.supplierType == 3;
                    m.$store.state.clientType.isChildCompany = a.supplierType == 2;
                    m.$store.state.clientType.isParentCompany = a.supplierType == 1;
                    m.$store.state.clientType.erpType = a.erpType;
                    console.log(a,'????')
                    // sessionStorage.setItem('isDealerUser', a.dealerUser); //是否是经销商
                  
                  
                    if (callbackFunc) {
                        callbackFunc()
                    }

                    if (routerPath) {
                        m.$router.push({path:basePath + routerPath})
                    }
                }, 0, 0, err => {
                    m.$alert(err.data.message, '提示信息', {
                        confirmButtonText: '确定'
                    })
                })
            
        }
    )
}

// 获取url中的参数
export const getUrlParam = name => {
    var ss = window.location.href.split('?')
    if (!ss || ss.length < 2) return null
    var paramStr = ss[1]

    var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)') //构造一个含有目标参数的正则表达式对象

    var r = paramStr.match(reg) //匹配目标参数
    if (r != null) return decodeURI(r[2])
    return null //返回参数值
}
