<template>
	<div id="myCollect">
		<div class="title-top bg-f mb-5">
			收藏的商品
		</div>
		<!--商品-->
		<div class="main bg-f pt-30 pb-50" v-if='type == 0' v-loading='Loading'>
			<div class="mianNav ml-40 mr-30 mb-30" >
				<div class="clearfix bgimg bbd" style="display: flex">
					<p class="fl font-14 color-6 mt-20 mr-20 mb-20" style="width: 50px">分类:</p>
					<ul class='clearfix  reduce' :style="{height: spreadHeight1 + 'px'}" style="flex: 1;width: 0">
						<li class="fl width-90 mt-20 color-3 font-14 li cursor tac height-25"
              v-for='(item,index) in searchClassify' :key="index"
              @click="getClassindex(index)"
              :class="{active1:index==activeIndexClassfy}">{{item.classifyName}}</li>
					</ul>
					<p v-if="this.searchClassify.length > 8" class="fr bdg mt-20 font-14 cursor color-3" @click="spread1=!spread1;spreadHeight1 = spread1 ? spreadHeightAuto1 : 59;">更多
						<i class="color-bb" :class='{"el-icon-caret-bottom":!spread1,"el-icon-caret-top":spread1}'></i>
					</p>
				</div>
				<div class="clearfix bbd" style="display: flex">
					<p class="font-14 color-6 mt-20 mr-20 fl mb-20" style="width: 50px;">品牌:</p>
					<div class="fl" style="flex: 1;width: 0">
						<div v-show='spread2' class="mt-20">
							<el-input placeholder="请输入品牌" style="width: 240px" v-model.trim='brandName' @keyup.enter.native="achieveBrand"></el-input>
							<el-button type="danger" icon="el-icon-search" class=" ml-30" @click='achieveBrand'>搜索</el-button>
						</div>
						<ul class='clearfix reduce' :style="{height: spreadHeight2 + 'px'}" :class="{ha:searchBrandName.length > 8 && spread2}">
							<li class="fl width-90 mt-20 height-25 color-3 font-14 li cursor tac hides mb-10" v-for='(item,index) in searchBrandName' :key="index" @click='getbrandindex(index)' :class="{active1:index==activeIndexBrand}">{{item.brandName}}</li>
						</ul>
					</div>
					<p v-if="searchBrandName.length > 8" class="fr mt-20 font-14 cursor color-3 bdg" @click="brandMore">更多
						<i class="color-bb" :class='{"el-icon-caret-bottom":!spread2,"el-icon-caret-top":spread2}'></i>
					</p>
				</div>
			</div>
			<ul class="clearfix product ml-30 mr-30">
				<li class="fl   bg-f  bd cursor" id="pro" v-for='(item,index) in goodsInfo' :key='index' style="position: relative;" @click='clickProduct(index)'>
					<img v-if='item.fileUrl' :class="item.skuState == 1?'disabledImg':''" :src="item.fileUrl" />
					<img v-else :class="item.skuState == 1?'disabledImg':''" src='../../assets/goods.jpg'/>
					<i v-if='item.skuState == 1' class="icon-no-affect iconfont fl mr-30 loss"></i>
					<p class="seckillList ml-10 pb-10 pt-10">
						<span class="one font-12 text-overflow" :class="item.skuState == 1?'color-6':'color-3'">{{item.skuName}}</span>
						<span class="one font-12" :class="item.skuState == 1?'color-6':'color-danger'">
                            <!-- <span v-if="isHotel&&item.skuState == 2">
                                ￥{{item.promotionPrice | fmoney}}
                            </span> -->
							
							<span > ￥{{ item.showUnitPrice | fmoney}}</span>
							<span v-if="item.showUnitName">/ {{item.showUnitName}}</span>
						</span>
						<!-- <span class="one font-14 text-overflow" :class="item.skuState == 1?'color-6':'color-9'">{{item.sellerName}}</span> -->
					</p>
					<p class="two cursor" @click.stop='goodsDelete(index)'>
						<i class="iconfont icon-delete font-16 color-9"></i>
					</p>
				</li>
			</ul>
			<div class="countBlank mt-50 ml-40" v-if="this.goodsInfo == 0">
				<img src="../../assets/empty.png" alt="" />
				<p class="color-9 mt-15">收藏夹空空如也</p>
				<el-button class="edit-btn" @click.native="goPage()">去逛逛</el-button>
			</div>
			<el-pagination class="tar mr-30" v-if='total > 0' :current-page="currentPage" :page-size='8' :total='total' layout="prev , pager, next, jumper" @current-change="handleCurrentChange">
				<!-- 分页组件 -->
			</el-pagination>
		</div>
		<!--供货商-->
		<!-- <div class="bg-f pt-30 pb-50" v-if='type == 1'>
			<div class="supplier ml-30 mr-30 mb-40 bg-f bd pt-10 pb-40 clearfix" v-for='(item,index) in sellerInfo' :key='index'>
				<div class="left ml-20 mt-30 fl" style="position: relative;">
					<div class="clearfix">
						<img v-if='item.avater' class="icon-60 portrait fl mr-10 cursor" :class="item.isInvalid?'disabledImg':''" :src="item.avater" @click='clickSeller(index)' />
						<img v-else class='icon-60 portrait fl mr-10 cursor' :class="item.isInvalid?'disabledImg':''" src='../../assets/header.jpg' @click='clickSeller(index)'/>
						<i v-if='item.isInvalid' class="icon-no-affect iconfont fl mr-30 loss"></i>
						<span class="fl font-16 w-176 mt-15 lh-16">{{item.storeName}}</span>
					</div>
					<p class="mt-20 font-16 color-9">收藏人数：<span>{{item.collectCount}}</span></p>
					<div class="mt-74 clearfix">
						<i @click='sellUpdate(index)' class="iconfont icon-updata font-30 fl mr-30 icon cursor"></i>
						<i @click='sellDelete(index)' class="iconfont icon-delete font-30 fl icon cursor"></i>
					</div>

				</div>
				<div :style="{height: sellerInfo[index].height +'px'}" class="right fl ml-10 mt-30">
					<div class="imgList bd fl ml-10 mb-20" v-for='(n,index1) in item.skuList' :key='index1'>
						<img v-if='n.fileUrl' :class="item.isInvalid?'disabledImg':''" :src="n.fileUrl" />
						<img v-else :class="item.isInvalid?'disabledImg':''"  src='../../assets/goods.jpg'/>
					</div>
				</div>
				<div>
					<span @click="storeGoodsAuto(index)" class="fr color-3 font-14 cursor mr-20">查看所有 <i class=" color-b" :class="sellerInfo[index].isAuto ? 'el-icon-caret-top' : 'el-icon-caret-bottom'"></i></span>
				</div>
			</div>
			<el-pagination class="tar mr-30" v-if='totalseller > 0' :current-page="currentPage1" :page-size='6' :total='totalseller' layout="prev , pager, next, jumper" @current-change="handleCurrentChange1">
			</el-pagination>
			<div class="countBlank mt-50 ml-40" v-if="this.sellerInfo.length == 0">
				<img src="../../assets/empty.png" alt="" />
				<p class="color-9 mt-15">收藏夹空空如也</p>
			</div>
		</div> -->

	</div>

</template>

<script>
	import { axiosApi, basePath } from "../../api/api";
	export default {
		data() {
			return {
				Loading:true,
				activeIndexClassfy: 0,
				activeIndexBrand: 0,
				type: '0',
				spreadHeight1: 59,
				spreadHeight2: 59,
				spread1: false, //判断是否展开
				spreadHeightAuto1: 59,
				spread2: false, //判断是否展开
				spreadHeightAuto2: 59,
				searchClassify: [{ classifyName: '全部', id: null }], //搜索分类
				searchBrandName: [{ brandName: '全部', id: null }], //搜索品牌
				goodsInfo: [], //所有的收藏的商品信息
				sellerInfo: [], //所有的收藏的供货商信息
				currentPage: 1,
				total: 0,
				isPagination: true,
				currentPage1: 1,
				totalseller: 0,
				isPagination1: true,
				classifyId: null,
				brandId: null,
				brandName: '',
        isHotel: sessionStorage.getItem('isHotel') === '1' ? true : false,
        isAdmin: sessionStorage.getItem('isAdmin') == '1' ? true : false
			}
		},
		methods: {
			brandMore() { //品牌更多
				this.spread2 = !this.spread2
				this.spreadHeight2 = this.spread2 ? this.spreadHeightAuto2 : 59;
			},
			//			分页
			handleCurrentChange(val) {
				this.currentPage = val
				this.achieve()
			},
			// handleCurrentChange1(val) {
			// 	this.currentPage1 = val
			// 	this.achieve1()
			// },
			//收藏的商品
			achieve() {
				const api = "/trader/collectGoodsList/select";
				const body = {
					buyerId: sessionStorage.traderId,
					classifyId: this.classifyId,
					brandId: this.brandId
				}
				axiosApi(api, body, this.callback, this.currentPage, 8)
			},
			callback(res) {
				this.total = res.data.data.totalCount;
				this.goodsInfo = res.data.data.elements;
				this.Loading = false
			},
			//点击商品跳转详情
			clickProduct(index) {
				if(this.goodsInfo[index].skuState != 1) {
					this.$router.push(basePath + '/shop/goodsDetails/' + this.goodsInfo[index].skuNo)
				}
			},
			//收藏夹为空跳转商品列表
			goPage(){
				this.$router.push(basePath + '/shop/shopIndex/' )
			},
			//收藏的供货商
			achieve1() {
				return
				const api = "/trader/collectSellerList/select";
				const body = {
					buyerId: sessionStorage.traderId
				}
				axiosApi(api, body, this.callback1, this.currentPage1, 6)
			},
			callback1(res) {
				this.sellerInfo = res.data.data.elements;
				this.totalseller = res.data.data.totalCount;
				if(this.sellerInfo.length > 0) for(let key in this.sellerInfo) {
		        	// this.sellerInfo[key] = Object.assign({},this.sellerInfo[key]);
					this.sellerInfo[key].height = 170;
					this.sellerInfo[key].isAuto = false;
		        	this.sellerInfo[key].heightAuto = (this.sellerInfo[key].skuList.length) <= 4 ? 170 : Math.ceil((this.sellerInfo[key].skuList.length)/4)*170;

		        	// this.sellerInfo[key] = Object.assign({},this.sellerInfo[key]);
		        	// console.log(this.sellerInfo[key].height,this.sellerInfo[key].heightAuto,5555)
		        }
			},
			storeGoodsAuto(index){
				// this.$set(this.sellerInfo[index],'isAuto',!this.sellerInfo[index].isAuto)
				this.sellerInfo[index].isAuto = !this.sellerInfo[index].isAuto;
				// this.$set(this.sellerInfo[index],'height',this.sellerInfo[index].isAuto ? this.sellerInfo[index].heightAuto : 170)

				this.sellerInfo[index].height = this.sellerInfo[index].isAuto ? this.sellerInfo[index].heightAuto : 170;
				this.sellerInfo.splice(index,1,this.sellerInfo[index])
				// this.sellerInfo[index] = Object.assign({},this.sellerInfo[index]);
				// console.log(this.sellerInfo[index].height,this.sellerInfo[index].isAuto)
			},
			//点击店铺头像跳转
			clickSeller(index) {
				if(this.sellerInfo[index].isInvalid == false) {
					this.$router.push(basePath + '/goodsSearch/classify/brand/goodsName/' + this.sellerInfo[index].sellerId)
				}

			},
			//查询品牌/分类
			search() {
				axiosApi("/goods/brandList/select", {}, (res) => {
					this.searchBrandName = this.searchBrandName.concat(res.data.data.elements); //品牌
		        	this.spreadHeightAuto2 = this.searchBrandName.length <= 8 ? 59 : Math.ceil((this.searchBrandName.length)/8)*59;
		        	if(this.spreadHeightAuto2 > 250) this.spreadHeightAuto2 = 250;
		        	// console.log(this.spreadHeightAuto2)
				});
				axiosApi("/goods/classifyTopList/select", { classificationId: null }, (res) => {
					this.searchClassify = this.searchClassify.concat(res.data.data); //分类
					this.spreadHeightAuto1 = this.searchClassify.length <= 8 ? 59 : Math.ceil((this.searchClassify.length)/8)*59;
		        	// console.log(this.spreadHeightAuto1)
				});
				// setTimeout(() => {
				// 	this.changeHeight()
				// }, 200)

			},
			//搜索品牌
			achieveBrand() {
				const api = "/goods/classifyBrandListQuery/select";
				const body = {
					classifyId:this.classifyId,
					brandName: this.brandName
				}
				axiosApi(api, body, this.achieveBrand1, 1, 1000)
			},
			achieveBrand1(res) {
				if(this.brandName == '') {
					this.searchBrandName = [{ brandName: '全部', id: null }]
					this.searchBrandName = this.searchBrandName.concat(res.data.data.elements); //品牌
					this.activeIndexBrand = null
				} else {
					this.activeIndexBrand = null
					this.searchBrandName = res.data.data.elements
				}

			},
			//			收藏的供货商顺序置顶
			sellUpdate(index) {
				let that = this;
				this.$confirm('您是否要置顶「' + this.sellerInfo[index].storeName + '」？', '提示信息', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					const api = "/trader/collectSellerSort/update";
					const body = {
						buyerId: sessionStorage.traderId,
						sellerId: this.sellerInfo[index].sellerId
					};
					axiosApi(api, body, (res) => {
						this.$message({
							type: 'success',
							message: '置顶成功!',
							duration: 1000
						});
						that.achieve1();
					}, this.currentPage, 10, (res) => {
						this.$message({
							type: 'info',
							message: res.data.message,
							duration: 3000
						});
					});
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消置顶'
					});
				})
			},
			//			收藏的供货商删除
			sellDelete(index) {
				let that = this;
				this.$confirm('您是否要删除「' + this.sellerInfo[index].storeName + '」？', '提示信息', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					const api = "/trader/collectSeller/update";
					const body = [{
						buyerId: sessionStorage.traderId,
						sellerId: this.sellerInfo[index].sellerId
					}];
					axiosApi(api, body, (res) => {
						this.$message({
							type: 'success',
							message: '删除成功!',
							duration: 1000
						});
						that.achieve1();
					}, this.currentPage, 10, (res) => {
						this.$message({
							type: 'info',
							message: res.data.message,
							duration: 3000
						});
					});
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				})
			},
			//			收藏的商品删除
			goodsDelete(index) {
				let that = this;
				this.$confirm('您是否要删除「' + this.goodsInfo[index].skuName + '」？', '提示信息', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					const api = "/trader/collectGoods/update";
					const body = [{
						buyerId: sessionStorage.traderId,
						skuNo: this.goodsInfo[index].skuNo,
						classifyId: this.goodsInfo[index].classifyId,
						brandId: this.goodsInfo[index].brandId
					}];
					axiosApi(api, body, (res) => {
						this.$message({
							type: 'success',
							message: '删除成功!',
							duration: 1000
						});
						that.achieve();
					}, this.currentPage, 10, (res) => {
						this.$message({
							type: 'info',
							message: res.data.message,
							duration: 3000
						});
					});
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				})
			},
			getClassindex(index) {
				this.activeIndexClassfy = index;
				this.classifyId = this.searchClassify[index].id
				this.achieveBrand()
				this.achieve();
			},
			getbrandindex(index) {
				this.activeIndexBrand = index;
				this.brandId = this.searchBrandName[index].id
				this.achieve();
			},
		},
		//进入加载
		mounted() {
			this.$nextTick(function() {
				this.isHotel && (this.dataTabs = ['收藏的商品'])
				this.achieve();
				// this.achieve1();
				this.search();

			})
		}
	}
</script>

<style lang="less">
	#myCollect {
		.height-25 {
			height: 25px;
			line-height: 25px;
		}
		.hides {
			overflow: hidden;
			/*自动隐藏文字*/
			text-overflow: ellipsis;
			/*文字隐藏后添加省略号*/
			white-space: nowrap;
			/*强制不换行*/
			width: 20em;
			/*不允许出现半汉字截断*/
		}
		.bdg {
			text-align:center;
			width: 70px;
			height: 25px;
			line-height: 25px;
			border: 1px solid #DDDDDD;
			border-radius:13px;
		}
		.main {
			/*商品*/
			.color-bb {
				color: #BBBBBB;
			}
			.w-1000 {
				width: 700px;
			}
			.li:hover,
			.active1 {
				// color: #FF4100;
				background: #E94A13;
				border-radius: 13px;
				color: white;
				width: auto;
			}
			// .bgimg {
			// 	background: url(../../assets/bg-dashed.png) 0px bottom;
			// 	background-repeat: repeat-x;
			// }
			.product li {
				width: 216px;
				height: 260px;
				position: relative;
				img {
					width: 160px;
					height: 160px;
					margin-left: 28px;
					margin-top: 20px;
				}
				.seckillList {
					width: 194px;
					height: 60px;
					// border-top: 1px solid #DDDDDD;
					.one {
						display: block;
						width: 197px;
						height: 28px;
						line-height: 28px;
						text-align: center;
					}
				}
				.two {
					width: 36px;
					height: 0px;
                    line-height: 40px;
                    text-align: center;
					background: rgba(88, 88, 88, 0.1);
					position: absolute;
					overflow: hidden;
					top: 0;
					right: 0;
                    border-bottom-left-radius: 3px;
					transition: all 0.5s;
					&:hover i {
						color: #FF4201
					}
				}
			}
			.product li:nth-child(4n) {

			}
			.product li:hover {
				.two {
					/*display: block;*/
					height: 40px;
				}
			}
		}
		/*供货商*/
		.supplier {
			.left {
				/*头像图片*/
				.portrait {
					border-radius: 30px
				}
				.w-176 {
					width: 176px;
				}
				.mt-74 {
					margin-top: 74px;
					.icon:hover {
						color: #FF4201;
					}
				}
			}
			.right {
				width: 650px;
				overflow: hidden;
				transition: all .5s;
				.imgList {
					width: 150px;
					height: 150px;
					img{
						width: 100%;
						height: 100%;
					}
				}
			}
		}
		.w750 {
			width: 750px;
		}
		.reduce {
			/*// height: 59px;*/
			overflow-x: hidden;
			overflow-y: hidden;
			transition: all .5s;
		}
		.ha {
			max-height: 250px;
			overflow-y: scroll;
		}
		.loss {
			position: absolute;
			font-size: 60px;
			top: 0px;
			left: 10px;
			z-index: 99;
		}
		.edit-btn{
			float:initial;
			span{
				color:#9E9E9E
			}
		}
		.disabledImg {
			opacity: 0.3;
			filter:alpha(opacity=30);
		}
		.countBlank {
				height: 300px;
				padding-top: 100px;
				text-align: center;
			}
	}
</style>