<template>
	<div id="storeManage" class="bg-f" v-loading="loading">
		<top></top>
		<div class="pay-order-content">
			<div class="content clearfix">
				<div class="lh-150">
					<img class="icon-60 mid ml-30" v-if="userInfo.headportraitUrl == null" src="../../assets/header.jpg" />
					<img v-else class="icon-60 mid ml-30" :src="userInfo.headportraitUrl" />
					<div class="dib mid ml-20">
						<p class="lh-16 mb-20">{{userInfo.employeeName}}</p>
						<p class="lh-16 color-9">账号：{{userInfo.loginName}}</p>
					</div>
					<!-- <el-button type="danger" class="fr mt-60 mr-30" @click="goPage">创建新店铺</el-button> -->
				</div>
				<div class="mt-5 my-store">
					<p class="font-18">我的店铺</p>
					<div class="mt-50 clearfix">
						<div class="e-store mListBox fl" v-for="(item,index) in storeInfo" :key="index">
							<div class="mInfo">
								<img
								:src="item.avater || errImg"
								/>
								<span class="text">{{item.storeName}}</span>
								<span class="type">
									<el-tag v-if="item.state === 2 &&(item.businessStatus === 1)">打烊中</el-tag>
									<el-tag v-if="item.state === 2 && (item.businessStatus === 2)" type="gray">关闭中</el-tag>
									<el-tag v-if="item.state === 1" type="primary">审核中</el-tag>
									<el-tag v-if="item.state === 2 &&(item.businessStatus === 0)" type="success">营业中</el-tag>
									<el-tag v-if="item.state === 3" type="danger">审核失败</el-tag>
								</span>
							</div>
							<div class="mhandle">
								<!-- 营业中 -->
								<template v-if="item.state === 2 &&(item.businessStatus === 0)">
									<el-button @click="inStore(item)">进入店铺</el-button>
									<el-button @click="handleBusinessManagement(item,index)">营业管理</el-button>
									<el-button @click="handleDaYang(item,index)">打烊店铺</el-button>
								</template>
								<!-- 打烊中 -->
								<template v-if="item.state === 2 &&(item.businessStatus === 1)">
									<el-button @click="inStore(item)">进入店铺</el-button>
									<el-button @click="handleBusinessManagement(item,index)">营业管理</el-button>
									<el-button @click="handleOpenStart(item,index)" type="primary">开始营业</el-button>
								</template>
								<!-- 关闭中 -->
								<template v-if="item.state === 2 && (item.businessStatus === 2)">
									<el-button @click="inStore(item)">进入店铺</el-button>
								</template>
								<!-- 审核中 -->
								<template v-if="item.state === 1">
									<el-button @click="lookApply(item)">查看申请</el-button>
								</template>
								<!-- 审核失败 -->
								<template v-if="item.state === 3">
									<el-button @click="lookApply(item)">查看申请</el-button>
									<el-button @click="deleteTraderRecord(item)">删除店铺</el-button>
								</template>

								<!-- <el-button @click="handleBusinessManagement(item,index)">营业管理</el-button> -->



								<!-- <el-button @click="inStore(item)">进入店铺</el-button>
								<el-button @click="handleBusinessManagement">营业管理</el-button>
								<el-button @click="handleDaYang">打烊店铺</el-button>
								<el-button @click="handleOpenStart" type="primary">开始营业</el-button>
								<el-button @click="lookApply(item)">查看申请</el-button>
								<el-button @click="deleteTraderRecord(item)">删除店铺</el-button> -->
								<div class="mh">
									<!-- 营业中 -->
									<a @click="handleCloseShop(item,index)" v-if="item.state === 2 &&(item.businessStatus === 0)">关闭店铺</a>
									<!-- 打烊中 -->
									<a @click="handleCloseShop(item,index)" v-if="item.state === 2 &&(item.businessStatus === 1)">关闭店铺</a>
									<!-- 关闭中 -->
									<a @click="handleOpenShop(item,index)" v-if="item.state === 2 &&(item.businessStatus === 2)">开启店铺</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- 营业管理浮层 -->
			<el-dialog
			title="设置您的营业时间段"
			:visible.sync="mdialogVisible"
			:before-close="businessManagementClose"
			class="mDialogBox"
			size="small"
			>
				<div class="mDialogContent">
					<div>
						<el-radio-group @change="handleDialogTime" v-model="timeType">
							<el-radio :label="1">24小时营业</el-radio>
							<el-radio :label="2">自定义时间段</el-radio>
						</el-radio-group>
					</div>
					<div>
						设置时间：
						<el-time-select
							:style="{width : '120px'}"
							:disabled="dialogTimeDisabled"
							placeholder="开始时间"
							v-model="startTime"
							:picker-options="{
							start: '00:00',
							step: '00:30',
							end: '23:30'
							}">
						</el-time-select>
						-
						<el-time-select
							:style="{width : '120px'}"
							:disabled="dialogTimeDisabled"
							placeholder="结束时间"
							v-model="endTime"
							:picker-options="{
							start: '00:00',
							step: '00:30',
							end: '23:30',
							minTime: startTime
							}">
						</el-time-select>
					</div>
					<div>
						<span><span class="origin">温馨提示：</span>营业时间段之外的时间均为打烊时段，打烊时段内您的商品将无法被购买。</span>
					</div>
				</div>
				<span slot="footer" class="dialog-footer">
					<el-button @click="businessManagementClose">取 消</el-button>
					<el-button type="primary" @click="businessManagementOk">确 定</el-button>
				</span>
			</el-dialog>
		</div>
	</div>
</template>

<script>
	import { axiosApi, basePath } from "../../api/api"
	import { MessageBox } from 'element-ui'
	import { mapMutations } from 'vuex'
	import errImg from '../../assets/store-big.jpg';
	export default {
		data() {
			return {
				errImg : errImg,
				mdialogVisible : false,
				timeType : 1,
				startTime : '',
				endTime : '',
				dialogTimeDisabled : false,

				loading: false,
				userInfo: {}, //账户信息
				storeInfo: [] //店铺信息
			}
		},
		mounted() {
			this.$nextTick(() => {
				this.manyStoresHomePage()
			})
		},
		methods: {
			// 营业管理
			handleBusinessManagement : function(item,index){
				this.mIndex = index;
				this.mdialogVisible = true;
				// 初始化数据
					//如果是时间段
				if(!item.isOpenAllHours){
					this.startTime = item.shopTime;
					this.endTime = item.closingTime;
					this.timeType = 2;
					this.handleDialogTime(2);
				}else{
					this.timeType = 1;
					this.handleDialogTime(1);
				}
			},
			// 营业管理浮层 关闭
			businessManagementClose : function(){
				this.mdialogVisible = false;
			},
			// 营业管理浮层 确定
			businessManagementOk : function(){
				let sendUrl = '/trader/updateBusinessHours/update';
				let sendObj = {
					traderId : this.storeInfo[this.mIndex].traderId
				};
				// 如果是24小时
				if(this.timeType === 1){
					sendObj.isOpenAllHours = true;
				}else{
					if(!this.startTime){
						this.$message.warning('请选择开始时间');
						return;
					}
					if(!this.endTime){
						this.$message.warning('请选择结束时间');
						return;
					}
					sendObj.shopTime = this.startTime;
					sendObj.closingTime = this.endTime;
				}
				axiosApi(sendUrl,sendObj,(res) => {
					let data = res.data;
					this.$message.success('设置成功');
					this.businessManagementClose();
					this.manyStoresHomePage();
				});
			},
			// 营业管理浮层 时间chnage
			handleDialogTime : function(val){
				// 如果是时间段
				if(val === 2){
					this.dialogTimeDisabled = false;
				}else{
					this.dialogTimeDisabled = true;
					this.startTime = '';
					this.endTime = '';
				}
			},
			// 设置店铺状态逻辑
			setDianPuTypeFn : function(item,type){
				let sendUrl = '/trader/updateBusinessHours/update';
				let sendObj = {
					traderId : item.traderId,
					businessStatus : type,
				};
				axiosApi(sendUrl,sendObj,(res) => {
					let data = res.data;
					this.$message.success('设置成功');
					this.manyStoresHomePage();
				});
			},
			// 打烊店铺
			handleDaYang : function(item,index){
				this.setDianPuTypeFn(item,1);
			},
			// 开始营业
			handleOpenStart : function(item,index){
				this.$confirm('是否开始营业？', '开始营业', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					}).then(() => {
						this.setDianPuTypeFn(item,0);
					}).catch(() => {
				});
			},
			// 开启店铺
			handleOpenShop : function(item){
				this.setDianPuTypeFn(item,0);
			},
			// 关闭店铺
			handleCloseShop : function(item){
				this.$confirm('关闭店铺后您的店铺以及您的商品信息将无法被用户检索！', '关闭店铺', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
					}).then(() => {
						this.setDianPuTypeFn(item,2);
					}).catch(() => {
				});
			},

			...mapMutations({
				"sth": "sth"
			}),
			//删除店铺
			deleteTraderRecord(item) {
				//				console.log(this.userInfo)
				this.$confirm('您确定要删除本次申请记录？', '提示信息', {
					confirmButtonText: '确定',
					type: 'warning'
				}).then(() => {
					axiosApi("/trader/deleteTraderRecord/delete", {
						loginName: sessionStorage.loginName,
						traderId: item.traderId
					}, res => {
						if(res.data.data == 1) {
							this.$message({
								type: "success",
								message: "操作成功",
								duration: 1000
							})
							this.manyStoresHomePage()
						}
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消',
						duration: 1000
					})
				})
			},
			//进店
			inStore(item) {
				//console.log(item)
				// axiosApi("/employee/login", {
				// 	loginType: 5,
				// 	traderId: item.traderId,
				// 	loginName: sessionStorage.loginName,
				// 	appId: 2
				// }, res => {
				// 	sessionStorage.traderId = item.traderId;
					//this.sth({traderId:item.traderId,path:"stm"})后期不知做不做，先注释
					this.$router.push(`${basePath}/goodsSearch/classify/brand/goodsName/${item.traderId}`)
				// })
			},
			//查看申请
			lookApply(item) {
				this.$router.push(basePath + "/register/step2/" + item.traderId + "/" + item.state); //此处state也用path接收。
			},
			//创建新店铺
			goPage() {
				this.$router.push(basePath + "/register/step2");
			},
			//默认加载店铺信息
			manyStoresHomePage() {
				axiosApi("/trader/manyStoresHomePage/select", {
					loginName: sessionStorage.loginName
				}, res => {
					this.userInfo = res.data.data;
					if(res.data.data.list) {
						this.storeInfo = res.data.data.list;
					}
				})
			}
		}
	}
</script>

<style lang="less">
	#storeManage {
		.mListBox{
			.mInfo{
				padding: 30px 20px;
				padding-bottom: 0;
				height: 60px;
				img{
					width: 60px;
					height: 60px;
					display: inline-block;
					vertical-align: middle;
				}
				.text{
					display: inline-block;
					vertical-align: middle;
					max-width: 256px;
					margin-left: 10px;
				}
				.type{
					float: right;
				}
			}
			.mhandle{
				padding: 30px 20px;
				padding-bottom: 0;
				margin-top: 20px;
				.mh{
					float: right;
					a{
						color: #20a0ff;
						cursor: pointer;
					}
				}
			}
		}
		.mDialogBox{
			.origin{
				color: #FF4201;
			}
			.el-dialog{
				height: 400px!important;
				min-height: 400px!important;
			}
			.el-dialog__body{
				padding: 0!important;
			}
			.mDialogContent{
				width: 80%;
				margin: 0 auto;
				>div{
					margin-top: 30px;
				}
			}
		}
		.pay-order-content {
			width: 1280px;
			margin: 0 auto;
			padding: 0 45px;
			box-sizing: border-box;
			.content {
				padding: 50px 82px;
				.lh-150 {
					height: 150px;
					line-height: 150px;
					border: 1px solid #ddd;
					.mt-60 {
						margin-top: 60px;
					}
				}
				.my-store {
					border: 1px solid #ddd;
					padding: 30px 0 50px 30px;
					.e-store {
						width: 478px;
						height: 202px;
						margin-top: 10px;
						margin-right: 10px;
						position: relative;
						border: 1px solid #ddd;
						.icon-pending,
						.icon-audit-failure {
							font-size: 100px;
							position: absolute;
							left: 50%;
							top: 50%;
							transform: translate(-50%, -50%);
							color: #fff;
						}
						.icon-plus-sign {
							font-size: 50px;
							color: #999;
						}
						.mt-37 {
							margin-top: 37px;
						}
						.cover,
						.coverf {
							width: 234px;
							height: 234px;
							background-color: #000;
							border: 1px solid #ddd;
							opacity: 0.4;
							position: absolute;
							top: 0;
							left: 0;
						}
						.coverf {
							background-color: #bbb;
							opacity: 1;
							span {
								/*// display: inline-block;*/
								float: right;
								width: 60px;
								height: 60px;
								line-height: 60px;
								text-align: center;
								background-color: #ff4201;
							}
						}
					}
				}
			}
		}
	}
</style>
