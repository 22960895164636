<template>
	<div id="groupStatement" class="pb-50">
		<!--<div class="title-top bg-f">
			团购报表
		</div>-->
		<div class="title-tabs bg-f black">
			<ul class="clearfix">
				<template v-for='(tab,index) in dataTabs' class="item">
					<li :type='index' :class="type==index?'active':''" @click='handleTabs' :key="index+'ay'">
						{{tab}}
						<div :class="type==index?'under-line':''"></div>
					</li>
					<div class="vertical-line" :key="index+'az'"></div>
				</template>
			</ul>
		</div>
		<div v-show='typeName == "团购报表"'>
			<div class="bg-f mt-5 pb-50">
				<el-form :inline="true" :model="formInlineData" label-width="auto" class="clearfix pl-20 pt-30 search">
					<div>
						<el-form-item label="超团商家">
							<el-input v-model.trim="formInlineData.storeName"></el-input>
						</el-form-item>
						<el-form-item label="手机">
							<el-input v-model.trim="formInlineData.tel" @input="formInlineData.tel=formInlineData.tel.replace(/[\u4e00-\u9fa5]/ig,'')"></el-input>
						</el-form-item>
						<el-form-item label="团购名称">
							<el-input v-model.trim="formInlineData.grouponName"></el-input>
						</el-form-item>
						<el-form-item label="参团企业">
							<el-input v-model.trim="formInlineData.companyName"></el-input>
						</el-form-item>
						<!-- <el-form-item label='分享时间'>
							<ys-daterange-picker v-model="formInlineData.orderDateRange" type="datetime" />

						</el-form-item> -->
						<el-form-item>
							<el-button type="info" class="fl ml-30" @click='handleSearch' icon="el-icon-search">查 询</el-button>
						</el-form-item>
					</div>
					<div>
					</div>
				</el-form>
				<div class="clearfix pr-20">
					<span class="color-blue cursor fr mr-30 mb-10" @click="exportStatement">批量导出</span >
			</div>

	<div class="ml-30 mt-10 table-default mr-30" >
		<el-table :data="tableData" border style="width: 100%" @selection-change="handleSelectionChange" >
			<el-table-column type="selection" width="40"></el-table-column>
			<el-table-column  label='团购名称' prop="grouponName" width="120" align="center"></el-table-column>
			<el-table-column label='超团商家'  width="170" align="center">
				<template slot-scope="{row}">{{row.storeName}}<br/>{{row.tel}}</template>
			</el-table-column>
			<el-table-column label='参团企业' align="center">
				<template slot-scope="{row}">
					<span>{{row.companyName}} </span>
				</template>
			</el-table-column>
			<el-table-column label='付款订单数' align="center" >
				<template slot-scope="{row}">
					<span v-if="row.companyVOList[0]&&(row.companyVOList[0].paidNum!=null)" class="color-blue tac " style="cursor:pointer;" @click='open(row)'>{{row.companyVOList[0].paidNum}}</span>
					<span v-else>--</span>
			    </template>
			</el-table-column>
			<el-table-column label='应付金额' align="center">
				<template slot-scope="{row}">
					<span v-if="row.companyVOList[0]&&(row.companyVOList[0].orderTotalMoney!=null)">{{row.companyVOList[0].orderTotalMoney}}</span>
					<span v-else>--</span>
				</template>
			</el-table-column>
			<el-table-column label='运费'  align="center">
				<template slot-scope="{row}"> 
					<span v-if="row.companyVOList[0]&&(row.companyVOList[0].freightTotalMoney!=null)">{{row.companyVOList[0].freightTotalMoney}}</span>
					<span v-else>--</span>
				</template>
			</el-table-column>
			<el-table-column label='实付金额'  align="center">
				<template slot-scope="{row}">
					<span v-if="row.companyVOList[0]&&(row.companyVOList[0].paidTotalMoney!=null)">{{row.companyVOList[0].paidTotalMoney}}</span>
					<span v-else>--</span>
				</template>
			</el-table-column>
			<el-table-column label='代金券抵扣' align="center">
				<template slot-scope="{row}">
					<span v-if="row.companyVOList[0]&&(row.companyVOList[0].voucherTotalMonney!=null)">{{row.companyVOList[0].voucherTotalMonney}}</span>
					<span v-else>--</span>
				</template>
			</el-table-column>
			<el-table-column label='客单价' align="center">
				<template slot-scope="{row}">
					<span v-if="row.companyVOList[0]&&(row.companyVOList[0].perTransaction!=null)">{{row.companyVOList[0].perTransaction}}</span>
					<span v-else>--</span>
				 </template>
			</el-table-column>
			<el-table-column label='操作' align="center">
				<template slot-scope="{row}">
					<div class="tac cursor color-blue"  @click="Statistics(row.grouponId)">统计</div>
					<div v-if="row.companyVOList.length>0" class="tac cursor color-blue" @click="goDetail(row)">详细数据</div>
				</template>
				
			</el-table-column>
		</el-table>

	</div>



		<el-pagination v-if='total>0' class="tar mt-20" :current-page="currentPage" :page-size='10' :total='total' layout="prev , pager, next, jumper" @current-change="handleCurrentChange">
			<!-- 分页组件 -->
		</el-pagination>
		<!--订单弹框-->
		<el-dialog :title="title" :visible.sync='openOrder' width="900px">
			<div class="clearfix mb-10">
				<div class="fl ml-50">
					<span>订单编号：</span>
					<el-input v-model.trim='openOrders' placeholder="请输入订单编号"></el-input>
				</div>
				<el-button type="info" class="fl ml-50" @click='getSearch(0)' icon="el-icon-search">搜 索</el-button>
			</div>
			<div v-loading='loading' class="table-item-default tac" v-if="ordersListResVo.length<=0">
				<!--<img src="../../assets/empty.png" alt="" />-->
				<div class="color-blue mt-30"><span class="cursor">暂无数据 </span></div>
			</div>
			<div v-else class="content mr-30 ml-30" v-loading='loading'>
				<el-table>
					<el-table-column label='商品' width="350" align="center"></el-table-column>
					<el-table-column label='单价' width="70" align="center"></el-table-column>
					<el-table-column label='数量' width="120" align="center"></el-table-column>
					<el-table-column label='   ' width="10"></el-table-column>   
					<!-- 因为写了公共样式只能空表示占位符保持排版不变 -->
					<el-table-column label='小计' width="80" align="center"></el-table-column>
					<el-table-column label='订单状态' align="center"></el-table-column>
				</el-table>
				<div class="table-item mt-10" v-for='(orders, index) in ordersListResVo' :key='index'>
					<div class="clearfix table-item-top lh-20 bbd bg-e">
						<span v-if='orders.isSplit' class='fl ml-30'>订单编号：{{orders.mainOrderSn}}</span>
							<span v-else>
								<span v-if='orders.orderStatus==1 || orders.orderStatus==0'>订单编号：{{orders.mainOrderSn}}</span>
								<span v-else>订单编号：{{orders.smallGrouponOrderListVOList[0].sn}}</span>
							</span>
						<span class='fl color-6 ml-30'>下单时间：{{orders.gmtCreate | time}}</span>
						<span v-if='orders.isSplit' class='fl color-6 ml-30'>订单状态:已拆分</span>
					</div>
					<div v-for='(order,index) in orders.smallGrouponOrderListVOList' :key="'orders-'+index">
						<div v-if='orders.isSplit' class="clearfix table-item-top lh-20 bbd">
								<span class='fl ml-30'>订单编号：{{order.sn}}</span>
						</div>
					<div class="table-item-content clearfix bbd" style="display: flex;">
						<div class="clearfix" style="box-sizing: border-box;">
							<!--商品清单-->
							<div class="clearfix" :class='index != order.orderItemVos.length-1 ? "bb":""' v-for="(orderItem, index) in order.orderItemVos" :key="index" style="padding: 20px 0 20px 20px;">
								<div class="fl" style="width: 320px;">
									<img v-if="orderItem.skuImage" :src="orderItem.skuImage" class="fl mr-20" />
									<img v-else src="../../assets/goods.jpg" class="fl mr-20" />
									<div class="fl">
										<p class="clearfix">
											<span class="fl">商品编码:</span>
											<span class="fl" style="width:120px;word-wrap:break-word">{{orderItem.k3GoodsNo}}</span>
										</p>
										<p style="width: 190px;">{{orderItem.skuName}}</p>
									</div>
								</div>
								<div class="fl tac " style="width:100px;">&yen;{{orderItem.originPrice | fmoney}}/{{ orderItem.unitName }}</div>
								<div class="fl tac" style="width:80px;">{{orderItem.num}}</div>
								<div class="fl tac" style="width:140px;">&yen;{{orderItem.discountTotalPrice | fmoney}}</div>
								<!-- <div v-if='orderItem.commission != null' class="fl tac" style="width:110px;">&yen;{{orderItem.commission | fmoney}}</div>
								<div v-else='orderItem.commission == null' class="fl tac" style="width:110px;">--</div> -->
							</div>
							<!--赠清单-->
							<div class="clearfix" v-for="(orderItem, index) in order.orderActivityGiftRelationVOs" :key="index" style="padding: 20px 0 20px 20px;border-top: 1px solid #DDDDDD;">
								<div class="fl" style="width: 309px;">
									<div style="position: relative;">
									<img v-if="orderItem.skuImage" :src="orderItem.skuImage" class="fl mr-20" />
									<img v-else src="../../assets/goods.jpg" class="fl mr-20" />
									<!--赠品标识-->
									<img src="../../assets/superGroup/complimentary.png" style="position: absolute;top: 0;left: 0;" />
									</div>
									<div class="fl">
										<p class="clearfix">
											<span class="fl">商品编码:</span>
											<span class="fl" style="width:100px;word-wrap:break-word">{{orderItem.k3GoodsNo}}</span>
										</p>
										<p style="width: 179px;">{{orderItem.skuName}}</p>
									</div>
								</div>
								<div class="fl tac " style="width:80px;">&yen;{{orderItem.originPrice | fmoney}}</div>
								<div class="fl tac" style="width:50px;">{{orderItem.giftNum}}</div>
								<div class="fl tac" style="width:110px;">&yen;{{orderItem.discountTotalPrice | fmoney}}</div>
								<div v-if='orderItem.commission != null' class="fl tac" style="width:110px;">&yen;{{orderItem.commission | fmoney}}</div>
								<div v-else='orderItem.commission == null' class="fl tac" style="width:110px;">--</div>
							</div>
						</div>
						<div class="bl tac pt-20 fl" style="width:200px;">
							<span v-if="order.orderStatus==0">已关闭</span>
							<span v-if="order.orderStatus==1">待付款</span>
							<span v-if="order.orderStatus==2">待发货</span>
							<span v-if="order.orderStatus==3">待收货</span>
							<span v-if="order.orderStatus==4">已完成</span>
						</div>
						<!--<div class="bl tac pt-20 fl" style="width:100px;">
							<span>{{order.type == 1 ? '大B自销' : order.type == 2 ? '小B自销' : '小B代销'}}</span>
						</div>-->
					</div>
					</div>
				</div>
				<el-pagination v-if='total1>0' class="tar mt-15" :current-page="currentPage1" :page-size='10' :total='total1' layout="prev , pager, next, jumper" @current-change="handleCurrentChangeOpen">
					<!-- 分页组件 -->
				</el-pagination>
			</div>
		</el-dialog>
		<!--商品弹框-->
		<el-dialog :title="title" :visible.sync='openGoods'>
			<div class="clearfix mb-10">
				<div class="fl ml-50">
					<span>商品名称：</span>
					<el-input v-model.trim='skuName' placeholder="请输入内容"></el-input>
				</div>
				<el-button type="info" class="fl ml-50" @click='getGoods(0)' icon="el-icon-search">搜 索</el-button>
			</div>
			<div v-loading='loading' class="table-item-default tac" v-if="allGoods.length<=0">
				<!--<img src="../../assets/empty.png" alt="" />-->
				<div class="color-blue mt-30"><span class="cursor">暂无数据 </span></div>
			</div>
			<div v-else class="content mr-30 ml-30" v-loading='loading'>
				<el-table class='mb-10'>
					<el-table-column label='卖家' width="350" align="center"></el-table-column>
					<el-table-column label='商品' width="420" align="center"></el-table-column>
					<el-table-column label='数量' align="center"></el-table-column>
				</el-table>
				<div class="table-goods clearfix" :class="{'e':index == 0}" v-for='(i, index) in allGoods' :key='index'>
					<div class="fl a tac">{{i.storeName}}</div>
					<div class="fl b clearfix">
						<img v-if="i.skuVO.fileUrl" :src="i.skuVO.fileUrl" class="fl mr-20" />
						<img v-else src="../../assets/goods.jpg" class="fl mr-20" />
						<div class="fl">
							<p class="clearfix">
								<span class="fl">商品编码:</span>
								<span class="fl" style="width:240px;word-wrap:break-word">{{i.skuVO.skuNo}}</span>
							</p>
							<p style="width: 300px;">{{i.skuVO.skuName}}</p>
						</div>
					</div>
					<div class="fl c tac">{{i.amount}}</div>
				</div>
				<el-pagination v-if='total2>0' class="tar mt-15" :current-page="currentPage2" :page-size='10' :total='total2' layout="prev , pager, next, jumper" @current-change="handleCurrentChangeOpenGood">
					<!-- 分页组件 -->
				</el-pagination>
			</div>
		</el-dialog>
	</div>
	</div>
	
	<!--业务员提成统计-->
	<div v-show='typeName == "业务员提成统计"'>
		<div>
			<div class="bg-f mt-5 pb-50 pl-30 pr-30">
				<el-form :inline="true" :model="form" label-width="90px" class="clearfix pt-30 search">
					<div>
						<el-form-item label="超团商家">
							<el-input v-model.trim="form2.sellerName"></el-input>
						</el-form-item>
						<el-form-item label="团购名称">
							<el-input v-model.trim="form2.grouponName"></el-input>
						</el-form-item>
						<el-form-item label="业务员名称">
							<el-input v-model.trim="form2.salesName"></el-input>
						</el-form-item>
					</div>
					<div>
						<el-button type="info" class="fl ml-30" @click='achieveSalesman(0)' icon="el-icon-search">搜 索</el-button>
					</div>
				</el-form>
				<div class="clearfix pr-20">
					<span class="color-blue cursor fr mr-30 mb-10" @click="exportSales">批量导出</span >
				</div>
				<el-table :data="tableDataSalesman" class='mt-10 table-default' stripe>
					<el-table-column label="超团商家" min-width="80">
						<template slot-scope="scope">
							<div>{{scope.row.sellerName}}</div>
						</template>
					</el-table-column>
					<el-table-column label="团购名称" min-width="100" prop='grouponName'></el-table-column>
					<el-table-column label="团购时间" min-width="90">
						<template slot-scope="scope">
							<div>{{scope.row.gmtStart|timenData}} 至   {{scope.row.gmtEnd|timenData}}</div>
						</template>
					</el-table-column>
					<el-table-column label="关联业务员" min-width="60" prop='salesName'></el-table-column>
					<el-table-column label="提成金额" min-width="50">
						<template slot-scope="scope">
							<div>{{scope.row.actualIncome}}元</div>
						</template>
					</el-table-column>
					<el-table-column label="操作" min-width="100">
						<template slot-scope="scope">
							<div class="color-blue cursor" @click="getThree(scope)">提成明细</div>
						</template>
					</el-table-column>
				</el-table>
				<el-pagination class="tar mt-20" v-if="totalSalesman>0" :current-page="currentPageSalesman" :page-size='10' :total='totalSalesman' layout="prev , pager, next, jumper" @current-change="handleCurrentChangeSalesman">
					<!-- 分页组件 -->
				</el-pagination>
			</div>
			<!--提成明细弹框-->
			<el-dialog title="提成明细" :visible.sync='openGoodsThree'>
				<div class="clearfix mb-10">
					<div class="fl ml-50">
						<span>商品名称：</span>
						<el-input v-model.trim='threeskuName' placeholder="请输入内容"></el-input>
					</div>
					<el-button type="info" class=" ml-30" @click='getThreeData(0)'>搜索</el-button>
				</div>
				<div class="clearfix mb-10">
					<span class="fr color-blue mr-30 cursor" @click="getExport">批量导出</span>
				</div>
				<div class="table-item-default tac" v-if="threeList.length<=0">
					<div class="color-blue mt-30"><span class="cursor">暂无数据 </span></div>
				</div>
				<div v-else class="content mr-30 ml-30" >
					<el-table class='mb-10'>
						<el-table-column label='商品名称' min-width="400" align="center"></el-table-column>
						<el-table-column label='规格' min-width="80" align="center"></el-table-column>
						<el-table-column label='商品分类' min-width="80" align="center"></el-table-column>
						<!--<el-table-column label='单瓶提成(元)' width="80" align="center"></el-table-column>-->
						<el-table-column label='总销量(瓶)' min-width="80" align="center"></el-table-column>
						<el-table-column label='总提成金额(元)' min-width="160" align="center"></el-table-column>
					</el-table>
					<div class="table-goods clearfix" :class="{'e':index == 0}" v-for='(i, index) in threeList' :key='index' style="display: flex">
						<div class="fl b clearfix" style="flex: 5">
							<img v-if="i.skuVO.fileUrl" :src="i.skuVO.fileUrl" class="fl mr-20" />
							<img v-else src="../../assets/goods.jpg" class="fl mr-20" />
							<div class="fl">
								<p class="clearfix">
									<span class="fl">商品编码:</span>
									<span class="fl" style="width:240px;word-wrap:break-word">{{i.skuVO.k3GoodsNo}}</span>
								</p>
								<p style="width: 240px;">{{i.skuVO.skuName}}</p>
							</div>
						</div>
						<!--<div class="fl c tac">&yen;{{i.salesIncome}}</div>-->
						<div class="fl c tac" style="flex: 1;">1*{{i.skuVO.specInfoNum}}</div>
						<div class="fl c tac" style="flex: 1;">{{i.skuVO.classifyName}}</div>
						<div class="fl c tac" style="flex: 1;">{{i.amount}}</div>
						<div class="fl c tac" style="flex: 2;">&yen;{{i.salesIncome}}</div>
					</div>
					<el-pagination class="tar mt-15" v-if="totalThree>0" :current-page="currentPageThree" :page-size='10' :total='totalThree' layout="prev , pager, next, jumper" @current-change="handleCurrentChangeThree">
						<!-- 分页组件 -->
					</el-pagination>
				</div>
			</el-dialog>
			<!-- 导出 -->
			<form ref="one" action='/sunrise-gateway/export/groupon/saleIncome/skuList' style="display: none">
				<input type="hidden" name="modelName" v-model="a">
				<input type="hidden" name="fileName" v-model="bbbb">
				<input type="hidden" name="startRow" v-model="c">
				<input type="hidden" name="skuName" v-model="exportGood">
				<input type="hidden" name="grouponCompanyId" v-model="grouponCompanyId">
			</form>
		</div>
	</div>
	<!-- 导出 -->
	<form ref="downClientForm1" action='/sunrise-gateway/export/groupon/statisticsGroupon/list' style="display: none">
		<input type="hidden" name="modelName" v-model="a">
		<input type="hidden" name="fileName" v-model="b">
		<input type="hidden" name="startRow" v-model="c">
		<input type="hidden" name="gIdList" v-model="checkOrderSn">
		<!--团id list-->
	</form>
	<form ref="downClientForm2" action='/sunrise-gateway/export/groupon/statisticsGroupon/list' style="display: none">
		<input type="hidden" name="modelName" v-model="a">
		<input type="hidden" name="fileName" v-model="b">
		<input type="hidden" name="startRow" v-model="c">
		<input type="hidden" name="tel" v-model="formInlineData.tel">
		<!--手机-->
		<input type="hidden" name="storeName" v-model="formInlineData.storeName">
		<!--超团卖家-->
		<input type="hidden" name="grouponName" v-model="formInlineData.grouponName">
		<!--团购名称-->
		<input type="hidden" name="companyName" v-model="formInlineData.companyName">
		<!--企业名称-->
		<input type="hidden" name="startDate" v-model="formInlineData.startDate">
		<!--开始时间-->
		<input type="hidden" name="endDate" v-model="formInlineData.endDate">
		<!--结束时间-->
	</form>
	<!--运费导出-->
	<form ref="downClientForm3" action='/sunrise-gateway/export/groupon/settlementFreight/list' style="display: none">
		<input type="hidden" name="modelName" v-model="a">
		<input type="hidden" name="fileName" v-model="bb">
		<input type="hidden" name="startRow" v-model="c">
		<input type="hidden" name="grouponName" v-model="form1.grouponName">
		<!--超团商家-->
		<input type="hidden" name="sendStroreName" v-model="form1.sendStroreName">
		<!--发货商家-->
		<input type="hidden" name="storeName" v-model="form1.storeName">
		<!--开始时间-->
		<input type="hidden" name="orderSn" v-model="form1.orderSn">
		<!--订单编号-->
	</form>
	<!--业务员统计导出-->
	<form ref="downClientForm4" action='/sunrise-gateway/export/groupon/saleIncome/list' style="display: none">
		<input type="hidden" name="modelName" v-model="a">
		<input type="hidden" name="fileName" v-model="bbb">
		<input type="hidden" name="startRow" v-model="c">
		<input type="hidden" name="sellerName" v-model="form3.sellerName">
		<!--超团商家-->
		<input type="hidden" name="grouponName" v-model="form3.grouponName">
		<!--团购名称-->
		<input type="hidden" name="salesName" v-model="form3.salesName">
		<!--业务员-->
	</form>
	</div>
</template>

<script>
	import { axiosApi, basePath } from "../../api/api";
	import YsDaterangePicker from "~/components/ys-daterange-picker";
	export default {
		components: {YsDaterangePicker},
		data() {
			return {
				dataTabs: ["团购报表", "运费统计","业务员提成统计"],
				type: 0,
				typeName:'',
				a: 'empty',
				b: '团购报表.xls',
				bb: '运费统计报表.xls',
				bbb: '业务员提成统计报表.xls',
				bbbb: '业务员提成明细报表.xls',
				c: 0,
				cc: 1,
				loading: false,
				loading1: true,
				checkAll: false, //选中状态
				checkOrder: [], //选中的每行订单数据
				checkOrderSn: [], //选中的每行订单数据
				tableData: [], //团购列表数据
				formInlineData: {
					tel: '', //手机
					storeName: null, //超团卖家
					grouponName: null, //团购名称
					companyName: null, //企业名称
					startDate: null, //start
					endDate: null, //end
					orderDateRange: [], //时间范围
				},
				total: 0,
				currentPage: 1,
				total1: 0,
				total2: 0,
				currentPage1: 1,
				currentPage2: 1,
				openOrder: false,
				ordersListResVo: [], //订单数据
				title: '', //弹框title
				grouponCompanyId: '', //弹框传值
				openGoods: false,
				openOrders: '', //弹框订单搜索
				skuName: '', //弹框商品数据
				allGoods: [], //弹框商品数据
				arr: [],
//				运费统计
				form:{
					storeName:'',
					grouponName:'',
					sendStroreName:'',
					orderSn:''
				},
				form2:{
					sellerName:'',
					grouponName:'',
					salesName:''
				},
				form1:{
					storeName:'',
					grouponName:'',
					sendStroreName:'',
					orderSn:''
				},
				form3:{
					sellerName:'',
					grouponName:'',
					salesName:''
				},
				totalFreight: 0,
				currentPageFreight: 1,
				tableDataFreight:[], //运费数据
				totalSalesman: 0,
				currentPageSalesman: 1,
				tableDataSalesman:[], //运费数据
				openGoodsThree:false,//提成明细弹框
				threeList:[],
				threeskuName:null,
				grouponCompanyId:null,
				totalThree: 0,
				currentPageThree: 1,
				exportGood:'',//导出商品名称
			}
		},
		methods: {
			getThree(scope){
				this.openGoodsThree = true
				this.grouponCompanyId = scope.row.id
				this.getThreeData()
			},
			getThreeData(type){
				if(type){
					this.currentPageThree = 1
					return false;
				}
				axiosApi('/groupon/saleIncome/skuList', {
					grouponCompanyId:this.grouponCompanyId,
					skuName:this.threeskuName
				}, (res) => {
					this.threeList = res.data.data ? res.data.data.elements : []
					this.totalThree = res.data.data ? res.data.data.totalCount : '';
				}, this.currentPageThree, 10, (res) => {
					this.loading1 = false
					this.$message({
						type: 'error',
						message: res.data.message,
						duration: 1000
					})
				})
			},
			//业务员明细分页
			handleCurrentChangeThree(val) {
				this.currentPageThree = val
				this.getThreeData()
			},
			//tab切换
			handleTabs(e) {
				if(e.target.attributes.type){
					this.type = e.target.attributes.type.value
					this.typeName = e.target.attributes.type.ownerElement.innerText.trim()
				}
				// if(this.typeName == "运费统计") {
				// 	this.currentPageFreight = 1
				// 	this.achieveFreight()
				// } 
				if(this.typeName == "团购报表") {
					this.currentPage= 1
					this.achieve()
				}else if(this.typeName == "业务员提成统计" ){
					this.currentPageSalesman = 1
					this.achieveSalesman()
				}
			},
			//批量导出
			exportStatement() {
				if(this.checkOrderSn.length > 0) {
					this.$refs.downClientForm1.submit();
					// this.checkAll = false
					// this.handleCheckAllChange()
					this.handleSelectionChange();
					this.handleSearch();
				} else {
					if(this.tableData.length > 0) {
						this.formInlineData.startDate = this.formInlineData.orderDateRange[0] ? this.formInlineData.orderDateRange[0].getTime() : null
						this.formInlineData.endDate = this.formInlineData.orderDateRange[1] ? this.formInlineData.orderDateRange[1].getTime() : null
						this.$refs.downClientForm2.submit();
					} else {
						this.$message({
							type: 'info',
							message: '暂无数据可导出!',
							duration: 1000
						});
					}

				}

			},
			getExport(){
				this.exportGood = ''
				this.exportGood = this.threeskuName
				if(this.threeList.length > 0){
					this.$refs.one.submit();
				}else{
					this.$message({
							type: 'info',
							message: '暂无数据可导出!',
							duration: 1000
						});
				}
			},
			//批量导出
			exportFreight() {
				if(this.tableDataFreight.length > 0) {
					this.$refs.downClientForm3.submit();
				} else {
						this.$message({
							type: 'info',
							message: '暂无数据可导出!',
							duration: 1000
						});

				}

			},
			//批量导出业务员
			exportSales() {
				if(this.tableDataSalesman.length > 0) {
					this.$refs.downClientForm4.submit();
				} else {
						this.$message({
							type: 'info',
							message: '暂无数据可导出!',
							duration: 1000
						});

				}

			},
			open(val) {
				this.loading = true
				this.openOrders = '' //点击清空搜索条件
				this.currentPage1 = 1
				this.title = val.grouponName
				this.grouponId = val.grouponId
				this.getSearch(val.grouponId)
				this.openOrder = true
			},
			openGood(val) {
				this.loading = true
				this.skuName = '' //点击清空搜索条件
				this.currentPage2 = 1
				this.title = val.companyName
				this.grouponCompanyId = val.grouponCompanyId
				this.getGoods(val.grouponCompanyId)
				this.openGoods = true
			},
			// handleCheckAllChange(val) { /*table全选*/
			// 	if(this.checkAll) {
			// 		this.tableData.forEach(item => {
			// 			this.checkOrder.push(item)
			// 		});
			// 		this.tableData.forEach(item => {
			// 			this.checkOrderSn.push(parseInt(item.grouponId))
			// 		});
			// 	} else {
			// 		this.checkOrder = [];
			// 		this.checkOrderSn = []
			// 	}
			// },
			// handleCheckChange(val) { //单选
			// 	let checkedCount = val.length;
			// 	this.checkAll = checkedCount === this.tableData.length;
			// 	this.arr = val.map(i => i.grouponId).join()
			// 	this.checkOrderSn = this.arr.split(",")
			// },

			handleSelectionChange(val){   //选择团购
				this.checkOrder = []; //清空
				this.checkOrderSn = []; //清空

				console.log(val,"99");
				if(val&&val.length>0){
					val.forEach(item => {
					this.checkOrder.push(item)
					this.checkOrderSn.push(parseInt(item.grouponId))
				});
				}
				console.log(val,"3333");
				
			},

			achieve(body) {
				this.loading1 = true
				axiosApi('/groupon/statisticsGroupon/list', body, (res) => {
					this.tableData = res.data.data.elements ? res.data.data.elements : []
					this.total = res.data.data ? res.data.data.totalCount : '';
					this.loading1 = false
				}, this.currentPage, 10, (res) => {
					this.loading1 = false
					this.$message({
						type: 'error',
						message: res.data.message,
						duration: 1000
					})
				})
			},
			//统计
			Statistics(body) {
				axiosApi('/groupon/statisticsGroupon/handing',{grouponId:body}, 
				(res) => {
					if (res.data.code === "000000") {
						this.$message.success("统计成功");
					}
					else{
						this.$message.warning("统计失败");
					}
				},  
				(err) => {
					this.$message({
						type: 'error',
						message:err.data.message,
						duration: 1000
					})
				})
				this.handleSearch();			
			},
			//企业订单弹窗数据
			getSearch(payload) {
				payload == 0 && (this.currentPage1 = 1)
				this.loading = true
				axiosApi('/groupon/orderList/select', {
					orderSn: this.openOrders,
					grouponId: this.grouponId
				}, (res) => {
					this.total1 = res.data.data ? res.data.data.totalCount : ''
					this.ordersListResVo = res.data.data ? res.data.data.elements : []
					this.loading = false
				}, this.currentPage1, 10, (res) => {
					this.$message({
						type: 'error',
						message: res.data.message,
						duration: 1000
					})
					this.loading = false
				})
			},
			//商品弹窗数据
			getGoods(payload) {
				payload == 0 && (this.currentPage2 = 1)
				this.loading = true
				axiosApi('/groupon/orderSales/list', {
					skuName: this.skuName,
					grouponCompanyId: this.grouponCompanyId
				}, (res) => {
					this.total2 = res.data.data ? res.data.data.totalCount : ''
					this.allGoods = res.data.data ? res.data.data.elements : []
					this.loading = false
				}, this.currentPage2, 10, (res) => {
					this.$message({
						type: 'error',
						message: res.data.message,
						duration: 1000
					})
					this.loading = false
				})
			},
			//表单条件查询
			handleSearch() {
				//获取开始接受时间
				this.formInlineData.startDate = this.formInlineData.orderDateRange[0] ? this.formInlineData.orderDateRange[0].getTime() : null
				this.formInlineData.endDate = this.formInlineData.orderDateRange[1] ? this.formInlineData.orderDateRange[1].getTime() : null
				if(this.currentPage === 1) {
					this.achieve(this.formInlineData)
				} else {
					this.currentPage = 1; //赋值自动调用一次接口
				}
			},
			//改变分页(弹窗)
			handleCurrentChangeOpen(val) {
				this.currentPage1 = val
				this.getSearch()
			},
			//改变分页
			handleCurrentChange(val) {
				this.checkAll = false
				this.currentPage = val
				this.achieve(this.formInlineData)
			},
			handleCurrentChangeOpenGood(val) {
				this.currentPage2 = val
				this.getGoods()
			},
			//运费分页
			// handleCurrentChangeFreight(val) {
			// 	this.currentPageFreight = val
			// 	this.achieveFreight()
			// },
			//业务员统计分页
			handleCurrentChangeSalesman(val) {
				this.currentPageSalesman = val
				this.achieveSalesman()
			},
//			运费数据
			achieveFreight(payload){
				if(payload == 0){
					this.currentPageFreight = 1
					this.form1 = Object.assign({}, this.form);
				}

				axiosApi('/groupon/settlementFreight/list', {
					storeName:this.form.storeName,
					grouponName:this.form.grouponName,
					sendStroreName:this.form.sendStroreName,
					orderSn:this.form.orderSn
				}, (res) => {
					this.totalFreight = res.data.data ? res.data.data.totalCount : ''
					this.tableDataFreight = res.data.data ? res.data.data.elements : []
				}, this.currentPageFreight, 10, (res) => {
					this.$message({
						type: 'error',
						message: res.data.message,
						duration: 1000
					})
				})
			},
			//			业务员统计数据
			achieveSalesman(payload){
				if(payload == 0){
					this.currentPageSalesman = 1
					this.form3 = Object.assign({}, this.form2);
				}

				axiosApi('/groupon/saleIncome/list', {
					sellerName:this.form2.sellerName,
					grouponName:this.form2.grouponName,
					salesName:this.form2.salesName
				}, (res) => {
					this.totalSalesman = res.data.data ? res.data.data.totalCount : ''
					this.tableDataSalesman = res.data.data ? res.data.data.elements : []
				}, this.currentPageSalesman, 10, (res) => {
					this.$message({
						type: 'error',
						message: res.data.message,
						duration: 1000
					})
				})
			},
			//去详细数据页面
			goDetail(item){
				this.$router.push(basePath + '/groupStatementDetail/' + item.grouponId + "?type="+item.grouponStatus+ "&gmtStart="+item.gmtStart+ "&gmtEnd="+item.gmtEnd)
			}
		},
		//进入加载
		mounted() {
			this.$nextTick(() => {
						this.dataTabs = []
						this.showButton("GROUP_STATEMENT",140023) && this.dataTabs.push('团购报表')
						// this.showButton("FREIGHT_COUNT",140023) && this.dataTabs.push('运费统计')
						this.showButton("MANAGER_COMMISSION_COUNT",140023) && this.dataTabs.push('业务员提成统计')
						if(this.dataTabs[0] == '团购报表'){
							this.type = 0
							this.typeName ='团购报表'
							this.currentPage= 1
							this.achieve()
						}
						// else if(this.dataTabs[0] == '运费统计'){
						// 	this.type = 0
						// 	this.typeName ='运费统计'
						// 	this.currentPageFreight = 1
						// 	this.achieveFreight()
						// }
						else{
							this.type = 0
							this.typeName ='业务员提成统计'
							this.currentPageSalesman = 1
							this.achieveSalesman()
						}
			})
		},
	}
</script>

<style lang="less">
	#groupStatement {
		.center-text .cell {
			text-align: center;
			}
		.el-dialog__body {
			padding: 20px 0 100px;
		}
		.datatimerange {
			width: 240px;
		}
		.bbe {
			border-right: 1px solid #eceef6;
			height: 70px;
			padding: 25px 0 20px 0;
			box-sizing: border-box;
		}
		.br {
			border-right: 1px solid #eceef6;
			display: flex;
			display: -webkit-flex;
			align-items: center;
		}
		.content {
			.el-table {
				border: none;
				font-size: 14px;
				th>.cell {
					line-height: 12px;
					padding: 10px 0 10px 0;
				}
				th.is-leaf {
					border-bottom: 0;
				}
			}
			.checkedAll {
				position: relative;
				top: 30px;
				left: -20px;
			}
			.el-checkbox {
				width: 30px;
				margin-left: 30px;
				.el-checkbox__input {
					height: 20px;
				}
			}
			.el-checkbox__inner {
				width: 20px;
				height: 20px;
			}
			.el-checkbox__inner::after {
				top: 2px;
			}
			.info {
				overflow: hidden;
				font-size: 12px;
				display: flex;
				/*justify-content: flex-start;
				align-items: center;*/
				border: 1px solid #eceef6;
				border-top: 0;
				.p {
					display: flex;
					align-items: center;
					justify-content: flex-start;
					border-bottom: 1px solid #eceef6;
					&:last-child {
						border-bottom: 0;
					}
					span {
						/*padding: 0 3px;
						box-sizing: border-box;*/
					}
				}
				.el-checkbox__input {
					margin-left: 5px !important;
				}
			}
			.bb {
				border-bottom: 1px solid #eceef6;
			}
			.bl {
				border-left: 1px solid #eceef6;
			}
			.el-table .el-table__body-wrapper {
				display: none;
			}
			.table-item {
				border: 1px solid #eceef6;
				font-size: 14px;
				border-bottom: none;
				.table-item-content .pbl-20 {
					padding: 0px 0px 20px 20px;
				}
				.table-item-top {
					border-bottom: 1px solid #eceef6;
					padding: 10px 0;
					.table-item-top-checkbox {
						margin-left: 14px
					}
				}
			}
			.table-item-default {
				height: 530px;
				padding-top: 120px;
			}
			.el-date-editor--daterange.el-input {
				width: 240px;
			}
			.el-form--inline {
				width: 650px;
				.el-form-item__label {
					font-size: 12px;
					width: 60px;
				}
			}
			.el-table .el-table__body-wrapper {
				display: none;
			}
			.order-datatimerange {
				width: 240px;
			}
			.el-table {
				border: none;
				font-size: 14px;
				th>.cell {
					line-height: 12px;
					padding: 10px 0 10px 0;
				}
				th.is-leaf {
					border-bottom: 0;
				}
			}
			.el-checkbox {
				width: 30px;
				margin-left: 30px;
				.el-checkbox__input {
					height: 20px;
				}
			}
			.el-checkbox__inner {
				width: 20px;
				height: 20px;
			}
			.el-checkbox__inner::after {
				top: 2px;
			}
			.table-item {
				.bl {
					border-left: 1px solid #DDD;
				}
				border: 1px solid #eceef6;
				font-size: 14px;
				border-bottom: none;
				.table-item-content .pbl-20 {
					padding: 0px 0px 20px 20px;
				}
				.table-item-top {
					padding: 10px 0;
					.table-item-top-checkbox {
						margin-left: 14px
					}
				}
				img {
					width: 80px;
					height: 80px;
				}
			}
			.table-item-default {
				height: 530px;
				padding-top: 120px;
			}
			.el-checkbox__label {
				display: none;
			}
		}
		.el-dialog {
			width: 950px !important;
		}
		.table-goods {
			border-left: 1px solid #F2F2F2;
			border-right: 1px solid #F2F2F2;
			.a {
				width: 355px;
				height: 120px;
				line-height: 120px;
				border-bottom: 1px solid #F2F2F2;
				border-right: 1px solid #F2F2F2;
			}
			.b {
				width: 426px;
				height: 121px;
				padding: 20px 0 20px 20px;
				box-sizing: border-box;
				border-bottom: 1px solid #F2F2F2;
				border-right: 1px solid #F2F2F2;
				img {
					width: 80px;
					height: 80px;
				}
			}
			.c {
				width: 104px;
				height: 120px;
				line-height: 120px;
				border-bottom: 1px solid #F2F2F2;
			}
		}
		.e {
			border-top: 1px solid #F2F2F2;
		}
	}
</style>
