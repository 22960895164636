<template>
	<div id="specialGoodss">
        <div class="title-top bg-f">特殊商品编辑【{{buyerStoreName}}】</div>
		<div class="content bg-f pl-30 pr-30 mt-5 pt-30">
			<div class='tar clearfix'>
				<el-button class="fr" @click="back">返回</el-button>
			</div>
			<div class="pb-30 mt-20">
				<el-table :data="tableData" style="width: 100%" stripe >
					<el-table-column label='商品名称' width="245">
						<template slot-scope="scope">
							<div class="fl">
								<img v-if="scope.row.fileUrl" :src="scope.row.fileUrl" alt="" />
								<img v-else src="../../../assets/goods.jpg" alt="" />
							</div>
							<div class="fl lh-16">
								<div class="width-140">
									商品编码:<span>{{scope.row.goodsNo ? scope.row.goodsNo :'--'}}</span>
								</div>
								<div style="text-align:left;" class="width-140" show-overflow-tooltip>{{scope.row.skuName}}</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column label='规格' width="58" align="center">
						<template slot-scope="scope">
							<span>1*{{scope.row.specInfoNum}}</span>
						</template>
					</el-table-column>
					<el-table-column label='促销价' width="65" align="center">
						<template slot-scope="scope">
							<span v-if="scope.row.promotionPrice">￥{{scope.row.promotionPrice||0 | fmoney }}</span>
							<span v-else>--</span>
						</template>
					</el-table-column>
					<el-table-column label='标准零售价' width="80" align="center">
						<template slot-scope="scope">
							<span v-if="scope.row.maxSellingPrice">￥{{scope.row.maxSellingPrice||0 | fmoney }}</span>
							<span v-else>--</span>
						</template>
					</el-table-column>
					<el-table-column label='最低零售价' width="80" align="center">
						<template slot-scope="scope">
							<span v-if="scope.row.minSellingPrice">￥{{scope.row.minSellingPrice||0 | fmoney }}</span>
							<span v-else>--</span>
						</template>
					</el-table-column>
					<el-table-column label='月限购' width="70" align="center">
						<template slot-scope="scope">
							<span v-if="scope.row.limitMonth">{{scope.row.limitMonth}}月</span>
							<span v-else>--</span>
						</template>
					</el-table-column>
					<el-table-column label='开始日期' width="100" align="center">
						<template slot-scope="scope">
							<span v-if="scope.row.startDate">{{timeFormat(scope.row.startDate)}}</span>
							<span v-else>--</span>
						</template>
					</el-table-column>
					<el-table-column label='数量限购' width="80" align="center">
						<template slot-scope="scope">
							<span v-if="scope.row.limitNum">{{scope.row.limitNum}}{{scope.row.sellingUnitName}}</span>
							<span v-else>--</span>
						</template>
					</el-table-column>
					<el-table-column label='最低起订量' width="80" align="center">
						<template slot-scope="scope">
							<span v-if="scope.row.minimunMoq">
								{{ scope.row.minimunMoq }}{{scope.row.units}}
							</span>
							<span v-else>--</span>
						</template>
					</el-table-column>
					<el-table-column label='操作' align="center">
						<template slot-scope="scope">
							<el-button class="width-63" type="text"  @click="handleSet(scope)">限购设置</el-button>
							<el-button class="width-63" v-if="scope.row.hotelSpecialGoodsId" type="text" @click="handleCancle(scope)">取消设置</el-button>
						</template>
					</el-table-column>
				</el-table>
			</div>
        </div>
		<!--限购设置-->
		<el-dialog class="minMop" title="限购设置" :visible.sync='setDialog'>
			<el-form class="pr-10" :model="form" ref="formDa" :rules="rules">
				<div class="one">
					<el-form-item class="ml-20" label="月限购" prop="limitMonth" required>
						<el-select v-model="form.limitMonth" size="mini" class="ml-15">
							<el-option v-for="(item, index) in arr" :label="item" :value="item" :key="index"></el-option>
						</el-select>
						<span class="ml-10">月</span>
					</el-form-item>
					<el-form-item label="限购数量:" class="ml-20" required>
						<el-radio-group v-model="radio">
							<el-form-item class="ml-10" prop="unitNum">
								<el-radio style="margin-right: 0" :label="1">{{ }}</el-radio>
								<el-input size="mini" class="mr-10" :maxlength="3" v-model="form.unitNum" placeholder="限购数量"></el-input>
								<span class="font-14">{{ form.units }}</span>
							</el-form-item>
							<el-form-item class="ml-10" prop="sellingNum">
								<el-radio style="margin-right: 0;line-height: 32px" class="mt-20" :label="2">{{ }}</el-radio>
								<el-input size="mini" class="mr-10" :maxlength="3" v-model="form.sellingNum" placeholder="限购数量"></el-input>
								<el-select v-model="form.sellingUnit" size="mini">
									<el-option v-for="(item, index) in goodsSellings" :label="item.dictValue" :value="item.dictKey" :key="index"></el-option>
								</el-select>
								<span class="font-14">（合{{ form.sellingNum }}*{{ form.specInfoNum }}{{ form.units }}）</span>
							</el-form-item>
						</el-radio-group>
					</el-form-item>
					<div class="tac">29-31日首次设置限购日期，限购结束日期为28日</div>
				</div>
			</el-form>
			<div slot='footer' class="dialog-footer">
				<el-button type='danger' @click="handleSave()">提交</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import { mapActions } from 'vuex'
	import { axiosApi, basePath } from '../../../api/api' //导入axiosApi

	export default {
		data() {
			var validateUnitNum = (rule, value, callback) => {
				if((value == "" || !value) && this.radio == 1){
					callback(new Error('请输入限购数量'));
				}else{
					callback();
				}
			}
			var validateSellingNum = (rule, value, callback) => {
				if((value == "" || !value) && this.radio == 2){
					callback(new Error('请输入限购数量'));
				}else{
					callback();
				}
			}
			return {
				rules: {
					limitMonth:[{
						required: true,
						message: '限购时间不能为空'
					}],
					limitNum:[{
						required: true,
						message: '限购数量不能为空'
					},{
						pattern: /^([1-9]\d{0,2}|1000)$/,
						message: '限购数量只能输入1-1000以内的纯数字'
					}],
					unitNum:[
						{ validator: validateUnitNum},
						{ pattern: /^[1-9]\d{0,2}$/, message: '最大支持3位整数' }
					],
					sellingNum:[
						{ validator: validateSellingNum},
						{ pattern: /^[1-9]\d{0,2}$/, message: '最大支持3位整数' }
					]
				},
                tableData:[],
                currentPage: 1,
				total: 0,
				buyerStoreName: null,
				setDialog: false,//限购设置弹窗
				arr:[1,2,3,4,5,6],
				arrType: [{
					id: '0',
					label: "瓶"
				}, {
					id: '1',
					label: "箱"
				}],
				form:{
					hotelId: this.$route.query.id,
					id: null,
					skuNo: null,//商品编码
					specInfoNum: null,// 商品规格
					limitNum: null,// 限购数量
					limitMonth: '1',//  月限购月数
					isIntegerMultiple: '0',// 是否按箱设置
					unitNum: 1,//数量
					sellingNum: 1,//数量
					sellingUnit:null,//规格单位
					specInfoNum: null,//规格
					units:null,//计量单位
				},
				radio: 1,
				goodsSellings: [],//包装分类
            }
		},
		mounted() {
			this.$nextTick(() => {
				this.buyerStoreName = this.$route.query.name
				this.achieve()
			})
		},
		watch:{
			radio(val){
				if(val == 1){
					this.form.isIntegerMultiple = 0;
				}else{
					this.form.isIntegerMultiple = 1;
				}
			}
		},
		methods: {
			timeFormat(value){
                var myDate = new Date(value);
                var year = myDate.getFullYear();
                var month = myDate.getMonth() + 1 < 10 ? '0' + (myDate.getMonth() + 1) : myDate.getMonth() + 1;
                var day = myDate.getDate() < 10 ? '0' + myDate.getDate() : myDate.getDate();
                return year + '-' + month + '-' + day;
            },
            achieve(){
				axiosApi("/superc/specialGoods/listSetDetail", {hotelId:this.$route.query.id}, (res) => {
					this.tableData = res.data.data
					this.total = res.data.data.totalCount
				}, this.currentPage, 20, (res) => {
					this.$message({
						type: 'error',
						message: res.data.message,
						duration: 2000
					});
				});
            },
			handleSet(scope){
				if(scope.row.isIntegerMultiple){
					this.radio = 2;
					this.form.unitNum = null;
					this.form.sellingNum = scope.row.limitNum;//销售单位数量
				}else{
					this.radio = 1;
					this.form.unitNum = scope.row.limitNum;//按瓶的最低起订量
					this.form.sellingNum = null;
				};

				this.setDialog = true
				this.form.id = scope.row.hotelSpecialGoodsId
				this.form.skuNo=scope.row.skuNo,//商品编码
				this.form.specInfoNum=scope.row.specInfoNum,// 商品规格
				this.form.limitMonth=scope.row.limitMonth?scope.row.limitMonth:'1',//  月限购月数
				// this.form.limitNum=scope.row.limitNum,// 限购数量
				this.form.isIntegerMultiple= scope.row.isIntegerMultiple?scope.row.isIntegerMultiple.toString():'0'// 是否按箱设置
				this.form.units = scope.row.units;//瓶
				this.form.sellingUnit = scope.row.sellingUnit ? scope.row.sellingUnit : scope.row.goodsSellings[0].dictKey;//规格单位
				this.goodsSellings = scope.row.goodsSellings//规格分类
				this.form.specInfoNum = scope.row.specInfoNum;//规格数量
			},
			handleSave(){
				if(this.form.isIntegerMultiple==1){
					this.form.limitNum = this.form.sellingNum;//销售单位数量
				}else{
					this.form.limitNum = this.form.unitNum;//销售单位数量
				};
				this.$refs.formDa.validate((valid) => {
					if(valid){
						axiosApi("/superc/specialGoods/set", this.form, (res) => {
							this.$message({
								type: 'success',
								message: '设置成功',
								duration: 2000
							});
							this.setDialog = false
							this.achieve()
						}, this.currentPage, 20, (res) => {
							this.$message({
								type: 'error',
								message: res.data.message,
								duration: 2000
							});
						});
					}
				})
			},
			handleCancle(scope){
				const h = this.$createElement
				this.$msgbox({
					title: '提示信息',
					message: h('p', {
						style: 'text-align:center'
					}, '确认取消该商品设置？'),
					showCancelButton: true,
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					beforeClose: (action, instance, done) => {
						if(action === 'confirm') {
							axiosApi("/superc/specialGoods/cancelSet", {
								id: scope.row.hotelSpecialGoodsId
							}, (res) => {
								this.$message({
									type: 'success',
									message: '操作成功',
									duration: 3000
								});
								this.achieve(); //刷新列表
							}, 1, 10, (res) => {
								this.$message({
									type: 'error',
									message: res.data.message,
									duration: 2000
								});
							});
							done()
						} else {
							done()
						}
						delete instance.$slots.default;
					}
				})
			},
			back(){
				window.history.go(-1);
			}
        }
	}
</script>

<style lang="less">
	#specialGoodss {
		.width-140 {
			width: 140px !important;
		}
		.width-63 {
			width: 63px !important;
		}
		.el-table .cell{
			padding: 10px 6px;
			text-align: center;
		}
		.el-button+.el-button{
			margin-left: 0;
		}
		.minMop {
			.el-dialog {
				width: 520px;
				min-height: 300px;
				.el-form-item__error {
					left: 37px;
				}
			}

			.one {
				.el-input {
					width: 120px !important;
				}
				.el-form-item__error {
					margin-left: 146px;
				}
			}
		}
		.tc{
			text-align: center;
			margin-left: 30px;
			font-size: 13px;
			color: #999;
		}
		.el-form-item__label{
			width: 100px;
		}
		.minMop .one .el-form-item__error{
			margin-left: -10px;
		}

	}
</style>
