// 导出表格
import axios from "axios";
import { Message } from 'element-ui';
var path = '/sunrise-gateway'

export function downloadFile(res, fileName) {
  let blob = new Blob([res.data]); //res为从后台返回的数据

  if (!fileName) {
      fileName = res.headers['content-disposition'].split('filename=').pop();
  }
  if ('msSaveOrOpenBlob' in navigator) {
      window.navigator.msSaveOrOpenBlob(blob, fileName);
  } else {
      const elink = document.createElement('a');
      elink.download = fileName;
      elink.style.display = 'none';
      elink.href = URL.createObjectURL(blob);
      document.body.appendChild(elink);
      elink.click();
      URL.revokeObjectURL(elink.href);
      document.body.removeChild(elink);
  }
}

export function exportInfo(url,data) {  
  return new Promise((resolve, reject) => {
    axios({
      url: path + url,
      method: 'get',
      params: data,
      responseType: 'blob',
    }).then(res => { 
      if (res.data.type === 'application/vnd.ms-excel') {
       downloadFile(res, data.fileName);
       resolve(res)
      } else {
        const file = new FileReader();
        file.readAsText(res.data, 'utf-8');
        file.onload = function () {
            const obj = JSON.parse(file.result);
            Message({
              type: 'info',
              message: obj.message
            });
        }
        reject('无导出！') 
      }
    })
  }) 
}