<template>
	<div id="client-channel">
		<el-dialog :title="title" :visible.sync="channelDialog" @close="closeAdd">
			<el-form :model="clientChannel" :rules="rules" ref="clientChannel" label-width="150px">
				<el-form-item label="渠道名称:" prop="classifyName">
					<el-input v-model.trim="clientChannel.classifyName" placeholder="输入渠道名称"></el-input>
				</el-form-item>
				<el-form-item label="上级渠道:" prop="parentId">
					<el-select v-model="clientChannel.parentId" clearable :disabled="disabled">
						<el-option v-for="item in channelList" :key="item.id" :label="item.classifyName" :value="item.id"></el-option>
					</el-select>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" @click="addChannel" :loading="btnLoading">确 定</el-button>
				<el-button @click="closeAdd">取 消</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {mapActions, mapMutations} from 'vuex'
	export default {
		props: ['value','title','disabled'],
		data() {
			return {
				clientChannel:{
					classifyName: null,
					parentId: null, 
					traderId: sessionStorage.getItem('traderId')
				},
				btnLoading:false,
				channelDialog: false,
				// channelList:[],
				rules: {
					classifyName: [{
						required: true,
						message: '请输入渠道名称'
					},{
						message: '字符长度最大为5',
						max: 5
					}]
				}
			}
		},
		computed: {
			clientChannelData(){
				// return { ...this.$store.state.channelLevel.channelData, traderId: sessionStorage.getItem('traderId') }
				
				// let a = this.$store.state.channelLevel.channelData;
				// this.clientChannel = Object.assign({}, {
				// 	classifyName: a.classifyName,
	   //              parentId: a.parentId == 0 ? "" : a.parentId,
	   //              traderId: sessionStorage.getItem('traderId')
				// })
				return this.$store.state.channelLevel.channelData
			},
			channelList(){
				return this.$store.state.channelLevel.clientChannelList
			},
		},
		watch: {
			value(val){
				this.channelDialog = val;
				if(this.$refs.clientChannel) this.$refs.clientChannel.resetFields();
			},
			clientChannelData:{
				handler:function(val){
					this.clientChannel = Object.assign({}, {
						classifyName: val.classifyName,
		                parentId: val.parentId == 0 ? "" : val.parentId,
		                id: val.id ? val.id : null,
		                traderId: sessionStorage.getItem('traderId')
					})
				},
				deep:true
			}
		},
		methods: {
			...mapMutations({
				'resetChannelLevelData': 'resetChannelLevelData'
			}),
			...mapActions({
				'addChannelLevelData': 'addChannelLevelData'
			}),
			addChannel() {
				this.$refs.clientChannel.validate(valid => {
					if(valid) {
						if(!this.clientChannel.parentId || this.clientChannel.parentId == ""){
							this.$set(this.clientChannel, 'parentId', 0)
						}
						setTimeout(()=>{
							this.addChannelLevelData({
								type: 0,//0为客户渠道，1为客户等级
								body: this.clientChannel
							})
							this.$emit('closedialog')
						},0)
					}
				})
			},
			//返回上一页
			closeAdd() {
				this.$emit('closedialog')
				this.resetChannelLevelData()
			},
		}
	}
</script>

<style lang="less">
	#client-channel{

	}
</style>
