<template>
  <div id="salesInCarInventory" class="bg-f">
    <div class="box-con bdt">
      <div class="pt-20 pb-20">
        <h3>{{teamName}}-{{teamCarNo}}</h3>
      </div>
      <hr />
      <div class="pt-20 pb-20">当前库存</div>
      <el-table :data="tableData" stripe>
        <el-table-column label="商品名称" align="center" min-width="200">
          <template slot-scope="scope">
            <div class="fl specialD">
              <img v-if="scope.row.fileUrl" :src="scope.row.fileUrl" alt />
              <img v-else src="../../assets/goods.jpg" alt />
              <img
                v-if="scope.row.storeType=='2'"
                class="special"
                src="../../assets/special.png"
                alt
              />
            </div>
            <div class="lh-16" style="padding-left: 70px">
              <div class="tal">
                商品编码:
                <span>{{scope.row.goodsNo ? scope.row.goodsNo :'--'}}</span>
              </div>
              <div class="tal skuName" show-overflow-tooltip>{{scope.row.skuName}}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="规格" min-width="150" align="center">
          <template slot-scope="scope">
            <span>1*{{scope.row.specInfoNum}}</span>
          </template>
        </el-table-column>
        <el-table-column label="商品分类" min-width="150" align="center">
          <template slot-scope="scope">
            <span>{{scope.row.classifyName}}</span>
          </template>
        </el-table-column>
        <el-table-column label="库存" min-width="150" align="center">
          <template slot-scope="scope">
            <span>{{scope.row.stock}}{{scope.row.units}}</span>
          </template>
        </el-table-column>
        <el-table-column label="进店价" min-width="150" align="center">
          <template slot-scope="scope">
            <span
              v-if="scope.row.sellerEnterShopPrice"
            >￥{{scope.row.sellerEnterShopPrice||0 | fmoney }}/{{scope.row.sellingUnitName}}</span>
            <span v-else>--</span>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        v-if="total>10"
        class="tar mt-20"
        :current-page.sync="currentPage"
        :page-size="10"
        :total="total"
        layout="prev , pager, next, jumper"
        @current-change="handleCurrentChange"
      >
        <!-- 分页组件 -->
      </el-pagination>
      <div class="pt-20 pb-20">出入库记录</div>
      <el-table :data="tableDataRecord" stripe>
        <el-table-column label="时间" align="center" min-width="200">
          <template slot-scope="scope">
            <span>{{scope.row.gmtModified | time}}</span>
          </template>
        </el-table-column>
        <el-table-column label="动作" align="center" min-width="150">
          <template slot-scope="scope">
            <span>{{scope.row.typeName}}</span>
          </template>
        </el-table-column>
          <el-table-column label="状态" align="center" min-width="100">
              <template slot-scope="scope">
                  <span>{{scope.row.state | state}}</span>
              </template>
          </el-table-column>
        <el-table-column label="操作人" align="center" min-width="150">
          <template slot-scope="scope">
            <span>{{scope.row.operatorName}}/{{scope.row.operatorMobile}}</span>
          </template>
        </el-table-column>
        <el-table-column label="明细" align="center">
          <template slot-scope="scope">
            <el-popover
              ref="popover1"
              placement="top-start"
              title
              width="300"
              trigger="hover"
              :content="scope.row.skuNames"
              v-if="scope.row.skuNames.length>38"
            ></el-popover>
            <span v-if="scope.row.skuNames.length>38" class="skuNames" @click="lookMore(scope.row)">{{scope.row.skuNames}}</span>
            <span v-else>{{scope.row.skuNames}}</span>
          </template>
        </el-table-column>
          <el-table-column label="备注" align="center" min-width="250">
              <template slot-scope="scope">
                  <span>{{scope.row.remark}}</span>
              </template>
          </el-table-column>
      </el-table>
      <el-pagination
        v-if="totalRecord>10"
        class="tar mt-20"
        :current-page.sync="currentPageRecord"
        :page-size="10"
        :total="totalRecord"
        layout="prev , pager, next, jumper"
        @current-change="handleCurrentChangeRecord"
      >
        <!-- 分页组件 -->
      </el-pagination>
    </div>
      <!--查看详细商品列表-->
      <el-dialog title="商品列表" :visible.sync="dialogTableVisible" class="chooseGoods" :before-close="closeDialog">
          <div class="goods-con">
              <div class="goods-items" v-for="(item,index) in goodsList" :key="index+'dg'">{{item.skuName}} * {{item.num}} {{item.sellingUnitName}}</div>
          </div>
      </el-dialog>
  </div>
</template>
<script>
import { axiosApi, basePath } from "../../api/api";
export default {
  components: {},
  data() {
    return {
        dialogTableVisible:false,
        goodsList:[],
      tableData: [],
      tableDataRecord: [],
      total: 0,
      totalRecord: 0,
      currentPage: 1,
      currentPageRecord: 1,
      teamName: "",
      teamCarNo: ""
    };
  },
  filters:{
        state(val){
            let str = ''
            switch (val) {
                case 0:
                    str = '未确认'
                    break
                case 1:
                    str = '已确认'
                    break
                case 2:
                    str = '已驳回'
                    break
            }
            return str
        }
    },
  mounted() {
    this.$nextTick(() => {
      this.teamName = this.$route.query.teamName;
      this.teamCarNo = this.$route.query.teamCarNo;
      this.getOrderInfo();
      // 获取出入库记录
      this.getRecord();
    });
  },
  methods: {
    lookMore(item){
        this.goodsList =  item.detailsList
        this.dialogTableVisible = true
    },
    getOrderInfo() {
      //获取订单详细信息
      axiosApi(
        "/goods/sicTeam/goodsStock/page",
        {
          id: this.$route.query.id
        },
        res => {
          this.total = res.data.data.totalCount;
          this.tableData = res.data.data.elements;
        },
        this.currentPage,
        10,
        res => {
          this.$message({
            type: "error",
            message: res.data.message,
            duration: 1000
          });
        }
      );
    },
    handleCurrentChange(val) {
      //分页
      this.currentPage = val;
      this.getOrderInfo();
    },
    // 获取出入库记录
    getRecord() {
      axiosApi(
        "/goods/sicTeam/goodsStockTransfer/page",
        {
          sicTeamId: this.$route.query.id
        },
        res => {
          this.totalRecord = res.data.data.totalCount;
          this.tableDataRecord = res.data.data.elements;
        },
        this.currentPageRecord,
        10,
        res => {
          this.$message({
            type: "error",
            message: res.data.message,
            duration: 1000
          });
        }
      );
    },
    handleCurrentChangeRecord(val) {
      //分页
      this.currentPageRecord = val;
      this.getRecord();
    }
  }
};
</script>

<style lang="less">
#salesInCarInventory {
    padding: 20px;
    .goods-con{
        overflow: auto;
        max-height: 580px;
        .goods-items{
            margin-bottom: 5px;
        }
    }

  .el-tabs__header {
    padding: 0;
    border-bottom: 1px solid #eee;
  }
  .color-red {
    color: red;
  }
  .el-table__body-wrapper {
    margin-top: 1px !important;
  }
  .skuName {
    /*max-width: 170px;*/
    height: auto;
  }
  .minMop {
    .el-form-item__label {
      width: 130px;
    }
    .el-dialog__body {
      padding: 30px 0 30px !important;
    }
    .el-dialog {
      width: 500px !important;
      min-height: 300px !important;
    }
    .el-form-item__error {
      left: 150px;
    }
  }
  .bdt {
    border-top: 1px solid #eee;
  }
  .el-tabs__header {
    padding: 0;
    border-bottom: 1px solid #eee;
  }
  /*.el-table__empty-block {*/
    /*display: none;*/
  /*}*/
  .box-con {
    .order-info {
      border: 1px solid #ddd;
      box-sizing: border-box;
      .order-info-tit {
        height: 40px;
        line-height: 40px;
        background-color: #eee;
      }
      .order-info-con {
        .order-info-conL {
          width: 700px;
          line-height: 30px;
          padding: 22.5px 0;
          label {
            width: 150px;
            display: inline-block;
            text-align: right;
          }
          &.order-info-conL2 {
            width: 90%;
          }
        }
      }
    }
    .goods {
      border: 1px solid #ddd;
      box-sizing: border-box;
      .goods-info {
        height: 120px;
        border-bottom: 1px solid #ddd;
        span {
          display: inline-block;
        }
        .goods-infoL {
          width: 650px;
          height: 100%;
          padding: 20px;
          box-sizing: border-box;
          border-right: 1px solid #ddd;
          img {
            width: 80px;
            height: 80px;
          }
          .w380 {
            width: 200px;
          }
          .w210 {
            width: 210px;
          }
        }
        .goods-infoR {
          width: 185px;
          display: inline-block;
        }
      }
      .goods-money {
        line-height: 25px;
        padding: 30px 50px;
        box-sizing: border-box;
        label {
          width: 300px;
          display: inline-block;
          text-align: right;
        }
      }
    }
    .el-dialog {
      min-height: 300px;
      .el-form-item__label {
        width: 140px;
      }
    }
    .cancel-order {
      .el-dialog {
        min-height: 500px;
      }
    }
    .sendGoods .el-form-item__error {
      margin-left: 140px;
    }
  }
  .color-red {
    color: red;
  }
  .my-badge {
    padding-top: 5px;
  }
  .myBtn {
    .el-button {
      width: 70px;
    }
  }
  .skuNames {
      cursor: pointer;
    width: 450px;
    height: auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #20a0ff;
  }
  .el-tabs__header {
    padding-top: 10px;
    padding-left: 0;
  }
  .el-checkbox__label {
    display: none;
  }
  .notPayCountmark {
    position: absolute;
    left: 144px;
    top: 10px;
  }
  .notDeliveryCount {
    position: absolute;
    left: 218px;
    top: 10px;
  }
  .orderDeliveriedCount {
    position: absolute;
    left: 294px;
    top: 10px;
  }
  .hide {
    display: none;
  }
  .content {
    .el-checkbox {
      width: 40px !important;
    }
    .el-date-editor--daterange.el-input {
      width: 240px;
    }
    .el-form--inline {
      width: 650px;
      .el-form-item__label {
        font-size: 12px;
        width: 60px;
      }
    }
    .el-table .el-table__body-wrapper {
      display: none;
    }
    .order-datatimerange {
      width: 240px;
    }
    .el-table {
      border: none;
      font-size: 14px;
      th > .cell {
        line-height: 12px;
        padding: 10px 0 10px 0;
        text-align: center;
      }
      th.is-leaf {
        border-bottom: 0;
      }
    }
    .checkedAll {
      position: relative;
      top: 30px;
    }
    .el-checkbox {
      width: 70px;
      margin-left: 30px;
      .el-checkbox__input {
        height: 20px;
      }
    }
    .el-checkbox__inner {
      width: 20px;
      height: 20px;
    }
    .el-checkbox__inner::after {
      top: 2px;
    }
    .table-item {
      .bl {
        border-left: 1px solid #ddd;
      }
      border: 1px solid #dddddd;
      font-size: 14px;
      border-bottom: none;
      .table-item-content .pbl-20 {
        padding: 0px 0px 20px 20px;
      }
      .table-item-top {
        padding: 10px 0;
        position: relative;
        .table-item-top-checkbox {
          margin-left: 14px;
        }
        .all_sn_ct {
          background: #eee;
          position: absolute;
          left: 70px;
          top: 30px;
          padding: 10px 5px;
          border: 1px solid #ddd;
          z-index: 1;
        }
      }
      img {
        width: 80px;
        height: 80px;
      }
    }
    .w100 {
      width: 100px;
    }
    .table-item-default {
      height: 530px;
      padding-top: 120px;
    }
    .el-dialog {
      min-height: 300px;
      .el-form-item__label {
        width: 140px;
      }
    }
    .sendGoods .el-form-item__error {
      margin-left: 140px;
    }
  }
  .ml-70 {
    margin-left: 70px;
  }
  .my-badge {
    .el-badge__content {
      border: 0;
    }
  }
  .color-blue {
    color: #20a0ff;
    cursor: pointer;
  }
  .specialD {
    position: relative;
    display: flex;
    justify-content: space-between;
    .skuImg {
      width: 180px;
      height: 180px;
      display: inline-block;
    }
    .row-item-1 {
      width: 240px;
      height: auto;
      .bw {
        word-wrap: break-word;
      }
    }
    .row-item-2 {
      width: 0;
        flex: 1;
      height: auto;
      .bw {
        word-wrap: break-word;
      }
    }
    .special {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}
</style>