<template>
  <div class="account-period" v-loading="loading">
    <div class="title-top bg-f">账期管理</div>
    <div class="bg-f mt-5 pl-20 pt-30 pr-20 bill-content pb-50">
      <el-form :inline="true" label-width="60px" class="clearfix mb-10 search">
        <el-form-item label="客户名称">
          <el-input v-model="form.storeName"></el-input>
        </el-form-item>
        <el-form-item label="结算方式">
          <el-select v-model="form.payMethodNumber" clearable>
            <el-option
              v-for="(item, index) in payMethodList"
              :label="item.payMethodName"
              :value="item.payMethodNumber"
              :key="index"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="客户渠道">
					<customerType v-model="form.cType" :multiple="false" :clearable="true" class="mr-10"/>
        </el-form-item>
        <el-form-item label="客户账号">
          <el-input v-model="form.loginName"></el-input>
        </el-form-item>
      </el-form>
      <div class="pt-5 pb-30">
        <el-button type="info" icon="el-icon-search" @click="search"
          >查询</el-button
        >
      </div>
      <el-table :data="data" stripe :row-class-name="tableRowClassName">
        <el-table-column
          label="客户名称"
          prop="storeName"
          align="center"
          show-overflow-tooltip
        />
        <el-table-column label="所属区域" prop="regionName" align="center" />
        <el-table-column label="客户账号" prop="loginName" align="center" />
        <el-table-column
          label="客户渠道"
          prop="ctypeName"
          align="center"
        >
          <!-- <template slot-scope="scope">
            {{ scope.row.ctypeName }}
          </template> -->
        </el-table-column>
        <el-table-column label="结算方式" prop="payMethodName" align="center" />
        <el-table-column label="最高额度" prop="creditAmount" align="center" />
        <el-table-column label="已用额度" prop="usedAmount" align="center" />
        <el-table-column label="逾期天数" prop="realOverDays" align="center" />
      </el-table>
      <div class="tac mt-30 pb-30" v-if="data.length == 0">
        <img src="../../../assets/empty.png" alt="" />
        <div class="color-9 mt-30">
          <span>暂无记录</span>
        </div>
      </div>
      <!-- 分页组件 -->
      <el-pagination
        v-if="total > 0"
        @current-change="handleCurrentChange"
        class="tar mt-20 mr-50"
        :current-page="currentPage"
        :page-size="10"
        :total="total"
        layout="prev , pager, next, jumper"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { axiosApi, basePath } from "~/api/api";
import customerType from "@/components/customerType.vue";
export default {
	components: {
		customerType
	},
  data() {
    return {
      payMethodList: [], // 结算方式
      data: [],
      form: {
        sellerId: sessionStorage.traderId,
        storeName: null,
        payMethodNumber: null,
        cType: null,
        loginName: null,
      },
      total: 0,
      currentPage: 1,
      responseData: {}, //响应数据
      loading: false,
    };
  },
  created() {
    //初始化数据
    this.getPayMethodList();
    this.getCustomerCreditList();
  },
  methods: {
    search() {
      if (this.currentPage == 1) {
        this.getCustomerCreditList();
      } else {
        this.currentPage = 1;
      }
    },
    // 请求账期结算方式
    getPayMethodList(body) {
      axiosApi(
        "/trader/seller/customerCreditPayMethodList",
        { sellerId: sessionStorage.traderId },
        (res) => {
          this.loading = false;
          this.payMethodList = res.data.data;
        },
        this.currentPage,
        10,
        (err) => {
          this.loading = false;
        }
      );
    },
    // 请求账期列表
    getCustomerCreditList() {
      this.loading = true;
      axiosApi(
        "/trader/seller/customerCreditList",
        this.form,
        (res) => {
          this.loading = false;
          this.data = res.data.data ? res.data.data.elements : [];
          this.total = res.data.data ? res.data.data.totalCount : 0;
        },
        this.currentPage,
        10,
        (err) => {
          this.loading = false;
        }
      );
    },
    handleCurrentChange(v) {
      this.currentPage = v;
      this.getCustomerCreditList();
    },
    tableRowClassName(row, index) {
      //标记背景色
      if (row.usedAmount > row.creditAmount || row.realOverDays > 0) {
        return "tag-row";
      }
      return "";
    },
  },
};
</script>

<style lang="less">
.account-period {
  .bill-content {
    min-height: 661px;
  }
  .el-table__empty-block {
    display: none;
  }
  .el-table .tag-row .cell {
    color: #ff4201;
  }
  .textBtn {
    width: 28px;
  }
}
</style>