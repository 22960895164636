<template>
    <div class="newCenter">
        <div class="realtimeOverview">
            <div class="title">实时概况</div>
            <div class="overviewBottom">
              <div class="orderTotal">
                <img src="../../assets/orderTotalPrice.png" alt="">
                <div class="orderTotalRight">
                  <div>￥{{generalSituationVO.totalOrderAmount|fmoney}} <span style="color:#b1abab;font-size:12px;padding-left:5px">今日￥{{generalSituationVO.todayOrderAmount|fmoney}}</span></div>
                  <div style="margin-top:12px;">订单总额（元）</div>
                </div>
              </div>
              <div class="orderTotal">
                  <img src="../../assets/orderTotal.png" alt="">
                    <div class="orderTotalRight">
                      <div>{{generalSituationVO.totalOrderCount}}<span style="color:#b1abab;font-size:12px;padding-left:5px">今日：{{generalSituationVO.todayOrderCount}}</span></div>
                      <div style="margin-top:12px;">订单总数（笔）</div>
                    </div>
                </div>
                <div class="orderTotal">
                    <img src="../../assets/custom.png" alt="">
                    <div class="orderTotalRight">
                      <div>{{generalSituationVO.totalCustomerCount}}<span style="color:#b1abab;font-size:12px;padding-left:5px">今日：{{generalSituationVO.todayCustomerCount}}</span></div>
                      <div style="margin-top:12px;">注册客户数（个）</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="bottom">
          <div class="leftContent">
          <div class="productTips">
            <div class="title">商品提示<span class="titleTips">需要关注的商品信息</span></div>
            <div class="content" style="margin-left:40px;">
                <div class="status" @click="lookGoodsPage('/customer/allProduct',1)">
                    <div class="num">{{goodsSituationVO.onShelfCount}}</div>
                    <div class="text">出售中</div>
                </div>
            </div>
            <div class="content">
                <div class="status" @click="lookGoodsPage('/customer/allProduct',2)">
                    <div class="num">{{goodsSituationVO.offShelfCount}}</div>
                    <div class="text">待上架</div>
                </div>
            </div>
            <div class="content">
                <div class="status" @click="lookGoodsPage('/customer/allProduct',0)">
                    <div class="num">{{goodsSituationVO.totalGoodsCount}}</div>
                    <div class="text">全部商品</div>
                </div>
            </div>
          </div> 
          <div class="dealTips">
            <div class="title">交易提示<span class="titleTips">需要立即处理的交易订单</span></div>
            <div class="content">
                <div class="status" @click="lookOrderPage(1)">
                    <div class="num">{{ordersSituationVO.waitPaymentCount}}</div>
                    <div class="text">待付款</div>
                </div>
            </div>
            <div class="content">
                <div class="status" @click="lookOrderPage(2)">
                    <div class="num">{{ordersSituationVO.waitShippingCount}}</div>
                    <div class="text">待发货</div>
                </div>
            </div>
            <div class="content">
                <div class="status" @click="lookOrderPage(3)">
                    <div class="num">{{ordersSituationVO.shippedCount}}</div>
                    <div class="text">已发货</div>
                </div>
            </div>
            <div class="content">
                <div class="status" @click="lookGoodsPage('/customer/saleAfterService',1)">
                    <div class="num">{{ordersSituationVO.afterSalesCount}}</div>
                    <div class="text">售后待处理</div>
                </div>
            </div>
          </div> 
          <div class="totalOrderStatistics">
            <div class="title">订单总数统计</div>
            <div id="orderEcharts" :style="{width: '99%', height: '300px'}"></div>
            <div class="datePick">
                <div :class="orderType==0?'selectedBtn':''" @click="drawOrderLine(orderTotalCountVO.todayOrderCountVO,0)">今日</div>
                <div :class="orderType==1?'selectedBtn':''" @click="drawOrderLine(orderTotalCountVO.weekOrderCountVO,1)">本周</div>
                <div :class="orderType==2?'selectedBtn':''" @click="drawOrderLine(orderTotalCountVO.monthOrderCountVO,2)">本月</div>
            </div>
          </div>
          <div class="totalOrderStatistics">
            <div class="title">客户总数统计</div>
            <div id="customEcharts" :style="{width: '99%', height: '300px',}"></div>
            <div class="datePick">
                <div :class="customerType==0?'selectedBtn':''" @click="drawCustomerLine(orderTotalCountVO.todayCustomerCountVO,0)">今日</div>
                <div :class="customerType==1?'selectedBtn':''" @click="drawCustomerLine(orderTotalCountVO.weekCustomerCountVO,1)">本周</div>
                <div :class="customerType==2?'selectedBtn':''" @click="drawCustomerLine(orderTotalCountVO.monthCustomerCountVO,2)">本月</div>
            </div>
          </div>
        </div>
        <div class="rightContent">
          <div class="salesStatistics">
            <div class="title">销售情况统计<span class="titleTips">按周期统计订单数及订单金额</span></div>
            <div class="ySales">
              <div class="yTitle"> 
                <div class="saleNumImg">
                  <img src="../../assets/saleNum.png" alt="">
                </div> 昨日销量
              </div>
              <div class="yContent">
                <div class="yDetail">
                  <span class="titleLeft">订单数（笔）：</span>
                  <span class="titleRight">{{yesterdayOrderSalesVO.orderCount}}</span>
                </div>
                <div class="yDetailTotal">
                  <span class="titleLeft">订单金额（元）：</span>
                  <span class="titleRight">￥{{yesterdayOrderSalesVO.orderAmount|fmoney}}</span>
                </div>
              </div>
            </div>
            <div class="mSales">
              <div class="mTitle"> 
                <div class="saleNumImg">
                  <img src="../../assets/saleNum.png" alt="">
                </div>本月销量
              </div>
              <div class="mContent">
                <div class="mDetail">
                  <span class="titleLeft">订单数（笔）：</span>
                  <span class="titleRight">{{monthOrderSalesVO.orderCount}}</span>
                </div>
                <div class="mDetailTotal">
                  <span class="titleLeft">订单金额（元）：</span>
                  <span class="titleRight">￥{{monthOrderSalesVO.orderAmount|fmoney}}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="productSalesRanking">
            <div class="title">单品销量排行<span class="titleTips">近30天单品销量</span></div>
            <div class="table">
                <div class="tableHead">
                    <div class="rank">排行</div>
                    <div class="goodsDetail">商品信息</div>
                    <div class="saleNum">销量</div>
                </div>
                <div class="tableContent" v-if="goodsSalesRankingVO.length == 0" style="text-align:center;line-height: 42px;display:inline-block;">
                  暂无数据
                </div>
                <div class="tableContent" v-for="(item,index) in goodsSalesRankingVO" :key="index" :style="index%2 !=0 ? 'background:#FFF8F5' : ''" v-show="index<=9">
                  <div class="rankContent" :style="(index<3)?'color: #E94A13;':'color:black'">{{index+1}}</div>
                  <div class="goodsDetailContent">{{item.skuName}}</div>
                  <div class="saleNumContent" :style="(index<3)?'color: #E94A13;':'color:black'">{{item.sales}}</div>
                </div>
            </div>
          </div>
        </div>
        </div>
    </div>
</template>

<script>
// 引入基本模板
var echarts = require('echarts');
import {axiosApi,basePath} from '~/api/api';
import { mapState,mapMutations } from 'vuex';
export default {
      data() {
        return {
          orderDate:'',
          customDate:'',
          goodsSalesRankingVO: [],//单品销量排行
          generalSituationVO: {},//实时概况
          goodsSituationVO: {},//商品提示
          ordersSituationVO: {},//交易提示
          monthOrderSalesVO:{},//本月销量
          yesterdayOrderSalesVO:{},//昨日销量
          orderTotalCountVO:{},//图表数据
          orderType:0,//
          customerType: 0,
          orderEcharts:null,
          customEcharts:null,
          isExist:false,//标签是否存在导航中
          createLabel:{}

        }
      },
      mounted() {
        this.getInfo();
        this.orderEcharts = echarts.init(document.getElementById('orderEcharts'))
        this.customEcharts = echarts.init(document.getElementById('customEcharts'))
      },
      computed: {
        ...mapState('nav',['jusAllNav','tagNav'])
      },
      methods: {
        ...mapMutations('nav',['addUserNav','changeTagNav','changNowNav',]),
        lookGoodsPage(url,type){
          this.isExist = false;
          this.createLabel = {}
          this.confirmJurisdiction(this.jusAllNav,url)
          if(this.isExist){
            if(this.createLabel && this.createLabel.url) {
                this.addUserNav(this.createLabel.id)
                let arr = this.$deepClone(this.tagNav)
                arr.push(this.createLabel)
                this.changeTagNav(arr)
                this.changNowNav(this.createLabel)
              }
            this.$router.push({
              path:url,
              query:{
                type:type
              }
            })
          }else {
             this.$message({
                    type: "info",
                    message: "您没有访问权限",
                    duration: 1000,
                })
          }
        },
        lookSalePage(type){
          const url = '/customer/saleAfterService'
          this.isExist = false;
          this.confirmJurisdiction(this.jusAllNav,url)
          if(this.isExist){
            this.$router.push({
            path:url,
            query:{
              type:type
            }
          })
          }else {
             this.$message({
                    type: "info",
                    message: "您没有访问权限",
                    duration: 1000,
                })
          }
        },
        tableRowClassName(row, index) {
          if (index%2 ===0) {
            return 'info-row';
          } else {
            return 'positive-row';
          }
        },
        drawOrderLine(orderData,num){
          this.orderType = num
          // 基于准备好的dom，初始化echarts实例
         
          let xStyle = {}
          if(orderData[0].length>8){
            xStyle = {
              type: 'category',
              boundaryGap: false,
              data: orderData[0],
              splitLine: {
                show: false
              },
              axisLabel:{interval:1,rotate:40}
            }
          }else {
            xStyle = {
              type: 'category',
              boundaryGap: false,
              data: orderData[0],
              // splitLine: {
              //         show: false
              //     },
                  }
          }
          this.orderEcharts.clear()
          // 绘制图表
          this.orderEcharts.setOption({
              grid:{
                right: '20px',
                left: '55px'
              },
              tooltip:{
                show: true,
                trigger: 'axis',
                axisPointer:{
                  type:'none'
                }
              },
              xAxis: xStyle,
              yAxis: {
                  type: 'value',
                  splitLine: {
                      show: false
                  },
                  minInterval: 1
              },
              series: [{
                  data: orderData[1],
                  type: 'line',
                  showSymbol: false,
                  smooth: true,
                  symbol:'circle',
                  areaStyle: {
                    color:'rgba(233, 74, 19, 0.16)'
                  },
                  lineStyle:{
                      color: {
                          type: 'linear',
                          x: 0,
                          y: 0,
                          x2: 1,
                          y2: 1,
                          colorStops: [{
                              offset: 0, color: '#E94A13' // 0% 处的颜色
                          },{
                              offset: 0.5, color: '#E94A13' // 0% 处的颜色
                          }, {
                              offset: 1, color: '#E94A13' // 100% 处的颜色
                          }],
                          global: false // 缺省为 false
                      }
                  },
                  itemStyle : { 
                    color:{
                      type: 'linear',
                      x: 0,
                      y: 0,
                      x2: 0,
                      y2: 1,
                      colorStops: [{
                          offset: 0, color: '#E94A13' // 0% 处的颜色
                      }, {
                          offset: 1, color: '#E94A13' // 100% 处的颜色
                      }],
                      global: false // 缺省为 false
                  }
                }
              }]
          });
          
        },
        drawCustomerLine(customerData,num){
          this.customerType = num
          let xStyle = {}
          if(customerData[0].length>8){
            xStyle = {
                  type: 'category',
                  boundaryGap: false,
                  data: customerData[0],
                  splitLine: {
                      show: false
                  },
                  axisLabel:{interval:1,rotate:40}
              }
          }else {
            xStyle = { type: 'category',
                  boundaryGap: false,
                  data: customerData[0],
                  splitLine: {
                      show: false
                  },
                  }
          }
          this.customEcharts.clear()
          this.customEcharts.setOption({
            grid:{
              right: '20px',
              left: '55px'
            },
            tooltip:{
              show: true,
              trigger: 'axis',
              axisPointer:{
                  type:'none'
                }
            },
            xAxis: xStyle,
            yAxis: {
              type: 'value',
              splitLine: {
                show: false
                },
                minInterval: 1
              },
              series: [{
                  data: customerData[1],
                  type: 'line',
                  smooth: true,
                  symbol:'circle',
                  // itemStyle:{
                  //   emphasis: {
                  //     color:'black'
                  //   }
                  // },
                  showSymbol: false,
                  areaStyle: {
                    color:'rgba(233, 74, 19, 0.16)'
                  },
                  lineStyle:{
                      color: {
                          type: 'linear',
                          x: 0,
                          y: 0,
                          x2: 1,
                          y2: 1,
                          colorStops: [{
                              offset: 0, color: '#E94A13' // 0% 处的颜色
                          },{
                              offset: 0.5, color: '#E94A13' // 0% 处的颜色
                          }, {
                              offset: 1, color: '#E94A13' // 100% 处的颜色
                          }],
                          global: false // 缺省为 false
                      }
                  },
              }]
          });
        },
        getInfo(){
          axiosApi('/order/seller/homePage/query',{"traderId":sessionStorage.traderId},res=>{
            const data = res.data.data
            this.generalSituationVO = data.generalSituationVO;
            this.goodsSituationVO = data.goodsSituationVO;
            this.ordersSituationVO = data.ordersSituationVO;
            this.goodsSalesRankingVO = data.goodsSalesRankingVO;
            this.monthOrderSalesVO = data.monthOrderSalesVO;
            this.yesterdayOrderSalesVO = data.yesterdayOrderSalesVO;
            this.orderTotalCountVO = data.orderTotalCountVO;
            this.orderTotalCountVO.todayOrderCountVO = this.changeData(this.orderTotalCountVO.todayOrderCountVO,'today','orderCount')
            this.orderTotalCountVO.weekOrderCountVO = this.changeData(this.orderTotalCountVO.weekOrderCountVO,'week','orderCount')
            this.orderTotalCountVO.monthOrderCountVO = this.changeData(this.orderTotalCountVO.monthOrderCountVO,'mouth','orderCount')
            this.orderTotalCountVO.todayCustomerCountVO = this.changeData(this.orderTotalCountVO.todayCustomerCountVO,'today','customerCount')
            this.orderTotalCountVO.weekCustomerCountVO = this.changeData(this.orderTotalCountVO.weekCustomerCountVO,'week','customerCount')
            this.orderTotalCountVO.monthCustomerCountVO = this.changeData(this.orderTotalCountVO.monthCustomerCountVO,'mouth','customerCount')
            this.drawOrderLine(this.orderTotalCountVO.todayOrderCountVO,0)
            this.drawCustomerLine(this.orderTotalCountVO.todayCustomerCountVO,0)
            
          })
        },
        changeData(data,type,isOrder,text){
          
          let titleData = [];
          let numData = []
          data.forEach(item=>{
            let title;
            if(type == 'today'){
              title = item['date']+'点'
            }else if(type == 'week'){
              title = item['date'].substring(5,10)+'\n'+item['day']
            }else {
              title = item['date'].substring(5,10)
            }
            titleData.push(title)
            // type.length < 5?titleData.push(item[type]):titleData.push(item['date']+item['day'])
            numData.push(item[isOrder])
          })
          return [titleData,numData];
        },
        lookOrderPage(num){
          const url = '/customer/order';
          this.isExist = false;
          this.createLabel = {}
          this.confirmJurisdiction(this.jusAllNav,url);
            if(this.isExist){
              if(this.createLabel && this.createLabel.url) {
                this.addUserNav(this.createLabel.id)
                let arr = this.$deepClone(this.tagNav)
                arr.push(this.createLabel)
                this.changeTagNav(arr)
                this.changNowNav(this.createLabel)
              }
              sessionStorage.setItem('orderType',num)
              this.$router.push({
                path: url,
              })
            }else {
                this.$message({
                    type: "info",
                    message: "您没有访问权限",
                    duration: 1000,
                })
            }
        },
        confirmJurisdiction(arr,url){
            arr.some(item=>{
                if(item.url == url){
                    this.isExist = true;
                    this.createLabel = item;
                    return true;
                }else {
                    if(item.sub.length != 0){
                        this.confirmJurisdiction(item.sub,url)
                    }
                }
            })
        },
      },
    }
</script>
<style lang="less" scoped>
  /deep/.el-table .info-row {
    background: #ffffff;
  }

  /deep/.el-table .positive-row {
    background: #FFF8F5;
  }
  /deep/.el-radio-button:first-child .el-radio-button__inner{
    border: none;
  }
  /deep/.el-radio-button__inner{
    border: none;
  }
  /deep/.el-radio-button__orig-radio:checked+.el-radio-button__inner{
    background: #fa5d47;
    -webkit-box-shadow: -1px 0 0 0 #fa5d47;
    box-shadow: -1px 0 0 0 #fa5d47;
  }
  .newCenter{
    width:100%;
    position: relative;
      &>div{
          box-sizing: border-box;
      }
    .realtimeOverview{
      width: 100%;
      height: 141px;
      border:1px solid rgba(228,234,238,1);
      background-color: #fff;
      padding:20px;
      .title{
        width:100%;
        font-size: 14px;
        color: #2C2C2C;
        font-weight:500;
      }
      .overviewBottom {
        width:100%;
        display:flex;
        align-items: center;
        justify-content: space-around;
        .orderTotal {
          display:flex;
          margin-top:25px;
          img {
            width: 50px;
            height: 50px;
          }
          .orderTotalRight {
            margin-left:10px;
            font-size:16px;

          }
        }
      }
    }
    .leftContent {
      width: 57%;
      .productTips,.dealTips{
        margin-top: 15px;
        width: 100%;
        height: 141px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        position: relative;
        border:1px solid rgba(228,234,238,1);
        background-color: #fff;
        .title{
          position: absolute;
          left: 20px;
          top: 20px;
          font-size: 14px;
          color: #2C2C2C;
          font-weight:500;
        }
        .content{
          margin-top: 40px;
          text-align: center;
          .status {
            cursor: pointer;
          }
        }
      }
    }
    .bottom {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
    .rightContent {
      padding-left: 20px;
      width:42%;
    }
    
    .totalOrderStatistics{
      position: relative;
      margin-top: 15px;
      width: 100%;
      height: 287px;
      border:1px solid rgba(228,234,238,1);
      background-color: #fff;
      .title{
        position: absolute;
        left: 20px;
        top: 20px;
        font-size: 14px;
        color: #2C2C2C;
        font-weight:500;
      }
      .datePick{
        position: absolute;
        right: 30px;
        top:10px;
        display: flex;
        justify-content: space-around;
        div {
          margin: 5px 10px;
          padding: 5px 10px;
          cursor: pointer;
        }
        
      }
    }

    .salesStatistics{
      box-sizing: border-box;
      width: 100%;
      height: 301px;
      border:1px solid rgba(228,234,238,1);
      background-color: #fff;
      margin-top: 15px;
      position: relative;
      padding-right: 20px;
      .title{
        font-size: 14px;
        color: #2C2C2C;
        font-weight:500;
        position: absolute;
        left: 20px;
        top: 20px;
      }
      .ySales{
        display: flex;
        margin: 60px 0 25px 25px; 
        .yTitle{
          width:30%;
          height:93px;
          background:rgba(250,93,71,1);
          text-align: center;
          line-height: 93px;
          color: #ffffff;
          font-size: 14px;
          .saleNumImg{
            display: inline-block;
            width:18px;
            height: 18px;
            img{
              width: 100%;
            }
          }
        }
        .yContent{
          width: 69%;
          .yDetail,.yDetailTotal{
            // width:250px;
            height:45px;
            line-height: 45px;
            text-align: center;
            background:#FFE3D9;
            border:1px solid #E94A13;
            color: #FA5D47;
          }
          .yDetailTotal{
            border-top:none;
          }
        }
      }
      .mSales{
        display: flex;
        margin-left: 25px;
        .mTitle{
          width:30%;
          height:93px;
          background:#FA5D47;
          text-align: center;
          line-height: 93px;
          color: #ffffff;
          font-size: 14px;
          .saleNumImg{
            display: inline-block;
            width:18px;
            height: 18px;
            margin-right: 2px;
            img{
              width: 100%;
            }
          }
        }
        .mContent{
          width: 69%;
          .mDetail,.mDetailTotal{
            height:45px;
            line-height: 45px;
            text-align: center;
            background:#FFE3D9;
            border:1px solid #E94A13;
            color: #FA5D47;
          }
          .mDetailTotal{
            border-top:none;
          }
        }
      }
    }
    .productSalesRanking{
        box-sizing: border-box;
      margin-top: 15px;
      width: 100%;
      border:1px solid rgba(228,234,238,1);
      background-color: #fff;
      padding-bottom: 20px;
      position: relative;
      .title{
        position: absolute;
        left: 20px;
        top: 20px;
        font-size: 14px;
        color: #2C2C2C;
        font-weight:500;
      }
      .table{
        width: 100%;
        padding:65px 25px 0;
        box-sizing:border-box;
        .tableHead,.tableContent{
          display:flex;
          width:100%;
          height: 46px;
          box-sizing:border-box;
          .rank{
            width: 20%;
          }
          .goodsDetail{
            width: 60%;
          }
          .saleNum{
            width: 20%;
          }
          .rank,.goodsDetail,.saleNum{
            float: left;
            text-align: center;
            height: 42px;
            line-height: 42px;
            // text-overflow: ellipsis;
            // overflow: hidden;
            // white-space: nowrap;
            color:#ffffff;
          }
          
          .rankContent{
            flex:2;
            width:0;
          }
          .goodsDetailContent{
            color: #1D1D1D;
            flex:6;
            width:0;
            overflow: hidden;
             text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            
          }
          .saleNumContent{
            flex:2;
            width:0;
          }
          
          .rankContent,.goodsDetailContent,.saleNumContent{
            float: left;
            text-align: center;
            height: 42px;
            line-height: 42px;
          }
          
        }
        .tableHead{
          background-color: #E94A13;
        }
        .tableContent{
          border: 1px solid #E4EAEE;
          border-top:none;
        }
      }
    }
  }
  .num{
    margin-top: 10px;
    color: #E94A13;
    font-size: 18px;
  }
  .text{
    color: #696E78;
    font-size: 12px;
  }
  .titleTips{
    margin-left: 12px;
    color:#696E78;
    font-size: 12px;
  }
  .selectedBtn {
          color:white;
          background: #fa5d47;
          border-radius: 4px;
        }
  .titleLeft {
    width: 46%;
    text-align:right;
    display:inline-block;
  }
  .titleRight {
    width: 52%;
    text-align:left;
    display:inline-block;
  }
</style>
