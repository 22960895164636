 <template>
    <div class="groupSalesManager bg-f">
        <!--tab 一级分类-->
        <div class="title-tabs bg-f black">
            <ul class="clearfix">
                <template v-for='(tab,index) in dataTabs' class="item">
                    <li :key="index+'d'" :type='index' :class="type==index?'active':''" @click='handleTabs'>
                        {{tab}}
                        <div :class="type==index?'under-line':''"></div>
                    </li>
                    <div :key="tab" class="vertical-line"></div>
                </template>
            </ul>
        </div>
        <!--满减送管理-->
        <div v-show="type == 0" id="groupProduct">
            <div class='bg-f'>
                <div class="ml-30 mr-30 second-title clearfix">
                    <div class="choose-three font-14 color-6 fl tac mr-10 cursor " :class="formInlineDataPlatform.status === ''?'actived':''"
                         @click="getStatus(0)">
                        全部状态({{count1}})
                    </div>
                    <div class="choose-three font-14 color-6 tac fl mr-10 cursor" :class="formInlineDataPlatform.status === '1'?'actived':''"
                         @click="getStatus(1)">
                        进行中({{count2}})
                    </div>
                    <div class="choose-three font-14 color-6 tac fl cursor" :class="formInlineDataPlatform.status === '0'?'actived':''"
                         @click="getStatus(2)">
                        已结束({{count3}})
                    </div>
                </div>
            </div>
        </div>
        <!--筛选条件-->
        <div class="bg-f">
            <el-form :inline="true" :model="formInlineDataPlatform" label-width="90px"
                     class="clearfix pl-20 pt-30 search">
                <div class="fl">
                    <el-form-item :label="type==0 ? '活动名称:' :type==1 ?'代金券名称:' : '活动商品名称:'">
                        <el-input :maxlength="20"  v-if="type == 0" v-model.trim="formInlineDataPlatform.title"></el-input>
                        <el-input :maxlength="20"  v-if="type == 1" v-model.trim="vouchTitle"></el-input>
                        <el-input :maxlength="20" v-else-if='type == 2' v-model.trim="skuName"></el-input>
                    </el-form-item>
                    <el-form-item label="活动类型:" v-if="type == 0">
                        <el-select v-model="formInlineDataPlatform.activityType" clearable>
                            <el-option :label='item.classifyName' :value="item.activityType" v-for='item in searchClassify' :key="item.activityType"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="手机号:" v-if="type == 1">
                        <el-input :maxlength="11" v-model.trim="mobile" :onkeyup="this.mobile=this.mobile?this.mobile.replace(/\D/g, ''):''"></el-input>
                    </el-form-item>
                </div>
                <el-button type="info" class="fl ml-50" @click='achieveSearch(0)' icon="el-icon-search">搜 索</el-button>
            </el-form>
        </div>
        <div class="addGift ml-10 mr-20">
            <el-button v-if="type == 0" type="text" class="fr ml-20" @click='addActivity'>添加活动</el-button>
            <el-button v-if="type == 1" type="text" class="fr">
            	<a class="color-blue" href="https://sunrise-cbs.oss-cn-hangzhou.aliyuncs.com/template/jiujiajiu/groupon/%E5%AF%BC%E5%85%A5%E4%BB%A3%E9%87%91%E5%88%B8%E5%AE%A2%E6%88%B7%E6%A8%A1%E6%9D%BF.xls" target="_blank">下载模板</a>
            </el-button>
            <el-button v-if="type == 1" type="text" class="fr ml-20" @click='addVoucher'>添加代金券</el-button>
            <el-button v-if="type == 2" type="text" class="fr ml-20" @click='goodVisible = true'>添加赠品</el-button>
            <!-- <el-button v-if="type == 2" type="text" class="fr ml-20" @click="addNoPipGift()">添加非活动商品</el-button> -->
        </div>
        <div class="p30 bg-f" style="padding-top: 10px!important;">
            <!--table-->
            <el-table v-show="type==0" :data="tableDate" v-loading="Loading" class='table-default' >
                <el-table-column label="活动名称" prop="title"></el-table-column>
                <el-table-column label="活动类型" prop="activityType">
                    <template slot-scope="scope">
                        {{ scope.row.activityType | toStatus }}
                    </template>
                </el-table-column>
                <el-table-column label="活动详情" prop="detail"></el-table-column>
                <el-table-column label="支付订单" prop="paidOrderNum">
                    <template slot-scope="scope">
                        <p style="color:#20a0ff;cursor:pointer;" @click="beforeGetOrderList(scope.row.id)">{{scope.row.paidOrderNum}}</p>
                    </template>
                </el-table-column>
                <el-table-column label="实付金额" prop="paidTotalMoney">
                    <template slot-scope="scope">
                        ￥{{scope.row.paidTotalMoney | fmoney}}
                    </template>
                </el-table-column>
                <el-table-column label="客单价">
                    <template slot-scope="scope">
                        <div>
                            <p> ￥{{ scope.row.paidOrderPrice | fmoney}} </p>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="团购活动" prop="grouponName"></el-table-column>
                <el-table-column label="状态">
                    <template slot-scope="scope">
                        <div class="t-status">{{scope.row.status==0 ? '已结束' : '进行中'}}</div>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="120">
                    <template slot-scope="scope">
                        <div class="clearfix tac">
                            <el-button type="text" @click='checkActivity(scope.row.id)'>查看</el-button>
                        </div>
                        <div  class="clearfix tac">
                            <el-button v-if="scope.row.status == 1" type="text"  @click='terminationActivity(scope.row.id)'>终止活动</el-button>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
              <!--代金券列表-->
            <el-table  v-show="type==1" :data="vouchData" v-loading="vouchLoading" class='table-default'>
               <el-table-column label="代金券名称" prop="title"></el-table-column>
                <el-table-column label="面额" prop="amountMoney">
                	 <template slot-scope="scope">
                       	 &yen;{{scope.row.amountMoney | fmoney}}
                    </template>
                </el-table-column>
                <el-table-column label="已发放/剩余">
                	 <template slot-scope="scope">
                      <span><span class="color-blue cursor" @click="vouchOpen(scope.row)">{{scope.row.customerNum}}</span>/{{scope.row.num-scope.row.customerNum}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="已使用" prop="customerUseNum"></el-table-column>
                <el-table-column label="支付订单" prop="paidOrderNum">
                	<template slot-scope="scope">
                	<span @click="vouchOrderOpen(scope.row)" class="color-blue cursor">{{scope.row.paidOrderNum ? scope.row.paidOrderNum : 0}}</span>
                	 </template>
                </el-table-column>
                <el-table-column label="实付金额" prop="paidTotalMoney">
                	 <template slot-scope="scope">
                       	 &yen;{{scope.row.paidTotalMoney | fmoney}}
                    </template>
                </el-table-column>
                <el-table-column label="客单价" prop="paidOrderPrice">
                	 <template slot-scope="scope">
                       	 &yen;{{scope.row.paidOrderPrice | fmoney}}
                    </template>
                </el-table-column>
                <el-table-column label="操作" width='100'>
                    <template slot-scope="scope">
                        <div class="opt">
                            <el-button  type="text" @click="goDetail(scope.row)">查看</el-button>
                            <el-upload class="dib" :action="`/sunrise-gateway/import/groupon/voucherCustomer?voucherId=${scope.row.id}`" :show-file-list="false" :on-success="handUpSuccess" :before-upload="handUpBefore" :on-error="handUpError" accept="application/vnd.ms-excel">
								<el-button @click='needId = scope.row.id' v-if='scope.row.importStatus == "success"' class='ml-0' type="text">导入客户</el-button>
							</el-upload>
							<div class="clearfix">
								<el-button class='no-err fl ml-10' v-if='scope.row.importStatus == "handle"' type="text" :loading="true"></el-button>
								<el-button class='fl' v-if='scope.row.importStatus == "handle"' type="text">处理中</el-button>
							</div>
                            <el-button class='ones' @click='exportError(scope.row.id)' v-if='scope.row.importStatus == "fail"' type="text">下载异常数据</el-button>
                            <div>
                               <el-button type="text" @click="addCustomerDialog(scope.row.id)">添加客户</el-button>
                            </div>
                            <div>
                                <!-- 已发放为0才能删除 -->
                               <el-button type="text" v-if="scope.row.customerNum==0" @click="deleteItem(scope.row.id)">删除</el-button>
                            </div>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <!--赠品列表-->
            <el-table  v-show="type==2" :data="giftData" v-loading="giftLoading" class='table-default'>
                <el-table-column label="活动商品名称" width="300">
                    <template slot-scope="scope">
                        <div class="goods-wrap">
                            <div class="ml-10 l-googs" style="position: relative;">
                                <img v-if='scope.row.giftPic' :src="scope.row.giftPic" />
                                <img v-else src="../../assets/goods.jpg" />
                            </div>
                            <div class="ml-10 r-goods">
                                <div class="tal w-160 lh-20">{{scope.row.skuName}}</div>
                                <div class="tal w-160 lh-20" v-if="scope.row.giftType ==0">规格:1 * {{scope.row.specInfoNum}}</div>
                                <div class="tal w-160 lh-20">
                                    <span style="color:#FF4201;">￥{{scope.row.giftPrice}}</span>
                                    <span style="color:#999999;text-decoration-line: line-through;margin-left: 10px;" v-if="scope.row.giftType ==0">￥{{scope.row.msrp}}</span>
                                </div>
                            </div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="活动商品分类" prop="classifyName"></el-table-column>
                <!-- <el-table-column label="赠品分类" prop="skuName"></el-table-column>
                <el-table-column label="赠品分类" prop="skuNo"></el-table-column> -->
                <el-table-column label="剩余库存">
                    <template slot-scope="scope">
								<span>{{ scope.row.giftStock}}{{ scope.row.giftUnit }}</span>
						</template>
                </el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <p style="color:#999999;" v-if="0">不可操作</p>
                        <div v-else class="opt">
                            <el-button  type="text" v-if="scope.row.giftType==1 && scope.row.operateType==1" @click="editGoods(scope.row)">编辑</el-button>
                            <el-button  type="text" v-if="scope.row.operateType==1" @click="delGoods(scope.row.id)">删除</el-button>
                            <p v-if="scope.row.operateType==0">不可操作</p>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页组件 -->
            <el-pagination class="tar mt-20" v-if="totalPlatform>0" :current-page="currentPagePlatform" :page-size='10'
                           :total='totalPlatform' layout="prev , pager, next, jumper"
                           @current-change="handleCurrentChangePlatform">
            </el-pagination>
        </div>

        <!--添加非标活动商品-->
        <el-dialog class='gift-dialog' @close="close" :title='title' :visible.sync='uncommonGiftDialog'>
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px">
                <el-form-item label='活动商品名称:' prop="skuName">
                    <el-input v-model.trim="ruleForm.skuName" :minlength="2" :maxlength="20" :disabled="editFlag"></el-input>
                </el-form-item>
                <el-form-item label='活动商品图片:' prop="giftPic">
                    <el-upload v-if="!editFlag"
                            class="avatar-uploader bd"
                            action="/sunrise-gateway/oss/ossUpload"
                            :show-file-list="false"
                            :on-success="handleAvatarSuccess"
                            :before-upload="beforeAvatarUpload">
                        <img v-if="ruleForm.giftPic" :src="ruleForm.giftPic" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                    <img v-if="editFlag" :src="ruleForm.giftPic" class="avatar">
                </el-form-item>
                <el-form-item label='活动商品价格:' prop="giftPrice">
                    <sy-input v-model.trim="ruleForm.giftPrice"  :min="0.01" :max="99999.99" type="float"  :disabled="editFlag"></sy-input>
<!--                    <el-input v-model.trim="ruleForm.giftPrice" placeholder="输入赠品价格"></el-input>-->
                    元
                </el-form-item>
                <el-form-item label='活动商品单位:' prop="giftUnit">
                    <el-input v-model.trim="ruleForm.giftUnit" :maxlength="5" :minlength="1" :disabled="editFlag"></el-input>
                </el-form-item>
                <el-form-item label='活动商品库存:' prop="giftStock">
                    <sy-input :min="1" :max="99999" v-model.trim="ruleForm.giftStock" type="int" ></sy-input>
<!--                    <el-input v-model.trim="ruleForm.giftStock" placeholder="输入库存"></el-input>-->
                    {{ruleForm.giftUnit}}
                </el-form-item>
            </el-form>
            <div slot='footer' class="dialog-footer" >
                <el-button type='danger' @click="handleSave('ruleForm')">确 定</el-button>
                <el-button @click="uncommonGiftDialog = false">取 消</el-button>
            </div>
        </el-dialog>
        <!--添加活动商品-->
        <el-dialog class="goods-dialog" title="选择商品" :visible.sync="goodVisible" @close="goodVisible = false" width="950px">
            <div v-loading="tableloading">
                <el-form :inline="true" :model="skuObj" label-width="80px" class="clearfix" :style="{width:'850px'}">
                    <div class="fl">
                        <el-form-item label="商品分类:">
                            <el-select v-model='skuObj.classifyId' placeholder='请选择' clearable >
                                <el-option v-for='item in classifylist' :key='item.id'
                                           :label='item.classifyName'
                                           :value='item.id'></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="商品名称:">
                            <el-input  v-model.trim="skuObj.skuName"></el-input>
                        </el-form-item>
                    </div>
                    <el-button type="primary" class="fl ml-50" @click="querygood">查询</el-button>
                </el-form>
                <el-table @selection-change="handleSelectionChange" :data="goodsList" stripe border tooltip-effect="dark" class="mt-10 table-default">
                    <el-table-column type="selection" width="50" :selectable='selectInit'>
                    </el-table-column>
                    <el-table-column label="商品名称" prop='skuName' width="350" show-overflow-tooltip>
                        <template slot-scope="scope">
                            <div class="goods-wrap">
                                <div class="ml-10 l-googs" style="position: relative;">
                                    <img v-if='scope.row.fileUrl' :src="scope.row.fileUrl" />
                                    <img v-else src="../../assets/goods.jpg" />
                                </div>
                                <div class="ml-10 r-goods">
                                    <div class="tal w-160 lh-20">{{scope.row.skuName}}</div>
                                    <div class="tal w-160 lh-20">规格:1 * {{scope.row.specInfoNum}}</div>
                                    <div class="tal w-160 lh-20">
                                        <span style="color:#FF4201;">￥{{scope.row.grouponPrice}}</span>
                                        <span style="color:#999999;text-decoration-line: line-through;margin-left: 10px;">￥{{scope.row.msrp}}</span>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="商品分类" prop='classifyName' >
                    </el-table-column>
                    <el-table-column label="商品库存" prop='stockEnableQuantity' >
                    </el-table-column>
                    <el-table-column label="销量单位" prop="unitName">
                    </el-table-column>
                    <el-table-column label="状态" prop='auditState' >
                        <template slot-scope="scope">
                            <p v-if="scope.row.goodsState==2" style="color: #20a0ff;">可设为赠品</p>
                            <!-- <p v-else-if="scope.row.totalStock == 0" style="color: #999">库存为0</p> -->
                            <p v-else style="color: #999">商品下架</p>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination class="tar mt-20" v-if='total>0'
                   :current-page="currentPage"
                   :page-size='10'
                   :total='total'
                   layout="prev , pager, next"
                   @current-change="handlePageChange">
                </el-pagination>
                <div slot="footer" class="dialog-footer" style="text-align: center">
                    <el-button type="primary" @click="addgift" :loading="btnLoading">确 定</el-button>
                    <el-button @click="goodVisible = false">取 消</el-button>
                </div>
            </div>
        </el-dialog>

        <!--订单-->
        <el-dialog class="order-dialog" title="订单" :visible.sync="orderVisible" width="950px">
            <div v-loading="orderloading">
                <el-form :inline="true" :model="orderObj" label-width="80px" class="clearfix " :style="{width:'850px'}">
                    <div class="fl">
                        <el-form-item label="订单状态:">
                            <el-select v-model='orderObj.orderStatus' placeholder='请选择' clearable >
                                <el-option v-for='item in orderStatus' :key='item.value'
                                           :label='item.label'
                                           :value='item.value'></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="订单编号:">
                            <el-input  v-model.trim="orderObj.mainOrderNo"></el-input>
                        </el-form-item>
                        <el-button type="primary" class="fr fr110 ml-50" @click="searchList">查询</el-button>
                    </div>
                    <div class="fl" style="width: 100%;">
                        <el-form-item label="下单时间:">
                            <el-date-picker
                                    v-model="rangeTime"
                                    type="daterange"
                                    placeholder="请选择时间">
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item  class="fr fr110 tar" style="position:relative;right: -42px;">
                            <p style="color: #20a0ff;cursor: pointer;" @click="download">导出订单</p>
                        </el-form-item>
                    </div>
                </el-form>
                <el-table @selection-change="handleSelectionChange" :data="orderList" stripe border tooltip-effect="dark" class="table-default">
                    <el-table-column label="订单编号" prop='mainOrderNo' width="200" show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column label="来源" prop='source' >
                    </el-table-column>
                    <el-table-column label="顾客" prop='buyerName' >
                    </el-table-column>
                    <el-table-column label="实付金额" prop="needPayMoney">
                        <template slot-scope="scope">
                            ￥{{ (scope.row.needPayMoney-scope.row.voucherCutMonney).toFixed(2) }}
                        </template>
                    </el-table-column>
                    <el-table-column label="下单时间" prop="units" width="200">
                        <template slot-scope="scope">
                            {{ scope.row.gmtCreate | time }}
                        </template>
                    </el-table-column>
                    <el-table-column label="订单状态" prop='auditState' >
                        <template slot-scope="scope">
                            {{scope.row.orderStatus | getStatus}}
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination class="tar mt-20" v-if='orderTotal>0'
                               :current-page="orderCurrentPage"
                               :page-size='10'
                               :total='orderTotal'
                               layout="prev , pager, next"
                               @current-change="handlerList">
                </el-pagination>
            </div>
        </el-dialog>
        <!-- 代金券添加客户 -->
        <el-dialog title="添加客户" :visible.sync="addCustomerShow" @close="closeAddCustomer">
            <el-form :model="addForm" ref="AddCustomer" label-width="120px" :rules="addFormRules">
                <el-form-item label="姓名：" prop="name">
                    <el-input v-model="addForm.name" maxlength="10" clearable></el-input>
                </el-form-item>
                <el-form-item label="手机号：" prop="mobile">
                    <el-input v-model="addForm.mobile" maxlength="11" clearable></el-input>
                </el-form-item>
                <el-form-item label="备注：" >
                    <el-input v-model="addForm.remark" maxlength="20" clearable></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="closeAddCustomer">取 消</el-button>
                <el-button type="primary" @click="submitAddCustomer">确 定</el-button>
            </div>
        </el-dialog>


         <!--代金券已发放-->
        <el-dialog class="order-dialog" title="发放代金券" :visible.sync="vouchVisible" width="1100px">
            <div v-loading="orderloading">
                <el-form :inline="true" :model="vouchObj" label-width="90px" class="clearfix " :style="{width:'850px'}">
                    <div class="fl">
                        <el-form-item label="代金券状态:">
                            <el-select v-model='vouchObj.status' placeholder='请选择代金券状态' clearable >
                                <el-option v-for='item in vouchStatusList' :key='item.value'
                                           :label='item.label'
                                           :value='item.value'></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="顾客:">
                            <el-input :maxlength="11"  v-model.trim="vouchObj.mobile" placeholder='请输入顾客手机号码'></el-input>
                        </el-form-item>
                        <el-button type="primary" class="fr fr110 ml-50" @click="searchVouch">查询</el-button>
                    </div>
                </el-form>
                <el-table @selection-change="handleSelectionChange" :data="vouchList" stripe border tooltip-effect="dark" class="table-default">
                    <el-table-column label="代金券编号" prop='voucherCode' width="200" show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column label="顾客" prop='mobile'>
                    	<template slot-scope="scope">
                    		<span>{{scope.row.name}}</span>
                    		<br />
                    		<span>{{scope.row.mobile}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="发放时间" width="160">
                    	<template slot-scope="scope">
                    		<span v-if='scope.row.gmtCreate'>{{scope.row.gmtCreate | time}}</span>
                    		<span v-else>--</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="使用时间" prop="needPayMoney" width="160">
                        <template slot-scope="scope">
                        	<span v-if='scope.row.gmtUse'>{{scope.row.gmtUse | time}}</span>
                        	<span v-else>--</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="关联订单号" prop="mainOrderNo" width="160">
                        <template slot-scope="scope">
                        	<span v-if='scope.row.mainOrderNo'>{{scope.row.mainOrderNo}}</span>
                        	<span v-else>--</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="代金券状态" >
                        <template slot-scope="scope">
                        	<span v-if='scope.row.useState == 0'>未使用</span>
                        	<span v-if='scope.row.useState == 1'>已使用</span>
                        	<span v-if='scope.row.useState == -1'>已过期</span>
                        </template>
                    </el-table-column>
                      <el-table-column label="操作" >
                        <template slot-scope="scope">
                         	  <span class="color-blue cursor" v-if='scope.row.useState == 0' @click="deleteVouch(scope)">删除</span>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination class="tar mt-20" v-if='vouchTotal>0'
                               :current-page="vouchCurrentPage"
                               :page-size='10'
                               :total='vouchTotal'
                               layout="prev , pager, next"
                               @current-change="handlerListVouch">
                </el-pagination>
            </div>
        </el-dialog>
		<!--代金券支付订单-->
        <el-dialog class="order-dialog" title="订单" :visible.sync="orderVisibleVouch" width="950px">
            <div v-loading="orderloading">
                <el-form :inline="true" :model="orderObjVouch" label-width="80px" class="clearfix " :style="{width:'850px'}">
                    <div class="fl">
                        <el-form-item label="订单状态:">
                            <el-select v-model='orderObjVouch.orderStatus' placeholder='请选择' clearable >
                                <el-option v-for='item in orderStatus' :key='item.value'
                                           :label='item.label'
                                           :value='item.value'></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="订单编号:">
                            <el-input  v-model.trim="orderObjVouch.mainOrderNo"></el-input>
                        </el-form-item>
                        <el-button type="primary" class="fr fr110 ml-50" @click="searchListOrder">查询</el-button>
                    </div>
                    <div class="fl" style="width: 100%;">
                        <el-form-item label="下单时间:">
                            <el-date-picker
                                    v-model="rangeTimes"
                                    type="daterange"
                                    placeholder="请选择时间">
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item  class="fr fr110 tar" style="position:relative;right: -42px;">
                            <p style="color: #20a0ff;cursor: pointer;" @click="downloadVouch">导出订单</p>
                        </el-form-item>
                    </div>
                </el-form>
                <el-table @selection-change="handleSelectionChange" :data="orderList" stripe border tooltip-effect="dark" class="table-default">
                    <el-table-column label="订单编号" prop='mainOrderNo' width="200" show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column label="来源" prop='source' >
                    </el-table-column>
                    <el-table-column label="顾客" prop='buyerName' >
                    </el-table-column>
                    <el-table-column label="实付金额" prop="needPayMoney">
                        <template slot-scope="scope">
                            ￥{{ (scope.row.needPayMoney-scope.row.voucherCutMonney).toFixed(2)}}
                        </template>
                    </el-table-column>
                    <el-table-column label="下单时间" prop="units" width="200">
                        <template slot-scope="scope">
                            {{ scope.row.gmtCreate | time }}
                        </template>
                    </el-table-column>
                    <el-table-column label="订单状态" prop='auditState' >
                        <template slot-scope="scope">
                            {{scope.row.orderStatus | getStatus}}
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination class="tar mt-20" v-if='orderTotal>0'
                               :current-page="vouchCurrentOrderPage"
                               :page-size='10'
                               :total='orderTotal'
                               layout="prev , pager, next"
                               @current-change="handlerListVoucher">
                </el-pagination>
            </div>
        </el-dialog>
        <!--导出-->
        <form ref="exportList" action='/sunrise-gateway/export/groupon/activityOrder/list' style="display: none">
            <input type="hidden" name="startRow" v-model="a">
            <input type="hidden" name="startCol" v-model="a">
            <input type="hidden" v-if="activityId" name="activityId" v-model="activityId">
            <input type="hidden" v-if="orderObj.orderStatus" name="orderStatus" v-model="orderObj.orderStatus">
            <input type="hidden" v-if="orderObj.mainOrderNo" name="mainOrderNo" v-model="orderObj.mainOrderNo">
            <input type="hidden" v-if="orderObj.startDate" name="startDate" v-model="orderObj.startDate">
            <input type="hidden" v-if="orderObj.endDate" name="endDate" v-model="orderObj.endDate">
            <input type="hidden" v-if="modelName" name="modelName" v-model="modelName">
            <input type="hidden"  name="pageIndex" v-model="orderCurrentPage">
            <input type="hidden"  name="pageSize" v-model="pageSize">
            <input type="hidden" name="fileName" v-model="fileName">
        </form>
        <!--导出-->
        <form ref="exportListVouch" action='/sunrise-gateway/export/groupon/voucher/selectOrderList' style="display: none">
            <input type="hidden" name="startRow" v-model="a">
            <input type="hidden" name="startCol" v-model="a">
            <input type="hidden" v-if="voucherId" name="voucherId" v-model="voucherId">
            <input type="hidden" v-if="orderObjVouchExport.orderStatus" name="orderStatus" v-model="orderObjVouchExport.orderStatus">
            <input type="hidden" v-if="orderObjVouchExport.mainOrderNo" name="mainOrderNo" v-model="orderObjVouchExport.mainOrderNo">
            <input type="hidden" v-if="orderObjVouchExport.startDate" name="startDate" v-model="orderObjVouchExport.startDate">
            <input type="hidden" v-if="orderObjVouchExport.endDate" name="endDate" v-model="orderObjVouchExport.endDate">
            <input type="hidden" v-if="modelName" name="modelName" v-model="modelName">
            <input type="hidden" name="fileName" v-model="fileName">
        </form>
          <!--失败文件导出-->
        <form ref="exporterr" action='/sunrise-gateway/export/groupon/getErrorVoucherCustomer' style="display: none">
            <input type="hidden" name="startRow" v-model="a">
            <input type="hidden" name="startCol" v-model="a">
            <input type="hidden" name="voucherId" v-model="voucherIdError">
            <input type="hidden" v-if="modelName" name="modelName" v-model="modelName">
            <input type="hidden" name="fileName" v-model="fileNames">
        </form>
    </div>
</template>

<script>
    import { axiosApi,gotoLoginPage,basePath } from "~/api/api";
    import { mapActions } from 'vuex'
    import { check } from '../../js/extend.js'

    const checkNum = (rule, value, callback) => {
        if(value<=0){
            return callback(new Error('请输入正确的库存'))
        }else{
            callback()
        }
    }
    export default {
        name: "groupSalesManager",
        data() {
            var validateMobile = (rule, value, callback) => {
                let reg = /^1(3\d|4[5-9]|5[0-35-9]|6[2567]|7[0-8]|8\d|9[0-35-9])\d{8}$/

                if (value === '') {
                 callback(new Error('请输入手机号'));
                } else if(!reg.test(value)) {
                 callback(new Error('请输入正确手机号'));
                } else {
                 callback();
                }
            };
            return {
            	fileNames:'异常客户数据.xls',
            	needId:"",
            	voucherIdError:'',//失败代金券id
                activityId:'',//活动id
                a: 0,//固定值 写死
                pageSize:10000,
                modelName:'empty',
                orderList:[],//订单列表
                orderTotal:0,//订单总计
                orderCurrentPage:1,//订单当前页
                rangeTime:[],
                rangeTimes:[],
                fileName:'',//导出表格名称
                exportForm:{},//订单
                id:null,//编辑赠品id
                editFlag:false,
                title:'添加非标活动商品',
                skuName:null,//赠品名称
                Loading: false,//列表loading
                giftLoading:false,//赠品列表loading
                orderloading:true,//订单弹窗loading
                vouchLoading:false,//代金券loading
                type: 0,//tab切换
                dataTabs: ["满减/送管理","代金券管理", "赠品"],//tab标题
                count1: 0,//全部状态统计
                count2: 0,//进行中状态统计
                count3: 0,//已结束状态统计
                status: 0,//满减送默认显示全部状态
                currentPagePlatform: 1,//当前页
                totalPlatform: 0,//总数据条数
                formInlineDataPlatform: {
                    title: '',//活动名称
                    activityType: '',//活动类型
                    status:'',//活动状态
                },//筛选条件
                orderVisible:false,//订单弹窗
                orderStatus:[//订单状态
                    { value:1,label:'待支付' },
                    { value:2,label:'已支付' },
                ],//订单状态
                searchClassify: [
                    {classifyName: '满额减', activityType: '1'},
                    {classifyName: '满件减', activityType: '2'},
                    {classifyName: '满额赠', activityType: '3'},
                    {classifyName: '满件赠', activityType: '4'}
                ],
                tableDate2:[],//满减增列表数据备份
                tableDate: [],//满减增列表数据
                giftData:[],//赠品列表
                giftData2:[],//赠品列表
                vouchData:[],//代金券数据
                addForm:{
                    voucherId: null,
                    mobile: null,
                    name:null,
                    remark:null
                }, // 代金券添加客户
                addCustomerShow: false, //代金券添加客户弹框
                addFormRules: {
                    name: [
                        { required: true, message: '请输入姓名', trigger: 'blur' },
                    ],
                    mobile: [
                        { required: true, validator:validateMobile, trigger: 'blur' },
                    ],
                },
                goodVisible: false,//赠品弹框
                uncommonGiftDialog: false,//非标赠品弹框
                ruleForm: {
                    skuName: '',
                    giftType:1,//0平台赠品 1自定义赠品
                    giftPic: '',//赠品图片
                    giftPrice: '',//赠品价格
                    giftUnit: '',//赠品计量单位
                    giftStock: '',//赠品库存
                },
                vouchTitle:null,
                mobile: '',
                rules: {
                    skuName: [
                        { required: true, message: '请输入赠品名称', trigger:'blur'},
                        { min:2, max: 20, message: '请输入赠品名称', trigger:'blur'},
                        { validator:check,trigger:'blur' }],
                    giftPic: [
                        { required: true, message: '请上传赠品图片' },],//赠品图片
                    giftPrice: [{
                        required: true,
                        message: '输入赠品价格',
                    }],//赠品价格
                    giftUnit: [{
                        required: true,
                        message: '输入赠品单位',
                    }, {
                        pattern: /^[\u4e00-\u9fa5]{0,5}$/,
                        message: '只能输入5个以内的汉字',
                    }],//赠品计量单位
                    giftStock: [{
                        required: true,
                        message: '输入赠品库存',
                    }],//赠品计量单位
                },
                skuObj:{
                    skuName:null,     //商品名称
                    classifyId:null  //商品分类
                },//赠品搜索条件
                orderObj:{
                    orderStatus:null,
                    mainOrderNo:null,
                    startDate:null,
                    endDate:null
                },//订单搜索条件
                // classifylist:[],//商品分类
                // proData:[],//商品列表
                // total:0,//商品总数
                currentPage:1,//商品弹窗当前页
                btnLoading:false,//搜索时
                willAddSend:[],//添加赠品列表
                vouchVisible:false,//已发放代金券
                voucherId:'',//代金券id
                vouchObj:{
                	status:'',
                	mobile:''
                },
                vouchList:[],
                vouchStatusList:[
                	 { value:0,label:'未使用' },
                	 { value:1,label:'已使用' },
                	  { value:-1,label:'已过期' },
                ],
                vouchTotal:0,
                vouchCurrentPage:1,
                vouchCurrentOrderPage:1,
                orderVisibleVouch:false,//代金券支付订单
                orderObjVouch:{
                    orderStatus:null,
                    mainOrderNo:null,
                    startDate:null,
                    endDate:null
                },
                orderObjVouchExport:{
                	orderStatus:null,
                    mainOrderNo:null,
                    startDate:null,
                    endDate:null
                }
            }
        },
        computed:{
            tableloading(){
                return   this.$store.state.sendGoodsManager.tableloading
            },
            classifylist(){
                return this.$store.state.classifylist.classifylistData
            },
            goodsList(){
                return this.$store.state.sendGoodsManager.goodsList;
            },
            total(){
                return this.$store.state.sendGoodsManager.total;
            }
        },
        filters:{
            toStatus(val){
                let str;
                switch(val){
                    case 1:{
                        str = '满额减' ;
                        break
                    }
                    case 2:{
                        str = '满件减' ;
                        break
                    }
                    case 3:{
                        str = '满额赠' ;
                        break
                    }
                    case 4:{
                        str = '满件赠' ;
                        break
                    }
                }
                return str;
            },
            getStatus(val){
                let str;
                switch(val){
                    case 0:{
                        str = '已取消' ;
                        break
                    }
                    case 1:{
                        str = '待支付' ;
                        break
                    }
                    case 2:{
                        str = '已支付' ;
                        break
                    }
                }
                return str;
            }
        },
        watch:{
            goodVisible(val){
                if(val){
                    this.querygood()
                }
            },
            // 'formInlineDataPlatform.status':{
            //     handler(){
            //         this.currentPagePlatform = 1;
            //         this.init()
            //     },
            //     deep: true,
            //     immediate: true
            // },
            // type:{
            //     handler(){
            //         this.currentPagePlatform = 1;
            //         this.init()
            //     },
            //     deep: true,
            //     immediate: true
            // },
            rangeTime(val){
                if(val && val[0]){
                    this.orderObj.startDate = new Date(val[0]).getTime();
                    this.orderObj.endDate = new Date(val[1]).getTime() - 0 + 24*60*60*1000;
                }else{
                    this.orderObj.startDate =null;
                    this.orderObj.endDate = null
                }
            },
             rangeTimes(val){
                if(val && val[0]){
                    this.orderObjVouch.startDate = new Date(val[0]).getTime();
                    this.orderObjVouch.endDate = new Date(val[1]).getTime() - 0 + 24*60*60*1000;
                }else{
                    this.orderObjVouch.startDate =null;
                    this.orderObjVouch.endDate = null
                }
            },
            tableDate2(val){
                this.tableDate = this.tableDate2.slice(0,10);
            },
            giftData2(val){
                this.giftData = this.giftData2.slice(0,10);
            },
            orderVisible(val){
                if(!val){
                    this.orderObj = {
                        orderStatus:null,
                        mainOrderNo:null,
                        startDate:null,
                        endDate:null
                    };
                    this.rangeTime = null;
                    this.orderList = []
                }
            }
        },
        methods: {
            ...mapActions({
                'queryclassifylistData':'queryclassifylistData',
                'getGoodsList':'getGoodsList'
            }),
            handUpSuccess(res, file, fileList) {
				
				
				switch(res.code) {
					case "000000": //系统交易成功
						this.getStatusId()
						break;
					case "999999": //系统异常
						this.$alert(res.message, '提示信息', {
							confirmButtonText: '确定'
						});
						this.$loading().close()
						break;
					case "AUTH_001": //用户未登陆
                        gotoLoginPage();
						break;
					case "AUTH_002": //用户无权限
						this.$alert(res.message, '提示信息', {
							confirmButtonText: '确定'
						});
						this.$loading().close()
						break;
					case "LOGIN_005": //用户已禁用
						this.$alert(res.message, '提示信息', {
							confirmButtonText: '确定'
						});
						this.$loading().close()
						break;
					case "LOGIN_006": //用户session失效
						gotoLoginPage();
						break;
					default:
						this.$alert(res.message, '提示信息', {
							confirmButtonText: '确定'
						});
						this.$loading().close()
						break;
				}

			},
			getStatusId(){
                axiosApi('/groupon/voucherCustomer/selectImportStatus',{
                	voucherId:this.needId
                },res => {
                	
                	if(res.data.data == 'handle'){
                		setTimeout(()=>{
                			this.getStatusId()
                		},2000)
                	}else{
                		this.$loading().close()
                		this.type = 1
                		this.achieveSearch()
                	}
                    
                },1,10,err => {
                    this.$message.warning(err.data.message)
                })
            },
            getCouponList(){
                this.vouchLoading = true;
                let url = '/groupon/voucher/selectList';
                let body = {
                    "title": this.vouchTitle,
                    mobile:this.mobile
                }
              axiosApi(url,body,res => {
                this.vouchData = res.data.data.elements;
                this.totalPlatform = res.data.data.totalCount;
                this.vouchLoading = false;
              })
            },
			handUpError(err, file, fileList) {
				this.$loading().close()
				this.$message.error('导入失败！');
			},
			handUpBefore(file) {
				this.$loading({
					fullscreen: true,
					lock: true,
					text: '正在导入客户...'
				})
				return true
			},
            //下载订单
            download(){
                let str = new Date().getFullYear() + "-" + (new Date().getMonth() + 1) + "-" + new Date().getDate();
                this.fileName = '订单' + str + '.xls';
                setTimeout(()=>{
                    if(this.orderList.length>0){
                        this.$refs.exportList.submit();
                    }else{
                        this.$message.warning('暂无数据，无法导出');
                    }
                },0)
            },
             downloadVouch(){
                let str = new Date().getFullYear() + "-" + (new Date().getMonth() + 1) + "-" + new Date().getDate();
                this.fileName = '订单' + str + '.xls';
                setTimeout(()=>{
                    if(this.orderList.length>0){
                        this.$refs.exportListVouch.submit();
                    }else{
                        this.$message.warning('暂无数据，无法导出');
                    }
                },0)
            },
            beforeGetOrderList(id){
                this.activityId = id;
                this.orderVisible = true;
                this.getOrderList({activityId:this.activityId});
            },
            //获取订单列表
            getOrderList({activityId=null,orderStatus=null,mainOrderNo=null, startDate=null, endDate=null,currentPage=1}){
                let body = { activityId, orderStatus, mainOrderNo, startDate, endDate };
                axiosApi('/groupon/activityOrder/list',body,res => {
                    this.orderloading = false;
                    this.orderList = res.data.data.elements;
                    this.orderTotal = res.data.data.totalCount;
                },currentPage,10,err => {
                    this.orderloading = false;
                    this.$message.warning(err.data.message)
                })
            },
            handlerList(val){
                this.orderCurrentPage = val;
                this.searchList();
            },
            //搜索订单
            searchList(){
                this.orderloading = true;
                this.getOrderList({...this.orderObj,activityId:this.activityId,currentPage:this.orderCurrentPage})
            },
            //初始化
            init() {
                this.achieveSearch();
            },
            //切换tab
            handleTabs(e) {
            	this.currentPagePlatform = 1
                this.type = e.target.attributes.type.value;
                this.totalPlatform = 1;
                this.init()
            },
            //切换子tab
            getStatus(index) {
                this.formInlineDataPlatform = {
                    activityType:'',
                    status:'',
                    title:''
                }
                switch (index) {
                    case 0:{
                        this.formInlineDataPlatform.status = '';
                        break;
                    }
                    case 1:{
                        this.formInlineDataPlatform.status = '1';
                        break;
                    }
                    case 2:{
                        this.formInlineDataPlatform.status = '0';
                        break;
                    }
                    default :{
                        this.formInlineDataPlatform.status = '';
                        break
                    }
                }
                this.currentPagePlatform = 1;
                this.init()
            },
            //搜索
            achieveSearch(payload) {
                let func = this.$debouncing(()=>{
                    if(payload == 0&&this.currentPagePlatform != 1) {
                        this.currentPagePlatform = 1;
                        return
                    }
                    let url;
                    let body;
                    if (this.type == 0) {//搜索活动
                        this.Loading = true;
                        body = this.formInlineDataPlatform;
                        url = '/groupon/activity/list'
                    } else if(this.type == 2) {//搜索赠品
                        this.giftLoading = true;
                        url = '/groupon/gift/list';
                        body = {
                            "skuName": this.skuName
                        }
                    }else if(this.type == 1){ //代金券
                        this.vouchLoading = true;
                        url = '/groupon/voucher/selectList';
                        body = {
                            "title": this.vouchTitle,
                            mobile:this.mobile
                        }
                    }
                    axiosApi(url,body,res => {
                        if(this.type == 0){
                            this.Loading = false;
                            this.tableDate2 = res.data.data.elements;
                            this.totalPlatform = res.data.data.totalCount;
                        }else if(this.type == 2) {
                            this.giftLoading = false;
                            this.giftData2 = res.data.data.elements;
                            this.totalPlatform = res.data.data.totalCount;
                        }else if(this.type == 1) {
                            this.vouchLoading = false;
                            this.vouchData = res.data.data.elements;
                            this.totalPlatform = res.data.data.totalCount;
                        }
                    },this.currentPagePlatform,10,err=>{
                        if(this.type == 0){
                            this.Loading = false;
                        }else if(this.type == 2) {
                            this.giftLoading = false;
                        }else if(this.type == 1) {
                            this.vouchLoading = false;
                        }
                    })
                })
            	func()
            },
            getCount(){
                axiosApi('/groupon/activity/selectNumGroupByStatus',{},res => {
                    this.count1 = res.data.data.total;
                    this.count2= res.data.data.effective ;
                    this.count3 = res.data.data.end;
                })
            },
            //终止活动
            terminationActivity(id){
                this.$confirm('确定要终止该活动吗?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    axiosApi('/groupon/activity/stop',{ id : id },res => {
                        this.$notify({
                            title: '成功',
                            message: '该活动已经终止',
                            type: 'success'
                        });
                        setTimeout(() => {
                            this.init();
                            this.getCount()
                        },0);
                    },1,10,err => {
                        this.$message.warning(err.data.message)
                    })
                }).catch()
            },
            //查看活动
            checkActivity(id){
                sessionStorage.managerType = this.formInlineDataPlatform.status;
                this.$router.push({
                    path:basePath + '/superGroup/activityDetail',
                    query:{
                        id:id
                    }
                })
            },
            //改变当前页
            handleCurrentChangePlatform(val) {
                this.currentPagePlatform = val;
                this.init();
            },
            //添加非标赠品  保存
            handleSave(ruleForm) {
                this.$refs[ruleForm].validate((valid) => {
                    if (valid) {
                        let url = this.editFlag ? '/groupon/gift/edit' :'/groupon/gift/add';
                        let body = {
                            ...this.ruleForm,
                        }
                        if (this.editFlag) {
                            body.id = this.id
                        }
                        axiosApi(url, body ,res => {
                            this.$notify({
                                title: '成功',
                                message: this.id ? '编辑' : '添加' + '非标活动商品成功',
                                type: 'success'
                            });
                            this.close();
                            setTimeout(() => {
                                this.init()
                            },0)
                        },1,10,err => {
                            this.$message.warning(err.data.message)
                        })
                    }
                })
            },
            //图片上传
            beforeAvatarUpload(file) {
                let bool = this.isUploadImg(file);
                return bool
            },
            //成功
            handleAvatarSuccess(res, file) {
                this.ruleForm.giftPic = res.data[0]
            },
            //搜索赠品
            querygood(){
                let body = {
                    ...this.skuObj,
                    sellerId:sessionStorage.getItem('traderId'),
                    goodsState: 2,
                    auditState:2,
                    goodsNo:null,
                    checkGift:true,
                    currentPage:this.currentPage
                };
                this.getGoodsList(body)
            },
            //选择商品
            handleSelectionChange(val){
                this.willAddSend = val;
            },
            //商品分页
            handlePageChange(val){
                this.currentPage = val;
                this.querygood();
            },
            addNoPipGift(){
                this.editFlag = false
                this.uncommonGiftDialog = true ;
                this.title = '添加非标活动商品'
                this.ruleForm.skuName = '';
                this.ruleForm.giftType = 1;
                this.ruleForm.giftPic = '';
                this.ruleForm.giftPrice = '';
                this.ruleForm.giftUnit = '';
                this.ruleForm.giftStock = '';
            },
            //添加赠品
            addgift(){
                if(this.willAddSend.length<=0){
                    this.$message.warning('请选择活动商品')
                    return
                };
                //goodVisible = false
                let goodsIdList = this.willAddSend.map(v => {
                    return v.id
                });
                this.btnLoading = true;
                axiosApi('/groupon/gift/add',{goodsIdList:goodsIdList,giftType:0},res => {
                    this.$notify({
                        title: '成功',
                        message: '添加活动商品成功',
                        type: 'success'
                    });
                    this.goodVisible = false;
                    this.btnLoading = false;
                    setTimeout(() => {
                        this.currentPagePlatform = 1;
                        this.init();
                    },0)
                },1,10,err => {
                    this.goodVisible = false;
                    this.btnLoading = false;
                    this.$message.warning(err.data.message);
                })
            },
            //编辑非标商品
            editGoods(obj){
                this.editFlag = true;
                this.title = '编辑非标活动商品';
                this.uncommonGiftDialog = true;
                this.ruleForm.skuName = obj.skuName;
                this.ruleForm.giftType = 1;
                this.ruleForm.giftPic = obj.giftPic;
                this.ruleForm.giftPrice = obj.giftPrice;
                this.ruleForm.giftUnit = obj.giftUnit;
                this.ruleForm.giftStock =obj.giftUseStock!=null?obj.giftStock+obj.giftUseStock: obj.giftStock;
                this.id = obj.id;
            },
            //删除赠品
            delGoods(id) {
                this.$confirm('确定要删除该活动商品吗?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    axiosApi('/groupon/gift/del',{id:id},res => {
                        this.$notify({
                            title: '成功',
                            message: '成功删除活动商品',
                            type: 'success'
                        });
                        setTimeout(() => {
                           this.init();
                        },0)
                    },1,10,err => {
                        this.$message.warning(err.data.message)
                    })
                })
            },
            //关闭非标弹框
            close(){
                if (this.$refs.ruleForm) this.$refs.ruleForm.resetFields();
                this.uncommonGiftDialog = false
            },
            //添加活动
            addActivity(){
                this.$router.push(basePath + '/superGroup/addActivity')
                // .then(() => {
                //     this.$router.go(0); // 刷新当前页面
                //     });
            },
            // 代金券添加客户
            addCustomerDialog(id){
                this.addCustomerShow = true
                this.addForm.voucherId = id
                // this.$router.go(0);
                
            },
            submitAddCustomer(){
                this.$refs['AddCustomer'].validate((valid) => {
                    if (valid) {
                        axiosApi('/groupon/voucherCustomer/add', this.addForm, res => {
                            this.closeAddCustomer()
                            this.$message({
                                type: 'success',
                                message: '添加成功！'
                            });
                            this.getCouponList()
                        }, null, null, res => {
                            this.$message({
                                type: 'info',
                                message: res.data.message,
                                duration: 3000
                            });
                        })
                    } else {
                        return false;
                    }
                });
            },
            closeAddCustomer(){
                this.addCustomerShow = false
                this.addForm = {
                    voucherId: null,
                    mobile: null,
                    name:null,
                    remark:null
                }
                this.$refs['AddCustomer'].resetFields();
            },


            // 删除代金券
            deleteItem(id){
                this.$confirm('此操作将永久删除该代金券, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                    }).then(() => {
                        axiosApi('/groupon/voucher/delete', {
                            id:id
                        }, res => {
                            this.$message({
                                type: 'success',
                                message: '删除成功！'
                            });
                            this.type = 1
                            this.achieveSearch()
                        }, null, null, res => {
                            this.$message({
                                type: 'info',
                                message: res.data.message,
                                duration: 3000
                            });
                        })
                    }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });          
                });
                
            },
            //添加代金券
            addVoucher(){
            	sessionStorage.vouchType = this.type;
            	// this.$router.push(basePath + '/superGroup/voucherDetail')
                this.$router.push(basePath + '/superGroup/voucherDetail')
                // .then(() => {
                //     this.$router.go(0); // 刷新当前页面
                //     });

            },
            selectInit(row,index){
                // row.totalStock == 0 ||
                if(row.goodsState == 1){
                    return false
                }else {
                    return true
                }
            },
            //添加带进去拿
            goDetail(row){
            	sessionStorage.vouchType = this.type;
            	this.$router.push({
            		path:basePath + '/superGroup/voucherDetail',
            		query:{
            			id:row.id
            		}
            	})
            },
            vouchOpen(row){
//          	this.vouchCurrentPage = 1
				this.vouchObj.mobile = this.mobile
				this.vouchObj.status = ''
            	this.orderloading = true
            	this.voucherId = row.id
            	this.vouchVisible = true
            	this.voucherCustomer(0)
            },
            voucherCustomer(payload) { // 代金券客户列表
            	if(payload == 0&&this.vouchCurrentPage != 1) {
					this.vouchCurrentPage = 1;
					return
				}
				axiosApi('/groupon/voucherCustomer/selectList', {
					voucherId: this.voucherId,
					mobile:this.vouchObj.mobile,
					useState:this.vouchObj.status
				}, res => {
					this.orderloading = false
					this.vouchList = res.data.data.elements
					this.vouchTotal = res.data.data.totalCount
				}, this.vouchCurrentPage, 10, res => {
					this.orderloading = false
					this.$message({
						type: 'info',
						message: res.data.message,
						duration: 3000
					});
				})
			},
			 vouchOrderOpen(row){
			 	this.orderObjVouch.orderStatus = null
			 	this.orderObjVouch.mainOrderNo = null
			 	this.rangeTimes = []
            	this.vouchCurrentOrderPage = 1
            	this.orderVisibleVouch = true;
               	this.voucherId = row.id
                this.voucherCustomerOrder()
            },
            handlerListVoucher(val){
                this.vouchCurrentOrderPage = val;
                this.voucherCustomerOrder();
            },
			voucherCustomerOrder() { // 支付订单列表
				axiosApi('/groupon/voucher/selectOrderList', {
					voucherId: this.voucherId,
					orderStatus:this.orderObjVouch.orderStatus,
					mainOrderNo:this.orderObjVouch.mainOrderNo,
					startDate:this.orderObjVouch.startDate,
					endDate:this.orderObjVouch.endDate
				}, res => {
					this.orderloading = false
					this.orderList = res.data.data.elements;
                    this.orderTotal = res.data.data.totalCount;
				}, this.vouchCurrentOrderPage, 10, res => {
					this.orderloading = false
					this.$message({
						type: 'info',
						message: res.data.message,
						duration: 3000
					});
				})
			},
			searchListOrder(){
				this.orderObjVouchExport.orderStatus = this.orderObjVouch.orderStatus
				this.orderObjVouchExport.startDate = this.orderObjVouch.startDate
				this.orderObjVouchExport.endDate = this.orderObjVouch.endDate
				this.orderObjVouchExport.mainOrderNo = this.orderObjVouch.mainOrderNo
				this.vouchCurrentPage = 1
				this.voucherCustomerOrder()
			},
			searchVouch(){
				this.voucherCustomer(0)
			},
			handlerListVouch(val){
				this.vouchCurrentPage = val
				this.voucherCustomer()
			},
			deleteVouch(scope){
				this.$confirm('您是否要删除选中的顾客？', '提示信息', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
				}).then(() => {
							axiosApi('/groupon/voucherCustomer/delete', {
								id:scope.row.id
							}, (res) => {
								this.$message({
									type: 'success',
									message: '删除成功!',
									duration: 1000
								});
								this.searchVouch();
								this.init()
							}, 1, 10, (res) => {
								this.$message({
									type: 'info',
									message: res.data.message,
									duration: 3000
								});
							});
					}).catch(() => {
						this.$message({
							type: 'info',
							message: '已取消删除',
							duration: 2000
						});
					})
			},
			//失败文件导出
			exportError(item,index){
				this.vouchData.forEach(items=>{
					if(items.id == item){
						items.importStatus = "success"
					}
				})
				this.voucherIdError = item
				setTimeout(()=>{
					this.$refs.exporterr.submit();
				},100)
				
			}
			
        },
        mounted() {
            this.$nextTick(()　=> {
                this.queryclassifylistData();
                if(sessionStorage.getItem('managerType')){
                    this.$set(this.formInlineDataPlatform,'status',sessionStorage.getItem('managerType'))
                }else if(sessionStorage.vouchType == 1){
                	this.type = sessionStorage.vouchType
                }else {
                    this.init();
//                  this.getCount()
                }
                this.getCount()
                this.getCouponList()
                sessionStorage.removeItem('managerType')
                sessionStorage.removeItem('vouchType')
            })
        }
    }
</script>

<style lang="less" >
    .fr110{
        width: 110px;
    }
    .groupSalesManager {
        .el-date-editor--daterange.el-input{
            width: 240px;
        }
        .second-title {
            height: 60px;
            padding: 17px 0px 17px 0px;
            box-sizing: border-box;
            border-bottom: 1px solid #EEEEEE;
        }

        .choose-three {
            width: 114px;
            height: 26px;
            line-height: 26px;
        }

        .actived {
            border: 1px solid #FF4201;
            color: #FF4201;
        }

        #groupProduct {
            .el-form-item__label {
                width: 100px;
            }

            .table-default .el-button {
                width: auto;
            }

            .c {
                position: relative;
            }

            .count4 {
                position: absolute;
                left: 245px;
                top: 15px;
            }

            .el-tabs__header {
                padding-left: 0;
            }

            .count2 {
                position: absolute;
                left: 325px;
                top: 15px;
            }

            .count3 {
                position: absolute;
                left: 435px;
                top: 15px;
            }

            .red {
                color: red;
            }

            .w-400 {
                width: 400px;
            }

            .w-160 {
                width: 160px;
            }

            .el-dialog {
                min-height: 300px;
            }

            .width-70 {
                width: 70px;
            }

            .black {
                .edit-btn span {
                    color: #000000 !important;
                }
            }

            .obt {
                margin-top: 80px;
                margin-right: 60px;
            }
        }

        .gift-dialog {
            .avatar-uploader, .avatar-uploader-icon, .avatar {
                width: 120px;
                height: 120px;
                line-height: 120px;
            }

            .el-dialog__body {
                padding-bottom: 100px;
            }
        }

        .goods-dialog ,.order-dialog{
            .el-dialog {
                // width: 1100px!important;
                max-height: 1000px;
            }
            .table-default.el-table .cell{
                padding: 3px 15px 0;
            }
            .el-dialog__body {
                padding: 20px 30px;
            }
            .el-dialog {
                .el-form {
                    .el-form-item {
                        .el-form-item__label {
                            &::before {
                                display: none
                            }
                        }
                    }
                }
            }
        }
        .goods-dialog{
            .el-form .el-input{width: 120px;}
        }
        .order-dialog{
            .el-date-editor--datetimerange.el-input{
                width: 240px;
            }
        }
        .goods-wrap{
            overflow: hidden;
            display: flex;
            .r-goods{
                flex: 1;
                overflow: hidden;
                div{
                    font-size: 12px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
        }
        
        .addGift{
            overflow: hidden;
        }
        .no-err{
        	width: 10px;
        	/*background-color:#FFFFFF;
  			opacity: 0.1;*/
        }
        .ones{
        	span{
        		margin-left: -18px !important;
        	}
        }
    }
</style>