import Vue from 'vue'
import storeTop from './storeTop.vue'
import logoTop from './logoTop.vue'
import top from "./top.vue"
import foot from "./foot.vue"
import tableA from './tables/tableA.vue'
import tableB from './tables/tableB.vue'
import tableC from './tables/tableC.vue'
import tableD from './tables/tableD.vue'
import chooseGoods from './chooseGoods.vue'
import chooseClient from './chooseClient.vue'
import chooseGift from './chooseGift.vue'
import commInfo from './tabEditcomm/commInfo.vue'//基本信息
import commDetails from './tabEditcomm/commDetails.vue'//商品详情
import aptitude from './tabEditcomm/aptitude.vue'//销售资质
/*import excommInfo from './tabExamcomm/commInfo.vue'//基本信息（审核）
import excommDetails from './tabExamcomm/commDetails.vue'//商品详情（审核）*/
import integral from './tabEditcomm/integral.vue'// 积分设置
import giftmanage from './giftmanage.vue'
import enoughcut from './enoughcut.vue'
import enoughsent from './enoughsent.vue'
import changeSalesman from './client/changeSalesman.vue'//更换业务员
import addressCheck from './client/addressCheck.vue'//收货地址审核
import addressEdit from './client/addressEdit.vue'//收货地址编辑
import addressAdd from './client/addressAdd.vue'//添加收货地址
//import addNewClient from './client/addNewClient.vue'//新增客户
import invoice from './invoice.vue' //发票弹窗
import writeinvoice from './writeinvoice.vue' //发票弹窗
import syInput from './sy-input.vue'
import Ueditor from './ueditor.vue'//复文本
import check from './check.vue' //地图
import imgview from './imgview.vue' //图片预览

//超团组件
import infoGroup from './groupComponents/infoGroup.vue'//基本信息

import addPayAccount from './addPayAccount.vue';//支付模块,添加银行卡
import addbank from './addbank.vue';//支付模块,添加银行卡
import unbindbank from './unbindbank.vue';//支付模块,解绑银行卡

import clientChannel from "./addClientResources/clientChannel.vue";//新增客户渠道
import clientLevel from "./addClientResources/clientLevel.vue";//新增客户等级
import addBrand from './clientBrand/addBrand.vue';//品牌维护
import brandTable from './clientBrand/brandTable.vue';//品牌维护

import ysDaterangePicker from './ys-daterange-picker.vue';//时间区间组件

import selectGift from './selectGift.vue';//超团选择赠品组件
import selectGoods from './selectGoods.vue';//超团选择赠品组件

//酒店久集组件
import invoiceHotel from './invoiceHotel.vue' //发票弹窗
import addinvoiceHotel from './addinvoiceHotel.vue' //发票弹窗
import writeinvoiceHotel from './writeinvoiceHotel.vue' //发票弹窗
import addressPicker from './addressPicker.vue' //四级地址选择

//账期
import addressTable from './addressTable.vue' //收款地址

//多图片上传
import multipleImgUpload from './multipleImgUpload.vue'
// 支付弹窗
import payPopup from './payPopup.vue'
//客户选择
import Client from './client.vue'
// 省市区街道
import selectArea from './area/selectArea'
import cityCascaderBox from "./area/cityCascader.vue";

export default {
	init() {
		Vue.component('storeTop', storeTop)
		Vue.component('logoTop', logoTop)
		Vue.component('top', top)
		Vue.component('foot', foot)
		Vue.component('tableA', tableA)
		Vue.component('tableB', tableB)
		Vue.component('tableC', tableC)
		Vue.component('tableD', tableD)
		Vue.component('selectGift', selectGift)
		Vue.component('selectGoods', selectGoods)
		Vue.component('chooseGoods', chooseGoods)
		Vue.component('chooseClient', chooseClient)
		Vue.component('chooseGift', chooseGift)
		Vue.component('giftmanage', giftmanage)
		Vue.component('commInfo', commInfo)//基本信息
		Vue.component('commDetails', commDetails)//商品详情
		Vue.component('aptitude', aptitude)//销售资质
		/*Vue.component('excommInfo', excommInfo)//基本信息（审核）
		Vue.component('excommDetails', excommDetails)//商品详情（审核*/
		Vue.component('integral', integral)// 积分设置
		
		Vue.component('enoughcut', enoughcut)
		Vue.component('enoughsent', enoughsent)
		Vue.component('changeSalesman', changeSalesman)
		Vue.component('addressCheck', addressCheck)
		Vue.component('addressEdit', addressEdit)
		Vue.component('addressAdd', addressAdd)
		Vue.component('invoice', invoice)
		Vue.component('writeinvoice', writeinvoice)
		Vue.component('sy-input', syInput)
		Vue.component('ueditor', Ueditor)
		//Vue.component('addNewClient', addNewClient)
		Vue.component('check', check)
		Vue.component('imgview', imgview)

		Vue.component('addPayAccount', addPayAccount)
		Vue.component('addbank', addbank)
		Vue.component('unbindbank', unbindbank)

		Vue.component('clientChannel', clientChannel)
		Vue.component('clientLevel', clientLevel)
		//超团组件
		Vue.component('infoGroup', infoGroup)
		Vue.component('addBrand', addBrand)
		Vue.component('brandTable', brandTable)
		Vue.component('ysDaterangePicker', ysDaterangePicker)
		//酒店久集组件
		Vue.component('invoice-hotel', invoiceHotel)
		Vue.component('addinvoice-hotel', addinvoiceHotel)
		Vue.component('writeinvoice-hotel', writeinvoiceHotel)
		Vue.component('address-picker', addressPicker)

		//账期 客户管理
		Vue.component('address-table', addressTable)

		Vue.component('multipleImgUpload', multipleImgUpload)
		Vue.component('payPopup',payPopup)

		Vue.component('Client', Client)
		Vue.component('selectArea', selectArea)
		Vue.component('cityCascaderBox', cityCascaderBox)
		
	}
}

/**
 * created by ykx 2019.7.4
 * @params   读取文件的路径 {String} directory
 * @params   useSubdirectories {Boolean} -是否遍历文件的子目录
 * @params   regExp {RegExp} -匹配文件的正则
 * require.context(’/’, false, /.vue/);
 * 第一个参数是文件路径，
 * 第二个参数表示是否搜索该文件下的子目录，
 * 第三个参数搜索文件正则表达式 返回[]数组。
 *
 * 返回一个函数，函数中有三个属性：
 * resolve {Function} -接受一个参数request,request为test文件夹下面匹配文件的相对路径,返回这个匹配文件相对于整个工程的相对路径
 * keys {Function} -返回匹配成功模块的名字组成的数组
 * id {String} -id属性返回了匹配的文件夹的相对于工程的相对路径,是否遍历子目录,匹配正则组成的字符串
 * */

// let files = require.context('/',true,/.vue$/).keys();
// // console.log(files,'files');
// files.map(v => {
//   let file = v.split('').reverse().join('').split('/')[0].split('').reverse().join('');
//   let fileName = file.split('.')[0];
//   if(fileName !== "ueditor"){
//       Vue.component(fileName,resolve => require([`${v}`],resolve))
//   }
// });
