<template>
    <div class="activity" v-loading="loading">
        <div class="form-wrap">
            <el-form :model="ruleForm" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                <el-form-item label="活动名称:" prop="title">
                    {{ruleForm.title}}
                </el-form-item>
                <el-form-item label="参与次数:" >
                    {{ruleForm.limitTimes}}
                </el-form-item>
                <el-form-item label="团购名称:" >
                    {{ruleForm.grouponName}}
                </el-form-item>
                <el-form-item label="活动类型:">
                    <el-radio-group v-model="ruleForm.activityType">
                        <el-radio v-if="ruleForm.activityType==1" label="1">满额减</el-radio>
                        <el-radio v-if="ruleForm.activityType==2" label="2">满件减</el-radio>
                        <el-radio v-if="ruleForm.activityType==3" label="3">满额赠</el-radio>
                        <el-radio v-if="ruleForm.activityType==4" label="4">满件赠</el-radio>
                    </el-radio-group>
                    <span v-if="((ruleForm.activityType==2 || ruleForm.activityType==4) && ruleForm.unitType==1)">
                        (商品按箱)
                    </span>
                    <span v-if="((ruleForm.activityType==2 || ruleForm.activityType==4) && ruleForm.unitType==2)">
                        (商品按瓶)
                    </span>
                </el-form-item>
                <el-form-item label="优惠设置:" prop="promotionType">
                    <el-radio-group v-model="ruleForm.promotionType">
                        <el-radio v-if="ruleForm.promotionType==1" label="1">阶梯满{{ruleForm.activityType==3 || ruleForm.activityType == 4 ? '赠' : '减'}}</el-radio>
                        <el-radio v-if="ruleForm.promotionType==2" label="2">循环满{{ruleForm.activityType==3 || ruleForm.activityType == 4 ? '赠' : '减'}}</el-radio>
                    </el-radio-group>
                    <!--优惠详情设置-->
                    <div class="discountDetail">
                        <p class="tips" v-if="ruleForm.promotionType==1">提示：每级优惠不叠加，如：满足二级优惠条件后则不再享有一级优惠。最多支持三级优惠。</p>
                        <!--满额减-->
                        <div v-if="ruleForm.activityType==1"  class="fullLimit">
                            <div class="stair">
                                <div class="stairList clearfix" v-for="(item,index) in ruleForm.cutRuleParamList" :key="index+'ae'">
                                    消费{{ruleForm.promotionType==1 ? '' : '每'}}满{{item.fullMoney}}元减{{item.minusAmount}}元
                                </div>
                            </div>
                        </div>
                        <!--满件减-->
                        <div v-if="ruleForm.activityType==2"  class="fullLimit">
                            <div class="stair">
                                <div class="stairList clearfix" v-for="(item,index) in ruleForm.cutRuleParamList" :key="index+'af'">
                                    消费{{ruleForm.promotionType==1 ? '' : '每'}}满{{item.fullAmount}}{{ruleForm.unitType=="1"?'箱':'瓶'}}减{{item.minusAmount}}元
                                </div>
                            </div>
                        </div>
                        <!--满额赠-->
                        <div v-if='ruleForm.activityType=="3"'  class="fullLimit">
                            <div class="fullSend-wrap" v-for="(item,i) in ruleForm.giftRuleParamList" :key="i+'ad'">
                                消费{{ruleForm.promotionType==1 ? '' : '每'}}满{{item.fullMoney}}元,送赠品
                                <div class="table-gift">
                                    <el-table :data="item.giftRelationVOList" :key="item.giftRelationVOList"  class='mt-10 table-default' stripe>
                                        <el-table-column label="赠品名称">
                                            <template slot-scope="scope">
                                                <div class="goods-wrap">
                                                    <div class="ml-10 l-googs" style="position: relative;">
                                                        <img v-if='scope.row.giftPic' :src="scope.row.giftPic" />
                                                        <img v-else src="../../assets/goods.jpg" />
                                                    </div>
                                                    <div class="ml-10 r-goods">
                                                        <div class="tal w-160 lh-20" :title="scope.row.skuName">{{scope.row.skuName}}</div>
                                                        <div class="tal w-160 lh-20" v-if="scope.row.specInfoNum">规格1*{{scope.row.specInfoNum}}</div>
                                                        <div class="tal w-160 lh-20" >
                                                            <span style="color:#FF4201;">￥{{scope.row.giftPrice | fmoney}}</span>
                                                            <span style="color:#999999;text-decoration-line: line-through;margin-left: 10px;" v-if="scope.row.msrp">￥{{scope.row.msrp | fmoney}}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </template>
                                        </el-table-column>
                                        <el-table-column label="赠品单位" prop="giftUnit"></el-table-column>
                                        <el-table-column label="库存" prop="giftStock">
                                            <template slot-scope="scope">
                                                {{scope.row.giftStock || 0}}
                                            </template>
                                        </el-table-column>
                                        <el-table-column label="赠品数量" prop="quantity">
                                        </el-table-column>
                                    </el-table>
                                </div>
                            </div>
                        </div>
                        <!--满件赠-->
                        <div v-if="ruleForm.activityType==4"  class="fullLimit">
                            <div class="fullSend-wrap" v-for="(item,i) in ruleForm.giftRuleParamList">
                                消费{{ruleForm.promotionType==1 ? '' : '每'}}满{{item.fullAmount}}{{ruleForm.unitType==1?'箱':'瓶'}},送赠品
                                <div class="table-gift">
                                    <el-table :data="item.giftRelationVOList" :key="item.giftRelationVOList"  class='mt-10 table-default' stripe>
                                        <el-table-column label="赠品名称">
                                            <template slot-scope="scope">
                                                <div class="goods-wrap">
                                                    <div class="ml-10 l-googs" style="position: relative;">
                                                        <img v-if='scope.row.giftPic' :src="scope.row.giftPic" />
                                                        <img v-else src="../../assets/goods.jpg" />
                                                    </div>
                                                    <div class="ml-10 r-goods">
                                                        <div class="tal w-160 lh-20 cursor" :title="scope.row.skuName">{{scope.row.skuName}}</div>
                                                        <div class="tal w-160 lh-20" v-if="scope.row.specInfoNum">规格1*{{scope.row.specInfoNum}}</div>
                                                        <div class="tal w-160 lh-20">
                                                            <span style="color:#FF4201;">￥{{scope.row.giftPrice | fmoney}}</span>
                                                            <span style="color:#999999;text-decoration-line: line-through;margin-left: 10px;" v-if="scope.row.msrp">￥{{scope.row.msrp | fmoney}}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </template>
                                        </el-table-column>
                                        <el-table-column label="赠品单位" prop="giftUnit"></el-table-column>
                                        <el-table-column label="库存" prop="giftStock">
                                            <template slot-scope="scope">
                                                {{scope.row.giftStock || 0}}
                                            </template>
                                        </el-table-column>
                                        <el-table-column label="赠品数量" prop="quantity"></el-table-column>
                                    </el-table>
                                </div>
                            </div>
                        </div>
                    </div>
                </el-form-item>
                <el-form-item label="活动商品:" prop="chooseType">
                    <el-radio-group v-model="ruleForm.chooseType">
                        <el-radio v-if="ruleForm.chooseType==1" label="1">所有商品</el-radio>
                        <el-radio v-if="ruleForm.chooseType==2" label="2">部分商品</el-radio>
                    </el-radio-group>
                    <div class="goodsTab">
                        <div class="tips">提示：单个商品仅允许同时参与一个满减/送活动</div>
                        <el-form :inline="true" :model="skuObj" label-width="80px" class="clearfix" :style="{width:'850px'}">
                            <div class="fl">
                                <el-form-item label="商品分类:">
                                    <el-select v-model='skuObj.classifyName' placeholder='请选择' clearable >
                                        <el-option v-for='item in classifylist' :key='item.id'
                                                   :label='item.classifyName'
                                                   :value='item.classifyName'></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="商品名称:">
                                    <el-input  v-model.trim="skuObj.skuName"></el-input>
                                </el-form-item>
                            </div>
                            <el-button type="primary" class="fl ml-50" @click="queryGoods">查询</el-button>
                        </el-form>
                        <el-table :data="tableDate2" :key="tableDate2"  class='mt-10 table-default' stripe>
                            <el-table-column label="商品名称" width="500" >
                                <template slot-scope="scope">
                                    <div class="goods-wrap">
                                        <div class="ml-10 l-googs" style="position: relative;">
                                            <img v-if='scope.row.fileUrl' :src="scope.row.fileUrl" />
                                            <img v-else src="../../assets/goods.jpg" />
                                        </div>
                                        <div class="ml-10 r-goods">
                                            <div class="tal w-160 lh-20"><p :title="scope.row.skuName">{{scope.row.skuName}}</p></div>
                                            <div class="tal w-160 lh-20" v-if="scope.row.specInfoNum">规格1*{{scope.row.specInfoNum}}</div>
                                            <div class="tal w-160 lh-20" >
                                                <span style="color:#FF4201;">￥{{scope.row.grouponPrice | fmoney}}</span>
                                                <span style="color:#999999;text-decoration-line: line-through;margin-left: 10px;">￥{{scope.row.msrp  | fmoney}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column label="商品分类" prop="classifyName"></el-table-column>
                            <el-table-column label="库存" prop="stockEnableQuantity">
                                <template slot-scope="scope">
                                    {{scope.row.stockEnableQuantity || 0}}
                                </template>
                            </el-table-column>
                            <el-table-column label="销量单位" prop="units"></el-table-column>
                            <el-table-column label="操作" >
                                <template slot-scope="scope">-</template>
                            </el-table-column>
                        </el-table>
                    </div>
                </el-form-item>
            </el-form>
            <el-pagination
                    class="tar mr-50 fr mt-0"
                    :current-page="currentPage"
                    :page-size='10'
                    :total='isSelected ? total2 : total'
                    layout="prev , pager, next, jumper"
                    @current-change="handlePageChange"
            >
            </el-pagination>
        </div>
    </div>
</template>

<script>
    import { mapActions,mapState,mapGetters ,mapMutations} from 'vuex'
    import {axiosApi} from "~/api/api";
    export default {
        name: "activityDetail",
        data(){
            return {
                loading:true,
                skuObj:{
                    skuName:'',
                    classifyName:''
                },
                tableDate2:[],
                currentPage:1,
                ruleForm:{
                    unitType:'',
                    title:'',
                    detail:'',
                    limitTimes:'',
                    activityType:'',
                    chooseType:'',
                    promotionType:'',
                    goodsList:[],
                    cutRuleParamList:[],
                    giftRuleParamList:[],
                    grouponName:'',

                },
                goodsList:[],
                isSelected:false,//搜索判断
            }
        },
        watch:{
            /*currentPage() {
                this.tableData = this.goodsList.slice((this.currentPage-1)*10, (this.currentPage-1)*10+10);
            },*/
            tableDate(val){
                this.tableDate2 = val;
            }
        },
        computed:{
            classifylist() {
                return this.$store.state.classifylist.classifylistData ?
                    this.$store.state.classifylist.classifylistData : []
            },
            total(){
                return this.goodsList.length
            },
            total2(){
                return this.tableDate2.length
            },
            tableDate(){
                return this.goodsList.slice((this.currentPage-1)*10, (this.currentPage-1)*10+10);
            }
        },
        methods:{
            ...mapActions({
                'queryclassifylistData':'queryclassifylistData',
            }),
            //获取详情
            getDetail(){
                axiosApi('/groupon/activity/select',{id:this.id},res => {
                    this.loading = false;
                    console.log(res,'res');
                    this.ruleForm.title = res.data.data.title;
                    this.ruleForm.detail = res.data.data.detail;
                    this.ruleForm.grouponName = res.data.data.grouponName;
                    this.ruleForm.limitTimes = res.data.data.limitTimes>0 ? res.data.data.limitTimes : "不限次数";
                    this.ruleForm.activityType = res.data.data.activityType.toString();
                    this.ruleForm.promotionType = res.data.data.promotionType.toString();
                    this.ruleForm.goodsIdList = res.data.data.goodsIdList;
                    this.ruleForm.chooseType = res.data.data.chooseType.toString();
                    this.ruleForm.cutRuleParamList = res.data.data.cutRuleVOList;
                    this.ruleForm.unitType = res.data.data.unitType;
                    res.data.data.giftRuleVOList.map(v => {
                        let list = v.giftRelationVOList;
                        let arr = [];
                        list.map(item => {
                            console.log(item,'itme')
                            arr.push({
                                quantity:item.quantity,
                                giftPic:item.grouponActivityGiftVO.giftPic,
                                giftUnit:item.grouponActivityGiftVO.giftUnit,
                                giftStock:item.grouponActivityGiftVO.giftStock,
                                skuName:item.grouponActivityGiftVO.skuName,
                                specInfoNum:item.grouponActivityGiftVO.specInfoNum,
                                giftPrice:item.grouponActivityGiftVO.giftPrice,
                                msrp:item.grouponActivityGiftVO.msrp,
                            })
                        })
                        this.ruleForm.giftRuleParamList.push(
                            {
                                fullMoney:v.fullMoney,
                                fullAmount:v.fullAmount,
                                giftRelationVOList:arr,
                            }
                        )
                    });
                    res.data.data.goodsRelationVOList.map(v => {
                        this.goodsList.push(v.simpleSkuInfo) ;
                    })

                },1,10,err => {
                    this.$message.warning(err.data.message)
                })
            },
            //搜索
            queryGoods(){
                if(this.skuObj.classifyName || this.skuObj.skuName){
                    this.isSelected = true;
                    let arr = [];//接收过滤后的数据
                    this.goodsList.map(v => {
                        if (this.skuObj.classifyName && this.skuObj.skuName) {
                            if (v.classifyName == this.skuObj.classifyName && v.skuName.indexOf(this.skuObj.skuName) != -1) {
                                arr.push(v)
                            }
                        } else if (this.skuObj.classifyName && !this.skuObj.skuName) {
                            if(v.classifyName == this.skuObj.classifyName){
                                arr.push(v)
                            }
                        } else if (!this.skuObj.classifyName && this.skuObj.skuName) {
                            if(v.skuName.indexOf(this.skuObj.skuName) != -1){
                                arr.push(v)
                            }
                        }
                    });
                    console.log(arr);
                    this.tableDate2 =[...arr];
                }else {
                    this.isSelected = false;
                    this.tableDate2 = this.tableDate;
                }
            },
            //当前页改变
            handlePageChange(val) {
                this.currentPage = val;
            }
        },
        mounted(){
            this.id = this.$route.query.id;
            this.$nextTick(() => {
                this.getDetail();
                this.queryclassifylistData();
            })
        }

    }
</script>

<style lang="less" >
    .activity{
        padding-top: 20px;
        .form-wrap{
            width: 1100px;
            margin: 20px auto;
            overflow: hidden;
            .activity-name{
                input{
                    width: 350px!important;
                }
            }
            .group-name .el-input{
                width: 350px!important;
            }
            .tips{
                color:#999999;
                margin: 20px 0 0;
                font-size: 14px;
            }
            .fullLimit{
                margin-top: 20px;
            }
            .stairList{
                margin-bottom: 19px;
                div{

                }
            }
            .goodsTab{
                .searchGoods{
                    display: flex;
                }
            }
            .addOpt{
                .save{
                    background-color: #FF4201;
                    border-color: #FF4201;
                    span{
                        color: #fff;
                    }
                }
            }
        }
        .goods-wrap{
            overflow: hidden;
            display: flex;
            .r-goods{
                flex: 1;
                overflow: hidden;
                div{
                    font-size: 12px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
        }
    }
</style>