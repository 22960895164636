<template>
    <div class="aside-nav">
        <div class="menu-title" v-if="asideNav.id == '999999' || asideNav.menuClassify ==1 ||asideNav.menuClassify ==2 || $route.path.indexOf('/messageCenter')!=-1 || $route.path.indexOf('/messageDetail')!=-1">
            <span v-if="$route.path.indexOf('/messageCenter')!=-1 || $route.path.indexOf('/messageDetail')!=-1">消息中心</span>
            <span v-else><i class="iconfont mr-10" :class="asideNav.icon"></i>{{asideNav.id == '999999' ? '业务应用' : asideNav.text}}</span>
        </div>
        <el-popover
                placement="left-end"
                width="550"
                offset="100"
                trigger="click"
                v-model="visible"
                @show="getAllNav"
        >
            <div class="customize-list">
                <div class="customize-list-title">
                    <div>
                        <strong class="color-333">自定义功能</strong>
                        <span class="color-6f font-12 ml-10">通过点击选择可添加菜单为管理常用操作项，最多添加10个</span>
                    </div>

                    <el-button type="text" class="sure-add-btn" @click="addCustomizeNav">确定</el-button>
                </div>
                <ul class="customize-list-box">
                    <li>
                        <dl v-for="(nav,i) in allNavList" :key="nav.id" v-if="i<9">
                            <dt> {{nav.text}} <i class="iconfont icon-right font-12 ml-5 "></i></dt>
                            <dd>
                                <span :class="{'selected': item.customize,'disabled': customizeNum >=10 && !item.customize}" v-for="(item,index) in nav.sub" :key="index" @click="handleState(item,index,i)">{{item.text}}</span>
                            </dd>
                        </dl>
                    </li>
                </ul>
            </div>
            <div class="customize" slot="reference" v-show="asideNav.id=='000000' && !($route.path.indexOf('/messageCenter')!=-1 || $route.path.indexOf('/messageDetail')!=-1)">
                <span>自定义功能</span> 
                <i class="iconfont icon-plus-sign font-12 ml-20"></i>
            </div>
        </el-popover>
        <div class="customize-menu" v-if="asideNav.id=='000000' && !($route.path.indexOf('/messageCenter')!=-1 || $route.path.indexOf('/messageDetail')!=-1)">
            <!--<el-menu-item>11</el-menu-item>-->
            <el-menu
                    :collapse-transition="false"
                    :default-active="$route.path"
                    router
                    class="el-menu-vertical-demo"
                    text-color="#fff"
                    active-text-color="#fff"
                    @select="selectCustomizeAsideNav"
            >
                <el-menu-item v-for="(item,index) in customizeNav" :class="{'is-actives': item.url == $route.path}"  :key="index" :index="item.url">{{item.text}}</el-menu-item>
            </el-menu>
        </div>
        <el-menu
                :collapse-transition="false"
                :default-active="$route.path"
                class="el-menu-vertical-demo"
                text-color="#fff"
                active-text-color="#fff"
                @select="selectAsideNav"
                v-if="asideNav.id!=='000000' && asideNav.id != '999999' && !($route.path.indexOf('/messageCenter')!=-1 || $route.path.indexOf('/messageDetail')!=-1)"
        >
            <template v-for="(item,index) in asideNav.sub">
                <el-submenu  v-if="item.sub && item.sub.length > 0 "  :key="index" :index='item.text'>
                    <template slot="title" >{{item.text}}</template>
                    <el-menu-item-group>
                        <el-menu-item :class="{'is-actives': data.url == $route.path}" v-for="(data,i) in item.sub" :key="i" :index="data.url">{{data.text}}</el-menu-item>
                    </el-menu-item-group>
                </el-submenu>
                <el-menu-item :class="{'is-actives': item.url == $route.path}" v-else  :key="index" :index="item.url" >{{item.text}}</el-menu-item>
            </template>

        </el-menu>
        <el-popover
                placement="right"
                width="550"
                offset="100"
                v-model="visible2"
                @show="getAllNav"
                trigger="hover">
            <div class="usually-list"  >
                <div class="usually-list-title">
                    <strong class="color-333">常用功能</strong>
                </div>
                <ul class="usually-list-box" v-if="usuallyNav.length>0">
                    <li>
                        <span v-for="(item,i) in usuallyNav" :key="i">
                            <span @click="gotoUrl(item)">{{item.text}}</span>
                        </span>
                    </li>
                </ul>
                <div v-else class="p20 font-14" style="text-align: center">暂无常用功能</div>
            </div>
            <div class="usually" slot="reference">
                <span >常用功能</span>
                <i class="iconfont icon-right font-12 ml-20 "></i>
            </div>
        </el-popover>
    </div>
</template>

<script>
    import { mapState ,mapMutations } from 'vuex'
    import { axiosApi,pushOrGotoPageByWholeUrl,checkGotoSameHost } from '../api/api'
    export default {
        name: "asideNav",
        data(){
          return{
              visible: false,
              visible2: false,
              customizeNum: 0,
              allNavList:[]
          }
        },
        computed:{
            ...mapState('nav',['asideNav','tagNav','nowNav','topNav','usuallyNav','customizeNav','allNav','allNav2','applicationMenuTreeList'])
        },
        watch:{
            allNav:{
                handler(val){
                    let arr = this.$deepClone(val);
                    arr.forEach(v=>{
                        v.sub.forEach(data=>{
                            this.customizeNav.forEach(i=>{
                                if (data.id == i.id){
                                    data.customize = true
                                }
                            })
                        })
                    })
                    this.allNavList = arr
                },
                deep:true,
                immediate: true
            },
            customizeNav:{
                handler(val){
                    let arr = this.$deepClone(this.allNav);
                    arr.forEach(v=>{
                        v.sub.forEach(data=>{
                            val.forEach(i=>{
                                if (data.id == i.id){
                                    data.customize = true
                                }
                            })
                        })
                    })
                    this.allNavList = arr
                },
                deep:true,
                immediate: true
            },
            topNav:{
                handler(val){
                    val.forEach(v=>{
                        if (v.url == this.$route.path) {
                            this.changeAsideNav(v)
                            if (v.id!='000000' && v.id!='999999') {
                                let arr = this.$deepClone(this.tagNav)
                                arr.push(v)
                                this.changeTagNav(arr)
                            }
                            this.changNowNav(v)
                            return false
                        }
                        if(v.sub && v.id!='999999'){
                            if (v.sub.length == 0){
                                return false
                            }
                            v.sub.forEach(data=>{
                                if (data.url == this.$route.path){
                                    this.changeAsideNav(v)
                                    let arr = this.$deepClone(this.tagNav)
                                    arr.push(data)
                                    this.changeTagNav(arr)
                                    this.changNowNav(data)
                                    return false
                                }
                                if (data.sub.length == 0){
                                    return false

                                }
                                data.sub.forEach(k=>{
                                    if (k.url == this.$route.path){
                                        this.changeAsideNav(v)
                                        let arr = this.$deepClone(this.tagNav)
                                        arr.push(k)
                                        this.changeTagNav(arr)
                                        this.changNowNav(k)
                                        return false
                                    }
                                })
                            })
                        }else if (v.sub && v.sub.length>0) {
                            v.sub.forEach(data=>{
                                if (data.url == this.$route.path){
                                    this.changeAsideNav(v)
                                    let arr = this.$deepClone(this.tagNav)
                                    arr.push(data)
                                    this.changeTagNav(arr)
                                    this.changNowNav(data)
                                    return false
                                }
                                if (data.sub.length == 0){
                                    return false

                                }
                                data.sub.forEach(k=>{
                                    if (k.url == this.$route.path){
                                        this.changeAsideNav(data)
                                        let arr = this.$deepClone(this.tagNav)
                                        arr.push(k)
                                        this.changeTagNav(arr)
                                        this.changNowNav(k)
                                        return false
                                    }
                                })
                            })
                        }
                        
                        
                    })
                    this.$forceUpdate()
                },
                deep:true,
                immediate: true
            },
            allNavList:{
                handler(val){
                    let num = 0
                    val.forEach(v=>{
                        v.sub.forEach(data=>{
                            if (data.customize) {
                                num+=1
                            }
                        })
                    })
                    this.customizeNum = num
                },
                deep:true,
                immediate: true
            },
            $route: {
                handler: function(val){
                    let data = this.findNavForRoute(this.topNav,val.path)
                    let data1 = this.findNavForRoute(this.topNav,'/customer/accountCenter')
                    let data2 = this.findNavForRoute(this.applicationMenuTreeList[0] ? this.applicationMenuTreeList[0].applicationMenuTree : [],val.path)
                    let data3 = this.findNavForRoute(this.applicationMenuTreeList[1] ? this.applicationMenuTreeList[1].applicationMenuTree : [],val.path)
                    if (this.nowNav && this.nowNav.customizeNav) {
                        this.changeAsideNav(data1)
                        return false
                    }
                    if (data &&  (!data2 && !data3)){
                        this.changeAsideNav(data)
                    } else if (data2) {
                        this.changeAsideNav(data2)
                    }else if (data3) {
                        this.changeAsideNav(data3)
                    }
                    this.$forceUpdate()
                },
                deep: true
            }
        },
        methods:{
            ...mapMutations('nav',['changeTagNav','changNowNav','changeResourceList','changeCustomizeNav','addUserNav','changeAsideNav']),
            selectAsideNav(val){
                let data = this.findNav(this.asideNav.sub,val)
                if(checkGotoSameHost(val, data.wholeUrl)){
                    this.addUserNav(data.id)
                    let arr = this.$deepClone(this.tagNav)
                    arr.push(data)
                    this.changeTagNav(arr)
                    this.changNowNav(data)
                }
                pushOrGotoPageByWholeUrl(val, data.wholeUrl);
            },
            selectCustomizeAsideNav(val){
                let data = this.findNav(this.customizeNav,val)
                data.customizeNav = true
                if (data&&data.url){
                    if(checkGotoSameHost(val, data.wholeUrl)){
                        this.addUserNav(data.id)
                        let arr = this.$deepClone(this.tagNav)
                        arr.push(data)
                        this.changeTagNav(arr)
                        this.changNowNav(data)
                    }
                    pushOrGotoPageByWholeUrl(val, data.wholeUrl);
                }
            },
            findNav(arr,url){
                let obj = null
                arr.forEach(v=>{
                    if (v.sub&&v.sub.length>0){
                        v.sub.forEach(k=>{
                            if (k.url == url) {
                                obj = k
                            }
                        })
                    } else{
                        if (v.url == url) {
                            obj = v
                        }
                    }
                })
                return obj
            },
            findNavForRoute(arr,url){
                let obj = null
                if (arr.length == 0){
                    return obj
                }

                arr.forEach(v=>{
                    if (v.url == url) {
                        obj = v
                        return false
                    }
                    if (v.sub&&v.sub.length>0){
                        v.sub.forEach(k=>{
                            if (k.sub&&k.sub.length>0){
                                k.sub.forEach(i=>{
                                    if (i.url == url) {
                                        obj = v
                                    }
                                })
                            } else{
                                if (k.url == url) {
                                    obj = v
                                }
                            }
                        })
                    }
                })
                return obj
            },
            gotoUrl(val){
                if(checkGotoSameHost(val.url, val.wholeUrl)){
                    let arr = this.$deepClone(this.tagNav)
                    arr.push(val)
                    this.changeTagNav(arr)
                    this.changNowNav(val)
                    this.visible2 = false
                }
                pushOrGotoPageByWholeUrl(val.url, val.wholeUrl);
            },
            handleState(val,i,j){
                if (this.customizeNum>=10){
                    if (!val.customize) {
                        return false
                    }
                }
                val.customize = !val.customize
                let arr = this.allNavList[j]
                this.$set(this.allNavList, j, arr);

            },
            addCustomizeNav(){
                let arr = []
                this.allNavList.forEach(v=>{
                    v.sub.forEach(data=>{
                        if (data.customize){
                            arr.push(data.id)
                        }
                    })
                })
                const body = {
                    resourceIdList: arr
                }
                const api = '/foundation/employeeSetupResource/update';
                axiosApi(api,body,(res)=>{
                    this.visible = false
                    this.getAllNav()
                    this.cancelAddCustomizeNav()
                },1,10,err=>{
                    this.$message.error(err.data.message)
                })
            },
            cancelAddCustomizeNav(){
                let arr = this.$deepClone(this.allNav);
                arr.forEach(v=>{
                    v.sub.forEach(data=>{
                        this.customizeNav.forEach(i=>{
                            if (data.id == i.id){
                                data.customize = true
                            }
                        })
                    })
                })
                this.allNavList = arr
                this.$forceUpdate()
            },
            getAllNav(){
                const body = {
                    loginName: sessionStorage.loginName,
                    appId: 2,
                    hostPrefix: this.PROJECT.hostPrefix
                }
                const api = '/foundation/employeeResourceConfig/check';
                axiosApi(api,body,(res)=>{
                    if (res.data.data) {
                        let recentResourceList = res.data.data.recentResourceList;//常用菜单
                        let setupResourceList = res.data.data.setupResourceList;//自定义菜单
                        this.changeResourceList(recentResourceList)
                        this.changeCustomizeNav(setupResourceList)
                    }
                })
            }
        },
        mounted(){
            this.getAllNav()
        }
    }
</script>

<style lang="less">
    .customize-list{
        padding: 6px 8px;
        .customize-list-title{
            border-bottom: 1px solid #EFEFEF;
            padding-bottom: 15px;
            height: 25px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .sure-add-btn{
                color: #FB6E48;
            }
        }
        .customize-list-box{
            max-height: 500px;
            overflow: auto;
            padding: 10px 0;
            li{
                line-height: 40px;
                dl{
                    display: flex;
                    font-size: 12px;
                    margin-bottom: 15px;
                    dt{
                        width: 73px;
                        height: 25px;
                        text-align: center;
                        line-height: 25px;
                        border-radius: 12.5px;
                        margin-right: 15px;
                        background-color: #2B2C4B;
                        color: #ffffff;
                    }
                    dd{
                        padding: 1px 0;
                        flex: 1;
                        display: flex;
                        flex-wrap: wrap;
                        span{
                            flex-shrink: 0;
                            cursor: pointer;
                            margin: 0 10px 10px 0;
                            padding: 0 7px;
                            height: 23px;
                            border-radius: 11.5px;
                            text-align: center;
                            line-height: 23px;
                            color: #6F6F6F;
                            &:hover{
                                background-color: #ffe3d9;
                                color: #ffffff;
                            }
                            &.disabled{
                                cursor: no-drop;
                                background-color: #c8c9cc;
                                color: #ffffff;
                            }
                            &.selected{
                                background-color: #FB6E48;
                                color: #ffffff;
                            }
                        }
                    }
                }
            }
        }
    }
    .usually-list{
        padding: 6px 8px;
        .usually-list-title{
            border-bottom: 1px solid #EFEFEF;
            padding-bottom: 15px;
            height: 25px;
            display: flex;
            align-items: center;
        }
        .usually-list-box{
            max-height: 700px;
            overflow: auto;
            padding: 10px 0;
            li{
                line-height: 40px;
                display: flex;
                flex-wrap: wrap;
                span{
                    flex-shrink: 0;
                    margin: 0 10px 10px 0;
                    padding: 0 7px;
                    height: 23px;
                    border-radius: 11.5px;
                    text-align: center;
                    line-height: 23px;
                    color: #6F6F6F;
                    &:hover{
                        background-color: #99675E;
                        color: #ffffff;
                    }
                }

            }
        }
    }
    .aside-nav{
        height: calc(100vh - 106px);
        overflow: auto;
        -ms-overflow-style: none;
        overflow: -moz-scrollbars-none;
        &::-webkit-scrollbar {
            width: 0 !important
        }
        .menu-title{
            height: 46px;
            line-height: 46px;
            padding: 0 30px;
            color: #ffffff;
        }
        .customize{
            cursor: pointer;
            overflow: hidden;
            height: 46px;
            line-height: 46px;
            padding: 0 30px;
            color: #ffffff;
            i{
                display: none;
            }
            &:hover{
                background:linear-gradient(270deg,rgba(251,126,73,1) 0%,rgba(250,93,71,1) 100%);
                i{
                    display: unset;
                }
            }
        }
        .usually{
            width: 100%;
            position: absolute;
            bottom: 0;
            cursor: pointer;
            overflow: hidden;
            height: 46px;
            line-height: 46px;
            text-align: center;
            color: #ffffff;
            background:linear-gradient(270deg,rgba(251,126,73,1) 0%,rgba(250,93,71,1) 100%);
        }
        .el-menu{
            background-color: #2B2C4B;
            border-right: none;
            .el-submenu{
                background-color: #2B2C4B;
                .el-submenu__title{
                    i{
                        color: #ffffff;
                    }
                }
                .el-menu-item-group__title{
                    padding: 0;
                }
                .el-submenu__title{
                    .el-menu-item;
                }
            }
            .el-menu-item{
                padding: 0 30px !important;
                background-color: #2B2C4B;
                height: 46px;
                line-height: 46px;
                &.is-actives{
                    background:linear-gradient(270deg,rgba(251,126,73,1) 0%,rgba(250,93,71,1) 100%);
                }
                &:hover{
                    color: rgba(251,126,73,1);
                }
            }
        }
        .customize-menu{

        }

    }
</style>