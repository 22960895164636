<template>
	<div id="client-level">
		<el-dialog :title="title" :visible.sync="levelDialog" @close="goPage">

			<el-form :model="clientLevel" ref="clientLevel" :rules="rules" label-width="150px">
				<el-form-item label="客户等级:" prop="levelName">
					<el-input v-model.trim="clientLevel.levelName" placeholder="输入客户等级"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" @click="addChannel" :loading="btnLoading">确 定</el-button>
				<el-button @click="goPage">取 消</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import { mapActions, mapMutations } from 'vuex'
	export default {
		props: ['value','title'],
		data() {
			return {
				clientLevel:{
					traderId: sessionStorage.getItem('traderId'), 
					levelName:'',
					id: null
				},
				btnLoading:false,
				levelDialog: false,
				rules: {
					levelName: [{
						required: true,
						message: '请输入客户等级'
					},{
						message: '字符长度最大为5',
						max: 5
					}]
				}
			}
		},
		computed: {
			clientLevelData(){
				// this.clientLevel = { ...this.$store.state.channelLevel.levelData, traderId: sessionStorage.getItem('traderId') }
				return this.$store.state.channelLevel.levelData
			},
			// channelLevelDataTitle(){
			// 	return this.$store.state.channelLevel.channelLevelDataTitle
			// }
		},
		watch: {
			value(val){
				this.levelDialog = val;
				if(this.$refs.clientLevel) this.$refs.clientLevel.resetFields();
			},
			clientLevelData:{
				handler:function(val){
					this.clientLevel = Object.assign({}, {
						levelName: val.levelName,
						id: val.id ? val.id : null,
		                traderId: sessionStorage.getItem('traderId')
					})
				},
				deep:true
			}
		},
		methods: {
			...mapMutations({
				'resetChannelLevelData': 'resetChannelLevelData'
			}),
			...mapActions({
				'addChannelLevelData': 'addChannelLevelData'	
			}),
			addChannel() {
				this.$refs.clientLevel.validate(valid => {
					if (valid) {
						this.addChannelLevelData({
							type: 1,//0为客户渠道，1为客户等级
							body: this.clientLevel
						})
						this.$emit('closedialog')
					}
				})
			},
			//取消
			goPage() {
				this.$emit('closedialog')
				this.resetChannelLevelData()
			}
		}
	}
</script>

<style lang="less">
	#client-level{

	}
</style>
