<template>
    <div class="ys-daterange-box" style="display: flex;align-items: center;">
        <el-date-picker
            :class="type === 'datetime' ? 'ys_picker ys_picker_datetime' : 'ys_picker'"
            v-model="startTime"
            :picker-options="pickerOptions1"
            :type="type === 'datetime' ? type : 'date'"
            :editable="required"
            @change="startChange"
            :clearable="clearable&&required"
            :disabled="disabled||startDisabled"
            :placeholder="placeholder[0] || ''">
        </el-date-picker>
        <div class="my-line">
            <span class="m-partition">&nbsp;-&nbsp; </span>
        </div>
        <el-date-picker
            :class="type === 'datetime' ? 'ys_picker ys_picker_datetime' : 'ys_picker'"
            v-model="endTime"
            :clearable="clearable"
            :picker-options="pickerOptions2"
            :type="type === 'datetime' ? type : 'date'"
            @change="endChange"
            :disabled="disabled||endDisabled"
            :placeholder="placeholder[1] || ''">
        </el-date-picker>
    </div>
</template>

<script>
    export default {
        props : {
            value: {//时间区间数组 v-model
                type: Array,
                default() {
                    return [];
                }
            },
            placeholder : { //时间选择框占位符数组 placeholder
                type : Array,
                default() {
                    return ['开始时间','结束时间'];
                }
            },
            clearable:{
                type:Boolean,
                default:true
            },
            startDisabledDate: [Date, String, Number], //开始时间限制选择的时间 格式:Date类型或日期字符串或时间戳格式
            endDisabledDate: [Date, String, Number], //结束时间限制选择的时间 格式:Date类型或日期字符串或时间戳格式
            type: String, //类型 可选值 datetime , date
            missdistance:[ String, Number],//开始时间和结束时间之间的天数
            disabled: Boolean, //是否禁用
            startDisabled:{
                type:Boolean,
                default:false
            },
            endDisabled:{
                type:Boolean,
                default:false
            },
            endType:{ // 结束时间是否禁用 为当天之前。
                type:Boolean,
                default:false
            },
            required: {
                type:Boolean,
                default:true
            },
        },
        // watch: {
        //     value(val){
        //         this.startTime = val[0] ? new Date(val[0]) : null;
        //         this.endTime = val[1] ? new Date(val[1]) : null;
        //     }
        // },
        computed: {
            startTime: {
                get: function(val) {
                    return this.value[0] ? new Date(this.value[0]) : null
                },
                set: function(val) {
                    //this.value[0] = val
                    if(val) {
                    	this.$set(this.value,0,val)
                    }else{
                    	this.$set(this.value,0,null)
                    }
                }
            },
            endTime: {
                get: function() {
                    return this.value[1] ? new Date(this.value[1]) : null
                },
                set: function(val) {
                    //this.value[1] = val
                   if(val) {
	                   	if(this.value.length > 1) {
	                   		this.$set(this.value,1,val)
	                   	}else {
	                   		this.$set(this.value,0,null)
	                   	}
                   }else{
                   		this.$set(this.value,1,null)
                   }
                }
            }
        },
        data() {
            let _this = this;
            function pickerOptions(time, startDisabledDate, endDisabledDate){
                if(startDisabledDate && endDisabledDate){//限制选择开始日期和结束日期
                    return time < startDisabledDate || time > endDisabledDate;
                }else if(startDisabledDate){//限制选择开始日期
                    if(_this.endType){
                      return time < startDisabledDate - 8.64e7;
                    }else{
                      return time < startDisabledDate;
                    }
                }else if(endDisabledDate){//限制选择结束日期
                    return time > endDisabledDate;
                }
                return false;//不限制
            }
            return {
                // startTime : null,
                // endTime : null,
                
                pickerOptions1 : {
                    disabledDate(time) {
                        let startDisabledDate = + new Date(_this.startDisabledDate) || null,
                        endDisabledDate = + new Date(_this.endDisabledDate) || null,
						missdistance = _this.missdistance || null; //限制天数
                        
                        if(_this.endDisabledDate && _this.endTime) {
                            endDisabledDate = _this.endDisabledDate > + new Date(_this.endTime) ? + new Date(_this.endTime) : _this.endDisabledDate;
                        }else if(_this.endTime){
                            endDisabledDate = + new Date(_this.endTime);
                        }
                        if(_this.endTime && missdistance){
                        	var t =new Date(_this.endTime);
							var iToDay=t.getDate();
							var iToMon=t.getMonth();
							var iToYear=t.getFullYear();
							var newDay = new Date(iToYear,iToMon,(parseFloat(iToDay)-parseFloat(missdistance) + 1));
							if(Number(newDay) > startDisabledDate){
								startDisabledDate = + new Date(newDay)
							}
                        }
                        
                        if(_this.type == 'datetime') startDisabledDate = startDisabledDate - 8.64e7;
                       
                        return pickerOptions(time.getTime(), startDisabledDate, endDisabledDate)
                    }
                },
                pickerOptions2 : {
                    disabledDate(time) {
                        let startDisabledDate = + new Date(_this.startDisabledDate) || null,
                        endDisabledDate = + new Date(_this.endDisabledDate) || null,
                        missdistance = _this.missdistance || null; //限制天数
                        if(_this.startDisabledDate && _this.startTime) {
                            startDisabledDate = _this.startDisabledDate < + new Date(_this.startTime) ? + new Date(_this.startTime) : _this.startDisabledDate;
                        }else if(_this.startTime){
                            startDisabledDate = + new Date(_this.startTime);
                        }
                        if(_this.startTime && missdistance){
                        	var t =new Date(_this.startTime);
							var iToDay=t.getDate();
							var iToMon=t.getMonth();
							var iToYear=t.getFullYear();
							var newDay = new Date(iToYear,iToMon,(parseFloat(iToDay) + parseFloat(missdistance) - 1));
							if(!endDisabledDate){
								endDisabledDate = + new Date(newDay)
							}else if(endDisabledDate && Number(newDay) < endDisabledDate){
								endDisabledDate = + new Date(newDay)
							}
                        }
                        if(_this.type == 'datetime') startDisabledDate = startDisabledDate - 8.64e7;
                        return pickerOptions(time.getTime(), startDisabledDate, endDisabledDate)
                    }
                }
            }
        },
        mounted(){
        },
        methods : {
            startChange(){
                const start = + new Date(this.startTime),
                end = + new Date(this.endTime);
                if(start && end && start > end){
                    this.startTime = this.endTime;
                }
                this.$emit('input', [this.startTime, this.endTime]);
                this.$emit('change', [this.startTime, this.endTime]);
            },
            endChange(val){
                if(this.type !== 'datetime' && !!this.endTime) {
                    const time = new Date(this.endTime).setHours(23, 59, 59, 999);
                    this.endTime = + new Date(time);
                }
                const start = + new Date(this.startTime),
                end = + new Date(this.endTime);
                if(start && end && start > end){
                    this.endTime = this.startTime;
                }
                this.$emit('input', [this.startTime, this.endTime]);
                this.$emit('change', [this.startTime, this.endTime]);
            },
            changes(val){
            	
            }
        }
    }
</script>

<style lang="less">
    .ys-daterange-box{
        display: flex;
        .el-input--prefix{
            .el-input__inner{
                font-size: 12px;
                padding-left: 10px;
                padding-right: 26px;
            }
        }

        .ys_picker{
            width:113px;
            .el-input__icon+.el-input__inner{
                padding-right: 26px!important;
            }
            .el-input__suffix{
                right: 18px;
            }
        }
        .ys_picker_datetime{
            width: 175px;
        }
        .el-input__prefix{
            right: 0px;
            left: auto;
        }
        .my-line {
            height: 32px;
            line-height: 32px;
        }
    }
</style>