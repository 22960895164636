import { render, staticRenderFns } from "./myDiscount.vue?vue&type=template&id=525b0181&"
import script from "./myDiscount.vue?vue&type=script&lang=js&"
export * from "./myDiscount.vue?vue&type=script&lang=js&"
import style0 from "./myDiscount.vue?vue&type=style&index=0&id=525b0181&prod&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports