<template>
    <div id="auditMargin">
        <div class="title-top bg-f">
            <tabsCheck @handleClick="handleTabsClick" :list="tabsList"></tabsCheck>
        </div>
        <div class="marginAudit-content bg-f mt-5">
            <div class="pt-30 flex">
                <div class="flex lh-32">
                    <span>提交时间：</span>
                    <el-date-picker
                      v-model="startTime"
                      :style="{width: '130px'}"
                      type="date"
                      placeholder="请选择"
                    >
                    </el-date-picker>
                    <span>&nbsp;至&nbsp;</span>
                    <el-date-picker
                      v-model="endTime"
                      :style="{width: '130px'}"
                      type="date"
                      placeholder="请选择"
                    >
                    </el-date-picker>
                </div>
                <div class="flex lh-32">
                    <span class="ml-30">久集账号：</span>
                    <el-input placeholder="请输入久集账号" v-model.trim="name" />
                </div>
                <el-button @click="handleSearch" class="el-button--info ml-30">搜索</el-button>
            </div>
            <div class="c-content">
                <!-- 支付凭证 dialog -->
                <imgview ref="imgview" :visible="dialogVisible" :imgs="paymentVoucherUrl" :startindex="0" @close="dialogVisible=false"></imgview>
                <order-viewredentials
                :imgList="paymentVoucherUrl"
                :type="'-1'"
                ref="viewredentials"></order-viewredentials>
                <div class="c-tables">
                    <el-table style="width: 100%" :data="tableList" border tooltip-effect="dark" stripe class="mt-20 table-default">
                        <el-table-column :resizable="false" width="110" prop="loginName" label="账号"></el-table-column>
                        <el-table-column :resizable="false" prop="name" label="姓名"></el-table-column>
                        <el-table-column :resizable="false" width="150" prop="idCard" label="身份证"></el-table-column>
                        <el-table-column :resizable="false" width="110" prop="bondMoney" label="已缴纳保证金"></el-table-column>
                        <el-table-column :resizable="false" prop="rechargeBondMoney" label="充值额度"></el-table-column>
                        <el-table-column :resizable="false" prop="rechargeImg" label="支付凭证">
                            <template slot-scope="scope">
                                <el-button @click="handlePaymentVoucher(scope.row)" type="text">查看</el-button>
                            </template>
                        </el-table-column>
                        <el-table-column :resizable="false" width="130" prop="gmtCreate" label="提交时间">
                            <template slot-scope="scope">
                                {{scope.row.gmtCreate | time}}
                            </template>
                        </el-table-column>

                        <template v-if="tabs === '1'">
                            <el-table-column :resizable="false" width="130" prop="gmtModified" label="操作时间">
                                <template slot-scope="scope">
                                    {{scope.row.gmtModified | time}}
                                </template>
                            </el-table-column>
                            <el-table-column :resizable="false" prop="operatorName" label="操作人"></el-table-column>
                            <el-table-column :resizable="false" prop="state" label="状态">
                                <template slot-scope="scope">
                                    <span v-if="scope.row.state === 0">待审核</span>
                                    <span v-if="scope.row.state === 1">已通过</span>
                                    <span v-else-if="scope.row.state === 2">已驳回</span>
                                </template>
                            </el-table-column>
                        </template>

                        <el-table-column :resizable="false" :fixed="tabs === '1' ? 'right' : false" width="150" prop="storeName" label="操作">
                            <template slot-scope="scope">
                                <div class="caozuo-div">
                                    <template v-if="tabs === '0'">
                                        <el-button @click="handleAdopt(scope.row)" type="text">通过</el-button>
                                        <el-button @click="handleReject(scope.row)" type="text">驳回</el-button>
                                    </template>
                                    <template v-else>
                                        <template v-if="scope.row.state === 2">
                                            <el-button @click="handleSeeReasons(scope.row)" type="text">查看驳回理由</el-button>
                                        </template>
                                        <template v-else>
                                            <span>--</span>
                                        </template>
                                    </template>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                    <!-- 分页 -->
                    <div class="pageBox">
                        <el-pagination class="tar" v-if='total>0'
                            :current-page="currentPage"
                            :page-size='10'
                            :total='total'
                            layout="prev, pager, next, jumper"
                            @current-change="handlePageChange">
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>
        <!-- 驳回浮层 -->
        <el-dialog @close="handleClose" title="提示" :visible.sync="dialogTableVisible">
            <div class="marginAudit-dialog-box">
                <el-form :model="ruleForm" ref="ruleForm">
                    <div class="dialog-title">请输入驳回理由：</div>
                    <el-form-item 
                    :rules="[
                        { required: true, message: '驳回理由不能为空', trigger: 'change' },
                        { min: 2, message: '请最少输入2个文字', trigger : 'change' },
                    ]"
                    label=" " 
                    prop="reason">
                        <el-input :autosize="{ minRows: 3, maxRows: 3}" :maxlength="100" type="textarea" v-model.trim="ruleForm.reason"></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="handleClose">取 消</el-button>
                <el-button type="primary" @click="handleOk">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import { axiosApi, basePath } from "../../../api/api";
    import tabsCheck from '../../../components/tabsCheck.vue';
    import orderViewCredentials from './marginComponent.vue';
    export default {
        components : {tabsCheck,'order-viewredentials' : orderViewCredentials},
        data() {
            return {
                startTime : '', // 开始时间
                endTime : '', // 结束时间
                name : '', // 久集账号
                startTimeDefault : '', // 点击搜索后 在赋值
                endTimeDefault : '',
                nameDefault : '',
                tabsList : [
                    {text : '待审核',active : true,value : '0'},
                    {text : '已审核',value : '1'}
                ],
                tabs : '0', // 当前tab
                total : 0, // 总数量
                currentPage : 1, // 当前页数
                tableList : [ // table date
                ],
                dialogVisible : false, // 支付凭证 dialog stats
                paymentVoucherUrl : [], // 支付凭证 图片url arr
                dialogTableVisible : false,
                ruleForm : {
                    reason : '',
                },
            }
        },
        mounted() {
            this.initTableList();
        },
        methods : {
            // 表格 init
            initTableList() {
                axiosApi('/superc/bond/recharge/select',{
                    loginName : this.nameDefault,
                    startTime : this.startTimeDefault ? + new Date(this.startTimeDefault) : '',
                    endTime : this.endTimeDefault ? + new Date(this.endTimeDefault) : '',
                    state : this.tabs,
                },(res) => {
                    if(res.data.data){
                        let data = res.data.data;
                        this.tableList = data.elements; 
                        this.total = data.totalCount;
                    }else{

                    }
                },this.currentPage,10);
            },
            // 点击搜索
            handleSearch() {
                if(+ new Date(this.startTime) > + new Date(this.endTime)){
                    this.$message.warning('开始时间不能大于结束时间');
                    return;
                }
                this.currentPage = 1;
                this.startTimeDefault = this.startTime;
                this.endTimeDefault = this.endTime;
                this.nameDefault = this.name;
                this.initTableList();
            },
            // tab点击
            handleTabsClick(tabs) {
                this.startTime = '';
                this.endTime = '';
                this.name = '';
                this.tabs = tabs.value;
                this.tabsList = this.tabsList.map((v,k) => {
                    v.active = false;
                    if(v.value === tabs.value){
                        v.active = true;
                    }
                    return v;
                });
                this.currentPage = 1;
                this.initTableList();
            },
            // 点击 查看支付凭证
            handlePaymentVoucher(row) {
                if(!row.rechargeImg){
                    this.$message.warning('暂无支付凭证，无法查看');
                }
                this.paymentVoucherUrl = row.rechargeImg.split(',');
                // 使用 imgview组件
                //this.dialogVisible = true;
                //this.$refs.imgview.setActiveItem(0);
                this.$refs.viewredentials.show();
            },
            // 点击通过
            handleAdopt(row) {
                this.$confirm('确认该笔金额已到账并通过审核?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    axiosApi('/superc/bond/recharge/approve',{
                        id : row.id,
                    },(res) => {
                        let data = res.data.data;
                        this.$message.success('操作成功');
                        this.currentPage = 1;
                        this.initTableList();
                    });
                }).catch(() => {        
                });
            },
            // 点击驳回
            handleReject(row) {
                this.dialogTableVisible = true;
                this.id = row.id;
            },
            // 查看驳回 理由
            handleSeeReasons(row) {
                let div = 
                <div>
                    <p>驳回理由：</p>
                    <p style={
                        {
                            marginTop : '5px',
                            wordWrap: 'break-word',
                            wordBreak: 'normal',
                            paddingBottom: '60px',
                        }
                    }>
                        {row.reason}
                    </p>
                </div>;
                this.$msgbox({
                title: '查看',
                message: div,
                showCancelButton: false,
                confirmButtonText: '确定',
                beforeClose: (action, instance, done) => {
                    done();
                }
                }).then(action => {
                });
            },
            // 分页change
            handlePageChange(val) {
                this.currentPage = val;
                this.initTableList();
            },
            // 浮层 取消
            handleClose() {
                this.dialogTableVisible = false;
                this.$refs.ruleForm.resetFields();
            },
            // 浮层 确定
            handleOk() {
                this.$refs.ruleForm.validate((valid) => {
                    if (valid) {
                        axiosApi('/superc/bond/recharge/refuse',{
                            id : this.id,
                            reason : this.ruleForm.reason,
                        },(res) => {
                            let data = res.data.data;
                            this.$message.success('操作成功');
                            this.currentPage = 1;
                            this.initTableList();
                            this.handleClose();
                        });
                    }
                });
            },
        },
    }
</script>

<style lang="less" scoped>
    #auditMargin{
        .marginAudit-content{
            padding: 0px 20px 20px 20px;
            .c-tables{
                overflow-x: auto;
            }
        }
    }
</style>

<style lang="less">
    #auditMargin{
        .el-tabs__header{
            padding-left: 0;
            margin-top: 30px;
            border-bottom: 1px solid #d1dbe5!important;
        }
        .caozuo-div .el-button{
            width: initial;
        }
        /* 重写浮层样式 */
        .el-dialog__body{
            padding: 30px 20px;
            min-width: auto;
        }
        .el-dialog{
            min-height: 300px;
        }
        .marginAudit-dialog-box{
            .dialog-title{
                margin-bottom: 10px;
            }
            .el-form-item__label{
                display: none;
            }
            .el-form-item__content{

            }
            textarea{
                resize :none;
            }
        }
    }
</style>