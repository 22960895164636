import Vue from 'vue';
import ElementUI from 'element-ui';
import {PROJECT} from '~/config/index.js';

import {Message} from 'element-ui';
import {funParabola} from './parabola-min.js';
import {basePath} from '~/api/api.js'

Vue.prototype.PROJECT = PROJECT;//抛出PROJECT对象全局使用
Vue.prototype.basePath = basePath;//抛出basePath对象全局使用

// Vue.prototype.$http = axios;

export const bus = new Vue();
let buttonList
bus.$on('buttonList', (data) => {
    buttonList = data;
})
Vue.prototype.fmoney = function (s = 0, n = 2) { //s:传入的float数字 ，n:希望返回小数点几位
    let m = s || 0
//	m = parseFloat((m + "").replace(/[^\d\.-]/g, "")).toFixed(n) + "";
    m = parseFloat((m + "").replace(/[^\d\.-]/g, ""))
    m = (Math.round(m * 100) / 100).toFixed(n) + ""
    var l = m.split(".")[0].split("").reverse(),
        r = m.split(".")[1];
    let t = "";
    for (let i = 0; i < l.length; i++) {
        t += l[i] + ((i + 1) % 3 == 0 && (i + 1) != l.length ? "," : "");
    }
    return t.split("").reverse().join("") + "." + r;
}

/* 按钮权限控制 */
Vue.prototype.showButton = (code, parentId) => {
    if (!buttonList) buttonList = JSON.parse(sessionStorage.getItem("buttonList"))
    let bool;
    if (!!buttonList)
        for (var i = 0; i < buttonList.length; i++) {
            if (buttonList[i].code == code && buttonList[i].parentId == parentId) {
                bool = true;
                break
            } else {
                bool = false;
            }
        }
    // 管理员全开权限-疑似前端调试有需要再打开
    // if (sessionStorage.getItem("isAdmin") == 1) bool = true;
    return bool;
}
/* cascader空数据处理 */
Vue.prototype.cascaderClear = (arr) => {
    if (!arr || !arr.length) return
    arr.map(item => {
        if (item.sub && item.sub.length > 0) {
            Vue.prototype.cascaderClear(item.sub)
        } else {
            item.sub = null
        }
    })
    return arr
}
// cascader空数据处理 subDistrictList
Vue.prototype.cascaderClear2 = (arr) => {
    if (!arr || !arr.length) return
    arr.map(item => {
        item.id = String(item.id)
        if (item.subDistrictList && item.subDistrictList.length > 0) {
            Vue.prototype.cascaderClear2(item.subDistrictList)
        } else {
            item.subDistrictList = null
        }
    })
    return arr
}
/* 判断上传图片是否符合规则 */
Vue.prototype.isUploadImg = (file) => {
    const isImage = (file.type.indexOf('png') != -1 || file.type.indexOf('jpeg') != -1 || file.type.indexOf('jpg') != -1 || file.type.indexOf('bmp') != -1);
    const imgSize = file.size / 1024 / 1024;
    if (!isImage) {
        Message.error('请上传jpg,jpeg,png或bmp格式图片!');
    } else {
        if (imgSize > 4) {
            Message.error('上传图片大小不能超过 4MB!');
        } else if (imgSize <= 0) {
            Message.error('上传图片大小不能等于 0MB!');
        }
    }
    return (isImage && (imgSize > 0 && imgSize <= 4))
}

/* 重置表单数据：参数:arr 数组， */
Vue.prototype.resetData = (strNull, str, arr, obj) => {
    if (strNull) strNull.data.forEach((item) => {
        strNull.name[item] = null;
    })
    if (str) str.data.forEach((item) => {
        str.name[item] = "";
    })
    if (arr) arr.data.forEach((item) => {
        arr.name[item] = [];
    })
    if (obj) obj.data.forEach((item) => {
        obj.name[item] = {};
    })
}


let checkTime = i => {
    i = i >= 10 ? i : '0' + i
    return i
}
/*时间戳转化时间字符串*/
Vue.prototype.timeString = (time) => {
    let hour = parseInt(time / 60 / 60)
    let minute = parseInt(time / 60 % 60)
    let second = parseInt(time % 60)
    hour = checkTime(hour)
    minute = checkTime(minute)
    second = checkTime(second)
    time = hour + ':' + minute + ':' + second
    return time;
}


// 数组去重 思路：获取没重复的最右一值放入新数组 
Array.prototype.unique = function () {
    var r = [],
        arr = this;
    for (var i = 0, l = arr.length; i < l; i++) {
        for (var j = i + 1; j < l; j++)
            if (arr[i] === arr[j]) j = ++i;
        r.push(arr[i]);
    }
    return r;
}

/* 加入购物车抛物线
funParabola执行返回的对象包含如下四个方法：
mark 在目标元素以及移动元素上通过data-center自定义属性标记当前的中心坐标，如-234, -345. 此方法主要用在demo中，方便测试与预览用的。实际可能用途不大。
position 重新获取元素的位置。在元素相对位置改变的时候，此方法很有用。否则会出现计算误差的情况。例如，页面布局是自适应或者响应式的，浏览器宽度变小了，两元素之间的距离变化了，此时需要执行下position，存储新的坐标位置。
move 触发抛物线运动。
init 初始化方法。实际上就是连续调用position, mark, move3个方法。

参数说明：
element表示移动的元素，原生DOM节点
target表示目标元素。原生DOM节点
options为可选参数。各个API名称以及含义如下：
	speed 表示每帧移动的像素大小，每帧（对于大部分显示屏）大约16~17毫秒。默认大小是166.67。也就是默认10px/ms.
	curvature 可以近似理解为抛物线的开头大小，也就是曲率。正数表示开口向下。默认大小是0.001. 数值越大，开头越小，弧度越高。因为web页面动辄大小几百像素，因此，曲率值较小。
	progress 表示抛物线运动过程中的回调，支持两个参数，x, y，表示当前的坐标，您可以根据这些坐标值做一些特殊的处理。
	complete 表示抛物线运动结束后的回调。
 */
Vue.prototype.parabola = funParabola;

/* input输入延迟 */
let timeout;
Vue.prototype.debounce = function (fun, time = 300) {
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(_ => {
        fun();
    }, time)
}


// 省市区value转label
Vue.prototype.getREgionLabel = function (region) {
    if ("000000" == region)
        return "全国";

    let province = region;
    
    let areaList = JSON.parse(localStorage.getItem('areaList'))

    for (let p = 0; p < areaList.length; ++p) {

        let pp = JSON.parse(JSON.stringify(areaList[p]));
        if (pp.id == region.substring(0, 2)) {
           
            if (region.substring(2, 4) == "")
                return pp.name;

            let city = region.substring(0, 4) ;
            for (let c = 0; c < pp.childs.length; ++c) {
                let cc = pp.childs[c];
                if (cc.id == city) {
                    if (region.substring(4, 6) == "")
                        return cc.name;
                    if(cc.childs !=undefined){
                        for (let r = 0; r < cc.childs.length; ++r) {
                            let rr = cc.childs[r];
                            if (rr.id == region) {
                                return rr.name;
                            }
                        }
                    }
                    
                }
            }
        }
    }

    return null;
}


Vue.prototype.resolveValueToText = function (data) {
    if (data instanceof Array == false) {
        data.fareDeliveryModeList.map(item2 => {
            let regionArr = item2.region.split(',')
            if (regionArr.length === 1 && item2.region === '000000') {
                item2.regionText = '所有地区'
            } else {
                let map4 = {};
                for (let i = 0; i < regionArr.length; i++) {
                    let region = regionArr[i];
                    if (region.substring(2, 6) != '') {
                        // debugger
                        let key1 = region.substring(0, 2);
                        let key2 = region.substring(0, 4)
                        map4[key1] = map4[key1] ? map4[key1] : []
                        map4[key1][key2] = map4[key1][key2] ? map4[key1][key2] : []
                        if (region.substring(4, 5) != '' || region.substring(5, 6) != '') { //区
                            map4[key1][key2].push(region)
                        }
                    } else if (region.substring(2, 3) == '' && region.substring(3, 4) == '') { //省
                        item2.regionText = item2.regionText ? item2.regionText + '、' + this.getREgionLabel(region) + '[全省]' : this.getREgionLabel(region) + '[全省]'
                    }

                }

                for (let i1 in map4) {
                    //省开始
                    item2.regionText = item2.regionText
                        ?
                        item2.regionText + '、' + this.getREgionLabel(String(i1)) + '['
                        :
                        this.getREgionLabel(String(i1)) + '['

                    // 市
                    let map5 = map4[i1]
                    for (let i2 in map5) {
                        if (typeof map5[i2] == 'object') { // typeof map5[i2] = object, remove, unique
                            item2.regionText =
                                item2.regionText.substring(item2.regionText.length - 1, item2.regionText.length) == ')'
                                    ?
                                    item2.regionText + '、' + this.getREgionLabel(String(i2)) + '(' + (map5[i2].length > 0 ? map5[i2].length : '全市') + ')'
                                    :
                                    item2.regionText + this.getREgionLabel(String(i2)) + '(' + (map5[i2].length > 0 ? map5[i2].length : '全市') + ')'
                        }
                    }
                    //省结尾
                    item2.regionText = item2.regionText + ']'
                }

            }
        })
    } else {
        data.map(item1 => {
            item1.fareDeliveryModeList.map(item2 => {
                
                let regionArr = item2.region.split(',')
                if (regionArr.length === 1 && item2.region === '000000') {
                    item2.regionText = '所有地区'
                } else {
                    let map4 = {};
                    for (let i = 0; i < regionArr.length; i++) {
                        let region = regionArr[i];
                        if (region.substring(2, 6) != '') {
                            // debugger
                            let key1 = region.substring(0, 2);
                            let key2 = region.substring(0, 4)


                            map4[key1] = map4[key1] ? map4[key1] : []
                            map4[key1][key2] = map4[key1][key2] ? map4[key1][key2] : []


                            if (region.substring(4, 5) != '' || region.substring(5, 6) != '') { //区
                                map4[key1][key2].push(region)
                            }
                        } else if (region.substring(2, 3) == '' && region.substring(3, 4) == '') { //省
                            item2.regionText = item2.regionText ? item2.regionText + '、' + this.getREgionLabel(region) + '[全省]' : this.getREgionLabel(region) + '[全省]'
                        }

                    }
                    for (let i1 in map4) {
                        //省开始
                        item2.regionText = item2.regionText
                            ?
                            item2.regionText + '、' + this.getREgionLabel(String(i1)) + '['
                            :
                            this.getREgionLabel(String(i1)) + '['

                        // 市
                        let map5 = map4[i1]
                        for (let i2 in map5) {
                            if (typeof map5[i2] == 'object') { // typeof map5[i2] = object, remove, unique
                                item2.regionText = item2.regionText + this.getREgionLabel(String(i2)) + '(' + (map5[i2].length > 0 ? map5[i2].length : '全市') + ')'
                            }
                        }
                        //省结尾
                        item2.regionText = item2.regionText + ']'
                    }

                }
            })
        })
    }

    return data;
}

Vue.prototype.resolveValueToText_4level = function (regionArr) {
    let regionText = "";
    if (regionArr && regionArr.length == 0) {
        return regionText;
    }
    if (regionArr.length === 1 && regionArr[0] == '0') {
        regionText = '全国';
    } else {

        let map4 = {};
        for (let i = 0; i < regionArr.length; i++) {
            let region = regionArr[i].toString();
            if (region.substring(2, 6) != '0000') {
                let key1 = region.substring(0, 2);
                let key2 = region.substring(0, 4)
                let key3 = region.substring(0, 6)
                map4[key1] = map4[key1] ? map4[key1] : []
                map4[key1][key2] = map4[key1][key2] ? map4[key1][key2] : []
                map4[key1][key2][key3] = map4[key1][key2][key3] ? map4[key1][key2][key3] : []
                if (region && region.length > 6) { //区
                    map4[key1][key2][key3].push(region)
                }
            } else if (region.substring(2, 3) == '0' && region.substring(3, 4) == '0') { //省
                regionText = regionText ? regionText + '，' + this.getREgionLabel(region) + '(全省)' : this.getREgionLabel(region) + '(全省)'
            }

        }
        for (let i1 in map4) {
            //省开始
            regionText = regionText ? regionText + '，' + this.getREgionLabel(String(i1)) + '/' : this.getREgionLabel(String(i1)) + '/'
            // 市
            let map5 = map4[i1]
            for (let i2 in map5) {
                if (typeof map5[i2] == 'object') { // typeof map5[i2] = object, remove, unique
                    regionText = regionText + this.getREgionLabel(String(i2)) + '/'
                    let map6 = map5[i2];
                    for (let i3 in map6) {
                        if (typeof map6[i3] == 'object' && i3.substring(4, 6) == "00") {
                            regionText = regionText.substring(0, regionText.length - 1) + '(全市) 、'
                        } else if (typeof map6[i3] == 'object') {
                            regionText = regionText + this.getREgionLabel(String(i3)) + '(' + ((map6[i3] && map6[i3].length > 0) ? map6[i3].length : '全区') + ') 、'
                        }
                    }
                    // regionText = regionText + '、'
                }
            }
            //省结尾
            // regionText = regionText + '}'
            regionText = regionText.substring(0, regionText.length - 1)
        }
    }
    return regionText;
}

Vue.prototype.getBrowserInfo = function(){
    var userAgent = navigator.userAgent; //取得浏览器的userAgent字符串
    var isOpera = userAgent.indexOf("Opera") > -1; //判断是否Opera浏览器
    var isIE = userAgent.indexOf("compatible") > -1
        && userAgent.indexOf("MSIE") > -1 && !isOpera; //判断是否IE浏览器
    var isEdge = userAgent.indexOf("Trident") > -1; //判断是否IE的Edge浏览器
    var isFF = userAgent.indexOf("Firefox") > -1; //判断是否Firefox浏览器
    var isSafari = userAgent.indexOf("Safari") > -1
        && userAgent.indexOf("Chrome") == -1; //判断是否Safari浏览器
    var isChrome = userAgent.indexOf("Chrome") > -1
        && userAgent.indexOf("Safari") > -1; //判断Chrome浏览器
    if (isIE) {
        var reIE = new RegExp("MSIE (\\d+\\.\\d+);");
        reIE.test(userAgent);
        var fIEVersion = parseFloat(RegExp["$1"]);
        if (fIEVersion == 7) {
            return "IE7";
        } else if (fIEVersion == 8) {
            return "IE8";
        } else if (fIEVersion == 9) {
            return "IE9";
        } else if (fIEVersion == 10) {
            return "IE10";
        } else if (fIEVersion == 11) {
            return "IE11";
        } else {
            return "0";
        }//IE版本过低
        return "IE";
    }
    if (isOpera) {
        return "Opera";
    }
    if (isEdge) {
        return "Edge";
    }
    if (isFF) {
        return "FF";
    }
    if (isSafari) {
        return "Safari";
    }
    if (isChrome) {
        return "Chrome";
    }
};


export const checkName = (rule, value, callback) => {
    const reg = /^[a-zA-Z0-9\u4e00-\u9fa5]+$/;
    if(!reg.test(value)) {
        return callback(new Error('请输入正确的活动名称'))
    } else {
        callback()
    }
}
Vue.prototype.resolveWidth = function() {
	let arr = document.getElementsByClassName('width-80p')
	let wd = document.documentElement.clientWidth
	for(let i = 0;i<arr.length;i++) {
		if(wd < 1396) {
			arr[i].style.maxWidth = 669 + 'px'
		} else if(wd >= 1396 && wd < 1730) {
			arr[i].style.maxWidth = 1000 + 'px'
		} else if(wd >= 1730 && wd < 2061) {
			arr[i].style.maxWidth = 1333 + 'px'
		} else if(wd >= 2061) {
			arr[i].style.maxWidth = 1667 + 'px'
		}
	}
}

/**
 * 验证中英文（支持大小写）、数字
 * */
export const check = (rule,value,callback) => {
    let reg = /^[A-Za-z0-9\u4e00-\u9fa5]+$/;
    if(!reg.test(value)){
        return callback(new Error('请输入赠品名称'))
    }else {
        callback()
    }
}