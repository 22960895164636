<template>
    <div class="add_with_additional">
        <div class="mb-20 font-16" style="marginLeft: 25px;">
            加价换购促销
        </div>
        <el-form :model="form" ref='form' label-width="98px" :rules="rules">
            <el-form-item label="活动名称:" prop="activityName">
                <div class="inline-block">
                    <el-input v-model.trim="form.activityName" palceholder="输入活动名称" class=""></el-input>
                </div>
                <div class="inline-block ml-40">
                    <template>
                        <!-- <el-checkbox v-model="isOvering">可叠加</el-checkbox> -->
                        <el-checkbox v-model="isNewUser">是否首单</el-checkbox>
                    </template>
                </div>
            </el-form-item>
            <el-form-item label="活动时间:" prop="value1" required>
                <div class="">
                    <template>
                        <div>
                            <ys-daterange-picker v-model="form.value1" type="datetime"/>
                        </div>
                    </template>
                </div>
            </el-form-item>
        </el-form>
        <!-- end -->
        <!-- 活动规则 -->
        <el-form :model="form" label-width="98px" class="">
            <el-form-item label="活动规则:">
                <template>
                    <div>
                        <el-radio class="radio" v-model="secradio" label="1">满额加价购</el-radio>
                        <el-radio class="radio " v-model="secradio" label="2">满件加价购</el-radio>
                        <el-radio class="radio" v-model="secradio" label="3">每满额加价购</el-radio>
                        <el-radio class="radio mr-30" v-model="secradio" label="4">每满件加价购</el-radio>
                        <div class="mt-20 ml-10">
                            <!-- <div v-if="secradio==2||secradio==4" class="mb-20">
                                <span class='redalert ml-10'>*</span>
                                <span class="">商品类型:</span>
                                <el-radio class="radio" v-model="unitType" label="1">按箱商品</el-radio>
                                <el-radio class="radio mr-30" v-model="unitType" label="2">按瓶商品</el-radio>
                            </div> -->
                            <div class="mt-10 clearfix" v-for="(x,y) in activityRulesList" :key="y"
                                 style="overflow: 'hidden';width: 970px;">
                                <div class="fl clearfix">
                                    <div class="fl">
                                        <span class='redalert ml-10'>*</span>
                                        <span class="" v-if="secradio==1||secradio==2">满: </span>
                                        <span class="" v-if="secradio==3||secradio==4">每满: </span>
                                        <span>
                      <sy-input v-show="secradio == '1'" v-model="x.fullMoney" type="float" size="mini" :min="0.01"
                                :max="99999999.99"></sy-input>
                      <sy-input v-show="secradio == '2'" v-model="x.fullAmount" type="int" size="mini" :min="1"
                                :max="99999999"></sy-input>
                      <sy-input v-show="secradio == '3'" v-model="x.fullMoney" type="float" size="mini" :min="0.01"
                                :max="99999999.99"></sy-input>
                      <sy-input v-show="secradio == '4'" v-model="x.fullAmount" type="int" size="mini" :min="1"
                                :max="99999999"></sy-input>
                      <span v-if="secradio == '1'||secradio == '3'">元</span>
                      <span v-if="(secradio == '2'||secradio == '4')&&unitType == 1">箱</span>
                      <span v-if="(secradio == '2'||secradio == '4')&&unitType == 2">瓶</span>
                    </span>
                                        <span class="ml-20">最多换购种类</span>
                                        <span>
                      <sy-input v-model="x.fullbuyNum" :willBuy="true" type="int" size="mini" :min="x.minNum"
                                :max="x.maxNum"></sy-input>
                    </span>
                                    </div>
                                    <div class="mb-10 fl ml-20">
                                        <el-button type="text" @click="addgift(y,x.giftList.length)">添加换购商品</el-button>
                                        <el-button type="text" @click="deletemoneylist(y)"
                                                   v-if="secradio == '1'||secradio == '2'">删除
                                        </el-button>
                                    </div>
                                </div>
                                <div class="ml-20 fl">
                                    <div class="mb-10" v-for="(n,i) in x.giftList" :key="i">
                                        <span class='redalert ml-10'>*</span>
                                        <span>换购商品: </span>
                                        <el-input v-model.trim="n.skuName" class="skuName_input" palceholder=""
                                                  :disabled="true"></el-input>
                                        <span class="color-gray">{{n.isIntegerMultiple ? '(1*' + n.specInfoNum + ')' : ''}}</span>
                                        <span class='redalert ml-10'>*</span>
                                        <span>单价: </span>
                                        <sy-input v-model="n.price" type="float" size="mini" :min="0.01"
                                                  :max="99999999.99"></sy-input>
                                        <span>元/{{n.unitType==1?'箱':n.giftUnit}}</span>
                                        <span class='redalert ml-10'>*</span>
                                        <span>数量: </span>
                                        <sy-input v-model="n.quantity" type="int" size="mini" :min="1"
                                                  :max="99999999"></sy-input>
                                        <el-select class="unit_select" v-model="n.unitType" placeholder="请选择">
                                            <el-option
                                                    v-for="item in n.unitOptions"
                                                    :key="item.value"
                                                    :label="item.label"
                                                    :value="item.value">
                                            </el-option>
                                        </el-select>
                                        <span class="ml-20" v-if="secradio==3||secradio==4">上限</span>
                                        <sy-input v-if="secradio==3||secradio==4" v-model="n.limitQuantity" :sent="true"
                                                  type="int" size="mini" :min="n.quantity*1" :max="99999999"></sy-input>
                                        <span v-if="secradio==3||secradio==4">
                      <span v-if="n.giftType == 1">{{n.giftUnit}}</span>
                      <span v-else>
                        {{n.unitType==1?'箱':n.giftUnit}}
                      </span>
                    </span>
                                        <el-checkbox class="ml-10" v-model="n.isrequired">是否必购</el-checkbox>
                                        <i @click="delOnegift(y,i)"
                                           class="iconfont icon-err font-16 cursor ml-20 delete_icon"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-10 ml-10">
                                <el-button type="text" class="btn" @click="addmoneylist"
                                           v-if="secradio == '1'||secradio == '2'">继续添加
                                </el-button>
                            </div>
                        </div>
                    </div>
                </template>
            </el-form-item>
        </el-form>
        <!-- end -->
        <!-- 限定次数 -->
        <el-form :model="form" label-width="98px" class="mt-20">
            <el-form-item label="参与次数:" required>
                <div class="">
                    <template>
                        <div class="">
                            <el-radio class="radio" v-model="isLimitTimes" label="0">不限次数</el-radio>
                            <el-radio class="radio" v-model="isLimitTimes" label="1">限制次数</el-radio>
                        </div>
                        <div class="mt-20 ml-90" v-if="isLimitTimes == '1'">
                            <span class='redalert ml-10'>*</span>
                            <sy-input v-model="limitTimes" type="int" size="mini" :min="1" :max="99999999"></sy-input>
                            <span>次</span>
                        </div>
                    </template>
                </div>
            </el-form-item>
        </el-form>
        <!-- end -->
        <!-- 活动对象 -->
        <el-form :model="form" label-width="98px" class="mt-20">
            <el-form-item label="活动对象:" required>
                <div class="">
                    <template>
                        <el-radio class="radio" v-model="sevradio" label="1">所有客户</el-radio>
                        <el-radio class="radio" v-model="sevradio" label="2">部分客户</el-radio>
                    </template>
                    <div v-show="sevradio=='2'">
                        <div class='tar'>
                            <el-button type='text' @click="addclient">添加客户</el-button>
                            <el-button type='text' @click="addAllClients">添加全部客户</el-button>
                            <!-- <el-button type='text' @click="delclients">批量删除</el-button> -->
                        </div>
                        <tableA :size="[100,200,110,170]"></tableA>
                        <chooseClient
                                v-on:closedialog="dialog2Visible = false"
                                :cVisible="dialog2Visible"
                                :ifnotauto="true"
                                :is-full-gift='true'
                        >
                        </chooseClient>
                    </div>
                </div>
            </el-form-item>
            <!-- 选择商品-->
            <el-form-item label="活动商品:" required>
                <div class="">
                    <template>
                        <el-radio class="radio" v-model="thiradio" label="1">所有商品</el-radio>
                        <el-radio class="radio" v-model="thiradio" label="2">部分商品</el-radio>
                        <!-- <el-radio class="radio" v-model="thiradio" label="3">部分商品不参与</el-radio> -->
                    </template>
                    <div v-show="thiradio!='1'">
                        <div class='tar'>
                            <el-button type='text' @click="addGood">添加商品</el-button>
                            <el-button type='text' @click="addAllGoods">添加全部商品</el-button>
                        </div>
                        <tableB :size="[360,,205]" :noclassify="true"></tableB>
                        <chooseGoods
                                v-on:closedialog="dialog1Visible = false"
                                :goodVisible="dialog1Visible"
                                :ifnotauto="true"
                                :isMeal="true"
                                :is-full-gift='true'
                                :isIntegerMul="isIntegerMultiple"
                        >
                        </chooseGoods>
                    </div>
                </div>
            </el-form-item>
        </el-form>
        <!-- end -->
        <el-form label-width="98px">
            <div class="mt-10">
                <el-form-item>
                    <el-button type="danger" @click="onSubmit" :loading="activityId?updateloading:loading">保存
                    </el-button>
                    <el-button @click="back">取消</el-button>
                </el-form-item>
            </div>
        </el-form>
        <chooseGift
                v-on:closedialog="dialogVisible = false"
                :giftVisible="dialogVisible"
                :goodsNum="goodsNum"
                :skuNoList="skuNoList"
                :isadditional="true">
        </chooseGift>
    </div>
</template>
<script>
	import {mapMutations, mapActions} from 'vuex'
	import Confirm from '@/utils/formconfirm'
	import {activityname} from '@/utils/formverify'
	import {axiosApi, basePath} from '@/api/api'
	import YsDaterangePicker from "~/components/ys-daterange-picker";

	export default {
		components: {YsDaterangePicker},
		data() {
			var validatevalue1 = (rule, value, callback) => {
				if (!value[0] || !value[1]) {
					return callback(new Error('请选择活动时间范围'));
				} else if (value[0].getTime() == value[1].getTime()) {
					return callback(new Error('开始时间和结束时间应设置不同'));
				} else {
					callback()
				}
			}
			return {
				isinited: false,// 已经初始化
				form: {
					activityName: '',
					value1: [],
				},
				isOvering: false,
				isNewUser: false,
				secradio: '1',// 活动规则radio
				sevradio: '1',// 活动对象
				thiradio: '1',//1全部商品参与，2部分商品参与，3部分商品不参与
				dialog1Visible: false,// 添加商品弹框组件
				dialog2Visible: false,// 添加指定客户弹框组件
				limitTimes: "1",// 限定次数
				isLimitTimes: '0',// 是否限定次数
				loading: false,
				goodsNum: 5,//可选择赠品数量，默认为最大值5
				unitOptions: [{// 瓶箱下拉选择框
					label: '箱',
					value: 1
				}, {
					label: '瓶',
					value: 2
				}],
				dialogVisible: false,// 选择商品
				unitType: null,// 按箱商品，按瓶商品
				isIntegerMultiple: null,// 是否整箱购买
				rules: {
					activityName: [
						{validator: activityname, trigger: 'blur'}
					],
					value1: [
						{validator: validatevalue1, trigger: 'change'}
					],
				},
				skuNoList: [],// 已添加的赠品 id
			}
		},
		computed: {
			activityId() {// 加价购编辑 活动 id
				return this.$route.query.activityId || ''
			},
			updateloading() {// 更新加价购活动
				return this.$store.state.activity.updateloading
			},
			activityRulesListbackup() {
				let arr = this.$store.state.ticket.willaddgiftData.map(v => {
					return v.giftList
				});
				// this.addgood({willadd:arr,addAllgoods:true})
				return this.$store.state.ticket.willaddgiftData
			},
			activityRulesList() {// 加价换购数据
				this.activityRulesListbackup.forEach((a, b) => {
					//this.skuNoList = [...this.skuNoList,...a.giftList]
					let fullbuyNum = a.fullbuyNum
					this.$set(a, 'minNum', 1)
					this.$set(a, 'maxNum', 5)
					if (a.giftList.length > 0) {
						let num = 0 //最大数量
						a.giftList.forEach((item, index) => {
							if (!item.isrequired) {//换购商品最大个数
								num++
							}
						});
						if (fullbuyNum > 0 && fullbuyNum >= num) {// 如果数量大于num ,赋值num
							fullbuyNum = num
						}else if(fullbuyNum === 0 && num > 0){
							fullbuyNum = 1
                        }
						this.$set(a, 'maxNum', num)
						if (num == 0) {
							this.$set(a, 'minNum', 0)
							this.$set(a, 'maxNum', 0)
						}
					}
					this.$set(a, 'fullbuyNum', fullbuyNum)
				});
				return this.activityRulesListbackup
			},
			postDatagood() {//已选择商品id
				if (this.secradio == 4 || this.secradio == 2) {//
					if (this.$route.query.activitySource == 2) {
						this.isIntegerMultiple = null;
					} else {
						this.isIntegerMultiple = this.unitType == '1'
					}
				} else {
					this.isIntegerMultiple = null
				}
				let buyerList = this.$store.state.clientlist.willadd || [];
				return {
					data: {
						notId: this.$store.getters.willaddid,
						isIntegerMultiple: this.isIntegerMultiple,
						buyerIds: buyerList.map(v => v.buyerId), // 添加 入参 buyerIds 选择客户的 buyerIds
						activitySource: this.$route.query.activitySource || 1, // B2B和酒店
						queryPage: 1, // 0-查询全部 1-分页查询
						activityType: 4,// 活动类型 满赠送赠品 1  2-满减 3.限时限购 4.满赠(加价购)
					},
					currentPage: 1
				}
			},
			postDataclient() {//已选择客户id
				return {
					data: {
						ids: this.$store.getters.willaddclientid,
						queryPage: 1, // 0-查询全部 1-分页查询
						clientType: this.$route.query.activitySource || 1, // B2B和酒店
					},
					currentPage: 1
				}
			},
			postData() {
				return {
					data: {
						goodsRelation: this.$store.state.ticket.willadd.map((a) => {
							return {skuNo: a.skuNo}
						}),
						customerRelation: this.$store.state.clientlist.willadd.map((a) => {
							return {buyerId: a.buyerId}
						}),
						activity: {
							activitySource: this.$route.query.activitySource || 1, // 传值 1,2 区别酒店营销，B2B营销
							activityName: this.form.activityName,
							gmtStart: Date.parse(this.form.value1[0]),
							gmtEnd: Date.parse(this.form.value1[1]),
							isLimitTimes: this.isLimitTimes,//是否限定次数
							limitTimes: this.limitTimes,//限定次数
							rangeType: this.thiradio,
							rangCustomerType: this.sevradio,
							activityType: '4',//1满赠，2满减， 4加价购
							sellerId: sessionStorage.getItem('traderId'),
							isOvering: this.isOvering ? '1' : '0',
							isNewUser: this.isNewUser ? '1' : '0'
						},
						activityGiftRuleExLi: this.activityRulesList.map((a, b) => {/* 赠品list */
							return {
								fullMoney: this.secradio == 1 || this.secradio == 3 ? a.fullMoney : null,
								fullAmount: this.secradio == 2 || this.secradio == 4 ? a.fullAmount : null,
								optional_num: a.fullbuyNum,
								type: this.secradio,
								unitType: this.unitType,
								activityGiftRelationLi: a.giftList.map((item, index) => {
									return {
										'sellerId': item.sellerId,
										'skuNo': item.skuNo,
										'skuName': item.skuName,
										'unitAmount': item.price,
										'quantity': item.quantity,
										'limitQuantity': item.limitQuantity || '',
										'unitType': item.unitType,
										'isNeedpay': item.isrequired ? 1 : 0,
										'price': item.price
									}
								}),
							}
						})
					}
				}
			},
			activitySource() {
				return (this.$route.query.activitySource ? this.$route.query.activitySource : this.$route.query.couponSource) || 1
			}
		},
		watch: {
			secradio(val) {
				if (this.activityId) {//编辑
					if (this.isinited) {
						this.clearwilladd()// 清空商品
						this.clearwilladdgift()// 清空赠品
						this.unitType = null
						if (val == 4 || val == 2) this.unitType = '1' //
					} else {
						// this.unitType = null
						// if(val==4||val==2) this.unitType = '1' //
					}
				} else {// 新增
					this.clearwilladd()// 清空商品
					this.clearwilladdgift()// 清空赠品
					this.unitType = null
					if (val == 4 || val == 2) this.unitType = '1' //
				}
				if (this.$route.query.activitySource == 2) {
					this.isIntegerMultiple = null
				}

			},
			unitType(val) {
				if (!this.activityId) {
					this.clearwilladd()
				}
			}
		},
		methods: {
			...mapMutations({//
				'initgood': 'initgood',
				'clearwilladdgift': 'clearwilladdgift',
				'casewilladdgiftData': 'casewilladdgiftData',
				'deleteOnewillBuygood': 'deleteOnewillBuygood',
				'addwillBuyData': 'addwillBuyData',
				'clearwillBuyData': 'clearwillBuyData',
				'clearwilladd': 'clearwilladd',
				'deleteOnewillGift': 'deleteOnewillGift',
				'addwilladdgift': 'addwilladdgift',
				'initclient': 'initclient',
				'initgiftmoney': 'initgiftmoney',
			}),
			...mapActions({
				'addgift': 'addgift',
				'queryclienttableData': 'queryclienttableData',
				'querygiftlistData': 'querygiftlistData',// 查询赠品
				'addgood': 'addgood',
				'updateactivitysent': 'updateactivitysent',// 更新加价购
				'fullgiftSearchGoods': 'fullgiftSearchGoods',// 赠品组合添加商品
				'fullgiftSearchClient': 'fullgiftSearchClient',// 添加客户 ，查询客户
				'chooseAllGoodsAction': 'chooseAllGoodsAction',
				'chooseAllClientsAction': 'chooseAllClientsAction',
			}),
			getsentdetail() {//获取满赠活动详情
				this.loading = true
				return new Promise((resolve, reject) => {
					axiosApi('/marketing/activityGift/Update/view', {activityId: this.activityId}, (res) => {
						resolve(res.data.data)
						this.loading = false
					}, 0, 10)
				})
			},
			onSubmit() {// 提交
				this.$refs['form'].validate((valid) => {
					if (!valid) return false
					let newconfirm = new Confirm(),
						confirmdata = this.postData.data
					newconfirm.use([confirmdata.activity.activityName], '活动名称为空！')
					newconfirm.use([confirmdata.activity.gmtStart], '活动时间为空！')
					newconfirm.use([confirmdata.activity.gmtEnd], '活动时间为空！')
					newconfirm.use([confirmdata.activity.isLimitTimes, '1', confirmdata.activity.limitTimes], '限制次数为空！')
					newconfirm.use([confirmdata.activity.rangCustomerType, '2', confirmdata.customerRelation], '未添加参与活动客户！')
					newconfirm.use([confirmdata.activity.rangeType, '2', confirmdata.goodsRelation], '未添加活动商品！')
					if (confirmdata.activity.activityName.length > 64) {
						newconfirm.status = false
						this.$message({
							type: 'warning',
							message: '活动名称最大字符64位，请修改！'
						})
					}
					let a = confirmdata.activityGiftRuleExLi
					for (let i = 0; i < a.length; i++) {
						if (this.secradio == '1') {// 订购金额
							newconfirm.use([a[i].fullMoney], '满额加价购金额为空！')
							newconfirm.cs('dot', a[i].fullMoney, '满额加价购')
							if (!newconfirm.status) return
						} else if (this.secradio == '2') {// 订购数量
							newconfirm.use([a[i].fullAmount], '满额加价购数量为空！')
							newconfirm.cs('int', a[i].fullAmount, '满件加价购')
							if (!newconfirm.status) return
						} else if (this.secradio == '3') {// 每满额送
							newconfirm.use([a[i].fullMoney], '每满额加价金额为空！')
							newconfirm.cs('dot', a[i].fullMoney, '每满额加价购')
							if (!newconfirm.status) return
						} else if (this.secradio == '4') {// 每满件送
							newconfirm.use([a[i].fullAmount], '每满件加价购数量为空！')
							newconfirm.cs('int', a[i].fullAmount, '每满件加价购')
							if (!newconfirm.status) return
						}

						let m = a[i].activityGiftRelationLi;
						newconfirm.use([m[0]], '换购商品为空！')
						if (!newconfirm.status) return
						for (let j = 0; j < m.length; j++) {
							newconfirm.use([m[j].price], '换购商品金额为空！')
							newconfirm.cs('dot', m[j].price, '换购商品金额')
							if (!newconfirm.status) return
							newconfirm.use([m[j].quantity], '换购商品数量为空！')
							newconfirm.cs('int', m[j].quantity, '换购商品数量')
							if (!newconfirm.status) return
						}
					}

					if (!newconfirm.status) return
					var full = confirmdata.activityGiftRuleExLi.map((a, b) => {
						return (this.secradio == '1' || this.secradio == '3') ? a.fullMoney : a.fullAmount
					})
					var len1 = full.length
					var fullset = full.unique();//数组去重
					var len2 = fullset.length;
					if (len1 != len2) {
						newconfirm.status = false
						this.$message({
							type: 'warning',
							message: '存在相同的活动规则！'
						})
					}

					if (newconfirm.status) {
						if(this.$route.query.type=='copy'){
							// 判断是否是复制新增
							this.addactivitysent(this.postData)
						}else{
							if (this.activityId) {// 更新加价购活动
								// let body = Object.assign(this.postData,{data:{activity:{id: this.activityId}}})
								this.postData.data.activity.id = this.activityId;
								this.updateactivitysent(this.postData)
							} else {// 新增加价购活动
								this.addactivitysent(this.postData)
							}
						}
				
					} else {
						return false
					}
				})
			},
			addactivitysent(data) {// 添加加价购活动接口
				var that = this
				that.loading = true
				const api = '/marketing/activityGift/add'
				let body = {}
				let newbody = Object.assign({}, body, data.data)
				axiosApi(api, newbody, (res) => {
					that.$notify({
						type: 'success',
						message: '添加满赠活动成功,即将跳转到活动列表页面'
					})
					setTimeout(function () {
						that.loading = false
						that.$router.push(basePath + '/activity/2')
					}, 1000)
				}, 0, 10, (res) => {
					that.loading = false
					that.$notify({
						type: 'warning',
						message: '添加加价换购活动失败,' + res.data.message
					})
				})
			},
			back() {// 取消提交
				// this.$router.push(basePath + '/activity/2')
				this.clearwilladd()// 清空指定商品
				this.clearwillBuyData()// 清空换购商品
				history.go(-1)
			},
			addmoneylist() {// 添加加价购规则

				this.addwilladdgift()
			},
			addgift(index, num) {// 添加换购商品
				if (num >= 5) {
					this.$message.info('每条规则最多有5种商品');
					return false
				}
				this.dialogVisible = true
				this.goodsNum = 5 - num;
				this.skuNoList = this.activityRulesList[index].giftList.map(n => {
					return n.skuNo
				})// 过滤已添加的商品
				this.querygiftlistData(Object.assign({data: {}}, {
					chooseIndex: index,
					type: 'addwithadditional',
					data: {skuNoList: this.skuNoList}
				}))
			},
			deletemoneylist(y) {
				let h = this.$createElement;
				this.$msgbox({
					title: '提示信息',
					message: h('p', {
						style: 'text-align:center'
					}, '是否确定删除?'),
					showCancelButton: true,
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					beforeClose: (action, instance, done) => {
						if (action === 'confirm') {
							this.casewilladdgiftData(y)
							done()
						} else {
							done()
						}
						delete instance.$slots.default;
					}
				})
			},
			delOnegift(index1, index2) {// 删除活动中的商品
				this.deleteOnewillGift({
					index1: index1,
					index2: index2
				})
			},
			addGood() {//添加活动商品
				this.dialog1Visible = true
				this.fullgiftSearchGoods(this.postDatagood)
			},
			addAllGoods() { // 添加全部商品
				this.$msgbox({
					title: '提示信息',
					message: '是否选择全部商品？',
					showCancelButton: true,
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					beforeClose: (action, instance, done) => {
						if (action === 'confirm') {
							instance.confirmButtonLoading = true;
							instance.confirmButtonText = '执行中...';
							let currentPath = this.$router.currentRoute.path || '';
							this.chooseAllGoodsAction({
								'instance': instance,
								'done': done,
								'currentPath': currentPath,
								'activitySource': this.activitySource
							});
						} else {
							done();
						}
					}
				})
			},
			addclient() {//添加客户
				this.dialog2Visible = true
				this.queryclienttableData(this.postDataclient)
				// this.fullgiftSearchClient(this.postDataclient)
			},
			addAllClients() {
				this.$msgbox({
					title: '提示',
					message: '是否选择全部客户？',
					showCancelButton: true,
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					beforeClose: (action, instance, done) => {
						if (action === 'confirm') {
							instance.confirmButtonLoading = true;
							instance.confirmButtonText = '执行中...';
							let postData = {
								data: {sellerId: sessionStorage.traderId},
								currentPage: 1
							}
							this.chooseAllClientsAction({
								'instance': instance,
								'done': done,
								'postData': postData,
								'clientType': this.activitySource
							});
						} else {
							done();
						}
					}
				})
			},
			delclients() {//批量删除客户
				if (this.$store.state.clientlist.willdelete.length != 0) {
					this.$msgbox({
						title: '提示信息',
						message: '您确定要删除这些客户？',
						showCancelButton: true,
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						beforeClose: (action, instance, done) => {
							if (action === 'confirm') {
								this.deleteclients()
								done();
							} else {
								done();
							}
						}
					})
				} else if (this.$store.state.clientlist.willadd.length == 0) {
					this.$alert('无客户可删除，请先添加客户', '', {
						confirmButtonText: '确定',
						callback: action => {

						}
					});
				} else {
					this.$alert('请先勾选需要删除的客户', '', {
						confirmButtonText: '确定',
						callback: action => {

						}
					});
				}
			},
			delgoods() {//批量删除商品
				if (this.$store.state.ticket.willdelete.length != 0) {
					this.$msgbox({
						title: '提示信息',
						message: '您确定要删除这些商品？',
						showCancelButton: true,
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						beforeClose: (action, instance, done) => {
							if (action === 'confirm') {
								this.deletegoods()
								done();
							} else {
								done();
							}
						}
					})
				} else if (this.$store.state.ticket.willadd.length == 0) {
					this.$alert('无商品可删除，请先添加商品', '', {
						confirmButtonText: '确定',
						callback: action => {

						}
					});
				} else {
					this.$alert('请先勾选需要删除的商品', '', {
						confirmButtonText: '确定',
						callback: action => {

						}
					});
				}
			},
			init(sentinfo) {// 初始化编辑页面

				this.form = {
					activityName: sentinfo.activityVo.activityName,
					value1: [new Date(sentinfo.activityVo.gmtStart), new Date(sentinfo.activityVo.gmtEnd)]
				}
				this.isOvering = sentinfo.activityVo.isOvering ? true : false
				this.isNewUser = sentinfo.activityVo.isNewUser ? true : false
				this.thiradio = sentinfo.activityVo.rangeType + ''//1全部商品参与，2部分商品参与，3部分商品不参与
				this.secradio = sentinfo.activityVo.type + '';//1按订购金额，2按订购数量
				//限制次数
				this.isLimitTimes = sentinfo.activityVo.isLimitTimes + ''
				this.limitTimes = sentinfo.activityVo.limitTimes + ''
				this.sevradio = sentinfo.activityVo.rangCustomerType + ''//1全部客户参与，2部分客户参与
				//初始化客户列表
				this.initclient(sentinfo.traderVos ? this.initList(sentinfo.traderVos) : [])
				let ne = []
				sentinfo.giftRuleExPos.map((a, b) => {
					this.unitType = a.unitType + '' // 商品类型按箱，按瓶
					ne.push(
						{
							fullMoney: a.fullMoney || '',
							fullAmount: a.fullAmount || '',
							fullbuyNum: a.optionalNum || '',// 换购种类数量
							giftList: a.activityGiftRelationLi.map(item => {
								let arr = [
									{// 瓶箱下拉选择框
									    label: '箱(规格单位)',
									    value: 1
									}, {
									    label: item.giftUnit + '(基本单位)',
									    value: 2
									}
								]
								return Object.assign(item, {price: item.unitAmount, isrequired: item.isNeedPay,unitOptions:arr})
							})
						}
					)
				})
				this.moneylist = ne
				let ve = []
				ve = ne.map((v) => {
					v.giftList.map(v1 => {
						if (v1.limitQuantity == 0) {
							v1.limitQuantity = ''
						}
						return v1
					})
					return v
				})
				this.initgiftmoney(JSON.parse(JSON.stringify(ve)))
				//初始化商品列表
				this.initgood({willadd: sentinfo.goodsSkuVos ? sentinfo.goodsSkuVos : []})
				setTimeout(() => {
					this.isinited = true //已经初始化
				}, 0)
			},
			initList(list){
			// 剔除已删除客户
			var arr = list.filter(item=>{
					return item.buyerId
			})
			return arr
			}
		},
		mounted() {
			this.$nextTick(() => {
				if (this.activityId) {
					this.getsentdetail().then(res => {
						this.init(res);
					})
				} else {
					this.clearwilladd()// 清空商品
					this.clearwilladdgift()// 清空赠品
				}
			})
		}
	}
</script>
<style lang="less">
    .add_with_additional {
        .color-gray {
            color: #999;
            display: inline-block;
            width: 45px;
        }

        .unit_select {
            .el-input {
                width: 120px;
            }
        }

        .skuName_input {
            width: 150px;
        }

        .delete_icon {
            &:hover {
                color: #ff4201;
            }
        }
    }
</style>

