<template>
  <!--单位管理-->
  <div id="contractCustomer">
    <div class="title-top bg-f clearfix">单位管理</div>
    <div class="content bg-f pr-30 pl-30 mt-5">
      <div class="clearfix">
        <div class="fr">
          <el-button type="narmal" class="mb-5 mr-10 mt-10" @click="openCustomer('form')">添加单位</el-button>
        </div>
      </div>
      <div class="pb-30">
        <el-table :data="tableData">
          <el-table-column label="单位名称">
            <template slot-scope="scope">
              <span>{{scope.row.unitName}}</span>
            </template>
          </el-table-column>
          <el-table-column label="单位属性">
            <template slot-scope="scope">
              <span>{{scope.row.isSystem == 1?'系统单位':'自定义单位'}}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <el-button type="text" v-if="scope.row.isSystem != 1" @click="isDelete(scope)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          class="tar"
          v-if="tableData.length>0"
          :current-page="currentPage"
          :page-size="10"
          :total="total"
          layout="prev , pager, next, jumper"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </div>
      <!--新增单位-->
      <el-dialog class="minMop" title="新增单位" :visible.sync="customer">
        <el-form class="pr-10" :rules="rules" ref="form" :model="form">
          <el-form-item label="单位名称:" class="ml-20" prop="unitName">
            <el-input
              class="ml-10 mr-10"
              v-model="form.unitName"
              placeholder="单位名称"
              :maxlength="40"
            ></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="danger" @click="addCustomer('form')">确定</el-button>
          <el-button class="ml-20" @click="customer = false">取消</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { axiosApi, basePath } from "../../../api/api";
import { MessageBox } from "element-ui";
function filterInput(val) {
  return val.replace(/[\ud800-\udbff][\udc00-\udfff]/g, "");
}
export default {
  data() {
    return {
      customer: false, //新增单位弹框
      Form: {
        unitName: ""
      },
      form: {
        unitName: ""
      },
      searchForm: {
        unitName: ""
      },
      tableData: [], //客户数据
      currentPage: 1,
      total: 0, //页面分页总数
      Page: 1,
      totalCount: 0, //弹窗分页总数
      rules: {
        unitName: [
          {
            required: true,
            message: "单位名称为必填项"
          },
          { min: 1, max: 2, message: "长度在 1 到 2 个字符", trigger: "blur" }
        ]
      },
      formPeriod: {
        settlementDay: "", //结算日期
        settlementPeriod: "" //周期
      },
      hotelId: "", //酒店ID
      cusId: "", //协议公司主键id
      bindData: [], //已绑定数据
      unBindData: [] //未绑定数据
    };
  },
  watch: {
    "form.unitName": {
      handler: function(val) {
        this.$nextTick(() => {
          this.form.unitName = filterInput(val);
        });
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.achieve();
    });
  },
  computed: {},
  methods: {
    achieve() {
      axiosApi(
        "/superc/tenantShopUnit/list",
        {},
        res => {
          this.tableData = res.data.data.elements ? res.data.data.elements : [];
          this.total = res.data.data.totalCount;
        },
        this.currentPage,
        10,
        res => {}
      );
    },
    addCustomer(form) {
      //新增单位
      this.$refs[form].validate(valid => {
        if (valid) {
          axiosApi(
            "/superc/tenantShopUnit/insert",
            {
              unitName: this.form.unitName
            },
            res => {
              this.achieve();
              this.$message({
                type: "success",
                message: "新增成功",
                duration: 3000
              });
              this.customer = false;
            },
            1,
            10,
            res => {
              this.customer = false;
              this.$message({
                type: "info",
                message: res.data.message,
                duration: 3000
              });
            }
          );
        } else {
        }
      });
    },
    openCustomer(form) {
      this.customer = true;
      if (this.$refs[form] !== undefined) {
        this.$refs[form].resetFields();
      }
    },
    //删除
    isDelete(val) {
      let that = this;
      this.$confirm("您是否要删除该单位？", "提示信息", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          const api = "/superc/tenantShopUnit/update";
          axiosApi(
            api,
            {
              id: val.row.id,
              unitName: val.row.unitName,
              isDeleted: 1
            },
            res => {
              this.$message({
                type: "success",
                message: "操作成功!",
                duration: 1000
              });
              this.currentPage = 1;
              that.achieve();
            },
            this.currentPage,
            10,
            res => {
              this.$message({
                type: "info",
                message: res.data.message,
                duration: 3000
              });
            }
          );
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作"
          });
        });
    },
    handleCurrentChange(val) {
      //分页查询
      this.currentPage = val;
      this.achieve();
    }
  }
};
</script>

<style lang="less">
#contractCustomer {
  .content {
    .el-form--inline {
      // width: 650px;
      .el-form-item__label {
        font-size: 12px;
        width: 60px;
      }
      .order-datatimerange {
        width: 240px;
      }
    }
    .el-button + .el-button {
      margin-left: 0;
    }
    .el-table .cell {
      text-align: center;
      padding: 10px 0;
      img {
        width: 60px;
        height: 60px;
        margin-left: 10px;
        margin-right: 10px;
      }
      .width-130 {
        width: 130px;
        text-align: left;
      }
      .el-button {
        height: 20px;
        width: 80px;
      }
    }
    .el-form-item__label {
      width: 100px;
    }
    .reject {
      .el-dialog {
        width: 900px;
        .el-dialog__body {
          min-height: 420px;
        }
      }
      .el-form-item__error {
        margin-left: 100px;
      }
    }
  }
  .el-dialog{
    min-height: 300px !important;
  }
  .el-form-item__error {
    margin-left: 110px !important;
  }
  .bindSales {
    .tc {
      text-align: center;
    }
    .el-dialog {
      width: 900px;
      min-height: 700px !important;
    }
    .el-dialog__body {
      padding: 20px;
      .search {
        .el-form-item__label {
          width: 80px;
        }
        .el-form--inline {
          width: 350px !important;
        }
      }
    }
  }
}
</style>