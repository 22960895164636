<template>
    <div id="jicaiListB" class="pb-50">
        <div v-if="firstInto">
            <div class="title-top bg-f">
                集采介绍
            </div>
            <div class="main bg-f mt-5">
                <div class="textTitle">1.什么是集采？</div>
                <div class="textContent mt-15">集采项目是久加久将自身的酒水资源，分享给各地 名烟名酒终端，使其获取当地该产品线上及线下独 家代理和优惠价格的一个项目。</div>
                <div class="clearfix">
                    <img class="img1 fr" src="../../assets/jicai_docs_1.png">
                </div>
                <div class="clearfix mt-30">
                    <img class="img2 fl mt-30" src="../../assets/jicai_docs_2.png">
                    <div class="textContent mt-15 fr">
                        <span class="textTitle">2.对我有什么好处？</span>
                        <div class="textContent mt-15">代理区域产品唯一性，阶梯式进货价格，久加久云商平台 提供价格支撑，协助退换货的售后增值服务。</div>
                    </div>
                </div>
                <div class="clearfix mt-50">
                    <div class="fl">
                        <span class="textTitle">3.如何报名？</span>
                        <div class="textContent mt-15">可直接登入久加久云商平台网站或久加久云商手 机APP中集采平台进行报名，或联系久加久业务 人员，由业务员进行代报名。</div>
                    </div>
                    <img class="img2 fr mt-30" src="../../assets/jicai_docs_3.png">
                </div>
                <el-button class="mt-30" type='danger' @click="changeToJicaiList">立即参与</el-button>
            </div>
        </div>
        <div v-else>
            <div class="title_top bg-f">
                <div class="fl">招标信息</div>
            </div>
            <div class="bg-f mt-5 pb-50">
                <div class="content bg-f pl-30 pr-30 pb-50">
                    <!-- 状态切换区域 -->
                    <div class="tab_box">
                        <div class="tab_item" :class="{tab_item_checked: item.type==state}"
                             v-for="(item,index) in tabList"
                             :key="index" @click="checkState(item)">
                            <span>{{item.label}}</span>
                            <span>({{item.bage ? item.bage : 0}})</span>
                        </div>
                    </div>
                    <!-- 搜索区域 -->
                    <div class="clearfix search">
                        <el-form :inline='true' class="pt-30 fl" :model="Form" ref="searchForm">
                            <div style="width:780px">
                                <el-form-item label='商品名称'>
                                    <el-input v-model.trim="Form.goodsName" :maxlength="30"
                                              placeholder="输入商品名称"></el-input>
                                </el-form-item>
                                <el-form-item label='商品分类'>
                                    <el-select v-model.trim="Form.skuClassifyIdList" placeholder="请选择">
                                        <el-option
                                                v-for="item in options"
                                                :key="item.value"
                                                :label="item.label"
                                                :value="item.value">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </div>
                        </el-form>
                        <el-button class="fl ml-30 mt-30" type='info' @click="getList()">查询</el-button>
                    </div>
                    <!-- 列表区域 -->
                    <div class="pb-30">
                        <el-table :data="List" class='table-default'>
                            <el-table-column label='招标名称' width="" class-name="plName">
                                <template slot-scope="scope">
                                    <span>{{scope.row.name}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column label='商品名称' width="">
                                <template slot-scope="scope">
                                    <span>{{scope.row.skuName}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column label='招标开始时间' width="90">
                                <template slot-scope="scope">
                                    <span>{{scope.row.bidDateFrom | timenData}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column label='招标地区' width="70">
                                <template slot-scope="scope">
                                    <span>已符合</span>
                                </template>
                            </el-table-column>
                            <el-table-column label='集采价格' width="">
                                <template slot-scope="scope">
                                    <span v-if="scope.row.state==2">-</span>
                                    <span v-html="scope.row.bidPriceList" v-else></span>
                                </template>
                            </el-table-column>
                            <el-table-column label='合约时间' width="70">
                                <template slot-scope="scope">
                                    <span>&nbsp;&nbsp;&nbsp;&nbsp;{{scope.row.validPeriod}}年</span>
                                </template>
                            </el-table-column>
                            <el-table-column label='状态' width="60">
                                <template slot-scope="scope">
                                    <span v-if="scope.row.state==0">未开始</span>
                                    <span v-if="scope.row.state==1">进行中</span>
                                    <span v-if="scope.row.state==2">已完成</span>
                                    <span v-if="scope.row.state==3">已报名</span>
                                </template>
                            </el-table-column>
                            <el-table-column label='操作' width="70">
                                <template slot-scope="scope">
                                    <el-button type='text' @click="goDetail(scope)">查看</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                        <el-pagination class="tar"
                                       v-if="List.length>0"
                                       :current-page="currentPage"
                                       :page-size='10'
                                       :total='total'
                                       layout="prev , pager, next, jumper"
                                       @current-change="handleCurrentChange">
                            <!-- 分页组件 -->
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    import {axiosApi, basePath, baseURI} from "../../api/api";

    export default {
        data() {
            return {
                tabList: [{
                    label: '全部',
                    type: -1,
                    bage: 0
                }, {
                    label: '未开始',
                    type: 0,
                    bage: 0
                }, {
                    label: '进行中',
                    type: 1,
                    bage: 1
                }],
                state: -1,
                currentPage: 1,
                total: 0,
                List: [],
                Form: {
                    skuClassifyIdList: null,   //gmtOrderStart下单开始时间 gmtOrderEnd下单结束时间
                    goodsName: null,
                },
                options: [{
                    value: null,
                    label: "全部"
                }],
                firstInto: false
            }
        },
        //进入加载
        mounted() {
            this.$nextTick(function () {
                if (sessionStorage.getItem('jicaiListBType')) {
                    this.state = sessionStorage.getItem('jicaiListBType');
                    sessionStorage.removeItem("jicaiListBType");
                }
                this.getList()
                this.getBage()
                this.getIsFirst()
                this.getOptions()
            })
        },
        methods: {
            getBage() {//获取角标
                axiosApi('/trader/bid/count/buyer', {}, (res) => {
                    this.tabList.forEach(item => {
                        if (item.type == -1) {
                            item.bage = res.data.data.notStart + res.data.data.started
                        } else if (item.type == 0) {
                            item.bage = res.data.data.notStart
                        } else if (item.type == 1) {
                            item.bage = res.data.data.started
                        }
                    })
                }, 1, 10)
            }
            ,
            firstIntoFun() {
                axiosApi("/base/trader/buyer/updateByTraderId", {
                    skipBidIntroductionPage: true,
                    traderId: sessionStorage.traderId
                }, res => {
                })
            },
            getIsFirst() {
                axiosApi("/base/trader/buyer/select", {
                    traderId: sessionStorage.traderId
                }, res => {
                    this.firstInto = !res.data.data.skipBidIntroductionPage //是否第一次进入 （true：第一次进入 不跳过；false：不是第一次进入 跳过） = ！ (true：跳过介绍页；false：不跳过)
                })
            },
            getList() {
                axiosApi('/trader/bid/list/buyer', {
                    stateList: this.state == -1 ? [0, 1] : [this.state],//状态(0:未开始，1:进行中)
                    bidByManager: false,
                    skuName: this.Form.goodsName ? this.Form.goodsName : null,//商品名称
                    skuClassifyIdList: this.Form.skuClassifyIdList ? [this.Form.skuClassifyIdList] : null,//商品分类
                }, (res) => {
                    this.List = res.data.data.elements ? res.data.data.elements : []
                    this.List.forEach(item => {
                        item.bidPriceList = this.priceToString(item.bidPriceList)
                    })
                    this.total = res.data.data.totalCount
                }, this.currentPage, 10, (res) => {
                })
            }
            ,
            checkState(val) {//切换状态
                this.state = val.type
                this.currentPage = 1
                this.getList()
            }
            ,
            handleCurrentChange(val) {//分页
                this.currentPage = val
                this.getList()
            }
            ,
            goDetail(scope) {
                this.$router.push({path: 'jicaiDetailB', query: {id: scope.row.id, ListB: true}})
                sessionStorage.setItem('jicaiListBType', this.state);
            }
            ,
            priceToString(bidPriceList) {
                let priceString = "";
                if (bidPriceList) {
                    priceString += "满" + bidPriceList[0].qty + "箱、每瓶" + bidPriceList[0].price + "元、返利" + bidPriceList[0].rebate + "%"
                    if (priceString.length < 19) {
                        priceString += "<BR/>"
                        if (bidPriceList.length > 1) {
                            let type = "满" + bidPriceList[1].qty + "箱、每瓶" + bidPriceList[1].price + "元、返利" + bidPriceList[1].rebate + "%";
                            if (type.length > 19) {
                                priceString += type.substring(0, 17) + "...";
                            } else {
                                priceString += type;
                            }
                        }
                    } else {
                        priceString += "..."
                    }
                    // bidPriceList.forEach(item => {
                    //     priceString += "满" + item.qty + "箱、每瓶" + item.price + "元、返利" + item.rebate + "%<BR/>"
                    //     return false
                    // });
                }
                return priceString;
            }
            ,
            changeToJicaiList() {
                this.firstIntoFun();
                this.firstInto = false;
            }
            ,
            getOptions() {
                axiosApi("/trader/bid/sku/classifyList", {
                    bidByManager: false
                }, res => {
                    res.data.data.forEach(item => {
                        this.options.push({
                            value: item.id,
                            label: item.classifyName
                        })
                    })
                })
            }
        }
        ,
    }
</script>

<style lang="less">
    #jicaiListB {
        .tab_box {
            height: 60px;
            line-height: 60px;
            box-sizing: border-box;
            border-bottom: 1px solid #eee;

            .tab_item {
                display: inline-block;
                width: 114px;
                height: 26px;
                line-height: 26px;
                text-align: center;
                box-sizing: border-box;
                color: #666;
                font-size: 14px;
                cursor: pointer;
            }

            .tab_item:hover {
                color: #FF4201;
            }

            .tab_item_checked {
                border-radius: 2px;
                border: 1px solid #FF4201;
                color: #FF4201;
            }
        }

        .title_top {
            width: 100%;
            height: 60px;
            line-height: 60px;
            box-sizing: border-box;
            padding-left: 20px;
            font-size: 16px;

            .button_top {
                width: 118px;
                height: 30px;
                line-height: 30px;
                border: 1px solid #DDDDDD;
            }
        }

        .input_short {
            width: 100px;
        }

        .main {
            padding: 30px 300px 50px 180px
        }

        .img1 {
            width: 158px;
            height: 100px;
        }

        .img2 {
            width: 121px;
            height: 120px;
        }

        .textTitle {
            font-size: 16px;
            font-family: STHeitiSC-Light;
            font-weight: 300;
            color: rgba(51, 51, 51, 1);
            line-height: 16px;
        }

        .textContent {
            width: 322px;
            height: 34px;
            font-size: 14px;
            font-family: STHeitiSC-Light;
            font-weight: 300;
            color: rgba(102, 102, 102, 1);
            line-height: 17px;
        }

        .table-default.el-table .cell {
            text-align: left !important;
        }

        .plName .cell {
            margin-left: 30px!important;
        }
    }
</style>