<template>
	<div id="shopOther">
		<searchLR></searchLR>	
		<div class="bg-f6">
			<router-view></router-view>
		</div>
	</div>
</template>

<script>
	import { axiosApi,basePath } from "../api/api";
	import searchLR from "./searchLR.vue";
	export	default{
		components: {
			searchLR
		},
		data(){
			return{
				data:[],
				classifyList:[],
				showClassifyList: false,
				nava:false,
				classifyList:[],
				classifyName:'白酒',
				brandList:[]
			}
		}
	}
</script>

<style lang="less">
	#shopOther .table{
		border-bottom: 2px solid #FF4201;
		.tab{
			height: 50px;
			line-height: 50px;
			text-align: center;
			.nav-w>li {
				width: 132px;
				color: #000;
			};
			.nav-w>li:nth-child(1) {
				width: 190px;
				background: #FF4201;
				color: #fff;
				position: relative;
				.leftClassify{
					position: absolute;
					width: 100%;
					left: 0;
					top: 50px;
					border-top: 2px solid #FF4201;
					z-index: 1000;
					color: #333;
					text-align: left;
					/*菜单展开*/
					.navMain {
						width: 750px;
						height: 420px;
						position: absolute;
						left: 190px;
						top: 0;
						z-index: 999;
						.w-100 {
							width: 100px;
						}
					}
				}
				.nav li {
					width: 170px;
					height: 50px;
					line-height: 50px
				}
				.nav li:hover {
					background: #F1F1F1;
					color: #FF4100;
				}
			};
			.nav-w>li:hover{
				background: #FF4201;
				color: #fff;
			}
			
		}
	}
</style>