<template>
	<div id="store-supplier-management">
		<!--头部-->
		<div class="title-top mb-5 bg-f">
			我的供货商
		</div>
		<!--内容-->
		<!--搜索条件 el-form-->

		<el-form :inline='true' class='pt-30 pl-30 bg-f search' v-if="!ishotel">
			<el-form-item label='供货商'>
				<el-input v-model.trim="storeName" placeholder='请输入供应商名称' :maxlength="100"></el-input>
			</el-form-item>
			<el-button class='ml-30' @click='handleClick' type="info">搜 索</el-button>
		</el-form>

		<div class="pl-30 pr-30 pb-50 content bg-f" :class="ishotel ? 'pt-30' : 'pt-10'">
			<!--表格 table-->
			<el-table :data="sellerList" stripe v-loading='loading' >
				<el-table-column label='供货商名称'  show-overflow-tooltip>
					<template slot-scope="scope">
						<span>{{scope.row.storeName}} <span class="color-9 font-12" v-if="scope.row.isFirst==1">(第一供货商)</span> </span>
					</template>
				</el-table-column>
				<el-table-column label='所在地区' width="180" show-overflow-tooltip>
					<template slot-scope="scope">
						<span>{{scope.row.provinceName+scope.row.cityName+scope.row.regionName+scope.row.attr}}</span>
					</template>
				</el-table-column>
				<el-table-column label='联系方式' show-overflow-tooltip>
					<template slot-scope="scope">
						<span>{{scope.row.linkname}} / {{scope.row.tel}}</span>
					</template>
				</el-table-column>
				<el-table-column :label='ishotel ? "账期（结算方式）" : "授信额度（元）"' show-overflow-tooltip>
					<template slot-scope="scope">
						<template v-if="!ishotel">
							<div v-if="scope.row.isDisable==1" class="color-9">
								已冻结
							</div>
							<div v-if="scope.row.isDisable==0">
								<div>剩余额度：<span style="color:#ff9900">{{scope.row.leftCreditMoney}}</span></div>
								<div>总额度：{{scope.row.creditMoney}}</div>
							</div>
							<div v-if="scope.row.isDisable==null" >
								--
							</div>
						</template>
						<template v-else></template>
					</template>
				</el-table-column>
				<el-table-column label='备注' prop='remark' show-overflow-tooltip></el-table-column>
				<el-table-column label='操作' width="110">
					<template slot-scope="scope">
						<div class="mb-10"><el-button type="text" class="wh-auto font-12" @click.native='handleIntoStore(scope.row)'>进店</el-button></div>
						<div><el-button type="text" class="wh-auto font-12" v-if="!ishotel" @click.native='handleDelete(scope.row)'>删除</el-button></div>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination v-if="total>0" class="tar mt-20"  :current-page="currentPage" :page-size='10' :total='total' layout="prev , pager, next, jumper" @current-change="handleCurrentChange">
				<!-- 分页组件 -->
			</el-pagination>
		</div>
	</div>
</template>

<script>
	import { axiosApi } from '../../../api/api' //api
	import { MessageBox } from 'element-ui'
	export default {
		data() {
			return {
				sellerList: [], //我的供货商列表
				currentPage: 1,
				total: 100,
				traderId: '', //交易对象id 商户
				storeName: '', //搜索-供货商名称
				loading:false,
				ishotel:sessionStorage.getItem("isHotel") === '1' //酒店用户
			}
		},
		activated() {
			this.$nextTick(() => {
				this.traderId = sessionStorage.traderId
				this.buyerFindSellerList();
			})
		},
		methods: {
			//处理进店操作
			handleIntoStore(row){
				this.$router.push('/customer/goodsSearch/classify/brand/goodsName/'+row.sellerId)
			},
			//搜索处理函数
			handleClick(){
				this.buyerFindSellerList(true)
			},
			buyerFindSellerList(bool) { // 供货商列表展示
				if(bool&&this.currentPage != 1) {
					this.currentPage = 1;
					return
				}
				this.loading = true
				const api = "/trader/buyerFindSellerList/select"
				let body = {
					traderId: this.traderId,
					storeName: this.storeName
				}
				axiosApi(api, body, this.buyerFindSellerListCallback, this.currentPage, 10,(res)=>{
					this.loading = false
					this.$message({type:'error',message:res.data.message,duration:1000})
				})
			},
			buyerFindSellerListCallback(res) { // 供货商列表展示 回调函数
				// console.log(res)
				this.loading = false
				this.sellerList = res.data.data.elements //获取的供应商列表数据
				this.total = res.data.data.totalCount //总条数
			},
			handleCurrentChange(val) { //分页处理函数
				// console.log(val)
				this.currentPage = val
				this.buyerFindSellerList()
			},
//			trader.sellerDeleteBuyer.update
			deleteRelevanceSellerList(body){   //删除商户的供货商
				const api = "/trader/sellerDeleteBuyer/update"
				this.loading = true
				axiosApi(api,body,this.deleteRelevanceSellerListCallback,1,10,(res)=>{
					this.loading = false
					MessageBox.alert(res.data.message, '提示', {
						confirmButtonText: '确定'
					})
					this.buyerFindSellerList()
				})
			},
			deleteRelevanceSellerListCallback(res){   //删除商户的供货商接口回调函数
				this.$message({
					type:'info',
					message:'删除成功'
				})
				this.buyerFindSellerList()
			},
			handleDelete(row) { //删除供应商
				let that = this
				this.$msgbox({
					title: '提示信息',
					message: "该数据删除后不可恢复，确认要删除？",
					showCancelButton: true,
					confirmButtonText: '删 除',
					cancelButtonText: '取 消',
					beforeClose: (action, instance, done) => {
						if(action === 'confirm') {
							instance.confirmButtonLoading = true;
							instance.confirmButtonText = '执行中...';
							let body = {
								ids:[row.id]
							}
							that.deleteRelevanceSellerList(body)
							instance.confirmButtonLoading = false;
							done()
						} else {
							done()
						}
					}
				})
			}
		}
	}
</script>

<style lang="less">
	#store-supplier-management .el-form--inline .el-form-item__label {
		/* 搜索框label 的字体是12px */
		width: 47px;
		font-size: 12px;
	}

	#store-supplier-management .el-form--inline .el-input__inner {
		/* 搜索框的字体是12px */
		font-size: 12px;
	}

	#store-supplier-management .content {
		position: relative;
	}

	#store-supplier-management .exportBtn {
		position: absolute;
		top: 0px;
		right: 30px;
	}

	#store-supplier-management {
		.wh-auto{width: auto;height: auto;}
		.mb-5 {
			margin-bottom: 5px;
		}
		.el-breadcrumb {
			.el-breadcrumb__item__inner {
				color: #FF4201;
			}
		}
		.title-top {
			border: none;
		}
		.el-table th>.cell {
			padding-left: 30px;
		}
		.el-table .cell {
			padding-left: 30px;
			color: #666666;
		}
	}
</style>
