<template>
	<div id="brandsApply" v-loading="loading">
		<div class="title-top bg-f mb-5">
			品牌申请
		</div>
		<div class="bg-f pt-30 pb-50">
			<el-form :inline="true" class="clearfix">
				<div class="ml-45 font-12">
					<div class="clearfix mr-30 bgimg pb-20" :class="{reduce:!spread1}">
						<p class="font-14 color-6 mt-20 mr-20  mb-20">分类:</p>
						<ul class="mt-20  w750">
							<li class="fl width-90 mb-20 color-3 font-14 li cursor tac hides" :class="{active:index==activeIndexClassfy}" v-for='(item,index) in classify' :key="index" @click="classifyChoose(item,index)">{{item.classifyName}}</li>
						</ul>
						<p v-if="classify.length > 8" class="fr mt-20  font-14 cursor color-3" @click="spread1=!spread1">更多
							<i class="color-bb" :class='{"el-icon-caret-bottom":!spread1,"el-icon-caret-top":spread1}'></i>
						</p>
					</div>
					<div class="mr-30 clearfix bgimg mb-10">
						<p class="font-14 color-6 mt-20 mr-20 fl mb-20">品牌:</p>
						<div class="fl">
							<div v-if='spread2 && isOpenGoods.length > 8' class="mt-20">
								<el-input placeholder="请输入品牌" v-model.trim='brandName' @keyup.enter.native="achieveBrand"></el-input>
								<el-button type="danger" class=" ml-30" @click='achieveBrand'>搜索</el-button>
							</div>
							<ul class='mt-20 w750' :class="{ha:isOpenGoods.length > 8 && spread2,reduce:!spread2}">
								<li class="fl width-90  mb-20 color-3 font-14 li cursor hides tac" :class="{active:index==activeIndexBrand}" v-for='(item,index) in isOpenGoods' :key="index" @click="brandChoose(item,index)">{{item.brandName}}</li>
							</ul>
						</div>
						<p v-if="isOpenGoods.length > 8" class="fr mt-20 font-14 cursor color-3" @click="brandMore">更多
							<i class="color-bb" :class='{"el-icon-caret-bottom":!spread2,"el-icon-caret-top":spread2}'></i>
						</p>
					</div>
				</div>
				<div class="ml-45">
					<div class="mt-30">
						<div>
							<label>请上传凭证：</label>
							<span class="color-9">(上传凭证文件大小4M以内,支持png,jpg格式)</span>
						</div>
						<div class="mt-20" :class="{noAdd:isAdd,add:!isAdd}">
							<el-upload action="/sunrise-gateway/oss/ossUpload" list-type="picture-card" :file-list="fileList" :on-preview="handlePictureCardPreview" :on-success="handleAvatarPhotoScucess" :on-remove="handleRemove" :before-upload="beforeAvatarUpload">
								<i class="el-icon-plus"></i>
							</el-upload>
							<el-dialog :visible.sync="dialogVisible" @close="dialogVisible" size="tiny">
								<img width="100%" :src="dialogImageUrl" alt="">
							</el-dialog>
						</div>
					</div>
					<div class="mt-50 exilic" ref="c">
						<label class="mb-20 dib">已有凭证：</label>
						<div v-if="dialogImageUrlList.length>0">
							<el-upload action="/sunrise-gateway/oss/ossUpload" :file-list="dialogImageUrlList" list-type="picture-card" :on-preview="handlePictureCardPreview">
								<i class="el-icon-plus"></i>
							</el-upload>
							<!--<el-dialog v-model="dialogVisible" size="tiny">
								<img width="100%" :src="dialogImageUrl" alt="show-message">
							</el-dialog>-->
						</div>
						<div v-else>
							您还没有上传过销售资质，上传资质审核通过后，您将成为品牌经销商。
						</div>
					</div>

				</div>
				<el-button type="danger" class="ml-45 mt-50" @click="upload">提交</el-button>
			</el-form>
		</div>
	</div>
</template>

<script>
	import { axiosApi, basePath } from "../../../api/api"
	export default {
		data() {
			return {
				dialogImageUrl: '',
				fileList: [], //上传经销协议
				dialogImageUrlList: [], //已有凭证
				dialogVisible: false,
				classify: [],
				isOpenGoods: [],
				isMore1: false, //判断是否有更多
				isMore2: false, //判断是否有更多
				activeIndexClassfy: 0,
				activeIndexBrand: 0,
				brandId: "",
				classifyId: "",
				checking: true, //已有凭证待审核状态
				isAdd: false, //上传凭证，最多10张
				spread1: false, //判断是否展开
				spread2: false, //判断是否展开
				loading: false, //上传图片load状态
				brandName: null //搜索品牌名

			}
		},
		mounted() {
			this.$nextTick(() => {
				this.search();
			})
		},
		methods: {
			brandMore() { //品牌更多
				this.spread2 = !this.spread2
				document.getElementsByClassName("w750")[1].scrollTop = 0
			},
			//搜索品牌
			achieveBrand() {
				const api = "/goods/classifyBrandListQuery/select";
				const body = {
					brandName: this.brandName,
					classifyId: this.classifyId
				}
				axiosApi(api, body, res => {
					this.isOpenGoods = res.data.data.elements
				}, 1, 1000)
			},
			//默认加载已有凭证
			findTraderCertList() {
				this.dialogImageUrlList = [];
				axiosApi("/trader/findTraderCertList/select", {
					traderId: sessionStorage.traderId,
					brandId: this.brandId,
					classifyId: this.classifyId
				}, res => {
					if(res.data.data) {
						this.dialogImageUrlList = [];
						res.data.data.certRelationVOs.forEach((item, index) => {
							if(item.state != 3) {
								this.dialogImageUrlList.push({
									url: item.certImg,
									state: item.state
								})
							}
						})
						setTimeout(() => {
							this.dialogImageUrlList.forEach((item, index) => {
								if(item.state == 1) {
									//console.log(Array.prototype.slice.call(this.$refs.c.childNodes[2].childNodes[0].children)[index])
									//console.log(this.$refs.c.childNodes[2].childNodes[0].childNodes[0].childNodes[index])
									this.$refs.c.childNodes[2].childNodes[0].childNodes[0].childNodes[index].className = "el-upload-list__item is-success credentials"
								}
							})
						}, 500)
					}
				})
			},
			//品牌点击
			brandChoose(item, index) {
				this.activeIndexBrand = index;
				this.brandId = item.id;
				this.dialogImageUrlList = [];
				this.findTraderCertList();
			},
			//分类点击
			classifyChoose(item, index) {
				// console.log(item)
				this.activeIndexClassfy = index;
				this.classifyId = item.id;
				this.brandName = ""
				this.dialogImageUrlList = [];
				this.searchBrand();
			},
			//查询品牌/分类
			search() {
				axiosApi("/goods/classifyTopList/select", {
					classificationId: null
				}, (res) => {
					this.classify = res.data.data; //分类	
					this.classifyId = res.data.data[0].id;
					this.searchBrand()
				});
				// setTimeout(() => {
				// 	this.changeHeight()
				// }, 200)

			},
			searchBrand() {
				axiosApi("/goods/classifyBrandListQuery/select", {
					classifyId: this.classifyId
				}, (res) => {
					this.isOpenGoods = res.data.data.elements; //品牌
					this.brandId = res.data.data.elements[0].id
					this.findTraderCertList();
				},1,1000);
			},
			//更多改变高度
			// changeHeight() {
			// 	if(this.classify.length > 8) {
			// 		this.spread1 = true;
			// 	}
			// 	if(this.isOpenGoods.length > 8) {
			// 		this.spread2 = true;
			// 	}
			// },
			handleAvatarPhotoScucess(res) { //上传图片经销协议（增加）
				this.fileList.push({
					url: res.data[0]
				})
				if(this.fileList.length == 10) {
					this.isAdd = true;
				}
				this.loading = false;
			},
			beforeAvatarUpload(file) { //上传之前图片的判断
				// console.log(file)
				const isImage = (file.type.indexOf('png') != -1 || file.type.indexOf('jpeg') != -1);
				const isLt4M = file.size / 1024 / 1024 < 4;
				if(!isImage) {
					this.$message.error('请上传格式为png或jpg的图片文件!');
				}
				if(!isLt4M) {
					this.$message.error('上传图片大小不能超过 4MB!');
				}
				if(isImage && isLt4M) {
					this.loading = true;
				}
				return isImage && isLt4M;
			},
			handlePictureCardPreview(file) {
				this.dialogImageUrl = file.url;
				this.dialogVisible = true;
			},
			handleRemove(file, fileList) {
				this.fileList = [];
				this.fileList = fileList;
				if(this.fileList.length < 10) {
					this.isAdd = false;
				}
			},
			upload() {
				let _this = this;
				if(this.fileList.length == 0) {
					this.$confirm('请上传您的销售许可凭证或授权协议', '提示信息', {
						confirmButtonText: '确定',
						type: 'warning'
					})
					return false;
				}
				this.$confirm('您确定要上传这些图片？', '提示信息', {
					confirmButtonText: '确定',
					type: 'warning'
				}).then(() => {
					const api = "/trader/addTraderCert/insert";
					let certImgUrls = [];
					this.fileList.forEach(item => {
						certImgUrls.push(item.url)
					})
					const body = {
						traderId: sessionStorage.traderId,
						brandId: this.brandId,
						classifyId: this.classifyId,
						certImgs: certImgUrls,
					};
					axiosApi(api, body, (res) => {
						this.fileList = []; //清掉上传的凭证
						this.isAdd = false;
						this.findTraderCertList(); //刷新页面，加载已有上传的凭证
						this.$message({
							type: 'success',
							message: '上传成功!',
							duration: 1000
						});
					}, this.currentPage, 10, (res) => {
						this.$message({
							type: 'warning',
							message: res.data.message,
							duration: 1000
						});
					});
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消'
					});
				})

			}
		}
	}
</script>

<style lang="less">
	#brandsApply {
		.el-upload-list--picture-card .el-upload-list__item,
		.el-upload--picture-card {
			width: 120px;
			height: 90px;
		}
		.el-upload--picture-card {
			line-height: 90px;
		}
		.li:hover,
		.li.active {
			color: #FF4100;
		}
		.noAdd {
			.el-upload.el-upload--picture-card {
				display: none;
			}
		}
		.add {
			.el-upload.el-upload--picture-card {
				display: inline-block;
			}
		}
		.exilic {
			.el-upload--picture-card,
			.el-upload-list__item-delete {
				display: none;
			}
			.el-upload-list__item-status-label {
				display: none;
			}
			.credentials {
				position: relative;
				&::before {
					display: block;
					content: '\5F85\5BA1\6838';
					position: absolute;
					width: 50px;
					height: 38px;
					right: 0;
					line-height: 38px;
					color: #fff;
					background: rgba(0, 0, 0, 0.5);
					text-align: center;
				}
			}
		}
		.bgimg {
			display: flex;
			justify-content: flex-start;
			background: url(../../../assets/bg-dashed.png) 0px bottom;
			background-repeat: repeat-x;
		}
		.reduce {
			height: 38px;
			overflow: hidden;
		}
		.w750 {
			width: 750px;
		}
		.ha {
			max-height: 250px;
			overflow-y: scroll;
		}
		.hides {
			overflow: hidden;
			/*自动隐藏文字*/
			text-overflow: ellipsis;
			/*文字隐藏后添加省略号*/
			white-space: nowrap;
			/*强制不换行*/
			width: 20em;
			/*不允许出现半汉字截断*/
		}
	}
</style>