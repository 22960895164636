<template>
	<div id="shopOther">
		<div class="table bg-f">
			<div class="width-1280 plr-45 tab clearfix">
				<ul class="nav-w">
					<li class="fl cursor bg-danger" @mouseover="showClassifyListFn(true)" @mouseout="showClassifyListFn(false)">
						<span>全部商品</span>
						<i class="el-icon-caret-bottom font-14"></i>
						<div class="leftClassify clearfix fl bg-danger" v-show="showClassifyList">
							<ul class="nav cursor">
								<li class="pl-20 clearfix " v-for='(item,index) in classifyList' :key="index" @click="classifyClick()" @mouseover="classifyBrandOverOut(index,true)" @mouseout="classifyBrandOverOut(index,false)">
									<i class="iconfont font-16 color-white" :class="item.icon"></i>
									<span class="font-16 ml-10 color-white">{{item.classifyName}}</span>
									<i class="el-icon-arrow-right color-white font-12 color-9 fr mr-10 mt-20"></i>
								</li>
							</ul>
							<!--菜单展开-->
							<div class='navMain pl-50 bg-f' v-show="nava" @mouseover="nava = true" @mouseout.stop="show(false)">
								<p class="font-16 lh-16 mt-20" v-if="classifyName != '其他'">{{classifyName}}</p>
								<ul class="item">
									<li class="fl text-overflow pr-20 mt-20 color-6 font-14 lh-14 w-100 cursor hover-danger" v-for='(n,i) in brandList' :title="n.brandName" @click="classifyClick(n.id)" :key='i'>{{ n.brandName }}</li>
								</ul>
							</div>
						</div>
					</li>
					<li v-for="(item,index) in data" class="fl cursor" :key="index">{{item}}</li>
				</ul>
				<div class="switchClass">
					<span>库存为0：</span>
					<el-switch
						v-model="zeroShow"
						active-color="#ff4201"
						@change="setShow">
					</el-switch>
					<span style="margin-left:10px">{{ zeroShow?'隐藏': '显示' }}</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { axiosApi,basePath } from "../api/api";
	import {mapActions, mapMutations} from 'vuex'
	export	default{
		name: 'searchLR',
		data(){
			return{
				data:[],
				classifyList:[],
				showClassifyList: false,
				showCollectStoreBtn: false,
				nava:false,
				classifyList:[],
				classifyName:'白酒',
				classifyId: null,
				brandId: null,
				brandList:[],
				isloginUser: false,
				isHotel: false,
				zeroShow: localStorage.getItem('showZero')==='true'?true:false
			}
		},
		computed: {
			indexStatus(){
				return this.$store.state.homeSearch.classfilShow;
			},
			collectStoreBtn(){
				return this.$store.state.homeSearch.collectStoreBtn;
			},
			// isCollection(){
			// 	return this.$store.state.homeSearch.isCollection;
			// },
			// sellerId(){
			// 	return this.$store.state.homeSearch.sellerId;
			// },
			zeroShowInit(){ 
				return localStorage.getItem('showZero')==='true'?true:false
			}
		},
		watch:{
			indexStatus(val){
				this.showClassifyList = val;
			},
			collectStoreBtn(val){
				this.showCollectStoreBtn = val;
			},
			zeroShowInit(val) {
				this.zeroShow = val
			},
		},
		methods:{
			...mapActions({
				'getSearchGoodsList': 'getSearchGoodsList'
			}),
			...mapMutations(['setZeroShow']),
			setShow(val){
				this.setZeroShow(val)
			},
			getClassifyList(){//获取左侧分类信息
				this.categoryLoading = true;
				let api = "/unlogin/goods/classifyBrandQuery/select";
				let body = { sellerId: sessionStorage.getItem('sellerId')||null, limit:8 };
				axiosApi(api, body,(res) => {
		        	//console.log(res)
		        	this.classifyList = res.data.data;
	    			// this.categoryLoading = false
	    			
		        },1,10)
	        },
	        classifyClick(brandId){//点击分类或品牌
	        	this.brandId = !!brandId ? brandId : 'brand';
				this.$router.push(basePath+'/goodsSearch/'+this.classifyId+'/'+ this.brandId+'/goodsName');
	        },
	        showClassifyListFn(bool){
	        	if(this.indexStatus === true) return;
	        	this.showClassifyList = bool;
	        },
	        show(bool){
	        	if(this.indexStatus === true){
	        		this.nava = bool
	        		return
	        	};
	        	this.nava = bool;
	        	this.showClassifyList = bool;
	        },
	        classifyBrandOverOut(index,bool){
	        	this.nava = bool;
	        	if(!bool) return;
				this.classifyId = this.classifyList[index].id;
				this.classifyName = this.classifyList[index].classifyName;
				this.brandList = this.classifyList[index].brandList;
				// console.log(this.classifyId,this.classifyName)
	        }
		},
		mounted(){
			this.$nextTick(()=> {
				this.isloginUser = !sessionStorage.getItem('loginName') ? false : true;
				this.isHotel = sessionStorage.getItem('isHotel') === '1' && this.isloginUser ? true : false;
				if(this.indexStatus === true) this.showClassifyList = true;
				this.getClassifyList();
			})
		}
	}
</script>

<style lang="less">
	#shopOther .table{
		border-bottom: 2px solid #FF4201;
		.tab{
			height: 50px;
			line-height: 50px;
			text-align: center;
			display: flex;
			align-items: center;
			// justify-content: space-between;
			// .nav-w{
			// 	flex: 1;
			// }
			.switchClass{
				margin-left: 40px;
				display: flex;
				align-items: center;
			}
			.nav-w>li {
				width: 132px;
				color: #000;
			};
			.nav-w>li:nth-child(1) {
				width: 190px;
				color: #fff;
				position: relative;
				.leftClassify{
					position: absolute;
					width: 100%;
					height: 420px;
					left: 0;
					top: 50px;
					border-top: 2px solid #FF4201;
					z-index: 10;
					color: #333;
					text-align: left;
					.nav{
						padding: 10px 0;
					}
					/*菜单展开*/
					.navMain {
						width: 744px;
						height: 416px;
						position: absolute;
						border: 3px solid #e53a00;
						border-top: 1px solid #e53a00;
						left: 190px;
						top: 0;
						z-index: 999;
						cursor: default;
						overflow: auto;
						.w-100 {
							width: 80px;
						}
						// .item{
						// 	overflow: scroll;
						// }
					}
				}
				.nav li {
					width: 170px;
					height: 50px;
					line-height: 50px
				}
				.nav li:hover {
					background: #e53a00;
				}
			};
			.nav-w>li:hover{
				background: #FF4201;
				color: #fff;
			}
			
		}
		.el-switch__label.is-active {
				color: #FF4201;
		}
	}
</style>