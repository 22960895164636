<template>
    <div id="jicaiCreate" class="bg-f">
        <div class="width-1280 box-con bdt">
            <div class="title-top bg-f">
                发布招标信息
            </div>
            <div class="bg-f">
                <el-form class='ml-100' :model='form' label-width='130px' :rules="rules" ref="ruleForm">
                    <el-form-item label="招标名称:" prop='name'>
                        <el-input placeholder="输入招标名称" class='fl'
                                  v-model.trim="form.name"></el-input>
                    </el-form-item>

                    <el-form-item label="招标商品:">
                        <div v-if="goods.goodsNo">
                            <img v-if="goods.goodsUrl" class="fl icon-60 bd"
                                 :src="goods.goodsUrl"/>
                            <img v-else class="fl icon-60 bd" src="../../assets/goods.jpg"/>
                            <div class="ml-20 fl">
                                <p class="lh-16 mt-15 color-9">{{goods.goodsName}}</p>
                                <p class="lh-16 mt-15 color-9">商品编码：{{goods.goodsNo}}</p>
                            </div>
                            <span v-if="!isUpdate" class="color_blue cursor ml-20" @click="addGoods">修改</span>
                        </div>
                        <el-button v-else class="button_top tac mr-30 font-14" @click="addGoods">添加商品</el-button>
                    </el-form-item>

                    <el-form-item label="集采价格:">
                        <ul>
                            <li class="mb-10" v-for="(item,index) in form.priceList" :key="index">
                                <el-input :disabled="isUpdate" class="input_short_2" placeholder="输入箱数"
                                          v-model.trim="item.qty" type="text" :maxlength="8"></el-input>
                                <span>箱</span>
                                <el-input :disabled="isUpdate" class="input_short_2" placeholder="输入价格"
                                          v-model.trim="item.price" type="text"
                                          :maxlength="8"></el-input>
                                <span>元/瓶</span>
                                <el-input :disabled="isUpdate" class="input_short_2" placeholder="输入返利"
                                          v-model.trim="item.rebate" type="text"
                                          :maxlength="5"></el-input>
                                <span>%</span>
                                <span class="color_blue cursor" @click="addPrice"
                                      v-if="index == form.priceList.length-1 & form.priceList.length<5&&!isUpdate">添加集采价</span>
                                <img class="icon-24" src="../../assets/jicai_icon_del.png" @click="delPrice(index)"
                                     v-else-if="!isUpdate"/>
                            </li>
                        </ul>
                    </el-form-item>

                    <el-form-item label="招标时间:" prop="date">
                        <el-date-picker :disabled="isUpdate" v-model="form.startTime" type="date" placeholder="选择日期"
                                        :picker-options="pickerOptions"/>
                    </el-form-item>

                    <el-form-item label="招标地区:">
                        <div v-if="regionText">{{regionText}}</div>
                        <el-button v-if="!isUpdate" class="button_top tac mr-30 font-14" @click="showDialog">选择招标区域
                        </el-button>
                    </el-form-item>

                    <el-form-item label="招标名额:" prop="personCount">
                        <el-input placeholder="输入招标人数" class="input_short_1"
                                  v-model.trim="form.personCount"></el-input>
                        人
                    </el-form-item>

                    <el-form-item label="合约时间:">
                        <el-select size='mini' v-model.trim="form.haoLong" placeholder="请选择">
                            <el-option
                                    :disabled="isUpdate"
                                    v-for="item in options"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                            </el-option>
                        </el-select>
                        年
                    </el-form-item>

                    <div class="mt-50 ml-55 pl-10">
                        <div class="clearfix">
                            <label class="mr-10 fl">招标说明:</label>
                            <ueditor ref="ueeditor" v-model="form.illustrationText"></ueditor>
                        </div>
                    </div>

                    <el-form-item label="" class='mt-50'>
                        <el-button style="z-index: 0;" type='danger' @click="commit">提交</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
        <!-- 添加商品组件 -->
        <chooseGoods
                v-on:closedialog="dialog1Visible = false"
                :goodVisible="dialog1Visible"
                :ifnotauto="true"
                :from-jicai="true"
                :hidePrice="true"
        >
        </chooseGoods>
        <address-picker
                v-show="dialogIsShow"
                :dialogIsShow="dialogIsShow"
                @closeDialog="closeDialog"/>
    </div>
</template>

<script>
    import {mapMutations, mapActions} from 'vuex'
    import {axiosApi, basePath} from "~/api/api"

    export default {
        data() {
            var checkPersonCount = (rule, value, callback) => {
                if (!value) {
                    return callback(new Error('招标人数不能为空'));
                } else if (value < 1) {
                    callback(new Error("不能小于1"));
                } else if (!/^[0-9]*$/.test(value)) {
                    callback(new Error("只能用数字"));
                } else if (value > 100) {
                    callback(new Error("不能超过100"));
                } else if (this.isUpdate && this.personCountUpdate > value) {
                    callback(new Error("不能小于原来的值"));
                } else {
                    callback();
                }
            };
            return {
                pickerOptions: {
                    disabledDate(time) {
                        let curDate = (new Date()).getTime();
                        let ten = 10 * 31536000 * 1000 + 24 * 3600 * 1000 * 3;
                        let TenYear = curDate + ten;
                        return time.getTime() + 24 * 3600 * 1000 < Date.now() || time.getTime() > TenYear;
                    }
                },
                dialog1Visible: false, // 添加商品浮层
                dialogIsShow: false,//地址弹窗
                btnLoading: false,
                regionText: null,
                isUpdate: false,//是不是编辑
                personCountUpdate: 0,//编辑的时候 数量不能小于原来的数据
                form: {
                    name: null,
                    priceList: [{
                        qty: null,
                        price: null,
                        rebate: null
                    }],
                    startTime: null,
                    area: null,
                    personCount: 3,
                    haoLong: 1,
                    illustrationText: '',// 富文本信息
                },
                goods: {
                    goodsName: null,
                    goodsUrl: null,
                    goodsNo: null
                },
                options: [{
                    value: '1',
                    label: '1'
                }, {
                    value: '2',
                    label: '2'
                }, {
                    value: '3',
                    label: '3'
                }],
                value: '',
                rules: {
                    name: [{message: '请输入招标名称'}, {min: 1, max: 30, message: '招标名称最多30个字符'}],
                    qty: [{min: 1, max: 5, message: '箱数长度在5位以内', trigger: 'blur'}],
                    date: [{message: '请选择日期'}],
                    personCount: [{validator: checkPersonCount, trigger: 'blur'}],
                }
            };
        },
        computed: {
            postDatagood() {//已选择商品id
                return {
                    data: {
                        notId: this.$store.getters.willaddid,
                    },
                    currentPage: 1
                }
            },
            goodsData() {
                return this.$store.state.ticket.willadd
            }
        },
        watch: {
            goodsData(val, oldVal) {
                if (val[0]) {
                    this.goods.goodsUrl = val[0].fileUrl;
                    this.goods.goodsName = val[0].skuName
                    this.goods.goodsNo = val[0].skuNo
                }
            }
        },
        methods: {
            ...mapActions({
                'queryJicaiData': 'queryJicaiData',
            }),
            ...mapMutations({
                'clearwilladd': 'clearwilladd',
            }),
            addPrice() {
                this.form.priceList.push({qty: null, price: null, rebate: null});
            },
            delPrice(index) {
                this.form.priceList.splice(index, 1);
            },
            addGoods() {// 添加商品
                this.dialog1Visible = true;
                this.queryJicaiData(this.postDatagood)
            },
            commit() {
                this.$refs.ruleForm.validate((v) => {
                    if (v) {
                        let type = false;//价格是否有个问题
                        if (this.goods.goodsNo == null) {
                            this.$message.error("请选择商品")
                            return false;
                        }
                        this.form.priceList.forEach(itme => {
                            if (!itme.qty || !itme.price) {
                                this.$message.error("集采价格不完整")
                                type = true;
                                return false
                            }
                            if (!/^[0-9]*$/.test(itme.qty)) {
                                this.$message.error('箱只能用整数')
                                type = true;
                                return false
                            }
                            if ((!/^-?\d+\.?\d{0,2}$/.test(itme.price))) {
                                this.$message.error('价格只能用两位小数')
                                type = true;
                                return false
                            }
                            if ((!/^-?\d+\.?\d{0,2}$/.test(itme.rebate))) {
                                this.$message.error('返利只能用两位小数')
                                type = true;
                                return false
                            }
                        })
                        if (type) {
                            return false;
                        }
                        if (!this.form.startTime) {
                            this.$message.error("请设置招标时间");
                            return false;
                        }
                        if (!this.form.area) {
                            this.$message.error("请设置招标地区")
                            return false
                        }

                        this.form.illustrationText = this.$refs.ueeditor.getContent();
                        let body = {
                            id: this.$route.query.id ? this.$route.query.id : null,
                            name: this.form.name,
                            skuNo: this.goods.goodsNo,
                            bidPriceList: this.form.priceList,
                            bidDateFrom: this.form.startTime,
                            allArea: this.form.area,
                            limitBitNum: this.form.personCount,
                            validPeriod: this.form.haoLong,
                            illustrationContent: this.form.illustrationText
                        }
                        this.insert(body) // 添加
                            .then(res => {
                                this.$message.success('添加集采成功！')
                                this.$router.push({path: 'jicaiList'})
                            })
                            .catch(res => {
                                this.$message.error(res.data.message)
                            })
                    }
                })
            },
            showDialog() {
                this.dialogIsShow = true;
            }
            ,
            closeDialog(val) {
                if (val) {
                    this.form.area = val;
                    this.regionText = this.resolveValueToText_4level(val)
                }
                this.dialogIsShow = false
            }
            ,
            insert(body) {// 新建套餐接口
                this.btnLoading = true
                return new Promise((resolve, reject) => {
                    axiosApi(this.$route.query.id ? '/trader/bid/update' : '/trader/bid/add', body, res => {
                        resolve(res)
                        this.btnLoading = false
                    }, 1, 10, res => {
                        reject(res)
                        this.btnLoading = false
                    })
                })
            }
            ,
            getDetail() {
                if (this.$route.query.id) {
                    axiosApi('/trader/bid/detail/seller', {
                        id: this.$route.query.id,
                        needAreaInfo: true
                    }, (res) => {
                        let Data = res.data.data ? res.data.data : {};
                        if (Data.state == 1) {
                            this.isUpdate = true;
                        }
                        this.form.name = Data.name;
                        this.form.priceList = Data.bidPriceList
                        this.form.startTime = Data.bidDateFrom
                        this.form.area = Data.allArea
                        this.form.personCount = Data.limitBitNum
                        this.personCountUpdate = Data.limitBitNum
                        this.form.haoLong = Data.validPeriod
                        this.form.illustrationText = Data.illustrationContent
                        this.$refs.ueeditor.setContent(Data.illustrationContent);
                        this.goods.goodsUrl = Data.skuImage
                        this.goods.goodsName = Data.skuName
                        this.goods.goodsNo = Data.skuNo
                        this.regionText = Data.allDistrictName
                    }, this.currentPage, 10, (res) => {
                        this.$message.error(res.data.message)
                    })
                }
            }
            ,
        },
        mounted() {
            this.$nextTick(function () {
                this.clearwilladd();
                this.getDetail();
            })
        }
        ,
    }
</script>

<style lang="less" scoped>
    #jicaiCreate {
        #own {
            width: 800px !important;
        }
        .bdt {
            border-top: 1px solid #eee;
        }

        .box-con {
            padding: 30px 125px 50px 125px;
        }

        .button_top {
            width: 238px;
            height: 30px;
            line-height: 30px;
            border: 1px solid #DDDDDD;
            color: #20a0ff
        }

        .color_blue {
            color: #20a0ff
        }

        .input_short_2 {
            width: 150px;
        }

        .input_short_1 {
            width: 120px;
        }

        .icon-24 {
            width: 24px;
            height: 24px;
        }
    }
</style>
