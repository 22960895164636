<template>
	<div id="people" class="">
		<div class="title-top bg-f mb-5">
			员工管理
			<el-button v-if="showButton('ADD',100063)" class="fr ml-0 mr-30 mt-14" @click.native="change1()">新增员工</el-button>
		</div>
		<div class="main bg-f pb-50 pl-30 pr-30">
			<el-form :inline="true" :model="form" label-width="90px" class="clearfix pt-30">
				<div>
					<el-form-item label="用户名">
						<el-input v-model.trim="form.loginName" @keyup.enter.native='achieve(true)'></el-input>
					</el-form-item>
					<el-form-item label="员工姓名">
						<el-input v-model.trim="form.employeeName" @keyup.enter.native='achieve(true)'></el-input>
					</el-form-item>
					<el-form-item label="所属部门">
						<el-cascader @keyup.enter.native='achieve(true)' :show-all-levels="false" clearable change-on-select :options="searchDepartmentList" :props="config" v-model="emptyId"></el-cascader>
					</el-form-item>
				</div>
			</el-form>
            <div class="pt-5 pb-30">
                <el-button type="info" icon="el-icon-search" class="ml-20" @click='achieve(true)'>搜索</el-button>
            </div>
			<div  class="topInfo">
				<span class=" color-blue cursor mr-60 " v-if="showButton('DEL',100063)" @click="getIds()">批量删除</span>

				<!-- 导入模块 -->
				<el-upload
				class="color-blue cursor mr-60"
				action="/sunrise-gateway/import/foundation/employee"
				:show-file-list="false"
				:on-success="handUpSuccess"
				:before-upload="handUpBefore"
				:on-error="handUpError"
				accept=".xls,.xlsx"
                 >
					<el-link type="primary" :underline="false" :loading="upLoading">员工信息批量导入</el-link>
					<!-- <img class="exportImg" src="/src/assets/E_card/download.png" alt=""> -->
        </el-upload>
				<el-link type="primary" class="mr-60" href="https://sunrise-app.oss-cn-shanghai.aliyuncs.com/template/ys/employee/employeeImport.xlsx" :underline="false">下载导入模板</el-link>
				<el-link class="link"  type="primary" :underline="false" @click="exportInfo">导出员工</el-link>
			</div>
			<el-table :data="tableData" v-loading="Loading" row-key="id" class='mt-10 table-default' @selection-change="handleSelectionChange">
				<el-table-column type="selection"></el-table-column>
				<el-table-column label="用户名" prop="loginName" min-width="100">
					<template slot-scope="{row}">
						<span>{{ row.loginName}} <el-tag v-if="row.isAdmin == 1" effect="dark" type="danger" size="mini">主账号</el-tag></span>
					</template>
				</el-table-column>
				<el-table-column label="员工姓名" prop="employeeName" min-width="100"></el-table-column>
				<el-table-column label="所属部门" prop="departmentName" min-width="100"></el-table-column>
				<el-table-column label="角色" min-width="80">
					<template slot-scope="{row}">
						<span>{{ row.roleName?row.roleName:'--' }}</span>
					</template>
				</el-table-column>
				<el-table-column label="职位"  min-width="80">
					<template slot-scope="{row}">
						<span>{{ row.position?row.position:'--' }}</span>
					</template>
				</el-table-column>
				<el-table-column label="手机" prop="mobile" min-width="100"></el-table-column>
				<el-table-column label="状态" min-width="80">
					<template slot-scope="scope">
						<span>{{scope.row.isDisable=="0"?'正常':'禁用'}}</span>
					</template>
				</el-table-column>
				<el-table-column label="操作" min-width="220">
					<template slot-scope="scope">
						<div>
							<el-link :underline="false"  class="itemMargin"  v-if="showButton('EDIT',100063)" size="small" type='primary' @click.native="change(scope.$index, tableData,'editPeople')">编辑</el-link>
							<el-link :underline="false"  class="itemMargin"  v-if="scope.row.bindingDeviceNo && scope.row.allowDeviceFree==0"  size="small" type='primary' @click.native="untiePhone(scope.$index, scope.row)">解绑手机</el-link>
							<el-link :underline="false"  class="itemMargin"  v-if="scope.row.managerId && !isDealerUser"  size="small" type='primary' @click.native="transferCustomer(scope.row)">客户迁移</el-link>
							<el-link :underline="false"  class="itemMargin"  v-if="showButton('DEL',100063)" size="small" type='primary' @click.native="deleteRows(scope.$index, tableData)">删除</el-link>
							<el-link :underline="false"  class="itemMargin"  v-if="!scope.row.managerId || scope.row.managerId == 0" size="small" type='primary' @click.native="dredgeApp(scope.$index, scope.row)">开通云销APP</el-link>
							<!--<el-button class='mr-30 ml-0' size="small" type='text' @click.native="deleteRows(scope.$index, tableData)">开通云销APP</el-button>-->
							<el-link type="success" v-if="scope.row.isDisable==1" class="itemMargin" :underline="false" @click="editDisable(scope.row.id,1)">启用</el-link>
              <el-link type="danger" v-else class="itemMargin" :underline="false" @click="editDisable(scope.row.id,0)">禁用</el-link>
						</div>
						<div v-if="!scope.row.isAdmin">
							<el-link :underline="false"  class="itemMargin" v-if="!scope.row.isOpenCloudStoreApp"  size="small" type='primary' @click="openOrCloseYun(scope.row.id,1)">开通云商APP</el-link>
							<el-link :underline="false"  class="itemMargin" v-else size="small" type='primary' @click="openOrCloseYun(scope.row.id,0)">关闭云商APP</el-link>
						</div>
					</template>
				</el-table-column>
			</el-table>

			<el-pagination v-if="total>0" class="tar mr-30" :current-page="currentPage" :page-sizes="[10, 20, 50, 100, 1000]" :page-size='pageSize' :total='total' layout="sizes, prev , pager, next, jumper" @current-change="handleCurrentChange" @size-change="SizeChange" >
				<!-- 分页组件 -->
			</el-pagination>


			 <!-- 导入错误弹框 -->
			<el-dialog
				title="批量导入"
				:visible.sync="upShow"
				width="70%"
				:close-on-click-modal="false"
				:close-on-press-escape="false"
			>
				<el-alert
					title="部分订单已成功导入，以下为导入失败的订单！"
					type="error"
					show-icon
					:closable="false"
				>
				</el-alert>
				<div class="divInfo">
					<el-card class="boxCard" v-for="(item, index) in errList" :key="index">
						<div slot="header" class="clearfix">
							<span>{{ item.msg }}</span>
						</div>
						<div class="tabFlex tabTop">
							<span class="tabSpan">员工姓名</span>
							<span class="tabSpan">所属部门</span>
							<span class="tabSpan">角色</span>
							<span class="tabSpan">职位</span>
							<span class="tabSpan">手机</span>
						</div>
						<div
							class="tabFlex tabRow"
						>
							<span class="tabSpan">{{ item.errorExcelParam.employeeName }}</span>
							<span class="tabSpan">{{ item.errorExcelParam.departmentName }}</span>
							<span class="tabSpan">{{ item.errorExcelParam.roleName }}</span>
							<span class="tabSpan">{{ item.errorExcelParam.position }}</span>
							<span class="tabSpan">{{ item.errorExcelParam.mobile }}</span>
						</div>
					</el-card>
				</div>

				<span slot="footer" class="dialog-footer">
					<el-button type="primary" @click="upShow = false">确 定</el-button>
				</span>
			</el-dialog>

			<!-- 导入错误弹框 -->
			<el-dialog
				title="批量导入"
				:visible.sync="upTextShow"
				width="70%"
				:close-on-click-modal="false"
				:close-on-press-escape="false"
			>
				<el-alert
					title="表格解析失败，所有订单并未导入！请检查以下内容，修改后重新导入！"
					type="error"
					show-icon
					:closable="false"
				>
				</el-alert>
				<div class="divInfo">
					<div
						v-for="(item, index) in errTextList"
						:key="index"
						style="margin: 0 20px"
					>
						<span>{{ item }}</span>
						<el-divider></el-divider>
					</div>
				</div>

				<span slot="footer" class="dialog-footer">
					<el-button type="primary" @click="upTextShow = false">确 定</el-button>
				</span>
			</el-dialog>

			<!-- 客户迁移弹窗 -->
			<el-dialog title="客户迁移" :visible.sync="transferCustomerDialog" @close="closeDialogFun">
				<div class="main-area">
					<div class="left">
						<span>{{oldCustomer}}的客户({{leftCustomers.length}})</span>
						<div class="left-content">
							<el-form :inline="true" :model="formInlineLeft" class="demo-form-inline">
								<el-form-item label="">
									<el-input size="small" v-model="formInlineLeft.leftCustomer" placeholder="商户名称">
										<div slot="append" @click="searchLeft">搜索</div>
									</el-input>
								</el-form-item>
							</el-form>
							<el-checkbox-group v-if="leftCustomers.length" v-model="checkedCustomer" @change="handleCheckedCustomersChange">
								<el-checkbox v-for="cu in leftCustomers" :label="cu.buyerId" :key="cu.buyerId">{{cu.storeName}}</el-checkbox>
							</el-checkbox-group>
							<div class="no-customer" v-else>暂无数据</div>
							<div class="select-all-wrap" v-if="leftCustomers.length">
								<el-checkbox v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
							</div>
						</div>
					</div>
					<div class="center"><p>迁移至</p></div>
					<div class="left right">
						<span>其他业务员</span>
						<div class="left-content right-content">
							<el-form :inline="true" :model="formInlineRight" class="demo-form-inline">
								<el-form-item label="">
									<el-input size="small" v-model="formInlineRight.rightSale" placeholder="业务员名称">
										<div @click="searchRight" slot="append">搜索</div>
									</el-input>
								</el-form-item>
							</el-form>
							<el-radio-group v-model="radio1" v-if="rightSales.length">
								<el-radio v-for="sale in rightSales" :key="sale.managerId" :label="sale.managerId">{{sale.name}}</el-radio>
							</el-radio-group>
							<div class="no-customer" v-else>暂无数据</div>
						</div>
					</div>
				</div>
				<span slot="footer" class="dialog-footer">
					<el-button :disabled="leftCustomers.length == 0" type="primary"  @click="ensureTransfer">确定</el-button>
					<el-button @click="cancelTransfer">取消</el-button>
				</span>
			</el-dialog>
		</div>
	</div>
</template>

<script>
	import { axiosApi, basePath } from "../../../api/api";
	import { exportInfo } from "~/api/download";

	export default {
		data() {
			return {

				//员工批量导入更新：zwl
                upLoading: false,

				// isDealerUser:sessionStorage.getItem('isDealerUser')=='true',
				Loading:true,
				form: { //搜索条件
					id: '',
					loginName: '',
					employeeName: '',
					department: '',
					departmentId: []
				},
				tableData: [], //员工列表
				searchDepartmentList: [], //搜索部门
				//				分页
				currentPage: 1,
				pageSize: 10,
				total: 0,
				isPagination: true,
				config: {
					label: "text",
					children: "sub",
					value: "id"
				},
				multipleSelection: [], //选中所有行
				ids: [], //批量删除所以id
				admin: [],
				emptyId: [],
				isadmin: true,
				transferCustomerDialog: false, //客户迁移模态框
				formInlineLeft: {
					leftCustomer: ''
				},
				leftCustomers: [], //客户迁移左侧商户
				checkedCustomer: [],
				checkAll: false,
				rightSales: [], //客户迁移右侧业务员
				formInlineRight: {
					rightSale: ''
				},
				radio1: '',
				row: null,
				oldCustomer: '',
				oldManagerId: '',
				upTextShow: false,
				upShow:false,
				errList: [],
				errTextList: []
			}
		},
		methods: {
			//导出
			exportInfo() {
				var api = '/export/foundation/employee'

				const body = {
					loginName: this.form.loginName,
					employeeName: this.form.employeeName,
					departmentId: this.emptyId[this.emptyId.length - 1],
					applicationType: 2,
					traderId: sessionStorage.traderId,
					isDeleted: 0,
					idList: this.ids.join()
				}
				body.modelName = 'empty',
				body.startRow = 0
				body.fileName = '员工列表.xls'
				exportInfo(api,body).then((res) => {
						
				}).catch((err) => {
						
				})
			},
            // 员工批量导入
			handUpBefore(file) {
				this.$loading({
				fullscreen: true,
				lock: true,
				text: "正在导入...",
			});
			return true;
				
			},
			handUpSuccess(res) {
				if (res.code === "000000") {
				if (res.data.code === "0") {
				this.$message({
					message: "导入成功!",
					type: "success",
				});
				} else if (res.data.code === "1") {
				// 员工新增异常
				this.errList = res.data.resultList;
				this.upShow = true;
				this.$message({
					message: "员工新增异常！导入失败！",
					type: "error",
				});
				} else if (res.data.code === "2") {
				//解析构造数据异常
				this.errTextList = res.data.resultBuildMsg;
				this.upTextShow = true;
				this.$message({
					message: "解析构造数据异常！导入失败！",
					type: "error",
				});
				}
				this.achieve()
			} else {
				this.$confirm(res.message, "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning",
				})
				.then(() => {})
				.catch(() => {});
			}
			this.$loading().close();
			},
			handUpError(err, file, fileList) {
				this.$loading().close();
                this.$message.error("导入失败！");
			},
 

			openOrCloseYun(id,isOpenCloudStoreApp){
				this.$confirm(`确定${isOpenCloudStoreApp ? '开通' : '关闭'}该员工登录云商APP的权限吗`, '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					axiosApi('/foundation/employee/isOpenCloudStoreApp',{ id,isOpenCloudStoreApp },res => {
						this.$notify({
							title: '成功',
							type:'success',
							message:'操作成功',
							duration:1000
						});
						this.achieve();
					},1,10,err => {
						this.$message.warning(err.data.message)
					})
				}).catch(() => {});
			},
			change: function(index, rows, path) {
				if(path == "editPeople") {
					this.$router.push(basePath + '/editPeople?id=' + this.tableData[index].loginName)
				}
			},
			change1: function() {
				this.$router.push(basePath + '/addPeople')
			},
			//加载员工列表/查询
			achieve(bool) {
				if(bool&&this.currentPage != 1) {
					this.currentPage = 1;
				}
				const api = "/foundation/employee/selectAll";
				const body = {
					loginName: this.form.loginName,
					employeeName: this.form.employeeName,
					departmentId: this.emptyId[this.emptyId.length - 1],
					applicationType: 2,
					traderId: sessionStorage.traderId,
					isDeleted: 0
				}
				axiosApi(api, body, this.callback, this.currentPage, this.pageSize)
			},
			callback(res) {
				this.tableData = res.data.data.elements
				this.total = res.data.data.totalCount
				this.Loading = false
			},
			//查询部门
			searchDepartment() {
				const api = "/foundation/department/displayTree";
				const body = {
					applicationType: 2,
					traderId: sessionStorage.traderId
				}
				axiosApi(api, body, this.callback1, this.currentPage, 10)
			},
			callback1(res) {
				this.searchDepartmentList = res.data.data ? res.data.data : [];
				this.searchDepartmentList = this.cascaderClear(this.searchDepartmentList)
			},
			editDisable(id,type){
				this.$confirm(`此操作将${type?'启用':'禁用'}该员工, 是否继续?`, '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					if(type){
						this.enable(id)
					}else{
						this.disable(id)
					}
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});          
				});
			},
			// 启用员工
			enable(id){
				axiosApi('/foundation/employee/enable',{id:id},res => {
					this.$message({
						type: "success",
						message: "启用成功!"
					});
					this.achieve();
				});
			},
			// 禁用员工
			disable(id){
				axiosApi('/foundation/employee/disable',{id:id},res => {
					this.$message({
						type: "success",
						message: "禁用成功!"
					});
					this.achieve();
				});
			},
			//删除员工
			deleteRows(index, rows) {
				let that = this;
				if(rows[index].managerId) {
					//开通云销app的
					this.achieveLeft(rows[index])
					.then((aa) => {
						let msg = that.isDealerUser ? '该业务员已绑定相关的客户，暂无法删除' : '需要迁移相关业务员的所有客户后才能删除';
						if(that.leftCustomers.length) {
							this.$message({
								type: 'warning',
								message: msg,
								duration: 2500
							});
						} else {
							this.$confirm('您是否要删除员工「' + this.tableData[index].employeeName + '」？', '提示信息', {
								confirmButtonText: '确定',
								cancelButtonText: '取消',
								type: 'warning'
							}).then(() => {
								if(this.tableData[index].isAdmin == 0) { //不是超级管理员
									const api = "/foundation/employee/delete";
									const body = { ids: [this.tableData[index].id] };
									axiosApi(api, body, (res) => {
										this.$message({
											type: 'success',
											message: '删除成功!',
											duration: 1000
										});
										that.achieve();
									}, this.currentPage, 10, (res) => {
										this.$message({
											type: 'info',
											message: res.data.message,
											duration: 3000
										});
									});
								} else {
									this.$message({
										type: 'warning',
										message: '当前员工不可删除',
										duration: 2000
									});
								}
							}).catch(() => {
								this.$message({
									type: 'info',
									message: '已取消删除',
									duration: 2000
								});
							})
						}
					})
				} else {
					//没开通云销app
					this.$confirm('您是否要删除员工「' + this.tableData[index].employeeName + '」？', '提示信息', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						if(this.tableData[index].isAdmin == 0) { //不是超级管理员
							const api = "/foundation/employee/delete";
							const body = { ids: [this.tableData[index].id] };
							axiosApi(api, body, (res) => {
								this.$message({
									type: 'success',
									message: '删除成功!',
									duration: 1000
								});
								that.achieve();
							}, this.currentPage, 10, (res) => {
								this.$message({
									type: 'info',
									message: res.data.message,
									duration: 3000
								});
							});
						} else {
							this.$message({
								type: 'warning',
								message: '当前员工不可删除',
								duration: 2000
							});
						}
					}).catch(() => {
						this.$message({
							type: 'info',
							message: '已取消删除',
							duration: 2000
						});
					})
				}
//				this.$confirm('您是否要删除员工「' + this.tableData[index].employeeName + '」？', '提示信息', {
//					confirmButtonText: '确定',
//					cancelButtonText: '取消',
//					type: 'warning'
//				}).then(() => {
//					if(this.tableData[index].isAdmin == 0) { //不是超级管理员
//						const api = "/foundation/employee/delete";
//						const body = { ids: [this.tableData[index].id] };
//						axiosApi(api, body, (res) => {
//							this.$message({
//								type: 'success',
//								message: '删除成功!',
//								duration: 1000
//							});
//							that.achieve();
//						}, this.currentPage, 10, (res) => {
//							this.$message({
//								type: 'info',
//								message: res.data.message,
//								duration: 3000
//							});
//						});
//					} else {
//						this.$confirm('当前员工不可删除', '提示信息', {
//							confirmButtonText: '确定',
//							cancelButtonText: '取消',
//							type: 'warning'
//						})
//					}
//				}).catch(() => {
//					this.$message({
//						type: 'info',
//						message: '已取消删除'
//					});
//				})
			},
			//批量选中
			handleSelectionChange(val) {
				let _this = this
				this.multipleSelection = val

				var ids = []
				var admin = []
				this.multipleSelection.forEach(function(item, index) {
					ids.push(item.id)
					admin.push(item.isAdmin)
				})
				_this.ids = ids
				_this.admin = admin
			},
			//批量选中获取值
			getIds() {
				let _this = this
				this.deleteall()
			},
			//批量删除员工
			deleteall() {
				for(let i = 0; i < this.admin.length; i++) {
					if(this.admin[i] == 1) {
						this.isadmin = false;
						break;
					}
				}
				let that = this;
				this.$confirm('您是否要删除选中的员工？', '提示信息', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					if(this.isadmin == true) { //不是超级管理员
						const api = "/foundation/employee/delete";
						const body = { ids: this.ids };
						axiosApi(api, body, (res) => {
							if(this.ids.length > 0) {
								this.$message({
									type: 'success',
									message: '删除成功!',
									duration: 1000
								});
							} else {
								this.$notify({
									type: 'warning',
									message: '您未选中任何员工!',
									duration: 1000
								});
							}
							this.ids = []
							that.achieve();
						}, this.currentPage, 10, (res) => {
							this.$notify({
								type: 'warning',
								message: '您未选中任何员工!',
								duration: 1000
							});
						});
					} else {
						this.$confirm('当前员工不可删除', '提示信息', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						})
					}

				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				})
			},
			//			分页
			handleCurrentChange(val) {
				this.currentPage = val
				this.achieve()
			},
			SizeChange(val){
				this.pageSize = val
				this.currentPage = 1
				this.achieve()
			},
			//开通云销APP
			dredgeApp(index, rows) {
				let that = this;
				this.$confirm('您是否要开通云销APP？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
						const api = "/unlogin/trader/manager/insert";
						const body = {
							mobile:rows.mobile,
							name:rows.employeeName,
							employeeId: rows.id,
							type:3,
							classType:1,
							traderId:rows.traderId
						};
						axiosApi(api, body, (res) => {
							this.$message({
								type: 'success',
								message: '开通成功!',
								duration: 1000
							});
							that.achieve();
						}, this.currentPage, 10, (res) => {
							this.$message({
								type: 'info',
								message: res.data.message,
								duration: 3000
							});
						});
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消开通'
					});
				})
			},
			untiePhone(index, row){//解绑手机
				let that = this;
				this.$confirm('确定要解绑该手机么？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
						const api = "/foundation/appdevice/rebinding";
						const body = { id: row.id };
						axiosApi(api, body, (res) => {
							this.$message({
								type: 'success',
								message: '解绑成功!',
								duration: 1000
							});
							that.achieve();
						}, this.currentPage, 10, (res) => {
							this.$message({
								type: 'info',
								message: res.data.message,
								duration: 3000
							});
						});
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消解绑'
					});
				})
			},
			//客户迁移
			transferCustomer(row) {
				this.row = row
				this.oldCustomer = row.employeeName
				this.oldManagerId = row.managerId
				this.transferCustomerDialog = true
				this.achieveLeft(row)
				this.achieveRight(row)
			},
			closeDialogFun() { //关闭回调
				this.transferCustomerDialog = false
				this.checkAll = false
				this.checkedCustomer = []
				this.radio1 = ''
				this.formInlineLeft.leftCustomer = ''
				this.formInlineRight.rightSale = ''
			},
			ensureTransfer() { //确定
				if (this.checkedCustomer.length == 0) {
					this.$message({
						type: 'warning',
						message: '请先选择客户!',
						duration: 1000
					});
				} else if (this.radio1 == '') {
					this.$message({
						type: 'warning',
						message: '请先选择其他业务员!',
						duration: 1000
					});
				} else {
					let body2 = []
					this.checkedCustomer.forEach(item => {
						body2.push({
							sellerId: sessionStorage.getItem('traderId'),
							buyerId: item,
							mangerId: this.radio1,
							oldManagerId: this.oldManagerId
						})
					})
					axiosApi('/trader/manager/migrate ', body2, res => {
						this.transferCustomerDialog = false
						this.$message({
							type: 'success',
							message: '迁移成功!',
							duration: 1000
						})
					},1,10, err => {
						this.transferCustomerDialog = false
					})
				}

			},
			cancelTransfer() { //取消
				this.transferCustomerDialog = false
			},
			achieveLeft(row) {
				let flag = this.isDealerUser;
				let url = flag ? '/dealer/buyer/managerSelect ' : '/trader/trader/select';
				let body = {
					managerId: row.managerId
				};
				if(!flag){
					body.sellerId = sessionStorage.getItem('traderId');
					body.storeName =  this.formInlineLeft.leftCustomer
				}
				return new Promise((resolve, reject) => {
					axiosApi(url, body, res => {
						this.leftCustomers = res.data.data ? res.data.data : []
						this.checkAll = false
						this.checkedCustomer = []
						resolve(this.leftCustomers)
					},1,1000, err => {
						reject([])
					})
				})
			},
			achieveRight(row) {
				const api = '/trader/managerSeller/selectList'
				const body = {
					managerType: 3,
					sellerId: sessionStorage.getItem('traderId'),
					name: this.formInlineRight.rightSale
				}
				axiosApi(api, body, res => {
					if (res.data.data && res.data.data.elements) {
						this.rightSales = res.data.data.elements.filter(a => {
							return a.managerId !== row.managerId
						})
						this.radio1 = ''
					} else {
					  this.rightSales !=  []
					}
				}, 1, 1000)
			},
			searchLeft() {
				this.achieveLeft(this.row)
			},
			searchRight() {
				this.achieveRight(this.row)
			},
			handleCheckAllChange() {
				let array = []
				this.leftCustomers.forEach(item => {
					array.push(item.buyerId)
				})
				this.checkedCustomer = this.checkedCustomer.length == array.length ? [] : array
			},
			handleCheckedCustomersChange(value) {
				let checkedCount = value.length;
        		this.checkAll = checkedCount === this.leftCustomers.length;
			}
		},
		computed:{
			isDealerUser(){
				console.log(this.$store)
				return this.$store.state.clientType.isDealerUser;
			}
		},
		//进入加载
		activated() {
			this.$nextTick(function() {
				this.achieve();
				this.searchDepartment()
			})
		}
	}
</script>

<style lang="less">
	#people {
		.topInfo{
			display: flex;
			align-items: center;
			justify-content: flex-end;
		}
		.exportImg{
			position: relative;
			top: 5px;
			width: 15px;
			height:15px;
		}
		.el-form-item__label{
			font-size: 12px;
		}
		.el-button--default {
			margin-right: 0px;
		}
		.el-tabs__header {
			padding-left: 0;
		}
		.title-top {
			border: 0;
		}
		.mt-14{
			margin-top: 14px;
		}
		.mr-60{
			margin-right: 60px;
		}
		.black{
			.edit-btn span{
				color: #000000 !important;
			}
		}
		.el-dialog {
			width: 700px;
			top: 10% !important;
			.el-dialog__body {
				padding: 0 0 40px 0px;
				height: 500px;
				.el-input-group__append {
					background-color: #fff;
					color: #ff4201;
					cursor: pointer;
				}
			}
			.main-area {
				display: flex;
				padding: 0 50px;
				.left {
					width: 240px;
					height: 401px;
					padding: 10px;
					line-height: 36px;
					&>span {
						margin: 10px;
						font-size: 14px;
						color: #333;
					}
					.left-content {
						padding-top: 20px;
						border: 1px solid #eee;
						height: 380px;
						.el-form {
							padding: 0 20px;
						}
						.el-form-item {
							margin-bottom: 8px;
						}
						.el-checkbox-group {
							height: 300px;
							overflow-y: auto;
							padding: 0 20px;
							.el-checkbox {
								display: block;
								.el-checkbox__label {
									max-width: 198px;
									overflow: hidden;
									white-space: nowrap;
									text-overflow: ellipsis;
								}
							}
							.el-checkbox+.el-checkbox {
								margin-left: 0;
							}
						}
						.no-customer {
							padding: 0 20px;
							color: #666;
						}
						.select-all-wrap {
							border-top: 1px solid #eee;
							padding: 0 20px;
						}
						.el-radio-group {
							width: 198px;
							padding: 0 20px;
							height: 336px;
							overflow-y: auto;
							.el-radio {
								display: block;
								height: 32px;
								.el-radio__label {
									max-width: 198px;
									overflow: hidden;
									white-space: nowrap;
									text-overflow: ellipsis;
								}
							}
							.el-radio+.el-radio {
								margin-left: 0;
							}
						}
					}
				}
				.center {
					padding: 0;
					height: 362px;
					display: flex;
					align-items: center;
					margin-top: 40px;
					p{
						border: 1px solid #eee;
						border-radius: 5px;
						width: 90px;
						height: 32px;
						text-align: center;
						line-height: 32px;
					}

				}
			}
		}
		.itemMargin{
			margin: 2px 5px;
		}
		.boxCard {
      margin: 5px 20px;
      .tabTop {
        background: #eee;
        margin-bottom: 5px;
      }
      .tabRow {
        border-bottom: 1px solid #eee;
      }
      .tabFlex {
        display: flex;
        .tabSpan {
          flex: 1;
          padding: 5px 10px;
        }
      }
    }
	}
</style>
