import Home from '../views/home.vue'
import NotFound from '../views/notFound.vue'
import Order from '../views/supplier/order/order.vue' //订货单
import DraftOrder from '../views/supplier/order/draftOrder.vue' //草稿订单
import OrderSet from '../views/supplier/order/orderSet.vue' //订货单设置
import Register from '../views/register/register.vue' //注册
import Step1 from '../views/register/step1.vue' //第一步
import Step2 from '../views/register/step2.vue' //第二步
import Step3 from '../views/register/step3.vue' //第三步
import ForgetFirst from '../views/register/forgetFirst.vue' //忘记密码第一步
import ForgetSecond from '../views/register/forgetSecond.vue' //忘记密码第二步
import ForgetThird from '../views/register/forgetThird.vue' //忘记密码第三步
import GeneralSet from '../views/supplier/generalSet.vue' //通用设置
import StoreInformation from '../views/supplier/account/storeInformation.vue' //店铺信息
import AccountIndex from '../views/supplier/account/index.vue' //账户信息
import AccountSafe from '../views/supplier/account/accountSafe.vue' //账户安全
import ChangePassFirst from '../views/supplier/account/changePassFirst.vue' //修改密码第一步
import changePassSecond from '../views/supplier/account/changePassSecond.vue' //修改密码第2步
import changePassThird from '../views/supplier/account/changePassThird.vue' //修改密码第3步
import QualManage from '../views/supplier/account/qualManage.vue' //资质管理
import People from '../views/supplier/person/people.vue' //人员管理
import AddPeople from '../views/supplier/person/addPeople.vue' //新增员工
import EditPeople from '../views/supplier/person/editPeople.vue' //编辑员工
import AddPartment from '../views/supplier/person/addPartment.vue' //新增部门
import RoleLimits from '../views/supplier/person/roleLimits.vue' //角色权限
import EditRole from '../views/supplier/person/editRole.vue' //编辑角色
import AddRole from '../views/supplier/person/addRole.vue' //新增角色
import Department from '../views/supplier/person/department.vue' //部门管理
import ClientList from '../views/supplier/client/clientList.vue' //全部客户
import BrandsApply from '../views/supplier/brands/brandsApply.vue' //上传销售协议  第一次
import advertisingManage from '../views/superC/advertisingManage.vue' //广告管理
import ClientEdit from '../views/supplier/client/clientEdit.vue' //客户编辑
import PayMode from '../views/supplier/assetManagement/payMode.vue' //收款账号设置
import cloudCashOut from '../views/supplier/assetManagement/cloudCashOut.vue' //云账户提现流水
import StoreSupplier from '../views/store/stock/supplier.vue' //采购进货 我的供应商
import supplierStore from '../views/store/stock/supplierStore.vue' //供应商列表-出入库可选商家
import CommodityStocks from '../views/comm/commodityStocks.vue' //商品库存
import AllProduct from '../views/comm/allProduct.vue' //商品管理/全部商品
import ProductSet from '../views/comm/productSet.vue' //商品管理/商品设置
import AddProduct from '../views/comm/addProduct.vue' //商品管理/添加商品
import EditCommHome from '../views/comm/editCommHome.vue' //编辑商品主页
import UploadLicense from '../views/comm/uploadLicense.vue' //商品管理/上传销售许可
import LookLicense from '../views/comm/lookLicense.vue' //商品管理/查看销售许可
import StoreRecord from '../views/store/recordManagement/record.vue' // 进货商 发票管理
import Search1 from '../components/search1.vue'
import AccountCenter from '../views/supplier/accountCenter.vue' //账户中心
import ApplicationManage from "../views/supplier/applicationManage.vue";//应用管理
import ShopingCar from '../views/shop/shopingCar.vue' //购物车
import ConfirmOrder from '../views/shop/confirmOrder.vue' //商户 确认订单
import PurchaseOrder from '../views/store/stock/purchaseOrder.vue' //商户 已采购的订单
import Shop from '../components/shop.vue' //商城首页
import ShopIndex from '../components/shopIndex.vue' //商城首页导航
import HomePage from '../views/shop/homePage.vue' //商城首页内容
import GoodsSearch from '../views/shop/goodsSearch.vue' //商城搜索
import StockInvoice from '../views/store/stock/invoice.vue' // 采购进货  我的发票
import MyStockInvoice from '../views/store/stock/myinvoice.vue' // 采购进货  我的发票
import GoodsDetails from '../views/shop/goodsDetails.vue' //商品详情
import Address from '../views/supplier/address.vue' //收货地址
import accountSecurity from '../views/supplier/accountSecurity.vue'; // 账户安全 目录
import MyCollect from '../views/store/myCollect.vue' //我的收藏
import MyDiscount from '../views/store/myDiscount.vue' //我的优惠券
// import SupplierOrderDetail from '../views/shop/supplierOrderDetail.vue' //销售卖货订单详情
import SupplierChangeOrder from '../views/shop/supplierChangeOrder.vue' //销售卖货修改订单
import PurchaseOrderDetail from '../views/store/stock/purchaseOrderDetail.vue' //我的订单订单详情
import ccPurchaseOrderDetail from '../views/store/stock/ccPurchaseOrderDetail.vue' //我的订单订单详情
import PayOrder from '../views/shop/payOrder.vue' //采购进货付款页面
import StoreManage from '../views/shop/storeManage.vue' //店铺管理
import Activity from '../views/supplier/activity/activity.vue' //活动管理
import Addacticket from '../views/supplier/activity/addacticket.vue' //添加活动券
import Cutdetail from '../views/activities/acdetails/cutdetail.vue' //满减详情
import Sentdetail from '../views/activities/acdetails/sentdetail.vue' //满赠详情

import Ticketdetail from '../views/supplier/activity/ticketdetail.vue' //优惠券明细
import EachTicketdetail from '../views/supplier/activity/eachticketdetail.vue' //优惠券详情
import Activities from '../views/activities/activities.vue' //活动路由主页
import Addticket from '../views/activities/acdetails/addticket.vue' //添加优惠券
import Addshopticket from '../views/activities/acdetails/addshopticket.vue' //添加优惠券--店铺
import Addgoodticket from '../views/activities/acdetails/addgoodticket.vue' //添加优惠券--商品
import Addclientticket from '../views/activities/acdetails/addclientticket.vue' //添加优惠券--店铺
import Updateticket from '../views/activities/acdetails/updateticket.vue' //更新优惠券
import Addacaboutenoughcut from '../views/activities/acdetails/addacaboutenoughcut.vue' //添加满减活动
import Addacaboutenoughsent from '../views/activities/acdetails/addacaboutenoughsent.vue' //添加满赠活动
import updatecut from '../views/activities/acdetails/updatecut.vue' //更新满减活动
import updatesent from '../views/activities/acdetails/updatesent.vue' //更新满赠活动
import PayCertCheck from '../views/shop/payCertCheck.vue' //采购进货付款页面
import MessageCenter from '../views/activities/MessageCenter.vue' //消息中心
import MessageDetail from '../views/activities/messegeDetail.vue' //消息详情
import newsMessage from '../components/messageInfo.vue' //新闻公告消息内容
import Preview from '../views/activities/preview.vue' //装修预览
import Aggrement from '../components/aggrement.vue' //服务协议
import MyAccount from '../views/supplier/paymodule/myaccount.vue' //支付模块， 我的账户
import Public from '../views/supplier/paymodule/public.vue' //支付模块， 头部公用
import Bind from '../views/supplier/paymodule/bind.vue' //支付模块， 绑定手机
import TransactionRecord from '~/views/supplier/paymodule/transactionRecord.vue' // 所有交易记录
import NameVerify from '../views/supplier/paymodule/nameverify.vue' //实名认证
import VerifyResult from '../views/supplier/paymodule/verifyresult.vue' //实名认证结果
import Recharge from '../views/supplier/paymodule/recharge.vue' //充值頁面
import Withdrawal from '../views/supplier/paymodule/withdrawal.vue' //提现頁面
import Rechargemid from '../views/supplier/paymodule/rechargemid.vue' //充值中间页面
import Rechargemid2 from '../views/supplier/paymodule/rechargemid2.vue' //充值中间页面
import Setbank from '../views/supplier/paymodule/setbank.vue' //银行卡管理
import SetPayAccount from '../views/supplier/paymodule/setPayAccount.vue' //银行卡管理
import Alipay from '../views/supplier/paymodule/alipay.vue' //支付宝付款页面
import AlipayCom from '../views/supplier/paymodule/alipayCom.vue' // 支付宝 公共头部logo
import Cashier from '../views/shop/cashier.vue' // 在线支付
import AddStore from '../views/register/addStore.vue' //录入客户
import clientData from '../views/supplier/client/clientData.vue' //客户渠道、等级
import clientGrouping from '../views/supplier/client/clientGrouping.vue' //客户分组
import addAndEditGrouping from '../views/supplier/client/addAndEditGrouping.vue'; // 客户新增 和 修改
import clientAddress from '../views/supplier/client/clientAddress.vue' //客户收货地址管理
import regionList from '../views/supplier/client/regionList.vue'; // 区域设置 列表
import addAndEditRegion from '../views/supplier/client/addAndEditRegion.vue'; // 区域新增 和 修改
import newsPush from '../views/supplier/messagePush/newsPush.vue' //  消息推送
import smsPush from '../views/supplier/messagePush/smsPush.vue' //  短信推送
import historicalRecord from '../views/supplier/messagePush/historicalRecord.vue' //  历史记录
import setOrder from '../views/supplier/messagePush/setOrder.vue' //  订单设置
import CustomerConfig from "../views/supplier/client/customerConfig.vue" //客户录入配置
import ClientUpdate from "../views/register/clientUpdate.vue" //升级为注册用户
import {basePath} from '../api/api'

// 酒店相关router
import hotelHome from '../views/hotel/home.vue'; // 酒店折扣维护 首页
import hotelDetail from '../views/hotel/detail.vue'; // 详情
import hotelAdjustment from '../views/hotel/adjustment.vue'; // 到期调整
import hotelEdit from '../views/hotel/edit.vue'; // 编辑
import hotelExamine from '../views/hotel/examine.vue'; // 审核
import paySuccess from '../views/shop/paySuccess.vue'; //先货后款 - 支付成功
// 酒店 开通商品同步K3
import hotelK3Edit from '../views/hotel/editK3.vue';// 编辑页面

//门店编码申请管理
import storeNumber from "../views/supplier/client/storeNumber.vue";
import storeNumDetail from "../views/supplier/client/storeNumDetail.vue";//门店审核，详情
import storeNumDetailEditor from "../views/supplier/client/storeNumDetailEditor.vue";//门店编辑，详情
import mapLocation from "../views/supplier/client/mapLocation.vue";//地图定位
import storeDistribution from "../views/supplier/storeDistribution/index.vue";//门店分布

//账单
import myBill from '../views/bill/myBill.vue';//我的账单
import BillDetail from '../views/bill/billDetail.vue';//账单明细
import BillManage from '../views/bill/billManage.vue'; //账单管理

import salesReport from "../views/shop/salesReport.vue";//销售报表
import accountPeriod from "../views/supplier/client/accountPeriod.vue";//销售报表

import packageManage from '../views/package/package.vue' //套餐管理
import changePackage from '../views/package/changePackage.vue' //添加，编辑套餐商品
import packgoodInfo from '../views/package/detail.vue' //套餐商品详情

// 提货管理
import orderDelivery from "../views/supplier/order/delivery.vue";// 提货管理
// 满赠活动
import Addfullactivity from "../views/supplier/activity/addfullactivity.vue";// 添加满赠活动
import Addwithadditional from '../views/activities/acdetails/addwithadditional.vue' //加价购

// 销售订单异常报表， 出库单异常报表
// import salesOrder from "../views/supplier/dockK3/salesOrder.vue";
// import outboundOrder from "../views/supplier/dockK3/outboundOrder.vue";

//车销
import salesStatistics from "../views/sellincar/salesStatistics.vue"; // 销售汇总
import salesInCarOrderList from "../views/sellincar/salesInCarOrderList.vue"; // 销售汇总-订单列表
import salesInCarOrderDetail from "../views/sellincar/salesInCarOrderDetail.vue"; // 销售汇总-订单详情
import salesInCarTeam from "../views/sellincar/salesInCarTeam.vue"; // 销售汇总-小组佣金设置
import salesInCarInventory from "../views/sellincar/salesInCarInventory.vue"; // 销售汇总-库存详情

// 进销存
import nowInventory from "../views/inventory/nowInventory.vue";//即时库存
import inventoryDetail from "../views/inventory/inventoryDetail.vue";//即时库存
import repertoryManage from "../views/inventory/repertoryManage.vue";//仓库管理
import sonRepertoryManage from "../views/inventory/sonRepertoryManage.vue";//仓库管理
import stockHouseOrderAdd from "../views/inventory/stockHouseOrderAdd.vue";//添加出入库单
import stockHouseOrderDetail from "../views/inventory/stockHouseOrderDetail.vue";//出入库单详情
import stockIn from "../views/inventory/stockIn.vue";//入库单列表
import stockOut from "../views/inventory/stockOut.vue";//出库单列表

export const ysFirstRouters = [
    {
        path: basePath + '/aggrement', //超c协议
        component: Aggrement,
        name:'aggrement',
        text:'超c协议'
    },
    {
        path: basePath + '/alipay',
        component: Alipay,
        name:'alipay',
        text:'支付宝页面' //支付宝页面
    },
    {
        path: basePath + '/preview', //店铺预览
        component: Preview,
        name:'preview',
        text:'店铺预览'
    },

    {
        path: basePath + '/register/step', //注册
        component: Register,
        name:'register',
        text:'注册',
        children: [
            // 注册2021/11/23注释，去掉注册
            // {
            //     path: basePath + '/register/step',
            //     component: Step1,
            //     name:'step1',
            //     text:'注册第一步'
            // },
            // {
            //     path: basePath + '/register/step2/:id?/:state?',
            //     component: Step2,
            //     name:'step2',
            //     text:'注册第二步'
            // },
            // {
            //     path: basePath + '/register/step3/:traderId?/:buyerId?',
            //     component: Step3,
            //     name:'step3',
            //     text:'注册第三步'
            // },
            {
                path: basePath + '/register/forgetFirst',
                component: ForgetFirst,
                name:'forgetFirst',
                text:'忘记密码第一步'
            },
            {
                path: basePath + '/register/forgetSecond',
                component: ForgetSecond,
                name:'forgetSecond',
                text:'忘记密码第二步'
            },
            {
                path: basePath + '/register/forgetThird/:path?',
                component: ForgetThird,
                name:'forgetThird',
                text:'忘记密码第三步'
            },
            {
                path: basePath + '/register/changePassSecond',
                component: changePassSecond,
                name:'changePassSecond',
                text:'改变密码第二步'
            },
            {
                path: basePath + '/register/changePassThird',
                component: changePassThird,
                name:'changePassThird',
                text:'改变密码第三步'
            },
            {
                path: basePath + '/clientUpdate', //升级为注册用户
                component: ClientUpdate,
                name:'clientUpdate',
                text:'升级为注册用户'
            }
        ]
    }
];
export const ysHomeRouters = [

    {
        path: basePath + '/goodsSearch/:classifyId?/:brandId?/:goodsName?/:seller?', //商城/商城搜索
        component: GoodsSearch,
        name:'goodsSearch',
        text:'商城搜索'
    },


    {
        path: basePath + '/ali', //支付模块下，公共的支付宝logo
        component: AlipayCom,
        children: [{
            path: 'status',
            component: VerifyResult //支付结果
        }],
        name:'alipayCom',
        text:'公共的支付宝logo'
    },
    {
        path: basePath + '/shop', //商城
        component: Shop,
        name:'shop',
        text:'商城',
        children: [{
            path: basePath + '/shopIndex', //商城其他
            component: ShopIndex,
            name:'shopIndex',
            text:'商城首页',
            children: [
                {
                    path: basePath + '/shop/shopIndex', //商城首页内容
                    component: HomePage,
                    name:'homePage',
                    text:'商城首页内容'
                },
                {
                    path: basePath + '/shop/goodsDetails/:skuNo', //商城/商品详情
                    component: GoodsDetails,
                    name:'goodsDetails',
                    text:'商城商品详情'
                }
            ]
        }]
    },
    {
        path: basePath + '/store/confirmOrder/:type/:skuNo?/:num?/:salesUnitType?', //商户 确认订单
        component: ConfirmOrder,
        name:'confirmOrder',
        text:'确认订单'
    },
    {
        path: basePath + '/payOrder', //采购进货 付款页面
        component: PayOrder,
        name:'payOrder',
        text:'采购进货付款页面'
    },
    {
        path: basePath + '/paySuccess', //先货后款  - 支付成功
        component: paySuccess,
        name:'paySuccess',
        text:'先货后款支付成功'
    },
    {
        path: basePath + '/cashier', // 在线支付
        component: Cashier,
        name:'cashier',
        text:'在线支付'
    },
    {
        path: basePath + '/storeManage', //店铺管理
        component: StoreManage,
        name:'storeManage',
        text:'店铺管理'
    },


];

export const ysHomeLogoRouters = [
    {
        path: basePath + '/uploadLicense/:classifyId?/:brandId?/:spuNo?', //商品管理/上传销售许可
        component: UploadLicense,
        name:'uploadLicense',
        text:'上传销售许可'
    },
    {
        path: basePath + '/lookLicense/:classifyId?/:brandId?/:spuNo?', //商品管理/查看销售许可
        component: LookLicense,
        name:'lookLicense',
        text:'查看销售许可'
    },
    {
        path: basePath + '/addRole/:sign?', //系统管理/角色编辑
        component: AddRole,
        name:'addRole',
        text:'添加角色'
    },
    {
        path: basePath + '/editRole/:id?/:text?/:isDisplay?', //系统管理/角色编辑
        component: EditRole,
        name:'editRole',
        text:'角色编辑'
    },
    {
        path: basePath + '/search1/messageDetail/:id', //消息详情
        component: MessageDetail,
        name:'messageDetail',
        text:'消息详情'
    },
    {
        path: basePath + '/news/message/:messageInfoInfoId', //新闻公告消息内容
        component: newsMessage,
        name:'newsMessage',
        text:'新闻公告消息内容'
    },
    {
        path: basePath + '/addClient', //经销商新增客户
        component: r => require.ensure([], () => r(require('../views/client/addClient')), 'addClient'),
        name:'addClient',
        text:'经销商新增客户'
    },
    // {
    //     path: basePath + '/checkClient', //经销商查看客户
    //     component: r => require.ensure([], () => r(require('../views/client/checkClient')), 'checkClient'),
    // },
    {
        path: basePath + '/activityGoods', //活动商品管理
        component: r => require.ensure([], () => r(require('../views/client/activityGoodsDetail')), 'activityGoodsDetail'),
        name:'activityGoods',
        text:'活动商品管理'
    },
    {
        path: basePath + '/checkPro', //审核商品
        component: r => require.ensure([], () => r(require('../views/agencyManagement/checkPro.vue')), 'checkPro'),
        name:'checkPro',
        text:'审核商品'
    },
    {
        path: basePath + '/proDetail', //查看商品
        component: r => require.ensure([], () => r(require('../views/agencyManagement/checkPro.vue')), 'checkPro'),
        name:'proDetail',
        text:'查看商品'
    },
    {
        path: basePath + '/proEditor', //编辑商品
        component: r => require.ensure([], () => r(require('../views/agencyManagement/checkPro.vue')), 'proEditor'),
        name:'proEditor',
        text:'编辑商品'
    },
    {
        path: basePath + '/prePay', //预支付订单
        component: r => require.ensure([], () => r(require('../views/shop/prePay.vue')), 'prePay'),
        name:'prePay',
        text:'预支付订单'
    },
    {
        path: basePath + '/clientAdd', //账期管理 添加客户
        component: r => require.ensure([], () => r(require('../views/paymentDays/clientAdd.vue')), 'clientAdd'),
        meta: {title: '添加客户'},
        name:'clientAdd',
        text:'添加客户'
    },
    {
        path: basePath + '/receivableDetail/:id', //账期管理 应收单详情
        component: r => require.ensure([], () => r(require('../views/paymentDays/receivableDetail.vue')), 'receivableDetail'),
        meta: {title: '应收单详情'},
        name:'receivableDetail',
        text:'应收单详情'
    },
    {
        path: basePath + '/lookVerificationSheet/:id', //账期管理 核销单详情
        component: r => require.ensure([], () => r(require('../views/paymentDays/lookVerificationSheet.vue')), 'lookVerificationSheet'),
        meta: {title: '核销单详情'},
        name:'lookVerificationSheet',
        text:'核销单详情'
    },
    {
        path: basePath + '/addVerificationSheet', //账期管理 添加核销单
        component: r => require.ensure([], () => r(require('../views/paymentDays/addVerificationSheet.vue')), 'addVerificationSheet'),
        meta: {title: '添加核销单'},
        name:'addVerificationSheet',
        text:'添加核销单'
    },
    {
        path: basePath + '/receiptDetail/:id', //账期管理 收款单详情
        component: r => require.ensure([], () => r(require('../views/paymentDays/receiptDetail')), 'receiptDetail'),
        meta: {title: '查看收款单'},
        name:'receiptDetail',
        text:'查看收款单'
    }

];
export const ysHomeMenuRouters = [
    {
        path: basePath + '/dep/addPartment/:id?/:sign?', //人员管理/部门管理/新增部门
        component: AddPartment,
        name:'addPartment',
        text:'新增部门'
    },
    {
        path: basePath + '/dep/employeeCustomer', 
        component: () => import('~/views/supplier/person/employeeCustomer'),
        name:'employeeCustomer',
        text:'部门员工编辑'
    },
    {
        path: basePath + '/deliverySet', //设置-配送设置
        name: 'deliverySet',
        text:'配送设置',
        component: () => import(/* webpackChunkName:'deliverySet' */ '~/views/supplier/set/deliverySet')
    },
    {
        path: basePath + '/deliverySet/cusManage', //设置-配送设置-客户管理-添加客户
        name: 'cusManage',
        text:'添加客户',
        component: () => import(/* webpackChunkName:'cusManage' */ '~/views/supplier/set/cusManage')
    },
    {
        path: basePath + '/addDelivery',//设置-配送设置-添加配送
        name: 'addDelivery',
        text: '添加配送',
        component: () => import(/*webpackChunkName:'addDelivery*/ '~/views/supplier/set/addDelivery')
    },
    {
        path: basePath + '/payCertCheck', //支付凭证审核
        component: PayCertCheck,
        name:'payCertCheck',
        text:'支付凭证审核'
    },
    {
        path: basePath + '/addStore/:mobile',
        component: AddStore,
        name:'addStore',
        text:'录入客户'
        // meta: { keepAlive: true }
    },
    {
        path: basePath + '/hotelDetail', // 酒店详情
        component: hotelDetail,
        name:'hotelDetail',
        text:'酒店详情'
    },
    {
        path: basePath + '/hotelAdjustment', // 酒店到期调整
        component: hotelAdjustment,
        name:'hotelAdjustment',
        text:'酒店到期调整'
    },
    {
        path: basePath + '/hotelEdit', // 酒店编辑
        component: hotelEdit,
        name:'hotelEdit',
        text:'酒店编辑'
    },
    {
        path: basePath + '/hotelExamine', // 酒店审核
        component: hotelExamine,
        name:'hotelExamine',
        text:'酒店审核'
    },
    {
        path: basePath + '/hotelK3Edit',
        component: hotelK3Edit,
        name:'hotelK3Edit',
        text:'酒店开通商品同步K3编辑页面'
    },
    {
        path: basePath + '/orderExceptionProcess', // 订单异常处理
        name: 'orderExceptionProcess',
        text:'订单异常处理',
        component: resolve => require(['../views/supplier/dockK3/orderExceptionProcess.vue'], resolve)
    },
    {
        path: basePath + '/valetOrder', //代客下单
        name: 'valetOrder',
        text:'代客下单',
        component: resolve => require(['../views/supplier/order/ValetOrder'], resolve)
    },
    {
        path: basePath + '/preOrder', //代客下单 预订单
        name: 'preOrder',
        text:'预订单',
        component: resolve => require(['../views/supplier/order/preOrder'], resolve),
    },
    {
        path: basePath + '/carGoodsEdit', //车销 --商品编辑：必须放在这里，没有头部的车销页面
        name: 'carGoodsEdit',
        text:'车销商品编辑',
        component: resolve => require(['../views/sellincar/carGoodsEdit'], resolve)
    },
    {
        path: basePath + '/activities',
        component: Activities,
        name:'activities',
        text:'活动路由主页',
        children: [
            {
                path: 'addticket',
                component: Addticket,
                name:'addticket',
                text:'添加优惠券',
            },
            {
                path: 'addshopticket',
                component: Addshopticket,
                name:'addshopticket',
                text:'添加优惠券--店铺',
            },
            {
                path: 'addgoodticket',
                component: Addgoodticket,
                name:'addgoodticket',
                text:'添加优惠券--商品',
            },
            {
                path: 'addclientticket',
                component: Addclientticket,
                name:'addclientticket',
                text:'添加优惠券--店铺',
            },
            {
                path: 'updateticket',
                component: Updateticket,
                name:'updateticket',
                text:'更新优惠券',
            },
            {
                path: 'addacaboutenoughcut',
                component: Addacaboutenoughcut,
                name:'addacaboutenoughcut',
                text:'添加满减活动',
            },
            {
                path: 'addacaboutenoughsent',
                component: Addacaboutenoughsent,
                name:'addacaboutenoughsent',
                text:'添加满赠活动',
            },
            {
                path: 'addwithadditional',// 加价购
                component: Addwithadditional,
                name:'addwithadditional',
                text:'加价购',
            },
            {
                path: 'updatecut',
                component: updatecut,
                name:'updatecut',
                text:'更新满减活动',
            },
            {
                path: 'updatesent',
                component: updatesent,
                name:'updatesent',
                text:'更新满赠活动',
            },
            {
                path: 'eachTicketdetail',
                component: EachTicketdetail,
                name:'eachTicketdetail',
                text:'优惠券详情',
            },
            {
                path: 'cutdetail',
                component: Cutdetail,
                name:'cutdetail',
                text:'满减详情',
            },
            {
                path: 'sentdetail',
                component: Sentdetail,
                name:'sentdetail',
                text:'满赠详情',
            },
        ]
    },
    {
        path: basePath + '/packgoodInfo',// 套餐商品详情
        component: packgoodInfo,
        name:'packgoodInfo',
        text:'套餐商品详情',
    },
    {
        path: basePath + '/serviceDetail/:serviceId', //采购服务单详情
        component: () => import(/* webpackChunkName:'afterService' */ '~/views/service/serviceDetail.vue'),
        name:'serviceDetail',
        text:'采购服务单详情',
    },
    {
        path: basePath + '/afterServiceDetail/:serviceId', //销售卖货服务单详情
        component: () => import(/* webpackChunkName:'saleAfterService' */ '~/views/supplier/afterService/serviceDetail.vue'),
        name:'afterServiceDetail',
        text:'销售卖货服务单详情',
    },
    {
        path: basePath + '/salesInCarOrderDetail', //车销 --订单详情
        component: salesInCarOrderDetail,
        name:'salesInCarOrderDetail',
        text:'车销订单详情',
    },
    {
        path: basePath + '/salesInCarInventory', //车销 --库存
        component: salesInCarInventory,
        name:'salesInCarInventory',
        text:'车销库存',
    },
    {
        path: basePath + '/editPeople/:sign?', //人员管理/编辑员工
        component: EditPeople,
        name:'editPeople',
        text:'编辑员工',
    },
    {
        path: basePath + '/addPeople/:sign?', //人员管理/新增员工
        component: AddPeople,
        name:'addPeople',
        text:'新增员工',
    },
    {
        path: basePath + '/pay', //支付模块下，对于二级页面公共的logo
        component: Public,
        name:'pay',
        text:'对于二级页面公共的logo',
        children: [
            {
                path: "bind",
                component: Bind,
                name:'bind',
                text:'支付密码设置', //支付密码设置
            },
            {
                path: "nameverify",
                component: NameVerify,
                name:'nameverify',
                text:'实名认证', //实名认证
            },
            {
                path: "verifyresult/:page?/:result?",
                component: VerifyResult,
                name:'verifyresult',
                text:'实名认证结果', //实名认证结果
            },
            {
                path: "recharge/:money?",
                component: Recharge,
                name:'recharge',
                text:'充值', //充值
            },
            {
                path: "withdrawal",
                component: Withdrawal,
                name:'withdrawal',
                text:'提现', //提现
            },
            {
                path: "rechargemid/:page?/:money?",
                component: Rechargemid,
                name:'Rechargemid',
                text:'充值1', //充值
            },
            {
                path: "rechargemid2/:page?/:money?",
                component: Rechargemid2,
                name:'Rechargemid2',
                text:'充值2', //充值
            },
            {
                path: basePath + '/transaction',
                component: TransactionRecord,
                name:'transaction',
                text:'所有交易记录',
            },
          
            {
                
                path: basePath + '/IntegralRecordInfo', // 积分记录
                component: () => import('~/views/supplier/paymodule/IntegralRecordInfo.vue'),
                name:'IntegralRecordInfo',
                text:'积分记录',
            },
            {
                
                path: basePath + '/coinRecordInfo', // 久币记录
                component: () => import('~/views/supplier/paymodule/coinRecordInfo.vue'),
                name:'coinRecordInfo',
                text:'久币记录',
            },
            {
                
                path: basePath + '/creditRecordInfo', // 授信余额明细
                component: () => import('~/views/supplier/paymodule/creditRecordInfo.vue'),
                name:'creditRecordInfo',
                text:'授信余额明细',
            },
            {
                path: basePath + '/expenseDetail', //账单明细
                component: () => import('~/views/supplier/paymodule/expenseDetail.vue'),
                name:'expenseDetail',
                text:'费用明细详情列表',
            },
            {
                path: basePath + '/billDetail', //账单明细
                component: BillDetail,
                name:'billDetail',
                text:'账单明细',
            }
        ]
    },
    {
        path: basePath + '/register/changePassFirst',
        component: ChangePassFirst,
        name:'changePassFirst',
        text:'修改密码第一步',
    },
    {
        path: basePath + '/editCommHome/:skuId?/:spuNo?/:rej?', //编辑商品主页
        component: EditCommHome,
        name:'editCommHome',
        text:'编辑商品主页',
    },
    {
        path: basePath + '/purchaseOrderDetail', //我的订单详情
        component: PurchaseOrderDetail,
        name:'purchaseOrderDetail',
        text:'我的订单详情',
    },
    {
        path: basePath + '/ccPurchaseOrderDetail', //我的订单详情
        component: ccPurchaseOrderDetail,
        name:'ccPurchaseOrderDetail',
        text:'我的订单详情',
    },
    // { // 后端菜单已删除,但前端有路由跳转未清除干净。。路由留证。
    //     path: basePath + "/supplierOrderDetail", //销售卖货 订单详情
    //     component: SupplierOrderDetail,
    //     name:'supplierOrderDetail',
    //     text:'订单详情',
    // },
    {
        path: basePath + "/supplierChangeOrder", //销售卖货 修改订单
        component: SupplierChangeOrder,
        name:'supplierChangeOrder',
        text:'修改订单',
    },
    {
        path: basePath + '/store/afterService', //采购售后管理
        name: 'afterService',
        text:'采购售后管理',
        component: () => import(/* webpackChunkName:'afterService' */ '~/views/service/index')
    },
    {
        path: basePath + '/saleAfterService', //销售卖货售后管理
        name: 'salesAfterService',
        text:'销售卖货售后管理',
        component: () => import(/* webpackChunkName:'afterService' */ '~/views/supplier/afterService/index')
    },
    {
        path: basePath + '/saleAddService', //销售卖货添加服务单
        name: 'saleAddService',
        text:'销售卖货添加服务单',
        component: () => import(/* webpackChunkName:'saleAddService' */ '~/views/supplier/afterService/addService')
    },
    {
        path: basePath + '/newValetOrder', //新代客下单
        name: 'newValetOrder',
        text:'新代客下单',
        component: resolve => require(['../views/supplier/order/newValetOrder'], resolve),
    },
    {
        path: basePath + '/addService', //添加服务单
        name: 'addService',
        text:'添加服务单',
        component: resolve => require(['../views/service/addService'], resolve),
    },
    {
        path: basePath + '/checkClient', //经销商查看客户
        component: r => require.ensure([], () => r(require('../views/client/checkClient')), 'checkClient'),
        name:'checkClient',
        text:'经销商查看客户',
    },
    {
        path: basePath + '/accountCenter',
        component: AccountCenter,
        name:'accountCenter',
        text:'账户中心',
    },
    {
        path: basePath + '/applicationManage',
        component: ApplicationManage,
        name:'applicationManage',
        text:'应用管理',
    },
    {
        path: basePath + '/order', //订货单
        name: "order",
        text:'订货单',
        component: Order,
        // meta: {keepAlive: true}
    },
    {
        path: basePath + '/draftOrder', //草稿订单
        name: "draftOrder",
        text:'草稿订单',
        component: DraftOrder,
        // meta: {keepAlive: true}
    },
    {
        path: basePath + '/orderSet', //订货单设置
        name: "orderSet",
        text:'订货单设置',
        component: OrderSet,
        // meta: {keepAlive: true}
    },
    {
        path: basePath + '/noticeSet', //订单消息设置
        name: "noticeSet",
        text:'订单消息设置',
        component: () => import('~/views/noticeSet/index.vue'),
        // meta: {keepAlive: true}
    },
    {
        path: basePath + '/orderDelivery',// 提货管理
        name: 'orderDelivery',
        text:'提货管理',
        component: orderDelivery,
        meta: {keepAlive: true}
    },
    {
        path: basePath + '/activity/:type?',
        component: Activity, //赠品管理,
        meta: {keepAlive: true},
        name:'activity',
        text:'赠品管理',
    },
    {
        path: basePath + '/addacticket',
        component: Addacticket,
        name:'addacticket',
        text:'添加活动券', //添加活动券
    },
    {
        path: basePath + '/addfullactivity',
        component: Addfullactivity,
        name:'addfullactivity',
        text:'添加满赠活动', //添加满赠活动
    },
    {
        path: basePath + '/ticketdetail',
        component: Ticketdetail,
        name:'ticketdetail',
        text:'优惠券领取明细', //优惠券领取明细
    },
    {
        path: basePath + '/generalSet', //通用设置
        component: GeneralSet,
        name:'generalSet',
        text:'通用设置',
    },
    {
        path: basePath + '/deal', //交易数据统计
        component: () => import(/* webpackChunkName:'deal' */ '~/views/dataStatistics/deal.vue'),
        name:'deal',
        text:'交易数据统计',
    },
    {
        path: basePath + '/client', //客户数据统计
        component: () => import(/* webpackChunkName:'client' */ '~/views/dataStatistics/client.vue'),
        name:'client',
        text:'客户数据统计',
    },
    {
        path: basePath + '/product', //商品数据统计
        component: () => import('~/views/dataStatistics/product.vue'),
        name:'product',
        text:'商品数据统计',
    },
    {
        path: basePath + '/generalQuery', //综合查询
        component: () => import('~/views/dataStatistics/integrated.vue'),
        name:'generalQuery',
        text:'综合查询',
    },
    {
        path: basePath + '/statistical/integral', // 积分统计
        component: () => import('~/views/dataStatistics/integral.vue'),
        name:'statisticalIntegral',
        text:'积分统计',
    },
    {
        path: basePath + '/statistical/integralDetails', // 积分详情
        component: () => import('~/views/dataStatistics/integralDetails.vue'),
        name:'integralDetails',
        text:'积分详情',
    },

    
    {
        path: basePath + '/storeInformation', //我的账户->店铺信息
        component: StoreInformation,
        name:'storeInformation',
        text:'店铺信息',
    },
    {
        path: basePath + '/accountInfo', //我的账户/账户信息
        component: AccountIndex,
        name:'accountInfo',
        text:'账户信息',
    },
    {
        path: basePath + '/accountSafe', //我的账户/账户安全
        component: AccountSafe,
        name:'accountSafe',
        text:'账户安全',
    },
    {
        path: basePath + '/people', //人员管理
        component: People,
        meta: {keepAlive: true},
        name:'people',
        text:'人员管理',
    },
    {
        path: basePath + '/qualManage', //人员管理//资质管理
        component: QualManage,
        name:'qualManage',
        text:'资质管理',
    },
    {
        path: basePath + '/roleLimits', //人员管理/部门管理/角色权限
        component: RoleLimits,
        name:'roleLimits',
        text:'角色权限',
    },
    {
        path: basePath + '/department', //部门管理
        component: Department,
        meta: {keepAlive: true},
        name:'department',
        text:'部门管理',
    },
    {
        path: basePath + '/clientList', //全部客户
        component: ClientList,
        meta: {keepAlive: true},
        name:'clientList',
        text:'全部客户',
    },
    {
        path: basePath + '/expenseDetailList', //费用明细详情列表
        component:  () => import('~/views/supplier/client/expenseDetailList.vue'),
        name:'expenseDetailList',
        text:'费用明细详情列表',
    },
    {
        path: basePath + '/expenseDetailEdit', //费用明细详情编辑
        component:  () => import('~/views/supplier/client/expenseDetailEdit.vue'),
        name:'expenseDetailEdit',
        text:'费用明细详情编辑',
    },
    
    {
        path: basePath + '/brandsApply', //品牌商申请  第一次上传销售协议
        component: BrandsApply,
        name:'brandsApply',
        text:'品牌商申请',
    },
    {
        path: basePath + '/advertisingManage', //广告管理
        component: advertisingManage,
        name:'advertisingManage',
        text:'广告管理',
    },
    {
        path: basePath + '/clientEdit/:id?/:type?/:buyerId?', //客户编辑
        component: ClientEdit,
        name:'clientEdit',
        text:'客户编辑',
    },
    {
        path: basePath + '/register/replace',
        component: Step1,
        name:'step1',
        text:'注册第一步',
    },
    {
        path: basePath + '/register/replace2/:id?/:state?',
        component: Step2,
        name:'step2',
        text:'注册第二步',
    },
    {
        path: basePath + '/register/replace3/:traderId?/:buyerId?',
        component: Step3,
        name:'step3',
        text:'注册第三步',
    },
    {
        path: basePath + '/payMode', //收款账号设置
        component: PayMode,
        name:'payMode',
        text:'收款账号设置',
    },
    {
        path: basePath + '/cloudCashOut', //云账户提现流水
        component: cloudCashOut,
        name:'cloudCashOut',
        text:'云账户提现流水',
    },
    {
        path: basePath + '/myBill', //我的账单
        component: myBill,
        name:'myBill',
        text:'我的账单',
    },
    {
        path: basePath + '/bill', //账单管理
        component: BillManage,
        name:'bill',
        text:'账单管理',
    },
    {
        path: basePath + '/packageManage', //套餐管理
        component: packageManage,
        meta: {keepAlive: true},
        name:'packageManage',
        text:'套餐管理',
    },
    {
        path: basePath + '/changePackage',// 编辑，添加套餐
        component: changePackage,
        name:'changePackage',
        text:'编辑，添加套餐',
    },
    {
        path: basePath + '/store/stock/supplier',
        component: StoreSupplier, //采购进货 我的供应商
        meta: {keepAlive: true},
        name:'supplier',
        text:'我的供应商',
    },
    {
        path: basePath + '/store/stock/supplierStore',
        component: supplierStore,
        meta: {keepAlive: true, title: '供应商列表'},
        name:'supplierStore',
        text:'供应商列表',
    },
    {
        path: basePath + '/commodityStocks', //商品库存
        component: CommodityStocks,
        meta: {keepAlive: true},
        name:'CommodityStocks',
        text:'商品库存',
    },
    {
        path: basePath + '/stock/skuStockAlert', //商品库存
        component: resolve => require(['~/views/comm/stock/skuStockAlert.vue'], resolve),
        meta: {keepAlive: true},
        name:'skuStockAlert',
        text:'库存预警',
    },
    {
        path: basePath + '/store/stock/invoice', // 采购进货 我的发票
        component: StockInvoice,
        meta: {keepAlive: true},
        name:'invoice',
        text:'我的发票1',
    },
    {
        path: basePath + '/store/stock/myinvoice', // 采购进货 我的发票
        component: MyStockInvoice,
        name:'myinvoice',
        text:'我的发票2',
    },
    /*{
        path: basePath + '/store/stock/mydealer', // 采购进货 我的经销商
        component: resolve => require(['~/views/store/stock/mydealer.vue'],resolve)
    },*/
    // {
    // 	path: basePath + '/store/stock/invoiceDetail', //采购进货 发票详情
    // 	component: StockInvoiceDetail
    // },
    {
        path: basePath + '/address', //收货地址 04-19
        component: Address,
        name:'address',
        text:'收货地址',
    },
    {
        path: basePath + '/accountSecurity',
        component: accountSecurity,
        name:'accountSecurity',
        text:'账户安全目录',
    },
    {
        path: basePath + '/allProduct', //商品管理/全部商品
        component: AllProduct,
        meta: {keepAlive: true},
        name:'allProduct',
        text:'全部商品',
    },
    {
        path: basePath + '/monthlySale', //商品管理/月售管理
        component: () => import('~/views/comm/monthlySale.vue'),
        name:'monthlySale',
        text:'月售管理',
    },
    {
        path: basePath + '/productSet', //商品管理/商品设置
        component: ProductSet,
        meta: {keepAlive: true},
        name:'productSet',
        text:'商品设置',
    },
    {
        path: basePath + '/addProduct', //商品管理/添加商品
        component: AddProduct,
        name:'addProduct',
        text:'添加商品',
    },
    {
        path: basePath + '/store/record', //进货商 发票管理
        component: StoreRecord,
        name:'storeRecord',
        text:'发票管理',
    },
    {
        path: basePath + '/store/purchaseOrder', // 商户 已采购的订单
        component: PurchaseOrder,
        name:'purchaseOrder',
        text:'已采购的订单',
        // meta: {keepAlive: true} bug12837
    },
    // 库存共享
    // {
    //     path: basePath + '/store/orderLayout', 
    //     component: () => import('~/views/store/myOrder/orderLayout.vue'),
    //     name:'orderLayout',
    //     text:'待采购商品',
    // },
    // {
    //     path: basePath + '/store/addOrder', 
    //     component: () => import('~/views/store/myOrder/addOrder.vue'),
    //     name:'addOrder',
    //     text:'新建采购订单',
    // },
    // 库存共享结束
    {
        path: basePath + '/store/myCollect', //商城/我的收藏
        component: MyCollect,
        name:'myCollect',
        text:'我的收藏',
    },
    {
        path: basePath + '/store/myDiscount', //商城/我的优惠券
        component: MyDiscount,
        meta: {keepAlive: true},
        name:'myDiscount',
        text:'我的优惠券',
    },
    {
        path: basePath + '/search1/messageCenter', //消息中心
        component: MessageCenter,
        meta: {keepAlive: true},
        name:'messageCenter',
        text:'消息中心', //这个是需要keepalive的
    },
    {
        path: basePath + '/myaccount', //支付模块，我的账户
        component: MyAccount,
        meta: {keepAlive: true},
        name:'myaccount',
        text:'我的账户',
    },
    {
        path:basePath + '/enterpriseAuthentication',//企业认证
        component:  resolve => require(['../views/supplier/paymodule/enterpriseAuthentication/index.vue'], resolve),//() => import('../views/supplier/paymodule/enterpriseAuthentication.vue')
        name:'enterpriseAuthentication',
        text:'企业认证',
    },
    {
        path: basePath + '/setbank', //银行卡管理
        component: Setbank,
        name:'setbank',
        text:'银行卡管理1',
    },
    {
        path: basePath + '/setPayAccount', //支付模块，我的账户
        component: SetPayAccount,
        name:'setPayAccount',
        text:'银行卡管理2',
    },
    {
        path: basePath + '/clientData', //客户渠道、等级列表
        component: clientData,
        name:'clientData',
        text:'等级列表',
    },
    {
        path: basePath + '/customerConfig', // 客户录入配置
        component: CustomerConfig,
        name:'customerConfig',
        text:'客户录入配置',
    },
    {
        path: basePath + '/clientGrouping', //客户分组
        component: clientGrouping,
        meta: {keepAlive: true},
        name:'clientGrouping',
        text:'客户分组',
    },
    {
        path: basePath + '/addAndEditGrouping', //客户分组 新增and 修改
        component: addAndEditGrouping,
        name:'addAndEditGrouping',
        text:'客户分组',
    },
    {
        path: basePath + '/clientAddress', //客户收货地址管理
        component: clientAddress,
        meta: {keepAlive: true},
        name:'clientAddress',
        text:'客户收货地址管理',
    },
    {
        path: basePath + '/storeNumber', //门店编码
        component: storeNumber,
        meta: {keepAlive: true},
        name:'storeNumber',
        text:'门店编码',
    },
    {
        path: basePath + '/storeNumDetail', //门店编码审核，详情
        component: storeNumDetail,
        name:'storeNumDetail',
        text:'门店编码审核详情',
    },
    {
        path: basePath + '/storeNumDetailEditor', //门店编码编辑，详情
        component: storeNumDetailEditor,
        name:'storeNumDetailEditor',
        text:'门店编码编辑详情',
    },
    {
        path: basePath + '/mapLocation', //地图定位
        component: mapLocation,
        name:'mapLocation',
        text:'地图定位',
    },
    {
        path: basePath + '/storeDistribution', //门店分布
        component: storeDistribution,
        name:'storeDistribution',
        text:'门店分布',
    },
    {
        path: basePath + '/regionList', //区域设置 列表
        component: regionList,
        meta: {keepAlive: true},
        name:'regionList',
        text:'区域设置列表',
    },
    {
        path: basePath + '/addAndEditRegion', //区域 新增and 修改
        component: addAndEditRegion,
        name:'addAndEditRegion',
        text:'区域新增修改',
    },
    {
        path: basePath + '/newsPush', //消息推送
        component: newsPush,
        name:'newsPush',
        text:'消息推送',
    },
    {
        path: basePath + '/merchanItemAdd', //消息推送
        component:  resolve => require(['~/views/supplier/messagePush/messageItem/merchanItemAdd.vue'], resolve),//() => import('../views/supplier/paymodule/enterpriseAuthentication.vue')
        name:'merchanItemAdd',
        text:'消息推送新增',
    },
    {
        path: basePath + '/merchantItemDetail', //消息推送详情
        component:  resolve => require(['~/views/supplier/messagePush/messageItem/merchantItemDetail.vue'], resolve),//() => import('../views/supplier/paymodule/enterpriseAuthentication.vue')
        name:'merchantItemDetail',
        text:'消息推送详情',
    },
    // 积分模块
    {
        path: basePath + '/integral/integralManagement', //积分管理
        component:  resolve => require(['../views/integral/integralManagement.vue'], resolve),
        name:'integral',
        text:'积分管理',
    },
    {
        path: basePath + '/integral/audit', // 积分审核
        component:  resolve => require(['../views/integral/audit.vue'], resolve),
        name:'audit',
        text:'积分管理-审核',
    },
    {
        path: basePath + '/integral/integralEditInfo', // 积分编辑&详情
        component:  resolve => require(['../views/integral/integralEditInfo.vue'], resolve),
        name:'integralEditInfo',
        text:'积分管理-编辑',
    },
    {
        path: basePath + '/integral/period', // 周期设置
        component:  resolve => require(['../views/integral/period.vue'], resolve),
        name:'period',
        text:'积分管理-周期设置',
    },
    {
        path: basePath + '/integral/integralSetting', // 客户积分设置
        component:  resolve => require(['../views/integral/integralSetting.vue'], resolve),
        name:'integralSetting',
        text:'积分-客户积分设置',
    },
    {
        path: basePath + '/integral/classifyPercentage', // 品类占比设置
        component:  resolve => require(['../views/integral/classifyPercentage.vue'], resolve),
        name:'classifyPercentage',
        text:'积分-品类占比设置',
    },
    {
        path: basePath + '/integral/goodsTargetSet', // 指定商品设置
        component:  resolve => require(['../views/integral/goodsTargetSet.vue'], resolve),
        name:'goodsTargetSet',
        text:'积分-指定商品设置',
    },
    {
        path: basePath + '/integral/settlementIntegration', // 兑换积分管理
        component:  resolve => require(['../views/integral/settlementIntegration.vue'], resolve),
        name:'settlementIntegration',
        text:'积分-兑换积分管理',
    },
    // ------
    // 久币模块
    {
        path: basePath + '/coin/traderCoin', // 客户久币管理
        component:  resolve => require(['../views/coin/traderCoin/index.vue'], resolve),
        name:'traderCoin',
        text:'客户久币管理',
    },
    {
        path: basePath + '/coin/traderCoinDetailInfo', // 客户久币管理-查看详情
        component:  resolve => require(['../views/coin/traderCoin/detail/traderCoinDetailInfo.vue'], resolve),
        name:'traderCoinDetailInfo',
        text:'查看详情',
    },
    {
        path: basePath + '/coin/coinSettlement', // 周期久币结算
        component:  resolve => require(['../views/coin/coinSettlement/index.vue'], resolve),
        name:'coinSettlement',
        text:'周期久币结算',
    },
    {
        path: basePath + '/coin/coinSettlementDeatil', // 周期久币结算详情
        component:  resolve => require(['../views/coin/coinSettlement/detail/coinSettlementDeatil.vue'], resolve),
        name:'coinSettlementDeatil',
        text:'周期久币结算-详情',
    },
    {
        path: basePath + '/coin/cycleCoinInfo', // 周期久币管理
        component:  resolve => require(['../views/coin/cycleCoinInfo/index.vue'], resolve),
        name:'cycleCoinInfo',
        text:'周期久币管理',
    },
    {
        path: basePath + '/coin/detailInfo', // 周期久币管理详情
        component:  resolve => require(['../views/coin/cycleCoinInfo/detail/detailInfo.vue'], resolve),
        name:'detailInfo',
        text:'周期久币管理-详情',
    },
    {
        path: basePath + '/coin/coinAudit', // 周期久币管理-审核
        component:  resolve => require(['../views/coin/cycleCoinInfo/coinAudit.vue'], resolve),
        name:'coinAudit',
        text:'周期久币管理-审核',
    },
    {
        path: basePath + '/coin/power', // 客户久币权限
        component:  resolve => require(['../views/coin/power/index.vue'], resolve),
        name:'power',
        text:'客户久币权限',
    },
    {
        path: basePath + '/coin/level', // 客户等级设置
        component:  resolve => require(['../views/coin/level/index.vue'], resolve),
        name:'level',
        text:'客户等级设置',
    },
    {
        path: basePath + '/coin/coinGoods', // 久币商品设置
        component:  resolve => require(['../views/coin/coinGoods/index.vue'], resolve),
        name:'coinGoods',
        text:'久币商品设置',
    },
    {
        path: basePath + '/coin/conditionGoods', // 指定商品设置
        component:  resolve => require(['../views/coin/conditionGoods/index.vue'], resolve),
        name:'conditionGoods',
        text:'指定商品设置',
    },
    {
        path: basePath + '/coin/cycleSet', // 久币周期设置
        component:  resolve => require(['../views/coin/cycleSet/index.vue'], resolve),
        name:'cycleSet',
        text:'久币周期设置',
    },
    // ------
    {
        path: basePath + '/smsPush', //短信推送
        component: smsPush,
        name:'smsPush',
        text:'短信推送',
    },
    {
        path: basePath + '/historicalRecord', //历史记录
        component: historicalRecord,
        name:'historicalRecord',
        text:'历史记录',
    },
    {
        path: basePath + '/setOrder', //订单设置
        component: setOrder,
        name:'setOrder',
        text:'订单设置',
    },
    {
        path: basePath + '/hotelHome', // 酒店首页
        component: hotelHome,
        name:'hotelHome',
        text:'酒店首页',
        // meta: { keepAlive: true }
    },

    {
        path: basePath + '/operations',
        component: resolve => require(['../views/operationManagement/index'], resolve),
        name:'operations',
        text:'运营管理',
    },
    {//城市拍档商家管理
        path: basePath + '/cityPartnerManagement/merchantManagement',
        component: resolve => require(['../views/operationManagement/merchantManagement'], resolve),
        name:'merchantManagement',
        text:'城市拍档商家管理',
    },
    {//城市拍档商品管理
        path: basePath + '/cityPartnerManagement/productManagement',
        component: resolve => require(['../views/operationManagement/productManagement'], resolve),
        name:'productManagement',
        text:'城市拍档商品管理',
    },
    {//城市拍档折扣管理
        path: basePath + '/cityPartnerManagement/discountManagement',
        component: resolve => require(['../views/operationManagement/discountManagement'], resolve),
        name:'discountManagement',
        text:'城市拍档折扣管理',
    },
    {//城市拍档订单管理
        path: basePath + '/cityPartnerManagement/orderManagement',
        component: resolve => require(['../views/operationManagement/orderManagement'], resolve),
        name:'orderManagement',
        text:'城市拍档订单管理',
    },
    {//城市拍档客户管理
        path: basePath + '/cityPartnerManagement/clientManagement',
        component: resolve => require(['../views/operationManagement/clientManagement'], resolve),
        name:'clientManagement',
        text:'城市拍档客户管理',
    },
    
    {
        path: basePath + '/salesStatistics', //车销 --销售汇总
        name: 'salesStatistics',
        text:'车销销售汇总',
        component: salesStatistics,
    },
    {
        path: basePath + '/salesInCarOrderList', //车销 --订单列表
        name: 'salesInCarOrderList',
        text:'车销订单列表',
        component: salesInCarOrderList,
    },
    {
        path: basePath + '/salesInCarTeam', //车销 --车销小组
        name: 'salesInCarTeam',
        text:'车销车销小组',
        component: salesInCarTeam,
    },
    {
        path: basePath + '/salesReport', // 销售报表
        component: salesReport,
        meta: {keepAlive: true},
        name:'salesReport',
        text:'销售报表',
    },
    {
        path: basePath + '/accountPeriod', //账期管理
        component: accountPeriod,
        name:'accountPeriod',
        text:'账期管理',
    },
    {
        path: basePath + '/client', //客户管理
        component: r => require.ensure([], () => r(require('../views/client/index')), 'client'),
        name:'client',
        text:'客户管理',
    },

    {
        path: basePath + '/proManagement', //商品管理
        component: r => require.ensure([], () => r(require('../views/agencyManagement/proManagement.vue')), 'proManagement'),
        name:'proManagement',
        text:'商品管理',
    },
    {
        path: basePath + '/orderManagement', //订单管理
        component: r => require.ensure([], () => r(require('../views/agencyManagement/orderManagement.vue')), 'orderManagement'),
        name:'orderManagement',
        text:'订单管理',
    },
    {
        path: basePath + '/agencyProduct', //经销商商品管理
        component: r => require.ensure([], () => r(require('../views/comm/agencyProduct')), 'agencyProduct'),
        name:'agencyProduct',
        text:'经销商商品管理',
    },
    {
        path: basePath + '/agencyOrder', //经销商订单管理
        component: r => require.ensure([], () => r(require('../views/comm/agencyOrder')), 'agencyOrder'),
        name:'agencyOrder',
        text:'经销商订单管理',
    },
    // {
    //     path: basePath + '/paymentManager', //账期管理
    //     component: r => require.ensure([], () => r(require('../views/paymentDays/paymentManager')), 'agencyOrder'),
    //     name:'paymentManager',
    //     text:'账期管理',
    // },
    {
        path: basePath + '/receiptManager', //应收款管理
        component: r => require.ensure([], () => r(require('../views/paymentDays/receiptManager')), 'receiptManager'),
        name:'receiptManager',
        text:'应收款管理',
    },
    {
        path: basePath + '/statement', //已生成报表
        component:() =>  import('../views/supplier/order/statement'),
        name:'statement',
        text:'已生成报表',
        // component: r => require.ensure([], () => r(require('../views/supplier/order/statement')), 'statement')
    },
    {
        path: basePath + '/carGoodsManage', //车销 --商品列表
        name: 'carGoodsManage',
        component: resolve => require(['../views/sellincar/carGoodsManage'], resolve),
        text:'车销商品列表',
    },
    {
        path: basePath + '/carGoodsAdd', //车销 --商品新增
        name: 'carGoodsAdd',
        text:'车销商品新增',
        component: resolve => require(['../views/sellincar/carGoodsAdd'], resolve)
    },
    {
        path: basePath + '/gift', //车销 --赠品管理
        name: 'carGoodsAdd',
        text:'赠品管理',
        component: resolve => require(['../views/sellincar/gift'], resolve)
    },
    {
        path: basePath + '/nowInventory', //即时库存
        component: nowInventory,
        name:'nowInventory',
        text:'即时库存',
    },
    {
        path: basePath + '/salesReturn', //退货管理
        component: () => import(/* webpackChunkName: 'SalesReturn' */'~/views/inventory/SalesReturn.vue'),
        name:'salesReturn',
        text:'退货管理',
    },
    {
        path: basePath + '/salesReturnDetail/:id', //退货管理详情
        component: () => import(/* webpackChunkName: 'SalesReturnDetail' */'~/views/inventory/SalesReturnDetail.vue'),
        name:'salesReturnDetail',
        text:'退货管理详情',
    },
    {
        path: basePath + '/inventoryDetail', //库存明细
        component: inventoryDetail,
        name:'inventoryDetail',
        text:'库存明细',
    },
    {
        path: basePath + '/repertoryManage', //仓库管理
        component: repertoryManage,
        name:'repertoryManage',
        text:'仓库管理',
    },
    {
        path: basePath + '/directionalWarehouse', //定向仓库方案列表
        component: () => import(/* webpackChunkName: 'directionalWarehouse' */'~/views/inventory/directionalWarehouse.vue'),
        name:'directionalWarehouse',
        text:'定向仓库方案列表',
    },
    {
        path: basePath + '/cusManage', //定向仓方案客户管理
        component: () => import(/* webpackChunkName: 'cusManage' */'~/views/inventory/cusManage.vue'),
        name:'cusManage',
        text:'定向仓方案客户管理',
    },
    {
        path: basePath + '/warehouseSetup',
        component: () => import (/*webpackChunkName: 'warehouseSetup' */ '~/views/inventory/warehouseSetup.vue'),
        name:'warehouseSetup',
        text:'定向仓库设置',
    },
    {
        path: basePath + '/addDirectionalWarehousePlan', //定向仓库方案列表
        component: () => import(/* webpackChunkName: 'addDirectionalWarehousePlan' */'~/views/inventory/addDirectionalWarehousePlan.vue'),
        name:'addDirectionalWarehousePlan',
        text:'定向仓库方案列表',
    },
    {
        path: basePath + '/editDirectionalWarehousePlan', //定向仓库方案编辑
        component: () => import(/* webpackChunkName: 'addDirectionalWarehousePlan' */'~/views/inventory/addDirectionalWarehousePlan.vue'),
        name:'editDirectionalWarehousePlan',
        text:'定向仓库方案编辑',
    },
    {
        path: basePath + '/sonRepertoryManage', //子公司仓库管理
        component: sonRepertoryManage,
        name:'sonRepertoryManage',
        text:'子公司仓库管理',
    },
    {
        path: basePath + "/stockHouseOrderAdd/0",
        component: stockHouseOrderAdd,
        meta: {title: '添加出库单'},
        name:'stockHouseOrderAdd',
        text:'添加出库单',
    },
    {
        path: basePath + "/stockHouseOrderAdd/1",
        component: stockHouseOrderAdd,
        meta: {title: '添加入库单'},
        name:'stockHouseOrderAdd',
        text:'添加入库单',
    },
    {
        path: basePath + "/stockHouseOrderDetail/0/:no?", //销售卖货 库存管理 入or出库单详情
        component: stockHouseOrderDetail,
        meta: {title: '出库单详情'},
        name:'stockHouseOrderDetail',
        text:'出库单详情',
    },
    {
        path: basePath + "/stockHouseOrderDetail/1/:no?", //销售卖货 库存管理 入or出库单详情
        component: stockHouseOrderDetail,
        meta: {title: '入库单详情'},
        name:'stockHouseOrderDetail',
        text:'入库单详情',
    },
    {
        path: basePath + '/stockIn', //入库单列表
        component: stockIn,
        name:'stockIn',
        text:'入库单列表',
    },
    {
        path: basePath + '/stockOut', //出库单列表
        component: stockOut,
        name:'stockOut',
        text:'出库单列表',
    }
];
export const ysCarLogoRouters = [
    {
        path: basePath + '/shop/shopingCar', //购物车
        component: ShopingCar,
        name:'shopingCar',
        text:'购物车',
    },
    // {
    //     path: basePath + '/purchaseOrderDetail', //我的订单详情
    //     component: PurchaseOrderDetail
    // },
    // {
    //     path: basePath + '/ccPurchaseOrderDetail', //我的订单详情
    //     component: ccPurchaseOrderDetail
    // },
    {
        path: basePath + '/store/stock/addProdurts', // 采购进货 指定商品
        component: resolve => require(['~/views/store/stock/addProducts.vue'], resolve),
        name:'addProdurts',
        text:'指定商品',
    }
];
