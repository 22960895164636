<template>
    <div>
        <div class="big-title">{{activityVo.activityType == 4?"加价换购活动详情":"满赠活动详情"}}
        </div>
        <div class="item-list" v-loading="loading">
            <div class="each-item mt-20">
                <div class="item-title fl">活动名称</div>
                <div class="item-body fl" :style="{'width':'400px'}">{{activityVo.activityName}}</div>
                <!-- <span style="margin-left:50px">{{activityVo.isOvering ? '可叠加' : '不可叠加'}}</span> -->
                <span style="margin-left:50px">{{activityVo.isNewUser ? '首单' : '非首单'}}</span>
            </div>
            <div class="each-item mt-20">
                <div class="item-title fl">活动时间</div>
                <div class="item-body fl">{{activityVo.gmtStart | timenyr}} 至 {{activityVo.gmtEnd | timenyr}}</div>
            </div>
            <div class="each-item mt-20 clearfix" v-if="activityVo.activityType != 4">
                <div class="item-title fl">活动规则</div>
                <div class="item-body fl" v-if=" activityVo.type == '1'">订购金额</div>
                <div class="item-body fl" v-if=" activityVo.type == '2'">订购数量</div>
                <div class="item-body fl" v-if=" activityVo.type == '3'">每满额送</div>
                <div class="item-body fl" v-if=" activityVo.type == '4'">每满件送</div>
                <div class="item-body  ml-30 fl" v-if="activityVo.type == '2'||activityVo.type == '4'">
                    {{giftRuleExPos[0].unitType==1?'按箱配置':'按瓶配置'}}
                </div>
                <br/>
                <div class="clearfix" :style="{'margin-left': '180px','margin-top': '20px'}" v-for="a in giftRuleExPos">
                    <div class="fl" v-if="activityVo.type == '1'">订购满{{a.fullMoney}}元</div>
                    <div class="fl" v-else-if="activityVo.type == '2'">订购满{{a.fullAmount}}{{a.unitType==1?'箱':'瓶'}}
                    </div>
                    <div class="fl" v-else-if="activityVo.type == '3'">每满{{a.fullMoney}}元</div>
                    <div class="fl" v-else-if="activityVo.type == '4'">每满{{a.fullAmount}}{{a.unitType==1?'箱':'瓶'}}</div>
                    <div class="fl ml-20">
                        <div v-for="(b, key) in a.giftList" :key="key" class="clearfix">
                            <div class="fl mr-20">
                                {{'赠品组合'+numTochinese(key+1)+":"}}
                            </div>
                            <div class="fl">
                                <div v-for="n in b.subgiftList" >
                                    <div class="dib text-overflow top" style="width:300px;" :title="n.skuName">
                                        赠品：{{n.skuName}}
                                    </div>
                                    <div class="dib ml-10">数量：{{n.quantity}}{{n.unitType == 1 ? '箱' : n.giftUnit}}
									<span>{{n.unitType == 1 ? '(规格单位)' : '(基本单位)'}}</span>
                             <!--           <span v-if="n.giftType ==1">
                      {{n.giftUnit}}
                    </span>
                                        <span v-else>
                      {{n.unitType==1?'箱':'瓶'}}
                    </span> -->
                                        <span class="ml-10 text-overflow" v-if="n.k3GiftTypeName && isOpenSaleType">
                      赠品类型：{{ n.k3GiftTypeName }}
                    </span>
                                    </div>
                                    <div class="dib ml-30" v-if="activityVo.type == '3'||activityVo.type == '4'">上限：
                                        <span v-if="n.limitQuantity">
                      {{n.limitQuantity}}{{n.unitType == 1 ? '箱' : n.giftUnit}}
					  <span>{{n.unitType == 1 ? '(规格单位)' : '(基本单位)'}}</span>
                      <!-- <span v-if="n.giftType ==1">
                        {{n.giftUnit}}
                      </span>
                      <span v-else>
                        {{n.unitType==1?'箱':'瓶'}}
                      </span> -->
                    </span>
                                        <span v-else>无</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 换购 -->
            <div class="each-item mt-20 clearfix" v-if="activityVo.activityType == 4">
                <div class="item-title fl">活动规则</div>
                <div class="item-body fl" v-if=" activityVo.type == '1'">满额加价购</div>
                <div class="item-body fl" v-if=" activityVo.type == '2'">满件加价购</div>
                <div class="item-body fl" v-if=" activityVo.type == '3'">每满额加价购</div>
                <div class="item-body fl" v-if=" activityVo.type == '4'">每满件加价购</div>
                <div class="item-body  ml-30 fl" v-if="activityVo.type == '2'||activityVo.type == '4'">
                    {{giftRuleExPos[0].unitType==1?'按箱配置':'按瓶配置'}}
                </div>
                </br>
                <div class="clearfix" :style="{'margin-left': '180px','margin-top': '20px'}" v-for="a in giftRuleExPos">
                    <div class="clearfix">
                        <div class="fl" v-if="activityVo.type == '1'">订购满{{a.fullMoney}}元</div>
                        <div class="fl" v-else-if="activityVo.type == '2'">
                            订购满{{a.fullAmount}}{{a.unitType==1?'箱':'瓶'}}
                        </div>
                        <div class="fl" v-else-if="activityVo.type == '3'">每满{{a.fullMoney}}元</div>
                        <div class="fl" v-else-if="activityVo.type == '4'">每满{{a.fullAmount}}{{a.unitType==1?'箱':'瓶'}}
                        </div>
                        <div class="fl ml-20">最多换购种类：{{a.optionalNum}}</div>
                    </div>
                    <div class="ml-20 mt-10">
                        <div v-for="n in a.activityGiftRelationLi" :key="n.skuName">
                            <div class="dib text-overflow top" style="width:300px;" :title="n.skuName">
                                换购商品：{{n.skuName}}
                            </div>
                            <div class="dib ml-10">单价：{{n.unitAmount}}元/
								{{n.unitType == 1 ? '箱' : n.giftUnit}}
                         <!--       <span v-if="n.giftType ==1">
              		{{n.giftUnit}}
              	</span>
                                <span v-else>
              		{{n.unitType==1?'箱':'瓶'}}
              	</span> -->
                            </div>
                            <div class="dib ml-10">数量：{{n.quantity}}
							{{n.unitType == 1 ? '箱' : n.giftUnit}}
							<span>{{n.unitType == 1 ? '(规格单位)' : '(基本单位)'}}</span>
                      <!--          <span v-if="n.giftType ==1">
              		{{n.giftUnit}}
              	</span>
                                <span v-else>
              		{{n.unitType==1?'箱':'瓶'}}
              	</span> -->
                            </div>
                            <div class="dib ml-30" v-if="activityVo.type == '3'||activityVo.type == '4'">上限：
                                <span v-if="n.limitQuantity">
              		{{n.limitQuantity}}{{n.unitType == 1 ? '箱' : n.giftUnit}}
					<span>{{n.unitType == 1 ? '(规格单位)' : '(基本单位)'}}</span>
              		<!-- <span v-if="n.giftType ==1">
	              		{{n.giftUnit}}
	              	</span>
	              	<span v-else>
	              		{{n.unitType==1?'箱':'瓶'}}
	              	</span> -->
              	</span>
                                <span v-else>无</span>
                            </div>
                            <div class="dib ml-10">{{n.isNeedPay?'必购':'非必购'}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="each-item mt-20" v-else-if="activityVo.type == '2'">
              <div class="item-title fl">活动规则</div>
              <div class="item-body fl">订购数量</div></br>
              <div :style="{'margin-left': '180px','margin-top': '10px'}" v-for="a in giftRuleExPos">
                <div class="dib">订购满{{a.fullAmount}}件</div>
                <div class="dib" v-for="n in a.activityGiftRelationLi" >赠品：{{n.skuName}} 数量：{{n.quantity}}</div>
            </div> -->


            <div class="each-item mt-20">
                <div class="item-title fl">参与次数</div>
                <div class="item-body fl" v-if="activityVo.isLimitTimes == '0'">不限次数</div>
                <div class="item-body fl" v-else-if="activityVo.isLimitTimes == '1'">限制 {{ activityVo.limitTimes }} 次
                </div>
            </div>

            <div class="each-item mt-20 pl-30" v-if="activityVo.rangCustomerType =='1'">
                <div class="item-title fl">参与活动客户</div>
                <div class="item-body fl">全部客户</div>
            </div>
            <div class="each-item mt-20 pl-30" v-else-if="activityVo.rangCustomerType =='2'">
                <div class="item-title fl">参与活动客户</div>
                <div class="item-body fl ml-70">
                    <div>部分客户</div>
                    <tableA :size="[100,220,150,150]" :operate="true" :check="true" :noform="true"></tableA>
                </div>
            </div>

            <div class="each-item mt-20 pl-30" v-if="activityVo.rangeType =='1'">
                <div class="item-title fl">参与活动商品</div>
                <div class="item-body fl">全部商品</div>
            </div>
            <div class="each-item mt-20 pl-30" v-else>
                <div class="item-title">参与活动商品</div>
                <div class="item-body ml-70">
                    <div>{{ activityVo.rangeType =='2' ? '部分商品参与' : '部分商品不参与' }}</div>
                    <tableB :size="[160,420,100]" :operate="true" :check="true" :noform="true" style='width: 683px;'></tableB>
                    <!--  <el-table :data="goodsSkuVos" tooltip-effect="dark" style="width: 740px" class="mt-10 table-default">
                       <el-table-column label="商品编码" prop='skuNo' width="">
                       </el-table-column>
                       <el-table-column label="商品名称" prop='skuName' width="">
                       </el-table-column>
                       <el-table-column label="库存" prop='stockEnableQuantity' width="">
                       </el-table-column>
                     </el-table> -->
                </div>
            </div>
            <div style="text-align:center;margin:10px">
                <el-button type="primary" @click="()=>{this.$router.go(-1)}">返回</el-button>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapMutations, mapActions} from 'vuex'
    import {axiosApi, basePath} from "../../../api/api"

    export default {
        data() {
            return {
                loading: false,
                activityGoodsRelationPoList: [],
                activityVo: {},
                giftRuleExPos: [],
                goodsSkuVos: [],
                isOpenSaleType: false ,//是否开通赠品类型
            }
        },
        methods: {
            ...mapMutations({
                'initclient': 'initclient',
                'initgood': 'initgood'
            }),
            //查看是否开通赠品类型权限
            findSellerJurisdiction() {
                axiosApi('/trader/findSellerJurisdiction/select', {
                    traderId: sessionStorage.traderId
                }, (res) => {
                    this.isOpenSaleType =  res.data.data.isOpenSaleType
                }, 1, 10, (res) => {

                })
            },
            getsentdetail() {
                this.loading = true
                const api = '/marketing/activityGift/Update/view'
                let body = {
                    activityId: sessionStorage.getItem('sentid')
                }
                let newbody = Object.assign({}, body)
                axiosApi(api, newbody, (res) => {
                    this.callback(res.data.data)
                }, 0, 10,err => {
                    this.loading = false;
                    this.$message.error(err.data.message)
                })
            },
            callback(res) {
                this.loading = false
                this.activityGoodsRelationPoList = res.activityGoodsRelationPoList
                this.activityVo = res.activityVo
                this.giftRuleExPos = res.giftRuleExPos
                if (this.activityVo.activityType != 4) {// 满赠
                    this.giftRuleExPos.map((a, b) => {
                        a.giftList = this.giftToview(a.activityGiftRelationLi)
                        return a
                    })
                }
                this.goodsSkuVos = res.goodsSkuVos
                setTimeout(() => {
                    //初始化客户列表
                    this.initclient(res.traderVos ? res.traderVos : [])
                    //初始化商品列表
                    this.initgood({willadd: res.goodsSkuVos ? res.goodsSkuVos : []})
                }, 0)
            },
            numTochinese(val) {// 数字转汉字
                if (!val && val != 0) return null;
                let arr = ["零", "一", "二", "三", "四", "五", "六", "七", "八", "九"];
                let a = '';
                arr.forEach((item, index) => {
                    if (val == index) {
                        a = item
                    }
                })
                return a
            },
            giftToview(arr = []) {// 后端数据 转化 前端显示结构
                let newArr = [...arr], viewArr = []
                newArr.forEach((a, b) => {
                    if (viewArr[a.groupSort - 1]) {
                        if (viewArr[a.groupSort - 1].subgiftList) {
                            viewArr[a.groupSort - 1].subgiftList.push(a)
                        } else {
                            viewArr[a.groupSort - 1].subgiftList = []
                            viewArr[a.groupSort - 1].subgiftList.push(a)
                        }
                    } else {
                        viewArr[a.groupSort - 1] = {}
                        viewArr[a.groupSort - 1].subgiftList = []
                        viewArr[a.groupSort - 1].subgiftList.push(a)
                    }
                })
                return viewArr
            },
        },
        mounted() {
            this.$nextTick(() => {
                this.getsentdetail()
                this.findSellerJurisdiction()
            })
        }
    }
</script>
<style lang="less">
	.ml-70{
		margin-left: 70px;
	}
    .big-title {
        text-align: center;
        font-size: 24px;
    }

    .item-list {
        font-size: 15px;

        .each-item {
            overflow: hidden;

            .item-title {
                width: 160px;
                text-align: right;
                padding-right: 20px;
            }
        }
    }
</style>
