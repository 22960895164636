import { axiosApi, basePath } from '../../api/api.js'
const help = {
  state: {
    list: [],
    form: {},
    total: 0,
    loading: false,
    name: {
      rootNanme: '',
      menuNanme: '',
      detailName: ''
    },
    currentPage: 1,
  },
  mutations: {
    setQuestionList(state, payload) {
      state.list = payload
    },
    setQuestionTotal(state, payload) {
      state.total = payload
    },
    setLoading(state, payload) {
      state.loading = payload
    },
    setName(state, payload) {
      state.name = payload
    },
    setCurrentPage(state, payload) {
      state.currentPage = payload
    },
  },

  actions: {
    // 获取问题列表 
    getQuestionList({ state }, payload) {
      state.loading = true
      // state.currentPage = payload.currentPage
      const api = '/foundation/support/question/search'
      axiosApi(api, payload, (res) => {
        if (res.data.code === '000000') {
          state.total = res.data.data.totalCount
          state.list = res.data.data.elements
          state.loading = false
        }
      }, 1, 10)
    },

  },
  getters: {

  }
}

export default help