<template>
    <div class="payment-manager">
      <!-- 门店角色-》门店佣金管理-》线下提现 -->
        <div class="title-top bg-f mb-5">
            佣金明细
        </div>
        <commissionDetailStoreSelf></commissionDetailStoreSelf>
    </div>
</template>

<script>
    import commissionDetailStoreSelf from './commissionDetailStoreSelf'
    export default {
        name: "storeGoodsManage",
        components:{
            commissionDetailStoreSelf
        }
    }
</script>

<style scoped>

</style>
