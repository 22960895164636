<template>
	<div id="client-address" class="pb-20">
		<div class="title-tabs bg-f">
			<ul class="clearfix" @click='handleSortTabs'>
				<template v-for='(tab,index) in dataSortTabs' class="item">
					<li :key="index" :type='index+1' :class="sortType==index+1?'active':''">
						{{tab}}
						<div :class="sortType==index+1?'under-line':''"></div>
					</li>
					<div :key="tab" class="vertical-line"></div>
				</template>
			</ul>
		</div>
		<!-- 搜索条件 -->
		<div class="bg-f pb-50 pl-30 pr-30" v-show="sortType != 2">
			<el-form :inline="true" label-width="70px" class="clearfix pt-30  mt-5 search">
                <el-form-item label="客户名称:">
                    <el-input v-model.trim="storeName"></el-input>
                </el-form-item>
                <el-form-item label="联系电话:">
                    <el-input v-model.trim="tel" @input="tel=tel.replace(/[\u4e00-\u9fa5]/ig,'')"></el-input>
                </el-form-item>
                <el-form-item label="客户类型:">
                    <el-select v-model="ctype">
                        <el-option v-for="item in customer" :key="item.value" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="状态:">
                    <el-select v-model="addressState">
                        <el-option label="全部" value=""></el-option>
                        <el-option label="待审核" value="1"></el-option>
                        <el-option label="正常使用" value="2"></el-option>
                        <el-option label="未通过" value="3"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="所属分组:">
                    <!-- <el-select v-model="groupId" clearable>
                        <el-option v-for="n in groupList" :key="n.id" :label="n.groupName" :value="n.id"></el-option>
                    </el-select> -->
										 <TreeGroup v-model="groupId"/>
                </el-form-item>
                <el-form-item label="所属区域:">
                    <el-cascader :props="districtProps" :options="districtList" clearable change-on-select placeholder="选择区域" v-model="districtIds"></el-cascader>
                </el-form-item>
			</el-form>
            <div class="pt-5">
                <el-button type="info" icon="el-icon-search"  @click="loading(true)">查询</el-button>
            </div>
			<!--收货地址编辑-->
			<addressEdit v-on:closedialog="addressCloseDialog" :sortType="sortType" :addressVisible="addressDialog1"></addressEdit>
			<!--收货地址审核-->
			<addressCheck v-on:closedialog="addressCloseDialog" :sortType="sortType" :addressVisible="addressDialog2"></addressCheck>
			<el-table key="deliveryAddress" :data="userData" tooltip-effect="dark" stripe class="mt-20 table-default" @selection-change="handleSelectionChange">
				<el-table-column prop="storeName" label="客户名称">
				</el-table-column>
				<el-table-column prop="linkman" label="收货人" width="110">
				</el-table-column>
				<el-table-column prop="tel" label="联系电话" width="110">
				</el-table-column>
				<el-table-column prop="groupName" label="所属分组" width="100">
				</el-table-column>
				<el-table-column prop="districtName" label="所属区域" width="80">
				</el-table-column>
				<el-table-column label="所在地区">
					<template slot-scope="scope">
						{{scope.row.provinceName}}{{scope.row.cityName}}{{scope.row.regionName}}{{scope.row.streetName}}
					</template>
				</el-table-column>
				<el-table-column prop="attr" label="详细地址" width="100">
				</el-table-column>
				<el-table-column label="状态" width="100">
					<template slot-scope="scope">
						<div v-if="scope.row.state == 1">待审核</div>
						<div v-if="scope.row.state == 2">正常使用</div>
						<div v-if="scope.row.state == 3">未通过</div>
					</template>
				</el-table-column>
				<el-table-column label="操作" width="90">
					<template slot-scope="scope">
						<div>
							<p class="tac">
								<el-button v-if="showButton('CUSTOMER_AUDIT_LABEL_ADD',100056) && scope.row.state == 1" @click="checkAddress(scope.row)" type="text" size="small">审核</el-button>
							</p>
							<p class="tac">
								<el-button v-if="showButton('EDIT',100056)&&scope.row.state != 1" @click="editAddress(scope.row)" type="text" size="small">编辑</el-button>
							</p>
						</div>
					</template>
				</el-table-column>
			</el-table>

			<el-pagination v-if="total>0" @current-change="handleCurrentChange" @size-change="handleSizeChange" class="tar mt-20 mr-50" :current-page="currentPage"  :page-sizes='[10, 20, 50, 100]' :page-size="pageSize" :total='total' layout="total,sizes,prev , pager, next, jumper">
				<!-- 分页组件 -->
			</el-pagination>
		</div>
		<Client class="bg-f pt-30 font-14 pb-50" style="min-height: 500px;" v-show="sortType == 2" :skuInfo="skuInfo" :activeTab="'0'"></Client>
	</div>
</template>

<script>
	import { axiosApi, basePath } from "../../../api/api"
	import { mapMutations } from 'vuex'
	import TreeGroup from '~/components/treeGroup'
	export default {
		components:{
			TreeGroup
		},
		data() {
			return {
				skuInfo:{},
				sortType:1,
				dataSortTabs: ['收货地址管理','收货地址设置'],
				basePath: basePath,
				addressState: "", //收货地址 状态
				addressDialog2: false, //收货地址 审核
				addressDialog1: false, //收货地址 编辑
				type: 0, //tab
				tel: "", //联系电话
				activeTab: '1',
				userData: [],
				multipleSelection: [],
				customer: [{
					label: "全部",
					value: ""
				}, {
					label: "经销商",
					value: "1"
				},{
					label: "终端",
					value: "2"
				},{
					label: "久加久*久柜",
					value: "3"

				},{
					label: "久加久*连锁店",
					value: "4"
				},{
					label: "久加久*店中店",
					value: "5"
				},{
                    label: "普通酒店",
                    value: "6"
                }],
				ctype: "",
				// isRetailer: "",
				// isDealer: "",
				traderNumber: "",
				storeName: "",
				loginName: "",
				isDisable: "",
				currentPage: 1,
				pageSize: 10,
				total: 0,
				// groupList: [],
				groupId: null,
				districtList: [],
				districtIds: [],
				districtProps: {
					label: 'districtName',
					value: 'id',
					children: 'subDistrictList'
				}
			}
		},
		activated() {
			this.loading();
			this.getSellerAdressList();
			//区域
			axiosApi('/trader/customerDistrict/query',{traderId: sessionStorage.getItem('traderId')},(res)=>{
				this.districtList = [res.data.data];
				this.districtList[0].id = '0';
			})
		},
		methods: {
			...mapMutations({
				'userAddressInfo': 'userInfo',
				// 'setDataList':'setDataList'
			}),
			//获取卖家开通/关闭审核收货地址信息
			getSellerAdressList(){
				axiosApi('/trader/sellerIsOpenAuditDeliveryAddress/select',{ traderId:sessionStorage.getItem('traderId') },res => {
					this.skuInfo = res.data.data;
				},1,10000,err => {
					this.$message.error(err.data.message)
				})
			},
			handleSortTabs(e){// B2B营销、酒店营销切换
				if(e.target.attributes.type){
					// 改变 type的值
					this.sortType = e.target.attributes.type.value;
					if(this.sortType != 2){
						this.traderNumber = '',
						this.storeName='',
						this.loginName='',
						this.isDisable='',
						this.addressState='',
						this.tel='',
						this.groupId='',//所属分组
						this.loading()
					}
				}
			},
			editAddress(val) { //地址编辑
				this.addressDialog1 = true
				console.log(val,'地址编辑')
				this.userAddressInfo(val)
			},
			checkAddress(val) { //地址审核
				this.addressDialog2 = true
				this.userAddressInfo(val)
			},
			handleSelectionChange(val) {
				this.multipleSelection = val;
			},
			handleSizeChange(val) {
				this.pageSize = val
				this.loading()
        console.log(`每页 ${val} 条`);
      },
			handleCurrentChange(val){
				this.currentPage = val;
				this.loading()
			},

			loading(bool) {
				if(bool&&this.currentPage != 1) {
					this.currentPage = 1;
					return
				}
				this.$loading();
				let api = this.sortType==1 ? "/trader/sellerFindClientAddressList/select" : '/trader/sellerFindClientAddressList/select';

				const body = {
                    // isHotel:this.sortType==1 ? false : true,
					sellerId: sessionStorage.traderId,
					traderNumber: this.traderNumber,
					storeName: this.storeName,
					loginName: this.loginName,
					ctype: this.ctype,
					isDisable: this.isDisable,
					state: this.addressState,
					tel: this.tel,
					groupId: this.groupId,//所属分组
					districtId: this.districtIds[this.districtIds.length - 1]//所属区域
				};



				axiosApi(api, body, res => {
					this.$loading().close();
					this.userData = res.data.data ? res.data.data.elements : [];
					// this.currentPage = res.data.data ? res.data.data.pageIndex : 1;
					this.total = res.data.data ? res.data.data.totalCount : 0;
				}, this.currentPage, this.pageSize,1)
			},
			addressCloseDialog(payload) { //收货地址弹窗
				if(payload.type === 1) { //编辑
					this.addressDialog1 = false
				}else if(payload.type === 2) { //审核
					this.addressDialog2 = false
				}
				if(payload.isLoading){
					this.loading()
				}
			}
		}
	}
</script>
<style lang="less">
	#client-address {
		position: relative;
		.el-form-item__label {
			font-size: 12px;
		}
	}
</style>
