<template>
  <div id="activity">
    <div class="title-tabs bg-f mb-5">
        <ul class="clearfix">
            <div v-for='(tab,index) in dataSortTabs' class="item" :key="tab.id+''">
                <li :class="sortType==tab.id?'active':''"  @click='handleSortTabs(tab.id)'>
                    {{tab.name}}
                    <div :class="sortType==tab.id?'under-line':''"></div>
                </li>
                <el-divider direction="vertical" v-if="index!=dataSortTabs.length-1"></el-divider>
            </div>
        </ul>
    </div>
    <div v-if="sortType != 4">
      <div class="title-tabs bg-f">
        <el-tabs v-model="type" @tab-click="handleTabs" class="activityTabs">
          <el-tab-pane v-if="(!(sortType == 3 && index != 0))" :label="item" :name="index + ''" v-for="(item, index) in dataTabs" :key="index"></el-tab-pane>
        </el-tabs>
      </div> 
      <div class="content bg-f pl-30 pr-30 pt-30 manage">

        <div class='tar'>
            <el-button style="width: auto;padding: 0 15px;" v-show="type=='0'" icon="el-icon-plus" @click="addticket">新增优惠券</el-button>
            <el-button style="width: auto;padding: 0 15px;" v-show="type=='1' || type =='2'" icon="el-icon-plus" @click="addnewactivity">添加新活动</el-button>
        </div>
        <div v-if="type != '3'" style="margin:10px 0">
          <el-form :inline="true" :model="searchform">
            <el-form-item label="活动名称">
              <el-input v-model="searchform.activityName" placeholder="活动名称"></el-input>
            </el-form-item>
            <el-form-item label="参与商品">
              <el-select
                v-model="searchform.skuId"
                filterable
                remote
                clearable
                reserve-keyword
                placeholder="请输入关键词"
                :remote-method="getGoods"
                >
                <el-option
                  v-for="item in goodsList"
                  :key="item.id"
                  :label="item.skuName"
                  :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="参与客户">
              <el-select
                v-model="searchform.findByBuyerId"
                filterable
                remote
                clearable
                reserve-keyword
                placeholder="请输入关键词"
                :remote-method="getUser"
                >
                <el-option
                  v-for="item in elements"
                  :key="item.id"
                  :label="item.storeName"
                  :value="item.buyerId">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="searchList">查询</el-button>
            </el-form-item>
          </el-form>
        </div>
        <!-- 优惠券列表 -->
        <div v-show="type == '0'">
            <el-tabs v-model="activeTab1" @tab-click="handleTab1Click">
                <el-tab-pane label="全部" name="1"></el-tab-pane>
                <el-tab-pane label="未开始" name="2"></el-tab-pane>
                <el-tab-pane label="进行中" name="3"></el-tab-pane>
                <el-tab-pane label="已结束" name="4"></el-tab-pane>
                <el-tab-pane name="5">
                  <span slot="label">待审核<span v-if="couponCount" class="color-danger">({{couponCount}})</span></span>
                </el-tab-pane>
            </el-tabs>
            
            <el-table :data="tableData2" tooltip-effect="dark" class="mt-20 table-default">
                <el-table-column label="活动名称" min-width="135">
                <template slot-scope="scope">
                    <div @click="toticketdetail(scope.$index, tableData2)" :style="{color: '#20a0ff',cursor: 'pointer'}">{{scope.row.couponName}}</div>
                </template>
                </el-table-column>
                <el-table-column label="发放时间" min-width="260">
                <template slot-scope="scope">
                    <div>{{scope.row.gmtSendStart | time}}  至  {{scope.row.gmtSendEnd | time}}</div>
                </template>
                </el-table-column>
                <el-table-column label="优惠券金额" min-width="80">
                <template slot-scope="scope">
                    <div>{{scope.row.minusMoney}}</div>
                </template>
                </el-table-column>
                <el-table-column label="发放数量">
                <template slot-scope="scope">
                    <div>{{scope.row.num}}</div>
                </template>
                </el-table-column>
                <el-table-column label="已领取">
                <template slot-scope="scope">
                    <div>{{scope.row.num-scope.row.remainNum}}</div>
                </template>
                </el-table-column>
                <el-table-column label="参与商品">
                    <template slot-scope="scope">
                        <div>{{scope.row.rangeSkuType==1?"全部商品":"部分商品"}}</div>
                    </template>
                </el-table-column>
                <el-table-column label="参与客户">
                    <template slot-scope="scope">
                        <div>{{scope.row.rangeTraderType==1?"全部客户":"部分客户"}}</div>
                    </template>
                </el-table-column>
                <el-table-column label="状态" min-width="70">
                  <template slot-scope="scope">
                      <div>{{scope.row.startStatus | status}}</div>
                  </template>
                </el-table-column>
                <el-table-column label="审核状态" min-width="70">
                  <template slot-scope="{row}">
                      <el-tooltip placement="top" v-if="row.auditState==2">
                        <div slot="content">审核原因:<br/>{{row.auditReason}}</div>
                        <div>{{row.auditStateName}}</div>
                      </el-tooltip>
                      <div v-else>{{row.auditStateName}}</div>
                  </template>
                </el-table-column>

                <el-table-column v-if="sortType == '3'" label="业务" min-width="70">
                <template slot-scope="scope">
                    <div>{{scope.row.couponSource == 1?'B2B':scope.row.couponSource == 2?'酒店':scope.row.couponSource == 3?'社会久集':''}}</div>
                </template>
                </el-table-column>
                <el-table-column label="操作" min-width="90">
                <template slot-scope="scope">
                    <el-link type="primary" :underline="false" style="display:block;margin:5px" v-if="scope.row.auditState==1 && showButton('AUDIT',100095)" @click="audit(scope.row.id)">审核</el-link>
                    <el-link type="primary" :underline="false" style="display:block;margin:5px" v-if="scope.row.startStatus == 2" @click="updateticket(scope.$index, tableData2)">编辑</el-link>
                    <el-link type="primary" :underline="false" style="display:block;margin:5px" v-if="scope.row.startStatus == 2" @click="deleteticket(scope.$index, tableData2)">删除</el-link>
                    <el-link type="primary" :underline="false" style="display:block;margin:5px" v-if="scope.row.startStatus == 3 && scope.row.status == 1" @click="stopticket(scope.$index, tableData2)">{{scope.row.status == 1 ? '终止' : ''}}</el-link>
                    <el-link type="primary" :underline="false" style="display:block;margin:5px" @click="toticketdetaildetail(scope.$index, tableData2)">领取明细</el-link>
                    <el-link type="primary" :underline="false" style="display:block;margin:5px" @click="copyAdd(scope.$index, tableData2)">复制</el-link>
                </template>
                </el-table-column>
                <div slot="empty" v-if="sortType == '3' && activeTab1 == '1'">
                  <div>
                    <span>
                      您还没有发放优惠券，<a class="underLine" :href="goLink">现在去发</a>
                    </span>
                  </div>
                </div>
            </el-table>

            <el-pagination class="tar" v-if='type0total>0'
                :current-page="currentPage2"
                :page-size='10'
                :total='type0total'
                layout="prev , pager, next, jumper"
                @current-change="handlePageChange">
            </el-pagination>
            
        </div>
      

        <!-- 满赠活动列表 -->
        <div v-if="type == '1'|| type == '2'">
            <enoughsent ref="enoughsent" :activityType="type" :activityName='searchform.activityName' :skuId="searchform.skuId" :findByBuyerId="searchform.findByBuyerId" @changStatusType="changStatusTypeFun"></enoughsent>
        </div>
        <!-- 满减活动列表 -->
        <!-- <div v-show="type == '2'">
            <enoughcut></enoughcut>
        </div> -->

        <!-- 赠品管理 -->
        <div v-show="type == '3'">
            <giftmanage></giftmanage>
        </div>
        
      </div>
    </div>
    <recommend v-if="sortType == 4"/>

    <!-- 审核弹框 -->
    <el-dialog title="审核" :visible.sync="dialogVisible" @close="dialogClose">
      <el-form :model="parmas" ref="parmas" :rules="rules" label-width="100px" style="margin: 0 20px">
        <el-form-item label="审核状态" prop="auditState">
          <el-select v-model="parmas.auditState" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="审核理由" prop="auditReason" :rules="{ required: parmas.auditState==2?true:false, message: '请输入审核理由', trigger: 'blur'}">
          <el-input
            type="textarea"
            :rows="2"
            placeholder="请输入审核理由"
            v-model="parmas.auditReason">
          </el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitAudit">确 定</el-button>
        <el-button @click="dialogClose">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {mapActions, mapMutations} from 'vuex'
import { axiosApi,basePath } from '../../../api/api' //导入axiosApi
import { auditCoupon, auditCouponCount } from "~/api/axiosInfo";
import recommend from "./recommend.vue";
export default {
  components:{
    recommend
  },
  data() {
    return {
      goLink:`${basePath}/activities/addgoodticket?couponSource=3`,
      type: '0',
      activeTab1: '1',
      currentPage2: 1,
      dataSortTabs: [
        {
          id:1,
          name: 'B2B营销',
        },
        // {
        //   id:2,
        //   name: '酒店营销',
        // },
        {
          id:3,
          name: '久集营销'
        },
         {
          id:4,
          name: '推荐商品'
        },
        
      ],
      dataTabs: ['优惠券管理', '满赠活动', '满减活动', '赠品管理'],
      statusType: null,
      auditState: null,
      searchform: {
        activityName: '',
        skuId: '',
        findByBuyerId: ''
      },
      loading: false,
      elements: [],
      goodsList: [],
      couponCount: null,
      // 审核
      dialogVisible: false,
      parmas: {
        id: null,
        auditState: 3,
        auditReason: null,
      },
      options:[
        {value:3,label:'审核通过'},
        {value:2,label:'审核驳回'},
      ],
      rules: {
        auditState: [
          { required: true, message: '请选择审核状态', trigger: 'change' }
        ],
      },
    }
  },
  watch: {
    tableData2(val) {
      val.forEach((a,b)=>{
        if(a.status == '2') {
          a.startStatus = 5
        }
      })
    }
  },
  activated() {
    this.$nextTick(()=> {
      
      switch(this.$route.params.type) {
        case "2":
          this.type = "1"
          break;
        case "3": 
          this.type = "2"
          break;
        case "4": 
          this.type = "3"
          break; 
        // default: 
        //   this.type = "0"
        //   break;
      }
      

      if(this.type == 0) {
        this.querycouponlist(this.postData2);
        this.auditCouponCount()
      }

      if(this.type != '0'){
        
        this.queryactivitypage({
          data: {
            status: this.statusType,
            activityType: this.type == 1?0:this.type,
            activitySource: this.sortType, //'B2B营销', '酒店营销'
            activityName: this.searchform.activityName||'',
            skuId: this.searchform.skuId||'',
            findByBuyerId: this.searchform.findByBuyerId||'',
            auditState:this.auditState
          },
          currentPage: 1
        })
        this.$nextTick(()=>{
          this.$refs.enoughsent.auditActivityCount()
        })
      }
    })
  },
  computed: {
    tableData2() {
      return this.$store.state.ticket.ticketlist ? this.$store.state.ticket.ticketlist.elements : []
    },
    type0total() {
      return this.$store.state.ticket.ticketlist ? this.$store.state.ticket.ticketlist.totalCount : 0
    },
    postData2() {
      return {
        data: {
          startStatus: this.activeTab1,
          sellerId: sessionStorage.traderId,
          couponSource: this.sortType, //'B2B营销', '酒店营销'
          activityName: this.searchform.activityName||'',
          skuId: this.searchform.skuId||'',
          findByBuyerId: this.searchform.findByBuyerId||'',
          auditState: this.auditState
        },
        currentPage: this.currentPage2
      }
    },
    sortType() {// '0' - B2B营销，'1' - 酒店营销
        return this.$store.state.activity.marketingCategory
    }
  },
  methods: {
    ...mapMutations({
        'changeMarketingCategory': 'changeMarketingCategory'
    }),
    ...mapActions({
      'querycouponlist': 'queryticketlist',// 优惠券活动查询
      'queryactivitypage': 'queryactivitypage'// 满赠活动列表
    }),
    auditCouponCount(){
      var data = {
        sellerId: sessionStorage.traderId,
        sortType: this.sortType
      }
      auditCouponCount(data).then(res=>{
        this.couponCount = res
      }).catch(err=>{

      })
    },
    audit(id){
      this.dialogVisible = true
      this.parmas.id = id
    }, 
    dialogClose(){
       this.dialogVisible = false
       this.parmas = {}
       this.$refs['parmas'].resetFields();
    },
    submitAudit(){
      this.$refs['parmas'].validate((valid) => {
        if (valid) {
          auditCoupon(this.parmas).then(res=>{
            this.auditCouponCount()
            this.searchList()
            this.dialogClose()
          }).catch(err=>{

          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
      
    },
    changStatusTypeFun(val) {
      if(val==5){
        // 待审核tab
        this.auditState = 1
      }else{
        if(val == '2'){
            var status = '0'
        }else if(val == '3'){
            var status = '1'
        }else if(val == '4'){
            var status = '2'
        }else{
            var status = null
        }
        // status: null全部， 0未开始， 1进行中， 2已结束
        this.statusType = status
        this.auditState = null
      }
    },
    initForm(){
      this.searchform= {
        activityName: '',
        skuId: '',
        findByBuyerId: ''
      }
    },
    //页面跳转
    addticket() {
      if(this.sortType != '3'){
        this.$router.push({path: basePath + '/addacticket', query:{couponSource: this.sortType}} )
      }else{
        this.$router.push({path: basePath + '/activities/addgoodticket', query:{couponSource: this.sortType}} )
      }
      
    },
    copyAdd(index, row){
      // 复制并新增
      var couponid = row[index].id
      this.$router.push({path:basePath + '/activities/addticket',query:{couponSource: this.sortType,couponid:couponid}})
    },
    searchList(){
      // sortType
      // type
      console.log(this.type,'type')
      if(this.type==0){
        this.querycouponlist(this.postData2);
      }else if(this.type==1||this.type==2){
        console.log('满赠搜索')
        this.queryactivitypage({
          data: {
            status: this.statusType ,
            activityType: this.type == 1?0:this.type,
            activitySource: this.sortType, //'B2B营销', '酒店营销'
            activityName: this.searchform.activityName||'',
            skuId: this.searchform.skuId||'',
            findByBuyerId: this.searchform.findByBuyerId||'',
            auditState:this.auditState
          },
          currentPage: 1
        })
      }
    },
    getGoods(query){
      var data = {
        skuName:query,
        sellerId: sessionStorage.traderId,
        auditState: 2
      }
      if (query !== '') {
          axiosApi('/goods/skuInfoPage/select', data, (res)=> {
            if(res.data.code==='000000'){
              this.goodsList = res.data.data.elements
            }
          })
      } else {
        this.goodsList = [];
      }
    },
    getUser(query){
      var that = this
      var url = '/trader/findClientAllList/select'
      var data = {
        storeName:query,
        sellerId: sessionStorage.traderId,
      }
      if (query !== '') {
          axiosApi(url, data, (res)=> {
            if(res.data.code==='000000'){
              this.elements = res.data.data.elements
            }
          })
      } else {
        this.elements = [];
      }
    },
    toticketdetail(index, row) {
      var couponid = row[index].id
      sessionStorage.setItem('couponid',couponid)
      this.$router.push({path:basePath + '/activities/eachticketdetail',query:{couponSource: this.sortType}})
    },
    toticketdetaildetail(index, row) {
      var couponid = row[index].id
      sessionStorage.setItem('couponid',couponid)
      this.$router.push({path:basePath + '/ticketdetail',query:{couponSource: this.sortType}})
    },
    //请求优惠券列表
    handlePageChange(val) {
      this.currentPage2 = val
      this.querycouponlist(this.postData2);
    },
    //切换优惠券管理的tabs
    handleTab1Click(tab, event) {
      if(this.activeTab1==5){
          // 待审核
          this.auditState = 1
      }else{
          // 其他tab
          this.auditState = null
      }
      this.currentPage2 = 1
      this.querycouponlist(this.postData2)
     
    },
    handleSortTabs(id){// B2B营销、酒店营销切换
      this.auditState = null
      this.initForm()
      if(id){
        // 改变 type的值 
        this.changeMarketingCategory(id)// 改变vuex 存储的 营销分类字段

        this.type = '0'
        this.auditState = null,
        this.activeTab1 = '1'

        if(this.type == 0) {
          this.querycouponlist(this.postData2);
          this.auditCouponCount()
        }
        if(this.type == 1 || this.type == 2 ){
          this.$refs.enoughsent.auditActivityCount()
          this.queryactivitypage({
            data: {
              // status: 3,//3全部， 0未开始， 1进行中， 2已结束
              status: this.statusType == '1' ? '3' : this.statusType == '2' ? '0' : this.statusType == '3' ? '1' : '2' ,
              activityType: this.type == 1?0:this.type,
              activitySource: this.sortType, //'B2B营销', '酒店营销'
              activityName: this.searchform.activityName||'',
              skuId: this.searchform.skuId||'',
              findByBuyerId: this.searchform.findByBuyerId||'',
            },
            currentPage: 1
          })
        }
      }
    },
    handleTabs() {
      this.initForm()
      console.log(this.type,'this.type')
      if(this.type == 1 || this.type == 2){
        this.queryactivitypage({
            data: {
                status: 3,//3全部， 0未开始， 1进行中， 2已结束
                activityType: this.type == 1?0:this.type,// 满赠activityType传值0，满减，限时限购不变
                activitySource: this.sortType, //'B2B营销', '酒店营销'
                activityName: this.searchform.activityName||'',
                skuId: this.searchform.skuId||'',
                findByBuyerId: this.searchform.findByBuyerId||''
            },
            currentPage: 1
        })
        if(this.type == 1 || this.type == 2 ){
          this.$nextTick(()=>{
            this.$refs.enoughsent.auditActivityCount()
          })
        }
      }else{
        this.auditCouponCount()
      }
    },
    //添加新活动
    addnewactivity() {
      if(this.type == '1') {// 满赠活动
        // this.$router.push(basePath + '/activities/addacaboutenoughsent')
        this.$router.push({path: basePath + '/addfullactivity', query:{activitySource: this.sortType}})
      } else if(this.type == '2') {
        this.$router.push({path: basePath + '/activities/addacaboutenoughcut', query:{activitySource: this.sortType}})
      }
    },
    updateticket(index, rows) {
      sessionStorage.setItem('ticketid', rows[index].id)
      this.$router.push({path : basePath+'/activities/updateticket', query:{couponSource: this.sortType}})
    },
    /* deleteRow(index, rows) {
      let that = this;
      this.$msgbox({
        title: '提示信息',
        message: '您确定要删除这条信息？',
        showCancelButton: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        beforeClose: (action, instance, done) => {
          if(action === 'confirm') {
            const api = "/goods/sku/delete";
            const body = {
              id: this.tableData[index].id
            };
            axiosApi(api, body, function(res) {
              done()
              that.achieve(); //再次刷新商品列表
            })
          } else {
            done();
          }
        }
      })
    }, */
    deleteticket(index, rows) {
      let that = this;
      this.$msgbox({
        title: '提示信息',
        message: '您确定要删除此优惠券？',
        showCancelButton: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        beforeClose: (action, instance, done) => {
          if(action === 'confirm') {
            const api = "/marketing/coupon/delete";
            const body = {
              id: rows[index].id
            };
            axiosApi(api, body, function(res) {
              that.$notify({
                  type: 'success',
                  message: '删除优惠券成功'
              })
              that.querycouponlist(that.postData2)
              done()
            },0,10,(res)=> {
                that.$notify({
                    type: 'warning',
                    message: '删除优惠券失败，' + res.data.message
                })
                done()
            })
          } else {
            done();
          }
        }
      })
    },
    stopticket(index, rows) {
      let that = this;
      this.$msgbox({
        title: '提示信息',
        message: '您确定要停用此优惠券？',
        showCancelButton: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        beforeClose: (action, instance, done) => {
          if(action === 'confirm') {
            const api = "/marketing/coupon/stop";
            const body = {
              id: rows[index].id,
              operatorType: rows[index].status
            };
            axiosApi(api, body, function(res) {
              that.$notify({
                type: 'success',
                message: '已终止优惠券使用'
              })
              setTimeout(()=>{that.querycouponlist(that.postData2)},1000)
              done()
            },0,10,(res)=>{
              that.$notify({
                type: 'warning',
                message: res.data.message
              })
            })
          } else {
            done();
          }
        }
      })
    },
  }
}
</script>

<style lang="less">
#activity {
  overflow: hidden;
  .underLine{
    text-decoration-line: underline !important;
  }
  .activityTabs{
    .el-tabs__header{
      padding-left: 15px;
      // .el-tabs__item{
      //   height: 60px;
      //   line-height: 60px;
      // }
    }
  }
  .el-table__body-wrapper {
  margin-top:1px!important;
  }
}
.manage {
  padding-bottom: 20px;
  .el-form--inline {
    .el-form-item__label {
      font-size: 12px;
      width: 60px;
    }
  }
}
</style>
