<template>
    <div id="superBHotGoods">
        <!-- head -->
        <div class="title-top bg-f mb-5">
            <div class="clearfix black">
                <el-breadcrumb class="fl ">
                    <el-breadcrumb-item>热门商品排行榜</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
        </div>

        <!-- body -->
        <div class="bg-f">

            <!-- list -->
            <div class="pl-30 pr-30 pb-30">

                <el-table :data="tableData" v-loading="Loading" class='mt-10 table-default' stripe>
                    <el-table-column prop="1" label="商品名称" width="310">
                        <template slot-scope="scope">
                            <div class="info flex clearfix ">
                                <div class="ml-10">
                                    <img v-if='scope.row.fileUrl' :src="scope.row.fileUrl"/>
                                    <img v-else src="../../assets/goods.jpg"/>
                                </div>
                                <div class="flex-1 ml-10 mt-10 hidden">
                                    <div class="tal">商品编码：{{scope.row.skuNo}}</div>
                                    <div class="tal">{{scope.row.skuName}}</div>
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="规格">
                        <template slot-scope="scope">
                            <div>1*{{scope.row.specInfoNum}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column label="分销佣金">
                        <template slot-scope="scope">
                            <div>￥{{scope.row.minCommission | fmoney}}-￥{{scope.row.maxCommission | fmoney}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column label="一级补贴" >
                        <template slot-scope="scope">
                            <div>￥{{scope.row.subsidyOne | fmoney}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column label="二级补贴">
                        <template slot-scope="scope">
                            <div>￥{{scope.row.subsidyTwo | fmoney}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column label="销量" prop="sales"></el-table-column>
                </el-table>

                <!-- index -->
                <el-pagination class="tar mr-30" v-if="agencyTotal>0" :current-page="agencyProPage" :page-size='10'
                               :total='agencyTotal'
                               layout="prev , pager, next, jumper" @current-change="handleCurrentChange">
                </el-pagination>
            </div>

        </div>

    </div>

</template>

<script>
    import {axiosApi, basePath} from "../../api/api";

    export default {
        name: "superBHotGoods",
        data() {
            return {
                Loading:false,
                agencyProPage: 1,
                agencyTotal: 0,
                tableData: [],//商品来源
            }
        },
        methods: {
            //搜索
            achieve() {
                this.Loading = true;

                let body = {
                    sellerId: sessionStorage.traderId,
                };
                let url = '/superb/statistics/skuList'
                axiosApi(url, body, res => {
                    this.Loading = false;
                    this.agencyTotal = res.data.data.totalCount;
                    this.tableData = res.data.data.elements;
                }, this.agencyProPage, 10, err => {
                })
            },
            //分页
            handleCurrentChange(val) {
                this.agencyProPage = val;
                this.achieve();
            },
        },
        mounted() {
            this.$nextTick(() => {
                this.achieve()
            })
        }
    }
</script>

<style lang="less">
    #superBHotGoods {
      
    }
</style>