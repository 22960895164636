<template>
	<div class="step1 clearfix" :class="{ 'width-1280': !hlepregister }">
		<div v-if="hlepregister" class="title-top bg-f mb-5">
			代客注册
		</div>
		<div class="bg-f" :class="{ 'replace': hlepregister,'step': !hlepregister }">
			<div class="mb-50">
				<!-- <img class="sub mr-10" src="../../assets/register.png" /> -->
				<i class="iconfont icon-inspector color-b font-18 mr-10"></i>
				<span class="color-6 font-16">1/3{{hlepregister?"设置客户用户名":"验证手机号"}}</span>
			</div>
			<el-form :model="form" :rules="rules" ref="form" :label-width='hlepregister ? "170px" : "340px"'>
				<el-form-item :label='hlepregister?"客户手机号码:":"手机号:"' prop="phone">
					<el-input v-model.trim="form.phone" :maxlength="11" @change="loading(1)"></el-input>
					<p v-if="exist" class="color-danger lh-12 mt-5">该手机号已经注册，如果忘记密码请通过验证码登录！</p>
				</el-form-item>
				<el-form-item label='再次确认手机号码' prop="phone2" v-if="hlepregister">
					<el-input type="text" v-model.trim="form.phone2" :maxlength="11" autocomplete='new-password'></el-input>
				</el-form-item>
                <el-form-item label=' ' v-if="!hlepregister">
                    <div id="c1"></div>
				</el-form-item>
				<el-form-item label="短信验证码:" prop="code" v-if="!hlepregister">
					<el-input class="w105 mr-20 fl" v-model.trim="form.code" :maxlength="6" @change="isCode=false"></el-input>
					<el-button class="w115 fl" @click="loading(2)" :disabled="codeDisabled">
						<span v-if="codeBool">获取验证码<span v-show="timeBool">{{time | seconds}}</span></span>
						<span v-else>获取验证码</span>
					</el-button>
					<p v-if="isCode" class="color-danger lh-12 mt-5">{{message}}</p>
				</el-form-item>
				<el-form-item style="width: 1px;height:0px;overflow:hidden;margin:0;">
					<el-input type="text" v-model.trim="form.code0" :maxlength="6" ></el-input>
				</el-form-item>
				<el-form-item style="width: 1px;height:0px;overflow:hidden;margin:0;">
					<el-input type="password" v-model="form.pass0" :maxlength="16"></el-input>
				</el-form-item>
				
				<el-form-item class="mt-50" label="设置密码:" prop="pass">
					<el-input :type="showPass?'text':'password'" @keyup.native="isCaps($event)" @blur="capB1 = false" @focus="isCaps($event)" v-model="form.pass" :maxlength="20" auto-complete="off">
						<i slot="suffix" class="el-icon-view" @click="showPass=!showPass"></i>
					</el-input>
					<!-- <el-input type="password" @keyup.native="isCaps($event)" @blur="capB1 = false" @focus="isCaps($event)" v-model="form.pass" :maxlength="16" auto-complete="off"></el-input> -->
					<img class="cap" v-if="capB1" src="../../assets/caps.png" />
					<div v-if="form.pass.length>=6" id="tips"><span></span><span></span><span></span><span></span></div>
				</el-form-item>
				<el-form-item label="确认密码:" prop="checkPass">
					<el-input :type="showCheckPass?'text':'password'" @keyup.native="isCaps2($event)" @blur="capB2 = false" @focus="isCaps2($event)" v-model="form.checkPass" :maxlength="20" >
						<i slot="suffix" class="el-icon-view" @click="showCheckPass=!showCheckPass"></i>
					</el-input>
					<!-- <el-input type="password" @keyup.native="isCaps2($event)" @blur="capB2 = false" @focus="isCaps2($event)" v-model="form.checkPass" :maxlength="16"></el-input> -->
					<img class="cap" v-if="capB2" src="../../assets/caps.png" />
				</el-form-item>
				<el-form-item>
					<div style="width: 240px;font-size: 12px;line-height: 1.2;">
						密码必须是6-20位，且数字、大写字母、小写字母、标点符号至少包含两种。
					</div>
				</el-form-item>
				<el-form-item v-if="!hlepregister">
					<el-checkbox v-model="disabled"></el-checkbox>
					已阅读
					<router-link :to="`${basePath}/aggrement`" target = _blank>
						<span class="color-3 hover-danger ml-6 color-blue cursor">《久加久云商平台用户注册协议》</span>
					</router-link>
				</el-form-item>
				<el-form-item class="mt-50" label="">
					<el-button type="danger" @click="goPage('form')" :disabled="disabled==false||exist==true">下一步</el-button>
				</el-form-item>
			</el-form>
		</div>
	</div>
</template>

<script>
	import { axiosApi, basePath } from "../../api/api"
	import { passcheck, capsisopen, passfocus } from "../../utils/passcheck.js"
	import md5 from "md5"
	function validatePassword(password) {
		const types = {
			digit: /\d/,
			upper: /[A-Z]/,
			lower: /[a-z]/,
			symbol: /[\W_]/ // 标点符号和其他符号
		};
		const requiredTypes = Object.values(types);
		const isValidLength = password.length >= 6 && password.length <= 20;
		const hasRequiredTypes = requiredTypes.some(regex => regex.test(password));
		const hasTwoTypes = requiredTypes.filter(regex => regex.test(password)).length >= 2;
	
		return isValidLength && hasRequiredTypes && hasTwoTypes;
	}
	export default {
		data() {
			var validatePass2 = (rule, value, callback) => {
				if(value === '') {
					callback(new Error('请再次输入密码'));
				} else if(value !== this.form.pass) {
					callback(new Error('两次输入密码不一致!'));
				} else {
					callback();
				}
			};
			var phoneCheck = (rule, value, callback) => {
				if(value === '') {
					callback(new Error('请再次输入手机号'));
				} else if(value !== this.form.phone) {
					callback(new Error('两次输入的手机号不一致!'));
				} else {
					callback();
				}
			};
			var validatePass = (rule, value, callback) => {
				if(value === '') {
					callback(new Error('请输入密码'));
				} else {

					const regex = /^(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*]).{6,20}$/;

					
					if(validatePassword(value)){
						if(this.form.checkPass !== '') {
							this.$refs.form.validateField('checkPass');
						}
						callback();
					}else{
						callback(new Error('密码必须是6-20位，且数字、大写字母、小写字母、标点符号至少包含两种。'));
					}
					
				}
			};
			return {
				showPass:false,
				showCheckPass: false,
			    token:'',
				one:'text',
				basePath: basePath,
				hlepregister: this.$route.path.indexOf('/replace') > 0 ? true : false,
				message: "", //验证码错误信息
				exist: false, //手机号错误消息是否显示
				isCode: false, //验证码错误信息是否显示
				disabled: true, //下一步按钮是否高亮
				codeDisabled: true, //获取验证码按钮是否可点击
				form: {
					code0:'',
					pass0:'',
					phone: "",
					phone2: "",
					code: "",
					pass: "",
					checkPass: ""
				},
				rules: {
					phone: [{
						required: true,
						pattern: /^1\d{10}$/,
						message: "请输入正确的手机号",
					}],
					phone2: [{
						required: true,
						validator: phoneCheck
					}],
					code: [{
						required: true,
						message: '请输入验证码',
						trigger: 'blur'
					}, {
						min: 6,
						max: 6,
						message: '请输入6位验证码',
						trigger: 'blur'
					}],
					pass: [{
						required: true,
						validator: validatePass,
						trigger: 'blur'
					}],
					checkPass: [{
						required: true,
						validator: validatePass2,
						trigger: 'blur'
					}],
				},
				time: "59",
				timeBool: false,
				codeBool: true,
				clearTime: -1,
				capB1: false, //判断大小写键是否打开
				capB2: false, //判断大小写键是否打开

			}
		},
		mounted() {
			this.$nextTick(() => {
                let _this = this
                if (!this.hlepregister) {
                    var myCaptcha = _dx.Captcha(document.getElementById('c1'), {
                        _noLogo: true,
                        style: 'inline',
                        inlineFloatPosition: 'down',
                        width: 240,
                        appId: '8f7a2bbfcb209f52dee6f645ee9c71c4', //appId，在控制台中“应用管理”或“应用配置”模块获取
                        success: function (token) {
                            console.log('token:', token)
                            _this.token = token
                        }
                    })
                }

				console.log('fail5')
//				console.log(this.hlepregister, "hlepregister")
				if(sessionStorage.getItem("step1")) {
					let a = JSON.parse(sessionStorage.getItem("step1"))
					this.form.phone = a.phone
					this.form.code = a.code
					this.form.pass = a.pass
					this.form.checkPass = a.pass
				}else{
					this.form.phone = ''
					this.form.code = ''
					this.form.pass = ''
					this.form.checkPass = ''
					this.form.phone2 = ''
				}
				// this.$route.path === `${basePath}/register/replace` && this.
			})

		},
		methods: {
			loading(type) {
                let _this = this;
				this.exist = false; //清除手机错误信息
				// console.log(this.form.phone)
				if(type == 1) { //查询手机号是否注册过
					this.codeDisabled = true;
					if(this.form.phone.length == 11) {
						const api1 = "/unlogin/employee/checkMobileNewAvailable"
						const body1 = {
							mobile: this.form.phone,
							loginName: this.form.phone,
							appId: 2
						}
						axiosApi(api1, body1, (res) => {
							if(!res.data.data) {
								this.exist = false;
								this.codeDisabled = false;
							} else {
								this.exist = true;
							}
						}, 1, 10)
					}
				}
				if(type == 2) {
                    if (!this.token){
                        this.$message.error('请先通过滑动验证码')
                        return false
                    }
					this.$refs.form.validateField("phone");
					//不用延迟也ok
					if(!this.exist && this.form.phone.length == 11) {
						this.codeDisabled = true;
						if(!this.codeBool) {
							clearTimeout(this.clearTime);
							this.codeBool = true;
							this.time = "59"
						}
						this.timeBool = true;
						this.clearTime = setInterval(function() {
							_this.time--;
							if(_this.time == 0) {
								_this.codeBool = false;
								_this.codeDisabled = false;
							}
						}, 1000)
						const api2 = "/unlogin/sms/sendSingleWithVerification"
						const body2 = {
						    token:this.token,
							mobile: this.form.phone,
							smsTemplate: "1",
							smsType: "sms0001",
							appId: 2,
							smsPurposeType: 1
						}
						axiosApi(api2, body2, () => {}, 1, 10);
					}
				}
			},
			isCaps(e) {
				this.capB1 = passfocus()
				if(this.form.pass.length >= 6) {
					passcheck(this.form.pass) //检测密码强度
				}
				this.capB1 = capsisopen(e, this.form.pass) //检测大小写键是否打开
			},
			isCaps2(e) {
				this.capB2 = passfocus()
				this.capB2 = capsisopen(e, this.form.checkPass) //检测大小写键是否打开
			},
			//下一步动作事件
			goPage(form) {
				// console.log("注册")
				//var a = md5(this.form.pass)
				//console.log(a.length)
				this.$refs[form].validate((valid) => {
					if(valid) {
						if(!this.hlepregister) {
							const api3 = "/unlogin/sms/varify"
							const body3 = {
								mobile: this.form.phone,
								messageCode: this.form.code,
								appId: 2,
								smsType: "sms0001"
							}
							axiosApi(api3, body3, (res) => {
								// console.log(res, "0000")
								if(res.data.data) {
									sessionStorage.stepMobile = this.form.phone;
									sessionStorage.loginPwd = md5(this.form.pass);
									sessionStorage.setItem("step1", JSON.stringify({
										phone: this.form.phone,
										code: this.form.code,
										pass: this.form.pass
									}))
									this.$router.push(basePath + "/register/step2");
								}
							}, 1, 10, (res) => {
								this.isCode = true;
								this.message = res.data.message;
							})
						} else {
							sessionStorage.stepMobile = this.form.phone;
							sessionStorage.loginPwd = md5(this.form.pass);
							this.$router.push(basePath + "/register/replace2/-1");//-1代表代客户注册
						}
					} else {
						// console.log('error submit!!');
						return false;
					}
				});
			},

		}
	}
</script>

<style lang="less">
	.step1 {
		.step{
			padding: 50px 0 50px 200px;
		}
		.el-form-item__label {
			padding-right: 30px;
		}
		.w115 {
			width: 115px;
		}
		.w105 {
			width: 105px;
		}
		.ml-6{
			margin-left: -6px;
		}
		.replace{
			padding: 30px 30px 50px;
		}
	}
</style>