<template>
	<div id="purchase-order-detail" class="bg-f">
		<div class="content-width" v-loading='loading'>
			<div class="mb-50" v-if="orderDetail.orderStatus != 0">
				<div class="order-state" :style="orderState"></div>
				<div class="mt-10 font-16 order-state-info">
					<div>
						<span class="" :class="orderDetail.orderStatus >=1?'color-danger':''">下单提交</span>
						<p class="font-12 color-9 mt-10" v-if="orderDetail.orderStatus >=1">{{orderDetail.gmtCreate | time}}</p>
					</div>
					<div>
						<span class="color-9" :class="orderDetail.orderStatus >=2?'color-danger':''">支付货款</span>
						<p class="font-12 color-9 mt-10" v-if="orderDetail.orderStatus>=2">{{orderDetail.gmtPay | time}}</p>
					</div>
					<div>
						<span class="color-9"  :class="orderDetail.orderStatus >=3?'color-danger':''">卖家发货</span>
						<p class="font-12 color-9 mt-10" v-if="orderDetail.orderStatus>=3">{{orderDetail.gmtShipped | time}}</p>
					</div>
					<div>
						<span class="color-9"  :class="orderDetail.orderStatus >=4?'color-danger':''">确认收货</span>
						<p class="font-12 color-9 mt-10" v-if="orderDetail.orderStatus>=4">{{orderDetail.gmtComplete | time}}</p>
					</div>
				</div>

			</div>
			<!--收货信息-->
			<div class="order-info clearfix mb-20">
				<div class="title">
					<p class="fl">收货信息</p>
				</div>
				<el-form class='clearfix fl' label-width="80px">
					<el-form-item label="收货人:">
						{{orderDetail.addrUsername}}
					</el-form-item>
					<el-form-item label="电话:">
						{{orderDetail.addrMobile}}
					</el-form-item>
					<el-form-item label='地址:'>
						{{orderDetail.addrProvince}}{{orderDetail.addrCity}}{{orderDetail.addrRegion}}{{ orderDetail.addrStreet }}{{orderDetail.addr}}
					</el-form-item>
					<el-form-item label='备注:'>
						<div>
							<p>{{orderDetail.remark?orderDetail.remark:'无'}}</p>
							<div style="display: inline-block;vertical-align:top;margin-top:10px" v-if="orderDetail.remarkPics">
								<img style="width: 80px;height: 80px" :src="item" alt="加载失败" v-for="(item,index) in orderDetail.remarkPics" :key="index" @click="lookImageDetail(index)"/>
							</div>
						</div>
					</el-form-item>
				</el-form>
			</div>

			<!--发票信息-->
			<div v-if="orderDetail.orderInvoicePo!=null">
				<div class="order-info clearfix mb-20"  v-if="orderDetail.orderInvoicePo.type==1">
					<div class="title">
						<p class="fl">发票信息</p>
					</div>
					<el-form class='fl mb-10' label-width="80px">
						<el-form-item label="发票类型:">
							{{orderDetail.orderInvoicePo.type==1?'普通发票':'增值税发票'}}
						</el-form-item>
						<el-form-item label="发票抬头:">
							{{orderDetail.orderInvoicePo.companyHead}}
						</el-form-item>
						<el-form-item label="纳税人识别号:">
							{{orderDetail.orderInvoicePo.taxpayerIdNo}}
						</el-form-item>
						<el-form-item label="收票人:">
							{{orderDetail.orderInvoicePo.linkman}}
						</el-form-item>
						<el-form-item label="手机号:">
							{{orderDetail.orderInvoicePo.tel}}
						</el-form-item>
						<el-form-item label="收票地址:">
							{{orderDetail.orderInvoicePo.provinceName+orderDetail.orderInvoicePo.cityName+orderDetail.orderInvoicePo.regionName+orderDetail.orderInvoicePo.addr}}
						</el-form-item>
					</el-form>
				</div>

				<div class="order-info clearfix mb-20" v-if="orderDetail.orderInvoicePo.type==2">
					<div class="title">
						<p class="fl">发票信息</p>
					</div>
					<el-form class='fl mb-10' label-width="80px">
						<el-form-item label="发票类型:">
							{{orderDetail.orderInvoicePo.type==1?'普通发票':'增值税发票'}}
						</el-form-item>
						<el-form-item label="公司名称:">
							{{orderDetail.orderInvoicePo.companyHead}}
						</el-form-item>
						<el-form-item label="纳税人识别号:">
							{{orderDetail.orderInvoicePo.taxpayerIdNo}}
						</el-form-item>
						<el-form-item label='注册地址:'>
							{{orderDetail.orderInvoicePo.registerAddress}}
						</el-form-item>
						<el-form-item label='注册电话:'>
							{{orderDetail.orderInvoicePo.registerPhone}}
						</el-form-item>
						<el-form-item label='开户银行:'>
							{{orderDetail.orderInvoicePo.bankDeposit}}
						</el-form-item>
						<el-form-item label='银行账号:'>
							{{orderDetail.orderInvoicePo.bankCard}}
						</el-form-item>
						<el-form-item label="收票人:">
							{{orderDetail.orderInvoicePo.linkman}}
						</el-form-item>
						<el-form-item label="手机号:">
							{{orderDetail.orderInvoicePo.tel}}
						</el-form-item>
						<el-form-item label="收票地址:">
							{{orderDetail.orderInvoicePo.provinceName+orderDetail.orderInvoicePo.cityName+orderDetail.orderInvoicePo.regionName+orderDetail.orderInvoicePo.addr}}
						</el-form-item>
					</el-form>
				</div>
			</div>
			<!--赠品信息-->
			<div class="order-info clearfix mb-20" v-if="!!orderDetail.orderGiftContent">
				<div class="title">
					<p class="fl">赠品信息</p>
				</div>
				<el-form class='fl clearfix gift' label-width="80px">
					<el-form-item label="赠品信息:">
						{{orderDetail.orderGiftContent}}
					</el-form-item>
				</el-form>
			</div>

			<div class="table-item mt-20" >
				<div class="clearfix bg-e lh-40 h-40 mb-10" style="border: 1px solid #ECEEF6;display:flex">
					<div class="fl" style="flex:1;text-align:center;padding: 0 10px">区域名称</div>
					<div class="fl" style="flex:2;text-align:center">商品信息</div>
					<div class="fl" style="flex:1;text-align:center;">单价</div>
					<div class="fl" style="flex:1;text-align:center;padding-right: 8px;box-sizing:border-box">数量</div>
					<div class="fl" style="flex:1;text-align:center;padding-right: 30px;box-sizing:border-box">商品积分</div>
					<div class="fl" style="flex:1;text-align:center;padding-right: 30px;box-sizing:border-box">合计</div>
					<div class="fl" style="flex:1;text-align:center">操作</div>
				</div>
				<div class="clearfix table-item-top">
					<span class='fl ml-20 color-6' v-if="orderDetail.orderStatus == 0">取消订单时间： {{orderDetail.gmtCancle | time}}</span>
					<span class='fl ml-20 color-6' v-else>下单时间： {{orderDetail.gmtCreate | time}}</span>
					<span class='fl ml-50 color-6'>订单编号： {{isParentPurchaseOrderType == 2?orderDetail.parentOrderSn:orderDetail.sn}}</span>
				</div>
				<div class="table-item-content bb clearfix" style="display: flex;">
                    <div class="pt-20 region_name" style="flex:1">
                        {{orderDetail.platformName}}
                    </div>
                    <!--商品-->
					<div class="clearfix" style="flex:5">
						<div class="clearfix last-no-border" v-for="(item, index) in orderDetail.orderItemVos" style="display:flex">
							<div class="fl gift" style="flex: 2">
                                <div v-if="item.changePurchaseItem" class="triangle-topleft border-red"></div>
                                <span v-if="item.changePurchaseItem" class="init default">换购</span>
								<img v-if="item.image" :src="item.image" class="fl mr-10" />
								<img v-else src="../../../assets/goods.jpg" class="fl mr-10" />
								<p>{{item.skuName}}</p>
								<p class="color-9 mt-20">规格:1*{{item.specInfoNum}}</p>
							</div>
							<div class="fl pbl-20 tac" style="flex:1">¥{{item.showUnitPrice | fmoney}}/{{item.showUnitName}}</div>
							<div class="fl pbl-20 tac" style="flex:1">{{item.specNum}}{{item.unit}}</div>
							<div class="fl pbl-20 tac" style="flex:1">{{item.goodsIntegration}}</div>
						</div>
						<!--赠品清单-->
						<div class="clearfix last-no-border" v-for="(orderItem, index) in orderDetail.orderGiftResVos" style="display:flex">
							<div class="fl gift" style="flex:2">
                                <div class="triangle-topleft border-red"></div>
                                <span class="init default">赠品</span>
								<img v-if="orderItem.image" :src="orderItem.image" class="fl mr-10" />
								<img v-else src="../../../assets/goods.jpg" class="fl mr-10" />
								<p>{{orderItem.skuName}}</p>
								<p class="color-9 mt-20" v-if="orderItem.specInfoNum">规格:1*{{orderItem.specInfoNum}}</p>
							</div>
							<div class="fl pbl-20 tac giftPriceClass" style="flex:1">¥{{ orderItem.salesPrice }}/{{ orderItem.sellingUnitName }}</div>
							<div class="fl pbl-20 tac " style="flex:1">{{orderItem.giftNum}}{{orderItem.sellingUnitName}}</div>
								<div class="fl pbl-20 tac" style="flex:1">0</div>
						</div>
					</div>
					<div class="bl tac pt-20" style="flex:1">
						<p v-if="orderDetail.editAuditState == 1 && orderDetail.orderStatus == 1" class="color-danger">待审核</p>
						<div v-else>
							<p v-if="orderDetail.remainAutoCancleTime&&(orderDetail.editAuditState == 3||orderDetail.editAuditState == null) && orderDetail.orderStatus == 1 && (orderDetail.lastestRemittanceAuditStatus==2||orderDetail.lastestRemittanceAuditStatus==null)">
								<span class="color-danger">剩余</span> <span class="color-danger" v-text="typeof(orderDetail.remainAutoCancleTime)=='string'?orderDetail.remainAutoCancleTime:null"></span>
							</p>
                            <p v-if=" orderDetail.orderStatus == 1 " class="color-danger">待付款</p>
							￥{{orderDetail.goodsTotalMoney || 0 | fmoney}}
						</div>
						<p v-if=" orderDetail.orderStatus == 0 " class="color-danger">已关闭</p>
						<p v-if=" orderDetail.orderStatus == 2 " class="color-danger">{{ orderDetail.shipStatus === 4 ? '部分发货' : '待发货'}}</p>
						<p v-if=" orderDetail.orderStatus == 3 " class="color-danger">已发货</p>
						<p v-if=" orderDetail.orderStatus == 4 " class="color-danger">已完成</p>
						<p v-if=" orderDetail.orderStatus == 5 " class="color-danger">已退款</p>

                        <!-- <div>
                            <el-button type='text' class="font-12 orderDetail_btn"  >售后记录</el-button>
                        </div> -->
					</div>
					<div class="bl tac pb-10 plr-10 pt-20 br" style="flex: 1;">
						<div class='mt-10' v-if="orderDetail.orderStatus == 3 && orderType">
							<el-button class="font-12" @click="confirmReceipt(orderDetail.sn)">确认收货</el-button>
						</div>
                        <!-- <div >
                            <el-button class="ml-0 mt-10 font-12" type='danger'  v-if="orderDetail && orderDetail.orderItemVos && orderDetail.orderItemVos.length > 0 && (orderDetail.orderStatus == 2 || orderDetail.orderStatus == 3 || orderDetail.orderStatus == 4 || orderDetail.orderStatus == 0)"  @click="tryAgainOrder(orderDetail)">
                                再来一单
                            </el-button>
                        </div> -->
                        <!-- <div >
                            <el-button class="ml-0 mt-10 font-12"   v-if="orderDetail.orderStatus == 2 || orderDetail.orderStatus == 3 || orderDetail.orderStatus == 4"  @click="applyForAfterService(orderDetail)">
                                申请售后
                            </el-button>
                        </div> -->
						<div v-if="orderDetail.editAuditState == 2 && orderDetail.orderStatus == 1" class="color-danger">商家正在审核您的订单</div>
						<div v-else-if="!((orderDetail.editAuditState == 1 || orderDetail.editAuditState == 2) && orderDetail.orderStatus == 1)">
							<div class='mt-10' v-if="orderDetail.payStatus != 3 && (orderDetail.lastestRemittanceAuditStatus == 2 || !orderDetail.lastestRemittanceAuditStatus) && orderDetail.orderStatus != 0 && orderDetail.payType != 6 && orderDetail.payType != 2">
								<el-button class="font-12" type="danger" v-if="showButton('PAY',100040) && orderType && !orderDetail.paymentOnBehalf" @click="orderPay(isParentPurchaseOrderType == 2?orderDetail.parentOrderSn:orderDetail.sn)">去付款</el-button>
							</div>
							<div v-if="orderDetail.orderStatus == 1 && orderDetail.payStatus == 1 && (orderDetail.lastestRemittanceAuditStatus == null || orderDetail.lastestRemittanceAuditStatus == 2 )">
								<el-button class="ml-0 mt-10 font-12" v-if="showButton('CANCEL',100040) && orderType" @click='handleCancelOrder()'>取消订单</el-button>
							</div>
							<div v-else-if="orderDetail.orderStatus == 2 && orderDetail.deliverySeparate && orderType">
								<el-button class="ml-0 mt-10 font-12" @click='separateDialog = true' type="danger">订单提货</el-button>
							</div>
						</div>
						<div class="mt-10 " v-if="orderDetail.orderStatus == 0 && orderDetail.cancleReason">
							<el-button class="font-12" style="width: 110px;" @click="handleCancleReason(orderDetail.cancleReason)">取消订单原因</el-button>
						</div>
						<div v-if="orderDetail.lastestRemittanceAuditStatus == 1 && orderDetail.orderStatus != 0" class="color-danger">
							您上传的支付凭证正在审核中
						</div>
						<div  class="color-danger" v-if="orderDetail.lastestRemittanceAuditStatus == 2 ">
							您上传的支付凭证审核未通过,未通过原因:{{orderDetail.remittanceApplyAuditFailReason}}
						</div>
					</div>
					<!--取消订单弹出框-->
					<el-dialog class='cancelOrderDialog' title='取消订单理由' :visible.sync='cancelOrderVisible'>
						<el-form class="pl-30 pr-30"  :model="form" ref="cancelOrderForm" :rules="rules">
							<el-form-item label="" prop='cancleReason'>
								<el-input type='textarea' placeholder='请输入取消订单的理由...' :autosize="{ minRows: 3 ,maxRows: 10}" v-model.trim='form.cancleReason'></el-input>
							</el-form-item>
						</el-form>
						<div slot='footer' class="dialog-footer">
							<el-button class="font-12" type='danger' @click="cancelOrderCommit">提 交</el-button>
							<el-button class="font-12" @click="cancelOrderVisible = false">取 消</el-button>
						</div>
					</el-dialog>
				</div>
				<div class="total clearfix">
					<ul class="fr">
            <div v-if="!showSalesCommission || (showSalesCommission && orderDetail.isOrderSeller)">
              <!-- isOrderSeller // 门店是卖家true, 子公司是非卖家false。子公司要隐藏，门店要展示这一块 -->
              <li class="clearfix mb-10" >
                <span class="tar w112">
                  <!-- <i class="color-danger tar" style="font-style: normal;" >{{orderDetail.count}}</i> -->
                  <!-- 件 -->
				  			商品总额：
                </span>
                <span class='tar'>￥ {{ orderDetail.goodsTotalMoney ||0 | fmoney}}</span>
              </li>
							<li class="clearfix mb-10">
                <span class="tar w112">预估积分：</span>
                <span class='tar'>{{ orderDetail.predictiveIntegral|| 0 | fmoney }}</span>
              </li>
              <li class="clearfix mb-10">
                <span class="tar w112">满减金额：</span>
                <span class='tar'>- ￥ {{orderDetail.activityCutMoney||0 | fmoney}}</span>
              </li>
              <li class="tar clearfix mb-10">
                <span class="tar w112">折扣返利：</span>
                <span class='tar'>- ￥ {{orderDetail.discountCutMonney||0 | fmoney}}</span>
              </li>
              <li class="tar clearfix mb-10">
                <span class="tar w112">优惠券金额：</span>
                <span class='tar'>- ￥ {{orderDetail.couponCutMoney||0 | fmoney}} </span>
              </li>
              
              <li class="tar clearfix mb-10">
                <span class="tar w112">运费：</span>
                <span class='tar'>+ ￥ {{orderDetail.freightMoney||0 | fmoney}}</span>
              </li>
							<li class="tar clearfix mb-10" v-if='orderDetail.voucherCutMonney != 0'>
                <span class="tar w112">抵货佣金：</span>
                <span class='tar'>- ￥ {{orderDetail.voucherCutMonney||0 | fmoney}} </span>
              </li>
            </div>
            <li v-if="showSalesCommission" class="tar clearfix mb-10">
              <span class="tar w112">采购金额：</span>
							<span class='tar'>￥ {{orderDetail.purchaseAmount||0 | fmoney}}</span>
						</li>
            <li v-if="showSalesCommission" class="tar clearfix mb-10">
              <span class="tar w112">销售员佣金：</span>
							<span class='tar'>￥ {{orderDetail.commission||0 | fmoney}}</span>
						</li>
            <li v-if="showSalesCommission" class="tar clearfix mb-10">
              <span class="tar w112">推广员佣金：</span>
							<span class='tar'> ￥ {{orderDetail.salesCommission||0 | fmoney}}</span>
						</li>
						<li v-if="orderDetail.userCoinAmount" class="tar clearfix mb-10">
              <span class="tar w112">使用久币：</span>
							<span class='tar'> {{orderDetail.userCoinAmount}}</span>
						</li>
						<li class="font-14 tar clearfix">
							<span class="tar w112">订单总额：</span>
							<span class='tar payment'>￥ {{orderDetail.totalMoney  | fmoney}}</span>
						</li>
						
						<li>
							<div class="font-12" style="color: red;text-align:right">（赠品不参与计算）</div>	
						</li>
					</ul>
				</div>
			</div>
			

			<!--付款信息-->
			<div class="clearfix" v-if="orderDetail.payStatus >= 2">
				 <el-tabs class='tabs' v-model="active">
						<el-tab-pane label="付款信息" name="1"></el-tab-pane>
						<el-tab-pane label="物流信息" name="2"></el-tab-pane>
						<el-tab-pane label="日志信息" name="3"></el-tab-pane>
						<el-tab-pane label="分批发货信息" name="4" v-if="orderDetail.deliverySeparate"></el-tab-pane>
				</el-tabs>
				<div v-if="active==1" class="order-info order-info-dispatch clearfix mb-20 mr-0 fl"
					 v-for="(item, index) in orderDetail.paymentLogsVOs" :key="index">
					<el-form class='fl mb-10' label-width="110px">
						<el-form-item label="付款时间:">
							{{item.payTime | time}}
						</el-form-item>
						<el-form-item label="付款方式:">
							<span>{{ item.payMethodName }}</span>
						</el-form-item>
						<!-- <el-form-item label='支付流水号:' v-if="!ishotel || !isDealerUser">
							{{item.paySn}}
						</el-form-item> -->
						<el-form-item label='支付金额:' v-if="!ishotel || !isDealerUser">
							￥{{item.payMoney | fmoney}}
						</el-form-item>
						<!--支付方式 == 6 为账期支付 显示应付金额-->
						<!-- <el-form-item label='应付金额:' v-if="item.payMethod==6">
							￥{{orderDetail.needPayMoney | fmoney}}
						</el-form-item> -->
						<el-form-item label='付款凭证:' v-if="item.isHasVoucher">
							<el-button type='text' style="width:auto;height: auto;" v-if="showButton('VOUCHER',100040)" @click="handleLookremittance">查看</el-button>
						</el-form-item>
					</el-form>

				</div>
				<logistics v-if="active==2" :info="orderDetail.logisticsVos"/>
				<logsList v-if="active==3" :info="orderDetail.orderLogVos"/>
				<!-- 发货信息 -->
				<outrecord v-if="active==4" :orderSn="orderDetail.sn" style="margin:20px 0 55px"></outrecord>
			</div>
			<el-dialog title="付款凭证信息" class="lookpaymentDialog" :visible.sync='lookpaymentVisible'>
				<el-form :model="orderRemittanceApplyVo" class='mb-50'>
					<div>
						<el-form-item label='持卡人姓名:'>
							<el-input placeholder='持卡人姓名' class="fl" readonly v-model='orderRemittanceApplyVo.bankUserName'></el-input>
						</el-form-item>
						<el-form-item label='收款银行名称:'>
							<el-input class="fl" readonly v-model="orderRemittanceApplyVo.incomeAccountName"></el-input>
						</el-form-item>
						<el-form-item label='收款银行账号:'>
							<el-input class="fl" readonly v-model="orderRemittanceApplyVo.incomeAccountNum"></el-input>
						</el-form-item>
					</div>
					<el-form-item label='付款金额:'>
						<el-input placeholder='请输入付款金额' class="fl" readonly v-model="orderRemittanceApplyVo.amount"></el-input>
					</el-form-item>
					<el-form-item label='汇款凭证:'>
						<el-upload :disabled="true" class="avatar-uploader fl" action="/sunrise-gateway/oss/ossUpload" list-type="picture-card" :on-preview="handlePictureCardPreview" :file-list="orderRemittanceApplyVo.fileList"></el-upload>
					</el-form-item>
				</el-form>
				<div slot="footer" class="dialog-footer">
					<el-button type="danger" @click="lookpaymentVisible=false">确 定</el-button>
				</div>
			</el-dialog>
			<el-dialog :visible.sync="dialogVisible" size="tiny" class="showImgDialog">
				<img width="100%" :src="dialogImageUrl" alt="">
			</el-dialog>
		</div>
		<!-- 通知发货弹窗 -->
		<separateOrderDialog
		 :dialogVisible.sync="separateDialog"
		 :orderSn="isParentPurchaseOrderType == 2?orderDetail.parentOrderSn:orderDetail.sn"
		 :sellerName="orderDetail.platformName"
		 @deliverySuccessCallback="orderDetailInfo({ orderSn: sessionStorage.orderSn })" />
		 <!-- 图片预览弹窗 -->
		 <imgview :visible="imgsvisible" :imgs="showimgs" :startindex="startindex" @close="imgsvisible=false"></imgview>

	</div>
</template>

<script>
    import {mapMutations, mapActions,mapState} from 'vuex';
	import { axiosApi, basePath } from "../../../api/api"
	import separateOrderDialog from './separateOrderDialog.vue'
	import outrecord from '~/components/order/outrecord.vue';
	import logistics from '~/components/logistics/logistics.vue'
	import logsList from '~/components/logistics/logsList.vue'
	export default {
		components: { separateOrderDialog,logistics, logsList, outrecord },
		data() {
			return {
				active: '1',
				orderState: {
					backgroundPosition: '0 0'
				},
				dialogVisible:false,
				dialogImageUrl:'',
				changePriceVisible: false,
				cancelOrderVisible: false,
				reason: null,
				orderDetail: {}, //订单详情信息
				loading: false,
				form:{
					cancleReason:null
				},
				rules:{
					cancleReason:[{max:30, message:'不能超过30个字！'}]
				},
				lookpaymentVisible:false,		//查看付款凭证dialog
				orderRemittanceApplyVo:{},		//付款凭证详情
				ishotel:sessionStorage.getItem("isHotel") === '1', //酒店用户
				detailList: [], // 发货信息
				separateOrder: [], // 剩余发货商品信息
				separateDialog: false,
        orderType:true, //判断是否为酒店久集订单,false为酒店久集订单
				showSalesCommission: 0,
		 		startindex:0,
				imgsvisible:false,
				showimgs:[],
			}
		},
		mounted() {
            let data = {
                url:  this.$route.fullPath,
                text: '采购单详情'
            }
            let arr = this.$deepClone(this.tagNav)
						
            arr.push(data)
						
            this.changeTagNav(arr)
						
            this.showSalesCommission = this.$route.query.showSalesCommission;
						this.$nextTick(() => {
							
								let afterUrl =  window.location.search.substring(1);
								if(afterUrl){
										this.orderType = false
								}else{
										this.orderType = true
								}
							
								this.orderDetailInfo({ orderSn: sessionStorage.orderSn })
						})
        },
        computed:{
            ...mapState('nav',['tagNav']),
          isParentPurchaseOrderType(){
            return sessionStorage.isParentPurchaseOrderType || ''
          },
            isDealerUser(){
                return this.$store.state.clientType.isDealerUser;
            }
        },
		methods: {
      ...mapMutations('nav',['changeTagNav']),
			lookImageDetail(index){
				this.startindex = index
				this.imgsvisible = true;
				this.showimgs = this.orderDetail.remarkPics;
			},
            //申请售后
            applyForAfterService(order){
                let orderSn = order.parentOrSon == 3 ? order.parentOrderSn : order.sn;
                let isSeller = false;
                let serviceSn = order.serviceSn;
                this.$router.push(basePath + '/addService',{
                    orderSn,
                    isSeller,
                    serviceSn
                })
                // this.orderSn = this.$route.query.orderSn
                // this.serviceSn = this.$route.query.serviceSn
                // this.isSeller = this.$route.query.isSeller

            },
            //再来一单
            tryAgainOrder(order){
                let body = {
                    buyerId:order.buyerId,
                    sellerId:order.sellerId,
                    skuList:order.orderItemVos.filter(v => {
                        return !v.activityId
                    }).map(v=>{
                        return {
                            skuNo:v.skuNo,
                            num:v.specNum,
                            operatorType:1,
                            selectedSalesUnitType:v.isIntegerMultiple ? 2 : 3
                        }
                    })
                }
                axiosApi('/order/cart/batchInsert',body,res => {
                    this.$router.push(basePath + '/shop/shopingCar')
                },1,1,err => {
                    this.$message({
						message: err.data.message,
						type: 'warning'
					});
                })
            },
			filterSeparateOrderInfo(data) {
				const orderItems = data.orderItemVos.map(n => {
					if (n.remainingSpecNum > 0) {
						return n
					}
				})
				const gitfItems = data.orderGiftResVos ? data.orderGiftResVos.map(n => {
					if (n.remainingNum > 0) {
						return {
							skuNo: n.skuNo,
							skuName: n.skuName,
							skuImage: n.skuImage,
							specNum: n.giftNum,
							remainingSpecNum: n.remainingNum,
							num: 0
						}
					}
				}) : []
				this.separateOrder = orderItems.concat(gitfItems)
			},
			handlePictureCardPreview(file) { //图片放大
				this.dialogImageUrl = file.url;
				this.dialogVisible = true
			},
			handleLookremittance() { //查看付款凭证
				this.remittanceApply({
					orderSn: sessionStorage.orderSn
				})
				this.lookpaymentVisible = true
			},
			//根据付款记录id获取汇款凭证
			remittanceApply(body) {
				axiosApi('/anon/order/remittanceApply/get', body, (res) => {
					this.orderRemittanceApplyVo = res.data.data;
					this.orderRemittanceApplyVo.fileList = JSON.parse(this.orderRemittanceApplyVo.urls).map(v => {
						return {url:v}
					})
				}, 1, 10)
			},
			orderPay(orderSn) { //去付款
				sessionStorage.setItem('orderSn', orderSn)
        let a = sessionStorage.isParentPurchaseOrderType || '',
            data = {}
        if(a==2){
          data.parentOrderSn = orderSn
        }else{
          data.orderSn = orderSn
        }
				axiosApi('/anon/parentOrder/detail/info',data,(res)=>{
					if (res.data.data.payStatus != 3) {
						this.$router.push(basePath + '/payOrder?topay=1')
					}else{
						this.orderDetailInfo({orderSn: sessionStorage.orderSn})
						this.$message({type:'info',message:'您已付款成功',duration:1000})
					}
				},null,null,(res)=>{

				})

			},
			handleCancelOrder() { //取消订单按钮
			// isParentPurchaseOrderType == 2?orderDetail.parentOrderSn:orderDetail.sn
				this.cancelOrderVisible = true
			},
			/*订单详情*/
			orderDetailInfo(body) {
				console.log('xxx执行')
        let a = sessionStorage.isParentPurchaseOrderType || '',
            data = {}
        if (a==2){
          data.parentOrderSn = body.orderSn
        } else {
          data.orderSn = body.orderSn
        }
        this.loading = true
        let api = this.showSalesCommission ? '/superc/order/Detail/info' : "/anon/parentOrder/detail/info"
				axiosApi(api, data, (res) => {
					this.loading = false
					this.orderDetail = res.data.data;
					this.orderDetail.remarkPics = JSON.parse(this.orderDetail.remarkPics)
					this.orderState.backgroundPosition = '0 '+ (this.orderDetail.orderStatus - 1) * -40 + 'px';
					if(this.orderDetail.remainAutoCancleTime && this.orderDetail.remainAutoCancleTime>0){
						let time = parseInt(this.orderDetail.remainAutoCancleTime / 1000)
						this.orderDetail.remainAutoCancleTime = this.timeString(time)
						this.orderDetail.clearTime = null
						this.orderDetail.clearTime = setInterval(() => {
							time--
							this.orderDetail.remainAutoCancleTime = this.timeString(time)
							if(time <= 0) {
							 	clearTimeout(this.orderDetail.clearTime)
							 	// if(this.orderDetail.orderStatus == 1&&this.orderDetail.payStatus==1&&(!this.orderDetail.editAuditState ||this.orderDetail.editAuditState==3)&&(!this.orderDetail.lastestRemittanceAuditStatus|| this.orderDetail.lastestRemittanceAuditStatus!=1)) {
								// 	this.orderDetail.orderStatus = 0
								// 	this.$set(this.orderDetail,'orderStatus', 0)
								// }
								this.$set(this.orderDetail,'remainAutoCancleTime', null)
							 }
						}, 1000)
					}else {
							this.$set(this.orderDetail,'remainAutoCancleTime', null)
					}
					// else if(this.orderDetail.orderStatus == 1 && this.orderDetail.payStatus==1 && (!this.orderDetail.editAuditState || this.orderDetail.editAuditState == 3) && (!this.orderDetail.lastestRemittanceAuditStatus || this.orderDetail.lastestRemittanceAuditStatus !=1)){
					// 	this.orderDetail.orderStatus = 0
					// 	this.$set(this.orderDetail,'orderStatus', 0)
					// }
					if (this.orderDetail.orderStatus === 2 && this.orderDetail.deliverySeparate) {
						this.filterSeparateOrderInfo(res.data.data)
					}
				}, 1, 10, (res) => {
					this.loading = false
					this.$message({
						type: 'error',
						message: res.data.message,
						duration: 1000
					})
				})
			},
			//订单取消的原因
			handleCancleReason(cancleReason) {
				const h = this.$createElement;
				this.$msgbox({
					title: '订单取消原因',
					message: h('p', null, [
						h('p', {
							style: 'text-align:center'
						}, cancleReason),
					]),
					showCancelButton: false,
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					beforeClose: (action, instance, done) => {
						if(action === 'confirm') {
							done()
						} else {
							done()
						}
						delete instance.$slots.default;
					}
				})
			},
			//弹窗 取消订单
			cancelOrderCommit(){
				this.$refs.cancelOrderForm.validate((valid)=>{
					if(valid){
						this.cancelOrderVisible = false
						this.cancelOrder({orderSn:this.isParentPurchaseOrderType == 2?this.orderDetail.parentOrderSn:this.orderDetail.sn,cancleReason:this.form.cancleReason,cancleType:1,buyerId:sessionStorage.traderId})

					}else{
						return false
					}
				})
			},
			//取消订单接口
			cancelOrder(body){
				axiosApi('/anon/order/cancel', body, (res)=>{
					this.$message({type:'success',message:'操作成功！',duration:1000})
					this.orderDetailInfo({
						orderSn: sessionStorage.orderSn
					})
				},1,10,(res)=>{
					this.$message({type:'error',message:res.data.message,duration:1000})
				})
			},
			confirmReceipt(orderSn){		//确认收货
				const h = this.$createElement
				this.$msgbox({
					title: '提示信息',
					message: h('p', {
						style: 'text-align:center'
					}, '是否确认收货？'),
					showCancelButton: true,
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					beforeClose: (action, instance, done) => {
						if(action === 'confirm') {
							this.confirmShipOrder({orderSn:orderSn})
							done()
						} else {
							done()
						}
						delete instance.$slots.default;
					}
				})
			},
			confirmShipOrder(body){
				axiosApi('/anon/order/ship/confirm',body,(res)=>{
					const h = this.$createElement
					this.$msgbox({
						message: h('p', {
						style: 'text-align:center'
						}, '确认收货成功'),
						showCancelButton: false,
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						beforeClose: (action, instance, done) => {
							if(action === 'confirm') {
								this.orderDetail.orderStatus = 4
								this.orderState.backgroundPosition = '0 '+ (this.orderDetail.orderStatus - 1) * -40 + 'px';
								done()
							} else {
								done()
							}
							delete instance.$slots.default;
						}
					})

				},1,10,(res)=>{
					this.$message({type:'error',message:res.data.message,duration:1000})
				})
			}
		}
	}
</script>

<style lang="less">
	#purchase-order-detail {
		.title {
			border-bottom:1px solid #ECEEF6;
			height: 40px;
			line-height: 40px;
			font-size: 16px;
			font-weight: 500;
		}
		width: 100%;
		padding-top: 40px;
		.el-upload-list--picture-card .el-upload-list__item{
			width: 70px;
			height: 80px;
		}
    .lh-40{
      line-height: 40px;
    }
    .h-40{
      height: 40px;
    }
		.detailList{
			.gift{
          position:relative;
      }
      .triangle-topleft{
	        border-top:40px solid #ff4201;
	        width: 0;
	        height: 0;
	        border-right: 40px solid transparent;
	        position: absolute;
	        left: 0;
	        top: 0;
	    }
	    .init{
	    		font-size: 12px;
	        transform: rotate(-45deg) translate(-4px, 5px);
	        position: absolute;
	        left: 0;
	        top: 0;
	        color: #fff;
	        letter-spacing: -0.34px;
	    }
	    .text_btn{
	    	width: auto;font-size: 12px;
	    }
		}
		.last-no-border{
			padding:20px;
			border-bottom: 1px solid #ddd;
		}
		.last-no-border:last-child{
			border-bottom: 0;
		}
		.bb{border-bottom: 1px solid #ECEEF6;}
		.bl{border-left: 1px solid #ECEEF6;}
		.br{border-right: 1px solid #ECEEF6;}
		.content-width {
			width: calc(100%-60px);
			margin: 0 30px;
			box-sizing: border-box;
			.order-state {
				width: 540px;
				height: 30px;
				background-image: url(../../../assets/order-state.png);
				margin-left: 60px;
			}
			.order-state2 {
				width: 540px;
				height: 30px;
				background: url(../../../assets/order-state.png) 0 -40px;
				// margin: 0 auto;
			}
			.order-state3 {
				width: 540px;
				height: 30px;
				background: url(../../../assets/order-state.png) 0 -80px;
				margin: 0 auto;
			}
			.order-state4 {
				width: 540px;
				height: 30px;
				background: url(../../../assets/order-state.png) 0 -120px;
				margin: 0 auto;
			}
			.order-state-info {
				line-height: 16px;
				width: 664px;
				// margin: 0 auto;
				display: flex;
				justify-content: space-between;
				div {
					width: 150px;
					text-align: center;
				}
			}
			.order-state-time {
				padding-left: 160px;
				.second {
					padding-left: 70px;
				}
				.third {
					padding-left: 65px;
				}
				.fourth {
					padding-left: 64px;
				}
			}
			.order-info {
				margin: 0 auto;
				margin-top: 30px;
				width: 100%;
				// border: 1px solid #DDDDDD;
				box-sizing: border-box;
				
				.el-form-item__label {
					padding: 0;
					line-height: 14px;
					height: 14px;
					padding-right: 20px;
					width: 145px;
				}
				.el-form-item__content {
					line-height: 14px;
				}
				.el-form {
					min-width: 600px;
					margin-top: 30px;
				}
				.gift{
					.el-form-item{
						.el-form-item__label{
							float: left;
							line-height: 20px;
						}
						.el-form-item__content{
							float: left;
							width: 700px;
							line-height: 20px;
						}
					}
				}
			}
			.lookpaymentDialog {
				.el-form-item__label {
					line-height: 32px;
					height: 32px;
				}
				.el-dialog {
					.el-dialog__body{
						padding-top: 20px;
						padding-left: 10px;
					}
					.avatar-uploader {
						.el-upload--picture-card {
							display: none;
						}
					}
					.el-icon-view {
						margin-left: 20px;
					}
					.el-icon-delete2 {
						display: none;
					}
					.el-upload-list--picture-card .el-upload-list__item-status-label {
						display: none;
					}
				}
				.showImgDialog {
					.el-dialog {
						z-index: 10000;
					}
					.el-dialog__body {
						width: auto;
						height: auto;
						img {
							width: auto;
							height: auto;
						}
					}
				}
			}
			.table-item {
				.bl {
					border-left: 1px solid #ECEEF6;
				}
				font-size: 14px;
				border-bottom: none;
				.table-item-content .pbl-20 {
					padding: 0px 0px 20px 20px;
                }
                .table-item-content{
                  .region_name{
					border-right: 1px solid #ECEEF6;
					border-left: 1px solid #ECEEF6;
                    padding: 20px 10px 0 10px;
                    width: 110px;
                    text-align: center;
                  }
                    .gift{
                        position:relative;
                    }
                    .triangle-topleft{
                        border-top:60px solid #ff4201;
                        width: 0;
                        height: 0;
                        border-right: 60px solid transparent;
                        position: absolute;
                        top: 0;
                        left: 0;
                    }
                    .init{
                        transform: rotate(-45deg) translate(-4px, 10px);
                        position: absolute;
                        left: 0;
                        top: 0;
                        color: #fff;
                        letter-spacing: -0.34px;
                    }
                }
				.table-item-top {
					border: 1px solid #ECEEF6;
					background: #F5F7FA;
					padding: 10px 0;
					.table-item-top-checkbox {
						margin-left: 14px
					}
				}
				img {
					width: 80px;
					height: 80px;
				}
				.total {
					width: 100%;
					padding-top: 30px;
					border-top: none;
					box-sizing: border-box;
					.payment {
						color: #FF5100;
					}
					span {
						display: inline-block;
						width: 90px;
						text-align: left;
					}
					.w112{
						width: 112px;
					}
					ul li {
						margin-bottom: 5px;
					}
				}
			}
			.table-item-default {
				height: 530px;
				padding-top: 120px;
			}
		}
		.giftPriceClass{
				text-decoration:line-through;
		}
	}
</style>
