<template>
	<!--协议客户-->
	<div id="contractCustomer">
		<div class="title-top bg-f clearfix">
			协议客户
			<el-button class="fr mt-14 mr-30" @click="openCustomer('form')">新增协议客户</el-button>
		</div>
		<div class="content bg-f pr-30 pl-30 mt-5">
			<div class="clearfix search">
				<el-form :inline='true' class="pt-30 fl" :model="Form" ref="searchForm">
					<el-form-item label='客户名称'>
						<el-input v-model.trim="Form.companyName" placeholder="请输入客户名称" :maxlength="40"></el-input>
					</el-form-item>
				</el-form>
				<el-button class="fl ml-30 mt-30" type='info' @click="achieve(true)">搜索</el-button>
			</div>
			<div class="pb-30 fristTable">
				<el-table :data="tableData">
					<el-table-column label='客户名称' width="100">
						<template slot-scope="scope">
							<span>{{scope.row.companyName}}</span>
						</template>
					</el-table-column>
					<el-table-column label='人数（人）' width="100">
						<template slot-scope="scope">
							<span v-if="scope.row.count">{{scope.row.count}}</span>
							<span v-else>0</span>
						</template>
					</el-table-column>
					<el-table-column label='结算时间（日）' width="120">
						<template slot-scope="scope">
							<span v-if="scope.row.settlementDay">{{scope.row.settlementDay}}</span>
							<span v-else>-</span>
						</template>
					</el-table-column>
					<el-table-column label='结算账期（月）' width="120">
						<template slot-scope="scope">
							<span v-if="scope.row.settlementPeriod">{{scope.row.settlementPeriod}}</span>
							<span v-else>-</span>
						</template>
					</el-table-column>
					<el-table-column label='状态' width="100">
						<template slot-scope="scope">
							<span>{{!scope.row.isDisable ? "启用" : "禁用"}}</span>
						</template>
					</el-table-column>
					<el-table-column label='已绑定销售员' width="100">
						<template slot-scope="scope">
							<span>{{scope.row.storeName}}</span>
						</template>
					</el-table-column>
					<el-table-column label='操作' align="center">
						<template slot-scope="scope">
							<router-link :to="`${basePath}/customerDetail/${scope.row.id}/${scope.row.companyName}`">
								<el-button type="text" class="w-64">查看详情</el-button>
							</router-link>
							<el-button type="text" class="w-34" @click='isForbbident(scope)'>{{scope.row.isDisable ? "启用" : "禁用"}}</el-button>
							<el-button type="text" class="w-34" @click='isDelete(scope)'>删除</el-button>
							<el-button type="text" @click='assignSale(scope)'>分配销售员</el-button>
							<el-button type="text" class="w-64" @click='setPeriod(scope)'>配置账期</el-button>
						</template>
					</el-table-column>
				</el-table>
				<el-pagination class="tar" v-if="tableData.length>0" :current-page="currentPage" :page-size='10' :total='total' layout="prev , pager, next, jumper" @current-change="handleCurrentChange">
					<!-- 分页组件 -->
				</el-pagination>
			</div>
			<!--新增协议客户-->
			<el-dialog class="minMop" title='新增协议客户' :visible.sync='customer'>
				<el-form class="pr-10" :rules="rules" ref="form" :model="form">
					<el-form-item label="客户名称:" class="ml-20" prop='companyName'>
						<el-input class="ml-10 mr-10" v-model="form.companyName" placeholder="客户名称" :maxlength="40"></el-input>
					</el-form-item>
				</el-form>
				<div slot='footer' class="dialog-footer">
					<el-button type='danger' @click="addCustomer('form')">确定</el-button>
					<el-button class="ml-20" @click="customer = false">取消</el-button>
				</div>
			</el-dialog>
			<!--配置账期-->
			<el-dialog class="minMop" title='配置账期' :visible.sync='period'>
				<el-form class="pr-10" :rules="rules" ref="form" :model="formPeriod">
					<el-form-item label="结算时间:" class="ml-20">
						<!-- <el-input class="ml-10 mr-10" v-model="formPeriod.value" placeholder="请输入账期起始时间"></el-input>号 -->
						<el-select class="ml-10 mr-10" v-model="formPeriod.settlementDay" placeholder="请输入账期起始时间" clearable>
							<el-option v-for="(item,index) in datas" :label="item" :value="item" :key="index"></el-option>
						</el-select>号
					</el-form-item>
					<el-form-item label="结算周期:" class="ml-20">
						<!-- <el-input class="ml-10 mr-10" v-model="formPeriod.value" placeholder="请输入账期周期"></el-input>天 -->
						<el-select class="ml-10 mr-10" v-model="formPeriod.settlementPeriod" placeholder="请输入账期周期" clearable>
							<el-option v-for="(item,index) in months" :label="item" :value="item" :key="index"></el-option>
						</el-select>月
					</el-form-item>
				</el-form>
				<div slot='footer' class="dialog-footer">
					<el-button type='danger' @click="savePeriod()">提 交</el-button>
				</div>
			</el-dialog>
		</div>
					<!--分配销售员-->
			<el-dialog class="bindSales" title='变更销售员' :visible.sync='setSales' width="700px">
				<div>
					<div class="tc mb-20">已关联销售员</div>
					<el-table :data="bindData">
						<el-table-column label='销售员编码' min-width="100" align="center" >
							<template slot-scope="scope">
								<span>{{scope.row.supercStoreId}}</span>
							</template>
						</el-table-column>
						<el-table-column label='销售员名称' min-width="100" align="center" >
							<template slot-scope="scope">
								<span>{{scope.row.storeName}}</span>
							</template>
						</el-table-column>
						<el-table-column label='操作' align="center">
							<template slot-scope="scope">
								<el-button type="text" @click='untie(scope)'>解绑</el-button>
							</template>
						</el-table-column>
					</el-table>
				</div>
				<div class="mt-20">
					<div class="tc">未关联销售员</div>
					<div class="clearfix search">
						<el-form :inline='true' class="pt-20 fl" :model="searchForm">
							<el-form-item label='销售员名称'>
								<el-input v-model.trim="searchForm.companyName" placeholder="请输入销售员名称" :maxlength="40"></el-input>
							</el-form-item>
						</el-form>
						<el-button class="fl mt-20" type='info' @click="getSalesList()">搜索</el-button>
					</div>
					<el-table :data="unBindData">
						<el-table-column label='销售员编码' min-width="100" align="center">
							<template slot-scope="scope">
								<span>{{scope.row.id}}</span>
							</template>
						</el-table-column>
						<el-table-column label='销售员名称' min-width="100" align="center">
							<template slot-scope="scope">
								<span>{{scope.row.storeName}}</span >
							</template>
						</el-table-column>
						<el-table-column label='操作' align="center">
							<template slot-scope="scope">
								<el-button type="text" @click='bind(scope)'>绑定</el-button>
							</template>
						</el-table-column>
					</el-table>
					<el-pagination class="tar"
					 v-if="unBindData.length>0"
					   :current-page="Page"
					   :page-size='5'
					   :total='totalCount' 
					layout="prev , pager, next, jumper" 
					@current-change="handleChange">
						<!-- 分页组件 -->
					</el-pagination>
				</div>
			</el-dialog>
	</div>
</template>

<script>
	import { axiosApi, basePath } from "../../../api/api";
	import { MessageBox } from 'element-ui'
	export default {
		data() {

			return {
				customer: false, //新增协议客户弹框
				Form: {
					companyName: ''
				},
				form: {
					companyName: ''
				},
				searchForm:{
					companyName: ''
				},
				tableData: [], //客户数据
				currentPage: 1,
				total: 0, //页面分页总数
				Page: 1,
				totalCount: 0, //弹窗分页总数
				rules: {
					companyName: [{
							required: true,
							message: '客户名称为必填项'
						},
						{
							pattern: /^[\u4e00-\u9fa5_a-zA-Z]{3,40}$/,
							message: '仅支持3-40位的中文或英文',
							trigger: 'blur'
						}
					]
				},
				period: false,//账期配置弹窗
				setSales: false,//变更销售员弹窗
				datas: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28],
				months:[1,2,3],
				formPeriod:{
					settlementDay: '',//结算日期
					settlementPeriod: ''//周期
				},
				hotelId: '',//酒店ID
				cusId: '',//协议公司主键id
				bindData:[],//已绑定数据
				unBindData:[],//未绑定数据
			}
		},
		watch: {

		},
		mounted() {
			this.$nextTick(() => {
				this.achieve()
			})
		},
		computed: {

		},
		methods: {
			achieve(val) { //分页查询酒店协议公司
				if(val&&this.currentPage != 1) {
					this.currentPage = 1;
					return
				}
				axiosApi('/superc/cooperativeCompany/pageList', {
//					hotelId: '2556',
					hotelId: sessionStorage.traderId,
					companyName: this.Form.companyName
				}, (res) => {
					this.tableData = res.data.data? res.data.data.elements : []
					this.total = res.data.data.totalCount
				}, this.currentPage, 10, (res) => {

				})
			},
			addCustomer(form) { //增加酒店协议公司
				this.$refs[form].validate((valid) => {
					if(valid) {
						axiosApi('/superc/cooperativeCompany/add', {
//							hotelId: '2556',
							hotelId: sessionStorage.traderId,
							companyName: this.form.companyName
						}, (res) => {
							this.achieve()
							this.$message({
								type: 'success',
								message: '新增成功',
								duration: 3000
							});
							this.customer = false
						}, 1, 10, (res) => {
							this.customer = false
							this.$message({
								type: 'info',
								message: res.data.message,
								duration: 3000
							});
						})
					} else {

					}
				});
			},
			openCustomer(form) {
				this.customer = true
				if(this.$refs[form] !== undefined) {
					this.$refs[form].resetFields();
				}
			},
			//禁用，启用
			isForbbident(val) {
				let title = val.row.isDisable ? '启用' : '禁用'
				let that = this;
				this.$confirm('您是否要' + title + '该客户？', '提示信息', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					const api = "/superc/cooperativeCompany/update";
					axiosApi(api, {
						id: val.row.id,
						isDisable: val.row.isDisable ? false : true
					}, (res) => {
						this.$message({
							type: 'success',
							message: '操作成功!',
							duration: 1000
						});
						that.achieve();
					}, this.currentPage, 10, (res) => {
						this.$message({
							type: 'info',
							message: res.data.message,
							duration: 3000
						});
					});
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消操作'
					});
				})
			},
			//删除
			isDelete(val) {
				let that = this;
				this.$confirm('您是否要删除该客户？', '提示信息', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					const api = "/superc/cooperativeCompany/delete";
					axiosApi(api, {
						id: val.row.id
					}, (res) => {
						this.$message({
							type: 'success',
							message: '操作成功!',
							duration: 1000
						});
						that.achieve();
					}, this.currentPage, 10, (res) => {
						this.$message({
							type: 'info',
							message: res.data.message,
							duration: 3000
						});
					});
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消操作'
					});
				})
			},
			handleCurrentChange(val) { //分页查询
				this.currentPage = val;
				this.achieve()
			},
			handleChange(val){//弹窗分页查询
				this.Page = val;
				this.getSalesList()
			},
			setPeriod(val){
				this.period = true
				this.cusId = val.row.id
				this.formPeriod.settlementDay = val.row.settlementDay
				this.formPeriod.settlementPeriod = val.row.settlementPeriod
			},
			savePeriod(){
				const body = {
					id: this.cusId,
					settlementDay: this.formPeriod.settlementDay,
					settlementPeriod: this.formPeriod.settlementPeriod	
				}
				if(!this.formPeriod.settlementDay){
					this.$message({
						type: 'info',
						message: '请先配置账期!',
						duration: 1000
					});
					return false
				}else if(!this.formPeriod.settlementPeriod){
					this.$message({
						type: 'info',
						message: '请先配置账期!',
						duration: 1000
					});
					return false
				}else{
					this.period = false
					axiosApi("/superc/cooperativeCompany/configureAccountPeriod", body, (res) => {
						this.achieve();
						this.$message({
							type: 'success',
							message: '操作成功!',
							duration: 1000
						});
					}, this.currentPage, 10, (res) => {
						this.$message({
							type: 'info',
							message: res.data.message,
							duration: 3000
						});
					});
				}
			},
			getBindSales(){//获取已绑定的销售员
				this.bindData = []
				axiosApi("/superc/cooperativeCompany/selectOne", {id: sessionStorage.cusId}, (res) => {
					if(res.data.data.supercStoreId){
						const A = {
							supercStoreId: res.data.data.supercStoreId,
							storeName:  res.data.data.storeName
						}
						this.bindData.push(A)
					}
				}, this.Page, 10, (res) => {
					this.$message({
						type: 'info',
						message: res.data.message,
						duration: 3000
					});
				});
			},
			getSalesList(){//获取未绑定的销售员
				axiosApi("/superc/cooperativeCompany/queryUnbondedSalesman", {id:sessionStorage.cusId,hotelId:sessionStorage.hotelId,storeName:this.searchForm.companyName}, (res) => {
					this.unBindData = res.data.data.elements
					this.totalCount = res.data.data.totalCount
				}, this.Page, 5, (res) => {
					this.$message({
						type: 'info',
						message: res.data.message,
						duration: 3000
					});
				});

			},
			assignSale(val){
				this.setSales =true
				sessionStorage.cusId = val.row.id
				sessionStorage.hotelId = val.row.hotelId
				this.getBindSales()
				this.getSalesList()
			},
			untie(val){//解绑销售员
				const body = {
					id: sessionStorage.cusId,
				}
				axiosApi("/superc/cooperativeCompany/unbindSalesman",body, (res) => {
					this.getBindSales()
					this.getSalesList()
					this.achieve()
				}, this.Page, 10, (res) => {
					this.$message({
						type: 'info',
						message: res.data.message,
						duration: 3000
					});
				});
			},
			bind(val){//绑定销售员
				const body = {
					id: sessionStorage.cusId,
					supercStoreId: val.row.id,
					storeName: val.row.storeName
				}
				axiosApi("/superc/cooperativeCompany/bindSalesman",body, (res) => {
					this.getBindSales()
					this.getSalesList()
					this.achieve()
				}, this.Page, 10, (res) => {
					this.$message({
						type: 'info',
						message: res.data.message,
						duration: 3000
					});
				});
			}
		}
	}
</script>

<style lang="less">
	#contractCustomer {
		.content {
			.el-form--inline {
				// width: 650px;
				.el-form-item__label {
					font-size: 12px;
					width: 60px;
				}
				.order-datatimerange {
					width: 240px;
				}
			}
			.w-64 {
				width: 64px!important;
			}
			.w-34 {
				width: 34px!important;
			}

			.el-button+.el-button {
				margin-left: 0;
			}
			.el-table .cell {
				text-align: center;
				padding: 10px 0;
				img {
					width: 60px;
					height: 60px;
					margin-left: 10px;
					margin-right: 10px;
				}
				.width-130 {
					width: 130px;
					text-align: left;
				}
				.el-button {
					height: 20px;
					width: 80px;
				}
			}
			.el-form-item__label {
				width: 100px;
			}
			.reject {
				.el-dialog {
					width: 900px;
					.el-dialog__body {
						min-height: 420px;
					}
				}
				.el-form-item__error {
					margin-left: 100px;
				}
			}
		}
		.el-dialog {
			min-height: 300px !important;
		}
		.el-form-item__error {
			margin-left: 110px!important;
		}
		.bindSales{
			.el-table__header{
				width:858px !important;
			}
			.tc{
				text-align: center;
			}
			.el-dialog{
				width: 900px;
				min-height: 700px !important;
			}
			.el-dialog__body{
				padding: 20px;
				.search{
					.el-form-item__label{
						width: 80px;
					}
					.el-form--inline{
						width:350px !important;
					}
				}
			}
		}
	}
</style>