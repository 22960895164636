<template>
  <div id="salesManage" class="pb-50">
    <!-- 图片预览 -->
    <imgview
      :visible="imgsvisible"
      :imgs="showimgs"
      :startindex="startindex"
      @close="imgsvisible=false"
    ></imgview>
    <!-- <div class="title-tabs bg-f black">
      <ul class="clearfix" @click="handleTabs">
        <template v-for="(tab,index) in dataTabs" class="item">
          <li :type="index" :class="type==index?'active':''" :key="index+'dk'">
            {{tab}}
            <div :class="type==index?'under-line':''"></div>
          </li>
          <div class="vertical-line" :key="index+'dl'"></div>
        </template>
      </ul>
    </div> -->
    <div class="bg-f" style="padding:20px">
      <!-- <el-form :inline="true" :model="form" label-width="90px" class="clearfix pt-30 search">
        <div class="fl width-80p">
          <el-form-item label="加入时间">
            <ys-daterange-picker v-model="Form.orderDateRange" :endDisabledDate="new Date()" />
          </el-form-item>
          <el-form-item label="销售员账号">
            <el-input v-model.trim="Form.loginName"></el-input>
          </el-form-item>
        </div>
        <el-button type="info" class="fl ml-50" @click="achieve(0)">搜索</el-button>
      </el-form> -->
      <!-- <div class="clearfix mr-30">
        <div class="fr">
          <el-button type="text" @click="exportOrder">批量导出</el-button>
          <el-button type="text" @click="exportOrder2" class="mr-30">上下级关系导出</el-button>
          <el-button type="danger" class="mb-5" @click="openNew">新增销售员</el-button>
        </div>
      </div> -->
      <el-table :data="tableData" v-loading="Loading" class="mt-10 table-default" stripe>
        <el-table-column label="账号" min-width="150">
          <template slot-scope="scope">
            <div>{{scope.row.loginName}}</div>
          </template>
        </el-table-column>
        <el-table-column label="销售员名" min-width="100">
          <template slot-scope="scope">
            <div>{{scope.row.storeName}}</div>
          </template>
        </el-table-column>
        <!-- <el-table-column label="姓名" min-width="100">
          <template slot-scope="scope">
            <div>{{scope.row.name}}</div>
          </template>
        </el-table-column> -->
        <el-table-column label="交易额(元)" min-width="90">
          <template slot-scope="scope">
            <div>￥{{scope.row.orderAllMoney||0 | fmoney }}</div>
          </template>
        </el-table-column>
        <el-table-column label="订单(笔)" min-width="180">
          <template slot-scope="scope">
            <div
              v-if="scope.row.selfOrderNum>0"
              class="cursor color-blue"
              @click="goOrder(scope,1)"
            >自营单{{scope.row.selfOrderNum}}笔</div>
            <div v-else>自营单0笔</div>
            <div
              v-if="scope.row.orderCount > scope.row.selfOrderNum"
              class="cursor color-blue"
              @click="goOrder(scope,2)"
            >久加久订单{{scope.row.orderCount-scope.row.selfOrderNum}}笔</div>
            <div v-else>久加久订单0笔</div>
          </template>
        </el-table-column>
        <el-table-column label="状态" min-width="90">
          <template slot-scope="scope">
            <span v-if="scope.row.state == '-1'" :style="{color : '#ff4201'}">已删除</span>
            <span v-if="scope.row.state == '1'">待审核</span>
            <span v-if="scope.row.state == '2'">正常</span>
            <span v-if="scope.row.state == '3'">审核失败</span>
            <span v-if="scope.row.state == '4'">已禁用</span>
            <span v-if="scope.row.state == '5'">待初审</span>
          </template>
        </el-table-column>
        <!-- <el-table-column label="推荐人" min-width="70">
          <template slot-scope="scope">
            <div>{{scope.row.referrerName ? scope.row.referrerName : '--'}}</div>
          </template>
        </el-table-column> -->
        <!-- <el-table-column label="支付凭证" width="90">
          <template slot-scope="scope">
            <div class="color-blue cursor" @click="lookIdCardImg(scope)">查看</div>
          </template>
        </el-table-column> -->
        <el-table-column label="操作" min-width="288">
          <template slot-scope="scope">
            <el-button type="text" @click="goToDetail(scope.row)">查看详情</el-button>
            <el-button type="text" @click="openNew(scope.row)">编辑销售员</el-button>
            <!-- <el-button v-if="scope.row.state == 2" type="text" @click="isForbbident(scope)">禁用</el-button> -->
            <!-- <el-button
              v-if="scope.row.state == 2"
              type="text"
              @click="handleEditBond(scope.row)"
            >修改保证金</el-button> -->
            <!-- <el-button v-if="scope.row.state == 1" type="text" @click="handlePass(scope.row)">通过</el-button>
            <el-button v-if="scope.row.state == 1" type="text" @click="handleReject(scope.row)">驳回</el-button>
            <el-button v-if="scope.row.state == 3" type="text" @click="LookReason(scope.row)">查看驳回理由</el-button>
            <el-button v-if="scope.row.state == 4" type="text" @click="isForbbident(scope)">启用</el-button> -->
            <!-- 删除后 查看操作人 -->
            <!-- <el-button
              v-if="scope.row.state == -1"
              @click="handleOperator(scope.row)"
              type="text"
            >查看</el-button>
            <el-button
              v-if="scope.row.state == -1"
              @click="handleWithdraw(scope.row)"
              type="text"
            >撤回</el-button>
            <el-button v-if="scope.row.state != -1" @click="handleDelete(scope.row)" type="text">删除</el-button> -->
          </template>
        </el-table-column>
      </el-table>
      <!-- 导出 -->
      <form
        ref="downClientForm"
        action="/sunrise-gateway/export/superc/store"
        style="display: none"
      >
        <input type="hidden" name="modelName" v-model="a" />
        <input type="hidden" name="fileName" v-model="b" />
        <input type="hidden" name="startRow" v-model="c" />
        <input type="hidden" name="state" v-model="Form.state" />
        <input type="hidden" name="loginName" v-model="Form.loginName" />
        <input type="hidden" name="storeName" v-model="Form.storeName" />
      </form>
        <!-- 导出上下级关系 -->
        <form
                ref="downClientForm2"
                action="/sunrise-gateway/export/superc/storeRelation"
                style="display: none"
        >
            <input type="hidden" name="modelName" value="empty">
            <input type="hidden" name="fileName" value="销售员上下级.xls">
            <input type="hidden" name="startRow" value="0">
            <input type="hidden" name="sellerId" :value="formDown.sellerId">
            <input type="hidden" name="supercType" :value="formDown.supercType">
        </form>
      <!-- 新增销售员 -->
      <el-dialog title="编辑销售员" :visible.sync="addManage" :close-on-click-modal="false">
        <el-form
          size="large"
          class="ml-30"
          :model="form"
          label-width="110px"
          :rules="rules"
          ref="form"
        >
          <!-- <el-form-item label="账号:" prop="loginName">
            <el-input
              class="inputTitle"
              v-model.trim="form.loginName"
              auto-complete="off"
              placeholder="请输入手机号"
              :maxlength="21"
            ></el-input>
          </el-form-item> -->
          <el-form-item label="姓名:" prop="name">
            <el-input
              class="inputTitle"
              v-model.trim="form.name"
              auto-complete="off"
              placeholder="请输入姓名"
              :maxlength="6"
            ></el-input>
          </el-form-item>
          <el-form-item label="身份证号:" prop="idCard">
            <el-input
              class="inputTitle"
              v-model.trim="form.idCard"
              auto-complete="off"
              placeholder="请输入身份证号码"
              :maxlength="18"
            ></el-input>
          </el-form-item>
          <!-- <el-form-item label="已缴保证金:" prop="bondMoney">
            <el-input
              class="inputTitle"
              v-model.trim="form.bondMoney"
              auto-complete="off"
              placeholder="请输入已缴保证金"
              :maxlength="18"
            ></el-input>元
          </el-form-item>
          <el-form-item label="已缴服务费:" prop="serviceMoney">
            <el-input
              class="inputTitle"
              v-model.trim="form.serviceMoney"
              auto-complete="off"
              placeholder="请输入已缴服务费"
              :maxlength="18"
            ></el-input>元/年
          </el-form-item> -->
          <!-- <el-form-item label="身份证照片:" prop="idCardImg">
            <div class="img" style="height: 90px;">
              <el-upload
                :class="isAd?'dis':''"
                action="/sunrise-gateway/oss/ossUpload"
                list-type="picture-card"
                :before-upload="beforeAvatarUpload"
                :on-success="handleAvatarSuccessList"
                :on-preview="handlePictureCardPreview"
                :file-list="form.idCardImg"
                :on-remove="handleRemove"
              >
                <i class="el-icon-plus"></i>
              </el-upload>
            </div>
            <span>(请上传身份证正面和反面)</span>
          </el-form-item> -->
        </el-form>
        <div slot="footer" class="dialog-footer footer mt-30">
          <el-button type="danger" @click="editInfo('form')">确 定</el-button>
          <!-- <el-button type="danger" @click="addShop('form')">确 定</el-button> -->
        </div>
      </el-dialog>
      <!--上传图片-->
      <el-dialog :visible.sync="dialogVisible" size="tiny">
        <img width="100%" :src="image" alt />
      </el-dialog>
      <!-- 身份证照片 -->
      <el-dialog title="保证金缴纳凭证" class="imgContainer" :visible.sync="idCardImg">
        <div class="p20">
          <div class="imgWrap tac" v-if="CardImg.length!=0">
            <img
              v-for="(item,index) in CardImg"
              :src="item"
              :key="index"
              @click="imgview(CardImg,index)"
            />
          </div>
          <div class="nocon" v-else>暂无数据</div>
        </div>
      </el-dialog>
      <!-- 授信管理弹窗 -->
      <el-dialog title="授信管理" class="accredit-dialog" :visible.sync="showAccredit">
        <div>
          <el-form :model="accreditForm" :rules="accreditRules" ref="accredit">
            <el-form-item label="授信额度:" prop="creditMoney">
              <el-input v-model="accreditForm.creditMoney"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div class="accredit-center">
          <div>已用额度：{{useCreditMoney}}</div>
          <div>
            可用额度：
            <span
              v-if="Number(accreditForm.creditMoney) || Number(accreditForm.creditMoney) == 0"
            >
              <span
                v-if="String( Number(accreditForm.creditMoney) - Number(useCreditMoney) ).indexOf('.') > -1"
              >{{ ( Number(accreditForm.creditMoney) - Number(useCreditMoney) ).toFixed(2)}}</span>
              <span v-else>{{ ( Number(accreditForm.creditMoney) - Number(useCreditMoney) )}}</span>
            </span>
          </div>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="lookAccreditDetail">查看授信明细</el-button>
          <el-button type="danger" @click="confirmAccredit">修改额度</el-button>
        </div>
      </el-dialog>
      <!--修改保证金弹出框-->
      <el-dialog class="bond" title="修改保证金" :visible.sync="editBond" @close="closeBond">
        <el-form ref="bondForm" class="pr-30" :model="bondForm" :rules="rules1" label-width="100px">
          <el-form-item label="保证金" prop="money">
            <el-input placeholder="请输入保证金" v-model.trim="bondForm.money" :maxlength="6"></el-input>元
          </el-form-item>
          <el-form-item class="mb-5" label="修改理由" prop="reason">
            <el-input
              type="textarea"
              :autosize="{ minRows: 3 ,maxRows: 10}"
              :maxlength="100"
              placeholder="请输入修改理由.."
              v-model.trim="bondForm.reason"
            ></el-input>
          </el-form-item>
          <br />
          <span class="color-9 font-12 gaze">修改理由会以消息的方式通知久集用户，请认真填写</span>
          <br />
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="danger" @click="ensureBondMoney">确定</el-button>
          <el-button @click="editBond = false">取消</el-button>
        </div>
      </el-dialog>
      <!-- 分页组件 -->
      <!-- <el-pagination
        class="tar mt-20"
        v-if="total>0"
        :current-page="currentPage"
        :page-size="10"
        :total="total"
        layout="prev , pager, next, jumper"
        @current-change="handleCurrentChange"
      ></el-pagination> -->
      <!-- 审核 -->
      <el-dialog :title="reviewTit" class="review-dialog" :visible.sync="showReview">
        <div class="content">
          <el-radio-group v-model="checkRadio">
            <el-radio :label="1">通过</el-radio>
            <el-radio :label="2">驳回</el-radio>
          </el-radio-group>
          <el-input
            v-if="checkRadio==2"
            class="mt-20"
            placeholder="请输入驳回原因"
            type="textarea"
            :maxlength="100"
            :autosize="{ minRows: 2, maxRows: 4}"
            v-model.trim="reason"
          ></el-input>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button type="danger" @click="saveReview">确定</el-button>
          <el-button @click="showReview=false">取消</el-button>
        </div>
      </el-dialog>
      <!-- 驳回弹窗 -->
      <el-dialog class="reject" title="驳回" :visible.sync="reject">
        <el-form ref="rejectForm" class="pr-30" :model="rejectForm" :rules="rules2">
          <el-form-item class="ml-40" prop="reason">
            <el-input
              type="textarea"
              :autosize="{ minRows: 3 ,maxRows: 10}"
              :maxlength="100"
              placeholder="请输入驳回理由.."
              v-model.trim="rejectForm.reason"
            ></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="danger" @click="ensureReject">确定</el-button>
          <el-button @click="reject = false">取消</el-button>
        </div>
      </el-dialog>
      <!-- 查看驳回理由 -->
      <el-dialog title="查看驳回理由" class="reason" :visible.sync="reasonFlag">
        <div class="look_reason">{{lookreason}}</div>
      </el-dialog>
    </div>
    <!-- 删除 查看操作人 -->
    <div class="operator-box">
      <el-dialog title="查看" :visible.sync="dialogStateOperator">
        <el-table :data="listOperator">
          <el-table-column property="deleteEmployeeName" label="操作人"></el-table-column>
          <el-table-column property="deleteTime" label="操作时间">
            <template slot-scope="scope">{{scope.row.deleteTime | time}}</template>
          </el-table-column>
        </el-table>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { axiosApi, basePath } from "../../../api/api";
import md5 from "md5";
// import YsDaterangePicker from "~/components/ys-daterange-picker";
export default {
  // components: {YsDaterangePicker},
  data() {
    var imgDouble = (rule, value, callback) => {
      if (this.form.idCardImg.length == 1) {
        callback(new Error("请上传正反2张照片"));
      } else {
        callback();
      }
    };
    var validateCreditMoney = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入授信额度!"));
      } else if (
        Number(this.accreditForm.creditMoney) < Number(this.useCreditMoney)
      ) {
        callback(new Error("可用额度不能小于0!"));
      } else if (/^([1-9][\d]{0,7}|0)(\.[\d]{1,2})?$/.test(value) == false) {
        callback(new Error("请输入整数部分最大为8位的正整数或者两位小数!"));
      } else {
        callback();
      }
    };
    var checkMoney = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("保证金不能为空"));
      }
      if (isNaN(Number(value))) {
        callback(new Error("请输入数字值"));
      } else {
        let reg = /^([1-9]\d*|[0]{1})$/;
        if (!reg.test(value)) {
          callback(new Error("请输入正确的数字值"));
        } else {
          callback();
        }
        callback();
      }
    };
    var address = (rule, value, callback) => {
      let val;
      if (value) {
        val = value.trim();
      }
      if (!val) {
        return callback(new Error("详细地址不能为空"));
      }
      if (val.length > 50 || val.length < 4) {
        return callback(new Error("地址长度为4-50个字符"));
      } else {
        callback();
      }
    };
    return {
        formDown:{
            companyId:'',
            supercType:'',
        },
      showReview: false, //审核弹窗
      reviewTit: null, //弹窗标题
      checkRadio: 1, //通过为1 驳回为2
      reason: "", //驳回原因
      salerId: null, //id
      stateReview: null, //状态
      a: "empty",
      b: "销售员管理.xls",
      c: "0",

      basePath: basePath,
      dataTabs: ["全部", "待审核", "已通过", "已驳回", "已禁用", "已删除"],
      type: "0",
      Loading: false,
      activeTab: "1",
      tableData: [], //商品列表
      searchClassify: [], //搜索分类
      //分页
      currentPage: 1,
      total: 0,
      isPagination: true,
      auditStatus: null,
      form: {
        name: null,
        loginName: null,
        idCard: null,
        referrerStoreLoginName: null,
        idCardImg: [],
        // bondMoney: null,
        // serviceMoney: null,
        address: null,
        selectedOptions: [],
        provinceId: null,
        cityId: null,
        regionId: null
      },
      Form: {
        orderDateRange: [],
        loginName: null,
        storeName: null,
        state: null,
        startDate: null,
        endDate: null
      },
      addManage: false, //新增销售员弹框，
      isAd: false,
      image: "",
      dialogVisible: false, //上传图片预览照片按钮
      idCardImg: false, //查看按钮
      imgsvisible: false, //身份证单个图片查看
      showimgs: [],
      startindex: 0,
      CardImg: [],
      rules: {
        loginName: [
          {
            required: true,
            pattern: /^1\d{10}$/,
            message: "请输入正确的11位手机号"
          },
          {
            max: 50,
            message: "请输入50个以内的字符"
          }
        ],
        idCardImg: [
          {
            required: true,
            type: "array",
            message: "请上传图片"
          },
          {
            validator: imgDouble
          }
        ],
        name: [
          {
            required: true,
            pattern: /^[a-zA-Z\u4e00-\u9fa5]{2,6}$/,
            message: "请输入2—6字（汉字，字母）"
          }
        ],
        idCard: [
          {
            required: true,
            pattern: /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/,
            message: "请输入正确的身份证号"
          }
        ],
        // bondMoney: [
        //   {
        //     required: true,
        //     message: "请输入正确保证金",
        //     pattern: /^[0-9]{1,7}([.]{1}[0-9]{0,2}){0,1}$/
        //   }
        // ],
        // serviceMoney: [
        //   {
        //     required: true,
        //     message: "请输入正确服务费",
        //     pattern: /^[0-9]{1,7}([.]{1}[0-9]{0,2}){0,1}$/
        //   }
        // ]
      },
      rules1: {
        money: [
          { required: true, message: "保证金不能为空" },
          { validator: checkMoney, trigger: "change" }
        ],
        reason: [
          {
            required: true,
            message: "修改理由不能为空"
          },
          {
            min: 4,
            max: 100,
            message: "修改理由为4-100位字符"
          }
        ]
      },
      rules2: {
        reason: [
          {
            required: true,
            message: "驳回理由不能为空"
          },
          {
            min: 4,
            max: 100,
            message: "驳回理由为4-100位字符"
          }
        ]
      },
      // 授信管理
      clickedItem: null,
      showAccredit: false,
      accreditForm: {
        creditMoney: 0
      },
      useCreditMoney: 0,
      accreditRules: {
        creditMoney: [{ validator: validateCreditMoney, trigger: "blur" }]
      },
      dialogStateOperator: false,
      listOperator: [],
      DefaultBondMoney: null,
      DefaultServiceMoney: null,
      editBond: false,
      bondItem: null, //保证金那条数据
      bondForm: {
        money: null,
        reason: null
      },
      reject: false,
      rejectItem: null, //驳回的那条数据信息
      rejectForm: {
        reason: null //输入文本框的驳回理由
      },
      lookreason: null, //查看驳回理由
      reasonFlag: false
    };
  },
  methods: {
    handleReject(item) {
      //驳回
      this.reject = true;
      this.rejectItem = item;
      this.rejectForm.reason = "";
      if (this.$refs.rejectForm) {
        this.$refs.rejectForm.resetFields();
      }
    },
    handleEditBond(item) {
      //修改保证金
      this.editBond = true;
      this.bondForm.money = item.bondMoney;
      this.bondItem = item;
    },
    closeBond() {
      //保证金弹窗关闭清空数据,清空验证规则
      this.bondForm = {
        money: null,
        reason: null
      };
      if (this.$refs.bondForm) {
        this.$refs.bondForm.resetFields();
      }
    },
    ensureReject() {
      //驳回确定按钮
      setTimeout(() => {
        this.$refs.rejectForm.validate(valid => {
          if (valid) {
            const body = {
              id: this.rejectItem.id,
              state: 3,
              reason: this.rejectForm.reason
            };
            axiosApi("/superc/supercStore/updateState", body, res => {
              this.achieve();
              this.reject = false;
            });
          }
        });
      }, 0);
    },
    handlePass(item) {
      //通过
      const h = this.$createElement;
      this.$msgbox({
        title: "提示信息",
        message: h("p", { style: "text-align:center" }, "确认通过该账号？"),
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            const body = {
              id: item.id,
              state: 2
            };
            axiosApi(
              "/superc/supercStore/updateState",
              body,
              res => {
                this.achieve();
              },
              1,
              10
            );
            done();
          } else {
            done();
          }
          delete instance.$slots.default;
        }
      });
    },
    LookReason(item) {
      //查看驳回理由
      this.reasonFlag = true;
      this.lookreason = item.reason;
    },
    ensureBondMoney() {
      //保证金修改确定按钮
      setTimeout(() => {
        this.$refs.bondForm.validate(valid => {
          ////console.log(valid)
          if (valid) {
            const body = {
              id: this.bondItem.id,
              bondMoney: this.bondForm.money,
              updateAmountReason: this.bondForm.reason
            };
            axiosApi(
              "/superc/supercStore/updateBond",
              body,
              res => {
                this.achieve();
                this.editBond = false;
              },
              1,
              10
            );
          }
        });
      }, 0);
    },

    goToDetail(row) {
      //去详情
      this.$router.push(basePath + "/supercSaleDetail/" + row.id);
    },
    //批量导出
    exportOrder() {
      if (this.tableData.length > 0) {
        this.$refs.downClientForm.submit();
      } else {
        this.$message({
          type: "info",
          message: "暂无数据导出",
          duration: 3000
        });
      }
    },
      //批量导出上下级关系
    exportOrder2() {
      if (this.tableData.length > 0) {
        this.$refs.downClientForm2.submit();
      } else {
        this.$message({
          type: "info",
          message: "暂无数据导出",
          duration: 3000
        });
      }
    },
    // 获取默认保证金和服务费，新增时使用
    getSetData() {
      //查询设置的提现日期
      axiosApi(
        "/superc/traderOpenSuperC/select",
        {
          traderId: sessionStorage.getItem("traderId")
        },
        res => {
          //配置信息
          this.DefaultBondMoney =
            res.data.data.bondMoney == null ? 0 : res.data.data.bondMoney;
          this.DefaultServiceMoney =
            res.data.data.serviceMoney == null ? 0 : res.data.data.serviceMoney;
        }
      );
    },
    handleTabs(e) {
      //Tab切换
      if (e.target.attributes.type) this.type = e.target.attributes.type.value;

      this.Form.addDateRange = [];
      this.Form.cNum = "";
      if (this.type == "0") {
        //全部
        this.Form.state = "";
      } else if (this.type == "1") {
        //待审核
        this.Form.state = 1;
      } else if (this.type == "2") {
        //已通过
        this.Form.state = 2;
      } else if (this.type == "3") {
        //已驳回
        this.Form.state = 3;
      } else if (this.type == "4") {
        //已禁用
        this.Form.state = 4;
      } else if (this.type == "5") {
        // 已删除
        this.Form.state = -1;
      }
      this.achieve(0);
    },
    //酒店店铺分页查询
    achieve(payload) {
      if (payload == 0 && this.currentPage != 1) {
        this.currentPage = 1;
        return;
      }
      this.Form.startDate = this.Form.orderDateRange[0]
        ? this.Form.orderDateRange[0].getTime()
        : null;
      this.Form.endDate = this.Form.orderDateRange[1]
        ? this.Form.orderDateRange[1].getTime()
        : null;
      this.Loading = true;
      axiosApi(
        "/superc/supercStore/selectList",
        {
          state: this.Form.state,
          loginName: this.Form.loginName,
          // storeName: this.Form.storeName,
          startDate: this.Form.startDate,
          endDate: this.Form.endDate
        },
        res => {
          this.tableData = res.data.data.elements ? res.data.data.elements : [];
          this.total = res.data.data.totalCount;
          this.Loading = false;
        },
        this.currentPage,
        10,
        res => {
          this.Loading = false;
        }
      );
    },
    goOrder(scope, type) {
      // type = 1 自营单;type = 2 久加久订单
      if (sessionStorage.getItem("isAdmin") == 1) {
        this.$router.push({
          path: basePath + "/storeOrder",
          query: {
            supercStoreId: scope.row.id,
            type: 0,
            orderType: type,
            state: scope.row.state
          }
        });
        // if (scope.row.state == "2") {
        //   this.$router.push({
        //     path: basePath + "/storeOrder",
        //     query: {
        //       supercStoreId: scope.row.id,
        //       type: 0
        //     }
        //   });
        // } else {
        //   this.$router.push({
        //     path: basePath + "/storeOrder",
        //     query: {
        //       supercStoreId: scope.row.id,
        //       nm: scope.row.storeName,
        //       type: 0
        //     }
        //   });
        // }
      }
    },
    // 老新增
    openNew(info) {
      this.isAd = false;
      this.form = {
        traderId: info.traderId,
        name: info.name,
        loginName: null,
        idCard: info.idCard,
        referrerStoreLoginName: null,
        idCardImg: [],
        bondMoney: this.DefaultBondMoney,
        serviceMoney: this.DefaultServiceMoney,
        address: null,
        selectedOptions: [],
        provinceId: null,
        cityId: null,
        regionId: null
      };
      if (this.$refs.form !== undefined) {
        this.$refs.form.resetFields();
      }
      this.addManage = true;
    },
    lookIdCardImg(scope) {
      console.log(scope.row.bondImg, "scope.row.bondImg");
      if (scope.row.bondImg && scope.row.bondImg != "") {
        this.CardImg = scope.row.bondImg.split(",");
        setTimeout(() => {
          this.idCardImg = true;
        }, 0);
      } else {
        this.idCardImg = true;
      }
    },
    imgview(imgs, index) {
      this.showimgs = imgs;
      this.startindex = index;
      this.imgsvisible = true;
    },
    handleRemove(file, filelist) {
      //照片删除
      this.form.idCardImg = [];
      this.form.idCardImg = filelist;
      this.loading = false;
      if (this.form.idCardImg.length > 1) {
        this.isAd = true;
      } else {
        this.isAd = false;
      }
    },
    handlePictureCardPreview(file) {
      //照片预览
      this.image = file.url;
      this.dialogVisible = true;
    },
    beforeAvatarUpload(file) {
      //上传之前图片的判断
      const isImage =
        file.type.indexOf("jpg") != -1 ||
        file.type.indexOf("jpeg") != -1 ||
        file.type.indexOf("png") != -1 ||
        file.type.indexOf("bmp") != -1;
      const isLt4M = file.size / 1024 / 1024 < 4 && file.size / 1024 / 1024 > 0;
      if (!isImage) {
        this.$message.error("上传图片只能是jpg,jpeg,png,bmp格式");
      }
      if (!isLt4M) {
        this.$message.error("上传图片大小不能大于 4MB或小于 0MB!");
      }
      return isImage && isLt4M;
    },
    handleAvatarSuccessList(file) {
      //成功上传照片
      this.form.idCardImg.push({
        url: file.data[0]
      });
      if (this.form.idCardImg.length > 1) {
        this.isAd = true;
      } else {
        this.isAd = false;
      }
    },
    // 老新增
    addShop(form) {
      //增加销售员
      this.$refs[form].validate(valid => {
        if (valid) {
          let imgList = [];
          this.form.idCardImg.map(item => {
            imgList.push(item.url);
          });
          axiosApi(
            "/superc/web/tenantShopStore/register",
            {
              loginName: this.form.loginName,
              name: this.form.name,
              idCard: this.form.idCard,
              bondMoney: this.form.bondMoney,
              serviceMoney: this.form.serviceMoney,
              idCardImg: imgList
            },
            res => {
              this.achieve();
              this.$message({
                type: "success",
                message: "新增成功",
                duration: 3000
              });
              this.addManage = false;
            },
            1,
            10,
            res => {
              this.$message({
                type: "info",
                message: res.data.message,
                duration: 3000
              });
            }
          );
        } else {
        }
      });
    },
    // 新编辑
    editInfo(form){
      console.log(this.form,'sss')
      this.$refs[form].validate(valid => {
        if (valid) {
          axiosApi(
            "/superc/superc/updateInfo",
            {
              traderId : this.form.traderId,
              name: this.form.name,
              idCard: this.form.idCard,
            },
            res => {
              this.achieve();
              this.$message({
                type: "success",
                message: "编辑成功",
                duration: 3000
              });
              this.addManage = false;
            },
            1,
            10,
            res => {
              this.$message({
                type: "info",
                message: res.data.message,
                duration: 3000
              });
            }
          );
        } else {
        }
      });
    },
    Review(scope, time) {
      //审核按钮
      this.stateReview = scope.row.state;
      this.salerId = scope.row.id;
      if (scope.row.state == 5) {
        this.reviewTit = "初审";
      } else if (scope.row.state == 1) {
        this.reviewTit = "复审";
      }
      this.showReview = true;
    },
    saveReview() {
      //保存审核状态
      const api = "/superc/supercStore/updateState";
      let state = null;
      if (this.checkRadio == 2) {
        //驳回
        state = 3;
      } else {
        if (this.stateReview == 5) {
          //初审
          state = 1;
        } else if (this.stateReview == 1) {
          //复审
          state = 2;
        }
      }
      axiosApi(
        api,
        {
          id: this.salerId,
          reason: this.reason,
          state: state
        },
        res => {
          this.achieve();
          this.showReview = false;
          let msg = "";
          state == 3 && (msg = "驳回成功！");
          state == 1 && (msg = "初审成功！");
          state == 2 && (msg = "复审成功！");
          this.$message({
            type: "success",
            message: msg,
            duration: 1000
          });
          that.achieve();
        },
        1,
        10,
        res => {
          this.showReview = false;
          this.$message({
            type: "info",
            message: res.data.message,
            duration: 3000
          });
        }
      );
    },
    //禁用，启用
    isForbbident(val) {
      let title = val.row.state == "2" ? "禁用" : "启用";
      let that = this;
      this.$confirm("您是否要" + title + "该销售员？", "提示信息", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          const api = "/superc/supercStore/updateState";
          axiosApi(
            api,
            {
              id: val.row.id,
              state: val.row.state == "2" ? "4" : "2"
            },
            res => {
              this.$message({
                type: "success",
                message: "操作成功!",
                duration: 1000
              });
              that.achieve();
            },
            this.currentPage,
            10,
            res => {
              this.$message({
                type: "info",
                message: res.data.message,
                duration: 3000
              });
            }
          );
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作"
          });
        });
    },
    showAccreditBtn(row) {
      if (this.$refs["accredit"] != undefined) {
        this.$refs["accredit"].resetFields();
      }
      this.clickedItem = row;
      this.showAccredit = true;
      this.accreditForm.creditMoney = row.creditMoney;
      this.useCreditMoney = row.useCreditMoney;
    },
    confirmAccredit() {
      this.$refs["accredit"].validate(valid => {
        if (valid) {
          axiosApi(
            "/superc/app/updateCreditMoney",
            {
              id: this.clickedItem.id,
              creditMoney: this.accreditForm.creditMoney
            },
            res => {
              this.showAccredit = false;
              this.$message({
                type: "success",
                message: "修改额度成功!",
                duration: 1000
              });
              this.achieve();
            },
            1,
            10,
            err => {
              this.$message.error(err.data.message);
            }
          );
        }
      });
    },
    lookAccreditDetail() {
      this.$router.push({
        path: basePath + "/creditDetail",
        query: { id: this.clickedItem.id }
      });
    },
    //			分页
    handleCurrentChange(val) {
      this.currentPage = val;
      this.achieve();
    },
    // 删除
    handleDelete(row) {
      console.log(row);
      this.$confirm("确认删除该账号？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.deleteFn(row.id);
        })
        .catch(() => {});
    },
    // 删除逻辑
    deleteFn(id) {
      axiosApi(
        "/superc/supercStore/web/delete",
        {
          id: id
        },
        res => {
          this.$message.success("操作成功");
          this.achieve(0);
        },
        1,
        1,
        err => {
          this.$message.warning(err.data.message);
        }
      );
    },
    // 删除 - 查看
    handleOperator(row) {
      this.dialogStateOperator = true;
      axiosApi(
        "/superc/delete/supercStore/select",
        {
          id: row.id
        },
        res => {
          let data = res.data.data;
          this.listOperator = [
            {
              deleteEmployeeName: data.deleteEmployeeName,
              deleteTime: data.deleteTime
            }
          ];
        }
      );
    },
    // 删除 - 撤回
    handleWithdraw(row) {
      this.$confirm("确认恢复该账号？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          axiosApi(
            "/superc/supercStore/web/resume",
            {
              id: row.id
            },
            res => {
              this.$message.success("操作成功");
              // 重置表格数据
              this.type = "0";
              this.Form.state = "";
              this.achieve(0);
            },
            1,
            1,
            err => {
              this.$message.warning(err.data.message);
            }
          );
        })
        .catch(() => {});
    }
  },
  //进入加载
  mounted() {
    this.$nextTick(() => {
      this.achieve();
      this.getSetData();
        this.formDown = {
            sellerId: sessionStorage.traderId,
            supercType: 3,
        }
    });
  }
};
</script>

<style lang="less">
#salesManage {
  .look_reason {
    word-wrap: break-word;
    text-align: left;
    padding:0 30px;
    box-sizing:border-box;
  }
  .table-default.el-table .cell {
    padding: 10px 0px;
  }
  .table-default .el-button {
    width: auto;
  }
  .el-tabs__header {
    padding-left: 0;
  }
  .el-dialog {
    min-height: 300px;
  }
  .black {
    .edit-btn span {
      color: #000000 !important;
    }
  }
  .el-upload-list__item {
    width: 90px;
    height: 90px;
  }
  .el-upload--picture-card {
    width: 90px;
    height: 90px;
    line-height: 90px;
    background-size: 50px 50px;
  }
  .dis .el-upload--picture-card {
    display: none;
  }
  .imgContainer {
    .el-dialog__body {
      padding: 10px 30px;
      .imgWrap {
        align-items: center;
          justify-content: space-around;
        overflow-x: auto;
        display: flex;
        img {
          height: 250px;
          width: auto;
          margin-right: 10px;
          &:nth-last-child(1) {
            margin-right: 0;
          }
        }
      }
    }
  }
  .nocon {
    text-align: center;
    margin-top: 15%;
  }

  .accredit-dialog {
    .accredit-center {
      padding-left: 115px;
      line-height: 32px;
      & > div {
        color: #333;
        &:nth-child(1) {
          margin-bottom: 10px;
          margin-right: 10px;
        }
      }
    }
    .el-form-item__error {
      left: 185px;
    }
  }
  .review-dialog {
    .content {
      text-align: center;
      padding: 0 50px;
    }
    .el-dialog {
      min-height: 320px;
    }
  }
  .ml-05 {
    margin-left: -5px;
  }
  /* 浮层样式 修改 */
  .operator-box {
    .el-dialog {
      min-height: auto !important;
    }
    .el-dialog__body {
      padding: 20px 30px !important;
    }
  }
  .el-table__body-wrapper {
  margin-top:1px!important;
  }
  .el-dialog {
    min-height: 350px;
    .el-dialog__body {
      padding: 40px 0 80px 0;
    }
    .el-form-item__label {
      width: 160px;
    }
    .el-textarea {
      .el-textarea__inner {
        border-radius: 0;
      }
    }
    .gaze {
      margin-left: 100px;
    }
  }
  .bond {
    .el-textarea {
      width: 300px;
    }
  }
}
</style>
