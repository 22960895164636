<template>
	<!--酒店服务协议-->
	<div id="hotelService">
		<div class="title-top bg-f clearfix">酒店版久集</div>
		<div class="content bg-f mt-5">
			<div class="content-box">
                <!-- <p>服务协议</p> -->
                <div class="con">
                    <p style="text-align:center;text-indent:28px"> <strong><span style="font-size:19px;font-family: 华文宋体">久加久·久集（酒店版）产品说明</span></strong></p>
                    <p style="text-indent:28px">
                        久加久·久集（酒店版）是浙江久加久科技股份有限公司自主研发的分销平台。酒店的大堂经理或者服务员可以通过APP向客户传递久加久的产品和服务，从而获得佣金，酒店也可获取丰厚的利润。对于酒店最关心的成本问题上，网络分销可以将商品库存的钱省下来，减轻财务负担，或者是回馈给消费者以扩大市场。在有效的控制好成本之后，用户还可通过微信分享的链接在线下单，这种高效方便的购物方式给酒店带来更多的收益，可谓是相得益彰，一举两得。
                    </p>
                    <p><br/></p>
                </div>
            </div>
            <div class="footer mt-30">
				<!-- <p><el-checkbox v-model="checked">我已阅读并同意上述协议</el-checkbox></p> -->
				<p><el-checkbox v-model="checked">我已知晓</el-checkbox></p>
				<el-button type="danger" :class="{'disabled': !checked}" @click="agree">开通酒店版久集</el-button>
            </div>
		</div>
	</div>
</template>

<script>
	import { axiosApi, basePath } from "../../../api/api";
	export default {
		data() {
			return {
				checked: false,
			}
		},
		mounted() {
			this.$nextTick(() => {

			})
		},
		methods: {
            agree(){
                if(this.checked){
                    axiosApi('/superc/hotelSeller/openSuperc',{traderId: sessionStorage.traderId},res=>{
                        this.$message({type:'success',message:'开通成功',duration:1000})
                        this.$router.push(basePath + '/salesManage')
                        location.reload()
                    },1,10,err => {
                        this.$message({type:'info',message:err.data.message,duration:1000})
                    })
                }
            }
		}
	}
</script>

<style lang="less">
	#hotelService {
		.content {
            height: 594px;
            box-sizing: border-box;
            padding: 30px 50px 40px 50px;
            .content-box{
                width:100%;
                height: 422px;
                box-sizing: border-box;
                border: 1px solid #DDDDDD;
                p{
                    /*// text-align: center;*/
                    margin-top: 25px;
                    font-size: 16px;
                }
                .con{
                    margin-top: 20px;
                    padding: 0 20px;
                    height: 330px;
                    overflow: hidden;
                    overflow-y: auto;
                }
            }
            .footer{
                text-align: center;
                p{ margin-bottom: 20px; }
                .el-button{
                    width: 120px;
                    &.disabled {
                        background-color: #999999 !important;
                        border-color: #999999 !important;
                    }
                }

            }
		}
	}
</style>
