<template>
  <!--久集 仓库-->
  <div id="nowInventory">
    <div class="title-top bg-f clearfix">即时库存</div>
    <div class="content bg-f pr-20 pl-20 mt-5">
      <div class="clearfix search">
        <el-form :inline="true" class="pt-30 fl" :model="form" ref="searchForm">
          <el-form-item class="mr-30" label="商品编码" prop="goodsNo">
            <el-input v-model="form.goodsNo" placeholder="请输入商品编码" :maxlength="20"></el-input>
          </el-form-item>
          <el-form-item label="商品名称">
            <el-input v-model.trim="form.skuName" placeholder="请输入商品名称" :maxlength="30"></el-input>
          </el-form-item>
          <el-form-item class="mr-30" label="商品分类">
            <el-select v-model="form.classifyId" placeholder="请选择商品分类" clearable>
              <el-option
                v-for="(tab,index) in classList"
                :label="tab.classifyName"
                :value="tab.id"
                :key="index"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="仓库名称">
            <el-select v-model="form.warehouseId" placeholder="请选择仓库" clearable>
              <el-option
                v-for="item in storeTypeList"
                :label="item.name"
                :value="item.id"
                :key="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <el-button class="fl ml-30 mtI-50" type="info" @click="achive(true)">搜索</el-button>
      </div>
      <div class="pb-20">
        <el-table :data="tableData">
          <el-table-column label="商品名称" width="220">
            <template slot-scope="scope">
              <div class="fl">
                <img v-if="scope.row.fileUrl" :src="scope.row.fileUrl" alt />
                <img v-else src="../../assets/goods.jpg" alt />
              </div>
              <div class="fl lh-16">
                <div class="width-130">
                  商品编码:<span>{{scope.row.goodsNo}}</span>
                </div>
                <div class="width-130" show-overflow-tooltip>{{scope.row.skuName}}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="规格">
            <template slot-scope="scope">
              <span>1*{{scope.row.specInfoNum}}</span>
            </template>
          </el-table-column>
          <el-table-column label="分类">
            <template slot-scope="scope">
              <span>{{scope.row.classifyName}}</span>
            </template>
          </el-table-column>
          <el-table-column label="仓库名称">
            <template slot-scope="scope">
              <span>{{scope.row.warehouseName}}</span>
            </template>
          </el-table-column>
          <el-table-column label="基本单位">
            <template slot-scope="scope">
              <span>{{scope.row.units}}</span>
            </template>
          </el-table-column>
          <el-table-column label="总库存">
            <template slot-scope="scope">
              <span>{{scope.row.stockQuantity}}</span>
            </template>
          </el-table-column>
          <el-table-column label="可用库存">
            <template slot-scope="scope">
              <span>{{scope.row.stockEnableQuantity}}</span>
            </template>
          </el-table-column>
          <el-table-column label="成本均价">
            <template slot-scope="scope">
              <span>{{scope.row.averagePrice | fmoney}}</span>
            </template>
          </el-table-column>
          <el-table-column label="总成本">
            <template slot-scope="scope">
              <span>{{scope.row.warehouseTotalPrice | fmoney}}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <el-button type="text" @click="goDetail(scope.row)">明细</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          class="tar"
          v-if="tableData.length>0"
          :current-page="currentPage"
          :page-size="10"
          :total="total"
          layout="prev , pager, next, jumper"
          @current-change="handleCurrentChange"
        >
          <!-- 分页组件 -->
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { axiosApi, basePath } from "../../api/api";
export default {
  data() {
    return {
      form: {
        skuName: null, //商品名称
        goodsNo: null, //商品编码
        classifyId: null, //商品分类
        warehouseId: null //K3仓库
      },
      storeTypeList: [],
      tableData: [], //商品数据
      classList: [], //分类数据
      currentPage: 1,
      total: null //页面分页总数
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.achive(); //默认加载商品列表
      this.getClassList(); //加载分类列表
      this.getStoreType();
    });
  },
  methods: {
    //清空搜索条件
    clear() {
      this.form = {
        skuName: null,
        goodsNo: null,
        classifyId: null,
        warehouseId: null
      };
    },
    getClassList() {
      //获取分类列表
      axiosApi(
        "/goods/classifyTopList/select",
        {},
        res => {
          this.classList = res.data.data;
        },
        1,
        100,
        res => {
          this.$message({
            type: "info",
            message: res.data.message,
            duration: 3000
          });
        }
      );
    },
    getStoreType() {
      //获取仓库列表
      axiosApi(
        "/warehouse/info/list",
        {},
        res => {
          this.storeTypeList = res.data.data;
        },
        1,
        100,
        res => {
          this.$message({
            type: "info",
            message: res.data.message,
            duration: 3000
          });
        }
      );
    },
    goDetail(item) {
      this.$router.push({
        path: basePath + "/inventoryDetail",
        query: { skuNo: item.skuNo, warehouseId: item.warehouseId }
      });
    },
    achive(val) {
      //获取已添加商品列表
      if (val && this.currentPage !== 1) {
        //重置为1
        this.currentPage = 1;
        return
      }
      const api = "/warehouse/skuinfo/stock/list";
      const body = {
        sellerId: sessionStorage.traderId,
        goodsNo: this.form.goodsNo,
        skuName: this.form.skuName,
        classifyId: this.form.classifyId,
        skuState: null,
        auditState: 2,
        warehouseId: this.form.warehouseId
      };
      this.$refs.searchForm.validate(valid => {
        if (valid) {
          axiosApi(
            api,
            body,
            res => {
              this.tableData = res.data.data.elements;
              this.total = res.data.data.totalCount;
            },
            this.currentPage,
            10,
            res => {
              this.$message({
                type: "info",
                message: res.data.message,
                duration: 3000
              });
            }
          );
        }
      });
    },
    handleCurrentChange(val) {
      //分页查询
      this.currentPage = val;
      this.achive();
    }
  }
};
</script>

<style lang="less">
#nowInventory {
  .content {
    .mtI-50 {
      margin-top: 82px !important;
    }
    .el-form--inline {
      width: 650px;
      .el-form-item__label {
        font-size: 12px;
        width: 60px;
      }
      .order-datatimerange {
        width: 240px;
      }
    }
    .el-table__body-wrapper {
      margin-top: 1px !important;
    }
    .el-button + .el-button {
      margin-left: 0;
    }
    .el-table .cell {
      text-align: center;
      padding: 10px 0;
      img {
        width: 60px;
        height: 60px;
        margin-left: 10px;
        margin-right: 10px;
      }
      .width-130 {
        width: 130px;
        text-align: left;
      }
      .el-button {
        height: 20px;
      }
    }
    .el-form-item__label {
      width: 100px;
    }
  }
}
</style>
