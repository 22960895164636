<template>
	<div class="bill-detail">
		<div class="clearfix mt-50 mb-20">
			<p class="fl font-20 lh-20">账单明细</p>
			<p class="fr font-16 lh-16">合计还款金额：<span class="color-danger">￥{{orderDetail.needPayMoney | fmoney}}</span></p>
		</div>
		<div class="order-list">
			<el-table class="order-table-header mb-20">
				<el-table-column label='商品' width="390" align="center"></el-table-column>
				<el-table-column label='单价' width='80' align="center"></el-table-column>
				<el-table-column label='数量' width='100' align="center"></el-table-column>
				<el-table-column label='订单金额' width="120" align="center"></el-table-column>
				<el-table-column label='订单状态' width="130" align="center"></el-table-column>
				<el-table-column label='状态' align="center"></el-table-column>
			</el-table>
			<div class="table-item mt-20" v-if="Object.keys(orderDetail).length>0">
				<div class="clearfix table-item-top">
					<span class='fl ml-20'>订单编号： {{orderDetail.sn}}</span>
					<span class='fl ml-20 color-9'>下单时间： {{orderDetail.gmtCreate | time}}</span>
				</div>
				<div class="table-item-content bb clearfix" style="display: flex;">
						<!--商品-->
					<div class="clearfix" >
						<div class="clearfix last-no-border" v-for="(item, index) in orderDetail.orderItemVos" :key='index'>
							<div class="fl" style="width: 350px;">
								<img v-if="item.image" :src="item.image" class="fl mr-10" />
								<img v-else src="../../assets/goods.jpg" class="fl mr-10" />
								<p>{{item.skuName}}</p>
								<p class="color-9 mt-20">规格:1*{{item.specInfoNum}}</p>
							</div>
							<div class="fl pbl-20 tac " style="width:100px;">￥{{item.discountPrice | fmoney}}</div>
							<div class="fl pbl-20 tac " style="width:50px;">{{item.num}}</div>
						</div>
						<!--赠品清单-->
						<div class="clearfix last-no-border" v-for="(orderItem, index) in orderDetail.orderGiftResVos" :key="index">
							<div class="fl" style="width: 350px;">
								<img v-if="orderItem.image" :src="orderItem.image" class="fl mr-10" />
								<img v-else src="../../assets/goods.jpg" class="fl mr-10" />
								<p>{{orderItem.skuName}}</p>
								<p class="color-9 mt-20" v-if="orderItem.specInfoNum">规格:1*{{orderItem.specInfoNum}}</p>
							</div>
							<div class="fl pbl-20 tac " style="width:100px;">￥0</div>
							<div class="fl pbl-20 tac " style="width:50px;">{{orderItem.giftNum}}</div>
						</div>
					</div>
					<div class="bl tac pt-20" style="width: 120px;">
						<p>￥{{orderDetail.needPayMoney | fmoney}}</p>
					</div>
					<div class="bl tac pt-20" style="width: 120px;">
						<p v-if="orderDetail.editAuditState == 1 && orderDetail.orderStatus == 1" >待审核</p>
						<div v-else>
							<p v-if=" orderDetail.orderStatus == 1 " >待付款</p>
						</div>
						<p v-if=" orderDetail.orderStatus == 0 " >已关闭</p>
						<p v-if=" orderDetail.orderStatus == 2 " >待发货</p>
						<p v-if=" orderDetail.orderStatus == 3 " >已发货</p>
						<p v-if=" orderDetail.orderStatus == 4 " >已完成</p>
					</div>
					<div class="bl tac pb-10 plr-10 pt-20" style="flex-grow: 1;">
						<span v-if="fwrittenoffstatus === 'C'">已还款</span>
						<span v-if="fwrittenoffstatus === 'B'">部分还款</span>
						<span v-if="fwrittenoffstatus === 'A'">未还款</span>
						<span v-if="fwrittenoffstatus === 'D'">已核销</span>
					</div>
				</div>
			</div>
			<div v-else class="tac">
				<img src="../../assets/empty.png"/>
				<p class="mt-10 color-9">暂无数据</p>
			</div>
		<!-- 还款记录 -->
		<p class="font-20 mt-50 mb-20">还款记录</p>
		<el-table :data="paymentList" :class="paymentList.length === 0?'order-table-header':''" stripe>
			<el-table-column label="还款日期" align="center">
				<template>--</template>
			</el-table-column>
			<el-table-column label="还款金额" align="center">
				<template slot-scope="scope">
					{{scope.row.fwrittenoffamountfor | fmoney}}
				</template>
			</el-table-column>
			<el-table-column label="核销时间" align="center">
				<template slot-scope="scope">
					{{scope.row.fverifydate | timenData}}
				</template>
			</el-table-column>
			<el-table-column label="核销人员" prop="fverifyusername" align="center"></el-table-column>
		</el-table>
		<div v-if="paymentList.length === 0" class="tac mt-20">
			<img src="../../assets/empty.png"/>
			<p class="mt-10 color-9">暂无数据</p>
		</div>
	</div>
</div>
</template>
<script>
	import { axiosApi, basePath } from "../../api/api"
	export default {
		data() {
			return {
				orderDetail: {}, //订单详情
				paymentList: [], //还款记录
				fwrittenoffstatus: null
			}
		},
		created() {
			this.fwrittenoffstatus = this.$route.query.fwrittenoffstatus
			this.init()
		},
		methods: {
			init() {
				this.verification({
						fid: this.$route.query.fid
					})
					.then(res => {
						this.paymentList = res.data.data
					})
					.catch(err => {

					})
				if (this.$route.query.fysorderid) {
					this.orderInfo({
						orderSn: this.$route.query.fysorderid
					})
					.then(res => {
						this.orderDetail = res.data.data
						console.log(this.orderDetail)
					})
					.catch(err => {
						this.$message.warning(err.data.message)
					})
				}
				
			},
			verification(body) { //1.6应收收款核销
				return new Promise((resolve, reject) => {
					axiosApi('/order/verification/list', body, res => {
						resolve(res)
					}, 1, null, err => {
						reject(err)
					})
				})
			},
			orderInfo(body) { //订单详情接口
				return new Promise((resolve, reject) => {
					axiosApi('/anon/order/detail/info', body, res => {
						resolve(res)
					}, 1, 10, err => {
						reject(err)
					})
				})
			}
		}
	};
</script>
<style lang="less">
	.bill-detail {
		width: 940px;
		margin: 0 auto;
		.order-list {
			.order-table-header {
				.el-table__body-wrapper {
					display: none;
				}
			}
		}
		.last-no-border{
			width:550px;
			padding:20px;
			border-bottom: 1px solid #ddd;
		}
		.last-no-border:last-child{
			border-bottom: 0;
		}
		.bb{border-bottom: 1px solid #DDDDDD;}
		.bl{border-left: 1px solid #DDDDDD;}
		.table-item {
				.bl {
					border-left: 1px solid #DDDDDD;
				}
				border: 1px solid #DDDDDD;
				font-size: 14px;
				border-bottom: none;
				.table-item-content .pbl-20 {
					padding: 0px 0px 20px 20px;
				}
				.table-item-top {
					border-bottom: 1px solid #DDDDDD;
					padding: 10px 0;
					.table-item-top-checkbox {
						margin-left: 14px
					}
				}
				img {
					width: 80px;
					height: 80px;
				}
			}
	}
</style>