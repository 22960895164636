<template>
	<div id="visitRecord">
		<div class="title-top bg-f mb-5">
			<div class="clearfix">
				<el-breadcrumb class="fl">
					<el-breadcrumb-item>拜访记录</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
		</div>
		<div class="main bg-f pb-50 pl-30 pr-30">
			<el-form :inline="true" :model="form" label-width="90px" class="clearfix pt-30">
				<div>
					<el-form-item label="拜访时间">
						<ys-daterange-picker v-model="form.timerange" type="date" :endDisabledDate="new Date()" />
					</el-form-item>
					<el-form-item label="名称">
						<el-input placeholder="请输入销售顾问 / 拜访店铺" v-model.trim="form.name" :disabled="isAdmin == 0 && isDepartmentLeader == 0"></el-input>
					</el-form-item>
					<el-form-item label="拜访类别">
						<el-select v-model="form.actionClassifyId" clearable>
							<el-option :label='item.actionClassifyName' :value="item.id" v-for='item in tableList' :key='item.id'></el-option>
						</el-select>
					</el-form-item>
				</div>
				<div>
					<el-button type="info" class="ml-10" @click="search" icon="el-icon-search">搜 索</el-button>
				</div>
			</el-form>
			<div class="clearfix">
				<el-button class="fr mr-30" type="text" @click="download">导出记录</el-button>
			</div>
			<el-table :data="tableData" v-loading="Loading" class='mt-10 table-default'>
				<el-table-column label="销售顾问" min-width="100">
					<template slot-scope="scope">
						<div>{{scope.row.managerName}}</div>
					</template>
				</el-table-column>
				<el-table-column label="签到时间" min-width="115">
					<template slot-scope="scope">
						<div>{{scope.row.startTime | timeI}}</div>
					</template>
				</el-table-column>
				<el-table-column label="签退时间" min-width="115">
					<template slot-scope="scope">

						<div v-if="scope.row.commitTime">{{scope.row.commitTime | timeI}}</div>
						<div v-else>未签退</div>
					</template>
				</el-table-column>
				<el-table-column label="拜访时长" min-width="90">
					<template slot-scope="scope">
						<div v-if="scope.row.visitDuration">{{scope.row.visitDuration}}</div>
						<div v-else>暂无数据</div>
					</template>
				</el-table-column>
				<el-table-column label="拜访店铺" min-width="120">
					<template slot-scope="scope">
						<div>{{scope.row.traderName}}</div>
					</template>
				</el-table-column>
				<el-table-column label="拜访动作" min-width="60">
					<template slot-scope="scope">
						<div>{{scope.row.actionNumScale}}</div>
					</template>
				</el-table-column>
				<el-table-column label="签到位置" min-width="60">
					<template slot-scope="scope">
						<div>{{scope.row.signOnStatus == 0 ? '异常' : '正常'}}</div>
					</template>
				</el-table-column>
				<el-table-column label="签到偏差距离(米)" min-width="80">
					<template slot-scope="scope">
						<div>{{scope.row.signOnLocation}}</div>
					</template>
				</el-table-column>
				<el-table-column label="签退位置" min-width="60">
					<template slot-scope="scope">
						<div>{{scope.row.commitStatus == 0 ? '异常' : '正常'}}</div>
					</template>
				</el-table-column>
				<el-table-column label="签退偏差距离(米)" min-width="80">
					<template slot-scope="scope">
						<div>{{scope.row.commitLocation}}</div>
					</template>
				</el-table-column>
				<el-table-column label="操作" min-width="100">
					<template slot-scope="scope">
						<el-button @click="toDetail(scope.row.id)" type="text" size="small">查看</el-button>
					</template>
				</el-table-column>
			</el-table>
			<!-- 分页组件 -->
			<el-pagination class="tar mt-20" :current-page="currentPage" :page-size='10' :total='total' layout="prev , pager, next, jumper" v-if="total>0" @current-change="handlePageChange"></el-pagination>
		</div>
		<!--导出-->
		<form ref="exportList" action='/sunrise-gateway/export/sales/web/visitSign ' style="display: none">
			<input type="hidden" name="modelName" v-model="c">
			<input type="hidden" name="startRow" v-model="a">
			<input type="hidden" name="startCol" v-model="a">
			<input type="hidden" v-if="exportForm.startTime" name="startTime" v-model="exportForm.startTime">
			<input type="hidden" v-if="exportForm.endTime" name="endTime" v-model="exportForm.endTime">
			<input type="hidden" v-if="exportForm.modelName" name="modelName" v-model="exportForm.modelName">
			<input type="hidden" v-if="exportForm.fuzzyText" name="fuzzyText" v-model="exportForm.fuzzyText">
			<input type="hidden" v-if="exportForm.actionClassifyId" name="actionClassifyId" v-model="exportForm.actionClassifyId">
			<input type="hidden" name="pageSize" v-model="exportForm.pageSize">
			<input type="hidden" name="fileName" v-model="fileName">
		</form>
	</div>
</template>

<script>
	import { axiosApi, basePath } from '../../api/api'
	// import YsDaterangePicker from "~/components/ys-daterange-picker";

	function formatNum(num) {
		return num < 10 ? '0' + num : num
	}
	export default {
		// components: {YsDaterangePicker},
		data() {
			return {
				c:'empty',
				fileName:'拜访记录.xls',
				Loading: false,
				tableData: [],
				form: {
					timerange: [],
					name: '',
					actionClassifyId: null
				},
				total: 0,
				currentPage: 1,
				startTime: '',
				endTime: '',
				managerName: '',
				clearable: true,
				a: 0, //固定值 写死
				exportForm: {}, //导出表单
				tableList: [], //分类列表数据
				isDepartmentLeader:null
			}
		},
		methods: {
			handlePageChange(val) {
				this.Loading = true
				this.currentPage = val
				axiosApi('/sales/visitSign/visitSelectPageList', this.postData, res => {
					this.tableData = res.data.data.elements;
					this.Loading = false
				}, this.currentPage, 10)
			},
			search() { //提交
				this.exportForm = {
					startTime: this.startTime == 'NaN-NaN-NaN' ? '' : this.startTime,
					endTime: this.endTime == 'NaN-NaN-NaN' ? '' : this.endTime,
					modelName: 'empty',
					fuzzyText: this.form.name,
					pageIndex: this.currentPage,
					actionClassifyId:this.form.actionClassifyId,
					pageSize: 10
				};
				this.Loading = true
				axiosApi('/sales/visitSign/visitSelectPageList', this.postData, res => {
					this.tableData = res.data.data.elements;
					this.total = res.data.data.totalCount; //总条目数量
					this.currentPage = res.data.data.pageIndex;
					this.Loading = false
				}, 1, 10)
			},
			toDetail(id) {
				this.$router.push({
					path: '/customer/visitedDetail?id=' + id
				})
			},
			timeFormat(value) {
				var myDate = new Date(value);
				var year = myDate.getFullYear();
				var month = myDate.getMonth() + 1 < 10 ? '0' + (myDate.getMonth() + 1) : myDate.getMonth() + 1;
				var day = myDate.getDate() < 10 ? '0' + myDate.getDate() : myDate.getDate();
				return year + '-' + month + '-' + day;
			},
			download() {
				this.fileName = '拜访记录.xls';
				setTimeout(() => {
					if(this.tableData.length > 0) {
						this.$refs.exportList.submit();
					} else {
						this.$message.warning('暂无数据，无法导出');
					}
				}, 0)
			},
			queryVisitAction() {
				axiosApi('/sales/visitActionClassify/web/selectList', {
					sellerId: sessionStorage.traderId
				}, res => {
					console.log(res, 'res')
					res.data.data.unshift({
						actionClassifyName:'全部类别',
						id:null						
					})
					this.tableList = res.data.data
				})
			},
		},
		activated() {
			this.$nextTick(() => {
				this.Loading = true;
				this.queryVisitAction() //分类数据
				axiosApi('/sales/visitSign/visitSelectPageList', this.postData, res => {
					this.Loading = false;
					this.tableData = res.data.data.elements;
					this.total = res.data.data.totalCount; //总条目数量
					this.currentPage = Number(pageIndex) ? Number(pageIndex) : 1;
					//加载后展示上次的搜索条件
				}, this.currentPage, 10)

			})
		},
		computed: {
			isAdmin() {
				return sessionStorage.getItem('isAdmin')
			},
			postData() {
				this.startTime = Date.parse(this.form.timerange[0])
				this.endTime = Date.parse(this.form.timerange[1])
				this.startTime = this.timeFormat(this.startTime);
				this.endTime = this.timeFormat(this.endTime);
				return {
					startTime: this.startTime == 'NaN-NaN-NaN' ? '' : this.startTime,
					endTime: this.endTime == 'NaN-NaN-NaN' ? '' : this.endTime,
					fuzzyText: this.form.name,
					actionClassifyId:this.form.actionClassifyId
				}
			}
		},
		mounted(){
            this.resolveIsSalesDepartmentLeaderWithCache(res=>{
                console.log(res)
                this.isDepartmentLeader = res
            });
		}
	}
</script>

<style lang="less">
	#visitRecord {
		padding-left: 15px;
		padding-bottom: 50px;
		.border {
			border: 1px solid #EEEEEE;
			background-color: #EEEEEE;
		}
		.inputWidth {
			width: 170px;
		}
	}
</style>