<template>
  <div id="foodAdd" class="bg-f">
    <div class="box-con bdt">
      <!-- title -->
      <div class="clearfix bg-f pt-30">
        <el-form :model="form" label-width="130px" :rules="rules" ref="ruleForm">
          <!-- <div class="">
            <el-form-item label="商品主图:" label-width="80px" prop="mainFileUrl">
              <el-upload
                disabled
                v-loading="loading"
                class="avatar-uploader"
                action="/sunrise-gateway/oss/ossUpload"
                :show-file-list="false"
                :on-change="handleChange"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload1"
              >
                <img v-if="form.mainFileUrl" class="icon-90" :src="form.mainFileUrl" />
                <i v-else class="el-icon-plus avatar-uploader-icon icon-90"></i>
              </el-upload>
            </el-form-item>
          </div>-->
          <div class>
            <el-form-item label="商品名称:" prop="skuName">
              <el-input v-model.trim="form.skuName" style="width: 365px"></el-input>
            </el-form-item>
            <div class="clearfix">
              <div class="fl">
                <el-form-item label="零售价:" prop="maxSellingPrice">
                  <el-input
                    size="mini"
                    @blur="blur1(form.maxSellingPrice)"
                    v-model.trim="form.maxSellingPrice"
                  ></el-input>
                  元/{{unitName}}
                </el-form-item>
                <el-form-item label="促销价:" prop="promotionPrice">
                  <el-input
                    size="mini"
                    @blur="blur2(form.promotionPrice)"
                    v-model.trim="form.promotionPrice"
                  ></el-input>
                  元/{{unitName}}
                </el-form-item>
                <el-form-item label="最低价:" prop="minSellingPrice">
                  <el-input
                    size="mini"
                    @blur="blur3(form.minSellingPrice)"
                    v-model.trim="form.minSellingPrice"
                  ></el-input>
                  元/{{unitName}}
                </el-form-item>
              </div>
            </div>
            <div>
              <el-form-item label="单位:" prop="unitKey">
                <el-select
                  clearable
                  size="mini"
                  v-model="form.unitKey"
                  placeholder="请选择"
                  @change="changeUnit"
                >
                  <el-option
                    v-for="(item,index) in options"
                    :key="index"
                    :label="item.unitName"
                    :value="item.unitKey"
                  ></el-option>
                </el-select>
              </el-form-item>
            </div>
          </div>
        </el-form>
      </div>

      <!-- tab -->
      <div class="mt-10">
        <div>
          <span class="text_title1">基本信息</span>
        </div>
        <hr class="line mt-5" />
      </div>

      <!-- 基本信息 -->
      <div class="pb-30 mt-50" v-show="type==0">
        <el-form :model="form" label-width="130px" :rules="rules" ref="ruleForm1">
          <!-- <el-form-item label="商品图片:" prop="photos">
            <span class="fl" style="height: 111px;overflow:hidden">
              <input type="hidden" v-model="form.fileUrls" />
              <el-upload
                :class="form.fileUrls.length>=6?'fileList2':''"
                action="/sunrise-gateway/oss/ossUpload"
                :file-list="form.fileUrls"
                list-type="picture-card"
                :limit="6"
                :on-preview="handlePictureCardPreview"
                :on-success="handleAvatarPhotoScucess"
                :before-upload="beforeAvatarUpload"
                :on-remove="handleRemove"
              >
                <i class="el-icon-plus"></i>
              </el-upload>
              <el-dialog :visible.sync="dialogVisible" size="tiny">
                <img width="100%" :src="dialogImageUrl" alt />
              </el-dialog>
            </span>
          </el-form-item>-->
          <el-form-item label="图片上传:" class="clearfix" prop="fileUrls">
            <ul class="clearfix fl goodsPic">
              <li class="fl mr-10" v-for="(item,i) in form.fileUrls" :key="i">
                <i @click="preView(i)" class="el-icon-view"></i>
                <img :src="item.url" alt />
                <i @click="delItem(i)" class="el-icon-delete"></i>
              </li>
            </ul>
            <el-upload
              class="fl"
              :class="form.fileUrls.length>=7 ? 'fileList' : ''"
              action="/sunrise-gateway/oss/ossUpload"
              :show-file-list="false"
              multiple
              :file-list="form.fileUrls"
              list-type="picture-card"
              :on-preview="handlePictureCardPreview"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
              :on-remove="handleRa"
              :on-error="errFun"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label>
            <p
              class="color-9"
            >第一张图片将作为商品主图，支持同时上传多张图片。支持jpg、png格式上传，建议使用尺寸800x800像素以上、大小不超过1M的正方形图片。</p>
          </el-form-item>
          <div class="item-form myClassify">
            <el-form-item label="商品分类:" prop="classifyId">
              <el-select
                :clearable="false"
                v-model="form.tenantClassify"
                @change="changeClassify"
                class="fl"
                placeholder="选择商品分类"
              >
                <el-option
                  v-for="(item,index) in typeList"
                  :key="index"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
              <el-select v-model="form.classifyId" class="fl ml-20" placeholder="选择商品分类">
                <el-option
                  v-for="(item,index) in optionsClass"
                  :key="index"
                  :label="item.classifyName"
                  :value="item.classifyId"
                ></el-option>
              </el-select>
            </el-form-item>
          </div>
          <!-- <div class="item-form myClass">
            <el-form-item label="销售员提成:" prop="rewardValue">
              <el-tooltip placement="bottom-start" effect="light" class="fl">
                <div slot="content">
                  销售员提成计算公式:
                  <br />
                  <br />最低价*该设置百分比或者为该设置固定佣金值 + (销售员售价-最低价)
                </div>
                <span class="el_label">
                  <img src="../../../assets/mark.png" alt />
                </span>
              </el-tooltip>
              <div class="item-box">
                <el-input
                  size="narmal"
                  v-model.trim="form.rewardValue"
                  @blur="blur4(form.rewardValue)"
                ></el-input>
                <div class="my-select-box">
                  <el-select
                    v-model="form.rewardType"
                    class="fl"
                    placeholder
                    @change="blur4(form.rewardValue)"
                  >
                    <el-option
                      v-for="(item,index) in rewardTypeList"
                      :key="index"
                      :label="item.name"
                      :value="item.id"
                    ></el-option>
                  </el-select>
                </div>
              </div>
            </el-form-item>
            <el-form-item label="推广员佣金:" prop="salesRewardValue">
              <el-tooltip placement="bottom-start" effect="light" class="fl">
                <div slot="content">
                  推广员佣金计算公式:
                  <br />
                  <br />最低价*该设置百分比或者为该设置固定佣金值
                </div>
                <span class="el_label">
                  <img src="../../../assets/mark.png" alt />
                </span>
              </el-tooltip>
              <div class="item-box">
                <el-input
                  size="narmal"
                  v-model.trim="form.salesRewardValue"
                  @blur="blur5(form.salesRewardValue)"
                ></el-input>
                <div class="my-select-box">
                  <el-select
                    v-model="form.salesRewardType"
                    class="fl"
                    placeholder
                    @change="blur5(form.salesRewardValue)"
                  >
                    <el-option
                      v-for="(item,index) in rewardTypeList"
                      :key="index"
                      :label="item.name"
                      :value="item.id"
                    ></el-option>
                  </el-select>
                </div>
              </div>
            </el-form-item>-->
            <!--  <el-form-item label="推广员佣金:" prop="salesCommission" class="my-el-form-item">
              <el-input
                size="narmal"
                v-model.trim="form.salesCommission"
                @blur="blur5(form.salesCommission)"
              ></el-input>元
            </el-form-item>-->
          <!--  </div> -->
          <div class="item-form my-item">
            <el-form-item label="最低起订量:" prop="minimunMoq">
              <el-input size="mini" v-model.trim="form.minimunMoq"></el-input>
              {{unitName}}
            </el-form-item>
            <div class="sku-item">
              <el-form-item label="规格:" label-width="60px" prop="sellingUnit" required>
                <el-input size="mini" v-model.trim="form.sellingUnit" disabled></el-input>箱
              </el-form-item>
              <el-form-item label-width="10px">X</el-form-item>
              <el-form-item label label-width="10px" prop="specInfoNum">
                <el-input size="mini" v-model.trim="form.specInfoNum"></el-input>
                {{unitName}}
              </el-form-item>
            </div>
          </div>
          <div class="item-form">
            <el-form-item label="运费设置:" prop="freTemplateId">
              <el-select clearable v-model="form.freTemplateId" class="fl" placeholder>
                <el-option
                  v-for="(item,index) in freTemplateList"
                  :key="index"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
              <el-button type="text" class="fl" @click="lookTemplate">查看</el-button>
            </el-form-item>
          </div>
        </el-form>
        <div class="clearfix ml-60">
          <label class="font-14">图文说明:</label>
          <div class="ml-70" style="margin-top:-18px">
            <span>温馨提示:请上传宽为890像素的图片</span>
            <ueditor ref="ueeditor" v-model="form.illustrationText"></ueditor>
          </div>
        </div>
      </div>
      <!-- button -->
      <div class="ml-100 pl-30">
        <el-button type="danger" @click="save(1)">保存</el-button>
        <el-button class="ml-20" @click="save(2)">保存并上架</el-button>
      </div>
    </div>
    <!-- 放大图片 -->
    <el-dialog :visible.sync="dialogVisible" size="tiny">
      <img width="100%" :src="ImageUrl" alt="show-message" />
    </el-dialog>
  </div>
</template>

<script>
import { axiosApi, basePath } from "../../../api/api";
function filterInput(val) {
  return val.replace(/[\ud800-\udbff][\udc00-\udfff]/g, "");
}
export default {
  data() {
    let reg2 = /^[0-9]{1,7}([.]{1}[0-9]{0,2}){0,1}$/;
    let reg3 = /^(\d{1,2}(\.\d{1,2})?|100)$/;
    var unitNums = (rule, value, callback) => {
      if (!value && value !== 0) {
        callback(new Error("请输入销售员提成"));
      } else if (value && this.form.rewardType == 1) {
        if (!reg3.test(value)) {
          callback(new Error("请输入销售员提成"));
        } else {
          callback();
        }
      } else if (value && this.form.rewardType == 2) {
        if (!reg2.test(value)) {
          callback(new Error("请输入销售员提成"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    var unitNumsSale = (rule, value, callback) => {
      if (!value && value !== 0) {
        callback(new Error("请输入推广员提成"));
      } else if (value && this.form.rewardType == 1) {
        if (!reg3.test(value)) {
          callback(new Error("请输入推广员提成"));
        } else {
          callback();
        }
      } else if (value && this.form.rewardType == 2) {
        if (!reg2.test(value)) {
          callback(new Error("请输入推广员提成"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    return {
      dialogVisible: false,
      ImageUrl: null,
      isNeedReset: false,
      isEdit: false,
      datas: [],
      options: [], //单位
      rewardTypeList: [
        { id: 1, name: "%" },
        { id: 2, name: "元" }
      ],
      typeList: [
        { id: 1, name: "名烟名酒店" },
        { id: 2, name: "夫妻老婆店" },
        { id: 3, name: "餐饮" }
      ],
      freTemplateList: [], //运费模板列表
      optionsClass: [], //分类
      fileList: [],
      unitName: "瓶", //单位
      form: {
        rewardType: 1, // %/元
        rewardValue: 0, //销售员佣金
        salesRewardValue: 0, //推广员佣金默认为0
        salesRewardType: 2,
        tenantClassify: 1,
        skuName: null, //商品名称
        maxSellingPrice: null, //零售价
        promotionPrice: null, //促销价
        minSellingPrice: null, //最低售价
        minimunMoq: null, //最低起订量
        unitKey: null,
        sellingUnit: 1, //包装单位
        specInfoNum: null, //包装规格
        skuState: null,
        freTemplateId: null,
        fileUrls: [],
        classifyId: null, //分类Id
        illustrationText: null
      },
      loading: false,
      dialogVisible: false, //预览
      dialogImageUrl: null, //图片显示
      isDisable: false, //控制加号显示隐藏
      type: 0,
      rules: {
        skuName: [
          { required: true, message: "请输入商品名称" },
          { min: 1, max: 30, message: "商品名称最多30个字符" }
        ],
        rewardType: [{ required: true, message: "请选择单位" }],
        salesRewardType: [{ required: true, message: "请选择单位" }],
        classifyId: [{ required: true, message: "请选择分类" }],
        rewardValue: [
          { required: true, validator: unitNums, message: "请输入销售员提成" }
        ],
        minimunMoq: [
          {
            required: true,
            message: "请输入最低起订量",
            pattern: /^[1-9]\d{0,2}$/
          }
        ],
        specInfoNum: [
          {
            required: true,
            message: "请输入正确的包装规格",
            pattern: /^[1-9]\d{0,2}$/
          }
        ],
        freTemplateId: [{ required: false, message: "请选择运费模板" }],
        salesRewardValue: [
          {
            required: true,
            validator: unitNumsSale,
            message: "请输入推广员提成"
          }
        ],
        unitKey: [{ required: true, message: "请选择单位" }],
        fileUrls: [{ required: true, message: "请上传商品主图" }],
        promotionPrice: [
          {
            required: true,
            message: "请输入正确促销价",
            pattern: /^[0-9]{1,7}([.]{1}[0-9]{0,2}){0,1}$/
          }
        ],
        minSellingPrice: [
          {
            required: true,
            message: "请输入正确最低售价",
            pattern: /^[0-9]{1,7}([.]{1}[0-9]{0,2}){0,1}$/
          }
        ],
        maxSellingPrice: [
          {
            required: true,
            message: "请输入正确零售价",
            pattern: /^[0-9]{1,7}([.]{1}[0-9]{0,2}){0,1}$/
          }
        ]
      }
    };
  },
  computed: {},
  created() {
    if (this.$route.query.isEdit == "1") {
      this.isEdit = true;
      let Obj = JSON.parse(localStorage.getItem("goodsInfo"));
      this.getClassifyList(Obj.tenantClassify, "init");
      // let fileList = JSON.parse(localStorage.getItem("goodsFileUrls"));
      this.unitName = Obj.unitName;
      this.form = {
        rewardType: Obj.rewardType, // %/元
        rewardValue: Obj.rewardValue, //销售员佣金
        salesRewardValue: Obj.salesRewardValue, //推广员佣金默认为0
        salesRewardType: Obj.salesRewardType, // %/元
        skuName: Obj.skuName, //商品名称
        tenantClassify: Obj.tenantClassify,
        maxSellingPrice: Obj.maxSellingPrice, //零售价
        promotionPrice: Obj.promotionPrice, //促销价
        minSellingPrice: Obj.minSellingPrice, //最低售价
        minimunMoq: Obj.minimunMoq, //最低起订量
        unitKey: Obj.unitKey,
        sellingUnit: 1, //包装单位
        specInfoNum: Obj.specInfoNum, //包装规格
        skuState: Obj.skuState,
        freTemplateId: Obj.freTemplateId,
        fileUrls: Obj.fileUrls,
        classifyId: Obj.classifyId, //分类Id
        illustrationText: Obj.illustrationText
      };
      console.log(this.form.fileUrls, 480);
      let arr = [];
      this.form.fileUrls.forEach(v => {
        arr.push({
          uid: 1,
          url: v,
          status: "success"
        });
      });
      this.form.fileUrls = arr;
      console.log(this.form.fileUrls, 481);
    } else {
      this.isEdit = false;
    }
  },
  //进入加载
  mounted() {
    this.$nextTick(function() {
      this.$refs.ueeditor.setContent(this.form.illustrationText);
      this.getFreTemplate();
      this.getUnitKeyList();
      if (this.$route.query.isEdit == "1") {
        this.isEdit = true;
        let Obj = JSON.parse(sessionStorage.getItem("goodsInfo"));
        this.getClassifyList(Obj.tenantClassify, "init");
        let fileList = JSON.parse(sessionStorage.getItem("goodsFileUrls"));
        this.unitName = Obj.unitName;
        this.form = {
          rewardType: Obj.rewardType, // %/元
          rewardValue: Obj.rewardValue, //销售员佣金
          salesRewardValue: Obj.salesRewardValue, //推广员佣金默认为0
          salesRewardType: Obj.salesRewardType, // %/元
          skuName: Obj.skuName, //商品名称
          maxSellingPrice: Obj.maxSellingPrice, //零售价
          promotionPrice: Obj.promotionPrice, //促销价
          minSellingPrice: Obj.minSellingPrice, //最低售价
          minimunMoq: Obj.minimunMoq, //最低起订量
          unitKey: Obj.unitKey,
          sellingUnit: 1, //包装单位
          specInfoNum: Obj.specInfoNum, //包装规格
          skuState: Obj.skuState,
          freTemplateId: Obj.freTemplateId,
          fileUrls: Obj.fileUrls,
          classifyId: Obj.classifyId, //分类Id
          tenantClassify: Obj.tenantClassify,
          illustrationText: Obj.illustrationText
        };
        let arr = [];
        this.form.fileUrls.forEach(v => {
          arr.push({
            uid: 1,
            url: v,
            status: "success"
          });
        });
        this.form.fileUrls = arr;
      } else {
        this.isEdit = false;
        this.getClassifyList(1, "init");
      }
    });
  },
  watch: {
    "form.skuName": {
      handler: function(val) {
        this.$nextTick(() => {
          this.form.skuName = filterInput(val);
        });
      }
    }
  },
  methods: {
    changeClassify() {
      this.getClassifyList(this.form.tenantClassify, "change");
    },
    preView(i) {
      this.ImageUrl = this.form.fileUrls[i].url; //门店照片的放大预览
      this.dialogVisible = true;
    },
    delItem(i) {
      this.form.fileUrls.splice(i, 1);
    },
    // 更改单位
    changeUnit(val) {
      let obj = {};
      obj = this.options.find(item => {
        return item.unitKey === val;
      });
      this.unitName = obj.unitName;
    },
    // 获取单位列表
    getUnitKeyList() {
      axiosApi(
        "/superc/tenantShopUnit/list",
        {},
        res => {
          this.options = res.data.data.elements ? res.data.data.elements : [];
          if (this.options.length > 0 && this.$route.query.isEdit != "1") {
            this.form.unitKey = this.options[0].unitKey;
          }
        },
        res => {}
      );
    },
    // 获取分类列表
    getClassifyList(val, type) {
      // type == change时是切换第一级分类
      axiosApi(
        "/superc/tenantShopClassify/list",
        {
          tenantClassify: val
        },
        res => {
          this.optionsClass = res.data.data.elements
            ? res.data.data.elements
            : [];
          if (type == "change") {
            this.form.classifyId = res.data.data.elements
              ? this.optionsClass[0].classifyId
              : null;
          }
        },
        res => {}
      );
    },
    getFreTemplate() {
      axiosApi(
        "/foundation/fare/templateSelectList",
        {
          traderId: sessionStorage.traderId,
          noDetails: true,
          businessType: "superc"
        },
        res => {
          if (res.data.data.elements) {
            this.freTemplateList = res.data.data.elements.map(v => {
              return {
                id: v.id,
                name: v.name,
                isDefault: v.isDefault
              };
            });
          }
        },
        1,
        100,
        err => {
          this.$message.error(err.data.message);
        }
      );
    },
    lookTemplate() {
      //查看运费模板
      if (!this.form.freTemplateId) {
        this.$message({
          type: "error",
          message: "暂无可查看的模板",
          duration: 1000
        });
        return false;
      }

      window.open(
        basePath + "/freightHandle?type=1&id=" + this.form.freTemplateId,
        "_blank"
      );
    },
    blur1(item) {
      if (/^[0-9]{1,7}([.]{1}[0-9]{0,2}){0,1}$/.test(item)) {
        this.form.maxSellingPrice = parseFloat(item).toFixed(2);
        this.form.promotionPrice = this.form.maxSellingPrice;
        this.form.minSellingPrice = this.form.maxSellingPrice;
      }
    },
    blur2(item) {
      if (/^[0-9]{1,7}([.]{1}[0-9]{0,2}){0,1}$/.test(item)) {
        this.form.promotionPrice = parseFloat(item).toFixed(2);
      }
    },
    blur3(item) {
      if (/^[0-9]{1,7}([.]{1}[0-9]{0,2}){0,1}$/.test(item)) {
        this.form.minSellingPrice = parseFloat(item).toFixed(2);
      }
    },
    blur4(item) {
      if (
        /^[0-9]{1,7}([.]{1}[0-9]{0,2}){0,1}$/.test(item) &&
        this.form.rewardType != "1"
      ) {
        this.form.rewardValue = parseFloat(item).toFixed(2);
      }
    },
    blur5(item) {
      if (
        /^[0-9]{1,7}([.]{1}[0-9]{0,2}){0,1}$/.test(item) &&
        this.form.salesRewardType != "1"
      ) {
        this.form.salesRewardValue = parseFloat(item).toFixed(2);
      }
    },
    handlePictureCardPreview(file) {
      //console.log(file, "fangda")
      this.ImageUrl = file.url; //门店照片的放大预览
      //console.log(this.dialogImageUrl)
      this.dialogVisible = true;
    },
    handleAvatarSuccess(res, file, filelist) {
      //照片
      let flag = false;
      let arr = [];
      setTimeout(() => {
        filelist.map((v, i) => {
          if (i < 7) {
            if (v.status !== "success") {
              this.count++;
            }
            arr.push({
              url: v.response ? v.response.data[0] : v.url,
              status: v.status,
              uid: v.uid
            });
          }
        });
        this.form.fileUrls = arr;
        this.loading = false;
      }, 500);
    },
    handleRa(file, fileList) {
      //照片删除
      this.form.fileUrls = fileList;
    },
    beforeAvatarUpload(file) {
      this.loading = true;
      const isImage =
        file.type.indexOf("png") != -1 ||
        file.type.indexOf("jpeg") != -1 ||
        file.type.indexOf("jpg") != -1;
      const imgSize = file.size / 1024 / 1024;
      const isLimit = this.form.fileUrls.length < 7;
      let bool = isImage && imgSize > 0 && imgSize < 1 && isLimit;
      if (!isImage) {
        this.$message.warning("请上传格式为png、jpg的图片");
      } else if (!(imgSize > 0 && imgSize < 1)) {
        this.$message.warning("请上传大小不超过1M的图片");
      }
      if (!bool) this.loading = false;
      return bool;
    },
    errFun(err) {
      console.log(err, "err");
    },
    save(isUp) {
      let arr = [];
      if(this.form.fileUrls.length > 0){
        this.form.fileUrls.forEach(item => {
        arr.push(item.url);
      });
      }else{
        this.$message.error("商品图片不能为空");
      }
      this.form.fileUrls = arr;
      if (/[\ud800-\udbff][\udc00-\udfff]/g.test(this.form.illustrationText)) {
        this.$message.error("图文说明请不要输入表情");
      } else {
        this.$refs.ruleForm.validate(v => {
          if (v) {
            this.$refs.ruleForm1.validate(v1 => {
              if (v1) {
                if(this.form.illustrationText){
                this.form.illustrationText = this.form.illustrationText.replace(
                  /[\ud800-\udbff][\udc00-\udfff]/g,
                  ""
                );
                }
                this.form.skuState = this.form.skuState == "2" ? "2" : isUp;
                if (this.$route.query.isEdit == "1") {
                  this.form = Object.assign(
                    { id: this.$route.query.id },
                    this.form
                  );
                }
                axiosApi(
                  this.isEdit == false
                    ? "/superc/tenantGoods/insert"
                    : "/superc/tenantGoods/update",
                  this.form,
                  res => {
                    this.$message({
                      type: "success",
                      duration: 1000,
                      message: this.isEdit ? "成功编辑商品" : "成功添加商品"
                    });
                    this.$router.push({
                      path: "storeGoodsManage"
                    });
                  },
                  1,
                  10,
                  res => {
                    this.$message.error(res.data.message);
                  }
                );
              }
            });
          }
        });
      }
    }
  }
};
</script>

<style lang="less">
#foodAdd {
  .goodsPic {
    li {
      position: relative;
      border: 1px solid #ccc;
      width: 90px;
      height: 90px;
      img {
        width: 100%;
        height: 100%;
      }
      i {
        cursor: pointer;
        position: absolute;
        top: 40%;
        display: none;
        font-size: 24px;
        color: #fff;
        line-height: 15px !important;
        &:first-child {
          left: 25%;
        }
        &:last-child {
          left: 65%;
        }
      }
      &:hover {
        background-color: #ccc;
        i {
          display: inline-block;
        }
      }
    }
  }
  .el-upload--picture-card {
    width: 90px;
    line-height: 90px;
    height: 90px;
  }

  .el-icon-plus {
    line-height: 90px;
  }

  .el-upload-list--picture-card .el-upload-list__item {
    width: 90px;
    height: 90px;
  }

  .fileList .el-upload--picture-card {
    display: none;
  }
  .avatar-uploader-icon {
    border: 1px solid #ddd;
    width: 90px;
    height: 90px;
    line-height: 90px;
    font-size: 30px;
  }
  .item-form {
    display: flex;
    .my-item {
      width: 500px !important;
      height: 32px;
    }
  }
  .myClassify {
    .el-form-item__error {
      left: 265px !important;
    }
  }
  .my-el-form-item {
    .el-form-item__content {
      display: flex;
    }
  }
  .item-box {
    display: flex;
    justify-content: space-between;
  }
  .my-select-box {
    width: 60px !important;
    height: 32px;
    float: left;
    .el-select {
      width: 60px !important;
      height: 32px;
      .el-input {
        width: 60px !important;
        height: 32px;
      }
    }
  }
  .myClass {
    .el-tooltip {
      margin-right: 10px !important;
    }
    .el-form-item__label {
      width: 100px !important;
    }
    .el-form-item__content {
      margin-left: 105px !important;
    }
  }
  .sku-item {
    width: 322px;
    display: flex;
    justify-content: flex-start;
    .el-form-item__label {
      width: 60px !important;
    }
    /*.el-form-item__content {*/
    /*margin-left: 60px !important;*/
    /*}*/
    .el-input--mini {
      width: 40px !important;
    }
  }
  .ml-80 {
    margin-left: 80px;
  }
  #editor {
    display: block !important;
  }
  .el-upload-list--picture-card .el-upload-list__item {
    width: 90px;
    height: 90px;
  }

  .el-upload--picture-card {
    width: 90px;
    height: 90px;
    line-height: 100px;
  }

  .bdt {
    border-top: 1px solid #eee;
  }

  .box-con {
    padding: 30px 125px 50px 125px;
  }

  .line {
    color: #eeeeee;
  }

  .text_title1 {
    padding: 5px 15px 5px 15px;
    border: 1px solid #dddddd;
  }

  .text_title {
    padding: 5px 15px 5px 15px;
    border: 1px solid #ff4201;
    color: #ff4201;
  }

  .one {
    width: 107px;
    height: 34px;
    line-height: 34px;
    background: #f5f5f5;
    margin-right: 15px;
  }

  .hover:hover {
    width: 105px;
    height: 32px;
    background: rgba(255, 255, 255, 1);
    border-radius: 2px;
    border: 1px solid rgba(255, 66, 1, 1);
    color: #ff4201;
  }

  .actived {
    width: 105px;
    height: 32px;
    background: rgba(255, 255, 255, 1);
    border-radius: 2px;
    border: 1px solid rgba(255, 66, 1, 1);
    color: #ff4201;
  }

  .fileList2 .el-upload--picture-card {
    display: none;
  }
}
</style>
