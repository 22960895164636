<template>
	<div id="eventreport" class="">
		<div class="title-top bg-f mb-5">
			<div class="clearfix">
				<el-breadcrumb class="fl">
					<el-breadcrumb-item>事件报备</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
		</div>
		<div class="main bg-f pb-50 pl-30 pr-30">
			<el-form :inline="true" :model="form" label-width="90px" class="clearfix pt-30">
				<div>
					<el-form-item label="报备时间">
						<ys-daterange-picker v-model="form.timerange" type="datetime" :endDisabledDate="new Date()"/>
					</el-form-item>
					<el-form-item label="部门">
						<el-cascader :props="apartmentProps" expand-trigger="hover" :options="options" v-model="form.apartment" clearable @change="handleChange" change-on-select :disabled="isDepartmentLeader == 0&& isAdmin!=1">
						</el-cascader>
					</el-form-item>
					<el-form-item label="人员">
						<el-input v-model.trim="form.managerName" :disabled="isDepartmentLeader == 0 && isAdmin != 1"></el-input>
					</el-form-item>
					<el-form-item label='状态'>
						<el-select v-model="form.taskStatus" placeholder="请选择" clearable>
							<el-option v-for="item in statuslist" :label="item.label" :value="item.value" :key="item.value">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label='任务类型'>
						<el-select v-model="form.taskTypeId" placeholder="请选择" clearable>
							<el-option v-for="item in eventtypelist" :label="item.taskTypeName" :value="item.id" :key="item.id">
							</el-option>
						</el-select>
					</el-form-item>
				</div>
				<div>
					<el-button type="info" class="ml-10" @click='search' icon="el-icon-search">搜 索</el-button>
				</div>
			</el-form>
			<el-table :data="tableData" v-loading="Loading" class='mt-10 table-default' @selection-change="handleSelectionChange" stripe>
				<el-table-column label="序号" min-width="80">
					<template slot-scope="scope">
						<div>{{scope.$index + pageSize*(currentPage-1)+1}}</div>
					</template>
				</el-table-column>
				<el-table-column label="任务名" min-width="120">
					<template slot-scope="scope">
						<div>{{scope.row.taskName}}</div>
					</template>
				</el-table-column>
				<el-table-column label="人员" min-width="120">
					<template slot-scope="scope">
						<div>{{scope.row.managerName}}</div>
					</template>
				</el-table-column>
				<el-table-column label="任务起止时间" min-width="270">
					<template slot-scope="scope">
						<div>{{scope.row.taskStartEndTime}}</div>
					</template>
				</el-table-column>
				<el-table-column label="完成状态" min-width="100">
					<template slot-scope="scope">
						<div>{{scope.row.taskStatus == 1 ? '进行中' : '已完成'}}</div>
					</template>
				</el-table-column>
				<el-table-column label="操作" min-width="200">
					<template slot-scope="scope">
						<el-button type="text" @click.native.prevent="watchRow(scope.$index, tableData)" size="small">查看</el-button>
					</template>
				</el-table-column>
			</el-table>
			<div class="clearfix">
				<el-form :inline="true" label-width="104px" class="clearfix pt-20 pl-20" style="float:left;">
					<el-form-item label='每页显示条数'>
						<el-select v-model="pageSize" placeholder="请选择" size="mini" @change="changePageSize">
							<el-option v-for="(item, index) in pageSizeList" :label="item" :value="item" :key="index">
							</el-option>
						</el-select>
					</el-form-item>
				</el-form>
				<el-pagination style="float: right;" class="tar mt-20 mr-50" :current-page="currentPage" :page-size='pageSize' :total='total' layout="prev , pager, next, jumper" v-if="total>0" @current-change="handlePageChange">
					<!-- 分页组件 -->
				</el-pagination>
			</div>
			
		</div>
	</div>
</template>
<script>
	import { axiosApi, basePath } from "../../api/api";
	// import YsDaterangePicker from "~/components/ys-daterange-picker";
	export default {
		// components: {YsDaterangePicker},
		data() {
			return {
				pageSize: 10,
				pageSizeList: [5,10,15,20],
				statuslist: [{
						value: 1,
						label: '进行中',
					},
					{
						value: 2,
						label: '已完成',
					}
				],
				options: [],
				eventtypelist: [],
				form: {
					timerange: [],
					apartment: null,
					managerName: '',
					statuslist: '',
					taskTypeId: '',
					taskStatus: '',
				},
				apartmentProps: {
					value: 'id',
					label: 'text',
					children: 'sub'
				},
				currentPage: 1,
				total: '',
				Loading: false,
				tableData: [],
				taskMeetingList: [],
				selectIdNameMap: [],
				isDepartmentLeader:null
			}
		},
		computed: {
			isAdmin(){
				return sessionStorage.getItem('isAdmin')
			},
			postData() {
				return {
					managerName: this.form.managerName ? this.form.managerName : null,
					taskTypeId: this.form.taskTypeId ? this.form.taskTypeId : null,
					taskStatus: this.form.taskStatus ? this.form.taskStatus : null,
					startDate: this.form.timerange[0] ? Date.parse(this.form.timerange[0]) : null,
					endDate: this.form.timerange[1] ? Date.parse(this.form.timerange[1]) : null,
					departmentId: this.form.apartment ? this.form.apartment.slice(-1)[0] : null,
				}
			}
		},
		mounted(){
            this.resolveIsSalesDepartmentLeaderWithCache(res=>{
                console.log(res)
                this.isDepartmentLeader = res
            });
		},
		methods: {
			search() {
				if(this.postData.taskTypeId == null) {
					this.currentPage = 1
					this.querytabledata()
				} else {
					//存储所有查询条件
					sessionStorage.setItem('tastingConditions',JSON.stringify(this.form))
					this.$router.push({ path: basePath + '/tasting', query: { typeId: this.postData.taskTypeId } })
				}
			},
			handleChange(val) {

			},
			handlePageChange(val) {
				this.currentPage = val
				this.querytabledata()
			},
			handleSelectionChange() {

			},
			watchRow(index, data) {
				this.$router.push({ path: basePath + '/tastingDetail', query: { id: data[index].id } })
			},
			querytasktype() {
				axiosApi('/sales/taskType/web/selectList', {

				}, (res) => {
					this.eventtypelist = res.data.data
				})
			},
			queryapartment() {
				axiosApi('/sales/clockTrack/web/departments', {
					traderId: sessionStorage.getItem('traderId')
				}, (res) => {
					this.options = res.data.data
					if(this.isAdmin !=1 && this.isDepartmentLeader == 0) {
						this.form.apartment = sessionStorage.getItem('departmentPath').split(',').map((a)=>{return Number(a)})
					}  
				})
			},
			querytabledata() {
				this.Loading = true
				axiosApi('/sales/task/web/selectListPage', this.postData, (res) => {
					this.Loading = false
					this.tableData = res.data.data.elements
					this.total = res.data.data.totalCount
				}, this.currentPage, this.pageSize)
			},
			//分页改变每页条数
			changePageSize () {
				this.querytabledata()
			}
		},
		activated() {
			if(this.isDepartmentLeader == 0 && this.isAdmin != 1) {
				this.form.managerName = sessionStorage.getItem('employeeName')
			}  
			this.queryapartment()
			this.querytasktype()
			this.querytabledata()
		}
	}
</script>