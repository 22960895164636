<template>
	<div id="groupDetails" class="bg-f" v-loading="loading">
		<div class="plr-45">
			<div class="commHeader mt-50 ml-40 plr-45 clearfix">
				<span>
					<img :src="logo" class="fl mr-20" v-if="logo!=''"/>
					<img src="../../assets/goods.jpg" class="fl mr-20" v-if="logo==''"/>
				</span>
				<div class="font-20 fl mt-5">
					<p>{{Data.skuName}}</p>
					<span class="color-9 mt-10">市场价：{{Data.enterShopPrice}}</span>
				</div>
				<div class="fr mt-20 mr-30" v-if="this.$route.params.rej==1">
					<el-button v-if='showButton("PLATFORM_GOODS_AUDIT",140003)' type="info" @click="rejectVisible=true">驳回申请</el-button>
					<el-button v-if='showButton("PLATFORM_GOODS_AUDIT",140003)' type="danger" @click="handleAudited">审核通过</el-button>
				</div>
			</div>
			<!--驳回申请弹窗-->
			<el-dialog class='cancelOrderDialog' title='输入驳回申请理由' :visible.sync='rejectVisible'>
				<el-form class="pl-30 pr-30" :model="ruForm" ref="rejectForm" :rules="rules">
					<el-form-item label="" prop='rejectionReason'>
						<el-input type='textarea' placeholder='请输入驳回申请的理由...' :autosize="{ minRows: 3 ,maxRows: 10}" v-model.trim='ruForm.rejectionReason'></el-input>
					</el-form-item>
				</el-form>
				<div slot='footer' class="dialog-footer">
					<el-button type='danger' @click="rejectCommit">提 交</el-button>
					<el-button @click="rejectVisible = false">取 消</el-button>
				</div>
			</el-dialog>
			<div class="pb-50">
				<infoGroup :ruForms='Data'></infoGroup>
			</div>
		</div>
	</div>
</template>

<script>
	import { axiosApi, basePath } from '../../api/api';
	export default {
		data() {
			return {
				rules: {
					rejectionReason: [{
							required: true,
							message: '请输入驳回原因！'
						},
						{
							max: 30,
							message: '不能超过30个字！'
						}
					]
				},
				Data: {},
				logo: '',
				rejectVisible: false, //弹出框
				ruForm: {
					id: this.$route.params.skuId,
					auditState: 2, //默认值是审核通过
					sellerId: sessionStorage.traderId,
					rejectionReason: null
				},
				skuNos: [],
				loading:false
			}
		},
		computed: {

		},
		methods: {
			//商品详细信息
			getcommInfo() {
				axiosApi("/unlogin/groupon/bigStoreBaseInfo/select", {
					skuNo: this.$route.params.skuNo,
					goodsType: this.$route.params.goodsType
				}, (res) => {
					this.Data = res.data.data ? res.data.data : {};
					if(this.Data.skuShowPictureVOs.length > 0) this.logo = this.Data.skuShowPictureVOs[0].fileUrl;
				}, 1, 10)
				setTimeout(()=>{
					document.documentElement.scrollTop = document.body.scrollTop =0;
					this.loading = false
				},500)
				
			},
			//审核
			audited() {
				this.skuNos.push(this.$route.params.skuNo)
				const api = "/groupon/grouponStoreAudit/batchUpdate";
				const body = {
					skuNos: this.skuNos,
					auditState: this.ruForm.auditState,
					rejectionReason: this.ruForm.rejectionReason,
					sellerId: sessionStorage.traderId
				};
				axiosApi(api, body, (res) => {
					this.$message({
						type: 'success',
						message: '操作成功!',
						duration: 1000
					});
					this.skuNos = []
					this.$router.push(basePath + "/superGroup/groupAllGoods");
				}, 1, 10, (res) => {
					this.skuNos = []
					this.$message({
						type: 'info',
						message: res.data.message,
						duration: 1000
					});
				})
			},
			//驳回申请理由提交
			rejectCommit() {
				this.$refs.rejectForm.validate((valid) => {
					if(valid) {
						this.ruForm.auditState = 3;
						this.rejectVisible = false
						this.audited();
					} else {
						return false
					}
				})
			},
			//审核通过
			handleAudited() {
				const h = this.$createElement;
				this.ruForm.auditState = 2
				this.$msgbox({
					title: '提示信息',
					message: h('p', {
						style: 'text-align:center'
					}, '是否确认审核通过？'),
					showCancelButton: true,
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					beforeClose: (action, instance, done) => {
						if(action === 'confirm') {
							this.audited();
							done()
						} else {
							done()
						}
						delete instance.$slots.default;
					}
				})
			},
		},
		mounted() {
			this.$nextTick(function() {
				this.loading = true
				this.getcommInfo();
			})
		}
	}
</script>

<style lang="less">
	#groupDetails {
        padding-top:1px;
		.commHeader {
			img {
				width: 90px;
				height: 90px;
			}
		}
		.el-tabs__header {
			padding-left: 0;
		}
		.el-dialog {
			min-height: 300px;
		}
		.bbd {
			position: relative;
		}
		.item1 {
			position: absolute;
			left: 70px;
			top: 6px;
		}
		.item2 {
			position: absolute;
			left: 160px;
			top: 6px;
		}
		.item3 {
			position: absolute;
			left: 245px;
			top: 6px;
		}
		.item4 {
			position: absolute;
			left: 335px;
			top: 6px;
		}
	}
</style>