<template >
    <div id="goods">
        <div class="searchGoods mt-20 mb-20">
            <div class="goods-name mr-30">
                <span>商品名称：</span>
                <el-input placeholder="请输入商品名称" v-model="skuObj.skuName"></el-input>
            </div>
            <div class="goods-type  mr-30">
                <span>商品分类：</span>
                <el-select v-model="skuObj.classifyName" placeholder="请选择" clearable>
                    <el-option v-for='item in classifylist' :key='item.id'
                               :label='item.classifyName'
                               :value='item.classifyName'></el-option>
                </el-select>
            </div>
            <div class="search">
                <el-button type="info" @click="search">查询</el-button>
            </div>
        </div>
        <el-table :data="tableData" tooltip-effect="dark"  class="mt-10 table-default"
                  @selection-change="handleSelectionChange" ref="multipleTable">
            <el-table-column type="selection" width="50" v-if="allGoods">
            </el-table-column>
            <el-table-column label="商品名称" prop='skuName' width="500" >
                <template slot-scope="scope" >
                    <div class="goods-wrap">
                        <div class="ml-10 l-googs" style="position: relative;">

                            <img v-if='scope.row.fileUrl' :src="scope.row.fileUrl" />
                            <img v-else src="../../assets/goods.jpg" />
                        </div>
                        <div class="ml-10 r-goods">
                            <div class="tal w-160 lh-20" ><p :title="scope.row.skuName">{{scope.row.skuName}}</p></div>
                            <div class="tal w-160 lh-20" v-if="scope.row.specInfoNum">规格1*{{scope.row.specInfoNum}}</div>
                            <div class="tal w-160 lh-20">
                                <span style="color:#FF4201;">￥{{scope.row.grouponPrice | fmoney}}</span>
                                <span style="color:#999999;text-decoration-line: line-through;margin-left: 10px;" v-if="scope.row.msrp">{{scope.row.msrp | fmoney}}</span>
                            </div>
                        </div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="商品分类" prop='classifyName'>
            </el-table-column>
            <el-table-column label="商品库存" prop='totalStock'>
            </el-table-column>
            <el-table-column label="销量单位" prop='units'>
            </el-table-column>
            <el-table-column label="操作">
                <template slot-scope="scope">
                    <!--@click.native.prevent="deleteRow(scope.$index, tableData)"-->
                    <el-button v-if="allGoods" type="text" size="small" @click="delGoods(scope.row.id)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <div class="mt-20 clearfix">
            <div class="l-footer fl" v-if="allGoods">
                <el-checkbox v-model="checked" @change="checkAllclient">选中全部</el-checkbox>
                <el-button type="text" @click="delMany">批量删除</el-button>
            </div>
            <el-pagination
                    v-if="willAddGoods.length>0"
                    class="tar mr-50 fr mt-0"
                    :current-page="currentPage"
                    :page-size='10'
                    :total='willAddGoods.length'
                    layout="prev , pager, next, jumper"
                    @current-change="handleit"
            >
                <!-- 分页组件 -->
            </el-pagination>
        </div>
    </div>
</template>

<script>
    import {mapMutations,mapActions} from 'vuex'

    export default {
        props: ['size', 'operate', 'check','allGoods'],
        data() {
            return {
                skuObj:{
                    skuName:null,
                    classifyName: null
                },
                tableData:[],
                searchData:[],//搜索结果
                checked:false,//选中全部
                currentPage: 1,
                willDel:[],//将要删除
                allMultipleSelection: [],//全部数据选中
                isSearch:false,//搜索
            }
        },
        watch: {
            currentPage() {
                this.tableData = this.willAddGoods.slice((this.currentPage-1)*10, (this.currentPage-1)*10+10);
            },
             total() {
                 this.tableData = this.willAddGoods.slice((this.currentPage-1)*10, (this.currentPage-1)*10+10)
             },
            allGoods(){
                this.$set(this.skuObj,'skuName',null);
                this.$set(this.skuObj,'classifyName',null)
            },
            willAddGoods() {
                this.tableData = this.willAddGoods.slice((this.currentPage-1)*10, (this.currentPage-1)*10+10)
            }
        },
        computed: {
            classifylist(){
                return this.$store.state.classifylist.classifylistData
            },
            willAddGoods(){
                return !this.allGoods ? this.$store.state.goodsAdd.goodsList : this.$store.state.goodsAdd.willAddGoods
            },
            total(){
                return !this.allGoods ? this.$store.state.goodsAdd.goodsList.length : this.$store.state.goodsAdd.willAddGoods.length
            }
        },
        created() {
        },
        methods: {
            ...mapActions({
                'getGoodsListActivity':'getGoodsListActivity'
            }),
            ...mapMutations({
                'changeLoading':'changeLoading'
            }),
            search(){
                if(this.skuObj.skuName || this.skuObj.classifyName){
                    this.changeLoading({goodsLoading:true})
                    this.isSearch = true;
                    let arr = [];//接收过滤后的数据
                    this.willAddGoods.map(v => {
                        if (this.skuObj.classifyName && this.skuObj.skuName) {
                            if (v.classifyName == this.skuObj.classifyName && v.skuName.indexOf(this.skuObj.skuName) != -1) {
                                v.quantity = 1;
                                arr.push(v)
                                // return v;
                            }
                        } else if (this.skuObj.classifyName && !this.skuObj.skuName) {
                            if(v.classifyName == this.skuObj.classifyName){
                                v.quantity = 1;
                                arr.push(v)
                                // return v;
                            }
                        } else if (!this.skuObj.classifyName && this.skuObj.skuName) {
                            if(v.skuName.indexOf(this.skuObj.skuName) != -1){
                                v.quantity = 1;
                                arr.push(v)
                                // return v;
                            }
                        }
                    });
                    this.changeLoading({goodsLoading:false});
                    this.tableData =[...arr];
                }else {
                    this.isSearch = false;
                    this.tableData = this.willAddGoods;
                }
            },
            //全部选中
            checkAllclient(e){
                if(e){
                    let _this = this;
                    setTimeout(() => {
                        this.allMultipleSelection =  [...this.willAddGoods];
                        this.$refs.multipleTable.clearSelection();
                        this.allMultipleSelection.forEach(row => {
                            _this.$refs.multipleTable.toggleRowSelection(row)
                        })

                    },0)
                }else{
                    setTimeout(() => {
                        this.allMultipleSelection = []
                        this.$refs.multipleTable.clearSelection()
                    }, 0);
                }
            },
            //批量删除
            delMany(){
                if(this.willDel.length<=0){
                    this.$message.warning('请选择要删除的商品');
                    return
                }
                this.delGoods(this.willDel)
            },
            handleit(val) {
                this.currentPage = val;
                setTimeout(() => {
                    if(this.allMultipleSelection.length>0){// 有选中的数据
                        this.allMultipleSelection.forEach(row => {
                            this.$refs.multipleTable.toggleRowSelection(row)
                        })
                    }else{// 不存在选中数据
                        this.$refs.multipleTable.clearSelection()
                    }
                }, 0)

            },
            handleSelectionChange(val) {
                this.willDel = val.map(v => {
                    return v.id
                });
                console.log(val)
                if(val.length>0){
                    if(val.length<this.willAddGoods.length){
                        this.checked = false
                    }else {
                        this.checked = true
                    }
                }else {
                    this.checked = false
                }
            },
            delGoods(ids) {
                let delIds = typeof ids == 'number' ? [ids] : ids;

                this.$confirm('确定要删除该商品吗?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                	if(delIds.length > 1){
                		this.currentPage = 1
                	}
                    this.$set(this.skuObj,'skuName',null);
                    this.$set(this.skuObj,'classifyName',null);
                    this.$store.commit('delGoods',{
                        delIds:delIds
                    })
                }).catch()
            }
        },
        mounted() {
            // this.$nextTick(()=>{
            //     this.getGoodsListActivity({})
            //     // this.clearwilladd()
            //     // this.clearwilldelete()
            // })
        }
    }
</script>

<style lang="less">
    #goods{
        .footer{
            display: flex;
            /*justify-content: space-between;*/
            align-items: center;
        }
        .searchGoods{
            display: flex;
        }
    }
</style>
