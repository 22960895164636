<template>
	<div id="nameverify">
		<p><i class="iconfont font-16 icon-inspector color-b"></i><span class="font-16 ml-10 color-9 lh-16">温馨提示:</span></p>
		<p class="mt-10 ml26 color-9 font-16 lh-16">实名认证信息必须为自己的真实信息，一旦认证，不可更改！</p>
		<el-form label-width="186px" :model="form" ref="form" :rules="rules" class="mt-50">
			<el-form-item label="真实姓名:" prop="name">
				<el-input v-model.trim="form.name" placeholder="请输入姓名" :maxlength="32"></el-input><span class="color-9 ml-10">为确保您的账户安全，请填写您本人的实名认证信息</span>
			</el-form-item>
			<el-form-item label="身份证号:" prop="id">
				<el-input v-model.trim="form.id" placeholder="请输入身份证号" :maxlength="18"></el-input>
			</el-form-item>
			<el-form-item label="银行卡号:" prop="card">
				<el-input v-model.trim="form.card" placeholder="请输入银行卡号"></el-input><span class="color-9 ml-10">仅支持您本人名下的银行卡</span>
			</el-form-item>
			<el-form-item label="手机号:" prop="phone">
				<el-input v-model.trim="form.phone" placeholder="请输入手机号" :maxlength="11"></el-input><span class="color-9 ml-10">请填写该卡在银行预留的手机号码</span>
			</el-form-item>
			<el-form-item label="短信验证码:" prop="code">
				<el-input v-model.trim="form.code" style="width:120px" @change="clearmsg"></el-input>
				<el-button class="ml-20 wa" @click="sendnum({type:3,mobile:form.phone})" :disabled="disabled">获取验证码<span v-if="timeb">{{time | seconds}}</span></el-button>
				<p v-if="errormsg.length>0" class="color-danger lh-14 mt-5">{{errormsg}}</p>
			</el-form-item>
			<!--<el-form-item>
				<el-checkbox v-model="disabled"></el-checkbox>
				已阅读
				<span class="color-3 hover-danger ml-6 color-blue cursor">《商源平台服务协议》</span>
			</el-form-item>-->
			<el-form-item class="mt-50">
				<el-button type="danger" @click="submit">确定</el-button>
			</el-form-item>
		</el-form>
	</div>
</template>

<script>
	import { axiosApi, basePath } from "~/api/api"
	import { mapActions, mapMutations } from "vuex"
    import { MessageBox } from 'element-ui'
	export default {
		data() {
			return {
				form: {
					phone: ""
				},
				rules: {
					name: [{
						required: true,
						message: '请输入姓名',
					},{
						pattern: /^([A-Za-z]|[\u4E00-\u9FA5]|[\·]){2,32}$/,
						message:"请输入您的真实姓名"
					}],
					id: [{
						required: true,
						message: '请输入身份证号',
					},{
						pattern: /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/,
						message:"请输入正确的身份证号"
					}],
					card: [{
						required: true,
						message: '请输入正确的银行卡号',
					}],
					phone: [{
						required: true,
						pattern: /^1\d{10}$/,
						message: '请输入银行预留手机号',
					},{
						pattern: /^1\d{10}$/,
						message: '请输入正确的手机号',
					}],
					code: [{
						required: true,
						pattern: /^\d{6}$/,
						message: '请输入6位验证码',
					}]
				}
			}
		},
		computed: {
			errormsg() {
				return this.$store.state.paymodule.errormsg
			},
			time() {
				return this.$store.state.paymodule.time
			},
			timeb() {
				return this.$store.state.paymodule.timeb
			},
			disabled() {
				return this.$store.state.paymodule.disabled
			}
        },
        beforeRouteEnter(to,form,next){
            let isAdmin = sessionStorage.getItem('isAdmin') == 1;
            if(!isAdmin) {
                MessageBox.alert('暂不支持对员工帐号开通此操作权限', '提示信息', {
                    confirmButtonText: '确定'
                })
                next(false)
            }else{
                next()
            }
        },
		watch: {
			"form.phone": {
				handler: function(a) {
					let reg = /^1\d{10}$/
					this.changedisabled(!reg.test(a))
				},
				deep: true
			}
		},
		mounted(){
			this.clearmsg()
			this.cleartime()
		},
		methods: {
			...mapActions({
				"bindCard": "bindCard",
				"sendnum": "sendnum"
			}),
			...mapMutations({
				"clearmsg": "clearmsg",
				"changedisabled": "changedisabled",
				"cleartime":"cleartime"
			}),
			submit() {
				this.$refs.form.validate(valid => {
					if(valid) {
						console.log("ok")
						this.bindCard({
							realName: this.form.name,
							idCard: this.form.id,
							bankCardNo: this.form.card,
							bankCardMobile: this.form.phone,
							smsCode: this.form.code,
							err:0
						})
					} else {
						console.log("fail")
					}
				})
			}
		}
	}
</script>

<style lang="less">
	#nameverify {
		padding: 30px 0px 30px 155px;
		.ml26 {
			margin-left: 26px;
		}
		.ml-6 {
			margin-left: -6px;
		}
		.el-form-item__label {
			padding-right: 20px;
		}
		.wa {
			width: auto;
			padding: 0 5px;
		}
	}
</style>