<template>
    <div id="superBGoods">
        <!-- head -->
        <div class="title-top bg-f mb-5">
            <div class="clearfix black">
                <el-breadcrumb class="fl ">
                    <el-breadcrumb-item>商品管理</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
        </div>

        <!-- class -->
        <div class='bg-f'>
            <div class="ml-30 mr-30 second-title clearfix">
                <div class="choose-three font-14 color-6 fl tac mr-10 cursor " :class="first == 0?'actived':''"
                     @click="getStatus(0)">
                    全部商品
                </div>
                <div class="choose-three font-14 color-6 tac fl mr-10 cursor"
                     :class="first == 1?'actived':''" @click="getStatus(1)">
                    异常商品
                    <span class="ml-5 color-danger" v-show="message">
                                ●
                            </span>
                </div>
            </div>
        </div>
        <!-- body -->
        <div class="bg-f">

            <!-- search -->
            <el-form ref="keyword" :inline="true" :model="keyword" label-width="90px"
                     class="clearfix pl-20 pt-30">
                <div class="fl width-70p">
                    <el-form-item label="商品编码">
                        <el-input v-model.trim="keyword.skuNo" placeholder="请输入商品编码"@input="keyword.skuNo=keyword.skuNo.replace(/[\u4e00-\u9fa5]/ig,'')"></el-input>
                    </el-form-item>
                    <el-form-item label="商品名称">
                        <el-input :maxlength="20" v-model.trim="keyword.skuName"
                                  placeholder="请输入商品名称"></el-input>
                    </el-form-item>
                    <el-form-item label="商品分类">
                        <el-select v-model="keyword.skuClassifyId" clearable placeholder="请选择分类">
                            <el-option :label='item.classifyName' :value="item.id" v-for='item in searchClassify'
                                       :key='item.id'></el-option>
                        </el-select>
                    </el-form-item>
                </div>
                <el-button type="info" class="fl ml-50" @click='getList'>搜索</el-button>
            </el-form>


            <!-- button -->
            <div class='tar clearfix mr-30'>
                <el-button type="text" class="fr" @click="checkTable">查看已生成报表</el-button>
                <el-button type="text" class="fr" @click="exportExcel">全部导出</el-button>
            </div>

            <!-- list -->
            <div class="pl-30 pr-30 pb-30">
                <el-table :data="tableData" v-loading="Loading" class='mt-10 table-default' stripe>
                    <el-table-column prop="1" label="商品名称" min-width="310">
                        <template slot-scope="scope">
                            <div class="info flex clearfix ">
                                <div class="ml-10">
                                    <img v-if='scope.row.fileUrl' :src="scope.row.fileUrl"/>
                                    <img v-else src="../../assets/goods.jpg"/>
                                </div>
                                <div class="flex-1 ml-10 mt-10 hidden">
                                    <div class="tal">商品编码：{{scope.row.skuNo}}</div>
                                    <div class="tal">{{scope.row.skuName}}</div>
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="规格" min-width="60">
                        <template slot-scope="scope">
                            <div>1*{{scope.row.specInfoNum}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column label="分类" prop="classifyName" min-width="60"></el-table-column>
                    <el-table-column label="单位" prop='units' min-width="60"></el-table-column>
                    <el-table-column label="佣金包总额" min-width="120">
                        <template slot-scope="scope">
                            <div v-if="scope.row.minCommission == scope.row.maxCommission">￥{{scope.row.minCommission +
                                scope.row.subsidyOne + scope.row.subsidyTwo | fmoney}}
                            </div>
                            <div v-else>￥{{scope.row.minCommission + scope.row.subsidyOne + scope.row.subsidyTwo |
                                fmoney}}
                                -￥{{scope.row.maxCommission + scope.row.subsidyOne + scope.row.subsidyTwo| fmoney}}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="佣金" min-width="120">
                        <template slot-scope="scope">
                            <div v-if="scope.row.minCommission==scope.row.maxCommission">￥{{scope.row.minCommission |
                                fmoney}}
                            </div>
                            <div v-else>￥{{scope.row.minCommission |
                                fmoney}}-￥{{scope.row.maxCommission | fmoney}}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="一级补贴" min-width="120">
                        <template slot-scope="scope">
                            <div>￥{{scope.row.subsidyOne | fmoney}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column label="二级补贴" min-width="120">
                        <template slot-scope="scope">
                            <div>￥{{scope.row.subsidyTwo | fmoney}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column label="利润" v-if="first==1" min-width="100">
                        <template slot-scope="scope">
                            <div :class="scope.row.minSalesPrice <= 0?'red':''"
                                 v-if="scope.row.minSalesPrice==scope.row.maxSalesPrice">￥{{scope.row.minSalesPrice |
                                fmoney}}
                            </div>
                            <div :class="scope.row.minSalesPrice <= 0?'red':''" v-else>￥{{scope.row.minSalesPrice |
                                fmoney}}-￥{{scope.row.maxSalesPrice | fmoney}}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" min-width="80">
                        <template slot-scope="scope">
                            <el-button class='ml-0 tac' size="small" type='text' @click="getItem(scope.row.skuNo)">编辑
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>

                <!-- index -->
                <el-pagination class="tar mt-20" v-if="agencyTotal>0" :current-page="agencyProPage" :page-size='10'
                               :total='agencyTotal'
                               layout="prev , pager, next, jumper" @current-change="handleCurrentChange">
                </el-pagination>
            </div>

        </div>


        <!-- dialog -->
        <el-dialog title="编辑" :visible.sync='dialogShow'>

            <div class="pl-50 mb-10 pb-20" style="border-bottom: 1px solid #EEEEEE;">

                <div class=" flex clearfix">
                    <div>
                        <img class="img" v-if='goods.fileUrl' :src="goods.fileUrl"/>
                        <img class="img" v-else src="../../assets/goods.jpg"/>
                    </div>
                    <div class="ml-10 hidden">
                        <div class="tal">{{goods.skuName}}</div>
                        <div class="tal mt-20">市场价：￥{{goods.enterShopPrice | fmoney}}/{{goods.units}}</div>
                    </div>
                </div>

                <div class="mt-20">
                    <div class="flex clearfix">
                        <div style="width: 200px">销售价：
                            <span v-if="goods.minSalesPrice==goods.maxSalesPrice">￥{{goods.minSalesPrice | fmoney}}/{{goods.units}}
                            </span>
                            <span v-else>￥{{goods.minSalesPrice | fmoney}}-￥{{goods.maxSalesPrice | fmoney}}/{{goods.units}}
                            </span>
                        </div>
                        <div style="width: 200px">建议零售价： ￥{{goods.msrp | fmoney}}/{{goods.units}}</div>
                        <div style="width: 200px">团购价： ￥{{goods.minimumGorupbuyyingPrice | fmoney}}/{{goods.units}}
                        </div>
                    </div>
                    <div class=" flex clearfix mt-10">
                        <div style="width: 200px">计量单位：{{goods.units}}</div>
                        <div style="width: 200px">最低起订量：
                            <span>{{goods.minimunMoq}}{{goods.units}}</span></div>
                    </div>

                </div>
            </div>
            <div class="pl-50 mt-20">
                <el-form :model="goods" ref="ruleForm" :rules='rules'>
                    <div>
                        佣金包总额：￥{{form.minCommissionPack| fmoney}}
                        <span v-if="this.secradio==2&&form.minCommissionPack!=form.maxCommissionPack"> -￥{{form.maxCommissionPack| fmoney}}/{{goods.units}}</span>
                    </div>
                    <div class="mt-10">
                        <el-radio-group v-model="secradio" @change="hanglesecradioChange">
                            <div class="block">
                                <el-radio class="radio" label="1">
                                    <span>按固定值</span>
                                    <sy-input v-model="form.fixedValue" type="float" size="mini"
                                              :disabled="secradio == 2" :min="0.00" :max="9999.99"></sy-input>
                                    <span>元/{{goods.units}}</span>
                                    <span class='redalert'
                                          v-if="(form.fixedValue<0||form.fixedValue>goods.minSalesPrice||form.fixedValue==goods.minSalesPrice) && secradio == 1">超出合理范围，请核查</span>
                                </el-radio>
                            </div>
                            <div class="block mt-10">
                                <el-radio class="radio" label="2">
                                    <span>按比例</span>
                                    <sy-input v-model="form.precent" type="int" size="mini" :disabled="secradio == 1"
                                              :min="0" :max="100"></sy-input>
                                    <el-tooltip placement="bottom-start" effect="light">
                                        <div slot="content">佣金包总额=销售价*比例<br/>
                                        </div>
                                        <span class='el_label'>%<img src="../../assets/mark.png" alt=""/></span>
                                    </el-tooltip>
                                    <span class='redalert'
                                          v-if="form.precent ==100 && secradio == 2">超出合理范围，请核查</span>
                                </el-radio>
                            </div>
                        </el-radio-group>
                    </div>
                    <div class="mt-20">
                        <el-tooltip placement="bottom-start" effect="light">
                            <div slot="content">
                                <div class="ml-30">
                                    <div class="fl">
                                        超级B1 ==> &nbsp&nbsp&nbsp 超B2 ==> &nbsp&nbsp&nbsp 超B3 ==> &nbsp&nbsp&nbsp 终端下单
                                    </div>
                                    <br/>
                                    <div class="fl mt-10">
                                        一级补贴 &nbsp--&nbsp 二级补贴 &nbsp--&nbsp 商品佣金
                                    </div>
                                    <br/>
                                </div>

                                <div>
                                    <hr class="ml-20 mr-20  mt-10 ">
                                    <br/>超B1发展下游超B2，超B2发展下游超B3，超B3的客户下单，超B3直接获取对应的商品佣金，<br/> 而超B1和超B2分别获取对应的一级补贴和二级补贴。
                                </div>
                            </div>
                            <span class='el_label'>上游补贴设置<img src="../../assets/mark.png" alt=""/></span>
                        </el-tooltip>
                    </div>

                    <div class="clearfix mt-10">

                        <div class="fl" style="width: 280px">
                            <el-form-item label="一级补贴:" prop="" required>
                                <sy-input size="mini" type="float" :min="0.00" :max="9999.99"
                                          v-model.trim="form.subsidyOne" palceholder="输入金额"
                                          class=""></sy-input>
                                元/{{goods.units}}
                            </el-form-item>
                        </div>

                        <div class="fl" style="width: 280px">
                            <el-form-item label="二级补贴:" prop="" required>
                                <sy-input size="mini" type="float" :min="0.00" :max="9999.99"
                                          v-model.trim="form.subsidyTwo" palceholder="输入金额"
                                          class=""></sy-input>
                                元/{{goods.units}}
                            </el-form-item>
                        </div>

                    </div>
                    <div class="mt-20">
                        <div>
                            超级B佣金：￥{{form.minCommission| fmoney}}
                            <span v-if="this.secradio==2&&form.minCommission!=form.maxCommission"> -￥{{form.maxCommission| fmoney}}/{{goods.units}}</span>
                            <span class='redalert' v-if="form.minCommission<0">超级B佣金不允许为负数，请重新设置</span>
                        </div>
                        <div class="mt-10">超级B佣金 = 佣金包总额 - 上游补贴（一级补贴 + 二级补贴）</div>
                    </div>
                </el-form>
            </div>


            <!-- button -->
            <div slot='footer' class="dialog-footer">
                <el-button type='danger' @click="save" :disabled="!buttonEnable">确 定</el-button>
                <el-button @click="dialogShow = false">取 消</el-button>
            </div>
        </el-dialog>


        <!--筛选条件导出-->
        <form ref="downClientForm2" action='/sunrise-gateway/export/superb/commission/skuList' style="display: none">
            <input type="hidden" name="modelName" v-model="a">
            <input type="hidden" name="fileName" v-model="b">
            <input v-if="keyword.skuNo" type="hidden" name="skuNo" v-model="keyword.skuNo">
            <input v-if="keyword.skuName" type="hidden" name="skuName" v-model="keyword.skuName">
            <input v-if="keyword.skuClassifyId" type="hidden" name="skuClassifyId" v-model="keyword.skuClassifyId">
        </form>

    </div>

</template>

<script>
    import {axiosApi, basePath} from "../../api/api";
    import {mapMutations} from "vuex";

    export default {
        name: "superBGoods",
        data() {
            return {
                buttonEnable: false,
                dialogShow: false,
                searchClassify: [], //搜索分类
                Loading: false,
                keyword: {
                    skuNo: '',//商品编码
                    skuName: '',//商品名称
                    skuClassifyId: '',//商品分类
                },
                exportBody: {},
                goods: {},
                secradio: '1',
                a: 'superb.commission.skuList',
                b: '商品列表.xls',
                form: {
                    minCommissionPack: 0,
                    maxCommissionPack: 0,
                    type: null,
                    precent: 0,
                    fixedValue: 0,
                    minCommission: 0,
                    maxCommission: 0,
                    minSalesPrice: 0,
                    maxSalesPrice: 0,
                    subsidyOne: 0,
                    subsidyTwo: 0,
                    skuNo: null,
                },
                type: null,
                agencyProPage: 1,
                agencyTotal: 0,
                tableData: [],//商品来源
                first: 0,
                rules: {},
                message: false,
            }
        },
        watch: {
            secradio: function (val) {
                if (val == 1) {
                    this.form.minCommissionPack = this.form.fixedValue;
                    this.form.maxCommissionPack = this.form.fixedValue;
                } else {
                    this.form.minCommissionPack = this.form.minSalesPrice * this.form.precent / 100;
                    this.form.maxCommissionPack = this.form.maxSalesPrice * this.form.precent / 100;
                }
            },

            'form.precent': function (val) {
                console.log("this.secradio", this.secradio)
                console.log("val", val)
                if (this.secradio == 2) {
                    this.form.minCommissionPack = this.form.minSalesPrice * val / 100;
                    this.form.maxCommissionPack = this.form.maxSalesPrice * val / 100;
                }
            },

            'form.fixedValue': function (val) {
                if (this.secradio == 1) {
                    this.form.minCommissionPack = val;
                    this.form.maxCommissionPack = val;
                }
            },
            'form.minCommissionPack': function (val) {
                this.form.minCommission = val - this.form.subsidyOne - this.form.subsidyTwo;
                this.setButtonEnable();
            },
            'form.maxCommissionPack': function (val) {
                this.form.maxCommission = val - this.form.subsidyOne - this.form.subsidyTwo;
            },
            'form.subsidyOne': function (val) {
                this.form.minCommission = this.form.minCommissionPack - val - this.form.subsidyTwo;
                this.form.maxCommission = this.form.maxCommissionPack - val - this.form.subsidyTwo;
                this.setButtonEnable();
            },
            'form.subsidyTwo': function (val) {
                this.form.minCommission = this.form.minCommissionPack - this.form.subsidyOne - val;
                this.form.maxCommission = this.form.maxCommissionPack - this.form.subsidyOne - val;
                this.setButtonEnable();
            },
        },
        methods: {
            ...mapMutations({
                setTask: "setTask"
            }),
            //搜索
            getList() {
                this.Loading = true;
                this.exportBody = {
                    type: this.type,
                    skuNo: this.keyword.skuNo || null,
                    skuName: this.keyword.skuName || null,
                    classifyId: this.keyword.skuClassifyId || null,
                    sellerId: sessionStorage.traderId,
                };

                let url = '/superb/commission/skulist'
                axiosApi(url, this.exportBody, res => {
                    this.Loading = false;
                    this.agencyTotal = res.data.data.totalCount;
                    this.tableData = res.data.data.elements;
                }, this.agencyProPage, 10, err => {
                })
            },
            //搜索
            getMessage() {
                let body = {
                    type: 'exception',
                    sellerId: sessionStorage.traderId,
                };
                let url = '/superb/commission/skulist'
                axiosApi(url, body, res => {
                    this.message = res.data.data.totalCount > 0 ? true : false;
                }, this.agencyProPage, 10, err => {
                })
            },
            exportExcel() {
                if (this.agencyTotal == 0) {
                    this.$message({
                        type: 'error',
                        message: '暂无数据可导出',
                        duration: 1000
                    });
                    return false
                }

                axiosApi(
                    "/datacenter/exportTask/addSuperbSkuList",
                    this.exportBody,
                    res => {
                        this.$notify({
                            title: "成功",
                            message:
                                "导出的文件正在生成，请不要重复点击，文件生成后，可以在“查看已生成报表”中查看!",
                            type: "success"
                        });
                    },
                    1,
                    10,
                    err => {
                        this.$message.error(err.data.message);
                    }
                );
            },
            //查看已生成报表
            checkTable() {
                let taskType = "superb_commission_sku";
                this.setTask({
                    url: "/datacenter/exportTask/listExportTask",
                    businessType: "superb",
                    taskType: taskType
                });
                this.$router.push(basePath + "/statement");
            },
            getItem(skuNo) {
                this.Loading = true;
                let body = {
                    skuNo: skuNo,
                    priceUnit: 1
                };
                let url = '/superb/commission/skuInfo'
                axiosApi(url, body, res => {
                    this.Loading = false;
                    if (res.data.data.isIntegerMultiple && res.data.data.priceUnit != 1) {
                        res.data.data.enterShopPrice = res.data.data.enterShopPrice / res.data.data.specInfoNum;
                        res.data.data.msrp = res.data.data.msrp / res.data.data.specInfoNum;
                        res.data.data.minimumGorupbuyyingPrice = res.data.data.minimumGorupbuyyingPrice / res.data.data.specInfoNum;
                    }
                    this.goods = res.data.data;

                    this.setForm(this.goods);
                    this.dialogShow = true;
                    console.log(this.dialogShow,'???')
                }, this.agencyProPage, 10, err => {
                })
            },
            //分页
            handleCurrentChange(val) {
                this.agencyProPage = val;
                this.getList();
                this.getMessage();
            },
            getStatus(e) {
                this.first = e;
                this.setStatus(e);
                this.agencyProPage = 1;
                this.getList();
                this.getMessage();
            },
            setStatus(e) {
                this.keyword.skuNo = null;
                this.keyword.skuClassifyId = null;
                this.keyword.skuName = null;
                switch (e) {
                    case 0:
                        this.type = null;
                        break;
                    case 1:
                        this.type = 'exception';
                        break;
                }
            },
            hanglesecradioChange(val) {
                if (val == 1) {
                    this.form.precent = 0;
                    this.form.type = 'fixedValue'
                } else {
                    this.form.fixedValue = 0;
                    this.form.type = 'precent'
                }
            },
            //查看商品
            showItem(item, flag) {
                this.$router.push({
                    path: basePath + '/proDetail',
                    query: {
                        id: flag == 0 ? item.id : item.dealerGoodsId,
                        flag: 'dealerDetail'
                    }
                })
            },
            setForm(goods) {
                this.form.minSalesPrice = goods.minSalesPrice ? goods.minSalesPrice : 0.00;
                this.form.maxSalesPrice = goods.maxSalesPrice ? goods.maxSalesPrice : 0.00;
                this.form.minCommission = goods.minCommission ? goods.minCommission : 0.00;
                this.form.maxCommission = goods.maxCommission ? goods.maxCommission : 0.00;
                this.secradio = (goods.commissionType == null || goods.commissionType == 'fixedValue') ? '1' : '2';
                this.form.type = (goods.commissionType == null || goods.commissionType == 'fixedValue') ? 'fixedValue' : 'precent';
                this.form.precent = goods.precent ? goods.precent : 0;
                this.form.fixedValue = goods.fixedValue ? goods.fixedValue : 0.00;
                this.form.subsidyOne = goods.subsidyOne ? goods.subsidyOne : 0.00;
                this.form.subsidyTwo = goods.subsidyTwo ? goods.subsidyTwo : 0.00;
                this.form.skuNo = goods.skuNo;
                if (this.secradio == 1) {
                    this.form.minCommissionPack = this.form.fixedValue;
                    this.form.maxCommissionPack = this.form.fixedValue;
                } else {
                    this.form.minCommissionPack = this.form.minSalesPrice * this.form.precent / 100;
                    this.form.maxCommissionPack = this.form.maxSalesPrice * this.form.precent / 100;
                }
            },
            save() {
                let body = this.form;
                let url = '/superb/commission/skuSave';
                axiosApi(url, body, res => {
                    this.dialogShow = false;
                    this.getList();
                    this.getMessage();
                }, this.agencyProPage, 10, err => {
                    this.$message.error(err.data.message)
                })
            },
            setButtonEnable() {
                if (this.form.minCommissionPack < 0 ||
                    this.form.minCommission < 0 ||
                    this.form.minCommissionPack >= this.goods.minSalesPrice
                ) {
                    this.buttonEnable = false;
                } else {
                    this.buttonEnable = true;
                }
            },


            //查询分类
            classSearch() {
                const api = "/goods/classifyTopList/select";
                const body = {
                    classificationId: ''
                };
                axiosApi(api, body, this.classsearch)
            },
            classsearch(res) {
                this.searchClassify = res.data.data;
            },
        },
        mounted() {
            this.$nextTick(() => {
                this.getList();
                this.getMessage();
                this.classSearch();
            })
        }
    }
</script>

<style lang="less">
    #superBGoods {

        .red {
            color: #FF4201;
        }

        .second-title {
            height: 60px;
            padding: 17px 0px 17px 0px;
            box-sizing: border-box;
            border-bottom: 1px solid #EEEEEE;
        }

        .actived {
            border-radius: 2px;
            border: 1px solid #FF4201;
            color: #FF4201;
        }

        .choose-three {
            width: 114px;
            height: 26px;
            line-height: 26px;
        }

        .el-dialog {
            width: 800px;
            height: 666px;
            .img {
                width: 100px;
                height: 100px;
            }

            .el-form-item__label {
                width: 80px;
                padding: 0px 10px 11px 0;
                height: 32px;
                line-height: 32px;
            }

            .redalert {
                color: #ff4949;
                font-size: 12px;
            }

            .el_label {
                width: 160px;
                padding: 0px 10px 11px 0;
                height: 32px;
                line-height: 32px;
                text-align: right;
                vertical-align: middle;
                font-size: 14px;
                color: #48576a;
                box-sizing: border-box;

                img {
                    width: 15px;
                    height: 15px;
                    margin-left: 5px;
                    vertical-align: sub;
                }


            }
        }

    }
</style>