<template>
	<div id="limits">
		<div class="title-top bg-f">角色管理
			<el-button class="fr mr-30" style="color: #FF5100" icon="el-icon-plus" v-if="showButton('ADD',100073)" @click.native="addRole">新增角色</el-button>
		</div>
		<div class="main mt-5 bg-f pb-50 pt-30">
            <div class="tree-box">
                <p class="tree-title">
                    <span class="fl">角色</span>
                    <span class="fr">操作</span>
                </p>
                <el-tree v-loading="Loading" :data="data1" :props="defaultProps" node-key="id" :default-expanded-keys="[2,3,4]" :expand-on-click-node="false" :render-content="renderContent"></el-tree>
            </div>

		</div>
	</div>
</template>
<script>
	import { MessageBox } from 'element-ui';
	import { axiosApi, basePath } from '../../../api/api';
	export default {
		data() {
			return {
				Loading: true,
				roleId: [],
				text: "",
				id: '',
				elements: [],
				data1: [],
				disabled: true,
				defaultProps: {
					children: 'sub',
					label: 'text'
				}
			}
		},
		methods: {
			//			获取角色列表
			achieve() {
				const api = "/foundation/role/selectAll";
				const body = {
					applicationType: 2,
					traderId: sessionStorage.traderId
				}
				axiosApi(api, body, this.callback)
			},
			callback(res) {
				this.data1 = res.data.data ? res.data.data : [];

				this.Loading = false;
			},
			renderContent(h, {
				node,
				data,
				store
			}) {
				return(
					<span style="width:100%">
			            <span>
			              	<span class="el-tree-bg">{node.label}</span>
			            </span>
			            <span   style="line-height:36px">
				            <span class='fr mr-20'>
		              {this.showButton("EDIT",100073)?<el-button size="mini" on-click={ () => this.edit(store, data) }>编辑</el-button>:null}
		              {this.showButton("DEL",100073)?<el-button type="danger" size="mini" on-click={ () => this.remove(store, data) }>删除</el-button>:null}
		            </span>
			            </span>
			            
			    </span>
				);
			},
			//删除员工
			remove(store, data) {

				this.roleId = data.id;
				this.text = data.text;
				this.$confirm('您是否要删除角色「' + this.text + '」？', '提示信息', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					const api = "/foundation/role/delete";
					const body = {
						ids: [this.roleId]
					};
					axiosApi(api, body, (res) => {
						this.$message({
							type: 'success',
							message: '删除成功!',
							duration: 1000
						});
						this.achieve();
					}, this.currentPage, 10, (res) => {
						this.$notify({
							title: '警告',
							message: res.data.message,
							type: 'warning'
						});
					});
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				})
			},

			//跳转到增加角色
			addRole() {
				this.$router.push(basePath + '/addRole')
			},
			//跳转到编辑角色
			edit(store, data) {
				this.id = data.id,
					this.text = data.text,
					this.isDisplay = data.isDisplay,
					this.$router.push(basePath + '/editRole/' + this.id + '/' + this.text + '/' + this.isDisplay)
			},

		},
		activated(){
			this.achieve()
		},
		mounted() {
			this.$nextTick(function() {
				this.achieve()
			})
		}
	}
</script>
<style lang="less">
	#limits {
		.title-top {
			.el-button {
				margin-top: 14px;
			}
		}
        .tree-box{
            margin: 0 30px;
            border: 1px solid #ECEEF6;
            border-bottom: none;
            .tree-title{
                background-color: #F5F7FA;
                padding: 0 180px 0 24px;
                height: 47px;
                line-height: 47px;
                border-bottom: 1px solid #ECEEF6;
            }
            .el-tree {
                .el-tree-node__content{
                    height: 47px;
                    border-bottom: 1px solid #ECEEF6;
                }
            }
        }
		.hide{
			display: none;
		}
	}
</style>