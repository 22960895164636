<!-- 地址选择 & 省市区三级联动 -->
<template>
  <el-form ref="form" :model="form" label-width="120px">
    <div>
      <el-select class="selectDiv" v-model="form.province" :disabled="disabled" placeholder="请选择省" @change="changePro">
        <el-option v-for="item in addressData" :key="item.id" :label="item.name" :value="item.id">
        </el-option>
      </el-select>
      <el-select class="selectDiv" v-model="form.city" :disabled="disabled" placeholder="请选择市" @change="changeCity">
        <el-option v-for="item in cityData" :key="item.id" :label="item.name" :value="item.id">
        </el-option>
      </el-select>
      <el-select class="selectDiv" v-model="form.district" :disabled="disabled" placeholder="请选择区" @change="changeArea">
        <el-option v-for="item in areaData" :key="item.id" :label="item.name" :value="item.id">
        </el-option>
      </el-select>
      <el-select class="selectDiv" v-model="form.street" :disabled="disabled" placeholder="请选择街道" @change="changeStreet">
        <el-option v-for="item in streetData" :key="item.id" :label="item.name" :value="item.id">
        </el-option>
      </el-select>
    </div>
  </el-form>
</template>
 
<script>
export default {
  data() {
    return {
      //  省数据
      addressData: [],
      //  市数据
      cityData: [],
      // 区数据
      areaData: [],
      // 街道数据
      streetData: [],
      // 街道数据
      form: {
        province: "",
        // 市
        city: "",
        // 区
        district: "",
        // 街道
        street: '',
      },
      // 用于判断返回数组true还是字符串false
      valueType: false,
      // 省市区街道name
      provinceName: '',
      cityName:'',
      areaName:'',
      streetName:''
    };
  },
  props: {
    value: {},
    disabled: {
      
    }
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  watch: {
    value(newVal,oldVal){
      this.init(newVal)
    }
  },
  mounted() {
    // 省份数据初始化
    
    this.addressData = JSON.parse(localStorage.getItem('areaList'));
    this.init(this.value)
  },
 
  methods: {
    // 初始化
    
    init(newVal){
      
      if(newVal === null){
        var idList = []
        this.valueType = false
      } else {
        if(typeof newVal === 'string'){
          if(newVal!=''){
            var idList = newVal.split(",");
          }else{
            var idList = []
          }
          this.valueType = false
        }else{
          var idList = newVal
          this.valueType = true
        }
      }
      
      var val = idList
      this.form = {
        province: val[0] || null,
        // 市
        city: val[1] || null,
        // 区
        district: val[2] || null,
        // 街道
        street: val[3] || null,
      }

      if (val[0]) {
        let provinceData = this.addressData.filter(item => item.id == val[0])
        if (provinceData.length) {
          var provinceName = provinceData[0].name
          // 市级列表
          this.cityData = provinceData[0].childs
          this.$emit('changeName',[provinceName,null,null,null])
          let cityData = this.cityData.filter(item => item.id == val[1])
          if (cityData.length) {
            var cityName = cityData[0].name
            // 区级列表
            this.areaData = cityData[0].childs
            this.$emit('changeName',[provinceName,cityName,null,null])
            let areaData = this.areaData.filter(item => item.id == val[2])
            if (areaData.length) {
              var areaName = areaData[0].name
              // 街道列表
              this.streetData = areaData[0].childs
              this.$emit('changeName',[provinceName,cityName,areaName,null])
              let streetData = this.streetData.filter(item => item.id == val[3])
              if (streetData.length) {
                var streetName = streetData[0].name
                // 返回省市区街道name
                this.$emit('changeName',[provinceName,cityName,areaName,streetName])
              }
            }
          }
        }
      }
    },
    // 省份更改
    changePro(e) {
      
      // 从省中过滤出市的数据
      this.cityData = this.addressData.filter((item) => {
        if(item.id == e){
          this.provinceName = item.name
        }
        return item.id == e;
      })[0].childs;
      // 省发生改变的时候清空ID
      this.form.city = '';
      this.form.district = ''
      this.form.street = '' 
      // 省发生改变的时候清空name
      this.cityName= '',
      this.areaName='',
      this.streetName=''

      this.areaData = []
      this.streetData = []
      
      if(this.valueType){
        var value = [this.form.province,null,null,null]
      }else{
        var value = [this.form.province,null,null,null].join(',')
      }
      this.$emit('change',value)
      this.init(value)
    },
    // 市更改
    changeCity(e) {
      // 获取到区的数据
      this.areaData = this.cityData.filter((item) => {
        if(item.id == e){
          this.cityName = item.name
        }
        return item.id == e;
      })[0].childs;
      // 市发生改变的时候清空ID
      this.form.district = ''
      this.form.street = '' 
      // 市发生改变的时候清空name
      this.areaName='',
      this.streetName=''

      this.streetData = []
      if(this.valueType){
        var value = [this.form.province,this.form.city,null,null]
      }else{
        var value = [this.form.province,this.form.city,null,null].join(',')
      }
      
      this.$emit('change',value)
      this.init(value)
    },
    // 区更改
    changeArea(e) {
    
      // 获取到街道的数据
      
      this.streetData = this.areaData.filter((item) => {
        if(item.id == e){
          this.areaName = item.name
        }
        return item.id == e;
      })[0].childs;

      // 区发生改变的时候清空ID
      this.form.street = '' 
      // 区发生改变的时候清空name
      this.streetName=''
      if(this.valueType){
        var value = [this.form.province,this.form.city,this.form.district,null]
      }else{
        var value = [this.form.province,this.form.city,this.form.district,null].join(',')
      }
      this.$emit('change',value)
      this.init(value)
    },
    changeStreet(e) {
      if(this.valueType){
        var value = [this.form.province,this.form.city,this.form.district,this.form.street]
      }else{
        var value = [this.form.province,this.form.city,this.form.district,this.form.street].join(',')
      }
      this.$emit('change',value)
      this.init(value)
    }
  },
};
</script>
 
<style lang="less" scoped>
.selectDiv{
  margin: 5px 0;
}
</style>