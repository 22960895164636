<template>
    <el-table :data="goodsList" stripe border tooltip-effect="dark" class="consignment">
        <el-table-column label="货品信息" width="400">
            <template slot-scope="scope">
                <div class="consign_gift clearfix">
                    <div class="fl">
						<div v-if="scope.row.skuGift" class="triangle-topleft border-red"></div>
						<span v-if="scope.row.skuGift" class="init default">赠品</span>
						<div v-if="scope.row.changePurchaseItem" class="triangle-topleft border-red"></div>
						<span v-if="scope.row.changePurchaseItem" class="init default">换购</span>
						<img v-if="scope.row.skuImage" class="fl img mr-10" :src="scope.row.skuImage"/>
						<img v-else src="../assets/goods.jpg" class="img fl mr-10" alt=""/>
					</div>
                    <div class="fl " style="width: 280px;">
                        <div>
							<p class="ellipsis-2">{{scope.row.skuName}}</p>
							<p class="color-9 mt-5" v-if="scope.row.k3SaleType" >商品类型:{{ scope.row.k3SaleTypeName }}</p>
							<p class="color-9 mt-5" v-if="isClient && scope.row.k3GiftType" >赠品类型:{{ scope.row.k3GiftTypeName }}</p>
                        </div>
                        <div class="mt-5">规格：1*{{scope.row.changeCoefficient}}</div>
                        <!--<div v-if="scope.row.skuGift" class="color-danger">赠品</div>-->
                    </div>
                </div>
            </template>
        </el-table-column>
        <el-table-column label="总数量" prop='allSpecNum' align="center">
            <template slot-scope="scope">
                <!--赠品-->
                <span v-if="scope.row.skuGift">
        			<!--标品-->
        			<span v-if="scope.row.giftType===1">{{scope.row.allSpecNum}}{{scope.row.isIntegerMultiple ? "箱" : scope.row.baseUnitName}}</span>
					<span v-else-if="scope.row.giftType===0">
						<!--整箱-->
						<span v-if="scope.row.isIntegerMultiple">{{scope.row.allSpecNum}}{{scope.row.isIntegerMultiple ? "箱" : scope.row.baseUnitName}}</span>
						<!--非整箱 （瓶）根据下拉框单元换算 -->
							<span v-else>
								<span v-if="scope.row.specUnit != '箱(规格单位)'">{{scope.row.allSpecNum}}{{scope.row.isIntegerMultiple ? "箱" : scope.row.baseUnitName}}</span>
								<span v-if="scope.row.specUnit == '箱(规格单位)'">
												<span v-if="Math.floor(scope.row.allSpecNum/scope.row.changeCoefficient)>0">{{Math.floor(scope.row.allSpecNum/scope.row.changeCoefficient)}}箱</span>
								<span v-if="(scope.row.allSpecNum%scope.row.changeCoefficient)>0">{{scope.row.allSpecNum%scope.row.changeCoefficient}}{{scope.row.isIntegerMultiple ? "箱" : scope.row.baseUnitName}}</span>
							</span>
						</span>
					</span>
				</span>
                <!--商品-->
                <span v-else>
        			<!--整箱-->
        			<span v-if="scope.row.isIntegerMultiple">{{scope.row.allSpecNum}}{{scope.row.isIntegerMultiple ? "箱" : scope.row.baseUnit}}</span>
                    <!--非整箱 （瓶）根据下拉框单元换算-->
				<span v-else>
        				<span v-if="scope.row.specUnit != '箱(规格单位)'">{{scope.row.allSpecNum}}{{scope.row.isIntegerMultiple ? "箱" : scope.row.baseUnit}}</span>
				<span v-if="scope.row.specUnit == '箱(规格单位)'">
        					<span v-if="Math.floor(scope.row.allSpecNum/scope.row.changeCoefficient)>0">{{Math.floor(scope.row.allSpecNum/scope.row.changeCoefficient)}}箱</span>
				<span v-if="(scope.row.allSpecNum%scope.row.changeCoefficient)>0">{{scope.row.allSpecNum%scope.row.changeCoefficient}}{{scope.row.isIntegerMultiple ? "箱" : scope.row.baseUnit}}</span>
				</span>
				</span>
				</span>
            </template>
        </el-table-column>
        <el-table-column label="可发数量" prop='remainingSpecNum' align="center">
            <template slot-scope="scope">
                <!--赠品-->
                <span v-if="scope.row.skuGift">
        			<!--标品-->
        			<span v-if="scope.row.giftType===1">{{scope.row.remainingSpecNum}}{{scope.row.isIntegerMultiple ? "箱" : scope.row.baseUnitName}}</span>
				<span v-else-if="scope.row.giftType===0">
        				<!--整箱-->
        				<span v-if="scope.row.isIntegerMultiple">{{scope.row.remainingSpecNum}}{{scope.row.isIntegerMultiple ? "箱" : scope.row.baseUnitName}}</span>
                    <!--非整箱 （瓶）根据下拉框单元换算-->
				<span v-else>
	        				<span v-if="scope.row.specUnit != '箱(规格单位)'">{{scope.row.remainingSpecNum}}{{scope.row.isIntegerMultiple ? "箱" : scope.row.baseUnitName}}</span>
				<span v-if="scope.row.specUnit == '箱(规格单位)'">
	        					<span v-if="Math.floor(scope.row.remainingSpecNum/scope.row.changeCoefficient)>0">{{Math.floor(scope.row.remainingSpecNum/scope.row.changeCoefficient)}}箱</span>
				<span v-if="(scope.row.remainingSpecNum%scope.row.changeCoefficient)>0">{{scope.row.remainingSpecNum%scope.row.changeCoefficient}}{{scope.row.isIntegerMultiple ? "箱" : scope.row.baseUnitName}}</span>
				</span>
				</span>
				</span>
				</span>
                <!--商品-->
                <span v-else>
        			<!--整箱-->
        			<span v-if="scope.row.isIntegerMultiple">{{scope.row.remainingSpecNum}}{{scope.row.isIntegerMultiple ? "箱" : scope.row.baseUnit}}</span>
                    <!--非整箱 （瓶）根据下拉框单元换算-->
				<span v-else>
        				<span v-if="scope.row.specUnit != '箱(规格单位)'">{{scope.row.remainingSpecNum}}{{scope.row.isIntegerMultiple ? "箱" : scope.row.baseUnit}}</span>
				<span v-if="scope.row.specUnit == '箱(规格单位)'">
        					<span v-if="Math.floor(scope.row.remainingSpecNum/scope.row.changeCoefficient)>0">{{Math.floor(scope.row.remainingSpecNum/scope.row.changeCoefficient)}}箱</span>
				<span v-if="(scope.row.remainingSpecNum%scope.row.changeCoefficient)>0">{{scope.row.remainingSpecNum%scope.row.changeCoefficient}}{{scope.row.isIntegerMultiple ? "箱" : scope.row.baseUnit}}</span>
				</span>
				</span>
				</span>
            </template>
        </el-table-column>
        <el-table-column label="单位" align="center">
            <template slot-scope="scope">
                <!--赠品-->
                <span v-if="scope.row.skuGift">
	        			<!--标品-->
	        			<span v-if="scope.row.giftType===1">{{scope.row.salesUnit}}</span>
				<span v-else-if="scope.row.giftType===0">
	        				<!--整箱-->
	        				<span v-if="scope.row.isIntegerMultiple">{{scope.row.salesUnit}}</span>
                    <!--非整箱 （瓶）根据下拉框单元换算-->
				<span v-else>
		        				<!--满足发货一箱-->
		        				<el-select v-if="Math.floor(scope.row.remainingSpecNum/scope.row.changeCoefficient)>0"
                                           class="unit_spec" v-model="scope.row.specUnit" placeholder="请选择"
                                           @change="unitChange(scope.row, scope.$index)">
									    <el-option
                                                v-for="items in scope.row.unitOptions"
                                                :key="items.value"
                                                :label="items.label"
                                                :value="items.value">
									    </el-option>
									  </el-select>
                    <!--不满足一箱-->
									  <span v-else>{{scope.row.salesUnit}}</span>
				</span>
				</span>
				</span>
                <!--商品-->
                <span v-else>
	        			<!--整箱-->
	        			<span v-if="scope.row.isIntegerMultiple">{{scope.row.salesUnit}}</span>
                    <!--非整箱 （瓶）根据下拉框单元换算-->
				<span v-else>
	        				<!--满足发货一箱-->
	        				<el-select v-if="Math.floor(scope.row.remainingSpecNum/scope.row.changeCoefficient)>0"
                                       class="unit_spec" v-model="scope.row.specUnit" placeholder="请选择"
                                       @change="unitChange(scope.row, scope.$index)">
								    <el-option
                                            v-for="items in scope.row.unitOptions"
                                            :key="items.value"
                                            :label="items.label"
                                            :value="items.value">
								    </el-option>
								  </el-select>
                    <!--不满足一箱-->
								  <span v-else>{{scope.row.salesUnit}}</span>
				</span>
				</span>
            </template>
        </el-table-column>
        <el-table-column label="本次发货数量" align="center" width="150">
            <template slot-scope="scope">
				<span v-if="scope.row.skuGift">
					<!--买家主动提货 || 正常的分批发货-->
            		<span>
            			<span v-if="scope.row.giftType === 1 || (scope.row.giftType === 0&&(scope.row.isIntegerMultiple|| (!scope.row.isIntegerMultiple&&Math.floor(scope.row.remainingSpecNum/scope.row.changeCoefficient)<=0)))">
            			<el-input-number v-model="scope.row.specNum" :min="0" :max="scope.row.remainingSpecNum"
                                         :step="1" :key="scope.$index"/>
            		</span>
					<span v-if="scope.row.giftType === 0&&!scope.row.isIntegerMultiple&&Math.floor(scope.row.remainingSpecNum/scope.row.changeCoefficient)>0">
            			<el-input-number v-model="scope.row.specNum" :min="0"
                                         :max="scope.row.specUnit != '箱(规格单位)'?scope.row.remainingSpecNum:Math.floor(scope.row.remainingSpecNum/scope.row.changeCoefficient)"
                                         :step="1" :key="scope.$index"/>
            		</span>
				</span>

				<!-- <span v-if='scope.row.pickupType == 2 && scope.row.deliverySeparate'>
            		暂不支持自主提货
            	</span> -->
				</span>
                <span v-else>
            		<span v-if="scope.row.isIntegerMultiple || (!scope.row.isIntegerMultiple&&Math.floor(scope.row.remainingSpecNum/scope.row.changeCoefficient)<=0)">
            			<el-input-number v-model="scope.row.specNum" :min="0" :max="scope.row.remainingSpecNum"
                                         :step="1" :key="scope.$index"/>
            		</span>
				<span v-if="!scope.row.isIntegerMultiple&&Math.floor(scope.row.remainingSpecNum/scope.row.changeCoefficient)>0">
            			<el-input-number v-model="scope.row.specNum" :min="0"
                                         :max="scope.row.specUnit != '箱(规格单位)'?scope.row.remainingSpecNum:Math.floor(scope.row.remainingSpecNum/scope.row.changeCoefficient)"
                                         :step="1" :key="scope.$index"/>
            		</span>
				</span>
                <!--<span>
                    暂不支持自主提货
                </span>-->
            </template>
        </el-table-column>
    </el-table>
</template>

<script>
    export default {
        props: {
            goodsList: Array
        },
        data() {
            return {
				isClient:location.pathname == '/customer/order',
                unitOptions: [{
                    label: '箱',
                    value: '箱'
                }, {
                    label: '瓶',
                    value: '瓶'
                }],
            }
        },
        watch: {},
        mounted() {
			this.goodsList.forEach(item => {
                item.specNum = 0
            })
        },
        methods: {
            unitChange(row, i) { // 瓶箱转换
                this.$set(this.goodsList, i, Object.assign(row, {
                    'specNum': 0
                }))
            }
        }
    }
</script>

<style lang="less">
    .unit_spec {
        width: 100px;

        .el-input {
            width: 100px;
        }

        .el-input__inner {
            width: 100px;
        }
    }

    .consign_gift {
        position: relative;
        padding: 10px 0;
        .triangle-topleft {
            border-top: 40px solid #ff4201;
            width: 0;
            height: 0;
            border-right: 40px solid transparent;
            position: absolute;
            left: 0px;
            top: 10px;
        }

        .init {
            font-size: 12px;
            transform: rotate(-45deg) translate(-4px, 5px);
            position: absolute;
            left: 0px;
            top: 10px;
            color: #fff;
            letter-spacing: -0.34px;
        }
    }
</style>
