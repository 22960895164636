<template>
    <div id="vipManage" class="pb-50">
        <div class="title-top bg-f">会员管理</div>
        <div class="bg-f mt-5 pb-50">
            <div class="content bg-f pl-30 pr-30 pb-50">
                <!-- 搜索区域 -->
                <div class="clearfix search">
                    <el-form :inline='true' class="pt-30 fl" :model="form" ref="searchForm">
                        <el-form-item label='会员昵称' label-width="60px" prop="name">
                            <el-input v-model.trim="form.name" :maxlength="30" placeholder="请输入会员昵称"></el-input>
                        </el-form-item>
                        <el-form-item label='手机号' prop="mobile">
                            <el-input v-model.trim="form.mobile" :maxlength="11" placeholder="请输入手机号"></el-input>
                        </el-form-item>
                    </el-form>
                    <el-button class="fl ml-30 mt-30" type='info' @click="getTableList()">查询</el-button>
                </div>
                <!-- 列表区域 -->
                <div class="pb-30 mt-20">
                    <el-table :data="tableList" class='table-default'>
                        <el-table-column label="手机号" prop="payMobile"></el-table-column>
                        <el-table-column label="昵称" prop="payNickName"></el-table-column>
                        <el-table-column label="酒水消费（元)" >
                            <template slot-scope="scope">
                                <span>￥{{scope.row.sumWineAmount | fmoney}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="菜品消费（元)" >
                            <template slot-scope="scope">
                                <span>￥{{scope.row.sumFoodAmount | fmoney}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="总消费（元)">
                            <template slot-scope="scope">
                                <span>￥{{scope.row.sumTotalAmount | fmoney}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="消费次数（次)" prop="spendCount"></el-table-column>
                    </el-table>
                    <el-pagination class="tar" v-if="tableList.length>0" :current-page="currentPage" :page-size='10'
                                   :total='total' layout="prev , pager, next, jumper"
                                   @current-change="handleCurrentChange">
                        <!-- 分页组件 -->
                    </el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    import {axiosApi, basePath, baseURI} from "../../api/api";

    export default {
        data() {
            return {
                /*规则*/
                rules: {
                    name: [{
                        message: '请输入姓名'
                    }],
                    mobile: [{
                        message: '请输入手机号'
                    }],
                },
                currentPage: 1,
                total: 0,
                tableList: [],
                form: {
                    name: '',
                    mobile: ''
                }
            }
        },
        //进入加载
        mounted() {
            this.$nextTick(function () {
                this.getTableList()
            })
        },
        methods: {
            getTableList() {
                axiosApi('/catering/platform/orderMembership/select', {
                    payNickName: this.form.name,
                    payMobile: this.form.mobile,
                }, (res) => {
                    this.tableList = res.data.data.elements ? res.data.data.elements : []
                    this.total = res.data.data.totalCount
                }, this.currentPage, 10, (res) => {
                })
            },
            handleCurrentChange(val) { //分页
                this.currentPage = val
                this.getTableList()
            },
        },
    }
</script>

<style lang="less">
    #vipManage {

    }
</style>