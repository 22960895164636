<template>
  <div id="actualpath">
    <div class="title-top bg-f pl-20">实时轨迹</div>
    <div style="display: flex; margin-top: 12px">
      <div class="left bg-f">
        <el-form
          :inline="true"
          :model="form"
          label-width="90px"
          class="clearfix pl-20 pt-30"
        >
          <div class="fl">
            <el-form-item label="部门">
              <el-cascader
                :props="apartmentProps"
                expand-trigger="hover"
                :options="options"
                v-model="form.apartment"
                @change="handleChange"
                change-on-select
                clearable
                :disabled="isAdmin == 0 && isDepartmentLeader == 0"
              >
              </el-cascader>
            </el-form-item>
            <el-form-item label="人员">
              <el-select
                v-model="manage"
                placeholder="请选择员工"
                clearable
                :disabled="isAdmin == 0 && isDepartmentLeader == 0"
              >
                <el-option
                  v-for="item in optionren"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-button
              type="danger"
              class="ml-50"
              @click="search"
              icon="el-icon-search"
              >查 询</el-button
            >
          </div>
        </el-form>
        <div class="mapbox-wrap">
          <div class="mapbox">
            <div id="map"></div>
            <div id="path-box" ref="mapinfo" :class="{ clicked: isSelected }">
              <div class="top">
                <div class="fir">
                  <i class="iconfont icon-user"></i>
                  <div>在线时间共计{{ selectedone.onlineTime }}</div>
                </div>
                <div
                  class="sec"
                  v-if="
                    selectedone.outlineTime !== '0秒' &&
                    selectedone.outlineTime !== null
                  "
                >
                  <span>轨迹异常</span>
                  <span>失联{{ selectedone.outlineTime }}</span>
                </div>
              </div>
              <div
                id="infobody"
                :class="{
                  'top-more':
                    selectedone.outlineTime &&
                    selectedone.outlineTime !== '0秒',
                }"
              >
                <el-timeline>
                  <el-timeline-item
                    v-for="(item, index) in newsignVisitTrackList"
                    :key="index"
                    :timestamp="item.typeText"
                    placement="top"
                  >
                    <div class="right red" v-if="item.outLineException">
                      {{ item.outLineException }}
                    </div>
                    <div v-else>
                      <div class="color-9">
                        {{
                          item.type == 5
                            ? "报备名称:" + item.taskName
                            : item.type == 3
                            ? "拜访商户:" + item.traderName
                            : ""
                        }}
                      </div>
                      <div class="color-9">
                        {{
                          item.type == 3
                            ? item.startTime + "-" + item.endTime
                            : item.trackDateHm
                        }}
                      </div>
                      <div class="color-9">
                        {{
                          item.addr
                            ? item.addr
                            : item.type == 3 || item.type == 5
                            ? "无详细位置"
                            : ""
                        }}
                      </div>
                    </div>
                  </el-timeline-item>
                </el-timeline>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="right bg-f" style="padding: 29px 26px">
        <div class="onlinestatus">
          <el-button-group>
            <el-button
              @click="changeTab(0)"
              size="mini"
              :type="activeStatus === 0 ? 'danger' : ''"
              >在线</el-button
            >
            <el-button
              @click="getOutlines"
              size="mini"
              :type="activeStatus === 1 ? 'danger' : ''"
              >失联</el-button
            >
            <el-button
              @click="changeTab(2)"
              size="mini"
              :type="activeStatus === 2 ? 'danger' : ''"
              >无需定位</el-button
            >
          </el-button-group>

          <!--<div @click="changeTab(2)" :class="{active: activeStatus === 2}">-->
          <!--<i class="boss"></i><span>无需定位: {{noNeedNum}}</span>-->
          <!--</div>-->
          <!--<div @click="getOutlines" :class="{active: activeStatus === 1}">-->
          <!--<i class="el-icon-information"></i><span>失联: </span><span>{{outlineNum}}</span>-->
          <!--</div>-->
          <!--<div @click="changeTab(0)" :class="{active: activeStatus === 0}">-->
          <!--<i class="el-icon-circle-check"></i><span>在线: {{onlineNum}}</span>-->
          <!--</div>-->
          <div class="gps" v-if="isSelected">
            <el-button type="danger" @click="showGps">电量/GPS状态</el-button>
          </div>
        </div>

        <div id="mapstatus-list">
          <div class="main-list">
            <div class="main-list-tl">
              <span>{{
                activeStatus === 0
                  ? "在线人数: " + onlineNum + "人"
                  : activeStatus === 1
                  ? "失联人数: " + outlineNum + "人"
                  : "无需定位: " + noNeedNum + "人"
              }}</span>
            </div>

            <div class="main-list-ct">
              <div
                class="ct-item"
                @click="
                  i.trackLongitude &&
                    openInfoWindow(
                      i.trackLongitude,
                      i.trackLatitude,
                      1,
                      i,
                      'clickItem'
                    )
                "
                :class="{
                  'clicked-item': clicked === i.managerId,
                  pointer: activeStatus === 0 || activeStatus === 1,
                }"
                v-for="i in rightMapList"
                :key="i.id"
              >
                <img :src="i.headportraitUrl ? i.headportraitUrl : imgHeader" />

                <div class="item">
                  <div class="pb-10">
                    <span class="user"
                      >{{ i.managerName || i.employeeName }}({{
                        i.departmentName
                      }})</span
                    >
                  </div>
                  <div v-if="activeStatus === 0">
                    <p class="pb-10 pl-20" style="position: relative">
                      <i class="iconfont icon-dingwei"></i>
                      <span class="span_addr">{{ i.addr }}</span>
                    </p>
                    <p class="pl-20" style="position: relative">
                      <i class="el-icon-time"></i>
                      <span>{{ i.gmtCreate | timenyrr }}</span>
                      <span>里程: {{ i.totalMeters | meters }}</span>
                    </p>
                  </div>
                  <p v-else-if="activeStatus === 2">
                    <span>{{ i.mobile }}</span>
                    <!-- <span>里程: 1KM</span> -->
                  </p>
                  <div v-else>
                    <p class="pb-10 pl-20" style="position: relative">
                      <i class="iconfont icon-dingwei"></i>
                      <span class="span_addr">{{ i.addr }}</span>
                    </p>
                    <p class="pl-20" style="position: relative">
                      <i class="el-icon-warning" style="color: #e14b28"></i>
                      <span class="fial">{{ i.outLineDuration }}</span>
                      <span>里程: {{ i.totalMeters | meters }}</span> -->
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- gps/电量弹窗 -->
    <el-dialog
      :title="labelname + '的手机电量'"
      :visible.sync="showGpsDialog"
      class="tac"
      @close="showGpsDialog = false"
    >
      <el-table
        max-height="450"
        border
        :data="CopyGpsDetails2"
        class="ml-30 mt-10 table-default mr-30"
        stripe
      >
        <el-table-column :resizable="false" label="时间" width="60">
          <template slot-scope="scope">
            <span>{{ scope.row.gmtCreate | getHM }}</span>
          </template>
        </el-table-column>
        <el-table-column :resizable="false" label="电量" width="60">
          <template slot-scope="scope">
            <span v-if="scope.row.electricity">{{
              scope.row.electricity == -1
                ? ""
                : scope.row.electricity.indexOf("%") > -1
                ? scope.row.electricity
                : scope.row.electricity + "%"
            }}</span>
          </template>
        </el-table-column>
        <el-table-column :resizable="false" label="GPS状态" width="60">
          <template slot-scope="scope">
            <span>{{
              scope.row.gpsStatus == 0
                ? "未开启"
                : scope.row.gpsStatus == 1
                ? "已开启"
                : ""
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          :resizable="false"
          label="wifi状态"
          width="60"
          prop="wifiStatus"
        >
          <template slot-scope="scope">
            <span>{{
              scope.row.wifiStatus == 0
                ? "未连接"
                : scope.row.wifiStatus == 1
                ? "已连接"
                : ""
            }}</span>
          </template>
        </el-table-column>
        <el-table-column :resizable="false" label="移动网络" width="60">
          <template slot-scope="scope">
            <span>{{
              scope.row.networkStatus == 0
                ? "未连接"
                : scope.row.networkStatus == 1
                ? "已连接"
                : ""
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          :resizable="false"
          label="位置"
          prop="addr"
          width="220"
        >
        </el-table-column>
        <el-table-column
          :resizable="false"
          label="手机型号"
          prop="phoneModel"
          width="120"
        >
        </el-table-column>
        <el-table-column
          :resizable="false"
          label="操作系统"
          prop="operatingSystem"
          width="60"
        >
        </el-table-column>
        <el-table-column
          :resizable="false"
          label="app版本号"
          prop="appVersion"
          width="70"
        >
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
let markerzIndex = 1000;
let markers = {
  error: {},
  correct: {},
  none: {},
};
var renderGo;
let mapEle;
var polyline;
import { mapActions, mapMutations } from "vuex";
import { axiosApi, basePath } from "../../api/api";
export default {
  data() {
    return {
      markerList: null,
      onlyOne: true,
      form: {
        apartment: [],
      },
      formInlineData: {
        managerName: "",
      },
      manage: "",
      options: [],
      optionren: [],
      departmentId: null,
      departmentIdList: [],
      tracklist: null,
      selectedone: {},
      noNeedNum: 0,
      onlineNum: 0,
      outlineNum: 0,
      apartmentProps: {
        value: "id",
        label: "text",
        children: "sub",
      },
      employeeNameChange: null,
      outlineMans: [], //失联业务员列表
      currentPage: 1,
      total: 0,
      Loading: false,
      clickedDataItem: {},
      showGpsDialog: false,
      img4: require("../../assets/yx/4.png"),
      img3: require("../../assets/yx/3.png"),
      img7: require("../../assets/yx/7.png"),
      img8: require("../../assets/yx/8.png"),
      imgEmpty: require("../../assets/yx/empty.png"),
      img5: require("../../assets/yx/5.png"),
      imgHeader: require("../../assets/header.jpg"),
      activeStatus: 0, // 0在线，1失联，2无需定位
      noNeedEmps: [],
      clicked: null, //右侧列表搜索或者选中的样式
      first: true,
      map: null, //地图变量，不要暴露为全局的变量
      marker_cluster: null,
      isSelected: false,
      labelname: "",
      diyManage: "",
      CopyGpsDetails2: [],
      isDepartmentLeader: null,
      newsignVisitTrackList: [],
    };
  },
  watch: {
    rightMapList(val) {
      let that = this;
      if (val && val.length >= 1) {
        //点聚合
        let type =
          that.activeStatus === 0
            ? "correct"
            : that.activeStatus === 1
            ? "error"
            : "none";
        that.make_cluster(val, type);
      } else if (val.length == 0) {
        that.map.clearOverlays();
      }
    },
  },
  computed: {
    rightMapList() {
      if (this.activeStatus === 0) {
        // 在线
        return this.tracklist;
      } else if (this.activeStatus === 1) {
        // 失联
        return this.outlineMans;
      } else if (this.activeStatus === 2) {
        // 无需定位
        return this.noNeedEmps;
      } else {
        return [];
      }
    },

    isAdmin() {
      return sessionStorage.getItem("isAdmin");
    },
  },
  methods: {
    ...mapActions("clientType", ["resolveIsSalesDepartmentLeaderWithCache"]),
    showGps() {
      this.showGpsDialog = true;
      this.CopyGpsDetails2 = [];
      this.CopyGpsDetails2 = this.selectedone.clockTrackList;
    },
    changeTab(val) {
      this.clicked = null;
      this.isSelected = false;
      this.map && this.map.clearOverlays();

      this.activeStatus = null;
      setTimeout(() => {
        this.activeStatus = val;
      }, 100);
    },

    make_cluster(val, x) {
      //聚合点
      let that = this;
      let map = this.map;
      map.clearOverlays();
      if (x === "none") {
        return;
      }
      val.forEach((item) => {
        let imgSrc = item.headportraitUrl
          ? item.headportraitUrl
          : that.imgHeader;
        var str =
          '<div class="show-preson-smalbox">\
                <img src="' +
          imgSrc +
          '" />\
                <br/ >\
                <span class="user">' +
          item.managerName +
          "</span>\
            </div>";
        var point = new BMap.Point(item.trackLongitude, item.trackLatitude);
        var label = new BMap.Label(str, {
          // 创建文本标注
          position: point, // 设置标注的地理位置
        });
        label.setStyle({
          // 设置label的样式
          border: "none",
        });
        map.addOverlay(label);
      });
      return;
    },
    ToHMSTime(value) {
      var myDate = new Date(value);
      var hours =
        myDate.getHours() < 10 ? "0" + myDate.getHours() : myDate.getHours();
      var minutes =
        myDate.getMinutes() < 10
          ? "0" + myDate.getMinutes()
          : myDate.getMinutes();
      var seconds =
        myDate.getSeconds() < 10
          ? "0" + myDate.getSeconds()
          : myDate.getSeconds();
      return hours + ":" + minutes + ":" + seconds;
    },
    //构建自定义信息窗体
    createInfoWindow(title, content) {
      var info = document.createElement("div");
      info.className = "info";

      //可以通过下面的方式修改自定义窗体的宽高
      //info.style.width = "400px";
      // 定义顶部标题
      var top = document.createElement("div");
      var titleD = document.createElement("div");
      top.className = "info-top";
      titleD.innerHTML = title;

      top.appendChild(titleD);
      info.appendChild(top);

      // 定义中部内容
      var middle = document.createElement("div");
      middle.className = "info-middle";
      middle.style.backgroundColor = "white";
      middle.innerHTML = content;
      info.appendChild(middle);

      return info;
    },
    openInfoWindow(lng, lat, type, data, from) {
      let that = this;
      if (type == 1) {
        //单个员工点击或者右侧列表条目点击 - 打开窗口之前展示出来marker
        this.manage = this.diyManage = this.clicked = data.managerId;
        if (from === undefined) {
          let yheight = document.documentElement
            ? document.documentElement.scrollTop
            : document.body.scrollTop; //滚动条距顶端的距离
          this.$nextTick(() => {
            let x = document.getElementsByClassName(
              "ct-item clicked-item pointer"
            );
            for (let i = 0; i < x.length; i++) {
              x[i].scrollIntoView(false);
            }
            window.scrollTo(0, yheight);
          });
        }
        that.getPathAndPoint();
      }
    },
    topathdetail(data) {
      var managerId = data.managerId;
      var trackDate = data.gmtCreate;
      var departmentId = this.departmentId;
      this.$router.push({
        path: basePath + "/pathdetail",
      });
      sessionStorage.setItem(
        "pathSearchParams",
        JSON.stringify({
          managerId: managerId,
          trackDate: trackDate,
          departmentId: departmentId,
        })
      );
    },
    handleChange(val) {
      this.departmentId = val.slice(-1)[0];
      this.getOutlines("1");
      this.queryemploy(null);
      this.manage = null;
    },
    getPathAndPoint() {
      let that = this;
      let map = that.map;
      map.clearOverlays();
      if (this.diyManage || this.manage) {
        axiosApi(
          "/sales/clockTrack/web/selectDetails",
          {
            managerId: this.diyManage || this.manage,
            trackDate: "",
          },
          (res) => {
            this.selectedone = res.data.data;

            if (res.data.data.clockTrackList.length > 0) {
              this.isSelected = true;
              this.optionren.map((item) => {
                if (item.value == this.diyManage || item.value == this.manage) {
                  this.labelname = item.label;
                }
              });

              // 划线
              let outlineArr = res.data.data.startingPoints
                ? res.data.data.startingPoints
                : [];
              let ss = res.data.data.clockTrackList.filter((a) => {
                return a.addr !== "";
              });
    
              let datasource = ss.map((a) => {
                return new BMap.Point(a.trackLongitude, a.trackLatitude);
              });
              if(datasource.length!==0){
                that.writepath(datasource, that.manage, "#6cc44c");
              }
              

              // 画点（标注列表）
              let newsignVisitTrackList =
                res.data.data.signVisitTrackList.filter((a) => {
                  return a.addr !== "";
                });
              newsignVisitTrackList.forEach((item, index) => {
                item.typeText =
                  item.type == 0
                    ? "签到"
                    : item.type == 1
                    ? "签退"
                    : item.type == 3
                    ? "拜访"
                    : item.type == 5
                    ? "报备"
                    : "异常";
                if (item.outLineException) {
                  item.trackLongitude =
                    newsignVisitTrackList[index - 1].trackLongitude;
                  item.trackLatitude =
                    newsignVisitTrackList[index - 1].trackLatitude;
                  item.infoBody = item.outLineException;
                } else {
                  item.infoBody = item.addr ? item.addr : "无详细位置";
                }
              });
              this.newsignVisitTrackList = newsignVisitTrackList;
              that.writepoint(newsignVisitTrackList);

              // // 画完轨迹和点之后再获取overlays--依然无法解决第一次点无法展示所有点,延时可以解决失联的第一个点击setfitview
              // let overlays = this.map.getAllOverlays();
              // setTimeout(() => {
              //   this.map.setFitView(overlays); //自适应显示
              // }, 100);
            } else {
              this.isSelected = false;
              this.$notify({
                type: "warning",
                message: "无轨迹信息",
              });
            }
          }
        );
      }
    },
    search() {
      var that = this
      this.map.clearOverlays();
      if (!this.manage) {
        this.changeTab(0);
        return false;
      }
      let haveThis = false;
      if (this.tracklist) {
        this.tracklist.forEach((item1) => {
          if (item1.managerId === this.manage) {
            this.activeStatus = 0;
          }
        });
      }

      this.outlineMans.forEach((item2) => {
        if (item2.managerId === this.manage) {
          this.activeStatus = 1;
        }
      });
  
      for (let i in this.noNeedEmps) {
        if (this.manage === this.noNeedEmps[i].managerId) {
          haveThis = true;
          this.clicked = this.manage;
          this.isSelected = false; //可以隐藏人员轨迹列表
          let yheight = document.documentElement
            ? document.documentElement.scrollTop
            : document.body.scrollTop; //滚动条距顶端的距离
          this.$nextTick(() => {
            let x = document.getElementsByClassName(
              "ct-item clicked-item pointer"
            );
            for (let i = 0; i < x.length; i++) {
              x[i].scrollIntoView(false);
            }
            window.scrollTo(0, yheight);
          });
          this.activeStatus = 2;
        }
      }

      if(that.activeStatus === 0){
         this.make_cluster(this.tracklist, "correct");
      }else if(that.activeStatus === 1){
         this.make_cluster(this.outlineMans, "error"); 
      }else{
         this.make_cluster(this.noNeedEmps, "none");
      }
    },
    queryapart() {
      axiosApi(
        "/sales/clockTrack/web/departments",
        {
          traderId: sessionStorage.getItem("traderId"),
        },
        (res) => {
          var gg = res.data.data;
          this.options = res.data.data ? res.data.data : [];
          this.options = this.cascaderClear(this.options);
          var that = this;
          function getId(st) {
            if (st !== null) {
              for (let i = 0; i < st.length; i++) {
                that.departmentIdList.push(st[i].id);
                getId(st[i].sub);
              }
            }
          }
          if (this.isDepartmentLeader == 0 && this.isAdmin == 0) {
            this.form.apartment = sessionStorage
              .getItem("departmentPath")
              .split(",")
              .map((a) => {
                return Number(a);
              });
            this.departmentId = this.form.apartment.slice(-1)[0];
            this.queryemploy();
          } else {
            getId(gg);
            this.queryemploy();
          }
        }
      );
    },
    queryemploy(x) {
      axiosApi(
        "/sales/clockTrack/web/managerCount",
        {
          departmentId: this.departmentId,
          departmentIdList: x === null ? [] : this.departmentIdList,
          resultType: 2,
          isDeleted: 0,
        },
        (res) => {
          var employees = res.data.data.employeeList;
          this.noNeedEmps = res.data.data.noNeedEmps;
          this.selectedone = {};
          this.noNeedNum = res.data.data.noNeedNum;
          this.onlineNum = res.data.data.onlineNum;
          this.outlineNum = res.data.data.outlineNum;
          this.optionren = employees.map((a) => {
            return {
              value: a.managerId,
              label: a.employeeName,
            };
          });
          if (this.isDepartmentLeader == 0 && this.isAdmin == 0) {
            this.manage = Number(sessionStorage.getItem("managerId"));
          }
          let trackList = res.data.data.trackList;
          if (trackList.length) {
            let newTrackList = trackList.map((a) => {
              return Object.assign({}, a, {
                pos: {
                  O: a.trackLatitude,
                  M: a.trackLongitude,
                  lng: a.trackLongitude,
                  lat: a.trackLatitude,
                },
              });
            });
            let newArr = [];
            let onLineEmps = res.data.data.onLineEmps;
            onLineEmps.forEach((item, i) => {
              newTrackList.forEach((item2) => {
                if (item.managerId === item2.managerId) {
                  let obj = Object.assign({}, item, item2);
                  newArr.push(obj);
                }
              });
            });
            this.tracklist = newArr;
          } else {
            this.tracklist = [];
          }
        }
      );
    },
    getOutlines(val) {
      //获取全部失联人员
      this.clicked = null;
      if (val !== "1") {
        this.activeStatus = null;
        setTimeout(() => {
          this.activeStatus = 1;
        }, 10);
        this.isSelected = false;
        if (this.outlineMans.length === this.outlineNum) {
          return false;
        }
      }

      this.Loading = true;

      axiosApi(
        "/sales/clockTrack/web/managerOutlineList",
        {
          departmentId: this.departmentId,
          departmentIdList: this.departmentId
            ? []
            : this.departmentIdList && this.departmentIdList.length
            ? this.departmentIdList
            : [],
          managerName: this.formInlineData.managerName,
        },
        (res) => {
          this.Loading = false;
          this.outlineMans = res.data.data.elements
            ? res.data.data.elements
            : [];
          this.currentPage = res.data.data.pageIndex;
          this.total = res.data.data.totalCount;
          // 解决一开始就搜索失联的人的情况
          this.make_cluster(this.outlineMans, "error");
        },
        this.currentPage,
        1000
      );
    },
    // 创建点
    makePoint(long, lat, info, mapObject, imgSrc, type) {
      var point = new BMap.Point(long, lat);

      var marker = new BMap.Marker(point, {
        icon: this.addMarker(imgSrc, type),
      }); // 创建标注
      mapObject.addOverlay(marker);
      var label = new BMap.Label(this.makeBubl(info.name), {
        // 创建文本标注
        position: point, // 设置标注的地理位置
        offset: new BMap.Size(-50, -60), // 设置标注的偏移量
      });
      label.setStyle({
        // 设置label的样式
        border: "2px solid #fff",
        display: "none",
      });
      marker.addEventListener("mouseover", function (e) {
        label.setStyle({ display: "block" });
      });
      marker.addEventListener("mouseout", function (e) {
        label.setStyle({ display: "none" });
      });
      mapObject.addOverlay(label);
    },
    addMarker(imgSrc, type) {
      if (type === 0 || type === 1) {
        var myIcon = new BMap.Icon(imgSrc, new BMap.Size(34, 34), {
          anchor: new BMap.Size(17, 17),

          imageSize: new BMap.Size(34, 34),
        });
      } else {
        var myIcon = new BMap.Icon(imgSrc, new BMap.Size(24, 34), {
          anchor: new BMap.Size(12, 30),

          imageSize: new BMap.Size(24, 34),
        });
      }

      return myIcon;
    },
    // 创建标注
    makeBubl(str) {
      let html =
        '<div style="width:150px;text-align:center;padding:0px 10px;white-space: pre-wrap;border:5px solid #171e39; position:relative;background-color:#171e39;color:#fff">' +
        '<span style="width:0; height:0; font-size:0; overflow:hidden; position:absolute;border-width:10px; border-style:solid dashed dashed; border-color:#171e39 transparent transparent;left:28px; bottom:-20px;"></span>' +
        str +
        "</div>";
      return html;
    },
    writepoint(data) {
      var that = this;
      var map = this.map;
      data.forEach((item) => {
        var labeltext;
        let iconimg = "";
        if (item.type == 0) {
          labeltext = "签到";
          iconimg = that.img4;
        } else if (item.type == 1) {
          labeltext = "签退";
          iconimg = that.img3;
        } else if (item.type == 3) {
          labeltext = "拜访";
          iconimg = that.img7;
        } else if (item.type == 5) {
          labeltext = "报备";
          iconimg = that.img8;
        } else if (item.type == 4) {
          // 4是失联
          iconimg = that.imgEmpty;
        } else if (item.type != 4) {
          //停 TODO 写成后端给的type类型
          iconimg = that.img5;
        }
        that.makePoint(
          item.trackLongitude,
          item.trackLatitude,
          { name: "id:" + item.id },
          map,
          iconimg,
          item.type
        );
      });
    },
    writepath(data, id, color) {
      let map = this.map;
       // 获取合理的中心点
      const centerPoint = map.getViewport(data);

      // 初始化地图，设置中心点坐标和地图级别
      map.centerAndZoom(centerPoint.center, centerPoint.zoom);

      var polyline = new BMap.Polyline(data, {
        strokeWeight: 12,
        strokeColor: color, // 线条颜色
        strokeStyle: color == "#ff4201" ? "dashed" : "solid",
        strokeOpacity: 0.8,
      });
      map.addOverlay(polyline);
     
    },
  },
  //进入加载
  mounted() {
    // *******获取云销负责人******
    this.resolveIsSalesDepartmentLeaderWithCache((res) => {
      this.isDepartmentLeader = res;
    });

    this.queryapart();
    this.$nextTick(() => {
      let that = this;
      // 解决一开始就搜索失联的人的情况
      // this.getOutlines("1");
      if (!that.map) {
        that.map = new BMap.Map("map", {
          coordsType: 3, // coordsType指定输入输出的坐标类型，3为gcj02坐标，5为bd0ll坐标，默认为5。
        });
        that.map.enableScrollWheelZoom(true);
        that.map.centerAndZoom(new BMap.Point(120.169428, 30.344913), 14);

        that.map.addEventListener("zoomend", function () {
          // 缩放事件
          // that.setMarker(this.getZoom())
        });
      }
    });
  },
};
</script>

<style lang="less">
	#actualpath {
        .left{
            width: 64%;
            .mapbox-wrap {
                width: 100%;
                min-width:1000px;
                height: 650px;
                overflow: auto;
                .mapbox {
                    box-sizing: border-box;
                    height: 600px;
                    display: flex;
                    margin: 0 10px;
                    margin-top: 10px;
                    & > div {
                        display: inline-block;
                    }
                    #map {
                        width:100%;
                        height: 600px;
                        .show-preson-smalbox {
                            display: block;
                            text-align: center;
                            background: #fff;
                            padding: 3px;
                            border: 2px solid green;
                            color: green;
                            font-size: 12px;
                            img {
                                width: 45px;
                                height: 45px;
                            }
                        }
                    }
                    #path-box {
                        width:24%;
                        min-width: 240px;
                        height: 600px;
                        border-right: 1px solid #ccc;
                        border-bottom: 1px solid #ccc;
                        /*// box-shadow: 0px 0px 5px #ccc;*/
                        display: none;
                        position: relative;
                        &.clicked {
                            display: block;
                            width: 260px;
                        }
                        .top {
                            background: #03A6E8;
                            color: #fff;
                            box-sizing: border-box;
                            padding: 10px;
                            .fir {
                                i {
                                    float: left;
                                    width: 20px;
                                    height: 20px;
                                    border: 1px solid #fff;
                                    border-radius: 50%;
                                    position: relative;
                                    margin-right: 5px;
                                    margin-top: 5px;
                                    &:before {
                                        position: absolute;
                                        left: 4px;
                                        top: 2px;
                                    }
                                }
                                &>div {
                                    line-height: 30px;
                                }
                            }
                            .sec {
                                margin-top: 10px;
                                font-size: 12px;
                                padding-left: 26px;
                                span {
                                    &:first-child {
                                        color: #FF4201;
                                        margin-right: 10px;
                                    }
                                }
                            }
                        }
                        #infobody {
                            width: 100%;
                            box-sizing: border-box;
                            overflow-y: auto;
                            position: absolute;
                            right: 0;
                            top: 50px;
                            bottom: 0;
                            padding: 0 20px;
                            &.top-more {
                                top: 77px;
                            }
                            // .amap-ui-markerlist-list-ele {
                            //     cursor: pointer;
                            //     overflow: hidden;
                            //     padding: 0 15px 0 0;
                            //     &:nth-last-child(1) {
                            //         .left {
                            //             .myline {
                            //                 &.myline-btm {
                            //                     width: 0px;
                            //                 }
                            //             }
                            //         }
                            //     }
                            //     &.selected {
                            //         background: #f2f2f2;
                            //     }
                            //     .left {
                            //         float: left;
                            //         width: 70px;
                            //         font-size: 14px;
                            //         color: #666;
                            //         margin-right: 10px;

                            //         &>div {
                            //             text-align: center;
                            //             &:first-child {
                            //                 padding-top: 10px;
                            //             }
                            //             &.myline {
                            //                 width: 2px;
                            //                 height: 60px;
                            //                 background: #F5F5F5;
                            //                 margin-left: 30px;
                            //                 &.myline-top {
                            //                     &.hide0 {
                            //                         width: 0px;
                            //                     }
                            //                 }
                            //                 &.myline-btm {
                            //                     &.hide1 {
                            //                         width: 0px;
                            //                     }
                            //                 }
                            //             }
                            //         }
                            //     }
                            //     .right {
                            //         padding-top: 11px;
                            //         color: #999999;
                            //         margin-left: 80px;
                            //         &.red {
                            //             color: #FF0101;
                            //             margin-top: 40px;
                            //             &.hide0,&.hide1,&.hide2,&.hide3,&.hide5 {
                            //                 display: none;
                            //             }
                            //         }
                            //         &>div {
                            //             &:nth-child(2),&:nth-child(3) {
                            //                 margin-top: 10px;
                            //             }
                            //         }
                            //     }
                            // }
                            // .amap-ui-markerlist-list-ele:hover {
                            //     background: #f2f2f2;
                            // }
                        }
                    }
                    // 地图window窗口模块
                    .amap-info-content {
                        padding: 0;
                        div.info-top {
                            min-width: 200px;
                            position: relative;
                            background: none repeat scroll 0 0 #F9F9F9;
                            border-bottom: 1px solid #CCC;
                            border-radius: 5px 5px 0 0;
                        }
                        div.info-top div {
                            display: inline-block;
                            color: #333333;
                            font-size: 14px;
                            font-weight: bold;
                            line-height: 31px;
                            padding: 0 10px;
                        }

                        div.info-middle {
                            font-size: 12px;
                            padding: 6px;
                            line-height: 20px;
                            .info-people-list {
                                cursor: pointer;
                                text-align: center;
                                vertical-align: top;
                                width: 65px;
                                display: inline-block;
                                margin: 4px;
                                img {
                                    width: 50px;
                                    height: 50px;
                                }
                                p {
                                    display: inline-block;
                                    width: 65px;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    white-space: nowrap;
                                }
                            }
                        }
                    }
                }
            }

        }
        .right{
            margin-left: 12px;
            flex: 1;
            .onlinestatus {
                .el-button-group{
                    &>.el-button{
                        &.el-button-danger{
                            &:hover{
                                color: #FFFFFF;
                                border-color: #FF5100;
                                background-color: #FFFFFF;
                            }
                        }
                        &:hover{
                            color: #FF5100;
                            border-color: #FF5100;
                            background-color: #FFFFFF;
                        }

                    }
                }
            }
            #mapstatus-list {
                margin-top: 27px;
                overflow-y: hidden;
                background: #fff;
                height: 600px;
                .main-list {
                    .main-list-tl {
                        background-color: #F5F7FA;
                        border: 1px solid #ECEEF6;
                        padding: 9px 17px;
                        font-size: 14px;
                        color: #333;
                        position: relative;
                        i {
                            position: absolute;
                            right: 10px;
                            top: 13px;
                        }
                    }
                    .main-list-ct {
                        height: 550px;
                        overflow: auto;
                        .ct-item {
                            overflow: hidden;
                            position: relative;
                            color: #959595;
                            border: 1px solid #ECEEF6;
                            border-top: none;
                            padding: 20px;
                            &.clicked-item {
                                background-color: #f2f2f2;
                            }
                            &.pointer {
                                cursor: pointer;
                            }
                            img {
                                float: left;
                                width: 80px;
                                height: 80px;
                                border-radius: 40px;
                                margin-right: 10px;
                                vertical-align: middle;
                            }
                            .item {
                                float: left;
                                position: relative;
                                margin: 10px 0px 10px 20px;
                                .user {
                                    font-weight: bold;
                                    margin-right: 5px;
                                    color: #666;
                                }
                                i {
                                    width: 10px;
                                    height: 10px;
                                    display: inline-block;
                                    margin-left: -18px;
                                    margin-right: 5px;
                                    color: #999;
                                    &.icon-dingwei {
                                        position: absolute;
                                        left: 19px;
                                        top: 1px;
                                        font-size: 14px;
                                    }
                                }
                                .fial {
                                    color: #E14B28;
                                }
                                .span_addr {
                                    color: #666;
                                }
                            }
                        }
                        .ct-item:hover {
                            background-color: #f2f2f2;
                        }
                    }
                }
            }
        }


         // 页面弹窗
        .el-dialog {
          width: 850px;
          .el-table {
            margin-bottom: 30px;
          }
        }


  }
</style>
