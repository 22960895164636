<template>
	<div id="myDiscount">
		<div class="title-tabs bg-f">
			<ul class="clearfix">
				<template v-for='(tab,index) in dataTabs' class="item">
					<li :type='index' :class="type==index?'active':''" :key="index" @click='handleTabs'>
						{{tab}}
						<div :class="type==index?'under-line':''"></div>
					</li>
					<div class="vertical-line" :key="'d'+index"></div>
				</template>
			</ul>
		</div>
		<div class="main mt-5 bg-f mb-10 pb-30" v-loading="loading">
			<div class="pt-30 pl-30 clearfix">
				<!--内容为空时显示-->
				<div class="countBlank" v-if="couponList.length==0">
					<img src="../../assets/empty.png" alt="" />
					<p class="color-9 mt-15">优惠券空空如也</p>
				</div>
				<div class="list mr-10 mt-30 mb-10 fl" v-for="(coupon,index) in couponList" :key="index">
					<div v-if="coupon.couponState==1">
						<div class="top color-white pt-50 tac">
							<p>￥<span class="font-50 lh-50">{{coupon.minusValue}}</span></p>
							<p class="font-14 mt-5" :title="coupon.condition">「 <span class="w-190 text-overflow">{{coupon.condition}}</span> 」</p>
							<p class="font-12 mt-5">{{coupon.gmtCreate | timenData}} 到 {{coupon.gmtExpired | timenData}}</p>
						</div>
						<div class="bottom bg-f6">
							<p class="font-14 lh-14 color-9 w-200 ml-10 mt-20">{{coupon.couponName}}</p>
							<p class="font-14 color-9 ml-10 mt-15 lh-14"><span>适用区域：</span>{{coupon.storeName}}</p>
							<el-button class='bbo font-14 mt-40 ml-50 cursor' @click="goStore(coupon)">立即使用</el-button>
						</div>
					</div>
					<div v-if="coupon.couponState==2 || coupon.couponState==3">
						<div class="topL color-white pt-50 tac">
							<i class="iconfont icon-expire color-danger" v-if="coupon.couponState==2"></i>
							<i class="iconfont icon-already-use color-danger" v-if="coupon.couponState==3"></i>
							<p>￥<span class="font-50 lh-50">{{coupon.minusValue}}</span></p>
							<p class="font-14 mt-5">「 <span class="w-190 text-overflow">{{coupon.condition}}</span> 」</p>
							<p class="font-12 mt-5">{{coupon.gmtCreate | timenData}} 到 {{coupon.gmtExpired | timenData}}</p>
						</div>
						<div class="bottom bg-f6">
							<p class="font-14 lh-14 color-9 w-200 ml-10 mt-20">{{coupon.couponName}}</p>
							<p class="font-14 color-9 ml-10 mt-15 lh-14"><span>适用区域：</span>{{coupon.storeName}}</p>
						</div>
					</div>
				</div>
			</div>
			<el-pagination v-if="totalCount>0"
				class="tar mt-20"
          :current-page = "currentPage"
          :page-size = '8'
          :total = 'totalCount'
          layout = "prev , pager, next, jumper"
          @current-change='handleCurrentChange'
          >                      <!-- 分页组件 -->
      </el-pagination>
		</div>
	</div>
</template>

<script>
	import { axiosApi, basePath } from '../../api/api';
	export default{
		data(){
			return{
				dataTabs:['全部','未使用','已使用','已过期'],
				couponList:[],
				loading: false,
				totalCount:0,
				currentPage:1,
				Tab:' ',
				type:0
			}
		},
		methods:{
			handleTabs(e) {
//				if(e.target.type) this.type = e.target.type // 改变 type的值
				if(e.target.attributes.type) this.type = e.target.attributes.type.value
				if(this.type == '0'){
					this.Tab = ''
				}else if(this.type == '1'){ 
					this.Tab = '1'
				}else if(this.type == '2'){ 
					this.Tab = '3'
				}else if(this.type == '3'){		
					this.Tab = '2'
				}
				this.currentPage = 1;
				this.getCouponList();
			},
			//分页
			handleCurrentChange(val){
				this.currentPage = val;
	      this.getCouponList();
			},
			//获取优惠券列表
			getCouponList(){
				this.loading = true;
				const api = "/trader/findCouponList/select";
				const body = {buyerId:sessionStorage.traderId,couponState:this.Tab};
				axiosApi(api, body, (res)=>{
					//console.log(res,'优惠券列表')
					if(res.data.data) {
						this.loading = false;
						this.couponList = res.data.data.elements;
						this.totalCount = res.data.data.totalCount
					}else{
						this.loading = false;
						this.couponList = []
					}
				}, this.currentPage, 8,(res)=>{
					this.loading = false;
				})
			},
			goStore(coupon){
				this.$router.push(basePath+'/goodsSearch/classify/brand/goodsName');
			}
		},
		activated(){
			this.$nextTick(function(){
				this.getCouponList();
			})
		},
		mounted(){
			if(this.$route.query){
				this.type = this.$route.query.type;
				if(this.type == '0'){
					this.Tab = ''
				}else if(this.type == '1'){ 
					this.Tab = '1'
				}else if(this.type == '2'){ 
					this.Tab = '3'
				}else if(this.type == '3'){		
					this.Tab = '2'
				}
			}
		}
	}
</script>

<style lang="less">
	#myDiscount{
		.el-tabs__header{
			padding-left: 0;
		}
		.font-20{
			font-size: 20px;
		}
		.main{
			overflow: hidden;
			.list{
        .lh-50{
          line-height: 50px;
        }
				.top{
					width: 227px;
					height: 113px;
					background: url('../../assets/Combined Shape.png');
					.font-50{
						font-size: 40px;
          }
				}
				.topL{
					width: 227px;
					height: 113px;
					position: relative;
					background: url('../../assets/Combined Shape2.png');
					.font-50{
						font-size: 40px;
					}
					i{
						position: absolute;
						top: 10px;
						left: 10px;
						font-size: 35px;
          }
				}
				.bottom{
					width: 227px;
					height: 170px;
					overflow: hidden;
					letter-spacing:-0.51px;
					.w-200{
						width: 200px;
					}
					.bbo{
						border: 1px solid #74D2D4;
						border-radius: 48px;
						width: 120px;
						color: #74D2D4;
					}
				}
			}
			.countBlank{
				height: 400px;
				padding-top: 220px;
				text-align: center;
			}
			.w-190{
				display: inline-block;
				max-width: 190px;
				line-height: 14px;
			}
		}
	}
</style>