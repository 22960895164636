<template>
    <div class="package_detail bg-f">
        <div class="width-1200 plr-45 pb-50 border">
            <!--商品logo-->
            <div class="pt-50 ml-40 plr-45 clearfix">
				<span>
					<img v-if="mealDetail.fileUrl" :src="mealDetail.fileUrl" class="fl mr-20 wh90"/>
					<img v-else src="../../assets/goods.jpg" class="fl mr-20 wh90"/>
				</span>
                <div class="font-20 fl mt-5">
                    <p>{{mealDetail.skuName}}</p>
                    <span class="color-9 mt-10">市场价：{{mealDetail.msrp}}</span>
                </div>
            </div>

            <!--基本信息-->
            <div class="mt-50 font-14 ml-30">
                <el-form :model='ruleForm' :rules="rules" ref="ruleForm" label-width='140px'>
                    <!--商品图片-->
                    <el-form-item label="商品图片:" class='upload_img' prop='fileList'>
                        <input type="hidden" :disabled="flag"  v-model="ruleForm.fileList"/>
                        <el-upload :class="{'fileList': ruleForm.fileList.length >= 6 || flag,'check':flag}"
                                   action="/sunrise-gateway/oss/ossUpload" :file-list='ruleForm.fileList'
                                   list-type="picture-card"
                                   :disabled="flag"
                                   :on-preview="handlePictureCardPreview"
                                   :on-success="handleAvatarPhotoScucess"
                                   :before-upload="beforeAvatarUpload"
                                   :on-remove="handleRemove">
                            <i class="el-icon-plus"></i>
                        </el-upload>

                        <el-dialog :visible.sync="dialogVisible" size="tiny">
                            <img width="100%" :src="dialogImageUrl" alt="">
                        </el-dialog>
                    </el-form-item>
                    <!--商品名称-->
                    <el-form-item label="商品名称:" class='skuName' prop="skuName">
                        <el-input class="ml-20 w480" :disabled="flag" v-model.trim="ruleForm.skuName" placeholder="请输入商品名称"></el-input>
                    </el-form-item>
                    <!--副标题-->
                    <el-form-item label="副标题:" class="tit" prop="skuSubtitle">
                        <el-input class="ml-20 w480" :disabled="flag" v-model.trim="ruleForm.skuSubtitle" placeholder="请输入副标题"></el-input>
                    </el-form-item>
                    <!--商品编码-->
                    <el-form-item label="商品编码:" class="tit">
                        <el-input class="ml-20 w480" :disabled="flag" :maxlength="20" v-model.trim="ruleForm.goodsNo"
                                  placeholder="请输入商品编码"></el-input>
                    </el-form-item>
                    <!--最低起订量-->
                    <el-form-item label="最低起订量:" class="mt-30 unitNum" required prop='unitNum'>
                        <el-input size="mini" :disabled="flag" class="ml-20 mr-10" v-model.trim="ruleForm.unitNum"
                                  placeholder="最低起订量"></el-input>
                        <span class="font-14">套</span>
                    </el-form-item>
                    <!--套餐商品-->
                    <el-form-item label='' class='good_table'>
                        <p class="list">套餐商品:</p>
                        <div class="list_setprice">
                            <el-table :data='ruleForm.goodsList'>
                                <el-table-column label='商品编码' align='center' prop='goodsNo'>
                                    <template slot-scope="scope">
                                        {{ !flag && !isCityPartner ? scope.row.k3GoodsNo : scope.row.goodsNo }}
                                    </template>
                                </el-table-column>
                                <el-table-column label='商品名称' align='center' prop='skuName'></el-table-column>
                                <el-table-column label='数量' align='center' prop='num'></el-table-column>
                                <el-table-column label='价格(元)' align='center' :render-header="renderHeader">
                                    <template slot-scope="scope">
                                        <el-form-item :key='scope.row.id'
                                                      :rules="[
												{ required: true, message: '请输入商品价格'},
						            { pattern: /^\d{1,5}(\.\d{1,2})?$/, message: '价格为1-5位数字，小数支持2位'}
						          ]"
                                                      :prop="'goodsList['+scope.$index+'].price'"
                                        >
                                            <el-input class='wp100' :disabled="flag" v-model.trim='scope.row.price'></el-input>
                                        </el-form-item>
                                    </template>
                                </el-table-column>
                                <el-table-column label='单位' align='center' prop='unitName'></el-table-column>
                                <el-table-column label='规格' align='center'>
                                    <template slot-scope="scope">
                                        1*{{scope.row.specInfoNum}}
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                    </el-form-item>
                    <p class="tar mt-30 mr-30">套餐价格：<span class="color-danger font-16">¥{{totalPrice | fmoney}}</span>
                        /套</p>
                    <el-form-item label="建议零售价:" class="mt-30 unitNum" required prop='msrp'>
                        <el-input class="ml-20 mr-10" :disabled="flag" v-model.trim="ruleForm.msrp" placeholder="建议零售价"></el-input>
                        <span class="font-14">元/套</span>
                    </el-form-item>
                    <div class="mt-50 ml-55 pl-10">
                        <span class="ml-20 pl-50 color-9">温馨提示：请上传890px宽的图片</span>
                        <div class="clearfix">
                            <label class="mr-10 fl">图文说明:</label>
                            <!--<el-input type="textarea" v-model="ruForm.illustrationText" auto-complete="off" placeholder="这里是富文本"></el-input>-->
                            <ueditor ref="ueeditor" :disabled="flag" v-model="ruleForm.illustrationText"></ueditor>
                        </div>
                    </div>
                    <el-form-item class='btn_form_item mt-30'>
                        <el-button type='danger' @click='save' v-if="!flag" :loading='btnloading'>保存</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
	import {axiosApi, basePath} from "~/api/api"
	import {mapActions, mapMutations} from 'vuex'

	export default {
		data() {
			return {
				flag:false,
				mealDetail: {},// 套餐详情
				dialogVisible: false,
				dialogImageUrl: '',
				ruleForm: {
                    skuName:'',
					fileList: [],// 商品图片
					goodsList: [],
					illustrationText: '',// 富文本信息
					skuSubtitle: '',// 副标题
					unitNum: '1', // 最低起订量
					goodsNo:null,//商品编码
                    msrp:''
				},
				rules: {// 验证规则
					skuSubtitle: [{max: 30, message: '副标题最多只能支持30个字符'}],
					skuName: [{required: true, message: '请输入套餐名称'}, {min: 1, max: 50, message: '套餐名称最多只能支持50个字符'}],
					fileList: [{required: true, message: '请上传门店图片', type: 'array'},
						{min: 1, max: 6, type: "array", message: "请上传最多6张商品照片"}],
					unitNum: [ // 最低起订量
						{required: true, message: '请输入最低起订量'},
						{pattern: /^[1-9]\d{0,2}$/, message: '最大支持3位整数'},
					],
					msrp: [{required: true, message: '请输入建议零售价'}, {
						pattern: /^\d{1,5}(\.\d{1,2})?$/,
						message: '零售价格为1-5位数字，小数支持2位'
					}]
				},
				btnloading: false,// 按钮loading
			}
		},
		computed: {
            isCityPartner() {
                return this.$store.state.clientType.isCityPartner;
            },
			totalPrice() {
				let goodsList = this.ruleForm.goodsList;
				let total = 0;
				if(goodsList.length>0){
					total = goodsList.reduce((sum,value,index,goodsList) => {
						// console.log(sum +=goodsList[index].price*goodsList[index].num)
						return sum + goodsList[index].price * goodsList[index].num
					},0)
                }

				/*goodsList.forEach(i => {
					if (i.price) {
						return total += i.price * i.num
					} else {
						return total = 0
					}
				})*/
				return total ? total : 0
			}
		},
		created() {// 加载页面
			if(this.$route.query.flag){
				this.flag = this.$route.query.flag ? true : false;
            }
			if (this.$route.query.skuId) {// 套餐详情
				this.mealInfo()
					.then(res => {
						this.ruleForm.goodsNo = res.goodsNo;
						this.ruleForm.unitNum = res.minimunMoq// 最低起订量
						this.ruleForm.skuSubtitle = res.skuSubtitle
						this.ruleForm.skuName = res.skuName,
							this.ruleForm.goodsList = res.skuSetInfoVOList.map(i => {
								return Object.assign(i, {price: i.discountPrice != null ? i.discountPrice : ''})
							})
						this.ruleForm.fileList = res.skuShowPictureVOs.map(i => {
							return Object.assign(i, {url: i.fileUrl})
						})
						this.ruleForm.illustrationText = res.illustrationText
						this.ruleForm.msrp = res.msrp
						this.mealDetail = res
					})
					.catch(res => {

					})
			}
		},
		methods: {
			...mapMutations({
				'changeSetPriceForm': 'changeSetPriceForm'
			}),
			mealInfo() {// 套餐详情接口
				return new Promise((resolve, reject) => {
					axiosApi('/goods/setMeal/info/select', {
						sellerId: this.$route.query.id ? this.$route.query.id : sessionStorage.traderId,
						id: this.$route.query.skuId
					}, res => {
						resolve(res.data.data)
					}, 1, 10, res => {
						reject(res)
					})
				})
			},
			mealUpdate(body) {// 编辑套餐商品
				this.btnloading = true
				return new Promise((resolve, reject) => {
					axiosApi('/goods/setMeal/info/update', body, res => {
						resolve(res)
						this.btnloading = false
					}, 1, 10, res => {
						reject(res)
						this.btnloading = false
					})
				})
			},
			save() {// 保存套餐
				this.$refs.ruleForm.validate((valid) => {
					if (valid) {
						if (this.ruleForm.msrp < this.totalPrice) {// 零售价小于套餐价就显示套餐价return false
							this.$message.warning('建议零售价不能小于套餐价')
							return false
						}
						this.ruleForm.illustrationText = this.$refs.ueeditor.getContent();
						let body = {
							sellerId: sessionStorage.traderId,// 卖家Id
							skuName: this.ruleForm.skuName,//套餐名称
							skuType: 2,
							illustrationText: this.ruleForm.illustrationText,//图文说明的内容富文本
							skuSubtitle: this.ruleForm.skuSubtitle,// 副标题
							minimunMoq: this.ruleForm.unitNum,// 最小起订量
							id: this.$route.query.skuId,// 套餐Id
							msrp: this.ruleForm.msrp,// 建议零售价
                            goodsNo:this.ruleForm.goodsNo
						}
						body.skuSetInfoParamList = this.ruleForm.goodsList.map(i => {// 套餐包含商品列表
							return {
								skuId: i.skuId,// 商品id
								skuName: i.skuName,
								k3GoodsNo: i.k3GoodsNo,
								specInfoNum: i.specInfoNum,
								unitName: i.unitName,
								skuNo: i.skuNo,
								goodsNo:i.goodsNo,
								discountPrice: i.price,// 套餐对单个商品设价
								num: i.num// 套餐单个商品数量
							}
						})
						body.skuShowPictureParam = this.ruleForm.fileList.map(i => {// 套餐商品图片
							return {
								fileUrl: i.fileUrl,
								isDefault: 1,
								sort: 2
							}
						})
						this.mealUpdate(body)
							.then(res => {
								this.$message.success('编辑套餐成功！')
								this.$router.push({path: 'allProduct'})
							})
							.catch(res => {
								this.$message.error(res.data.message)
							})
					} else {
						return false
					}
				})
			},
			handlePictureCardPreview(file) {// 预览图片
				this.dialogImageUrl = file.url;
				this.dialogVisible = true;
			},
			handleAvatarPhotoScucess(res, file, fileList) {// 上传图片成功
				file.url = res.data[0];
				file.fileUrl = res.data[0];
				this.ruleForm.fileList = fileList
				this.$refs.ruleForm.validateField("fileList"); //部分表单字段验证
			},
			beforeAvatarUpload(file) {// 上传图片之前的验证
				return this.isUploadImg(file)
			},
			handleRemove(file, fileList) {// 移除上传图片
				this.ruleForm.fileList = fileList
				this.$refs.ruleForm.validateField("fileList");
			},
			// table title 星号必填标志
			renderHeader(h, {column, $index}) {
				let span =<span class="is-required" > {column.label} </span>
				return span
			}
		}
	}
</script>

<style lang="less">
    .package_detail {
        padding-top: 20px;
        .border {
            border: 1px solid #CCCCCC;
            box-sizing: border-box;
        }

        .wh90 {
            width: 90px;
            height: 90px;
        }

        .el-upload--picture-card {
            width: 90px;
            line-height: 90px;
            height: 90px;
        }

        .w480 {
            width: 480px;
        }

        .el-icon-plus {
            line-height: 90px;
        }

        .el-upload-list--picture-card .el-upload-list__item {
            width: 90px;
            height: 90px;
        }

        .upload_img {
            .el-form-item__content {
                margin-left: 160px !important;
            }
        }

        .list {
            width: 117px;
            text-align: left;
            margin-bottom: 30px;
        }

        .good_table {
            .el-form-item__content {
                margin-left: 58px !important;
            }
        }

        .btn_form_item {
            .el-form-item__content {
                margin-left: 56px !important;
            }
        }

        .fileList {
            .el-upload.el-upload--picture-card {
                display: none;
            }
        }

        .check .el-upload-list__item-actions .el-upload-list__item-delete{
                display: none;
        }

        /*调整最低起订量 错误提示位置*/

        .unitNum, .skuName {
            .el-form-item__content {
                .el-form-item__error {
                    margin-left: 20px;
                }
            }
        }

        .cell {
            .el-form-item {
                margin-top: 20px;
                margin-bottom: 20px;
            }

            .el-form-item__content {
                width: 130px;
                margin-left: 0 !important;

                .el-input {
                    width: 130px;
                }
            }
        }

    }
</style>
