<template>
    <div id="drinksManage" class="">
        <div class="title-tabs bg-f mb-5">
            <ul class="clearfix">
                <template v-for='(tab,index) in dataTabs' class="item">
                        <li :type='index' :class="type==index?'active':''" @click='handleTabs'>
                            {{tab}}
                            <div :class="type==index?'under-line':''"></div>
                        </li>
                    <div class="vertical-line"></div>
                </template>
            </ul>
        </div>
        <cabinet-view v-if="type==0"></cabinet-view>
        <self-view v-else></self-view>

    </div>
</template>

<script>
    import cabinetView from './cabinetView'
    import selfView from './selfView'

    export default {
        components: {
            cabinetView,
            selfView
        },
        data() {
            return {
                dataTabs: ['久柜酒水管理', '自营啤酒饮料管理'],
                type: 0,
            }
        },
        methods: {
            handleTabs(e) {
                if (e.target.attributes.type) this.type = e.target.attributes.type.value;
                if (this.type == 0) {
                    // todo
                } else if (this.type == 1) {

                }
                setTimeout(() => {
                }, 0)
            }
        },
        //进入加载
        activated() {
            this.$nextTick(function () {

            })
        }
    }
</script>

<style lang="less">
    #drinksManage {

    }
</style>