<template>
	<div id="news" class="">
    <!-- 搜索控件 -->
		<div>
			<el-form :inline="true" :model="formInlineData"  label-width="auto" class="clearfix" row-key="id">
				<el-form-item label="新闻标题">
						<el-input v-model.trim="formInlineData.name"></el-input>
					</el-form-item>
				<el-form-item label="文章分类:">
					<el-select v-model.trim="formInlineData.classifyId" placeholder="请选择" clearable>
						<el-option
							v-for="item in classifyList"
							:key="item.id"
							:label="item.classifyName"
							:value="item.id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="info" class="fl ml-50" @click='search' icon="el-icon-search">搜 索</el-button>
				</el-form-item>
			</el-form>
			<div style="text-align:right">
				<el-button @click="addnews">新增新闻</el-button>
			</div>
			<el-table :data="newsList" stripe tooltip-effect="dark" class="mt-10 table-default" width="100px" >
				<el-table-column label="新闻标题">
					<template slot-scope="scope" style="width: 200px;">
						<div>{{scope.row.name}}</div>
					</template>
				</el-table-column>

				<el-table-column label="新闻首图" >
					<template slot-scope="scope">
						<img :src="scope.row.pictureFileUrl" alt=""  style="width: 200px; height: 120px;">
					</template>
				</el-table-column>

				<el-table-column label="发布时间">
					<template slot-scope="scope">
						<div>{{scope.row.issueDate | timenyr}}</div>
					</template>
				</el-table-column>
				<el-table-column label="新闻类型">
					<template slot-scope="scope">
						<div>{{scope.row.classifyName}}</div>
					</template>
				</el-table-column>
               



				<el-table-column label="操作">
					<template slot-scope="{row}">
							<el-link type="primary" @click="editNews(row)">编辑</el-link>
							<el-link type="danger" @click="submitDelete(row.id)">删除</el-link>
					</template>
				</el-table-column>
			</el-table>

			<el-pagination class="tar mr-30" :current-page="currentPage" v-if="total>0" :page-size='10' :total='total' layout="prev , pager, next, jumper" @current-change="handleSearch">
				<!-- 分页组件 -->
			</el-pagination>
		</div>
		
	</div>

</template>

<script>
	import { axiosApi, basePath,basePath1 } from "~/api/api"
  import { getNewsClassList,deleteNews } from "~/api/news"
	export default {
		data() {
			return {
				active:'1',
				//搜索数据
				formInlineData: {
					name: null,        //新闻标题
				  classifyId: null,  //新闻类型
				},
				
				form: {
					id:null,
          name:'',//文章名称
          classifyId:'',//分类id
          pictureFileUrl:'',//文章首图
          issueDate:'',//发布日期
          illustrationText:'',//文章内容
          outline:'',//文章摘要
					image:[],
				},

        classifyList:[],

        newsList:[],//新闻列表
        loading:false,
        total:0,
        currentPage: 1,

			}
		},
		methods: {
      getNewsClassList(){
        getNewsClassList({companyTraderId:sessionStorage.traderId}).then(res=>{
					var list = [{classifyName:'全部', id:null}, ...res]
          this.classifyList = list
        }).catch(err=>{})
      },
      //添加新闻
      addnews() {
        this.$router.push({
          path: basePath1 + '/articleDetails',
        })
      },
			search(){
				this.currentPage = 1;
        this.queryNews();
			},
      //分页查询
      handleSearch(val){
          this.currentPage = val;
          this.queryNews();
      },
      //查询
      queryNews(){
          this.loading = true;
          axiosApi('/unlogin/official/article/page', {companyTraderId:sessionStorage.traderId,name:this.formInlineData.name,classifyId:this.formInlineData.classifyId},res => {
              
              this.newsList = res.data.data.elements;
              this.total = res.data.data.totalCount;
              this.loading = false;
          },this.currentPage,10,err => {
              this.$message.warning(err.data.message)
          })
      },
      //编辑
      editNews(item){
        setTimeout(() => {
          // let arr = []
          // arr.push({ url: item.image })
          // this.form = Object.assign({}, item, { image: arr })
          // sessionStorage.setItem('form', JSON.stringify(this.form))
          // sessionStorage.setItem('currentPage', this.currentPage)
          this.$router.push({
            path: basePath1 + '/articleDetails',
            query: {
							id: item.id
              // isNew: 0,
              // titleTop: '编辑文章'
            }
          })
        }, 0)
      },
			submitDelete(id){
				this.$confirm('此操作将删除该新闻, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.deleteNews(id)
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});          
				});
			},
			 deleteNews(id){
				deleteNews({id:id}).then(res=>{
					this.$message({
						type: 'success',
						message: '删除成功!'
					});
					this.search()
				}).catch(err=>{})
			},
    },
		activated(){
			 this.getNewsClassList()
       this.search()
		},
    mounted(){
      this.$nextTick(() => {
        this.getNewsClassList()
        this.search()
      })
    }
	}
</script>

<style lang="less">
	#news {
		padding-left: 15px;
		padding-bottom: 50px;
		.disabled {
			color: gray;
		}
		.el-dialog {
			width: 800px!important;
		}
		.ue-editor {
			transform: translateX(0);
		}
		.border {
			border: 1px solid #EEEEEE;
			background-color: #EEEEEE;
		}
		.search {
			width: 171px;
			height: 33px;
		}
		.select {
			width: 130px;
			height: 33px;
		}
		.el-dialog {
			width: 650px;
		}
		.inputTitle {
			width: 351px;
		}
		.inputSort {
			width: 135px;
		}
		.classify-input .el-input {
			width: 130px;
		}
		.textarea {
			width: 350px;
			height: 115px;
		}
		.el-dialog__footer {
			position: relative;
			background-color: #fff;
			padding: 10px 0;
			margin-top: -10px;
		}
		.noAdd {
			.el-upload.el-upload--picture-card {
				display: none;
			}
		}
		.add {
			.el-upload.el-upload--picture-card {
				display: inline-block;
			}
		}
	}
</style>
