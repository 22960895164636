<template>
    <div id="allProduct" class="pb-50" v-loading='loading'>
        <div class="title-top bg-f mb-5">
            商品设置
        </div>
        <div class="bg-f pr-30 pl-30 pt-20 pb-20" style="min-height: 500px">
            <el-form ref="form" :model="formSet" label-width="auto">
                <el-form-item label="是否默认显示计量单位价格:">
                    <el-switch v-model="formSet.priceUnit" @change="handlePaymentSwitch" active-text="开" inactive-text="关" :active-value="1" :inactive-value="2" active-color="#13ce66" inactive-color="#ccc">
                    </el-switch>
                </el-form-item>
                <el-form-item label="是否默认显示客户的非协议商品:">
                    <el-switch v-model="formSet.nonAgreementGoodsEnable" @change="changeSwitch" active-text="开" inactive-text="关" :active-value="true" :inactive-value="false" active-color="#13ce66" inactive-color="#ccc">
                    </el-switch>
                </el-form-item>
                <el-form-item label="是否对客户显示对账单数据:">
                    <el-switch v-model="formSet.openBuyerFreeView" @change="changeBuyerFreeView" active-text="开" inactive-text="关" :active-value="true" :inactive-value="false" active-color="#13ce66" inactive-color="#ccc">
                    </el-switch>
                </el-form-item>
                <el-form-item label="是否自动同步新增的商品为赠品:">
                    <el-switch v-model="formSet.openSyncGift" @change="changeIsGift" active-text="开" inactive-text="关" :active-value="true" :inactive-value="false" active-color="#13ce66" inactive-color="#ccc">
                    </el-switch>
                </el-form-item>
                <!-- <el-form-item label="商品单价优先获取最近销售价:">
                    <el-switch v-model="formSet.recentPurchasePrice" @change="handleRecentPurchasePriceSwitch" active-text="开" inactive-text="关" :active-value="1" :inactive-value="0" active-color="#13ce66" inactive-color="#ccc">
                    </el-switch>
                </el-form-item> -->
            </el-form>
        </div>
    </div>
</template>

<script>
    import {axiosApi} from "../../api/api";
    import { updateIsOpenSyncGift,updateIsOpenBuyerFreeView } from "~/api/setting";
    export default {
        data() {
            return {
                formSet:{
                    priceUnit:1,
                    nonAgreementGoodsEnable: false,
                    openSyncGift: false,
                    openBuyerFreeView: false
                },
                type: '0',
                loading: false,
                isChange:false,
                
            }
        },
        methods: {
            // 商品单价优先获取最近销售价
            // 云商3.5.1功能中去掉
            // handleRecentPurchasePriceSwitch(val){
            //     axiosApi('/trader/sellerRecentChosenPrice/update', {
            //         traderId: sessionStorage.getItem('traderId'),
            //         recentPurchasePrice: val,
            //     },(res) => {
            //         this.$message.success('设置成功')
            //     },1,10,err=>{
            //         this.$message.error('设置失败')
            //         this.formSet.recentPurchasePrice = val == 1 ? 0 : 1
            //     })
            // },
            // 是否开启商品按瓶设价
            handlePaymentSwitch(val) {
                axiosApi('/trader/sellerIsOpenOnlinePay/update', {
                    traderId: sessionStorage.getItem('traderId'),
                    priceUnit: val,
                },(res) => {
                    this.$message.success('设置成功')
                },1,10,err=>{
                    this.$message.error('设置失败')
                    this.formSet.priceUnit = val == 1 ? 2 : 1
                })
            },
            changeSwitch(val){
                axiosApi('/trader/sellerIsOpenAgreementGoodsEnable/update', {
                    traderId: sessionStorage.getItem('traderId'),
                    nonAgreementGoodsEnable: val,
                },(res) => {
                    this.$message.success('设置成功')
                },1,10,err=>{
                    this.$message.error('设置失败')
                    this.formSet.nonAgreementGoodsEnable = !val
                })
            },
            findTraderInfoBySerllerId() {// 查询商品是否开通同步K3
                return new Promise((resolve, reject) => {
                    axiosApi('/trader/findTraderInfoBySerllerId/select', {
                        'traderId': sessionStorage.getItem('traderId')
                    }, (res) => {
                        resolve(res)
                    }, 1, 10, err => {
                        reject(err)
                    })
                })
            },
            
            changeIsGift(val){
                // 是否自动同步新增的商品为赠品
                updateIsOpenSyncGift({openSyncGift:this.formSet.openSyncGift}).then(res=>{
                    this.$message.success('设置成功')
                }).catch(err=>{
                    this.$message.error('设置失败')
                    this.formSet.openSyncGift = !val
                })
            },
            changeBuyerFreeView(val){
                updateIsOpenBuyerFreeView({openBuyerFreeView:this.formSet.openBuyerFreeView}).then(res=>{
                    this.$message.success('设置成功')
                }).catch(err=>{
                    this.$message.error('设置失败')
                    this.formSet.openBuyerFreeView = !val
                })
            },
        },
        //进入加载
        activated() {
            this.$nextTick(function() {
                // this.findSellerJurisdiction();
                this.findTraderInfoBySerllerId().then(res => {
                    this.formSet.priceUnit = res.data.data.priceUnit
                    this.formSet.recentPurchasePrice = res.data.data.recentPurchasePrice ? 1 : 0;
                    this.formSet.nonAgreementGoodsEnable = res.data.data.nonAgreementGoodsEnable;
                    this.formSet.openSyncGift = res.data.data.openSyncGift
                    this.formSet.openBuyerFreeView = res.data.data.openBuyerFreeView
                })
            })
        },
        mounted(){
            // if(this.$route.query.type){
            //     this.type = this.$route.query.type
            //     this.$forceUpdate()
            // }
        },
        watch:{
            $route:{
                handler: function(a){
                    console.log(a)
                    if(a.query){
                        this.type = a.query.type
                    }
                },
                deep:true
            }
        }
    }
</script>

<style lang="less">
    #allProduct {
        .bb {
            border-bottom: 1px solid #DDDDDD;
        }
        .order_status {
            height: 60px;

            li {
                box-sizing: border-box;
                height: 26px;
                line-height: 26px;
                padding-left: 10px;
                padding-right: 10px;
                list-style-type: none;
                margin-top: 17px;
                margin-right: 10px;
                cursor: pointer;
                user-select: none;
                border-radius: 2px;

                &:hover {
                    color: #ff4201;
                }
            }

            .active {
                border: 1px solid #ff4201;
                color: #ff4201;
            }
        }
        .el-dialog{
            width: 50%;
            padding: 0 30px;
        }
        .el-dialog__body{
            padding-top: 20px;
        }
        .table-default .el-button {
            width: auto;
        }
        .bb {
            border-bottom: 1px solid #DDDDDD;
        }
        .c {
            position: relative;
        }
        .order_status {
            height: 60px;

            li {
                box-sizing: border-box;
                height: 26px;
                line-height: 26px;
                padding-left: 10px;
                padding-right: 10px;
                list-style-type: none;
                margin-top: 17px;
                margin-right: 10px;
                cursor: pointer;
                user-select: none;
                border-radius: 2px;

                &:hover {
                    color: #ff4201;
                }
            }

            .active {
                border: 1px solid #ff4201;
                color: #ff4201;
            }
        }
        .count4 {
            position: absolute;
            left: 235px;
            top: 15px;
            z-index: 10;
        }

        .el-tabs__header {
            padding-left: 0;
        }

        .count2 {
            position: absolute;
            left: 308px;
            top: 15px;
            z-index: 10;
        }

        .count3 {
            position: absolute;
            left: 435px;
            top: 15px;
            z-index: 10;
        }

        .red {
            color: red;
        }

        .w-400 {
            width: 400px;
        }

        .w-160 {
            width: 160px;
        }

        .el-dialog {
            min-height: 300px;
        }

        .width-70 {
            width: 70px;
        }

        .black {
            .edit-btn span {
                color: #000000 !important;
            }
        }

        .obt {
            margin-top: 80px;
            margin-right: 60px;
        }
    }
</style>
