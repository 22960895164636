<template>
    <div id="hotel-edit-box">
        <div class="m-content width-1280">
            <div class="m-title">折扣维护-编辑</div>
            <div class="m-detail">
                <div class="m-item">
                    <div class="m-item-label">客户名称：</div>
                    <div class="m-item-content">{{customerName}}</div>
                </div>
                <div class="m-item">
                    <div class="m-item-label">折扣生效日期：</div>
                    <div class="m-item-content">
                        <el-date-picker v-if="type === 1" v-model="timeStart" placeholder="开始时间"
                                        :picker-options="pickerOptions">
                        </el-date-picker>
                        <span v-else>{{timeStart | timenData}}</span>
                        <span> - </span>
                        <el-date-picker v-model="timeEnd" placeholder="结束时间" :picker-options="pickerOptions2"/>
                    </div>
                </div>
                <div class="m-item">
                    <div class="m-item-label">状态：</div>
                    <div class="m-item-content red">{{state}}</div>
                </div>
                <!--<div class="m-item clearfix">
                    <el-form :inline='true' class="fl search" :model="searchForm">
                        <el-form-item label='商品编码'>
                            <el-input v-model.trim='searchForm.goodsNo'></el-input>
                        </el-form-item>
                        <el-form-item label='商品名称' class='ml-20 mr-10'>
                            <el-input v-model.trim="searchForm.skuName"></el-input>
                        </el-form-item>
                        <el-form-item label='商品分类' class='ml-20'>
                            <el-select v-model='searchForm.classifyId' placeholder='请选择' clearable>
                                <el-option v-for='(item,i) in classifylist' :key='i' :label='item.classifyName'
                                           :value='item.id'></el-option>
                            </el-select>
                        </el-form-item>
                    </el-form>
                    <el-button class="fl ml-30" type='info' @click="handleQueryBtn">查 询</el-button>
                </div>-->
                <div style="text-align:right;">
                    <el-button type="text" @click="openSyncPackageGoodDialog(true)" v-if="!flag">套餐商品同步</el-button>
                    <!-- <el-button type="text" v-if="ruleForm.tableListGoods.length>0" @click="moqVisible=true">统一设置起订量
                    </el-button> -->
                    <el-button type="text" @click="handleAddGoods">添加商品</el-button>
                    <el-button type="text" @click="exportData" v-if="!flag">导出数据</el-button>
                </div>
            </div>
            <!--统一设置起订量弹出框-->
            <el-dialog title="统一设置起订量" class="moqdialog" :visible.sync="moqVisible" @close="closeMoqDialog">
                <el-form :model="minimunMoqForm" ref="minimunMoqForm" :rules="rules">
                    <el-form-item label="最低起订量:" prop="num" style="width: 100%;">
                        <el-input class="ml-10" size="mini" v-model="minimunMoqForm.num"></el-input>
                        <el-select class='unit_select_dialog' v-model='minimunMoqForm.unit' placeholder='请选择'>
                            <el-option v-for='(item) in unitOptions' :key='item.value' :label='item.label'
                                       :value='item.value'></el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button type="primary" @click="moqcomfirm">确 定</el-button>
                    <el-button @click="moqVisible=false">取 消</el-button>
                </div>
            </el-dialog>
            <div class="m-goods-table">
                <el-form :model="ruleForm" ref="ruleForm">
                    <el-table v-loading="loading" border :data="ruleForm.tableListGoods" tooltip-effect="dark" stripe
                              class="mt-20 table-default">
                        <template slot="empty">
                            <img src="../../assets/empty.png"/>
                            <p>您的商品列表空空如也，现在添加您第一件商品吧！</p>
                            <div class="mt-10">
                                <el-button style="width:90px;height:32px;" type="primary" @click="handleAddGoods">添加商品
                                </el-button>
                            </div>
                        </template>
                        <el-table-column label="商品名称" width="230">
                            <template slot-scope="scope">
                                <div class="m-goods-table-goods-name">
                                    <div class="m-left">
                                        <img v-if="scope.row.fileUrl" :src="scope.row.fileUrl"/>
                                        <img v-else src="../../assets/goods.jpg"/>
                                    </div>
                                    <div class="m-right">
                                        <p style="margin-bottom:15px;" class="mt-10">商品编码：{{scope.row.goodsNo}}</p>
                                        <p>{{scope.row.skuName}}</p>
                                    </div>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="规格型号" width="60">
                            <template slot-scope="scope">
                                <span>1 * {{scope.row.specInfoNum}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="商品分类" width="60">
                            <template slot-scope="scope">
                                {{ scope.row.classifyName }}
                            </template>
                        </el-table-column>
                        <el-table-column label="最低起订量" :render-header="renderHeader" width="170">
                            <template slot-scope="scope">
								<div class="moq_show" v-if="!scope.row.moqStatus">
									<span>{{scope.row.tableForm.miniMoq||'--'}}{{scope.row.skuType===2?'套':scope.row.unitsName}}</span>
									<span v-if="scope.row.skuType!==2">
										(合{{ Math.ceil(scope.row.tableForm.miniMoq / scope.row.specInfoNum) }} {{scope.row. sellingUnitName }})
									</span>
								</div>
                            </template>
                        </el-table-column>
                        <el-table-column label="会员价(元)" :render-header="renderHeader" width="140">
                            <template slot-scope="scope">
                                <!--套餐商品-->
                                <el-form-item v-if='scope.row.skuType===2' :key="scope.row.id"
                                              :prop="'tableListGoods['+scope.$index+'].promotionPrice'"
                                              class="pb-20"
                                              :rules="[
                                    { required: true, message: '请输入会员价'},
                                    { pattern: /^\d{1,5}(\.\d{1,2})?$/, message: '会员价为1-5位数字，小数支持2位'},
                                    { validator: (rule, value, callback) => {validator2(rule, value, callback,scope.$index)}}
                                ]">
									<el-input disabled style="width:80px;" placeholder="请输入"
                                              v-model.trim="scope.row.promotionPrice"/>
                                    <span>/套</span>
                                </el-form-item>
                                <!--普通sku商品-->
                                <el-form-item v-else
                                              :key="scope.row.id"
                                              :rules="[
													{ required: true, message: '请输入会员价'},
													{ pattern: /^\d{1,5}(\.\d{1,2})?$/, message: '会员价为1-5位数字，小数支持2位'},
													{ validator: (rule, value, callback) => {validator2(rule, value, callback,scope.$index)}}
												]"
                                              :prop="'tableListGoods['+scope.$index+'].tableForm.promotion'"
                                              class="pb-20"
                                              style="width:120px;margin:0 auto;">
										<div>
											<el-input style="width:80px;" placeholder="请输入" @change="changeUnitPromotion(scope.$index)"
                                              v-model.trim="scope.row.tableForm.unitPromotionPrice"/>
                                    		<span>/{{  scope.row.unitsName }}</span>
										</div>
										<div>
											<el-input style="width:80px;" placeholder="请输入"  @change="changePromotion(scope.$index)"
                                              v-model.trim="scope.row.tableForm.promotion"/>
											<span>/{{ scope.row.sellingUnitName  }}</span>
										</div>
                                </el-form-item>
                            </template>
                        </el-table-column>

                        <el-table-column label="折扣/返利" :render-header="renderHeader" width="200">
                            <template slot-scope="scope">
                                <div class="tal">
                                    <div class="mb-20">
                                        <el-radio :disabled="scope.row.skuType===2"
                                                  @click.native="handleRadio(scope.$index)" class="radio"
                                                  v-model="scope.row.tableForm.discountType" :label="1">折扣优惠
                                        </el-radio>
                                        <el-form-item
                                                v-if="scope.row.tableForm.discountType===1 "
                                                :key="scope.row.id"
                                                class="m-inline pb-10"
                                                :rules="[
                                                { required: scope.row.tableForm.discountType === 1  , message: '请输入折扣优惠'},
                                                { pattern: scope.row.tableForm.discountType === 1 ? /^(1|0\.(0[1-9]|[1-9]{1,2}|[1-9]0)$)$/ : '', message: '请填写0-1之间的数字包括1'},
                                            ]"
                                                :prop="'tableListGoods['+scope.$index+'].tableForm.unitDiscountValue'"
                                        >
                                            <el-input style="width:80px;" placeholder=""
                                                      v-model.trim="scope.row.tableForm.unitDiscountValue"
                                                      :disabled="scope.row.tableForm.discountType === 2||scope.row.skuType===2"
                                            />
                                        </el-form-item>
                                    </div>
                                    <div class="mb-10">
                                        <el-radio :disabled="scope.row.skuType===2"
                                                  @click.native="handleRadio2(scope.$index)" class="radio"
                                                  v-model="scope.row.tableForm.discountType" :label="2">固定返利
                                        </el-radio>
                                        <el-form-item
                                                v-if="scope.row.tableForm.discountType===2"
                                                :key="scope.row.id"
                                                class="m-inline pb-10"
                                                :rules="scope.row.skuType === 2?[{required: scope.row.tableForm.discountType === 2 , message: '请编辑'}]:[
                                                { required: scope.row.tableForm.discountType === 2 , message: '请输入固定返利'},
                                                { pattern: scope.row.tableForm.discountType === 2 ? /^(?:0\.\d{1,2}|[1-9]\d{0,2}(?:\.\d{1,2})?|1000|0)$/ : '', message: '请填写0-1000之间的数字'},
                                                { validator: (rule, value, callback) => {validator(rule, value, callback,scope.$index)}}
                                            ]"
                                                :prop="'tableListGoods['+scope.$index+'].tableForm.unitDiscountValue'"
                                        >
                                            <el-input style="width:80px;" placeholder=""
                                                      v-model.trim="scope.row.tableForm.unitDiscountValue"
                                                      :disabled="scope.row.tableForm.discountType === 1||scope.row.skuType===2"
                                            />
                                            <span>/{{ scope.row.unitsName }}</span>
                                        </el-form-item>
                                        <div class="ml-85 mt-30 font-14"
                                             v-if='scope.row.skuType!==2 && scope.row.tableForm.discountType===2'>
											 {{ scope.row.tableForm.unitDiscountValue * scope.row.specInfoNum | fmoney }}/{{ scope.row. sellingUnitName }}
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="loginName" label="瓶盖费(元)" :render-header="renderHeader" width="120">
                            <template slot-scope="scope">
                                <el-form-item
                                        :key="scope.row.id"
                                        :rules="[
                                        { required: true, message: '请输入瓶盖费'},
                                        { pattern: /^\d{1,5}(\.\d{1,2})?$/, message: '请输入1-5位数字'},
                                    ]"
                                        :prop="'tableListGoods['+scope.$index+'].tableForm.capFee'"
                                        class="pb-20"
                                >
                                    <el-input :disabled="scope.row.skuType===2" style="width:60px;" placeholder="请输入"
                                              v-model.trim="scope.row.tableForm.capFee"/>
                                </el-form-item>
                            </template>
                        </el-table-column>
                        <el-table-column label="是否停售">
                            <template slot-scope="scope">
                                <div class="tac font-14">
                                    <p>{{ scope.row.skuState == 1 ? '停售' : '出售' }}</p>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="是否可见">
                            <template slot-scope="scope">
                                <div class="tac font-14">
                                    <p>{{ scope.row.isVisible ? '可见' : '不可见' }}</p>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="操作" width="60">
                            <template slot-scope="scope">
                                <div>
                                    <el-button type="text" v-if="scope.row.skuType===2"
                                               @click="handleEdit(scope.row,scope.$index)"
                                               class="color-blur">编辑
                                    </el-button>
                                </div>
                                <div>
                                    <el-button type="text" class="color-blur"
                                               @click="handleDelete(scope.row,scope.$index)">删除
                                    </el-button>
                                </div>
                                <div v-if="isCityPartner">
                                    <div v-if="scope.row.skuState">
                                        <el-button type="text" class="color-blur"
                                                   v-if="scope.row.skuState == 1 && scope.row.skuType!==2 && !scope.row.add"
                                                   @click="setSkuState(scope.row)">出售
                                        </el-button>
                                        <div v-else>
                                            <el-button type="text" class="color-blur"
                                                       v-if="scope.row.skuType!==2 && !scope.row.add"
                                                       @click="setSkuState(scope.row)">停售
                                            </el-button>
                                        </div>
                                        <el-button type="text" @click="setVisibile(scope.row,1)"
                                                   v-if="scope.row.skuType!==2 && !scope.row.isVisible && !scope.row.add"
                                                   class="color-blur">
                                            可见
                                        </el-button>
                                        <el-button type="text" @click="setVisibile(scope.row,0)"
                                                   v-if="scope.row.skuType!==2 && scope.row.isVisible && !scope.row.add"
                                                   class="color-blur">
                                            不可见
                                        </el-button>
                                    </div>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                    <!-- 商品个数备注 -->
                    <div class="mt-20 color-9">备注: 常规商品{{generalGoodsnum}}个，套餐商品{{packageGoodsnum}}个</div>
                </el-form>
            </div>
            <affix ref="affix" z-index="10" :offsetBottom="0">
                <div class="mt-20 m-submit-box bg-e">
                    <el-button class="m-submit" @click="handleSubmit" type="primary">提交</el-button>
                    <el-button @click="handleGoHome">返回</el-button>
                </div>
            </affix>
        </div>
        <!-- 套餐商品同步 -->
        <sync-package-goods :sellerId='sellerId' :buyerId="buyerId" @update="updateK3"></sync-package-goods>
        <!--统一设置起订量弹出框-->
        <el-dialog title="统一设置起订量" class="moqdialog" :visible.sync="moqVisible" @close="closeMoqDialog">
            <el-form :model="minimunMoqForm" ref="minimunMoqForm" :rules="rules">
                <el-form-item label="最低起订量:" prop="num" style="width: 100%;">
                    <el-input class="ml-10" size="mini" v-model="minimunMoqForm.num" :maxlength="3"></el-input>
                    <el-select class='unit_select_dialog' v-model='minimunMoqForm.unit' placeholder='请选择'>
                        <el-option v-for='(item) in unitOptions' :key='item.value' :label='item.label'
                                   :value='item.value'></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="moqcomfirm">确 定</el-button>
                <el-button @click="moqVisible=false">取 消</el-button>
            </div>
        </el-dialog>

        <el-dialog title="选择商品" :visible.sync="dialog1Visible" id="goods" @close="handleClose">
            <div v-loading="tableloading">
                <el-form :inline="true" :model="skuObj" label-width="80px" class="clearfix" :style="{width:'850px'}">
                    <div class="fl">
                        <el-form-item label="商品编码:">
                            <el-input v-model.trim="skuObj.skuNo" @input="skuObj.skuNo=skuObj.skuNo.replace(/[\u4e00-\u9fa5]/ig,'')"></el-input>
                        </el-form-item>
                        <el-form-item label="商品名称:">
                            <el-input v-model.trim="skuObj.skuName"></el-input>
                        </el-form-item>
                        <el-form-item label="商品分类:">
                            <el-select v-model='skuObj.classifyId' placeholder='请选择' clearable>
                                <el-option v-for='(item) in classifylist' :key='item.id'
                                           :label='item.classifyName'
                                           :value='item.id'></el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                    <el-button type="primary" class="fl ml-50" @click="querygood">查询</el-button>
                </el-form>
                <el-table @selection-change="handleSelectionChange" :data="proData" stripe border tooltip-effect="dark"
                          class="mt-10 table-default m-table">
                    <el-table-column type="selection" width="50">
                    </el-table-column>
                    <el-table-column label="商品图片">
                        <template slot-scope="scope">
                            <img v-if="scope.row.fileUrl" :src="scope.row.fileUrl"/>
                            <img v-else src="../../assets/goods.jpg"/>
                        </template>
                    </el-table-column>
                    <el-table-column label="商品编码" prop='skuNo'>
                        <template slot-scope="scope">
                            <span>{{ scope.row.goodsNo }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="商品名称" prop='skuName' width="350" show-overflow-tooltip>
                    </el-table-column>
                    <!-- <el-table-column label="单位" prop=' sellingUnitName'>
                    </el-table-column> -->
                    <el-table-column label="分类" prop='classifyName'>
                    </el-table-column>
                </el-table>
                <el-pagination class="tar" v-if='total>0'
                               :current-page="currentPageGoods"
                               :page-size='10'
                               :total='total'
                               layout="prev , pager, next, jumper"
                               @current-change="handlePageChange">
                </el-pagination>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="addGoods" :loading="btnLoading">确 定</el-button>
                <el-button @click="dialog1Visible = false">取 消</el-button>
            </div>
        </el-dialog>

        <el-dialog :visible.sync="dialogVisiable"
                   class="mdialog"
                   :before-close="handleDialogClose"
                   :close-on-press-escape="false"
                   :close-on-click-modal="false"
                   title='设价'
        >
            <el-form ref="setPriceForm" class="set_price" :model='form'>
                <el-table border :data='form.goodsList'>
                    <el-table-column label='商品编码' align='center' prop='skuNo'></el-table-column>
                    <el-table-column label='商品名称' align='center' prop='skuName'></el-table-column>
                    <el-table-column label='数量' align='center' prop='num'></el-table-column>
                    <el-table-column label='价格(元)' align='center' width="150" :render-header="renderHeader"
                                     class="price">
                        <template slot-scope="scope">
                            <el-form-item :key='scope.row.id'
                                          :rules="[
											{ required: true, message: '请输入商品价格'},
					            { pattern: /^\d{1,5}(\.\d{1,2})?$/, message: '价格为1-5位数字，小数支持2位'}
					          ]"
                                          :prop="'goodsList['+scope.$index+'].discountPrice'"
                            >
                                <el-input v-model.trim='scope.row.discountPrice'
                                          @change='changePrice'></el-input>
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column label='单位' align='center' prop='unitName'></el-table-column>
                    <el-table-column label='规格' align='center'>
                        <template slot-scope="scope">
                            1*{{scope.row.specInfoNum}}
                        </template>
                    </el-table-column>
                </el-table>

                <p class='mt-15 tar pr-30'>套餐价格：<span class="font-16 color-danger">{{totalPrice | fmoney}}</span> /套</p>
                <div class="mt-15 clearfix">
                    <div class="fl lh-32 mr-15">
                        备注:
                    </div>
                    <div class="fl">
                        <el-input class='w480' v-model='form.remark'></el-input>
                    </div>
                </div>
                <el-form-item label="会员价:" class="mt-30 msrp" style="width: 400px;" :rules="[{ required: true, message: '请输入会员价'},
		        	{ pattern: /^\d{1,5}(\.\d{1,2})?$/, message: '会员价格为1-5位数字，小数支持2位'},
		        	{ validator: (rule, value, callback) => {valideMsrp(rule, value, callback)}}]" prop='msrp'>
                    <el-input class="ml-20 mr-10" v-model.trim="form.msrp" placeholder="请输入会员价"></el-input>
                    <span class="font-14">元/套</span>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" :loading="btnLoading" @click="saveMeal">确 定</el-button>
                <el-button @click='handleDialogClose'>取 消</el-button>
            </div>
        </el-dialog>

        <!-- form 导出数据 -->
        <form ref="form" action='/sunrise-gateway/export/hotel/agreementGoods/list' style="display: none">
            <input type="hidden" name="agreementId" v-model="a">
            <input type="hidden" name="modelName" v-model="b">
            <input type="hidden" name="fileName" v-model="c">
        </form>
    </div>
</template>

<script>
	import {mapMutations, mapActions} from 'vuex';
	import {axiosApi, basePath} from "../../api/api";
	import affix from './affix.vue';
	import goods from './component-goods.vue';
	// import syncPackageGoods from './syncPackageGoods.vue'

	export default {
		components: {
			affix,
			goods,
			// syncPackageGoods
		},
		data() {
			let _this = this;
			return {
				changeFlag: false,
				generalGoodsnum: 0,// 普通商品数量
				packageGoodsnum: 0,// 套餐商品数量
				isOpenUnitPrice: false,
				currentPage: 1,
				currentPageGoods: 1,
				a: this.$route.query.id || '',
				b: 'hotel.agreement.goods',
				c: '酒店商品数据.xls',
				customerName: '大白兔酒店', // 客户名称
				timeStart: '', // 开始时间
				timeEnd: '', // 结束时间
				type: '', // 类型 1 编辑 2 调整日期-编辑
				state: '待编辑', // 状态
				dialog1Visible: false, // 添加商品浮层
				searchForm: {
					classifyId: '',
					agreementId: this.$route.query.id || '',
					goodsNo: '',
					buyerId: this.$route.query.buyerId
				},
				// 表格中的 表单数据
				ruleForm: {
					tableListGoods: [],
				},
				// 日期 禁用状态 开始
				pickerOptions: {
					disabledDate(time) {
						let myDate = new Date();
						let year = myDate.getFullYear();
						let month = myDate.getMonth() + 1 < 10 ? '0' + (myDate.getMonth() + 1) : myDate.getMonth() + 1;
						let day = myDate.getDate() < 10 ? '0' + myDate.getDate() : myDate.getDate();
						let t = year + '-' + month + '-' + day;
						return (time.getTime() !== +new Date(`${t} 00:00:00`));
					}
				},
				// 日期 禁用状态 结束
				pickerOptions2: {
					disabledDate(time) {
						return (time.getTime() < +new Date(_this.timeStart)) || (time.getTime() < +new Date());
					}
				},
				flag: false,
				loading: true,
				dialogVisiable: false,// 套餐设价弹框
				btnLoading: false,// 按钮loading
				form: {
					goodsList: [],
					remark: '',// 备注
					msrp: '',// 建议零售价
				},// 套餐form
				totalPrice: '',
				btnloading: false, //按钮loading
				unitOptions: [{ // 瓶箱选择器
					label: '箱(规格单位)',
					value: '箱(规格单位)'
				}, {
					label: '瓶(基本单位)',
					value: '瓶(基本单位)'
				}],
				moqVisible: false, // 统一设置起订量弹框
				minimunMoqForm: {
					unit: '箱(规格单位)', // 瓶、箱
					num: 1, // 最低起订量
				},
				rules: {
					num: [{
						required: true,
						message: '请输入最低起订量'
					},
						{
							pattern: /^[1-9]\d*$/,
							message: '起订量是大于0的正整数'
						}]
				},
				sellerId: '',// 卖家id
				buyerId: '',// 买家id
				addlists: [],//选择商品
				multipleSelection: [],
				skuObj: {
					skuNo: '',     //商品(sku)编码
					skuName: '',     //商品名称
					classifyId: ''  //商品分类
				},//商品搜索条件
				goodsData: [],//商品数组
				tableloading: false,
				total: 0,
				proData: [],//弹框商品数组
			}
		},
		computed: {
			// // 添加商品的数据
			// goodsData() {
			// 	return this.$store.state.ticket.willadd;
			// },
			isCityPartner() {
				return this.$store.state.clientType.isCityPartner;
			},
			classifylist() { // 商品分类
				return this.$store.state.classifylist.classifylistData ?
					this.$store.state.classifylist.classifylistData : []
			},
		},
		watch: {
			'form.goodsList': function () {
				let goodsList = this.form.goodsList
				let total = 0
				// goodsList.forEach(i => {
				// 	if (i.discountPrice) {
				// 		total += i.discountPrice * i.num
				// 	} else {
				// 		total = 0
				// 	}
				// })
				goodsList.map(v => {
					total += (v.discountPrice || 0) * v.num
				});
				this.totalPrice = total;
			},
			dialog1Visible(val) {
				if (val) {
					this.querygood()
				}
			}
		},
		mounted() {
			this.$nextTick(() => {
				// 如果当前是页面 调整到期-编辑 or 编辑
				if (this.$route.query.type === '2') {
					this.type = 2;
				} else {
					this.type = 1;
				}

				;
				this.classSearch();
				this.init();
			});
		},
		methods: {
			...mapActions({
				'hotelselleragreement': 'hotelselleragreement', // 折扣商品列表(单个客户) hotelEdit
				'classSearch': 'queryclassifylistData',
			}),
			...mapMutations({
				'openSyncPackageGoodDialog': 'openSyncPackageGoodDialog',// 套餐商品同步
			}),
			//修改价格
			changeUnitPromotion(index){
				console.log(index,this.ruleForm.tableListGoods[index]);
				let obj = this.ruleForm.tableListGoods[index];
				obj.tableForm.promotion = (obj.tableForm.unitPromotionPrice * obj.specInfoNum).toFixed(2);
				this.$set(this.ruleForm.tableListGoods,index,obj)
			},
			changePromotion(index){
				console.log(index,this.ruleForm.tableListGoods[index]);
				console.log(index,this.ruleForm.tableListGoods[index]);
				let obj = this.ruleForm.tableListGoods[index];
				obj.tableForm.unitPromotionPrice = (obj.tableForm.promotion / obj.specInfoNum).toFixed(2);
				this.$set(this.ruleForm.tableListGoods,index,obj)
			},
			//商品弹框选择商品确定
			addGoods() {
				this.goodsData = this.multipleSelection;
				this.handleOk()
			},
			//选择商品
			handleSelectionChange(val) {
				let addlist = val.map((each) => {
					return {
						sellerId: each.sellerId,
						skuNo: each.skuNo,
						skuName: each.skuName
					}
				})
				this.addlists = addlist;
				this.multipleSelection = val.map(item => {
					this.$set(item, 'add', true)// 添加add 属性区分新增商品
					return item
				})
			},
			handlePageChange(val) { //分页
				this.currentPageGoods = val
				this.querygood()
			},
			/*handleQueryBtn(page) { // 查询商品
				if (this.currentPage === 1) {// 当前分页等于1 赋值为1 不触发 handlePageChange
					this.currentPage = 1
					this.gethotelList()
				} else {// 赋值为1 触发 handlePageChange
					this.currentPage = 1
					return false
				}
			},*/
			/*gethotelList() {// 获取酒店折扣数据
				this.loading = true
				this.hotelselleragreement({
					'body': this.searchForm,
					'currentPage': this.currentPage
				}).then(res => {
					this.loading = false;
					let data = res.data.data.elements; // 折扣商品列表(单个客户)数据
					this.total = res.data.data.totalCount; // 折扣商品列表(单个客户)总条数
					if (data) {
						this.ruleForm.tableListGoods = this.changeGoodsList(data)
					}
				}).catch(err => {
					this.loading = false;
				})
			},*/
			updateK3() {// 套餐同步后更新
				axiosApi('/goods/hotelselleragreement/select', {
					id: this.$route.query.id,
				}, (res) => {
					let data = res.data.data;
					if (data) {
						this.state = data.statusName;
						this.customerName = data.buyerStoreName;
						this.timeStart = data.startDate;
						this.timeEnd = data.endDate;
						this.generalGoodsnum = data.conventionalQuantity || 0// 普通商品数量
						this.packageGoodsnum = data.setMealQuantity || 0// 套餐商品数量
					}
				})
			},
			init() {
				// 初始化数据
				axiosApi('/goods/hotelselleragreement/select', {
					id: this.$route.query.id,
				}, (res) => {
					let data = res.data.data;
					if (data) {
						this.state = data.statusName;
						this.customerName = data.buyerStoreName;
						this.timeStart = data.startDate;
						this.timeEnd = data.endDate;
						this.sellerId = data.sellerId
						this.buyerId = data.buyerId
						// this.isOpenUnitPrice = data.isOpenUnitPrice,// 是否开通 按瓶 展示价格
						this.gethotelList();
						this.updateK3();
					}
				}, 1, 1, () => {
					this.loading = false;
				});
			},
			gethotelList() {
				// 获取商品数据
				axiosApi('/goods/hotelagreementgoods/selectList', this.searchForm, (res) => {
					this.loading = false;
					let data = res.data.data;
					if (data) {
						// this.changeFlag = bool;
						this.ruleForm.tableListGoods = this.changeGoodsList(data);
						// 重置 affix位置
						this.$refs.affix.initScroll();
					}
					// console.log(data,'商品数据');
				}, 1, 10, () => {
					this.loading = false;
				});
			},
			exportData() {// 导出数据
				if (this.ruleForm.tableListGoods.length > 0) {
					let flag = true;
					this.ruleForm.tableListGoods.map(v => {
						if (!v.add) {
							flag = false;
						}
					});
					if (!flag) {
						this.$refs.form.submit();
					} else {
						this.$message.warning('没有商品数据')
					}

				} else {
					this.$message.warning('没有商品数据')
				}
			},
			changeGoodsList(payload) {// 转换数据
				let arr = payload.map((v) => {
					if (v.skuType === 2) {// 套餐商品
						// v.promotionPrice = v.promotionPrice// 编辑显示
						v.fileUrl = v.img;
						v.id = v.skuId;
						v.mainId = v.id;
						v.goodsNo = v.goodsNo;
						v.classifyName = v.classifyName;
						v.skuState = v.skuState;
						v.isVisible = v.isVisible;
						v.tableForm = {
							miniMoq: v.minimunMoq,// 最低起订量
							promotion: v.promotionPrice, // 套餐的会员价
							discountType: 2, // 折扣类型
							capFee: '0', // 瓶盖费
							remarks: v.remark, // 备注
							unitDiscountValue: v.unitDiscountValue, // 固定返利
						}
						return v
					} else {
						let obj = {
							id: v.skuId,
							mainId: v.id,
							skuNo: v.skuNo,
							fileUrl: v.img,
							skuName: v.skuName,
							classifyName: v.classifyName,
							goodsNo: v.goodsNo,
							specInfoNum: v.specInfoNum,
							sellingUnitName: v. sellingUnitName,
							skuType: v.skuType,
							unitsName: v.unitsName,// 基本单位瓶
							skuState: v.skuState,
							isVisible: v.isVisible,
							tableForm: {
								miniMoq: v.minimunMoq,// 最低起订量
								promotion: v.promotionPrice , // 会员价
								unitPromotionPrice:v.unitPromotionPrice,
								discountType: v.discountType, // 折扣类型
								unitDiscountValue: v.unitDiscountValue, 
								capFee: v.capFee + '', // 瓶盖费
								remarks: v.remark, // 备注
							},
						};
						return obj;
					}
				})
				return arr
			},
			//设置可见
			setVisibile(item, flag) {
				let msg = flag == 1 ? '可见' : '不可见';
				let body = {
					isVisible: flag,
					// skuNo:item.skuNo,
					id: item.mainId
				};
				this.$confirm(`是否将该商品设置为${msg}状态?`, '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					axiosApi('/goods/hotelagreementgoods/visible', body, res => {
						this.$message({
							type: 'success',
							message: '设置成功',
							duration: 1000
						});
						this.init()
					}, 1, 10, err => {
						this.$message.warning(err.data.message)
					})
				}).catch(err => {
				})
			},
			setSkuState(item) {
				let msg;
				let body = {
					skuNo: item.skuNo,
					id: item.mainId,
				};
				if (item.skuState == 1) {//已出售  设为停售
					body.skuState = 2;
					msg = '出售'
				} else {//已停售  设为出售
					body.skuState = 1;
					msg = '停售'
				}
				this.$confirm(`是否将该商品设置为${msg}状态?`, '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					axiosApi('/goods/hotelagreementgoods/setting/up/skuState', body, res => {
						this.$message({
							type: 'success',
							message: '设置成功',
							duration: 1000
						});
						this.init()
					}, 1, 10, err => {
						this.$message.warning(err.data.message)
					})
				}).catch(err => {
				})
			},
			// 增加表格中的 表单数据
			setTableForm(arr) {
				return (
					arr.map((v, k) => {
						// this.changeFlag = false;
						if (v.skuType === 2) {// 套餐商品
							v.add = v.add;
							v.goodsNo = v.goodsNo,
							v.promotionPrice = v.add ? 0 : v.msrp
							v.classifyName = v.classifyName
							v.skuState = v.add ? 2 : v.skuState
							//v.fileUrl = v.img
							v.tableForm = {
								miniMoq: v.minimunMoq,// 最低起订量
								promotion: v.msrp, // 套餐的会员价
								discountType: 2, // 折扣类型
								capFee: '0', // 瓶盖费
								remarks: v.remark, // 备注
								unitDiscountValue: v.unitDiscountValue, // 固定返利
							}
							if (v.skuSetInfoVOList) v.jsonSetInfo = JSON.stringify(v.skuSetInfoVOList);
							return v
						} else {
							let a = {
								add: v.add,
								id: v.id,
								skuNo: v.skuNo,
								fileUrl: v.fileUrl,
								skuName: v.skuName,
								isVisible: v.isVisible,
								skuState: v.add ? 2 : v.skuState,
								classifyName: v.classifyName,
								specInfoNum: v.specInfoNum,
								sellingUnitName: v. packageUnitName,
								skuType: v.skuTypes,
								goodsNo: v.goodsNo,
								isIntegerMultiple: v.isIntegerMultiple,
								unitsName: v.unitsName,// 基本单位瓶
							};
							a.tableForm = {
								miniMoq: v.minimunMoq,// 最低起订量
								promotion: '', // 会员价
								discountType: 1, // 折扣类型
								unitDiscountValue: '', // 固定返利
								capFee: '0', // 瓶盖费
								remarks: '', // 备注
							};
							return a;
						}
					})
				)
			},
			closeMoqDialog() {// 关闭弹框
				this.moqVisible = false
				this.minimunMoqForm.num = 1
			},
			moqcomfirm() { // 统一设置确定最低起订量
				this.$refs.minimunMoqForm.validate((valid) => {
					if (valid) {
						// 按箱设置起订量 -- 按瓶设置起订量
						let arr = []
						arr = JSON.parse(JSON.stringify(this.ruleForm.tableListGoods)).map(v => {
							if (v.skuType != 2) {
								v.tableForm.miniMoq = this.minimunMoqForm.num;
							}
							console.log(v, 'v', this.minimunMoqForm.unit)
							return v
						});
						console.log(arr, 'arr')
						this.ruleForm.tableListGoods = JSON.parse(JSON.stringify(arr))
						this.moqVisible = false
						this.$message.success('操作成功')
					} else {
						return false
					}
				})
			},
			// 数组去重
			duplicateRemoval(arr) {
				let a = [];
				let obj = {};
				arr.map((v, k) => {
					if (obj[v.id] !== true) {
						a.push(v);
						obj[v.id] = true;
					}
				});
				return a;
			},
			...mapActions({
				'querygoodtableData': 'allGoodsQuery',// 查询全部商品
			}),
			querygood() {
				const api = '/goods/skuInfoPage/select';
				let initialstate = {
					sellerId: sessionStorage.getItem('traderId'),
					auditState: 2, 		//审核状态（1：待审核 2：通过 3： 未通过）
					hotelAgreementId: this.a,
					notId: this.ruleForm.tableListGoods.map(v => v.id),
					skuNos: this.ruleForm.tableListGoods.map(v => v.skuNo),
					...this.skuObj
				};
				axiosApi(api, initialstate, (res) => {
					console.log(res, 'goods');
					this.tableloading = false;
					this.proData = res.data.data.elements;
					this.total = res.data.data.totalCount;
				}, this.currentPageGoods, 10, err => {
					this.tableloading = false;
				})
			},
			// 添加商品
			handleAddGoods() {
				// console.log('添加商品');
				this.dialog1Visible = true;
				this.querygoodtableData({
					data: {
						notId: this.ruleForm.tableListGoods.map((v) => v.id),
						skuNos: this.ruleForm.tableListGoods.map((v) => v.skuNo)
					},
					hotelAgreementId: this.a,
					currentPage: 1,
				});
			},
			// 添加商品 浮层 ok
			handleOk() {
				// 处理数据
				// 增加form字段
				console.log(this.goodsData, 'goodsData');

				if (this.goodsData.length <= 0) {
					this.$message.warning('请选择商品');
					return
				}

				this.changeFlag = true;
				let arr = this.setTableForm(this.goodsData);
				setTimeout(() => {
					this.changeFlag = false;
				})
				// 合并数组
				this.ruleForm.tableListGoods.unshift(...arr);
				// 数组去重
				this.ruleForm.tableListGoods = this.duplicateRemoval(this.ruleForm.tableListGoods);
				// 清空已选择数据
				this.handleClose();
				// 重置 affix位置
				this.$refs.affix.initScroll();
				// 清空校验 (增加了 el-form-item的key 不需要手动清空校验)
				/* this.$nextTick(() => {
                    this.$refs.ruleForm.fields.map((v,k) => {
                        v.validateState = '';
                    });
                }); */
				console.log(this.ruleForm.tableListGoods)
			},
			// 添加商品 浮层 close
			handleClose() {
				this.dialog1Visible = false;
			},
			// table title render
			renderHeader(h, {column, $index}) {
				let span = <span class= "is-required" > {column.label} </span>;
				return span;
			},
			changePrice() {// 监听价格输入框变化
				let arr = this.form.goodsList
				let num = 0
				for (let i = 0; i < arr.length; i++) {
					let a = arr[i]
					if (a.discountPrice) {
						num += a.discountPrice * a.num
					} else {
						num = 0
						break
					}
				}
				this.totalPrice = num ? num : 0
			},
			mealInfo(id) {// 套餐详情接口
				return new Promise((resolve, reject) => {
					axiosApi('/goods/setMeal/info/select', {
						sellerId: sessionStorage.traderId,
						id: id
					}, res => {
						resolve(res.data.data)
					}, 1, 10, res => {
						reject(res)
					})
				})
			},
			handleEdit(row, index) {// 套餐商品编辑设价
				console.log(row, index, 'ooo')
				this.dialogVisiable = true
				this.mealIndex = index
				this.mealRow = row
				this.form.goodsList = JSON.parse(row.jsonSetInfo);// 初始化套餐商品列表
				this.form.msrp = row.promotionPrice ? row.promotionPrice : 0
				this.form.remark = row.remark ? row.remark : ''
			},
			saveMeal() {// 更新套餐
				this.$refs.setPriceForm.validate(v => {
					if (v) {
						if (this.form.msrp < this.totalPrice) {// 零售价小于套餐价就显示套餐价return false
							this.$message.warning('会员价不能小于套餐价')
							return false
						}
						this.dialogVisiable = false
						let mealObj = {
							'jsonSetInfo': JSON.stringify(this.form.goodsList),
							'promotionPrice': this.form.msrp,
							'remark': this.form.remark,
							'discountValue': (this.form.msrp - this.totalPrice).toFixed(2),
							'unitDiscountValue': (this.form.msrp - this.totalPrice).toFixed(2),
						}
						mealObj.tableForm = {
							miniMoq: this.mealRow.tableForm.miniMoq,
							discountType: 2, // 折扣类型
							capFee: '0', // 瓶盖费
							remarks: mealObj.remark, // 备注
                            discountValue: mealObj.discountValue, // 固定返利
                            unitDiscountValue: mealObj.unitDiscountValue
						}
						this.$set(this.ruleForm.tableListGoods, this.mealIndex, Object.assign(this.mealRow, mealObj))// 设价成功同步列表数据
						this.form = {goodsList: []}// 清空
						this.totalPrice = ''
						this.mealRow = {}
					} else {
						return false
					}
				})
			},
			handleDialogClose() {// 套餐设价弹框消失
				this.dialogVisiable = false
				this.form = {goodsList: []}
			},
			mealUpdate(body) {// 编辑套餐商品接口
				this.btnloading = true
				return new Promise((resolve, reject) => {
					axiosApi('/goods/setMeal/info/update', body, res => {
						resolve(res)
						this.btnloading = false
					}, 1, 10, err => {
						reject(err)
						this.btnloading = false
					})
				})
			},
			// 点击删除
			handleDelete(row, index) {
				this.$confirm('是否删除该条？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.changeFlag = true;
					this.ruleForm.tableListGoods.splice(index, 1);
					setTimeout(() => {
						this.changeFlag = false;
					})
					// 重置 affix位置
					this.$refs.affix.initScroll();
				}).catch(() => {
				});
			},
			// 点击提交
			handleSubmit() {
				if (!this.timeStart && this.type === 1) {
					this.$message.warning('请选择折扣开始日期');
					return;
				}
				if (!this.timeEnd) {
					this.$message.warning('请选择折扣结束日期');
					return;
				}
				if (new Date(this.timeStart) > new Date(this.timeEnd)) {
					this.$message.warning('折扣开始日期不能大于结束日期');
					return;
				}
				if (this.ruleForm.tableListGoods.length === 0) {
					this.$message.warning('请至少添加一个商品');
					return;
				}
				this.$refs.ruleForm.validate((valid) => {
					if (valid) {
						// 处理数据
						// console.log(this.ruleForm.tableListGoods,'表单数据');
						let form = {
							id: this.$route.query.id,
							startDate: +new Date(this.timeStart),
							endDate: +new Date(this.timeEnd),
							hotelAgreementGoodsList: []
						};
						form.hotelAgreementGoodsList = this.ruleForm.tableListGoods.map((v, k) => {
							console.log(v,'v')
							let {promotion,unitPromotionPrice, discountType,unitDiscountValue, capFee, remarks, minimunMoq} = v.tableForm;
							if (v.jsonSetInfo) {// 套餐商品
								return ({
									skuNo: v.skuNo,
									promotionPrice: v.promotionPrice, // 会员价
									discountType: discountType, // 折扣类型
									unitDiscountValue,
									discountValue: unitDiscountValue ,
									capFee: capFee, // 瓶盖费
									remark: remarks, // 备注
									jsonSetInfo: v.jsonSetInfo,
									skuType: v.skuType,// 类型
									minimunMoq: minimunMoq // 最低起订量
								});
							} else {// B2B商品
								let body = {
									skuNo: v.skuNo,
									unitPromotionPrice,
									promotionPrice: promotion, // 会员价
									discountType: discountType, // 折扣类型
									//因为收到数据这样discountValue 代表基本单位的折扣 在上传的时候 需要转换
									discountValue: discountType === 1 ? unitDiscountValue : (unitDiscountValue * v.specInfoNum).toFixed(2),
									unitDiscountValue:unitDiscountValue,
									capFee: capFee, // 瓶盖费
									remark: remarks, // 备注
									skuType: v.skuType,
									isVisible: v.isVisible,
									skuState: v.skuState
								}
								
								return body
							}
						});
						console.log(form,'form');
						// debugger
						this.$confirm('是否确认提交?', '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {
							// 请求接口
							axiosApi('/goods/hotelsellergoods/batchinsert', form, (res) => {
								let data = res.data.data;
								if (data && data.failcount) {
									this.$confirm(`有${data.failcount}个商品添加失败`, '提示', {
										confirmButtonText: '确定',
										type: 'warning'
									}).then(() => {
										this.$router.push(this.basePath + '/hotelHome');
									}).catch(() => {
									});
									// 请求成功
								} else {
									// 是否返回 点击确定 直接返回列表 ， 不需要提示.
									if (this.isGoHome === true) {
										this.$router.push(this.basePath + '/hotelHome');
										this.isGoHome = false;
										return;
									}
									this.$router.push(this.basePath + '/hotelHome');
								}
							}, 1, 10, res => {
								this.$message.error(res.data.message)
							});
						}).catch(() => {
						})

					} else {
						// console.log('error submit!!');
					}
				});
			},
			// 点击返回
			handleGoHome() {
				this.$confirm('是否保存数据？', '提示', {
					confirmButtonText: '是',
					cancelButtonText: '否',
					type: 'warning'
				}).then(() => {
					this.isGoHome = true;
					this.handleSubmit();
				}).catch(() => {
					this.$router.push(this.basePath + '/hotelHome');
				});
			},
			valideMsrp(rule, value, callback) {// 校验会员价
				if (value <= 0) {
					callback('会员价大于0');
					return
				}
				callback()
			},
			// 校验 固定返利
			validator(rule, value, callback, index) {
				let row = this.ruleForm.tableListGoods[index];
				let form = row.tableForm;
				if (form.discountType === 2) {
					if (+form.unitDiscountValue >= +form.unitPromotionPrice) {
						callback('固定返利不能大于等于会员价');
						return;
					}
				}
				callback();
			},
			// 校验 会员价
			validator2(rule, value, callback, index) {
				let row = this.ruleForm.tableListGoods[index];
				let form = row.tableForm;
				if (form.discountType === 2) {
					if (+form.unitDiscountValue >= +form.unitPromotionPrice) {
						callback('会员价要大于固定返利');
						return;
					}
				}
				if (value <= 0) {
					callback('会员价大于0');
					return
				}
				callback();
			},
			// 单选框 change 折扣返利
			handleRadio(index) {
				/* 解决触发多次问题 */

				if (this.ruleForm.tableListGoods[index].tableForm.discountType === 1) {
					return;
				}
				this.ruleForm.tableListGoods[index].tableForm.unitDiscountValue = '';
				/* 清空 校验 */
				this.$nextTick(() => {
					this.$refs.ruleForm.fields[index * 7 - 1 + 3].validateState = '';
				});
			},
			// 单选框 change 固定返利
			handleRadio2(index) {
				/* 解决触发多次问题 */
				if (this.ruleForm.tableListGoods[index].tableForm.discountType === 2) {
					return;
				}
				this.ruleForm.tableListGoods[index].tableForm.unitDiscountValue = '';
				/* 清空 校验 */
				this.$nextTick(() => {
					this.$refs.ruleForm.fields[index * 7 - 1 + 2].validateState = '';
				});
			},
		},
	}
</script>

<style lang="less">
    #hotel-edit-box {
		padding-top:20px;
		background:white;
        #goods .el-dialog {
            width: 950px;
            /*height: 975px;*/
        }

        #goods .table-default.el-table .cell {
            padding: 3px 15px 0;
        }

        #goods .el-dialog__body {
            padding: 20px 30px;
        }

        #goods .el-dialog__footer {
            position: static;
            transform: translateX(0);
            margin-bottom: 40px;
        }

        #goods .el-dialog {
            .el-form {
                .el-form-item {
                    .el-form-item__label {
                        &::before {
                            display: none
                        }
                    }
                }
            }
        }

        #goods .m-table {
            /* height: 470px;
            overflow-y: auto; */
        }

        #goods .el-form .el-input {
            width: 120px;
        }

        .red {
            color: #ff4201;
        }

        .width-120 {
            width: 120px;
        }

        .m-inline {
            display: inline-block;
        }

        .m-content {
            border: 1px solid #ccc;
            padding: 20px 40px;
            padding-bottom: 50px;

            .m-title {
                height: 30px;
                border-bottom: 1px solid #ccc;
            }

            .m-detail {
                padding-top: 20px;
                padding-bottom: 20px;
                border-bottom: 1px solid #ccc;

                .m-item {
                    margin-bottom: 20px;

                    .m-item-label {
                        display: inline-block;
                        width: 120px;
                        text-align: right;
                    }

                    .m-item-content {
                        display: inline-block;

                    }
                }
            }

            .m-goods-table {
                /* 商品名称 */

                .m-goods-table-goods-name {
                    /*// overflow: hidden;*/
                    height: 80px;

                    .m-left {
                        float: left;
                        text-align: left;
                        margin-right: 10px;

                        img {
                            width: 70px;
                            height: 70px;
                        }
                    }

                    .m-right {
                        float: left;
                        width: 133px;
                        text-align: left;
                    }
                }

            }

            .m-submit-box {
                height: 40px;
                line-height: 40px;
                text-align: center;

                .m-submit {
                    margin-right: 30px;
                }

                button {
                    height: 32px;
                }
            }
        }
    }
</style>

<style lang="less">
    // .m-goods-table{.el-form-item__error{margin-left: 8px;}}
    #hotel-edit-box {
        .set_price .el-table .el-form-item__content .el-input {
            width: 100% !important;
        }

        .unit_select {
            width: 90px;

            .el-input {
                width: 90px !important;
            }
        }

        .moqdialog .el-form-item.is-error {
            .el-input__inner {
                border-color: #ddd !important;
            }

            .el-form-item__error {
                left: 195px;
            }
        }

        .moqdialog .is-required:before {
            display: none;
        }

        .moqdialog {
            .el-dialog {
                min-height: auto;
            }

            .el-select {
                width: auto;

                .el-input {
                    width: 75px;
                }

                .el-input__inner {
                    width: 75px;
                }
            }

            .unit {
                font-size: 14px;
            }

            .el-radio__label {
                display: none;
            }

            .el-dialog__footer {
                position: static;
                transform: translateX(0);
                padding-bottom: 50px;
            }

            .el-dialog__headerbtn {
                position: absolute;
                right: 0;
                top: 0;
            }

            .radio2 {
                .el-input--mini {
                    .el-input__inner {
                        border-right: none;
                    }

                    .el-input__inner:hover,
                    .el-input__inner:focus {
                        border-right: none;
                    }
                }
            }
        }

        .miniMoq {

        }

        /* table title 星号 */

        .is-required:before {
            content: "*";
            color: #ff4949;
            margin-right: 4px;
        }

        /* table 内容 星号 去除 */

        .el-table__row .is-required:before {
            display: none;
        }

        /* error border 优先级不够问题 */

        .el-form-item.is-error .el-input__inner {
            border-color: #ff4949 !important;
        }

        /* error 文案删除 */
        /* .el-form-item__error{
            display: none;
        } */

        .el-form-item {
            margin-bottom: 0;
            display: inline-block;
        }

        textarea {
            resize: none;
        }

        .el-table__body-wrapper {
            overflow: visible;
        }

        .el-table__empty-block {
            height: 166px;
        }

        .mdialog {
            .el-dialog__footer {
                position: static;
                transform: translateX(0);
                padding-bottom: 50px;
            }

            .msrp:before {
                content: '';
            }

            .msrp {
                .el-form-item__label {
                    width: auto;
                }

                .el-form-item__error {
                    margin-left: 80px;
                }
            }

            .el-dialog.el-dialog {
                width: 1000px;
            }

            .el-dialog__body {
                padding: 50px 30px 90px 30px;
            }

            .el-dialog__headerbtn {
                position: absolute;
                right: 0;
                top: 0;
            }

            .mr-15 {
                margin-right: 15px;
            }

            .w480 {
                width: 480px;
            }

            .cell {
                .el-form-item {
                    margin-top: 10px;
                    margin-bottom: 10px;
                }
            }

            .set_price {
                .cell {
                    .el-form-item {
                        margin-top: 20px;
                        margin-bottom: 20px;

                        .el-form-item__content {
                            .el-input {

                            }
                        }
                    }
                }
            }
        }

        .unit_select_dialog {
            width: 90px;

            .el-input {
                width: 90px !important;
            }

            .el-input__inner {
                width: 90px !important;
            }
        }
    }
</style>
