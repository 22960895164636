<template>
    <div class="reginListBox pb-20">
        <div class="title-top bg-f">
            区域设置
            <el-button @click="add" icon="el-icon-plus" class="fr mr-30 mt-14">新增区域</el-button>
        </div>
        <div class="bg-f mt-5 content-padding">
            <div class="content">
                <div class="left">
                    <el-tree
                        :data="treeList"
                        @node-click="treeClick"
                        :props="treeProps"
                        node-key="id"
                        :default-expand-all="true"
                        :expand-on-click-node="false"

                    >
                        <span  class="custom-tree-node mlist" slot-scope="{ node, data }">
                            <span title={data.districtName} class="mtext">
                                {{data.districtName}}
                            </span>
                            <span class='handle'>
                                <span class="edit" v-if="!(data.id === 0)" @click='edit(data)'>编辑</span>
                                <span class="delete" v-if="!data.subDistrictList && !(data.id === 0)" @click='mdelete(data)' >删除</span>
                            </span>
                        </span>
                    </el-tree>
                </div>
                <div class="right">
                    <p class="selectTextBox">
                        [ {{ selectedText }} ]包含以下客户
                    </p>
                    <div class="tableBox pl-20 pr-20">
                        <el-table :data="tableList" border tooltip-effect="dark" stripe class="mt-20 table-default">
                            <el-table-column prop="storeName" label="客户名称"></el-table-column>
                            <el-table-column prop="loginName" label="客户账号"></el-table-column>
                            <el-table-column prop="groupName" label="所属分组"></el-table-column>
                            <el-table-column prop="cTypeName" label="客户类型">
                                
                            </el-table-column>
                            <el-table-column label="客户渠道">
                                <template slot-scope="scope">
                                    <span>{{scope.row.channelName}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column  label="状态">
                                <template slot-scope="scope">
                                    {{scope.row.isDisable==1?"禁用":"正常"}}
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <div class="pageBox">
                        <el-pagination class="tar" v-if='total>0'
                            :current-page="currentPage"
                            :page-size='10'
                            :total='total'
                            layout="prev, pager, next, jumper"
                            @current-change="handlePageChange">
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div> 
    </div>
</template>

<script>
import { axiosApi, basePath } from "../../../api/api"


export default {
    data: function() {
        return ({
            // 当前选中的 tree id
            districtId : 0,
            // 左侧树
            treeList : [
                {
                    id : 0,
                    districtName : '全国',
                    subDistrictList : [
                        /* {
                            id : 222,
                            demo : 20,
                            districtName : '浙江省',
                            subDistrictList : [],
                        } */
                    ],
                }
            ],
            // table数据
            tableList : [],
            // 总数量
            total : 0,
            // 当前页数
            currentPage : 1,
            // 当前选中的 文案
            selectedText : '全国',
            treeProps: {
                value: 'id',
                label: 'districtName',
                children:'subDistrictList'
            },
        })
    },
    activated: function() {
        this.districtId = 0
        this.selectedText = '全国'
        this.getLeftTree();
    },
    methods: {
        // 获取左侧 树
        getLeftTree : function(){
            // 查询 左侧树
            axiosApi('/trader/customerDistrict/query',{
                traderId : sessionStorage.getItem('traderId'),
            },(res) => {
                this.treeList = this.cascaderClear2([res.data.data]);
            });
            // 默认查询 全国区域 右侧表格数据
            this.getTableList(1,0);
        },
        // 获取表格 数据
        getTableList : function(currentPage,districtId){
            if(districtId || (districtId === 0) ){
                districtId = districtId
            }else{
                districtId = this.districtId;
            }
            axiosApi('/trader/findClientAllList/select',{
                sellerId : sessionStorage.getItem('traderId'),
                districtId : districtId,
            },(res) => {
                if(res.data.data){
                    let data = res.data.data;
                    this.tableList = data.elements;
                    this.total = data.totalCount;
                }else{
                    this.tableList = [];
                    this.total = 0; 
                }
            },this.currentPage,10);
        },
        // 点击左侧树 
        treeClick : function(data){
            this.districtId = data.id;
            this.currentPage = 1;
            this.selectedText = data.districtName;
            this.getTableList(1,data.id);
        },
        // 点击新增
        add : function(){
            this.$router.push({
                path : `${basePath}/addAndEditRegion`,
                query : {
                    type : 'add',
                },
            });
        },
        // 点击 编辑
        edit : function(data){
            this.$router.push({
                path : `${basePath}/addAndEditRegion`,
                query : {
                    type : 'edit',
                    id : data.id,
                },
            });
        },
        // 点击删除
        mdelete : function(data){
            let dele = () => {
                axiosApi('/trader/customerDistrict/delete',{
                    id : data.id,
                    traderId : sessionStorage.getItem('traderId'),
                },(res) => {
                    this.getLeftTree();
                    this.selectedText = '全国';
                    this.districtId = 0;
                });
            };
            const h = this.$createElement;
            this.$msgbox({
                title: '提示',
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                showCancelButton: true,
                message: h('p', null, [
                    h('p', { style: {textAlign : 'center' , fontSize : '16px',marginBottom : '10px'} }, `是否确认删除区域「${data.districtName}」`),
                    h('p',{style : {color : '#666' , textAlign : 'center'}}, '删除之后该区域的客户将隶属于上一级区域')
                ]),
                type: 'warning'
                }).then(() => {
                    dele();
                }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });          
            });
        },
        // 点击分页
        handlePageChange : function(val){
            this.currentPage = val;
            this.getTableList(val);
        },
        renderContent : function(h, { node, data, store }){
            // 当前是否是 全国
            let isQ = data.id === 0;
            // 当前是否 叶子节点
            let isY = data.subDistrictList ? false : true;
            return (
                <span class="mlist">
                    <span title={data.districtName} class="mtext">
                        {data.districtName}
                    </span>
                    <span class='handle'>
                        {
                            !isQ ? <a class="edit" on-click={this.edit.bind(this,data)}>编辑</a> : null
                        }
                        {
                            isY && !isQ ? <a class="delete" on-click={this.mdelete.bind(this,data)} >删除</a> : null
                        }
                    </span>
                </span>
            );
        },
    },
}
</script>

<style lang="less">

.reginListBox{
    .el-tree{
        float: left;
    }
    .content-padding{
        padding: 30px 30px 50px;
    }

    .pageBox{
        padding-right: 20px;
        padding-bottom: 20px;
    }
    .content{
        border: 1px solid #ccc;
        height: auto;
        display: flex;
        align-items: stretch;
        min-height: 480px;
        .left{
            border-right: 1px solid #ccc;
            padding: 20px 0;
            max-width: 230px;
            overflow-x: auto;
            max-height: 628px;
            overflow-y: auto;
        }
        .right{
            width: ~"calc(100% - 231px)";
            .selectTextBox{
                padding: 20px 20px 0 20px;
            }
        }
    }
    .el-tree-node__content {
        min-width: 180px;
    }
    .el-tree-node__content .handle{
        color: #00a0e9;
        padding-left: 20px;
        opacity: 0;
    }
       
    
    .el-tree-node{
        white-space: nowrap;
    }
    .el-tree-node>.el-tree-node__children{
        overflow: visible;
    }
    .el-tree-node__content:hover .handle{
        opacity: 1;
    }
}

</style>

