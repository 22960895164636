<template>
    <div class="">
        <el-table :data="detailList" border class="detailList">
            <el-table-column label="批次号" width="150" align="center">
                <template slot-scope="scope">
                    {{scope.row.deliverySeqId}}
                </template>
            </el-table-column>
            <el-table-column label="货品信息" min-width="280">
                <template slot-scope="scope">
                    <div class="gift" style="padding: 20px 0;height: 95px">
                        <div v-if="scope.row.changePurchaseItem" class="triangle-topleft border-red"></div>
                        <span v-if="scope.row.changePurchaseItem" class="init default">换购</span>
                        <div v-if="scope.row.skuGift" class="triangle-topleft border-red"></div>
                        <span v-if="scope.row.skuGift" class="init default">赠品</span>
                        <img v-if="scope.row.skuImage" class="fl img mr-10" :src="scope.row.skuImage" />
                        <img v-else src="../../assets/goods.jpg" class="img fl mr-10" alt="" />
                        <div class="fl" style="width: 240px;">{{scope.row.skuName}}</div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="总数量" prop='specNumTotal' align="center" width="110"></el-table-column>
            <el-table-column label="单位" prop='specUnitName' align="center" width="60"></el-table-column>
            <el-table-column label="提货数量" prop='specNum' align="center" width="110"></el-table-column>
            <el-table-column label="提货时间" prop='gmtCreate' align="center" width="160">
                <template slot-scope="scope">
                    {{scope.row.gmtCreate | time}}
                </template>
            </el-table-column>
            <el-table-column label="出库单" prop='gmtCreate' align="center" width="160">
                <template slot-scope="{row}">
                    <div v-if="row.deliveryId" style="text-align:left">
                        <div>出库单号：{{row.deliveryId}}</div>
                        <div>出库时间：{{row.gmtDelivery | time}}</div>
                    </div>
                    <div v-else>--</div>
                </template>
            </el-table-column>
            <el-table-column label="状态" align='center' width="160">
                <template slot-scope="scope">
                    <div class="font-12">{{scope.row.statusName}}</div>
                    <el-tooltip v-if="scope.row.status === 4" effect="dark" :content="scope.row.rejectReason" placement="left-start">
                        <el-button type="text" class="text_btn">原因</el-button>
                    </el-tooltip>
                    <el-button v-if="scope.row.status === 1" type="default" size="mini" @click="cancel(scope.row.deliverySeqId)">取消</el-button>

                </template>
            </el-table-column>
        </el-table>
    </div>
</template>


<script>
import { getDeliveryItem, cancelDelivery } from "~/api/order";
export default {
    name:'outrecord',
    props: ["orderSn"],
    data () {
        return {
            detailList:[]
        };
    },

    components: {},

    computed: {},

    mounted(){},
    watch:{
        orderSn:{
           handler:function(val){
                console.log(val,'v')
                if(val){
                    this.getDeliveryInfo()
                    // this.detailList = val;
                }
           },
           immediate:true,
        }
    },

    methods: {
        getDeliveryInfo(val) {
            const body = {
                orderSn: this.orderSn,
                status: 2
            }
            getDeliveryItem(body).then(res=>{
                this.detailList = res || []
            }).catch(err=>{

            })
        },
        cancelSubmit(id){
            this.$confirm('是否取消提货？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.cancel(id)
            }).catch(() => {
                       
            });
        },
        cancel(id){
            cancelDelivery({deliverySeqId: id}).then(res=>{
                this.$message({
                    type: 'success',
                    message: '取消成功!'
                });
                this.getDeliveryInfo()
            }).catch(err=>{

            })
        },
    }
}

</script>
<style lang='less'>
    .outRecord {
        .noContent {
            padding-bottom: 257px;
            width: 939px;
            margin: 0 auto;

            p {
                text-align: center;
                margin-top: 50px;
            }

            .el-table .cell {
                padding: 10px;
            }
        }
    }
</style>