import Vue from 'vue';
import Router from 'vue-router';
import Home from '../views/home.vue';
import NotFound from '../views/notFound.vue';
import Welcome from '../views/welcome.vue'; //首次登陆引导页
import Search1 from '../components/search1.vue';
import Admin from '../components/admin.vue';
import LogoHead from '../components/logoHead.vue'; //logo头部
import ShopingSearch from '../components/shopingSearch.vue'; //购物车搜索
import storeMap from "../views/supplier/storeDistribution/storeMap.vue";//地图
import Redirect from '../views/redirect.vue'
import Login from '../views/login.vue'

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

import { ysFirstRouters, ysHomeRouters, ysHomeLogoRouters, ysHomeMenuRouters, ysCarLogoRouters } from './ys-router';
import { yxFirstRouters, yxHomeRouters, yxHomeLogoRouters, yxHomeMenuRouters, yxCarLogoRouters } from './yx-router';
import { ccFirstRouters, ccHomeRouters, ccHomeLogoRouters, ccHomeMenuRouters, ccCarLogoRouters } from './cc-router';
import { ctFirstRouters, ctHomeRouters, ctHomeLogoRouters, ctHomeMenuRouters, ctCarLogoRouters } from './ct-router';
import { psFirstRouters, psHomeRouters, psHomeLogoRouters, psHomeMenuRouters, psCarLogoRouters } from './ps-router';
import { ecFirstRouters, ecHomeRouters, ecHomeLogoRouters, ecHomeMenuRouters, ecCarLogoRouters } from './Ec-router';


import { superBFirstRouters, superBHomeRouters, superBHomeLogoRouters, superBHomeMenuRouters, superBCarLogoRouters } from './superB-router';
	/* 跟路由下边 */
let firstRouters = [ ...ysFirstRouters, ...yxFirstRouters, ...ctFirstRouters, ...ccFirstRouters, ...psFirstRouters, ...ecFirstRouters, ...superBFirstRouters],
	/* 只有头部导航页面 */
	homeRouters = [ ...ysHomeRouters, ...yxHomeRouters, ...ctHomeRouters,  ...psHomeRouters, ...ecHomeRouters,...superBHomeRouters],
	/* 有logo头部页面 */
	homeLogoRouters = [  ...yxHomeLogoRouters, ...ctHomeLogoRouters, ...ccHomeLogoRouters, ...psHomeLogoRouters,...ecHomeLogoRouters, {
        path: basePath + '/orderPrint', //发货单
        component: r => require.ensure([], () => r(require('../views/comm/print.vue')), 'print')
    }, ],
	/* 二级菜单 */
	homeMenuRouters = [ ...ysHomeMenuRouters, ...yxHomeMenuRouters, ...ctHomeMenuRouters, ...ccHomeMenuRouters, ...psHomeMenuRouters , ...superBHomeMenuRouters,...ccHomeRouters,...superBHomeLogoRouters ,...ysHomeLogoRouters, ...ecHomeMenuRouters],
	/* 有logo头部带搜索页面，eg：购物车 */
	carLogoRouters = [ ...ysCarLogoRouters, ...yxCarLogoRouters, ...ctCarLogoRouters, ...ccCarLogoRouters, ...psCarLogoRouters , ...superBCarLogoRouters, ...ecCarLogoRouters ];

Vue.use(Router)
import { basePath } from '../api/api'

let router = new Router({
	mode: "history",
	scrollBehavior(to, from, savedPosition) {
		// console.log(savedPosition)
		if(savedPosition) {
			return savedPosition
		} else {
			return {
				x: 0,
				y: 0
			}
		}
	},
	routes: [
        {
            path: basePath + '/login',
			component:Login,
			name:'login',
			text:'登录'
		},
		{
			path: '/redirect',
			component: Redirect,
			name:'redirect',
			text:'跳转'
		},
		{
			path: basePath + '/welcome',
			component: Welcome,
			name:'welcome',
			text:'欢迎'
		},
		{
			path: basePath + '/404',
			component: NotFound,
			name:'notFound',
			text:'页面不存在 '
		},
		{
			path: basePath + '/storeMap', //地图定位
			component: storeMap,
			name:'storeMap',
			text:'地图定位'

		},
		/* 跟路由下边 */
		...firstRouters,
		{
			path: basePath + '/searchs',
			component: Home,
			name:'search',
			text:'搜索',
			children: [
				...homeRouters,
				{
					path: basePath + '/logoHead', //头部logo
					component: LogoHead,
					name:'logoHead',
					text:'头部logo',
					children: [	/* 有logo头部页面 */
						...homeLogoRouters
					]
				},
                // {
                //     path: basePath + '/search1',
                //     component: Search1,
                //     children: [	/* 二级菜单 */
                //         ...homeMenuRouters
                //     ]
                // },
				{
					path: basePath + '/shopingSearch', //购物车
					component: ShopingSearch,
					name:'shopingSearch',
					text:'购物车',
					children: [	/* 有logo头部带搜索页面，eg：购物车 */
						...carLogoRouters
					]
				}
			]
		},
        {
            path: basePath + '/admin',
			component: Admin,
			name:'admin',
			text:'菜单',
            children: [	/* 二级菜单 */
                ...homeMenuRouters
            ]
        },
		{
			path: basePath,
			name:'start',
			text:'直接跳转到首页',
			redirect: {
				path: basePath + '/shop/shopIndex'
			}
		},
		{
			path: '/',
			name:'start',
			text:'直接跳转到首页',
			redirect: {
				path: basePath + '/shop/shopIndex'
			}
		},
		{
			path: basePath + '/',
			name:'start',
			text:'直接跳转到首页',
			redirect: {
				path: basePath + '/shop/shopIndex'
			}
		},
		{
			path: basePath + '/help',
			name: 'helpHome',
			text: '帮助中心',
			component: () => import('~/views/help/home.vue'),
			redirect: {
				path: basePath + '/help/list'
			},
			children: [	/* 二级菜单 */
				{
					path: basePath + '/help/list',
					name: 'helpList',
					text: '帮助列表',
					component: () => import('~/views/help/list.vue'),
				},
				{
					path: basePath + '/help/details',
					name: 'helpDetails',
					text: '帮助详情',
					component: () => import('~/views/help/details.vue'),
				}
			]
		},
//		{
//			path: '*',
//			redirect: {
//				path: basePath + '/404'
//			}
//		}
	],
});
/**
 * Loading chunk {n} failed
 * 报错来自于webpack进行code spilt之后某些bundle文件lazy loading失败
 * 监听路由跳转出错，重新渲染目标页面
 * */
router.onError((error) => {
	const pattern = /Loading chunk (\d)+ failed/g;
	const isChunkLoadFailed = error.message.match(pattern);
	const targetPath = router.history.pending ? router.history.pending.fullPath : '/'
	if (isChunkLoadFailed) {
		router.replace(targetPath);
	}
});

export default router;
