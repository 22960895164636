<template>
  <!-- 客户类型选择框 -->
  <div class="customerType" >
    <el-select v-model="types" placeholder="客户类型" :multiple="multiple" :clearable="clearable" collapse-tags>
      <el-option
        v-for="item in optionsType"
        :key="item.value"
        :label="item.label"
        :value="item.value">
      </el-option>
    </el-select>
  </div>
</template>

<script>
import { axiosApi, basePath } from "~/api/api";
import { mapMutations, mapActions } from 'vuex';
export default {
  name: 'customerType',
  model: {
    prop: 'value', 
    event: 'valChange'
  },
  props: {
    value: {
      type: [Number, String, Array],
      default: ''
    },
    multiple: {
      type: Boolean,
      default: true
    },
    clearable: {
      type: Boolean,
      default: false
    }
  },
  data(){
    return {
     
    }
  },
  computed:{
    types: {
      get:function () {
        return this.value
      },
      set:function (val) {
        this.$emit('valChange', val)
      }
    },
    optionsType() {
      return this.$store.state.clientType.optionsType
    }, 
  },
  activated(){
    this.getClientType()
  },
  mounted(){
    
    this.getClientType()
  },
  methods: {
    ...mapActions('clientType',['getClientType']),
  },
}
</script>

<style lang="less">
.customerType{
  .el-select{
    .el-input {
      width: 180px;
    }
  }
}

</style>