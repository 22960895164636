<template>
  <div class="security-manage">
    <div class="title-tabs2 bg-f">
      <ul class="clearfix">
        <div
          v-for="(tab, index) in dataTabs"
          class="item"
          :key="index"
          @click="handleTabs(index)"
        >
          <li :class="tab == clickedTabName ? 'active' : ''">
            {{ tab }}
            <div :class="tab == clickedTabName ? 'under-line' : ''"></div>
          </li>
          <div class="vertical-line"></div>
        </div>
      </ul>
    </div>

    <!-- 保证金明细 -->
    <div class="marginAudit-box bg-f" v-show="clickedTabName == '保证金明细'">
      <div class="marginAudit-content bg-f mt-5">
        <div class="pt-30 flex">
          <div class="flex lh-32">
            <span class="ml-20">提交时间：</span>
            <el-date-picker
              v-model="startTime"
              :style="{ width: '130px' }"
              type="date"
              placeholder="请选择"
            ></el-date-picker>
            <span>&nbsp;至&nbsp;</span>
            <el-date-picker
              v-model="endTime"
              :style="{ width: '130px' }"
              type="date"
              placeholder="请选择"
            ></el-date-picker>
          </div>
          <div class="flex lh-32">
            <span class="ml-30">久集账号：</span>
            <el-input placeholder="请输入久集账号" v-model.trim="name" />
            <el-button @click="handleSearch(1)" class="el-button--info ml-30"
              >搜索</el-button
            >
          </div>
        </div>

        <div class="c-content">
          <imgview
            ref="imgview"
            :visible="dialogVisible"
            :imgs="paymentVoucherUrl"
            :startindex="0"
            @close="dialogVisible = false"
          ></imgview>
          <order-viewredentials
            :imgList="paymentVoucherUrl"
            :type="'-1'"
            ref="viewredentials"
          ></order-viewredentials>
          <div class="c-tables">
            <el-table
              style="width: 100%"
              :data="tableList"
              border
              tooltip-effect="dark"
              stripe
              class="mt-20 table-default"
            >
              <el-table-column
                :resizable="false"
                width="110"
                prop="loginName"
                label="账号"
              ></el-table-column>
              <el-table-column
                :resizable="false"
                prop="name"
                label="姓名"
              ></el-table-column>
              <el-table-column
                :resizable="false"
                width="150"
                prop="idCard"
                label="身份证"
              ></el-table-column>
              <el-table-column
                :resizable="false"
                prop="bondMoney"
                label="保证金额度"
              ></el-table-column>
              <el-table-column
                :resizable="false"
                width="80"
                prop="rechargeImg"
                label="支付凭证"
              >
                <template slot-scope="scope">
                  <el-button
                    @click="handlePaymentVoucher(scope.row)"
                    type="text"
                    >查看</el-button
                  >
                </template>
              </el-table-column>
              <el-table-column
                v-if="tabs === '0'"
                :resizable="false"
                prop="gmtCreate"
                label="提交时间"
              >
                <template slot-scope="scope">{{
                  scope.row.gmtCreate | time
                }}</template>
              </el-table-column>

              <template v-if="tabs === '1'">
                <el-table-column
                  :resizable="false"
                  width="80"
                  prop="gmtModified"
                  label="操作时间"
                >
                  <template slot-scope="scope">{{
                    scope.row.gmtModified | time
                  }}</template>
                </el-table-column>
                <el-table-column
                  :resizable="false"
                  prop="operatorName"
                  label="操作人"
                ></el-table-column>
                <el-table-column :resizable="false" prop="state" label="状态">
                  <template slot-scope="scope">
                    <span v-if="scope.row.state === 0">待审核</span>
                    <span v-if="scope.row.state === 1">已通过</span>
                    <span v-else-if="scope.row.state === 2">已驳回</span>
                  </template>
                </el-table-column>
              </template>
            </el-table>
            <!-- 分页 -->
          </div>
        </div>
      </div>
      <!-- 驳回浮层 -->
      <el-dialog
        @close="handleClose"
        title="提示"
        :visible.sync="dialogTableVisible"
      >
        <div class="marginAudit-dialog-box">
          <el-form :model="ruleForm" ref="ruleForm">
            <div class="dialog-title">请输入驳回理由：</div>
            <el-form-item
              :rules="[
                {
                  required: true,
                  message: '驳回理由不能为空',
                  trigger: 'change'
                },
                { min: 2, message: '请最少输入2个文字', trigger: 'change' }
              ]"
              label=" "
              prop="reason"
            >
              <el-input
                :autosize="{ minRows: 3, maxRows: 3 }"
                :maxlength="100"
                type="textarea"
                v-model.trim="ruleForm.reason"
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="handleClose">取 消</el-button>
          <el-button type="primary" @click="handleOk">确 定</el-button>
        </div>
      </el-dialog>
    </div>

    <!-- 保证金提取 -->
    <div class="marginExtraction-box" v-show="clickedTabName == '保证金提取'">
      <div class="marginExtraction-content bg-f mt-5">
        <div class="pt-30 flex">
          <div class="flex lh-32">
            <span class="ml-20">申请时间：</span>
            <el-date-picker
              v-model="startTime"
              :style="{ width: '130px' }"
              type="date"
              placeholder="请选择"
            ></el-date-picker>
            <span>&nbsp;至&nbsp;</span>
            <el-date-picker
              v-model="endTime"
              :style="{ width: '130px' }"
              type="date"
              placeholder="请选择"
            ></el-date-picker>
          </div>
          <div class="flex lh-32">
            <span class="ml-30">久集账号：</span>
            <el-input placeholder="请输入久集账号" v-model.trim="name" />
          </div>
          <el-button @click="handleSearch(2)" class="el-button--info ml-30"
            >搜索</el-button
          >
        </div>
        <el-tabs v-model="activeTab1" @tab-click="handleTab1Click">
          <el-tab-pane label="未打款" name="0"></el-tab-pane>
          <el-tab-pane label="已打款" name="1"></el-tab-pane>
        </el-tabs>

        <div class="c-content">
          <div class="c-table">
            <el-table
              @selection-change="handleSelectionChange"
              style="width: 100%"
              :data="tableList"
              border
              tooltip-effect="dark"
              stripe
              class="mt-20 table-default"
            >
              <el-table-column
                type="selection"
                v-if="tabs === '0'"
                width="40"
              ></el-table-column>
              <el-table-column
                :resizable="false"
                prop="loginName"
                label="账号"
              ></el-table-column>
              <el-table-column
                :resizable="false"
                prop="name"
                label="姓名"
              ></el-table-column>
              <el-table-column
                :resizable="false"
                prop="storeName"
                label="店铺名"
              ></el-table-column>
              <el-table-column
                :resizable="false"
                prop="withdrawMoney"
                label="提取金额"
              ></el-table-column>
              <el-table-column
                :resizable="false"
                prop="bankName"
                label="银行卡"
              ></el-table-column>
              <el-table-column
                :resizable="false"
                width="150"
                prop="bankCardNo"
                label="卡号"
              ></el-table-column>
              <el-table-column
                :resizable="false"
                prop="userName"
                label="持卡人姓名"
              ></el-table-column>
              <el-table-column
                :resizable="false"
                width="135"
                prop="gmtCreate"
                label="申请时间"
              >
                <template slot-scope="scope">{{
                  scope.row.gmtCreate | time
                }}</template>
              </el-table-column>
              <el-table-column :resizable="false" prop="storeName" label="操作">
                <template slot-scope="scope">
                  <template v-if="scope.row.state === 0">
                    <el-button
                      :style="{ width: 'auto' }"
                      @click="handleConfirmMoney(scope.row)"
                      type="text"
                      >确认打款</el-button
                    >
                  </template>
                  <template v-else>
                    <span class="el-icon-circle-check handleSuccess"></span>
                    <span>已打款</span>
                  </template>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>

    <div class="pageBox">
      <el-pagination
        class="tar"
        v-if="total > 0"
        :current-page="currentPage"
        :page-size="10"
        :total="total"
        layout="prev, pager, next, jumper"
        @current-change="handlePageChange"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import { axiosApi, basePath } from "../../../api/api";
import tabsCheck from "../../../components/tabsCheck.vue";
import orderViewCredentials from "../orderViewCredentialsComponent.vue";
export default {
  components: { tabsCheck, "order-viewredentials": orderViewCredentials },
  data() {
    return {
      clickedTabName: "",
      dataTabs: [],
      startTime: "", // 开始时间
      endTime: "", // 结束时间
      name: "", // 久集账号
      startTimeDefault: "", // 点击搜索后 在赋值
      endTimeDefault: "",
      nameDefault: "",
      tabs: "0", // 当前tab
      total: 0, // 总数量
      currentPage: 1, // 当前页数
      tableList: [],
      dialogVisible: false, // 支付凭证 dialog stats
      paymentVoucherUrl: [], // 支付凭证 图片url arr
      dialogTableVisible: false,
      ruleForm: {
        reason: ""
      },
      activeTab1: "0",
      // 保证金提取
      multipleSelection: [] //列表多选 数据
    };
  },
  computed: {
    showtab1() {
      if (this.showButton("MARGIN_AUDIT", 130016)) {
        //审核
        return true;
      }
    },
    showtab2() {
      if (this.showButton("MARGIN_WITHDRAW", 130016)) {
        //提取
        return true;
      }
    }
  },
  mounted() {
    if (sessionStorage.isAdmin == "1" || (this.showtab1 && this.showtab2)) {
      this.dataTabs = ["保证金明细", "保证金提取"];
    } else if (this.showtab1) {
      this.dataTabs.push("保证金明细");
    } else if (this.showtab2) {
      this.dataTabs.push("保证金提取");
    }
    this.clickedTabName = this.dataTabs[0];
    this.initTableList();
  },
  methods: {
    handleTabs(index) {
      this.clickedTabName = this.dataTabs[index];
      this.startTime = "";
      this.endTime = "";
      this.name = "";
      this.tabs = this.activeTab1 = "0";
      if (this.currentPage === 1) {
        this.initTableList();
      } else {
        this.currentPage = 1;
          this.initTableList();
      }
    },
    // 表格 init
    initTableList() {
      const api =
        this.clickedTabName == "保证金明细"
          ? "/superc/supercStore/selectList"
          : "/superc/bond/apply/select";
      axiosApi(
        api,
        {
          loginName: this.nameDefault,
          startTime: this.startTimeDefault
            ? +new Date(this.startTimeDefault)
            : "",
          endTime: this.endTimeDefault ? +new Date(this.endTimeDefault) : "",
          state: this.clickedTabName == "保证金提取" ? this.tabs : 2
        },
        res => {
          if (res.data.data) {
            let data = res.data.data;
            this.tableList = data.elements;
            this.total = data.totalCount;
            this.currentPage = data.pageIndex;
          }
        },
        this.currentPage,
        10
      );
    },
    // 点击搜索
    handleSearch(name) {
      if (+new Date(this.startTime) > +new Date(this.endTime)) {
        this.$message.warning("开始时间不能大于结束时间");
        return;
      }
      this.currentPage = 1;
      this.startTimeDefault = this.startTime;
      this.endTimeDefault = this.endTime;
      this.nameDefault = this.name;
      this.initTableList();
    },
    // tab点击
    handleTab1Click(tabs) {
      this.startTime = "";
      this.endTime = "";
      this.name = "";
      this.tabs = this.activeTab1;
      this.currentPage = 1;
      this.initTableList();
    },
    // 点击 查看支付凭证
    handlePaymentVoucher(row) {
      if (!row.bondImg) {
        this.$message.warning("暂无支付凭证，无法查看");
      } else {
        this.paymentVoucherUrl = row.bondImg.split(",");
        // 使用 imgview组件
        this.$refs.viewredentials.show();
      }
    },
    // 点击通过
    handleAdopt(row) {
      this.$confirm("确认该笔金额已到账并通过审核?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          axiosApi(
            "/superc/bond/recharge/approve",
            {
              id: row.id
            },
            res => {
              let data = res.data.data;
              this.$message.success("操作成功");
              this.currentPage = 1;
              this.initTableList();
            }
          );
        })
        .catch(() => {});
    },
    // 点击驳回
    handleReject(row) {
      this.dialogTableVisible = true;
      this.id = row.id;
    },
    // 查看驳回 理由
    handleSeeReasons(row) {
      let div = (
        <div>
          <p>驳回理由：</p>
          <p
            style={{
              marginTop: "5px",
              wordWrap: "break-word",
              wordBreak: "normal",
              paddingBottom: "60px"
            }}
          >
            {row.reason}
          </p>
        </div>
      );
      this.$msgbox({
        title: "查看",
        message: div,
        showCancelButton: false,
        confirmButtonText: "确定",
      }).then(action => {});
    },
    handlePageChange(val) {
      this.currentPage = val;
      this.initTableList();
    },
    // 浮层 取消
    handleClose() {
      this.dialogTableVisible = false;
      this.$refs.ruleForm.resetFields();
    },
    // 浮层 确定
    handleOk() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          axiosApi(
            "/superc/bond/recharge/refuse",
            {
              id: this.id,
              reason: this.ruleForm.reason
            },
            res => {
              let data = res.data.data;
              this.$message.success("操作成功");
              this.currentPage = 1;
              this.initTableList();
              this.handleClose();
            }
          );
        }
      });
    },

    // 保证金提取
    // 点击 确认打款
    handleConfirmMoney(row) {
      this.confirmMoneyFn([row.id]);
    },
    // 确认打款逻辑
    confirmMoneyFn(ids, message) {
      this.$confirm(message || "请确认该笔保证金已转账给用户", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          axiosApi(
            "/superc/bond/apply/approve",
            {
              idList: ids
            },
            res => {
              let data = res.data.data;
              this.$message.success("操作成功");
              this.currentPage = 1;
              this.initTableList();
            }
          );
        })
        .catch(() => {});
    },
    // 表格多选
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 批量确定
    handleAllOk() {
      if (this.multipleSelection.length === 0) {
        this.$message.warning("请勾选需要操作的选项");
        return;
      }
      let ids = this.multipleSelection.map((v, k) => {
        return v.id;
      });
      this.confirmMoneyFn(ids, "请确认所选选项，保证金已转账给用户");
    }
  }
};
</script>

<style lang="less" scoped>
.marginAudit-box {
  .marginAudit-content {
    padding: 0px 20px 20px 20px;
    .c-tables {
      overflow-x: auto;
    }
  }
}
.marginExtraction-box {
  .marginExtraction-content {
    padding: 0px 20px 20px 20px;
    .handleSuccess {
      color: #13ce66;
    }
  }
}
</style>

<style lang="less">
.marginExtraction-box {
  .el-tabs__header {
    padding-left: 30px;
    margin-top: 30px;
  }
}
.marginAudit-box {
  .el-tabs__header {
    padding-left: 30px;
    margin-top: 30px;
  }
  .caozuo-div .el-button {
    width: initial;
  }
  /* 重写浮层样式 */
  .el-dialog__body {
    padding: 30px 20px;
    min-width: auto;
  }
  .el-dialog {
    min-height: 300px;
  }
  .marginAudit-dialog-box {
    .dialog-title {
      margin-bottom: 10px;
    }
    .el-form-item__label {
      display: none;
    }
    textarea {
      resize: none;
    }
  }
}
</style>
