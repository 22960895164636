<template>
	<div id="shopingSearch" class="bg-f pb-20" >
		<div class="width-1280 plr-45 pt-20 bg-f">
			<el-row type="flex" justify="space-between">
				<el-col :span="6">
					 <router-link to="/customer/shop/shopIndex">
						<img class="dib cursor" src="../assets/logo-r.png" />
					 </router-link>
				</el-col>
				<el-col :span="18">
					<div class="clearfix mt-10">
						<el-input class="fl" v-model.trim="goodsName" @keydown.enter.native='goodsSearch' placeholder="请输入关键词搜索"></el-input>
						<el-button class="fl color-white" @click="goodsSearch">搜 索</el-button>
					</div>
				</el-col>
			</el-row>
		</div>
		<div class="width-1280 plr-45 pt-50">
			<router-view></router-view>
		</div>
	</div>
</template>

<script>
	import { basePath } from "../api/api";
	export default {
		data() {
			return {
				basePath: basePath,
				goodsName: "",
				activeIndex: '1'
			}
		},
		mounted() {
			this.changeHeight()
		},
		methods: {
			goodsSearch(){//搜索商品
				this.goodsName = this.goodsName.trim();//去除首位空格
				let goodsName = (this.goodsName === null || this.goodsName === "") ? 'goodsName' : this.goodsName;
				this.$router.push(basePath+'/goodsSearch/classify/brand/' + goodsName );
			},
			changeHeight() {
				var height = document.documentElement.clientHeight;
				document.getElementById("shopingSearch").style.minHeight = height - 36-50 + "px";

			}
		}
	}
</script>
<style lang='less'>
	#shopingSearch .el-col-6{
		width: 199px;
		.dib.cursor{
			width: 190px;
			height: 39px;
			margin-top: 10px;
			vertical-align: middle;
		}
	}
	#shopingSearch .platform{
		display: inline-block;
		color: #ff4201;
		/*// vertical-align: top;*/
		/*// line-height: 20px;*/
		font-size: 24px;
		/*// margin-top: 5px;*/
		vertical-align: middle;
	}
	#shopingSearch .el-col-18 .el-input__inner{
		border: 2px solid #ff4201;
	}
	#shopingSearch .el-col-18 .el-input__inner,
	#shopingSearch .el-col-18 .el-input {
		width: 600px;
		height: 36px !important;
	}
	#shopingSearch .el-col-18 .el-button {
		width: 80px;
		height: 36px;
		font-size: 16px;
		background-color: #ff4201;
		border: 0;
		border-radius: 0;
	}
	#shopingSearch .el-col-18 .el-button:hover{
		color: #fff;
	}
</style>
