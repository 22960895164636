<!-- 省/市/区/街道、4级select -->
<template>
  <div class="cityCascaderBox">
    <el-cascader
      @change="Change"
      placeholder="请选择"
      clearable
      v-model="dataValue"
      :options="data"
      :props="props"
      :disabled="disabled"
      :show-all-levels="showAllLevels"
    ></el-cascader>
  </div>
</template>

<script>
export default {
  props: {
    value:{}, // 
    showAllLevels:{
      default: false 
    },
    checkStrictly: {
      default: true  
    },
    emitPath: {
      default: false  
    }, // 返回数组true 还是字符串 false
    num: {
      default: 4
    }, // 层级 省市区街道 
    disabled:{
      default: false
    }
  },
  data() {
    return {
      data: [],
      props: {
        value: 'id',
        label: 'name',
        children: 'childs',
        checkStrictly: this.checkStrictly,
        emitPath: this.emitPath
      },
      dataValue: [],
    };
  },
   watch: {
    value(newVal,oldVal){
      this.dataValue = newVal
    }
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  mounted: function () {
    this.dataValue = this.value

    this.getList(this.num)
  },
  methods: {
    Change: function (val) {
      
      this.$emit("change", val);
      if(this.emitPath){
        this.init(val)
      }
    },
    init(val){
      if (!val) {
        return
      }
      if (val[0]) {
        let provinceData = this.data.filter(item => item.id == val[0])
        if (provinceData.length) {
          var provinceName = provinceData[0].name
          // 市级列表
          this.cityData = provinceData[0].childs
          this.$emit('changeName',[provinceName])
          if(this.num>1){
            let cityData = this.cityData.filter(item => item.id == val[1])
            if (cityData.length) {
              var cityName = cityData[0].name
              // 区级列表
              this.areaData = cityData[0].childs
              this.$emit('changeName',[provinceName,cityName])
              if(this.num>2){
                let areaData = this.areaData.filter(item => item.id == val[2])
                if (areaData.length) {
                  var areaName = areaData[0].name
                  // 街道列表
                  this.streetData = areaData[0].childs
                  this.$emit('changeName',[provinceName,cityName,areaName])
                  if(this.num>3){
                    let streetData = this.streetData.filter(item => item.id == val[3])
                    if (streetData.length) {
                      var streetName = streetData[0].name
                      // 返回省市区街道name
                      this.$emit('changeName',[provinceName,cityName,areaName,streetName])
                    }
                  }
                }
              }
              
            }
          }
          
        }
      }
    },
    getList(num){
      var list = JSON.parse(localStorage.getItem("areaList"));
      if(num == 4) {
        this.data = list
        return
      }
     
      this.data = list.map(item =>{
        if(num==1){
          delete item.childs
        }else{
          item.childs = item.childs.map(item2=>{
            if(num==2){
              delete item2.childs
            }else{
              item2.childs = item2.childs.map(item3=>{
                delete item3.childs
                return item3
              })
            }
            return item2
          })
        }
        return item
      })
    },
  },
};
</script>

<style lang="less" scoped>

</style>
