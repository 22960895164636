<template>
    <div id="hotelManage" class="">
        <div class="title-top bg-f mb-5">
            <div class="clearfix black">
                <el-breadcrumb class="fl ">
                    <el-breadcrumb-item>酒店管理</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
        </div>
        <div class="content bg-f  pb-50">

            <div class="clearfix search">
                <el-form :inline="true" :model="form" label-width="70px" class="clearfix pl-20 pt-30"
                         style="width: 1000px">
                    <div class="fl" style="width: 690px">
                        <el-form-item label="酒店名称">
                            <el-input v-model.trim="form.buyerLoginName" @keyup.enter.native='achieve(true)'></el-input>
                        </el-form-item>
                        <el-form-item label="酒店账号">
                            <el-input v-model.trim="form.buyerStoreName" @keyup.enter.native='achieve(true)'></el-input>
                        </el-form-item>
                    </div>
                    <el-button type="info" class="ml-10" @click='achieve(true)' icon="el-icon-search">查 询</el-button>

                </el-form>
                <div class='clearfix'>
                    <span class="fr color-blue cursor mr-30"
                          @click="dialogShow = true">添加酒店</span>
                </div>
                <el-table :data="tableData" v-loading="Loading" class='ml-30 mt-20 table-default mr-30' stripe>
                    <el-table-column prop="1" label="酒店名称/编号">
                        <template slot-scope="scope">
                            <div class="info flex clearfix ">
                                <div class="flex-1 ml-10 mt-10 hidden">
                                    <div class="tal ">{{scope.row.buyerStoreName}}</div>
                                    <div class="tal">{{scope.row.buyerNumber}}</div>
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="酒店账号" prop="buyerLoginName"></el-table-column>
                    <el-table-column prop="1" label="联系人/电话">
                        <template slot-scope="scope">
                            <div class="info flex clearfix ">
                                <div class="flex-1 ml-10 mt-10 hidden">
                                    <div class="tal ">{{scope.row.linkname}}</div>
                                    <div class="tal">{{scope.row.lineTel}}</div>
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="地区" prop="addr"></el-table-column>
                    <el-table-column label="酒水金额">
                        <template slot-scope="scope">
                            <span>￥{{scope.row.wineAmount | fmoney}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="酒水单（笔）" prop="wineCount"></el-table-column>
                    <el-table-column label="状态">
                        <template slot-scope="scope">
                            <span>{{ scope.row.status==2? '禁用' : '启用' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作">
                        <template slot-scope="scope">
                            <el-button v-if="scope.row.status==1" class='ml-0 width-50' size="small" type='text'
                                       @click="disableItem(scope,2)">禁用
                            </el-button>
                            <el-button v-else class='ml-0 width-50' size="small" type='text'
                                       @click="disableItem(scope,1)">启用
                            </el-button>
                        </template>
                    </el-table-column>

                </el-table>

                <el-pagination v-if="total>0" class="tar mt-20 mr-30" :current-page="currentPage" :page-size='10'
                               :total='total'
                               layout="prev , pager, next, jumper" @current-change="handleCurrentChange">
                    <!-- 分页组件 -->
                </el-pagination>
            </div>
        </div>
        <el-dialog class="minMop" title="添加酒店" :visible.sync='dialogShow'>
            <el-form class="pr-30" :model="form1" ref="ruleForm" :rules='rules'>
                <el-form-item label="账号:" prop='account'>
                    <el-input placeholder='输入账号' v-model.trim='form1.account'></el-input>
                </el-form-item>
            </el-form>
            <div slot='footer' class="dialog-footer">
                <el-button type='danger' @click="save">添 加</el-button>
                <el-button @click="dialogShow = false">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {axiosApi, basePath} from "../../api/api";

    export default {
        data() {
            return {
                dialogShow: false,
                Loading: true,
                form: { //搜索条件
                    buyerLoginName: null,
                    buyerStoreName: null,
                },
                form1: {
                    account: '',
                },
                tableData: [], //员工列表
                currentPage: 1,
                total: 0,
                rules: {
                    account: [
                        {required: true, message: '请输入酒店账号',}
                    ],
                }
            }
        },
        watch: {
            dialogShow(val) {
                if (!val) {
                    this.form.account = null;
                    this.$refs.ruleForm.resetFields()
                }
            }
        },
        methods: {
            achieve(bool) {
                if (bool && this.currentPage != 1) {
                    this.currentPage = 1;
                }
                const api = "/catering/platform/hotel/selectPage";
                const body = {
                    buyerLoginName: this.form.buyerLoginName ? this.form.buyerLoginName : null,
                    buyerStoreName: this.form.buyerStoreName ? this.form.buyerStoreName : null
                }
                axiosApi(api, body, this.callback, this.currentPage, 10)
            },
            callback(res) {
                this.tableData = res.data.data.elements
                this.total = res.data.data.totalCount
                this.Loading = false
            },
            disableItem(scope, status) {
                let type = status == 2 ? "禁用" : "启用";

                this.$confirm("将" + type + "该酒店, 是否继续?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                }).then(() => {
                    axiosApi('/catering/platform/hotel/status/update', {
                        id: scope.row.id,
                        status: status,
                    }, (res) => {
                        this.$message({
                            type: 'success',
                            duration: 1000,
                            message: type + "成功!",
                        });
                        this.achieve(true);
                    }, this.currentPage, 10, (res) => {
                        this.$message.error(res.data.message)
                    })
                }).catch(() => {
                    this.$message({
                        message: "已取消" + type,
                    });
                });
            },
            save() {
                this.$refs.ruleForm.validate((v) => {
                    if (v) {
                        axiosApi('/catering/platform/hotel/insert', {
                            buyerLoginName: this.form1.account,
                        }, (res) => {
                            this.$message({
                                type: 'success',
                                duration: 1000,
                                message: "添加成功!",
                            });
                            this.achieve(true);
                            this.dialogShow = false;
                        }, this.currentPage, 10, (res) => {
                            this.$message.error(res.data.message)
                        })
                    }
                })
            },
            handleCurrentChange(val) {
                this.currentPage = val
                this.achieve()
            },
        },
        //进入加载
        mounted() {
            this.$nextTick(function () {
                this.achieve();
            })
        }
    }
</script>

<style lang="less">
    #hotelManage {

        .search .el-form-item__label {
            width: 90px;
        }

        .el-dialog {
            height: 300px !important;
            min-height: 300px !important;
        }

        .el-form-item__error {
            margin-left: 185px !important;
        }

        .title-top {
            border: 0;
        }

        .black {
            .edit-btn span {
                color: #000000 !important;
            }
        }

        .el-table {
            overflow: hidden;
            max-width: 100%;
            background-color: #fff;
            border: 1px solid #dfe6ec;
            font-size: 12px;
            color: #1f2d3d;
        }

    }
</style>