<template>
	<div id="registerTop">
		<!--注册相关界面-->
		<StoreTop v-if="wtop"></StoreTop>
		<div class="logo">
			<div class="width-1280 clearfix logo-content">
				<div class="fl" >
					<router-link to="/customer/shop/shopIndex">
						<img class="cursor mt-18" src="../assets/logo-r.png" />
					</router-link>
				</div>
				<div class="fr" v-if="log">
					<div class="color-6 font-16 cursor" v-if="register" @click="goPage('/login')">
						登录
					</div>
					<div v-else>
						<span class="color-6 font-16 cursor lh-16 dib" @click="goPage('/login')">登录</span>
						<!-- <span class="h16 dib ml-20 mr-20 sub"></span>
						<span class="color-6 font-16 cursor lh-16 dib" @click="goPage('/register/step')">注册</span> -->
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import StoreTop from './storeTop.vue'
	import { basePath,gotoLoginPage } from '~/api/api'
	export default {
		data() {
			return {
				tel: "400-8888-9979",
				register: false,
				wtop: false,
				log: true
			}
		},
		mounted() {
			this.$nextTick(() => {
				if(this.$route.path.indexOf("step") > 0) {
					this.register = true;
				}
				if(sessionStorage.getItem("loginName")) {
					this.wtop = true;
					this.log = false;
				}
			})
		},
		components: {
			StoreTop
		},
		methods: {
			goPage(path) {
			    if (path == "/login") {
			        gotoLoginPage();
			        return;
			    }

				if (path == "/register/step") {
				    this.register = true;
				}
				this.$router.push(basePath + path);
			}
		}
	}
</script>

<style lang="less">
	#registerTop {
		.logo {
			box-sizing: border-box;
			height: 82px;
			line-height: 80px;
			.h16 {
				height: 16px;
				width: 1px;
				background: #666;
			}
			.logo-content{
				padding: 0 45px;
				border-bottom: 2px solid #ddd;
				vertical-align: middle;
				.fl{
					height: 80px;
					.mt-18{
						margin-top: 20px;
						width: 190px;
					}
				}
			}
		}
	}
</style>