<template>
	<div class="register">
		<header>
			<RegisterTop></RegisterTop>
		</header>
		<div class="step">
			<router-view></router-view>
		</div>
		<div class="foot">
			<foot></foot>
		</div>
	</div>
</template>

<script>
	import RegisterTop from "../../components/registerTop.vue";
	import Foot from "../../components/foot.vue";
	export default {
		components: {
			RegisterTop,
			Foot
		},
		mounted() {
			this.changeHeight();
		},
		methods: {
			changeHeight() {
				var height = document.documentElement.clientHeight;
				document.getElementsByClassName("step")[0].style.minHeight = height - 240 + "px";
			}
		}
	}
</script>

<style lang="less">
	.foot {
		.width-1280 {
			width: 1190px;
			border-top: 2px solid #eee;
		}
		div p {
            color: #333;
            a{
                color: #333!important;
            }
		}
	}
</style>