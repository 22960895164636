<template>
    <div id="tableSet" class="">
        <div class="title-top bg-f mb-5">
            <div class="clearfix black">
                <el-breadcrumb class="fl ">
                    <el-breadcrumb-item>酒桌设置</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
        </div>
        <div class="content bg-f pb-50">
            <div class="clearfix search">
                <el-form :inline="true" :model="form1" label-width="50px" class="clearfix pl-20 pt-30"
                         style="width: 1000px">
                    <div class="fl" style="width: 300px">
                        <el-form-item label="桌号">
                            <el-input v-model.trim="form1.tableNo" placeholder="输入桌号"
                                      @keyup.enter.native='achieve(true)'></el-input>
                        </el-form-item>
                    </div>
                    <el-button type="info" class="fl ml-30" @click='achieve(true)'>查询</el-button>

                </el-form>
                <div class='clearfix'>
                    <span class="fr color-blue cursor mr-50"
                          @click="classManage()">类别维护</span>
                    <span class="fr color-blue cursor mr-20"
                          @click="dialogShow = true">添加新桌号</span>
                    <span class="fr color-blue cursor mr-20"
                          @click="getQR()">批量下载二维码</span>
                </div>
                <el-table :data="tableData" v-loading="Loading" class='ml-30 mt-20 table-default mr-30' stripe>
                    <el-table-column label="桌号" prop="tableNo"></el-table-column>
                    <el-table-column label="类别名" prop="categoryName"></el-table-column>
                    <el-table-column label="类别属性" prop="describe"></el-table-column>
                    <el-table-column label="人数" prop="maxNumberPeople">
                        <template slot-scope="scope">
                            <div v-if="scope.row.minNumberPeople!=null">
                                {{scope.row.minNumberPeople}}-{{scope.row.maxNumberPeople}}人
                            </div>
                            <div v-else>不限</div>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="100">
                        <template slot-scope="scope">
                            <el-button size="small" type='text'
                                       @click.native="updateItem(scope)">编辑
                            </el-button>
                            <el-button class='ml-0 width-90' size="small" type='text'
                                       @click.native="deleteItem(scope)">删除
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>

                <el-pagination v-if="total>0" class="tar mr-30" :current-page="currentPage" :page-size='10'
                               :total='total'
                               layout="prev , pager, next, jumper" @current-change="handleCurrentChange">
                    <!-- 分页组件 -->
                </el-pagination>
            </div>
        </div>

        <!--添加服务员弹窗-->
        <el-dialog :title="isUpdate?'编辑':'添加桌号'"
                   :visible.sync='dialogShow'
                   @close="closeDialog1">
            <div :class="{'bb':form.tableList.length>6,'bbb':form.tableList.length<7}">
                <el-form :model="form" ref="ruleForm" :rules='rules' class="ml-30">
                    <el-form-item label-width="0px">
                        <ul>
                            <li class="mb-10" v-for="(item,index) in form.tableList" :key="index"
                                style="display: flex;align-items: center;">
                                <span>&nbsp; &nbsp; &nbsp; 桌号:</span>
                                <el-input size='mini' placeholder="输入桌号"
                                          v-model.trim="item.tableNo" type="text" :maxlength="5"></el-input>
                                <span>&nbsp; &nbsp; &nbsp; 人数:</span>
                                <el-select clearable size='mini' v-model.trim="item.minNumberPeople" placeholder="请选择">
                                    <el-option
                                            v-for="item in options"
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item.value">
                                    </el-option>
                                </el-select>
                                <span>-</span>
                                <el-select clearable size='mini' v-model.trim="item.maxNumberPeople" placeholder="请选择">
                                    <el-option
                                            v-for="item in options"
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item.value">
                                    </el-option>
                                </el-select>
                                <span>&nbsp; &nbsp; &nbsp; 类别:</span>
                                <el-select clearable v-model.trim="item.categoryId" placeholder="请选择" class="aa">
                                    <el-option
                                            v-for="item in categoryOptions"
                                            :key="item.id"
                                            :label="item.categoryName"
                                            :value="item.id">
                                    </el-option>
                                </el-select>
                                <img v-if="form.tableList.length!=1" class="icon-24 ml-10"
                                     src="../../assets/jicai_icon_del.png"
                                     @click="delPrice(index)"/>
                            </li>
                        </ul>
                    </el-form-item>
                </el-form>
                <span class=" ml-60 color_blue cursor" @click="addPrice"
                      v-if="!isUpdate">+ 添加桌号</span>
            </div>

            <div slot='footer' class="dialog-footer">
                <el-button type='danger' @click="save">确 定</el-button>
                <el-button @click="dialogShow = false">取 消</el-button>
            </div>

        </el-dialog>
        <form ref="downQR" :action='url' style="display: none">

        </form>
    </div>
</template>

<script>
    import {axiosApi, basePath} from "../../api/api";

    export default {
        data() {
            return {
                dialogShow: false,
                options: [],
                categoryOptions: [],
                Loading: true,
                form: {
                    tableList: [{
                        tableNo: null,
                        minNumberPeople: null,
                        maxNumberPeople: null,
                        categoryId: null
                    }],
                },
                form1: { //搜索条件
                    tableNo: null,
                },
                isUpdate: false,
                tableData: [], //员工列表
                currentPage: 1,
                total: 0,
                rules: {},
                url: null
            }
        },
        methods: {
            closeDialog1() {
                setTimeout(() => {
                    this.form.tableList = [];
                    this.addPrice();
                    this.isUpdate = false;
                }, 500)
            },
            achieve(bool) {
                if (bool && this.currentPage != 1) {
                    this.currentPage = 1;
                }
                const api = "/catering/platform/table/selectList";
                const body = {
                    tableNo: this.form1.tableNo ? this.form1.tableNo : null
                }
                axiosApi(api, body, this.callback, this.currentPage, 10)
            },

            callback(res) {
                this.tableData = res.data.data.elements
                this.total = res.data.data.totalCount
                this.Loading = false
            },

            handleCurrentChange(val) {
                this.currentPage = val
                this.achieve()
            },

            updateItem(scope) {
                this.form.tableList = [];
                this.form.tableList.push({
                    id: scope.row.id,
                    tableNo: scope.row.tableNo,
                    minNumberPeople: scope.row.minNumberPeople,
                    maxNumberPeople: scope.row.maxNumberPeople,
                    categoryId: scope.row.categoryId
                });
                this.isUpdate = true;
                this.dialogShow = true;
            },

            getQR() {
                if (this.total == 0) {
                    this.$message({
                        type: 'error',
                        message: '暂无数据可导出',
                        duration: 1000
                    })
                    return false
                }

                this.Loading = true;
                axiosApi('/catering/platform/table/exportQrCode', {}, (res) => {
                    this.Loading = false;
                    this.url = res.data.data.downloadUrl;
                    setTimeout(() => {
                        this.$refs.downQR.submit();
                    }, 0)
                }, this.currentPage, 10)
            },

            classManage() {
                this.$router.push({path: 'classManage', query: null});
            },

            addPrice() {
                this.form.tableList.push({
                    tableNo: null,
                    minNumberPeople: null,
                    maxNumberPeople: null,
                    categoryId: null
                });
            },

            delPrice(index) {
                this.form.tableList.splice(index, 1);
            },

            save() {
                this.$refs.ruleForm.validate((v) => {
                    if (v) {
                        this.form.tableList.forEach(item => {
                            if (item.maxNumberPeople < item.minNumberPeople) {
                                let a = item.maxNumberPeople;
                                item.maxNumberPeople = item.minNumberPeople;
                                item.minNumberPeople = a;
                            }
                        });
                        if (this.isUpdate) {
                            let body = this.form.tableList[0];
                            this.insert(body, '/catering/platform/table/update') // 添加
                                .then(res => {
                                    this.$message({
                                        type: 'success',
                                        duration: 1000,
                                        message: "更新成功!",
                                    });
                                    this.dialogShow = false;
                                    this.achieve(true);
                                })
                                .catch(res => {
                                    this.$message.error(res.data.message)
                                });
                        } else {
                            let body = this.form.tableList;
                            this.insert(body, '/catering/platform/table/insert') // 添加
                                .then(res => {
                                    this.$message({
                                        type: 'success',
                                        duration: 1000,
                                        message: "添加成功!",
                                    });
                                    this.dialogShow = false;
                                    this.achieve(true);
                                })
                                .catch(res => {
                                    this.$message.error(res.data.message)
                                });
                        }
                    }
                })
            },

            insert(body, url) {// 插入接口
                this.btnLoading = true
                return new Promise((resolve, reject) => {
                    axiosApi(url, body, res => {
                        resolve(res)
                        this.btnLoading = false
                    }, 1, 10, res => {
                        reject(res)
                        this.btnLoading = false
                    })
                })
            },

            getCategory() {
                axiosApi("/catering/platform/tableCategory/selectList", {}, (res) => {
                    this.categoryOptions = res.data.data;
                }, this.currentPage, 10)
            },

            setOptions() {
                for (let i = 1; i < 21; i++) {
                    let obj = {
                        value: null,
                        label: null
                    }
                    obj.value = i;
                    obj.label = i;
                    this.options.push(obj)
                }
            },

            deleteItem(scope) {
                this.$confirm("将删除酒桌, 是否继续?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                }).then(() => {
                    axiosApi('/catering/platform/table/delete', {
                        id: scope.row.id,
                    }, (res) => {
                        this.$message({
                            type: 'success',
                            duration: 1000,
                            message: "删除成功!",
                        });
                        this.achieve(true);
                    }, this.currentPage, 10, (res) => {
                        this.$message.error(res.data.message)
                    })
                }).catch(() => {
                    this.$message({
                        message: "已取消删除",
                    });
                });
            }
        },
        //进入加载
        mounted() {
            this.$nextTick(function () {
                this.achieve();
                this.getCategory();
                this.setOptions();
            })
        }
    }
</script>

<style lang="less">
    #tableSet {
        .el-dialog{
            width: 860px;
            min-height: 400px;
        }
        .el-dialog__body{
            padding:30px;
            min-height: 400px;
        }
        .minMops {
            height: 300px;;
        }

        .bb {
            overflow-y: scroll !important;
            height: 300px;
        }
        .bbb {
            height: 300px;
            overflow-y:auto;
        }

        .aa {
            .el-input {
                width: 190px;
                height: 32px;
            }
        }

        .el-dialog {
            height: 500px !important;
            width: 800px !important;
            min-height: 500px !important;
            min-width: 800px !important;
        }

        .color_blue {
            color: #20a0ff;
            padding: 10px;
            Border: 1px dashed #DDDDDD;
        }

        .el-table {
            overflow: hidden;
            width: 94%;
            max-width: 100%;
            background-color: #fff;
            border: 1px solid #dfe6ec;
            font-size: 12px;
            color: #1f2d3d;
        }


        .title-top {
            border: 0;
        }

        .black {
            .edit-btn span {
                color: #000000 !important;
            }
        }

    }
</style>