<template>
	<div id="groupManager" class="pb-50">
		<div class="title-top bg-f">团购管理</div>
		<div class="bg-f mt-5 pb-50">
			<div class="content bg-f pl-30 pr-30 pb-50">
                <!-- 状态切换区域 -->
                <div class="tab_box">
                    <div class="tab_item" :class="{tab_item_checked: item.type==state}" v-for="(item,index) in tabList" :key="index" @click="checkState(item)">
                        <span>{{item.label}}</span>
                        <span>({{item.bage ? item.bage : 0}})</span>
                    </div>
                </div>
                <!-- 搜索区域 -->
                <div class="clearfix search">
                    <el-form :inline='true' class="pt-30" :model="Form" ref="searchForm">
                        <el-form-item label='商家账号' class="mr-20">
                            <el-input v-model.trim="Form.loginName" :maxlength="30" placeholder="请输入商家账号"></el-input>
                        </el-form-item>
                        <el-form-item label='商家名称' class='mr-20'>
                            <el-input v-model.trim="Form.traderName" :maxlength="30" placeholder="请输入商家名称"></el-input>
                        </el-form-item>
                        <el-form-item label='团购名称'>
                            <el-input v-model.trim="Form.grouponName" :maxlength="30" placeholder="请输入团购名称"></el-input>
                        </el-form-item>
                    </el-form>
                    <div>
                      <el-button type='info' @click="getGroupList()" icon="el-icon-search">搜 索</el-button>
                    </div>
                    <div style="text-align:right;padding:20px 0px">
                      <el-link type="primary" :underline="false" @click="()=>{this.$router.push('addGroup')}">新增团购</el-link>
                    </div>
                </div>
                <!-- 列表区域 -->
                <div class="pb-30">
                    <el-table :data="GroupList" class='table-default'>
                        <el-table-column label='商家账号' width="">
                            <template slot-scope="scope">
                                <span>{{scope.row.traderInfo.loginName}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label='商家名称' width="">
                            <template slot-scope="scope">
                                <span>{{scope.row.traderInfo.storeName}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label='团购名称' width="">
                            <template slot-scope="scope">
                                <span>{{scope.row.grouponName}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label='参团企业' width="">
                            <template slot-scope="scope">
                                <span>{{scope.row.companyInfo.companyName}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label='团购时间' width="">
                            <template slot-scope="scope">
                                <span>{{scope.row.gmtStart|timenData}}至{{scope.row.gmtEnd|timenData}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label='全部状态' width="">
                            <template slot-scope="scope">
                                <span v-if="scope.row.status==1">未开始</span>
                                <span v-if="scope.row.status==2">进行中</span>
                                <span v-if="scope.row.status==3">已结束</span>
                                <span v-if="scope.row.status==4">已关闭</span>
                            </template>
                        </el-table-column>
                        <el-table-column label='审核人员' width="">
                            <template slot-scope="scope">
                                <span v-if="scope.row.auditTraderName && scope.row.auditTraderName!='0'">{{scope.row.auditTraderName}}</span>
                                <span v-else>-</span>
                            </template>
                        </el-table-column>
                        <el-table-column label='操作' width="">
                            <template slot-scope="scope">
                                <div class="btnDiv">
                                  <el-button type='text' style="width: 90px;" @click="goDetail(scope)">查看团购</el-button>
                                  <el-popover
                                    v-if="scope.row.shareUrl" 
                                    placement="right"
                                    width="400"
                                    trigger="click">
                                    <a :href="scope.row.shareUrl">
                                      <el-image :src="scope.row.shareUrl"></el-image>
                                    </a>
                                    <el-button  slot="reference" type='text' style="width: 90px;" >查看图片</el-button>
                                  </el-popover>
                                  <div v-else>
                                    <el-button v-if="scope.row.status==1||scope.row.status==2" type='text' style="width: 90px;" @click="shareInfo(scope.row.id)">生成分享图</el-button>
                                  </div>
                                  <el-button type='text' v-if="scope.row.status==1||scope.row.status==2" style="width: 90px;" @click="closeGroup(scope.row.id)">关闭团购</el-button>
                                </div>
                                 
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-pagination class="tar mt-20"
                        v-if="GroupList.length>0" 
                        :current-page="currentPage" 
                        :page-size='10' 
                        :total='total' 
                        layout="prev , pager, next, jumper"
                         @current-change="handleCurrentChange">
                        <!-- 分页组件 -->
                    </el-pagination>
                </div>
            </div>
        </div>
  </div>
</template>

<script>
import axios from "axios";
import { axiosApi, basePath, baseURI } from "../../api/api";
export default {
  data() {
    return {
      tabList: [
        {
          label: "全部团购",
          type: 0,
          bage: 0
        },
        {
          label: "未开始",
          type: 1,
          bage: 0
        },
        {
          label: "进行中",
          type: 2,
          bage: 0
        },
        {
          label: "已结束",
          type: 3,
          bage: 0
        },
        {
          label: "已关闭",
          type: 4,
          bage: 0
        }
      ],
      state: 0, //全部 0  未开始 1 进行中 2 已结束 3 已关闭 4
      currentPage: 1,
      total: 0,
      GroupList: [],
      Form: {
        traderName: null, //卖家名称
        loginName: null, //账号
        grouponName: null
      }
    };
  },
  //进入加载
  activated() {
    this.$nextTick(function() {
      this.getGroupList();
      this.getBage();
    });
  },
  methods: {
    search() {
      if (this.currentPage === 1) {
        this.getGroupList(this.formInlineData);
      } else {
        this.currentPage = 1; //赋值自动调用一次接口
      }
    },
    getBage() {
      //获取角标
      axiosApi(
        "/groupon/countStatus/select",
        {},
        res => {
          this.tabList.forEach(item => {
            if (item.type == 0) {
              item.bage = res.data.data.allCount;
            } else if (item.type == 1) {
              item.bage = res.data.data.hadNotCount;
            } else if (item.type == 2) {
              item.bage = res.data.data.inProgressCount;
            } else if (item.type == 3) {
              item.bage = res.data.data.endCount;
            } else if (item.type == 4) {
              item.bage = res.data.data.closedCount;
            }
          });
        },
        1,
        10
      );
    },
    getGroupList() {
      axiosApi(
        "/groupon/parentGroupon/list",
        {
          sellerId: sessionStorage.traderId,
          status: this.state,
          loginName: this.Form.loginName ? this.Form.loginName : null,
          traderName: this.Form.traderName ? this.Form.traderName : null,
          grouponName: this.Form.grouponName ? this.Form.grouponName : null
        },
        res => {
          this.GroupList = res.data.data.elements ? res.data.data.elements : [];
          this.total = res.data.data.totalCount;
        },
        this.currentPage,
        10,
        res => {}
      );
    },
    checkState(val) {
      //切换状态
      this.state = val.type;
      this.currentPage = 1;
      this.getGroupList();
    },
    handleCurrentChange(val) {
      //分页
      this.currentPage = val;
      this.getGroupList();
    },
    goDetail(scope) {
      //跳转团购详情
      this.$router.push({ path: "groupDetail", query: { id: scope.row.id } });
    },
    // 生成分享图
    shareInfo(id){
      axiosApi(
        "/groupon/web/sharePicUrl/generate",
        {id:id},
        res => {
          this.$message({
            message: '生成分享图片成功！',
            type: 'success'
          });
          this.getGroupList();
          this.getBage();
        },
        null,
        null
      );
    },
    closeGroup(id){
      this.$confirm('此操作将关闭该团购, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        axiosApi(
          "/groupon/groupon/close",
          {id:id},
          res => {
            this.$message({
              type: 'success',
              message: '关闭成功!'
            });
            this.currentPage = 1;
            this.getGroupList();
            this.getBage();
          },
          null,
          null
        );
        
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });          
      });
    },
  }
};
</script>

<style lang="less">
#groupManager {
  .tab_box {
    height: 60px;
    line-height: 60px;
    box-sizing: border-box;
    border-bottom: 1px solid #eee;
    .tab_item {
      display: inline-block;
      width: 114px;
      height: 26px;
      line-height: 26px;
      text-align: center;
      box-sizing: border-box;
      color: #666;
      font-size: 14px;
      cursor: pointer;
    }
    .tab_item:hover {
      color: #ff4201;
    }
    .tab_item_checked {
      border-radius: 2px;
      border: 1px solid #ff4201;
      color: #ff4201;
    }
  }
  .btnDiv{
    .el-button+.el-button {
        margin-left: 0px;
    }
  }
}
</style>