<template>
  <div class="hotel-report">
    <div class="title-top bg-f clearfix">
      <div class="color-3">销售报表</div>
    </div>

    <div class="content bg-f pb-50 pl-30 pr-30 mt-5" v-loading="loading">
      <div class="tp-ct">今日实时数据更新： {{reloadTime | time}} <el-button type="text" :loading="reloadLoading" @click="reload">刷新</el-button></div>
      <div class="ct-border">
        <div class="ct1">
          <div>久集整体数据（利润及积分仅统计已付款订单数据）</div>
          <div class="ct1-rt">
            <div class="btn1" :class="{selected: timeType === 0}" @click="changeDate(0)">今天</div>
            <div class="btn2" :class="{selected: timeType === 1}" @click="changeDate(1)">本周</div>
            <el-form :inline='true' :model="form">
              <el-form-item label='自定义'>
                <ys-daterange-picker v-model="form.timeRange" :endDisabledDate="new Date()"/>
              </el-form-item>
            </el-form>
            <el-button type="info" class="ml-10" @click="changeDate(2)">查询</el-button>
          </div>
        </div>

        <div class="num-wrap">
          <div class="num1">
            <div>
              <p>{{dataList.sellerCommission}}</p>
              <p>酒店利润</p>
            </div>
            <b class="mt-10">|</b>
            <div>
              <p>{{dataList.commission}}</p>
              <p>销售员积分</p>
            </div>
          </div>
          <div class="num2">
            <div>
              <p>{{dataList.orderNum}}</p>
              <p>订单数量</p>
            </div>
            <b>|</b>
            <div>
              <p>{{dataList.needPayMoney}}</p>
              <p>订单金额</p>
            </div>
            <b>|</b>
            <div>
              <p>{{dataList.shareNum}}</p>
              <p>分享次数</p>
            </div>
            <b>|</b>
            <div>
              <p>{{dataList.pageView}}</p>
              <p>浏览次数</p>
            </div>
            <b>|</b>
            <div>
              <p>{{dataList.uniqueVisitor}}</p>
              <p>访问人数</p>
            </div>
          </div>
        </div>

        <div class="tb-wrap">
          <el-table :data="tableData" stripe  @sort-change="changeSort" border>
            <el-table-column label="销售员账号" width="155">
              <template slot-scope="scope">
                <span>{{scope.row.loginName}}</span>
              </template>
            </el-table-column>
            <el-table-column label="店铺名" width="155">
              <template slot-scope="scope">
                <span>{{scope.row.storeName}}</span>
              </template>
            </el-table-column>
            <el-table-column label="订单金额" width="155" sortable="custom">
              <template slot-scope="scope">
                <span>{{scope.row.needPayMoney}}</span>
              </template>
            </el-table-column>
            <el-table-column label="酒店利润" width="155" sortable="custom">
              <template slot-scope="scope">
                <span>{{scope.row.sellerCommission}}</span>
              </template>
            </el-table-column>
            <el-table-column label="销售员积分" width="155" sortable="custom">
              <template slot-scope="scope">
                <span>{{scope.row.commission}}</span>
              </template>
            </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-button @click="toGoodsDetail(scope.row)" type="text">查看商品详情</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { axiosApi, basePath } from "../../../api/api";
export default {
  data() {
    return {
      form: {
        timeRange: []
      },
      startTime: null,
      endTime: null,
      timeType: 0,
      reloadLoading: false,
      reloadTime: null,
      dataList: {},
      tableData: [],
      searchForm: {
        orderByType: null,
        orderByColumn: null
      },
      loading: false
    };
  },
  watch: {
    timeType(val) {
      if (!this.reloadLoading && val != 2){
        this.achieve()
      }
    }
  },
  methods: {
    reload() {
      this.reloadLoading = true
      // reset all selection
      this.searchForm = {
        orderByType: null,
        orderByColumn: null
      }
      this.startTime = new Date().setHours(0, 0, 0)
      this.endTime = new Date().setHours(23, 59, 59)
      this.form.timeRange = []
      this.timeType = 0
      setTimeout(() => {
        this.achieve()
        this.reloadLoading = false
      }, 200)
    },
    getStart(day) {
      day = day === 0 ? 7 : day
      let t = new Date().setHours(0, 0, 0) - (day - 1) * 24 * 60 * 60 * 1000
      return t;
    },
    changeDate(val) {
      if (val === 0) {
        this.startTime = new Date().setHours(0, 0, 0)
        this.endTime = new Date().setHours(23, 59, 59)
        this.form.timeRange = []
        this.timeType = val
      } else if (val === 1) {
        this.startTime = this.getStart(new Date().getDay())
        this.endTime = new Date().setHours(23, 59, 59)
        this.form.timeRange = []
        this.timeType = val
      } else if (val === 2) {
        let a = this.form.timeRange[0] ? Date.parse(this.form.timeRange[0]) : null
        let b = this.form.timeRange[1] ? this.form.timeRange[1].getTime() : null
        if (a && b) {
          // 自定义时间跨度不超过3年
          if (b - a > 3 * 365 * 24 * 60 * 60 * 1000) {
            this.$message({type: 'warning', message: '自定义时间不可以超过3年', duration: 1500})
            return false
          }
          this.startTime = a
          this.endTime = b
          this.timeType = 2
          this.achieve()
        } else if (!a) {
          this.$message({type: 'warning', message: '请选择开始时间', duration: 1500})
        } else if (!b) {
          this.$message({type: 'warning', message: '请选择结束时间', duration: 1500})
        }
      }
    },
    achieve() {
      this.loading = true;
      axiosApi('/superc/hotelStatistics/selectSellerSalesReport', {
        sellerId: sessionStorage.traderId,
        startTime: this.startTime,
        endTime: this.endTime
      }, res => {
        this.dataList = res.data.data
        this.reloadTime = new Date()
        this.loading = false
      }, 1, 10, err => {
        this.$message({
          type: 'error',
          message: err.data.message,
          duration: 2000
        })
      })
      this.achieve2()
    },
    achieve2() {
      axiosApi('/superc/superStatistics/selectSupercStoreSales', {
        sellerId: sessionStorage.traderId,
        startTime: this.startTime,
        endTime: this.endTime,
        orderByType: this.searchForm.orderByType,
        orderByColumn: this.searchForm.orderByColumn
      }, res => {
        if (res.data.data) {
          this.tableData = res.data.data.elements ? res.data.data.elements : []
        }
      }, 1, 10000, err => {
        this.$message({
          type: 'error',
          message: err.data.message,
          duration: 2000
        })
      })
    },
    changeSort(val) {
      let type = null;
      if (val.column) {
        switch (val.column.label){
          case '订单金额':
            type = 'need_pay_money'
            break;
          case '酒店利润':
            type = 'seller_commission'
            break;
          case '销售员积分':
            type = 'commission'
            break;
        }
        this.searchForm.orderByColumn = type;
        if(val.order=="ascending") {//升序
          this.searchForm.orderByType = 'asc'
        }else if(val.order=="descending") {//降序
          this.searchForm.orderByType = 'desc'
        }
        this.achieve2();//排序执行默认接口
      }
    },
    toGoodsDetail(val) {
      sessionStorage.setItem('hotelReportData', JSON.stringify(
        {
          startTime: this.startTime,
          endTime: this.endTime,
          id: val.id
        }
      ))
      let routeUrl = this.$router.resolve({
        path: basePath + "/hotelReportGoods"
      });
      window.open(routeUrl .href, '_blank');
    }
  },
  created() {
    if (this.startTime === null || this.endTime === null) {
      this.startTime = new Date().setHours(0, 0, 0)
      this.endTime = new Date().setHours(23, 59, 59)
    }
    this.reloadTime = new Date()
    this.achieve()
  }
};
</script>

<style lang="less">
.hotel-report {
  .content {
    color: #666;
    .tp-ct {
      height: 64px;
      line-height: 64px;
    }
    .ct-border {
      border: 1px solid #ddd;
      .ct1 {
        padding: 0 20px;
        background: #eee;
        height: 60px;
        line-height: 60px;
        display: flex;
        justify-content: space-between;
        .ct1-rt {
          display: flex;
          .btn1,.btn2 {
            border: 1px solid #fff;
            width: 60px;
            height: 32px;
            line-height: 32px;
            margin-top: 14px;
            background-color: #fff;
            text-align: center;
            border-radius: 2px;
            cursor: pointer;
            &.selected {
              border: 1px solid #20a0ff;
              background-color: #20a0ff;
              color: #fff;
            }
          }
          .btn1 {
            border-right: 0;
          }
          .el-button {
            width: 60px;
            margin-top: 14px;
          }
           .el-form-item {
            margin: 0;
            .el-form-item__label {
              width: 100px;
            }
            .el-form-item__content {
              vertical-align: middle;
              input {
                font-size: 12px;
              }
            }
          }
        }
      }
      .num-wrap {
        height: 215px;
        border-bottom: 1px solid #ddd;
        & > .num1,.num2 {
          font-size: 12px;
          width: 100%;
          display: flex;
          height: 107px;
          padding: 30px 0;
          box-sizing: border-box;
          b {
            font-size: 30px;
            color: #ddd;
            font-weight: 400;
            margin-top: 5px;
          }
          & > div {
            flex: 1;
            text-align: center;
            &:nth-last-child(1) {
              border: 0;
            }

          }
        }
        .num1 {
          p {
            &:nth-child(1) {
              font-size: 30px;
            }
          }
        }
        .num2 {
          p {
            &:nth-child(1) {
              font-size: 20px;
            }
          }
        }
      }
      .tb-wrap {
        padding: 50px 30px;
        .el-table .cell {
          text-align: center;
          padding: 10px 0;
        }
      }

    }
  }
}
</style>
