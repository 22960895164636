<template>
    <div class="addAndEditRegionBox pb-20">
        <div class="title-top bg-f">
            {{typeText}}
        </div>
        <div class="bg-f mt-5 pb-50 formBox">
            <el-form :model="form" :rules="rules" ref="form" label-width="120px">
                <el-form-item label="区域名称：" prop="groupName">
                    <el-input :maxlength="10" v-model.trim="form.groupName"></el-input>
                </el-form-item>
                <el-form-item label="上级区域：" prop="QYdata">
                    <template v-if="type !== 'edit'">
                        <el-cascader
                        :options="QYlist"
                        v-model="form.QYdata"
                        change-on-select
                        :props="{value : 'id',label : 'districtName',children :'subDistrictList'}"
                        ></el-cascader>
                    </template>
                    <template v-else>
                        {{editQYname}}
                    </template>
                </el-form-item>
                <el-form-item label="包含客户：" prop="rightList">
                    <el-button type="primary" @click="addClick">点击添加</el-button>
                    <div class="dataBox">
                        <div class="Mbtn">预览</div>
                        <ul class="list bd">
                            <template v-for="(item,index) in bhList">
                                <li :key="index" :title="item.storeName + ' ' + item.loginName">{{item.storeName}} {{item.loginName}}</li>
                            </template>
                        </ul>
                    </div>
                </el-form-item>
                <el-form-item class="mt-50" label="">
                    <el-button type="primary" @click="submit">确定</el-button>
                    <el-button @click="cancel">取消</el-button>
                </el-form-item>
            </el-form>
            <!-- 浮层 -->
            <el-dialog @close="handleClose" title="客户分组" :visible.sync="dialogVisible" >
                <div class="popBox">
                    <el-input icon="search" @change="leftSearch" class="leftSearch" placeholder="请输入客户名称" v-model.trim="leftSearchVal" :style="{width:'198px'}"></el-input>
                    <el-transfer :render-content="renderFunc" filterable filter-placeholder="请输入客户名称" :titles="['全部客户', '已选客户']" :buttactive-texts="['移 除', '移至已选']" :data="form.leftList" v-model="form.rightList">
                    </el-transfer>
                    <div class="tishi">
                        温馨提示：蓝色表示该客户已经在一个区域，同一客户不能同时属于两个区域，若拉入另一区域，则默认从之前区域移除。
                    </div>
                </div>
                <div slot="footer" class="dialog-footer">
                    <el-button type="danger" @click="handleSave">确 定</el-button>
                    <el-button @click="handleClose">取 消</el-button>
                </div>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import { axiosApi, basePath } from "../../../api/api"


export default {
    data: function() {
        return ({
            // 当前页面类型
            type: this.$route.query.type,
            typeText: this.$route.query.type === 'add' ? '新建区域' : '修改区域',
            // 包含客户list
            bhList : [],

            // 表单数据
            form: {
                // 组名
                groupName: '',
                // 客户列表左侧数据
                leftList: [
                    {
                        buyerId: '12121212',
                        storeName: '张三',
                        loginName: 13333333333,
                    }
                ],
                // 客户列表右侧数据
                rightList: [],
                // 区域数据 选中
                QYdata : ['0'],
            },
            rules: {
                groupName: [
                    {
                        required: true,
                        message: '输入长度为10以内的区域名',
                    }
                ],
                rightList: [
                    {
                        type: 'array',
                        required: false,
                        message: '选择分组客户',
                        trigger: 'blur',
                    }
                ],
                QYdata: [
                    {
                        type: 'array',
                        required: false,
                        trigger: 'blur',
                    }
                ],
            },

            // 浮层数据
            dialogVisible: false,
            // 左侧搜索val
            leftSearchVal: '',
            // 浮层点击确定 默认的右侧数据
            detailRightList: [],

            // 区域select 数据list
            QYlist : [
                {
                    id : 0,
                    districtName : '全国',
                    subDistrictList : [
                        {
                            id : 222,
                            districtName : '浙江省',
                        }
                    ],
                }
            ],
            // 用于回显的 上级区域名称
            editQYname : '',
        })
    },
    mounted: function() {
        if (this.type === 'edit') {
            this.initEdit(this.$route.query.id);
        }
        // 获取 区域列表
        axiosApi('/trader/customerDistrict/query',{
            traderId : sessionStorage.getItem('traderId'),
        },(res) => {
            res.data.data.id = '0';
            let data = [res.data.data];
            /* let fn = function(data){
                let a = null;
                a = data.map((v,k) => {
                    if(v.subDistrictList.length === 0){
                        v.subDistrictList = null;
                    }else{
                        fn(v.subDistrictList);
                    }
                    return v;
                });
                return a;
            };
            this.data = fn(data); */
            this.QYlist = data;
        });
    },
    methods: {
        // 初始化 修改
        initEdit: function(id) {
            let sendUrl = '/trader/customerDistrict/query';
            let sendObj = {
                traderId: sessionStorage.getItem('traderId'),
                id: id,
            };
            axiosApi(sendUrl,sendObj,(res) => {
                let data = res.data;
                this.form.groupName = data.data.districtName;
                this.form.rightList = data.data.buyerList.map((v) => {
                    let obj = {
                        storeName : v.storeName,
                        loginName : v.loginName,
                        buyerId : v.buyerId,
                    };
                    return JSON.stringify(obj);
                });
                this.bhList = this.form.rightList.map((v,k) => {
                    return JSON.parse(v)
                });
                this.detailRightList = this.form.rightList;
                this.editQYname = data.data.parentDistrictName;
            });
        },
        // 点击添加
        addClick: function() {
            this.dialogVisible = true;
            this.initLeftData();
        },
        // 点击确定 提交
        submit: function() {
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    let sendUrl = '';
                    let sendObj = {};
                    let buyerIdList = this.form.rightList.map((v) => {
                        let keyRow = JSON.parse(v);
                        return keyRow.buyerId;
                    });
                    // 新增
                    if (this.type === 'add') {
                        sendUrl = '/trader/customerDistrict/add';
                        sendObj = {
                            parentDistrictId : [...this.form.QYdata].pop(),
                            traderId: sessionStorage.getItem('traderId'),
                            districtName: this.form.groupName,
                            buyerIdList: buyerIdList,
                        };
                    // 修改
                    } else {
                        sendUrl = '/trader/customerDistrict/update';
                        sendObj = {
                            id: this.$route.query.id,
                            traderId: sessionStorage.getItem('traderId'),
                            districtName: this.form.groupName,
                            buyerIdList: buyerIdList,
                        };
                    }
                    axiosApi(sendUrl, sendObj, (res) => {
                        let message = this.type === 'add' ? '新增成功' : '修改成功';
                        this.$message.success(message);
                        setTimeout(() => {
                            this.goBack();
                        },1500);
                    },1,1,(err)=>{
                        this.$message.info(err.data.message)
                    });
                }
            })
        },
        goBack : function(){
            if(this.$route.query.a && this.$route.query.b){
                let a = this.$route.query.a;
                let b = this.$route.query.b;
                this.$router.push({
                    path: basePath + `/editCommHome/${a}/${b}/0?type=3`
                });
            }else if(this.$route.query.c && this.$route.query.d){
                let c = this.$route.query.c;
                let d = this.$route.query.d;
                this.$router.push({
                    path: basePath + `/clientEdit/${c}/0/${d}`
                });
            }else if(this.$route.query.e){
                let e = this.$route.query.e;
                this.$router.push({
                    path: basePath + `/addStore/${e}`
                });
            }else{
            /* 如果当前是区域列表进来 回到区域列表 */
                this.$router.push({
                    path: basePath + '/regionList'
                });
            }
        },
        // 点击取消
        cancel: function() {
            this.$confirm('信息尚未保存，是否返回', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.goBack();
            }).catch(() => {

            });
        },

        // 浮层相关方法
        // 穿梭框数据渲染
        renderFunc: function(h, option) {
            let title = `${option.storeName} - ${option.loginName}`;
            let cls = option.district ? 'Mbor' : '';
            return <span class={cls} title={title}>{option.storeName} - {option.loginName}</span>;
        },
        // 关闭浮层
        dialogClose: function(type) {
            this.dialogVisible = false;
            this.leftSearchVal = '';
        },
        // 浮层点击确定
        handleSave: function() {
            this.dialogClose();
            this.bhList = this.form.rightList.map((v,k) => {
                return JSON.parse(v)
            });
            this.detailRightList = this.form.rightList;
            this.$refs.form.validateField('rightList');
        },
        // 浮层点击取消
        handleClose: function() {
            this.dialogClose();
            this.form.rightList = this.detailRightList;
        },
        // 左侧数据初始化
        initLeftData: function(val,pageSize) {
            this.form.leftList = [];
            let sendUrl = '/trader/parentDepartment/customer/list';
            let sendObj = {
                sellerId: sessionStorage.getItem('traderId'),
                storeName: val || '',
            };
            axiosApi(sendUrl, sendObj, (res) => {
                let data = res.data;
                this.form.leftList = data.data.map((v,k) => {
                    v.key = JSON.stringify({
                        storeName : v.storeName,
                        loginName : v.loginName,
                        buyerId : v.buyerId,
                    });
                    return v;
                });
                
            }, 1, 10000);
        },
        // 左侧搜索
        leftSearch: function(val) {
            // 如果搜索条件 为空查 初始化数据
            if (val === '') {
                this.initLeftData();
                // 按搜索条件 搜索
            } else {
                this.initLeftData(val,10000);
            }
        },
    },
}
</script>

<style lang="less">
.addAndEditRegionBox {
    .bd {
        border: 1px solid #ccc;
    }
    .el-transfer-panel:first-child .Mbor{
        color: #20a0ff;
    }
    .formBox {
        padding-left: 100px;
        padding-top: 30px;
    }
    .dataBox {
        margin-top: 20px;
        .Mbtn{
            width: 90px;
            height: 32px;
            border: 1px solid #ddd;
            text-align: center;
            border-bottom: none;
        }
        .list {
            max-height: 400px;
            height: 200px;
            overflow: hidden;
            width: 500px;
            overflow-y: auto;
            li {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                float: left;
                padding: 10px;
                padding-left: 30px;
                width: ~"calc(50% - 40px)";
            }
        }
    }
    .popBox {
        margin: 0 auto;
        width: 70%;
        position: relative;
        /* 重写穿梭框按钮 样式 */
        .el-button--primary{
            color: #1f2d3d!important;
            background-color: #fff!important;
            border: 1px solid #bfcbd9!important;
        }

        .el-transfer-panel__body {
            .el-input--small {
                width: 218px!important
            }
        }
        .leftSearch {
            display: none;
            position: absolute;
            z-index: 10;
            top: 47px;
            left: 11px;
        }
        .tishi{
            color: #8391a5;
            margin-top: 20px;
            width: 400px;
        }
    }
    .el-dialog{
        width: 964px;
        height: 730px;
    }
    .el-transfer-panel {
        width: 258px;
    }
    .el-transfer-panel__body {
        height: 340px;
    }
    .el-transfer-panel__list.is-filterable {
        height: 300px;
    }
}
</style>

