<template>
    <div id="editCommHome" class="bg-f">
        <div class="plr-45 " v-loading="mainLoading" >
            <div class="commHeader pt-30 ml-40 plr-45 clearfix">
				<span>
					<img :src="logo" class="fl mr-20" v-if="logo!=''"/>
					<img src="../../assets/goods.jpg" class="fl mr-20" v-if="logo==''"/>
				</span>
                <div class="font-20 fl mt-5">
                    <p>{{skuInfo.skuName}}</p>
                    <span class="color-9 mt-10">市场价：{{skuInfo.enterShopPrice}}</span>
                </div>
                <!-- <div class="fr mt-20 mr-30" v-if="this.$route.params.rej==1">
                    <el-button type="info" @click="rejectVisible=true">驳回申请</el-button>
                    <el-button type="danger" @click="handleAudited">审核通过</el-button>
                </div> -->
            </div>
            <!--驳回申请弹窗-->
            <el-dialog class='cancelOrderDialog' title='输入驳回申请理由' :visible.sync='rejectVisible'>
                <el-form class="pl-30 pr-30" :model="ruForm" ref="rejectForm" :rules="rules">
                    <el-form-item label="" prop='rejectionReason'>
                        <el-input type='textarea' placeholder='请输入驳回申请的理由...' :autosize="{ minRows: 3 ,maxRows: 10}"
                                  v-model.trim='ruForm.rejectionReason'></el-input>
                    </el-form-item>
                </el-form>
                <div slot='footer' class="dialog-footer">
                    <el-button type='danger' @click="rejectCommit">提 交</el-button>
                    <el-button @click="rejectVisible = false">取 消</el-button>
                </div>
            </el-dialog>
            <!--编辑-->
            <div v-if="this.$route.params.rej==0">
                <div class="commCont">
                    <div class="ml-40 mr-30 plr-45 mt-30">
                        <div class="bbd clearfix">
                            <el-tabs class="fl supplier" v-model="activeTab" @tab-click="handleClick">
                                <el-tab-pane label="基本信息" name="1"></el-tab-pane>
                                <el-tab-pane label="媒体设置" name="2"></el-tab-pane>
                                <el-tab-pane label="商品详情" name="3"></el-tab-pane>
                                <!-- <el-tab-pane label="停售设置" name="7"></el-tab-pane> -->
                                <el-tab-pane label="可见设置" name="8"></el-tab-pane>
                                <el-tab-pane label="限购设置" name="9"></el-tab-pane>
                                <el-tab-pane label="积分设置" name="10" v-if="openIntegration"></el-tab-pane>
                            </el-tabs>
                        </div>
                    </div>
                </div>
                <div class="pb-50">
                    <commInfo v-bind:isEdit="true" :skuInfo="skuInfo" @updateBase="updateBase" :isK3="isK3" v-show="activeTab=='1'"
                              :isOpenPriceSync="isOpenPriceSync"></commInfo>
                    <div v-show="activeTab=='2'" v-loading="loading"   class="media mt-50 font-14 ml-30">
                        <el-form   ref="ruleForm" label-width='130px'>
                            <el-form-item label="图片上传:" class="clearfix">
                                <ul class="clearfix fl goodsPic">
                                    <li class="fl mr-10" v-for="(item,i) in dialogImageUrl" :key='i'>
                                        <i @click="preView(i)" class="el-icon-view"></i>
                                        <img :src="item.url" alt="">
                                        <i @click="delItem(i)" class="el-icon-delete"></i>
                                    </li>
                                </ul>
                                <el-upload class="fl" :class="dialogImageUrl.length>=6 ? 'fileList' : ''" action="/sunrise-gateway/oss/ossUpload" :show-file-list="false"  multiple :file-list="dialogImageUrl" list-type="picture-card" :on-preview="handlePictureCardPreview" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload" :on-remove="handleRa" :on-error="errFun">
                                    <i class="el-icon-plus"></i>
                                </el-upload>
                            </el-form-item>
                            <el-form-item label="">
                                <p class="color-9">第一张图片将作为商品主图，支持同时上传多张图片。支持jpg、png格式上传，建议使用尺寸800x800像素以上、大小不超过1M的正方形图片。</p>
                            </el-form-item>
                            <el-form-item label="">
                                <el-button class="el-button mt-50 el-button--danger" @click="updateMedia">保存</el-button>
                            </el-form-item>
                        </el-form>
                    </div>
                    <commDetails v-bind:isEdit='true' :skuInfo="skuInfo" v-show="activeTab=='3'"></commDetails>

                    <!-- <Client :skuInfo="skuInfo" :activeTab="'7'" v-show="activeTab=='7'"></Client> -->
                    <Client :skuInfo="skuInfo" :activeTab="'8'"  v-show="activeTab == 8"></Client>
                    <Client :skuInfo="skuInfo" :activeTab="'9'" v-show="activeTab == 9"></Client>
                    <integral :skuInfo="skuInfo" @changeSuccess="getcommInfo" v-if="activeTab == '10'"/>
                </div>
            </div>
            <!-- 放大图片 -->
            <el-dialog :visible.sync="dialogVisible" size="tiny">
                <img width="100%" :src="ImageUrl" alt="show-message">
            </el-dialog>
        </div>
    </div>
</template>

<script>
    import {axiosApi, basePath} from '../../api/api';
    import {mapActions, mapMutations} from 'vuex'
    import _ from 'underscore';
    export default {
        data() {
            return {
                count:0,
                mainLoading:true,
                loading:false,
                ImageUrl:null,
                dialogVisible:false,
                rules: {
                    rejectionReason: [
                        {required: true, message: '请输入驳回原因！'},
                        {max: 30, message: '不能超过30个字！'}
                    ]
                },
                ruleForm:{},
                activeTab: '1',
                skuInfo: {},
                logo: '',
                rejectVisible: false,//弹出框
                ruForm: {
                    id: this.$route.params.skuId,
                    auditState: 2,//默认值是审核通过
                    sellerId: sessionStorage.traderId,
                    rejectionReason: null
                },
                isDot1: false,
                isDot2: false,
                isDot3: false,
                isDot4: false,
                isDot5: false,
                isK3: false,
                isOpenPriceSync: false,// 商品开通同步K3
                dialogImageUrl:[],
                openIntegration: false, // 积分设置开关
            }
        },
        methods: {
            ...mapMutations({'changemoqUnitName': 'changemoqUnitName', 'changek3minimunMoq': 'changek3minimunMoq'}),
            handleClick(tab, event) {
                sessionStorage.activeTab = this.activeTab;
            },
            preView(i){
                this.ImageUrl = this.dialogImageUrl[i].url; //门店照片的放大预览
                this.dialogVisible = true;
            },
            delItem(i){
                this.dialogImageUrl.splice(i,1);
            },
            //多媒体设置
            updateMedia(){
                let flag = false;
                let skuShowPictureParam = [];
                for(let i = 0;i<this.dialogImageUrl.length;i++){
                    let v = this.dialogImageUrl[i];
                    if(v.url.indexOf('blob') != -1){
                        this.$message.warning('图片上传中');
                        flag = true
                        break
                    }else{
                        skuShowPictureParam.push({
                            fileUrl:v.url,
                            fileType:1
                        })
                    }
                }
                if(flag) return
                // let skuShowPictureParam = this.dialogImageUrl.map( v => {
                //
                //     return {
                //         fileUrl:v.url,
                //         fileType:1
                //     }
                // });
                let body = {
                    id:this.skuInfo.id,
                    skuShowPictureParam
                };
                axiosApi('/goods/sku/updateImages',body,res => {
                    console.log(res,'res');
                    this.$message({
                        type: 'success',
                        message: '修改成功!',
                        duration: 1000
                    });
                },1,10,err => {
                    this.$message.warning(err.data.message)
                })
            },
            updateBase(){
                this.getcommInfo();
            },
            //商品详细信息
            getcommInfo() {
                axiosApi('/trader/findTraderInfoBySerllerId/select', {
                    'traderId': sessionStorage.getItem('traderId')
                }, (res) => {
                    this.isK3 = res.data.data.erpType === 2
                    this.isOpenPriceSync = res.data.data.isOpenPriceSync || false //商品开通同步K3
                    this.openIntegration = res.data.data.openIntegration
                });
                // console.log(this.$route.params.skuId)
                //云商2.6.8 商品查询接口改变  原接口：/goods/skuInfo/select
                // const api = "/goods/skuInfoBack/select";
                const api = "/goods/skuInfoBack/select";
                const body = {id: this.$route.params.skuId};
                axiosApi(api, body, (res) => {
                    console.log(res,'商品全部信息(主页面)');
                    this.dialogImageUrl = res.data.data.skuShowPictureVOs.map(v => {
                        return {
                            ...v,
                            ...{url:v.fileUrl}
                        }
                    });

                    console.log(this.dialogImageUrl)
                    this.skuInfo = res.data.data ? res.data.data : {};
                    if (this.skuInfo.skuShowPictureVOs.length > 0) this.logo = this.skuInfo.skuShowPictureVOs[0].fileUrl;
                    this.changek3minimunMoq(this.skuInfo.isIntegerMultiple ? this.skuInfo.sellingNum : this.skuInfo.unitNum)
                    if (this.skuInfo.isIntegerMultiple) {
                        this.changemoqUnitName(this.skuInfo.integerUnitName)
                    } else {
                        this.changemoqUnitName('瓶')
                    }
                    this.mainLoading = false;
                }, 1, 10,err => {
                    this.$message.error(err.data.message)
                    this.mainLoading = false;
                })
            },
            //审核
            audited() {
                const api = "/goods/sku/check";
                const body = this.ruForm;
                axiosApi(api, body, (res) => {
                    // console.log(res,'审核')
                    this.$message({
                        type: 'success',
                        message: '操作成功!',
                        duration: 1000
                    });
                    this.$router.push(basePath + "/allProduct");
                }, 1, 10, (res) => {
                    this.$message({
                        type: 'info',
                        message: res.data.message,
                        duration: 1000
                    });
                })
            },
            //驳回申请理由提交
            rejectCommit() {
                this.$refs.rejectForm.validate((valid) => {
                    if (valid) {
                        this.ruForm.auditState = 3;
                        this.rejectVisible = false
                        this.audited();
                    } else {
                        return false
                    }
                })
            },
            //审核通过
            handleAudited() {
                const h = this.$createElement;
                this.$msgbox({
                    title: '提示信息',
                    message: h('p', {style: 'text-align:center'}, '是否确认审核通过？'),
                    showCancelButton: true,
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    beforeClose: (action, instance, done) => {
                        if (action === 'confirm') {
                            this.audited();
                            done()
                        } else {
                            done()
                        }
                        delete instance.$slots.default;
                    }
                })
            },
            handlePictureCardPreview(file) {
                //console.log(file, "fangda")
                this.ImageUrl = file.url; //门店照片的放大预览
                //console.log(this.dialogImageUrl)
                this.dialogVisible = true;

            },
            handleAvatarSuccess(res,file,filelist) { //照片
                console.log(res,file,filelist, "aa");
                let flag = false;
                let arr = [];
                setTimeout(() => {
                    filelist.map((v,i) => {
                        if(i<6){
                            if(v.status !== 'success'){
                                this.count++;
                            }
                            arr.push({
                                url:v.response ? v.response.data[0] : v.url,
                                status:v.status,
                                uid:v.uid
                            })
                        }
                    });
                    this.dialogImageUrl = arr;
                    this.loading = false;
                },500);
            },
            handleRa(file, fileList) { //照片删除
                this.dialogImageUrl = fileList
            },
            beforeAvatarUpload(file) {
                this.loading = true;
                const isImage = (file.type.indexOf('png') != -1 || file.type.indexOf('jpeg') != -1 || file.type.indexOf('jpg') != -1);
                const imgSize = file.size / 1024 / 1024;
                const isLimit = this.dialogImageUrl.length<6;
                let bool = isImage && (imgSize>0 && imgSize<1) && isLimit;
                if(!isImage){
                    this.$message.warning('请上传格式为png、jpg的图片')
                }else if(!(imgSize>0 && imgSize<1)){
                    this.$message.warning('请上传大小不超过1M的图片')
                }
                if(!bool) this.loading = false;
                return bool;
            },
            errFun(err) {
                console.log(err,'err');
            },
        },
        beforeRouteLeave(to,form,next){
        	sessionStorage.removeItem('activeTab');
        	next();
        },
        mounted() {
            this.$nextTick(function () {

                if (sessionStorage.activeTab) {
                    this.activeTab = sessionStorage.activeTab
                }
                ;
                if (this.$route.params.rej) {
                    sessionStorage.activeTab = 1
                }
                if (this.$route.query.type) {
                    this.activeTab = this.$route.query.type
                }
                this.getcommInfo();
                // this.getIsdotBoolean();
            })
        }
    }
</script>

<style lang="less">
    #editCommHome {
        .media{
            .fileList{
                .el-upload-list--picture-card{
                    display: none;
                }
            }
        }
        .goodsPic{
            li{
                position: relative;
                border: 1px solid #ccc;
                width: 90px;
                height: 90px;
                img{
                    width: 100%;
                    height: 100%;
                }
                i{
                    cursor: pointer;
                    position: absolute;
                    top: 40%;
                    display: none;
                    font-size: 24px;
                    color: #fff;
                    line-height: 15px!important;
                    &:first-child{
                        left: 25%;
                    }
                    &:last-child{
                        left: 65%;
                    }
                }
                &:hover{
                    background-color: #ccc;
                    i{
                        display: inline-block;
                    }
                }
            }
        }
        .el-upload--picture-card {
            width: 90px;
            line-height: 90px;
            height: 90px;
        }

        .el-icon-plus {
            line-height: 90px;
        }

        .el-upload-list--picture-card .el-upload-list__item {
            width: 90px;
            height: 90px;
        }

        .fileList .el-upload--picture-card {
            display: none;
        }
        .commHeader {
            img {
                width: 90px;
                height: 90px;
            }
        }

        .el-tabs__header {
            padding-left: 0;
        }

        .el-dialog {
            min-height: 300px;
        }

        .bbd {
            position: relative;
        }

        .item1 {
            position: absolute;
            left: 70px;
            top: 6px;
        }

        .item2 {
            position: absolute;
            left: 160px;
            top: 6px;
        }

        .item3 {
            position: absolute;
            left: 245px;
            top: 6px;
        }

        .item4 {
            position: absolute;
            left: 335px;
            top: 6px;
        }
    }

</style>
