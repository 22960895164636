<template>
	<div id="brandsApply">
		<div class="bg-f pt-30 pb-50">
			<el-form :inline="true" class="clearfix">
				<div class="ml-45 font-12">
					<label>分类：</label><span>{{this.classifyName}}</span>
					<label class="ml-50">品牌：</label><span>{{this.brandName}}</span>
				</div>
				<div class="ml-45">
					<div class="mt-30">
						<div>
							<label>请上传凭证：</label>
							<span class="color-9">(上传凭证文件大小4M以内，支持png,jpg格式)</span>
						</div>
						<div class="mt-20" :class="{noAdd:isAdd,add:!isAdd}">
							<el-upload action="/sunrise-gateway/oss/ossUpload" list-type="picture-card" :file-list="fileList" :on-preview="handlePictureCardPreview" :on-success="handleAvatarPhotoScucess" :on-remove="handleRemove" :before-upload="beforeAvatarUpload">
								<i class="el-icon-plus"></i>
							</el-upload>
							<el-dialog :visible.sync="dialogVisible" size="tiny">
								<img width="100%" :src="dialogImageUrl" alt="">
							</el-dialog>
						</div>
					</div>
					<div class="mt-50 exilic" ref="c">
						<label class="mb-20 dib">已有凭证：</label>
						<div v-if="dialogImageUrlList.length>0">
							<el-upload action="/sunrise-gateway/oss/ossUpload" :file-list="dialogImageUrlList" list-type="picture-card" :on-preview="handlePictureCardPreview">
								<i class="el-icon-plus"></i>
							</el-upload>
							<el-dialog :visible.sync="dialogVisible" size="tiny">
								<img width="100%" :src="dialogImageUrl" alt="show-message">
							</el-dialog>
						</div>
						<div v-else>
							您还没有上传过销售资质,请先上传！
						</div>
					</div>

				</div>
				<el-button type="danger" class="ml-45 mt-50" @click="upload" :disabled='canAdd'>提交</el-button>
			</el-form>
		</div>
	</div>
</template>

<script>
	import { axiosApi, basePath } from "../../api/api";
	export default {
		data() {
			return {
				dialogImageUrl: '',
				fileList: [], //上传经销协议
				dialogImageUrlList: [], //已有凭证
				dialogVisible: false,
				classify: [],
				BrandName: [],
				isMore: true,
				activeIndexClassfy: 0,
				activeIndexBrand: 0,
				brandId: "",
				classifyId: "",
				checking: true, //已有凭证待审核状态
				isAdd: false, //上传凭证，最多10张
				canAdd:true,//判断提交按钮是否可以提交
				classifyName:'',
				brandName:''
			}
		},
		mounted() {
			this.$nextTick(() => {
				this.achieve();
			})
		},
		methods: {
			//默认加载已有凭证
			achieve() {
				this.classifyName = sessionStorage.classifyName,
				this.brandName = sessionStorage.brandName,
				axiosApi("/trader/findTraderCertList/select", {
					traderId: sessionStorage.traderId,
					brandId: this.$route.params.brandId,
					classifyId: this.$route.params.classifyId,
				}, res => {
					if(res.data.data) {
						this.dialogImageUrlList = [];
						res.data.data.certRelationVOs.forEach((item, index) => {
							if(item.state != 3) {
								this.dialogImageUrlList.push({
									url: item.certImg,
									state: item.state
								})
							}
						})
						setTimeout(() => {
							this.dialogImageUrlList.forEach((item, index) => {
								if(item.state == 1) {
									this.$refs.c.childNodes[2].childNodes[0].childNodes[0].childNodes[index].className = "el-upload-list__item is-success credentials"
								}
							})
						}, 500)
					}
				})
			},
			handleAvatarPhotoScucess(res) { //上传图片经销协议（增加）
				this.fileList.push({
					url: res.data[0]
				})
				if(this.fileList.length == 10) {
					this.isAdd = true;
				}
				if(this.fileList.length != 0) {
					this.canAdd = false;
				}
			},
			beforeAvatarUpload(file) { //上传之前图片的判断
				
				const isImage = (file.type.indexOf('jpg') != -1 || file.type.indexOf('jpeg') != -1 || file.type.indexOf('png') != -1 || file.type.indexOf('bmp') != -1);
				const isLt4M = file.size / 1024 / 1024 < 4 && file.size / 1024 / 1024 > 0;
				if(!isImage) {
					this.$message.error('请正确上传图片格式文件!');
				}
				if(!isLt4M) {
					this.$message.error('上传图片大小不能大于 4MB或小于 0MB!');
				}
				return isImage && isLt4M;
			},
			handlePictureCardPreview(file) {
				this.dialogImageUrl = file.url;
				this.dialogVisible = true;
			},
			handleRemove(file, fileList) {
				this.fileList = [];
				this.fileList = fileList;
				if(this.fileList.length < 10) {
					this.isAdd = false;
				}
				if(this.fileList.length == 0) {
					this.canAdd = true;
				}
			},
			upload() {
				let _this = this;
				this.$confirm('您确定要上传这些图片？', '提示信息', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					const api = "/goods/sellingLicense/upload";
					let certImgUrls = [];
					this.fileList.forEach(item => {
						certImgUrls.push(item.url)
					})
					const body = {
						sellerId: sessionStorage.traderId,
						brandId: _this.$route.params.brandId,
						classifyId: _this.$route.params.classifyId,
						spuNo: _this.$route.params.spuNo,
						certImgUrls: certImgUrls,
					};
					axiosApi(api, body, (res) => {
						this.$message({
							type: 'success',
							message: '上传成功!',
							duration: 1000
						});
						history.go(-1);
					}, this.currentPage, 10, (res) => {
						this.$message({
							type: 'warning',
							message: res.data.message,
							duration: 3000
						});
					});
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				})

			}
		}
	}
</script>

<style lang="less">
	#brandsApply {
		.el-upload-list--picture-card .el-upload-list__item,
		.el-upload--picture-card {
			width: 120px;
			height: 90px;
		}
		.el-upload--picture-card {
			line-height: 90px;
		}
		.li:hover,
		.li.active {
			color: #FF4100;
		}
		.noAdd {
			.el-upload.el-upload--picture-card {
				display: none;
			}
		}
		.add {
			.el-upload.el-upload--picture-card {
				display: inline-block;
			}
		}
		.exilic {
			.el-upload--picture-card,
			.el-upload-list__item-delete {
				display: none;
			}
			.el-upload-list__item-status-label {
				display: none;
			}
			.credentials {
				position: relative;
				&::before {
					display: block;
					content: '\5F85\5BA1\6838';
					position: absolute;
					width: 50px;
					height: 38px;
					right: 0;
					line-height: 38px;
					color: #fff;
					background: rgba(0, 0, 0, 0.5);
					text-align: center;
				}
			}
		}
	}
</style>