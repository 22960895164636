<template>
	<div id="freight-price">
		<div class="title-menu">
			<div class="clearfix">
				<el-breadcrumb class="fl">
					<el-breadcrumb-item>运费结算</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
		</div>
		<div class="p30">
			<el-form :inline="true" :model="formInlineData" class="search clearfix" label-width="60">
				<div class="fl">
					<el-form-item label='按年月'>
						<el-date-picker v-model="formInlineData.startTime" type="month" placeholder="请选择" :picker-options="pickerOptions">
						</el-date-picker>
						至
						<el-date-picker v-model="formInlineData.endTime" type="month" placeholder="请选择" :picker-options="pickerOptions">
						</el-date-picker>
					</el-form-item>
				</div>
				<el-button @click='handlerSeach' type="info" class="fl ml-30">查询</el-button>
			</el-form>
			<div class="tar pr-20">
				<a style="color:#20a0ff;" href="http://sunrise-cbs.oss-cn-hangzhou.aliyuncs.com/template/%E8%BF%90%E8%B4%B9%E6%9C%88%E7%BB%93%E8%B4%A6%E5%8D%95%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xls">
					<el-button type='text'>下载模板</el-button>
				</a>
				<el-button type="text" @click="handDown">批量导出</el-button>
				<el-upload class="dib" action="/sunrise-gateway/import/foundation/freightSettle/list?startRow=1&startCol=0" :show-file-list="false" :on-success="handUpSuccess" :before-upload="handUpBefore" :on-error="handUpError" accept="application/vnd.ms-excel">
					<el-button type='text'>批量导入</el-button>
				</el-upload>
			</div>
			<div class="content">
				<div v-for="item in arrInfo">
					<p><span>{{item.gmtPaid|time}}</span><i class="cursor color-b" :class="item.checked?'el-icon-caret-right':'el-icon-caret-bottom'" @click="spreadClick(item)"></i></p>
					<div class="mt-10" v-if="item.checked">
						<div class="info" style="height: 40px;">
							<span class="s1">商家名称</span>
							<span class="s2">商家订单号/下单时间</span>
							<span class="s3">运单号</span>
							<span class="s5">始发地/目的地</span>
							<span class="s7">计费重量</span>
							<span class="s8">复核重量</span>
							<span class="s9">运单状态</span>
							<span class="s10">运费</span>
							<span class="s11">服务费</span>
							<span class="s12">折扣费</span>
							<span class="s13">赔偿费</span>
							<span class="s14">应付</span>
							<span class="s15 tac">总计</span>
						</div>
						<div class="info" v-for="v in formInfo">
							<span class="s1">{{v.buyerName}}</span>
							<div>
								<p class="clearfix" v-for="i in v.pos">
									<span class="s2">
										{{i.orderSn}}
										<br />
										{{i.gmtPaid | time}}
									</span>
									<span class="s3">{{i.deliveryNo}}</span>
									<span class="s5">
										{{i.deliverCity}}/{{i.receiveProvince}}
										<!--<br />-->
										
									</span>
									<span class="s7">{{i.chargedWeight}}</span>
									<span class="s8">{{i.checkWeight}}</span>
									<span class="s9">{{i.status}}</span>
									<span class="s10">{{i.freightMoney}}</span>
									<span class="s11">{{i.coverCharge}}</span>
									<span class="s12">{{i.deduction}}</span>
									<span class="s13">{{i.compensation}}</span>
									<span class="s14">{{i.needPayMoney}}</span>
								</p>
							</div>
							<span class="s15 tac">{{v.freightMoney}}</span>
						</div>
					</div>
				</div>
			</div>
			<el-pagination v-if='total>0' class="tar" :current-page.sync="currentPage" :page-size='10' :total='total' layout="prev , pager, next, jumper" @current-change="freightSettleMonthAll">
				<!-- 分页组件 -->
			</el-pagination>
		</div>
		<!-- 导出 -->
		<form ref="downClientForm" action='/sunrise-gateway/export/foundation/freightSettle/list' style="display: none">
			<input type="hidden" name="modelName" v-model="a">
			<input type="hidden" name="fileName" v-model="b">
			<input type="hidden" name="startRow" v-model="d">
			<input type="hidden" name="startDate" v-model="startDate">
			<input type="hidden" name="endDate" v-model="endDate">
		</form>
		<!-- 下载导入失败文件信息 -->
		<form ref="downClientErrForm" action='/sunrise-gateway/export/foundation/batchImport/failed' style="display: none">
			<input type="hidden" name="modelName" v-model="e">
			<input type="hidden" name="fileName" v-model="f">
			<input type="hidden" name="key" v-model="g">
		</form>
	</div>
</template>

<script>
	import { axiosApi, basePath } from "~/api/api"
	import { MessageBox } from 'element-ui';

	export default {
		data() {
			return {
				a: 'foundation.freightSettle.list',
				b: '运费月结账单.xls',
				d: 1,
				e: 'importCustomer.failedRecord',
				f: '导入失败账单列表信息.xls',
				g: null,
				pickerOptions: {
					disabledDate(time) {
						return true;
					}
				}, //禁选未来时间
				formInlineData: {
					startTime: "",
					endTime: "",
					maxTime: "",
					minTime: ""
				},
				startDate: "",
				endDate: "",
				total: 0,
				currentPage: 1,
				arrInfo: [], //所有年月
				formInfo: {}, //单月信息
			}
		},
		filters: {
			time(value) {
				let myDate = new Date(value);
				let year = myDate.getFullYear();
				let month = myDate.getMonth() + 1;
				return year + '年' + (month >= 10 ? month : '0' + month) + '月';
			}
		},
		watch: {
			formInlineData: {
				handler: function(item) {
					this.pickerOptions.disabledDate = function(time) {
						return time.getTime() < item.minTime || time.getTime() > item.maxTime
					}
					//开始时间
					if (item.startTime) {
						this.startDate = item.startTime.getTime()
					}else{
						this.startDate = ""
					}
					if (item.endTime) {
						this.endDate = item.endTime.getTime()
					}else{
						this.endDate = ""
					}
				},
				deep: true
			}
		},
		mounted() {
			this.$nextTick(() => {
				axiosApi("/foundation/freightSettle/time", {
					traderId: sessionStorage.traderId
				}, res => {
					if(res.data.data) {
						this.formInlineData.maxTime = res.data.data.maxTime
						this.formInlineData.minTime = res.data.data.minTime
					}
				}).then(res => {
					this.freightSettleMonthAll()
				})
			})
		},
		methods: {
			handUpSuccess(res, file, fileList) {
				this.$loading().close()
				switch(res.code) {
					case "000000": //系统交易成功
						let h = this.$createElement;
						if(res.data.failedRows == 0) { //失败条数为0
							this.$msgbox({
								title: '提示信息',
								message: h('p', {
									style: 'text-align:center'
								}, [
									h('p', null, '导入已完成！'),
									h('p', null, `共导入信息${res.data.successedRows}条，全部成功！`)
								]),
								showCancelButton: false,
								confirmButtonText: '确定',
								beforeClose: (action, instance, done) => {
									if(action === 'confirm') {
										done()
										this.freightSettleMonthAll()
									} else {
										done()
									}
									delete instance.$slots.default;
								}
							})
						} else {
							this.g = res.data.failedRowsKey;
							this.$msgbox({
								title: '提示信息',
								message: h('p', {
									style: 'text-align:center'
								}, [
									h('p', null, '导入已完成！'),
									h('p', null, `共导入信息${res.data.successedRows}条，失败${res.data.failedRows}条，点击下载查看失败原因！`)
								]),
								showCancelButton: true,
								confirmButtonText: '下载失败文件',
								cancelButtonText: '我不想知道',
								beforeClose: (action, instance, done) => {
									if(action === 'confirm') {
										this.$refs.downClientErrForm.submit();
										done()
									} else {
										done()
									}
									delete instance.$slots.default;
								}
							})
						}
						break;
					case "999999": //系统异常
						this.$alert(res.message, '提示信息', {
							confirmButtonText: '确定'
						});
						break;
					case "AUTH_001": //用户未登陆
						gotoLoginPage();
						break;
					case "AUTH_002": //用户无权限
						this.$alert(res.message, '提示信息', {
							confirmButtonText: '确定'
						});
						break;
					case "LOGIN_005": //用户已禁用
						this.$alert(res.message, '提示信息', {
							confirmButtonText: '确定'
						});
						break;
					case "LOGIN_006": //用户session失效
						gotoLoginPage();
						break;
					default:
						this.$alert(res.message, '提示信息', {
							confirmButtonText: '确定'
						});
						break;
				}

			},
			handUpError(err, file, fileList) {
				this.$loading().close()
				this.$message.error('导入失败！');
			},
			handUpBefore(file) {
				this.$loading({
					fullscreen: true,
					lock: true,
					text: '正在导入客户...'
				})
				return true
			},
			handDown() {
				this.$refs.downClientForm.submit();
			},
			handlerSeach() {
				this.currentPage = 1
				this.freightSettleMonthAll()
			},
			//所有月份运费结算列表						
			freightSettleMonthAll() {
				let a = this.formInlineData.startTime
				let b = this.formInlineData.endTime
				a && (a = a.getTime())
				b && (b = b.getTime())
				if(a && b && a > b) {
					MessageBox.alert("开始时间不得大于结束时间", '提示信息', {
						confirmButtonText: '确定'
					});
					return false;
				}
				axiosApi("/foundation/freightSettleMonth/all", {
					traderId: sessionStorage.traderId,
					startTime: a,
					endTime: b,
				}, res => {
					this.arrInfo = res ? res.data.data.elements : []
					this.total = res.data.data.totalCount
					this.arrInfo.length > 0 && this.arrInfo.forEach((item, index) => {
						if(index == 0) {
							this.$set(item, "checked", true)
						} else {
							this.$set(item, "checked", false)
						}
					})
					this.arrInfo.length > 0 && this.freightSettleMonthGet(this.arrInfo[0].gmtPaid)
				}, 1, 10)
			},
			//单月份运费结算列表						
			freightSettleMonthGet(payload) {
				axiosApi("/foundation/freightSettleMonth/get", {
					traderId: sessionStorage.traderId,
					time: payload
				}, res => {
					this.formInfo = res.data.data
				})
			},
			spreadClick(item) {
				this.arrInfo.forEach(i => {
					if(i == item) {
						i.checked = !i.checked
					} else {
						i.checked = false
					}
				})
				this.freightSettleMonthGet(item.gmtPaid)
			}

		}
	}
</script>

<style lang="less">
	#freight-price {
		background-color: #fff;
		.content {
			&>div {
				margin: 10px 0;
				&>p {
					display: flex;
					justify-content: space-between;
					background-color: #eee;
					height: 40px;
					align-items: center;
					padding: 0 15px;
				}
				.info {
					display: flex;
					justify-content: flex-start;
					align-items: center;
					border: 1px solid #ddd;
					border-bottom: 0;
					&:last-child {
						border-bottom: 1px solid #ddd;
					}
					&>span {
						padding: 0 5px;
						box-sizing: border-box;
					}
					p {
						display: flex;
						align-items: center;
						justify-content: flex-start;
						border: 1px solid #ddd;
						border-top: 0;
						padding: 5px 0;
						&:last-child {
							border-bottom: 0;
						}
						span {
							padding: 0 3px;
							box-sizing: border-box;
						}
					}
					.s1 {
						width: 80px;
					}
					.s2 {
						width: 155px;
					}
					.s3 {
						width: 105px;
					}
					.s5 {
						width: 120px;
					}
					.s7,
					.s8,
					.s9 {
						width: 66px;
					}
					.s10,
					.s14,
					.s15 {
						width: 40px;
					}
					.s11,
					.s12,
					.s13 {
						width: 53px;
					}
				}
			}
		}
	}
</style>