<template>
	<div id="check">
		<div class="mt-50 ml-100 infoDiv">
			<div class='mb-30 ml-50'>
				<div class="ml-15 clearfix">
					<label class="color-3 fl">角色名称：</label>
					<div class="c fl">
						<el-input value='' v-model.trim='roleName' @change="change()"></el-input>
						<p class='color-danger font-12 cc d width-230' :class={dd:disabled}>请输入1-16位角色名称！</p>
					</div>
				</div>
			</div>
			<el-form class="mb-50 ml-50 clearfix width-640" v-for="(item,index1) in list" :key="index1">
				<fieldset class="bd">
					<legend class="ml-10 pl-20 pr-20">
						<el-checkbox class='mr-40 mb-10' v-model="item.checkAll" @change="all(item)">{{item.text}}</el-checkbox>
					</legend>
					<div v-for='(items,index1) in item.sub' :key="index1">
						<el-checkbox class="color-3 mr-30 fl mb-10 mt-10 ml-15 w-600" v-model="items.checked" :label="items.id" @change="handleCheckAllChange(item,items)">{{items.text}}</el-checkbox>
						<span class="fl ml-30">
						  <el-checkbox-group v-model="items.checkedAreas"> 
						    <div v-for='(itemss,index) in items.sub' :key="index+'s'">
                                <el-checkbox class='w-600 mb-10 hides' v-model="itemss.checked"  v-if="!isCityPartner||itemss.id!='200158'" :title="itemss.text" :key="itemss.id" :label="itemss.id" @change="handleCheckedCitiesChange($event,item,items,itemss)">{{itemss.text}}</el-checkbox>
                                <span class="fl ml-30">
                                        <el-checkbox-group v-model="itemss.checkedAreas2">
                                            <el-checkbox class='width-110 mb-10 hides' v-model="itemsss.checked" v-if="!isCityPartner||itemsss.id!='200158'" :title="itemsss.text" v-for='itemsss in itemss.sub' :key="itemsss.id" :label="itemsss.id" @change="handleCheckedCitiesChange2($event,item,items,itemss,itemsss,index,index1)">{{itemsss.text}}</el-checkbox>
                                        </el-checkbox-group>
                                    </span>
                                </div>
						  </el-checkbox-group>
					</span>
					</div>
				</fieldset>
			</el-form>
		</div>
		<div class="ml-50 btnDivClass">
				<div class="ml-100">
					<el-button type="primary" @click='addInfo' :disabled="bool">确定</el-button>
					<el-button @click.native='goPage()'>取消</el-button>
				</div>
			</div>
	</div>
</template>

<script>
	import { MessageBox } from 'element-ui';
	import { axiosApi, basePath } from "../../../api/api";
	export default {
		data() {

			return {
				sign: '',
				checkedAreas: [],
				id: "",
				list: [],
				dialogAreaVisible: false,
				disabled: false,
				bool: true,
				test: [],
				topRole: [], //上一级角色
				onelist: [], //获取已有资源
				roleName: '', //角色名称
				resources: [], //资源绑定
				roleLists: [], //所有角色
				path: {
					path: basePath + "/roleLimits"
				},
				config: { //修改默认属性
					label: "text",
					children: "sub",
					value: "id"
				},
				arr: []
			}
		},
        computed:{
            isCityPartner() {
                return this.$store.state.clientType.isCityPartner;
            }
        },
		methods: {
			//页面跳转
			goPage() {
				//				this.$router.push(basePath + '/limits');
				history.go(-1)
			},
			//input输入是否为空判断
			change() {
				if(this.roleName.length > 0 && this.roleName.length < 17) {
					this.disabled = false;
					this.bool = false
				} else {
					this.disabled = true;
					this.bool = true
				}
			},
			gethash() {
				var test = window.location.href;
				this.id = test.split('=')[1];
			},
			//			获取角色列表
			achieveRole() {
				const api = "/foundation/role/selectAll";
				const body = {}
				axiosApi(api, body, res => {
					this.roleLists = res.data.data;
				}, this.currentPage, 10)
			},
			//			获取所有权限
			achieveList() {
				const api = "/foundation/resource/alldisplaytree";
				const body = {
					loginName: sessionStorage.loginName,
					appId: 2
				}
				axiosApi(api, body, res => {
					this.list = res.data.data;
					this.checked();
				}, this.currentPage, 10)
			},
			checked() {
				let _this = this;
				this.list.forEach(function(item, index) {
					if(typeof item.checkAll == "undefined" || item.checkAll == null) {
						_this.$set(item, "checkAll", false)
					}
					if(typeof item.allAreas == "undefined") {
						_this.$set(item, "allAreas", [])
					}
					if(item.sub) {
						for(let i = 0; i < item.sub.length; i++) {
							if(typeof item.sub[i].checked == "undefined" || item.sub[i].checked == null) {
								_this.$set(item.sub[i], "checked", false)
							}
							if(typeof item.sub[i].checkedAreas == "undefined") {
								_this.$set(item.sub[i], "checkedAreas", [])
							}
                            if (item.sub[i].sub.length>0){
                                for (let j = 0; j < item.sub[i].sub.length; j++) {
                                    if(typeof item.sub[i].sub[j].checked == "undefined" || item.sub[i].sub[j].checked == null) {
                                        _this.$set(item.sub[i].sub[j],"checked", false)
                                    }
                                    if(typeof item.sub[i].sub[j].checkedAreas2 == "undefined") {
                                        _this.$set(item.sub[i].sub[j], "checkedAreas2", [])
                                    }
                                    if (item.sub[i].sub[j].sub.length > 0){
                                        for (let k = 0; k < item.sub[i].sub[j].sub.length; k++) {
                                            if(typeof item.sub[i].sub[j].sub[k].checked == "undefined" || item.sub[i].sub[j].sub[k].checked == null) {
                                                _this.$set(item.sub[i].sub[j].sub[k],"checked", false)
                                                console.log(123,item.sub[i].sub[j].sub[k], item.sub[i].sub[j].sub[k].checked);
                                            }
                                        }
                                    }
                                }
                            }
						}
					}

				})
                console.log(this.list);

            },
			//			二级全选
			handleCheckAllChange(item, items) {
                console.log(items.checked);
                if(items.checked) {
					item.checkAll = true //二级菜单有一个勾选，它对应的一级菜单一定勾选，取消某个二级菜单的勾选，不会影响上级菜单
					if(items.sub) { //二级菜单勾选，三级都选中
						items.sub.forEach(item => {
							items.checkedAreas.push(item.id)
                            if (item.sub){
                                item.checkedAreas2 = []
                                item.sub.forEach(itemss=>{
                                    itemss.checked = true
                                    item.checkedAreas2.push(itemss.id)
                                })
                            }
						})
					}
				} else { //二级菜单未勾选，三级都不选中
					items.checkedAreas = []
                    if (items.sub) {
                        items.sub.forEach(i=>{
                            i.checkedAreas2= []
                        })
                    }
				}

			},
			//			三级菜单全选
			handleCheckedCitiesChange(val,item, items, itemss) {
                console.log(val,item, items, itemss);
                itemss.checked =  val
                console.log(itemss.checked);
                if (itemss.checked){
                    item.checkAll = true
                    items.checked = true
                    if (itemss.sub) {
                        itemss.checkedAreas2 = []
                        itemss.sub.forEach(i=>{
                            i.checked = true
                            itemss.checkedAreas2.push(i.id)
                        })
                    }
                } else{
                    itemss.checkedAreas2 = []
                }
			},
            //			四级菜单全选
            handleCheckedCitiesChange2(val,item, items, itemss,itemsss,index,index1) {
                itemsss.checked = val

                if (itemsss.checked) {
                    this.$set(items,'checked',true)
                    items.checkedAreas.push(itemss.id)
                    console.log(itemss.checkedAreas2,itemsss.id);
                    // itemss.checkedAreas2.push(itemsss.id)
                    console.log(itemss.checkedAreas2,itemsss.id,11111);
                    this.$set(items.sub,index,itemss)
                    this.$set(item.sub,index1,items)
                    items.checked = true
                    itemss.checked = true
                    item.checkAll = true

                }else{
                    console.log(itemss.checkedAreas2,itemsss.id);
                    let index = itemss.checkedAreas2.findIndex(data => {return data === itemsss.id})
                    console.log(itemss.checkedAreas2,index,'定位');
                    // itemss.checkedAreas2.splice(index, 1)
                    console.log(itemss.checkedAreas2);
                }
                this.$forceUpdate()
            },
			//新增  一级菜单勾选
			all(item) {
				if(item.checkAll && item.sub) {
					let allAreas = [];
					item.sub.forEach((item1) => {
						item1.checked = true;
						allAreas.push(item1.id)
						if(item1.sub) {
							let checkedAreas = [];
							item1.sub.forEach((item2) => {
								checkedAreas.push(item2.id)
                                if (item2.sub){
                                    item2.sub.forEach(item3=>{
                                        item3.checked = true
                                        item2.checkedAreas2.push(item3.id)
                                        // if (item3.sub){
                                        //     item3.sub.forEach(item4=>{
                                        //         item3.checkedAreas2.push(item4.id)
                                        //     })
                                        // }
                                    })
                                }
							})
							item1.checkedAreas = checkedAreas;
						}
					})
					item.allAreas = allAreas;
				} else {
					item.allAreas = [];
					if(item.sub) {
						item.sub.forEach((item1) => {
							item1.checked = false;
							if(item1.sub) {
								item1.checkedAreas = [];
                                item1.sub.forEach(item2=>{
                                    item2.checked = false
                                    item2.checkedAreas2=[]
                                    if (item2.sub) {
                                        item2.sub.forEach(item3=>{
                                            item3.checked = false
                                        })
                                    }
                                })
							}
						})
					}
				}
			},
			//点击确定提交数据
			addInfo() {
				let arr = []
				this.list.forEach(item => {
					if(item.checkAll) {
						arr.push(item.id)
						if(item.sub) {
							item.sub.forEach(item1 => {
								if(item1.checked) {
									arr.push(item1.id)
									item1.checkedAreas.forEach(item2 => {
										arr.push(item2)
									})
                                    if (item1.sub){
                                        console.log('item1',item1);
                                        item1.sub.forEach(item2=>{
                                            if (item2.sub){
                                                item2.sub.forEach(item3=>{
                                                    if (item3.checked){
                                                        arr.push(item3.id)
                                                    }
                                                })
                                            }
                                        })
                                    }
								}
							})
						}
					}
				})
				this.arr = arr
				this.addRole()

			},
			//			增加角色
			addRole() {
				const api = "/foundation/role/insert";
				const body = {
					id: '',
					roleName: this.roleName,
					resources: this.arr,
					traderId: sessionStorage.traderId,
					applicationType: 2

				}
				axiosApi(api, body, res => {
					this.$message({
						title: '成功',
						message: '恭喜你，添加成功！',
						type: 'success'
					});

					if(this.$route.params.sign == 1) {
						this.sign = this.$route.params.sign
						this.$router.push(basePath + '/addPeople/' + this.sign)
					} else if(this.$route.params.sign == 2) {
						this.sign = this.$route.params.sign
						this.$router.push(basePath + '/editPeople/' + this.sign)
					} else {
						this.$router.push(basePath + '/roleLimits')
					}
				}, this.currentPage, 10, (res) => {
					this.$message({
						type: 'warning',
						message: res.data.message,
						duration: 1000
					});
				})
			},
		},
		mounted() {
			this.$nextTick(function() {
				this.achieveList()
				this.achieveRole()

			})
		}
	}
</script>

<style lang="less">
	#check {
		padding-top: 1px;
		background-color: #ffffff;
		.infoDiv{
			overflow: auto;
			height: 75vh;
		}	
		.el-checkbox {
			margin-left: 0;
		}
		.el-checkbox__label {
			display:inline !important;
		}
		.w-600 {
			width: 600px;
			display: block;
		}
		.width-110 {
			width: 110px;
		}
		.hides {
			overflow: hidden;
			/*自动隐藏文字*/
			text-overflow: ellipsis;
			/*文字隐藏后添加省略号*/
			white-space: nowrap;
		}
		legend label {
			margin-right: 0px !important;
			line-height: initial;
			margin-bottom: 0px !important;
		}
		.c {
			position: relative;
		}
		.cc {
			position: absolute;
			left: 0;
			top: 110%
		}
		.d {
			display: none;
		}
		.dd {
			display: inline-block;
		}
		.btnDivClass{
			background:#fff;
			height:90px;
			// .btnDivClassFixed{
			// 	height:90px;
			// 	width: 70%;
			// 	background:#fff;
			// 	position: fixed;
			// 	bottom: 0;
			// 	z-index: 2;
			// 	padding:20px 0;
			// 	box-sizing: border-box;
			// }
		}
	}
</style>