<template >
    <div class="tableA">
        <div class="clearfix">
            <el-form :inline="true" label-width="80px" class="fl search" v-if="!noform">
                <el-form-item label="客户名称:">
                    <el-input placeholder="输入客户名称" v-model.trim="storeName"></el-input>
                </el-form-item>
                <el-form-item label="客户类型:" v-if="activitySource == 1">
                    <el-select v-model="ctype" @change="typeSelectChange" clearable class="type-select">
                        <el-option v-for="item in optionsType" :label="item.label" :value="item.value"
                                   :key="item.value"></el-option>
                    </el-select>
                    <el-cascader ref="cchannel" :options="clientChannelList" class="type-cascader" placeholder="选择终端渠道"
                                 :props="channelProp" :disabled="isDisabled" clearable change-on-select
                                 v-model="cchannel"></el-cascader>
                </el-form-item>
                <el-form-item label="所属分组:" :class="{'mt-10': activitySource == 1}">
                    <!-- <el-select v-model="groupId" placeholder="选择分组" ref="group" clearable>
                        <el-option v-for="n in groupList" :label="n.groupName" :value="n.id" :key="n.id"></el-option>
                    </el-select> -->
                    <TreeGroup v-model="groupId" />
                </el-form-item>
                <el-form-item label="所属区域:" class="mt-10">
                    <el-cascader :props="districtProps" ref="district" :options="districtList" clearable
                                 change-on-select placeholder="选择区域" v-model="districtIds"></el-cascader>
                </el-form-item>
            </el-form>
            <el-button v-if="!noform" type="primary" class="fl ml-20" @click="queryclient">查询</el-button>
        </div>
        <div class="clearfix mt-20" v-if='total>0 && !noform' style="paddingLeft: 17px;">
            <el-checkbox class="fl " v-model="allclientsChecked" @change="checkAllclient">选中全部客户</el-checkbox>
            <el-button class="fl ml-20" type='text' @click="delclients">批量删除</el-button>
        </div>
        <el-table :data="tableData" tooltip-effect="dark" ref="multipleClientTable" class="mt-10 table-default"
                  @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="50" v-if="!check">
            </el-table-column>
            <el-table-column label="客户名称" :width="size[1]" prop='storeName'>
            </el-table-column>
            <el-table-column label="客户类型" :width="size[2]">
                <template slot-scope="scope">
                    <span v-if="scope.row.ctype == 1">经销商</span>
                    <span v-else-if="scope.row.ctype == 2">终端</span>
                    <span v-else-if="scope.row.ctype == 3">久加久*久柜</span>
                    <span v-else-if="scope.row.ctype == 6">普通酒店</span>
                    <span v-else-if="scope.row.ctype == 5">久加久*店中店</span>
                    <span v-else-if="scope.row.ctype == 4">久加久*连锁店</span>
                    <span v-else-if="scope.row.ctype == 41">久加久*连锁店/直营</span>
                    <span v-else-if="scope.row.ctype == 42">久加久*连锁店/加盟</span>
                </template>
            </el-table-column>
            <el-table-column label="终端渠道" prop="channelName">
            </el-table-column>
            <el-table-column label="所属分组" prop="groupName">
            </el-table-column>
            <el-table-column label="所属区域" prop='districtName' :width="size[3]">
            </el-table-column>
            <el-table-column label="操作" v-if="!operate">
                <template slot-scope="scope">
                    <el-button type="text" @click.native.prevent="deleteRow(scope.$index, tableData)" size="small">删除
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <div class="clearfix" v-if='total>0'>
            <el-pagination
                    class="fr mt-20 mr-50"
                    :current-page="currentPage"
                    :page-size='10'
                    :total='total'
                    layout="prev , pager, next, jumper"
                    v-if="tabletotalData.length>0"
                    @current-change="handleit"
            >
                <!-- 分页组件 -->
            </el-pagination>
        </div>
    </div>
</template>

<script>
    import {mapMutations,mapActions} from 'vuex'
    import TreeGroup from '~/components/treeGroup'
    export default {
        props: ['size', 'check', 'operate', 'noform'],
        components: {
            TreeGroup
        },
        data() {
            return {
                currentPage: 1,
                tableData: [],
                storeName: "",
                ctype: '',//客户类型
                cchannel: [],//客户渠道
                groupId: null,//所属分组
                districtIds: [],//所属区域
                isDisabled: false,
                districtProps: {
                    label: 'districtName',
                    value: 'id',
                    children: 'subDistrictList'
                },
                channelProp: {
                    label: 'classifyName',
                    value: 'id',
                    children: 'sub'
                },
                allclientsChecked: false,//选中全部客户
                searchedTableData: [], //查询条件后的 客户
                isFirst: 1,
                isDelete: false,// 是否删除
                isSearched: false,// 是否已经搜索
                allMultipleSelection: [],//全部数据选中
            }
        },
        watch: {
            currentPage() {
                if (this.searchedTableData.length == 0 && !this.isSearched) {
                    this.tableData = this.tabletotalData.slice((this.currentPage - 1) * 10, (this.currentPage - 1) * 10 + 10)
                } else {
                    this.tableData = this.searchedTableData.slice((this.currentPage - 1) * 10, (this.currentPage - 1) * 10 + 10)
                }
            },
            total() {
                if (this.searchedTableData.length == 0 && !this.isSearched) {
                    this.tableData = this.tabletotalData.slice((this.currentPage - 1) * 10, (this.currentPage - 1) * 10 + 10)
                } else {
                    this.tableData = this.searchedTableData.slice((this.currentPage - 1) * 10, (this.currentPage - 1) * 10 + 10)
                }
            },
            tabletotalData() {
                if (this.isFirst > 1 && !this.isDelete) {
                    this.storeName = ""
                    this.ctype = ''//客户类型
                    this.cchannel = []//客户渠道
                    this.groupId = null//所属分组
                    this.districtIds = []//所属区域
                    this.queryclient()
                }
                this.isFirst += 1
                this.isDelete = false
            },
        },
        computed: {
            total() {
                if (this.searchedTableData.length == 0 && !this.isSearched) {
                    return this.$store.state.clientlist.willadd.length
                } else {
                    return this.searchedTableData.length
                }
            },
            tabletotalData() {
                console.log(this.$store.state.clientlist.willadd,'this.$store.state.clientlist.willadd')
                return this.$store.state.clientlist.willadd
            },
            districtList() {
                return this.cascaderClear2(this.$store.state.channelLevel.districtList) || []
            },
            // groupList() {
            //     return this.$store.state.channelLevel.groupList
            // },
            clientChannelList() {
                return this.cascaderClear(this.$store.state.channelLevel.clientChannelList) || []
            },
            activitySource() {// 1 - B2B  2 - 酒店
                return (this.$route.query.activitySource ? this.$route.query.activitySource : this.$route.query.couponSource) || 1
            },
            optionsType() {
                return this.$store.state.clientType.optionsType
            },
        },
        methods: {
            ...mapMutations({
                'deleteRowdata': 'deletewilladddata2',
                'clearwilladd': 'clearwilladd2',
                'willdeteleclients': 'willdeteleclients',
                'deleteclients': 'deleteclients', //删除客户
            }),
            ...mapActions('clientType',['getClientType']),
            queryclient() {// 查询 前端筛选
                this.isSearched = true
                // console.log(this.$refs) // 所属区域this.$refs.district.currentLabels  终端渠道this.$refs.cchannel.currentLabels  所属分组 this.$refs.group.selectedLabel
                this.searchedTableData = this.tabletotalData.filter(item => {
                    let a = item.storeName.indexOf(this.storeName) != -1, // 客户名称
                        b = !this.ctype || item.ctype == this.ctype, //客户类型
                        c = this.cchannel.length == 0 || item.channelName == this.$refs.cchannel.currentLabels[this.$refs.cchannel.currentLabels.length - 1],//终端渠道
                        d = !this.groupId || this.groupId == item.groupId, // 所属分组
                        e = this.districtIds.length == 0 || this.$refs.district.currentLabels[this.$refs.district.currentLabels.length - 1] == item.districtName //所属区域
                    return a && b && c && d && e
                })
                this.tableData = this.searchedTableData.slice((this.currentPage - 1) * 10, (this.currentPage - 1) * 10 + 10)
                this.currentPage = 1
            },
            checkAllclient(e) { //选择全部的客户
                if (e) {

                        if (this.searchedTableData.length == 0 && !this.isSearched) {// 使用 tabletotalData
                            this.allMultipleSelection = [...this.tabletotalData]
                        } else { // 使用 searchedTableData
                            this.allMultipleSelection = [...this.searchedTableData]
                        }
                        this.$refs.multipleClientTable.clearSelection()
                        this.allMultipleSelection.forEach(row => {
                            this.$refs.multipleClientTable.toggleRowSelection(row)
                        })

                } else {

                        this.allMultipleSelection = []
                        this.$refs.multipleClientTable.clearSelection()

                }
            },
            handleit(val) {
                this.currentPage = val

                    if (this.allMultipleSelection.length > 0) {// 有选中的数据
                        this.allMultipleSelection.forEach(row => {
                            this.$refs.multipleClientTable.toggleRowSelection(row)
                        })
                    } else {// 不存在选中数据
                        this.$refs.multipleClientTable.clearSelection()
                    }
            },
            handleSelectionChange(val) {
                this.willdeteleclients(val)

                    if (this.allMultipleSelection.length > val.length) {
                        this.allMultipleSelection = [...val]
                    }
                    if (this.searchedTableData.length == 0 && !this.isSearched) {
                        this.allclientsChecked = this.allMultipleSelection.length === this.tabletotalData.length
                    } else {
                        this.allclientsChecked = this.allMultipleSelection.length === this.searchedTableData.length
                    }
                if (this.allMultipleSelection.length <= val.length) {
                    this.allMultipleSelection.push(...val)
                    this.allMultipleSelection = [...new Set(this.allMultipleSelection)]
                }
                if (this.allMultipleSelection.length > 0) {
                    if (this.searchedTableData.length == 0 && !this.isSearched) {
                        this.allclientsChecked = this.allMultipleSelection.length === this.tabletotalData.length
                    } else {
                        this.allclientsChecked = this.allMultipleSelection.length === this.searchedTableData.length
                    }
                }
            },
            delclients() {//批量删除客户
                if (this.$store.state.clientlist.willdelete.length != 0) {
                    this.$msgbox({
                        title: '提示信息',
                        message: '您确定要删除这些客户？',
                        showCancelButton: true,
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        beforeClose: (action, instance, done) => {
                            if (action === 'confirm') {
                                this.deleteclients()
                                this.allclientsChecked = false
                                done();
                            } else {
                                done();
                            }
                        }
                    })
                } else if (this.$store.state.clientlist.willadd.length == 0) {
                    this.$alert('无客户可删除，请先添加客户', '', {
                        confirmButtonText: '确定',
                        callback: action => {

                        }
                    });
                } else {
                    this.$alert('请先勾选需要删除的客户', '', {
                        confirmButtonText: '确定',
                        callback: action => {

                        }
                    });
                }
            },
            typeSelectChange(val) {
                if (val == "1") {
                    this.isDisabled = true;
                    this.cchannel = [];
                } else {
                    this.isDisabled = false;
                }
            },
            deleteRow(index, rows) {
                let that = this;
                this.$msgbox({
                    title: '提示信息',
                    message: '您确定要删除这条信息？',
                    showCancelButton: true,
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    beforeClose: (action, instance, done) => {
                        if (action === 'confirm') {
                            this.isDelete = true //确定 isDelete 置为 true
                            if (this.searchedTableData.length == 0) {
                                this.deleteRowdata((this.currentPage - 1) * 10 + index)//更改 tabletotalData
                            } else {
                                this.willdeteleclients([rows[index]])// 添加到删除列表
                                this.searchedTableData.splice((this.currentPage - 1) * 10 + index, 1)
                                this.deleteclients()
                            }
                            done();
                        } else {
                            done();
                        }
                    }
                })
            }
        },
        mounted() {
            this.$nextTick(() => {
                this.clearwilladd()
                this.getClientType()
            })
        }
    }
</script>

<style lang="less">
    .tableA {
        .el-form {
            .el-form-item {
                .el-form-item__label {
                    &::before {
                        display: none
                    }
                }
            }
        }

        .search {
            width: 680px;

            .type-select, .type-select .el-input {
                width: 100px;
            }

            .type-cascader, .type-cascader .el-input {
                width: 135px;
            }
        }
    }
</style>
