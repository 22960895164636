<template>
	<div>
		<div class="title-top bg-f title-tabs">
			<span>添加{{houseTypeText}}库单</span>
		</div>

		<div class="stock-house-order-add bg-f">
			<div class="less_pad">
				<div class="input-block">
					<!--<el-button type="default" v-if="putIn" :disabled="$route.path.indexOf('/1') == -1 && !this.formInlineData.houseware" @click="openDialog">从采购单导入</el-button>-->
					<el-form
						:inline="true"
						:model="formInlineData"
						label-width="90px"
						class="clearfix pt-30 search"
						:rules="rules"
						ref="formData"
					>
						<el-form-item label="单据类型" prop="inOrOutType">
							<el-select v-model="formInlineData.inOrOutType">
								<el-option
										:label="item.label"
										:value="item.value"
										v-for="(item, index) in whichTypeList"
										:key="index"
								></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="供应商" v-if="$route.path.indexOf('/1') != -1">
							<el-select v-model="formInlineData.provider" value-key="sellerId" clearable>
								<el-option
										:label="item.supplierName"
										:value="item"
										v-for="(item, index) in formInlineData.providerList"
										:key="index"
								></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="客户名称" v-else>
							<el-select v-model="formInlineData.buyerId" clearable  filterable :filter-method="getClient" @change="changeClient"
									   placeholder="请输入客户名称">
								<el-option
										class="optionsList"
										v-for="item in options"
										:key="item.id"
										:label="item.storeName"
										:value="item.id">
									<div>
										<p >{{ item.storeName }}</p>
										<p style="color: #666; font-size: 12px">{{ item.platformDescription }}</p>
									</div>
								</el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="收货人" v-if="$route.path.indexOf('/1') == -1">
							<el-input v-model.trim="formInlineData.addrUsername" :maxlength="10"></el-input>
						</el-form-item>
						<el-form-item label="联系电话" v-if="$route.path.indexOf('/1') == -1" prop="addrTel">
							<el-input v-model.trim="formInlineData.addrTel" :maxlength="12"></el-input>
						</el-form-item>
						<el-form-item label="收货地址" v-if="$route.path.indexOf('/1') == -1" class="addr_block">
							<selectArea v-model="formInlineData.districtIds" @changeName="changeName" />
							<el-form-item class="fl">
								<el-input style="width: 325px" v-model.trim="formInlineData.addr" :maxlength="40" placeholder="请填写详细地址" ></el-input>
							</el-form-item>
						</el-form-item>
						<el-form-item label="仓库名称" prop="houseware">
							<el-select v-model="formInlineData.houseware" clearable @change="housewareChange">
								<el-option
									:label="item.name"
									:value="item.id"
									v-for="(item, index) in formInlineData.housewareList"
									:key="index"
								></el-option>
							</el-select>
						</el-form-item>

						<el-form-item label="操作人" prop="opeartor">
							<el-select v-model="formInlineData.opeartor" value-key="id">
								<el-option
									:label="item.employeeName"
									:value="item"
									v-for="(item, index) in formInlineData.operatorList"
									:key="index"
								></el-option>
							</el-select>
						</el-form-item>
						<el-form-item :label="houseTypeText+'库备注'">
							<el-input placeholder='备注内容，200字以内' style="width:673px" show-word-limit  v-model.trim="formInlineData.remark" :maxlength="200" :autosize="{ minRows: 3, maxRows: 4}" type="textarea"></el-input>
						</el-form-item>
					</el-form>
				</div>

				<!--商品信息-->
				<div class="goods-block clearfix mt-50">

					<div class="pr-20 pl-20">
						<div class="table-head">
							<span><i class="el-icon-menu mr-10"></i> 数据列表</span>
							<div>
								<el-button :disabled="!this.formInlineData.houseware" @click="addGoods" class="">添加商品</el-button>
								<el-button :disabled="!this.formInlineData.houseware" v-if="$route.path.indexOf('/1') == -1" @click="addGift" class="">添加赠品</el-button>
							</div>
						</div>
						<div class="clearfix item-title item-item">
							<div>
								<div class="order-item-item fl tac">
									商品名称
								</div>
								<div class="order-item-item-1 fl">
									规格
								</div>
								<div class="order-item-item-2 fl">
									分类
								</div>
								<div class="order-item-item-3 fl">
									仓库名称
								</div>
								<div class="order-item-item-4 fl">
									基本单位
								</div>
								<div class="order-item-item-5 fl">
									{{houseTypeText}}库数量
								</div>
								<div class="order-item-item-6 fl">
									{{this.$route.path.indexOf('/1') == -1 ? '单价' : '成本价'}}
								</div>
								<div class="order-item-item-7 fl">
									{{this.$route.path.indexOf('/1') == -1 ? '金额' : '总成本'}}
								</div>
								<div class="order-item-item-8 fr">
									操作
								</div>
							</div>
						</div>
						<div class="item-content">
							<!-- 商品列表 -->
							<div v-if="GoodsData.length > 0 || GiftData.length > 0">
								<div class="item-item" v-for="(i, index) in GoodsData" :key="index" style="position:relative">
									<div class="clearfix ">
										<div class="clearfix order-item-item fl" style="position: relative;">
											<img v-if="i.fileUrl" class="fl img mr-10" :src="i.fileUrl" />
											<img v-else src="../../assets/goods.jpg" class="fl img mr-10"/>
											<div class="fl">
												<p>商品编码：{{i.goodsNo}}</p>
												<p>{{i.skuName}}</p>
											</div>
										</div>
										<div class="order-item-item-1 fl">
											1*{{i.specInfoNum}}
										</div>
										<div class="order-item-item-2 fl">
											{{i.classifyName}}
										</div>
										<div class="order-item-item-3 fl">
											{{housewareName}}
										</div>
										<div class="order-item-item-4 fl">
											{{i.unitsName || i.giftUnit}}
										</div>
										<div class="order-item-item-5 fl">
											<el-input-number size="small" v-model="i.num" @change="changeStep(index,1)" :step="1" :min="1" :max="99999"></el-input-number>
										</div>
										<div class="order-item-item-6 fl">
											<sy-input  size="small" @blur="changePrice(index,1)" v-model="i.unitEnterShopPrice" type="float" :min="0.01" :max="100000.00"></sy-input>
										</div>
										<div class="order-item-item-7 fl">
											{{(i.unitEnterShopPrice * i.num).toFixed(2) }}
										</div>
										<div class="order-item-item-8 fr">
											<i class="el-icon-circle-close color-b font-24 mt-5" @click="delCar(i,index)"></i>
										</div>
									</div>
								</div>
								<div class="item-item" v-for="(i, index) in GiftData" :key="index" style="position:relative">
									<div class="clearfix ">
										<div class="clearfix order-item-item fl" style="position: relative;">
											<img width="80" src="../../assets/superGroup/complimentary.png" style="position: absolute;top: 0px;left: 0px;" />
											<img v-if="i.giftPic" class="fl img mr-10" :src="i.giftPic" />
											<img v-else src="../../assets/goods.jpg" class="fl img mr-10"/>
											<div class="fl">
												<p>商品编码：{{i.goodsNo}}</p>
												<p>{{i.skuName}}</p>
											</div>
										</div>
										<div class="order-item-item-1 fl">
											1*{{i.specInfoNum}}
										</div>
										<div class="order-item-item-2 fl">
											{{i.classifyName}}
										</div>
										<div class="order-item-item-3 fl">
											{{housewareName}}
										</div>
										<div class="order-item-item-4 fl">
											{{i.unitsName || i.giftUnit}}
										</div>
										<div class="order-item-item-5 fl">
											<el-input-number size="small" v-model="i.num" @change="changeStep(index,2)" :step="1" :min="1" :max="99999"></el-input-number>
										</div>
										<div class="order-item-item-6 fl">
											<sy-input disabled size="small" v-model="i.unitEnterShopPrice" type="float" :min="0.01" :max="100000.00"></sy-input>
										</div>
										<div class="order-item-item-7 fl">
											{{i.totalPrice }}
										</div>
										<div class="order-item-item-8 fr">
											<i class="el-icon-circle-close color-b font-24 mt-5" @click="delCar(i,index)"></i>
										</div>
									</div>
								</div>

							</div>

							<div class="item-item text-center" v-else>暂无数据</div>
						</div>
					</div>
				</div>
				<!--选择商品-->
				<el-dialog title="选择商品" :visible.sync="dialog1Visible" :before-close="cancelGoods">
					<div class="fl" slot="title">
						<el-tabs v-model="goodsTab">
							<el-tab-pane label="选择商品" name="1"></el-tab-pane>
							<el-tab-pane :label="'已选商品('+tableDataHave.length+')'" name="2"></el-tab-pane>
						</el-tabs>
					</div>
					<div class="pb-50">
						<el-form v-if="goodsTab == 1" :inline="true" :model="skuObj" label-width="80px" class="clearfix" :style="{width:'850px'}">
							<div class="fl choose-form">
								<el-form-item label="商品编码:">
									<el-input v-model.trim="skuObj.goodsNo" clearable></el-input>
								</el-form-item>
								<el-form-item label="商品名称:">
									<el-input v-model.trim="skuObj.skuName" clearable></el-input>
								</el-form-item>
								<el-form-item label="商品分类:">
									<el-select v-model='skuObj.classifyId' placeholder='请选择' clearable>
										<el-option v-for='item in classifylist' :key='item.id' :label='item.classifyName'
												   :value='item.id'></el-option>
									</el-select>
								</el-form-item>
							</div>
							<el-button type="primary" class="fl ml-50" @click="getGoodsData()">查询</el-button>
						</el-form>
						<div v-if="goodsTab == 1">
							<el-table v-loading="tableloading" :data="tableData" stripe border tooltip-effect="dark"
									  class="mt-10 table-default">
								<el-table-column label="商品编码" width="120">
									<template slot-scope="scope">
										{{scope.row.goodsNo }}
									</template>
								</el-table-column>
								<el-table-column label="商品名称" prop='skuName' width="350" show-overflow-tooltip>
									<template slot-scope="scope">
										<div class="flex">
											<div class="flex-1 ml-10" style="overflow: hidden">
												<div class="tal  text-overflow" :title="scope.row.skuName">
													{{scope.row.skuName}}
												</div>
											</div>
										</div>
									</template>
								</el-table-column>
								<el-table-column :label="$route.path.indexOf('/1') == -1 ? '单价' : '成本价'">
									<template slot-scope="scope">
										￥{{$route.path.indexOf('/1') == -1 ? scope.row.unitEnterShopPrice : scope.row.unitCostPrice | fmoney}}/{{ scope.row.units }}
									</template>
								</el-table-column>
								<el-table-column label="商品分类" prop='classifyName'>
									<template slot-scope="scope">
										{{ scope.row.classifyId!==499 ?　scope.row.classifyName : '其他' }}
									</template>
								</el-table-column>
								<el-table-column label="商品库存">
									<template slot-scope="scope">
										<div>{{ scope.row.stockEnableQuantity }}</div>
									</template>
								</el-table-column>
								<el-table-column label="基本单位" prop='units'>
								</el-table-column>
								<el-table-column label="操作">
									<template slot-scope="scope">
										<div v-if="$route.path.indexOf('/1') == -1">
											<p class="tac" v-if="scope.row.stockEnableQuantity === 0" style="line-height: 25px;">库存不足</p>
											<div v-else>
												<el-button v-if="!scope.row.selected" type="text" @click="addGoodsData(scope)">
													添加
												</el-button>
												<p class="tac" v-else style="line-height: 25px;">已添加</p>
											</div>
										</div>
										<div v-else>
											<el-button v-if="!scope.row.selected" type="text" @click="addGoodsData(scope)">
												添加
											</el-button>
											<p class="tac" v-else style="line-height: 25px;">已添加</p>
										</div>
									</template>
								</el-table-column>
							</el-table>
							<div class="clearfix" v-if='total>0'>
								<el-pagination class="fr" v-if='total>0' :current-page="currentPage" :page-size='10' :total='total'
											   layout="prev , pager, next" @current-change="handlePageChange">
								</el-pagination>
							</div>
						</div>
						<div v-if="goodsTab == 2">
							<el-table v-loading="tableloading" :data="tableDataHave2" stripe border tooltip-effect="dark"
									  class="mt-10 table-default">
								<el-table-column label="商品编码" width="120">
									<template slot-scope="scope">
										{{ scope.row.goodsNo}}
									</template>
								</el-table-column>
								<el-table-column label="商品名称" prop='skuName' width="350" show-overflow-tooltip>
									<template slot-scope="scope">
										<div class="flex">
											<div class="flex-1 ml-10" style="overflow: hidden">
												<div class="tal  text-overflow" :title="scope.row.skuName">
													{{scope.row.skuName}}
												</div>
											</div>
										</div>
									</template>
								</el-table-column>
								<el-table-column :label="$route.path.indexOf('/1') == -1 ? '单价' : '成本价'">
									<template slot-scope="scope">
										￥{{$route.path.indexOf('/1') == -1 ? scope.row.unitEnterShopPrice : scope.row.unitCostPrice | fmoney}}/{{ scope.row.units }}
									</template>
								</el-table-column>
								<el-table-column label="商品分类" prop='classifyName'>
									<template slot-scope="scope">
										{{ scope.row.classifyId!==499 ?　scope.row.classifyName : '其他' }}
									</template>
								</el-table-column>
								<el-table-column label="商品库存">
									<template slot-scope="scope">
										<div>{{ scope.row.stockEnableQuantity }}</div>
									</template>
								</el-table-column>
								<el-table-column label="基本单位" prop='units'>
								</el-table-column>
								<el-table-column label="操作">
									<template slot-scope="scope">
										<el-button type="text" @click="delGoodsData(scope)">删除</el-button>
									</template>
								</el-table-column>
							</el-table>
							<div class="clearfix" v-if='totalHave>0'>
								<el-pagination class="fr" v-if='totalHave>0' :current-page="currentPageHave" :page-size='10' :total='totalHave'
											   layout="prev , pager, next" @current-change="handlePageChangeHave">
								</el-pagination>
							</div>
						</div>
					</div>
					<div slot="footer" class="dialog-footer">
						<el-button type="primary"  :loading="btnLoading"  @click="confirmGoods">确 定</el-button>
						<el-button @click="cancelGoods">取 消</el-button>
					</div>
				</el-dialog>
				<!--选择赠品-->
				<el-dialog title="选择赠品" :visible.sync="dialog1Visible2" :before-close="cancelGift">
					<div class="fl" slot="title">
						<el-tabs v-model="giftTab">
							<el-tab-pane label="选择赠品" name="1"></el-tab-pane>
							<el-tab-pane :label="'已选赠品('+giftTableDataHave.length+')'" name="2"></el-tab-pane>
						</el-tabs>
					</div>
					<div>
						<el-form v-if="giftTab == 1" :inline="true" :model="giftObj" label-width="80px" class="clearfix" :style="{width:'850px'}">
							<div class="fl choose-form">
								<el-form-item label="商品编码:" >
									<el-input v-model.trim="giftObj.goodsNo" clearable></el-input>
								</el-form-item>
								<el-form-item label="商品名称:">
									<el-input v-model.trim="giftObj.skuName" clearable></el-input>
								</el-form-item>
								<el-form-item label="商品分类:">
									<el-select v-model='giftObj.classifyId' placeholder='请选择' clearable>
										<el-option v-for='item in classifylist' :key='item.id' :label='item.classifyName'
												   :value='item.id'></el-option>
									</el-select>
								</el-form-item>
							</div>
							<el-button type="primary" class="fl ml-50" @click="giftCurrentPage = 1; getGiftData()">查询</el-button>
						</el-form>
						<div v-if="giftTab == 1">
							<el-table v-loading="tableloading" :data="giftTableData" stripe border tooltip-effect="dark"
									  class="mt-10 table-default">
								<el-table-column label="商品编码" width="120">
									<template slot-scope="scope">
										{{ scope.row.k3GoodsNo }}
									</template>
								</el-table-column>
								<el-table-column label="商品名称" prop='skuName' width="350" show-overflow-tooltip>
									<template slot-scope="scope">
										<div class="flex">
											<div class="flex-1 ml-10" style="overflow: hidden">
												<div class="tal  text-overflow" :title="scope.row.skuName">
													{{scope.row.skuName}}
												</div>
											</div>
										</div>
									</template>
								</el-table-column>
								<el-table-column label="单价">
									<template slot-scope="scope">
										￥{{scope.row.giftPrice | fmoney}}/{{ scope.row.giftUnit }}
									</template>
								</el-table-column>
								<el-table-column label="商品分类" prop='classifyName'>
									<template slot-scope="scope">
										{{ scope.row.classifyId!==499 ?　scope.row.classifyName : '其他' }}
									</template>
								</el-table-column>
								<el-table-column label="商品库存">
									<template slot-scope="scope">
										<div>
											<p>{{ scope.row.giftStock }}</p>
										</div>
									</template>
								</el-table-column>
								<el-table-column label="基本单位" prop='giftUnit'>
								</el-table-column>
								<el-table-column label="操作">
									<template slot-scope="scope">
										<p class="tac" style="line-height: 25px;"  v-if="scope.row.giftStock === 0">库存不足</p>
										<div v-else>
											<el-button v-if="!scope.row.selected" type="text" @click="addGiftData(scope)">
												添加
											</el-button>
											<p class="tac" style="line-height: 25px;"  v-else>已添加</p>
										</div>
									</template>
								</el-table-column>
							</el-table>
							<div class="clearfix" v-if='totalGift>0'>
								<el-pagination class="fr" v-if='totalGift>0' :current-page="giftCurrentPage" :page-size='10' :total='totalGift'
											   layout="prev , pager, next" @current-change="giftHandlePageChange">
								</el-pagination>
							</div>
						</div>
						<div v-if="giftTab == 2">
							<el-table v-loading="tableloading" :data="giftTableDataHave2" stripe border tooltip-effect="dark" class="mt-10 table-default">
								<el-table-column label="商品编码" width="120">
									<template slot-scope="scope">
										{{scope.row.k3GoodsNo}}
									</template>
								</el-table-column>
								<el-table-column label="商品名称" prop='skuName' width="350" show-overflow-tooltip>
									<template slot-scope="scope">
										<div class="flex">
											<div class="flex-1 ml-10" style="overflow: hidden">
												<div class="tal  text-overflow" :title="scope.row.skuName">
													{{scope.row.skuName}}
												</div>
											</div>
										</div>
									</template>
								</el-table-column>
								<el-table-column label="单价">
									<template slot-scope="scope">
										￥{{scope.row.giftPrice | fmoney}}/{{ scope.row.giftUnit }}
									</template>
								</el-table-column>
								<el-table-column label="商品分类" prop='classifyName'>
									<template slot-scope="scope">
										{{ scope.row.classifyId!==499 ?　scope.row.classifyName : '其他' }}
									</template>
								</el-table-column>
								<el-table-column label="商品库存">
									<template slot-scope="scope">
										<div>
											<p >{{ scope.row.giftStock }}</p>
										</div>
									</template>
								</el-table-column>
								<el-table-column label="基本单位" prop='giftUnit'>
								</el-table-column>
								<el-table-column label="操作">
									<template slot-scope="scope">
										<el-button type="text" @click="delGiftData(scope)">删除</el-button>
									</template>
								</el-table-column>
							</el-table>
							<div class="clearfix" v-if='totalGiftHave>0'>
								<el-pagination class="fr" v-if='totalGiftHave>0' :current-page="giftCurrentPageHave" :page-size='10' :total='totalGiftHave'
											   layout="prev , pager, next" @current-change="giftHandlePageChangeHave">
								</el-pagination>
							</div>
						</div>
					</div>
					<div slot="footer" class="dialog-footer">
						<el-button type="primary"  :loading="btnLoading"  @click="confirmGift">确 定</el-button>
						<el-button @click="cancelGift">取 消</el-button>
					</div>
				</el-dialog>

				<div class="btm-block pb-50 pr-20">
					<div class="clearfix">
						<el-button class="fr" type="danger" :disabled="GoodsData.length == 0 && GiftData.length == 0" :loading="btnLoading" @click="ensureCommit">保存</el-button>
					</div>
				</div>
			</div>
		</div>
	</div>

</template>

<script>
import { axiosApi, basePath } from '../../api/api' //api
import {mapMutations, mapActions} from 'vuex'
export default {
	data() {
		return {
			GoodsData: [],
			GiftData: [],
			skuNoList: [],//已添加商品id
			tableloading: false,
			giftCheckNum: 0,
			total: 0,
			// totalHave: 0,
			totalGift: 0,
			// totalGiftHave: 0,
			goodsTab: '1',// 选择商品弹窗的tab
			giftTab: '1',//选择赠品弹窗的tab
			tableData: [],
			tableDataHave: [],
			giftTableData: [],
			giftTableDataHave: [],
			dialog1Visible: false,
			dialog1Visible2: false,
			currentPage: 1,
			currentPageHave: 1,
			giftCurrentPage: 1,
			giftCurrentPageHave: 1,
			skuObj: {
				skuNo: null,
				skuName: null, //商品名称
				classifyId: null, //商品分类
				sellerId: sessionStorage.getItem('traderId'),
			},
			giftObj:{
				skuName: null, //商品名称
				classifyId: null, //商品分类
				sellerId: sessionStorage.getItem('traderId'),
			},

			giftList:[],
			multipleSelection: [],
			fourListsDelivey: [],
			housewareName: '',//仓库名称
			putIn: false,//判断是否是添加入库单
			goodsList:[],
			leadInGoods:[],
			//仓库信息；入库类型；经办人；供应商；入库时间
			//仓库信息；出库类型；经办人；供应商；出库时间
			formInlineData: {
                addr:'',
				districtIds:[],
				buyerId:'',
				buyerName: '',
				streetName:'',
				streetId:'',
				provinceId: '',
				cityId: '',
				regionId:  '',
				provinceName:  '',
				cityName: '',
				regionName: '',
				addrStreetId: '',
				addrStreet: '',
				housewareList: [],
				houseware: '',
				billType: '',
				billTypeList: [
					{ value: '1', label: "单据类型一" },
					{ value: '2', label: "单据类型二" }
				],
				inTypeList: [
					{ value: 'purchase', label: "采购入库" },
					{ value: 'other', label: "其他入库" }
				],
				outTypeList: [
					{ value: 'other', label: "其他出库" }
				],
				inOrOutType: '',
				operatorList: [],
				opeartor: '',
				providerList: [],
				provider: {},
				gmtCreate: '',
				remark: ''
			},
			rules: {
				addrTel: [
					{
						required: false,
						pattern: /\d{7,12}/,
						message: '请输入正确的电话'
					}
				],
				houseware: [
					{
						required: true,
						message: '请选择仓库',
					}
				],
				inOrOutType: [
					{
						required: true,
						message: '请选择类型',
					}
				],
				opeartor: [
					{
						required: true,
						message: '请选择操作人',
					}
				]
			},
			btnLoading: false,
			operateType: this.$route.path.indexOf('/1') != -1 ? '1' : '0',
			valid: false,
			dialogTableVisible: false,
			stockHouseOrderForm: {
				orderSn:null,   //订单编号
				sellerId: null,
				traderType:1
			},
			btnLoading2: false,
			stockHouseOrderList: [],
			stockHouseOrderLoading: false,
			sellerList: [],
			saved: false,
			goodsNum : 5,
			options: [],
			areaNameList: []
		}
	},
	computed: {
		giftTableDataHave2() {
			return this.giftTableDataHave.slice(10*(this.giftCurrentPageHave-1),10*(this.giftCurrentPageHave-1)+10)
		},
		tableDataHave2() {
			return this.tableDataHave.slice(10*(this.currentPageHave-1),10*(this.currentPageHave-1)+10)
		},
		totalGiftHave() {
			return this.giftTableDataHave.length
		},//已选择的赠品数量
		totalHave() {
			return this.tableDataHave.length
		},//已选择的商品数量
		GoodsGiftData() {//商品赠品合集
			return [].concat(this.GoodsData,this.GiftData)
		},

		classifylist() {
			return this.$store.state.classifylist.classifylistData ? this.$store.state.classifylist.classifylistData : []
		},
		houseTypeText() {
			return this.$route.path.indexOf('/1') != -1 ? '入' : '出'
		},
		whichTypeList() {
		  let text = this.operateType == 1 ? this.formInlineData.inTypeList : this.formInlineData.outTypeList
		  return text;
		},
		postDatagood() {//已选择商品id
			let obj = {
				data: {
					skuState: null,
					notId: this.$store.getters.willaddid
				},
				currentPage: 1
			}
			obj.data.warehouseId = this.formInlineData.houseware
			return obj;
		},
	},
	watch: {
		'formInlineData.houseware'(val) {

			let data = this.formInlineData.housewareList.filter(i=>{
				return i.id == val
			})

			this.housewareName = data.length > 0 ? data[0].name : ''

		},
		dialog1Visible(val) {
			if (val) {
				this.getGoodsData()
			} else {
				this.skuObj = {
					skuNo: null,
					skuName: null, //商品名称
					classifyId: null, //商品分类
					sellerId: sessionStorage.getItem('traderId'),
				}
			}
		},
		dialog1Visible2(val) {
			if (val) {
				this.getGiftData()
			} else {
				this.giftObj = {
					skuName: null, //商品名称
					classifyId: null, //商品分类
					sellerId: sessionStorage.getItem('traderId'),
				}
			}
		},
	},
	methods: {
		...mapActions({
			'queryclassifylistData': 'queryclassifylistData',
		}),
		changeName(e){ // 省市区街道地址名
			this.areaNameList = e
		},
		//查询商品
		getGoodsData(bool = true) {
			if (bool && this.currentPage != 1) {
				this.currentPage = 1;
				return
			}
			this.tableloading = true;
			let url = '/goods/skuInfoPage/select';
			let body = {
				...this.skuObj,
				warehouseId: this.formInlineData.houseware,
				skuType: 1,
				warehouseStockIn: this.operateType === '1' ? true : false,
				auditState: 2
			};
			axiosApi(url,body, res => {
				this.tableloading = false;
				this.tableData =  res.data.data.elements.map(item=>{
					return {
						...item,
						num:1,
						k3SaleType: '001'
					}
				})
				this.tableData.map(v => {
					let flag = false;
					this.tableDataHave.map(item => {
						if (v.skuNo == item.skuNo) {
							flag = true
						}
					});
					if (!flag) {
						v.selected = false;
					} else {
						v.selected = true;
					}
				});
				this.tableDataHave = this.tableDataHave.concat(this.tableData.filter(v=>{
					let flag = true
					this.tableDataHave.forEach(item=>{
						if (item.skuNo == v.skuNo){
							flag = false
						}
					})
					return v.selected == true && flag
				}))

				this.total = res.data.data.totalCount;
			}, this.currentPage, 10, err => {
				this.tableloading = false;
				this.$message.warning(err.data.message)
			})
		},
		// 查询赠品
		getGiftData(bool = true) {
			if (bool && this.giftCurrentPage != 1) {
				this.giftCurrentPage = 1;
				return
			}
			let body = {
				...this.giftObj,
				sellerId: sessionStorage.getItem('traderId'),
				giftType: 0,
				warehouseId: this.formInlineData.houseware,
        isWarehouseStock: true
      }
			axiosApi('/marketing/gift/page',body,res=>{
				this.giftTableData = res.data.data.elements.filter((item)=>{
					return item.giftType == 0
				}).map(val=>{
					if (this.giftTableDataHave.length>0){
						let flag = true
						this.giftTableDataHave.forEach(data=>{
							if (data.skuNo == val.skuNo){
								flag = false
							}
						})
						if (!flag){
							return {
								...val,
								selected: true,
								isGift: true,
								num:1,
								goodsMinNum: 1,
								goodsMaxNum: 999999,
								k3GiftType: 'X105',
								k3SaleType: '001',
								// isIntegerMultiple: 1
								isIntegerMultiple: true
							}
						}else{
							return {
								...val,
								isGift: true,
								num:1,
								goodsMinNum: 1,
								goodsMaxNum: 999999,
								k3GiftType: 'X105',
								k3SaleType: '001',
								// isIntegerMultiple: 1
								isIntegerMultiple: true
							}
						}
					} else{
						return {
							...val,
							isGift: true,
							num:1,
							goodsMinNum: 1,
							goodsMaxNum: 999999,
							k3GiftType: 'X105',
							k3SaleType: '001',
							// isIntegerMultiple: 1
							isIntegerMultiple: true
						}
					}

				})
				this.giftTableDataHave = this.giftTableDataHave.concat(this.giftTableData.filter(v=>{
					let flag = true
					this.giftTableDataHave.forEach(item=>{
						if (item.skuNo == v.skuNo){
							flag = false
						}
					})
					return v.selected == true && flag
				}))
				this.totalGift = res.data.data.totalCount
			},this.giftCurrentPage,10,err=>{
				this.$message.err(err.data.message)
			})
		},
		//删除暂存的商品赠品
		delCar(item,index) {
			this.$confirm(`确认删除该${ item.isGift ? '赠':'商'}品吗?`, '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				if(item.isGift){
					this.GiftData.splice(index-this.GoodsData.length,1)
					this.giftTableData.map(i=>{
						if (i.skuNo == item.skuNo){
							return {
								...i,
								selected: false
							}
						} else{
							return i
						}
					})
					this.giftTableDataHave = this.giftTableDataHave.filter(i=>{
						if (i.skuNo == item.skuNo){
							return false
						} else{
							return true
						}
					})
				}else{
					this.GoodsData.splice(index,1)
					this.tableData.map(i=>{
						if (i.skuNo == item.skuNo){
							return {
								...i,
								selected: false
							}
						} else{
							return i
						}
					})
					this.tableDataHave = this.tableDataHave.filter(i=>{
						if (i.skuNo == item.skuNo){
							return false
						} else{
							return true
						}
					})
				}
			}).catch(err => {
			});
		},
		//查询客户
		getClient(query) {
			if (!query){
				return false
			}
			let url = this.isDealerUser ? '/sales/dealer/app/selectDealerCustomers' : '/trader/findClientAllList/select';
			let body = {};
			if(this.isDealerUser){
				body.storeName = query
			}else {
				body.storeName = query
				body.accountSource = "1"
				body.sellerId = sessionStorage.traderId
			}
			axiosApi(url, body, res => {
				this.options = [];
				res.data.data && res.data.data.elements.map(v => {
					v.id = v.buyerId;
					this.options.push(v);
				})
			},1,100)

		},
		//选择商品
		/* handleSelectionChange(val){
            this.confirmGift(val)
         },*/
		// 添加到已选择商品
		addGoodsData(item) {
			if (item.row.dealerGoods) {
				this.$message.warning(item.row.skuName + '是经销商商品，暂不支持下单');
				return
			}
			this.tableDataHave.push(item.row)
			item.row.selected = true
			this.$set(this.tableData,item.$index,item.row)
		},
		// 删除已选择的商品
		delGoodsData(item) {
			this.tableDataHave.splice(item.$index,1)
			item.row.selected = false
			this.tableData.map(i=>{
				if (i.skuNo == item.row.skuNo){
					return {
						...i,
						selected: false
					}
				} else{
					return i
				}
			})
		},
		//确认选择商品
		confirmGoods() {

			let data = this.tableDataHave.filter(v=>{
				let flag = true
				this.GoodsData.forEach(i=>{
					if(v.skuNo == i.skuNo){
						flag = false
					}
				})
				return flag
			})
			this.GoodsData = this.GoodsData.concat(data.map(v=>{
				let curEnterPrice = this.$route.path.indexOf('/1') != -1 ? v.unitCostPrice : v.unitEnterShopPrice;
				return {
					...v,
					num:1,
					unitEnterShopPrice: curEnterPrice != 0.00 ? curEnterPrice.toFixed(2) : '0.01',
					totalPrice: curEnterPrice != 0.00 ? (curEnterPrice * v.num).toFixed(2) : '0.01',
					showUnitPrice: v.showUnitPrice.toFixed(2)
				}
			})).filter(v=>{
				let flag =  false
				this.tableDataHave.forEach(i=>{

					if (v.skuNo == i.skuNo){
						flag = true
					}
				})
				return flag
			})
			this.dialog1Visible = false
		},
		// 取消选择的商品
		cancelGoods() {
			this.tableDataHave = this.GoodsData
			this.dialog1Visible = false
		},
		// 添加到已选择赠品
		addGiftData(item) {
			this.giftTableDataHave.push(item.row)
			item.row.selected = true
			this.$set(this.giftTableData,item.$index,item.row)
		},
		// 删除已选择的赠品
		delGiftData(item) {
			this.giftTableDataHave.splice(item.$index,1)
			item.row.selected = false
			this.giftTableData.map(i=>{
				if (i.skuNo == item.row.skuNo){
					return {
						...i,
						selected: false
					}
				} else{
					return i
				}
			})
		},
		// 确认选择赠品
		confirmGift() {
			let data = this.giftTableDataHave.filter(v=>{
				let flag = true
				this.GiftData.forEach(i=>{
					if(v.skuNo == i.skuNo){
						flag = false
					}
				})
				return flag
			})
			this.GiftData = this.GiftData.concat(data.map(v=>{
				return {
					...v,
					num:1,
					unitEnterShopPrice: '0.00',
					totalPrice: '0.00',
				}
			})).filter(v=>{
				let flag =  false
				this.giftTableDataHave.forEach(i=>{
					if (v.skuNo == i.skuNo){
						flag = true
					}
				})
				return flag

			})
			this.dialog1Visible2 = false
		},
		// 取消选择的赠品
		cancelGift() {
			this.giftTableDataHave = this.GiftData
			this.dialog1Visible2 = false
		},
		//分页
		handlePageChange(val) {
			this.currentPage = val;
			this.getGoodsData(false)
		},
		handlePageChangeHave(val) {
			this.currentPageHave = val;
		},

		giftHandlePageChange(val) {
			this.giftCurrentPage = val;
			this.getGiftData(false)
		},
		giftHandlePageChangeHave(val) {
			this.giftCurrentPageHave = val;
		},
		//设置选项框
		selectInit(row, index) {
			if(row.giftStatus == 1 || row.giftStock == 0) return false
			if(row.giftStatus == 0 ) return true
		},

		//选择客户
		changeClient(val) {
			this.getAddressList()
			this.options.map(v => {
				if (v.id == this.formInlineData.buyerId) {
					this.formInlineData.buyerName = v.storeName;
				}
			});
		},
		//获取地址列表
		getAddressList(){
			if (!this.formInlineData.buyerId) {
				this.formInlineData.districtIds = []
				this.formInlineData.addrUsername = ''
				this.formInlineData.addrTel = ''
				this.fourListsDelivey = []
				this.formInlineData.addr = ''
				return false
			}
			let body = {
				traderId: this.formInlineData.buyerId
			}
			axiosApi('/trader/orderAddress/selectList', body, res => {
				let data = []
				for (let i = 0;i < res.data.data.length; i++) {
					if (res.data.data[i].isDefault === 1 && res.data.data[i].state === 2) {
						data = res.data.data[i];
						break;
					}
				}
				if (data.length === 0) return;
				this.formInlineData.districtIds = [data.provinceId.toString(),data.cityId.toString(),data.regionId.toString(), data.streetId.toString()]
			
				this.formInlineData.addr = data.addr
				this.formInlineData.addrTel = data.tel
				this.formInlineData.addrUsername = data.linkman
			}, 1, 100, err => {
				this.$message.warning(err.data.message)
			})
		},
		//查询客户
		getClient(query) {
			if (!query){
				return false
			}
			let url = '/trader/findClientAllList/select';
			let body = {};

			body.storeName = query
			body.accountSource = "1"
			body.sellerId = sessionStorage.traderId
			axiosApi(url, body, res => {
				this.options = [];
				res.data.data && res.data.data.elements.map(v => {
					v.id = v.buyerId;
					this.options.push(v);
				})
			},1,100)

		},
		housewareChange() {
			this.GoodsData = []
			this.GiftData = []
			this.tableDataHave = []
			this.giftTableData = []
		},
	
		addGoods() {
			this.dialog1Visible = true
		},
		addGift(){
			this.dialog1Visible2 = true
		},
		buyerFindSellerList(bool) { // 供货商
			const api = "/warehouse/supplier/selectPage"
			let body = {
				traderId: sessionStorage.traderId,
				listType:'all'
			}
			axiosApi(api, body,res => {
				this.formInlineData.providerList = res.data.data.elements ? res.data.data.elements : []
			}, 1, 100,(err)=>{
				this.$message({type:'error',message:err.data.message,duration:1000})
			})
		},
		getSellerLists() {
			const api = "/trader/buyerFindSellerList/select"
			let body = {
				traderId: sessionStorage.traderId,
			}
			axiosApi(api, body,res => {
				this.sellerList = res.data.data.elements ? res.data.data.elements : []
			}, 1, 100,(err)=>{
				this.$message({type:'error',message:err.data.message,duration:1000})
			})
		},
		//加载员工列表/查询
		achieveEmplyee() {
			const api = "/foundation/employee/selectAll";
			const body = {
				applicationType: 2,
				traderId: sessionStorage.traderId,
				isDeleted: 0
			}
			axiosApi(api, body, res => {
				this.formInlineData.operatorList = res.data.data.elements ? res.data.data.elements : []
				res.data.data.elements.forEach((item, index) => {
					if (item.loginName == sessionStorage.loginName) {
						this.formInlineData.opeartor = item
					}
				})

			}, 1, 100, err => {
				this.$message({type:'error',message:err.data.message,duration:1000})
			})
		},
		getWareHouseList() {
			axiosApi('/warehouse/info/list',{},(res)=>{
				this.formInlineData.housewareList = res.data.data
			})
		},
		changeStep(index,flag) {
			if (flag == 1) {
				let item = this.GoodsData[index]
				setTimeout(()=>{
					item.num = item.num ? parseInt(item.num.toString().replace('.','')) : 0;
					if (item.type == 1){
						item.totalPrice = item.unitEnterShopPrice ? (item.num * item.unitEnterShopPrice).toFixed(2) : 0
					}
					this.$set(this.GoodsData, index, item)
				},0)
			}else{
				let item = this.GiftData[index]
				setTimeout(()=>{
					this.$set(this.GiftData, index, item)
				},0)
			}

		},
		changePrice(index,flag) {
			let item = null;
			if (flag == 1){
				item = this.GoodsData[index]

				setTimeout(()=>{
					if (isNaN(Number(item.unitEnterShopPrice))) {
						item.unitEnterShopPrice = 0.01
					} else if (Number(item.unitEnterShopPrice) <= 0){
						item.unitEnterShopPrice = 0.01
					} else if (Number(item.unitEnterShopPrice) > 100000) {
						item.unitEnterShopPrice = 100000.00
					}
					item.unitEnterShopPrice = Number(item.unitEnterShopPrice).toFixed(2)
					item.totalPrice = item.unitEnterShopPrice ? (item.num * item.unitEnterShopPrice).toFixed(2) : '0.00'
					this.$set(this.GoodsData, index, item)
				},0)
			}else{
				item = this.GiftData[index]
				setTimeout(()=>{
					if (Number(item.unitEnterShopPrice) <= 0){
						item.unitEnterShopPrice = 0.01
					} else if (Number(item.unitEnterShopPrice) > 100000) {
						item.unitEnterShopPrice = 100000.00
					}
					item.unitEnterShopPrice = item.unitEnterShopPrice.substr(0, (item.unitEnterShopPrice).indexOf('.') + 3)
					item.totalPrice = item.unitEnterShopPrice ? (item.num * item.unitEnterShopPrice).toString().substr(0, (item.num * item.unitEnterShopPrice).toString().indexOf('.') + 3) : '0.00'
					this.$set(this.GiftData, index, item)
				},0)
			}

		},
		ensureCommit() {
			let ary = [];
			this.GoodsData.forEach(item => {
				ary.push({
					warehouseId: this.formInlineData.houseware,//仓库id
					skuNo: item.skuNo,
					skuName: item.skuName,
					k3GoodsNo: item.goodsNo || item.k3GoodsNo,
					baseUnitName:  item.baseUnitName || item.units ,
					unitName:  item.baseUnitName || item.units ,
					baseUnit: item.spuVo.units ,
					sellingUnit: item.spuVo.units ,
					sellingUnitName:item.baseUnitName || item.units ,
					specInfoNum: item.specInfoNum,
					enterShopPrice: item.unitEnterShopPrice,
					amount: item.num,
					type: 1
				})
			})
			this.GiftData.forEach(item => {
				ary.push({
					warehouseId: this.formInlineData.houseware,//仓库id
					skuNo: item.skuNo,
					skuName: item.skuName,
					k3GoodsNo: item.goodsNo || item.k3GoodsNo,
					baseUnitName: item.giftUnit,
					unitName: item.giftUnit,
					baseUnit: item.giftUnit,
					sellingUnit:  item.giftUnit,
					sellingUnitName: item.giftUnit,
					specInfoNum: item.specInfoNum,
					enterShopPrice: 0.00,
					amount: item.num,
					type: 2
				})
			})
			let body = {
				addrUsername: this.formInlineData.addrUsername,
				addrTel: this.formInlineData.addrTel,

				addrProvinceId: this.formInlineData.districtIds[0],
				addrCityId:	this.formInlineData.districtIds[1],
				addrRegionId: this.formInlineData.districtIds[2],
				addrStreetId: this.formInlineData.districtIds[3],

				addrProvince: this.areaNameList[0],
				addrCity: this.areaNameList[1],
				addrRegion: this.areaNameList[2],
				addrStreet: this.areaNameList[3],

				addr: this.formInlineData.addr,
				warehouseId: this.formInlineData.houseware,// 仓库id
				stockType: this.operateType === '1' ? 'stockIn' : 'stockOut',// 出入库类型 入库stockIn 出库stockOut
				sellerId: this.formInlineData.provider ? this.formInlineData.provider.sellerId : null,// 供应商id
				sellerName: this.formInlineData.provider ? this.formInlineData.provider.supplierName : null, // 供应商名称
				remark: this.formInlineData.remark,// 备注
				employeeId: this.formInlineData.opeartor.id, // 经办人 id
				employeeName: this.formInlineData.opeartor.employeeName,// 经办人 名称
				warehouseDeliveryVoucherItemList: ary,
				gmtCreate: this.formInlineData.gmtCreate ? String(Date.parse(this.formInlineData.gmtCreate)) === 'NaN' ? this.formInlineData.gmtCreate : Date.parse(this.formInlineData.gmtCreate) : null
			}
			// stockInType// 入库类型purchase 采购 other 其他
			// 	stockOutType// 出库类型orders 销售出库 other 其他
			if(this.operateType === '1') {
				body.stockInType = this.formInlineData.inOrOutType
			} else {
				body.stockOutType = this.formInlineData.inOrOutType
				body.buyerId = this.formInlineData.buyerId;
				body.buyerName = this.formInlineData.buyerId ? this.formInlineData.buyerName : null
			}
			this.$refs.formData.validate(valid => {
				if(valid) {
					this.valid = true;
					this.btnLoading = true;
					axiosApi('/warehouse/deliveryVoucher/insert', body, res => {
						this.$message.success('添加成功！');
						this.btnLoading = false;
						this.saved = true
						this.cancelAndBack()
					}, 1, 10, err => {
						this.btnLoading = false;
						if (err.data.code == 'WH0012') {
							this.lessStock(err)
						} else if (err.data.code == 'WH0011') {
							this.moreReturn(err)
						} else if (err.data.code === 'WH0002') {
							this.$message.error('部分商品不在该仓库，请核查！');
						}else{
							this.$message.error(err.data.message);
						}
					})
				} else {
					this.$message.warning('请先完善信息！')
				}
			})
		},
		moreReturn(err) {
			const h = this.$createElement;
			let msg = JSON.parse(err.data.message);
			let msgData = [];
			if (Array.isArray(msg)) {
				msg.forEach(item => {
					msgData.push(
							h(
									"p",
									{ style: "font-size: 12px;line-height: 20px;color: #666;" },
									"商品编码：" +item.skuNo +"  " +item.skuName +"  可退数量" +item. returnNum + item.unitName
							)
					);
				});
			}
			let originData = [
				h("p", {style: "margin-bottom: 10px;"}, "以下商品或赠品退货数量大于可退数量，请核查出库单:")
			];
			let showData = [...originData, ...msgData];
			this.$msgbox({
				title: "出库单提示信息",
				message: h("div", null, showData),
				showCancelButton: false,
				confirmButtonText: "确定",
				closeOnClickModal: false,
				customClass: "orderBox",
				lockScroll: false,
				showClose: false,
				beforeClose: (action, instance, done) => {
					if (action === "confirm") {
						done();
					} else {
						done();
					}
					delete instance.$slots.default;
				}
			}).then(action => {
			});
		},
		lessStock(err) {
			const h = this.$createElement;
			let msg = JSON.parse(err.data.message);
			let msgData = [];
			if (Array.isArray(msg)) {
				msg.forEach(item => {
					msgData.push(
							h('p', null, [
								h(
										"span",
										{ style: "font-size: 12px;line-height: 20px;color: #666;margin-right: 15px;" },
										"商品名称：" +item.skuName
								),
								h(
										'span',
										{ style: "font-size: 12px;line-height: 20px;color: #666;" },
										"当前仓库库存" + item. stockEnableQuantity + item.unitName
								)
							])
					);
				});
			}
			let originData = [
				h("p",  {style: "margin-bottom: 10px;"}, "以下商品或赠品库存不足，请核查库存:")
			];
			let showData = [...originData, ...msgData];
			this.$msgbox({
				title: "出库提示信息",
				message: h("div", null, showData),
				showCancelButton: false,
				confirmButtonText: "确定",
				closeOnClickModal: false,
				customClass: "orderBox",
				lockScroll: false,
				showClose: false,
				beforeClose: (action, instance, done) => {
					if (action === "confirm") {
						done();
					} else {
						done();
					}
					delete instance.$slots.default;
				}
			}).then(action => {
			});
		},
		cancelAndBack() {
			if(this.$route.path.indexOf('/1') == -1) {
				this.$router.replace(`${basePath}/stockOut`)
			} else {
				this.$router.replace(`${basePath}/stockIn`)
			}
		},
	},
	mounted() {
		if (this.$route.path.indexOf('/1') != -1){
			this.putIn = true
		}
		const date = new Date();
		this.formInlineData.gmtCreate = date.setTime(date.getTime());
		this.queryclassifylistData();
		this.buyerFindSellerList()
		this.achieveEmplyee()
		this.getWareHouseList()
		this.getSellerLists()
	}
};
</script>


<style lang="less">
.text-center{
	text-align: center;
}
.stock-house-order-add {
	.less_pad {
		padding: 30px 30px 30px 20px;
		width: 100%;
		box-sizing: border-box;
	}
	.el-input--small .el-input__inner{
		height: 30px !important;
	}

	.input-block {
		.el-form-item {
			display: block;
		}
		.addr_block {
			.el-form-item {
				display: inline-block;
			}
		}
	}
	.table-head{
		display: flex;
		height: 56px;
		padding: 0 20px;
		align-items: center;
		justify-content: space-between;
		background-color: #f6f6f6;
		border-bottom: 1px solid #DDDDDD;
	}
	.goods-block {
		// 商品，赠品
		.item-content {
			border: 1px solid #DDDDDD;
			border-top: none;
			box-sizing: border-box;
			width: 100%;
			margin-bottom: 20px;
		}
		.item-title {
			background: #f6f6f6;
			padding: 20px 0;
			text-align: center;
		}
		.item-item {
			padding: 20px 10px 20px 20px;
			border-bottom: 1px dashed #DDD;
			box-sizing: border-box;
			&:nth-last-child(1) {
				border-bottom: 0;
			}
			&.item-title {
				border-bottom: 0;
			}
			& > div {
				display: flex;
				& > div {
					display: flex;
					align-items: center;
					justify-content: center;
				}
				.order-item-item {
					justify-content: flex-start;
					width: 290px;
					p {
						word-break: break-all;
					}
					.img {
						width: 80px;
						height: 80px;
					}
				}
				.order-item-item-1, .order-item-item-2,.order-item-item-4 {
					min-width: 56px;
                    flex: 1;
				}
				.order-item-item-3 {
					min-width: 80px;
                    flex: 1;
				}
				.order-item-item-5 {
                    flex: 1;
					min-width: 140px;
					.el-input-number__decrease,.el-input-number__increase {
						line-height:28px !important;
					}
				}
				.order-item-item-6, .order-item-item-7{
					    flex: 1;
                      .el-input--small {
                        width: 90px;
                      }
				}
				.order-item-item-8 {
                    flex: 1;
					min-width: 30px;
				}
			}
		}
	}
	.remark-block {
		label {
			font-size: 14px;
			color: #666;
		}
		.ipt-wrap {
			width: ~"calc(100% - 86px)";
			margin-left: 30px;
		}
	}
	.btm-block {
		color: #666;
		& > div {
			&:nth-child(1) {
				margin-top: 10px;
			}
		}
		div.clearfix {
			padding: 10px 0;
		}
	}
		.el-dialog {
			width: 950px !important;
		}
	#chooseGift .el-dialog {
		width: 950px !important;
		min-height: auto;
	}

	#chooseGift .table-default.el-table .cell {
		padding: 3px 15px 0;
	}

	#chooseGift .el-dialog__body {
		padding: 20px 30px;
	}

	#chooseGift .el-form .el-input {
		width: 120px;
	}

	#chooseGift .table-default .el-table__row {
		cursor: pointer;
	}

	#chooseGift .table-default .el-table__body tr.current-row > td, #chooseGift .table-default .el-table__body tr.current-row > td:hover {
		background-color: #edf7ff;
	}
	.el-dialog {
		width: auto;

	}
	.el-dialog__body {
		padding: 30px 20px;
	}
	.choose-form{
		.el-input{
			width: 147px;
		}
	}
	.el-form-item__label {
		font-size: 14px;
	}
	#chooseGift {
		.el-dialog__footer {
			position: static;
			transform: translateX(0);
			padding: 10px 20px 35px;
		}
	}

}
</style>

