<template>
  <div id="storeOrderDetail-store" class="bg-f">
    <div class="box-con bdt">
      <div class="order-info">
        <div class="order-info-tit pl-20">订单信息</div>
        <div class="order-info-con clearfix">
          <div class="order-info-conL fl">
            <div v-if="orderInfo.adjustingGoods == 1">
              <label>
                <el-badge class="my-badge" value="调货"></el-badge>
              </label>
              <span class="ml-10">{{orderInfo.headName}}</span>
            </div>
            <div>
              <label>订单编号：</label>
              <span class="ml-10">{{orderInfo.orderSn}}</span>
            </div>
            <div v-if="orderInfo.parentSn">
              <label>采购单号：</label>
              <span class="ml-10">{{orderInfo.parentSn}}</span>
              <span @click="orderDetail(orderInfo.parentSn)" class="ml-10 cursor color-blue">查看</span>
            </div>
            <div>
              <label>供货商名称：</label>
              <span class="ml-10">{{orderInfo.sellerName}}</span>
            </div>
            <div>
              <label>订单状态：</label>
              <span class="ml-10" v-if="orderInfo.orderStatus==0">已关闭</span>
              <span class="ml-10 color-danger" v-if="orderInfo.orderStatus==1">待付款</span>
              <span class="ml-10" v-if="orderInfo.orderStatus==2">待发货</span>
              <span class="ml-10" v-if="orderInfo.orderStatus==3">已发货</span>
              <span class="ml-10" v-if="orderInfo.orderStatus==4">已完成</span>
            </div>
            <div v-if="orderInfo.orderStatus==0" class="order-cancel-flex">
              <label>关闭原因：</label>
              <span class="ml-10">{{orderInfo.cancelReason}}</span>
            </div>
          </div>
          <div class="fr mt-30 mr-20">
            <el-button
              v-if="orderInfo.orderStatus == '2' && $route.query.tp == '1' && $route.query.type != '久加久酒水单'"
              @click="openCustomer('Form')"
            >发货</el-button>
            <span
              v-if="orderInfo.orderStatus==1 && orderInfo.remainAutoCancelTime && orderInfo.remainAutoCancelTime != 0"
            >
              <span class="mr-20">
                还剩
                <span class="color-danger">{{orderInfo.remainAutoCancelTime}}</span>自动取消
              </span>
              <el-button @click="handleCancelOrder()">取消订单</el-button>
            </span>
          </div>
        </div>
      </div>

      <el-dialog class="cancelOrderDialog" title="取消订单理由" :visible.sync="cancelOrderVisible">
        <el-form class="pl-30 pr-30" :model="form" ref="cancelOrderForm" :rules="rules2">
          <el-form-item label prop="cancleReason">
            <el-input
              type="textarea"
              placeholder="请输入取消订单的理由..."
              :autosize="{ minRows: 3 ,maxRows: 10}"
              v-model.trim="form.cancleReason"
            ></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="danger" @click="handleCommit(2)">确定</el-button>
          <el-button @click="cancelOrderVisible = false">取 消</el-button>
        </div>
      </el-dialog>

      <el-tabs class="mt-20" v-model="show" @tab-click="handleClick">
        <el-tab-pane label="订单详情" name="first"></el-tab-pane>
        <el-tab-pane label="出库/发货" name="second"></el-tab-pane>
        <!-- <el-tab-pane label="授信凭证" v-if="orderInfo.payMethod === '2'" name="voucher"></el-tab-pane> -->
      </el-tabs>

      <div v-if="show=='first'">
        <!--购买商品基本信息-->
        <div class="goods mt-20">
          <div class="goods-info clearfix" v-for="g in orderInfo.itemList">
            <div class="goods-infoL fl clearfix">
              <span class="fl">
                <img v-if="g.fileUrl" :src="g.fileUrl" alt />
                <img v-else src="../../../assets/goods.jpg" alt />
              </span>
              <span class="w380 ml-15 fl">
                <p>{{g.skuName}}</p>
                <span class="mt-10 color-9" v-if="g.specInfoNum">规格: 1*{{g.specInfoNum}}</span>
              </span>
              <span class="w210 fl tac">￥{{g.discountPrice||0 | fmoney}}</span>
              <span class="fl ml-15">{{g.num}}{{g.unit}}</span>
            </div>
            <span class="goods-infoR tac pt-20 fl">￥{{g.discountPrice*g.num | fmoney}}</span>
          </div>
          <div class="goods-money clearfix">
            <div class="goods-moneyR fr">
              <div>
                <label>
                  <span class="mr-20">共{{orderInfo.skuTotalNum}}件商品</span>商品金额：
                </label>
                <span class="ml-10">¥{{orderInfo.goodsTotalMoney||0 | fmoney}}</span>
              </div>
              <div>
                <label>运费：</label>
                <span class="ml-10">¥{{orderInfo.freightMoney||0 | fmoney}}</span>
                <span
                  class="ml-10"
                  v-if="orderInfo.freightDiscountsMoney"
                >(已减免运费{{orderInfo.freightDiscountsMoney}}元)</span>
                <div>
                  <label>实付金额：</label>
                  <span class="ml-10">¥{{orderInfo.needPayMoney||0 | fmoney}}</span>
                </div>
                <div class="font-20 color-danger" v-if="$route.query.type == '久加久酒水单'">
                  <label>销售员佣金：</label>
                  <span class="ml-10">￥{{orderInfo.commission | fmoney}}</span>
                </div>
                <!-- <div class="font-20 color-danger" v-if="$route.query.type == '久加久酒水单'">
                  <label>推广员佣金：</label>
                  <span class="ml-10">￥{{orderInfo.salesCommission | fmoney}}</span>
                </div> -->
              </div>
            </div>
          </div>
          <!--收货信息-->
          <div class="order-info mt-20">
            <div class="order-info-tit pl-20">收货信息</div>
            <div class="order-info-con">
              <div class="order-info-conL">
                <div>
                  <label>客户名称：</label>
                  <span class="ml-10">{{orderInfo.customerName}}</span>
                </div>
                <div>
                  <label>收货人：</label>
                  <span class="ml-10">{{orderInfo.addrUsername}}</span>
                </div>
                <div>
                  <label>联系电话：</label>
                  <span class="ml-10">{{orderInfo.addrTel}}</span>
                </div>
                <div>
                  <label>收货地址：</label>
                  <span class="ml-10">{{orderInfo.addrInfo}}</span>
                </div>
              </div>
            </div>
          </div>
          <!-- 支付信息 -->
          <div class="order-info mt-20">
            <div class="order-info-tit pl-20">支付信息</div>
            <div class="order-info-con">
              <div class="order-info-conL">
                <div class="clearfix">
                  <label class="fl">支付方式：</label>
                  <div class="fl" style="width: 75%">
                    <span
                      class="ml-10"
                      v-if="orderInfo.payMethodExp !=''"
                    >{{orderInfo.payMethodExp}}</span>
                    <span class="ml-10" v-else>无</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--备注信息-->
          <div class="order-info mt-20">
            <div class="order-info-tit pl-20">备注信息</div>
            <div class="order-info-con">
              <div class="order-info-conL">
                <div class="clearfix">
                  <label class="fl">备注信息：</label>
                  <div class="fl" style="width: 75%">
                    <span class="ml-10" v-if="orderInfo.remark">{{orderInfo.remark}}</span>
                    <span class="ml-10" v-else>无</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--发票信息-->
          <div class="order-info mt-20" v-if="orderInfo.invoiceInfoVO">
            <div class="order-info-tit pl-20">发票信息</div>
            <div class="order-info-con">
              <div class="order-info-conL">
                <div>
                  <label>发票信息：</label>
                  <span class="ml-10" v-if="orderInfo.invoiceInfoVO.personalCompanyType==null">无</span>
                  <span class="ml-10" v-if="orderInfo.invoiceInfoVO.personalCompanyType==2">个人</span>
                  <span class="ml-10" v-if="orderInfo.invoiceInfoVO.personalCompanyType==1">
                    <span>{{orderInfo.invoiceInfoVO.companyHead}}</span>
                    <span class="ml-5">{{orderInfo.invoiceInfoVO.taxpayerIdNo}}</span>
                  </span>
                </div>
                <div>
                  <label>发票地址：</label>
                  <span
                    class="ml-10"
                    v-if="orderInfo.invoiceInfoVO.personalCompanyType==null && orderInfo.invoiceInfoVO.provinceId==null"
                  >无</span>
                  <span class="ml-10" v-else>
                    <span>{{orderInfo.invoiceInfoVO.linkman}}</span>
                    <span class="ml-5">{{orderInfo.invoiceInfoVO.tel}}</span>
                    <span
                      class="ml-5"
                    >{{orderInfo.invoiceInfoVO.provinceName}}{{orderInfo.invoiceInfoVO.cityName}}{{orderInfo.invoiceInfoVO.regionName}}</span>
                    <span class="ml-5">{{orderInfo.invoiceInfoVO.addr}}</span>
                  </span>
                </div>
              </div>
            </div>
          </div>

          <!-- 佣金信息 -->
          <div class="order-info mt-20">
            <div class="order-info-tit pl-20">佣金信息</div>
            <div class="order-info-con">
              <div class="order-info-conL">
                <div>
                  <label>销售员：</label>
                  <span class="ml-10">{{orderInfo.storeName}}</span>
                  <span v-if="orderInfo.storeLoginName != null">/{{orderInfo.storeLoginName}}</span>
                  <span class="ml-10">佣金：</span>
                  <span class="ml-10">{{orderInfo.commission}}元</span>
                </div>
                <!-- <div>
                  <label>推广员：</label>
                  <span
                    class="ml-10"
                  >{{orderInfo.referrerStoreName != null?orderInfo.referrerStoreName:'无'}}</span>
                  <span
                    v-if="orderInfo.referrerStoreLoginName != null"
                  >/{{orderInfo.referrerStoreLoginName}}</span>
                  <span class="ml-10">佣金：</span>
                  <span class="ml-10">{{orderInfo.salesCommission}}元</span>
                </div> -->
              </div>
            </div>
          </div>

          <!--赠品信息-->
          <div
            class="order-info mt-20"
            v-if="orderInfo.noticeGiftAddrUsername && this.$route.query.tp === '2'"
          >
            <div class="order-info-tit pl-20">赠品信息</div>
            <div class="order-info-con">
              <div class="order-info-conL order-info-conL2">
                <div class="flex">
                  <label style="min-width: 150px;">赠品信息：</label>
                  <span class="ml-10">{{orderInfo.noticeGiftDescription}}</span>
                </div>
                <div class="flex">
                  <label>收货地址：</label>
                  <div class="ml-10">
                    <span>{{orderInfo.noticeGiftAddrUsername}}</span>
                    <span class="ml-5">{{orderInfo.noticeGiftAddrTel}}</span>
                    <span class="ml-5">{{orderInfo.noticeGiftAddrInfo}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="show=='second'">
        <div v-if="orderInfo.orderStatus==3 || orderInfo.orderStatus==4">
          <!--物流信息-->
          <div class="order-info mt-20" v-if="traceApiDtos.length >0">
            <div class="order-info-tit pl-20">物流信息</div>
            <div class="order-info-con" v-for="item in traceApiDtos">
              <div class="order-info-conL">
                <div class="clearfix pl-20 mb-10" style="width: 1000px;">
                  <div class="fl ml-50">
                    <span>物流公司:</span>
                    <span class="ml-5">{{item.expressComName}}</span>
                  </div>
                  <div class="fl ml-30">
                    <span>物流单号:</span>
                    <span class="ml-5">{{item.expressNo}}</span>
                  </div>
                  <div class="fl ml-30">
                    <span>发货时间:</span>
                    <span class="ml-5">{{orderInfo.gmtShipped | time}}</span>
                  </div>
                </div>
                <!--start-->
                <div v-if="item.expressData != null">
                  <span>订单流转</span>
                </div>
                <div
                  v-if="item.expressData != null"
                  class="order-info-con pl-20"
                  style="width: 1000px;"
                >
                  <div class="order-info-conS ml-50" v-if="item.expressData">
                    <div
                      class="clearfix"
                      v-for="(item1,index) in JSON.parse(item.expressData).reverse()"
                      :key="index"
                      :class="index == JSON.parse(item.expressData).length -1 ? 'color-red':''"
                    >
                      <span class="fl">{{item1.ftime | time}}</span>
                      <div class="fl ml-10" style="width: 80%;">
                        <span>{{item1.context}}</span>
                        <!--<span class="ml-10">{{item1.opeTitle}}</span>-->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="order-info mt-20" v-else-if="orderInfo.expressName != null">
            <div class="order-info mt-20">
              <div class="order-info-tit pl-20">物流信息</div>
              <div class="order-info-con">
                <div class="order-info-conL">
                  <div class="clearfix pl-20 mb-10" style="width: 1000px;">
                    <div class="fl ml-50">
                      <span>{{orderInfo.expressName != '商家配送'?'物流公司:':'发货方式:'}}</span>
                      <span class="ml-5">{{orderInfo.expressName}}</span>
                    </div>
                    <div class="fl ml-30" v-if="orderInfo.expressName != '商家配送'">
                      <span>物流单号:</span>
                      <span class="ml-5">{{orderInfo.expressNum}}</span>
                    </div>
                    <div class="fl ml-30">
                      <span>发货时间:</span>
                      <span class="ml-5">{{orderInfo.gmtShipped | time}}</span>
                    </div>
                    <div class="fl ml-30" v-if="orderInfo.gmtComplete">
                      <span>收货时间:</span>
                      <span class="ml-5">{{orderInfo.gmtComplete | time}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-else-if="traceApiDtos.length <= 0 && orderInfo.expressName == null"
          class="pt-30 pb-20 font-24 tac"
          style="width: 1000px;"
        >暂无记录</div>
      </div>
    </div>

    <hotel-divide-order
      :parentOrderSn="parentOrderSn"
      class="cancel-order"
      text="取消订单"
      :showIt="divideOrderVisible"
      @changeOrderDialogStatus="changeOrderDialogStatusFun"
      @handleClick="handleClickFun"
    ></hotel-divide-order>
    <el-dialog class="minMop" title="发货" :visible.sync="customer">
      <el-form class="pr-10" :rules="rules" ref="Form" :model="Form">
        <el-form-item label="请选择发货方式:" class="ml-20" prop="shipperType">
          <el-radio-group v-model="Form.shipperType" size="medium">
            <el-radio-button class="mr-20" :label="1">快递发货</el-radio-button>
            <el-radio-button :label="2">商家配送</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label="物流公司:"
          v-if="Form.shipperType == 1"
          class="ml-20"
          prop="expressName"
          required
        >
          <el-input
            class="ml-10 mr-10"
            v-model="Form.expressName"
            placeholder="请填写物流公司"
            :maxlength="40"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="物流单号:"
          v-if="Form.shipperType == 1"
          class="ml-20"
          prop="expressNum"
          required
        >
          <el-input
            class="ml-10 mr-10"
            v-model="Form.expressNum"
            placeholder="请填写物流单号"
            :maxlength="40"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="danger" @click="addCustomer('Form')">确定</el-button>
        <el-button class="ml-20" @click="customer = false">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import orderViewCredentials from "./orderViewCredentialsComponent.vue";
import hotelDivideOrder from "../../../components/jiuji/hotelDivideOrder.vue";
import { axiosApi, basePath } from "../../../api/api";
function filterInput(val) {
  return val.replace(/[\ud800-\udbff][\udc00-\udfff]/g, "");
}
function filterNumber(val) {
  return val.replace(/[^\w\.\/]/ig, "");
}
export default {
  components: {
    "order-viewredentials": orderViewCredentials,
    hotelDivideOrder: hotelDivideOrder
  },
  data() {
    return {
      datas: [1, 2, 3],
      traceApiDtos: [],
      /*规则*/
      rules2: {
        cancleReason: [
          {
            required: true,
            message: "取消订单理由不能为空"
          },
          {
            min: 4,
            max: 100,
            message: "取消订单理由为4-100位字符"
          }
        ]
      },
      show: "first",
      orderInfo: {}, //订单信息
      cancelOrderVisible: false,
      isDeliver: false,
      sendGoods: false,
      expressList: [], //物流列表
      form: {
        cancleReason: "", //取消订单原因
        companyName: "", //物流公司
        expressNum: "" //物流编号
      },
      customer: false, //发货弹窗
      Form: {
        orderSn: this.$route.query.sn,
        shipperType: 2,
        // expressName: null,
        // expressNum: null
      },
      rules: {
        expressName: [
          {
            required: true,
            message: "物流公司名称为必填项"
          },
          { min: 1, max: 20, message: "长度在 1 到 20 个字符", trigger: "blur" }
        ],
        expressNum: [
          {
            required: true,
            message: "物流单号为必填项"
          },
          { min: 1, max: 20, message: "长度在 1 到 20 个字符", trigger: "blur" },
        ]
      },
      voucherTableList: [], // 授信凭证 list data
      imgviewVisible: false, // 查看凭证 浮层开关
      voucherImgList: [], // 查看凭证 图片list
      bankInfo: [],
      total: 0,
      currentPage: 1,
      getSellerId: sessionStorage.getItem("traderId"),
      parentOrderSn: this.$route.query.id,
      divideOrderVisible: false
    };
  },
  watch: {
    "Form.expressName": {
      handler: function(val) {
        this.$nextTick(() => {
          this.Form.expressName = filterInput(val);
        });
      }
    },
    "Form.expressNum": {
      handler: function(val) {
        this.$nextTick(() => {
          this.Form.expressNum = filterNumber(val);
        });
      }
    }
  },
  mounted() {
    this.initVoucherTable();
    this.$nextTick(() => {
      this.getOrderInfo();
      if (sessionStorage.show) {
        this.show = sessionStorage.show;
      }
    });
  },
  methods: {
    deliver() {
      let obj = JSON.parse(JSON.stringify(this.Form));
      axiosApi(
        "/superc/tenantShopOrder/deliverGoods",
        obj,
        res => {
          this.getOrderInfo();
          this.isDeliver = false;
          this.$message({
            type: "success",
            message: "成功发货",
            duration: 3000
          });
          // this.customer = false;
        },
        1,
        10,
        res => {
          // this.customer = false;
          this.isDeliver = false;
          this.$message({
            type: "info",
            message: res.data.message,
            duration: 3000
          });
        }
      );
    },
    addCustomer(form) {
      if (this.Form.shipperType == 1) {
        this.$refs[form].validate(valid => {
          if (valid) {
            if (this.isDeliver) {
              this.isDeliver = false;
              this.deliver();
            }
          } else {
          }
        });
      } else if (this.Form.shipperType == 2) {
        this.$msgbox({
          title: "提示信息",
          message: "确定商家配送？",
          showCancelButton: true,
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          beforeClose: (action, instance, done) => {
            if (action === "confirm") {
              this.deliver();
              done();
            } else {
              done();
            }
          }
        });
      }
    },
    openCustomer(form) {
      // this.isDeliver = true;
      // this.customer = true;
      // if (this.$refs[form] !== undefined) {
      //   this.$refs[form].resetFields();
      // }
      this.$msgbox({
        title: "提示信息",
        message: "是否确定已发货？",
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            this.deliver();
            done();
          } else {
            done();
          }
        }
      });
    },
    changeOrderDialogStatusFun(val) {
      this.divideOrderVisible = val;
    },
    handleClickFun(val) {
      this.divideOrderVisible = false;
      this.cancelOrderVisible = true;
    },
    handlePageChange(val) {
      this.currentPage = val;
      this.initVoucherTable();
    },
    // 获取凭证 列表
    initVoucherTable() {
      let body = null;
      if (this.$route.query.id) {
        body = { orderSn: this.$route.query.id };
      } else {
        body = { orderSn: this.$route.query.sn };
      }
      axiosApi(
        "/superc/credit/order/backReason",
        body,
        res => {
          let data = res.data.data;
          if (data) {
            this.tableList = data.elements;
            this.total = data.totalCount;
            if (data.elements && data.elements.length > 0) {
              this.voucherTableList = data.elements.map((v, k) => {
                v.bankInfo = {
                  bankName: v.bankName,
                  bankUserName: v.bankUserName,
                  bankCard: v.bankCard
                };
                return v;
              });
            }
          }
        },
        this.currentPage,
        10
      );
    },
    // 查看凭证
    handleVoucherSee(row) {
      row.imgs = row.imgs ? row.imgs : [];
      if (row.imgs.length === 0) {
        this.$message.warning("暂未上传凭证");
        return;
      }
      this.voucherImgList = row.imgs;
      this.bankInfo = row.bankInfo;
      this.$refs.viewredentials.show();
    },
    // 查看 驳回理由
    handleVoucherReject(row) {
      let div = (
        <div>
          <p>驳回理由：</p>
          <p
            style={{
              marginTop: "5px",
              wordWrap: "break-word",
              wordBreak: "normal",
              paddingBottom: "60px"
            }}
          >
            {row.reason}
          </p>
        </div>
      );
      this.$msgbox({
        title: "查看",
        message: div,
        showCancelButton: false,
        confirmButtonText: "确定",
        beforeClose: (action, instance, done) => {
          done();
        }
      }).then(action => {});
    },
    handleClick() {
      console.log(this.show);
      //切换内容
    },
    getOrderInfo() {
      //获取订单详细信息
      axiosApi(
        "/superc/tenantShopOrder/queryOrderDetail",
        {
          orderSn: this.$route.query.sn
        },
        res => {
          this.orderInfo = res.data.data;
          this.traceApiDtos = res.data.data.expressOrderListenList
            ? res.data.data.expressOrderListenList
            : [];
          if (
            this.orderInfo.remainAutoCancelTime != null &&
            this.orderInfo.remainAutoCancelTime > 0
          ) {
            let time = parseInt(this.orderInfo.remainAutoCancelTime / 1000); //转化为整数
            this.orderInfo.remainAutoCancelTime = this.timeString(time); //转化为时间格式
            let clearTime = null; //定义变量
            clearTime = setInterval(() => {
              time--;
              this.orderInfo.remainAutoCancelTime = this.timeString(time);
              if (time <= 0) {
                clearTimeout(clearTime);
                //								if(this.orderInfo.orderStatus == 1) this.orderInfo.orderStatus = 0
              }
            }, 1000);
          } else {
            //						if(this.orderInfo.orderStatus == 1) this.orderInfo.orderStatus = 0
          }
        },
        1,
        10,
        res => {
          this.$message({
            type: "error",
            message: res.data.message,
            duration: 1000
          });
        }
      );
    },
    handleCancelOrder() {
      //取消订单按钮
      if (this.$route.query.id) {
        //有拆单情况
        this.divideOrderVisible = true;
      } else {
        this.cancelOrderVisible = true;
      }
    },
    handleCommit(state) {
      //确定
      this.$refs.cancelOrderForm.validate(valid => {
        if (valid) {
          this.cancelOrderVisible = false;
          axiosApi(
            "/superc/order/webCancel",
            {
              orderSn: this.$route.query.id
                ? this.$route.query.id
                : this.$route.query.parentOrderSn,
              reason: this.form.cancleReason,
              cancleType: 2
            },
            res => {
              this.getOrderInfo();
              this.$message({
                type: "success",
                message: "取消成功",
                duration: 3000
              });
            },
            1,
            10,
            res => {
              this.$message({
                type: "error",
                message: res.data.message,
                duration: 1000
              });
            }
          );
        } else {
          return false;
        }
      });
    },
    orderDetail(orderSn) {
      //去订单详情页面
      let type = "jiuji";
      sessionStorage.setItem("orderSn", orderSn);
      window.open(
        basePath +
          "/ccPurchaseOrderDetail?type=" +
          type +
          "&showSalesCommission=1",
        "_blank"
      );
    }
  }
};
</script>

<style lang="less">
#storeOrderDetail-store {

  .el-radio-button__inner {
    border-left: 1px solid #bfcbd9 !important;
  }
  .minMop {
    .el-form-item__label {
      width: 130px;
    }
    .el-dialog__body {
      padding: 30px 0 30px !important;
    }
    .el-dialog {
      width: 500px !important;
      min-height: 380px !important;
    }
    .el-form-item__error {
      left: 150px;
    }
  }
  .bdt {
    border-top: 1px solid #eee;
  }
  .el-tabs__header {
    padding: 0;
    border-bottom: 1px solid #eee;
  }
  .box-con {
    padding: 20px;
    .order-info {
      border: 1px solid #ddd;
      box-sizing: border-box;
      .order-info-tit {
        height: 40px;
        line-height: 40px;
        background-color: #eee;
      }
      .order-info-con {
        .order-info-conL {
          width: 700px;
          line-height: 30px;
          padding: 22.5px 0;
          label {
            width: 150px;
            display: inline-block;
            text-align: right;
          }
          &.order-info-conL2 {
            width: 90%;
          }
        }
      }
    }
    .goods {
      border: 1px solid #ddd;
      box-sizing: border-box;
      .goods-info {
        height: 120px;
        border-bottom: 1px solid #ddd;
        span {
          display: inline-block;
        }
        .goods-infoL {
          width: 80%;
          height: 100%;
          padding: 20px;
          box-sizing: border-box;
          border-right: 1px solid #ddd;
          img {
            width: 80px;
            height: 80px;
            display: inline-block;
          }
          .w380 {
            width: 380px;
          }
          .w210 {
            width: 210px;
          }
        }
        .goods-infoR {
          width: 20%;
            padding-left: 20px;
            box-sizing: border-box;
          display: inline-block;
        }
      }
      .goods-money {
        line-height: 25px;
        padding: 30px 50px;
        box-sizing: border-box;
        label {
          width: 300px;
          display: inline-block;
          text-align: right;
        }
      }
    }
    .el-dialog {
      min-height: 300px;
      .el-form-item__label {
        width: 140px;
      }
    }
    .cancel-order {
      .el-dialog {
        min-height: 500px;
      }
    }
    .sendGoods .el-form-item__error {
      margin-left: 140px;
    }
  }
  .color-red {
    color: red;
  }
  .my-badge {
    padding-top: 5px;
  }
}
</style>
