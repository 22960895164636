import {axiosApi,basePath} from '../../api/api.js'
import {Notification,Message } from 'element-ui'
import m from '../../main.js'

const marketActivity = {
    state:{
        giftList:[],
        willAddActivityList:[
            { fullMoney:'', minusAmount:'',fullAmount:''}//fullMoney  满减门槛   minusAmount  满减力度
        ],//满减规则列表
        willAddActivitySendList:[
            {
                fullMoney:'',
                fullAmount:'', //  满赠门槛
                giftList:[],//赠品列表
                willAddGift:[],//即将添加的赠品列表
                willDeleteGift:[],//即将删除的赠品列表
            }
        ],//赠品列表
    },

    mutations:{
        //添加满额减规则
        addActivityList(state,payload){
            if(state.willAddActivityList.length<3){
                state.willAddActivityList.push({
                    fullMoney:'',
                    minusAmount:'',
                    fullAmount:''
                })
            }else{
                Message.warning('最多添加三级优惠');
            }
        },
        //删除满额减规则
        delActivity(state,payload){
            state.willAddActivityList.splice(payload.index,1)
        },
        //重置满额减规则
        resetWillAddActivityList(state,payload){
            state.willAddActivityList = [{
                fullMoney:'',
                minusAmount:'',
                fullAmount:''
            }]
        },
        //添加满赠规则
        addActivitySendList(state,payload){
            if(state.willAddActivitySendList.length<3){
                // let arr = [];
                // state.getList.map(v => {
                //     arr.push(v)
                // })
                state.willAddActivitySendList.push({
                    fullAmount:'',
                    fullMoney:'',
                    giftList:JSON.parse(JSON.stringify(state.giftList)),
                    willAddGift:[],
                    willDeleteGift:[],
                })
            }else{
                Message.warning('最多添加三级优惠');
            }
        },
        //删除满赠规则
        delActivitySend(state,payload){
            state.willAddActivitySendList.splice(payload,1)
        },
        //重置满赠规则
        resetWillAddActivitySendList(state,payload){
            state.willAddActivitySendList = [{
                fullAmount:'',
                fullMoney:'',
                giftList:JSON.parse(JSON.stringify(state.giftList)),
                willAddGift:[],
                willDeleteGift:[],
            }]
        },
        //添加赠品
        addGift(state,payload){
            console.log(payload,'pay;load')
            // state.willAddGift.push(...payload.willAdd);
            // payload.willAdd.map(v => {
            //     state.willAddActivitySendList[payload.currentGiftIndex].willAddGift.push(v)
            // })
            state.willAddActivitySendList[payload.currentGiftIndex].willAddGift.push(...payload.willAdd)
        },
        //删除赠品 级
        delGift(state,payload){
            state.willAddActivitySendList.splice(payload.index,1)
        },
        //删除赠品 单个
        removeGift(state,payload){
            state.willAddActivitySendList[payload.index].willAddGift.splice(payload.indexSecond,1)
        },
        //按条件查询赠品
        queryGift(state,payload){
            let arr = [];
            // state.willAddActivitySendList[payload.currentGiftIndex].giftList.map(v => {
            //     let flag = false;
            //     state.willAddActivitySendList[payload.currentGiftIndex].willAddGift.map(x => {
            //         if(v.id == x.id){
            //             flag = true;
            //         }
            //     })
            //     if(!flag){
            //         arr.push(v)
            //     }
            // })
            // // console.log(arr);
            // // console.log(payload)
            // state.willAddActivitySendList[payload.currentGiftIndex].giftList = arr.map(v => {
            //     if(v.classifyId != payload.classifyId){
            //         return v;
            //     }
            // })
        }
    },

    actions:{
        //异步操作 获取赠品列表
        getGiftList({state},payload){
            let api = '/groupon/gift/list';
            let body = {
                "skuName": payload.skuName,
                "giftType": payload.giftType || null
            };
            axiosApi(api,body,res => {
                console.log(res,'giftlist');
                state.giftList = JSON.parse(JSON.stringify(res.data.data.elements));
                state.willAddActivitySendList[0].giftList = JSON.parse(JSON.stringify(res.data.data.elements));
            })
        }
    },

    getters:{
        giftList:(state) => (index) => {
            let arr = [];
            state.willAddActivitySendList[index].giftList.map(v => {
                let flag = false;
                state.willAddActivitySendList[index].willAddGift.map(x => {
                    if(v.id == x.id){
                        flag = true;
                    }
                })
                if(!flag){
                    arr.push(v)
                }
            })
            return arr
        }
    }
};

export default marketActivity;