<template>
  <div class="hotel-edit-box" style="background: white">
    <div class="m-content">
      <!-- <div class="m-title">折扣维护-编辑</div> -->
      <div class="m-detail">
        <!-- <div class="m-item">
          <div class="m-item-label">客户名称：</div>
          <div class="m-item-content">{{ customerName }}</div>
        </div>
        <div class="m-item">
          <div class="m-item-label">状态：</div>
          <div class="m-item-content red">{{ state }}</div>
        </div> -->
        <div class="m-item clearfix">
          <el-form
            :inline="true"
            class="fl search"
            :model="searchForm"
          >
            <el-form-item label="商品编码">
              <el-input v-model.trim="searchForm.goodsNo"></el-input>
            </el-form-item>
            <el-form-item label="商品名称" class="mr-10">
              <el-input v-model.trim="searchForm.skuName"></el-input>
            </el-form-item>
            <el-form-item label="商品分类">
              <el-select
                v-model="searchForm.classifyId"
                placeholder="请选择"
                clearable
              >
                <el-option
                  v-for="(item, i) in classifylist"
                  :key="i"
                  :label="item.classifyName"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <el-button class="fl ml-30" type="info" @click="handleQueryBtn"
            >查 询</el-button
          >
        </div>
        <div style="text-align: right" class="button_list">
					
          <!-- <el-button type="text" @click="handleAddGoods" v-if="!flag"
            >添加套餐商品</el-button
          > -->
          <!-- <el-button type="text" @click="exportData" v-if="!flag"
            >导出数据</el-button
          > -->
        </div>
      </div>
      <div class="m-goods-table">
        <!-- <el-form :model="ruleForm" ref="ruleForm"> -->
        <el-table
          v-loading="loading"
          border
          :data="ruleForm.tableListGoods"
          tooltip-effect="dark"
          class="mt-20 table-default"
        >
          <template slot="empty">
            <img class="mt-20" src="../../assets/empty.png" />
            <p>您的商品列表空空如也，现在添加您第一件商品吧！</p>
            <!-- <div class="mt-10">
              <el-button
                style="width: 90px; height: 32px"
                type="primary"
                @click="handleAddGoods"
                v-if="!flag"
              >
                添加套餐商品
              </el-button>
            </div> -->
          </template>
          <el-table-column label="商品名称" min-width="250">
            <template slot-scope="scope">
              <div class="m-goods-table-goods-name">
                <div class="m-left">
                  <img v-if="scope.row.fileUrl" :src="scope.row.fileUrl" />
                  <img v-else src="../../assets/goods.jpg" />
                </div>
                <div class="m-right">
                  <p style="margin-bottom: 15px" class="mt-10">
                    商品编码：{{ scope.row.goodsNo ? scope.row.goodsNo : "无" }}
                  </p>
                  <p class="ellipsis-2">{{ scope.row.skuName }}</p>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="规格" min-width="60">
            <template slot-scope="scope">
              <span
                >1 *
                {{
                  scope.row.skuType === 2 ? "1" : scope.row.specInfoNum
                }}</span
              >
            </template>
          </el-table-column>
          <el-table-column
            label="最低起订量"
            min-width="160"
            :render-header="renderHeader"
            align="center"
          >
            <template slot-scope="scope">
              <div class="moq_show">
                <span
                  >{{ scope.row.minimunMoq || "--"
                  }}{{
                    scope.row.skuType === 2 ? "套" : scope.row.unitsName
                  }}</span
                >
              </div>
            </template>
          </el-table-column>
          <el-table-column label="商品分类" min-width="80">
            <template slot-scope="scope">
              <span>{{
                scope.row.classifyName ? scope.row.classifyName : "其他"
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="会员价(元)"
            :render-header="renderHeader"
            min-width="120"
          >
            <template slot-scope="{row}">
              <div class="tac">
                <div v-if="row.skuType !== 2">
									<div v-if="row.unitPrice||row.integerPrice">
										<div>
											<span v-if="row.unitPrice">{{ row.unitPrice }} /{{row.unitsName}}</span>
										</div>
										<div>
											<span v-if="row.integerPrice">{{ row.integerPrice }} /{{row.packageUnitName}}</span>
										</div>
									</div>
									<span v-else>--</span>
                </div>
								<div v-else>
									<span v-if="row.unitPrice">{{ row.unitPrice }} /套</span>
									<span v-else>--</span>
								</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="折扣/返利"
            :render-header="renderHeader"
            min-wdith="120"
          >
            <template slot-scope="{row}">
              <div>
                <div class="tac" v-if="row.skuType === 2"> -- </div>
                <div v-else>
                  <div v-if="row.discountType === 1">
                    <div>折扣优惠:</div>
                    <div>{{ row.unitDiscountValue }}</div>
                  </div>
                  <div v-else-if="row.integerDiscountValue||row.unitDiscountValue">
                    <div>固定返利:</div>
                    {{ row.integerDiscountValue
                    }}{{ "/" + row.packageUnitName }}
                    <br />
                    {{ row.unitDiscountValue
                    }}{{ "/" + row.unitsName }}
                  </div>
									<div v-else>--</div>
                </div>
              </div>
            </template>
          </el-table-column>
          <!-- <el-table-column
            prop="loginName"
            label="瓶盖费(元)"
            :render-header="renderHeader"
            min-width="100"
          >
            <template slot-scope="scope">
              <div class="tac">{{ scope.row.tableForm.capFee }}</div>
            </template>
          </el-table-column> -->
          <el-table-column
            prop="loginName"
            label="酒店促销费(元)"
            min-width="120"
          >
            <template slot-scope="{row}">
              <div class="tac">
                <div v-if="row.unitPromotionDiscountsPrice">
                  {{ row.unitPromotionDiscountsPrice | fmoney}}{{ "/" + row.unitsName }}
                </div>
                <div v-if="row.integerPromotionDiscountPrice">
                  {{ row.integerPromotionDiscountPrice | fmoney }}/{{ row.packageUnitName}}
                </div>
                <div v-if="row.skuType === 2 || !row.unitPromotionDiscountsPrice || !row.integerPromotionDiscountPrice">--</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="loginName"
            label="销售员促销费(元)"
            min-width="120"
          >
            <template slot-scope="{row}">
              <div class="tac">
                <div v-if="row.unitPromotionCommission">
                  {{row.unitPromotionCommission | fmoney }}/{{row.unitsName}}
                </div>
                <div v-if="row.integerPromotionCommission">
                  {{ row.integerPromotionCommission | fmoney }}/{{row.packageUnitName}}
                </div>
                <div v-if="row.skuType === 2 || !row.integerPromotionCommission || !row.unitPromotionCommission">--</div>
              </div>
            </template>
          </el-table-column>
					<el-table-column
            label="是否可见"
            show-overflow-tooltip
            min-width="100"
          >
            <template slot-scope="scope">
              <div class="tac">
                <p>{{ scope.row.isVisible == 1 ? "可见" : "不可见" }}</p>
              </div>
            </template>
          </el-table-column> 
					<!-- 3.5.3中移除 -->
          <!-- <el-table-column
            label="是否停售"
            show-overflow-tooltip
            min-width="100"
          >
            <template slot-scope="scope">
              <div class="tac">
                <p>{{ scope.row.skuState == 1 ? "停售" : "出售" }}</p>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="是否可见"
            show-overflow-tooltip
            min-width="100"
          >
            <template slot-scope="scope">
              <div class="tac">
                <p>{{ scope.row.isVisible == 1 ? "可见" : "不可见" }}</p>
              </div>
            </template>
          </el-table-column> -->
          <el-table-column label="操作" min-width="100">
            <template slot-scope="scope">
              <el-button
                type="text"
                v-if="scope.row.skuType === 2 && !flag"
                @click="handleEdit(scope.row, scope.$index)"
                class="color-blur"
                >设价</el-button
              >
              <el-button
                type="text"
                v-if="scope.row.skuType === 2 && !flag"
                @click="handleDelete(scope.row, scope.$index)"
                class="color-blur"
                >重置价格</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <!-- 商品个数备注 -->
        <!-- <div class="mt-20 color-9">
          备注: 常规商品{{ generalGoodsnum }}个，套餐商品{{ packageGoodsnum }}个
        </div> -->
        <el-pagination
          class="tar"
          v-if="total > 0"
          :current-page="currentPage"
          :page-size="10"
          :total="total"
          layout="prev, pager, next, jumper"
          @current-change="handlePageChange"
        >
        </el-pagination>
        <!-- </el-form> -->
      </div>
    </div>
    <!-- 添加商品组件 -->
    <!-- <goods
      :hotelAgreementId="id"
      @closedialog="handleClose"
      :goodVisible="dialog1Visible"
      :ifnotauto="true"
      :ok="true"
      @handleOk="handleOk"
      ref="chooseGoods"
      :isOpenPricesync="true"
    >
    </goods> -->
    <el-dialog
      :visible.sync="dialogVisiable"
      class="mdialog"
      :before-close="handleDialogClose"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      title="设价"
      width="830px"
    >
      <el-form ref="setPriceForm" class="set_price" :model="form">
        <el-table :data="form.goodsList">
          <el-table-column
            label="商品编码"
            align="center"
            prop="skuNo"
          ></el-table-column>
          <el-table-column
            label="商品名称"
            align="center"
            prop="skuName"
          ></el-table-column>
          <el-table-column
            label="数量"
            align="center"
            prop="num"
          ></el-table-column>
          <el-table-column
            label="价格(元)"
            align="center"
            :render-header="renderHeader"
            class="price"
          >
            <template slot-scope="scope">
              <el-form-item
                :key="scope.row.id"
                :rules="[
                  { required: true, message: '请输入价格' },
                  {
                    pattern: /^\d{1,5}(\.\d{1,2})?$/,
                    message: '价格为1-5位数字，小数支持2位',
                  },
                ]"
                :prop="'goodsList[' + scope.$index + '].discountPrice'"
              >
                <el-input
                  class="wp100"
                  v-model.trim="scope.row.discountPrice"
                  @change="changePrice(scope.row)"
                ></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column
            label="单位"
            align="center"
            prop="unitName"
          ></el-table-column>
          <el-table-column label="规格" align="center">
            <template slot-scope="scope">
              1*{{ scope.row.specInfoNum }}
            </template>
          </el-table-column>
        </el-table>

        <p class="mt-15 tar pr-30">
          套餐价格：<span class="font-16 color-danger">{{
            totalPrice | fmoney
          }}</span>
          /套
        </p>
        <div class="mt-20 clearfix">
          <div class="fl lh-32 mr-15">备注:</div>
          <div class="fl">
            <el-input class="w480" v-model="form.remark"></el-input>
          </div>
        </div>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" :loading="btnLoading" @click="saveMeal"
          >确 定</el-button
        >
        <el-button @click="handleDialogClose">取 消</el-button>
      </div>
    </el-dialog>
    <!-- form 导出数据 -->
    <form
      ref="form"
      action="/sunrise-gateway/export/hotel/agreementGoods/list"
      style="display: none"
    >
      <input type="hidden" name="agreementId" v-model="a" />
      <input type="hidden" name="modelName" v-model="b" />
      <input type="hidden" name="fileName" v-model="c" />
    </form>
  </div>
</template>

<script>
import { mapMutations, mapActions } from "vuex";
import { axiosApi, basePath } from "../../api/api";
import affix from "./affix.vue";
import goods from "./component-goods.vue";
// import syncPackageGoods from "./syncPackageGoods.vue";

export default {
  components: {
    affix,
    goods,
    // syncPackageGoods,
  },
  data() {
    let _this = this;
    return {
      flag: false,
      id: null,
      a: this.$route.query.id || "",
      b: "hotel.agreement.goods",
      c: "酒店商品数据.xls",
      customerName: "大白兔酒店", // 客户名称
      timeStart: "", // 开始时间
      timeEnd: "", // 结束时间
      type: "", // 类型 1 编辑 2 调整日期-编辑
      state: "待编辑", // 状态
      dialog1Visible: false, // 添加商品浮层
      // 表格中的 表单数据
      ruleForm: {
        tableListGoods: [],
      },
      loading: true,
      dialogVisiable: false, // 套餐设价弹框
      btnLoading: false, // 按钮loading
      form: {
        goodsList: [],
        remark: "", // 备注
        // msrp: '', // 建议零售价
        minimunMoq: "", // 最低起订量
      }, // 套餐form
      totalPrice: "",
      searchForm: {
        classifyId: "",
        skuName: "",
        goodsNo: "",
        buyerId: this.$route.query.buyerId,
      },
      moqVisible: false, // 最低起订量弹出框显隐变量
      beforeRow: {}, // 修改最低起订量之前的数据，用于保存原始数据
      currentPage: 1, // 当前页
      total: 0, // 商品数据总条数
      minimunMoqForm: {
        unit: "箱(规格单位)", // 瓶、箱
        num: 1, // 最低起订量
      },
      rules: {
        num: [
          {
            required: true,
            message: "请输入最低起订量",
          },
          {
            pattern: /^[1-9]\d*$/,
            message: "起订量是大于0的正整数",
          },
        ],
      },
      mealRow: {},
      mealIndex: "",
      generalGoodsnum: 0, // 普通商品数量
      packageGoodsnum: 0, // 套餐商品数量
      sellerId: "", // 卖家id
      buyerId: "", // 买家id
    };
  },
  computed: {
    // 添加商品的数据
    goodsData() {
      return this.$store.state.ticket.willadd;
    },
    classifylist() {
      // 商品分类
      return this.$store.state.classifylist.classifylistData
        ? this.$store.state.classifylist.classifylistData
        : [];
    },
  },
  watch: {
    "form.goodsList": function () {
      let goodsList = this.form.goodsList;
      let total = 0;
      goodsList.forEach((i) => {
        if (i.discountPrice) {
          total += i.discountPrice * i.num;
        } else {
          total = 0;
        }
      });
      this.totalPrice = total ? total : 0;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.flag = this.$route.query.flag && this.$route.query.flag == 1;
      this.id = this.$route.query.id;
      this.getData();
      this.classSearch()
    });
  },
  methods: {
    ...mapActions({
      querygoodtableData: "querygoodtableDataMeal", // ticket.js
      setUpQuantity: "setUpQuantity", //统一设置起订量 hotelEdit.js
      // hotelselleragreement: "hotelselleragreement", // 折扣商品列表(单个客户) hotelEdit
      mealGoodUpdate: "mealGoodUpdate", // 编辑套餐后保存
      classSearch: 'queryclassifylistData',
    }),
    /* ...mapMutations({
      openSyncPackageGoodDialog: "openSyncPackageGoodDialog", // 套餐商品同步
    }), */
    getData() {
      axiosApi(
        "/goods/sku/customerPrice/list",
        this.searchForm,
        (res) => {
          this.loading = false;
          let data = res.data.data.elements; 
          this.total = res.data.data.totalCount;
          if (data) {
            this.ruleForm.tableListGoods = data;
          }
        },
        this.currentPage,
        10,
        () => {
          this.loading = false;
        }
      );
    },
  
  
    // exportData() {
    //   // 导出数据
    //   if (this.total > 0) {
    //     this.$refs.form.submit();
    //   } else {
    //     this.$message.warning("没有商品数据");
    //   }
    // },
  
    handlePageChange(val) {
      // 更改分页
      this.currentPage = val;
      this.getData();
    },
    handleQueryBtn(page) {
      // 查询商品
      this.currentPage = 1;
      this.getData();
    },
    /* gethotelList() {
      // 获取酒店折扣数据
      this.loading = true;
      this.hotelselleragreement({
        body: this.searchForm,
        currentPage: this.currentPage,
      })
        .then((res) => {
          this.loading = false;
          let data = res.data.data.elements; // 折扣商品列表(单个客户)数据
          this.total = res.data.data.totalCount; // 折扣商品列表(单个客户)总条数
          if (data) {
            this.ruleForm.tableListGoods = this.changeGoodsList(data);
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    }, */
    // 增加表格中的 表单数据
    /* setTableForm(arr) {
      return arr.map((v, k) => {
        if (v.skuType === 2) {
          // 套餐商品
          v.moqStatus = false; // 判断起订量是否能修改状态
          v.promotionPrice = v.promotionPrice; // 编辑显示
          v.fileUrl = v.img;
          v.id = v.id;
          v.skuId = v.skuId;
          v.unitsName = v.unitsName;
          v.skuState = v.skuState;
          v.tableForm = {
            promotion: v.promotionPrice, // 套餐的会员价
            discountType: 2, // 折扣类型
            capFee: "0", // 瓶盖费
            remarks: v.remark, // 备注
            fixed: v.discountValue, // 固定返利
            discount: "", // 折扣返利
            salesPrice: "--", //促销费用
            salesPersonPrice: "--",
          };
          if (v.skuSetInfoVOList)
            v.jsonSetInfo = JSON.stringify(v.skuSetInfoVOList);
          return v;
        } else {
          let a = {
            moqStatus: false, // 判断起订量是否能修改状态
            id: v.skuId,
            mainId: v.id,
            skuNo: v.skuNo,
            fileUrl: v.img,
            unitsName: v.unitsName,
            skuName: v.skuName,
            specInfoNum: v.specInfoNum,
            isVisible: v.isVisible,
            sellingUnitName: v.sellingUnitName,
            skuType: v.skuType,
            minimunMoq: v.minimunMoq, // 普通商品最低起订量
            tableForm: {
              promotion: v.promotionPrice,
              unitPromotionPrice: v.unitPromotionPrice,
              discountType: v.discountType, // 折扣类型
              discount: v.discountType === 1 ? v.discountValue + "" : "", // 折扣返利
              unitDiscountValue:
                v.discountType === 2 ? v.unitDiscountValue : "", // 固定返利
              discountValue: v.discountValue,
              capFee: v.capFee + "", // 瓶盖费
              remarks: v.remark, // 备注
              promotionDiscountsPrice: v.promotionDiscountsPrice, // 酒店促销费用
              unitPromotionDiscountsPrice: v.unitPromotionDiscountsPrice,
              promotionCommission: v.promotionCommission, // 销售员促销费
              unitPromotionCommission: v.unitPromotionCommission,
            },
            classifyName: v.classifyName,
            goodsNo: v.goodsNo,
            skuState: v.skuState,
            remark: v.remark,
          };
          return a;
        }
      });
    }, */
    // 转化数据格式
    /* changeGoodsList(payload) {
			return payload
      const arr = payload.map((v) => {
        if (v.skuType === 2) {
          // 套餐商品
          v.moqStatus = false; // 判断起订量是否能修改状态
          //v.promotionPrice = v.promotionPrice// 编辑显示
          v.fileUrl = v.img;
          v.id = v.id;
          v.skuId = v.skuId;
          v.unitsName = v.unitsName;
          v.tableForm = {
            promotion: v.promotionPrice, // 套餐的会员价
            discountType: 2, // 折扣类型
            capFee: "0", // 瓶盖费
            remarks: v.remark, // 备注
            fixed: v.discountValue, // 固定返利
            discount: "", // 折扣返利
            salesPrice: "--", //促销费用
            salesPersonPrice: "--",
          };
          return v;
        } else {
          let obj = {
            moqStatus: false, // 判断起订量是否能修改状态
            id: v.skuId,
            mainId: v.id,
            skuNo: v.skuNo,
            fileUrl: v.img,
            skuName: v.skuName,
            specInfoNum: v.specInfoNum,
            isVisible: v.isVisible,
            sellingUnitName: v.sellingUnitName,
            skuType: v.skuType,
            minimunMoq: v.minimunMoq, // 普通商品最低起订量
            tableForm: {
              promotion: v.promotionPrice,
              unitPromotionPrice: v.unitPromotionPrice,
              discountType: v.discountType, // 折扣类型
              discount: v.discountType === 1 ? v.discountValue + "" : "", // 折扣返利
              unitDiscountValue:
                v.discountType === 2 ? v.unitDiscountValue : "", // 固定返利
              discountValue: v.discountValue,
              capFee: v.capFee + "", // 瓶盖费
              remarks: v.remark, // 备注
              promotionDiscountsPrice: v.promotionDiscountsPrice, // 酒店促销费用
              unitPromotionDiscountsPrice: v.unitPromotionDiscountsPrice,
              promotionCommission: v.promotionCommission, // 销售员促销费
              unitPromotionCommission: v.unitPromotionCommission,
            },
            classifyName: v.classifyName,
            goodsNo: v.goodsNo,
            skuState: v.skuState,
            remark: v.remark,
            unitsName: v.unitsName,
          };
          return obj;
        }
      });
      return arr;
    }, */
    /* // 数组去重
    duplicateRemoval(arr) {
      let a = [];
      let obj = {};
      arr.map((v, k) => {
        if (obj[v.id] !== true) {
          a.push(v);
          obj[v.id] = true;
        }
      });
      return a;
    }, */

    // 添加商品
    // handleAddGoods() {
    //   this.dialog1Visible = true;
		// 	let arrId = [];
		// 	this.ruleForm.tableListGoods.forEach((v) => {
		// 		if (v.add) arrId.push(v.id); // 商品列表id
		// 	});
		// 	this.querygoodtableData({
		// 		data: {
		// 			isAddToSkuCustomerPrice: true,
		// 			buyerId: this.$route.query.buyerId
		// 			// notId: arrId,
		// 			// hotelAgreementId: this.$route.query.id,
		// 		},
		// 		currentPage: 1,
		// 	});
    // },
    // 添加商品 浮层 ok
   /*  handleOk() {
      // 处理数据
      // 增加form字段
      let arr = this.setTableForm(this.goodsData);
      // 合并数组
      arr.map((v) => {
        if (v.add) {
          this.ruleForm.tableListGoods.unshift(v);
        }
      });
      // 数组去重
      this.ruleForm.tableListGoods = this.duplicateRemoval(
        this.ruleForm.tableListGoods
      );
      // 清空已选择数据
      this.handleClose();
    },
    // 添加商品 浮层 close
    handleClose() {
      this.$store.state.ticket.willadd = [];
      this.dialog1Visible = false;
    }, */
    // table title render
    renderHeader(h, { column, $index }) {
      let span = <span class="is-required"> {column.label} </span>;
      return span;
    },
    changePrice(val) {
      // 监听价格输入框变化
      let goodsList = this.form.goodsList;
      let total = 0;
      goodsList.forEach((i) => {
        if (i.discountPrice) {
          total += i.discountPrice * i.num;
        } else {
          total = 0;
        }
      });
      this.totalPrice = total ? total : 0;
    },
    handleEdit(row, index) {
      // 套餐商品编辑设价
      this.dialogVisiable = true;
      this.mealIndex = index;
      this.mealRow = row;
      this.form.goodsList = JSON.parse(JSON.stringify(row.skuSetInfoVOList)); // 初始化套餐商品列表
			
      // this.form.msrp = row.promotionPrice ? row.promotionPrice : 0
      this.form.remark = row.remark ? row.remark : "";
      // this.form.minimunMoq = row.minimunMoq ? row.minimunMoq : 1; // 套餐最低起订量
    },
    saveMeal() {
      // 更新套餐
      this.$refs.setPriceForm.validate((v) => {
        if (v) {
          // if(this.form.msrp < this.totalPrice) { // 零售价小于套餐价就显示套餐价return false
          // 	this.$message.warning('建议零售价不能小于套餐价')
          // 	return false
          // }
          let body = {
            // agreementId: this.$route.query.id,
						buyerId: this.$route.query.buyerId,
						remark: this.form.remark,
						skuType: 2,
						skuNo:this.mealRow.skuNo,
						id:this.mealRow.id,
						jsonSetInfo: this.form.goodsList,
          };
          // body.id = this.mealRow.add ? null : this.mealRow.id;
          // body.skuId = this.mealRow.add ? this.mealRow.id : this.mealRow.skuId;
         /*  body = Object.assign({}, body, {
            
            // minimunMoq: this.form.minimunMoq,
            /// promotionPrice: this.form.msrp,
            
          }); */
          this.mealGoodUpdate(body)
            .then((res) => {
              this.dialogVisiable = false;
              let data = res.data.data;
							this.getData()
							return
              let mealObj = {
                jsonSetInfo: data.jsonSetInfo,
                promotionPrice: data.promotionPrice,
                minimunMoq: data.minimunMoq,
                remark: data.remark,
                discountValue: data.discountValue,
                add: null,
                id: data.id,
              };
              mealObj.tableForm = {
                discountType: 2, // 折扣类型
                capFee: "0", // 瓶盖费
                remarks: mealObj.remark, // 备注
                fixed: mealObj.discountValue, // 固定返利
                discount: "", // 折扣返利
              };
              this.$set(
                this.ruleForm.tableListGoods,
                this.mealIndex,
                Object.assign(this.mealRow, mealObj)
              ); // 设价成功同步列表数据
              this.form = {
                goodsList: [],
                remark: "", // 备注
                msrp: "", // 建议零售价
                minimunMoq: "", // 最低起订量
              };
              this.totalPrice = "";
              this.mealRow = {};
              this.$message.success("操作成功");
              
            })
            .catch((res) => {
              this.dialogVisiable = false;
              this.$message.error(res.data.message);
            });
        } else {
          return false;
        }
      });
    },
    handleDialogClose() {
      // 套餐设价弹框消失
      this.dialogVisiable = false;
      this.form = {
        goodsList: [],
        remark: "", // 备注
        msrp: "", // 建议零售价
        minimunMoq: "", // 最低起订量
      };
    },
    
    //重置会员
    deleteMeal(id) {
      return new Promise((resolve, reject) => {
        axiosApi(
          "/goods/sku/customerPrice/delete",
          {
            id: id,
          },
          (res) => {
            resolve(res);
          },
          null,
          null,
          (res) => {
            reject(res);
          }
        );
      });
    },
    // 点击重置会员
    handleDelete(row, index) {
      this.$confirm("是否重置会员价？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          // 删除数据库里面的
            this.deleteMeal(row.id).then((res) => {
							this.getData()
              this.$message.success("重置成功");
            });
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="less" >
.hotel-edit-box {
  .red {
    color: #ff4201;
  }
  .width-120 {
    width: 120px;
  }
  .m-inline {
    display: inline-block;
  }
  .m-content {
    margin-top: 20px;
    border: 1px solid #ccc;
    padding: 20px 40px;
    padding-bottom: 50px;
    .button_list {
      .el-button {
        width: auto;
      }
    }
    .m-title {
      height: 30px;
      border-bottom: 1px solid #ccc;
    }
    .m-detail {
      padding-top: 20px;
      padding-bottom: 20px;
      border-bottom: 1px solid #ccc;
      .m-item {
        margin-bottom: 20px;
        .m-item-label {
          display: inline-block;
          width: 120px;
          text-align: right;
        }
        .m-item-content {
          display: inline-block;
        }
      }
    }
    .m-goods-table {
      /* 商品名称 */
      .m-goods-table-goods-name {
        overflow: hidden;
        height: 80px;
        .m-left {
          float: left;
          text-align: left;
          margin-right: 10px;
          img {
            width: 70px;
            height: 70px;
          }
        }
        .m-right {
          text-align: left;
        }
      }
    }
    .m-submit-box {
      height: 40px;
      line-height: 40px;
      text-align: center;
      .m-submit {
        margin-right: 30px;
      }
      button {
        height: 32px;
      }
    }
  }
}
</style>

<style lang="less">

	.m-goods-table {
		.table-default .el-button {
			width: 100%;
			margin-left: 0;
		}
		.el-form-item__error {
			/*// margin-left: 8px;*/
		}
		.unit_select {
			width: 90px;
			.el-input {
				width: 90px !important;
			}
		}
		.moq_unit {
			display: flex;
			align-items: center;
			justify-content: center;
			margin-bottom: 20px;
			.el-form-item__error {
				width: 150px;
				text-align: left;
			}
		}
	}

	.hotel-edit-box {
		.el-zoom-in-top-enter-active,
		.el-zoom-in-top-leave-active {
			display: none !important;
		}
		.moqdialog .el-form-item.is-error {
			.el-input__inner {
				border-color: #ddd !important;
			}
			.el-form-item__error {
				left: 195px;
			}
		}
		.moqdialog .is-required:before {
			display: none;
		}
		.moqdialog {
			.el-dialog {
				min-height: auto;
			}
			.el-select {
				width: auto;
				.el-input {
					width: 75px;
				}
				.el-input__inner {
					width: 75px;
				}
			}
			.unit {
				font-size: 14px;
			}
			.el-radio__label {
				display: none;
			}
			.el-dialog__footer {
				position: static;
				transform: translateX(0);
				padding-bottom: 50px;
			}
			.el-dialog__headerbtn {
				position: absolute;
				right: 0;
				top: 0;
			}
			.radio2 {
				.el-input--mini {
					.el-input__inner {
						border-right: none;
					}
					.el-input__inner:hover,
					.el-input__inner:focus {
						border-right: none;
					}
				}
			}
		}
		/* table title 星号 */
		.is-required:before {
			content: "*";
			color: #ff4949;
			margin-right: 4px;
		}
		/* table 内容 星号 去除 */
		.el-table__row .is-required:before {
			display: none;
		}
		/* error border 优先级不够问题 */
		.el-form-item.is-error .el-input__inner {
			border-color: #ff4949 !important;
		}
		/* error 文案删除 */
		/* .el-form-item__error{
            display: none;
        } */

		textarea {
			resize: none;
		}
		.el-table__body-wrapper {
			// overflow: visible;
		}
		.el-table__empty-block {
			height: 166px;
		}
		.mdialog {
			.el-dialog__footer {
				position: static;
				transform: translateX(0);
				padding-bottom: 50px;
			}
			.msrp:before {
				content: '';
			}
			.msrp {
				.el-form-item__label {
					width: auto;
				}
				.el-form-item__error {
					margin-left: 80px;
				}
			}
			.miniMoq:before {
				content: '';
			}
			.miniMoq {
				.el-form-item__label {
					width: auto;
				}
				.el-form-item__error {
					margin-left: 110px;
				}
			}
			.el-dialog.el-dialog {
				width: 1000px;
			}
			.el-dialog__body {
				padding: 50px 30px 90px 30px;
			}
			.el-dialog__headerbtn {
				position: absolute;
				right: 0;
				top: 0;
			}
			.mr-15 {
				margin-right: 15px;
			}
			.w480 {
				width: 480px;
			}
			.cell {
				.el-form-item {
					margin-top: 10px;
					margin-bottom: 10px;
				}
			}
			.set_price {
				.cell {
					.el-form-item {
						margin-top: 20px;
						margin-bottom: 20px;
						.el-form-item__content {
							.el-input {
								width: auto;
							}
						}
					}
				}
			}
		}
		.unit_select_dialog {
			width: 90px;
			.el-input {
				width: 90px !important;
			}
			.el-input__inner{
				width: 90px !important;
			}
		}
	}
</style>
