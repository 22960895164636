<template>
    <div class="activity pt-30">
        <div class="form-wrap">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                <el-form-item label="活动名称:" prop="title">
                    <el-input class="activity-name" :minlength="2" :maxlength="20" v-model="ruleForm.title"></el-input>
                </el-form-item>
                <el-form-item label="参与次数:" required>
                    <el-select v-model="ruleForm.limitTimes" placeholder="请选择">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="团购名称:" required>
                    <el-select class="group-name" v-model="ruleForm.grouponId" placeholder="请选择" @change="changeGroup">
                        <el-option v-for="item in activityList" :key="item.id" :label="item.grouponName" :value="item.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="活动类型:" prop="activityType">
                    <el-radio-group v-model="ruleForm.activityType">
                        <el-radio label="1">满额减</el-radio>
                        <el-radio label="2">满件减</el-radio>
                        <el-radio label="3">满额赠</el-radio>
                        <el-radio label="4">满件赠</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item v-if="ruleForm.activityType==2 || ruleForm.activityType==4" label="商品类型:" prop="unitType">
                    <el-radio-group v-model="ruleForm.unitType">
                        <el-radio label="1">商品按箱</el-radio>
                        <el-radio label="2">商品按瓶</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="优惠设置:" prop="promotionType">
                    <el-radio-group v-model="ruleForm.promotionType">
                        <el-radio label="1">阶梯满{{ruleForm.activityType==3 || ruleForm.activityType == 4 ? '赠' : '减'}}</el-radio>
                        <el-radio label="2">循环满{{ruleForm.activityType==3 || ruleForm.activityType == 4 ? '赠' : '减'}}</el-radio>
                    </el-radio-group>
                    <!--优惠详情设置-->
                    <div class="discountDetail">
                        <p class="tips" v-if="ruleForm.promotionType==1">提示：每级优惠不叠加，如：满足二级优惠条件后则不再享有一级优惠。最多支持三级优惠。</p>
                        <!--满额减-->
                        <div v-if="ruleForm.activityType==1"  class="fullLimit">
                            <div class="stair">
                                <div class="stairList clearfix" v-for="(item,index) in stairList" :key="index+'ac'">
                                    <div class="fl mr-50">
                                        消费{{ruleForm.promotionType==1 ? '' : '每'}}满&nbsp;&nbsp;<sy-input v-model="item.fullMoney" type="float" :min="0.01" :max="99999.99"></sy-input>&nbsp;&nbsp;元
                                    </div>
                                    <div class="fl mr-30">
                                        减&nbsp;&nbsp;<sy-input v-model="item.minusAmount" type="float" :min="0.01" :max="99999.99"></sy-input>&nbsp;&nbsp;元
                                    </div>
                                    <el-button type="text" v-if="index>0" @click="removeActivity(index)">删除</el-button>
                                </div>
                                <el-button v-if="ruleForm.promotionType==1 && stairList.length<3" type="text" @click="addActivity">继续添加</el-button>
                            </div>
                        </div>
                        <!--满件减-->
                        <div v-if="ruleForm.activityType==2"  class="fullLimit">
                            <div class="stair">
                                <div class="stairList clearfix" v-for="(item,index) in stairList" :key="index+'ab'">
                                    <div class="fl mr-50">
                                        消费{{ruleForm.promotionType==1 ? '' : '每'}}满&nbsp;&nbsp;<sy-input v-model="item.fullAmount" type="int" :min="1" :max="99999"></sy-input><span>&nbsp;&nbsp;{{ruleForm.unitType==1?'箱':'瓶'}}</span>
                                    </div>
                                    <div class="fl mr-30">
                                        减&nbsp;&nbsp;<sy-input v-model="item.minusAmount" type="float" :min="0.01" :max="99999.99"></sy-input>&nbsp;&nbsp;元
                                    </div>
                                    <el-button type="text" v-if="index>0" @click="removeActivity(index)">删除</el-button>
                                </div>
                                <el-button type="text" v-if="ruleForm.promotionType==1 && stairList.length<3" @click="addActivity">继续添加</el-button>
                            </div>
                        </div>
                        <!--满额赠-->
                        <div v-if="ruleForm.activityType==3"  class="fullLimit">
                            <div class="fullSend-wrap" v-for="(item,i) in sendList" :key="i+'cc'">
                                <div class="info mt-20 clearfix">
                                    消费{{ruleForm.promotionType==1 ? '' : '每'}}满&nbsp;&nbsp;<sy-input v-model="item.fullMoney"  type="float" :min="0.01" :max="99999.99"></sy-input>&nbsp;&nbsp;元,送赠品（最多可选三个赠品）：
                                    <el-button type="text"  v-if="i!=0" @click="removeGift(i)">删除{{i==1?'二':'三'}}级优惠</el-button>
                                </div>
                                <div class="table-gift">
                                    <div class="btn clearfix">
                                        <el-button type="text" class="fr" @click="selectGift(i)">选择赠品</el-button>
                                    </div>
                                    <tableC :key="i" :index="i"></tableC>
                                </div>
                            </div>
                            <div class="addGift mt-30" v-if="ruleForm.promotionType==1">
                                <el-button v-if="sendList.length<3"  type="text"  @click="addActivitySendList">添加{{sendList.length==1?'二':'三'}}级优惠</el-button>
                            </div>
                        </div>
                        <!--满件赠-->
                        <div v-if="ruleForm.activityType==4"  class="fullLimit">
                            <div class="fullSend-wrap" v-for="(item,i) in sendList">
                                <div class="info mt-20">
                                    消费{{ruleForm.promotionType==1 ? '' : '每'}}满&nbsp;&nbsp;<sy-input v-model="item.fullAmount"  type="int" :min="1" :max="99999"></sy-input>&nbsp;&nbsp;{{ruleForm.unitType=="1"?'箱':'瓶'}},送赠品（最多可选三个赠品）：
                                    <el-button type="text"  v-if="i!=0" @click="removeGift(i)">删除{{i==1?'二':'三'}}级优惠</el-button>
                                </div>
                                <div class="table-gift">
                                    <div class="btn clearfix">
                                        <el-button type="text" class="fr"  @click="selectGift(i)">选择赠品</el-button>
                                    </div>
                                    <tableC :index="i"></tableC>
                                </div>
                            </div>
                            <div class="addGift mt-30" v-if="ruleForm.promotionType==1">
                                <el-button v-if="sendList.length<3" type="text"  @click="addActivitySendList">添加{{sendList.length==1?'二':'三'}}级优惠</el-button>
                            </div>
                        </div>
                    </div>
                </el-form-item>
                <el-form-item label="活动商品:" prop="chooseType">
                    <el-radio-group v-model="ruleForm.chooseType">
                        <el-radio label="1">所有商品</el-radio>
                        <el-radio label="2">部分商品</el-radio>
                    </el-radio-group>
                    <div class="goodsTab">
                        <div class="tips">提示：单个商品仅允许同时参与一个满减/送活动</div>
                        <!--<div class="searchGoods mt-20 mb-20">
                            <div class="goods-name mr-30">
                                <span>商品名称：</span>
                                <el-input placeholder="请输入商品名称" v-model="skuObj.skuName"></el-input>
                            </div>
                            <div class="goods-type  mr-30">
                                <span>商品分类：</span>
                                <el-select v-model="skuObj.classifyId" placeholder="请选择" clearable>
                                    <el-option v-for='item in classifylist' :key='item.id'
                                               :label='item.classifyName'
                                               :value='item.id'></el-option>
                                </el-select>
                            </div>
                            <div class="search">
                                <el-button type="info" @click="search">查询</el-button>
                            </div>
                        </div>-->
                        <div v-if="ruleForm.chooseType==2" class="allGoods mb-20 clearfix">
                            <el-button type="text" class="fr" @click="isSelectAll">选择全部商品</el-button>
                            <el-button type="text" class="fr" @click="goodsVisible = true">选择商品</el-button>
                        </div>
                        <tableD v-loading="goodsLoading" :allGoods="ruleForm.chooseType==1?false:true"></tableD>
                    </div>
                </el-form-item>
                <el-form-item label="" class="addOpt">
                    <el-button class="save" @click="onSubmit('ruleForm')" :loading="btnLoading">保存</el-button>
                    <el-button @click="goPre">取消</el-button>
                </el-form-item>
            </el-form>
        </div>
        <select-gift  :currentGiftIndex="currentGiftIndex" :max="3" :product-visible="giftVisible" :pro-data="proData" @closedialog="giftVisible = false"></select-gift>
        <select-goods :goods-visible="goodsVisible" :goods-data="goodsData" @closedialog2="goodsVisible = false"></select-goods>
    </div>
</template>

<script>
    import { mapActions,mapState,mapGetters ,mapMutations} from 'vuex'
    import { checkName } from "~/js/extend";
    import {axiosApi,basePath} from "~/api/api";
    export default {
        name: "addActivity",
        data(){
            return {
                btnLoading:false,
                skuObj:{
                    skuName:null,
                    classifyId: null
                },
                companyId:null,//公司id
                grouponId:null,//团购id
                currentGiftIndex:0,//当前点击赠索引
                goodsVisible:false,
                giftVisible:false,//选择赠品弹框
                activityList:[],//团购列表
                options:[
                    { value:-1,label:"不限次数" },
                    { value:1,label:"1" },
                    { value:2,label:"2" },
                    { value:3,label:"3" },
                    { value:4,label:"4" },
                    { value:5,label:"5" },
                    { value:6,label:"6" },
                    { value:7,label:"7" },
                    { value:8,label:"8" },
                    { value:9,label:"9" },
                    { value:10,label:"10" },
                ],//参与次数选项
                ruleForm:{
                    limitTimes:-1,//参与次数
                    title:"",//活动名称
                    grouponId:'',//团购id
                    activityName:'',//团购名称
                    activityType:"1",//活动类型
                    promotionType:"1",//优惠设置
                    unitType:"1",//商品类型
                    chooseType:"1",//参与活动的商品:全部 or  部分
                },//表单数据
                rules:{
                    title:[
                        { required:true,message:'请输入活动标题',trigger:'blur' },
                        { validator:checkName,trigger:'blur' },
                        { min:2,max:20,message:'请输入活动标题',trigger:'blur' }
                    ],
                    activityType:[
                        { required: true, message: '请选择活动类型', trigger: 'change' }
                    ],
                    promotionType:[
                        { required: true, message: '请选择优惠类型', trigger: 'change' }
                    ],
                    unitType:[
                        { required: true, message: '请选择销售单位', trigger: 'change' }
                    ],
                    chooseType:[
                        { required: true, message: '请选择活动商品', trigger: 'change' }
                    ]
                }
            }
        },
        computed:{
            classifylist(){
                return this.$store.state.classifylist.classifylistData
            },
            proData(){//选择赠品列表
                return this.$store.getters.giftList(this.currentGiftIndex);
            },
            goodsData(){//商品列表
                return this.$store.getters.goodsList;
            },
            stairList(){
                return this.$store.state.marketActivity.willAddActivityList
            },
            sendList(){
                return this.$store.state.marketActivity.willAddActivitySendList
            },
            goodsLoading(){
                return this.$store.state.goodsAdd.goodsLoading;
            }
        },
        watch:{
            "ruleForm.promotionType":function (val) {
                this.resetWillAddActivityList();
                this.resetWillAddActivitySendList()
            },
            "ruleForm.activityType":function(val){
                this.resetWillAddActivityList();
                this.resetWillAddActivitySendList();
                this.currentGiftIndex = 0;
                if(val == 1){
                    this.searchGoods()
                }

                if(val == 2){
                    this.$set(this.ruleForm,'unitType',"1")
                    this.searchGoods(this.ruleForm.unitType==1);
                }


                if(val == 3){
                    this.searchGoods()
                    this.getGiftList({
                        skuName:null
                    });
                }

                if(val == 4){
                    this.$set(this.ruleForm,'unitType',"1")
                    this.searchGoods(this.ruleForm.unitType==1);
                    this.getGiftList({
                        skuName:null
                    });
                }
            },
            "ruleForm.chooseType":function(val){
                if(val == 2){
                    this.resetGoods({flag:true})
                }else{
                    this.selectedAll()
                }
            },
            "ruleForm.unitType":function(val){
                if(val=="1"){//按箱
                    this.searchGoods(true);
                }else{//按瓶
                    this.searchGoods(false);
                }
            }
        },
        beforeMount(){
            // this.getGiftList();
            this.getGroupList();
            this.queryclassifylistData();
            // this.getGoodsListActivity()
        },
        methods:{
            ...mapActions({
                'getGiftList':'getGiftList',
                'getGoodsListActivity':'getGoodsListActivity',
                'queryclassifylistData':'queryclassifylistData',
            }),
            ...mapMutations({
                'addActivityList':'addActivityList',
                'delActivity':'delActivity',
                'resetWillAddActivityList':'resetWillAddActivityList',
                'addActivitySendList':'addActivitySendList',
                'delActivitySend':'delActivitySend',
                'resetWillAddActivitySendList':'resetWillAddActivitySendList',
                'delGift':'delGift',
                'selectedAll':'selectedAll',
                "resetGoods":"resetGoods"
            }),
            //选择所有
            isSelectAll(){
                if(!this.ruleForm.grouponId){
                    this.$message.warning('请先选择团购活动');
                    return
                }
                if(this.$store.state.goodsAdd.goodsList.length<=0){
                    this.$message.warning('该团购活动下暂无商品');
                    return
                }
                this.$confirm('确定要添加全部商品吗?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.selectedAll()
                }).catch()
            },
            search(){
                // this.searchGoods(this.ruleForm.unitType == "1" && (this.ruleForm.activityType == 2 || this.ruleForm.activityType == 4));
                if(this.ruleForm.chooseType==1){//全部商品

                }else{//部分

                }
            },
            //查询商品
            searchGoods(isIntegerMultiple=null){
                if(this.grouponId){
                    let body = {
                        sellerId:sessionStorage.getItem('traderId'),
                        // goodsState:1,
                        // auditState:2,
                        goodsNo:null,
                        skuName:null,
                        classifyId: null,
                        notSkuNo:[],
                        isIntegerMultiple:isIntegerMultiple,
                        grouponId:this.grouponId
                    }
                    this.getGoodsListActivity(body)
                }
            },
            //获取团购活动列表
            getGroupList(){
                axiosApi('/groupon/groupon/list',{ statusList:[1,2] },res => {
                    this.activityList = res.data.data;
                })
            },
            //团购活动变更、
            changeGroup(val){
                this.ruleForm.activityType = "1";
                this.ruleForm.promotionType = "1";
                this.ruleForm.unitType = "1";
                this.ruleForm.chooseType = "1";
                this.grouponId = val;
                this.activityList.map(v => {
                    if(v.id == val){
                        this.companyId = v.companyId;
                    }
                });
                this.searchGoods();
            },
            //添加阶梯满减
            addActivity(){
                this.addActivityList()
            },
            //删除活动
            removeActivity(index){
                this.$confirm('确定要删除吗?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.delActivity({index:index})
                }).catch()
            },
            //选择赠品
            selectGift(i){
                this.currentGiftIndex = i;
                this.giftVisible = true;
            },
            //关闭赠品弹框
            closeDialog(){
                this.giftVisible = false
            },
            //删除i级赠品
            removeGift(i){
                this.$confirm('确定要删除吗?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.currentGiftIndex = 0;
                    this.delGift({index:i})
                }).catch()
            },
            //提交
            onSubmit(ruleForm){
                //参与活动的商品id
                let goodsIdList = this.$store.state.goodsAdd.willAddGoods.map(v => {
                    return v.id
                });

                let body = {
                    title:this.ruleForm.title,
                    limitTimes:this.ruleForm.limitTimes,
                    unitType:this.ruleForm.unitType,
                    grouponId:this.grouponId,
                    companyId :this.companyId,
                    activityType:this.ruleForm.activityType,
                    chooseType:this.ruleForm.chooseType,
                    promotionType:this.ruleForm.promotionType,
                    goodsIdList:goodsIdList,
                }

                let flag = true;

                if(!this.grouponId){
                    flag = false;
                    this.$message.warning('请选择团购活动');
                    return;
                }

                if(goodsIdList.length<=0){
                    flag = false;
                    this.$message.warning('请选择商品');
                    return;
                }




                let giftRuleParamList = [];//满赠规则
                let cutRuleParamList = [];//满减规则
                let detailLists = [];
                if(this.ruleForm.activityType == 1){//满额减
                    let list = this.$store.state.marketActivity.willAddActivityList;
                    this.$store.state.marketActivity.willAddActivityList.map(((v,i) => {

                        let str = `满${v.fullMoney}元减${v.minusAmount}元`;
                        detailLists.push(str);

                        if(!v.fullMoney){
                            flag = false;
                            this.$message.warning('请设置优惠门槛')
                        }

                        if(!v.minusAmount){
                            flag = false;
                            this.$message.warning('请设置优惠金额')
                        }

                        if(Number(v.minusAmount) >= Number(v.fullMoney)){
                            flag = false;
                            this.$message.warning('优惠门槛必须大于优惠金额')
                        }

                        if(i<list.length-1){//不唯一

                            if(Number(v.fullMoney) >= Number(list[i+1].fullMoney)){
                                flag = false;
                                this.$message.warning('下一级优惠门槛必须大于上一级')
                            }

                            if(Number(v.minusAmount) >= Number(list[i+1].minusAmount)){
                                flag = false;
                                this.$message.warning('下一级优惠金额必须大于上一级')
                            }

                        }
                        cutRuleParamList[i] = {};
                        cutRuleParamList[i].fullMoney = v.fullMoney;
                        cutRuleParamList[i].minusAmount = v.minusAmount;
                    }));
                    body.detail = detailLists.join(',')
                    body.cutRuleParamList = cutRuleParamList
                }else if(this.ruleForm.activityType == 2){//满件减
                    let list = this.$store.state.marketActivity.willAddActivityList;
                    this.$store.state.marketActivity.willAddActivityList.map(((v,i) => {
                        cutRuleParamList[i] = {};
                        cutRuleParamList[i].fullAmount = v.fullAmount;
                        cutRuleParamList[i].minusAmount = v.minusAmount;


                        let str = `满${v.fullAmount}件减${v.minusAmount}元`;
                        detailLists.push(str);

                        if(!v.fullAmount){
                            flag = false;
                            this.$message.warning('请设置满件数量')
                        }

                        if(i<list.length-1){
                            if(Number(v.fullAmount) >= Number(list[i+1].fullAmount)){
                                flag = false;
                                this.$message.warning('下一级满件数量必须大于上一级')
                            }

                            if(Number(v.minusAmount) >= Number(list[i+1].minusAmount)){
                                flag = false;
                                this.$message.warning('下一级优惠金额必须大于上一级')
                            }
                        }
                    }));

                    //满件减规则二次验证  件数*单价 > 优惠金额
                    this.$store.state.goodsAdd.willAddGoods.map(v => {
                        this.$store.state.marketActivity.willAddActivityList.map(n => {
                            if(body.unitType == 1){//按箱
                                if(v.grouponPrice * n.fullAmount * v.specInfoNum < n.minusAmount){
                                    flag = false;
                                    this.$message.warning('满减门槛必须大于商品价格')
                                }
                            }else{
                                if(v.grouponPrice * n.fullAmount < n.minusAmount){
                                    flag = false;
                                    this.$message.warning('满减门槛必须大于商品价格')
                                }
                            }
                        })
                    })

                    body.detail =detailLists.join(',')
                    body.cutRuleParamList = cutRuleParamList
                }else if(this.ruleForm.activityType == 3){//满额赠
                    let list = this.$store.state.marketActivity.willAddActivitySendList;
                    this.$store.state.marketActivity.willAddActivitySendList.map(((v,i) => {
                        giftRuleParamList[i] = {};
                        giftRuleParamList[i].fullMoney = v.fullMoney;
                        giftRuleParamList[i].giftRelationVOList = [];

                        let str = `满${v.fullMoney}元，送赠品`;
                        detailLists.push(str);

                        if(!v.fullMoney){
                            flag = false;
                            this.$message.warning('请设置满额')
                        }

                        if(i<list.length-1){
                            if(Number(v.fullMoney) >= Number(list[i+1].fullMoney)){
                                flag = false;
                                this.$message.warning('下一级满额必须大于上一级')
                            }
                        }

                        if(v.willAddGift.length<=0){
                            flag = false;
                            this.$message.warning('请选择赠品');
                        }

                        v.willAddGift.map((item,index) => {
                            giftRuleParamList[i].giftRelationVOList[index] = {};
                            giftRuleParamList[i].giftRelationVOList[index].quantity = item.quantity;
                            giftRuleParamList[i].giftRelationVOList[index].grouponActivityGiftId = item.id;
                        })
                    }));
                    body.detail = detailLists.join(',')
                    body.giftRuleParamList = giftRuleParamList
                }else if(this.ruleForm.activityType == 4){//满件赠
                    let list = this.$store.state.marketActivity.willAddActivitySendList;
                    this.$store.state.marketActivity.willAddActivitySendList.map(((v,i) => {

                        if(!v.fullAmount){
                            flag = false;
                            this.$message.warning('请设置满件数量')
                        }

                        giftRuleParamList[i] = {};
                        giftRuleParamList[i].fullAmount = v.fullAmount;
                        giftRuleParamList[i].giftRelationVOList = [];

                        let str = `满${v.fullAmount}件，送赠品`;
                        detailLists.push(str);

                        if(i<list.length-1){
                            if(Number(v.fullAmount) >= Number(list[i+1].fullAmount)){
                                flag = false;
                                this.$message.warning('下一级满件数量必须大于上一级')
                            }
                        }
                        if(v.willAddGift.length<=0){
                            flag = false;
                            this.$message.warning('请选择赠品');
                        }

                        v.willAddGift.map((item,index) => {
                            giftRuleParamList[i].giftRelationVOList[index] = {};
                            giftRuleParamList[i].giftRelationVOList[index].quantity = item.quantity;
                            giftRuleParamList[i].giftRelationVOList[index].grouponActivityGiftId = item.id;
                        })
                    }));
                    body.detail = detailLists.join(',')
                    body.giftRuleParamList = giftRuleParamList
                }

                if(!flag){
                    return
                }

                this.btnLoading = true;
                axiosApi('/groupon/activity/add',body,res => {
                    this.btnLoading = false;
                    this.loading = false;
                    this.$notify({
                        title: '成功',
                        message: '活动新增成功即将跳转活动列表',
                        type: 'success'
                    });
                   this.$nextTick(() => {
                       this.resetWillAddActivityList();
                       this.resetWillAddActivitySendList();
                       this.resetGoods({flag:false});
                   })
                    setTimeout(() => {
                        this.$router.push('/customer/superGroup/groupSalesManager')
                    },0);
                },1,10,err => {
                    this.btnLoading = false;
                    this.loading = false;
                    this.$message.warning(err.data.message)
                })
            },

            //回到上一页
            goPre(){
                this.$router.push(basePath + '/superGroup/groupSalesManager')
            }
        },
        beforeDestroy(){
            this.resetWillAddActivityList();
            this.resetWillAddActivitySendList();
            this.resetGoods({flag:false});
        }
    }
</script>

<style lang="less" >
.activity{
    background:white;
    .form-wrap{
        width: 1100px;
        margin: 20px auto 0;
        .activity-name{
            input{
                width: 350px!important;
            }
        }
        .group-name .el-input{
            width: 350px!important;
        }
        .tips{
            color:#999999;
            margin: 20px 0 0;
            font-size: 14px;
        }
        .fullLimit{
            margin-top: 20px;
        }
        .stairList{
            margin-bottom: 19px;
            div{

            }
        }
        .goodsTab{
            .searchGoods{
                display: flex;
            }
        }
        .addOpt{
            .save{
                background-color: #FF4201;
                border-color: #FF4201;
                span{
                    color: #fff;
                }
            }
        }
    }
    .goods-wrap{
        overflow: hidden;
        display: flex;
        .r-goods{
            flex: 1;
            overflow: hidden;
            div{
                font-size: 12px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
    }
}
</style>