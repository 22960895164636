<template>
	<div id="businessList" class="pr-40">
		<div class="title-tabs bg-f pl-20">
			<ul class="clearfix">
				<template v-for='(tab,index) in dataTabs' class="item">
					<li :type='index' :class="type==index?'active':''" @click='handleTabs'>
						{{tab}}
						<div :class="type==index?'under-line':''"></div>
					</li>
					<div class="vertical-line"></div>
				</template>
			</ul>
		</div>
		<div class='bg-f mt-5 pb-50'>
			<el-form :inline="true" :model="formInlineData" label-width="90px" class="pl-20 clearfix pt-30">
				<div class="fl" style="width: 80%;">
					<el-form-item label="商户名称" v-if='type == 0'>
						<el-input v-model.trim="formInlineData.storeName"></el-input>
					</el-form-item>
					<el-form-item label="经销商名称" v-else>
						<el-input v-model.trim="formInlineData.storeName"></el-input>
					</el-form-item>
					<el-form-item label="业务员名称" v-if='type == 0'>
						<el-input v-model.trim="formInlineData.managerName"></el-input>
					</el-form-item>
				</div>
				<el-button type="info" class="fl" @click='achieve(true)'>搜索</el-button>
			</el-form>
			<div class="clearfix pr-30 mb-10" style="overflow: hidden;">
				<!--<p class="fr mr-30 cursor font-12 color-blue mt-15" v-if='showButton("DEL",200012)' @click='showLog'>删除日志</p>
				<p class="fr mr-30 cursor font-12 color-blue mt-15" v-if='showButton("DEL",200012)' @click='deleteMer'>批量删除</p>-->
				<p v-if='showButton("EXPORT",200012)' class="fr mr-30 cursor font-12 color-blue mt-15" @click='exporttable'>导出</p>
				<p v-if='type == 0 && showButton("BATCH_ADD_MANAGER",200012)' class="fr mr-30 cursor font-12 color-blue mt-15" @click='getAllMerchant'>批量新增业务员</p>
			</div>
			<div v-show='type == "0"'>
				<el-table :data="merchantList" class='ml-30 mt-10 table-default mr-30' stripe @selection-change="handleSelectionAll" v-loading='Loading'>
					<el-table-column type="selection" width="50">
					</el-table-column>
					<el-table-column label="商户名称" prop="storeName" width="70">
					</el-table-column>
					<el-table-column label="联系人" prop="linkname" width="70">
					</el-table-column>
					<el-table-column label="联系方式" prop="tel" width="85">
					</el-table-column>
					<el-table-column label="所在区域" width="125">
						<template slot-scope="scope">
							<span>{{scope.row.provinceName}}</span>
							<span>{{scope.row.cityName}}</span>
							<span>{{scope.row.regionName}}</span>
						</template>
					</el-table-column>
					<el-table-column label="客户等级" width="60">
						<template slot-scope="scope">
							<span v-if="scope.row.levelName">{{scope.row.levelName}}</span>
							<span v-else>无</span>
						</template>
					</el-table-column>
					<el-table-column label="客户类型" width="60">
						<template slot-scope="scope">
							<p v-if="scope.row.cType == 1">经销商</p>
							<p v-if="scope.row.cType == 2">终端</p>
							<span v-else-if="scope.row.ctype == 3">久加久*久柜</span>
							<span v-else-if="scope.row.ctype == 5">久加久*店中店</span>
							<span v-else-if="scope.row.ctype == 4">久加久*连锁店</span>
						</template>
					</el-table-column>
					<el-table-column label="终端渠道" width="70">
						<template slot-scope="scope">
							<p v-if="scope.row.cType == 1">无</p>
							<p v-if="scope.row.cType ==2">
								<span v-if="scope.row.classifyName1">{{scope.row.classifyName1}}</span>
								<span v-if="scope.row.classifyName2">/{{scope.row.classifyName2}}</span>
								<span v-if="!scope.row.classifyName1">无</span>
							</p>
						</template>
					</el-table-column>
					<el-table-column label="业务员" width="85">
						<template slot-scope="scope">
							<div v-if="scope.row.managerNames">
								<p :title="scope.row.managerNames" v-if="scope.row.managerNames.indexOf(',')>0">{{scope.row.managerNames.split(',')[0]}}...</p>
								<p v-if="scope.row.managerNames.indexOf(',') == -1">{{scope.row.managerNames}}</p>
							</div>
						</template>
					</el-table-column>
					<el-table-column label="来源" prop="" width="60">
						<template slot-scope="scope">
							<p v-if="scope.row.cSource == 1">注册商户</p>
							<p v-if="scope.row.cSource == 2">录入商户</p>
						</template>
					</el-table-column>
					<el-table-column label="经纬度">
						<template slot-scope="scope">
							<p>{{scope.row.longitude ? scope.row.longitude : '未添加'}}</p>
							<p>{{scope.row.latitude}}</p>
						</template>
					</el-table-column>
					<el-table-column label="操作" width="75">
						<template slot-scope="scope">
							<div v-if='showButton("EDIT_MANAGER",200012) || showButton("EDIT_DEALER",200012) || showButton("SET_DEALER",200012) || showButton("DEL_LNGANDLAT",200012)'>
								<el-button class='mr-10' v-if='showButton("EDIT_MANAGER",200012)'  @click="changeName(scope.$index, merchantList)" type="text" size="small">
									变更业务员
								</el-button>
								<el-button v-if='showButton("EDIT_DEALER",200012)' class='ml-0 mr-10' @click.native.prevent="changeDealer(scope.$index, scope.row)" type="text" size="small">
									配置卖家
								</el-button>
								<el-button class='ml-0 mr-10' v-if='scope.row.longitude && showButton("DEL_LNGANDLAT",200012)' @click="deleteRows(scope.$index, scope.row)" type="text" size="small">
									清空经纬度
								</el-button>
								<!--<el-button class='ml-0 mr-10' v-if='showButton("DEL",200012)' @click="deleteMer(scope.$index, scope.row)" type="text" size="small">
									删除
								</el-button>-->
							</div>
							<div v-else>-</div>
						</template>
					</el-table-column>
				</el-table>
			</div>
			<div v-show='type == "1"'>
				<el-table :data="merchantList" class='ml-30 mt-10 mr-30' stripe v-loading='Loading' @selection-change="handleSelectionAll">
					<el-table-column type="selection">
					</el-table-column>
					<el-table-column label="经销商名称" prop="storeName">
					</el-table-column>
					<el-table-column label="联系人" prop="linkname">
					</el-table-column>
					<el-table-column label="联系方式" prop="tel">
					</el-table-column>
					<el-table-column label="所在区域" width="140">
						<template slot-scope="scope">
							<span>{{scope.row.provinceName}}</span>
							<span>{{scope.row.cityName}}</span>
							<span>{{scope.row.regionName}}</span>
						</template>
					</el-table-column>
					<el-table-column label="入驻时间">
						<template slot-scope="scope">
							<p>{{scope.row.gmtCreate | timenData }}</p>
						</template>
					</el-table-column>
					<el-table-column label="来源">
						<template slot-scope="scope">
							<p v-if="scope.row.cSource==1">注册商户</p>
							<p v-if="scope.row.cSource==2">录入商户</p>
						</template>
					</el-table-column>
					<el-table-column label="分配买家" prop="count">
					</el-table-column>
					<el-table-column label="操作">
						<template slot-scope="scope">
							<el-button v-if='showButton("EDIT_TRADER",200012)' @click="dispatch(scope.$index, scope.row)" type="text" size="small">
								分配买家
							</el-button>
							<div v-else>-</div>
						</template>
					</el-table-column>
				</el-table>
			</div>
			<el-pagination v-if='total>0' class="tar mr-30" :current-page="currentPage" :page-size='10' :total='total' layout="prev , pager, next, jumper" @current-change="handleCurrentChange">
			</el-pagination>
			<!--变更业务员-->
			<el-dialog title="变更业务员" :visible.sync="changeSalesman" class='tac' @close="changeSalesman=false">
				<div class="mb-50">
					<p class="mb-10">已关联业务员</p>
					<div class="clearfix">
						<div class="fl ml-50">
							<span>业务员名称：</span>
							<el-input v-model.trim='searchInName' placeholder="请输入内容"></el-input>
						</div>
						<el-button type="info" class="fl ml-50" @click='getBuyers(true)'>搜索</el-button>
					</div>
					<el-table v-loading="Loading3" :data="buyerList" class='ml-30 mt-10 table-default mr-30 ' stripe @selection-change="handleSelectionDelete">
						<el-table-column type="selection">
						</el-table-column>
						<el-table-column label="业务员编码" prop="managerId" width="445">
						</el-table-column>
						<el-table-column label="业务员名称" prop="name" width="445">
						</el-table-column>
					</el-table>
					<el-pagination v-if='total3>0' class="tar mr-30" :current-page="currentPage3" :page-size='10' :total='total3' layout="prev , pager, next, jumper" @current-change="handleCurrentChange3">
					</el-pagination>
				</div>
				<div class="mb-50">
					<p class="mb-10">未关联业务员</p>
					<div class="clearfix">
						<div class="fl ml-50">
							<span>业务员名称：</span>
							<el-input v-model.trim='searchNoName' placeholder="请输入内容"></el-input>
						</div>
						<el-button type="info" class="fl ml-50" @click='getMans(true)'>搜索</el-button>
					</div>
					<el-table v-loading="Loading3" :data="manList" class='ml-30 mt-10 table-default mr-30' stripe @selection-change="handleSelectionChange">
						<el-table-column type="selection">
						</el-table-column>
						<el-table-column label="业务员编码" prop="managerId" width="445">
						</el-table-column>
						<el-table-column label="业务员名称" prop="name" width="445">
						</el-table-column>
					</el-table>
					<el-pagination v-if='total4>0' class="tar mr-30" :current-page="currentPage4" :page-size='10' :total='total4' layout="prev , pager, next, jumper" @current-change="handleCurrentChange4">
					</el-pagination>
				</div>
				<el-button type='danger' class='mt-10 mr-30' @click="manDeletesId">解绑业务员</el-button>
				<el-button type='danger' class='mt-10' @click="getIds">绑定业务员</el-button>
			</el-dialog>
			<!--批量变更业务员-->
			<el-dialog title="批量新增业务员" :visible.sync="changeSalesmanAll" class='tac' @close="changeSalesmanAll=false">
				<div class="clearfix">
					<div class="fl ml-50">
						<span>业务员名称：</span>
						<el-input v-model.trim='searchNoName' placeholder="请输入内容"></el-input>
					</div>
					<el-button type="info" class="fl ml-50" @click='getMans(true)'>搜索</el-button>
				</div>
				<el-table :data="manList" class='ml-30 mt-10 table-default mr-30' stripe @selection-change="handleSelectionChange" v-loading='Loading4'>
					<el-table-column type="selection">
					</el-table-column>
					<el-table-column label="业务员编码" prop="managerId" width="445">
					</el-table-column>
					<el-table-column label="业务员名称" prop="name" width="445">
					</el-table-column>
				</el-table>
				<el-pagination v-if='total5>0' class="tar mr-30" :current-page="currentPage4" :page-size='10' :total='total5' layout="prev , pager, next, jumper" @current-change="handleCurrentChange4">
				</el-pagination>
				<el-button type='danger' class='mt-10 mt-50' @click="getMoreIds">绑定业务员</el-button>
			</el-dialog>
			<!--变更经销商 改为 配置卖家-->
			<el-dialog title=配置卖家 :visible.sync="dealer" class='tac' @close="dealer=false">
				<div class="clearfix">
					<div class="fl ml-50">
						<span>经销商名称：</span>
						<el-input v-model.trim='searchDealer' placeholder="请输入内容"></el-input>
					</div>
					<el-button type="info" class="fl ml-50" @click='achieveDealer(true)'>搜索</el-button>
				</div>
				<el-table v-loading="Loading2" :data="dealerList" class='ml-30 mt-10 table-default mr-30' stripe @selection-change="handleSelectionChange">
					<el-table-column label="经销商名称" prop="storeName">
					</el-table-column>
					<el-table-column label="联系人" prop="linkname">
					</el-table-column>
					<el-table-column label="联系方式" prop="tel">
					</el-table-column>
					<el-table-column label="所在区域">
						<template slot-scope="scope">
							<span>{{scope.row.provinceName}}</span>
							<span>{{scope.row.cityName}}</span>
							<span>{{scope.row.regionName}}</span>
						</template>
					</el-table-column>
					<el-table-column label="入驻时间">
						<template slot-scope="scope">
							<p>{{scope.row.gmtCreate | timenData }}</p>
						</template>
					</el-table-column>
					</el-table-column>
					<el-table-column label="来源">
						<template slot-scope="scope">
							<p v-if="scope.row.cSource==1">注册商户</p>
							<p v-if="scope.row.cSource==2">录入商户</p>
						</template>
					</el-table-column>
					<el-table-column label="分配买家" prop="count">
					</el-table-column>
					<el-table-column label="操作">
						<template slot-scope="scope">
							<p v-if='scope.row.checked' class='cursor color-red' @click="getDelarId(scope.$index, dealerList)">解绑</p>
							<p v-else class='cursor color-blue ' @click="delarBind(scope.$index, dealerList)">绑定</p>
						</template>
					</el-table-column>
				</el-table>
				<el-pagination v-if='total1>0' class="tar mr-30" :current-page="currentPage1" :page-size='10' :total='total1' layout="prev , pager, next, jumper" @current-change="handleCurrentChange1">
				</el-pagination>
			</el-dialog>
			<!--分配商户 改为 分配买家-->
			<el-dialog title="分配买家" :visible.sync="dispatchSale" @close="dispatchSale=false">
				<div class="clearfix">
					<div class="fl ml-50">
						<span>商户名称：</span>
						<el-input v-model.trim='searchStore' placeholder="请输入内容"></el-input>
					</div>
					<el-button type="info" class="fl ml-50" @click='achieveMerchant(true)'>搜索</el-button>
				</div>
				<el-table v-loading="Loading1" :data="merchantList1" class='ml-30 mt-10 table-default mr-30' stripe>
					<el-table-column label="商户名称" prop="storeName" width="110">
					</el-table-column>
					<el-table-column label="联系人" prop="linkname" width="75">
					</el-table-column>
					<el-table-column label="联系方式" prop="tel" width="85">
					</el-table-column>
					<el-table-column label="所在区域" width="140">
						<template slot-scope="scope">
							<span>{{scope.row.provinceName}}</span>
							<span>{{scope.row.cityName}}</span>
							<span>{{scope.row.regionName}}</span>
						</template>
					</el-table-column>
					<el-table-column label="客户等级" width="70">
						<template slot-scope="scope">
							<span v-if="scope.row.levelName">{{scope.row.levelName}}</span>
							<span v-else>无</span>
						</template>
					</el-table-column>
					<el-table-column label="客户类型" width="70">
						<template slot-scope="scope">
							<p v-if="scope.row.cType == 1">经销商</p>
							<p v-if="scope.row.cType == 2">终端</p>
							<span v-else-if="scope.row.ctype == 3">久加久*久柜</span>
							<span v-else-if="scope.row.ctype == 4">久加久*店中店</span>
							<span v-else-if="scope.row.ctype == 5">久加久*连锁店</span>
						</template>
					</el-table-column>
					<el-table-column label="终端渠道" width="70">
						<template slot-scope="scope">
							<p v-if="scope.row.cType == 1">无</p>
							<p v-if="scope.row.cType ==2">
								<span v-if="scope.row.classifyName1">{{scope.row.classifyName1}}</span>
								<span v-if="scope.row.classifyName2">/{{scope.row.classifyName2}}</span>
								<span v-if="!scope.row.classifyName1">无</span>
							</p>
						</template>
					</el-table-column>
					<el-table-column label="业务员" width="85">
						<template slot-scope="scope">
							<div v-if="scope.row.managerNames">
								<p :title="scope.row.managerNames" v-if="scope.row.managerNames.indexOf(',')>0">{{scope.row.managerNames.split(',')[0]}}...</p>
								<p v-if="scope.row.managerNames.indexOf(',') == -1">{{scope.row.managerNames}}</p>
							</div>
						</template>
					</el-table-column>
					<el-table-column label="来源" width="70">
						<template slot-scope="scope">
							<p v-if="scope.row.cSource == 1">注册商户</p>
							<p v-if="scope.row.cSource == 2">录入商户</p>
						</template>
					</el-table-column>
					<el-table-column label="经纬度">
						<template slot-scope="scope">
							<p>{{scope.row.longitude ? scope.row.longitude : '未添加'}}</p>
							<p>{{scope.row.latitude}}</p>
						</template>
					</el-table-column>
					<el-table-column label="操作" width="70">
						<template slot-scope="scope">
							<p v-if='scope.row.checked' class='cursor color-red' @click="UnbindForId(scope.$index, merchantList1)">解绑</p>
							<p v-else class='cursor color-blue' @click="saleBind(scope.$index, merchantList1)">绑定</p>
						</template>
					</el-table-column>
				</el-table>
				<el-pagination v-if='total2>0' class="tar mr-30" :current-page="currentPage2" :page-size='10' :total='total2' layout="prev , pager, next, jumper" @current-change="handleCurrentChange2">
				</el-pagination>
			</el-dialog>
		</div>
		<!--导出-->
		<form ref="merchantList" :action='api' style="display: none">
			<input type="text" name="startRow" v-model="a">
			<input type="text" name="startCol" v-model="b">
			<input type="text" name="fileName" v-model="fileName">
			<input type="text" name="modelName" v-model="modelName">
			<input v-if='formInlineData.storeName1 && exportType==0' type="text" name="storeName" v-model="formInlineData.storeName1">
			<input v-if='formInlineData.managerName1 && exportType==0' type="text" name="managerName" v-model="formInlineData.managerName1">
			<input v-if='type == 0' type="text" name="type" v-model="e">
			<input v-if='type == 1' type="text" name="type" v-model="f">
			<input v-if='type == 0' type="text" name="sellerId" v-model="g">
			<input v-if='exportType==1' type="text" name="idsStr" v-model="formInlineData.idsStr">
		</form>
	</div>
</template>

<script>
	import { axiosApi, basePath } from "../../api/api"
	export default {
		data() {
			return {
				dataTabs: ['客户', '卖家'],
				Loading: true,
				Loading1: true, //分配商户loading
				Loading2: true, //变更经销商loading
				Loading3: true, //变更业务员loading
				Loading4: true, //批量新增业务员loading
				type: '0',
				merchantList: [], //商户列表
				merchantList1: [], //分配商户列表
				merchantListOne: [], //分配商户列表
				sellerBindBuyers: [{
					sellerId: null,
					buyerId: null
				}],
				indexs: [], //已绑定的商户
				ids: null, //解绑需要的id
				sellerId: null, //卖家id
				buyerId: null, //买家id
				formInlineData: {
					storeName: null,
					storeName1: null,
					idsStr: [],
					managerName: null, //搜索框的业务员名称
					managerName1: null //导出的业务员名称
				},
				currentPage: 1,
				total: '0',
				currentPage1: 1,
				total1: '0',
				currentPage2: 1,
				total2: '0',
				currentPage: 1,
				total3: '0',
				currentPage3: 1,
				total4: '0',
				currentPage4: 1,
				total5: '0',
				currentPage5: 1,
				multipleSelection: [],
				changeSalesman: false,
				dealer: false,
				change: {
					name: ''
				},
				activeName2: 'second',
				dispatchSale: false,
				dialogVisible: false,
				manList: [], //业务员列表
				storeBindManagers: [],
				oldManagerId: null,
				managerId: null,
				buyerId: null,
				dealerList: [], //变更经销商列表
				delarId: null, //变更经销商获取buyerid
				dealerOne: [], //变更经销商获取已绑定的经销商
				idDelar: null, //经销商解绑传值
				buyerList: [], //查询商户（买家）对应卖家列表
				buyer: [], //批量获取商户
				buyers: [], //批量变更业务员获取批量商户buyerId
				changeSalesmanAll: false, //批量变更业务员弹框
				deletes: [],
				deleteList: [], //解绑业务员
				//导出数据
				a: 0,
				b: 0,
				c: 'empty',
				e: 0,
				f: 1,
				g: '',
				api: '', //数据导入切换不同api
				modelName: '',
				fileName: '',
				exportType: 0, //是否选择导出数据
				searchDealer: null, //变更经销商搜索
				searchStore: null, //变更商户搜索
				searchNoName: null, //搜索未关联业务员
				searchInName: null, //搜索已经关联业务员
				buyerIdExclude1:null,//分配买家时候自己的id
				buyerIdExclude2:null,//配置卖家时候自己的id
				ids: [],//删除客户id
				buyerIdList: []//删除客户的进货商id
			}
		},

		methods: {
			//tab切换
			handleTabs(e) {
				if(e.target.attributes.type) this.type = e.target.attributes.type.value
				this.formInlineData.storeName = null
				this.formInlineData.managerName = null
				this.Loading = true
				this.merchantList = []
				this.achieve()
			},
			//导出数据
			exporttable() {
				this.getIdsStr() //获取打钩选择id
				if(this.formInlineData.idsStr.length > 0) {
					this.exportType = 1
				} else {
					this.exportType = 0
				}
				var d = new Date();
				var str = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate();
				if(this.type == 0) {
					this.api = '/sunrise-gateway/export/sales/web/factoryBuyer';
					this.modelName = 'sales.web.factoryBuyer';
					this.fileName = '客户列表_' + str + '.xls'
				} else {
					this.api = '/sunrise-gateway/export/sales/web/factorySeller';
					this.modelName = 'sales.web.factorySeller';
					this.fileName = '卖家列表_' + str + '.xls'
				}
				setTimeout(() => {
					if(this.merchantList.length > 0) {
						setTimeout(() => {
							this.$refs.merchantList.submit()
							this.formInlineData.idsStr = [] //导出成功后清空
						}, 0)
					} else {
						this.$message({
							type: 'info',
							message: '暂无数据可导出!',
							duration: 1000
						});
					}
				}, 0)
			},
			//商户列表
			achieve(bool) {
				if(bool&&this.currentPage != 1) {
					this.currentPage = 1;
					return
				}
				this.g = sessionStorage.traderId
				this.formInlineData.storeName1 = this.formInlineData.storeName
				this.formInlineData.managerName1 = this.formInlineData.managerName
				const api = "/sales/trader/web/selectSimpleListPage";
				let body = null
				if (this.type == 0) {
					body = {
						storeName: this.formInlineData.storeName,
						managerName: this.formInlineData.managerName,
						sellerId: sessionStorage.traderId,
						type: 0
					}
				} else if (this.type == 1) {
					body = {
						storeName: this.formInlineData.storeName,
						type: 1
					}
				}
				axiosApi(api, body, this.callback, this.currentPage, 10)
			},
			callback(res) {
				this.Loading = false
				this.merchantList = res.data.data.elements ? res.data.data.elements : []
				this.total = res.data.data.totalCount
			},
			//分配商户列表 --> 分配买家 分页
			achieveMerchant(bool,row) {
				if(bool&&this.currentPage2 != 1) {
					this.currentPage2 = 1;
					return
				}
				const api = "/sales/trader/web/selectSimpleListPage";
				const body = {
					type: 0,
					// searchType: 2,
					sellerId: sessionStorage.traderId,
					storeName: this.searchStore,
					buyerIdExclude:this.buyerIdExclude1
				}
				axiosApi(api, body, this.callback1, this.currentPage2, 10)
			},
			callback1(res) {
				this.merchantList1 = res.data.data.elements ? res.data.data.elements : []
				this.total2 = res.data.data.totalCount
				this.achieveMerchantOne()
				this.showBind()
			},
			//分配商户列表1 -> 分配买家不分页
			achieveMerchantOne() { 
				const api = "/sales/trader/web/selectList";
				const body = {
					type: 0,
					// searchType: 2,
					sourceTrader: sessionStorage.traderId,
					sellerId: this.sellerId
				}
				axiosApi(api, body, this.callback2)
			},
			callback2(res) {
				this.merchantListOne = res.data.data ? res.data.data : []
				this.getDif()
			},
			//判断是否已经绑定
			getDif() {
				let _this = this
				this.merchantListOne.forEach(function(item, index) {
					_this.merchantList1.forEach(function(item1, index1) {
						if(item.buyerId == item1.buyerId) {
							item1.checked = true
						}
					})
				})
				this.Loading1 = false
			},
			showBind() {
				let _this = this
				this.merchantList1.forEach(function(item, index) {
					if(typeof item.checked == "undefined") {
						_this.$set(item, "checked", false)
					}
				})
			},
			//变更成经销商
			becommerDealer(index, rows) {
				let that = this;
				this.$confirm('您确定要设为经销商？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					const api = "/sales/traderCert/web/insert";
					const body = {
						buyerId: this.merchantList[index].buyerId,
						sellerId: sessionStorage.traderId
					};
					axiosApi(api, body, (res) => {
						this.$message({
							type: 'success',
							message: '操作成功!',
							duration: 1000
						});
						that.achieve();
					}, this.currentPage, 10, (res) => {
						this.$message({
							type: 'info',
							message: res.data.message,
							duration: 3000
						});
					});
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消操作'
					});
				})
			},
			//商户清空经纬度
			deleteRows(index, row) {
				let that = this;
				if(row.accountSource == 2){
					this.$confirm('您是否要清空该商户经纬度？', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						const api = "/sales/traderLngAndLatInfo/delete";
						const body = { traderId: this.merchantList[index].buyerId };
						axiosApi(api, body, (res) => {
							this.$message({
								type: 'success',
								message: '清空成功!',
								duration: 1000
							});
							that.achieve();
						}, this.currentPage, 10, (res) => {
							this.$message({
								type: 'info',
								message: res.data.message,
								duration: 3000
							});
						});
					}).catch(() => {
						this.$message({
							type: 'info',
							message: '已取消清空'
						});
					})
				} else if(row.accountSource == 1){
					this.$alert('请联系平台管理员删除', '提示', {
						type:'warning',
			          	confirmButtonText: '确定',
			          	callback: action => {
			            	//nothing to do
			          	}
			        });
				}
			},
			//删除客户
			deleteMer(index, row){
				this.ids = [];
				this.buyerIdList = [];
				if(row){
					this.ids.push(row.id);
					this.buyerIdList.push(row.buyerId);
				}else {
					this.getIdsStr()
				}
				console.log(this.ids,this.buyerIdList,'ididi')
				if(this.ids.length>0 || this.buyerIdList.length>0) {
					this.deleteEnt();
				} else {
					this.$message({
						type: 'info',
						message: '您未选择任何商户，请先选择！',
						duration: 3000
					});
				}
			},
			deleteEnt(){
				let that = this;
				this.$confirm('确定要删除该商户吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					const api = "/sales/managerBuyer/web/batchDelete";
					const body = { ids: this.ids, buyerIdList: this.buyerIdList };
					axiosApi(api, body, (res) => {
						this.$message({
							type: 'success',
							message: '删除成功!',
							duration: 1000
						});
						that.achieve();
					}, this.currentPage, 10, (res) => {
						this.$message({
							type: 'info',
							message: res.data.message,
							duration: 3000
						});
					});
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				})
			},
			//删除日志
			showLog(){
				this.$router.push(basePath + '/showLog')
			},
			//			分页
			handleCurrentChange(val) {
				this.currentPage = val
				this.achieve()
			},
			handleCurrentChange1(val) {
				this.currentPage1 = val
				this.achieveDealer()
			},
			handleCurrentChange2(val) {
				this.currentPage2 = val
				this.Loading1 = true
				this.achieveMerchant()
			},
			handleCurrentChange3(val) {
				this.currentPage3 = val
				//				this.Loading1 = true
				this.getBuyers()
			},
			handleCurrentChange4(val) {
				this.currentPage4 = val
				//				this.Loading1 = true
				this.getMans()
			},
			//			},
			//商户绑定
			saleBind(index) {
				this.sellerBindBuyers[0].buyerId = this.merchantList1[index].buyerId
				let that = this;
				this.$confirm('您是否要绑定该商户？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.Loading1 = true
					const api = "/sales/sellerbuyer/web/bind";
					const body = {
						sellerBindBuyers: this.sellerBindBuyers
					};
					axiosApi(api, body, (res) => {
						this.$message({
							type: 'success',
							message: '绑定成功!',
							duration: 1000
						});
						that.achieveMerchant();
						that.type = 1
						that.achieve()
					}, this.currentPage, 10, (res) => {
						this.$message({
							type: 'info',
							message: res.data.message,
							duration: 3000
						});
					});
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消绑定'
					});
				})
			},
			//			商户与经销商关系解绑寻找id
			UnbindForId(index) {
				let _this = this
				let ids = this.merchantList1[index].buyerId
				this.merchantListOne.forEach(function(item, index) {
					if(ids == item.buyerId) {
						_this.ids = item.id
					}
				})
				this.saleUnbind()
			},
			//解绑商户
			saleUnbind() {
				let that = this;
				this.$confirm('您是否要解绑该商户？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.Loading1 = true
					const api = "/sales/sellerbuyer/web/unBind";
					const body = {
						ids: [this.ids]
					};
					axiosApi(api, body, (res) => {
						this.$message({
							type: 'success',
							message: '解绑成功!',
							duration: 1000
						});
						that.achieveMerchant();
						that.type = 1
						that.achieve()
					}, this.currentPage, 10, (res) => {
						this.$message({
							type: 'info',
							message: res.data.message,
							duration: 3000
						});
					});
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消解绑'
					});
				})
			},
			//查询商户对应业务员列表
			getMans(bool) {
				if(bool&&this.currentPage4 != 1) {
					this.currentPage4 = 1;
					return
				}
				axiosApi("/sales/managerSale/selectList", {
					sellerId: sessionStorage.traderId,
					buyerId: this.buyerId,
					name: this.searchNoName
				}, (res) => {
					this.manList = res.data.data.elements ? res.data.data.elements : [];
					this.total4 = res.data.data.totalCount
					this.total5 = res.data.data.totalCount
					this.Loading3 = false
				}, this.currentPage4, 10);
			},
			//			查询商户（买家）对应卖家列表
			getBuyers(bool) {
				if(bool&&this.currentPage3 != 1) {
					this.currentPage3 = 1;
					return
				}
				axiosApi("/sales/buyerManager/web/selectList", {
					buyerId: this.buyerId,
					name: this.searchInName
				}, (res) => {
					this.buyerList = res.data.data.elements ? res.data.data.elements : [];
					this.total3 = res.data.data.totalCount
					this.getMans()
				}, this.currentPage3, 10);
			},
			//变更业务员全选
			handleSelectionChange(val) {
				this.multipleSelection = val
			},
			//解绑业务员全选
			handleSelectionDelete(val) {
				this.deletes = val
			},
			manDeletesId() {
				this.storeBindManagers = []
				this.deleteList = []
				let _this = this
				this.deletes.forEach(function(item) {
					_this.deleteList.push(item.managerId)
				})
				this.manDeletes()
			},
			manDeletes() {
				this.storeBindManagers = []
				let _this = this
				if(this.deleteList.length > 0) {
					this.deleteList.forEach(function(item, index) {
						let obj = {}
						obj.sellerId = sessionStorage.traderId
						obj.buyerId = _this.buyerId
						obj.managerId = item
						_this.storeBindManagers.push(obj)
					})
					// this.changeSalesman = false
					this.manDelete()
				} else {
					this.$message({
						type: 'info',
						message: '您未选择已关联的业务员，请先选择！',
						duration: 3000
					});
				}
			},
			//变更业务员获取id
			getIds() {
				this.storeBindManagers = []
				let _this = this
				if(this.multipleSelection.length > 0) {
					this.multipleSelection.forEach(function(item, index) {
						let obj = {}
						obj.sellerId = sessionStorage.traderId
						obj.buyerId = _this.buyerId
						obj.oldManagerId = _this.oldManagerId
						obj.managerId = item.managerId
						_this.storeBindManagers.push(obj)
					})
					// this.changeSalesman = false
					this.manChange()
				} else {
					this.$message({
						type: 'info',
						message: '您未选择未关联的业务员，请先选择！',
						duration: 3000
					});
				}
			},
			//变更业务员
			manChange() {
				let that = this;
				this.$confirm('您确定要新增业务员？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					const api = "/sales/managerbuyer/web/update";
					const body = { storeBindManagers: this.storeBindManagers };
					axiosApi(api, body, (res) => {
						that.changeSalesman = false
						that.changeSalesmanAll = false
						this.$message({
							type: 'success',
							message: '新增成功!',
							duration: 1000
						});
						that.achieve();
					}, this.currentPage, 10, (res) => {
						this.$message({
							type: 'info',
							message: res.data.message,
							duration: 3000
						});
					});
				}).catch(() => {
					this.changeSalesman = false
					this.changeSalesmanAll = false
					this.$message({
						type: 'info',
						message: '已取消新增'
					});
				})
			},
			//业务员解绑
			manDelete() {
				let that = this;
				this.$confirm('您确定要解绑该业务员？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					const api = "/sales/managerBuyer/web/delete";
					const body = { storeBindManagers: this.storeBindManagers };
					axiosApi(api, body, (res) => {
						this.changeSalesman = false
						this.$message({
							type: 'success',
							message: '解绑成功!',
							duration: 1000
						});
						that.achieve();
					}, this.currentPage, 10, (res) => {
						this.$message({
							type: 'info',
							message: res.data.message,
							duration: 3000
						});
					});
				}).catch(() => {
					this.changeSalesman = false
					this.$message({
						type: 'info',
						message: '已取消解绑'
					});
				})
			},
			//变更业务员按钮
			changeName(index, rows) {
				this.oldManagerId = this.merchantList[index].managerId //获取旧业务员id
				this.buyerId = this.merchantList[index].buyerId
				this.currentPage4 = 1
				this.searchInName = null
				this.searchNoName = null
				this.changeSalesman = true
				this.getBuyers()
			},
			//全选商户
			handleSelectionAll(val) {
				this.buyer = val
			},
			getIdsStr() {
				let _this = this
				this.buyer.forEach(function(item, index) {
					_this.formInlineData.idsStr.push(item.id)
					_this.ids.push(item.id)//批量删除客户的id
					_this.buyerIdList.push(item.buyerId)//批量删除客户的进货商id
				})
			},
			getAllMerchant() {
				let _this = this
				if(this.buyer.length == 0) {
					this.$message({
						type: 'info',
						message: '您未选择任何商户，请先选择！',
						duration: 3000
					});
				} else {
					this.currentPage4 = 1
					this.searchNoName = null
					this.changeSalesmanAll = true
					this.getMans()
					this.Loading4 = false
					this.buyer.forEach(function(item) {
						_this.buyers.push(item.buyerId)
					})
				}
			},
			//变更业务员获取id
			getMoreIds() {
				this.storeBindManagers = []
				let _this = this
				if(this.multipleSelection.length > 0) {
					_this.buyers.forEach(function(item) {
						_this.multipleSelection.forEach(function(item1) {
							let obj = {}
							obj.sellerId = sessionStorage.traderId
							obj.buyerId = item
							obj.oldManagerId = null
							obj.managerId = item1.managerId
							_this.storeBindManagers.push(obj)
						})
					})
					// this.changeSalesman = false
					this.manChange()
				} else {
					this.$message({
						type: 'info',
						message: '您未选择任何业务员，请先选择！',
						duration: 3000
					});
				}
			},
			//变更经销商按钮 --> 配置卖家按钮
			changeDealer(index, row) {
				this.dealer = true
				this.buyerIdExclude2 = row.buyerId // 配置卖家时候剔除自己
				this.achieveDealer()
				this.delarId = this.merchantList[index].buyerId
				this.sellerBindBuyers[0].buyerId = this.merchantList[index].buyerId
				this.currentPage1 = 1
			},
			//变更经销商列表 -- > 配置卖家
			achieveDealer(bool,row) {
				if(bool) this.currentPage1 = 1;
				const api = "/sales/trader/web/selectSimpleListPage";
				const body = {
					type: 1,
					// searchType: 2,
					storeName: this.searchDealer,
					buyerIdExclude:this.buyerIdExclude2
				}
				axiosApi(api, body, this.achieveDealerr, this.currentPage1, 10)
			},
			achieveDealerr(res) {
				this.dealerList = res.data.data.elements ? res.data.data.elements : []
				this.total1 = res.data.data.totalCount
				this.Loading = false
				this.getDealer()
				this.showdelar()
			},
			//获取已绑定的经销商
			getDealer() {
				axiosApi("/sales/buyerSeller/web/selectAll", { buyerId: this.delarId }, (res) => {
					this.dealerOne = res.data.data ? res.data.data : []
					this.getDifferent()
				});
			},
			showdelar() {
				let _this = this
				this.dealerList.forEach(function(item, index) {
					if(typeof item.checked == "undefined") {
						_this.$set(item, "checked", false)
					}
				})
			},
			//判断是否已绑定
			getDifferent() {
				let _this = this
				this.dealerOne.forEach(function(item, index) {
					_this.dealerList.forEach(function(item1, index1) {
						if(item.sellerId == item1.buyerId) {
							item1.checked = true
						}
					})
				})
				this.Loading2 = false
			},
			//经销商绑定
			delarBind(index) {
				this.sellerBindBuyers[0].sellerId = this.dealerList[index].buyerId
				let that = this;
				this.$confirm('您是否要绑定该经销商？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.Loading2 = true
					const api = "/sales/sellerbuyer/web/bind";
					const body = {
						sellerBindBuyers: this.sellerBindBuyers
					};
					axiosApi(api, body, (res) => {
						this.$message({
							type: 'success',
							message: '绑定成功!',
							duration: 1000
						});
						that.achieveDealer();
					}, this.currentPage, 10, (res) => {
						this.$message({
							type: 'info',
							message: res.data.message,
							duration: 3000
						});
					});
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消绑定'
					});
				})
			},
			//经销商解绑获取解绑id
			getDelarId(index, rows) {
				let _this = this
				this.dealerOne.forEach(function(item) {
					if(_this.dealerList[index].buyerId == item.sellerId) {
						_this.idDelar = item.id
					}
				})
				this.delarFree()
			},
			//经销商解绑
			delarFree() {
				let that = this;
				this.$confirm('您是否要解绑该经销商？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.Loading2 = true
					const api = "/sales/sellerbuyer/web/unBind";
					const body = {
						ids: [this.idDelar]
					};
					axiosApi(api, body, (res) => {
						this.$message({
							type: 'success',
							message: '解绑成功!',
							duration: 1000
						});
						that.achieveDealer();
					}, this.currentPage, 10, (res) => {
						this.$message({
							type: 'info',
							message: res.data.message,
							duration: 3000
						});
					});
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消解绑'
					});
				})
			},
			//分配商户按钮 --> 改为分配买家按钮
			dispatch(index,row) {
				this.Loading1 = true //解绑/绑定状态切换
				this.sellerBindBuyers[0].sellerId = this.merchantList[index].buyerId
				this.sellerId = this.merchantList[index].buyerId
				this.buyerIdExclude1 = row.buyerId // 分配买家时候剔除自己的字段
				this.searchStore = null
				this.dispatchSale = true //弹窗打开
				this.achieveMerchant()
				this.currentPage2 = 1
			}
		},
		//进入加载
		activated() {
			this.$nextTick(function() {
				this.achieve();
			})
		}
	}
</script>

<style lang="less">
	#businessList {
		padding-bottom: 50px;
		.cell {
			padding-left: 0;
			padding-right: 0;
			text-align: center;
		}
		.el-tabs__item {
			height: 30px;
		}
		.el-tabs__active-bar {
			display: none;
		}
		.is-active {
			border-bottom: 2px solid;
		}
		.el-dialog {
			min-height: 300px;
			min-width: 500px;
		}
		.el-dialog {
			width: 1000px;
			min-height: 600px;
		}
		.mt-15 {
			margin-top: 15px;
		}
		.el-dialog__body {
			padding-top: 10px;
		}
		.cell .el-button {
			width: auto;
		}
		.color-red {
			color: red;
		}
	}
</style>