<template>
	<div id="commodityStocks" class="pb-50" v-loading="Loading">
		<div class="title-top bg-f">
			<span>库存管理</span>
			<div class="fr" style="margin-right:20px">
				<el-upload
          class="dib"
          action="/sunrise-gateway/import/warehouse/stock/alert"
          :show-file-list="false"
          :on-success="handUpSuccess"
          :before-upload="handUpBefore"
          :on-error="handUpError"
          accept=".xls,.xlsx"
        >
					<el-link type="primary" :underline="false" :loading="upLoading">库存预警批量导入</el-link>
        </el-upload>
				
				<el-link type="primary" href="https://sunrise-cbs.oss-cn-hangzhou.aliyuncs.com/template/jiujiajiu/b2b/%E5%95%86%E5%93%81%E5%BA%93%E5%AD%98%E9%A2%84%E8%AD%A6%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx" :underline="false">库存预警导入模板</el-link>
        
			</div>
		</div>
		<div class="bg-f mt-5 pb-50 ">
			<el-form :inline="true" :model="formInlineData" label-width="90px" class="clearfix pl-20 pt-30 search">
                <el-form-item label="商品编码">
                    <el-input v-model.trim="formInlineData.goodsNo" @input="formInlineData.goodsNo=formInlineData.goodsNo.replace(/[\u4e00-\u9fa5]/ig,'')"></el-input>
                </el-form-item>
                <el-form-item label="商品名称">
                    <el-input v-model.trim="formInlineData.skuName"></el-input>
                </el-form-item>
                <el-form-item label="商品分类">
                    <el-select v-model="formInlineData.id" clearable>
                        <el-option :label='item.classifyName' :value="item.id" v-for='item in searchClassify' :key='item.id'></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="商品状态">
                    <el-select v-model="formInlineData.skuState" placeholder="输入状态" clearable>
                        <!--<el-option label="全部状态" value=""></el-option>-->
                        <el-option label="待上架" value='1'></el-option>
                        <el-option label="出售中" value='2'></el-option>
                    </el-select>
                </el-form-item>
			</el-form>
            <div class="pb-30 pl-30">
                <el-button type="info" icon="el-icon-search" @click.native='achieve(true)'>查询</el-button>
            </div>
			<!--<div class="bbd clearfix ml-30 mr-30 bg-f">-->
				<!--<el-button class="edit-btn">同步JDE</el-button>-->
				<!--<el-button class="edit-btn mt-30" type='text'>同步JDE</el-button>-->
				<!--<el-button class="edit-btn mt-30" type='text'>批量导出</el-button>-->
			<!--</div>-->
			<!-- <el-table v-loading="Loading" :data="tableData" tooltip-effect="dark" class="ml-30 mr-30 mt-20 table-default" stripe> -->
				<!--<el-table-column type="selection" width="50"></el-table-column>-->
				<!-- <el-table-column prop="1" label="商品名称" width="290">
					<template slot-scope="scope">
						<div class="fl ml-10">
							<img v-if='scope.row.fileUrl' :src="scope.row.fileUrl" />
							<img v-else src="../../assets/goods.jpg" />
						</div>
						<div class="fl w160 ml-10 mt-10">
							<div class="tal">商品编码:{{scope.row.goodsNo}}</div>
							<div class="tal">{{scope.row.skuName}}</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column label="规格" width="75">
					<template slot-scope="scope">
						<div>1*{{scope.row.specInfoNum}}</div>
					</template>
				</el-table-column>
				<el-table-column label="商品分类" width="90">
					<template slot-scope="scope">
						<div>{{scope.row.classifyName}}</div>
					</template>
				</el-table-column>
				<el-table-column label="商品品牌" width="90">
					<template slot-scope="scope">
						<div>{{scope.row.brandName}}</div>
					</template>
				</el-table-column>
				<el-table-column label="商品状态" width="80">
					<template slot-scope="scope">
						<span v-if="scope.row.skuState == '1'">待上架</span>
						<span v-if="scope.row.skuState == '2'">出售中</span>
					</template>
				</el-table-column>
				<el-table-column label="可用库存" width="90">
					<template slot-scope="scope">
						<div>{{scope.row.stockEnableQuantity}}</div>
					</template>
				</el-table-column>
				<el-table-column label="库存" width="90">
					<template slot-scope="scope">
						<div>{{scope.row.stockQuantity}}</div>
					</template>
				</el-table-column>
				<el-table-column label="操作">
					<template slot-scope="scope">
						<el-button type="text" class='font-12 width-90' @click="handleSynJDE(scope)">{{erpType === 1 ? '同步JDE' : erpType === 2 ? '同步K3' : '编辑库存' }}</el-button>
					</template>
				</el-table-column>
			</el-table> -->
			<div style="text-align:right;margin:10px 40px;">
				<el-link type="primary" :underline="false" @click="exportList">即时库存导出</el-link>
			</div>
			<div class="pl-30 pr-30 content">
				<div class="order-list">
					<el-table>
						<el-table-column label='商品名称' min-width="258" align="center"></el-table-column>
						<el-table-column label='规格' min-width="86"></el-table-column>
						<el-table-column label='商品分类' min-width="86"></el-table-column>
						<el-table-column label='销售组织号' min-width="86"></el-table-column>
						<el-table-column label='仓库名称' min-width="86"></el-table-column>
						<el-table-column label='库存(基本)' min-width="86"></el-table-column>
						<el-table-column label='库存(规格)' min-width="86"></el-table-column>
						<el-table-column label='库存预警' min-width="86"></el-table-column>
						<el-table-column label='操作' align="center" min-width="86"></el-table-column>
					</el-table>
					<div class="table-item-default tac" v-if="tableData.length<=0">
						<img src="../../assets/empty.png" alt="" />
						<div class="color-danger mt-30">
							<router-link :to="{path:'/customer/shop/shopIndex'}"><span class="color-danger"> 啥都没有，去首页看看 ></span></router-link>
						</div>
					</div>
					<div v-else>
						<div class="table-item mt-10" v-for='(goods, index) in tableData' :key='index'>
							<div class="table-item-content clearfix bbd" style="display: flex;" >
								<div class=" clearfix" style="box-sizing: border-box;flex:3;align-items: center;justify-content: center" >
									<!--商品清单-->
									<div class=" clearfix" style="padding: 20px;box-sizing: border-box;display: flex">
										<div style="width: 80px"  class="mr-10">
											<img v-if='goods.fileUrl' :src="goods.fileUrl" />
											<img v-else src="../../assets/goods.jpg" />
										</div>
										<div class="" style="flex: 1">
											<p class="color-9" style="word-break:break-all;word-wrap:break-word">商品编码:{{goods.goodsNo}}</p>
											<p class="" style="word-break:break-all;word-wrap:break-word">{{goods.skuName}}</p>
										</div>
									</div>
								</div>
								<div class="bl  tac " style="word-break: break-all;flex:1;align-items: center;justify-content: center;display:flex">1*{{goods.specInfoNum}}</div>
								<div class="bl  tac " style="word-break: break-all;flex:1;align-items: center;justify-content: center;display:flex">{{goods.classifyName}}</div>
								<div class="bl  tac" style="word-break: break-all;flex-direction:column;justify-content: space-between;flex:4;display:flex">
                                    <div :class="{bb: i !=goods.warehouseList.length-1 , 'tac': true}" v-for="(v,i) in goods.warehouseList" :key="'j'+i" style="display:flex;height:100%;">
                                        <div class=" tac fl" style="flex:1;word-break: break-all;height:100%;align-items: center;justify-content: center;display:flex">{{v.companyNo}}</div>
                                        <div class="bl tac fl"  style="flex:3;word-break: break-all;height: 100%;flex-direction:column;display: flex;justify-content: space-between;height:100%">
                                            <div :class="{bb: j != v.list.length-1}" v-for="(data,j) in v.list" :key="j+'j'" style="display: flex;flex: 1">
                                                <div class="tac" style="flex:1;word-break: break-all;display: flex;align-items: center;justify-content: center">{{data.name}}</div>
                                                <div class="bl  tac" style="flex:1;word-break: break-all;display: flex;align-items: center;justify-content: center">{{data.stock}}</div>
                                                <div class="bl tac" style="flex:1;word-break: break-all;display: flex;align-items: center;justify-content: center">{{data.specStock}}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
								<div class="bl  tac " style=" word-break: break-all;flex:1;flex-direction:column;align-items: center;justify-content: center;display:flex">
									 <el-input-number v-model="goods.skuStockAlertQuantity"  :controls="false"  :min="0" @blur="handleChange(goods.skuNo,goods.skuStockAlertQuantity)"></el-input-number>
									 <!-- <p style="color:red">0为不预警</p> -->
									<!-- <el-input v-model.trim="goods.skuStockAlertQuantity" style="max-width:80px" :controls='false' @input="goods.skuStockAlertQuantity=goods.skuStockAlertQuantity.replace(/\D/g,'')" @blur="handleChange(goods.skuNo,goods.skuStockAlertQuantity)"></el-input> -->
								</div>
								<div class="bl  tac" style="flex: 1;" >
									<div style="height:100%;display: flex;align-items: center;justify-content: center">
										<el-button v-if="erpType === 1" type="text" class='font-12 width-90' @click="handleSynJDE(goods)"> 同步JDE </el-button>
										<el-button v-if="erpType === 2||erpType===4" type="text" class='font-12 width-90' @click="handleSynJDE(goods)"> 同步K3 </el-button>
										<el-button v-if="erpType===0 && goods.skuSetInfoVOList.length === 0" type="text" class='font-12 width-90' @click="handleSynJDE(goods)">编辑库存</el-button>
									</div>
								</div>

							</div>
						</div>
					</div>
					<el-pagination class="tar mr-30 mt-20" :current-page="currentPage" v-if="total>0" :page-size='10' :total='total' layout="prev , pager, next, jumper" @current-change="handleCurrentChange">
						<!-- 分页组件 -->
					</el-pagination>
				</div>
			</div>
		</div>

		<!--JDE修改库存-->
		<el-dialog title="修改库存" :visible.sync="jde">
			<el-form :inline="true" :model="form2" class="clearfix boom" :rules="rules" ref="form2">
				<el-form-item label="库存:">
					<el-input size='large' v-model.trim="form2.allNum" disabled></el-input>
				</el-form-item>
				<el-form-item label="可用库存:">
					<el-input size='large' v-model.trim="form2.haveNum" disabled></el-input>
				</el-form-item>
				<el-form-item label="出/入库:" prop='goodsNum'>
					<el-input size='large' v-model.trim="form2.goodsNum"></el-input>
				</el-form-item>
			</el-form>
			<span class='hint font-12'>出库请输入负数</span>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" @click='updateNum("form2")'>确 定</el-button>
				<el-button @click="jde=false">取 消</el-button>
			</div>
		</el-dialog>

		<form
      ref="downClientForm1"
      action="/sunrise-gateway/export/warehouse/commodityStock"
      style="display: none"
    >
      <input type="hidden" name="modelName" v-model="a" />
      <input type="hidden" name="fileName" v-model="b" />
      <input type="hidden" name="sellerId" v-model="c" />
      <input type="hidden" name="startRow" v-model="d" />
      <input type="hidden" name="goodsNo" v-model="formInlineData.goodsNo" />
			<input type="hidden" name="skuName" v-model="formInlineData.skuName" />
			<input type="hidden" name="classifyId" v-model="formInlineData.id" />
			<input type="hidden" name="skuState" v-model="formInlineData.skuState" />
			<input type="hidden" name="auditState" v-model="formInlineData.auditState" />
    </form>
	</div>
</template>

<script>
	import { axiosApi, basePath } from "../../api/api";
	import { parseTime } from "~/utils";
	export default {
		data() {
			var validateNum = (rule, value, callback) => {
				if(parseInt(this.form2.haveNum) + parseInt(value) < 0 || parseInt(this.form2.haveNum) + parseInt(value) > 2147483647) {
					callback(new Error('您的输入超出库存正常限定'));
				} else {
					callback();
				}
			};
			return {
				upLoading: false,
				erpType:null, //0:未使用erp，1:jde,2:k3
				jde: false, //同步jde？编辑库存
				formInlineData: { name: '', goodsNo: '', skuName: '', classifyName: '', classifyId: '', id: '', skuState: '', auditState:2 },
				tableData: [], //列表
				//loading: true,
				pageLoading: true,
				//分页
				currentPage: 1,
				total: 0,
				isPagination: true,
				Loading: true,
				searchClassify: [], //搜索分类
				multipleSelection: [], //选中所有行
				//isOpenJde: 0,
				//isOpenK3: false,
				goosNo: '', //修改库存专用skuNo
				form2: {
					goodsNum: null,
					haveNum: null,
					allNum: null
				},
				rules: {
					goodsNum: [
						{ pattern: /^-?[1-9]\d{0,8}$/, message: '输入值只能为非0的整数,且不超过9位' },
						{ required: true, message: '输入值不能为空', trigger: 'blur' },
						{ validator: validateNum, trigger: 'blur' }
					]
				},
				a: "empty",
				b: '即时库存'+ parseTime(new Date()) + '.xlsx',
				c: sessionStorage.getItem("traderId"),
				d: 0,
			}
		},
		methods: {
			exportList(){
				this.b = '即时库存'+ parseTime(new Date()) + '.xlsx',
				setTimeout(() => {
					this.$refs.downClientForm1.submit();
				}, 0);
			},
			handUpBefore(file) {
				this.upLoading = true;
				//   this.$loading({
				//     fullscreen: false,
				//     lock: true,
				//     text: "正在导入..."
				//   });

				//   return true;
			},
			handUpSuccess(res, file, fileList) {
				// if(res)
				this.upLoading = false
				console.log(res,'rs')
				switch (res.code) {
					case "000000": //系统交易成功
						if(res.data){
							var str = ''
							res.data.forEach(item=>{
								str += `<p>${item}</p>`
							})
							this.$alert(str, "提示信息", {
								confirmButtonText: "确定",
								dangerouslyUseHTMLString: true
							});
						}else{
							this.$message({
								message: '导入成功！',
								type: 'success'
							});
							this.achieve();
						}
						
						break;
					case "AUTH_001": //用户未登陆
					case "LOGIN_006": //用户session失效
						gotoLoginPage();
						break;
					default:
						this.$alert(res.message, "提示信息", {
							confirmButtonText: "确定",
							
						});
						break;
				}
			},
			handUpError(err, file, fileList) {
			//   this.$loading().close();
				this.$message.error("导入失败！");
			},
			handleChange(skuNo,stockAlertQuantity = 0){
				const api = "/warehouse/stock/alert/editSkuStockAlertQuantity";
				const body = {
					skuNo,
					stockAlertQuantity
				}
				axiosApi(api, body, (res)=>{
					if(res.data.code==='000000'){
						this.Loading = true
						this.achieve()
					}
				}, null, null)
			},
			//加载商品列表
			achieve(bool) {
				if(bool&&this.currentPage != 1) {
					this.currentPage = 1;
					return
				}
				const api = "/goods/skuInfoPage/select";
				const body = {
					sellerId: sessionStorage.traderId,
					goodsNo: this.formInlineData.goodsNo,
					skuName: this.formInlineData.skuName,
					classifyId: this.formInlineData.id,
					skuState: this.formInlineData.skuState,
					auditState: 2
				}
				axiosApi(api, body, this.callback, this.currentPage, 10)
			},
			callback(res) {
				let data = res.data.data.elements ? res.data.data.elements : [];
				this.total = res.data.data.totalCount ? res.data.data.totalCount : '';
				data.forEach(v=>{
						let arr = []
						if (v.warehouseMap){
								Object.keys(v.warehouseMap).forEach(function(key){
										let obj = {
												companyNo : key,
												list: v.warehouseMap[key]
										}
										arr.push(obj)
								});
								v.warehouseList = arr
						} else{
								v.warehouseList = []
						}

				})
				this.tableData = data;
				this.Loading = false
			},
			//判断是否有jde权限
			isJde() {
				const api = "/trader/findSellerJurisdiction/select";
				const body = {
					traderId: sessionStorage.traderId
				}
				axiosApi(api, body, (res)=>{
					this.erpType = res.data.data.erpType //0:未使用erp，1:jde,2:k3
//					this.isOpenJde = res.data.data.isOpenJde
//					this.isOpenK3 = res.data.data.k3SalesCompanyNo ? true : false
				}, this.currentPage, 10)
			},
			//			分页
			handleCurrentChange(val) {
				this.currentPage = val
				this.achieve()
			},
			//查询分类
			classSearch() {
				const api = "/goods/classifyTopList/select";
				const body = {
					classificationId: ''
				}
				axiosApi(api, body, this.classsearch)
			},
			classsearch(res) {
				let data = res.data.data;
				this.searchClassify = data;
			},
			//同步JDE/K3
			handleSynJDE(scope) {
				//0:未使用erp，1:jde,2:k3 "erpType"
				if(!this.erpType) { //既没有开通 jde 也没有开通 k3
					this.goosNo = scope.skuNo
					this.form2.goodsNum = null
					this.jde = true
					this.form2.allNum = scope.stockQuantity
					this.form2.haveNum = scope.stockEnableQuantity
				} else{ //可能开通 jde和k3
					const title = this.erpType === 1 ? 'JDE' : 'K3';
					this.$confirm('是否确认同步'+ title +'？', '提示信息', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						const api = this.erpType === 1 ?  "/goods/sku/stockBySkuNo/jdeSync" : '/goods/sku/stockBySkuNo/k3Sync';
						const body = { skuNo: scope.skuNo };
						axiosApi(api, body, (res) => {
							this.$message({
								type: 'success',
								message: '同步'+title+'成功!',
								duration: 1000
							});
							this.achieve();
						}, 1, 10, (res) => {
							this.$message({
								type: 'info',
								message: res.data.message,
								duration: 1000
							});
						})
					}).catch(() => {
						this.$message({
							type: 'info',
							message: '已取消同步'
						});
					})
				}
			},
			//编辑库存
			updateNum(form2) {
				this.$refs[form2].validate((valid) => {
					if(valid) {
						this.jde = false
						setTimeout(() => {
							this.$confirm('是否确认修改库存？', '提示信息', {
								confirmButtonText: '确定',
								cancelButtonText: '取消',
								type: 'warning'
							}).then(() => {
								const api = "/goods/sku/stockBySkuNo/update";
								const body = {
									skuNo: this.goosNo,
									stock: parseInt(this.form2.goodsNum)
								};
								axiosApi(api, body, (res) => {
									this.$message({
										type: 'success',
										message: '修改成功!',
										duration: 1000
									});
									this.achieve();
								}, this.currentPage, 10, (res) => {
									this.$message({
										type: 'info',
										message: res.data.message,
										duration: 1000
									});
								})
							}).catch(() => {
								this.$message({
									type: 'info',
									message: '已取消修改'
								});
							})
						}, 400)
					} else {
						return false;
					}
				});

			},
		}, //进入加载
		activated() {
			this.$nextTick(function() {
				this.isJde();
				
				this.classSearch();

			})
		},
		created(){
			this.achieve();
		},
	}
</script>

<style lang="less">
	#commodityStocks {
		.el-form-item__label {
			font-size: 12px;
			width: 160px;
		}
		.w160 {
			width: 160px;
		}
		.el-tabs__header {
			padding-left: 0;
		}
		.edit-btn {
			margin-right: 0;
			margin-left: 0;
		}
		.el-form-item__error {
			width: 240px;
		}
		.el-dialog {
			min-height: 450px;
		}
		.hint {
			color: red;
			position: absolute;
			right: 7%;
			top: 53%;
		}
		.content {
			.el-table .el-table__body-wrapper {
				display: none;
			}
			.order-datatimerange {
				width: 240px;
			}
			.el-table{
				border: none;font-size: 14px;
				th>.cell{line-height: 12px; padding: 10px 0 10px 0;text-align: center;}
				th.is-leaf{
					border-bottom: 0;
				}
			}
			.checkedAll {
				position: relative;
				top: 30px;
			}
			.el-checkbox{
				width: 70px;
				margin-left: 30px;
				.el-checkbox__input{height: 20px;}
			}
			.el-checkbox__inner{width: 20px;height: 20px;}
			.el-checkbox__inner::after {top: 2px;}
			.table-item {
				.bl {border-left: 1px solid #DDD;}
                .bb {border-bottom: 1px solid #DDD;}
				border: 1px solid #DDDDDD;
				font-size: 14px;
				border-bottom: none;
				.table-item-content .pbl-20 {
					padding: 0px 0px 20px 20px;
				}
				.table-item-top {
					padding: 10px 0;
					.table-item-top-checkbox {
						margin-left: 14px
					}
				}
				img {
					width: 80px;
					height: 80px;
                    float: left;
				}
			}
			.w100{
				width: 100px;
			}
			.bottom_content{
				height: 30px;
				line-height: 30px;
				padding-left: 15px;
				border-bottom: 1px solid #dddddd;
			}
		}
	}
</style>
