<template>
    <div class="marginExtraction-box">
        <div class="title-top bg-f">
            <tabsCheck @handleClick="handleTabsClick" :list="tabsList"></tabsCheck>
        </div>
        <div class="marginExtraction-content bg-f mt-5">
            <div class="search pt-30 flex">
                <div class="flex lh-32">
                    <span class="ml-20">申请时间：</span>
                    <el-date-picker
                      v-model="startTime"
                      :style="{width: '130px'}"
                      type="date"
                      placeholder="请选择"
                    >
                    </el-date-picker>
                    <span>&nbsp;至&nbsp;</span>
                    <el-date-picker
                      v-model="endTime"
                      :style="{width: '130px'}"
                      type="date"
                      placeholder="请选择"
                    >
                    </el-date-picker>
                </div>
                <div class="flex lh-32">
                    <span class="ml-30">久集账号：</span>
                    <el-input placeholder="请输入久集账号" v-model.trim="name" />
                </div>

                <el-button @click="handleSearch" class="el-button--info ml-30" icon="el-icon-search">搜 索</el-button>
                <div v-if="tabs === '0'">
                    <el-button class="fr" @click="handleAllOk" type="text">批量确定</el-button>
                </div>
            </div>
            <div class="c-content">
                <!-- <el-tabs type="card" v-model="tabs" @tab-click="handleTabsClick">
                    <el-tab-pane label="未打款" name="0"></el-tab-pane>
                    <el-tab-pane label="已打款" name="1"></el-tab-pane>
                </el-tabs> -->
                <div class="c-table">
                    <el-table @selection-change="handleSelectionChange" style="width: 100%" :data="tableList" border tooltip-effect="dark" class="mt-20 table-default">
                        <el-table-column
                        type="selection"
                        v-if="tabs === '0'"
                        width="40">
                        </el-table-column>
                        <el-table-column :resizable="false" prop="loginName" label="账号"></el-table-column>
                        <el-table-column :resizable="false" prop="name" label="姓名"></el-table-column>
                        <el-table-column :resizable="false" prop="storeName" label="店铺名"></el-table-column>
                        <el-table-column :resizable="false" prop="withdrawMoney" label="提取金额"></el-table-column>
                        <el-table-column :resizable="false" prop="bankName" label="银行卡"></el-table-column>
                        <el-table-column :resizable="false" width="150" prop="bankCardNo" label="卡号"></el-table-column>
                        <el-table-column :resizable="false" prop="userName" label="持卡人姓名"></el-table-column>
                        <el-table-column :resizable="false" width="130" prop="gmtCreate" label="申请时间">
                            <template slot-scope="scope">
                                {{scope.row.gmtCreate | time}}
                            </template>
                        </el-table-column>
                        <el-table-column :resizable="false" prop="storeName" label="操作">
                            <template slot-scope="scope">
                                <template v-if="scope.row.state === 0">
                                    <el-button :style="{width : 'auto'}" @click="handleConfirmMoney(scope.row)" type="text">确认打款</el-button>
                                </template>
                                <template v-else>
                                    <span class="el-icon-circle-check handleSuccess"></span>
                                    <span>已打款</span>
                                </template>
                            </template>
                        </el-table-column>
                    </el-table>
                    <!-- 分页 -->
                    <div class="pageBox">
                        <el-pagination class="tar mt-20" v-if='total>0'
                            :current-page="currentPage"
                            :page-size='10'
                            :total='total'
                            layout="prev, pager, next, jumper"
                            @current-change="handlePageChange">
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { axiosApi, basePath } from "../../api/api";
    import tabsCheck from '../../components/tabsCheck.vue';
    export default {
        components : {tabsCheck},
        data() {
            return {
                startTime : '', // 申请时间
                endTime : '', // 结束时间
                name : '', // 久集账号
                startTimeDefault : '', // 点击搜索后 在赋值
                endTimeDefault : '',
                nameDefault : '',
                tabsList : [
                    {text : '未打款',active : true,value : '0'},
                    {text : '已打款',value : '1'}
                ],
                tabs : '0',
                total : 0, // 总数量
                currentPage : 1, // 当前页数
                tableList : [], // 列表数据
                multipleSelection : [], //列表多选 数据
            }
        },
        mounted() {
            this.initTableList2();
        },
        methods : {
            // 表格 init
            initTableList2() {
                axiosApi('/superc/bond/apply/select',{
                    loginName : this.nameDefault,
                    startTime : this.startTimeDefault ? + new Date(this.startTimeDefault) : '',
                    endTime : this.endTimeDefault ? + new Date(this.endTimeDefault) : '',
                    state : this.tabs,
                },(res) => {
                    if(res.data.data){
                        let data = res.data.data;
                        this.tableList = data.elements;
                        this.total = data.totalCount;
                    }else{

                    }
                },this.currentPage,10);
            },
            // 点击搜索
            handleSearch() {
                if(+ new Date(this.startTime) > + new Date(this.endTime)){
                    this.$message.warning('开始时间不能大于结束时间');
                    return;
                }
                this.currentPage = 1;
                this.startTimeDefault = this.startTime;
                this.endTimeDefault = this.endTime;
                this.nameDefault = this.name;
                this.initTableList2();
            },
            // 点击 确认打款
            handleConfirmMoney(row) {
                this.confirmMoneyFn([row.id]);
            },
            // 确认打款逻辑
            confirmMoneyFn(ids,message) {
                this.$confirm(message || '请确认该笔保证金已转账给用户', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    axiosApi('/superc/bond/apply/approve',{
                        idList : ids,
                    },(res) => {
                        let data = res.data.data;
                        this.$message.success('操作成功');
                        this.currentPage = 1;
                        this.initTableList2();
                    });
                }).catch(() => {
                });
            },
            // tabs 切换
            handleTabsClick(tabs) {
                this.startTime = '';
                this.endTime = '';
                this.name = '';
                this.tabs = tabs.value;
                this.tabsList = this.tabsList.map((v,k) => {
                    v.active = false;
                    if(v.value === tabs.value){
                        v.active = true;
                    }
                    return v;
                });
                this.currentPage = 1;
                this.initTableList2();
            },
            // 分页change
            handlePageChange(val) {
                this.currentPage = val;
                this.initTableList2();
            },
            // 表格多选
            handleSelectionChange(val) {
                this.multipleSelection = val;
            },
            // 批量确定
            handleAllOk() {
                if(this.multipleSelection.length === 0){
                    this.$message.warning('请勾选需要操作的选项');
                    return;
                }
                let ids = this.multipleSelection.map((v,k) => {
                    return v.id;
                });
                this.confirmMoneyFn(ids,'请确认所选选项，保证金已转账给用户');
            },
        },
    }
</script>

<style lang="less" scoped>
    .marginExtraction-box{
        .marginExtraction-content{
            padding: 0px 20px 20px 20px;
            .handleSuccess{
                color: #13CE66;
            }
        }
    }
</style>

<style lang="less">

</style>
