<template>
<div></div>
 <!-- <div id="login">
		<header>
			<top :borderTop="true"></top>
		</header>
		<div class="wrap">
			<img class="bg-img" src="../assets/login-bg.png" />
			<div class="w1280">
				<div class="content" :style="{height: activeName == 'first' ? '350px' : '530px'}">
					<div class="title">
						<el-tabs v-model="activeName">
							<el-tab-pane label="账号登录" name="first">
								<el-form>
									<el-form-item>
										<img src="../assets/user.png" />
										<el-input size="large" type="text" @change="change(1)" v-model.trim="form.loginName" @keyup.enter.native="login(1)" placeholder="请输入账号/手机号" auto-complete="off"></el-input>
									</el-form-item>
									<el-form-item>
										<img src="../assets/password.png" />
										<el-input size="large" type="password" :maxlength="16" @change="change(2)" v-model="form.loginPass" @keyup.enter.native="login(1)" placeholder="请输入密码" auto-complete="off"></el-input>
										<div class="messages">
											<p v-show="mistake" class="color-danger lh-14">{{message}}</p>
										</div>
									</el-form-item>
									<el-form-item>
										<el-button type="danger" @click="login(1)" :disabled="disabled">登录</el-button>
									</el-form-item>
									<el-form-item>
										<div class="w250">
											<p class="font-12 color-6 lh-12 cursor fl ad" @click="goPage('/register/step')">注册</p>
											<p class="font-12 color-6 lh-12 cursor fr ad" @click="goPage('/register/forgetFirst')">忘记密码？</p>
										</div>
									</el-form-item>
								</el-form>
							</el-tab-pane>
							<el-tab-pane label="验证码登录" name="second">
								<el-form>
                                    <div>
                                        <div id="c1" style="margin: 10px auto"></div>
                                    </div>
									<el-form-item>
										<img src="../assets/user.png" />
										<el-input size="large" type="text" @change="change(1)" :maxlength="11" v-model.trim="form.loginName2" @keyup.enter.native="login(2)" placeholder="请输入手机号" auto-complete="off"></el-input>
										<p v-if="exist" class="color-danger lh-12 mt-10 ml-36 fl">系统不存在的用户</p>
									</el-form-item>
									<el-form-item>
										<img src="../assets/password.png" />
										<el-input size="large" @change="codeChange" :maxlength="6" v-model.trim="form.code" placeholder="请输入验证码" @keyup.enter.native="login(2)" auto-complete="off"></el-input>
										<el-button class="codeBtn" type="text" :disabled="codeDisabled" @click="sending">
											<span v-if="codeBool">获取验证码<span v-show="timeBool">{{time | seconds}}</span></span>
											<span v-else>获取验证码</span>
										</el-button>
										<br />
										<div class="messages">
											<p v-show="isCode" class="color-danger lh-14">{{message1}}</p>
										</div>
									</el-form-item>
									<el-form-item>
										<el-button type="danger" @click="login(2)" :disabled="codeDis">登录</el-button>
									</el-form-item>
									<el-form-item>
										<div class="w250">
											<p class="font-12 color-6 lh-12 cursor fl ad" @click="goPage('/register/step')">注册</p>
											<p class="font-12 color-6 lh-12 cursor fr ad" @click="goPage('/register/forgetFirst')">忘记密码？</p>
										</div>
									</el-form-item>
								</el-form>
							</el-tab-pane>
						</el-tabs>
					</div>
				</div>
			</div>
		</div>
		<div class="footer font-12">
			<Foot></Foot>
		</div>
	</div> -->
</template>

<script>
	import Top from "../components/top.vue";
	import Foot from "../components/foot.vue";
	import { axiosApi, basePath,gotoLoginPage } from "../api/api";
	import { mapActions } from 'vuex'
	import md5 from "md5"
	export default {
		components: {
			Top,
			Foot
		},
		data() {
			return {
                token:'',
				message: "", //账号登陆  返回错误错误信息
				message1: "", //验证码错误信息
				exist: false, //手机号错误消息是否显示
				isCode: false, //验证码错误信息是否显示
				codeDisabled: true, //获取验证码按钮是否可点击
				activeName: "first",
				form: {
					loginName: "",
					loginName2: "",
					loginPass: "",
					code: ""
				},
				disabled: true,
				bool1: false,
				bool2: false,
				mistake: false,
				time: "59",
				timeBool: false,
				codeBool: true,
				clearTime: -1,
				codeDis: true, //手机验证码登陆是否高亮的布尔值
			}
		},
		created(){
		  gotoLoginPage()
			return;
		},
		mounted() {
			this.$nextTick(() => {
			    let _this = this
                var myCaptcha = _dx.Captcha(document.getElementById('c1'), {
                    _noLogo: true,
                    appId: '8f7a2bbfcb209f52dee6f645ee9c71c4', //appId，在控制台中“应用管理”或“应用配置”模块获取
                    success: function (token) {
                        console.log('token:', token)
                        _this.token = token
                        console.log('打印',_this.token);
                    }
                })
                this.change(1);
				this.change(2);
				document.getElementById("login").style.height = document.documentElement.clientHeight + "px";
				window.onresize = () => {
					if(this.$route.path.indexOf("login") > -1) {
						document.getElementById("login").style.height = document.documentElement.clientHeight + "px";
					}
				}
			})
		},
		filters: {
			seconds(value) {
				return "(" + value + "s)";
			}
		},
		methods: {
			...mapActions('clientType',['getUserInfo']),
			codeChange(val) { //判断手机验证码登陆按钮是否高亮
				//赋值先后顺序
				this.isCode = false; //账号或密码改变时时清除错误提示   验证码登陆
				this.codeDis = !(this.bool1 && this.form.code.length > 0)
			},
			change(type) { //账户，密码change验证
				this.mistake = false; //账号或密码改变时时清除错误提示  账号登陆
				if(type == 1) {
					this.isCode = false; //账号或密码改变时时清除错误提示   验证码登陆
					this.codeBool = false
					let reg = /^1\d{10}$/
					let adreg = /^[A-Za-z0-9\u4e00-\u9fa5]{2,20}$/
					this.bool1 = this.activeName == "first" ? adreg.test(this.form.loginName) : reg.test(this.form.loginName2)
					if(this.activeName == "second") { //验证码登陆 ，判断手机号是否已注册过。
						this.checkMobileNewAvailable()
					}
				}
				if(type == 2) {
					this.bool2 = this.activeName == "first" ? this.form.loginPass.length > 0 : this.form.code.length > 0
				}
				this.disabled = !(this.bool1 && this.bool2)
				this.codeDis = !(this.bool1 && this.bool2)
			},
			checkMobileNewAvailable() { //验证码登陆 ，判断手机号是否已注册过。
				this.exist = false;
				this.codeDisabled = true;
				if(this.bool1 && this.form.loginName2.length == 11) {
					axiosApi("/unlogin/employee/checkMobileNewAvailable", {
						mobile: this.form.loginName2,
						loginName: this.form.loginName2,
						appId: 2
					}, (res) => {
						if(res.data.data) {
							this.exist = false;
							this.codeDisabled = false;
						} else {
							this.exist = true;
						}
					})
				}
			},
			login(type) {
				this.change(1);
				this.change(2);
				setTimeout(() => {
					if(this.disabled) return false; //键盘事件检查
					const api = "/employee/login";
					let body = {};
					let name = "";
					if(type == 1) { //账号登陆
						body = {
							"loginName": this.form.loginName,
							"loginPass": md5(this.form.loginPass),
							"appId": "2",
							"loginType": "1"
						};
						name = this.form.loginName;
					} else if(type == 2) { //验证码登陆
						body = {
							loginName: this.form.loginName2,
							messageCode: this.form.code,
							appId: "2",
							loginType: "2"
						};
						name = this.form.loginName2;
					}
					axiosApi(api, body, (res) => {
						console.log(res,'loginy');
						// localStorage.setItem("xToken", res.data.context.xToken); //xToken
						this.getUserInfo({
							body: {
								"loginName": name,
								appId: 2
							},
							isLogin: true
						})
					}, 1, 10, (err) => {
						if(err.data.code == "LOGIN_002" || err.data.code == "LOGIN_001" || err.data.code == "AUTH_003") { //用户名或密码错误
							this.mistake = true;
							this.message = err.data.message;
						} else if(err.data.code == "LOGIN_003" || err.data.code == "LOGIN_004" || err.data.code == "AUTH_004") { //用户审核中//用户审核失败//录入客户登录
							//						console.log("用户审核中")
							axiosApi("/unlogin/foundation/employee/selectOneByLoginName", {
								loginName: name,
								appId: 2
							}, (res) => {
								
								if(res.data.data&&err.data.code == "AUTH_004") {//录入客户登录
									// this.$alert(err.data.message, '提示', {
									// 	confirmButtonText: '确定'
									// });
									// console.log(8888)
									// this.$router.push(`${basePath}/clientUpdate?traderId=${res.data.data.traderId}&type=0`)
								}else{
									if(res.data.data){
										this.$router.push(`${basePath}/register/step2/${res.data.data.traderId}`)
									}
								}
							})
						}else if(err.data.code.indexOf("SMS") > -1) {
							this.isCode = true
							this.message1 = err.data.message
						}else{
							this.$message.warning(err.data.message)
						}
					});
				}, 0)
			},
			sending() { //发送验证码
                console.log(this.token);
                if (!this.token){
                    this.$message.error('请先通过滑动验证码')
                    return false
                }
                if(!this.exist && this.form.loginName2.length == 11) {
					let _this = this;
					this.codeDisabled = true;
					if(!this.codeBool) {
						clearTimeout(this.clearTime);
						this.codeBool = true;
						this.time = "59";
					}
					this.timeBool = true;
					this.clearTime = setInterval(function() {
						_this.time--;
						if(_this.time == 0) {
							_this.codeBool = false;
							_this.codeDisabled = false;
						}
					}, 1000)
					const api2 = "/unlogin/sms/sendSingleWithVerification"
					const body2 = {
					    token: this.token,
						mobile: this.form.loginName2,
						smsTemplate: "2",
						smsType: "sms0002",
						appId: "2",
						smsPurposeType: 1,
						"fields": {
							"product": "商源云商官方网站"
						}
					}
					axiosApi(api2, body2, () => {}, 1, 10);
				}
			},
			goPage(url) {
				this.$router.push({
					path: basePath + url
				})
			}
		}
	}
</script>

<style scoped lang="less">
	#login {
		min-width: 1200px;
		position: relative;
		background-color: #fff;
		.wrap {
			position: relative;
			.bg-img {
				width: 100%;
				z-index: 0;
				display: block;
				min-height: 763px;
			}
			.w1280 {
				position: absolute;
				width: 1280px;
				left: 50%;
				margin-left: -640px;
				top: 0;
				.content {
					box-shadow: 0 0 10px rgba(0, 0, 0, .5);
					width: 350px;
					height: 350px;
					background-color: #fff;
					position: absolute;
					right: 150px;
					top: 75px;
					z-index: 10;
					.title {
						width: 100%;
						height: 50px;
						line-height: 50px;
						background-color: #f0f0f0;
						text-align: center;
					}
					img {
						width: 36px;
						height: 36px;
						float: left;
						margin-left: 50px;
					}
					.el-form {
						padding-top: 30px;
					}
					.el-button--danger {
						width: 250px;
						height: 36px;
						margin: 30px 50px 0;
					}
					.w250 {
						width: 250px;
						margin: 0 auto;
					}
					.el-input--large {
						float: left;
					}
					.el-tabs__header {
						padding-left: 0;
					}
					.codeBtn {
						width: auto;
						position: absolute;
						right: 60px;
						color: #ff4201;
						margin-top: 2px;
						font-size: 12px;
						&:hover {
							color: #ff4201;
							background-color: #fff;
						}
					}
					.codeBtn.is-disabled {
						color: #999;
						&:hover {
							color: #999;
						}
					}
					.messages {
						position: absolute;
						left: 50px;
						top: 40px;
					}
					.ml-36 {
						margin-left: -36px;
					}
					p.ad:hover {
						color: #ff4201;
					}
				}
			}
		}
		.footer{
			background-color: #333;
			color:#fff
		}
	}
</style>
