<template >
  <div class="logo-top">
    <div class="logo">
			<img @click="$router.push('/customer/shop/shopIndex')" class="mid cursor" src="../assets/logo-r.png" />
		</div>
  </div>
</template>

<script>
export default {
}
</script>

<style lang="less">
.logo-top .logo {
  vertical-align: middle;
  height: 102px;
  background: #fff;
  img {
    width: 190px;
    height: 39px;
    margin-top: 30px;
    margin-left: 30px;
  };
}
</style>
