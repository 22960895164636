<template>
  <div id="invoice-dialog">
    <el-dialog
      title="发票详情"
      :visible.sync="dialogVisible"
      size=""
      @close="goback"
      width="500px"
      >
      <div class="items">
        <div>发票类型: {{info.type == '1' ? '增值税普通发票' : '增值税专用发票'}}</div>
        <div>发票金额: ¥{{String(info.needPayMoney - yunfei).indexOf('.') > -1 ? (info.needPayMoney - yunfei).toFixed(2) : (info.needPayMoney - yunfei)}}</div>
        <div >发票抬头：{{info.companyHead}}</div>
        <div v-if="info.personalCompanyType == '1'">纳税人识别号: {{info.taxpayerIdNo}}</div>
        <div >收货人：{{info.linkman}}</div>
        <div >地址：{{info.provinceName + '' + info.cityName + '' + info.regionName + '' + info.addr}}</div>
        <div >电话号码：{{info.tel}}</div>
        <div v-if="info.remarks">备注：{{ info.remarks }}</div>
<!--        <div >开户银行：{{info.bankDeposit}}</div>-->
<!--        <div >银行账户：{{info.bankCard}}</div>-->
        <!-- <div>发票内容：明细</div> -->
      </div>
      <!--<el-button @click="goback" :style="{margin: '0 auto',display: 'block', 'margin-top': '20px'}">取 消</el-button>-->
    </el-dialog>
  </div>
</template>

<script>
  export default {
    props: ["info", "visible", 'yunfei'],
    data() {
      return {
        dialogVisible: false
      }
    },
    watch: {
      visible(val) {
        this.dialogVisible = val
      }
    },
    methods: {
      goback() {
        this.$emit('close')
      }
    }
  }
</script>

<style lang="less" scoped>
  .items {
      width: 360px;
      margin: 0 70px;
      &>div {
        margin-top: 12px;
      }
    }
</style>
