<template>
	<div id="loginTop" class="width-1280" :class="{'bb-2': !borderTop}">
		<router-link :to="basePath + '/shop/shopIndex'">
			<img class="mid cursor" src="../assets/logo-r.png" />
		</router-link>
	</div>
</template>

<script>
	import { basePath } from "../api/api";
	export default {
		props:['borderTop'],
		data() {
			return {
				basePath: basePath
			}
		}
	}
</script>

<style lang="less">
	#loginTop {
		height: 80px;
		padding-top: 10px;
		img{
			width: 190px;
			margin-top: 10px;
		}
	}
	.bb-2{
		border-bottom: 2px solid #eee;
	}
</style>