<template>
	<div id="goodsSearch" v-loading.fullscreen.lock="fullscreenLoading">
		<div id="shop" class="bg-f font-16">
			<div class="width-1280 plr-45">
				<div class="logo">
					<el-row type="flex" justify="space-between">
						<el-col :span="8">
							<router-link :to="basePath + '/shop/shopIndex'">
								<img class="cursor" src="../../assets/logo-r.png" />
							</router-link>
						</el-col>
						<el-col class="mt-30">
							<div class="clearfix">
								<el-input class="fl" v-model.trim="goodsName" @keydown.enter.native='searchName("goodsName")' placeholder="请输入关键词搜索"></el-input>
								<el-button class="fl" @click.native='searchName("goodsName")'>搜 索</el-button>
							</div>
						</el-col>
						<el-col class="mt-30" :span="4">
							<!-- <router-link :to="basePath + '/shop/shopingCar'"> -->
								<el-button @click.native="goShoppingCar" class="font-12 border-radius-no">
									<span><i class="iconfont icon-shopping-car color-danger"></i></span>
									<span class="color-danger ml-10">我的购物车</span>
									<span class="sub" v-if="shoppingCarNum > 0">{{shoppingCarNum}}</span>
								</el-button>
							<!-- </router-link> -->
						</el-col>
					</el-row>
				</div>
			</div>
		</div>
		<div class="table bg-f">
			<div class="width-1280 plr-45 tab clearfix">
				<ul class="nav-w">
					<li class="fl cursor bg-danger" @mouseover="showClassifyList = true" @mouseout="showClassifyList = false">
						<span>全部商品</span>
						<i class="el-icon-caret-bottom font-14"></i>
						<div class="leftClassify clearfix fl bg-danger" v-show="showClassifyList">
							<ul class="nav mt-10 pb-10 cursor">
								<li class="pl-20 clearfix" v-for='(item,index) in classifyList' @click="classifyClick(false)" @mouseover="classifyBrandOverOut(index,true)" @mouseout="classifyBrandOverOut(index,false)" :key='index'>
									<i class="iconfont font-16 color-white" :class="item.icon"></i>
									<span class="font-16 ml-10 color-white">{{item.classifyName}}</span>
									<i class="el-icon-arrow-right color-white font-12 color-9 fr mr-10 mt-20"></i>
								</li>
							</ul>
							<!--菜单展开-->
							<div class='navMain pl-50 bg-f' v-show='nava' @mouseover="nava = true" @mouseout.stop="nava = false;showClassifyList = false;">
								<p class="font-16 lh-16 mt-20" v-if="classifyName != '其他'">{{classifyName}}</p>
								<ul class="item">
									<li class="fl text-overflow pr-20 mt-20 color-6 font-14 lh-14 w-100 cursor hover-danger" v-for='n in brandList' :title="n.brandName" @click="classifyClick(n.id)" :key='n.brandName'>{{ n.brandName }}</li>
								</ul>
							</div>
						</div>
					</li>
					<li v-for="item in dataList" class="fl cursor" :key="item">{{item}}</li>
				</ul>
				<div class="switchClass">
					<span>库存为0：</span>
					<el-switch
						v-model="zeroShow"
						active-color="#ff4201"
						@change="setShow">
					</el-switch>
					<span style="margin-left:10px">{{ zeroShow?'隐藏': '显示' }}</span>
				</div>
				<span :style="{lineHeight: 'initial'}" v-if="isSupper && supplierSmallImg" class="fr">
					店铺名称：{{ storeName }}
					<p>
						<template>
							<div class="mShopInfo">
								营业时间：
								<template v-if="shangHuData.isOpenAllHours">
									24小时全天营业
								</template>
								<template v-else>
									<span>{{shangHuData.shopTime}}</span>
									--
									<span>{{shangHuData.closingTime}}</span>
								</template>
								<el-tag v-if="shangHuData.businessStatus === 0">营业中</el-tag>
								<el-tag v-if="shangHuData.businessStatus === 1">打烊中</el-tag>
								<el-tag v-if="shangHuData.businessStatus === 2">关闭中</el-tag>
							</div>
						</template>
					</p>
				</span>
			</div>
		</div>
		<div id="shopStore" class="clearfix" v-if="isSupper">
			<div class="banner" v-if="supplierSmallImg" :style="bgSupplierSmallImg"></div>
			<div class="defaultsStore-wrap" v-if="!supplierSmallImg">
				<div class="defaultsStore pt-20 width-1280">
					<strong class="font-20">{{ storeName }}</strong>
					<!-- <el-button v-if="sellerId != buyerId !isHotel" type="danger" @click.native="handCollectionSupper" class="ml-20 mid">
						<span v-if="collectedType">已收藏</span>
						<span v-else>收藏店铺</span>
					</el-button> -->
					<div class="mshopInfo">
						<template>
							<div class="mShopInfo">
								营业时间：
								<template v-if="shangHuData.isOpenAllHours">
									24小时全天营业
								</template>
								<template v-else>
									<span>{{shangHuData.shopTime}}</span>
									--
									<span>{{shangHuData.closingTime}}</span>
								</template>
								<el-tag v-if="shangHuData.businessStatus === 0">营业中</el-tag>
								<el-tag v-if="shangHuData.businessStatus === 1">打烊中</el-tag>
								<el-tag v-if="shangHuData.businessStatus === 2">关闭中</el-tag>
							</div>
						</template>
					</div>
				</div>
			</div>
			<div class="storeSearch clearfix">
				<div class="width-1280 plr-45">
					<ul>
						<li class="fl cursor" :class="{ 'bg-rgba': storeLiActive }" @click="supperHomeClick(true)"><span class="color-white">店铺首页</span></li>
						<li class="fl cursor" :class="{ 'bg-rgba': !storeLiActive }" @click="supperHomeClick(false)"><span class="color-white">全部商品</span></li>
					</ul>
					<div class="clearfix fr mt-10">
						<el-input class="fl" v-model.trim="supperGoodsName" @keydown.enter.native="searchName('supper')" placeholder="搜索本店商品"></el-input>
						<el-button class="fl font-16" @click="searchName('supper')" >搜索</el-button>
						<!-- <el-button v-if="supplierSmallImg && !isHotel" @click.native="handCollectionSupper" class="ml-10 fr">
							<span class="color-danger" v-if="collectedType">已收藏</span>
							<span v-else>收藏店铺</span>
						</el-button> -->
					</div>
				</div>
			</div>
			<div class="banner cursor bannerBig" @click="openLocation(supplierBigUrl)" v-if="supplierBigImg" :style="bgSupplierBigImg"></div>
		</div>
		<div class="width-1280 plr-45">
			<div class="color-6 mt-20 font-14 goods-info" >
				搜索“{{searchCondition || '全部'}}”共<span class="color-danger">{{ goodsNum }}</span>个商品
			</div>
			<!-- 商品赛选项 -->
			<div class="nav mt-20 bg-f bg-f font-14" v-if="showRaceCondition">
				<div class="ml-30 mr-30 clearfix bgimg" v-for="(item,index) in screeningConditions" v-if="item.children.length > 0" :style="{height: item.height +'px'}" :key="index">
					<p class="color-6 pt-20 pb-20 fl w-100">{{item.title}}:</p>
					<ul class='clearfix w-1000 fl'>
						<li class="color-3 fl w-100 hover-danger cursor" :class="{ 'color-danger': !item.active}"  @click="searchTypeClick(item, null)">全部</li>
						<li class="color-3 fl w-100" v-for='(item2,index2) in item.children' :key="index2" :class="{ 'color-danger': item.active == item2.dictKey, 'pl-20': (index2+1)%10 > 0 }" @click="searchTypeClick(item, item2.dictKey)">
							<el-tooltip v-if="item2.dictValue?item2.dictValue.length > 6:false" effect="dark" :content="item2.dictValue" placement="bottom">
								<span class=" text-overflow hover-danger cursor text-overflow s-100" >{{item2.dictValue}}</span>
							</el-tooltip>
							<span v-else class=" text-overflow hover-danger cursor text-overflow s-100" >{{item2.dictValue}}</span>
						</li>
					</ul>
					<p class="fr pt-20 cursor color-3 more" v-if="item.heightAuto > 60" @click="heightAuto(item)">更多
						<i class="el-icon-caret-bottom color-bb"></i>
					</p>
				</div>
			</div>
			<div class="main mt-20 mb-50">
				<div class="four bg-f clearfix font-16 color-6 " v-if="searchType!='goodsType'">
					<!-- 不是推荐商品更多进入-显示 -->
					<span class="ml-30 mr-30 li cursor" @click="sortClick(1,1)">综合排序</span>
					<el-dropdown class='ml-30 mr-30 cursor font-16 color-6' v-for="item in elDropdown" :key='item.title'>
						<span class="el-dropdown-link">
					    	{{item.title}}
					    	<i class="el-icon-caret-bottom el-icon--right"></i>
						</span>
						<el-dropdown-menu slot="dropdown">
							<el-dropdown-item  @click.native="sortClick(item.value,1)">从低到高</el-dropdown-item>
							<el-dropdown-item  @click.native="sortClick(item.value,2)">从高到低</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>
				</div>
				<div v-else>
					<div v-if="$route.query.activityId">
						<div class="activityInfo">
							<div class="itemDiv">活动名称：{{activityInfo.activityName}} <span class="activityStatusName" v-if="activityInfo.activityStatusName">({{activityInfo.activityStatusName}})</span> </div>
							<div class="itemDiv">活动时间：{{activityInfo.infoTime}}</div>
							<div class="itemDiv">活动内容：<el-tag type="danger" size="mini" effect="dark" v-if="activityInfo.activityTypeName">{{activityInfo.activityTypeName}}</el-tag>{{activityInfo.activityContent}}</div>
						</div>
					</div>
				</div>	
				<div class="clearfix">
					<goodsItem v-for='(item,index) in goodsList' :key='item.skuNo' :itemData="item" @parabolaFn="parabolaFn" @storeClick="goStore" />
					<!-- :isHotel="isHotel" -->
				</div>
			</div>
			<el-pagination v-if="total > 0" class="tar  pb-50" :current-page="currentPage" :page-size='pageSize' :total='total' layout="prev , pager, next, jumper" @current-change='handleCurrentChange'>
					<!-- 分页组件 -->
			</el-pagination>
		</div>
		<rightMenu v-show="!isSupper"></rightMenu>
	</div>
</template>

<script>
	import RightMenu from "../../components/rightMenu.vue";
	import goodsItem from "../../components/goodsItem.vue";
	import { axiosApi,basePath } from "../../api/api";
	import {mapMutations, mapActions} from 'vuex'
	import { parseTime, computeNumber } from "@/utils";
	var parabola,element,target;
	export default {
		components: {
			RightMenu, goodsItem
		},
		data() {
			return {
				activityInfo: {}, // 活动信息
				shangHuData : {
					// 商户状态 0营业中 1已打烊 2已关闭
					businessStatus : '',
					isOpenAllHours : '',
					closingTime : '',
					shopTime : '',
				},
				bgSupplierSmallImg:{
					backgroundImage: null //"url("+ require('../../assets/supper-small.jpg') +")"
				},
				bgSupplierBigImg:{
					backgroundImage: null //"url("+ require('../../assets/supper-big.jpg') +")"
				},
				goodsMinNum: 1,
				currentPage:1,//当前页数
				pageSize:20,//	每页显示条目个数
				total:0,//总条目数
				searchCondition:null,
				dataList:[],
				classifyList:[],
				showClassifyList: false,
				showCollectStoreBtn: false,
				nava:false,
				classifyName:'白酒',
				brandList:[],
				classifyId: null,
				brandId: null,
				goodsName: "",
				isGoodsName: "",
				goodsNum: 0,
				goodsList:[],
				productAreaId: null,//产地
				categoryId: null,//就得品类
				packingId: null,//包装
				flavorId: null,//香型
				tasteId: null,//口味
				domesticImportId: null,//国产/进口
				grapeVarietiesId: null,//葡萄品种
				proofSection: null,//酒精度
				temClassifyId: null,//分类中间变量
				showRaceCondition: true,//是否显示搜索条件
				screeningConditions: [],//筛选条件列表
				isMore: true,
				elDropdown:[{title:"销量",value:4},{title:"价格",value:2},{title:"上架时间",value:3},],
				sortType: 1,
				sortOrder: 1,
				sellerId: null,
				buyerId: sessionStorage.traderId,
				supperGoodsName: "",
				supplierSmallImg: false,
				supplierBigImg: false,
				supplierBigUel: null,
				isSupper:false,
				storeName: "",
				searchType:'',
				collectedType: false, //默认不收藏
				fullscreenLoading: false,
				activeIndex:"",//当前购物车抖动
				isloginUser: false,
				storeLiActive: true,//店铺首页与商品选中状态
				showSearchInfo: false,
				// isHotel: false,
				// ishotelAgreementGoods: 2,//是否只查询酒店协议商品 1 是 2 否
				zeroShow: this.$store.state.homeSearch.zeroShow,
				searchItem: '',
				searchVal: ''
			}
		},
		computed: {
			shoppingCarNum() {
				return this.$store.state.homeSearch.shoppingCarNum
			},
			// zeroShow(){
			// 	return this.$store.state.homeSearch.zeroShow
			// },
		},
		methods: {
			...mapMutations({
				'mouseHide': 'mouseHide',
				'collectStoreHide':'collectStoreHide',
				'setZeroShow': 'setZeroShow'
			}),
			...mapActions({
				'setShoppingCarNum': 'setShoppingCarNum'
			}),
			setShow(val){
				this.setZeroShow(val)
				this.currentPage = 1;
				switch (this.searchType) {
					case 'goodsName':
							this.searchName(this.searchType) // 此处传this.searchType是为了使用上一次搜索所传的类型
						break; 
					case 'brand':
							this.classifyBrandId(this.brandId)
						break;
					case 'classify':
							this.classifyBrandId(false)
						break;
					case 'goodsType':
							this.goodsSearch()
						break;
					case 'supper':
						break;
					default:
						this.searchTypeClick(this.searchItem,this.searchVal)
						break;
				}
			},

			classifyClick(brandId){
				this.classifyId = this.temClassifyId;
				this.classifyBrandId(brandId)
			},
			reset(){
				for(var i = 0;i < arguments.length;i++){
					this[arguments[i]] = arguments[i] == 'goodsName' ? "" : null;
				}
			},
			query(){//调接口
				this.sortType = 1;
				this.sortOrder = 1;
				if(this.currentPage === 1){
					this.goodsSearch();
				}else{
					this.currentPage = 1;
				}
			},
			searchName(value){//商品名称查询
				this.reset('classifyId', 'brandId', 'productAreaId', 'categoryId', 'packingId', 'flavorId', 'tasteId', 'domesticImportId', 'grapeVarietiesId', 'proofSection');
				this.searchType = value;
				if(value === 'goodsName') {
					this.sellerId = null;
				}
				setTimeout(()=>{
					this.query();
				},0)
			},
			sortClick(sortType,sortOrder){//排序查询
				this.sortType = sortType;
				this.sortOrder = sortOrder;
				setTimeout(()=>{
					if(this.currentPage === 1){
						this.goodsSearch();
					}else{
						this.currentPage = 1;
					}
				},0)
			},
			classifyBrandId(brandId){//分类/品牌查询
				// this.ishotelAgreementGoods = 1;
	      // this.classifyId = this.temClassifyId;
				this.filterItem();
				this.reset('goodsName', 'isGoodsName', 'productAreaId', 'categoryId', 'packingId', 'flavorId', 'tasteId', 'domesticImportId', 'grapeVarietiesId', 'proofSection');
				if(!brandId){
					this.brandId = null;
					this.searchType = 'classify';
				}else{
					this.brandId = brandId;
					this.searchType = 'brand';
				}
				this.showRaceCondition = true;
				this.showClassifyList = false;
	        	setTimeout(()=>{
					this.query();
				},0)
	    },
			//点击搜索条件搜索
			searchTypeClick(item, val){
				// this.ishotelAgreementGoods = 1;
				console.log(item, val)
				this.searchItem = item
				this.searchVal = val
				this.currentPage = 1;
				this.reset('goodsName', 'isGoodsName');
				this.searchType = '';
				this[item.key] = val;//筛选添加ID
				item.active = val;//选中高亮
				setTimeout(()=>{
					this.query();
				})
			},
			supperHomeClick(bool){//店铺全部商品
				this.bool = bool // 用于判断
				// this.ishotelAgreementGoods = 1;
				this.reset('goodsName', 'isGoodsName','classifyId', 'brandId', 'productAreaId', 'categoryId', 'packingId', 'flavorId', 'tasteId', 'domesticImportId', 'grapeVarietiesId', 'proofSection');
				this.searchType = '';
				setTimeout(()=>{
					this.query();
					this.supperGoodsName = "";
					this.supplierBigImg = bool;
					this.storeLiActive = bool;
				})
			},
			goStore(sellerId){//进入店铺
				// this.ishotelAgreementGoods = 1;
				this.reset('goodsName', 'isGoodsName','classifyId', 'brandId', 'productAreaId', 'categoryId', 'packingId', 'flavorId', 'tasteId', 'domesticImportId', 'grapeVarietiesId', 'proofSection');
				this.sellerId = sellerId;
				this.searchType = '';
				this.getStoreImg();
				this.supperGoodsName = "";
				this.searchName('supper');
			},
			//加载搜索商品列表
			goodsSearch() {
				this.fullscreenLoading = true;
				this.isSupper = !this.$route.params.seller ? false : true;
				let body;
				
				switch (this.searchType) {//跳转收索商品名称
					case 'goodsType'://搜索
							this.searchCondition = this.$route.query.name
							if(this.$route.query.activityId){
								body = {
									activityId:this.$route.query.activityId,
									buyerId: sessionStorage.traderId
								}
							}else{
								body = {
									returnCollected:true,
									goodsViewType: this.$route.query.goodsType,
								}
							}
							
	            break;
					case 'goodsName'://商品名称搜索
							this.showRaceCondition = false;//是否显示商品筛选项
							this.isSupper = false;//是否显示店铺图片
							this.showSearchInfo = true;
							this.isGoodsName = this.goodsName;//点击搜索再显示
							let goodsName;
							if(this.isGoodsName === null || this.isGoodsName === ""){
								goodsName = 'goodsName';
								this.searchCondition = '全部';//展示搜索内容
							}else{
								goodsName = this.isGoodsName;
								this.searchCondition = this.isGoodsName;
							}
							this.$router.push(`${basePath}/goodsSearch/classify/brand/${goodsName}`);
							body = { buyerId: sessionStorage.traderId, skuName: this.isGoodsName ,sortType: this.sortType, sortOrder: this.sortOrder };
							// if(this.isHotel && (body.skuName == "" || body.skuName == null)) this.ishotelAgreementGoods = body.ishotelAgreementGoods = 1;
		                break;
	        case 'brand'://品牌搜索
							this.searchType = 'brand';
							this.showSearchInfo = true;
							this.showRaceCondition = true;
							// this.classifyId = this.temClassifyId;
							this.$router.push(`${basePath}/goodsSearch/${this.classifyId}/${this.brandId}/goodsName`);
							body = { buyerId: sessionStorage.traderId, classifyId: this.classifyId, brandId: this.brandId ,sortType: this.sortType, sortOrder: this.sortOrder }
							// !this.isHotel ? { buyerId: sessionStorage.traderId, classifyId: this.classifyId, brandId: this.brandId ,sortType: this.sortType, sortOrder: this.sortOrder } : { buyerId: sessionStorage.traderId, classifyId: this.classifyId, brandIds: this.brandId ? [this.brandId] : [], ishotelAgreementGoods: 1 ,sortType: this.sortType, sortOrder: this.sortOrder };
	            break;
	        case 'classify'://全部分类搜索
							this.searchType = 'classify';
							this.showRaceCondition = true;
							this.showSearchInfo = true;
							this.isSupper = false;
							// this.classifyId = this.temClassifyId;
							this.$router.push(`${basePath}/goodsSearch/${this.classifyId}/brand/goodsName`);
							body = { buyerId: sessionStorage.traderId, classifyId: this.classifyId ,sortType: this.sortType, sortOrder: this.sortOrder };
							// if(this.isHotel) this.ishotelAgreementGoods = body.ishotelAgreementGoods = 1;
	                break;
	        case 'supper'://搜索供应商商品名搜索
	            this.showRaceCondition = false;
							this.supplierBigImg =false;
							this.isSupper = true;
							this.searchType = 'supper';
							this.goodsName = "";
							// alert(this.supperGoodsName)
							this.searchCondition = this.supperGoodsName = this.supperGoodsName.trim();//去除首位空格
							let goodsName2 = (this.supperGoodsName === null || this.supperGoodsName === "") ? 'goodsName' : this.supperGoodsName;
							this.$router.push(`${basePath}/goodsSearch/classify/brand/${goodsName2}/${this.sellerId}`);
							body = { buyerId: sessionStorage.traderId, skuName: this.supperGoodsName, storeSellerId: this.sellerId ,sortType: this.sortType, sortOrder: this.sortOrder };
							// if(this.isHotel && (body.skuName == "" || body.skuName == null)) {
							// 	this.ishotelAgreementGoods = body.ishotelAgreementGoods = 2;
							// }
							break;
	        default://按分类赛选条件搜索全部商品或供应商商品
						this.showSearchInfo = true;
						this.searchType = '';
						this.showRaceCondition = !this.classifyId ? false : true;
						this.isGoodsName = this.goodsName.trim();//去除首位空格
						body = {
								buyerId: sessionStorage.traderId,
								storeSellerId: this.sellerId,
								sortType: this.sortType,//排序类型
								sortOrder: this.sortOrder,//排序方式
				        skuName: this.isGoodsName,//商品名称
								classifyId: this.classifyId,//分类ID
								brandId: this.brandId,//品牌id
								productAreaId: this.productAreaId,//产地
								categoryId: this.categoryId,//酒的品类
								packingId: this.packingId,//包装
								flavorId: this.flavorId,//香型
								tasteId: this.tasteId,//口味
								domesticImportId: this.domesticImportId,//国产/进口
								grapeVarietiesId: this.grapeVarietiesId,//葡萄品种
								proofSection: this.proofSection//酒精度
				    } 
	          break;
				}
				if(this.searchType==="goodsType"){
					if(this.$route.query.activityId){ 
						// 消息中心-活动通知进入时
						var api = '/marketing/activity/detail/query'
					}else{
						var api = '/unlogin/goods/recommendGoods/list'
					}
				}else{
					var api = this.isloginUser ? "/goods/sku/search" : "/unlogin/goods/sku/search";
				}
				// this.isHotel ? '/goods/hotelSkuSearch/select' : ()
				body.shouldHasStore = this.zeroShow // 库存为0是否显示 /goods/sku/search 接口使用。其他接口无意义
				axiosApi(api ,body ,(res) => {
					let a = res.data.data;
					this.activityInfo = {} // 清除活动信息
					if(this.searchType === 'goodsType'){
						if(this.$route.query.activityId){ 
							// 消息中心-活动通知进入时
							var activityInfo = {
								activityStatusName:a.activityStatusName,
								activityTypeName:a.activityTypeName,
								activityContent:a.activityContent,
								activityName:a.activityName,
								infoTime: parseTime(a.gmtStart,'{y}-{m}-{d}')+'至'+parseTime(a.gmtEnd,'{y}-{m}-{d}')
							}
							this.activityInfo = activityInfo
							this.goodsList = a.skuVOList
							this.goodsNum = this.goodsList.length;
							this.fullscreenLoading = false;
						}else{
							this.goodsList = a
							this.goodsNum = this.goodsList.length;

							this.fullscreenLoading = false;
						}
						return
					}
					this.conditionChieve(a);
					this.goodsList = a.page.elements ? a.page.elements : [];
					this.goodsNum = a.page.totalCount ? a.page.totalCount : 0;
					this.total = a.page.totalCount;
					// this.currentPage = a.pageIndex;
					this.fullscreenLoading = false;
					if(this.goodsList.length > 0){
						// this.isHotel && 
						this.goodsList.forEach(n=>{
							if(n.hotelAgreementGoodsVO){
								n.priorityPrice = n.hotelAgreementGoodsVO.discountPrice;//商品折后价
								n.price = n.hotelAgreementGoodsVO.promotionPrice;//商品会员价
							}else{
								n.priorityPrice = n.msrp;//商品价格
								n.price = -1;
							}
						})
					}
			   }, this.currentPage, this.pageSize,res=>{
			   	this.fullscreenLoading = false;
			   	this.$message.error(res.data.message)
			   })
			},
			goShoppingCar(){
				if (!this.isloginUser) {
					this.$store.commit('setloginDialog',true);
	                return false;
				}
				this.$router.push(basePath + '/shop/shopingCar')
			},
      openLocation(url){
      	if(!url) return;
      	let httpUrl = (url.indexOf('http://') == 0 || url.indexOf('https://') == 0) ? url : window.location.protocol + '//' + url;
      	window.open(httpUrl)
      },
			//分页
			handleCurrentChange(val) {
				this.currentPage = val;
				this.goodsSearch();
			},
			getClassifyList(){//获取左侧分类信息
				let api = "/unlogin/goods/classifyBrandQuery/select";
				// this.isHotel ? "/goods/hotelClassifyBrand/select" : 
				let body = { sellerId: sessionStorage.getItem('sellerId')||null,limit:8 };
				axiosApi(api, body,(res) => {
					this.classifyList = res.data.data;
					this.filterItem();
		        },1,10)
	    },
      classifyBrandOverOut(index,bool){//鼠标移入分类品牌li
      	//this.showRaceCondition = bool;
      	this.nava = bool;
      	if(!bool) return;
				this.temClassifyId = this.classifyList[index].id;
				this.classifyName = this.classifyList[index].classifyName;
				this.brandList = this.classifyList[index].brandList;
      },
	    filterItem(){
			
				const classifyObj =  this.classifyList.filter(item => {
					return item.id == this.classifyId
				})
					console.log(this.classifyId, classifyObj)
				this.searchCondition = classifyObj[0].classifyName
	    },
			conditionChieve(a) {//加载搜索商品条件品牌、产地、包装
				if(!this.showRaceCondition) return
				this.screeningConditions = [];
				if(a.brandConditionSet.length > 0) this.screeningConditions.push({
						key: 'brandId', title: '产地',
						title: '品牌',
						children: a.brandConditionSet,
						active: this.brandId,
						height: 60,
						heightAuto: this.getHeightAuto(a.brandConditionSet.length+1),
						isAuto: false
					});
				if(a.productAreaConditionSet.length > 0)this.screeningConditions.push({ key: 'productAreaId', title: '产地', children: a.productAreaConditionSet ,active: this.productAreaId, height: 60,  heightAuto: this.getHeightAuto(a.productAreaConditionSet.length+1), isAuto: false });
				if(a.packingConditionSet.length > 0)this.screeningConditions.push({ key: 'packingId', title: '包装', children: a.packingConditionSet ,active: this.packingId, height: 60,  heightAuto: this.getHeightAuto(a.packingConditionSet.length+1), isAuto: false });
				// if(a.classifyConditionSet.length > 0)this.screeningConditions.push({ key: 'classifyId', title: '分类', children: a.classifyConditionSet ,active: this.classifyId, height: 60,  heightAuto: this.getHeightAuto(a.classifyConditionSet.length+1), isAuto: false });
				if(a.proofConditionSet.length > 0)this.screeningConditions.push({ key: 'proofSection', title: '酒精度', children: a.proofConditionSet ,active: this.proofSection, height: 60,  heightAuto: this.getHeightAuto(a.proofConditionSet.length+1), isAuto: false });
				if(a.flavorConditionSet.length > 0)this.screeningConditions.push({ key: 'flavorId', title: '香型', children: a.flavorConditionSet ,active: this.flavorId, height: 60,  heightAuto: this.getHeightAuto(a.flavorConditionSet.length+1), isAuto: false });
				if(a.tasteConditionSet.length > 0)this.screeningConditions.push({ key: 'tasteId', title: '口味', children: a.tasteConditionSet ,active: this.tasteId, height: 60,  heightAuto: this.getHeightAuto(a.tasteConditionSet.length+1), isAuto: false });
				if(a.domesticImportConditionSet.length > 0)this.screeningConditions.push({ key: 'domesticImportId', title: '国产/进口', children: a.domesticImportConditionSet ,active: this.domesticImportId, height: 60,  heightAuto: this.getHeightAuto(a.domesticImportConditionSet.length+1), isAuto: false });
				if(a.categoryConditionSet.length > 0)this.screeningConditions.push({ key: 'grapeVarietiesId', title: '葡萄品种', children: a.categoryConditionSet ,active: this.grapeVarietiesId, height: 60,  heightAuto: this.getHeightAuto(a.categoryConditionSet.length+1), isAuto: false });
			},
			getHeightAuto(num){
				return Math.ceil(num/10)*60;
			},
			heightAuto(item){
				// console.log(item)
				item.isAuto = !item.isAuto;
				item.height = item.isAuto ? item.heightAuto : 60;
			},
			getStoreImg(){//店铺装修图片
				let api = this.isloginUser ? "/trader/shopPositiondecoration/select" : "/unlogin/trader/shopPositiondecoration/select";
				axiosApi(api, {sellerId: this.sellerId}, (res) => {
					this.shangHuData = {
						businessStatus : res.data.data.businessStatus,
						isOpenAllHours : res.data.data.isOpenAllHours,
						closingTime : res.data.data.closingTime,
						shopTime : res.data.data.shopTime,
					};
					if(!!res.data.data.position1.img) {
						this.bgSupplierSmallImg.backgroundImage = 'url('+ res.data.data.position1.img+ ')';
						this.supplierSmallImg = true;
					}else{
						this.supplierSmallImg = false;
					}
					if(!!res.data.data.position2.img) {
						this.bgSupplierBigImg.backgroundImage = 'url('+ res.data.data.position2.img +')';
						if(!!this.classifyId){
							this.supplierBigImg = false;//店内分类搜索不展示大图
						}else{
							this.supplierBigImg = true;
						}

						this.supplierBigUrl = res.data.data.position2.url;
					}else{
						this.supplierBigImg = false;
					}
					this.storeName = !res.data.data.storeName ? "" : res.data.data.storeName;
				}, 1, 10)
			},
			 handCollectionSupper(){//收藏店铺
			 	if (!this.isloginUser) {
					/* this.$alert('请先登录', '提示信息', {
	                    confirmButtonText: '确定'
					}) */
					this.$store.commit('setloginDialog',true);
	                return false;
				}else if(this.buyerId == this.sellerId) {
					this.$message({
			          message: "不能收藏自己的店铺",
			          type: 'info',
			          duration: 1000
			       });
					return
				}
				let body = [{ 'buyerId': sessionStorage.traderId , 'sellerId': this.sellerId }];
				let api = "/trader/collectSeller/update";
				this.CollectApi(api, body,'sellerCollected');
			},
			CollectApi(api, body,type){
				axiosApi(api, body, (res) => {
					this.collectedType = res.data.data[0].sellerCollected;
					this.$message({
			          message: this.collectedType ? "收藏成功！" : "已取消收藏！",
			          type: 'success',
			          duration: 1000
			       });
				}, 1, 10,(res)=>{
					this.$message({
			          message: res.data.message,
			          type: 'info',
			          duration: 1000
			       });
				})
			},
			//判断店铺是否已收藏
			getCollectedType(){
				if(!this.isloginUser) return;
				let body = {
					buyerId: sessionStorage.traderId,
					sellerId: this.sellerId
				};
				axiosApi("/trader/judgeSellerSkuCollect/select", body, (res)=>{
					//console.log(res,'是否收藏')
					if(res.data.data) this.collectedType = res.data.data.sellerCollected;
				})
			},
			parabolaFn(){//抛物线小球
				parabola.init();
			}
		},
		mounted() {
			this.$nextTick(function() {
				if(this.$route.query.goodsType){
					// 首页推荐商品模块进入,设置搜索类型。
					// 消息中心-活动通知进入,设置搜索类型。
					this.searchType = 'goodsType';
				}
				this.isloginUser = !sessionStorage.getItem('loginName') ? false : true;
				// this.isHotel = sessionStorage.getItem('isHotel') === '1' && this.isloginUser  ? true : false;
				if(!isNaN(Number(this.$route.params.classifyId))){
					this.classifyId = Number(this.$route.params.classifyId);//获取分类ID
					this.brandId = isNaN(Number(this.$route.params.brandId)) ? null : Number(this.$route.params.brandId);//获取品牌ID

					this.showRaceCondition = true;
				}
				this.isGoodsName = this.goodsName = this.$route.params.goodsName != 'goodsName' ?  this.$route.params.goodsName : "";
				if(this.goodsName.length > 0) this.searchType = 'goodsName';//商品名称搜索
				if(!!this.classifyId) this.searchType = 'classify';//分类搜索
				if(!!this.brandId) this.searchType = 'brand';//品牌搜索
				if(!isNaN(Number(this.$route.params.seller))){//店内搜索
					this.sellerId = this.$route.params.seller;
					this.isSupper = true;
					this.getStoreImg();
					this.searchType = '';
					if(!!this.classifyId){
						this.supplierBigImg = false;
					}
				}
				
				// if(this.searchType !== 'goodsName')this.ishotelAgreementGoods = 1;
				// this.conditionChieve();
				this.mouseHide();
				this.collectStoreHide();
				this.goodsSearch();
				this.getClassifyList();
				if(this.isloginUser) this.setShoppingCarNum();
				if(!!this.sellerId)this.getCollectedType();

				target = document.getElementById("shopping-empty");
				element = document.getElementById("ball");
				parabola = this.parabola(element, target, {
			        speed: 700,// 每帧移动的像素大小
			        curvature: 0.0006,// 实际指焦点到准线的距离
			        complete: function () {//动画执行完回调
			            element.style.visibility = "hidden";
			        }
			    }).mark();
			})
		}
	}
</script>

<style lang="less">
	#goodsSearch {
		.mShopInfo{
			color: #666;
			font-size: 12px;
		}
		.mshopInfo{
			margin-top: 15px;
		}
		.plr-45{
			padding:0 45px;
		}

		.nav{
			ul li{
				position: relative;
				.show-dict-value{
					position: absolute;
					top: 50px;
					background-color: rgba(0, 0, 0, 0.7);
					border-radius: 9px;
					line-height: 18px;
					color: #fff;
					z-index: 10000;
					padding: 4px 10px;
				}
			}
		}
		
		.goods-info{
			line-height: 30px;
		}
		#shop {
			.font-19{
				font-size: 19px;
			}
			font-size: 16px;
			.logo {
				height: 108px;
				background: #fff;
				.border-radius-no{
					border-radius: 0;
				}
				.el-col-8{
					width: 199px;
					img {
						width: 190px;
						height: 39px;
						margin-top: 30px;
					};
					.platform {
						display: inline-block;
						color: #FF4201;
						vertical-align: top;
						font-size: 24px;
					};
				};
				.el-col-24{
					width: 600px;
					.el-input{
						width: 520px;
					};
					.el-input__inner{
						border: 2px solid #FF4201;
						border-right: 0;
						height: 36px !important;
					};
					.el-button{
						width: 80px;
						height: 36px;
						color: #FFF;
						font-size: 16px;
						background: #FF4201;
						border: 2px solid #FF4201;
						border-radius: 0;
					};
				};
				.el-col-4{
					width: 190px;
					.el-button{
						width: 190px;
						height: 36px;
					};
					span{
						vertical-align: middle;
					};
					.sub{
						min-width: 12px;
						height: 16px;
						line-height: 16px;
						padding:0 2px;
						display: inline-block;
						background: #FF4201;
						border-radius: 8px;
						color: #fff;
						margin-left: 8px;
					}
				}
			}
		}
		.table{
		border-bottom: 2px solid #FF4201;
		.tab{
			height: 50px;
			line-height: 50px;
			text-align: center;
			.switchClass{
				float: left;
				margin-left: 40px;
				display: flex;
				align-items: center;
			}
			.nav-w>li {
				width: 132px;
				color: #000;
			};
			.nav-w>li:nth-child(1) {
				width: 190px;
				color: #fff;
				position: relative;
				.leftClassify{
					position: absolute;
					width: 100%;
					height: 420px;
					left: 0;
					top: 50px;
					border-top: 2px solid #FF4201;
					z-index: 10;
					color: #333;
					text-align: left;
					/*菜单展开*/
					.navMain {
						width: 744px;
						height: 416px;
						border: 2px solid #F1F1F1;
						position: absolute;
						border: 3px solid #e53a00;
						border-top: 1px solid #e53a00;
						left: 190px;
						top: 0;
						z-index: 999;
						cursor: default;
						overflow: auto;
						.w-100 {
							width: 100px;
						}
					}
				}
				.nav li {
					width: 170px;
					height: 50px;
					line-height: 50px
				}
				.nav li:hover {
					background: #e53a00;
				}
			};
			.nav-w>li:hover{
				background: #FF4201;
				color: #fff;
			}

		}
		
	}
		#shopStore{
			margin: 0 auto;
			font-size: 16px;
			.banner{
				width: 100%;
				height: 120px;
				/*// background-size: cover;*/
			    background-repeat:no-repeat;
			    background-position:center center;
			}
			.defaultsStore-wrap{
				height:120px;
				background:url(../../assets/store-small.png);
				.defaultsStore{
					height: 120px;
				}
			}
			.bannerBig{
				height: 600px;
			}
			.storeSearch{
				height: 50px;
				line-height: 50px;
				background: #FF4201;
				text-align: center;
				ul li {
					width: 132px;
				}
				ul li:nth-child(1) {
					width: 190px;
				}
				ul li:hover {
					background: rgba(0,0,0,0.1);
				}
				.bg-rgba{
					background: rgba(0,0,0,0.1);
				}
				.el-input{
					width: 270px;
				};
				.el-input__inner{
					border-right: 0;
					height: 30px !important;
				};
				.el-button{
					width: 80px;
					height: 30px;
					border-left: 0;
					border-radius: 0;
					background: #F5F5F5;
				};
			}

		}
		.word {
			color: #FF4201;
		}
		.color-bb {
			color: #BBBBBB;
		}
		.w-100 {
			width: 80px;
		}
		.w-1000 {
			width: 1000px;
			.w-100{
				line-height: 60px;
				height: 60px;
			}
			.s-100{
				width: 90px;
				height: 60px;
				display: inline-block;
			}
		}
		.li:hover {
			color: #FF4100;
		}
		.bgimg {
			background: url(../../assets/bg-dashed.png) 0px bottom;
			background-repeat: repeat-x;
			overflow: hidden;
			transition: all .5s;
		}
		.bgimg:last-child{
			background-image: none;
		}
		.main {
			.el-dropdown-link{
				color:#666;
			}
			.four {
				padding: 17px 0 17px 0;
				i {
					font-size: 5px;
					color: #DDDDDD;
				}
			}
		}
		.activityInfo{
			background: #fff;
			padding: 20px;
			box-sizing: border-box;
			.itemDiv{
				margin-top:10px;
				.activityStatusName{
					color: #ff4201;
				}
			}
		}
	}
</style>
