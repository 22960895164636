<template>
	<div id="sales-report" v-loading='loading'>
		<div class="title-top bg-f clearfix mb-5">
			<span>销售报表</span>
		</div>
		<div class="bg-f pb-50 pl-30 pr-30">
			<div class="clearfix">
				<el-form :inline='true' class="pt-30 fl search" :model="form" label-width="80px"  ref="form">
					<el-form-item label='买家名称'>
						<el-input v-model.trim="form.buyerName"></el-input>
					</el-form-item>
					<el-form-item label='买家电话'>
						<el-input v-model.trim="form.buyerMobile" @input="form.buyerMobile=form.buyerMobile.replace(/[\u4e00-\u9fa5]/ig,'')"></el-input>
					</el-form-item>
					<el-form-item label='业务员'>
						<el-select v-model="form.managerId" filterable clearable remote placeholder="请输入关键字或选择业务员" :remote-method="getManagerList">
							<el-option :label="item.employeeName" v-for="(item,index) in managerList" :value="item.managerId" :key="index">{{item.employeeName}}({{item.loginName}})</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label='下单时间'>
						<ys-daterange-picker v-model="dateRange"/>
					</el-form-item>
				</el-form>
			</div>
            <div class="pt-5">
                <el-button class="" icon="el-icon-search" type='info' @click="handleSearch">查 询</el-button>
            </div>
			<div class='tar'>
				<el-button type='text' @click="handDown">批量导出</el-button>
			</div>
			<el-table :data="salesReportList" stripe style="width: 100%;" @selection-change="handleSelectionCheck">
				<el-table-column type="selection" width="60"></el-table-column>
				<el-table-column label='买家名称' prop="buyerName"></el-table-column>
				<el-table-column label='买家电话' prop="buyerPhone" align="center"></el-table-column>
				<el-table-column label='数量' prop="orderCount" align="center"></el-table-column>
				<el-table-column label='金额' prop="amount" align="center"></el-table-column>
				<el-table-column label='最后下单时间' align="center" width="155">
					<template slot-scope="scope">
						<span>{{scope.row.gmtCreate | timenyr}}</span>
					</template>
				</el-table-column>
				<el-table-column label='业务员' prop="managerName" align="center"></el-table-column>
			</el-table>
			<el-pagination v-if='total>0' class="tar mt-15" :current-page="currentPage" :page-size='10' :total='total' layout="prev , pager, next, jumper" @current-change="handleCurrentChange">
				<!-- 分页组件 -->
			</el-pagination>
		</div>
		<!-- 导出 -->
		<form ref="downOrderForm" action='/sunrise-gateway/export/orderSaleCountReport/customer/list' style="display: none">
			<input type="hidden" name="modelName" value="orderSaleCountReport.customer.list">
			<input type="hidden" name="fileName" value="销售报表.xls">
			<input type="hidden" name="startRow" value="1">
			<input type="hidden" name="sellerId" v-model="formDown.sellerId">
			<input type="hidden" name="managerId" v-model="formDown.managerId">
			<input type="hidden" name="buyerName" v-model="formDown.buyerName">
			<input type="hidden" name="buyerMobile" v-model="formDown.buyerMobile">
			<input type="hidden" name="gmtOrderStart" v-model="formDown.gmtOrderStart">
			<input type="hidden" name="gmtOrderEnd" v-model="formDown.gmtOrderEnd">
			<input type="hidden" name="isExport" v-model="isExport">
			<input type="hidden" name="reportList" v-model="reportList">
		</form>
	</div>
</template>

<script>
	import { axiosApi, basePath } from "~/api/api"
	import { mapActions } from "vuex"
	import YsDaterangePicker from "~/components/ys-daterange-picker";
	export default {
		components: {YsDaterangePicker},
		data() {
			return {
				isExport: 1,
				form: {
					sellerId: sessionStorage.getItem('traderId'),
					buyerName: null, //买家名称
					buyerMobile: null, //买家手机
					managerId: null, //业务员id
					gmtOrderStart: null, //下单开始时间
					gmtOrderEnd: null, //下单结束时间
					isExport: 0
				},
				dateRange: [],
				salesReportList: [], //报表数据列表
				currentPage: 1,
				total: 0,
				loading: false,
				formDown: {
					sellerId: sessionStorage.getItem('traderId'),
					buyerName: null, //买家名称
					buyerMobile: null, //买家手机
					managerId: null, //业务员id
					gmtOrderStart: null, //下单开始时间
					gmtOrderEnd: null, //下单结束时间
					isExport: 0
				},
				reportList: null
			}
		},
		activated() {
			this.$nextTick(() => {
				this.querySalesReport()
				this.getManagerList()
			})
		},
		computed: {
			managerList() {
				return this.$store.state.salesMan.people
			}
		},
		methods: {
			...mapActions({
				"findSales": "findSales"
			}),
			handDown(){
				if(!this.salesReportList || this.salesReportList.length === 0){
					this.$message.info('暂无可导出的数据')
					return
				}
				this.$refs.downOrderForm.submit();
			},
			handleSearch() { //查询
				this.form.gmtOrderStart = !this.dateRange[0] ? null : + new Date(this.dateRange[0])
				this.form.gmtOrderEnd = !this.dateRange[1] ? null : + new Date(this.dateRange[1])
				this.formDown = Object.assign({},this.form)
				if(this.currentPage === 1){
					this.querySalesReport()
				}else{
					this.currentPage = 1;//赋值自动调用一次接口
				}
			},
			handleCurrentChange(val) {
				this.currentPage = val
				this.querySalesReport()
			},
			/* 获取销售报表信息 */
			querySalesReport() {
				this.loading = true
				axiosApi('/order/saleCountReport/list', this.form, (res) => {
					this.loading = false
					this.salesReportList = res.data.data.elements
					this.total = res.data.data.totalCount
				}, this.currentPage, 10, (res) => {
					this.salesReportList = []
					this.loading = false
					this.$message({
						type: 'error',
						message: res.data.message
					})
				})
			},
			handleSelectionCheck(val){
				const n = val.map(n => {
					return {
						amount: n.amount,
						orderCount: n.orderCount,
						buyerName: n.buyerName,
						buyerPhone: n.buyerPhone,
						gmtCreate: n.gmtCreate,
						managerName: n.managerName,
						sellerName: n.sellerName,
						sellerPhone: n.sellerPhone
					}
				})
				this.reportList = JSON.stringify(n)
			},
			getManagerList(query){
				this.findSales(query)
			}
		}
	}
</script>

<style lang="less">
	#sales-report {
	}
</style>