<template>
	<div class="step3 width-1280">
		<div class="mb-50 mt-50">
			<!-- <img class="sub mr-10" src="../../assets/register.png" /> -->
			<i class="iconfont icon-inspector color-b font-18 mr-10"></i>
			<span class="color-6 font-16">
				<span v-if="bool">2/2</span>
				<span v-else>3/3</span>
				完成
			</span>
		</div >
		<div class="w330 tac">
			<img src="../../../assets/register-success.png"/>
			<p class="color-danger mt-30 font-30 lh-30">修改密码成功</p>
			<el-button class="mt-50" @click="goPage">返回</el-button>
			
		</div>
	</div>
</template>
<script>
	export default{
		data(){
			return{
				bool:false//判断是忘记密码还是修改密码
			}
		},
		mounted(){
			this.$nextTick(()=>{
				this.bool = this.$route.params.path == "cpf";
			})
		},
		methods:{
			goPage(){
				this.$router.push('/customer/accountSecurity')
			}
		}
	}
	
</script>