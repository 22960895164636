<template>
	<div class="client-updata width-1280 pb-50" v-loading="pictureLoading">
		<div class="mb-50">
			<span>升级为注册用户</span>
		</div>
		<el-form :model="form" :rules="rules" ref="form" label-width='170px'>
			<el-form-item label="手机号码:">
				<el-input v-model.trim='form.tel' disabled></el-input>
			</el-form-item>
			<el-form-item label="设置密码:" prop="pass">
				<el-input :type="showPass?'text':'password'" @keyup.native="isCaps($event)" @blur="capB1 = false" @focus="isCaps($event)" v-model="form.pass" :maxlength="20" auto-complete="off">
					<i slot="suffix" class="el-icon-view" @click="showPass=!showPass"></i>
				</el-input>
				<!-- <el-input type="password" @keyup.native="isCaps($event)" @blur="capB1 = false" @focus="isCaps($event)" v-model="form.pass" :maxlength="16" auto-complete="off"></el-input> -->
				<img class="cap" v-if="capB1" src="../../assets/caps.png" />
				<span v-if="form.pass.length<6" class="color-9 ml-10">密码必须是6-20位，且数字、大写字母、小写字母、标点符号至少包含两种。</span>
				<div v-else id="tips"><span></span><span></span><span></span><span></span></div>
			</el-form-item>
			<el-form-item label="确认密码:" prop="checkPass">
				<el-input :type="showCheckPass?'text':'password'" @keyup.native="isCaps2($event)" @blur="capB2 = false" @focus="isCaps2($event)" v-model="form.checkPass" :maxlength="20" >
					<i slot="suffix" class="el-icon-view" @click="showCheckPass=!showCheckPass"></i>
				</el-input>
				<!-- <el-input type="password" @keyup.native="isCaps2($event)" @blur="capB2 = false" @focus="isCaps2($event)" v-model="form.checkPass" :maxlength="16"></el-input> -->
				<img class="cap" v-if="capB2" src="../../assets/caps.png" />
			</el-form-item>
			<el-form-item label="营业执照编码:" prop="licenseNum">
				<el-input v-model.trim="form.licenseNum" @blur="getK3List" placeholder="请输入内容"></el-input>
			</el-form-item>
			<el-form-item label="门店编码:" v-if="form.storeCodeNo">
				<el-input v-model="form.storeCodeNo" disabled></el-input>
			</el-form-item>
			<el-form-item label="门店类型:" v-if="form.storeCodeNo">
				<el-input v-model="form.sellerType" disabled></el-input>
			</el-form-item>
			<el-form-item label="商家名称:" prop="storeName">
				<el-input v-model.trim="form.storeName" :disabled="!!form.storeCodeNo"></el-input>
			</el-form-item>
			<el-form-item label='商户地址:' required>
				<selectArea v-model="form.selectedOptions" @changeName="changeName" />
			</el-form-item>
			<el-form-item props="attr">
				<el-input v-model.trim="form.attr" :disabled="!!form.storeCodeNo"></el-input>
			</el-form-item>
			<el-form-item label="营业执照照片:" prop="dialogImageUrl">
				<p class="color-9 lh-14 p10">图片仅支持jpg,jpeg,png,bmp格式,文件小于4M</p>
				<div :class="{noAdd:form.dialogImageUrl.length, hasP: !!form.storeCodeNo}">
					<input type="hidden" v-model="form.dialogImageUrl" />
					<el-upload action="/sunrise-gateway/oss/ossUpload" :file-list="form.dialogImageUrl" list-type="picture-card" :on-preview="handlePictureCardPreview" :on-success="handleAvatarScucess" :before-upload="beforeAvatarUpload" :on-remove="handleRa" >
						<i class="el-icon-plus"></i>
					</el-upload>
					<el-dialog :visible.sync="dialogVisible" size="tiny">
						<img width="100%" :src="dialogImageUrl" alt="show-message">
					</el-dialog>
				</div>
			</el-form-item>
			<el-form-item label="门店照片:" prop="dialogImageUrlList">
				<p class="color-9 lh-14 p10">图片仅支持jpg,jpeg,png,bmp格式,文件小于4M</p>
				<div :class="{noAdd:form.dialogImageUrlList.length >= 2}">
					<input type="hidden" v-model="form.dialogImageUrlList" />
					<el-upload action="/sunrise-gateway/oss/ossUpload" :file-list="form.dialogImageUrlList" list-type="picture-card" :on-preview="handlePictureCardPreview" :on-success="handleAvatarSuccessList" :on-remove="handleRemove" :before-upload="beforeAvatarUpload">
						<i class="el-icon-plus"></i>
					</el-upload>
					<el-dialog :visible.sync="dialogVisible" size="tiny">
						<img width="100%" :src="dialogImageUrl" alt="show-message">
					</el-dialog>
				</div>
				<!--<div v-else class="hasP">
					<el-upload action="/sunrise-gateway/oss/ossUpload" :file-list="form.dialogImageUrlList" list-type="picture-card" :on-preview="handlePictureCardPreview">
						<i class="el-icon-plus"></i>
					</el-upload>
					<el-dialog v-model="dialogVisible" size="tiny">
						<img width="100%" :src="dialogImageUrl" alt="show-message">
					</el-dialog>
				</div>-->
			</el-form-item>
			<el-form-item label="客户分组">
				<el-cascader v-model="form.groupId" clearable :options="treeList" :props="treeProps"></el-cascader>
			</el-form-item>
			<el-form-item
          label="客户类型:"
          prop="cType"
        >
          <el-select
            v-model="form.cType"
            clearable
						:disabled="!!form.storeCodeNo"
            placeholder="请选择客户类型"
          >
            <el-option
              :label="item.name"
              :value="item.id"
              v-for="item in prepareList"
              :key="item.id"
            ></el-option>
          </el-select>
        </el-form-item>

				<el-form-item label="K3编码:" prop="k3No" v-if="needK3No">
          <!-- <el-input
            v-model.trim="form.k3No"
            placeholder="请输入K3编码"
            :disabled="!!form.storeCodeNo"
          ></el-input> -->
					<el-select v-model="form.k3No" :disabled="!!form.storeCodeNo" clearable placeholder="请选择">
            <el-option
              v-for="item in K3info"
              :key="item.custNumber"
              :label="item.custNumber"
              :value="item.custNumber">
              <div style="line-height:1.5">
                <div>{{item.custName}}</div>
                <div>{{item.custNumber}}</div>
              </div>
            </el-option>
          </el-select>
					<el-tooltip class="item" effect="dark" content="K3数据更新" placement="right">
						<el-button @click="getK3List" style="width:30px;height:30px;background:#409eff;padding:0;line-height:30px;color:#fff" icon="el-icon-refresh" circle></el-button>
					</el-tooltip>
        </el-form-item>


			<el-form-item label="联系人姓名:">
				<el-input v-model.trim="form.linkname"></el-input>
			</el-form-item>
			<!--<el-form-item label="推荐经销商:" class="mb-30" v-if="!isCityPartner">-->
				<!--<el-select v-model='form.bindingDealerTraderId' filterable clearable placeholder="请选择经销商">-->
					<!--<el-option :label="item.storeName" :value="item.buyerId" v-for='item in agencyList' :key='item.buyerId'></el-option>-->
				<!--</el-select>-->
			<!--</el-form-item>-->
			<el-form-item class='mt-50 mb-0'>
				<el-checkbox v-model="checked" @change="changeCheckbox">已阅读
					<router-link :to="`${basePath}/aggrement`" target=_ blank>
						<span class="color-3 hover-danger ml-6 color-blue cursor">《久加久平台服务协议》</span>
					</router-link>
				</el-checkbox>
			</el-form-item>
			<el-form-item class='mt-50 mb-0'>
				<el-button type='danger' class="commit-btn" @click="commit" :disabled="!checked">提交</el-button>
			</el-form-item>
			<el-dialog class="complete-dialog" :visible.sync="completeVisible" :close-on-click-modal='false'>
				<div class="content">
					<p class="title mt-50 mb-50">商户已升级成为注册用户！</p>
					<p class="tip mb-100"> {{time}} 秒后自动跳转到首页...</p>
					<el-button @click="jummp">点击跳转</el-button>
				</div>
			</el-dialog>
		</el-form>

	</div>
</template>

<script>
	import { axiosApi, basePath } from "../../api/api"
	import { passcheck, capsisopen, passfocus } from "../../utils/passcheck.js"
	import { MessageBox } from 'element-ui';
	import md5 from "md5"
	import { mapActions } from 'vuex'
	function validatePassword(password) {
		const types = {
			digit: /\d/,
			upper: /[A-Z]/,
			lower: /[a-z]/,
			symbol: /[\W_]/ // 标点符号和其他符号
		};
		const requiredTypes = Object.values(types);
		const isValidLength = password.length >= 6 && password.length <= 20;
		const hasRequiredTypes = requiredTypes.some(regex => regex.test(password));
		const hasTwoTypes = requiredTypes.filter(regex => regex.test(password)).length >= 2;
	
		return isValidLength && hasRequiredTypes && hasTwoTypes;
	}
	export default {
		data() {
			var validatePass = (rule, value, callback) => {
				if(value === '') {
					callback(new Error('请输入密码'));
				} else {

					const regex = /^(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*]).{6,20}$/;

					
					if(validatePassword(value)){
						if(this.form.checkPass !== '') {
							this.$refs.form.validateField('checkPass');
						}
						callback();
					}else{
						callback(new Error('密码必须是6-20位，且数字、大写字母、小写字母、标点符号至少包含两种。'));
					}
					
				}
			};
			var validatePass2 = (rule, value, callback) => {
				if(value === '') {
					callback(new Error('请再次输入密码'));
				} else if(value !== this.form.pass) {
					callback(new Error('两次输入密码不一致!'));
				} else {
					callback();
				}
			};
			var validateAddress = (rule, value, callback) => {
				var info = this.form.selectedOptions
				if (info.length===0) {
					callback(new Error('请选择省市区'));
				} else {
					if(info[0]==null){
						callback(new Error('请选择省'));
					}else if(info[1]===null){
						callback(new Error('请选择市'));
					}else if(info[2]===null){
						callback(new Error('请选择区'));
					}else{
						callback();
					}
				}
			};
			return {
				showPass:false,
				showCheckPass: false,
				K3info: [],
				agencyList: [], // 经销商
				prepareList: [], // 
				needK3No: false, // 是否显示K3编码 
				basePath: basePath,
				hlepregister: false,
				pictureLoading: false, //上传图片loading，或者点击下一步页面加载跳转
				traderPhotoRelationParams: [], //审核门店照片信息
				form: {
                    // cusTypeId:'',//客户类别
					bindingDealerTraderId: "",
					buyerSellerRelationId: "", //进货商关联关系表主键
					storeName: null,
					attr: null,
					licenseNum: '',
					linkname: null,
					selectedOptions: [],
					photo: null,
					dialogImageUrlList: [], //门店照片信息
					photos: null,
					dialogImageUrl: [], //记录营业执照照片
					state: "", //1 表示审核中  3表示未通过
					reason: "",
					pass: "",
					checkPass: "",
					tel: '', // 手机
					code: '', //手机短信验证码
					cType: 2,
					k3No: null,
					groupId: null,
					storeCodeNo: null,
					sellerType: null,
					streetId: null,
					streetName: null
				},
				dialogVisible: false,
				fileList: [], //门店照片的url
				dialogImageUrl: null, //只做门店照片的预览用
				rules: {
					storeName: [{
						required: true,
						message: '请输入商户名称',
					}, {
						min: 2,
						max: 40,
						message: '请输入2-40个字符',
					}],
					linkname: [{
						required: true,
						pattern: /^([A-Za-z]|[\u4E00-\u9FA5]){2,6}$/,
						message: '请输入2-6个字符，可为汉字或字母'
					}],
          selectedOptions: [{ validator: validateAddress, trigger: 'change' }],
					attr: [{
						required: true,
						message: '请输入详细地址',
					}, {
						min: 4,
						max: 50,
						message: '请输入4-50个字符',
					}],
					licenseNum: [{
						required: true,
						pattern: /^[0-9a-zA-Z]{10,20}$/,
						message: '请输入10-20个字符，可为字母数字'
					}],
					dialogImageUrl: [{
						required: true,
						type: "array",
						message: '请上传营业执照照片',
					}],
					dialogImageUrlList: [{
						required: true,
						type: "array",
						message: '请上传门店照片',
					}],
					pass: [{
						required: true,
						validator: validatePass,
						trigger: 'blur'
					}],
					checkPass: [{
						required: true,
						validator: validatePass2,
						trigger: 'blur'
					}],
					code: [{
						required: true,
						message: '请输入验证码',
						trigger: 'blur'
					}, {
						min: 6,
						max: 6,
						message: '请输入6位验证码',
						trigger: 'blur'
					}],
					k3No: [{
						required: true,
						message: '请输入K3编码',
						trigger: 'blur'
					}]
				},
				capB1: false, //判断大小写键是否打开
				capB2: false, //判断大小写键是否打开
				completeVisible: false, // 升级完成提醒
				time: 5,
				checked: false, //协议选择框
				cleartime: null, // 清除循环
				storeInfo: {}, //录入客户信息
				isCode: false, //验证码错误信息是否显示
				codeDisabled: false, //获取验证码按钮是否可点击
				timeCode: "59", //倒计时时间
				timeBool: false,
				codeBool: true,
				bool: false, // 判断是否选中门店编码，若未选中则清除带出信息
				resetForm: {},
				fourLists: [], //四级地址
				treeList: [], // 客户分组list
				treeProps: {
					value: 'id',
					label: 'name',
					children:'sub',
					emitPath:false
				},
				areaNameList: []
			}
		},
		watch: {
			bool(val) {
				if(!val && this.form.storeCodeNo) {
					this.resetFormInfo()
				}
			}
		},
		computed: {
			isCityPartner() {
				return this.$store.state.clientType.isCityPartner;
			},
		},
		mounted() {
			this.$nextTick(() => {
				this.findStoreInfo({traderId: this.$route.query.traderId})
				this.getAgencyList()
				this.getPrepare()
				this.getLeftTree()
			})
		},
		methods: {
			...mapActions('clientType',['getUserInfo']),
			getAgencyList() {
				let url = '/trader/findClientAllList/select';
				axiosApi(url, {
					"sellerId": sessionStorage.getItem('traderId'),
					"ctype": "1"
				}, res => {
					this.agencyList = res.data.data.elements;
				})
			},
			 // 获取客户分组
			getLeftTree(){
					// 查询 左侧树
					axiosApi('/trader/customer/group/listTree',{},(res) => {
							this.treeList = this.treeInit(res.data.data);
					});
					// 默认查询 全国区域 右侧表格数据
			},
			treeInit(tree){
				tree.forEach(item=>{
					if(item.sub.length>0){
						this.treeInit(item.sub)
					}else{
						delete item.sub
					}
				})
				return tree
			},
			getK3List(){
				axiosApi('/trader/k3CustomerInfos/getByLicenseNum',{licenseNum:this.form.licenseNum},res => {
					if(res.data.code==='000000'){
						this.K3info = res.data.data
						if(res.data.data[0]&& !this.form.k3No){
							// 默认选中第一个
							
							this.form.k3No = res.data.data[0].custNumber || null 
						}
					}
				})
			},
			getPrepare(){
				axiosApi('/trader/proxyClientRegister/prepare',{},res => {
					if(res.data.code==='000000'){
						this.prepareList = res.data.data.cTypeVoList
						this.needK3No = res.data.data.needK3No
					}
				})
			},
			changeCheckbox(e) { // 解决火狐el-checkbox 不能选中问题
				const userAgent = navigator.userAgent
				if(userAgent.indexOf("Firefox") > -1) {
					this.checked = !this.checked
				}
			},
			resetFormInfo() {
				this.form = {
					...this.resetForm,
					licenseNum: this.form.licenseNum
				}
			},
			findStoreInfo(body) { //初始化录入用户信息
				axiosApi("/unlogin/trader/findStoreInfo/select", body, (res) => {
					if(res.data.data) {
						let data = res.data.data
						this.storeInfo = data
						this.form.tel = data.tel //手机号码
						this.form.storeName = data.storeName //商家名称
						let selectedOptions = [];
						var addressVO = data.addressVO
						selectedOptions.push(addressVO.provinceId?addressVO.provinceId.toString():null, addressVO.cityId?addressVO.cityId.toString():null, addressVO.regionId?addressVO.regionId.toString():null,addressVO.streetId?addressVO.streetId.toString():null);
						this.form.selectedOptions = selectedOptions;
						this.form.attr = data.addressVO.addr
	
						this.form.linkname = data.linkname //联系人姓名
						//营业执照照片
						if(data.licenseImg) {
							this.form.dialogImageUrl = [{
								url: data.licenseImg
							}];
						}
						// 门头照片
						let photos = data.photos || [];
						this.form.dialogImageUrlList = [];
						photos.forEach((item) => {
							this.form.dialogImageUrlList.push({
								url: item.photoUrl,
								id: item.id
							});
						})

						this.form.storeCodeNo = res.data.data.storeCodeNo
						this.form.cType = res.data.data.cType
						this.form.groupId = res.data.data.groupId
						// this.form.cusTypeId = [res.data.data.cusTypeId]

						this.resetForm = Object.assign({}, this.form)
					}
				})
			},
			isCaps(e) {
				this.capB1 = passfocus()
				if(this.form.pass.length >= 6) {
					passcheck(this.form.pass) //检测密码强度
				}
				this.capB1 = capsisopen(e, this.form.pass) //检测大小写键是否打开
			},
			isCaps2(e) {
				this.capB2 = passfocus()
				this.capB2 = capsisopen(e, this.form.checkPass) //检测大小写键是否打开
			},
			handleAvatarScucess(res) { //营业执照照片
				//console.log(res, "aa")
				this.pictureLoading = false;
				this.form.dialogImageUrl.push({
					url: res.data[0]
				})
				this.$refs.form.validateField("dialogImageUrl"); //部分表单字段验证
			},
			handleAvatarSuccessList(res) { //门店照片
				this.form.dialogImageUrlList.push({
					url: res.data[0]
				});
				this.pictureLoading = false;
				this.$refs.form.validateField("dialogImageUrlList"); //部分表单字段验证
			},
			handleRa(file, fileList) { //营业执照照片删除
				this.form.dialogImageUrl = []
				this.form.dialogImageUrl = fileList
				this.$refs.form.validateField("dialogImageUrl"); //部分表单字段验证
			},
			handleRemove(file, fileList) { //门店照片删除
				this.form.dialogImageUrlList = [];
				this.form.dialogImageUrlList = fileList;
				if(this.form.state == 3 && file.id) {
					//console.log(file, fileList)
					let obj = {};
					obj.traderId = this.$route.params.id;
					obj.photoId = file.id;
					obj.type = 2;
					this.traderPhotoRelationParams.push(obj);

				}
				this.$refs.form.validateField("dialogImageUrlList"); //部分表单字段验证
			},
			beforeAvatarUpload(file) {
				let bool = this.isUploadImg(file)
				if(bool) {
					this.pictureLoading = true
				}
				return bool
			},
			handlePictureCardPreview(file) {
				this.dialogImageUrl = file.url; //门店照片的放大预览
				this.dialogVisible = true;
			},
			
			changeName(e){ // 省市区街道地址名
				this.areaNameList = e
			},
			commit() { //提交
				this.$refs.form.validate((valid) => {
					if(valid) { //验证通过
						this.fileList = []
						this.form.dialogImageUrlList.forEach((item, index) => {
							this.fileList.push(item.url);
						})
						const body = {
							traderId: this.$route.query.traderId,
							employeeId: this.storeInfo.employeeId,
							loginPwd: md5(this.form.pass),
							licenseNum: this.form.licenseNum,
							licenseImg: this.form.dialogImageUrl[0].url,
							photoUrl: this.fileList,
							provinceId: this.form.selectedOptions[0],
							cityId: this.form.selectedOptions[1],
							regionId: this.form.selectedOptions[2],
							streetId: this.form.selectedOptions[3],
							provinceName: this.areaNameList[0],
							cityName: this.areaNameList[1],
							regionName: this.areaNameList[2],
							streetName: this.areaNameList[3],
							addr: this.form.attr,
							linkname: this.form.linkname,
							storeName: this.form.storeName,
							cType: this.form.cType,
							k3No:this.form.k3No,
							groupId: this.form.groupId,
							storeCodeNo: this.form.storeCodeNo,
							sellerType: this.form.sellerType,
						}
						if(this.$route.query.type === '1' || this.$route.query.type === '2') { //列表客户升级
							this.traderUpgrade('/trader/traderUpgrade/update', body)
						} else if(this.$route.query.type === '0') { //注册
							//用户自己升级的时候必须项目
							body.mobile = this.form.tel //手机号码
							body.messageCode = this.form.code //手机验证码

						}
					}
				})
			},
			traderUpgrade(api, body) { //升级注册用户接口
				axiosApi(api, body, (res) => {
					if(this.$route.query.type !== '0') {
						this.$message({
							showClose: true,
							message: '升级成功！',
							type: 'success'
						})
					}
					if(this.$route.query.type === '1') { //返回列表
						this.$router.push(`${basePath}/clientList`)
					} else if(this.$route.query.type === '0') { //登录
						this.openDialog()
					} else if(this.$route.query.type === '2') { //详情
						this.$router.push(`${basePath}/clientEdit/${this.$route.query.id}/0/${this.$route.query.buyerId}`)
					}
				}, null, null, (res) => {
					MessageBox.alert(res.data.message, '提示信息', {
						confirmButtonText: '确定'
					});
				})
			},
			openDialog() { //升级完成弹框提示
				this.completeVisible = true
				this.time = 5
				this.cleartime = setInterval(() => {
					this.time--
						if(this.time === 0) {
							this.jummp()
						}
				}, 1000)
			},
			jumpLogin() { //登录
				axiosApi('/employee/login', {
					"loginName": this.form.tel,
					"loginPass": md5(this.form.pass),
					"appId": "2",
					"loginType": "1"
				}, (res) => {
					this.getUserInfo({
						body: {
							"loginName": this.form.tel,
							appId: 2
						},
						isLogin: true
					})
				})
			},
			jummp() { //立即跳转
				clearTimeout(this.cleartime)
				this.completeVisible = false
				this.jumpLogin()
			},
			getCode() { //获取短信验证码
				this.codeDisabled = true;
				if(!this.codeBool) {
					clearTimeout(this.clearTime);
					this.codeBool = true;
					this.timeCode = "59"
				}
				this.timeBool = true;
				const _this = this
				this.clearTime = setInterval(function() {
					_this.timeCode--;
					if(_this.timeCode == 0) {
						_this.codeBool = false;
						_this.codeDisabled = false;
					}
				}, 1000)
				const api = "/sms/sendSingle"
				const body = {
					mobile: this.form.tel,
					smsTemplate: "9",
					smsType: "sms0006", //升级录入客户
					appId: 2,
					smsPurposeType: 1
				}
				axiosApi(api, body, () => {}, 1, 10);
			},
		}
	}
</script>

<style lang="less">
	.client-updata {
		.dropdown_wrap {
			padding: 10px 0;
			border: 1px solid #ddd;
			position: absolute;
			width: 240px;
			top: 34px;
			z-index: 100;
			box-sizing: border-box;
			background-color: #fff;
			border-radius: 2px;
			box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
			transform-origin: center top 0px;
			/*// max-height: 300px;*/
			/*// overflow-y: scroll;*/
			.dropdown_item {
				padding: 10px;
				border-bottom: 1px solid #ddd;
				cursor: pointer;
				font-size: 14px;
				line-height: 18px;
				p {
					color: #666;
					font-size: 12px;
					line-height: 16px;
				}
			}
			.dropdown_item:hover {
				background-color: #eee;
			}
			.dropdown_item:last-child {
				border-bottom: 0;
			}
		}
		.w115 {
			width: 115px;
		}
		.commit-btn {
			width: 240px;
		}
		.complete-dialog {
			.el-dialog {
				width: 900px;
				.el-dialog__header {
					display: none;
				}
				.content {
					text-align: center;
					.title {
						font-size: 28px;
					}
				}
			}
		}
		padding: 50px 0 0 200px;
		.el-form-item__label {
			padding-right: 30px;
		}
		.p10 {
			padding: 9px 0 10px;
		}
		.ml-200 {
			margin-left: -200px;
		}
		.el-input.is-disabled .el-input__inner {
			background-color: #fff;
			color: #333;
		}
		.el-cascader.is-disabled .el-cascader__label {
			color: #333;
			cursor: not-allowed;
		}
		.noAdd {
			.el-upload.el-upload--picture-card {
				display: none;
			}
		}
		.w100 {
			width: 100px;
		}
		.w130 {
			width: 130px;
		}
	}
	.el-select-dropdown__item{
		height: auto;
		padding: 5px 20px;
	}
</style>
