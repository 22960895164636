<template>
    <div class="add-gift-sent" :loading="loading">
        <el-form :model="form" label-width="98px" ref="form" :rules="rules">
            <el-form-item label="活动名称:" prop="activityName">
                <div class="inline-block">
                    <el-input v-model.trim="form.activityName" palceholder="输入活动名称" class=""></el-input>
                </div>
                <div class="inline-block ml-40">
                    <template>
                        <!-- <el-checkbox v-model="isOvering">可叠加</el-checkbox> -->
                        <el-checkbox v-model="isNewUser">是否首单</el-checkbox>
                    </template>
                </div>
            </el-form-item>
            <el-form-item label="活动时间:" prop="value1" required>
                <div class="">
                    <template>
                        <div>
                            <ys-daterange-picker v-model="form.value1" type="datetime"/>
                        </div>
                    </template>
                </div>
            </el-form-item>
        </el-form>
        <el-form :model="form" label-width="98px" class="">
            <el-form-item label="活动规则:">
                <template>
                    <div>
                        <el-radio-group v-model="secradio" @change="handleSeRadio">
                            <el-radio class="radio" label="1">订购金额</el-radio>
                            <el-radio class="radio" label="2">订购数量</el-radio>
                            <el-radio class="radio" label="3">每满额送</el-radio>
                            <el-radio class="radio mr-30" label="4">每满件送</el-radio>
                        </el-radio-group>
                        <!-- <span>订购金额</span> -->
                        <div class="mt-20 ml-10">
                            <div v-if="secradio==2||secradio==4" class="mb-20">
                                <span class='redalert ml-10'>*</span>
                                <span class="">商品类型:</span>
                                <el-radio-group v-model="unitType" @change="handleUnitRadio">
                                    <el-radio class="radio" label="1">按箱配置</el-radio>
                                    <el-radio class="radio mr-30" label="2">按瓶配置</el-radio>
                                </el-radio-group>
                            </div>
                            <div class="mt-10" v-for="(x,y) in activityRulesList" :key="y"
                                 :style="{overflow: 'hidden'}">
                                <div class='clearfix'>
                                    <div class="fl">
                                        <span class='redalert ml-10'>*</span>
                                        <span class="" v-if="secradio==1||secradio==2">订购满</span>
                                        <span class="" v-if="secradio==3||secradio==4">每满</span>
                                        <span>
                                              <sy-input v-show="secradio == '1'" v-model="x.fullMoney" type="float" size="mini" :min="0.01"
                                                        :max="99999999.99"></sy-input>
                                              <sy-input v-show="secradio == '2'" v-model="x.fullAmount" type="int" size="mini" :min="1"
                                                        :max="99999999"></sy-input>
                                              <sy-input v-show="secradio == '3'" v-model="x.fullMoney" type="float" size="mini" :min="0.01"
                                                        :max="99999999.99"></sy-input>
                                              <sy-input v-show="secradio == '4'" v-model="x.fullAmount" type="int" size="mini" :min="1"
                                                        :max="99999999"></sy-input>
                                              <span v-if="secradio == '3'||secradio == '1'">元</span>
                                              <span v-if="(secradio == '2'||secradio == '4')&&unitType == 1">箱</span>
                                              <span v-if="(secradio == '2'||secradio == '4')&&unitType == 2">瓶</span>
                                        </span>
                                    </div>
                                    <div class="fl ml-20 mb-10">
                                        <el-button type="text" class="ml-20" style="width:auto;"
                                                   @click="addgiftcombination(y,x)">添加赠品组合
                                        </el-button>
                                        <i v-if="secradio=='1'||secradio=='2'" @click="deletemoneylist(y)"
                                           class="el-icon-circle-close font-16 cursor ml-20"></i>
                                    </div>
                                </div>

                                <div class="ml-20 clearfix" v-for="(item,i) in x.giftList" :key="i">
                                    <div class="mb-5">
                                        <span class='redalert ml-10'>*</span>
                                        <span>{{'赠品组合'+numTochinese(i+1)+': '}}</span>
                                        <el-button type="text" class="ml-20" style="width:auto;"
                                                   @click="addgift(y,x,i,item)">添加赠品
                                        </el-button>
                                        <i @click="deletecombination(y, i)"
                                           class="el-icon-circle-close font-16 cursor ml-20"></i>
                                    </div>
                                    <div class="ml-50">
                                        <div v-for="(n, key) in item.subgiftList" class="mb-10">
                                            <el-input v-model.trim="n.skuName" palceholder=""
                                                      :disabled="true"></el-input>
                                            <span class='redalert ml-10'>*</span>
                                            <span>数量</span>
                                            <sy-input v-model="n.quantity" type="int" size="mini" :min="1"
                                                      :max="99999999"></sy-input>
                                            <!--<span>{{n.giftUnit}}</span>-->
                                            <!--<span v-if="n.giftType == 1">{{n.giftUnit}}</span>
                                            <el-select v-else class="unit_select" v-model="n.unitType"
                                                       placeholder="请选择">
                                                <el-option v-for="item in unitOptions" :key="item.value"
                                                           :label="item.label" :value="item.value"></el-option>
                                            </el-select>-->
                                            <span v-if="n.originGiftType == 1">{{n.giftUnit}}</span>
                                            <el-select v-else class="unit_select" v-model="n.unitType"
                                                       placeholder="请选择">
                                                <el-option v-for="item in n.unitOptions" :key="item.value"
                                                           :label="item.label" :value="item.value"></el-option>
                                            </el-select>
                                            <span class="ml-20" v-if="secradio==3||secradio==4">上限</span>
                                            <sy-input v-if="secradio==3||secradio==4" v-model="n.limitQuantity"
                                                      :sent="true" type="int" size="mini" :min="n.quantity*1"
                                                      :max="99999999"></sy-input>
                                            <span v-if="secradio==3||secradio==4">
                                            <span v-if="n.originGiftType == 1">{{n.giftUnit}}</span>
                                                <span v-else>
                                                  {{n.unitType==1?'箱':'瓶'}}
                                                </span>
                                            </span>
                                            <!--新增 赠品类型-->
                                            <el-select v-model="n.giftType" class="unit_select2"
                                                       placeholder="请选择" >
                                                <el-option v-for="a in giftTypeData" :key="a.giftType"
                                                           :label="a.giftTypeName" :value="a.giftType"></el-option>
                                            </el-select>
                                            <i @click="delOnegift(y,i,key)"
                                               class="el-icon-circle-close font-16 cursor ml-20"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-10 ml-10">
                                <el-button type="text" class="btn" @click="addmoneylist"
                                           v-if="secradio == '1'||secradio == '2'">继续添加
                                </el-button>
                            </div>
                        </div>
                    </div>
                </template>
            </el-form-item>
        </el-form>

        <!-- 限定次数 -->
        <el-form :model="form" label-width="98px" class="mt-20">
            <el-form-item label="参与次数:" required>
                <div class="">
                    <template>
                        <div class="">
                            <el-radio class="radio" v-model="isLimitTimes" label="0">不限次数</el-radio>
                            <el-radio class="radio" v-model="isLimitTimes" label="1">限制次数</el-radio>
                        </div>
                        <div class="mt-20 ml-90" v-if="isLimitTimes == '1'">
                            <span class='redalert ml-10'>*</span>
                            <sy-input v-model="limitTimes" type="int" size="mini" :min="1" :max="99999999"></sy-input>
                            <span>次</span>
                        </div>
                    </template>
                </div>
            </el-form-item>
        </el-form>

        <!-- 选择客户 -->
        <el-form :model="form" label-width="98px" class="mt-20" :rules="rules">
            <el-form-item label="活动对象:" required>
                <div class="">
                    <template>
                        <el-radio-group v-model="sevradio" @change="handlesevradio">
                            <el-radio class="radio" label="1">所有客户</el-radio>
                            <el-radio class="radio" label="2">部分客户</el-radio>
                        </el-radio-group>
                    </template>
                    <div class="" v-show="sevradio=='2'">
                        <div class='tar'>
                            <el-button type='text' @click="addclient">添加客户</el-button>
                            <el-button type='text' @click="addAllClients">添加全部客户</el-button>
                            <!-- <el-button type='text' @click="delclients">批量删除</el-button> -->
                        </div>
                        <tableA :size="[100,200,110,170]"></tableA>
                        <chooseClient
                                v-on:closedialog="dialog2Visible = false"
                                :cVisible="dialog2Visible"
                                :ifnotauto="true"
                                :is-full-gift='true'
                        >
                        </chooseClient>
                    </div>
                </div>
            </el-form-item>
        </el-form>

        <!-- 选择商品-->
        <el-form :model="form" label-width="98px" class="">
            <el-form-item label="活动商品:" required>
                <div class="">
                    <template>
                        <el-radio class="radio" v-model="thiradio" label="1">所有商品</el-radio>
                        <el-radio class="radio" v-model="thiradio" label="2">部分商品</el-radio>
                        <!-- <el-radio class="radio" v-model="thiradio" label="3">部分商品不参与</el-radio> -->
                    </template>
                    <div class="" v-show="thiradio!='1'">
                        <div class='tar'>
                            <el-button type='text' @click="addGood">添加商品</el-button>
                            <el-button type='text' @click="addAllGoods">添加全部商品</el-button>
                        </div>
                        <tableB :size="[360,,205]" :noclassify="true"></tableB>
                        <chooseGoods
                                v-on:closedialog="dialog1Visible = false"
                                :goodVisible="dialog1Visible"
                                :ifnotauto="true"
                                :is-full-gift='true'
                                :activityId="sentid"
                        >
                        </chooseGoods>
                    </div>
                </div>
            </el-form-item>
        </el-form>
        <el-form label-width="98px">
            <div class="mt-10">
                <el-form-item>
                    <el-button type="danger" @click="onSubmit" :loading="updateloading">保存</el-button>
                    <el-button @click="back">取消</el-button>
                </el-form-item>
            </div>
        </el-form>
        <chooseGift
                v-on:closedialog="dialogVisible = false"
                :giftVisible="dialogVisible"
                :goodsNum="goodsNum"
                :isfullgift="true"
                :skuNoList="skuNoList"
        >
        </chooseGift>
    </div>
</template>

<script>
    import {mapMutations, mapActions} from 'vuex'
    import Confirm from '@/utils/formconfirm'
    import {activityname} from '@/utils/formverify'
    import {axiosApi, basePath} from '@/api/api'
    import YsDaterangePicker from "~/components/ys-daterange-picker";

    export default {
        components: {YsDaterangePicker},
        data() {
            var validatevalue1 = (rule, value, callback) => {
                if (!value[0] || !value[1]) {
                    return callback(new Error('请选择活动时间范围'));
                } else if (value[0].getTime() == value[1].getTime()) {
                    return callback(new Error('开始时间和结束时间应设置不同'));
                } else {
                    callback()
                }
            }
            return {
                giftTypeData:[],
                loading: false,
                chooseIndex: 0,
                form: {
                    activityName: '',
                    value1: [],
                },
                dialogVisible: false,
                dialog1Visible: false,
                dialog2Visible: false,
                limitTimes: "1",
                isOvering: false,
                isNewUser: false,
                checkList: [],
                thiradio: '1',//1全部商品参与，2部分商品参与，3部分商品不参与
                secradio: '1',//1按订购金额，2按订购数量
                sevradio: '1',//1全部客户参与，2部分客户参与
                unitType: null,// 按箱商品，按瓶商品
                isinited: false,// 已经初始化
                isIntegerMultiple: null,// 是否整箱购买
                isLimitTimes: '0',
                rules: {
                    activityName: [
                        {required: true, message: '请输入优惠券名称', trigger: 'blur'}
                    ],
                    value1: [
                        {validator: validatevalue1, trigger: 'change'}
                    ],
                },
                goodsNum: 5,//可选择赠品数量，默认为最大值5
                unitOptions: [{// 瓶箱下拉选择框
                    label: '箱',
                    value: 1
                }, {
                    label: '瓶',
                    value: 2
                }],
                isMounted: false,
                skuNoList: [],//赠品sku列表
            }
        },
        watch: {
            activityClientlist(val) {
                console.log(1111, this.isMounted, '0')
                if (this.isMounted) this.clearwilladd()// 清空商品
            },
            activityRulesList:{
                handler:function (val) {

                },
                deep:true
            }
        },
        computed: {
            activityClientlist() {// 客户列表
                return this.$store.state.clientlist.willadd
            },
            updateloading() {
                return this.$store.state.activity.updateloading
            },
            //满赠活动详情id
            sentid() {
                return sessionStorage.getItem('activitysentid')
            },
            postDataclient() {//已选择客户id
                return {
                    data: {
                        ids: this.$store.getters.willaddclientid,
                        // queryPage: 1, // 0-查询全部 1-分页查询
                        clientType: this.$route.query.activitySource || 1, // B2B和酒店
                    },
                    currentPage: 1
                }
            },
            postDatagood() {//已选择商品id
                if (this.secradio == 4 || this.secradio == 2) {//
                    this.isIntegerMultiple = this.unitType == '1'
                } else {
                    this.isIntegerMultiple = null
                }
                let buyerList = this.$store.state.clientlist.willadd || [];
                return {
                    data: {
                        notId: this.$store.getters.willaddid,
                        isIntegerMultiple: this.isIntegerMultiple,
                        buyerIds: buyerList.map(v => v.buyerId), // 添加 入参 buyerIds 选择客户的 buyerIds
                        activitySource: this.$route.query.activitySource || 1, // B2B和酒店
                        queryPage: 1, // 0-查询全部 1-分页查询
                        activityType: 1,// 活动类型 满赠送赠品 1  2-满减 3.限时限购 4.满赠(加价购)
                    },
                    currentPage: 1
                }
            },
            postData() {
                return {
                    data: {
                        goodsRelation: this.$store.state.ticket.willadd.map((a) => {
                            return {skuNo: a.skuNo}
                        }),
                        customerRelation: this.$store.state.clientlist.willadd.map((a) => {
                            return {buyerId: a.buyerId}
                        }),
                        activity: {
                            id: this.sentid,
                            activitySource: this.$route.query.activitySource || 1, // 传值 1,2 区别酒店营销，B2B营销
                            activityName: this.form.activityName,
                            gmtStart: Date.parse(this.form.value1[0]),
                            gmtEnd: Date.parse(this.form.value1[1]),
                            isLimitTimes: this.isLimitTimes,//是否限定次数
                            limitTimes: this.limitTimes,//限定次数
                            rangeType: this.thiradio,
                            rangCustomerType: this.sevradio,
                            activityType: '1',//1满赠，2满减
                            sellerId: sessionStorage.getItem('traderId'),
                            isOvering: this.isOvering ? '1' : '0',
                            isNewUser: this.isNewUser ? '1' : '0'
                        },
                        activityGiftRuleExLi: this.activityRulesList.map((a, b) => {/* 赠品list */
                            return {
                                fullMoney: this.secradio == 1 || this.secradio == 3 ? a.fullMoney : null,
                                fullAmount: this.secradio == 2 || this.secradio == 4 ? a.fullAmount : null,
                                type: this.secradio,
                                activityGiftRelationLi: this.arrayTogiftList(a.giftList),
                                unitType: this.unitType
                            }
                        })
                    }
                }
            },
            activityRulesList() {
                return this.$store.getters.getfullgiftList
            },
            activitySource() {
                return (this.$route.query.activitySource ? this.$route.query.activitySource : this.$route.query.couponSource) || 1
            }
        },
        methods: {
            ...mapMutations({
                'initgiftmoneyEvent': 'initgiftmoneyEvent',
                'initgiftnum': 'initgiftnum',
                'deletegoods': 'deletegoods',
                'casewilladdgiftData': 'casewilladdgiftData',
                'casewilladdgiftnum': 'casewilladdgiftnum',
                'deleteclients': 'deleteclients',
                'initclient': 'initclient',
                'initgood': 'initgood',
                'clearwilladd': 'clearwilladd',
                // 'clearwilladdgift': 'clearwilladdgift',
                'addwilladdgift': 'addwilladdgift',
                'deleteOnewillGift': 'deleteOnewillGift',
                'casewilladdgiftDataEvent': 'casewilladdgiftDataEvent',////删除一种满赠规则 -- 赠品组合
                'deletecombinationEvent': 'deletecombinationEvent', //删除商品组合  -- 赠品组合
                'deleteOnewillGiftEvent': 'deleteOnewillGiftEvent',// 删除单个赠品 -- 赠品组合
                'addwilladdgiftEvent': 'addwilladdgiftEvent', //添加活动规则 -- 赠品组合
                'clearwilladdgiftEvent': 'clearwilladdgiftEvent',//清空赠品
            }),
            ...mapActions({
                'querygoodtableData': 'querygoodtableData',
                'addactivitysent': 'addactivitysent',
                'querygiftlistData': 'querygiftlistData',
                
                'querygiftlistData': 'querygiftlistData',
                'updateactivitysent': 'updateactivitysent',
                'queryclienttableData': 'queryclienttableData',
                'querygiftlistDataEvent': 'querygiftlistDataEvent',//赠品组合，查询赠品组合
                'fullgiftSearchGoods': 'fullgiftSearchGoods',// 赠品组合添加商品
                'fullgiftSearchClient': 'fullgiftSearchClient',// 添加客户 ，查询客户
                'chooseAllGoodsAction': 'chooseAllGoodsAction',
                'chooseAllClientsAction': 'chooseAllClientsAction',
            }),
            giftTypeList(){
                axiosApi('/marketing/giftType/list',{},res => {
                    console.log(res,'res list');
                    this.giftTypeData = res.data.data
                }).then(() => {
                    this.getsentdetail();
                })
            },
            handlesevradio() {
                if (this.isMounted) this.clearwilladd()// 清空商品
                this.isMounted = true //  初始化 '2' 渲染完成就走 change第一次change  之后改为 true
            },
            deletecombination(index1, index2) { //删除一种赠品组合 index1 ->规则索引 index2->组合索引
                let h = this.$createElement;
                this.$msgbox({
                    title: '提示信息',
                    message: h('p', {
                        style: 'text-align:center'
                    }, '是否确定删除?'),
                    showCancelButton: true,
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    beforeClose: (action, instance, done) => {
                        if (action === 'confirm') {
                            setTimeout(() => {
                                this.deletecombinationEvent({
                                    index1: index1,
                                    index2: index2
                                })
                            }, 0)
                            done()
                        } else {
                            done()
                        }
                        delete instance.$slots.default;
                    }
                })
            },
            addgiftcombination(index, item) { // 添加赠品组合     index 规则索引 -- item 规则对象
                let num = 0
                if (!item.giftList) num = 0;
                if (item.giftList) num = item.giftList.length;
                if (num >= 5) {
                    this.$message.info('每条规则最多有5种赠品组合');
                    return false
                } else {
                    this.dialogVisible = true;  //chooseGift 赠品弹框 open
                    this.goodsNum = 5;
                    let skuNoList = [], giftList = this.activityRulesList[index].giftList, subIndex = 0;
                    if (item.giftList) {// 存在
                        subIndex = item.giftList.length
                    }
                    this.skuNoList = skuNoList
                    this.querygiftlistDataEvent(Object.assign({data: {}}, {
                        'chooseIndex': index,
                        'subIndex': subIndex,
                        data: {skuNoList: []}
                    }))
                }
            },
            arrayTogiftList(arr = []) {//转化postData里数组结构用于传参
                arr.map((c, d) => {
                    c.subgiftList.forEach((e, k) => {
                        e.groupSort = d + 1
                    })
                    return c.subgiftList
                })
                let newArr = []
                arr.forEach((a, b) => {
                    newArr.push.apply(newArr, a.subgiftList)
                })
                return newArr
            },
            giftToview(arr = []) {// 后端数据 转化 前端显示结构
                let newArr = [...arr], viewArr = []
                newArr.forEach((a, b) => {
                    if (viewArr[a.groupSort - 1]) {
                        if (viewArr[a.groupSort - 1].subgiftList) {
                            viewArr[a.groupSort - 1].subgiftList.push(a)
                        } else {
                            viewArr[a.groupSort - 1].subgiftList = []
                            viewArr[a.groupSort - 1].subgiftList.push(a)
                        }
                    } else {
                        viewArr[a.groupSort - 1] = {}
                        viewArr[a.groupSort - 1].subgiftList = []
                        viewArr[a.groupSort - 1].subgiftList.push(a)
                    }
                })
                return viewArr
            },
            numTochinese(val) {// 数字转汉字
                if (!val && val != 0) return null;
                let arr = ["零", "一", "二", "三", "四", "五", "六", "七", "八", "九"];
                let a = '';
                arr.forEach((item, index) => {
                    if (val == index) {
                        a = item
                    }
                })
                return a
            },
            handleSeRadio(val) {
                if (this.isinited) {
                    // this.clearwilladd()// 清空商品
                    // this.clearwilladdgiftEvent()// 清空赠品
                } else {
                    this.clearwilladd()// 清空商品
                    this.clearwilladdgiftEvent()// 清空赠品
                    if (val == 4 || val == 2) {
                        this.unitType = '1'
                    }else {
                        this.unitType = null
                    }
                }
                this.isinited = false;
            },
            handleUnitRadio(val) {
                this.clearwilladd()
            },
            //获取满赠活动详情
            getsentdetail() {
                this.loading = true
                const api = '/marketing/activityGift/Update/view'
                let body = {
                    activityId: this.sentid
                }
                let newbody = Object.assign({}, body)
                axiosApi(api, newbody, (res) => {
                    this.callback(res.data.data)
                }, 0, 10,err => {
                    this.$message.warning(err.data.message)
                })
            },
            callback(sentinfo) {
                this.loading = false;
                this.form = {
                    activityName: sentinfo.activityVo.activityName,
                    value1: [new Date(sentinfo.activityVo.gmtStart), new Date(sentinfo.activityVo.gmtEnd)]
                };
                this.isOvering = sentinfo.activityVo.isOvering ? true : false
                this.isNewUser = sentinfo.activityVo.isNewUser ? true : false
                this.thiradio = sentinfo.activityVo.rangeType + ''//1全部商品参与，2部分商品参与，3部分商品不参与
                this.secradio = sentinfo.activityVo.type + '';//1按订购金额，2按订购数量


                //限制次数
                this.isLimitTimes = sentinfo.activityVo.isLimitTimes + ''
                this.limitTimes = sentinfo.activityVo.limitTimes + ''
                this.sevradio = sentinfo.activityVo.rangCustomerType + ''//1全部客户参与，2部分客户参与
                this.isMounted = sentinfo.activityVo.rangCustomerType == '1' // true 第一次不走change
                //初始化客户列表
                if (this.sevradio != 1) {
                    this.initclient(sentinfo.traderVos ? this.initList(sentinfo.traderVos) : [])
                }

                let ne = [];
                let that = this;
                sentinfo.giftRuleExPos.map((a, b) => {
                    that.unitType = a.unitType + '' // 商品类型按箱，按瓶
                    ne.push(
                        {
                            fullMoney: a.fullMoney || '',
                            fullAmount: a.fullAmount || '',
                            giftList: that.giftToview(a.activityGiftRelationLi)
                        }
                    )
                })
                this.moneylist = ne
                let ve = []
                ve = ne.map((v) => {
                    v.giftList.map(v1 => {
                        v1.subgiftList.forEach(v2 => {
							console.log(v2,'v2')
                            v2.originGiftType = v2.giftType;
                            v2.giftType = v2.k3GiftType;
							let arr = [
								{// 瓶箱下拉选择框
								    label: '箱(规格单位)',
								    value: 1
								}, {
								    label: v2.giftUnit + '(基本单位)',
								    value: 2
								}
							]
							v2.unitOptions = arr
                            if (v2.limitQuantity == 0) {
                                v2.limitQuantity = ''
                            }
                        })
                        return v1
                    })
                    return v
                });
                ve.map((v,i1) => {
                    v.giftList.map((item,i2) => {
                        item.subgiftList.map((n,i3) => {
                            let flag = false;
                            this.giftTypeData.map((x,x1) => {
                                if(x.giftType == n.giftType){
                                    flag = true;
                                }
                            });
                            if(!flag){
                                n.giftType = null;
                                n.k3GiftType = null;
                            }
                        })
                    })
                });
                this.initgiftmoneyEvent(ve)
                //初始化商品列表
                this.initgood({willadd: sentinfo.goodsSkuVos ? sentinfo.goodsSkuVos : []})
                // setTimeout(() => {
                    this.isinited = true //已经初始化
                // }, 0)
            },
            initList(list){
            // 剔除已删除客户
            var arr = list.filter(item=>{
                return item.buyerId
            })
            return arr
            },
            delgoods() {//批量删除商品
                if (this.$store.state.ticket.willdelete.length != 0) {
                    this.$msgbox({
                        title: '提示信息',
                        message: '您确定要删除这些商品？',
                        showCancelButton: true,
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        beforeClose: (action, instance, done) => {
                            if (action === 'confirm') {
                                this.deletegoods()
                                done();
                            } else {
                                done();
                            }
                        }
                    })
                } else if (this.$store.state.ticket.willadd.length == 0) {
                    this.$alert('无商品可删除，请先添加商品', '', {
                        confirmButtonText: '确定',
                        callback: action => {

                        }
                    });
                } else {
                    this.$alert('请先勾选需要删除的商品', '', {
                        confirmButtonText: '确定',
                        callback: action => {

                        }
                    });
                }
            },
            deletemoneylist(y) {
                let h = this.$createElement;
                this.$msgbox({
                    title: '提示信息',
                    message: h('p', {
                        style: 'text-align:center'
                    }, '是否确定删除?'),
                    showCancelButton: true,
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    beforeClose: (action, instance, done) => {
                        if (action === 'confirm') {
                            this.casewilladdgiftDataEvent(y)
                            done()
                        } else {
                            done()
                        }
                        delete instance.$slots.default;
                    }
                })
            },
            delOnegift(index1, index2) {
                this.deleteOnewillGift({
                    index1: index1,
                    index2: index2
                })
            },
            delOnegift(index1, index2, index3) {
                // this.dialogVisible = true;
                setTimeout(() => {
                    this.dialogVisible = true; //更新 state
                    this.dialogVisible = false;
                }, 0)
                this.deleteOnewillGiftEvent({
                    index1: index1,
                    index2: index2,
                    index3: index3
                })
            },
            deletenumlist(y) {
                this.casewilladdgiftnum(y)
                this.numlist.splice(y, 1)
            },
            delclients() {//批量删除客户
                if (this.$store.state.clientlist.willdelete.length != 0) {
                    this.$msgbox({
                        title: '提示信息',
                        message: '您确定要删除这些客户？',
                        showCancelButton: true,
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        beforeClose: (action, instance, done) => {
                            if (action === 'confirm') {
                                this.deleteclients()
                                done();
                            } else {
                                done();
                            }
                        }
                    })
                } else if (this.$store.state.clientlist.willadd.length == 0) {
                    this.$alert('无客户可删除，请先添加客户', '', {
                        confirmButtonText: '确定',
                        callback: action => {

                        }
                    });
                } else {
                    this.$alert('请先勾选需要删除的客户', '', {
                        confirmButtonText: '确定',
                        callback: action => {

                        }
                    });
                }
            },
            addmoneylist() {
                this.addwilladdgiftEvent()
            },
            addGood() {//添加活动商品
                this.dialog1Visible = true
                // this.querygoodtableData(this.postDatagood)
                this.fullgiftSearchGoods({...this.postDatagood, ...{activityId: this.sentid}})
            },
            addAllGoods() { // 添加全部商品
                this.$msgbox({
                    title: '提示信息',
                    message: '是否选择全部商品？',
                    showCancelButton: true,
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    beforeClose: (action, instance, done) => {
                        if (action === 'confirm') {
                            instance.confirmButtonLoading = true;
                            instance.confirmButtonText = '执行中...';
                            let currentPath = this.$router.currentRoute.path || '';
                            this.chooseAllGoodsAction({
                                'instance': instance,
                                'done': done,
                                'currentPath': currentPath,
                                'activitySource': this.activitySource
                            });
                        } else {
                            done();
                        }
                    }
                })
            },
            addclient() {//添加客户
                this.dialog2Visible = true
                this.queryclienttableData(this.postDataclient)
            },
            addAllClients() {
                this.$msgbox({
                    title: '提示',
                    message: '是否选择全部客户？',
                    showCancelButton: true,
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    beforeClose: (action, instance, done) => {
                        if (action === 'confirm') {
                            instance.confirmButtonLoading = true;
                            instance.confirmButtonText = '执行中...';
                            let postData = {
                                data: {sellerId: sessionStorage.traderId},
                                currentPage: 1
                            }
                            this.chooseAllClientsAction({
                                'instance': instance,
                                'done': done,
                                'postData': postData,
                                'clientType': this.activitySource
                            });
                        } else {
                            done();
                        }
                    }
                })
            },
            addgift(index, item, index2, item2) {// 添加赠品 index 规则索引 -- item 规则对象 index2-> 组合内的赠品索引
                let num = 0
                if (!item2.subgiftList) num = 0;
                if (item2.subgiftList) num = item2.subgiftList.length;
                if (num >= 5) {
                    this.$message.info('每种组合最多包含5种赠品');
                    return false
                } else {
                    this.dialogVisible = true;  //chooseGift 赠品弹框 open
                    this.goodsNum = 5 - num;
                    let skuNoList = [], subIndex = 0;
                    if (item.giftList) {// 存在
                        subIndex = index2;
                        skuNoList = item2.subgiftList.map(n => { // 获取赠品列表的 skuno
                            return n.skuNo
                        })
                    }
                    this.skuNoList = skuNoList // props 传值 chooseGift
                    this.querygiftlistDataEvent(Object.assign({data: {}}, {
                        'chooseIndex': index,
                        'subIndex': subIndex,
                        data: {skuNoList: skuNoList}
                    }))
                }
            },
            back() {
                this.$router.push(basePath + '/activity/2')
            },
            filterTypeData(type){
                let str = ''
                this.giftTypeData.map(v => {
                    if(v.giftType == type){
                        str = v.giftTypeName
                    }
                })
                return str
            },
            onSubmit() {
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        let newconfirm = new Confirm();
                        let confirmdata = this.postData.data
                        newconfirm.use([confirmdata.activity.activityName], '活动名称为空！')
                        newconfirm.use([confirmdata.activity.gmtStart], '活动时间为空！')
                        newconfirm.use([confirmdata.activity.gmtEnd], '活动时间为空！')
                        newconfirm.use([confirmdata.activity.isLimitTimes, '1', confirmdata.activity.limitTimes], '限制次数为空！')
                        newconfirm.use([confirmdata.activity.rangCustomerType, '2', confirmdata.customerRelation], '未添加参与活动客户！')
                        newconfirm.use([confirmdata.activity.rangeType, '2', confirmdata.goodsRelation], '未添加活动商品！')
                        // newconfirm.cs('dot', confirmdata.fullMoney, '订购金额')
                        // newconfirm.cs('int', confirmdata.quantity, '赠品数量')
                        if (confirmdata.activity.activityName.length > 64) {
                            newconfirm.status = false
                            this.$message({
                                type: 'warning',
                                message: '活动名称最大字符64位，请修改！'
                            })
                        }
                        //    if(confirmdata.activity.gmtStart < new Date().getTime()) {
                        //      newconfirm.status = false
                        //      this.$message({
                        //        type: 'warning',
                        //        message: '活动开始时间应在当前时间之后，请修改！'
                        //      })
                        //    }
                        if (confirmdata.activityGiftRuleExLi.length == 0) {
                            this.$message.warning('赠品组合为空！');
                            return false
                        }
                        confirmdata.activityGiftRuleExLi.forEach((a, b) => {
                            console.log(a,'aaaaa')
                            a.activityGiftRelationLi.map(v => {
                                v.k3GiftType = v.giftType;
                                v.k3GiftTypeName = this.filterTypeData(v.giftType);
                                v.giftType = v.originGiftType;
                                delete v.originGiftType;
                            });
                            console.log(a,'bbbb')
                            /*{
                                  fullAmount: a.fullNum,
                                  type: 2,
                                  activityGiftRelationLi:
                                }*/
                            if (this.secradio == '1') {
                                newconfirm.use([a.fullMoney], '订购金额为空！')
                                newconfirm.cs('dot', a.fullMoney, '订购金额')
                            } else if (this.secradio == '2') {// 订购数量
                                newconfirm.use([a.fullAmount], '订购数量为空！')
                                newconfirm.cs('int', a.fullAmount, '订购数量')
                            } else if (this.secradio == '3') {// 每满额送
                                newconfirm.use([a.fullMoney], '满额金额为空！')
                                newconfirm.cs('dot', a.fullMoney, '满额金额')
                            } else if (this.secradio == '4') {// 每满件送
                                newconfirm.use([a.fullAmount], '满件数量为空！')
                                newconfirm.cs('int', a.fullAmount, '满件数量')
                            }
                            // a.activityGiftRelationLi.forEach
                            let m = a.activityGiftRelationLi || [];//
                            newconfirm.use([m[0]], '赠品组合为空！')
                            for (let i = 0; i < m.length; i++) {
                                // console.log(m)
                                newconfirm.use([m[i].quantity], '赠品数量为空！')
                                newconfirm.cs('int', m[i].quantity, '赠品数量')
                                if (!newconfirm.status) return
                            }
                        })

                        var full = confirmdata.activityGiftRuleExLi.map((a, b) => {
                            return (this.secradio == '1' || this.secradio == '3') ? a.fullMoney : a.fullAmount
                        })
                        var len1 = full.length
                        // var fullset = new Set(full)//数组去重
                        // var len2 = fullset.size
                        var fullset = full.unique();//数组去重
                        var len2 = fullset.length;
                        if (len1 != len2) {
                            newconfirm.status = false
                            this.$message({
                                type: 'warning',
                                message: '存在相同订购金额的活动规则！'
                            })
                        }

                        if (newconfirm.status) {
                            this.updateactivitysent(this.postData)
                        } else {
                            return false
                        }
                    } else {
                        return false;
                    }
                })
            }
        },
        mounted() {
            this.$nextTick(() => {
                this.giftTypeList();
            })
        }
    }
</script>

<style lang="less">
    .btn {
        text-align: left
    }

    .add-gift-sent {
        .iconfont.icon-err:hover {
            color: #20a0ff;
        }
    }
</style>
