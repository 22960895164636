<template>
<div>
    <el-tabs v-model="activeTab" @tab-click="handleTabClick">
        <el-tab-pane label="全部" name="1"></el-tab-pane>
        <el-tab-pane label="未开始" name="2"></el-tab-pane>
        <el-tab-pane label="进行中" name="3"></el-tab-pane>
        <el-tab-pane label="已结束" name="4"></el-tab-pane>
    </el-tabs>

    <el-table :data="tableData" tooltip-effect="dark" class="mt-20 table-default">
        <el-table-column label="活动名称" width="225">
            <template slot-scope="scope">
                <div @click="tocutdetail(scope.$index, tableData)" :style="{color: '#20a0ff',cursor: 'pointer'}">{{scope.row.activityName}}</div>
            </template>
        </el-table-column>
        <el-table-column label="有效时间" width="295">
            <template slot-scope="scope">
                <div>{{scope.row.gmtStart | timenyr}} 至 {{scope.row.gmtEnd | timenyr}}</div>
            </template>
        </el-table-column>
        <el-table-column label="活动状态" width="95">
            <template slot-scope="scope">
                <div>{{scope.row.status | status2}}</div>
            </template>
        </el-table-column>
        <el-table-column label="操作">
            <template slot-scope="scope">
                <el-button type="text" @click="updatecut(scope.$index, tableData)" v-if="scope.row.status == '0'">编辑</el-button>
                <el-button type="text" @click="deletecut(scope.$index, tableData)" v-if="scope.row.status == '0'">删除</el-button>
                <el-button type="text" @click="endecut(scope.$index, tableData)" v-if="scope.row.status == '1'">终止</el-button>
            </template>
        </el-table-column>
    </el-table>

    <el-pagination class="tar" v-if='total>0'
    :current-page="currentPage"
    :page-size='10'
    :total='total'
    layout="prev , pager, next, jumper"
    @current-change="handlePageChange">
    </el-pagination>
</div>
</template>

<script>
import {mapActions,mapMutations} from 'vuex'
import { axiosApi,basePath } from '../api/api' //导入axiosApi
export default {
    data() {
        return {
            activeTab: '1',
            currentPage: 1,
        }
    },
    computed: {
        tableData() {
            return this.$store.state.activity.cutlist ? this.$store.state.activity.cutlist.elements : [] 
        },
        total() {
            return this.$store.state.activity.cutlist ? this.$store.state.activity.cutlist.totalCount : 0 
        },
        postData() {
            return {
                data: {
                    status: this.activeTab == '1' ? '3' : this.activeTab == '2' ? '0' : this.activeTab == '3' ? '1' : '2' ,
                    activityType: '2'
                },
                currentPage: this.currentPage
            }
        },
    },
    methods: {
        ...mapActions({
            'querycutpage': 'queryactivitypage',
        }),
        ...mapMutations({
            'writecutid': 'writecutid',
        }),
        deletecut(index, rows) {
            let that = this;
            this.$msgbox({
                title: '提示信息',
                message: '您确定要删除此活动？',
                showCancelButton: true,
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                beforeClose: (action, instance, done) => {
                    if(action === 'confirm') {
                        const api = "/marketing/activity/delete";
                        const body = {
                            activityId: rows[index].activityId,
                            sellerId: sessionStorage.getItem('traderId')
                        };
                        axiosApi(api, body, function(res) {
                            that.querycutpage(that.postData)
                            done()
                        })
                    } else {
                        done();
                    }
                }
            })
        },
        updatecut(index, rows) {
            sessionStorage.setItem('activitycutid',rows[index].activityId)
            this.$router.push(basePath+'/activities/updatecut')
        },
        endecut(index, rows) {
            let that = this;
            this.$msgbox({
                title: '提示信息',
                message: '您确定要终止此活动？',
                showCancelButton: true,
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                beforeClose: (action, instance, done) => {
                    if(action === 'confirm') {
                        const api = "/marketing/activity/stop";
                        const body = {
                            activityId: rows[index].activityId,
                            sellerId: sessionStorage.getItem('traderId')
                        };
                        axiosApi(api, body, function(res) {
                            that.$notify({
                                type: 'success',
                                message: '该满减活动已经成功终止！'
                            })
                            that.querycutpage(that.postData)
                            done()
                        }, 0, 10, (res)=> {
                            that.$notify({
                                type: 'warning',
                                message: '该满减活动已经终止失败！'
                            })
                            done()
                        })
                    } else {
                        done();
                    }
                }
            })
        },
        handleTabClick() {
            this.currentPage = 1
            this.querycutpage(this.postData)
        },
        handlePageChange(val) {
            this.currentPage = val
            this.querycutpage(this.postData)
        },
        tocutdetail(index, row) {
            var cutid = row[index].id
            sessionStorage.setItem('cutid',cutid)
            this.$router.push(basePath + '/activities/cutdetail')
        }
    },
    mounted() {
        this.$nextTick(()=>{
            this.querycutpage(this.postData)
        })
    }
}
    
</script>