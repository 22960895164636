<template>
    <div id="selectedGoods">
        <el-dialog title="选择商品" :visible.sync="productVisible" @close="goPage">
            <div v-loading="tableloading">
                <el-form :inline="true" :model="skuObj" label-width="80px" class="clearfix" :style="{width:'850px'}">
                    <div class="fl">
                        <el-form-item label="商品分类:">
                            <el-select v-model='skuObj.classifyName' placeholder='请选择' clearable >
                                <el-option v-for='item in classifylist' :key='item.id'
                                           :label='item.classifyName'
                                           :value='item.classifyName'></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="商品名称:">
                            <el-input  v-model.trim="skuObj.skuName"></el-input>
                        </el-form-item>
                    </div>
                    <el-button type="primary" class="fl ml-50" @click="querygood">查询</el-button>
                </el-form>
                <el-table @selection-change="handleSelectionChange" ref="tableDate" :data="tableDate2" stripe border tooltip-effect="dark" class="mt-10 table-default">
                    <el-table-column type="selection" width="50" :selectable='selectInit'>
                    </el-table-column>
                    <el-table-column label="商品名称" prop='skuName' width="350" show-overflow-tooltip>
                        <template slot-scope="scope">
                            <div class="goods-wrap">
                                <div class="ml-10 l-googs" style="position: relative;">
                                    <img v-if='scope.row.fileUrl' :src="scope.row.fileUrl" />
                                    <img v-else src="../assets/goods.jpg" />
                                </div>
                                <div class="ml-10 r-goods">
                                    <div class="tal w-160 lh-20">{{scope.row.skuName}}</div>
                                    <div class="tal w-160 lh-20" v-if="scope.row.specInfoNum">规格1*{{scope.row.specInfoNum}}</div>
                                    <div class="tal w-160 lh-20">
                                        <span style="color:#FF4201;">￥{{scope.row.grouponPrice | fmoney}}</span>
                                        <span style="color:#999999;text-decoration-line: line-through;margin-left: 10px;">￥{{scope.row.msrp | fmoney}}</span>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="商品分类" prop='classifyName' >
                    </el-table-column>
                    <el-table-column label="商品库存" prop='totalStock' >
                    </el-table-column>
                    <el-table-column label="销量单位" prop='units' >
                    </el-table-column>
                    <el-table-column label="状态" prop="inActivity">
                        <template slot-scope="scope">
                            <p v-if="scope.row.status == 0">无效商品</p>
                            <div v-else>
                                <p v-if="!scope.row.inActivity" style="color: #20a0ff;">可参加活动</p>
                                <p v-else style="color: #999">已参加其他活动</p>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination class="tar" v-if='total>0 || total2>0'
                               :current-page="currentPage"
                               :page-size='10'
                               :total='isSelected ? total2 : total'
                               layout="prev , pager, next"
                               @current-change="handlePageChange">
                </el-pagination>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="addgift" :loading="btnLoading">确 定</el-button>
                <el-button @click="goPage">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {mapActions,mapMutations} from 'vuex'
    import { axiosApi,basePath } from '../api/api' //导入axiosApi
    export default {
        props: ["goodsVisible","goodsData"],
        data() {
            return {
                tableDate2:[],//备份数据源
                currentPage: 1,
                skuObj:{
                    skuName:null,     //商品名称
                    classifyName:null  //商品分类
                },
                tableloading:false,
                btnLoading:false,     //搜索时
                multipleSelection: [],
                addlists: [],
                productVisible: false,
                willAdd:[],//即将添加的商品
                isSelected:false,//搜索判断
            }
        },
        computed: {
            classifylist() {
                return this.$store.state.classifylist.classifylistData ?
                    this.$store.state.classifylist.classifylistData : []
            },
            total(){
                return this.goodsData.length
            },
            total2(){
                return this.tableDate2.length
            },
            tableDate(){
                return this.goodsData.slice((this.currentPage-1)*10, (this.currentPage-1)*10+10);
            }
        },
        watch: {
            goodsVisible(value) {
                this.productVisible = value
            },
            tableDate(val){
                this.tableDate2 = val;
            },

        },
        mounted(){

        },
        methods: {
            selectInit(row,index){
                if(row.inActivity==1 || row.status == 0){
                    return false
                }
                return true
            },
            handleSelectionChange(val) {
                this.willAdd = val;
            },
            querygood() {
                if(this.skuObj.classifyName || this.skuObj.skuName){
                    this.isSelected = true;
                    let arr = [];//接收过滤后的数据
                    this.goodsData.map(v => {
                        if (this.skuObj.classifyName && this.skuObj.skuName) {
                            if (v.classifyName == this.skuObj.classifyName && v.skuName.indexOf(this.skuObj.skuName) != -1) {
                                arr.push(v)
                                // return v;
                            }
                        } else if (this.skuObj.classifyName && !this.skuObj.skuName) {
                            if(v.classifyName == this.skuObj.classifyName){
                                arr.push(v)
                                // return v;
                            }
                        } else if (!this.skuObj.classifyName && this.skuObj.skuName) {
                            if(v.skuName.indexOf(this.skuObj.skuName) != -1){
                                arr.push(v)
                                // return v;
                            }
                        }
                    });
                    console.log(arr);
                    this.tableDate2 =[...arr];
                }else{
                    this.isSelected = false;
                    this.tableDate2 = this.tableDate
                }
            },
            //调用添加赠品接口
            addgift() {
                if(this.willAdd.length==0){
                    this.$message.warning('请添加商品');
                    return
                }
                this.$emit('closedialog2',null);
                this.$store.commit('addGoods',{
                    willAdd:this.willAdd
                });
            },
            //返回上一页
            goPage() {
                this.skuObj.classifyName = null;
                this.skuObj.skuName = null;
                this.$emit('closedialog2',null)
            },
            handlePageChange(val){
                this.currentPage = val;
                setTimeout(() => {
                    if(this.willAdd.length>0){// 有选中的数据
                        this.willAdd.forEach(row => {
                            this.$refs.tableDate.toggleRowSelection(row)
                        })
                    }else{// 不存在选中数据
                        this.$refs.tableDate.clearSelection()
                    }
                }, 0)
            }
        }
    }
</script>

<style lang="less">
    #selectedGoods{
        .el-table__header,.el-table__body,.el-table__empty-block{
            width: 100%!important;
        }
    }

    #selectedGoods .el-dialog {
        width: 950px;
        min-height: auto;
    }
    #selectedGoods .table-default.el-table .cell{
        padding: 3px 15px 0;
    }
    #selectedGoods .el-dialog__body {
        padding: 20px 30px;
    }

    #selectedGoods {
        .el-dialog__footer{
            position: static;
            transform: translateX(0);
            padding: 10px 20px 35px;
        }
    }

    #selectedGoods {
        .el-dialog {
            .el-form {
                .el-form-item {
                    .el-form-item__label {
                        &::before {
                            display: none
                        }
                    }
                }
            }
        }
    }
    #selectedGoods .el-form .el-input{width: 120px;}
</style>
