<template>
  <div id="platformGoods">
			<div class="bg-f pb-50 pl-30 pr-30">
				<el-form :inline="true" :model="formInlineDataPlatform" label-width="90px" class="clearfix pt-30 search">
					<div>
						<el-form-item label="商品编码">
							<el-input  v-model.trim="formInlineDataPlatform.goodsNo" @input="formInlineDataPlatform.goodsNo=formInlineDataPlatform.goodsNo.replace(/[\u4e00-\u9fa5]/ig,'')"></el-input>
						</el-form-item>
						<el-form-item label="商品名称">
							<el-input v-model.trim="formInlineDataPlatform.skuName"></el-input>
						</el-form-item>
						<el-form-item label="商品分类">
							<el-select v-model.trim="formInlineDataPlatform.classifyId" clearable>
								<el-option :label='item.classifyName' :value="item.id" v-for='item in searchClassify' :key="item.id"></el-option>
							</el-select>
						</el-form-item>
					</div>
					<div>
						<el-button type="info" class="fl ml-30" @click='searchList' icon="el-icon-search">搜 索</el-button>
					</div>
					
				</el-form>
				<el-table :data="tableData" v-loading="Loading" class='mt-10 table-default'>
					<el-table-column prop="1" label="商品名称" min-width="262">
						<template slot-scope="scope">
							<div class="fl ml-10" style="position: relative;">
								<img v-if='scope.row.fileUrl' :src="scope.row.fileUrl" />
								<img v-else src="../../../assets/goods.jpg" />
								<img v-show='scope.row.bizType == 1' src="../../../assets/bind.png" style="position: absolute;left:0px;top:0" />
							</div>
							<div class="fl ml-10 mt-10">
								<div class="tal w-160">商品编码:{{scope.row.goodsNo}}</div>
								<div class="tal w-160">{{scope.row.skuName}}</div>
							</div>
						</template>
					</el-table-column>
					
					<el-table-column label="规格" min-width="70">
						<template slot-scope="scope">
							<div>1*{{scope.row.specInfoNum}}</div>
						</template>
					</el-table-column>
					<el-table-column label="商品分类" mi-width="70" prop='classifyName'></el-table-column>
					<el-table-column label="库存" min-width="70" prop='stockEnableQuantity'></el-table-column>
					<el-table-column label="进店价" min-width="68">
						<template slot-scope="scope">
							<div v-if='scope.row.enterShopPrice'>￥{{scope.row.enterShopPrice || 0}}</div>
							<div v-else>--</div>
						</template>
					</el-table-column>
				
					<el-table-column label="操作" min-width="150">
						<template slot-scope="scope">
							<div>
								<router-link :to="`${basePath}/groupDetails/${scope.row.skuNo}/${scope.row.goodsType}/0/add`">
									<el-button class='ml-0 tal' size="small" type='text'>添加</el-button>
								</router-link>
							</div>
						</template>
					</el-table-column>
				</el-table>
				<el-pagination class="tar mr-30" v-if="totalPlatform>0" :current-page="currentPagePlatform" :page-size='10' :total='totalPlatform' layout="prev , pager, next, jumper" @current-change="handleCurrentChangePlatform">
					<!-- 分页组件 -->
				</el-pagination>
			</div>
		</div>
</template>

<script>
import { axiosApi, basePath } from "~/api/api";
export default {
  data(){
    return {
      Loading: false,
      formInlineDataPlatform: {
        sellerId: sessionStorage.traderId,
        goodsNo: null, // 商品编码
        skuName: null, // 商品名称
        classifyId: null, // 商品分类
        goodsState: null, // 订单状态
        AuditState: null, // 审核状态
      },
      currentPagePlatform:1,
      searchClassify: [],
      tableData: [],
      totalPlatform: {},
    }
  },
  mounted(){
    this.classSearch()
    this.getList()
  },
  methods: {
    getList(){
      this.Loading = true;
      axiosApi('/groupon/platformGoods/list', this.formInlineDataPlatform, (res) => {
        if(res.data.data){
          this.tableData = res.data.data.elements ? res.data.data.elements : [];
          this.totalPlatform = res.data.data.totalCount ? res.data.data.totalCount : '';
          this.Loading = false;
          if(this.tableData.length > 0) {
            this.openGoods = this.tableData[0].isOpenGoods
          }
        }else{
          this.Loading = false;
        }
      }, this.currentPagePlatform, 10,err=>{
          this.Loading = false;
      })
    },
    //查询分类
    classSearch() {
      const api = "/unlogin/groupon/classify/selectList";
      const body = {}
      axiosApi(api, body, (res)=>{
        this.searchClassify = res.data.data;
      })
    },
    // 搜索
    searchList(){
      this.currentPagePlatform = 1
      this.getList()
    },
    // 分页
    handleCurrentChangePlatform(val){
      this.currentPagePlatform = val
       this.getList()
    },
  },
}
</script>

<style lang="less" scoped>


</style>