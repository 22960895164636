<template>
	<div id="client-data" class="pb-20">
		<div class="title-tabs bg-f">
			<ul class="clearfix">
				<template v-for='(tab,index) in dataTabs' class="item">
					<li :type='index' :class="type==index?'active':''" @click='handleTabs'>
						{{tab}}
						<div :class="type==index?'under-line':''"></div>
					</li>
					<div class="vertical-line"></div>
				</template>
			</ul>
		</div>
		
		<div class="bg-f pb-50 mt-5">
			<div class="tar pt-30 pr-30">
				<el-button v-if="showButton('CUSTOMER_LABEL_ADD',100056) && type == 0" @click="addClick">新增终端渠道</el-button>
				<el-button v-if="showButton('CUSTOMER_LABEL_ADD',100056) && type == 1" @click="addClick">新增客户等级</el-button>
			</div>
			<!--新增客户渠道弹窗-->
			<clientChannel v-model="addChannel" :title="title" :disabled="disabled" v-on:closedialog="addChannel = false"></clientChannel>
			<!--新增客户等级弹窗-->
			<clientLevel v-model="addLevel" :title="title" v-on:closedialog="addLevel = false"></clientLevel>

			<div v-show="type==0" class="ml-30 mt-20 mr-30">
				<div class="tree-title">
					<div class="fl ml-30">渠道名称</div>
					<div class="fr">操作</div>
				</div>
				<el-tree :data="clientChannelList" class="pr-10 pt-10 pb-10" :props="channelProps" :expand-on-click-node="false" :render-content="renderContent"></el-tree>
			</div>

			<!--客户等级-->
			<el-table v-show="type==1" key="clientChannel" :data="clientLevelList" tooltip-effect="dark" stripe class="ml-30 mt-20 mr-30 table-default">
				<el-table-column prop="levelName" label="客户等级"></el-table-column>
				<el-table-column label="操作">
					<template slot-scope="scope">
						<div>
							<p class="tac">
								<el-button @click="editChannel(null,scope.row)" type="text" size="small">编辑</el-button>
							</p>
							<p class="tac">
								<el-button @click="delChannel(null,scope.row)" type="text" size="small">删除</el-button>
							</p>
						</div>
					</template>
				</el-table-column>
			</el-table>
			<!-- 品牌维护 容器 -->
			<div class="brandMaintainBox ml-30 mr-30" v-if="type == 2">
				<div class="mSearchBox">
					<el-input @keyup.enter.native="handleBrandSearch" v-model="brandSearchVal" placeholder="请输入品牌名称" />
					<el-button type="info" class="ml-30" @click="handleBrandSearch">搜索</el-button>
					<el-button class="fr" @click="handleAddBrand">添加品牌</el-button>
				</div>
				<div class="mContentBox">
					<div class="mb-10 mt-20 oh">
						<el-button class='fr' type='text' @click="handleBrandDelete('all')">批量删除</el-button>
					</div>
					<div>
						<el-table @selection-change="handleSelectionChange" :data="brandList" border tooltip-effect="dark" stripe class="mt-20 table-default">
							<el-table-column
							type="selection"
							width="55">
							</el-table-column>
							<el-table-column label="品牌图片">
								<template slot-scope="scope">
									<img class="bd" :src="scope.row.logoIcon" v-if="scope.row.logoIcon" />
									<img class="bd" src="./../../../assets/brand.png" v-else />
								</template>
							</el-table-column>
							<el-table-column prop="brandName" label="品牌名称">
							</el-table-column>
							<el-table-column prop="classifyName" label="分类">
								<template slot-scope="scope">
									<span>{{scope.row.classifyName || '--'}}</span>
								</template>
                        	</el-table-column>
							<el-table-column label="操作">
								<template slot-scope="scope">
									<el-button @click="handleBrandDelete('one',scope.row)" type="text" size="small">删除</el-button>
								</template>
							</el-table-column>
						</el-table>
						<div class="pageBox">
							<el-pagination class="tar" v-if='brandtotal>0'
								:current-page="brandcurrentPage"
								:page-size='10'
								:total='brandtotal'
								layout="prev, pager, next, jumper"
								@current-change="handlePageChange">
							</el-pagination>
						</div>
					</div>
				</div>
				<!-- 浮层 -->
				<addBrand ref="addBrand" :isSetBrand="true" :check='true' @close="brandClose"></addBrand>
			</div>
		</div>
	</div>
</template>

<script>
	import { axiosApi, basePath } from "../../../api/api"
	import { mapMutations, mapActions } from 'vuex'
	import { MessageBox } from 'element-ui'

	export default {
		data() {
			return {
				title: null,
				disabled: false,
				basePath: basePath,
				dataTabs: ['客户渠道', '客户等级','品牌维护'],
				type: 0,
				currentPage: 1,
				total: 0,
				addChannel: false,
				addLevel: false,
				channelProps: {
					children: 'sub',
          			label: 'classifyName'
				},
				// 品牌 table list
				brandList : [],
				brandSearchVal : '',
				brandcurrentPage : 1,
            	brandtotal : 0,
			}
		},
		computed: {
			clientChannelList(){
				return this.$store.state.channelLevel.clientChannelList
			},
			clientLevelList(){
				return this.$store.state.channelLevel.clientLevelList
			}
		},
		mounted() {
			this.$nextTick(()=>{
				this.loading();
			})
		},
		methods: {
			/* 品牌维护 相关逻辑 start */
			handleBrandSearch : function(){
				this.getBrandTable();
			},
			handleAddBrand : function(){
				this.$refs.addBrand.show();
			},
			handleBrandDelete : function(type,row){
				let ids = [];
				let message = '';
				/* 删除 全部 */
				if(type === 'all'){
					if(!this.multipleSelection || this.multipleSelection.length === 0){
						this.$message.warning('请勾选品牌');
						return;
					}else{
						this.multipleSelection.map(v => {ids.push(v.id)});
						message = '是否确认删除选中的品牌？'; // brandName
					}
				}else{
					ids.push(row.id);
					message = `是否确认删除品牌[${row.brandName}]？`;
				}
				this.$confirm(message, '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
					}).then(() => {
						this.brandDeleteFn(ids);
					}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});          
				});
			},
			initBrandTableOne : function(){
				this.brandSearchVal = '';
				this.brandcurrentPage = 1;
				this.getBrandTable();
			},
			brandDeleteFn : function(ids){
				// 删除逻辑
				axiosApi("/trader/traderBrand/delete",{
					traderId: sessionStorage.traderId,
					idList: ids
				},(res) => {
					let data = res.data.data;
					this.$message.success('删除成功');
					this.initBrandTableOne();
				});	
			},
			getBrandTable : function(){
				axiosApi("/trader/traderBrand/select",{
					traderId: sessionStorage.traderId,
					brandName: this.brandSearchVal,
					isPage: 1,
				},(res) => {
					let data = res.data.data;
					this.brandList = data.elements;
					this.brandtotal = data.totalCount;
				},this.brandcurrentPage,10);
			},
			// 分页change
			handlePageChange : function(val){
				this.brandcurrentPage = val;
				this.getBrandTable();
			},
			brandClose : function(){
				this.initBrandTableOne();
			},
			handleSelectionChange : function(val){
				this.multipleSelection = val;
			},
			/* 品牌维护 相关逻辑 end */
			...mapMutations({
				'insert': 'insert',
				'update': 'update'
			}),
			...mapActions({
				'queryChannelLevelData': 'queryChannelLevelData',
				'delChannelLevelData': 'delChannelLevelData',
				'selectChannelLevelData': 'selectChannelLevelData'
			}),
			handleTabs(e){
				if(e.target.attributes.type) this.type = e.target.attributes.type.value
				//清空数据
				this.currentPage = 1;
				this.loading();
				if(e.target.attributes.type && ( e.target.attributes.type.value == '2') ){
					this.brandSearchVal = '';
					this.initBrandTableOne();
				}
			},
			addClick(){
				this.insert();
				if(this.type == 0){
					this.title = '新增客户渠道'
					this.addChannel = true
					this.disabled = false
				}else{
					this.title = '新增客户等级'
					this.addLevel = true
				}
			},
			editChannel(store, data){
				this.update();//编辑
				this.selectChannelLevelData({
					type: this.type,
					id: data.id
				})
				if(this.type == 0){
					this.title = '编辑客户渠道'
					this.disabled = true
					this.addChannel = true
				}else{
					this.title = '编辑客户等级'
					this.addLevel = true
				}
			},
			delChannel(store, data){
				this.delChannelLevelData({
					type: this.type,
					id: data.id
				})
			},
			loading(){
				this.queryChannelLevelData(this.type)
			},
			renderContent(h, { node, data, store }) {
				return (
					<span style="width:100%">
						<span>
							<span class="ml-20">{node.label}</span>
						</span>
						<span class="fr mr-20">
							<el-button size="mini" on-click={ () => this.editChannel(store, data) }>改名</el-button>
							<el-button size="mini" on-click={ () => this.delChannel(store, data) }>删除</el-button>
						</span>
					</span>
				);
			}
		}
	}
</script>
<style lang="less">
	#client-data {
		position: relative;
		.el-form-item__label {
			font-size: 12px;
		}
		.tree-title{
			background-color: #eee;
			height: 40px;
			border:1px solid #ddd;
			border-bottom: 0;
			div{
				line-height: 40px;
				color: #666;
			}
			.fr{
				width: 230px;
				text-align: center;
			}
		}
		.el-tree{
			border:1px solid #ddd;
            .el-tree-node__content{
                height: 40px;
            }
		}
	}
</style>