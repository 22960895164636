<template>
    <div class="creditManagement-box">
        <div class="title-top bg-f">
            授信管理
        </div>
        <div class="creditManagement-content bg-f mt-5">
            <div class="search pt-30">
                <span>账号：</span>
                <el-input placeholder="请输入账号" v-model.trim="account" />
                <span class="ml-30">姓名：</span>
                <el-input placeholder="请输入姓名" v-model.trim="name" />
                <el-button @click="handleSearch" class="el-button--info ml-30" icon="el-icon-search">搜 索</el-button>
            </div>
            <div class="c-table">
                <!-- 表格 -->
                <el-table :data="tableList" border tooltip-effect="dark" class="mt-20 table-default">
                    <el-table-column :resizable="false"  prop="loginName" label="账号"></el-table-column>
                    <el-table-column :resizable="false"  prop="name" label="姓名"></el-table-column>
                    <el-table-column :resizable="false"  prop="bondMoney" label="保证金"></el-table-column>
                    <el-table-column :resizable="false"  width="130" prop="gmtCreate" label="注册时间">
                        <template slot-scope="scope">
                            {{scope.row.gmtCreate | time}}
                        </template>
                    </el-table-column>
                    <el-table-column :resizable="false"  prop="creditMoney" label="授信额度（元）">
                    </el-table-column>
                    <el-table-column :resizable="false"  prop="leftCreditMoney" label="可用额度（元）">
                    </el-table-column>
                    <el-table-column :resizable="false"  prop="useCreditMoney" label="已用额度（元）">
                    </el-table-column>
                    <el-table-column :resizable="false"  prop="groupName" label="操作">
                        <template slot-scope="scope">
                            <el-button :style="{width : 'auto'}" @click="handleEnableAndDisable(scope.row)" type="text">{{scope.row.creditState === 0 ? '启用' : '禁用'}}</el-button>
                            <!-- 0禁用；1启用 -->
                            <div>
                                <el-button :style="{width : 'auto'}" @click="handleDetail(scope.row)" type="text">授信明细</el-button>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
                <!-- 分页 -->
                <div class="pageBox">
                    <el-pagination class="tar" v-if='total>0'
                        :current-page="currentPage"
                        :page-size='10'
                        :total='total'
                        layout="prev, pager, next, jumper"
                        @current-change="handlePageChange">
                    </el-pagination>
                </div>
                <!-- 浮层  -->
                <el-dialog title="授信明细" :visible.sync="dialogVisible">
                    <div class="credit-dialog-content">
                        <el-table :data="tableListDialog" border tooltip-effect="dark" stripe class="mt-20 table-default">
                             <el-table-column :resizable="false" width="150" prop="gmtCreate" label="创建时间">
                                <template slot-scope="scope">
                                    {{scope.row.gmtCreate | time}}
                                </template>
                             </el-table-column>
                             <el-table-column :resizable="false" align="left" width="200" prop="creditExplain" label="交易详情"></el-table-column>
                             <el-table-column :resizable="false" prop="changeMoney" label="明细"></el-table-column>
                             <el-table-column :resizable="false" prop="leftCreditMoney" label="可用额度"></el-table-column>
                             <el-table-column :resizable="false" prop="creditMoney" label="总额度"></el-table-column>
                             <el-table-column :resizable="false" prop="typeName" label="类型">
                             </el-table-column>
                             <el-table-column :resizable="false" prop="operatorName" label="操作人">
                                <template slot-scope="scope">
                                    {{scope.row.operatorName || '--'}}
                                </template>
                             </el-table-column>
                        </el-table>
                        <div class="pageBox">
                            <el-pagination class="tar mt-20" v-if='tableListDialog.length>0'
                                :current-page="currentPageDialog"
                                :page-size='10'
                                :total='totalDialog'
                                layout="prev, pager, next, jumper"
                                @current-change="handlePageChangeDialog">
                            </el-pagination>
                        </div>
                    </div>
                </el-dialog>
            </div>
        </div>
    </div>
</template>

<script>
    import { axiosApi, basePath } from "../../api/api";
    export default {
        data() {
            return {
                account : '', // 账号
                name : '', // 姓名
                accountDefault : '', // 点击搜索后 在赋值
                nameDefault : '',
                tableList : [], // table数据
                total : 11, // 总数量
                currentPage : 1, // 当前页数
                dialogVisible : false,
                tableList : [], // 表格数据
                currentPageDialog : 1, // 浮层当前页数
                tableListDialog : [], // 浮层表格数据
                totalDialog : '', // 浮层数据 总数量
            }
        },
        mounted() {
            this.initTableList();
        },
        methods : {
            // 获取表格数据
            initTableList() {
                axiosApi('/superc/credit/manage/pageList',{
                    loginName : this.accountDefault,
                    name : this.nameDefault,
                },(res) => {
                    if(res.data.data){
                        let data = res.data.data;
                        this.tableList = data.elements || []; 
                        this.total = data.totalCount;
                    }else{

                    }
                },this.currentPage,10);
            },
            // 点击搜索
            handleSearch() {
                this.accountDefault = this.account;
                this.nameDefault = this.name;
                this.currentPage = 1;
                this.initTableList();
            },
            // 分页change
            handlePageChange(val) {
                this.currentPage = val;
                this.initTableList();
            },
            // 浮层分页 change
            handlePageChangeDialog(val) {
                this.currentPageDialog = val;
                this.initTableListDialog();
            },
            // 启用 or 禁用
            handleEnableAndDisable(row) {
                let fn = () => {
                    axiosApi('/superc/credit/manage/states',{
                        traderId : sessionStorage.getItem('traderId'),
                        id : row.id,
                        creditState : row.creditState === 0 ? 1 : 0,
                    },(res) => {
                        this.$message.success('操作成功');
                        this.currentPage = 1;
                        this.initTableList();
                    });
                };
                // 启用
                if(row.creditState === 0){
                    fn();
                // 禁用
                }else{
                    this.$confirm('确认禁用该商家的授信额度?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        fn();
                    }).catch(() => {        
                    });
                }
            },
            // 初始化 浮层表格 数据
            initTableListDialog() {
                axiosApi('/superc/credit/manage/logPageList',{
                    id : this.sxmxId,
                },(res) => {
                    let data = res.data.data;
                    if(data){
                        this.tableListDialog = data.elements || [];
                        this.totalDialog = data.totalCount;
                    }
                },this.currentPageDialog,10);
            },
            // 授信明细
            handleDetail(row) {
                this.sxmxId = row.id;
                this.dialogVisible = true;
                this.currentPageDialog = 1;
                this.initTableListDialog();
            },
        },
    }
</script>

<style lang="less" scoped>
    .creditManagement-box{
        .creditManagement-content{
            padding: 0px 20px 20px 20px;
        }
        .credit-dialog-content{
            padding: 20px 20px;
        }
    }
</style>

<style lang="less">
    .creditManagement-box{
        .el-table td.is-left .cell{
            text-align: left;
        }
        /* 重写浮层样式 */
        .el-dialog{
            width: 900px !important;
            min-height: initial;
        }
        .el-dialog__body{
            padding: 0;
        }
    }
</style>