<template>
  <div class="add_full_activity">
    <!-- title -->
    <div class="title-tabs bg-f">
      <ul>
        <li>添加满赠活动</li>
      </ul>
		</div>
    <!-- end -->
    <div class="content bg-f pl-30 pr-30 pt-30">
			<div class="clearfix">
        <div>
          <div class="full_activity_body">
            <ul>
              <li @click="jump(index)" v-for="(item,index) in fullactivity" :key="index">
                <div class="">
                  <i class='iconfont'></i>
                </div>
                <div class="">
                  <div class="sub-tit" v-text="item.subtit">
                  </div>
                  <div class="sub-body" v-text="item.subbody">
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { basePath } from '../../../api/api' //basePath
  export default {
    data() {
      return {
        fullactivity:[{
          subtit: '满赠促销',
          subbody: '设置购买任意或指定的商品满足的一定金额或数量后，系统将自动赠送商品。'
        },{
          subtit: '加价换购促销',
          subbody: '设置购买任意或指定的商品满足的一定金额或数量后，客户可加价换购指定的商品。'
        }]
      }
    },
    methods: {
      jump(index){// 跳转
        let temp = index === 0 ? false : true
        if(index){// 加价换购促销
          this.$router.push({path: basePath + '/activities/addwithadditional', query:{activitySource: this.$route.query.activitySource || 1}})
        }else{// 满赠促销
          this.$router.push({path: basePath + '/activities/addacaboutenoughsent', query:{activitySource: this.$route.query.activitySource || 1}})
        }
      }
    }
  }
</script>
<style lang="less">
  .add_full_activity{
    overflow: hidden;
    .full_activity_body {
      min-height: 500px;
      overflow: hidden;
      margin-top: 18px;
      ul {
        overflow: hidden;
        li {
          width: 460px;
          float: left;
          height: 140px;
          box-sizing: border-box;
          padding: 20px;
          border: 1px solid #ddd;
          margin-bottom: 20px;
          cursor: pointer;
          &:nth-child(odd) {
            margin-right: 20px;
          }
          &>div {
            float: left;
            &:nth-child(1) {
              &::after {
                content: '';
                display: block;
                height: 1px;
              }
              width: 0px;
              margin-right: 10px;
            }
            &:nth-child(2) {
              /*// width: 300px;*/
              margin-right: 15px;
              .sub-tit {
                font-size: 18px;
                line-height: 18px;
                color: #333;
              }
              .sub-body {
                font-size: 14px;
                color: #666;
                margin-top: 10px;
              }
            }
            &:nth-child(3) {
              button {
                margin-top:  18px;
              }
            }
          }
        }
      }
    }
  }
</style>

