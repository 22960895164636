<template>
	<div id="allProduct" class="pb-50" v-loading='loading'>
		<div class="topDiv title-top bg-f mb-5">
			<div>
                商品管理
            </div>
			<div class="pt-20 pb-20 pr-20">
                <el-button @click="$router.push(basePath+'/monthlySale')">商品销售管理</el-button>
                <el-upload
                    class="dib"
                    action="/sunrise-gateway/import/goods/sku/integr"
                    :show-file-list="false"
                    :on-success="handUpSuccess"
                    :before-upload="handUpBefore"
                    :on-error="handUpError"
                    accept=".xls,.xlsx"
                    v-if="showButton('INTEGRAL_PERIOD_ADD', 260004)"
                >
                    <el-button >批量导入积分</el-button>
                </el-upload>
                <el-button class="mr-0" v-if="showButton('INTEGRAL_PERIOD_ADD', 260004)">
            	    <a style="color:#606266" href="https://sunrise-cbs.oss-cn-hangzhou.aliyuncs.com/template/jiujiajiu/b2b/%E5%95%86%E5%93%81%E7%A7%AF%E5%88%86%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xls" target="_blank">下载积分模板</a>
                </el-button>
                <el-button v-if="showButton('ADD',100055)" @click='goPage'>添加商品</el-button>
				<el-upload
                    class="dib"
                    action="/sunrise-gateway/import/goods/skuCustomerVisibleSetting"
                    :show-file-list="false"
                    :on-success="handUpSuccess"
                    :before-upload="handUpBefore"
                    :on-error="handUpError"
                    accept=".xls,.xlsx"
                >
                    <el-button >批量导入</el-button>
                </el-upload>
                <el-button class="mr-0">
            	    <a style="color:#606266" href="https://sunrise-cbs.oss-cn-hangzhou.aliyuncs.com/template/jiujiajiu/b2b/%E5%95%86%E5%93%81%E5%8F%AF%E8%A7%81%E6%80%A7%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xls" target="_blank">下载模板</a>
                </el-button>
			</div>
		</div>
		<div>
			<div class="bg-f pl-30 pr-30 black">
				<ul class="clearfix order_status bb">
					<template v-for='(tab,index) in dataTabss' class="item">
						<li :type='index' class="fl" :class="type==index?'active':''" :key="'i'+index" @click='handleTabs'>
							{{tab.title}}<span :type='index' v-if="tab.num>0" class="color-danger">({{tab.num}})</span>
						</li>
					</template>
				</ul>
			</div>
			<div class="bg-f pl-30 pr-30  pb-50">
				<el-form :inline="true" :model="formInlineData" label-width="60px" class="clearfix pl-20 pt-30 search">
					<div class="fl">
						<el-form-item label="商品编码" class="mr-20">
							<el-input v-model.trim="formInlineData.goodsNo" @input="formInlineData.goodsNo=formInlineData.goodsNo.replace(/[\u4e00-\u9fa5]/ig,'')"></el-input>
						</el-form-item>
						<el-form-item label="商品名称" class="mr-20">
							<el-input v-model.trim="formInlineData.skuName"></el-input>
						</el-form-item>
						<el-form-item label="商品分类" class="mr-20">
							<el-select v-model="formInlineData.id" clearable>
								<el-option :label='item.classifyName' :value="item.id" v-for='item in searchClassify' :key='item.id'></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="商品状态" class="mr-20">
							<el-select v-model="formInlineData.skuState" placeholder="输入状态" clearable :disabled='dis'>
								<!--<el-option label="全部状态" value=null></el-option>-->
								<el-option label="待上架" value='1'></el-option>
								<el-option label="出售中" value='2'></el-option>
							</el-select>
						</el-form-item>
					</div>
				</el-form>
                <div class="pt-5">
                    <el-button type="info" class="ml-20" icon="el-icon-search" @click='achieve1(true)'>搜 索</el-button>
                </div>
				<div class="clearfix tar mb-10">
                    <!-- 3.5.2移除价格同步 -->
                    <!-- <el-button type="text" :loading="AllPriceLoading" v-if="isOpenPriceSync" @click="AllPrice">一键价格同步</el-button> -->
					<el-button type='text' @click="handDown">导出</el-button>
					<el-button type='text' v-if='showButton("PUT",100055)' @click='getInfo(1)'>批量上架</el-button>
					<el-button type='text' v-if='showButton("DOWN",100055)' @click='getInfo(2)'>批量下架</el-button>
				</div>
				<el-table :data="tableData" v-loading="Loading" class='table-default' @selection-change="handleSelectionChange" stripe>
					<el-table-column type="selection" width="40" :key="Math.random()"></el-table-column>
					<el-table-column prop="1" label="商品名称" min-width="262" :key="Math.random()">
						<template slot-scope="scope">
							<div class="fl ml-10">
								<img v-if='scope.row.fileUrl' :src="scope.row.fileUrl" />
								<img v-else src="../../assets/goods.jpg" />
							</div>
							<div class="fl ml-10 mt-10">
								<div class="tal w-160">商品编码:{{scope.row.goodsNo}}</div>
								<div class="tal w-160 ellipsis">{{scope.row.skuName}}</div>
							</div>
						</template>
					</el-table-column>
                    <el-table-column label="积分系数" min-width="90">
						<template slot-scope="{row}">                        
							<div>{{ row.integration || '--'}}</div>
                            <div v-if="row.integrationEffectiveStart" style="color:red">
                                <div ><span>启用时间:</span><span>{{row.integrationEffectiveStart}}</span></div>
                                <div ><span>结束时间:</span><span>{{row.integrationEffectiveEnd}}</span></div>
                            </div>
						</template>
					</el-table-column>
					<el-table-column label="规格" min-width="50">
						<template slot-scope="scope">
							<div>1*{{scope.row.specInfoNum}}</div>
						</template>
					</el-table-column>
					<el-table-column label="商品分类" min-width="70" prop='classifyName'></el-table-column>
					<el-table-column label="库存" min-width="70">
						<template slot-scope="scope">
							<!-- <div v-if="scope.row.erpType==0">{{scope.row.stockEnableQuantity}}</div>
							<div v-if="scope.row.erpType!=0">
								<div>
									{{scope.row.isIntegerMultiple?scope.row.stockEnableSpecQuantity:scope.row.stockEnableQuantity}}
								</div>
							</div> -->
                            <div>
                                <div v-if="scope.row.skuType !== 2">
                                    <p>单{{ scope.row.unitsName }}:{{scope.row.stockEnableQuantity}}</p>
                                    <p>整{{ scope.row.packageUnitName }}:{{ Math.floor(scope.row.stockEnableQuantity / scope.row.specInfoNum) }}</p>
                                </div>
                                <div v-else>
                                    <p>单{{ scope.row.packageUnitName }}:{{scope.row.stockEnableQuantity}}</p>
                                </div>
                            </div>
						</template>
					</el-table-column>
					<el-table-column label="进店价" min-width="120">
						<template slot-scope="scope">
							<div>
                                <div v-if="scope.row.skuType !== 2">
                                    <p>单{{ scope.row.unitsName }}:￥{{scope.row.unitEnterShopPrice | fmoney}}</p>
                                    <p>整{{ scope.row.packageUnitName }}:￥{{scope.row.integerEnterShopPrice | fmoney}}</p>
                                </div>
                                <div v-else>
                                    <p>单{{ scope.row.packageUnitName }}:￥{{scope.row.enterShopPrice | fmoney}}</p>
                                </div>
                            </div>
						</template>
					</el-table-column>
					<el-table-column label="销量" min-width="70" prop='sales'>
                        <template slot-scope="scope">
                            <div>
                                <div v-if="scope.row.skuType !== 2">
                                    <p>单{{ scope.row.unitsName }}:{{scope.row.sales}}</p>
                                    <p>整{{ scope.row.packageUnitName }}:{{Math.floor(scope.row.sales / scope.row.specInfoNum )}}</p>
                                </div>
                                <div v-else>
                                    <p>单{{ scope.row.packageUnitName }}:{{scope.row.sales}}</p>
                                </div>
                            </div>
                        </template>
                    </el-table-column>
					<el-table-column label="状态" min-width="70">
						<template slot-scope="scope">
							<span v-if="scope.row.skuState == '1'">待上架</span>
							<span v-if="scope.row.skuState == '2'">出售中</span>
						</template>
					</el-table-column>
                    <el-table-column label="销售类型" min-width="90">
						<template slot-scope="scope">
							<div>
                                <div v-if="scope.row.skuType !== 2">
                                    <p v-if="scope.row.salesUnitType == 1">整{{ scope.row.packageUnitName }} + 单{{ scope.row.unitsName }}</p>
                                    <p v-if="scope.row.salesUnitType == 2">整{{ scope.row.packageUnitName }}</p>
                                    <p v-if="scope.row.salesUnitType == 3">单{{ scope.row.unitsName }}</p>
                                </div>
                                <div v-else>
                                    <p>套</p>
                                </div>
                            </div>
						</template>
					</el-table-column>
					<el-table-column label="操作">
						<template slot-scope="scope">
							<!--skuType===2是套餐商品-->
							<div v-if="scope.row.skuType === 2">
								<router-link :to="`${basePath}/packgoodInfo?skuId=${scope.row.id}`" target=_blank>
									<el-button class='ml-0 tal' size="small" type='text'>编辑</el-button>
								</router-link>
								<div v-if='scope.row.skuState==1'>
									<el-button v-if="showButton('PUT',100055)" class='ml-0 tal' size="small" type='text' @click.native="upperlower(scope.row.skuState, scope.row)">上架
									</el-button>
								</div>
								<div v-else>
									<el-button v-if="showButton('DOWN',100055)" class='ml-0 tal' size="small" type='text' @click.native="upperlower(scope.row.skuState, scope.row)">下架
									</el-button>
								</div>
								<!-- <el-button v-if="showButton('DEL',100055)" class='ml-0 tal' size="small" type='text' @click.native="deleteRow(scope.$index, tableData)">删除
								</el-button> -->
							</div>
							<!--普通sku商品-->
							<div v-else>
                                <router-link v-if="showButton('EDIT',100055)" :to="`${basePath}/editCommHome/${scope.row.id}/${scope.row.spuNo}/0`" target=_blank>
                                    <el-button class='ml-0 tal' size="small" type='text'>编辑</el-button>
                                </router-link>
                                <!--<el-button class='ml-20 tal' size="small" type='text' @click.native="upperlower(scope.$index, tableData)">{{scope.row.skuState=='1'?'上架':'下架'}}</el-button>-->
                                <div v-if='scope.row.skuState==1'>
                                    <el-button v-if="showButton('PUT',100055)" class='ml-0 tal' size="small" type='text' @click.native="upperlower(scope.row.skuState, scope.row)">上架
                                    </el-button>
                                </div>
                                <div v-else>
                                    <el-button v-if="showButton('DOWN',100055)" class='ml-0 tal' size="small" type='text' @click.native="upperlower(scope.row.skuState, scope.row)">下架
                                    </el-button>
                                </div>
							</div>
							
						</template>
					</el-table-column>
				</el-table>
				<el-pagination class="tar mt-20" v-if="total>0" :current-page="currentPage" :page-size='10' :total='total' layout="prev , pager, next, jumper" @current-change="handleCurrentChange">
					<!-- 分页组件 -->
				</el-pagination>
			</div>
		</div>
	

		
		<form ref="form" action='/sunrise-gateway/export/goods/skulist' style="display: none">
			<input type="hidden" name="modelName" v-model="a">
			<input type="hidden" name="fileName" v-model="b">
			<input type="hidden" name="sellerId" v-model="c">
			<input type="hidden" name="auditState" v-model="d">
            <div v-if="multipleSelection.length==0">
                <input type="hidden" name="goodsNo" v-model="formInlineData.goodsNo">
                <input type="hidden" name="skuName" v-model="formInlineData.skuName">
                <input type="hidden" name="classifyId" v-model="formInlineData.id">
                <input type="hidden" name="skuState" v-model="formInlineData.skuState">
            </div>
            <div v-else>
                <input type="hidden" name="ids" v-model="idListSelection">
            </div>
            
		</form>

		<el-dialog title="商品下架" :visible.sync="productVisible">
			<p class="color-9 lh-24">以下商品与经销商商品关联，下架后则经销商商品对客户不可见，请根据业务需要进行商品下架；注：“全部下架”则下架已选中的所有商品；“部分下架”则只下架已选中的未关联经销商商品；</p>
			<el-table :data="confirmSkuList" stripe border tooltip-effect="dark" class="mt-20 table-default">
				<el-table-column label="商品名称" prop='skuName' width="350" show-overflow-tooltip>
					<template slot-scope="scope">
						<div class="fl ml-10">
							<img v-if='scope.row.fileUrl' :src="scope.row.fileUrl" />
							<img v-else src="../../assets/goods.jpg" />
						</div>
						<div class="fl ml-10 mt-10">
							<div class="tal w-160">商品编码:{{scope.row.goodsNo}}</div>
							<div class="tal w-160">{{scope.row.skuName}}</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column label="规格" prop='specInfoNum'>
					<template slot-scope="scope">
						<div>1*{{scope.row.specInfoNum}}</div>
					</template>
				</el-table-column>
			</el-table>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" @click="downAllProduct(1)" :loading="btnLoading">全部下架</el-button>
				<el-button @click="downAllProduct(0)">部分下架</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
    import axios from 'axios';
    import {axiosApi, basePath, baseURI} from "../../api/api";
    export default {
        data() {
            return {
                // formSet:{
                //     isOpenGoods: 1,
                //     priceUnit:1,
                //     nonAgreementGoodsIconfont:'非协议',
                //     recentPurchasePrice:0
                // },
                // currentIndex:'0',
                btnLoading:false,
                productVisible:false,
                a: 'goods.skulist',
                b: '商品列表.xls',
                c: sessionStorage.getItem('traderId'),
                d: 2,
                basePath: basePath,
                dataTabss: [{title:"全部商品",num:0}, {title:"出售中",num:0}, {title:"待上架",num:0}],
                type: '0',
                Loading: true,
                loading: false,
                // dialogAreaVisible: false,
                formInlineData: {
                    name: '',
                    skuNo: '',
                    skuName: '',
                    classifyName: '',
                    classifyId: '',
                    id: '',
                    skuState: null,
                    AuditState: 2,
                    isAuthorizationFailure: null,
                    goodsNo: ''
                },
                // activeTab: '1',
                tableData: [], //商品列表
                searchClassify: [], //搜索分类
                //				分页
                currentPage: 1,
                total: 0,
                // isPagination: true,
                // count1: null,
                // count2: null,
                // count3: null,
                title1: '', //上下架
                // skuState1: null,
                // arr1: [],
                arr2: [],
                all: false,
                multipleSelection: [],
                idListSelection: '',
                classifyName: null,
                // brandName: null,
                classifyId: null,
                // brandId: null,
                // rej: 1, //判断是否进入审核页面
                // isOpenGoods: 0,
                // spread: false,
                dis: false,
                // news: '撤回', //判断撤回/删除
                multipleSelectionIdList: [],
                // isOpenPriceSync: false,
                confirmSkuList:[],//管理经销商商品
				// isChange:false,
                // AllPriceLoading: false, // 一键同步
            }
        },
        methods: {
            handUpSuccess(res, file, fileList) {
				// if(res)
				this.upLoading = false
				switch (res.code) {
					case "000000": //系统交易成功
						if(res.data){
							var str = ''
							res.data.forEach(item=>{
								str += `<p>${item}</p>`
							})
							this.$alert(str, "提示信息", {
								confirmButtonText: "确定",
								dangerouslyUseHTMLString: true
							});
						}else{
							this.$message({
								message: '导入成功！',
								type: 'success'
							});
							this.achieve1();
						}
						
						break;
					case "AUTH_001": //用户未登陆
					case "LOGIN_006": //用户session失效
						gotoLoginPage();
						break;
					default:
						this.$alert(res.message, "提示信息", {
							confirmButtonText: "确定",
							
						});
						break;
				}
			},
            handUpBefore(file) {
				this.upLoading = true;
				//   this.$loading({
				//     fullscreen: false,
				//     lock: true,
				//     text: "正在导入..."
				//   });

				//   return true;
			},
            handUpError(err, file, fileList) {
                this.$loading().close();
                this.$message.error("导入失败！");
            },
         
          
            // findTraderInfoBySerllerId() {// 查询商品是否开通同步K3
            //     return new Promise((resolve, reject) => {
            //         axiosApi('/trader/findTraderInfoBySerllerId/select', {
            //             'traderId': sessionStorage.getItem('traderId')
            //         }, (res) => {
            //             resolve(res)
            //         }, 1, 10, err => {
            //             reject(err)
            //         })
            //     })
            // },
            
            handDown() {
                if (this.total) {// 有商品数据
                    this.$refs.form.submit();
                } else {
                    this.$message.warning('没有商品数据')
                }
            },
            handleTabs(e) {
                // if(e.target.type) this.type = e.target.type // 改变 type的值
                if (e.target.attributes.type) this.type = e.target.attributes.type.value

                if (this.type == '0') {
                    this.dis = false;
                    //重置表单
                    this.resetData({
                        name: this.formInlineData,
                        data: ['goodsNo', 'skuName', 'id', 'isAuthorizationFailure', 'skuState']
                    })
                    // this.formInlineData.goodsNo = null,
                    // this.formInlineData.skuName = null,
                    // this.formInlineData.id = null,
                    // this.formInlineData.isAuthorizationFailure = null,
                    // this.formInlineData.skuState = null,
                    this.formInlineData.AuditState = '2';
                } else if (this.type == '1') {
                    this.dis = true;
                    this.resetData({
                        name: this.formInlineData,
                        data: ['goodsNo', 'skuName', 'id', 'isAuthorizationFailure']
                    })
                    this.formInlineData.skuState = '2';
                    this.formInlineData.AuditState = '2';
                } else if (this.type == '2') {
                    this.dis = true;
                    this.resetData({
                        name: this.formInlineData,
                        data: ['goodsNo', 'skuName', 'id', 'isAuthorizationFailure']
                    })
                    this.formInlineData.skuState = '1',
                        this.formInlineData.AuditState = '2'
                }
                setTimeout(() => {
                	if(this.currentPage === 1){
						this.achieve1()
                    }else{
						this.currentPage = 1
                    }
                }, 0)
            },
            // setGo() {
            //     setTimeout(() => {
            //         this.showButton("DOWN", 100055)
            //     }, 100)
            // },
            //去商品库
            goPage() {
                this.$router.push(basePath + '/addProduct')
            },
            //判断是否有jde权限
            // isJde() {
            //     const api = "/trader/findSellerJurisdiction/select";
            //     const body = {
            //         traderId: sessionStorage.traderId
            //     }
            //     axiosApi(api, body, (res) => {
            //         this.isOpenGoods = res.data.data.isOpenGoods
            //         this.formSet.isOpenGoods = res.data.data.isOpenGoods
            //         this.getCount()
            //     }, this.currentPage, 10)
            // },
            //加载商品列表
            achieve() {
                const api = "/goods/skuInfoPage/select";
                const body = {
//					sellerId: sessionStorage.traderId,
//					auditState: 2,
                    sellerId: sessionStorage.traderId,
                    goodsNo: this.formInlineData.goodsNo,
                    skuName: this.formInlineData.skuName,
                    classifyId: this.formInlineData.id,
                    skuState: this.formInlineData.skuState,
                    auditState: this.formInlineData.AuditState,
                    isAuthorizationFailure: this.formInlineData.isAuthorizationFailure,
                }
                axiosApi(api, body, this.callback, this.currentPage, 10,()=>{
                    this.Loading = false;
                })
            },
            callback(res) {
                this.Loading = false;
                this.tableData = res.data.data.elements ? res.data.data.elements : [];
                this.total = res.data.data.totalCount ? res.data.data.totalCount : '';
            },
            //查询
            achieve1(bool) {
                this.loading = true
                if (bool && this.currentPage != 1) {
                    this.currentPage = 1;
                    // return;
                }
                this.getCount();
                const api = "/goods/skuInfoPage/select";
                const body = {
                    sellerId: sessionStorage.traderId,
                    goodsNo: this.formInlineData.goodsNo,
                    skuName: this.formInlineData.skuName,
                    classifyId: this.formInlineData.id,
                    skuState: this.formInlineData.skuState,
                    auditState: this.formInlineData.skuState == null ? this.formInlineData.AuditState : "2",
                    //					auditState: this.formInlineData.AuditState,
                    isAuthorizationFailure: this.formInlineData.isAuthorizationFailure,
                }
                axiosApi(api, body, this.callback3, this.currentPage, 10,()=>{
                    this.loading = false
                })
            },
            callback3(res) {
                this.tableData = res.data.data.elements ? res.data.data.elements : [];
                this.total = res.data.data.totalCount ? res.data.data.totalCount : '';
                this.currentPage = res.data.data.pageIndex;
                this.loading = false
            },
            //			分页
            handleCurrentChange(val) {
                this.currentPage = val
                this.achieve1()
            },
            //查询分类
            classSearch() {
                const api = "/goods/classifyTopList/select";
                const body = {
                    classificationId: ''
                }
                axiosApi(api, body, this.classsearch)
            },
            classsearch(res) {
                this.searchClassify = res.data.data;
            },
            deleteRow(index, rows) {
                let that = this;
                this.$confirm('您是否要删除这件商品？', '提示信息', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    const api = this.tableData[index].skuType === 2 ? '/goods/setMeal/info/delete' : "/goods/sku/delete";
                    const body = {id: this.tableData[index].id};
                    axiosApi(api, body, (res) => {
                        this.$message({
                            type: 'success',
                            message: '删除成功!',
                            duration: 1000
                        });
                        this.achieve();
                        this.getCount()
                    }, this.currentPage, 10, (res) => {
                        this.$message({
                            type: 'info',
                            message: res.data.message,
                            duration: 3000
                        });
                    });
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                })
            },

            //角标
            getCount() {
                this.tLoading = true;
                const api = "/goods/skuCount/select";
                const body = {sellerId: sessionStorage.traderId}
                axiosApi(api, body, res => {
					this.tLoading = false;
					let arr = this.dataTabss;
					let arrTab = JSON.parse(JSON.stringify(arr));
					arrTab.map(v => {
						if(v.title === '出售中'){
							v.num = res.data.data.standUpCount;;
						}
						if(v.title === '待上架'){
							v.num = res.data.data.standdownCount;
						}
					
                        if(v.title === '全部商品'){
                            v.num = res.data.data.auditPassCount;
                        }
					})
					this.dataTabss = arrTab
                }, 1, 10,err => {
					this.tLoading = false;
                	this.$message.warning(err.data.message)
                })
            },
            
            //单个商品下架处理
            offSale(skuNos){
                const api = "/goods/sku/offSale";
                const body = {
                    skuNos: skuNos
                };
                axiosApi(api, body, (res) => {
                    if(res.data.data.length>0){
                        this.confirmSkuList = res.data.data;
                        this.productVisible = true;
                    }else{
                        that.$message({
                            type: 'success',
                            message: '操作成功!',
                            duration: 1000
                        });
                        this.achieve1();
                    }
                }, null, null, (res) => {
                    this.$message({
                        type: 'info',
                        message: res.data.message,
                        duration: 3000
                    });
                });
            },
            upperlower(skuState, row) {
                if (skuState == '1') {
                    this.title1 = '上架'
                    var api = "/goods/sku/onSale";
                } else {
                    this.title1 = '下架'
                    var api = "/goods/sku/offSale";
                }
                let that = this;
                this.$confirm('您是否要' + this.title1 + '该商品？', '提示信息', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    const body = {
                        skuNos: row.skuNo,
                    };
                    axiosApi(api, body, (res) => {
                        if(res.data.data.length>0){
                            this.confirmSkuList = res.data.data;
                            this.productVisible = true;
                        }else{
                            that.$message({
                                type: 'success',
                                message: '操作成功!',
                                duration: 1000
                            });
                            this.achieve1();
                        }

                    }, this.currentPage, 10, (res) => {
                        this.$message({
                            type: 'info',
                            message: res.data.message,
                            duration: 3000
                        });
                    });
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: `已取消${this.title1}`
                    });
                })
            },
            handleSelectionChange(val) { //table 选中行
                var idListSelection = []
                this.multipleSelectionIdList = val.map((item) => {
                    idListSelection.push(item.id)
                    return item.id
                })
                
                this.multipleSelection = val
                this.idListSelection = idListSelection.join(',')
                console.log(this.multipleSelection,this.idListSelection)
            },
            getInfo(type) {
                if (this.multipleSelection.length == 0) {
                    this.$notify({
                        title: '提示信息',
                        message: '您未选中任何商品！',
                        type: 'warning'
                    });
                } else {
                    let _this = this
                    this.multipleSelection.forEach(function (item, index) {
                        _this.arr2.push(item.skuNo);
                    })
                    if (this.arr2.length > 1) {
                        this.arr2 = this.arr2.join(',')
                    } else {
                        this.arr2 = this.arr2[0]
                    }
                    if (type == 1) {
                        this.allupperlower('1')
                        this.title1 = '上架'
                    } else {
                        this.title1 = '下架'
                        this.allupperlower('2')
                    }
                    
                }

            },
            //批量上下架
            allupperlower(skuState) {
                if (skuState == '1') {
                    this.title1 = '上架'
                    var api = "/goods/sku/onSale";
                } else {
                    this.title1 = '下架'
                    var api = "/goods/sku/offSale";
                }
                let that = this;
                this.$confirm('您是否要' + this.title1 + '选中商品？', '提示信息', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    const body = {
                        skuNos: that.arr2,
                    };
                    axiosApi(api, body, (res) => {
                        console.log(res,'res');
                        if(res.data.data.length>0){
                            this.confirmSkuList = res.data.data;
                            this.productVisible = true;
                        }else{
                            that.$message({
                                type: 'success',
                                message: '操作成功!',
                                duration: 1000
                            });
                            this.achieve1();
                            this.arr2 = []
                        }
                    }, this.currentPage, 10, (res) => {
                        this.$notify({
                            type: 'warning',
                            message: res.data.message,
                            duration: 3000
                        });
                        this.arr2 = []
                    });
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消操作'
                    });
                    this.arr2 = []
                })
            },
            //下架所有商品  包含经销商商品
            downAllProduct(flag){
                if(flag){
                    const api = "/goods/sku/offSale";
                    let arr = this.confirmSkuList.map( v => v.skuNo);
                    const body = {
                        confirmSkus: arr.join(','),
                    };
                    axiosApi(api, body, (res) => {
                        this.achieve1();
                        this.arr2 = [];
                        this.productVisible = false;
                        this.confirmSkus = [];
                    },1,10,err => {
                        this.$notify({
                            type: 'warning',
                            message: err.data.message,
                            duration: 3000
                        });
                    })
                }else{
                    this.achieve1();
                    this.arr2 = [];
                    this.confirmSkuList = [];
                    this.productVisible = false
                }
            },
            //编辑/查看
            // handleEdit(scope) {
            // 	if(scope.row.edit) {
            // 		this.rej = 2 //查看
            // 		this.$router.push(basePath + "/editCommHome/" + scope.row.id + '/' + scope.row.spuNo + '/' + this.rej);
            // 	} else {
            // 		this.rej = 0 //编辑
            // 		this.$router.push(basePath + "/editCommHome/" + scope.row.id + '/' + scope.row.spuNo + '/' + this.rej);
            // 	}
            // },
            //审核
            // handleCheck(scope) {
            //     this.rej = 1 //审核
            //     this.$router.push(basePath + "/editCommHome/" + scope.row.id + '/' + scope.row.spuNo + '/' + this.rej);
            // },
            //未通过
            // notPass(scope) {
            // 	this.rej = 0 //编辑
            // 	this.$router.push(basePath + "/editCommHome/" + scope.row.id + '/' + scope.row.spuNo + '/' + this.rej);
            // },
            //待审核/未通过商品撤回删除
            // regret(scope, type) {
            //     if (type == 1) {
            //         this.news = '撤回'
            //     } else {
            //         this.news = '删除'
            //     }
            //     let that = this;
            //     this.$confirm('您是否要' + this.news + '该商品？', '提示信息', {
            //         confirmButtonText: '确定',
            //         cancelButtonText: '取消',
            //         type: 'warning'
            //     }).then(() => {
            //         const api = "/goods/checkSku/delete";
            //         const body = {id: scope.row.id};
            //         axiosApi(api, body, (res) => {
            //             this.$message({
            //                 type: 'success',
            //                 message: '操作成功!',
            //                 duration: 1000
            //             });
            //             that.achieve1();
            //         }, this.currentPage, 10, (res) => {
            //             this.$message({
            //                 type: 'info',
            //                 message: res.data.message,
            //                 duration: 3000
            //             });
            //         });
            //     }).catch(() => {
            //         this.$message({
            //             type: 'info',
            //             message: '已取消操作'
            //         });
            //     })
            // },
		},
		//进入加载
		activated() {
			this.$nextTick(function() {
				// this.findSellerJurisdiction();
                this.getCount()
				this.achieve();
				this.classSearch();
			    // this.isJde();
				// this.findTraderInfoBySerllerId().then(res => {
				// 	this.formSet.nonAgreementGoodsIconfont = res.data.data.nonAgreementGoodsIconfont || '非协议'
				// 	this.formSet.priceUnit = res.data.data.priceUnit
                //     this.isOpenPriceSync = res.data.data.isOpenPriceSync || false
                //     this.formSet.recentPurchasePrice = res.data.data.recentPurchasePrice ? 1 : 0;
				// })
			})
        },
        mounted(){
        },
        watch:{
            $route:{
                handler: function(a){
                    if(a.query.type){
                        this.type = a.query.type
                        if (this.type == '0') {
                    this.dis = false;
                    //重置表单
                    this.resetData({
                        name: this.formInlineData,
                        data: ['goodsNo', 'skuName', 'id', 'isAuthorizationFailure', 'skuState']
                    })
                   
                    this.formInlineData.AuditState = '2';
                } else if (this.type == '1') {
                    this.dis = true;
                    this.resetData({
                        name: this.formInlineData,
                        data: ['goodsNo', 'skuName', 'id', 'isAuthorizationFailure']
                    })
                    this.formInlineData.skuState = '2';
                    this.formInlineData.AuditState = '2';
                } else if (this.type == '2') {
                    this.dis = true;
                    this.resetData({
                        name: this.formInlineData,
                        data: ['goodsNo', 'skuName', 'id', 'isAuthorizationFailure']
                    })
                    this.formInlineData.skuState = '1',
                        this.formInlineData.AuditState = '2'
                }
                    }
                },
                deep:true,
                immediate: true
            }
        }
	}
</script>

<style lang="less">
    #allProduct {
        .topDiv{
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        .bb {
            border-bottom: 1px solid #DDDDDD;
        }
        .order_status {
            height: 60px;

            li {
                box-sizing: border-box;
                height: 26px;
                line-height: 26px;
                padding-left: 10px;
                padding-right: 10px;
                list-style-type: none;
                margin-top: 17px;
                margin-right: 10px;
                cursor: pointer;
                user-select: none;
                border-radius: 2px;

                &:hover {
                    color: #ff4201;
                }
            }

            .active {
                border: 1px solid #ff4201;
                color: #ff4201;
            }
        }
        .el-dialog{
            width: 50%;
            padding: 0 30px;
        }
        .el-dialog__body{
            padding-top: 20px;
        }
        .table-default .el-button {
            width: auto;
        }
        .bb {
            border-bottom: 1px solid #DDDDDD;
        }
        .c {
            position: relative;
        }
        .order_status {
            height: 60px;

            li {
                box-sizing: border-box;
                height: 26px;
                line-height: 26px;
                padding-left: 10px;
                padding-right: 10px;
                list-style-type: none;
                margin-top: 17px;
                margin-right: 10px;
                cursor: pointer;
                user-select: none;
                border-radius: 2px;

                &:hover {
                    color: #ff4201;
                }
            }

            .active {
                border: 1px solid #ff4201;
                color: #ff4201;
            }
        }
        .count4 {
            position: absolute;
            left: 235px;
            top: 15px;
            z-index: 10;
        }

        .el-tabs__header {
            padding-left: 0;
        }

        .count2 {
            position: absolute;
            left: 308px;
            top: 15px;
            z-index: 10;
        }

        .count3 {
            position: absolute;
            left: 435px;
            top: 15px;
            z-index: 10;
        }

        .red {
            color: red;
        }

        .w-400 {
            width: 400px;
        }

        .w-160 {
            width: 160px;
        }

        .el-dialog {
            min-height: 300px;
        }

        .width-70 {
            width: 70px;
        }

        .black {
            .edit-btn span {
                color: #000000 !important;
            }
        }

        .obt {
            margin-top: 80px;
            margin-right: 60px;
        }
    }
</style>
