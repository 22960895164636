<template>
  <div id="advertisingManage" class="pb-50">
    <div class="clearfix bg-f mb-5">
      <div class="title-tabs2 bg-f mb-5">
        <ul class="clearfix">

          <el-tabs v-model="activeName" @tab-click="handleTab">
            <el-tab-pane v-for="(tab,index) in dataTabs" :key="index" :label="tab.name" :name="tab.type"></el-tab-pane>
          </el-tabs>
        </ul>
        <div class="fl" v-if="activeName!='0'">
          <el-button class="fr mr-0" icon="el-icon-plus" @click="addAdvertising">新增广告</el-button>
        </div>
      </div>
    </div>
    <!-- 云商 -->
    <div v-if="activeName==0" class="bg-f black" style="padding:30px">
     <advertisement/>
    </div>
    <!-- 久集 -->
    <div v-else>
      <div class="bg-f pl-30 pr-30 black">
        <ul class="clearfix order_status bb">
          <template v-for="(tab,index) in dataTabss" class="item">
            <li
              :type="index"
              class="fl"
              :class="type==index?'active':''"
              :key="index"
              @click="handleTabs(index)"
            >
              {{tab.title}}
              <span :type="index" v-if="tab.num>0" class="color-danger">({{tab.num}})</span>
            </li>
          </template>
        </ul>
      </div>
      <div class="bg-f pb-50 pl-30 pr-30">
        <el-table :data="tableData">
          <el-table-column label="广告名称" align="center" min-width="200px">
            <template slot-scope="scope">{{scope.row.advertisementName}}</template>
          </el-table-column>
          <el-table-column label="推送时间" align="center" min-width="260px;">
            <template slot-scope="scope">
              <span
                v-if="!scope.row.isDefault"
              >{{scope.row.gmtAdvertisementStart | time}}-{{scope.row.gmtAdvertisementEnd | time}}</span>
              <span v-else>永久有效</span>
            </template>
          </el-table-column>
          <el-table-column label="轮播图" align="center" min-width="100px;">
            <template slot-scope="scope">
              <el-button type="text" @click="lookImg(scope.row.imgUrl)">查看</el-button>
            </template>
          </el-table-column>
          <el-table-column label="状态" align="center" min-width="90px;">
            <template
              slot-scope="scope"
            >{{scope.row.status == 1?'未开始':scope.row.status == 2?'进行中':scope.row.status ==3?'已结束':'已终止'}}</template>
          </el-table-column>
          <el-table-column label="广告位置" align="center" min-width="90px;">
            <template slot-scope="scope">{{scope.row.locationType == 1?'店铺店招':'App首页'}}</template>
          </el-table-column>
          <el-table-column label="操作" align="center" min-width="150px">
            <template slot-scope="scope">
              <el-button
                type="text"
                @click="handleEdit(scope.row)"
                v-if="(scope.row.status == 1 || scope.row.status == 2) && !scope.row.isDefault"
              >编辑</el-button>
              <el-button
                type="text"
                @click="stopAdvertising(scope.row.id)"
                v-if="(scope.row.status == 1 || scope.row.status == 2) && !scope.row.isDefault"
              >终止</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          class="tar mr-30"
          v-if="total>0"
          :current-page="currentPage"
          :page-size="10"
          :total="total"
          layout="prev , pager, next, jumper"
          @current-change="handleCurrentChange"
        >
          <!-- 分页组件 -->
        </el-pagination>
      </div>
      <!--新增久集广告-->
      <el-dialog class="minMop" :title="isEdit?'编辑广告':'新增广告'" :visible.sync="customer" width="770px">
        <el-form class="pl-30 pr-30 formDiv" :rules="rules" ref="form" :model="form">
          <el-form-item label=" " prop="supercType">
            <el-radio-group v-model="form.supercType" size="medium">
              <el-radio :label="1">社会版久集</el-radio>
              <el-radio :label="2">酒店版久集</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="广告名称:" prop="advertisementName" required>
            <el-input
              @keyup.native="form.advertisementName = form.advertisementName.replace(/[\ud800-\udbff][\udc00-\udfff]/g, '')"
              v-model="form.advertisementName"
              placeholder="广告名称"
              :maxlength="20"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="推送时间:"
            class="fl w400 startError"
            prop="gmtAdvertisementStart"
            required
          >
            <el-date-picker
              v-model="form.gmtAdvertisementStart"
              type="datetime"
              placeholder="开始时间"
              :picker-options="pickerOptions"
              :clearable="false"
            ></el-date-picker>
            <span>-</span>
          </el-form-item>
          <el-form-item label class="fl EndError" prop="gmtAdvertisementEnd" required>
            <el-date-picker
              v-model="form.gmtAdvertisementEnd"
              type="datetime"
              placeholder="结束时间"
              :picker-options="pickerOptions2"
              :clearable="false"
            />
          </el-form-item>
          <el-form-item label="广告位置:" class="clear" prop="locationType" required>
            <el-select v-model="form.locationType" placeholder>
              <el-option
                v-for="(item,index) in siteList"
                :key="index"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="轮播图:" prop="imgUrl">
            <el-upload
              v-loading="loading"
              :class="form.imgUrl?'fileList2 avatar-uploader':'avatar-uploader'"
              list-type="picture-card"
              action="/sunrise-gateway/oss/ossUpload"
              :file-list="fileList"
              :limit="1"
              :on-preview="handlePictureCardPreview"
              :on-change="handleChange"
              :on-success="handleAvatarSuccess"
              :on-remove="handleRemove"
              :before-upload="beforeAvatarUpload1"
            >
              <i class="el-icon-plus" v-if="!form.imgUrl"></i>
              <img v-if="form.imgUrl" class="icon-90" :src="form.imgUrl" />
            </el-upload>
          </el-form-item>
          <span class="ml-pic">图片尺寸{{form.locationType == 2?'343*134':'375*214'}}</span>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button v-if="!isEdit" type="danger" @click="addCustomer('form')">确定</el-button>
          <el-button v-else type="danger" @click="Edit('form')">编辑</el-button>
          <el-button class="ml-20" @click="customer = false">取消</el-button>
        </div>
      </el-dialog>
     
    </div>
    <!-- 查看图片 -->
     <el-dialog :visible.sync="dialogVisible" size="tiny">
        <img width="100%" :src="dialogImageUrl" alt />
      </el-dialog>
  </div>
</template>
<script>
import axios from "axios";
import advertisement from "~/components/advertisement/advertisementList.vue";
import { axiosApi, basePath, baseURI } from "../../api/api";
function filterInput(val) {
  return val.replace(/[\ud800-\udbff][\udc00-\udfff]/g, "");
}
export default {
  components:{
    advertisement
  },
  data() {
    let reg = /[\ud800-\udbff][\udc00-\udfff]/g;
    var validateName = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入广告名称"));
      } else {
        if (reg.test(value)) {
          callback(new Error("禁止输入表情"));
        } else {
          callback();
        }
      }
    };
    return {
      isAllowClick: true,
      pickerOptions: {
        disabledDate(time) {
          return new Date(time).getTime() < Date.now() - 8.64e7; //如果没有后面的-8.64e7就是不可以选择今天的
        }
      },
      pickerOptions2: {
        disabledDate(time) {
          return new Date(time).getTime() < Date.now() - 8.64e7; //如果没有后面的-8.64e7就是不可以选择今天的
        }
      },
      dialogVisible: false,
      dialogImageUrl: null,
      // 一级tab
      dataTabs: [
        {type:'0',name:'云商商城'},
        {type:'1',name:'社会版久集'},
        // {type:'2',name:'酒店版久集'},
      ],
      activeName: '0',
      

      currentIndex: "0",
      type: 0,
      supercType: 1,
      dataTabss: [
        { title: "全部", num: 0 },
        { title: "未开始", num: 0 },
        { title: "进行中", num: 0 },
        { title: "已结束", num: 0 },
        { title: "已终止", num: 0 }
      ],
      siteList: [
        { id: 1, name: "店铺店招" },
        { id: 2, name: "app首页" }
      ],
      customer: false,
      isEdit: false,
      activeTab: "1",
      tableData: [], //商品列表
      searchClassify: [], //搜索分类
      currentPage: 1,
      total: 0,
      form: {
        supercType: 1,
        locationType: 1,
        advertisementName: null,
        gmtAdvertisementStart: "",
        gmtAdvertisementEnd: "",
        imgUrl: null
      },
      rules: {
        advertisementName: [
          {
            validator: validateName
          },
          { min: 2, max: 20, message: "长度在 2 到 20 个字符", trigger: "blur" }
        ],
        imgUrl: [
          {
            required: true,
            message: "请上传图片"
          }
        ]
      },
      editable: false,
      loading: false,
      id: null, //广告id
      fileList: [] //图片
    };
  },
  watch: {
    customer(val) {
      if (!val) {
        let _this = this;
        this.isEdit = false;
        setTimeout(() => {
          if (_this.$refs.form) _this.$refs.form.resetFields();
        }, 50);
      }
    },
    dialogVisible(val) {
      if (!val) {
        this.dialogImageUrl = null;
      }
    },
    "form.advertisementName": {
      handler: function(val) {
        this.$nextTick(() => {
          this.form.advertisementName = filterInput(val);
        });
      }
    }
  },
  methods: {
    handleEdit(item) {
      this.isEdit = true;
      this.customer = true;
      this.form.advertisementName = item.advertisementName;
      this.form.gmtAdvertisementStart = new Date(item.gmtAdvertisementStart);
      this.form.gmtAdvertisementEnd = new Date(item.gmtAdvertisementEnd);
      this.form.locationType = item.locationType;
      this.form.supercType = item.supercType;
      this.form.imgUrl = item.imgUrl;
      this.id = item.id;
      this.fileList = [this.form.imgUrl].map(v => {
        return {
          url: v
        };
      });
    },
    //终止
    stopAdvertising(id) {
      this.$msgbox({
        title: "提示信息",
        message: "您确定要终止该广告吗？",
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            axiosApi(
              "/superc/advertisement/disabled",
              { id },
              res => {
                this.$message({
                  type: "success",
                  message: "终止成功"
                });
                this.achieve();
                this.dialogShow = false;
              },
              1,
              10,
              res => {
                this.$message({
                  type: "error",
                  message: res.data.message
                });
              }
            );
            done();
          } else {
            done();
          }
        }
      });
    },
    //预览
    lookImg(url) {
      this.dialogImageUrl = url;
      this.dialogVisible = true;
    },
    //新增广告
    addCustomer(form) {
      let reg = /[\ud800-\udbff][\udc00-\udfff]/g;
      this.$refs[form].validate(valid => {
        if (valid) {
          if (!reg.test(this.form.advertisementName)) {
            this.form.supercType = Number(this.form.supercType);
            this.form.gmtAdvertisementStart = new Date(
              this.form.gmtAdvertisementStart
            ).getTime();
            this.form.gmtAdvertisementEnd = new Date(
              this.form.gmtAdvertisementEnd
            ).getTime();
            let obj = JSON.parse(JSON.stringify(this.form));
            axiosApi(
              "/superc/advertisement/insert",
              obj,
              res => {
                this.$message({
                  type: "success",
                  message: "新增成功"
                });
                this.achieve();
                this.customer = false;
              },
              1,
              10,
              res => {
                this.$message({
                  type: "error",
                  message: res.data.message
                });
              }
            );
          }
        }
      });
    },
    //编辑广告
    Edit(form) {
      let reg = /[\ud800-\udbff][\udc00-\udfff]/g;
      this.$refs[form].validate(valid => {
        if (valid) {
          if (!reg.test(this.form.advertisementName)) {
            let obj = JSON.parse(JSON.stringify(this.form));
            obj.gmtAdvertisementStart = new Date(
              obj.gmtAdvertisementStart
            ).getTime();
            obj.gmtAdvertisementEnd = new Date(
              obj.gmtAdvertisementEnd
            ).getTime();
            this.$msgbox({
              title: "提示信息",
              message: "确定编辑广告吗？",
              showCancelButton: true,
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              beforeClose: (action, instance, done) => {
                if (action === "confirm") {
                  obj.id = this.id;
                  axiosApi(
                    "/superc/advertisement/update",
                    obj,
                    res => {
                      this.$message({
                        type: "success",
                        message: "编辑成功"
                      });
                      this.achieve();
                      this.customer = false;
                    },
                    1,
                    10,
                    res => {
                      this.$message({
                        type: "error",
                        message: res.data.message
                      });
                    }
                  );
                  done();
                } else {
                  done();
                }
              }
            });
          }
        }
      });
    },
    //   打开新增广告
    addAdvertising() {
      this.customer = true;
      this.form.advertisementName = null;
      this.form.locationType = 2;
      this.form.supercType = this.activeName;
      this.form.imgUrl = null;
      this.fileList = [];
      this.form.gmtAdvertisementStart = new Date();
      this.form.gmtAdvertisementEnd = new Date();
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    beforeAvatarUpload1(file) {
      this.loading = true;
      const isImage =
        file.type.indexOf("jpg") != -1 ||
        file.type.indexOf("jpeg") != -1 ||
        file.type.indexOf("png") != -1 ||
        file.type.indexOf("bmp") != -1;
      const isLt4M = file.size / 1024 / 1024 < 4 && file.size / 1024 / 1024 > 0;
      if (!isImage) {
        this.loading = false;
        this.$message.error("上传图片只能是jpg,jpeg,png,bmp格式");
      }
      if (!isLt4M) {
        this.loading = false;
        this.$message.error("图片大小不能大于4MB");
      }
      return isImage && isLt4M;
    },
    handleAvatarSuccess(res) {
      this.loading = false;
      this.form.imgUrl = res.data[0];
    },
    handleRemove(res) {
      this.form.imgUrl = null;
    },
    handleChange() {
      //   this.$refs.ruleForm.validateField("imgUrl");
      //   if (this.form.fileUrls.length >= 6) {
      //     this.isDisable = true;
      //   } else {
      //     this.isDisable = false;
      //   }
    },
    handleTab({name,label},event) {
      if(name !== '0'){
        // 久集
        this.currentPage = 1;
        this.supercType = name;
        this.type = 0;
        this.achieve();
      }
    },


    handleTabs(index) {
      if (this.isAllowClick) {
        this.currentPage = 1;
        this.type = index;
        this.achieve();
      }
    },
    //查询
    achieve() {
      this.isAllowClick = false;
      axiosApi(
        "/superc/advertisement/selectPage",
        {
          supercType: this.supercType, //1社会2酒店
          status: this.type //
        },
        res => {
          this.tableData = res.data.data.elements ? res.data.data.elements : [];
          this.total = res.data.data.totalCount;
          this.isAllowClick = true;
        },
        this.currentPage,
        10,
        res => {
          this.isAllowClick = true;
        }
      );
    },
    //			分页
    handleCurrentChange(val) {
      if (this.isAllowClick) {
        this.currentPage = val;
        this.achieve();
      }
    }
  },
  //进入加载
  mounted() {
    this.$nextTick(function() {
      this.achieve();
    });
  }
};
</script>

<style lang="less">
@import "../../../theme/util.less";
#advertisingManage {
    .el-button+.el-button {
        margin-left: 0px;
    }
  .ml-pic {
    margin-left: 185px;
    color: #ff4201;
    font-size: 12px;
  }
  .bb {
    border-bottom: 1px solid #dddddd;
  }
  .w400 {
    width: 420px !important;
  }
  .order_status {
    height: 60px;

    li {
      box-sizing: border-box;
      height: 26px;
      line-height: 26px;
      padding-left: 10px;
      padding-right: 10px;
      list-style-type: none;
      margin-top: 17px;
      margin-right: 10px;
      cursor: pointer;
      user-select: none;
      border-radius: 2px;

      &:hover {
        color: #ff4201;
      }
    }

    .active {
      border: 1px solid #ff4201;
      color: #ff4201;
    }
  }
  .startError {
    .el-form-item__error {
      margin-left: 70px;
    }
  }
  .EndError {
    .el-form-item__error {
      margin-left: -50px;
    }
  }
  .el-form-item__error {
    left: 24% ;
  }
  .formDiv{
    .avatar-uploader {
      margin-left: 190px;
      width: 90px ;
      height: 90px ;
      border: 1px solid #cccccc;
      overflow: hidden;
    }
    .el-upload-list__item {
      width: 90px ;
      height: 90px ;
    }
    .el-upload--text {
      width: 90px ;
      height: 90px ;
      border: 1px solid #ccc;
    }
    .fileList2 .el-upload--picture-card {
      display: none;
    }
    .el-upload--picture-card {
      width: 90px ;
      height: 90px ;
      line-height: 90px ;
      border: none ;
    }
  }

  .el-dialog {
    width: 860px;
    padding: 0 30px;
  }
  .el-dialog__body {
    padding-top: 20px;
  }
  .table-default .el-button {
    width: auto;
  }
  .el-table__body-wrapper {
    margin-top: 1px !important;
  }
  .bb {
    border-bottom: 1px solid #dddddd;
  }
  .c {
    position: relative;
  }
  .order_status {
    height: 60px;

    li {
      box-sizing: border-box;
      height: 26px;
      line-height: 26px;
      padding-left: 10px;
      padding-right: 10px;
      list-style-type: none;
      margin-top: 17px;
      margin-right: 10px;
      cursor: pointer;
      user-select: none;
      border-radius: 2px;

      &:hover {
        color: #ff4201;
      }
    }

    .active {
      border: 1px solid #ff4201;
      color: #ff4201;
    }
  }
  .count4 {
    position: absolute;
    left: 235px;
    top: 15px;
    z-index: 10;
  }

  .el-tabs__header {
    padding-left: 0;
  }

  .count2 {
    position: absolute;
    left: 308px;
    top: 15px;
    z-index: 10;
  }

  .count3 {
    position: absolute;
    left: 435px;
    top: 15px;
    z-index: 10;
  }

  .red {
    color: red;
  }

  .w-400 {
    width: 400px;
  }

  .w-160 {
    width: 160px;
  }

  .el-dialog {
    min-height: 300px;
  }

  .width-70 {
    width: 70px;
  }

  .black {
    .edit-btn span {
      color: #000000 !important;
    }
  }

  .obt {
    margin-top: 80px;
    margin-right: 60px;
  }
}
</style>