<template>
	<div id="food" class="width-1280">
		<p>
            浙江久加久科技股份有限公司 旗下网站  
            <span>
                网站备案号:<a href="http://beian.miit.gov.cn" target="_blank">{{ webSiteId }}</a>     
            </span> 
            <span v-if="isShowManageId">
                经营性备案号:<a href="http://www.beian.miit.gov.cn/" target="_blank" rel="noopener noreferrer">{{ manageId }}</a>
            </span>
        </p>
		<p>公司地址：<span>{{address}}</span></p>
		<p>客服电话：<span>{{tel}}</span></p>
	</div>
</template>

<script>
	export default{
		data(){
			return{
				address:"浙江省杭州市拱墅区半山街道北秀街386号商源集团",
                tel:"400-888-9979",
                webSiteId:'浙ICP备20009414号',
                manageId:'浙B2-20150256',
                isShowManageId:false
			}
        },
        beforeMount(){
            if(location.host === 'mall.jiujiajiu.vip'){
                this.isShowManageId = false;
                this.webSiteId = '浙ICP备20009414号'
            }else {
                this.isShowManageId = true;
                this.webSiteId = '浙ICP备07504847号'
            }
        }
    }
    /**
     * 1)jiujiajiu.com 显示
        网站备案号：浙ICP备07504847号
        经营性备案号：浙B2-20150256
       2)mall.jiujiajiu.vip 显示
        网站备案号：浙ICP备20009414号
     */
</script>

<style lang="less">
	#food{
		clear:both;
		height: 146px;
		font-size: 12px;
		box-sizing: border-box;
		padding-top: 47px;
		p{
			text-align: center;
			/*margin-bottom: 10px;*/
			line-height: 12px;

			a{
				color:#fff
			}
		}
		p:nth-child(2){
				margin: 15px 0;
			}
	} 
</style>