import {axiosApi, basePath} from '../../api/api.js'
import {Notification, Message} from 'element-ui'
import m from '../../main.js'

const giftEvent = {
    state: {
        fullgiftList: [{
            fullMoney: "",//满足金额
            fullAmount: "",//满足数量
            fullbuyNum: 1,// 换购种类数量默认为1
            // giftList: [{// 赠品组合
            //   subgiftList:[]//赠品列表
            // }]
            giftList: []
        }],
        tableloading: false,
        willaddgiftDataid: 0,//第几个活动规则
        giftListId: 0,//组合索引
        giftlistData: [],//赠品列表
    },
    mutations: {
        //初始化赠品
        initgiftmoneyEvent(state, payload) {
            console.log(payload,'init')
            state.fullgiftList = [];
            state.fullgiftList.push(...payload)
        },
        clearwilladdgiftEvent(state) { //清空赠品列表
            state.fullgiftList = [{
                fullMoney: "",//满足金额
                fullAmount: "",//满足数量
                fullbuyNum: 1,// 换购种类数量
                // giftList: [{// 赠品组合
                //   subgiftList:[]//赠品列表
                // }]
                giftList: []
            }]
            // state.willaddgiftnum = []
        },
        addwilladdgiftEvent(state, payload) {//新增一种满赠条件
            if (state.fullgiftList.length < 5) {
                state.fullgiftList.push({
                    fullMoney: "",//满足金额
                    fullAmount: "",//满足数量
                    fullbuyNum: 1,// 换购种类数量
                    // giftList: [{// 赠品组合
                    //   subgiftList:[]//赠品列表
                    // }]
                    giftList: []
                })
            } else {
                Message.warning('最多添加5条活动规则');
            }
        },
        casewilladdgiftDataEvent(state, payload) {//删除一种满赠条件
            state.fullgiftList.splice(payload, 1);
        },
        deletecombinationEvent(state, payload) {//删除一种赠品组合
            state.fullgiftList[payload.index1].giftList.splice(payload.index2, 1);
        },
        deleteOnewillGiftEvent(state, payload) {//删除单个赠品
            state.fullgiftList[payload.index1].giftList[payload.index2].subgiftList.splice(payload.index3, 1)
        },
    },
    actions: {
        addgiftEvent({state}, payload) {// 添加'赠品'存入对应的'赠品组合'
            console.log(payload, '111');
            let obj = state.fullgiftList[state.willaddgiftDataid];
            if (!obj.giftList) {
                obj.giftList = [];
                obj.giftList[state.giftListId] = {};
                obj.giftList[state.giftListId].subgiftList = [];
                obj.giftList[state.giftListId].subgiftList.push(...payload.willadd)
            } else {
                if (obj.giftList[state.giftListId]) {
                    if (obj.giftList[state.giftListId].subgiftList && obj.giftList[state.giftListId].subgiftList.length >= 0) {
                        obj.giftList[state.giftListId].subgiftList.push(...payload.willadd)
                    }
                } else {
                    obj.giftList[state.giftListId] = {};
                    obj.giftList[state.giftListId].subgiftList = [];
                    obj.giftList[state.giftListId].subgiftList.push(...payload.willadd)
                }
            }
            // console.log(state.fullgiftList[state.willaddgiftDataid], '222222')
            // state.fullgiftList[state.willaddgiftDataid] = obj;
        },
        querygiftlistDataEvent({state}, payload) {//加载赠品列表
            state.tableloading = true;
            if (payload && payload.chooseIndex >= 0) {
                state.willaddgiftDataid = payload.chooseIndex;// 要添加的组合索引
            }
            if (payload && payload.subIndex >= 0) {
                state.giftListId = payload.subIndex;// 要添加的组合索引
            }
            console.log(state.giftListId,payload)
            const api = "/marketing/gift/page"
            let body = {}
            let newbody = Object.assign({
                sellerId: sessionStorage.getItem('traderId')||sessionStorage.getItem('traderId'),
                giftType:0
            }, body, payload.data);

            axiosApi(api, newbody, (res) => {
                state.tableloading = false
                state.giftlistData = res.data.data
            }, payload.currentPage, 10)
        },
    },
    getters: {
        getfullgiftList: state => {
            return state.fullgiftList
        }
    }
}

export default giftEvent
