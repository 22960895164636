const exportTaskList = {
    state:{
        url:null,
        businessType:null,
        taskType:null
    },
    mutations:{
        setTask(state,payload){
            state.url = payload.url;
            state.businessType = payload.businessType;
            state.taskType = payload.taskType;
        }
    }
};

export default exportTaskList;
