<template>
    <!--超团订单-->
    <div id="psOrder">
        <div class="title-tabs bg-f black mb-5">
            <ul class="clearfix">
                <template v-for='(tab,index) in tabs' class="item">
                    <li :type='index' :class="type==index?'active':''" @click='handleTabs'>
                        {{tab}}
                        <div :class="type==index?'under-line':''"></div>
                    </li>
                    <div class="vertical-line"></div>
                </template>
            </ul>
        </div>
        <div class='bg-f'>
            <div class="ml-30 mr-30 second-title clearfix">
                <div v-if="type == 0" class="choose-three font-14 color-6 fl tac mr-10 cursor "
                     :class="first == 0?'actived':''"
                     @click="getStatus(0)">
                    全部订单({{badge.count0}})
                </div>
                <div v-else class="choose-three font-14 color-6 fl tac mr-10 cursor " :class="first == 0?'actived':''"
                     @click="getStatus(0)">
                    全部预约单({{badge.count0}})
                </div>
                <div v-if="type == 0" class="choose-three font-14 color-6 tac fl mr-10 cursor"
                     :class="first == 1?'actived':''" @click="getStatus(1)">
                    待付款({{badge.count1}})
                </div>
                <div v-else class="choose-three font-14 color-6 tac fl mr-10 cursor" :class="first == 1?'actived':''"
                     @click="getStatus(1)">
                    待确认({{badge.count1}})
                </div>
                <div v-if="type == 0" class="choose-three font-14 color-6 tac fl cursor"
                     :class="first == 2?'actived':''" @click="getStatus(2)">
                    部分付款({{badge.count2}})
                </div>
                <div v-else class="choose-three font-14 color-6 tac fl cursor" :class="first == 2?'actived':''"
                     @click="getStatus(2)">
                    已预定({{badge.count2}})
                </div>
                <div class="choose-three font-14 color-6 tac fl cursor" :class="first == 3?'actived':''"
                     @click="getStatus(3)">
                    已完成({{badge.count3}})
                </div>
                <div v-if="type == 1" class="choose-three font-14 color-6 tac fl cursor"
                     :class="first == 4?'actived':''" @click="getStatus(4)">
                    已取消({{badge.count4}})
                </div>
                <div v-else class="choose-three font-14 color-6 tac fl cursor" :class="first == 4?'actived':''"
                     @click="getStatus(4)">
                    已取消({{badge.count4}})
                </div>
            </div>
        </div>
        <div class="content bg-f pl-30 pr-30 pb-50">
            <div class="clearfix search">
                <el-form :inline='true' class="pt-30 fl" :model="form">
                    <el-form-item v-if="type==0" label='桌号'>
                        <el-input v-model.trim="form.tableNo" placeholder="请输入桌号" :maxlength="25"></el-input>
                    </el-form-item>
                    <el-form-item v-if="type==0" label='餐桌类别' class='ml-20'>
                        <el-select v-model="form.tableCategoryId" placeholder="请选择餐桌类别" clearable>
                            <el-option
                                    v-for="item in options"
                                    :key="item.id"
                                    :label="item.categoryName"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label='商品名称'>
                        <el-input v-model.trim="form.skuName" :maxlength="30" placeholder="请输入商品名称"></el-input>
                    </el-form-item>
                    <el-form-item label='客户手机' class='ml-20'>
                        <el-input v-model.trim="form.customerMobile" :maxlength="30"
                                  placeholder="请输入客户手机"></el-input>
                    </el-form-item>
                    <el-form-item label='订单编号'>
                        <el-input v-model.trim="form.sn" :maxlength="30" placeholder="请输入订单编号"></el-input>
                    </el-form-item>
                    <el-form-item :label="type==1 ? '预约时间' : '下单时间'" class="ml-20">
                        <ysDaterangePicker v-model="form.orderDateRange"/>
                    </el-form-item>
                </el-form>
                <el-button class="fl ml-30 mt-30" type='info' @click="queryOrderList">查询</el-button>
            </div>
            <div v-if="type == 0" class='tar clearfix mb-10'>
                <el-button type='text' class="fr" @click="exportOrder">导出</el-button>
            </div>
            <div>
                <el-table :data="groupList" stripe class='table-default font-12'>
                    <el-table-column label='订单编号' prop="sn" width="150">
                    </el-table-column>
                    <el-table-column :label="type==1 ? '预约到店时间' : '下单时间'">
                        <template slot-scope="scope">
                            <span v-if="type==0">{{ scope.row.gmtCreate | time}}</span>
                            <span v-else>{{ scope.row.appointmentTime | time}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column v-if="type==0" label='桌号' prop="tableNo" width="60">
                    </el-table-column>
                    <el-table-column label='商品数量' prop="totalGoodsCount" width="60">
                    </el-table-column>
                    <el-table-column label='客户信息'>
                        <template slot-scope="scope">
                            <div v-if="type==1">
                                <p>{{scope.row.customerNickName}}</p>
                                <p>{{ scope.row.customerMobile }}</p>
                            </div>
                            <div v-else>
                                <div v-if="scope.row.customerNickName">
                                    <p>{{scope.row.customerNickName}}</p>
                                    <p>{{ scope.row.customerMobile }}</p>
                                </div>
                                <div v-else>
                                    <p>（服务员代下单）</p>
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column :label=" type==1 ? '预约状态' : '订单状态'" width="60">
                        <template slot-scope="scope">
                            <div v-if="type == 1">
                                <span v-if="scope.row.orderStatus == 1">待确认</span>
                                <span v-if="scope.row.orderStatus == 2">已预定</span>
                                <span v-if="scope.row.orderStatus == 3">就餐中</span>
                                <span v-if="scope.row.orderStatus == 4">已取消</span>
                                <span v-if="scope.row.orderStatus == 5">已完成</span>
                            </div>
                            <div v-else>
                                <span v-if="scope.row.orderStatus == 1">待付款</span>
                                <span v-if="scope.row.orderStatus == 2">部分支付</span>
                                <span v-if="scope.row.orderStatus == 3">已支付</span>
                                <span v-if="scope.row.orderStatus == 4">已完成</span>
                                <span v-if="scope.row.orderStatus == 5">已取消</span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label='酒水单金额'>
                        <template slot-scope="scope">
                            <span>￥{{scope.row.wineAmount | fmoney}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label='订单金额'>
                        <template slot-scope="scope">
                            <span>￥{{scope.row.totalAmount | fmoney}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label='操作' width="80">
                        <template slot-scope="scope">
                            <el-button
                                    v-if="scope.row.tableNo&&type == 0 && (scope.row.orderStatus ==3||scope.row.orderStatus ==2||scope.row.orderStatus ==1)&&isDisable"
                                    type='text'
                                    class="ml-10"
                                    @click="affirmOrder0(scope)">确认收款
                            </el-button>
                            <el-button v-if="type == 0&& scope.row.orderStatus ==1&&isDisable" type='text'
                                       @click="dialogShow(scope)">订单改价
                            </el-button>
                            <el-button v-if="type == 0" type='text' @click="toDetails(0,scope)">订单详情</el-button>
                            <el-button v-if="type == 0&&scope.row.orderStatus == 1&&isDisable" type='text'
                                       @click="cancelOrder0(scope)">
                                取消订单
                            </el-button>
                            <el-button
                                    v-if="type == 1&&(scope.row.orderStatus == 1)&&isDisable"
                                    type='text' class="ml-10"
                                    @click="cancelOrder1(scope)">
                                取消预约
                            </el-button>
                            <el-button v-if="type == 1" type='text' @click="toDetails(1,scope)">预约详情</el-button>
                            <el-button v-if="type == 1 && scope.row.orderStatus ==1&&isDisable" type='text'
                                       @click="affirmOrder1(scope)">确认预约
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination class="tar" v-if="groupList.length>0" :current-page="currentPage" :page-size='10'
                               :total='total' layout="prev , pager, next, jumper" @current-change="handleCurrentChange">
                    <!-- 分页组件 -->
                </el-pagination>
                <!--订单改价-->
                <el-dialog class='cancelOrderDialog' title='订单改价' :visible.sync='updateOrderVisible'>
                    <el-form class="pl-30 pr-30" :model="form1" ref="cancelOrderForm" :rules="rules2">
                        <el-form-item label='改价金额' prop='price'>
                            <el-input v-model.trim="form1.price" placeholder="请输入改价金额" :maxlength="25"></el-input>
                        </el-form-item>
                        <el-form-item class="mt-10" label="" prop='remark'>
                            <el-input :maxlength="100" type='textarea' placeholder='请输入改价理由...'
                                      :autosize="{ minRows: 3 ,maxRows: 10}"
                                      v-model.trim='form1.remark'></el-input>
                        </el-form-item>
                    </el-form>
                    <div slot='footer' class="dialog-footer">
                        <el-button type='danger' @click="handleCommit()">确 定</el-button>
                        <el-button @click="updateOrderVisible = false">取 消</el-button>
                    </div>
                </el-dialog>

                <!--取消订单弹出框-->
                <el-dialog class='cancelOrderDialog' :close-on-click-modal='false' @close="closeOrderDialog"
                           :title='type==1?"取消预约理由":"取消订单理由"' :visible.sync='cancelOrderVisible'>
                    <el-form class="pl-30 pr-30" :model="form2" ref="cancelOrderForm3" :rules="rules">
                        <el-form-item label="" prop='cancelReason'>
                            <el-input type='textarea' :maxlength="100"
                                      :placeholder='type==1?"请输入取消预约理由...":"请输入取消订单的理由..."'
                                      :autosize="{ minRows: 3 ,maxRows: 5}"
                                      v-model.trim='form2.cancelReason'></el-input>
                        </el-form-item>
                    </el-form>
                    <div slot='footer' class="dialog-footer">
                        <el-button type='danger' @click="cancelOrderCommit()">提
                            交
                        </el-button>
                        <el-button @click="cancelOrderVisible = false">取 消</el-button>
                    </div>
                </el-dialog>
            </div>
        </div>
        <!--筛选条件导出订单-->
        <form ref="downClientForm2" action='/sunrise-gateway/export/catering/platform/hotelOrder' style="display: none">
            <input type="hidden" name="modelName" v-model="a">
            <input type="hidden" name="fileName" v-model="b">
            <!--订单编号-->
            <input v-if="form3.sn" type="hidden" name="sn" v-model="form3.sn">
            <!--订单状态-->
            <input v-if="form3.orderStatus" type="hidden" name="orderStatus" v-model="form3.orderStatus">
            <!--顾客名称-->
            <input v-if="form3.customerNickName" type="hidden" name="customerNickName" v-model="form3.customerNickName">
            <!--顾客手机-->
            <input v-if="form3.customerMobile" type="hidden" name="customerMobile" v-model="form3.customerMobile">
            <!--商品名称-->
            <input v-if="form3.skuName" type="hidden" name="skuName" v-model="form3.skuName">
            <!--桌号-->
            <input v-if="form3.tableNo" type="hidden" name="tableNo" v-model="form3.tableNo">
            <!--类型-->
            <input v-if="form3.tableCategoryId" type="hidden" name="tableCategoryId" v-model="form3.tableCategoryId">
            <!--开始时间-->
            <input v-if="form3.gmtCreateStart" type="hidden" name="gmtCreateStart" v-model="form3.gmtCreateStart">
            <!--结束时间-->
            <input v-if="form3.gmtCreateEnd" type="hidden" name="gmtCreateEnd" v-model="form3.gmtCreateEnd">
        </form>
    </div>
</template>

<script>
    import {axiosApi, basePath} from "../../api/api"

    export default {
        data() {
            return {
                rules2: {
                    price: [{
                        required: true,
                        message: '请输入改价金额',
                        pattern: /^[0-9]{1,7}([.]{1}[0-9]{0,2}){0,1}$/
                    }],
                    remark: [{
                        required: true,
                        message: '改价理由不能为空'
                    }, {
                        min: 4,
                        max: 100,
                        message: '改价理由为4-100位字符'
                    }]
                },
                updateOrderVisible: false,
                type: '0',
                tabs: ['订单', '预约单'],
                cancelOrderVisible: false, //取消订单弹框
                form: {
                    sn: null,
                    customerNickName: null, //订单编号
                    customerMobile: null, //客户手机
                    skuName: null, //商品名称
                    orderDateRange: [], //
                    orderStatus: null,
                    tableNo: null,
                    tableCategoryId: null,
                },
                form3: {
                    sn: null,
                    customerNickName: null, //订单编号
                    customerMobile: null, //客户手机
                    skuName: null, //商品名称
                    orderStatus: null,
                    tableNo: null,
                    tableCategoryId: null,
                    gmtCreateStart: null,
                    gmtCreateEnd: null,
                },
                a: 'catering.platform.hotelOrder',
                b: '订单列表.xls',
                form1: {
                    price: null,
                    remark: null,
                    sn: null,
                    preTotalAmount: null,
                },
                form2: {
                    id: null,
                    sn: null,
                    cancelReason: null,
                },
                rules: {
                    cancelReason: [{
                        required: true,
                        message: '不能为空',
                    }, {
                        max: 100,
                        message: '不能超过100个字！'
                    }]
                },
                loading: false,
                currentPage: 1,
                total: null,
                isDisable: true,
                badge: {
                    count0: 0,
                    count1: 0,
                    count2: 0,
                    count3: 0,
                    count4: 0,
                },
                first: 0,
                options: [],
                groupList: []
            }
        },
        watch: {
            updateOrderVisible(val) {
                if (!val) {
                    this.form1.price = null;
                    this.form1.cancelReason = null;
                    this.form1.sn = null;
                    this.form1.preTotalAmount = null;
                    this.$refs.cancelOrderForm.resetFields()
                }
            }
        },
        mounted() {
            this.$nextTick(() => {
                this.type = this.$route.query.type ? this.$route.query.type : 0;
                this.first = this.$route.query.first ? this.$route.query.first : 0;
                this.setStatus(this.first);
                this.queryOrderList();
                this.anonOrderCount();
                this.getClass();
                this.getIsHotel();
            })
        },
        methods: {
            anonOrderCount() { //订单统计(角标)
                let url = this.type == 1 ? '/catering/platform/hotelOrderAppointment/selectCount' : '/catering/platform/hotelOrder/count';
                axiosApi(url, {}, (res) => {
                    if (this.type == 0) {
                        this.badge.count0 = res.data.data.allOrder;
                        this.badge.count1 = res.data.data.unPayOrderCount;
                        this.badge.count2 = res.data.data.partPayOrderCount;
                        this.badge.count3 = res.data.data.completedOrderCount;
                        this.badge.count4 = res.data.data.cancelOrderCount;
                    } else {
                        this.badge.count0 = res.data.data.unAuditCount + res.data.data.cancelCount + res.data.data.exchangedCount + res.data.data.appointedCount;
                        this.badge.count1 = res.data.data.unAuditCount;
                        this.badge.count2 = res.data.data.appointedCount;
                        this.badge.count3 = res.data.data.exchangedCount;
                        this.badge.count4 = res.data.data.cancelCount;
                    }
                }, 1, 10, (res) => {

                })
            },
            closeOrderDialog() {
                this.form2.sn = null;
                this.form2.id = null;
                this.form2.cancelReason = null;
                this.$refs.cancelOrderForm3.resetFields()
            },
            cancelOrder0(scope) {
                this.form2.id = scope.row.id;
                this.cancelOrderVisible = true;
            },
            cancelOrder1(scope) {
                this.form2.sn = scope.row.sn;
                this.cancelOrderVisible = true;
            },
            //保存取消订单理由
            cancelOrderCommit() {
                this.$refs.cancelOrderForm3.validate((valid) => {
                    if (valid) {
                        this.cancelOrderVisible = false;
                        let url;
                        let body;
                        if (this.type == 0) {
                            url = '/catering/platform/order/cancel';
                            body = {
                                id: this.form2.id,
                                cancelReason: this.form2.cancelReason,
                            }
                        } else {
                            url = '/catering/platform/orderAppointmentStatus/update';
                            body = {
                                sn: this.form2.sn,
                                orderStatus: 4,
                                cancelReason: this.form2.cancelReason,
                            }
                        }


                        axiosApi(url, body, (res) => {
                            this.$message({
                                type: 'success',
                                duration: 1000,
                                message: "取消成功!",
                            });
                            this.anonOrderCount();
                            this.queryOrderList();
                        }, this.currentPage, 10, (res) => {
                            this.$message.error(res.data.message)
                        });
                    } else {
                        return false
                    }
                })
            },
            //获取是 酒店 pos用户 再去拉列表 然后再过滤
            getIsHotel() {
                axiosApi('/catering/platform/hotel/selectDetail', {}, (res) => {
                    if (res.data.data) {
                        if (res.data.data.status == 1) {
                            this.isDisable = true;
                        } else {
                            this.isDisable = false;
                        }
                    }
                }, this.currentPage, 10)
            },
            exportOrder() {
                if (this.total == 0) {
                    this.$message({
                        type: 'error',
                        message: '暂无数据可导出',
                        duration: 1000
                    })
                    return false
                }
                this.$refs.downClientForm2.submit();
            },
            handleCurrentChange(val) { //分页
                this.currentPage = val;
                this.queryOrderList();
                this.anonOrderCount()
            },
            handleTabs(e) {
                this.first = 0;
                if (e.target.attributes.type) {
                    this.type = e.target.attributes.type.value;
                    this.typeName = e.target.attributes.type.ownerElement.innerText.trim();
                }
                this.setStatus(this.first);
                this.anonOrderCount();
                this.queryOrderList(true)
            },
            queryOrderList(bool) {
                this.form3 = this.form;
                this.form3.gmtCreateStart = this.form.orderDateRange[0] ? this.form.orderDateRange[0].getTime() : null;
                this.form3.gmtCreateEnd = this.form.orderDateRange[1] ? this.form.orderDateRange[1].getTime() : null;

                if (bool && this.currentPage != 1) {
                    this.currentPage = 1;
                    return
                }
                this.loading = true;
                let url = this.type == 1 ? '/catering/platform/hotelOrderAppointment/selectPage' : '/catering/platform/hotelOrder/selectPage';
                let body = this.type == 0 ? {
                    orderStatus: this.form.orderStatus ? this.form.orderStatus : null,
                    sn: this.form.sn ? this.form.sn : null,
                    customerNickName: this.form.customerNickName ? this.form.customerNickName : null,
                    customerMobile: this.form.customerMobile ? this.form.customerMobile : null,
                    skuName: this.form.skuName ? this.form.skuName : null,
                    tableNo: this.form.tableNo ? this.form.tableNo : null,
                    tableCategoryId: this.form.tableCategoryId ? this.form.tableCategoryId : null,
                    gmtCreateStart: this.form.orderDateRange[0] ? this.form.orderDateRange[0].getTime() : null,
                    gmtCreateEnd: this.form.orderDateRange[1] ? this.form.orderDateRange[1].getTime() : null,
                } : {
                    orderStatus: this.form.orderStatus,
                    snLike: this.form.sn,
                    customerNickNameLike: this.form.customerNickName,
                    customerMobileLike: this.form.customerMobile,
                    skuName: this.form.skuName,
                    startAppointmentTime: this.form.orderDateRange[0] ? this.form.orderDateRange[0].getTime() : null,
                    endAppointmentTime: this.form.orderDateRange[1] ? this.form.orderDateRange[1].getTime() : null,
                }


                axiosApi(url, body,
                    (res) => {
                        this.loading = false;
                        console.log(res, 'res')
                        this.groupList = res.data.data.elements;
                        this.total = res.data.data.totalCount;
                    }, this.currentPage, 10, (res) => {
                        this.loading = false;
                        this.$message({
                            type: 'error',
                            message: res.data.message,
                            duration: 1000
                        })
                    }
                )
            },
            dialogShow(scope) {
                this.updateOrderVisible = true;
                this.form1.sn = scope.row.sn;
                this.form1.preTotalAmount = scope.row.totalAmount;
            },
            getClass() {
                axiosApi('/catering/platform/tableCategory/selectList', {}, (res) => {
                    this.options = res.data.data;
                }, this.currentPage, 10)
            },
            handleCommit() {
                this.$refs.cancelOrderForm.validate((valid) => {
                    if (valid) {
                        this.updateOrderVisible = false
                        axiosApi('/catering/platform/order/changePrice', {
                            preTotalAmount: this.form1.preTotalAmount,
                            totalAmount: this.form1.price,
                            remark: this.form1.remark,
                            sn: this.form1.sn,
                        }, (res) => {
                            this.$message({
                                type: 'success',
                                message: '改价成功',
                                duration: 1000
                            })
                            this.queryOrderList();
                            this.anonOrderCount();
                        }, 1, 10, (res) => {
                            this.$message({
                                type: 'error',
                                message: res.data.message,
                                duration: 1000
                            })
                        })
                    } else {
                        return false
                    }
                })
            },
            affirmOrder0(scope) {
                this.$confirm("确定收款吗？是否继续?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                }).then(() => {
                    axiosApi('/catering/platform/order/confirm', {
                        id: scope.row.id,
                    }, (res) => {
                        this.$message({
                            type: 'success',
                            duration: 1000,
                            message: "确认成功!",
                        });
                        this.queryOrderList();
                        this.anonOrderCount();
                    }, this.currentPage, 10, (res) => {
                        this.$message.error(res.data.message)
                    })
                }).catch(() => {
                    this.$message({
                        message: "已取消确认",
                    });
                });
            },
            affirmOrder1(scope) {
                this.$confirm("将确认该订单, 是否继续?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                }).then(() => {
                    axiosApi('/catering/platform/orderAppointmentStatus/update', {
                        sn: scope.row.sn,
                        orderStatus: 2,
                    }, (res) => {
                        this.$message({
                            type: 'success',
                            duration: 1000,
                            message: "确认成功!",
                        });
                        this.queryOrderList();
                        this.anonOrderCount();
                    }, this.currentPage, 10, (res) => {
                        this.$message.error(res.data.message)
                    })
                }).catch(() => {
                    this.$message({
                        message: "已取消确认",
                    });
                });
            },
            toDetails(type, scope) {
                this.$router.push({
                    path: 'psOrderDetails',
                    query: {
                        first: this.first,
                        type: type,
                        id: scope.row.id,
                        sn: scope.row.sn,
                        isSubCompany: 0,
                        isDisable: this.isDisable
                    }
                });
            },
            //tab切换new
            getStatus(e) {
                this.first = e;
                this.anonOrderCount()
                this.setStatus(e);
                this.queryOrderList(true)
            },

            setStatus(e) {
                if (e == '0') { //全部订单
                    this.form.orderStatus = null;
                } else { //待付款
                    if (this.type == 0) {
                        switch (e) {
                            case 1:
                                this.form.orderStatus = 1;
                                break;
                            case 2:
                                this.form.orderStatus = 2;
                                break;
                            case 3:
                                this.form.orderStatus = 4;
                                break;
                            case 4:
                                this.form.orderStatus = 5;
                                break;
                        }
                    } else {
                        switch (e) {
                            case 1:
                                this.form.orderStatus = 1;
                                break;
                            case 2:
                                this.form.orderStatus = 2;
                                break;
                            case 3:
                                this.form.orderStatus = 5;
                                break;
                            case 4:
                                this.form.orderStatus = 4;
                                break;
                        }
                    }
                }
            }
        }
    }
</script>

<style lang="less">
    #psOrder {

        .table-default .el-button {
            width: auto;
        }

        .content {
            .el-date-editor--daterange.el-input {
                width: 240px;
            }

            .el-form--inline {
                width: 650px;

                .el-form-item__label {
                    font-size: 12px;
                    width: 60px;
                }
            }

            .order-datatimerange {
                width: 240px;
            }


            .checkedAll {
                position: relative;
                top: 30px;
            }

            .el-checkbox {
                width: 70px;
                margin-left: 30px;

                .el-checkbox__input {
                    height: 20px;
                }
            }

            .el-checkbox__inner {
                width: 20px;
                height: 20px;
            }

            .el-checkbox__inner::after {
                top: 2px;
            }

            .table-item {
                .bl {
                    border-left: 1px solid #ddd;
                }

                border: 1px solid #dddddd;
                font-size: 14px;
                border-bottom: none;

                .table-item-content .pbl-20 {
                    padding: 0px 0px 20px 20px;
                }

                .table-item-top {
                    padding: 10px 0;

                    .table-item-top-checkbox {
                        margin-left: 14px;
                    }
                }

                img {
                    width: 80px;
                    height: 80px;
                }
            }

            .w100 {
                width: 100px;
            }

            .table-item-default {
                height: 530px;
                padding-top: 120px;
            }

            .el-dialog {
                min-height: 300px;

                .el-form-item__label {
                    width: 140px;
                }
            }

            .sendGoods .el-form-item__error {
                margin-left: 140px;
            }
        }

        .actived {
            border-radius: 2px;
            border: 1px solid #FF4201;
            color: #FF4201;
        }

        .second-title {
            height: 60px;
            padding: 17px 0px 17px 0px;
            box-sizing: border-box;
            border-bottom: 1px solid #EEEEEE;
        }

        .choose-three {
            width: 114px;
            height: 26px;
            line-height: 26px;
        }
    }
</style>