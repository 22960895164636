<template>
    <el-table :data="tableDate" tooltip-effect="dark"  class="mt-30 ml-20 table-default" ref="multipleTable">
        <el-table-column label="收货人" prop='linkman'  >
        </el-table-column>
        <el-table-column label="联系电话" prop='tel' >
        </el-table-column>
        <el-table-column label="所在地区"  >
            <template slot-scope="scope">
                {{ scope.row.provinceName }}{{ scope.row.cityName }}{{ scope.row.regionName }}{{ scope.row.streetName }}
            </template>
        </el-table-column>
        <el-table-column label="详细地址" prop='attr'>
        </el-table-column>
        <el-table-column label="状态" prop='state' >
            <template slot-scope="scope">
                <div v-if="scope.row.state == 1">
                    待审核
                </div>
                <div v-else>
                    正常使用
                </div>
            </template>
        </el-table-column>
    </el-table>
</template>

<script>
    export default {
        name: "addressTable",
        props:{
            tableDate:{//地址类型1营业地址2仓库地址3联系地址
                type:Array,
                default:[]
            }
        },
        filters:{
            addressType(val){
                if(val == 4){
                    return '营业地址';
                }else if(val == 3){
                    return '收货地址';
                }else if(val == 5){
                    return '联系地址';
                } else if (val == 2) {
                    return '发票地址';
                }
                else if (val == 1) {
                    return '发货地址';
                }
            }
        }
    }
</script>

<style scoped>

</style>