<template>
	<div id="department">
		<div class="title-top bg-f">
			部门管理
			<el-button @click="addPar" v-if="showButton('ADD',100064)" class="fr mr-30">新增部门</el-button>
		</div>
		<div class="main pt-30 pl-30 pr-30 pb-50 bg-f mt-5">
			<div class="clearfix width-970">
				<div class="main-l mr-20 fl">
					<el-tree :data="depList" :props="defaultProps" default-expand-all :expand-on-click-node="false" @node-click="handleNodeClick" :render-content="renderContent"></el-tree>
				</div>
				<div class="main-r fl" v-loading="Loading">
					<el-table :data="elements" style="width: 100%;">
					    <el-table-column  label="用户名" min-width="120">
					    	<template slot-scope="scope">
									<el-tag effect="dark" type="danger" size="mini" v-if="scope.row.isDepartmentLeader==1">负责人</el-tag>
					    		<span>{{scope.row.loginName}}</span>
					    	</template>
					    </el-table-column>
					    <el-table-column label="员工姓名" min-width="90">
					    	<template slot-scope="scope">
					    		<span>{{scope.row.employeeName}}</span>
					    	</template>
					    </el-table-column>
					    <el-table-column label="所属部门" min-width="200">
					    	<template slot-scope="scope">
					    		<span>{{scope.row.departmentName}}</span>
					    	</template>
					    </el-table-column>
							 <el-table-column label="是否默认该部门">
					    	<template slot-scope="scope">
					    		<span>{{scope.row.isDefaultDepartment?'是':'否'}}</span>
					    	</template>
					    </el-table-column>
					    <el-table-column label="职位" min-width="100">
					    	<template slot-scope="scope">
					    		<span>{{scope.row.position}}</span>
					    	</template>
					    </el-table-column>
					    <el-table-column label="手机" min-width="120">
					    	<template slot-scope="scope">
					    		<span>{{scope.row.mobile}}</span>
					    	</template>
					    </el-table-column>
					    <el-table-column label="状态" min-width="80">
					        <template slot-scope="scope">
		                <span>{{scope.row.isDisable=="0"?'启用':'停用'}}</span>
		              </template>
					    </el-table-column>
							<el-table-column label="操作">
					    	<template slot-scope="{row}">
					    		<el-link :underline="false" type="primary" @click="toUrl(row)">编辑</el-link>
					    	</template>
					    </el-table-column>
					</el-table>
					<el-pagination v-if="elements.length>0"
						class="tar mt-20"
		                :current-page = "currentPage"
		                :page-size = '10'
		                :total = 'totalCount'
		                layout = "prev , pager, next, jumper"
		                @current-change='handleCurrentChange'
		                >                      <!-- 分页组件 -->
		            </el-pagination>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import { axiosApi,basePath } from '~/api/api';
	export default{
		data(){ 
			return{
				Loading:false,
				id: 'XX',
				depId:'',
				elements:[],
				depList:[],
				defaultProps: {
					children: 'sub',
					label: 'text'
				},
				totalCount:0,
				currentPage:1
			}
		},
		methods: {
			handleCurrentChange(val) {
	            this.currentPage = val;
	            this.getEmpList();
//	            console.log(`当前页: ${val}`);
	        },
			handleDetails(data) {
//				console.log(data);
			},
			handleNodeClick(data) {
//				console.log(data);
			},
			renderContent(h, { node, data, store }) {
					console.log(data,'istop')
			    return (
			        <span>
			            <span>
			              	<span class="el-tree-bg" on-click={ () => this.search(data) }>{node.label}</span>
			            </span>
			            <span class={data.isTop?'hide':'iconBtn hide color-9'}>
			           	 	{this.showButton("EDIT",100064)?<i class="iconfont icon-editor pl-20" on-click={ () => this.edit(store, data) }></i>:null}
				            {this.showButton("DEL",100064)?<i class="iconfont icon-delete ml-5" on-click={ () => this.remove(store, data) }></i>:null}
			            </span>
			    </span>);
			},
			//点击部门切换右侧信息
			search(data){
				this.depId = data.id;
				let tree = document.getElementsByClassName('el-tree-node__content');
				for(var i=0;i<tree.length;i++){
					tree[i].onclick = function(){
						for(var i=0;i<tree.length;i++){
							tree[i].className = "el-tree-node__content";
						}
						this.className = "el-tree-node__content tree-active";
					}
				}
				this.currentPage = 1
				this.getEmpList();
			},
			edit(store, data){
				this.depId = data.id;
				this.$router.push(basePath+"/dep/addPartment/"+this.depId);
			},
			//部门删除
			remove(store, data) {
				this.depId = data.id;
		        this.$confirm('是否确认删除部门「' +data.text+ '」？', '提示信息', {
		          confirmButtonText: '确定',
		          cancelButtonText: '取消',
		          type: 'warning'
		        }).then(() => {
		        	const api = "/foundation/department/delete";
		        	const body = {id:this.depId};
		        	axiosApi(api, body,(res)=>{
				        this.$message({
				            type: 'success',
				            message: '删除成功!',
				            duration: 1000
				        });
				        this.getDepList();
				    },this.currentPage,10,(res)=>{
				    	this.$message({
			            	type: 'info',
			            	message: res.data.message,
			            	duration: 3000
			          	});
				    });
		        }).catch(() => {
		          this.$message({
		            type: 'info',
		            message: '已取消删除'
		          }); 
		        })
		    },
			addPar(){
				this.$router.push(basePath+"/dep/addPartment/" + this.id)
			},
			//部门列表查询
			getDepList(){
				const api = "/foundation/department/displayTree";
				axiosApi(api, {traderId:sessionStorage.traderId,applicationType: 2},(res)=>{
					this.depList = res.data.data ? res.data.data : [];
	       			//console.log(this.depList)
				},1,10);
			},
			//查询员工列表
			getEmpList(){
				this.Loading = true;
				const api = "/foundation/employee/selectAll";
				const body = {traderId:sessionStorage.traderId,applicationType: 2,departmentId:this.depId, isDeleted: 0};
				axiosApi(api, body,this.emplist,this.currentPage,10)
			},
			emplist(res){
//				console.log('获取员工列表:',res)
				let emplist = res.data.data;
				this.totalCount = emplist.totalCount; 
				this.elements = emplist.elements;
				this.Loading = false;
//	       		console.log(this.elements)
			},
			toUrl(row){
				this.$router.push({path:basePath+'/dep/employeeCustomer',query:{id:row.id,departmentId:row.departmentId}})
			},
		},
		
		activated(){
			this.$nextTick(function(){
				this.getDepList();
				this.getEmpList();
			})
		}
	}
</script>
<style lang="less">
	#department{
		.title-top{
			.el-button{
				margin-top: 14px;
			}
		}
		.width-970{
			width: 100%;
		}
		.main-l{
			width: 15%;
			overflow-x: auto;
			border: 1px solid #DDDDDD;
			box-sizing: border-box;
		}
		.main-r{
			width: 82%;
			box-sizing: border-box;
		}
		.hide{
			display: none;
		}
		.el-table table .cell{
			text-align: center;
		}
		.el-tree{
			border: 0;
			min-height: 500px;
			float: left;
		}
		.el-tree-node__content {
			min-width: 200px;
		  padding-right: 10px;
			.iconBtn{
				display: inline-block;
				i:hover{
					color: #FF4201;
				}
			}
		}
		.el-tree-bg{
			padding-left: 25px;
			margin-left: -3px;
			background: url(../../../assets/icon-1.png) no-repeat 0 40%;
		}
		.el-tree-node__content:hover{
			color: #FF4201;
			
		}
		.tree-active{
			color: #20a0ff;
			background: #F4F5F9;
		}
		.el-tree-node{
			white-space: nowrap;
		}
	}
</style>