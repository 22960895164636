<template>
  <!--久集人员管理-->
  <div id="perManagement">
    <!-- 图片预览 -->
    <imgview
      :visible="imgsvisible"
      :imgs="showimgs"
      :startindex="startindex"
      @close="imgsvisible=false"
    ></imgview>
    <div class="title-tabs bg-f mb-5">
      <ul class="clearfix">
        <div v-for="(tab,index) in dataTabs" class="item" :key="index">
          <li :type="index" :class="type==index?'active':''" @click="handleTabs" >
            {{tab}}
            <div :class="type==index?'under-line':''"></div>
          </li>
          <div class="vertical-line"></div>
        </div>
      </ul>
      <el-button @click="addSuperCSwitch">新增销售员</el-button>
    </div>
    <div class="content bg-f pr-30 pl-30">
      <div class="clearfix search">
        <el-form :inline="true" class="pt-30 fl" :model="Form" ref="searchForm">
          <el-form-item label="加入时间">
            <!--<el-date-picker v-model="Form.addDateRange" class="order-datatimerange" type="daterange" placeholder="选择时间范围" :picker-options="pickerOptions">
            </el-date-picker>-->
            <ys-daterange-picker v-model="Form.addDateRange" :endDisabledDate="new Date()" />
          </el-form-item>
          <el-form-item
            class="ml-20"
            label="久集账号"
            :rules="[{pattern:/^[1]\d{10}$/,message:'请输入正确手机号',trigger:'blur'}]"
            prop="cNum"
          >
            <el-input :maxlength="11" type="number"  v-model.trim="Form.cNum" placeholder="请输入久集账号"></el-input>
          </el-form-item>
        </el-form>
        <el-button class="fl ml-30 mt-30" type="info" @click="search" icon="el-icon-search">搜 索</el-button>
      </div>
      <div class="pb-30" v-loading="Loading">
        <el-upload
          class="dib fr"
          action="/sunrise-gateway/asyncImport?validateGroupName=superc.supercStore.validateSocietyStore&importGroupName=superc.supercStore.importSocietyStore"
          :show-file-list="false"
          :on-success="handUpSuccess"
          :before-upload="handUpBefore"
          :on-error="handUpError"
          accept="application/vnd.ms-excel"
        >
          <el-button type="text" :loading="isLoading">批量导入</el-button>
        </el-upload>
        <a
          style="color:#20a0ff;"
          class="fr"
          href="https://sunrise-cbs.oss-cn-hangzhou.aliyuncs.com/template/jiujiajiu/superc/%E5%AF%BC%E5%85%A5%E7%A4%BE%E4%BC%9A%E4%B9%85%E9%9B%86%E9%94%80%E5%94%AE%E5%91%98%E6%A8%A1%E6%9D%BF.xls"
        >
          <el-button type="text">下载导入模板</el-button>
        </a>
		<el-button type="text" class="fr" v-if="isHasError" @click="exportErrorExcel">下载异常数据</el-button>
        <el-button type="text" class="fr" @click="exportExcel">全部导出</el-button>
          <el-button type="text" class="fr ml-20" @click="exportExcel2">上下级关系导出</el-button>
        <el-table :data="elements" style="width: 100%;">
          <el-table-column label="账号" min-width="100" align="center">
            <template slot-scope="scope">
              <span>{{scope.row.loginName}}</span>
            </template>
          </el-table-column>
          <el-table-column label="销售员名" min-width="80" align="center">
            <template slot-scope="scope">
              <span>{{scope.row.storeName}}</span>
            </template>
          </el-table-column>
          <el-table-column label="交易额(元)" min-width="100" align="center">
            <template slot-scope="scope">
              <span>￥{{scope.row.orderAllMoney | fmoney}}</span>
            </template>
          </el-table-column>
          <el-table-column label="订单(笔)" min-width="80" align="center">
            <template slot-scope="scope">
              <span>{{scope.row.orderCount}}</span>
            </template>
          </el-table-column>
          <el-table-column label="状态" min-width="100" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.state == 1">待审核</span>
              <span v-if="scope.row.state == 2">已通过</span>
              <span v-if="scope.row.state == 3">已驳回</span>
              <span v-if="scope.row.state == 4">已禁用</span>
              <span v-if="scope.row.state == -1" :style="{color : '#ff4201'}">已删除</span>
            </template>
          </el-table-column>
          <el-table-column label="推荐人" min-width="120" align="center">
            <template slot-scope="scope">
              <span>{{scope.row.referrerName ? scope.row.referrerName : '-'}}{{scope.row.referrerTel ? `/${scope.row.referrerTel}` : ''}}</span>
            </template>
          </el-table-column>
          <!--<el-table-column label="已缴纳保证金" width="100">
				        <template slot-scope="scope">
	                       <el-button type="text" @click="lookBondRecord(scope.row)">￥{{scope.row.bondMoney | fmoney}}</el-button>
	                    </template>
          </el-table-column>-->
          <!--<el-table-column label="服务费（元/年）" width="100">
				        <template slot-scope="scope">
	                       <span>￥{{scope.row.serviceMoney||0 | fmoney}}</span>
	                    </template>
				    </el-table-column>
				    <el-table-column label="服务时间" width="150">
				        <template slot-scope="scope">
	                       <span v-if="scope.row.serviceStartTime">{{timeFormat(scope.row.serviceStartTime)}}至{{timeFormat(scope.row.serviceEndTime)}}</span>
	                       <span v-else>-</span>
	                    </template>
          </el-table-column>-->
          <el-table-column label="支付凭证" min-width="100" prop="bondImg" align="center">
            <template slot-scope="scope">
              <el-button type="text" class="w60" @click="lookBondCertify(scope.row.bondImg)">查看</el-button>
            </template>
          </el-table-column>
          <el-table-column label="操作" min-width="260" align="center">
            <template slot-scope="scope">
              <span>
                <el-button type="text" @click="goToDetail(scope.row)">查看详情</el-button>
              </span>
              <span v-if="scope.row.state == 2">
                <el-button type="text" @click="handleDis(scope.row)">禁用</el-button>
                <el-button type="text" @click="handleEditBond(scope.row)">修改保证金</el-button>
              </span>
              <span v-if="scope.row.state == 1">
                <el-button type="text" @click="handlePass(scope.row)">通过</el-button>
                <el-button type="text" @click="handleReject(scope.row)">驳回</el-button>
              </span>
              <span v-if="scope.row.state == 3">
                <el-button type="text" @click="LookReason(scope.row)">查看驳回理由</el-button>
              </span>
              <span v-if="scope.row.state == 4">
                <el-button type="text" @click="handleEnable(scope.row)">启用</el-button>
              </span>
              <!-- 删除后 查看操作人 -->
              <span v-if="scope.row.state == -1">
                <el-button @click="handleOperator(scope.row)" type="text">查看</el-button>
                <el-button @click="handleWithdraw(scope.row)" type="text">撤回</el-button>
              </span>
              <span v-if="scope.row.state != -1">
                <el-button @click="handleDelete(scope.row)" type="text">删除</el-button>
              </span>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          v-if="elements.length>0"
          class="tar mt-20"
          :current-page="currentPage"
          :page-size="10"
          :total="totalCount"
          layout="prev , pager, next, jumper"
          @current-change="handleCurrentChange"
        >
          <!-- 分页组件 -->
        </el-pagination>
      </div>
      <!--修改保证金弹出框-->
      <el-dialog class="bond" title="修改保证金" :visible.sync="editBond" @close="closeBond">
        <el-form ref="bondForm" class="pr-30" :model="bondForm" :rules="rules1" label-width="100px">
          <el-form-item label="保证金" prop="money">
            <el-input placeholder="请输入保证金" v-model.trim="bondForm.money" :maxlength="6"></el-input>
          </el-form-item>
          <el-form-item class="mb-5" label="修改理由" prop="reason">
            <el-input
              type="textarea"
              :autosize="{ minRows: 3 ,maxRows: 10}"
              :maxlength="100"
              placeholder="请输入修改理由.."
              v-model.trim="bondForm.reason"
            ></el-input>
          </el-form-item>
          <br />
          <span class="color-9 font-12 gaze">修改理由会以消息的方式通知久集用户，请认真填写</span>
          <br />
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="danger" @click="ensureBondMoney">确定</el-button>
          <el-button @click="editBond = false">取消</el-button>
        </div>
      </el-dialog>
      <!-- 驳回弹窗 -->
      <el-dialog class="reject" title="驳回" :visible.sync="reject">
        <el-form ref="rejectForm" class="pr-30" :model="rejectForm" :rules="rules2">
          <el-form-item class="ml-40" prop="reason">
            <el-input
              type="textarea"
              :autosize="{ minRows: 3 ,maxRows: 10}"
              :maxlength="100"
              placeholder="请输入驳回理由.."
              v-model.trim="rejectForm.reason"
            ></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="danger" @click="ensureReject">确定</el-button>
          <el-button @click="reject = false">取消</el-button>
        </div>
      </el-dialog>
      <!-- 查看驳回理由 -->
      <el-dialog title="查看驳回理由" class="reason" :visible.sync="reason">
        <div class="pl-20 pr-20">{{lookreason}}</div>
      </el-dialog>
      <!-- 保证金修改记录 -->
      <el-dialog class="dialog-box" title="保证金修改记录" :visible.sync="bondRecord">
        <el-table :data="bondData" style="width:90%;margin:0 5%;">
          <el-table-column label="操作日期" width="130">
            <template slot-scope="scope">
              <div>{{timeFormat(scope.row.gmtCreate)}}</div>
            </template>
          </el-table-column>
          <el-table-column label="操作账号" width="130">
            <template slot-scope="scope">
              <span v-if="scope.row.changeLoginName">{{scope.row.changeLoginName}}</span>
              <span v-else>-</span>
            </template>
          </el-table-column>
          <el-table-column label="明细" width="100">
            <template slot-scope="scope">
              <div v-if="scope.row.changeAmout < 0">{{scope.row.changeAmout}}</div>
              <div v-if="scope.row.changeAmout > 0">+{{scope.row.changeAmout}}</div>
              <div v-if="scope.row.changeAmout == 0">{{scope.row.changeAmout}}</div>
              <span v-if="scope.row.changeAmout == null">-</span>
            </template>
          </el-table-column>
          <el-table-column label="保证金余额">
            <template slot-scope="scope">
              <span v-if="scope.row.currAmout">￥{{scope.row.currAmout||0 | fmoney}}</span>
              <span v-else>-</span>
            </template>
          </el-table-column>
          <el-table-column label="类型">
            <template slot-scope="scope">
              <span v-if="scope.row.reason">{{scope.row.reason}}</span>
              <span v-else>-</span>
            </template>
          </el-table-column>
        </el-table>
        <div class="myPage2">
          <el-pagination
            v-if="bondData.length>0"
            class="tar"
            :current-page="currentPage2"
            :page-size="5"
            :total="totalCount2"
            layout="prev , pager, next, jumper"
            @current-change="handleCurrentChange2"
          >
            <!-- 分页组件 -->
          </el-pagination>
        </div>
      </el-dialog>
      <!--新增销售员弹窗-->
      <el-dialog
        class="newSuperc"
        title="新增销售员"
        :visible.sync="addSuperC"
        @close="closeSuperCSwitch"
        v-loading="upload"
      >
        <el-form
          label-width="180px"
          ref="newSuperc"
          class="pr-30"
          :model="newSuperc"
          :rules="rules3"
        >
          <el-form-item label="账号" prop="mobile">
            <el-input
              placeholder="请输入手机号"
              @input="ifMobile(newSuperc.mobile)"
              v-model.trim="newSuperc.mobile"
              :maxlength="11"
              :minlength="11"
            ></el-input>
          </el-form-item>
          <el-form-item label="姓名" prop="name">
            <el-input placeholder="请输入姓名" v-model.trim="newSuperc.name"></el-input>
          </el-form-item>
          <el-form-item label="身份证号" prop="idCard">
            <el-input placeholder="请输入身份证号" v-model.trim="newSuperc.idCard" :maxlength="18"></el-input>
          </el-form-item>
          <el-form-item label="身份证图片" prop="fileList">
            <el-upload
              :class="isDisable?'fileList':''"
              action="/sunrise-gateway/oss/ossUpload"
              :file-list="newSuperc.fileList"
              list-type="picture-card"
              :on-preview="handlePictureCardPreview"
              :on-success="handleAvatarPhotoScucess"
              :before-upload="beforeAvatarUpload"
              :on-remove="handleRemove"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
            <el-dialog :visible.sync="dialogVisible" size="tiny">
              <img width="100%" :src="dialogImageUrl" alt />
            </el-dialog>
          </el-form-item>
          <el-form-item label="已缴纳保证金" prop="bondMoney">
            <el-input placeholder="请输入已缴纳保证金" :maxlength="6" v-model.trim="newSuperc.bondMoney"></el-input>
          </el-form-item>
          <el-form-item label="服务费" prop="serviceMoney">
            <el-input
              type="text"
              placeholder="请输入服务费"
              v-model.trim="newSuperc.serviceMoney"
              :maxlength="6"
              @keyup.native="newSuperc.serviceMoney=newSuperc.serviceMoney.replace(/\s+/g,'')"
            ></el-input>
            <span>元/年</span>
          </el-form-item>
          <el-form-item label="收货地址:" prop="selectedOptions">
           
            <cityCascaderBox v-model='newSuperc.selectedOptions' :num="3" :showAllLevels="true" :checkStrictly="false" :emitPath="true" @changeName="changeName"/>
          </el-form-item>
          <el-form-item class="detailAddress" label=" " prop="attr">
            <el-input
              placeholder="详细收货地址"
              v-model.trim="newSuperc.attr"
              @keyup.native="newSuperc.attr = newSuperc.attr.replace(/[\ud800-\udbff][\udc00-\udfff]/g,'')"
            ></el-input>
          </el-form-item>
          <!--<el-form-item label="推荐人" prop="referrer">
						<el-input placeholder='请输入推荐人姓名\手机号' v-model.trim='newSuperc.referrer'></el-input>
          </el-form-item>-->
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="addNewSuperc()" type="danger">确定</el-button>
          <el-button @click="closeSuperCSwitch">取消</el-button>
        </div>
      </el-dialog>
      <!-- 缴纳保证金凭证弹窗 -->
      <el-dialog title="保证金缴纳凭证" class="imgContainer" :visible.sync="certify">
        <div class="p20">
          <div class="imgWrap tac" v-if="bondImg.length!=0">
            <img
              v-for="(item,index) in bondImg"
              :src="item"
              :key="index"
              @click="imgview(bondImg,index)"
            />
          </div>
          <div class="nocon" v-else>暂无数据</div>
        </div>
      </el-dialog>
      <!-- 身份证照片 -->
      <el-dialog title="身份证照片" class="imgContainer" :visible.sync="idCardImg">
        <div>
          <div class="imgWrap" v-if="CardImg.length!=0">
            <img
              v-for="(item,index) in CardImg"
              :src="item"
              :key="index"
              @click="imgview(CardImg,index)"
            />
          </div>
          <div class="nocon" v-else>暂无数据</div>
        </div>
      </el-dialog>
    </div>
    <form
      ref="exporterr"
      action="/sunrise-gateway/export/supercStore/exportErrorSuperc"
      style="display: none"
    >
      <input type="text" name="startRow" v-model="a" />
      <input type="text" name="startCol" v-model="a" />
	  <input type="text" name="businessId" v-model="z" />
	  <input type="text" name="businessType" v-model="x" />
	  <input type="text" name="taskType" v-model="y" />
      <input type="hidden" name="modelName" v-model="e" />
      <input type="hidden" name="fileName" v-model="f" />
      <!-- <input type="hidden" name="key" v-model="g" /> -->
    </form>
    <!--导出-->
    <form ref="exportList" action="/sunrise-gateway/export/superc/store" style="display: none">
      <input type="text" name="startRow" v-model="a" />
      <input type="text" name="startCol" v-model="a" />
      <input type="text" v-if="Form.cNum" name="loginName" v-model="Form.cNum" />
      <input type="text" v-if="Form.state" name="state" v-model="Form.state" />
      <input type="text" v-if="Form.startTime" name="startDate" v-model="Form.startTime" />
      <input type="text" v-if="Form.endTime" name="endDate" v-model="Form.endTime" />
      <input type="text" name="fileName" v-model="fileName" />
      <input type="text" name="modelName" v-model="modelName" />
    </form>
      <!--导出上下级关系-->
      <form ref="exportList2" action="/sunrise-gateway/export/superc/storeRelation" style="display: none">
          <input type="hidden" name="modelName" value="empty">
          <input type="hidden" name="fileName" value="销售员上下级.xls">
          <input type="hidden" name="startRow" value="0">
          <input type="hidden" name="sellerId" :value="formDown.sellerId">
          <input type="hidden" name="supercType" :value="formDown.supercType">
      </form>
    <!-- 删除 查看操作人 -->
    <div class="operator-box">
      <el-dialog title="查看" :visible.sync="dialogStateOperator">
        <el-table :data="listOperator">
          <el-table-column property="deleteEmployeeName" label="操作人"></el-table-column>
          <el-table-column property="deleteTime" label="操作时间">
            <template slot-scope="scope">{{scope.row.deleteTime | time}}</template>
          </el-table-column>
        </el-table>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { axiosApi, gotoLoginPage, basePath } from "~/api/api.js";
export default {
  data() {
    var checkMoney = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("保证金不能为空"));
      }
      if (isNaN(Number(value))) {
        callback(new Error("请输入数字值"));
      } else {
        let reg = /^([1-9]\d*|[0]{1})$/;
        if (!reg.test(value)) {
          callback(new Error("请输入正确的数字值"));
        } else {
          callback();
        }
        callback();
      }
    };
    var address = (rule, value, callback) => {
      let val;
      if (value) {
        val = value.trim();
      }
      if (!val) {
        return callback(new Error("详细地址不能为空"));
      }
      if (val.length > 50 || val.length < 4) {
        return callback(new Error("地址长度为4-50个字符"));
      } else {
        callback();
      }
    };
    var referrer = (rule, value, callback) => {
      let reg1 = /^1(3[0-9]|4[57]|5[0-35-9]|7[0135678]|8[0-9])\d{8}$/; // 手机号;
      let reg2 = /^[\u4e00-\u9fa5]{2,16}$/; // 中文名
      if (value) {
        if (reg1.test(value) || reg2.test(value)) {
          callback();
        } else {
          return callback(new Error("请输入正确的推荐人（姓名/手机号）"));
        }
      } else {
        callback();
      }
    };
    return {
        formDown:{
            sellerId:'',
            supercType:'',
        },
	  x:'superc',
	  y:'superc_society_store',
	  z:sessionStorage.traderId,
	  isLoading: false,
      type: "0",
      dataTabs: ["全部", "待审核", "已通过", "已驳回", "已禁用", "已删除"],
      addSuperC: false,
      editBond: false,
      reject: false,
      reason: false,
      certify: false, //保证金凭证弹窗
      Form: {
        state: null,
        addDateRange: [],
        cNum: null,
        startTime: null,
        endTime: null
      },
      bondForm: {
        money: null,
        reason: null
      },
      elements: [],
      Loading: false,
      currentPage: 1,
      currentPage2: 1,
      totalCount: 1,
      totalCount2: 1,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > new Date();
        }
      },
      rejectItem: null, //驳回的那条数据信息
      rejectForm: {
        reason: null //输入文本框的驳回理由
      },
      lookreason: null, //查看驳回理由
      bondItem: null, //保证金那条数据
      rules1: {
        money: [
          { required: true, message: "保证金不能为空" },
          { validator: checkMoney, trigger: "change" }
        ],
        reason: [
          {
            required: true,
            message: "修改理由不能为空"
          },
          {
            min: 4,
            max: 100,
            message: "修改理由为4-100位字符"
          }
        ]
      },
      rules2: {
        reason: [
          {
            required: true,
            message: "驳回理由不能为空"
          },
          {
            min: 4,
            max: 100,
            message: "驳回理由为4-100位字符"
          }
        ]
      },
      rules3: {
        mobile: [
          {
            required: true,
            message: "账号不为空"
          },
          {
            pattern: /^((0\d{2,3}-?\d{7,8})|(\d{7,8})|(1\d{10}))$/,
            message: "请输入正确手机号"
          }
        ],
        name: [
          {
            required: true,
            message: "姓名不为空"
          },
          {
            pattern: /^([A-Za-z]|[\u4E00-\u9FA5]){2,16}$/,
            message: "请输入2到16位汉字或者字母"
          }
        ],
        idCard: [
          {
            required: true,
            message: "身份证号不能为空"
          },
          {
            pattern: /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/,
            message: "请输入正确的身份证号"
          }
        ],
        fileList: [
          {
            required: true,
            type: "array",
            message: "请上传身份证正反面照片"
          }
        ],
        serviceMoney: [
          { required: true, message: "服务费不能为空" },
          { validator: checkMoney, trigger: "change" }
        ],
        bondMoney: [
          {
            required: true,
            message: "保证金不能为空"
          },
          {
            validator: checkMoney,
            trigger: "change"
          }
        ],
        selectedOptions: [
          {
            required: true,
            type: "array",
            message: "请选择省市区"
          }
        ],
        attr: [
          {
            validator: address,
            trigger: "change,blur"
          }
        ],
        referrer: [
          {
            validator: referrer,
            trigger: "change,blur"
          }
        ]
      },
      bondRecord: false,
      bondData: [],
      supercStoreId: null, //久集店铺主键id
      upload: false,
      newSuperc: {
        mobile: null,
        name: null,
        idCard: null, //身份证号
        fileList: [], //身份证照片存储
        bondMoney: null,
        serviceMoney: null, //保证金
        selectedOptions: null,
        attr: null,
        referrer: null //推荐人
      }, //新增销售员表单信息
      traderId: null, //新增时候根据手机号判断
      bondImg: [], //保证金凭证图片
      imgsvisible: false,
      showimgs: [],
      startindex: 0,
      fileList: [], //上传图片地址
      isDisable: false, //控制加号显示隐藏
      dialogVisible: false, //预览
      dialogImageUrl: null, //图片显示
      idCardImg: false, //身份证照片弹窗
      CardImg: [], //身份证照片
      idStr: [], //全部导出选中列表
      fileName: null, //导出文件名称
      a: 0, //写死
      e: "empty",
      f: "销售员异常数据.xls",
      modelName: "superc.store",
      dialogStateOperator: false,
	  listOperator: [],
	  isHasError:false,// 是否有异常数据
    areaNameList:[],
    };
  },
  activated() {
    this.$nextTick(() => {
	  this.queryList(); //默认加载列表
	  this.getFunc(0)
        this.formDown = {
            sellerId: sessionStorage.traderId,
            supercType: 1,
        }
    });
  },
  methods: {
    changeName(e){
			this.areaNameList = e
		},
	exportErrorExcel(){
		this.$refs.exporterr.submit();
		this.isHasError = false
	},
    getFunc(type) {
      axiosApi(
        "/foundation/importTask/selectStatus",
        {
          businessId: sessionStorage.traderId,
          businessType: "superc",
          taskType: "superc_society_store"
        },
        res => {
          if (res.data.data == "handle") {
			this.isLoading = true;
            setTimeout(() => {
              this.getFunc();
            }, 5000);
          } else if(res.data.data == "fail") {
			this.isHasError = true
			this.isLoading = false;
          }else{
			  if(type !=0){
				  this.queryList()
			  }
			  this.isHasError = false;
			  this.isLoading = false;
		  }
        },
        1, 10
      );
    },
    handUpBefore(file) {
		this.isLoading = true;
    //   this.$loading({
    //     fullscreen: false,
    //     lock: true,
    //     text: "正在导入..."
    //   });

    //   return true;
    },
    handUpSuccess(res, file, fileList) {
      this.isLoading = false
      switch (res.code) {
        case "000000": //系统交易成功
          this.getFunc();
          break;
        case "999999": //系统异常
          this.$alert(res.message, "提示信息", {
            confirmButtonText: "确定"
          });
          break;
        case "AUTH_001": //用户未登陆
          gotoLoginPage();
          break;
        case "AUTH_002": //用户无权限
          this.$alert(res.message, "提示信息", {
            confirmButtonText: "确定"
          });
          break;
        case "LOGIN_005": //用户已禁用
          this.$alert(res.message, "提示信息", {
            confirmButtonText: "确定"
          });
          break;
        case "LOGIN_006": //用户session失效
          gotoLoginPage();
          break;
        default:
          this.$alert(res.message, "提示信息", {
            confirmButtonText: "确定"
		  });
          break;
      }
    },
    handUpError(err, file, fileList) {
    //   this.$loading().close();
      this.$message.error("导入失败！");
    },
    imgview(imgs, index) {
      this.showimgs = imgs;
      this.startindex = index;
      this.imgsvisible = true;
    },
    search() {
      if(!this.Loading){
        this.Form.startTime = this.Form.addDateRange[0]
        ? this.Form.addDateRange[0].getTime()
        : null;
      this.Form.endTime = this.Form.addDateRange[1]
        ? this.Form.addDateRange[1].getTime()
        : null;
      this.$refs.searchForm.validate(valid => {
        if (valid) {
          this.queryList();
        }
      });
      }
    },
    timeFormat(value) {
      var myDate = new Date(value);
      var year = myDate.getFullYear();
      var month =
        myDate.getMonth() + 1 < 10
          ? "0" + (myDate.getMonth() + 1)
          : myDate.getMonth() + 1;
      var day =
        myDate.getDate() < 10 ? "0" + myDate.getDate() : myDate.getDate();
      return year + "-" + month + "-" + day;
    },
    queryList(x) {
      this.Loading = true;
      if (this.Form.cNum == "") {
        this.Form.cNum = null;
      }
      //				this.Form.startTime = this.Form.startTime == null ? null : this.timeFormat(this.Form.startTime);
      //				this.Form.endTime = this.Form.endTime == null ? null : this.timeFormat(this.Form.endTime);
      // 给后台已删除字段为 -1 ， 前端使用的是5;
      let type = this.type === "5" ? "-1" : this.type;
      const body = {
        loginName: this.Form.cNum,
        startDate: this.Form.startTime,
        endDate: this.Form.endTime,
        state: type !== "0" ? type : null
      };
      axiosApi(
        "/superc/supercStore/selectList",
        body,
        res => {
          this.Loading = false;
          this.elements = res.data.data.elements;
          this.totalCount = res.data.data.totalCount;
          this.currentpage = res.data.data.pageIndex;
        },
        x ? x : 1,
        10,
        err => {
          this.Loading = false;
          this.elements = [];
        }
      );
    },
    handleTabs(e) {
      //Tab切换
      if (e.target.attributes.type) this.type = e.target.attributes.type.value;
      this.Form.addDateRange = [];
      this.Form.cNum = "";
      this.currentPage = 1;
      if (this.type == "0") {
        //全部
        this.queryList();
      } else if (this.type == "1") {
        //待审核
        this.queryList();
      } else if (this.type == "2") {
        //已通过
        this.queryList();
      } else if (this.type == "3") {
        //已驳回
        this.queryList();
      } else if (this.type == "4") {
        //已禁用
        this.queryList();
      } else if (this.type == "5") {
        // 已删除
        this.queryList();
      }
    },
    handleCurrentChange(val) {
      //分页
      this.currentPage = val;
      this.$refs.searchForm.validate(valid => {
        if (valid) {
          this.queryList(val, this.type);
        }
      });
    },
    handleCurrentChange2(val) {
      //查看保证金分页
      this.currentPage2 = val;
      this.queryList2(val, { supercStoreId: this.supercStoreId });
    },
    handleDis(item) {
      //禁用
      const h = this.$createElement;
      this.$msgbox({
        title: "提示信息",
        message: h("p", { style: "text-align:center" }, "确认禁用该账号？"),
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            const body = {
              id: item.id,
              state: 4
            };
            axiosApi(
              "/superc/supercStore/updateState",
              body,
              res => {
                this.queryList(this.currentpage);
              },
              1,
              10
            );
            done();
          } else {
            done();
          }
          delete instance.$slots.default;
        }
      });
    },
    handleEnable(item) {
      //启用
      const h = this.$createElement;
      this.$msgbox({
        title: "提示信息",
        message: h("p", { style: "text-align:center" }, "确认启用该账号？"),
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            const body = {
              id: item.id,
              state: 2
            };
            axiosApi(
              "/superc/supercStore/updateState",
              body,
              res => {
                this.queryList(this.currentpage);
              },
              1,
              10
            );
            done();
          } else {
            done();
          }
          delete instance.$slots.default;
        }
      });
    },
    handlePass(item) {
      //通过
      const h = this.$createElement;
      this.$msgbox({
        title: "提示信息",
        message: h("p", { style: "text-align:center" }, "确认通过该账号？"),
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            const body = {
              id: item.id,
              state: 2
            };
            axiosApi(
              "/superc/supercStore/updateState",
              body,
              res => {
                this.queryList(this.currentpage);
              },
              1,
              10
            );
            done();
          } else {
            done();
          }
          delete instance.$slots.default;
        }
      });
    },
    handleEditBond(item) {
      //修改保证金
      this.editBond = true;
      this.bondItem = item;
    },
    ensureBondMoney() {
      //保证金修改确定按钮
      setTimeout(() => {
        this.$refs.bondForm.validate(valid => {
          ////console.log(valid)
          if (valid) {
            const body = {
              id: this.bondItem.id,
              bondMoney: this.bondForm.money,
              updateAmountReason: this.bondForm.reason
            };
            axiosApi(
              "/superc/supercStore/updateBond",
              body,
              res => {
                this.queryList(this.currentpage);
                this.editBond = false;
              },
              1,
              10
            );
          }
        });
      }, 0);
    },
    closeBond() {
      //保证金弹窗关闭清空数据,清空验证规则
      this.bondForm = {
        money: null,
        reason: null
      };
      this.$refs.bondForm.resetFields();
    },
    handleReject(item) {
      //驳回
      this.reject = true;
      this.rejectItem = item;
    },
    ensureReject() {
      //驳回确定按钮
      setTimeout(() => {
        this.$refs.rejectForm.validate(valid => {
          if (valid) {
            const body = {
              id: this.rejectItem.id,
              state: 3,
              reason: this.rejectForm.reason
            };
            axiosApi("/superc/supercStore/updateState", body, res => {
              this.queryList(this.currentpage);
              this.reject = false;
            });
          }
        });
      }, 0);
    },
    LookReason(item) {
      //查看驳回理由
      this.reason = true;
      this.lookreason = item.reason;
    },
    queryList2(x, body) {
      axiosApi(
        "/superc.supercBondLog.selectListPage",
        body,
        res => {
          ////console.log(res.data.data)
          this.bondData = res.data.data.elements;
          this.totalCount2 = res.data.data.totalCount;
          this.currentPage2 = res.data.data.pageIndex;
        },
        x ? x : 1,
        5
      );
    },
    lookBondRecord(item) {
      //查看保证金记录
      this.bondRecord = true;
      this.supercStoreId = item.id;
      const body = {
        supercStoreId: item.id
      };
      this.queryList2(this.currentpage2, body);
    },
    ifMobile(val) {
      let reg = /^((0\d{2,3}-?\d{7,8})|(\d{7,8})|(1\d{10}))$/;
      if (reg.test(val)) {
        //判断手机号已经存在时候，数据带出来
        axiosApi(
          "/superc/trader/isRegisterForWeb",
          { loginName: val, sellerId: sessionStorage.traderId },
          res => {
            ////console.log(res,'zzz')
            //以下注释为手机号已经注册，将信息展示再输入框中
            this.traderId =
              res.data.data && res.data.data.addressVO
                ? res.data.data.addressVO.trader
                : null;
            if (res.data.data) {
              this.newSuperc.name = res.data.data.name;
              if (res.data.data.addressVO && res.data.data.addressVO.attr) {
                this.newSuperc.attr = res.data.data.addressVO.attr;
              }
              if (
                res.data.data.addressVO &&
                res.data.data.addressVO.provinceId
              ) {
                this.newSuperc.selectedOptions = [
                  String(res.data.data.addressVO.provinceId),
                  String(res.data.data.addressVO.cityId),
                  String(res.data.data.addressVO.regionId)
                ];
              }
              this.newSuperc.idCard = res.data.data.idCard;
            } else {
              this.newSuperc.bondMoney = null;
              this.newSuperc.selectedOptions = this.newSuperc.selectedOptions
                ? []
                : null;
              this.newSuperc.attr = null;
              this.newSuperc.name = null;
            }
          },
          1,
          10,
          res => {
            this.$message({
              type: "info",
              message: res.data.message,
              duration: 1000
            });
          }
        );
      } else {
        this.newSuperc.bondMoney = null;
        this.newSuperc.selectedOptions = this.newSuperc.selectedOptions
          ? []
          : null;
        this.newSuperc.attr = null;
        this.newSuperc.name = null;
      }
    },
    addSuperCSwitch() {
      //新增销售员弹窗打开
      this.addSuperC = true;
    },
    closeSuperCSwitch() {
      //关闭新增销售员弹窗
      this.addSuperC = false;
      this.isDisable = false; //保证上传图片入口存在
      this.$refs.newSuperc.resetFields();
    },
    addNewSuperc() {
      this.$refs.newSuperc.validate(valid => {
        if (valid) {
          this.upload = true;
          this.fileList = [];
          this.newSuperc.fileList.forEach(item => {
            this.fileList.push(item.url);
          });
          ////console.log(this.fileList,'上传的数据')
          let info = this.areaNameList;
          let body = {
            traderId: this.traderId,
            mobile: this.newSuperc.mobile,
            name: this.newSuperc.name,
            idCard: this.newSuperc.idCard,
            idCardImg: this.fileList,
            bondMoney: this.newSuperc.bondMoney,
            serviceMoney: this.newSuperc.serviceMoney,
            loginName: this.newSuperc.mobile,
            provinceId: this.newSuperc.selectedOptions[0],
            cityId: this.newSuperc.selectedOptions[1],
            regionId: this.newSuperc.selectedOptions[2],
            provinceName: info[0],
            cityName: info[1],
            regionName: info[2],
            address: this.newSuperc.attr
            //							referrer: this.newSuperc.referrer
          };
          axiosApi(
            "/superc/supercStore/web/register",
            body,
            res => {
              this.queryList();
              this.addSuperC = false;
              this.upload = false;
              this.$message.success(
                { message: "新增销售员成功" },
                { duration: 100 }
              );
            },
            1,
            10,
            err => {
              // ////console.log(err.data.message)
              this.upload = false;
              this.$message.info(
                { message: err.data.message },
                { duration: 100 }
              );
            }
          );
        }
      });
    },
    lookBondCertify(item) {
      this.certify = true;
      this.bondImg = item ? item.split(",") : [];
    },
    lookIdCardImg(scope) {
      this.idCardImg = true;
      this.CardImg = scope.row.idCardImg ? scope.row.idCardImg : [];
    },
    //上传图片
    handlePictureCardPreview(file) {
      //预览
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    beforeAvatarUpload(file) {
      //上传之前的验证
      const isImage =
        file.type.indexOf("jpg") != -1 ||
        file.type.indexOf("jpeg") != -1 ||
        file.type.indexOf("png") != -1 ||
        file.type.indexOf("bmp") != -1;
      const isLt4M = file.size / 1024 / 1024 < 4 && file.size / 1024 / 1024 > 0;
      if (!isImage) {
        this.$message.error("请上传正确的图片格式文件!");
        this.loading = false;
      }
      if (!isLt4M) {
        this.$message.error("上传图片大小不能超过 4MB!");
        this.loading = false;
      }
      return isImage && isLt4M;
    },
    handleAvatarPhotoScucess(res, file, fileList) {
      //上传成功的状态
      file.url = res.data[0];
      ////console.log(fileList)
      this.newSuperc.fileList = fileList;
      ////console.log(this.newSuperc.fileList,'1234')
      //成功的回调
      if (fileList.length >= 2) {
        this.isDisable = true;
      } else {
        this.isDisable = false;
      }
    },
    handleRemove(file, fileList) {
      //删除
      //////console.log(file, fileList,'++++++++++++++++')
      this.newSuperc.fileList = fileList;
      if (fileList.length >= 2) {
        this.isDisable = true;
      } else {
        this.isDisable = false;
      }
    },
    exportExcel() {
      //全部导出
      this.Form.state = this.type !== "0" ? this.type : null;
      this.Form.startTime = this.Form.addDateRange[0]
        ? this.Form.addDateRange[0].getTime()
        : null;
      this.Form.endTime = this.Form.addDateRange[1]
        ? this.Form.addDateRange[1].getTime()
        : null;
      let str =
        new Date().getFullYear() +
        "-" +
        (new Date().getMonth() + 1) +
        "-" +
        new Date().getDate();
      this.fileName = "人员管理列表_" + str + ".xls";

      setTimeout(() => {
        if (this.elements.length > 0) {
          this.$refs.exportList.submit();
        } else {
          this.$message.warning("暂无数据可导出！");
        }
      }, 0);
    },
      //导出上下级关系
      exportExcel2(){
          if (this.elements.length > 0) {
              this.$refs.exportList2.submit();
          } else {
              this.$message.warning("暂无数据可导出！");
          }
      },
    // 删除
    handleDelete(row) {
      console.log(row);
      this.$confirm("确认删除该账号？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.deleteFn(row.id);
        })
        .catch(() => {});
    },
    // 删除逻辑
    deleteFn(id) {
      axiosApi(
        "/superc/supercStore/web/delete",
        {
          id: id
        },
        res => {
          this.$message.success("操作成功");
          this.currentPage = 1;
          this.queryList();
        },
        1,
        1,
        err => {
          this.$message.warning(err.data.message);
        }
      );
    },
    // 删除 - 查看
    handleOperator(row) {
      this.dialogStateOperator = true;
      axiosApi(
        "/superc/delete/supercStore/select",
        {
          id: row.id
        },
        res => {
          let data = res.data.data;
          this.listOperator = [
            {
              deleteEmployeeName: data.deleteEmployeeName,
              deleteTime: data.deleteTime
            }
          ];
        }
      );
    },
    // 删除 - 撤回
    handleWithdraw(row) {
      this.$confirm("确认恢复该账号？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          axiosApi(
            "/superc/supercStore/web/resume",
            {
              id: row.id
            },
            res => {
              this.$message.success("操作成功");
              // 重置表格数据
              this.currentPage = 1;
              this.type = "0";
              this.queryList();
            },
            1,
            1,
            err => {
              this.$message.warning(err.data.message);
            }
          );
        })
        .catch(() => {});
    },
    goToDetail(row) {
      //去详情
      this.$router.push(basePath + "/superPerDetail/" + row.id);
    }
  }
};
</script>

<style lang="less">
#perManagement {
  #imgview {
    z-index: 9999;
  }
  .item{
    margin: 0 20px;
  }
  .content {
    .el-form--inline {
      width: 650px;
      .el-form-item__label {
        font-size: 12px;
        width: 60px;
      }
      .order-datatimerange {
        width: 240px;
      }
    }
    .el-table .cell {
      text-align: center;
      padding: 10px 0;
      .el-button {
        height: 18px;
        width: auto;
      }
      .el-button + .el-button {
        margin-left: 0;
      }
    }
    .detailAddress {
      .el-form-item__label:before {
        content: "";
      }
    }
  }
  .dialog-box {
    .el-dialog {
      width: 600px;
    }
  }
  .newSuperc .el-dialog {
    width: 550px;
  }
  .el-dialog {
    min-height: 350px;
    .el-dialog__body {
      padding: 40px 0 80px 0;
    }
    .el-form-item__label {
      width: 160px;
    }
    .el-textarea {
      .el-textarea__inner {
        border-radius: 0;
      }
    }
    .gaze {
      margin-left: 100px;
    }
  }
  .bond {
    .el-textarea {
      width: 300px;
    }
  }
  .reject {
    .el-dialog {
      min-height: 290px;
    }
  }
  .reason {
    .el-dialog__body {
      div {
        padding: 0px 30px;
        text-indent: 28px;
        text-align: center;
      }
    }
  }
  .imgContainer {
    .el-dialog__body {
      padding: 10px 30px;
      .imgWrap {
          justify-content: space-around;
        overflow-x: auto;
        display: flex;
        img {
          height: 250px;
          width: auto;
          margin-right: 10px;
          &:nth-last-child(1) {
            margin-right: 0;
          }
        }
      }
    }
  }
  .myPage2 {
    margin-right: 5%;
  }
  .el-upload--picture-card {
    width: 90px;
    line-height: 90px;
    height: 90px;
  }
  .el-icon-plus {
    line-height: 90px;
  }
  .el-upload-list--picture-card .el-upload-list__item {
    width: 90px;
    height: 90px;
  }
  .fileList .el-upload--picture-card {
    display: none;
  }
  .nocon {
    text-align: center;
  }
  /* 浮层样式 修改 */
  .operator-box {
    .el-dialog {
      min-height: auto !important;
    }
    .el-dialog__body {
      padding: 20px 30px !important;
    }
  }
}
</style>
