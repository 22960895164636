<template>
	<div id="supercIntro">
		<div v-if="state==4" class="conFile bg-f">
			<!--商户被禁用时显示-->
			<div class="showImg">
				<img src="../../assets/superc/file.png" alt="" />
				<p>久集功能被禁用，请联系久加久平台重新开启</p>
			</div>
		</div>
		<div class="clearfix conShow" v-else>
			<!--申请及审核时显示-->
			<div class="swiper fl">
				<div class="swiper-pos">
					<el-carousel height="395px">
					    <el-carousel-item v-for="(item,index) in imgUrl" :key="index">
					        <img :src="item.src" alt="" />
					    </el-carousel-item>
				    </el-carousel>
				</div>
			</div>
			<div class="con fr">
				<div class="logo-c">
					<h2>久集</h2>
					<span>高效移动分销助手</span>
				</div>
				<div class="btn-c">
					<el-button v-if="state==null||state==3" @click="goToApply()">申请免费开通</el-button>
					<el-button v-if="state==1" :disabled="true" >正在申请中</el-button>
				</div>
				<ul>
					<li v-for="i in showData" class="mt-30">{{i.show}}</li>
				</ul>
			</div>
		</div>
	</div>
</template>
<script>
	import { axiosApi, basePath } from "../../api/api";
	export default {
		data(){
			return {
				state:null,
				showData:[{show:'帮助有货源的商家整理渠道分销商品'},{show:'部署您独有的移动分销入口，使流量变现'},{show:'以人为中心，粉丝既渠道，社交分享顾客购买，久集即获佣金'}],
				imgUrl:[{src:require('../../assets/superc/1.png')},
					{src:require('../../assets/superc/2.png')},
					{src:require('../../assets/superc/3.png')}]
			}
		},
		methods:{
			goToApply(){
				this.$router.push({path:'applyAcount'})
			}
		},
		mounted (){
			//查询商户开通久集的申请结果状态
			axiosApi('/superc/traderOpenSuperC/select',{traderId:sessionStorage.getItem('traderId')},res=>{
				this.state = res.data.data.state
			})
		}
	}
</script>
<style lang="less">
	#supercIntro{
		box-sizing: border-box;
		.conFile{
			width: 1000px;
			height: 704px;
			text-align: center;
			display:table-cell;
        	vertical-align:middle;
			.showImg{
				p{font-size: 16px;margin-top: 40px;}
			}
		}
		.conShow{
			width: 1000px;
			height: 704px;
			background: url(../../assets/superc/bg.png) no-repeat;
			background-size: 100%;
			position: relative;
			padding-top: 100px;
			padding-left: 77px;
			padding-bottom: 86px;
			.swiper{
				width: 255px;
				height: 518px;
				box-sizing: border-box;
				background: url(../../assets/superc/bg-wphone.png);
				position: relative;
				.swiper-pos{
					position: absolute;
					top: 64px;
					left: 16px;
				}
				.el-carousel__container{
					width: 222px;
					img{
						width: 100%;
						height: 100%;
					}
				}

			}
			.con{
				width: 579px;
				color: #FFFFFF;
				padding-right: 20px;
				box-sizing: border-box;
				.logo-c{
					h2{
						font-size: 48px;
						font-weight: 400;
						margin-bottom: 25px;
					}
					span{font-size: 36px;}
				}
				.btn-c{
					margin: 50px 0;
					.el-button{
						width: 247px;
						height: 60px;
						color: #FF4201;
						font-size: 24px;
						border-radius: 30px;
					}
				}
				ul{
					margin-left: 24px;
					li{
						font-size: 24px;
						list-style: initial;
					}
				}
			}
		}
	}
</style>
