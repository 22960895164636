import { axiosApi, basePath } from "./api";

// 支付接口
export function orderPay (data={}) {
  return new Promise((resolve, reject) => {
    axiosApi('/order/order/pay', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
}

// 银行卡接口
export function getBankInfoList (data={}) {
  return new Promise((resolve, reject) => {
    axiosApi('/trader/findSellerBankInfoList/select', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
}
