<template>
    <div id="draft-order" v-loading='loading'>
        <div class="clearfix bg-f">
            <div class="fl title-tabs bg-f black mb-5">
                <ul class="clearfix">
                    <li class="active" >
                        待提交单
                        <div class="under-line"></div>
                    </li>
                    <div class="vertical-line"></div>
                </ul>
            </div>
        </div>
        <div class="content bg-f pb-50 pl-30 pr-30 mt-5">
            <div class="clearfix">
                <el-form :inline='true' class="pt-30 fl search" :model="form" ref="form">
                    <el-form-item label='订单编号' prop="orderSn">
                        <el-input v-model.trim='form.orderSn' @input="form.orderSn=form.orderSn.replace(/[\u4e00-\u9fa5]/ig,'')"></el-input>
                    </el-form-item>
                    <el-form-item label='保存时间' class="ml-20" prop="orderDateRange">
                        <ys-daterange-picker v-model="form.orderDateRange"/>
                    </el-form-item>
                    <el-form-item label='客户名称'  prop="buyerName">
                        <el-input v-model.trim="form.buyerName" :maxlength="40"></el-input>
                    </el-form-item>
                    <el-form-item label='客户手机' class="ml-20" prop="buyerTel">
                        <el-input v-model.trim="form.buyerTel" :maxlength="11"></el-input>
                    </el-form-item>
                    <el-form-item label='商品名称'  prop="skuName">
                        <el-input v-model.trim="form.skuName" :maxlength="40"></el-input>
                    </el-form-item>
                </el-form>
                <el-button class="fl ml-30 mt-30" type='info' @click="handleQueryBtn">查 询</el-button>
            </div>
            <div class="order-list">
                <!--<el-checkbox v-model="checkAll" @change="handleCheckAllChange" class="checkedAll"></el-checkbox>-->
                <div class="clearfix font-12 bg-e table_header color-3" style="display: flex;text-align: center">
                    <div class="fl" style="flex: 4">商品信息</div>
                    <div class="fl" style="flex: 1">单价</div>
                    <div class="fl" style="flex: 1">数量</div>
                    <div class="f1" style="flex: 1" v-show="!isCityPartner">仓库</div>
                    <div class="fl" style="flex: 1">客户名称</div>
                    <div class="fl" style="flex: 1">交易金额</div>
                    <div class="fl" style="flex: 1">交易状态</div>
                    <div class="fl" style="flex: 1.5">交易操作</div>
                </div>
                <div class="table-item-default tac" v-if="orderList.length<=0">
                    <img src="../../../assets/empty.png" alt=""/>
                    <div class="color-danger mt-30">
                        <router-link :to="{path:'/customer/shop/shopIndex'}"><span
                                class="color-danger"> 啥都没有，去首页看看 ></span></router-link>
                    </div>
                </div>
                <div v-else>
                    <div class="table-item mt-20 font-12" v-for='order in orderList' :key="order.id">
                        <div class="clearfix table-item-top bg-e">
                            <!--<el-checkbox class="fl checked" :label="order" v-model="order.checked"-->
                                         <!--@change="handleCheckChange1(order)"></el-checkbox>-->
                            <span class='fl ml-20'>订单编号：{{order.orderSn}}</span>
                            <span class='fl ml-20'>保存时间：{{order.gmtModified | time}}</span>
                            <span class='fl ml-20'>制单员：{{order.operatorName ? order.operatorName : '无'}}</span>
                            <span class='fl ml-20'>销售员：{{order.managerName ? order.managerName: '无'}}</span>
                            <div class="separate" style="display:inline-block;width:160px;text-align:right;height: 50px">
                                <img  v-if="order.deliverySeparate" style="height:50px;width:auto;"
                                      src="../../../assets/separate.png"/>
                                <img v-if="order.openStraight" style="height:50px;width:auto;"
                                     src="../../../assets/straight.png"/>
                            </div>
                        </div>
                        <div v-if="order.remark || order.remarkPics" class="bbd clearfix"
                             style="lineHeight:40px;paddingLeft: 76px;display: flex">
                            <span class="fl" style="vertical-align:top;width: 60px;">客户备注：</span>
                            <div class=" fl" style="word-break: break-word;width: 0;flex: 1">
                                <span style="vertical-align:top">{{order.remark}}</span>
                                <div v-if="order.remarkPics" style="display:inline-block;margin-top:10px" class="ml-10" >
                                    <img style="width: 30px;height: 30px" class="mr-10"  :src="item" v-for="(item,index) in order.remarkPics" alt="" :key="index" @click="lookImageDetail(order.remarkPics,index)">
                                </div>
                            </div>
                        </div>
                        <div v-if="order.remarkForModify || order.remarkForModifyPics" class="bbd clearfix"
                             style="lineHeight:26px;paddingLeft: 76px;color:rgba(255,89,24,1);display: flex">
                            <span class="fl" style="width: 60px">业务备注:</span>
                            <div class=" fl"  style="word-break: break-word;width: 0;flex: 1">
                                <span style="vertical-align:top">{{order.remarkForModify}}</span>
                                <div style="display:inline-block;margin-top:10px" class="ml-10"  v-if="order.remarkForModifyPics">
                                    <img style="width: 30px;height:30px" class="mr-10"  :src="item" v-for="(item,index) in order.remarkForModifyPics" alt="" :key="index" @click="lookImageDetail(order.remarkForModifyPics,index)">
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="table-item-content clearfix" style="display: flex;">
                                <!--商品-->
                                <div class="clearfix bb" :style="!isCityPartner?'flex: 7;':'flex:6'">
                                    <div style="padding: 20px 0 20px 20px" :class="{'bb': index != order.orderItemVos.length-1 || (index == order.orderItemVos.length-1 && order.orderGiftResVos.length>0)}" v-for="(orderItem,index) in order.orderItemVos" :key="orderItem.id">
                                        <div class="clearfix" style="display: flex">
                                            <div class="fl gift" style="flex: 4">
                                                <div class="clearfix">
                                                    <div v-if="orderItem.changePurchaseItem"
                                                         class="triangle-topleft border-red"></div>
                                                    <span v-if="orderItem.changePurchaseItem" class="init default">换购</span>
                                                    <img v-if="orderItem.skuImage" :src="orderItem.skuImage" class="fl mr-10"/>
                                                    <img v-else src="../../../assets/goods.jpg" class="fl mr-10"/>
                                                    <div class="fl" style="width: 210px;">
                                                        <el-tooltip class="item" effect="dark" :content="orderItem.skuName"
                                                                    placement="top-start">
                                                            <p class="text-overflow">{{orderItem.skuName}}</p>
                                                        </el-tooltip>
                                                        <p class="color-9 mt-10" v-if="orderItem.changeCoefficient">
                                                            规格:1*{{orderItem.changeCoefficient}}</p>
                                                        <p class="color-9 mt-10"
                                                           v-if="orderItem.k3SaleTypeName">
                                                            商品类型:{{orderItem.k3SaleTypeName}}</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="fl " style="flex: 1;text-align: center">
                                                ¥{{orderItem.price}}/{{orderItem.isIntegerMultiple ? orderItem.integerUnitName : orderItem.baseUnitName }}
                                            </div>
                                            <div class="fl " style="flex: 1;text-align: center">
                                                <div>{{orderItem.num}}{{orderItem.isIntegerMultiple ? orderItem.integerUnitName : orderItem.baseUnitName}}</div>
                                            </div>
                                            <div class="f1" v-if="!isCityPartner" style="flex:1;text-align:center">{{order.openStraight?'虚拟仓':orderItem.k3StockName}}</div>
                                        </div>
                                        <p v-if="orderItem.remark" style="lineHeight:26px;color:rgba(255,89,24,1)">商品备注：{{ orderItem.remark }}</p>
                                    </div>
                                    <!--赠品清单-->
                                    <div style="padding: 20px 0 20px 20px" :class="{'bb': index != order.orderGiftResVos.length-1}" v-for="(orderItem,index) in order.orderGiftResVos" :key="orderItem.id">
                                        <div class="clearfix" style="display: flex">
                                            <div class="fl gift" style="flex: 4">
                                                <div class="triangle-topleft border-red"></div>
                                                <span class="init default">赠品</span>
                                                <img v-if="orderItem.skuImage" :src="orderItem.skuImage" class="fl mr-10"/>
                                                <img v-else src="../../../assets/goods.jpg" class="fl mr-10"/>
                                                <div class="fl" style="width: 210px;">
                                                    <el-tooltip class="item" effect="dark" :content="orderItem.skuName"
                                                                placement="top-start">
                                                        <p class="text-overflow">{{orderItem.skuName}}</p>
                                                    </el-tooltip>
                                                    <p class="color-9 mt-10" v-if="orderItem.changeCoefficient">
                                                        规格:1*{{orderItem.changeCoefficient}}</p>
                                                    <p class="color-9 mt-10"
                                                       v-if="orderItem.k3SaleTypeName&&orderItem.isGift">
                                                        商品类型:{{orderItem.k3SaleTypeName}}</p>
                                                    <p class="color-9 mt-10"
                                                       v-if="orderItem.k3GiftTypeName && !isCityPartner&&orderItem.isGift">
                                                        赠品类型:{{orderItem.k3GiftTypeName}}</p>
                                                </div>

                                            </div>
                                            <div class="fl giftPriceClass" style="flex: 1;text-align: center">¥{{orderItem.price}}/{{orderItem.isIntegerMultiple ? orderItem.integerUnitName : orderItem.baseUnitName }}</div>
                                            <div class="fl " style="flex: 1;text-align: center">
                                                <div>{{orderItem.num}}{{orderItem.isIntegerMultiple ? orderItem.integerUnitName : orderItem.baseUnitName}}</div>
                                            </div>
                                            <div class="fl " v-if="!isCityPartner" style="flex: 1;text-align: center">{{order.openStraight?'虚拟仓':orderItem.k3StockName}}</div>
                                        </div>
                                        <p v-if="orderItem.remark" style="lineHeight:26px;color:rgba(255,89,24,1)">商品备注：{{ orderItem.remark }}</p>
                                    </div>
                                </div>
                                <div class="bl bb tac pt-20" style="flex: 1">
                                    <p class="tac mb-5">{{order.buyerTel}}</p>
                                    <p>{{order.buyerName}}</p>
                                </div>
                                <div class="bl bb tac pt-20" style="flex: 1">
                                    <p class="tac mb-5" style="word-break: break-all">¥{{order.needPayMoney | fmoney}}</p>
                                    <p>含运费 (￥{{order.freightMoney | fmoney}})</p>
                                    <p class='mt-10 ' v-if="order.clientSource">
                                        <i v-if="order.clientSource != 1 && order.clientSource != 7"
                                           class="iconfont icon-phone font-14 color-3"></i>
                                        <i v-else class="iconfont icon-computed font-14 color-3"></i>
                                        {{order.clientSource | clientSource}}</p>
                                </div>
                                <div class="bl bb tac pt-20" style="flex: 1">
                                    <p class="color-danger">待提交</p>
                                </div>
                                <div class="bl bb tac pt-20 pb-10" style="flex: 1.5;">
                                    <div>
                                        <el-button type="text" @click="deleteOrder(order.id,'确认要删除该待草稿订单吗？')">删除订单</el-button>
                                    </div>
                                    <div>
                                        <el-button type="text" @click="lookOrder(order)">查看订单</el-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <el-pagination v-if='total>0' class="tar mt-20" :current-page="currentPage" :page-size='10' :total='total' layout="prev , pager, next, jumper" @current-change="handleCurrentChange">
                <!-- 分页组件 -->
            </el-pagination>
        </div>
        <!-- 图片预览 -->
        <imgview :visible="imgVisible" :imgs="showImg" :startindex="startIndex" @close="imgVisible=false"></imgview>
    </div>

</template>

<script>
    import {axiosApi, basePath} from "../../../api/api"
    import {mapMutations, mapActions,mapState} from 'vuex';
    import md5 from 'md5'
    export default {
        name:'draftOrder',
        computed: {
            ...mapState('nav',['tagNav']),
            isCityPartner() {
                return this.$store.state.clientType.isCityPartner;
            }
        },
        data() {
            return {
                flag: false,
                form: {
                    sellerId: sessionStorage.traderId,
                    orderSn: null, //订单编号
                    buyerName: null, //客户名称
                    buyerTel: null, //客户手机
                    skuName: null, //商品名称
                    gmtBegin: null, //保存开始时间
                    gmtEnd: null, //保存结束时间
                    orderDateRange: [], //保存时间数组
                },
                formSet: {
                    isOpenOrder: 1,
                    cancelTime: '',
                    freight: '',
                    isOpenOrderTypeOpt:0,//是否开通订单单据类型修改
                    openAfterSales:'2',//开通售后
                    checklist:[],//开启pc 云销
                },
                orderList: [], //订单列表
                currentPage: 1,
                total: null,
                loading: false,
                imgVisible:false,
                startIndex:0,
                showImg:[]
            }
        },
        filters: {
            clientSource(val) {
                let str = '';
                switch (val) {
                    case 1: {
                        str = '云商PC端';
                        break
                    }
                    case 2: {
                        str = '云商IOS端';
                        break
                    }
                    case 3: {
                        str = '云商Android端';
                        break
                    }
                    case 4: {
                        str = '云销IOS端';
                        break
                    }
                    case 5: {
                        str = '云销Android端';
                        break
                    }
                    case 6: {
                        str = 'WX下单';
                        break
                    }
                    case 7: {
                        str = 'PC代下单';
                        break
                    }
                    case 8: {
                        str = '餐链下单';
                        break
                    }
                    case 9: {
                        str = '超B下单';
                        break
                    }
                    default : {
                        str = '';
                        break
                    }
                }
                return str
            }
        },
        activated(){
            this.queryOrderList()
            this.findSellerJurisdiction()
        },
        mounted() {
            let navData = {
                url:  this.$route.fullPath,
                text: '待提交单列表'
            }
            let arr = this.$deepClone(this.tagNav)
            arr.push(navData)
            this.changeTagNav(arr)
            this.$nextTick(() => {
                this.queryOrderList()
                this.findSellerJurisdiction()
            })
        },
        methods: {
            ...mapMutations('nav',['changeTagNav']),
            //查看是否开通订单审核权限
            findSellerJurisdiction() {
                axiosApi('/trader/findSellerJurisdiction/select', {
                    traderId: sessionStorage.traderId
                }, (res) => {
                    this.auditCompetence = res.data.data;
                    let binaryData = this.auditCompetence.aftersalesChannel.split('');
                    this.formSet = {
                        ...this.formSet,
                        openAfterSales:binaryData[0] == 1 ? '1' : '2',//开通售后
                        isOpenOrder: this.auditCompetence.isOpenOrder,
                        isOpenOrderTypeOpt: this.auditCompetence.isOpenOrderTypeOpt ? 1 : 0,
                        cancelTime: this.auditCompetence.cancelTime,
                        freight: this.auditCompetence.freight
                    }
                    this.formSet.checklist[0] = binaryData[binaryData.length-2] == 1 ? '1' : '';
                    this.formSet.checklist[1] = binaryData[binaryData.length-1] == 1 ? '2' : ''
                }, 1, 10, (res) => {

                })
            },
            lookImageDetail(list,index){
                this.imgVisible = true;
                this.startIndex = index;
                this.showImg = list;
            },
            handleQueryBtn() { //查询
                this.form.gmtBegin = !this.form.orderDateRange[0] ? null : this.form.orderDateRange[0].getTime()
                this.form.gmtEnd = !this.form.orderDateRange[1] ? null : this.form.orderDateRange[1].getTime()
                this.currentPage = 1;//赋值自动调用一次接口
                this.queryOrderList()
            },
            handleCurrentChange(val) {
                this.currentPage = val
                this.queryOrderList()
            },
            /*草稿单列表查询及条件查询接口*/
            queryOrderList() {
                this.loading = true
                let body = {
                    ...this.form
                }
                axiosApi('/order/draftOrder/query', body, (res) => {
                    this.loading = false
                    if (res.data.data) {
                        this.orderList = res.data.data.elements
                        this.orderList.forEach(v=>{
                            v.remarkPics = JSON.parse((v.remarkPics))
                            v.orderItemVos = []
                            v.orderGiftResVos = []
                            v.draftOrderSkuItemPos.forEach(data=>{
                                if (!data.isGift){
                                    v.orderItemVos.push(data)
                                } else{
                                    v.orderGiftResVos.push(data)
                                }
                            })
                        })
                        this.total = res.data.data.totalCount
                    } else {
                        this.orderList = []
                        this.total = 0
                    }
                }, this.currentPage, 10, (res) => {
                    this.orderList = []
                    this.total = 0
                    this.loading = false
                    this.$message({
                        type: 'error',
                        message: res.data.message,
                        duration: 1000
                    })
                })
            },
            /*删除草稿单*/
            deleteOrder(id,info){
                this.$confirm(info,'提示',{
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    axiosApi('/order/draftOrder/delete',{id: id},res => {
                        this.$message.success('删除成功')
                        this.queryOrderList()
                    },1,10,err => {
                        this.$message.warning(err.data.message);
                    })
                }).catch()
            },
            /*查看草稿单*/
            lookOrder(val){
                let body = {};
                body.storeName = val.buyerName;
                body.accountSource = "1"
                body.sellerId = sessionStorage.traderId
                axiosApi('/trader/findClientAllList/select',body,res=>{
                    let isExist = false;
                    res.data.data&&res.data.data.elements.forEach(item=>{
                        if(item.buyerId==val.buyerId){
                            isExist = true;
                        }
                    })
                    if(!isExist){
                        this.deleteOrder(val.id,'该客户不存在或已删除，是否删除此待提交单？')
                    }else {
                        sessionStorage.setItem('draftOrderInfo',JSON.stringify(val))
                        this.$router.push(basePath + '/newValetOrder?whichPage=3&orderSn='+val.orderSn)
                    }   
                })
            }
        }
    }
</script>

<style lang="less">
    // .el-date-range-picker__editor {
    //     width: 148px;
    // }

    .inline-block {
        display: inline-block;
    }

    #draft-order {
        position: relative;
        box-sizing: border-box;
        .form-set {
            .el-input {
                width: 60px;
            }
        }

        .orderDetail_btn {
            height: auto;
            margin-top: 10px;
        }

        .order_status {
            height: 60px;

            li {
                box-sizing: border-box;
                height: 26px;
                line-height: 26px;
                padding-left: 10px;
                padding-right: 10px;
                list-style-type: none;
                margin-top: 17px;
                margin-right: 10px;
                cursor: pointer;
                user-select: none;
                border-radius: 2px;

                &:hover {
                    color: #ff4201;
                }
            }

            .active {
                border: 1px solid #ff4201;
                color: #ff4201;
            }
        }

        .table_header {
            height: 40px;
            line-height: 40px;
        }

        .bbd {
            border-bottom: 1px solid #ddd
        }

        .ellipsis {
            display: inline-block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .checkedAll {
            position: relative;
            top: 30px;
            width: 70px;
            margin-left: 30px;
        }

        .checked {
            width: 25px;
            margin-left: 30px;

            .el-checkbox__label {
                display: none;
            }
        }

        .bb {
            border-bottom: 1px solid #DDDDDD;
        }

        .bl {
            border-left: 1px solid #DDDDDD;
        }

        .order-datatimerange {
            width: 240px;
        }

        .w-570 {
            width: 528px !important;
            display: flex;
            flex-wrap: wrap;

            > div {
                padding: 20px;
                padding-right: 0;
                width: 100%;
                box-sizing: border-box;
            }
        }

        #notPayCountmark {
            position: absolute;
            left: 167px;
            top: 10px;
        }

        #notDeliveryCount {
            position: absolute;
            left: 248px;
            top: 10px;
        }

        #orderDeliveriedCount {
            position: absolute;
            left: 330px;
            top: 10px;
        }

        #deliverySeparateCount {
            position: absolute;
            left: 417px;
            top: 10px;
        }

        #notAuditCount {
            position: absolute;
            left: 667px;
            top: 10px;
        }

        .hide {
            display: none;
        }

        .content {
            .el-form--inline {
                width: 650px;

                .el-form-item {
                    margin-right: 0 !important;
                }

                .el-form-item__label {
                    font-size: 12px;
                    width: 60px;
                }
            }

            .paymentHistory .el-table .el-table__body-wrapper {
                display: block;
            }

            .el-table .el-table__body-wrapper {
                display: none;
            }

            .table-item {
                border: 1px solid #DDDDDD;
                font-size: 14px;
                border-bottom: none;

                .table-item-content .pbl-20 {
                    padding: 0px 0px 20px 20px;
                }

                .table-item-content {
                    .gift {
                        position: relative;
                    }

                    .triangle-topleft {
                        border-top: 60px solid #ff4201;
                        width: 0;
                        height: 0;
                        border-right: 60px solid transparent;
                        position: absolute;
                        top: 0;
                        left: 0;
                    }

                    .init {
                        transform: rotate(-45deg) translate(-4px, 10px);
                        position: absolute;
                        left: 0;
                        top: 0;
                        color: #fff;
                        letter-spacing: -0.34px;
                    }
                }

                .table-item-top {
                    border-bottom: 1px solid #DDDDDD;
                    line-height: 40px;
                    /*// padding: 10px 0;*/
                    /*// padding-right: 65px;*/
                    position: relative;

                    .mr-65 {
                        margin-right: 65px;
                    }

                    .separate {
                        position: absolute;
                        right: 0;
                        top: 0;
                    }

                    .table-item-top-checkbox {
                        margin-left: 14px
                    }

                    div {
                        width: 260px;
                    }
                }

                img {
                    width: 80px;
                    height: 80px;
                }
            }

            .table-item-default {
                height: 530px;
                padding-top: 120px;
            }
        }

        .cancelOrderDialog {
            .el-dialog__body {
                padding-top: 20px;
                padding-bottom: 0px;
                margin-bottom: 100px;
            }

            .el-dialog {
                min-height: 250px;
            }

            .el-textarea {
                .el-textarea__inner {
                    border-radius: 0;
                }
            }
        }

        .changePriceDialog {
            .el-dialog {
                width: 1000px;
            }

            .el-dialog__body {
                .el-form-item__label {
                    font-size: 12px;
                    width: 215px;
                    margin-right: 20px;
                }

                .el-input__inner {
                    font-size: 12px;
                }

                .ferght {
                    flex-grow: 1;
                }

                .changePriceInfo {
                    padding-bottom: 36px;
                    border: 1px solid #DDDDDD;
                    border-top: none;
                    margin-bottom: 80px;
                }
            }
        }
        .giftPriceClass{
            text-decoration:line-through;
        }
    }
    .copyInfo {
        display:flex;
        align-items: flex-start;
        .copyNumber {
            vertical-align:top;
            display: flex;
            align-items: flex-start;
            width: 260px;
            span {
                display: inline-block;
                vertical-align: top;
            }
            .numberOne {
                width: 70px;
            }
            .numberTwo {
                width: 190px;
                text-align:left;
            }
        }
    }
    .outHouse {
        display: flex;
        align-items: flex-start;
        .outHouseContent{
        }
        .outHouseNumber {
            text-align:left;
            display:inline-block;
            width: 190px;
        }
        .outHouseNumber,.outHouseCopy {
            vertical-align: top;
        }
        .outHouseCopy {
            padding:0;
            display:inline-block;
        }
    }
    .copyText,.outHouseCopy {
        vertical-align:top;
        color:blue;
        display:inline-block;
        cursor: pointer;

    }
    .copyText {
        margin-left:4px;
    }
</style>
