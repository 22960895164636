<template>
    <div id="orderSet" v-loading='loading'>
        <div class="clearfix bg-f">
            <div class="fl title-tabs bg-f black mb-5">
                <ul class="clearfix">
                        <li class="active" >
                            订单设置
                            <div class="under-line"></div>
                        </li>
                        <div class="vertical-line"></div>
                </ul>
            </div>
        </div>
        <div class="content bg-f pb-50 pl-30 pr-30 mt-5 pt-10" style="min-height: 500px">
            <el-form ref="form" class="form-set" :model="formSet" label-width="170px">
                <el-form-item label="订单审核:">
                    <el-switch
                            v-model="formSet.isOpenOrder"
                            @change="orderCheck"
                            active-text="开"
                            inactive-text="关"
                            :active-value="1"
                            :inactive-value="0"
                            active-color="#13ce66"
                            inactive-color="#ccc">
                    </el-switch>
                </el-form-item>
                <el-form-item label="订单自动关闭时间:">
                    <template v-if="setTime">
                        <sy-input type="int" v-model="formSet.cancelTime" size="mini" :min="0" :max="99"></sy-input>
                    </template>
                    <span v-else>{{ formSet.cancelTime }}</span> 小时
                    <el-button class="ml-20" @click="setOrderCloseTime">{{setTime ? '保存' : '修改'}}</el-button>
                </el-form-item>
                <el-form-item label="订单单据类型修改:" v-if="isChildCompany">
                    <el-switch
                            v-model="formSet.isOpenOrderTypeOpt"
                            @change="changeOrderType"
                            active-text="开"
                            inactive-text="关"
                            :active-value="1"
                            :inactive-value="0"
                            active-color="#13ce66"
                            inactive-color="#ccc">
                    </el-switch>
                </el-form-item>
                <el-form-item label="订单改价审核人员:" >
                    <el-button class="ml-20" @click="openAdd">新增</el-button>
                    <div style="margin-top:20px">
                        <span style="color:#F56C6C">*仅可添加两名审核人员</span>
                        <el-table :data="auditEmployeeList" style="width: 500px">
                            <el-table-column prop="employeeName" label="员工姓名" ></el-table-column>
                            <el-table-column prop="mobile" label="手机号" ></el-table-column>
                            <el-table-column label="操作" >
                                <template slot-scope="{row}">
                                    <el-link type="danger" :underline="false" @click="deleteInfoConfirm(row.id)">删除</el-link>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </el-form-item>
                <el-form-item label="是否开启售后申请:">
                    <el-radio v-model="formSet.openAfterSales" label="1">是</el-radio>
                    <el-radio v-model="formSet.openAfterSales" label="2">否</el-radio>
                </el-form-item>
                <el-form-item label="适用客户端:" v-if="formSet.openAfterSales==1">
                    <el-checkbox-group v-model="formSet.checklist">
                        <el-checkbox label="1">云商APP/PC</el-checkbox>
                        <el-checkbox label="2">云销APP</el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
                <el-form-item label="">
                    <el-button @click="updateAfterService">保存</el-button>
                </el-form-item>
            </el-form>

            <el-dialog
                title="添加审核员"
                :visible.sync="dialogAdd"
                width="30%"
                center>
                <div>
                    <span style="color:#F56C6C">*仅可添加两名审核人员</span>
                    <el-table :data="list" style="width: 500px">
                        <el-table-column prop="employeeName" label="员工姓名" ></el-table-column>
                        <el-table-column prop="mobile" label="手机号" ></el-table-column>
                        <el-table-column label="操作" >
                            <template slot-scope="{row}">
                                <span style="color:#C0C4CC" v-if="row.isAdd">已添加</span>
                                <el-link v-else type="primary" :underline="false" @click="addAuditEmployee(row)">添加</el-link>
                            </template>
                        </el-table-column>
                    </el-table>
                    <div style="text-align:right;">
                        <el-pagination
                            small
                            @size-change="sizeChange"
                            @current-change="currentChange"
                            :current-page="pageIndex"
                            :page-size="pageSize"
                            layout="total, prev, pager, next"
                            :total="total">
                        </el-pagination>
                    </div>
                </div>
                <!-- <span slot="footer" class="dialog-footer">
                    <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
                    <el-button @click="dialogVisible = false">取 消</el-button>
                </span> -->
            </el-dialog>
        </div>
    </div>
</template>

<script>
    import {axiosApi, basePath} from "~/api/api"
    import { getEmployeeList } from "~/api/publicInfo";
    import { getAuditEmployee, deleteAuditEmployee, addAuditEmployee } from "~/api/axiosInfo";
    export default {
        name:'order',
        computed: {
            isChildCompany() {
                return this.$store.state.clientType.isChildCompany;
            }
        },
        data() {
            return {
                formSet: {
                    isOpenOrder: 1,
                    cancelTime: '',
                    isOpenOrderTypeOpt:0,//是否开通订单单据类型修改
                    openAfterSales:'2',//开通售后
                    checklist:[],//开启pc 云销
                },
                setTime: false, 
                auditCompetence : {
                    isOpenOrder: null,
                }, //审核权限
                loading: false,
                auditEmployeeList:[],
                // 添加审核人员弹框
                dialogAdd:false,
                list: [],
                total:0,
                pageIndex:1,
                pageSize:10,
               
            }
        },
        mounted() {
            this.$nextTick(() => {
                this.findSellerJurisdiction()
                this.getAuditEmployee()
            })
        },
        methods: {
            //查看是否开通订单审核权限
            findSellerJurisdiction() {
                axiosApi(
                    "/trader/findSellerJurisdiction/select",
                    {
                    traderId: sessionStorage.traderId,
                    },
                    (res) => {
                    this.auditCompetence = res.data.data;
                    let binaryData = this.auditCompetence.aftersalesChannel.split("");
                    this.formSet = {
                        ...this.formSet,
                        openAfterSales: binaryData[0] == 1 ? "1" : "2", //开通售后
                        isOpenOrder: this.auditCompetence.isOpenOrder,
                        isOpenOrderTypeOpt: this.auditCompetence.isOpenOrderTypeOpt ? 1 : 0,
                        cancelTime: this.auditCompetence.cancelTime,
                        freight: this.auditCompetence.freight,
                    };
                    this.formSet.checklist[0] =
                        binaryData[binaryData.length - 2] == 1 ? "1" : "";
                    this.formSet.checklist[1] =
                        binaryData[binaryData.length - 1] == 1 ? "2" : "";
                    },
                    1,
                    10,
                    (res) => {}
                );
            },
            orderCheck(val) {
                axiosApi('/trader/sellerOpenOrder/update', {
                    'traderId': sessionStorage.getItem('traderId'),
                    'isOpenOrder': val
                }, res => {
                    this.$message.success('设置成功')
                }, 1, 100, (err) => {
                    this.$message({
                        title: '失败',
                        message: err.data.message,
                        type: 'info'
                    });
                    this.formSet.isOpenOrder = val == 1 ? 0 : 1
                });
            },

            setOrderCloseTime() {
                if (this.setTime) {
                    axiosApi('/trader/updateOrderCancelTime/update ', {
                        'traderId': sessionStorage.getItem('traderId'),
                        'cancelTime': this.formSet.cancelTime
                    }, (res) => {
                        this.$message({
                            title: '成功',
                            message: '恭喜你，操作成功！',
                            type: 'success'
                        });
                        this.setTime = !this.setTime
                    }, 1, 10, err => {
                        this.$message.error('设置失败')
                        this.this.formSet.cancelTime = null
                    });
                } else {
                    this.setTime = !this.setTime
                }

            },


             //修改订单单据类型
            changeOrderType(val){
                axiosApi('/trader/sellerIsOpenOrderTypeOpt/update',{ traderId:sessionStorage.getItem('traderId'),isOpenOrderTypeOpt:val },res => {
                    this.$message.success('设置成功')
                },1,10,err => {
                    this.$message({
                        title: '失败',
                        message: err.data.message,
                        type: 'info'
                    });
                    this.formSet.isOpenOrderTypeOpt = !val
                })
            },

            //售后设置
            updateAfterService(){
                let aftersalesChannel = '00000000'.split('');
                let len = aftersalesChannel.length;
                if(this.formSet.openAfterSales == 1)  aftersalesChannel.splice(0,1,'1');
                if(this.formSet.checklist.includes('1')) aftersalesChannel.splice(len-1,1,'1');
                if(this.formSet.checklist.includes('2')) aftersalesChannel.splice(len-2,1,'1');
                console.log(aftersalesChannel)
                let body = {
                    "traderId":sessionStorage.getItem('traderId'),
                    "aftersalesChannel":aftersalesChannel.join('')
                }
                axiosApi('/trader/aftersalesChannel/update',body,res => {
                    this.$message({
                        type:'success',
                        message:res.data.message
                    })
                },1,1,err => {
                    this.$message({
                        type:'error',
                        message:err.data.message
                    })
                })
            },
            // 获取审核人员列表
            getAuditEmployee(){
                getAuditEmployee().then(res=>{
                    this.auditEmployeeList = res
                }).catch(err=>{

                })
            },
            // 打开新增审核人员弹框
            openAdd(){
                if(this.auditEmployeeList&&this.auditEmployeeList.length===2){
                    this.$message({
                        message: '最多增加两名审核人员,请删除后再新增！',
                        type: 'warning'
                    });
                    return
                }
                this.getEmployeeList()
            },
            addAuditEmployee(row){
                addAuditEmployee({employeeId:row.id,employeeName:row.employeeName}).then(res=>{
                    this.getAuditEmployee()
                    this.dialogAdd = false
                }).catch(err=>{

                })
            },
            deleteInfo(id){
                deleteAuditEmployee({id:id}).then(res=>{
                    this.$message({
                        type: 'success',
                        message: '删除成功!'
                    });
                    this.getAuditEmployee()
                }).catch(err=>{

                })

            },
            deleteInfoConfirm(id){
                this.$confirm('是否删除该审核人员审核权限！', "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                })
                .then(() => {
                    this.deleteInfo(id)
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });   
                });
            },
            getEmployeeList(){
                var data = {
                    traderId: sessionStorage.traderId,
                    isDeleted: 0,
					isDisable: 0,
                    pageIndex:this.pageIndex,
                    pageSize:this.pageSize
                }
                getEmployeeList(data).then(res=>{
                    var list = res.elements
                    if(this.auditEmployeeList!=null&&this.auditEmployeeList.length>0){
                        list.map(item=>{
                            this.auditEmployeeList.forEach(items=>{
                                if(item.id===items.employeeId){
                                    item.isAdd = true
                                }
                            })
                            return item
                        })
                    }
                    this.list = list
                    this.total = res.totalCount
                    this.dialogAdd = true
                }).catch(err=>{

                })
            },
            sizeChange(val){
                this.pageSize = val
                this.getEmployeeList()
            },
            currentChange(val){
                this.pageIndex = val
                this.getEmployeeList()
            },
        }
    }
</script>

<style lang="less">
    #orderSet {
        position: relative;
        box-sizing: border-box;
        .form-set {
            .el-input {
                width: 60px;
            }
        }
        .el-dialog__footer {
            padding: 10px 20px 20px;
            box-sizing: border-box;
            position: static;
            display: block;
            transform: none;
        }

        .orderDetail_btn {
            height: auto;
            margin-top: 10px;
        }


        .table_header {
            height: 40px;
            line-height: 40px;
        }

        .bbd {
            border-bottom: 1px solid #ECEEF6
        }



        

        .bb {
            border-bottom: 1px solid #ECEEF6;
        }

        .bl {
            border-left: 1px solid #ECEEF6;
        }

        .order-datatimerange {
            width: 240px;
        }

        .w-570 {
            width: 528px !important;
            display: flex;
            flex-wrap: wrap;

            > div {
                padding: 20px;
                padding-right: 0;
                width: 100%;
                box-sizing: border-box;
            }
        }
        .content {
            .el-form--inline {

                .el-form-item {

                }

                .el-form-item__label {
                    font-size: 12px;
                    width: 60px;
                }
            }

            .table-item {
                border: 1px solid #ECEEF6;
                font-size: 14px;
                border-bottom: none;

                .table-item-content .pbl-20 {
                    padding: 0px 0px 20px 20px;
                }

                .table-item-content {
                    .gift {
                        position: relative;
                    }

                    .triangle-topleft {
                        border-top: 60px solid #ff4201;
                        width: 0;
                        height: 0;
                        border-right: 60px solid transparent;
                        position: absolute;
                        top: 0;
                        left: 0;
                    }

                    .init {
                        transform: rotate(-45deg) translate(-4px, 10px);
                        position: absolute;
                        left: 0;
                        top: 0;
                        color: #fff;
                        letter-spacing: -0.34px;
                    }
                }

                .table-item-top {
                    border-bottom: 1px solid #ECEEF6;
                    line-height: 40px;
                    /*// padding: 10px 0;*/
                    /*// padding-right: 65px;*/
                    position: relative;

                    .mr-65 {
                        margin-right: 65px;
                    }

                    .separate {
                        position: absolute;
                        right: 0;
                        top: 0;
                    }

                    .table-item-top-checkbox {
                        margin-left: 14px
                    }

                    div {
                        width: 260px;
                    }
                }

                img {
                    width: 80px;
                    height: 80px;
                }
            }

            .table-item-default {
                height: 530px;
                padding-top: 120px;
            }
        }
    }
</style>
