<template>
  <div id="invoice-dialog">
    <el-dialog
      title="开具发票"
      :visible.sync="dialogVisible"
      size=""
      @close="goback"
      width="500px"
      >
      <div class="items" :style="{'margin-bottom':'80px'}">
        <div>发票类型: {{info.type == '1' ? '增值税普通发票' : '增值税专用发票'}}</div>
        <div>发票金额: ¥{{String(info.needPayMoney - yunfei).indexOf('.') > -1 ? (info.needPayMoney - yunfei).toFixed(2) : (info.needPayMoney - yunfei)}}</div>
        <div >发票抬头：{{info.companyHead}}</div>
        <div v-if="info.personalCompanyType == '1'">纳税人识别号: {{info.taxpayerIdNo}}</div>
        <div >收货人：{{info.linkman}}</div>
        <div >地址：{{info.provinceName + '' + info.cityName + '' + info.regionName + '' + info.addr}}</div>
        <div >电话号码：{{info.tel}}</div>
        <div v-if="info.remarks">备注：{{ info.remarks }}</div>
        <!--<div>发票类型: {{info.type == '1' ? '普通发票' : '增值税发票'}}</div>
        <div v-if="info.type == '1'">发票抬头：{{info.companyHead}}</div>
        <div v-if="info.type == '2'">公司名称: {{info.companyHead}}</div>
        <div>纳税人识别号: {{info.taxpayerIdNo}}</div>
        <div v-if="info.type == '2'">注册地址：{{info.registerAddress}}</div>
        <div v-if="info.type == '2'">注册电话：{{info.registerPhone}}</div>
        <div v-if="info.type == '2'">开户银行：{{info.bankDeposit}}</div>
        <div v-if="info.type == '2'">银行账户：{{info.bankCard}}</div>
        &lt;!&ndash; <div>发票内容：明细</div> &ndash;&gt;
        <div>发票金额: ¥{{info.needPayMoney - yunfei}}</div>-->
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="writeinvoice()">确认开票</el-button>
        <el-button @click="goback">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import { axiosApi,basePath } from '../api/api' //导入axiosApi
  export default {
    props: ["info", "visible", 'sn', 'yunfei'],
    data() {
      return {
        dialogVisible: false
      }
    },
    watch: {
      visible(val) {
        this.dialogVisible = val
      }
    },
    methods: {
      writeinvoice() {
        const api = "/order/invoice/makeout"
        axiosApi(api, {orderSn: this.sn}, ()=>{
          this.$notify({
            type: 'success',
            message: '开具发票成功'
          })
          this.goback()
          this.$emit('reload')
        },0,10,(res)=>{
          this.$notify({
            type: 'warning',
            message: res.data.message
          })
        })
      },
      goback() {
        this.$emit('close')
      }
    }
  }
</script>

<style lang="less" scoped>
  .items {
      width: 360px;
      margin: 0 70px;
      &>div {
        margin-top: 12px;
      }
    }
</style>
