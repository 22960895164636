<template>
  <div class="hotel-report">
    <messageTitle :type="4" class="bg-f"/>
    <div class="title-top bg-f clearfix">
      <div>
        <el-button class="fr mr-30 mt-14" icon="el-icon-plus" @click="addNewOne">添加活动</el-button>
      </div>
    </div>

    <div class="content bg-f p-30">
      <el-table :data="tableData" stripe empty-text="暂时没添加活动哦">
        <el-table-column label="序号" width="60">
          <template slot-scope="scope">
            <span>{{( currentPage - 1 ) * 10 + scope.$index + 1}}</span>
          </template>
        </el-table-column>
        <el-table-column label="活动标题" width="140">
          <template slot-scope="scope">
            <span>{{scope.row.title}}</span>
          </template>
        </el-table-column>
        <el-table-column label="活动内容" width="">
          <template slot-scope="scope">
            <span>{{scope.row.detail}}</span>
          </template>
        </el-table-column>
        <el-table-column label="业务" width="120">
          <template slot-scope="scope">
            <span>{{scope.row.concreteBusinessType === 'b2b_normal' ? 'B2B' : scope.row.concreteBusinessType === 'superc_society' ? '社会久集' : scope.row.concreteBusinessType === 'superc_hotel' ? '酒店久集' : scope.row.concreteBusinessType === 'superc_tenantshop' ? '门店久集' : scope.row.concreteBusinessType === 'groupon' ? '超团' : scope.row.concreteBusinessType === 'b2b_hotel' ? '酒店云商' : '' }}</span>
          </template>
        </el-table-column>
        <el-table-column label="活动时间" width="160">
          <template slot-scope="scope">
            <span>{{scope.row.startDate}} - {{scope.row.endDate}}</span>
          </template>
        </el-table-column>
        <el-table-column label="赠品信息" width="160">
          <template slot-scope="scope">
            <span>{{scope.row.giftDescription ? scope.row.giftDescription : '-'}}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button @click="delIt(scope.row)" type="text" width="80">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页组件 -->
      <el-pagination
        class="tar"
        v-if="tableData.length>0"
        :current-page="currentPage"
        :page-size="10"
        :total="total"
        layout="prev , pager, next, jumper"
        @current-change="handleCurrentChange"
      ></el-pagination>
    </div>

    <el-dialog title="添加活动" :visible.sync="addDialog" class="add_dia_ele">
      <el-form :model="formData" :rules="rules" ref="formData" label-width="100px">
        <el-form-item label="活动标题:" prop="titleName">
          <el-input v-model.trim="formData.titleName" placeholder="输入10个字符以内" :maxlength="10"></el-input>
        </el-form-item>
        <el-form-item label="活动内容:" prop="contentName">
          <el-input class="areaIpt" type="textarea" :rows="6" placeholder="输入100个字符以内" :maxlength="100" v-model.trim="formData.contentName"></el-input>
        </el-form-item>
        <el-form-item label="业务:" prop="concreteBusinessType">
          <el-select v-model="formData.concreteBusinessType" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label='活动时间' prop="orderDateRange">
          <el-date-picker
            v-model="formData.orderDateRange"
            type="daterange"
            :picker-options="pickerOptions"
            start-placeholder="选择开始时间" end-placeholder="选择结束时间" range-placeholder="-">
          </el-date-picker>
        </el-form-item>

        <el-form-item label="赠品">
          <el-switch
            v-model="hasGift"
            active-text=""
            inactive-text=""
            active-color="#13ce66">
          </el-switch>
        </el-form-item>
        <div v-if="hasGift">
          <el-form-item label="推送条件">
            <el-input value="首单" disabled></el-input>
          </el-form-item>
          <el-form-item label="赠品信息" prop="giftDescription">
            <el-input class="areaIpt" type="textarea" :rows="3" placeholder="输入30个字符以内" :maxlength="30" v-model.trim="formData.giftDescription"></el-input>
          </el-form-item>
        </div>

      </el-form>
      <div slot="footer">
        <el-button @click="addDialog = false">取消</el-button>
        <el-button type="danger" @click="submit" :loading="btnLoading">提交</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { axiosApi, basePath } from "../../../api/api";
import messageTitle from '../../supplier/messagePush/messageTitle.vue';
export default {
  components : {messageTitle},
  data() {
    var checkTime = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请选择活动时间！'))
      } else if(!value[0]) {
        return callback(new Error('请选择活动时间！'))
      } else {
        callback()
      }
    }
    return {
      tableData: [],
      currentPage: 1,
      total: 0,
      addDialog: false,
      hasGift: false,
      formData: {
        giftDescription: '',
        titleName: '',
        contentName: '',
        orderDateRange: '',
        concreteBusinessType: 'superc_hotel' // b2b_normal, b2b_hotel, superc_society, superc_hotel, superc_tenantshop, group
      },
      rules: {
        titleName: [{
          required: true,
          message: '输入10个字符以内的标题'
        }],
        contentName: [{
          required: true,
          message: '输入100个字符以内的内容'
        }],
        concreteBusinessType: [
          {required: true,
          message: '请选择业务类型'}
        ],
        orderDateRange: [{
          required: true,
          message: '请选择活动时间！'
        }, {
          validator: checkTime
        },],
        giftDescription: [
          {
            required: true,
            message: '请填写赠品信息'
          },
          { min: 5, max: 30, message: '长度在5到30个字符', trigger: 'blur, change' }
        ]
      },
      btnLoading: false,
      pickerOptions:{
        disabledDate(time) {
          if(time.getTime() <= new Date().getTime() - 24* 60 * 60 * 1000){
            return true
          }
          return false
        }
      },
      options: [
        // {
        //   label: 'B2B',
        //   value: 'b2b_normal'
        // },
        {
          label: '社会久集',
          value: 'superc_society'
        },
        {
          label: '酒店久集',
          value: 'superc_hotel'
        },
        {
          label: '门店久集',
          value: 'superc_tenantshop'
        }
      ]
    };
  },
  methods: {
    achieve(val) {
      if (val && this.currentPage != 1) {
        this.currentPage = 1
        return
      }
      axiosApi('/foundation/notice/pageList', {
        sellerId: sessionStorage.traderId
      }, res => {
        this.tableData = res.data.data.elements
        this.total = res.data.data.totalCount
        this.currentPage = res.data.data.pageIndex
      }, this.currentPage, 10, err => {
        this.$message({
          type: 'error',
          message: err.data.message,
          duration: 2000
        })
      })
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.achieve()
    },
    addNewOne() {
      this.hasGift = false
      this.formData = {
        titleName: '',
        contentName: '',
        orderDateRange: '',
        concreteBusinessType: 'superc_hotel',
        giftDescription: ''
      }
      if (this.$refs.formData) {
        this.$refs.formData.resetFields()
      }
      this.addDialog = true
    },
    delIt(val) {
      let h = this.$createElement;
      this.$msgbox({
        title: '提示信息',
        message: h('p', {
          style: 'text-align:center'
        }, '是否确定删除?'),
        showCancelButton: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        beforeClose: (action, instance, done) => {
          if(action === 'confirm') {
            axiosApi('/foundation/notice/delete', {
              id: val.id
            }, res => {
              this.achieve(true)
              this.$message({
                type: 'success',
                message: '删除成功！',
                duration: 1500
              })
            })
            done()
          } else {
            done()
          }
          delete instance.$slots.default;
        }
      })
    },
    getYMD(time) {
      let y = time.getFullYear()
      let m = time.getMonth() + 1
      let d = time.getDate()
      m = m < 10 ? '0' + m : m
      d = d < 10 ? '0' + d : d
      return y + '-' + m + '-' + d
    },
    submit() {
      this.$refs.formData.validate(val => {
        if (val) {
          this.btnLoading = true
          let a = this.formData.orderDateRange[0]
          let b = this.formData.orderDateRange[1]
          a && (a = this.getYMD(a))
          b && (b = this.getYMD(b))
          axiosApi('/foundation/notice/add', {
            sellerId: sessionStorage.traderId,
            title: this.formData.titleName,
            detail: this.formData.contentName,
            startDate: a,
            endDate: b,
            concreteBusinessType: this.formData.concreteBusinessType,
            isWithGift: this.hasGift ? 1 : 0,
            giftRule: 1,
            giftDescription: this.hasGift ? this.formData.giftDescription : null
          }, res => {
            this.btnLoading = false
            this.addDialog = false
            this.$message({
              type: 'success',
              message: '新增活动成功！',
              duration: 1000
            })
            this.achieve(true)
          },1,10, err => {
            this.btnLoading = false
            this.$message({
              type: 'error',
              message: err.data.message,
              duration: 1500
            })
          })
        }
      })
    }
  },
  created() {
    this.achieve()
  }
};
</script>

<style lang="less">
.hotel-report {
  .content {
    .el-table .cell {
      text-align: center;
      padding: 10px 0;
    }
  }
  .add_dia_ele {
    .el-dialog {
      .el-dialog__body {
        padding: 30px 30px 100px 30px;
        .el-input__inner {
          font-size: 12px;
          height:40px !important;
        }
        .areaIpt {
          word-break: break-all;
        }
      }
    }
  }
}
</style>
