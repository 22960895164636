import { axiosApi, basePath } from '../../api/api.js'
import {Notification} from 'element-ui'
import m from '../../main.js'

const setPrice = {
	state: {
		dialogVisiable: false,// 设价弹框
		setPriceForm:{// 设价组件table
			tableData:[{
				skuNo:'84932859',// 商品编码
				skuName:'五粮液5年醇香500ML',// 商品名称
				num:1,// 数量
				price:null,// 价格
				unit:'瓶',// 单位
				format:'10',// 规格
				id:'1',// key
			},{
				skuNo:'84932859',// 商品编码
				skuName:'五粮液5年醇香500ML',// 商品名称
				num:1,// 数量
				price:null,// 价格
				unit:'瓶',// 单位
				format:'10',// 规格
				id:'1',// key
			}]
		}
	},
	mutations: {
		switchDialog(state, payload){// 切换弹框显隐
			state.dialogVisiable = payload
		},
		changeSetPriceForm(state, payload){// 改变设价组件值
			state.setPriceForm = payload
		}
	},
	actions: {
		
	},
	getters: {
		
	}
}

export default setPrice