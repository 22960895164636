<template>
  <div id="groupProduct">
			<div class='bg-f'>
				<div class="ml-30 mr-30 second-title clearfix">
					<div class="choose-three font-14 color-6 fl tac mr-10 cursor " :class="first == 0?'actived':''" @click="getStatus(0)">
						全部商品({{count1}})
					</div>
					<div class="choose-three font-14 color-6 tac fl mr-10 cursor" :class="first == 2?'actived':''" @click="getStatus(2)">
						出售中({{count2}})
					</div>
					<div class="choose-three font-14 color-6 tac fl cursor" :class="first == 1?'actived':''" @click="getStatus(1)">
						待上架({{count3}})
					</div>
					<div v-if='openGoods == 1' class="choose-three font-14 color-6 tac fl cursor" :class="first == 3?'actived':''" @click="getStatus(3)">
						待审核({{count4}})
					</div>
					<div v-if='openGoods == 1' class="choose-three font-14 color-6 tac fl cursor" :class="first == 4?'actived':''" @click="getStatus(4)">
						审核未通过({{count5}})
					</div>
				</div>
			</div>
			<div class="bg-f pb-50 pl-30 pr-30">
				<el-form :inline="true" :model="formInlineDataPlatform" label-width="90px" class="clearfix pt-30 search">
					<div>
						<el-form-item label="商品编码">
							<el-input  v-model.trim="formInlineDataPlatform.goodsNo" @input="formInlineDataPlatform.goodsNo=formInlineDataPlatform.goodsNo.replace(/[\u4e00-\u9fa5]/ig,'')"></el-input>
						</el-form-item>
						<el-form-item label="商品名称">
							<el-input v-model.trim="formInlineDataPlatform.skuName"></el-input>
						</el-form-item>
						<el-form-item label="商品分类">
							<el-select v-model.trim="formInlineDataPlatform.classifyId" clearable>
								<el-option :label='item.classifyName' :value="item.id" v-for='item in searchClassify' :key="item.id"></el-option>
							</el-select>
						</el-form-item>
            <el-form-item label="特殊商品">
							<el-select v-model.trim="formInlineDataPlatform.storeType" clearable>
								<el-option :label='item.name' :value="item.id" v-for='item in storeTypeList' :key="item.id"></el-option>
							</el-select>
						</el-form-item>
					</div>
					<div>
						<el-button type="info" class="fl ml-30" @click='searchList' icon="el-icon-search">搜 索</el-button>
					</div>
					
				</el-form>
				<div class="clearfix mr-50 pr-10">
					<span v-if='lower ' class="fr color-blue ml-10 cursor" @click='batchList(2)'>批量下架</span>
					<span v-if='up ' class="fr color-blue ml-10 cursor" @click='batchList(1)'>批量上架</span>
				</div>
				<el-table :data="tableData" v-loading="Loading" class='mt-10 table-default' @selection-change="handleSelectionChangePlatform">
					<el-table-column type="selection" min-width="50"></el-table-column>
					<el-table-column prop="1" label="商品名称" min-width="262">
						<template slot-scope="scope">
							<div class="fl ml-10" style="position: relative;">
								<img v-if='scope.row.fileUrl' :src="scope.row.fileUrl" />
								<img v-else src="../../../assets/goods.jpg" />
								<img v-show='scope.row.bizType == 1' src="../../../assets/bind.png" style="position: absolute;left:0px;top:0" />
							</div>
							<div class="fl ml-10 mt-10">
								<div class="tal w-160">商品编码:{{scope.row.goodsNo}}</div>
								<div class="tal w-160">{{scope.row.skuName}}</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column label="是否特殊商品" min-width="70">
						<template slot-scope="scope">
							{{scope.row.storeType===1?'否':'是'}}
						</template>
					</el-table-column>
					<el-table-column label="规格" min-width="70">
						<template slot-scope="scope">
							<div>1*{{ scope.row.specInfoNum }}</div>
						</template>
					</el-table-column>
					<el-table-column label="商品分类" mi-width="70" prop='classifyName'></el-table-column>
					<el-table-column label="库存" min-width="70" >
            <template slot-scope="scope">
							<div>{{scope.row.stockEnableQuantity}}{{ scope.row.unitName }}</div>
						</template>
          </el-table-column>
					<el-table-column v-if='identity == "central"' label="虚拟库存" min-width="70">
						<template slot-scope="scope">
							<div>{{scope.row.virtualStock ? scope.row.virtualStock : 0}}{{ scope.row.unitName }}</div>
						</template>
					</el-table-column>
					<el-table-column label="进店价" min-width="68">
						<template slot-scope="scope">
							<div v-if='identity != "big"'>￥{{scope.row.enterShopPrice || 0}}/{{ scope.row.unitName }}</div>
							<div v-else>--</div>
						</template>
					</el-table-column>
					<el-table-column label="团购价" min-width="68">
						<template slot-scope="scope">
							<div>￥{{scope.row.grouponPrice}}/{{ scope.row.unitName }}</div>
						</template>
					</el-table-column>
					<el-table-column label="销量" min-width="70">
            <template slot-scope="scope">
							<div>{{scope.row.sales}}{{ scope.row.unitName }}</div>
						</template>
          </el-table-column>
					<el-table-column label="状态" min-width="70">
						<template slot-scope="scope">
							<span v-if="scope.row.auditState == 1">待审核</span>
							<span v-else-if="scope.row.goodsState == 1">待上架</span>
							<span v-else-if="scope.row.auditState == 2 && scope.row.goodsState == 2">出售中</span>
							<span v-else-if="scope.row.auditState == 3">审核未通过</span>

							<span v-else>-</span>
						</template>
					</el-table-column>
					<el-table-column label="操作" min-width="150">
						<template slot-scope="scope">
              <div>
                <el-button class='ml-0 tal' size="small" type='text' @click="handleMinimunMoq(scope)">设置</el-button>
                <el-button v-if="scope.row.auditState===2&&scope.row.goodsState===2" class='ml-0 tal' size="small" type='text' @click="upperlower(scope.row.skuNo, 1)">下架</el-button>
                <el-button  v-if="scope.row.auditState===2&&scope.row.goodsState===1" class='ml-0 tal' size="small" type='text' @click="upperlower(scope.row.skuNo, 2)">上架</el-button>
                <router-link :to="`${basePath}/groupDetails/${scope.row.skuNo}/${scope.row.goodsType}/2`">
									<el-button  class='ml-0 tal' size="small" type='text'>查看</el-button>
								</router-link>
                <router-link v-if='scope.row.auditState===3||scope.row.goodsState===1' :to="`${basePath}/groupDetails/${scope.row.skuNo}/${scope.row.goodsType}/0`">
									<el-button class='ml-0 tal' size="small" type='text'>编辑</el-button>
								</router-link>
                <el-button v-if='scope.row.auditState===1' class='ml-0 tal' size="small" type='text' @click="handleCheck(scope)">审核</el-button>
              </div>
						</template>
					</el-table-column>
				</el-table>
				<el-pagination class="tar mr-30" v-if="totalPlatform>0" :current-page="currentPagePlatform" :page-size='10' :total='totalPlatform' layout="prev , pager, next, jumper" @current-change="handleCurrentChangePlatform">
					<!-- 分页组件 -->
				</el-pagination>
				<!--运费弹窗-->
				<el-dialog class="minMop" title="设置" :visible.sync='minMop'>
					<el-form ref="minMopForm" class="pr-10" :model="minMopForm">
						<el-form-item label="运费设置:" class="ml-20 fare-item" required prop="fareId">
							<el-select v-model="minMopForm.fareId" size="medium" placeholder="默认模板">
								<el-option v-for="(item, index) in fareIdList" :label="item.name" :value="item.id" :key="index"></el-option>
							</el-select>
							<el-button v-if='minMopForm.fareId && minMopForm.fareId != ""' type="text" @click="lookTemplate">查看</el-button>
						</el-form-item>
					</el-form>
					<div slot='footer' class="dialog-footer">
						<el-button type='danger' @click="handleSave">保存</el-button>
					</div>
				</el-dialog>
			</div>
		</div>
</template>

<script>
import { axiosApi, basePath } from "~/api/api";
export default {
  data(){
    return {
      count1: 0,
      count2: 0,
      count3: 0,
      count4: 0,
      count5: 0,
      Loading: false,
      storeTypeList: [
        {name: '否', id: 1},
        {name: '是', id: 2}
      ],
      formInlineDataPlatform: {
        sellerId: sessionStorage.traderId,
        goodsNo: null, // 商品编码
        skuName: null, // 商品名称
        classifyId: null, // 商品分类
        goodsState: null, // 订单状态
        AuditState: null, // 审核状态
      },
      currentPagePlatform:1,
      searchClassify: [],
      tableData: [],
      totalPlatform: {},
      first: '0',
      lower: false,
      up: false,
      openGoods: 1,
      identity: '',
      minMop: false,
      minMopForm: {
        fareId: null,
      },
      skuNo: null,
      fareIdList: [],
    }
  },
  mounted(){
    this.isShowLogin()
    this.classSearch()
    this.getList()
  },
  methods: {
    getList(){
      this.Loading = true;
      axiosApi('/groupon/grouponGoods/list', this.formInlineDataPlatform, (res) => {
        if(res.data.data){
          this.tableData = res.data.data.elements ? res.data.data.elements : [];
          this.totalPlatform = res.data.data.totalCount ? res.data.data.totalCount : '';
          this.getCount()
          this.Loading = false;
          if(this.tableData.length > 0) {
            this.openGoods = this.tableData[0].isOpenGoods
          }
        }else{
          this.Loading = false;
        }
      }, this.currentPagePlatform, 10,err=>{
          this.Loading = false;
      })
    },
    //角标
    getCount() {
      const api = "/groupon/storeAuditCount/select";
      const body = {
        sellerType: 1,
        sellerId: sessionStorage.traderId
      }
      axiosApi(api, body, (res) => {
        this.count1 = res.data.data.totalCount ? res.data.data.totalCount : 0; //全部的数量
        this.count2 = res.data.data.upperGoodsCount ? res.data.data.upperGoodsCount : 0; //出售中状态的数量
        this.count5 = res.data.data.noPassAuditStateCount ? res.data.data.noPassAuditStateCount : 0; //未通过审核状态的数量
        this.count3 = res.data.data.lowerGoodsCount ? res.data.data.lowerGoodsCount : 0; //待上架
        this.count4 = res.data.data.todoAuditStateCount ? res.data.data.todoAuditStateCount : 0; //待审核
      }, 1, 10)
    },
    //查询分类
    classSearch() {
      const api = "/unlogin/groupon/classify/selectList";
      const body = {}
      axiosApi(api, body, (res)=>{
        this.searchClassify = res.data.data;
      })
    },
    

    getStatus(type) {
				this.first = type
				this.currentPagePlatform = 1
				if(this.first == '0') {
						this.formInlineDataPlatform.goodsState = null,
						this.formInlineDataPlatform.AuditState = null,
						this.up = false,
						this.lower = false
				}
				if(this.first == '1') {
						this.formInlineDataPlatform.goodsState = '1',
						this.formInlineDataPlatform.AuditState = '2',
					  this.up = false,
						this.lower = true
				}
				if(this.first == '2') {
						this.formInlineDataPlatform.goodsState = '2',
						this.formInlineDataPlatform.AuditState = null,
					  this.up = true,
						this.lower = false
				}
				if(this.first == '3') {
            this.formInlineDataPlatform.goodsState = null,
            this.formInlineDataPlatform.AuditState = '1',
            this.up = false,
            this.lower = false
				}
				if(this.first == '4') {
            this.formInlineDataPlatform.goodsState = null,
            this.formInlineDataPlatform.AuditState = '3',
            this.up = false,
            this.lower = false
				}
        this.getList()
		},
    // 搜索
    searchList(){
      this.currentPagePlatform = 1
      this.getList()
    },
    // 分页
    handleCurrentChangePlatform(val){
      this.currentPagePlatform = val
       this.getList()
    },
    // 多选选中
    handleSelectionChangePlatform(val) { 
      this.multipleSelectionPlatform = val
    },

    // 单个上下架
    upperlower(skuNo,goodsState){
       this.batchUpdate([skuNo],goodsState)
    },
    // 批量上下架
    batchList(goodsState){
      var skuNos = this.multipleSelectionPlatform.map(item=>{
        return item.skuNo
      })
      this.batchUpdate(skuNos,goodsState)
    },
    // 上下架接口
    batchUpdate(skuNos,goodsState){
      var that = this
      const body = {
        skuNos: skuNos, // sku编号
        goodsState: goodsState, // 上下架状态
        sellerId: sessionStorage.traderId
      };
      axiosApi('/groupon/bigStoreState/batchUpdate', body, (res) => {
        if(res.data.code="000000"){
          that.$message({
            type: 'success',
            message: '操作成功!',
            duration: 1000
          });
          that.searchList()
          that.getCount()
        }
      });
    },
   //设置运费
    handleMinimunMoq(scope) {
      this.skuNo = scope.row.skuNo
      this.getFareIdList(scope.row.skuNo)
      this.minMop = true
    },
    getFareIdList(val) { //运费模版列表
      axiosApi('/foundation/fare/templateSelectList', {
        traderId: sessionStorage.traderId,
        noDetails: true,
        businessType: "groupon"
      }, res => {
        this.relationSelect(val)
        this.fareIdList = res.data.data.elements.map(item => {
          return {
            id: item.isDefault == 1 ? item.id : item.id,
            name: item.isDefault == 1 ? item.name + ' (默认模板)' : item.name,
            isDefault: item.isDefault,
            copyId: item.id
          }
        })
        res.data.data.elements.forEach(item => {
          if(item.isDefault == 1) {
            this.isDefaultId = item.id
          }

        })
      }, 1, 100)
    },
    //查询单个商品的运费模板
    relationSelect(val) {
      axiosApi('/foundation/fare/relationSelect', {
        skuNo: val,
        businessType: "groupon",
        relationType: "goods"
      }, (res) => {
        this.minMopForm.fareId = res.data.data ? res.data.data.fareId : this.isDefaultId
      }, 1, 10, (res) => {
        this.$message.info(res.data.message);
      });
    },
    lookTemplate() {
      //因为默认都是经过改造的，不存在ID
      if(this.fareIdList && this.fareIdList.length === 0) {
        return false;
      }
      let defaultList = this.fareIdList.filter(item => {
        return item.id == '' && item.isDefault == 1;
      })
      let id = null;
      if(this.minMopForm.fareId == '') {
        id = defaultList[0].copyId
      } else {
        id = this.minMopForm.fareId
      }
      window.open(basePath + '/freightHandle?type=1&id=' + id, '_blank')
    },
    handleSave() {
      if(!this.minMopForm.fareId) {
        return false;
      }
      axiosApi('/foundation/fare/relationUpdate', {
        fareId: this.minMopForm.fareId,
        skuNo: this.skuNo,
        businessType: 'groupon',
        relationType: "goods"
      }, res => {
        this.minMop = false
        this.$message({
          message: '默认模板设置成功！',
          type: 'success',
          duration: 2000
        })

      }, 1, 10, err => {
        this.$message.error(err.data.message)
      })

    },
    //审核
    handleCheck(scope) {
      this.$router.push(basePath + "/groupDetails/" + scope.row.skuNo + '/' + scope.row.goodsType + '/' + 1);
    },
    isShowLogin() {
				axiosApi('/groupon/sellerType/select', {
					id: sessionStorage.traderId
				}, (res) => {
					this.identity = res.data.data
				}, 1, 10, (res) => {
					this.$message.info(res.data.message);
				});
			},
  },
}
</script>

<style lang="less" scoped>


</style>