<template>
    <div id="reportType">
        <div class="title-top bg-f mb-5">
            报备类型设置
            <el-button v-if='showButton("ADD",200023)' class="fr mt-14 mr-30" @click="dialogType=true">新增</el-button>
        </div>

        <div class="main bg-f pb-50 pt-30 pl-30 pr-30">
            <el-table :data="tableData" stripe tooltip-effect="dark" class="table-default" width="" v-loading="loadingtable">
                <el-table-column prop="taskTypeName" label="类型名称" width="110">
                </el-table-column>
                <el-table-column prop="taskTypeDescription" label="类型描述" width="140">
                </el-table-column>
                <el-table-column prop="selectNames" label="配置项" width="480">
                </el-table-column>
                <el-table-column label="操作" >
                    <template slot-scope="scope">
                        <div v-if='showButton("EDIT",200023) || showButton("DEL",200023)'>
                            <el-button v-if='showButton("EDIT",200023)' @click="editRow(scope.$index, tableData)" type="text" size="small" >
                            编辑
                            </el-button>
                            <el-button v-if='showButton("DEL",200023)' @click.native.prevent="deleteRow(scope.$index, tableData)" type="text" size="small">
                                删除
                            </el-button>
                        </div>
                        <div v-else>-</div>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                class="tar mt-20 mr-50"
                :current-page="currentPage"
                :page-size='10'
                :total='total'
                layout="prev , pager, next, jumper"
                v-if="total>0"
                @current-change="handlePageChange"
                >
                    <!-- 分页组件 -->
            </el-pagination>
            <div class="add">
                <el-dialog
                    title="新增报备类型"
                    :visible.sync="dialogType"
                    size="small">
                    <div class="box">
                        <el-form :model="postData1" label-width="90px" class="clearfix pl-20">
                            <el-form-item label="类型名称" prop="taskTypeName" required>
                                <el-input placeholder="请输入名称" class="w240" v-model.trim="postData1.taskTypeName" :maxlength="15"></el-input>
                            </el-form-item>
                            <el-form-item label="类型描述" prop="taskTypeDescription" required>
                                <el-input placeholder="请输入描述" class="w240" v-model.trim="postData1.taskTypeDescription" :maxlength="20"></el-input>
                            </el-form-item>
                            <el-form-item label="设置选项">
                                <el-button class="color-danger" @click="addadd(1)">新增</el-button>
                            </el-form-item>
                            <div class="optionlist">
                                <div class="" v-for="(item,index) in postData1.taskTypeSelectList1" :key="index">
                                    <span class="redrequired ml-10">*</span><span>类型</span>
                                    <el-select v-model="item.selectType" placeholder="请选择" size="mini">
                                        <el-option
                                        v-for="item in options"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                        </el-option>
                                    </el-select>
                                    <span class="redrequired">*</span><span>选项名</span>
                                    <el-input v-model.trim="item.selectName" placeholder="请输入选项名" size="small" :maxlength="10"></el-input>
                                    <span class="ml-10">描述</span>
                                    <el-input v-model.trim="item.selectDescribe" placeholder="请输入描述" size="small" :maxlength="20"></el-input>
                                    <span v-if="item.selectType != 3" class="redrequired ml-10">*</span><span v-if="item.selectType != 3" >长度/张数</span>
                                    <sy-input v-if="item.selectType != 3" type="int" size="mini" :min="1" :max="99" v-model="item.selectLength"></sy-input>
                                    <div class="delete" @click="adddelete(1, index)"><div></div></div>
                                </div>
                            </div>
                            <el-form-item label="执行选项">
                                <el-button class="color-danger" @click="addadd(2)">新增</el-button>
                            </el-form-item>
                            <div class="optionlist">
                                <div class="" v-for="(item, index) in postData1.taskTypeSelectList2" :key="index">
                                    <span class="redrequired ml-10">*</span><span>类型</span>
                                    <el-select v-model="item.selectType" placeholder="请选择" size="mini">
                                        <el-option
                                        v-for="item in options"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                        </el-option>
                                    </el-select>
                                    <span class="redrequired">*</span><span>选项名</span>
                                    <el-input v-model.trim="item.selectName" placeholder="请输入选项名" size="small" :maxlength="10"></el-input>
                                    <span class="ml-10">描述</span>
                                    <el-input v-model.trim="item.selectDescribe" placeholder="请输入描述" size="small" :maxlength="20"></el-input>
                                    <span v-if="item.selectType != 3" class="redrequired ml-10">*</span><span v-if="item.selectType != 3">长度/张数</span>
                                    <sy-input v-if="item.selectType != 3" type="int" size="mini" :min="1" :max="99" v-model="item.selectLength"></sy-input>
                                    <div class="delete" @click="adddelete(2, index)"><div></div></div>
                                </div>
                            </div>
                        </el-form>
                    </div>
                    <span slot="footer" class="dialog-footer">
                        <el-button class="ml-20 mt-50" type="danger" @click="addeventtype" :loading="loading1">确 定</el-button>
                        <el-button class="ml-20 mt-50" @click="dialogType = false">取 消</el-button>
                    </span>
                </el-dialog>
            </div>
            <div class="edit">
                <el-dialog
                    title="编辑报备类型"
                    :visible.sync="dialogEdit"
                    size="small">
                    <div class="box">
                        <el-form :model="postData2" label-width="90px" class="clearfix pl-20">
                            <el-form-item label="类型名称" prop="taskTypeName" required>
                                <el-input placeholder="请输入名称" class="w240" v-model.trim="postData2.taskTypeName" :maxlength="15"></el-input>
                            </el-form-item>
                            <el-form-item label="类型描述" prop="taskTypeDescription" required>
                                <el-input placeholder="请输入描述" class="w240" v-model.trim="postData2.taskTypeDescription" :maxlength="20"></el-input>
                            </el-form-item>
                            <el-form-item label="设置选项">
                                <el-button class="color-danger" @click="editadd(1)">新增</el-button>
                            </el-form-item>
                            <div class="optionlist">
                                <div v-for="(item,index) in postData2.taskTypeSelectList1" :key="index">
                                    <span class="redrequired ml-10">*</span><span>类型</span>
                                    <el-select v-model="item.selectType" placeholder="请选择" size="mini" :disabled="item.disabled">
                                        <el-option
                                        v-for="item in options"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                        </el-option>
                                    </el-select>
                                    <span class="redrequired">*</span><span>选项名</span><el-input v-model.trim="item.selectName" placeholder="请输入选项名" size="small" :maxlength="10"></el-input>
                                    <span class="ml-10">描述</span><el-input v-model.trim="item.selectDescribe" placeholder="请输入描述" size="small" :maxlength="20"></el-input>
                                    <span v-if="item.selectType != 3" class="redrequired ml-10">*</span><span v-if="item.selectType != 3">长度/张数</span><sy-input v-if="item.selectType != 3" type="int" size="mini" :min="1" :max="99" v-model="item.selectLength"></sy-input> 
                                    <div class="delete" @click="editdelete(1, index)"><div></div></div>
                                </div>
                            </div>
                            <el-form-item label="执行选项">
                                <el-button class="color-danger" @click="editadd(2)">新增</el-button>
                            </el-form-item>
                            <div class="optionlist">
                                <div v-for="(item,index) in postData2.taskTypeSelectList2" :key="index">
                                    <span class="redrequired ml-10">*</span><span>类型</span>
                                    <el-select v-model="item.selectType" placeholder="请选择" size="mini" :disabled="item.disabled">
                                        <el-option
                                        v-for="item in options"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                        </el-option>
                                    </el-select>
                                    <span class="redrequired">*</span><span>选项名</span><el-input v-model.trim="item.selectName" placeholder="请输入选项名" size="small" :maxlength="10"></el-input>
                                    <span class="ml-10">描述</span><el-input v-model.trim="item.selectDescribe" placeholder="请输入描述" size="small" :maxlength="20"></el-input>
                                    <span v-if="item.selectType != 3" class="redrequired ml-10">*</span><span v-if="item.selectType != 3">长度/张数</span><sy-input v-if="item.selectType != 3" type="int" size="mini" :min="1" :max="99" v-model="item.selectLength"></sy-input> 
                                    <div class="delete" @click="editdelete(2, index)"><div></div></div>
                                </div>
                            </div>
                        </el-form>
                    </div>
                    <span slot="footer" class="dialog-footer">
                        <el-button class="ml-20 mt-50" type="danger" @click="editeventtype" :loading="loading1">确 定</el-button>
                        <el-button class="ml-20 mt-50 color-danger" @click="dialogEdit = false">取 消</el-button>
                    </span>
                </el-dialog>
            </div>
        </div>
    </div>
</template>

<script>
import { axiosApi,basePath } from "../../api/api";
import Confirm from '../../utils/formconfirm'
export default {
    data() {
        return {
            loadingtable: false,
            currentPage: 1,
            total: '',
            tableData: [],
            dialogType:false,
            dialogEdit:false,
            loading1: false,
            editid: null,
            options: [
                {
                    label: '文本',
                    value: 1,
                },
                {
                    label: '图片',
                    value: 2,
                },
                {
                    label: '时间',
                    value: 3
                }
            ],
            postData1: {
                taskTypeName: '',
                taskTypeDescription: '',
                taskTypeSelectList1: [
                    {
                        selectName: null,
                        selectLength: '',
                        selectType: 1,
                        excuteType: 0,
                        selectDescribe: null
                    }
                ],
                taskTypeSelectList2: [
                    {
                        selectName: null,
                        selectLength: '',
                        selectType: 1,
                        excuteType: 1,
                        selectDescribe: null
                    }
                ]
            },
            postData2: {
                taskTypeName: '',
                taskTypeDescription: '',
                taskTypeSelectList1: [
                    {
                        selectName: null,
                        selectLength: '',
                        selectType: 1,
                        excuteType: 0,
                        selectDescribe: null
                    }
                ],
                taskTypeSelectList2: [
                    {
                        selectName: null,
                        selectLength: '',
                        selectType: 1,
                        excuteType: 1,
                        selectDescribe: null
                    }
                ]
            },
            deletelist: []
        }
    },
    watch: {
        dialogType(val) {
            if(val) {
                this.postData1 = {
                    taskTypeName: '',
                    taskTypeDescription: '',
                    taskTypeSelectList1: [
                        {
                            selectName: null,
                            selectLength: '',
                            selectType: 1,
                            excuteType: 0,
                            selectDescribe: null
                        }
                    ],
                    taskTypeSelectList2: [
                        {
                            selectName: null,
                            selectLength: '',
                            selectType: 1,
                            excuteType: 1,
                            selectDescribe: null
                        }
                    ]
                }
            }
        }
    },
    methods: {
        adddelete(x, index) {
            this.$msgbox({
                title: '提示信息',
                message: '确认删除此选项？',
                showCancelButton: true,
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                beforeClose: (action, instance, done) => {
                    if(action === 'confirm') {
                        if(x == 1) {
                            this.postData1.taskTypeSelectList1.length>1?this.postData1.taskTypeSelectList1.splice(index, 1): this.$notify({message: '至少存在一条设置选项',type:'warning'})
                        } else {
                            this.postData1.taskTypeSelectList2.length>1?this.postData1.taskTypeSelectList2.splice(index, 1): this.$notify({message: '至少存在一条执行选项',type:'warning'})
                        }
                        done()
                    } else {
                        done()
                    }
                }
            })
        },
        addadd(x) {
            if(x == 1) {
                if(this.postData1.taskTypeSelectList1.length<20) {
                    this.postData1.taskTypeSelectList1.push(
                        {
                            selectName: null,
                            selectLength: '',
                            selectType: 1,
                            excuteType: 0,
                            selectDescribe: null
                        }
                    )
                } else {
                    this.$notify({
                        type: 'warning',
                        message: '最多配置20个设置选项'
                    })
                }
            } else {
                if(this.postData1.taskTypeSelectList2.length<20) {
                    this.postData1.taskTypeSelectList2.push(
                        {
                            selectName: null,
                            selectLength: '',
                            selectType: 1,
                            excuteType: 1,
                            selectDescribe: null
                        }
                    )
                } else {
                    this.$notify({
                        type: 'warning',
                        message: '最多配置20个执行选项'
                    })
                }
            }
        },
        editadd(x) {
            if(x == 1) {
                if(this.postData2.taskTypeSelectList1.length<20) {
                    this.postData2.taskTypeSelectList1.push(
                    {
                            selectName: null,
                            selectLength: '',
                            selectType: 1,
                            excuteType: 0,
                            selectDescribe: null
                        }
                    )
                } else {
                    this.$notify({
                        type: 'warning',
                        message: '最多配置20个设置选项'
                    })
                }
            } else {
                if(this.postData2.taskTypeSelectList2.length<20) {
                    this.postData2.taskTypeSelectList2.push(
                        {
                            selectName: null,
                            selectLength: '',
                            selectType: 1,
                            excuteType: 1,
                            selectDescribe: null
                        }
                    )
                } else {
                    this.$notify({
                        type: 'warning',
                        message: '最多配置20个执行选项'
                    })
                }
            }
        },
        editdelete(x, index) {
            this.$msgbox({
                title: '提示信息',
                message: '确认删除此选项？',
                showCancelButton: true,
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                beforeClose: (action, instance, done) => {
                    if(action === 'confirm') {
                        if(x == 1) {
                            if(this.postData2.taskTypeSelectList1.length>1) {
                                this.deletelist.push(this.postData2.taskTypeSelectList1[index].id)
                                this.postData2.taskTypeSelectList1.splice(index, 1)
                            } else {
                                this.$notify({message: '至少存在一条设置选项',type:'warning'})
                            }
                        } else {
                            if(this.postData2.taskTypeSelectList2.length>1) {
                                this.deletelist.push(this.postData2.taskTypeSelectList2[index].id)
                                this.postData2.taskTypeSelectList2.splice(index, 1)                          
                            } else {
                                this.$notify({message: '至少存在一条执行选项',type:'warning'})
                            }
                        }
                        done()
                    } else {
                        done()
                    }
                }
            })
        },
        querytable(x) {
            this.loadingtable = true
            axiosApi('/sales/taskType/web/selectListPage', {}, (res)=>{
                this.tableData = res.data.data.elements
                this.total = res.data.data.totalCount
                this.loadingtable = false
            },x?x:this.currentPage, 15 )
        },
        //弹窗一，新增报备类型
        addeventtype() {
            this.loading1 = true
            let newconfirm = new Confirm()
            let confirmdata = this.postdataadd
            newconfirm.use([confirmdata.taskTypeName], '请输入报备类型名称')
            newconfirm.use([confirmdata.taskTypeDescription], '请输入报备类型描述')
            confirmdata.taskTypeSelectList.forEach((a)=>{
                newconfirm.use([a.selectName], '请完整填写选项必填项')
                if(a.selectType != 3) {
                    newconfirm.use([a.selectLength], '请完整填写选项必填项')
                }
            })
            let timeTypeList = confirmdata.taskTypeSelectList.filter((a)=>{return a.selectType == 3})
            if(timeTypeList.length > 5) {
                newconfirm.status = false
                this.$message('日期类型超过上限')
            }
            var namelist = confirmdata.taskTypeSelectList.map((a)=>{return a.selectName})
            var abc = namelist.unique();//数组去重
            if(namelist.length !== abc.length) {
                newconfirm.status = false
                this.$message('存在相同的选项名称')
            }
            if(newconfirm.status) {
                axiosApi('/sales/taskType/web/insert',this.postdataadd,(res)=>{
                    this.querytable(1)
                    this.$notify({
                        type: 'success',
                        message: '新增报备类型成功'
                    })
                    this.loading1 = false
                },undefined,undefined,(res)=>{
                    this.loading1 = false
                    this.$notify({
                        type: 'warning',
                        message: '新增报备类型失败，'+ res.data.message
                    })
                })
                this.dialogType = false
            } else {
                this.loading1 = false
            }
        },
        editeventtype() {
            this.loading1 = true
            let newconfirm = new Confirm()
            let confirmdata = this.postdataedit
            newconfirm.use([confirmdata.taskTypeName], '请输入报备类型名称')
            newconfirm.use([confirmdata.taskTypeDescription], '请输入报备类型描述')
            confirmdata.taskTypeSelectList.forEach((a)=>{
                newconfirm.use([a.selectName], '请完整填写选项必填项')
                if(a.selectType != 3) {
                    newconfirm.use([a.selectLength], '请完整填写选项必填项')
                }
            })
            let timeTypeList = confirmdata.taskTypeSelectList.filter((a)=>{return a.selectType == 3})
            if(timeTypeList.length > 5) {
                newconfirm.status = false
                this.$message('日期类型超过上限')
            }
            var namelist = confirmdata.taskTypeSelectList.map((a)=>{return a.selectName})
            var abc = namelist.unique();//数组去重
            if(namelist.length !== abc.length) {
                newconfirm.status = false
                this.$message('存在相同的选项名称')
            }
            if(newconfirm.status) { 
                axiosApi('/sales/taskType/web/update',this.postdataedit,(res)=>{
                    this.querytable(1)
                    this.$notify({
                        type: 'success',
                        message: '编辑报备类型成功'
                    })
                    this.loading1 = false
                },undefined,undefined,(res)=>{
                    this.loading1 = false
                    this.$notify({
                        type: 'warning',
                        message: '编辑报备类型失败，'+ res.data.message
                    })
                })
                this.dialogEdit = false
            } else {
                this.loading1 = false
            }
        },
        handlePageChange(val) {
            this.currentPage = val
            this.querytable()
        },
        deleteRow(index, data) {
            var that = this
            this.$msgbox({
                title: '提示信息',
                message: '您确定要删除此报备类型？',
                showCancelButton: true,
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                beforeClose: (action, instance, done) => {
                    if(action === 'confirm') {
                    axiosApi('/sales/taskType/web/delete',{id: data[index].id},()=>{
                        this.$notify({
                            type: 'success',
                            message: '删除报备类型成功'
                        })
                        that.querytable(1)
                    },undefined,undefined,(res)=>{
                        this.$notify({
                            type: 'warning',
                            message: '删除报备类型失败'+res.data.message
                        })
                    })
                    done();
                    } else {
                    done();
                    }
                }
            })
        },
        editRow(index, data) {
            this.editid = data[index].id
            axiosApi('/sales/taskType/web/selectInfo',{id: data[index].id},(res)=>{
                let rest = res.data.data
                this.postData2.taskTypeName = rest.taskTypeName
                this.postData2.taskTypeDescription = rest.taskTypeDescription
                this.postData2.taskTypeSelectList1 = rest.taskTypeSelectList.filter((a)=>{return a.excuteType ==0}).map((a)=>{return Object.assign({},a,{disabled: true})})
                this.postData2.taskTypeSelectList2 = rest.taskTypeSelectList.filter((a)=>{return a.excuteType ==1}).map((a)=>{return Object.assign({},a,{disabled: true})})
                this.dialogEdit = true
            })
        }
    },
    computed: {
        postdataadd() {
            return {
                taskTypeName: this.postData1.taskTypeName,
                taskTypeDescription: this.postData1.taskTypeDescription,
                taskTypeSelectList: [...this.postData1.taskTypeSelectList1,...this.postData1.taskTypeSelectList2]
            }
        },
        postdataedit() {
            return {
                id: this.editid,
                taskTypeName: this.postData2.taskTypeName,
                selectDelIdList: this.deletelist,
                taskTypeDescription: this.postData2.taskTypeDescription,
                taskTypeSelectList: [...this.postData2.taskTypeSelectList1,...this.postData2.taskTypeSelectList2].map((a)=>{
                    return {
                        selectName: a.selectName,
                        selectLength: a.selectLength,
                        selectType: a.selectType,
                        excuteType: a.excuteType,
                        selectDescribe: a.selectDescribe,
                        id: a.id ? a.id : null
                    }
                })
            }
        }
    },
    activated() {
        this.querytable()
    }
}
</script>

<style lang="less">
    #reportType{
        padding-left: 15px;
        padding-bottom: 50px;
        .redrequired {
            color: red;
            margin-right: 0px !important;
        }
        .border{
            border: 1px solid #EEEEEE;
            background-color:  #EEEEEE;
        }
        .w240{
            width: 240px;
        }
        .w130{
            width: 130px;
        }
        .w75{
            width: 75px;
        }
        .w335{
            width: 335px;
        }
        .selectWrap{
            display: inline-block;
            .el-input{
                width: 75px;
            }
        }
        .add, .edit {
            .el-dialog {
                width: 870px;
                max-height: 790px;
                .box {
                    overflow: hidden;
                    margin-bottom: 45px;
                    .optionlist {
                        max-height: 190px;
                        width: 790px;
                        margin-bottom: 20px;
                        margin-left: 43px;
                        overflow-y: scroll;
                        &>div {
                            margin-bottom: 10px;
                            &:last-child {
                                margin-bottom: 0px;
                            }
                            span {
                                font-size: 12px;
                                margin-right: 5px;
                            }
                            .el-input--small {
                                width: 138px!important;
                            }
                            .delete {
                                display: inline-block;
                                vertical-align: middle;
                                position: relative;
                                margin-left: 10px;
                                width: 16px;
                                height: 16px;
                                border-radius: 8px;
                                background: #ff4201;
                                &>div {
                                    position: absolute;
                                    height: 2px;
                                    width: 12px;
                                    background: #fff;
                                    left: 2px;
                                    top: 7px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
</style>
