<template>
	<div class="forget width-1280">
		<div class="mb-50">
			<!-- <img class="sub mr-10" src="../../assets/register.png" /> -->
			<i class="iconfont icon-inspector color-b font-18 mr-10"></i>
			<span class="color-6 font-16">1/3验证手机号</span>
		</div>
		<el-form :model="form" :rules="rules" ref="form" label-width='340px'>
			<el-form-item label='手机号:' prop="phone">
				<el-input v-model.trim="form.phone" :maxlength="11" @change="checkMobileNewAvailable"></el-input>
				<p v-if="exist" class="color-danger lh-12 mt-5">系统不存在的用户</p>
			</el-form-item>
            <el-form-item label=' '>
                <div id="c1"></div>
            </el-form-item>
			<el-form-item label="短信验证码:" prop="code">
				<el-input class="w105 mr-20" :maxlength="6" v-model.trim="form.code" @change="isCode=false"></el-input>
				<el-button class="w115 auto-btn" @click="sending" :disabled="codeDisabled">
					<span v-if="codeBool">获取验证码<span v-show="timeBool">{{time | seconds}}</span></span>
					<span v-else>获取验证码</span>
				</el-button>
				<p v-if="isCode" class="color-danger lh-12 mt-5">{{cmessage}}</p>
			</el-form-item>
			<el-form-item class="mt-50" label="">
				<el-button type="danger" @click="goPage('form')">下一步</el-button>
			</el-form-item>
		</el-form>
	</div>
</template>

<script>
	import { axiosApi, basePath } from "../../api/api"
	import md5 from "md5"
	export default {
		data() {
			return {
			    token:'',
				exist: false, //手机号错误信息是否显示
				cmessage: "", //验证码错误信息
				isCode: false, //验证码错误信息时候显示
				codeDisabled: true, //发送验证码按钮是否禁用
				form: {
					phone: "",
					code: "",
				},
				rules: {
					phone: [{
						required: true,
						pattern: /^1\d{10}$/,
						message: "请输入正确的手机号",
					}],
					code: [{
						required: true,
						message: '请输入验证码',
						trigger: 'blur'
					}, {
						min: 6,
						max: 6,
						message: '请输入6位验证码',
						trigger: 'blur'
					}]
				},
				time: "59",
				timeBool: false,
				codeBool: true,
				clearTime: -1
			}
		},
		filters: {
			seconds(value) {
				return "(" + value + "s)";
			}
		},
		methods: {
			checkMobileNewAvailable() { //判断该手机号是否是绑定的手机号
				this.exist = false;////清除手机错误信息
				this.codeDisabled = true;
				if(this.form.phone.length == 11) {
					axiosApi("/unlogin/employee/checkMobileNewAvailable", {
						mobile: this.form.phone,
						loginName: this.form.phone,
						appId: 2
					}, (res) => {
						if(res.data.data) {
							this.exist = false;
							this.codeDisabled = false;
						} else {
							this.exist = true;
						}
					})
				}
			},
			sending() { //发送验证码
                if (!this.token){
                    this.$message.error('请先通过滑动验证码')
                    return false
                }
                this.$refs.form.validateField("phone");
				if(!this.exist && this.form.phone.length == 11) {
					let _this = this;
					this.codeDisabled = true;
					if(!this.codeBool) {
						clearTimeout(this.clearTime);
						this.codeBool = true;
						this.time = "59"
					}
					this.timeBool = true;
					this.clearTime = setInterval(function() {
						_this.time--;
						if(_this.time == 0) {
							_this.codeBool = false;
							_this.codeDisabled = false;
						}
					}, 1000)
					const api2 = "/unlogin/sms/sendSingleWithVerification"
					const body2 = {
					    token:this.token,
						mobile: this.form.phone,
						smsTemplate: "3",
						smsType: "sms0003",
						appId: 2,
						smsPurposeType:1
					}
					axiosApi(api2, body2, () => {}, 1, 10);
				}
			},
			goPage(form) {
				this.$refs[form].validate((valid) => {
					if(valid && !this.exist) {
						const api = "/unlogin/sms/varify"
						const body = {
							mobile: this.form.phone,
							messageCode: this.form.code,
							smsType: "sms0003",
							appId: 2
						}
						axiosApi(api, body, (res) => {
							if(res.data.data) {
								sessionStorage.mobile = this.form.phone;
								this.$router.push(basePath + "/register/forgetSecond");
							}
						}, 1, 10, (res) => {
							this.isCode = true;
							this.cmessage = res.data.message;
						})
					} else {
						// console.log('error submit!!');
						return false;
					}
				});
			},

		},
        mounted() {
		    this.$nextTick(()=>{
                let _this = this
                var myCaptcha = _dx.Captcha(document.getElementById('c1'), {
                    _noLogo: true,
                    style: 'inline',
                    inlineFloatPosition: 'down',
                    width: 240,
                    appId: '8f7a2bbfcb209f52dee6f645ee9c71c4', //appId，在控制台中“应用管理”或“应用配置”模块获取
                    success: function (token) {
                        console.log('token:', token)
                        _this.token = token
                    }
                })
            })
        }
    }
</script>
<style lang="less">
	.forget{
		padding: 50px 0 50px 200px;
	}
</style>
