<template>
  <div class="cloud-cash-out">
    <div class="title-top bg-f mb-5">
			云账户提现流水
		</div>

    <div class="pb-30 pt-30 bg-f">
      <el-form :inline="true" :model="searchForm" class="pl-20 clearfix bg-f">
          <el-form-item label="云账户订单编号">
            <el-input
              placeholder="请输入云账户订单编号"
              v-model="searchForm.thirdPaySn"
              :maxlength="18"
              @input="searchForm.thirdPaySn = searchForm.thirdPaySn.replace(/[^a-zA-Z\d]/g, '')"
            ></el-input>
          </el-form-item>
          <el-form-item label="收款人名称">
            <el-input
              placeholder="请输入收款人名称"
              v-model="searchForm.traderName"
              :maxlength="10"
              @keyup.native="searchForm.traderName = searchForm.traderName.replace(/[^a-zA-Z\u4e00-\u9fa5]/g, '')"
            ></el-input>
          </el-form-item>
          <el-form-item label="收款人账号">
            <el-input
              placeholder="请输入收款人账号"
              v-model="searchForm.traderLogin"
              :maxlength="12"
              @input="searchForm.traderLogin = searchForm.traderLogin.replace(/[^a-zA-Z\d]/g, '')"
            ></el-input>
          </el-form-item>
          <el-form-item label="提现状态">
            <el-select v-model="searchForm.withdrawState">
              <el-option
                v-for="item in withdrawStateList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="所属业务">
            <el-select v-model="searchForm.concreteBusinessType">
              <el-option
                v-for="item in concreteBusinessTypeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="申请时间">
            <ysDaterangePicker v-model="searchForm.timeRange" :endDisabledDate="new Date()" />
          </el-form-item>
      </el-form>
        <div class="pb-30 pt-5">
            <el-button type="info" icon="el-icon-search" class="ml-20" @click="handleSearch(0)">搜 索</el-button>
        </div>

      <div class="main bg-f">
        <el-table :data="tableData" stripe v-loading="loading1">
          <el-table-column label="收款人名称" prop="traderName" align="center" min-width="85"></el-table-column>
          <el-table-column label="收款人账号" prop="traderLogin" min-width="100" align="center"></el-table-column>
          <el-table-column label="申请单号" prop="applyWithdrawSn" min-width="160" align="center"></el-table-column>
          <el-table-column label="云账户订单号" prop="thirdPaySn" min-width="160" align="center"></el-table-column>
          <el-table-column label="所属业务" prop="advancedMoney" min-width="100" align="center">
            <template slot-scope="scope">
              <p>
                {{scope.row.concreteBusinessType === 'superc_society' ? '社会版久集' :
                scope.row.concreteBusinessType === 'superc_hotel' ? '酒店版久集' :
                scope.row.concreteBusinessType === 'superc_tenantshop' ? '门店版久集' :
                scope.row.concreteBusinessType === 'groupon' ? '超团' :
                scope.row.concreteBusinessType === 'b2b_superb' ? '超级B' :
                scope.row.concreteBusinessType === 'jmall' ? 'b2c' : ''}}
              </p>
            </template>
          </el-table-column>
          <el-table-column label="收款渠道" min-width="120" align="center">
            <template slot-scope="scope">
              <div>{{scope.row.accountName}}</div>
              <div>({{scope.row.name}} {{scope.row.accountNumber}})</div>
            </template>
          </el-table-column>
          <el-table-column label="订单金额" min-width="100" align="center">
            <template slot-scope="scope">{{scope.row.withdrawMoney | fmoney}}</template>
          </el-table-column>
          <el-table-column label="申请时间" min-width="92" align="center">
            <template slot-scope="scope">
              <p>{{scope.row.gmtCreate | time}}</p>
            </template>
          </el-table-column>
          <el-table-column label="到账时间" min-width="92" align="center">
            <template slot-scope="scope">
              <p>{{scope.row.accountTime | time}}</p>
            </template>
          </el-table-column>

          <el-table-column label="提现状态" min-width="90" align="center">
            <template slot-scope="scope">
              <!--  handle-处理中；fail-失败；success-成功-->
              <el-tooltip
                v-if="scope.row.withdrawState === 'fail'"
                class="item"
                effect="dark"
                :content="scope.row.failReason"
                placement="bottom-end"
              >
                <el-button type="text">失败</el-button>
              </el-tooltip>
              <span
                v-else
              >{{scope.row.withdrawState === 'handle' ? '处理中' : scope.row.withdrawState === 'success' ? '成功' : ''}}</span>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页组件 -->
        <el-pagination
          v-if="totalCount>0"
          class="tar"
          :current-page="currentPage"
          :page-size="10"
          :total="totalCount"
          layout="prev , pager, next, jumper"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </div>
    </div>
    
  </div>
</template>

<script>
import { basePath, axiosApi } from "~/api/api";

export default {
  data() {
    return {
      searchForm: {
        startTime: "",
        endTime: "",
        timeRange: [],
        traderLogin: "",
        traderName: "",
        withdrawState: "",
        concreteBusinessType: "",
        thirdPaySn: ""
      },
      withdrawStateList: [
        {
          label: "全部",
          value: ""
        },
        {
          label: "成功",
          value: "success"
        },
        {
          label: "失败",
          value: "fail"
        },
        {
          label: "处理中",
          value: "handle"
        }
      ],
      // superc_society"社会版久集"     superc_hotel "酒店版久集"),     superc_tenantshop"门店版久集" groupon "超团"   b2c"jmall"
      concreteBusinessTypeList: [
        {
          label: "全部",
          value: ""
        },
        {
          label: "社会版久集",
          value: "superc_society"
        },
        {
          label: "酒店版久集",
          value: "superc_hotel"
        },
        {
          label: "门店版久集",
          value: "superc_tenantshop"
        },
        {
          label: "超级B",
          value: "b2b_superb"
        }
      ],
      tableData: [],
      currentPage: 1,
      totalCount: 0,
      loading1: false
    };
  },
  methods: {
    achieve() {
      this.loading1 = true;
      axiosApi(
        "/finance/onlineDetail/page",
        {
          sellerId: sessionStorage.traderId,
          startTime: this.searchForm.timeRange[0]
            ? this.searchForm.timeRange[0].getTime()
            : null,
          endTime: this.searchForm.timeRange[1]
            ? this.searchForm.timeRange[1].getTime()
            : null,
          traderLogin: this.searchForm.traderLogin
            ? this.searchForm.traderLogin
            : null,
          traderName: this.searchForm.traderName
            ? this.searchForm.traderName
            : null,
          withdrawState:
            this.searchForm.withdrawState !== ""
              ? this.searchForm.withdrawState
              : null,
          concreteBusinessType: this.searchForm.concreteBusinessType
            ? this.searchForm.concreteBusinessType
            : null,
          thirdPaySn: this.searchForm.thirdPaySn
            ? this.searchForm.thirdPaySn
            : null
        },
        res => {
          this.loading1 = false;
          this.tableData = res.data.data.elements;
          this.totalCount = res.data.data.totalCount;
        },
        this.currentPage,
        10,
        err => {
          this.loading1 = false;
        }
      );
    },
    nextPage(val) {
      this.$router.push({ path: basePath + "/advancePayDetail" });
    },
    handleSearch(val) {
      this.currentPage = 1;
      this.achieve();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.achieve();
    }
  },
  created() {
    this.achieve();
  }
};
</script>

<style lang="less">
.cloud-cash-out {
  .width-80 {
    width: 80%;
  }
  .el-form-item__label {
    width: 94px;
    font-size: 12px;
  }
  .el-table__body-wrapper {
    margin-top: 1px !important;
  }
  .main {
    padding: 0px 20px;
    .el-table {
      .cell {
        padding: 10px 12px;
      }
    }
    .el-button.el-button--text {
      min-width: auto !important;
      padding: 0 10px !important;
      width: auto !important;
    }
  }
}
</style>