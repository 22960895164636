<template>
  <div id="supplier-purchase-order" v-loading="loading">
    <div class="clearfix bg-f">
      <div class="fl title-tabs bg-f black mb-5">
        <ul class="clearfix">
          <li class="active">
            订单管理
            <div class="under-line"></div>
          </li>
          <div class="vertical-line"></div>
        </ul>
      </div>
      <div
        class="fr mr-20"
        style="padding: 22px 0 22px 30px"
      >
        <el-upload
          v-if="showButton('IMPORT_MULT', 100054)"
          style="display: inline-block; margin-right: 10px"
          action="/sunrise-gateway/import/anon/order?startRow=1&startCol=0"
          :show-file-list="false"
          :on-success="UpSuccess"
          :before-upload="UpBefore"
          :on-error="UpError"
        >
          <el-button>批量导入</el-button>
        </el-upload>
        <a
          v-if="showButton('IMPORT_MULT', 100054)"
          style="color: #20a0ff; margin-right: 10px"
          href="https://sunrise-app.oss-cn-shanghai.aliyuncs.com/template/%E8%AE%A2%E5%8D%95%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx"
        >
          <el-button>下载模板</el-button>
        </a>
        <el-button v-if="!isCityPartner" @click="deliveryBtn"
          >提货管理</el-button
        >
        <el-button @click="addOrder" v-if="showButton('ORDERS_CUSTOMER', 100054)">代客下单</el-button>
        <el-button @click="goDraftOrder" v-if="showButton('ORDERS_CUSTOMER', 100054)">待提交单</el-button>
      </div>
    </div>
    <div class="content bg-f pb-50 pl-30 pr-30 mt-5">
      <ul class="clearfix order_status bb" style="height: 60px">
        <template v-for="(tab, index) in dataTabs" class="item">
          <li
            :type="index"
            class="fl"
            :class="type == index ? 'active' : ''"
            :key="index"
            @click="handleTabs(tab.title, index)"
          >
            {{ tab.title
            }}<span :type="index" v-if="tab.num > 0" class="color-danger"
              >({{ tab.num }})</span
            >
          </li>
          <div class="vertical-line" :key="index + tab"></div>
        </template>
      </ul>
      <div class="clearfix">
        <el-form
          :inline="true"
          class="pt-30 fl search"
          :model="form"
          ref="form"
        >
          <el-form-item label="订单编号" class="mr-20" prop="orderSn">
            <el-input
              v-model.trim="form.orderSn"
              @input="
                form.orderSn = form.orderSn.replace(/[\u4e00-\u9fa5]/gi, '')
              "
            ></el-input>
          </el-form-item>
          <el-form-item label="买家名称" class="mr-20" prop="storeName">
            <el-input v-model.trim="form.storeName"></el-input>
          </el-form-item>
          <el-form-item label="买家手机" class="mr-20" prop="buyerMobile">
            <el-input
              v-model.trim="form.buyerMobile"
              @input="
                form.buyerMobile = form.buyerMobile.replace(
                  /[\u4e00-\u9fa5]/gi,
                  ''
                )
              "
            ></el-input>
          </el-form-item>

          <el-form-item label="商品名称" class="mr-20" prop="itemName">
            <el-input v-model.trim="form.itemName"></el-input>
          </el-form-item>

          <el-form-item label="客户类型" class="mr-20" prop="managerName">
            <el-cascader
              v-model="customerType"
              clearable
              :options="optionsType"
              :props="{ checkStrictly: true }"
            ></el-cascader>
          </el-form-item>
          <el-form-item label="销售员" class="mr-20" prop="managerName">
            <el-input v-model.trim="form.managerName"></el-input>
          </el-form-item>
          <el-form-item label="订单来源" class="mr-20" prop="managerName">
            <el-select
              v-model="form.clientSource"
              clearable
              placeholder="请选择"
            >
              <el-option
                v-for="item in options"
                :key="item + item.value"
                :label="item.label"
                :value="item.value"
                v-show="!(isCityPartner && item.value == 10)"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="下单时间" class="mr-20" prop="orderDateRange">
            <ys-daterange-picker v-model="form.orderDateRange" />
          </el-form-item>
          <el-form-item label="客户分组" class="mr-20" prop="managerName">
            <TreeGroup v-model="form.groupId" />
          </el-form-item>
          <el-form-item label="支付方式" class="mr-20">
            <el-select
              v-model="form.payTypeList"
              clearable
              multiple
              collapse-tags
              placeholder="请选择"
            >
              <el-option
                v-for="item in payTypeInfo"
                :key=" item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="所属区域">
            <districtSelect v-model="form.districtId" class="mr-10"/>
          </el-form-item>
        </el-form>
      </div>
      <div class="pt-5">
        <el-button icon="el-icon-search" type="info" @click="handleQueryBtn"
          >查 询</el-button
        >
      </div>
      <div class="tar pl-20 pr-20">
        <el-button type="text" @click="exportOrder">批量导出</el-button>
        <el-button type="text" class="fr" @click="checkTable"
          >查看已生成报表</el-button
        >
      </div>
      <div class="order-list">
        <el-checkbox
          v-model="checkAll"
          @change="handleCheckAllChange"
          class="checkedAll"
        ></el-checkbox>
        <div
          class="clearfix font-12 bg-e table_header color-3"
          style="display: flex; text-align: center"
        >
          <div class="fl" style="flex: 4">商品信息</div>
          <div class="fl" style="flex: 1">单价</div>
          <div class="fl" style="flex: 1">数量</div>
          <div class="f1" style="flex: 1" v-if="!isCityPartner">仓库</div>
          <div class="fl" style="flex: 1">买家名称</div>
          <div class="fl" style="flex: 1">订单总额</div>
          <div class="fl" style="flex: 1">交易状态</div>
          <div class="fl" style="flex: 1">支付方式</div>
          <div class="fl" style="flex: 1">预估积分</div>
          <div class="fl" style="flex: 1.5">操作</div>
        </div>
        <div class="table-item-default tac" v-if="orderList.length <= 0">
          <img src="../../../assets/empty.png" alt="" />
          <div class="color-danger mt-30">
            <router-link :to="{ path: '/customer/shop/shopIndex' }"
              ><span class="color-danger">
                啥都没有，去首页看看 ></span
              ></router-link
            >
          </div>
        </div>
        <div v-else>
          <div
            class="table-item mt-20 font-12"
            v-for="order in orderList"
            :key="order.id"
          >
            <div class="clearfix table-item-top bg-e">
              <el-checkbox
                class="fl checked"
                :label="order"
                v-model="order.checked"
                @change="handleCheckChange1(order)"
              ></el-checkbox>
              <span class="fl ml-20"
                >订单编号：{{ order.parentOrderSn || order.sn }}</span
              >
              <span class="fl ml-20"
                >下单时间：{{ order.gmtCreate | time }}</span
              >
              <span class="fl ml-20"
                >制单员：{{
                  order.operatorName ? order.operatorName : "无"
                }}</span
              >
              <span class="fl ml-20"
                >销售员：{{
                  order.managerName ? order.managerName : "无"
                }}</span
              >
              <span class="fl ml-20" v-if="order.gmtComplete">交易完成时间：{{ order.gmtComplete | time }}</span>
              <span class="fl ml-20" v-show="order.parentOrSon == 3"
                >订单状态：已拆分</span
              >
              <div
                class="separate"
                style="
                  display: inline-block;
                  width: 160px;
                  text-align: right;
                  height: 50px;
                "
              >
                <img
                  v-if="order.deliverySeparate"
                  style="height: 50px; width: auto"
                  src="../../../assets/separate.png"
                />
                <img
                  v-if="order.openStraight"
                  style="height: 50px; width: auto"
                  src="../../../assets/straight.png"
                />
                <div
                  style="
                    width: 95px;
                    display: inline-block;
                    vertical-align: top;
                  "
                  v-show="order.parentOrSon != 3 && order.clientSource != 10"
                >
                  <span
                    style="vertical-align: top; padding-right: 10px"
                    v-if="
                      order.k3Sn == null &&
                      order.erpType == 'k3' &&
                      order.orderStatus != 1 &&
                      order.orderStatus != 0
                    "
                    >正在同步ERP<i class="el-icon-loading"></i
                  ></span>
                  <el-popover
                    ref="popoverThree"
                    placement="bottom"
                    width="300"
                    trigger="hover"
                  >
                    <div class="copyInfo" v-if="k3info.k3Sn">
                      <div class="copyNumber">
                        <span class="numberOne">销售单号：</span>
                        <span class="numberTwo">{{ k3info.k3Sn }}</span>
                      </div>
                      <span
                        class="copyText"
                        v-clipboard:copy="k3info.k3Sn"
                        v-clipboard:success="onCopy"
                        v-clipboard:error="onError"
                        >复制</span
                      >
                    </div>
                    <div class="outHouse" v-if="k3info.k3OutStockNos">
                      <div style="width: 70px">出库单号：</div>
                      <div class="outHouseContent">
                        <div
                          class="outHouseRight"
                          v-for="(iteml, index) in k3info.k3OutStockNos"
                          :key="index + 'ii'"
                        >
                          <span class="outHouseNumber">{{ iteml }}</span>
                          <span
                            class="outHouseCopy"
                            v-clipboard:copy="iteml"
                            v-clipboard:success="onCopy"
                            v-clipboard:error="onError"
                            >复制</span
                          >
                        </div>
                      </div>
                    </div>
                    <div class="copyInfo" v-if="k3info.k3ReceiveBillNo">
                      <div class="copyNumber">
                        <span class="numberOne">收款单号：</span>
                        <span class="numberTwo">{{
                          k3info.k3ReceiveBillNo
                        }}</span>
                      </div>
                      <span
                        class="copyText"
                        v-clipboard:copy="k3info.k3ReceiveBillNo"
                        v-clipboard:success="onCopy"
                        v-clipboard:error="onError"
                        >复制</span
                      >
                    </div>
                    <!--      -->
                    <span
                      v-if="
                        order.erpType == 'k3' &&
                        order.k3Sn &&
                        order.orderStatus != 0 &&
                        order.orderStatus != 1
                      "
                      style="
                        cursor: pointer;
                        display: inline-block;
                        vertical-align: top;
                        padding-right: 10px;
                      "
                      slot="reference"
                      @mouseenter="changektInfo(order)"
                      >同步ERP成功<i class="el-icon-arrow-down"></i
                    ></span>
                  </el-popover>
                </div>
              </div>
            </div>
            <div
              v-if="order.remark || order.remarkPics"
              class="bbd clearfix"
              style="lineheight: 40px; paddingleft: 76px; display: flex"
            >
              <span class="fl" style="vertical-align: top; width: 60px"
                >客户备注：</span
              >
              <div class="fl" style="word-break: break-word; width: 0; flex: 1">
                <span style="vertical-align: top">{{ order.remark }}</span>
                <div
                  v-if="order.remarkPics"
                  style="display: inline-block; margin-top: 10px"
                  class="ml-10"
                >
                  <img
                    style="width: 30px; height: 30px"
                    class="mr-10"
                    :src="item"
                    v-for="(item, index) in order.remarkPics"
                    alt=""
                    :key="index"
                    @click="lookImageDetail(order.remarkPics, index)"
                  />
                </div>
              </div>
            </div>
            <div
              v-if="order.remarkForModify || order.remarkForModifyPics"
              class="bbd clearfix"
              style="
                lineheight: 26px;
                paddingleft: 76px;
                color: rgba(255, 89, 24, 1);
                display: flex;
              "
            >
              <span class="fl" style="width: 60px">业务备注:</span>
              <div class="fl" style="word-break: break-word; width: 0; flex: 1">
                <span style="vertical-align: top">{{
                  order.remarkForModify
                }}</span>
                <div
                  style="display: inline-block; margin-top: 10px"
                  class="ml-10"
                  v-if="order.remarkForModifyPics"
                >
                  <img
                    style="width: 30px; height: 30px"
                    class="mr-10"
                    :src="item"
                    v-for="(item, index) in order.remarkForModifyPics"
                    alt=""
                    :key="index"
                    @click="lookImageDetail(order.remarkForModifyPics, index)"
                  />
                </div>
              </div>
            </div>
            <!-- v-if="order.parentOrSon == 1" -->
            <div >
              <div class="table-item-content clearfix" style="display: flex">
                <!--商品-->
                <div
                  class="clearfix bb"
                  :style="!isCityPartner ? 'flex: 7;' : 'flex:6'"
                >
                  <div
                    style="padding: 20px 0 20px 20px"
                    :class="{
                      bb:
                        index != order.orderItemVos.length - 1 ||
                        (index == order.orderItemVos.length - 1 &&
                          order.orderGiftResVos.length > 0),
                    }"
                    v-for="(orderItem, index) in order.orderItemVos"
                    :key="orderItem.id"
                  >
                    <div class="clearfix" style="display: flex">
                      <div class="fl gift" style="flex: 4">
                        <div class="clearfix">
                          <div
                            v-if="orderItem.changePurchaseItem"
                            class="triangle-topleft border-red"
                          ></div>
                          <span
                            v-if="orderItem.changePurchaseItem"
                            class="init default"
                            >换购</span
                          >
                          <img
                            v-if="orderItem.image"
                            :src="orderItem.image"
                            class="fl mr-10"
                          />
                          <img
                            v-else
                            src="../../../assets/goods.jpg"
                            class="fl mr-10"
                          />
                          <div class="fl" style="width: 210px">
                            <el-tooltip
                              class="item"
                              effect="dark"
                              :content="orderItem.skuName"
                              placement="top-start"
                            >
                              <p class="text-overflow">
                                {{ orderItem.skuName }}
                              </p>
                            </el-tooltip>
                            <p
                              class="color-9 mt-10"
                              v-if="orderItem.goodsNo"
                            >
                              商品编码:{{ orderItem.goodsNo }}
                            </p>
                            <p
                              class="color-9 mt-10"
                              v-if="orderItem.specInfoNum"
                            >
                              规格:1*{{ orderItem.specInfoNum }}
                            </p>
                            <p
                              class="color-9 mt-10"
                              v-if="orderItem.k3SaleTypeName"
                            >
                              商品类型:{{ orderItem.k3SaleTypeName }}
                            </p>
                          </div>
                        </div>
                      </div>

                      <div class="fl" style="flex: 1; text-align: center">
                        ¥{{ orderItem.salesPrice }}/{{ orderItem.unitName }}
                        <p style="color: #ff4201; line-height: 20px" v-if="orderItem.promotionDiscountsMoney">
                          返利¥{{ orderItem.promotionDiscountsMoney }}/{{ orderItem.unitName }}
                        </p>
                      </div>
                      <div class="fl" style="flex: 1; text-align: center">
                        <div>{{ orderItem.specNum }}{{ orderItem.unitName }}</div>
                        <div
                          class="color-danger mt-10"
                          v-if="
                            order.deliverySeparate && (order.orderStatus == 2||order.orderStatus == 3)
                          "
                        >
                          可发{{ orderItem.remainingSpecNum
                          }}{{ orderItem.unitName }}
                        </div>
                      </div>
                      <div
                        class="f1"
                        v-if="!isCityPartner"
                        style="flex: 1; text-align: center"
                      >
                        {{
                          orderItem.openStraight
                            ? "虚拟仓"
                            : orderItem.k3StockName
                        }}
                      </div>
                    </div>
                    <p
                      v-if="orderItem.remark"
                      style="lineheight: 26px; color: rgba(255, 89, 24, 1)"
                    >
                      商品备注：{{ orderItem.remark }}
                    </p>
                  </div>
                  <!--赠品清单-->
                  <div
                    style="padding: 20px 0 20px 20px"
                    :class="{ bb: index != order.orderGiftResVos.length - 1 }"
                    v-for="(orderItem, index) in order.orderGiftResVos"
                    :key="orderItem.id"
                  >
                    <div class="clearfix" style="display: flex">
                      <div class="fl gift" style="flex: 4">
                        <div class="triangle-topleft border-red"></div>
                        <span class="init default">赠品</span>
                        <img
                          v-if="orderItem.image"
                          :src="orderItem.image"
                          class="fl mr-10"
                        />
                        <img
                          v-else
                          src="../../../assets/goods.jpg"
                          class="fl mr-10"
                        />
                        <div class="fl" style="width: 210px">
                          <el-tooltip
                            class="item"
                            effect="dark"
                            :content="orderItem.skuName"
                            placement="top-start"
                          >
                            <p class="text-overflow">{{ orderItem.skuName }}</p>
                          </el-tooltip>
                          <p class="color-9 mt-10" v-if="orderItem.goodsNo">商品编码:{{orderItem.goodsNo}}</p>
                          <p class="color-9 mt-10" v-if="orderItem.specInfoNum">
                            规格:1*{{ orderItem.specInfoNum }}
                          </p>
                          <p
                            class="color-9 mt-10"
                            v-if="
                              orderItem.k3SaleTypeName &&orderItem.giftType == 0
                            "
                          >
                            商品类型:{{ orderItem.k3SaleTypeName }}
                          </p>
                          <p
                            class="color-9 mt-10"
                            v-if="
                              orderItem.k3GiftTypeName &&
                              !isCityPartner &&
                              orderItem.giftType == 0
                            "
                          >
                            赠品类型:{{ orderItem.k3GiftTypeName }}
                          </p>
                        </div>
                      </div>
                      <div class="fl" style="flex: 1; text-align: center">
                        <span class="giftPriceClass">¥{{ orderItem.salesPrice }}/{{ orderItem.unitName }}</span>  
                      </div>
                      <div class="fl" style="flex: 1; text-align: center">
                        <div>
                          {{ orderItem.giftNum }}{{ orderItem.sellingUnitName }}
                        </div>
                        <div
                          class="color-danger mt-10"
                          v-if="
                            order.deliverySeparate && (order.orderStatus == 2||order.orderStatus == 3)
                          "
                        >
                          可发{{ orderItem.remainingNum
                          }}{{ orderItem.sellingUnitName }}
                        </div>
                      </div>
                      <div
                        class="fl"
                        v-if="!isCityPartner"
                        style="flex: 1; text-align: center"
                      >
                        {{
                          orderItem.openStraight
                            ? "虚拟仓"
                            : orderItem.k3StockName
                        }}
                      </div>
                    </div>
                    <p
                      v-if="orderItem.remark"
                      style="lineheight: 26px; color: rgba(255, 89, 24, 1)"
                    >
                      商品备注：{{ orderItem.remark }}
                    </p>
                  </div>
                </div>
                <!-- 买家名称 -->
                <div class="bl bb tac pt-20" style="flex: 1">
                  <p class="tac mb-5">{{ order.buyerPhone }}</p>
                  <p>{{ order.buyerName }}</p>
                </div>
                <!-- 支付金额 -->
                <div class="bl bb tac pt-20" style="flex: 1">
                  <p class="tac mb-5" style="word-break: break-all">
                    ¥{{ order.totalMoney | fmoney }}
                  </p>
                  <p>含运费 (￥{{ order.freightMoney | fmoney }})</p>
                  <p class="mt-10" v-if="order.clientSource">
                    <i v-if=" order.clientSource != 1 &&order.clientSource != 7 && order.clientSource != 10" class="iconfont icon-phone font-14 color-3"></i>
                    <i v-else-if="order.clientSource == 10" class="iconfont icon-store font-14 color-3" ></i>
                    <i v-else class="iconfont icon-computed font-14 color-3" ></i>
                    {{ order.clientSource | clientSource }}
                  </p>
                </div>
                <!-- 交易状态 -->
                <div class="bl bb tac pt-20" style="flex: 1">
                  <p
                    class="color-danger"
                    v-if="order.editAuditState == 1 && order.orderStatus == 1"
                  >
                    待审核
                  </p>
                  <div v-else>
                    <p v-if="order.orderStatus == 1">待付款</p>
                    <p
                      class="mt-10"
                      v-if="
                        !(order.editAuditState == 1 && order.orderStatus == 1)
                      "
                    >
                      <span
                        class="color-danger font-12"
                        v-if="
                          (order.editAuditState == 3 ||
                            order.editAuditState == null) &&
                          order.orderStatus == 1 &&
                          (order.lastestRemittanceAuditStatus == 2 ||
                            order.lastestRemittanceAuditStatus == null)&&
                            order.remainAutoCancleTime
                        "
                        >剩余
                        <span
                          v-text="
                            typeof order.remainAutoCancleTime == 'string'
                              ? order.remainAutoCancleTime
                              : null
                          "
                        ></span>
                      </span>
                    </p>
                  </div>
                  <p v-if="order.orderStatus == 0">已关闭</p>
                  <p v-else-if="order.orderStatus == 2">
                    <!-- order.shipStatus == 4 ? "部分发货" 2021/11/25已废弃-->
                    待发货
                  </p>

                  <p v-else-if="order.orderStatus == 3">已发货</p>
                  <p v-else-if="order.orderStatus == 4">已完成</p>
                  <p v-else-if="order.orderStatus == 5">已退款</p>
                  <!-- <div v-if="order.isAfterSale">
                                        <el-button type='text' class="font-12 orderDetail_btn"  >售后记录</el-button>
                                    </div> -->
                </div>
                <!-- 支付方式 -->
                <div class="bl bb tac pt-20" style="flex: 1">
                  <span>{{ order.payTypeName }}</span>
                </div>
                <!-- 积分 -->
                <div class="bl bb tac pt-20" style="flex: 1">
                  <span>{{ order.predictiveIntegral }} 积分</span>
                </div>

                
                <div class="bl bb tac pt-20 pb-10" style="flex: 1.5">
                  <!-- <div v-if="order.orderStatus == 1&&(order.editAuditState == null || order.editAuditState == 3) && (order.lastestRemittanceAuditStatus !== 1 || order.lastestRemittanceAuditStatus == null)"
                                         class="mb-10">
                                        <el-button @click="paymentForOther(order)" class="font-12">代付款</el-button>
                                    </div> -->
                  <div
                    v-if="
                      (order.orderStatus == 2 ||
                      order.orderStatus == 3 ||
                      order.orderStatus == 4 ||
                      order.orderStatus == 5 ||
                      order.orderStatus == 0)&&
                      showButton('ORDERS_CUSTOMER', 100054)
                    "
                  >
                    <el-button
                      class="ml-0 mb-10 font-12"
                      @click="tryAgainOrder(order.parentOrderSn, order)"
                      type="danger"
                    >
                      再来一单
                    </el-button>
                  </div>
                  <!-- <el-button v-if="order.orderStatus == 3"
                                                type='text' @click="print(orderInfoObj.parentOrderSn,1)">打印发货单
                                     </el-button>-->
                  <!--  v-if="" -->
                  <!-- order.editAuditState != 2 &&
                      order.editAuditState != 1 &&
                      order.orderStatus == 1 &&
                      order.lastestRemittanceAuditStatus !== 1 -->
                  <div
                    v-if="order.enablePay"
                    class="mb-10"
                  >
                    <el-button
                      @click="orderPay(order, 1)"
                      type="danger"
                      class="font-12"
                      >去付款</el-button
                    >
                  </div>
                  <div
                    v-if="
                      order.lastestRemittanceAuditStatus == 1 &&
                      showButton('AUDIT', 100054)&&
                      order.enableAuditRemittance
                    "
                    class="color-danger mb-10"
                  >
                    <el-button
                      class="font-12"
                      type="danger"
                      @click="
                        auditRemittance(
                          order.parentOrderSn,
                          1,
                          order.orderStatus
                        )
                      "
                      >审核付款凭证
                    </el-button>
                  </div>
                  <div v-if="order.editAuditState != 1">
                    <div class="mb-10" v-if="order.existAppDelivery">
                      <el-button
                        type="danger"
                        class="font-12"
                        size="mini"
                        @click="auditDelivery(order)"
                        >提货审核
                      </el-button>
                    </div>
                    <!--  order.deliverySeparate &&
                        order.orderStatus == 2 &&
                        order.shipStatus !== 4 &&
                        (type == 4 || type == 0 || type == 2) -->
                    <div
                      class="mb-10"
                      v-if="order.enableDeliveryGoods"
                    >
                      <el-button
                        class="font-12"
                        size="mini"
                        type="danger"
                        @click="notifySeparate(order.sn, order.sellerName)"
                        >代客提货
                      </el-button>
                    </div>
                    <div
                      style="margin: 10px 0"
                      v-if="
                        order.enableReturnDeliveryGoods&&
                        showButton('RETURN_CUSTOMSR', 100054)
                      "
                    >
                      <el-button
                        class="font-12"
                        size="mini"
                        type="danger"
                        @click="openDialogInfo(order.sn, order.sellerName)"
                        >代客退货</el-button
                      >
                    </div>


                   <!-- v-if="order.orderModifyAudit==null&&order.orderStatus == 1 && (!order.voucherCutMonney || order.voucherCutMonney == 0) && order.lastestRemittanceAuditStatus != 1" -->
                    <div v-if="order.enableEdit"
                      class="mb-10"
                    >
                    <!-- enableEdit -->
                      <el-button
                        class="font-12"
                        @click="changeorderdetail(order, 1)"
                        >修改订单
                      </el-button>
                    </div>


                    <div>
                      <div>
                        <el-button
                          class="ml-0 mb-10 font-12"
                          @click="handleCancelOrder(order)"
                          v-if="
                            showButton('CANCEL', 100054) &&
                            order.orderStatus == 1 &&
                            order.payStatus == 1 &&
                            (order.lastestRemittanceAuditStatus == null ||
                              order.lastestRemittanceAuditStatus == 2)
                          "
                        >
                          取消订单
                        </el-button>

                        <div
                          v-if="order.lastestRemittanceAuditStatus == 2"
                          class="color-danger font-12"
                        >
                          支付凭证审核未通过
                        </div>
                      </div>
                    </div>
                  </div>
                  <div >
                      <el-button class="mb-10 font-12"   v-if="order.enableAfterSale"  @click="applyForAfterService(order)">
                          申请售后
                      </el-button>
                  </div>
                    <div >
                      <el-button class="mb-10 font-12"   v-if="order.enableRefund"  @click="confirmRefundApply(order)">
                          仅退款
                      </el-button>
                  </div>

                  <div class="mb-10">
                    <el-button
                      class="font-12"
                      @click="goToOrderDetail(order.sn, 1, order.orderStatus)"
                      >查看订单
                    </el-button>
                  </div>
                  <div
                    v-if="
                      order.jdeSn == null &&
                      order.orderStatus == 2 &&
                      order.k3Sn == null &&
                      isCityPartner
                    "
                  >
                    <el-button
                      class="mb-10 font-12"
                      type="danger"
                      @click="shipConfirmDialog(order.sn, order.orderStatus)"
                      v-if="showButton('DELIVER', 100054)"
                      >发 货
                    </el-button>
                  </div>
                  <el-button
                    class="font-12 ml-0 mb-10"
                    type="danger"
                    v-if="
                      showButton('PRINT', 100054) &&
                      order.orderStatus == 3 &&
                      order.jdeSn == null &&
                      order.k3Sn == null &&
                      isCityPartner
                    "
                    @click="print(order.sn, 2)"
                  >
                    打印发货单
                  </el-button>
                  <div v-if="order.orderStatus == 0">
                      <!-- 已关闭才能删除 -->
                      <el-button class="mb-10 font-12" @click="openDeleteDialog(order.sn)">删除订单</el-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 信用特批 -->
        <el-dialog
          title="提示"
          :visible.sync="paymentOnBehalfDialog"
          :close-on-click-modal="false"
          @close="closePaymentOnBehalfDialog"
          class="paymentOnBehalfDialog"
        >
          <div class="pl-30 pr-30 font-14">
            <div>
              <span>{{
                preInfo.accountsAvailable
                  ? preInfo.availableMsg
                  : preInfo.unAvailableMsg
              }}</span>
            </div>
            <!--
                            isOpenSellerSpecialCredit:是否设置了特批
                            isNeedCheckSpecial：账期是否异常
                            openAccountPeriod：是否开通授信
                        -->
            <div
              v-if="isOpenSellerSpecialCredit && preInfo.isNeedCheckSpecial"
              class="mt-20"
            >
              <el-checkbox v-model="checkedCredit">开启信用特批</el-checkbox>
              <div class="clearfix mt-20" v-if="checkedCredit">
                <el-form
                  :inline="true"
                  class="fl goodsForm"
                  label-width="80px"
                  :rules="rulesCredit"
                  :model="creditForm"
                  ref="creditForm"
                >
                  <!--去除chrome浏览器自动填充账号密码-->
                  <el-form-item
                    style="width: 0px; height: 1px; overflow: hidden; margin: 0"
                  >
                    <el-input
                      type="text"
                      v-model.trim="creditForm.code0"
                      :maxlength="6"
                    ></el-input>
                  </el-form-item>
                  <el-form-item
                    style="width: 0px; height: 1px; overflow: hidden; margin: 0"
                  >
                    <el-input
                      type="password"
                      v-model="creditForm.pass0"
                      :maxlength="16"
                    ></el-input>
                  </el-form-item>
                  <el-form-item
                    label="特批人"
                    prop="specialPerson"
                    style="margin-left: -8px"
                  >
                    <el-input
                      auto-complete="off"
                      :maxlength="10"
                      v-model.trim="creditForm.specialPerson"
                    ></el-input>
                  </el-form-item>
                  <br />
                  <el-form-item label="特批密码" prop="specialPassword">
                    <el-input
                      auto-complete="off"
                      :minlength="6"
                      :maxlength="16"
                      type="password"
                      v-model.trim="creditForm.specialPassword"
                    ></el-input>
                  </el-form-item>
                  <br />
                  <el-form-item label="" v-if="checkedCredit && errMassage">
                    <p class="color-danger ml-20">{{ errMassage }}</p>
                  </el-form-item>
                </el-form>
              </div>
            </div>
          </div>
          <div slot="footer" class="dialog-footer">
            <el-button
              type="danger"
              :disabled="
                (!preInfo.accountsAvailable && !isOpenSellerSpecialCredit) ||
                (!preInfo.accountsAvailable &&
                  isOpenSellerSpecialCredit &&
                  !checkedCredit)
              "
              :loading="btnLoading"
              @click="handlepaymentOnBehalfDialog"
              >确 定
            </el-button>
            <el-button @click="paymentOnBehalfDialog = false">取 消</el-button>
          </div>
        </el-dialog>
        <!-- 删除订单 -->
        <el-dialog
          class="cancelOrderDialog"
          :close-on-click-modal="false"
          @close="closeDeleteDialog"
          title="删除订单理由"
          :visible.sync="deleteOrderVisible"
          width="500px"
        >
          <el-form
            class="pl-30 pr-30"
            :model="deleteForm"
            ref="deleteForm"
            :rules="rules"
          >
            <el-form-item label="" prop="deleteReason">
              <el-input
                type="textarea"
                placeholder="请输入删除订单的理由..."
                :autosize="{ minRows: 3, maxRows: 5 }"
                v-model.trim="deleteForm.deleteReason"
              ></el-input>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button
              type="danger"
              @click="deleteOrderCommit"
              >提 交
            </el-button>
            <el-button @click="deleteOrderVisible = false">取 消</el-button>
          </div>
        </el-dialog>
        <!--取消订单弹出框-->
        <el-dialog
          class="cancelOrderDialog"
          :close-on-click-modal="false"
          @close="closeOrderDialog"
          title="取消订单理由"
          :visible.sync="cancelOrderVisible"
          width="500px"
        >
          <el-form
            class="pl-30 pr-30"
            :model="form"
            ref="cancelOrderForm"
            :rules="rules"
          >
            <el-form-item label="" prop="cancleReason">
              <el-input
                type="textarea"
                placeholder="请输入取消订单的理由..."
                :autosize="{ minRows: 3, maxRows: 5 }"
                v-model.trim="form.cancleReason"
              ></el-input>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button
              type="danger"
              :loading="btnLoading"
              @click="cancelOrderCommit(order.parentOrderSn)"
              >提 交
            </el-button>
            <el-button @click="cancelOrderVisible = false">取 消</el-button>
          </div>
        </el-dialog>
      </div>
      <el-pagination
        v-if="total > 0"
        class="tar mt-20"
        :current-page="currentPage"
        :page-size="10"
        :total="total"
        layout="prev , pager, next, jumper"
        @current-change="handleCurrentChange"
      >
        <!-- 分页组件 -->
      </el-pagination>
    </div>
    <!--代客提货-->
    <separateOrderDialog
      :msg="msg"
      :dialogVisible.sync="separateDialog"
      :orderSn="orderSn"
      :sellerName="sellerName"
      @deliverySuccessCallback="handleCheck"
    />
    <!-- 代客退货 -->
    <dialogInfo
      :dialogVisible.sync="dialogInfoShow"
      :orderSn="orderSn"
      :sellerName="sellerName"
      @deliverySuccessCallback="dialogInfoCallback"
    />
    <!-- k3显示信息 -->
    <el-popover
      ref="popoverFour"
      placement="bottom"
      width="300"
      trigger="hover"
    >
      <div class="copyInfo" v-if="k3info.k3Sn">
        <div class="copyNumber">
          <span class="numberOne">销售单号：</span>
          <span class="numberTwo">{{ k3info.k3Sn }}</span>
        </div>
        <span
          class="copyText"
          v-clipboard:copy="k3info.k3Sn"
          v-clipboard:success="onCopy"
          v-clipboard:error="onError"
          >复制</span
        >
      </div>
      <div class="outHouse" v-if="k3info.k3OutStockNos">
        <div style="width: 70px">出库单号：</div>
        <div class="outHouseContent">
          <div
            class="outHouseRight"
            v-for="(item, index) in k3info.k3OutStockNos"
            :key="index + 'ii'"
          >
            <span class="outHouseNumber">{{ item }}</span>
            <span
              class="outHouseCopy"
              v-clipboard:copy="item"
              v-clipboard:success="onCopy"
              v-clipboard:error="onError"
              >复制</span
            >
          </div>
        </div>
      </div>
      <div class="copyInfo" v-if="k3info.k3ReceiveBillNo">
        <div class="copyNumber">
          <span class="numberOne">收款单号：</span>
          <span class="numberTwo">{{ k3info.k3ReceiveBillNo }}</span>
        </div>
        <span
          class="copyText"
          v-clipboard:copy="k3info.k3ReceiveBillNo"
          v-clipboard:success="onCopy"
          v-clipboard:error="onError"
          >复制</span
        >
      </div>
      <!--      -->
    </el-popover>
    <payPopup
      :dialogvisible.sync="payVisible"
      @getChild="getPayVisible"
      @paySuccess="getSuccessPay"
    ></payPopup>
    <!-- 图片预览 -->
    <imgview
      :visible="imgsvisible"
      :imgs="showimgs"
      :startindex="startindex"
      @close="imgsvisible = false"
    ></imgview>

    <!-- 导入错误弹框 -->
    <el-dialog
      title="批量导入"
      :visible.sync="upShow"
      width="70%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-alert
        title="部分订单已成功导入，以下为导入失败的订单！"
        type="error"
        show-icon
        style="margin: 0 20px"
        :closable="false"
      >
      </el-alert>
      <div class="divInfo">
        <el-card class="boxCard" v-for="(item, index) in errList" :key="index">
          <div slot="header" class="clearfix">
            <span>{{ item.msg }}</span>
          </div>
          <div class="tabFlex tabTop">
            <span class="tabSpan">客户信息</span>
            <span class="tabSpan">销售员</span>
            <span class="tabSpan">商品编码</span>
            <span class="tabSpan">商品单价</span>
            <span class="tabSpan">商品数量</span>
            <span class="tabSpan">仓库</span>
            <span class="tabSpan">是否为赠品</span>
            <span class="tabSpan">赠品费用</span>
            <span class="tabSpan">订单备注</span>
          </div>
          <div
            v-for="(items, indexs) in item.errorExcelParams"
            :key="indexs"
            class="tabFlex tabRow"
          >
            <span class="tabSpan">{{ items.customerName }}</span>
            <span class="tabSpan">{{ items.saleEmployeeName }}</span>
            <span class="tabSpan">{{ items.k3GoodsNo }}</span>
            <span class="tabSpan">{{ items.unitPrice }}</span>
            <span class="tabSpan">{{ items.num }}</span>
            <span class="tabSpan">{{ items.k3StockName }}</span>
            <span class="tabSpan">{{ items.isGift ? "是" : "否" }}</span>
            <span class="tabSpan">{{ items.k3GiftTypeName }}</span>
            <span class="tabSpan">{{ items.remark }}</span>
          </div>
        </el-card>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="upShow = false">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 导入错误弹框 -->
    <el-dialog
      title="批量导入"
      :visible.sync="upTextShow"
      width="70%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-alert
        title="表格解析失败，所有订单并未导入！请检查以下内容，修改后重新导入！"
        type="error"
        show-icon
        style="margin: 0 20px 24px"
        :closable="false"
      >
      </el-alert>
      <div class="divInfo">
        <div
          v-for="(item, index) in errTextList"
          :key="index"
          style="margin: 0 20px"
        >
          <span>{{ item }}</span>
          <el-divider></el-divider>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="upTextShow = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import separateOrderDialog from "../../store/stock/separateOrderDialog.vue";
import dialogInfo from "~/components/refundGoods/dialogInfo.vue";
import TreeGroup from "~/components/treeGroup";
import districtSelect from "@/components/districtSelect/districtSelect.vue";

import { axiosApi, basePath } from "~/api/api";
import { mapMutations, mapActions } from "vuex";
import { deleteOrder, refundApply } from "~/api/order";

import md5 from "md5";
export default {
  name: "order",
  components: {
    separateOrderDialog,
    dialogInfo,
    TreeGroup,
    districtSelect
  },
  computed: {
    isChildCompany() {
      return this.$store.state.clientType.isChildCompany;
    },
    isCityPartner() {
      return this.$store.state.clientType.isCityPartner;
    },
  },
  data() {
    var checkName = (rule, value, callback) => {
      const re = /^[\u4e00-\u9fa5a-zA-Z0-9]{1,10}$/gi;
      if (!re.test(value)) {
        callback(new Error("请输入特批人名称"));
      } else {
        callback();
      }
    };
    var checkPassWord = (rule, value, callback) => {
      const reg = /^[^\u4e00-\u9fa5]{6,16}$/gi;
      if (!reg.test(value)) {
        callback(new Error("请输入正确的密码"));
      } else {
        callback();
      }
    };
    return {
      deleteOrderVisible:false,
      deleteForm: {
        orderSn: null,
        deleteReason:null,
      },

      payVisible: false,
      erpvalue: false,
      // isAfterService:'1',//开启售后
      // useWhereList:['1','2'],//适用
      showErpErrorCount: false,
      k3info: {
        k3Sn: "", //销售单号
        k3ReceiveBillNo: "", //收款单号
        k3OutStockNos: "", //出库单号
      },
      isOpenSellerSpecialCredit: false, //是否开启信用特批
      creditForm: {
        specialPerson: "",
        specialPassword: "",
      }, //信用特批
      errMassage: null,
      rulesCredit: {
        specialPerson: [
          { required: true, message: "请输入特批人名称", trigger: "blur" },
          { validator: checkName, trigger: "blur" },
        ],
        specialPassword: [
          { required: true, message: "请输入特批密码" },
          { validator: checkPassWord, trigger: "blur" },
        ],
      },
      checkedCredit: false, //是否开启信用特批
      setTime: false,
      setFreight: false,
      formSet: {
        isOpenOrder: 1,
        cancelTime: "",
        freight: "",
        isOpenOrderTypeOpt: 0, //是否开通订单单据类型修改
        openAfterSales: "2", //开通售后
        checklist: [], //开启pc 云销
      },
      isHotel: sessionStorage.getItem("isHotel"),
      flag: false,
      cloneForm: {},
      giftTypeData: [],
      msg: "当前订单中的所有商品可提货数量不足",
      sellerName: "",
      orderSn: null,
      separateDialog: false, // 代客提货
      dialogInfoShow: false, // 代客退货
      a: "order.list",
      b: "订单列表.xls",
      c: sessionStorage.getItem("traderId"),
      d: 1,
      e: 2,
      type: "0",
      dataTabs: [
        { title: "所有订单", num: 0 },
        { title: "待付款", num: 0 },
        { title: "待发货", num: 0 },
        {
          title: "已发货",
          num: 0,
        },
        { title: "分批待提货", num: 0 },
        { title: "已完成", num: 0 },
        { title: "已关闭", num: 0 },
        { title: "待审核", num: 0 },
      ],
      data: ["1"],
      cancelOrderVisible: false, //控制取消订单弹出框dialogVisible
      reason: null, //取消订单的理由
      customerType: [],
      form: {
        groupId: null, //分组
        ctype: null, //客户类型
        clientSource: null, //订单来源
        orderSn: null, //订单编号
        storeName: null, //买家名称
        buyerMobile: null, //买家手机
        itemName: null, //商品名称
        deliveryNo: null, //物流单号
        gmtOrderStart: null, //下单开始时间
        gmtOrderEnd: null, //下单结束时间
        orderDateRange: [], //下单时间数组
        traderType: 2, //供应商类型
        sellerId: sessionStorage.traderId,
        orderStatus: null, //订单状态
        editAuditState: null, //待审核
        cancleReason: null, //取消订单的理由
        managerName: null, // 销售员
        payTypeList: [],
      },
      payTypeInfo:[
        {value:'0',label:'组合支付'},
        {value:'1',label:'线下支付'},
        {value:'3',label:'在线支付'},
        {value:'4',label:'余额支付'},
        {value:'6',label:'账期支付'},
        {value:'10',label:'直营店支付'},
        {value:'11',label:'久币支付'},
      ],
      options: [
        { value: null, label: "全部来源" },
        { value: 1, label: "云商PC端" },
        { value: 2, label: "云商IOS端" },
        { value: 3, label: "云商Android端" },
        { value: 4, label: "云销IOS端" },
        { value: 5, label: "云销Android端" },
        { value: 7, label: "PC代下单" },
        { value: 8, label: "餐链下单" },
        { value: 9, label: "超B下单" },
        { value: 10, label: "门店POS" },
      ],
      optionsType: [
        { value: null, label: "全部类型" },
        { value: 1, label: "经销商" },
        { value: 2, label: "终端" },
        { value: 6, label: "普通酒店" },
        { value: 3, label: "久加久*久柜" },
        {
          value: 4,
          label: "久加久*连锁店",
          children: [
            { value: 41, label: "直营" },
            { value: 42, label: "加盟" },
          ],
        },
        { value: 5, label: "久加久*店中店" },
      ],
      rules: {
        cancleReason: [
          {
            max: 30,
            message: "不能超过30个字！",
          },
        ],
        deleteReason: [
          {
            required: true, 
            max: 30,
            message: "不能超过30个字！",
            trigger: 'blur'
          },
        ],
      },
      orderList: [], //订单列表
      currentPage: 1,
      total: null,
      loading: false,
      order: {}, //订单改价的订单详情
      order2: {}, //订单改价显示
      passRemark: false, //备注验证
      badge: {
        //标记
        allCount: 0, //全部订单
        notPayCount: 0, //未付款
        notDeliveryCount: 0, //已付款-待发货
        orderDeliveriedCount: 0, //已发货-待收货
        deliverySeparateCount: 0, // 订单提货
        successCount: 0, //已完成
        cancleCount: 0, //已取消
        notAuditCount: 0, //未审核
      },
      auditCompetence: {
        isOpenOrder: null,
      }, //审核权限
      count: 0, //是否开通订单审核权限记录次数
      btnLoading: false,
      checkOrderSn: [], //选中的订单列表
      checkAll: false,
      checkOrder: [],
      preInfo: {}, // 预支付信息
      paymentOnBehalfDialog: false, //代付款显，隐
      clickPaymentOrder: {}, // 点击代付款按钮，获取订单信息
      imgsvisible: false,
      startindex: 0,
      showimgs: [],
      upShow: false, // 导入错误弹框
      upTextShow: false, // 导入解析错误弹框
      errList: [],
      errTextList: [],
    };
  },
  filters: {
    clientSource(val) {
      let str = "";
      switch (val) {
        case 1: {
          str = "云商PC端";
          break;
        }
        case 2: {
          str = "云商IOS端";
          break;
        }
        case 3: {
          str = "云商Android端";
          break;
        }
        case 4: {
          str = "云销IOS端";
          break;
        }
        case 5: {
          str = "云销Android端";
          break;
        }
        case 6: {
          str = "WX下单";
          break;
        }
        case 7: {
          str = "PC代下单";
          break;
        }
        case 8: {
          str = "餐链下单";
          break;
        }
        case 9: {
          str = "超B下单";
          break;
        }
        case 10: {
          str = "门店POS";
          break;
        }
        default: {
          str = "";
          break;
        }
      }
      return str;
    },
  },
  // beforeMount() {
  //   if (this.isCityPartner) {
  //     this.dataTabs = [
  //       { title: "所有订单", num: 0 },
  //       { title: "待付款", num: 0 },
  //       { title: "待发货", num: 0 },
  //       { title: "已发货", num: 0 },
  //       { title: "已完成", num: 0 },
  //       { title: "已关闭", num: 0 },
  //       { title: "待审核", num: 0 },
  //     ];
  //     this.options.pop();
  //   }
  // },
  activated() {
    //只刷新数据，不改变整体的缓存
    this.changeCredit();
    let type = sessionStorage.getItem("orderType");
    if (type) {
      this.type = type;
      if (this.type == "0") {
        //全部订单
        this.form.orderStatus = null;
      } else if (this.type == "1") {
        //待付款
        this.form.orderStatus = 1;
      } else if (this.type == "2") {
        //待发货
        this.form.orderStatus = 2;
      } else if (this.type == "3") {
        //待收货
        this.form.orderStatus = 3;
      } else if (this.type == "4") {
        // 分批发货
        this.form.orderStatus = null;
        this.form.orderStatusList = [2,3]
        this.form.shipStatusList = [1,4]
        this.form.deliverySeparate = 1;
      } else if (this.type == "5") {
        //已完成
        this.form.orderStatus = 4;
      } else if (this.type == "6") {
        //已关闭
        this.form.orderStatus = 0;
      } else if (this.type == "7") {
        this.form.orderStatus = 1;
        this.form.editAuditState = 1; //待审核
      }
    }
    // else {
    //   this.type = "0";
    // }
    this.findSellerJurisdiction();
    this.form.sellerId = sessionStorage.traderId;
    this.queryOrderList(this.form);
    this.anonOrderCount();
  },
  mounted() {
    this.$nextTick(() => {
      this.changeCredit();
      let type = sessionStorage.getItem("orderType");
      if (type) {
        this.type = type;
        if (this.type == "0") {
          //全部订单
          this.form.orderStatus = null;
        } else if (this.type == "1") {
          //待付款
          this.form.orderStatus = 1;
        } else if (this.type == "2") {
          //待发货
          this.form.orderStatus = 2;
        } else if (this.type == "3") {
          //待收货
          this.form.orderStatus = 3;
        } else if (this.type == "4") {
          // 分批发货
          this.form.orderStatus = null;
          this.form.orderStatusList = [2,3]
          this.form.shipStatusList = [1,4]
          this.form.deliverySeparate = 1;
        } else if (this.type == "5") {
          //已完成
          this.form.orderStatus = 4;
        } else if (this.type == "6") {
          //已关闭
          this.form.orderStatus = 0;
        } else if (this.type == "7") {
          this.form.orderStatus = 1;
          this.form.editAuditState = 1; //待审核
        }else if (this.type == "7") {
          this.form.orderStatus = 1;
          this.form.editAuditState = 1; //待审核
        }
      }
      // else {
      //   this.type = "0";
      // }
      this.findSellerJurisdiction();
      // this.anonOrderCount();
      this.form.sellerId = sessionStorage.traderId;
      this.queryOrderList(this.form);
      this.anonOrderCount();
      sessionStorage.removeItem("orderType");
      sessionStorage.removeItem("draftOrderInfo");
    });
  },
  watch: {
    form: {
      handler: function () {
        this.flag = false;
      },
      deep: true,
    },
    paymentOnBehalfDialog(val) {
      if (!val) {
        this.checkedCredit = false;
        this.errMassage = null;
        this.creditForm = { specialPerson: "", specialPassword: "" };
      }
    },
  },
  methods: {
    //申请售后
    applyForAfterService(order){
        console.log(order,'or')
        let orderSn = order.parentOrSon == 3 ? order.parentOrderSn : order.sn;
        this.$router.push({path:basePath + '/saleAddService',query:{
            orderSn,
        }})
    },

    // 仅退款确认

    confirmRefundApply(order){
      this.$confirm('是否确认对订单进行全额退款?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.refundApplyBtn(order)
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          });          
        });
    },
    // 仅退款
    refundApplyBtn(order){
        refundApply({orderSn:order.sn}).then(res=>{
            //查看
            this.$router.push(basePath + "/afterServiceDetail/" + res.serviceNo);
        }).catch(err=>{

        })
    },

    openDeleteDialog(orderSn){
      this.deleteForm.orderSn = orderSn
      this.deleteOrderVisible = true
    },
    closeDeleteDialog(){
      this.deleteOrderVisible = false
      this.deleteForm = {
        orderSn: null,
        deleteReason:null,
      }
    },
    deleteOrderCommit(){
      this.$refs['deleteForm'].validate((valid) => {
        if (valid) {
          this.deleteOrder()
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 删除订单
    deleteOrder(){
      deleteOrder(this.deleteForm).then(res=>{
        this.$message({
            message: "删除成功!",
            type: "success",
          });
        this.closeDeleteDialog()
        this.currentPage = 1
        this.handleQueryBtn()
      }).catch(err=>{

      })
    },
    UpSuccess(res) {
      if (res.code === "000000") {
        if (res.data.code === "0") {
          this.$message({
            message: "导入成功!",
            type: "success",
          });
        } else if (res.data.code === "1") {
          // 下单错误
          this.errList = res.data.resultOrders;
          this.upShow = true;
        } else if (res.data.code === "2") {
          // 表单解析错误
          this.errTextList = res.data.resultBuildMsg;
          this.upTextShow = true;
        }
      } else {
        this.$confirm(res.message, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {})
          .catch(() => {});
      }
      this.$loading().close();
    },
    UpBefore(file) {
      this.$loading({
        fullscreen: true,
        lock: true,
        text: "正在导入...",
      });
      return true;
    },
    UpError(err, file, fileList) {
      this.$loading().close();
      this.$message.error("导入失败！");
    },
    getSuccessPay(val) {
      if (val) {
        this.queryOrderList(this.form);
      }
    },
    getPayVisible(val) {
      this.payVisible = false;
    },
    ...mapMutations({
      setTask: "setTask",
    }),
    goDraftOrder() {
      this.$router.push(basePath + "/draftOrder");
    },
    lookImageDetail(list, index) {
      this.imgsvisible = true;
      this.startindex = index;
      this.showimgs = list;
    },
    changektInfo(order) {
      this.erpvalue = true;
      this.$set(this.k3info, "k3Sn", order.k3Sn);
      this.$set(this.k3info, "k3ReceiveBillNo", order.k3ReceiveBillNo);
      this.$set(this.k3info, "k3OutStockNos", order.k3OutStockNos);
      this.$forceUpdate();
    },
    onCopy() {
      this.$message({
        type: "success",
        message: "复制成功",
      });
    },
    onError() {
      this.$message({
        type: "success",
        message: "复制失败",
      });
    },    
    //再来一单
    tryAgainOrder(orderSn, order) {
      // let body = {
      //     buyerId:order.buyerId,
      //     sellerId:order.sellerId,
      //     orderSn
      // }
      let body = {
        buyerId: order.buyerId,
        sellerId: order.sellerId,
        accountSource: 1,
      };
      axiosApi("/trader/findClientAllList/select", body, (res) => {
        let data = res.data.elements;
        if (Array.isArray(data) && data.length == 0) {
          this.$message.info("该客户已被删除");
        } else {
          this.$router.push(
            basePath +
              "/newValetOrder?type=1&whichPage=4&orderSn=" +
              orderSn +
              "&buyerId=" +
              order.buyerId +
              "&sellerId=" +
              order.sellerId
          ); //再来一单
        }
      });
    },
   
  
    //打印发货单、
    print(orderSn, type, status) {
      if (this.isCityPartner) {
        this.goToOrderDetail(orderSn, 1, status);
      } else {
        window.open(
          window.location.origin +
            basePath +
            "/orderPrint?orderSn=" +
            orderSn +
            "&type=" +
            type
        );
      }
    },


    //查看已生成报表
    checkTable() {
      this.setTask({
        url: "/datacenter/exportTask/listExportTask",
        businessType: "b2b",
        taskType: "b2b_order",
      });
      this.$router.push(basePath + "/statement");
    },
    //批量导出新增
    exportOrder() {
      if (this.cloneForm) {
        if (this.cloneForm.orderDateRange) {
          if (
            !this.cloneForm.orderDateRange[0] ||
            !this.cloneForm.orderDateRange[1]
          ) {
            this.$message.warning("请选择下单时间并查询，再进行批量导出");
            return;
          }
        } else {
          this.$message.warning("请选择下单时间并查询，再进行批量导出");
          return;
        }
      } else {
        this.$message.warning("请选择下单时间并查询，再进行批量导出");
        return;
      }
      this.optionsType.map((item) => {
        if (item.value == this.cloneForm.ctype) {
          this.cloneForm.ctypeName = item.label;
        }
        if (item.children) {
          item.children.forEach((list) => {
            if (list.value == this.cloneForm.ctype) {
              this.cloneForm.ctypeName = "久加久*连锁店/" + list.label;
            }
          });
        }
      });
      this.options.map((v) => {
        if (v.value == this.cloneForm.clientSource) {
          this.cloneForm.clientSourceName = v.label;
        }
      });

      this.cloneForm.sellerId =
        sessionStorage.traderId || sessionStorage.traderId;
      axiosApi(
        "/datacenter/exportTask/addB2BOrder",
        this.cloneForm,
        (res) => {
          this.$notify({
            title: "成功",
            message:
              "导出的文件正在生成，请不要重复点击，文件生成后，可以在“查看已生成报表”中查看!",
            type: "success",
          });
        },
        1,
        10,
        (err) => {
          this.$message.error(err.data.message);
        }
      );
    },
    orderPay(orderSn, type) {
      // let a = null
      // if (type == 2) a = 2
      // if (type == 1) a = 1
      sessionStorage.isParentPurchaseOrderType = type;
      sessionStorage.purchaseOrderType = this.type;
      sessionStorage.setItem("orderSn", orderSn.parentOrderSn);
      sessionStorage.isParentPurchaseOrderType = type;
      sessionStorage.purchaseOrderType = this.type;
      sessionStorage.setItem("orderSn", orderSn.parentOrderSn);
      // window.open(window.location.origin + basePath + '/payOrder?topay=1&seller=1')
      this.payVisible = true;
    },
    // 打开代客提货
    notifySeparate(data, name) {
      this.separateDialog = true;
      this.orderSn = data;
      this.sellerName = name;
    },
    // 打开代客退货
    openDialogInfo(data, name) {
      this.dialogInfoShow = true;
      this.orderSn = data;
      this.sellerName = name;
    },
    dialogInfoCallback() {
      // 退货弹框确定回调
      this.queryOrderList(this.form);
    },
    handleCheck() {
      this.findSellerJurisdiction();
      this.queryOrderList(this.form);
    },
    auditDelivery(order) {
      // 提交审核
      sessionStorage.orderType = this.type;
      window.open(
        window.location.origin + basePath + "/orderDelivery?orderSn=" + order.sn
      );
    },
    deliveryBtn() {
      sessionStorage.orderType = this.type;
      this.$router.push({ path: basePath + "/orderDelivery" });
    },
    changeCredit() {
      axiosApi(
        "/trader/sellerSpecialCredit/select",
        { isSetNull: true },
        (res) => {
          if (res.data.data) {
            this.isOpenSellerSpecialCredit = true;
          }
        },
        1,
        10,
        (err) => {
          this.$message.warning(err.data.message);
        }
      );
      this.dialogCreditVisible = true;
    },
    paymentForOther(order) {
      // 代付款
      axiosApi(
        "/anon/order/prepay/info",
        {
          orderSn: order.parentOrSon == 3 ? order.parentOrderSn : order.sn,
        },
        (res) => {
          if (res.data.data) {
            this.preInfo = res.data.data;
            this.paymentOnBehalfDialog = true;
          }
        },
        1,
        10,
        (res) => {
          this.$message.error(res.data.message);
        }
      );
      this.clickPaymentOrder = order;
    },
    pay(body) {
      axiosApi(
        "/anon/order/hotel/pay",
        {
          orderSn:
            this.clickPaymentOrder.parentOrSon == 3
              ? this.clickPaymentOrder.parentOrderSn
              : this.clickPaymentOrder.sn,
          source: 2,
          payMethod: 6,
          ...body,
        },
        (res) => {
          this.$message.success("代付款成功");
          this.btnLoading = false;
          this.paymentOnBehalfDialog = false;
          setTimeout(() => {
            this.queryOrderList(this.form);
            this.findSellerJurisdiction();
            this.anonOrderCount();
          }, 500);
        },
        1,
        10,
        (res) => {
          // this.$message.warning(res.data.message)
          this.btnLoading = false;
          this.errMassage = res.data.message;
          // this.paymentOnBehalfDialog = false
        }
      );
    },
    handlepaymentOnBehalfDialog() {
      // 弹框二次确定
      this.btnLoading = true;
      let body = JSON.parse(JSON.stringify(this.creditForm));
      delete body.code0;
      delete body.pass0;
      body.specialPassword = md5(body.specialPassword);
      if (this.checkedCredit) {
        body.isNeedCheckSpecial = true;
        this.$refs.creditForm.validate((valid) => {
          if (valid) {
            this.pay(body);
          } else {
            this.btnLoading = false;
          }
        });
      } else {
        body.isNeedCheckSpecial = false;
        this.pay(body);
      }
    },
    closePaymentOnBehalfDialog() {
      this.preInfo = this.clickPaymentOrder = {};
      this.paymentOnBehalfDialog = false;
    },
    //点击修改订单按钮
    changeorderdetail(order) {
      let changeOrderObj = {
        type: "supplierChangeOrder",
        orderSn: order.sn,
      };
      this.$router.push({
        path: basePath + "/newValetOrder",
        query: {
          orderSn: order.sn,
          type: 1,
          whichPage: 1,
          orderStatus: order.orderStatus,
        },
      });
      // window.open(window.location.origin + basePath + '/newValetOrder?&orderSn='+order.sn+'&type=1&whichPage=1&orderStatus='+order.orderStatus);
    },
    //new
    handleCheckAllChange(val) {
      /*table全选*/
      if (this.checkAll) {
        this.orderList.forEach((item) => {
          this.$set(item, "checked", true);
          if (item.parentOrSon == 1) {
            this.checkOrder.push(item);
          } else if (item.parentOrSon == 3) {
            item.sonOrders.forEach((v, k) => {
              this.$set(v, "checked", true);
              this.checkOrder.push(v);
            });
          }
        });
        this.checkOrder = this.checkOrder.unique(); // 数组去重
        this.checkOrderSn = this.checkOrder
          .map((i) => {
            if (i.parentOrSon == 1) return i.sn; // 不拆分订单 返回订单编号 sn
            if (i.parentOrSon == 3) return i.sn; // 拆分订单 其他状态 返回 sn
          })
          .join();
      } else {
        this.orderList.forEach((item) => {
          this.$set(item, "checked", false);
          if (item.parentOrSon == 3) {
            item.sonOrders.forEach((v, k) => {
              this.$set(v, "checked", false);
            });
          }
        });
        this.checkOrder = [];
        this.checkOrderSn = "";
      }
    },
    handleCheckChange1(v) {
      // 不拆分单选 ，主订单 全选
      if (v.checked) {
        if (v.parentOrSon == 3) {
          v.sonOrders.forEach((item, index) => {
            this.$set(item, "checked", true);
            this.checkOrder.push(item);
          });
        } else {
          this.checkOrder.push(v);
        }
        this.checkOrder = this.checkOrder.unique(); // 数组去重
      } else {
        if (v.parentOrSon == 3) {
          v.sonOrders.forEach((item, index) => {
            this.$set(item, "checked", false);
            if (this.checkOrder.indexOf(item) !== -1) {
              let i = this.checkOrder.indexOf(item);
              this.checkOrder.splice(i, 1);
            }
          });
        } else {
          if (this.checkOrder.indexOf(v) !== -1) {
            let i = this.checkOrder.indexOf(v);
            this.checkOrder.splice(i, 1);
          }
        }
      }
      let a = true;
      this.orderList.forEach((item) => {
        if (item.parentOrSon == 1) {
          if (!item.checked) a = false;
        } else if (item.parentOrSon == 3) {
          item.sonOrders.forEach((v, k) => {
            if (!v.checked) a = false;
          });
        }
      });
      this.checkOrderSn = this.checkOrder
        .map((i) => {
          if (i.parentOrSon == 1) return i.sn; // 不拆分订单 返回订单编号 sn
          if (i.parentOrSon == 3) return i.sn; // 拆分订单 其他状态 返回 sn
        })
        .join();
      this.checkAll = a;
    },
    handleCheckChange2(v1, v) {
      // 子订单 单选
      // v1 子订单 v2 主订单
      if (v1.checked) {
        this.checkOrder.push(v1);
        this.checkOrder = this.checkOrder.unique(); // 数组去重
      } else {
        let i = this.checkOrder.indexOf(v1);
        this.checkOrder.splice(i, 1);
      }
      let a = true;
      v.sonOrders.forEach((item, index) => {
        if (!item.checked) a = false; // 任意一个子订单未选中 a=false
      });
      this.$set(v, "checked", a);
      this.orderList.forEach((item) => {
        if (item.parentOrSon == 1) {
          if (!item.checked) a = false;
        } else if (item.parentOrSon == 3) {
          item.sonOrders.forEach((v, k) => {
            if (!v.checked) a = false;
          });
        }
      });
      this.checkOrderSn = this.checkOrder
        .map((i) => {
          if (i.parentOrSon == 1) return i.sn; // 不拆分订单 返回订单编号 sn
          if (i.parentOrSon == 3) return i.sn; // 拆分订单 其他状态 返回 sn
        })
        .join();
      this.checkAll = a;
    },
    //查看是否开通订单审核权限
    findSellerJurisdiction() {
      axiosApi(
        "/trader/findSellerJurisdiction/select",
        {
          traderId: sessionStorage.traderId,
        },
        (res) => {
          this.auditCompetence = res.data.data;
          let binaryData = this.auditCompetence.aftersalesChannel.split("");
          this.formSet = {
            ...this.formSet,
            openAfterSales: binaryData[0] == 1 ? "1" : "2", //开通售后
            isOpenOrder: this.auditCompetence.isOpenOrder,
            isOpenOrderTypeOpt: this.auditCompetence.isOpenOrderTypeOpt ? 1 : 0,
            cancelTime: this.auditCompetence.cancelTime,
            freight: this.auditCompetence.freight,
          };
          this.formSet.checklist[0] =
            binaryData[binaryData.length - 2] == 1 ? "1" : "";
          this.formSet.checklist[1] =
            binaryData[binaryData.length - 1] == 1 ? "2" : "";
          // if (this.auditCompetence.isOpenOrder == 0) {
          //   if (this.count == 0) {
          //     let len = this.dataTabs.length;
          //     this.dataTabs.splice(len - 2, 1); //删除数组最后一个数组长度改变， 返回最后一个元素
          //   }
          //   this.count = 1;
          // } else {
          //   if (this.count == 1) {
          //     this.dataTabs.push("待审核");
          //   }
          //   this.count = 0;
          // }
        },
        1,
        10,
        (res) => {}
      );
    },
    //订单统计(角标)
    anonOrderCount() {
      axiosApi(
        "/anon/order/count",
        {
          traderType: 2,
          sellerId: sessionStorage.traderId,
        },
        (res) => {
          let allCount = 0;
          for (let k in res.data.data) {
            allCount += this.badge[k];
          }
          this.dataTabs[0].num = allCount;
          this.dataTabs.forEach((v,index) => {
              if (index == "1") {
                //待付款
                v.num = res.data.data.notPayCount;
              } else if (index == "2") {
                //待发货
                 v.num = res.data.data.notDeliveryCount;
              } else if (index == "3") {
                //待收货
                v.num = res.data.data.orderDeliveriedCount;
              } else if (index == "4") {
                // 分批发货
                v.num = res.data.data.deliverySeparateCount;
              } else if (index == "5") {
                //已完成
                v.num = res.data.data.successCount;
              } else if (index == "6") {
                //已关闭
                v.num = res.data.data.cancleCount;
              } else if (index == "7") {
                v.num = res.data.data.notAuditCount;
              }
          });
        },
        1,
        10,
        (res) => {}
      );
    },
    goToOrderDetail(orderSn, type, orderStatus) {
      //订单详情
      let a = null;
      if (type == 2) a = 2; // 聚合的主订单
      if (type == 1) a = 1; // 子订单，不拆分订单
      this.$router.push({
        path: basePath + "/newValetOrder",
        query: {
          orderSn,
          type: a,
          whichPage: 2,
          orderStatus,
        },
      });
      sessionStorage.orderType = this.type;
      sessionStorage.orderType = this.type;
    },
    addOrder() {
      //代客下单
      this.$router.push(
        basePath + "/newValetOrder?type=1&whichPage=0&orderStatus=1"
      );
    },
    handleQueryBtn() {
      //查询
      if (this.customerType.length == 1) {
        this.form.ctype = this.customerType[0];
      } else if (this.customerType.length == 2) {
        this.form.ctype = this.customerType[1];
      } else {
        this.form.ctype = "";
      }
      this.form.gmtOrderStart = !this.form.orderDateRange[0]
        ? null
        : this.form.orderDateRange[0].getTime();
      this.form.gmtOrderEnd = !this.form.orderDateRange[1]
        ? null
        : this.form.orderDateRange[1].getTime();
      this.cloneForm = JSON.parse(JSON.stringify(this.form));
      this.currentPage = 1; //赋值自动调用一次接口

      this.queryOrderList(this.form);
      this.anonOrderCount();

     
      this.findSellerJurisdiction();
      setTimeout(() => {
        this.flag = true;
      }, 0);
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.queryOrderList(this.form);
      this.findSellerJurisdiction();
      this.anonOrderCount();
    },
    /*订单列表查询及条件查询接口*/
    queryOrderList(body) {
      // bool 判断是否为 审核接口
      if(body.orderStatus == 1&&body.editAuditState == 1){
        //待审核
        var url = "/order/modifyAudit/page"
      }else{
        var url ="/anon/order/list/query"
      }
      this.loading = true;
      axiosApi(
        url,
        body,
        (res) => {
          this.loading = false;
          if (res.data.data) {
            this.orderList = res.data.data.elements;
            this.total = res.data.data.totalCount;
            this.orderList.forEach((item, index) => {
              // 用于单选，全选
              item.remarkPics = JSON.parse(item.remarkPics);
              item.remarkForModifyPics = JSON.parse(item.remarkForModifyPics);
              this.$set(item, "checked", false);
              if (item.k3OutStockNos != null) {
                item.k3OutStockNos = item.k3OutStockNos.split(",");
              }
              if (item.parentOrSon == 3) {
                //拆分订单
                item.sonOrders.forEach((v, k) => {
                  this.$set(v, "checked", false);
                  if (v.k3OutStockNos != null) {
                    v.k3OutStockNos = v.k3OutStockNos.split(",");
                  }
                });
              }
            });
            //订单计时器
            this.orderList.forEach((item, index) => {
              if (
                item.remainAutoCancleTime != null &&
                item.remainAutoCancleTime > 0
              ) {
                let time = parseInt(item.remainAutoCancleTime / 1000);
                item.remainAutoCancleTime = this.timeString(time);
                item.clearTime = null;
                item.clearTime = setInterval(() => {
                  time--;
                  item.remainAutoCancleTime = this.timeString(time);
                  if (time <= 0) {
                    clearTimeout(item.clearTime);
                    // if (
                    //   item.orderStatus == 1 &&
                    //   item.payStatus == 1 &&
                    //   (item.editAuditState == null ||
                    //     item.editAuditState == 3) &&
                    //   (item.lastestRemittanceAuditStatus == null ||
                    //     item.lastestRemittanceAuditStatus != 1)
                    // ) {
                    //   // item.orderStatus = 0;
                    //   // if (item.parentOrSon == 3) {
                    //   //   item.sonOrders.forEach((v) => {
                    //   //     v.orderStatus = 0;
                    //   //   });
                    //   // }
                    // }
                    item.remainAutoCancleTime = null
                    this.$set(this.orderList, index, item);
                  }
                }, 1000);
              } else {
                // if (
                //   item.orderStatus == 1 &&
                //   item.payStatus == 1 &&
                //   (item.editAuditState == null || item.editAuditState == 3) &&
                //   (item.lastestRemittanceAuditStatus == null ||
                //     item.lastestRemittanceAuditStatus != 1)
                // ) {
                //   // item.orderStatus = 0;
                //   // if (item.parentOrSon == 3) {
                //   //   item.sonOrders.forEach((v) => {
                //   //     v.orderStatus = 0;
                //   //   });
                //   // }
                // }
                item.remainAutoCancleTime = null
                this.$set(this.orderList, index, item);
              }
               console.log(item.remainAutoCancleTime,'remainAutoCancleTime')
            });
            this.$forceUpdate();
          } else {
            this.orderList = [];
            this.total = 0;
          }
        },
        this.currentPage,
        10,
        (res) => {
          this.orderList = [];
          this.loading = false;
          this.$message({
            type: "error",
            message: res.data.message,
            duration: 1000,
          });
        }
      );
    },
    handleTabs(title, index) {
      this.type = index;
      this.$refs.form.resetFields();
      this.checkAll = false;
      this.form.gmtOrderStart = null; //下单开始时间
      this.form.gmtOrderEnd = null; //下单结束时间
      this.form.orderStatus = null;
      this.form.editAuditState = null;
      this.form.orderStatusList = null
      this.form.shipStatusList = null
      this.form.deliverySeparate = null;
      this.form.ctype = "";
      this.customerType = [];
      this.currentPage = 1; 
      if (this.form.erpErrorOrder) {
        delete this.form.erpErrorOrder;
      }

       if (this.type == "0") {
        //全部订单
        this.form.orderStatus = null;
      } else if (this.type == "1") {
        //待付款
        this.form.orderStatus = 1;
      } else if (this.type == "2") {
        //待发货
        this.form.orderStatus = 2;
      } else if (this.type == "3") {
        //待收货
        this.form.orderStatus = 3;
      } else if (this.type == "4") {
        // 分批发货
        this.form.orderStatusList = [2,3]
        this.form.shipStatusList = [1,4]
        this.form.deliverySeparate = 1;
      } else if (this.type == "5") {
        //已完成
        this.form.orderStatus = 4;
      } else if (this.type == "6") {
        //已关闭
        this.form.orderStatus = 0;
      } else if (this.type == "7") {
        this.form.orderStatus = 1;
        this.form.editAuditState = 1; //待审核
      }
      this.queryOrderList(this.form);
      this.anonOrderCount();
    },
    /*取消订单操作*/
    //关闭弹框
    closeOrderDialog() {
      this.$refs.cancelOrderForm.resetFields();
      this.cancelOrderVisible = false;
    },
    //保存取消订单理由
    cancelOrderCommit(parentOrderSn) {
      this.$refs.cancelOrderForm.validate((valid) => {
        if (valid) {
          this.cancelOrderVisible = false;
          this.btnLoading = true;
          this.cancelOrder({
            orderSn: this.order.parentOrderSn,
            cancleReason: this.form.cancleReason,
            cancleType: 2,
            sellerId: sessionStorage.traderId,
          });
        } else {
          return false;
        }
      });
    },
    //取消订单接口
    cancelOrder(body) {
      this.loading = true;
      axiosApi(
        "/anon/order/cancel",
        body,
        (res) => {
          this.loading = false;
          this.$message({
            type: "success",
            message: "操作成功！",
            duration: 1000,
          });
          this.btnLoading = false;
          this.queryOrderList(this.form);
          this.findSellerJurisdiction();
          this.anonOrderCount();
        },
        1,
        10,
        (res) => {
          this.loading = false;
          this.btnLoading = false;
          this.$message({
            type: "error",
            message: res.data.message,
            duration: 1000,
          });
        }
      );
    },
    handleCancelOrder(order) {
      //取消订单按钮
      this.order = order;
      this.cancelOrderVisible = true;
      this.btnLoading = false;
    },

    /*订单改价*/
    //备注验证
    checkRemark(order) {
      if (order.remark.length > 30) {
        this.passRemark = true;
      } else {
        this.passRemark = false;
      }
    },
    //匹配整数 小数点后两位
    testFreight(order) {
      let reg = /^[\+]?\d*?\.?\d*?$/;
      if (!reg.test(order.freightMoney)) {
        order.freightMoney = 0;
      } else {
        if (order.freightMoney !== 0 && order.freightMoney.indexOf(".") > 0) {
          order.freightMoney = order.freightMoney.substr(
            0,
            order.freightMoney.indexOf(".") + 3
          );
        }
      }
    },
    //折扣匹配 正负数 小数点后两位
    num(order) {
      let reg = /^[\+\-]?\d*?\.?\d*?$/;
      if (!reg.test(order.changedPrice)) {
        order.changedPrice = 0;
      } else {
        if (order.changedPrice !== 0 && order.changedPrice.indexOf(".") > 0) {
          order.changedPrice = order.changedPrice.substr(
            0,
            order.changedPrice.indexOf(".") + 3
          );
        }
      }
      this.$set(
        this.order,
        "changeTotalPrice",
        (
          this.order.goodsTotalMoney * 1 +
          this.order.freightMoney * 1 -
          this.order.activityCutMoney * 1 -
          this.order.couponCutMoney * 1 +
          this.order.changedPrice * 1
        ).toFixed(2)
      );
      if (this.order.changeTotalPrice < 0.01) {
        order.changedPrice = (0).toFixed(2);
        this.$message({
          type: "info",
          message: "���价后订单总金额至少是0.01",
          duration: 1000,
        });
      }
    },
    /*失焦赋值修改价格和运费*/
    blurPriceInput() {
      if (this.order.changedPrice == "" || this.order.changedPrice == "-") {
        this.order.changedPrice = (0).toFixed(2);
      }
      this.$set(
        this.order,
        "changeTotalPrice",
        (
          this.order.goodsTotalMoney * 1 +
          this.order.freightMoney * 1 -
          this.order.activityCutMoney * 1 -
          this.order.couponCutMoney * 1 +
          this.order.changedPrice * 1 -
          this.order.discountCutMonney
        ).toFixed(2)
      );
      this.$set(
        this.order,
        "changePrice",
        (this.order.changedPrice * 1).toFixed(2)
      );
      this.$set(
        this.order,
        "freightMoney",
        (this.order.freightMoney * 1).toFixed(2)
      );
      this.order2 = Object.assign({}, this.order);
    },
    auditRemittance(orderSn, type, orderStatus) {
      // this.$router.push({
      //     path:basePath+'/newValetOrder?whichPage=2&orderSn='+orderSn+'&type='+type
      // })
      // this.$router.push({
      //     path: basePath + '/payCertCheck',
      //     query: {
      //         sign: orderSn
      //     }
      // });
      // window.open(window.location.origin + basePath + '/payCertCheck?sign=' + orderSn);
      // 新版跳到详情
      window.open(
        window.location.origin +
          basePath +
          "/newValetOrder?orderSn=" +
          orderSn +
          "&type=" +
          type +
          "&whichPage=2&orderStatus=" +
          orderStatus
      );
    },
    shipConfirmDialog(orderSn, status) {
      if (this.isCityPartner) {
        this.goToOrderDetail(orderSn, 1, status);
        return false;
      }
      const h = this.$createElement;
      this.$msgbox({
        title: "提示信息",
        message: h(
          "p",
          {
            style: "text-align:center",
          },
          "确认该订单已经发货？"
        ),
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        beforeClose: (action, instance, done) => {
          if (action == "confirm") {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = "执行中...";
            this.doShip(
              {
                orderSn: orderSn,
              },
              instance
            );
            done();
          } else {
            done();
          }
          delete instance.$slots.default;
        },
      });
    },
    doShip(body, instance = {}) {
      this.loading = true;
      axiosApi(
        "/order/delivery/confirm",
        body,
        (res) => {
          instance.confirmButtonLoading = false;
          this.loading = false;
          this.queryOrderList(this.form);
          this.findSellerJurisdiction();
          this.anonOrderCount();
          this.$message({
            type: "success",
            message: "发货成功",
            duration: 2000,
          });
        },
        1,
        10,
        (res) => {
          instance.confirmButtonLoading = false;
          this.loading = false;
          this.$message({
            type: "error",
            message: res.data.message,
            duration: 2000,
          });
        }
      );
    },
  },
};
</script>

<style scoped lang="less">
.el-alert {
  width: auto;
  .el-alert__title {
    font-size: 13px;
    line-height: 18px;
    font-weight: 900;
  }
}
.divInfo {
  max-height: 500px;
  overflow-y: auto;
}
.boxCard {
  margin: 5px 20px;
  .tabTop {
    background: #eee;
    margin-bottom: 5px;
  }
  .tabRow {
    border-bottom: 1px solid #eee;
  }
  .tabFlex {
    display: flex;
    .tabSpan {
      flex: 1;
      padding: 5px 10px;
    }
  }
}
</style>

<style lang="less">
// .el-date-range-picker__editor {
//   width: 148px;
// }

.inline-block {
  display: inline-block;
}

#supplier-purchase-order {
  position: relative;
  box-sizing: border-box;
  .form-set {
    .el-input {
      width: 60px;
    }
  }

  .orderDetail_btn {
    height: auto;
    margin-top: 10px;
  }

  .order_status {
    height: 60px;

    li {
      box-sizing: border-box;
      height: 26px;
      line-height: 26px;
      padding-left: 10px;
      padding-right: 10px;
      list-style-type: none;
      margin-top: 17px;
      margin-right: 10px;
      cursor: pointer;
      user-select: none;
      border-radius: 2px;

      &:hover {
        color: #ff4201;
      }
    }

    .active {
      border: 1px solid #ff4201;
      color: #ff4201;
    }
  }

  .table_header {
    height: 40px;
    line-height: 40px;
  }

  .bbd {
    border-bottom: 1px solid #eceef6;
  }

  .ellipsis {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .paymentOnBehalfDialog {
    .el-dialog {
      min-height: auto;

      .el-dialog__footer {
        position: static;
        transform: translateX(0);
        padding-bottom: 30px;
      }

      .el-dialog__headerbtn {
        position: absolute;
        top: 0;
        right: 0;
      }
    }
  }

  .checkedAll {
    position: relative;
    top: 30px;
    width: 70px;
    margin-left: 30px;
  }

  .checked {
    width: 25px;
    margin-left: 30px;

    .el-checkbox__label {
      display: none;
    }
  }

  .bb {
    border-bottom: 1px solid #dddddd;
  }

  .bl {
    border-left: 1px solid #eceef6;
  }

  .order-datatimerange {
    width: 240px;
  }

  .w-570 {
    width: 528px !important;
    display: flex;
    flex-wrap: wrap;

    > div {
      padding: 20px;
      padding-right: 0;
      width: 100%;
      box-sizing: border-box;
    }
  }

  #notPayCountmark {
    position: absolute;
    left: 167px;
    top: 10px;
  }

  #notDeliveryCount {
    position: absolute;
    left: 248px;
    top: 10px;
  }

  #orderDeliveriedCount {
    position: absolute;
    left: 330px;
    top: 10px;
  }

  #deliverySeparateCount {
    position: absolute;
    left: 417px;
    top: 10px;
  }

  #notAuditCount {
    position: absolute;
    left: 667px;
    top: 10px;
  }

  .hide {
    display: none;
  }

  .content {
    .el-form--inline {
      .el-form-item {
      }
      .el-form-item__label {
        font-size: 12px;
        width: 60px;
      }
    }
    .paymentHistory .el-table .el-table__body-wrapper {
      display: block;
    }
    .el-table .el-table__body-wrapper {
      display: none;
    }
    .table-item {
      border: 1px solid #eceef6;
      font-size: 14px;
      border-bottom: none;

      .table-item-content .pbl-20 {
        padding: 0px 0px 20px 20px;
      }

      .table-item-content {
        .gift {
          position: relative;
        }

        .triangle-topleft {
          border-top: 60px solid #ff4201;
          width: 0;
          height: 0;
          border-right: 60px solid transparent;
          position: absolute;
          top: 0;
          left: 0;
        }

        .init {
          transform: rotate(-45deg) translate(-4px, 10px);
          position: absolute;
          left: 0;
          top: 0;
          color: #fff;
          letter-spacing: -0.34px;
        }
      }

      .table-item-top {
        border-bottom: 1px solid #eceef6;
        line-height: 40px;
        // padding: 10px 0;

        padding-right: 65px;
        position: relative;

        .mr-65 {
          margin-right: 65px;
        }

        .separate {
          position: absolute;
          right: 0;
          top: 0;
        }

        .table-item-top-checkbox {
          margin-left: 14px;
        }

        div {
          width: 260px;
        }
      }

      img {
        width: 80px;
        height: 80px;
      }
    }

    .table-item-default {
      height: 530px;
      padding-top: 120px;
    }
  }

  .cancelOrderDialog {
    .el-dialog__body {
      padding-top: 20px;
      padding-bottom: 0px;
      margin-bottom: 100px;
    }

    .el-dialog {
      min-height: 250px;
    }

    .el-textarea {
      .el-textarea__inner {
        border-radius: 0;
      }
    }
  }

  .changePriceDialog {
    .el-dialog {
      width: 1000px;
    }

    .el-dialog__body {
      .el-form-item__label {
        font-size: 12px;
        width: 215px;
        margin-right: 20px;
      }

      .el-input__inner {
        font-size: 12px;
      }

      .ferght {
        flex-grow: 1;
      }

      .changePriceInfo {
        padding-bottom: 36px;
        border: 1px solid #eceef6;
        border-top: none;
        margin-bottom: 80px;
      }
    }
  }
  .giftPriceClass{
    text-decoration:line-through;
  }
}
.copyInfo {
  display: flex;
  align-items: flex-start;
  .copyNumber {
    vertical-align: top;
    display: flex;
    align-items: flex-start;
    width: 260px;
    span {
      display: inline-block;
      vertical-align: top;
    }
    .numberOne {
      width: 70px;
    }
    .numberTwo {
      width: 190px;
      text-align: left;
    }
  }
  
}
.outHouse {
  display: flex;
  align-items: flex-start;
  .outHouseContent {
  }
  .outHouseNumber {
    text-align: left;
    display: inline-block;
    width: 190px;
  }
  .outHouseNumber,
  .outHouseCopy {
    vertical-align: top;
  }
  .outHouseCopy {
    padding: 0;
    display: inline-block;
  }
}
.copyText,
.outHouseCopy {
  vertical-align: top;
  color: blue;
  display: inline-block;
  cursor: pointer;
}
.copyText {
  margin-left: 4px;
}
</style>
