import axios from 'axios';
import store from '../store';
export default {
  list: [],
  init() {
    // 线上地址
    var url = 'https://sunrise-app.oss-cn-shanghai.aliyuncs.com/template/area/area.json'
    // 测试地址
    // var url = 'https://sunrise-app.oss-cn-shanghai.aliyuncs.com/template/area/area-2023.json'
    axios.get(url).then(res => { 
      if (res.status = 200) {
        // store.commit('InitAreaList', res.data)
        localStorage.setItem('areaList',JSON.stringify(res.data))
        this.list = res.data
      } else { 
        // store.commit('InitAreaList',[])
        localStorage.setItem('areaList',[])
        this.list = []
      }
    })
  }
}