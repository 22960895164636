<template>
  <div id="allGoodss">
    <div class="title-tabs bg-f mb-5">
      <ul class="clearfix">
        <template v-for="(tab,index) in dataTabs" class="item">
          <li :key="index" :type="index" :class="type==index?'active':''" @click="handleTabs">
            {{tab}}
            <div :class="type==index?'under-line':''"></div>
          </li>
          <div :key="tab" class="vertical-line"></div>
        </template>
      </ul>
    </div>

    <div class="content bg-f pl-30 pr-30 manage">
      <div class="clearfix search">
        <el-form :inline="true" class="pt-30 ml-30" :model="formInlineData">
          <el-form-item label="商品编码" class>
            <el-input v-model.trim="formInlineData.goodsNo" :maxlength="30" placeholder="请输入商品编码" @input="formInlineData.goodsNo=formInlineData.goodsNo.replace(/[\u4e00-\u9fa5]/ig,'')"></el-input>
          </el-form-item>
          <el-form-item label="商品名称" class>
            <el-input v-model.trim="formInlineData.skuName" :maxlength="30" placeholder="请输入商品名称"></el-input>
          </el-form-item>
          <el-form-item label="商品分类" class>
            <el-select v-model="formInlineData.classifyId" clearable>
              <el-option
                :label="item.classifyName"
                :value="item.classifyId"
                v-for="(item, i) in searchClassify"
                :key="i"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="商品类型">
            <el-select v-model="formInlineData.storeType" clearable>
              <el-option label="特殊商品" value="2"></el-option>
              <el-option label="普通商品" value="1"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div>
          <el-button class=" ml-30 mb-20" type="info" @click="achieve(0)" icon="el-icon-search">查 询</el-button>
        </div>
      </div>
      <div style="position: relative;">
        <el-tabs v-model="type1" @tab-click="handleTab1Click">
          <el-tab-pane label="全部" name="0"></el-tab-pane>
          <el-tab-pane label="未上架" name="1"></el-tab-pane>
          <el-tab-pane label="已上架" name="2"></el-tab-pane>
        </el-tabs>
        <el-badge class="count" :max="99" :value="count" :hidden="count ==0"/>
      </div>

      <div class="pb-30 mt-20 allGoods" v-show="type == 0">
        <div class="tar clearfix">
          <el-button type="text" class="fr" @click="showOneKey()">批量设置积分</el-button>
        </div>
        <el-table
          :data="tableData"
          style="width: 100%"
          @selection-change="handleSelectionChange"
        >
          <el-table-column label="商品名称" min-width="245">
            <template slot-scope="scope">
              <div class="fl specialD">
                <img v-if="scope.row.fileUrl" :src="scope.row.fileUrl" alt />
                <img v-else src="../../../assets/goods.jpg" alt />
                <img
                  v-if="scope.row.storeType=='2'"
                  class="special"
                  src="../../../assets/special.png"
                  alt
                />
              </div>
              <div class="fl lh-16">
                <div class="width-130">
                  商品编码:
                  <span>{{scope.row.goodsNo ? scope.row.goodsNo :'--'}}</span>
                </div>
                <div class="width-130" show-overflow-tooltip>{{scope.row.skuName}}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="规格" min-width="63" align="center">
            <template slot-scope="scope">
              <span>1*{{scope.row.specInfoNum}}</span>
            </template>
          </el-table-column>
          <el-table-column label="折扣/返利" min-width="70" align="center">
            <template slot-scope="scope">
              <span
                v-if="scope.row.discountValue"
              >{{scope.row.discountType=='1' ?'折扣优惠':'固定返利' }}{{scope.row.discountValue}}</span>
              <span v-else>--</span>
            </template>
          </el-table-column>
          <el-table-column label="标准零售价" min-width="80" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.maxSellingPrice">￥{{scope.row.maxSellingPrice||0 | fmoney }}</span>
              <span v-else>--</span>
            </template>
          </el-table-column>
          <el-table-column label="促销价" min-width="66" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.promotionPrice">￥{{scope.row.promotionPrice||0 | fmoney }}</span>
              <span v-else>--</span>
            </template>
          </el-table-column>
          <el-table-column label="最低零售价" min-width="86" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.minSellingPrice">￥{{scope.row.minSellingPrice||0 | fmoney }}</span>
              <span v-else>--</span>
            </template>
          </el-table-column>
          <el-table-column label="最低起订量" min-width="86" align="center">
            <template slot-scope="scope">
              <span
                v-if="scope.row.minimunMoq"
              >{{scope.row.isIntegerMultiple ? scope.row.minimunMoq * scope.row.specInfoNum : scope.row.minimunMoq}}{{scope.row.units}}</span>
              <span v-else>--</span>
            </template>
          </el-table-column>
          <el-table-column label="销售员积分" min-width="76" align="center">
            <template slot-scope="scope">
              <span
                v-if="scope.row.rewardValue != null "
              >{{scope.row.rewardValue}}{{scope.row.rewardType == '1' ? '%' : '分'}}</span>
              <span v-else>--</span>
            </template>
          </el-table-column>
          <el-table-column label="序号" align="center" min-width="60">
            <template slot-scope="scope">
              <span>{{scope.row.sort != null?scope.row.sort:'-'}}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" width="250">
            <template slot-scope="scope">
              <el-button type="text" @click="openSort(scope.row)">排序</el-button>
              <el-button
                type="text"
                @click="handleSetSpecial(scope)"
              >{{scope.row.storeType == "1" ? "设为特殊商品":"取消特殊商品"}}</el-button>
              <p
                class="width-63"
                style="color: gray;font-size: 14px;cursor: default;height: 32px;text-align: center;width:  100% !important;line-height: 32px; display:inline-block"
                v-if="scope.row.minSellingPrice == null || scope.row.promotionPrice == null || scope.row.maxSellingPrice == null || scope.row.discountValue == null"
              >
                <span>设置</span>
              </p>
              <el-button
                v-else
                class="width-63"
                type="text"
                @click="handleMinimunMoq(scope,'rules9')"
              >设置</el-button>
              <el-button
                class="width-63"
                type="text"
                @click="handleUp(scope)"
              >{{scope.row.skuState == "1" ? "上架":"下架"}}</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pb-30 mt-20" v-show="type == 1">
        <el-table
          :data="tableData"
          style="width: 100%"
          @selection-change="handleSelectionChange"
        >
          <el-table-column label="商品名称" width="280">
            <template slot-scope="scope">
              <div class="fl specialD">
                <img v-if="scope.row.fileUrl" :src="scope.row.fileUrl" alt />
                <img v-else src="../../../assets/goods.jpg" alt />
                <img
                  v-if="scope.row.storeType=='2'"
                  class="special"
                  src="../../../assets/special.png"
                  alt
                />
              </div>
              <div class="fl lh-16">
                <div class="width-130">
                  商品编码:
                  <span>{{scope.row.goodsNo ? scope.row.goodsNo :'--'}}</span>
                </div>
                <div class="width-130" show-overflow-tooltip>{{scope.row.skuName}}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="规格" width="63" align="center">
            <template slot-scope="scope">
              <span>1*{{scope.row.specInfoNum}}</span>
            </template>
          </el-table-column>
          <el-table-column label="商品分类"  align="center">
            <template slot-scope="scope">
              <span>{{scope.row.classifyName}}</span>
            </template>
          </el-table-column>
          <el-table-column label="标准零售价"  align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.maxSellingPrice">￥{{scope.row.maxSellingPrice||0 | fmoney }}</span>
              <span v-else>--</span>
            </template>
          </el-table-column>
          <el-table-column label="促销价" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.promotionPrice">￥{{scope.row.promotionPrice||0 | fmoney }}</span>
              <span v-else>--</span>
            </template>
          </el-table-column>
          <el-table-column label="最低零售价" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.minSellingPrice">￥{{scope.row.minSellingPrice||0 | fmoney }}</span>
              <span v-else>--</span>
            </template>
          </el-table-column>
          <el-table-column label="最低起订量" align="center">
            <template slot-scope="scope">
              <span
                v-if="scope.row.minimunMoq"
              >{{scope.row.isIntegerMultiple ? scope.row.minimunMoq * scope.row.specInfoNum : scope.row.minimunMoq}}{{scope.row.units}}</span>
              <span v-else>--</span>
            </template>
          </el-table-column>
          <!-- <el-table-column label="序号" width="60" align="center">
            <template slot-scope="scope">
              <span>{{scope.row.sort != null?scope.row.sort:'-'}}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <span class="blue" @click="openSort(scope.row)">排序</span>
            </template>
          </el-table-column> -->
        </el-table>
      </div>
      <!-- 公用的分页组件 -->
      <el-pagination
        class="tar"
        v-if="tableData.length>0"
        :current-page="currentPage"
        :page-size="10"
        :total="total"
        layout="prev , pager, next, jumper"
        @current-change="handleCurrentChange"
      ></el-pagination>
      <!--一键设置积分-->
      <el-dialog class="score-dialog" title="批量设置积分" :visible.sync="salesOneKeyMark">
        <el-form ref="markOneKeyMarks" class="pr-30 ml100" :model="markForm" :rules="rules2">
          <el-form-item label="销售员积分:" class="ml-20" required>
            <el-form-item class="ml-15" prop="rewardValue">
              <el-input
                type="text"
                size="mini"
                class="ml-10 mr-10"
                v-model="markForm.rewardValue"
                placeholder="请输入"
              ></el-input>&nbsp;%
            </el-form-item>
            <p class="ml-10 mt-10">（一键设置积分后会适用全部商品）</p>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="danger" @click="setOneKeyMarks('markOneKeyMarks', 1)">保存</el-button>
          <el-button
            v-if="this.currentRow.skuState === 1"
            class="ml-20"
            @click="setOneKeyMarks('markOneKeyMarks', 2)"
          >保存并上架</el-button>
        </div>
      </el-dialog>
      <!--积分和最低起订量弹窗-->
      <el-dialog class="minMop" :title="score ? '设置' : '设置'" :visible.sync="minMop">
        <!-- 设置最低起订量 -->
        <el-form ref="minMopForm" class="pr-10" :model="minMopForm" :rules="rules5">
          <el-form-item label="最低起订量:" class="ml-20" required>
            <el-radio-group v-model="radio">
              <el-form-item class="ml-15" prop="unitNum">
                <el-radio class="noMargin" :label="1">{{ }}</el-radio>
                <el-input
                  size="mini"
                  class="ml-10 mr-10"
                  v-model="minMopForm.unitNum"
                  placeholder="最低起订量"
                  :maxlength="3"
                ></el-input>
                <span class="font-14">{{ minMopForm.units }}</span>
              </el-form-item>
              <el-form-item class="ml-15" prop="sellingNum">
                <el-radio class="mt-20 noMargin" :label="2">{{ }}</el-radio>
                <el-input
                  size="mini"
                  class="ml-10 mr-10"
                  v-model="minMopForm.sellingNum"
                  placeholder="最低起订量"
                  :maxlength="3"
                ></el-input>
                <el-select v-model="minMopForm.sellingUnit" size="mini" style="width: 120px">
                  <el-option
                    v-for="(item, index) in goodsSellings"
                    :label="item.dictValue"
                    :value="item.dictKey"
                    :key="index"
                  ></el-option>
                </el-select>
                <span
                  class="font-14"
                >（合{{ minMopForm.sellingNum }}*{{ minMopForm.specInfoNum }}{{ minMopForm.units }}）</span>
              </el-form-item>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="运费设置:" class="ml-20 fare-item" required prop="fareId">
            <el-select v-model="minMopForm.fareId" size="medium" placeholder="默认模板">
              <el-option
                v-for="(item, index) in fareIdList"
                :label="item.name"
                :value="item.id"
                :key="index"
              ></el-option>
            </el-select>
            <el-button type="text" @click="lookTemplate">查看</el-button>
          </el-form-item>
        </el-form>
        <!-- 设置积分 -->
        <el-form v-show="score" class="pr-10" :model="formPrice" ref="rules9" :rules="rules9">
          <div class="one">
            <el-form-item class="ml-20" label="销售员积分" prop="rewardValue" required>
              <el-input placeholder="请输入" v-model.trim="formPrice.rewardValue"></el-input>
              <el-select v-model="formPrice.rewardType" size="mini">
                <el-option
                  v-for="(item, index) in arr"
                  :label="item.label"
                  :value="item.id"
                  :key="index"
                ></el-option>
              </el-select>
            </el-form-item>
          </div>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="danger" @click="handleMinSave(1)">保存</el-button>
          <el-button v-if="currentRow.skuState === 1" class="ml-20" @click="handleMinSave(2)">保存并上架</el-button>
        </div>
      </el-dialog>
      <!-- 排序 -->
      <el-dialog class="sort" title="序号" :visible.sync="sortvisiable">
        <el-form ref="sortForm" class="pr-10" :model="sortForm" :rules="sortRules">
          <el-form-item label="序号:" class="ml-20" prop="sort">
            <el-input type="text" v-model="sortForm.sort" placeholder="请输入序号" required></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="danger" @click="handleSortSave">确定</el-button>
          <el-button class="ml-20" @click="clearSort">重置</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { axiosApi, basePath } from "../../../api/api"; //导入axiosApi

export default {
  data() {
    let reg2 = /^(\d|[1-9]\d|100)(\.\d{1,2})?$/;
    let reg3 = /^(?:0\.\d{1,2}|[1-9]\d{0,2}(?:\.\d{1,2})?|1000|0)$/;
    var unitNums = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入销售员积分"));
      } else if (value && this.formPrice.rewardType == 1) {
        if (!reg2.test(value)) {
          callback(new Error("请输入0-100数字"));
        } else {
          callback();
        }
      } else if (value && this.formPrice.rewardType == 2) {
        if (!reg3.test(value)) {
          callback(new Error("请输入0-1000数字"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    var validateUnitNum = (rule, value, callback) => {
      if ((value == "" || !value) && this.radio == 1) {
        callback(new Error("请输入最低起订量"));
      } else {
        callback();
      }
    };
    var validateSellingNum = (rule, value, callback) => {
      if ((value == "" || !value) && this.radio == 2) {
        callback(new Error("请输入最低起订量"));
      } else {
        callback();
      }
    };
    var fareId = (rule, value, callback) => {
      if (value == "") {
        // callback(new Error('请选择运费模板'));
        callback();
      } else {
        callback();
      }
    };
    return {
      sortvisiable: false,
      id: null, //商品主键id
      sortForm: {
        sort: null
      },
      sortRules: {
        sort: [
          { required: true, message: "请输入序号" },
          {
            pattern: /^[1-9]\d{0,3}$/,
            message: "请输入1-9999之间的整数"
          }
        ]
      },
      type: "0", // 0-全部商品 1-协议商品
      type1: "0",
      radio: 1,
      currentPage: 1,
      total: 0,
      dataTabs: ["全部商品", "酒店协议商品"],
      salesOneKeyMark: false, //一键设置销售员积分弹框
      formInlineData: {
        skuName: null, //商品名称
        goodsNo: null, //商品编码
        classifyId: "", //商品分类
        skuState: null, //商品状态
        storeType: "" //
      },
      searchClassify: [],
      tableData: [
        {
          name: "123"
        }
      ],
      markForm: {
        rewardType: "1",
        rewardValue: "",
        freightDiscountsMoney: 0
      },
      rules2: {
        rewardValue: [
          {
            validator: unitNums,
            trigger: "blur"
          }
        ],
        freightDiscountsMoney: [
          {
            pattern: /^([1-9][\d]{0,2}|0)(\.[\d]{1,2})?$/,
            message: "请输入正确金额，最大999.99元",
            trigger: "blur"
          }
        ]
      },
      score: false, //非协议商品设价弹框
      minMop: false,
      minMopForm: {
        id: null,
        unitNum: 1, //数量
        sellingNum: 1, //数量
        sellingUnit: null, //规格单位
        specInfoNum: null, //规格
        units: null, //计量单位
        isIntegerMultiple: false, //是否包装规格整数倍
        fareId: "" //运费模版id
      },
      // goodsSellings: ['箱', '瓶'], //包装分类
      goodsSellings: [], //包装分类
      arr: [
        {
          id: "1",
          label: "%"
        },
        {
          id: "2",
          label: "分"
        }
      ], //分数
      rules5: {
        unitNum: [
          {
            validator: validateUnitNum
          },
          {
            pattern: /^[1-9]\d{0,2}$/,
            message: "不可以为0，最大支持3位整数"
          }
        ],
        sellingNum: [
          {
            validator: validateSellingNum
          },
          {
            pattern: /^[1-9]\d{0,2}$/,
            message: "不可以为0，最大支持3位整数"
          }
        ],
        fareId: [{ validator: fareId, trigger: "change" }]
      },
      rules9: {
        rewardValue: [
          {
            validator: unitNums,
            trigger: "blur"
          }
        ]
      },
      formPrice: {
        //设置积分
        id: "", //设价Id
        skuName: null, //商品名称
        priceUnit: null,
        rewardType: "1",
        rewardValue: 0
      },
      count: "",
      fareIdList: [],
      defaultFareId: "",
      currentRow: {}
    };
  },
  watch: {
    radio(val) {
      if (val == 1) {
        this.minMopForm.isIntegerMultiple = 0;
        if (this.minMopForm.sellingNum == 0) {
          this.minMopForm.sellingNum = null;
        }
      } else {
        this.minMopForm.isIntegerMultiple = 1;
        if (this.minMopForm.unitNum == 0) {
          this.minMopForm.unitNum = null;
        }
      }
    },
    sortvisiable(val) {
      if (!val) {
        let _this = this;
        setTimeout(() => {
          if (_this.$refs.sortForm) _this.$refs.sortForm.resetFields();
        }, 500);
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.achieve();
      this.getClassList();
      this.getFareIdList();
    });
  },
  methods: {
    clearSort() {
      axiosApi(
        "/superc/goodsSort/update",
        {
          supercType: 2,
          id: this.id,
          sort: null
        },
        res => {
          this.$message({
            type: "success",
            message: "重置成功",
            duration: 1500
          });
          this.achieve(); //默认加载商品列表
          this.sortvisiable = false;
        },
        this.Page,
        10,
        res => {
          this.$message({
            type: "error",
            message: res.data.message,
            duration: 1500
          });
        }
      );
    },
    handleSortSave() {
      this.$refs.sortForm.validate(valid => {
        if (valid) {
          axiosApi(
            "/superc/goodsSort/update",
            {
              supercType: 2,
              id: this.id,
              sort: this.sortForm.sort ? parseInt(this.sortForm.sort) : null
            },
            res => {
              this.$message({
                type: "success",
                message: "设置成功",
                duration: 1500
              });
              this.achieve(); //默认加载商品列表
              this.sortvisiable = false;
            },
            this.Page,
            10,
            res => {
              this.$message({
                type: "error",
                message: res.data.message,
                duration: 1500
              });
            }
          );
        }
      });
    },
    openSort(item) {
      this.id = item.id;
      this.sortvisiable = true;
      if (item.sort == null) {
        this.getSort();
      } else {
        this.sortForm.sort = parseInt(item.sort);
      }
    },
    getSort() {
      axiosApi(
        "/superc/goodsSortMax/select",
        {
          supercType: 2
        },
        res => {
          this.sortForm.sort = res.data.data;
        },
        this.Page,
        10,
        res => {}
      );
    },
    // 打开批量设置积分
    showOneKey() {
      this.salesOneKeyMark = true;
      this.markForm = {
        rewardValue: "",
        rewardType: "1",
        freightDiscountsMoney: 0
      }
    },
    setOneKeyMarks(markOneKeyMarks, status) {
      this.$refs[markOneKeyMarks].validate(valid => {
        if (valid) {
          if (status == 1) {
            axiosApi(
              "/superc/traderGoods/batchSetReward",
              {
                // idList: this.idList,
                skuState: this.type1 == "1" ? 1 : this.type1 == "2" ? 2 : null,
                rewardValue: this.markForm.rewardValue,
                updateSkuState: status == 1 ? false : true // true：保存并上架 false：保存不上架
              },
              res => {
                this.achieve();
                this.salesOneKeyMark = false;
                this.$message({
                  type: "success",
                  message: "操作成功",
                  duration: 3000
                });
              },
              this.currentPage,
              10,
              res => {
                this.$message({
                  type: "info",
                  message: res.data.message,
                  duration: 3000
                });
                this.salesOneKeyMark = false;
              }
            );
          } else if (status == 2) {
            axiosApi(
              "/superc/traderGoods/checkBatchSetReward",
              {
                hotelId: this.$route.query.traderId
              },
              res => {
                if (res.data.data) {
                  const h = this.$createElement;
                  this.$msgbox({
                    title: "提示信息",
                    message: h(
                      "p",
                      { style: "text-align:center" },
                      "有" +
                        res.data.data +
                        "款商品不满足上架条件，是否将符合条件的商品先行上架"
                    ),
                    showCancelButton: true,
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    beforeClose: (action, instance, done) => {
                      if (action === "confirm") {
                        axiosApi(
                          "/superc/traderGoods/batchSetReward",
                          {
                            // idList: this.idList,
                            skuState:
                              this.type1 == "1"
                                ? 1
                                : this.type1 == "2"
                                ? 2
                                : null,
                            rewardValue: this.markForm.rewardValue,
                            updateSkuState: true // true：保存并上架 false：保存不上架
                          },
                          res => {
                            this.achieve();
                            this.salesOneKeyMark = false;
                            this.$message({
                              type: "success",
                              message: "操作成功",
                              duration: 3000
                            });
                          },
                          this.currentPage,
                          10,
                          res => {
                            this.$message({
                              type: "info",
                              message: res.data.message,
                              duration: 3000
                            });
                            this.salesOneKeyMark = false;
                          }
                        );
                        // this.salesOneKeyMark = false
                        done();
                      } else {
                        this.salesOneKeyMark = false;
                        done();
                      }
                      delete instance.$slots.default;
                    }
                  });
                } else {
                  axiosApi(
                    "/superc/traderGoods/batchSetReward",
                    {
                      // idList: this.idList,
                      skuState:
                        this.type1 == "1" ? 1 : this.type1 == "2" ? 2 : null,
                      rewardValue: this.markForm.rewardValue,
                      updateSkuState: true // true：保存并上架 false：保存不上架
                    },
                    res => {
                      this.achieve();
                      this.salesOneKeyMark = false;
                      this.$message({
                        type: "success",
                        message: "操作成功",
                        duration: 3000
                      });
                    },
                    this.currentPage,
                    10,
                    res => {
                      this.$message({
                        type: "info",
                        message: res.data.message,
                        duration: 3000
                      });
                      this.salesOneKeyMark = false;
                    }
                  );
                }
              },
              this.currentPage,
              10,
              res => {
                this.$message({
                  type: "info",
                  message: res.data.message,
                  duration: 3000
                });
              }
            );
          }
        }
      });
    },
    getFareIdList() {
      //运费模版列表
      axiosApi(
        "/foundation/fare/templateSelectList",
        {
          traderId: sessionStorage.traderId,
          noDetails: true,
          businessType: "superc"
        },
        res => {
          this.fareIdList = res.data.data.elements.map(item => {
            if (item.isDefault == 1) {
              this.defaultFareId = item.id;
            }
            return {
              id: item.id, // 有的商品选择并保存了默认模板就要id有值；没选择过模板id为空
              name: item.isDefault == 1 ? item.name + " (默认模板)" : item.name,
              isDefault: item.isDefault,
              copyId: item.id
            };
          });
        },
        1,
        100
      );
    },
    achieve(val) {
      if (val == 0 && this.currentPage != 1) {
        this.currentPage = 1;
      }
      axiosApi(
        "/superc/traderGoods/selectPageListForHotel",
        {
          isAgreementGoods: this.type == "1" ? "1" : "2",
          isAgreement: this.type === "1" ? 1 : 0,
          skuState:
            this.formInlineData.skuState == 0
              ? null
              : this.formInlineData.skuState,
          goodsNo: this.formInlineData.goodsNo,
          skuName: this.formInlineData.skuName,
          classifyId: this.formInlineData.classifyId,
          storeType: this.formInlineData.storeType
        },
        res => {
          this.getCount(); //角标数量
          this.tableData = [];
          res.data.data.elements.forEach(item => {
            if (item.fareId === null) {
              item.fareId = this.defaultFareId;
            }
            this.tableData.push(item);
          });
          this.total = res.data.data.totalCount;
        },
        this.currentPage,
        10,
        res => {}
      );
    },
    getClassList() {
      //获取分类列表
      axiosApi(
        "/superc/classify/selectFirstClassifyList",
        {},
        res => {
          this.searchClassify = res.data.data;
        },
        1,
        10,
        res => {
          this.$message({
            type: "info",
            message: res.data.message,
            duration: 3000
          });
        }
      );
    },
    getCount() {
      //角标数量统计
      axiosApi(
        "/superc/traderGoods/count",
        {
          isAgreementGoods: this.type == "1" ? "1" : "2",
          skuState: "2"
        },
        res => {
          this.count = res.data.data.count;
        },
        1,
        10,
        res => {
          this.$message({
            type: "info",
            message: res.data.message,
            duration: 3000
          });
        }
      );
    },
    //协议非协议切换
    handleTabs(e) {
      this.type1 = "0";
      this.formInlineData = {
        skuName: null, //商品名称
        goodsNo: null, //商品编码
        classifyId: "",
        skuState: "0", //商品状态
        storeType: ""
      };
      if (e.target.attributes.type) this.type = e.target.attributes.type.value;
      this.achieve(0);
    },
    //上架未上架tab切换
    handleTab1Click(e) {
      this.formInlineData = {
        skuName: null, //商品名称
        goodsNo: null, //商品编码
        classifyId: "",
        storeType: ""
      };
      this.type1 = e.name;
      this.formInlineData.skuState = e.name;
      this.achieve(0);
    },
    //new
    handleSelectionChange(val) {
      /*table全选*/
    },
    //分页
    handleCurrentChange(val) {
      this.currentPage = val;
      this.achieve();
    },
    handleUp(scope) {
      //上下架
      if (scope.row.skuState == 1) {
        this.skuState = 2;
        this.stateText = "上架";
      } else if (scope.row.skuState == 2) {
        this.skuState = 1;
        this.stateText = "下架";
      }
      if (scope.row.id) {
        //单个商品上下架数据处理
        this.skuNoList = []; //处理每次添加的订单编号
        this.skuNoList.push(scope.row.skuNo);
      }
      const h = this.$createElement;
      this.$msgbox({
        title: "提示信息",
        message: h(
          "p",
          {
            style: "text-align:center"
          },
          "确认" + this.stateText + "该商品？"
        ),
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            if (this.skuNoList.length > 0) {
              axiosApi(
                "/superc/traderGoods/updateStateForHotel",
                {
                  skuState: this.skuState,
                  skuNoList: this.skuNoList,
                  isAgreement: this.type == "1" ? "1" : "2",
                  supercType: 2
                },
                res => {
                  this.$message({
                    type: "success",
                    message: "操作成功",
                    duration: 3000
                  });
                  this.skuNoList = []; //清空存储的商品编号
                  this.achieve(); //刷新列表
                },
                1,
                10,
                res => {
                  this.$message({
                    type: "info",
                    message: res.data.message,
                    duration: 3000
                  });
                }
              );
            } else {
              this.$message({
                type: "info",
                message: "请选择商品",
                duration: 3000
              });
            }
            done();
          } else {
            done();
          }
          delete instance.$slots.default;
        }
      });
    },
    handleMinimunMoq(scope, type) {
      //最低起订量数据处理
      if (this.type === "1") {
        //设置起购+积分
        this.score = false;
      } else {
        //协议最低起订量
        if (this.$refs["rules9"] !== undefined) {
          this.$refs["rules9"].resetFields();
        }
        this.formPrice.rewardValue = scope.row.rewardValue
          ? scope.row.rewardValue
          : 0;
        this.formPrice.rewardType =
          scope.row.rewardType == null ? "1" : scope.row.rewardType.toString();
        this.score = true;
      }
      if (this.$refs.minMopForm !== undefined) {
        this.$refs.minMopForm.resetFields();
      }
      if (scope.row.isIntegerMultiple == 1) {
        this.radio = 2; //箱
      } else {
        this.radio = 1; //瓶
      }
      this.minMopForm.id = scope.row.id;
      this.minMopForm.units = scope.row.units; //瓶

      if (scope.row.isIntegerMultiple) {
        this.minMopForm.unitNum = null; //按瓶的最低起订量
        this.minMopForm.sellingNum = scope.row.minimunMoq; //销售单位数量
      } else {
        this.minMopForm.unitNum = scope.row.minimunMoq; //按瓶的最低起订量
        this.minMopForm.sellingNum = null; //销售单位数量
      }

      this.minMopForm.sellingUnit =
        scope.row.sellingUnit != null
          ? scope.row.sellingUnit
          : scope.row.goodsSellings[0].dictKey; //规格单位
      this.minMopForm.specInfoNum = scope.row.specInfoNum; //规格数量
      this.minMopForm.priceUnit = scope.row.priceUnit; //
      this.minMopForm.fareId = scope.row.fareId ? scope.row.fareId : ""; //运费模版
      this.minMop = true;
      // 存储点击的row
      this.currentRow = scope.row;
      this.goodsSellings = scope.row.goodsSellings; //规格分类
    },
    submitScoreAndMinnumber(type) {
      //1保存 2保存并上架
      let paramObj = {
        id: this.minMopForm.id, //必需
        isIntegerMultiple: this.minMopForm.isIntegerMultiple, //必需 单位（1：箱；0：瓶）
        unitNum: this.minMopForm.unitNum, //按瓶的数量
        sellingNum: this.minMopForm.sellingNum, //按箱的数量
        isAgreementGoods: this.type == "1" ? 1 : 2, //必需 协议/非协议(1:是 2：否)
        updateSkuState: type == "1" ? false : true, //必需 true：保存并上架 false：保存不上架
        fareId: this.minMopForm.fareId ? this.minMopForm.fareId : null, //运费模版id
        businessType: "superc",
        sellingUnit: this.minMopForm.sellingUnit
      };
      this.score &&
        (paramObj = Object.assign({}, paramObj, {
          rewardValue: this.formPrice.rewardValue, //积分值(非协议时必输)
          rewardType: this.formPrice.rewardType //积分单位(1：百分比；2：分) （非协议时必输）
        }));
      axiosApi(
        "/superc/traderGoods/setRewardAndMinNum",
        paramObj,
        res => {
          this.minMop = false;
          this.achieve(); //刷新列表
          this.$message({
            type: "success",
            message: "设置成功",
            duration: 3000
          });
        },
        this.currentPage,
        10,
        res => {
          this.minMop = false;
          this.achieve(); //刷新列表
          this.$message({
            type: "info",
            message: res.data.message,
            duration: 3000
          });
        }
      );
    },
    handleMinSave(type) {
      //type 1保存 2保存并上架 1确定 2取消
      if (this.type1 === "2" && type == "2") {
        this.minMop = false;
        return false;
      }
      let flag = false; //是否判断执行起订量判断
      let flag1 = false;
      let flag2 = false;
      if (this.score) {
        this.$refs.rules9.validate(valid => {
          if (valid) {
            flag1 = true;
          }
        });
        this.$refs.minMopForm.validate(valid => {
          if (valid) {
            flag2 = true;
          }
        });
        setTimeout(() => {
          if (flag1 && flag2) {
            this.submitScoreAndMinnumber(type);
          }
        }, 0);
      } else {
        this.$refs.minMopForm.validate(valid => {
          if (valid) {
            this.submitScoreAndMinnumber(type);
          }
        });
      }
    },

    renderHeader(h, { column, $index }) {
      let span = <span class="is-required">{column.label}</span>;
      return span;
    },
    lookTemplate() {
      //因为默认都是经过改造的，不存在ID
      if (this.fareIdList && this.fareIdList.length === 0) {
        return false;
      }
      let defaultList = this.fareIdList.filter(item => {
        return item.id == "" && item.isDefault == 1;
      });
      let id = null;
      if (this.minMopForm.fareId == "") {
        id = defaultList[0] && defaultList[0].copyId;
      } else {
        id = this.minMopForm.fareId;
      }
      if (!id) {
        this.$message({
          message: "暂无可查看的模板！",
          duration: 1500,
          type: "warning"
        });
        return;
      }
      window.open(basePath + "/freightHandle?type=1&id=" + id, "_blank");
    },
    handleSetSpecial(scope) {
      if (scope.row.storeType == 1) {
        const h = this.$createElement;
        this.$msgbox({
          title: "提示信息",
          message: h(
            "p",
            {
              style: "text-align:center"
            },
            "确认将该商品设为特殊商品？"
          ),
          showCancelButton: true,
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          beforeClose: (action, instance, done) => {
            if (action === "confirm") {
              axiosApi(
                "/superc/specialGoods/companySet",
                {
                  id: scope.row.id
                },
                res => {
                  this.$message({
                    type: "success",
                    message: "操作成功",
                    duration: 3000
                  });
                  this.achieve(); //刷新列表
                },
                1,
                10,
                res => {
                  this.$message({
                    type: "info",
                    message: res.data.message,
                    duration: 3000
                  });
                }
              );
              done();
            } else {
              done();
            }
            delete instance.$slots.default;
          }
        });
      } else if (scope.row.storeType == 2) {
        const h = this.$createElement;
        this.$msgbox({
          title: "提示信息",
          message: h(
            "p",
            {
              style: "text-align:center"
            },
            "确认取消该特殊商品？"
          ),
          showCancelButton: true,
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          beforeClose: (action, instance, done) => {
            if (action === "confirm") {
              axiosApi(
                "/superc/specialGoods/companyCancel ",
                {
                  id: scope.row.id
                },
                res => {
                  this.$message({
                    type: "success",
                    message: "操作成功",
                    duration: 3000
                  });
                  this.achieve(); //刷新列表
                },
                1,
                10,
                res => {
                  this.$message({
                    type: "info",
                    message: res.data.message,
                    duration: 3000
                  });
                }
              );
              done();
            } else {
              done();
            }
            delete instance.$slots.default;
          }
        });
      }
    }
  }
};
</script>

<style lang="less">
#allGoodss {
  .blue {
    color: #20a0ff;
    display: inline-block;
    width: auto;
    height: auto;
    text-align: center;
    cursor: pointer;
  }
  .sort {
    .el-dialog {
      min-height: 250px !important;
    }
    .el-form-item__label {
      width: 120px !important;
    }
    .el-form-item__error {
      left: 28%;
    }
  }
  .el-table__body-wrapper {
    margin-top: 1px !important;
  }
  .manage {
    padding-bottom: 20px;
    .el-form--inline {
      .el-form-item__label {
        font-size: 12px;
        width: 60px;
      }
    }
  }
  .el-button + .el-button {
    margin-left: 0 !important;
  }
  .el-form-item__label {
    width: 100px;
  }
  .ml100 {
    margin-left: 100px !important;
  }
  .score-dialog {
    .el-dialog {
      width: 520px;
      min-height: 300px;
      .el-form-item__error {
        left: 96px;
      }
    }
  }
  .minMop {
    .el-dialog {
      width: 520px;
      min-height: 300px;
        .el-input{
            width: 190px;
        }
        .el-select--mini{

            .el-input{
                width: 100%;
            }
        }
      .el-form-item__error {
        left: 37px;
      }
    }
  }
  .width-130 {
    width: 145px;
  }
  .one {
    .el-input {
      width: 120px !important;
    }
    .el-form-item__error {
      margin-left: 61px;
    }
  }
  .count {
    position: absolute;
    top: 0;
    left: 222px;
  }
  .width-63 {
    width: 63px !important;
  }
  .fare-item {
    .el-form-item__error {
      left: 100px !important;
    }
  }
  .allGoods {
    .el-table .cell {
      padding: 10px;
    }
  }
  .specialD {
    position: relative;
    .special {
      position: absolute;
      top: 0;
      left: 0;
    }
    img {
      width: 60px;
      height: 60px;
      display: inline-block;
      margin-right: 5px;
    }
  }
}
.noMargin {
  margin-right: 0 !important;
}
th {
  text-align:center;
}
</style>
