<template>
    <div id="choose-client" class="media font-14" :class="{ 'mt-50':activeTab!=='0','ml-50':activeTab!=='0' }">
        <el-form ref="form" :model="form" label-width="120px">
            <el-form-item :label="title">
                <el-radio-group :disabled="checked" v-model="form.resource">
                    <el-radio label="1">是</el-radio>
                    <el-radio label="2">否</el-radio>
                    <el-radio label="3" v-if="activeTab == 0">智能审核</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="收货区域:" v-if="form.resource == 3">
                <div v-if="regionText">{{regionText}}</div>
                <el-button v-if="!isUpdate" class="button_top tac mr-30 font-14" @click="dialogIsShow = true;">选择收货地址
                </el-button>
                <p class="font-12 color-666">设置收货区域后，客户编辑、新增的收货地址超出收货区域范围，将进入自动审核状态。</p>
            </el-form-item>
            <el-form-item  label="适用客户" v-if="((activeTab == 7 || activeTab == 9 || activeTab == 0) && form.resource == 1) || (activeTab != 7 && activeTab != 9 && activeTab != 0 && form.resource == 2)">
                <el-radio-group :disabled="checked" v-model="form.client">
                    <el-radio label="1">全部客户</el-radio>
                    <el-radio label="2">部分客户</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label=" " v-if="form.client == 2 && ((activeTab == 8 && form.resource == 2) || (activeTab != 8 && form.resource == 1))">
                <div class="clearfix">
                    <el-button class="fr mr-20" type='text' v-if="!checked"  @click="addClient">添加客户</el-button>
                </div>
                <el-table :data="tableData" tooltip-effect="dark" ref="clientTable" class="mt-10 table-default">
                    <el-table-column label="客户名称"  prop='storeName'>
                    </el-table-column>
                    <el-table-column label="客户类型" >
                        <template slot-scope="scope">
                            <span v-if="scope.row.ctype == 1">经销商</span>
                            <span v-else-if="scope.row.ctype == 2">终端</span>
                            <span v-else-if="scope.row.ctype == 3">久加久*久柜</span>
                            <span v-else-if="scope.row.ctype == 6">普通酒店</span>
                            <span v-else-if="scope.row.ctype == 5">久加久*店中店</span>
                            <span v-else-if="scope.row.ctype == 4">久加久*连锁店</span>
                            <span v-else-if="scope.row.ctype == 41">久加久*连锁店/直营</span>
                            <span v-else-if="scope.row.ctype == 42">久加久*连锁店/加盟</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="终端渠道" prop="channelName">
                    </el-table-column>
                    <el-table-column label="所属分组" prop="groupName">
                    </el-table-column>
                    <el-table-column label="所属区域" prop='districtName' >
                    </el-table-column>
                    <el-table-column v-if="activeTab == '9'" label="限购数量" prop="groupName" width="150">
                        <template slot-scope="scope">
                            <sy-input :disabled="checked" type="int" :min="skuInfo.minimunMoq" @input="getChange(scope.row,scope.$index)" :max="999"  v-model="scope.row.limitPurchaseNum"></sy-input>&nbsp;{{ skuInfo.unitsName }}(合{{scope.row.interLimitPurchaseNum }}{{skuInfo.packageUnitName}})
                        </template>
                    </el-table-column>
                    <el-table-column v-if=" activeTab == '9'" min="1" label="限购周期" prop='districtName' width="170">
                        <template slot-scope="scope">
                            <sy-input :disabled="checked" type="int" :min="1" :max="366" v-model="scope.row.limitPurchaseDay"></sy-input>&nbsp;天后
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" >
                        <template slot-scope="scope">
                            <span v-if="checked">-</span>
                            <el-button v-else type="text" @click.native.prevent="deleteRow(scope.$index, tableData)"   size="small">删除
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-form-item>
            <el-form-item  v-if="form.resource == '1' && form.client == '1' && activeTab=='9'" label="限购数量" required>
                <sy-input :disabled="checked"  type="int" :min="skuInfo.minimunMoq" v-model="form.limitPurchaseNum" :max="999"></sy-input>
                <!--<el-input  type="number" :min="skuInfo.unitNum" v-model="form.limitPurchaseNum" :max="999"></el-input>-->&nbsp;{{ skuInfo.unitsName }}(合{{ Math.ceil( form.limitPurchaseNum / skuInfo.specInfoNum )+ skuInfo.packageUnitName }})
            </el-form-item>
            <el-form-item  v-if="form.resource == '1' && form.client == '1' && activeTab=='9'" label="限购周期" required>
                每&nbsp;<sy-input :disabled="checked"  type="int" :min="1" v-model="form.limitPurchaseDay" :max="366"></sy-input>&nbsp;天后可再下单
            </el-form-item>
            <el-form-item label="">
                <div class="clearfix" v-if='total>0 && form.client == 2 && ((form.resource == 1 && activeTab != 8 ) || ( activeTab == 8 && form.resource == 2 ) )'>
                    <el-pagination
                            class="fr mt-20 mr-50"
                            :current-page="currentPage"
                            :page-size='10'
                            :total='total'
                            layout="prev , pager, next, jumper"
                            v-if="tabletotalData.length>0"
                            @current-change="handleit"
                    >
                        <!-- 分页组件 -->
                    </el-pagination>
                </div>
            </el-form-item>
            <el-form-item label="">
                <el-button class="color-danger" v-if="!checked" @click="saveClient">保存</el-button>
                <!--<el-button class="" @click="goBack">取消</el-button>-->
            </el-form-item>
        </el-form>
        <address-picker
                v-show="dialogIsShow"
                :dialogIsShow="dialogIsShow"
                :title="addressTitle"
                :provinceIdsMap="provinceIdsMap"
                :cityIdsMap="cityIdsMap"
                :regionIdsMap="regionIdsMap"
                :isAllProvince="true"
                @closeDialog="closeDialog"/>
        <choose-client @closedialog="clientVisible = false" :ids="ids" :activeTab="activeTab" :cVisible="clientVisible"></choose-client>
    </div>
</template>

<script>
    import { mapActions,mapMutations } from 'vuex';
    import { axiosApi,basePath } from "~/api/api";

    export default {
        props:{
            activeTab:{
                type:String,
                default:""
            },
            skuInfo:{
                type:Object,
                default:{}
            },
            checked:{
                type:Boolean
            }
        },
        name: "client",
        data(){
            return {
                addressTitle:'收货地址',
                regionText:'',
                dialogIsShow: false,//地址弹窗
                isUpdate: false,//是不是编辑
                currentPage: 1,
                form:{
                    resource:"2",
                    client:"1",
                    limitPurchaseNum:this.skuInfo.minimunMoq,
                    limitPurchaseDay:1
                },
                flag:true,
                ids:[],
                title:'',
                clientVisible:false,
                tableData:[],
                searchedTableData: [], //查询条件后的 客户
                allMultipleSelection: [],//全部数据选中
                // tabletotalData:[],
                provinceIdsMap : [],
                cityIdsMap : [],
                regionIdsMap :[],
            }
        },
        watch:{
            "form.resource":{
                handler:function (val) {
                    if(val == 1 && !this.flag){
                        this.$set(this.form,'client',"1")
                    }
                    this.flag = false;
                },
                deep:true
            },
            activeTab:{
                handler:function(val){
                    if(val == 7 ){
                        this.title = '是否停售';
                    }else if(val == 8 ){
                        this.title = '是否可见';
                    }else if(val == 9 ){
                        this.title = '是否限购';
                    }else if(val == 0){
                        this.title = '是否审核';
                    }
                },
                immediate:true
            },
            currentPage() {
                if (this.searchedTableData.length == 0 && !this.isSearched) {
                    let data = this.tabletotalData.slice((this.currentPage - 1) * 10, (this.currentPage - 1) * 10 + 10);
                    data.map(v => {
                        v.limitPurchaseNum = v.limitPurchaseNum < this.form.limitPurchaseNum ? this.form.limitPurchaseNum : v.limitPurchaseNum;
                        v.interLimitPurchaseNum = Math.ceil(v.limitPurchaseNum / this.skuInfo.specInfoNum)
                    });
                    this.tableData = data;
                } else {
                    let data = this.searchedTableData.slice((this.currentPage - 1) * 10, (this.currentPage - 1) * 10 + 10);
                    data.map(v => {
                        v.limitPurchaseNum = v.limitPurchaseNum < this.form.limitPurchaseNum ? this.form.limitPurchaseNum : v.limitPurchaseNum;
                         v.interLimitPurchaseNum = Math.ceil(v.limitPurchaseNum / this.skuInfo.specInfoNum)
                    });
                    this.tableData = data;
                }
            },
            total() {
                if (this.searchedTableData.length == 0 && !this.isSearched) {
                    let data = this.tabletotalData.slice((this.currentPage - 1) * 10, (this.currentPage - 1) * 10 + 10);
                    data.map(v => {
                         v.interLimitPurchaseNum = Math.ceil(v.limitPurchaseNum / this.skuInfo.specInfoNum)
                        v.limitPurchaseNum = v.limitPurchaseNum < this.form.limitPurchaseNum ? this.form.limitPurchaseNum : v.limitPurchaseNum;
                    });
                    this.tableData = data;
                } else {
                    let data = this.searchedTableData.slice((this.currentPage - 1) * 10, (this.currentPage - 1) * 10 + 10);
                    data.map(v => {
                         v.interLimitPurchaseNum = Math.ceil(v.limitPurchaseNum / this.skuInfo.specInfoNum)
                        v.limitPurchaseNum = v.limitPurchaseNum < this.form.limitPurchaseNum ? this.form.limitPurchaseNum : v.limitPurchaseNum;
                    });
                    this.tableData = data;
                }
            },
            tabletotalData() {
                this.ids = [];
                this.tabletotalData.map(v => {
                    this.ids.push(v.buyerId);
                });
                if (this.isFirst > 1 && !this.isDelete) {
                    this.storeName = ""
                    this.ctype = ''//客户类型
                    this.cchannel = []//客户渠道
                    this.groupId = null//所属分组
                    this.districtIds = []//所属区域
                    this.queryclient()
                }
                this.isFirst += 1
                this.isDelete = false
            },
            skuInfo:{
                handler:function (val) {
                    this.$nextTick(() => {
                        if(this.activeTab == '7'){
                            console.log(val,'6')
                            this.$set(this.form,'resource',val.isStopSelling ? '1' : '2');
                            this.$set(this.form,'client',val.stopSellingSetting ? '2' : '1');
                            this.$store.dispatch('addclient', {
                                willadd: val.stopSellingCustomers,
                                activeTab:this.activeTab,
                                checked:this.checked
                            })
                        }else if(this.activeTab == '8' ){
                            this.$set(this.form,'resource',val.isVisible ? '1' : '2');
                            this.$set(this.form,'client',val.visibleSetting ? '2' : '1');
                            this.$store.dispatch('addclient', {
                                willadd: val.visibleCustomers,
                                activeTab:this.activeTab,
                                checked:this.checked
                            })
                        }else if(this.activeTab == '9' ){
                            this.$set(this.form,'resource',val.isLimitPurchase ? '1' : '2');
                            this.$set(this.form,'client',val.limitPurchaseSetting ? '2' : '1');
                            this.$set(this.form,'limitPurchaseDay',val.limitPurchaseDay ? Number(val.limitPurchaseDay) : 1);
                            this.$set(this.form,'limitPurchaseNum',val.minimunMoq < val.limitPurchaseNum ? val.limitPurchaseNum : val.minimunMoq);
                            this.$store.dispatch('addclient', {
                                willadd: val.limitPurchaseCustomers,
                                activeTab:this.activeTab,
                                checked:this.checked
                            })
                        }else if(this.activeTab == '0'){
                            let provinceIdsMap = [];
                            let cityIdsMap = [];
                            let regionIdsMap = [];
                            //省
                            for (const key in val.provinceIdsMap) {
                                provinceIdsMap.push({
                                    key,
                                    value:val.provinceIdsMap[key]
                                })
                            }
                            //市
                            for (const key in val.cityIdsMap) {
                                cityIdsMap.push({
                                    key,
                                    value:val.cityIdsMap[key]
                                })
                            }
                            //区
                            for (const key in val.regionIdsMap) {
                                regionIdsMap.push({
                                    key,
                                    value:val.regionIdsMap[key]
                                })
                            }
                            this.$set(this.form,'resource',val.deliveryAddressAuditType == 3 ? '3' : (val.deliveryAddressAuditType == 2 ? '1' : '2'));
                            this.$set(this.form,'allAreaName',val.allAreaName);
                            this.$set(this,'provinceIdsMap',provinceIdsMap);
                            this.$set(this,'cityIdsMap',cityIdsMap);
                            this.$set(this,'regionIdsMap',regionIdsMap);
                            this.regionText = val.allAreaName;
                            this.$set(this.form,'client',((typeof val.auditAllDeliveryAddress == "object") || val.auditAllDeliveryAddress) ? '1' : '2');
                            this.$store.dispatch('addclient', {
                                willadd: val.traderVos,
                                activeTab:this.activeTab,
                                checked:this.checked
                            })
                        };
                    })
                },
                deep:true,
                immediate: true
            }
        },
        computed:{
            postDataclient() {//已选择客户id
                return {
                    data: {
                        ids: this.ids,
                        queryPage: 1, // 0-查询全部 1-分页查询
                        clientType: this.$route.query.activitySource || 1, // B2B和酒店
                    },
                    currentPage: 1
                }
            },
            total() {
                if(this.activeTab == '7'){
                   return this.$store.state.clientlist.willaddSale ? this.$store.state.clientlist.willaddSale.length : 0
                }else if(this.activeTab == '8'){
                    return  this.$store.state.clientlist.willaddVisible ? this.$store.state.clientlist.willaddVisible.length : 0
                }else if(this.activeTab == '9'){
                    return  this.$store.state.clientlist.willaddLimitation ? this.$store.state.clientlist.willaddLimitation.length : 0
                }else if(this.activeTab == '0'){
                    return this.$store.state.clientlist.willadd? this.$store.state.clientlist.willadd.length  : 0;
                };
            },
            tabletotalData() {
                let arr;
                if(this.activeTab == '7' ){
                    arr = this.$store.state.clientlist.willaddSale || []
                }else if(this.activeTab == '8' ){
                    arr = this.$store.state.clientlist.willaddVisible || []
                }else if(this.activeTab == '9'){
                    arr = this.$store.state.clientlist.willaddLimitation || []
                }else if(this.activeTab == '0'){
                    arr = this.$store.state.clientlist.willadd || [];
                };

                arr.map(v => {
                    v.limitPurchaseNum = v.limitPurchaseNum ? v.limitPurchaseNum : 1;
                    v.limitPurchaseDay = v.limitPurchaseDay ? v.limitPurchaseDay :1;
                });

                if (this.searchedTableData.length == 0 && !this.isSearched) {
                    let data = arr.slice((this.currentPage - 1) * 10, (this.currentPage - 1) * 10 + 10);
                    data.map(v => {
                        v.limitPurchaseNum = v.limitPurchaseNum < this.form.limitPurchaseNum ? this.form.limitPurchaseNum : v.limitPurchaseNum;
                    });
                    this.tableData = data;
                } else {
                    let data = arr.slice((this.currentPage - 1) * 10, (this.currentPage - 1) * 10 + 10);
                    data.map(v => {
                        v.limitPurchaseNum = v.limitPurchaseNum < this.form.limitPurchaseNum ? this.form.limitPurchaseNum : v.limitPurchaseNum;
                    });
                    this.tableData = data;
                }
                return arr
            },
            districtList() {
                return this.cascaderClear2(this.$store.state.channelLevel.districtList) || []
            },
            // groupList() {
            //     return this.$store.state.channelLevel.groupList
            // },
            clientChannelList() {
                return this.cascaderClear(this.$store.state.channelLevel.clientChannelList) || []
            },
            activitySource() {// 1 - B2B  2 - 酒店
                return (this.$route.query.activitySource ? this.$route.query.activitySource : this.$route.query.couponSource) || 1
            },
            customer() {
                if (this.activitySource == 1) {
                    return [{label: "全部", value: ""}, {label: "经销商", value: "1"}, {
                        label: "终端",
                        value: "2"
                    }, {label: "久加久*连锁店", value: "4"}, {
                        label: "久加久*店中店",
                        value: "5"
                    }]
                } else {
                    return [{label: "全部", value: ""}, {label: "普通酒店", value: "6"}, {label: "久加久*久柜", value: "3"}]
                }
            }
        },
        methods:{
            ...mapMutations({
                'deleteRowdata': 'deletewilladddata2',
                'clearwilladd': 'clearwilladd2',
                'willdeteleclients': 'willdeteleclients',
                'deleteclients': 'deleteclients', //删除客户
            }),
            ...mapActions({
                'queryclienttableData': 'queryclienttableData',
            }),
            closeDialog(val) {
                console.log(val,'val');
                
                if (val) {
                    this.form.area = val;
                    this.regionText = this.resolveValueToText_4level(val);
                }
                this.dialogIsShow = false
            },
            getChange(item,index){
                console.log(item,index);
                let obj = this.$deepClone(item);
                obj.interLimitPurchaseNum = Math.ceil(obj.limitPurchaseNum / this.skuInfo.specInfoNum);
                this.$set(this.tableData,index,obj)
            },
            //保存
            saveClient(){
                let url = this.activeTab !== '0' ? '/goods/customerSetting/update' : '/trader/sellerIsOpenAuditDeliveryAddress/update';
                let body = {};
                if(this.activeTab == '7'){
                    body.skuNo = this.skuInfo.skuNo;
                    body.isStopSelling = this.form.resource == 1 ? this.form.resource : 0;
                    body.settingType = 0;
                    body.stopSellingSetting = this.form.client == 2 ? 1 : 0
                }else if(this.activeTab == '8'){
                    body.skuNo = this.skuInfo.skuNo
                    body.isVisible = this.form.resource == 1 ? this.form.resource : 0;
                    body.settingType = 1;
                    body.visibleSetting = this.form.client == 2 ? 1 : 0
                }else if(this.activeTab == '9'){
                    body.skuNo = this.skuInfo.skuNo
                    body.isLimitPurchase = this.form.resource == 1 ? this.form.resource : 0;
                    body.settingType = 2;
                    body.limitPurchaseSetting = this.form.client == 2 ? 1 : 0
                    if(this.form.client == 1){
                        body.limitPurchaseNum = this.form.limitPurchaseNum;
                        body.limitPurchaseDay = this.form.limitPurchaseDay;
                    }
                }else if(this.activeTab == '0'){
                    // body.buyerIdList = [];
                    body.traderId = sessionStorage.getItem('traderId');
                    // body.IsOpenAuditDeliveryAddress = this.form.resource == 1 ? true : false;
                    body.auditAllDeliveryAddress = this.form.client == 1 ? true : false;
                    if(this.form.resource == 1){
                        body.deliveryAddressAuditType = '2'
                    }else if(this.form.resource == 2){
                        body.deliveryAddressAuditType = '1'
                    }else if(this.form.resource == 3){
                        if(this.form.area.length == 0){
                            this.$message({
                                type:'warning',
                                message:'请选择收货地址'
                            });
                            return
                        }
                        body.deliveryAddressAuditType = '3';
                        body.allArea = this.form.area;
                    }
                };
                if(this.tabletotalData.length>0){
                    if(this.activeTab != '0'){
                        body.buyerSettings = [];
                        this.tabletotalData.map(v => {
                            let obj = {};
                            obj.buyerId = v.buyerId;
                            if(this.activeTab == '9'){
                                obj.limitPurchaseNum = v.limitPurchaseNum;
                                obj.limitPurchaseDay = v.limitPurchaseDay;
                            };
                            body.buyerSettings.push(obj)
                        })
                    }else{
                        if(this.form.client == 2){
                            body.buyerIdList = [];
                            this.tabletotalData.map(v => {
                                body.buyerIdList.push(v.buyerId)
                            })
                        }
                    }
                }else{
                    if((this.activeTab != 0 && this.activeTab !=7 && this.form.resource == 2 && this.form.client == 2) || ((this.activeTab ==7 || this.activeTab ==0) && this.form.resource == 1 && this.form.client == 2)){
                        this.$message.warning('请选择客户');
                        return
                    }
                };
                console.log(body)
                axiosApi(url,body,res => {
                    console.log(res,'res');
                    this.$message.success('设置成功');
                    // setTimeout(() => {
                    //     window.location.reload()
                    // },500)
                },1,10,err => {
                    this.$message.warning(err.data.message)
                })
            },
            //取消
            goBack(){
                this.$router.history.go(-1);
            },
            //删除
            deleteRow(index, rows) {
                console.log(index,rows,'rows')
                let that = this;
                this.$msgbox({
                    title: '提示信息',
                    message: '您确定要删除这条信息？',
                    showCancelButton: true,
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    beforeClose: (action, instance, done) => {
                        if (action === 'confirm') {
                            console.log('0')
                            this.isDelete = true //确定 isDelete 置为 true
                            if (this.searchedTableData.length == 0) {
                                if(this.activeTab == "0"){
                                    axiosApi('/trader/sellerAuditDeliveryAddress/delete',{ buyerId:rows[index].buyerId,sellerId:sessionStorage.getItem('traderId') },res => {
                                        this.deleteRowdata({
                                            activeTab:this.activeTab,
                                            index:(this.currentPage - 1) * 10 + index
                                        })//更改 tabletotalData
                                    },1,10,err => {
                                        this.$message.warning(err.data.message)
                                    })
                                }else {
                                    console.log('1')
                                    this.deleteRowdata({
                                        activeTab:this.activeTab,
                                        index:(this.currentPage - 1) * 10 + index
                                    })//更改 tabletotalData
                                }
                            } else {
                                console.log('2')
                                this.willdeteleclients([rows[index]])// 添加到删除列表
                                this.searchedTableData.splice((this.currentPage - 1) * 10 + index, 1)
                                this.deleteclients()
                            }
                            done();
                        } else {
                            done();
                        }
                    }
                })
            },
            //添加
            handleit(val) {
                this.currentPage = val;
            },
            addClient(){
                this.clientVisible = true;
                if(this.activeTab == '0'){
                    this.postDataclient.data.clientType = null;
                };
				this.postDataclient.data.accountSource = 1;
                this.queryclienttableData({
                    data:this.postDataclient,
                    activeTab:true
                })
            }
        },
        mounted(){
        }
    }
</script>

<style lang="less">
    #choose-client{
        .el-form-item__content{
            margin-right: 25px;
        }
        .el-table__row,.el-form{
            .el-input{
                width: 120px;
            }
        }
    }
</style>
