<template>
	<div id="welcome" class="clearfix">
		<top></top>
		<div class="tit mt-10">
			<i class="iconfont icon-ok color-cyan middle"></i>
			<span class="ml-30 middle">欢迎登录久加久云商</span>
		</div>
		<div class="conent mt-50 clearfix">
			<div class="fl mr-50"><img src="../assets/welcome.png" alt=""></div>
			<div class="fl">
				<div class="fl label font-20">您可以：</div>
				<div class="fl">
					<el-button @click="goHomePage(1)" class="hover-bg-denger font-18 ml-10 mb-30">采购进货</el-button>
					<el-button @click="goHomePage(2)" class="hover-bg-denger font-18">销售卖货</el-button>
				</div>
			</div>
		</div>
		<!-- <foot class="foot"></foot> -->
		<!-- <el-dialog v-model='bool'></el-dialog> -->
		
	</div>
</template>

<script>
	window.document.body.style.overflow = 'visible';
	import { basePath } from "../api/api"
	export default {
		data(){
			return {
				basePath: basePath,
				bool: true
			}
		},
		methods:{
			goHomePage(type){
				sessionStorage.setItem("userType",type);
				this.$router.push(basePath + '/shop/shopIndex')
			}
		}
	}
</script>

<style lang="less">
	#welcome{
		width: 1280px;
		min-height: 760px;
		margin:0 auto;
		.tit{
			padding:50px 0 0 155px;
			.icon-ok{
				font-size: 80px;
			}
			span{
				font-size: 50px;
			}
		}
		.conent{
			img{
				margin-left: 355px;
				width: 220px;
				width: 255px;
			}
			.label{
				line-height: 50px;
			}
			.hover-bg-denger{
				width:200px;
				height:50px;
				display: block;
			}
			.hover-bg-denger:hover{
				background-color: #ff4201;
				color: #fff;
				border-color: #ff4201;
			}
		}
		.middle{
			vertical-align: middle;
		}
	}
</style>