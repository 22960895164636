<template>
  <div>
    <el-table :data="listInfo">
      <el-table-column label="关联单号" prop="relationSn" ></el-table-column>
      <el-table-column label="操作时间" prop="showTime" width="140"></el-table-column>
      <el-table-column label="操作额度" prop="changeCoin" ></el-table-column>
      <el-table-column label="业务操作" prop="typeName"></el-table-column>
      <el-table-column label="说明" show-overflow-tooltip>
        <template slot-scope="{row}">
          <span>{{ row.remark }}</span>
        </template>
      </el-table-column>
    </el-table>

    <div class="tar mt-20">
      <router-link :to="`${basePath}/coinRecordInfo?id=${id}`">
        <el-button type="text" class="width-auto">
          查看所有交易记录
          <i class="el-icon-arrow-right color-b"></i>
        </el-button>
      </router-link>
    </div>
  </div>
</template>

<script>
import { axiosApi, basePath } from "~/api/api";
import { parseTime } from "@/utils";
import { getCoinRecordPage } from "~/api/axiosInfo";
export default {
  name: 'coinRecord',
  props: ['id'],
  data(){
    return {
      basePath: basePath,
      listInfo: []
    }
  },
  mounted(){
    this.getList()
  },
  computed:{

  },
  methods:{
    getList(){
      getCoinRecordPage({acctTraderCoinId:this.id,pageIndex:1,pageSize:10}).then(res=>{
        this.listInfo = res.elements.map(item=>{
          item.showTime = parseTime(item.gmtCreate)
          return item
        })
      }).catch(err=>{

      })
      
    },
  }
}
</script>

<style lang="less" scoped>

</style>