<template>
    <div class="loginDialogBox">
        <el-dialog
                :visible.sync="dialogVisible"
                class="mdialog"
                :before-close="handleDialogClose">
            <div class="dialogContent">
                <el-tabs @tab-click="tabClick" v-model="activeName">
                    <el-tab-pane label="账号登录" name="one">
                        <div class="form1 formBox">
                            <el-form :rules="verifyForm1" :model="form1" ref="form1">
                                <el-form-item prop="loginName">
                                    <img src="../assets/user.png"/>
                                    <el-input placeholder="请输入账号/手机号" v-model.trim="form1.loginName"></el-input>
                                </el-form-item>
                                <el-form-item prop="passWord">
                                    <img src="../assets/password.png"/>
                                    <el-input placeholder="请输入密码" type="password" v-model="form1.passWord"></el-input>
                                </el-form-item>
                            </el-form>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="验证码登录" name="two">
                        <div class="form2 formBox">
                            <el-form :rules="verifyForm2" :model="form2" ref="form2">
                                <el-form-item prop="loginName2">
                                    <div id="c1" style="margin-left: 52px"></div>
                                </el-form-item>
                                <el-form-item prop="loginName2">
                                    <img src="../assets/user.png"/>
                                    <el-input @input="handleCodeLoginPhone" :maxlength="11" placeholder="请输入手机号"
                                              v-model.trim="form2.loginName2"></el-input>
                                    <div v-if="!systemNo" class="el-form-item__error">系统不存在的用户</div>
                                </el-form-item>
                                <el-form-item prop="code">
                                    <img src="../assets/password.png"/>
                                    <el-input :maxlength="6" v-model.trim="form2.code"></el-input>

                                    <el-button class="codeBtn" type="text" :disabled="!(codeType&&!codeDisabled)"
                                               @click="getCode">
                                        {{codeText}}
                                    </el-button>
                                </el-form-item>
                            </el-form>
                        </div>
                    </el-tab-pane>
                </el-tabs>
                <div>
                    <div class="dialogBtnBox">
                        <el-button @click="handleDialogOk" type="primary">登录</el-button>
                    </div>
                    <div class="signBox">
												<!-- 2021/11/23注释 -->
                        <!-- <a @click="go('/register/step')">注册</a> -->
                        <a @click="go('/register/forgetFirst')" class="fr">忘记密码</a>
                    </div>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">

            </span>
        </el-dialog>
    </div>
</template>

<script>
	import {mapActions, mapMutations} from 'vuex'
	import {axiosApi, basePath} from "../api/api";
	import md5 from "md5";

	export default {
		data() {
			let verifyUserName = (rule, value, callback) => {
				let reg = /^[A-Za-z0-9\u4e00-\u9fa5]{2,20}$/;
				if (!value) {
					return callback(new Error("登录账号不可为空"));
				}
				// setTimeout(() => {
				if (value === "admin" || value === "管理员") {
					callback(new Error("登录账号包含被系统屏蔽的字符"));
				}
				if (!reg.test(value)) {
					callback(new Error("登录账号长度为2-20个字符"));
				} else {
					callback();
				}
				// }, 500);
			};
			let verifyUserName2 = (rule, value, callback) => {
				if (!value) {
					callback("请输入手机号");
					return;
				} else if (!/1\d{10}/.test(value)) {
					callback("手机号输入有误");
					return;
				}
				callback();
			};
			return {
                token:'',
				//dialogVisible : false,
				activeName: "one",
				codeText: "获取验证码",
				codeDisabled: false,
				// 表单1数据
				form1: {
					loginName: "",
					passWord: ""
				},
				// 表单1校验
				verifyForm1: {
					loginName: [
						{
							validator: verifyUserName.bind(this),
							required: true,
							trigger: "blur"
						}
					],
					passWord: [{required: true, message: "请输入密码", trigger: "blur"}]
				},
				// 表单2数据
				form2: {
					loginName2: "",
					code: ""
				},
				// 表单2校验
				verifyForm2: {
					loginName2: [
						{
							validator: verifyUserName2.bind(this),
							required: true,
							trigger: "blur"
						}
					],
					code: [{required: true, message: "请输入验证码", trigger: "blur"}]
				},
				codeType: false,
				systemNo: true
			};
		},
		// 计算
		computed: {
			dialogVisible: function () {
				return this.$store.state.homeSearch.mloginDialogState;
			}
		},
		mounted() {
            this.$nextTick(() => {
                let _this = this
                var myCaptcha = _dx.Captcha(document.getElementById('c1'), {
                    _noLogo: true,
                    style: 'inline',
                    inlineFloatPosition: 'down',
                    width: 251,
                    appId: '8f7a2bbfcb209f52dee6f645ee9c71c4', //appId，在控制台中“应用管理”或“应用配置”模块获取
                    success: function (token) {
                        console.log('token:', token)
                        _this.token = token
                    }
                })
            })
		},
		methods: {
			...mapActions('clientType',['getUserInfo']),
			...mapMutations({
				'setType': 'setType'
			}),
			// 验证码登录 手机号输入 change
			handleCodeLoginPhone(val) {
				this.systemNo = true;
				this.codeType = false;
				if (!val) {
					return;
				} else if (!/1\d{10}/.test(val)) {
					return;
				}
				this.verifyUserNameFn(
					() => {
						this.codeType = true;
					},
					data => {
						this.systemNo = false;
					},
					true
				);
			},
			// 点击注册，忘记密码
			go(url) {
				this.handleDialogClose();
				this.$router.push({
					path: basePath + url
				});
			},
			// 校验用户名是否存在
			verifyUserNameFn(success, error, nomessage) {
				let mobile = "";
				let loginName = "";
				if (this.activeName === "one") {
					mobile = this.form1.loginName;
					loginName = this.form1.loginName;
				} else {
					mobile = this.form2.loginName2;
					loginName = this.form2.loginName2;
				}
				let body = {
					loginName: loginName,
					appId: 2
				}
				if (this.activeName !== "one") {// 验证码
					body.mobile = mobile
				}
				axiosApi(
					"/unlogin/employee/checkMobileNewAvailable",
					body,
					res => {
						if (res.data.data) {
							success && success();
						} else {
							if (!nomessage) {
								this.$message.warning("系统不存在的用户");
							}
							error && error(res);
						}
					},
					1,
					10,
					err => {
						if (!nomessage) {
							this.$message.warning(err.data.message);
						} else {
							error && error(err);
						}
					}
				);
			},
			// tab点击
			tabClick(val) {
				// this.$refs.form1.resetFields();
				// this.$refs.form2.resetFields();
			},
			// 点击获取验证码
			getCode() {
                if (!this.token){
                    this.$message.error('请先通过滑动验证码')
                    return false
                }
                if (!this.codeType) {
					return;
				}
				this.$refs.form2.validateField("loginName2");
				// 发送验证码
				let sendCode = () => {
					const api2 = "/unlogin/sms/sendSingleWithVerification";
					const body2 = {
					    token:this.token,
						mobile: this.form2.loginName2,
						smsTemplate: "2",
						smsType: "sms0002",
						appId: "2",
						smsPurposeType: 1,
						fields: {
							product: "商源云商官方网站"
						}
					};
					axiosApi(
						api2,
						body2,
						() => {
							codeFn();
						},
						1,
						10
					);
				};
				// 发送验证码 文案修改
				let codeFn = () => {
					let time = 60;
					let timer = setInterval(() => {
						this.codeText = `再次发送(${time})`;
						time--;
						if (time < 0) {
							this.codeText = "获取验证码";
							clearInterval(timer);
							this.codeDisabled = false;
							return;
						}
					}, 1000);
				};
				// if(this.form2.loginName && this.codeDisabled && /1\d{10}/.test(this.form2.loginName)){
				//     this.verifyUserNameFn(() => {
				//         this.codeDisabled = true;
				//         codeFn();
				//         sendCode();
				//     },() => {
				//         this.codeDisabled = true;
				//     });
				// }
				this.codeDisabled = true;
				sendCode();
			},
			// 浮层关闭
			handleDialogClose() {
				//this.dialogVisible = false;
				this.$store.commit("setloginDialog", false);
				this.$refs.form1.resetFields();
				this.$refs.form2.resetFields();
				this.systemNo = true;
				this.activeName = "one";
			},
			// 浮层确定
			handleDialogOk() {
				let sendObj = null;
				// 密码登录
				if (this.activeName === "one") {
					sendObj = this.verifyFn1();
					// 验证码登录
				} else {
					sendObj = this.verifyFn2();
				}
				// 发送逻辑
				let sendFn = () => {
					let sendUrl = "/employee/login";
					// 登录ajax
					sessionStorage.setItem("loginName", sendObj.loginName);
					axiosApi(
						sendUrl,
						sendObj,
						res => {
							this.getUserInfo({
								body: {
									"loginName": sendObj.loginName,
									appId: 2
								},
								isLogin: false
							})
							// 获取用户信息ajax
							// axiosApi(
							// 	"/foundation/employee/selectOneByLoginName",
							// 	{
							// 		loginName: sendObj.loginName,
							// 		appId: 2
							// 	},
							// 	res => {
							// 		if (
							// 			res.data.data.loginSuccessCount == 1 &&
							// 			res.data.data.isAdmin == 1 &&
							// 			res.data.data.isHotel != 1
							// 		) {
							// 			sessionStorage.setItem("isOne", 1);
							// 		}
							// 		this.$cookies.set(
							// 			"userName",
							// 			res.data.data.loginName,
							// 			null,
							// 			"/"
							// 		);
							// 		console.log(res);
							// 		// sessionStorage.setItem("xToken", res.data.context.xToken); //xToken
							// 		//缓存登录次数，从1开始
							// 		this.setType({
							// 			'isDealerUser': res.data.data.dealerUser,
							// 			'isCityPartner': res.data.data.supplierType == 4
							// 		})
							// 		// sessionStorage.setItem('isDealerUser',  res.data.data.dealerUser); //是否是经销商
							// 		sessionStorage.setItem("loginName", res.data.data.loginName);
							// 		sessionStorage.setItem("mobile", res.data.data.mobile);
							// 		sessionStorage.setItem("traderId", res.data.data.traderId); //当前 店铺/供应商 ID
							// 		sessionStorage.setItem("id", res.data.data.id); //当前用户ID
							// 		sessionStorage.setItem("isAdmin", res.data.data.isAdmin); //是否是管理员
							// 		sessionStorage.setItem(
							// 			"isDepartmentLeader",
							// 			res.data.data.isDepartmentLeader
							// 		); //是否未部门领导
							// 		sessionStorage.setItem(
							// 			"employeeName",
							// 			res.data.data.employeeName
							// 		); //员工人员
							// 		sessionStorage.setItem(
							// 			"departmentPath",
							// 			res.data.data.departmentPath
							// 		); //部门id列表
							// 		sessionStorage.setItem("managerId", res.data.data.managerId); //员工id
							// 		sessionStorage.setItem("isHotel", res.data.data.isHotel); //是否是酒店用户

							// 		if (sessionStorage.getItem("isOne") == 1) {
							// 			this.$store.commit("setloginDialog", {
							// 				type: false,
							// 				url: ""
							// 			})
							// 			this.$router.push({
							// 				path: basePath + "/welcome"
							// 			});
							// 		} else {
							// 			this.$message.success("登录成功");
							// 			setTimeout(() => {
							// 				let openUrl = this.$store.state.homeSearch.mloginDialogUrl;
							// 				if (openUrl) {
							// 					this.$store.commit("setloginDialog", {
							// 						type: false,
							// 						url: ""
							// 					});
							// 					this.$router.push({
							// 						path: basePath + openUrl
							// 					});
							// 				}
							// 				location.reload(true);
							// 			}, 1000);
							// 		}
							// 	}
							// );
						},
						1,
						10,
						res => {
							let message = "";
							if (
								res.data.code == "LOGIN_002" ||
								res.data.code == "LOGIN_001" ||
								res.data.code == "AUTH_003"
							) {
								message = "用户名或密码错误";
							} else if (res.data.code == "LOGIN_003") {
								message = "用户审核中";
							} else if (res.data.code == "LOGIN_004") {
								message = "用户审核失败";
							} else {
								message = res.data.message;
							}
							this.$message.warning(message);
						}
					);
				};
				if (sendObj) {
					// 校验用户名 是否存在
					if (this.activeName !== "one") {// 验证码登录
						this.verifyUserNameFn(() => {
							sendFn();
						});
					} else {// 账号登录不要验证
						sendFn();
					}

				}
			},
			// 密码登录 逻辑
			verifyFn1() {
				let rt = null;
				this.$refs.form1.validate(valid => {
					if (valid) {
						rt = {
							loginName: this.form1.loginName,
							loginPass: md5(this.form1.passWord),
							appId: "2",
							loginType: "1"
						};
					}
				});
				return rt;
			},
			// 验证码登录逻辑
			verifyFn2() {
				let rt = null;
				this.$refs.form2.validate(valid => {
					if (valid) {
						rt = {
							loginName: this.form2.loginName2,
							messageCode: this.form2.code,
							appId: "2",
							loginType: "2"
						};
					}
				});
				return rt;
			}
		}
	};
</script>

<style lang="less">
    body{
        .dx_captcha_clickword-style-popup{
            z-index: 2020;
        }
    }
    .loginDialogBox {
        /* 修改弹出框样式 */

        .mdialog {
            .el-dialog{
                width: 350px;
                .el-dialog__header {
                    height: 24px !important;
                    border-bottom: none !important;
                }

                .el-dialog {
                    min-height: 350px !important;
                    width: 350px !important;
                }

                .el-dialog__body {
                    padding: 30px 20px !important;
                    padding: 20px 0 !important;
                }

                .el-tabs__header {
                    padding-left: 79px !important;
                }

                .el-dialog__footer {
                    width: 100% !important;
                    bottom: 68px !important;
                }
            }

        }

        /* content样式 */

        .dialogContent {
            padding-top: 10px;

            .dialogBtnBox {
                button {
                    width: 250px;
                    height: 36px;
                    margin-left: 52px;
                }
            }

            .signBox {
                width: 247px;
                margin: 0 auto;
                margin-top: 10px;

                a {
                    font-size: 12px;
                    color: #666;
                    cursor: pointer;
                }

                a:hover {
                    color: #ff4201;
                }
            }

            .formBox {
                margin-top: 20px;
                .el-form-item__content{
                    &>img {
                        width: 36px;
                        height: 36px;
                        float: left;
                        margin-left: 52px;
                    }
                }
                .el-form-item__error {
                    padding-left: 50px;
                }

                .el-input__inner {
                    width: 215px;
                    height: 36px !important;
                }

                .codeBtn {
                    width: auto;
                    position: absolute;
                    right: 60px;
                    color: #ff4201;
                    margin-top: 2px;
                    font-size: 12px;
                    border: none;
                    &:hover {
                        color: #ff4201;
                        background-color: #fff;
                    }
                }

                .codeBtn.is-disabled {
                    color: #999;

                    &:hover {
                        color: #999;
                    }
                }
            }
        }
    }
</style>
