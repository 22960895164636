<template>
    <div class="addAndEditBox pb-20">
        <div class="title-top bg-f">
            {{ type == 'add' ? '添加分组' : '编辑分组' }}
        </div>
        <div class="bg-f mt-5 pb-50 formBox">
            <el-form label-width="120px">
                <el-form-item label="分组名称：" prop="groupName">
                    <el-input :maxlength="10" v-model.trim="groupName"></el-input>
                </el-form-item>
                <el-form-item label="选择客户：" prop="rightList">
                    <div class="clearfix  pt-10 pb-10 pl-20 pr-20 addPro" >
                        <el-button class="fr" type="text" @click="dialogVisible = true">添加客户</el-button>
                        <el-button class="fr" type="text" @click="deletebatches">批量删除</el-button>
                    </div>
                    <div class="order-content fl ml-0 mr-20">
                        <el-table :data="clientChoosed2" stripe @selection-change="handleSelectionChange" >
                            <el-table-column type="selection" ></el-table-column>
                             <el-table-column label="用户名" prop="loginName">
                            </el-table-column>
                            <el-table-column label="客户名称" prop='storeName'  show-overflow-tooltip>
                            </el-table-column>
                            <el-table-column label="客户类型" prop='classifyName'>
                                <template slot-scope="scope">
                                    <span v-if="scope.row.ctype == 1">经销商</span>
                                    <span v-else-if="scope.row.ctype == 2">终端</span>
                                    <span v-else-if="scope.row.ctype == 6">普通酒店</span>
                                    <span v-else-if="scope.row.ctype == 3">久加久*久柜</span>
                                    <span v-else-if="scope.row.ctype == 5">久加久*店中店</span>
                                    <span v-else-if="scope.row.ctype == 4">久加久*连锁店</span>
                                </template>
                            </el-table-column>
                            <el-table-column label="客户分组" prop="groupName">
                            </el-table-column>
                            <el-table-column prop="address" label="操作" align="center">
                                <template slot-scope="scope">
                                    <el-button type="text" @click="deleteClientItem(scope)">删除</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                         <div class="clearfix" v-if='totalHaveChoosed>0'>
                            <el-pagination class="fr" v-if='totalHaveChoosed>0' :current-page="currentPageChoosed" :page-size='10'
                                        :total='totalHaveChoosed'
                                        layout="prev , pager, next" @current-change="handlePageChangeHaveChoosed">
                            </el-pagination>
                        </div>
                    </div>
                </el-form-item>
                <el-form-item class="mt-50" label="">
                    <el-button type="primary" @click="submit">保存</el-button>
                    <!-- <el-button @click="cancel">取消</el-button> -->
                </el-form-item>
            </el-form>
        </div>
        <!--选择客户-->
        <el-dialog title="选择客户" class="clientDialog" :visible.sync="dialogVisible" width="950px" :before-close="cancelGoods">
            <div class="fl" slot="title">
                <el-tabs v-model="clientTab">
                    <el-tab-pane label="选择客户" name="1"></el-tab-pane>
                    <el-tab-pane :label="'已选客户('+tableDataHave.length+')'" name="2"></el-tab-pane>
                </el-tabs>
            </div>
            <div class="pb-30">
                 <div class="clearfix search plr-20 " v-if="clientTab == 1">
                   <div class="fl" style="width:700px">
                        <el-form :inline='true' class="search" :model="searchForm" ref="searchForm" label-width="65px">
                            <el-form-item label='用户名:' prop="orderSn">
                                <el-input placeholder="请输入用户名" :maxlength="20" oninput="value=value.replace(/[^a-zA-Z0-9\u4e00-\u9fa5]/g,'')" v-model.trim='searchForm.loginName'></el-input>
                            </el-form-item>
                            <el-form-item label='客户类型:' class='ml-20' >
                                <el-cascader v-model="searchForm.ctype" clearable :options="optionsType" :props="{checkStrictly:true}"></el-cascader>
                            </el-form-item>
                            <el-form-item label='客户名称:'>
                                <el-input placeholder="请输入客户名称" :maxlength="40" v-model.trim='searchForm.storeName'></el-input>
                            </el-form-item>
                            <el-form-item label='客户分组:' class="ml-20">
                                <TreeGroup v-model="searchForm.groupId"/>
                            </el-form-item>
                            <el-form-item label="业务员:" >
                                <el-select v-model="searchForm.managerName" filterable clearable remote placeholder="请选择业务员" :remote-method="remoteMethod">
                                    <el-option :label="item.employeeName" v-for="(item,index) in salesManList" :value="item.employeeName" :key="index">{{item.employeeName}}({{item.loginName}})</el-option>
                                </el-select>
                            </el-form-item>
                        </el-form>
                   </div>
                    <el-button class="fr" type='info' @click="handleSearchClick">查 询</el-button>
                </div>
                <div v-if="clientTab == 1" style="min-height: 160px" class=" plr-20">
                    <el-table v-loading="tableloading" :data="clientData" stripe border tooltip-effect="dark"
                              class="mt-10 table-default">
                        <el-table-column label="用户名" prop="loginName">
                        </el-table-column>
                        <el-table-column label="客户名称" prop='storeName'  show-overflow-tooltip>
                        </el-table-column>
                        <el-table-column label="客户类型" prop='classifyName'>
                            <template slot-scope="scope">
                                <span v-if="scope.row.ctype == 1">经销商</span>
                                <span v-else-if="scope.row.ctype == 2">终端</span>
                                <span v-else-if="scope.row.ctype == 6">普通酒店</span>
                                <span v-else-if="scope.row.ctype == 3">久加久*久柜</span>
                                <span v-else-if="scope.row.ctype == 5">久加久*店中店</span>
                                <span v-else-if="scope.row.ctype == 4">久加久*连锁店</span>
                                <span v-else-if="scope.row.ctype == 41">久加久*连锁店/直营</span>
                                <span v-else-if="scope.row.ctype == 42">久加久*连锁店/加盟</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="客户分组" prop="groupName">
                        </el-table-column>
                        <el-table-column label="业务员" prop="name">
                        </el-table-column>
                        <el-table-column label="操作">
                            <template slot-scope="scope">
                                <el-button v-if="!scope.row.selected" type="text" @click="addClientData(scope)">
                                    添加
                                </el-button>
                                <p class="tac" v-else style="line-height: 25px;">已添加</p>
                            </template>
                        </el-table-column>
                    </el-table>
                    <div class="clearfix" v-if='total>0'>
                        <div class="fl mt-20">
                            <el-button size="mini" type="primary" @click="addOnePage">添加当前页</el-button>
                            <el-button size="mini" type="primary" @click="addAllPage">按查询结果添加</el-button>
                        </div>
                        <el-pagination class="fr" v-if='total>0' :current-page="currentPage" :page-size='10'
                                       :total='total'
                                       layout="prev , pager, next" @current-change="handlePageChange">
                        </el-pagination>
                    </div>
                </div>
                <div v-if="clientTab == 2" style="min-height: 160px" class=" plr-20">
                    <el-table v-loading="tableloading" :data="tableDataHave2" stripe border tooltip-effect="dark"
                              class="mt-10 table-default">
                         <el-table-column label="用户名" prop="loginName">
                        </el-table-column>
                        <el-table-column label="客户名称" prop='storeName'  show-overflow-tooltip>
                        </el-table-column>
                        <el-table-column label="客户类型" prop='classifyName'>
                            <template slot-scope="scope">
                                <span v-if="scope.row.ctype == 1">经销商</span>
                                <span v-else-if="scope.row.ctype == 2">终端</span>
                                <span v-else-if="scope.row.ctype == 6">普通酒店</span>
                                <span v-else-if="scope.row.ctype == 3">久加久*久柜</span>
                                <span v-else-if="scope.row.ctype == 5">久加久*店中店</span>
                                <span v-else-if="scope.row.ctype == 4">久加久*连锁店</span>
                                <span v-else-if="scope.row.ctype == 41">久加久*连锁店/直营</span>
                                <span v-else-if="scope.row.ctype == 42">久加久*连锁店/加盟</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="客户分组" prop="groupName">
                        </el-table-column>
                        <el-table-column label="业务员" prop="name">
                        </el-table-column>
                        <el-table-column label="操作">
                            <template slot-scope="scope">
                                <el-button type="text" @click="delClientData(scope)">删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <div class="clearfix" v-if='totalHave>0'>
                        <div class="fl mt-20">
                            <el-button size="mini" type="primary" @click="delOnePage">删除当前页</el-button>
                            <el-button size="mini" type="primary" @click="delAllPage">删除全部页</el-button>
                        </div>
                        <el-pagination class="fr" v-if='totalHave>0' :current-page="currentPageHave" :page-size='10'
                                       :total='totalHave'
                                       layout="prev , pager, next" @current-change="handlePageChangeHave">
                        </el-pagination>
                    </div>
                </div>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" :loading="btnLoading" @click="confirmClient">确 定</el-button>
                <el-button @click="cancelGoods">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { axiosApi, basePath } from "../../../api/api"
import TreeGroup from '~/components/treeGroup'

export default {
    name:'addAndEditGrouping',
    components:{
        TreeGroup
    },
    data: function() {
        return ({
            currentPageChoosed:1,
            multipleSelectionClients:[],//批量删除选择客户
            clientChoosed:[],//已选择客户列表
            salesManList:[],//业务员列表
            tableloading:false,
            btnLoading:false,
            classifylist:[],//客户分类
            tableData: [],
            total: 0,
            currentPage:1,
            clientTab: '1',// 选择弹窗的tab
            clientData:[],//客户列表
            tableDataHave: [],//缓存中的客户列表
            currentPageHave:1,
            dialogVisible:false,//添加客户弹框
            // 当前页面类型
            type: this.$route.query.type,
            optionsType: [
                {value: null, label: '全部类型'}, {value: 1, label: '经销商'}, {value: 2, label: '终端'},
                {value: 6, label: '普通酒店'}, {value: 3, label: '久加久*久柜'}, {value: 4, label: '久加久*连锁店',children:[{value:41,label:'直营'},{value:42,label:'加盟'}]},
                {value: 5, label: '久加久*店中店'}
            ],
            searchForm:{
                storeName:null,
                loginName:null,
                groupId:null,
                managerName:null,
                // managerName:null,
                ctype:null
            },//客户搜索条件
            groupName:null
        })
    },
    computed:{
        totalHave() {
            return this.tableDataHave.length
        },
        totalHaveChoosed(){
            return this.clientChoosed.length;
        },
        clientChoosed2(){
            return this.clientChoosed.slice(10 * (this.currentPageChoosed - 1), 10 * (this.currentPageChoosed - 1) + 10)
        },  
        tableDataHave2() {
            return this.tableDataHave.slice(10 * (this.currentPageHave - 1), 10 * (this.currentPageHave - 1) + 10)
        },
    },
    mounted: function() {
        if (this.type === 'edit') {
            this.initEdit(this.$route.query.groupId);
        }
    },
    watch:{
        dialogVisible(val){
            if(val){
                this.getClientList()
                // this.getSalseManList()
            }else {
                // this.clientData = [];
                this.tableDataHave = []
                this.searchForm = {
                    storeName:null,
                    loginName:null,
                    groupId:null,
                    // managerName:null,
                    ctype:null
                }
            }
        },
        tableDataHave2(val){
            if (val.length == 0){
                this.currentPageHave = this.currentPageHave == 1 ? 1: this.currentPageHave - 1
            }
        },
        clientChoosed2(val){
            if (val.length == 0){
                this.currentPageChoosed = this.currentPageChoosed == 1 ? 1: this.currentPageChoosed - 1
            }
        }
    },
    methods: {
        handlePageChangeHaveChoosed(val){
            this.currentPageChoosed = val;
        },  
        //多选
        handleSelectionChange(val) {
            this.multipleSelectionClients = val;
        },
        //批量删除
        deletebatches(){
            if(this.multipleSelectionClients.length == 0){
                this.$message({
                    type:'warning',
                    message:'请选择客户'
                })
                return
            }
            this.$confirm(`确认删除这${this.multipleSelectionClients.length}条客户吗?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.multipleSelectionClients.map(v => {
                    this.clientChoosed = this.clientChoosed.filter(n => {
                        return n.id != v.id
                    })
                    this.tableDataHave = this.tableDataHave.filter(n => {
                        return n.id != v.id
                    })
                    
                })
            }).catch()
            
        },
        //删除已选客户
        deleteClientItem(item){
            this.$confirm(`确认删除该客户吗?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.clientChoosed.splice(item.$index+(this.currentPageChoosed-1)*10, 1);
                this.tableDataHave = this.tableDataHave.map(i => {
                    if (i.id == item.row.id) {
                        return {
                            ...i,
                            selected: false
                        }
                    } else {
                        return i
                    }
                })
                this.tableDataHave = this.tableDataHave.filter(i => {
                    if (i.id == item.row.id) {
                        return false
                    } else {
                        return true
                    }
                })
            })
        },
        //添加客户到缓存中
        addClientData(data){
            this.tableDataHave.push(data.row)
            data.row.selected = true
            this.$set(this.clientData, data.$index, data.row)
        },
        unique(arr) {
            const res = new Map();
            return arr.filter((arr) => !res.has(arr.id) && res.set(arr.id, 1))
        },
        //添加一整页数据到已选客户缓存中
        addOnePage(){
            this.clientData.forEach(v=>{
                v.selected = true
            })
            this.tableDataHave = Array.from(new Set(this.tableDataHave.concat(this.clientData)))
            this.tableDataHave = this.unique(this.tableDataHave)
        },
        //添加所有数据到已选客户缓存中
        addAllPage(){
            const api = "/trader/findClientAllList/select";
            let unInBuyerIdList = []
            this.clientChoosed.forEach(v=>{
                unInBuyerIdList.push(v.buyerId)
            })
            const body = {
                unInBuyerIdList: unInBuyerIdList,
                sellerId: sessionStorage.traderId,
                ...this.searchForm,
                ctype: this.searchForm.ctype && this.searchForm.ctype.length> 0 ? this.searchForm.ctype[this.searchForm.ctype.length - 1] : null
            }
            axiosApi(api, body, res => {
                this.tableDataHave = this.tableDataHave.concat(res.data.data.elements.map(v => {
                    v.selected = true;
                    return v
                }))
                this.tableDataHave = this.unique(this.tableDataHave)
                this.clientData = this.tableDataHave.slice(0,9)
                this.currentPage = 1
            },1,10000,err => {

            },1)
        },
        //删除已选客户
        delClientData(item){
            this.tableDataHave.splice(item.$index+(this.currentPageHave-1)*10, 1)
            item.row.selected = false
            this.clientData = this.clientData.map(i => {
                if (i.id == item.row.id) {
                    return {
                        ...i,
                        selected: false
                    }
                } else {
                    return i
                }
            })
            // this.clientChoosed = this.clientChoosed.map(i => {
            //     if (i.id == item.row.id) {
            //         return {
            //             ...i,
            //             selected: false
            //         }
            //     } else {
            //         return i
            //     }
            // })
        },
        //删除一整页数据在已选客户缓存中
        delOnePage(){
            let arr = this.tableDataHave.splice((this.currentPageHave-1)*10, 10)
            // item.row.selected = false
            this.clientData = this.clientData.map(i => {
                arr.forEach(v=>{
                    if (v.id == i.id){
                        i.selected = false
                    }
                })
                return {
                    ...i
                }
            })
            // this.clientChoosed = this.clientChoosed.map(i => {
            //     arr.forEach(v=>{
            //         console.log(v.id, i.id);
            //         if (i.id == v.id) {
            //             return {
            //                 ...i,
            //                 selected: false
            //             }
            //         }
            //     })
            // })
        },
        //删除所有数据到在选客户缓存中
        delAllPage(){
            this.tableDataHave = []
            this.clientData = this.clientData.map(i => {
               return {
                   ...i,
                   selected: false
               }
            })
        },
        //分页
        handlePageChange(val){
            this.currentPage = val;
            this.getClientList(false)
        },
        handlePageChangeHave(val){
            this.currentPageHave = val;
        },
        remoteMethod(query){
            const api = '/foundation/employee/selectAll' //更换业务员（查询所有的业务员）
            let body = {
                traderId: sessionStorage.traderId,
                employeeName: query,
                searchManagerOnly: 1,//是否只查询业务员
                isDeleted: 0
            }
            axiosApi(api, body, res => {
                if(res.data.data) {
                    this.salesManList = res.data.data.elements;
                }
            },1,100)
        },
        //获取业务员列表
        getSalseManList(){
            axiosApi('/foundation/employee/selectAll',{
                "traderId":sessionStorage.getItem('traderId'),
                "searchManagerOnly":1,
                "isDeleted":0
            },res => {
                console.log(res,'获取业务员列表');
                this.salesManList = res.data.data.elements ? res.data.data.elements : []
            })
        },
        //搜索
        handleSearchClick(){
            this.getClientList(true)
        },
        getClientList(bool){
            this.tableloading = true;
            if (bool && this.currentPage != 1) {
                this.currentPage = 1;
                return
            }
            let unInBuyerIdList = []
            this.clientChoosed.forEach(v=>{
                unInBuyerIdList.push(v.buyerId)
            })
            const api = "/trader/findClientAllList/select";
            const body = {
                unInBuyerIdList: unInBuyerIdList,
                sellerId: sessionStorage.traderId,
                ...this.searchForm,
                ctype: this.searchForm.ctype && this.searchForm.ctype.length> 0 ? this.searchForm.ctype[this.searchForm.ctype.length - 1] : null
            }
            axiosApi(api, body, res => {
                console.log(res,'客户列表');
                this.tableloading = false;
                // this.clientData = res.data.data.elements;
                this.clientData = res.data.data.elements.map(v => {
                    let flag = false;
                    this.tableDataHave.map(item => {
                        if (v.id == item.id) {
                            flag = true
                        }
                    });
                    v.selected = flag;                    
                    return v
                })
                this.total = res.data.data.totalCount
            },this.currentPage,10,err => {
                this.tableloading = false;
            })
        },
         //确认选择
        confirmClient() {
            this.clientChoosed = this.clientChoosed.concat(JSON.parse(JSON.stringify(this.tableDataHave)));
            if (Math.ceil(this.clientChoosed.length/10) < this.currentPageChoosed) {
                this.currentPageChoosed = 1
            }
            this.dialogVisible = false
        },
        // 取消选择
        cancelGoods() {
            this.tableDataHave = []
            this.dialogVisible = false
        },
        // 初始化 修改
        initEdit: function(groupId) {
            let sendUrl = '/trader/queryCustomerGroup/select';
            let sendObj = {
                sellerId: sessionStorage.getItem('traderId'),
                groupId: groupId,
            };
            axiosApi(sendUrl,sendObj,(res) => {
                let data = res.data;
                this.groupName = data.data.groupName;
                this.clientChoosed = JSON.parse(JSON.stringify(data.data.groupBuyerList.elements));
                // this.tableDataHave = JSON.parse(JSON.stringify(data.data.groupBuyerList.elements));
                 
            });
        },
        // 点击添加
        addClick: function() {
            this.dialogVisible = true;
            this.initLeftData();
        },
        // 点击确定 提交
        submit: function() {
            let sendUrl = '';
            let sendObj = {};
            let buyerIdList = this.clientChoosed.map((v) => {
                return v.buyerId;
            });
            if(!this.groupName){
                this.$message({
                    type:'warning',
                    message:'请输入分组名称'
                })
                return
            }
            // 新增
            if (this.type === 'add') {
                sendUrl = '/trader/addCustomerGroup/insert';
                sendObj = {
                    sellerId: sessionStorage.getItem('traderId'),
                    groupName: this.groupName,
                    buyerIdList: buyerIdList,
                };
            // 修改
            } else {
                sendUrl = '/trader/updateCustomerGroup/update';
                sendObj = {
                    groupId: this.$route.query.groupId,
                    sellerId: sessionStorage.getItem('traderId'),
                    groupName: this.groupName,
                    buyerIdList: buyerIdList,
                };
            }
            axiosApi(sendUrl, sendObj, (res) => {
                let message = this.type === 'add' ? '新增成功' : '修改成功';
                this.$message.success(message);
                setTimeout(() => {
                    this.goBack();
                },1500);
            },1,1,(err)=>{
                this.$message.info(err.data.message)
            });
        },
        goBack : function(){
            if(this.$route.query.a && this.$route.query.b){
                let a = this.$route.query.a;
                let b = this.$route.query.b;
                this.$router.push({
                    path: basePath + `/editCommHome/${a}/${b}/0?type=3`
                });
            }else if(this.$route.query.c && this.$route.query.d){
                let c = this.$route.query.c;
                let d = this.$route.query.d;
                this.$router.push({
                    path: basePath + `/clientEdit/${c}/0/${d}`
                });
            }else if(this.$route.query.e){
                let e = this.$route.query.e;
                this.$router.push({
                    path: basePath + `/addStore/${e}`
                });
            }else{
            /* 如果当前是客户分组进来 回到客户分组列表 */
                this.$router.push({
                    path: basePath + '/clientGrouping'
                });
            }
        },
        // 点击取消
        cancel: function() {
            this.$confirm('信息尚未保存，是否返回', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.goBack();
            }).catch(() => {

            });
        },

        // 浮层相关方法
        // 穿梭框数据渲染
        renderFunc: function(h, option) {
            let title = `${option.storeName} - ${option.loginName}`;
            let cls = option.groupId ? 'Mbor' : '';
            return <span class={cls} title={title}>{option.storeName} - {option.loginName}</span>;
        },
        // 关闭浮层
        dialogClose: function(type) {
            this.dialogVisible = false;
            this.leftSearchVal = '';
        },
        // 浮层点击确定
        handleSave: function() {
            this.dialogClose();
            this.bhList = this.form.rightList.map((v,k) => {
                return JSON.parse(v)
            });
            this.detailRightList = this.form.rightList;
            this.$refs.form.validateField('rightList');
        },
        // 浮层点击取消
        handleClose: function() {
            this.dialogClose();
            this.form.rightList = this.detailRightList;
        },
        // 左侧数据初始化
        initLeftData: function(val,pageSize) {
            this.form.leftList = [];
            let sendUrl = '/trader/parentDepartment/customer/list';
            let sendObj = {
                sellerId: sessionStorage.getItem('traderId'),
                storeName: val || '',
            };
            axiosApi(sendUrl, sendObj, (res) => {
                let data = res.data;
                this.form.leftList = data.data.map((v,k) => {
                    v.key = JSON.stringify({
                        storeName : v.storeName,
                        loginName : v.loginName,
                        buyerId : v.buyerId,
                    });
                    return v;
                });
                
            }, 1, 10000);
        },
        // 左侧搜索
        leftSearch: function(val) {
            // 如果搜索条件 为空查 初始化数据
            if (val === '') {
                this.initLeftData();
                // 按搜索条件 搜索
            } else {
                this.initLeftData(val,10000);
            }
        },
    },
}
</script>

<style lang="less">
.addAndEditBox {
    .clientDialog .el-dialog{
        width: 850px;
        height:auto
    }
    .bd {
        border: 1px solid #ccc;
    }
    .el-transfer-panel:first-child .Mbor{
        color: #20a0ff;
    }
    .formBox {
        padding-top: 30px;
    }
    .dataBox {
        margin-top: 20px;
        .Mbtn{
            width: 90px;
            height: 32px;
            border: 1px solid #ddd;
            text-align: center;
            border-bottom: none;
        }
        .list {
            max-height: 400px;
            height: 200px;
            overflow: hidden;
            width: 500px;
            overflow-y: auto;
            li {
                float: left;
                padding: 10px;
                padding-left: 30px;
                width: ~"calc(50% - 40px)";
            }
        }
    }
    .popBox {
        margin: 0 auto;
        width: 70%;
        position: relative;
        /* 重写穿梭框按钮 样式 */
        .el-button--primary{
            color: #1f2d3d!important;
            background-color: #fff!important;
            border: 1px solid #bfcbd9!important;
        }

        .el-transfer-panel__body {
            .el-input--small {
                width: 218px!important
            }
        }
        .leftSearch {
            display: none;
            position: absolute;
            z-index: 10;
            top: 47px;
            left: 11px;
        }
        .tishi{
            color: #8391a5;
            margin-top: 20px;
            width: 400px;
        }
    }
    .el-dialog {
        width: 800px;
        height: 730px;
    }
    .el-transfer-panel {
        width: 220px;
    }
    .el-transfer-panel__body {
        height: 340px;
    }
    .el-transfer-panel__list.is-filterable {
        height: 300px;
    }
}
</style>

