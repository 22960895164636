import {axiosApi, basePath} from '../../api/api.js'
import {Notification, Message} from 'element-ui'
import m from '../../main.js'

const ticket = {
    state: {
        //商品列表维护
        goodtableData: '',
        allgoodtableData: [],//查询所有的商品数据
        //添加商品列表维护，不走接口
        willadd: [],
        willdelete: [],
        //赠品列表维护，不走接口
        giftlistData: '',
        //优惠券列表
        ticketlist: '',
        ticketid: '',
        willaddgiftData: [{
            fullMoney: "",//满足金额
            fullAmount: "",//满足数量
            fullbuyNum: 1,// 换购种类数量默认为1
            giftList: []
        }],
        willaddgiftDataid: 0,//第几个
        willaddgiftnum: [],
        willaddgiftnumid: '',
        gifttype: '',//1按订购金额，2按订购数量
        couponid: '',
        addloading: false,
        updateloading: false,
        tableloading: false,
        notId: [],
        skuNos:[],
        // 加价购
        willBuyData: [{
            fullMoney: "",//满足金额
            fullAmount: "",//满足数量
            fullbuyNum: '',// 换购种类数量
            goodsList: []
        }],
        willBuygoodIndex: 0,//加价购第几个
    },
    mutations: {
        // 加价购
        casewillBuyData(state, payload) {//删除一种满赠 加价购商品
            state.willBuyData.splice(payload, 1);
        },
        //删除单个赠品
        deleteOnewillBuygood(state, payload) {
            state.willBuyData[payload.index1].goodsList.splice(payload.index2, 1);
        },
        //新增一种满赠条件
        addwillBuyData(state, payload) {
            if (state.willBuyData.length < 20) {
                state.willBuyData.push({
                    fullMoney: "",//满足金额
                    fullAmount: "",//满足数量
                    fullbuyNum: '',// 换购种类数量
                    goodsList: []
                })
            } else {
                Message.warning('最多添加20条活动规则');
            }
        },
        clearwillBuyData(state, payload) {
            state.willBuyData = [{
                fullMoney: "",//满足金额
                fullAmount: "",//满足数量
                fullbuyNum: '',// 换购种类数量
                goodsList: []
            }]
        },
        // end
        deleteMealGood(state, payload) {// 删除已选中套餐商品
            state.willadd = state.willadd.filter((i) => {
                return i !== payload
            })
        },
        clearwilladd(state) {
            state.willadd = []
        },
        clearwilldelete(state) {
            state.willdelete = []
        },
        clearwilladdgift(state) {
            state.willaddgiftData = [{
                fullMoney: "",//满足金额
                fullAmount: "",//满足数量
                fullbuyNum: 1,// 换购种类数量
                giftList: []
            }]
            state.willaddgiftnum = []
        },
        //删除一种满赠条件
        casewilladdgiftData(state, payload) {
            state.willaddgiftData.splice(payload, 1);
        },
        //新增一种满赠条件
        addwilladdgift(state, payload) {
            if (state.willaddgiftData.length < 5) {
                state.willaddgiftData.push({
                    fullMoney: "",//满足金额
                    fullAmount: "",//满足数量
                    fullbuyNum: 1,// 换购种类数量
                    giftList: []
                })
            } else {
                Message.warning('最多添加5条活动规则');
            }
        },
        casewilladdgiftnum(state, payload) {
            var c
            state.willaddgiftnum.forEach((a, b) => {
                if (a.id == payload) {
                    c = b
                } else if (a.id > payload) {
                    a.id = a.id - 1
                }
            })
            if (c != undefined) {
                state.willaddgiftnum.splice(c, 1)
            }
        },
        //删除单个赠品
        deleteOnewillGift(state, payload) {
            console.log('delete',state.willaddgiftData[payload.index1].giftList)
            state.willaddgiftData[payload.index1].giftList.splice(payload.index2, 1);
        },
        //单个删除商品
        deletewilladddata(state, payload) {
            state.willadd.splice(payload, 1)
        },
        //将要删除的商品
        willdetelegoods(state, payload) {
            state.willdelete = payload
        },
        //批量删除商品
        deletegoods(state) {
            var m = state.willadd.filter((each) => {
                let g = true
                for (let i = 0; i < state.willdelete.length; i++) {
                    if (each.skuNo == state.willdelete[i].skuNo) {
                        g = false
                    }
                }
                return g
            })
            state.willdelete = []
            state.willadd = m
        },
        writeticket(state, payload) {
            state.ticketid = payload
        },
        writecouponid(state, payload) {
            state.couponid = payload
        },
        //商品列表
        initgood(state, payload) {
            state.willadd = [];
            state.willadd.push(...payload.willadd)
            // state.willadd = new Set(...state.willadd)
        },
        //初始化赠品
        initgiftmoney(state, payload) {
            state.willaddgiftData = [];
            state.willaddgiftData.push(...payload)
        },
        initgiftnum(state, payload) {
            state.willaddgiftnum.push(...payload)
        },
        clearnotid(state, payload) {
            state.notId = []
        },
    },
    actions: {
        addgoodwidthadditional({state, commit}, payload) {// 添加加价购商品
            state.willBuyData[state.willBuygoodIndex].goodsList.push(...payload.willadd)
        },
        //设置商品列表
        addgood({ state, commit }, payload) {
            console.log(payload,'xxxxxNNNx')
            if (payload.addAllgoods) {
                state.willadd = [...payload.willadd]
            } else {
                state.willadd.push(...payload.willadd);
            }
            console.log(state.willadd,'state.willaddstate.willadd')
        },
        addgift({state}, payload) {
            state.willaddgiftData[state.willaddgiftDataid].giftList.push(...payload.willadd);
        },
        deleteGift({state}, payload) {
            state.willaddgiftData[payload.index1].giftList.splice(payload.index2, 1);
        },
        //请求商品列表
        querygoodtableData({state}, payload) {
            state.tableloading = true
            const api = '/goods/skuInfoPage/select'
            let newbody = payload.data
            let initialstate = {

                sellerId: sessionStorage.getItem('traderId'),
                skuState: 2,  				//sku状态(1：待上架（下架） 2：上架中)
                auditState: 2  		//审核状态（1：待审核 2：通过 3： 未通过）
            }
            if (!payload.data.notId) {
                newbody = Object.assign({}, payload.data, {notId: state.notId,skuNos:state.skuNos})
            } else {
                state.notId = payload.data.notId;
                state.skuNos = payload.data.skuNos
            }
            let obj = {};
            if(payload.hotelAgreementId){
                obj = {hotelAgreementId:payload.hotelAgreementId};
            }
            if (payload.skuState === 'null') {
                newbody = Object.assign({}, newbody, {skuState: null})
            }
            let body = Object.assign({}, initialstate, newbody,obj);
            axiosApi(api, body, (res) => {
                state.tableloading = false
                res.data.data.elements.forEach(v=>{
                    v.num=1
                })
                state.goodtableData = res.data.data
            }, payload.currentPage, 10)
        },
        querygoodtableDataStockIn({state}, payload) {
            state.tableloading = true
            const api = '/goods/skuInfoPage/select'
            let newbody = payload.data
            let initialstate = {
                sellerId:sessionStorage.getItem('traderId'),
                skuState: 2,  				//sku状态(1：待上架（下架） 2：上架中)
                auditState: 2,  		//审核状态（1：待审核 2：通过 3： 未通过）
                skuType: 1,
            }
            if(!payload.data.notId) {
                newbody = Object.assign({}, payload.data, {notId: state.notId})
            }else{
                state.notId = payload.data.notId
            }
            let body = Object.assign({},initialstate,newbody);
            axiosApi(api,body,(res)=> {
                setTimeout(() => {
                    state.tableloading = false
                    res.data.data.elements.forEach(item => {
                        item.enterShopPrice = '0.00'
                        item.num=1
                    })
                    state.goodtableData = res.data.data
                }, 250)
            },payload.currentPage,10,err=>{
                state.tableloading = false;
            })
        },
        querygoodtableDataStockOut({state}, payload) {
            state.tableloading = true
            const api = '/warehouse/info/list'

            let newbody = payload.data
            let initialstate = {
                sellerId:sessionStorage.getItem('traderId'),
                skuState: null, //sku状态(1：待上架（下架） 2：上架中)
                auditState: 2 //审核状态（1：待审核 2：通过 3： 未通过）
            }
            if(!payload.data.notId) {
                newbody = Object.assign({}, payload.data, {notId: state.notId})
            }else{
                state.notId = payload.data.notId
            }
            let body = Object.assign({},initialstate,newbody);
            axiosApi(api,body,(res)=> {
                setTimeout(() => {
                    state.tableloading = false
                    state.goodtableData = res.data.data
                }, 250)
            },payload.currentPage,10,err=>{
                state.tableloading = false;
            })
        },
        //请求商品列表
        querygoodtableDataMeal({state}, payload) {
            state.tableloading = true
            const api = '/goods/skuInfoPage/select'
            let newbody = payload.data
            let initialstate = {
                sellerId: sessionStorage.getItem('traderId'),
                auditState: 2,// 审核状态（1：待审核 2：通过 3： 未通过）
                skuType: 2// 套餐 固定为2
            }
            if (!payload.data.notId) {
                newbody = Object.assign({}, payload.data, {notId: state.notId,skuNos:state.skuNos},)
            } else {
                state.notId = payload.data.notId;
                state.skuNos = payload.data.skuNos
            }
            let obj = {};
            if(payload.hotelAgreementId){
                obj = {hotelAgreementId:payload.hotelAgreementId};
            }
            let body = Object.assign({}, initialstate, newbody,obj);
            axiosApi(api, body, (res) => {
                state.tableloading = false
                res.data.data.elements.forEach(v=>{
                    v.num=1
                })
                state.goodtableData = res.data.data
            }, payload.currentPage, 10)
        },
        // 酒店折扣与维护 查询全部商品
        allGoodsQuery({state}, payload) {
            state.tableloading = true
            const api = '/goods/skuInfoPage/select'
            let newbody = payload.data
            let initialstate = {
                sellerId: sessionStorage.getItem('traderId'),
                auditState: 2  		//审核状态（1：待审核 2：通过 3： 未通过）
            }
            if (!payload.data.notId) {
                newbody = Object.assign({}, payload.data, {notId: state.notId,skuNos:state.skuNos})
            } else {
                state.notId = payload.data.notId;
                state.skuNos = payload.data.skuNos
            }
            let obj = {};
            if(payload.hotelAgreementId){
                obj = {hotelAgreementId:payload.hotelAgreementId};
            }
            let body = Object.assign({}, initialstate, newbody,obj);
            axiosApi(api, body, (res) => {
                state.tableloading = false
                res.data.data.elements.forEach(v=>{
                    v.num=1
                })
                state.goodtableData = res.data.data
            }, payload.currentPage, 10)

        },
        fullgiftSearchGoods({state}, payload) {// 满赠 查询商品接口 (满赠组合)
            state.tableloading = true
            const api = '/marketing/activity/querySku';
            let newbody;
            if(payload.activityId){
                newbody = {...payload.data,...{activityId:payload.activityId}}
            }else {
                newbody = payload.data
            }
            let initialstate = {
                sellerId: sessionStorage.getItem('traderId'),
                auditState: 2, //审核状态（1：待审核 2：通过 3： 未通过）
                skuType: '1',// 过滤套餐商品
            }
            if (newbody.activitySource == 1) { //B2B 添加商品状态
                initialstate.skuState = 2 //sku状态(1：待上架（下架） 2：上架中)
            }
            if (!payload.data.notId) {
                newbody = Object.assign({}, payload.data, {notId: state.notId},{activityId:payload.activityId})
            } else {
                state.notId = payload.data.notId
            }
            let body = Object.assign({}, initialstate, newbody);
            return new Promise((resolve, reject) => {
                axiosApi(api, body, (res) => {
                    state.tableloading = false
                    if (newbody.queryPage == 0) {// 不分页
                        state.allgoodtableData = []
                        state.allgoodtableData = res.data.data ? res.data.data.elements.filter(v => v.participateStatus != 1) : []
                    } else {// 分页
                        state.goodtableData = res.data.data
                    }
                    resolve(res);
                }, payload.currentPage, 10, err => {
                    reject(err)
                })
            })
        },
        societyfullgiftSearchGoods({state}, payload) {// 满赠 查询商品接口 (满赠组合) 社会版久集
            state.tableloading = true
            const api = '/superc/traderGoods/selectPageList';
            let newbody;
            if(payload.activityId){
                newbody = {...payload.data,...{activityId:payload.activityId}}
            }else {
                newbody = payload.data
            }
            console.log(payload, 'newbody');
            let initialstate = {
                sellerId: sessionStorage.getItem('traderId'),
                auditState: 2, //审核状态（1：待审核 2：通过 3： 未通过）
                skuType: '1',// 过滤套餐商品
            }
            if (newbody.activitySource == 1) { //B2B 添加商品状态
                initialstate.skuState = 2 //sku状态(1：待上架（下架） 2：上架中)
            }
            if (!payload.data.notId) {
                newbody = Object.assign({}, payload.data, {notId: state.notId},{activityId:payload.activityId})
            } else {
                state.notId = payload.data.notId
            }
            let body = Object.assign({}, initialstate, newbody);
            console.log(newbody,body, 'body')
            return new Promise((resolve, reject) => {
                axiosApi(api, body, (res) => {
                    state.tableloading = false
                    if (newbody.queryPage == 0) {// 不分页
                        state.allgoodtableData = []
                        state.allgoodtableData = res.data.data ? res.data.data.elements.filter(v => v.participateStatus != 1) : []
                    } else {// 分页
                        state.goodtableData = res.data.data
                    }
                    resolve(res);
                }, payload.currentPage, 10, err => {
                    reject(err)
                })
            })
        },
        chooseAllGoodsAction({rootState, state, dispatch}, payload) {
            let currentPath = payload.currentPath, data = {}, skuObj = {};
            if (currentPath.indexOf('/addacaboutenoughsent') != -1 || currentPath.indexOf('/updatesent') != -1) skuObj.activityType = 1; // 送赠品
            if (currentPath.indexOf('/addwithadditional') != -1) skuObj.activityType = 4; // 换购
            if (currentPath.indexOf('/addacaboutenoughcut') != -1 || currentPath.indexOf('/updatecut') != -1) skuObj.activityType = 2; // 满减
            data = {data: skuObj, currentPage: 1};
            let newpostdata = Object.assign({}, data), buyerList = rootState.clientlist.willadd || [];
            newpostdata.data.buyerIds = buyerList.map(v => v.buyerId); // 添加 入参 buyerIds 选择客户的 buyerIds
            newpostdata.data.activitySource = payload.activitySource || 1; // B2B和酒店
            newpostdata.data.queryPage = 0; // 0-查询全部 1-分页查询
            newpostdata.data.isIntegerMultiple = payload.isIntegerMultiple; //
            if(payload.activitySource != '3'){
                dispatch('fullgiftSearchGoods', newpostdata)
                    .then(res => {
                        console.log('777777')
                        payload.instance.confirmButtonLoading = false;
                        dispatch('addgood', {
                            willadd: state.allgoodtableData,
                            addAllgoods: true
                        })
                        payload.done();
                        if (state.allgoodtableData.length === 0) {
                            Message.warning('没有可以选中的商品')
                            return false
                        }
                        Message.success('添加商品成功');
                    })
                    .catch(() => {
                        payload.done();
                        Message.error('添加商品失败');
                    })
            }else{
                dispatch('societyfullgiftSearchGoods', newpostdata)
                    .then(res => {
                        console.log('777777newpostdata',newpostdata)
                        payload.instance.confirmButtonLoading = false;
                        dispatch('addgood', {
                            willadd: state.allgoodtableData,
                            addAllgoods: true
                        })
                        payload.done();
                        if (state.allgoodtableData.length === 0) {
                            Message.warning('没有可以选中的商品')
                            return false
                        }
                        Message.success('添加商品成功');
                    })
                    .catch(() => {
                        payload.done();
                        Message.error('添加商品失败');
                    })
            }

        },
        queryMealData({state}, payload) {// 过滤套餐商品列表(所有商品)
            state.tableloading = true
            const api = '/goods/skuInfoPage/select'
            let newbody = payload.data
            let initialstate = {
                sellerId: sessionStorage.getItem('traderId'),
                auditState: 2,  		//审核状态（1：待审核 2：通过 3： 未通过）
                skuType: '1'  // 过滤套餐商品
            }
            if (!payload.data.notId) {
                newbody = Object.assign({}, payload.data, {notId: state.notId})
            } else {
                state.notId = payload.data.notId
            }
            let body = Object.assign({}, initialstate, newbody);
            axiosApi(api, body, (res) => {
                state.tableloading = false
                res.data.data.elements.forEach(v=>{
                    v.num=1
                })
                state.goodtableData = res.data.data
            }, payload.currentPage, 10)
        },
        queryJicaiData({state}, payload) {// 过滤套餐商品列表(所有商品 包括上架 下架)
            state.tableloading = true
            const api = '/goods/skuInfoPage/select'
            let newbody = payload.data
            let initialstate = {
                sellerId: sessionStorage.getItem('traderId'),
                skuType: '1'  // 过滤套餐商品
            }
            if (!payload.data.notId) {
                newbody = Object.assign({}, payload.data, {notId: state.notId})
            } else {
                state.notId = payload.data.notId
            }
            let body = Object.assign({}, initialstate, newbody);
            axiosApi(api, body, (res) => {
                state.tableloading = false
                res.data.data.elements.forEach(v=>{
                    v.num=1
                })
                state.goodtableData = res.data.data
            }, payload.currentPage, 10)
        },
        //过滤套餐商品列表(上架中,通过)
        queryMealtableData({state}, payload) {
            state.tableloading = true
            if (payload && payload.chooseIndex >= 0) {// 判断添加到哪个活动的商品
                state.willBuygoodIndex = payload.chooseIndex;
            }
            const api = '/goods/skuInfoPage/select'
            let newbody = payload.data
            let initialstate = {
                sellerId: sessionStorage.getItem('traderId'),
                skuState: 2, //sku状态(1：待上架（下架） 2：上架中)
                auditState: 2, //审核状态（1：待审核 2：通过 3： 未通过）
                skuType: '1' // 过滤套餐商品
            }
            if (!payload.data.notId) {
                newbody = Object.assign({}, payload.data, {notId: state.notId})
            } else {
                state.notId = payload.data.notId
            }
            let body = Object.assign({}, initialstate, newbody);
            if(payload.isGift) delete body.skuState;
            axiosApi(api, body, (res) => {
                state.tableloading = false
                res.data.data.elements.forEach(v=>{
                    v.num=1
                })
                state.goodtableData = res.data.data
            }, payload.currentPage, 10,err => {
                this.$message.warning(err.data.message)
            })
        },
        //我的客户添加总经销商品
        querygoodtableDatas({state}, payload) {
            state.tableloading = true
            const api = '/goods/skuInfoPage/select'
            let initialstate = {
                sellerId: sessionStorage.getItem('traderId'),
                skuState: 2, //sku状态(1：待上架（下架） 2：上架中)
                auditState: 2, //审核状态（1：待审核 2：通过 3： 未通过）
                isAuthorization: true
            }
            let body = Object.assign({}, initialstate, payload.data);
            axiosApi(api, body, (res) => {
                state.tableloading = false
                res.data.data.elements.forEach(v=>{
                    v.num=1
                })
                state.goodtableData = res.data.data
            }, payload.currentPage, 10)
        },
        //供货商查询店铺下商户可以购买的商品
        queryskuCustomData({state}, payload) {
            state.tableloading = true
            const api = '/goods/skuCostom/search'
            let initialstate = {
                sellerId: sessionStorage.getItem('traderId'),
            }
            let body = Object.assign({}, initialstate, payload.data);
            axiosApi(api, body, (res) => {
                state.tableloading = false
                state.goodtableData = res.data.data
            }, payload.currentPage, 10)
        },
        //加载赠品列表
        querygiftlistData({state}, payload) {
            state.tableloading = true;
            if (payload && payload.chooseIndex >= 0) {
                state.willaddgiftDataid = payload.chooseIndex;
            }
            const api = "/marketing/gift/page"
            let body = {};
            if (payload && (payload.type == 'addwithadditional' || payload.ischangedorder )) {
                body.giftType = 0 // 赠品标品
            }
            let newbody = Object.assign({
                sellerId: sessionStorage.getItem('traderId'),
            }, body, payload.data)
            axiosApi(api, newbody, (res) => {
                state.tableloading = false
                state.giftlistData = res.data.data
            }, payload.currentPage, 10)
        },
        //优惠券新增
        addticket({state}, payload) {
            state.addloading = true
            const api = '/marketing/coupon/add'
            let body = {
                sellerId: sessionStorage.getItem('traderId'),
            }
            let newbody = Object.assign({}, body, payload.data)
            axiosApi(api, newbody, (res) => {
                Notification.success({
                    message: '添加优惠券成功，即将跳转到优惠券列表页面'
                })
                setTimeout(function () {
                    state.addloading = false
                    m.$router.push(basePath + '/activity/1')
                }, 1000)
            }, 0, 10, (res) => {
                state.addloading = false
                Notification.warning({
                    message: '添加优惠券失败，' + res.data.message
                })
            })
        },
        //优惠券更新
        updateticket({state}, payload) {
            state.updateloading = true
            const api = '/marketing/coupon/update'
            let body = {
                sellerId: sessionStorage.getItem('traderId')
            }
            let newbody = Object.assign({}, body, payload.data)
            axiosApi(api, newbody, (res) => {
                Notification.success({
                    message: '修改优惠券成功，即将跳转到优惠券列表页面'
                })
                setTimeout(function () {
                    state.updateloading = false
                    m.$router.push(basePath + '/activity/1')
                }, 1000)
            }, 0, 10, (res) => {
                state.updateloading = false
                Notification.warning({
                    message: '修改优惠券失败，' + res.data.message
                })
            })
        },
        // 优惠券列表
        queryticketlist({state}, payload) {
            const api = '/marketing/coupon/list'
            let body = {
                startStatus: 1
            }
            let newbody = Object.assign({}, body, payload.data)
            axiosApi(api, newbody, (res) => {
                state.ticketlist = res.data.data
            }, payload.currentPage, 10)
        },
    },
    getters: {
        willaddid(state) {
            return state.willadd.map((a, b) => {
                return a.id
            })
        },
        willaddskuno(state) {
            return state.willadd.map((a, b) => {
                return a.skuNo
            })
        }
    }
}

export default ticket
