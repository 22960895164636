<template>
    <div id="classManage" class="">
        <div class="title-top bg-f mb-5">
            <div class="clearfix black">
                <el-breadcrumb class="fl ">
                    <el-breadcrumb-item>类别维护</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
        </div>
        <div class="content bg-f pl-30 pr-30 pb-50">
            <div class="clearfix search">
                <el-form :inline="true" :model="form" label-width="90px" class="clearfix pl-20 pt-30"
                         style="width: 1000px">
                    <div class="fl" style="width: 690px">
                        <el-form-item label="类别名称">
                            <el-input v-model.trim="form.categoryName" placeholder="输入类别名称"
                                      @keyup.enter.native='achieve(true)'></el-input>
                        </el-form-item>
                    </div>
                    <el-button type="info" class="fl ml-50" @click='achieve(true)'>查询</el-button>

                </el-form>
                <div class='clearfix'>
                    <span class="fr color-blue cursor mr-30"
                          @click="dialogShow = true">添加类别</span>
                </div>
                <el-table :data="tableData" v-loading="Loading" class='ml-30 mt-10 table-default mr-30' stripe>
                    <el-table-column label="类别名称" prop="categoryName"></el-table-column>
                    <el-table-column label="包厢/大厅">
                        <template slot-scope="scope">
                            <span>{{ scope.row.categoryType == 1 ? '大厅' :'包厢'}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="100">
                        <template slot-scope="scope">
                            <el-button v-if="scope.row.categoryType == 2" size="small" type='text' style="width: 95px;"
                                       @click="changeItem(scope,1)">设为大厅
                            </el-button>
                            <el-button v-else size="small" type='text' style="width: 95px;"
                                       @click="changeItem(scope,2)">设为包厢
                            </el-button>
                            <el-button class='ml-0 width-90' size="small" type='text'
                                       @click="updateItem(scope)">编辑
                            </el-button>
                            <el-button class='ml-0 width-90' size="small" type='text'
                                       @click="deleteItem(scope)">删除
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>

                <el-pagination v-if="total>0" class="tar mr-30" :current-page="currentPage" :page-size='10'
                               :total='total'
                               layout="prev , pager, next, jumper" @current-change="handleCurrentChange">
                    <!-- 分页组件 -->
                </el-pagination>
            </div>
        </div>

        <!--添加服务员弹窗-->
        <el-dialog class="minMop" :title="!form1.id?'添加类别':'编辑'" :visible.sync='dialogShow' @close="closeDialog1">
            <el-form :model="form1" ref="ruleForm" :rules='rules'>
                <el-form-item label="类别名称" prop='categoryName'>
                    <el-input placeholder='输入类别名称' v-model.trim='form1.categoryName'></el-input>
                </el-form-item>
                <el-form-item v-if="!form1.id" label="大厅/包厢" prop='categoryType'>
                    <el-select clearable v-model.trim="form1.categoryType" placeholder="请选择">
                        <el-option
                                v-for="item in options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div slot='footer' class="dialog-footer">
                <el-button type='danger' @click="save">确 定</el-button>
                <el-button @click="dialogShow = false">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {axiosApi, basePath} from "../../api/api";

    export default {
        data() {
            return {
                dialogShow: false,
                Loading: true,
                form: { //搜索条件
                    categoryName: null,
                },
                options: [{
                    value: '1',
                    label: '大厅'
                }, {
                    value: '2',
                    label: '包厢'
                }],
                form1: { //搜索条件
                    id: null,
                    categoryType: '',
                    categoryName: '',
                },
                tableData: [], //员工列表
                currentPage: 1,
                total: 0,
                rules: {
                    categoryName: [{required: true, message: '请输入类别名称'}, {min: 1, max: 10, message: '类别名称最多10个字符'}],
                    categoryType: [{required: true, message: '请选择大厅/包厢'}],
                }
            }
        },
        watch: {
            dialogShow(val) {
                if (!val) {
                }
            }
        },
        methods: {
            achieve(bool) {
                if (bool && this.currentPage != 1) {
                    this.currentPage = 1;
                }
                const api = "/catering/platform/tableCategory/selectList";
                const body = {
                    categoryName: this.form.categoryName ? this.form.categoryName : null
                };
                axiosApi(api, body, this.callback, this.currentPage, 10)
            },

            callback(res) {
                console.log(res, "res");
                this.tableData = res.data.data;
                this.total = res.data.data.totalCount;
                this.Loading = false
            },

            handleCurrentChange(val) {
                this.currentPage = val;
                this.achieve()
            },
            closeDialog1() {
                setTimeout(() => {
                    this.form1.id = null;
                    this.form1.categoryType = null;
                    this.form1.categoryName = null;
                    this.$refs.ruleForm.resetFields()
                }, 500)
            },

            changeItem(scope, categoryType) {
                var type = categoryType == 1 ? "设为大厅" : "设为包厢";

                this.$confirm(type + " 是否继续?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                }).then(() => {
                    axiosApi('/catering/platform/tableCategoryType/update', {
                        id: scope.row.id,
                        categoryType: categoryType,
                    }, (res) => {
                        this.$message({
                            type: 'success',
                            duration: 1000,
                            message: type + "成功!",
                        });
                        this.achieve(true);
                    }, this.currentPage, 10, (res) => {
                        this.$message.error(res.data.message)
                    })
                }).catch(() => {
                    this.$message({
                        message: "已取消" + type,
                    });
                });
            },

            updateItem(scope) {
                this.dialogShow = true;
                setTimeout(() => {
                    this.$refs.ruleForm.resetFields();
                    this.form1.categoryName = scope.row.categoryName;
                    this.form1.id = scope.row.id;
                }, 0)



            },

            deleteItem(scope) {
                this.$confirm("将删除酒桌类别, 是否继续?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                }).then(() => {
                    axiosApi('/catering/platform/tableCategory/delete', {
                        id: scope.row.id,
                    }, (res) => {
                        this.$message({
                            type: 'success',
                            duration: 1000,
                            message: "删除成功!",
                        });
                        this.achieve(true);
                    }, this.currentPage, 10, (res) => {
                        this.$message.error(res.data.message)
                    })
                }).catch(() => {
                    this.$message({
                        message: "已取消删除",
                    });
                });
            },
            save() {
                this.$refs.ruleForm.validate((v) => {
                    if (v) {
                        let body = {
                            id: this.form1.id,
                            categoryType: this.form1.categoryType,
                            categoryName: this.form1.categoryName,
                        }
                        if (this.form1.id) {
                            this.insert(body, '/catering/platform/tableCategory/update') // 添加
                                .then(res => {
                                    this.$message({
                                        type: 'success',
                                        duration: 1000,
                                        message: "更新成功!",
                                    });
                                    this.dialogShow = false;
                                    this.achieve(true);
                                })
                                .catch(res => {
                                    this.$message.error(res.data.message)
                                });
                        } else {
                            this.insert(body, '/catering/platform/tableCategory/insert') // 添加
                                .then(res => {
                                    this.$message({
                                        type: 'success',
                                        duration: 1000,
                                        message: "添加成功!",
                                    });
                                    this.dialogShow = false;
                                    this.achieve(true);
                                })
                                .catch(res => {
                                    this.$message.error(res.data.message)
                                });
                        }

                    }
                })
            },

            insert(body, url) {// 插入接口
                this.btnLoading = true
                return new Promise((resolve, reject) => {
                    axiosApi(url, body, res => {
                        resolve(res)
                        this.btnLoading = false
                    }, 1, 10, res => {
                        reject(res)
                        this.btnLoading = false
                    })
                })
            },
        },
        //进入加载
        mounted() {
            this.$nextTick(function () {
                this.achieve();
            })
        }
    }
</script>

<style lang="less">
    #classManage {
        .search .el-form-item__label {
            width: 90px;
        }

        .el-table {
            overflow: hidden;
            width: 94%;
            max-width: 100%;
            background-color: #fff;
            border: 1px solid #dfe6ec;
            font-size: 12px;
            color: #1f2d3d;
        }
        .el-dialog {
            height: 300px !important;
            min-height: 300px !important;
        }

        .el-form-item__error {
            margin-left: 185px !important;
        }

        .el-form-item__label {
            font-size: 12px;
        }

        .title-top {
            border: 0;
        }

        .black {
            .edit-btn span {
                color: #000000 !important;
            }
        }

    }
</style>