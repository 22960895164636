<template>
    <div :class="mysize == 'mini' ? 'el-input el-input--mini' : mysize = 'small' ? 'el-input el-input--small' : 'el-input'">
        <el-input @change="updatevalue" @blur="nativevalue" v-model="myvalue" ref="input" icon="" :size="mysize"
                  class="inline-block mr-5" :disabled="disabled" :placeholder="placeholderss"></el-input>
    </div>
</template>
<script>
    function emit(val) {
        this.myvalue = val
        this.$emit('input', this.myvalue)

    }
    export default {
        name: 'syInput',
        props: {
            value: {
                require: true
            },
            type: {
                type: String,
                require: true
            },
            size: String,
            min: {
            	type:Number,
                default:0
            },
            precision: Number,
            max: Number,
            length: [Number, Array],
            message: String,
            disabled: Boolean,
            sent: Boolean, // 营销活动上限
            willBuy: Boolean, // 换购最大限购数量
            placeholders: String,

        },
        data() {
            return {
                myvalue: '',
                mysize: '',
                placeholderss:''
            }
        },
        watch: {
            value(val) {
                this.myvalue = val + ''
            },
             placeholders(val) {
                this.placeholderss = val
            },
        },
        methods: {
            updatevalue(val) {
                switch (this.type) {
                    case 'int':
                        var re = /[^0-9]/g
                        var newval = val.replace(re, '')
                        console.log('val', newval);
                        var min = Number(this.min)
                        var max = Number(this.max)
                        if (this.max) {
                            if (newval >= max) {
                                newval = max
                            }
                        }
                        if (this.willBuy) {
                            if (this.max == 0) {
                                newval = 0
                            }
                        }
                        emit.call(this, newval + '')
                        return
                    case 'float':
                        var re = /[^.0-9]/g
                        var newval = val.replace(re, '')
                        var m = newval.indexOf('.')
                        var min = Number(this.min)
                        var max = Number(this.max)
                        if (this.max) {
                            if (newval >= max) {
                                newval = max + ''
                                emit.call(this, newval);
                                return
                            }
                        }
                        if (m < 0) {
                            if (newval != '') {
                                this.$emit('input', newval)

                                    this.myvalue = newval

                            } else {

                                    this.myvalue = ''

                            }
                        } else {
                            var left = newval.slice(0, m + 1)
                            if(this.precision){
                                var right = newval.slice(m + 1).replace(/[^0-9]/g, '').slice(0, this.precision)
                            }else{
                                var right = newval.slice(m + 1).replace(/[^0-9]/g, '').slice(0, 2)
                            }
                            
                            
                            var old = right
                            if (right.length < 2) {
                                    this.myvalue = left + old;
                                // right = right.padEnd(2, '0')
                                let mid = new Array(2).fill('0').join('').slice(right.length);
                                right = right + mid;
                                console.log(right,'======right')
                                this.$emit('input', left + right)
                            } else {
                                emit.call(this, left + right)
                            }
                        }
                        return
                    case "string":
                        var len = this.length
                        switch (typeof (len)) {
                            case 'number':
                                var newval = this.myvalue.slice(0, len + 4)
                                if (newval.length > len[1]) {
                                    this.$message(this.message)
                                }
                                emit.call(this, newval)
                                return
                            case 'object':
                                var newval = this.myvalue.slice(0, len[1] + 4)
                                if (newval.length > len[1]) {
                                    this.$message(this.message)
                                }
                                emit.call(this, newval)
                                return
                            default:
                                return
                        }
                        return
                    default:
                        return
                }
            },
            nativevalue(val) {
                switch (this.type) {
                    case 'int':
                        var min = this.min
                        var k = this.myvalue != undefined ? this.myvalue.replace(/^0+/, '') : ''
                        if (this.sent) {// 营销活动上限
                            if (this.myvalue) {
                                if (this.myvalue < min) {
                                    emit.call(this, min + '')
                                } else {
                                    emit.call(this, k)
                                }
                            } else {
                                emit.call(this, '')
                            }
                        } else {
                            if (this.myvalue <= min) {// 小于最低起订量 赋值最低起订量
                                emit.call(this, min + '')
                            } else {
                                emit.call(this, k)
                            }
                        }

                        return
                    case 'float':
                        var min = this.min || 0;
                        var k = (this.myvalue != undefined && this.myvalue >= 1 ? this.myvalue.replace(/^0+/, '') : this.myvalue != undefined ? this.myvalue : '') || '0';
                        var m = k.indexOf('.')
                        if (k < min) {
                            emit.call(this, min + '')
                        } else {
                            if (m < 0) {
                                emit.call(this, k + '.00')
                            } else {
                                var left = k.slice(0, m + 1)
                                var right = k.slice(m + 1).replace(/[^0-9]/, '').slice(0, 2)
                                if (right.length < 2) {
                                        // right = right.padEnd(2, '0')
                                        let mid = new Array(2).fill('0').join('').slice(right.length);
                                        right = right + mid;
                                        console.log(right,'======right')
                                        this.myvalue = left + right
                                } else {
                                    emit.call(this, k)
                                }
                            }
                        }
                        return
                    case 'string':
                        var len = this.length
                        switch (typeof (len)) {
                            case 'number':
                                var newval = this.myvalue != undefined ? this.myvalue.slice(0, len) : ''
                                emit.call(this, newval)
                                return
                            case 'object':
                                var newval = this.myvalue != undefined ? this.myvalue.slice(0, len[1]) : ''
                                emit.call(this, newval)
                                if (this.myvalue.length < len[0]) {
                                    this.$message(this.message)
                                }
                                return
                            default:
                                return
                        }
                        return
                    default:
                        return
                }
            }
        },
        mounted() {
            this.$nextTick(() => {
                this.myvalue = this.value + ''
                this.mysize = this.size
                this.placeholderss = this.placeholders ? this.placeholders : '请输入'
            })
        }
    }
</script>
<style lang="less">
    input::-webkit-inner-spin-button {
        -webkit-appearance: none !important;
        margin: 0;
    }

    input[type="number"] {
        -moz-appearance: textfield;
    }
</style>
