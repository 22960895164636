<template>
    <div class="payment-manager">
        <div class="title-tabs2 bg-f mb-5">
            <ul class="clearfix">
                <div v-for="(tab,index) in dataTabs" class="item" :key="index" @click="handleTabs(index)">
                    <li :class="tab==clickedTabName?'active':''">
                        {{tab}}
                        <div :class="tab==clickedTabName?'under-line':''"></div>
                    </li>
                    <div class="vertical-line"></div>
                </div>
            </ul>
        </div>
        <storeSelfGoods v-show="activeName==0"></storeSelfGoods>
        <storeNotSelfGoods v-show="activeName==1"></storeNotSelfGoods>
    </div>
</template>

<script>
    import storeSelfGoods from './storeSelfGoods';
    import storeNotSelfGoods from './storeNotSelfGoods'
    export default {
        name: "storeGoodsManage",
        components:{
            storeSelfGoods,
            storeNotSelfGoods
        },
        data(){
            return {
                clickedTabName: "自营商品管理",
                dataTabs:['自营商品管理','久加久商品管理'],
                activeName:0
            }
        },
        methods:{
            handleClick(tab, event) {
                console.log(tab, event);
            },
            handleTabs(index) {
                this.clickedTabName = this.dataTabs[index];
                this.activeName = index
            },
        }
    }
</script>

<style >

</style>