<template>
  <div id="pathdetail">
    <div class="title-top bg-f pl-20">轨迹详情</div>
    <div class="bg-f mt-5 pb-50">
      <el-form
        :inline="true"
        :model="form"
        label-width="90px"
        class="clearfix pl-20 pt-30"
      >
        <div class="fl width-70p">
          <el-form-item label="部门">
            <el-cascader
              :props="apartmentProps"
              expand-trigger="hover"
              :options="options"
              v-model="form.apartment"
              @change="handleChange"
              change-on-select
              clearable
            >
            </el-cascader>
          </el-form-item>
          <el-form-item label="人员">
            <el-select
              @change="changePeople"
              v-model="manage"
              placeholder="请选择员工"
            >
              <el-option
                v-for="item in optionren"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="时间">
            <el-date-picker
              v-model="pathtimeday"
              type="date"
              placeholder="选择日期"
              size="small"
              :picker-options="pickerOptions0"
              :clearable="false"
            >
            </el-date-picker>
          </el-form-item>
        </div>
        <div class="fl ml-50 right-btn">
          <el-button type="info" @click="search">搜索</el-button>
          <el-button
            v-if="isSelected && ensuredPeople"
            type="default"
            @click="showGpsDetail"
            >电量/GPS状态</el-button
          >
        </div>
      </el-form>

      <!-- gps/电量弹窗 -->
      <el-dialog
        :title="labelname + '的手机电量'"
        :visible.sync="showGpsDialog"
        @close="showGpsDialog = false"
      >
        <el-table
          max-height="450"
          border
          :data="gpsDetail"
          class="ml-30 mt-10 table-default mr-30"
          stripe
        >
          <el-table-column :resizable="false" label="时间" width="60">
            <template slot-scope="scope">
              <span>{{ scope.row.gmtCreate | getHM }}</span>
            </template>
          </el-table-column>
          <el-table-column :resizable="false" label="电量" width="60">
            <template slot-scope="scope">
              <span v-if="scope.row.electricity">{{
                scope.row.electricity == -1
                  ? ""
                  : scope.row.electricity.indexOf("%") > -1
                  ? scope.row.electricity
                  : scope.row.electricity + "%"
              }}</span>
            </template>
          </el-table-column>
          <el-table-column :resizable="false" label="GPS状态" width="60">
            <template slot-scope="scope">
              <span>{{
                scope.row.gpsStatus == 0
                  ? "未开启"
                  : scope.row.gpsStatus == 1
                  ? "已开启"
                  : ""
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            :resizable="false"
            label="wifi状态"
            width="60"
            prop="wifiStatus"
          >
            <template slot-scope="scope">
              <span>{{
                scope.row.wifiStatus == 0
                  ? "未连接"
                  : scope.row.wifiStatus == 1
                  ? "已连接"
                  : ""
              }}</span>
            </template>
          </el-table-column>
          <el-table-column :resizable="false" label="移动网络" width="60">
            <template slot-scope="scope">
              <span>{{
                scope.row.networkStatus == 0
                  ? "未连接"
                  : scope.row.networkStatus == 1
                  ? "已连接"
                  : ""
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            :resizable="false"
            label="位置"
            prop="addr"
            width="220"
          >
          </el-table-column>
          <el-table-column
            :resizable="false"
            label="手机型号"
            prop="phoneModel"
            width="120"
          >
          </el-table-column>
          <el-table-column
            :resizable="false"
            label="操作系统"
            prop="operatingSystem"
            width="60"
          >
          </el-table-column>
          <el-table-column
            :resizable="false"
            label="app版本号"
            prop="appVersion"
            width="70"
          >
          </el-table-column>
        </el-table>
      </el-dialog>

      <div class="mapbox" v-loading="loading">
        <div id="map" :class="{ clicked: isSelected }"></div>
        <div id="mapinfo" ref="mapinfo" :class="{ clicked: isSelected }">
          <div class="top">
            <div class="fir">
              <i class="iconfont icon-user"></i>
              <div>在线时间共计{{ selectedone.onlineTime }}</div>
            </div>
            <div
              class="sec"
              v-if="
                selectedone.outlineTime !== '0秒' &&
                selectedone.outlineTime !== null
              "
            >
              <span>轨迹异常</span>
              <span>失联{{ selectedone.outlineTime }}</span>
            </div>
          </div>
          <div
            id="infobody"
            :class="{
              'top-more':
                selectedone.outlineTime && selectedone.outlineTime !== '0秒',
            }"
          >
            <div class="color-9" style="margin:5px 0">
              里程：{{ selectedone.totalMeters | meters }}
            </div>
            <el-timeline>
              <el-timeline-item v-for="(item,index) in newsignVisitTrackList" :key="index" :timestamp="item.typeText" placement="top">
                <div class="right red" v-if="item.outLineException" >{{item.outLineException}}</div>
                <div v-else>
                  <div class="color-9">{{item.type == 5 ? "报备名称:"+item.taskName : item.type == 3 ? "拜访商户:"+item.traderName : ""}}</div>
                  <div class="color-9">{{item.type == 3 ? item.startTime + "-" + item.endTime : item.trackDateHm}}</div>
                  <div class="color-9">{{item.addr ? item.addr : item.type == 3 || item.type == 5 ? "无详细位置" : ""}}</div>
                </div>
              </el-timeline-item>
            </el-timeline>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
var map;
var renderGo;
let mapEle;
import { axiosApi, basePath } from "../../api/api";

export default {
  data() {
    return {
      pickerOptions0: {
        disabledDate(time) {
          return time.getTime() >= Date.now() + 24 * 60 * 60 * 1000 - 8.64e7;
        },
      },
      form: {
        apartment: [],
      },
      manage: "",
      options: [],
      optionren: [],
      departmentId: null,
      departmentIdList: [],
      tracklist: [],
      selectedone: {},
      isSelected: true,
      pathtimeday: null,
      apartmentProps: {
        value: "id",
        label: "text",
        children: "sub",
      },
      params: null,
      clickedDataItem: {},
      showGpsDialog: false,
      labelname: "",
      ensuredPeople: true,
      img4: require("../../assets/yx/4.png"),
      img3: require("../../assets/yx/3.png"),
      img7: require("../../assets/yx/7.png"),
      img8: require("../../assets/yx/8.png"),
      imgEmpty: require("../../assets/yx/empty.png"),
      img5: require("../../assets/yx/5.png"),
      markerList: null,
      loading: false,
      gpsDetail: [],
      newsignVisitTrackList: []
    };
  },
  methods: {
    showGpsDetail() {
      this.showGpsDialog = true;
      this.gpsDetail = [];
      this.gpsDetail = this.selectedone.clockTrackList;
    },
    changePeople(e) {
      if (e == this.params.managerId) {
        this.ensuredPeople = true;
      } else {
        this.ensuredPeople = false;
      }
      this.optionren.map((item) => {
        if (item.value == e) {
          this.labelname = item.label;
        }
      });
    },
    // 创建点
    makePoint(long, lat, info, mapObject, imgSrc, type) {
      var point = new BMap.Point(long, lat);

      var marker = new BMap.Marker(point, { icon: this.addMarker(imgSrc,type) }); // 创建标注
      mapObject.addOverlay(marker);
      var label = new BMap.Label(this.makeBubl(info.name), {
        // 创建文本标注
        position: point, // 设置标注的地理位置
        offset: new BMap.Size(-50, -60), // 设置标注的偏移量
      });
      label.setStyle({
        // 设置label的样式
        border: "2px solid #fff",
        display: "none",
      });
      marker.addEventListener("mouseover", function (e) {
        label.setStyle({ display: "block" });
      });
      marker.addEventListener("mouseout", function (e) {
        label.setStyle({ display: "none" });
      });
      mapObject.addOverlay(label);
    },
    addMarker(imgSrc,type){
        if(type===0||type===1){
          var myIcon = new BMap.Icon(imgSrc, new BMap.Size(34, 34), {    
    
              anchor: new BMap.Size(17, 17),    
            
              imageSize: new BMap.Size(34, 34)
          }); 
        }else{
          var myIcon = new BMap.Icon(imgSrc, new BMap.Size(24, 34), {    
                
              anchor: new BMap.Size(12, 30),    
            
              imageSize: new BMap.Size(24, 34)
          }); 
        }
        

        return myIcon
    },
    // 创建标注
    makeBubl(str) {
      let html =
        '<div style="width:150px;text-align:center;padding:0px 10px;white-space: pre-wrap;border:5px solid #171e39; position:relative;background-color:#171e39;color:#fff">' +
        '<span style="width:0; height:0; font-size:0; overflow:hidden; position:absolute;border-width:10px; border-style:solid dashed dashed; border-color:#171e39 transparent transparent;left:28px; bottom:-20px;"></span>' +
        str +
        "</div>";
      return html;
    },
    writepoint(data) {
      var that = this;
      data.forEach((item) => {
        var labeltext;
        let iconimg = "";
        if (item.type == 0) {
          labeltext = "签到";
          iconimg = that.img4;
        } else if (item.type == 1) {
          labeltext = "签退";
          iconimg = that.img3;
        } else if (item.type == 3) {
          labeltext = "拜访";
          iconimg = that.img7;
        } else if (item.type == 5) {
          labeltext = "报备";
          iconimg = that.img8;
        } else if (item.type == 4) {
          // 4是失联
          iconimg = that.imgEmpty;
        } else if (item.type != 4) {
          //停 TODO 写成后端给的type类型
          iconimg = that.img5;
        }
        that.makePoint(
          item.trackLongitude,
          item.trackLatitude,
          { name: "id:" + item.id },
          map,
          iconimg,
          item.type
        );
      });
    },
    writepath(data, id, color) {
      // 获取合理的中心点
      const centerPoint = map.getViewport(data);

      // 初始化地图，设置中心点坐标和地图级别
      map.centerAndZoom(centerPoint.center, centerPoint.zoom);

      var polyline = new BMap.Polyline(data, {
        strokeWeight: 12,
        strokeColor: color, // 线条颜色
        strokeStyle: color == "#ff4201" ? "dashed" : "solid",
        strokeOpacity: 0.8,
      });
      map.addOverlay(polyline);
    },
    handleChange(val) {
      this.departmentId = val.slice(-1)[0];
      this.queryemploy(null);
    },
    search() {
      map.clearOverlays();
      this.ensuredPeople = true;
      var that = this
      axiosApi(
        "/sales/clockTrack/web/selectDetails",
        {
          managerId: this.manage,
          trackDate: this.pathtimeday ? Date.parse(this.pathtimeday) : "",
        },
        (res) => {
          sessionStorage.setItem(
            "pathSearchParams",
            JSON.stringify({
              managerId: this.manage,
              trackDate: this.pathtimeday ? Date.parse(this.pathtimeday) : "",
              departmentId: this.departmentId
                ? this.departmentId
                : this.params.departmentId,
            })
          );
          this.params = JSON.parse(sessionStorage.getItem("pathSearchParams"));
       
          this.selectedone = res.data.data;
          if (res.data.data.clockTrackList.length > 0) {
            this.isSelected = true;
          } else {
            this.isSelected = false;
            map.centerAndZoom(new BMap.Point(120.169428, 30.344913), 14);
            this.$notify({
              type: "warning",
              message: "无轨迹信息",
            });
          }
          // 画线
          let ss = res.data.data.clockTrackList.filter((a) => {
            return a.addr !== "";
          });
          // ss.forEach((item) => {
          //   item.color = "#6cc44c";
          // });
          let datasource = ss.map((a) => {
            return new BMap.Point(a.trackLongitude, a.trackLatitude);
          });
          
          if(datasource.length!=0){
             that.writepath(datasource, that.manage, "#6cc44c");
          }

          // datasource.forEach(item=>{
           
          // })
          
          // for (let i = 0, len = datasource.length; i < len - 1; i++) {
          //   console.log(len,'datasource')
            
          // }


          //用签到点列表去做标记
          let newsignVisitTrackList = res.data.data.signVisitTrackList.filter(
            (a) => {
              return a.addr !== "";
            }
          );
          newsignVisitTrackList.forEach((item, index) => {
            item.typeText =
              item.type == 0
                ? "签到"
                : item.type == 1
                ? "签退"
                : item.type == 3
                ? "拜访"
                : item.type == 5
                ? "报备"
                : "异常";
            if (item.outLineException) {
              item.trackLongitude =
                newsignVisitTrackList[index - 1].trackLongitude;
              item.trackLatitude =
                newsignVisitTrackList[index - 1].trackLatitude;
              item.infoBody = item.outLineException;
            } else {
              item.infoBody = item.addr ? item.addr : "无详细位置";
            }
          });

          this.newsignVisitTrackList = newsignVisitTrackList
          that.writepoint(newsignVisitTrackList);
        }
      );
    },
    queryapart() {
      axiosApi(
        "/sales/clockTrack/web/departments",
        {
          traderId: sessionStorage.getItem("traderId"),
        },
        (res) => {
          var gg = res.data.data;
          this.options = res.data.data;
          var that = this;

          //获取部门绑定list
          var depId = Number(this.params.departmentId);

          var idarr = [];
          idarr.unshift(depId);
          function setdep(id) {
            var sour = res.data.data;

            function pushid(arr) {
              for (var i = 0; i < arr.length; i++) {
                if (arr[i].id == id) {
                  idarr.unshift(arr[i].parentId);
                  setdep(arr[i].parentId);
                  break;
                } else {
                  if (arr[i].sub) {
                    pushid(arr[i].sub);
                  } else {
                    continue;
                  }
                }
              }
            }

            pushid(sour);

            that.form.apartment = idarr.slice(1);
          }

          setdep(depId);

          function getId(st) {
            if (st !== null) {
              for (let i = 0; i < st.length; i++) {
                that.departmentIdList.push(st[i].id);
                getId(st[i].sub);
              }
            }
          }
          getId(gg);
          this.queryemploy();
        }
      );
    },
    queryemploy(x, y) {
      axiosApi(
        "/sales/clockTrack/web/managerCount",
        {
          departmentId: this.departmentId,
          departmentIdList: this.departmentIdList,
          resultType: 2,
        },
        (res) => {
          var employees = res.data.data.employeeList;
          var tracks = res.data.data.trackList;
          this.manage = Number(this.params.managerId);

          this.optionren = employees.map((a) => {
            return {
              value: a.managerId,
              label: a.employeeName,
            };
          });
          this.optionren.map((item) => {
            if (item.value == this.manage) {
              this.labelname = item.label;
            }
          });
        }
      );
    },
  },
  //进入加载
  mounted() {
    // this.loading = true
    this.params = JSON.parse(sessionStorage.getItem("pathSearchParams"));

    // 百度地图
    map = new BMap.Map("map", {
      coordsType: 3, // coordsType指定输入输出的坐标类型，3为gcj02坐标，5为bd0ll坐标，默认为5。
    });
    map.enableScrollWheelZoom(true);

    this.$nextTick(() => {
      this.queryapart();
      this.pathtimeday = new Date(Number(this.params.trackDate));

      let that = this;
      axiosApi(
        "/sales/clockTrack/web/selectDetails",
        {
          managerId: this.params.managerId,
          trackDate: that.pathtimeday ? Date.parse(that.pathtimeday) : "",
        },
        (res) => {
          that.selectedone = res.data.data;
          if (res.data.data.clockTrackList.length > 0) {
            that.isSelected = true;
            //画线
            let ss = res.data.data.clockTrackList.filter((a) => {
              return a.addr !== "";
            });
           
            let datasource = ss.map((a) => {
              return new BMap.Point(a.trackLongitude, a.trackLatitude);
            });

            if(datasource.length!=0){
              that.writepath(datasource, that.manage, "#6cc44c");
            }

            let newsignVisitTrackList = res.data.data.signVisitTrackList.filter(
              (a) => {
                return a.addr !== "";
              }
            );
            newsignVisitTrackList.forEach((item, index) => {
              item.typeText =
                item.type == 0
                  ? "签到"
                  : item.type == 1
                  ? "签退"
                  : item.type == 3
                  ? "拜访"
                  : item.type == 5
                  ? "报备"
                  : "异常";
              if (item.outLineException) {
                item.trackLongitude =
                  newsignVisitTrackList[index - 1].trackLongitude;
                item.trackLatitude =
                  newsignVisitTrackList[index - 1].trackLatitude;
                item.infoBody = item.outLineException;
              } else {
                item.infoBody = item.addr ? item.addr : "无详细位置";
              }
            });
            this.newsignVisitTrackList = newsignVisitTrackList
            that.writepoint(newsignVisitTrackList);
          } else {
            map.centerAndZoom(new BMap.Point(120.169428, 30.344913), 14);
            that.isSelected = false;
            that.$notify({
              type: "warning",
              message: "无轨迹信息",
            });
          }
        }
      );
    });
  },
};
</script>

<style lang="less">
#pathdetail {
  // 地图window窗口模块
  .amap-info-content {
    padding: 0;
    div.info-top {
      position: relative;
      background: none repeat scroll 0 0 #f9f9f9;
      border-bottom: 1px solid #ccc;
      border-radius: 5px 5px 0 0;
    }
    div.info-top div {
      display: inline-block;
      color: #333333;
      font-size: 14px;
      font-weight: bold;
      line-height: 31px;
      padding: 0 10px;
    }

    div.info-middle {
      font-size: 12px;
      padding: 6px;
      line-height: 20px;
    }
  }
  // 页面弹窗
  .el-dialog {
    width: 850px;
    .el-table {
      margin-bottom: 30px;
    }
  }
  .right-btn {
    width: 110px;
    button {
      margin-left: 0;
      width: 100px;
      &:nth-child(1) {
        margin-bottom: 20px;
      }
    }
  }

  .status {
    margin: 0px 30px;
    height: 20px;
    & > div {
      float: right;
      margin-left: 20px;
      i {
        margin-right: 10px;
        &.el-icon-information {
          color: #d81e06;
        }
        &.el-icon-circle-check {
          color: #1cfa2b;
        }
        &.boss {
          width: 14px;
          height: 14px;
          border-radius: 7px;
          display: inline-block;
          vertical-align: middle;
          box-sizing: border-box;
          border: 1px solid #c6c6c6;
          position: relative;
          &:before {
            content: "";
            display: block;
            position: absolute;
            width: 8px;
            height: 1px;
            background: #c6c6c6;
            left: 2px;
            top: 6px;
          }
        }
      }
    }
  }
  .mapbox {
    height: 500px;
    margin: 0 30px;
    margin-top: 20px;
    box-sizing: border-box;
    position: relative;
    #map {
      position: absolute !important;
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      &.clicked {
        right: 270px;
      }
    }
    #mapinfo {
      overflow-y: hidden;
      position: absolute;
      box-shadow: 0px 0px 5px #ccc;
      top: 0px;
      right: 0px;
      bottom: 0px;
      display: none;
      &.clicked {
        display: block;
        width: 260px;
      }
      .top {
        background: #03a6e8;
        color: #fff;
        box-sizing: border-box;
        padding: 10px;
        .fir {
          i {
            float: left;
            width: 20px;
            height: 20px;
            border: 1px solid #fff;
            border-radius: 50%;
            position: relative;
            margin-right: 5px;
            margin-top: 5px;
            &:before {
              position: absolute;
              left: 4px;
              top: 2px;
            }
          }
          & > div {
            line-height: 30px;
          }
        }
        .sec {
          margin-top: 10px;
          font-size: 12px;
          padding-left: 26px;
          span {
            &:first-child {
              color: #ff4201;
              margin-right: 10px;
            }
          }
        }
      }
      #infobody {
        width: 100%;
        box-sizing: border-box;
        overflow-y: auto;
        position: absolute;
        right: 0;
        top: 50px;
        bottom: 0;
        padding: 0 20px;
        &.top-more {
          top: 77px;
        }
      }
    }
  }
}
</style>