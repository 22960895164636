/*
 * 该js文件是针对密码强弱的一个判断
 */

/*
 * 检测密码强度
 */
function checkStrong(sValue) {
	var modes = 0;
	if(sValue.length < 6) return false;
	if(/\d/.test(sValue)) modes++; //数字
	if(/[a-z]/.test(sValue)) modes++; //小写
	if(/[A-Z]/.test(sValue)) modes++; //大写  
	if(/\W/.test(sValue)) modes++; //特殊字符
	switch(modes) {
		case 1:
			return 1;
			break;
		case 2:
			return 2;
		case 3:
		case 4:
			return sValue.length < 12 ? 3 : 4
			break;
	}
}
//强度处理事件
export const passcheck = (val) => {
	//	console.log("检测密码强度")
	let oTips = document.getElementById("tips");
	let aSpan = oTips.getElementsByTagName("span");
	let aStr = ["弱", "中", "强", "非常好"];
	let index = checkStrong(val)
	if(index) {
		oTips.className = "s" + index;
		for(let i = 0; i < aSpan.length; i++) {
			aSpan[i].className = aSpan[i].innerHTML = "";
			aSpan[index - 1].className = "active"
			aSpan[index - 1].innerHTML = aStr[index - 1]
		}
	}
}

/*
 * 检测大小写键是否打开
 */
let flag = true
let bool = false
let fo = false
export const capsisopen = (e, val) => {
	let reg = /^[A-Z]+$/
	if(reg.test(val.substr(val.length - 1)) && flag) { //大写
		flag = false
		bool = true
		return bool
	}
	if (e.keyCode == 20 && fo && val.length == 0) {//从设置密码切换到确认密码，不输入任何值按大写键
		bool = !bool
	}
	if(e.keyCode == 20 && val.length > 0) { //不输入任何值时按大小写有bug，故加上length>0
		bool = !bool
	}
	return bool
}
export const passfocus = () => {
	if(bool) {
		fo = true
		return true //对于设置密码时已经知道大小写键是否开启，判断确认密码的大写已开启是否提示。反过来也一样
	}
}