import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import ticket from './modules/ticket.js'  //优惠券模块
import classifylist from './modules/classifylist.js'  //请求商品分类列表数据
import clientlist from './modules/clientlist'  //请求客户分类列表数据
import activity from './modules/activity'
import homeSearch from './modules/homeindex.js'
import purchaseOrder from './modules/purchaseOrder'
import salesMan from "./modules/salesMan.js"
import address from "./modules/address.js"
import shl from "./modules/state.js"
import paymodule from "./modules/paymodule.js"
import channelLevel from "./modules/channelLevel.js"//客户渠道、等级
import clientBrand from "./modules/clientBrand.js"//客户品牌维护
import setPrice from "./modules/setPrice.js"// 套餐设价
import hotelEdit from "./modules/hotelEdit.js";// 酒店折扣维护编辑
import goodsAdd from "./modules/goodsAdd.js";
import marketActivity from "./modules/marketActivity.js";
import sendGoodsManager from './modules/sendGoodsManager'
import giftEvent from "./modules/giftEvent.js";// 满赠活动
import clientType from "./modules/clientType.js";// 用户类型
import exportTaskList from "./modules/exportTaskList.js";// 用户类型
import nav from "./modules/nav.js";// 我的界面导航信息
import help from "./modules/help.js"

Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        nav:nav,
        sendGoodsManager:sendGoodsManager,
        marketActivity:marketActivity,
        goodsAdd:goodsAdd,
        ticket: ticket,
        classifylist: classifylist,
        clientlist: clientlist,
        activity: activity,
        homeSearch: homeSearch,
        purchaseOrder: purchaseOrder,
        salesMan:salesMan,
        address:address,
        shl:shl,
        paymodule:paymodule,
        channelLevel:channelLevel,
        clientBrand: clientBrand,
        setPrice: setPrice,
        hotelEdit: hotelEdit,
        giftEvent: giftEvent,
        clientType,
        exportTaskList,
        help
    },
    plugins: [
        createPersistedState({
            storage: window.sessionStorage,
            reducer(val){
                return {
                    exportTaskList:val.exportTaskList,
                    clientType:val.clientType
                }
            }
        })
    ]
})

export default store



