<template>
	<div id="top">
		<div class="logo">
			<div class="width-1280">
				<el-row type="flex" justify="space-between">
					<el-col :span="8">
						<router-link to="/customer/shop/shopIndex">
							<img class="cursor" src="../assets/logo-w.png" />
						</router-link>
					</el-col>
					<div class="mt-15">
						<el-input class="fl" v-model.trim="goodsName" @keydown.enter.native='goodsSearch' placeholder="请输入关键词搜索"></el-input>
						<el-button class="fl" @click="goodsSearch">搜 索</el-button>
					</div>
				</el-row>
			</div>
		</div>
		<div class="plr-45 index-attention-wrap" v-if="showThisTitle == false ? false : curShow == false ? false : true">
			<div class="index-attention"><div class="flex-center"><img src="../assets/attention.png"/>系统正在持续优化中，敬请期待！</div>
				<div @click="hideThisTitle" class="font-16 mr-30 color-9"><i class="el-icon-circle-close"></i></div>
			</div>
		</div>
		<div class="clearfix width-1280 plr-45 pt-40">
			<div class="main-left fl ml22">
				<nava></nava>
			</div>
			<div class="main-right fl pb-20 mb-50">
				<!-- <router-view></router-view> -->
				<keep-alive>
					<router-view v-if="$route.meta.keepAlive"></router-view>
				</keep-alive>
				<router-view v-if="!$route.meta.keepAlive"></router-view>
			</div>
		</div>
	</div>
</template>

<script>
	import Nava from "./nava.vue";
	import RightMenu from "./rightMenu.vue";
	import { basePath } from "../api/api";
	export default {
		components: {
			Nava,
			RightMenu
		},
		data() {
			return {
				goodsName: '',
				curShow: true
			}
		},
		computed: {
			showThisTitle() {
				if (this.$cookies.get('showTitle') && this.$cookies.get('showTitle').indexOf('false') > -1 && this.$cookies.get('showTitle').indexOf( this.$cookies.get('userName') ) > -1) {
					return false
				} else {
					return true
				}
			}
		},
		mounted() {
			this.changeHeight()
		},
		methods: {
			hideThisTitle() {
				this.curShow = false;
				let aaa = this.$cookies.get('userName') + 'false'
				this.$cookies.set(
					"showTitle",
					aaa,
					null,
					"/"
				);
			},
			changeHeight() {
				var height = document.documentElement.clientHeight;
				document.getElementsByClassName("main-right")[0].style.minHeight = height - 141 - 50 + "px";
			},
			goodsSearch(){//搜索商品
				this.goodsName = this.goodsName.trim();//去除首位空格
				let goodsName = (this.goodsName === null || this.goodsName === "") ? 'goodsName' : this.goodsName;
				this.$router.push(basePath+'/goodsSearch/classify/brand/' + goodsName );
			}
		}
	}
</script>
<style lang="less">
	.index-attention-wrap {
		width: 1180px;
		color: #F86E21;
		height: 36px;
		line-height: 36px;
		margin: 10px auto 0px;
		.index-attention {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding-left: 50px;
			background: #FEFCEC;
			border: 1px solid #FADCD3;
			.flex-center {
				display: flex;
				align-items: center;
				img {
					margin-right: 10px;
					width: 16px;
					height: 16px;
				}
			}
		}
	}
	
	.main-left{
		width: 130px;
		margin-bottom: 50px;
	}
	.main-left.ml22{
		margin-left: 22px;
	}
	.main-right{
		width: 1030px;
		padding-bottom: 50px;
		float: right;
		margin-right: 5px;
		box-sizing: border-box;
	}

	#top header {
		background-color: #f5f5f5;
		height: 36px;
		line-height: 36px;
		color: #666;
		font-size: 12px;
	}
	
	#top .logo {
		height: 60px;
		line-height: 60px;
		background-color: #ff4201;
	}
	
	#top .logo .el-col-8 img {
		width: 190px;
		height: 39px;
		vertical-align: middle;
		/*margin-top: 24px;*/
	}
	
	/*#top .logo .el-col-8>span {
		display: inline-block;
		width: 1px;
		height: 30px;
		background-color: #fff;
		margin: 0px 10px;
	}*/
	
	#top .logo .platform {
		display: inline-block;
		color: #fff;
		vertical-align: top;
		font-size: 24px;
	}
	
	#top .logo .el-button {
		width: 80px;
		height: 30px;
		font-size: 16px;
		background-color: #F5F5F5;
		border: 0;
		border-radius: 0;
	}
	
	#top .logo .el-input__inner,
	#top .logo .el-input {
		width: 270px;
		height: 30px !important;
	}
</style>