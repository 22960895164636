<template>
	<div id="content">
		<div class="title-tabs bg-f">
			<ul class="clearfix">
				<template v-for='(tab,index) in dataTabs' class="item">
					<!-- <el-badge :value="0" class="item"> -->
						<li :key="index+'aj'" :type='index' :class="type==index?'active':''" @click='handleTabs'>
							{{tab}}
							<div :class="type==index?'under-line':''"></div>
						</li>
					<!-- </el-badge> -->
					<div class="vertical-line" :key="index+'ak'"></div>
				</template>
			</ul>
		</div>

		<div class="mt-5 bg-f pb-50">
			<MerchantNotification v-if="type==0"/>
			<EventsNotification v-else-if="type==1"/>
			<OrderNotification v-else-if="type==2"/>
		</div>

	</div>
</template>

<script>
	import { axiosApi, basePath } from "../../api/api";
	import { mapMutations } from "vuex"
	import MerchantNotification from "./MessageCenterItem/MerchantNotification.vue";
	import EventsNotification from "./MessageCenterItem/EventsNotification.vue";
	import OrderNotification from "./MessageCenterItem/OrderNotification.vue";

	export default {
		components:{
			MerchantNotification,
			EventsNotification,
			OrderNotification
		},
		data() {
			return {
				dataTabs: ['商户通知','活动通知','订单通知'],
				type: 0
			};
		},
		activated() {
			if(this.$route.query.type){
				this.type = this.$route.query.type;
			}
		},
		methods: {
			
			handleTabs(e) {
				if(e.target.attributes.type) this.type = e.target.attributes.type.value;

				if (this.type==0) {
					// 公告通知
				}else if(this.type == 1){
					// 系统更新
				}else if(this.type==2){
					// 商户信息
				}
				return
			}
		}
	};
</script>

<style lang="less">
	#content {
		width: 100%;
		.el-tabs__header{
			padding-left: 10px;
		}
	}
	.el-badge__content .is-fixed{
		right: 2px !important;
	}
</style>
