<template>
  <!--订单明细-->
  <div id="salesInCarOrderInfo">
    <div class="content bg-f pl-30 pr-30 pb-40">
      <div class="clearfix search">
        <el-form :inline="true" class="pt-30 fl" :model="form" ref="searchForm">
          <el-form-item label="下单时间">
            <ys-daterange-picker v-model="form.orderDateRange" :endDisabledDate="new Date()" />
          </el-form-item>
          <el-form-item label="客户帐号" class="ml-20">
            <el-input v-model.trim="form.buyerLoginName" :maxlength="30" placeholder="请输入客户帐号"></el-input>
          </el-form-item>
          <el-form-item label="客户名称">
            <el-input v-model.trim="form.buyerName" :maxlength="30" placeholder="请输入客户名称"></el-input>
          </el-form-item>
          <el-form-item label="结算状态" class="ml-20">
            <el-select v-model="form.isSettlement" placeholder="请选择结算状态" clearable>
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="支付方式">
            <el-select v-model="form.payType" placeholder="请选择支付方式" clearable>
              <el-option
                v-for="item in payMethodList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <el-button class="fl ml-30 mt-30" type="info" @click="handleCheck">查询</el-button>
      </div>
      <div class="tar clearfix">
        <el-button type="text" @click="exportExcel">批量导出</el-button>
        <el-button style="width: 100px;" type="text" @click="closeAllAccount">批量结算</el-button>
      </div>
      <div class="order-list" v-loading="loading">
        <el-checkbox v-model="checkAll" @change="handleCheckAllChange" class="checkedAll"></el-checkbox>
        <el-table>
          <el-table-column label width="50"></el-table-column>
          <el-table-column label="商品信息"  align="center"></el-table-column>
          <el-table-column label="单价"  ></el-table-column>
          <el-table-column label="数量" ></el-table-column>
          <el-table-column label="买家名称"  ></el-table-column>
          <el-table-column label="订单金额"></el-table-column>
          <el-table-column label="小组" ></el-table-column>
          <!--<el-table-column label="佣金" width="60"></el-table-column>-->
          <el-table-column label="结算状态"></el-table-column>
          <el-table-column label="操作"></el-table-column>
        </el-table>
        <div>
          <el-checkbox-group v-model="checkOrder" @change="handleCheckChange">
            <div class="table-item mt-10" v-for="(order, index) in ordersListResVo" >
              <div class="clearfix table-item-top lh-20 bbd bg-e">
                <el-checkbox
                  class="fl"
                  :label="order"
                  :disabled="order.isSettlement == 1?true:false"
                ></el-checkbox>
                <span class="fl">订单编号：{{order.orderSn}}</span>
                <span class="fl color-6 ml-30">下单时间：{{order.gmtCreate | time}}</span>
              </div>
              <div class="table-item-content clearfix bbd" style="display: flex;padding-left: 50px">
                <div class="clearfix" style="flex: 3; box-sizing: border-box;width: 0">
                  <!--商品清单-->
                  <div
                    class="bb clearfix"
                    v-for="(orderItem, index) in order.orderItemPos"
                    :key="orderItem.id"
                    style="display: flex; padding: 20px 0 20px 20px;"
                  >
                    <div class="fl specialD" style="flex: 1;width: 0;margin-left: -50px">
                      <img v-if="orderItem.skuImage" :src="orderItem.skuImage" class="fl mr-20" />
                      <img v-else src="../../assets/goods.jpg" class="fl mr-20" />
                      <img
                        v-if="order.storeType=='2'"
                        class="special"
                        src="../../assets/special.png"
                        alt
                      />
                      <div class="row-item-1">
                        <!-- <p class="bw">{{orderItem.skuNo}}</p> -->
                        <p class="bw">{{orderItem.skuName}}</p>
                        <p class="color-9">规格:1*{{orderItem.changeCoefficient}}</p>
                      </div>
                    </div>
                    <div
                      class="fl tac"
                      style="flex: 1;width: 0"
                    >¥{{orderItem.discountSpecPrice | fmoney}}/{{orderItem.unit}}</div>
                    <div
                      class="fl tac"
                      style="flex: 1;width: 0"
                    >{{orderItem.specNum}}{{orderItem.unit}}</div>
                  </div>
                    <!--赠品清单-->
                    <div
                            class="bb clearfix"
                            v-for="(orderItem, index) in order.orderGiftResVos"
                            :key="index"
                            style="display: flex; padding: 20px 0 20px 20px;"
                    >
                        <div class="fl specialD" style="flex: 1;width: 0;margin-left: -50px">
                            <img v-if="orderItem.skuImage" :src="orderItem.skuImage" class="fl mr-20" />
                            <img v-else src="../../assets/goods.jpg" class="fl mr-20" />
                            <img src="../../assets/superGroup/complimentary.png" class="giftLogo" alt="">
                            <div class="row-item-1">
                                <!-- <p class="bw">{{orderItem.skuNo}}</p> -->
                                <p class="bw">{{orderItem.skuName}}</p>
                                <p class="color-9">规格:1*{{orderItem.changeCoefficient}}</p>
                            </div>
                        </div>
                        <div
                                class="fl tac"
                                style="flex: 1;width: 0"
                        >¥{{orderItem.discountSpecPrice | fmoney}}/{{orderItem.sellingUnitName}}</div>
                        <div
                                class="fl tac"
                                style="flex: 1;width: 0"
                        >{{orderItem.giftNum}}{{orderItem.sellingUnitName}}</div>
                    </div>
                </div>
                <div class="bl bb tac pt-20" style="flex: 1;word-break: break-all;width: 0">
                  {{order.buyerLoginName}}
                  <br />
                  {{order.buyerName}}
                </div>
                <div
                  class="bl bb tac pt-20"
                  style="flex: 1;word-break: break-all;width: 0"
                >¥{{order.needPayMoney | fmoney}}</div>
                <div
                  class="bl bb tac pt-20"
                  style="flex: 1;word-break: break-all;width: 0"
                >{{order.managerName ? order.teamName: '--'}}</div>
                <!--<div-->
                  <!--class="bl bb tac pt-20"-->
                  <!--style="width:63px;word-break: break-all"-->
                <!--&gt;¥{{order.teamCommission | fmoney}}</div>-->
                <div class="bl bb tac pt-20" style="flex: 1;width: 0">
                  <span v-if="order.isSettlement==0">未结</span>
                  <span v-if="order.isSettlement==1">已结</span>
                </div>
                <div class="bl bb tac myBtn" :style="{paddingTop : '15px'}" style="flex: 1;width: 0">
                  <div>
                    <el-button
                      type="text"
                      v-if="order.isSettlement==0"
                      @click="closeAnAccount(order)"
                    >结算</el-button>
                    <el-button type="text" class="ml-0" @click="orderDetail(order)">查看详情</el-button>
                  </div>
                </div>
              </div>
            </div>
          </el-checkbox-group>
        </div>
        <el-pagination
          v-if="total>10"
          class="tar mt-20"
          :current-page.sync="currentPage"
          :page-size="10"
          :total="total"
          layout="prev , pager, next, jumper"
          @current-change="handleCurrentChange"
        >
          <!-- 分页组件 -->
        </el-pagination>
      </div>
    </div>
    <!--导出-->
    <form ref="exportList" action="export/sic/order/list" style="display: none">
      <input type="text" name="startRow" v-model="a" />
      <input type="text" name="startCol" v-model="a" />
      <input type="text" v-if="formExport.orderSn" name="orderSn" v-model="formExport.orderSn" />
      <input type="text" v-if="formExport.skuName" name="skuName" v-model="formExport.skuName" />
      <input
        type="text"
        v-if="formExport.orderStatus"
        name="orderStatus"
        v-model="formExport.orderStatus"
      />
      <input
        type="text"
        v-if="formExport.supercStoreId"
        name="supercStoreId"
        v-model="formExport.supercStoreId"
      />
      <input
        type="text"
        v-if="formExport.queryBeginDate"
        name="startTime"
        v-model="formExport.queryBeginDate"
      />
      <input type="text" v-if="formExport.queryEndDate" name="endTime" v-model="formExport.queryEndDate" />
      <input type="text" v-if="formExport.payMethod" name="payMethod" v-model="form.payMethod" />
      <input type="text" v-if="form.sellerId" name="sellerId" v-model="form.sellerId" />
      <input type="text" v-if="idStr.length" name="idStr" v-model="idStr" />
      <input type="text" name="fileName" v-model="fileName" />
      <input type="text" name="modelName" v-model="modelName" />
    </form>
  </div>
</template>

<script>
import { axiosApi, basePath } from "../../api/api";
import { mapMutations } from "vuex";
import _ from "underscore";
// import YsDaterangePicker from "~/components/ys-daterange-picker";

export default {
  components: {},
  data() {
    return {
      type: "0",
      data: ["1"],
      reason: null,
      ordersListResVo: [], //订单数据
      orderSn: null, //订单编号
      superCList: [], //久集账号列表
      idStr: [], //批量导出选中列表
      fileName: null, //导出名称
      a: 0, //固定值 写死
      modelName: "superc.hotelSuperOrder",
      flag: false,
      options: [
        { value: null, label: "全部" },
        { value: 0, label: "未结" },
        { value: 1, label: "已结" }
      ],
      payMethodList: [
        { value: null, label: "全部" },
        { value: "cash", label: "现金" },
        { value: "account", label: "赊销" }
      ],
      cloneForm: {},
      form: {
        orderDateRange: [],
        queryBeginDate: this.$route.query.queryBeginDate,
        queryEndDate: this.$route.query.queryEndDate,
        sellerId: sessionStorage.traderId,
        buyerLoginName: null, //客户帐号
        managerLoginName: this.$route.query.managerLoginName,
        buyerName: null, //客户名称
        isSettlement: null, //是否已结算0否1是
        payType: "" //支付类型cash现金,account赊销
      },
      formExport: {
        orderSn: null, //订单编号
        skuName: null, //商品名称
        orderStatus: null, //订单状态
        supercStoreId: null, //销售员id
          queryBeginDate: null,
          queryEndDate: null,
        payMethod: null // 支付方式
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > new Date();
        }
      }, //禁选未来时间
      rules: {
        cancleReason: [
          {
            max: 30,
            message: "不能超过30个字！"
          }
        ]
      },
      clearTime: 1, //定时器
      loading: false,
      currentPage: 1,
      total: null,
      checkAll: false, //选中状态
      checkOrder: [], //选中的每行订单数据
      sendGoods: false, //发货弹框
      expressList: [], //物流列表
      divideOrderVisible: false, //拆单dialog
      cancelOrCheck: 0, //默认取消订单为0， 审核凭证为1
      parentOrderSn: null, //主订单parentOrderSn
      rowSupercStoreId: null, //点击的item的supercsotreid,
      showAllsn: false,
      showSnIndex: null
    };
  },
  watch: {
    "form.buyerLoginName": {
      handler: function() {
        if (this.form.buyerLoginName != null) {
          this.form.buyerLoginName = this.form.buyerLoginName.replace(
            /[\ud800-\udbff][\udc00-\udfff]/g,
            ""
          );
        }
      }
    },
    "form.buyerName": {
      handler: function() {
        if (this.form.buyerName != null) {
          this.form.buyerName = this.form.buyerName.replace(
            /[\ud800-\udbff][\udc00-\udfff]/g,
            ""
          );
        }
      }
    },
    form: {
      handler: function() {
        this.flag = false;
      },
      deep: true
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.form.orderDateRange = [
        this.$route.query.queryBeginDate,
        this.$route.query.queryEndDate
      ];
      this.queryOrderList(this.form); //默认加载订单
    });
  },
  methods: {
    ...mapMutations({
      setTask: "setTask"
    }),
    ValetLoginName() {},
    exportExcel() {
        if (this.ordersListResVo.length == 0){
            this.$message('暂无数据导出！')
            return false
        }
      let link = `${window.location.protocol}//${
        document.domain
      }/sunrise-gateway/export/sic/order/list?modelName=sic.order&fileName=订单列表.xls${
        this.form.queryBeginDate != null
          ? `&queryBeginDate=${this.form.queryBeginDate}`
          : ""
      }${
        this.form.queryEndDate != null
          ? `&queryEndDate=${this.form.queryEndDate}`
          : ""
      }${
        this.form.buyerLoginName != null
          ? `&buyerLoginName=${this.form.buyerLoginName}`
          : ""
      }${
        this.form.managerLoginName != null
          ? `&managerLoginName=${this.form.managerLoginName}`
          : ""
      }${
        this.form.buyerName != null ? `&buyerName=${this.form.buyerName}` : ""
      }${this.form.payType != null ? `&payType=${this.form.payType}` : ""}${
        this.form.isSettlement != null
          ? `&isSettlement=${this.form.isSettlement}`
          : ""
      }&sellerId=${sessionStorage.traderId}`;
      window.open(link);
    },
    handleCurrentChange(val) {
      //分页
      this.currentPage = val;
      this.queryOrderList(this.form);
    },
    handleCheck() {
      ///订单条件查询
      this.form.queryBeginDate =
        this.form.orderDateRange && this.form.orderDateRange[0]
          ? this.form.orderDateRange[0].getTime()
          : null;
      this.form.queryEndDate =
        this.form.orderDateRange && this.form.orderDateRange[1]
          ? this.form.orderDateRange[1].getTime()
          : null;
      this.cloneForm = JSON.parse(JSON.stringify(this.form));
      setTimeout(() => {
        this.flag = true;
      }, 0);
      //订单编号表单验证
      this.$refs.searchForm.validate(valid => {
        if (valid) {
          if (this.currentPage != 1) {
            this.currentPage = 1;
            return;
          }
          this.queryOrderList(this.form);
        }
      });
    },
    queryOrderList(body) {
      /*久集订单列表*/
      this.loading = true;
      axiosApi(
        "/order/sic/web/page",
        body,
        res => {
          this.loading = false;
          this.total = res.data.data.totalCount;
          this.ordersListResVo = res.data.data.elements;
          /*定时器显示*/
          this.ordersListResVo.forEach((item, index) => {
            if (
              item.remainAutoCancelTime != null &&
              item.remainAutoCancelTime > 0
            ) {
              let time = parseInt(item.remainAutoCancelTime / 1000); //转化为整数
              item.remainAutoCancelTime = this.timeString(time); //转化为时间格式
              item.clearTime = null; //定义变量
              item.clearTime = setInterval(() => {
                time--;
                item.remainAutoCancelTime = this.timeString(time);
                if (time <= 0) {
                  clearTimeout(item.clearTime);
                  //this.cancelOrder({orderSn:item.sn,cancleType:3})  //系统取消
                  //									if(item.orderStatus == 1) item.orderStatus = 0
                  this.$set(this.ordersListResVo, index, item);
                }
              }, 1000);
            } else {
              //							if(item.orderStatus == 1) item.orderStatus = 0
              this.$set(this.ordersListResVo, index, item);
            }
          });
          //导出参数处理
          this.formExport.orderSn = this.form.orderSn;
          this.formExport.skuName = this.form.skuName;
          this.formExport.orderStatus = this.form.orderStatus;
          this.formExport.supercStoreId = this.form.supercStoreId;
          this.formExport.startTime = this.form.startTime;
          this.formExport.endTime = this.form.endTime;
          this.formExport.payMethod = this.form.payMethod;

          // this.anonOrderCount();
        },
        this.currentPage,
        10,
        res => {
          this.loading = false;
          this.$message({
            type: "error",
            message: res.data.message,
            duration: 1000
          });
        }
      );
    },
    closeAnAccount(order) {
      //结算
      this.$msgbox({
        title: "提示信息",
        message: "确定将该笔订单变为已结算吗？",
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            let ids = [order.id];
            ids.map(String);
            this.closeAccountFunc(ids);
            done();
          } else {
            done();
          }
        }
      });
    },
    closeAllAccount() {
      if (this.idStr.length > 0) {
        this.$msgbox({
          title: "提示信息",
          message: "确定将选中的订单变为已结算吗？",
          showCancelButton: true,
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          beforeClose: (action, instance, done) => {
            if (action === "confirm") {
              this.closeAccountFunc(this.idStr);
              done();
            } else {
              done();
            }
          }
        });
      } else {
        this.$message({
          type: "error",
          message: "请先选中订单",
          duration: 1000
        });
      }
    },
    closeAccountFunc(ids) {
      // 结算
      console.log(ids);
      axiosApi(
        "/order/sic/settlement",
        {
          idList: ids
        },
        res => {
          this.$message({
            type: "success",
            message: "结算成功",
            duration: 1000
          });
          this.queryOrderList(this.form);
        },
        1,
        10,
        res => {
          this.$message({
            type: "error",
            message: res.data.message,
            duration: 1000
          });
        }
      );
    },
    orderDetail(order) {
      //去订单详情页面
      sessionStorage.setItem("orderSn", order.orderSn);
      window.open(
        basePath + "/salesInCarOrderDetail?id=" + order.id,
        "_blank"
      );
    },
    handleCheckAllChange(val) {
      /*table全选*/
      if (this.checkAll) {
        this.ordersListResVo.forEach(item => {
          if (item.isSettlement == 0) {
            this.checkOrder.push(item);
            this.idStr.push(item.id);
          }
        });
      } else {
        this.checkOrder = [];
        this.idStr = [];
      }
    },

    handleCheckChange(val) {
      //单选
      let checkedCount = val.length;
      this.checkAll = checkedCount === this.ordersListResVo.length;
      this.idStr = [];
      val.forEach(item => {
        this.idStr.push(item.id);
      });
    },
    changeOrderDialogStatusFun(val) {
      this.divideOrderVisible = val;
    }
  }
};
</script>

<style lang="less">
#salesInCarOrderInfo {
  position: relative;
  box-sizing: border-box;
  .myBtn {
    .el-button {
      width: 70px;
    }
  }
  .el-tabs__header {
    padding-top: 10px;
    padding-left: 0;
  }
  .el-checkbox__label {
    display: none;
  }
  .notPayCountmark {
    position: absolute;
    left: 144px;
    top: 10px;
  }
  .notDeliveryCount {
    position: absolute;
    left: 218px;
    top: 10px;
  }
  .orderDeliveriedCount {
    position: absolute;
    left: 294px;
    top: 10px;
  }
  .hide {
    display: none;
  }
  .content {
    .el-checkbox {
      width: 40px !important;
    }
    .el-date-editor--daterange.el-input {
      width: 240px;
    }
    .el-form--inline {
      width: 650px;
      .el-form-item__label {
        font-size: 12px;
        width: 60px;
      }
    }
    .el-table .el-table__body-wrapper {
      display: none;
    }
    .order-datatimerange {
      width: 240px;
    }
    .el-table {
      border: none;
      font-size: 14px;
      th > .cell {
        line-height: 12px;
        padding: 10px 0 10px 0;
        text-align: center;
      }
      th.is-leaf {
        border-bottom: 0;
      }
    }
    .checkedAll {
      position: relative;
      top: 30px;
    }
    .el-checkbox {
      width: 70px;
      margin-left: 30px;
      .el-checkbox__input {

      }
    }
    .el-checkbox__inner {

    }
    .el-checkbox__inner::after {
      top: 2px;
    }
    .table-item {
      .bl {
        border-left: 1px solid #ddd;
      }
      border: 1px solid #dddddd;
      font-size: 14px;
      border-bottom: none;
      .table-item-content .pbl-20 {
        padding: 0px 0px 20px 20px;
      }
      .table-item-top {
        padding: 10px 0;
        position: relative;
        .table-item-top-checkbox {
          margin-left: 14px;
        }
        .all_sn_ct {
          background: #eee;
          position: absolute;
          left: 70px;
          top: 30px;
          padding: 10px 5px;
          border: 1px solid #ddd;
          z-index: 1;
        }
      }
      img {
        width: 80px;
        height: 80px;
      }
    }
    .w100 {
      width: 100px;
    }
    .table-item-default {
      height: 530px;
      padding-top: 120px;
    }
    .el-dialog {
      min-height: 300px;
      .el-form-item__label {
        width: 140px;
      }
    }
    .sendGoods .el-form-item__error {
      margin-left: 140px;
    }
  }
  .ml-70 {
    margin-left: 70px;
  }
  .my-badge {
    .el-badge__content {
      border: 0;
    }
  }
  .color-blue {
    color: #20a0ff;
    cursor: pointer;
  }
  .specialD {
    position: relative;
    display: flex;
    justify-content: space-between;
      .giftLogo{
          position: absolute;
          left: 0;
          top:0;
      }
    .row-item-1 {
      width: 188px;
      height: auto;
      .bw {
        word-wrap: break-word;
      }
    }
    .row-item-2 {
      width: 0;
        flex: 1;
      height: auto;
      .bw {
        word-wrap: break-word;
      }
    }
    .special {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}
</style>