<template>
	<div id="nava">
	  <el-menu :default-active="$route.path" :default-openeds="openMenuList" router @open="menuOpen" @close="menuClose" @select="selectMenu" class="el-menu-vertical">
			<el-submenu v-for="(data,index1) in datas" v-if="data.sub && data.sub.length > 0  && data.id !== 200000 && data.id != 210163" :index='data.text' :key="index1">
				<template slot="title" v-if="data.id !== 200000 && data.id != 210163"><i class="el-icon-caret-right"></i>{{data.text}}</template>

				<!-- 酒店久集加了2个分组 -->
				<el-menu-item-group v-if="data.code === 'IS_OPEN_HOTEL_SUPER_C' && data.sub1 && data.sub1.length && data.sub[0].groupId != null">
					<template slot="title">日常管理</template>
					<el-menu-item v-for="(item,index2) in data.sub1" :index="basePath + item.url" :key="index1 +'-'+index2">
						&nbsp; {{item.text}}
					</el-menu-item>
				</el-menu-item-group>

				<el-menu-item-group v-if="data.code === 'IS_OPEN_HOTEL_SUPER_C' && data.sub2 && data.sub2.length && data.sub[0].groupId != null">
					<template slot="title">基础设置</template>
					<el-menu-item v-for="(item,index2) in data.sub2" :index="basePath + item.url" :key="index1 +'-'+index2">
						&nbsp; {{item.text}}
					</el-menu-item>
				</el-menu-item-group>

				<!-- 门店久集加了2个分组 -->
				<el-menu-item-group v-if="data.code === 'IS_OPEN_TENANTSHOP_SUPERC' && data.sub3 && data.sub3.length && data.sub[0].groupId != null">
					<template slot="title">日常管理</template>
					<el-menu-item v-for="(item,index2) in data.sub3" :index="basePath + item.url" :key="index1 +'-'+index2">
						&nbsp; {{item.text}}
					</el-menu-item>
				</el-menu-item-group>

				<el-menu-item-group v-if="data.code === 'IS_OPEN_TENANTSHOP_SUPERC' && data.sub4 && data.sub4.length && data.sub[0].groupId != null">
					<template slot="title">基础设置</template>
					<el-menu-item v-for="(item,index2) in data.sub4" :index="basePath + item.url" :key="index1 +'-'+index2">
						&nbsp; {{item.text}}
					</el-menu-item>
				</el-menu-item-group>

				<el-menu-item v-else-if="data.sub[0].groupId == null" v-for="(item,index2) in data.sub" :index="basePath + item.url" :key="index1 +'-'+index2">
					&nbsp; {{item.text}}
				</el-menu-item>

			</el-submenu>
			<!-- 云商-->
			<el-menu-item v-else-if="data.id !== 200000 && data.id != 210163" :index="basePath + data.url">{{data.text}}</el-menu-item>
		</el-menu>
	</div>
</template>
<script>
	import { axiosApi, basePath } from '../api/api'
	import { mapMutations, mapActions } from "vuex"
  import { getUnReadCount } from "~/api/publicInfo";

	export default {
		name: 'nava',
		data() {
			return {
				openMenuList: []
			}
		},
		computed: {
			datas() {
		    	return this.$store.state.homeSearch.menuList
		    }
		},
		mounted() {
			this.$nextTick(() => {
				if(sessionStorage.isHotel == '1'&&sessionStorage.loginName) this.openMenuList = ['账号管理','采购进货','资产管理'];
				this.getMenuList();
			})
		},
		methods: {
			menuOpen(){

			},
			menuClose(){

			},
			...mapMutations({
				"mesnum": "mesnum"
			}),
		    ...mapActions({
		      'getMenuList': 'getMenuList'
		    }),
			filterNode(value, data, node){
				return data.type == 1
			},
			selectMenu(val) {
				getUnReadCount({employeeId: sessionStorage.id}).then(res=>{
						//记录未读信息的个数
						this.mesnum(res.allUnReadCount)
				}).catch(err=>{

				})
				
			}
		}
	}
</script>
<style lang="less">
	#nava{
		.el-menu-vertical{
			background-color: #f6f6f6;
			margin-top: -16px;
		}
		.el-menu-item, .el-submenu__title{
			color: #333;
			height: 46px;
			font-size: 16px;
			line-height: 46px;
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;
			padding-right: 10px;
		}
		.el-submenu .el-menu .el-menu-item{
			color: #666;
			font-size: 12px;
			height: 31px;
			line-height: 31px;
			width: 130px;
			padding-left: 14px !important;
			padding-right: 10px !important;
		}
		.el-submenu>.el-submenu__title .el-icon-caret-right {
			position: absolute;
			left: 0;
			top: 50%;
			margin-top: -7px;
			transition: transform .3s;
			font-size: 12px;
			color: #bbb;
		}
		.el-submenu.is-opened>.el-submenu__title .el-icon-caret-right {
		    transform: rotateZ(90deg);
		}
		.el-submenu .el-menu,.el-menu--horizontal.el-menu--dark .el-submenu .el-menu-item:hover, .el-menu--horizontal.el-menu--dark .el-submenu .el-submenu-title:hover, .el-menu-item:hover, .el-submenu__title:hover{
			background-color: #f6f6f6;
		}
		.el-menu-item.is-active,
		.el-menu .el-submenu.is-opened .el-menu-item:hover, .el-menu .el-submenu.is-opened .el-menu-item.is-active,
		.el-submenu.is-opened .el-submenu__title .el-icon-caret-right, .el-submenu:hover .el-submenu__title .el-icon-caret-right{
			color: #ff4201;
		}
		.el-submenu__icon-arrow{
			display: none;
		}
		.el-menu-item-group__title {
			padding: 0 0 0 20px !important;
		}
		.el-menu {
			.el-menu-item-group {
				.el-menu-item-group__title {
					font-weight: 400;
					color: #333;
					padding: 5px 0 6px 24px!important;
				}
				.el-menu-item {
					padding-left: 28px!important;
				}
			}
		}

	}
</style>
