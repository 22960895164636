<template>
  <div id="addinvoice-dialog">
    <el-dialog title="补开发票" :visible.sync="dialogVisible" size @close="goback" width="600px">
      <div class="input-item">
        <el-form :model="form" label-width="120px" :rules="rules" ref="ruleForm">
          <el-form-item label="订单编号：" prop="orderSn" required>
            <el-input v-model.trim="form.orderSn" placeholder="输入订单编号" :maxlength="19"></el-input>
          </el-form-item>
          <el-form-item label="发票类型：">
            <el-radio-group v-model="form.type">
              <el-radio label="1">增值税普通发票</el-radio>
              <el-radio label="2">增值税专用发票</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="抬头类型：">
            <el-radio-group v-model="form.personalCompanyType">
              <el-radio label="2">个人</el-radio>
              <el-radio label="1">单位</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="发票抬头：" prop="taitou" >
            <el-input v-model.trim="form.taitou" placeholder="输入发票抬头"></el-input>
          </el-form-item>
          <el-form-item
            label="纳税人识别号："
            v-if="form.personalCompanyType == '1'"
            prop="taxpayerIdNo"
            required
          >
            <el-input v-model.trim="form.taxpayerIdNo" placeholder="输入15-20位纳税人识别号"></el-input>
          </el-form-item>
          <el-form-item label="收货人：" prop="linkman">
            <el-input v-model.trim="form.linkman" placeholder="输入收货人" :maxlength="16"></el-input>
          </el-form-item>
          <el-form-item label="电话：" prop="tel" >
            <el-input v-model.trim="form.tel" placeholder="输入电话"></el-input>
          </el-form-item>
          <el-form-item label="收票地址：" prop="areas" >
            <cityCascaderBox v-model='form.areas' :num="3" :showAllLevels="true" :checkStrictly="false" :emitPath="true" @changeName="changeName"/>
          </el-form-item>
          <el-form-item label prop="addr">
            <el-input v-model.trim="form.addr" placeholder="输入收票地址" :maxlength="50"></el-input>
          </el-form-item>
          <el-form-item label="备注：">
            <el-input v-model.trim="form.remarks" placeholder="输入备注" :maxlength="50"></el-input>
          </el-form-item>
          <el-form-item label="发票金额：">
            <el-input v-model.trim="form.money" placeholder="输入发票金额" :disabled="true"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="addinvoice">确定开票</el-button>
        <el-button @click="goback">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { axiosApi, basePath } from "../api/api";
import {
  checkcompanyname,
  checkphonenum,
  taxpayerIdNo,
  registerAddress,
  bankDeposit,
  bankCard,
  checktaitou,
  tel
} from "../utils/formverify";

export default {
  props: ["visible", "isHotelJJ"],
  data() {
    var checkorderSn = (rule, value, callback) => {
      const api = "/superc/invoice/getInfo";
      if (value.length == 0) {
        return callback(new Error("请输入订单编号！"));
      } else if (!/\d/g.test(value)) {
        return callback(new Error("订单编号为数字类型！"));
      } else {
        axiosApi(
          api,
          { orderSn: value },
          res => {
            this.form.name = res.data.data.buyerName;
            this.form.money = (res.data.data.needPayMoney-res.data.data.freightMoney).toFixed(2);
            callback();
          },
          0,
          10,
          res => {
            return callback(new Error("请输入正确的订单编号！"));
          }
        );
      }
    };
    var linkman = (rule, value, callback) => {
      if (value == "" || !value) {
        callback(new Error("请输入收货人"));
      } else {
        callback();
      }
    };
    var areas = (rule, value, callback) => {
      if (value.length == "0" || !value) {
        callback(new Error("请输入收票地址"));
      } else {
        callback();
      }
    };
    var addr = (rule, value, callback) => {
      if (value == "" || !value) {
        callback(new Error("请输入详细地址"));
      } else {
        callback();
      }
    };
    var taxpayerIdNos = (rule, value, callback) => {
      const re = /^[0-9A-Z]{15,20}$/;
      if (value == "" || !value) {
        return callback(new Error("请输入纳税人识别号"));
      } else if (!re.test(value)) {
        callback(new Error("请输入正确的纳税人识别号"));
      } else {
        callback();
      }
    };
    var checkcompanynames = (rule, value, callback) => {
      const re = /^[\u4e00-\u9fa5a-zA-Z]{1,30}$/;
      if (value == "" || !value) {
        return callback(new Error("请输入公司名称"));
      } else if (!re.test(value)) {
        return callback(new Error("公司名称为不超过30个字符的汉字和字母组合"));
      } else {
        callback();
      }
    };
    return {
      dialogVisible: false,
      form: {
        orderSn: "",
        name: "",
        type: "1",
        personalCompanyType: "2",
        taitou: "",
        companyHead: "",
        taxpayerIdNo: "",
        registerAddress: "",
        registerPhone: "",
        bankDeposit: "",
        bankCard: "",
        content: "2",
        money: "", 
        elDialog: false,
        orderType: "2",
        remarks: "",
        addr: "",
        areas: [],
        linkman: "",
        tel: ""
      },
      addressArray: [],
      obj: {},
      rules: {
        orderSn: [
            { require: true, message: "请输入订单编号", trigger: "blur" },
          { validator: checkorderSn, trigger: "blur" }

        ],
        companyHead: [{ validator: checkcompanynames, trigger: "blur" }],
        taitou: [{ validator: checktaitou, trigger: "blur" },
            {require: true, message: "请输入发票抬头", trigger: "blur" }],
        taxpayerIdNo: [
          {
            validator: taxpayerIdNos,
            trigger: "blur",
            min: 15,
            max: 20,
            message: "输入15-20位纳税人识别号"
          },
            {require: true, message: "纳税人识别号", trigger: "blur" }
        ],
        registerAddress: [{ validator: registerAddress, trigger: "blur" }],
        tel: [{ validator: tel, trigger: "blur" }],
        linkman: [{required: true, message: '请输入收货人', trigger: 'blur' }],
        areas: [{ required: true, message: '请选择收票地址', trigger: 'change' }],
        addr: [{ required: true, message: '请输入详细地址', trigger: 'blur' }],
        bankDeposit: [{ validator: bankDeposit, trigger: "blur" }],
        bankCard: [{ validator: bankCard, trigger: "blur" }]
      }
    };
  },
  watch: {
    visible(val) {
      this.dialogVisible = val;
      if (val) {
        this.form.type = "1";
        this.form.name = "";
        this.form.money = "";
        this.form.remarks = "";
        if (this.$refs.ruleForm) this.$refs.ruleForm.resetFields();
      }
    }
  },
  computed: {
    postData() {
      var newobj = Object.assign({}, this.form);
      if (newobj.taitou != "") {
        newobj.companyHead = newobj.taitou;
      }
      delete newobj.taitou;
      delete newobj.name;
      delete newobj.money;
      return newobj;
    }
  },
  methods: {
    changeName(e){
      this.addressArray = e
    },
    addinvoice() {
      const api = this.isHotelJJ
        ? "/superc/invoice/makeUp"
        : "/order/invoice/makeUp";
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          let obj = {};
          obj.type = this.form.type;
          obj.linkman = this.form.linkman;
          obj.tel = this.form.tel;
          obj.remarks = this.form.remarks;
          obj.personalCompanyType = this.form.personalCompanyType;
          obj.orderSn = this.form.orderSn;
          obj.addr = this.form.addr;
          obj.provinceId = this.form.areas[0];
          obj.cityId = this.form.areas[1];
          obj.regionId = this.form.areas[2];
          obj.provinceName = this.addressArray[0];
          obj.cityName = this.addressArray[1];
          obj.regionName = this.addressArray[2];
          obj.companyHead = this.form.taitou;
          obj.taxpayerIdNo = this.form.taxpayerIdNo;
          axiosApi(
            api,
            { ...obj, ...{ hotelId: sessionStorage.traderId } },
            () => {
              this.$notify({
                type: "success",
                message: "补开发票成功"
              });
              this.goback();
              this.$emit("reload");
            },
            0,
            10,
            res => {
              this.$notify({
                type: "warning",
                message: res.data.message
              });
            }
          );
        } else {
          console.log("error");
        }
      });
    },
    goback() {
      this.$emit("close");
    }
  }
};
</script>

<style lang="less" scoped>
#addinvoice-dialog {
  .input-item {
    width: 420px;
    max-height: 600px;
    margin: 0 80px;
    margin-bottom: 80px;
  }
}
</style>
