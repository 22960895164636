import {axiosApi,basePath} from '../../api/api.js'
import {Notification,Message } from 'element-ui'
import m from '../../main.js'

const goodsAdd = {
    state: {
        goodsList:[],//商品列表
        willAddGoods:[],//即将添加的商品列表
        willDeleteGoods:[],//即将删除的商品列表
        goodsLoading:false,//商品loading
    },
    mutations: {
        addGoods(state,payload){
            state.willAddGoods.push(...payload.willAdd);
        },
        delGoods(state,payload){
            console.log(payload,'');
            let arr = []
            state.willAddGoods.map(v => {
                let flag = true;
                payload.delIds.map(n => {
                     if(v.id == n){
                         flag = false
                     }
                });
                if(flag){
                    arr.push(v)
                }
            });
            console.log(arr,'arrr')
            state.willAddGoods = arr;
        },
        selectedAll(state,payload){
            state.willAddGoods = state.goodsList;
        },
        resetGoods(state,payload){
            if(!payload.flag){
                state.goodsList = [];
            }
            state.willAddGoods = [];
        },
        changeLoading(state,payload){
            state.goodsLoading = payload.goodsLoading;
        }
    },
    actions: {
        //异步操作 获取商品列表
        getGoodsListActivity({state},payload){
            state.goodsLoading = true;
            axiosApi('/groupon/activityGoods/list',payload,res => {
                state.goodsLoading = false;
                console.log(res);
                state.goodsList = res.data.data;
                state.willAddGoods = res.data.data;
            })
        }
    },
    getters: {
        goodsList(state){
            let arr = [];
            state.goodsList.map(v => {
                let flag = false;
                state.willAddGoods.map(x => {
                    if(v.id == x.id){
                        flag = true;
                    }
                })
                if(!flag){
                    arr.push(v)
                }
            })
            return arr;
        }
    }
}

export default goodsAdd