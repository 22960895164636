<template>
  <div class="delivery_management">
    <!-- body  -->
    <div class="body">
      <div class="clearfix">
        <el-form
          :inline="true"
          class="pt-30 searchForm"
          :model="form"
          ref="form"
        >
          <el-form-item label="订单编号" prop="orderSn">
            <el-input
              v-model.trim="form.orderSn"
              @input="
                form.orderSn = form.orderSn.replace(/[\u4e00-\u9fa5]/gi, '')
              "
              placeholder="请输入订单编号"
            ></el-input>
          </el-form-item>
          <el-form-item label="买家名称" prop="buyerName">
            <el-input
              v-model.trim="form.buyerName"
              placeholder="请输入买家名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="订单商品" prop="skuName">
            <el-input
              v-model.trim="form.skuName"
              placeholder="请输入商品名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="提货时间" prop="orderDateRange">
            <ys-daterange-picker v-model="form.orderDateRange" />
          </el-form-item>
        </el-form>
        <br />
        <el-button class="ml-10 mb-30" type="info" @click="handleQueryBtn(1)"
          >查 询</el-button
        >
      </div>
      <div class="clearfix list-top">
        <div class="fl">数据列表</div>
        <div class="fr mr-10">
          <el-button
            v-if="!isPickup"
            type="default"
            @click="batchAudit(1, null)"
            >批量通过</el-button
          >
          <el-button
            v-if="!isPickup"
            type="default"
            @click="batchAudit(0, null)"
            >批量驳回</el-button
          >
        </div>
      </div>
      <div class="list">
        <div class="top clearfix" style="display: flex; text-align: center">
          <el-checkbox
            v-model="checkAll"
            @change="handleCheckAllChange"
          ></el-checkbox>
          <el-dropdown
            class="dropdown1"
            @command="handleCommandTime"
            style="position: absolute"
          >
            <span class="el-dropdown-link">
              {{ time }}<i class="el-icon-caret-bottom el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                v-for="(item, index) in deliveryTime"
                :key="index"
                :command="item"
                >{{ item }}</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
          <span style="flex: 4">提货详情</span>
          <span style="flex: 1">下单数量</span>
          <span style="flex: 1">下单仓库</span>
          <span style="flex: 1">提货数量</span>
          <div style="flex: 1">
            <el-dropdown class="dropdown1" @command="handleCommandStatus">
              <span class="el-dropdown-link">
                {{ statusName}}<i class="el-icon-caret-bottom el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  v-for="(item, index) in statusList"
                  :key="item.id"
                  :command="item"
                  >{{ item.type }}</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </div>

          <span style="flex: 1">审核人员</span>
          <span style="flex: 2">操作</span>
        </div>

        <div class="table-item-default tac" v-if="elements.length <= 0">
          <img src="../../../assets/empty.png" alt="" />
          <div class="color-danger mt-30">
            <span class="color-danger">暂无数据</span>
          </div>
        </div>
        <!--  列表 -->
        <div class="content" v-for="(item, k) in elements" :key="k">
          <div class="clearfix top1">
            <el-checkbox
              class="fl ml-15"
              :disabled="!item.ischecked"
              v-model="item.checkOrderItem"
              @change="(checked) => handleDeliveryCheck(checked, item, k)"
            ></el-checkbox>
            <div class="fl" style="minwidth: 240px; marginleft: 17px">
              订单编号：{{ item.orderSn }}
            </div>
            <div class="fl ml-20">买家名称：{{ item.buyerName }}</div>
            <div
              class="fr mr-10 cursor color-blue"
              @click="goToOrderDetail(item.orderSn, 1)"
            >
              查看订单 <i class="el-icon-arrow-right"></i>
            </div>
          </div>
          <div class="clearfix top2">

            
            <div class="fl  ml-20" >
              提货批次号：{{ item.deliverySeqId }}
            </div>
            <div class="fl ml-20">
              提货时间：{{ item.gmtCreate | time }}
            </div>
            <div
              class="fl ml-20 ellipsis"
              style="width: 500px"
              :title="item.remark"
            >
              提货备注：{{ item.remark || "无" }}
            </div>
          </div>
          <el-checkbox-group
            v-model="checkOrder"
            @change="(v) => handleCheckChange(item)"
          >
            <div class="clearfix font-14" style="display: flex">
              <div style="flex: 9">
                <div
                  class="item clearfix"
                  v-for="(i, k) in item.subDeliveryItem"
                  :key="k"
                  style="display: flex"
                >
                  <div class="good_item fl" style="flex: 4">
                    <el-checkbox
                      :disabled="i.status != 1"
                      :label="i"
                    ></el-checkbox>
                    <div style="display: flex; flex-direction: column">
                      <div class="good">
                        <div
                          v-if="i.changePurchaseItem"
                          class="triangle-topleft border-red"
                        ></div>
                        <span v-if="i.changePurchaseItem" class="init default"
                          >换购</span
                        >
                        <div
                          v-if="i.skuGift"
                          class="triangle-topleft border-red"
                        ></div>
                        <span v-if="i.skuGift" class="init default">赠品</span>
                        <img v-if="i.skuImage" :src="i.skuImage" alt="" />
                        <img v-else src="../../../assets/goods.jpg" alt="" />
                        <div class="good_name">
                          <div>
                            <p class="text-overflow">{{ i.skuName }}</p>
                            <p
                              class="mt-10 color-9"
                              v-if="i.k3SaleType"
                            >
                              商品类型：{{ i.k3SaleTypeName }}
                            </p>
                            <p
                              class="mt-10 color-9"
                              v-if="i.k3GiftType"
                            >
                              赠品类型：{{ i.k3GiftTypeName }}
                            </p>
                            <p
                              class="mt-10 color-9"
                              v-if="i.deliveryId"
                            >
                              K3出库单单号：{{ i.deliveryId }}
                            </p>
                          </div>
                        </div>
                      </div>
                      <!-- <p  v-if="i.remark" style="lineHeight:20px;color:rgba(255,89,24,1)">商品备注：{{ i.remark }}</p> -->
                    </div>
                  </div>
                  <div
                    class="fl tac item_item"
                    style="flex: 1; display: flex; justify-content: center"
                  >
                    <div>{{ i.specNumTotal }}&nbsp;</div>
                    <div v-if="item.pickupType == 2 && item.deliverySeparate">
                      <div v-if="!i.skuGift">{{ i.specUnitName }}</div>
                      <div v-else>
                        <!--满足发货一箱-->
                        <el-select
                          v-if="
                            Math.floor(i.remainingNum / i.changeCoefficient) >
                              0 && i.own == '瓶'
                          "
                          class="unit_spec"
                          v-model="i.specUnitName"
                          placeholder="请选择"
                          @change="unitChange(i, k)"
                        >
                          <el-option
                            v-for="item in unitOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          >
                          </el-option>
                        </el-select>
                        <!--不满足一箱-->
                        <span v-else>{{ i.specUnitName }}</span>
                      </div>
                    </div>
                    <div v-else>
                      {{ i.specUnitName }}
                    </div>
                  </div>
                  <div class="fl tac item_item" style="flex: 1">
                    {{ i.k3StockName }}
                  </div>
                  <div
                    class="fl tac item_item"
                    style="flex: 1; position: relative"
                  >
                    <span
                      v-if="
                        item.pickupType == 2 &&
                        i.skuGift &&
                        i.status == 1 &&
                        item.deliverySeparate
                      "
                      class="font-12"
                      style="
                        color: red;
                        position: absolute;
                        top: -20px;
                        left: 20px;
                      "
                      >可发
                      <span v-if="i.own == '箱'"
                        >{{ i.remainingNum || 0 }}箱</span
                      >
                      <span v-else>
                        <span v-if="i.own == '瓶' && i.specUnitName == '瓶'">
                          {{ i.remainingNum || 0 }}瓶
                        </span>
                        <span v-if="i.own == '瓶' && i.specUnitName == '箱'">
                          <span
                            >{{
                              Math.floor(i.remainingNum / i.changeCoefficient)
                            }}箱</span
                          >
                          <span
                            >{{
                              i.remainingNum -
                              Math.floor(i.remainingNum / i.changeCoefficient) *
                                i.changeCoefficient
                            }}瓶</span
                          >
                        </span>
                      </span>
                    </span>
                    <el-input-number
                      class="own-input"
                      v-if="
                        item.pickupType == 2 &&
                        i.skuGift &&
                        i.status == 1 &&
                        item.deliverySeparate &&
                        i.own == '箱'
                      "
                      v-model="i.specNum"
                      style="position: absolute; top: 60px; left: 0.5px"
                      :min="0"
                      :max="i.remainingNum || 0"
                      :step="1"
                    />
                    <el-input-number
                      class="own-input"
                      v-if="
                        item.pickupType == 2 &&
                        i.skuGift &&
                        i.status == 1 &&
                        item.deliverySeparate &&
                        i.own == '瓶' &&
                        i.specUnitName == '瓶'
                      "
                      v-model="i.specNum"
                      style="position: absolute; top: 60px; left: 0.5px"
                      :min="0"
                      :max="i.remainingNum || 0"
                      :step="1"
                    />
                    <el-input-number
                      class="own-input"
                      v-if="
                        item.pickupType == 2 &&
                        i.skuGift &&
                        i.status == 1 &&
                        item.deliverySeparate &&
                        i.own == '瓶' &&
                        i.specUnitName == '箱'
                      "
                      v-model="i.specNum"
                      style="position: absolute; top: 60px; left: 0.5px"
                      :min="0"
                      :max="
                        Math.floor(i.remainingNum / i.changeCoefficient) || 0
                      "
                      :step="1"
                    />

                    <span v-if="item.pickupType != 2" class="font-12">
                      {{ i.specNum }}
                      <span v-if="i.own == '箱'">箱</span>
                      <span v-else>
                        <span v-if="i.own == '瓶' && i.specUnitName == '瓶'"
                          >瓶
                        </span>
                        <!-- <span v-if="i.own == '瓶' && i.specUnitName == '箱'">
													<span>{{Math.floor(i.remainingNum/i.changeCoefficient)}}箱</span>
													<span>{{i.remainingNum - Math.floor(i.remainingNum/i.changeCoefficient)*i.changeCoefficient}}瓶</span>
												</span> -->
                      </span>
                    </span>
                    <span
                      v-if="item.pickupType == 2 && i.status != 1"
                      class="font-12"
                    >
                      {{ i.specNum }}
                      <span v-if="i.own == '箱'">箱</span>
                      <span v-else>
                        <span v-if="i.own == '瓶' && i.specUnitName == '瓶'"
                          >瓶
                        </span>
                        <!-- <span v-if="i.own == '瓶' && i.specUnitName == '箱'">
													<span>{{Math.floor(i.remainingNum/i.changeCoefficient)}}箱</span>
													<span>{{i.remainingNum - Math.floor(i.remainingNum/i.changeCoefficient)*i.changeCoefficient}}瓶</span>
												</span> -->
                      </span>
                    </span>
                    <span
                      v-if="item.pickupType == 2 && i.status == 1 && !i.skuGift"
                      class="font-12"
                    >
                      {{ i.specNum }}
                      <span v-if="i.own == '箱'">箱</span>
                      <span v-else>
                        <span v-if="i.own == '瓶' && i.specUnitName == '瓶'"
                          >瓶
                        </span>
                        <!-- <span v-if="i.own == '瓶' && i.specUnitName == '箱'">
													<span>{{Math.floor(i.remainingNum/i.changeCoefficient)}}箱</span>
													<span>{{i.remainingNum - Math.floor(i.remainingNum/i.changeCoefficient)*i.changeCoefficient}}瓶</span>
												</span> -->
                      </span>
                    </span>
                  </div>
                  
                  
                  
                  
                  
                  <div
                    class="fl tac"
                    style="flex: 1; borderleft: 1px solid #ddd; height: 120px"
                  >
                    <div class="mt-50">{{ i.statusName }}</div>
                    <el-tooltip
                      v-if="i.status === 4"
                      effect="dark"
                      :content="i.rejectReason"
                      placement="left"
                    >
                      <span class="color-danger">原因</span>
                    </el-tooltip>
                  </div>
                  <div
                    class="fl tac"
                    style="flex: 1; borderleft: 1px solid #ddd; height: 120px"
                  >
                    <div class="mt-30">{{ i.auditUserName }}</div>
                    <div v-if="i.gmtAudit" style="margintop: 10px">
                      {{ i.gmtAudit | time }}
                    </div>
                  </div>
									<!-- 操作 -->
                  <div
                    class="fl tac"
                    style="flex: 2; borderleft: 1px solid #ddd; height: 120px"
                  >
                    <div v-if="i.status === 1" class="mt-25 mb-10">
                      <el-button
                        @click="batchAudit(1, i)"
                        type="primary"
                        size="mini"
                        >通过</el-button
                      >
                      <div class="mt-10">
                        <el-button
                          @click="batchAudit(0, i)"
                          type="default"
                          size="mini"
                          >驳回</el-button
                        >
                      </div>
                    </div>
                    
                    
				
                  </div>
                </div>
              </div>
              <!--end-->

              <!--右侧单独按钮-->
              <!-- <div
                class="tac fr"
                style="
                  flex: 2;
                  border: 1px solid #ddd;
                  borderleft: none;
                  border-top: none;
                "
                :style="'height:' + 120.5 * item.subDeliveryItem.length + 'px'"
              >
                <div v-if="item.deliverySeparate&&item.status === 1">
                  <div class="mt-25 mb-10">
                    <el-button
                      @click="batchAudit(1, item, true)"
                      type="primary"
                      size="mini"
                      >通过</el-button
                    >
                  </div>
                  <el-button
                    @click="batchAudit(0, item, true)"
                    type="default"
                    size="mini"
                    >驳回</el-button
                  >
                </div>
              </div> -->
            </div>
          </el-checkbox-group>
        </div>

        <!-- 列表End -->
        <!-- 分页 -->
        <el-pagination
          v-if="total > 0"
          class="tar"
          :current-page="currentPage"
          :page-size="10"
          :total="total"
          layout="prev , pager, next, jumper"
          @current-change="handleCurrentChange"
        >
          <!-- 分页组件 -->
        </el-pagination>
      </div>
    </div>
    <!-- bodyEnd -->
    <!--审核驳回弹出框-->
    <el-dialog
      class="cancelDeliveryDialog"
      :close-on-click-modal="false"
      @close="closeOrderDialog"
      :title="title"
      :visible.sync="cancelVisible"
      width="500px"
    >
      <el-form
        class="pl-30 pr-30"
        :model="cancelform"
        ref="cancelForm"
        :rules="rules"
      >
        <el-form-item label="" prop="rejectReason">
          <el-input
            type="textarea"
            :maxlength="30"
            placeholder="请输入驳回的理由..."
            :autosize="{ minRows: 3, maxRows: 5 }"
            v-model.trim="cancelform.rejectReason"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="danger" :loading="btnLoading" @click="cancelCommit"
          >提 交</el-button
        >
        <el-button @click="cancelVisible = false">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { axiosApi, basePath } from "../../../api/api";
// import YsDaterangePicker from "~/components/ys-daterange-picker";
export default {
  // components: {YsDaterangePicker},
  data() {
    return {
      unitOptions: [
        {
          label: "箱",
          value: "箱",
        },
        {
          label: "瓶",
          value: "瓶",
        },
      ],
      dataTabs: ["全部提货", "申请中", "已发货", "未通过"],
      type: "0",
      form: {
        orderSn: "",
        buyerName: "",
        skuName: "",
        orderDateRange: [null, null], // 提货时间数组
      },
      checkAll: false,
      deliveryTime: [
        "全部提货",
        "近一月提货",
        "近三个月提货",
        "近六个月提货",
        "近一年提货",
      ],
      time: "全部提货",
      period: null,
      // statusList: ["全部状态", "申请中", "已发货", "待发货", "未通过"],
			statusList: [
				{id:null,type:'全部状态'},
				{id:0,type:'已取消'},
				{id:1,type:'申请中'},
				{id:2,type:'已发货'},
				{id:3,type:'待发货'},
				{id:4,type:'已驳回'},
				{id:5,type:'已退货'},
			],
      statusName: "全部状态",
      status: null,
      total: 0,
      currentPage: 1,
      elements: [],
      checkOrder: [], // 选中的商品数量
      cancelVisible: false,
      cancelform: {
        rejectReason: "",
      },
      checkedArr: [], //选中已操作数组
      rules: {
        rejectReason: [
          {
            required: true,
            message: "请输入驳回的理由",
          },
          {
            max: 30,
            message: "不能超过30个字！",
          },
        ],
      },
      btnLoading: false,
      title: "",
      isPickupType: false,
      isAll: false,
      isPickup: false,
      giftTypeData: [],
    };
  },
  activated() {
    this.checkAll = false;
    this.form.orderSn = this.$route.query.orderSn
      ? this.$route.query.orderSn
      : "";
    let body = Object.assign(
      {
        period: this.period,
        status: this.status,
        sellerId: sessionStorage.traderId,
      },
      this.form
    );
    this.giftTypeList();
    // body.buyerId = '9861'
    this.deliveryListPort(body).then((res) => {
      this.processingData(res);
    });
  },
  methods: {

    giftTypeFilter(val) {
      let str = "";
      this.giftTypeData.map((v) => {
        if (v.giftType == val) {
          str = v.giftTypeName;
        }
      });
      return str;
    },
    giftTypeList() {
      axiosApi("/marketing/giftType/list", {}, (res) => {
        console.log(res, "res list");
        this.giftTypeData = res.data.data;
      });
    },
    unitChange(row, i) {
      // 瓶箱转换
      row.specNum = 0;
      //				this.$set(this.goodsList, i, Object.assign(row, {
      //					'specNum': 0
      //				}))
    },
    processingData(res) {
      // 处理数据
      if (res.data.data) {
        let data = res.data.data;
        this.total = data.totalCount; // 列表总数
        this.elements = JSON.parse(JSON.stringify(data.elements));
        this.elements.forEach((item) => {
          // 列表数据
          if (typeof item.checkOrderItem == "undefined") {
            this.$set(item, "checkOrderItem", false);
          }

          let temp = false;
          item.subDeliveryItem.forEach((it) => {
            it.own = it.specUnitName;
            if (it.status == 1) temp = true; // 提货申请中状态 能选中
            if (it.status == 1 && it.skuGift && item.pickupType == 2) {
              it.specNum = 0;
            }
          });
          if (temp) this.$set(item, "ischecked", true); // 能选中
          if (item.pickupType == 2) {
            this.isAll = true;
          } else {
            this.isAll = false;
          }
          if (!item.deliverySeparate) {
            this.isPickup = false;
          } else if (item.deliverySeparate && item.pickupType == 1) {
            this.isPickup = false;
          } else if (item.deliverySeparate && item.pickupType == 2) {
            this.isPickup = true;
          }
        });
        console.log(this.elements, "elements");
      } else {
        this.elements = [];
        this.total = 0;
      }
    },
    goToOrderDetail(orderSn, type) {
      //订单详情
      let a = null;
      if (type === 2) a = 2; // 聚合的主订单
      if (type === 1) a = 1; // 子订单，不拆分订单
      this.$router.push({
        path: basePath + "/newValetOrder",
        query: {
          orderSn: orderSn,
          type: a,
          whichPage: 2,
        },
      });
      sessionStorage.orderType = this.type;
    },
    handleTabs(e) {
      if (e.target.attributes.type) this.type = e.target.attributes.type.value;
    },
    handleQueryBtn(val) {
      // 条件查询
      if (val) {
        // 点击查询按钮
        if (this.currentPage != 1) {
          this.currentPage = 1;
          return false;
        }
      }
      // 自定义时间筛选
      if (
        this.form.orderDateRange.indexOf(null) == 1 ||
        (this.form.orderDateRange.indexOf(null) == 0 &&
          this.form.orderDateRange[1] != null)
      ) {
        // 只有结束时间没有选择只有开始时间没有选择
        this.$message.warning("开始时间和结束时间都必须选择！");
        return false;
      }
      let body = Object.assign(
        {
          period: this.period,
          status: this.status,
          sellerId: sessionStorage.traderId,
        },
        this.form
      );
      if (this.form.orderDateRange.indexOf(null) == -1) {
        // 都选择
        body.gmtStart = +new Date(this.form.orderDateRange[0]);
        body.gmtEnd = +new Date(this.form.orderDateRange[1]);
        this.time = "全部提货";
        body.period = null; // 有时间重置为null
      }
      delete body.orderDateRange;
      this.deliveryListPort(body).then((res) => {
        this.processingData(res);
      });
    },
    handleCurrentChange(val) {
      // 分页
      this.currentPage = val;
      // 自定义时间筛选
      if (
        this.form.orderDateRange.indexOf(null) == 1 ||
        (this.form.orderDateRange.indexOf(null) == 0 &&
          this.form.orderDateRange[1] != null)
      ) {
        // 只有结束时间没有选择只有开始时间没有选择
        this.$message.warning("开始时间和结束时间都必须选择！");
        return false;
      }
      let body = Object.assign(
        {
          period: this.period,
          status: this.status,
          sellerId: sessionStorage.traderId,
        },
        this.form
      );
      if (this.form.orderDateRange.indexOf(null) == -1) {
        // 都选择
        body.gmtStart = +new Date(this.form.orderDateRange[0]);
        body.gmtEnd = +new Date(this.form.orderDateRange[1]);
      }
      delete body.orderDateRange;
      this.deliveryListPort(body).then((res) => {
        this.processingData(res);
        this.handleCheckAllChange();
      });
    },
    batchArr(arr) {
      console.log(arr, "arr");
      let body = {
        sellerId: sessionStorage.traderId,
        auditPass: true,
        itemPos: [],
      };
      if (this.isAll) {
        //卖家开启分配发货
        arr.forEach((item) => {
          if (item.skuGift) {
            item.id = null;
          }
        });
        body.itemPos = arr;
      } else {
        arr.forEach((item) => {
          if (item.status == 1) {
            // 待审核
            body.itemPos.push({
              id: item.id,
            });
          }
        });
      }

      return body;
    },
    processData(obj) {
      let arr = [];
      console.log(this.checkOrder, "aa", obj);
      this.checkOrder.forEach((item) => {
        console.log(item, "item");
        obj.subDeliveryItem.forEach((item1) => {
          if (item.id == item1.id) {
            arr.push(item);
          }
        });
      });
      console.log(arr, "error");
      return arr;
    },
    batchAudit(type, item, flag) {
      // 审核通过， 驳回
      console.log(this.checkOrder, "this.checkOrder");
      if (item == null && this.checkOrder.length <= 0) {
        this.$message.warning("请选中需要操作的数据！");
        return false;
      }
      if (
        item == null &&
        this.checkOrder.length > 0 &&
        this.batchArr(this.checkOrder).itemPos.length <= 0
      ) {
        this.$message.warning(
          type === 1 ? "没有需要批量通过的数据" : "没有需要批量驳回的数据"
        );
        return false;
      }
      if (flag && this.checkOrder.length <= 0) {
        //卖家分配提货
        this.$message.warning("请选中需要操作的数据！");
        return false;
      }
      if (flag && this.checkOrder.length > 0) {
        console.log(this.checkOrder, "test数据");
        if (this.processData(item).length > 0) {
          let a = 0;
          this.processData(item).forEach((item) => {
            if (item.specNum == 0) {
              a++;
            }
            return false;
          });
          if (a > 0) {
            this.$message.warning("请输入所选赠品提货的数量！");
            return false;
          }
        } else {
          this.$message.warning("请选中正确操作的数据！");
          return false;
        }
      }
      if (type === 1) {
        let h = this.$createElement;
        this.$msgbox({
          title: item == null ? "批量审核通过" : "提货审核通过",
          message: h(
            "p",
            null,
            item == null
              ? "请确认是否通过所选订单的提货申请？"
              : "请确认是否通过该订单的提货申请？"
          ),
          showCancelButton: true,
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          beforeClose: (action, instance, done) => {
            if (action === "confirm") {
              instance.confirmButtonLoading = true;
              instance.confirmButtonText = "执行中...";
              if (item == null) {
                // 批量
                if (this.checkOrder.length > 0) {
                  done();
                  this.batchAuditPort(this.batchArr(this.checkOrder))
                    .then((res) => {
                      setTimeout(() => {
                        this.$message.success("操作成功");
                        instance.confirmButtonLoading = false;
                        this.checkedArr = this.checkOrder = []; // 清空选中数组
                        this.checkAll = false;
                        this.elements.forEach((item) => {
                          item.checkOrderItem = false; // 订单纬度复选不选中
                        });
                        this.handleQueryBtn();
                      }, 300);
                    })
                    .catch((res) => {
                      setTimeout(() => {
                        this.$message.error(res.data.message);
                        this.checkedArr = this.checkOrder = []; // 清空选中数组
                        this.checkAll = false;
                        this.elements.forEach((item) => {
                          item.checkOrderItem = false; // 订单纬度复选不选中
                        });
                        instance.confirmButtonLoading = false;
                      }, 300);
                    });
                }
              } else {
                // 单个
                done();
                if (item.pickupType == 2 && item.deliverySeparate) {
                  //卖家分配提货
                  let arrList = [];
                  let body = {
                    sellerId: sessionStorage.traderId,
                    auditPass: true,
                    itemPos: [],
                  };

                  arrList = this.processData(item);
                  arrList.forEach((item) => {
                    if (item.skuGift) {
                      item.id = null;
                    }
                  });
                  body.itemPos = arrList;
                  this.batchAuditPort(body)
                    .then((res) => {
                      setTimeout(() => {
                        this.$message.success("操作成功");
                        this.checkOrder = [];
                        instance.confirmButtonLoading = false;
                        this.handleQueryBtn();
                      }, 300);
                    })
                    .catch((res) => {
                      setTimeout(() => {
                        this.checkOrder = [];
                        this.$message.error(res.data.message);
                        instance.confirmButtonLoading = false;
                      }, 300);
                    });
                } else {
                  //之前的逻辑
                  this.batchAuditPort(this.batchArr([item]))
                    .then((res) => {
                      setTimeout(() => {
                        this.$message.success("操作成功");
                        instance.confirmButtonLoading = false;
                        this.handleQueryBtn();
                      }, 300);
                    })
                    .catch((res) => {
                      setTimeout(() => {
                        this.$message.error(res.data.message);
                        instance.confirmButtonLoading = false;
                      }, 300);
                    });
                }
              }
            } else {
              done();
            }
            delete instance.$slots.default;
          },
        });
      } else if (type === 0) {
        if (item == null) {
          if (this.checkOrder.length > 0) {
            this.cancelform.rejectReason = "";
            this.cancelVisible = true;
            this.checkedArr = [];
          }
          this.title = "批量审核驳回";
        } else {
          if (item.pickupType == 2 && item.deliverySeparate) {
            //卖家分配提货
            this.isPickupType = true;
            this.checkedArr = this.processData(item);
            let a = 0;
            this.checkedArr.forEach((item) => {
              if (item.skuGift) {
                a++;
              }
            });
            if (a == this.checkedArr.length) {
              //只有赠品
              this.$message.warning("卖家分配提货模式下直接驳回赠品没有意义！");
              return false;
            } else {
              this.cancelform.rejectReason = "";
              this.cancelVisible = true;
              this.title = "提货审核驳回";
            }
          } else {
            this.isPickupType = false;
            this.checkedArr = [item];
            this.cancelform.rejectReason = "";
            this.cancelVisible = true;
            this.title = "提货审核驳回";
          }
        }
      }
    },

    cancelCommit() {
      // 弹框驳回提交
      let body = {};
      if (this.checkedArr.length > 0) {
        if (!this.isPickupType) {
          body = this.batchArr(this.checkedArr);
          body.auditPass = false;
        } else {
          body = {
            sellerId: sessionStorage.traderId,
            auditPass: false,
            itemPos: [],
          };
          this.checkedArr.forEach((item) => {
            if (item.skuGift) {
              item.id = null;
            }
          });
          body.itemPos = this.checkedArr;
        }
      } else {
        if (this.checkOrder.length > 0) {
          body = this.batchArr(this.checkOrder);
          body.auditPass = false;
        } else {
          return false;
        }
      }
      setTimeout(() => {
        this.$refs.cancelForm.validate((valid) => {
          if (valid) {
            body.rejectReason = this.cancelform.rejectReason;
            this.btnLoading = true;
            this.batchAuditPort(body)
              .then((res) => {
                this.cancelVisible = false;
                setTimeout(() => {
                  this.btnLoading = false;
                  this.$message.success("操作成功");
                  this.checkedArr = this.checkOrder = []; // 清空选中数组
                  this.handleQueryBtn();
                }, 300);
              })
              .catch((res) => {
                this.cancelVisible = false;
                setTimeout(() => {
                  this.btnLoading = false;
                  this.$message.error(res.data.message);
                  this.checkedArr = this.checkOrder = []; // 清空选中数组
                  this.handleQueryBtn();
                }, 300);
              });
          } else {
            return false;
          }
        });
      }, 0);
    },
    closeOrderDialog() {
      this.cancelform.rejectReason = "";
      this.cancelVisible = false;
      this.btnLoading = false;
    },
    handleCommandTime(command) {
      this.form.orderDateRange = [null, null]; // 清空自定义时间
      this.time = command;
      this.changePeriod();
      this.handleQueryBtn();
    },
    handleCommandStatus(command) {
      this.statusName = command.type;
			this.status = command.id
      // this.changeStatus();
      this.handleQueryBtn();
    },
    changePeriod() {
      // 改变时间
      let index = this.deliveryTime.indexOf(this.time);
      switch (index) {
        case 0:
          this.period = null;
          break;
        case 1:
          this.period = 7;
          break;
        case 2:
          this.period = 3;
          break;
        case 3:
          this.period = 4;
          break;
        case 4:
          this.period = 8;
          break;
        default:
          break;
      }
    },
    // changeStatus() {
    //   // 改变状态
    //   let index = this.statusList.indexOf(this.statusName);
    //   this.status = index === 0 ? null : index;
    // },
    handleCheckAllChange(val) {
      // 全选
      this.checkOrder = [];
      if (this.checkAll) {
        this.elements.forEach((item) => {
          let temp = false;
          item.subDeliveryItem.forEach((it) => {
            if (it.status == 1) {
              // 提货申请中状态 选中
              this.checkOrder.push(it);
              temp = true;
            }
          });
          if (temp) item.checkOrderItem = true; // 订单纬度复选选中
        });
      } else {
        this.elements.forEach((item) => {
          item.checkOrderItem = false; // 订单纬度复选不选中
        });
        this.checkOrder = [];
      }
    },
    handleDeliveryCheck(checked, item, k) {
      // 选中订单纬度
      if (item.checkOrderItem) {
        // 订单纬度 选中
        item.subDeliveryItem.forEach((it) => {
          if (it.status == 1) {
            // 提货申请中状态 选中
            let index = this.checkOrder.indexOf(it); // 选中数组中是否包含当前元素
            if (index == -1) {
              // 不包含
              this.checkOrder.push(it);
            }
          }
        });
        let checkedCount = this.checkOrder.length;
        let index = 0;
        this.elements.forEach((item) => {
          item.subDeliveryItem.forEach((it) => {
            if (it.status == 1) {
              // 累加审核中状态的个数
              index++;
            }
          });
        });
        this.checkAll = checkedCount === index;
      } else {
        // 不选中
        item.subDeliveryItem.forEach((it) => {
          if (it.status == 1) {
            // 提货申请中状态 选中
            let index = this.checkOrder.indexOf(it); // 选中数组中是否包含当前元素
            if (index > -1) this.checkOrder.splice(index, 1);
          }
        });
        this.checkAll = false;
      }
    },
    handleCheckChange(item) {
      // 选中订单中的商品
      console.log(item,'-------')
      let val = this.checkOrder; // 选中数值
      console.log(this.checkOrder,'checkOrdercheckOrder')
      let isNoChecked = false;
      item.subDeliveryItem.forEach((it) => {
        if (it.status == 1) {
          // 审核中状态
          if (val.indexOf(it) == -1) isNoChecked = true; // 只要其中一个没有选中不全选
          console.log(val.indexOf(it));
        }
      });
      item.checkOrderItem = !isNoChecked; // 订单纬度选中
      let checkedCount = val.length;
      let index = 0;
      this.elements.forEach((item) => {
        item.subDeliveryItem.forEach((it) => {
          if (it.status == 1) {
            // 累加审核中状态的个数
            index++;
          }
        });
      });
      this.checkAll = checkedCount === index;
    },
		cancel(id){
			var data = {
					deliveryId: id
			}

			this.$confirm(`此操作将取消该订单,请验证K3出库单:${id}是否已处理, 是否继续?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
					this.cancelOrder(data).then(res=>{
						this.handleQueryBtn();
					})
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '操作已取消'
          });          
        })
		},
    // 接口
    // 买家提货记录
    deliveryListPort(body) {
      return new Promise((resolve, reject) => {
        axiosApi(
          "/order/deliveryItem/all/page",
          body,
          (res) => {
            resolve(res);
          },
          this.currentPage,
          10,
          (err) => {
            reject(err);
          }
        );
      });
    },
    // 发货记录审核（通过，拒绝）
    batchAuditPort(body) {
      return new Promise((resolve, reject) => {
        axiosApi(
          "/order/deliveryItem/batchAudit",
          body,
          (res) => {
            resolve(res);
          },
          null,
          null,
          (err) => {
            reject(err);
          }
        );
      });
    },
		// 取消订单
    cancelOrder(body) {
      return new Promise((resolve, reject) => {
        axiosApi(
          "/order/deliveryItem/cancel",
          body,
          (res) => {
            resolve(res);
          },
          null,
          null,
          (err) => {
            reject(err);
          }
        );
      });
    },
    // 接口End
  },
};
</script>

<style lang="less">
.delivery_management {
  position: relative;
  box-sizing: border-box;
  .searchForm {
    width: 80%;
    .el-form-item__label {
      width: auto;
    }
  }
  .body {
    background-color: #ffffff;
    padding-left: 30px;
    padding-right: 15px;
    padding-bottom: 100px;
    .list-top {
      height: 50px;
      line-height: 50px;
      background-color: #f6f6f6;
      padding-left: 15px;
      border: 1px solid #ddd;
    }
    .list {
      .table-item-default {
        padding-top: 100px;
        min-height: 400px;
      }
      .top {
        background-color: #f6f6f6;
        height: 40px;
        line-height: 40px;
        padding-left: 15px;
        .dropdown1 {
          width: 115px;
          text-align: center;
          .el-dropdown-link {
            cursor: pointer;
          }
        }
      }
      .content {
        // 内容
        .top1 {
          margin-top: 10px;
          height: 50px;
          line-height: 50px;
          background-color: #f6f6f6;
        }
        .top2 {
          height: 50px;
          line-height: 50px;
          border-left: 1px solid #ddd;
          border-right: 1px solid #ddd;
          border-bottom: 1px solid #ddd;
        }
        .item {
          height: 120px;
          border-right: 1px solid #ddd;
          border-bottom: 1px solid #ddd;
          .good_item {
            width: 400px;
            height: 120px;
            display: flex;
            align-items: center;
            border-left: 1px solid #ddd;
            padding-left: 15px;
            .el-checkbox__label {
              display: none;
            }
            .good {
              display: flex;
              margin-left: 10px;
              position: relative;
              .triangle-topleft {
                border-top: 60px solid #ff4201;
                width: 0;
                height: 0;
                border-right: 60px solid transparent;
                position: absolute;
                top: 0;
                left: 0;
              }
              .init {
                transform: rotate(-45deg) translate(-4px, 10px);
                position: absolute;
                left: 0;
                top: 0;
                color: #fff;
                letter-spacing: -0.34px;
              }
              img {
                width: 80px !important;
                height: 80px;
              }
              .good_name {
                margin-left: 10px;
                display: flex;
                align-items: center;
              }
            }
          }
          .item_item {
            height: 120px;
            line-height: 120px;
            border-left: 1px solid #ddd;
          }
          .mt-25 {
            margin-top: 25px;
          }
        }
      }
    }
  }
  .cancelDeliveryDialog {
    .el-dialog {
      min-height: auto;
    }
  }
  .ellipsis {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .mt-25 {
    margin-top: 25px;
  }
  .unit_spec {
    width: 50px;
    .el-input {
      width: 50px;
    }
    .el-input__inner {
      width: 50px;
    }
  }
  .own-input {
    .el-input-number,
    .el-input-number .el-input {
      height: 30px;
      width: 90px !important;
      line-height: 30px;
    }
    .el-input-number .el-input .el-input__inner {
      height: 30px !important;
      width: 90px !important;
      padding-right: 31px;
      padding-left: 31px;
      text-align: center;
    }
    .el-input-number__increase {
      width: 25px !important;
    }
    .el-input-number__decrease,
    .el-input-number__increase {
      width: 25px !important;
    }
  }
}
</style>