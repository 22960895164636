<template>
	<div id="myaccount">
		<div class="pb-20 bg-f">
			<div class="clearfix" style="padding: 10px;background: #F5F7FA;">
				<img class="fl icon-80" style="border-radius:80px;" v-if="form.headportraitUrl" :src="form.headportraitUrl" />
				<img class="fl icon-80"  style="border-radius:80px;" v-else src="../../../assets/header.jpg" />
				<div class="fl ml-20 mt-10">
					<p class="lh-16 font-16">账户名：{{form.loginName}}</p>
					<div class="mt-20">
						<i class="iconfont icon-certification mr-5 font-14" :class="form.isCompanyVerified?'color-blue':'color-b'"></i>
                        <span class="color-3 cursord" v-if="form.isCompanyVerified">已企业认证</span>
						<span v-else class="color-9">企业认证</span>
						<span class="ml-10 cursor" :class="form.isCompanyVerified?'din':'color-blue'" @click="goPage('3')">去认证</span>
						<i class="iconfont icon-phone ml-30 mr-5 color-blue font-14"></i><span>{{form.mobile}}</span>
						<i class="iconfont icon-pay-pass ml-30 mr-5 font-14" :class="form.hasPayPassword?'color-blue':'color-b'"></i>
						<span :class="form.hasPayPassword?'color-3':'color-9'">{{form.hasPayPassword?"支付密码":"支付密码:"}}</span>
						<span class="ml-10 color-blue cursor" @click="goPage('2')">{{form.hasPayPassword?"修改":"去设置"}}</span>
					</div>
				</div>
			</div>
			<div class="mt-20 ml-30" style="display:flex;align-item:center">
				<div style="padding-right:30px">
					<p class="mb-10">账户资产</p>
					<p class="color-danger font-20 dib h32 lh-32" >
						<span v-if="form.withdrawalBalance!=null&&form.noWithdrawalBalance!=null">￥{{form.allmoney?form.allmoney:0 | fmoney}}</span>
						<span v-else>--</span>
					</p>
				</div>
				<div class="bl mr-30 pl-30">
					<p class="mb-10">可提现金额</p>
					<p class="color-danger font-20 dib h32 lh-32">
						<span v-if="form.withdrawalBalance!=null">￥{{form.withdrawalBalance | fmoney}}</span>
						<span v-else>--</span>
					</p>
					
				</div>
				<div class="bl mr-30 pl-30 ">
					<p class="mb-10">在途金额</p>
					<p class="color-danger font-20 dib h32 lh-32">
						<span v-if="form.noWithdrawalBalance!=null">￥{{form.noWithdrawalBalance | fmoney}}</span>
						<span v-else>--</span>
					</p>
				</div>
				<div class="bl mr-30 pl-30 " v-if="form.acctTraderCoinVO!=null">
					<p class="mb-10">可使用久币</p>
					<p class="color-danger font-20 dib h32 lh-32">
						<span v-if="form.acctTraderCoinVO.withdrawCoin!=null">{{ form.acctTraderCoinVO.withdrawCoin }}</span>
						<span v-else>--</span>
					</p>
				</div>
				<div class="bl mr-30 pl-30"  style="cursor: pointer;">
					<p class="mb-10">授信金额 <span v-if="form.buyerFinanceAnalyseVO.analyseReport&&showButton('FINANCE_ANALYSE_VIEW', 101077)" class="color-blue" @click="()=>{this.$router.push( basePath + '/creditRecordInfo')}"><i class="el-icon-s-data"></i>对账单</span></p>
					<p class="color-danger font-10  lh-16"><span>总额：</span>￥{{form.buyerFinanceAnalyseVO.creditAmount | fmoney}}</p>
					<p class="color-danger font-10  lh-16"><span>可用：</span>￥{{form.buyerFinanceAnalyseVO.availableCredit | fmoney}}</p>
				</div>
				<div class="bl mr-30 pl-30" style="position: relative;" v-if="form.buyerIntegrationVO.settlementIntegration!=null">
					<p class="mb-10">本期预估积分</p>
					<p class="color-danger font-20 dib h32 lh-32">{{form.buyerIntegrationVO.settlementIntegration | fmoney}}</p>
					<span v-if="gmtEnd" class="color-danger" style="position: absolute;bottom: -15px;left: 30px;width: 200px;font-size: 12px;">结算时间：{{ gmtEnd }}</span>
				</div>
				<!-- <p class="font-16 lh-16">账户余额:</p>
				<div class="mt-10 clearfix">
					<div class="fl">
						<span class="color-danger font-32 dib h32 lh-32">{{form.allmoney?form.allmoney:0 | fmoney}}</span>
						<span :class="form.withdrawalBalance||form.noWithdrawalBalance?'dib':'din'">
							<span class="ml-5 color-9 lh-14">(可提现:￥{{form.withdrawalBalance | fmoney}}；不可提现:￥{{form.noWithdrawalBalance | fmoney}})</span>
						<el-tooltip content="不可提现金额不能提现到银行卡，确认收货7天后自动转为可提现金额" placement="bottom" effect="light">
							<i class="iconfont font-14 icon-inspector color-b"></i>
						</el-tooltip>
						</span>
					</div></div? -->
					<div class="fl mt-10">
						<el-button v-if="form.allmoney&&form.withdrawalBalance" class="ml-30" type="blue" @click="goPage('1')">提现</el-button>
						<!-- <el-button type="blue" @click="goPage('0')">充值</el-button> -->
					</div>
			</div>
		</div>
		<div class="mt-10 bg-f pb-30">
			<el-tabs v-model="paymentTab" @tab-click="handleTabClick(paymentTab)" class="bbe9 mb-25">
				<el-tab-pane label='可提现金额交易记录' name='6'style="padding-left: 20px"></el-tab-pane>
				<el-tab-pane label='在途金额交易记录' name='0' ></el-tab-pane>
				<el-tab-pane label='充值记录' name='1'></el-tab-pane>
				<el-tab-pane label='提现记录' name='2'></el-tab-pane>
				<el-tab-pane label='退款记录' name='3'></el-tab-pane>
				<el-tab-pane label='积分记录' name='4' v-if="form.buyerIntegrationVO.buyerIntegrationCycleInfoId!=null"></el-tab-pane>
				<el-tab-pane label='久币记录' name='5' v-if="form.acctTraderCoinVO"></el-tab-pane>
			</el-tabs>
		
			<div v-if="paymentTab=='4'" class="ml-25 mr-25">
				<IntegralRecord :id="buyerIntegrationCycleInfoId" :list="list"/>
			</div>
			<div v-else-if="paymentTab=='5'&&form.acctTraderCoinVO" class="ml-25 mr-25">
				<coinRecord :id="form.acctTraderCoinVO?form.acctTraderCoinVO.id:null"/>
			</div>
			<div v-else>
				<el-table :data="paymentdata" :class="{'table-body-hidden':paymentdata.length==0}" class="ml-25 mr-25">
					<el-table-column label="流水号" prop="fundsId" ></el-table-column>
					<el-table-column label="操作时间" prop="createdTime" width="140">
						<template slot-scope="scope" >
							{{scope.row.createdTime | time}}
						</template>
					</el-table-column>
					<el-table-column label="收入(元)"  >
						<template slot-scope="scope">
							{{scope.row.incomeAmount | fmoney}}
						</template>
					</el-table-column>
					<el-table-column label="支出(元)"  >
						<template slot-scope="scope">
							{{scope.row.payoutAmount | fmoney}}
						</template>
					</el-table-column>
					<el-table-column label="账户余额(元)" >
						<template slot-scope="scope">
							{{scope.row.balance | fmoney}}
						</template>
					</el-table-column>
					<el-table-column label="业务大类" >
						<template slot-scope="scope">
							<el-button @click="orderDetail(scope.row, scope.row.outOrderNo, 1)" v-if="scope.row.tradeTypeId==30 && !scope.row.orderNo.startsWith('JJ03') && !scope.row.orderNo.startsWith('JJ01') && !scope.row.orderNo.startsWith('JJ02') && !scope.row.orderNo.startsWith('CT')" class="transactiactive-text-btn" type="text">{{scope.row.fundsName}}</el-button>
							<span v-else class="inline-block h32">{{scope.row.fundsName}}</span>
						</template>
					</el-table-column>
					<el-table-column label="业务小类" show-overflow-tooltip>
						<template slot-scope="scope">
							{{scope.row.busiTypeName}}
						</template>
					</el-table-column>
					<el-table-column label="备注" prop="remark" show-overflow-tooltip>
						<template slot-scope="scope">
							{{scope.row.fundsDesc}}
						</template>
					</el-table-column>
				</el-table>
				<div class="tac mt-30" v-if="paymentdata.length==0">
					<img src="../../../assets/empty.png" alt="" />
					<div class="color-9 mt-30">
						<span>暂无记录</span>
					</div>
				</div>
				<div class="tar mt-20" v-if="paymentdata.length>0">
					<router-link :to="`${basePath}/transaction`">
						<el-button type="text" class="width-auto">
							查看所有交易记录
							<i class="el-icon-arrow-right color-b"></i>
						</el-button>
					</router-link>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { axiosApi, basePath } from "~/api/api.js"
	import {mapActions, mapMutations} from 'vuex'
	import { parseTime } from "@/utils";
	import IntegralRecord from '~/components/account/IntegralRecord.vue'
	import coinRecord from "~/components/account/coinRecord.vue";
	export default {
		name: 'myaccount',
		components: {
			IntegralRecord,
			coinRecord
		},
		data() {
			return {
				info: [], //返回数据的信息
				form: {
					buyerFinanceAnalyseVO: {},
					buyerIntegrationVO:{},
					acctTraderCoinVO:{}
				}, //用户基本信息
				basePath: basePath,
				gmtEnd: null, //单独变量。判断时间
				buyerIntegrationCycleInfoId: null,
				list: [],
			}
		},
		activated() {
			this.$nextTick(()=>{
				this.loading()
				this.clearForm()  //清空查询条件
				this.tradeType()   //选中tab所需要的查询条件
				this.paymentQuery()
			})
		},
		computed:{
			paymentdata(){  //交易记录
				return this.$store.state.paymodule.paymentdata
			},
			paymentTab: {
				get: function() {
				return this.$store.state.paymodule.paymentTab;
				// return this.$store.state.paymodule.paymentTab;
				
				},
				set: function (newVal) {
				this.$store.state.paymodule.paymentTab = newVal;
				
				}
			},
			formInline(){    //查询数据form
					return this.$store.state.paymodule.formInline
				}
		},
		methods: {
			...mapActions({
				'paymentQuery':'paymentQuery' //交易记录接口
			}),
			...mapMutations([
				'paymentTabClick',   //vuex 交易记录tab切换
				'clearForm',
				'tradeType',
				'goOrderDetail'  //跳转订单详情
			]),
			loading() {
				axiosApi("/anon/cbs/account/employeeInfo", {}, res => {
					this.form = res.data.data
					//账户余额
					this.$set(this.form, "allmoney", this.form.withdrawalBalance + this.form.noWithdrawalBalance)
					let reg = /^1\d{10}$/
					if(reg.test(this.form.loginName)) {
						this.$set(this.form, "loginName", this.form.loginName.replace(/(\d{3})\d{4}(\d{3})/, '$1****$2'))
					}
					if(this.form.isCompanyVerified) {
						this.$set(this.form, "content", this.form.realName + "," + this.form.idCardNumber)
					}
					if(res.data.data.buyerIntegrationVO.gmtEnd){
						this.gmtEnd = parseTime(res.data.data.buyerIntegrationVO.gmtEnd,'{y}-{m}-{d}')
					}else{
						this.gmtEnd = null
					}

					this.buyerIntegrationCycleInfoId = res.data.data.buyerIntegrationVO.buyerIntegrationCycleInfoId
					if(res.data.data.buyerIntegrationVO.buyerIntegrationCycleRecordVOPage){
						this.list =  res.data.data.buyerIntegrationVO.buyerIntegrationCycleRecordVOPage.elements

					}
					let payform = {
						loginName: this.form.loginName,
						idCardNumber: this.form.idCardNumber,
						isCompanyVerified: this.form.isCompanyVerified,
						hasPayPassword: this.form.hasPayPassword,
						realName:this.form.realName,
						step:1,
						mobile:this.form.mobile
					}
					sessionStorage.setItem("acctTraderCoinVO", JSON.stringify(res.data.data.acctTraderCoinVO))
					sessionStorage.setItem("payform", JSON.stringify(payform))
				})
			},
			handleTabClick(val) {   //交易记录tab切换
				if(val!=4&&val!=5){ // 4是积分记录,5是久币记录，单独的组件所以排除掉。
					this.paymentTabClick(val)
					this.paymentQuery()
				}
			},
			goPage(path) { //跳转页面
				if(path == "0") { //充值
					this.$router.push(`${basePath}/pay/recharge/${this.form.allmoney}`)
				} else if(path == "1") { //提现
				    if (this.form.allmoney <=0){
                        this.$message.warning('可用金额为0元，暂不支持提现')
                        return false
                    }
                    if (!this.form.isCompanyVerified) {
                        this.$confirm('您的账户尚未通过企业认证，请尽快完成企业认证', '提示', {
                            confirmButtonText: '去认证',
                            cancelButtonText: '我再想想',
                            type: 'warning'
                        }).then(() => {
                            // if (sessionStorage.isAdmin == 1){
                                axiosApi("/trader/findStoreInfo/select", {traderId: sessionStorage.traderId}, (res)=>{
                                    this.$router.push(`${basePath}/enterpriseAuthentication?mobile=${res.data.data.mobile}&storeName=${res.data.data.storeName}&licenseNum=${res.data.data.licenseNum}`)
                                }, 1, 10,err=>{
                                    this.$message.error(err.data.message)
                                })
                            // } else{
                            //     this.$message.error('暂不支持对员工账号开放此操作权限')
                            // }

                            return false
                        }).catch(() => {

                        });
                        return false
                    }
				    if (!this.form.hasPayPassword) {
                        this.$confirm('您的账户尚未设置支付密码，请尽快完成密码设置', '提示', {
                            confirmButtonText: '去设置',
                            cancelButtonText: '我再想想',
                            type: 'warning'
                        }).then(() => {
                            this.$router.push(`${basePath}/pay/bind`)
                            return false
                        }).catch(() => {

                        });
                        return false
                    }
					this.$router.push(`${basePath}/pay/rechargemid/0/${this.form.withdrawalBalance}`)
				}else if(path == "2") { //设置支付密码
					this.$router.push(`${basePath}/pay/bind`)
				}else if(path == "3") { //去认证
				    if (sessionStorage.isAdmin == 1){
                        axiosApi("/trader/findStoreInfo/select", {traderId: sessionStorage.traderId}, (res)=>{
                            this.$router.push(`${basePath}/enterpriseAuthentication?mobile=${res.data.data.mobile}&storeName=${res.data.data.storeName}&licenseNum=${res.data.data.licenseNum}`)
                        }, 1, 10,err=>{
                            this.$message.error(err.data.message)
                        })
                    } else{
                        this.$message.error('暂不支持对员工账号开放此操作权限')
                    }

				}

			},
      orderDetail(row, orderSn, type){  //跳转订单详情
        let a = null
        if(type == 2) a = 2
        if(type == 1) a = 1
        sessionStorage.setItem('orderSn',orderSn)
        sessionStorage.isParentPurchaseOrderType = a //因为订单详情里面 酒店版酒集根据截取url 判断是否是 酒集订单所以不能用 url传值 判断是否是拆分订单
		// 门店久集订单详情-要增加销售员佣金，需要存储特殊标识
		//C端订单暂时隐藏
        // if (row.orderNo.startsWith('JJ03')) {
        //   this.$router.push({path: basePath + '/purchaseOrderDetail', query: {showSalesCommission: 1}})
        // } else {
          this.$router.push({path: basePath + '/purchaseOrderDetail'})
        // }
			}
		}
	}
</script>

<style lang="less">
	#myaccount {
		.h32 {
			height: 32px;line-height: 32px;
		}
		.el-tabs__header {
			padding-left: 0;
		}
		.table-body-hidden{
			.el-table__empty-block{display: none;}
		}
		.transactiactive-text-btn {
			font-size: 12px;width: auto;
		}
		.cursord {
			cursor: default;
		}
		.bl {
			border-left: 1px solid #DDDDDD;
		}
		.br {
			border-right: 1px solid #DDDDDD;
		}
		.pr-80 {
			padding-right: 80px;
		}
		.bbe9 {
			border-bottom: 1px solid #e9e9e9;
			background: #F8F8F8;
		}
		.el-tabs__active-bar {
			height: 0!important;
		}
		.el-tabs__item.is-active {
			border-bottom: 1px solid #e9e9e9;
			background: white!important;
		}
		.el-tabs__item {
			padding-left: 20px!important;
			padding-right: 20px!important;
		}
		.mr-25 {
			margin-right: 25px;
		}
		.ml-25 {
			margin-left: 25px;
		}
		.mb-25 {
			margin-bottom: 25px;
		}
	}
</style>