<template>
	<div id="setbank">
		<div class="title-top bg-f">
			银行卡管理
		</div>
		<div class="mt-5 bg-f p30 cheight clearfix">
			<div class="bankcard bd cursor fl mr-20 mb-20" v-for="item in cardlist">
				<p class="h50">
					<span class="fl ml-30 color-white">{{item.bankName}}</span>
					<span class="fr mr-30 color-white font-12">{{item.bankType==1?"储蓄卡":"信用卡"}}</span>
				</p>
				<div class="pl-30 pr-30 font-12 color-3">
					<p class="lh-12 mt-10">持卡人：{{item.userName}}</p>
					<p class="lh-12 mt-10">{{item.bankCardNo}}</p>
					<p class="color-6 tar mt-20 unbind" @click="unbindcard(item)">解绑此卡</p>
				</div>
			</div>
			<div class="bankcard bd tac cursor fl" @click="dialogvisible=true">
				<i class="iconfont icon-plus-sign color-b font-50 mt-40 dib"></i>
				<p class="color-9 font-12">添加银行卡</p>
			</div>
		</div>
		<!--添加银行卡弹窗-->
		<addbank v-on:closedialog="close(1)" :dialogvisible="dialogvisible" :page="1"></addbank>
		<!--解绑银行卡弹窗-->
		<unbindbank v-on:closedialog="close(2)" :dialogvisible="undialogvisible" :bankCardId="bankCardId"></unbindbank>
	</div>
</template>

<script>
	import { axiosApi, basrPath } from "~/api/api"
	export default {
		data() {
			return {
				dialogvisible: false, //添加銀行卡彈窗是否关闭
				undialogvisible: false, //解绑银行卡弹窗是否关闭
				cardlist: [],
				bankCardId: ""
			}
		},
		mounted() {
			this.$nextTick(() => {
				document.getElementsByClassName("cheight")[0].style.minHeight = document.documentElement.clientHeight - 406 + "px";
				if(!sessionStorage.getItem("payform")) {
					axiosApi("/anon/cbs/account/employeeInfo", {}, res => {
						let payform = {
							mobile: res.data.data.mobile,
							isVerified: res.data.data.isVerified,
							idCardNumber: res.data.data.idCardNumber,
							realName: res.data.data.realName,
						}
						sessionStorage.setItem("payform",JSON.stringify(payform))
					})
				}
				this.getcard()
			})
	},
	methods: {
		getcard() {
			axiosApi("/anon/cbs/pay/cardList", {}, res => {
				this.cardlist = res.data.data
			})
		},
		unbindcard(item) {
			this.bankCardId = item.bankCardId
			this.undialogvisible = true
		},
		close(type) {
			this.getcard()
			setTimeout(() => {
				if(type == 1) {
					this.dialogvisible = false
				} else if(type == 2) {
					this.undialogvisible = false
				}
			})
		}
	}
	}
</script>

<style lang="less">
	#setbank {
		.bankcard {
			border-radius: 6px;
			width: 268px;
			height: 148px;
			.h50 {
				padding-top: 24px;
				width: 270px;
				margin-left: -1px;
				height: 27px;
				background-color: #20a0ff;
				border-radius: 6px 6px 0 0;
			}
			.unbind {
				display: none;
			}
			&:hover {
				.unbind {
					display: block;
				}
			}
		}
	}
</style>