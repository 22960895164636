<template>
    <div class="smsPushBox">
        <messageTitle :type="2" class="bg-f"/>
        <div class="formBox mt-5 bg-f pt-20">
            <el-form :model="form" :rules="ruleForm" ref="form" label-width="120px">
                <el-form-item label="接收对象：">
                    <div class="mb-10">
                        <el-radio-group @change="handleReceiveObj" v-model="form.receiveObj">
                            <el-radio :label="2">客户</el-radio>
                            <el-radio :label="1">员工</el-radio>
                        </el-radio-group>
                    </div>
                    <!-- 客户类型 -->
                    <div v-if="form.receiveObj === 2">
                        <el-radio-group @change="handleCustomer" v-model="form.customerType">
                            <el-radio :label="1">全部客户</el-radio>
                            <el-radio :label="2">分组客户</el-radio>
                            <el-radio :label="3">区域客户</el-radio>
                            <el-radio :label="4">特定客户</el-radio>
                        </el-radio-group>
                        <div class="treeBox" 
                            v-if="form.customerType === 2 || form.customerType === 3"
                        >
                            <!-- 分组树 -->
                            <el-tree
                                v-if="form.customerType === 2"
                                ref="fzTree"
                                :props="{
                                    value: 'id',
                                    label: 'name',
                                    children:'sub'
                                }"
                                :data="form.fzCustomerData"
                                show-checkbox
                                node-key="id"
                                default-expand-all
                                :expand-on-click-node="false"
                            >
                            </el-tree>
                            <div class="qyTreeBox">
                                <!-- 区域树 -->
                                <el-tree
                                    v-if="form.customerType === 3"
                                    ref="qyTree"
                                    :check-strictly="true"
                                    :props="{
                                        value: 'id',
                                        label: 'districtName',
                                        children:'subDistrictList'
                                    }"
                                    :data="form.qyCustomerData"
                                    show-checkbox
                                    node-key="id"
                                    :default-expand-all="true"
                                    :expand-on-click-node="false"
                                    :render-content="renderContent"
                                >
                                </el-tree>
                            </div>
                        </div>
                    </div>
                    <!-- 员工类型 -->
                    <div v-if="form.receiveObj === 1">
                        <el-radio-group @change="handleStaff" v-model="form.staffType">
                            <el-radio :label="1">全部员工</el-radio>
                            <el-radio :label="2">部门员工</el-radio>
                            <el-radio :label="3">特定角色</el-radio>
                            <el-radio :label="4">特定员工</el-radio>
                        </el-radio-group>
                        <div class="treeBox"
                            v-if="form.staffType === 2 || form.staffType === 3"
                        >
                            <div class="qyTreeBox">
                                <!-- 部门树 -->
                                <el-tree
                                    v-if="form.staffType === 2"
                                    ref="bmTree"
                                    :check-strictly="true"
                                    :props="{
                                        value: 'id',
                                        label: 'text',
                                        children:'sub'
                                    }"
                                    :data="form.bmCustomerData"
                                    show-checkbox
                                    node-key="id"
                                    :default-expand-all="true"
                                    :expand-on-click-node="false"
                                    :render-content="renderContent2"
                                >
                                </el-tree>
                            </div>
                            <!-- 角色树 -->
                            <el-tree
                                v-if="form.staffType === 3"
                                ref="jsTree"
                                :props="{
                                    value: 'id',
                                    label: 'text',
                                    children:'subDistrictList'
                                }"
                                :data="form.jsCustomerData"
                                show-checkbox
                                node-key="id"
                                default-expand-all
                                :expand-on-click-node="false"
                            >
                            </el-tree>
                        </div>
                    </div>
                    <!-- 输入框 -->
                    <div v-if="form.customerType === 4 || form.staffType === 4">
                        <el-input placeholder="请输入手机号" 
                            :style="{width : '360px',marginTop : '10px'}" 
                            v-model.trim="form.userNameOrPhone" 
                        />
                        <span class="color-6">
                            直接输入手机号，多个手机号用英文逗号“,”隔开
                        </span>
                    </div>
                </el-form-item>
                <el-form-item label="消息内容：" prop="messageContent">
                    <el-input
                     :style="{width : '360px'}" type="textarea"
                     :maxlength="256"
                     :autosize="{ minRows: 4, maxRows: 4}" 
                     v-model.trim="form.messageContent"></el-input>
                </el-form-item>
                <el-form-item label="发送时间：">
                    <el-radio-group @change="handleSend" v-model="form.sendType">
                        <el-radio :label="1">立即</el-radio>
                        <el-radio :label="2">定时</el-radio>
                    </el-radio-group>
                    <el-date-picker
                        v-if="form.sendType === 2"
                        v-model="form.timer"
                        :clearable="false"
                        type="datetime"
                        placeholder="选择日期时间">
                    </el-date-picker>
                </el-form-item>
                <el-form-item class="pt-30 pb-30">
                    <el-button :loading="loading" type="primary" @click="handleSubmit">确定</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import { axiosApi, basePath } from "../../../api/api";
import messageTitle from './messageTitle.vue';

import { getFz, getQy, getBm, getJs } from '~/api/messageAjaxApi';

export default {
    components : {messageTitle},
    data: function() {
        return ({
            form : {
                // 接收对象
                receiveObj : 2,
                // 客户类型
                customerType : 1,
                // 员工类型
                staffType : 1,
                // 用户名 或者 手机号
                userNameOrPhone : '',
                // 消息内容
                messageContent : '',
                // 发送类型
                sendType : 1,
                // 时间
                timer : '',
                // 分组 数据
                fzCustomerData : [],
                // 区域 数据
                qyCustomerData : [],
                // 部门 数据
                bmCustomerData : [],
                // 角色 数据
                jsCustomerData : [],
            },
            ruleForm : {
                messageContent : [
                    { required: true, message: '请输入消息内容'}
                ],
            },
            loading : false,
        })
    },
    mounted: function() {
        // 获取 分组数据
        getFz((data) => {
            this.form.fzCustomerData = data;
        });
        // 获取 区域数据
        getQy((data) => {
            this.form.qyCustomerData = data;
        });
        // 获取 部门
        getBm((data) => {
            this.form.bmCustomerData = data;
        });
        // 获取 角色
        getJs((data) => {
            this.form.jsCustomerData = data;
        });
    },
    methods: {
        handleSubmit : function(){
            this.$refs.form.validate((valid) => {
                if (valid) {
                    // this.$refs.fzTree.getCheckedNodes() 树 选中的数据
                    // 通过 校验
                    let verifyData = this.verifyFn();
                    if(verifyData){
                        this.loading = true;
                        let form = {...this.form};
                        let sendUrl = '/sms/merchantSendDiyContent';
                        let sendObj = {
                            // 商家的traderId
                            traderId : sessionStorage.getItem('traderId'),
                            // 消息的接收类型（1 员工，2 客户）
                            receiverType : form.receiveObj,
                            // 消息接收子类型（1全部员工，2部门员工，3特定角色，4特定员工)
                            receiverSubtype : form.receiveObj === 2 ? form.customerType : form.staffType,
                            // 消息接收者的标签（如部门ids,角色ids,分组ids，区域ids;多个id之间以,号隔开）
                            receiverTags : verifyData.ids || '',
                            // 消息接收者描述（当消息接收者子类型为部门员工、特定角色、分组客户、区域客户4种中的一种时，该值为选中的部门名称、角色名称、分组名称、区域名称拼接的字符串，以,隔开）
                            receiverDescribe : verifyData.names || '',
                            mobile : form.userNameOrPhone,
                            businessTypeList : [2],
                            // 消息内容
                            diySendContent : form.messageContent,
                            // 触发时间(若消息立即发送则该值为空，否则为具体的时间)
                            gmtTrigger : + new Date(form.timer) || '',
                        };
                        axiosApi(sendUrl, sendObj, (res) => {
                            let data = res.data;
                            this.$message.success('发送成功');
                            this.reload();
                            this.loading = false;
                        },1,10,(err) => {
                            this.loading = false;
                            let data = err.data;
                            if(data.code === 'SMS0007'){
                                this.$message.warning(data.message);
                            }
                        });
                    }
                }
            });
        },
        // 校验表单 其他 数据
        verifyFn : function(){
            let form = {...this.form};
            let message = '';
            // 文本输入 id集合  name集合
            let text = '', 
                ids = [] , 
                names = [];
            if(this.form.customerType === 2){
                let v = this.$refs.fzTree.getCheckedNodes();
                if(v.length === 0){
                    message = '请选择分组';
                }else{
                    v.map((v,k) => {
                        ids.push(v.id);
                        names.push(v.groupName);
                    });
                }
            }else if(this.form.customerType === 3){
                let v = this.$refs.qyTree.getCheckedNodes()
                if(v.length === 0){
                    message = '请选择区域';
                }else{
                    v.map((v,k) => {
                        ids.push(v.id);
                        names.push(v.districtName);
                    });
                }
            }else if(this.form.staffType === 2){
                let v = this.$refs.bmTree.getCheckedNodes();
                if(v.length === 0){
                    message = '请选择部门';
                }else{
                    v.map((v,k) => {
                        ids.push(v.id);
                        names.push(v.text);
                    });
                }
            }else if(this.form.staffType === 3){
                let v = this.$refs.jsTree.getCheckedNodes();
                if(v.length === 0){
                    message = '请选择角色';
                }else{
                    v.map((v,k) => {
                        ids.push(v.id);
                        names.push(v.text);
                    });
                }
            }else if(this.form.customerType === 4 || this.form.staffType === 4){
                let v = form.userNameOrPhone
                if(!v){
                    message = '请输入特定客户';
                }else{
                    text = v;
                }
            }
            if(message){
                this.$message.warning(message);
            }else{
                if(ids.length > 0){
                    return {ids : ids.join(',') , names : names.join(',')}
                }else{
                    return {};
                }
            }
        },
        // 重置页面 数据
        reload : function(){
            this.$refs.form.resetFields();
            this.form.receiveObj = 2;
            this.form.customerType = 1;
            this.form.staffType = 1;
            this.form.userNameOrPhone = '';
            this.form.messageContent = '';
            this.form.sendType = 1;
            this.form.timer = '';
        },
        // 接收对象 change
        handleReceiveObj : function(){
            this.form.customerType = 1;
            this.form.staffType = 1;
            this.form.userNameOrPhone = '';
        },
        // 客户类型 change
        handleCustomer : function(val){
            this.form.userNameOrPhone = '';
        },
        // 员工类似 change 
        handleStaff : function(){
            this.form.userNameOrPhone = '';
        },
        // 发送时间 change
        handleSend : function(val){
            if(val === 2){
                this.form.timer = + new Date();
            }
        },
        renderContent : function(h, { node, data, store }){
            return (
                <span class="mlist">
                    <span title={data.districtName} class="mtext">
                        {data.districtName}
                    </span>
                </span>
            )
        },
        renderContent2 : function(h, { node, data, store }){
            return (
                <span class="mlist">
                    <span title={data.text} class="mtext">
                        {data.text}
                    </span>
                </span>
            )
        },
    },
}
</script>

<style lang="less">

.smsPushBox {
    .treeBox{
        width: 360px;
        max-height: 240px;
        padding: 10px 0;
        overflow-y: auto;
        border: 1px solid #d1dbe5;
        margin-top: 10px;
        .el-tree{
            border: none;
        }
    }
    /* 区域树 样式 部门树 样式 */
    .qyTreeBox{
        overflow: hidden;
        /* 暂无数据 样式 */
        .el-tree__empty-text{
            position: initial;
        }
        .mlist{
        display: inline-block;
            .mtext{
                display: inline-block;
                max-width: 100px;
                overflow:hidden;
                text-overflow:ellipsis;
                white-space:nowrap
            }
        }
        /* 重写 tree样式 */
        .el-tree{
            float: left;
        }
        .el-tree-node__content{
            /* min-width: 230px; */
        }
        .el-tree-node__content>.el-checkbox{
            vertical-align: middle;
            display: inline-block;
            margin-top: -10px;
        }
        .el-tree-node__content:hover{
            background : initial;
        }
        .el-tree-node__expand-icon{
            display: inline-block;
            margin-top: -26px;
        }
        .el-tree-node__content .handle{
            opacity: 0;
            display: inline-block;
            margin-bottom: 28px;
            vertical-align: middle;
        }
        .el-tree-node__content:hover .handle{
            opacity: 1;
        }
    }
}

</style>

