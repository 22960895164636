<template>
    <div class="brandTitleBox"> 
        <div class="title-tabs">
            <ul class="clearfix">
                <template v-for="(item,index) in list">
                    <div class="el-badge item" :key="index+'ao'">
                        <li @click="handleClick(item)" :class="{active : item.active}">
                            <span>{{item.text}}</span>
                            <div :class="{'under-line' : item.active}"></div>
                        </li>
                    </div>
                    <div class="vertical-line" v-if="index !== list.length - 1" :key="index + 1+'ap'"></div>
                </template>
            </ul>
        </div>
    </div>
</template>

<script>


export default {
    props : {
        list : {
            type : Array,
        }
    },
    data: function() {
        return ({
        })
    },
    mounted: function() {
        
    },
    methods: {
        handleClick(item) {
            if(item.url){
                this.$router.push(item.url);
            }
            this.$emit('handleClick',item);
        },
    },
}
</script>

<style scoped lang="less">

.brandTitleBox {
    .clearfix{
        padding: 0;
    }
    .title-tabs .under-line{
        top: 46px;
    }
}

</style>

