<template>
    <div class="hotel-detail-box bg-f">
        <div class="m-content">
            <div class="m-title">折扣维护-{{type === 1 ? '折扣维护' : '详情'}}</div>
            <div class="m-detail">
                <div class="m-item">
                    <div class="m-item-label">客户名称：</div>
                    <div class="m-item-content">{{customerName}}</div>
                </div>
                <div class="m-item">
                    <div class="m-item-label">折扣生效日期：</div>
                    <div class="m-item-content">
                        <div v-if="status==0">

                        </div>
                        <div v-else>
                            <span>{{timeStart | timenData}}</span>
                            <span> - </span>
                            <span v-if="!adjustDateVisible">{{timeEnd | timenData}}</span>
                            <el-date-picker v-else v-model="timeEndDefault" placeholder="结束时间" :picker-options="pickerOptions" />
                            <el-button v-if="adjustDateVisibleBtn && type === 1" @click="handleAdjustDate" class="red ml-10">调整日期</el-button>
                            <el-button class="ml-10" v-if="!adjustDateVisibleBtn" @click="handleAdjustDateOk" type="primary">确定</el-button>
                            <el-button v-if="!adjustDateVisibleBtn" @click="handleAdjustDateClose">取消</el-button>
                        </div>
                    </div>
                </div>
                <div class="m-item">
                    <div class="m-item-label">状态：</div>
                    <div class="m-item-content red">{{state}}</div>
                </div>
            </div>
            <div class="m-goods-table">
                <el-table :data="tableListGoods" tooltip-effect="dark" stripe class="mt-20 table-default">
                    <el-table-column prop="skuName" label="商品名称" min-width="100"></el-table-column>
                    <el-table-column label="规格型号" min-width="70">
                        <template slot-scope="scope">
                             <span>1 * {{scope.row.specInfoNum}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="promotionPrice" label="会员价(元)" min-width="80">
                        <template slot-scope="scope">
                             <div>
                                 <p>
                                     <span>{{scope.row.unitPromotionPrice | fmoney}}/{{scope.row.unitsName}}</span>
                                 </p>
                                  <p>
                                     <span>{{scope.row.promotionPrice | fmoney}}/{{scope.row. sellingUnitName}}</span>
                                 </p>
                             </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="折扣/返利" min-width="100">
                        <template slot-scope="scope">
                            <!-- {{scope.row.discountType === 1 ? '折扣优惠：' : '固定返利：'}}{{scope.row.discountValue}}
                            <span v-if="scope.row.discountType !== 1">/{{scope.row.showUnitName}}</span> -->
                            <div>
                                <p v-if="scope.row.discountType === 1">
                                    <span>折扣优惠：</span>
                                    <span>{{scope.row.unitDiscountValue}}</span>
                                </p>
                                <p v-else>
                                    <span>固定返利：</span>
                                     <br>
                                    <span>¥{{scope.row.unitDiscountValue}}/{{ scope.row.unitsName }}</span>
                                    <br>
                                    <span>¥{{scope.row.discountValue}}/{{ scope.row. sellingUnitName }}</span>
                                </p>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="capFee" label="瓶盖费(元)" width="180"></el-table-column>
                </el-table>
                <!-- 备注 -->
                <div class="mt-20 color-9">备注: 常规商品{{generalGoodsnum}}个，套餐商品{{packageGoodsnum}}个</div>
                <!-- 分页 -->
                <div class="pageBox">
                    <el-pagination class="tar mt-20" v-if='total>0'
                        :current-page="currentPage"
                        :page-size='10'
                        :total='total'
                        layout="prev, pager, next, jumper"
                        @current-change="handlePageChange">
                    </el-pagination>
                </div>
            </div>
            <!-- 操作记录 -->
            <div class="m-operation-record">
                <div class="m-item mt-20">
                    <div class="m-item-label">操作记录</div>
                </div>
                <div class="m-record-table">
                    <el-table :data="tableListRecord" tooltip-effect="dark" stripe class="mt-20 table-default">
                        <el-table-column label="编辑日期">
                            <template slot-scope="scope">
                                {{scope.row.operatorTime | timenData}}
                            </template>
                        </el-table-column>
                        <el-table-column prop="operatorName" label="编辑人员"></el-table-column>
                    </el-table>
                    <!-- 分页 -->
                    <div class="pageBox">
                        <el-pagination class="tar mt-20" v-if='totalRecord>0'
                            :current-page="currentPageRecord"
                            :page-size='10'
                            :total='totalRecord'
                            layout="prev, pager, next, jumper"
                            @current-change="handlePageChange2">
                        </el-pagination>
                    </div>
                </div>
            </div>
            <affix ref="affix" z-index="10" :offsetBottom="0">
                <div class="mt-20 bg-e m-submit-box" style="text-align:center;">
                    <el-button v-if="type === 1" class="m-submit" @click="handleSubmit" type="primary">提交</el-button>
                    <el-button @click="handleGoHome">返回</el-button>
                </div>
            </affix>
        </div>
    </div>
</template>

<script>
    import {mapMutations, mapActions} from 'vuex';
    import { axiosApi, basePath } from "../../api/api";
    import affix from './affix.vue';

    export default {
        components : {
            affix,
        },
        data() {
            let _this = this;
            return({
                status:null,
                customerName : '大白兔酒店', // 客户名称
                timeStart : '2012-11-11', // 开始时间
                timeEnd : '2012-11-12', // 结束时间
                timeEndDefault : '2012-11-12',
                type : '', //  1 到期调整 2 详情
                state : '待编辑', // 状态
                adjustDateVisible : false, // 调整日期开关
                adjustDateVisibleBtn : true, // 调整日期 按钮开关
                total : 0,
                currentPage : 1,
                totalRecord : 11,
                currentPageRecord : 1,
                tableListGoods : [],
                tableListRecord : [],
                // 日期 禁用状态
                pickerOptions : {
                    disabledDate(time) {
                        return ( time.getTime() < + new Date(_this.timeStart) ) || ( time.getTime() < + new Date() );
                    }
                },
                generalGoodsnum: 0,// 普通商品数量
                packageGoodsnum: 0,// 套餐商品数量
            });
        },
        mounted() {
            this.$nextTick(() => {
                // 如果当前是页面 详情 or 到期调整
                if(this.$route.query.type === '2'){
                    this.type = 2;
                }else{
                    this.type = 1;
                }
                // 初始化数据
                axiosApi('/goods/hotelselleragreement/select',{
                    id : this.$route.query.id,
                },(res) => {
                    let data = res.data.data;
                    if(data){
                    	this.status = data.status;
                        this.state = data.statusName;
                        this.customerName = data.buyerStoreName;
                        this.timeStart = data.startDate;
                        this.timeEnd = data.endDate;
                        this.timeEndDefault = data.endDate;
                        this.generalGoodsnum = data.conventionalQuantity || 0// 普通商品数量
                        this.packageGoodsnum = data.setMealQuantity || 0// 套餐商品数量
                        // 获取商品数据
                        this.initTableListGoods();
                        // 获取操作记录
                        this.initTableListRecord();
                    }
                });
            });
        },
        methods : {
            // 初始化 商品table
            initTableListGoods() {
                axiosApi('/goods/hotelagreementgoods/selectPageList',{
                    agreementId : this.$route.query.id,
                    buyerId:this.$route.query.buyerId
                },(res) => {
                    let data = res.data.data;
                    console.log(data,'商品数据');
                    if(data){
                        this.tableListGoods = data.elements;
                        this.total = data.totalCount;
                        this.$nextTick(() => {
                            // 重置 affix位置
                            this.$refs.affix.initScroll();
                        });
                    }
                },this.currentPage,10);
            },
            // 初始化 操作记录table
            initTableListRecord() {
                axiosApi('/goods/hotelselleroperate/selectList',{
                    agreementId : this.$route.query.id,
                },(res) => {
                    let data = res.data.data;
                    console.log(data,'操作记录');
                    if(data){
                        this.tableListRecord = data.elements;
                        this.totalRecord = data.totalCount;
                        this.$nextTick(() => {
                            // 重置 affix位置
                            this.$refs.affix.initScroll();
                        });
                    }
                },this.currentPageRecord,10);
            },
            // 商品分页 change
            handlePageChange(val) {
                this.currentPage = val;
                this.initTableListGoods();
            },
            // 操作记录 change
            handlePageChange2(val) {
                this.currentPageRecord = val;
                this.initTableListRecord();
            },
            // 点击提交
            handleSubmit() {
                if(!this.adjustDateVisibleBtn){
                    this.$message.warning('请保存折扣结束日期');
                    return;
                }
                if(!this.timeEnd){
                    this.$message.warning('请选择折扣结束日期');
                    return;
                }
                if(new Date(this.timeStart) > new Date(this.timeEnd)){
                    this.$message.warning('折扣开始日期不能大于结束日期');
                    return;
                }
                axiosApi('/goods/hotelselleragreement/updateEndDate',{
                    id : this.$route.query.id,
                    endDate : this.timeEnd,
                },(res) => {
                    let data = res.data.data;
                    this.$message.success('修改成功');
                    setTimeout(() => {
                        this.handleGoHome();
                    },1500);
                },1,10,res=>{
                		this.$message.error(res.data.message)
                });
            },
            // 点击返回
            handleGoHome() {
            	window.history.go(-1)
                // this.$router.push(this.basePath + '/hotelHome');
            },
            // 点击调整日期
            handleAdjustDate() {
                this.adjustDateVisible = true;
                this.adjustDateVisibleBtn = false;
            },
            // 调整日期 确定
            handleAdjustDateOk() {
            		if(!this.timeEndDefault){
            			this.timeEndDefault = this.timeEnd
            		}
                let myDate = new Date(this.timeEndDefault);
                let year = myDate.getFullYear();
                let month = myDate.getMonth() + 1 < 10 ? '0' + (myDate.getMonth() + 1) : myDate.getMonth() + 1;
                let day = myDate.getDate() < 10 ? '0' + myDate.getDate() : myDate.getDate();
                this.timeEnd = year + '-' + month + '-' + day;
                this.adjustDateClose();
            },
            // 调整日期 取消
            handleAdjustDateClose() {
                this.timeEndDefault = this.timeEnd;
                this.adjustDateClose();
            },
            // 调整日期 取消逻辑
            adjustDateClose() {
                this.adjustDateVisible = false;
                this.adjustDateVisibleBtn = true;
            },
        },
    }
</script>

<style lang="less" scoped>
    .hotel-detail-box{
        .red{
            color: #ff4201;
        }
        .width-120{
            width: 120px;
        }
        .m-inline{
            display: inline-block;
        }
        .m-content{
            margin-top: 20px;
            border: 1px solid #ccc;
            padding: 20px 40px 50px 40px;
            .m-title{
                height: 30px;
                border-bottom: 1px solid #ccc;
            }
            .m-detail{
                padding-top: 20px;
                padding-bottom: 20px;
                border-bottom: 1px solid #ccc;
                .m-item{
                    margin-bottom: 20px;
                    .m-item-label{
                        display: inline-block;
                        width: 120px;
                        text-align: right;
                    }
                    .m-item-content{
                        display: inline-block;

                    }
                }
            }
            .m-goods-table{
                /* 商品名称 */
                .m-goods-table-goods-name{
                    overflow: hidden;
                    .m-left{
                        float: left;
                        text-align: left;
                        margin-right: 10px;
                        margin-left: 10px;
                        img{
                            width: 80px;
                            height: 80px;
                        }
                    }
                    .m-right{
                        float: left;
                        width: 190px;
                        text-align: left;
                    }
                }
            }
            .m-submit-box{
                height: 40px;
                line-height: 40px;
                line-height: 40px;
                text-align: center;
                .m-submit{
                    margin-right: 30px;
                }
            }
        }
        .m-operation-record{
            .m-item{
                border-bottom: 1px solid #ccc;
                padding-bottom: 20px;
                .m-item-label{
                    display: inline-block;
                    width: 120px;
                    text-align: right;
                }
            }
            .m-record-table{

            }
        }
    }
</style>

<style lang="less">
    .hotel-detail-box{
        /* table title 星号 */
        .is-required:before{
            content: "*";
            color: #ff4949;
            margin-right: 4px;
        }
        /* table 内容 星号 去除 */
        .el-table__row .is-required:before{
            display: none;
        }
        /* error border 优先级不够问题 */
        .el-form-item.is-error .el-input__inner{
            border-color: #ff4949!important;
        }
        /* error 文案删除 */
        .el-form-item__error{
            display: none;
        }
        .el-form-item{
            margin-bottom: 0;
        }
        textarea{
            resize: none;
        }
        .el-table__body-wrapper{
            overflow: visible;
        }
        .el-table__empty-block{
            height: 166px;
        }
    }
</style>
