<template>
  <!--门店老板-》佣金明细-->
  <div id="commDetails">
    <!-- <div class="title-top bg-f">佣金明细</div> -->
    <div class="content bg-f">
      <div class="clearfix search">
        <el-form :inline="true" class="pt-30 fl" :model="searchForm" ref="searchForm">
          <el-form-item
            class="fl"
            label="账号"
            :rules="[{pattern:/^[1]\d{10}$/,message:'请输入正确账号',trigger:'blur'}]"
            prop="cNum"
          >
            <el-input :maxlength="11" v-model.trim="searchForm.loginName" placeholder="请输入账号"></el-input>
          </el-form-item>
          <el-form-item
            class="ml-20"
            label="姓名"
            :rules="[{pattern:/^[\u4E00-\u9FA5A-Za-z0-9]+$/,message:'请输入正确的姓名',trigger:'blur'}]"
            prop="cName"
          >
            <el-input :maxlength="11" v-model.trim="searchForm.cName" placeholder="请输入姓名"></el-input>
          </el-form-item>
        </el-form>
        <el-button class="fl ml-30 mt-30" type="info" @click="search">搜索</el-button>
      </div>
      <div class="pb-30" v-loading="loading">
        <!--<el-button type="text" class="fr" @click="exportExcel">全部导出</el-button>-->
        <el-table
          :data="elements"
          stripe
          style="width: 100%;"
          @sort-change="changeSort"
          :empty-text="textShow"
        >
          <el-table-column label="账号" width>
            <template slot-scope="scope">
              <span>{{scope.row.loginName}}</span>
            </template>
          </el-table-column>
          <el-table-column label="姓名" width>
            <template slot-scope="scope">
              <span>{{scope.row.name}}</span>
            </template>
          </el-table-column>
          <el-table-column label="可结佣金" width sortable="custom">
            <template slot-scope="scope">
              <span>{{scope.row.withdrawCommission | fmoney}}</span>
            </template>
          </el-table-column>
          <el-table-column label="历史收入" width sortable="custom">
            <template slot-scope="scope">
              <span>{{scope.row.allCommissionIncome | fmoney}}</span>
            </template>
          </el-table-column>
          <el-table-column label="待结算佣金" width sortable="custom">
            <template slot-scope="scope">
              <span>{{scope.row.unbalancedCommission | fmoney}}</span>
            </template>
          </el-table-column>
          <el-table-column label="已提佣金" width sortable="custom">
            <template slot-scope="scope">
              <span>{{scope.row.paymentCommission | fmoney}}</span>
            </template>
          </el-table-column>

          <el-table-column label="操作" width>
            <template slot-scope="scope">
              <el-button type="text" @click="handleShow(scope.row)">查看</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          v-if="elements.length>0"
          class="tar mt-20"
          :current-page="currentPage"
          :page-size="10"
          :total="totalCount"
          layout="prev , pager, next, jumper"
          @current-change="handleCurrentChange"
        >
          <!-- 分页组件 -->
        </el-pagination>
      </div>
      <!-- 佣金记录弹窗 -->
      <el-dialog title="佣金记录" class="show" :visible.sync="showList" width="1100px">
        <el-table
          v-if="commData.length>0"
          :data="commData"
          stripe
          style="width:90%;margin:0 5%;"
          :show-header="false"
          @expand-change="expand"
          v-loading="loading1"
        >
          <el-table-column label min-width="130">
            <template slot-scope="scope">
              <div>{{scope.row.operateTime | timeI}}</div>
            </template>
          </el-table-column>
          <el-table-column label min-width="250">
            <template slot-scope="scope">
              <div class="goods">
                <div class="goods-tit">{{scope.row.operateDesc}}</div>
                <div>{{scope.row.operateNoDesc}}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label min-width="80">
            <template slot-scope="scope">
              <span>{{scope.row.operateMoney < 0 ? ' ':'+'}}{{scope.row.operateMoney}}</span>
            </template>
          </el-table-column>
          <el-table-column label min-width="99">
            <template slot-scope="scope">
              <span>
                可提佣金：
                <br />
                {{scope.row.withdrawCommission}}
              </span>
            </template>
          </el-table-column>
          <el-table-column label min-width="99">
            <template slot-scope="scope">
              <span>
                佣金余额：
                <br />
                {{scope.row.balanceCommission}}
              </span>
            </template>
          </el-table-column>
          <el-table-column label min-width="90">
            <template slot-scope="scope">
              <div>{{scope.row.typeNote}}</div>
            </template>
          </el-table-column>
          <el-table-column label min-width="120">
            <template slot-scope="scope">
              <span>{{scope.row.remark}}</span>
            </template>
          </el-table-column>
          <el-table-column label type="expand" min-width="100">
            <template slot-scope="props">
              <div v-if="props.row.type !== 0" v-loading="loading2">
                <el-table :data="props.row.applyData" stripe style="width:100%;">
                  <el-table-column label="申请单号">
                    <template slot-scope="scope">
                      <div>{{scope.row.applyWithdrawSn}}</div>
                    </template>
                  </el-table-column>
                  <el-table-column label="收款渠道">
                    <template slot-scope="scope">
                      <div>{{scope.row.accountName}}</div>
                      <div>({{scope.row.name}} {{scope.row.accountNumber}})</div>
                    </template>
                  </el-table-column>
                  <el-table-column label="提现金额">
                    <template slot-scope="scope">
                      <span>{{scope.row.withdrawMoney | fmoney}}</span>
                    </template>
                  </el-table-column>
                  <el-table-column label="提现状态" v-if="props.row.type !== 2">
                    <template slot-scope="scope">
                      <!-- handle-处理中；fail-失败；success-成功-->
                      <el-tooltip
                        v-if="scope.row.withdrawState === 'fail'"
                        class="item"
                        effect="dark"
                        :content="scope.row.failReason"
                        placement="bottom-end"
                      >
                        <el-button type="text">失败</el-button>
                      </el-tooltip>
                      <span
                        v-else
                      >{{scope.row.withdrawState === 'handle' ? '处理中' : scope.row.withdrawState === 'success' ? '成功' : ''}}</span>
                    </template>
                  </el-table-column>
                  <el-table-column label="申请时间">
                    <template slot-scope="scope">
                      <span>{{scope.row.gmtCreate | time}}</span>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
              <div class="order-list" v-if="props.row.type === 0" v-loading="loading2">
                <el-table>
                  <el-table-column label="商品" min-width="160" align="center"></el-table-column>
                  <el-table-column label="单价" min-width="80"></el-table-column>
                  <el-table-column label="数量" min-width="80"></el-table-column>
                  <el-table-column label="订单金额" min-width="80"></el-table-column>
                  <el-table-column label="销售佣金" min-width="80"></el-table-column>
                  <el-table-column label="买家" min-width="80"></el-table-column>
                  <el-table-column label="手机号码" min-width="80"></el-table-column>
                  <el-table-column label="佣金到账时间" min-width="80"></el-table-column>
                  <div slot="empty" class="emptyBg"></div>
                </el-table>
                <div>
                  <div class="table-item mt-10">
                    <div class="clearfix table-item-top lh-20 bbd bg-e pl-20">
                      <span class="fl">订单编号：{{props.row.orderSn}}</span>
                      <span
                        class="fl color-6 ml-30"
                        v-if="props.row.orderData.gmtCreate"
                      >下单时间：{{props.row.orderData.gmtCreate | time}}</span>
                    </div>
                    <div class="table-item-content clearfix bbd" style="display: flex;">
                      <div class="clearfix" style="box-sizing: border-box;flex:4">
                        <!--商品清单-->
                        <div
                          class="bb clearfix"
                          :key="index"
                          style="padding: 20px 0 10px 10px;display:flex"
                          v-for="(orderItem, index) in props.row.orderData.itemList"
                        >
                          <div class="fl" style="flex:2">
                            <img v-if="orderItem.fileUrl" :src="orderItem.fileUrl" class="fl mr-20" />
                            <img v-else src="../../../assets/goods.jpg" class="fl mr-20" />
                            <p class="bw">{{orderItem.skuName}}</p>
                            <p class="color-9">规格:1*{{orderItem.specInfoNum}}</p>
                          </div>
                          <div class="fl tac" style="flex:1">¥{{orderItem.originPrice | fmoney}}</div>
                          <div class="fl tac" style="flex:1">{{orderItem.num}}{{orderItem.unit}}</div>
                        </div>
                      </div>
                      <div
                        class="bl bb tac pt-20"
                        style="flex:1;word-break: break-all"
                      >{{props.row.orderData.needPayMoney | fmoney}}</div>
                      <div
                        class="bl bb tac pt-20"
                        style="flex:1;word-break: break-all"
                      >{{props.row.orderData.commission | fmoney}}</div>
                      <div
                        class="bl bb tac pt-20"
                        style="flex:1;word-break: break-all"
                      >{{props.row.orderData.addrUsername}}</div>
                      <div class="bl bb tac pt-20" style="flex:1;">{{props.row.orderData.addrTel}}</div>
                      <div class="bl bb tac pt-20" style="flex:1;">
                        <div
                          class="tac"
                          v-if="props.row.orderData.commissionSettlementTime"
                        >{{props.row.orderData.commissionSettlementTime | timeI}}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          v-if="commData.length>0"
          class="tar mt-20"
          :current-page="currentPage1"
          :page-size="6"
          :total="totalCount1"
          layout="prev , pager, next, jumper"
          @current-change="handleCurrentChange1"
        >
          <!-- 分页组件 -->
        </el-pagination>
        <div v-else class="text-none">该用户暂时没有佣金记录</div>
      </el-dialog>
    </div>
    <!--导出-->
    <!--<form ref="exportList" action='/sunrise-gateway/export/superc/store' style="display: none">
			<input type="text" name="startRow" v-model="a">
			<input type="text" name="startCol" v-model="a">
			<input type="text" name="fileName" v-model="fileName">
			<input type="text" name="modelName" v-model="modelName">
    </form>-->
  </div>
</template>

<script>
import { axiosApi, basePath } from "../../../api/api";
export default {
  data() {
    return {
      a: 0, //导出数据入参
      fileName: "佣金提现记录.xls", //导出数据入参
      modelName: "", //导出数据入参
      searchForm: {
        loginName: null, //账号
        cName: null, //名称
        orderColumn: null, //排序类型
        orderType: null //升降序
      },
      loading: false,
      loading1: false,
      loading2: false,
      currentPage: 1,
      totalCount: 10,
      currentPage1: 1,
      totalCount1: 10,
      elements: [], //列表数据
      commData: [],
      showList: false, //弹出框
      supercStoreId: null,
      textShow: "暂无数据", //提示语
      index: null,
      curRow: null
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.getCommList(); //加载默认列表
    });
  },
  methods: {
    getCommList() {
      //久集佣金列表
      this.loading = true;
      axiosApi(
        "/superc/store/commission/select",
        {
          loginName: this.searchForm.loginName,
          name: this.searchForm.cName,
          orderColumn: this.searchForm.orderColumn,
          orderType: this.searchForm.orderType,
          supercType: 3
        },
        res => {
          this.loading = false;
          this.totalCount = res.data.data.totalCount;
          this.elements = res.data.data.elements ? res.data.data.elements : [];
        },
        this.currentPage,
        10,
        res => {
          this.loading = false;
        }
      );
    },
    handleCurrentChange(val) {
      //分页
      this.currentPage = val;
      this.getCommList();
    },
    handleCurrentChange1(val) {
      //弹窗分页
      this.currentPage1 = val;
      this.getCommDetaile();
    },
    changeSort(val) {
      //排序
      let type = null;
      switch (val.column.label) {
        case "历史收入":
          type = "allCommissionIncome";
          break;
        case "可结佣金":
          type = "withdrawCommission";
          break;
        case "待结算佣金":
          type = "unbalancedCommission";
          break;
        case "已提佣金":
          type = "paymentCommission";
          break;
      }
      this.searchForm.orderColumn = type;
      if (val.order == "ascending") {
        //升序
        this.searchForm.orderType = 1;
      } else if (val.order == "descending") {
        //降序
        this.searchForm.orderType = 2;
      }
      this.getCommList(); //排序执行默认接口
    },
    search() {
      //搜索
      this.$refs.searchForm.validate(valid => {
        if (valid) {
          this.currentPage = 1;
          this.getCommList(); //搜索执行默认接口
        }
      });
    },
    getCommDetaile() {
      //佣金明细列表
      this.loading1 = true;
      axiosApi(
        "/superc/commission/detail/select",
        {
          supercStoreId: this.curRow.supercStoreId,
          financialCommissionId: this.curRow.id
        },
        res => {
          this.loading1 = false;
          this.totalCount1 = res.data.data.totalCount;
          this.commData = res.data.data.elements ? res.data.data.elements : [];
        },
        this.currentPage1,
        6,
        res => {}
      );
    },
    handleShow(val) {
      //弹出框传值
      this.showList = true;
      this.curRow = val;
      this.supercStoreId = val.supercStoreId;
      this.getCommDetaile();
    },
    getArrivalDetail(val) {
      //到账明细
      this.loading2 = true;
      val.orderData = {
        itemList: []
      };
      axiosApi(
        "/superc/order/details/selectByOrderSn",
        { orderSn: val.orderSn },
        res => {
          this.loading2 = false;
          val.orderData = res.data.data
            ? res.data.data
            : {
                itemList: []
              };
        },
        this.currentPage,
        10,
        res => {
          this.loading2 = false;
        }
      );
    },
    getApplyDetail(val) {
      //提现明细
      this.loading2 = true;
      val.applyData = [];
      axiosApi(
        "/finance/commission/selectCommissionWithdrawById",
        { id: val.withdrawApplyId },
        res => {
          this.loading2 = false;
          val.applyData.push(res.data.data);
        },
        this.currentPage,
        10,
        res => {
          this.loading2 = false;
        }
      );
    },
    expand(val) {
      //展开触发事件
      if (val.type == "0") {
        this.getArrivalDetail(val); //加载订单详情
      } else {
        this.getApplyDetail(val); //加载提现详情
      }
    },
    exportExcel() {
      //导出
      setTimeout(() => {
        if (this.elements.length > 0) {
          //						this.$refs.exportList.submit()
        }
      }, 0);
    }
  }
};
</script>

<style lang="less">
#commDetails {
  .emptyBg {
    display: none;
  }
  .content {
    padding: 0px 30px 20px 20px;
    .el-table .cell {
      text-align: center;
    }
  }
  .bw {
    word-wrap: break-word;
  }
  .show {
    .el-pagination {
      padding-right: 45px;
    }
    .el-dialog {
      width: 1180px;
      .el-dialog__body {
        padding: 30px 0 50px 0;
        max-height: 600px;
        overflow-y: auto;
        .goods {
          div {
            text-align: left;
          }
          .goods-tit {
            width: 270px;
            /*超出部分显示...*/
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            /* autoprefixer: off*/
            -webkit-box-orient: vertical;
            /* autoprefixer: on*/
          }
        }
        .el-table__expanded-cell {
          padding: 10px 5px;
        }
        .el-table__expand-column .cell:before {
          content: "详细";
          position: absolute;
          left: -8px;
          top: 15px;
          color: #20a0ff;
        }
        .el-table__expand-icon {
          -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
        }
        .el-table__expand-icon--expanded {
          -webkit-transform: rotate(270deg);
          transform: rotate(270deg);
        }
        .el-table__expand-icon > .el-icon {
          color: #20a0ff;
        }
        .el-table__empty-block {
          min-height: 0;
        }
        .table-item {
          border: 1px solid #dddddd;
          font-size: 14px;
          border-bottom: none;
          .bl {
            border-left: 1px solid #ddd;
          }
          .table-item-content .pbl-20 {
            padding: 0px 0px 20px 20px;
          }
          .table-item-top {
            padding: 10px 0;
          }
          img {
            width: 80px;
            height: 80px;
          }
        }
      }
      .text-none {
        text-align: center;
        margin-top: 140px;
      }
    }
  }
}
</style>
