<template>
	<div id="transaction-record">
		<div class="clearfix">
			<el-form :inline='true' :model="formInline" class="form-search fl">
				<el-form-item label="业务大类">
					<el-select v-model="formInline.tradeTypeId" placeholder="请选择" clearable>
						<el-option v-for="tradeTypeItem in tradeType" :key="tradeTypeItem.value" :label="tradeTypeItem.label" :value="tradeTypeItem.value">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="业务小类">
					<el-select v-model="formInline.busiTypeId" placeholder="请选择" clearable>
						<el-option v-for="zy in selection" :key="zy.value" :label="zy.label"  :value="zy.value">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="交易时间">
					<ys-daterange-picker v-model="formInline.time"/>
				</el-form-item>
			</el-form>
			<el-button class="fl" type="info" @click="handleSearch">查 询</el-button>
		</div>
		<div class="clearfix">
			<el-tabs v-if="isTabShow" v-model="fundsType" @tab-click="handleTabClick" class="bbd ctabs mb-10">
				<el-tab-pane label="所有明细" name="0"></el-tab-pane>
				<el-tab-pane label="收入明细" name="1"></el-tab-pane>
				<el-tab-pane label="支出明细" name="2"></el-tab-pane>
			</el-tabs>
			<el-table :data="paymentdata" :class="{'table-body-hidden':paymentdata.length==0}" stripe>
				<el-table-column label="流水号" prop="fundsId" min-width="100"></el-table-column>
				<el-table-column label="操作时间" prop="createdTime" min-width="150">
					<template slot-scope="scope">
						{{scope.row.createdTime | time}}
					</template>
				</el-table-column>
				<el-table-column label="收入(元)"  min-width="60">
					<template slot-scope="scope">
						{{scope.row.incomeAmount | fmoney}}
					</template>
				</el-table-column>
				<el-table-column label="支出(元)"  min-width="60">
					<template slot-scope="scope">
						{{scope.row.payoutAmount | fmoney}}
					</template>
				</el-table-column>
				<el-table-column label="账户余额(元)" min-width="60">
					<template slot-scope="scope">
						{{scope.row.balance | fmoney}}
					</template>
				</el-table-column>
				<el-table-column label="业务大类" min-width="100">
					<template slot-scope="scope">
						<el-button @click="orderDetail(scope.row, scope.row.outOrderNo)" v-if="scope.row.tradeTypeId==30 && !scope.row.orderNo.startsWith('JJ03') && !scope.row.orderNo.startsWith('JJ01') && !scope.row.orderNo.startsWith('JJ02') && !scope.row.orderNo.startsWith('CT')" class="transactiactive-text-btn" type="text">{{scope.row.fundsName}}</el-button>
						<span v-else class="inline-block h32">{{scope.row.fundsName}}</span>
					</template>
				</el-table-column>
				<el-table-column label="业务小类" show-overflow-tooltip min-width="100">
					<template slot-scope="scope">
						{{scope.row.busiTypeName}}
					</template>
				</el-table-column>
				<el-table-column label="备注" prop="remark" show-overflow-tooltip>
					<template slot-scope="scope">
						{{scope.row.fundsDesc}}
					</template>
				</el-table-column>
			</el-table>
			<el-pagination v-if='total>0' class="tar" :current-page="currentPage" :page-size='10' :total='total' layout="prev , pager, next, jumper" @current-change="handleCurrentChange">
			</el-pagination>
			<div class="tac mt-30" v-if="paymentdata.length==0">
				<img src="../../../assets/empty.png" alt="" />
				<div class="color-9 mt-30">
					<span>暂无记录</span>
				</div>
			</div>

		</div>
	</div>
</template>

<script>
	import { axiosApi, basePath } from '../../../api/api'
	import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
	import YsDaterangePicker from "~/components/ys-daterange-picker";
	export default {
		components: {YsDaterangePicker},
		data() {
			return {}
		},
		mounted() {
			this.$nextTick(() => {
				if(this.fundsType == '0') {
					this.formInline.fundsType = null
				}
				this.clearForm()
				this.paymentQuery()
				this.initTimeRange()
			})
		},
		computed: {
			...mapGetters([
				'selection', 'tradeType', 'formInline', 'paymentdata', 'total', 'currentPage', 'fundsType', 'isTabShow'
			])
		},
		methods: {
			...mapActions({
				'paymentQuery': 'paymentQuery'
			}),
			...mapMutations([
				'currentpageChange',
				'tabChange',
				'search',
				'clearForm',
				'goOrderDetail',
				'initTimeRange'
			]),
			handleSearch() { //查询
				this.search()
				this.paymentQuery()
			},
			handleCurrentChange(val) { //分页change
				this.currentpageChange(val)
				this.paymentQuery()
			},
			handleTabClick(val) {
				this.tabChange(val.name)
				this.paymentQuery()
			},
			orderDetail(row, val){
        this.goOrderDetail(val)
		// 门店久集订单详情-要增加销售员佣金，需要存储特殊标识
		//C端订单暂时不跳转
        // if (row.orderNo.startsWith('JJ03')) {
        //   this.$router.push({path: basePath + '/purchaseOrderDetail', query: {showSalesCommission: 1}})
        // } else {
          this.$router.push({path: basePath + '/purchaseOrderDetail'})
        // }
			}
		}
	}
</script>

<style lang="less">
	#transaction-record {
		box-sizing: border-box;
		margin: 0 auto;
		padding: 50px 0;
		/*表单查询*/
		.form-search {
			width: 660px;
			.el-form-item {
				margin-right: 30px;
			}
			.el-input__inner {
				font-size: 12px;
			}
			.el-form-item__label {
				padding: 0 10px 0 0;
				width: 57px;
				font-size: 12px;
				color: #333333;
				letter-spacing: -0.29px;
			}
			/*日期控件*/
			.el-date-editor--daterange.el-input {
				width: 240px;
			}
		}
		.ctabs {
			.el-tabs__header {
				padding-left: 0px;
			}
			.el-tabs__item {
				font-size: 12px;
			}
		}
		.el-table {
			th>.cell {
				font-weight: normal;
				color: #333333;
			}
			.transactiactive-text-btn {
				font-size: 12px;width: auto;
			}
		}
		.table-body-hidden {
			.el-table__empty-block {
				display: none;
			}
		}
		.h32{
			height: 32px;line-height: 32px;
		}
	}
</style>