<template>
	<div id="article" class="">
		<div class="title-top bg-f">
			葵花宝典
				<el-button v-if='showButton("ADD",200015)' class="fr mt-14 mr-30" @click="newarticle">新增文章</el-button>
				<el-button class="fr mt-14 mr-30" @click="goClass">新增分类</el-button>
		</div>
		<div class="bg-f mt-5 pb-50 pl-30 pr-30">
			<el-form :inline="true" :model="formInlineData" label-width="85px" class="clearfix pt-30" style="display:flex">
				<div>
					<el-form-item label="文章标题">
						<el-input v-model.trim="formInlineData.title"></el-input>
					</el-form-item>
					<el-form-item label="文章分类">
						<el-select v-model="formInlineData.sunflowerClassifyId" placeholder="输入分类" clearable>
							<el-option :label='item.classifyName' :value="item.id" v-for='item in searchClassify' :key="item.id"></el-option>
						</el-select>
					</el-form-item>
				</div>
				<div style="flex:1;min-width:200px">
					<el-button type="info" class="fl ml-50" @click='queryarticle(1)' icon="el-icon-search">搜 索</el-button>
				</div>
			</el-form>

			<el-table :data="tableData" stripe tooltip-effect="dark" class="mt-10 table-default" width="" v-loading="Loading">
				<el-table-column label="序号">
					<template slot-scope="scope">
						<div>{{scope.row.sort}}</div>
					</template>
				</el-table-column>
				<el-table-column label="分类名">
					<template slot-scope="scope">
						<div>{{scope.row.classifyName}}</div>
					</template>
				</el-table-column>
				<el-table-column label="文章标题">
					<template slot-scope="scope">
						<div>{{scope.row.title}}</div>
					</template>
				</el-table-column>
				<el-table-column label="新增时间">
					<template slot-scope="scope">
						<div>{{scope.row.gmtCreate | timenyr}}</div>
					</template>
				</el-table-column>
				<el-table-column label="操作">
					<template slot-scope="scope">
						<div v-if='showButton("EDIT",200015) || showButton("DISABLE",200015)'>
							<el-button v-if='showButton("EDIT",200015)' @click.native.prevent="edit(scope.row)" type="text" size="small">
								编辑
							</el-button>
							<br>
							<el-button v-if='scope.row.isDisable==0 && showButton("DISABLE",200015)' @click.native.prevent="forbid(scope.row)" type="text" size="small">
								禁用
							</el-button>
							<el-button v-if='scope.row.isDisable == 1 && showButton("DISABLE",200015)'  @click.native.prevent="forbid(scope.row)" type="text" size="small" class="disabled">
								启用
							</el-button>
						</div>
						<div v-else>-</div>
					</template>
					
				</el-table-column>
			</el-table>

			<el-pagination class="tar mr-30" :current-page="currentPage" v-if="total>0" :page-size='10' :total='total' layout="prev , pager, next, jumper" @current-change="handleCurrentChange">
				<!-- 分页组件 -->
			</el-pagination>
		</div>
		<el-dialog :visible.sync="dialogVisible" size="tiny">
			<img v-if="image" width="100%" :src="image" alt="">
			<img v-else width="90%" src="../../assets/goods.jpg">
		</el-dialog>
	</div>

</template>

<script>
	import { axiosApi, basePath } from "../../api/api"
	export default {
		data() {
			return {
				formInlineData: {
					title: null,
					sunflowerClassifyId: null,
					isDisabled:0
				},
				searchClassify: [],
				Loading: false,
				tableData: [],
				formArticle: {
					content: '',
					image: [],
					sunflowerClassifyId: null
				},
				currentPage: 1,
				total: 0,
				rules: {
					title: [{
						required: true,
						message: '请输入标题'
					}, {
						max: 20,
						message: '请输入20个以内的字符'
					}],
					image: [{
						required: true,
						type: "array",
						message: '请上传图片',
					}],
					sort: [{
							required: true,
							message: '请输入排序号'
						},
						{
							pattern: /^[0-9]\d{0,3}$/,
							message: '排序号必须为数字值,且不能超过4位！'
						}
					],
					sunflowerClassifyId: [{
						required: true,
						message: '请选择分类'
					}],
					content: [{
						required: true,
						message: '请输入文章内容'
					}]
				},
				new: false, //新建or编辑
				image: '',
				dialogVisible: false,
				initsort: null

			}
		},
		methods: {
			handleCurrentChange(value) {
				this.currentPage = value
				this.queryarticle()
			},
			newarticle() {
				sessionStorage.setItem('currentPage', this.currentPage)
				this.$router.push({
					path: basePath + '/articleDetails',
					query: {
						isNew: 1,
						titleTop: '新增文章'
					}
				})
			},
			//去葵花宝典页面
			goClass(){
				this.$router.push({
					path: basePath + '/classify'
				})
			},
			queryarticle(x) {
				this.Loading = true
				axiosApi('/sales/sunflowerarticle/web/selectList', this.formInlineData, (res) => {
					this.tableData = res.data.data.elements
					this.total = res.data.data.totalCount
					//					this.currentPage = res.data.data.pageIndex
					this.Loading = false
				}, x ? x : this.currentPage, 10)
			},
			queryclasses(x) {
				axiosApi('/sales/sunflowerclassify/web/selectList', {}, (res) => {
					this.searchClassify = res.data.data

				})
			},
			edit(item) {
				//判断编辑序号是否改变
				this.initsort = item.sort;
				setTimeout(() => {
					let arr = []
					arr.push({ url: item.image })
					this.formArticle = Object.assign({}, item, { image: arr })
					sessionStorage.setItem('formArticle', JSON.stringify(this.formArticle))
					sessionStorage.setItem('currentPage', this.currentPage)
					this.$router.push({
						path: basePath + '/articleDetails',
						query: {
							isNew: 0,
							titleTop: '编辑文章'
						}
					})
				}, 0)
			},
			forbid(item) {
				axiosApi('/sales/sunflowerarticle/app/update', {
					id: item.id,
					sunflowerClassifyId: item.sunflowerClassifyId,
					sort: item.sort,
					isDisable: item.isDisable == 1 ? 0 : 1
				}, res => {
					this.queryarticle(1)
				})

			},
		},
		activated() {
			this.$nextTick(() => {
				// if(sessionStorage.currentPage) {
				// 	this.currentPage = parseInt(sessionStorage.getItem("currentPage"))
				// }
				this.queryclasses(this.currentPage)
				this.queryarticle(this.currentPage)
				// sessionStorage.removeItem("currentPage");
			})
		},
		computed: {

		}
	}
</script>

<style lang="less">
	#article {
		padding-left: 15px;
		padding-bottom: 50px;
		.disabled {
			color: gray;
		}
		.el-dialog {
			width: 800px!important;
		}
		.ue-editor {
			transform: translateX(0);
		}
		.border {
			border: 1px solid #EEEEEE;
			background-color: #EEEEEE;
		}
		.search {
			width: 171px;
			height: 33px;
		}
		.select {
			width: 130px;
			height: 33px;
		}
		.el-dialog {
			width: 650px;
		}
		.inputTitle {
			width: 351px;
		}
		.inputSort {
			width: 135px;
		}
		.classify-input .el-input {
			width: 130px;
		}
		.textarea {
			width: 350px;
			height: 115px;
		}
		.el-dialog__footer {
			position: relative;
			background-color: #fff;
			padding: 10px 0;
			margin-top: -10px;
		}
		.noAdd {
			.el-upload.el-upload--picture-card {
				display: none;
			}
		}
		.add {
			.el-upload.el-upload--picture-card {
				display: inline-block;
			}
		}
	}
</style>
