<template>
    <div id="commInfo">
        <div class="mt-50 font-14 ml-30">
            <el-form :model='ruleForm' :rules="rules" ref="ruleForm" label-width='130px'>
                <el-form-item label="商品名称:">
                    <span class="ml-20">{{ruleForm.skuName}}</span>
                </el-form-item>
                <el-form-item label="副标题:" class="tit" prop="skuSubtitle">
                    <el-input class="ml-20" :maxlength="30" :disabled="!isEdit" v-model.trim="ruleForm.skuSubtitle"
                              placeholder="请输入副标题"></el-input>
                </el-form-item>
                <div class="main clearfix">
                    <el-form-item label="商品编码:" prop="goodsNo" class="fl mr-50">
                        <el-input :maxlength="20" class="ml-20" :disabled="!isEdit || isK3" v-model.trim="ruleForm.goodsNo"
                                  placeholder="请输入商品编码"></el-input>
                    </el-form-item>
                    <div class="fl mr-50 mb-20">
                        <label class="mr-17">条形码:</label>
                        <span>{{ruleForm.spuVo.citm}}</span>
                    </div>
                    <div class="fl mr-50 mb-20">
                        <label class="mr-17">分类:</label>
                       <span>{{ruleForm.classifyName}}</span>
                    </div>
                    <div class="fl mr-50 mb-20">
                        <label class="mr-17">品牌:</label>
                        <span>{{ruleForm.brandName}}</span>
                    </div>
                    <div class="fl mr-50 mb-20">
                        <label class="mr-17">计量单位:</label>
                        <span>{{ruleForm.unitsName}}</span>
                    </div>
                    <div class="fl mr-50 mb-20">
                        <label class="mr-17">包装规格:</label>
                        <span>1*{{ruleForm.spuVo.specInfoNum}}</span>
                    </div>
                </div>
                 <el-form-item label="销售类型:" class="mt-30" required>
                    <el-radio-group v-model="ruleForm.salesUnitType">
                        <el-form-item class="ml-15" prop="unitNum">
                            <el-radio :disabled="!isEdit" :label="1">{{ }}</el-radio>
                            <span class="font-14">整{{ ruleForm.packageUnitName }}<span>({{ruleForm.specInfoNum}}{{ruleForm.unitsName}}装)</span>+<span>单{{ ruleForm.unitsName}}</span></span>
                        </el-form-item>
                        <el-form-item class="ml-15" prop="unitNum">
                            <el-radio :disabled="!isEdit" :label="2">{{ }}</el-radio>
                            <span class="font-14">整{{ ruleForm.packageUnitName }}<span>({{ruleForm.specInfoNum}}{{ruleForm.unitsName}}装)</span></span>
                        </el-form-item>
                        <el-form-item class="ml-15" prop="unitNum">
                            <el-radio :disabled="!isEdit" :label="3">{{ }}</el-radio>
                            <span class="font-14">单{{ ruleForm.unitsName }}</span>
                        </el-form-item>
                        <p class="color-999 ml-15">提示： <span>{{ ruleForm.packageUnitName }}<span>({{ruleForm.specInfoNum}}{{ruleForm.unitsName}}装)</span></span>  与<span>单{{ ruleForm.unitsName}}</span>同时销售时，客户下单时可以二选一，但不支持同时下单</p>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="最低起订量:" class="mt-30" prop="minimunMoq">
                     <sy-input size="mini" type="int" class="ml-10 mr-10" :disabled="!isEdit"
                                      v-model.trim="ruleForm.minimunMoq" placeholder="最低起订量"></sy-input>
                    <span class="font-14">{{ ruleForm.unitsName }}</span>
                    <span class="font-14">（合{{ Math.ceil(ruleForm.minimunMoq / ruleForm.spuVo.specInfoNum) }}{{ruleForm.packageUnitName}}）</span>
                    <p class="color-999 ml-15">提示：整{{ ruleForm.packageUnitName }}销售的起订量，是基于单{{ ruleForm.unitsName }}的起订量进行换算；不满足{{ruleForm.packageUnitName}}时则取1{{ruleForm.packageUnitName}}，满足{{ruleForm.packageUnitName}}且有小数时则向上取整</p>
                </el-form-item>
                <div class="clearfix mt-50">
                    <!--云商v2.6.8版本-->
                    <el-form-item label="商品设价:" class="mt-30" required>
                        <ul class="flex price-title">
                            <li>商品规格</li>
                            <li><span class="color-danger">*</span>进店价</li>
                            <li><span class="color-danger">*</span>建议零售价</li>
                            <!-- <li>成本价</li> -->
                            <li>最低起订量</li>
                        </ul>
                        <ul class="flex price-detail">
                            <li>
                                <p>整{{ruleForm.packageUnitName}}({{ruleForm.spuVo.specInfoNum}}{{ruleForm.unitsName}}装)</p>
                            </li>
                            <li>
                                <sy-input type="float" size="mini" @input="flag = false"  :max="9999999999.99" :min="0.00" class="ml-20" :disabled="!isEdit" v-model.trim="ruleForm2.integerEnterShopPrice"
                                      placeholder="进店价"></sy-input>
                                <span class="font-14">元/{{ ruleForm.packageUnitName }}</span>
                            </li>
                            <li>
                                <sy-input type="float" size="mini" @input="flag = false" :max="9999999999.99" :min="0.00" class="ml-20" :disabled="!isEdit" v-model.trim="ruleForm2.integerMsrp"
                                      placeholder="建议零售价"></sy-input>
                                <span class="font-14">元/{{ ruleForm.packageUnitName }}</span>
                            </li>
                            <!-- <li>
                                <sy-input type="float" size="mini" @input="flag = false" :max="9999999999.99" :min="0.00" class="ml-20" :disabled="!isEdit" v-model.trim="ruleForm2.integerCostPrice"
                                      placeholder="成本价"></sy-input>
                                <span class="font-14">元/{{ ruleForm.packageUnitName }}</span>
                            </li> -->
                            <li>
                                {{ Math.ceil(ruleForm.minimunMoq / ruleForm.spuVo.specInfoNum) }}{{ruleForm.packageUnitName}}起购
                            </li>
                        </ul>
                        <ul class="flex price-detail">
                            <li>
                                <p>单{{ruleForm.unitsName}}(1{{ruleForm.unitsName}}装)</p>
                            </li>
                            <li>
                                <sy-input type="float" size="mini" @input="flag = true"  :max="9999999999.99" :min="0.00" class="ml-20" :disabled="!isEdit" v-model.trim="ruleForm.unitEnterShopPrice"
                                      placeholder="进店价"></sy-input>
                                <span class="font-14">元/{{ ruleForm.unitsName }}</span>
                            </li>
                            <li>
                                <sy-input type="float" size="mini" @input="flag = true"  :max="9999999999.99" :min="0.00" class="ml-20" :disabled="!isEdit" v-model.trim="ruleForm.unitMsrp"
                                      placeholder="建议零售价"></sy-input>
                                <span class="font-14">元/{{ ruleForm.unitsName }}</span>
                            </li>
                            <!-- <li>
                                <sy-input type="float" size="mini" @input="flag = true"  :max="9999999999.99" :min="0.00" class="ml-20" :disabled="!isEdit" v-model.trim="ruleForm.unitCostPrice"
                                      placeholder="成本价"></sy-input>
                                <span class="font-14">元/{{ ruleForm.unitsName }}</span>
                            </li> -->
                            <li>
                                {{ruleForm.minimunMoq}}{{ruleForm.unitsName}}起购
                            </li>
                        </ul>
                    </el-form-item>
                </div>
                <el-form-item v-if="isEdit" class="mt-30">
                    <el-button class="ml-20" type="danger" @click="handleSave('ruleForm')">保存</el-button>
                </el-form-item>

            </el-form>
        </div>
    </div>
</template>

<script>
    import {axiosApi, basePath} from '../../api/api';
    import {mapActions, mapMutations} from 'vuex'
    import _  from 'underscore';
    export default {
        // isOpenPriceSync: 商品开通K3同步
        props: {isEdit: Boolean, skuInfo: Object, isK3: Boolean, isOpenPriceSync: Boolean},
        data() {
            return {
                flag: false,
            	firstEnter:true,
                brandList:[],
                rules: {
                    skuSubtitle: [{max: 30, message: '副标题最多只能支持30个字符'}],
                    // goodsNo:[{ max:20, pattern: /^[0-9a-zA-Z]{0,20}$/, message: '商品编码为数字或字母,且少于20个字符' }],
                    minimunMoq: [
                        {message: '请输入最低起订量',required: true,},
                        {pattern: /^[1-9]\d{0,2}$/, message: '最大支持3位整数'}
                    ],
                    // unitCostPrice: [{
                    //     pattern: /(^[1-9](\d+)?(\.\d{1,2})?$)|(^\d\.\d{1,2}$)/,
                    //     message: '仅支持小数点前10位，小数点后2位数字',
                    //     trigger: 'blur'
                    // }],
                    unitEnterShopPrice: [
                        {required: true, message: '进店价为必填项'},
                        {
                            pattern: /(^[1-9](\d+)?(\.\d{1,2})?$)|(^\d\.\d{1,2}$)/,
                            message: '仅支持小数点前10位，小数点后2位数字',
                            trigger: 'blur'
                        }
                    ],
                    unitMsrp: [
                        {required: true, message: '建议零售价为必填项'},
                        {
                            pattern: /(^[1-9](\d+)?(\.\d{1,2})?$)|(^\d\.\d{1,2}$)/,
                            message: '仅支持小数点前10位，小数点后2位数字',
                            trigger: 'blur'
                        }
                    ]
                },
                loading: false,
                ruleForm2:{
                    integerCostPrice:0,
                    integerEnterShopPrice:0,
                    integerMsrp:0
                },
                ruleForm: {
                    salesUnitType:1,//销售类型
                    skuShowPictureVOs: [],//图片信息
                    skuName: '',//商品名称
                    skuNo: '',//条形码
                    classifyId:'',
                    classifyName: '',//分类
                    brandId:'',
                    brandName: '',//品牌
                    unitsName: '',//计量单位
                    spuVo: {},//基础信息
                    id: '',//sku_id
                    skuSubtitle: '',//副标题
                    goodsNo: '',//商品编码
                    minimunMoq: 1,//最低起订量
                    unitCostPrice: '',//成本价（基本单位）
                    integerCostPrice:'',//成本价（规格单位）
                    unitEnterShopPrice: '',//进店价（基本单位）
                    integerEnterShopPrice:'',//进店价（规格单位）
                    unitMsrp: '',//建议零售价(基本单位)
                    integerMsrp:'',// 建议零售价（规格单位）
                    // minimumGorupbuyyingPrice: '',//团购价
                    isIntegerMultiple: false,//是否包装规格整数倍
                    //isPreferential: false,//是否启用客户设价
                    sellerId: sessionStorage.traderId,
                    sellingUnit: null,//销售单位,
                    packageUnitName:null,
                    sellingNum: 1,//销售单位数量
                    unitNum: 1,//按瓶的最低起订量
                    boxInfo:{},
                    bottleInfo:{}
                },
                isDisable: false,//控制加号显示隐藏
                dialogVisible: false,//预览
                dialogImageUrl: null,//图片显示
                fileList: [],
                goodsSellings: [],//包装
                radio: 1,
                setPriceArray:[],//商品设价
                unitInput: false,//手动改变单瓶设价
            }
        },
        computed: {
            unitsName() {
                // priceUnit设价单位（1：基本单位，2：商品展示销售单位）
                return (this.radio === 1 || this.ruleForm.priceUnit === 1) ? this.ruleForm.unitsName : this.goodsSellings.filter(n => this.ruleForm.sellingUnit === n.dictKey)[0].dictValue
            },
            classifylist() {
                return this.$store.state.classifylist.classifylistData ?
                    this.$store.state.classifylist.classifylistData : []
            },
        },
        watch: {
            // "ruleForm.unitCostPrice":{
            //     handler:function(val,oldVal){
            //         if(val !== oldVal  && oldVal && this.flag ){
            //             this.ruleForm2.integerCostPrice = (Number(this.ruleForm.unitCostPrice) * this.ruleForm.spuVo.specInfoNum).toFixed(2);
            //         }
            //     }
            // },
            "ruleForm.unitEnterShopPrice":{
                handler:function(val,oldVal){
                    if(val !== oldVal && oldVal && this.flag){
                        this.ruleForm2.integerEnterShopPrice = (Number(this.ruleForm.unitEnterShopPrice) * this.ruleForm.spuVo.specInfoNum).toFixed(2);
                    }
                }
            },
            "ruleForm.unitMsrp":{
                handler:function(val,oldVal){
                    if(val !== oldVal  && oldVal && this.flag){
                        this.ruleForm2.integerMsrp = (Number(this.ruleForm.unitMsrp) * this.ruleForm.spuVo.specInfoNum).toFixed(2);
                    }
                }
            },
            // "ruleForm2.integerCostPrice":{
            //     handler:function(val,oldVal){
            //         if(val !== oldVal){
            //             this.$set(this.ruleForm,'unitCostPrice',(val / this.ruleForm.spuVo.specInfoNum).toFixed(2));
            //         }
            //     }
            // },
            "ruleForm2.integerEnterShopPrice":{
                handler:function(val,oldVal){
                    if(val !== oldVal){
                        this.$set(this.ruleForm,'unitEnterShopPrice',(val / this.ruleForm.spuVo.specInfoNum).toFixed(2));
                    }
                }
            },
            "ruleForm2.integerMsrp":{
                handler:function(val,oldVal){
                    if(val !== oldVal){
                        this.$set(this.ruleForm,'unitMsrp',(val / this.ruleForm.spuVo.specInfoNum).toFixed(2));
                    }
                }
            },
            skuInfo:{
                handler(val){
                    console.log(val,'val');
                    //商品详细信息
                    if (JSON.stringify(val).length>2) {
                        if (val.isIntegerMultiple) {
                            this.radio = 2;
                        } else {
                            this.radio = 1;
                        }
                        if (val.skuShowPictureVOs.length > 0) {
                            val.skuShowPictureVOs.forEach((item) => {
                                item.url = item.fileUrl;
                            });//图片处理
                            this.fileList = val.skuShowPictureVOs;//图片展示
                            if(this.fileList.length >= 6) {
                                this.isDisable = true
                            } else {
                                this.isDisable = false
                            }
                        } else {
                            this.fileList = []
                        }
						this.namedCustomerPriceVOs = val.namedCustomerPriceVOs;//协议客户列表
                        // "integerCostPrice",'unitCostPrice',
                        this.ruleForm = _.pick(val,"skuName","skuNo","classifyId","isPreferential","specInfoNum",
                            "classifyName","brandId","brandName","unitsName","spuVo","id","skuSubtitle","goodsNo","minimunMoq",
                            "integerEnterShopPrice","unitMsrp","integerMsrp","unitMinimumGroupPrice",
                            "integerMinimumGroupPrice","isIntegerMultiple","priceUnit",'boxInfo','bottleInfo','',
							"sellingNum","unitNum","sellingUnitName","sellingUnit","packageUnitName","salesUnitType","unitEnterShopPrice"
                        );
                        console.log(this.ruleForm,'this.ruleForm')
                        // this.ruleForm.unitCostPrice = this.ruleForm.unitCostPrice.toFixed(2);
                        this.ruleForm.unitEnterShopPrice = this.ruleForm.unitEnterShopPrice.toFixed(2);
                        this.ruleForm.unitMsrp = this.ruleForm.unitMsrp.toFixed(2);
                        // this.ruleForm.minimumGorupbuyyingPrice = this.ruleForm.minimumGorupbuyyingPrice.toFixed(2);
                        
                        //新增中间变量
                        // this.ruleForm2.integerCostPrice = Number(this.ruleForm.integerCostPrice).toFixed(2);
                        this.ruleForm2.integerEnterShopPrice = Number(this.ruleForm.integerEnterShopPrice).toFixed(2) ;
                        this.ruleForm2.integerMsrp = Number(this.ruleForm.integerMsrp).toFixed(2);

                        this.ruleForm.skuShowPictureParam = val.skuShowPictureVOs;
                        this.goodsSellings = val.goodsSellings ? val.goodsSellings : [];
                        const unit = this.goodsSellings.length > 0 ? this.goodsSellings[0].dictKey : null
                        this.ruleForm.sellingUnit = val.sellingUnit || unit;// 销售单位
                        this.ruleForm.packageUnitName = val.packageUnitName;// 
                    }
                },
                immediate:true,
                deep:true
            }
        },
        methods: {
            //查询商品列表名字
            ...mapActions({
                'classSearch': 'queryclassifylistData',
            }),
            ...mapMutations({
                'changek3minimunMoq': 'changek3minimunMoq',
                'changemoqUnitName': 'changemoqUnitName'
            }),
			toUnits(val){
            	let str = '';
				this.goodsSellings.filter(v => {
					if(val == v.dictKey){
						str = v.dictValue
                    }
                });
				return str
			},
            handleClassifyId(val){
                this.classifylist.map(v => {
                    if (v.id == val){
                        this.ruleForm.classifyName = v.classifyName
                    }
                })
            },
            handleBrandId(val){
                this.brandList.map(v => {
                    if (v.id == val){
                        this.ruleForm.brandName = v.brandName
                    }
                })
            },
            //商品品牌
            getBrand(){
                axiosApi('/goods/classifyBrandListQuery/select',{},res => {
                    console.log(res,'品牌');
                    this.brandList = res.data.data.elements;
                })
            },
            //商品信息保存
            handleSave(ruleForm) {
                this.$refs[ruleForm].validate((valid) => {
                    if (valid) {
                        const api = "/goods/sku/update";
                        const body = Object.assign({},this.ruleForm,this.ruleForm2);
                        console.log(body,'body');
                        /* if(!body.unitEnterShopPrice || body.unitEnterShopPrice == 0){
                            this.$message({
                                type: 'warning',
                                message: '请输入商品进店价',
                                duration: 1000
                            });
                            return
                        }else if(!body.unitMsrp || body.unitMsrp == 0){
                            this.$message({
                                type: 'warning',
                                message: '请输入商品建议零售价',
                                duration: 1000
                            });
                            return
                        } */
                        axiosApi(api, body, (res) => {
                        	// this.changeFlag =
                            //console.log(res)
                            this.$message({
                                type: 'success',
                                message: '修改成功!',
                                duration: 1000
                            });
                            this.$emit('updateBase');
                            if (this.radio == 1) {
                                this.changek3minimunMoq(this.ruleForm.unitNum)
                                this.changemoqUnitName('瓶')
                            } else {
                                this.skuInfo.goodsSellings.forEach(item => {
                                    if (item.dictKey == this.ruleForm.sellingUnit) {//
                                        this.changemoqUnitName(item.dictValue)
                                    }
                                })
                                this.changek3minimunMoq(this.ruleForm.sellingNum)
                            }
                        }, 1, 10,err => {
                            this.$message.warning(err.data.message);
                        })
                    } else {
                        return false;
                    }
                });
            },

            //上传图片
            handlePictureCardPreview(file) {//预览
                this.dialogImageUrl = file.url;
                this.dialogVisible = true;
            },
            beforeAvatarUpload(file) {//上传之前的验证
                let bool = this.isUploadImg(file);
                return bool
            },
            handleAvatarPhotoScucess(res, file, fileList) {//上传成功的状态
                //console.log(this.ruleForm.skuShowPictureVOs)
                file.url = res.data[0];
                file.fileUrl = res.data[0];
                file.fileType = 1;
                this.ruleForm.skuShowPictureVOs = fileList;
                //成功的回调
                if (fileList.length >= 6) {
                    this.isDisable = true
                } else {
                    this.isDisable = false
                }
            },
            handleRemove(file, fileList) {//删除
                //console.log(file, fileList,'++++++++++++++++')
                this.ruleForm.skuShowPictureVOs = fileList
                //console.log(this.ruleForm.skuShowPictureVOs)
                if (fileList.length >= 6) {
                    this.isDisable = true
                } else {
                    this.isDisable = false
                }
            },
            /*//协议客户设价列表
            getNamedCustomerPrice(){
                const api = "/goods/namedCustomerPricePageList/select";
                const body = {skuId: this.$route.params.skuId};
                axiosApi(api, body, (res)=>{
                    //console.log(res,'123')
                    this.namedCustomerPriceVOs = res.data.data.elements;//协议客户列表
                }, 1, 100,(res)=>{
                    this.$message({
                        type: 'info',
                        message: res.data.message,
                        duration: 1000
                    });
                })
            },*/
            /*//获取所有客户列表
            getTraderLikeStoreName(query){//客户模糊搜索
                this.loading = true;
                const api = "/trader/findSellerBuyerList/page/select";
                axiosApi(api, {storeName: query,sellerId:sessionStorage.traderId}, (res)=>{
                    this.loading = false;
                    //console.log(res,'客户列表');
                    this.sellerList = res.data.data.elements;
                }, 1, 100, (res) => {
                    this.loading = false
                })
            },*/
            //协议客户添加
            /*customChange(){
                //console.log(this.storeInfo)
                this.newCusList.unshift({
                    buyerId: this.storeInfo.buyerId,
                    storeName:this.storeInfo.storeName,
                    minimunMoq: 1,
                    preferentialPrice: this.ruleForm.enterShopPrice
                })
            },*/
            /*Add(scope){
                if (null == scope.row.minimunMoq || 0 == scope.row.minimunMoq.length || 0 == scope.row.minimunMoq) {
                    this.$message.info('请输入最低起订量');
                    return;
                }
                if(!(/(^[1-9]\d{0,2}$)/.test(scope.row.minimunMoq))){
                    this.$message.info('请输入3位以内正整数');
                    return;
                }
                if (null == scope.row.minimunMoq || 0 == scope.row.preferentialPrice.length || 0 == scope.row.preferentialPrice) {
                    this.$message.info('请输入订货价');
                    return;
                }
                if(!(/^\d{1,10}(\.\d{1,2})?$/.test(scope.row.preferentialPrice))){
                    this.$message.info('订货价支持小数点前10位，小数点后2位');
                    return;
                }

                if(null != scope.row.minimunMoq && null != scope.row.preferentialPrice){
                    const api = "/goods/namedCustomerPrice/insert";
                    const body = {
                        buyerId:scope.row.buyerId,
                        minimunMoq:scope.row.minimunMoq,
                        preferentialPrice:scope.row.preferentialPrice,
                        skuId: this.ruleForm.id
                    };
                    axiosApi(api, body, (res)=>{
                        //console.log(res,'插入成功')
                        this.$message({
                            type: 'success',
                            message: '添加成功!',
                            duration: 1000
                      });
                        let index = this.newCusList.indexOf(scope.row);{
                        if (index !== -1)
                              this.newCusList.splice(index, 1)
                        };
                       this.getNamedCustomerPrice();
                    }, 1, 10,(res)=>{
                        this.$message({
                            type: 'info',
                            message: res.data.message,
                            duration: 3000
                        });
                    })
                }
            },
            remove(scope){
                let index = this.newCusList.indexOf(scope.row);
                if (index !== -1) {
                  this.newCusList.splice(index, 1)
                };
            },
            //协议客户设置
            handleAgrSave(scope){
                if (null == scope.row.minimunMoq || 0 == scope.row.minimunMoq.length || 0 == scope.row.minimunMoq) {
                    this.$message.info('请输入最低起订量');
                    return;
                }
                if(!(/(^[1-9]\d{0,2}$)/.test(scope.row.minimunMoq))){
                    this.$message.info('请输入3位以内正整数');
                    return;
                }
                if (null == scope.row.minimunMoq || 0 == scope.row.preferentialPrice.length || 0 == scope.row.preferentialPrice) {
                    this.$message.info('请输入订货价');
                    return;
                }
                if(!(/^\d{1,10}(\.\d{1,2})?$/.test(scope.row.preferentialPrice))){
                    this.$message.info('订货价支持小数点前10位，小数点后2位');
                    return;
                }

                if(null != scope.row.minimunMoq && null != scope.row.preferentialPrice){
                    const api = "/goods/namedCustomerPrice/update";
                    const body = {
                        id:scope.row.id,
                        buyerId:scope.row.buyerId,
                        minimunMoq:scope.row.minimunMoq,
                        preferentialPrice:scope.row.preferentialPrice
                    };
                    axiosApi(api, body, (res)=>{
                        //console.log(res)
                        this.$message({
                            type: 'success',
                            message: '修改成功!',
                            duration: 1000
                        });
                        this.getNamedCustomerPrice();
                    }, 1, 10)
                }
            },
            //协议客户移除
            handleDelete(scope){
                //console.log(scope)
                this.$confirm('是否确认删除协议客户「' +scope.row.storeName+ '」？', '提示信息', {
                  confirmButtonText: '确定',
                  cancelButtonText: '取消',
                  type: 'warning'
                }).then(() => {
                    const api = "/goods/namedCustomerPrice/delete";
                    const body = {id:scope.row.id}
                    axiosApi(api, body, (res)=>{
                        //console.log(res)
                        this.$message({
                            type: 'success',
                            message: '删除成功!',
                            duration: 1000
                       });
                       this.getNamedCustomerPrice();
                    }, 1, 10)
                }).catch(() => {
                  this.$message({
                    type: 'info',
                    message: '已取消删除'
                  });
                })
            }*/

        },
		mounted(){
            this.classSearch();
            this.getBrand()
			// this.$nextTick(function(){
  			// 	//this.getNamedCustomerPrice();
			// 	this.getTraderLikeStoreName();//所有客户列表
			// })
		}
    }
</script>

<style lang="less">
    #commInfo {
        .tit {
            .el-input {
                width: 400px;
            }
        }

        .el-form-item__error {
            margin-left: 20px;
        }

        .el-radio-group {
            .el-form-item__error {
                margin-left: 40px;
            }
        }

        .mr-17 {
            margin-right: 17px;
        }

        .main {
            label {
                width: 130px;
                height: 32px;
                line-height: 32px;
                padding-right: 10px;
                box-sizing: border-box;
                text-align: right;
                display: inline-block;
            }

            span {
                width: 240px;
                display: inline-block;
            }
        }

        .search {
            .el-input__inner {
                height: 36px !important;
                border: 2px solid #ff4201;
            }

            .el-input {
                width: 320px;
            }

            .el-button {
                width: 80px;
                height: 36px;
                font-size: 16px;
                background-color: #ff4201;
                border: 0;
                border-radius: 0;
            }
        }

        .el-upload--picture-card {
            width: 90px;
            line-height: 90px;
            height: 90px;
        }

        .el-icon-plus {
            line-height: 90px;
        }

        .el-upload-list--picture-card .el-upload-list__item {
            width: 90px;
            height: 90px;
        }

        .fileList .el-upload--picture-card {
            display: none;
        }

        .mainList {
            .el-input {
                width: 100px;

                .el-input__inner {
                    text-align: center;
                    border: 0;
                    box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 8px;
                }
            }
        }

        .see-goods-img {
            .el-upload-list__item-delete {
                display: none;
            }
        }
        .price-detail{
            border-left: 1px solid #ccc;
            li{
                flex: 1;
                &:first-of-type,&:last-of-type{
                    text-align: center; 
                }
                /* text-align: center; */
                padding: 10px 5px;
                border-right: 1px solid #ccc;
                border-bottom: 1px solid #ccc;
            }
        }
        .price-title{
            line-height: 36px;
            background-color: #eee;
            border: 1px solid #ccc;
            li{
                flex: 1;
                text-align: center;
                color: #666;
            }

        }
    }
</style>
