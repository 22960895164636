<template>
	<div id="advert" v-loading="pictureLoading">
		<div class="title-top bg-f">
			<span class="fl">考勤设置</span>
			<el-button v-if='showButton("ADD",200022)' class="fr mt14 mr-30" @click="goPage('add')">新增考勤</el-button>
		</div>
		<div class="bg-f pl-30 pr-30 pb-50 mt-5 pt-40">
			<el-table :data="info" border tooltip-effect="dark" stripe>
				<el-table-column label="标题" align="center" prop="configTitle">
				</el-table-column>
				<el-table-column label="考勤起始时间" align="center" prop="signOnTime">
				</el-table-column>
				<el-table-column label="考勤结束时间" align="center" prop="signOutTime">
				</el-table-column>
				<el-table-column label="考勤周期" align="center" show-overflow-tooltip>
					<template slot-scope="scope">
						<span v-for="(item, index) in scope.row.clockWeekList" :key="index">{{item}}</span>
					</template>
				</el-table-column>
				<el-table-column label="考勤人员数" align="center">
					<template slot-scope="scope">
						<el-button type="text" @click="lookClockPeople(scope.row.managerNames)">{{scope.row.managerNum}}</el-button>
					</template>
				</el-table-column>
				<el-table-column label="操作" align="center">
					<template slot-scope="scope">
						<div v-if='showButton("EDIT",200022) || showButton("DEL",200022)'>
							<el-button v-if='showButton("EDIT",200022)' type="text" @click="goPage('edit',scope.row)">编辑</el-button>
							<el-button v-if='showButton("DEL",200022)' type="text" class="ml-0" @click="deleteRow(scope.row)">删除</el-button>
						</div>
						<div v-else>-</div>
						
					</template>
				</el-table-column>
			</el-table>
			<el-pagination v-if="total>0" @current-change="handleCurrentChange" class="tar mt-20 mr-50" :current-page="currentPage" :page-size='10' :total='total' layout="prev , pager, next, jumper">
				<!-- 分页组件 -->
			</el-pagination>
			<el-dialog title="考勤人员" :visible.sync="peopleDialog" @close="peopleDialog = false">
				<span v-for="(p,index) in people" :key="p">{{p}}<span v-if="index < people.length - 1">, </span></span>
			</el-dialog>
		</div>
	</div>
</template>
<script>
	import { axiosApi, basePath } from "../../api/api"
	export default {
		data() {
			return {
				info: [],
				currentPage: 1,
				total: 0,
				pictureLoading: false,
				people: [],
				peopleDialog: false
			}
		},
		mounted() {
			this.loading()
		},
		methods: {
			lookClockPeople(val) {
				this.people = val
				this.peopleDialog = true
			},
			goPage(type,item){
				if (type=='add') {
					this.$router.push(basePath+"/checkDetail")
				}else if (type == 'edit') {
					this.$router.push(basePath+"/checkDetail/"+item.id)
				}
			},
			handleCurrentChange(val){
				this.currentPage = val;
				this.loading();
			},
			loading() {
				this.pictureLoading = true;
				this.info = []
				axiosApi("/sales/clockConfig/selectPageList", {}, res => {
					this.pictureLoading = false;
					if(res.data.data.elements.length > 0) {
						this.info = res.data.data.elements
						this.total = res.data.data.totalCount
					}
				},this.currentPage,10,err => {
					this.pictureLoading = false;
				})
			},
			deleteRow(item) {
				this.$confirm('确定执行该操作？', '提示', {
					confirmButtonText: '确定',
					type: 'warning'
				}).then(() => {
					axiosApi("/sales/clockConfig/delete", {
						id: item.id
					}, res => {
						this.loading();
						this.$message({
							type: "success",
							message: "操作成功",
							duration: 1000
						})
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消',
						duration: 1000
					})
				})
			}
		}
	}
</script>
<style lang="less">
	#advert {
		.mt14 {
			margin-top: 14px;
		}
		.el-table th>.cell {
			text-align: center;
		}
		.el-table .cell{
			padding: 0 10px;
		}
		.el-dialog__body {
			padding: 30px;
		}
	}
</style>