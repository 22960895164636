<template>
  <!--自营商品管理-->
  <div id="storeSelfGoods" @click="closeBox">
    <div class="title-tabs bg-f" style="position: relative;">
      <ul class="clearfix" @click="handleTabs">
        <template v-for="(tab,index) in dataTabs" class="item">
          <li :type="index" :class="type==index?'active':''" :key="index+'ds'">
            {{tab}}
            <div :class="type==index?'under-line':''"></div>
          </li>
          <div class="vertical-line" :key="index+'dr'"></div>
        </template>
      </ul>
      <el-badge class="count" :max="99" :value="count" :hidden="count == 0"/>
    </div>
    <div class="content bg-f pr-30 pl-30">
      <div class="clearfix search">
        <el-form :inline="true" class="pt-30 fl" :model="Form" ref="searchForm">
          <el-form-item label="sku编码">
            <el-input v-model.trim="Form.skuNo" placeholder="请输入sku编码" :maxlength="30"></el-input>
          </el-form-item>
          <el-form-item label="商品名称" class="mr-100">
            <el-input v-model.trim="Form.skuName" placeholder="请输入商品名称" :maxlength="30"></el-input>
          </el-form-item>
          <!-- <el-form-item class="mr-30" label="商品分类">
            <el-select
              v-model="Form.tenantClassify"
              placeholder="请选择分类属性"
              clearable
              @change="getClassList"
            >
              <el-option v-for="tab in typeList" :label="tab.name" :value="tab.id" :key="tab.id"></el-option>
            </el-select>
          </el-form-item>-->
          <el-form-item class="mr-30 MySecond" label="商品分类">
            <el-input
              v-model.trim="Form.classifyName"
              :maxlength="30"
              @focus="openClassify"
              @blur="closeClassify"
            ></el-input>
            <div
              class="searchSelectBox"
              v-show="isShowSearchClassify && searchClassifyList.length>0"
            >
              <ul>
                <li
                  v-for="item in searchClassifyList"
                  :key="item.classifyId"
                  @click="selectClassify(item)"
                >
                  <span>{{item.classifyName}}</span>
                  <span>{{item.tenantClassifyName}}</span>
                </li>
              </ul>
            </div>
            <div class="secondSelectBox" v-show="isShowClassify">
              <ul class="tean-ul">
                <li class="tean-li fl" :class="Form.tenantClassify == (index +1)?'active-li':''" v-for="(item,index) in typeList" :key="item.id">
                  <span
                    @click="handleTab(item.id)"
                    :class="Form.tenantClassify == (index +1)?'active':''"
                  >{{item.name}}</span>
                </li>
              </ul>
              <ul class="item-ul">
                <li v-for="item in classList" class="fl item-li" :key="item.classifyId">
                  <span @click="selectClassify(item)">{{item.classifyName}}</span>
                </li>
              </ul>
            </div>
          </el-form-item>
        </el-form>
        <el-button class="fl ml-30 mt-30" type="info" @click="achieve(true)">搜索</el-button>
      </div>
      <div class="tar clearfix" v-if="type==0">
        <router-link :to="`${basePath}/storeGoodsAdd`">
          <el-button type="text" class="fr">上传商品</el-button>
        </router-link>
      </div>
      <div class="pb-30">
        <el-table :data="tableData" @selection-change="handleSelectionChange" stripe>
          <el-table-column key="a" label="商品名称" min-width="230">
            <template slot-scope="scope">
              <div class="fl specialD">
                <img v-if="scope.row.fileUrl" :src="scope.row.fileUrl" alt />
                <img v-else src="../../../assets/goods.jpg" alt />
                <img
                  v-if="scope.row.storeType=='2'"
                  class="special"
                  src="../../../assets/special.png"
                  alt
                />
              </div>
              <div class="fl lh-16">
                <div class="width-130">
                  sku编码:
                  <span v-if="scope.row.skuNo">{{scope.row.skuNo}}</span>
                  <span v-else>--</span>
                </div>
                <div class="width-130" show-overflow-tooltip>{{scope.row.skuName}}</div>
                <div
                  v-if="scope.row.storeType == '2' && scope.row.limitMonth && scope.row.limitNum"
                  class="width-130 color-r"
                >限购：每{{scope.row.limitMonth}}月{{scope.row.limitNum}}{{scope.row.units}}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="规格" :min-width="itemWidth" prop="specInfoNum">
            <template slot-scope="scope">
              <span>1*{{scope.row.specInfoNum}}</span>
            </template>
          </el-table-column>
          <el-table-column key="b" label="商品分类" :min-width="itemWidth">
            <template slot-scope="scope">
              <span>{{scope.row.classifyName}}</span>
            </template>
          </el-table-column>
          <el-table-column key="c" label="促销价" :min-width="itemWidth">
            <template slot-scope="scope">
              <span v-if="scope.row.promotionPrice">￥{{scope.row.promotionPrice||0 | fmoney }}</span>
              <span v-else>-</span>
            </template>
          </el-table-column>
          <el-table-column key="d" label="最低零售价" :min-width="itemWidth">
            <template slot-scope="scope">
              <span v-if="scope.row.minSellingPrice">￥{{scope.row.minSellingPrice||0 | fmoney }}</span>
              <span v-else>-</span>
            </template>
          </el-table-column>
          <el-table-column key="e" label="标准零售价" :min-width="itemWidth">
            <template slot-scope="scope">
              <span v-if="scope.row.maxSellingPrice">￥{{scope.row.maxSellingPrice||0 | fmoney }}</span>
              <span v-else>-</span>
            </template>
          </el-table-column>
          <!-- <el-table-column key="f" label="销售员提成" min-width="100">
            <template slot-scope="scope">
              <span
                v-if="scope.row.rewardValue == 0 || scope.row.rewardValue"
              >{{scope.row.rewardValue}}{{scope.row.rewardType == '1' ? '%' :'元'}}</span>
              <span v-else>--</span>
            </template>
          </el-table-column>
          <el-table-column key="g" label="推广员佣金" min-width="100">
            <template slot-scope="scope">
              <span
                v-if="scope.row.salesRewardValue == 0 || scope.row.salesRewardValue"
              >{{scope.row.salesRewardValue}}{{scope.row.salesRewardType == '1' ? '%' :'元'}}</span>
              <span v-else>--</span>
            </template>
          </el-table-column> -->
          <el-table-column key="h" label="最低起订量" :min-width="itemWidth">
            <template slot-scope="scope">
              <span>{{scope.row.minimunMoq}}{{scope.row.unitName}}</span>
            </template>
          </el-table-column>
          <el-table-column key="j" label="序号" :min-width="itemWidth">
            <template slot-scope="scope">
              <span>{{scope.row.sort != null?scope.row.sort:'-'}}</span>
            </template>
          </el-table-column>
          <el-table-column key="l" label="操作" align="center" min-width="85">
            <template slot-scope="scope">
              <el-button type="text" @click="openSort(scope.row)">排序</el-button>
              <el-button type="text" @click="setMark(scope.row)">编辑</el-button>
              <el-button type="text" v-if="type==0" @click="handleUp(scope)">上架</el-button>
              <el-button type="text" v-if="type==1" @click="handleUp(scope)">下架</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          class="tar"
          v-if="tableData.length>0"
          :current-page="currentPage"
          :page-size="10"
          :total="total"
          layout="prev , pager, next, jumper"
          @current-change="handleCurrentChange"
        >
          <!-- 分页组件 -->
        </el-pagination>
      </div>
      <!--设置-->
      <el-dialog class="minMop" title="设置" :visible.sync="salesMark">
        <el-form ref="markMarks" class="pr-10" :model="markForm" :rules="rules">
          <el-form-item label="销售员佣金:" class="ml-20" required>
            <el-form-item class="ml-15" prop="rewardValue">
              <el-input
                size="mini"
                class="ml-10 mr-10"
                v-model="markForm.rewardValue"
                placeholder="请输入"
              ></el-input>
              <el-select v-model="markForm.rewardType" size="mini">
                <el-option label="%" value="1"></el-option>
                <el-option label="元" value="2"></el-option>
              </el-select>
            </el-form-item>
          </el-form-item>
          <el-form-item
            label="运费补贴:"
            class="ml-20 freightDiscounts-money"
            prop="freightDiscountsMoney"
          >
            <el-input
              size="mini"
              class="ml-10 mr-10"
              v-model="markForm.freightDiscountsMoney"
              placeholder="请输入金额"
            ></el-input>
            <span class="color-6">元/{{units}}</span>
            <div class="color-6 ml-100">（用于补贴消费者的运费，由酒店承担）</div>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="danger" @click="setMarks('markMarks', 1)">保存</el-button>
          <el-button v-if="type == '0'" class="ml-20" @click="setMarks('markMarks', 2)">保存并上架</el-button>
        </div>
      </el-dialog>
      <!--一键设置佣金-->
      <el-dialog class="minMop" title="批量设置佣金" :visible.sync="salesOneKeyMark">
        <el-form ref="markOneKeyMarks" class="pr-30 ml50" :model="markForm" :rules="rules">
          <el-form-item label="销售员佣金:" class="ml-20" required>
            <el-form-item class="ml-15" prop="rewardValue">
              <el-input
                size="mini"
                class="ml-10 mr-10"
                v-model="markForm.rewardValue"
                placeholder="请输入"
              ></el-input>&nbsp;%
            </el-form-item>
            <p class="ml-10 mt-10">（一键设置佣金后会适用全部商品）</p>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="danger" @click="setOneKeyMarks('markOneKeyMarks', 1)">保存</el-button>
          <el-button
            v-if="type == '0'"
            class="ml-20"
            @click="setOneKeyMarks('markOneKeyMarks', 2)"
          >保存并上架</el-button>
        </div>
      </el-dialog>
      <!-- 排序 -->
      <el-dialog class="sort" title="序号" :visible.sync="sortvisiable">
        <el-form ref="sortForm" class="pr-10" :model="sortForm" :rules="sortRules">
          <el-form-item label="序号:" class="ml-20" prop="sort">
            <el-input type="text" v-model="sortForm.sort" placeholder="请输入序号" required></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="danger" @click="handleSortSave">确定</el-button>
          <el-button class="ml-20" @click="clearSort">重置</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { axiosApi, basePath } from "../../../api/api";
import { MessageBox } from "element-ui";
const delay = (function() {
  let timer = 0;
  return function(callback, ms) {
    clearTimeout(timer);
    timer = setTimeout(callback, ms);
  };
})();
export default {
  name: "storeSelfGoods",
  data() {
    let reg1 = /^([0-9]|([1-9]\d)|(100))(\.\d{1,2})?$/;
    let reg2 = /^(\d|[1-9]\d|100)(\.\d{1,2})?$/;
    let reg3 = /^([0]|[1-9]\d{0,3}|10000)$/;

    var unitNums = (rule, value, callback) => {
      if (!value && value !== 0) {
        callback(new Error("请输入销售员佣金"));
      } else if (value && this.markForm.rewardType == 1) {
        if (!reg2.test(value)) {
          callback(new Error("请输入0-100数字"));
        } else {
          callback();
        }
      } else if (value && this.markForm.rewardType == 2) {
        if (!reg3.test(value)) {
          callback(new Error("请输入0-10000数字"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    return {
      searchClassifyList: [],
      typeList: [
        { id: 1, name: "名烟名酒店" },
        { id: 2, name: "夫妻老婆店" },
        { id: 3, name: "餐饮" }
      ],
      isShowClassify: false,
      isShowSearchClassify: false,
      sortvisiable: false,
      id: null, //商品主键id
      sortForm: {
        sort: null
      },
      sortRules: {
        sort: [
          { required: true, message: "请输入序号" },
          {
            pattern: /^[1-9]\d{0,3}$/,
            message: "请输入1-9999之间的整数"
          }
        ]
      },
      rules: {
        rewardValue: [
          {
            validator: unitNums,
            trigger: "blur"
          }
        ],
        freightDiscountsMoney: [
          {
            pattern: /^([1-9][\d]{0,2}|0)(\.[\d]{1,2})?$/,
            message: "请输入正确金额，最大999.99元",
            trigger: "blur"
          }
        ]
      },
      type: "0",
      dataTabs: ["未上架", "已上架"],
      //搜索表单
      Form: {
        skuName: null, //商品名称
        skuNo: null,
        tenantClassify: 1,
        classifyId: null, //商品分类
        classifyName: null,
        skuState: "1" //商品状态
      },
      tableData: [], //商品数据
      classList: [], //分类数据
      skuNoList: [], //上下架商品列表
      skuState: null, //1下架2上架
      stateText: "", //上下架文案
      currentPage: 1,
      total: null, //页面分页总数
      reject: false, //设价弹窗
      totalCount: null, //弹窗分页总数
      //设置佣金弹框数据
      markForm: {
        rewardType: "1",
        rewardValue: "",
        freightDiscountsMoney: 0
      },
      idList: [], //设置佣金商品id
      salesMark: false, //销售员佣金弹框
      salesOneKeyMark: false, //一键设置销售员佣金弹框
      count: "", //角标数据
      units: null,
      isAdmin: "1",
      itemWidth: 70,
      isInSelect: false //在分类框中
    };
  },
  watch: {
    sortvisiable(val) {
      if (!val) {
        let _this = this;
        setTimeout(() => {
          if (_this.$refs.sortForm) _this.$refs.sortForm.resetFields();
        }, 500);
      }
    },
    "Form.classifyName": {
      handler: function() {
        if (this.Form.classifyName != null) {
          this.isShowSearchClassify = true;
          this.isShowClassify = false;
          delay(() => {
            this.getSearchClassify(this.Form.classifyName);
          }, 500);
        }
      }
    }
  },
  created() {
    this.isAdmin = sessionStorage.isAdmin;
  },
  mounted() {
    this.$nextTick(() => {
      this.achieve();
      this.getClassList(1);
    });
  },
  computed: {},
  methods: {
    closeBox() {
      let div = document.querySelector(".MySecond");
      if (div) {
        if (!div.contains(event.target)) {
          //以外的区域
          this.isShowClassify = false;
        }
      }
    },
    getSearchClassify(classifyName) {
      if (classifyName) {
        //获取分类列表
        axiosApi(
          "/superc/tenantShopClassify/list",
          {
            classifyName: classifyName
          },
          res => {
            if (res.data.data.elements.length > 0) {
              res.data.data.elements.forEach(item => {
                item.tenantClassifyName =
                  item.tenantClassify == 1
                    ? "名烟名酒店"
                    : item.tenantClassify == 2
                    ? "夫妻老婆店"
                    : item.tenantClassify == 3
                    ? "餐饮"
                    : "";
              });
              this.searchClassifyList = res.data.data.elements;
            } else {
              this.searchClassifyList = [];
            }
          },
          1,
          100,
          res => {
            this.$message({
              type: "info",
              message: res.data.message,
              duration: 3000
            });
          }
        );
      }else{
        this.isShowClassify = true
        this.isShowSearchClassify = false
      }
    },
    handleTab(tenantClassify) {
      this.Form.tenantClassify = tenantClassify;
      this.isInSelect = true;
      this.getClassList(tenantClassify);
      let _this = this;
      setTimeout(() => {
        _this.isInSelect = false;
      }, 1000);
    },
    selectClassify(item) {
      this.Form.classifyId = item.classifyId;
      this.Form.classifyName = item.classifyName;
      this.Form.tenantClassify = item.tenantClassify;
      setTimeout(() => {
        this.isShowClassify = false;
        this.isShowSearchClassify = false;
      }, 250);
    },
    openClassify() {
      this.isShowClassify = true;
      this.isShowSearchClassify = false;
    },
    closeClassify() {
      setTimeout(() => {
        if (!this.isInSelect) {
          this.isShowClassify = false;
          this.isShowSearchClassify = false;
        }
      }, 250);
    },
    clearSort() {
      axiosApi(
        "/superc/goodsSort/update",
        {
          supercType: 3,
          id: this.id,
          sort: null,
          isSelf: 1
        },
        res => {
          this.$message({
            type: "success",
            message: "重置成功",
            duration: 1500
          });
          this.achieve(); //默认加载商品列表
          this.sortvisiable = false;
        },
        this.Page,
        10,
        res => {
          this.$message({
            type: "error",
            message: res.data.message,
            duration: 1500
          });
        }
      );
    },
    handleSortSave() {
      this.$refs.sortForm.validate(valid => {
        if (valid) {
          axiosApi(
            "/superc/goodsSort/update",
            {
              supercType: 3,
              id: this.id,
              sort: this.sortForm.sort ? parseInt(this.sortForm.sort) : null,
              isSelf: 1
            },
            res => {
              this.$message({
                type: "success",
                message: "设置成功",
                duration: 1500
              });
              this.achieve(); //默认加载商品列表
              this.sortvisiable = false;
            },
            this.Page,
            10,
            res => {
              this.$message({
                type: "error",
                message: res.data.message,
                duration: 1500
              });
            }
          );
        }
      });
    },
    openSort(item) {
      this.id = item.tenantGoodsId;
      this.sortvisiable = true;
      if (item.sort == null) {
        this.getSort();
      } else {
        this.sortForm.sort = parseInt(item.sort);
      }
    },
    getSort() {
      axiosApi(
        "/superc/goodsSortMax/select",
        {
          supercType: 3,
          isSelf: 1
        },
        res => {
          this.sortForm.sort = res.data.data;
        },
        this.Page,
        10,
        res => {}
      );
    },
    achieve(val) {
      if (val && this.currentPage != 1) {
        this.currentPage = 1;
        return;
      }
      axiosApi(
        "/superc/tenantGoods/selectPage",
        {
          isSelf: 1,
          skuState: this.Form.skuState,
          skuNo: this.Form.skuNo,
          skuName: this.Form.skuName,
          classifyId: this.Form.classifyId
          // tenantClassify: this.Form.tenantClassify
        },
        res => {
          this.tableData = res.data.data.elements ? res.data.data.elements : [];
          this.total = res.data.data.totalCount;
          this.getCount();
        },
        this.currentPage,
        10,
        res => {}
      );
    },
    getCount() {
      //角标数量统计
      axiosApi(
        "/superc/tenantGoods/count",
        {
          isSelf: "1"
        },
        res => {
          this.count = res.data.data.onTheShelvesCount;
        },
        1,
        10,
        res => {
          this.$message({
            type: "info",
            message: res.data.message,
            duration: 3000
          });
        }
      );
    },
    //设置佣金数据处理
    setMark(val) {
      localStorage.setItem("goodsInfo", JSON.stringify(val));
      localStorage.setItem("goodsFileUrls", JSON.stringify(val.fileUrls));
      this.$router.push({
        path: basePath + "/storeGoodsAdd",
        query: { isEdit: 1, id: val.id }
      });
    },
    //设置
    setMarks(markMarks, status) {
      // this.$refs[markMarks].validate((valid) => {
      // 	if(valid) {
      // 		axiosApi('/superc/hotelGoods/setReward', {
      // 			idList: this.idList,
      // 			rewardValue: this.markForm.rewardValue,
      // 			rewardType: this.markForm.rewardType,
      // 			updateSkuState: status == 1 ? false : true, // true：保存并上架 false：保存不上架
      // 			freightDiscountsMoney: this.markForm.freightDiscountsMoney
      // 		}, (res) => {
      // 			this.achieve()
      // 			this.salesMark = false
      // 			this.$message({
      // 				type: 'success',
      // 				message: '操作成功',
      // 				duration: 3000
      // 			});
      // 		}, this.currentPage, 10, (res) => {
      // 			this.$message({
      // 				type: 'info',
      // 				message: res.data.message,
      // 				duration: 3000
      // 			});
      // 			this.salesMark = false
      // 		})
      // 	}
      // })
    },
    handleTabs(e) {
      //Tab切换
      if (e.target.attributes.type) this.type = e.target.attributes.type.value;
      this.Form = {
        skuName: null, //商品名称
        classifyId: null
      };
      this.currentPage = 1;
      if (this.type == "0") {
        //未上架
        this.Form.skuState = "1";
        this.achieve();
      } else if (this.type == "1") {
        //已上架
        this.Form.skuState = "2";
        this.achieve();
      }
    },
    handleSelectionCheck(val) {
      //选中的状态
      this.skuNoList = [];
      val.forEach(item => {
        this.skuNoList.push(item.skuNo);
      });
    },
    handleCurrentChange(val) {
      //分页查询
      this.currentPage = val;
      this.achieve();
    },
    getClassList(tenantClassify) {
      //获取分类列表
      axiosApi(
        "/superc/tenantShopClassify/list",
        {
          tenantClassify: tenantClassify
        },
        res => {
          this.classList = res.data.data.elements;
          // this.isShowClassify = true
        },
        1,
        100,
        res => {
          this.$message({
            type: "info",
            message: res.data.message,
            duration: 3000
          });
        }
      );
    },
    handleSelectionChange(val) {
      /*table全选*/
      //console.log(val, '选中值')
      this.skuNoList = []; //清空每次全选时添加的订单编号
      this.idStr = []; //清空每次全选时添加的订单Id
      val.forEach(item => {
        this.skuNoList.push(item.skuNo);
        this.idStr.push(item.id);
      });
    },
    handleUp(scope) {
      //上下架
      if (scope.row.skuState == 1) {
        this.skuState = 2;
        this.stateText = "上架";
      } else if (scope.row.skuState == 2) {
        this.skuState = 1;
        this.stateText = "下架";
      }
      // if(scope.row.id) { //单个商品上下架数据处理
      // 	this.skuNoList = []; //处理每次添加的订单编号
      // 	this.skuNoList.push(scope.row.skuNo);
      // };
      const h = this.$createElement;
      this.$msgbox({
        title: "提示信息",
        message: h(
          "p",
          {
            style: "text-align:center"
          },
          "确认" + this.stateText + "该商品？"
        ),
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            axiosApi(
              "/superc/tenantGoods/skuState/update",
              {
                skuState: this.skuState,
                id: scope.row.id
              },
              res => {
                this.skuNoList = []; //清空存储的商品编号
                this.currentPage = 1;
                this.achieve(); //刷新列表
                this.$message({
                  type: "success",
                  message: "操作成功",
                  duration: 3000
                });
              },
              1,
              10,
              res => {
                this.$message({
                  type: "info",
                  message: res.data.message,
                  duration: 3000
                });
              }
            );
            done();
          } else {
            done();
          }
          delete instance.$slots.default;
        }
      });
    },
    showOneKey() {
      this.salesOneKeyMark = true;
    },
    setOneKeyMarks(markOneKeyMarks, status) {
      this.$refs[markOneKeyMarks].validate(valid => {
        if (valid) {
          if (status == 1) {
            axiosApi(
              "/superc/hotelGoods/batchSetReward",
              {
                // idList: this.idList,
                rewardValue: this.markForm.rewardValue,
                updateSkuState: status == 1 ? false : true // true：保存并上架 false：保存不上架
              },
              res => {
                this.achieve();
                this.salesOneKeyMark = false;
                this.$message({
                  type: "success",
                  message: "操作成功",
                  duration: 3000
                });
              },
              this.currentPage,
              10,
              res => {
                this.$message({
                  type: "info",
                  message: res.data.message,
                  duration: 3000
                });
                this.salesOneKeyMark = false;
              }
            );
          } else if (status == 2) {
            axiosApi(
              "/superc/hotelGoods/checkBatchSetReward",
              {},
              res => {
                console.log(res, "123456");
                if (res.data.data) {
                  const h = this.$createElement;
                  this.$msgbox({
                    title: "提示信息",
                    message: h(
                      "p",
                      { style: "text-align:center" },
                      "有" +
                        res.data.data +
                        "款商品不满足上架条件，是否将符合条件的商品先行上架"
                    ),
                    showCancelButton: true,
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    beforeClose: (action, instance, done) => {
                      if (action === "confirm") {
                        axiosApi(
                          "/superc/hotelGoods/batchSetReward",
                          {
                            // idList: this.idList,
                            rewardValue: this.markForm.rewardValue,
                            updateSkuState: true // true：保存并上架 false：保存不上架
                          },
                          res => {
                            this.achieve();
                            this.salesOneKeyMark = false;
                            this.$message({
                              type: "success",
                              message: "操作成功",
                              duration: 3000
                            });
                          },
                          this.currentPage,
                          10,
                          res => {
                            this.$message({
                              type: "info",
                              message: res.data.message,
                              duration: 3000
                            });
                            this.salesOneKeyMark = false;
                          }
                        );
                        // this.salesOneKeyMark = false
                        done();
                      } else {
                        this.salesOneKeyMark = false;
                        done();
                      }
                      delete instance.$slots.default;
                    }
                  });
                } else {
                  axiosApi(
                    "/superc/hotelGoods/batchSetReward",
                    {
                      // idList: this.idList,
                      rewardValue: this.markForm.rewardValue,
                      updateSkuState: true // true：保存并上架 false：保存不上架
                    },
                    res => {
                      this.achieve();
                      this.salesOneKeyMark = false;
                      this.$message({
                        type: "success",
                        message: "操作成功",
                        duration: 3000
                      });
                    },
                    this.currentPage,
                    10,
                    res => {
                      this.$message({
                        type: "info",
                        message: res.data.message,
                        duration: 3000
                      });
                      this.salesOneKeyMark = false;
                    }
                  );
                }
              },
              this.currentPage,
              10,
              res => {
                this.$message({
                  type: "info",
                  message: res.data.message,
                  duration: 3000
                });
              }
            );
          }
        }
      });
    }
  }
};
</script>

<style lang="less">
#storeSelfGoods {
  .MySecond {
    position: relative;
    .secondSelectBox {
      position: absolute;
      top: 32px;
      width: 292px;
      min-height: 193px;
      border: 1px solid #c7d8e3;
      background-color: #ffffff;
      z-index: 999;
      padding-bottom: 40px;
      .item-ul {
        width: 100%;
        min-height: 145px;
        height: auto;
        padding-top: 20px;
        padding-left: 10px;
        box-sizing: border-box;
        .item-li {
          width: 85px;
          height: 30px;
          margin-right: 7px;
          display: flex;
          justify-content: center;
          span {
            width: 85px;
            height: 30px;
            text-align: center;
            line-height: 30px;
            color: #999999;
            cursor: pointer;
          }
          span:hover{
            color: #FF4201;
          }
        }
      }
      .tean-ul {
        width: 100%;
        height: 46px;
        border-bottom: 1px solid #DDDDDD;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 20px;
        box-sizing:border-box;
        .tean-li {
          width: 72px;
          height: 26px;
          display: flex;
          justify-content: center;
          background-color: #FFF2ED;
          color: #FF4201;
          .active {
            color: #ffffff !important;
            background-color: #FF4201 !important;
          }
          span {
            width: 80px;
            height: 26px;
            text-align: center;
            line-height: 26px;
            cursor: pointer;
            font-size: 12px;
          }
          span:hover {
            width: 80px;
            height: 26px;
            text-align: center;
            line-height: 26px;
            cursor: pointer;
            font-size: 12px;
          }
        }
        .active-li{
          background-color: #FF4201 !important;
          color: #ffffff !important;
        }
      }
    }
    .searchSelectBox {
      position: absolute;
      top: 32px;
      width: 300px;
      min-height: 200px;
      max-height: 600px;
      border: 1px solid #c7d8e3;
      background-color: #ffffff;
      z-index: 999;
      padding-top: 10px;
      padding-bottom: 20px;
      ul {
        width: 100%;
        height: auto;
        li {
          width: 100%;
          padding: 0 40px;
          height: auto;
          display: flex;
          box-sizing: border-box;
          align-items: center;
          justify-content: space-between;
          color: #999999;
        }
        li:hover {
          color: #FF4201;
          cursor: pointer;
        }
      }
    }
  }
  .blue {
    color: #20a0ff;
    display: inline-block;
    width: auto;
    height: auto;
    text-align: center;
    cursor: pointer;
  }
  .sort {
    .el-dialog {
      min-height: 250px !important;
    }
    .el-form-item__label {
      width: 120px !important;
    }
    .el-form-item__error {
      left: 28%;
    }
  }
  .content {
    .el-form--inline {
      width: 800px;
      .el-form-item__label {
        font-size: 12px;
        width: 60px;
      }
      .order-datatimerange {
        width: 240px;
      }
    }
    .el-button + .el-button {
      margin-left: 0;
    }
    .el-input {
      width: 170px;
    }
    .el-table__body-wrapper {
      margin-top: 1px !important;
    }
    .el-table .cell {
      text-align: center;
      padding: 10px 0;
      img {
        width: 60px;
        height: 60px;
        margin-left: 10px;
        margin-right: 10px;
      }
      .width-130 {
        width: 140px;
        text-align: left;
      }
      .el-button {
        height: 20px;
      }
    }
    .el-form-item__label {
      width: 100px;
    }
    .reject {
      .el-dialog {
        width: 900px;
        .el-dialog__body {
          min-height: 100px;
          margin-left: 50px;
        }
      }
      .el-form-item__error {
        margin-left: 100px;
      }
    }
    .goods {
      .el-dialog__body {
        padding-top: 30px;
      }
      .el-dialog {
        width: 968px;
      }
    }
  }
  .city {
    position: absolute;
    z-index: 10;
    width: 335px;
    padding-top: 10px;
    top: 30px;
    left: 0;
    background: #ffffc3;
    .el-checkbox {
      margin: 5px;
    }
  }
  .w125 {
    width: 125px;
    position: relative;
  }
  .w100 {
    width: 100px;
  }
  .zindex {
    z-index: 20;
  }
  .w400 {
    width: 400px;
  }
  .el_label {
    width: 100px;
    padding: 0px 10px 11px 0;
    height: 32px;
    line-height: 32px;
    text-align: right;
    vertical-align: middle;
    float: left;
    font-size: 14px;
    color: #48576a;
    box-sizing: border-box;
    img {
      width: 15px;
      height: 15px;
      margin-left: 5px;
      vertical-align: sub;
    }
  }
  .minMop {
    .el-dialog {
      width: 520px;
      min-height: 300px;
      .el-form-item__error {
        left: 37px;
      }
    }
  }
  .el-form-item__error {
    left: 95px !important;
  }
  .count {
    position: absolute;
    top: 15px;
    left: 150px;
  }
  .freightDiscounts-money {
    .el-form-item__error {
      left: 110px !important;
    }
  }
  .ml50 {
    margin-left: 100px;
  }
  .el-message-box__content {
    padding: 30px 35px;
  }
  .specialD {
    position: relative;
    img {
      width: 60px;
      height: 60px;
      display: inline-block;
      margin-right: 5px;
    }
    .special {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  .color-r {
    color: red;
  }
}
</style>
