<template>
    <div id="jicaiListMy" class="pb-50">
        <div class="title_top bg-f clearfix">
            <div class="fl">我的竞标</div>
        </div>
        <div class="bg-f mt-5 pb-50">
            <div class="content bg-f pl-30 pr-30 pb-50">
                <!-- 状态切换区域 -->
                <div class="tab_box">
                    <div class="tab_item" :class="{tab_item_checked: item.type==state}" v-for="(item,index) in tabList"
                         :key="index" @click="checkState(item)">
                        <span>{{item.label}}</span>
                        <span>({{item.bage ? item.bage : 0}})</span>
                    </div>
                </div>
                <!-- 搜索区域 -->
                <div class="clearfix search">
                    <el-form :inline='true' class="pt-30 fl" :model="Form" ref="searchForm">
                        <div style="width:780px">
                            <el-form-item label='商品名称'>
                                <el-input v-model.trim="Form.goodsName" :maxlength="30"
                                          placeholder="输入商品名称"></el-input>
                            </el-form-item>
                            <el-form-item label='招标时间' prop="orderDateRange">
                                <ys-daterange-picker v-model="Form.orderDateRange"/>
                            </el-form-item>
                        </div>
                    </el-form>
                    <el-button class="fl ml-30 mt-30" type='info' @click="getList()">查询</el-button>
                </div>
                <!-- 列表区域 -->
                <div class="pb-30">
                    <el-table :data="List" class='table-default'>
                        <el-table-column label='招标名称' width=""  class-name="plName">
                            <template slot-scope="scope">
                                <span>{{scope.row.name}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label='商品名称' width="">
                            <template slot-scope="scope">
                                <span>{{scope.row.skuName}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label='招标时间' width="80">
                            <template slot-scope="scope">
                                <span>{{scope.row.bidDateFrom | timenData}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label='招标地区' width="70">
                            <template slot-scope="scope">
                                <span>已符合</span>
                            </template>
                        </el-table-column>
                        <el-table-column label='集采价格' width="">
                            <template slot-scope="scope">
                                <span>{{scope.row.bidPriceList}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label='合约时间' width="70">
                            <template slot-scope="scope">
                                <span>&nbsp;&nbsp;&nbsp;&nbsp;{{scope.row.validPeriod}}年</span>
                            </template>
                        </el-table-column>
                        <el-table-column label='状态' width="60">
                            <template slot-scope="scope">
                                <span v-if="scope.row.bidderState==0">竞标中</span>
                                <span v-if="scope.row.bidderState==1">竞标成功</span>
                                <span v-if="scope.row.bidderState==2">竞标失败 </span>
                            </template>
                        </el-table-column>
                        <el-table-column label='操作' width="70">
                            <template slot-scope="scope">
                                <el-button type='text' @click="goDetail(scope)">查看</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-pagination class="tar"
                                   v-if="List.length>0"
                                   :current-page="currentPage"
                                   :page-size='10'
                                   :total='total'
                                   layout="prev , pager, next, jumper"
                                   @current-change="handleCurrentChange">
                        <!-- 分页组件 -->
                    </el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {axiosApi, basePath, baseURI} from "../../api/api";
    // import YsDaterangePicker from "~/components/ys-daterange-picker";

    export default {
        // components: {YsDaterangePicker},
        data() {
            return {
                tabList: [{
                    label: '全部',
                    type: 1,
                    bage: 0
                }, {
                    label: '竞标中',
                    type: 2,
                    bage: 0
                }, {
                    label: '竞标成功',
                    type: 3,
                    bage: 0
                }, {
                    label: '竞标失败',
                    type: 4,
                    bage: 0
                }],
                state: 1,
                currentPage: 1,
                total: 0,
                List: [],
                Form: {
                    orderDateRange: [],   //gmtOrderStart下单开始时间 gmtOrderEnd下单结束时间
                    goodsName: null,
                },

            }
        },
        //进入加载
        mounted() {
            this.$nextTick(function () {
                if (sessionStorage.getItem('jicaiMyType')) {
                    this.state = Number(sessionStorage.getItem('jicaiMyType'));
                    sessionStorage.removeItem("jicaiMyType");
                }
                this.getList()
                this.getBage()
            })
        },
        methods: {
            getBage() {//获取角标
                axiosApi('/trader/bid/count/myBid', {}, (res) => {
                    this.tabList.forEach(item => {
                        if (item.type == 1) {
                            item.bage = res.data.data.waiting + res.data.data.success + res.data.data.failed
                        } else if (item.type == 2) {
                            item.bage = res.data.data.waiting
                        } else if (item.type == 3) {
                            item.bage = res.data.data.success
                        } else if (item.type == 4) {
                            item.bage = res.data.data.failed
                        }
                    })
                }, 1, 10)
            }
            ,
            getList() {
                let state_ = null;
                switch (this.state) {
                    case 1:
                        state_ = null;
                        break;
                    case 2:
                        state_ = 0;
                        break;
                    case 3:
                        state_ = 1;
                        break;
                    case 4:
                        state_ = 2;
                        break;
                }
                let bidDateSearchFrom = !this.Form.orderDateRange[0] ? null : this.Form.orderDateRange[0].getTime()
                let bidDateSearchTo = !this.Form.orderDateRange[1] ? null : this.Form.orderDateRange[1].getTime()
                axiosApi('/trader/bid/list/my', {
                    bidderState: state_,//状态(-1:已删除，0:未开始，1:进行中，2:已完成)
                    bidByManager: false,//是否代投标
                    bidDateSearchFrom: bidDateSearchFrom ? bidDateSearchFrom : null,//招标时间开始
                    bidDateSearchTo: bidDateSearchTo ? bidDateSearchTo : null,//招标时间结束
                    skuName: this.Form.goodsName ? this.Form.goodsName : null,//商品名称
                }, (res) => {
                    this.List = res.data.data.elements ? res.data.data.elements : []
                    this.List.forEach(item => {
                        item.bidPriceList = "满" + item.contractQty + "箱、每瓶" + item.contractPrice + "元、返利" + item.contractRebate + "%"
                    })
                    this.total = res.data.data.totalCount
                }, this.currentPage, 10, (res) => {
                })
            },
            priceToString(bidPriceList) {
                let priceString = "";
                if (bidPriceList) {
                    priceString += "满" + bidPriceList[0].qty + "箱、每瓶" + bidPriceList[0].price + "元、返利" + bidPriceList[0].rebate + "%"
                    if (priceString.length < 19) {
                        priceString += "<BR/>"
                        if (bidPriceList.length > 1) {
                            let type = "满" + bidPriceList[1].qty + "箱、每瓶" + bidPriceList[1].price + "元、返利" + bidPriceList[1].rebate + "%";
                            if (type.length > 19) {
                                priceString += type.substring(0, 17) + "...";
                            } else {
                                priceString += type;
                            }
                        }
                    } else {
                        priceString += "..."
                    }
                    // bidPriceList.forEach(item => {
                    //     priceString += "满" + item.qty + "箱、每瓶" + item.price + "元、返利" + item.rebate + "%<BR/>"
                    //     return false
                    // });
                }
                return priceString;
            }
            ,
            checkState(val) {//切换状态
                this.state = val.type
                this.currentPage = 1
                this.getList()
            }
            ,
            handleCurrentChange(val) {//分页
                this.currentPage = val
                this.getList()
            }
            ,
            goDetail(scope) {//跳转团购详情
                this.$router.push({path: 'JicaiDetailB', query: {id: scope.row.id, bidderId: scope.row.bidderId}})
                sessionStorage.setItem('jicaiMyType', this.state);
            }
        }
        ,
    }
</script>

<style lang="less">
    #jicaiListMy {
        .tab_box {
            height: 60px;
            line-height: 60px;
            box-sizing: border-box;
            border-bottom: 1px solid #eee;

            .tab_item {
                display: inline-block;
                width: 114px;
                height: 26px;
                line-height: 26px;
                text-align: center;
                box-sizing: border-box;
                color: #666;
                font-size: 14px;
                cursor: pointer;
            }

            .tab_item:hover {
                color: #FF4201;
            }

            .tab_item_checked {
                border-radius: 2px;
                border: 1px solid #FF4201;
                color: #FF4201;
            }
        }

        .title_top {
            width: 100%;
            height: 60px;
            line-height: 60px;
            box-sizing: border-box;
            padding-left: 20px;
            font-size: 16px;

            .button_top {
                width: 118px;
                height: 30px;
                line-height: 30px;
                border: 1px solid #DDDDDD;
            }
        }

        .input_short {
            width: 100px;
        }

        .table-default.el-table .cell {
            text-align: left !important;
        }
        .plName .cell {
            margin-left: 30px!important;
        }
    }
</style>