<template>
  <div>
    <!-- 搜索 -->
    <div>
      <el-form :inline="true" :model="form" class="demo-form-inline" label-width="auto">
        <el-form-item label="商户通知">
          <el-input v-model="form.title" placeholder="商户通知" clearable></el-input>
        </el-form-item>
        <el-form-item label="接收对象">
          <el-select
            v-model="form.traderId"
            filterable
            remote
            clearable
            reserve-keyword
            placeholder="请输入接收对象关键词"
            :remote-method="getUser"
            >
            <el-option
              v-for="item in elements"
              :key="item.id"
              :label="item.storeName"
              :value="item.buyerId">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="推送时间">
          <ys-daterange-picker v-model="form.value" type="date"/>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="search">查询</el-button>
        </el-form-item>
      </el-form>
      <div style="text-align:right">
        <el-button type="primary" @click="$router.push(basePath+'/merchanItemAdd')">新增商户通知</el-button>
      </div>
    </div>
    <!-- 列表 -->
    <div style="margin-top:20px">
      <el-table :data="list" border style="width: 100%">
        <el-table-column  prop="title"  label="商户通知" > </el-table-column>
        <el-table-column  prop=""  label="接收对象" > 
          <template slot-scope="{row}">
            <div>{{row.wholePush?'全部':'部分'}}</div>
          </template>
        </el-table-column>
        <el-table-column  prop="Time"  label="推送时间" ></el-table-column>
        <el-table-column  prop="operatorName"  label="操作人" > </el-table-column>
        <el-table-column  label="操作" align="center"> 
          <template slot-scope="{row}">
            <el-link type="danger" @click="submitDelete(row.id)">删除</el-link>
            <el-link type="primary" @click="detail(row.id)">查看详情</el-link>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- 分页 -->
    <div style="text-align:right">
      <el-pagination
        v-if="total>0"
        @current-change="CurrentChange"
        :current-page="form.pageIndex"
        :page-size="form.pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import {axiosApi, basePath, baseURI} from "~/api/api.js";
import {currencyNoticePage,currencyNoticeDel} from "~/api/newsPush";
import { parseTime, computeNumber } from "@/utils";
export default {
  data(){
    return {
      form:{
        pageIndex: 1,
        pageSize:10,
        value:[],
        queryBeginDate: '',
        queryEndDate: '',
      },
      elements:[],
      list:[],
      total:0,
    }
  },
  computed:{
    searchData(){
      // 处理搜索参数
      var data = Object.assign({},this.form)
      data.queryBeginDate = 
        this.form.value && this.form.value[0]
          ? this.form.value[0].getTime()
          : null;
      data.queryEndDate =
        this.form.value && this.form.value[1]
          ? this.form.value[1].getTime()
          : null;
      delete data.value
      return data
    }
  },
  activated(){
    this.getList()
  },
  mounted(){
    this.getList()
  },
  methods:{
    search(){
      this.form.pageIndex = 1
      this.getList()
    },
    CurrentChange(val){
      this.form.pageIndex = val
      this.getList()
    },
    getUser(query){
      var that = this
      var url = '/trader/findClientAllList/select'
      var data = {
        storeName:query,
        sellerId: sessionStorage.traderId,
      }
      if (query !== '') {
          axiosApi(url, data, (res)=> {
            if(res.data.code==='000000'){
              this.elements = res.data.data.elements
            }
          })
      } else {
        this.elements = [];
      }
    },
    getList(){
      currencyNoticePage(this.searchData).then(res=>{
        var list = res.elements.map(item=>{
          item.Time = parseTime(item.pushTime,'{y}-{m}-{d} {h}:{i}')
          return item
        })
        this.list = list
        this.total = res.totalCount
      }).catch(err=>{})
    },
    submitDelete(id) {
      this.$confirm('此操作将永久删除该消息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.deleteInfo(id)
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });          
      });
    },
    deleteInfo(id){
      currencyNoticeDel({id:id}).then(res=>{
        this.$message({
          type: 'success',
          message: '删除成功!'
        });
        this.search()
      }).catch(err=>{})
    },
    detail(id){
      this.$router.push({path:basePath+'/merchantItemDetail',query:{id:id}})
    },
  },
}
</script>

<style>

</style>