<template>
	<div class="applySuccess">
		<!--<div class="title-top bg-f clearfix">申请成功</div>-->
		<div class="content bg-f pl-30 pr-30 mt-5 pt-20">
			<div class="info">
				<img src="../../assets/superc/logo1.png" alt="" />
				<p>恭喜您，申请成功</p>
				<p>请等待审核</p>
				<el-button @click="ensure">确 定</el-button>
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		data(){
			return {

			}
		},
		methods:{
			ensure(){
				this.$router.push({path:'supercIntro'})
			}
		}
	}
</script>
<style lang="less">
	.applySuccess{
		.content{ 
			width: 1000px;
			height: 704px;
			background: url(../../assets/superc/bg1.png) no-repeat 40%;
			background-size: 150% 100%;
			display:table-cell;
        	vertical-align:middle;
			.info{
				img{
					width: 180px;
					height: 180px;
					margin-bottom: 30px;
				}
				font-size: 18px;
				color: #FFFFFF;
				text-align: center;
				line-height: 20px;
			}
			.el-button{
				width: 200px;
				height: 50px;
				margin-top: 40px;
				color: #FF4201;
				font-size: 20px;
				border-radius: 30px;
			}
		}
	}
</style>