<template>
  <!-- 子公司-》门店久集管理-》佣金管理 -->
  <div class="commission-cashout">
    <div class="title-tabs2 bg-f">
			<ul class="clearfix">
				<div v-for='(tab,index) in dataTabs' class="item" :key="index"  @click='handleTabs(index)'>
					<li :class="tab==clickedTabName?'active':''">
						{{tab}}
						<div :class="tab==clickedTabName?'under-line':''"></div>
					</li>
					<div class="vertical-line"></div>
				</div>
			</ul>
		</div>
    <commission-withdraw-online  v-if="clickedTabName == '佣金提现'" :supercType="3"></commission-withdraw-online>
    <commission-details v-if="clickedTabName == '佣金明细'" :supercType="3"></commission-details>
  </div>
</template>

<script>
	import { axiosApi, basePath } from "../../../api/api";
  import commissionDetails from '../commissionDetails'
  import commissionWithdrawOnline from '../supercComponent/commissionWithdrawOnline'
  export default {
    components: {
      commissionDetails,
      commissionWithdrawOnline
    },
    data() {
      return {
        dataTabs: ['佣金提现', '佣金明细'],
        clickedTabName: '佣金提现'
      }
    },
    methods: {
      handleTabs(index) {//头部Tab切换
				this.clickedTabName = this.dataTabs[index]
			},
    },
    mounted() {
			this.clickedTabName = this.dataTabs[0]
		}
  }
</script>

<style lang="less">
</style>