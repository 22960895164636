<template>
	<div id="checkDetail" v-loading="pictureLoading">
		<div class="title-top bg-f">
			{{title}}
		</div>
		<div class="mt-5 bg-f pl-30 pt-30 pr-30 pb-50">
			<div :class="{reduce:spread}">
				<label class="label"><span class="color-danger mr3">*</span>标题：</label>
				<el-input placeholder="请输入标题" v-model.trim="info.configTitle"></el-input>
			</div>
			<div class="mt-30 clearfix">
				<label class="fl label"><span class="color-danger mr3">*</span>考勤时间：</label>
				<ul class="week fl">
					<li v-for="(item, index) in weeklist" :key="index" :class="{active:item.active}" @click="changeDay(item,index)">
						{{item.day}}
					</li>
					<!--等后台给字段-->
					<li><el-checkbox class="mr-5" v-model="info.clockHolidays"></el-checkbox>法定假期不计考勤</li>
				</ul>
			</div>
			<div class="mt-30">
				<label class="label"><span class="color-danger mr3">*</span>上班时间：</label>

				<el-time-select v-model="info.signOnTime" ref="timeSelect" :editable="true" :picker-options="{
				    start: '06:00',
				    step: '00:15',
				    end: '24:00'
				  }" placeholder="选择上班时间">
				</el-time-select>
				<label class="ml-40"><span class="color-danger mr3">*</span>下班时间：</label>
				<el-time-select v-model="info.signOutTime" ref="timeSelect2" :editable="true" :picker-options="{
				    start: '06:00',
				    step: '00:15',
				    end: '24:00',
				    minTime: info.signOnTime
				  }" placeholder="选择下班时间">
				</el-time-select>
				<span class="gray">（支持自定义上下班时间，例：08:29）</span>
			</div>
			<div class="gray" style="marginLeft: 90px;marginTop: 10px; color: #ff4201;">时间调整后，次日生效</div>
			<div class="mt-30 bbd pb-20 pb-30 clearfix">
				<label class="label">上班地点：</label>
				<span class="bd p57 dib" v-if="placeobj.checkname">{{placeobj.checkname}}<i class="iconfont icon-fail ml-5 cursor" @click="placeobj={}"></i></span>
				<el-button type="info" @click="opencheck" class="ml-5" :disabled="placeobj.checkname?true:false">新增</el-button>
			</div>
			<div class="mt-30 bbd pb-30 clearfix">
				<label class="fl label">定义异常：</label>
				<div class="fl mt-6">
					<p>
						<span class="dib w295"><span class="color-danger mr3">*</span>签到晚
						<sy-input type="int" v-model="info.lateRange" size="mini" :min="1" :max="999" class="mr-10"></sy-input>分钟以上视为迟到</span>
						<span class="ml-50"><span class="color-danger mr3">*</span>签退早
						<sy-input type="int" v-model="info.leaveRange" size="mini" :min="1" :max="999" class="mr-10"></sy-input>分钟以上视为早退</span>
					</p>
					<p class="mt-30">
						<span class="dib w295"><span class="color-danger mr3">*</span>迟到
						<sy-input type="int" v-model="info.lateAbsenteeismHalf" size="mini" :min="1" :max="999" class="mr-10"></sy-input>分钟以上视为旷工半天</span>
						<span class="ml-50"><span class="color-danger mr3">*</span>迟到
						<sy-input type="int" v-model="info.lateAbsenteeismOne" size="mini" :min="1" :max="999" class="mr-10"></sy-input>分钟以上视为旷工一天</span>
					</p>
					<p class="mt-30">
						<span class="dib w295"><span class="color-danger mr3">*</span>早退
						<sy-input type="int" v-model="info.leaveAbsenteeismHalf" size="mini" :min="1" :max="999" class="mr-10"></sy-input>分钟以上视为旷工半天</span>
						<span class="ml-50"><span class="color-danger mr3">*</span>早退
						<sy-input type="int" v-model="info.leaveAbsenteeismOne" size="mini" :min="1" :max="999" class="mr-10"></sy-input>分钟以上视为旷工一天</span>
					</p>
				</div>
			</div>
      <div class="mt-30 bbd pb-30 clearfix">
        <label>自动签退时间：</label>
        <el-time-select v-model="info.autoSignOutTime" ref="timegmtSignOut" :editable="true" :picker-options="{
            start: '16:00',
            step: '00:15',
            end: '24:00',
          }" placeholder="选择自动签退时间">
        </el-time-select>
      </div>
			<div class="mt-30 clearfix">
				<span class="fl"><span class="color-danger mr3">*</span>执行部门/人员</span>
				<div class="fl ml-60 w780">
					<el-checkbox v-if="allIdList.length" style="float: right;marginRight: 30px;margin-bottom: 10px;margin-top: -10px;" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
					<div class="clearfix mt-10 manager_list" id="manager-list">
						<el-tree :data="departments" :props="defaultProps" default-expand-all :expand-on-click-node="false" :render-content="renderContent"></el-tree>
						<el-checkbox-group v-if="managerList.length" v-model="manarr" @change="handleCheckedChange">
							<el-checkbox :title="item.disabled ? '该人员已配置'+item.clockWeekListToText.join('、')+'，无法勾选' : null" v-for="(item, index) in managerList" :label="item.managerId" :key="index" :disabled="item.disabled">{{item.employeeName}}</el-checkbox>
						</el-checkbox-group>
						<div v-else style="marginLeft: 220px;paddingTop: 10px;">暂无人员</div>

					</div>
				</div>
			</div>
			<el-button type="danger" class="mt-30" @click="hanlesave">保存</el-button>
			<check :cVisible="cVisible" @closedialog="closecheck">
			</check>
		</div>
	</div>
</template>

<script>
	import { axiosApi, basePath } from "../../api/api"
	import { MessageBox } from 'element-ui'
	export default {
		data() {
			return {
				pictureLoading: false,
				info: {
					clockHolidays:true,//是否节假日记考勤（0否，1：是）
					configTitle: "", //考勤标题
					signOutTime: "16:00", //签退时间
					signOnTime: "06:00", //签到时间
					lateRange: "", //迟到范围
					leaveRange: "", //早退范围
					lateAbsenteeismHalf: "", //迟到旷工半天范围
					lateAbsenteeismOne: "", //迟到旷工一天范围
					leaveAbsenteeismHalf: "", //早退旷工半天范围
          leaveAbsenteeismOne: "", //早退旷工一天范围
          autoSignOutTime: "",//自动签退时间
				},
				initManagerList: [], // 接口获取的所有人员
				manarr: [], //选中的人员
				editarr: [], //编辑时候选中的人员
				departments: [], //部门
				defaultProps: {
					'children': 'sub',
					'label': 'text'
				},
				weeklist: [{
					active: false,
					label: "2",
					day: "周一"
				}, {
					active: false,
					label: "3",
					day: "周二"
				}, {
					active: false,
					label: "4",
					day: "周三"
				}, {
					active: false,
					label: "5",
					day: "周四"
				}, {
					active: false,
					label: "6",
					day: "周五"
				}, {
					active: false,
					label: "7",
					day: "周六"
				}, {
					active: false,
					label: "1",
					day: "周日"
				}],
				placeobj: {},
				cVisible: false,
				title:'',
				spread :false,
				checkAll:false,
				allIdList: [],
				alreadyMan: [], //已添加id数组,model可变
				alreadyManList: [], //存储已添加数据的对象数组
				rules: []
			}
		},
		computed: {
			managerList() {
				let nnn = this.initManagerList
				let allid = this.allIdList.slice(0)
				this.weeklist.forEach((dayitem) => {
					if(dayitem.active) {
						for(let i = 0;i < nnn.length; i++) {
							if(nnn[i].clockWeekList.length && this.isInArray(nnn[i].clockWeekList, dayitem.label)) {
								nnn[i].disabled = true
								if (this.isInArray(this.manarr, nnn[i].managerId)) {
									this.manarr.forEach((item,index) => {
										if (item == nnn[i].managerId) {
											this.manarr.splice(index, 1)
										}
									})
								}
							}
						}
					}
				})
				return nnn;
			}
		},
		mounted() {
			this.pictureLoading = true
			this.getdepartments()
			.then(() => {
				this.pictureLoading = false
				setTimeout(() => {
					this.$nextTick(() => {
						let par = document.getElementById('manager-list')
						let arr = par.getElementsByClassName('el-tree-node__content')
						if(arr.length) {
							arr[0].className = "el-tree-node__content tree-active";
						}
					})
				}, 100)
			})
			
			if(this.$route.params.id) {
				this.title = '编辑考勤'
				this.geteditinfo()
			}else{
				this.title = '新增考勤'
			}
		},
		methods: {
			isInArray(arr,value){
				for(var i = 0; i < arr.length; i++){
					if(value === arr[i]){
						return true;
					}
				}
				return false;
			},
			changeDay(item,index) {
				item.active = !item.active
				//选中操作，如某个用户不可选，禁用且取消选中；取消选中操作，放开可选的，但是不帮用户选中任何一个
				if (item.active ) { //选中
					let nnn = this.initManagerList
					let allid = this.allIdList.slice(0)
					this.weeklist.forEach((dayitem) => {
						if(dayitem.active) {
							for(let i = 0;i < nnn.length; i++) {
								if(nnn[i].clockWeekList.length && this.isInArray(nnn[i].clockWeekList, dayitem.label)) {
									nnn[i].disabled = false
									if (this.isInArray(this.manarr, nnn[i].managerId)) {
										this.manarr.forEach((item,index) => {
											if (item == nnn[i].managerId) {
												this.manarr.splice(index, 1)
											}
										})
										nnn[i].disabled = true
									}	
								}
							}
						} else {
							for(let i = 0;i < nnn.length; i++) {
								nnn[i].disabled = false
							}
						}
					})
				} else { //取消选中
					this.initManagerList.forEach(item => {
						item.disabled = false
					})
				}
			},
			//前端验证
			checkfield() {
				let reg = /^(0[6-9]|1[0-9]|2[0-3]):([0-5][0-9])|24:00$/;
				// let diySignOnTime = this.$refs.timeSelect.$refs.reference.currentValue ? this.$refs.timeSelect.$refs.reference.currentValue : '';
				// let diySignOutTime = this.$refs.timeSelect2.$refs.reference.currentValue ? this.$refs.timeSelect2.$refs.reference.currentValue : '';
				let diySignOnTime = this.info.signOnTime ? this.info.signOnTime : '';
				let diySignOutTime = this.info.signOutTime ? this.info.signOutTime : '';
				let outLaterThanOn = false //下班时间比上班时间晚，默认赋值false
                console.log(diySignOnTime);
                if(diySignOnTime.length == 5 && diySignOutTime.length == 5 && reg.test(diySignOnTime) && reg.test(diySignOutTime)){
					let a1 = diySignOnTime.replace(/[:]/g,"");
					let a2 = diySignOutTime.replace(/:/g,'');
					if(parseInt(a2) > parseInt(a1)) {
						outLaterThanOn = true
					}
				}
				this.rules = [{
					name: this.info.configTitle,
					message: "请输入考勤标题"
				}, {
					name: this.weeklist.filter(item => {
						return item.active
					}),
					message: "请选择考勤时间"
				}, {
					name: diySignOnTime.length == 5 && reg.test(diySignOnTime) ? diySignOnTime : '',
					message: "请选择或者输入合理的上班时间"
				}, {
					name: diySignOutTime && reg.test(diySignOutTime) && outLaterThanOn ? diySignOutTime : '',
					message: "请选择或者输入合理的下班时间"
				}, {
					name: this.info.lateAbsenteeismHalf,
					message: "请输入迟到旷工半天范围"
				}, {
					name: this.info.lateAbsenteeismOne,
					message: "请输入迟到旷工一天范围"
				}, {
					name: this.info.leaveAbsenteeismHalf,
					message: "请输入早退旷工半天范围"
				}, {
					name: this.info.leaveAbsenteeismOne,
					message: "请输入早退旷工一天范围"
				}, {
					name: this.info.lateRange,
					message: "请输入迟到范围"
				}, {
					name: this.info.leaveRange,
					message: "请输入早退范围"
				}, {
					name: this.editarr,
					message: "请选择人员"
				}]
				let b = true
				this.rules.every((item, index) => {
					if(item.name.length == 0) {
						b = false
						this.$message({
							type: 'warning',
							message: item.message,
							duration: 2000
						})
						return false
					}
					if(index == 0) {
						if(!this.titlechange()) {
							b = false
							return false
						} else {
							b = true
						}
					}
					return true
				})
				parseInt()
				if(parseInt(this.info.lateAbsenteeismHalf)>=parseInt(this.info.lateAbsenteeismOne)) {
					b = false
					this.$message({
						type: 'info',
						message: '定义异常的迟到时间配置不合理',
						duration: 2000
					})
				}

				if(parseInt(this.info.leaveAbsenteeismHalf)>=parseInt(this.info.leaveAbsenteeismOne)) {
					b = false
					this.$message({
						type: 'info',
						message: '定义异常的早退时间配置不合理',
						duration: 2000
					})
        }
        if(parseInt(this.info.autoSignOutTime) < parseInt(this.info.signOutTime) ){
          b = false
          this.$message({
						type: 'info',
						message: '自动签退时间不能早于签退时间',
						duration: 2000
					})
        }
				return b
			},
			//标题的验证
			titlechange() {
				let reg = /^([A-Za-z]|[\u4E00-\u9FA5]|[0-9]){1,20}$/
				if(!reg.test(this.info.configTitle)) {
					this.spread = true
					this.$message({
						type: 'info',
						message: "请输入1-20个字符，可为汉字、字母和数字",
					})
					return false
				} else {
					this.spread = false
					return true
				}
			},
			//编辑，获取后台返回数据
			geteditinfo() {
				axiosApi("/sales/clockConfig/selectDetails", {
					id: this.$route.params.id
				}, res => {
					if(res.data.data) {
						this.info = res.data.data
						this.alreadyManList = []
						this.alreadyMan = []
						this.info.managerConfigList.forEach(item => {
							this.alreadyManList.push(item)
							this.alreadyMan.push(item.managerId)
						})
						this.info.clockWeekList.forEach(item1 => {
							this.weeklist.forEach(item2 => {
								if(item2.label == item1) {
									item2.active = true
								}
							})
						})
						this.info.clockHolidays = this.info.clockHolidays == "1"?true:false
						this.placeobj = {
							signOnLongitude: this.info.signOnLongitude,
							signOnLatitude: this.info.signOnLatitude,
							checkname: this.info.configAddrName,
							checkaddress: this.info.configAddr,
							checkremark: this.info.placeClockRemarks,
							checkrange: this.info.placeRange,
						}
						this.alreadyMan.forEach(item => {
							this.manarr.push(item)
						})
					}
				})
			},
			//保存
			hanlesave() {
				this.editarr = []
				this.manarr.forEach(item1 => { //新添加
					this.editarr = this.editarr.concat(this.managerList.filter(item2 => item2.managerId == item1))
				})
				if(!this.checkfield()) return false //验证
				this.editarr = this.editarr.map(item => {
					return {
						managerId: item.managerId,
						managerName: item.managerName ? item.managerName : item.employeeName
					}
				})
				let list = []
				this.weeklist.forEach(item => {
					if(item.active) {
						list.push(item.label)
					}
				})
				let body = {
					configTitle: this.info.configTitle,
					clockWeekList: list,
					signOnTime: this.info.signOnTime,
					signOutTime: this.info.signOutTime,
					signOnLongitude: this.placeobj.signOnLongitude,
					signOnLatitude: this.placeobj.signOnLatitude,
					configAddrName: this.placeobj.checkname,
					configAddr: this.placeobj.checkaddress,
					placeClockRemarks: this.placeobj.checkremark,
					placeRange: this.placeobj.checkrange,
					lateRange: this.info.lateRange,
					lateAbsenteeismHalf: this.info.lateAbsenteeismHalf,
					lateAbsenteeismOne: this.info.lateAbsenteeismOne,
					leaveRange: this.info.leaveRange,
					leaveAbsenteeismHalf: this.info.leaveAbsenteeismHalf,
					leaveAbsenteeismOne: this.info.leaveAbsenteeismOne,
					managerConfigList: this.editarr,
					clockHolidays:this.info.clockHolidays?"1":"0"
        }
        if(this.info.autoSignOutTime){
          body.autoSignOutTime = this.info.autoSignOutTime
        }
				let api = ""
				if(this.$route.params.id) {
					body = Object.assign({}, body, {
						id: this.$route.params.id
					})
					api = "/sales/clockConfig/update"
				} else {
					api = "/sales/clockConfig/insert"
				}
				axiosApi(api, body, res => {
					this.$router.push(basePath + "/settings")
					this.$message({
						type: 'success',
						message: "操作成功",
						duration: 1000
					})
				}, 1, 10, res => {
					MessageBox.alert(res.data.message, '提示', {
						confirmButtonText: '确定'
					});
				})
			},
			//获取部门
			getdepartments() {
				return new Promise((resolve, reject) => {
					axiosApi("/sales/clockTrack/web/departments", {}, res => {
						this.departments = res.data.data ? res.data.data : [];
						//获取第一个节点的数据
						if (this.departments.length) {
							this.search(this.departments[0])
						}
						resolve()
					}, null, null, err => {
						reject()
					})
				})
				
			},
			getmanager(list, val) {
				if (this.$route.params.id) { //编辑时候新的接口，返回的每个人的考勤日期过滤的当前可选的
					axiosApi("/sales/clockconfig/web/query", { 
						clockConfigId: this.$route.params.id,
						departmentId: list,
						isDeleted: 0
					}, res => {
						if(res.data.data) {
							this.initManagerList = res.data.data
							this.initManagerList.forEach(item => {
								item.disabled = false
								item.clockWeekListToText = []
								item.clockWeekList.forEach(day => {
									switch(day) {
										case "1":
											item.clockWeekListToText.push('周日')
										break;
										case "2":
											item.clockWeekListToText.push('周一')
										break;
										case "3":
											item.clockWeekListToText.push('周二')
										break;
										case "4":
											item.clockWeekListToText.push('周三')
										break;
										case "5":
											item.clockWeekListToText.push('周四')
										break;
										case "6":
											item.clockWeekListToText.push('周五')
										break;
										case "7":
											item.clockWeekListToText.push('周六')
										break;
										default:
										
									}
								})
							})
						}
						if(val.parentId === 0) {
							this.allIdList = []
							this.initManagerList.forEach(item => {
								this.allIdList.push(item.managerId)
							})
						}
					})
				} else {
					axiosApi("/sales/clockTrack/web/managerCount", {
						departmentId: list,
						resultType: "1",
						isDeleted: 0
					}, res => {
						if(res.data.data) {
							this.initManagerList = res.data.data
							this.initManagerList.forEach(item => {
								item.disabled = false
								item.clockWeekListToText = []
								item.clockWeekList.forEach(day => {
									switch(day) {
										case "1":
											item.clockWeekListToText.push('周日')
										break;
										case "2":
											item.clockWeekListToText.push('周一')
										break;
										case "3":
											item.clockWeekListToText.push('周二')
										break;
										case "4":
											item.clockWeekListToText.push('周三')
										break;
										case "5":
											item.clockWeekListToText.push('周四')
										break;
										case "6":
											item.clockWeekListToText.push('周五')
										break;
										case "7":
											item.clockWeekListToText.push('周六')
										break;
										default:
										
									}
								})
							})
						}
						if(val.parentId === 0) {
							this.allIdList = []
							this.initManagerList.forEach(item => {
								this.allIdList.push(item.managerId)
							})
						}
					})
				}
			},
			opencheck() {
				this.cVisible = true
			},
			closecheck(item) {
				if(item) {
					this.placeobj = item
				}
				this.cVisible = false
			},
			renderContent(h, { node, data, store }) {
			    return (
			        <span on-click={ () => this.search(data) }>
			            <span class="el-tree-bg" >{node.label}</span>
			    	</span>
			    );
			},
			search(data){
				let tree = document.getElementsByClassName('el-tree-node__content');
				for(var i=0;i<tree.length;i++){
					tree[i].onclick = function(){
						for(var i=0;i<tree.length;i++){
							tree[i].className = "el-tree-node__content";
						}
						this.className = "el-tree-node__content tree-active";
					}
				}
				this.getmanager(data.id,data)
			},
			handleCheckAllChange(event) { //待添加全选
				if(event) {
					let newinitidlist = this.initManagerList.filter(aa => {
						return aa.disabled == false
					})
					this.manarr = []
					newinitidlist.forEach(item => {
						this.manarr.push(item.managerId)
					})
				} else {
					this.manarr = []
				}
		    },
		    handleCheckedChange(value) {
		        let checkedCount = value.length;
		        this.checkAll = checkedCount === this.allIdList.length;
		    }
		}
	}
</script>

<style lang="less">
	#checkDetail {
		.gray {
			color: #666;
		}
		.label {
			display: inline-block;
			width: 83px;
			text-align: right;
		}
		.p57 {
			padding: 5px 7px;
			margin-right: 15px;
		}
		.mt-6 {
			margin-top: -6px;
		}
		.mr3 {
			margin-right: 3px;
		}
		.w780 {
			width: 780px;
			.el-tree {
				width: 200px;
				border: 0;
				border-right: 1px solid #ddd;
			}
		}
		.w555 {
			width: 555px;
			.el-checkbox {
				margin: 0 10px 10px 0;
			}
		}
		.w275 {
			width: 275px;
		}
		.w295 {
			width: 295px;
		}
		.el-input--mini {
			width: 80px;
			margin: 0 5px;
		}
		.bg-f6 {
			background-color: #f6f6f6;
			padding: 10px 10px 0;
		}
		.week {
			li {
				float: left;
				padding: 0 10px;
				margin: 0 5px;
				&:not(:last-child) {
					cursor: pointer;
					border: 1px solid #ddd;
					border-radius: 5px;
				}
				&.active {
					color: #20a0ff;
					border-color: #20a0ff;
					border-radius: 5px;
				}
			}
		}
		.reduce{
			.el-input__inner{
				border-radius: 0;
    			border: 1px solid red;
			}
		}
		.el-tree-bg{
			padding-left: 25px;
			margin-left: -3px;
			background: url(../../assets/icon-1.png) no-repeat 0 40%;
		}
		.tree-active{
			color: #20a0ff;
			background: #F4F5F9;
		}
		.el-tree-node{
			white-space: nowrap;
		}
		.manager_list{
			.el-tree{
				float: left;
			}
			.el-checkbox-group{
				float: left;
				width: 550px;
				label.el-checkbox{
					margin-left: 15px;
				}
			}
		}
		.el-checkbox__input.is-disabled .el-checkbox__inner {
			cursor: default;
		}
		.el-checkbox__input.is-disabled+.el-checkbox__label {
			cursor: default;
		}
	}
</style>