import Ecard_article from '../views/official/article_e.vue' //文章管理
import CompanyCardSet from '../views/official/companyCardSet.vue' //商品详情，基本信息
import Ecard_articleDetails from '../views/official/articleDetails_e.vue' //文章新增、编辑



export const ecFirstRouters = [];

export const ecHomeRouters = [
];
export const ecHomeLogoRouters = [];



export const ecHomeMenuRouters = [
    {
        path: '/official/article', //官网新闻管理
        component: Ecard_article,
        meta: {
            keepAlive: true
        },
        name:'ecard_article',
        text:'官网新闻管理'
    },
    {
        path: '/official/recruitment', // 官网招聘信息
        component:  resolve => require(['../views/official/recruitment/index.vue'], resolve),
        meta: {
            keepAlive: true
        },
        name:'ecard_article',
        text:'官网招聘信息'
    },
    {
        path: '/official/advertisement', //官网轮播图管理
        component:  resolve => require(['../views/official/advertisement/index.vue'], resolve),
        meta: {
            keepAlive: true
        },
        name:'ecard_article',
        text:'官网轮播图管理'
    },
    {
        path: '/ecard/article', //电子名片文章管理
        component: Ecard_article,
        meta: {
            keepAlive: true
        },
        name:'ecard_article',
        text:'电子名片新闻管理'
    },
 
    {
        path:'/official/companyCardSet', //电子名片模板管理
        component: CompanyCardSet,
        meta: {
            keepAlive: true
        },
        name:'companyCardSet',
        text:'名片模板管理'
    },
	{
		path:'/official/articleDetails', //文章编辑，管理
		component: Ecard_articleDetails,
        name:'ecard_articleDetails',
        text:'文章编辑管理',
    }, 
    {
        path: '/ecard/goodsInfo', // 企业产品
        component: () => import('~/views/official/goodsInfo/index.vue'),
        name:'goodsInfoEcard',
        text:'企业产品'
    },
    {
        path: '/ecard/goodsInfoList', // 企业产品-添加商品列表
        component: () => import('~/views/official/goodsInfo/goodsInfoList.vue'),
        name:'goodsInfoList',
        text:'商品列表'
    },
    {
        path: '/ecard/goodsInfoDetails', // 企业产品-商品详情
        component: () => import('~/views/official/goodsInfo/goodsInfoDetails.vue'),
        name:'goodsInfoDetails',
        text:'商品详情'
    },
];

export const ecCarLogoRouters = [];