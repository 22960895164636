<template>
  <div id="invoice-dialog">
    <el-dialog
      title="开具发票"
      :visible.sync="dialogVisible"
      size=""
      @close="goback"
      >
      <div class="items" :style="{'margin-bottom':'80px'}">
        <div>发票类型: {{info.orderInvoiceVo && info.orderInvoiceVo.personalCompanyType == '1' ? '单位' : '个人'}}</div>
        <div v-if="info.orderInvoiceVo && info.orderInvoiceVo.personalCompanyType == '1'">公司名称: {{info.orderInvoiceVo.companyHead}}</div>
        <div v-if="info.orderInvoiceVo && info.orderInvoiceVo.personalCompanyType == '1'">纳税人识别号: {{info.orderInvoiceVo.taxpayerIdNo}}</div>
        <div v-if="info.orderInvoiceVo">发票金额: ¥{{info.orderInvoiceVo.needPayMoney}}</div>
        <div v-if="info.orderInvoiceVo">
          发票地址：
          {{info.orderInvoiceVo.linkman}}
          {{info.orderInvoiceVo.tel}}
          {{info.orderInvoiceVo.provinceName}}
          {{info.orderInvoiceVo.cityName}}
          {{info.orderInvoiceVo.regionName}}
          {{info.orderInvoiceVo.addr}}
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="writeinvoice()">确认开票</el-button>
        <el-button @click="goback">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import { axiosApi,basePath } from '../api/api' //导入axiosApi
  export default {
    props: ["info", "visible", 'sn', 'yunfei'],
    data() {
      return {
        dialogVisible: false
      }
    },
    watch: {
      visible(val) {
        this.dialogVisible = val
      }
    },
    methods: {
      writeinvoice() {
        const api = "/order/invoice/makeout"
        axiosApi(api, {orderSn: this.sn,orderType:'2'}, ()=>{
          this.$notify({
            type: 'success',
            message: '开具发票成功'
          })
          this.goback()
          this.$emit('reload')
        },0,10,(res)=>{
          this.$notify({
            type: 'warning',
            message: res.data.message
          })
        })
      },
      goback() {
        this.$emit('close')
      }
    }
  }
</script>

<style lang="less" scoped>
  .items {
      width: 360px;
      margin: 0 70px;
      &>div {
        margin-top: 12px;
      }
    }
</style>