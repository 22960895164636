<template>
	<!--久集仓库-->
	<div id="Jdepot">
		<div class="title-top bg-f clearfix">久集仓库</div>
		<div class="content bg-f pr-20 pl-20 mt-5">
			<div class="clearfix search">
				<el-form :inline='true' class="pt-30 fl" :model="form" ref="searchForm">
					<el-form-item class="mr-30" label='商品编码' prop="goodsNo">
						<el-input v-model="form.goodsNo" placeholder="请输入商品编码" :maxlength="20" @input="form.goodsNo=form.goodsNo.replace(/[\u4e00-\u9fa5]/ig,'')"></el-input>
					</el-form-item>
					<el-form-item class="mr-30" label='商品名称'>
						<el-input v-model.trim="form.skuName" placeholder="请输入商品名称" :maxlength="30"></el-input>
					</el-form-item>
					<el-form-item class="mr-30" label='商品分类'>
						<el-select v-model="form.classifyId" placeholder="请选择商品分类" clearable>
							<el-option v-for='(tab,index) in classList' :label="tab.classifyName" :value="tab.classifyId" :key='index'></el-option>
						</el-select>
					</el-form-item>
					<el-form-item class="mr-30" label='商品状态'>
						<el-select v-model="form.skuState" placeholder="请选择商品状态" clearable>
							<el-option label="全部状态" value=null></el-option>
							<el-option label="未上架" value='1'></el-option>
							<el-option label="已上架" value='2'></el-option>
						</el-select>
					</el-form-item>
					<el-form-item class="mr-30" label='K3仓库' v-if="isK3Seller">
						<!--<el-input v-model.trim="form.stockNum" placeholder="请输入K3仓库" :maxlength="30"></el-input>-->
						<el-select v-model="form.storeType" placeholder="请选择仓库" clearable>
							<!--<el-option label="全部仓库" value=null></el-option>-->
							<el-option label="统一仓" value='1'></el-option>
							<el-option label="久集特殊仓" value='2'></el-option>
						</el-select>
					</el-form-item>
				</el-form>
				<div>
					<el-button type='info' @click="achive(true)" icon="el-icon-search">搜 索</el-button>
				</div>
			</div>
			<div class="clearfix mb-10" style="overflow: hidden;">
				<p class="fr mr-10 cursor font-12 color-blue mt-15" v-if="isK3Seller" @click='syncK3'>同步</p>
				<p class="fr mr-30 cursor font-12 color-blue mt-15" @click='exportExcel'>批量导出</p>
			</div>
			<div class="pb-20">
				<el-table :data="tableData" @selection-change="handleSelectionChange">
					<el-table-column type="selection" min-width="50"></el-table-column>
					<el-table-column label='商品名称' min-width="220">
						<template slot-scope="scope">
                            <div style="display: flex">
                                <div class="fl">
                                    <img  alt="" v-lazy="scope.row.fileUrl"  />
                                </div>
                                <div class="fl lh-16" style="flex: 1;width: 0">
                                    <div class="width-130">
                                        商品编码:<span v-if="scope.row.goodsNo">{{scope.row.goodsNo}}</span>
                                        <span v-else>无</span>
                                    </div>
                                    <div class="width-130" show-overflow-tooltip>{{scope.row.skuName}}</div>
                                </div>
                            </div>

						</template>
					</el-table-column>
					<el-table-column label='规格' min-width="90">
						<template slot-scope="scope">
							<span>{{scope.row.specification}}</span>
						</template>
					</el-table-column>
					<el-table-column label='商品分类' min-width="90">
						<template slot-scope="scope">
							<span>{{scope.row.classifyName}}</span>
						</template>
					</el-table-column>
					<el-table-column label='商品品牌' min-width="90">
						<template slot-scope="scope">
							<span>{{scope.row.brandName}}</span>
						</template>
					</el-table-column>
					<el-table-column label='K3仓库' min-width="90" v-if="isK3Seller">
						<template slot-scope="scope">
							<span>{{scope.row.storeType==1?"统一仓":"久集特殊仓"}}</span>
						</template>
					</el-table-column>
					<el-table-column label='商品状态' min-width="100">
						<template slot-scope="scope">
							<span v-if="scope.row.skuState==1">待上架</span>
							<span v-if="scope.row.skuState==2">已上架</span>
						</template>
					</el-table-column>
					<el-table-column label='可用库存' min-width="100">
						<template slot-scope="scope">
							<span>{{scope.row.stockEnableQuantity}}</span>
						</template>
					</el-table-column>
					<!--<el-table-column label='库存' width="">
						<template slot-scope="scope">
							<span>{{scope.row.stockQuantity}}</span>
						</template>
					</el-table-column>-->
					<el-table-column label='操作' align="center" min-width="150">
						<template slot-scope="scope">
							<el-button type="text" v-if="isK3Seller" @click='syncK3(scope.$index, scope.row)'>同步</el-button>
							<span v-else>-</span>
						</template>
					</el-table-column>
				</el-table>
				<el-pagination class="tar" 
					v-if="tableData.length>0" 
					:current-page="currentPage" 
					:page-size='10' 
					:total='total' 
					layout="prev , pager, next, jumper" 
					@current-change="handleCurrentChange">
					<!-- 分页组件 -->
				</el-pagination>
			</div>
		</div>
		<!--导出-->
		<form ref="exportList" :action='exportApi' style="display: none">
			<input type="text" name="startRow" v-model="a">
			<input type="text" name="startCol" v-model="a">
			<input type="text" v-if="form.skuName" name="skuName" v-model="form.skuName">
			<input type="text" v-if="form.classifyId" name="classifyId" v-model="form.classifyId">
			<input type="text" v-if="form.skuState" name="skuState" v-model="form.skuState">
			<input type="text" v-if="form.goodsNo" name="goodsNo" v-model="form.goodsNo">
			<input type="text" v-if="form.storeType" name="storeType" v-model="form.storeType">
			<input type="text" v-if="idStr" name="idStr" v-model="idStr">
			<input type="text" name="fileName" v-model="fileName">
			<input type="text" name="modelName" v-model="modelName">
		</form>
	</div>
</template>

<script>
	import { axiosApi, basePath } from "../../api/api";
	export default {
		data() {
			return {
				form: {
					skuName: null, //商品名称
					goodsNo: null, //商品编码
					classifyId: null, //商品分类
					skuState: null, //商品状态
					storeType: null,//K3仓库
				},
				tableData: [], //商品数据
				classList: [], //分类数据
				currentPage: 1,
				total: null,//页面分页总数
				isK3Seller: false,//是否k3 
				a: 0,
				idStr:[],//全部导出选中列表
				fileName: null,//导出文件名称
				exportApi: null,
				modelName: null,
				goodsList: [],//全部商品
				skuNoList: [], //Sku数组
				stockNumList: [],//对应的库存编码数组
			}
		},
		mounted() {
			this.$nextTick(() => {
				axiosApi("/superc/seller/isK3Seller", {}, (res) => {
					this.isK3Seller = res.data.data.isK3Seller;
				}, this.currentPage, 10, (res) => {
					this.$message({type: 'info',message: res.data.message,duration: 3000});
				});
				this.achive(); //默认加载商品列表
				this.getClassList(); //加载分类列表
			})
		},
		methods: {
			getClassList() { //获取分类列表
				axiosApi("/superc/classify/selectFirstClassifyList", {}, (res) => {
					this.classList = res.data.data;
				}, this.currentPage, 10, (res) => {
					this.$message({type: 'info',message: res.data.message,duration: 3000});
				});
			},
			achive(val) { //获取已添加商品列表
				if(val){ //重置为1
					this.currentPage = 1
				}
				const api = "/superc/traderGoods/selectForStockPageList";
				const body = {
					skuName: this.form.skuName,
					classifyId: this.form.classifyId,
					skuState: this.form.skuState,
					goodsNo: this.form.goodsNo,
					storeType: this.form.storeType,
				};
				this.$refs.searchForm.validate((valid)=>{
					if(valid){
						axiosApi(api, body, (res) => {
							//console.log(res, '商品列表')
							this.tableData = res.data.data.elements;
							this.total = res.data.data.totalCount;
						}, this.currentPage, 10, (res) => {
							this.$message({
								type: 'info',
								message: res.data.message,
								duration: 3000
							});
						});
					}
				})
			},
			syncK3(index, row){
				this.skuNoList = [];
				this.stockNumList = [];
				if(row){
					this.skuNoList.push(row.skuNo);
					this.stockNumList.push(row.stockNum);
				}else {
					this.getIdsStr()
				}
//				console.log(this.skuNoList,this.stockNumList,'ididi')
				if(this.skuNoList.length>0 || this.stockNumList.length>0) {
					this.syncEnt();
				} else {
					this.$message({
						type: 'info',
						message: '您未选择任何商品，请先选择！',
						duration: 3000
					});
				}
			},
			syncEnt(){
				let that = this;
				this.$confirm('确定要同步K3吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					const api = "/superc/seller/synK3Stock";
					const body = { skuNoList: this.skuNoList, stockNumList: this.stockNumList };
					axiosApi(api, body, (res) => {
						this.$message({
							type: 'success',
							message: '同步成功!',
							duration: 1000
						});
						that.achive();
					}, this.currentPage, 10, (res) => {
						this.$message({
							type: 'info',
							message: res.data.message,
							duration: 3000
						});
					});
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消同步'
					});
				})
			},
			handleCurrentChange(val) { //分页查询
				this.currentPage = val;
				this.achive()
			},
			handleSelectionChange(val) {/*table全选*/
//				console.log(val, '选中值')
				this.goodsList = val
				
				this.idStr=[];//清空每次全选时添加的订单Id
				val.forEach(item=>{
					this.idStr.push(item.id)
				})
			},
			getIdsStr() {
				let _this = this
				this.goodsList.forEach(function(item, index) {
					_this.skuNoList.push(item.skuNo)//Sku数组
					_this.stockNumList.push(item.stockNum)//对应的库存编码数组
				})
			},
			exportExcel(){//批量导出
				let str = new Date().getFullYear() + "-" + (new Date().getMonth() + 1) + "-" + new Date().getDate();
				this.fileName = '久集仓库列表_' + str + '.xls'
				if(this.isK3Seller){
					this.exportApi = '/sunrise-gateway/export/superc/traderGoodsForK3Stock'
					this.modelName = 'export.superc.traderGoodsForK3Stock'
				}else {
					this.exportApi = '/sunrise-gateway/export/superc/traderGoodsForStock'
					this.modelName = 'export.superc.traderGoodsForStock'
				}
				
				setTimeout(()=>{
					if(this.tableData.length>0){
						this.$refs.exportList.submit()
					}else{
						this.$message.info('请选择导出商品')
					}
				},0)
			},
		}
	}
</script>

<style lang="less">
	#Jdepot{
		.content {
			.el-form--inline {
				.el-form-item__label {
					font-size: 12px;
					width: 60px;
				}
				.order-datatimerange {
					width: 240px;
				}
			}
			.el-button+.el-button {
				margin-left: 0;
			}
			.el-table .cell {
				text-align: center;
				padding: 10px 0;
				img {
					width: 60px;
					height: 60px;
					margin-left: 10px;
					margin-right: 10px;
				}
				.width-130 {
					text-align: left;
				}
				.el-button {
					height: 20px;
				}
			}
			.el-form-item__label {
				width: 100px;
			}
		}
	}
</style>