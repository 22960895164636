<template>
    <div :key="index">
        <el-table :data="willAddGift" :key="index"  class='mt-10 table-default' stripe>
            <el-table-column label="赠品名称" width="500">
                <template slot-scope="scope">
                    <div class="goods-wrap">
                        <div class="ml-10 l-googs" style="position: relative;">
                            <img v-if='scope.row.giftPic' :src="scope.row.giftPic" />
                            <img v-else src="../../assets/goods.jpg" />
                        </div>
                        <div class="ml-10 r-goods">
                            <div class="tal w-160 lh-20"><p :title="scope.row.skuName">{{scope.row.skuName}}</p></div>
                            <div class="tal w-160 lh-20" v-if="scope.row.specInfoNum">规格1*{{scope.row.specInfoNum}}</div>
                            <div class="tal w-160 lh-20">
                                <span style="color:#FF4201;">￥{{scope.row.giftPrice | fmoney}}</span>
                                <span style="color:#999999;text-decoration-line: line-through;margin-left: 10px;" v-if="scope.row.msrp">￥{{scope.row.msrp | fmoney}}</span>
                            </div>
                        </div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="赠品单位" prop="giftUnit"></el-table-column>
            <el-table-column label="库存" prop="giftStock"></el-table-column>
            <el-table-column label="赠品数量" prop="quantity"></el-table-column>
            <el-table-column label="操作">
                <template slot-scope="scope">
                    <div class="opt">
                        <el-button  type="text" @click="delGift(index,scope.$index)">删除</el-button>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <!-- 分页组件 -->
        <el-pagination class="tar mr-30" v-if="total>0" :current-page="currentPage" :page-size='10'
                       :total='total' layout="prev , pager, next, jumper"
                       @current-change="handleCurrentChange">
        </el-pagination>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    export default {
        name: "tableC",
        props:['index'],
        data(){
            return {
                total:0,//总数据条数,
                currentPage:1,//当期页
            }
        },
        computed:{
            willAddGift(){
                return this.$store.state.marketActivity.willAddActivitySendList[this.index].willAddGift
            }
        },
        created(){
            // console.log(this.$store.state.giftSend)
        },
        methods: {
            //改变当前页
            handleCurrentChange(val){

            },
            //删除赠品
            delGift(index,indexSecond){
                this.$confirm('是否继续删除该赠品?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$store.commit('removeGift',{
                        index:index,
                        indexSecond:indexSecond
                    })
                }).catch(() => {

                });
            }
        }
    }
</script>

<style scoped>

</style>