<template>
    <div>
        作为跳转用的页面
    </div>
</template>
<script>
import { axiosApi,getLoginInfoBySessionId } from '~/api/api.js';
import Cookies from 'js-cookie'

export default {
    data() {
        return{
        }
    },
    created(){
        Cookies.set("JSESSIONID",'',-1)
        let urlParams = this.resolveRequestUrlParams()
        getLoginInfoBySessionId(urlParams.JSESSIONID, '/shop/shopIndex')
    },
    methods:{
        resolveRequestUrlParams() {
            let url = window.location.search; //获取url中"?"符后的字串
            let urlParams = new Object();
            if (url.indexOf("?") != -1) {
                let str = url.substr(1);
                let strs = str.split("&");
                for(let i = 0; i < strs.length; i ++) {
                    urlParams[strs[i].split("=")[0]]=decodeURI(strs[i].split("=")[1]);
                }
            }
            return urlParams;
        }
    }
}
</script>