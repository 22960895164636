<template>
    <div id="tastingDetail">
        <imgview :visible="imgsvisible" :imgs="showimgs" :startindex="startindex" @close="imgsvisible=false"></imgview>
        <div class="title-top bg-f mb-5">
			<div class="clearfix">
				<el-breadcrumb class="fl">
					<el-breadcrumb-item>报备详情</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
		</div>
        <div class="main bg-f pb-50" v-loading="loading">
            <div class="lists ml-30 mr-30 pt-30">
               <div class="item" >
                    <div class="title">报备名称</div>
                    <div class="body">{{taskName}}</div>
               </div>
               <div class="item" v-for="(item, index) in taskDetailsList" :key="index">
                    <div class="title">{{item.selectName}}</div>
                    <div class="body" v-if="item.selectType ==1">{{item.details}}</div>
                    <div class="body" v-if="item.selectType ==2" >
                        <div :key="i"  v-for="(i,index) in item.details">
                            <img v-if="i" :src="i"  @click="imgview(item.details, index)"/>
                            <img v-else src="../../assets/goods.jpg">
                        </div>
                    </div>
                    <div class="body" v-if="item.selectType ==3">
                        {{item.details | timeWeek}}
                    </div>
               </div>
               <div class="item" >
                    <div class="title">上报时间</div>
                    <div v-if='taskTime' class="body">{{taskTime | timenyr}}</div>
                    <div v-else class="body">无</div>
               </div>
               <div class="item" >
                    <div class="title">上报经纬度</div>
                    <div class="body">
						<span :class="{red: taskLongitude === '0.000000'}">
                            {{ !taskLongitude ? '无经度' : taskLongitude !== '0.000000' ? taskLongitude : '无法获取经纬度'}}
                        </span>
                        <span>，</span>
                        <span :class="{red: taskLatitude === '0.000000'}" >
                            {{ !taskLatitude ? '无纬度' : taskLatitude !== '0.000000' ? taskLatitude : '无法获取经纬度'}}
                        </span>
				    </div>
               </div>
            </div>
            <el-button type="danger" class="backback mt-30 mb-30" @click="back">返回</el-button>
        </div>
    </div>
</template>
<script>
import { axiosApi,basePath } from "../../api/api";
export default {
    data() {
        return {
            id: '',
            taskTime: '',
            taskName: '',
            taskDetailsList: '',
            taskLongitude: '',
            taskLatitude: '',
            loading: false,
            imgsvisible: false,
            showimgs: [],
            startindex: 0,
        }
    },
    methods: {
        queryinfo() {
            this.loading = true
            axiosApi('/sales/task/web/selectInfo',{id: this.id},(res)=>{
                var result = res.data.data
                this.taskTime = result.taskTime
                this.taskName = result.taskName
                this.taskDetailsList = result.taskDetailsList
                this.taskDetailsList.forEach((a)=>{
                    if (a.selectType == 2) {
                        a.details = JSON.parse(a.details)
                    }
                })
                this.taskLongitude = result.taskLongitude
                this.taskLatitude = result.taskLatitude
                this.loading = false
            },1,10,err => {
                this.loading = false
            })
        },
        back() {
            history.go(-1)
        },
        imgview(imgs, index) {
            this.showimgs = imgs
            this.startindex = index
            this.imgsvisible = true
        }
    },
    mounted() {
        this.id = this.$route.query.id
        this.queryinfo()
    }
}
</script>
<style lang="less">
    #tastingDetail{
        .backback {
            margin-left: 156px;
        }
        .lists {
           .item {
               overflow: hidden;
               display: flex;
               font-size: 14px;
               margin-bottom: 20px;
               .title {
                    width: 110px;
                    margin-right: 30px;
                    text-align: right;
               }
               .body {
                    display: flex;
                    overflow: hidden;
                    flex-wrap: wrap;
                    width: 100%;
                    max-height: 260px;
                    overflow: auto;
                    &::-webkit-scrollbar {
                        display: none;
                    }
                    img {
                        display: block;
                        width: 120px;
                        height: 120px;
                        margin-right: 20px;
                        margin-bottom: 20px;
                    }
					span{
                        &.red {
                            color: red;
                        }
                    }
               }
           }
       }
    }
</style>