<template>
    <div id="visitedDetail">
        <imgview :visible="imgsvisible" :imgs="showimgs" :startindex="startindex" @close="imgsvisible=false"></imgview>
        <div class="title-top bg-f mb-5">
            <div class="clearfix">
                <el-breadcrumb class="fl">
                    <el-breadcrumb-item>拜访记录详情</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
        </div>
        <div class="main bg-f pb-50 pl-30 pt-30 pr-30" v-loading="loading">
	        <div class="ml-15">
	            <span> 店铺名：</span><span class="ml-30">{{allInfo.traderName}}</span>
	        </div>
	        <div class="mt-40">
	            <span>签到时间：</span><span class="ml-30">{{allInfo.startTime}}</span>
	            <span class="ml-60">签退时间：</span>
	            <span v-if="allInfo.commitTime" class="ml-30">{{allInfo.commitTime}}</span>
	            <span v-else>未签退</span>
	            <span class="ml-60">拜访时长：</span>
	            <span v-if="allInfo.visitDuration" class="ml-30">{{allInfo.visitDuration}}</span>
	            <span v-else>暂无数据</span>
	        </div>
		    <div class="mt-40">
	            <span>签到地址：</span><span class="ml-30">{{allInfo.signOnAddr ? allInfo.signOnAddr : '暂无数据'}}</span>
				<!-- 新的签到优化后的数据 -->
				<span v-if="allInfo.signOnStatus != null">
					<span v-if="allInfo.signOnStatus == 1">
						<span v-if="allInfo.signOnAddr">（正常）</span>			
					</span>
					<span v-else-if="allInfo.signOnStatus == 0">
						<span class="red">（异常：</span>
						<span class="red" v-if="!allInfo.signOnAddr"> 无法获取经纬度）</span>
						<span class="red" v-if=" allInfo.signOnAddr" >和店铺地址偏差{{allInfo.signOffsetRange}}米）</span>
					</span>
				</span>
				<!-- 历史数据 -->
				<span v-else>
					<span class="red" v-if="allInfo.signOffsetRange > 600 || !allInfo.signOnAddr">（异常：</span>
					<span class="red" v-if="!allInfo.signOnAddr"> 无法获取经纬度）</span>
					<span class="red" v-if=" allInfo.signOnAddr && allInfo.signOffsetRange > 600" >和店铺地址偏差{{allInfo.signOffsetRange}}米）</span>
					<span v-if="allInfo.signOffsetRange <= 600 && allInfo.signOnAddr">（正常）</span>
				</span>
	            
	        </div>
			<div class="mt-40">
	            <span>签退地址：</span><span class="ml-30">{{allInfo.commitAddr ? allInfo.commitAddr : '暂无数据'}}</span>
				<!-- 新的签退优化后的数据 -->
				<span v-if="allInfo.commitStatus != null">
					<span v-if="allInfo.commitStatus == 1">
						<span v-if="allInfo.commitAddr">（正常）</span>			
					</span>
					<span v-else-if="allInfo.commitStatus == 0">
						<span class="red">（异常：</span>
						<span class="red" v-if="!allInfo.commitAddr"> 无法获取经纬度）</span>
						<span class="red" v-if=" allInfo.commitAddr" >和店铺地址偏差{{allInfo.commitOffsetRange}}米）</span>
					</span>
				</span>
				<!-- 历史数据 -->
				<span v-else>
					<span class="red" v-if="allInfo.commitOffsetRange > 600 || !allInfo.commitAddr">（异常：</span>
					<span class="red" v-if="!allInfo.commitAddr"> 无法获取经纬度 ）</span>				
					<span class="red" v-if="allInfo.commitAddr && allInfo.commitOffsetRange > 600">和店铺地址偏差{{allInfo.commitOffsetRange}}米）</span>
					<span v-if="allInfo.commitOffsetRange <= 600 && allInfo.commitAddr">（正常）</span>
				</span>
	        </div>
	        <div v-if="tableData1.length!=0">
	            <div class="mt-40">陈列检查：</div>
	            <el-table v-if="tableData1[0].checkProduct" class="mt-30 table-default"  :data="tableData1" style="width: 100%" stripe>
	                <el-table-column class="pl-30" prop="checkProduct" label="陈列商品" width="180">
	                </el-table-column>
	                <el-table-column prop="checkArea"  label="陈列面积" width="180"></el-table-column>
	                <el-table-column prop="checkCost"  label="陈列费用" width="180"></el-table-column>
	                <el-table-column label="备注">
	                    <template slot-scope="scope" >
	                        <div v-html="scope.row.remark"></div>
	                    </template>
	                </el-table-column>
	                <el-table-column  label="是否合格" width="180">
	                    <template slot-scope="scope">
	                        <div>{{scope.row.isStandard==0?'否':'是'}}</div>
	                    </template>
	                </el-table-column>
	            </el-table>
	            <el-table v-else class="mt-30 table-default"  :data="tableData1" style="width: 100%" stripe>
	                <el-table-column prop="checkArea"  label="陈列面积" width="180"></el-table-column>
	                <el-table-column prop="checkCost"  label="陈列费用" width="180"></el-table-column>
	                <el-table-column label="备注">
	                    <template slot-scope="scope" >
	                        <div v-html="scope.row.remark"></div>
	                    </template>
	                </el-table-column>
	                <el-table-column  label="是否合格" width="180">
	                    <template slot-scope="scope">
	                        <div>{{scope.row.isStandard==0?'否':'是'}}</div>
	                    </template>
	                </el-table-column>
	            </el-table>
	            
	            <div v-if="tableData1[0].checkSkuJson && !tableData1[0].checkProduct" class="mt-40">陈列商品：</div>
	            <el-table v-if="tableData1[0].checkSkuJson && !tableData1[0].checkProduct" class="mt-30 table-default"  :data="tableData1[0].checkSkuJson" style="width: 100%" stripe>
	                <el-table-column  label="商品图片">
	                	<template slot-scope="scope" >
							<div class="goodsImg">
		                        <img :src="scope.row.checkSkuImage" v-if="scope.row.checkSkuImage" alt="" />
								<img v-else src="../../assets/goods.jpg">
							</div>
	                    </template>
	                </el-table-column>
	                <el-table-column  label="商品名称" >
	                	<template slot-scope="scope" >
	                        <div v-html="scope.row.checkSkuName"></div>
	                    </template>
	                </el-table-column>
	                <el-table-column label="商品规格">
	                    <template slot-scope="scope" >
	                        <div v-html="scope.row.checkSkuSpecInfo"></div>
	                    </template>
	                </el-table-column>
	            </el-table>
	            
	            <div class="mt-30">
	                <span class="picText">陈列照片：</span>
	                <div class="picList ml-90" v-if="allInfo.visitCheckList">
	                    <div v-if="allInfo.visitCheckList.images==''||allInfo.visitCheckList.images==null">暂无数据</div>
	                    <div v-else class="ml-10 picitem" >
	                        <div v-for="(item,index) in allInfo.visitCheckList.images.split(',')" :key="index" >
                                <img v-if="item" class="img"  :src="item" alt="陈列照片" @click="imgview( allInfo.visitCheckList.images.split(',') , index )">
	                            <img v-else src="../../assets/goods.jpg">
                            </div>
	                    </div>
	                </div>
	                <div class="ml-90" v-else>暂无数据</div>
	            </div>
	        </div>

	        <div v-if="tableData2.length!=0">
	            <div class="mt-40">库存上报：</div>
	            <div v-if="tableData2[0] && tableData2[0].stockSkuJson && !tableData2[0].checkProduct">
		            <el-table class="mt-30 table-default" :data="tableData2" style="width:100%" stripe>
						<el-table-column label="商品图片">
		                	<template slot-scope="scope" >
								<div class="goodsImg">
									<img v-if="scope.row.stockSkuJson.stockSkuImage" :src="scope.row.stockSkuJson.stockSkuImage" />
									<img v-else src="../../assets/goods.jpg">
								</div>
		                    </template>
		                </el-table-column>
		                <el-table-column label="商品名称">
		                    <template slot-scope="scope" >
			                	<div class="clearfix" v-if="scope.row.stockSkuJson">
			                		<div :style="{textAlign : 'center'}">
			                			<span>{{scope.row.stockSkuJson.stockSkuName}}</span>
			                		</div>
			                	</div>
			                </template>
		                </el-table-column>
		                <el-table-column prop="stockSkuJson.stockSkuNum" label="库存"></el-table-column>
		            </el-table>
		        </div>
		        <div v-if="tableData2[0] && tableData2[0].checkProduct && tableData2[0].checkNum">
		       		<!-- 下边两条兼容老的拜访记录 -->
		       		<el-table class="mt-30 table-default" :data="tableData2" style="width:100%" stripe>
		            	<el-table-column label="商品" prop="checkProduct"></el-table-column>
		            	<el-table-column label="库存" prop="checkNum"></el-table-column>
		            </el-table>
		        </div>
	        </div>

	        <div v-if="getProduct.length!=0">
	            <div class="mt-40">竞品上报：</div>
	            <el-table class="mt-30 table-default" :data="getProduct" style="width: 100%" stripe>
	                <el-table-column prop="companyName" label="供货公司" width="180"></el-table-column>
	                <el-table-column prop="productName" label="商品名称" width="180"></el-table-column>
	                <el-table-column prop="productPrice" label="进店价" width="180"></el-table-column>
	                <el-table-column prop="stockNum" label="库存" width="180"></el-table-column>
	                <el-table-column prop="remarks" label="备注" ></el-table-column>
	            </el-table>
	        </div>
	        <div v-if="tableData4.length!=0">
	            <div class="mt-40 table-default">竞争公司上报：</div>
	            <el-table class="mt-30 table-default" :data="tableData4" style="width: 100%" stripe>
	                <el-table-column prop="companyName" label="供货公司" width=""></el-table-column>
	                <el-table-column prop="companyDetails" label="公司描述" width=""></el-table-column>
	            </el-table>
	        </div>
        
	        <!-- 自定义动作 -->
	        <div v-for="(aa,index1) in actionObjectArr" :key="index1">
					<div class="mt-40 mb-20">{{aa.name}}：</div>
					<div>
						<!-- 文本 -->
						<div v-if="aa.contentOther && aa.contentOther.length">
							<el-table class="table-default" :data="aa.contentOther" style="width:100%" stripe>
								<el-table-column prop="contentName" label="内容名称" width=""></el-table-column>
								<el-table-column prop="contentDetails" label="内容详情" width=""></el-table-column>
							</el-table>
						</div>
						
						<!-- 图片 -->
						<div class="mt-30" v-for="(bb,index) in aa.content2" :key="index">
							<span class="picText">{{bb.contentName}}</span>
							<div class="picList ml-90" v-if="bb.contentDetails">
								<div class="ml-10 picitem" v-for="( item ,index ) in bb.contentDetails.split(',')" :key="index">
									<img v-if="item" class="img" :src="item" alt="陈列照片" :key="index" 
									@click="imgview( bb.contentDetails.split(',') , index )">
									<img v-else src="../../assets/goods.jpg">
								</div>
							</div>
						</div>
						
					</div>
	        </div>
	      
			<!-- 代下单 -->
	        <div v-if="visitOrderNoArr.length" class="mt-30">
	            <div class="Mnum" :style="{display : 'flex'}">
					<span :style="{whiteSpace : 'nowrap'}">代下单(订单编号)：</span>
				</div>
				<el-table class="mt-30 table-default" :data="visitOrderNoArr" style="width: 100%" stripe>
					<el-table-column prop="companyName" label="订单编号" width="">
						<template slot-scope="scope">
							<a :style="{color : '#20a0ff' , cursor : 'pointer'}" @click="openOrderDetail(scope.row)">{{scope.row}}</a>
						</template>
					</el-table-column>
				</el-table>
	        </div>

			<!-- 总结 -->
			<div v-if="summary" class="mt-30">
				<div class="mt-40">总结：</div>
				<div class="summary-content" v-html="summary"></div>
			</div>

	        <el-button @click="back"  class="mt-50" type="danger">返回</el-button>
        </div>
    </div>
</template>

<script>
    import {axiosApi ,basePath} from '../../api/api'
    export default {
        data() {
            return {
                allInfo:{
                   visitCheckList:{images:''} 
                },
                tableData1: [],//陈列商品数据
                tableData2: [],//库存上报数据
                tableData4: [],//竞品公司上报数据
                imgsvisible: false,
                showimgs: [],
                startindex: 0,
				actionArr:[],//所有手动动作
				visitOrderNoArr : [],
				summary: '', //总结字段
				loading: true
            }
        },
        computed:{
            getProduct(){
                let arr=this.allInfo.visitProductReport;
                let desarr=[];
                for(let i in arr){
                    for(let j in arr[i].reportProductList){
						if (arr[i].reportProductList[j].productName) {
							desarr.push({
								companyName:arr[i].reportProductList[j].companyName,
								productName:arr[i].reportProductList[j].productName,
								productPrice:arr[i].reportProductList[j].productPrice,
								stockNum:arr[i].reportProductList[j].stockNum,
								remarks:arr[i].reportProductList[j].remarks
							})
						}
                    }
                }
                return desarr;
            },
            getActionName(){//获取手动动作名字
                let arr=[];
                for(let i = 0;i<this.allInfo.visitActionList.length;i++){
                    if(this.allInfo.visitActionList[i].actionType==2){ 
                        arr.push({name:this.allInfo.visitActionList[i].actionName});
                    }
                }
                return arr;
            },
            actionObjectArr(){//数组
                let newarr=[]
                for(let i=0;i<this.actionArr.length;i++){
                    newarr.push( Object.assign({},this.getActionName[i],this.actionArr[i]) )
                }
                newarr.forEach(item=>{
                    item.contentOther = [];
                    item.content2 = [];
                    item.content.forEach(iii=>{
                        if (iii.contentType == 2){
                            item.content2.push(iii)
                        } else {
							item.contentOther.push(iii)
						}
                    })
                })
                return newarr;
            }

        },
        mounted(){
            if(this.$route.query.id!=undefined){
                let id = this.$route.query.id;
                axiosApi('/sales/visitSign/app/visitSignSelectDetails',{id:id},res=>{
					this.visitOrderNoArr = res.data.data.visitOrderNo ? res.data.data.visitOrderNo.split(',') : [];
                    this.allInfo=res.data.data
                    if(res.data.data.visitCheckList!=null){
						res.data.data.visitCheckList.checkSkuJson = JSON.parse(res.data.data.visitCheckList.checkSkuJson);
						this.tableData1.push(res.data.data.visitCheckList);
						//this.tableData1.push(resetStringObj(res.data.data.visitCheckList));
					}//陈列商品数据
                    this.tableData2=this.resetVisitStockReport(res.data.data.visitStockReport)//库存上报数据
					this.tableData4=res.data.data.visitCompanyReport ? res.data.data.visitCompanyReport : []//竞品公司上报数据
					this.actionArr = res.data.data.handlerActionContent ? res.data.data.handlerActionContent : []
					this.summary = res.data.data.summary ? res.data.data.summary : ''
					this.loading = false
                },1,10,err => {
					this.loading = false
				})
            }
            

        },
        methods:{
			resetVisitStockReport : function(arr){
				if(arr && arr.length){
					let newArr = arr.map((v,k) => {
						if(v.stockSkuJson && (typeof v.stockSkuJson === 'string') ){
							v.stockSkuJson = JSON.parse(v.stockSkuJson);
						}
						return v;
					});
					return newArr;
				}else{
					return [];
				}
			},
			openOrderDetail(orderSn) {
				//通过订单号进入订单详情页面
				sessionStorage.setItem('orderType','visitedDetail?id='+this.$route.query.id)
				//订单详情
				let supplierOrderDetail = {
					type: 'supplierOrderDetail',
					orderSn: orderSn
				}
				sessionStorage.setItem('supplierOrderDetail', JSON.stringify(supplierOrderDetail))
				this.$router.push(basePath + '/cloudOrderDetails')
			},
            back(){
				history.go(-1)
            },
            imgview(imgs, index) {
                this.showimgs = imgs
                this.startindex = index
                this.imgsvisible = true
            }
        }
    }
</script>

<style lang="less">
    #visitedDetail {
		.goodsImg {
			margin: 0 auto;
		}
		.Mnum{
			.text{
				word-break : 'break-all';
				word-wrap : 'break-word';
				white-space : 'pre-wrap';
				cursor: pointer;
				color: #20a0ff;
			}
		}
        .red{
            color: #f00;
        }
        padding-left: 15px;
        padding-bottom: 50px;
        #imgview{
            z-index: 10;
        }
        .border{
            border: 1px solid #EEEEEE;
            background-color:  #EEEEEE;
        }
        .picText{
            position: relative;
            top: 20px;
        }
        .picList{
			display: flex;
			justify-content:flex-start;
            align-items:center;
            width: auto;
            height: 200px;
            overflow-y: hidden;
            overflow-x: auto;
            .picitem{
               display: flex;
               justify-content:flex-start;
               align-items:center;
               .img{
					width: auto;
                    height: 180px;
                    margin-left: 10px;
                }
            }  
        }
        .actionTable{
            width: 100%;
            border: 2px solid #eee;
            border-collapse: 0;
            thead{
                height: 35px;
                border-bottom:1px solid #ddd;
                th{
                    color: #666;
                    font-size: 12px;
                    padding: 10px 5px;
                }
            }
            tr{
                text-align: center;

                td{
                    padding: 10px 5px;
                    color: #666;
                    font-size: 12px;
                    border-top: 1px solid #ddd;
                }
                &.none-tr{
                    td{
                        height: 60px;
                        padding: 0;
                        background-color: #fff;
                        border-top: 1px solid #ddd;

                    }
                }
            }
            tr:nth-child(2n-1){
                background-color: #f6f6f6;
            }
        }
		.goodsImg{
			width: 80px;
			height: 80px;
			img{
				width: 100%;
				height: 100%;
				margin-right: 20px;
				display: block;
			}
		}
		.w200{
			margin-left: 20px;
			.goodsTitle{
				width: 200px;
				font-size:14px;
				color: #666666;
				line-height: 22px;
				margin-bottom: 10px;
				/*超过两行显示...*/
				text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        /* autoprefixer: off*/
        -webkit-box-orient: vertical; 
        /* autoprefixer: on*/
			}
			div{
				text-align: left;
			}
		}
		.summary-content {
			margin: 10px 0 10px 40px;
			text-indent: 24px;
		}

    }
</style>