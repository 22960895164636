import { axiosApi, basePath } from "./api";


// 获取商品分类列表
export function getClassifyTopList (data={}) {
  return new Promise((resolve, reject) => {
    axiosApi('/goods/classifyTopList/select', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
}

// 查询商品
export function sarechGoods (data={}) {
  return new Promise((resolve, reject) => {
    axiosApi('/goods/skuInfo/select/by/goodsno', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
};


// 获取周期列表
export function getIntegrationCycleList (data={}) {
  return new Promise((resolve, reject) => {
    axiosApi('/trader/seller/integrationCycle/select/name', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
}

// 获取当前周期
export function getCurrent (data={}) {
  return new Promise((resolve, reject) => {
    axiosApi('/trader/seller/integrationCycle/getCurrent', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
}

// 校验运费模版是否默认
export function templateValidate (data={}) {
  return new Promise((resolve, reject) => {
    axiosApi('/foundation/fare/templateValidate', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
}

// 免费时间列表
export function getFreeList (data={}) {
  return new Promise((resolve, reject) => {
    axiosApi('/foundation/fare/BuyerFreeSet/SelectList', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
}

// 设置免费时间
export function setFreeList (data={}) {
  return new Promise((resolve, reject) => {
    axiosApi('/foundation/fare/BuyerFreeSet/update', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
}

// 运费校验
export function getCalculate (data={}) {
  return new Promise((resolve, reject) => {
    axiosApi('/anon/order/freight/calculate', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
}

// 员工编辑详情
export function getEmployeeInfo (data={}) {
  return new Promise((resolve, reject) => {
    axiosApi('/foundation/employee/info', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
}

// 部门客户列表
export function getCustomerList (data={}) {
  return new Promise((resolve, reject) => {
    axiosApi('/trader/parentDepartment/customer/list', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
}

// 已选客户列表
export function getOwnCustomerList (data={}) {
  return new Promise((resolve, reject) => {
    axiosApi('/trader/own/customer/list', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
}

// 更新员工编辑
export function updateCustomer (data={}) {
  return new Promise((resolve, reject) => {
    axiosApi('/foundation/employee/cusotmer/update', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
}

// 更新部门客户
export function updateDepartment (data={}) {
  return new Promise((resolve, reject) => {
    axiosApi('/foundation/department/customer/update', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
}

// 获取关系客户
export function getTradersSelect (data={}) {
  return new Promise((resolve, reject) => {
    axiosApi('/trader/trader/select', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
}
/* 久币模块接口---------------------------------------- */
// 客户等级查询
export function getCoinLevelList (data={}) {
  return new Promise((resolve, reject) => {
    axiosApi('/trader/coinLevel/list', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
};

// 客户等级操作  参数optType （create-新增；update-更新 ；delete-删除）
export function postCoinLevelOperate (data={}) {
  return new Promise((resolve, reject) => {
    axiosApi('/trader/coinLevel/operate', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
};

// 客户久币权限 列表查询
export function getPageBaseInfo (data={}) {
  return new Promise((resolve, reject) => {
    axiosApi('/trader/customer/pageBaseInfo', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
};

// 客户久币权限 禁用&启用
export function postCoinPowerSet (data={}) {
  return new Promise((resolve, reject) => {
    axiosApi('/trader/coin/power/set', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
};

// 久币周期列表
export function getCurrentQuery (data={}) {
  return new Promise((resolve, reject) => {
    axiosApi('/trader/coinCycle/page', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
};

// 新增或者修改或者终止或者删除周期接口 
// optType为 update 修改，delete 删除
export function updateCurrent (data={}) {
  return new Promise((resolve, reject) => {
    axiosApi('/trader/coinCycle/opt', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
};

// stop 终止周期
export function stopCurrent (data={}) {
  return new Promise((resolve, reject) => {
    axiosApi('/trader/coinCycle/stop', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
};


// 指定商品设置
// 获取列表
export function getCoinGoods (data={}) {
  return new Promise((resolve, reject) => {
    axiosApi('/goods/coinSku/conditionGoods/page', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
};

// 新增或者修改指定商品
export function updateGoods (data={}) {
  return new Promise((resolve, reject) => {
    axiosApi('/goods/coinSku/conditionGoods/operate', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
};


// 周期久币管理相关
// 获取周期列表
export function getSellerCurrentQuery (data={}) {
  return new Promise((resolve, reject) => {
    axiosApi('/trader/sellerCoinCycle/current/query', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
};

// 周期久币管理列表&周期久币结算
export function getBuyerCycleInfoPage (data={}) {
  return new Promise((resolve, reject) => {
    axiosApi('/trader/coin/buyerCycleInfo/page', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
};

// 周期久币管理列表-审核数量
export function getAuditCount (data={}) {
  return new Promise((resolve, reject) => {
    axiosApi('/trader/coin/buyerCycleAudit/count', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
};

// 周期久币管理详情 - 指定商品 & 特殊商品
export function getCoinInfoPage (data={}) {
  return new Promise((resolve, reject) => {
    axiosApi('/order/coinInfo/page', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
};

// 周期久币管理详情 - 修改记录 auditState=success & 审核详情 auditState=apply
export function getCoinModifiedApplyLogs (data={}) {
  return new Promise((resolve, reject) => {
    axiosApi('/order/coinModifiedApply/detail', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
};

// 周期久币管理详情 - 修改申请
export function updateCoinModifiedApply (data={}) {
  return new Promise((resolve, reject) => {
    axiosApi('/order/coinModified/apply', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
};

// 周期久币管理详情 - 审核
export function updateCoinModifiedApproval(data={}) {
  return new Promise((resolve, reject) => {
    axiosApi('/order/coinModified/approval', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
};

// 周期久币结算---------------------------------
// 久币商品销售详情
export function getCoinGoodsInfoList (data={}) {
  return new Promise((resolve, reject) => {
    axiosApi('/order/coinGoodsInfo/list', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
};

// 久币待审核计数
export function getBuyerCycleSettlementAudit (data={}) {
  return new Promise((resolve, reject) => {
    axiosApi('/trader/coin/buyerCycleSettlementAudit/count', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
};

// 久币结算接口
export function postBuyerCycleSettlement (data={}) {
  return new Promise((resolve, reject) => {
    axiosApi('/trader/coin/buyerCycleInfo/settlement', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
};

// 客户久币结算批量审核
export function updateBuyerCycleSettlementApproval (data={}) {
  return new Promise((resolve, reject) => {
    axiosApi('/trader/coin/buyerCycleSettlement/approval', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
};

// 客户久币详情
export function getBuyerCycleInfoDetail (data={}) {
  return new Promise((resolve, reject) => {
    axiosApi('/trader/coin/buyerCycleInfo/detail', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
};

// 特殊商品设置
// 获取列表
export function getCoinGoodsPage (data={}) {
  return new Promise((resolve, reject) => {
    axiosApi('/goods/coinSku/coinGoods/page', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
};

// 特殊商品操作
export function updateCoinGoodsOperate (data={}) {
  return new Promise((resolve, reject) => {
    axiosApi('/goods/coinSku/coinGoods/operate', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
};
/* 资产久币记录 */
// 资产久币记录获取列表&客户久币管理关联订单&客户久币管理修改记录分页查询
export function getCoinRecordPage (data={}) {
  return new Promise((resolve, reject) => {
    axiosApi('/trader/coin/record/page', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
};

// 客户周期明细详情
export function getBuyerCurrentCycle (data={}) {
  return new Promise((resolve, reject) => {
    axiosApi('/trader/coin/buyerCurrentCycle/get', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
};

/* 客户久币管理 */
// 客户久币分页查询
export function getCoinPage (data={}) {
  return new Promise((resolve, reject) => {
    axiosApi('/trader/coin/page', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
};

// 客户久币查看详情
export function getCoinDetail (data={}) {
  return new Promise((resolve, reject) => {
    axiosApi('/trader/coin/detail', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
};




// 客户久币审核申请分页查询
export function getApplyCoinPage (data={}) {
  return new Promise((resolve, reject) => {
    axiosApi('/trader/coin/apply/page', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
};

// 申请修改久币
export function appendApplyCoin (data={}) {
  return new Promise((resolve, reject) => {
    axiosApi('/trader/coin/apply/append', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
};

// 审核久币修改
export function auditApplyCoin (data={}) {
  return new Promise((resolve, reject) => {
    axiosApi('/trader/coin/apply/audit', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
};


// 客户久币管理待审核数
export function getApplyCoinCount (data={}) {
  return new Promise((resolve, reject) => {
    axiosApi('/trader/coin/apply/count', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
};

// 设置-云销是否支持代下单赠品开关
export function updateGift (data={}) {
  return new Promise((resolve, reject) => {
    axiosApi('/sales/sellerIsOpenSalesGift/update', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
};
// 设置-获取云销是否支持代下单赠品开关
export function getGiftInfo (data={}) {
  return new Promise((resolve, reject) => {
    axiosApi('/sales/findTraderInfoBySerllerId/select', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
};

// 销售-广告管理-云商广告
// 获取广告列表
export function getAdvertisement (data={}) {
  return new Promise((resolve, reject) => {
    axiosApi('/foundation/advertisement/page', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
}

// 添加广告
export function advertisementInsert (data={}) {
  return new Promise((resolve, reject) => {
    axiosApi('/foundation/advertisement/insert', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
}

// 广告详情
export function getSelectInfo (data={}) {
  return new Promise((resolve, reject) => {
    axiosApi('/foundation/advertisement/selectInfo', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
}

// 编辑广告
export function updateSelectInfo (data={}) {
  return new Promise((resolve, reject) => {
    axiosApi('/foundation/advertisement/update', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
}

// 删除广告
export function deleteSelectInfo (data={}) {
  return new Promise((resolve, reject) => {
    axiosApi('/foundation/advertisement/delete', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
}

// 置顶广告
export function setTop (data={}) {
  return new Promise((resolve, reject) => {
    axiosApi('/foundation/advertisement/setTop', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
}

// 客户

// 新增客户费用明细
export function insertGoodsFeeInfo (data={}) {
  return new Promise((resolve, reject) => {
    axiosApi('/trader/buyerGoodsFeeInfo/insert', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
}
// 编辑客户费用明细
export function updateGoodsFeeInfo (data={}) {
  return new Promise((resolve, reject) => {
    axiosApi('/trader/buyerGoodsFeeInfo/update', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
}

// 客户费用列表
export function getGoodsFeeInfo (data={}) {
  return new Promise((resolve, reject) => {
    axiosApi('/trader/buyerGoodsFeeInfo/page', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
}
// 对账单费用明细
export function getBuyerGoodsFeeInfo (data={}) {
  return new Promise((resolve, reject) => {
    axiosApi('/trader/buyerGoodsFeeInfo/self/page', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
}

// 客户费用详情
export function getGoodsFeeInfoDetail (data={}) {
  return new Promise((resolve, reject) => {
    axiosApi('/trader/buyerGoodsFeeInfo/detail', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
}

// 客户费用删除
export function deleteGoodsFeeInfo (data={}) {
  return new Promise((resolve, reject) => {
    axiosApi('/trader/buyerGoodsFeeInfo/delete', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
}


// 营销中心-推荐商品
export function getGoodsRecommendPage (data={}) {
  return new Promise((resolve, reject) => {
    axiosApi('/goods/goodsRecommendPage/select', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
}

// 添加推荐商品
export function addRecommend (data={}) {
  return new Promise((resolve, reject) => {
    axiosApi('/goods/goodsRecommend/add', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
}

// 置顶接口
export function overhead (data={}) {
  return new Promise((resolve, reject) => {
    axiosApi('/goods/goodsRecommend/overhead', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
}

// 删除推荐商品
export function deleteItem (data={}) {
  return new Promise((resolve, reject) => {
    axiosApi('/goods/goodsRecommend/delete', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
}

// 推荐商品终止
export function goodsRecommendTerminate (data={}) {
  return new Promise((resolve, reject) => {
    axiosApi('/goods/goodsRecommend/terminate', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
}



// 满减满赠审核
export function auditActivity (data={}) {
  return new Promise((resolve, reject) => {
    axiosApi('/marketing/activity/audit', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
}


// 优惠券审核
export function auditCoupon (data={}) {
  return new Promise((resolve, reject) => {
    axiosApi('/marketing/coupon/audit', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
}


// 满减满赠待审核数
export function auditActivityCount (data={}) {
  return new Promise((resolve, reject) => {
    axiosApi('/marketing/activity/audit/count', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
}

// 优惠券待审核数
export function auditCouponCount (data={}) {
  return new Promise((resolve, reject) => {
    axiosApi('/marketing/coupon/audit/count', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
}


// 首页商品
export function goodsRecommendIndex (data={}) {
  return new Promise((resolve, reject) => {
    axiosApi('/unlogin/goods/goodsRecommendIndex/select', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
}

// 获取订单设置-已添加审核人员
export function getAuditEmployee (data={}) {
  return new Promise((resolve, reject) => {
    axiosApi('/order/auditEmployee/list', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
}
// 订单设置-已添加审核人员-删除
export function deleteAuditEmployee (data={}) {
  return new Promise((resolve, reject) => {
    axiosApi('/order/auditEmployee/delete', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
}
// 订单设置-审核人员-添加
export function addAuditEmployee (data={}) {
  return new Promise((resolve, reject) => {
    axiosApi('/order/auditEmployee/add', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
}

// 角色管理列表
export function getRoleSelectAll (data={}) {
  return new Promise((resolve, reject) => {
    axiosApi('/foundation/role/selectAll', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
}


// 销售-商品管理
export function getGoods (data={}) {
  return new Promise((resolve, reject) => {
    axiosApi('/goods/skuInfoPage/select', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
}


// 销售-商品销量管理
export function updatePreSales (data={}) {
  return new Promise((resolve, reject) => {
    axiosApi('/goods/sku/preSales/update', data, (res) => {
      if (res.data.code === "000000") {
        resolve(res.data.data)
      } else { 
        reject(res)
      }
    }, data.pageIndex||null, data.pageSize||null, (err) => { 
      reject(err)
    });
  })
}